import { useSelector } from 'react-redux';
import { Radio } from 'antd';

import AccessibleDiv from 'components/AccessibleDiv';
import { TcoProjectListType } from 'pages/TcoProjectsPage/types';
import { tcoDashboard } from 'redux/tcoDashboardSlice';

import './index.scss';

type TcoDashboardListItemProps = {
  dashboard: TcoProjectListType;
  onSelectDashboard: () => void;
};

const TcoDashboardListItem = ({
  dashboard,
  onSelectDashboard,
}: TcoDashboardListItemProps) => {
  const { selectedTco } = useSelector(tcoDashboard);

  return (
    <AccessibleDiv
      className="dashboard-list-item flex flex-gap-16 cursor-pointer"
      onClick={onSelectDashboard}
    >
      <Radio checked={selectedTco?.name === dashboard.name} />
      <div className={`dashboard-details flex flex-column flex-gap-4`}>
        <span className="font-button">{dashboard.name}</span>
        <span className="description font-caption">
          {dashboard.description}
        </span>
      </div>
    </AccessibleDiv>
  );
};

export default TcoDashboardListItem;
