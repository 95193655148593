import { Modal as BaseModal } from 'antd';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { convertHexToHsl } from 'utils/dashboardUtils';

const Modal = ({ children, ...remainingProps }: any) => {
  const { theme } = useSelector(selectTheme);

  return (
    <BaseModal
      closeIcon={
        <Icon iconName={ICONS.CLOSE_FILL} color={theme.buttonIconColor} />
      }
      footer={null}
      {...remainingProps}
      style={{
        ...remainingProps.style,
        color: theme.textColor,
        '--button-icon-color': theme.buttonIconColor,
        '--text-color': theme.textColor,
        '--primary-color': theme.primaryColor,
        '--table-header-color': convertHexToHsl(theme.primaryColor, { l: 96 }),
      }}
    >
      {children}
    </BaseModal>
  );
};

export default Modal;
