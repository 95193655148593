import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataCenter, setDataCenterComponent } from 'redux/dataCenterSlice';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import { DataCenterComponents } from './constants';
import ManualEntry from './components/ManualEntry';
import DataCenterTable from './components/DataCenterTable';

const DataCenterPage = () => {
  const dispatch = useDispatch();
  const { dataCenterComponent } = useSelector(dataCenter);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      dispatch(setDataCenterComponent(DataCenterComponents.DATA_CENTER_TABLE));
      removeZeroMarginClass();
    };
  }, []);

  const getComponent = () => {
    if (dataCenterComponent === DataCenterComponents.DATA_CENTER_TABLE) {
      return <DataCenterTable />;
    }

    if (dataCenterComponent === DataCenterComponents.MANUAL_ENTRY) {
      return <ManualEntry />;
    }
  };

  return <>{getComponent()}</>;
};

export default DataCenterPage;
