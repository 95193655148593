import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Modal from 'components/Modal';

type RulesLimitModalProps = {
  show: boolean;
  onClickOk: Function;
  message: string;
};

const RulesLimitModal = ({
  show,
  onClickOk,
  message,
}: RulesLimitModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={show}
      centered
      footer={
        <Button
          className="flex flex-inline"
          title={t('ok')}
          onClick={onClickOk}
        />
      }
      closable={false}
      data-testid="rules-limit-modal"
      destroyOnClose={true}
    >
      <div className="modal-heading">{message}</div>
    </Modal>
  );
};

export default RulesLimitModal;
