import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GProfilerTabsView,
  GProfilerConnectionView,
  GProfilerProviderView,
} from 'constants/gProfiler';
import { RootState } from './store';

export interface GProfilerState {
  currentStep: number;
  gProfilerTabsView: string;
  gProfilerConnectionView: string;
  gProfilerProviderView: string;
}

const initialState: GProfilerState = {
  currentStep: 0,
  gProfilerTabsView: GProfilerTabsView.TABS,
  gProfilerConnectionView: GProfilerConnectionView.TABLE_VIEW,
  gProfilerProviderView: GProfilerProviderView.PROVIDER_LIST,
};

export const gProfilerSlice = createSlice({
  name: 'gProfiler',
  initialState,
  reducers: {
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setGProfilerTabsView(state, action: PayloadAction<string>) {
      state.gProfilerTabsView = action.payload;
    },
    setGProfilerConnectionView(state, action: PayloadAction<string>) {
      state.gProfilerConnectionView = action.payload;
    },
    setGProfilerProviderView(state, action: PayloadAction<string>) {
      state.gProfilerProviderView = action.payload;
    },
  },
});
export const selectGProfiler = (state: RootState) => state.gProfiler;
export const {
  setCurrentStep,
  setGProfilerTabsView,
  setGProfilerConnectionView,
  setGProfilerProviderView,
} = gProfilerSlice.actions;

export default gProfilerSlice.reducer;
