import { message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';

import Modal from 'components/Modal';
import Button from 'components/Button';
import SearchUserInput from 'components/AddUserSearch';
import AddRole from 'pages/UserPage/components/AddRole';
import ModalFooterSteps from 'components/ModalFooterSteps';
import { searchUserExist } from 'pages/UserPage/services';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { UserInfoType } from 'types/userManagementConsole';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

type AddUserProps = {
  showAddUsersModal: boolean;
  setShowAddUsersModal: (val: boolean) => void;
  fetchUserData: () => void;
};

const AddUser = ({
  showAddUsersModal,
  setShowAddUsersModal,
  fetchUserData,
}: AddUserProps) => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);

  const [userList, setUserList] = useState<UserInfoType[]>([]);
  const [step, setStep] = useState(1);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);

  const removeEmailFromList = (emailId: string) => {
    setUserList(
      userList.filter((user: UserInfoType) => user.email !== emailId)
    );
  };

  const onClickNext = () => {
    setStep(2);
    setShowAddUsersModal(false);
    setShowAddRoleModal(true);
  };

  const onClose = () => {
    setShowAddUsersModal(false);
    setUserList([]);
  };

  const onSetUserInfo = (user: UserInfoType) => {
    const params = {
      email: user.email,
    };
    searchUserExist(params)
      .then((res: any) => {
        res?.data?.responseData?.present
          ? message.warning(t('userAlreadyExistsErrorMessage'))
          : userList.findIndex((item) => item.email === user.email) === -1 &&
            setUserList([...userList, { email: user.email, name: user.name }]);
      })
      .catch((error) => {
        onApiCallError(error);
      });
  };

  return (
    <div className="adduser flex flex-column">
      <Modal
        visible={showAddUsersModal}
        centered
        onCancel={onClose}
        title={t('addUserModal.title')}
        footer={<ModalFooterSteps totalSteps={2} currentStep={step} />}
      >
        <SearchUserInput
          placeholder={t('addUserModal.searchPlaceHolder')}
          setUserInfo={onSetUserInfo}
          excludeUserEmail
        />
        <div className="adduser-email-list flex flex-justify-content-left flex-wrap">
          {userList.map((user: UserInfoType) => (
            <AccessibleDiv
              className="adduser-email-list-email flex flex-center"
              onClick={() => removeEmailFromList(user.email)}
              key={user.email}
            >
              {user.email}
              <Icon
                iconName={ICONS.CLOSE_LINE}
                size={ICONS_SIZE.SM}
                color={theme.buttonIconColor}
              />
            </AccessibleDiv>
          ))}
        </div>
        <div className="adduser-button flex flex-end">
          <Button
            title={t('addUserModal.nextButton')}
            onClick={onClickNext}
            disabled={userList?.length === 0}
          />
        </div>
      </Modal>
      <AddRole
        showAddRoleModal={showAddRoleModal}
        setShowAddRoleModal={setShowAddRoleModal}
        userList={userList}
        step={step}
        setStep={setStep}
        setUserList={setUserList}
        fetchUserData={fetchUserData}
      />
    </div>
  );
};

export default AddUser;
