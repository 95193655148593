import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerComponent from 'components/DrawerComponent';
import Loader from 'components/Loader';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getTicketConnection } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

import { OptimizationTableType } from '../../type';
import { getTicketIncidentInfo } from '../../services';
import './index.scss';

type TicketInfoModalProps = {
  show: boolean;
  setShow: (value: boolean) => void;
  ticketInfo: OptimizationTableType | null;
  status: string | undefined;
};

const TicketInfoModal = ({
  show,
  setShow,
  ticketInfo,
  status,
}: TicketInfoModalProps) => {
  const { t } = useTranslation();

  const [ticketInfoData, setTicketInfoData] = useState<any>();
  const [ticketGoToUrlEndpoint, setTicketGoToUrlEndpoint] = useState();
  const [ticketInfoRequestStatus, setTicketInfoRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [ticketUrlRequestStatus, setTicketUrlRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    if (ticketInfo) {
      setTicketInfoRequestStatus(REQUEST_STATUS.PROCESSING);
      getTicketIncidentInfo(ticketInfo.sys_id || '')
        .then((res: any) => {
          if (res?.status === 200) {
            setTicketInfoRequestStatus(REQUEST_STATUS.SUCCESS);
            setTicketInfoData(res.data.response);
          }
        })
        .catch((e) => onApiCallError(e, true, setTicketInfoRequestStatus));

      setTicketUrlRequestStatus(REQUEST_STATUS.PROCESSING);
      getTicketConnection()
        .then((res: any) => {
          if (res?.status === 200) {
            setTicketUrlRequestStatus(REQUEST_STATUS.SUCCESS);
            setTicketGoToUrlEndpoint(res.data.responseData.endpoint);
          }
        })
        .catch((e) => onApiCallError(e, true, setTicketUrlRequestStatus));
    }
  }, [show]);

  /**
   * @function onGoToIncident
   * @description Opens the ticket url in a new tab when clicked on Go To Incident
   */
  const onGoToIncident = () => {
    if (ticketInfo) {
      const link = `${ticketGoToUrlEndpoint}incident.do?sys_id=${ticketInfo.sys_id}`;
      window.open(link, '_blank');
    }
  };

  const TicketData = [
    {
      heading: t('gprofilerInsightsDashboard.name'),
      description: ticketInfo?.name,
    },
    {
      heading: t('gprofilerInsightsDashboard.description'),
      description: ticketInfoData?.description,
    },
    {
      heading: t('gprofilerInsightsDashboard.resolution'),
      description: ticketInfo?.resolution,
    },
    {
      heading: t('gprofilerInsightsDashboard.impact'),
      description: ticketInfo?.impact,
    },
    {
      heading: t('gprofilerInsightsDashboard.effort'),
      description: ticketInfo?.effort,
    },
  ];

  const getComponent = () => {
    if (
      ticketInfoRequestStatus === REQUEST_STATUS.PROCESSING ||
      ticketUrlRequestStatus === REQUEST_STATUS.PROCESSING
    )
      return <Loader />;
    else if (
      ticketInfoRequestStatus === REQUEST_STATUS.SUCCESS &&
      ticketUrlRequestStatus === REQUEST_STATUS.SUCCESS &&
      ticketInfoData
    )
      return (
        <>
          <div className="flex flex-gap-8 flex-align-items-center">
            <div className="form-header">
              {t('gprofilerInsightsDashboard.incidentIdHeader', {
                id: ticketInfoData?.number,
              })}
            </div>
            <span className="font-caption status">{status}</span>
          </div>
          <div>
            {TicketData.map((data) => (
              <div
                key={data.heading}
                className="ticket-list flex flex-column flex-gap-8"
              >
                <div className="font-caption-bold">{data.heading}</div>
                <span className="font-caption">{data.description}</span>
              </div>
            ))}
          </div>
        </>
      );
    else
      return (
        <div className="flex stretch flex-center text-align-center form-header">
          {t('apiCallErrorMessage')}
        </div>
      );
  };

  return (
    <DrawerComponent
      className="gprofiler-ticket-info"
      width={486}
      open={show}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-align-item-center flex-end">
          <Button
            title={t('gprofilerInsightsDashboard.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={t('gprofilerInsightsDashboard.goToIncident')}
            onClick={() => {
              onGoToIncident();
            }}
            disabled={!ticketInfoData || !ticketGoToUrlEndpoint}
          />
        </div>
      }
    >
      {getComponent()}
    </DrawerComponent>
  );
};

export default TicketInfoModal;
