import axios from 'axios';

import { Config } from 'config';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { store } from 'redux/store';
import { APIS } from 'constants/endpoints';
import { setApiCallsInProgress } from 'redux/authorizationSlice';
import { getEndpointFromUrl } from 'utils/utilsForTests';

import { LoginTypes } from './constants';

/**
 * @function addErrorInterceptor
 * @description Function to add error interceptor to handle 401 and 503 errors
 * @param error error object
 * @param navigate useNavigate hook
 * @param setSessionExpired function to set session expired
 * @return Promise for axios interceptor
 */
export const handleErrorInterceptor = (
  error: any,
  navigate: Function,
  setSessionExpired: Function
) => {
  const UNAUTHORIZED = 401;

  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  const status = error?.response?.status;

  if (status === UNAUTHORIZED && Config.login_type === LoginTypes.MFA) {
    localStorage.clear();
    navigate(NAVIGATION_MENU_PATH.LOGIN);
    return;
  }

  if (status === UNAUTHORIZED) {
    setSessionExpired(true);
  }

  return Promise.reject(error);
};

/**
 * @function axiosInterceptor
 * @description Function to handle the axios interceptors
 * @param navigate useNavigate hook
 * @param setSessionExpired function to set session expired
 * @return Promise for axios interceptor
 */
export const axiosInterceptor = (
  navigate: Function,
  setSessionExpired: Function
) => {
  axios.interceptors.request.use(
    (config) => {
      updateApiCallsCount(config.url!, 1);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      updateApiCallsCount(response.config.url!, -1);
      return response;
    },
    (error) => {
      updateApiCallsCount(
        axios.isCancel(error) ? '' : error.response.config.url,
        -1
      );
      return handleErrorInterceptor(error, navigate, setSessionExpired);
    }
  );
};

/**
 * @function updateApiCallsCount
 * @description Function to update the api calls count
 * @param url Url for which the api call is made
 * @param count count to be updated with, +1 to increment by 1 and -1 to decrement by 1
 */
const updateApiCallsCount = (url: string, count: number) => {
  const api = getEndpointFromUrl(url);
  if (api !== APIS.GET_AUTH_TOKEN) {
    store.dispatch(setApiCallsInProgress(count));
  }
};
