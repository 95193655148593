import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { dataCenter, setNetworkEntries } from 'redux/dataCenterSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { NetworkEntryListType } from 'pages/DataCenterPage/types';
import {
  deleteNetworkResource,
  getAllNetworkResources,
} from 'pages/DataCenterPage/services';
import {
  MODE_OF_PURCHASES,
  PurchaseModes,
} from 'pages/DataCenterPage/constants';
import QuickActionMenu from 'components/QuickActionMenu';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import DeleteModal from 'components/DeleteModal';
import { ERROR_KEY, REQUEST_STATUS } from 'constants/requestBody';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { onApiCallError } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import {
  EntryQuickActions,
  ENTRY_QUICKACTIONS,
} from '../ManualEntry/constants';
import EntryTableHeader from '../EntryTableHeader';
import NetworkRowModal from '../NetworkRowModal';

const NetworkEntry = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { networkEntries, selectedDataCenter } = useSelector(dataCenter);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [totalCount, setTotalCount] = useState(0);
  const [currentNetworkEntry, setCurrentNetworkEntry] =
    useState<NetworkEntryListType>();
  const [showNetworkRowModal, setShowNetworkRowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [networkEntryDataRequestStatus, setNetworkEntryDataRequestStatus] =
    useState('');
  const [
    deleteNetworkResourceRequestStatus,
    setDeleteNetworkResourceRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    fetchNetworkEntryData();
  }, []);

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    fetchNetworkEntryData(page);
  };

  /**
   * @function fetchNetworkEntryData
   * @description Function to fetch paginated network entry data
   * @param page page number defaults to first page
   */
  const fetchNetworkEntryData = (page: number = 1) => {
    setNetworkEntryDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      dataCenterCode: selectedDataCenter?.dataCenterCode,
      page: page - 1,
      size: PAGINATION_SIZE,
    };

    getAllNetworkResources(params)
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(setNetworkEntries(res.data.responseData.content));
          setTotalCount(res.data.responseData.totalElements);
          setNetworkEntryDataRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setNetworkEntryDataRequestStatus(REQUEST_STATUS.ERROR);
        message.error({ content: t('apiCallErrorMessage'), key: ERROR_KEY });
      })
      .catch((e) => {
        onApiCallError(e, true, setNetworkEntryDataRequestStatus);
      });
  };

  /**
   * @function deleteNetworkEntry
   * @description Function to delete a network entry
   */
  const deleteNetworkEntry = () => {
    setDeleteNetworkResourceRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      id: currentNetworkEntry?.id,
    };

    deleteNetworkResource(params)
      .then((res: any) => {
        if (res?.status === 200) {
          message.success(t('manualEntry.networkLabels.deleteNetworkSuccess'));
          setDeleteNetworkResourceRequestStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          onSaveOrUpdateEntry();
          return;
        }
        message.error({
          content:
            res?.message ?? t('manualEntry.networkLabels.deleteNetworkFailed'),
        });
        setShowDeleteModal(false);
        setDeleteNetworkResourceRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteNetworkResourceRequestStatus,
          e?.response?.data?.message ??
            t('manualEntry.networkLabels.deleteNetworkFailed')
        );
        setShowDeleteModal(false);
      });
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick action buttons from the table
   * @param action : The clicked action
   */
  const handleQuickAction = (action: string) => {
    switch (action) {
      case EntryQuickActions.EDIT:
        setShowNetworkRowModal(true);
        break;
      case EntryQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  /**
   * @function onSaveOrUpdateEntry
   * @description Callback function for save or update network entry
   */
  const onSaveOrUpdateEntry = () => {
    setCurrentPage(1);
    fetchNetworkEntryData();
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + (index + 1),
      width: '5%',
    },
    {
      title: t('manualEntry.networkLabels.dataTransferEgress'),
      dataIndex: 'dataTransferEgressInGb',
      key: 'dataTransferEgressInGb',
    },
    {
      title: t('manualEntry.networkLabels.modeOfPurchase'),
      dataIndex: 'modeOfPurchase',
      key: 'modeOfPurchase',
      render: (text: string) =>
        MODE_OF_PURCHASES.find((item) => item.key === text)?.label,
    },
    {
      title: t('manualEntry.networkLabels.dateOfPurchase'),
      dataIndex: 'dateOfPurchase',
      key: 'dateOfPurchase',
      render: (text: string) => moment(text).format(MONTH_YEAR_FORMAT),
    },
    {
      title: t('manualEntry.networkLabels.duration'),
      dataIndex: 'durationInMonths',
      key: 'durationInMonths',
      render: (text: string) =>
        text ? `${text} ${t('manualEntry.networkLabels.months')}` : '--',
    },
    {
      title: t('manualEntry.networkLabels.cost'),
      dataIndex: 'cost',
      key: 'cost',
      render: (_text: string, record: NetworkEntryListType) => {
        const cost =
          record.modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
            ? record.monthlyCost
            : record.totalCost;
        return `${currencySymbol} ${numberCommaSeparator(cost ?? 0)}`;
      },
    },
    {
      title: t('manualEntry.networkLabels.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_text: string, record: any) => {
        return (
          <QuickActionMenu
            setCurrentConnectionData={() => setCurrentNetworkEntry(record)}
            quickActions={ENTRY_QUICKACTIONS}
            quickActionHandler={(action: string) => {
              handleQuickAction(action);
            }}
          />
        );
      },
      fixed: 'right',
      width: '8%',
      align: 'center',
    },
  ];

  return (
    <div className="manual-entry-tab-container card flex flex-column flex-gap-16">
      <EntryTableHeader
        title={t('manualEntry.networkLabels.tableTitle')}
        onClickAddRow={() => {
          setShowNetworkRowModal(true);
          setCurrentNetworkEntry(undefined);
        }}
      />
      <Table
        rootClassName="manual-entry-table"
        pagination={false}
        columns={columns}
        dataSource={networkEntries.map((item, index) => ({
          key: index,
          ...item,
        }))}
        loading={networkEntryDataRequestStatus === REQUEST_STATUS.PROCESSING}
        scroll={{ y: '100%', x: '100%' }}
        designVersion2
        fillContainer
      />
      <div className="page-footer flex">
        {totalCount > PAGINATION_SIZE && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </div>
      {showNetworkRowModal && (
        <NetworkRowModal
          show={showNetworkRowModal}
          setShow={setShowNetworkRowModal}
          onSaveOrUpdateEntry={onSaveOrUpdateEntry}
          networkEntry={currentNetworkEntry}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={deleteNetworkEntry}
          loading={
            deleteNetworkResourceRequestStatus === REQUEST_STATUS.PROCESSING
          }
        />
      )}
    </div>
  );
};

export default NetworkEntry;
