import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import { ICONS } from 'constants/icons';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { BUTTON_TYPE } from 'constants/appearance';
import {
  cloudConnection,
  setCurrentStep,
  setResetStepForm,
} from 'redux/cloudConnectionSlice';
import { VALIDATION_STATUS } from 'pages/ConnectingCSPPage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';

import './index.scss';

type FooterProps = {
  selectedProvider: string | undefined;
  itemsLength: number;
  onSubmit: () => void;
  submitRequestStatus: string;
};

const Footer = ({
  selectedProvider,
  itemsLength,
  onSubmit,
  submitRequestStatus,
}: FooterProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currentStep, currentStepValidation, isEditConnection } =
    useSelector(cloudConnection);
  const dispatch = useDispatch();

  const onClickNext = () => {
    if (currentStep < itemsLength - 1) {
      dispatch(setCurrentStep(currentStep + 1));
    } else {
      onSubmit();
    }
  };

  const onClickPrevious = () => {
    if (currentStep > 0) {
      dispatch(setCurrentStep(currentStep - 1));
    }
  };

  const getNextButtonTitle = () => {
    if (
      currentStep === itemsLength - 1 &&
      currentStepValidation === VALIDATION_STATUS.SKIP
    )
      return isEditConnection
        ? t('connectionCSPForm.skipAndUpdate')
        : t('connectionCSPForm.skipAndFinish');
    else if (currentStep === itemsLength - 1)
      return isEditConnection
        ? t('connectionCSPForm.update')
        : t('connectionCSPForm.finish');
    else if (currentStepValidation === VALIDATION_STATUS.SKIP)
      return t('connectionCSPForm.skipStep');
    return t('connectionCSPForm.nextStep');
  };

  return (
    <footer className="provider-footer flex flex-space-between flex-align-items-center">
      <div className="flex">
        <Button
          onClick={() => navigate(NAVIGATION_MENU_PATH.CLOUD_CONNECTIONS)}
          title={t('connectionCSPForm.cancel')}
          type={BUTTON_TYPE.LINK}
        />
        <div className="vertical-rule"></div>
        <Button
          onClick={() => dispatch(setResetStepForm(true))}
          title={t('connectionCSPForm.reset')}
          type={BUTTON_TYPE.LINK}
        />
      </div>
      {currentStep === 0 ? (
        <Button
          onClick={onClickNext}
          title={t('connectionCSPForm.continue')}
          iconName={ICONS.ARROW_RIGHT}
          disabled={!selectedProvider}
        />
      ) : (
        <div className="flex flex-gap-10">
          {(!isEditConnection || (isEditConnection && currentStep > 1)) && (
            <Button
              onClick={onClickPrevious}
              title={t('connectionCSPForm.previousStep')}
              iconName={ICONS.ARROW_LEFT}
              type={BUTTON_TYPE.OUTLINED}
              reverseIconTitleOrder
            />
          )}
          <Button
            onClick={onClickNext}
            title={getNextButtonTitle()}
            iconName={ICONS.ARROW_RIGHT}
            disabled={currentStepValidation === VALIDATION_STATUS.INVALID}
            loading={submitRequestStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
      )}
    </footer>
  );
};

export default Footer;
