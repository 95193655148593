import i18n from 'i18n';
import moment from 'moment';

import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  SNOWFLAKE_TABLES,
} from 'constants/requestBody';
import { CostByNameType, MonthlyCostType } from 'types/dataTypes';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { getSelectedDashboardConnectionName } from 'utils/dashboardUtils';
import {
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import {
  getCostByNamePerMonthExcelData,
  getCostTrendExcelData,
} from '../SnowflakeDashboardComponents/utils';
import {
  TopAccountsByCostExportColumns,
  TopWarehousesByCostExportColumns,
} from './constants';

/**
 * @function getWarehouseCostTrendQuery
 * @description Function to construct the warehouse cost trend query
 * @param integrationId integration Id of the selected snowflake dashboard
 * @param dateRange date range selected
 * @returns Object containing the request query.
 */
export const getWarehouseCostTrendQuery = (
  integrationId: string,
  dateRange: string[]
) => {
  return {
    snowflakeIntegrationId: integrationId,
    queryDto: {
      columns: [
        {
          label: 'month',
          field: QUERY_FIELDS.CONCAT_YEAR_MONTH_USAGE_DATE,
        },
        {
          label: 'cost',
          field: QUERY_FIELDS.CREDITS_BILLED,
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: [
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange[0])
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange[1])
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.SERVICE_TYPE,
              comparator: COMPARATORS.IN,
              value: "('WAREHOUSE_METERING', 'WAREHOUSE_METERING_READER')",
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
      cached: true,
    },
    table: SNOWFLAKE_TABLES.METERING_DAILY_HISTORY,
  };
};

/**
 * @function getCreditsUsedByWarehousePerMonthQuery
 * @description Function to construct the query for credits usage by warehouse by month chart
 * @param integrationId integration Id of the selected snowflake dashboard
 * @param dateRange date range selected
 * @returns Object containing the request query.
 */
export const getCreditsUsedByWarehousePerMonthQuery = (
  integrationId: string,
  dateRange: string[]
) => {
  return {
    snowflakeIntegrationId: integrationId,
    queryDto: {
      columns: [
        {
          label: 'month',
          field: QUERY_FIELDS.CONCAT_YEAR_MONTH_START_TIME,
        },
        {
          label: 'creditsUsed',
          field: QUERY_FIELDS.CREDITS_USED,
        },
        {
          label: 'warehouse',
          field: QUERY_FIELDS.WAREHOUSE_NAME,
        },
      ],
      aggregators: [
        {
          label: 'creditsUsed',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month', 'warehouse'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
        {
          label: 'creditsUsed',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: [
            {
              field: QUERY_FIELDS.START_TIME_CAPS,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange[0])
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: 'AND',
            },
            {
              field: QUERY_FIELDS.START_TIME_CAPS,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange[1])
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
      cached: true,
    },
    table: SNOWFLAKE_TABLES.WAREHOUSE_METERING_HISTORY,
  };
};

/**
 * @function getTopAccountsByComputeCostQuery
 * @description Function to construct the query for top accounts by compute cost chart
 * @param integrationId integration Id of the selected snowflake dashboard
 * @param dateRange date range selected
 * @returns Object containing the request query.
 */
export const getTopAccountsByComputeCostQuery = (
  integrationId: string,
  dateRange: string[]
) => {
  return {
    snowflakeIntegrationId: integrationId,
    queryDto: {
      columns: [
        {
          label: 'usage',
          field: QUERY_FIELDS.CREDITS_BILLED,
        },
        {
          label: 'accountName',
          field: QUERY_FIELDS.ACCOUNT_NAME_CAPS,
        },
      ],
      aggregators: [
        {
          label: 'usage',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['accountName'],
      orderBy: [
        {
          label: 'usage',
          sort: 'DESC',
        },
      ],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: [
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange[0])
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange[1])
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.SERVICE_TYPE,
              comparator: COMPARATORS.IN,
              value: "('WAREHOUSE_METERING', 'WAREHOUSE_METERING_READER')",
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
      cached: true,
    },
    table: SNOWFLAKE_TABLES.METERING_DAILY_HISTORY,
  };
};

/**
 * @function getTopWarehousesByComputeCostQuery
 * @description Function to construct the query for top warehouses by compute cost chart
 * @param integrationId integration Id of the selected snowflake dashboard
 * @param dateRange date range selected
 * @returns Object containing the request query.
 */
export const getTopWarehousesByComputeCostQuery = (
  integrationId: string,
  dateRange: string[]
) => {
  return {
    snowflakeIntegrationId: integrationId,
    queryDto: {
      columns: [
        {
          label: 'usage',
          field: QUERY_FIELDS.CREDITS_USED_COMPUTE,
        },
        {
          label: 'warehouseName',
          field: QUERY_FIELDS.WAREHOUSE_NAME,
        },
      ],
      aggregators: [
        {
          label: 'usage',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['warehouseName'],
      orderBy: [
        {
          label: 'usage',
          sort: 'DESC',
        },
      ],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: [
            {
              field: QUERY_FIELDS.START_TIME_CAPS,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange[0])
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.START_TIME_CAPS,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange[1])
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
      cached: true,
    },
    table: SNOWFLAKE_TABLES.WAREHOUSE_METERING_HISTORY,
  };
};

/**
 * @function getSelectedData
 * @description Function to return the filtered data based on the selected dropdown options
 * @param allData all data
 * @param selectedItems List of selected items
 * @returns List of filtered data
 */
export const getSelectedData = (
  allData: CostByNameType[],
  selectedItems: string[]
) => {
  return allData.filter((item) => selectedItems.includes(item.name));
};

/**
 * @function getTopAccountsByCostExcelData
 * @description Function to return the excel export data for top accounts by cost graph
 * @param accountsByCostData accounts cost data
 * @param dateRange date range
 * @param selectedAccounts selected accounts in the filter
 * @returns Object containing the excel export data.
 */
export const getTopAccountsByCostExcelData = (
  accountsByCostData: CostByNameType[],
  dateRange: string[],
  selectedAccounts: string[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.topAccountsByComputeCost'),
    columns: TopAccountsByCostExportColumns,
    data: insertIndex(accountsByCostData).map((item) => ({
      ...item,
      cost: numberCommaSeparator(item.cost),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.integrationName'),
        value: getSelectedDashboardConnectionName(),
      },
      {
        heading: i18n.t('excelExportLabels.accounts'),
        value: selectedAccounts.join(', '),
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: moment(dateRange[0], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: moment(dateRange[1], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
    ],
  };
};

/**
 * @function getTopWarehousesByCostExcelData
 * @description Function to return the excel export data for top warehouses by cost graph
 * @param warehousesByCostData warehouses cost data
 * @param dateRange date range
 * @param selectedWarehouses selected warehouses in the filter
 * @returns Object containing the excel export data.
 */
export const getTopWarehousesByCostExcelData = (
  warehousesByCostData: CostByNameType[],
  dateRange: string[],
  selectedWarehouses: string[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.topWarehousesByComputeCost'),
    columns: TopWarehousesByCostExportColumns,
    data: insertIndex(warehousesByCostData).map((item) => ({
      ...item,
      cost: numberCommaSeparator(item.cost),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.integrationName'),
        value: getSelectedDashboardConnectionName(),
      },
      {
        heading: i18n.t('snowflakeDashboards.warehouses'),
        value: selectedWarehouses.join(', '),
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: moment(dateRange[0], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: moment(dateRange[1], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
    ],
  };
};

/**
 * @function getSnowflakeWarehouseExcelData
 * @description Function to return the excel export data for snowflake warehouse dashboard
 * @param costTrend Cost trend graph details
 * @param creditUsageByWarehouse Credit usage by warehouse graph details
 * @param topAccounts Top accounts by cost graph details
 * @param topWarehouses Top warehouse by cost graph details
 * @returns List containing the excel export data.
 */
export const getSnowflakeWarehouseExcelData = (
  costTrend: {
    data: MonthlyCostType[];
    dateRange: string[];
  },
  creditUsageByWarehouse: {
    data: any[];
    dateRange: string[];
  },
  topAccounts: {
    data: CostByNameType[];
    dateRange: string[];
    selectedAccounts: string[];
  },
  topWarehouses: {
    data: CostByNameType[];
    dateRange: string[];
    selectedWarehouses: string[];
  }
) => {
  return [
    getCostTrendExcelData(costTrend.data, costTrend.dateRange),
    getCostByNamePerMonthExcelData(
      i18n.t('graphHeadings.creditUsageByWarehousePerMonth'),
      creditUsageByWarehouse.data,
      creditUsageByWarehouse.dateRange
    ),
    getTopAccountsByCostExcelData(
      topAccounts.data,
      topAccounts.dateRange,
      topAccounts.selectedAccounts
    ),
    getTopWarehousesByCostExcelData(
      topWarehouses.data,
      topWarehouses.dateRange,
      topWarehouses.selectedWarehouses
    ),
  ];
};
