import { PROVIDER } from 'constants/cloudProviders';

export enum DASHBOARD {
  COST_SUMMARY = 'COST_SUMMARY',
  CREDIT_SUMMARY = 'CREDIT_SUMMARY',
  COST_RECOMMENDATION_SUMMARY = 'COST_RECOMMENDATION_SUMMARY',
  PROJECT_DEEP_DIVE = 'PROJECT_DEEP_DIVE',
  CARBON_FOOTPRINT = 'CARBON_FOOTPRINT',
  NEW_VIEW = 'NEW_VIEW',
  CONSOLIDATED_COST_SUMMARY = 'CONSOLIDATED_COST_SUMMARY',
  SNOWFLAKE_WAREHOUSE = 'SNOWFLAKE_WAREHOUSE',
  SNOWFLAKE_STORAGE = 'SNOWFLAKE_STORAGE',
  COMPUTE = 'COMPUTE',
  STORAGE = 'STORAGE',
  DATABASE = 'DATABASE',
}

const GCP_DASHBOARD_NAVIGATION_DATA = [
  {
    id: DASHBOARD.COST_SUMMARY,
    title: 'costSummary',
  },
  {
    id: DASHBOARD.CREDIT_SUMMARY,
    title: 'creditSummary',
  },
  {
    id: DASHBOARD.COST_RECOMMENDATION_SUMMARY,
    title: 'costRecommendationSummary',
  },
  {
    id: DASHBOARD.PROJECT_DEEP_DIVE,
    title: 'projectDeepDive',
  },
  {
    id: DASHBOARD.CARBON_FOOTPRINT,
    title: 'carbonFootprint',
  },
  {
    id: DASHBOARD.COMPUTE,
    title: 'compute',
  },
  {
    id: DASHBOARD.STORAGE,
    title: 'storage',
  },
  {
    id: DASHBOARD.DATABASE,
    title: 'database',
  },
];

const AWS_DASHBOARD_NAVIGATION_DATA = [
  {
    id: DASHBOARD.COST_SUMMARY,
    title: 'costSummary',
  },
  {
    id: DASHBOARD.CREDIT_SUMMARY,
    title: 'creditSummary',
  },
  {
    id: DASHBOARD.COST_RECOMMENDATION_SUMMARY,
    title: 'costRecommendationSummary',
  },
  {
    id: DASHBOARD.PROJECT_DEEP_DIVE,
    title: 'accountDeepDive',
  },
  {
    id: DASHBOARD.COMPUTE,
    title: 'compute',
  },
  {
    id: DASHBOARD.STORAGE,
    title: 'storage',
  },
  {
    id: DASHBOARD.DATABASE,
    title: 'database',
  },
];

const AZURE_DASHBOARD_NAVIGATION_DATA = [
  {
    id: DASHBOARD.COST_SUMMARY,
    title: 'costSummary',
  },
  {
    id: DASHBOARD.COST_RECOMMENDATION_SUMMARY,
    title: 'costRecommendationSummary',
  },
  {
    id: DASHBOARD.PROJECT_DEEP_DIVE,
    title: 'resourceGroupDeepDive',
  },
  {
    id: DASHBOARD.COMPUTE,
    title: 'compute',
  },
  {
    id: DASHBOARD.STORAGE,
    title: 'storage',
  },
  {
    id: DASHBOARD.DATABASE,
    title: 'database',
  },
];

const OCI_DASHBOARD_NAVIGATION_DATA = [
  {
    id: DASHBOARD.COST_SUMMARY,
    title: 'costSummary',
  },
  {
    id: DASHBOARD.PROJECT_DEEP_DIVE,
    title: 'compartmentDeepDive',
  },
];

export const CONSOLIDATED_DASHBOARD_NAVIGATION_DATA = [
  {
    id: DASHBOARD.CONSOLIDATED_COST_SUMMARY,
    title: 'consolidatedCostSummary',
  },
];

export const SNOWFLAKE_DASHBOARD_NAVIGATION = [
  {
    id: DASHBOARD.SNOWFLAKE_WAREHOUSE,
    title: 'warehouse',
  },
  {
    id: DASHBOARD.SNOWFLAKE_STORAGE,
    title: 'storage',
  },
];

export const getDashboardNavigationMenuByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AWS:
      return AWS_DASHBOARD_NAVIGATION_DATA;
    case PROVIDER.GCP:
      return GCP_DASHBOARD_NAVIGATION_DATA;
    case PROVIDER.AZURE:
      return AZURE_DASHBOARD_NAVIGATION_DATA;
    case PROVIDER.OCI:
      return OCI_DASHBOARD_NAVIGATION_DATA;
    default:
      return [];
  }
};
export enum CUSTOM_NAVIGATION_MENU_DROPDOWN_IDS {
  DELETE = 'DELETE',
  DEVELOPER_MODE = 'DEVELOPER_MODE',
}

export const CUSTOM_NAVIGATION_MENU_DROPDOWN = [
  {
    id: CUSTOM_NAVIGATION_MENU_DROPDOWN_IDS.DEVELOPER_MODE,
    label: 'developerMode',
  },
  {
    id: CUSTOM_NAVIGATION_MENU_DROPDOWN_IDS.DELETE,
    label: 'delete',
  },
];
