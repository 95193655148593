import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/Loader';
import { validateStringLengthLessThan } from 'utils/validations';
import {
  FREE_TEXT_CHARACTER_LIMIT,
  MAX_CHARACTER_LIMIT,
} from 'constants/validation';
import {
  cloudConnection,
  setAccessDetails,
  setCurrentConnection,
  setCurrentStepValidation,
  setResetStepForm,
} from 'redux/cloudConnectionSlice';
import { fetchConnectionById } from 'utils/services';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  ACCESS_DATA_VALUES,
  SECRET_KEY_PLACEHOLDER,
  VALIDATION_STATUS,
} from 'pages/ConnectingCSPPage/constants';
import { onApiCallError } from 'utils/handleErrors';

import AccessDetails from './components/AccessDetails';
import BillingDetails from './components/BillingDetails';
import { RecommendationDetails } from './components/RecommendationDetails';
import { CarbonFootprintDetails } from './components/CarbonFootprintDetails';
import BasicDetails from '../BasicDetails';

const AzureConnectionForm = () => {
  const dispatch = useDispatch();
  const {
    currentConnection,
    isEditConnection,
    accessDetails,
    currentStep,
    isTryAgain,
    resetStepForm,
  } = useSelector(cloudConnection);

  const [isDuplicateConnectionName, setIsDuplicateConnectionName] =
    useState(true);
  const [connectionName, setConnectionName] = useState('');
  const [
    fetchConnectionDataRequestStatus,
    setFetchConnectionDataRequestStatus,
  ] = useState('');

  useEffect(() => {
    if (isEditConnection || isTryAgain) {
      setIsDuplicateConnectionName(false);
      setProviderInitialValues();
      return;
    }

    dispatch(
      setCurrentConnection({
        ...currentConnection,
        migrated: false,
        wantBilling: true,
        wantRecommendations: true,
        wantCarbonFootprint: true,
      })
    );
  }, [isEditConnection, isTryAgain]);

  useEffect(() => {
    if (resetStepForm) {
      switch (currentStep) {
        case 1:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              dataSourceType: '',
              displayName: '',
              name: '',
              description: '',
              planType: '',
            })
          );
          break;
        case 2:
          if (!isEditConnection) {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                azureTenantId: '',
                azureSubscriptionId: '',
                azureAppId: '',
                azureClientSecret: '',
              })
            );
            dispatch(setAccessDetails([]));
          }
          break;
        case 3:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantBilling: false,
              azureSynapseWorkspaceName: '',
              azureDatabase: '',
              azureBillingTable: '',
            })
          );
          break;
        case 4:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantRecommendations: false,
              azureRecommendationDataset: '',
              azureRecommendationDatasetTable: '',
            })
          );
          break;
        case 5:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantCarbonFootprint: false,
              azureCarbonFootprintDataset: '',
              azureCarbonFootprintDatasetTable: '',
            })
          );
          break;
      }
      dispatch(setResetStepForm(false));
    }
  }, [resetStepForm]);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        dispatch(setCurrentStepValidation(validateBasicDetailsFields()));
        break;
      case 2:
        dispatch(setCurrentStepValidation(validateAccessDetailsFields()));
        break;
      case 3:
        dispatch(setCurrentStepValidation(validateBillingDetailsFields()));
        break;
      case 4:
        dispatch(
          setCurrentStepValidation(validateRecommendationDetailsFields())
        );
        break;
      case 5:
        dispatch(
          setCurrentStepValidation(validateCarbonFootprintDetailsFields())
        );
        break;
      default:
        dispatch(setCurrentStepValidation(VALIDATION_STATUS.INVALID));
        break;
    }
  }, [
    currentStep,
    currentConnection,
    isDuplicateConnectionName,
    accessDetails,
  ]);

  /**
   * @function setProviderInitialValues
   * @description Function to set initial values from the edit page
   */
  const setProviderInitialValues = () => {
    setFetchConnectionDataRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchConnectionById(currentConnection?.connectorId)
      .then((res: any) => {
        const connectionDetails = res?.data?.responseData;
        dispatch(
          setCurrentConnection({
            ...connectionDetails,
            azureSubscriptionId: isEditConnection
              ? SECRET_KEY_PLACEHOLDER
              : connectionDetails?.azureSubId,
            azureTenantId: isEditConnection
              ? SECRET_KEY_PLACEHOLDER
              : connectionDetails?.azureTenantId,
            azureAppId: isEditConnection
              ? SECRET_KEY_PLACEHOLDER
              : connectionDetails?.azureClientId,
            azureClientSecret: isEditConnection
              ? SECRET_KEY_PLACEHOLDER
              : connectionDetails?.azureClientSecret,
            azureDatabase: connectionDetails?.billingDataset,
            azureSynapseWorkspaceName: connectionDetails?.azureSynapseWorkspace,
            azureBillingTable: connectionDetails?.billingTableName,
            azureRecommendations: connectionDetails?.wantRecommendations,
            azureRecommendationDataset:
              connectionDetails?.recommendationDataset,
            azureRecommendationDatasetTable:
              connectionDetails?.recommendationsTableName,
            azureCarbonFootprint: connectionDetails?.wantCarbonFootprint,
          })
        );
        setConnectionName(connectionDetails.displayName);
        setFetchConnectionDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setFetchConnectionDataRequestStatus);
      });
  };

  /**
   * @function validateBasicDetailsFields
   * @description Function to validate basic details fields
   * @return boolean true if validation is success else false
   */
  const validateBasicDetailsFields = () => {
    if (
      currentConnection?.displayName &&
      !validateStringLengthLessThan(
        currentConnection?.displayName,
        MAX_CHARACTER_LIMIT
      ) &&
      ((isEditConnection &&
        currentConnection?.displayName === connectionName) ||
        !isDuplicateConnectionName) &&
      currentConnection?.dataSourceType &&
      !validateStringLengthLessThan(
        currentConnection?.description,
        FREE_TEXT_CHARACTER_LIMIT
      ) &&
      currentConnection.planType
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateAccessDetailsFields
   * @description Function to validate access details fields
   * @return boolean true if validation is success else false
   */
  const validateAccessDetailsFields = () => {
    if (!validateBasicDetailsFields()) {
      return VALIDATION_STATUS.INVALID;
    }

    if (
      currentConnection?.azureTenantId &&
      currentConnection?.azureSubscriptionId &&
      currentConnection?.azureAppId &&
      currentConnection?.azureClientSecret &&
      (isEditConnection ||
        accessDetails.find(
          (value) => value.role === ACCESS_DATA_VALUES.HAS_BILLING_ACCESS
        )?.haveAccess)
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateBillingDetailsFields
   * @description Function to validate access details fields
   * @return boolean true if validation is success else false
   */
  const validateBillingDetailsFields = () => {
    if (
      currentConnection?.azureSynapseWorkspaceName &&
      currentConnection?.azureDatabase &&
      currentConnection?.azureBillingTable
    )
      return VALIDATION_STATUS.VALID;

    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateRecommendationDetailsFields
   * @description Function to validate access details fields
   * @return boolean true if validation is success else false
   */
  const validateRecommendationDetailsFields = () => {
    if (
      !currentConnection?.azureRecommendationDataset ||
      !currentConnection?.azureRecommendationDatasetTable
    )
      return VALIDATION_STATUS.SKIP;
    else if (
      currentConnection?.azureRecommendationDataset &&
      currentConnection?.azureRecommendationDatasetTable
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateCarbonFootprintDetailsFields
   * @description Function to validate access details fields
   * @return boolean true if validation is success else false
   */
  const validateCarbonFootprintDetailsFields = () => {
    if (
      !currentConnection?.azureCarbonFootprintDataset ||
      !currentConnection?.azureCarbonFootprintDatasetTable
    )
      return VALIDATION_STATUS.SKIP;
    else if (
      currentConnection?.azureCarbonFootprintDataset &&
      currentConnection?.azureCarbonFootprintDatasetTable
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  const getFormByStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicDetails
            setIsDuplicateConnectionName={setIsDuplicateConnectionName}
            connectionName={connectionName}
          />
        );
      case 2:
        return <AccessDetails />;
      case 3:
        return <BillingDetails />;
      case 4:
        return <RecommendationDetails />;
      case 5:
        return <CarbonFootprintDetails />;
    }
  };

  if (fetchConnectionDataRequestStatus === REQUEST_STATUS.PROCESSING) {
    return <Loader />;
  }

  return (
    <div
      className="cloud-connection-form flex-fit"
      data-testid="azure-connection-form"
    >
      {getFormByStep()}
    </div>
  );
};

export default AzureConnectionForm;
