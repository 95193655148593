import './index.scss';

type ConnectionFormCardProps = {
  children: React.ReactNode;
};

const ConnectionFormCard = ({ children }: ConnectionFormCardProps) => {
  return (
    <div className="gprofiler-connection-form flex flex-column flex-align-items-center new-styled-scroll">
      {children}
    </div>
  );
};

export default ConnectionFormCard;
