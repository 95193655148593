import { MutableRefObject, useEffect, useRef } from 'react';
import { Divider, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectChatBot } from 'redux/chatBotSlice';
import Icon from 'components/Icon';
import { Sender } from 'components/ChatBoxModal/constants';
import { ICONS } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ChatBot, InstancesWithoutLogo } from 'assets/icons';

import './index.scss';

type ChatConversationsProps = {
  fetchResponseReqStatus: string;
  inputRef: MutableRefObject<any>;
  profileImage: any;
  canSetInputFocus: boolean;
  dataTestId?: string;
};

const ChatConversations = ({
  fetchResponseReqStatus,
  inputRef,
  profileImage,
  canSetInputFocus,
  dataTestId,
}: ChatConversationsProps) => {
  const { t } = useTranslation();
  const { chats, selectedChatbotProvider } = useSelector(selectChatBot);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    moveScrollToView();
  }, [chats]);

  const moveScrollToView = () => {
    if (canSetInputFocus) {
      setTimeout(() => inputRef?.current?.focus());
    }
    lastMessageRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  /**
   * @function getSubmodelChangeContent
   * @description Function to to construct the model change message element
   * @param submodelName name of the sub model that is set to
   * @returns JSX element containing the message that the model is set.
   */
  const getSubmodelChangeContent = (submodelName: string | undefined) => (
    <div className="model-change-content flex flex-center flex-gap-8">
      <Divider type="horizontal" />
      <span className="font-tooltip-small">
        {t('chatBotLabel.modelHasBeenSetTo', {
          submodel: submodelName,
        })}
      </span>
      <Divider type="horizontal" />
    </div>
  );

  return (
    <div
      className="chat-conversations flex flex-column flex-gap-16"
      data-testid={dataTestId}
    >
      {chats?.map((chat, index) => (
        <div className="flex flex-column" key={chat.key}>
          {(chats.at(index - 1)?.submodel_name !== chat.submodel_name ||
            index === 0) &&
            getSubmodelChangeContent(chat.submodel_name)}
          <div
            className={`chat-content flex ${
              chat.sender === Sender.USER ? 'user flex-end' : 'bot'
            }`}
            ref={
              index === chats.length - 1 &&
              fetchResponseReqStatus === REQUEST_STATUS.SUCCESS
                ? lastMessageRef
                : undefined
            }
          >
            <div className="chat flex flex-gap-4">
              {chat.sender === Sender.BOT &&
                (InstancesWithoutLogo.includes(window.location.hostname) ? (
                  <Icon
                    iconName={ICONS.ROBOT_2_LINE}
                    className="bot-icon"
                    data-testid="chat-bot-icon"
                  />
                ) : (
                  <ChatBot className="bot-icon" data-testid="chat-bot-icon" />
                ))}
              <div className="chat-message">{chat.message}</div>
              {chat.sender === Sender.USER && (
                <img
                  src={profileImage}
                  alt="profileImage"
                  className="profile-icon"
                />
              )}
            </div>
          </div>
        </div>
      ))}
      {chats.length > 0 &&
        chats[chats.length - 1].submodel_name !==
          selectedChatbotProvider?.submodel_name &&
        getSubmodelChangeContent(selectedChatbotProvider?.submodel_name)}
      {fetchResponseReqStatus === REQUEST_STATUS.PROCESSING && (
        <div className="chat-content bot flex" ref={lastMessageRef}>
          <div className="chat full-width flex flex-gap-4">
            {InstancesWithoutLogo.includes(window.location.hostname) ? (
              <Icon iconName={ICONS.ROBOT_2_LINE} className="bot-icon" />
            ) : (
              <ChatBot className="bot-icon" />
            )}
            <div className="chat-message full-width">
              <Skeleton
                rootClassName="loading-skeleton"
                active
                paragraph={{ rows: 2 }}
                title={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatConversations;
