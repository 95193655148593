import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTicketIntegrationData,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';
import { Radio, message } from 'antd';
import DrawerComponent from 'components/DrawerComponent';
import Button from 'components/Button';
import SelectDropdown from 'components/Select';
import { FormLabel } from 'components/FormLabel';
import { BUTTON_TYPE } from 'constants/appearance';
import { getValidationStyle, validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';

import {
  getTicketConnection,
  getAssigneeGroups,
  getAssignToList,
  getCategories,
} from 'utils/services';
import {
  INCIDENT_TYPE_OPTIONS,
  INCIDENT_TYPES,
  DefaultFormData,
  InitialServiceNowData,
} from '../../constants';
import { ServiceNowDataType, OptimizationTableType } from '../../type';
import { createIncidentTicket } from '../../services';
import './index.scss';

type CreateTicketModalProps = {
  show: boolean;
  setShow: (value: boolean) => void;
  items: number;
  serviceList: OptimizationTableType[];
  serviceName: string | undefined;
  impact: string | null;
};

const CreateTicketModal = ({
  show,
  setShow,
  items,
  serviceList,
  serviceName,
  impact,
}: CreateTicketModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ticketIntegrationData } = useSelector(ticketIntegration);

  const [checkValue, setCheckValue] = useState<string>(
    INCIDENT_TYPES.BULK_INCIDENT
  );
  const [assignmentGroups, setAssignmentGroups] = useState<string[]>([]);
  const [assignmentToList, setAssignmentToList] = useState<
    { email: string; name: string }[]
  >([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [
    assignmentGroupValidationMessage,
    setAssignmentGroupValidationMessage,
  ] = useState('');
  const [assignedToValidationMessage, setAssignedToValidationMessage] =
    useState('');
  const [categoryValidationMessage, setCategoryValidationMessage] =
    useState('');
  const [assignmentGroupsLoading, setAssignmentGroupsLoading] =
    useState<string>(REQUEST_STATUS.SUCCESS);
  const [assigneeToListLoading, setAssigneeToListLoading] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );
  const [categoriesLoading, setCategoriesLoading] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );
  const [createLoading, setCreateLoading] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );
  const [serviceNowData, setServiceNowData] = useState<ServiceNowDataType>(
    InitialServiceNowData
  );

  useEffect(() => {
    return () => {
      setCategoryValidationMessage('');
      setAssignedToValidationMessage('');
      setAssignmentGroupValidationMessage('');
      setCheckValue(INCIDENT_TYPES.BULK_INCIDENT);
    };
  }, [show]);

  useEffect(() => {
    fetchServiceNowConnection();
  }, [show]);

  useEffect(() => {
    if (
      ticketIntegrationData.url &&
      ticketIntegrationData.usernameHost &&
      ticketIntegrationData.password
    ) {
      getAllGroups();
      getAllAssignees();
      getAllCategories();
      setServiceNowData({
        ...serviceNowData,
        assignmentGroup: ticketIntegrationData.defaultAssignmentGroup,
        assignedTo: ticketIntegrationData.defaultAssignedTo,
        category: ticketIntegrationData.category,
      });
    }
  }, [ticketIntegrationData]);

  const fetchServiceNowConnection = () => {
    getTicketConnection()
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res.data.responseData;
          dispatch(
            setTicketIntegrationData({
              url: data.endpoint,
              usernameHost: data.username,
              password: data.password,
              setDefault: data.default,
              defaultAssignmentGroup: data.defaultAssigneeGroup,
              defaultAssignedTo: data.defaultAssignTo,
              category: data.defaultCategory,
            })
          );
          return;
        }
        dispatch(setTicketIntegrationData(DefaultFormData));
      })
      .catch(() => {
        dispatch(setTicketIntegrationData(DefaultFormData));
      });
  };

  /**
   * @function getAllGroups
   * @description Function to fetch all the service now groups.
   */
  const getAllGroups = () => {
    setAssignmentGroupsLoading(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
    };

    getAssigneeGroups(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setAssignmentGroups(res.data.responseData);
          setAssignmentGroupsLoading(REQUEST_STATUS.SUCCESS);
          return;
        }
        setAssignmentGroupsLoading(REQUEST_STATUS.ERROR);
        setAssignmentGroups([]);
      })
      .catch(() => {
        setAssignmentGroupsLoading(REQUEST_STATUS.ERROR);
        setAssignmentGroups([]);
      });
  };

  /**
   * @function getAllCategories
   * @description Function to fetch all the categories
   */
  const getAllCategories = () => {
    setCategoriesLoading(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
    };

    getCategories(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setCategoriesLoading(REQUEST_STATUS.SUCCESS);
          setCategories(res.data.responseData);
          return;
        }

        setCategoriesLoading(REQUEST_STATUS.ERROR);
        setCategories([]);
      })
      .catch(() => {
        setCategoriesLoading(REQUEST_STATUS.ERROR);
        setCategories([]);
      });
  };

  /**
   * @function getAllAssignees
   * @description Function to fetch all the assignees list.
   */
  const getAllAssignees = () => {
    setAssigneeToListLoading(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
    };

    getAssignToList(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setAssigneeToListLoading(REQUEST_STATUS.SUCCESS);
          setAssignmentToList(res.data.responseData);
          return;
        }

        setAssigneeToListLoading(REQUEST_STATUS.ERROR);
        setAssignmentToList([]);
      })
      .catch(() => {
        setAssigneeToListLoading(REQUEST_STATUS.ERROR);
        setAssignmentToList([]);
      });
  };

  const createTicket = () => {
    setCreateLoading(REQUEST_STATUS.PROCESSING);
    const isBulkTicket = checkValue === INCIDENT_TYPES.BULK_INCIDENT;

    const requestBody = {
      serviceName,
      bulkTicket: items === 1 ? false : isBulkTicket,
      multipleTicket: items === 1 ? false : !isBulkTicket,
      assignTo: serviceNowData.assignedTo,
      category: serviceNowData.category,
      gprofDetails: serviceList.map((service) => ({
        name: service.name,
        description: service.description,
        category: service.category,
        impact,
        effort: service.effort,
        resolution: service.resolution,
      })),
    };

    createIncidentTicket(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setCreateLoading(REQUEST_STATUS.SUCCESS);
          message.success(
            t('gprofilerInsightsDashboard.createTicket.ticketCreateSuccess')
          );
          setShow(false);
          return;
        }
        setCreateLoading(REQUEST_STATUS.ERROR);
        message.error(
          t('gprofilerInsightsDashboard.createTicket.errorCreateTicket')
        );
      })
      .catch(() => {
        setCreateLoading(REQUEST_STATUS.ERROR);
        message.error(
          t('gprofilerInsightsDashboard.createTicket.errorCreateTicket')
        );
      });
  };

  /**
   * @function getDropdown
   * @description Function to construct options list for dropdown.
   * @param data data for options.
   * @returns List of dropdown options
   */
  const getDropdown = (data: string[]) =>
    data?.map((item) => ({
      value: item,
      label: item,
    }));

  return (
    <DrawerComponent
      className="gprofiler-create-ticket"
      width={486}
      open={show}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-align-item-center flex-end">
          <Button
            title={t('gprofilerInsightsDashboard.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={t('gprofilerInsightsDashboard.create')}
            onClick={() => {
              createTicket();
            }}
            disabled={
              !serviceNowData.assignmentGroup ||
              !serviceNowData.category ||
              !serviceNowData.assignedTo
            }
            loading={createLoading === REQUEST_STATUS.PROCESSING}
          />
        </div>
      }
    >
      <div className="create-ticket-container flex flex-column flex-gap-16">
        <header>
          <div className="title form-header">
            {t('gprofilerInsightsDashboard.createTicket.createSnowIncident')}
          </div>
          <span className="sub-title font-caption">
            {items === 1
              ? t('gprofilerInsightsDashboard.createTicket.singleItem')
              : t('gprofilerInsightsDashboard.createTicket.multiItem', {
                  items,
                })}
          </span>
        </header>
        {items > 1 && (
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('gprofilerInsightsDashboard.createTicket.incidentType')}
              required
            />
            <Radio.Group
              className="flex flex-column"
              value={checkValue}
              onChange={(e: any) => {
                setCheckValue(e.target.value);
              }}
            >
              {INCIDENT_TYPE_OPTIONS.map((incident) => (
                <Radio value={incident.type} key={incident.type}>
                  {t(
                    `gprofilerInsightsDashboard.createTicket.${incident.title}`
                  )}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        )}
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('gprofilerInsightsDashboard.createTicket.assignmentGroup')}
            required={true}
          />
          <SelectDropdown
            showSearch
            value={serviceNowData.assignmentGroup}
            placeholder={t(
              'gprofilerInsightsDashboard.createTicket.defaultAssignmentGroupPlaceholder'
            )}
            options={getDropdown(assignmentGroups)}
            onChange={(value: any) => {
              validateEmptyField(
                value,
                t('gprofilerInsightsDashboard.createTicket.assignmentGroup'),
                setAssignmentGroupValidationMessage
              );
              setServiceNowData({
                ...serviceNowData,
                assignmentGroup: value,
              });
            }}
            onBlur={() => {
              validateEmptyField(
                serviceNowData.assignmentGroup,
                t('gprofilerInsightsDashboard.createTicket.assignmentGroup'),
                setAssignmentGroupValidationMessage
              );
            }}
            loading={assignmentGroupsLoading === REQUEST_STATUS.PROCESSING}
            designVersion2
          />
          <span
            style={{
              display: `${getValidationStyle(
                assignmentGroupValidationMessage
              )}`,
            }}
            className="font-validation-error"
          >
            {assignmentGroupValidationMessage}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('gprofilerInsightsDashboard.createTicket.assignedTo')}
            required={true}
          />
          <SelectDropdown
            showSearch
            value={serviceNowData.assignedTo}
            placeholder={t(
              'gprofilerInsightsDashboard.createTicket.defaultAssignedToPlaceholder'
            )}
            options={assignmentToList.map((item) => ({
              value: item.email,
              label: item.name,
            }))}
            onChange={(value: any) => {
              validateEmptyField(
                value,
                t('gprofilerInsightsDashboard.createTicket.assignedTo'),
                setAssignedToValidationMessage
              );
              setServiceNowData({
                ...serviceNowData,
                assignedTo: value,
              });
            }}
            onBlur={() => {
              validateEmptyField(
                serviceNowData.assignedTo,
                t('gprofilerInsightsDashboard.createTicket.assignedTo'),
                setAssignedToValidationMessage
              );
            }}
            loading={assigneeToListLoading === REQUEST_STATUS.PROCESSING}
            designVersion2
          />
          <span
            style={{
              display: `${getValidationStyle(assignedToValidationMessage)}`,
            }}
            className="font-validation-error"
          >
            {assignedToValidationMessage}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('gprofilerInsightsDashboard.createTicket.category')}
            required={true}
          />
          <SelectDropdown
            showSearch
            value={serviceNowData.category}
            placeholder={t(
              'gprofilerInsightsDashboard.createTicket.categoryPlaceholder'
            )}
            options={getDropdown(categories)}
            onChange={(value: any) => {
              validateEmptyField(
                value,
                t('gprofilerInsightsDashboard.createTicket.category'),
                setCategoryValidationMessage
              );
              setServiceNowData({
                ...serviceNowData,
                category: value,
              });
            }}
            onBlur={() => {
              validateEmptyField(
                serviceNowData.category,
                t('gprofilerInsightsDashboard.createTicket.category'),
                setCategoryValidationMessage
              );
            }}
            loading={categoriesLoading === REQUEST_STATUS.PROCESSING}
            designVersion2
          />
          <span
            style={{
              display: `${getValidationStyle(categoryValidationMessage)}`,
            }}
            className="font-validation-error"
          >
            {categoryValidationMessage}
          </span>
        </div>
      </div>
    </DrawerComponent>
  );
};

export default CreateTicketModal;
