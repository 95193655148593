import i18n from 'i18n';

export const PROGRESS_BAR_ITEMS = [
  {
    title: i18n.t('addBudgetAlert.steps.titleFirst'),
    description: i18n.t('addBudgetAlert.steps.descriptionFirst'),
  },
  {
    title: i18n.t('addBudgetAlert.steps.titleSecond'),
    description: i18n.t('addBudgetAlert.steps.descriptionSecond'),
  },
  {
    title: i18n.t('addBudgetAlert.steps.titleThird'),
    description: i18n.t('addBudgetAlert.steps.descriptionThird'),
  },
  {
    title: i18n.t('addBudgetAlert.steps.titleFourth'),
    description: i18n.t('addBudgetAlert.steps.descriptionFourth'),
  },
  {
    title: i18n.t('addBudgetAlert.steps.titleFifth'),
    description: i18n.t('addBudgetAlert.steps.descriptionFifth'),
  },
  {
    title: i18n.t('addBudgetAlert.steps.titleSixth'),
    description: i18n.t('addBudgetAlert.steps.descriptionSixth'),
  },
];
