import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getAllAwsRdsPurchasedRis
 * @description Function to fetch all the AWS RDS Purchased RIs
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAllAwsRdsPurchasedRis = async (params: any) => {
  const requestApi =
    APIS.GET_AWS_RDS_PURCHASED_RIS + params.connectorId + '/' + params.region;

  return ServiceCalls.get(requestApi);
};

/**
 * @function getAwsRdsRiProductDescriptions
 * @description Function to fetch all the AWS RDS RI Product Descriptions
 * @return axios response from GET request
 */
export const getAwsRdsRiProductDescriptions = async () => {
  return ServiceCalls.get(APIS.GET_AWS_RI_RDS_PRODUCT_DESCRIPTION);
};

/**
 * @function getAwsRdsRiInstanceClasses
 * @description Function to fetch all the AWS RDS RI Instance Classes
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsRdsRiInstanceClasses = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_RDS_INSTANCE_CLASSES,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsRdsRiIsMultiAz
 * @description Function to fetch is the AWS RI is Multi AZ
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsRdsRiIsMultiAz = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_RDS_IS_MULTI_AZ,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsRdsRiTerm
 * @description Function to fetch is the AWS RI terms
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsRdsRiTerm = async (requestBody: any, cancelToken: any) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_RDS_TERM,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsRdsRiPaymentOptions
 * @description Function to fetch the AWS RDS Payment Options
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsRdsRiPaymentOptions = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_RDS_PAYMENT_OPTIONS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsRdsRiOfferingDetails
 * @description Function to fetch all the AWS RDS RI Offering Details
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsRdsRiOfferingDetails = async (
  requestBody: any,
  cancelToken?: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_RDS_OFFERING_DETAILS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function purchaseAwsRdsRis
 * @description Function to purchase AWS RDS RI
 * @param requestBody request body
 * @param params request params
 * @return axios response from POST request
 */
export const purchaseAwsRdsRis = async (requestBody: any, params: any) => {
  const requestApi =
    APIS.PURCHASE_AWS_RDS_RIS + params.connectorId + '/' + params.region;
  return ServiceCalls.post(requestApi, requestBody);
};

/**
 * @function getAllAwsEc2PurchasedRis
 * @description Function to fetch all the AWS EC2 Purchased RIs
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAllAwsEc2PurchasedRis = async (params: any) => {
  const requestApi =
    APIS.GET_AWS_EC2_PURCHASED_RIS + params.connectorId + '/' + params.region;

  return ServiceCalls.get(requestApi);
};

/**
 * @function deleteQueuedAwsEc2Ri
 * @description Function to delete the Queued AWS EC2 RI
 * @param params request parameters
 * @return axios response from GET request
 */
export const deleteQueuedAwsEc2Ri = async (params: any) => {
  const requestApi =
    APIS.DELETE_QUEUED_AWS_EC2_RI +
    params.connectorId +
    '/' +
    params.riId +
    '/' +
    params.region;

  return ServiceCalls.get(requestApi, null, params);
};

/**
 * @function getAwsRiPlatforms
 * @description Function to fetch the AWS RI Platforms
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAwsRiPlatforms = async () => {
  return ServiceCalls.get(APIS.GET_AWS_RI_PLATFORMS);
};

/**
 * @function getAwsRiAvailabilityZones
 * @description Function to fetch the AWS RI Availability Zones
 * @param params request parameters
 * @param cancelToken cancel token for request abort
 * @return axios response from GET request
 */
export const getAwsRiAvailabilityZones = async (
  params: any,
  cancelToken: any
) => {
  const requestApi =
    APIS.GET_AWS_RI_AVAILABILITY_ZONES +
    params.connectorId +
    '/' +
    params.region;

  return ServiceCalls.get(requestApi, null, params, cancelToken);
};

/**
 * @function getAwsEc2RiTenancy
 * @description Function to fetch the AWS EC2 RI Tenancy
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsEc2RiTenancy = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_EC2_TENANCY,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsEc2RiOfferingClasses
 * @description Function to fetch the AWS EC2 RI Offering Classes
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsEc2RiOfferingClasses = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_EC2_OFFERING_CLASSES,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsEc2RiPaymentOptions
 * @description Function to fetch the AWS EC2 RI PaymentOptions
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsEc2RiPaymentOptions = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_EC2_PAYMENT_OPTIONS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsRiInstanceTypes
 * @description Function to fetch the AWS EC2 RI Instance types
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsRiInstanceTypes = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_EC2_INSTANCE_TYPES,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsEc2RiOfferingDetails
 * @description Function to fetch all the AWS EC2 RI Offering Details
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsEc2RiOfferingDetails = async (
  requestBody: any,
  cancelToken?: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_EC2_OFFERING_DETAILS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function purchaseAwsEc2Ris
 * @description Function to purchase AWS EC2 RI
 * @param requestBody request body
 * @param params request params
 * @return axios response from POST request
 */
export const purchaseAwsEc2Ris = async (requestBody: any, params: any) => {
  const requestApi =
    APIS.PURCHASE_AWS_EC2_RIS + params.connectorId + '/' + params.region;
  return ServiceCalls.post(requestApi, requestBody);
};

/**
 * @function getAllAwsRedshiftPurchasedRis
 * @description Function to fetch all the AWS Redshift Purchased RIs
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAllAwsRedshiftPurchasedRis = async (params: any) => {
  const requestApi =
    APIS.GET_AWS_REDSHIFT_PURCHASED_RIS +
    params.connectorId +
    '/' +
    params.region;

  return ServiceCalls.get(requestApi);
};

/**
 * @function getAwsRedshiftRiNodeTypes
 * @description Function to get AWS Redshift RI Node Types
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsRedshiftRiNodeTypes = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_REDSHIFT_NODE_TYPES,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsRedshiftRiPaymentOptions
 * @description Function to fetch all the AWS Redshift RI Payment Options
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsRedshiftRiPaymentOptions = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_REDSHIFT_PAYMENT_OPTIONS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsRedshiftRiOfferingDetails
 * @description Function to fetch all the AWS Redshift RI Offering Details
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsRedshiftRiOfferingDetails = async (
  requestBody: any,
  cancelToken?: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_REDSHIFT_OFFERING_DETAILS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function purchaseAwsRedshiftRis
 * @description Function to purchase AWS Redshift RIs
 * @param requestBody request body
 * @param params request params
 * @return axios response from POST request
 */
export const purchaseAwsRedshiftRis = async (requestBody: any) => {
  const requestApi = APIS.PURCHASE_AWS_REDSHIFT_RIS;
  return ServiceCalls.post(requestApi, requestBody);
};

/**
 * @function getAllAwsMemoryDbPurchasedRis
 * @description Function to fetch all the AWS Memory DB Purchased RIs
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAllAwsMemoryDbPurchasedRis = async (params: any) => {
  const requestApi =
    APIS.GET_AWS_MEMORY_DB_PURCHASED_RIS +
    params.connectorId +
    '/' +
    params.region;

  return ServiceCalls.get(requestApi);
};

/**
 * @function getAllAwsOpenSearchPurchasedRis
 * @description Function to fetch all the AWS OpenSearch Purchased RIs
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAllAwsOpenSearchPurchasedRis = async (params: any) => {
  const requestApi =
    APIS.GET_AWS_OPEN_SEARCH_PURCHASED_RIS +
    params.connectorId +
    '/' +
    params.region;

  return ServiceCalls.get(requestApi);
};

/**
 * @function purchaseAwsMemoryDbRis
 * @description Function to purchase AWS memory DB RI
 * @param requestBody request body
 * @param params request params
 * @return axios response from POST request
 */
export const purchaseAwsMemoryDbRis = async (requestBody: any, params: any) => {
  const requestApi =
    APIS.PURCHASE_AWS_MEMORY_DB_RIS + params.connectorId + '/' + params.region;
  return ServiceCalls.post(requestApi, requestBody);
};

/**
 * @function getAwsMemoryDbRiNodeTypes
 * @description Function to fetch all the AWS Memory DB RI Node Types
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsMemoryDbRiNodeTypes = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_MEMORY_DB_NODE_TYPES,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsMemoryDbRiOfferingDetails
 * @description Function to fetch all the AWS Memory DB RI Offering Details
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsMemoryDbRiOfferingDetails = async (
  requestBody: any,
  cancelToken?: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_MEMORY_DB_OFFERING_DETAILS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAllAwsElastiCachePurchasedRis
 * @description Function to fetch all the AWS ElastiCache Purchased RIs
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAllAwsElastiCachePurchasedRis = async (params: any) => {
  const requestApi =
    APIS.GET_AWS_ELASTICACHE_PURCHASED_RIS +
    params.connectorId +
    '/' +
    params.region;

  return ServiceCalls.get(requestApi);
};

/**
 * @function getAwsElasticacheRiNodeTypes
 * @description Function to fetch all the AWS ElastiCache RI Node Types
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsElastiCacheRiNodeTypes = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_ELASTICACHE_NODE_TYPES,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsElastiCacheRiTerms
 * @description Function to fetch all the AWS ElastiCache RI Terms
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsElastiCacheRiTerms = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_ELASTICACHE_TERMS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsElastiCacheRiPaymentOptions
 * @description Function to fetch all the AWS ElastiCache RI Payment Options
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsElastiCacheRiPaymentOptions = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_ELASTICACHE_PAYMENT_OPTIONS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsElastiCacheRiOfferingDetails
 * @description Function to fetch all the AWS ElastiCache RI Offering Details
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsElastiCacheRiOfferingDetails = async (
  requestBody: any,
  cancelToken?: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_RI_ELASTICACHE_OFFERING_DETAILS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function purchaseAwsElastiCacheRis
 * @description Function to purchase AWS ElastiCache RI
 * @param requestBody request body
 * @return axios response from POST request
 */
export const purchaseAwsElastiCacheRis = async (requestBody: any) => {
  return ServiceCalls.post(APIS.PURCHASE_AWS_ELASTICACHE_RIS, requestBody);
};

/**
 * @function getAwsOpenSearchRiInstanceTypes
 * @description Function to fetch the AWS Open Search RI Instance Types
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsOpenSearchRiInstanceTypes = async (
  requestBody: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_OPEN_SEARCH_INSTANCE_TYPES,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function getAwsOpenSearchRiOfferingDetails
 * @description Function to fetch all the AWS Open Search RI Offering Details
 * @param requestBody request body
 * @param cancelToken cancel token for request abort
 * @return axios response from POST request
 */
export const getAwsOpenSearchRiOfferingDetails = async (
  requestBody: any,
  cancelToken?: any
) => {
  return ServiceCalls.post(
    APIS.GET_AWS_OPEN_SEARCH_OFFERING_DETAILS,
    requestBody,
    null,
    {},
    cancelToken
  );
};

/**
 * @function purchaseAwsOpenSearchRis
 * @description Function to purchase AWS Open Search RIs
 * @param requestBody request body
 * @return axios response from POST request
 */
export const purchaseAwsOpenSearchRis = async (requestBody: any) => {
  const requestApi = APIS.PURCHASE_AWS_OPEN_SEARCH_RIS;
  return ServiceCalls.post(requestApi, requestBody);
};

/**
 * @function getAWSRiRecommendations
 * @description Function to fetch all the AWS RI Recommendations
 * @param params request params
 * @return axios response from GET request
 */
export const getAWSRiRecommendations = async (params: any) => {
  return ServiceCalls.get(APIS.AWS_RECOMMENDATION_RESERVATION, null, params);
};

/**
 * @function getAWSSpRecommendations
 * @description Function to fetch all the AWS SP Recommendations
 * @param params request params
 * @return axios response from GET request
 */
export const getAWSSpRecommendations = async (params: any) => {
  return ServiceCalls.get(APIS.AWS_SAVINGS_PLANS_RECOMMENDATION, null, params);
};
