import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CostByProjectDatasetType } from 'types/dataTypes';
import GraphHeader from 'components/GraphHeader';
import ColumnLineChart from 'components/ColumnLineChart';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { getChartExcelExportData } from 'utils/exportToExcel';

import { CostByProjectMixedColumns } from '../../constants';

type CostByProjectMixedProps = {
  costByProjectData: CostByProjectDatasetType[];
  selectedCostByProjectData: CostByProjectDatasetType[];
  requestStatus: string;
  setSelectedCostByProjectData: (val: CostByProjectDatasetType[]) => void;
  isCostByProjectTableView: boolean;
  setIsCostByProjectTableView: (value: boolean) => void;
  pdfView: boolean;
};

const CostByProjectMixed = ({
  costByProjectData,
  selectedCostByProjectData,
  requestStatus,
  setSelectedCostByProjectData,
  setIsCostByProjectTableView,
  isCostByProjectTableView,
  pdfView,
}: CostByProjectMixedProps) => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);
  const { selectedDashboard, expandedGraphName } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [activeLegends, setActiveLegends] = useState<string[]>([
    t('costByProjectMixed.cost', { currencySymbol: currencySymbol }),
    t('costByProjectMixed.trend'),
  ]);

  /**
   * @function isDataIncluded
   * @description Function to check if the the active legends list includes the legend id
   * @param id Id to be validated
   * @returns true if the id is exists, else false
   */
  const isDataIncluded = useCallback(
    (id: string) => {
      return activeLegends.includes(id);
    },
    [activeLegends]
  );

  /**
   * @function getData
   * @description Function to return the data based on the legend selection
   * @returns list of project cost data
   */
  const getData = useCallback(() => {
    const data: any[] = [];
    if (
      isDataIncluded(
        t('costByProjectMixed.cost', { currencySymbol: currencySymbol })
      ) ||
      isDataIncluded(t('costByProjectMixed.trend'))
    ) {
      data.push(
        ...selectedCostByProjectData.map((item) => ({
          ...item,
          type: t('costByProjectMixed.cost', {
            currencySymbol: currencySymbol,
          }),
        }))
      );
    } else {
      data.push({
        month: null,
        cost: null,
        type: t('costByProjectMixed.cost', { currencySymbol: currencySymbol }),
      });
    }

    return data;
  }, [selectedCostByProjectData, activeLegends]);

  /**
   * @function getColumnColor
   * @description Function to return the column color based on the selection
   * @param data data against which the color is fetched
   * @returns string color
   */
  const getColumnColor = useCallback(
    (data: any) => {
      if (!isDataIncluded(data.type)) {
        return 'transparent';
      }

      return theme.primaryColor;
    },
    [activeLegends]
  );

  /**
   * @function getLineColor
   * @description Function to return the line color
   * @returns string color
   */
  const getLineColor = useCallback(() => {
    return !isDataIncluded(t('costByProjectMixed.trend'))
      ? 'transparent'
      : theme.secondaryColor;
  }, [activeLegends]);

  /**
   * @function getLegendOverrides
   * @description Function to return the custom legends
   * @returns list of custom legends
   */
  const getLegendOverrides = useCallback(() => {
    return [
      {
        name: t('costByProjectMixed.cost', { currencySymbol: currencySymbol }),
        unchecked: !isDataIncluded(
          t('costByProjectMixed.cost', { currencySymbol: currencySymbol })
        ),
        marker: {
          symbol: 'square',
          style: {
            fill: theme.primaryColor,
          },
        },
      },
      {
        name: t('costByProjectMixed.trend'),
        unchecked: !isDataIncluded(t('costByProjectMixed.trend')),
        marker: {
          symbol: 'hyphen',
          style: {
            lineWidth: 2,
            stroke: theme.secondaryColor,
          },
        },
      },
    ];
  }, [activeLegends]);

  const projectsDropdown = (
    <div className="filters">
      <GraphFilterDropdown
        allData={costByProjectData}
        selectedData={selectedCostByProjectData}
        setSelectedData={setSelectedCostByProjectData}
        valueSuffix={t('costByProjectMixed.project')}
        fieldName={DROPDOWN_VALUE_FIELDS.PROJECT}
        designVersion2
      />
    </div>
  );

  const getComponent = () =>
    isCostByProjectTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={selectedCostByProjectData.map((value, index) => {
            return { ...value, key: index };
          })}
          columns={CostByProjectMixedColumns}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnLineChart
        data={getData()}
        xField={'project'}
        yField={'cost'}
        xTitle={t('costByProjectMixed.project')}
        yTitle={t('costTrend.costInCurrency', {
          currencySymbol: currencySymbol,
        })}
        groupingField={'type'}
        columnColorOverride={getColumnColor}
        lineColorOverride={getLineColor}
        showFullLabels={
          expandedGraphName === 'cost-by-project-mixed' || pdfView
        }
        legendOverride={getLegendOverrides()}
        prefixSymbol={currencySymbol}
        onClickLegends={(e: any) => {
          setActiveLegends(
            e.gEvent.delegateObject.component.cfg.items
              .filter(
                (item: any) => item.unchecked === undefined || !item.unchecked
              )
              .map((item: any) => item.name)
          );
        }}
      />
    );

  return (
    <div className="graph-card cost-by-project-mixed" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.ytdCostByProject')}
        graphName="cost-by-project-mixed"
        filters={projectsDropdown}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostByProjectTableView}
        setIsTableView={setIsCostByProjectTableView}
        excelData={getChartExcelExportData(
          t('graphHeadings.ytdCostByProject'),
          selectedCostByProjectData,
          CostByProjectMixedColumns,
          {
            connectionName: selectedDashboard!.connectorName,
          }
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default CostByProjectMixed;
