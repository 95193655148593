import { Col, Row } from 'antd';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';

import {
  MonthlyCostType,
  ColouredProviderCostByRegionType,
  CostByServiceFamilyType,
  CostByProviderServiceType,
  ColouredCostByProviderServiceType,
  ColouredCostByProviderResourceType,
  MonthlyCostByNameType,
} from 'types/dataTypes';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getChartData } from 'utils/services';
import {
  generateGraphColors,
  getBillingPeriodDateFormatByProvider,
  getPreviousMonthAndYear,
  getProviderForConnection,
} from 'utils/dashboardUtils';
import {
  getMonthYearShortList,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { getGroupsData } from 'pages/OverviewPage/components/GroupsDashboard/services';
import { CHART_TYPES, GRAPH_OPTIONS } from 'constants/graphConfig';
import { PROVIDER } from 'constants/cloudProviders';
import { getGroupProvider } from 'utils/providerDetails';
import { calculateTotalCostSum } from 'utils/CostSum';
import { onApiCallError } from 'utils/handleErrors';
import { MISCELLANEOUS_FAMILY } from 'constants/graphLabels';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { selectCommonUtility } from 'redux/commonUtilitySlice';

import ConsolidatedCostTrend from './components/CostTrend';
import MonthlyCostByCloudProvider from './components/MonthlyCostByCloudProvider';
import ConsolidatedCloudServices from './components/ConsolidatedCloudServices';
import ConsolidatedRegions from './components/ConsolidatedRegions';
import {
  getAllConnectorIdsInGroup,
  getDefaultLabel,
  getFilteredConnections,
} from './utils/commonUtils';
import {
  getCostByResourceForTrendsRequestBody,
  getCostByServiceForTrendsRequestBody,
  getCostTrendRequestBodyByProvider,
  getCostTrendsData,
  getCostTrendsExportColumns,
  getCostTrendsHeading,
} from './utils/costTrendUtils';
import ConsolidatedComparisonCards from './components/ConsolidatedComparisonCards';
import CostByComputeService from './components/CostByComputeService';
import CostByStorageService from './components/CostByStorageService';
import {
  getCloudServicesRequestBody,
  getCostByComputeServiceRequestBody,
  getCostByStorageServiceRequestBody,
  getMonthlyCostByProviderRequestBody,
} from './utils/monthlyCostByProviderUtils';
import { getRegionsRequestBodyByProvider } from './utils/regionsDataUtil';
import {
  ConsolidatedCloudServicesExportColumns,
  ConsolidatedRegionsExcelExportColumns,
  getConsolidatedYTDCostExportColumns,
  CostByServiceCategoryExportColumns,
} from './constants';
import { getConsolidatedCostSummaryExcelData } from './utils/exportToExcel';

const GCPConsolidatedCostSummaryDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    expandedGraphName,
    tableViewEnabled,
    selectedGroupMetaData,
    selectedDashboard,
    showExpandGraphModal,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [uniqueProviders, setUniqueProviders] = useState<string[]>([]);

  //State for Cost Trend Data
  const [costTrendData, setCostTrendData] = useState<MonthlyCostType[]>([]);
  const [costTrendRequestStatus, setCostTrendRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [costTrendStartMonth, setCostTrendStartMonth] = useState(
    getPreviousMonthAndYear(11)
  );
  const [costTrendEndMonth, setCostTrendEndMonth] = useState(
    getPreviousMonthAndYear(0)
  );

  //State for Monthly Cost By Cloud Provider
  const [monthlyCostByCloudProviderData, setMonthlyCostByCloudProviderData] =
    useState<MonthlyCostByNameType[]>([]);
  const [
    monthlyCostByCloudProviderTableData,
    setMonthlyCostByCloudProviderTableData,
  ] = useState<any[]>([]);
  const [
    ytdCostByCloudProviderRequestStatus,
    setYtdCostByCloudProviderRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [ytdCostByProviderConnections, setYtdCostByProviderConnections] =
    useState<string[]>([]);
  const [costByProviderStartMonth, setCostByProviderStartMonth] = useState(
    getPreviousMonthAndYear(11)
  );
  const [costByProviderEndMonth, setCostByProviderEndMonth] = useState(
    getPreviousMonthAndYear(0)
  );
  const [selectedTrendMonth, setSelectedTrendMonth] = useState('');
  const [selectedTrendService, setSelectedTrendService] = useState<{
    provider: string;
    service: string;
  }>({
    provider: '',
    service: '',
  });
  const [monthWiseServiceData, setMonthWiseServiceData] = useState<{
    [key: string]: ColouredCostByProviderServiceType[];
  }>({});
  const [monthServiceResourceData, setMonthServiceResourceData] = useState<{
    [key: string]: ColouredCostByProviderResourceType[];
  }>({});
  const [costTrendSliderValue, setCostTrendSliderValue] = useState<{
    x: number;
    y: number;
  }>();

  //State for consolidated cloud services
  const [consolidatedCloudServicesData, setConsolidatedCloudServicesData] =
    useState<CostByServiceFamilyType[]>([]);
  const [
    consolidatedCloudServicesRequestStatus,
    setConsolidatedCloudServicesRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [cloudServiceConnections, setCloudServiceConnections] = useState<
    string[]
  >([]);
  const [serviceGroups, setServiceGroups] = useState<{
    [key: string]: string[];
  }>();
  const [consolidatedServicesStartMonth, setConsolidatedServicesStartMonth] =
    useState(getPreviousMonthAndYear(11));
  const [consolidatedServicesEndMonth, setConsolidatedServicesEndMonth] =
    useState(getPreviousMonthAndYear(0));

  // State for cost by compute service
  const [costByComputeServiceData, setCostByComputeServiceData] = useState<
    CostByProviderServiceType[]
  >([]);
  const [
    costByComputeServiceRequestStatus,
    setCostByComputeServiceRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [costByComputeServiceStartMonth, setCostByComputeServiceStartMonth] =
    useState(getPreviousMonthAndYear(11));
  const [costByComputeServiceEndMonth, setCostByComputeServiceEndMonth] =
    useState(getPreviousMonthAndYear(0));

  // State for cost by compute service
  const [costByStorageServiceData, setCostByStorageServiceData] = useState<
    CostByProviderServiceType[]
  >([]);
  const [
    costByStorageServiceRequestStatus,
    setCostByStorageServiceRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [costByStorageServiceStartMonth, setCostByStorageServiceStartMonth] =
    useState(getPreviousMonthAndYear(11));
  const [costByStorageServiceEndMonth, setCostByStorageServiceEndMonth] =
    useState(getPreviousMonthAndYear(0));

  //State for consolidated regions data
  const [consolidatedRegionRequestStatus, setConsolidatedRegionRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [consolidatedRegionsConnections, setConsolidatedRegionsConnections] =
    useState<string[]>([]);
  const [consolidatedRegionsTableData, setConsolidatedRegionsTableData] =
    useState<ColouredProviderCostByRegionType[]>([]);
  const [consolidatedRegionStartMonth, setConsolidatedRegionStartMonth] =
    useState(getPreviousMonthAndYear(11));
  const [consolidatedRegionEndMonth, setConsolidatedRegionEndMonth] = useState(
    getPreviousMonthAndYear(0)
  );

  //States for table view
  const [isCostTrendTableView, setIsCostTrendTableView] = useState(false);
  const [isYtdCostByProviderTableView, setIsYtdCostByProviderTableView] =
    useState(false);
  const [costByComputeServiceTableView, setCostByComputeServiceTableView] =
    useState(false);
  const [costByStorageServiceTableView, setCostByStorageServiceTableView] =
    useState(false);

  useEffect(() => {
    getGroupFamilyData();
  }, []);

  useEffect(() => {
    const connectorIds = getAllConnectorIdsInGroup(
      (selectedGroupMetaData?.connectorDtos ?? []).filter(
        (connection) => connection.wantBilling
      )
    );
    setYtdCostByProviderConnections(connectorIds);
    setCloudServiceConnections(connectorIds);
    setConsolidatedRegionsConnections(connectorIds);

    setUniqueProviders(
      getFilteredConnections(
        (selectedGroupMetaData?.connectorDtos ?? []).filter(
          (connection) => connection.wantBilling
        ),
        connectorIds
      ).reduce((acc: string[], connection) => {
        if (!acc.includes(getProviderForConnection(connection))) {
          acc.push(getProviderForConnection(connection));
        }
        return acc;
      }, [])
    );

    if (selectedGroupMetaData) {
      getRegionsData();
    }
  }, [selectedGroupMetaData]);

  useEffect(() => {
    setCostTrendData([]);
    if (selectedGroupMetaData) {
      getCostTrendData();
    }
  }, [selectedGroupMetaData, costTrendEndMonth, costTrendStartMonth]);

  useEffect(() => {
    setCostTrendSliderValue(undefined);
    if (selectedTrendMonth && !monthWiseServiceData[selectedTrendMonth]) {
      getCostByServiceForTrends();
    }
  }, [selectedTrendMonth]);

  useEffect(() => {
    setCostTrendSliderValue(undefined);
    if (
      selectedTrendMonth &&
      selectedTrendService.service &&
      !monthServiceResourceData[
        `${selectedTrendMonth}${selectedTrendService.provider}${selectedTrendService.service}`
      ]
    ) {
      getCostByResourceForTrends();
    }
  }, [selectedTrendMonth, selectedTrendService]);

  useEffect(() => {
    setMonthlyCostByCloudProviderData([]);
    if (selectedGroupMetaData) {
      getMonthlyCostByCloudProviderData();
    }
  }, [
    selectedGroupMetaData,
    ytdCostByProviderConnections,
    costByProviderStartMonth,
    costByProviderEndMonth,
  ]);

  useEffect(() => {
    setConsolidatedCloudServicesData([]);

    if (selectedGroupMetaData && serviceGroups) {
      getCloudServicesData();
    }
  }, [
    selectedGroupMetaData,
    cloudServiceConnections,
    serviceGroups,
    consolidatedServicesStartMonth,
    consolidatedServicesEndMonth,
  ]);

  useEffect(() => {
    setCostByComputeServiceData([]);

    if (selectedGroupMetaData) {
      getCostByComputeServiceData();
    }
  }, [
    selectedGroupMetaData,
    cloudServiceConnections,
    costByComputeServiceStartMonth,
    costByComputeServiceEndMonth,
  ]);

  useEffect(() => {
    setCostByStorageServiceData([]);

    if (selectedGroupMetaData) {
      getCostByStorageServiceData();
    }
  }, [
    selectedGroupMetaData,
    cloudServiceConnections,
    costByStorageServiceStartMonth,
    costByStorageServiceEndMonth,
  ]);

  useEffect(() => {
    setConsolidatedRegionsTableData([]);

    if (selectedGroupMetaData) {
      getRegionsData();
    }
  }, [
    selectedGroupMetaData,
    consolidatedRegionsConnections,
    consolidatedRegionStartMonth,
    consolidatedRegionEndMonth,
  ]);

  useEffect(() => {
    setIsCostTrendTableView(tableViewEnabled);
    setIsYtdCostByProviderTableView(tableViewEnabled);
    setCostByComputeServiceTableView(tableViewEnabled);
    setCostByStorageServiceTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    if (selectedGroupMetaData) {
      const filteredConnections = (
        selectedGroupMetaData?.connectorDtos || []
      ).filter((connection) => connection.wantBilling);
      dispatch(
        setExportToExcelData(
          getConsolidatedCostSummaryExcelData(
            selectedGroupMetaData.name,
            {
              data: CostTrendTableData,
              columns: getCostTrendsExportColumns(
                selectedTrendMonth,
                selectedTrendService.service
              ),
              filterData: {
                startDate: costTrendStartMonth,
                endDate: costTrendEndMonth,
                selectedTrendMonth: selectedTrendMonth,
                selectedTrendService: selectedTrendService.service,
              },
            },
            {
              data: monthlyCostByCloudProviderTableData,
              providers: uniqueProviders,
              connections: getFilteredConnections(
                filteredConnections,
                ytdCostByProviderConnections
              ).map((connection) => connection.name),
            },
            {
              data: consolidatedCloudServicesData,
              connections: getFilteredConnections(
                filteredConnections,
                cloudServiceConnections
              ).map((connection) => connection.name),
            },
            {
              computeData: costByComputeServiceData,
              storageData: costByStorageServiceData,
              connections: getFilteredConnections(
                filteredConnections,
                cloudServiceConnections
              ).map((connection) => connection.name),
            },
            {
              data: consolidatedRegionsTableData,
              connections: getFilteredConnections(
                filteredConnections,
                cloudServiceConnections
              ).map((connection) => connection.name),
            }
          )
        )
      );
    }
  }, [
    selectedGroupMetaData,
    costTrendData,
    monthlyCostByCloudProviderTableData,
    consolidatedCloudServicesData,
    consolidatedRegionsTableData,
    ytdCostByProviderConnections,
    cloudServiceConnections,
    cloudServiceConnections,
  ]);

  /**
   * @function onChangeCostTrendDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostTrendDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostTrendStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setCostTrendEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  /**
   * @function onChangeCostByProviderDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostByProviderDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostByProviderStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setCostByProviderEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  /**
   * @function onChangeCostByComputeServiceDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostByComputeServiceDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostByComputeServiceStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setCostByComputeServiceEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  /**
   * @function onChangeCostByStorageServiceDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostByStorageServiceDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostByStorageServiceStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setCostByStorageServiceEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  /**
   * @function onChangeConsolidatedServicesDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeConsolidatedServicesDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setConsolidatedServicesStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setConsolidatedServicesEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  /**
   * @function onChangeConsolidatedRegionDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeConsolidatedRegionDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setConsolidatedRegionStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setConsolidatedRegionEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  /**
   * @function getGroupFamilyData
   * @description Function to fetch family group data
   */
  const getGroupFamilyData = () => {
    getGroupsData()
      .then((res: any) => {
        setServiceGroups(res?.data?.responseData);
      })
      .catch((e) => {
        onApiCallError(e, false);
      });
  };

  /**
   * @function addFamilyDataToServiceData
   * @description Function to add family data to service data. If there is no family will add to MISCELLANEOUS_FAMILY type
   * @param serviceData - each service data
   * @returns service data with family type.
   */
  const addFamilyDataToServiceData = (serviceData: CostByServiceFamilyType) => {
    for (const key in serviceGroups) {
      if (serviceGroups[key].includes(serviceData.service)) {
        serviceData.family = key;
        break;
      } else {
        serviceData.family = MISCELLANEOUS_FAMILY;
      }
    }
    return serviceData;
  };

  /**
   * @function getMonthlyCostByCloudProviderData
   * @description Function to set needed data for Monthly Cost by Cloud Provider graph
   */
  const getMonthlyCostByCloudProviderData = () => {
    setYtdCostByCloudProviderRequestStatus(REQUEST_STATUS.PROCESSING);
    axios
      .all(
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          ytdCostByProviderConnections
        ).map((connection) =>
          getChartData(
            getMonthlyCostByProviderRequestBody(
              getProviderForConnection(connection),
              costByProviderStartMonth,
              costByProviderEndMonth,
              connection.migrated
            ),
            connection.connectorId
          )
        )
      )
      .then((res: any) => {
        if (res.some((response: any) => response.status !== 200)) {
          onApiCallError(
            res.data.message,
            false,
            setYtdCostByCloudProviderRequestStatus
          );
          return;
        }

        const labels: string[] = getMonthYearShortList(
          costByProviderStartMonth,
          costByProviderEndMonth,
          MONTH_YEAR_FORMAT
        );

        let monthlyCostByCSP: MonthlyCostByNameType[] = [];
        uniqueProviders.forEach((provider) => {
          labels.forEach((label) => {
            monthlyCostByCSP.push({
              name: provider,
              cost: 0,
              month: label,
            });
          });
        });
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          ytdCostByProviderConnections
        ).forEach((item, index) => {
          res[index]?.data?.forEach((data: any) => {
            const existingIndex = monthlyCostByCSP.findIndex(
              (eachItem) =>
                eachItem.name === getProviderForConnection(item) &&
                eachItem.month ===
                  (getProviderForConnection(item) === PROVIDER.AWS
                    ? moment(data.month, YEAR_MONTH_WITHOUT_ZERO).format(
                        MONTH_YEAR_FORMAT
                      )
                    : moment(data.month, YEAR_MONTH_WITHOUT_SEPARATOR).format(
                        MONTH_YEAR_FORMAT
                      ))
            );
            if (existingIndex >= 0) {
              monthlyCostByCSP[existingIndex].cost += +data?.cost;
            }
          });
        });

        monthlyCostByCSP = monthlyCostByCSP.filter((data) =>
          labels.includes(data.month)
        );
        setMonthlyCostByCloudProviderData(monthlyCostByCSP);

        const tableData: any[] = [];
        labels.forEach((month) => {
          const data = monthlyCostByCSP.filter((item) => item.month === month);
          const row: any = {};
          row.month = month;
          data.forEach((item) => {
            row[item.name] = item.cost;
          });
          tableData.push(row);
        });
        setMonthlyCostByCloudProviderTableData(tableData);

        setYtdCostByCloudProviderRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, false, setYtdCostByCloudProviderRequestStatus)
      );
  };

  /**
   * @function getCloudServicesData
   * @description Function to get monthly consolidated service to cost data
   */
  const getCloudServicesData = () => {
    setConsolidatedCloudServicesRequestStatus(REQUEST_STATUS.PROCESSING);
    axios
      .all(
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          cloudServiceConnections
        ).map((connection) =>
          getChartData(
            getCloudServicesRequestBody(
              getProviderForConnection(connection),
              consolidatedServicesStartMonth,
              consolidatedServicesEndMonth,
              connection.migrated
            ),
            connection.connectorId
          )
        )
      )
      .then((res: any) => {
        if (res.some((response: any) => response.status !== 200)) {
          onApiCallError(
            res.data.message,
            false,
            setConsolidatedCloudServicesRequestStatus
          );
          return;
        }
        const consolidatedServiceData: CostByServiceFamilyType[] = [];
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          cloudServiceConnections
        ).forEach((connection, index) => {
          res[index]?.data?.forEach((serviceData: CostByServiceFamilyType) => {
            serviceData = addFamilyDataToServiceData(serviceData);
            const existingIndex = consolidatedServiceData.findIndex(
              (eachItem) =>
                eachItem.service === getDefaultLabel(serviceData.service)
            );
            if (existingIndex >= 0) {
              consolidatedServiceData[existingIndex].cost += +serviceData?.cost;
            } else {
              consolidatedServiceData.push({
                ...serviceData,
                service: getDefaultLabel(serviceData.service),
                provider: getProviderForConnection(connection),
              });
            }
          });
        });
        setConsolidatedCloudServicesData(consolidatedServiceData);
        setConsolidatedCloudServicesRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, false, setConsolidatedCloudServicesRequestStatus)
      );
  };

  /**
   * @function getCostTrendData
   * @description Function to make an API call to get the monthly cost for the selected dashboard
   */
  const getCostTrendData = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    axios
      .all(
        (selectedGroupMetaData?.connectorDtos ?? [])
          .filter((connection) => connection.wantBilling)
          .map((connection) =>
            getChartData(
              getCostTrendRequestBodyByProvider(
                getProviderForConnection(connection),
                costTrendStartMonth,
                costTrendEndMonth,
                connection.migrated
              ),
              connection.connectorId
            )
          )
      )
      .then((res: any) => {
        if (res.some((response: any) => response.status !== 200)) {
          setCostTrendRequestStatus(REQUEST_STATUS.ERROR);
          return;
        }

        const labels: string[] = getMonthYearShortList(
          costTrendStartMonth,
          costTrendEndMonth
        );
        let costData: number[] = Array(12).fill(0);

        (selectedGroupMetaData?.connectorDtos ?? [])
          .filter((connection) => connection.wantBilling)
          .forEach((connection, index) => {
            res[index]?.data?.forEach((data: MonthlyCostType) => {
              const dataMonth = moment(
                data.month,
                getBillingPeriodDateFormatByProvider(
                  getProviderForConnection(connection)
                )
              ).format(MONTH_YEAR_SHORT);
              let position = labels.indexOf(dataMonth);
              costData[position] += +data.cost;
            });
          });
        setCostTrendData(
          labels.map((label, index) => ({
            month: label,
            cost: costData.at(index) ?? 0,
          }))
        );
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setCostTrendRequestStatus));
  };

  /**
   * @function getCostByServiceForTrends
   * @description Function to fetch the service details for the selected month.
   */
  const getCostByServiceForTrends = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);
    axios
      .all(
        (selectedGroupMetaData?.connectorDtos ?? [])
          .filter((connection) => connection.wantBilling)
          .map((connection) =>
            getChartData(
              getCostByServiceForTrendsRequestBody(
                getProviderForConnection(connection),
                selectedTrendMonth,
                connection.migrated
              ),
              connection.connectorId
            )
          )
      )
      .then((res: any) => {
        if (res.some((response: any) => response.status !== 200)) {
          setCostTrendRequestStatus(REQUEST_STATUS.ERROR);
          return;
        }
        let data: any[] = [];
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          cloudServiceConnections
        ).forEach((connection, index) => {
          res[index]?.data?.forEach((serviceData: any) => {
            const index = data.findIndex(
              (eachItem: any) =>
                eachItem.service === serviceData.service &&
                eachItem.provider === getProviderForConnection(connection)
            );
            if (index === -1)
              data.push({
                service: serviceData.service,
                cost: Number(serviceData.cost),
                provider: getProviderForConnection(connection),
              });
            else data[index].cost += Number(serviceData.cost);
          });
        });

        data = data.filter((item: any) => item.cost > 0) || [];
        data = data.sort((a: any, b: any) => b.cost - a.cost);
        const colors = generateGraphColors(data.length);
        setMonthWiseServiceData({
          ...monthWiseServiceData,
          [selectedTrendMonth]:
            data.map((item: any, index: number) => ({
              ...item,
              service: item.service ?? 'Others',
              color: colors[index],
            })) || [],
        });
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendRequestStatus);
      });
  };

  /**
   * @function getCostByResourceForTrends
   * @description Function to fetch the resource details for the selected month and service.
   */
  const getCostByResourceForTrends = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);
    axios
      .all(
        (selectedGroupMetaData?.connectorDtos ?? [])
          .filter(
            (connection) =>
              connection.wantBilling &&
              getProviderForConnection(connection) ===
                selectedTrendService.provider
          )
          .map((connection) =>
            getChartData(
              getCostByResourceForTrendsRequestBody(
                getProviderForConnection(connection),
                selectedTrendMonth,
                selectedTrendService.service,
                connection.migrated
              ),
              connection.connectorId
            )
          )
      )
      .then((res: any) => {
        if (res.some((response: any) => response.status !== 200)) {
          setCostTrendRequestStatus(REQUEST_STATUS.ERROR);
          return;
        }
        let data: any[] = [];
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          cloudServiceConnections
        ).forEach((connection, index) => {
          res[index]?.data?.forEach((resourceData: any) => {
            const index = data.findIndex(
              (eachItem: any) =>
                eachItem.resource === resourceData.resourceName &&
                eachItem.provider === getProviderForConnection(connection)
            );
            if (index === -1) {
              data.push({
                resource: resourceData.resourceName,
                cost: Number(resourceData.cost),
                provider: getProviderForConnection(connection),
              });
              return;
            }
            data[index].cost += Number(resourceData.cost);
          });
        });

        data = data.filter((item: any) => item.cost > 0) || [];
        data = data.sort((a: any, b: any) => b.cost - a.cost);
        const colors = generateGraphColors(data.length);
        setMonthServiceResourceData({
          ...monthServiceResourceData,
          [`${selectedTrendMonth}${selectedTrendService.provider}${selectedTrendService.service}`]:
            data.map((item: any, index: number) => ({
              ...item,
              resource: item.resource ?? 'Others',
              color: colors[index],
            })) || [],
        });
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendRequestStatus);
      });
  };

  /**
   * @function getCostByComputeServiceData
   * @description Function to get monthly consolidated service to cost data
   */
  const getCostByComputeServiceData = () => {
    setCostByComputeServiceRequestStatus(REQUEST_STATUS.PROCESSING);
    axios
      .all(
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          cloudServiceConnections
        ).map((connection) =>
          getChartData(
            getCostByComputeServiceRequestBody(
              getProviderForConnection(connection),
              costByComputeServiceStartMonth,
              costByComputeServiceEndMonth,
              connection.migrated
            ),
            connection.connectorId
          )
        )
      )
      .then((res: any) => {
        if (res.some((response: any) => response.status !== 200)) {
          onApiCallError(
            res.data.message,
            false,
            setConsolidatedCloudServicesRequestStatus
          );
          return;
        }
        const data: CostByProviderServiceType[] = [];
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          cloudServiceConnections
        ).forEach((connection, index) => {
          res[index]?.data?.forEach((computeServiceData: any) => {
            data.push({
              service: computeServiceData.service,
              cost: computeServiceData.cost,
              provider: getProviderForConnection(connection),
            });
          });
        });
        setCostByComputeServiceData(data);
        setCostByComputeServiceRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, false, setCostByComputeServiceRequestStatus)
      );
  };

  /**
   * @function getCostByStorageServiceData
   * @description Function to get monthly consolidated service to cost data
   */
  const getCostByStorageServiceData = () => {
    setCostByStorageServiceRequestStatus(REQUEST_STATUS.PROCESSING);
    axios
      .all(
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          cloudServiceConnections
        ).map((connection) =>
          getChartData(
            getCostByStorageServiceRequestBody(
              getProviderForConnection(connection),
              costByStorageServiceStartMonth,
              costByStorageServiceEndMonth,
              connection.migrated
            ),
            connection.connectorId
          )
        )
      )
      .then((res: any) => {
        if (res.some((response: any) => response.status !== 200)) {
          onApiCallError(
            res.data.message,
            false,
            setConsolidatedCloudServicesRequestStatus
          );
          return;
        }
        const data: CostByProviderServiceType[] = [];
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          cloudServiceConnections
        ).forEach((connection, index) => {
          res[index]?.data?.forEach((storageServiceData: any) => {
            data.push({
              service: storageServiceData.service,
              cost: storageServiceData.cost,
              provider: getProviderForConnection(connection),
            });
          });
        });
        setCostByStorageServiceData(data);
        setCostByStorageServiceRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, false, setCostByStorageServiceRequestStatus)
      );
  };

  /**
   * @function getRegionsData
   * @description Function to make an API call to get the consolidated regions data of selected group
   */
  const getRegionsData = () => {
    setConsolidatedRegionRequestStatus(REQUEST_STATUS.PROCESSING);
    axios
      .all(
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          consolidatedRegionsConnections
        ).map((connection) =>
          getChartData(
            getRegionsRequestBodyByProvider(
              getProviderForConnection(connection),
              consolidatedRegionStartMonth,
              consolidatedRegionEndMonth,
              connection.migrated
            ),
            connection.connectorId
          )
        )
      )
      .then((res: any) => {
        if (res.some((response: any) => response.status !== 200)) {
          setCostTrendRequestStatus(REQUEST_STATUS.ERROR);
          return;
        }

        const consolidatedRegionData: any[] = [];
        getFilteredConnections(
          (selectedGroupMetaData?.connectorDtos ?? []).filter(
            (connection) => connection.wantBilling
          ),
          consolidatedRegionsConnections
        ).forEach((item, index) => {
          res[index]?.data?.forEach((connectorData: any) => {
            const existingIndex = consolidatedRegionData.findIndex(
              (eachItem: ColouredProviderCostByRegionType) =>
                eachItem.region === getDefaultLabel(connectorData.region)
            );

            if (existingIndex >= 0) {
              consolidatedRegionData[existingIndex].cost +=
                +connectorData?.cost;
            } else {
              consolidatedRegionData.push({
                region: getDefaultLabel(connectorData.region),
                cost: +connectorData.cost,
                provider: getProviderForConnection(item),
              });
            }
          });
        });
        const colors = generateGraphColors(consolidatedRegionData.length);
        const colouredConsolidatedRegionsData = [
          ...consolidatedRegionData.map((item, index) => ({
            ...item,
            color: colors[index],
          })),
        ];
        setConsolidatedRegionsTableData(colouredConsolidatedRegionsData);
        setConsolidatedRegionRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setConsolidatedRegionRequestStatus);
      });
  };

  /**
   * @function getCostTrendLegendsForPdfExport
   * @description Function to fetch the legends list based on the slider position
   * @returns list of legends with color
   */
  const getCostTrendLegendsForPdfExport = () => {
    if (!selectedTrendMonth) {
      return undefined;
    }

    const startIndex = costTrendSliderValue
      ? costTrendSliderValue.x * (CostTrendTableData.length - 1)
      : 0;

    const endIndex = costTrendSliderValue
      ? costTrendSliderValue.y * (CostTrendTableData.length - 1) + 1
      : GRAPH_OPTIONS.sliderCountLimit + 1;

    return CostTrendTableData.slice(startIndex, endIndex).map((data: any) => ({
      name: data.month ?? data.service ?? data.resource ?? '',
      color: data.color ?? '',
    }));
  };

  const getPdfMetaData = () => {
    return {
      viewName: t('dashNav.costSummary'),
      fileName: selectedDashboard?.name!,
      heading: selectedDashboard?.name!,
      subtitle1: selectedDashboard?.groupName!,
      subtitle2: '',
      groupProvider: getGroupProvider(selectedGroupMetaData),
    };
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'cost-trend':
        return (
          <ConsolidatedCostTrend
            monthlyCost={costTrendData}
            costTrendRequestStatus={costTrendRequestStatus}
            setIsCostTrendTableView={setIsCostTrendTableView}
            isCostTrendTableView={isCostTrendTableView}
            pdfView={pdfView}
            costTrendStartMonth={costTrendStartMonth}
            costTrendEndMonth={costTrendEndMonth}
            onChangeCostTrendDateRange={onChangeCostTrendDateRange}
            selectedTrendMonth={selectedTrendMonth}
            setSelectedTrendMonth={setSelectedTrendMonth}
            selectedTrendService={selectedTrendService}
            setSelectedTrendService={setSelectedTrendService}
            serviceData={monthWiseServiceData[selectedTrendMonth] || []}
            resourceData={
              monthServiceResourceData[
                `${selectedTrendMonth}${selectedTrendService.provider}${selectedTrendService.service}`
              ] || []
            }
            sliderValue={costTrendSliderValue}
            setSliderValue={setCostTrendSliderValue}
          />
        );
      case 'monthly-cost-by-cloud-provider':
        return (
          <MonthlyCostByCloudProvider
            requestStatus={ytdCostByCloudProviderRequestStatus}
            isTableView={isYtdCostByProviderTableView}
            setIsTableView={setIsYtdCostByProviderTableView}
            monthlyCostByCloudProvider={monthlyCostByCloudProviderData}
            monthlyCostByCloudProviderTableData={
              monthlyCostByCloudProviderTableData
            }
            providers={uniqueProviders}
            selectedConnections={ytdCostByProviderConnections}
            setSelectedConnections={setYtdCostByProviderConnections}
            pdfView={pdfView}
            costByProviderStartMonth={costByProviderStartMonth}
            costByProviderEndMonth={costByProviderEndMonth}
            onChangeCostByProviderDateRange={onChangeCostByProviderDateRange}
          />
        );
      case 'consolidated-cloud-services':
        return (
          <ConsolidatedCloudServices
            data={consolidatedCloudServicesData}
            requestStatus={consolidatedCloudServicesRequestStatus}
            selectedConnections={cloudServiceConnections}
            setSelectedConnections={setCloudServiceConnections}
            pdfView={pdfView}
            consolidatedServicesStartMonth={consolidatedServicesStartMonth}
            consolidatedServicesEndMonth={consolidatedServicesEndMonth}
            onChangeConsolidatedServicesDateRange={
              onChangeConsolidatedServicesDateRange
            }
          />
        );
      case 'cost-by-compute-service':
        return (
          <CostByComputeService
            requestStatus={costByComputeServiceRequestStatus}
            isTableView={costByComputeServiceTableView}
            setIsTableView={setCostByComputeServiceTableView}
            costByComputeServiceData={costByComputeServiceData}
            selectedConnections={cloudServiceConnections}
            setSelectedConnections={setCloudServiceConnections}
            pdfView={pdfView}
            costByComputeServiceStartMonth={costByComputeServiceStartMonth}
            costByComputeServiceEndMonth={costByComputeServiceEndMonth}
            onChangeCostByComputeServiceDateRange={
              onChangeCostByComputeServiceDateRange
            }
          />
        );
      case 'cost-by-storage-service':
        return (
          <CostByStorageService
            requestStatus={costByStorageServiceRequestStatus}
            isTableView={costByStorageServiceTableView}
            setIsTableView={setCostByStorageServiceTableView}
            costByStorageServiceData={costByStorageServiceData}
            selectedConnections={cloudServiceConnections}
            setSelectedConnections={setCloudServiceConnections}
            pdfView={pdfView}
            costByStorageServiceStartMonth={costByStorageServiceStartMonth}
            costByStorageServiceEndMonth={costByStorageServiceEndMonth}
            onChangeCostByStorageServiceDateRange={
              onChangeCostByStorageServiceDateRange
            }
          />
        );
      case 'consolidated-regions':
        return (
          <ConsolidatedRegions
            requestStatus={consolidatedRegionRequestStatus}
            selectedConnections={consolidatedRegionsConnections}
            setSelectedConnections={setConsolidatedRegionsConnections}
            consolidatedRegionsTableData={consolidatedRegionsTableData}
            pdfView={pdfView}
            consolidatedRegionStartMonth={consolidatedRegionStartMonth}
            consolidatedRegionEndMonth={consolidatedRegionEndMonth}
            onChangeConsolidatedRegionDateRange={
              onChangeConsolidatedRegionDateRange
            }
          />
        );
    }
  };

  const CostTrendTableData =
    getCostTrendsData(selectedTrendMonth, selectedTrendService.service, {
      trends: costTrendData,
      service: monthWiseServiceData[selectedTrendMonth] || [],
      resource:
        monthServiceResourceData[
          `${selectedTrendMonth}${selectedTrendService.provider}${selectedTrendService.service}`
        ] || [],
    }) ?? [];

  return (
    <div className="gcp-consolidated-cost-summary flex flex-column flex-gap-24">
      <div className="cost-cards flex flex-space-between">
        {ConsolidatedComparisonCards()}
      </div>
      {getGraphComponent('cost-trend')}
      <Row gutter={24}>
        <Col span={14}>
          {getGraphComponent('monthly-cost-by-cloud-provider')}
        </Col>
        <Col span={10}>{getGraphComponent('consolidated-cloud-services')}</Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>{getGraphComponent('cost-by-compute-service')}</Col>
        <Col span={12}>{getGraphComponent('cost-by-storage-service')}</Col>
      </Row>
      {getGraphComponent('consolidated-regions')}
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      <PdfDownloadComponent
        pdfMetaData={getPdfMetaData()}
        pdfCardData={{ costCardsData: ConsolidatedComparisonCards() }}
        pdfContent={[
          {
            element: getGraphComponent('cost-trend', true),
            contentType: selectedTrendMonth
              ? CHART_TYPES.BAR_CHART
              : CHART_TYPES.BAR_LINE_CHART,
            graphName: 'cost-trend',
            isTableView: isCostTrendTableView,
            tableName: getCostTrendsHeading(
              selectedTrendMonth,
              selectedTrendService.service
            ),
            column: getCostTrendsExportColumns(
              selectedTrendMonth,
              selectedTrendService.service
            ),
            body: insertIndex(CostTrendTableData).map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
            legends: getCostTrendLegendsForPdfExport(),
          },
          {
            element: getGraphComponent('monthly-cost-by-cloud-provider', true),
            contentType: CHART_TYPES.BAR_CHART,
            graphName: 'monthly-cost-by-cloud-provider',
            isTableView: isYtdCostByProviderTableView,
            tableName: t('graphHeadings.monthlyCostbyCloudProvider'),
            column: getConsolidatedYTDCostExportColumns(uniqueProviders),
            body: insertIndex(monthlyCostByCloudProviderTableData).map(
              (item) => ({
                ...item,
                cost: `${currencySymbol} ${numberCommaSeparator(item.cost)}`,
              })
            ),
          },
          {
            contentType: CHART_TYPES.TABLE,
            column: ConsolidatedCloudServicesExportColumns,
            graphName: 'consolidated-cloud-services',
            tableName: t('graphHeadings.cloudServices'),
            body: insertIndex(
              consolidatedCloudServicesData.map((item) => ({
                ...item,
                cost: `${currencySymbol} ${numberCommaSeparator(item.cost)}`,
              }))
            ),
            tableFooterData: {
              totalTableData: [
                t('grandTotal'),
                '',
                '',
                '',
                `$ ${calculateTotalCostSum(
                  consolidatedCloudServicesData.map(
                    (eachData) => +eachData.cost
                  )
                )}`,
              ],
            },
          },
          {
            element: getGraphComponent('cost-by-compute-service', true),
            contentType: CHART_TYPES.BAR_CHART,
            graphName: 'cost-by-compute-service',
            isTableView: costByComputeServiceTableView,
            tableName: t('graphHeadings.costByComputeServices'),
            column: CostByServiceCategoryExportColumns,
            body: insertIndex(costByComputeServiceData).map((item) => ({
              ...item,
              cost: `${currencySymbol} ${numberCommaSeparator(item.cost)}`,
            })),
          },
          {
            element: getGraphComponent('cost-by-storage-service', true),
            contentType: CHART_TYPES.BAR_CHART,
            graphName: 'cost-by-storage-service',
            isTableView: costByStorageServiceTableView,
            tableName: t('graphHeadings.costByStorageServices'),
            column: CostByServiceCategoryExportColumns,
            body: insertIndex(costByStorageServiceData).map((item) => ({
              ...item,
              cost: `${currencySymbol} ${numberCommaSeparator(item.cost)}`,
            })),
          },
          {
            element: getGraphComponent('consolidated-regions', true),
            contentType: CHART_TYPES.MAP,
            graphName: 'consolidated-regions',
            isTableView: false,
            tableName: t('graphHeadings.costByRegion'),
            column: ConsolidatedRegionsExcelExportColumns,
            body: insertIndex(consolidatedRegionsTableData),
            legends: consolidatedRegionsTableData.map((item) => ({
              color: item.color,
              name: `${item.region || 'Others'} - ${
                currencySymbol + numberCommaSeparator(item.cost)
              }`,
            })),
          },
        ]}
      />
    </div>
  );
};

export default GCPConsolidatedCostSummaryDashboard;
