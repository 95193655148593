import { useEffect, useState } from 'react';
import { Tooltip } from 'antd';

import { useSelector } from 'react-redux';

import { customDashboard } from 'redux/customDashboardSlice';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';

import NewLayouts from '../NewLayouts';

import './index.scss';

const AddNewLayoutPlaceholder = () => {
  const { customViewData } = useSelector(customDashboard);

  const [showLayoutOptions, setShowLayoutOptions] = useState(true);

  useEffect(() => {
    setShowLayoutOptions(customViewData.layoutDesigns.length === 0);
  }, [customViewData.layoutDesigns]);

  return (
    <div className="margin-24 add-layout-placeholder">
      <div className="flex flex-center full-height ">
        <Tooltip
          overlayClassName="layout-options"
          overlay={<NewLayouts setShowLayoutOptions={setShowLayoutOptions} />}
          placement="bottom"
          open={showLayoutOptions}
          onOpenChange={(visible) =>
            setShowLayoutOptions(
              customViewData.layoutDesigns.length === 0 || visible
            )
          }
          trigger="click"
        >
          <Icon
            className="add-icon"
            iconName={showLayoutOptions ? ICONS.CLOSE_LINE : ICONS.ADD_LINE}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default AddNewLayoutPlaceholder;
