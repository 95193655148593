import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function createTicketIntegration
 * @description Function to create the workflow ticket integration
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createTicketIntegration = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_TICKET_INTEGRATION, body);
};
