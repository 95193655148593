import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';

import { FormLabel } from 'components/FormLabel';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import SelectDropdown from 'components/Select';
import { REQUEST_STATUS } from 'constants/requestBody';
import { validateEmptyField } from 'utils/validations';
import { getOciGetDataSchemaTables } from 'pages/ConnectingCSPPage/services';
import { onApiCallError } from 'utils/handleErrors';
import Input from 'components/Input';
import { INPUT_SIZE } from 'constants/appearance';

import { FileInfoType } from '../../types';

type OCIBillingDetailsProps = {
  walletFileInfo: FileInfoType;
};

const OCIBillingDetails = ({ walletFileInfo }: OCIBillingDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection } = useSelector(cloudConnection);

  const [databaseRequiredValidation, setDatabaseRequiredValidation] =
    useState('');
  const [billingTableOptions, setBillingTableOptions] = useState<string[]>([]);
  const [billingTableRequestStatus, setBillingTableRequestStatus] =
    useState('');
  const [billingTableValidation, setBillingTableValidation] = useState('');

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (currentConnection?.billingDataset) {
      getBillingTableData(source);
    } else {
      setBillingTableOptions([]);
    }

    return () => {
      source.cancel();
    };
  }, [currentConnection?.billingDataset]);

  /**
   * @function getBillingTableData
   * @description Function to fetch table data options using the credentials, database and fileId
   */
  const getBillingTableData = (cancelTokenSource: CancelTokenSource) => {
    setBillingTableRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      ociAdwUsername: currentConnection?.ociAdwUsername,
      ociAdwPassword: currentConnection?.ociAdwPassword,
      billingDataset: currentConnection?.billingDataset,
      jsonFileId: walletFileInfo.fileId,
      connectorId: currentConnection?.connectorId,
    };
    getOciGetDataSchemaTables(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        setBillingTableOptions(res.data.responseData);
        setBillingTableRequestStatus(REQUEST_STATUS.SUCCESS);
        setBillingTableValidation('');
      })
      .catch((e) => {
        onApiCallError(e, false, setBillingTableRequestStatus);
        setBillingTableOptions([]);
        if (!axios.isCancel(e)) {
          setBillingTableValidation(t('ociBillingTablesFetchingError'));
        }
      });
  };

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t(`ociConnectionCSPForm.database`)} />
        <Input
          value={currentConnection?.billingDataset}
          placeholder={t('enterDatabase')}
          size={INPUT_SIZE.SMALL}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                billingDataset: e.target.value,
              })
            );
            setDatabaseRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.billingDataset,
              t(`ociConnectionCSPForm.database`),
              setDatabaseRequiredValidation
            )
          }
        />
        <span
          style={{
            display: `${databaseRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {databaseRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t(`ociConnectionCSPForm.billingTable`)} />
        <SelectDropdown
          value={currentConnection?.billingTableName}
          placeholder={t('selectBillingTable')}
          loading={billingTableRequestStatus === REQUEST_STATUS.PROCESSING}
          options={billingTableOptions.map((option) => ({
            name: option,
            value: option,
          }))}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                billingTableName: value,
              })
            );
            setBillingTableValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.billingTableName,
              t(`ociConnectionCSPForm.billingTable`),
              setBillingTableValidation
            )
          }
          showSearch
        />
        <span
          style={{
            display: `${billingTableValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {billingTableValidation}
        </span>
      </div>
    </div>
  );
};

export default OCIBillingDetails;
