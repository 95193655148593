import { store } from 'redux/store';

export const isLeftScrollButtonArea = (chart: any, xCoor: any, yCoor: any) => {
  const {
    chartArea: { top, left, height },
  } = chart;

  return (
    xCoor >= left - 13 &&
    xCoor <= left + 13 &&
    yCoor >= top + height / 2 - 13 &&
    yCoor <= top + height / 2 + 13
  );
};

export const isRightScrollButtonArea = (chart: any, xCoor: any, yCoor: any) => {
  const {
    chartArea: { top, right, height },
  } = chart;

  return (
    xCoor >= right - 13 &&
    xCoor <= right + 13 &&
    yCoor >= top + height / 2 - 13 &&
    yCoor <= top + height / 2 + 13
  );
};

export const getPlugins = (metadata: {
  scrollable: boolean;
  xAxisMinimumValue: number;
  xAxisMaximumValue: number;
}) => {
  const theme = store.getState().theme.theme;
  const scrollButtons = {
    id: 'scrollButtons',

    afterEvent(chart: any, args: any) {
      const {
        canvas,
        data,
        scales: { x },
      } = chart;

      canvas.addEventListener('mousemove', (_event: any) => {
        const xCoor = args.event.x;
        const yCoor = args.event.y;
        const lastValue = data.labels.length - 1;

        if (
          (isLeftScrollButtonArea(chart, xCoor, yCoor) &&
            x.min > metadata.xAxisMinimumValue) ||
          (isRightScrollButtonArea(chart, xCoor, yCoor) && x.max < lastValue)
        ) {
          canvas.style.cursor = 'pointer';
        } else {
          canvas.style.cursor = 'default';
        }
      });
    },
    afterDatasetsDraw(chart: any, _args: any, _pluginOptions: any) {
      const {
        ctx,
        data,
        chartArea: { top, left, bottom, width, right, height },
        scales: { x },
      } = chart;

      ctx.save();

      const angle = Math.PI / 180;
      const radius = 10;
      const lastValue = data.labels.length - 1;

      function buttons(
        xPos: any,
        yPos: any,
        r: any,
        aS: any,
        aE: any,
        text: any
      ) {
        ctx.beginPath();
        ctx.lineWidth = 3;
        ctx.strokeStyle = theme.buttonIconColor;
        ctx.fillStyle = 'white';
        ctx.arc(xPos, yPos, r, aS, aE, false);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();

        ctx.font = 'bold 30px, Open Sans';
        ctx.textAlign = 'center';
        ctx.fillStyle = theme.buttonIconColor;
        ctx.fillText(text, xPos, yPos);
        ctx.restore();
      }

      if (x.min > metadata.xAxisMinimumValue) {
        buttons(left, top + height / 2, radius, 0, angle * 360, '<');
      }

      if (x.max < lastValue) {
        buttons(right, top + height / 2, radius, 0, angle * 360, '>');
      }

      if (x.min > metadata.xAxisMinimumValue || x.max < lastValue) {
        ctx.beginPath();
        ctx.fillStyle = 'lightGrey';
        ctx.rect(left, bottom + 100, width, 8);
        ctx.fill();
        ctx.closePath();

        // Movable Bar
        const min = chart.options.scales.x.min;
        let startingPoint = left + (width / data.labels.length) * min;
        const barWidth =
          (width / data.labels.length) * (metadata.xAxisMaximumValue + 1);
        ctx.beginPath();
        ctx.fillStyle = theme.primaryColor;
        ctx.rect(startingPoint, bottom + 100, barWidth, 8);
        ctx.fill();
        ctx.closePath();
      }
    },
  };

  let pluginList = [];
  if (metadata.scrollable) {
    pluginList.push(scrollButtons);
  }
  return pluginList;
};

export const scrollEffect = (
  event: any,
  _i: any,
  chart: any,
  metadata: { xAxisMinimumValue: number }
) => {
  const { data } = chart;

  const xCoor = event.x;
  const yCoor = event.y;
  const lastValue = data.labels.length - 1;

  if (chart.options.scales.x.min > metadata.xAxisMinimumValue) {
    if (isLeftScrollButtonArea(chart, xCoor, yCoor)) {
      chart.options.scales.x.min = chart.options.scales.x.min - 1;
      chart.options.scales.x.max = chart.options.scales.x.max - 1;
    }
  }

  if (chart.options.scales.x.max < lastValue) {
    if (isRightScrollButtonArea(chart, xCoor, yCoor)) {
      chart.options.scales.x.min = chart.options.scales.x.min + 1;
      chart.options.scales.x.max = chart.options.scales.x.max + 1;
    }
  }

  chart.update();
};
