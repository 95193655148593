import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import Tooltip from 'components/Tooltip';
import AccessibleDiv from 'components/AccessibleDiv';
import { CONJUNCTIONS } from 'constants/requestBody';
import { FieldSource } from 'constants/dashboard';
import {
  getChartConditions,
  getTableColumns,
  updateConditionsQuery,
} from 'pages/CustomDashboardPage/utils';
import { FilterType } from 'types/dashboard';

import ConditionsList from '../ConditionsList';

import './index.scss';

const Conditions = () => {
  const { t } = useTranslation();
  const [showConditionsDropdown, setShowConditionsDropdown] = useState(false);

  const getAvailableColumnsForConditions = () =>
    getTableColumns()?.filter(
      (column) => column.dimensionType !== FieldSource.TAGS
    ) ?? [];

  const onClickAddCondition = (conjunction: string) => {
    const oldConditions = getChartConditions();
    if (
      oldConditions.some(
        (condition) => !condition.field || !condition.comparator
      )
    )
      return;
    const newConditions: FilterType[] = [
      ...oldConditions,
      {
        field: '',
        value: '',
        comparator: '',
        conjunctToNextFilter: conjunction,
      },
    ];
    updateConditionsQuery(newConditions);
    setShowConditionsDropdown(false);
  };

  return (
    <div className="table-conditions">
      <div className="conditions-heading flex flex-align-items-center flex-space-between">
        <div className="font-caption-bold">
          {t('customDashboard.optionsLabels.condition')}
        </div>
        {getChartConditions().length < 5 &&
        getAvailableColumnsForConditions().length ? (
          <Tooltip
            overlay={
              <div className="flex flex-column flex-gap-8">
                <AccessibleDiv
                  className="font-caption cursor-pointer"
                  onClick={() => onClickAddCondition(CONJUNCTIONS.AND)}
                >
                  {CONJUNCTIONS.AND}
                </AccessibleDiv>
                <AccessibleDiv
                  className="font-caption cursor-pointer"
                  onClick={() => onClickAddCondition(CONJUNCTIONS.OR)}
                >
                  {CONJUNCTIONS.OR}
                </AccessibleDiv>
              </div>
            }
            placement="bottomRight"
            open={showConditionsDropdown}
            onOpenChange={(visible: any) => {
              const conditions = getChartConditions();
              if (conditions.length >= 5) return;
              if (conditions.length && visible)
                onClickAddCondition(
                  conditions.at(0)?.conjunctToNextFilter ?? CONJUNCTIONS.OR
                );
              else setShowConditionsDropdown(visible);
            }}
            trigger="click"
            arrow={false}
          >
            <Icon className="add-icon" iconName={ICONS.ADD_LINE} />
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
      <ConditionsList />
    </div>
  );
};

export default Conditions;
