import { useTranslation } from 'react-i18next';

import ChartsList from '../ChartsList';

import './index.scss';

const ChartTypes = () => {
  const { t } = useTranslation();

  return (
    <div className="report-chart-types">
      <div className="font-caption-bold">{t('reports.type')}</div>
      <ChartsList />
    </div>
  );
};

export default ChartTypes;
