import { useTranslation } from 'react-i18next';
import { REQUEST_STATUS } from 'constants/requestBody';
import { generateGraphColors } from 'utils/dashboardUtils';
import GranulateUsageMeteringPieChart from '../GranulateUsageMeteringPieChart';
import { DummyData } from './constants';

const NodeDistribution = () => {
  const { t } = useTranslation();

  const colors = generateGraphColors(DummyData.length ?? 0);

  const data = DummyData.map((item) => ({
    ...item,
    color: colors.pop() ?? '',
  }));

  return (
    <GranulateUsageMeteringPieChart
      data={data}
      heading={t('consolidatedDashboard.nodeDistributionType')}
      graphName="node-distribution"
      colorField="namespace"
      angleField="utilization"
      requestStatus={REQUEST_STATUS.SUCCESS}
    />
  );
};

export default NodeDistribution;
