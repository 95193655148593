import i18n from 'i18n';
import { CoverageUtilizationExcelMetaData } from 'pages/ScorecardPage/types';
import { ConnectionListType } from 'types/dashboard';
import { insertIndex } from 'utils/dataFormatterUtils';
import { UtilizationCoverageExportColumns } from '../../constants';

/**
 * @function getUtilizationCoverageExcelData
 * @param utilization Utilization data containing the sheetName, data and average of utilization
 * @param coverage - Coverage data containing the sheetName, data and average of coverage
 * @param connection connection details of the selected connection
 * @returns Object containing the details of excel export
 */
export const getUtilizationCoverageExcelData = (
  utilization: CoverageUtilizationExcelMetaData,
  coverage: CoverageUtilizationExcelMetaData,
  connection: ConnectionListType | null
) => {
  const utilizationFooter: any = {};
  utilizationFooter.month = i18n.t(
    'scorecard.committedUsageDiscount.monthlyAverage'
  );
  utilizationFooter.value = utilization.averagePercentage;

  const coverageFooter: any = {};
  coverageFooter.month = i18n.t(
    'scorecard.committedUsageDiscount.monthlyAverage'
  );
  coverageFooter.value = coverage.averagePercentage;

  return [
    {
      sheetName: utilization.sheetName,
      columns: UtilizationCoverageExportColumns,
      data: insertIndex(utilization.data),
      filters: [
        {
          heading: i18n.t('excelExportLabels.connectionName'),
          value: connection?.displayName,
        },
      ],
      footer: utilizationFooter,
    },
    {
      sheetName: coverage.sheetName,
      columns: UtilizationCoverageExportColumns,
      data: insertIndex(coverage.data),
      filters: [
        {
          heading: i18n.t('excelExportLabels.connectionName'),
          value: connection?.displayName,
        },
      ],
      footer: coverageFooter,
    },
  ];
};
