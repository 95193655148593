import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';

import './index.scss';

type SuccessComponentProps = {
  mainTitle: string;
  subTitle?: React.ReactElement | string;
  buttonTitle: string;
  linkTitle?: string;
  onHandleButtonClick: () => void;
  loadingButton?: boolean;
  onHandleLinkClick?: () => void;
  icon: React.ReactElement;
  additionalComponent?: React.ReactElement;
  additionalClassName?: string;
};
const SuccessComponent = ({
  mainTitle,
  subTitle,
  buttonTitle,
  linkTitle,
  onHandleButtonClick,
  loadingButton,
  onHandleLinkClick,
  icon,
  additionalComponent,
  additionalClassName,
}: SuccessComponentProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`success-component flex flex-column flex-center flex-fit full-height ${additionalClassName}`}
      data-testid="success-component"
    >
      {icon}
      <div className="font-heading-4-bold">{mainTitle}</div>
      <div className="sub-title font-subHeader">{subTitle}</div>
      <Button
        className="dashboard-button"
        title={buttonTitle}
        type="primary"
        onClick={onHandleButtonClick}
        loading={loadingButton}
      />
      {linkTitle && onHandleLinkClick && (
        <div className="footer-info font-caption flex flex-align-items-center">
          <span>{t('connectionCSPSuccessPage.orTag')}</span>
          <Button
            title={linkTitle}
            type={BUTTON_TYPE.LINK}
            onClick={onHandleLinkClick}
          />
        </div>
      )}
      <div className="additional-component">{additionalComponent}</div>
    </div>
  );
};

export default SuccessComponent;
