export enum ROLES {
  ADMIN = 'ADMIN',
  USER = 'USER',
  POWERUSER = 'POWERUSER',
  COSTMANAGER = 'COSTMANAGER',
}

export const roles = [
  {
    id: ROLES.USER,
    title: 'user',
  },
  {
    id: ROLES.ADMIN,
    title: 'admin',
  },
  {
    id: ROLES.POWERUSER,
    title: 'powerUser',
  },
  {
    id: ROLES.COSTMANAGER,
    title: 'costManager',
  },
];

export enum AUTHORITIES {
  DASHBOARD_READ = 'DASHBOARD::READ',
  DASHBOARD_LIST = 'DASHBOARD::LIST',
  DASHBOARD_LIST_ASSIGNED = 'DASHBOARD::LIST_ASSIGNED',
  DASHBOARD_WRITE = 'DASHBOARD::WRITE',
  DASHBOARD_MODIFY = 'DASHBOARD::MODIFY',
  CLOUD_CONNECTOR_READ = 'CLOUD_CONNECTOR::READ',
  CLOUD_CONNECTOR_LIST = 'CLOUD_CONNECTOR::LIST',
  CLOUD_CONNECTOR_LIST_ASSIGNED = 'CLOUD_CONNECTOR::LIST_ASSIGNED',
  CLOUD_CONNECTOR_WRITE = 'CLOUD_CONNECTOR::WRITE',
  CLOUD_CONNECTOR_MODIFY = 'CLOUD_CONNECTOR::MODIFY',
  BUDGET_ALERTS_READ = 'BUDGET_ALERTS::READ',
  BUDGET_ALERTS_LIST = 'BUDGET_ALERTS::LIST',
  BUDGET_ALERTS_WRITE = 'BUDGET_ALERTS::WRITE',
  BUDGET_ALERTS_MODIFY = 'BUDGET_ALERTS::MODIFY',
  COST_CONTROL_READ = 'COST_CONTROL::READ',
  COST_CONTROL_LIST = 'COST_CONTROL::LIST',
  COST_CONTROL_WRITE = 'COST_CONTROL::WRITE',
  COST_CONTROL_MODIFY = 'COST_CONTROL::MODIFY',
  COST_ALLOCATION_READ = 'COST_ALLOCATION::READ',
  COST_ALLOCATION_LIST = 'COST_ALLOCATION::LIST',
  COST_ALLOCATION_WRITE = 'COST_ALLOCATION::WRITE',
  COST_ALLOCATION_MODIFY = 'COST_ALLOCATION::MODIFY',
  USER_MANAGEMENT_READ = 'USER_MANAGEMENT::READ',
  USER_MANAGEMENT_LIST = 'USER_MANAGEMENT::LIST',
  USER_MANAGEMENT_WRITE = 'USER_MANAGEMENT::WRITE',
  USER_MANAGEMENT_MODIFY = 'USER_MANAGEMENT::MODIFY',
  THIRD_PARTY_APPS_READ = 'THIRD_PARTY_APPS::READ',
  THIRD_PARTY_APPS_LIST = 'THIRD_PARTY_APPS::LIST',
  THIRD_PARTY_APPS_WRITE = 'THIRD_PARTY_APPS::WRITE',
  THIRD_PARTY_APPS_MODIFY = 'THIRD_PARTY_APPS::MODIFY',
}
