import { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import { ProfileIcon } from 'assets/icons';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { UserInfoType } from 'types/userManagementConsole';
import { onApiCallError } from 'utils/handleErrors';
import { getOtherUsersProfilePhoto } from 'utils/services';

import './index.scss';

type UserRowProps = {
  user: UserInfoType;
  onClickRemoveUserFromTeam: (val: UserInfoType) => void;
};

const UserRow = ({ user, onClickRemoveUserFromTeam }: UserRowProps) => {
  const [profilePic, setProfilePic] = useState(ProfileIcon);

  useEffect(() => {
    fetchProfileImage();
  }, []);

  /**
   * @function fetchProfileImage
   * @description function to fetch the profile image
   */
  const fetchProfileImage = async () => {
    getOtherUsersProfilePhoto(user.email)
      .then(async (res: any) => {
        if (res?.status === 200) {
          const imageBlob = await res.blob();
          const imageObjectURL = URL.createObjectURL(imageBlob);
          setProfilePic(imageObjectURL);
        }
      })
      .catch((e) => {
        onApiCallError(e, false);
      });
  };

  return (
    <div
      key={user.email}
      className="user-row flex flex-align-items-center flex-gap-8"
    >
      <img src={profilePic} alt="profileimage" className="user-pic" />
      <div>
        <div className="font-button">{user.name}</div>
        <div className="email font-caption">{user.email}</div>
      </div>
      <span className="flex flex-end flex-fit">
        <Icon
          className="remove-icon"
          iconName={ICONS.CLOSE_LINE}
          size={ICONS_SIZE.FW}
          onClick={() => onClickRemoveUserFromTeam(user)}
          dataTestId="user-remove-cta"
        />
      </span>
    </div>
  );
};

export default UserRow;
