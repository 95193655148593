import { useSelector } from 'react-redux';

import { cmdbSetup } from 'redux/cmdbSetupSlice';
import { CMDB_PROGRESS_BAR_CONTENT } from 'pages/CmdbConfigurationPage/constants';

const CmdbConfigurationHeader = () => {
  const { cmdbConfigurationStep } = useSelector(cmdbSetup);

  return (
    <div className="cmdb-configuration-header flex flex-column flex-gap-8 flex-center">
      <span className="modal-heading">
        {CMDB_PROGRESS_BAR_CONTENT[cmdbConfigurationStep].title}
      </span>
      <span className="table-typography">
        {CMDB_PROGRESS_BAR_CONTENT[cmdbConfigurationStep].description}
      </span>
    </div>
  );
};

export default CmdbConfigurationHeader;
