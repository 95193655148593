import { message, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { createUser } from 'pages/UserPage/services';
import { EmailRoleMap } from 'pages/UserPage/types';
import { UserInfoType } from 'types/userManagementConsole';
import { REQUEST_STATUS } from 'constants/requestBody';
import ModalFooterSteps from 'components/ModalFooterSteps';
import { fetchAllRolesNames } from 'utils/services';
import SelectDropdown from 'components/Select';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

type AddRoleProps = {
  showAddRoleModal: boolean;
  setShowAddRoleModal: (val: boolean) => void;
  userList: UserInfoType[];
  step: number;
  setStep: (val: number) => void;
  setUserList: (val: UserInfoType[]) => void;
  fetchUserData: () => void;
};

const AddRole = ({
  showAddRoleModal,
  setShowAddRoleModal,
  userList,
  step,
  setStep,
  setUserList,
  fetchUserData,
}: AddRoleProps) => {
  const { t } = useTranslation();
  const [userRoles, setUserRoles] = useState<EmailRoleMap[]>([]);
  const [inviteUserRequestStatus, setInviteUserRequestStatus] = useState('');
  const [rolesData, setRolesData] = useState<string[]>([]);
  const [disableInviteButton, setDisableInviteButton] = useState(true);

  useEffect(() => {
    const emailRoleMap: EmailRoleMap[] = [];
    userList.forEach((user: UserInfoType) => {
      emailRoleMap.push({
        email: user.email,
        name: user.name,
        roleName: '',
        saasOrgUrl: window.location.origin + '/',
      });
    });
    setUserRoles(emailRoleMap);
    rolesData?.length === 0 && getAllRolesData();
  }, [userList]);

  useEffect(() => {
    setDisableInviteButton(userRoles.some((user) => user.roleName === ''));
  }, [userRoles]);

  const onClose = () => {
    setStep(1);
    setShowAddRoleModal(false);
    setUserRoles([]);
    setUserList([]);
  };

  /**
   * @function getAllRolesData
   * @description Function to get all roles names data.
   */
  const getAllRolesData = () => {
    fetchAllRolesNames()
      .then((res: any) => {
        setRolesData(res?.data?.responseData);
      })
      .catch((e) => {
        onApiCallError(e);
      });
  };

  /**
   * @function onClickInvite
   * @description Function to handle send invite.
   */
  const onClickInvite = () => {
    setInviteUserRequestStatus(REQUEST_STATUS.PROCESSING);
    createUser(userRoles)
      .then(() => {
        setInviteUserRequestStatus(REQUEST_STATUS.SUCCESS);
        message.success(t('addUserModal.successfullyAddedUser'));
        setStep(1);
        setShowAddRoleModal(false);
        setUserList([]);
        fetchUserData();
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setInviteUserRequestStatus,
          t('addUserModal.failedToAddUser')
        );
        setStep(1);
        setShowAddRoleModal(false);
        setUserList([]);
      });
  };

  /**
   * @function handleChooseRole
   * @description Function to handle while choosing role
   * @param role - role assigned to a user
   * @param record - includes data of a user
   */
  const handleChooseRole = (role: string, record: EmailRoleMap) => {
    setUserRoles(
      userRoles.map((userRole) =>
        userRole.email === record.email
          ? { ...userRole, roleName: role }
          : userRole
      )
    );
  };

  const roleOptions = (
    <>
      {rolesData?.map((role) => (
        <Select.Option key={role} value={role}>
          {role}
        </Select.Option>
      ))}
    </>
  );

  const columns = [
    {
      className: 'index',
      title: '#',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      className: 'email',
      title: t('addRoleModal.emailAddress'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      className: 'role',
      title: t('addRoleModal.role'),
      dataIndex: 'userRole',
      key: 'role',
      align: 'left' as const,
      render: (_text: string, record: EmailRoleMap) => {
        return (
          <SelectDropdown
            showSearch
            placeholder={t('addRoleModal.searchRolePlaceholder')}
            className="roles-menu cursor-pointer full-width"
            menu={roleOptions}
            onSelect={(event: string) => handleChooseRole(event, record)}
          />
        );
      },
    },
  ];

  return (
    <div className="addrole">
      <Modal
        visible={showAddRoleModal}
        centered
        onCancel={onClose}
        title={t('addRoleModal.title')}
        footer={<ModalFooterSteps totalSteps={2} currentStep={step} />}
      >
        <div className="table">
          <Table
            className="table-typography;"
            columns={columns}
            dataSource={userRoles.map((user, index) => {
              return {
                ...user,
                key: index,
              };
            })}
            pagination={false}
          />
        </div>
        <div className="addrole-button flex flex-end">
          <Button
            title={t('addRoleModal.sendInviteButton')}
            loading={inviteUserRequestStatus === REQUEST_STATUS.PROCESSING}
            onClick={onClickInvite}
            disabled={disableInviteButton}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddRole;
