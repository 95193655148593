import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectReport } from 'redux/reportSlice';
import { CHARTS_LIST } from 'constants/dashboard';
import { CHART_TYPES } from 'constants/graphConfig';

import ChartMetricList from '../ChartMetricList';

import './index.scss';

const ChartMetrics = () => {
  const { t } = useTranslation();
  const { reportOptions } = useSelector(selectReport);

  /**
   * @function getValidationMessage
   * @description Function to return the validation message for metric in chart view
   * @returns string validation message
   */
  const getValidationMessage = () => {
    if (reportOptions.metric.length === 0) {
      return t('reports.chooseMetricInTable');
    }

    if (reportOptions.chartType === CHART_TYPES.TABLE) {
      return t('reports.chooseChartType');
    }

    const selectedChartMetricCount = CHARTS_LIST.find(
      (item) => item.chartType === reportOptions.chartType
    )?.metricsCount;
    if ((selectedChartMetricCount?.min ?? 0) > reportOptions.metric.length) {
      return t('reports.chooseAtleastNMetric', {
        count: selectedChartMetricCount?.min ?? 0,
      });
    }

    return null;
  };

  /**
   * @function getMetricListComponent
   * @description Function to return the metric list component based on the validation
   * @returns JSX component
   */
  const getMetricListComponent = () => {
    const validationMessage = getValidationMessage();

    return validationMessage ? (
      <div className="metric-validation-message font-caption-bold">
        {validationMessage}
      </div>
    ) : (
      <ChartMetricList />
    );
  };

  return (
    <div className="report-chart-metrics">
      <div className="font-caption-bold">{t('reports.metrics')}</div>
      {getMetricListComponent()}
    </div>
  );
};

export default ChartMetrics;
