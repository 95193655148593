import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';
/**
 * @function fetchAWScatalogs
 * @description Function to fetch AWS catalogs
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const fetchAWScatalogs = async (body: any) => {
  const requestAPI = APIS.AWS_CATALOGS;
  return ServiceCalls.post(requestAPI, body);
};

/**
 * @function fetchAWSdatabase
 * @description Function to fetch AWS database
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const fetchAWSdatabase = async (body: any) => {
  const requestAPI = APIS.AWS_DATABASES;
  return ServiceCalls.post(requestAPI, body);
};

/**
 * @function fetchAWStable
 * @description Function to fetch AWS database
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const fetchAWStable = async (body: any) => {
  const requestAPI = APIS.AWS_TABLES;
  return ServiceCalls.post(requestAPI, body);
};

/**
 * @function fetchAWSregions
 * @description Function to fetch AWS regions
 * @params body : Object containing the request body
 * @return axios response from GET request
 */
export const fetchAWSregions = async () => {
  const requestAPI = APIS.AWS_REGIONS;
  return ServiceCalls.get(requestAPI);
};
