import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { message } from 'antd';

import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import Button from 'components/Button';
import { BUTTON_SIZE } from 'constants/appearance';
import { ICONS } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { deleteRecommendationViews } from 'pages/CostOptimizationInsightsPage/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  DEFAULT_VIEW,
  DEFAULT_VIEW_DROPDOWN_OPTIONS,
} from 'pages/CostOptimizationInsightsPage/constants';
import { RulesetsType } from 'pages/RuleEnginePage/types';

type DeleteViewButtonProps = {
  getAllRecommendationViews: Function;
  rulesets: RulesetsType[];
};

const DeleteViewButton = ({
  getAllRecommendationViews,
  rulesets,
}: DeleteViewButtonProps) => {
  const { t } = useTranslation();
  const {
    currentRecommendationView,
    selectedCostOptimizationInsightsConnection,
  } = useSelector(costOptimizationInsights);

  const [deleteViewRequestStatus, setDeleteViewRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  const onClickDeleteView = () => {
    setDeleteViewRequestStatus(REQUEST_STATUS.PROCESSING);

    const extraHeaders = {
      viewName: currentRecommendationView.viewName,
      connectorId: selectedCostOptimizationInsightsConnection!.connectorId,
    };

    deleteRecommendationViews(extraHeaders)
      .then((res: any) => {
        if (res.status === 200) {
          message.success({
            content: t(
              'costOptimizationInsight.recommendationApiMessage.deleteSuccess',
              {
                viewName: currentRecommendationView.viewName,
              }
            ),
          });
          getAllRecommendationViews(DEFAULT_VIEW_DROPDOWN_OPTIONS[0]);
          setDeleteViewRequestStatus(REQUEST_STATUS.SUCCESS);
        }
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteViewRequestStatus,
          t('costOptimizationInsight.recommendationApiMessage.deleteFailure', {
            viewName: currentRecommendationView.viewName,
          })
        );
      });
  };

  return (
    <Button
      className="delete-view-button"
      size={BUTTON_SIZE.SMALL}
      iconName={ICONS.DELETE_BIN_LINE}
      title={t('costOptimizationInsight.recommendationTableHeader.deleteView')}
      onClick={onClickDeleteView}
      loading={deleteViewRequestStatus === REQUEST_STATUS.PROCESSING}
      disabled={
        currentRecommendationView.key === DEFAULT_VIEW ||
        rulesets.some(
          (ruleset) => ruleset.rulesetId === currentRecommendationView.key
        )
      }
    />
  );
};

export default DeleteViewButton;
