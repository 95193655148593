import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import { isEqual } from 'lodash';

import {
  selectChatBot,
  setAllChatbotProviders,
  setChats,
  setConnections,
  setIsChatBoxOpen,
  setIsNewSession,
  setSelectedChatbotProvider,
  setSelectedConnection,
} from 'redux/chatBotSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import Button from 'components/Button';
import DrawerComponent from 'components/DrawerComponent';
import AccessibleDiv from 'components/AccessibleDiv';
import Icon from 'components/Icon';
import ConnectionListDropdown from 'components/ConnectionListDropdown';
import { INPUT_SIZE } from 'constants/appearance';
import { ICONS } from 'constants/icons';
import { CONNECTION_STATUS } from 'pages/ConnectingCSPPage/constants';
import { ConnectionListType } from 'types/dashboard';
import {
  REFRESH_INTERVAL,
  REFRESH_INTERVAL_5000,
} from 'constants/defaultValues';
import {
  ChatBot,
  ChatBotWithoutLogo,
  InstancesWithoutLogo,
  ProfileIcon,
} from 'assets/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { fetchConnectionData, getUserprofilePhoto } from 'utils/services';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import ChatConversations from './components/ChatConversations';
import { Sender } from './constants';
import { ChatbotProviderListType, ChatType } from './types';
import {
  clearConversationHistory,
  fetchChatHistory,
  fetchPromptResponse,
  fetchRecommendedQuestions,
  getAllSupportedGenAIProviders,
  getSelectedModel,
  selectChatConnection,
  setSelectedModel,
} from './services';

import './index.scss';
import axios from 'axios';

const ChatBoxModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isChatBoxOpen,
    chats,
    connections,
    selectedConnection,
    isNewSession,
    allChatbotProviders,
    selectedChatbotProvider,
  } = useSelector(selectChatBot);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const inputRef = useRef<any>(null);

  const [allProvidersRequestStatus, setAllProvidersRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [recommendedQueries, setRecommendedQueries] = useState<string[]>([]);
  const [queryString, setQueryString] = useState('');
  const [fetchResponseReqStatus, setFetchResponseReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [profileImage, setProfileImage] = useState<any>(ProfileIcon);
  const [fetchConnectionsRequestStatus, setFetchConnectionsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [isConnectionDropdownVisible, setIsConnectionDropdownVisible] =
    useState(false);
  const [fetchChatHistoryRequestStatus, setFetchChatHistoryRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [selectConnectionRequestStatus, setSelectConnectionRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [selectedModelRequestStatus, setSelectedModelRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    getAllChatbotProviders();
    fetchRecommendedQueries();
    fetchImage();
    fetchConnections(true);

    if (isNewSession) {
      fetchPreviousSessionChat();
    }

    const interval = setInterval(() => {
      fetchConnections();
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let interval: any = undefined;
    if (fetchResponseReqStatus !== REQUEST_STATUS.PROCESSING) {
      fetchPreviousSessionChat();
      interval = setInterval(() => {
        fetchPreviousSessionChat();
      }, REFRESH_INTERVAL_5000);
    }

    return () => clearInterval(interval);
  }, [fetchResponseReqStatus, chats, selectedChatbotProvider?.submodel_name]);

  useEffect(() => {
    if (
      !connections.find(
        (item) => item.connectorId === selectedConnection?.connectorId
      )
    ) {
      dispatch(setSelectedConnection(connections?.[0] ?? null));
    }
  }, [connections]);

  useEffect(() => {
    if (selectedConnection) {
      setConnection();
    }
  }, [selectedConnection]);

  /**
   * @function getAllChatbotProviders
   * @description Function to fetch all chatbot providers and sub models
   */
  const getAllChatbotProviders = () => {
    setAllProvidersRequestStatus(REQUEST_STATUS.PROCESSING);

    const requests = [getAllSupportedGenAIProviders(), getSelectedModel()];

    axios
      .all(requests)
      .then((responses: any[]) => {
        const allModels: ChatbotProviderListType[] = responses[0].data;
        const selectedModel: { model_name: string; submodel_name: string } = {
          model_name: responses[1].data.model,
          ...responses[1].data,
        };

        if (
          allModels.some(
            (model) =>
              model.model_name === selectedModel.model_name &&
              model.submodel_name.includes(selectedModel.submodel_name)
          )
        ) {
          dispatch(setSelectedChatbotProvider(selectedModel));
        } else {
          setChatbotProvider(null, null);
          dispatch(setSelectedChatbotProvider(null));
        }

        dispatch(setAllChatbotProviders(allModels));
        setAllProvidersRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      })
      .catch((e: any) => {
        onApiCallError(e, false, setAllProvidersRequestStatus);
      });
  };

  /**
   * @function fetchPreviousSessionChat
   * @description Function to fetch the previous chat history in the session
   */
  const fetchPreviousSessionChat = () => {
    setFetchChatHistoryRequestStatus(REQUEST_STATUS.PROCESSING);

    fetchChatHistory()
      .then((res: any) => {
        const data = Array.isArray(res?.data) ? res?.data : [];
        const historyChats = data.map((item: any, index: number) => ({
          message: item.User ?? item?.AI,
          sender: item.User ? Sender.USER : Sender.BOT,
          key: index,
          approxCost: item?.approx_costing,
          totalTokens: item?.total_token,
          model_name: item?.model_name,
          submodel_name: item?.submodel_name,
        }));

        if (!isEqual(chats, historyChats)) {
          dispatch(setChats(historyChats));
        }

        setFetchChatHistoryRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchChatHistoryRequestStatus);
      })
      .finally(() => {
        dispatch(setIsNewSession(false));
      });
  };

  /**
   * @function fetchSelectedChatbotProvider
   * @description Function to fetch the selected model and submodel
   */
  const fetchSelectedChatbotProvider = () => {
    setSelectedModelRequestStatus(REQUEST_STATUS.PROCESSING);

    getSelectedModel()
      .then((res: any) => {
        dispatch(
          setSelectedChatbotProvider({
            model_name: res?.data?.model,
            ...res?.data,
          })
        );
        setSelectedModelRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSelectedModelRequestStatus);
        dispatch(setSelectedChatbotProvider(null));
      });
  };

  /**
   * @function setChatbotProvider
   * @description Function to set the chatbot provider for the session
   * @param modelName model name
   * @param subModelName sub model name
   */
  const setChatbotProvider = (
    modelName: string | null,
    subModelName: string | null
  ) => {
    setSelectedModelRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      model_name: modelName,
      submodel_name: subModelName,
    };

    setSelectedModel(requestBody)
      .then(() => {
        fetchSelectedChatbotProvider();
        if (modelName !== selectedChatbotProvider?.model_name) {
          dispatch(setChats([]));
        }
        setSelectedModelRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSelectConnectionRequestStatus);
        dispatch(setSelectedChatbotProvider(null));
      });
  };

  /**
   * @function setConnection
   * @description Function to set the chat connection for the session
   */
  const setConnection = () => {
    setSelectConnectionRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connection_name: selectedConnection?.name,
      connector_id: selectedConnection?.connectorId,
    };

    selectChatConnection(requestBody)
      .then(() => {
        setSelectConnectionRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSelectConnectionRequestStatus);
        dispatch(setSelectedConnection(null));
      });
  };

  /**
   * @function fetchConnections
   * @description Function to fetch the connection list
   */
  const fetchConnections = (setInputFocus: boolean = false) => {
    setFetchConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);

    fetchConnectionData()
      .then((res: any) => {
        const connectionList: ConnectionListType[] = (
          res?.data?.responseData?.content ?? []
        ).filter(
          (connection: ConnectionListType) =>
            connection.status === CONNECTION_STATUS.ACTIVE &&
            connection.wantBilling
        );
        dispatch(setConnections(connectionList));

        setFetchConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchConnectionsRequestStatus);
        dispatch(setConnections([]));
      })
      .finally(() => {
        if (setInputFocus && !isConnectionDropdownVisible) {
          inputRef?.current?.focus();
        }
      });
  };

  /**
   * @function fetchRecommendedQueries
   * @description Function to fetch the recommended queries
   */
  const fetchRecommendedQueries = () => {
    fetchRecommendedQuestions()
      .then((res: any) => {
        setRecommendedQueries(res?.data ?? []);
      })
      .catch((e) => {
        onApiCallError(e, false);
      });
  };

  /**
   * @function fetchImage
   * @description Function to fetch profile image
   */
  const fetchImage = async () => {
    try {
      const res: any = await getUserprofilePhoto();
      if (res.status > 400) {
        setProfileImage(ProfileIcon);
      } else {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setProfileImage(imageObjectURL);
      }
    } catch (error) {
      onApiCallError(error, false);
      setProfileImage(ProfileIcon);
    }
  };

  /**
   * @function fetchResponse
   * @description Function to fetch the response to query
   * @param conversations list of existing conversations
   */
  const fetchResponse = (conversations: ChatType[], query: string) => {
    setFetchResponseReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      query: query,
    };

    fetchPromptResponse(requestBody)
      .then((res: any) => {
        const chatResponse = res?.data;
        dispatch(
          setChats([
            ...conversations,
            {
              message: chatResponse?.content ?? chatResponse?.error,
              sender: Sender.BOT,
              key: conversations.length,
              approxCost: chatResponse?.approx_costing,
              totalTokens: chatResponse?.total_token,
              model_name: selectedChatbotProvider?.model_name ?? '',
              submodel_name: selectedChatbotProvider?.submodel_name ?? '',
            },
          ])
        );
        setFetchResponseReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchResponseReqStatus);
        dispatch(
          setChats([
            ...conversations,
            {
              message: t('chatBotLabel.errorFetchingResponse'),
              sender: Sender.BOT,
              key: conversations.length,
              model_name: selectedChatbotProvider?.model_name ?? '',
              submodel_name: selectedChatbotProvider?.submodel_name ?? '',
            },
          ])
        );
      });
  };

  /**
   * @function clearChatHistory
   * @description Function to clear the chat history
   */
  const clearChatHistory = () => {
    clearConversationHistory()
      .then(() => {
        dispatch(setSelectedConnection(connections?.[0] ?? null));
        dispatch(setChats([]));
      })
      .catch((e) => {
        onApiCallError(e, false);
      });
  };

  /**
   * @function onCloseChatBox
   * @description Callback function for closing the chat box modal
   * @param clearChats boolean to clear the chats
   */
  const onCloseChatBox = (clearChats: boolean = false) => {
    dispatch(setIsChatBoxOpen(false));

    if (clearChats) {
      clearChatHistory();
    }
  };

  /**
   * @function onClickGenerate
   * @description Callback function for generating the query
   * @param query query entered or clicked from recommendations
   */
  const onClickGenerate = (query: string = queryString) => {
    if (!isConnectionDropdownVisible) {
      inputRef?.current?.focus();
    }

    if (query?.trim()) {
      const conversations = [
        ...chats,
        {
          message: query,
          sender: Sender.USER,
          key: chats.length,
          model_name: selectedChatbotProvider?.model_name ?? '',
          submodel_name: selectedChatbotProvider?.submodel_name ?? '',
        },
      ];
      dispatch(setChats(conversations));
      setQueryString('');

      fetchResponse(conversations, query);
    }
  };

  const getConnectionError = () => {
    if (
      connections.length === 0 &&
      fetchConnectionsRequestStatus === REQUEST_STATUS.PROCESSING
    ) {
      return t('chatBotLabel.fetchingConnections');
    } else if (!selectedConnection) {
      return t('chatBotLabel.selectConnection');
    }

    return null;
  };

  const getChatbotModelsError = () => {
    if (
      allChatbotProviders.length === 0 &&
      allProvidersRequestStatus === REQUEST_STATUS.ERROR
    ) {
      return t('errorFetchingLLMs');
    } else if (
      !selectedChatbotProvider ||
      !selectedChatbotProvider.model_name ||
      !selectedChatbotProvider.submodel_name
    ) {
      return t('chooseLLMModel');
    }

    return null;
  };

  const TokensUsedContent = (
    <div className="tokens-used-container font-caption-bold flex flex-align-items-center flex-gap-4">
      <span className="token-icon" />
      <span className="label">{t('chatBotLabel.tokenUsage')}</span>
      <span>{chats[chats.length - 1]?.totalTokens ?? 0}</span>
      <span className="label">{t('chatBotLabel.price')}</span>
      <span>
        {currencySymbol}
        {numberCommaSeparator(chats[chats.length - 1]?.approxCost ?? 0, 3, 3)}
      </span>
    </div>
  );

  return (
    <DrawerComponent
      rootClassName="chat-box-modal-wrapper"
      className="chat-box-modal"
      open={isChatBoxOpen}
      title={
        <div className="chat-box-heading flex flex-align-items-center flex-space-between">
          <div className="flex flex-align-items-center flex-gap-12">
            <div className="flex flex-align-items-center flex-gap-8">
              {!InstancesWithoutLogo.includes(window.location.hostname) && (
                <ChatBot
                  className="bot-icon"
                  data-testid="chat-bot-header-icon"
                />
              )}
              <div className="flex flex-column flex-gap-4">
                <div className="flex flex-align-items-center flex-gap-8">
                  <span className="form-header">
                    {t('navigationMenu.chatBot')}
                  </span>
                  <SelectDropdown
                    rootClassName="select-chatbot-provider"
                    value={selectedChatbotProvider?.submodel_name}
                    options={allChatbotProviders.map((item) => ({
                      label: item.model_name,
                      options: item.submodel_name.map((subItem) => ({
                        label: subItem,
                        value: subItem,
                      })),
                    }))}
                    loading={[
                      allProvidersRequestStatus,
                      selectedModelRequestStatus,
                    ].includes(REQUEST_STATUS.PROCESSING)}
                    onSelect={(value: any) => {
                      setChatbotProvider(
                        allChatbotProviders.find((item) =>
                          item.submodel_name.includes(value)
                        )?.model_name ?? '',
                        value
                      );
                    }}
                    size={INPUT_SIZE.SMALL}
                    designVersion2
                    disabled={
                      fetchResponseReqStatus === REQUEST_STATUS.PROCESSING
                    }
                  />
                </div>
                {TokensUsedContent}
              </div>
            </div>
          </div>
          <div className="actions flex flex-align-items-center flex-gap-12 flex-fit flex-end">
            <ConnectionListDropdown
              additionalClassName="chat-bot-connection-dropdown"
              selectedConnection={selectedConnection}
              onSelectDropdown={(id: string) => {
                dispatch(
                  setSelectedConnection(
                    connections.find(
                      (connection) => connection.connectorId === id
                    ) ?? null
                  )
                );
              }}
              connectionList={connections}
              loadingDropdown={
                connections.length === 0 &&
                [
                  fetchConnectionsRequestStatus,
                  selectConnectionRequestStatus,
                ].includes(REQUEST_STATUS.PROCESSING)
              }
              additionalDropdownProps={{
                open: isConnectionDropdownVisible,
                onDropdownVisibleChange: (open) => {
                  setIsConnectionDropdownVisible(open);
                  if (!open) {
                    inputRef?.current?.focus();
                  }
                },
              }}
            />
            <Icon
              iconName={ICONS.SUBTRACT_LINE}
              onClick={() => onCloseChatBox()}
              dataTestId="minimize-icon"
            />
            <Icon
              iconName={ICONS.CLOSE_FILL}
              onClick={() => onCloseChatBox(true)}
              dataTestId="close-icon"
            />
          </div>
        </div>
      }
      width="50%"
      mask={false}
      autoFocus={false}
      afterOpenChange={(open: boolean) => open && inputRef.current?.focus()}
    >
      <div className="chat-box-container flex flex-column flex-gap-16 flex-fit flex-end">
        <div className="chat-box-header">
          {InstancesWithoutLogo.includes(window.location.hostname) ? (
            <ChatBotWithoutLogo className="chat-bot-icon" />
          ) : (
            <ChatBot className="chat-bot-icon" />
          )}
          <div className="modal-heading">{t('chatBotLabel.heading')}</div>
        </div>
        {evaluateRequestArray([
          fetchChatHistoryRequestStatus,
          selectedModelRequestStatus,
        ]) === REQUEST_STATUS.PROCESSING && isNewSession ? (
          <div className="chat-conversations flex flex-column flex-gap-16">
            <div className="chat-content user flex">
              <div className="chat full-width flex flex-gap-4">
                <div className="chat-message full-width">
                  <Skeleton
                    rootClassName="loading-skeleton"
                    active
                    paragraph={{ rows: 2 }}
                    title={false}
                  />
                </div>
                <img
                  src={profileImage}
                  alt="profileImage"
                  className="profile-icon"
                />
              </div>
            </div>
            <div className="chat-content bot flex">
              <div className="chat full-width flex flex-gap-4">
                {InstancesWithoutLogo.includes(window.location.hostname) ? (
                  <Icon iconName={ICONS.ROBOT_2_LINE} className="bot-icon" />
                ) : (
                  <ChatBot className="bot-icon" />
                )}
                <div className="chat-message full-width">
                  <Skeleton
                    rootClassName="loading-skeleton"
                    active
                    paragraph={{ rows: 2 }}
                    title={false}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="chat-box-conversations new-styled-scroll flex flex-column flex-gap-16">
            <div className="recommended-queries flex flex-center flex-wrap flex-gap-16">
              {recommendedQueries.map((item) => (
                <AccessibleDiv
                  className="query font-caption-bold cursor-pointer"
                  key={item}
                  onClick={() => {
                    if (
                      fetchResponseReqStatus === REQUEST_STATUS.SUCCESS &&
                      selectedConnection &&
                      selectedModelRequestStatus === REQUEST_STATUS.SUCCESS
                    ) {
                      onClickGenerate(
                        item?.charAt(0)?.toUpperCase() + item?.slice(1)
                      );
                    }
                  }}
                >
                  {item}
                </AccessibleDiv>
              ))}
            </div>
            <ChatConversations
              inputRef={inputRef}
              fetchResponseReqStatus={fetchResponseReqStatus}
              profileImage={profileImage}
              canSetInputFocus={!isConnectionDropdownVisible}
            />
          </div>
        )}
        <span
          style={{
            display: `${getConnectionError() !== null ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {getConnectionError()}
        </span>
        <span
          style={{
            display: `${getChatbotModelsError() !== null ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {getChatbotModelsError()}
        </span>
        <AccessibleDiv
          className="query-input flex flex-align-items-center flex-gap-16"
          onClick={() => inputRef?.current?.focus()}
          data-testid="query-input"
        >
          <Input
            className="query-input-field"
            ref={inputRef}
            placeholder={t('chatBotLabel.enterQueryPlaceholder')}
            value={queryString}
            onChange={(e: any) => setQueryString(e.target.value)}
            onPressEnter={() => onClickGenerate()}
            bordered={false}
            disabled={
              [fetchResponseReqStatus, selectConnectionRequestStatus].includes(
                REQUEST_STATUS.PROCESSING
              ) || !selectedConnection
            }
          />
          <Button
            title={t('chatBotLabel.generate')}
            onClick={() => onClickGenerate()}
          />
        </AccessibleDiv>
        {chats.length > 0 && (
          <div className="cebee-bot-footer-note font-small-bold">
            {t('cebeeBotFooterNote')}
          </div>
        )}
      </div>
    </DrawerComponent>
  );
};

export default ChatBoxModal;
