import { StatusEnum } from 'constants/granulate';

export const getStatusString = (status: string) => {
  switch (status) {
    case StatusEnum.COMPLETED:
    case StatusEnum.ACTIVATED:
      return 'active';
    case StatusEnum.INSTALLATION:
    case StatusEnum.ONGOING:
      return 'installing';
    case StatusEnum.FAILED:
      return 'failed';
    case StatusEnum.INITIATED:
      return 'initiated';
    case StatusEnum.DEACTIVATED:
      return 'deactivated';
    case StatusEnum.LEARNING:
      return 'learning';
    case StatusEnum.READY_FOR_ACTIVATION:
      return 'ready-for-activation';
    case StatusEnum.PASSIVE:
      return 'passive';
    case StatusEnum.LOADED:
      return 'loaded';
    case StatusEnum.BENCHMARK:
      return 'benchmark';
    case StatusEnum.OPTIMIZED:
      return 'optimized';
    case StatusEnum.UNINSTALLING:
      return 'uninstalling';
    case StatusEnum.UNINSTALLED:
      return 'uninstalled';
  }
};
