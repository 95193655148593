import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { CLUSTER_COUNT_OPTIONS } from 'pages/ContainerInsightsPage/components/GCPUsageMetering/constants';
import GranulateUsageMeteringLineChart from '../GranulateUsageMeteringLineChart';

type ClusterCountType = {
  data: any[];
  requestStatus: string;
  selectedValue: string;
  setSelectedValue: (val: string) => void;
  xField: string;
  yField: string;
  xTitle: string;
  yTitle: string;
  groupField: string;
  postfixSymbol: string;
  pdfView: boolean;
  isClusterCountTableView: boolean;
  setIsClusterCountTableView: (value: boolean) => void;
  tableData: any[];
  excelFilters: any[];
};

const ClusterCount = ({
  data,
  requestStatus,
  selectedValue,
  setSelectedValue,
  xField,
  yField,
  xTitle,
  yTitle,
  groupField,
  postfixSymbol,
  pdfView,
  isClusterCountTableView,
  setIsClusterCountTableView,
  tableData,
  excelFilters,
}: ClusterCountType) => {
  const { t } = useTranslation();

  const HeadingComponent = (
    <div className="flex flex-gap-12 flex-align-items-center">
      <div>{t('consolidatedDashboard.clusterCount')}</div>
      <div>
        <Radio.Group
          options={CLUSTER_COUNT_OPTIONS}
          onChange={(e) => setSelectedValue(e.target.value)}
          value={selectedValue}
          optionType="button"
          rootClassName="no-custom-style savings-types"
          style={{ height: 28 }}
        />
      </div>
    </div>
  );

  return (
    <GranulateUsageMeteringLineChart
      data={data}
      heading={t('consolidatedDashboard.clusterCount')}
      graphName="cluster-count"
      yTitle={yTitle}
      xTitle={xTitle}
      headingComponent={HeadingComponent}
      requestStatus={requestStatus}
      groupingField={groupField}
      xField={xField}
      yField={yField}
      postfixSymbol={postfixSymbol}
      pdfView={pdfView}
      setIsTableView={setIsClusterCountTableView}
      isTableView={isClusterCountTableView}
      tableData={tableData}
      excelFilters={excelFilters}
    />
  );
};

export default ClusterCount;
