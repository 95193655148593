import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchCmdbFields
 * @description Function to fetch CMDB fields
 * @return axios response from GET request
 */
export const fetchCmdbFields = async () => {
  return ServiceCalls.get(APIS.GET_CMDB_FIELDS);
};
