import { CancelToken } from 'axios';

import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function createTeam
 * @description Function to create a team
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createTeam = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_TEAM, body);
};

/**
 * @function updateTeam
 * @description Function to update a team
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const updateTeam = async (body: any) => {
  return ServiceCalls.post(APIS.UPDATE_TEAM, body);
};

/**
 * @function validateUniqueTeamName
 * @description Function to validate the team name
 * @param teamName team name to be validated
 * @param cancelToken cancel token
 * @return axios response from GET request
 */
export const validateUniqueTeamName = async (
  teamName: string,
  cancelToken?: CancelToken
) => {
  const api = APIS.VALIDATE_TEAM_NAME + teamName;
  return ServiceCalls.get(api, null, undefined, cancelToken);
};

/**
 * @function deleteTeam
 * @description Function to delete a team
 * @param teamName Name of the team to be deleted
 * @return axios response from DELETE request
 */
export const deleteTeam = async (teamName?: string) => {
  const api = APIS.DELETE_TEAM + '/' + teamName;
  return ServiceCalls.delete(api);
};
