import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
  CostMonitorComponents,
  DEFAULT_COST_MONITOR_FORM_VALUES,
} from 'pages/CostMonitorPage/constants';
import { LeftBackArrowIcon } from 'assets/icons';
import Button from 'components/Button';
import AccessibleDiv from 'components/AccessibleDiv';
import {
  costMonitor,
  setCostMonitorComponent,
  setCostMonitorFormData,
  setExistingAttachedSubscriptions,
  setIsTryAgain,
} from 'redux/costMonitorSlice';
import { providerList } from 'redux/providerSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import {
  ALERT_SUBSCRIPTION_THRESHOLD_TYPE,
  AnamolySubscriptionType,
} from 'pages/CostMonitorPage/types';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import BasicDetails from '../BasicDetails';
import SetupCostMonitor from './SetupCostMonitor';
import {
  createAwsMonitor,
  detachAwsMonitor,
  updateAwsMonitor,
} from './services';

const AWSCostMonitorForm = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'costMonitor.createCostMonitorLabels',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedProvider } = useSelector(providerList);
  const {
    isEditForm,
    isTryAgain,
    costMonitorFormData,
    existingAttachedSubscriptions,
  } = useSelector(costMonitor);

  const [createButtonClick, setCreateButtonClick] = useState(false);
  const [basicDetailsValidation, setBasicDetailsValidation] = useState(false);
  const [alertSubscriptionsValidation, setAlertSubscriptionsValidation] =
    useState(false);
  const [createMonitorRequestStatus, setCreateMonitorRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [subscriptionsBeforeEdit, setSubscriptionsBeforeEdit] = useState<
    string[]
  >([]);
  const [detachMonitorRequestStatus, setDetachMonitorRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    if (isTryAgain) {
      return;
    }

    if (isEditForm) {
      const subscriptions = costMonitorFormData.anomalySubscriptions.map(
        (item) => item.subscriptionArn ?? ''
      );
      dispatch(setExistingAttachedSubscriptions(subscriptions));
      setSubscriptionsBeforeEdit(subscriptions);
    } else {
      dispatch(setExistingAttachedSubscriptions([]));
      setSubscriptionsBeforeEdit([]);
    }
  }, []);

  /**
   * @function handleBackArrowClick
   * @description Callback function for click event of back arrow
   */
  const handleBackArrowClick = () => {
    dispatch(setIsTryAgain(false));
    if (isEditForm) {
      navigate(NAVIGATION_MENU_PATH.COST_MONITOR);
    } else {
      dispatch(setCostMonitorComponent(CostMonitorComponents.PROVIDER_LIST));
      dispatch(setCostMonitorFormData(DEFAULT_COST_MONITOR_FORM_VALUES));
    }
  };

  /**
   * @function onClickCreateMonitor
   * @description Function for validating and creating or updating a monitor
   */
  const onClickCreateMonitor = () => {
    setCreateButtonClick(!createButtonClick);
    if (!basicDetailsValidation || !alertSubscriptionsValidation) {
      return;
    }

    if (
      isEditForm &&
      existingAttachedSubscriptions.length !== subscriptionsBeforeEdit.length
    ) {
      detachMonitorFromSubscriptions();
    } else {
      createOrUpdateMonitor();
    }
  };

  /**
   * @function detachMonitorFromSubscriptions
   * @description Function to detach the monitor from subscription
   */
  const detachMonitorFromSubscriptions = () => {
    setDetachMonitorRequestStatus(REQUEST_STATUS.PROCESSING);
    const detachingSubscriptions = subscriptionsBeforeEdit.filter(
      (subscription) => !existingAttachedSubscriptions.includes(subscription)
    );

    const requests = detachingSubscriptions.map((subscription) => {
      const params = {
        connectorId: costMonitorFormData.connectorId,
        monitorArn: costMonitorFormData.monitorARN,
        subscriptionArn: subscription,
      };
      return detachAwsMonitor(params);
    });

    axios
      .all(requests)
      .then(() => {
        createOrUpdateMonitor();
        setDetachMonitorRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDetachMonitorRequestStatus,
          t('errorDetachingMonitor')
        );
      });
  };

  /**
   * @function createOrUpdateMonitor
   * @description Function to create or update a monitor for a connection
   */
  const createOrUpdateMonitor = () => {
    setCreateMonitorRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      ...costMonitorFormData,
      provider: selectedProvider,
      anomalySubscriptions: getAnomalySubscriptionsPayload(),
    };

    (isEditForm ? updateAwsMonitor(requestBody) : createAwsMonitor(requestBody))
      .then((res: any) => {
        if (res.status === 200) {
          setCreateMonitorRequestStatus(REQUEST_STATUS.SUCCESS);
          dispatch(setCostMonitorComponent(CostMonitorComponents.SUCCESS_PAGE));
          return;
        }
        setCreateMonitorRequestStatus(REQUEST_STATUS.ERROR);
        dispatch(
          setErrorMessage(res.data.message || t('errorCreatingCostMonitor'))
        );
        dispatch(setCostMonitorComponent(CostMonitorComponents.ERROR_PAGE));
      })
      .catch((e) => {
        onApiCallError(e, false, setCreateMonitorRequestStatus);
        dispatch(
          setErrorMessage(
            e.response.data.message || t('errorCreatingCostMonitor')
          )
        );
        dispatch(setCostMonitorComponent(CostMonitorComponents.ERROR_PAGE));
      });
  };

  /**
   * @function getAnomalySubscriptionsPayload
   * @description Function construct the anomaly subscriptions payload
   * @returns list of subscriptions
   */
  const getAnomalySubscriptionsPayload = () => {
    return costMonitorFormData.anomalySubscriptions
      .filter(
        (subscription) =>
          !subscription.subscriptionArn ||
          !existingAttachedSubscriptions.includes(subscription.subscriptionArn)
      )
      .map((subscription) => {
        if (subscription.existingSubscription) {
          return {
            subscriptionName: subscription.subscriptionName,
            subscriptionArn: subscription.subscriptionArn,
            existingSubscription: true,
          };
        }

        return {
          ...subscription,
          anomalyTotalImpactPercentage: fetchValueByThresholdType(
            ALERT_SUBSCRIPTION_THRESHOLD_TYPE.PERCENTAGE,
            subscription
          ),
          anomalyTotalImpactAbsolute: fetchValueByThresholdType(
            ALERT_SUBSCRIPTION_THRESHOLD_TYPE.AMOUNT,
            subscription
          ),
          thresholds: undefined,
        };
      });
  };

  /**
   * @function fetchValueByThresholdType
   * @description Function to find the threshold value by threshold type
   * @param thresholdType type of threshold
   * @param subscription Anomaly subscription for finding the value
   * @returns integer threshold value
   */
  const fetchValueByThresholdType = (
    thresholdType: string,
    subscription: AnamolySubscriptionType
  ) => {
    return Number(
      subscription?.thresholds?.find(
        (item) => item.thresholdType === thresholdType
      )?.thresholdValue
    );
  };

  return (
    <div className="create-monitor-form flex flex-column flex-fit">
      <div className="new-page-header create-monitor-form-header flex flex-align-items-center">
        <AccessibleDiv
          className="back-arrow flex cursor-pointer"
          onClick={handleBackArrowClick}
        >
          <LeftBackArrowIcon />
        </AccessibleDiv>
        <div className="flex flex-column title-section">
          <div className="modal-heading">{t('pageTitle')}</div>
          <div className="table-typography">{t('pageSubTitle')}</div>
        </div>
        <div className="create-button">
          <Button
            title={isEditForm ? t('update') : t('create')}
            loading={[
              createMonitorRequestStatus,
              detachMonitorRequestStatus,
            ].includes(REQUEST_STATUS.PROCESSING)}
            onClick={onClickCreateMonitor}
          />
        </div>
      </div>
      <div className="form-container page-content full-height flex flex-gap-16">
        <BasicDetails
          createButtonClick={createButtonClick}
          setBasicDetailsValidation={setBasicDetailsValidation}
        />
        <SetupCostMonitor
          createButtonClick={createButtonClick}
          setAlertSubscriptionsValidation={setAlertSubscriptionsValidation}
        />
      </div>
    </div>
  );
};

export default AWSCostMonitorForm;
