import i18n from 'i18n';

export enum SubscriptionQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const SUBSCRIPTION_QUICK_ACTIONS_LIST = [
  {
    id: SubscriptionQuickActions.EDIT,
    title: i18n.t('subscriptions.edit'),
  },
  {
    id: SubscriptionQuickActions.DELETE,
    title: i18n.t('subscriptions.delete'),
  },
];

export enum SubscriptionFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const SUBSCRIPTION_FREQUENCY_LIST = [
  {
    id: SubscriptionFrequency.DAILY,
    title: i18n.t('subscriptions.daily'),
  },
  {
    id: SubscriptionFrequency.WEEKLY,
    title: i18n.t('subscriptions.weekly'),
  },
  {
    id: SubscriptionFrequency.MONTHLY,
    title: i18n.t('subscriptions.monthly'),
  },
];

export const WEEKDAYS = [
  { value: 1, label: 'Sunday' },
  { value: 2, label: 'Monday' },
  { value: 3, label: 'Tuesday' },
  { value: 4, label: 'Wednesday' },
  { value: 5, label: 'Thursday' },
  { value: 6, label: 'Friday' },
  { value: 7, label: 'Saturday' },
];
