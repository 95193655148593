import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { sum, xor } from 'lodash';
import { selectContainerInsights } from 'redux/containerInsightsSlice';
import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { fetchConnectionById, getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  getDateMonthShortList,
  getListOfHours,
  getMonthAndYearDigitsFormat,
  getMonthAndYearStringFormat,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import {
  formatArrayToString,
  insertIndex,
  numberCommaSeparator,
} from 'utils/dataFormatterUtils';
import { generateGraphColors } from 'utils/dashboardUtils';
import { DateRangeFilter, MonthlyCostType } from 'types/dataTypes';
import {
  NamespaceUsageCostType,
  ClusterCostType,
} from 'pages/ContainerInsightsPage/types';
import {
  getCostTrendExportColumns,
  getNamespaceUsageCostExportColumns,
  getCostClusterCostExportColumns,
  getDateInterval,
} from 'pages/ContainerInsightsPage/utils';
import {
  DATE_INTERVALS,
  DATE_RANGES,
} from 'pages/ContainerInsightsPage/constants';

import { GRANULATE_GRAPHS } from '../GranulateUsageMetering/constants';
import {
  ClusterBreakdownType,
  EffectiveCpuUtilizationType,
  PodUsageCostType,
  EffectiveCpuUtilizationByClusterType,
} from './types';
import CostTrend from '../CostTrend';
import ClusterBreakdown from './components/ClusterBreakdown';
import { ClusterCost } from '../ClusterCost';
import EffectiveCPUUtilization from './components/EffectiveCpuUtilization';
import NamespaceUsageCost from '../Namespace Usage & Cost';
import EffectiveMemoryUtilizationByPods from './components/EffectiveMemoryUtilizationByPods';
import { PodUsageCostExportColumns } from './constants';
import {
  getClusterBreakdownExportColumns,
  getEffectiveCpuUtilizationExportColumns,
  getUsageMeteringExcelData,
} from './utils/exportToExcel';
import {
  getClustersKeyValueStructfilters,
  getFormattedStartDate,
  getDateFormatForGraphLabels,
  getEffectiveUtilizationDateQueryField,
  formatDateFieldData,
  getClusterBreakdownDateQueryField,
  getEffectiveUtilizationPercentageQueryField,
  getNamespacesKeyValueStructfilters,
} from './utils';

import './index.scss';

type GCPUsageMeteringProps = {
  projects: string[];
  setProjects: (projects: string[]) => void;
  projectsRequestStatus: string;
  setProjectsRequestStatus: (status: string) => void;
  clusters: string[];
  setClusters: (clusters: string[]) => void;
  clustersRequestStatus: string;
  setClustersRequestStatus: (status: string) => void;
  selectedProjects: string[];
  setSelectedProjects: (projects: string[]) => void;
  selectedClusters: string[];
  setSelectedClusters: (clusters: string[]) => void;
  PdfComponents: any[];
  getGranulateGraphComponent: (
    graphName: string,
    pdfView?: boolean
  ) => JSX.Element | undefined;
  selectedCpuCount: string;
  selectedCpuUtilized: string;
  granulateChartsExcelData: any[];
};

const GCPUsageMetering = ({
  projects,
  setProjects,
  projectsRequestStatus,
  clusters,
  selectedClusters,
  clustersRequestStatus,
  setClustersRequestStatus,
  setProjectsRequestStatus,
  selectedProjects,
  setSelectedClusters,
  setClusters,
  setSelectedProjects,
  PdfComponents,
  getGranulateGraphComponent,
  selectedCpuCount,
  selectedCpuUtilized,
  granulateChartsExcelData,
}: GCPUsageMeteringProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection } = useSelector(selectContainerInsights);
  const { showConsolidatedDashboard, selectedGranulateService } = useSelector(
    costOptimizationInsights
  );
  const {
    tableViewEnabled,
    showExpandGraphModal,
    expandedGraphName,
    pdfDownloadMode,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  // Cost Trend states
  const [costTrendValues, setCostTrendValues] = useState<MonthlyCostType[]>([]);
  const [costTrendDateRange, setCostTrendDateRange] = useState<string[]>([
    moment().subtract(11, 'months').format(YEAR_MONTH_WITHOUT_SEPARATOR),
    moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
  ]);
  const [costTrendNamespaces, setCostTrendNamespaces] = useState<string[]>([]);
  const [selectedCostTrendNamespaces, setSelectedCostTrendNamespaces] =
    useState<string[]>([]);
  const [costTrendRequestStatus, setCostTrendRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [costTrendNamespaceRequestStatus, setCostTrendNamespaceRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [isCostTrendTableView, setIsCostTrendTableView] = useState(false);

  // Cluster breakdown states
  const [clusterBreakdownData, setClusterBreakdownData] = useState<
    ClusterBreakdownType[]
  >([]);
  const [clusterBreakdownRequestStatus, setClusterBreakdownRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [isClusterBreakdownTableView, setIsClusterBreakdownTableView] =
    useState(false);
  const [clusterBreakdownDateFilter, setClusterBreakdownDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  // Cluster Cost
  const [clusterCostData, setClusterCostData] = useState<ClusterCostType[]>([]);
  const [clusterCostRequestStatus, setClusterCostRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [isClusterCostTableView, setIsClusterCostTableView] = useState(false);
  const [clusterCostDateFilter, setClusterCostDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  // Effective CPU Utilization
  const [effectiveUtilizationByCluster, setEffectiveUtilizationByCluster] =
    useState<EffectiveCpuUtilizationByClusterType[]>([]);
  const [effectiveCPUUtilizationLabels, setEffectiveCPUUtilizationLabels] =
    useState<string[]>([]);
  const [effectiveCPUUtilizationData, setEffectiveCPUUtilizationData] =
    useState<EffectiveCpuUtilizationType[]>([]);
  const [
    filteredEffectiveCPUUtilizationData,
    setFilteredEffectiveCPUUtilizationData,
  ] = useState<EffectiveCpuUtilizationType[]>([]);
  const [
    effectiveCpuUtilizationNamespaces,
    setEffectiveCpuUtilizationNamespaces,
  ] = useState<string[]>([]);
  const [
    selectedCpuUtilizationNamespaces,
    setSelectedCpuUtilizationNamespaces,
  ] = useState<string[]>([]);
  const [
    effectiveCPUUtilizationRequestStatus,
    setEffectiveCPUUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [
    isEffectiveCPUUtilizationTableView,
    setIsEffectiveCPUUtilizationTableView,
  ] = useState(false);
  const [
    effectiveCPUUtilizationDateFilter,
    setEffectiveCPUUtilizationDateFilter,
  ] = useState<DateRangeFilter>({
    option: DATE_RANGES.THIS_YEAR,
    startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
    endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
  });

  // Namespace Usage Cost
  const [namespaceUsageCostData, setNamespaceUsageCostData] = useState<
    NamespaceUsageCostType[]
  >([]);
  const [filteredNamespaceUsageCostData, setFilteredNamespaceUsageCostData] =
    useState<NamespaceUsageCostType[]>([]);
  const [selectedNamespaces, setSelectedNamespaces] = useState<string[]>([]);
  const [namespaceUsageCostRequestStatus, setNamespaceUsageCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [namespaceUsageCostTableView, setNamespaceUsageCostTableView] =
    useState(false);
  const [namespaceUsageCostDateFilter, setNamespaceUsageCostDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  // Pod Usage And Cost
  const [podUsageCostData, setPodUsageCostData] = useState<PodUsageCostType[]>(
    []
  );
  const [filteredPodUsageCostData, setFilteredPodUsageCostData] = useState<
    PodUsageCostType[]
  >([]);
  const [selectedPods, setSelectedPods] = useState<string[]>([]);
  const [podUsageCostRequestStatus, setPodUsageCostRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [podUsageCostTableView, setPodUsageCostTableView] = useState(false);
  const [podUsageCostDateFilter, setPodUsageCostDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  const connectorId = selectedGranulateService
    ? selectedGranulateService.connectorId
    : selectedConnection?.connectorId;

  useEffect(() => {
    setProjects([]);
    if (selectedConnection || selectedGranulateService) {
      fetchAndSetProjects();
    }
  }, [selectedConnection, selectedGranulateService]);

  useEffect(() => {
    setSelectedProjects(projects);
  }, [projects]);

  useEffect(() => {
    setClusters([]);
    setSelectedClusters([]);
    if (showConsolidatedDashboard) {
      setClusters(['granulate-cluster']);

      setClustersRequestStatus(REQUEST_STATUS.SUCCESS);
    } else if (selectedProjects.length > 0) {
      fetchClusters();
    }
  }, [selectedProjects, selectedGranulateService]);

  useEffect(() => {
    setSelectedClusters(clusters);
  }, [clusters]);

  useEffect(() => {
    if (selectedClusters.length > 0) {
      fetchCostTrendNamespaces();
    } else {
      setCostTrendNamespaces([]);
      setSelectedCostTrendNamespaces([]);
    }
  }, [selectedClusters]);

  useEffect(() => {
    if (selectedCostTrendNamespaces.length > 0) {
      fetchCostTrendData();
    } else {
      setCostTrendValues([]);
    }
  }, [selectedCostTrendNamespaces, costTrendDateRange]);

  useEffect(() => {
    if (selectedClusters.length > 0) {
      fetchClusterBreakdownData();
    } else {
      setClusterBreakdownData([]);
    }
  }, [selectedClusters, clusterBreakdownDateFilter]);

  useEffect(() => {
    if (selectedClusters.length > 0) {
      fetchClusterCostData();
    } else {
      setClusterCostData([]);
    }
  }, [selectedClusters, clusterCostDateFilter]);

  useEffect(() => {
    if (selectedClusters.length > 0) {
      fetchEffectiveCpuUtilizationData();
    } else {
      setEffectiveCPUUtilizationData([]);
    }
  }, [selectedClusters, effectiveCPUUtilizationDateFilter]);

  useEffect(() => {
    const namespaceFilteredData = effectiveCPUUtilizationData.filter((item) =>
      selectedCpuUtilizationNamespaces.includes(item.namespace)
    );
    const interval = getDateInterval(
      effectiveCPUUtilizationDateFilter.startDate,
      effectiveCPUUtilizationDateFilter.endDate
    );

    const data: EffectiveCpuUtilizationType[] = [];
    const utilizationByCluster: EffectiveCpuUtilizationByClusterType[] = [];
    selectedClusters.forEach((cluster) => {
      effectiveCPUUtilizationLabels.forEach((label) => {
        const dataItems = namespaceFilteredData.filter(
          (utilizationItem) =>
            formatDateFieldData(utilizationItem.time, interval).format(
              getDateFormatForGraphLabels(interval)
            ) === label && utilizationItem.cluster === cluster
        );
        utilizationByCluster.push({
          time: label,
          utilization: sum(dataItems.map((item) => Number(item.utilization))),
          cluster: cluster,
        });
        data.push(
          ...selectedCpuUtilizationNamespaces.map((namespace) => ({
            time: label,
            utilization:
              dataItems.find((dataItem) => dataItem.namespace === namespace)
                ?.utilization ?? 0,
            cluster: cluster,
            namespace: namespace,
          }))
        );
      });
    });
    setFilteredEffectiveCPUUtilizationData(data);
    setEffectiveUtilizationByCluster(utilizationByCluster);
  }, [effectiveCPUUtilizationData, selectedCpuUtilizationNamespaces]);

  useEffect(() => {
    if (selectedClusters.length > 0) {
      fetchNamespaceUsageAndCostData();
    } else {
      setNamespaceUsageCostData([]);
      setSelectedNamespaces([]);
    }
  }, [selectedClusters, namespaceUsageCostDateFilter]);

  useEffect(() => {
    setFilteredNamespaceUsageCostData(
      namespaceUsageCostData.filter((item) =>
        selectedNamespaces.includes(item.namespace)
      )
    );
  }, [namespaceUsageCostData, selectedNamespaces]);

  useEffect(() => {
    if (selectedClusters.length > 0) {
      fetchPodUsageAndCostData();
    } else {
      setPodUsageCostData([]);
      setSelectedPods([]);
    }
  }, [selectedClusters, podUsageCostDateFilter]);

  useEffect(() => {
    setFilteredPodUsageCostData(
      podUsageCostData.filter((item) => selectedPods.includes(item.pod))
    );
  }, [podUsageCostData, selectedPods]);

  useEffect(() => {
    if (selectedConnection) {
      dispatch(
        setExportToExcelData([
          ...getUsageMeteringExcelData(
            costTrendValues.map((value, index) => {
              return {
                month: getMonthAndYearStringFormat(value.month),
                cost: value.cost,
                key: index,
              };
            }),
            getExcelFilters(),
            {
              clusterBreakdownData,
              tableData: clusterBreakdownTableExportData(),
              clusterBreakdownDateFilter,
              clusterCostData,
              clusterCostDateFilter,
            },
            {
              effectiveCPUUtilizationData: filteredEffectiveCPUUtilizationData,
              tableData: effectiveCpuUtilizationTableExportData(),
              effectiveCPUUtilizationDateFilter,
            },
            {
              namespaceUsageCostData,
              namespaceUsageCostDateFilter,
              selectedNamespaces,
            },
            { podUsageCostData, podUsageCostDateFilter, selectedPods }
          ),
          ...granulateChartsExcelData,
        ])
      );
    }
  }, [
    selectedConnection,
    selectedProjects,
    selectedClusters,
    costTrendValues,
    clusterBreakdownData,
    clusterCostData,
    effectiveCPUUtilizationData,
    namespaceUsageCostData,
    podUsageCostData,
    clusterBreakdownDateFilter,
    clusterCostDateFilter,
    effectiveCPUUtilizationDateFilter,
    namespaceUsageCostDateFilter,
    selectedNamespaces,
    podUsageCostDateFilter,
    selectedPods,
    selectedCpuUtilized,
    selectedCpuCount,
  ]);

  useEffect(() => {
    setIsCostTrendTableView(tableViewEnabled);
    setIsClusterCostTableView(tableViewEnabled);
    setIsClusterBreakdownTableView(tableViewEnabled);
    setIsEffectiveCPUUtilizationTableView(tableViewEnabled);
    setNamespaceUsageCostTableView(tableViewEnabled);
    setPodUsageCostTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  /**
   * @function fetchAndSetProjects
   * @description Function to fetch the connection data and set projects
   */
  const fetchAndSetProjects = () => {
    setProjectsRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchConnectionById(connectorId ?? '')
      .then((res: any) => {
        setProjects(
          (res?.data?.responseData?.projectDatasetMap ?? []).map(
            (item: any) => item.project
          )
        );
        setProjectsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setProjectsRequestStatus);
        setProjects([]);
      });
  };

  /**
   * @function fetchClusters
   * @description Function to fetch the clusters for selected projects
   */
  const fetchClusters = () => {
    setClustersRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'cluster',
          field: QUERY_FIELDS.CLUSTER_LABELS_VALUE,
        },
      ],
      structColumns: [
        {
          label: 'cluster_labels',
          field: QUERY_FIELDS.LABELS,
        },
      ],
      groupBy: ['cluster'],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.CLUSTER_LABELS_KEY,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_VALUES.GOOG_K8S_CLUSTER_NAME,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.PROJECT_ID,
              comparator: COMPARATORS.IN,
              value: formatArrayToString(selectedProjects),
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
      cached: true,
    };

    getChartData(requestBody, selectedConnection?.connectorId)
      .then((res: any) => {
        const data = res.data;
        setClusters(data.map((item: any) => item.cluster) || []);
        setClustersRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setClustersRequestStatus);
        setClusters([]);
      });
  };

  /**
   * @function fetchCostTrendNamespaces
   * @description Function to fetch the cost trend namespaces
   */
  const fetchCostTrendNamespaces = () => {
    setCostTrendNamespaceRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'namespaces',
          field: QUERY_FIELDS.CLUSTER_NAMESPACE_LABELS_VALUE,
        },
      ],
      structColumns: [
        {
          label: 'cluster_namespace_labels',
          field: QUERY_FIELDS.LABELS,
        },
      ],
      groupBy: ['namespaces'],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.CLUSTER_NAMESPACE_LABELS_KEY,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_VALUES.K8S_NAMESPACE,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.PROJECT_ID,
              comparator: COMPARATORS.IN,
              value: formatArrayToString(selectedProjects),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        {
          conjunctToNextFilter: CONJUNCTIONS.AND,
          keyValueStructfilters:
            getClustersKeyValueStructfilters(selectedClusters),
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
      cached: true,
    };

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        const allNamespaces: string[] = (res?.data || []).map(
          (item: any) => item.namespaces
        );
        setCostTrendNamespaces(allNamespaces);
        setSelectedCostTrendNamespaces(allNamespaces.slice(0, 10));
        setCostTrendNamespaceRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendNamespaceRequestStatus);
      });
  };

  /**
   * @function getKeyValueStructFiltersForTrendQuery
   * @description Function to construct the key value struct filters for cost trends query
   * @returns List of key value struct filters
   */
  const getKeyValueStructFiltersForTrendQuery = () => {
    const keyValueStructFilters = [
      {
        keyValueStructfilters:
          getClustersKeyValueStructfilters(selectedClusters),
      },
    ];

    if (xor(costTrendNamespaces, selectedCostTrendNamespaces).length !== 0) {
      keyValueStructFilters.push({
        keyValueStructfilters: getNamespacesKeyValueStructfilters(
          selectedCostTrendNamespaces
        ),
      });
    }

    return keyValueStructFilters;
  };

  /**
   * @function fetchCostTrendData
   * @description Function to fetch the cost trend data
   */
  const fetchCostTrendData = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: QUERY_FIELDS.COST,
        },
        {
          label: 'month',
          field: QUERY_FIELDS.INVOICE_MONTH,
        },
      ],
      structColumns: [
        {
          label: 'cluster_labels',
          field: QUERY_FIELDS.LABELS,
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: costTrendDateRange[0],
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: costTrendDateRange[1],
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.PROJECT_ID,
              comparator: COMPARATORS.IN,
              value: formatArrayToString(selectedProjects),
            },
            {
              comparator: COMPARATORS.EQUALS,
              field: QUERY_FIELDS.CLUSTER_LABELS_KEY,
              value: QUERY_VALUES.GOOG_K8S_CLUSTER_NAME,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      keyValueStructFilterGroups: getKeyValueStructFiltersForTrendQuery(),
      dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
      cached: true,
    };

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        const data = res.data;
        let costData: MonthlyCostType[] = [];
        const labels = getMonthYearShortList(
          costTrendDateRange[0],
          costTrendDateRange[1]
        );
        labels.forEach((label) => {
          const cost = data.find(
            (item: any) => item.month === getMonthAndYearDigitsFormat(label)
          );
          if (cost) {
            costData.push(cost);
            return;
          }
          costData.push({ month: getMonthAndYearDigitsFormat(label), cost: 0 });
        });
        setCostTrendValues(costData);
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendRequestStatus);
      });
  };

  /**
   * @function getAdditionalFiltersForHourInterval
   * @description Function to get the additional filters for hour interval
   * @param startDate start date
   * @param endDate end date
   * @returns additional filters array
   */
  const getAdditionalFiltersForHourInterval = (
    startDate: string,
    endDate: string
  ) => {
    const interval = getDateInterval(startDate, endDate);
    if (interval === DATE_INTERVALS.HOUR) {
      return [
        {
          field: QUERY_FIELDS.USAGE_END_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: `(TIMESTAMP('${moment(startDate).format(
            HYPHEN_DATE_FORMAT
          )} 00:00:00 UTC'))`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.USAGE_END_TIME,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: `(TIMESTAMP('${moment(endDate).format(
            HYPHEN_DATE_FORMAT
          )} 23:00:00 UTC'))`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
    }
    return [];
  };

  /**
   * @function fetchClusterBreakdownData
   * @description Function to fetch the Cluster breakdown data
   */
  const fetchClusterBreakdownData = () => {
    setClusterBreakdownRequestStatus(REQUEST_STATUS.PROCESSING);

    const interval = getDateInterval(
      clusterBreakdownDateFilter.startDate,
      clusterBreakdownDateFilter.endDate
    );

    let labels: string[] = [];
    if (interval === DATE_INTERVALS.HOUR) {
      labels = getListOfHours();
    } else {
      labels = getDateMonthShortList(
        getFormattedStartDate(
          clusterBreakdownDateFilter.startDate,
          interval
        ).format(HYPHEN_DATE_FORMAT),
        clusterBreakdownDateFilter.endDate,
        interval,
        getDateFormatForGraphLabels(interval)
      );
    }
    const requestBody = {
      columns: [
        {
          label: 'cluster',
          field: QUERY_FIELDS.CLUSTER_LABELS_VALUE,
        },
        {
          label: 'time',
          field: getClusterBreakdownDateQueryField(interval),
        },
        {
          label: 'cost',
          field: QUERY_FIELDS.COST,
        },
      ],
      structColumns: [
        {
          label: 'cluster_labels',
          field: QUERY_FIELDS.LABELS,
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['cluster', 'time'],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(clusterBreakdownDateFilter.startDate).format(
                YEAR_MONTH_WITHOUT_SEPARATOR
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(clusterBreakdownDateFilter.endDate).format(
                YEAR_MONTH_WITHOUT_SEPARATOR
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.CLUSTER_LABELS_KEY,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_VALUES.GOOG_K8S_CLUSTER_NAME,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.PROJECT_ID,
              comparator: COMPARATORS.IN,
              value: formatArrayToString(selectedProjects),
            },
            ...getAdditionalFiltersForHourInterval(
              clusterBreakdownDateFilter.startDate,
              clusterBreakdownDateFilter.endDate
            ),
          ],
        },
      ],
      keyValueStructFilterGroups: [
        {
          keyValueStructfilters:
            getClustersKeyValueStructfilters(selectedClusters),
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
      cached: true,
    };

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        const data: ClusterBreakdownType[] = res.data || [];
        const clusterData: ClusterBreakdownType[] = [];
        selectedClusters.forEach((cluster) => {
          labels.forEach((label) => {
            clusterData.push({
              time: label,
              cost:
                data.find(
                  (item) =>
                    formatDateFieldData(item.time, interval).format(
                      getDateFormatForGraphLabels(interval)
                    ) === label && item.cluster === cluster
                )?.cost ?? 0,
              cluster: cluster,
            });
          });
        });
        setClusterBreakdownData(clusterData);
        setClusterBreakdownRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setClusterBreakdownRequestStatus);
      });
  };

  /**
   * @function fetchClusterCostData
   * @description Function to fetch the Namespace Usage and Cost data
   */
  const fetchClusterCostData = () => {
    setClusterCostRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'cluster',
          field: QUERY_FIELDS.CLUSTER_LABELS_VALUE,
        },
        {
          label: 'cost',
          field: QUERY_FIELDS.COST,
        },
      ],
      structColumns: [
        {
          label: 'cluster_labels',
          field: QUERY_FIELDS.LABELS,
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['cluster'],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(clusterCostDateFilter.startDate).format(
                YEAR_MONTH_WITHOUT_SEPARATOR
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(clusterCostDateFilter.endDate).format(
                YEAR_MONTH_WITHOUT_SEPARATOR
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.CLUSTER_LABELS_KEY,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_VALUES.GOOG_K8S_CLUSTER_NAME,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.PROJECT_ID,
              comparator: COMPARATORS.IN,
              value: formatArrayToString(selectedProjects),
            },
            ...getAdditionalFiltersForHourInterval(
              clusterCostDateFilter.startDate,
              clusterCostDateFilter.endDate
            ),
          ],
        },
      ],
      keyValueStructFilterGroups: [
        {
          keyValueStructfilters:
            getClustersKeyValueStructfilters(selectedClusters),
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
      cached: true,
    };

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        const data: ClusterCostType[] = res.data ?? [];
        const colors = generateGraphColors(data?.length ?? 0);
        setClusterCostData(
          data.map((item) => ({ ...item, color: colors.pop() ?? '' }))
        );
        setClusterCostRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setClusterCostRequestStatus);
      });
  };

  /**
   * @function fetchEffectiveCpuUtilizationData
   * @description Function to fetch the Effective CPU Utilization data
   */
  const fetchEffectiveCpuUtilizationData = () => {
    setEffectiveCPUUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);

    const interval = getDateInterval(
      effectiveCPUUtilizationDateFilter.startDate,
      effectiveCPUUtilizationDateFilter.endDate
    );

    let labels: string[] = [];
    if (interval === DATE_INTERVALS.HOUR) {
      labels = getListOfHours();
    } else {
      labels = getDateMonthShortList(
        getFormattedStartDate(
          effectiveCPUUtilizationDateFilter.startDate,
          interval
        ).format(HYPHEN_DATE_FORMAT),
        effectiveCPUUtilizationDateFilter.endDate,
        interval,
        getDateFormatForGraphLabels(interval)
      );
    }

    const requestBody = {
      columns: [
        {
          label: 'utilization',
          field: getEffectiveUtilizationPercentageQueryField(interval),
        },
        {
          label: 'time',
          field: getEffectiveUtilizationDateQueryField(interval),
        },
        {
          label: 'cluster',
          field: QUERY_FIELDS.CLUSTER_NAME,
        },
        {
          label: 'namespace',
          field: QUERY_FIELDS.NAMESPACE,
        },
      ],
      aggregators: [
        {
          label: 'utilization',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['time', 'cluster', 'namespace'],
      orderBy: [
        {
          label: 'time',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.START_TIME,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: effectiveCPUUtilizationDateFilter.startDate,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.START_TIME,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(effectiveCPUUtilizationDateFilter.endDate)
                .add(1, 'day')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.GCP_RESOURCE_NAME,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_VALUES.CPU,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
      cached: true,
    };

    let utilizationRequests: any[] = [];

    selectedProjects.forEach((project) => {
      const params = {
        project: project,
      };
      utilizationRequests.push(getChartData(requestBody, connectorId, params));
    });

    axios
      .all(utilizationRequests)
      .then((responses: any[]) => {
        let data: any[] = [];
        responses.forEach((res) => {
          data.push(...res.data);
        });
        setEffectiveCPUUtilizationLabels(labels);
        let uniqueNamespaces: string[] = [];

        if (showConsolidatedDashboard) {
          data.forEach((item) => {
            if (
              item.cluster === 'granulate-cluster' &&
              !uniqueNamespaces.includes(item.namespace)
            ) {
              uniqueNamespaces.push(item.namespace);
            }
          });
        } else {
          data.forEach((item) => {
            if (!uniqueNamespaces.includes(item.namespace)) {
              uniqueNamespaces.push(item.namespace);
            }
          });
        }

        setEffectiveCpuUtilizationNamespaces(uniqueNamespaces);
        setSelectedCpuUtilizationNamespaces(uniqueNamespaces.slice(0, 10));
        setEffectiveCPUUtilizationData(data);
        setEffectiveCPUUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEffectiveCPUUtilizationRequestStatus);
      });
  };

  /**
   * @function fetchNamespaceUsageAndCostData
   * @description Function to fetch the Namespace Usage and Cost data
   */
  const fetchNamespaceUsageAndCostData = () => {
    setNamespaceUsageCostRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'namespace',
          field: QUERY_FIELDS.CLUSTER_LABELS_VALUE,
        },
        {
          label: 'costAfterCredit',
          field: QUERY_FIELDS.NAMESPACE_COST_AFTER_CREDIT,
        },
      ],
      structColumns: [
        {
          label: 'cluster_labels',
          field: QUERY_FIELDS.LABELS,
        },
      ],
      aggregators: [
        {
          label: 'costAfterCredit',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['namespace'],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(namespaceUsageCostDateFilter.startDate).format(
                YEAR_MONTH_WITHOUT_SEPARATOR
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(namespaceUsageCostDateFilter.endDate).format(
                YEAR_MONTH_WITHOUT_SEPARATOR
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.CLUSTER_LABELS_KEY,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_VALUES.K8S_NAMESPACE,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.PROJECT_ID,
              comparator: COMPARATORS.IN,
              value: formatArrayToString(selectedProjects),
            },
            ...getAdditionalFiltersForHourInterval(
              namespaceUsageCostDateFilter.startDate,
              namespaceUsageCostDateFilter.endDate
            ),
          ],
        },
      ],
      keyValueStructFilterGroups: [
        {
          keyValueStructfilters:
            getClustersKeyValueStructfilters(selectedClusters),
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
      cached: true,
    };

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        const data: NamespaceUsageCostType[] = res.data ?? [];
        data.sort(
          (item1, item2) => item2.costAfterCredit - item1.costAfterCredit
        );
        setNamespaceUsageCostData(data);
        setSelectedNamespaces(data.slice(0, 10).map((item) => item.namespace));
        setFilteredNamespaceUsageCostData(data.slice(0, 10));
        setNamespaceUsageCostRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setNamespaceUsageCostRequestStatus);
      });
  };

  /**
   * @function fetchPodUsageAndCostData
   * @description Function to fetch the Pod usage and cost data
   */
  const fetchPodUsageAndCostData = () => {
    setPodUsageCostRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'usageAmount',
          field: QUERY_FIELDS.USAGE_AMOUNT,
        },
        {
          label: 'pod',
          field: QUERY_FIELDS.LABELS_VALUE,
        },
      ],
      structColumns: [
        {
          label: 'labels',
          field: QUERY_FIELDS.LABELS,
        },
      ],
      aggregators: [
        {
          label: 'usageAmount',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['pod'],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.START_TIME,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: podUsageCostDateFilter.startDate,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.START_TIME,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(podUsageCostDateFilter.endDate)
                .add(1, 'day')
                .format(HYPHEN_DATE_FORMAT),

              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.LABELS_KEY,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_VALUES.APP,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.CLUSTER_NAME,
              comparator: COMPARATORS.IN,
              value: formatArrayToString(selectedClusters),
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
      cached: true,
    };

    let podUsageCostRequests: any[] = [];

    selectedProjects.forEach((project) => {
      const params = {
        project: project,
      };
      podUsageCostRequests.push(getChartData(requestBody, connectorId, params));
    });

    axios
      .all(podUsageCostRequests)
      .then((responses: any[]) => {
        let data: PodUsageCostType[] = [];
        responses.forEach((res) => {
          data.push(...res.data);
        });
        data.sort((item1, item2) => item2.usageAmount - item1.usageAmount);
        setPodUsageCostData(data);
        setSelectedPods(data.slice(0, 10).map((item) => item.pod));
        setFilteredPodUsageCostData(data.slice(0, 10));
        setPodUsageCostRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setPodUsageCostRequestStatus);
      });
  };

  /**
   * @function clusterBreakdownTableExportData
   * @description Function to return the cluster breakdown data for tabular view export
   * @returns List of grouped cluster breakdown data
   */
  const clusterBreakdownTableExportData = () => {
    const groupedByCluster: any[] = [];
    clusterBreakdownData.forEach((item) => {
      const existingCluster = groupedByCluster.find(
        (obj) => obj.cluster === item.cluster
      );
      if (existingCluster) {
        existingCluster[
          `${item.time}-cost`
        ] = `${currencySymbol}${numberCommaSeparator(item.cost)}`;
      } else {
        groupedByCluster.push({
          cluster: item.cluster,
          [`${item.time}-cost`]: `${currencySymbol}${numberCommaSeparator(
            item.cost
          )}`,
          key: groupedByCluster.length,
          slNo: groupedByCluster.length,
        });
      }
    });
    return groupedByCluster;
  };

  /**
   * @function effectiveCpuUtilizationTableExportData
   * @description Function to return the effective cpu utilization data for tabular view export
   * @returns List of cpu utilization grouped by cluster and namespace
   */
  const effectiveCpuUtilizationTableExportData = () => {
    const groupedByClusterAndNamespace: any[] = [];
    filteredEffectiveCPUUtilizationData.forEach((item) => {
      const existingCluster = groupedByClusterAndNamespace.find(
        (obj) =>
          obj.cluster === item.cluster && obj.namespace === item.namespace
      );
      if (existingCluster) {
        existingCluster[`${item.time}-utilization`] = `${numberCommaSeparator(
          item.utilization ?? 0
        )}%`;
      } else {
        groupedByClusterAndNamespace.push({
          cluster: item.cluster,
          namespace: item.namespace,
          [`${item.time}-utilization`]: `${numberCommaSeparator(
            item.utilization
          )}%`,
          key: groupedByClusterAndNamespace.length,
          slNo: groupedByClusterAndNamespace.length,
        });
      }
    });
    return groupedByClusterAndNamespace;
  };

  /**
   * @function getGraphComponent
   * @description Function to return the react components for graphs
   * @param graphName Name of the graph
   * @param pdfView Is the graph for pdf download, defaults to false
   */
  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'cost-trend':
        return (
          <CostTrend
            costTrendValues={costTrendValues}
            dateRange={costTrendDateRange}
            setDateRange={setCostTrendDateRange}
            allNamespaces={costTrendNamespaces}
            selectedNamespaces={selectedCostTrendNamespaces}
            setSelectedNamespaces={setSelectedCostTrendNamespaces}
            requestStatus={[
              costTrendRequestStatus,
              projectsRequestStatus,
              clustersRequestStatus,
              costTrendNamespaceRequestStatus,
            ]}
            costTrendNamespaceRequestStatus={costTrendNamespaceRequestStatus}
            setIsCostTrendTableView={setIsCostTrendTableView}
            isCostTrendTableView={isCostTrendTableView}
            pdfView={pdfView}
            excelFilters={[
              ...getExcelFilters(),
              {
                heading: t('excelExportLabels.namespaces'),
                value: selectedCostTrendNamespaces.toString(),
              },
              {
                heading: t('excelExportLabels.startDate'),
                value: moment(costTrendDateRange[0]).format(MONTH_YEAR_FORMAT),
              },
              {
                heading: t('excelExportLabels.endDate'),
                value: moment(costTrendDateRange[1]).format(MONTH_YEAR_FORMAT),
              },
            ]}
          />
        );

      case 'cluster-breakdown':
        return (
          <ClusterBreakdown
            clusterBreakdownData={clusterBreakdownData}
            tableData={clusterBreakdownTableExportData()}
            isClusterBreakdownTableView={isClusterBreakdownTableView}
            setIsClusterBreakdownTableView={setIsClusterBreakdownTableView}
            requestStatus={[
              clusterBreakdownRequestStatus,
              projectsRequestStatus,
              clustersRequestStatus,
            ]}
            pdfView={pdfView}
            clusterBreakdownDateFilter={clusterBreakdownDateFilter}
            setClusterBreakdownDateFilter={setClusterBreakdownDateFilter}
            excelFilters={getExcelFilters()}
          />
        );

      case 'cluster-cost':
        return (
          <ClusterCost
            isClusterCostTableView={isClusterCostTableView}
            setClusterCostTableView={setIsClusterCostTableView}
            requestStatus={[
              clusterCostRequestStatus,
              projectsRequestStatus,
              clustersRequestStatus,
            ]}
            clusterCostData={clusterCostData}
            pdfView={pdfView}
            clusterCostDateFilter={clusterCostDateFilter}
            setClusterCostDateFilter={setClusterCostDateFilter}
            excelFilters={getExcelFilters()}
          />
        );

      case 'effective-cpu-utilization':
        return (
          <EffectiveCPUUtilization
            effectiveUtilizationByCluster={effectiveUtilizationByCluster}
            tableData={effectiveCpuUtilizationTableExportData()}
            allNamespaces={effectiveCpuUtilizationNamespaces}
            selectedNamespaces={selectedCpuUtilizationNamespaces}
            setSelectedNamespaces={setSelectedCpuUtilizationNamespaces}
            requestStatus={[
              effectiveCPUUtilizationRequestStatus,
              projectsRequestStatus,
              clustersRequestStatus,
            ]}
            isTableView={isEffectiveCPUUtilizationTableView}
            setIsTableView={setIsEffectiveCPUUtilizationTableView}
            effectiveCPUUtilizationData={filteredEffectiveCPUUtilizationData}
            pdfView={pdfView}
            cpuUtilizationDateFilter={effectiveCPUUtilizationDateFilter}
            setCpuUtilizationDateFilter={setEffectiveCPUUtilizationDateFilter}
            excelFilters={getExcelFilters()}
          />
        );

      case 'namespace-usage-cost':
        return (
          <NamespaceUsageCost
            namespaceUsageCostData={namespaceUsageCostData}
            filteredNamespaceUsageCostData={filteredNamespaceUsageCostData}
            selectedNamespaces={selectedNamespaces}
            setSelectedNamespaces={setSelectedNamespaces}
            requestStatus={[
              namespaceUsageCostRequestStatus,
              projectsRequestStatus,
              clustersRequestStatus,
            ]}
            namespaceUsageCostTableView={namespaceUsageCostTableView}
            setNamespaceUsageCostTableView={setNamespaceUsageCostTableView}
            pdfView={pdfView}
            namespaceUsageCostDateFilter={namespaceUsageCostDateFilter}
            setNamespaceUsageCostDateFilter={setNamespaceUsageCostDateFilter}
            excelFilters={getExcelFilters()}
          />
        );

      case 'pod-usage-cost':
        return (
          <EffectiveMemoryUtilizationByPods
            podUsageCostData={podUsageCostData}
            filteredPodUsageCostData={filteredPodUsageCostData}
            selectedPods={selectedPods}
            setSelectedPods={setSelectedPods}
            requestStatus={[
              podUsageCostRequestStatus,
              projectsRequestStatus,
              clustersRequestStatus,
            ]}
            podUsageCostTableView={podUsageCostTableView}
            setPodUsageCostTableView={setPodUsageCostTableView}
            pdfView={pdfView}
            podUsageCostDateFilter={podUsageCostDateFilter}
            setPodUsageCostDateFilter={setPodUsageCostDateFilter}
            excelFilters={getExcelFilters()}
          />
        );
    }
  };

  /**
   * @function getExcelFilters
   * @description Function to return the filters applied for excel export
   * @returns List of filters applied
   */
  const getExcelFilters = () => {
    return [
      {
        heading: t('excelExportLabels.connectionName'),
        value: showConsolidatedDashboard
          ? selectedGranulateService?.connectionName
          : selectedConnection?.displayName,
      },
      {
        heading: t('excelExportLabels.projects'),
        value: selectedProjects.toString(),
      },
      {
        heading: t('excelExportLabels.clusters'),
        value: selectedClusters.toString(),
      },
    ];
  };

  /**
   * @function getPdfMetaData
   * @description Function to return the metadata for pdf file export
   * @returns Object containing metadata for pdf file export
   */
  const getPdfMetaData = () => {
    return {
      viewName: t('dashNav.usageMetering'),
      fileName: showConsolidatedDashboard
        ? selectedGranulateService?.connectionName ?? ''
        : selectedConnection?.displayName ?? '',
      heading: showConsolidatedDashboard
        ? selectedGranulateService?.connectionName ?? ''
        : selectedConnection?.displayName ?? '',
      subtitle1: showConsolidatedDashboard
        ? selectedGranulateService?.name ?? ''
        : selectedConnection?.dataSourceType ?? '',
      provider: showConsolidatedDashboard
        ? selectedGranulateService?.provider ?? ''
        : selectedConnection?.provider,
    };
  };

  return (
    <>
      {showExpandGraphModal && (
        <ExpandModal
          show={showExpandGraphModal}
          graphContent={
            GRANULATE_GRAPHS.includes(expandedGraphName)
              ? getGranulateGraphComponent(expandedGraphName)
              : getGraphComponent(expandedGraphName)
          }
        />
      )}
      {getGraphComponent('cost-trend')}
      <Row gutter={[24, 24]}>
        <Col span={14}>{getGraphComponent('cluster-breakdown')}</Col>
        <Col span={10}>{getGraphComponent('cluster-cost')}</Col>
      </Row>
      {getGraphComponent('effective-cpu-utilization')}
      <Row gutter={[24, 24]}>
        <Col span={12}>{getGraphComponent('namespace-usage-cost')}</Col>
        <Col span={12}>{getGraphComponent('pod-usage-cost')}</Col>
      </Row>

      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfContent={[
            ...PdfComponents,
            {
              element: getGraphComponent('cost-trend', true),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: 'cost-trend',
              column: getCostTrendExportColumns(),
              body: costTrendValues.map((value, index) => ({
                month: getMonthAndYearStringFormat(value.month),
                cost: numberCommaSeparator(value.cost),
                slNo: index,
              })),
              tableName: t('graphHeadings.costTrend'),
              isTableView: isCostTrendTableView,
            },
            {
              element: getGraphComponent('cluster-breakdown', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'cluster-breakdown',
              column: getClusterBreakdownExportColumns(clusterBreakdownData),
              body: clusterBreakdownTableExportData(),
              tableName: t('graphHeadings.clusterBreakdown'),
              isTableView: isClusterBreakdownTableView,
            },
            {
              element: getGraphComponent('cluster-cost', true),
              contentType: CHART_TYPES.DOUGHNUT_CHART,
              graphName: 'cluster-cost',
              column: getCostClusterCostExportColumns(),
              body: insertIndex(clusterCostData),
              tableName: t('graphHeadings.clusterCost'),
              isTableView: isClusterCostTableView,
              legends: clusterCostData.map((item) => ({
                name: item.cluster,
                color: item.color,
              })),
            },
            {
              element: getGraphComponent('effective-cpu-utilization', true),
              contentType: CHART_TYPES.LINE_CHART,
              graphName: 'effective-cpu-utilization',
              column: getEffectiveCpuUtilizationExportColumns(
                filteredEffectiveCPUUtilizationData
              ),
              body: effectiveCpuUtilizationTableExportData(),
              tableName: t('graphHeadings.effectiveCpuUtilization'),
              isTableView: isEffectiveCPUUtilizationTableView,
            },
            {
              element: getGraphComponent('namespace-usage-cost', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'namespace-usage-cost',
              column: getNamespaceUsageCostExportColumns(),
              body: insertIndex(filteredNamespaceUsageCostData),
              tableName: t('graphHeadings.namespaceUsageCost'),
              isTableView: namespaceUsageCostTableView,
            },
            {
              element: getGraphComponent('pod-usage-cost', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'pod-usage-cost',
              column: PodUsageCostExportColumns,
              body: insertIndex(filteredPodUsageCostData),
              tableName: t('graphHeadings.effectiveMemoryUtilizationByPod'),
              isTableView: podUsageCostTableView,
            },
          ]}
          pdfMetaData={getPdfMetaData()}
          pdfCardData={{
            costOptmization: {
              cards: showConsolidatedDashboard
                ? getGranulateGraphComponent('info-cards', true)
                : undefined,
              insertAtStart: true,
            },
          }}
        />
      )}
    </>
  );
};

export default GCPUsageMetering;
