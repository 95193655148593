import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'components/Table';
import ConfirmModal from 'components/ConfirmModal';
import QuickActionMenu from 'components/QuickActionMenu';

import {
  GranulateQuickActions,
  GRANULATE_CONNECTION_QUICKACTIONS,
  ServiceStatus,
} from './constants';

import './index.scss';

type InstanceTableProps = {
  setSelectedInstances: (selectedItems: any) => void;
  selectedInstances: any[];
  data: any[];
  loading: boolean;
  isSelectAll: boolean;
  onClickDeactivate: (hostNames: string[]) => void;
  onClickActivate: (hostnames: string[]) => void;
  showActivateConfirmModal: boolean;
  setShowActivateConfirmModal: (value: boolean) => void;
  showDeactivateConfirmModal: boolean;
  setShowDeactivateConfirmModal: (value: boolean) => void;
  loadModel: boolean;
  selectedRowKeys: number[];
  setSelectedRowKeys: (rows: number[]) => void;
};

const InstanceTable = ({
  setSelectedInstances,
  selectedInstances,
  data,
  loading,
  isSelectAll,
  selectedRowKeys,
  setSelectedRowKeys,
  onClickDeactivate,
  onClickActivate,
  showActivateConfirmModal,
  setShowActivateConfirmModal,
  showDeactivateConfirmModal,
  setShowDeactivateConfirmModal,
  loadModel,
}: InstanceTableProps) => {
  const { t } = useTranslation();

  const [actionRecord, setActionRecord] = useState<any>();

  let instanceLabel;

  if (isSelectAll) {
    instanceLabel = t('granulateInsightDashboard.allInstances');
  } else if (selectedInstances.length > 1) {
    instanceLabel = t('granulateInsightDashboard.allSelectedInstances');
  } else {
    instanceLabel = t('granulateInsightDashboard.selectedInstances');
  }

  /**
   * @function onSelectTableRow
   * @description Callback function for row selection
   * @param record selected row data.
   * @param checked boolean value to indicate row selection or deselection
   */
  const onSelectTableRow = (record: any, checked: boolean) => {
    if (checked) {
      setSelectedInstances([...selectedInstances, record]);
      return;
    }
    setSelectedInstances((prevSelectedInstances: any) =>
      prevSelectedInstances.filter(
        (instance: any) => instance.instanceName !== record.instanceName
      )
    );
  };

  const onSelectAllTableRow = (selected: boolean, record: any[]) => {
    setSelectedInstances([]);
    if (selected) {
      setSelectedInstances(record);
    }
  };

  const getQuickActionsMenu = (record: any) => {
    if (record.status === ServiceStatus.PASSIVE)
      return [GRANULATE_CONNECTION_QUICKACTIONS[1]];
    if (record.status === ServiceStatus.ACTIVE)
      return [GRANULATE_CONNECTION_QUICKACTIONS[0]];
    return [];
  };

  const handleQuickAction = (action: any, record: any) => {
    setActionRecord(record);
    switch (action) {
      case GranulateQuickActions.ACTIVATED:
        setShowActivateConfirmModal(true);
        break;
      case GranulateQuickActions.DEACTIVATE:
        setShowDeactivateConfirmModal(true);
        break;
    }
  };

  const deactivateInstance = () => {
    if (selectedInstances.length > 0) {
      onClickDeactivate(
        selectedInstances.map((instance: any) => instance.instanceName)
      );
      return;
    }
    onClickDeactivate([actionRecord.instanceName]);
  };

  const activateInstance = () => {
    if (selectedInstances.length > 0) {
      onClickActivate(
        selectedInstances.map((instance: any) => instance.instanceName)
      );
      return;
    }
    onClickActivate([actionRecord.instanceName]);
  };

  const columns = [
    {
      title: t('granulateInsightDashboard.instanceName'),
      dataIndex: 'instanceName',
      key: 'instanceName',
      width: 150,
      fixed: true,
      render: (text: any) => {
        return <span className="font-caption">{text}</span>;
      },
    },
    {
      title: t('granulateInsightDashboard.agentVersion'),
      key: 'agentVersion',
      dataIndex: 'agentVersion',
      align: 'center' as const,
      sorter: (a: any, b: any) => a.agentVersion.localeCompare(b.agentVersion),
      width: 120,
      render: (text: any) => {
        return (
          <div className="flex flex-gap-4 flex-center">
            <span className="font-caption">{text}</span>
          </div>
        );
      },
    },
    {
      title: t('granulateInsightDashboard.status'),
      key: 'status',
      dataIndex: 'status',
      align: 'center' as const,
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      width: 100,
      render: (text: any) => {
        return <span className={`status ${text?.toLowerCase()}`}>{text}</span>;
      },
    },
    {
      title: t('granulateInsightDashboard.action'),
      key: 'action',
      align: 'center',
      width: 100,
      render: (_text: string, record: any) => (
        <QuickActionMenu
          quickActions={getQuickActionsMenu(record)}
          quickActionHandler={(action) => handleQuickAction(action, record)}
          disabled={selectedInstances.length > 1}
        />
      ),
    },
  ];

  return (
    <div className="instance-table flex flex-fit">
      <Table
        showSorterTooltip={false}
        rowSelection={{
          type: 'checkbox',
          onSelect: onSelectTableRow,
          onSelectAll: onSelectAllTableRow,
          selectedRowKeys,
          onChange: (newSelectedRowKeys: number[]) =>
            setSelectedRowKeys(newSelectedRowKeys),
        }}
        pagination={false}
        dataSource={data}
        columns={columns}
        loading={loading}
        fillContainer
        scroll={{ y: '100%' }}
        designVersion2
      />
      {showActivateConfirmModal && (
        <ConfirmModal
          show={showActivateConfirmModal}
          setShow={setShowActivateConfirmModal}
          title={t('granulateInsightDashboard.doYouConfirm')}
          subTitle={
            <>
              {t('granulateInsightDashboard.activateConfirm', {
                instanceType: instanceLabel,
              })}
            </>
          }
          loadingYes={loadModel}
          onClickYes={() => activateInstance()}
          onClickNo={() => setShowActivateConfirmModal(false)}
        />
      )}
      {showDeactivateConfirmModal && (
        <ConfirmModal
          show={showDeactivateConfirmModal}
          setShow={setShowDeactivateConfirmModal}
          title={t('granulateInsightDashboard.doYouConfirm')}
          subTitle={
            <>
              {t('granulateInsightDashboard.deActivateConfirm', {
                instanceType: instanceLabel,
              })}
            </>
          }
          loadingYes={loadModel}
          onClickYes={() => deactivateInstance()}
          onClickNo={() => setShowDeactivateConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default InstanceTable;
