import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AccessibleDiv from 'components/AccessibleDiv';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { selectTheme } from 'redux/themeSlice';

import { ControlFilterType } from './types';
import { getVisibleFilters } from './utils';

import './index.scss';

type ControlComponentProps = {
  filters: ControlFilterType[];
  additionalClassName?: string;
};

const ControlComponent = ({
  filters,
  additionalClassName,
}: ControlComponentProps) => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);

  const [toggleClass, setToggleClass] = useState<boolean>(false);

  return (
    <div
      className={`${
        additionalClassName ?? ''
      } controls-container-header flex flex-space-between flex-align-items-start full-width ${
        toggleClass ? 'expanded' : ''
      }`}
      onMouseEnter={() => setToggleClass(true)}
      onMouseLeave={() => setToggleClass(false)}
      data-testid="control-component"
    >
      <div
        className={`flex ${
          toggleClass ? 'flex-column flex-fit ' : ''
        }flex-gap-16`}
      >
        <span className="font-caption">{t('dashboardLabels.controls')}</span>
        {toggleClass ? (
          <div className="flex flex-gap-24">
            {getVisibleFilters(filters).map((filter) => (
              <div
                className={`filter-container ${
                  !filter.width && 'default-width'
                } flex flex-column flex-gap-8`}
                key={filter.title}
                style={{ width: filter.width }}
              >
                <div className="flex flex-space-between">
                  <span className="font-caption-bold">{filter.title}</span>
                  <AccessibleDiv
                    className="clear-button small-grey-button cursor-pointer"
                    onClick={filter.onClear}
                    data-testid={`${filter.title}-clear-cta`}
                  >
                    {t('dashboardLabels.clear')}
                  </AccessibleDiv>
                </div>
                {filter.filter}
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-gap-24">
            {getVisibleFilters(filters).map((filter) => (
              <div
                className="minimized-filter-container flex flex-gap-8"
                key={filter.title}
              >
                <span className="font-caption-bold">{filter.title}</span>
                <span className="font-caption">{filter.minimizedText}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <Icon
        iconName={ICONS.ARROW_DOWN_S_LINE}
        color={theme.primaryColor}
        className={`arrow-icon ${toggleClass ? 'rotated' : ''}`}
      />
    </div>
  );
};

export default ControlComponent;
