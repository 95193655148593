import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import GraphHeader from 'components/GraphHeader';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import LineChart from 'components/LineChart';
import ColumnChart from 'components/ColumnChart';
import { REQUEST_STATUS } from 'constants/requestBody';

import './index.scss';
import { getGranulateLineChartsExcelData } from './utils';

type GranulateUsageMeteringLineChartType = {
  data: any[];
  heading: string;
  filters?: React.ReactNode;
  graphName: string;
  xTitle: string;
  yTitle: string;
  headingComponent?: React.ReactNode;
  groupingField?: string;
  requestStatus: string;
  xField: string;
  yField: string;
  postfixSymbol?: string;
  pdfView: boolean;
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  tableData: any[];
  excelFilters: any[];
};

const GranulateUsageMeteringLineChart = ({
  data,
  heading,
  filters,
  graphName,
  xTitle,
  yTitle,
  headingComponent,
  groupingField,
  requestStatus,
  xField,
  yField,
  postfixSymbol,
  pdfView,
  isTableView,
  setIsTableView,
  tableData,
  excelFilters,
}: GranulateUsageMeteringLineChartType) => {
  const { t } = useTranslation();

  const months = uniqBy(data, 'time');
  const getChart = () => {
    if (months.length > 3) {
      return (
        <LineChart
          data={data}
          xField={xField}
          yField={yField}
          xTitle={xTitle}
          yTitle={yTitle}
          groupingField={groupingField}
          showAllLegend={pdfView}
          disableAnimation={true}
          postfixSymbol={postfixSymbol}
        />
      );
    } else {
      return (
        <ColumnChart
          data={data}
          xField={xField}
          yField={yField}
          xTitle={xTitle}
          yTitle={yTitle}
          isGroup={true}
          isStack={true}
          groupingField={groupingField}
          columnGroupField={groupingField}
          suffixSymbol={postfixSymbol}
        />
      );
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title:
        yTitle === 'Pods'
          ? t('containerInsight.usageMeteringLabels.namespace')
          : t('containerInsight.usageMeteringLabels.category'),
      dataIndex: 'field',
      key: 'field',
      width: 150,
    },
    ...uniqBy(data, 'time').map((item: any) => ({
      title: item.time,
      key: `${item.time}-value`,
      dataIndex: `${item.time}-value`,
      width: 100,
      render: (text: any) => text ?? '-',
    })),
  ];

  const getComponent = () =>
    isTableView ? (
      <Table
        pagination={false}
        loading={requestStatus === REQUEST_STATUS.PROCESSING}
        dataSource={tableData}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer
      />
    ) : (
      getChart()
    );

  return (
    <div
      className="granulate-usage-metering cluster-cost graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={heading}
        headingComponent={headingComponent}
        graphName={graphName}
        filters={filters}
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        isTableViewSwitch={!pdfView}
        isTableView={isTableView}
        excelData={getGranulateLineChartsExcelData(
          data,
          tableData,
          excelFilters,
          heading,
          yTitle
        )}
        setIsTableView={setIsTableView}
        designVersion2
      />

      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default GranulateUsageMeteringLineChart;
