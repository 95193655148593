export const SCHEMA_TYPES = [
  'STRING',
  'BYTES',
  'INTEGER',
  'FLOAT',
  'NUMERIC',
  'BIGNUMERIC',
  'BOOLEAN',
  'TIMESTAMP',
  'DATE',
  'TIME',
  'DATETIME',
  'GEOGRAPHY',
  'RECORD',
];

export const SCHEMA_MODES = ['NULLABLE', 'REQUIRED', 'REPEATED'];
