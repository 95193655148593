import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  selectReport,
  setReportOptions,
  setSelectedReportDimensions,
} from 'redux/reportSlice';

import DimensionListItem from './components/DimensionsListItem';

import './index.scss';

const DimensionList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedReportDimensions, reportOptions } = useSelector(selectReport);

  /**
   * @function handleOnDragEnd
   * @description Updates the selected dimensions list based on the drag and drop
   * @param result - result of the drag and drop
   */
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedDimensions = Array.from(selectedReportDimensions);
    const [reorderedItem] = updatedDimensions.splice(result.source.index, 1);
    updatedDimensions.splice(result.destination.index, 0, reorderedItem);
    dispatch(setSelectedReportDimensions(updatedDimensions));
    dispatch(
      setReportOptions({
        ...reportOptions,
        dimension: updatedDimensions,
      })
    );
  };

  return (
    <div className="report-dimension-list">
      {selectedReportDimensions.length === 0 ? (
        <div className="empty-dimensions-message font-subHeader-small">
          {t('reports.clickToAddDimension')}
        </div>
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="dimensions">
            {(provided) => (
              <ul
                className="draggable-dimension-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selectedReportDimensions.map((eachDimension, index) => (
                  <Draggable
                    key={`${eachDimension.dimension}${eachDimension.dimensionType}${eachDimension.tagDimensionType}`}
                    draggableId={`${eachDimension.dimension}${eachDimension.dimensionType}${eachDimension.tagDimensionType}`}
                    index={index}
                  >
                    {(provided) => (
                      <li ref={provided.innerRef} {...provided.draggableProps}>
                        <DimensionListItem
                          dimension={eachDimension}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default DimensionList;
