import {
  ContainerInsightsIcon,
  AnomalyDetectionIcon,
  ThirdPartyIcon,
  GranulateIcon,
} from 'assets/icons';
import { NavigationMenuDataState } from 'types/navigationMenu';
import UserPage from 'pages/UserPage';
import RolesPage from 'pages/RolesPage';
import TeamsPage from 'pages/TeamsPage';
import GroupsPage from 'pages/GroupsPage';
import ProfilePage from 'pages/ProfilePage';
import OverviewPage from 'pages/OverviewPage';
import DashboardsPage from 'pages/DashboardsPage';
import StaticFilesPage from 'pages/StaticFilesPage';
import TcoDashboardPage from 'pages/TcoDashboardPage';
import TagsAndLabelsPage from 'pages/TagsAndLabelsPage';
import ConnectingCSPPage from 'pages/ConnectingCSPPage';
import CostAllocationPage from 'pages/CostAllocationPage';
import CustomDashboardPage from 'pages/CustomDashboardPage';
import BudgetsAndAlertsPage from 'pages/BudgetsAndAlertsPage';
import TicketIntegrationPage from 'pages/TicketIntegrationPage';
import CostAllocationDashboardPage from 'pages/CostAllocationDashboardPage';
import CostOptimizationInsightsPage from 'pages/CostOptimizationInsightsPage';
import ImportStaticFile from 'pages/StaticFilesPage/components/ImportStaticFile';
import AddOrEditConnection from 'pages/ConnectingCSPPage/components/AddOrEditConnection';
import CostAllocationForm from 'pages/CostAllocationPage/components/CostAllocationForm';
import CreateBudgetAlertConnection from 'pages/BudgetsAndAlertsPage/components/BudgetsAndAlertsForm';
import TcoProjectsPage from 'pages/TcoProjectsPage';
import DataCenterPage from 'pages/DataCenterPage';
import ContainerInsightsPage from 'pages/ContainerInsightsPage';
import RuleEnginePage from 'pages/RuleEnginePage';
import AutoPilotSavings from 'pages/AutoPilotSavings';
import CreateRulesetForm from 'pages/RuleEnginePage/components/CreateRuleset';
import AcknowledgementPage from 'pages/AcknowledgementPage';
import AppearancePage from 'pages/AppearancePage';
import CostMonitorForm from 'pages/CostMonitorPage/components/CostMonitorForm';
import AnomalyDashboardPage from 'pages/AnomalyDashboardPage';
import CostMonitorPage from 'pages/CostMonitorPage';
import ScorecardPage from 'pages/ScorecardPage';
import GProfilerConnectionPage from 'pages/ThirdPartyPage/components/GProfilerConnectionPage';
import PurchasePage from 'pages/PurchasePage';
import PurchaseSavingPlans from 'pages/PurchasePage/components/AwsSavingsPlans/components/PurchaseSavingPlans';
import PurchaseRdsRI from 'pages/PurchasePage/components/ReservedInstances/components/PurchaseRdsRI';
import PurchaseEC2RI from 'pages/PurchasePage/components/ReservedInstances/components/PurchaseEC2RI';
import PurchaseRedshiftRI from 'pages/PurchasePage/components/ReservedInstances/components/PurchaseRedshiftRI';
import PurchaseOpenSearchRI from 'pages/PurchasePage/components/ReservedInstances/components/PurchaseOpenSearchRI';
import PurchaseMemoryDbRI from 'pages/PurchasePage/components/ReservedInstances/components/PurchaseMemoryDbRI';
import PurchaseElastiCacheRI from 'pages/PurchasePage/components/ReservedInstances/components/PurchaseElastiCacheRI';
import GProfilerInsightPage from 'pages/ThirdPartyPage/components/GProfilerInsightPage';
import ReportsPage from 'pages/ReportsPage';
import CreateReportPage from 'pages/CreateReportPage';
import SubscriptionsPage from 'pages/SubscriptionsPage';
import BillingMappingPage from 'pages/BillingMappingPage';
import TagMappingPage from 'pages/TagMappingPage';
import GranulateConnectionPage from 'pages/ThirdPartyPage/Granulate/GranulateConnectionPage';
import GranulateInsightDashboard from 'pages/ThirdPartyPage/Granulate/GranulateInsightPage';
import IntegrationsPage from 'pages/IntegrationsPage';
import ReleaseNotesPage from 'pages/ReleaseNotesPage';
import TagCompliancePage from 'pages/TagCompliancePage';
import AdminControlsPage from 'pages/AdminControlsPage';
import CmdbSetupListPage from 'pages/CmdbSetupListPage';
import CmdbConfigurationPage from 'pages/CmdbConfigurationPage';

import { CONJUNCTIONS } from './requestBody';
import { ICONS } from './icons';

// Side Navigation Menu bar - Data
export enum NAVIGATION_MENU_ID {
  ALL_FEATURES = 'ALL_FEATURES',
  DASHBOARD = 'DASHBOARD',
  SPEND_DIAGNOSTICS = 'SPEND_DIAGNOSTICS',
  DASHBOARDS = 'DASHBOARDS',
  COST_ALLOCATION_DASHBOARD = 'COST_ALLOCATION_DASHBOARD',
  DATA_SOURCES = 'DATA_SOURCES',
  CLOUD_CONNECTIONS = 'CLOUD_CONNECTIONS',
  GROUPS = 'GROUPS',
  STATIC_FILES = 'STATIC_FILES',
  BUDGETS_AND_ALERTS = 'BUDGETS_AND_ALERTS',
  BUDGETS_LIST = 'BUDGETS_LIST',
  COST_OPTIMIZATION = 'COST_OPTIMIZATION',
  TCO_INSIGHTS = 'TCO_INSIGHTS',
  TCO_DASHBOARD = 'TCO_DASHBOARD',
  COMMITMENTS = 'COMMITMENTS',
  CONTAINER_INSIGHTS = 'CONTAINER_INSIGHTS',
  TCO_PROJECT = 'TCO_PROJECT',
  DATA_CENTER = 'DATA_CENTER',
  COST_OPTIMIZATION_INSIGHTS = 'COST_OPTIMIZATION_INSIGHTS',
  RULE_ENGINE = 'RULE_ENGINE',
  AUTOPILOT_SAVINGS_REALISATION = 'AUTOPILOT_SAVINGS_REALISATION',
  COST_ALLOCATION = 'COST_ALLOCATION',
  TAG_MANAGEMENT = 'TAG_MANAGEMENT',
  TAGS_OR_LABELS = 'TAGS_OR_LABELS',
  TAG_COMPLIANCE = 'TAG_COMPLIANCE',
  COST_ALLOCATION_SUB = 'COST_ALLOCATION_SUB',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  USERS = 'USERS',
  ROLES = 'ROLES',
  TEAMS = 'TEAMS',
  SETTINGS = 'SETTINGS',
  PROFILE = 'PROFILE',
  TICKET_INTEGRATION = 'TICKET_INTEGRATION',
  ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
  APPEARANCE = 'APPEARANCE',
  ANOMALY_DETECTION = 'ANOMALY_DETECTION',
  COST_MONITOR = 'COST_MONITOR',
  ANOMALY_DASHBOARD = 'ANOMALY_DASHBOARD',
  SCORECARD_DASHBOARD = 'SCORECARD_DASHBOARD',
  THIRD_PARTY_APPS = 'THIRD_PARTY_APPS',
  GRANULATE_APPS = 'GRANULATE_APPS',
  GRANULATE = 'GRANULATE',
  GPROFILER = 'GPROFILER',
  INSIGHT_DASHBOARD = 'INSIGHT_DASHBOARD',
  GPROFILER_CONNECTIONS = 'GPROFILER_CONNECTIONS',
  GRANULATE_CONNECTIONS = 'GRANULATE_CONNECTIONS',
  GRANULATE_INSIGHT_DASHBOARD = 'GRANULATE_INSIGHT_DASHBOARD',
  REPORT_AND_SUBSCRIPTION = 'REPORT_AND_SUBSCRIPTION',
  REPORTS = 'REPORTS',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  DIMENSIONS = 'DIMENSIONS',
  BILLING_MAPPING = 'BILLING_MAPPING',
  TAG_MAPPING = 'TAG_MAPPING',
  CHAT_BOT = 'CHAT_BOT',
  INTEGRATIONS = 'INTEGRATIONS',
  RELEASE_NOTES = 'RELEASE_NOTES',
  ADMIN_CONTROLS = 'ADMIN_CONTROLS',
  CMDB_SETUP = 'CMDB_SETUP',
}

export enum NAVIGATION_MENU_PATH {
  DEFAULT = '/',
  SPEND_DIAGNOSTICS = '/spend-diagnostics',
  SPEND_DIAGNOSTICS_WITH_ID = '/spend-diagnostics/:urlDashboardId',
  DASHBOARDS = '/dashboards',
  COST_ALLOCATION_DASHBOARD = '/cost-allocation-summary',
  CUSTOM_DASHBOARD = '/custom-dashboard',
  CLOUD_CONNECTIONS = '/cloud-connections',
  CREATE_CONNECTIONS = '/create-connections',
  GROUPS = '/groups',
  STATIC_FILES = '/static-files',
  IMPORT_FILE = '/import-file',
  BUDGETS_AND_ALERTS = '/budgets-and-alerts',
  CREATE_BUDGET = '/create-budget',
  TCO_DASHBOARD = '/tco-dashboard',
  COMMITMENTS = '/commitments',
  PURCHASE_EC2_RI = '/purchase-ec2-ri',
  PURCHASE_RDS_RI = '/purchase-rds-ri',
  PURCHASE_REDSHIFT_RI = '/purchase-redshift-ri',
  PURCHASE_OPEN_SEARCH_RI = '/purchase-open-search-ri',
  PURCHASE_ELASTICACHE_RI = '/purchase-elasticache-ri',
  PURCHASE_MEMORY_DB_RI = '/purchase-memory-db-ri',
  PURCHASE_SAVING_PLANS = '/purchase-saving-plans',
  CONTAINER_INSIGHTS = '/container-insights',
  TCO_PROJECT = '/tco-project',
  DATA_CENTER = '/data-center',
  COST_OPTIMIZATION_INSIGHTS = '/cost-optimization-insights',
  RULE_ENGINE = '/rule-engine',
  AUTOPILOT_SAVINGS_REALISATION = '/autopilot-savings-realisation',
  CREATE_RULESET = '/create-ruleset',
  TAGS_OR_LABELS = '/tags-or-labels',
  TAG_COMPLIANCE = '/tag-compliance',
  COST_ALLOCATION = '/cost-allocation',
  CREATE_COST_ALLOCATION = '/create-cost-allocation',
  USERS = '/users',
  ROLES = '/roles',
  TEAMS = '/teams',
  PROFILE = '/profile',
  TICKET_INTEGRATION = '/ticket-integration',
  NOT_AUTHORIZED = '/not-authorized',
  ERROR = '/error',
  ACKNOWLEDGEMENT = '/acknowledgements',
  APPEARANCE = '/appearance',
  COST_MONITOR = '/cost-monitor',
  CREATE_COST_MONITOR = '/create-cost-monitor',
  ANOMALY_DASHBOARD = '/anomaly-dashboard',
  SCORECARD = '/scorecards',
  THIRD_PARTY_APPS_GPROFILER = '/third-party-apps/gprofiler',
  THIRD_PARTY_CONNECTION_PAGE = '/third-party-apps/gprofiler/connection',
  GRANULATE_CONNECTION_PAGE = '/third-party-apps/granulate/configure',
  GRANULATE_INSIGHT_PAGE = '/third-party-apps/granulate/instances',
  LOGIN = '/login',
  REPORTS = '/reports',
  CREATE_REPORT = '/create-report',
  SUBSCRIPTIONS = '/subscriptions',
  BILLING_MAPPING = '/billing-mapping',
  TAG_MAPPING = '/tag-mapping',
  HEALTH_READINESS = '/health/readiness',
  INTEGRATIONS = '/integrations',
  RELEASE_NOTES = '/release-notes',
  ADMIN_CONTROLS = '/admin-controls',
  CMDB_SETUP = '/cmdb-setup',
  CONFIGURE_CMDB_SETUP = '/configure-cmdb-setup',
}

export const NAVIGATION_MENU_DATA: NavigationMenuDataState[] = [
  {
    id: NAVIGATION_MENU_ID.DASHBOARD,
    title: 'dashboard',
    permissions: ['dashboardList', 'dashboardRead'],
    subSections: [
      {
        id: NAVIGATION_MENU_ID.SPEND_DIAGNOSTICS,
        title: 'spendDiagnostics',
        rootPath: NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS,
        otherPaths: [NAVIGATION_MENU_PATH.DEFAULT],
        paramPaths: [NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS_WITH_ID],
        permissions: ['dashboardRead'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.DASHBOARDS,
        title: 'dashboards',
        rootPath: NAVIGATION_MENU_PATH.DASHBOARDS,
        otherPaths: [NAVIGATION_MENU_PATH.CUSTOM_DASHBOARD],
        permissions: ['dashboardList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.SCORECARD_DASHBOARD,
        title: 'scorecards',
        rootPath: NAVIGATION_MENU_PATH.SCORECARD,
        permissions: ['dashboardRead'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
    ],
    subMenu: [
      {
        id: NAVIGATION_MENU_ID.REPORT_AND_SUBSCRIPTION,
        title: 'reportAndSubscriptions',
        permissions: ['dashboardRead'],
        subSections: [
          {
            id: NAVIGATION_MENU_ID.REPORTS,
            title: 'reports',
            rootPath: NAVIGATION_MENU_PATH.REPORTS,
            otherPaths: [NAVIGATION_MENU_PATH.CREATE_REPORT],
            permissions: ['dashboardRead'],
            permissionConjunction: CONJUNCTIONS.OR,
          },
          {
            id: NAVIGATION_MENU_ID.SUBSCRIPTIONS,
            title: 'subscriptions',
            rootPath: NAVIGATION_MENU_PATH.SUBSCRIPTIONS,
            permissions: ['dashboardRead'],
            permissionConjunction: CONJUNCTIONS.OR,
          },
        ],
      },
      {
        id: NAVIGATION_MENU_ID.DIMENSIONS,
        title: 'dimension',
        permissions: ['dimensions'],
        subSections: [
          {
            id: NAVIGATION_MENU_ID.TAG_MAPPING,
            title: 'tagMapping',
            rootPath: NAVIGATION_MENU_PATH.TAG_MAPPING,
            permissions: ['dimensions'],
            permissionConjunction: CONJUNCTIONS.OR,
          },
          {
            id: NAVIGATION_MENU_ID.BILLING_MAPPING,
            title: 'billingMapping',
            rootPath: NAVIGATION_MENU_PATH.BILLING_MAPPING,
            permissions: ['dimensions'],
            permissionConjunction: CONJUNCTIONS.OR,
          },
        ],
      },
    ],
    iconName: ICONS.HOME_LINE,
    subSectionsFirst: true,
  },
  {
    id: NAVIGATION_MENU_ID.DATA_SOURCES,
    title: 'dataSources',
    permissions: ['cloudConnectorList'],
    subSections: [
      {
        id: NAVIGATION_MENU_ID.CLOUD_CONNECTIONS,
        title: 'cloudConnections',
        rootPath: NAVIGATION_MENU_PATH.CLOUD_CONNECTIONS,
        otherPaths: [NAVIGATION_MENU_PATH.CREATE_CONNECTIONS],
        permissions: ['cloudConnectorList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.GROUPS,
        title: 'groups',
        rootPath: NAVIGATION_MENU_PATH.GROUPS,
        permissions: ['cloudConnectorList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.STATIC_FILES,
        title: 'importStaticFile',
        rootPath: NAVIGATION_MENU_PATH.STATIC_FILES,
        otherPaths: [NAVIGATION_MENU_PATH.IMPORT_FILE],
        permissions: ['cloudConnectorList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.INTEGRATIONS,
        title: 'integrations',
        rootPath: NAVIGATION_MENU_PATH.INTEGRATIONS,
        permissions: ['cloudConnectorList', 'integrations'],
        permissionConjunction: CONJUNCTIONS.AND,
      },
      {
        id: NAVIGATION_MENU_ID.CMDB_SETUP,
        title: 'cmdbSetup',
        rootPath: NAVIGATION_MENU_PATH.CMDB_SETUP,
        otherPaths: [NAVIGATION_MENU_PATH.CONFIGURE_CMDB_SETUP],
        permissions: ['cloudConnectorList', 'userManagementWrite'],
        permissionConjunction: CONJUNCTIONS.AND,
      },
    ],
    iconName: ICONS.CLOUD_LINE,
  },
  {
    id: NAVIGATION_MENU_ID.BUDGETS_AND_ALERTS,
    title: 'budgetsAndAlerts',
    subSections: [
      {
        id: NAVIGATION_MENU_ID.BUDGETS_LIST,
        title: 'budgetsList',
        rootPath: NAVIGATION_MENU_PATH.BUDGETS_AND_ALERTS,
        otherPaths: [NAVIGATION_MENU_PATH.CREATE_BUDGET],
        permissions: ['budgetAlertsList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
    ],
    iconName: ICONS.ALARM_WARNING_LINE,
    permissions: ['budgetAlertsList'],
  },
  {
    id: NAVIGATION_MENU_ID.ANOMALY_DETECTION,
    title: 'anomalyDetection',
    permissions: ['costControlList', 'costControlRead', 'costControlWrite'],
    subSections: [
      {
        id: NAVIGATION_MENU_ID.ANOMALY_DASHBOARD,
        title: 'anomalyDashboard',
        rootPath: NAVIGATION_MENU_PATH.ANOMALY_DASHBOARD,
        permissions: ['costControlRead', 'costControlList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.COST_MONITOR,
        title: 'setupMonitorOrAlert',
        rootPath: NAVIGATION_MENU_PATH.COST_MONITOR,
        otherPaths: [NAVIGATION_MENU_PATH.CREATE_COST_MONITOR],
        permissions: ['costControlList', 'costControlWrite'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
    ],
    icon: AnomalyDetectionIcon,
  },
  {
    id: NAVIGATION_MENU_ID.COST_OPTIMIZATION,
    title: 'costOptimization',
    permissions: ['costControlList', 'costControlRead', 'costControlWrite'],
    subSections: [
      {
        id: NAVIGATION_MENU_ID.COST_OPTIMIZATION_INSIGHTS,
        title: 'costOptimizationInsightsPage',
        rootPath: NAVIGATION_MENU_PATH.COST_OPTIMIZATION_INSIGHTS,
        permissions: ['costControlRead', 'costControlList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.RULE_ENGINE,
        title: 'ruleEngine',
        rootPath: NAVIGATION_MENU_PATH.RULE_ENGINE,
        otherPaths: [NAVIGATION_MENU_PATH.CREATE_RULESET],
        permissions: ['costControlList', 'costControlWrite'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.AUTOPILOT_SAVINGS_REALISATION,
        title: 'autoPilotSavingsRealisation',
        rootPath: NAVIGATION_MENU_PATH.AUTOPILOT_SAVINGS_REALISATION,
        permissions: ['costControlRead'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.COMMITMENTS,
        title: 'commitments',
        rootPath: NAVIGATION_MENU_PATH.COMMITMENTS,
        otherPaths: [
          NAVIGATION_MENU_PATH.PURCHASE_EC2_RI,
          NAVIGATION_MENU_PATH.PURCHASE_RDS_RI,
          NAVIGATION_MENU_PATH.PURCHASE_REDSHIFT_RI,
          NAVIGATION_MENU_PATH.PURCHASE_ELASTICACHE_RI,
          NAVIGATION_MENU_PATH.PURCHASE_MEMORY_DB_RI,
          NAVIGATION_MENU_PATH.PURCHASE_SAVING_PLANS,
          NAVIGATION_MENU_PATH.PURCHASE_OPEN_SEARCH_RI,
        ],
        permissions: ['costControlRead', 'costControlWrite'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
    ],
    iconName: ICONS.DONUT_CHART_FILL,
  },
  {
    id: NAVIGATION_MENU_ID.CONTAINER_INSIGHTS,
    title: 'containerInsights',
    rootPath: NAVIGATION_MENU_PATH.CONTAINER_INSIGHTS,
    permissions: ['costControlRead'],
    subSections: [
      {
        id: NAVIGATION_MENU_ID.CONTAINER_INSIGHTS,
        title: 'containerInsights',
        rootPath: NAVIGATION_MENU_PATH.CONTAINER_INSIGHTS,
        permissions: ['costControlRead'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
    ],
    icon: ContainerInsightsIcon,
  },
  {
    id: NAVIGATION_MENU_ID.TCO_INSIGHTS,
    title: 'tcoInsights',
    permissions: ['costControlRead', 'costControlWrite', 'costControlList'],
    subSections: [
      {
        id: NAVIGATION_MENU_ID.TCO_DASHBOARD,
        title: 'tcoDashboard',
        rootPath: NAVIGATION_MENU_PATH.TCO_DASHBOARD,
        permissions: ['costControlRead'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.TCO_PROJECT,
        title: 'tcoProject',
        rootPath: NAVIGATION_MENU_PATH.TCO_PROJECT,
        permissions: ['costControlList', 'costControlWrite'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.DATA_CENTER,
        title: 'dataCenter',
        rootPath: NAVIGATION_MENU_PATH.DATA_CENTER,
        permissions: ['costControlList', 'costControlWrite'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
    ],
    iconName: ICONS.LINE_CHART_LINE,
  },
  {
    id: NAVIGATION_MENU_ID.COST_ALLOCATION,
    title: 'costAllocation',
    permissions: ['costAllocationList', 'costAllocationWrite'],
    subSections: [
      {
        id: NAVIGATION_MENU_ID.COST_ALLOCATION_SUB,
        title: 'costAllocation',
        rootPath: NAVIGATION_MENU_PATH.COST_ALLOCATION,
        otherPaths: [NAVIGATION_MENU_PATH.CREATE_COST_ALLOCATION],
        permissions: ['costAllocationList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.COST_ALLOCATION_DASHBOARD,
        title: 'summary',
        rootPath: NAVIGATION_MENU_PATH.COST_ALLOCATION_DASHBOARD,
        permissions: ['dashboardRead', 'costAllocationList'],
        permissionConjunction: CONJUNCTIONS.AND,
      },
    ],
    subMenu: [
      {
        id: NAVIGATION_MENU_ID.TAG_MANAGEMENT,
        title: 'tagManagement',
        permissions: ['costAllocationList', 'costAllocationWrite'],
        subSections: [
          {
            id: NAVIGATION_MENU_ID.TAGS_OR_LABELS,
            title: 'tagsOrLables',
            rootPath: NAVIGATION_MENU_PATH.TAGS_OR_LABELS,
            permissions: ['costAllocationWrite'],
            permissionConjunction: CONJUNCTIONS.OR,
          },
          {
            id: NAVIGATION_MENU_ID.TAG_COMPLIANCE,
            title: 'tagCompliance',
            rootPath: NAVIGATION_MENU_PATH.TAG_COMPLIANCE,
            permissions: ['costAllocationList'],
            permissionConjunction: CONJUNCTIONS.OR,
          },
        ],
      },
    ],
    iconName: ICONS.FILE_TEXT_LINE,
  },
  {
    id: NAVIGATION_MENU_ID.THIRD_PARTY_APPS,
    title: 'thirdPartyApps',
    permissions: [
      'thirdPartyAppsList',
      'thirdPartyAppsRead',
      'thirdPartyAppsWrite',
      'thirdPartyAppsModify',
    ],
    subMenu: [
      {
        id: NAVIGATION_MENU_ID.GRANULATE_APPS,
        title: 'granulateApps',
        permissions: [
          'thirdPartyAppsList',
          'thirdPartyAppsRead',
          'thirdPartyAppsWrite',
          'thirdPartyAppsModify',
        ],
        icon: GranulateIcon,
        subSections: [],
        subMenu: [
          {
            id: NAVIGATION_MENU_ID.GRANULATE,
            title: 'granulate',
            permissions: [
              'thirdPartyAppsList',
              'thirdPartyAppsRead',
              'thirdPartyAppsWrite',
              'thirdPartyAppsModify',
              'granulate',
            ],
            subSections: [
              {
                id: NAVIGATION_MENU_ID.GRANULATE_INSIGHT_DASHBOARD,
                title: 'instances',
                rootPath: NAVIGATION_MENU_PATH.GRANULATE_INSIGHT_PAGE,
                permissions: [
                  'thirdPartyAppsRead',
                  'thirdPartyAppsWrite',
                  'thirdPartyAppsModify',
                  'granulate',
                ],
                permissionConjunction: CONJUNCTIONS.AND,
              },
              {
                id: NAVIGATION_MENU_ID.GRANULATE_CONNECTIONS,
                title: 'configure',
                rootPath: NAVIGATION_MENU_PATH.GRANULATE_CONNECTION_PAGE,
                permissions: [
                  'thirdPartyAppsList',
                  'thirdPartyAppsRead',
                  'thirdPartyAppsWrite',
                  'thirdPartyAppsModify',
                  'granulate',
                ],
                permissionConjunction: CONJUNCTIONS.AND,
              },
            ],
          },
          {
            id: NAVIGATION_MENU_ID.GPROFILER,
            title: 'gprofiler',
            permissions: [
              'thirdPartyAppsList',
              'thirdPartyAppsRead',
              'thirdPartyAppsWrite',
              'thirdPartyAppsModify',
            ],
            subSections: [
              {
                id: NAVIGATION_MENU_ID.INSIGHT_DASHBOARD,
                title: 'insightDashboard',
                rootPath: NAVIGATION_MENU_PATH.THIRD_PARTY_APPS_GPROFILER,
                permissions: [
                  'thirdPartyAppsRead',
                  'thirdPartyAppsWrite',
                  'thirdPartyAppsModify',
                ],
                permissionConjunction: CONJUNCTIONS.OR,
              },
              {
                id: NAVIGATION_MENU_ID.GPROFILER_CONNECTIONS,
                title: 'connections',
                rootPath: NAVIGATION_MENU_PATH.THIRD_PARTY_CONNECTION_PAGE,
                permissions: [
                  'thirdPartyAppsList',
                  'thirdPartyAppsRead',
                  'thirdPartyAppsWrite',
                  'thirdPartyAppsModify',
                ],
                permissionConjunction: CONJUNCTIONS.OR,
              },
            ],
          },
        ],
      },
    ],
    subSections: [],
    icon: ThirdPartyIcon,
  },
  {
    id: NAVIGATION_MENU_ID.USER_MANAGEMENT,
    title: 'userManagement',
    permissions: ['userManagementList'],
    subSections: [
      {
        id: NAVIGATION_MENU_ID.USERS,
        title: 'users',
        rootPath: NAVIGATION_MENU_PATH.USERS,
        permissions: ['userManagementList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.ROLES,
        title: 'roles',
        rootPath: NAVIGATION_MENU_PATH.ROLES,
        permissions: ['userManagementList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.TEAMS,
        title: 'teams',
        rootPath: NAVIGATION_MENU_PATH.TEAMS,
        permissions: ['userManagementList'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
    ],
    iconName: ICONS.USER_LINE,
  },
  {
    id: NAVIGATION_MENU_ID.SETTINGS,
    title: 'settings',
    subSections: [
      {
        id: NAVIGATION_MENU_ID.PROFILE,
        title: 'profile',
        rootPath: NAVIGATION_MENU_PATH.PROFILE,
        permissions: ['profile'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.APPEARANCE,
        title: 'appearance',
        rootPath: NAVIGATION_MENU_PATH.APPEARANCE,
        permissions: ['profile'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.TICKET_INTEGRATION,
        title: 'integrations',
        rootPath: NAVIGATION_MENU_PATH.TICKET_INTEGRATION,
        permissions: ['ticketIntegration'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.ACKNOWLEDGEMENT,
        title: 'acknowledgements',
        rootPath: NAVIGATION_MENU_PATH.ACKNOWLEDGEMENT,
        permissions: ['profile'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.RELEASE_NOTES,
        title: 'releaseNotes',
        rootPath: NAVIGATION_MENU_PATH.RELEASE_NOTES,
        permissions: ['profile'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
      {
        id: NAVIGATION_MENU_ID.ADMIN_CONTROLS,
        title: 'adminControls',
        rootPath: NAVIGATION_MENU_PATH.ADMIN_CONTROLS,
        permissions: ['adminControls'],
        permissionConjunction: CONJUNCTIONS.OR,
      },
    ],
    iconName: ICONS.SETTINGS_LINE,
    permissions: ['profile', 'ticketIntegration', 'adminControls'],
  },
];

export const ROUTES_DATA = [
  {
    path: NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS,
    permissions: ['dashboardRead'],
    element: OverviewPage,
  },
  {
    path: NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS_WITH_ID,
    permissions: ['dashboardRead'],
    element: OverviewPage,
  },
  {
    path: NAVIGATION_MENU_PATH.DASHBOARDS,
    permissions: ['dashboardList'],
    element: DashboardsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.CUSTOM_DASHBOARD,
    permissions: ['dashboardWrite'],
    element: CustomDashboardPage,
  },
  {
    path: NAVIGATION_MENU_PATH.COST_ALLOCATION_DASHBOARD,
    permissions: ['dashboardRead'],
    element: CostAllocationDashboardPage,
  },
  {
    path: NAVIGATION_MENU_PATH.CLOUD_CONNECTIONS,
    permissions: ['cloudConnectorList'],
    element: ConnectingCSPPage,
  },
  {
    path: NAVIGATION_MENU_PATH.CREATE_CONNECTIONS,
    permissions: ['cloudConnectorWrite'],
    element: AddOrEditConnection,
  },
  {
    path: NAVIGATION_MENU_PATH.GROUPS,
    permissions: ['cloudConnectorList'],
    element: GroupsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.STATIC_FILES,
    permissions: ['cloudConnectorList'],
    element: StaticFilesPage,
  },
  {
    path: NAVIGATION_MENU_PATH.IMPORT_FILE,
    permissions: ['cloudConnectorWrite'],
    element: ImportStaticFile,
  },
  {
    path: NAVIGATION_MENU_PATH.BUDGETS_AND_ALERTS,
    permissions: ['budgetAlertsList'],
    element: BudgetsAndAlertsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.CREATE_BUDGET,
    permissions: ['budgetAlertsWrite'],
    element: CreateBudgetAlertConnection,
  },
  {
    path: NAVIGATION_MENU_PATH.TCO_DASHBOARD,
    permissions: ['costControlRead'],
    element: TcoDashboardPage,
  },
  {
    path: NAVIGATION_MENU_PATH.COMMITMENTS,
    permissions: ['costControlRead'],
    element: PurchasePage,
  },
  {
    path: NAVIGATION_MENU_PATH.PURCHASE_EC2_RI,
    permissions: ['costControlWrite'],
    element: PurchaseEC2RI,
  },
  {
    path: NAVIGATION_MENU_PATH.PURCHASE_RDS_RI,
    permissions: ['costControlWrite'],
    element: PurchaseRdsRI,
  },
  {
    path: NAVIGATION_MENU_PATH.PURCHASE_REDSHIFT_RI,
    permissions: ['costControlWrite'],
    element: PurchaseRedshiftRI,
  },
  {
    path: NAVIGATION_MENU_PATH.PURCHASE_OPEN_SEARCH_RI,
    permissions: ['costControlWrite'],
    element: PurchaseOpenSearchRI,
  },
  {
    path: NAVIGATION_MENU_PATH.PURCHASE_ELASTICACHE_RI,
    permissions: ['costControlWrite'],
    element: PurchaseElastiCacheRI,
  },
  {
    path: NAVIGATION_MENU_PATH.PURCHASE_MEMORY_DB_RI,
    permissions: ['costControlWrite'],
    element: PurchaseMemoryDbRI,
  },
  {
    path: NAVIGATION_MENU_PATH.PURCHASE_SAVING_PLANS,
    permissions: ['costControlWrite'],
    element: PurchaseSavingPlans,
  },
  {
    path: NAVIGATION_MENU_PATH.CONTAINER_INSIGHTS,
    permissions: ['costControlRead'],
    element: ContainerInsightsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.TCO_PROJECT,
    permissions: ['costControlList', 'costControlWrite'],
    element: TcoProjectsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.DATA_CENTER,
    permissions: ['costControlList', 'costControlWrite'],
    element: DataCenterPage,
  },
  {
    path: NAVIGATION_MENU_PATH.COST_OPTIMIZATION_INSIGHTS,
    permissions: ['costControlRead', 'costControlList'],
    element: CostOptimizationInsightsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.RULE_ENGINE,
    permissions: ['costControlList', 'costControlWrite'],
    element: RuleEnginePage,
  },
  {
    path: NAVIGATION_MENU_PATH.AUTOPILOT_SAVINGS_REALISATION,
    permissions: ['costControlRead'],
    element: AutoPilotSavings,
  },
  {
    path: NAVIGATION_MENU_PATH.CREATE_RULESET,
    permissions: ['costControlWrite', 'costControlModify'],
    element: CreateRulesetForm,
  },
  {
    path: NAVIGATION_MENU_PATH.TAGS_OR_LABELS,
    permissions: ['costAllocationWrite'],
    element: TagsAndLabelsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.TAG_COMPLIANCE,
    permissions: ['costAllocationList'],
    element: TagCompliancePage,
  },
  {
    path: NAVIGATION_MENU_PATH.COST_ALLOCATION,
    permissions: ['costAllocationList'],
    element: CostAllocationPage,
  },
  {
    path: NAVIGATION_MENU_PATH.CREATE_COST_ALLOCATION,
    permissions: ['costAllocationWrite'],
    element: CostAllocationForm,
  },
  {
    path: NAVIGATION_MENU_PATH.USERS,
    permissions: ['userManagementList'],
    element: UserPage,
  },
  {
    path: NAVIGATION_MENU_PATH.ROLES,
    permissions: ['userManagementList'],
    element: RolesPage,
  },
  {
    path: NAVIGATION_MENU_PATH.TEAMS,
    permissions: ['userManagementList'],
    element: TeamsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.PROFILE,
    permissions: ['profile'],
    element: ProfilePage,
  },
  {
    path: NAVIGATION_MENU_PATH.TICKET_INTEGRATION,
    permissions: ['ticketIntegration'],
    element: TicketIntegrationPage,
  },
  {
    path: NAVIGATION_MENU_PATH.ACKNOWLEDGEMENT,
    permissions: ['profile'],
    element: AcknowledgementPage,
  },
  {
    path: NAVIGATION_MENU_PATH.APPEARANCE,
    permissions: ['profile'],
    element: AppearancePage,
  },
  {
    path: NAVIGATION_MENU_PATH.COST_MONITOR,
    permissions: ['costControlList', 'costControlRead', 'costControlWrite'],
    element: CostMonitorPage,
  },
  {
    path: NAVIGATION_MENU_PATH.CREATE_COST_MONITOR,
    permissions: ['costControlWrite'],
    element: CostMonitorForm,
  },
  {
    path: NAVIGATION_MENU_PATH.ANOMALY_DASHBOARD,
    permissions: ['costControlRead'],
    element: AnomalyDashboardPage,
  },
  {
    path: NAVIGATION_MENU_PATH.SCORECARD,
    permissions: ['dashboardRead'],
    element: ScorecardPage,
  },
  {
    path: NAVIGATION_MENU_PATH.THIRD_PARTY_APPS_GPROFILER,
    permissions: [
      'thirdPartyAppsRead',
      'thirdPartyAppsWrite',
      'thirdPartyAppsModify',
    ],
    element: GProfilerInsightPage,
  },
  {
    path: NAVIGATION_MENU_PATH.THIRD_PARTY_CONNECTION_PAGE,
    permissions: [
      'thirdPartyAppsList',
      'thirdPartyAppsRead',
      'thirdPartyAppsWrite',
      'thirdPartyAppsModify',
    ],
    element: GProfilerConnectionPage,
  },
  {
    path: NAVIGATION_MENU_PATH.GRANULATE_CONNECTION_PAGE,
    permissions: [
      'thirdPartyAppsList',
      'thirdPartyAppsRead',
      'thirdPartyAppsWrite',
      'thirdPartyAppsModify',
    ],
    element: GranulateConnectionPage,
  },
  {
    path: NAVIGATION_MENU_PATH.GRANULATE_INSIGHT_PAGE,
    permissions: [
      'thirdPartyAppsList',
      'thirdPartyAppsRead',
      'thirdPartyAppsWrite',
      'thirdPartyAppsModify',
      'granulate',
    ],
    permissionConjunction: CONJUNCTIONS.AND,
    element: GranulateInsightDashboard,
  },
  {
    path: NAVIGATION_MENU_PATH.REPORTS,
    permissions: ['dashboardRead'],
    element: ReportsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.CREATE_REPORT,
    permissions: ['dashboardRead'],
    element: CreateReportPage,
  },
  {
    path: NAVIGATION_MENU_PATH.SUBSCRIPTIONS,
    permissions: ['dashboardRead'],
    element: SubscriptionsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.BILLING_MAPPING,
    permissions: ['dimensions'],
    element: BillingMappingPage,
  },
  {
    path: NAVIGATION_MENU_PATH.TAG_MAPPING,
    permissions: ['dimensions'],
    element: TagMappingPage,
  },
  {
    path: NAVIGATION_MENU_PATH.INTEGRATIONS,
    permissions: ['cloudConnectorList', 'integrations'],
    permissionConjunction: CONJUNCTIONS.AND,
    element: IntegrationsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.RELEASE_NOTES,
    permissions: ['profile'],
    permissionConjunction: CONJUNCTIONS.AND,
    element: ReleaseNotesPage,
  },
  {
    path: NAVIGATION_MENU_PATH.ADMIN_CONTROLS,
    permissions: ['adminControls'],
    permissionConjunction: CONJUNCTIONS.AND,
    element: AdminControlsPage,
  },
  {
    path: NAVIGATION_MENU_PATH.CMDB_SETUP,
    permissions: ['cloudConnectorList', 'userManagementWrite'],
    permissionConjunction: CONJUNCTIONS.AND,
    element: CmdbSetupListPage,
  },
  {
    path: NAVIGATION_MENU_PATH.CONFIGURE_CMDB_SETUP,
    permissions: ['cloudConnectorList', 'userManagementWrite'],
    permissionConjunction: CONJUNCTIONS.AND,
    element: CmdbConfigurationPage,
  },
];
