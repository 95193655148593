import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import { selectTheme } from 'redux/themeSlice';
import Button from 'components/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';

import './index.scss';

type TableFilterDropdownProps = {
  allKeys: any[];
  selectedKeys: any[];
  setSelectedKeys: (keys: any[]) => void;
  confirm: () => void;
  clearFilters?: () => void;
  designVersion2?: boolean;
};

const TableFilterDropdown = ({
  allKeys,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
  designVersion2 = false,
}: TableFilterDropdownProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tableFilterDropdown',
  });
  const { theme } = useSelector(selectTheme);

  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredKeys, setFilteredKeys] = useState<any[]>(allKeys);

  useEffect(() => {
    if (searchValue) {
      setFilteredKeys(
        allKeys.filter((provider: any) =>
          provider.text.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
      return;
    }
    setFilteredKeys(allKeys);
  }, [allKeys, searchValue]);

  return (
    <div
      className={`table-filter-dropdown flex flex-column ${
        designVersion2 ? 'design-version-2' : 'flex-gap-8'
      }`}
      style={
        {
          '--button-icon-color': theme.buttonIconColor,
          '--text-color': theme.textColor,
        } as React.CSSProperties
      }
    >
      <section className="filters flex flex-column flex-gap-8">
        {allKeys.length > 5 && (
          <Input
            placeholder={t('searchFilter')}
            value={searchValue}
            onChange={(e: any) => {
              setSearchValue(e.target.value);
            }}
            onPressEnter={() => confirm()}
          />
        )}
        {!searchValue && (
          <Checkbox
            checked={allKeys.every((provider: any) =>
              selectedKeys?.includes(provider.value)
            )}
            onChange={(e: any) => {
              if (e.target.checked) {
                setSelectedKeys([...allKeys.map((p: any) => p.value)]);
              } else {
                setSelectedKeys([]);
              }
            }}
          >
            {t('selectAll')}
          </Checkbox>
        )}
        {filteredKeys.map((provider: any) => (
          <div
            key={provider.value}
            className="filter-key flex flex-row justify-between align-center"
          >
            <Checkbox
              checked={selectedKeys?.includes(provider.value)}
              onChange={(e: any) => {
                if (e.target.checked) {
                  setSelectedKeys([...selectedKeys, provider.value]);
                } else {
                  setSelectedKeys(
                    selectedKeys.filter((p: any) => p !== provider.value)
                  );
                }
              }}
            >
              {provider.text}
            </Checkbox>
          </div>
        ))}
      </section>
      <div className="footer-buttons flex flex-gap-4 flex-align-items-center flex-end">
        <Button
          type={BUTTON_TYPE.LINK}
          title={t('clear')}
          onClick={() => {
            clearFilters?.();
            setSearchValue('');
          }}
        />
        <Button
          type={BUTTON_TYPE.PRIMARY}
          size={BUTTON_SIZE.SMALL}
          title={t('apply')}
          onClick={() => confirm()}
        />
      </div>
    </div>
  );
};

export default TableFilterDropdown;
