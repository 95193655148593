import { Dropdown, MenuProps } from 'antd';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { CustomDashboardActions } from 'constants/quickAction';

import './index.scss';

type QuickActionMenuProps = {
  setCurrentConnectionData?: () => void;
  quickActions: any[];
  quickActionHandler: (val: any) => void;
  disabled?: boolean;
};
const QuickActionMenu = ({
  setCurrentConnectionData,
  quickActions,
  quickActionHandler,
  disabled,
}: QuickActionMenuProps) => {
  const { theme } = useSelector(selectTheme);

  const items: MenuProps['items'] = quickActions.map((action) => ({
    key: action.id,
    label: action.title,
    danger:
      action.id === CustomDashboardActions.DELETE ||
      action.id === CustomDashboardActions.DEACTIVATE ||
      action.id === CustomDashboardActions.UNINSTALL,
  }));

  return (
    <>
      {!disabled ? (
        <Dropdown
          menu={{ items, onClick: ({ key }) => quickActionHandler(key) }}
          trigger={['click']}
          placement="bottom"
          className="quickAction-dropdown"
          overlayClassName="quickAction-overlay"
        >
          <Icon
            className="flex flex-column"
            iconName={ICONS.MORE_FILL}
            onClick={(e) => {
              e.preventDefault();
              setCurrentConnectionData?.();
            }}
            color={theme.buttonIconColor}
            dataTestId="action-cta"
          />
        </Dropdown>
      ) : (
        <Icon
          className="flex flex-column quick-action-disabled-icon"
          iconName={ICONS.MORE_FILL}
          dataTestId="action-cta-disabled"
        />
      )}
    </>
  );
};

export default QuickActionMenu;
