import { CancelToken } from 'axios';
import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function deleteConnectionData
 * @description Function to delete connections data.
 * @param connectionId connector ID of the connection to be deleted.
 * @return axios response from POST request.
 */
export const deleteConnectionData = async (connectionId: string) => {
  return ServiceCalls.delete(APIS.DELETE_CONENCTION + connectionId);
};

/**
 * @function checkConnectorNameAlreadyUsed
 * @description Function to check connector name is already used
 * @param params include string key parameter for checking connector name already exists
 * @return axios response from GET request
 */
export const checkConnectorNameAlreadyUsed = async (params: any) => {
  return ServiceCalls.get(APIS.IS_CONNECTOR_NAME_EXISTS, null, params);
};

/**
 * @function getOciGetDataSchemaTables
 * @description Function to get data schema Tables using oci credentials
 * @param body The body which contains the zip file id, dataSource type, database, oci credentials
 * @return axios response from POST request
 */
export const getOciGetDataSchemaTables = async (
  body: any,
  cancelToken: CancelToken
) => {
  return ServiceCalls.post(
    APIS.GET_OCI_DATASCHEMATABLES,
    body,
    null,
    null,
    cancelToken
  );
};

/**
 * @function getOciRegions
 * @description Function to get the regions for OCI budget connection
 * @return axios response from GET request
 */
export const getOciRegions = async () => {
  return ServiceCalls.get(APIS.GET_OCI_REGIONS);
};

/**
 * @function getGcpProjectDatasets
 * @description Function to get the projects and datasets for GCP connection
 * @return axios response from GET request
 */
export const getGcpProjectDatasets = async (params: any) => {
  return ServiceCalls.get(APIS.GET_GCP_PROJECTS_DATASETS, null, params);
};

/**
 * @function getAccessDetails
 * @description Function to get the access details for a connection
 * @return axios response from POST request
 */
export const getAccessDetails = async (body: any, params: any) => {
  return ServiceCalls.post(APIS.GET_ACCESS_DETAILS, body, null, params);
};
