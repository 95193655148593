import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  ruleEngine,
  setDeletedRuleIds,
  setRuleEngineFormData,
} from 'redux/ruleEngineSlice';
import { RulesetTemplateType, RulesType } from 'pages/RuleEnginePage/types';
import {
  DEFAULT_RULE,
  DEFAULT_STATEMENT,
} from 'pages/RuleEnginePage/constants';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Button from 'components/Button';
import { ICONS } from 'constants/icons';
import { DeleteIconButton } from 'assets/icons';
import { RecommendationCategoriesLabels } from 'constants/recommendations';
import { BUTTON_SIZE } from 'constants/appearance';
import { toTitleCase } from 'utils/dataFormatterUtils';

import {
  getDefaultRule,
  getDefaultStatement,
  isRecommendationCategoryDisabled,
  isRecommendationStatementDisabled,
} from '../../utils';
import StatementComponent from '../StatementComponent';
import RulesLimitModal from '../RulesLimitModal';

type RuleCardProps = {
  rule: RulesType;
  ruleIndex: number;
  rulesetTemplateList: RulesetTemplateType[];
  createButtonClick: boolean;
};

const RuleCard = ({
  rule,
  ruleIndex,
  rulesetTemplateList,
  createButtonClick,
}: RuleCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ruleEngineFormData, deletedRuleIds } = useSelector(ruleEngine);

  const [showStatementsLimitModal, setShowStatementsLimitModal] =
    useState(false);

  /**
   * @function addNewStatement
   * @description Function to add a new statement in a rule
   */
  const addNewStatement = () => {
    const rules: RulesType[] = [...ruleEngineFormData.rules];
    let statements = rules.at(ruleIndex)?.statementValuesList;

    // Show Statements limit popup if 5 statements are added for a rule
    if (statements?.length === 5) {
      setShowStatementsLimitModal(true);
      return;
    }

    const defaultStatement =
      getDefaultStatement(
        rulesetTemplateList,
        ruleEngineFormData,
        rule.recommendationType,
        rule.recommendationStatementType
      ) ?? DEFAULT_STATEMENT;

    //Add the first statement for a rule
    if (!statements || statements.length === 0) {
      statements = [defaultStatement];
    } else {
      // Append a new statement to the existing rule
      statements = [...statements, defaultStatement];
    }

    // Update rules with updated statements
    rules.splice(ruleIndex, 1, {
      ...(rules.at(ruleIndex) ?? DEFAULT_RULE),
      statementValuesList: statements,
    });

    dispatch(setRuleEngineFormData({ ...ruleEngineFormData, rules: rules }));
  };

  /**
   * @function deleteRule
   * @description Function to delete a rule
   */
  const deleteRule = () => {
    //Add ruleId to the deleted rule ids list if it's an existing rule
    if (rule.rulesId) {
      dispatch(setDeletedRuleIds([...deletedRuleIds, rule.rulesId]));
    }

    let rules: RulesType[] = [...ruleEngineFormData.rules];
    rules.splice(ruleIndex, 1);

    if (rules.length === 0) {
      rules = [
        getDefaultRule(rulesetTemplateList, ruleEngineFormData) ?? DEFAULT_RULE,
      ];
    }

    dispatch(setRuleEngineFormData({ ...ruleEngineFormData, rules: rules }));
  };

  /**
   * @function onChangeRecommendationCategory
   * @description Callback function for onchange event of recommendation category dropdown
   * @param value recommendation category selected
   */
  const onChangeRecommendationCategory = (value: string) => {
    const rules: RulesType[] = [...ruleEngineFormData.rules];
    rules.splice(ruleIndex, 1, {
      ...(rules.at(ruleIndex) ?? DEFAULT_RULE),
      recommendationType: value,
      recommendationStatementType: '',
      checkId: undefined,
      statementValuesList: [],
    });

    dispatch(setRuleEngineFormData({ ...ruleEngineFormData, rules: rules }));
  };

  /**
   * @function onChangeRecommendationStatementType
   * @description Callback function for onchange event of recommendation statement type dropdown
   * @param value recommendation statement type selected
   */
  const onChangeRecommendationStatementType = (value: string) => {
    const rules: RulesType[] = [...ruleEngineFormData.rules];
    rules.splice(ruleIndex, 1, {
      ...(rules.at(ruleIndex) ?? DEFAULT_RULE),
      recommendationStatementType: value,
      checkId: rulesetTemplateList
        .find((item) => item.recommendationType === rule.recommendationType)
        ?.statements?.find((item) => item.recommendationStatementType === value)
        ?.checkId,
      statementValuesList: [],
    });

    dispatch(setRuleEngineFormData({ ...ruleEngineFormData, rules: rules }));
  };

  return (
    <div className="rule-card card flex flex-column flex-gap-8">
      <Row className="recomendation" gutter={12}>
        <Col span={8}>
          <FormLabel
            title={t('createRulesetLabels.rulesets.recomendationCategory')}
          />
          <SelectDropdown
            className="full-width"
            value={rule?.recommendationType}
            options={RecommendationCategoriesLabels.map((item) => ({
              value: item.key,
              label: item.label,
              disabled: isRecommendationCategoryDisabled(
                rulesetTemplateList,
                ruleEngineFormData,
                item.key,
                rule.recommendationType
              ),
            }))}
            onChange={onChangeRecommendationCategory}
            showSearch
          />
        </Col>
        <Col span={14}>
          <FormLabel title={t('createRulesetLabels.rulesets.statement')} />
          <SelectDropdown
            className="full-width"
            value={rule?.recommendationStatementType}
            options={rulesetTemplateList
              .find(
                (item) => item.recommendationType === rule?.recommendationType
              )
              ?.statements?.map((item) => ({
                value: item.recommendationStatementType,
                label: toTitleCase(
                  item.recommendationStatementType.replaceAll('_', ' ')
                ),
                disabled: isRecommendationStatementDisabled(
                  rulesetTemplateList,
                  ruleEngineFormData,
                  rule?.recommendationType,
                  item.recommendationStatementType,
                  ruleIndex
                ),
              }))}
            onChange={onChangeRecommendationStatementType}
            showSearch
          />
        </Col>
        <Col span={2} className="flex">
          <DeleteIconButton
            className="cursor-pointer flex flex-align-self-end"
            width={32}
            height={32}
            onClick={deleteRule}
          />
        </Col>
      </Row>
      <Row className="statements flex flex-gap-8">
        <Col className="map-line" />
        <Col
          span={22}
          className="statement-list card full-width flex flex-column flex-gap-16"
        >
          <div className="font-button">
            {t('createRulesetLabels.rulesets.statementValues')}
          </div>
          {!rule?.statementValuesList ||
            (rule.statementValuesList.length === 0 && (
              <div className="font-validation-error">
                {t('createRulesetLabels.rulesets.noStatementsErrorMessage')}
              </div>
            ))}
          {rule?.statementValuesList?.map((statement, index) => (
            <StatementComponent
              key={statement.field}
              statement={statement}
              statementIndex={index}
              rule={rule}
              ruleIndex={ruleIndex}
              rulesetTemplateList={rulesetTemplateList}
              createButtonClick={createButtonClick}
            />
          ))}
        </Col>
      </Row>
      <Row>
        <Col offset={1}>
          <Button
            className="add-new-value-cta"
            size={BUTTON_SIZE.SMALL}
            title={t('createRulesetLabels.rulesets.addNewStatementValue')}
            iconName={ICONS.ADD_LINE}
            onClick={addNewStatement}
            disabled={
              getDefaultStatement(
                rulesetTemplateList,
                ruleEngineFormData,
                rule.recommendationType,
                rule.recommendationStatementType
              ) === undefined
            }
          />
        </Col>
      </Row>
      <RulesLimitModal
        show={showStatementsLimitModal}
        onClickOk={() => setShowStatementsLimitModal(false)}
        message={t('createRulesetLabels.rulesets.statementsLimitMessage')}
      />
    </div>
  );
};

export default RuleCard;
