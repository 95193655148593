import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { selectReleaseNotes, setReleaseNotes } from 'redux/releaseNotesSlice';
import NavigationPath from 'components/NavigationPath';
import Accordion from 'components/Accordion';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import DashboardComponent from 'components/DashboardComponent';
import { DATE_FORMAT, SLASH_DATE_FORMAT } from 'utils/date';

import ReleaseNoteHeading from './components/ReleaseNoteHeading';
import ReleaseNoteDescription from './components/ReleaseNoteDescription';
import { getReleaseNotes } from './services';
import { ReleaseNoteType } from './types';

import './index.scss';

const ReleaseNotesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { releaseNotes } = useSelector(selectReleaseNotes);

  const [releaseNotesReqStatus, setReleaseNotesReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    addZeroMarginClass();

    if (releaseNotes.length === 0) {
      fetchReleaseNotes();
    }

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  const fetchReleaseNotes = () => {
    setReleaseNotesReqStatus(REQUEST_STATUS.PROCESSING);

    getReleaseNotes()
      .then((res: any) => {
        const data = res?.data?.responseData ?? [];
        const releaseNotesData: ReleaseNoteType[] = data.map((release: any) => {
          const featureList = release?.notesWithCategories ?? [];
          return {
            version: release.releaseVer,
            releaseDate: moment(release.releaseDate, SLASH_DATE_FORMAT).format(
              DATE_FORMAT
            ),
            newFeatures:
              featureList.find((item: any) => item.category === 'Feature')
                ?.captions ?? [],
            improvements:
              featureList.find((item: any) => item.category === 'Improvement')
                ?.captions ?? [],
            bugFixes:
              featureList.find((item: any) => item.category === 'Bugs')
                ?.captions ?? [],
          };
        });
        releaseNotesData.sort((a, b) =>
          moment(b.releaseDate).diff(a.releaseDate)
        );
        dispatch(setReleaseNotes(releaseNotesData));
        setReleaseNotesReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setReleaseNotesReqStatus);
      });
  };

  return (
    <div className="release-notes flex flex-column flex-fit">
      <header className="release-notes-header new-page-header modal-heading flex flex-align-items-center flex-space-between">
        {t('navigationMenu.releaseNotes')}
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <DashboardComponent
          component={
            <Accordion
              className="release-notes-list"
              items={releaseNotes.map((release) => ({
                key: release.version,
                heading: <ReleaseNoteHeading release={release} />,
                children: <ReleaseNoteDescription release={release} />,
              }))}
              expandIconPosition="end"
              defaultActiveKey={releaseNotes?.[0]?.version}
            />
          }
          requestStatus={releaseNotesReqStatus}
        />
      </div>
    </div>
  );
};

export default ReleaseNotesPage;
