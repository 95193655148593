import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatbotProviderListType } from 'components/ChatBoxModal/types';
import Checkbox from 'components/Checkbox';
import DashboardComponent from 'components/DashboardComponent';
import Modal from 'components/Modal';
import Button from 'components/Button';
import {
  isAllLLMsChecked,
  isLLMSelected,
  onSelectAllModels,
  onSelectSubModel,
} from './utils';
import Empty from 'components/Empty';

import './index.scss';

type ChatBotLLMsModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  allChatBotLLMs: ChatbotProviderListType[];
  fetchAllLLMsReqStatus: string;
  selectedChatBotLLMs: ChatbotProviderListType[];
  onClickOk: (selectedModels: ChatbotProviderListType[]) => void;
};

const ChatBotLLMsModal = ({
  show,
  setShow,
  allChatBotLLMs,
  fetchAllLLMsReqStatus,
  selectedChatBotLLMs,
  onClickOk,
}: ChatBotLLMsModalProps) => {
  const { t } = useTranslation();

  const [selectedLLMs, setSelectedLLMs] = useState<ChatbotProviderListType[]>(
    []
  );

  useEffect(() => {
    setSelectedLLMs(selectedChatBotLLMs);
  }, [selectedChatBotLLMs]);

  const LLMsList = (
    <div className="llm-models-selection flex flex-column flex-gap-8">
      <div className="heading form-header flex flex-space-between flex-gap-16">
        <div>{t('llms')}</div>
        <Checkbox
          checked={isAllLLMsChecked(allChatBotLLMs, selectedLLMs)}
          onChange={(e) =>
            onSelectAllModels(e.target.checked, allChatBotLLMs, setSelectedLLMs)
          }
          disabled={allChatBotLLMs.length === 0}
          data-testid="all-checkbox"
        />
      </div>
      <div className="llm-models flex flex-column flex-gap-16">
        {allChatBotLLMs.map((llmModel) => (
          <div key={llmModel.model_name}>
            <div className="model-name small-grey-button">
              {llmModel.model_name}
            </div>
            <div className="llm-sub-models">
              {llmModel.submodel_name.map((subModel) => (
                <div
                  className="flex flex-space-between flex-gap-16"
                  key={subModel}
                >
                  <div>{subModel}</div>
                  <Checkbox
                    checked={isLLMSelected(
                      subModel,
                      llmModel.model_name,
                      selectedLLMs
                    )}
                    onChange={(e) =>
                      onSelectSubModel(
                        e.target.checked,
                        llmModel.model_name,
                        subModel,
                        selectedLLMs,
                        setSelectedLLMs
                      )
                    }
                    data-testid={subModel}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {allChatBotLLMs.length === 0 && (
        <Empty description={t('noLLMsSupported')} />
      )}
    </div>
  );

  return (
    <Modal
      rootClassName="chat-bot-llms-modal"
      open={show}
      onCancel={() => {
        setSelectedLLMs(selectedChatBotLLMs);
        setShow(false);
      }}
      title={t('chooseLLMs')}
      footer={
        <div className="flex flex-end">
          <Button
            rootClassName=""
            title={t('ok')}
            onClick={() => onClickOk(selectedLLMs)}
            disabled={allChatBotLLMs.length === 0}
          />
        </div>
      }
      data-testid="chat-bot-llms-modal"
    >
      <DashboardComponent
        component={LLMsList}
        requestStatus={fetchAllLLMsReqStatus}
        errorMessage={t('errorFetchingLLMs')}
      />
    </Modal>
  );
};

export default ChatBotLLMsModal;
