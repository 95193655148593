import i18n from 'i18n';
import { COMPARATORS } from 'constants/requestBody';

export const COMPARATORS_LIST = [
  {
    label: i18n.t('comparatorsLabels.greaterThanOrEqualTo'),
    symbol: COMPARATORS.GREATER_THAN_OR_EQUAL,
  },
  {
    label: i18n.t('comparatorsLabels.lessThanOrEqualTo'),
    symbol: COMPARATORS.LESS_THAN_OR_EQUAL,
  },
  { label: i18n.t('comparatorsLabels.equalsTo'), symbol: COMPARATORS.EQUALS },
  {
    label: i18n.t('comparatorsLabels.notEqualTo'),
    symbol: COMPARATORS.NOT_EQUAL_TO,
  },
];
