import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectPurchase,
  setPurchaseConnectionList,
  setSelectedConnection,
  setSelectedGcpProjectId,
  setSelectedPurchasePageView,
} from 'redux/purchaseSlice';
import ConnectionsListDrawer from 'components/ConnectionsListDrawer';
import Button from 'components/Button';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { ConnectionListType } from 'types/dashboard';
import { searchConnectionData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { getProviderLogo } from 'utils/providerDetails';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import './index.scss';

type PurchaseHeaderProps = {
  setIsEmptyConnections?: (val: boolean) => void;
  setRequestStatus?: (val: string) => void;
  showConnectionDropdown?: boolean;
};

const PurchaseHeader = ({
  setIsEmptyConnections,
  setRequestStatus,
  showConnectionDropdown = true,
}: PurchaseHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, purchaseConnectionList } =
    useSelector(selectPurchase);

  const [fetchConnectionsRequestStatus, setFetchConnectionsRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [showSwitchConnectionDrawer, setShowSwitchConnectionDrawer] =
    useState(false);

  useEffect(() => {
    if (showConnectionDropdown) {
      fetchConnections();
    }
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    if (purchaseConnectionList.length === 0) {
      return;
    }

    if (
      !selectedConnection ||
      !purchaseConnectionList.some(
        (connection) =>
          connection.connectorId === selectedConnection.connectorId
      )
    ) {
      dispatch(setSelectedConnection(purchaseConnectionList[0]));
    }
  }, [purchaseConnectionList]);

  /**
   * @function fetchConnections
   * @description Function to fetch the connection list
   */
  const fetchConnections = (addLoader: boolean = true) => {
    addLoader && setFetchConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: [PROVIDER.AWS, PROVIDER.GCP].join(','),
    };

    searchConnectionData(params)
      .then((res: any) => {
        let connections: ConnectionListType[] =
          res?.data?.responseData?.content || [];

        dispatch(setPurchaseConnectionList(connections));
        setIsEmptyConnections?.(connections.length === 0);
        if (addLoader) {
          setFetchConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
          setRequestStatus?.(REQUEST_STATUS.SUCCESS);
        }
      })
      .catch((e) => {
        onApiCallError(
          e,
          false,
          addLoader ? setFetchConnectionsRequestStatus : undefined
        );
        dispatch(setPurchaseConnectionList([]));
        addLoader && setRequestStatus && setRequestStatus(REQUEST_STATUS.ERROR);
      });
  };

  return (
    <div className="purchase-header flex flex-align-items-center flex-space-between flex-wrap">
      <div className="modal-heading flex flex-align-items-center flex-gap-16">
        <img
          width={35}
          height={35}
          src={getProviderLogo(selectedConnection?.provider ?? '')}
          alt={`${selectedConnection?.provider} Logo`}
        />
        <span>{selectedConnection?.displayName}</span>
      </div>
      {showConnectionDropdown && (
        <>
          <div className="header-right flex flex-align-items-center">
            <Button
              title={t('dashboardLabels.switchConnection')}
              iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
              iconSize={ICONS_SIZE.ONE_X}
              onClick={() => setShowSwitchConnectionDrawer(true)}
            />
          </div>
          <ConnectionsListDrawer
            isCB360TabSelected
            show={showSwitchConnectionDrawer}
            setShow={setShowSwitchConnectionDrawer}
            selectedConnection={selectedConnection}
            loading={
              fetchConnectionsRequestStatus === REQUEST_STATUS.PROCESSING
            }
            connectionList={purchaseConnectionList}
            onClick={(id: string) => {
              dispatch(setSelectedGcpProjectId(''));
              dispatch(setSelectedPurchasePageView(''));
              dispatch(
                setSelectedConnection(
                  purchaseConnectionList.find(
                    (connection) => connection.connectorId === id
                  ) ?? null
                )
              );
              setShowSwitchConnectionDrawer(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default PurchaseHeader;
