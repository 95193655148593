import { onApiCallError } from 'utils/handleErrors';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CMDBFieldTagsMappingType } from 'pages/CmdbSetupListPage/types';
import { fetchConnectionData } from 'utils/services';
import { ConnectionListType } from 'types/dashboard';

import { fetchCmdbFields } from './services';

/**
 * @function fetchAndSetCmdbFields
 * @description Function to fetch CMDB fields and set to the setter
 * @param setData Object containing the callbacks to set the data and request status
 */
export const fetchAndSetCmdbFields = (setData: {
  setCmdbFields: (val: string[]) => void;
  setReqStatus: (val: string) => void;
}) => {
  setData.setReqStatus(REQUEST_STATUS.SUCCESS);

  fetchCmdbFields()
    .then((res: any) => {
      setData.setCmdbFields(res.data.responseData);
      setData.setReqStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setData.setReqStatus);
    });
};

/**
 * @function fetchAndSetConnections
 * @description Function to fetch cloud connections and set to the setter
 * @param setData Object containing the callbacks to set the data and request status
 */
export const fetchAndSetConnections = (setData: {
  setConnections: (val: ConnectionListType[]) => void;
  setReqStatus: (val: string) => void;
}) => {
  setData.setReqStatus(REQUEST_STATUS.SUCCESS);

  fetchConnectionData()
    .then((res: any) => {
      setData.setConnections(
        res.data.responseData.content.filter((item: any) => item.wantBilling)
      );
      setData.setReqStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setData.setReqStatus);
    });
};

/**
 * @function getCmdbFieldOptions
 * @description Function to return the CMDB fields for dropdown options by adding the disabled field based on the existing fields
 * @param cmdbFields List of cmdb field to be added
 * @param cmdbFieldTagsMapping List of CMDB field and tags mapping based on which the disabled field is calculated
 * @returns List of CMDB fields for the dropdown options.
 */
export const getCmdbFieldOptions = (
  cmdbFields: string[],
  cmdbFieldTagsMapping: CMDBFieldTagsMappingType[]
) => {
  return cmdbFields.map((field) => ({
    value: field,
    label: field,
    disabled: cmdbFieldTagsMapping.some((tagMap) => tagMap.cmdbField === field),
  }));
};
