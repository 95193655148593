import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { providerList } from 'redux/providerSlice';
import { FormLabel } from 'components/FormLabel';
import UploadFile from 'components/UploadFile';

import { getSaticFilesAllowedFileTypes } from './utils';

type UploadFileFormProps = {
  focusSchema: boolean;
  isStandardSchema: boolean;
  setFileToBeUploaded: (value: File | undefined) => void;
};

const UploadFileForm = ({
  focusSchema,
  isStandardSchema,
  setFileToBeUploaded,
}: UploadFileFormProps) => {
  const { t } = useTranslation();
  const { selectedProvider } = useSelector(providerList);

  const [fileRequiredValidation, setFileRequiredValidation] = useState('');

  // File states
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [filename, setFilename] = useState('');

  return (
    <div className="collapse-body flex flex-column">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('importFileLabels.formLabels.step4.fileTypeFile', {
            fileType: getSaticFilesAllowedFileTypes(
              selectedProvider,
              focusSchema,
              isStandardSchema
            ).label,
            interpolation: { escapeValue: false },
          })}
          required={true}
        />
        <UploadFile
          isFileUploaded={isFileUploaded}
          filename={filename}
          accept={getSaticFilesAllowedFileTypes(
            selectedProvider,
            focusSchema,
            isStandardSchema
          ).extensions.join(',')}
          buttonTitle={t(`importFileLabels.formLabels.step4.chooseFile`)}
          onSelectFile={async (file, onSuccess) => {
            setFileToBeUploaded(file);
            onSuccess();
            setFilename(file.name);
            setIsFileUploaded(true);
            setFileRequiredValidation('');
          }}
          onRemoveFile={() => {
            setFilename('');
            setIsFileUploaded(false);
            setFileToBeUploaded(undefined);
          }}
        />
        <span
          style={{
            display: `${fileRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {fileRequiredValidation}
        </span>
      </div>
    </div>
  );
};

export default UploadFileForm;
