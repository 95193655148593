import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import { validateInputLength } from 'pages/ConnectingCSPPage/utils';
import { ruleEngine, setRuleEngineFormData } from 'redux/ruleEngineSlice';
import { getAllRulesets } from 'pages/RuleEnginePage/services';
import { RulesetsType } from 'pages/RuleEnginePage/types';
import {
  getValidationStyle,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { FREE_TEXT_CHARACTER_LIMIT } from 'constants/validation';

type BasicDetailsProps = {
  createButtonClick: boolean;
  setBasicDetailsValidation: (val: boolean) => void;
};

const BasicDetails = ({
  createButtonClick,
  setBasicDetailsValidation,
}: BasicDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { ruleEngineFormData } = useSelector(ruleEngine);

  const [nameValidationMessage, setNameValidationMessage] = useState('');
  const [descriptionValidationMessage, setDescriptionValidationMessage] =
    useState('');
  const [rulesets, setRulesets] = useState<RulesetsType[]>([]);

  useEffect(() => {
    fetchAllRulesets();
  }, []);

  useEffect(() => {
    checkDuplicateRulesetName(ruleEngineFormData?.ruleSetName);
  }, [rulesets]);

  useEffect(() => {
    setBasicDetailsValidation(validateBasicDetails(false));
  }, [
    ruleEngineFormData.ruleSetName,
    ruleEngineFormData.description,
    ruleEngineFormData.cloudProvider,
  ]);

  useDidMountEffect(() => {
    validateBasicDetails(true);
  }, [createButtonClick]);

  /**
   * @function fetchAllRulesets
   * @description Function to fetch all the rulesets for validation
   */
  const fetchAllRulesets = () => {
    getAllRulesets()
      .then((res: any) => {
        setRulesets(res?.data?.responseData?.content);
      })
      .catch((e) => onApiCallError(e, false));
  };

  /**
   * @function checkDuplicateRulesetName
   * @description Function to validate for duplicate ruleset name
   * @param currentRulesetName ruleset name
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean false if the ruleset name is duplicate else false
   */
  const checkDuplicateRulesetName = (
    currentRulesetName: string,
    setErrorMessage: boolean = true
  ) => {
    if (
      rulesets.some(
        (ruleset) =>
          ruleset.ruleSetName === currentRulesetName &&
          ruleset.rulesetId !== ruleEngineFormData?.rulesetId
      )
    ) {
      setErrorMessage &&
        setNameValidationMessage(
          t('createRulesetLabels.basicDetails.duplicateRulesetMessage')
        );
      return false;
    }
    return true;
  };

  /**
   * @function validateRulesetName
   * @description Function to validate the ruleset name field
   * @param value Value to be validated
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateRulesetName = (
    value: string,
    setErrorMessage: boolean = true
  ) => {
    if (
      !validateEmptyField(
        value,
        t('createRulesetLabels.basicDetails.name'),
        setNameValidationMessage,
        setErrorMessage
      ) &&
      validateInputLength(
        value,
        t(`createRulesetLabels.basicDetails.name`),
        setNameValidationMessage
      ) &&
      checkDuplicateRulesetName(value, setErrorMessage)
    ) {
      return true;
    }

    return false;
  };

  /**
   * @function validateDescription
   * @description Function to validate the description field
   * @param value Value to be validated
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateDescription = (
    value: string,
    setErrorMessage: boolean = true
  ) => {
    if (
      validateEmptyField(
        value,
        t('createRulesetLabels.basicDetails.description'),
        setDescriptionValidationMessage,
        setErrorMessage
      )
    ) {
      return false;
    }

    return !validateStringLengthLessThan(
      value,
      FREE_TEXT_CHARACTER_LIMIT,
      t('createRulesetLabels.basicDetails.description'),
      setErrorMessage ? setDescriptionValidationMessage : undefined
    );
  };

  /**
   * @function validateBasicDetails
   * @description Function to validate the fields in basic details
   * @param value Value to be validated
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateBasicDetails = (setErrorMessage: boolean) => {
    let validation = true;
    validation =
      validateRulesetName(ruleEngineFormData.ruleSetName, setErrorMessage) &&
      validation;
    validation =
      validateDescription(ruleEngineFormData.description, setErrorMessage) &&
      validation;
    validation = !!ruleEngineFormData.cloudProvider && validation;
    return validation;
  };

  /**
   * @function onChangeAutoPilotStatus
   * @description Updates the autopilot status based on the selection
   * @param isAutoPilotEnabled Boolean to indicate whether the autopilot is enabled or not
   */
  const onChangeAutoPilotStatus = (isAutoPilotEnabled: boolean) => {
    // Update the view filter creation to false if the auto pilot is enabled, else false
    const viewFilterCreationStatus = isAutoPilotEnabled
      ? false
      : ruleEngineFormData.createViewFilter;

    dispatch(
      setRuleEngineFormData({
        ...ruleEngineFormData,
        autopilotOn: isAutoPilotEnabled,
        createViewFilter: viewFilterCreationStatus,
      })
    );
  };

  return (
    <div className="basic-details full-height flex flex-column flex-gap-24">
      <span className="form-header">
        {t('createRulesetLabels.basicDetails.formTitle')}
      </span>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('createRulesetLabels.basicDetails.name')}
          required={true}
        />
        <Input
          default
          type="input"
          placeholder={t('createRulesetLabels.basicDetails.name')}
          value={ruleEngineFormData?.ruleSetName}
          onChange={(e: any) => {
            dispatch(
              setRuleEngineFormData({
                ...ruleEngineFormData,
                ruleSetName: e.target.value,
              })
            );
            validateRulesetName(e.target.value.trim());
          }}
          onBlur={() => {
            validateRulesetName(ruleEngineFormData?.ruleSetName.trim());
          }}
        />
        <span
          style={{
            display: `${getValidationStyle(nameValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {nameValidationMessage}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`createRulesetLabels.basicDetails.description`)}
          required={true}
        />
        <Input
          type="textarea"
          autoSize={{ minRows: 3, maxRows: 6 }}
          placeholder={t('createRulesetLabels.basicDetails.description')}
          value={ruleEngineFormData?.description}
          onChange={(e: any) => {
            dispatch(
              setRuleEngineFormData({
                ...ruleEngineFormData,
                description: e.target.value,
              })
            );
            validateDescription(e.target.value.trim());
          }}
          onBlur={() =>
            validateDescription(ruleEngineFormData.description.trim())
          }
        />
        <span
          style={{
            display: `${getValidationStyle(descriptionValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {descriptionValidationMessage}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`createRulesetLabels.basicDetails.enableAutoPilotLabel`)}
          required={true}
        />
        <Radio.Group
          buttonStyle="solid"
          value={ruleEngineFormData.autopilotOn}
          onChange={(e) => onChangeAutoPilotStatus(e.target.value)}
          data-testid="autopilot-radio"
        >
          <Radio.Button
            key="Yes"
            value={true}
            data-testid="autopilot-radio-yes"
          >
            {t('radioYes')}
          </Radio.Button>
          <Radio.Button key="No" value={false} data-testid="autopilot-radio-no">
            {t('radioNo')}
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`createRulesetLabels.basicDetails.wantFilterView`)}
          required={true}
          disabled={ruleEngineFormData.autopilotOn}
        />
        <Radio.Group
          buttonStyle="solid"
          value={ruleEngineFormData.createViewFilter}
          onChange={(e) => {
            dispatch(
              setRuleEngineFormData({
                ...ruleEngineFormData,
                createViewFilter: e.target.value,
              })
            );
          }}
          disabled={ruleEngineFormData.autopilotOn}
          data-testid="view-filter-radio"
        >
          <Radio.Button
            key="Yes"
            value={true}
            data-testid="view-filter-radio-yes"
          >
            {t('radioYes')}
          </Radio.Button>
          <Radio.Button
            key="No"
            value={false}
            data-testid="view-filter-radio-no"
          >
            {t('radioNo')}
          </Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

export default BasicDetails;
