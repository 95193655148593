import Icon from 'components/Icon';
import SelectDropdown from 'components/Select';
import { ICONS, ICONS_SIZE } from 'constants/icons';

import { ReportSharedTeamType } from '../../types';
import { ShareReportPermissionOptions } from '../../constants';

import './index.scss';

type TeamRowProps = {
  team: ReportSharedTeamType;
  onClickRemoveTeamFromTeam: (val: ReportSharedTeamType) => void;
  onSelectPermission: (val: string) => void;
};

const TeamRow = ({
  team,
  onClickRemoveTeamFromTeam,
  onSelectPermission,
}: TeamRowProps) => {
  return (
    <div
      key={team.teamName}
      className="share-report-team-row flex flex-center flex-space-between"
    >
      <div className="flex flex-gap-8 flex-center">
        <div className="team-pic flex flex-center font-button">T</div>
        <div className="font-button">{team.teamName}</div>
      </div>
      <div className="flex flex-align-items-center flex-gap-8">
        <SelectDropdown
          className="permission-dropdown"
          value={team.permission}
          options={ShareReportPermissionOptions}
          onSelect={onSelectPermission}
        />
        <span className="flex flex-end flex-fit">
          <Icon
            className="remove-icon"
            dataTestId="remove-team-icon"
            iconName={ICONS.CLOSE_LINE}
            size={ICONS_SIZE.FW}
            onClick={() => onClickRemoveTeamFromTeam(team)}
          />
        </span>
      </div>
    </div>
  );
};

export default TeamRow;
