import i18n from 'i18n';

export enum ReportTypes {
  MY_REPORT = 'MY_REPORT',
  SHARED = 'SHARED',
}

export const REPORTS_TYPES_LIST = [
  { id: ReportTypes.MY_REPORT, title: i18n.t('reports.myReport') },
  { id: ReportTypes.SHARED, title: i18n.t('reports.shared') },
];

export enum ReportQuickActions {
  OPEN = 'OPEN',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const REPORT_QUICK_ACTIONS_LIST = [
  {
    id: ReportQuickActions.OPEN,
    title: i18n.t('reports.open'),
  },
  {
    id: ReportQuickActions.EDIT,
    title: i18n.t('reports.edit'),
  },
  {
    id: ReportQuickActions.DELETE,
    title: i18n.t('reports.delete'),
  },
];

export enum ReportPermissions {
  READ = 'READ',
  WRITE = 'WRITE',
}

export const REPORT_PERMISSIONS_LIST = [
  {
    id: ReportPermissions.READ,
    title: i18n.t('reports.read'),
  },
  {
    id: ReportPermissions.WRITE,
    title: i18n.t('reports.write'),
  },
];
