/**
 * @function clusterUtilizationAndPodCountTableData
 * @description Function to return the table data for granulate line chart
 * @returns List of grouped table data
 */
export const clusterUtilizationAndPodCountTableData = (data: any[]) => {
  const tabledata: any[] = [];
  data.forEach((item) => {
    const existingItem = tabledata.find(
      (obj) => obj.field === item.category || obj.field === item.namespace
    );
    if (existingItem) {
      existingItem[`${item.time}-value`] =
        item.yField ?? `${item.utilization.toFixed(2)} %`;
    } else {
      tabledata.push({
        field: item.category ?? item.namespace,
        [`${item.time}-value`]:
          item.yField ?? `${item.utilization.toFixed(2)} %`,
        key: tabledata.length,
        slNo: tabledata.length,
      });
    }
  });
  return tabledata;
};
