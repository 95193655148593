import i18n from 'i18n';

import {
  ImportFileDataType,
  SchemaDefinitionType,
} from 'pages/StaticFilesPage/types';

import { SCHEMA_MODES, SCHEMA_TYPES } from '../SchemaRow/constants';

/**
 * @function resetSchemaDefinitionToStandard
 * @description Updates the mode and type fields based on the standard schema chosen for each schema row
 * @param schemaDefinition Schema definition to be updated
 * @param standardSchema Standard schema from whcih the field values needs to be used
 * @returns A list of schema definition with updated vaues, returns as is if a row doesn't contain the standard field mapping
 */
export const resetSchemaDefinitionToStandard = (
  schemaDefinition: SchemaDefinitionType[],
  standardSchema: SchemaDefinitionType[]
) => {
  return schemaDefinition.map((schemaRow) => {
    // Row has standard field mapping
    if (schemaRow.mappedFieldName) {
      const standardFieldDetails = standardSchema.find(
        (item) => item.name === schemaRow.mappedFieldName
      );
      return {
        ...schemaRow,
        mode: standardFieldDetails!.mode,
        type: standardFieldDetails!.type,
      };
    }

    // Returns the row as is if the row doesn't have standard field mapping
    return schemaRow;
  });
};

/**
 * @function getSchemaDefaultFields
 * @description Function to get the default schema
 * @returns object with default schema fields
 */
export const getSchemaDefaultFields = () => {
  return { name: '', type: '', mode: '' };
};

/**
 * @function validateSchemaDefinition
 * @description Function to validate the schema definition based on the configuration and schema added
 * @param importFileData The Object containing the import file details and schemas
 * @param jsonSchema Parsed JSON schema
 * @param setErrorMessage Callback to set the error message
 * @param standardSchema List of standard schema for validation
 * @returns Modified JSON schema post validation
 */
const validateSchemaDefinition = (
  importFileData: ImportFileDataType,
  jsonSchema: any[],
  setErrorMessage: (val: string | null) => void,
  standardSchema: SchemaDefinitionType[]
) => {
  // Validations for Spend diagnostics enabled
  if (importFileData.wantSpendDiagnosticsDashboard) {
    return jsonSchema.map((schemaRow) => {
      const standardFieldMap = standardSchema.find(
        (schema) => schema.name === schemaRow.mappedFieldName
      );

      // If mappedFieldName is not added or if none of the fields in standard schema matches with mappedFieldName provides, return the schema definition with mapped field details being empty
      if (!schemaRow.mappedFieldName || !standardFieldMap) {
        setErrorMessage(i18n.t('schemaNotMatching'));
        return { ...schemaRow, type: '', mappedFieldName: '', mode: '' };
      }

      // If the matching field in standard schema found, check the type and mode and set them to standard Schema if they are not matching
      let modifiedSchemaRow = { ...schemaRow };
      if (standardFieldMap.type !== schemaRow.type) {
        setErrorMessage(i18n.t('schemaNotMatching'));
        modifiedSchemaRow = {
          ...modifiedSchemaRow,
          type: standardFieldMap.type,
        };
      }

      if (standardFieldMap.mode !== schemaRow.mode) {
        setErrorMessage(i18n.t('schemaNotMatching'));
        modifiedSchemaRow = {
          ...modifiedSchemaRow,
          mode: standardFieldMap.mode,
        };
      }

      return modifiedSchemaRow;
    });
  }

  // Validations for Spend Diagnostics Disabled
  return jsonSchema.map((schemaRow) => {
    let modifiedSchemaRow = { ...schemaRow };

    // Validate type field
    if (schemaRow.type && !SCHEMA_TYPES.includes(schemaRow.type)) {
      setErrorMessage(i18n.t('schemaNotMatching'));
      modifiedSchemaRow = { ...modifiedSchemaRow, type: '' };
    }

    // Validate mode field
    if (schemaRow.mode && !SCHEMA_MODES.includes(schemaRow.mode)) {
      setErrorMessage(i18n.t('schemaNotMatching'));
      modifiedSchemaRow = { ...modifiedSchemaRow, mode: '' };
    }

    return modifiedSchemaRow;
  });
};

/**
 * @function convertSchemaToSchemaDefinition
 * @description Function to convert the schem string into a valid JSON schema definition
 * @param importFileData The Object containing the import file details and schemas
 * @param setErrorMessage Callback to set the error message
 * @param standardSchema List of standard schema for validation
 * @returns Modified JSON schema post validation
 */
export const convertSchemaToSchemaDefinition = (
  importFileData: ImportFileDataType,
  setErrorMessage: (val: string | null) => void,
  standardSchema: SchemaDefinitionType[]
) => {
  // Return empty row if the shema entered is empty
  if (!importFileData.schema) {
    setErrorMessage(null);
    return [getSchemaDefaultFields()];
  }

  let jsonSchema = [];
  try {
    jsonSchema = JSON.parse(importFileData.schema);
  } catch {
    // If schema parsing fails, return the previous schema definition
    setErrorMessage(i18n.t('failedToConvertTheSchema'));
    return importFileData.schemaDefinition;
  }

  // If the parsed JSON is not an array or empty array, returns the existing schema definition without modification
  if (!Array.isArray(jsonSchema) || jsonSchema.length === 0) {
    setErrorMessage(i18n.t('failedToConvertTheSchema'));
    return importFileData.schemaDefinition;
  }

  return validateSchemaDefinition(
    importFileData,
    jsonSchema,
    setErrorMessage,
    standardSchema
  );
};

/**
 * @function convertSchemaDefinitionToSchema
 * @description Function to convert the schem definition into a string schema
 * @param importFileData The Object containing the import file details and schemas
 * @param errorMessage Error message if any while parsing the schema
 * @param setErrorMessage Callback to set the error message
 * @returns String schema
 */
export const convertSchemaDefinitionToSchema = (
  importFileData: ImportFileDataType,
  errorMessage: string | null,
  setErrorMessage: (val: string | null) => void
) => {
  setErrorMessage(null);
  if (errorMessage) {
    return importFileData.schema;
  }

  return JSON.stringify(importFileData.schemaDefinition);
};
