import { t } from 'i18next';
import { insertIndex } from 'utils/dataFormatterUtils';
import { store } from 'redux/store';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

/**
 * @function getGranulatePieChartsExcelData
 * @description Function to return the memory utilization and cpu utilization excel data
 * @param tableData table data
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getGranulatePieChartsExcelData = (
  tableData: any,
  filters: any[],
  sheetName: string,
  angleField: string
) => {
  return {
    sheetName: sheetName,
    columns: getGranulatePieChartExportColumns(angleField),
    data: insertIndex(tableData).map((item) => ({
      ...item,
      costAfterCredit: (item.costAfterCredit ?? 0).toFixed(2),
    })),
    filters: filters,
  };
};

export const getGranulatePieChartExportColumns = (angleField: string) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: t('consolidatedDashboard.applicationDetails.name'),
    dataKey: 'name',
    key: 'name',
    width: 120,
  },
  {
    header: t('consolidatedDashboard.applicationDetails.namespace'),
    dataKey: 'namespace',
    key: 'namespace',
    width: 120,
  },
  {
    header: t(`consolidatedDashboard.applicationDetails.${angleField}`),
    dataKey: angleField,
    key: angleField,
    width: 150,
    alignment: 'center',
  },
  {
    header: t('consolidatedDashboard.applicationDetails.costCredit', {
      currencySymbol: currencySymbol,
    }),
    dataKey: 'costAfterCredit',
    key: 'costAfterCredit',
    width: 120,
    alignment: 'center',
  },
];
