import i18n from 'i18n';
import moment from 'moment';
import { FIELD_VALUE_TYPES, OPERATORS } from 'pages/RuleEnginePage/constants';
import {
  RulesetTemplateType,
  RulesType,
  StatementValueTemplateType,
  StatementValueType,
} from 'pages/RuleEnginePage/types';
import { isEmptyField, isNumber } from 'utils/validations';

/**
 * @function parseStatements
 * @description Function to parse the template mapping and construct list of templates
 * @param statementsObject Object containing the template data of statements
 * @param statementsToAllCategories statements templates added to all the statements
 * @returns template constructed for each statement type
 */
const parseStatements = (
  statementsObject: Object,
  statementsToAllCategories: Object
) => {
  return Object.entries(statementsObject).map((item: [string, any]) => {
    const statementsList = Object.entries({
      ...item[1],
      ...statementsToAllCategories,
    });
    const checkIdObject = statementsList.find(
      (statement: [string, any]) => statement[0] === 'checkId'
    );
    return {
      recommendationStatementType: item[0],
      checkId: checkIdObject?.[1] as string | undefined,
      statementValuesList: statementsList
        .filter((statement: [string, any]) => statement[0] !== 'checkId')
        .map((statement: [string, any]) => ({
          field: statement[0],
          operators: statement[1]['OPERATORS'],
          type: statement[1]['type'],
          property: statement[1]['property'],
          max: statement[1]['max'],
          min: statement[1]['min'],
          applicableValues: statement[1]['applicable_values'],
        })),
    };
  });
};

/**
 * @function parseRuleEngineTemplate
 * @description Function to parse template map and construct the template for each category
 * @param rawTemplate raw template for parsing
 * @returns Template formed from raw template
 */
export const parseRuleEngineTemplate = (rawTemplate: Object) => {
  const templateMapping = Object.entries(rawTemplate);
  const statementsToAllCategories = templateMapping.find(
    (item) => item[0] === 'ALL'
  );

  const template: RulesetTemplateType[] = templateMapping
    .filter((item) => item[0] !== 'ALL')
    .map((item: [string, Object]) => ({
      recommendationType: item[0],
      statements: parseStatements(
        item[1],
        statementsToAllCategories ? statementsToAllCategories[1] : {}
      ),
    }));
  return template;
};

/**
 * @function getTemplateForARuleStatement
 * @description Function to return a statement template for the rule and statement given
 * @param rulesetTemplateList ruleset template
 * @param rule rule for which the template is filtered
 * @param statement statement for which the template is filtered
 * @returns statement template for the fiven rule and statement
 */
export const getTemplateForARuleStatement = (
  rulesetTemplateList: RulesetTemplateType[],
  rule: RulesType,
  statement: StatementValueType
) => {
  return rulesetTemplateList
    .find((item) => item.recommendationType === rule.recommendationType)
    ?.statements?.find(
      (item) =>
        item.recommendationStatementType === rule.recommendationStatementType
    )
    ?.statementValuesList?.find((item) => item.field === statement.field);
};

/**
 * @function validateIntegerRange
 * @description Function for validating the integer range value(s)
 * @param statement statement validated
 * @param statementTemplate template of the statement validated
 * @returns list of errors
 */
const validateIntegerRange = (
  statement: StatementValueType,
  statementTemplate: StatementValueTemplateType | undefined
) => {
  let errorMessages: string[] = [];
  const value1 = statement.value.valueRange?.min;
  const value2 = statement.value.valueRange?.max;

  // Empty and integer validations
  if (isEmptyField(value1?.trim())) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.value1Empty'),
    ];
  } else if (!isNumber(value1)) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.value1MustBeInteger'),
    ];
  }

  if (isEmptyField(value2?.trim())) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.value2Empty'),
    ];
  } else if (!isNumber(value2)) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.value2MustBeInteger'),
    ];
  }

  if (errorMessages.length > 0) {
    return errorMessages;
  }

  //Values should be in the specified range
  if (statementTemplate?.min && Number(value1) < statementTemplate.min) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.value1CannotBeLessThan', {
        value: statementTemplate.min,
      }),
    ];
  } else if (statementTemplate?.max && Number(value1) > statementTemplate.max) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.value1CannotBeGreaterThan', {
        value: statementTemplate.max,
      }),
    ];
  }

  if (statementTemplate?.min && Number(value2) < statementTemplate.min) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.value2CannotBeLessThan', {
        value: statementTemplate.min,
      }),
    ];
  } else if (statementTemplate?.max && Number(value2) > statementTemplate.max) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.value2CannotBeGreaterThan', {
        value: statementTemplate.max,
      }),
    ];
  }

  if (errorMessages.length > 0) {
    return errorMessages;
  }

  //Value1 should be less than value2
  if (Number(value1) > Number(value2)) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.value1ShouldBeLessThanValue2'),
    ];
  }

  return errorMessages;
};

/**
 * @function validateInteger
 * @description Function for validating the integer value(s)
 * @param statement statement validated
 * @param statementTemplate template of the statement validated
 * @returns list of errors
 */
const validateInteger = (
  statement: StatementValueType,
  statementTemplate: StatementValueTemplateType | undefined
) => {
  let errorMessages: string[] = [];
  if (statement.operator === OPERATORS.BETWEEN) {
    return validateIntegerRange(statement, statementTemplate);
  }

  const value = statement.value.constantValue;

  // Empty and integer validations
  if (isEmptyField(value?.trim())) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.valueEmpty'),
    ];
  } else if (!isNumber(value)) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.valueMustBeInteger'),
    ];
  }

  if (errorMessages.length > 0) {
    return errorMessages;
  }

  //Values should be in the specified range
  if (statementTemplate?.min && Number(value) < statementTemplate.min) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.valueCannotBeLessThan', {
        value: statementTemplate.min,
      }),
    ];
  } else if (statementTemplate?.max && Number(value) > statementTemplate.max) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.valueCannotBeGreaterThan', {
        value: statementTemplate.max,
      }),
    ];
  }

  return errorMessages;
};

/**
 * @function validateString
 * @description Function for validating the String value(s)
 * @param statement statement validated
 * @param statementTemplate template of the statement validated
 * @returns list of errors
 */
const validateString = (
  statement: StatementValueType,
  statementTemplate: StatementValueTemplateType | undefined
) => {
  let errorMessages: string[] = [];
  const value = statement.value.constantValue;

  // Empty validations
  if (statement.operator !== OPERATORS.IN && isEmptyField(value?.trim())) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.valueEmpty'),
    ];
  } else if (
    statement.operator === OPERATORS.IN &&
    (!statement.value.valueList || statement.value.valueList.length === 0)
  ) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.chooseAtleastOneOption'),
    ];
  }

  //Values should be one of the options for menu
  if (errorMessages.length === 0 && statementTemplate?.applicableValues) {
    if (
      statement.operator === OPERATORS.IN &&
      !statement.value.valueList?.some((item) =>
        statementTemplate.applicableValues?.includes(item)
      )
    ) {
      errorMessages = [
        ...errorMessages,
        i18n.t('createRulesetLabels.rulesets.valuesChosenMustWithin', {
          values: statementTemplate.applicableValues.toString(),
        }),
      ];
    }

    if (
      statement.value.constantValue &&
      !statementTemplate.applicableValues.includes(
        statement.value.constantValue
      )
    ) {
      errorMessages = [
        ...errorMessages,
        i18n.t('createRulesetLabels.rulesets.valuesChosenMustbeOneof', {
          values: statementTemplate.applicableValues.toString(),
        }),
      ];
    }
  }

  return errorMessages;
};

/**
 * @function validateDateRange
 * @description Function for validating the Date ranges
 * @param statement statement validated
 * @param statementTemplate template of the statement validated
 * @returns list of errors
 */
const validateDateRange = (
  statement: StatementValueType,
  statementTemplate: StatementValueTemplateType | undefined
) => {
  let errorMessages: string[] = [];

  const value1 = statement.value.valueRange?.min?.trim();
  const value2 = statement.value.valueRange?.max?.trim();

  // Empty date value
  if (isEmptyField(value1)) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.startDateIsEmpty'),
    ];
  }

  if (isEmptyField(value2)) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.endDateIsEmpty'),
    ];
  }

  if (errorMessages.length > 0) {
    return errorMessages;
  }

  //Values should be in the specified range
  if (
    statementTemplate?.min &&
    moment(value1) < moment(statementTemplate.min)
  ) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.startDateCannotBeLessThan', {
        value: statementTemplate.min,
      }),
    ];
  } else if (
    statementTemplate?.max &&
    moment(value1) > moment(statementTemplate.max)
  ) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.startDateCannotBeGreaterThan', {
        value: statementTemplate.max,
      }),
    ];
  }

  if (
    statementTemplate?.min &&
    moment(value2) < moment(statementTemplate.min)
  ) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.endDateCannotBeLessThan', {
        value: statementTemplate.min,
      }),
    ];
  } else if (
    statementTemplate?.max &&
    moment(value2) > moment(statementTemplate.max)
  ) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.endDateCannotBeGreaterThan', {
        value: statementTemplate.max,
      }),
    ];
  }

  if (errorMessages.length > 0) {
    return errorMessages;
  }

  //Value1 should be less than value2
  if (moment(value1) > moment(value2)) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.startDateShouldBeLessThanEndDate'),
    ];
  }

  return errorMessages;
};

/**
 * @function validateDate
 * @description Function for validating the Date value(s)
 * @param statement statement validated
 * @param statementTemplate template of the statement validated
 * @returns list of errors
 */
const validateDate = (
  statement: StatementValueType,
  statementTemplate: StatementValueTemplateType | undefined
) => {
  let errorMessages: string[] = [];
  if (statement.operator === OPERATORS.BETWEEN) {
    return validateDateRange(statement, statementTemplate);
  }

  const value = statement.value.constantValue?.trim();

  // Empty and integer validations
  if (isEmptyField(value)) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.chooseADate'),
    ];
  }

  //Values should be in the specified range
  if (errorMessages.length > 0) {
    return errorMessages;
  }

  if (statementTemplate?.min && moment(value) < moment(statementTemplate.min)) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.dateCannotBeLessThan', {
        value: statementTemplate.min,
      }),
    ];
  } else if (
    statementTemplate?.max &&
    moment(value) > moment(statementTemplate.max)
  ) {
    errorMessages = [
      ...errorMessages,
      i18n.t('createRulesetLabels.rulesets.dateCannotBeGreaterThan', {
        value: statementTemplate.max,
      }),
    ];
  }

  return errorMessages;
};

/**
 * @function validateStatement
 * @description Function for validating the statement rule
 * @param statement statement validated
 * @param statementTemplate template of the statement validated
 * @returns list of errors
 */
export const validateStatement = (
  statement: StatementValueType,
  statementTemplate: StatementValueTemplateType | undefined
) => {
  let errorMessages: string[] = [];

  if (!statement.field) {
    return [i18n.t('createRulesetLabels.rulesets.noFieldMessage')];
  }

  if (!statement.operator) {
    return [i18n.t('createRulesetLabels.rulesets.noOperatorMessage')];
  }

  if (!statementTemplate?.type) {
    return errorMessages;
  }

  if (
    [
      FIELD_VALUE_TYPES.INTEGER.valueOf(),
      FIELD_VALUE_TYPES.FLOAT.valueOf(),
    ].includes(statementTemplate?.type)
  ) {
    errorMessages = validateInteger(statement, statementTemplate);
  }

  if (statementTemplate?.type === FIELD_VALUE_TYPES.STRING) {
    errorMessages = validateString(statement, statementTemplate);
  }

  if (statementTemplate?.type === FIELD_VALUE_TYPES.DATE) {
    errorMessages = validateDate(statement, statementTemplate);
  }
  return errorMessages;
};
