import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { selectPurchase, setSavingPlansCartData } from 'redux/purchaseSlice';
import { selectNavMenu } from 'redux/activeNavMenuSlice';
import EmptyCart from 'pages/PurchasePage/components/EmptyCart';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';

import { PurchaseCartComponents } from 'pages/PurchasePage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';

import BuySavingPlansFormModal from '../BuySavingPlansFormModal';
import PurchasedSavingPlansCard from '../PurchasedSavingPlansCard';
import {
  getTotalDue,
  getTotalMonthlyPayment,
  getTotalUpfrontCost,
} from './utils';
import { buyAwsSavingPlans, getAwsSpOfferingId } from '../../services';
import { PurchaseSavingPlansType } from '../../types';

import './index.scss';

type SavingPlansCartProps = {
  setSpCartComponent: (val: PurchaseCartComponents) => void;
};

const SavingPlansCart = ({ setSpCartComponent }: SavingPlansCartProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { savingPlansCartData, selectedConnection } =
    useSelector(selectPurchase);
  const { isSubMenuOpen } = useSelector(selectNavMenu);

  const [showSpForm, setShowSpForm] = useState(false);
  const [placeOrderRequestStatus, setPlaceOrderRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  /**
   * @function onClickPurchase
   * @description Callback function for clicking the buy button
   */
  const onClickPurchase = () => {
    setPlaceOrderRequestStatus(REQUEST_STATUS.PROCESSING);

    const requests = savingPlansCartData.map((item) => {
      const requestBody = {
        connectorId: selectedConnection?.connectorId,
        region: item.region,
        savingsPlanPaymentOption: item.paymentOption,
        savingsPlanType: item.savingsPlanType,
        instanceFamily: item.instanceFamily,
        term: item.term,
      };
      return getAwsSpOfferingId(requestBody);
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        const cartData: PurchaseSavingPlansType[] = savingPlansCartData?.map(
          (item: PurchaseSavingPlansType, index: any) => ({
            ...item,
            savingsPlanOfferingId: responses.at(index).data.responseData,
          })
        );
        dispatch(setSavingPlansCartData(cartData));
        setSpCartComponent(PurchaseCartComponents.SUCCESS);
        setPlaceOrderRequestStatus(REQUEST_STATUS.SUCCESS);
        dispatch(setSavingPlansCartData([]));
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setPlaceOrderRequestStatus,
          t('purchasePage.savingsPlansLabels.fetchOfferingIdError')
        );
      });
  };

  /**
   * @function purchaseSavingPlans
   * @description Function to purchase the saving plans
   */
  const _purchaseSavingPlans = (cartData: PurchaseSavingPlansType[]) => {
    const requests = cartData.map((item) => {
      const requestBody = {
        connectorId: selectedConnection?.connectorId,
        region: item.region,
        commitment: item.commitment,
        purchaseTime: item.purchaseTime,
        upfrontPaymentAmount: item.upfrontPaymentAmount,
        savingsPlanOfferingId: item.savingsPlanOfferingId,
      };
      return buyAwsSavingPlans(requestBody);
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        if (responses?.every((res: any) => res?.status === 200)) {
          setSpCartComponent(PurchaseCartComponents.SUCCESS);
          setPlaceOrderRequestStatus(REQUEST_STATUS.SUCCESS);
          dispatch(setSavingPlansCartData([]));
          return;
        }
        setSpCartComponent(PurchaseCartComponents.ERROR);
        setPlaceOrderRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setPlaceOrderRequestStatus);
        setSpCartComponent(PurchaseCartComponents.ERROR);
      });
  };

  const getComponent = () => {
    if (savingPlansCartData.length === 0) {
      return (
        <EmptyCart
          description={t(
            'purchasePage.savingsPlansLabels.emptySpCartDescription'
          )}
          buttonTitle={t('purchasePage.savingsPlansLabels.addSavingsPlans')}
          setOpenForm={setShowSpForm}
        />
      );
    }

    return (
      <div className="saving-plans-cart flex flex-column flex-gap-16">
        <div className="flex flex-align-items-center flex-space-between">
          <div>
            <div className="modal-heading">
              {t('purchasePage.savingsPlansLabels.purchaseSavingPlans')}
            </div>
            <div className="cart-heading-subtitle small-grey-button">
              {t('purchasePage.cart.cartHeadingSubTitle')}
            </div>
          </div>
          <Button
            title={t('purchasePage.cart.addNew')}
            type={BUTTON_TYPE.NEUTRAL}
            onClick={() => setShowSpForm(true)}
          />
        </div>
        <div className="cart-list flex flex-column flex-gap-10">
          {savingPlansCartData
            .map((item, index) => ({ ...item, key: index }))
            .map((data, index) => (
              <PurchasedSavingPlansCard
                spData={data}
                index={index}
                key={data.key}
              />
            ))}
        </div>
        <PurchaseFooter
          totalHeading={t('purchasePage.savingsPlansLabels.spTotalDueNow', {
            count: savingPlansCartData.length,
          })}
          totalValue={getTotalDue(savingPlansCartData)}
          costSummary={[
            {
              key: t('purchasePage.savingsPlansLabels.monthlyPayment'),
              value: getTotalMonthlyPayment(savingPlansCartData),
            },
            {
              key: t('purchasePage.savingsPlansLabels.upfrontCost'),
              value: getTotalUpfrontCost(savingPlansCartData),
            },
          ]}
          okTitle={t('purchasePage.cart.placeOrder')}
          onClickCancel={() => navigate(NAVIGATION_MENU_PATH.COMMITMENTS)}
          onClickOk={onClickPurchase}
          loadingOk={placeOrderRequestStatus === REQUEST_STATUS.PROCESSING}
          className={`cart-footer ${isSubMenuOpen && 'nav-pinned'}`}
        />
      </div>
    );
  };

  return (
    <section>
      {getComponent()}
      {showSpForm && (
        <BuySavingPlansFormModal show={showSpForm} setShow={setShowSpForm} />
      )}
    </section>
  );
};

export default SavingPlansCart;
