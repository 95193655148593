import { Col, Row } from 'antd';
import moment from 'moment';
import { sum, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import TagFilters from 'components/TagFilters';
import {
  addZeroMarginClass,
  generateGraphColors,
  getAWSTagFiltersData,
  getPreviousMonthDayAndYear,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import {
  ColouredCostByServiceType,
  ColouredCostByResourceType,
  MonthlyCostByNameType,
  ColouredCostByAccountType,
} from 'types/dataTypes';
import { CHART_TYPES, GRAPH_OPTIONS } from 'constants/graphConfig';
import { PROVIDER } from 'constants/cloudProviders';
import { getChartData } from 'utils/services';
import {
  dateNYearsFromToday,
  DATE_FORMAT,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  formatDateFieldByProvider,
  YEAR_HYPHEN_MONTH,
} from 'utils/date';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
  REQUEST_STATUS,
} from 'constants/requestBody';
import ExpandModal from 'components/ExpandModal';
import { onApiCallError } from 'utils/handleErrors';
import {
  formatArrayToStringByProvider,
  insertIndex,
  numberCommaSeparator,
} from 'utils/dataFormatterUtils';
import DropdownCheckbox from 'components/DropdownCheckbox';
import DatePicker from 'components/DatePicker';
import ControlComponent from 'components/DashboardControl';
import { CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT } from 'constants/userConsole';
import { INPUT_SIZE } from 'constants/appearance';
import { getPdfMetaData } from 'pages/OverviewPage/components/ConnectionsDashboard/utils';

import {
  getCostTimeLineExportColumns,
  getAwsCostTimeLineExcelData,
  getTopAccountsExcelData,
  getTopAccountsTableDataSource,
  getTopAccountsTableHeading,
  getTopServicesExcelData,
  getTopAccountsExportColumns,
} from './utils';
import { TopServicesExportColumns } from './constants';
import CostTimeLine from './components/CostTimeLine';
import TopServices from './components/TopServices';
import TopAccounts from './components/TopAccounts';

const AWSAccountDeepDiveDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    expandedGraphName,
    tableViewEnabled,
    selectedDashboard,
    selectedConnection,
    pdfDownloadMode,
    tagsFilters,
    showExpandGraphModal,
    selectedDashboardView,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>(dateNYearsFromToday(1));
  const [endDate, setEndDate] = useState<string>(
    moment().format(HYPHEN_DATE_FORMAT)
  );

  //States for Top Accounts
  const [awsTopAccountsData, setAwsTopAccountsData] = useState<
    ColouredCostByAccountType[]
  >([]);
  const [awsTopAccountsDataRequestStatus, setAwsTopAccountsDataRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [isTopAccountsTableView, setIsTopAccountsTableView] =
    useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [selectedService, setSelectedService] = useState<string>('');
  const [accountWiseServiceData, setAccountWiseServiceData] = useState<{
    [key: string]: ColouredCostByServiceType[];
  }>({});
  const [serviceWiseResourceData, setServiceWiseResourceData] = useState<{
    [key: string]: ColouredCostByResourceType[];
  }>({});
  const [topAccountsSliderValue, setTopAccountsSliderValue] = useState<{
    x: number;
    y: number;
  }>();

  //States for Account timeline data
  const [awsAccountTimeLineData, setAwsAccountTimeLineData] = useState<
    MonthlyCostByNameType[]
  >([]);
  const [awsAccountTimeLineRequestStatus, setAwsAccountTimeLineRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [awsAccountTimeLineDataFull, setAwsAccountTimeLineDataFull] = useState<
    MonthlyCostByNameType[]
  >([]);

  //States for Service timeline data
  const [awsServiceTimeLineData, setAwsServiceTimeLineData] = useState<
    MonthlyCostByNameType[]
  >([]);
  const [awsServiceTimeLineRequestStatus, setAwsServiceTimeLineRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  //States for Top Services
  const [awsTopServicesData, setAWSTopServicesData] = useState<
    ColouredCostByServiceType[]
  >([]);
  const [awsTopServicesDataRequestStatus, setAWSTopServicesDataRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [isTopServiceTableView, setIsTopServiceTableView] =
    useState<boolean>(false);

  //States for table view
  const [isCostAccountTimeLineTableView, setIsCostAccountTimeLineTableView] =
    useState(false);
  const [isCostServiceTimeLineTableView, setIsCostServiceTimeLineTableView] =
    useState(false);

  // State for getting accounts list status
  const [accountsListRequestStatus, setAccountsListRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    addZeroMarginClass('dashboard-view');

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    if (selectedDashboard) {
      dispatch(
        setExportToExcelData([
          getTopAccountsExcelData(
            selectedDashboard?.connectorName,
            TopAccountsTableDataSource,
            selectedAccount,
            selectedService,
            startDate,
            endDate,
            selectedAccounts
          ),
          getAwsCostTimeLineExcelData(
            selectedDashboard.connectorName,
            getTimeLineTableOrExportData(awsAccountTimeLineData),
            'account-time-line',
            startDate,
            endDate,
            selectedAccounts
          ),
          getAwsCostTimeLineExcelData(
            selectedDashboard.connectorName,
            getTimeLineTableOrExportData(awsServiceTimeLineData),
            'service-time-line',
            startDate,
            endDate,
            selectedAccounts
          ),
          getTopServicesExcelData(
            selectedDashboard?.connectorName,
            awsTopServicesData,
            startDate,
            endDate,
            selectedAccounts
          ),
        ])
      );
    }
  }, [
    selectedDashboard,
    awsTopAccountsData,
    accountWiseServiceData,
    serviceWiseResourceData,
    selectedAccount,
    selectedService,
    awsAccountTimeLineData,
    awsServiceTimeLineData,
    awsTopServicesData,
    startDate,
    endDate,
    selectedAccounts,
  ]);

  useEffect(() => {
    if (selectedDashboard?.connectorId) {
      getAwsAccountTimeLineData();
    }
  }, [selectedDashboard]);

  useEffect(() => {
    if (selectedDashboard?.connectorId && selectedAccounts.length > 0) {
      getAwsTopAccountsData();
      getAwsTopServicesData();
      getAwsServiceTimeLineData();
      getAwsAccountTimeLineData();
    } else {
      setAwsTopAccountsData([]);
      setAWSTopServicesData([]);
      setAwsServiceTimeLineData([]);
      setAwsAccountTimeLineData([]);
    }
  }, [startDate, endDate, selectedAccounts, tagsFilters]);

  useEffect(() => {
    if (selectedAccount && !accountWiseServiceData[selectedAccount]) {
      getCostByServiceForAccount();
    }
  }, [selectedAccount, tagsFilters]);

  useEffect(() => {
    if (
      selectedService &&
      !serviceWiseResourceData[`${selectedAccount}${selectedService}`]
    ) {
      getCostByResourceForService();
    }
  }, [selectedService, tagsFilters]);

  useEffect(() => {
    setIsCostServiceTimeLineTableView(tableViewEnabled);
    setIsCostAccountTimeLineTableView(tableViewEnabled);
    setIsTopAccountsTableView(tableViewEnabled);
    setIsTopServiceTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  /**
   * @function getAwsTopAccountsData
   * @description Function to fetch the cost data for top accounts
   */
  const getAwsTopAccountsData = () => {
    setAwsTopAccountsDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'account',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['account'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                startDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                endDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
    };

    if (firstRender === false) {
      const accountsFilterField = {
        field: getQueryFieldByDataSource(
          selectedDashboard!.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          selectedConnection!.focusConversionEnabled
        ),
        comparator: COMPARATORS.IN,
        value: formatArrayToStringByProvider(selectedAccounts, PROVIDER.AWS),
        conjunctToNextFilter: CONJUNCTIONS.AND,
      };
      requestBody.filterGroups[0].filters.push(accountsFilterField);
    }

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data = res?.data ?? [];
        const colors = generateGraphColors(data.length);
        setAwsTopAccountsData(
          data.map((item: any, index: number) => ({
            ...item,
            cost: Number(item.cost),
            color: colors[index],
          }))
        );

        setAwsTopAccountsDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setAwsTopAccountsDataRequestStatus);
      });
  };

  /**
   * @function getCostByServiceForAccount
   * @description Function to fetch the cost by service for selected account
   */
  const getCostByServiceForAccount = () => {
    setAwsTopAccountsDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'service',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PRODUCT_SERVICENAME,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['service'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                startDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                endDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.EQUALS,
              value: selectedAccount,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
    };
    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data = res?.data ?? [];
        const colors = generateGraphColors(data.length);
        setAccountWiseServiceData({
          ...accountWiseServiceData,
          [selectedAccount ?? '']: data.map((item: any, index: number) => ({
            ...item,
            cost: Number(item.cost),
            color: colors[index],
          })),
        });
        setAwsTopAccountsDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setAwsTopAccountsDataRequestStatus);
      });
  };

  /**
   * @function getCostByResourceForService
   * @description Function to fetch the cost by resource for selected service
   */
  const getCostByResourceForService = () => {
    setAwsTopAccountsDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'resource',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_USAGE_TYPE,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['resource'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                startDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                endDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.EQUALS,
              value: selectedAccount,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.PRODUCT_SERVICENAME,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.EQUALS,
              value: selectedService,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
    };
    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data = res?.data ?? [];
        const colors = generateGraphColors(data.length);
        setServiceWiseResourceData({
          ...serviceWiseResourceData,
          [`${selectedAccount}${selectedService}`]:
            data.map((item: any, index: number) => ({
              ...item,
              color: colors[index],
              resource: item.resource ?? 'Others',
              cost: Number(item.cost),
            })) ?? [],
        });
        setAwsTopAccountsDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setAwsTopAccountsDataRequestStatus);
      });
  };

  /**
   * @function getAwsAccountTimeLineData
   * @description Function to fetch the cost data for top accounts over time
   */
  const getAwsAccountTimeLineData = () => {
    setAwsAccountTimeLineRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'name',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            isStaticData
              ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
              : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['name', 'month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                startDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                endDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
    };

    if (firstRender === false) {
      const accountFilterField = {
        field: getQueryFieldByDataSource(
          selectedDashboard!.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          selectedConnection!.focusConversionEnabled
        ),
        comparator: COMPARATORS.IN,
        value: formatArrayToStringByProvider(selectedAccounts, PROVIDER.AWS),
        conjunctToNextFilter: CONJUNCTIONS.AND,
      };
      requestBody.filterGroups[0].filters.push(accountFilterField);
    }
    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: MonthlyCostByNameType[] = res?.data ?? [];
        const costByAccount: MonthlyCostByNameType[] = [];
        const months: string[] = getMonthYearShortList(
          moment(startDate).format(YEAR_MONTH_WITHOUT_SEPARATOR),
          moment(endDate).format(YEAR_MONTH_WITHOUT_SEPARATOR),
          YEAR_HYPHEN_MONTH
        );

        selectedAccounts.forEach((account) => {
          months.forEach((month) => {
            costByAccount.push({
              name: account,
              month: moment(month, YEAR_HYPHEN_MONTH).format(MONTH_YEAR_FORMAT),
              cost: Number(
                data.find(
                  (item: any) =>
                    item.name === account &&
                    moment(item.month).format(YEAR_HYPHEN_MONTH) === month
                )?.cost ?? 0
              ),
            });
          });
        });

        setAwsAccountTimeLineData(costByAccount);
        setAwsAccountTimeLineRequestStatus(REQUEST_STATUS.SUCCESS);
        if (firstRender === true) {
          const uniqueData = uniqBy(data, 'name');
          setAwsAccountTimeLineDataFull(uniqueData);
          setSelectedAccounts(uniqueData.map((item) => item.name));
          setAccountsListRequestStatus(REQUEST_STATUS.SUCCESS);
          setFirstRender(false);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setAwsAccountTimeLineRequestStatus);
        setAccountsListRequestStatus(REQUEST_STATUS.ERROR);
      });
  };

  /**
   * @function getAwsTopServicesData
   * @description Function to fetch the cost data for top services
   */
  const getAwsTopServicesData = () => {
    setAWSTopServicesDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'service',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PRODUCT_SERVICENAME,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['service'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                startDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                endDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
    };
    if (firstRender === false) {
      const accountsFilterField = {
        field: getQueryFieldByDataSource(
          selectedDashboard!.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          selectedConnection!.focusConversionEnabled
        ),
        comparator: COMPARATORS.IN,
        value: formatArrayToStringByProvider(selectedAccounts, PROVIDER.AWS),
        conjunctToNextFilter: CONJUNCTIONS.AND,
      };
      requestBody.filterGroups[0].filters.push(accountsFilterField);
    }

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const colors = generateGraphColors(res?.data?.length);
        setAWSTopServicesData(
          res?.data.map((item: any, index: number) => ({
            ...item,
            cost: Number(item.cost),
            color: colors[index],
          })) ?? []
        );
        setAWSTopServicesDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setAWSTopServicesDataRequestStatus);
      });
  };

  /**
   * @function getAwsServiceTimeLineData
   * @description Function to fetch the cost data for top services over time
   */
  const getAwsServiceTimeLineData = () => {
    setAwsServiceTimeLineRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'name',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PRODUCT_SERVICENAME,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            isStaticData
              ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
              : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['name', 'month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                startDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                endDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
    };

    if (firstRender === false) {
      const accountsFilterField = {
        field: getQueryFieldByDataSource(
          selectedDashboard!.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          selectedConnection!.focusConversionEnabled
        ),
        comparator: COMPARATORS.IN,
        value: formatArrayToStringByProvider(selectedAccounts, PROVIDER.AWS),
        conjunctToNextFilter: CONJUNCTIONS.AND,
      };
      requestBody.filterGroups[0].filters.push(accountsFilterField);
    }

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: MonthlyCostByNameType[] = res?.data ?? [];
        const costByService: MonthlyCostByNameType[] = [];
        const months: string[] = getMonthYearShortList(
          moment(startDate).format(YEAR_MONTH_WITHOUT_SEPARATOR),
          moment(endDate).format(YEAR_MONTH_WITHOUT_SEPARATOR),
          YEAR_HYPHEN_MONTH
        );
        const uniqueDataByService = uniqBy(data, 'name');

        uniqueDataByService.forEach((serviceData) => {
          months.forEach((month) => {
            costByService.push({
              name: serviceData.name,
              month: moment(month, YEAR_HYPHEN_MONTH).format(MONTH_YEAR_FORMAT),
              cost: Number(
                data.find(
                  (item: any) =>
                    item.name === serviceData.name &&
                    moment(item.month).format(YEAR_HYPHEN_MONTH) === month
                )?.cost ?? 0
              ),
            });
          });
        });

        setAwsServiceTimeLineData(costByService);
        setAwsServiceTimeLineRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setAwsServiceTimeLineRequestStatus);
      });
  };

  /**
   * @function getTimeLineTableOrExportData
   * @description function to get table data source grouped by name for timeline data
   * @returns object array of table data source
   */
  const getTimeLineTableOrExportData = (
    timeLineData: MonthlyCostByNameType[]
  ) => {
    const groupedByName: any[] = [];
    timeLineData.forEach((item) => {
      const existingData = groupedByName.find((obj) => obj.name === item.name);
      if (existingData) {
        existingData[
          `${item.month}-cost`
        ] = `${currencySymbol}${numberCommaSeparator(item.cost)}`;
      } else {
        groupedByName.push({
          name: item.name,
          [`${item.month}-cost`]: `${currencySymbol}${numberCommaSeparator(
            item.cost
          )}`,
          key: groupedByName.length,
          slNo: groupedByName.length,
        });
      }
    });
    return groupedByName;
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'top-accounts':
        return (
          <TopAccounts
            topAccountsData={awsTopAccountsData}
            requestStatus={[
              awsTopAccountsDataRequestStatus,
              accountsListRequestStatus,
            ]}
            startDate={startDate}
            endDate={endDate}
            selectedAccounts={selectedAccounts}
            pdfView={pdfView}
            isTableView={isTopAccountsTableView}
            setTableView={setIsTopAccountsTableView}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            accountWiseServiceData={
              accountWiseServiceData[selectedAccount] ?? []
            }
            serviceWiseResourceData={
              serviceWiseResourceData[`${selectedAccount}${selectedService}`] ??
              []
            }
            sliderValue={topAccountsSliderValue}
            setSliderValue={setTopAccountsSliderValue}
          />
        );

      case 'account-time-line':
        return (
          <CostTimeLine
            title={t('accountDeepDiveSummary.costAccountTimeLine')}
            graph="account-time-line"
            requestStatus={[
              awsAccountTimeLineRequestStatus,
              accountsListRequestStatus,
            ]}
            isTableView={isCostAccountTimeLineTableView}
            setIsTableView={setIsCostAccountTimeLineTableView}
            startDate={startDate}
            endDate={endDate}
            selectedAccounts={selectedAccounts}
            timeLineData={awsAccountTimeLineData}
            tableData={getTimeLineTableOrExportData(awsAccountTimeLineData)}
            pdfView={pdfView}
          />
        );

      case 'service-time-line':
        return (
          <CostTimeLine
            title={t('accountDeepDiveSummary.costServiceTimeLine')}
            graph="service-time-line"
            requestStatus={[
              awsServiceTimeLineRequestStatus,
              accountsListRequestStatus,
            ]}
            isTableView={isCostServiceTimeLineTableView}
            setIsTableView={setIsCostServiceTimeLineTableView}
            startDate={startDate}
            endDate={endDate}
            selectedAccounts={selectedAccounts}
            timeLineData={awsServiceTimeLineData}
            tableData={getTimeLineTableOrExportData(awsServiceTimeLineData)}
            pdfView={pdfView}
          />
        );

      case 'top-services':
        return (
          <TopServices
            data={awsTopServicesData}
            requestStatus={[
              awsTopServicesDataRequestStatus,
              accountsListRequestStatus,
            ]}
            startDate={startDate}
            endDate={endDate}
            selectedAccounts={selectedAccounts}
            pdfView={pdfView}
            isTableViewSwitch={!pdfView}
            isTableView={isTopServiceTableView}
            setTableView={setIsTopServiceTableView}
            showExpandedGraphModal={showExpandGraphModal}
          />
        );
    }
  };

  const disabledDate = (current: any) => {
    return (
      current > moment().endOf('day') ||
      current < moment().endOf('day').subtract(2, 'years')
    );
  };

  const pickerOnChange = (dates: any, _dateString: [string, string]) => {
    if (dates?.[0] && dates?.[1]) {
      setStartDate(dates[0].format(HYPHEN_DATE_FORMAT));
      setEndDate(dates[1].format(HYPHEN_DATE_FORMAT));
    } else {
      setStartDate(getPreviousMonthDayAndYear(5));
      setEndDate(getPreviousMonthDayAndYear(0));
    }
  };

  const TopAccountsTableDataSource = getTopAccountsTableDataSource(
    awsTopAccountsData,
    accountWiseServiceData[selectedAccount] ?? [],
    serviceWiseResourceData[`${selectedAccount}${selectedService}`] ?? [],
    selectedAccount,
    selectedService
  );

  return (
    <div>
      <ControlComponent
        filters={[
          {
            title: t('accountDeepDiveSummary.accounts'),
            filter: (
              <DropdownCheckbox
                itemOptions={awsAccountTimeLineDataFull.map((data) => ({
                  title: data.name ?? '',
                  value: data.name ?? '',
                }))}
                selectedItems={selectedAccounts}
                setSelectedItems={setSelectedAccounts}
                designVersion2
                size={INPUT_SIZE.SMALL}
                dataTestId="accounts-filter"
              />
            ),
            minimizedText:
              selectedAccounts
                .join(', ')
                .substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...',
            onClear: () => setSelectedAccounts([]),
          },
          {
            title: t('accountDeepDiveSummary.duration'),
            filter: (
              <DatePicker
                defaultValue={[moment(startDate), moment(endDate)]}
                className="full-width"
                onChange={pickerOnChange}
                disabledDate={disabledDate}
                format={DATE_FORMAT}
                designVersion2
                data-testid="duration-filter"
              />
            ),
            minimizedText:
              moment(startDate).format(DATE_FORMAT) +
              ' to ' +
              moment(endDate).format(DATE_FORMAT),
            onClear: () => {
              setStartDate(dateNYearsFromToday(1));
              setEndDate(moment().format(HYPHEN_DATE_FORMAT));
            },
          },
        ]}
      />
      <div className="aws-add-container inner-dashboard-content flex flex-column flex-gap-24">
        <TagFilters />
        <Row gutter={24}>
          <Col span={12}>{getGraphComponent('top-accounts')}</Col>
          <Col span={12}>{getGraphComponent('account-time-line')}</Col>
        </Row>
        <Row gutter={24} className="row-2">
          <Col span={16}>{getGraphComponent('service-time-line')}</Col>
          <Col span={8}>{getGraphComponent('top-services')}</Col>
        </Row>
      </div>
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData(
            t('dashNav.accountDeepDive'),
            selectedDashboard!,
            selectedConnection!
          )}
          pdfContent={[
            {
              element: getGraphComponent('top-accounts', true),
              graphName: 'top-accounts',
              contentType: CHART_TYPES.BAR_CHART,
              column: getTopAccountsExportColumns(
                selectedAccount,
                selectedService
              ),
              body: insertIndex(TopAccountsTableDataSource).map(
                (topAccount) => ({
                  ...topAccount,
                  cost: numberCommaSeparator(topAccount.cost),
                })
              ),
              legends: TopAccountsTableDataSource.slice(
                topAccountsSliderValue
                  ? topAccountsSliderValue.x *
                      (TopAccountsTableDataSource.length - 1)
                  : 0,
                topAccountsSliderValue
                  ? topAccountsSliderValue.y *
                      (TopAccountsTableDataSource.length - 1) +
                      1
                  : GRAPH_OPTIONS.sliderCountLimit + 1
              ).map((data: any) => ({
                name: data.account ?? data.service ?? data.resource ?? '',
                color: data.color ?? '',
              })),
              tableName: getTopAccountsTableHeading(
                selectedAccount,
                selectedService
              ),
              isTableView: isTopAccountsTableView,
            },
            {
              element: getGraphComponent('account-time-line', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'account-time-line',
              isTableView: isCostAccountTimeLineTableView,
              tableName: t('accountDeepDiveSummary.costAccountTimeLine'),
              column: getCostTimeLineExportColumns(
                'account-time-line',
                startDate,
                endDate
              ),
              body: getCostTimeLineExportColumns(
                'service-time-line',
                startDate,
                endDate
              ),
            },
            {
              element: getGraphComponent('service-time-line', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'service-time-line',
              isTableView: isCostServiceTimeLineTableView,
              tableName: t('accountDeepDiveSummary.costServiceTimeLine'),
              column: getCostTimeLineExportColumns(
                'service-time-line',
                startDate,
                endDate
              ),
              body: getTimeLineTableOrExportData(awsAccountTimeLineData),
            },
            {
              element: getGraphComponent('top-services', true),
              graphName: 'top-services',
              contentType: CHART_TYPES.PIE_CHART,
              column: TopServicesExportColumns,
              body: awsTopServicesData.map((topService, index) => ({
                ...topService,
                cost: numberCommaSeparator(topService.cost),
                index: index + 1,
              })),
              tableName: t('accountDeepDiveSummary.topServices'),
              tableFooterData: {
                totalTableData: [
                  '',
                  t('accountDeepDiveSummary.grandTotal'),
                  `$ ${numberCommaSeparator(
                    sum(awsTopServicesData.map((value) => value.cost))
                  )}`,
                ],
              },
              isTableView: isTopServiceTableView,
              legends: awsTopServicesData.map((topAccount) => ({
                name: topAccount.service ?? '',
                color: topAccount.color,
              })),
            },
          ]}
        />
      )}
    </div>
  );
};

export default AWSAccountDeepDiveDashboard;
