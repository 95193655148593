export const DASHBOARD_TYPES = {
  BILLING: 'BILLING',
  RECOMMENDATIONS: 'RECOMMENDATIONS',
  CARBON_FOOTPRINT: 'CARBON_FOOTPRINT',
  CONTAINER_USAGE: 'CONTAINER_USAGE',
  BILLING_DEFAULT: 'BILLING_DEFAULT',
  RECOMMENDATIONS_SUMMARY: 'RECOMMENDATIONS_SUMMARY',
  CUSTOM_INSIGHTS: 'CUSTOM_INSIGHTS',
  SNOWFLAKE: 'SNOWFLAKE',
  CONTAINER_INSIGHT: 'CONTAINER_INSIGHT',
  CONTAINER_USAGE_GRANULATE: 'CONTAINER_USAGE_GRANULATE',
};

export const SNOWFLAKE_TABLES = {
  WAREHOUSE_METERING_HISTORY: 'WAREHOUSE_METERING_HISTORY',
  METERING_DAILY_HISTORY: 'METERING_DAILY_HISTORY',
  STAGE_STORAGE_USAGE_HISTORY: 'STAGE_STORAGE_USAGE_HISTORY',
  STORAGE_DAILY_HISTORY: 'STORAGE_DAILY_HISTORY',
  DATABASE_STORAGE_USAGE_HISTORY: 'DATABASE_STORAGE_USAGE_HISTORY',
};

export const QUERY_FIELDS = {
  COST_PLUS_CREDIT_AMOUNT: 'cost+credit_amount',
  LINE_ITEM_UNBLENDED_COST: 'line_item_unblended_cost',
  SUM_LINE_ITEM_UNBLENDED_COST: 'SUM(line_item_unblended_cost)',
  LINE_ITEM_LINE_ITEM_TYPE: 'line_item_line_item_type',
  DATE_FORMAT_BILLING_PERIOD:
    "date_format(bill_billing_period_start_date, '%Y-%m')",
  FORMAT_TIMESTAMP_BILLING_PERIOD:
    "FORMAT_TIMESTAMP('%Y-%m', bill_billing_period_start_date)",
  LINE_ITEM_USAGE_START_DATE: 'line_item_usage_start_date',
  LINE_ITEM_USAGE_END_DATE: 'line_item_usage_end_date',
  DATE_LINE_ITEM_USAGE_START_DATE: 'DATE(line_item_usage_start_date)',
  PRODUCT_SERVICENAME: 'product_servicename',
  PRODUCT_REGION: 'product_region',
  LINE_ITEM_USAGE_TYPE: 'line_item_usage_type',
  INVOICE_MONTH: 'invoice.month',
  PROJECT_NAME: 'project.name',
  SERVICE_DESCRIPTION: 'service.description',
  SKU_DESCRIPTION: 'sku.description',
  UNITS_PLUS_NANOS: '(units+nanos/1000000000)*-1',
  NANOS: '(nanos/1000000000)*-1',
  RECOMMENDER_SUBTYPE: 'recommender_subtype',
  COUNT_ALL: 'COUNT(name)',
  CATEGORY: 'category',
  CLOUD_ENTITY_ID: 'cloud_entity_id',
  PRIORITY: 'priority',
  DESCRIPTION: 'description',
  DURATION_SECONDS_60_60_24: 'durationSeconds/60/60/24',
  LAST_REFRESH_TIME: 'last_refresh_time',
  COST: 'cost',
  CREDIT_AMOUNT: 'credit_amount*-1',
  COST_METRICS_TYPE: 'cost_metric_type',
  LOCATION_REGION: 'location.region',
  USAGE_START_TIME: 'usage_start_time',
  USAGE_END_TIME: 'usage_end_time',
  CARBON_FOOTPRINT_KGCO2E: 'carbon_footprint_total_kgCO2e.location_based',
  USAGE_MONTH: 'usage_month',
  PROJECT_ID: 'project.id',
  BILLING_ACCOUNT_ID: 'billing_account_id',
  ACCOUNT_ID: 'account_id',
  MONTH: 'month',
  SERVICE: 'service',
  REGION: 'region',
  BILL_PAYER_ACCOUNT_ID: 'bill_payer_account_id',
  LOCATION_ZONE: 'location.zone',
  PRODUCT_REGION_CODE: 'product_region_code',
  CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE:
    'CONCAT(YEAR(BillingPeriodStartDate),MONTH(BillingPeriodStartDate))',
  CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE:
    "CONCAT(EXTRACT(YEAR from TIMESTAMP(COALESCE(SAFE.PARSE_DATE('%Y-%m-%d', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%m-%d-%Y', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%Y/%m/%d', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%m/%d/%Y', TRIM(BillingPeriodStartDate))))),EXTRACT(MONTH from TIMESTAMP(COALESCE(SAFE.PARSE_DATE('%Y-%m-%d', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%m-%d-%Y', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%Y/%m/%d', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%m/%d/%Y', TRIM(BillingPeriodStartDate))))))",
  COST_BILLING_CURRENCY: 'CostInBillingCurrency',
  BILLING_PERIOD_START_DATE: 'BillingPeriodStartDate',
  BILLING_PERIOD_END_DATE: 'BillingPeriodEndDate',
  RESOURCE_LOCATION: 'ResourceLocation',
  CONCAT_METER_CATEGORY: "CONCAT(MeterCategory,' ',MeterSubCategory)",
  METER_CATEGORY: 'MeterCategory',
  RESOURCE_GROUP: 'ResourceGroup',
  RESOURCE_NAME: 'ResourceName',
  RESOURCE_LABEL_STATIC: 'resource_labels',
  PROJECT_LABEL_CUSTOM: 'project.labels',
  TAGS: 'Tags',
  TAGS_DATA: 'TAGS_DATA',
  SEQUENCE_NUMBER: 'seqnum',
  RECOMMENDER: 'recommender',
  NAME: 'name',
  STATE: 'state',
  ACTIVE: "'ACTIVE'",
  PRIMARY_IMPACT_CATEGORY: 'primary_impact.category',
  COST_MY_COST: 'COST_MY_COST',
  LTRIM_TO_CHAR_USAGE_INTERVAL_END:
    "ltrim(TO_CHAR(USAGE_INTERVAL_END,'yyyymm'),'0')",
  LTRIM_TO_CHAR_USAGE_INTERVAL_END_YYYY_MM_DD:
    "ltrim(TO_CHAR(USAGE_INTERVAL_END,'yyyy-mm-dd'),'0')",
  PRD_COMPARTMENT_NAME: 'PRD_COMPARTMENT_NAME',
  PRD_SERVICE: 'PRD_SERVICE',
  PRD_REGION: 'PRD_REGION',
  TARGET_RESOURCES: 'TO_JSON_STRING(target_resources)',
  SUM_UNITS_PLUS_NANOS: 'sum(IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1',
  PRODUCT_NAME: 'ProductName',
  SUBSCRIPTION_NAME: 'SubscriptionName',
  BILLING_PERIOD_START_DATE_LOWERCASE: 'bill_billing_period_start_date',
  DATE_FORMAT_BILL_BILLING_PERIOD_START_DATE_YEAR_MONTH:
    "DATE_FORMAT(bill_billing_period_start_date, '%Y%m')",
  NAMESPACE: 'namespace',
  CLOUD_RESOURCE_SIZE: 'cloud_resource_size',
  START_TIME: 'start_time',
  END_TIME: 'end_time',
  CLUSTER_NAME: 'cluster_name',
  GCP_RESOURCE_NAME: 'resource_name',
  FRACTION: 'fraction',
  EXTRACT_DATE_FROM_START_TIME: 'EXTRACT(DATE FROM start_time)',
  EXTRACT_WEEK_FROM_START_TIME:
    'CONCAT(EXTRACT(YEAR FROM start_time),EXTRACT(WEEK FROM start_time))',
  EXTRACT_MONTH_FROM_START_TIME:
    'CONCAT(EXTRACT(YEAR FROM start_time),EXTRACT(MONTH FROM start_time))',
  EXTRACT_QUARTER_FROM_START_TIME:
    'CONCAT(EXTRACT(YEAR FROM start_time),EXTRACT(QUARTER FROM start_time))',
  EXTRACT_YEAR_FROM_START_TIME: 'EXTRACT(YEAR FROM start_time)',
  EXTRACT_HOUR_FROM_END_TIME: "CONCAT(EXTRACT(HOUR FROM end_time),':00:00')",
  CONCAT_EXTRACT_HOUR_FROM_START_TIME:
    "CONCAT(EXTRACT(HOUR FROM start_time),':00:00')",
  CONCAT_EXTRACT_HOUR_FROM_USAGE_START_TIME:
    "CONCAT(EXTRACT(HOUR FROM usage_start_time),':00:00')",
  EXTRACT_DATE_FROM_USAGE_START_TIME: 'EXTRACT(DATE FROM usage_start_time)',
  CONCAT_EXTRACT_YEAR_WEEK_FROM_USAGE_START_TIME:
    'CONCAT(EXTRACT(YEAR FROM usage_start_time),EXTRACT(WEEK FROM usage_start_time))',
  CONCAT_EXTRACT_YEAR_MONTH_FROM_USAGE_START_TIME:
    'CONCAT(EXTRACT(YEAR FROM usage_start_time),EXTRACT(MONTH FROM usage_start_time))',
  CONCAT_EXTRACT_YEAR_QUARTER_FROM_USAGE_START_TIME:
    'CONCAT(EXTRACT(YEAR FROM usage_start_time),EXTRACT(QUARTER FROM usage_start_time))',
  EXTRACT_YEAR_FROM_USAGE_START_TIME: 'EXTRACT(YEAR FROM usage_start_time)',
  LABELS: 'labels',
  CLUSTER_LABELS_VALUE: 'cluster_labels.value',
  CLUSTER_NAMESPACE_LABELS_VALUE: 'cluster_namespace_labels.value',
  CLUSTER_NAMESPACE_LABELS_KEY: 'cluster_namespace_labels.key',
  CLUSTER_LABELS_KEY: 'cluster_labels.key',
  LABELS_VALUE: 'labels.value',
  LABELS_KEY: 'labels.key',
  FRACTIONX100: 'fraction*100',
  FRACTIONX100BY24: '(fraction*100)/24',
  FRACTIONX100BY24BY7: '(fraction*100)/24/7',
  FRACTIONX100BY24BY30: '(fraction*100)/24/30',
  FRACTIONX100BY24BY30BY3: '(fraction*100)/24/30/3',
  NAMESPACE_COST_AFTER_CREDIT:
    'cost + IFNULL((SELECT SUM(c.amount) FROM UNNEST(credits) c), 0)',
  USAGE_AMOUNT: 'usage.amount/(1e+12)',
  DISTINCT_CHECKID: 'DISTINCT checkId',
  CHECKNAME: 'checkName',
  INSTANCE_ID: 'instance id',
  INSTANCE_TYPE: 'instance type',
  INSTANCE_COUNT: 'instance count',
  RESERVED_INSTANCE_ID: 'reserved instance id',
  ACCOUNTID: 'accountid',
  CUSTOM_RECOM_STATUS: 'customrecomstatus',
  DATETIME: 'datetime',
  ESTIMATED_MONTHLY_SAVINGS: 'estimated monthly savings',
  MONTHLY_STORAGE_COST: 'monthly storage cost',
  CURRENT_MONTHLY_STORAGE_COST: 'current monthly storage cost',
  IP_ADDRESS: 'ip address',
  CLUSTER: 'cluster',
  REASON: 'reason',
  DB_INSTANCE_NAME: 'db instance name',
  DAYS_SINCE_LAST_CONNECTION: 'days since last connection',
  LOAD_BALANCER_NAME: 'load balancer name',
  VOLUME_ID: 'volume id',
  VOLUME_NAME: 'volume name',
  VOLUME_SIZE: 'volume size',
  VOLUME_TYPE: 'volume type',
  ZONE: 'zone',
  EXPIRATION_DATE: 'expiration date',
  HOSTED_ZONE_NAME: 'hosted zone name',
  HOSTED_ZONE_ID: 'hosted zone id',
  RESOURCE_RECORD_SET_NAME: 'resource record set name',
  RESOURCE_RECORD_SET_TYPE: 'resource record set type',
  COST_ALL_CAPS: 'COST',
  CONCAT_YEAR_MONTH_FROM_LAST_REFRESH_TIME:
    'CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time))',
  EXTRACT_DATE_FROM_LAST_REFRESH_TIME: 'EXTRACT(DATE FROM last_refresh_time)',
  REPLACE_ESTIMATED_MONTHLY_SAVINGS_DOLLAR_BY_EMPTY:
    "CAST(REPLACE(REPLACE(\"estimated monthly savings\",'$',''),',','') AS DECIMAL(10,2))",
  REPLACE_MONTHLY_STORAGE_COST_DOLLAR_BY_EMPTY:
    "CAST(REPLACE(REPLACE(\"monthly storage cost\",'$',''),',','') AS DECIMAL(10,2))",
  ROW_NUM: 'rownum',
  DATE_PARSE_DATE_TIME: "date_parse(datetime,'%Y-%m-%d %H:%i:%s')",
  DATE_PARSE_GENERATION_TIME: "date_parse(generationtime,'%Y-%m-%dT%H:%i:%sZ')",
  COUNT_ALLX3_6: 'count(*)*3.6',
  BILL_BILL_TYPE: 'bill_bill_type',
  FORMAT_DATE_TIME_YYYYMM:
    "format_datetime(cast(date_parse(datetime,'%Y-%m-%d %H:%i:%s') as date),'YYYYMM')",
  FORMAT_GENERATION_TIME_YYYYMM:
    "format_datetime(cast(date_parse(generationtime,'%Y-%m-%dT%H:%i:%sZ') as date),'YYYYMM')",
  FOURTEEN_DAYS_AVERAGE_CPU_UTILIZATION: '14-day average cpu utilization',
  FOURTEEN_DAYS_AVERAGE_NETWORK_IO: '14-day average network i/o',
  NUMBER_OF_DAYS_LOW_UTILIZATION: 'number of days low utilization',
  STORAGE_PROVISIONED: 'storage provisioned',
  USER_STATUS_ACTIVE: 'ACTIVE',
  NEW_USER_ROLE: 'VIEWER',
  SUBTYPE: 'subtype',
  INSIGHT: 'insight',
  DISTINCT_RECOMMENDATION_NAME: 'DISTINCT(recommendationName)',
  DATASOURCE_TYPE_CONNECTION: 'CONNECTION',
  DATASOURCE_TYPE_GROUP: 'GROUP',
  DATASOURCE_TYPE_STATIC_FILE_IMPORT: 'STATIC_FILE_IMPORT',
  FUNCTION_ARN: 'function arn',
  FUNCTION_NAME: 'function name',
  ENDPOINT_ARN: 'endpoint arn',
  WORKLOAD_ARN: 'workload arn',
  ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS:
    'Estimated Savings with Recommendations',
  ESTIMATED_SAVINGS_PERCENTAGE: 'estimated savings percentage',
  ACCOUNT_NAME: 'accountname',
  PROVISIONED_INFERENCE_UNIT: 'provisioned inference unit',
  AUTOSCALING_STATUS: 'autoscaling status',
  LAST_UPDATED_TIME: 'last updated time',
  VOLUME_SIZE_GB: 'volume size (gb)',
  VOLUME_BASELINE_IOPS: 'volume baseline iops',
  VOLUME_BURST_IOPS: 'volume burst iops',
  VOLUME_BURST_THROUGHPUT: 'volume burst throughput',
  RECOMMENDED_VOLUME_TYPE: 'recommended volume type',
  RECOMMENDED_VOLUME_SIZE_GB: 'recommended volume size (gb)',
  RECOMMENDED_VOLUME_BASELINE_IOPS: 'recommended volume baseline iops',
  RECOMMENDED_VOLUME_BURST_IOPS: 'recommended volume burst iops',
  RECOMMENDED_VOLUME_BASELINE_THROUGHPUT:
    'recommended volume baseline throughput',
  RECOMMENDED_VOLUME_BURST_THROUGHPUT: 'recommended volume burst throughput',
  LOOKBACK_PERIOD_DAYS: 'lookback period (days)',
  SAVINGS_OPPORTUNITY_PERCENTAGE: 'savings opportunity (%)',
  ESTIMATED_MONTHLY_SAVINGS_CURRENCY: 'estimated monthly savings currency',
  VCPU: 'vcpu',
  MINIMUM_VCPU: 'minimum vcpu',
  SQL_SERVER_EDITION: 'sql server edition',
  MAXIMUM_VCPU: 'maximum vcpu',
  RECOMMENDED_INSTANCE_TYPE: 'recommended instance type',
  PLATFORM: 'platform',
  CURRENT_MONTHLY_COST: 'current monthly cost',
  RECOMMENDED_NUMBER_OF_RIS_TO_PURCHASE:
    'recommended number of ris to purchase',
  EXPECTED_AVERAGE_RI_UTILIZATION: 'expected average ri utilization',
  UPFRONT_COST_OF_RIS: 'upfront cost of ris',
  ESTIMATED_COST_OF_RIS: 'estimated costs of ris',
  ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RI_PURCHASE:
    'estimated on-demand cost post recommended ri purchase',
  ESTIMATED_BREAK_EVEN: 'estimated break even',
  LOOKBACK_PERIOD: 'lookback period',
  TERM: 'term',
  FAMILY: 'family',
  NODE_TYPE: 'node type',
  PRODUCT_DESCRIPTION: 'product description',
  RECOMMENDED_NUMBER_OF_RESERVED_NODES_TO_PURCHASE:
    'recommended number of reserved nodes to purchase',
  EXPECTED_AVERAGE_RESERVED_NODE_UTILIZARION:
    'expected average reserved node utilization',
  UPFRONT_COST_OF_RESERVED_NODES: 'upfront cost of reserved nodes',
  ESTIMATED_COST_OF_RESERVED_NODES: 'estimated cost of reserved nodes',
  ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVED_NODES_PURCHASE:
    'estimated on-demand cost post recommended reserved nodes purchase',
  INSTANCE_CLASS: 'instance class',
  INSTANCE_SIZE: 'instance size',
  RECOMMENDED_NUMBER_OF_RESERVED_INSTANCES_TO_PURCHASE:
    'recommended number of reserved instances to purchase',
  EXPECTED_AVERAGE_RESERVED_INSTANCES_TO_PURCHASE:
    'expected average reserved instance utilization',
  UPFRONT_COST_OF_RESERVED_INSATNCES: 'upfront cost of reserved instances',
  ESTIMATED_COST_OF_RESERVED_INSTANCES: 'estimated cost of reserved instances',
  ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVED_INSTANCE_PURCHASE:
    'estimated on-demand cost post recommended reserved instance purchase',
  MULTI_AZ: 'multi-az',
  LICENCE_MODEL: 'licence model',
  DATABASE_EDITION: 'database edition',
  DATABASE_ENGINE: 'database engine',
  DEPLOYMENT_OPTION: 'deployment option',
  ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVE_INSTANCE_PURCHASE_MONTHLY:
    'estimated on-demand cost post recommended reserve instance purchase (monthly)',
  STATUS: 'status',
  MAX_DAILY_TIMEOUT_RATE: 'max daily timeout rate',
  DATE_OF_MAX_DAILY_TIMEOUT_RATE: 'date of max daily timeout rate',
  AVERAGE_DAILY_TIMEOUT_RATE: 'average daily timeout rate',
  FUNCTION_TIMEOUT_SETTINGS: 'function timeout settings',
  LOST_DAILY_COMPUTE_COST: 'lost daily compute cost',
  AVERAGE_DAILY_INVOKES: 'average daily invokes',
  CURRENT_DAY_INVOKES: 'current day invokes',
  CURRENT_DAY_TIMEOUT_RATE: 'current day timeout rate',
  MAX_DAILY_ERROR_RATE: 'max daily error rate',
  DATE_FOR_MAX_ERROR_RATE: 'date for max error rate',
  AVERAGE_DAILY_ERROR_RATE: 'average daily error rate',
  CURRENT_DAY_ERROR_RATE: 'current day error rate',
  FUNCTION_VERSION: 'function version',
  MEMORY_SIZE: 'memory size',
  RECOMMENDED_MEMORY_SIZE: 'recommended memory size',
  SAVINGS_OPPORTUNITY: 'savings opportunity',
  WORKLOAD_NAME: 'workload name',
  REVIEWER_NAME: 'reviewer name',
  WORKLOAD_TYPE: 'workload type',
  WORKLOAD_STARTED_DATE: 'workload started date',
  WORKLOAD_LAST_MODIFIED_DATE: 'workload last modified date',
  NUMBER_OF_IDENTIFIED_HRIS_FOR_COST_OPTIMIZATION:
    'number of identified hris for cost optimization',
  NUMBER_OF_HRIS_RESOLVED_FOR_COST_OPTIMIZATION:
    'number of hris resolved for cost optimization',
  NUMBER_OF_QUESTIONS_ANSWERED_FOR_COST_OPTIMIZATION:
    'number of questions answered for cost optimization',
  TOTAL_NUMBER_OF_QUESTIONS_IN_COST_OPTIMIZATION_PILLAR:
    'total number of questions in cost optimization pillar',
  INSTANCE_NAME: 'instance name',
  SAVINGS_PLAN_TYPE: 'savings plan type',
  PAYMENT_OPTION: 'payment option',
  UPFRONT_COST: 'upfront cost',
  HOURLY_COMMITMENT_TO_PURCHASE: 'hourly commitment to purchase',
  ESTIMATED_AVERAGE_UTILIZATION: 'estimated average utilization',
  SNAPSHOT_ID: 'snapshot id',
  SNAPSHOT_NAME: 'snapshot name',
  SNAPSHOT_AGE: 'snapshot age',
  INSTANCE_ID_NO_SPACE: 'instanceid',
  INSTANCE_NAME_NO_SPACE: 'instancename',
  INSTANCE_TYPE_NO_SPACE: 'instancetype',
  GENERATION_TIME: 'generationtime',
  CPU_UTILIZATION: 'cpu utilization',
  RECOMMENDED_INSTANCE_TYPE_NO_SPACE: 'recommendedinstancetype',
  ESTIMATED_SAVINGS: 'estimated savings',
  SUM_COST_SAVINGS: 'sum(costSavings)',
  CONCAT_EXTRACT_YEAR_LAST_REFRESHED:
    'CONCAT(EXTRACT(YEAR FROM lastRefreshed),EXTRACT(MONTH FROM lastRefreshed))',
  BILLING_RESOURCE_NAME: 'resource.name',
  STATUS_PRIORITY: 'status_priority',
  PROPERTIES_SHORTDESCRIPTION_PROBLEM: 'properties_shortDescription_problem',
  PROPERTIES_LAST_UPDATED: 'properties_lastUpdated',
  PROPERTIES_SAVINGS: 'properties_extendedProperties_savingsAmount',
  TYPE: 'type',
  PROPERTIES_IMPACTED_FIELD: 'properties_impactedField',
  PROPERTIES_IMPACTED_VALUE: 'properties_impactedValue',
  PROPERTIES_CATEGORY: 'properties_category',
  EXTENDED_PROPERTIES_SAVINGS_AMOUNT_INTEGER:
    'SUM(CAST(properties_extendedProperties_savingsAmount as Integer))',
  EXTENDED_PROPERTIES_SAVINGS_AMOUNT_FLOAT:
    'SUM(CAST(properties_extendedProperties_savingsAmount as FLOAT))',
  EXTENDED_PROPERTIES_SAVINGS_AMOUNT_FLOAT_64:
    'SUM(CAST(properties_extendedProperties_savingsAmount as FLOAT64))',
  DATE_UPLOADED_TIMESTAMP: 'date_uploaded_timestamp',
  LAST_DAY_OF_MONTH_VALUE_DESC: 'ROW_NUMBER() OVER (ORDER BY (LastDayofMonth))',
  LAST_DAY_OF_MONTH: 'LastDayofMonth',
  DATE_UPLOADED_TIMESTAMP_DATE:
    'CAST(SUBSTRING(date_uploaded_timestamp, 1, 10) AS DATE)',
  EOMONTH_DATE_UPLOADED_TIMESTAMP:
    '(EOMONTH(CAST(SUBSTRING(date_uploaded_timestamp, 1, 10) AS DATE)))',
  EOMONTH_CAST_DATE_UPLOADED_TIMESTAMP:
    'EOMONTH(CAST(SUBSTRING(date_uploaded_timestamp, 1, 10) AS DATE))',
  PROPERTIES_RESOURCE_METADATA_RESOURCE_ID:
    'properties_resourceMetadata_resourceId',
  AZURE_RECOM_ID: 'id',
  CAST_PROPERTIES_SAVINGS_FLOAT:
    'CAST(properties_extendedProperties_savingsAmount AS FLOAT)',
  YEAR_DATE: 'YEAR(Date)',
  MONTH_DATE: 'MONTH(Date)',
  SUM_COST_IN_BILLING_CURRENCY: 'SUM(CostInBillingCurrency)',
  TAG: 'tag',
  TAG_KEY: 'tag.key',
  TAG_VALUE: 'tag.value',
  LINE_ITEM_USAGE_ACCOUNT_ID: 'line_item_usage_account_id',
  SUM_IF_NULL_UNITS_NANOS:
    'IF((IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1=-0,0,(IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1)',
  COUNT_DISTINCT_ACCOUNT_NAME: 'COUNT(DISTINCT ACCOUNT_NAME)',
  COUNT_DISTINCT_WAREHOUSE_ID: 'COUNT(DISTINCT WAREHOUSE_ID)',
  CREDITS_USED: 'CREDITS_USED',
  START_TIME_CAPS: 'START_TIME',
  END_TIME_CAPS: 'END_TIME',
  CREDITS_USED_COMPUTE: 'CREDITS_USED_COMPUTE',
  USAGE: 'USAGE',
  CONCAT_YEAR_MONTH_USAGE_DATE: 'CONCAT(YEAR(USAGE_DATE),MONTH(USAGE_DATE))',
  USAGE_DATE: 'USAGE_DATE',
  CONCAT_YEAR_MONTH_START_TIME: 'CONCAT(YEAR(START_TIME),MONTH(START_TIME))',
  WAREHOUSE_NAME: 'WAREHOUSE_NAME',
  ACCOUNT_NAME_CAPS: 'ACCOUNT_NAME',
  AVG_STAGE_BYTES: 'AVERAGE_STAGE_BYTES',
  CREDITS: 'CREDITS',
  AVERAGE_DATABASE_BYTES: 'AVERAGE_DATABASE_BYTES',
  DATABASE_NAME: 'DATABASE_NAME',
  AVERAGE_FAILSAFE_BYTES: 'AVERAGE_FAILSAFE_BYTES',
  CLOUD_RESOURCE_SIZE_PERCENTAGE: '(cloud_resource_size*fraction*100)/24/30',
  POD_LABELS_VALUE: 'pod_labels.value',
  POD_HASH_LABELS_VALUE: 'pod_hash_labels.value',
  CLOUD_RESOURCE_SIZE_PERCENTAGE_1024:
    '((cloud_resource_size*fraction*100)/(1024*1024))/24/30',
  CLUSTER_NAME_WITHOUT_SPACE: 'clustername',
  POD_CPU_LIMIT: 'pod_cpu_limit',
  POD_CPU_REQUEST: 'pod_cpu_request',
  POD_CPU_RESERVED_CAPACITY: 'pod_cpu_reserved_capacity',
  POD_CPU_USAGE_SYSTEM: 'pod_cpu_usage_system',
  POD_CPU_UTILIZATION: 'pod_cpu_utilization',
  POD_CPU_UTILIZATION_OVER_POD_LIMIT: 'pod_cpu_utilization_over_pod_limit',
  FROM_UNIXTIME_CAST_BIGINT:
    'from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))',
  POD_MEMORY_CACHE: 'pod_memory_cache',
  POD_MEMORY_LIMIT: 'pod_memory_limit',
  POD_MEMORY_MAX_USAGE: 'pod_memory_max_usage',
  POD_MEMORY_REQUEST: 'pod_memory_request',
  POD_MEMORY_RESERVED_CAPACITY: 'pod_memory_reserved_capacity',
  POD_MEMORY_USAGE: 'pod_memory_usage',
  POD_MEMORY_UTILIZATION: 'pod_memory_utilization',
  POD_MEMORY_UTILIZATION_OVER_POD_LIMIT:
    'pod_memory_utilization_over_pod_limit',
  POD_NETWORK_RX_BYTES: 'pod_network_rx_bytes',
  POD_NETWORK_TX_BYTES: 'pod_network_tx_bytes',
  POD_NETWORK_TOTAL_BYTES: 'pod_network_total_bytes',
  NODE_FILESYSTEM_AVAILABLE: 'node_filesystem_available',
  NODE_FILESYSTEM_CAPACITY: 'node_filesystem_capacity',
  NODE_FILESYSTEM_USAGE: 'node_filesystem_usage',
  NODE_FILESYSTEM_UTILIZATION: 'node_filesystem_utilization',
  NODE_NAME: 'nodename',
  CONTAINER_CPU_USAGE_SYSTEM: 'container_cpu_usage_system',
  CONTAINER_CPU_USAGE_TOTAL: 'container_cpu_usage_total',
  CONTAINER_CPU_USAGE_USER: 'container_cpu_usage_user',
  CONTAINER_CPU_UTILIZATION: 'container_cpu_utilization',
  CONTAINER_MEMORY_USAGE: 'container_memory_usage',
  CONTAINER_MEMORY_UTILIZATION: 'container_memory_utilization',
  CONTAINER_MEMORY_MAX_USAGE: 'container_memory_max_usage',
  POD_COUNT_LABELS_VALUE: 'pod_count_labels.value',
  PRICING_TERM: 'pricing_term',
  LINE_ITEM_PRODUCT_CODE: 'line_item_product_code',
  PRODUCT_OPERATING_SYSTEM: 'product_operating_system',
  PRODUCT_INSTANCE_TYPE_FAMILY: 'product_instance_type_family',
  LINE_ITEM_RESOURCE_ID: 'line_item_resource_id',
  PRODUCT_OPERATION: 'product_operation',
  LINE_ITEM_OPERATION: 'line_item_operation',
  PRODUCT_PRODUCT_NAME: 'product_product_name',
  PRODUCT_DATABASE_ENGINE: 'product_database_engine',
  SUM_CAST_LINE_ITEM_USAGE_AMOUNT_AS_DOUBLE:
    'SUM(CAST(line_item_usage_amount AS DOUBLE))',
  SUM_CAST_LINE_ITEM_USAGE_AMOUNT_AS_FLOAT_64:
    'SUM(CAST(line_item_usage_amount AS FLOAT64))',
  EBS_VOLUME_COVERAGE_SPLIT_PART_COLUMN:
    "(CASE SPLIT_PART(line_item_usage_type,':',2) WHEN 'VolumeUsage' THEN 'EBS - Magnetic' WHEN 'VolumeIOUsage' THEN 'EBS Magnetic IO' WHEN 'VolumeUsage.gp2' THEN 'EBS GP2' WHEN 'VolumeUsage.gp3' THEN 'EBS GP3' WHEN 'VolumeP-IOPS.gp3' THEN 'EBS GP3 IOPS' WHEN 'VolumeP-Throughput.gp3' THEN 'EBS GP3 Throughput' WHEN 'VolumeUsage.piops' THEN 'EBS io1' WHEN 'VolumeP-IOPS.piops' THEN 'EBS io1 IOPS' WHEN 'VolumeUsage.io2' THEN 'EBS io2' WHEN 'VolumeP-IOPS.io2' THEN 'EBS io2 IOPS' WHEN 'VolumeUsage.st1' THEN 'EBS st1' WHEN 'VolumeUsage.sc1' THEN 'EBS sc1' WHEN 'directAPI' THEN 'EBS Direct API Requests' WHEN 'FastSnapshotRestore' THEN 'EBS Fast Snapshot Restore' ELSE SPLIT_PART(line_item_usage_type,':',2) END)",
  EBS_VOLUME_COVERAGE_SPLIT_COLUMN:
    "(CASE SPLIT(line_item_usage_type,':')[SAFE_OFFSET(1)] WHEN 'VolumeUsage' THEN 'EBS - Magnetic' WHEN 'VolumeIOUsage' THEN 'EBS Magnetic IO' WHEN 'VolumeUsage.gp2' THEN 'EBS GP2' WHEN 'VolumeUsage.gp3' THEN 'EBS GP3' WHEN 'VolumeP-IOPS.gp3' THEN 'EBS GP3 IOPS' WHEN 'VolumeP-Throughput.gp3' THEN 'EBS GP3 Throughput' WHEN 'VolumeUsage.piops' THEN 'EBS io1' WHEN 'VolumeP-IOPS.piops' THEN 'EBS io1 IOPS' WHEN 'VolumeUsage.io2' THEN 'EBS io2' WHEN 'VolumeP-IOPS.io2' THEN 'EBS io2 IOPS' WHEN 'VolumeUsage.st1' THEN 'EBS st1' WHEN 'VolumeUsage.sc1' THEN 'EBS sc1' WHEN 'directAPI' THEN 'EBS Direct API Requests' WHEN 'FastSnapshotRestore' THEN 'EBS Fast Snapshot Restore' ELSE SPLIT(line_item_usage_type,':')[SAFE_OFFSET(1)] END)",
  PRICING_UNIT: 'pricing_unit',
  PRODUCT_PRODUCT_FAMILY: 'product_product_family',
  SUM_CAST_DECIMAL_LINE_ITEM_UNBLENDED_COST:
    'SUM(CAST(line_item_unblended_cost AS DECIMAL(16,8)))',
  SUM_CAST_FLOAT_64_LINE_ITEM_UNBLENDED_COST:
    'SUM(CAST(line_item_unblended_cost AS FLOAT64))',
  CREDITS_BILLED: 'CREDITS_BILLED',
  SERVICE_TYPE: 'SERVICE_TYPE',
  SELECT_STRUCT_PROJECT_LABELS:
    'ARRAY(SELECT AS STRUCT * FROM UNNEST(project.labels))',
  CONSUMED_SERVICE: 'ConsumedService',
  RESOURCE_ID: 'ResourceId',
  DATE: 'Date',
  RESOURCE_GLOBAL_NAME: 'resource.global_name',
  SUM_SAVINGS_PLAN_USED_COMMITMENT: 'SUM(savings_plan_used_commitment)',
  CAST_SUM_SAVINGS_PLAN_USED_COMMITMENT:
    '(CAST(SUM(IFNULL(CAST(savings_plan_used_commitment AS NUMERIC), 0)) AS NUMERIC)',
  SUM_SAVINGS_PLAN_TOTAL_COMMITMENT_TO_DATE:
    'SUM(savings_plan_total_commitment_to_date)',
  CAST_SUM_SAVINGS_PLAN_TOTAL_COMMITMENT_TO_DATE:
    'CAST(SUM(IFNULL(CAST(savings_plan_total_commitment_to_date AS NUMERIC), 1)) AS NUMERIC))',
  STRING_AGG_DISTINCT_TAG_KEY: "STRING_AGG(DISTINCT tag.key, ',')",
  COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE:
    "DATE(COALESCE(SAFE.PARSE_DATE('%Y-%m-%d', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%m-%d-%Y', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%Y/%m/%d', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%m/%d/%Y', TRIM(BillingPeriodStartDate))))",
  COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_END_DATE:
    "DATE(COALESCE(SAFE.PARSE_DATE('%Y-%m-%d', TRIM(BillingPeriodEndDate)),SAFE.PARSE_DATE('%m-%d-%Y', TRIM(BillingPeriodEndDate)),SAFE.PARSE_DATE('%Y/%m/%d', TRIM(BillingPeriodEndDate)),SAFE.PARSE_DATE('%m/%d/%Y', TRIM(BillingPeriodEndDate))))",
  PRICING_MODEL: 'PricingModel',
  CONVERT_DATETIME_DATE: 'convert(datetime, Date)',
  COALESCE_SAFE_PARSE_DATE:
    "DATE(COALESCE(SAFE.PARSE_DATE('%Y-%m-%d', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%m-%d-%Y', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%Y/%m/%d', TRIM(BillingPeriodStartDate)),SAFE.PARSE_DATE('%m/%d/%Y', TRIM(BillingPeriodStartDate))))",
  METER_NAME: 'MeterName',
  CONCAT_DATEPART_YEAR_MONTH_PROPERTIES_LAST_UPDATED:
    'CONCAT(DATEPART(YEAR, properties_lastUpdated), DATEPART(MONTH, properties_lastUpdated))',
  CONCAT_EXTRACT_YEAR_MONTH_PROPERTIES_LAST_UPDATED:
    'CONCAT(EXTRACT(YEAR FROM properties_lastUpdated), EXTRACT(MONTH FROM properties_lastUpdated))',
  CONVERT_DATETIME_PROPERTIES_LAST_UPDATED:
    'CONVERT(datetime, LEFT(properties_lastUpdated, 23), 126)',
  METER_SUB_CATEGORY: 'MeterSubCategory',
  ADDITIONAL_INFO_SERVICE_TYPE: "JSON_VALUE(AdditionalInfo, '$.ServiceType')",
  PRD_RESOURCE: 'PRD_RESOURCE',
  COST_TYPE: 'cost_type',
  USAGE_UNIT: 'usage.unit',
  LOCATION_LOCATION: 'location.location',
  SUM_MONTHLY_STOARGE_COST:
    "SUM(CAST(regexp_replace(\"monthly storage cost\", '\\$', '') AS REAL))",
  SUM_ESTIMATED_MONTHLY_SAVINGS:
    "SUM(CAST(regexp_replace(\"estimated monthly savings\", '\\$', '') AS REAL))",
  COUNT_ALL_MULTIPLY_3_2: 'count(*)*3.2',
  SUM_ESTIMATED_SAVINGS: 'SUM("estimated savings")',
  SUM_ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS:
    "SUM(CAST(regexp_replace(\"Estimated Savings with Recommendations\", '\\$', '') AS REAL))",
  DATE_FORMAT_DATE_PARSE_DATETIME_YEAR_MONTH:
    "date_format(date_parse(datetime,'%Y-%m-%d %H:%i:%s'),'%Y-%m')",
  DATE_FORMAT_DATE_PARSE_DATETIME_YEAR_MONTH_DAY:
    "date_format(date_parse(datetime,'%Y-%m-%d %H:%i:%s'),'%Y-%m-%d')",
};

export const AGGREGATORS = {
  SUM: 'SUM',
  DISTINCT: 'DISTINCT',
  AVG: 'AVG',
};

export const COMPARATORS = {
  GREATER_THAN_OR_EQUAL: '>=',
  LESS_THAN_OR_EQUAL: '<=',
  LESS_THAN: '<',
  GREATER_THAN: '>',
  EQUALS: '=',
  LIKE: 'LIKE ',
  NOT_LIKE: 'NOT LIKE',
  NOT_EQUAL_TO: '!=',
  IN: 'IN',
  NOT_EQUAL: '<>',
  NOT_IN: 'NOT IN',
  IS: 'IS',
};

export const ORDER_BY = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};

export const QUERY_VALUES = {
  COMMITMENT: '%COMMITMENT%',
  DELETE_DISK: '%DELETE_DISK%',
  ADDRESS: '%ADDRESS%',
  IMAGE: '%IMAGE%',
  VM: '%VM%',
  MACHINE: '%MACHINE%',
  ROLE: '%ROLE%',
  COST: 'COST',
  PERFORMANCE: 'PERFORMANCE',
  SECURITY: 'SECURITY',
  SUSTAINED_USAGE_DISCOUNT: 'Sustained Usage Discount',
  COMMITTED_USAGE_DISCOUNT: 'Committed Usage Discount',
  BUNDLED_DISCOUNT: 'BundledDiscount',
  USAGE: 'Usage',
  CREDIT: 'Credit',
  ADJUSTMENT: 'Adjustment',
  DISCOUNTED_USAGE: "('SavingsPlanUpfrontFee','SavingsPlanRecurringFee')",
  PURCHASE: 'Purchase',
  CREDIT_BREAKDOWN: "('BundledDiscount','Credit')",
  CREDIT_BREAKDOWN_FOCUS: "('Usage','Adjustment')",
  SEQUENCE_NUMBER_VALUE_DESC:
    'row_number() over (partition by name order by last_refresh_time desc, state asc)',
  SEQUENCE_NUMBER_VALUE_ASC:
    'row_number() over (partition by name order by last_refresh_time asc)',
  AZURE_SEQUENCE_NUMBER_VALUE_DESC:
    'row_number() over (partition by name order by properties_lastUpdated desc)',
  DATE_INTERVAL_10_DAYS: '#DATE_SUB(CURRENT_TIMESTAMP(), INTERVAL 10 DAY)',
  DATE_INTERVAL_30_DAYS: '#DATE_SUB(CURRENT_TIMESTAMP(), INTERVAL 30 DAY)',
  DATE_INTERVAL_90_DAYS: '#DATE_SUB(CURRENT_TIMESTAMP(), INTERVAL 90 DAY)',
  DATE_INTERVAL_365_DAYS: '#DATE_SUB(CURRENT_TIMESTAMP(), INTERVAL 365 DAY)',
  GOOG_K8S_CLUSTER_NAME: 'goog-k8s-cluster-name',
  K8S_NAMESPACE: 'k8s-namespace',
  CPU: 'cpu',
  APP: 'app',
  REFUND: 'Refund',
  AMAZON_ELASTIC_COMPUTE_CLOUD: "('Amazon Elastic Compute Cloud')",
  AMAZON_RELATIONAL_DATABASE_SERVICE: "('Amazon Relational Database Service')",
  BOX_USAGE: '%BoxUsage%',
  EBS_VOLUME_USAGE_GP2: 'EBS:VolumeUsage.gp2',
  ELASTIC_LOAD_BALANCING: "('Elastic Load Balancing')",
  AMAZON_REDSHIFT: "('Amazon Redshift')",
  ACTIVE: 'ACTIVE',
  CLAIMED: 'CLAIMED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  NORMAL_CASE_COST: 'Cost',
  SELECT_MAX_DATE_UPLOADED_TIMESTAMP:
    '(SELECT MAX(CAST(SUBSTRING(date_uploaded_timestamp, 1, 10) AS DATE)) FROM cb360.recomendations)',
  INTEGER: 'Integer',
  AMAZON_EC2: 'AmazonEC2',
  AWS_LAMBDA: 'AWSLambda',
  T3_CPU_CREDITS: 'T3CPUCredits',
  FARGATE_TASK: 'FargateTask',
  AMAZON_EKS: 'AmazonEKS',
  AMAZON_ECS: 'AmazonECS',
  AMAZON_EBS: 'AmazonEBS',
  EKS_CONTROL_PANEL: 'EKSControlPlane',
  AWS_RELATIONAL_DATABASE_SERVICE: 'Amazon Relational Database Service',
  AMAZON_RED_SHIFT: 'Amazon Redshift',
  AMAZON_OPEN_SEARCH_SERVICE: 'Amazon OpenSearch Service',
  AMAZON_NEPTUNE: 'Amazon Neptune',
  AMAZON_ELASTIC_CACHE: 'Amazon ElastiCache',
  AMAZON_DOCUMENT_DB: 'Amazon DocumentDB',
  GB_MO: '%%GB-Mo%%',
  EBS_VOLUME: '%%EBS%%Volume%%',
  STORAGE: 'Storage',
  SYSTEM_OPERATION: 'System Operation',
  STORAGE_SNAPSHOT: 'Storage Snapshot',
  AMAZON_FSX: 'Amazon FSx',
  AMAZON_ELASTIC_FILE_SYSTEM: 'Amazon Elastic File System',
  NULL: 'NULL',
  NOT_NULL: 'NOT NULL',
  AMAZON_SIMPLE_EMAIL_SERVICE: 'Amazon Simple Email Service',
  AMAZON_SIMPLE_STORAGE_SERVICE: 'Amazon Simple Storage Service',
  AMAZON_SIMPLE_NOTIFICATION_SERVICE: 'Amazon Simple Notification Service',
  READ_LOCATION: 'ReadLocation',
  COMPUTE_SAVINGS_PLANS: 'ComputeSavingsPlans',
  SAVINGS_PLAN_RECURRING_FEE: 'SavingsPlanRecurringFee',
  PUT_CROSS_ACCOUNT_EVENTS: 'PutCrossAccountEvents',
  LOGGING: '%logging%',
  MICROSOFT_DOCUMENT_DB: 'Microsoft.DocumentDB',
  MICROSOFT_SQL: 'Microsoft.Sql',
  MICROSOFT_DB_FOR_POSTGRESQL: 'Microsoft.DBforPostgreSQL',
  MICROSOFT_MY_SQL_SERVER: 'Microsoft.MySQLServer',
  MICROSOFT_MARIA_DB_SERVER: 'Microsoft.MariaDBServer',
  MICROSOFT_CACHE: 'Microsoft.Cache',
  SELECT_MAX_PROPERTIES_LAST_UPDATED:
    '(SELECT MAX(properties_lastUpdated) FROM cb360.recomendations GROUP BY DATEPART(YEAR, properties_lastUpdated), DATEPART(MONTH, properties_lastUpdated))',
  MANAGED_DISK: 'Managed Disk',
  BLOB: 'Blob',
  VIRTUAL_MACHINES: '%Virtual Machines%',
  FUNCTIONS: 'Functions',
  CONTAINER_INSTANCES: 'Container Instances',
  COMPUTE_ENGINE: 'Compute Engine',
  CLOUD_RUN: 'Cloud Run',
  CLOUD_RUN_FUNCTIONS: 'Cloud Run Functions',
  CLOUD_FUNCTIONS: 'Cloud Functions',
  KUBERNETES_ENGINE: 'Kubernetes Engine',
  CLOUD_STORAGE: 'Cloud Storage',
  BYTE_SECONDS: 'byte-seconds',
  CLOUD_SQL: 'Cloud SQL',
  BIGQUERY: 'BigQuery',
};

export const GROUP_BY_COLUMN_INDEX = {
  ONE: '1',
  TWO: '2',
};

export const CONJUNCTIONS = {
  AND: 'AND',
  OR: 'OR',
};

export const REQUEST_STATUS = {
  PROCESSING: 'Processing',
  SUCCESS: 'Success',
  ERROR: 'Error',
};

export const ERROR_KEY = 'API_CALL_ERROR_KEY';
export const SUCCESS_KEY = 'API_CALL_ERROR_KEY';

export const ANT_MESSAGE_LONG_DURATION = 5;
