import { useEffect, useRef } from 'react';

// Hook to access the given state {value} from the previous render
const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export default usePrevious;
