import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGCPZonesData } from 'pages/ThirdPartyPage/components/GProfilerConnectionPage/services';
import { extractServiceName } from 'pages/ThirdPartyPage/components/GProfilerConnectionPage/utils';
import {
  selectGranulateConnection,
  setGCPConnection,
} from 'redux/granulateConnectionSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { onApiCallError } from 'utils/handleErrors';

import GranulateAppsGCPConnection from '../../../../GranulateAppsComponents/GranulateAppsGCPConnection';
import { getGCPClusterData } from '../../services';

type GCPConnectionFormProps = {
  setSubmitDisabled: (val: boolean) => void;
};

const GCPConnectionForm = ({ setSubmitDisabled }: GCPConnectionFormProps) => {
  const { gcpConnection, connectionList } = useSelector(
    selectGranulateConnection
  );
  const dispatch = useDispatch();

  const [serviceList, setServiceList] = useState<string[]>([]);
  const [zones, setZones] = useState<string[]>([]);
  const [clusterList, setClusterList] = useState<any[]>([]);
  const [inputLoaderStatus, setInputLoaderStatus] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchingClusterStatus, setFetchingClusterStatus] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );
  const [projects, setProjects] = useState<string[]>([]);
  const [projectsRequestStatus, setProjectsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  const resetGCPConnection = () => {
    dispatch(
      setGCPConnection({
        connectionName: '',
        cloudConnection: '',
        serviceName: '',
        region: '',
        cluster: '',
        file: undefined,
        connectorId: '',
        project: '',
      })
    );
  };

  const addConnectorIdForNewConnection = (connectorId: string) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        connectorId,
      })
    );
  };

  const removeJson = () => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        file: undefined,
        serviceName: '',
        region: '',
        cluster: '',
        connectorId: '',
      })
    );
  };

  const dispatchCluster = (cluster: string) => {
    dispatch(setGCPConnection({ ...gcpConnection, cluster }));
  };

  const dispatchRegionAndResetingCluster = (region: string) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        region,
        cluster: '',
      })
    );
  };

  const dispatchServiceName = (service?: string) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        serviceName: service!,
      })
    );
  };

  const dispatchCloudConnectionAndConnectorId = (
    cloudConnection: string,
    connectorId: string
  ) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        cloudConnection,
        connectorId,
      })
    );
  };

  const dispatchConnectionName = (connectionName: string) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        connectionName,
      })
    );
  };

  const dispatchJsonField = (keyJson: any) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        file: {
          jsonFileId: keyJson,
        },
      })
    );
  };

  const dispatchProject = (project: string) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        project,
      })
    );
  };

  /**
   * @function fetchServiceList
   * @description Function to get all services
   */
  const fetchServiceList = () => {
    const serviceNames = connectionList
      .filter((connection) => connection.provider === PROVIDER.GCP)
      .map((connection) => extractServiceName(connection.serviceName));
    setServiceList(serviceNames);
  };

  /**
   * @function fetchZones
   * @description Function to get all zones based on connectorId
   */
  const fetchZones = () => {
    if (gcpConnection.project) {
      setInputLoaderStatus(REQUEST_STATUS.PROCESSING);
      getGCPZonesData({
        connectorId: gcpConnection.connectorId,
        projectId: gcpConnection.project,
      })
        .then((res: any) => {
          if (res?.status === 200) {
            setInputLoaderStatus(REQUEST_STATUS.SUCCESS);
            setZones(res?.data);
          }
        })
        .catch((e) => {
          onApiCallError(e, true);
          setInputLoaderStatus(REQUEST_STATUS.ERROR);
        });
    }
  };

  /**
   * @function fetchClusterList
   * @description Function to get all clusters based on zone
   * @param zone cluster related to zone
   */
  const fetchClusterList = (zone: string) => {
    const params = { connectorId: gcpConnection.connectorId, zoneId: zone };

    setFetchingClusterStatus(REQUEST_STATUS.PROCESSING);
    getGCPClusterData(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setFetchingClusterStatus(REQUEST_STATUS.SUCCESS);
          setClusterList(res?.data);
        }
      })
      .catch((e) => {
        setFetchingClusterStatus(REQUEST_STATUS.ERROR);
        onApiCallError(e, true);
      });
  };

  return (
    <GranulateAppsGCPConnection
      setSubmitDisabled={setSubmitDisabled}
      gcpConnection={gcpConnection}
      serviceList={serviceList}
      fetchServiceList={fetchServiceList}
      zones={zones}
      fetchZones={fetchZones}
      clusterList={clusterList}
      setClusterList={setClusterList}
      fetchClusterList={fetchClusterList}
      fetchingClusterStatus={fetchingClusterStatus}
      setFetchingClusterStatus={setFetchingClusterStatus}
      inputLoaderStatus={inputLoaderStatus}
      setInputLoaderStatus={setInputLoaderStatus}
      resetGCPConnection={resetGCPConnection}
      addConnectorIdForNewConnection={addConnectorIdForNewConnection}
      removeJson={removeJson}
      dispatchCluster={dispatchCluster}
      dispatchRegionAndResetingCluster={dispatchRegionAndResetingCluster}
      dispatchCloudConnectionAndConnectorId={
        dispatchCloudConnectionAndConnectorId
      }
      dispatchServiceName={dispatchServiceName}
      dispatchConnectionName={dispatchConnectionName}
      dispatchJsonField={dispatchJsonField}
      dispatchProject={dispatchProject}
      hideSetupConnection={true}
      showProjectDropdown={true}
      projects={projects}
      setProjects={setProjects}
      projectsRequestStatus={projectsRequestStatus}
      setProjectsRequestStatus={setProjectsRequestStatus}
    />
  );
};

export default GCPConnectionForm;
