import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import MenuManager from 'components/MenuManager';
import { selectNavMenu } from 'redux/activeNavMenuSlice';
import { importStaticFiles } from 'redux/importStaticFileSlice';
import IdleTimer from 'components/IdleTimer';

import './index.scss';

const AppLayout = () => {
  const { isSubMenuOpen, showMenuWithTitle } = useSelector(selectNavMenu);
  const { uploadingFileTransactions } = useSelector(importStaticFiles);

  useEffect(() => {
    if (uploadingFileTransactions.length > 0) {
      window.addEventListener('beforeunload', beforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [uploadingFileTransactions]);

  const beforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = null;
    return undefined;
  };

  return (
    <div className="app-layout stretch flex">
      <IdleTimer>
        <MenuManager />
        <div
          className={`main-section stretch flex flex-column ${
            isSubMenuOpen && 'nav-pinned'
          } ${(showMenuWithTitle || isSubMenuOpen) && 'menu-with-title'}`}
        >
          <div className="app-content full-height flex flex-column">
            <Outlet />
          </div>
        </div>
      </IdleTimer>
    </div>
  );
};

export default AppLayout;
