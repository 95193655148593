import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import Input from 'components/Input';
import { COLORS } from 'constants/graphConfig';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { INPUT_SIZE } from 'constants/appearance';
import {
  dispatchUpdatedCustomViewData,
  doesDataSourceSupportBillingMapping,
} from 'pages/CustomDashboardPage/utils';
import { ColumnType } from 'types/dashboard';
import {
  customDashboard,
  setSelectedDimensions,
} from 'redux/customDashboardSlice';

type DimensionListItemProps = {
  dimension: ColumnType;
  dragHandleProps?: any;
};

const DimensionListItem = ({
  dimension,
  dragHandleProps,
}: DimensionListItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedDimensions } = useSelector(customDashboard);
  const [isEditName, setIsEditName] = useState(false);

  /**
   * @function updateDimension
   * @description Updates the dimension name based on dimension field and updates the custom view data
   * @param dimensionField - dimension to be updated
   * @param dimensionName - updated dimension name
   */
  const updateDimension = (dimensionField: string, dimensionName: string) => {
    const updatedDimensions = selectedDimensions.map((item) =>
      item.field === dimensionField ? { ...item, label: dimensionName } : item
    );

    dispatch(setSelectedDimensions(updatedDimensions));
  };

  /**
   * @function onRemoveDimension
   * @description Removes the dimension from the selected dimensions list and updates the custom view data
   * @param dimension - dimension to be removed
   */
  const onRemoveDimension = (dimension: string) => {
    const updatedDimensions = selectedDimensions.filter(
      (item) => item.field !== dimension
    );

    dispatch(setSelectedDimensions(updatedDimensions));
    dispatchUpdatedCustomViewData();
  };

  return (
    <div
      className="dimension-item flex flex-space-between flex-align-items-center"
      key={dimension.field}
    >
      <div className="flex flex-column flex-gap-2" {...dragHandleProps}>
        <div className="flex flex-gap-2 flex-align-items-start">
          <Icon
            iconName={ICONS.DRAGGABLE}
            size={ICONS_SIZE.SM}
            color={COLORS.colorRegentGrey}
            className="dimension-handle"
          />
          {isEditName && !doesDataSourceSupportBillingMapping() ? (
            <Input
              placeholder={t('customDashboard.optionsLabels.searchDimensions')}
              value={dimension.label}
              onChange={(e: any) =>
                updateDimension(dimension.field, e.target.value)
              }
              onBlur={() => {
                setIsEditName(false);
                dispatchUpdatedCustomViewData();
              }}
              onPressEnter={() => {
                setIsEditName(false);
                dispatchUpdatedCustomViewData();
              }}
              size={INPUT_SIZE.SMALL}
            />
          ) : (
            <span className="dimension-label font-subHeader">
              {`${
                dimension.tagDimensionType
                  ? dimension.label + ' (' + dimension.tagDimensionType + ')'
                  : dimension.label
              }`}
            </span>
          )}
        </div>
        {!doesDataSourceSupportBillingMapping() && (
          <div className="actual-field flex flex-gap-4 flex-align-items-center">
            <span className="font-small-bold">
              {t('customDashboard.optionsLabels.actualField')}
            </span>
            <span className="font-small">{dimension.field}</span>
          </div>
        )}
      </div>
      <div className="flex flex-gap-4">
        {!isEditName && !doesDataSourceSupportBillingMapping() && (
          <Icon
            iconName={ICONS.EDIT_LINE}
            size={ICONS_SIZE.SM}
            color={COLORS.colorRegentGrey}
            onClick={() => setIsEditName(true)}
          />
        )}
        <Icon
          iconName={ICONS.SUBTRACT_LINE}
          size={ICONS_SIZE.SM}
          color={COLORS.colorRegentGrey}
          onClick={() => onRemoveDimension(dimension.field)}
        />
      </div>
    </div>
  );
};

export default DimensionListItem;
