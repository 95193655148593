import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RangeValue } from 'rc-picker/lib/interface';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { HYPHEN_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import { MonthlyCreditCostMetricType } from 'types/dataTypes';
import { selectDashboard } from 'redux/dashboardSlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import LineChart from 'components/LineChart';

import { getAWSCreditDiscountBreakdownExcelData } from '../../utils/exportToExcel';

type CreditDiscountBreakdownProps = {
  requestStatus: string;
  creditDiscountBreakdownLabels: string[];
  isCreditDiscountBreakdownTableView: boolean;
  setCreditDiscountBreakdownTableView: (value: boolean) => void;
  monthlyCreditByCostTypeData: MonthlyCreditCostMetricType[];
  pdfView: boolean;
  creditDiscountStartDate: string;
  creditDiscountEndDate: string;
  onChangeCreditDiscountDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

export const CreditDiscountBreakdown = ({
  requestStatus,
  creditDiscountBreakdownLabels,
  isCreditDiscountBreakdownTableView,
  setCreditDiscountBreakdownTableView,
  monthlyCreditByCostTypeData,
  pdfView,
  creditDiscountStartDate,
  creditDiscountEndDate,
  onChangeCreditDiscountDateRange,
}: CreditDiscountBreakdownProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const getColumns = () => {
    let columns: any[] = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        render: (_text: any, _record: any, index: number) => index + 1,
        width: 50,
      },
      {
        title: t('creditDiscountBreakdown.month'),
        dataIndex: 'month',
        key: 'month',
        width: 50,
        align: 'center' as const,
      },
    ];
    monthlyCreditByCostTypeData.forEach((value) => {
      columns = [
        ...columns,
        {
          title: value.costMetricType,
          dataIndex: value.costMetricType,
          key: value.costMetricType,
          width: 100,
          align: 'center' as const,
          render: (text: any) => currencySymbol + numberCommaSeparator(text),
        },
      ];
    });
    return columns;
  };

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(creditDiscountStartDate, HYPHEN_DATE_FORMAT),
          moment(creditDiscountEndDate, HYPHEN_DATE_FORMAT),
        ]}
        onChange={onChangeCreditDiscountDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  const getDataSource = () =>
    creditDiscountBreakdownLabels.map((value, index) => {
      let thisMonthData: any = {
        month: value,
        key: index,
      };
      monthlyCreditByCostTypeData.forEach((costData) => {
        if (costData.creditData)
          thisMonthData[costData.costMetricType] = Number(
            costData.creditData[index]
          );
        else thisMonthData[costData.costMetricType] = 0;
      });
      return thisMonthData;
    });

  const getGraphData = () => {
    let graphData: any[] = [];
    monthlyCreditByCostTypeData.forEach((value) => {
      for (let i = 0; i < value?.creditData?.length || 0; i++) {
        graphData.push({
          month: creditDiscountBreakdownLabels[i],
          value: Number(value.creditData[i]) || 0,
          category: value.costMetricType?.trim(),
        });
      }
    });
    return graphData;
  };

  const getComponent = () =>
    isCreditDiscountBreakdownTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={getDataSource()}
          columns={getColumns()}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <LineChart
        data={getGraphData()}
        xField="month"
        yField="value"
        groupingField="category"
        xTitle={t('creditDiscountBreakdown.month')}
        yTitle={t('creditDiscountBreakdown.creditAmount')}
        showAllLegend={pdfView}
        disableAnimation={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  return (
    <div className="credit-discout-breakdown graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.creditDiscountBreakdown')}
        graphName={'credit-discout-breakdown'}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        isTableView={isCreditDiscountBreakdownTableView}
        setIsTableView={setCreditDiscountBreakdownTableView}
        designVersion2
        excelData={getAWSCreditDiscountBreakdownExcelData(
          selectedDashboard?.connectorName ?? '',
          getDataSource(),
          monthlyCreditByCostTypeData
        )}
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};
