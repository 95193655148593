import i18n from 'i18n';

import { ItemOptionsType } from 'components/DropdownCheckbox/types';
import { ChartCriteria } from 'constants/dashboard';
import { store } from 'redux/store';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const AzureCloudSpendColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('resourceGroups'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: i18n.t('costInCurrency', { currencySymbol }),
    dataIndex: 'cost',
    key: 'cost',
    width: 80,
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
    align: 'right' as const,
  },
];

export const CostByResourceColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 50,
  },
  {
    title: i18n.t('costByResource.resources'),
    dataIndex: 'resource',
    key: 'resource',
  },
  {
    title: i18n.t('costByResource.costInCurrency', { currencySymbol }),
    dataIndex: 'cost',
    key: 'cost',
    width: 200,
    align: 'right' as const,
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
  },
];

export const YtdCostByResourceGroupColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 50,
  },
  {
    title: i18n.t('ytdCostByAzureResourceGroups.resourceGroup'),
    dataIndex: 'resource_group',
    key: 'resource_group',
  },
  {
    title: i18n.t('ytdCostByAzureResourceGroups.month'),
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: i18n.t('ytdCostByAzureResourceGroups.costAfterCredit', {
      currencySymbol: currencySymbol,
    }),
    dataIndex: 'cost',
    key: 'cost',
    width: 180,
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
  },
];

export const UsageSpendColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    width: 30,
    render: (_text: any, _record: any, index: number) => index + 1,
  },
  {
    title: i18n.t('usageSpend.usageType'),
    dataIndex: 'usage_type',
    key: 'usage_type',
  },
  {
    title: i18n.t('usageSpend.spend'),
    dataIndex: 'cost',
    key: 'cost',
    width: 200,
    align: 'right' as const,
    render: (text: string) => currencySymbol + numberCommaSeparator(text),
  },
];

export const CHART_CRITERIA_LIST: ItemOptionsType[] = [
  {
    title: i18n.t('timePeriod'),
    value: ChartCriteria.TIME_PERIOD,
  },
  {
    title: i18n.t('region'),
    value: ChartCriteria.REGION,
  },
  {
    title: i18n.t('resource'),
    value: ChartCriteria.RESOURCE,
  },
  {
    title: i18n.t('resourceGroup'),
    value: ChartCriteria.RESOURCE_GROUP,
  },
  {
    title: i18n.t('service'),
    value: ChartCriteria.SERVICE,
  },
  {
    title: i18n.t('spendProfile'),
    value: ChartCriteria.SPEND_PROFILE,
  },
];
