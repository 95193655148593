import { useSelector } from 'react-redux';
import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import { getProviderLogo } from 'utils/providerDetails';

const GranulateDashboardHeader = () => {
  const { selectedGranulateService } = useSelector(costOptimizationInsights);

  return (
    <div className="container-cost-dashboard-title flex flex-align-items-center flex-gap-16">
      <img
        width={35}
        height={35}
        src={getProviderLogo(selectedGranulateService?.provider ?? '')}
        alt={`${selectedGranulateService?.provider} Logo`}
      />
      <div className="flex flex-column">
        <div className="title-container flex flex-row">
          <div className="edit flex flex-center">
            <div className="modal-heading">
              {selectedGranulateService?.name}
            </div>
          </div>
        </div>
        <div className="data-source-type flex font-caption-bold">
          <div>{selectedGranulateService?.connectionName}</div>
        </div>
      </div>
    </div>
  );
};

export default GranulateDashboardHeader;
