import React from 'react';
import './index.scss';

type TooltipContentProps = {
  title: string;
  data: any[];
  prefixSymbol?: string;
  postfixSymbol?: string;
  additionalContent?: React.ReactNode;
};

const TooltipContent = ({
  title,
  data,
  prefixSymbol,
  postfixSymbol,
  additionalContent,
}: TooltipContentProps) => {
  if (title)
    return (
      <div className="custom-tooltip-content flex flex-column flex-gap-4">
        <span className="font-tooltip-small">{title}</span>
        {data.map((item: any) => (
          <div
            className="flex flex-space-between flex-center flex-gap-8"
            key={item.name}
          >
            <div className="flex flex-gap-4 flex-center">
              <div className="color-disc" style={{ background: item.color }} />
              <span className="y-title font-comparison-label">{item.name}</span>
            </div>
            <span className="value font-small-bold">
              {item.prefix ?? prefixSymbol} {item.value}
              {` ${item.suffix ?? postfixSymbol ?? ''}`}
            </span>
          </div>
        ))}
        {additionalContent}
      </div>
    );
  return <></>;
};

export default TooltipContent;
