import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ruleEngine,
  setIsTryAgain,
  setRuleEngineComponent,
} from 'redux/ruleEngineSlice';
import { setSelectedCostOptimizationInsightsNav } from 'redux/costOptimizationInsightsSlice';
import { RuleEngineComponents } from 'pages/RuleEnginePage/constants';
import { COST_OPTIMIZATION_INSIGHTS_NAV } from 'pages/CostOptimizationInsightsPage/constants';
import ProviderList from 'components/ConnectionProviderList';
import SuccessComponent from 'components/SuccessComponent';
import ErrorComponent from 'components/ErrorComponent';
import { SuccessIcon } from 'assets/icons';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { PROVIDER } from 'constants/cloudProviders';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import RulesetForm from '../RulesetForm';

import './index.scss';

const CreateRulesetForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ruleEngineComponent, ruleEngineFormData, isEditForm } =
    useSelector(ruleEngine);

  useEffect(() => {
    if (!ruleEngineFormData) {
      dispatch(setRuleEngineComponent(RuleEngineComponents.PROVIDER_LIST));
    }
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function onHandleButtonClick
   * @description Callback function for clicking the back arrow
   */
  const onHandleButtonClick = () => {
    dispatch(
      setSelectedCostOptimizationInsightsNav(
        COST_OPTIMIZATION_INSIGHTS_NAV.WASTE_MANAGEMENT_RECOMMENDATIONS
      )
    );
    navigate(NAVIGATION_MENU_PATH.COST_OPTIMIZATION_INSIGHTS);
  };

  /**
   * @function getComponent
   * @description Function for returning the component based on the component set
   * @returns JSX element
   */
  const getComponent = () => {
    const { PROVIDER_LIST, RULESETS_FORM, SUCCESS_PAGE, ERROR_PAGE } =
      RuleEngineComponents;

    switch (ruleEngineComponent) {
      case PROVIDER_LIST:
        return (
          <div className="vertical-margin-24">
            <ProviderList
              handleContinueButtonClick={() =>
                dispatch(setRuleEngineComponent(RULESETS_FORM))
              }
              handleBackArrowClick={() =>
                navigate(NAVIGATION_MENU_PATH.RULE_ENGINE)
              }
              heading={t(
                'costMonitor.createCostMonitorLabels.selectCloudProvider'
              )}
              subHeading={t(
                'ruleEngineLabels.rulesetCloudProviderListSubHeading'
              )}
              disabledList={[PROVIDER.AZURE, PROVIDER.OCI]}
            />
          </div>
        );

      case RULESETS_FORM:
        return <RulesetForm />;
      case SUCCESS_PAGE:
        return (
          <SuccessComponent
            mainTitle={
              isEditForm
                ? t('createRulesetLabels.rulesets.successfullyUpdated')
                : t('createRulesetLabels.rulesets.successfullyCreated')
            }
            subTitle={
              isEditForm
                ? t('createRulesetLabels.rulesets.successfullyUpdatedSubTitle')
                : t('createRulesetLabels.rulesets.successfullyCreatedSubTitle')
            }
            buttonTitle={t(
              'createRulesetLabels.rulesets.gotoCostOptimizationInsights'
            )}
            icon={<SuccessIcon />}
            onHandleButtonClick={onHandleButtonClick}
            linkTitle={t('createRulesetLabels.rulesets.gotoRuleEngineList')}
            onHandleLinkClick={() => navigate(NAVIGATION_MENU_PATH.RULE_ENGINE)}
          />
        );
      case ERROR_PAGE:
        return (
          <ErrorComponent
            mainTitle={
              isEditForm
                ? t('createRulesetLabels.rulesets.errorUpdatingRule')
                : t('createRulesetLabels.rulesets.errorCreatingRule')
            }
            buttonTitle={t('createRulesetLabels.rulesets.tryAgain')}
            onHandleButtonClick={() => {
              dispatch(setIsTryAgain(true));
              dispatch(
                setRuleEngineComponent(RuleEngineComponents.RULESETS_FORM)
              );
            }}
            linkTitle={t('createRulesetLabels.rulesets.gotoRuleEngineList')}
            onHandleLinkClick={() => navigate(NAVIGATION_MENU_PATH.RULE_ENGINE)}
          />
        );
    }
  };

  return <div className="page-content">{getComponent()}</div>;
};

export default CreateRulesetForm;
