import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { useNavigate } from 'react-router-dom';
import { loginRequest } from 'authConfig';
import Button from 'components/Button';
import { LoginTypes } from 'pages/LoginPage/constants';
import { BUTTON_SIZE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { Config } from 'config';
import { logout } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

type LogoutOverlayProps = {
  profileImage: any;
};

const LogoutOverlay = ({ profileImage }: LogoutOverlayProps) => {
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const loginType = Config.login_type;
  const navigate = useNavigate();

  /**
   * @function onClickLogout
   * @description Function to logout from application
   */
  const onClickLogout = () => {
    if (loginType === LoginTypes.MFA) {
      localStorage.clear();
      navigate(NAVIGATION_MENU_PATH.LOGIN);
      return;
    }

    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    instance.logoutRedirect(request);
  };

  /**
   * @function invalidateToken
   * @description Function to invalidate the token on logout
   */
  const invalidateToken = () => {
    logout()
      .then((res: any) => {
        if (res.status === 200) {
          onClickLogout();
          return;
        }

        message.error('logoutErrorMessage');
      })
      .catch((e) => {
        onApiCallError(e);
        message.error('logoutErrorMessage');
      });
  };

  return (
    <div className="logout-overlay flex flex-column flex-gap-12">
      <div className="flex flex-align-items-center flex-gap-4">
        <img src={profileImage} alt="profileimage" className="profile-pic" />
        <div>
          <div className="font-button">{localStorage.getItem('name')}</div>
          <div className="email font-caption">
            {localStorage.getItem('email')}
          </div>
        </div>
      </div>
      <Button
        title={t('logoutBtn')}
        rootClassName="full-width"
        size={BUTTON_SIZE.SMALL}
        onClick={invalidateToken}
      />
    </div>
  );
};

export default LogoutOverlay;
