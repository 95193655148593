import { useDispatch, useSelector } from 'react-redux';

import { CHART_TYPES } from 'constants/graphConfig';
import AccessibleDiv from 'components/AccessibleDiv';
import {
  customDashboard,
  setCustomViewData,
  setSelectedChartIndex,
  setSelectedChartRow,
} from 'redux/customDashboardSlice';
import { ChartType, LayoutDesignsType } from 'types/dashboard';

import { LAYOUTS } from './constants';

import './index.scss';

type NewLayoutsProps = {
  setShowLayoutOptions: (val: boolean) => void;
};

const NewLayouts = ({ setShowLayoutOptions }: NewLayoutsProps) => {
  const dispatch = useDispatch();
  const { customViewData, customDashboardDataSource } =
    useSelector(customDashboard);

  const addLayout = (layout: number[]) => {
    const charts: ChartType[] = layout.map((chartPercentage, index) => ({
      chartPosition: index,
      percentage: chartPercentage,
      chartType: CHART_TYPES.TABLE,
      tableQuery: {
        columns: [],
        aggregators: [],
        dashBoardType: customDashboardDataSource.dashBoardType,
        cached: true,
      },
      chartQuery: {
        columns: [],
        aggregators: [],
        dashBoardType: customDashboardDataSource.dashBoardType,
        cached: true,
      },
    }));
    const layoutDesign: LayoutDesignsType = {
      position: customViewData.layoutDesigns.length,
      charts: charts,
    };

    dispatch(
      setCustomViewData({
        ...customViewData,
        layoutDesigns: [...(customViewData?.layoutDesigns || []), layoutDesign],
      })
    );
    dispatch(setSelectedChartRow(customViewData.layoutDesigns.length));
    dispatch(setSelectedChartIndex(0));
    setShowLayoutOptions(false);
  };

  return (
    <div className="new-layouts flex flex-gap-12">
      {LAYOUTS.map((layout) => (
        <AccessibleDiv
          className="layout cursor-pointer flex flex-gap-2"
          key={layout.toString()}
          onClick={() => addLayout(layout)}
        >
          {layout.length === 1 ? (
            <div className={`width-${layout[0]} layout-share`} />
          ) : (
            <>
              <div className={`width-${layout[0]} layout-share`} />
              <div className={`width-${layout[1]} layout-share`} />
            </>
          )}
        </AccessibleDiv>
      ))}
    </div>
  );
};

export default NewLayouts;
