/**
 * @function calculateTotalCostSum
 * @description Function to calculate the sum of cost field in a array of numbers
 * @param data List of cost
 * @returns number containing the total cost
 */
export const calculateTotalCostSum = (data: number[]) =>
  Math.round(
    100 *
      data.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
  ) / 100;
