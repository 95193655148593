import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import FormFooter from 'components/FormFooter';
import ProgressBar from 'components/ProgressBar';
import ProviderList from 'components/ConnectionProviderList';
import {
  INTEGRATION_PROVIDER_LIST_DATA,
  IntegrationTypes,
  IntegrationsPageComponent,
} from 'pages/IntegrationsPage/constants';
import { SnowflakeIntegrationType } from 'pages/IntegrationsPage/types';
import { setNavSubPath } from 'redux/activeNavMenuSlice';
import { providerList } from 'redux/providerSlice';

import SnowflakeAccess from './components/SnowflakeAccess';
import { PROGRESS_BAR_ITEMS } from './constants';

import './index.scss';

type IntegrationFormProps = {
  editIntegrationData?: SnowflakeIntegrationType;
  setIntegrationPageComponent: Function;
};

const IntegrationForm = ({
  editIntegrationData,
  setIntegrationPageComponent,
}: IntegrationFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', {
    keyPrefix: 'integrations',
  });
  const { selectedProvider } = useSelector(providerList);

  const [currentStep, setCurrentStep] = useState(editIntegrationData ? 1 : 0);
  const [formSubmitRequestStatus, setFormSubmitRequestStatus] = useState('');
  const [clickedSubmit, setClickedSubmit] = useState(false);

  useEffect(() => {
    dispatch(
      setNavSubPath([
        editIntegrationData ? t('editIntegrationHeading') : t('addIntegration'),
      ])
    );
  }, []);

  /**
   * @function isNextButtonDisabled
   * @description function to check if next button should be disabled
   */
  const isNextButtonDisabled = () => {
    if (currentStep === 0) {
      return Object.values(IntegrationTypes).some(
        (type) => type !== selectedProvider
      );
    }
  };

  /**
   * @function getNextButtonTitle
   * @returns next button title
   */
  const getNextButtonTitle = () => {
    if (currentStep === 0) return t('next');
    return editIntegrationData ? t('update') : t('submit');
  };

  return (
    <div className="integration-form flex">
      <ProgressBar
        items={PROGRESS_BAR_ITEMS}
        current={currentStep}
        additionalClassNames="progress-bar new-styled-scroll width-40"
        heading={
          editIntegrationData
            ? t('editIntegrationHeading')
            : t('setupNewIntegrationHeading')
        }
        subHeading={t('setupNewIntegrationSubHeading', {
          stepCount: PROGRESS_BAR_ITEMS.length,
        })}
      />
      <div className="flex flex-column flex-space-between flex-fit">
        <div className="form-content new-styled-scroll">
          {currentStep === 0 ? (
            <ProviderList
              heading={PROGRESS_BAR_ITEMS[currentStep].title}
              subHeading={PROGRESS_BAR_ITEMS[currentStep].description}
              listData={INTEGRATION_PROVIDER_LIST_DATA}
            />
          ) : (
            <div className="flex flex-column flex-gap-24">
              <div className="flex flex-column flex-gap-8 flex-center">
                <span className="modal-heading">
                  {PROGRESS_BAR_ITEMS[currentStep].title}
                </span>
                <span className="table-typography">
                  {PROGRESS_BAR_ITEMS[currentStep].description}
                </span>
              </div>
              <SnowflakeAccess
                clickedSubmit={clickedSubmit}
                setClickedSubmit={setClickedSubmit}
                setFormSubmitRequestStatus={setFormSubmitRequestStatus}
                setIntegrationPageComponent={setIntegrationPageComponent}
                editSnowflakeData={editIntegrationData}
              />
            </div>
          )}
        </div>
        <FormFooter
          showPreviousButton={currentStep !== 0 && !editIntegrationData}
          onClickNext={() => {
            if (currentStep === 0) {
              return setCurrentStep(currentStep + 1);
            }
            setClickedSubmit(true);
          }}
          nextButtonTitle={getNextButtonTitle()}
          isNextDisabled={isNextButtonDisabled()}
          onClickPrevious={() => setCurrentStep(currentStep - 1)}
          submitRequestStatus={formSubmitRequestStatus}
          onClickCancel={() =>
            setIntegrationPageComponent(IntegrationsPageComponent.TABLE)
          }
        />
      </div>
    </div>
  );
};

export default IntegrationForm;
