import { PurchaseAwsEc2RisType } from 'pages/PurchasePage/components/ReservedInstances/types';

/**
 * @function getEc2SubTotalDue
 * @description Function to calculate the sub total due of a purchase
 * @param term The term purchased for in years
 * @param averageMonthlyRecurringCost Average Monthly Recurring Cost
 * @returns sub total due amount calculated
 */
export const getEc2SubTotalDue = (
  term: number,
  averageMonthlyRecurringCost: string | number | undefined
) => {
  if (averageMonthlyRecurringCost && term) {
    return Number(averageMonthlyRecurringCost) * term * 12;
  } else {
    return 0;
  }
};

/**
 * @function getEc2RiTotalDue
 * @description Function to calculate the total due for the cart
 * @param cartData cart data
 * @returns total due amount calculated
 */
export const getEc2RiTotalDue = (cartData: PurchaseAwsEc2RisType[]) => {
  return cartData.reduce(
    (sum, item) =>
      sum + getEc2SubTotalDue(item.term, item.averageMonthlyRecurringCost),
    0
  );
};

/**
 * @function getEc2RiTotalMonthlyRecurringCost
 * @description Function to calculate the total monthly recurring cost for the cart
 * @param cartData cart data
 * @returns total monthly recurring cost calculated
 */
export const getEc2RiTotalMonthlyRecurringCost = (
  cartData: PurchaseAwsEc2RisType[]
) => {
  return cartData.reduce(
    (sum, item) => sum + item.averageMonthlyRecurringCost,
    0
  );
};
