import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchAllocationTrendData
 * @description Function to fetch the allocation trend data
 * @param params Request parameters
 * @return axios response from GET request
 */
export const fetchAllocationTrendData = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALLOCATION_TREND_DATA, null, params);
};

/**
 * @function fetchBUAllocationByMonths
 * @description Function to fetch the allocation by business units for months given
 * @param params Request parameters
 * @return axios response from GET request
 */
export const fetchBUAllocationByMonths = async (params: any) => {
  return ServiceCalls.get(APIS.ALLOCATION_BY_BU, null, params);
};

/**
 * @function fetchCSCAllocationByMonthsAndBU
 * @description Function to fetch the allocation by cost center code for months and business units given
 * @param params Request parameters
 * @return axios response from GET request
 */
export const fetchCSCAllocationByMonthsAndBU = async (params: any) => {
  return ServiceCalls.get(APIS.ALLOCATION_BY_COST_CENTER_CODE, null, params);
};

/**
 * @function fetchAllAllocations
 * @description Function to fetch all the allocations
 * @param params Request parameters
 * @return axios response from GET request
 */
export const fetchAllAllocations = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_ALLOCATIONS, null, params);
};
