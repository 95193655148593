import { Upload, message } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';

type UploadFileProps = {
  isFileUploaded: boolean;
  filename: string;
  buttonTitle: string;
  onSelectFile: (value: File, onSuccess: any, onError: any) => void;
  onRemoveFile: () => void;
  accept?: string;
};

const UploadFile = ({
  isFileUploaded,
  filename,
  buttonTitle,
  onSelectFile,
  onRemoveFile,
  accept,
}: UploadFileProps) => {
  const { t } = useTranslation();

  /**
   * @function onHandleChange
   * @description Function to handle change events of uploading file.
   * @param info : info will give the values of uploaded file
   */
  const onHandleChange = (info: any) => {
    if (info.file.status === 'done') {
      message.success(t('fileUploadSuccessfullyMessage'));
    } else if (info.file.status === 'error') {
      message.error(t('fileUploadFailedMessage'));
    }
  };

  /**
   * @function handleSelectFile
   * @description Function to handle the file upload.
   * @param options : options give the details of file
   */
  const handleSelectFile = (options: any) => {
    const { onSuccess, onError, file } = options;
    onSelectFile(file, onSuccess, onError);
  };

  return (
    <div>
      <Upload
        name="file"
        fileList={
          isFileUploaded || filename
            ? [
                {
                  uid: '1',
                  status: 'done',
                  name: filename,
                },
              ]
            : undefined
        }
        accept={accept}
        onChange={onHandleChange}
        multiple={false}
        customRequest={handleSelectFile}
        onRemove={onRemoveFile}
        maxCount={1}
        showUploadList={{
          showRemoveIcon: true,
          removeIcon: <Icon iconName={ICONS.CLOSE_FILL} />,
        }}
        className={`${(isFileUploaded || filename) && 'upload-disabled'}`}
      >
        <Button
          title={
            <div className="flex flex-gap-4">
              {buttonTitle}
              <Icon iconName={ICONS.UPLOAD_2_LINE} size={ICONS_SIZE.SM} />
            </div>
          }
          type={BUTTON_TYPE.NEUTRAL}
          size={BUTTON_SIZE.SMALL}
          disabled={isFileUploaded || filename}
        />
      </Upload>
    </div>
  );
};

export default UploadFile;
