import Icon from 'components/Icon';
import { ICONS_SIZE } from 'constants/icons';

import { getIconBySpendType } from './utils';
import { ProfileSpendTypes } from '../../constants';
import { getClassNameBySpendName } from '../../utils';

import './index.scss';

type SpendProfileIconProps = {
  spendType: ProfileSpendTypes;
  size?: 'medium' | 'small';
};

const SpendProfileIcon = ({
  spendType,
  size = 'medium',
}: SpendProfileIconProps) => {
  return (
    <div
      className={`spend-profile-icon ${getClassNameBySpendName(spendType)}`}
      data-testid="spend-profile-icon"
    >
      {spendType === ProfileSpendTypes.CONSTANT_SPEND ? (
        <div
          className={`constant-spend-icon ${size}`}
          data-testid="constant-spend-icon"
        />
      ) : (
        <Icon
          iconName={getIconBySpendType(spendType)}
          size={size === 'medium' ? ICONS_SIZE.XL : ICONS_SIZE.SM}
          dataTestId="spend-icon"
        />
      )}
    </div>
  );
};

export default SpendProfileIcon;
