import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import GraphHeader from 'components/GraphHeader';
import ColumnChart from 'components/ColumnChart';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { selectDashboard } from 'redux/dashboardSlice';
import { CostByServiceType } from 'types/dataTypes';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { DATE_FORMAT } from 'utils/date';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { CostByAwsServiceColumns } from '../../constants';

type CostByAWSServiceProps = {
  costByServiceValues: CostByServiceType[];
  selectedCostByServiceData: CostByServiceType[];
  setSelectedCostByServiceData: (val: CostByServiceType[]) => void;
  serviceGraphStartDate: string;
  serviceGraphEndDate: string;
  onChangeServiceDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  requestStatus: string;
  setIsCostByServiceTableView: (val: boolean) => void;
  isCostByServiceTableView: boolean;
  pdfView: boolean;
};

const CostByAWSService = ({
  costByServiceValues,
  selectedCostByServiceData,
  setSelectedCostByServiceData,
  serviceGraphStartDate,
  serviceGraphEndDate,
  onChangeServiceDateRange,
  requestStatus,
  setIsCostByServiceTableView,
  isCostByServiceTableView,
  pdfView,
}: CostByAWSServiceProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const filters = (
    <div className="filters flex flex-align-items-center full-width">
      <DatePicker
        defaultValue={[
          moment(serviceGraphStartDate),
          moment(serviceGraphEndDate),
        ]}
        onChange={onChangeServiceDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        format={DATE_FORMAT}
        designVersion2
      />
      <GraphFilterDropdown
        allData={costByServiceValues}
        selectedData={selectedCostByServiceData}
        setSelectedData={setSelectedCostByServiceData}
        valueSuffix={t('ytdCostByGCPService.services')}
        fieldName={DROPDOWN_VALUE_FIELDS.SERVICE}
        designVersion2
      />
    </div>
  );

  const getComponent = () => {
    return (
      <div
        className={`graph ${isCostByServiceTableView && 'flex flex-gap-16'}`}
      >
        <div
          className={`graph-area ${
            isCostByServiceTableView && 'with-table expand-hide'
          }`}
        >
          <ColumnChart
            data={selectedCostByServiceData}
            xField="service"
            yField="cost"
            groupingField="service"
            xTitle={t('costByAwsService.services')}
            yTitle={t('costByAwsService.costInCurrency', { currencySymbol })}
            prefixSymbol={currencySymbol}
          />
        </div>
        {isCostByServiceTableView && (
          <div className="tabular-view width-30">
            <Table
              pagination={false}
              loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
              dataSource={selectedCostByServiceData.map((value, index) => {
                return { ...value, key: index };
              })}
              columns={CostByAwsServiceColumns}
              scroll={{ y: 190 }}
              designVersion2
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="cost-by-aws-service graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.costByAwsService')}
        graphName="cost-by-aws-service"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostByServiceTableView}
        setIsTableView={setIsCostByServiceTableView}
        filters={filters}
        designVersion2
        excelData={getChartExcelExportData(
          t('graphHeadings.costByAwsService'),
          selectedCostByServiceData,
          CostByAwsServiceColumns,
          {
            connectionName: selectedDashboard?.connectorName,
          }
        )}
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray([requestStatus])}
      />
    </div>
  );
};

export default CostByAWSService;
