import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LineChart from 'components/LineChart';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { selectDashboard } from 'redux/dashboardSlice';
import { MonthlyCostByNameType } from 'types/dataTypes';
import { getMonthYearShortList, MONTH_YEAR_FORMAT } from 'utils/date';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';

import { getAwsCostTimeLineExcelData } from '../../utils';

type CostTimeLineProps = {
  title: string;
  graph: string;
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  startDate: string;
  endDate: string;
  selectedAccounts: string[];
  timeLineData: MonthlyCostByNameType[];
  tableData: any;
  pdfView: boolean;
};
const CostTimeLine = ({
  title,
  graph,
  requestStatus,
  isTableView,
  setIsTableView,
  startDate,
  endDate,
  selectedAccounts,
  timeLineData,
  tableData,
  pdfView,
}: CostTimeLineProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: t(
        `accountDeepDiveSummary.${
          graph === 'service-time-line' ? 'services' : 'accounts'
        }`
      ),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      fixed: true,
    },
    ...getMonthYearShortList(startDate, endDate, MONTH_YEAR_FORMAT).map(
      (month) => ({
        title: month,
        key: `${month}-cost`,
        dataIndex: `${month}-cost`,
        width: 100,
      })
    ),
  ];

  const getComponent = () =>
    isTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={tableData}
          columns={columns}
          scroll={{ y: 230 }}
          designVersion2
        />
      </div>
    ) : (
      <LineChart
        data={timeLineData}
        xField="month"
        yField="cost"
        groupingField="name"
        xTitle={t('accountDeepDiveSummary.month')}
        yTitle={t('accountDeepDiveSummary.cost', {
          currencySymbol,
        })}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
        disableAnimation={pdfView}
      />
    );

  return (
    <div
      className={`account-deep-dive-graph graph-card ${graph}`}
      id="graph-container"
    >
      <GraphHeader
        heading={title}
        graphName={graph}
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        excelData={getAwsCostTimeLineExcelData(
          selectedDashboard?.connectorName ?? '',
          tableData,
          graph,
          startDate,
          endDate,
          selectedAccounts
        )}
        designVersion2
      />
      <div className="graph no-filters">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default CostTimeLine;
