import { PropsWithChildren, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import jwt_decode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';

import { REQUEST_STATUS } from 'constants/requestBody';
import { fetchAuthToken } from 'utils/services';
import {
  setAuthorities,
  setIsServerError,
  setUserAuthorization,
  userAuthorization,
} from 'redux/authorizationSlice';
import { onApiCallError } from 'utils/handleErrors';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';

import TimeoutModal from './components/TimeoutModal';

const IdleTimer = ({ children }: Readonly<PropsWithChildren<any>>) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { azureADAccessToken, apiCallsInProgress } =
    useSelector(userAuthorization);

  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [tokenRefreshStatus, setTokenRefreshStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    let refreshTokenInterval: any;
    if (!showTimeoutModal) {
      refreshTokenInterval = setInterval(
        onRefreshToken,
        Number(process.env.REACT_APP_TOKEN_REFRESH_TIME)
      );
    }

    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, [showTimeoutModal]);

  useEffect(() => {
    if (apiCallsInProgress !== 0) {
      reset();
    }
  }, [apiCallsInProgress]);

  const { reset } = useIdleTimer({
    timeout: Number(process.env.REACT_APP_USER_IDLE_TIME),
    onIdle: () => setShowTimeoutModal(true),
    debounce: 1000,
  });

  /**
   * @function onTokenRefresh
   * @description Callback function for post token refresh
   */
  const onTokenRefresh = () => {
    setShowTimeoutModal(false);
  };

  /**
   * @function onClickStayLoggedIn
   * @description Callback function for clicking stay logged in cta
   */
  const onClickStayLoggedIn = () => {
    onRefreshToken(setTokenRefreshStatus, onTokenRefresh);
  };

  /**
   * @function onRefreshToken
   * @description Function to refresh the token
   * @param setApiCallStatus Callback function to set the api call status
   * @param onApiComplete Callback function post api call is complete for both success and failure
   */
  const onRefreshToken = (
    setApiCallStatus?: (val: string) => void,
    onApiComplete?: Function
  ) => {
    setApiCallStatus?.(REQUEST_STATUS.PROCESSING);
    fetchAuthToken(azureADAccessToken)
      .then((res: any) => {
        if (res.status === 200) {
          const { data } = res;
          const { accessToken } = data;
          const tokenData: any = jwt_decode(accessToken);
          dispatch(setUserAuthorization(true));
          dispatch(setIsServerError(false));
          dispatch(setAuthorities(tokenData.authorities));
          localStorage.setItem('name', tokenData.name);
          localStorage.setItem('email', tokenData.sub);
          localStorage.setItem('role', tokenData.role);
          localStorage.setItem('expiresAt', tokenData.exp);
          localStorage.setItem('issuedAt', tokenData.iat);
          return;
        }

        dispatch(setUserAuthorization(false));
        dispatch(setIsServerError(true));
      })
      .catch((error) => {
        if ([404, 401].includes(error.response.status)) {
          dispatch(setIsServerError(false));
          navigate(NAVIGATION_MENU_PATH.LOGIN);
        } else {
          dispatch(setIsServerError(true));
        }
        dispatch(setUserAuthorization(false));
        onApiCallError(error);
      })
      .finally(() => {
        onApiComplete?.();
        setApiCallStatus?.(REQUEST_STATUS.SUCCESS);
      });
  };

  return (
    <div className="stretch flex">
      {children}
      <TimeoutModal
        show={showTimeoutModal}
        onClickStayLoggedIn={onClickStayLoggedIn}
        tokenRefreshStatus={tokenRefreshStatus}
      />
    </div>
  );
};

export default IdleTimer;
