import { SchemaDefinitionType } from './types';

/**
 * @function downloadSchema
 * @description Function to download the schema in json file
 * @param schema Schema to be downloaded
 * @param fileName Optional fileName to be used for the json file downloaded. Defaults to schema.json
 */
export const downloadSchema = (
  schema: SchemaDefinitionType[] | string,
  fileName?: string
) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    typeof schema === 'string' ? schema : JSON.stringify(schema)
  )}`;
  const link = document.createElement('a');
  link.href = jsonString;
  link.download = fileName ? `${fileName}.json` : 'schema.json';
  link.click();
};
