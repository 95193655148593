import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Skeleton, Radio, Space } from 'antd';
import moment from 'moment';

import Button from 'components/Button';
import DrawerComponent from 'components/DrawerComponent';
import Input from 'components/Input';
import { BUTTON_TYPE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  selectGProfilerInsights,
  setSelectedService,
} from 'redux/gProfilerInsights';
import { ConnectionList } from 'types/gProfiler';
import { SLASH_DATE_FORMAT } from 'utils/date';
import { getProviderSmallLogo } from 'utils/providerDetails';

import { ImpactCategoryLabels } from '../../constants';

import './index.scss';

type ServiceListModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
};

const ServiceListModal = ({ show, setShow }: ServiceListModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gProfilerServices, gProfilerServicesReqStatus, selectedService } =
    useSelector(selectGProfilerInsights);

  const [selectedDashboard, setSelectedDashboard] = useState<ConnectionList>();
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    setSelectedDashboard(selectedService);
  }, [selectedService]);

  const ServiceList = (
    <div className="service-list">
      {gProfilerServices.length > 0 ? (
        <Radio.Group
          onChange={(e) => setSelectedDashboard(e.target.value)}
          value={selectedDashboard}
        >
          <Space direction="vertical">
            {gProfilerServices
              .filter(
                (item) =>
                  item.connectionName
                    .toLowerCase()
                    .includes(searchKey.toLowerCase()) ||
                  item.serviceName
                    .toLowerCase()
                    .includes(searchKey.toLowerCase())
              )
              .map((item) => (
                <Radio
                  key={`${item.connectionName}-${item.serviceName}`}
                  className="cursor-pointer"
                  value={item}
                >
                  <div className="service-list-item flex flex-align-items-center flex-space-between">
                    <div className="flex flex-align-items-center flex-gap-12">
                      <img
                        src={getProviderSmallLogo(item.provider)}
                        alt={`${item.provider}-logo`}
                        className="provider-logo"
                      />
                      <div className="font-button service-name">
                        <div>{item.serviceName}</div>
                        <div className="flex flex-align-items-center flex-gap-4">
                          <span className="connection-name font-caption-bold">
                            {item.connectionName}
                          </span>
                          {item.optimizationPotential && (
                            <span className="optimization-potential font-caption-bold">
                              {
                                ImpactCategoryLabels.find(
                                  (potential) =>
                                    potential.key === item.optimizationPotential
                                )?.label
                              }
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <span className="create-date font-caption-bold">
                      {moment(item.createdDate).calendar({
                        lastDay: '[Yesterday]',
                        sameDay: '[Today]',
                        nextDay: '[Tomorrow]',
                        lastWeek: '[Last] dddd',
                        nextWeek: '[Next] dddd',
                        sameElse: SLASH_DATE_FORMAT,
                      })}
                    </span>
                  </div>
                  <Divider />
                </Radio>
              ))}
          </Space>
        </Radio.Group>
      ) : (
        <>{t('gprofilerInsightsDashboard.emptyConnections')}</>
      )}
    </div>
  );

  const getComponent = () => {
    switch (gProfilerServicesReqStatus) {
      case REQUEST_STATUS.PROCESSING:
        return (
          <>
            <Skeleton avatar paragraph={{ rows: 2 }} />
            <Skeleton avatar paragraph={{ rows: 2 }} />
          </>
        );
      case REQUEST_STATUS.ERROR:
        return <>{t('apiCallErrorMessage')}</>;
      case REQUEST_STATUS.SUCCESS:
        return ServiceList;
    }
  };

  return (
    <DrawerComponent
      className="gprofiler-service-list"
      width={486}
      open={show}
      title={
        <header className="flex flex-align-items-center flex-space-between">
          <span>{t('gprofilerInsightsDashboard.allDashboards')}</span>
          <Input
            className="search-input"
            type="search"
            value={searchKey}
            onChange={(e: any) => setSearchKey(e.target.value)}
            placeholder={t('gprofilerInsightsDashboard.searchByName')}
          />
        </header>
      }
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-align-item-center flex-end">
          <Button
            title={t('gprofilerInsightsDashboard.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={t('gprofilerInsightsDashboard.apply')}
            onClick={() => {
              dispatch(setSelectedService(selectedDashboard));
              setShow(false);
            }}
          />
        </div>
      }
    >
      <section className="flex flex-column flex-gap-24">
        {getComponent()}
      </section>
    </DrawerComponent>
  );
};

export default ServiceListModal;
