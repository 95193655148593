import i18n from 'i18n';
import { DateRangePresetOptions } from 'components/DatePicker/constants';

export enum CreateReportsPageComponent {
  CANVAS = 'CANVAS',
  SUCCESS_COMPONENT = 'SUCCESS_COMPONENT',
  ERROR_COMPONENT = 'ERROR_COMPONENT',
}

export enum ReportsViewType {
  EDIT = 'EDIT',
  PREVIEW = 'PREVIEW',
  OPEN = 'OPEN',
}

export enum ReportsDataSourceNavs {
  DATASOURCE = 'DATASOURCE',
  TABLE = 'TABLE',
  CHART = 'CHART',
}

export const REPORTS_DATASOURCE_NAV_LIST = [
  {
    key: ReportsDataSourceNavs.DATASOURCE,
    label: i18n.t('reports.datasource'),
  },
  {
    key: ReportsDataSourceNavs.TABLE,
    label: i18n.t('reports.table'),
  },
  {
    key: ReportsDataSourceNavs.CHART,
    label: i18n.t('reports.chart'),
  },
];

export enum ReportSharePermissions {
  READ = 'READ',
  WRITE = 'WRITE',
}

export enum DateRanges {
  THIRTY = 'THIRTY',
  SEVEN = 'SEVEN',
  NINETY = 'NINTY',
  CUSTOM = 'CUSTOM',
}

export const ReportsDateRangesToPresetValuesMap = [
  {
    dateRange: DateRanges.SEVEN,
    presetValue: DateRangePresetOptions.LAST_7_DAYS,
  },
  {
    dateRange: DateRanges.THIRTY,
    presetValue: DateRangePresetOptions.LAST_30_DAYS,
  },
  {
    dateRange: DateRanges.NINETY,
    presetValue: DateRangePresetOptions.LAST_90_DAYS,
  },
  {
    dateRange: DateRanges.CUSTOM,
    presetValue: DateRangePresetOptions.CUSTOM,
  },
];
