import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChatbotProvider,
  ChatbotProviderListType,
  ChatType,
} from 'components/ChatBoxModal/types';
import { ConnectionListType } from 'types/dashboard';
import { RootState } from './store';

export interface ChatBotSlice {
  isChatBoxOpen: boolean;
  chats: ChatType[];
  connections: ConnectionListType[];
  selectedConnection: ConnectionListType | null;
  isNewSession: boolean;
  allChatbotProviders: ChatbotProviderListType[];
  selectedChatbotProvider: ChatbotProvider | null;
}

const initialState: ChatBotSlice = {
  isChatBoxOpen: false,
  chats: [],
  connections: [],
  selectedConnection: null,
  isNewSession: true,
  allChatbotProviders: [],
  selectedChatbotProvider: null,
};

export const chatBotSlice = createSlice({
  name: 'chatBot',
  initialState,
  reducers: {
    setIsChatBoxOpen: (state, action: PayloadAction<boolean>) => {
      state.isChatBoxOpen = action.payload;
    },
    setChats: (state, action: PayloadAction<ChatType[]>) => {
      state.chats = action.payload;
    },
    setConnections: (state, action: PayloadAction<ConnectionListType[]>) => {
      state.connections = action.payload;
    },
    setSelectedConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.selectedConnection = action.payload;
    },
    setIsNewSession: (state, action: PayloadAction<boolean>) => {
      state.isNewSession = action.payload;
    },
    setAllChatbotProviders: (
      state,
      action: PayloadAction<ChatbotProviderListType[]>
    ) => {
      state.allChatbotProviders = action.payload;
    },
    setSelectedChatbotProvider: (
      state,
      action: PayloadAction<ChatbotProvider | null>
    ) => {
      state.selectedChatbotProvider = action.payload;
    },
  },
});

export const selectChatBot = (state: RootState) => state.chatBot;
export const {
  setIsChatBoxOpen,
  setChats,
  setConnections,
  setSelectedConnection,
  setIsNewSession,
  setAllChatbotProviders,
  setSelectedChatbotProvider,
} = chatBotSlice.actions;
export default chatBotSlice.reducer;
