import { message } from 'antd';
import i18n from 'i18n';
import axios from 'axios';
import moment from 'moment';
import { uniqBy } from 'lodash';

import {
  getGcpDefaultRecommendationCountQuery,
  getGcpRecommendationTableDefaultQuery,
  getGcpRulesetFilterRecommendationCountQuery,
  getGcpRulesetFilterRecommendationQuery,
} from 'pages/CostOptimizationInsightsPage/components/GCP/RecommendationTable/utils';
import {
  CategoryRecommenderMappingType,
  ConsolidatedStatusFilterType,
  IncidentRecommendationMappingType,
  RecommendationFiltersType,
  ViewListType,
} from 'pages/CostOptimizationInsightsPage/types';
import {
  DEFAULT_VIEW,
  FilterKeys,
  RecommendationStatus,
  RecommendationSource,
  GranulateRecommenderSubTypes,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  applyGCPRecommendations,
  getApplicableGCPRecommendations,
  getGcpCategoryRecommenderMapping,
} from 'pages/CostOptimizationInsightsPage/components/GCP/RecommendationTable/services';
import {
  addIndexAndCommaSeparator,
  hasRulesetFilters,
} from 'pages/CostOptimizationInsightsPage/utils';
import {
  applyAzureRecommendations,
  getAzureCategoryRecommenderMapping,
} from 'pages/CostOptimizationInsightsPage/components/Azure/RecommendationTable/services';
import {
  getAzureDefaultRecommendationCountQuery,
  getAzureRecommendationTableDefaultQuery,
  getAzureRulesetFilterRecommendationCountQuery,
  getAzureRulesetFilterRecommendationQuery,
} from 'pages/CostOptimizationInsightsPage/components/Azure/RecommendationTable/utils';
import { getRulesetFilteredRequestBodyForAwsCheckId } from 'pages/CostOptimizationInsightsPage/components/AWS/RecommendationTable/utils';
import {
  applyAWSRecommendations,
  getApplicableAWSRecommendations,
} from 'pages/CostOptimizationInsightsPage/components/AWS/RecommendationTable/services';
import {
  getAWSCategoryMapping,
  getChartData,
  getFilteredRecommendations,
  getRulesetFilters,
} from 'utils/services';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { onApiCallError, useNull } from 'utils/handleErrors';
import {
  ConnectionListType,
  FilterGroupType,
  ProjectNumberIdMappingType,
} from 'types/dashboard';
import { INFINITE_SCROLL_PAGE_SIZE } from 'constants/userConsole';
import {
  SERVICE_NOW_STATUS_LABELS,
  ServiceNowStatus,
  TICKET_RECOMMENDATION_STATUS,
} from 'constants/workflowIntegrations';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { UNKNOWN_VALUE } from 'constants/utilityConstants';
import {
  DATE_TIME_AM_PM,
  dateToday,
  HYPHEN_DATE_FORMAT,
  HYPHEN_DATE_TIME_HH_MM_SS,
} from 'utils/date';
import { PROVIDER } from 'constants/cloudProviders';
import { getProviderForConnection } from 'utils/dashboardUtils';
import { RulesetFilterGroupsType } from 'types/dataTypes';
import { ContainerType, HPAType } from 'types/GranulateRecommendation';
import {
  AWS_CHECKS_MAP,
  AWS_CHECK_IDS,
  CHECK_NAME_ID_MAP,
} from 'constants/recommendations';
import {
  convertContainerStringToArrayOfObject,
  convertHpaStringToObject,
  fetchCPUMemoryCostImpact,
  getRequestBodyObject,
} from 'pages/CostOptimizationInsightsPage/components/Granulate/RecommendationPage/utils';
import { SERVICE_NOW_STATUS } from 'pages/CostOptimizationInsightsPage/components/Granulate/RecommendationPage/constants';

import { RecommendationList } from '../../types';
import {
  CpuMemoryCostImpactType,
  GranulateConnectionsType,
  GranulateRecommendationFilterQueryType,
} from './types';
import {
  getGranulateConnectionsForConnector,
  getGranulateRecommendationsForConnector,
} from './services';
import {
  AWS_EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER_FIELDS,
  AWS_EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER_FIELDS,
  AWS_LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT_FIELDS,
  AWS_LAMBDA_FUNCTIONS_HIGH_ERROR_RATES_FIELDS,
  AWS_LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY_FIELDS,
  AWS_OVERPROVISIONED_EBS_VOLUMES_FIELDS,
  AWS_RIGHT_SIZING_EC2_FIELDS,
  AWS_ROUTE_53_LATENCY_RECORD_SETS_FIELDS,
  AWS_UNDERUTILIZED_ENDPOINTS_FIELD,
  AWS_WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR_FIELDS,
  AZURE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS,
  CONSUMPTION_MANAGEMENT_COMMON_RECOMMENDATION_FIELDS,
  GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS,
  GRANULATE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS,
} from '../RecommendationDetailsModal/constants';

/**
 * @function getAllGcpRecommenderMapping
 * @description Function to fetch all the category to recommenders mapping for GCP.
 * @param setData Callback to set the data
 * @param setRequestStatus Callback to set the request status
 */
const getAllGcpRecommenderMapping = (
  setData: (val: CategoryRecommenderMappingType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  getGcpCategoryRecommenderMapping()
    .then((res: any) => {
      if (res.status === 200) {
        setData(res.data.responseData);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      setRequestStatus(REQUEST_STATUS.ERROR);
    })
    .catch((e) => {
      onApiCallError(e, true, setRequestStatus);
    });
};

/**
 * @function getAllAwsRecommenderMapping
 * @description Function to fetch the aws check ids for each category for AWS.
 * @param setData Callback to set the data
 * @param setRequestStatus Callback to set the request status
 */
const getAllAwsRecommenderMapping = (
  setData: (val: CategoryRecommenderMappingType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  getAWSCategoryMapping()
    .then((res: any) => {
      if (res.status === 200) {
        setData(res.data.responseData);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      setData([]);
      setRequestStatus(REQUEST_STATUS.ERROR);
    })
    .catch((e) => {
      setData([]);
      onApiCallError(e, true, setRequestStatus);
    });
};

/**
 * @function getAllAzureRecommenderMapping
 * @description Function to fetch all the category to recommenders mapping for Azure.
 * @param setData Callback to set the data
 * @param setRequestStatus Callback to set the request status
 */
const getAllAzureRecommenderMapping = (
  setData: (val: CategoryRecommenderMappingType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  getAzureCategoryRecommenderMapping()
    .then((res: any) => {
      if (res.status === 200) {
        setData(res.data.responseData);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      setData([]);
      setRequestStatus(REQUEST_STATUS.ERROR);
    })
    .catch((e) => {
      setData([]);
      onApiCallError(e, true, setRequestStatus);
    });
};

/**
 * @function getAllRecommenderMapping
 * @description Function to fetch all the category to recommender mappings by provider.
 * @param provider provider fpr which the recommender map is fetched
 * @param setData Callback to set the data
 * @param setRequestStatus Callback to set the request status
 */
export const getAllRecommenderMapping = (
  provider: string,
  setData: (val: CategoryRecommenderMappingType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getAllGcpRecommenderMapping(setData, setRequestStatus);

    case PROVIDER.AWS:
      return getAllAwsRecommenderMapping(setData, setRequestStatus);

    case PROVIDER.AZURE:
      return getAllAzureRecommenderMapping(setData, setRequestStatus);
  }
};

/**
 * @function fetchGcpApplicableRecommenders
 * @description Function to fetch all the applicable recommenders for GCP
 * @param setData Callback to set the data
 */
const fetchGcpApplicableRecommenders = (setData: (val: string[]) => void) => {
  getApplicableGCPRecommendations()
    .then((res: any) => {
      if (res.status === 200) {
        setData(res.data.responseData);
        return;
      }

      setData([]);
    })

    .catch((e) => {
      onApiCallError(e, false);
      setData([]);
    });
};

/**
 * @function fetchAwsApplicableRecommenders
 * @description Function to fetch all the applicable recommenders for AWS
 * @param setData Callback to set the data
 */
const fetchAwsApplicableRecommenders = (setData: (val: string[]) => void) => {
  getApplicableAWSRecommendations()
    .then((res: any) => {
      if (res.status === 200) {
        setData(res.data.responseData);
        return;
      }

      setData([]);
    })

    .catch((e) => {
      onApiCallError(e, false);
      setData([]);
    });
};

/**
 * @function fetchApplicableRecommenders
 * @description Function to fetch all the applicable recommenders by provider
 * @param provider provider for which the recommenders are fetched
 * @param setData Callback to set the data
 */
export const fetchApplicableRecommenders = (
  provider: string,
  setData: (val: string[]) => void
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return fetchGcpApplicableRecommenders(setData);

    case PROVIDER.AWS:
      return fetchAwsApplicableRecommenders(setData);

    case PROVIDER.AZURE:
      return setData([]);
  }
};

/**
 * @function getRulesetFilterGroups
 * @description Function to return the ruleset filters by responses
 * @param responses responses from which the ruleset filters need to be parsed
 * @returns List of filter groups
 */
const getRulesetFilterGroups = (responses: any[]) => {
  const filters: FilterGroupType[] = [];
  responses?.forEach((item: any) => {
    filters.push(...item.data.responseData);
  });

  return [{ filterGroups: filters }];
};

/**
 * @function getAWSRulesetFilterGroups
 * @description Function to return the ruleset filters by responses for AWS
 * @param responses responses from which the ruleset filters need to be parsed
 * @returns List of filter groups
 */
const getAWSRulesetFilterGroups = (responses: any[]) => {
  const filters: RulesetFilterGroupsType[] = [];
  responses?.forEach((item: any) => {
    filters.push(...item.data.responseData);
  });

  return filters;
};

/**
 * @function getRulesetViewFilters
 * @description Function to fetch the ruleset filters
 * @param provider Cloud provider for which the rulesets are fetched
 * @param setData Callback to set the data
 * @param setRequestStatus Callback to set the request status
 * @param viewList List of views
 * @param currentRecommendationView selected recommendation view
 */
export const getRulesetViewFilters = (
  provider: string,
  setData: (val: RulesetFilterGroupsType[]) => void,
  setRequestStatus: (val: string) => void,
  viewList: ViewListType[],
  currentRecommendationView: ViewListType
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const rulesetIds = viewList?.some(
    (view) => view.key === currentRecommendationView.key
  )
    ? currentRecommendationView.recommendationsFilterDtoList
        .find((item) => item.key === FilterKeys.RULESET)
        ?.values?.map((item) => item.value)
    : [currentRecommendationView.key];

  axios
    .all(
      (rulesetIds ?? []).map((rulesetId) =>
        getRulesetFilters({ rulesetId: rulesetId })
      )
    )
    .then((res) => {
      let filters: RulesetFilterGroupsType[] =
        provider === PROVIDER.AWS
          ? getAWSRulesetFilterGroups(res)
          : getRulesetFilterGroups(res);

      setData(filters);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      setData([]);
      onApiCallError(e, true, setRequestStatus);
    });
};

/**
 * @function getRecommendationDataDefaultQuery
 * @description Function to return the recommendation data query by provider
 * @param provider Cloud provider for which the query is fetched
 * @param currentRecommendationView selected recommendation view
 * @param categoryRecommenderMapping Category to recommenders mapping
 * @param selectedCostOptimizationInsightsNav Selected category
 * @returns Query for recommendation Data
 */
const getRecommendationDataDefaultQuery = (
  provider: string,
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGcpRecommendationTableDefaultQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav
      );

    case PROVIDER.AZURE:
      return getAzureRecommendationTableDefaultQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav
      );
  }
};

/**
 * @function getRulesetFilterRecommendationDataQuery
 * @description Function to return the recommendation data query by provider for ruleset filters
 * @param provider Cloud provider for which the query is fetched
 * @param currentRecommendationView selected recommendation view
 * @param categoryRecommenderMapping Category to recommenders mapping
 * @param selectedCostOptimizationInsightsNav Selected category
 * @param rulesetFilters ruleset filters to be applied in the query
 * @returns Query for recommendation Data with ruleset filters
 */
const getRulesetFilterRecommendationDataQuery = (
  provider: string,
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string,
  rulesetFilters: RulesetFilterGroupsType[]
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGcpRulesetFilterRecommendationQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav,
        rulesetFilters[0]?.filterGroups
      );

    case PROVIDER.AZURE:
      return getAzureRulesetFilterRecommendationQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav,
        rulesetFilters[0].filterGroups
      );
  }
};

/**
 * @function getRecommendationQuery
 * @description Function to return the recommendation data query by provider
 * @param hasRulesetFilters boolean to indicated whether ruleset filters are present or not
 * @param provider Cloud provider for which the query is fetched
 * @param currentRecommendationView selected recommendation view
 * @param categoryRecommenderMapping Category to recommenders mapping
 * @param selectedCostOptimizationInsightsNav Selected category
 * @param rulesetFilters ruleset filters to be applied in the query
 * @returns Query for recommendation Data with ruleset filters
 */
const getRecommendationQuery = (
  hasRulesetFilters: boolean,
  provider: string,
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string,
  rulesetFilters: RulesetFilterGroupsType[]
) => {
  return hasRulesetFilters
    ? getRulesetFilterRecommendationDataQuery(
        provider,
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav,
        rulesetFilters
      )
    : getRecommendationDataDefaultQuery(
        provider,
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav
      );
};

/**
 * @function getAwsResourceFieldByCheckId
 * @description Function to return the resource based on the checkId and the data provided
 * @param checkId CheckId for which the resource is fetched
 * @param data Data from which the resource id is fetched
 * @returns Resource name
 */
const getAwsResourceFieldByCheckId = (checkId: string, data: any) => {
  switch (checkId) {
    case AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS:
      return data.endpointArn;

    case AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES:
      return data.volumeId;

    case AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER:
    case AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER:
      return data.instanceId;

    case AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS:
      return data.hostedZoneName;

    case AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT:
    case AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES:
      return data.functionArn;

    case AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY:
      return data.functionName;

    case AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR:
      return data.workloadName;

    case AWS_CHECK_IDS.RIGHT_SIZING_EC2:
      return data.instanceName;
  }
};

/**
 * @function fetchAwsRecommendationData
 * @description Function to fetch the recommendation data for AWS
 * @param checkIds List of checkIds for which the data is fetched
 * @param setData Callback to set the fetched data
 * @param setRequestStatus Callback to set the request status
 * @param setCurrentPage Callback to set the current page for pagination
 * @param currentRecommendationView Recommendation view selected
 * @param rulesetFilters ruleset filters to be applied in the query
 * @param params Params to be added for requests
 */
const fetchAwsRecommendationData = (
  checkIds: string[],
  setData: (val: RecommendationList[]) => void,
  setRequestStatus: (val: string) => void,
  setCurrentPage: (val: number) => void,
  currentRecommendationView: ViewListType,
  rulesetFilters: RulesetFilterGroupsType[],
  params: Object
) => {
  const requests: any[] = [];
  checkIds.forEach((checkId) => {
    const requestBody = getRulesetFilteredRequestBodyForAwsCheckId(
      checkId,
      currentRecommendationView,
      rulesetFilters
    );

    requests.push(
      getFilteredRecommendations(requestBody, params).catch(useNull)
    );
  });

  axios
    .all(requests)
    .then((responses: any[]) => {
      const data: RecommendationList[] = [];
      let hasMoreData = false;

      responses.forEach((res) => {
        const checkId = JSON.parse(res.config.data).checkId;
        data.push(
          ...res.data.map((item: any) => ({
            ...item,
            recommendationId: item[getAwsUniqueKey(checkId)],
            checkId: checkId,
            recommendationSource: RecommendationSource.CSP,
            recommendation: CHECK_NAME_ID_MAP.find(
              (check) => check.id === checkId
            )?.name,
            timestamp: item.generationTime,
            resource: getAwsResourceFieldByCheckId(checkId, item),
            accountName: item.accountName,
            region: item.region,
            provisionedInferenceUnit: item.provisionedInferenceUnit,
            autoScalingStatus: item.autoScalingStatus,
            reason: item.reason,
            risk: item.risk,
            priority: item.priority,
            volumeType: item.volumeType,
            volumeSizeGb: item.volumeSizeGb,
            volumeBaselineIops: item.volumeBaselineIops,
            volumeBurstIops: item.volumeBurstIops,
            volumeBurstThroughput: item.volumeBurstThroughput,
            recommendedVolumeType: item.recommendedVolumeType,
            recommendedVolumeSizeGb: item.recommendedVolumeSizeGb,
            recommendedVolumeBaselineIops: item.recommendedVolumeBaselineIops,
            recommendedVolumeBurstIops: item.recommendedVolumeBurstIops,
            recommendedVolumeBaselineThroughput:
              item.recommendedVolumeBaselineThroughput,
            recommendedVolumeBurstThroughput:
              item.recommendedVolumeBurstThroughput,
            lookbackPeriodDays: item.lookbackPeriodDays,
            savingsOpportunityPercentage: item.savingsOpportunityPercentage,
            estimatedMonthlySavings: item.estimatedMonthlySavings,
            estimatedMonthlySavingsCurrency:
              item.estimatedMonthlySavingsCurrency,
            instanceType: item.instanceType,
            vCpu: item.vCpu,
            minimumVCpu: item.minimumVCpu,
            sqlServerEdition: item.sqlServerEdition,
            maximumVCpu: item.maximumVCpu,
            recommendedInstanceType: item.recommendedInstanceType,
            hostedZoneId: item.hostedZoneId,
            resourceRecordSetName: item.resourceRecordSetName,
            resourceRecordSetType: item.resourceRecordSetType,
            status: item.status,
            maxDailyTimeoutRate: item.maxDailyTimeoutRate,
            dateOfMaxDailyTimeoutRate: item.dateOfMaxDailyTimeoutRate,
            averageDailyTimeoutRate: item.averageDailyTimeoutRate,
            functionTimeoutSettings: item.functionTimeoutSettings,
            lostDailyComputeCost: item.lostDailyComputeCost,
            averageDailyInvokes: item.averageDailyInvokes,
            currentDayInvokes: item.currentDayInvokes,
            currentDayTimeoutRate: item.currentDayTimeoutRate,
            maxDailyErrorRate: item.maxDailyErrorRate,
            dateForMaxErrorRate: item.dateForMaxErrorRate,
            averageDailyErrorRate: item.averageDailyErrorRate,
            currentDayErrorRate: item.currentDayErrorRate,
            functionVersion: item.functionVersion,
            memorySize: item.memorySize,
            recommendedMemorySize: item.recommendedMemorySize,
            lookbackPeriod: item.lookbackPeriod,
            savingsOpportunity: item.savingsOpportunity,
            workloadName: item.workloadName,
            workloadType: item.workloadType,
            workloadStartedDate: item.workloadStartedDate,
            workloadLastModifiedDate: item.workloadLastModifiedDate,
            numberOfIdentifiedHrisForCostOptimization:
              item.numberOfIdentifiedHrisForCostOptimization,
            numberOfHrisResolvedForCostOptimization:
              item.numberOfHrisResolvedForCostOptimization,
            numberOfQuestionsAnsweredForCostOptimization:
              item.numberOfQuestionsAnsweredForCostOptimization,
            totalNumberOfQuestionsInCostOptimizationPillar:
              item.totalNumberOfQuestionsInCostOptimizationPillar,
            instanceId: item.instanceId,
            cpuUtilization: item.cpuUtilization,
            estimatedSavings: item.estimatedSavings,
          }))
        );

        if (res.data.length >= INFINITE_SCROLL_PAGE_SIZE) {
          hasMoreData = true;
        }
      });
      setData(data);
      setRequestStatus?.(REQUEST_STATUS.SUCCESS);

      // Logic to fetch the next page recommendation, checks if any of the checkIds recommendation has more data than pageSize and try to fetch more.
      if (hasMoreData) {
        setCurrentPage(2);
      }
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getRecommendationList
 * @description Function to fetch the recommendation data
 * @param selectedCostOptimizationInsightsConnection Connection selected
 * @param setData Callback to set the fetched data
 * @param setRequestStatus Callback to set the request status
 * @param withPagination Whether to fetch the data with pagination or not
 * @param currentPage page number for pagination
 * @param setCurrentPage Callback to set the current page for pagination
 * @param currentRecommendationView Recommendation view selected
 * @param viewList List of views
 * @param categoryRecommenderMapping List of category to recommenders map
 * @param selectedCostOptimizationInsightsNav Selected category
 * @param rulesetFilters ruleset filters to be applied in the query
 */
export const getRecommendationList = (
  selectedCostOptimizationInsightsConnection: ConnectionListType,
  setData: (val: RecommendationList[]) => void,
  setRequestStatus: (val: string) => void,
  withPagination: boolean,
  currentPage: number,
  setCurrentPage: (val: number) => void,
  currentRecommendationView: ViewListType,
  viewList: ViewListType[] | undefined,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string,
  rulesetFilters: RulesetFilterGroupsType[]
) => {
  setRequestStatus?.(REQUEST_STATUS.PROCESSING);

  const hasRulesetFilter =
    currentRecommendationView.key !== DEFAULT_VIEW &&
    hasRulesetFilters(viewList, currentRecommendationView);

  let params: any = {
    connectorId: selectedCostOptimizationInsightsConnection.connectorId,
  };

  if (withPagination) {
    params = {
      ...params,
      page: currentPage,
      pageSize: INFINITE_SCROLL_PAGE_SIZE,
    };
  }

  if (
    getProviderForConnection(selectedCostOptimizationInsightsConnection) ===
    PROVIDER.AWS
  ) {
    const checkIds =
      categoryRecommenderMapping?.find(
        (category) => category.category === selectedCostOptimizationInsightsNav
      )?.recommenders ?? [];

    fetchAwsRecommendationData(
      checkIds,
      setData,
      setRequestStatus,
      setCurrentPage,
      currentRecommendationView,
      rulesetFilters,
      params
    );
    return;
  }

  const requestBody = getRecommendationQuery(
    hasRulesetFilter,
    getProviderForConnection(selectedCostOptimizationInsightsConnection),
    currentRecommendationView,
    categoryRecommenderMapping,
    selectedCostOptimizationInsightsNav,
    rulesetFilters
  );

  (hasRulesetFilter
    ? getFilteredRecommendations(requestBody, params)
    : getChartData(
        requestBody,
        selectedCostOptimizationInsightsConnection?.connectorId,
        params
      )
  )
    .then((res: any) => {
      setData(
        res.data.map((item: any) => ({
          ...item,
          recommendationId: item.recommendationName,
          accountId: item.projectNumber,
          recommendationSource: RecommendationSource.CSP,
          resource:
            item.targetResource ??
            `${item.impactedField}/${item.impactedValue}`,
          recommendation: item.recommendation ?? item.recommendationName, // GCP ?? AZURE
          timeStamp: item.lastRefreshed,
          recommender: item.recommender ?? item.recommendationProblem, // GCP ?? AZURE
          resourceMetadata: item.resourceMetadata,
          serviceNowStatus: ServiceNowStatus.TICKET_TBC,
          recStatus: item.recommendationState,
          priority: item.priority,
          type: item.recommender_subtype,
          category: item.recommendationCategory,
          costSavings: item.costSavings
            ? numberCommaSeparator(item.costSavings)
            : UNKNOWN_VALUE,
          recommendationProblem: item.recommendationProblem,
          impactedField: item.impactedField,
          impactedValue: item.impactedValue,
        }))
      );
      setRequestStatus?.(REQUEST_STATUS.SUCCESS);

      if (res.data.length >= INFINITE_SCROLL_PAGE_SIZE) {
        setCurrentPage(2);
      }
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
      setData([]);
    });
};

/**
 * @function getRecommendationsCountDefaultQuery
 * @description Function to return the recommendations count query without filters
 * @param provider Provider for which the query is returned
 * @param currentRecommendationView Recommendation view selected
 * @param categoryRecommenderMapping List of category to recommenders map
 * @param selectedCostOptimizationInsightsNav Selected category
 * @returns Query to fetch the recommendations count
 */
const getRecommendationsCountDefaultQuery = (
  provider: string,
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGcpDefaultRecommendationCountQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav
      );

    case PROVIDER.AZURE:
      return getAzureDefaultRecommendationCountQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav
      );
  }
};

/**
 * @function getRulesetFilterRecommendationsCountQuery
 * @description Function to return the recommendations count query with ruleset filters
 * @param provider Provider for which the query is returned
 * @param currentRecommendationView Recommendation view selected
 * @param categoryRecommenderMapping List of category to recommenders map
 * @param selectedCostOptimizationInsightsNav Selected category
 * @param rulesetFilters List of ruleset filters
 * @returns Query to fetch the recommendations count with ruleset filters
 */
const getRulesetFilterRecommendationsCountQuery = (
  provider: string,
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string,
  rulesetFilters: RulesetFilterGroupsType[]
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGcpRulesetFilterRecommendationCountQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav,
        rulesetFilters[0]?.filterGroups
      );

    case PROVIDER.AZURE:
      return getAzureRulesetFilterRecommendationCountQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav,
        rulesetFilters[0].filterGroups
      );
  }
};

/**
 * @function getRecommendationsCountQuery
 * @description Function to return the recommendations count query by provider
 * @param hasRulesetFilters boolean to indicate whether the ruleset filters are present or not
 * @param provider Provider for which the query is returned
 * @param currentRecommendationView Recommendation view selected
 * @param categoryRecommenderMapping List of category to recommenders map
 * @param selectedCostOptimizationInsightsNav Selected category
 * @param rulesetFilters List of ruleset filters
 * @returns Query to fetch the recommendations count
 */
const getRecommendationsCountQuery = (
  hasRulesetFilters: boolean,
  provider: string,
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string,
  rulesetFilters: RulesetFilterGroupsType[]
) => {
  return hasRulesetFilters
    ? getRulesetFilterRecommendationsCountQuery(
        provider,
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav,
        rulesetFilters
      )
    : getRecommendationsCountDefaultQuery(
        provider,
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav
      );
};

/**
 * @function getTotalRecommendationsCount
 * @description Function to fetch the total count of recommendations for GCP
 * @param selectedCostOptimizationInsightsConnection connection for which the data is fetched
 * @param setCount Callback function to set the count
 * @param currentRecommendationView Recommendation view selected
 * @param viewList List of views
 * @param categoryRecommenderMapping List of category to recommenders map
 * @param selectedCostOptimizationInsightsNav Selected category
 * @param rulesetFilters List of ruleset filters
 */
export const getTotalRecommendationsCount = (
  selectedCostOptimizationInsightsConnection: ConnectionListType,
  setCount: (val: number) => void,
  currentRecommendationView: ViewListType,
  viewList: ViewListType[] | undefined,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string,
  rulesetFilters: RulesetFilterGroupsType[]
) => {
  const hasRulesetFilter =
    currentRecommendationView.key !== DEFAULT_VIEW &&
    hasRulesetFilters(viewList, currentRecommendationView);

  const requestBody = getRecommendationsCountQuery(
    hasRulesetFilter,
    getProviderForConnection(selectedCostOptimizationInsightsConnection),
    currentRecommendationView,
    categoryRecommenderMapping,
    selectedCostOptimizationInsightsNav,
    rulesetFilters
  );

  const params = {
    connectorId: selectedCostOptimizationInsightsConnection?.connectorId,
  };

  (hasRulesetFilter
    ? getFilteredRecommendations(requestBody, params)
    : getChartData(
        requestBody,
        selectedCostOptimizationInsightsConnection?.connectorId
      )
  )
    .then((res: any) => {
      setCount(parseFloat(res?.data[0]?.totalCount?.toFixed(2)));
    })
    .catch((e) => {
      onApiCallError(e, false);
      setCount(0);
    });
};

/**
 * @function getServiceNowStatusAndSysId
 * @description Function to fetch the service now status and the sysId of the recommendation mapped ticket.
 * @param serviceNowTickets List of service now tickets
 * @param recommendationId The name of the recommendation
 * @param recStatus The name of the recommendation status of the recommendation
 * @returns service now status if the ticket is created else `Ticket TBC` by default
 */
const getServiceNowStatusAndSysId = (
  serviceNowTickets: IncidentRecommendationMappingType[],
  recommendationId: string,
  recStatus: string
) => {
  const incident = serviceNowTickets.find((snowIncident) =>
    snowIncident.recommendationApprovals?.some(
      (recommendationEntry) => recommendationEntry.name === recommendationId
    )
  );

  if (incident) {
    return { incidentStatus: incident.incidentStatus, sysId: incident.sysId };
  }

  if (
    recStatus === RecommendationStatus.ACTIVE ||
    recStatus === RecommendationStatus.FAILED
  ) {
    return { incidentStatus: ServiceNowStatus.TICKET_TBC };
  }

  return { incidentStatus: UNKNOWN_VALUE };
};

/**
 * @function getIncidentRecommendationStatus
 * @description Function to fetch the approval status of recommendation in service now.
 * @param recommendationId The name of the recommendation
 * @param serviceNowTickets List of service now tickets
 * @returns Object containing the approvalStatus, name and sysId
 */
const getIncidentRecommendationStatus = (
  recommendationId: string,
  serviceNowTickets: IncidentRecommendationMappingType[]
) => {
  const recommendationApprovals = serviceNowTickets.find((incident) =>
    incident.recommendationApprovals?.some(
      (recommendationEntry) => recommendationEntry.name === recommendationId
    )
  )?.recommendationApprovals;

  if (recommendationApprovals) {
    return recommendationApprovals.find(
      (recommendation) => recommendation.name === recommendationId
    )!;
  }

  return {
    approvalStatus: undefined,
    name: recommendationId,
    recomStatus: undefined,
    sysId: undefined,
  };
};

/**
 * @function addServiceNowStatusToRecommendationData
 * @description Function to add the service now status to the recommendation data.
 * @param serviceNowTickets List of service now tickets
 * @param recommendationData Recommendation Data
 * @param projectNumberIdMapping List of project number to id mapping
 * @returns Recommendation data with SNOW details updated
 */
export const addServiceNowStatusToRecommendationData = (
  recommendationData: RecommendationList[],
  serviceNowTickets: IncidentRecommendationMappingType[],
  projectNumberIdMapping: ProjectNumberIdMappingType[]
) => {
  return recommendationData.map((item: RecommendationList) => {
    const { incidentStatus, sysId } = getServiceNowStatusAndSysId(
      serviceNowTickets,
      item.recommendationId,
      item.recStatus
    );

    const { recomStatus, approvalStatus } = getIncidentRecommendationStatus(
      item.recommendationId,
      serviceNowTickets
    );

    return {
      ...item,
      accountId:
        projectNumberIdMapping.find(
          (eachId) =>
            eachId.projectNumber.toString() === item.accountId.toString()
        )?.projectId ?? item.accountId,
      recStatus: recomStatus ?? item.recStatus,
      serviceNowStatus: incidentStatus,
      incidentRecommendationStatus: approvalStatus,
      timeStamp: moment(item.timeStamp).format(DATE_TIME_AM_PM),
      sysId: sysId,
    };
  });
};

/**
 * @function filterRecommendationTableDataSource
 * @description Function that filters table data on the frontend for service now or recommendation status filters
 * @param recommendationData Data to be filtered
 * @param filters List of filters to applied
 * @param selectedStatusNav Snow status selected
 * @returns Recommendation table data filtered based on the snow filters
 */
export const filterRecommendationTableDataSource = (
  recommendationData: RecommendationList[],
  filters: ConsolidatedStatusFilterType,
  selectedStatusNav: string
) => {
  let recommendationTableFilteredData = [...recommendationData];

  let status = [selectedStatusNav];
  if (selectedStatusNav === ServiceNowStatus.TICKET_TBC) {
    status =
      filters.incidentStatus?.length > 0
        ? filters.incidentStatus
        : [selectedStatusNav, UNKNOWN_VALUE];
  }

  recommendationTableFilteredData = recommendationTableFilteredData.filter(
    (value) => status.includes(value.serviceNowStatus)
  );

  if (filters.incidentRecommendationStatus?.length) {
    recommendationTableFilteredData = recommendationTableFilteredData.filter(
      (value) =>
        ![
          ServiceNowStatus.RESOLVED.valueOf(),
          ServiceNowStatus.CLOSED.valueOf(),
        ].includes(value.serviceNowStatus) ||
        filters.incidentRecommendationStatus.includes(
          value?.incidentRecommendationStatus ?? ''
        )
    );
  }

  if (filters.recommendationStatus?.length) {
    recommendationTableFilteredData = recommendationTableFilteredData.filter(
      (value) => filters.recommendationStatus.includes(value.recStatus)
    );
  }

  if (filters.recommendationSource?.length) {
    recommendationTableFilteredData = recommendationTableFilteredData.filter(
      (value) =>
        filters.recommendationSource.includes(value.recommendationSource)
    );
  }

  return recommendationTableFilteredData;
};

/**
 * @function checkRecordStatusForApplyRec
 * @description Function to check if apply recommendation quick action should be shown as an
 * option in the recommendation row. Checks the rec status and snow status.
 * @param record The row details for the recommendation
 */
export const checkRecordStatusForApplyRec = (record: RecommendationList) => {
  return (
    record.incidentRecommendationStatus ===
      TICKET_RECOMMENDATION_STATUS.APPROVED &&
    (record.recStatus === RecommendationStatus.CLAIMED ||
      record.recStatus === RecommendationStatus.FAILED) &&
    (record.serviceNowStatus === ServiceNowStatus.RESOLVED ||
      record.serviceNowStatus === ServiceNowStatus.CLOSED)
  );
};

/**
 * @function isRecommendationApplyEligible
 * @description Function to check if the recommendation can be applied by provider
 * @param recommender Recommender to validate
 */
export const isRecommendationApplyEligible = (
  provider: string,
  record: RecommendationList,
  applicableRecommendations: string[]
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return applicableRecommendations.includes(record.recommender!);

    case PROVIDER.AWS:
      return applicableRecommendations.includes(record.checkId!);

    case PROVIDER.AZURE:
      return true;
  }
};

/**
 * @function getAwsUniqueKey
 * @description Function to return the unique by checkId for AWS
 * @param checkId CheckId for which the key is fetched
 * @returns unique key for the given check id
 */
export const getAwsUniqueKey = (checkId: string) =>
  (AWS_CHECKS_MAP.find((checkIdMap) => checkIdMap.check === checkId)
    ?.uniqueKeyLabel ?? 'accountId') as keyof RecommendationList;

/**
 * @function applyGcpRecommendation
 * @description Function to apply the recommendation for GCP
 * @param connection Connection for which the recommendation is applied
 * @param recommendation recommendation details
 * @param setStatus Callback to ste the api call status
 * @param onApplyRecommendation Callback for successful recommendation apply
 */
const applyGcpRecommendation = (
  connection: ConnectionListType,
  recommendation: RecommendationList,
  setStatus: (val: string) => void,
  onApplyRecommendation: () => void
) => {
  setStatus(REQUEST_STATUS.PROCESSING);

  const requestBody = {
    connectorId: connection.connectorId,
    recommendationName: recommendation.recommendationId,
    recommender: recommendation.recommender,
    createSnapShot: false,
  };

  applyGCPRecommendations(requestBody)
    .then((res: any) => {
      if (res?.status === 200) {
        message.success(
          i18n.t(
            'costOptimizationInsight.recommendationTableAction.applyRecSuccess',
            {
              recommendationId: recommendation.recommendationId.substring(
                recommendation.recommendationId.lastIndexOf('/') + 1
              ),
            }
          )
        );
        onApplyRecommendation();
        setStatus(REQUEST_STATUS.SUCCESS);

        return;
      }
      message.error(
        res?.data?.message ||
          i18n.t(
            'costOptimizationInsight.recommendationTableAction.applyRecFailure',
            {
              recommendationId: recommendation.recommendationId.substring(
                recommendation.recommendationId.lastIndexOf('/') + 1
              ),
            }
          )
      );
      setStatus(REQUEST_STATUS.ERROR);
    })
    .catch((e) => {
      message.error(
        e.response.data.message ||
          i18n.t(
            'costOptimizationInsight.recommendationTableAction.applyRecFailure',
            {
              recommendationId: recommendation.recommendationId.substring(
                recommendation.recommendationId.lastIndexOf('/') + 1
              ),
            }
          )
      );
      onApiCallError(e, false, setStatus);
    });
};

/**
 * @function applyAwsRecommendation
 * @description Function to apply the recommendation for AWS
 * @param connection Connection for which the recommendation is applied
 * @param recommendation recommendation details
 * @param setStatus Callback to ste the api call status
 * @param onApplyRecommendation Callback for successful recommendation apply
 * @param onFailure Callback for failure to apply the recommendation
 * @param additionalRequestBody Additional request body to be sent to api
 */
const applyAwsRecommendation = (
  connection: ConnectionListType,
  recommendation: RecommendationList,
  setStatus: (val: string) => void,
  onApplyRecommendation: () => void,
  onFailure: () => void,
  additionalRequestBody?: Object
) => {
  setStatus(REQUEST_STATUS.PROCESSING);

  const requestBody = {
    connectorId: connection.connectorId,
    checkId: recommendation.checkId,
    resourceId: (recommendation as any)[
      getAwsUniqueKey(recommendation.checkId!)
    ],
    region: recommendation.region,
    ...additionalRequestBody,
  };

  applyAWSRecommendations(requestBody)
    .then((res: any) => {
      if (res?.status === 200) {
        message.success(
          i18n.t(
            'costOptimizationInsight.recommendationTableAction.applyRecSuccess',
            {
              recommendationId: (recommendation as any)[
                getAwsUniqueKey(recommendation.checkId!)
              ],
            }
          )
        );
        onApplyRecommendation();
        setStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      message.error(
        res?.data?.message ||
          i18n.t(
            'costOptimizationInsight.recommendationTableAction.applyRecFailure',
            {
              recommendationId: (recommendation as any)[
                getAwsUniqueKey(recommendation.checkId!)
              ],
            }
          )
      );
      onFailure();
      setStatus(REQUEST_STATUS.ERROR);
    })
    .catch((e) => {
      message.error(
        e.response.data.message ||
          i18n.t(
            'costOptimizationInsight.recommendationTableAction.applyRecFailure',
            {
              recommendationId: (recommendation as any)[
                getAwsUniqueKey(recommendation.checkId!)
              ],
            }
          )
      );
      onFailure();
      onApiCallError(e, false, setStatus);
    });
};

/**
 * @function applyAzureRecommendation
 * @description Function to apply the recommendation for AWS
 * @param connection Connection for which the recommendation is applied
 * @param recommendation recommendation details
 * @param setStatus Callback to ste the api call status
 * @param onApplyRecommendation Callback for successful recommendation apply
 */
const applyAzureRecommendation = (
  connection: ConnectionListType,
  recommendation: RecommendationList,
  setStatus: (val: string) => void,
  onApplyRecommendation: () => void
) => {
  setStatus(REQUEST_STATUS.PROCESSING);

  const requestBody = {
    connectorId: connection.connectorId,
    recommendationName: recommendation.recommendation,
    recommender: recommendation.recommender,
    recommendationId: recommendation.recommendationId,
    resourceId: recommendation.resourceMetaData!.substring(1),
  };

  applyAzureRecommendations(requestBody)
    .then((res: any) => {
      if (res?.status === 200) {
        message.success(
          i18n.t(
            'costOptimizationInsight.recommendationTableAction.applyRecSuccess',
            {
              recommendationId: recommendation.recommendation,
            }
          )
        );
        onApplyRecommendation();
        setStatus(REQUEST_STATUS.SUCCESS);

        return;
      }
      message.error(
        res?.data?.message ??
          i18n.t(
            'costOptimizationInsight.recommendationTableAction.applyRecFailure',
            {
              recommendationId: recommendation.recommendation,
            }
          )
      );
      setStatus(REQUEST_STATUS.ERROR);
    })
    .catch((e) => {
      message.error(
        e.response.data.message ??
          i18n.t(
            'costOptimizationInsight.recommendationTableAction.applyRecFailure',
            {
              recommendationId: recommendation.recommendation,
            }
          )
      );
      onApiCallError(e, false, setStatus);
    });
};

/**
 * @function applyRecommendation
 * @description Function to apply the recommendation by provider
 * @param connection Connection for which the recommendation is applied
 * @param recommendation recommendation details
 * @param setStatus Callback to ste the api call status
 * @param onApplyRecommendation Callback for successful recommendation apply
 * @param onFailure Callback for failure to apply the recommendation
 * @param additionalRequestBody Additional request body to be sent to api
 */
export const applyRecommendation = (
  connection: ConnectionListType,
  recommendation: RecommendationList,
  setStatus: (val: string) => void,
  onApplyRecommendation: () => void,
  onFailure: () => void,
  additionalRequestBody?: Object
) => {
  switch (getProviderForConnection(connection)) {
    case PROVIDER.GCP:
      applyGcpRecommendation(
        connection,
        recommendation,
        setStatus,
        onApplyRecommendation
      );
      break;

    case PROVIDER.AWS:
      applyAwsRecommendation(
        connection,
        recommendation,
        setStatus,
        onApplyRecommendation,
        onFailure,
        additionalRequestBody
      );
      break;

    case PROVIDER.AZURE:
      applyAzureRecommendation(
        connection,
        recommendation,
        setStatus,
        onApplyRecommendation
      );
      break;
  }
};

/**
 * @function getGranulateAllowedFiltersFromView
 * @description Returns the list of filters allowed for Granulate
 * @param currentRecommendationView recommendation view fro which the filters are filtered from.
 * @returns List of filters applicable for Granulate
 */
const getGranulateAllowedFiltersFromView = (
  currentRecommendationView: ViewListType
) => {
  const filters: RecommendationFiltersType[] = [];
  currentRecommendationView.recommendationsFilterDtoList.forEach((filter) => {
    if (filter.key === FilterKeys.RECOMMENDER_SUBTYPE) {
      const values = filter.values!.filter((item) =>
        Object.values(GranulateRecommenderSubTypes).includes(
          item.value as GranulateRecommenderSubTypes
        )
      );
      if (values.length) {
        filters.push({ ...filter, values: values });
      }
    } else if (filter.granulateFilter) {
      filters.push(filter);
    }
  });

  return filters;
};

/**
 * @function getGranulateCostSavingsFilterQueries
 * @description Returns the list of filters query for cost savings filters
 * @param costSavingsFilters The list of cost savings filters for which the filter queries are constructed
 * @returns List of filter queries
 */
const getGranulateCostSavingsFilterQueries = (
  costSavingsFilters: RecommendationFiltersType
) => {
  return costSavingsFilters.values!.map((valueLabel) => {
    const valueOptions = valueLabel.value.split(',');
    return {
      key: costSavingsFilters.key,
      comparator: valueOptions[0],
      values: [valueOptions[1]],
      conjuctionToNextFilter: valueOptions[2],
    };
  });
};

/**
 * @function getGranulateFiltersForQuery
 * @description Returns the list of filters queries for granulate
 * @param filters The list of filters for which the filter queries are constructed
 * @returns List of filter queries
 */
const getGranulateFiltersForQuery = (
  filters: RecommendationFiltersType[]
): GranulateRecommendationFilterQueryType[] => {
  const filtersRequestQuery: GranulateRecommendationFilterQueryType[] = [];
  filters.forEach((filter) => {
    if (filter.key === FilterKeys.COST_SAVINGS) {
      filtersRequestQuery.push(...getGranulateCostSavingsFilterQueries(filter));
    } else {
      filtersRequestQuery.push({
        key: filter.key,
        comparator: COMPARATORS.IN,
        values: filter.values!.map((valueLabelMap) => valueLabelMap.value),
        conjuctionToNextFilter: CONJUNCTIONS.AND,
      });
    }
  });
  return filtersRequestQuery;
};

/**
 * @function getMemoryUtilizationRequestBody
 * @description Return the query to get the memory utilization query for GCP
 * @param cluster CLuster for which the memory reduction query is constructed
 * @returns Object containing query to get the memory reduction for GCP
 */
const getMemoryUtilizationRequestBody = (cluster: string) => {
  return {
    columns: [
      {
        label: 'name',
        field: QUERY_FIELDS.POD_LABELS_VALUE,
      },
      {
        label: 'pods',
        field: QUERY_FIELDS.POD_COUNT_LABELS_VALUE,
      },
      {
        label: 'namespace',
        field: QUERY_FIELDS.NAMESPACE,
      },
      {
        label: 'memoryUtilisation',
        field: '((cloud_resource_size*fraction)/(1024*1024))',
      },
      {
        label: 'sku_id',
        field: 'sku_id',
      },
      {
        label: 'time',
        field: QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME,
      },
    ],
    structColumns: [
      {
        label: 'pod_labels',
        field: QUERY_FIELDS.LABELS,
      },
      {
        label: 'pod_count_labels',
        field: QUERY_FIELDS.LABELS,
      },
    ],
    aggregators: [
      {
        label: 'memoryUtilisation',
        function: AGGREGATORS.AVG,
      },
    ],
    groupBy: ['time', 'name', 'pods', 'namespace', 'sku_id'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: 'start_time',
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment()
              .subtract(1, 'days')
              .format(HYPHEN_DATE_TIME_HH_MM_SS),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: 'end_time',
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: dateToday(HYPHEN_DATE_FORMAT),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: 'pod_labels.key',
            comparator: COMPARATORS.IN,
            value: '("app","component","k8s-app")',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: 'pod_count_labels.key',
            comparator: COMPARATORS.IN,
            value: '("pod-template-generation")',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: 'resource_name',
            comparator: COMPARATORS.EQUALS,
            value: 'memory',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: 'cluster_name',
            comparator: COMPARATORS.EQUALS,
            value: cluster,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
    cached: true,
  };
};

/**
 * @function getCpuUtilizationRequestBody
 * @description Return the query to get the cpu utilization query for GCP
 * @param cluster CLuster for which the cpu reduction query is constructed
 * @returns Object containing query to get the cpu reduction for GCP
 */
const getCpuUtilizationRequestBody = (cluster: string) => {
  return {
    columns: [
      {
        label: 'name',
        field: QUERY_FIELDS.LABELS_VALUE,
      },
      {
        label: 'sku_id',
        field: 'sku_id',
      },
      {
        label: 'cpuUtilisation',
        field: '(cloud_resource_size*fraction)',
      },
      {
        label: 'time',
        field: QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME,
      },
      {
        label: 'namespace',
        field: QUERY_FIELDS.NAMESPACE,
      },
    ],
    structColumns: [
      {
        label: 'labels',
        field: QUERY_FIELDS.LABELS,
      },
    ],
    aggregators: [
      {
        label: 'cpuUtilisation',
        function: AGGREGATORS.AVG,
      },
    ],
    groupBy: ['time', 'name', 'namespace', 'sku_id'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: 'start_time',
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment().subtract(1, 'days').format(HYPHEN_DATE_FORMAT),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: 'end_time',
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: dateToday(HYPHEN_DATE_FORMAT),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: 'labels.key',
            comparator: COMPARATORS.IN,
            value: '("app","component","k8s-app")',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },

          {
            field: 'resource_name',
            comparator: COMPARATORS.EQUALS,
            value: 'cpu',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: 'cluster_name',
            comparator: COMPARATORS.EQUALS,
            value: cluster,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
    cached: true,
  };
};

/**
 * @function getCostImpactRequestBody
 * @description Fetches the CPU and Memory utilization to construct the request body for cost impact api
 * @param connection Granulate connection for which the request body is constructed
 * @param recommendations List of Granulate recommendations
 * @returns Object containing the list CPU and Memory request bodies for Cost Impact
 */
const getCostImpactRequestBody = async (
  connection: GranulateConnectionsType,
  recommendations: RecommendationList[]
) => {
  const params = { project: connection.project };

  const costImpactMemoryRequestBody: any[] = [];
  const costImpactCPURequestBody: any[] = [];

  // Fetches CPU and Memory utilization
  const requests = [
    getChartData(
      getMemoryUtilizationRequestBody(connection.clusterName),
      connection.connectorId,
      params
    ),
    getChartData(
      getCpuUtilizationRequestBody(connection.clusterName),
      connection.connectorId,
      params
    ),
  ];

  try {
    const responses: any[] = await axios.all(requests);

    const memoryUtilizations = responses[0].data;
    const cpuUtilizations = responses[1].data;

    recommendations.forEach((recommendation) => {
      const container = recommendation.containers?.find(
        (container) => container.name === recommendation.controller_name
      );

      memoryUtilizations.forEach((memoryUtilization: any) => {
        if (
          (recommendation.controller_name === memoryUtilization.name ||
            memoryUtilization.name.includes(recommendation.controller_name)) &&
          container?.current_mem &&
          container.recommended_memory
        ) {
          costImpactMemoryRequestBody.push(
            getRequestBodyObject(
              connection.serviceDisplayName,
              recommendation,
              memoryUtilization.sku_id,
              container?.current_mem,
              container?.recommended_memory,
              'MEMORY',
              { unitDescription: 'mebibyte-seconds', usageUnit: 'MB.s' }
            )
          );
        }
      });

      cpuUtilizations.forEach((cpuUtilization: any) => {
        if (
          (recommendation.controller_name === cpuUtilization.name ||
            cpuUtilization.name.includes(recommendation.controller_name)) &&
          container?.current_cpu &&
          container.recommended_cpu
        ) {
          costImpactCPURequestBody.push(
            getRequestBodyObject(
              connection.serviceDisplayName,
              recommendation,
              cpuUtilization.sku_id,
              container?.current_cpu,
              container?.recommended_cpu,
              'CPU',
              { unitDescription: 'seconds', usageUnit: 's' }
            )
          );
        }
      });
    });
  } catch (e) {
    onApiCallError(e, false);
  }

  return { costImpactMemoryRequestBody, costImpactCPURequestBody };
};

/**
 * @function getCpuAndMemoryCostImpactForGranulateConnection
 * @description Fetches the Granulate CPU and Memory cost impact for a Granulate connection
 * @param connection Granulate connection for which the cost impact is fetched
 * @param recommendations List of Granulate recommendations
 * @returns Object containing the CPU and Memory Cost Impact
 */
const getCpuAndMemoryCostImpactForGranulateConnection = async (
  connection: GranulateConnectionsType,
  recommendations: RecommendationList[]
) => {
  const { costImpactCPURequestBody, costImpactMemoryRequestBody } =
    await getCostImpactRequestBody(connection, recommendations);

  const cpuCostImpact = await fetchCPUMemoryCostImpact(
    connection.connectorId,
    costImpactCPURequestBody,
    connection.project
  );

  const memoryCostImpact = await fetchCPUMemoryCostImpact(
    connection.connectorId,
    costImpactMemoryRequestBody,
    connection.project
  );

  return {
    cpuCostImpact,
    memoryCostImpact,
  };
};

/**
 * @function getGranulateServiceNameToCostImpactMap
 * @description Fetches the Granulate cost impact for each Granulate connection
 * @param granulateConnections List of Granulate connections
 * @param recommendations List of Granulate recommendations
 * @returns Object containing the map from Granulate service name to CPU and Memory Cost Impact
 */
const getGranulateServiceNameToCostImpactMap = async (
  granulateConnections: GranulateConnectionsType[],
  recommendations: RecommendationList[]
) => {
  const promises = granulateConnections.map((connection) =>
    getCpuAndMemoryCostImpactForGranulateConnection(
      connection,
      recommendations
    ).then((res) => {
      return { key: connection.serviceDisplayName, value: res };
    })
  );

  const results = await Promise.all(promises);

  const serviceNameCostImpactMap: { [key: string]: CpuMemoryCostImpactType } =
    {};
  results.forEach((result) => {
    serviceNameCostImpactMap[result.key] = result.value;
  });

  return serviceNameCostImpactMap;
};

/**
 * @function isCpuGranulateRecommendation
 * @description Returns if the recommendation is a CPU recommendation based on the HPA and Containers recommendation data
 * @param hpa HPA recommendation data
 * @param containers Containers recommendation data
 * @returns A boolean true if the it has CPU recommendation else false
 */
const isCpuGranulateRecommendation = (
  hpa: HPAType,
  containers: ContainerType[]
) => {
  // Returns true if there is a HPA recommender CPU or at least one container has recommended CPU
  return (
    !!hpa.recommended_cpu ||
    containers.some((container) => container.recommended_cpu)
  );
};

/**
 * @function isMemoryGranulateRecommendation
 * @description Returns if the recommendation is a memory recommendation based on the HPA and Containers recommendation data
 * @param hpa HPA recommendation data
 * @param containers Containers recommendation data
 * @returns A boolean true if the it has memory recommendation else false
 */
const isMemoryGranulateRecommendation = (
  hpa: HPAType,
  containers: ContainerType[]
) => {
  // Returns true if there is a HPA recommender Memory or at least one container has recommended Memory
  return (
    !!hpa.recommended_mem ||
    containers.some((container) => container.recommended_memory)
  );
};

/**
 * @function getGranulateRecommendationType
 * @description Returns the type of recommendation based on the HPA and Containers recommendation data
 * @param hpa HPA recommendation data
 * @param containers Containers recommendation data
 * @returns A string containing the recommendation type. Defaults to N/A if none matches
 */
const getGranulateRecommendationType = (
  hpa: HPAType,
  containers: ContainerType[]
) => {
  const isCpuRecommendation: boolean = isCpuGranulateRecommendation(
    hpa,
    containers
  );
  const isMemoryRecommendation: boolean = isMemoryGranulateRecommendation(
    hpa,
    containers
  );

  if (isCpuRecommendation && isMemoryRecommendation) {
    return (
      GranulateRecommenderSubTypes.GRANULATE_CPU +
      '/' +
      GranulateRecommenderSubTypes.GRANULATE_MEMORY
    );
  }

  if (isCpuRecommendation) {
    return GranulateRecommenderSubTypes.GRANULATE_CPU;
  }

  if (isMemoryRecommendation) {
    return GranulateRecommenderSubTypes.GRANULATE_MEMORY;
  }

  return 'N/A';
};

/**
 * @function fetchGranulateRecommendations
 * @description Fetches the Granulate recommendations for the CSP connection
 * @param connection CSP connection for which the recommendations are fetched
 * @param granulateConnections The list of Granulate connections
 * @param filters Filters to be applied
 * @returns The list of recommendations
 */
const fetchGranulateRecommendations = async (
  connection: ConnectionListType,
  granulateConnections: GranulateConnectionsType[],
  filters: RecommendationFiltersType[]
) => {
  let recommendations: RecommendationList[] = [];

  const params = {
    connectorId: connection.connectorId,
    provider: getProviderForConnection(connection),
  };

  const requestBody = getGranulateFiltersForQuery(filters);

  try {
    const response: any = await getGranulateRecommendationsForConnector(
      params,
      requestBody
    );

    recommendations = response.data.map((item: any, index: number) => {
      const connection = granulateConnections.find(
        (connection) => connection.serviceDisplayName === item.u_service_name
      );

      const hpa = convertHpaStringToObject(item.u_hpa);
      const containers = convertContainerStringToArrayOfObject(
        item.u_containers,
        item.incidentStatus === SERVICE_NOW_STATUS.UNDEFINED &&
          item.u_approval_status === null
      );

      return {
        recommendationId: 'GRANULATE' + item.u_resource_name + index,
        accountId: connection?.project ?? '',
        recommendationSource: RecommendationSource.GRANULATE,
        resource: connection?.clusterName ?? '',
        serviceName: item.u_service_name,
        recommendation: getGranulateRecommendationType(hpa, containers),
        timeStamp: item.creation_time,
        recStatus: RecommendationStatus.NA,
        serviceNowStatus:
          item.incidentStatus === 'UNDEFINED'
            ? ServiceNowStatus.TICKET_TBC
            : item.incidentStatus,
        sysId: item.sys_id,
        namespace: item.u_namespace,
        controller_name: item.u_resource_name,
        containers: containers,
        hpa: hpa,
        patch_yaml: item.u_patch_yaml.trim(),
        costImpact: Number(item.u_cost_impact ?? 0),
      };
    });
  } catch (e) {
    onApiCallError(e, false);
  }

  return recommendations;
};

/**
 * @function getTotalCostImpact
 * @description Calculates the total cost impact for a recommendation with given cpu and memory impact data
 * @param recommendation Recommendation for which the cost impact needs to be calculated
 * @param costImpact cpu and memory cost impact for the associated service name
 * @returns Sum of memory and cpu cost impact. Undefined of the costImpact data is invalid
 */
const getTotalCostImpact = (
  recommendation: RecommendationList,
  costImpact: CpuMemoryCostImpactType
) => {
  let cpuCostDifference: number = 0;
  let memoryCostDifference: number = 0;

  if (costImpact?.cpuCostImpact?.length > 0) {
    cpuCostDifference =
      costImpact.cpuCostImpact.find(
        (data: any) => data.podName === recommendation.controller_name
      )?.costDifference ?? 0;
  }

  if (costImpact?.memoryCostImpact?.length > 0) {
    memoryCostDifference =
      costImpact.memoryCostImpact.find(
        (data: any) => data.podName === recommendation.controller_name
      )?.costDifference ?? 0;
  }

  return cpuCostDifference + memoryCostDifference;
};

/**
 * @function calculateGcpGranulateTotalCostImpact
 * @description Calculated the total CPU and Memory cost impact for the granulate recommendation and returns the updated recommendation
 * @param recommendations List of recommendations for which the cost impact needs to be updated
 * @param serviceNameCostImpactMap Granulate service name to cpu and memory cost impact
 * @returns Th list of recommendations with updated cost impact
 */
const calculateGcpGranulateTotalCostImpact = (
  recommendations: RecommendationList[],
  serviceNameCostImpactMap: { [key: string]: CpuMemoryCostImpactType }
) => {
  return recommendations.map((recommendation) => {
    const costImpact = serviceNameCostImpactMap[recommendation.serviceName!];
    return {
      ...recommendation,
      costImpact: getTotalCostImpact(recommendation, costImpact),
    };
  });
};

/**
 * @function getAllGranulateRecommendations
 * @description Fetches and sets the granulate recommendations for all the granulate connections under a CSP connection
 * @param connection CSP connection for which the recommendations are fetched
 * @param granulateConnections List of granulate connections
 * @param currentView The recommendation view selected
 * @param setData Callback to set the fetched recommendations
 * @param setRequestStatus Callback to set the request status of the api request
 */
export const getAndSetGranulateRecommendations = async (
  connection: ConnectionListType,
  granulateConnections: GranulateConnectionsType[],
  setData: (val: RecommendationList[]) => void,
  setRequestStatus: (val: string) => void,
  recommendationFilters: RecommendationFiltersType[] = []
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const recommendations = await fetchGranulateRecommendations(
    connection,
    granulateConnections,
    recommendationFilters
  );

  if (getProviderForConnection(connection) === PROVIDER.AWS) {
    setData(recommendations);
  } else {
    const serviceNameCostImpactMap =
      await getGranulateServiceNameToCostImpactMap(
        granulateConnections,
        recommendations
      );
    setData(
      calculateGcpGranulateTotalCostImpact(
        recommendations,
        serviceNameCostImpactMap
      )
    );
  }

  setRequestStatus(REQUEST_STATUS.SUCCESS);
};

/**
 * @function getAllGranulateRecommendations
 * @description Fetches and sets the granulate recommendations for all the granulate connections under a CSP connection
 * @param connection CSP connection for which the recommendations are fetched
 * @param granulateConnections List of granulate connections
 * @param currentView The recommendation view selected
 * @param setData Callback to set the fetched recommendations
 * @param setRequestStatus Callback to set the request status of the api request
 */
export const getAllGranulateRecommendations = async (
  connection: ConnectionListType,
  granulateConnections: GranulateConnectionsType[],
  currentView: ViewListType,
  setData: (val: RecommendationList[]) => void,
  setRequestStatus: (val: string) => void
) => {
  if (
    currentView.recommendationsFilterDtoList.length &&
    !getGranulateAllowedFiltersFromView(currentView).length
  ) {
    return;
  }

  getAndSetGranulateRecommendations(
    connection,
    granulateConnections,
    setData,
    setRequestStatus,
    currentView.recommendationsFilterDtoList
  );
};

/**
 * @function getGranulateConnectionForCspConnection
 * @description Fetches and sets the granulate connections associated with a CSP connection
 * @param permissions Object containing the map of permissions
 * @param connection CSP connection for which the Granulate connections are fetched
 * @param setData Callback to set the fetched Granulate connections
 * @param setRequestStatus Callback to set the request status of the api request
 */
export const getGranulateConnectionForCspConnection = (
  permissions: { [key: string]: boolean },
  connection: ConnectionListType,
  setData: (val: GranulateConnectionsType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  if (!permissions.thirdPartyAppsRead) {
    return;
  }

  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const params = {
    connectorId: connection.connectorId,
  };

  getGranulateConnectionsForConnector(params)
    .then((res: any) => {
      setData(res.data);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getAwsExportColumns
 * @description Returns the list of columns for all the checkIds consolidated
 * @returns List of columns
 */
const getAwsExportColumns = () => {
  const columns = [
    ...AWS_UNDERUTILIZED_ENDPOINTS_FIELD,
    ...AWS_OVERPROVISIONED_EBS_VOLUMES_FIELDS,
    ...AWS_EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER_FIELDS,
    ...AWS_EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER_FIELDS,
    ...AWS_ROUTE_53_LATENCY_RECORD_SETS_FIELDS,
    ...AWS_LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT_FIELDS,
    ...AWS_LAMBDA_FUNCTIONS_HIGH_ERROR_RATES_FIELDS,
    ...AWS_LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY_FIELDS,
    ...AWS_WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR_FIELDS,
    ...AWS_RIGHT_SIZING_EC2_FIELDS,
  ];

  return [...uniqBy(columns, 'field')];
};

/**
 * @function getExportColumns
 * @description Returns the list of columns for excel export by provider along with Granulate columns based on the permission
 * @param provider Provider for which the columns are required
 * @param permissions Object containing the permissions
 * @returns List of columns for excel export
 */
const getExportColumns = (
  provider: string,
  permissions: { [key: string]: boolean }
) => {
  const columns = [...CONSUMPTION_MANAGEMENT_COMMON_RECOMMENDATION_FIELDS];

  switch (provider) {
    case PROVIDER.GCP:
      columns.push(...GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS);
      break;

    case PROVIDER.AWS:
      columns.push(...getAwsExportColumns());
      break;

    case PROVIDER.AZURE:
      columns.push(...AZURE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS);
      break;
  }

  if (permissions.thirdPartyAppsRead) {
    columns.push(...GRANULATE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS);
  }

  return [
    {
      header: '#',
      key: 'slNo',
      width: 10,
      alignment: 'center',
      dataKey: 'slNo',
    },
    ...columns.map((column) => ({
      header: column.label,
      key: column.field,
      dataKey: column.field,
    })),
  ];
};

/**
 * @function getRecommendationDataForExport
 * @description Returns the list of excel export data categorized by service now status
 * @param connection CSP connection
 * @param recommendations List of recommendations
 * @param statusFilters List of filters
 * @param permissions Object containing the permissions
 * @returns List excel export data
 */
export const getRecommendationDataForExport = (
  connection: ConnectionListType,
  recommendations: RecommendationList[],
  statusFilters: ConsolidatedStatusFilterType,
  permissions: { [key: string]: boolean }
) => {
  const recommendationDataByStatus = SERVICE_NOW_STATUS_LABELS.map(
    (status) => ({
      sheetName: status.tabTitle,
      recommendationTableData: filterRecommendationTableDataSource(
        recommendations,
        statusFilters,
        status.key
      ),
    })
  );

  const exportDataWithColumnsAndFilters = recommendationDataByStatus
    .filter((item) => item.recommendationTableData.length > 0)
    .map((item) => ({
      sheetName: item.sheetName,
      columns: getExportColumns(
        getProviderForConnection(connection),
        permissions
      ),
      data: addIndexAndCommaSeparator(item.recommendationTableData),
      filters: [
        {
          heading: i18n.t('excelExportLabels.connectionName'),
          value: connection.name,
        },
      ],
    }));

  return exportDataWithColumnsAndFilters;
};

/**
 * @function hasRecommendationFilters
 * @description Returns whether the recommendation filters are added or not
 * @param recommendationFilter Recommendation filter to be validated
 * @returns Boolean true if the recommendation filters are present else false
 */
export const hasRecommendationFilters = (
  recommendationFilter: ViewListType
) => {
  return (
    recommendationFilter.recommendationsFilterDtoList &&
    recommendationFilter.recommendationsFilterDtoList.length
  );
};
