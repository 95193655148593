import i18n from 'i18n';

import { AzureDataSource } from 'pages/ConnectingCSPPage/components/BasicDetails/constants';

/**
 * @function getWorkspaceLabelByDataSource
 * @description Function to fetch the workspace label by data source
 * @param dataSourceType data source type for which the label is fetched
 * @returns string label
 */
export const getWorkspaceLabelByDataSource = (dataSourceType: string) => {
  return dataSourceType === AzureDataSource.SQL
    ? i18n.t('azureConnectionCSPForm.step3.sqlServer')
    : i18n.t('azureConnectionCSPForm.step3.synapseWorkspaceName');
};
