import { Collapse } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';

import { selectReport, setSelectedReportMetrics } from 'redux/reportSlice';
import Checkbox from 'components/Checkbox';
import Empty from 'components/Empty';
import { FieldSource, FIELD_SOURCE_LABELS } from 'constants/dashboard';
import { AvailableFieldsType } from 'types/dashboard';
import { ColumnType } from 'pages/ReportsPage/types';

const { Panel } = Collapse;

type MetricsWithCategoriesProps = {
  searchKey: string;
};

const MetricsWithCategories = ({ searchKey }: MetricsWithCategoriesProps) => {
  const dispatch = useDispatch();
  const { reportMetrics, selectedReportMetrics } = useSelector(selectReport);

  /**
   * @function getSelectedMetricsCount
   * @description get selected metrics count
   * @param source the field source
   * @returns length of selected metrics for the given source
   */
  const getSelectedMetricsCount = (source: string) => {
    return reportMetrics.filter(
      (fieldItem) =>
        fieldItem.name.toLowerCase().includes(searchKey.toLowerCase()) &&
        fieldItem.source === source &&
        selectedReportMetrics.some((metric) => metric.label === fieldItem.name)
    ).length;
  };

  const onChangeMetricsCheckbox = (
    e: CheckboxChangeEvent,
    fieldItem: AvailableFieldsType
  ) => {
    let selectedValues: ColumnType[];

    if (e.target.checked) {
      selectedValues = [
        ...selectedReportMetrics,
        { label: fieldItem.name, field: fieldItem.name },
      ];
    } else {
      selectedValues = selectedReportMetrics.filter(
        (item) => item.label !== fieldItem.name
      );
    }

    dispatch(setSelectedReportMetrics(selectedValues));
  };

  /**
   * @function getUserSystemMetrics
   * @description Function to get the user/system metrics list
   * @param source the field source, user or system
   * @returns list of metrics
   */
  const getUserSystemMetrics = (source: string) =>
    reportMetrics.filter(
      (fieldItem) =>
        fieldItem.name.toLowerCase().includes(searchKey.toLowerCase()) &&
        fieldItem.source === source
    );

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="collapse-arrow" />
    );

  return (
    <Collapse
      className="report-metrics-group full-width new-styled-scroll"
      accordion={true}
      bordered={false}
      expandIcon={getExpandIcon}
      defaultActiveKey={[FieldSource.SYSTEM]}
    >
      {FIELD_SOURCE_LABELS.filter(
        (item) => item.value !== FieldSource.TAGS
      ).map((source) => (
        <Panel
          className="field-panel"
          header={
            <div className="flex flex-align-items-center flex-gap-8">
              {source.label}
            </div>
          }
          key={source.value}
          extra={
            getSelectedMetricsCount(source.value) ? (
              <div className="font-small-bold flex flex-center values-count">
                {getSelectedMetricsCount(source.value)}
              </div>
            ) : null
          }
        >
          <div className="flex flex-column flex-gap-8">
            {getUserSystemMetrics(source.value).length ? (
              getUserSystemMetrics(source.value).map((fieldItem) => (
                <Checkbox
                  key={fieldItem.name}
                  checked={selectedReportMetrics.some(
                    (metric) => metric.label === fieldItem.name
                  )}
                  onChange={(e) => onChangeMetricsCheckbox(e, fieldItem)}
                >
                  {fieldItem.name}
                </Checkbox>
              ))
            ) : (
              <Empty />
            )}
          </div>
        </Panel>
      ))}
    </Collapse>
  );
};

export default MetricsWithCategories;
