import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { RecommendationFiltersType } from 'pages/CostOptimizationInsightsPage/types';
import DatePicker from 'components/DatePicker';
import { DATE_FORMAT, HYPHEN_DATE_FORMAT } from 'utils/date';
import { ValueLabelType } from 'types/dashboard';

import './index.scss';

type DateRangeInputFieldProps = {
  filter: RecommendationFiltersType;
  onChangeFilterValues: (
    filter: RecommendationFiltersType,
    values: ValueLabelType[] | null
  ) => void;
};

const DateRangeInputField = ({
  filter,
  onChangeFilterValues,
}: DateRangeInputFieldProps) => {
  /**
   * @function onChangeDateRange
   * @description Updated the date range values in the format DD-MM-YYYY.
   * @param dates Dates to be updated with.
   */
  const onChangeDateRange = (dates: RangeValue<Moment>) => {
    if (!dates || !dates[0] || !dates[1]) {
      onChangeFilterValues(filter, []);
      return;
    }

    onChangeFilterValues(filter, [
      {
        value: [
          dates[0].format(HYPHEN_DATE_FORMAT),
          dates[1].format(HYPHEN_DATE_FORMAT),
        ].toString(),
      },
    ]);
  };

  return (
    <DatePicker
      className="date-range-input-field"
      onChange={onChangeDateRange}
      disabledDate={(current: any) =>
        current > moment().endOf('day') ||
        current < moment().subtract(30, 'days')
      }
      format={DATE_FORMAT}
      designVersion2
    />
  );
};

export default DateRangeInputField;
