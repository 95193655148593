import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from 'antd';

type CheckboxProps = {
  children?: React.ReactNode;
} & BaseCheckboxProps;

const Checkbox = ({ children, ...remainingProps }: CheckboxProps) => {
  return <BaseCheckbox {...remainingProps}>{children}</BaseCheckbox>;
};

export default Checkbox;
