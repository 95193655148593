import { ReactComponent as LoaderIconWithoutLogo } from 'assets/icons/loading.svg';
import { ReactComponent as LoaderIconWithLogo } from 'assets/icons/loader.svg';
import { ReactComponent as LogoWithoutLogo } from 'assets/icons/logoWithoutLogo.svg';
import { ReactComponent as LogoWithLogo } from 'assets/icons/logo.svg';
import { ReactComponent as logoIconWithoutLogo } from 'assets/icons/logoIconWithoutLogo.svg';
import { ReactComponent as logoIconWithLogo } from 'assets/icons/logoIcon.svg';
import logoPngWithLogo from 'assets/icons/logoInPng.png';
import logoPngWithoutLogo from 'assets/icons/logoInPngWithoutLogo.png';

import { Instances } from 'constants/endpoints';

export { default as AWSMediumIcon } from 'assets/icons/awsMediumIcon.svg';
export { default as OCIMediumIcon } from 'assets/icons/ociMediumIcon.svg';
export { default as AzureMediumIcon } from 'assets/icons/azureIcon.svg';
export { default as GcpIcon } from 'assets/icons/gcpIcon.svg';
export { default as GCPLogo } from 'assets/icons/gcpLogo.svg';
export { default as AzureLogo } from 'assets/icons/azureLogo.svg';
export { default as AWSLogo } from 'assets/icons/awsLogo.svg';
export { default as OciLogo } from 'assets/icons/ociLogo.svg';
export { ReactComponent as LeftBackArrowIcon } from 'assets/icons/leftBackArrow.svg';
export { ReactComponent as RightForwardArrowIcon } from 'assets/icons/rightForwardArrow.svg';
export { ReactComponent as SuccessIcon } from 'assets/icons/successIcon.svg';
export { ReactComponent as ErrorIcon } from 'assets/icons/errorIcon.svg';
export { ReactComponent as UploadingIcon } from 'assets/icons/uploadingIcon.svg';
export { default as ProfileIcon } from 'assets/icons/profileIcon.svg';
export { ReactComponent as TickFillWarning } from 'assets/icons/tickFillWarning.svg';
export { ReactComponent as TickFillError } from 'assets/icons/tickFillError.svg';
export { ReactComponent as DeleteIconButton } from 'assets/icons/deleteIconButton.svg';
export { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';
export { ReactComponent as DeloitteLogo } from 'assets/icons/deloitte.svg';
export { ReactComponent as DashboardRowDuplicate } from 'assets/icons/dashboardRowDuplicate.svg';
export { default as GroupIcon } from 'assets/icons/groupIcon.svg';
export { default as AzureGroup } from 'assets/icons/azureGroup.svg';
export { default as GcpGroup } from 'assets/icons/gcpGroup.svg';
export { default as AwsGroup } from 'assets/icons/awsGroup.svg';
export { ReactComponent as BarChart } from 'assets/icons/barChart.svg';
export { ReactComponent as LineChart } from 'assets/icons/lineChart.svg';
export { ReactComponent as BarLineChart } from 'assets/icons/barLineChart.svg';
export { ReactComponent as PieChart } from 'assets/icons/pieChart.svg';
export { ReactComponent as DoughnutChart } from 'assets/icons/doughnutChart.svg';
export { ReactComponent as BarStackChart } from 'assets/icons/barStackChart.svg';
export { default as JiraIcon } from 'assets/icons/jiraIcon.svg';
export { default as ServiceNowIcon } from 'assets/icons/serviceNowIcon.svg';
export { ReactComponent as CsvFileIcon } from 'assets/icons/csvFileIcon.svg';
export { ReactComponent as XlsFileIcon } from 'assets/icons/xlsFileIcon.svg';
export { ReactComponent as FileIcon } from 'assets/icons/fileIcon.svg';
export { ReactComponent as DensityExpand } from 'assets/icons/densityExpand.svg';
export { ReactComponent as AnomalyDetectionIcon } from 'assets/icons/anomalyDetection.svg';
export { ReactComponent as ThirdPartyIcon } from 'assets/icons/thirdParty.svg';
export { ReactComponent as GProfilerIcon } from 'assets/icons/gProfiler.svg';
export { ReactComponent as EmptyCartIcon } from 'assets/icons/emptyCartIcon.svg';
export { ReactComponent as AreaChart } from 'assets/icons/areaChart.svg';
export { ReactComponent as Prohibited } from 'assets/icons/prohibited.svg';
export { ReactComponent as ContainerInsightsIcon } from 'assets/icons/containerInsights.svg';
export { ReactComponent as ChatBot } from 'assets/icons/chatBot.svg';
export { ReactComponent as ChatBotWithoutLogo } from 'assets/icons/chatBotWithoutLogo.svg';
export { ReactComponent as GranulateIcon } from 'assets/icons/granulate.svg';
export { default as SnowflakeIcon } from 'assets/icons/snowflake.svg';
export { ReactComponent as ShortLogoWithoutIcon } from 'assets/icons/cb360ShortLogoWithoutIcon.svg';

export const InstancesWithoutLogo: string[] = [Instances.DEV, Instances.QA];

const LoaderIcon = InstancesWithoutLogo.includes(window.location.hostname)
  ? LoaderIconWithoutLogo
  : LoaderIconWithLogo;
export { LoaderIcon };

const Logo = InstancesWithoutLogo.includes(window.location.hostname)
  ? LogoWithoutLogo
  : LogoWithLogo;
export { Logo };

const LogoIcon = InstancesWithoutLogo.includes(window.location.hostname)
  ? logoIconWithoutLogo
  : logoIconWithLogo;
export { LogoIcon };

const logoPng = InstancesWithoutLogo.includes(window.location.hostname)
  ? logoPngWithoutLogo
  : logoPngWithLogo;
export { logoPng };
