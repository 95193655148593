import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';
import { CMDBListType } from 'pages/CmdbSetupListPage/types';

/**
 * @function createCmdbSetup
 * @description Function to create the CMDB setup
 * @return axios response from POST request
 */
export const createCmdbSetup = async (body: CMDBListType) => {
  return ServiceCalls.post(APIS.CREATE_NEW_CMDB_SETUP, body);
};

/**
 * @function updateCmdbSetup
 * @description Function to update the CMDB setup
 * @return axios response from PUT request
 */
export const updateCmdbSetup = async (body: CMDBListType) => {
  return ServiceCalls.put(APIS.UPDATE_CMDB_SETUP, body);
};
