import AccessibleDiv from 'components/AccessibleDiv';
import './index.scss';

type ProviderListCardProps = {
  iconSVG: string | undefined;
  header: string;
  subHeader: string;
  provider: string;
  selectedProvider: string;
  onClickProvider: (value: string) => void;
  disabled?: boolean;
};

const ProviderListCard = ({
  iconSVG,
  header,
  subHeader,
  provider,
  selectedProvider,
  onClickProvider,
  disabled = false,
}: ProviderListCardProps) => (
  <AccessibleDiv
    onClick={() => {
      onClickProvider(provider);
    }}
    className={`card-container flex flex-row full-height flex-align-items-center ${
      selectedProvider && selectedProvider === provider && 'selected'
    } ${disabled && 'disabled'}`}
    data-testid={`${provider}-card`}
  >
    <div className="logo flex flex-center">
      <img className="icon" src={iconSVG} alt={`${provider} Logo`} />
    </div>
    <div className="provider-data-section flex flex-column flex-justify-content-center">
      <div className="title provider-list-typography">{header}</div>
      <div className="sub-title table-typography">{subHeader}</div>
    </div>
  </AccessibleDiv>
);

export default ProviderListCard;
