export enum StorageDashboardGraphs {
  EBS_SPEND_BY_ACCOUNTS = 'ebs-spend-by-accounts',
  EBS_VOLUME_COVERAGE = 'ebs-volume-coverage',
  EBS_STORAGE_MONTHLY_COST = 'ebs-storage-monthly-cost',
  EBS_STORAGE_SPEND = 'ebs-storage-spend',
  EBS_OPERATION_USAGE_COST = 'ebs-operation-usage-cost',
  EBS_SNAPSHOT_SPEND = 'ebs-snapshot-spend',
  EBS_VOLUMES_SPEND = 'ebs-volumes-spend',
  STORAGE_FS_USAGE_COST_BY_ACCOUNT = 'storage-fs-usage-cost-by-account',
  STORAGE_FS_USAGE_COST_BY_REGION = 'storage-fs-usage-cost-by-region',
  STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE = 'storage-fs-usage-cost-by-product-code',
  STORAGE_FS_USAGE_COST_BY_USAGE_TYPE = 'storage-fs-usage-cost-by-usage-type',
  STORAGE_FS_USAGE_COST_BY_OPERATION = 'storage-fs-usage-cost-by-operation',
}
