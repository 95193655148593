import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getAllReports
 * @description Function to fetch all the reports
 * @param params Object containing the request params
 * @param cancelToken token for cancelling the request
 * @return axios response from GET request
 */
export const getAllReports = async (params: any, cancelToken: any) => {
  return ServiceCalls.get(APIS.GET_ALL_REPORTS, null, params, cancelToken);
};

/**
 * @function searchReports
 * @description Function to search the reports
 * @param params Object containing the request params
 * @param cancelToken token for cancelling the request
 * @return axios response from GET request
 */
export const searchReports = async (params: any, cancelToken: any) => {
  return ServiceCalls.get(APIS.SEARCH_REPORTS, null, params, cancelToken);
};

/**
 * @function deleteReport
 * @description Function to delete a report
 * @param params Object containing the request params
 * @return axios response from DELETE request
 */
export const deleteReport = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_REPORT, null, params);
};

/**
 * @function shareReport
 * @description Function to share a report
 * @param requestBody Object containing the request body
 * @return axios response from POST request
 */
export const shareReport = async (requestBody: any) => {
  return ServiceCalls.post(APIS.SHARE_REPORT, requestBody);
};
