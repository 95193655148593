import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';

import {
  selectPurchase,
  setAwsRedshiftRisCartData,
  setAwsRegions,
} from 'redux/purchaseSlice';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import { TERMS_LIST } from 'pages/PurchasePage/constants';
import { PurchaseAwsRedshiftRisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import {
  AWS_REDSHIFT_RI_DEFAULT_VALUES,
  RI_PAYMENT_OPTIONS_LIST,
} from 'pages/PurchasePage/components/ReservedInstances/constants';
import {
  getAwsRedshiftRiNodeTypes,
  getAwsRedshiftRiOfferingDetails,
  getAwsRedshiftRiPaymentOptions,
} from 'pages/PurchasePage/components/ReservedInstances/services';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import {
  convertYearToSeconds,
  getAverageMonthlyRecurringCost,
  getSubTotalDue,
} from 'pages/PurchasePage/components/ReservedInstances/utils';
import { fetchAwsRegions } from 'pages/PurchasePage/services';
import { isEmptyField, isNumber, validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

type BuyRedshiftRiFormModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  editData?: PurchaseAwsRedshiftRisType;
  indexPosition?: number;
};

const BuyRedshiftRiFormModal = ({
  show,
  setShow,
  editData,
  indexPosition,
}: BuyRedshiftRiFormModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, awsRedshiftRiCartData, awsRegions } =
    useSelector(selectPurchase);

  const [redshiftRiFormData, setRedshiftRiFormData] =
    useState<PurchaseAwsRedshiftRisType>(AWS_REDSHIFT_RI_DEFAULT_VALUES);
  const [nodeTypes, setNodeTypes] = useState<string[]>();
  const [fetchNodeTypesReqStatus, setFetchNodeTypesReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchRegionReqStatus, setFetchRegionReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [nodeTypeValidation, setNodeTypeValidation] = useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [paymentOptionValidation, setPaymentOptionValidation] = useState('');
  const [quantityValidation, setQuantityValidation] = useState('');
  const [paymentOptionsList, setPaymentOptionsList] = useState<string[]>([]);
  const [paymentOptionsRequestStatus, setPaymentOptionsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [offeringDetailsRequestStatus, setOfferingDetailsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [offeringDetailsValidation, setOfferingDetailsValidation] =
    useState('');
  const [editLoaded, setEditLoaded] = useState(false);

  useEffect(() => {
    if (editData) {
      setRedshiftRiFormData(editData);
    }

    if (awsRegions.length === 0) {
      getAwsRegions();
    }
  }, []);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (validateRegion(redshiftRiFormData.region, false)) {
      fetchNodeTypes(source);
    }
    return () => {
      source.cancel();
    };
  }, [redshiftRiFormData.region]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (
      validateNodeType(redshiftRiFormData.nodeType, false) &&
      validateRegion(redshiftRiFormData.region, false)
    ) {
      fetchPaymentOptions(source);
    }
    return () => {
      source.cancel();
    };
  }, [
    redshiftRiFormData.nodeType,
    redshiftRiFormData.region,
    redshiftRiFormData.term,
  ]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (editLoaded) {
      setRedshiftRiFormData({
        ...redshiftRiFormData,
        hourlyUsageCharge: 0,
        oneTimePayment: 0,
        offeringId: '',
      });
      setOfferingDetailsValidation('');
    } else {
      setEditLoaded(true);
    }

    if (validateAllFields(false)) {
      fetchOfferingDetails(source);
    }

    return () => {
      source.cancel();
    };
  }, [
    redshiftRiFormData.nodeType,
    redshiftRiFormData.region,
    redshiftRiFormData.term,
    redshiftRiFormData.paymentOption,
    redshiftRiFormData.quantity,
  ]);

  /**
   * @function fetchNodeTypes
   * @description Function to fetch product descriptions data
   * @param cancelTokenSource Cancel token to cancel the api call if there is a call in progress
   */
  const fetchNodeTypes = (cancelTokenSource: CancelTokenSource) => {
    setFetchNodeTypesReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      region: redshiftRiFormData?.region,
      connectorId: selectedConnection?.connectorId,
    };

    getAwsRedshiftRiNodeTypes(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        setNodeTypes(res.data.responseData || []);
        setFetchNodeTypesReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchNodeTypesReqStatus);
      });
  };

  /**
   * @function fetchRegions
   * @description Function to fetch regions data
   */
  const getAwsRegions = () => {
    setFetchRegionReqStatus(REQUEST_STATUS.PROCESSING);

    fetchAwsRegions({ connectorId: selectedConnection?.connectorId })
      .then((res: any) => {
        dispatch(setAwsRegions(res.data.responseData));
        setFetchRegionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchRegionReqStatus);
      });
  };

  /**
   * @function fetchPaymentOptions
   * @description Function to fetch the payment options for the selected node type, region and term
   * @param cancelTokenSource Cancel token to cancel the api call if there is a call in progress
   */
  const fetchPaymentOptions = (cancelTokenSource: CancelTokenSource) => {
    setPaymentOptionsRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      nodeType: redshiftRiFormData.nodeType,
      region: redshiftRiFormData?.region,
      duration: convertYearToSeconds(redshiftRiFormData.term),
      connectorId: selectedConnection?.connectorId,
      reservedNodeOfferingType: 'Regular',
    };

    getAwsRedshiftRiPaymentOptions(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        const data = res?.data?.responseData;
        setPaymentOptionsList(data);
        setPaymentOptionsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setPaymentOptionsList([]);
        onApiCallError(e, false, setPaymentOptionsRequestStatus);
      });
  };

  /**
   * @function fetchOfferingDetails
   * @description Function to fetch the offering details
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchOfferingDetails = (cancelTokenSource: CancelTokenSource) => {
    setOfferingDetailsRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      nodeType: redshiftRiFormData.nodeType,
      region: redshiftRiFormData?.region,
      duration: convertYearToSeconds(redshiftRiFormData.term),
      offeringType: redshiftRiFormData.paymentOption,
      connectorId: selectedConnection?.connectorId,
      quantity: Number(redshiftRiFormData.quantity),
      reservedNodeOfferingType: 'Regular',
    };

    getAwsRedshiftRiOfferingDetails(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          if (data.offeringId) {
            setRedshiftRiFormData({
              ...redshiftRiFormData,
              hourlyUsageCharge: data?.hourlyUsageCharge || 0,
              oneTimePayment: data?.oneTimePayment || 0,
              offeringId: data?.offeringId || '',
            });
            setOfferingDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
            return;
          }
          setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
          setOfferingDetailsValidation(
            t('purchasePage.reservedInstancesLabels.offeringIdNullError')
          );
          return;
        }
        setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
        setOfferingDetailsValidation(
          t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      })
      .catch((e) => {
        onApiCallError(e, false, setOfferingDetailsRequestStatus);
        setOfferingDetailsValidation(
          e?.response?.data?.message ||
            t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      });
  };

  /**
   * @function validateNodeType
   * @description Function to validate the node types field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateNodeType = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.reservedInstancesLabels.selectNodeType`),
      setNodeTypeValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateRegion
   * @description Function to validate the region field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateRegion = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.region`),
      setRegionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validatePaymentOption
   * @description Function to validate the payment option field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validatePaymentOption = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.paymentOptions`),
      setPaymentOptionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateQuantityField
   * @description Function to validate the quantity field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityField = (
    value: number | string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (
      !validateEmptyField(
        value,
        t(`purchasePage.reservedInstancesLabels.quantity`),
        setQuantityValidation,
        addErrorMessage
      )
    ) {
      return (
        isNumber(
          value?.toString(),
          false,
          t(`purchasePage.reservedInstancesLabels.quantity`),
          addErrorMessage ? setQuantityValidation : undefined
        ) && validateQuantityRangeLimit(value, addErrorMessage)
      );
    }

    return false;
  };

  /**
   * @function validateQuantityRangeLimit
   * @description Function to validate the quantity limit and range
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityRangeLimit = (
    value: string | number | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (!value || !isNumber(value.toString(), false)) {
      return false;
    }

    if (Number(value) <= 0) {
      addErrorMessage &&
        setQuantityValidation(
          t('purchasePage.reservedInstancesLabels.quantityLimitError')
        );
      return false;
    }

    addErrorMessage && setQuantityValidation('');

    return true;
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAllFields = (addErrorMessage: boolean = true) => {
    let validation = true;

    if (!validateNodeType(redshiftRiFormData?.nodeType, addErrorMessage)) {
      validation = false;
    }

    if (!validateRegion(redshiftRiFormData?.region, addErrorMessage)) {
      validation = false;
    }

    if (
      !validatePaymentOption(redshiftRiFormData?.paymentOption, addErrorMessage)
    ) {
      validation = false;
    }

    if (!validateQuantityField(redshiftRiFormData?.quantity, addErrorMessage)) {
      validation = false;
    }

    if (
      addErrorMessage &&
      validation &&
      ((isEmptyField(redshiftRiFormData?.hourlyUsageCharge) &&
        isEmptyField(redshiftRiFormData?.oneTimePayment)) ||
        isEmptyField(redshiftRiFormData?.offeringId))
    ) {
      setOfferingDetailsValidation(
        t('purchasePage.reservedInstancesLabels.missingOfferingDetails')
      );
      validation = false;
    }

    return validation;
  };

  /**
   * @function onClickBuy
   * @description Callback function for buy button click
   */
  const onClickBuy = () => {
    if (!validateAllFields()) {
      return;
    }

    if (editData !== undefined && indexPosition !== undefined) {
      const cartData = [...awsRedshiftRiCartData];
      cartData.splice(indexPosition, 1, redshiftRiFormData);
      dispatch(setAwsRedshiftRisCartData(cartData));
    } else {
      dispatch(
        setAwsRedshiftRisCartData([
          ...awsRedshiftRiCartData,
          redshiftRiFormData,
        ])
      );
    }
    setShow(false);
  };

  return (
    <DrawerComponent
      className="redshift-ri-purchase-form"
      width={481}
      open={show}
      title={`${t(
        'purchasePage.reservedInstancesLabels.purchaseReservedInstances'
      )} - ${t('purchasePage.reservedInstancesLabels.redshift')}`}
      onClose={() => setShow(false)}
      footer={
        <PurchaseFooter
          totalHeading={t('purchasePage.cart.subTotalDueNow')}
          totalValue={getSubTotalDue(
            redshiftRiFormData.term,
            redshiftRiFormData.hourlyUsageCharge
          )}
          costSummary={[
            {
              key: t(
                'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
              ),
              value: getAverageMonthlyRecurringCost(
                redshiftRiFormData.term,
                redshiftRiFormData.hourlyUsageCharge
              ),
            },
            {
              key: t('purchasePage.reservedInstancesLabels.oneTimePayment'),
              value: redshiftRiFormData?.oneTimePayment,
            },
          ]}
          okTitle={
            editData
              ? t('purchasePage.cart.update')
              : t('purchasePage.cart.buy')
          }
          onClickCancel={() => setShow(false)}
          onClickOk={onClickBuy}
          loading={offeringDetailsRequestStatus === REQUEST_STATUS.PROCESSING}
          validationMessage={offeringDetailsValidation}
        />
      }
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.region')} required={true} />
          <SelectDropdown
            value={redshiftRiFormData?.region}
            options={awsRegions.map((item) => ({ value: item, label: item }))}
            placeholder={t('purchasePage.cart.selectRegion')}
            loading={fetchRegionReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setRedshiftRiFormData({
                ...redshiftRiFormData,
                region: value,
                nodeType: '',
              });
              validateRegion(value);
            }}
            onBlur={() => {
              validateRegion(redshiftRiFormData?.region);
            }}
            designVersion2
          />
          <span
            style={{
              display: regionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {regionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.nodeType')}
            required={true}
          />
          <SelectDropdown
            value={redshiftRiFormData?.nodeType}
            options={nodeTypes?.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t(
              'purchasePage.reservedInstancesLabels.selectNodeType'
            )}
            loading={fetchNodeTypesReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setRedshiftRiFormData({
                ...redshiftRiFormData,
                nodeType: value,
              });
              validateNodeType(value);
            }}
            onBlur={() => {
              validateNodeType(redshiftRiFormData?.nodeType);
            }}
            designVersion2
          />
          <span
            style={{
              display: nodeTypeValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {nodeTypeValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.term')} required={true} />
          <Radio.Group
            options={TERMS_LIST}
            onChange={(e) => {
              setRedshiftRiFormData({
                ...redshiftRiFormData,
                term: e.target.value,
              });
            }}
            value={redshiftRiFormData.term}
            optionType="button"
            rootClassName="no-custom-style"
            style={{ height: 28 }}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.cart.paymentOptions')}
            required={true}
          />
          <SelectDropdown
            value={redshiftRiFormData?.paymentOption}
            options={RI_PAYMENT_OPTIONS_LIST.filter((item) =>
              paymentOptionsList.includes(item.value)
            )}
            placeholder={t('purchasePage.cart.selectPaymentOption')}
            loading={paymentOptionsRequestStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setRedshiftRiFormData({
                ...redshiftRiFormData,
                paymentOption: value,
              });
              validatePaymentOption(value);
            }}
            onBlur={() => {
              validatePaymentOption(redshiftRiFormData?.paymentOption);
            }}
            designVersion2
          />
          <span
            style={{
              display: paymentOptionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {paymentOptionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.quantity')}
            required={true}
          />
          <Input
            value={redshiftRiFormData?.quantity}
            type="stepNumber"
            rootClassName="width-30"
            steps={1}
            min={1}
            onChange={(value: number) => {
              setRedshiftRiFormData({
                ...redshiftRiFormData,
                quantity: value,
              });
              validateQuantityField(value);
            }}
            onBlur={() => {
              validateQuantityField(redshiftRiFormData?.quantity);
            }}
          />
          <span
            style={{
              display: quantityValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {quantityValidation}
          </span>
        </div>
      </section>
    </DrawerComponent>
  );
};

export default BuyRedshiftRiFormModal;
