import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from 'components/Table';
import ColumnChart from 'components/ColumnChart';
import DashboardComponent from 'components/DashboardComponent';
import {
  generateGraphColors,
  getPreviousMonthAndYear,
} from 'utils/dashboardUtils';
import { MonthlyCostByServiceType } from 'types/dataTypes';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import GraphHeader from 'components/GraphHeader';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { getChartExcelExportData } from 'utils/exportToExcel';

import { getUniqueServices } from './utils';
import { getCostByServiceColumns } from '../../utils';

type CostByGCPServiceProps = {
  requestStatus: string;
  monthlyCostByServiceData: MonthlyCostByServiceType[];
  setSelectedMonthlyCostByServiceData: (
    val: MonthlyCostByServiceType[]
  ) => void;
  isCostByServiceTableView: boolean;
  setIsCostByServiceTableView: (value: boolean) => void;
  selectedMonthlyCostByServiceData: MonthlyCostByServiceType[];
  pdfView?: boolean;
};

const CostByGCPService = ({
  monthlyCostByServiceData,
  setSelectedMonthlyCostByServiceData,
  requestStatus,
  isCostByServiceTableView,
  setIsCostByServiceTableView,
  selectedMonthlyCostByServiceData,
  pdfView,
}: CostByGCPServiceProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const servicesDropdown = (
    <div className="filters">
      <GraphFilterDropdown
        allData={getUniqueServices(monthlyCostByServiceData)}
        selectedData={getUniqueServices(selectedMonthlyCostByServiceData)}
        setSelectedData={(selectedItems: MonthlyCostByServiceType[]) =>
          setSelectedMonthlyCostByServiceData(
            monthlyCostByServiceData.filter((eachService) =>
              selectedItems.some((item) => item.service === eachService.service)
            )
          )
        }
        valueSuffix={t('ytdCostByGCPService.services')}
        fieldName={DROPDOWN_VALUE_FIELDS.SERVICE}
        designVersion2
      />
    </div>
  );

  const getComponent = () =>
    isCostByServiceTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={getTableDataSource().map((value, index) => ({
            ...value,
            key: index,
          }))}
          columns={getCostByServiceColumns(selectedMonthlyCostByServiceData)}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={selectedMonthlyCostByServiceData}
        isStack={true}
        xField="month"
        yField="cost"
        groupingField="service"
        xTitle={t('ytdCostByGCPService.month')}
        yTitle={t('ytdCostByGCPService.cost', {
          currencySymbol: currencySymbol,
        })}
        colorField="service"
        columnColorOverride={generateGraphColors(
          selectedMonthlyCostByServiceData.length
        )}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  /**
   * @function getTableDataSource
   * @description function to get table data source grouped by project
   * @returns object array of table data source
   */
  const getTableDataSource = () => {
    const groupedByProject: any[] = [];
    selectedMonthlyCostByServiceData.forEach((item) => {
      const existingProject = groupedByProject.find(
        (obj) => obj.service === item.service
      );
      if (existingProject) {
        existingProject[`${item.month}-cost`] = item.cost;
      } else {
        groupedByProject.push({
          service: item.service,
          [`${item.month}-cost`]: item.cost,
        });
      }
    });
    return groupedByProject;
  };

  return (
    <div className="graph-card ytd-cost-by-gcp-service" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.ytdCostByGcpService')}
        graphName="ytd-cost-by-gcp-service"
        filters={servicesDropdown}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostByServiceTableView}
        setIsTableView={setIsCostByServiceTableView}
        excelData={getChartExcelExportData(
          t('graphHeadings.ytdCostByGcpService'),
          getTableDataSource(),
          getCostByServiceColumns(selectedMonthlyCostByServiceData),
          {
            connectionName: selectedDashboard!.connectorName,
            startDate: getPreviousMonthAndYear(11),
            endDate: getPreviousMonthAndYear(0),
          }
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default CostByGCPService;
