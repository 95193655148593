import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionTagsListType } from 'pages/TagMappingPage/types';
import { RootState } from './store';

export interface DimensionMappingSlice {
  allConnectionsMap: ConnectionTagsListType[];
}

const initialState: DimensionMappingSlice = {
  allConnectionsMap: [],
};

export const dimensionMappingSlice = createSlice({
  name: 'dimensionMapping',
  initialState,
  reducers: {
    setAllConnectionsMap: (
      state,
      action: PayloadAction<ConnectionTagsListType[]>
    ) => {
      state.allConnectionsMap = action.payload;
    },
  },
});

export const selectDimensionMapping = (state: RootState) =>
  state.dimensionMapping;
export const { setAllConnectionsMap } = dimensionMappingSlice.actions;
export default dimensionMappingSlice.reducer;
