import { useState } from 'react';

import AccessibleDiv from 'components/AccessibleDiv';

import './index.scss';

type TagComponentProps = {
  tag: string;
  priority?: number;
  onClickTag: () => void;
};

const TagComponent = ({ tag, priority, onClickTag }: TagComponentProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <AccessibleDiv
      key={tag}
      className={`tag-item cursor-pointer flex flex-align-items-center flex-gap-8 ${
        priority && 'checked'
      } ${isHover && 'active'}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClickTag}
    >
      <div className="tag-checkbox font-small-bold flex flex-center">
        {priority}
      </div>
      <div className="tag-value">{tag}</div>
    </AccessibleDiv>
  );
};

export default TagComponent;
