import i18n from 'i18n';

export enum DataCenterComponents {
  DATA_CENTER_TABLE = 'DATA_CENTER_TABLE',
  MANUAL_ENTRY = 'MANUAL_ENTRY',
}

export enum ManualEntryNavs {
  COMPUTE = 'COMPUTE',
  STORAGE = 'STORAGE',
  NETWORK = 'NETWORK',
  DATABASE = 'DATABASE',
  SOFTWARE = 'SOFTWARE',
  LABOUR = 'LABOUR',
}

export enum PurchaseModes {
  RENTAL_OR_LEASE = 'RENTAL_LEASE',
  PERMANENT_PURCHASE = 'PERMANENT_PURCHASE',
}

export const MODE_OF_PURCHASES = [
  {
    key: PurchaseModes.RENTAL_OR_LEASE,
    label: i18n.t('manualEntry.computeLabels.rentalOrLease'),
  },
  {
    key: PurchaseModes.PERMANENT_PURCHASE,
    label: i18n.t('manualEntry.computeLabels.permanentPurchase'),
  },
];

export enum HostOs {
  WINDOWS = 'Windows',
}

export enum HypervisorUsed {
  REDHAT_KVM = 'RedHatKVM',
}

export enum DbEngineType {
  MYSQL = 'MySQL',
}

export enum SoftwareSubscriptionTypes {
  SUBSCRIPTION = 'SUBSCRIPTION',
  PERMANENT_PURCHASE = 'PERMANENT_PURCHASE',
}

export enum EmploymentTypes {
  CONTRACTUAL = 'CONTRACTUAL',
  PERMANENT = 'PERMANENT',
}

export const VALIDTAION_REGEX = {
  INTEGER: { regex: /^\d+$/, errorInfo: 'Positive Integer' },
  FLOAT: { regex: /^-?\d+(?:[.,]\d*?)?$/, errorInfo: 'Positive Float' },
  DDMMYYYY: {
    regex: /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/,
    errorInfo: 'DD/MM/YYYY or DD/MM/YY',
  },
  PERCENTAGE1_100: {
    regex: /^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/,
    errorInfo: '0.00 to 100.00',
  },
  ALPHABET_SPACE_ONLY: { regex: /^[A-Za-z ]+$/, errorInfo: 'Letter words' },
  PURCHASE_MODE: {
    regex: new RegExp(
      Object.values(PurchaseModes)
        .map((value) => value + '$')
        .join('|')
    ),
    errorInfo: Object.values(PurchaseModes).join(' or '),
  },
  HOST_OS: {
    regex: new RegExp(
      Object.values(HostOs)
        .map((value) => value + '$')
        .join('|')
    ),
    errorInfo: Object.values(HostOs).join(' or '),
  },
  HYPERVISOR_USED: {
    regex: new RegExp(
      Object.values(HypervisorUsed)
        .map((value) => value + '$')
        .join('|')
    ),
    errorInfo: Object.values(HypervisorUsed).join(' or '),
  },
  DB_ENGINE_TYPE: {
    regex: new RegExp(
      Object.values(DbEngineType)
        .map((value) => value + '$')
        .join('|')
    ),
    errorInfo: Object.values(DbEngineType).join(' or '),
  },
  SUBSCRIPTION_TYPE: {
    regex: new RegExp(
      Object.values(SoftwareSubscriptionTypes)
        .map((value) => value + '$')
        .join('|')
    ),
    errorInfo: Object.values(SoftwareSubscriptionTypes).join(' or '),
  },
  EMPLOYMENT_TYPE: {
    regex: new RegExp(
      Object.values(EmploymentTypes)
        .map((value) => value + '$')
        .join('|')
    ),
    errorInfo: Object.values(EmploymentTypes).join(' or '),
  },
};

export const EXCEL_COLUMNS = [
  {
    name: i18n.t('uploadTcoFile.validation.dataCenterColumn'),
    requiredValidation: true,
    requestField: 'dataCenterCode',
  },
  {
    name: i18n.t('manualEntry.computeLabels.noOfVms'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'vmCount',
  },
  {
    name: i18n.t('manualEntry.computeLabels.avgvCpuPerVm'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.PERCENTAGE1_100,
    requestField: 'vcpuPerVmInPercentage',
  },
  {
    name: i18n.t('manualEntry.computeLabels.avgMemoryPerVm'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'ramPerVmInGB',
  },
  {
    name: i18n.t('manualEntry.computeLabels.hostOs'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.HOST_OS,
    requestField: 'hostOS',
  },
  {
    name: i18n.t('manualEntry.computeLabels.hypervisorUsed'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.HYPERVISOR_USED,
    requestField: 'hypervisor',
  },
  {
    name: i18n.t('manualEntry.computeLabels.dateOfPurchase'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.DDMMYYYY,
    requestField: 'dateOfPurchase',
  },
  {
    name: i18n.t('manualEntry.computeLabels.modeOfPurchase'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.PURCHASE_MODE,
    requestField: 'modeOfPurchase',
  },
  {
    name: i18n.t('manualEntry.computeLabels.duration'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'durationInMonths',
  },
  {
    name: i18n.t('manualEntry.computeLabels.cost'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'monthlyCost',
  },
  {
    name: i18n.t('manualEntry.storageLabels.name'),
    requiredValidation: true,
    requestField: 'name',
  },
  {
    name: i18n.t('manualEntry.storageLabels.totalStorage'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'totalStorageInGB',
  },
  {
    name: i18n.t('manualEntry.networkLabels.dataTransferEgress'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'dataTransferEgressInGb',
  },
  {
    name: i18n.t('manualEntry.databaseLabels.numberOfOnPremDbInstances'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'noOfOnPremDBInstances',
  },
  {
    name: i18n.t('manualEntry.databaseLabels.dbEngineType'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.DB_ENGINE_TYPE,
    requestField: 'dbEngineType',
  },
  {
    name: i18n.t('manualEntry.databaseLabels.numberOfDbLicenses'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'noOfDbLicenses',
  },
  {
    name: i18n.t('manualEntry.softwareLabels.licenceNo'),
    requiredValidation: true,
    requestField: 'licenseNo',
  },
  {
    name: i18n.t('manualEntry.softwareLabels.noOfUsers'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'noOfUsers',
  },
  {
    name: i18n.t('manualEntry.softwareLabels.type'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.SUBSCRIPTION_TYPE,
    requestField: 'subscriptionType',
  },
  {
    name: i18n.t('manualEntry.softwareLabels.cost'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'cost',
  },
  {
    name: i18n.t('manualEntry.laborLabels.employeeName'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.ALPHABET_SPACE_ONLY,
    requestField: 'empName',
  },
  {
    name: i18n.t('manualEntry.laborLabels.employmentType'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.EMPLOYMENT_TYPE,
    requestField: 'employmentType',
  },
  {
    name: i18n.t('manualEntry.laborLabels.monthlySalary'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'salaryCost',
  },
  {
    name: i18n.t('manualEntry.laborLabels.startDate'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.DDMMYYYY,
    requestField: 'startDate',
  },
  {
    name: i18n.t('manualEntry.laborLabels.endDate'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.DDMMYYYY,
    requestField: 'endDate',
  },
  {
    name: i18n.t('manualEntry.laborLabels.monthlySalary'),
    requiredValidation: true,
    regexValidation: VALIDTAION_REGEX.INTEGER,
    requestField: 'salaryCost',
  },
];

export const RESOURCE_REQUIRED_COLUMNS = [
  {
    name: ManualEntryNavs.COMPUTE,
    columns: [
      i18n.t('uploadTcoFile.validation.dataCenterColumn'),
      i18n.t('manualEntry.computeLabels.noOfVms'),
      i18n.t('manualEntry.computeLabels.avgvCpuPerVm'),
      i18n.t('manualEntry.computeLabels.avgMemoryPerVm'),
      i18n.t('manualEntry.computeLabels.hostOs'),
      i18n.t('manualEntry.computeLabels.hypervisorUsed'),
      i18n.t('manualEntry.computeLabels.dateOfPurchase'),
      i18n.t('manualEntry.computeLabels.modeOfPurchase'),
      i18n.t('manualEntry.computeLabels.duration'),
      i18n.t('manualEntry.computeLabels.cost'),
    ],
  },
  {
    name: ManualEntryNavs.STORAGE,
    columns: [
      i18n.t('uploadTcoFile.validation.dataCenterColumn'),
      i18n.t('manualEntry.storageLabels.name'),
      i18n.t('manualEntry.storageLabels.totalStorage'),
      i18n.t('manualEntry.storageLabels.modeOfPurchase'),
      i18n.t('manualEntry.storageLabels.dateOfPurchase'),
      i18n.t('manualEntry.storageLabels.duration'),
      i18n.t('manualEntry.storageLabels.cost'),
    ],
  },
  {
    name: ManualEntryNavs.NETWORK,
    columns: [
      i18n.t('uploadTcoFile.validation.dataCenterColumn'),
      i18n.t('manualEntry.networkLabels.dataTransferEgress'),
      i18n.t('manualEntry.networkLabels.modeOfPurchase'),
      i18n.t('manualEntry.networkLabels.dateOfPurchase'),
      i18n.t('manualEntry.networkLabels.duration'),
      i18n.t('manualEntry.networkLabels.cost'),
    ],
  },
  {
    name: ManualEntryNavs.DATABASE,
    columns: [
      i18n.t('uploadTcoFile.validation.dataCenterColumn'),
      i18n.t('manualEntry.databaseLabels.numberOfOnPremDbInstances'),
      i18n.t('manualEntry.databaseLabels.dbEngineType'),
      i18n.t('manualEntry.databaseLabels.numberOfDbLicenses'),
      i18n.t('manualEntry.databaseLabels.modeOfPurchase'),
      i18n.t('manualEntry.databaseLabels.dateOfPurchase'),
      i18n.t('manualEntry.databaseLabels.duration'),
      i18n.t('manualEntry.databaseLabels.cost'),
    ],
  },
  {
    name: ManualEntryNavs.SOFTWARE,
    columns: [
      i18n.t('uploadTcoFile.validation.dataCenterColumn'),
      i18n.t('manualEntry.softwareLabels.name'),
      i18n.t('manualEntry.softwareLabels.licenceNo'),
      i18n.t('manualEntry.softwareLabels.noOfUsers'),
      i18n.t('manualEntry.softwareLabels.type'),
      i18n.t('manualEntry.softwareLabels.startDate'),
      i18n.t('manualEntry.softwareLabels.endDate'),
      i18n.t('manualEntry.softwareLabels.cost'),
    ],
  },
  {
    name: ManualEntryNavs.LABOUR,
    columns: [
      i18n.t('uploadTcoFile.validation.dataCenterColumn'),
      i18n.t('manualEntry.laborLabels.employeeName'),
      i18n.t('manualEntry.laborLabels.employmentType'),
      i18n.t('manualEntry.laborLabels.startDate'),
      i18n.t('manualEntry.laborLabels.endDate'),
      i18n.t('manualEntry.laborLabels.monthlySalary'),
    ],
  },
];

export enum ValidationStatus {
  SUCCESS = 'SUCCESS',
  MISSING_COLS = 'MISSING_COLS',
  NO_ROWS_DETECTED = 'NO_ROWS_DETECTED',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}
