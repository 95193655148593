import { Button as BaseButton } from 'antd';
import Icon from 'components/Icon';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import { ICONS_SIZE } from 'constants/icons';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import {
  changeAlphaOfColor,
  getIconTextColorByBackground,
} from 'utils/dashboardUtils';

const Button = ({
  title,
  type = BUTTON_TYPE.PRIMARY,
  disabled,
  size = BUTTON_SIZE.LARGE,
  iconName,
  icon,
  iconSize = ICONS_SIZE.SM,
  className,
  reverseIconTitleOrder = false,
  buttonTitleClassName,
  ...remainingProps
}: any) => {
  const { theme } = useSelector(selectTheme);

  const getClassnameBySize = () => {
    switch (size) {
      case BUTTON_SIZE.SMALL:
        return 'btn-small';

      case BUTTON_SIZE.MEDIUM:
        return 'btn-medium';

      case BUTTON_SIZE.LARGE:
      default:
        return 'btn-large';
    }
  };

  /**
   * @function getStyleByType
   * @description Function to get the style of button based on type.
   * @returns style object for button
   */
  const getStyleByType = () => {
    switch (type) {
      case BUTTON_TYPE.NEUTRAL:
        return {
          color: theme.buttonIconColor,
          backgroundColor: changeAlphaOfColor(theme.buttonIconColor, 15),
        };
      case BUTTON_TYPE.OUTLINED:
        return {
          color: theme.buttonIconColor,
          border: `1px solid ${theme.buttonIconColor}`,
        };
      case BUTTON_TYPE.LINK:
        return {
          color: theme.buttonIconColor,
        };
      case BUTTON_TYPE.PRIMARY:
      default:
        return {
          backgroundColor: theme.buttonIconColor,
          color: getIconTextColorByBackground(theme.buttonIconColor),
        };
    }
  };

  return (
    <BaseButton
      style={getStyleByType()}
      className={`flex flex-center ${getClassnameBySize()} ${
        disabled && 'btn-disabled cursor-disabled'
      } ${className}`}
      type={type}
      disabled={disabled}
      {...remainingProps}
    >
      <div className={`flex flex-center flex-gap-4 ${buttonTitleClassName}`}>
        {!reverseIconTitleOrder && title}
        {iconName && (
          <Icon
            iconName={iconName}
            color={
              type === BUTTON_TYPE.PRIMARY
                ? getIconTextColorByBackground(theme.buttonIconColor)
                : theme.buttonIconColor
            }
            size={iconSize}
          />
        )}
        {icon}
        {reverseIconTitleOrder && title}
      </div>
    </BaseButton>
  );
};

export default Button;
