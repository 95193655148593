import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { message } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { useDispatch, useSelector } from 'react-redux';

import { dataCenter, setSoftwareEntries } from 'redux/dataCenterSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { SoftwareEntryListType } from 'pages/DataCenterPage/types';
import {
  deleteSoftwareResource,
  getAllSoftwareResources,
} from 'pages/DataCenterPage/services';
import DeleteModal from 'components/DeleteModal';
import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import Pagination from 'components/Pagination';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { ERROR_KEY, REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { MONTH_YEAR_FORMAT } from 'utils/date';

import {
  EntryQuickActions,
  ENTRY_QUICKACTIONS,
  TypesOfSoftwareSubscriptions,
} from '../ManualEntry/constants';
import EntryTableHeader from '../EntryTableHeader';
import SoftwareRowModal from '../SoftwareRowModal';

const SoftwareEntry = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { softwareEntries, selectedDataCenter } = useSelector(dataCenter);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [totalCount, setTotalCount] = useState(0);
  const [currentSoftwareEntry, setCurrentSoftwareEntry] =
    useState<SoftwareEntryListType>();
  const [showSoftwareRowModal, setShowSoftwareRowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [softwareEntryDataRequestStatus, setSoftwareEntryDataRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [
    deleteSoftwareResourceRequestStatus,
    setDeleteSoftwareResourceRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    fetchSoftwareEntryData();
  }, []);

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    fetchSoftwareEntryData(page);
  };

  /**
   * @function fetchSoftwareEntryData
   * @description Function to fetch paginated software entry data
   * @param page page number defaults to first page
   */
  const fetchSoftwareEntryData = (page: number = 1) => {
    setSoftwareEntryDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      dataCenterCode: selectedDataCenter?.dataCenterCode,
      page: page - 1,
      size: PAGINATION_SIZE,
    };

    getAllSoftwareResources(params)
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(setSoftwareEntries(res.data.responseData.content));
          setTotalCount(res.data.responseData.totalElements);
          setSoftwareEntryDataRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setSoftwareEntryDataRequestStatus(REQUEST_STATUS.ERROR);
        message.error({ content: t('apiCallErrorMessage'), key: ERROR_KEY });
      })
      .catch((e) => {
        onApiCallError(e, true, setSoftwareEntryDataRequestStatus);
      });
  };

  /**
   * @function deleteSoftwareEntry
   * @description Function to delete a software entry
   */
  const deleteSoftwareEntry = () => {
    setDeleteSoftwareResourceRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      id: currentSoftwareEntry?.id,
    };

    deleteSoftwareResource(params)
      .then((res: any) => {
        if (res?.status === 200) {
          message.success(
            t('manualEntry.softwareLabels.deleteSoftwareSuccess')
          );
          setDeleteSoftwareResourceRequestStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          onSaveOrUpdateEntry();
          return;
        }
        message.error({
          content:
            res?.message ??
            t('manualEntry.softwareLabels.deleteSoftwareFailed'),
        });
        setShowDeleteModal(false);
        setDeleteSoftwareResourceRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteSoftwareResourceRequestStatus,
          e?.response?.data?.message ??
            t('manualEntry.softwareLabels.deleteSoftwareFailed')
        );
        setShowDeleteModal(false);
      });
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick action buttons from the table
   * @param action : The clicked action
   */
  const handleQuickAction = (action: string) => {
    switch (action) {
      case EntryQuickActions.EDIT:
        setShowSoftwareRowModal(true);
        break;
      case EntryQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  /**
   * @function onSaveOrUpdateEntry
   * @description Callback function for save or update software entry
   */
  const onSaveOrUpdateEntry = () => {
    setCurrentPage(1);
    fetchSoftwareEntryData();
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + (index + 1),
      width: '4%',
    },
    {
      title: t('manualEntry.softwareLabels.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('manualEntry.softwareLabels.licenceNo'),
      dataIndex: 'licenseNo',
      key: 'licenseNo',
    },
    {
      title: t('manualEntry.softwareLabels.noOfUsers'),
      dataIndex: 'noOfUsers',
      key: 'noOfUsers',
    },
    {
      title: t('manualEntry.softwareLabels.type'),
      dataIndex: 'subscriptionType',
      key: 'subscriptionType',
      render: (text: string) =>
        TypesOfSoftwareSubscriptions.find((item) => item.key === text)?.label,
    },
    {
      title: t('manualEntry.softwareLabels.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text: string) => moment(text).format(MONTH_YEAR_FORMAT),
    },
    {
      title: t('manualEntry.softwareLabels.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text: string) =>
        text ? moment(text).format(MONTH_YEAR_FORMAT) : '--',
    },
    {
      title: t('manualEntry.softwareLabels.cost'),
      dataIndex: 'cost',
      key: 'cost',
      render: (text: string) =>
        `${currencySymbol} ${numberCommaSeparator(text)}`,
    },
    {
      title: t('manualEntry.softwareLabels.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '8%',
      render: (_text: string, record: any) => {
        return (
          <QuickActionMenu
            setCurrentConnectionData={() => setCurrentSoftwareEntry(record)}
            quickActions={ENTRY_QUICKACTIONS}
            quickActionHandler={(action: string) => {
              handleQuickAction(action);
            }}
          />
        );
      },
      align: 'center',
    },
  ];

  return (
    <div className="manual-entry-tab-container card flex flex-column flex-gap-16">
      <EntryTableHeader
        title={t('manualEntry.softwareLabels.tableTitle')}
        onClickAddRow={() => {
          setShowSoftwareRowModal(true);
          setCurrentSoftwareEntry(undefined);
        }}
      />
      <Table
        rootClassName="manual-entry-table"
        pagination={false}
        columns={columns}
        dataSource={softwareEntries.map((item, index) => ({
          key: index,
          ...item,
        }))}
        loading={softwareEntryDataRequestStatus === REQUEST_STATUS.PROCESSING}
        scroll={{ y: '100%', x: '100%' }}
        designVersion2
        fillContainer
      />
      <div className="page-footer flex">
        {totalCount > PAGINATION_SIZE && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </div>
      {showSoftwareRowModal && (
        <SoftwareRowModal
          show={showSoftwareRowModal}
          setShow={setShowSoftwareRowModal}
          onSaveOrUpdateEntry={onSaveOrUpdateEntry}
          softwareEntry={currentSoftwareEntry}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={deleteSoftwareEntry}
          loading={
            deleteSoftwareResourceRequestStatus === REQUEST_STATUS.PROCESSING
          }
        />
      )}
    </div>
  );
};

export default SoftwareEntry;
