import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function createSnowflakeIntegration
 * @description Function to create Snowflake Integration
 * @param data request body for POST request.
 * @returns axios response from POST request
 */
export const createSnowflakeIntegration = async (data: any) => {
  return ServiceCalls.post(APIS.SNOWFLAKE_INTEGRATION, data);
};

/**
 * @function updateSnowflakeIntegration
 * @description Function to update Snowflake Integration
 * @param data request body for PUT request.
 * @returns axios response from PUT request
 */
export const updateSnowflakeIntegration = async (data: any) => {
  return ServiceCalls.put(APIS.SNOWFLAKE_INTEGRATION, data);
};

/**
 * @function deleteSnowflakeIntegrations
 * @description Function to delete Snowflake Integration data by id
 * @param params request params for DELETE request.
 * @return axios response from DELETE request
 */
export const deleteSnowflakeIntegrations = async (params: any) => {
  return ServiceCalls.delete(APIS.SNOWFLAKE_INTEGRATION, null, params);
};
