import i18n from 'i18n';

import { ItemOptionsType } from 'components/DropdownCheckbox/types';
import { ChartCriteria } from 'constants/dashboard';
import { store } from 'redux/store';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const AwsCloudSpendColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountIds'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: i18n.t('costInCurrency', { currencySymbol }),
    dataIndex: 'cost',
    key: 'cost',
    width: 80,
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
    align: 'right' as const,
  },
];

export const CostByAwsServiceColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('costByAwsService.services'),
    dataIndex: 'service',
    key: 'service',
  },
  {
    title: i18n.t('costByAwsService.costInCurrency', { currencySymbol }),
    dataIndex: 'cost',
    key: 'cost',
    width: 80,
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
    align: 'right' as const,
  },
];

export const UsageSpendColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    width: '5%',
    render: (_text: any, _record: any, index: number) => index + 1,
  },
  {
    title: i18n.t('usageSpend.usageType'),
    dataIndex: 'usage_type',
    key: 'usage_type',
    width: '20%',
  },
  {
    title: i18n.t('usageSpend.spend'),
    dataIndex: 'cost',
    key: 'cost',
    width: '5%',
    align: 'right' as const,
    render: (text: any) => {
      return currencySymbol + numberCommaSeparator(text);
    },
  },
];

export const CHART_CRITERIA_LIST: ItemOptionsType[] = [
  {
    title: i18n.t('timePeriod'),
    value: ChartCriteria.TIME_PERIOD,
  },
  {
    title: i18n.t('service'),
    value: ChartCriteria.SERVICE,
  },
  {
    title: i18n.t('region'),
    value: ChartCriteria.REGION,
  },
  {
    title: i18n.t('account'),
    value: ChartCriteria.ACCOUNT,
  },
  {
    title: i18n.t('spendProfile'),
    value: ChartCriteria.SPEND_PROFILE,
  },
  {
    title: i18n.t('savingsPlan'),
    value: ChartCriteria.SAVINGS_PLAN,
  },
];
