import { useTranslation } from 'react-i18next';

import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';

type HpaPolicyProps = {
  recommendation: RecommendationList;
};

const HpaPolicy = ({ recommendation }: HpaPolicyProps) => {
  const { t } = useTranslation();

  if (
    !recommendation.hpa ||
    (!recommendation.hpa.recommended_cpu && !recommendation.hpa.recommended_mem)
  ) {
    return <div>N/A</div>;
  }

  return (
    <div>
      {recommendation.hpa.recommended_cpu && (
        <span key={recommendation.namespace}>
          {t('granulateInsightDashboard.cpu')}:{' '}
          {recommendation.hpa.recommended_cpu}
        </span>
      )}
      {recommendation.hpa.recommended_mem &&
        recommendation.hpa.recommended_cpu &&
        ' / '}
      {recommendation.hpa.recommended_mem && (
        <span key={recommendation.namespace}>
          {t('granulateInsightDashboard.memory')}:{' '}
          {recommendation.hpa.recommended_mem}
        </span>
      )}
    </div>
  );
};

export default HpaPolicy;
