import { PropsWithChildren, ReactElement } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { render } from '@testing-library/react';
import { BrowserRouter } from 'react-router-dom';

import { Config } from 'config';
import commonUtilityReducer from 'redux/commonUtilitySlice';
import activeMenuReducer from 'redux/activeNavMenuSlice';
import userAuthorizationReducer from 'redux/authorizationSlice';
import dashboardReducer from 'redux/dashboardSlice';
import budgetsAndAlertsReducer from 'redux/budgetsAndAlertsSlice';
import cloudConnectionReducer from 'redux/cloudConnectionSlice';
import providerReducer from 'redux/providerSlice';
import costAllocationReducer from 'redux/costAllocationSlice';
import customDashboardReducer from 'redux/customDashboardSlice';
import successAndErrorPageReducer from 'redux/successAndErrorPageSlice';
import importStaticFilesReducer from 'redux/importStaticFileSlice';
import costAllocationDashboardReducer from 'redux/costAllocationDashboardSlice';
import ticketIntegrationReducer from 'redux/ticketIntegrationSlice';
import costOptimizationInsightsReducer from 'redux/costOptimizationInsightsSlice';
import tagsAndLabelReducer from 'redux/tagsAndLabelsSlice';
import dataCenterReducer from 'redux/dataCenterSlice';
import tcoDashboardReducer from 'redux/tcoDashboardSlice';
import containerInsightsReducer from 'redux/containerInsightsSlice';
import ruleEngineReducer from 'redux/ruleEngineSlice';
import themeReducer from 'redux/themeSlice';
import costMonitorReducer from 'redux/costMonitorSlice';
import anomalyDetectionDashboardReducer from 'redux/anomalyDetectionDashboardSlice';
import scorecardReducer from 'redux/scorecardSlice';
import gProfilerReducer from 'redux/gProfilerSlice';
import purchaseReducer from 'redux/purchaseSlice';
import gProfilerConnectionReducer from 'redux/gProfilerConnectionSlice';
import gProfilerInsightsReducer from 'redux/gProfilerInsights';
import reportReducer from 'redux/reportSlice';
import chatBotReducer from 'redux/chatBotSlice';
import granulateReducer from 'redux/granulateSlice';
import granulateInsightsReducer from 'redux/granulateInsightsSlice';
import granulateConnectionReducer from 'redux/granulateConnectionSlice';
import dimensionMappingReducer from 'redux/dimensionMappingSlice';
import releaseNotesReducer from 'redux/releaseNotesSlice';
import tagComplianceReducer from 'redux/tagComplianceSlice';
import cmdbSetupReducer from 'redux/cmdbSetupSlice';

export function renderWithProviders(
  ui: ReactElement,
  {
    initialState = {},
    store = configureStore({
      reducer: {
        commonUtility: commonUtilityReducer,
        activeMenu: activeMenuReducer,
        userAuthorization: userAuthorizationReducer,
        dashboard: dashboardReducer,
        budgetsAndAlerts: budgetsAndAlertsReducer,
        tagsAndLabels: tagsAndLabelReducer,
        cloudConnection: cloudConnectionReducer,
        provider: providerReducer,
        costAllocation: costAllocationReducer,
        customDashboard: customDashboardReducer,
        successAndErrorPage: successAndErrorPageReducer,
        importStaticFiles: importStaticFilesReducer,
        costAllocationDashboard: costAllocationDashboardReducer,
        ticketIntegration: ticketIntegrationReducer,
        costOptimizationInsights: costOptimizationInsightsReducer,
        dataCenter: dataCenterReducer,
        tcoDashboard: tcoDashboardReducer,
        containerInsights: containerInsightsReducer,
        ruleEngine: ruleEngineReducer,
        theme: themeReducer,
        costMonitor: costMonitorReducer,
        anomalyDetectionDashboard: anomalyDetectionDashboardReducer,
        scorecardReducer: scorecardReducer,
        gProfiler: gProfilerReducer,
        purchase: purchaseReducer,
        gProfilerConnection: gProfilerConnectionReducer,
        gProfilerInsights: gProfilerInsightsReducer,
        report: reportReducer,
        chatBot: chatBotReducer,
        granulate: granulateReducer,
        granulateConnection: granulateConnectionReducer,
        granulateInsights: granulateInsightsReducer,
        dimensionMapping: dimensionMappingReducer,
        releaseNotes: releaseNotesReducer,
        tagCompliance: tagComplianceReducer,
        cmdbSetup: cmdbSetupReducer,
      },
      preloadedState: initialState,
    }),
    ...renderOptions
  }: any = {}
) {
  function Wrapper({ children }: Readonly<PropsWithChildren<{}>>) {
    return (
      <Provider store={store}>
        <BrowserRouter>{children}</BrowserRouter>
      </Provider>
    );
  }

  // Return an object with the store and all of RTL's query functions
  return {
    store,
    ...render(ui, {
      wrapper: Wrapper,
      ...renderOptions,
    }),
  };
}

export const getEndpointFromUrl = (url: string) => {
  return Config.be_service_url
    ? url.slice(Config.be_service_url.length)
    : url.slice('undefined'.length);
};
