import i18n from 'i18n';

export enum IntegrationsQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const INTEGRATIONS_QUICK_ACTIONS = [
  {
    id: IntegrationsQuickActions.EDIT,
    title: i18n.t('integrations.edit'),
  },
  {
    id: IntegrationsQuickActions.DELETE,
    title: i18n.t('integrations.delete'),
  },
];

export enum IntegrationsPageComponent {
  TABLE,
  FORM,
  SUCCESS,
  ERROR,
}

export enum IntegrationTypes {
  SNOWFLAKE = 'SNOWFLAKE',
}

export enum IntegrationStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export const INTEGRATION_STATUS_LABELS = [
  {
    id: IntegrationStatus.ACTIVE,
    label: i18n.t('integrations.active'),
  },
  {
    id: IntegrationStatus.DEACTIVATED,
    label: i18n.t('integrations.deactivated'),
  },
];

export const INTEGRATION_PROVIDER_LIST_DATA = [
  {
    provider: IntegrationTypes.SNOWFLAKE,
    header: 'snowflakeHeader',
    subHeader: 'snowflakeSubHeader',
  },
];
