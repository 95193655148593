import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Divider, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTheme,
  setAutoGenerateChartColors,
  setManualChartColors,
  setTheme,
} from 'redux/themeSlice';
import Button from 'components/Button';
import { ROLES } from 'constants/roles';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import { validateHexColorField } from 'utils/validations';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import ChartAppearance from './components/ChartColorPrefrences';
import GlobalAppearance from './components/GlobalAppearance';
import {
  CUSTOM_PALLETE_NAME_FIELD,
  STATIC_COLORS_FOR_CHART,
} from './constants';
import {
  createCustomPallete,
  fetchChartColorPreferences,
  fetchDefaultPallete,
  fetchStaticPalletes,
  saveChartColors,
  saveDefaultPallete,
} from './services';
import { ColorPalleteType, ManualChartColorsType } from './types';

import './index.scss';

const AppearancePage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'appearance' });
  const dispatch = useDispatch();
  const { theme } = useSelector(selectTheme);

  // Theme states
  const [staticPalletes, setStaticPalletes] = useState<ColorPalleteType[]>([]);
  const [defaultPallete, setDefaultPallete] = useState<ColorPalleteType>();
  const [selectedPallete, setSelectedPallete] = useState<ColorPalleteType>();
  const [isCustomTheme, setIsCustomTheme] = useState(false);
  const [customPallete, setCustomPallete] = useState<ColorPalleteType>({
    ...theme,
    templateName: CUSTOM_PALLETE_NAME_FIELD,
  });

  // Charts Appearance states
  const [autoGenerateColors, setAutoGenerateColors] = useState(true);
  const [manualColors, setManualColors] = useState<ManualChartColorsType[]>([]);
  const [manualColorsValidation, setManualColorsValidation] = useState('');

  // Request statuses
  const [saveThemeRequestStatus, setSaveThemeRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [saveChartColorRequestStatus, setSaveChartColorRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [staticPalletesRequestStatus, setStaticPalletesRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [defaultPalletesRequestStatus, setDefaultPalletesRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [chartColorRequestStatus, setChartColorRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    if (localStorage.getItem('role') === ROLES.ADMIN) {
      getStaticPalletes();
      getDefaultPallete();
    }
    getChartColorPreference();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    if (defaultPallete) {
      if (
        !staticPalletes
          .map((item) => item.templateId)
          .includes(defaultPallete.templateId)
      ) {
        setIsCustomTheme(true);
        setCustomPallete({
          ...defaultPallete,
        });
        return;
      }
      setSelectedPallete(defaultPallete);
      setIsCustomTheme(false);
    }
  }, [staticPalletes, defaultPallete]);

  /**
   * @function getDefaultPallete
   * @description Function to fetch static palletes
   */
  const getDefaultPallete = () => {
    setDefaultPalletesRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchDefaultPallete()
      .then((res: any) => {
        if (res.status === 200) {
          setDefaultPallete(res?.data?.responseData);
          setDefaultPalletesRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setDefaultPalletesRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, true, setDefaultPalletesRequestStatus);
      });
  };

  /**
   * @function getStaticPalletes
   * @description Function to fetch static palletes
   */
  const getStaticPalletes = () => {
    setStaticPalletesRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchStaticPalletes()
      .then((res: any) => {
        if (res.status === 200) {
          setStaticPalletes(res?.data?.responseData);
          setStaticPalletesRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setStaticPalletesRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, true, setStaticPalletesRequestStatus);
      });
  };

  /**
   * @function getChartColorPreference
   * @description Function to fetch static palletes
   */
  const getChartColorPreference = () => {
    setChartColorRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchChartColorPreferences()
      .then((res: any) => {
        if (res.status === 200) {
          setAutoGenerateColors(res?.data?.responseData?.autoGenerate);
          setManualColors(res?.data?.responseData?.selectedColors);
          setChartColorRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setChartColorRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, true, setChartColorRequestStatus);
      });
  };

  /**
   * @function saveChartAppearance
   * @description function to save chart appearance in the backend
   */
  const saveChartAppearance = () => {
    setSaveChartColorRequestStatus(REQUEST_STATUS.PROCESSING);
    const body = {
      autoGenerate: autoGenerateColors,
      selectedColors: autoGenerateColors ? [] : manualColors,
    };
    saveChartColors(body)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setAutoGenerateChartColors(body.autoGenerate));
          const colors = body.autoGenerate
            ? STATIC_COLORS_FOR_CHART
            : body.selectedColors;
          dispatch(setManualChartColors(colors));
          setSaveChartColorRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success(t('saveChartColorSuccess'));
        } else {
          setSaveChartColorRequestStatus(REQUEST_STATUS.ERROR);
          message.error(t('saveChartColorError'));
        }
      })
      .catch((e: any) => {
        onApiCallError(
          e,
          true,
          setSaveChartColorRequestStatus,
          t('saveChartColorError')
        );
      });
  };

  /**
   * @function saveCustomTheme
   * @description function to save the custom theme and set it to default
   */
  const saveCustomTheme = () => {
    setSaveThemeRequestStatus(REQUEST_STATUS.PROCESSING);
    const body = {
      templateName: customPallete.templateName,
      primaryColor: customPallete.primaryColor,
      secondaryColor: customPallete.secondaryColor,
      buttonIconColor: customPallete.buttonIconColor,
      backgroundColor: customPallete.backgroundColor,
      textColor: customPallete.textColor,
    };
    createCustomPallete(body)
      .then((res: any) => {
        if (res.status === 200) {
          const newPalleteTemplateId = res?.data?.responseData;
          saveDefaultPallete(newPalleteTemplateId).then(
            (defaultPalleteRes: any) => {
              if (defaultPalleteRes.status === 200) {
                setSaveThemeRequestStatus(REQUEST_STATUS.SUCCESS);
                dispatch(setTheme(body));
                setSelectedPallete(undefined);
                message.success(t('successfullySetCustomTheme'));
              }
            }
          );
          return;
        }
        setSaveThemeRequestStatus(REQUEST_STATUS.ERROR);
        message.error(t('saveError'));
      })
      .catch((e: any) => {
        onApiCallError(e, true, setSaveThemeRequestStatus, t('saveError'));
      });
  };

  /**
   * @function onHandleSave
   * @description function to handle save button click
   */
  const onHandleSave = () => {
    // User has selected a static theme and no custom theme
    if (
      !isCustomTheme &&
      selectedPallete &&
      selectedPallete?.templateId !== defaultPallete?.templateId
    ) {
      setSaveThemeRequestStatus(REQUEST_STATUS.PROCESSING);
      saveDefaultPallete(selectedPallete?.templateId ?? '')
        .then((res: any) => {
          if (res.status === 200) {
            setSaveThemeRequestStatus(REQUEST_STATUS.SUCCESS);
            message.success(t('saveStaticThemeSuccess'));
            dispatch(setTheme(selectedPallete));
            return;
          }
          setSaveThemeRequestStatus(REQUEST_STATUS.ERROR);
          message.error(t('saveError'));
        })
        .catch((e: any) => {
          onApiCallError(e, true, setSaveThemeRequestStatus, t('saveError'));
        });
    }
    // If user has selected a custom theme. Save it as a new dynamic theme and set it default
    else if (isCustomTheme && validateCustomPalleteFields()) {
      saveCustomTheme();
    }
    // Save the chart appearance fields
    if (validateChartColorFields()) {
      saveChartAppearance();
    }
  };

  /**
   * @function validateCustomPalleteFields
   * @description Function to validate all the fields of the form
   * @returns boolean value
   */
  const validateCustomPalleteFields = () => {
    if (!isCustomTheme) {
      return true;
    } else {
      return (
        validateHexColorField(customPallete.primaryColor) &&
        validateHexColorField(customPallete.secondaryColor) &&
        validateHexColorField(customPallete.buttonIconColor) &&
        validateHexColorField(customPallete.backgroundColor) &&
        validateHexColorField(customPallete.textColor)
      );
    }
  };

  /**
   * @function validateChartColorFields
   * @description Function to validate all the fields of the form
   * @returns boolean value
   */
  const validateChartColorFields = () => {
    if (autoGenerateColors) {
      setManualColorsValidation('');
      return true;
    } else if (manualColors.length < 5) {
      setManualColorsValidation(t('minimum5ManualColors'));
      return false;
    }
    setManualColorsValidation('');
    return true;
  };

  return (
    <div className="appearance-page flex flex-column">
      <div className="appearance-page-header new-page-header flex flex-end">
        <Button
          title={t('saveBtn')}
          onClick={onHandleSave}
          disabled={!validateCustomPalleteFields()}
          loading={
            evaluateRequestArray([
              saveThemeRequestStatus,
              saveChartColorRequestStatus,
            ]) === REQUEST_STATUS.PROCESSING
          }
        />
      </div>
      <div className="appearance-container page-content flex flex-column flex-gap-24">
        {localStorage.getItem('role') === ROLES.ADMIN && (
          <div className="card appearance-form-content flex flex-column">
            <span className="form-header">{t('globalAppearance')}</span>
            <Divider />
            <GlobalAppearance
              selectedStaticPallete={selectedPallete}
              setSelectedPallete={setSelectedPallete}
              staticPalletes={staticPalletes}
              customPallete={customPallete}
              setCustomPallete={setCustomPallete}
              isCustomTheme={isCustomTheme}
              setIsCustomTheme={setIsCustomTheme}
              dropdownRequestStatus={evaluateRequestArray([
                staticPalletesRequestStatus,
                defaultPalletesRequestStatus,
              ])}
            />
          </div>
        )}
        <div className="card appearance-form-content flex flex-column">
          <span className="form-header">{t('chartsAppearance')}</span>
          <span className="font-caption sub-heading">
            {t('chartsAppearanceSubtitle')}
          </span>
          <Divider />
          <DashboardComponent
            component={
              <ChartAppearance
                autoGenerateColors={autoGenerateColors}
                setAutoGenerateColors={setAutoGenerateColors}
                manualColors={manualColors}
                setManualColors={setManualColors}
                manualColorsValidation={manualColorsValidation}
                setManualColorsValidation={setManualColorsValidation}
              />
            }
            requestStatus={chartColorRequestStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default AppearancePage;
