import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'constants/requestBody';
import {
  SavingsCostItemsType,
  DatePercentageType,
  SavingsEffectivenessType,
} from 'types/dataTypes';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { selectScorecard } from 'redux/scorecardSlice';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { onApiCallError } from 'utils/handleErrors';
import {
  DATE_MONTH_SHORT,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { CHART_TYPES } from 'constants/graphConfig';
import {
  PURCHASE_TACTICS_TAB,
  SCORECARDS_TIMERANGE,
} from 'pages/ScorecardPage/constants';
import { getDateLabelsByGranularity } from 'pages/ScorecardPage/utils';
import { PROVIDER } from 'constants/cloudProviders';
import {
  fetchAwsUtilizationDetails,
  fetchGcpCudEffectivenessDetails,
} from 'pages/ScorecardPage/services';

import SavingsEffectiveness from '../SavingsEffectiveness';
import {
  AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS,
  AWS_SAVINGS_EFFECTIVENESS_ITEMS,
  AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS,
  GCP_SAVINGS_EFFECTIVENESS_ITEMS_FIELDS,
  GCP_SAVINGS_EFFECTIVENESS_SUB_ITEMS_TO_SERVICES_MAP,
} from './constants';
import {
  getSavingsEffectivenessExcelData,
  getSavingsEffectivenessExportColumns,
  getSavingsEffectivenessExportData,
  getSubItemEnum,
} from './utils';

import './index.scss';

const SavingsEffectivenessScorecardDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tableViewEnabled, expandedGraphName, showExpandGraphModal } =
    useSelector(selectDashboard);
  const { selectedConnection, selectedPurchaseTacticsTab } =
    useSelector(selectScorecard);

  // Savings Effectiveness states
  const [savingsEffectivenessData, setSavingsEffectivenessData] =
    useState<SavingsEffectivenessType>({
      savingsEffectivenessPercentage: [],
      savingsCostItems: [],
    });
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(
    SCORECARDS_TIMERANGE.MONTHLY
  );
  const [savingsEffectivenessDateRange, setSavingsEffectivenessDateRange] =
    useState([
      moment()
        .subtract(5, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
    ]);
  const [savingsEffectivenessTableView, setSavingsEffectivenessTableView] =
    useState(false);
  const [
    savingsEffectivenessRequestStatus,
    setSavingsEffectivenessRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    savingsEffectivenessErrorMessage,
    setSavingsEffectivenessErrorMessage,
  ] = useState<string>();

  useEffect(() => {
    setSavingsEffectivenessTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    setSavingsEffectivenessData({
      savingsEffectivenessPercentage: [],
      savingsCostItems: [],
    });
    if (selectedConnection?.wantBilling) {
      if (selectedConnection.provider === PROVIDER.AWS) {
        getAWSSavingsEffectivenessDetails();
      }

      if (selectedConnection.provider === PROVIDER.GCP) {
        getGCPSavingsEffectivenessDetails();
      }
    }
  }, [selectedConnection, selectedTimeRange, savingsEffectivenessDateRange]);

  useEffect(() => {
    if (
      selectedConnection &&
      selectedPurchaseTacticsTab === PURCHASE_TACTICS_TAB.SAVINGS_EFFECTIVENESS
    ) {
      dispatch(
        setExportToExcelData([
          getSavingsEffectivenessExcelData(
            savingsEffectivenessData,
            selectedTimeRange,
            savingsEffectivenessDateRange,
            selectedConnection
          ),
        ])
      );
    }
  }, [
    selectedConnection,
    selectedPurchaseTacticsTab,
    savingsEffectivenessData,
    selectedTimeRange,
    savingsEffectivenessDateRange,
  ]);

  /**
   * @function pushOnDemandEquivalentSpendData
   * @description push on demand equivalent spend data to respective arrays
   * @param dataItem from the response
   * @param savingCostData data containing the saving cost of all the items
   */
  const pushOnDemandEquivalentSpendData = (
    dataItem: any,
    date: string,
    savingCostData: {
      [key: string]: SavingsCostItemsType[];
    }
  ) => {
    savingCostData.onDemandSpend.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf(),
      cost: dataItem?.totalOnDemandEquivalentSpend || 0,
    });
    savingCostData.riOnDemandEquivalentSpendEc2.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS.RI_ON_DEMAND_EQUIVALENT_SPEND_EC2.valueOf(),
      cost: dataItem?.riUtilizationEC2?.riOnDemandEquivalentSpend || 0,
    });
    savingCostData.riOnDemandEquivalentSpendElasticCache.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS.RI_ON_DEMAND_EQUIVALENT_SPEND_ELASTIC_CACHE.valueOf(),
      cost: dataItem?.riUtilizationElasticCache?.riOnDemandEquivalentSpend || 0,
    });
    savingCostData.riOnDemandEquivalentSpendMemoryDB.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS.RI_ON_DEMAND_EQUIVALENT_SPEND_MEMORY_DB.valueOf(),
      cost: dataItem?.riUtilizationMemoryDB?.riOnDemandEquivalentSpend || 0,
    });
    savingCostData.riOnDemandEquivalentSpendOpenSearch.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS.RI_ON_DEMAND_EQUIVALENT_SPEND_OPEN_SEARCH.valueOf(),
      cost: dataItem?.riUtilizationOpenSearch?.riOnDemandEquivalentSpend || 0,
    });
    savingCostData.riOnDemandEquivalentSpendElasticSearch.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS.RI_ON_DEMAND_EQUIVALENT_SPEND_ELASTIC_SEARCH.valueOf(),
      cost:
        dataItem?.riUtilizationElasticSearch?.riOnDemandEquivalentSpend || 0,
    });
    savingCostData.riOnDemandEquivalentSpendRedshift.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS.RI_ON_DEMAND_EQUIVALENT_SPEND_REDSHIFT.valueOf(),
      cost: dataItem?.riUtilizationRedShift?.riOnDemandEquivalentSpend || 0,
    });
    savingCostData.riOnDemandEquivalentSpendRds.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS.RI_ON_DEMAND_EQUIVALENT_SPEND_RDS.valueOf(),
      cost: dataItem?.riUtilizationRDS?.riOnDemandEquivalentSpend || 0,
    });
    savingCostData.spOnDemandSpend.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS.SP_ON_DEMAND_SPEND_EC2_FARGATE_LAMDA_SAGEMAKER.valueOf(),
      cost:
        (dataItem?.spUtilization?.spOnDemandEquivalentSpend || 0) +
        (dataItem?.onDemandCostNotCovered || 0),
    });
  };

  /**
   * @function pushRiAndSpSavingsData
   * @description push RI and SP savings data to respective arrays
   * @param dataItem from the response
   * @param savingCostData data containing the saving cost of all the items
   */
  const pushRiAndSpSavingsData = (
    dataItem: any,
    date: string,
    savingCostData: {
      [key: string]: SavingsCostItemsType[];
    }
  ) => {
    savingCostData.riAndSpSavings.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf(),
      cost: dataItem?.totalSavings || 0,
    });
    savingCostData.riSavingsEc2.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS.SAVINGS_FROM_RELATED_RI_EC2.valueOf(),
      cost: dataItem?.riUtilizationEC2?.riSavings || 0,
    });
    savingCostData.riSavingsElasticCache.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS.SAVINGS_FROM_RELATED_RI_ELASTIC_CACHE.valueOf(),
      cost: dataItem?.riUtilizationElasticCache?.riSavings || 0,
    });
    savingCostData.riSavingsMemoryDB.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS.SAVINGS_FROM_RELATED_RI_MEMORY_DB.valueOf(),
      cost: dataItem?.riUtilizationMemoryDB?.riSavings || 0,
    });
    savingCostData.riSavingsOpenSearch.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS.SAVINGS_FROM_RELATED_RI_OPEN_SERACH.valueOf(),
      cost: dataItem?.riUtilizationOpenSearch?.riSavings || 0,
    });
    savingCostData.riSavingsElasticSearch.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS.SAVINGS_FROM_RELATED_RI_ELASTIC_SEARCH.valueOf(),
      cost: dataItem?.riUtilizationElasticSearch?.riSavings || 0,
    });
    savingCostData.riSavingsRedshift.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS.SAVINGS_FROM_RELATED_RI_REDSHIFT.valueOf(),
      cost: dataItem?.riUtilizationRedShift?.riSavings || 0,
    });
    savingCostData.riSavingsRds.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS.SAVINGS_FROM_RELATED_RI_RDS.valueOf(),
      cost: dataItem?.riUtilizationRDS?.riSavings || 0,
    });
    savingCostData.spNetSavings.push({
      date: date,
      category: AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf(),
      subItem:
        AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS.SAVINGS_PLAN_FROM_RELATED_SAVINGS_PLAN.valueOf(),
      cost: dataItem?.spUtilization?.spSavings || 0,
    });
  };

  /**
   * @function getAWSSavingsEffectivenessDetails
   * @description Function fetch the AWS savings effectiveness details
   */
  const getAWSSavingsEffectivenessDetails = () => {
    setSavingsEffectivenessRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      granularity: selectedTimeRange,
      startDate: savingsEffectivenessDateRange[0],
      endDate:
        selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
          ? moment(savingsEffectivenessDateRange[1], HYPHEN_DATE_FORMAT).format(
              HYPHEN_DATE_FORMAT
            )
          : moment(savingsEffectivenessDateRange[1], HYPHEN_DATE_FORMAT)
              .add(1, 'days')

              .format(HYPHEN_DATE_FORMAT),
    };

    fetchAwsUtilizationDetails(params)
      .then((res: any) => {
        const data = res?.data?.responseData || [];
        const labels = getDateLabelsByGranularity(
          selectedTimeRange,
          savingsEffectivenessDateRange
        );
        let riAndSpSavings: SavingsCostItemsType[] = [];
        let riSavingsEc2: SavingsCostItemsType[] = [];
        let riSavingsElasticCache: SavingsCostItemsType[] = [];
        let riSavingsMemoryDB: SavingsCostItemsType[] = [];
        let riSavingsOpenSearch: SavingsCostItemsType[] = [];
        let riSavingsElasticSearch: SavingsCostItemsType[] = [];
        let riSavingsRedshift: SavingsCostItemsType[] = [];
        let riSavingsRds: SavingsCostItemsType[] = [];
        let spNetSavings: SavingsCostItemsType[] = [];

        let costToAchieveSavings: SavingsCostItemsType[] = [];

        let onDemandSpend: SavingsCostItemsType[] = [];
        let riOnDemandEquivalentSpendEc2: SavingsCostItemsType[] = [];
        let riOnDemandEquivalentSpendElasticCache: SavingsCostItemsType[] = [];
        let riOnDemandEquivalentSpendMemoryDB: SavingsCostItemsType[] = [];
        let riOnDemandEquivalentSpendOpenSearch: SavingsCostItemsType[] = [];
        let riOnDemandEquivalentSpendElasticSearch: SavingsCostItemsType[] = [];
        let riOnDemandEquivalentSpendRedshift: SavingsCostItemsType[] = [];
        let riOnDemandEquivalentSpendRds: SavingsCostItemsType[] = [];
        let spOnDemandSpend: SavingsCostItemsType[] = [];
        let savingsEffectiveness: DatePercentageType[] = [];
        const dateFormat =
          selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY.valueOf()
            ? MONTH_YEAR_FORMAT
            : DATE_MONTH_SHORT;
        labels.forEach((label) => {
          const dataItem = data.find(
            (item: any) => moment(item.startDate).format(dateFormat) === label
          );

          pushRiAndSpSavingsData(dataItem, label, {
            riAndSpSavings,
            riSavingsEc2,
            riSavingsElasticCache,
            riSavingsMemoryDB,
            riSavingsOpenSearch,
            riSavingsElasticSearch,
            riSavingsRedshift,
            riSavingsRds,
            spNetSavings,
          });
          pushOnDemandEquivalentSpendData(dataItem, label, {
            onDemandSpend,
            riOnDemandEquivalentSpendEc2,
            riOnDemandEquivalentSpendElasticCache,
            riOnDemandEquivalentSpendMemoryDB,
            riOnDemandEquivalentSpendOpenSearch,
            riOnDemandEquivalentSpendElasticSearch,
            riOnDemandEquivalentSpendRedshift,
            riOnDemandEquivalentSpendRds,
            spOnDemandSpend,
          });

          costToAchieveSavings.push({
            date: label,
            category:
              AWS_SAVINGS_EFFECTIVENESS_ITEMS.COST_TO_ACHIEVE_SAVINGS.valueOf(),
            cost: dataItem?.totalCostToAchieveSavings || 0,
          });
          savingsEffectiveness.push({
            date: label,
            percentage: dataItem?.savingsEffectiveness || 0,
          });
        });
        setSavingsEffectivenessData({
          savingsEffectivenessPercentage: savingsEffectiveness,
          savingsCostItems: [
            ...riAndSpSavings,
            ...riSavingsEc2,
            ...riSavingsElasticCache,
            ...riSavingsMemoryDB,
            ...riSavingsOpenSearch,
            ...riSavingsElasticSearch,
            ...riSavingsRedshift,
            ...riSavingsRds,
            ...spNetSavings,
            ...costToAchieveSavings,
            ...onDemandSpend,
            ...riOnDemandEquivalentSpendEc2,
            ...riOnDemandEquivalentSpendElasticCache,
            ...riOnDemandEquivalentSpendMemoryDB,
            ...riOnDemandEquivalentSpendOpenSearch,
            ...riOnDemandEquivalentSpendElasticSearch,
            ...riOnDemandEquivalentSpendRedshift,
            ...riOnDemandEquivalentSpendRds,
            ...spOnDemandSpend,
          ],
        });
        setSavingsEffectivenessErrorMessage(undefined);
        setSavingsEffectivenessRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSavingsEffectivenessRequestStatus);
        setSavingsEffectivenessErrorMessage(e?.response?.data?.message);
      });
  };

  /**
   * @function getGcpSubItemCost
   * @description Function return the total cost of all the services in a sub item
   * @param subItem sun item for which the cost is calculated
   * @param category category for which the sub item belongs to.
   * @param data data to filter and calculate the cost.
   * @returns The sum of the cost of all the services for a given sub item and category
   */
  const getGcpSubItemCost = (subItem: string, category: any, data: any[]) => {
    const services =
      GCP_SAVINGS_EFFECTIVENESS_SUB_ITEMS_TO_SERVICES_MAP.find(
        (item) => item.key === subItem
      )?.services ?? [];
    const filteredServicesData = data.filter((item) =>
      services.includes(item.service)
    );

    return filteredServicesData.reduce(
      (sum, item) => sum + item[category.subItemField],
      0
    );
  };

  /**
   * @function getGCPSavingsEffectivenessDetails
   * @description Function fetch the GCP savings effectiveness details
   */
  const getGCPSavingsEffectivenessDetails = () => {
    setSavingsEffectivenessRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestParamDateFormat =
      selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
        ? YEAR_MONTH_WITHOUT_SEPARATOR
        : HYPHEN_DATE_FORMAT;

    const params = {
      connectorId: selectedConnection?.connectorId,
      granularity: selectedTimeRange,
      startDate: moment(savingsEffectivenessDateRange[0]).format(
        requestParamDateFormat
      ),
      endDate:
        selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
          ? moment(savingsEffectivenessDateRange[1], HYPHEN_DATE_FORMAT).format(
              requestParamDateFormat
            )
          : moment(savingsEffectivenessDateRange[1], HYPHEN_DATE_FORMAT)
              .add(1, 'days')
              .format(requestParamDateFormat),
    };

    fetchGcpCudEffectivenessDetails(params)
      .then((res: any) => {
        const data = res?.data?.responseData || [];
        const dateLabels = getDateLabelsByGranularity(
          selectedTimeRange,
          savingsEffectivenessDateRange
        );
        const dateFormat =
          selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY.valueOf()
            ? MONTH_YEAR_FORMAT
            : DATE_MONTH_SHORT;

        let gcpSavingsEffectivenessCostData: SavingsCostItemsType[] = [];
        let savingsEffectivenessPercentage: DatePercentageType[] = [];

        dateLabels.forEach((date) => {
          const dataItem = data.find(
            (item: any) => moment(item.startTime).format(dateFormat) === date
          );

          GCP_SAVINGS_EFFECTIVENESS_ITEMS_FIELDS.forEach((category) => {
            gcpSavingsEffectivenessCostData.push({
              date: date,
              category: category.key,
              cost: dataItem ? Number(dataItem[category.field] || 0) : 0,
            });

            Object.values(
              getSubItemEnum(selectedConnection?.provider, category.key)
            ).forEach((subItem: any) => {
              gcpSavingsEffectivenessCostData.push({
                date: date,
                category: category.key,
                subItem: subItem,
                cost: getGcpSubItemCost(
                  subItem,
                  category,
                  dataItem?.serviceItems || []
                ),
              });
            });
          });

          savingsEffectivenessPercentage.push({
            date: date,
            percentage: dataItem?.savingsEffectiveness || 0,
          });
        });

        setSavingsEffectivenessData({
          savingsEffectivenessPercentage: savingsEffectivenessPercentage,
          savingsCostItems: gcpSavingsEffectivenessCostData,
        });
        setSavingsEffectivenessRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSavingsEffectivenessRequestStatus);
        setSavingsEffectivenessErrorMessage(e?.response?.data?.message);
      });
  };

  /**
   * @function getGraphComponent
   * @description Function to get the graph component
   * @param graphName string name of the graph
   * @param pdfView boolean flag to check if the graph is for pdf
   * @returns ReactNode
   */
  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    if (graphName === 'savings-effectiveness') {
      return getSavingsEffectivenessGraphComponent(pdfView);
    }
  };

  /**
   * @function getSavingsEffectivenessGraphComponent
   * @description Function to get the savings effectiveness graph component
   * @param pdfView boolean flag to check if the graph is for pdf
   * @param pdfDateRange string[] date range for pdf
   * @returns ReactNode
   */
  const getSavingsEffectivenessGraphComponent = (
    pdfView: boolean = false,
    pdfDateRange?: string[]
  ) => {
    return (
      <SavingsEffectiveness
        savingsEffectivenessData={savingsEffectivenessData}
        requestStatus={savingsEffectivenessRequestStatus}
        pdfView={pdfView}
        selectedTimeRange={selectedTimeRange}
        setSelectedTimeRange={setSelectedTimeRange}
        savingsEffectivenessDateRange={
          pdfDateRange ?? savingsEffectivenessDateRange
        }
        setSavingsEffectivenessDateRange={setSavingsEffectivenessDateRange}
        savingsEffectivenessTableView={savingsEffectivenessTableView}
        setSavingsEffectivenessTableView={setSavingsEffectivenessTableView}
        errorMessage={savingsEffectivenessErrorMessage}
      />
    );
  };

  if (selectedConnection && !selectedConnection.wantBilling) {
    return <div>{t('scorecard.noBillingAccess')}</div>;
  }

  return (
    <>
      <div className="savings-effectiveness-scorecard-dashboard">
        {getGraphComponent('savings-effectiveness')}
      </div>
      {showExpandGraphModal &&
        selectedPurchaseTacticsTab ===
          PURCHASE_TACTICS_TAB.SAVINGS_EFFECTIVENESS && (
          <ExpandModal
            show={showExpandGraphModal}
            graphContent={getGraphComponent(expandedGraphName)}
          />
        )}
      {selectedPurchaseTacticsTab ===
        PURCHASE_TACTICS_TAB.SAVINGS_EFFECTIVENESS && (
        <PdfDownloadComponent
          pdfContent={[
            {
              element: getGraphComponent('savings-effectiveness', true),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: 'savings-effectiveness',
              isTableView: savingsEffectivenessTableView,
              column: getSavingsEffectivenessExportColumns(
                selectedTimeRange,
                savingsEffectivenessDateRange
              ),
              body: insertIndex(
                getSavingsEffectivenessExportData(
                  selectedConnection?.provider,
                  savingsEffectivenessData,
                  selectedTimeRange,
                  savingsEffectivenessDateRange,
                  true
                )
              ),
              boldRows: getSavingsEffectivenessExportData(
                selectedConnection?.provider,
                savingsEffectivenessData,
                selectedTimeRange,
                savingsEffectivenessDateRange
              )
                .map((value, index) => (!value.isSubItem ? index : -1))
                .filter((value) => value !== -1),
              tableName: t('graphHeadings.overallSavingsEffectiveness'),
              tableFooterData: {
                totalTableData: [
                  t('scorecard.savingsEffectiveness.footer'),
                  ...savingsEffectivenessData.savingsEffectivenessPercentage.map(
                    (value) => `${numberCommaSeparator(value.percentage)}%`
                  ),
                ],
              },
            },
          ]}
          pdfMetaData={{
            viewName: t('navigationMenu.scorecards'),
            fileName: selectedConnection?.name ?? '',
            heading: selectedConnection?.name ?? '',
            subtitle1: '',
            provider: selectedConnection?.provider ?? '',
          }}
        />
      )}
    </>
  );
};

export default SavingsEffectivenessScorecardDashboard;
