import i18n from 'i18n';
import {
  AllocationTrendType,
  CostByBusinessUnitType,
  CostByCostCenterCodeType,
} from 'pages/CostAllocationDashboardPage/types';
import { insertIndex } from 'utils/dataFormatterUtils';
import {
  getAllocationByBusinessUnitExportColumns,
  getAllocationByCostCenterCodeExportColumns,
  AllocationTrendExportColumns,
} from '../constants';
import { store } from 'redux/store';

export const getAllocationTrendExcelData = (
  connectorName: string,
  allocationTrendData: AllocationTrendType[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.allocationTrend'),
    columns: AllocationTrendExportColumns,
    data: insertIndex(allocationTrendData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

export const getAllocationByBusinessUnitExcelData = (
  connectorName: string,
  businessUnitsData: CostByBusinessUnitType[],
  selectedMonths: string[],
  selectedBusinessUnits: string[]
) => {
  const state = store.getState();
  const commonUtilityState = state.commonUtility;
  return {
    sheetName: i18n.t('graphHeadings.allocationCostByBusinessUnit'),
    columns: getAllocationByBusinessUnitExportColumns(
      commonUtilityState.currencySymbol
    ),
    data: insertIndex(businessUnitsData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      {
        heading: i18n.t(
          'costAllocationDashboardLabels.allocationByBusinessUnit.months'
        ),
        value: selectedMonths,
      },
      {
        heading: i18n.t(
          'costAllocationDashboardLabels.allocationByCSC.businessUnits'
        ),
        value: selectedBusinessUnits,
      },
    ],
  };
};

export const getAllocationByCostCenterCodeExcelData = (
  connectorName: string,
  costCentersCost: CostByCostCenterCodeType[],
  selectedMonths: string[],
  selectedBusinessUnits: string[],
  selectedCostCenters: string[]
) => {
  const state = store.getState();
  const commonUtilityState = state.commonUtility;
  return {
    sheetName: i18n.t('graphHeadings.costCenterCode'),
    columns: getAllocationByCostCenterCodeExportColumns(
      commonUtilityState.currencySymbol
    ),
    data: insertIndex(costCentersCost),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      {
        heading: i18n.t(
          'costAllocationDashboardLabels.allocationByBusinessUnit.months'
        ),
        value: selectedMonths,
      },
      {
        heading: i18n.t(
          'costAllocationDashboardLabels.allocationByCSC.businessUnits'
        ),
        value: selectedBusinessUnits,
      },
      {
        heading: i18n.t(
          'costAllocationDashboardLabels.allocationByCSC.costCenters'
        ),
        value: selectedCostCenters,
      },
    ],
  };
};

export const getCostAllocationExcelData = (
  connectorName: string,
  costTrendData: AllocationTrendType[],
  businessUnitsData: {
    data: CostByBusinessUnitType[];
    selectedMonths: string[];
    selectedBusinessUnits: string[];
  },
  costCentersCostData: {
    data: CostByCostCenterCodeType[];
    selectedMonths: string[];
    selectedBusinessUnits: string[];
    selectedCostCenters: string[];
  }
) => {
  return [
    getAllocationTrendExcelData(connectorName, costTrendData),
    getAllocationByBusinessUnitExcelData(
      connectorName,
      businessUnitsData.data,
      businessUnitsData.selectedMonths,
      businessUnitsData.selectedBusinessUnits
    ),
    getAllocationByCostCenterCodeExcelData(
      connectorName,
      costCentersCostData.data,
      costCentersCostData.selectedMonths,
      costCentersCostData.selectedBusinessUnits,
      costCentersCostData.selectedCostCenters
    ),
  ];
};
