import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { uniqBy } from 'lodash';

import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  getDateMonthShortList,
  getListOfHours,
  getMonthAndYearDigitsFormat,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
} from 'utils/date';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { MonthlyCostType, DateRangeFilter } from 'types/dataTypes';
import {
  NamespaceUsageCostType,
  ClusterCostType,
} from 'pages/ContainerInsightsPage/types';
import {
  getCostTrendExportColumns,
  getNamespaceUsageCostExportColumns,
  getCostClusterCostExportColumns,
  getDateInterval,
} from 'pages/ContainerInsightsPage/utils';
import { selectContainerInsights } from 'redux/containerInsightsSlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import { generateGraphColors } from 'utils/dashboardUtils';
import {
  DATE_INTERVALS,
  DATE_RANGES,
} from 'pages/ContainerInsightsPage/constants';

import {
  UtilizationByDimensionAndNodeType,
  UtilizationByNodeAndTimeType,
} from './types';
import CostTrend from '../CostTrend';
import { GRANULATE_GRAPHS } from '../GranulateUsageMetering/constants';
import NamespaceUsageCost from '../Namespace Usage & Cost';
import { ClusterCost } from '../ClusterCost';
import PodMemoryUtilization from './components/PodMemoryUtilization';
import PodCpuUtilization from './components/PodCpuUtilization';
import PodNetworkUtilization from './components/PodNetworkUtilization';
import NodeFileUtilization from './components/NodeFileUtilization';
import ContainerCpuUtilization from './components/ContainerCpuUtilization';
import ContainerMemoryUtilization from './components/ContainerMemoryUtilization';
import {
  AwsClustersQuery,
  POD_CPU_UTILIZATION_DIMENSIONS,
  FILE_UTILIZATION_DIMENSIONS,
  PodMemoryUtilizationExportColumns,
  PodNetworkUtilizationExportColumns,
  POD_MEMORY_UTILIZATION_DIMENSIONS,
  POD_NETWORK_UTILIZATION_DIMENSIONS,
  FileUtilizationDimensionEnum,
  CONTAINER_CPU_UTILIZATION_DIMENSIONS,
  CONTAINER_MEMORY_UTILIZATION_DIMENSIONS,
  ContainerMemoryUtilizationDimensionEnum,
  DUMMY_COST_TREND_VALUES,
  DUMMY_NAMESPACES,
  DUMMY_NAMESPACES_COST,
  DUMMY_CLUSTER_COST_VALUES,
} from './constants';
import {
  getUtilizationByDimensionExportColumns,
  getUsageMeteringExcelData,
  getPodMemoryUtilizationExportData,
} from './utils/exportToExcel';
import {
  getDateFormatForGraphLabels,
  formatDateFieldData,
  getFormattedStartDate,
  getPodCpuUtilizationQuery,
  getPodMemoryUtilizationQuery,
  getPodNetworkUtilizationQuery,
  getNodeFileUtilizationQuery,
  getContainerCpuUtilizationQuery,
  getContainerMemoryUtilizationQuery,
} from './utils';

import './index.scss';

type AWSUsageMeteringProps = {
  setClusters: (clusters: string[]) => void;
  clustersRequestStatus: string;
  setClustersRequestStatus: (status: string) => void;
  selectedClusters: string[];
  setSelectedClusters: (clusters: string[]) => void;
  PdfComponents: any[];
  getGranulateGraphComponent: (
    graphName: string,
    pdfView?: boolean
  ) => JSX.Element | undefined;
  selectedCpuCount: string;
  selectedCpuUtilized: string;
  granulateChartsExcelData: any[];
};

const AWSUsageMetering = ({
  selectedClusters,
  clustersRequestStatus,
  setClustersRequestStatus,
  setSelectedClusters,
  setClusters,
  PdfComponents,
  getGranulateGraphComponent,
  selectedCpuUtilized,
  selectedCpuCount,
  granulateChartsExcelData,
}: AWSUsageMeteringProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection } = useSelector(selectContainerInsights);
  const {
    tableViewEnabled,
    showExpandGraphModal,
    expandedGraphName,
    pdfDownloadMode,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { showConsolidatedDashboard, selectedGranulateService } = useSelector(
    costOptimizationInsights
  );

  //States for filters
  // const [clusters, setClusters] = useState<string[]>([]);
  // const [clustersRequestStatus, setClustersRequestStatus] = useState(
  //   REQUEST_STATUS.SUCCESS
  // );
  // const [selectedClusters, setSelectedClusters] = useState<string[]>([]);

  // Cost Trend states
  const costTrendNamespaces = DUMMY_NAMESPACES;
  const [costTrendValues, setCostTrendValues] = useState<MonthlyCostType[]>([]);
  const [costTrendDateRange, setCostTrendDateRange] = useState<string[]>([
    moment().subtract(11, 'months').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);
  const [selectedCostTrendNamespaces, setSelectedCostTrendNamespaces] =
    useState<string[]>(DUMMY_NAMESPACES);
  const [costTrendRequestStatus, setCostTrendRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isCostTrendTableView, setIsCostTrendTableView] = useState(false);

  // Namespace Usage & Cost states
  const [namespaceUsageCostData, setNamespaceUsageCostData] = useState<
    NamespaceUsageCostType[]
  >([]);
  const [filteredNamespaceUsageCostData, setFilteredNamespaceUsageCostData] =
    useState<NamespaceUsageCostType[]>([]);
  const [
    selectedNamespaceUsageCostNamespaces,
    setSelectedNamespaceUsageCostNamespaces,
  ] = useState<string[]>(DUMMY_NAMESPACES);
  const [namespaceUsageCostRequestStatus, setNamespaceUsageCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [namespaceUsageCostTableView, setNamespaceUsageCostTableView] =
    useState(false);
  const [namespaceUsageCostDateFilter, setNamespaceUsageCostDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  // Cluster Cost
  const [clusterCostData, setClusterCostData] = useState<ClusterCostType[]>([]);
  const [clusterCostRequestStatus, setClusterCostRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [isClusterCostTableView, setIsClusterCostTableView] = useState(false);
  const [clusterCostDateFilter, setClusterCostDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  // Pod CPU Utilization
  const [podCpuUtilizationData, setPodCpuUtilizationData] = useState<
    UtilizationByNodeAndTimeType[]
  >([]);
  const [filteredPodCpuUtilizationData, setFilteredPodCpuUtilizationData] =
    useState<UtilizationByNodeAndTimeType[]>([]);
  const [podCpuUtilizationNodes, setPodCpuUtilizationNodes] = useState<
    string[]
  >([]);
  const [selectedPodCpuUtilizationNode, setSelectedPodCpuUtilizationNode] =
    useState<string>('');
  const [podCpuUtilizationRequestStatus, setPodCpuUtilizationRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [isPodCpuUtilizationTableView, setIsPodCpuUtilizationTableView] =
    useState(false);
  const [podCpuUtilizationDateFilter, setPodCpuUtilizationDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  // Pod Memory Utilization
  const [podMemoryUtilizationData, setPodMemoryUtilizationData] = useState<
    UtilizationByDimensionAndNodeType[]
  >([]);
  const [
    filteredPodMemoryUtilizationData,
    setFilteredPodMemoryUtilizationData,
  ] = useState<UtilizationByDimensionAndNodeType[]>([]);
  const [podMemoryUtilizationNodes, setPodMemoryUtilizationNodes] = useState<
    string[]
  >([]);
  const [
    podMemoryUtilizationSelectedNode,
    setPodMemoryUtilizationSelectedNode,
  ] = useState<string>('');
  const [
    podMemoryUtilizationRequestStatus,
    setPodMemoryUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [podMemoryUtilizationTableView, setPodMemoryUtilizationTableView] =
    useState(false);
  const [podMemoryUtilizationDateFilter, setPodMemoryUtilizationDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  // Pod Network Utilization
  const [podNetworkUtilizationData, setPodNetworkUtilizationData] = useState<
    UtilizationByDimensionAndNodeType[]
  >([]);
  const [
    filteredPodNetworkUtilizationData,
    setFilteredPodNetworkUtilizationData,
  ] = useState<UtilizationByDimensionAndNodeType[]>([]);
  const [podNetworkUtilizationNodes, setPodNetworkUtilizationNodes] = useState<
    string[]
  >([]);
  const [
    podNetworkUtilizationSelectedNode,
    setPodNetworkUtilizationSelectedNode,
  ] = useState<string>('');
  const [
    podNetworkUtilizationRequestStatus,
    setPodNetworkUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [podNetworkUtilizationTableView, setPodNetworkUtilizationTableView] =
    useState(false);
  const [podNetworkUtilizationDateFilter, setPodNetworkUtilizationDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  // Node File Utilization
  const [nodeFileUtilizationData, setNodeFileUtilizationData] = useState<
    UtilizationByNodeAndTimeType[]
  >([]);
  const [filteredNodeFileUtilizationData, setFilteredNodeFileUtilizationData] =
    useState<UtilizationByNodeAndTimeType[]>([]);
  const [nodeFileUtilizationNodes, setNodeFileUtilizationNodes] = useState<
    string[]
  >([]);
  const [selectedFileUtilizationNode, setSelectedFileUtilizationNode] =
    useState<string>('');
  const [
    nodeFileUtilizationRequestStatus,
    setNodeFileUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [nodeFileUtilizationTableView, setNodeFileUtilizationTableView] =
    useState(false);
  const [nodeFileUtilizationDateFilter, setNodeFileUtilizationDateFilter] =
    useState<DateRangeFilter>({
      option: DATE_RANGES.THIS_YEAR,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
    });

  // Container CPU Utilization
  const [containerCpuUtilizationData, setContainerCpuUtilizationData] =
    useState<UtilizationByNodeAndTimeType[]>([]);
  const [
    filteredContainerCpuUtilizationData,
    setFilteredContainerCpuUtilizationData,
  ] = useState<UtilizationByNodeAndTimeType[]>([]);
  const [containerCpuUtilizationNodes, setContainerCpuUtilizationNodes] =
    useState<string[]>([]);
  const [
    selectedContainerCpuUtilizationNode,
    setSelectedContainerCpuUtilizationNode,
  ] = useState<string>('');
  const [
    containerCpuUtilizationRequestStatus,
    setContainerCpuUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    isContainerCpuUtilizationTableView,
    setIsContainerCpuUtilizationTableView,
  ] = useState(false);
  const [
    containerCpuUtilizationDateFilter,
    setContainerCpuUtilizationDateFilter,
  ] = useState<DateRangeFilter>({
    option: DATE_RANGES.THIS_YEAR,
    startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
    endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
  });

  // Container memory Utilization
  const [containerMemoryUtilizationData, setContainerMemoryUtilizationData] =
    useState<UtilizationByNodeAndTimeType[]>([]);
  const [
    filteredContainerMemoryUtilizationData,
    setFilteredContainerMemoryUtilizationData,
  ] = useState<UtilizationByNodeAndTimeType[]>([]);
  const [containerMemoryUtilizationNodes, setContainerMemoryUtilizationNodes] =
    useState<string[]>([]);
  const [
    selectedContainerMemoryUtilizationNode,
    setSelectedContainerMemoryUtilizationNode,
  ] = useState<string>('');
  const [
    containerMemoryUtilizationRequestStatus,
    setContainerMemoryUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    containerMemoryUtilizationTableView,
    setContainerMemoryUtilizationTableView,
  ] = useState(false);
  const [
    containerMemoryUtilizationDateFilter,
    setContainerMemoryUtilizationDateFilter,
  ] = useState<DateRangeFilter>({
    option: DATE_RANGES.THIS_YEAR,
    startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
    endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
  });

  useEffect(() => {
    setClusters([]);
    setSelectedClusters([]);
    if (selectedConnection || selectedGranulateService) {
      fetchClusters();
    }
  }, [selectedConnection, selectedGranulateService]);

  useEffect(() => {
    if (selectedClusters.length > 0) {
      fetchCostTrendData();
    } else {
      setCostTrendValues([]);
    }
  }, [selectedClusters, costTrendDateRange]);

  useEffect(() => {
    if (selectedClusters.length > 0) {
      fetchNamespaceUsageCostData();
    } else {
      setNamespaceUsageCostData([]);
    }
  }, [selectedClusters, namespaceUsageCostDateFilter]);

  useEffect(() => {
    const namespaceFilteredData = namespaceUsageCostData.filter((item) =>
      selectedNamespaceUsageCostNamespaces.includes(item.namespace)
    );
    namespaceFilteredData.sort(
      (item1, item2) => item2.costAfterCredit - item1.costAfterCredit
    );
    setFilteredNamespaceUsageCostData(namespaceFilteredData);
  }, [namespaceUsageCostData, selectedNamespaceUsageCostNamespaces]);

  useEffect(() => {
    if (selectedClusters.length > 0) {
      fetchClusterCostData();
    } else {
      setClusterCostData([]);
    }
  }, [selectedClusters, clusterCostDateFilter]);

  useEffect(() => {
    setSelectedPodCpuUtilizationNode('');
    if (selectedClusters.length > 0) {
      fetchPodCpuUtilizationData();
    } else {
      setPodCpuUtilizationData([]);
    }
  }, [selectedClusters, podCpuUtilizationDateFilter]);

  useEffect(() => {
    const nodeFilteredData = podCpuUtilizationData.filter(
      (item) => selectedPodCpuUtilizationNode === item.node
    );
    setFilteredPodCpuUtilizationData(nodeFilteredData);
  }, [podCpuUtilizationData, selectedPodCpuUtilizationNode]);

  useEffect(() => {
    setPodMemoryUtilizationSelectedNode('');
    if (selectedClusters.length > 0) {
      fetchPodMemoryUtilizationData();
    } else {
      setPodMemoryUtilizationData([]);
    }
  }, [selectedClusters, podMemoryUtilizationDateFilter]);

  useEffect(() => {
    const nodeFilteredData = podMemoryUtilizationData.filter(
      (item) => podMemoryUtilizationSelectedNode === item.node
    );
    nodeFilteredData.sort(
      (item1, item2) => item2.utilization - item1.utilization
    );

    setFilteredPodMemoryUtilizationData(nodeFilteredData);
  }, [podMemoryUtilizationData, podMemoryUtilizationSelectedNode]);

  useEffect(() => {
    setPodNetworkUtilizationSelectedNode('');
    if (selectedClusters.length > 0) {
      fetchPodNetworkUtilizationData();
    } else {
      setPodNetworkUtilizationData([]);
    }
  }, [selectedClusters, podNetworkUtilizationDateFilter]);

  useEffect(() => {
    const nodeFilteredData = podNetworkUtilizationData.filter(
      (item) => podNetworkUtilizationSelectedNode === item.node
    );
    nodeFilteredData.sort(
      (item1, item2) => item2.utilization - item1.utilization
    );

    setFilteredPodNetworkUtilizationData(nodeFilteredData);
  }, [podNetworkUtilizationData, podNetworkUtilizationSelectedNode]);

  useEffect(() => {
    setSelectedFileUtilizationNode('');
    if (selectedClusters.length > 0) {
      fetchNodeFileUtilizationData();
    } else {
      setNodeFileUtilizationData([]);
    }
  }, [selectedClusters, nodeFileUtilizationDateFilter]);

  useEffect(() => {
    const nodeFilteredData = nodeFileUtilizationData.filter(
      (item) => selectedFileUtilizationNode === item.node
    );
    setFilteredNodeFileUtilizationData(nodeFilteredData);
  }, [nodeFileUtilizationData, selectedFileUtilizationNode]);

  useEffect(() => {
    setSelectedContainerCpuUtilizationNode('');
    if (selectedClusters.length > 0) {
      fetchContainerCpuUtilizationData();
    } else {
      setContainerCpuUtilizationData([]);
    }
  }, [selectedClusters, containerCpuUtilizationDateFilter]);

  useEffect(() => {
    const nodeFilteredData = containerCpuUtilizationData.filter(
      (item) => selectedContainerCpuUtilizationNode === item.node
    );
    setFilteredContainerCpuUtilizationData(nodeFilteredData);
  }, [containerCpuUtilizationData, selectedContainerCpuUtilizationNode]);

  useEffect(() => {
    setSelectedContainerMemoryUtilizationNode('');
    if (selectedClusters.length > 0) {
      fetchContainerMemoryUtilizationData();
    } else {
      setContainerMemoryUtilizationData([]);
    }
  }, [selectedClusters, containerMemoryUtilizationDateFilter]);

  useEffect(() => {
    const nodeFilteredData = containerMemoryUtilizationData.filter(
      (item) => selectedContainerMemoryUtilizationNode === item.node
    );
    setFilteredContainerMemoryUtilizationData(nodeFilteredData);
  }, [containerMemoryUtilizationData, selectedContainerMemoryUtilizationNode]);

  useEffect(() => {
    if (selectedConnection) {
      dispatch(
        setExportToExcelData([
          ...getUsageMeteringExcelData(
            getExcelFilters(),
            {
              data: costTrendValues,
              dateRange: costTrendDateRange,
              selectedNamespaces: selectedCostTrendNamespaces,
            },
            {
              data: filteredNamespaceUsageCostData,
              dateRange: namespaceUsageCostDateFilter,
              selectedNamespaces: selectedNamespaceUsageCostNamespaces,
            },
            {
              data: clusterCostData,
              filters: [
                {
                  heading: t('excelExportLabels.startDate'),
                  value: clusterCostDateFilter.startDate,
                },
                {
                  heading: t('excelExportLabels.endDate'),
                  value: clusterCostDateFilter.endDate,
                },
              ],
            },
            {
              cpuData: filteredPodCpuUtilizationData,
              cpuTableData: cpuUtilizationTableExportData(
                filteredPodCpuUtilizationData
              ),
              cpuDateFilter: podCpuUtilizationDateFilter,
              selectedCpuNode: selectedPodCpuUtilizationNode,
              memoryData: filteredPodMemoryUtilizationData,
              memoryDateFilter: podMemoryUtilizationDateFilter,
              selectedMemoryNode: podMemoryUtilizationSelectedNode,
              networkData: filteredPodNetworkUtilizationData,
              networkDateFilter: podNetworkUtilizationDateFilter,
              selectedNetworkNode: podNetworkUtilizationSelectedNode,
            },
            {
              nodeFileUtilizationData: filteredNodeFileUtilizationData,
              tableData: nodeFileUtilizationTableExportData(),
              nodeFileUtilizationDateFilter: nodeFileUtilizationDateFilter,
              selectedNode: selectedFileUtilizationNode,
            },
            {
              cpuData: filteredContainerCpuUtilizationData,
              cpuTableData: cpuUtilizationTableExportData(
                filteredContainerCpuUtilizationData
              ),
              cpuDateFilter: containerCpuUtilizationDateFilter,
              selectedCpuNode: selectedContainerCpuUtilizationNode,
              memoryData: filteredContainerMemoryUtilizationData,
              memoryTableData: containerMemoryUtilizationTableExportData(),
              memoryDateFilter: containerMemoryUtilizationDateFilter,
              selectedMemoryNode: selectedContainerMemoryUtilizationNode,
            }
          ),
          ...granulateChartsExcelData,
        ])
      );
    }
  }, [
    selectedConnection,
    selectedClusters,
    costTrendValues,
    costTrendDateRange,
    filteredNamespaceUsageCostData,
    namespaceUsageCostDateFilter,
    selectedNamespaceUsageCostNamespaces,
    clusterCostData,
    clusterCostDateFilter,
    selectedCostTrendNamespaces,
    filteredPodCpuUtilizationData,
    podCpuUtilizationDateFilter,
    filteredPodMemoryUtilizationData,
    filteredPodMemoryUtilizationData,
    podMemoryUtilizationDateFilter,
    podMemoryUtilizationSelectedNode,
    filteredPodNetworkUtilizationData,
    podNetworkUtilizationDateFilter,
    podNetworkUtilizationSelectedNode,
    filteredNodeFileUtilizationData,
    nodeFileUtilizationDateFilter,
    selectedFileUtilizationNode,
    filteredContainerCpuUtilizationData,
    filteredContainerCpuUtilizationData,
    containerCpuUtilizationDateFilter,
    selectedContainerCpuUtilizationNode,
    filteredContainerMemoryUtilizationData,
    containerMemoryUtilizationDateFilter,
    selectedContainerMemoryUtilizationNode,
    selectedCpuUtilized,
    selectedCpuCount,
  ]);

  useEffect(() => {
    setIsCostTrendTableView(tableViewEnabled);
    setNamespaceUsageCostTableView(tableViewEnabled);
    setIsClusterCostTableView(tableViewEnabled);
    setPodMemoryUtilizationTableView(tableViewEnabled);
    setIsPodCpuUtilizationTableView(tableViewEnabled);
    setPodNetworkUtilizationTableView(tableViewEnabled);
    setNodeFileUtilizationTableView(tableViewEnabled);
    setIsContainerCpuUtilizationTableView(tableViewEnabled);
    setContainerMemoryUtilizationTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  /**
   * @function fetchClusters
   * @description Function to fetch the clusters for selected projects
   */
  const fetchClusters = () => {
    setClustersRequestStatus(REQUEST_STATUS.PROCESSING);

    const connectorId = selectedGranulateService
      ? selectedGranulateService.connectorId
      : selectedConnection?.connectorId;

    getChartData(AwsClustersQuery, connectorId)
      .then((res: any) => {
        const data = res.data;
        const clustersList = data?.map((item: any) => item.cluster) ?? [];
        setClusters(clustersList);
        setSelectedClusters(clustersList);
        setClustersRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setClustersRequestStatus);
      });
  };

  /**
   * @function fetchCostTrendData
   * @description Function to fetch the cost trend data
   */
  const fetchCostTrendData = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);
    const data = DUMMY_COST_TREND_VALUES;
    let costData: MonthlyCostType[] = [];
    const labels = getMonthYearShortList(
      costTrendDateRange[0],
      costTrendDateRange[1]
    );
    labels.forEach((label) => {
      const cost = data.find(
        (item: any) => item.month === getMonthAndYearDigitsFormat(label)
      );
      if (cost) {
        costData.push(cost);
        return;
      }
      costData.push({ month: getMonthAndYearDigitsFormat(label), cost: 0 });
    });
    setCostTrendValues(costData);
    setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
  };

  /**
   * @function fetchNamespaceUsageCostData
   * @description Function to fetch the namespace usage and cost data
   */
  const fetchNamespaceUsageCostData = () => {
    setNamespaceUsageCostRequestStatus(REQUEST_STATUS.PROCESSING);

    const data = DUMMY_NAMESPACES_COST;
    setNamespaceUsageCostData(data);
    setNamespaceUsageCostRequestStatus(REQUEST_STATUS.SUCCESS);
  };
  /**
   * @function fetchClusterCostData
   * @description Function to fetch the Namespace Usage and Cost data
   */
  const fetchClusterCostData = () => {
    setClusterCostRequestStatus(REQUEST_STATUS.PROCESSING);

    const data: ClusterCostType[] = DUMMY_CLUSTER_COST_VALUES;
    const colors = generateGraphColors(data?.length ?? 0);
    setClusterCostData(
      data.map((item) => ({ ...item, color: colors.pop() ?? '' }))
    );
    setClusterCostRequestStatus(REQUEST_STATUS.SUCCESS);
  };

  /**
   * @function fetchPodCpuUtilizationData
   * @description Function to fetch the Pod CPU Utilization data
   */
  const fetchPodCpuUtilizationData = () => {
    setPodCpuUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);
    const interval = getDateInterval(
      podCpuUtilizationDateFilter.startDate,
      podCpuUtilizationDateFilter.endDate
    );
    let labels: string[] = [];
    if (interval === DATE_INTERVALS.HOUR) {
      labels = getListOfHours();
    } else {
      labels = getDateMonthShortList(
        getFormattedStartDate(
          podCpuUtilizationDateFilter.startDate,
          interval
        ).format(HYPHEN_DATE_FORMAT),
        podCpuUtilizationDateFilter.endDate,
        interval,
        getDateFormatForGraphLabels(interval)
      );
    }

    const requestBody = getPodCpuUtilizationQuery(
      [
        podCpuUtilizationDateFilter.startDate,
        podCpuUtilizationDateFilter.endDate,
      ],
      interval,
      selectedClusters
    );

    const connectorId = selectedGranulateService
      ? selectedGranulateService.connectorId
      : selectedConnection?.connectorId;

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        let data: any[] = res?.data ?? [];
        const utilizationData: UtilizationByNodeAndTimeType[] = [];
        const cpuUtilizationNodes = uniqBy(data, 'node').map(
          (item) => item.node
        );
        labels.forEach((time) => {
          cpuUtilizationNodes.forEach((node) => {
            // Fetch the data for the time and node
            const dataItem = data.find(
              (item) =>
                formatDateFieldData(item.time, interval).format(
                  getDateFormatForGraphLabels(interval)
                ) === time && item.node === node
            );
            POD_CPU_UTILIZATION_DIMENSIONS.forEach((dimension) => {
              utilizationData.push({
                time: time,
                node: node,
                dimension: dimension.displayLabel,
                utilization: Number(dataItem?.[dimension.dataLabel] ?? 0),
              });
            });
          });
        });

        setPodCpuUtilizationNodes(cpuUtilizationNodes);
        setSelectedPodCpuUtilizationNode(cpuUtilizationNodes[0]);
        setPodCpuUtilizationData(utilizationData);
        setPodCpuUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setPodCpuUtilizationRequestStatus);
      });
  };

  /**
   * @function fetchPodMemoryUtilizationData
   * @description Function to fetch the Pod Memory Utilization data
   */
  const fetchPodMemoryUtilizationData = () => {
    setPodMemoryUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = getPodMemoryUtilizationQuery(
      [
        podMemoryUtilizationDateFilter.startDate,
        podMemoryUtilizationDateFilter.endDate,
      ],
      selectedClusters
    );

    const connectorId = selectedGranulateService
      ? selectedGranulateService.connectorId
      : selectedConnection?.connectorId;

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        let data: any[] = res?.data ?? [];
        const utilizationData: UtilizationByDimensionAndNodeType[] = [];
        const memoryUtilizationNodes = uniqBy(data, 'node').map(
          (item) => item.node
        );
        memoryUtilizationNodes.forEach((node) => {
          // Fetch the data for the time and node
          const dataItem = data.find((item) => item.node === node);
          POD_MEMORY_UTILIZATION_DIMENSIONS.forEach((dimension) => {
            utilizationData.push({
              node: node,
              dimension: dimension.displayLabel,
              utilization: Number(dataItem?.[dimension.dataLabel] ?? 0),
            });
          });
        });

        setPodMemoryUtilizationNodes(memoryUtilizationNodes);
        setPodMemoryUtilizationSelectedNode(memoryUtilizationNodes[0]);
        setPodMemoryUtilizationData(utilizationData);
        setPodMemoryUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setPodMemoryUtilizationRequestStatus);
      });
  };

  /**
   * @function fetchPodNetworkUtilizationData
   * @description Function to fetch the Pod Network Utilization data
   */
  const fetchPodNetworkUtilizationData = () => {
    setPodNetworkUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = getPodNetworkUtilizationQuery(
      [
        podNetworkUtilizationDateFilter.startDate,
        podNetworkUtilizationDateFilter.endDate,
      ],
      selectedClusters
    );

    const connectorId = selectedGranulateService
      ? selectedGranulateService.connectorId
      : selectedConnection?.connectorId;

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        let data: any[] = res?.data ?? [];
        const utilizationData: UtilizationByDimensionAndNodeType[] = [];
        const networkUtilizationNodes = uniqBy(data, 'node').map(
          (item) => item.node
        );
        networkUtilizationNodes.forEach((node) => {
          const dataItem = data.find((item) => item.node === node);
          POD_NETWORK_UTILIZATION_DIMENSIONS.forEach((dimension) => {
            utilizationData.push({
              node: node,
              dimension: dimension.displayLabel,
              utilization: Number(dataItem?.[dimension.dataLabel] ?? 0),
            });
          });
        });

        setPodNetworkUtilizationNodes(networkUtilizationNodes);
        setPodNetworkUtilizationSelectedNode(networkUtilizationNodes[0]);
        setPodNetworkUtilizationData(utilizationData);
        setPodNetworkUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setPodNetworkUtilizationRequestStatus);
      });
  };

  /**
   * @function fetchNodeFileUtilizationData
   * @description Function to fetch the Node File Utilization data
   */
  const fetchNodeFileUtilizationData = () => {
    setNodeFileUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);
    const interval = getDateInterval(
      nodeFileUtilizationDateFilter.startDate,
      nodeFileUtilizationDateFilter.endDate
    );
    let labels: string[] = [];
    if (interval === DATE_INTERVALS.HOUR) {
      labels = getListOfHours();
    } else {
      labels = getDateMonthShortList(
        getFormattedStartDate(
          nodeFileUtilizationDateFilter.startDate,
          interval
        ).format(HYPHEN_DATE_FORMAT),
        nodeFileUtilizationDateFilter.endDate,
        interval,
        getDateFormatForGraphLabels(interval)
      );
    }

    const requestBody = getNodeFileUtilizationQuery(
      [
        nodeFileUtilizationDateFilter.startDate,
        nodeFileUtilizationDateFilter.endDate,
      ],
      interval,
      selectedClusters
    );

    const connectorId = selectedGranulateService
      ? selectedGranulateService.connectorId
      : selectedConnection?.connectorId;

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        let data: any[] = res?.data ?? [];
        const utilizationData: UtilizationByNodeAndTimeType[] = [];
        const fileUtilizationNodes = uniqBy(data, 'node').map(
          (item) => item.node
        );
        labels.forEach((time) => {
          fileUtilizationNodes.forEach((node) => {
            const dataItem = data.find(
              (item) =>
                formatDateFieldData(item.time, interval).format(
                  getDateFormatForGraphLabels(interval)
                ) === time && item.node === node
            );
            FILE_UTILIZATION_DIMENSIONS.forEach((dimension) => {
              utilizationData.push({
                time: time,
                node: node,
                dimension: dimension.displayLabel,
                utilization: Number(dataItem?.[dimension.dataLabel] ?? 0),
              });
            });
          });
        });

        setNodeFileUtilizationNodes(fileUtilizationNodes);
        setSelectedFileUtilizationNode(fileUtilizationNodes[0]);
        setNodeFileUtilizationData(utilizationData);
        setNodeFileUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setNodeFileUtilizationRequestStatus);
      });
  };

  /**
   * @function fetchContainerCpuUtilizationData
   * @description Function to fetch the Container CPU Utilization data
   */
  const fetchContainerCpuUtilizationData = () => {
    setContainerCpuUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);
    const interval = getDateInterval(
      containerCpuUtilizationDateFilter.startDate,
      containerCpuUtilizationDateFilter.endDate
    );
    let labels: string[] = [];
    if (interval === DATE_INTERVALS.HOUR) {
      labels = getListOfHours();
    } else {
      labels = getDateMonthShortList(
        getFormattedStartDate(
          containerCpuUtilizationDateFilter.startDate,
          interval
        ).format(HYPHEN_DATE_FORMAT),
        containerCpuUtilizationDateFilter.endDate,
        interval,
        getDateFormatForGraphLabels(interval)
      );
    }

    const requestBody = getContainerCpuUtilizationQuery(
      [
        containerCpuUtilizationDateFilter.startDate,
        containerCpuUtilizationDateFilter.endDate,
      ],
      interval,
      selectedClusters
    );

    const connectorId = selectedGranulateService
      ? selectedGranulateService.connectorId
      : selectedConnection?.connectorId;

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        let data: any[] = res?.data ?? [];
        const utilizationData: UtilizationByNodeAndTimeType[] = [];
        const cpuUtilizationNodes = uniqBy(data, 'node').map(
          (item) => item.node
        );
        labels.forEach((time) => {
          cpuUtilizationNodes.forEach((node) => {
            const dataItem = data.find(
              (item) =>
                formatDateFieldData(item.time, interval).format(
                  getDateFormatForGraphLabels(interval)
                ) === time && item.node === node
            );
            CONTAINER_CPU_UTILIZATION_DIMENSIONS.forEach((dimension) => {
              utilizationData.push({
                time: time,
                node: node,
                dimension: dimension.displayLabel,
                utilization: Number(dataItem?.[dimension.dataLabel] ?? 0),
              });
            });
          });
        });

        setContainerCpuUtilizationNodes(cpuUtilizationNodes);
        setSelectedContainerCpuUtilizationNode(cpuUtilizationNodes[0]);
        setContainerCpuUtilizationData(utilizationData);
        setContainerCpuUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setContainerCpuUtilizationRequestStatus);
      });
  };

  /**
   * @function fetchContainerMemoryUtilizationData
   * @description Function to fetch the Container Memory Utilization data
   */
  const fetchContainerMemoryUtilizationData = () => {
    setContainerMemoryUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);
    const interval = getDateInterval(
      containerMemoryUtilizationDateFilter.startDate,
      containerMemoryUtilizationDateFilter.endDate
    );
    let labels: string[] = [];
    if (interval === DATE_INTERVALS.HOUR) {
      labels = getListOfHours();
    } else {
      labels = getDateMonthShortList(
        getFormattedStartDate(
          containerMemoryUtilizationDateFilter.startDate,
          interval
        ).format(HYPHEN_DATE_FORMAT),
        containerMemoryUtilizationDateFilter.endDate,
        interval,
        getDateFormatForGraphLabels(interval)
      );
    }

    const requestBody = getContainerMemoryUtilizationQuery(
      [
        containerMemoryUtilizationDateFilter.startDate,
        containerMemoryUtilizationDateFilter.endDate,
      ],
      interval,
      selectedClusters
    );

    const connectorId = selectedGranulateService
      ? selectedGranulateService.connectorId
      : selectedConnection?.connectorId;

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        let data: any[] = res?.data ?? [];
        const utilizationData: UtilizationByNodeAndTimeType[] = [];
        const memoryUtilizationNodes = uniqBy(data, 'node').map(
          (item) => item.node
        );
        labels.forEach((time) => {
          memoryUtilizationNodes.forEach((node) => {
            const dataItem = data.find(
              (item) =>
                formatDateFieldData(item.time, interval).format(
                  getDateFormatForGraphLabels(interval)
                ) === time && item.node === node
            );
            CONTAINER_MEMORY_UTILIZATION_DIMENSIONS.forEach((dimension) => {
              utilizationData.push({
                time: time,
                node: node,
                dimension: dimension.displayLabel,
                utilization: Number(dataItem?.[dimension.dataLabel] ?? 0),
              });
            });
          });
        });

        setContainerMemoryUtilizationNodes(memoryUtilizationNodes);
        setSelectedContainerMemoryUtilizationNode(memoryUtilizationNodes[0]);
        setContainerMemoryUtilizationData(utilizationData);
        setContainerMemoryUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setContainerMemoryUtilizationRequestStatus);
      });
  };

  /**
   * @function cpuUtilizationTableExportData
   * @description Function to return the pod/container cpu utilization data for tabular view export
   * @returns List of cpu utilization grouped by for each dimension and month
   */
  const cpuUtilizationTableExportData = (
    data: UtilizationByNodeAndTimeType[]
  ) => {
    const groupedByDimension: any[] = [];
    data.forEach((item) => {
      const existingDimension = groupedByDimension.find(
        (obj) => obj.dimension === item.dimension
      );
      if (existingDimension) {
        existingDimension[`${item.time}-utilization`] = `${numberCommaSeparator(
          item.utilization ?? 0
        )}%`;
      } else {
        groupedByDimension.push({
          dimension: item.dimension,
          [`${item.time}-utilization`]: `${numberCommaSeparator(
            item.utilization
          )}%`,
          key: groupedByDimension.length,
          slNo: groupedByDimension.length + 1,
        });
      }
    });
    return groupedByDimension;
  };

  /**
   * @function nodeFileUtilizationTableExportData
   * @description Function to return the node file utilization data for tabular view export
   * @returns List of node file utilization grouped for each dimension and month
   */
  const nodeFileUtilizationTableExportData = () => {
    const groupedByDimension: any[] = [];
    filteredNodeFileUtilizationData.forEach((item) => {
      const suffix =
        item.dimension ===
        FILE_UTILIZATION_DIMENSIONS.find(
          (item) =>
            FileUtilizationDimensionEnum.DISK_UTILIZATION_PERCENTAGE ===
            item.dataLabel
        )?.displayLabel
          ? '%'
          : '';
      const existingDimension = groupedByDimension.find(
        (obj) => obj.dimension === item.dimension
      );
      if (existingDimension) {
        existingDimension[`${item.time}-utilization`] = `${numberCommaSeparator(
          item.utilization ?? 0
        )} ${suffix}`;
      } else {
        groupedByDimension.push({
          dimension: item.dimension,
          [`${item.time}-utilization`]: `${numberCommaSeparator(
            item.utilization
          )} ${suffix}`,
          key: groupedByDimension.length,
          slNo: groupedByDimension.length + 1,
        });
      }
    });
    return groupedByDimension;
  };

  /**
   * @function containerMemoryUtilizationTableExportData
   * @description Function to return the container memory utilization data for tabular view export
   * @returns List of container memory utilization grouped for each dimension and month
   */
  const containerMemoryUtilizationTableExportData = () => {
    const groupedByDimension: any[] = [];
    filteredContainerMemoryUtilizationData.forEach((item) => {
      const suffix =
        item.dimension ===
        CONTAINER_MEMORY_UTILIZATION_DIMENSIONS.find(
          (item) =>
            ContainerMemoryUtilizationDimensionEnum.MEMORY_UTILIZATION_BY_CONTAINER ===
            item.dataLabel
        )?.displayLabel
          ? '%'
          : '';

      const dimensionSuffix =
        item.dimension ===
        CONTAINER_MEMORY_UTILIZATION_DIMENSIONS.find(
          (item) =>
            ContainerMemoryUtilizationDimensionEnum.MEMORY_UTILIZATION_BY_CONTAINER ===
            item.dataLabel
        )?.displayLabel
          ? ''
          : ` (${t('containerInsight.usageMeteringLabels.bytes')})`;

      const existingDimension = groupedByDimension.find(
        (obj) => obj.dimension === item.dimension + dimensionSuffix
      );
      if (existingDimension) {
        existingDimension[`${item.time}-utilization`] = `${numberCommaSeparator(
          item.utilization ?? 0
        )} ${suffix}`;
      } else {
        groupedByDimension.push({
          dimension: item.dimension + dimensionSuffix,
          [`${item.time}-utilization`]: `${numberCommaSeparator(
            item.utilization
          )} ${suffix}`,
          key: groupedByDimension.length,
          slNo: groupedByDimension.length + 1,
        });
      }
    });
    return groupedByDimension;
  };

  /**
   * @function getGraphComponent
   * @description Function to return the react components for graphs
   * @param graphName Name of the graph
   * @param pdfView Is the graph for pdf download, defaults to false
   */
  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'cost-trend':
        return (
          <CostTrend
            costTrendValues={costTrendValues}
            dateRange={costTrendDateRange}
            setDateRange={setCostTrendDateRange}
            allNamespaces={costTrendNamespaces}
            selectedNamespaces={selectedCostTrendNamespaces}
            setSelectedNamespaces={setSelectedCostTrendNamespaces}
            requestStatus={[
              costTrendRequestStatus,
              podCpuUtilizationRequestStatus,
              clustersRequestStatus,
            ]}
            costTrendNamespaceRequestStatus={clustersRequestStatus}
            setIsCostTrendTableView={setIsCostTrendTableView}
            isCostTrendTableView={isCostTrendTableView}
            pdfView={pdfView}
            excelFilters={[
              ...getExcelFilters(),
              {
                heading: t('excelExportLabels.namespaces'),
                value: selectedCostTrendNamespaces.toString(),
              },
              {
                heading: t('excelExportLabels.startDate'),
                value: moment(costTrendDateRange[0]).format(MONTH_YEAR_FORMAT),
              },
              {
                heading: t('excelExportLabels.endDate'),
                value: moment(costTrendDateRange[1]).format(MONTH_YEAR_FORMAT),
              },
            ]}
          />
        );

      case 'namespace-usage-cost':
        return (
          <NamespaceUsageCost
            namespaceUsageCostData={namespaceUsageCostData}
            filteredNamespaceUsageCostData={filteredNamespaceUsageCostData}
            selectedNamespaces={selectedNamespaceUsageCostNamespaces}
            setSelectedNamespaces={setSelectedNamespaceUsageCostNamespaces}
            requestStatus={[
              namespaceUsageCostRequestStatus,
              podMemoryUtilizationRequestStatus,
              clustersRequestStatus,
            ]}
            namespaceUsageCostTableView={namespaceUsageCostTableView}
            setNamespaceUsageCostTableView={setNamespaceUsageCostTableView}
            pdfView={pdfView}
            namespaceUsageCostDateFilter={namespaceUsageCostDateFilter}
            setNamespaceUsageCostDateFilter={setNamespaceUsageCostDateFilter}
            excelFilters={getExcelFilters()}
          />
        );

      case 'cluster-cost':
        return (
          <ClusterCost
            isClusterCostTableView={isClusterCostTableView}
            setClusterCostTableView={setIsClusterCostTableView}
            requestStatus={[
              clusterCostRequestStatus,
              podCpuUtilizationRequestStatus,
              clustersRequestStatus,
            ]}
            clusterCostData={clusterCostData}
            pdfView={pdfView}
            clusterCostDateFilter={clusterCostDateFilter}
            setClusterCostDateFilter={setClusterCostDateFilter}
            excelFilters={getExcelFilters()}
          />
        );

      case 'pod-cpu-utilization':
        return (
          <PodCpuUtilization
            tableData={cpuUtilizationTableExportData(
              filteredPodCpuUtilizationData
            )}
            allNodes={podCpuUtilizationNodes}
            selectedNode={selectedPodCpuUtilizationNode}
            setSelectedNode={setSelectedPodCpuUtilizationNode}
            requestStatus={[
              podCpuUtilizationRequestStatus,
              clustersRequestStatus,
            ]}
            isTableView={isPodCpuUtilizationTableView}
            setIsTableView={setIsPodCpuUtilizationTableView}
            filteredPodCpuUtilizationData={filteredPodCpuUtilizationData}
            pdfView={pdfView}
            podCpuUtilizationDateFilter={podCpuUtilizationDateFilter}
            setPodCpuUtilizationDateFilter={setPodCpuUtilizationDateFilter}
            excelFilters={getExcelFilters()}
          />
        );

      case 'pod-memory-utilization':
        return (
          <PodMemoryUtilization
            podMemoryUtilizationData={filteredPodMemoryUtilizationData}
            allNodes={podMemoryUtilizationNodes}
            podMemoryUtilizationSelectedNode={podMemoryUtilizationSelectedNode}
            setPodMemoryUtilizationSelectedNode={
              setPodMemoryUtilizationSelectedNode
            }
            requestStatus={[
              podMemoryUtilizationRequestStatus,
              clustersRequestStatus,
            ]}
            podMemoryUtilizationTableView={podMemoryUtilizationTableView}
            setPodMemoryUtilizationTableView={setPodMemoryUtilizationTableView}
            pdfView={pdfView}
            podMemoryUtilizationDateFilter={podMemoryUtilizationDateFilter}
            setPodMemoryUtilizationDateFilter={
              setPodMemoryUtilizationDateFilter
            }
            excelFilters={getExcelFilters()}
          />
        );

      case 'pod-network-utilization':
        return (
          <PodNetworkUtilization
            podNetworkUtilizationData={filteredPodNetworkUtilizationData}
            allNodes={podNetworkUtilizationNodes}
            podNetworkUtilizationSelectedNode={
              podNetworkUtilizationSelectedNode
            }
            setPodNetworkUtilizationSelectedNode={
              setPodNetworkUtilizationSelectedNode
            }
            requestStatus={[
              podNetworkUtilizationRequestStatus,
              clustersRequestStatus,
            ]}
            podNetworkUtilizationTableView={podNetworkUtilizationTableView}
            setPodNetworkUtilizationTableView={
              setPodNetworkUtilizationTableView
            }
            pdfView={pdfView}
            podNetworkUtilizationDateFilter={podNetworkUtilizationDateFilter}
            setPodNetworkUtilizationDateFilter={
              setPodNetworkUtilizationDateFilter
            }
            excelFilters={getExcelFilters()}
          />
        );

      case 'node-file-utilization':
        return (
          <NodeFileUtilization
            tableData={nodeFileUtilizationTableExportData()}
            allNodes={nodeFileUtilizationNodes}
            selectedNode={selectedFileUtilizationNode}
            setSelectedNode={setSelectedFileUtilizationNode}
            requestStatus={[
              nodeFileUtilizationRequestStatus,
              clustersRequestStatus,
            ]}
            isTableView={nodeFileUtilizationTableView}
            setIsTableView={setNodeFileUtilizationTableView}
            filteredNodeFileUtilizationData={filteredNodeFileUtilizationData}
            pdfView={pdfView}
            nodeFileUtilizationDateFilter={nodeFileUtilizationDateFilter}
            setNodeFileUtilizationDateFilter={setNodeFileUtilizationDateFilter}
            excelFilters={getExcelFilters()}
          />
        );

      case 'container-cpu-utilization':
        return (
          <ContainerCpuUtilization
            tableData={cpuUtilizationTableExportData(
              filteredContainerCpuUtilizationData
            )}
            allNodes={containerCpuUtilizationNodes}
            selectedNode={selectedContainerCpuUtilizationNode}
            setSelectedNode={setSelectedContainerCpuUtilizationNode}
            requestStatus={[
              containerCpuUtilizationRequestStatus,
              clustersRequestStatus,
            ]}
            isTableView={isContainerCpuUtilizationTableView}
            setIsTableView={setIsContainerCpuUtilizationTableView}
            filteredContainerCpuUtilizationData={
              filteredContainerCpuUtilizationData
            }
            pdfView={pdfView}
            containerCpuUtilizationDateFilter={
              containerCpuUtilizationDateFilter
            }
            setContainerCpuUtilizationDateFilter={
              setContainerCpuUtilizationDateFilter
            }
            excelFilters={getExcelFilters()}
          />
        );

      case 'container-memory-utilization':
        return (
          <ContainerMemoryUtilization
            tableData={containerMemoryUtilizationTableExportData()}
            allNodes={containerMemoryUtilizationNodes}
            selectedNode={selectedContainerMemoryUtilizationNode}
            setSelectedNode={setSelectedContainerMemoryUtilizationNode}
            requestStatus={[
              containerMemoryUtilizationRequestStatus,
              clustersRequestStatus,
            ]}
            isTableView={containerMemoryUtilizationTableView}
            setIsTableView={setContainerMemoryUtilizationTableView}
            filteredContainerMemoryUtilizationData={
              filteredContainerMemoryUtilizationData
            }
            pdfView={pdfView}
            containerMemoryUtilizationDateFilter={
              containerMemoryUtilizationDateFilter
            }
            setContainerMemoryUtilizationDateFilter={
              setContainerMemoryUtilizationDateFilter
            }
            excelFilters={getExcelFilters()}
          />
        );
    }
  };

  /**
   * @function getExcelFilters
   * @description Function to return the filters applied for excel export
   * @returns List of filters applied
   */
  const getExcelFilters = () => {
    return [
      {
        heading: t('excelExportLabels.connectionName'),
        value: showConsolidatedDashboard
          ? selectedGranulateService?.connectionName
          : selectedConnection?.displayName,
      },
      {
        heading: t('excelExportLabels.clusters'),
        value: selectedClusters.toString(),
      },
    ];
  };

  /**
   * @function getPdfMetaData
   * @description Function to return the metadata for pdf file export
   * @returns Object containing metadata for pdf file export
   */
  const getPdfMetaData = () => {
    return {
      viewName: t('dashNav.usageMetering'),
      fileName: showConsolidatedDashboard
        ? selectedGranulateService?.connectionName ?? ''
        : selectedConnection?.displayName ?? '',
      heading: showConsolidatedDashboard
        ? selectedGranulateService?.connectionName ?? ''
        : selectedConnection?.displayName ?? '',
      subtitle1: showConsolidatedDashboard
        ? selectedGranulateService?.name ?? ''
        : selectedConnection?.dataSourceType ?? '',
      provider: showConsolidatedDashboard
        ? selectedGranulateService?.provider ?? ''
        : selectedConnection?.provider,
    };
  };

  return (
    <>
      {getGraphComponent('cost-trend')}
      <Row gutter={[24, 24]}>
        <Col span={12}>{getGraphComponent('namespace-usage-cost')}</Col>
        <Col span={12}>{getGraphComponent('cluster-cost')}</Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={12}>{getGraphComponent('pod-cpu-utilization')}</Col>
        <Col span={12}>{getGraphComponent('pod-memory-utilization')}</Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={12}>{getGraphComponent('pod-network-utilization')}</Col>
        <Col span={12}> {getGraphComponent('node-file-utilization')}</Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={12}>{getGraphComponent('container-cpu-utilization')}</Col>
        <Col span={12}>{getGraphComponent('container-memory-utilization')}</Col>
      </Row>
      {showExpandGraphModal && (
        <ExpandModal
          show={showExpandGraphModal}
          graphContent={
            GRANULATE_GRAPHS.includes(expandedGraphName)
              ? getGranulateGraphComponent(expandedGraphName)
              : getGraphComponent(expandedGraphName)
          }
        />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfContent={[
            ...PdfComponents,
            {
              element: getGraphComponent('cost-trend', true),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: 'cost-trend',
              column: getCostTrendExportColumns(),
              body: costTrendValues.map((value, index) => ({
                month: moment(value.month).format(MONTH_YEAR_FORMAT),
                cost: numberCommaSeparator(value.cost),
                slNo: index,
              })),
              tableName: t('graphHeadings.costTrend'),
              isTableView: isCostTrendTableView,
            },
            {
              element: getGraphComponent('namespace-usage-cost', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'namespace-usage-cost',
              column: getNamespaceUsageCostExportColumns(),
              body: insertIndex(filteredNamespaceUsageCostData).map((item) => ({
                ...item,
                costAfterCredit:
                  currencySymbol + numberCommaSeparator(item.costAfterCredit),
              })),
              tableName: t('graphHeadings.namespaceUsageCost'),
              isTableView: namespaceUsageCostTableView,
            },
            {
              element: getGraphComponent('cluster-cost', true),
              contentType: CHART_TYPES.DOUGHNUT_CHART,
              graphName: 'cluster-cost',
              column: getCostClusterCostExportColumns(),
              body: insertIndex(clusterCostData),
              tableName: t('graphHeadings.clusterCost'),
              isTableView: isClusterCostTableView,
              legends: clusterCostData.map((item) => ({
                name: item.cluster,
                color: item.color,
              })),
            },
            {
              element: getGraphComponent('pod-cpu-utilization', true),
              contentType: CHART_TYPES.LINE_CHART,
              graphName: 'pod-cpu-utilization',
              column: getUtilizationByDimensionExportColumns(
                filteredPodCpuUtilizationData
              ),
              body: cpuUtilizationTableExportData(
                filteredPodCpuUtilizationData
              ),
              tableName: t('graphHeadings.podCpuUtilization'),
              isTableView: isPodCpuUtilizationTableView,
            },
            {
              element: getGraphComponent('pod-memory-utilization', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'pod-memory-utilization',
              column: PodMemoryUtilizationExportColumns,
              body: getPodMemoryUtilizationExportData(
                filteredPodMemoryUtilizationData
              ),
              tableName: t('graphHeadings.podMemoryUtilization'),
              isTableView: podMemoryUtilizationTableView,
            },
            {
              element: getGraphComponent('pod-network-utilization', true),
              contentType: CHART_TYPES.DOUGHNUT_CHART,
              graphName: 'pod-network-utilization',
              column: PodNetworkUtilizationExportColumns,
              body: insertIndex(filteredPodNetworkUtilizationData).map(
                (item) => ({
                  ...item,
                  utilization: numberCommaSeparator(item.utilization),
                })
              ),
              tableName: t('graphHeadings.podNetworkUtilization'),
              isTableView: podNetworkUtilizationTableView,
            },
            {
              element: getGraphComponent('node-file-utilization', true),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: 'node-file-utilization',
              column: getUtilizationByDimensionExportColumns(
                filteredNodeFileUtilizationData
              ),
              body: nodeFileUtilizationTableExportData(),
              tableName: t('graphHeadings.nodeFileUtilization'),
              isTableView: nodeFileUtilizationTableView,
            },
            {
              element: getGraphComponent('container-cpu-utilization', true),
              contentType: CHART_TYPES.LINE_CHART,
              graphName: 'container-cpu-utilization',
              column: getUtilizationByDimensionExportColumns(
                filteredContainerCpuUtilizationData
              ),
              body: cpuUtilizationTableExportData(
                filteredContainerCpuUtilizationData
              ),
              tableName: t('graphHeadings.containerCpuUtilization'),
              isTableView: isContainerCpuUtilizationTableView,
            },
            {
              element: getGraphComponent('container-memory-utilization', true),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: 'container-memory-utilization',
              column: getUtilizationByDimensionExportColumns(
                filteredContainerMemoryUtilizationData
              ),
              body: containerMemoryUtilizationTableExportData(),
              tableName: t('graphHeadings.containerMemoryUtilization'),
              isTableView: containerMemoryUtilizationTableView,
            },
          ]}
          pdfMetaData={getPdfMetaData()}
          pdfCardData={{
            costOptmization: {
              cards: showConsolidatedDashboard
                ? getGranulateGraphComponent('info-cards', true)
                : undefined,
              insertAtStart: true,
            },
          }}
        />
      )}
    </>
  );
};

export default AWSUsageMetering;
