import i18n from 'i18n';

export enum GranulateQuickActions {
  DEACTIVATE = 'DEACTIVATE',
  ACTIVATED = 'ACTIVATED',
}

export const GRANULATE_CONNECTION_QUICKACTIONS = [
  {
    id: GranulateQuickActions.DEACTIVATE,
    title: i18n.t('granulateConnectionPage.deactivate'),
  },
  {
    id: GranulateQuickActions.ACTIVATED,
    title: i18n.t('granulateConnectionPage.activate'),
  },
];

export enum ServiceStatus {
  PASSIVE = 'passive',
  ACTIVE = 'active',
}
