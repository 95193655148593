import moment from 'moment';
import {
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
} from 'constants/requestBody';
import { formatArrayToString } from 'utils/dataFormatterUtils';
import { HYPHEN_DATE_FORMAT } from 'utils/date';
import { ComparisonListType, ConnectionListType } from 'types/dashboard';
import { CoverageUtilizationExcelMetaData } from 'pages/ScorecardPage/types';

import { getSavingsTrackExcelData } from '../SavingsTrack/utils';
import { getUtilizationCoverageSummaryExcelData } from '../CoverageUtilizationSummaryGraph/utils';

/**
 * @function geCostSavingsQuery
 * @description Function to construct and return the cost savings query for GCP
 * @param recommenders List of string recommenders
 * @param states List of string states
 * @param dateRange Object containing the start date and end date
 * @returns Object containing the cost savings query
 */
export const geCostSavingsQuery = (
  recommenders: string[],
  states: string[],
  dateRange: { startDate: string; endDate: string }
) => {
  return {
    columns: [
      {
        label: 'totalCostSaved',
        field: QUERY_FIELDS.SUM_UNITS_PLUS_NANOS,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.SEQUENCE_NUMBER,
          field:
            'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.LAST_REFRESH_TIME,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange.startDate).format(HYPHEN_DATE_FORMAT),
            },
            {
              field: QUERY_FIELDS.LAST_REFRESH_TIME,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange.endDate).format(HYPHEN_DATE_FORMAT),
            },
          ],
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: formatArrayToString(states),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.RECOMMENDER,
                comparator: COMPARATORS.IN,
                value: formatArrayToString(recommenders),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getGCPScorecardSummaryExcelExportData
 * @description Function to construct and return gcp scorecard summary excel export data
 * @param savingsTrackData List of svings track data
 * @param cudExcelData Utilization and coverage data for CUD
 * @param connection Connection details of the selected dashboard
 * @returns List containing the excel export data
 */
export const getGCPScorecardSummaryExcelExportData = (
  savingsTrackData: ComparisonListType[],
  cudExcelData: {
    utilization: CoverageUtilizationExcelMetaData;
    coverage: CoverageUtilizationExcelMetaData;
  },
  connection: ConnectionListType | null
) => {
  return [
    getSavingsTrackExcelData(savingsTrackData, connection),
    ...getUtilizationCoverageSummaryExcelData(
      cudExcelData.utilization,
      cudExcelData.coverage,
      connection
    ),
  ];
};
