import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import { DeleteIconButton } from 'assets/icons';
import { PROVIDER } from 'constants/cloudProviders';
import {
  AMOUNT_MINIMUM,
  PERCENT,
  PERCENTAGE_MINIMUM,
} from 'constants/validation';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { BudgetActionType } from 'pages/BudgetsAndAlertsPage/types';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { providerList } from 'redux/providerSlice';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import { getValidationStyle } from 'utils/validations';

import { BUDGET_PERCENTAGE_MAX_LIMIT } from '../../GCP/SetupBudgetsDetails/constants';
import {
  AZURE_BUDGET_PERCENTAGE_MIN_LIMIT,
  AZURE_BUDGET_PERCENTAGE_MAX_LIMIT,
} from '../../Azure/SetupBudgetDetails/constants';

type BudgetRowProps = {
  triggerAtOptions: { value: string; label: string }[];
  budget: BudgetActionType;
  index: number;
};

const BudgetRow = ({ triggerAtOptions, budget, index }: BudgetRowProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { budgetData } = useSelector(budgetsAndAlerts);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { selectedProvider } = useSelector(providerList);

  const [validationMessage, setValidationMessage] = useState('');

  useDidMountEffect(() => {
    validateBudgetRow();
  }, [budgetData.thresholdRules]);

  const removeBudget = () => {
    const tempArr = [...budgetData.thresholdRules];
    tempArr.splice(index, 1);
    dispatch(setBudgetData({ ...budgetData, thresholdRules: tempArr }));
  };

  /**
   * @function getMinMaxPercentageLimit
   * @description Function to get min and max percentage limit based on the provider
   * @returns min and max percentage limit
   */
  const getMinMaxPercentageLimit = () => {
    if (selectedProvider !== PROVIDER.AZURE) {
      return {
        min: PERCENTAGE_MINIMUM,
        max: BUDGET_PERCENTAGE_MAX_LIMIT,
      };
    }
    return {
      min: AZURE_BUDGET_PERCENTAGE_MIN_LIMIT,
      max: AZURE_BUDGET_PERCENTAGE_MAX_LIMIT,
    };
  };

  /**
   * @function validateBudgetRow
   * @description Function to validate the budget row
   */
  const validateBudgetRow = () => {
    if (budget.percentage <= 0 && selectedProvider !== PROVIDER.AZURE) {
      setValidationMessage(
        t('addBudgetAlert.createBudgetLabels.actions.percOfBudgetRequired')
      );
      return;
    }

    if (
      budget.percentage < getMinMaxPercentageLimit().min ||
      budget.percentage > getMinMaxPercentageLimit().max
    ) {
      setValidationMessage(
        t('addBudgetAlert.createBudgetLabels.actions.percOfBudget') +
          t('addBudgetAlert.createBudgetLabels.actions.percentageRangeError', {
            min: getMinMaxPercentageLimit().min,
            max: getMinMaxPercentageLimit().max,
          })
      );
      return;
    }

    if (validateBudgets()) {
      setValidationMessage(
        t('addBudgetAlert.createBudgetLabels.actions.percOfBudgetUnique')
      );
      return;
    }

    setValidationMessage('');
  };

  /**
   * @function validateBudgets
   * @description Function that validates the changed array of budgets for uniqueness and empty-ness
   * Sets validation error to all the duplicate budgets
   * @param updatedBudgets is an array of BudgetType
   */
  const validateBudgets = () => {
    return budgetData.thresholdRules.some(
      (otherBudget, idx) =>
        idx !== index &&
        otherBudget.percentage === budget.percentage &&
        otherBudget.triggerOn === budget.triggerOn
    );
  };

  const onHandlePercentageChange = (value: number) => {
    const tempArr = [...budgetData.thresholdRules];
    tempArr.splice(index, 1, {
      percentage: value,
      amount: value * (budgetData.amount / PERCENT),
      triggerOn: budget.triggerOn,
    });
    dispatch(setBudgetData({ ...budgetData, thresholdRules: tempArr }));
  };

  const onHandleAmountChange = (value: number) => {
    const tempArr = [...budgetData.thresholdRules];
    tempArr.splice(index, 1, {
      percentage: (value / budgetData.amount) * PERCENT,
      amount: value,
      triggerOn: budget.triggerOn,
    });
    dispatch(setBudgetData({ ...budgetData, thresholdRules: tempArr }));
  };

  const onHandleSelectTriggerAt = (value: any) => {
    const tempArr = [...budgetData.thresholdRules];
    tempArr.splice(index, 1, {
      percentage: budget.percentage,
      amount: budget.amount,
      triggerOn: value,
    });
    dispatch(setBudgetData({ ...budgetData, thresholdRules: tempArr }));
  };

  return (
    <Row gutter={16} className="budget-row" key={index}>
      <Col span={7} className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.actions.percOfBudget')}
          required={true}
        />
        <Input
          type="number"
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.actions.percOfBudgetPlaceholder'
          )}
          value={budget.percentage >= 0 ? budget.percentage : ''}
          onChange={onHandlePercentageChange}
          min={PERCENTAGE_MINIMUM}
          onBlur={() => validateBudgetRow()}
        />
      </Col>
      <Col span={7} className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.actions.amount', {
            currencySymbol: currencySymbol,
          })}
          required={true}
        />
        <Input
          type="number"
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.actions.budgetAmountPlaceholder'
          )}
          value={budget.amount >= 0 ? budget.amount : ''}
          onChange={onHandleAmountChange}
          min={AMOUNT_MINIMUM}
          onBlur={() => validateBudgetRow()}
        />
      </Col>
      <Col span={8} className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.actions.triggerAt')}
          required={true}
        />
        <SelectDropdown
          value={budget.triggerOn}
          options={triggerAtOptions}
          onSelect={onHandleSelectTriggerAt}
          onBlur={() => validateBudgetRow()}
          designVersion2
        />
      </Col>
      <Col span={2} className="flex-align-self-end">
        {budgetData.thresholdRules.length > 1 && (
          <DeleteIconButton
            className="cursor-pointer"
            data-testid="delete-icon"
            width={38}
            height={38}
            onClick={removeBudget}
          />
        )}
      </Col>
      <div
        style={{
          display: getValidationStyle(validationMessage),
        }}
        className="error font-validation-error"
      >
        {validationMessage}
      </div>
    </Row>
  );
};

export default BudgetRow;
