export enum REQUIRED_KEYS {
  OPTIONAL = 'OPTIONAL',
  MANDATORY = 'MANDATORY',
}

export const REQUIRED_OPTIONS = [
  { key: REQUIRED_KEYS.OPTIONAL, title: 'optional' },
  { key: REQUIRED_KEYS.MANDATORY, title: 'mandatory' },
];

export const MAXIMUM_TAGS = 15;
