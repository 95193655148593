import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { Table as AntTable } from 'antd';
import { uniqBy } from 'lodash';

import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { evaluateRequestArray } from 'utils/handleErrors';
import DualAxisChart from 'components/DualAxisChart';
import DatePicker from 'components/DatePicker';
import TooltipContent from 'components/TooltipContent';
import { HYPHEN_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'utils/date';
import { DualAxisChartData } from 'types/dashboard';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { isDateRangeDisabled, onOpenChange } from 'pages/OverviewPage/utils';
import { RangeValue as MomentRangeValue } from 'pages/OverviewPage/types';

import { getChartExcelDataWithDateFilter } from '../../utils';

type StackedDualAxisChartsProps = {
  title: string;
  graph: string;
  data: DualAxisChartData;
  xTitle?: string;
  leftYTitle: string;
  rightYTitle?: string;
  syncYAxis?: boolean;
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  dateRange: string[];
  setDateRange: (val: string[]) => void;
  pdfView: boolean;
  columns: any[];
  tableData: any[];
  selectedAccounts: string[];
  showSlider?: boolean;
  isStack?: boolean;
  isGroup?: boolean;
  excludePrefixTypes?: string[];
  maxMonths?: number;
};

const StackedDualAxisCharts = ({
  title,
  graph,
  data,
  xTitle,
  leftYTitle,
  rightYTitle,
  syncYAxis,
  requestStatus,
  isTableView,
  setIsTableView,
  dateRange,
  setDateRange,
  pdfView,
  columns,
  tableData,
  selectedAccounts,
  showSlider,
  isStack = true,
  isGroup = false,
  excludePrefixTypes = [],
  maxMonths,
}: StackedDualAxisChartsProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [dates, setDates] = useState<MomentRangeValue>(null);

  const onChangeDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT)
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment(dateString[1], MONTH_YEAR_FORMAT)
        .endOf('month')
        .format(HYPHEN_DATE_FORMAT),
    ]);
  };

  const getTooltipContentOverride = (title: string, data: any[]) => {
    return (
      <TooltipContent
        title={title}
        data={data.map((obj) => ({
          ...obj,
          prefix: excludePrefixTypes.includes(obj.name)
            ? undefined
            : currencySymbol,
        }))}
      />
    );
  };

  const getTableData = () => {
    const rightAxisLabels = uniqBy(data.rightAxisData, 'type').map(
      (item) => item.type
    );

    return tableData.filter((item) => !rightAxisLabels.includes(item.type));
  };

  const getSummaryComponent = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row className="font-caption-bold">
        <AntTable.Summary.Cell index={0} colSpan={2}>
          {data.rightAxisData?.[0]?.type}
        </AntTable.Summary.Cell>
        {data.rightAxisData.map((item, index) => (
          <AntTable.Summary.Cell index={index + 1} key={item.time}>
            {currencySymbol + numberCommaSeparator(item.value)}
          </AntTable.Summary.Cell>
        ))}
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  const getComponent = () =>
    isTableView ? (
      <Table
        pagination={false}
        dataSource={getTableData()}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2
        fillContainer
        summary={getSummaryComponent}
      />
    ) : (
      <DualAxisChart
        leftAxisData={data.leftAxisData}
        rightAxisData={data.rightAxisData}
        xField="time"
        leftAxisYField="value"
        rightAxisYField="value"
        syncYAxis={syncYAxis}
        leftAxisSeriesField="type"
        rightAxisSeriesField="type"
        xTitle={xTitle ?? t('computeDashboard.month')}
        leftYTitle={leftYTitle}
        rightYTitle={rightYTitle}
        prefixSymbol={currencySymbol}
        disableAnimation={pdfView}
        showSlider={showSlider}
        isStack={isStack}
        isGroup={isGroup}
        tooltipContentOverride={getTooltipContentOverride}
      />
    );

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(dateRange[0], HYPHEN_DATE_FORMAT),
          moment(dateRange[1], HYPHEN_DATE_FORMAT),
        ]}
        value={
          dates || [
            moment(dateRange[0], HYPHEN_DATE_FORMAT),
            moment(dateRange[1], HYPHEN_DATE_FORMAT),
          ]
        }
        onOpenChange={(open: boolean) => onOpenChange(open, setDates)}
        onCalendarChange={(val: MomentRangeValue) => setDates(val)}
        onChange={onChangeDateRange}
        disabledDate={(current: Moment) =>
          isDateRangeDisabled(current, dates, maxMonths)
        }
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  return (
    <div
      className={`dual-axis-charts graph-card ${graph} full-height flex flex-column flex-fit`}
      id="graph-container"
    >
      <GraphHeader
        heading={title}
        graphName={graph}
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getChartExcelDataWithDateFilter({
          sheetName: title,
          columns,
          data: tableData,
          connectorName: selectedDashboard?.connectorName ?? '',
          provider: selectedDashboard!.connectorProvider,
          dateRange,
          selectedAccounts,
        })}
        designVersion2
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default StackedDualAxisCharts;
