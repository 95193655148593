import axios from 'axios';
import { message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  RI_SNOW_IDENTIFIER,
  RecommendationOfferingClass,
  RecommendationTerm,
  TERMS_LIST,
  Terms,
} from 'pages/PurchasePage/constants';
import RecommendationsWrapper from 'pages/PurchasePage/components/RecommendationsWrapper';
import { ControlFilterType } from 'components/DashboardControl/types';
import RadioGroup from 'components/RadioGroup';
import { ORDERED_SERVICE_NOW_STATUS_LIST } from 'constants/workflowIntegrations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PurchaseSavingPlansType } from 'pages/PurchasePage/components/AwsSavingsPlans/components/PurchaseSavingPlans/types';
import { selectPurchase } from 'redux/purchaseSlice';
import { ComparisonListType } from 'types/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import { getAwsRegionIdFromName } from 'utils/dashboardUtils';

import {
  AWS_SERVICES_REQUEST_FIELDS,
  RIPaymentOptions,
  RI_PAYMENT_OPTIONS_LIST,
} from '../../constants';
import {
  convertYearToSeconds,
  getDefaultRiMetricCards,
  getRecommendedRIsColumns,
} from '../../utils';
import {
  getAWSRiRecommendations,
  getAwsElastiCacheRiOfferingDetails,
  purchaseAwsElastiCacheRis,
} from '../../services';
import { PurchaseReservedInstanceType } from '../../types';

const ElastiCacheRecommendation = () => {
  const { t } = useTranslation();
  const { selectedConnection } = useSelector(selectPurchase);

  const [filterValues, setFilterValues] = useState<{
    [key: string]: string | number;
  }>({
    term: RecommendationTerm.THREE_YEARS,
    paymentOption: RIPaymentOptions.NO_UPFRONT,
  });

  /**
   * @function purchaseElastiCacheRIs
   * @description Function to purchase the ElastiCache RIs
   * @param data RIs offering ids and details to purchase
   * @param setPurchaseRequestStatus set purchase request status for button
   * @param setData set data for Recommendation table
   * @param setMetricCards set metric cards data
   * @param setReqStatus set request status for Recommendation table
   */
  const purchaseElastiCacheRIs = async (
    data: (PurchaseReservedInstanceType | PurchaseSavingPlansType)[],
    setPurchaseRequestStatus: (val: string) => void,
    setData: (val: any[]) => void,
    setMetricCards: (val: ComparisonListType[]) => void,
    setReqStatus: (val: string) => void
  ) => {
    const offeringData = data as PurchaseReservedInstanceType[];
    const requests = offeringData.map((item) => {
      const requestBody = {
        connectorId: selectedConnection?.connectorId,
        riOfferingId: item.offeringId,
        count: item.quantity,
        region: item.region,
      };

      return purchaseAwsElastiCacheRis(requestBody);
    });

    try {
      const responses: any[] = await axios.all(requests);
      if (responses?.filter((res: any) => res?.status === 200).length > 0) {
        setPurchaseRequestStatus(REQUEST_STATUS.SUCCESS);
        const successCount = responses.filter(
          (res: any) => res?.status === 200
        ).length;
        message.success(
          t(
            'purchasePage.reservedInstancesLabels.purchaseRecommendedRiSuccess',
            {
              successCount,
              totalCount: responses.length,
            }
          )
        );
        fetchRecommendationData(setData, setMetricCards, setReqStatus);
        return;
      }
      message.error(
        t('purchasePage.reservedInstancesLabels.purchaseRecommendedRiError')
      );
      setPurchaseRequestStatus(REQUEST_STATUS.ERROR);
    } catch (e) {
      onApiCallError(
        e,
        true,
        setPurchaseRequestStatus,
        t('purchasePage.reservedInstancesLabels.purchaseRecommendedRiError')
      );
    }
  };

  const fetchRecommendationData = (
    setData: (val: any[]) => void,
    setMetricCards: (val: ComparisonListType[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);
    setMetricCards(getDefaultRiMetricCards());

    const params = {
      connectorId: selectedConnection?.connectorId,
      servicename: AWS_SERVICES_REQUEST_FIELDS.ELASTI_CACHE,
      paymentOption: filterValues.paymentOption,
      termInYears: filterValues.term,
      offeringClass: RecommendationOfferingClass.STANDARD,
    };
    getAWSRiRecommendations(params)
      .then((res: any) => {
        const data = res?.data?.responseData;
        setData(
          data?.reservationRecommendationList?.map((item: any) => ({
            ...item,
            serviceNowStatus: ORDERED_SERVICE_NOW_STATUS_LIST.at(
              Number(item.incidentStatus)
            ),
            incidentRecommendationStatus: item.approvalStatus,
            id: item.riid,
          }))
        );
        setMetricCards([
          {
            ...getDefaultRiMetricCards()[0],
            value: data?.totalPurchaseRecommendation,
            requestStatus: [REQUEST_STATUS.SUCCESS],
          },
          {
            ...getDefaultRiMetricCards()[1],
            value: data?.estimatedTotalCost,
            requestStatus: [REQUEST_STATUS.SUCCESS],
          },
          {
            ...getDefaultRiMetricCards()[2],
            value: data?.estimatedMonthlySaving,
            requestStatus: [REQUEST_STATUS.SUCCESS],
          },
          {
            ...getDefaultRiMetricCards()[3],
            value: data?.estimatedSavingsInPercentage,
            requestStatus: [REQUEST_STATUS.SUCCESS],
          },
        ]);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        setMetricCards(
          getDefaultRiMetricCards().map((item) => ({
            ...item,
            requestStatus: [REQUEST_STATUS.ERROR],
          }))
        );
        setData([]);
        onApiCallError(e, true, setRequestStatus);
      });
  };

  const controlFilters: ControlFilterType[] = [
    {
      title: t('purchasePage.term'),
      filter: (
        <RadioGroup
          options={TERMS_LIST.map((item) => ({
            label: item.label,
            value: item.recommendationValue,
          }))}
          onChange={(e: any) =>
            setFilterValues({ ...filterValues, term: e.target.value })
          }
          value={filterValues.term}
          optionType="button"
          rootClassName="no-custom-style radio-button-options"
          style={{ height: 28 }}
        />
      ),
      minimizedText:
        TERMS_LIST.find(
          (item) => item.recommendationValue === filterValues.term
        )?.label ?? '',
      onClear: () =>
        setFilterValues({
          ...filterValues,
          term: RecommendationTerm.THREE_YEARS,
        }),
      width: 'auto',
    },
    {
      title: t('purchasePage.paymentOptions'),
      filter: (
        <RadioGroup
          options={RI_PAYMENT_OPTIONS_LIST}
          onChange={(e: any) =>
            setFilterValues({ ...filterValues, paymentOption: e.target.value })
          }
          value={filterValues.paymentOption}
          optionType="button"
          rootClassName="no-custom-style radio-button-options"
          style={{ height: 28 }}
        />
      ),
      minimizedText:
        RI_PAYMENT_OPTIONS_LIST.find(
          (item) => item.value === filterValues.paymentOption
        )?.label ?? '',
      onClear: () =>
        setFilterValues({
          ...filterValues,
          paymentOption: RIPaymentOptions.NO_UPFRONT,
        }),
      width: 'auto',
    },
  ];

  /**
   * @function onClickPurchase
   * @description Function to purchase the selected elasticache RIs
   * @param selectedRecommendations selected elasticache RIs
   * @param setPurchaseRequestStatus set purchase request status for button
   * @param setPurchaseOfferingDetails set offering ids and details for purchase
   */
  const onClickPurchase = async (
    selectedRecommendations: any[],
    setPurchaseRequestStatus: (val: string) => void,
    setPurchaseOfferingDetails: (val: PurchaseReservedInstanceType[]) => void
  ) => {
    setPurchaseRequestStatus(REQUEST_STATUS.PROCESSING);
    const purchaseRiDetails: PurchaseReservedInstanceType[] = [];

    for (const item of selectedRecommendations) {
      const requestBody = {
        engine: item.os,
        nodeType: item.instanceType,
        term: convertYearToSeconds(
          TERMS_LIST.find(
            (option) => option.recommendationValue === filterValues.term
          )?.value ?? Terms.ONE_YEAR
        ),
        offeringType: filterValues.paymentOption,
        connectorId: selectedConnection?.connectorId,
        region: getAwsRegionIdFromName(item.location) ?? '',
        quantity: item.recommendedInstanceQuantityPurchase,
      };

      try {
        const res: any = await getAwsElastiCacheRiOfferingDetails(requestBody);
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          if (data.offeringId) {
            purchaseRiDetails.push({
              offeringId: data.offeringId,
              region: getAwsRegionIdFromName(item.location) ?? '',
              quantity: item.recommendedInstanceQuantityPurchase,
            });
            continue;
          }
          setPurchaseRequestStatus(REQUEST_STATUS.ERROR);
          message.error(
            t('purchasePage.reservedInstancesLabels.offeringIdNullError')
          );
          continue;
        }
        setPurchaseRequestStatus(REQUEST_STATUS.ERROR);
        message.error(
          t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      } catch (e: any) {
        onApiCallError(
          e,
          true,
          setPurchaseRequestStatus,
          e?.response?.data?.message ??
            t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      }
    }

    setPurchaseOfferingDetails(purchaseRiDetails);
    setPurchaseRequestStatus(REQUEST_STATUS.SUCCESS);
  };

  return (
    <RecommendationsWrapper
      controlFilters={controlFilters}
      filterValues={filterValues}
      purchaseRecommendationCtaTitle={t('purchasePage.purchaseRecommendedRi')}
      columns={getRecommendedRIsColumns(
        AWS_SERVICES_REQUEST_FIELDS.ELASTI_CACHE
      )}
      fetchRecommendationData={fetchRecommendationData}
      heading={`${t('purchasePage.reservedInstancesLabels.elastiCache')} ${t(
        'purchasePage.reservedInstances'
      )}`}
      onClickPurchase={onClickPurchase}
      purchaseOfferings={purchaseElastiCacheRIs}
      snowTicketIdentifier={RI_SNOW_IDENTIFIER}
    />
  );
};

export default ElastiCacheRecommendation;
