import { Menu, Skeleton, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DrawerComponent from 'components/DrawerComponent';
import Input from 'components/Input';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import Empty from 'components/Empty';
import DeleteModal from 'components/DeleteModal';
import { INPUT_SIZE } from 'constants/appearance';
import { CustomDashboardActions } from 'constants/quickAction';
import {
  MY_DASHBOARD_TYPES,
  MY_DASHBOARD_TYPES_DATA,
} from 'constants/dashboard';
import { defaultCustomViewData } from 'constants/defaultValues';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DASHBOARD_STATUS } from 'pages/DashboardsPage/constants';
import {
  deleteDashboard,
  setDashboardStatus,
} from 'pages/DashboardsPage/services';
import { setCustomViewData } from 'redux/customDashboardSlice';
import {
  selectDashboard,
  setDashboardList,
  setTableViewEnabled,
} from 'redux/dashboardSlice';
import { DashboardListType } from 'types/navigationMenu';
import { fetchDashboardList, setDefaultDashboard } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

import DashboardListItem from './components/DashboardListItem';
import './index.scss';

type DashboardListDrawerProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  onSelectDashboard: (dashboard: string) => void;
  allowedDashboardTypes?: string[];
};

const DashboardListDrawer = ({
  show,
  setShow,
  onSelectDashboard,
  allowedDashboardTypes = [
    MY_DASHBOARD_TYPES.SINGLE_CONNECTION,
    MY_DASHBOARD_TYPES.GROUP,
    MY_DASHBOARD_TYPES.IMPORTS,
    MY_DASHBOARD_TYPES.SNOWFLAKE,
  ],
}: DashboardListDrawerProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dashboardList, selectedMyDashboardType, selectedDashboard } =
    useSelector(selectDashboard);

  const [selectedDashboardListType, setSelectedDashboardListType] = useState(
    selectedMyDashboardType
  );
  const [currentDashboard, setCurrentDashboard] = useState<
    DashboardListType | undefined
  >(undefined);
  const [fetchDashboardDataRequestStatus, setFetchDashboardDataRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDashboardRequestStatus, setDeleteDashboardRequestStatus] =
    useState('');

  const [searchKey, setSearchKey] = useState('');

  const handleQuickAction = (action: string) => {
    switch (action) {
      case CustomDashboardActions.PUBLISH_DRAFT:
      case CustomDashboardActions.ACTIVATE:
        currentDashboard &&
          changeStatus(currentDashboard, DASHBOARD_STATUS.PUBLISH);
        break;

      case CustomDashboardActions.SET_AS_DEFAULT:
        currentDashboard && setAsDefault(currentDashboard);
        break;

      case CustomDashboardActions.DEACTIVATE:
        currentDashboard &&
          changeStatus(currentDashboard, DASHBOARD_STATUS.DE_ACTIVATED);
        break;

      case CustomDashboardActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  /**
   * @function fetchAndSetDashboardData
   * @description Function to fetch dashboards data and with search and filters
   */
  const fetchAndSetDashboardData = () => {
    setFetchDashboardDataRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchDashboardList()
      .then((res: any) => {
        dispatch(setDashboardList(res?.data?.responseData?.content));
        setFetchDashboardDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchDashboardDataRequestStatus);
        dispatch(setDashboardList([]));
      });
  };

  /**
   * @function setAsDefault
   * @description Function to make a dashboard default
   * @param dashboard Dashboard which is to be made default.
   */
  const setAsDefault = (dashboard: DashboardListType) => {
    setFetchDashboardDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      dashboardId: dashboard.id,
    };
    setDefaultDashboard(params)
      .then(() => {
        message.success(
          `${t('dashboardLabels.defaultDashboardSuccess')}${dashboard.name}.`
        );
        fetchAndSetDashboardData();
      })
      .catch((e) =>
        onApiCallError(
          e,
          true,
          setFetchDashboardDataRequestStatus,
          t('dashboardLabels.defaultDashboardError')
        )
      );
  };

  /**
   * @function changeStatus
   * @description Function to change a dashboard's status ie -> PUBLISH, DE_ACTIVATED
   * @param dashboard Dashboard which is to be made default.
   * @param status Status for the dashboard.
   */
  const changeStatus = (dashboard: DashboardListType, status: string) => {
    setFetchDashboardDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      status: status,
    };
    setDashboardStatus(dashboard.id, params)
      .then(() => {
        message.success(
          `${dashboard.name}${t(
            'dashboardLabels.changeDashboardStatusSuccess'
          )}${status}.`
        );
        fetchAndSetDashboardData();
      })
      .catch((e) =>
        onApiCallError(
          e,
          true,
          setFetchDashboardDataRequestStatus,
          `${t('dashboardLabels.changeDashboardStatusError')}${dashboard.name}`
        )
      );
  };

  /**
   * @function deleteSelectedDashboard
   * @description Function to delete a dashboard
   */
  const deleteSelectedDashboard = () => {
    setDeleteDashboardRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      id: currentDashboard?.id,
    };
    deleteDashboard(params)
      .then(() => {
        message.success(
          `${currentDashboard?.name}${t(
            'dashboardLabels.deleteDashboardSuccess'
          )}.`
        );
        fetchAndSetDashboardData();
        setDeleteDashboardRequestStatus(REQUEST_STATUS.SUCCESS);
        setShowDeleteModal(false);
        navigate(NAVIGATION_MENU_PATH.DEFAULT);
        dispatch(setCustomViewData(defaultCustomViewData));
      })
      .catch((e) =>
        onApiCallError(
          e,
          true,
          setDeleteDashboardRequestStatus,
          t('dashboardLabels.deleteDashboardError')
        )
      );
  };

  /**
   * @function getDashboardListByType
   * @description Function to filter dashboard list by type and search key
   * @returns Filtered dashboard list by type and search key
   */
  const getDashboardListByType = () =>
    dashboardList
      .filter(
        (dashboard) => dashboard.dashBoardType === selectedDashboardListType
      )
      .filter((dashboard) =>
        dashboard.name.toLowerCase().includes(searchKey.toLowerCase())
      );

  return (
    <DrawerComponent
      className="dashboard-list-modal"
      open={show}
      title={
        <header className="flex flex-align-items-center flex-space-between">
          <span>{t('dashboardLabels.allDashboards')}</span>
          <Input
            placeholder={t('dashboardLabels.searchByName')}
            type="search"
            className="search-input"
            value={searchKey}
            size={INPUT_SIZE.SMALL}
            onChange={(e: any) => setSearchKey(e.target.value)}
          />
        </header>
      }
      onClose={() => setShow(false)}
    >
      {fetchDashboardDataRequestStatus === REQUEST_STATUS.PROCESSING ? (
        <>
          <Skeleton avatar active />
          <Skeleton avatar active />
          <Skeleton avatar active />
        </>
      ) : (
        <>
          <HorizontalNavigationMenu
            menuItems={MY_DASHBOARD_TYPES_DATA.filter((dashboardType) =>
              allowedDashboardTypes.includes(dashboardType.type)
            ).map((navMenu) => (
              <Menu.Item
                key={navMenu.type}
                eventKey={navMenu.type}
                className={`font-caption-bold flex-fit ${
                  navMenu.type === selectedDashboardListType && 'active-menu'
                }`}
                onClick={() => setSelectedDashboardListType(navMenu.type)}
              >
                {t(`dashboardLabels.${navMenu.label}`)}
              </Menu.Item>
            ))}
            selectedKeys={[]}
          />
          <div className="dashboard-list flex flex-column">
            {getDashboardListByType().length === 0 ? (
              <Empty />
            ) : (
              getDashboardListByType().map((dashboard) => (
                <DashboardListItem
                  key={dashboard.id}
                  dashboard={dashboard}
                  selectedDashboard={selectedDashboard}
                  setCurrentDashboard={setCurrentDashboard}
                  handleQuickAction={handleQuickAction}
                  onClick={() => {
                    if (
                      selectedDashboard?.id === dashboard.id ||
                      dashboard.dashboardStatus ===
                        DASHBOARD_STATUS.DE_ACTIVATED
                    )
                      return;
                    dispatch(setCustomViewData(defaultCustomViewData));
                    onSelectDashboard(dashboard.id);
                    dispatch(setTableViewEnabled(false));
                    setShow(false);
                  }}
                />
              ))
            )}
          </div>
        </>
      )}
      {currentDashboard !== undefined && showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={deleteSelectedDashboard}
          loading={deleteDashboardRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </DrawerComponent>
  );
};

export default DashboardListDrawer;
