import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ColorPalleteType,
  ManualChartColorsType,
} from 'pages/AppearancePage/types';
import {
  DEFAULT_THEME,
  STATIC_COLORS_FOR_CHART,
} from 'pages/AppearancePage/constants';
import { RootState } from './store';

export interface ThemeState {
  theme: ColorPalleteType;
  autoGenerateChartColors: boolean;
  manualChartColors: ManualChartColorsType[];
}

const initialState: ThemeState = {
  theme: DEFAULT_THEME,
  autoGenerateChartColors: true,
  manualChartColors: STATIC_COLORS_FOR_CHART,
};

export const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ColorPalleteType>) => {
      state.theme = action.payload;
    },
    setAutoGenerateChartColors: (state, action: PayloadAction<boolean>) => {
      state.autoGenerateChartColors = action.payload;
    },
    setManualChartColors: (
      state,
      action: PayloadAction<ManualChartColorsType[]>
    ) => {
      state.manualChartColors = action.payload;
    },
  },
});

export const selectTheme = (state: RootState) => state.theme;
export const { setTheme, setAutoGenerateChartColors, setManualChartColors } =
  themeSlice.actions;
export default themeSlice.reducer;
