import { CoverageUtilizationExcelMetaData } from 'pages/ScorecardPage/types';
import { ConnectionListType } from 'types/dashboard';
import { getUtilizationCoverageExcelData } from '../CoverageUtilizationGraph/utils';

/**
 * @function getAwsRiExcelData
 * @description Function to return the excel data for export
 * @param data Object with Utilization and Coverage data containing the sheetName, data and average
 * @param connection connection details of the selected connection
 * @returns List of excel export data
 */
export const getAwsRiExcelData = (
  data: {
    [key: string]: {
      utilization: CoverageUtilizationExcelMetaData;
      coverage: CoverageUtilizationExcelMetaData;
    };
  },
  connection: ConnectionListType | null
) => {
  return [
    ...getUtilizationCoverageExcelData(
      data.compute.utilization,
      data.compute.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      data.elastiCache.utilization,
      data.elastiCache.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      data.memoryDb.utilization,
      data.memoryDb.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      data.elasticSearch.utilization,
      data.elasticSearch.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      data.openSearch.utilization,
      data.openSearch.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      data.redshift.utilization,
      data.redshift.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      data.rds.utilization,
      data.rds.coverage,
      connection
    ),
  ];
};
