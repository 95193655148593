import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import NavigationPath from 'components/NavigationPath';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  selectPurchase,
  setSelectedPurchasePageView,
} from 'redux/purchaseSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import DashboardComponent from 'components/DashboardComponent';
import { PROVIDER } from 'constants/cloudProviders';

import PurchaseHeader from './components/PurchaseHeader';
import {
  PURCHASE_PAGE_VIEWS,
  AwsPurchasePageViewsList,
  GcpPurchasePageViewsList,
  AzurePurchasePageViewsList,
} from './constants';
import AwsSavingsPlans from './components/AwsSavingsPlans';
import ReservedInstances from './components/ReservedInstances';
import CommittedUseDiscounts from './components/CommittedUseDiscounts';
import AzureSavingsPlans from './components/AzureSavingsPlans';

import './index.scss';

const PurchasePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  const { selectedPurchasePageView, selectedConnection } =
    useSelector(selectPurchase);

  const [isEmptyConnectionList, setIsEmptyConnectionList] = useState(false);
  const [connectionsRequestStatus, setConnectionsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    if (selectedConnection) {
      dispatch(
        setSelectedPurchasePageView(getPurchaseViewsList()?.at(0)?.id ?? '')
      );
    }
  }, [selectedConnection]);

  if (!permissions.cloudConnectorList) {
    return <div>{t('noConnectionsPermission')}</div>;
  }

  if (
    connectionsRequestStatus !== REQUEST_STATUS.PROCESSING &&
    isEmptyConnectionList
  ) {
    return <div>{t('noConnectionsFound')}</div>;
  }

  const getPurchaseViewsList = () => {
    switch (selectedConnection?.provider) {
      case PROVIDER.AWS:
        return AwsPurchasePageViewsList;
      case PROVIDER.GCP:
        return GcpPurchasePageViewsList;
      case PROVIDER.AZURE:
        return AzurePurchasePageViewsList;
      default:
        return [];
    }
  };

  const getViewComponent = () => {
    switch (selectedPurchasePageView) {
      case PURCHASE_PAGE_VIEWS.RESERVED_INSTANCE:
        return <ReservedInstances />;
      case PURCHASE_PAGE_VIEWS.SAVINGS_PLAN:
        return selectedConnection?.provider === PROVIDER.AZURE ? (
          <AzureSavingsPlans />
        ) : (
          <AwsSavingsPlans />
        );
      case PURCHASE_PAGE_VIEWS.COMMITTED_USE_DISCOUNTS:
        return <CommittedUseDiscounts />;
    }
  };

  return (
    <div className="commitments flex flex-column full-height">
      <div className="new-page-header">
        <PurchaseHeader
          setIsEmptyConnections={setIsEmptyConnectionList}
          setRequestStatus={setConnectionsRequestStatus}
        />
      </div>
      <div className="page-content">
        <NavigationPath />
        <HorizontalNavigationMenu
          className="horizontal-nav-menu-tabs"
          menuItems={getPurchaseViewsList().map((navMenu) => (
            <Menu.Item
              className={`font-caption-bold ${
                navMenu.id === selectedPurchasePageView && 'active-menu'
              }`}
              onClick={() => {
                dispatch(setSelectedPurchasePageView(navMenu.id));
              }}
              key={navMenu.id}
              eventKey={navMenu.id}
            >
              {t(navMenu.title)}
            </Menu.Item>
          ))}
          selectedKeys={[selectedPurchasePageView]}
        />
        <div className="margin-24">
          <DashboardComponent
            component={getViewComponent()}
            requestStatus={connectionsRequestStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default PurchasePage;
