import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import ColumnChart from 'components/ColumnChart';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { CostByNameType } from 'types/dataTypes';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import { getSelectedData, getTopWarehousesByCostExcelData } from '../utils';

type TopWarehousesByCostProps = {
  allWarehousesCostData: CostByNameType[];
  selectedWarehouses: string[];
  setSelectedWarehouses: (value: string[]) => void;
  topWarehousesByCostRequestStatus: string;
  isTopWarehousesByCostTableView: boolean;
  setIsTopWarehousesByCostTableView: (value: boolean) => void;
  topWarehousesByCostDateRange: string[];
  onChangeTopWarehousesByCostDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  pdfView?: boolean;
};

const TopWarehousesByCost = ({
  allWarehousesCostData,
  selectedWarehouses,
  setSelectedWarehouses,
  topWarehousesByCostRequestStatus,
  isTopWarehousesByCostTableView,
  setIsTopWarehousesByCostTableView,
  topWarehousesByCostDateRange,
  onChangeTopWarehousesByCostDateRange,
  pdfView,
}: TopWarehousesByCostProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('snowflakeDashboards.warehouses'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('snowflakeDashboards.costInCurrency', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'cost',
      key: 'cost',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  const filters = (
    <div className="filters">
      <DatePicker
        defaultValue={[
          moment(topWarehousesByCostDateRange[0]),
          moment(topWarehousesByCostDateRange[1]),
        ]}
        onChange={onChangeTopWarehousesByCostDateRange}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        disabledDate={(current: any) => current > moment().endOf('day')}
      />
      <GraphFilterDropdown
        allData={allWarehousesCostData}
        selectedData={getSelectedData(
          allWarehousesCostData,
          selectedWarehouses
        )}
        setSelectedData={(selectedData: any) =>
          setSelectedWarehouses(selectedData.map((item: any) => item.name))
        }
        valueSuffix={t('snowflakeDashboards.warehouses')}
        fieldName={DROPDOWN_VALUE_FIELDS.NAME}
        loading={topWarehousesByCostRequestStatus === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );

  const getComponent = () => (
    <div
      className={`graph ${
        isTopWarehousesByCostTableView && 'flex flex-gap-16'
      }`}
    >
      {isTopWarehousesByCostTableView ? (
        <div className="tabular-view">
          <Table
            pagination={false}
            loading={
              topWarehousesByCostRequestStatus === REQUEST_STATUS.PROCESSING
            }
            dataSource={getSelectedData(
              allWarehousesCostData,
              selectedWarehouses
            )?.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            scroll={{ y: 190 }}
            designVersion2
            fillContainer
          />
        </div>
      ) : (
        <ColumnChart
          data={getSelectedData(allWarehousesCostData, selectedWarehouses)}
          xField="name"
          yField="cost"
          groupingField="name"
          xTitle={t('snowflakeDashboards.warehouses')}
          yTitle={t('snowflakeDashboards.costInCurrency', {
            currencySymbol: currencySymbol,
          })}
          showAllLegend={pdfView}
          prefixSymbol={currencySymbol}
          disableAnimation={pdfView}
        />
      )}
    </div>
  );

  return (
    <div
      className="graph-card top-warehouses-by-compute-cost flex flex-column flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.topWarehousesByComputeCost')}
        filters={filters}
        graphName="top-warehouses-by-cost"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isTopWarehousesByCostTableView}
        setIsTableView={setIsTopWarehousesByCostTableView}
        excelData={getTopWarehousesByCostExcelData(
          getSelectedData(allWarehousesCostData, selectedWarehouses),
          topWarehousesByCostDateRange,
          selectedWarehouses
        )}
        className="extended-filter"
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={topWarehousesByCostRequestStatus}
      />
    </div>
  );
};

export default TopWarehousesByCost;
