import i18n from 'i18n';

import { TagMappingType } from './types';

export enum TagMappingQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const TAG_MAPPING_QUICK_ACTIONS_LIST = [
  {
    id: TagMappingQuickActions.EDIT,
    title: i18n.t('tagMapping.edit'),
  },
  {
    id: TagMappingQuickActions.DELETE,
    title: i18n.t('tagMapping.delete'),
  },
];

export const defaultTagMapData: TagMappingType = {
  customTagName: '',
  cloudMapList: [],
};

export const DROPDOWN_NONE = 'NONE';

export const DROPDOWN_NONE_OPTION = {
  label: i18n.t('tagMapping.none'),
  value: DROPDOWN_NONE,
};
