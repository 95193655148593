import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SelectDropdown from 'components/Select';
import { FormLabel } from 'components/FormLabel';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { ConnectionListType } from 'types/dashboard';
import {
  verifyAwsBudgetAccess,
  verifyAzureBudgetAccess,
  verifyGcpBudgetAccess,
} from 'pages/BudgetsAndAlertsPage/services';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import { providerList } from 'redux/providerSlice';
import { fetchAllBillingAccounts, fetchConnectionData } from 'utils/services';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';

import { BillingAccountOptionTypes } from '../types';

type BudgetsAndAlertsConnectionFormGCPProps = {
  isBudgetConnectionError: boolean;
  setIsBudgetConnectionError: (value: boolean) => void;
};

const ConnectionForm = ({
  isBudgetConnectionError,
  setIsBudgetConnectionError,
}: BudgetsAndAlertsConnectionFormGCPProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { budgetData } = useSelector(budgetsAndAlerts);
  const { selectedProvider } = useSelector(providerList);

  // Request Status states
  const [connectionOptionsRequestStatus, setConnectionOptionsRequestStatus] =
    useState('');
  const [
    billingAccountOptionsRequestStatus,
    setBillingAccountOptionsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Dropdown options states
  const [connectionOptions, setConnectionOptions] = useState<
    ConnectionListType[]
  >([]);
  const [billingAccountOptions, setBillingAccountOptions] = useState<
    BillingAccountOptionTypes[]
  >([]);

  // Validation states
  const [selectedConnectionReqValidation, setSelectedConnectionReqValidation] =
    useState('');
  const [billingAccountReqValidation, setBillingAccountReqValidation] =
    useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [validateBudgetAccessReqStatus, setValidateBudgetAccessReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    setConnectionOptionsRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      provider: selectedProvider,
    };

    fetchConnectionData(params)
      .then((res: any) => {
        setConnectionOptions(res?.data?.responseData?.content);
        setConnectionOptionsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setConnectionOptionsRequestStatus);
      });
  }, []);

  useEffect(() => {
    if (budgetData.connectorId && selectedProvider === PROVIDER.GCP) {
      setBillingAccountOptions([]);
      dispatch(
        setBudgetData({
          ...budgetData,
          billingAccount: '',
        })
      );
      fetchBillingAccountOptions();
      setSelectedConnectionReqValidation('');
    }
  }, [budgetData.connectorId]);

  useEffect(() => {
    if (
      budgetData.connectorId &&
      ((selectedProvider === PROVIDER.GCP && budgetData.billingAccount) ||
        selectedProvider !== PROVIDER.GCP)
    ) {
      validateBudgetAccess();
    }
  }, [budgetData.connectorId, budgetData.billingAccount]);

  /**
   * @function fetchBillingAccountOptions
   * @description Function to fetch billing account options data and set the billing account dropdown
   */
  const fetchBillingAccountOptions = async () => {
    setBillingAccountOptionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: budgetData.connectorId,
    };
    try {
      const resData: any = await fetchAllBillingAccounts(params);
      if (resData?.status === 200) {
        const { data } = resData;
        const { responseData } = data;
        const selectOptions: BillingAccountOptionTypes[] = [];
        for (const billingData in responseData) {
          selectOptions.push({
            value: billingData,
            title: responseData[billingData],
          });
        }
        setBillingAccountOptions(selectOptions);
        setBillingAccountOptionsRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
    } catch (e) {
      onApiCallError(e, true, setBillingAccountOptionsRequestStatus);
    }
  };

  /**
   * @function validateBudgetAccess
   * @description Function to validate budget access for a given connection
   */
  const validateBudgetAccess = async () => {
    setValidateBudgetAccessReqStatus(REQUEST_STATUS.PROCESSING);
    const params = { connectorId: budgetData.connectorId };
    let budgetAccessData: any;
    switch (selectedProvider) {
      case PROVIDER.GCP:
        budgetAccessData = await verifyGcpBudgetAccess(params);
        break;
      case PROVIDER.AWS:
        budgetAccessData = await verifyAwsBudgetAccess(params);
        break;
      case PROVIDER.AZURE:
        budgetAccessData = await verifyAzureBudgetAccess(params);
        break;
      case PROVIDER.OCI:
        setValidateBudgetAccessReqStatus(REQUEST_STATUS.SUCCESS);
        setIsBudgetConnectionError(false);
        return;
    }
    if (
      budgetAccessData?.status === 200 &&
      budgetAccessData?.data?.responseData
    ) {
      setValidateBudgetAccessReqStatus(REQUEST_STATUS.SUCCESS);
      setIsBudgetConnectionError(false);
    } else {
      setValidateBudgetAccessReqStatus(REQUEST_STATUS.ERROR);
      setErrorMessage(budgetAccessData?.data?.message);
      setIsBudgetConnectionError(true);
    }
  };

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.selectAConnection')}
          required={true}
        />
        <SelectDropdown
          value={budgetData.connectorId}
          options={connectionOptions.map((options) => ({
            value: options.connectorId,
            label: options.displayName,
          }))}
          loading={
            evaluateRequestArray([
              connectionOptionsRequestStatus,
              validateBudgetAccessReqStatus,
            ]) === REQUEST_STATUS.PROCESSING
          }
          onSelect={(value: any) => {
            setErrorMessage('');
            dispatch(
              setBudgetData({
                ...budgetData,
                connectorId: value,
                billingAccount: '',
              })
            );
            setSelectedConnectionReqValidation('');
          }}
          designVersion2
        />
        <span
          style={{
            display: `${selectedConnectionReqValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {selectedConnectionReqValidation}
        </span>
      </div>
      {selectedProvider === PROVIDER.GCP && (
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('addBudgetAlert.billingAccount')}
            required={true}
          />
          <SelectDropdown
            value={budgetData.billingAccount}
            options={billingAccountOptions.map((options) => ({
              value: options.value,
              label: options.title,
            }))}
            loading={
              evaluateRequestArray([
                billingAccountOptionsRequestStatus,
                validateBudgetAccessReqStatus,
              ]) === REQUEST_STATUS.PROCESSING
            }
            onSelect={(value: any) => {
              dispatch(
                setBudgetData({
                  ...budgetData,
                  billingAccount: value,
                })
              );
              setBillingAccountReqValidation('');
            }}
            designVersion2
          />
          <span
            style={{
              display: `${billingAccountReqValidation ? 'inline' : 'none'}`,
            }}
            className="font-validation-error"
          >
            {billingAccountReqValidation}
          </span>
        </div>
      )}
      <div className="flex flex-column">
        {isBudgetConnectionError && errorMessage && (
          <div className="error-message flex flex-justify-content-center font-caption">
            {errorMessage ?? t('addBudgetAlert.errorMessage')}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectionForm;
