import { combineReducers } from '@reduxjs/toolkit';

import commonUtilityReducer from './commonUtilitySlice';
import activeMenuReducer from './activeNavMenuSlice';
import userAuthorizationReducer from './authorizationSlice';
import dashboardReducer from './dashboardSlice';
import budgetsAndAlertsReducer from './budgetsAndAlertsSlice';
import cloudConnectionReducer from './cloudConnectionSlice';
import providerReducer from './providerSlice';
import costAllocationReducer from './costAllocationSlice';
import customDashboardReducer from './customDashboardSlice';
import successAndErrorPageReducer from './successAndErrorPageSlice';
import importStaticFilesReducer from './importStaticFileSlice';
import costAllocationDashboardReducer from './costAllocationDashboardSlice';
import ticketIntegrationReducer from './ticketIntegrationSlice';
import costOptimizationInsightsReducer from './costOptimizationInsightsSlice';
import tagsAndLabelReducer from './tagsAndLabelsSlice';
import dataCenterReducer from './dataCenterSlice';
import tcoDashboardReducer from './tcoDashboardSlice';
import containerInsightsReducer from './containerInsightsSlice';
import ruleEngineReducer from './ruleEngineSlice';
import themeReducer from './themeSlice';
import costMonitorReducer from './costMonitorSlice';
import anomalyDetectionDashboardReducer from './anomalyDetectionDashboardSlice';
import scorecardReducer from './scorecardSlice';
import gProfilerReducer from './gProfilerSlice';
import purchaseReducer from './purchaseSlice';
import gProfilerConnectionReducer from './gProfilerConnectionSlice';
import gProfilerInsightsReducer from './gProfilerInsights';
import reportReducer from './reportSlice';
import chatBotReducer from './chatBotSlice';
import granulateReducer from './granulateSlice';
import dimensionMappingReducer from './dimensionMappingSlice';
import granulateConnectionReducer from './granulateConnectionSlice';
import granulateInsightsReducer from './granulateInsightsSlice';
import releaseNotesReducer from './releaseNotesSlice';
import tagComplianceReducer from './tagComplianceSlice';
import cmdbSetupReducer from './cmdbSetupSlice';

export default combineReducers({
  commonUtility: commonUtilityReducer,
  activeMenu: activeMenuReducer,
  userAuthorization: userAuthorizationReducer,
  dashboard: dashboardReducer,
  budgetsAndAlerts: budgetsAndAlertsReducer,
  tagsAndLabels: tagsAndLabelReducer,
  cloudConnection: cloudConnectionReducer,
  provider: providerReducer,
  costAllocation: costAllocationReducer,
  customDashboard: customDashboardReducer,
  successAndErrorPage: successAndErrorPageReducer,
  importStaticFiles: importStaticFilesReducer,
  costAllocationDashboard: costAllocationDashboardReducer,
  ticketIntegration: ticketIntegrationReducer,
  costOptimizationInsights: costOptimizationInsightsReducer,
  dataCenter: dataCenterReducer,
  tcoDashboard: tcoDashboardReducer,
  containerInsights: containerInsightsReducer,
  ruleEngine: ruleEngineReducer,
  theme: themeReducer,
  costMonitor: costMonitorReducer,
  anomalyDetectionDashboard: anomalyDetectionDashboardReducer,
  scorecardReducer: scorecardReducer,
  gProfiler: gProfilerReducer,
  purchase: purchaseReducer,
  gProfilerConnection: gProfilerConnectionReducer,
  gProfilerInsights: gProfilerInsightsReducer,
  report: reportReducer,
  chatBot: chatBotReducer,
  granulate: granulateReducer,
  granulateConnection: granulateConnectionReducer,
  granulateInsights: granulateInsightsReducer,
  dimensionMapping: dimensionMappingReducer,
  releaseNotes: releaseNotesReducer,
  tagCompliance: tagComplianceReducer,
  cmdbSetup: cmdbSetupReducer,
});
