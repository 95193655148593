import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';

import { selectScorecard } from 'redux/scorecardSlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import ExpandModal from 'components/ExpandModal';
import { CategoryMonthlyCost, ItemValueType } from 'pages/ScorecardPage/types';
import {
  AWS_RI_SERVICES,
  AWS_SP_GRAPHS_TO_SERVICES_MAP,
  SAVING_PLAN_REPORT_TYPES,
  SCORECARDS_TIMERANGE,
} from 'pages/ScorecardPage/constants';
import { RecommendationStatus } from 'pages/CostOptimizationInsightsPage/constants';
import { ComparisonListType } from 'types/dashboard';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  AWS_CHECK_IDS,
  RECOMMENDATION_CATEGORIES,
} from 'constants/recommendations';
import {
  getAWSCategoryMapping,
  getChartData,
  getFilteredRecommendations,
} from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_HYPHEN_MONTH,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import {
  fetchAwsUtilizationDetails,
  fetchWMAssignedWeightage,
  getAWSRICoverageDetails,
  getAWSRIUtilizationDetails,
  getAWSSPCoverageDetails,
  getAWSSPUtilizationDetails,
} from 'pages/ScorecardPage/services';
import { getAwsRiServiceLabels } from 'pages/ScorecardPage/utils';
import { AwsCategoryMappingType, KeyValueTypes } from 'types/dataTypes';
import {
  getCostOfWasteRequestBody,
  getTotalCostRequestBody,
  getWasteManagementCategoriesByProvider,
  getWasteManagementCategoriesLabelsByProvider,
} from 'pages/ScorecardPage/components/WasteManagementScorecardDashboard/utils';
import { CHART_TYPES } from 'constants/graphConfig';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';

import { SUMMARY_GRAPHS } from '../../constants';
import SavingsTrack from '../SavingsTrack';
import SavingsEffectivenessSummary from '../SavingsEffectivenessSummary';
import CoverageUtilizationSummaryGraph from '../CoverageUtilizationSummaryGraph';
import {
  geCostSavingsQuery,
  getAWSScorecardSummaryExcelExportData,
  getMonthWiseDates,
} from './utils';
import { getUtilizationCoverageSummaryExportColumns } from '../CoverageUtilizationSummaryGraph/utils';

const AWSSummaryDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showExpandGraphModal, expandedGraphName, tableViewEnabled } =
    useSelector(selectDashboard);
  const { selectedConnection } = useSelector(selectScorecard);

  const [categoryRecommendersMap, setCategoryRecommendersMap] = useState<
    AwsCategoryMappingType[]
  >([]);
  const [
    categoryRecommendersMapRequestStatus,
    setCategoryRecommendersMapRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [savingsTrackCards, setSavingsTrackCards] = useState<
    ComparisonListType[]
  >([]);

  // YTD Savings
  const [ytdPurchaseTacticsSavings, setYtdPurchaseTacticsSavings] = useState(0);
  const [ytdNetSavings, setYtdNetSavings] = useState(0);
  const [ytdConsumptionSavings, setYtdConsumptionSavings] = useState(0);
  const [ytdWasteSavings, setYtdWasteSavings] = useState(0);
  const [
    ytdPurchaseTacticsSavingsRequestStatus,
    setYtdPurchaseTacticsSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [ytdNetSavingsRequestStatus, setYtdNetSavingsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [
    ytdConsumptionSavingsRequestStatus,
    setYtdConsumptionSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [ytdWasteSavingsRequestStatus, setYtdWasteSavingsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  // Current Month Savings
  const [purchaseTacticsSavings, setPurchaseTacticsSavings] = useState(0);
  const [netSavings, setNetSavings] = useState(0);
  const [consumptionSavings, setConsumptionSavings] = useState(0);
  const [wasteSavings, setWasteSavings] = useState(0);
  const [
    purchaseTacticsSavingsRequestStatus,
    setPurchaseTacticsSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [consumptionSavingsRequestStatus, setConsumptionSavingsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [wasteSavingsRequestStatus, setWasteSavingsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  // FY Forecast Savings
  const [purchaseTacticsForecastSavings, setPurchaseTacticsForecastSavings] =
    useState(0);
  const [consumptionForecastSavings, setConsumptionForecastSavings] =
    useState(0);
  const [wasteForecastSavings, setWasteForecastSavings] = useState(0);
  const [
    purchaseTacticsForecastSavingsRequestStatus,
    setPurchaseTacticsForecastSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    consumptionForecastSavingsRequestStatus,
    setConsumptionForecastSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    wasteForecastSavingsRequestStatus,
    setWasteForecastSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Savings Effectiveness
  const [
    consolidatedSavingsEffectivenessPercentage,
    setConsolidatedSavingsEffectivenessPercentage,
  ] = useState(0);
  const [savingsEffectivenessPercentage, setSavingsEffectivenessPercentage] =
    useState(0);
  const [
    savingsEffectivenessRquestStatus,
    setSavingsEffectivenessRquestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  const [weightagePercentage, setWeightagePercentage] = useState<
    KeyValueTypes[]
  >([]);
  const [
    weightagePercentageRequestStatus,
    setWeightagePercentageRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [costOfWaste, setCostOfWaste] = useState<CategoryMonthlyCost[]>([]);
  const [costOfWasteRequestStatus, setCostOfWasteRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [totalCost, setTotalCost] = useState<CategoryMonthlyCost[]>([]);
  const [totalCostRequestStatus, setTotalCostRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [wasteManagementRate, setWasteManagementRate] = useState(0);
  const [onDemandSpend, setOnDemandSpend] = useState(0);
  const [wmTotalCostSum, setWmTotalCostSum] = useState(0);

  // Saving Plan
  const [spUtilizationData, setSpUtilizationData] = useState<ItemValueType[]>(
    []
  );
  const [spCoverageData, setSpCoverageData] = useState<ItemValueType[]>([]);
  const [spUtilizationRequestStatus, setSpUtilizationRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [spCoverageRequestStatus, setSpCoverageRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [spSelectedReportType, setSpSelectedReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [spTableView, setSpTableView] = useState(false);

  // Reserved Instance
  const [riUtilizationData, setRiUtilizationData] = useState<ItemValueType[]>(
    []
  );
  const [riCoverageData, setRiCoverageData] = useState<ItemValueType[]>([]);
  const [riUtilizationRequestStatus, setRiUtilizationRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [riCoverageRequestStatus, setRiCoverageRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [riSelectedReportType, setRiSelectedReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [riTableView, setRiTableView] = useState(false);

  useEffect(() => {
    fetchAwsCategoryMapping();
  }, []);

  useEffect(() => {
    setYtdPurchaseTacticsSavings(0);
    setYtdNetSavings(0);
    setYtdConsumptionSavings(0);
    setYtdWasteSavings(0);
    setPurchaseTacticsSavings(0);
    setNetSavings(0);
    setConsumptionSavings(0);
    setWasteSavings(0);
    setPurchaseTacticsForecastSavings(0);
    setConsumptionForecastSavings(0);
    setWasteForecastSavings(0);
    setConsolidatedSavingsEffectivenessPercentage(0);
    setSavingsEffectivenessPercentage(0);
    setWeightagePercentage([]);
    setCostOfWaste([]);
    setTotalCost([]);
    setWasteManagementRate(0);
    setOnDemandSpend(0);
    setWmTotalCostSum(0);
    setSpUtilizationData([]);
    setSpCoverageData([]);
    setSpSelectedReportType(SAVING_PLAN_REPORT_TYPES.UTILIZATION);
    setSpTableView(false);
    setRiUtilizationData([]);
    setRiCoverageData([]);
    setRiSelectedReportType(SAVING_PLAN_REPORT_TYPES.UTILIZATION);
    setRiTableView(false);

    if (!selectedConnection || categoryRecommendersMap.length === 0) {
      return;
    }

    if (selectedConnection.wantBilling) {
      // Savings Effectiveness
      getAWSSavingsEffectivenessDetails();
      fetchWeightagePercentage();
      fetchTotalCost();
    }

    if (selectedConnection.wantRecommendations) {
      // YTD Savings
      getSavingsCost(
        categoryRecommendersMap.find(
          (item) => item.category === RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS
        )?.recommenders,
        [RecommendationStatus.SUCCEEDED],
        {
          startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setYtdPurchaseTacticsSavings,
        setYtdPurchaseTacticsSavingsRequestStatus
      );

      getAWSYtdSavingsEffectivenessDetails();

      getSavingsCost(
        categoryRecommendersMap.find(
          (item) =>
            item.category === RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT
        )?.recommenders,
        [RecommendationStatus.SUCCEEDED],
        {
          startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setYtdConsumptionSavings,
        setYtdConsumptionSavingsRequestStatus
      );

      getSavingsCost(
        categoryRecommendersMap.find(
          (item) => item.category === RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
        )?.recommenders,
        [RecommendationStatus.SUCCEEDED],
        {
          startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setYtdWasteSavings,
        setYtdWasteSavingsRequestStatus
      );

      setTimeout(() => {
        // Current Month Svaings
        getSavingsCost(
          categoryRecommendersMap.find(
            (item) =>
              item.category === RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS
          )?.recommenders,
          [RecommendationStatus.SUCCEEDED],
          getMonthWiseDates(),
          setPurchaseTacticsSavings,
          setPurchaseTacticsSavingsRequestStatus
        );

        getSavingsCost(
          categoryRecommendersMap.find(
            (item) =>
              item.category === RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT
          )?.recommenders,
          [RecommendationStatus.SUCCEEDED],
          getMonthWiseDates(),
          setConsumptionSavings,
          setConsumptionSavingsRequestStatus
        );

        getSavingsCost(
          categoryRecommendersMap.find(
            (item) =>
              item.category === RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
          )?.recommenders,
          [RecommendationStatus.SUCCEEDED],
          getMonthWiseDates(),
          setWasteSavings,
          setWasteSavingsRequestStatus
        );

        // FY Savings Forecast
        getSavingsCost(
          categoryRecommendersMap.find(
            (item) =>
              item.category === RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS
          )?.recommenders,
          [RecommendationStatus.ACTIVE, RecommendationStatus.FAILED],
          getMonthWiseDates(),
          setPurchaseTacticsForecastSavings,
          setPurchaseTacticsForecastSavingsRequestStatus
        );

        getSavingsCost(
          categoryRecommendersMap.find(
            (item) =>
              item.category === RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT
          )?.recommenders,
          [RecommendationStatus.ACTIVE, RecommendationStatus.FAILED],
          getMonthWiseDates(),
          setConsumptionForecastSavings,
          setConsumptionForecastSavingsRequestStatus
        );

        getSavingsCost(
          categoryRecommendersMap.find(
            (item) =>
              item.category === RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
          )?.recommenders,
          [RecommendationStatus.ACTIVE, RecommendationStatus.FAILED],
          getMonthWiseDates(),
          setWasteForecastSavings,
          setWasteForecastSavingsRequestStatus
        );
      }, 1000);

      // Savings Effectiveness
      fetchCostOfWaste();

      //Reserved Instance
      fetchRiUtilizationDetails();
      fetchRiCoverageDetails();

      //Saving Plan
      fetchSpUtilizationDetails();
      fetchSpCoverageDetails();
    }
  }, [selectedConnection, categoryRecommendersMap]);

  useEffect(() => {
    setSavingsTrackCards([
      {
        heading: t('scorecard.summary.ytdSavings'),
        value:
          ytdPurchaseTacticsSavings +
          ytdConsumptionSavings +
          ytdWasteSavings +
          ytdNetSavings,
        requestStatus: [
          ytdPurchaseTacticsSavingsRequestStatus,
          ytdConsumptionSavingsRequestStatus,
          ytdWasteSavingsRequestStatus,
          ytdNetSavingsRequestStatus,
          categoryRecommendersMapRequestStatus,
        ],
      },
      {
        heading: t('scorecard.summary.currentMonthSavings'),
        value:
          purchaseTacticsSavings +
          consumptionSavings +
          wasteSavings +
          netSavings,
        requestStatus: [
          purchaseTacticsSavingsRequestStatus,
          consumptionSavingsRequestStatus,
          wasteSavingsRequestStatus,
          savingsEffectivenessRquestStatus,
          categoryRecommendersMapRequestStatus,
        ],
      },
      {
        heading: t('scorecard.summary.fySavingsForecast'),
        value:
          purchaseTacticsForecastSavings +
          consumptionForecastSavings +
          wasteForecastSavings,
        requestStatus: [
          purchaseTacticsForecastSavingsRequestStatus,
          consumptionForecastSavingsRequestStatus,
          wasteForecastSavingsRequestStatus,
          categoryRecommendersMapRequestStatus,
        ],
      },
      {
        heading: t('scorecard.summary.currentMonthPurchaseTacticsSavings'),
        value: purchaseTacticsSavings + netSavings,
        requestStatus: [
          purchaseTacticsSavingsRequestStatus,
          savingsEffectivenessRquestStatus,
          categoryRecommendersMapRequestStatus,
        ],
      },
      {
        heading: t('scorecard.summary.currentMonthConsumptionSavings'),
        value: consumptionSavings,
        requestStatus: [
          consumptionSavingsRequestStatus,
          categoryRecommendersMapRequestStatus,
        ],
      },
      {
        heading: t('scorecard.summary.currentMonthWasteSavings'),
        value: wasteSavings,
        requestStatus: [
          wasteSavingsRequestStatus,
          categoryRecommendersMapRequestStatus,
        ],
      },
    ]);
  }, [
    ytdPurchaseTacticsSavings,
    ytdNetSavings,
    ytdConsumptionSavings,
    ytdWasteSavings,
    purchaseTacticsForecastSavings,
    ytdNetSavingsRequestStatus,
    consumptionForecastSavings,
    wasteForecastSavings,
    purchaseTacticsSavings,
    netSavings,
    consumptionSavings,
    wasteSavings,
    ytdPurchaseTacticsSavingsRequestStatus,
    ytdConsumptionSavingsRequestStatus,
    ytdWasteSavingsRequestStatus,
    purchaseTacticsForecastSavingsRequestStatus,
    consumptionForecastSavingsRequestStatus,
    wasteForecastSavingsRequestStatus,
    purchaseTacticsSavingsRequestStatus,
    consumptionSavingsRequestStatus,
    wasteSavingsRequestStatus,
    savingsEffectivenessRquestStatus,
    categoryRecommendersMapRequestStatus,
  ]);

  useEffect(() => {
    if (costOfWaste.length && totalCost.length && weightagePercentage.length) {
      let wmRate = 0;
      let totalCostSum = 0;
      const categoryLabels = getWasteManagementCategoriesLabelsByProvider(
        selectedConnection?.provider
      );

      // Calculate Waste Management cost ratio for each category
      Object.values(
        getWasteManagementCategoriesByProvider(
          selectedConnection?.provider ?? ''
        )
      ).forEach((category) => {
        const categoryLabel =
          categoryLabels.find((item) => item.value === category)?.label ?? '';

        const totalCostValue =
          totalCost.find((totalCostItem) => totalCostItem.category === category)
            ?.costData[0]?.cost ?? 0;

        const costOfWasteValue =
          costOfWaste.find(
            (costOfWasteItem) => costOfWasteItem.category === category
          )?.costData[0]?.cost ?? 0;

        const ratio = totalCostValue ? costOfWasteValue / totalCostValue : 0;
        totalCostSum += totalCostValue;
        const weightage = weightagePercentage.find(
          (weightageItem) => weightageItem.key === categoryLabel
        );
        wmRate += Math.min(1, ratio) * Number(weightage?.value ?? 0);
      });

      setWasteManagementRate(wmRate);
      setWmTotalCostSum(totalCostSum);
    }
  }, [costOfWaste, totalCost, weightagePercentage]);

  useEffect(() => {
    let savingsEffectivenessWeightage = 0.5;
    let wasteManagementSavingsWeightage = 0.5;

    if (onDemandSpend === 0) {
      wasteManagementSavingsWeightage = 1;
      savingsEffectivenessWeightage = 0;
    }

    if (wmTotalCostSum === 0) {
      savingsEffectivenessWeightage = 1;
      wasteManagementSavingsWeightage = 0;
    }

    setConsolidatedSavingsEffectivenessPercentage(
      savingsEffectivenessPercentage * savingsEffectivenessWeightage +
        wasteManagementRate * wasteManagementSavingsWeightage
    );
  }, [
    onDemandSpend,
    wmTotalCostSum,
    savingsEffectivenessPercentage,
    wasteManagementRate,
  ]);

  useEffect(() => {
    setSpTableView(tableViewEnabled);
    setRiTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    dispatch(
      setExportToExcelData(
        getAWSScorecardSummaryExcelExportData(
          savingsTrackCards,
          {
            utilization: {
              sheetName: `${t('graphHeadings.reservedInstance')} (${t(
                'scorecard.savingsTypes.utilization'
              )})`,
              data: riUtilizationData,
            },
            coverage: {
              sheetName: `${t('graphHeadings.reservedInstance')} (${t(
                'scorecard.savingsTypes.coverage'
              )})`,
              data: riCoverageData,
            },
          },
          {
            utilization: {
              sheetName: `${t('graphHeadings.savingPlan')} (${t(
                'scorecard.savingsTypes.utilization'
              )})`,
              data: spUtilizationData,
            },
            coverage: {
              sheetName: `${t('graphHeadings.savingPlan')} (${t(
                'scorecard.savingsTypes.coverage'
              )})`,
              data: spCoverageData,
            },
          },
          selectedConnection
        )
      )
    );
  }, [
    selectedConnection,
    savingsTrackCards,
    riUtilizationData,
    riCoverageData,
    spUtilizationData,
    spCoverageData,
  ]);

  /**
   * @function fetchAwsCategoryMapping
   * @description Function to fetch the aws check names and check ids.
   */
  const fetchAwsCategoryMapping = () => {
    setCategoryRecommendersMapRequestStatus(REQUEST_STATUS.PROCESSING);
    getAWSCategoryMapping()
      .then((res: any) => {
        setCategoryRecommendersMap(res?.data?.responseData);
        setCategoryRecommendersMapRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, false, setCategoryRecommendersMapRequestStatus)
      );
  };

  /**
   * @function getSavingsCost
   * @description Function to fetch the AWS saving cost
   * @param recommenders List of recommenders for which the data is fetched
   * @param states List of string recommendation status
   * @param dateRange Object containing the start and end date
   * @param setData Callback function for setting the data
   * @param setData Callback function for setting the request status
   */
  const getSavingsCost = (
    recommenders: string[] | undefined,
    states: string[],
    dateRange: { startDate: string; endDate: string },
    setData: (val: number) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);
    const requests: any[] = [];
    (recommenders ?? []).forEach((recommender) => {
      const requestBody = geCostSavingsQuery(recommender, states, dateRange);

      requests.push(
        getFilteredRecommendations(requestBody, {
          connectorId: selectedConnection?.connectorId,
        })
      );
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        let totalCostSum = 0;
        responses?.forEach((res: any) => {
          const data = res?.data || [];
          data.forEach(
            (costData: any) =>
              (totalCostSum += Number(costData?.estimatedSavings || 0))
          );
        });
        setData(totalCostSum);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setRequestStatus));
  };

  /**
   * @function getAWSYtdSavingsEffectivenessDetails
   * @description Function fetch the YTD AWS savings effectiveness details
   */
  const getAWSYtdSavingsEffectivenessDetails = () => {
    setYtdNetSavingsRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      granularity: SCORECARDS_TIMERANGE.MONTHLY,
      startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      endDate: moment().format(HYPHEN_DATE_FORMAT),
    };

    fetchAwsUtilizationDetails(params)
      .then((res: any) => {
        const data: any[] = res?.data?.responseData || [];
        let totalNetSavings = 0;
        (data || []).forEach((item: any) => {
          totalNetSavings += item?.totalSavings || 0;
        });

        setYtdNetSavings(totalNetSavings);
        setYtdNetSavingsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setYtdNetSavingsRequestStatus);
      });
  };

  /**
   * @function getAWSSavingsEffectivenessDetails
   * @description Function fetch the AWS savings effectiveness details
   */
  const getAWSSavingsEffectivenessDetails = () => {
    setSavingsEffectivenessRquestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      granularity: SCORECARDS_TIMERANGE.MONTHLY,
      ...getMonthWiseDates(),
    };

    fetchAwsUtilizationDetails(params)
      .then((res: any) => {
        const data = res?.data?.responseData || [];
        if (data.length > 0) {
          setNetSavings(data[0]?.totalSavings || 0);
          setSavingsEffectivenessPercentage(data[0]?.savingsEffectiveness || 0);
          setOnDemandSpend(data[0]?.totalOnDemandEquivalentSpend || 0);
        } else {
          setNetSavings(0);
          setSavingsEffectivenessPercentage(0);
          setOnDemandSpend(0);
        }
        setSavingsEffectivenessRquestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSavingsEffectivenessRquestStatus);
      });
  };

  /**
   * @function fetchWeightagePercentage
   * @description Function to fetch the weightage percentage
   */
  const fetchWeightagePercentage = () => {
    setWeightagePercentageRequestStatus(REQUEST_STATUS.PROCESSING);

    fetchWMAssignedWeightage()
      .then((res: any) => {
        let responseData = JSON.parse(res?.data?.responseData)[
          selectedConnection?.provider ?? ''
        ];

        const data = Object.entries(responseData).map((item) => ({
          key:
            getWasteManagementCategoriesLabelsByProvider(
              selectedConnection?.provider
            ).find((labelItem) => labelItem.value === item[0])?.label ??
            item[0],
          value: Number(item[1]),
        }));
        setWeightagePercentage(data);
        setWeightagePercentageRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setWeightagePercentageRequestStatus);
      });
  };

  /**
   * @function fetchCostOfWaste
   * @description Function to fetch the cost of waste for all waste management categories
   */
  const fetchCostOfWaste = () => {
    setCostOfWasteRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    const wmKeys = Object.keys(
      getWasteManagementCategoriesByProvider(selectedConnection?.provider)
    );
    wmKeys.forEach((category) => {
      const requestBody = getCostOfWasteRequestBody(
        selectedConnection?.provider ?? '',
        category,
        getMonthWiseDates(),
        SCORECARDS_TIMERANGE.MONTHLY
      );

      requests.push(
        getFilteredRecommendations(requestBody, {
          connectorId: selectedConnection?.connectorId,
        })
      );
    });

    axios
      .all(requests)
      .then((responses) => {
        let consolidatedData: CategoryMonthlyCost[] = [];
        wmKeys.forEach((key, index) => {
          consolidatedData.push({
            category: key,
            costData: (responses.at(index)?.data || []).map((item: any) => {
              const cost =
                (Number(item.estimatedSavings) / moment().daysInMonth()) *
                (moment().date() - 1);
              return {
                month: moment(item.month, YEAR_MONTH_WITHOUT_ZERO).format(
                  MONTH_YEAR_FORMAT
                ),
                cost: cost,
              };
            }),
          });
        });
        setCostOfWaste(consolidatedData);
        setCostOfWasteRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostOfWasteRequestStatus);
      });
  };

  /**
   * @function fetchTotalCost
   * @description Function to fetch the total cost for all waste management categories
   */
  const fetchTotalCost = () => {
    setTotalCostRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    const wmKeys = Object.keys(
      getWasteManagementCategoriesByProvider(selectedConnection?.provider ?? '')
    );
    wmKeys.forEach((category) => {
      requests.push(
        getChartData(
          getTotalCostRequestBody(
            selectedConnection?.provider ?? '',
            category,
            getMonthWiseDates(),
            SCORECARDS_TIMERANGE.MONTHLY
          ),
          selectedConnection?.connectorId,
          { checkId: AWS_CHECK_IDS[category as keyof typeof AWS_CHECK_IDS] }
        )
      );
    });

    axios
      .all(requests)
      .then((responses) => {
        let consolidatedData: CategoryMonthlyCost[] = [];
        wmKeys.forEach((category, index) => {
          consolidatedData.push({
            category: category,
            costData: (responses.at(index)?.data || []).map((item: any) => {
              return {
                ...item,
                month: moment(item.month, YEAR_HYPHEN_MONTH).format(
                  MONTH_YEAR_FORMAT
                ),
                cost: Number(item.cost),
              };
            }),
          });
        });
        setTotalCost(consolidatedData);
        setTotalCostRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTotalCostRequestStatus);
      });
  };

  /**
   * @function fetchRiUtilizationDetails
   * @description Function to fetch the RI utilization details
   */
  const fetchRiUtilizationDetails = () => {
    setRiUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);

    const requests: any[] = [];
    Object.values(AWS_RI_SERVICES).forEach((service) => {
      const params = {
        connectorId: selectedConnection?.connectorId,
        ...getMonthWiseDates(),
        granularity: SCORECARDS_TIMERANGE.MONTHLY,
        service: service,
      };
      requests.push(getAWSRIUtilizationDetails(params));
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        const utilizationData: ItemValueType[] = [];
        responses?.forEach((res) => {
          const data = Object.entries<number>(res?.data?.responseData || {});
          utilizationData.push({
            item: getAwsRiServiceLabels(res?.config?.params?.service),
            value: data[0] ? data[0][1] : 0,
          });
        });
        setRiUtilizationData(utilizationData);
        setRiUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRiUtilizationRequestStatus);
      });
  };

  /**
   * @function fetchRiCoverageDetails
   * @description Function to fetch the RI coverage details
   */
  const fetchRiCoverageDetails = () => {
    setRiCoverageRequestStatus(REQUEST_STATUS.PROCESSING);

    const requests: any[] = [];
    Object.values(AWS_RI_SERVICES).forEach((service) => {
      const params = {
        connectorId: selectedConnection?.connectorId,
        ...getMonthWiseDates(),
        granularity: SCORECARDS_TIMERANGE.MONTHLY,
        service: service,
      };
      requests.push(getAWSRICoverageDetails(params));
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        const coverageData: ItemValueType[] = [];
        responses?.forEach((res) => {
          const data = Object.entries<number>(res?.data?.responseData || {});
          coverageData.push({
            item: getAwsRiServiceLabels(res?.config?.params?.service),
            value: data[0] ? data[0][1] : 0,
          });
        });
        setRiCoverageData(coverageData);
        setRiCoverageRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRiCoverageRequestStatus);
      });
  };

  /**
   * @function fetchSpUtilizationDetails
   * @description Function to fetch the SP utilization details
   */
  const fetchSpUtilizationDetails = () => {
    setSpUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);

    const requests: any[] = [];
    AWS_SP_GRAPHS_TO_SERVICES_MAP.forEach((item) => {
      const params = {
        connectorId: selectedConnection?.connectorId,
        ...getMonthWiseDates(),
        granularity: SCORECARDS_TIMERANGE.MONTHLY,
        savingsPlanType: item.utilization?.toString(),
      };
      requests.push(getAWSSPUtilizationDetails(params));
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        const utilizationData: ItemValueType[] = [];
        AWS_SP_GRAPHS_TO_SERVICES_MAP?.forEach((item, index) => {
          const data = Object.entries<number>(
            responses[index]?.data?.responseData || {}
          );
          utilizationData.push({
            item: item.key,
            value: data[0] ? data[0][1] : 0,
          });
        });
        setSpUtilizationData(utilizationData);
        setSpUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSpUtilizationRequestStatus);
      });
  };

  /**
   * @function fetchSpCoverageDetails
   * @description Function to fetch the SP coverage details
   */
  const fetchSpCoverageDetails = () => {
    setSpCoverageRequestStatus(REQUEST_STATUS.PROCESSING);

    const requests: any[] = [];
    AWS_SP_GRAPHS_TO_SERVICES_MAP.forEach((item) => {
      const params = {
        connectorId: selectedConnection?.connectorId,
        ...getMonthWiseDates(),
        granularity: SCORECARDS_TIMERANGE.MONTHLY,
        service: item.coverage?.toString(),
      };
      requests.push(getAWSSPCoverageDetails(params));
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        const coverageData: ItemValueType[] = [];
        AWS_SP_GRAPHS_TO_SERVICES_MAP?.forEach((item, index) => {
          const data = Object.entries<number>(
            responses[index]?.data?.responseData || {}
          );
          coverageData.push({
            item: item.key,
            value: data[0] ? data[0][1] : 0,
          });
        });
        setSpCoverageData(coverageData);
        setSpCoverageRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSpCoverageRequestStatus);
      });
  };

  const getGraphComponent = (
    graph: string,
    pdfView: boolean = false,
    reportTypeOverride?: string
  ) => {
    switch (graph) {
      case SUMMARY_GRAPHS.SAVINGS_TRACK:
        return (
          <SavingsTrack
            savingsTrackCards={savingsTrackCards}
            pdfView={pdfView}
          />
        );

      case SUMMARY_GRAPHS.SAVINGS_EFFECTIVENESS:
        return (
          <SavingsEffectivenessSummary
            pdfView={pdfView}
            savingsPercentage={consolidatedSavingsEffectivenessPercentage}
            requestStatus={[
              savingsEffectivenessRquestStatus,
              weightagePercentageRequestStatus,
              costOfWasteRequestStatus,
              totalCostRequestStatus,
              categoryRecommendersMapRequestStatus,
            ]}
          />
        );

      case SUMMARY_GRAPHS.SAVING_PLAN:
        return (
          <CoverageUtilizationSummaryGraph
            heading={t('graphHeadings.savingPlan')}
            graphName={SUMMARY_GRAPHS.SAVING_PLAN}
            pdfView={pdfView}
            isTableView={spTableView}
            setIsTableView={setSpTableView}
            coverageUtilizationData={{
              utilization: spUtilizationData,
              coverage: spCoverageData,
            }}
            requestStatus={[
              spUtilizationRequestStatus,
              spCoverageRequestStatus,
              categoryRecommendersMapRequestStatus,
            ]}
            selectedReportType={reportTypeOverride ?? spSelectedReportType}
            setSelectedReportType={setSpSelectedReportType}
            isVerticalBarAlignment={false}
          />
        );

      case SUMMARY_GRAPHS.RESERVED_INSTANCE:
        return (
          <CoverageUtilizationSummaryGraph
            heading={t('graphHeadings.reservedInstance')}
            graphName={SUMMARY_GRAPHS.RESERVED_INSTANCE}
            pdfView={pdfView}
            isTableView={riTableView}
            setIsTableView={setRiTableView}
            coverageUtilizationData={{
              utilization: riUtilizationData,
              coverage: riCoverageData,
            }}
            requestStatus={[
              riUtilizationRequestStatus,
              riCoverageRequestStatus,
              categoryRecommendersMapRequestStatus,
            ]}
            selectedReportType={reportTypeOverride ?? riSelectedReportType}
            setSelectedReportType={setRiSelectedReportType}
          />
        );
    }
  };

  return (
    <>
      <div className="flex flex-column flex-gap-24">
        <Row gutter={24}>
          <Col span={12}>{getGraphComponent(SUMMARY_GRAPHS.SAVINGS_TRACK)}</Col>
          <Col span={12}>
            {getGraphComponent(SUMMARY_GRAPHS.SAVINGS_EFFECTIVENESS)}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            {getGraphComponent(SUMMARY_GRAPHS.RESERVED_INSTANCE)}
          </Col>
          <Col span={12}>{getGraphComponent(SUMMARY_GRAPHS.SAVING_PLAN)}</Col>
        </Row>
      </div>
      {showExpandGraphModal && (
        <ExpandModal
          show={showExpandGraphModal}
          graphContent={getGraphComponent(expandedGraphName)}
        />
      )}
      <PdfDownloadComponent
        pdfContent={[
          {
            element: getGraphComponent(SUMMARY_GRAPHS.SAVINGS_TRACK, true),
            contentType: CHART_TYPES.BAR_LINE_CHART,
            graphName: SUMMARY_GRAPHS.SAVINGS_TRACK,
            isTableView: false,
          },
          {
            element: getGraphComponent(
              SUMMARY_GRAPHS.SAVINGS_EFFECTIVENESS,
              true
            ),
            contentType: CHART_TYPES.PIE_CHART,
            graphName: SUMMARY_GRAPHS.SAVINGS_EFFECTIVENESS,
            isTableView: false,
          },
          {
            element: getGraphComponent(
              SUMMARY_GRAPHS.RESERVED_INSTANCE,
              true,
              SAVING_PLAN_REPORT_TYPES.UTILIZATION
            ),
            contentType: CHART_TYPES.BAR_CHART,
            graphName: SUMMARY_GRAPHS.RESERVED_INSTANCE,
            isTableView: riTableView,
            column: getUtilizationCoverageSummaryExportColumns(
              SAVING_PLAN_REPORT_TYPES.UTILIZATION
            ),
            body: insertIndex(
              riUtilizationData.map((item) => ({
                ...item,
                value: numberCommaSeparator(item.value),
              }))
            ),
            tableName: `${t('graphHeadings.reservedInstance')} (${t(
              'scorecard.savingsTypes.utilization'
            )})`,
          },
          {
            element: getGraphComponent(
              SUMMARY_GRAPHS.RESERVED_INSTANCE,
              true,
              SAVING_PLAN_REPORT_TYPES.COVERAGE
            ),
            contentType: CHART_TYPES.BAR_LINE_CHART,
            graphName: SUMMARY_GRAPHS.RESERVED_INSTANCE,
            isTableView: riTableView,
            column: getUtilizationCoverageSummaryExportColumns(
              SAVING_PLAN_REPORT_TYPES.COVERAGE
            ),
            body: insertIndex(
              riCoverageData.map((item) => ({
                ...item,
                value: numberCommaSeparator(item.value),
              }))
            ),
            tableName: `${t('graphHeadings.reservedInstance')} (${t(
              'scorecard.savingsTypes.coverage'
            )})`,
          },
          {
            element: getGraphComponent(
              SUMMARY_GRAPHS.SAVING_PLAN,
              true,
              SAVING_PLAN_REPORT_TYPES.UTILIZATION
            ),
            contentType: CHART_TYPES.BAR_CHART,
            graphName: SUMMARY_GRAPHS.SAVING_PLAN,
            isTableView: spTableView,
            column: getUtilizationCoverageSummaryExportColumns(
              SAVING_PLAN_REPORT_TYPES.UTILIZATION
            ),
            body: insertIndex(
              spUtilizationData.map((item) => ({
                ...item,
                value: numberCommaSeparator(item.value),
              }))
            ),
            tableName: `${t('graphHeadings.savingPlan')} (${t(
              'scorecard.savingsTypes.utilization'
            )})`,
          },
          {
            element: getGraphComponent(
              SUMMARY_GRAPHS.SAVING_PLAN,
              true,
              SAVING_PLAN_REPORT_TYPES.COVERAGE
            ),
            contentType: CHART_TYPES.BAR_LINE_CHART,
            graphName: SUMMARY_GRAPHS.SAVING_PLAN,
            isTableView: spTableView,
            column: getUtilizationCoverageSummaryExportColumns(
              SAVING_PLAN_REPORT_TYPES.COVERAGE
            ),
            body: insertIndex(
              spCoverageData.map((item) => ({
                ...item,
                value: numberCommaSeparator(item.value),
              }))
            ),
            tableName: `${t('graphHeadings.savingPlan')} (${t(
              'scorecard.savingsTypes.coverage'
            )})`,
          },
        ]}
        pdfMetaData={{
          viewName: t('scorecard.views.summary'),
          fileName: selectedConnection?.name ?? '',
          heading: selectedConnection?.name ?? '',
          subtitle1: selectedConnection?.dataSourceType ?? '',
          provider: selectedConnection?.provider ?? '',
        }}
      />
    </>
  );
};

export default AWSSummaryDashboard;
