import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupRequestType, GroupType } from 'pages/GroupsPage/types';
import { createGroup, updateGroup } from 'pages/GroupsPage/services';
import Button from 'components/Button';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import {
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import ConnectionList from '../ConnectionList';

type CreateGroupModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  setShowSuccessPage: (val: boolean) => void;
  fetchAllGroups: () => void;
  editGroup?: GroupType;
};

const CreateGroupModal = ({
  show,
  setShow,
  setShowSuccessPage,
  fetchAllGroups,
  editGroup,
}: CreateGroupModalProps) => {
  const { t } = useTranslation();

  const [groupData, setGroupData] = useState<GroupRequestType>({
    name: '',
    connectorIds: [],
  });
  const [groupNameValidationMessage, setGroupNameValidationMessage] =
    useState('');
  const [connectionsValidationMessage, setConnectionsValidationMessage] =
    useState('');
  const [apiValidationMessage, setApiValidationMessage] = useState('');
  const [createGroupApiRequestStatus, setCreateGroupApiRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    if (editGroup) {
      setGroupData({
        ...editGroup,
        connectorIds: editGroup.connectorDtos.map((item) => item.connectorId),
      });
    }
  }, [editGroup, show]);

  const validateGroupName = (groupName: string) => {
    if (
      validateEmptyField(
        groupName.trim(),
        t('groupsLabels.name'),
        setGroupNameValidationMessage
      )
    ) {
      setGroupData({ ...groupData, name: groupName });
      return false;
    }

    if (
      !validateAlphanumericNames(
        groupName.trim(),
        t('groupsLabels.name'),
        setGroupNameValidationMessage
      )
    ) {
      setGroupData({ ...groupData, name: groupName });
      return false;
    }

    if (validateStringLengthLessThan(groupName.trim(), MAX_CHARACTER_LIMIT)) {
      setGroupNameValidationMessage(
        t('groupsLabels.groupNameLengthValidationMessage')
      );
      return false;
    }
    setGroupNameValidationMessage('');
    setGroupData({ ...groupData, name: groupName });
    return true;
  };

  const validateGroupData = () => {
    let validationStatus = true;
    if (!validateGroupName(groupData.name)) {
      validationStatus = false;
    }

    if (groupData.connectorIds.length < 2) {
      setConnectionsValidationMessage(
        t('groupsLabels.connectionsValidationMessage')
      );
      validationStatus = false;
    }

    return validationStatus;
  };

  const handleCreateGroup = () => {
    if (!validateGroupData()) {
      return;
    }

    setCreateGroupApiRequestStatus(REQUEST_STATUS.PROCESSING);
    (editGroup ? updateGroup(groupData) : createGroup(groupData))
      .then((res: any) => {
        if (res?.status === 200) {
          setShowSuccessPage(true);
          setShow(false);
          fetchAllGroups();
          setCreateGroupApiRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setApiValidationMessage(res?.message);
          setCreateGroupApiRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setCreateGroupApiRequestStatus);
        setApiValidationMessage(e?.response?.data?.message);
      });
  };

  const CreateGroupCta = (
    <Button
      className="full-width"
      title={editGroup ? t('groupsLabels.update') : t('groupsLabels.create')}
      onClick={handleCreateGroup}
      loading={createGroupApiRequestStatus === REQUEST_STATUS.PROCESSING}
    />
  );

  return (
    <DrawerComponent
      className="create-group-modal"
      open={show}
      title={
        editGroup ? t('groupsLabels.editGroup') : t('groupsLabels.createAGroup')
      }
      onClose={() => setShow(false)}
      footer={CreateGroupCta}
      dataTestId="create-group-drawer"
    >
      <div className="group-modal-body">
        <div className="group-modal-form flex flex-column flex-gap-16">
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('groupsLabels.name')}
              required={true}
              disabled={!!editGroup}
            />
            <Input
              placeholder={t('groupsLabels.namePlaceholder')}
              value={groupData?.name}
              onChange={(e: any) => validateGroupName(e.target.value)}
              onBlur={() => validateGroupName(groupData.name)}
              disabled={!!editGroup}
            />
            <span
              style={{
                display: `${groupNameValidationMessage ? 'inline' : 'none'}`,
              }}
              className="font-validation-error"
            >
              {groupNameValidationMessage}
            </span>
          </div>
          <div className="form-item flex flex-column">
            <FormLabel title={t('groupsLabels.selectConnections')} required />
            <ConnectionList
              groupData={groupData}
              setGroupData={setGroupData}
              setConnectionsValidationMessage={setConnectionsValidationMessage}
            />
            <span
              style={{
                display: `${connectionsValidationMessage ? 'inline' : 'none'}`,
              }}
              className="font-validation-error"
            >
              {connectionsValidationMessage}
            </span>
          </div>
        </div>
        {apiValidationMessage && (
          <div className="error-message flex flex-justify-content-center font-caption">
            {apiValidationMessage}
          </div>
        )}
      </div>
    </DrawerComponent>
  );
};

export default CreateGroupModal;
