import { PROVIDER } from 'constants/cloudProviders';
import {
  QUERY_FIELDS,
  AGGREGATORS,
  ORDER_BY,
  DASHBOARD_TYPES,
  COMPARATORS,
  QUERY_VALUES,
  CONJUNCTIONS,
} from 'constants/requestBody';
import { ConnectionListType, TagsFilterType } from 'types/dashboard';
import {
  MonthlyCostType,
  ColouredCostByServiceType,
  ColouredCostByResourceType,
} from 'types/dataTypes';
import { DashboardListType } from 'types/navigationMenu';
import {
  getAzureTagFiltersData,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import { formatArrayToStringByProvider } from 'utils/dataFormatterUtils';
import { formatDateFieldByProvider } from 'utils/date';

/**
 * @function getCostTrendsExportColumns
 * @description Function to return the columns for excel and pdf export.
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @param data trends graph data for all the drilldown levels
 * @return List of columns for export
 */
export const getCostTrendsData = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined,
  data: {
    trends?: MonthlyCostType[];
    service?: ColouredCostByServiceType[];
    resource?: ColouredCostByResourceType[];
  }
) => {
  const { trends = [], service = [], resource = [] } = data;
  if (!selectedTrendMonth) {
    return trends;
  }

  if (!selectedTrendService) {
    return service;
  }

  if (selectedTrendService) {
    return resource;
  }

  return [];
};

/**
 * @function getAzureCloudSpendQuery
 * @description Function to return the query for cloud spend
 * @param startDate start date for the query filter
 * @param endDate end date for the query filter
 * @param isStaticData is the data for static dashboard
 * @param selectedTagsFilterGroups selected tags filter groups
 * @param selectedDashboard Dashboard details for which the query is returned
 * @param selectedConnection Object containing the connection details
 * @param selectedAccounts List of accounts to be filtered with
 * @returns Query for cloud spend
 */
export const getAzureCloudSpendQuery = (
  startDate: string,
  endDate: string,
  isStaticData: boolean,
  selectedTagsFilterGroups: any[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedAccounts: string[]
) => {
  return {
    columns: [
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.RESOURCE_GROUP,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [{ label: 'cost', function: AGGREGATORS.SUM }],
    groupBy: ['name'],
    orderBy: [{ label: 'cost', sort: ORDER_BY.DESCENDING }],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startDate,
              PROVIDER.AZURE,
              isStaticData
            ),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              endDate,
              PROVIDER.AZURE,
              isStaticData
            ),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard!.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              selectedConnection!.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...selectedTagsFilterGroups,
    ],
  };
};

/**
 * @function getAzureTaggedUntaggedSpendQuery
 * @description Function to return the query for tagged and untagged spend
 * @param startMonth start month for the query filter
 * @param endMonth end month for the query filter
 * @param tagFilters tag filters for the query
 * @param selectedDashboard selected dashboard
 * @param selectedConnection Object containing the connection details
 * @param selectedDashboardView selected dashboard view
 * @param selectedAccounts List of selected accounts for filter
 * @returns List of queries for tagged and untagged spend
 */
export const getAzureTaggedUntaggedSpendQuery = (
  startMonth: string,
  endMonth: string,
  tagFilters: TagsFilterType[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  selectedAccounts: string[]
) => {
  const isStaticData = isDashboardWithStaticData(selectedDashboard);
  const monthField = isStaticData
    ? getQueryFieldByDataSource(
        selectedDashboard.dashBoardType,
        QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE,
        selectedConnection.focusConversionEnabled
      )
    : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE;
  const dateField = isStaticData
    ? getQueryFieldByDataSource(
        selectedDashboard.dashBoardType,
        QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE,
        selectedConnection.focusConversionEnabled
      )
    : QUERY_FIELDS.BILLING_PERIOD_START_DATE;

  const selectedTagsFilterGroups = getAzureTagFiltersData(
    tagFilters,
    selectedDashboard.id,
    selectedDashboardView
  );
  const baseQuery = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'name',
        field: monthField,
      },
    ],
    groupBy: ['name'],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: dateField,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(startMonth, PROVIDER.AZURE, false),
          },
          {
            field: dateField,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(endMonth, PROVIDER.AZURE, false),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };

  if (selectedTagsFilterGroups.length) {
    return [
      {
        ...baseQuery,
        filterGroups: [baseQuery.filterGroups[0], ...selectedTagsFilterGroups],
      },
    ];
  }

  return [
    {
      ...baseQuery,
      filterGroups: [
        ...baseQuery.filterGroups,
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.TAGS,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.IS,
              value: QUERY_VALUES.NOT_NULL,
            },
          ],
        },
      ],
    },
    {
      ...baseQuery,
      filterGroups: [
        ...baseQuery.filterGroups,
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.TAGS,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.IS,
              value: QUERY_VALUES.NULL,
            },
          ],
        },
      ],
    },
  ];
};

/**
 * @function getAzureServiceSpendProfileQuery
 * @description Function to return the query for service spend profile
 * @param startMonth start month for the query filter
 * @param endMonth end month for the query filter
 * @param selectedDashboard selected dashboard
 * @param selectedConnection Object containing the connection details
 * @param selectedDashboardView selected dashboard view
 * @param tagFilters tag filters for the query
 * @param selectedAccounts List of selected accounts
 * @returns Query for service spend profile
 */
export const getAzureServiceSpendProfileQuery = (
  startMonth: string,
  endMonth: string,
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  tagFilters: TagsFilterType[],
  selectedAccounts: string[]
) => {
  const isStaticData = isDashboardWithStaticData(selectedDashboard);
  const monthField = isStaticData
    ? getQueryFieldByDataSource(
        selectedDashboard.dashBoardType,
        QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE,
        selectedConnection.focusConversionEnabled
      )
    : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE;
  const dateField = isStaticData
    ? getQueryFieldByDataSource(
        selectedDashboard.dashBoardType,
        QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE,
        selectedConnection.focusConversionEnabled
      )
    : QUERY_FIELDS.BILLING_PERIOD_START_DATE;

  const selectedTagsFilterGroups = getAzureTagFiltersData(
    tagFilters,
    selectedDashboard.id,
    selectedDashboardView
  );

  return {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'service',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.CONSUMED_SERVICE,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: monthField,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['service', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: dateField,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: startMonth,
          },
          {
            field: dateField,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: endMonth,
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...selectedTagsFilterGroups,
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};
