import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function createCustomView
 * @description Function to create or draft a custom view
 * @param body Object containing the request parameters
 * @return axios response from POST request
 */
export const createCustomView = async (body: any, params: any) => {
  return ServiceCalls.post(APIS.CREATE_CUSTOM_VIEW, body, null, params);
};

/**
 * @function updateCustomView
 * @description Function to update a custom view
 * @param body Object containing the request parameters
 * @return axios response from POST request
 */
export const updateCustomView = async (body: any) => {
  return ServiceCalls.post(APIS.UPDATE_CUSTOM_VIEW, body);
};

/**
 * @function createCustomDashboard
 * @description Function to create or draft a custom dashboard
 * @param body Object containing the request parameters
 * @return axios response from POST request
 */
export const createCustomDashboard = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_CUSTOM_DASHBOARD, body);
};
