import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { setNavSubPath } from 'redux/activeNavMenuSlice';
import { setExistingReport } from 'redux/reportSlice';
import NavigationPath from 'components/NavigationPath';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import Button from 'components/Button';
import { ReportsViewType } from 'pages/CreateReportPage/constants';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import { ReportTypes, REPORTS_TYPES_LIST } from './constants';
import MyReport from './components/MyReport';
import Shared from './components/Shared';

import './index.scss';

const ReportsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedReportType, setSelectedReportType] = useState<string>();

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    const reportType = getReportTypeByLocation();
    setSelectedReportType(reportType.id);
    dispatch(setNavSubPath([reportType.title || t('reports.myReport')]));
  }, [location]);

  /**
   * @function getReportTypeByLocation
   * @description function to fetch the report type by the url location
   * @returns Object containing the report type details (Shared or My Report)
   */
  const getReportTypeByLocation = () => {
    const queryParams = new URLSearchParams(location.search);
    const reportTypeQuery = queryParams.get('reportType')?.toUpperCase();

    let reportType = REPORTS_TYPES_LIST.find(
      (item) => item.id === reportTypeQuery
    );

    if (!reportType) {
      reportType = REPORTS_TYPES_LIST[0];
      navigate(
        NAVIGATION_MENU_PATH.REPORTS +
          '?reportType=' +
          reportType.id.toLocaleLowerCase()
      );
    }

    return reportType;
  };

  const onClickCreateReport = () => {
    dispatch(setExistingReport(null));
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('view', ReportsViewType.EDIT);
    queryParams.set('reportType', getReportTypeByLocation().id.toLowerCase());
    navigate(NAVIGATION_MENU_PATH.CREATE_REPORT + '?' + queryParams.toString());
  };

  const getComponent = () => {
    switch (selectedReportType) {
      case ReportTypes.MY_REPORT:
        return <MyReport />;

      case ReportTypes.SHARED:
        return <Shared />;
    }
  };

  return (
    <div className="reports flex flex-column flex-fit">
      <header className="new-page-header reports-header flex flex-align-items-center flex-space-between">
        <span className="modal-heading">{t('navigationMenu.reports')}</span>
        <Button
          title={t('reports.createReport')}
          onClick={onClickCreateReport}
        />
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <HorizontalNavigationMenu
          menuItems={REPORTS_TYPES_LIST.map((reportType) => (
            <Menu.Item
              onClick={() => {
                navigate(
                  NAVIGATION_MENU_PATH.REPORTS +
                    '?reportType=' +
                    reportType.id.toLocaleLowerCase()
                );
              }}
              key={reportType.id}
              eventKey={reportType.id}
              className={`font-caption-bold menu ${
                reportType.id === selectedReportType && 'active-menu'
              }`}
            >
              {reportType.title}
            </Menu.Item>
          ))}
          selectedKeys={selectedReportType ? [selectedReportType] : []}
        />
        <div className="reports-content margin-24 flex flex-column flex-fit">
          {getComponent()}
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
