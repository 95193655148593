import LabelOverflowWithTooltip from 'components/LabelOverflowWithTooltip';
import { LEGEND_ITEM_NAME_LENGTH_LIMIT } from 'constants/userConsole';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import SpendProfileIcon from '../SpendProfileIcon';
import { SpendProfileDataType } from '../../types';

import './index.scss';

type SpendProfileFooterProps = {
  spendData: SpendProfileDataType;
  pdfView: boolean;
};

const SpendProfileFooter = ({
  spendData,
  pdfView,
}: SpendProfileFooterProps) => {
  return (
    <div
      className="spend-profile-footer flex flex-space-evenly flex-gap-8 flex-align-items-start flex-wrap"
      data-testid="spend-profile-footer"
    >
      {spendData.topAppsImpacted.map((impactedApp) => (
        <div
          className="flex flex-column flex-center flex-gap-4 flex-fit"
          key={impactedApp.app}
        >
          <div className="font-caption-bold">
            {LabelOverflowWithTooltip(
              impactedApp.app,
              LEGEND_ITEM_NAME_LENGTH_LIMIT,
              !pdfView
            )}
          </div>
          <div className="flex flex-center flex-gap-8">
            <div className="percentage font-caption">
              {numberCommaSeparator(impactedApp.percentage)} %
            </div>
            <SpendProfileIcon spendType={spendData.spendType} size="small" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SpendProfileFooter;
