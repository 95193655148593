import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import moment from 'moment';
import { sumBy, uniqBy } from 'lodash';

import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import TagFilters from 'components/TagFilters';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { getChartData } from 'utils/services';
import {
  addZeroMarginClass,
  generateGraphColors,
  getBillingPeriodDateFormatByProvider,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import {
  DATE_FORMAT,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  enumerateDaysBetweenDates,
  getMonthYearShortList,
} from 'utils/date';
import { modifyToExportColumns } from 'utils/exportToExcel';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { DualAxisChartData } from 'types/dashboard';
import { OTHERS_LABEL } from 'constants/graphLabels';

import {
  getAWSStorageExcelData,
  getEbsOperationUsageCostColumns,
  getEbsOperationUsageCostQuery,
  getEbsSnapshotSpendQuery,
  getSpendByAccountsQuery,
  getEbsStorageMonthlyCostColumns,
  getStorageSpendColumns,
  getStorageSpendByStorageTypeQuery,
  getStorageMonthlyCostQuery,
  getStorageCoverageQuery,
  getVolumesCoverageColumns,
  getEbsVolumesSpendQuery,
  getRegionWithGroupingValuesColumns,
  getStorageFsCostByAccountsQuery,
  getStorageFsCostByOperationQuery,
  getStorageFsCostByProductCodeQuery,
  getStorageFsCostByRegionsQuery,
  getStorageFsCostByUsageTypeQuery,
  getSpendByAccountsLabelByProvider,
  getVolumeCoverageLabelByProvider,
  getStorageMonthlyCostLabelByProvider,
  getStorageFsUsageCostLabelByProvider,
  getStorageSpendLabelByProvider,
  hasChartBasedOnProvider,
  getStorageFsUsageCostByOperationColumnLabel,
  getStorageFsCostByRegionsLabelByProvider,
  getStorageFsCostByOperationLabelByProvider,
} from './utils';
import StackedColumnCharts from '../StackedColumnCharts';
import DashboardPieDonutCharts from '../DashboardPieDonutCharts';
import {
  getAccountWithGroupingValuesColumns,
  getDualAxisCostByTypeTableData,
  getEbsVolumesSpendColumns,
  getUsageCostTableData,
  getAccountLabelByProvider,
} from '../../utils';
import StackedDualAxisCharts from '../StackedDualAxisCharts';
import { UnitCostTimePeriod } from '../../constants';
import StackedColumnLineWithUnitCostCharts from '../StackedColumnLineWithUnitCostCharts';
import ServiceViewsControls from '../ServiceViewsControls';
import { StorageDashboardGraphs } from './constants';

const StorageDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedDashboard,
    selectedConnection,
    showExpandGraphModal,
    expandedGraphName,
    tableViewEnabled,
    pdfDownloadMode,
    tagsFilters,
    selectedDashboardView,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  // Accounts States
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [accountsReqStatus, setAccountsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  // EBS Spend by Accounts
  const [ebsSpendByAccounts, setEbsSpendByAccounts] = useState<any[]>([]);
  const [ebsSpendByAccountsReqStatus, setEbsSpendByAccountsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [ebsSpendByAccountsTableView, setEbsSpendByAccountsTableView] =
    useState(false);
  const [ebsSpendByAccountsDateRange, setEbsSpendByAccountsDateRange] =
    useState([
      moment()
        .subtract(5, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
    ]);

  // EBS Volume Coverage (GB-Mo) Previous Month
  const [ebsVolumeCoverage, setEbsVolumeCoverage] = useState<any[]>([]);
  const [ebsVolumeCoverageReqStatus, setEbsVolumeCoverageReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [ebsVolumeCoverageTableView, setEbsVolumeCoverageTableView] =
    useState(false);

  // EBS Storage Monthly Cost
  const [ebsStorageMonthlyCost, setEbsStorageMonthlyCost] = useState<any[]>([]);
  const [ebsStorageMonthlyCostReqStatus, setEbsStorageMonthlyCostReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [ebsStorageMonthlyCostTableView, setEbsStorageMonthlyCostTableView] =
    useState(false);
  const [ebsStorageMonthlyCostDateRange, setEbsStorageMonthlyCostDateRange] =
    useState([
      moment()
        .subtract(5, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
    ]);
  const [
    ebsStorageMonthlyUnitCostTimePeriod,
    setEbsStorageMonthlyUnitCostTimePeriod,
  ] = useState<UnitCostTimePeriod>(UnitCostTimePeriod.HOURLY);

  // EBS Storage Spend
  const [ebsStorageSpend, setEbsStorageSpend] = useState<any[]>([]);
  const [ebsStorageSpendReqStatus, setEbsStorageSpendReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [ebsStorageSpendTableView, setEbsStorageSpendTableView] =
    useState(false);
  const [ebsStorageSpendDateRange, setEbsStorageSpendDateRange] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // EBS Operations Usage Cost Last 90 Days
  const [ebsOperationsUsageCost, setEbsOperationsUsageCost] =
    useState<DualAxisChartData>({
      leftAxisData: [],
      rightAxisData: [],
    });
  const [ebsOperationsUsageCostReqStatus, setEbsOperationsUsageCostReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [ebsOperationsUsageCostTableView, setEbsOperationsUsageCostTableView] =
    useState(false);
  const [ebsOperationsUsageCostDateRange, setEbsOperationsUsageCostDateRange] =
    useState([
      moment().subtract(91, 'days').format(HYPHEN_DATE_FORMAT),
      moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
    ]);

  // EBS Snapshot Spend
  const [ebsSnapshotSpend, setEbsSnapshotSpend] = useState<any[]>([]);
  const [ebsSnapshotSpendReqStatus, setEbsSnapshotSpendReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [ebsSnapshotSpendTableView, setEbsSnapshotSpendTableView] =
    useState(false);
  const [ebsSnapshotSpendDateRange, setEbsSnapshotSpendDateRange] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);
  const [
    ebsSnapshotSpendUnitCostTimePeriod,
    setEbsSnapshotSpendUnitCostTimePeriod,
  ] = useState<UnitCostTimePeriod>(UnitCostTimePeriod.HOURLY);

  // EBS Volumes Spend
  const [ebsVolumesSpend, setEbsVolumesSpend] = useState<any[]>([]);
  const [ebsVolumesSpendReqStatus, setEbsVolumesSpendReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [ebsVolumesSpendTableView, setEbsVolumesSpendTableView] =
    useState(false);
  const [ebsVolumesSpendDateRange, setEbsVolumesSpendDateRange] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // Storage FS Usage Cost by Account
  const [storageFsCostByAccounts, setStorageFsCostByAccounts] = useState<any[]>(
    []
  );
  const [
    storageFsCostByAccountsReqStatus,
    setStorageFsCostByAccountsReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    storageFsCostByAccountsTableView,
    setStorageFsCostByAccountsTableView,
  ] = useState(false);
  const [
    storageFsCostByAccountsDateRange,
    setStorageFsCostByAccountsDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);
  const [
    storageFsCostByAccountsUnitCostPeriod,
    setStorageFsCostByAccountsUnitCostPeriod,
  ] = useState(UnitCostTimePeriod.HOURLY);

  // Storage FS Usage Cost by Region
  const [storageFsCostByRegions, setStorageFsCostByRegions] = useState<any[]>(
    []
  );
  const [storageFsCostByRegionsReqStatus, setStorageFsCostByRegionsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [storageFsCostByRegionsTableView, setStorageFsCostByRegionsTableView] =
    useState(false);
  const [storageFsCostByRegionsDateRange, setStorageFsCostByRegionsDateRange] =
    useState([
      moment()
        .subtract(5, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
    ]);

  // Storage FS Usage Cost by Product Code
  const [storageFsCostByProductCode, setStorageFsCostByProductCode] = useState<
    any[]
  >([]);
  const [
    storageFsCostByProductCodeReqStatus,
    setStorageFsCostByProductCodeReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    storageFsCostByProductCodeTableView,
    setStorageFsCostByProductCodeTableView,
  ] = useState(false);
  const [
    storageFsCostByProductCodeDateRange,
    setStorageFsCostByProductCodeDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // Storage FS Usage Cost by Usage Type
  const [storageFsCostByUsageType, setStorageFsCostByUsageType] = useState<
    any[]
  >([]);
  const [
    storageFsCostByUsageTypeReqStatus,
    setStorageFsCostByUsageTypeReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    storageFsCostByUsageTypeTableView,
    setStorageFsCostByUsageTypeTableView,
  ] = useState(false);
  const [
    storageFsCostByUsageTypeDateRange,
    setStorageFsCostByUsageTypeDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // Storage FS Usage Cost by Operation
  const [storageFsCostByOperation, setStorageFsCostByOperation] = useState<
    any[]
  >([]);
  const [
    storageFsCostByOperationReqStatus,
    setStorageFsCostByOperationReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    storageFsCostByOperationTableView,
    setStorageFsCostByOperationTableView,
  ] = useState(false);
  const [
    storageFsCostByOperationDateRange,
    setStorageFsCostByOperationDateRange,
  ] = useState([
    moment().subtract(91, 'days').format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);

  useEffect(() => {
    addZeroMarginClass('dashboard-view');

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEbsSpendByAccounts([]);
    } else {
      fetchEbsSpendByAccounts();
    }
  }, [selectedAccounts, ebsSpendByAccountsDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEbsVolumeCoverage([]);
    } else {
      fetchEbsVolumeCoverage();
    }
  }, [selectedAccounts, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEbsStorageMonthlyCost([]);
    } else {
      fetchEbsStorageMonthlyCost();
    }
  }, [selectedAccounts, ebsStorageMonthlyCostDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEbsStorageSpend([]);
    } else {
      fetchEbsStorageSpend();
    }
  }, [selectedAccounts, ebsStorageSpendDateRange, tagsFilters]);

  useEffect(() => {
    if (
      selectedAccounts.length > 0 &&
      hasChartBasedOnProvider(
        selectedDashboard!.connectorProvider,
        StorageDashboardGraphs.EBS_OPERATION_USAGE_COST
      )
    ) {
      fetchEbsOperationsUsageCost();
    } else {
      setEbsOperationsUsageCost({ leftAxisData: [], rightAxisData: [] });
    }
  }, [selectedAccounts, ebsOperationsUsageCostDateRange, tagsFilters]);

  useEffect(() => {
    if (
      selectedAccounts.length > 0 &&
      hasChartBasedOnProvider(
        selectedDashboard!.connectorProvider,
        StorageDashboardGraphs.EBS_SNAPSHOT_SPEND
      )
    ) {
      fetchEbsSnapshotSpend();
    } else {
      setEbsSnapshotSpend([]);
    }
  }, [selectedAccounts, ebsSnapshotSpendDateRange, tagsFilters]);

  useEffect(() => {
    if (
      selectedAccounts.length > 0 &&
      hasChartBasedOnProvider(
        selectedDashboard!.connectorProvider,
        StorageDashboardGraphs.EBS_VOLUMES_SPEND
      )
    ) {
      fetchEbsVolumesSpend();
    } else {
      setEbsVolumesSpend([]);
    }
  }, [selectedAccounts, ebsVolumesSpendDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setStorageFsCostByAccounts([]);
    } else {
      fetchStorageFsCostByAccounts();
    }
  }, [selectedAccounts, storageFsCostByAccountsDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setStorageFsCostByRegions([]);
    } else {
      fetchStorageFsCostByRegions();
    }
  }, [selectedAccounts, storageFsCostByRegionsDateRange, tagsFilters]);

  useEffect(() => {
    if (
      selectedAccounts.length > 0 &&
      hasChartBasedOnProvider(
        selectedDashboard!.connectorProvider,
        StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE
      )
    ) {
      fetchStorageFsCostByProductCode();
    } else {
      setStorageFsCostByProductCode([]);
    }
  }, [selectedAccounts, storageFsCostByProductCodeDateRange, tagsFilters]);

  useEffect(() => {
    if (
      selectedAccounts.length > 0 &&
      hasChartBasedOnProvider(
        selectedDashboard!.connectorProvider,
        StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_USAGE_TYPE
      )
    ) {
      fetchStorageFsCostByUsageType();
    } else {
      setStorageFsCostByUsageType([]);
    }
  }, [selectedAccounts, storageFsCostByUsageTypeDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setStorageFsCostByOperation([]);
    } else {
      fetchStorageFsCostByOperation();
    }
  }, [selectedAccounts, storageFsCostByOperationDateRange, tagsFilters]);

  useEffect(() => {
    setEbsSpendByAccountsTableView(tableViewEnabled);
    setEbsVolumeCoverageTableView(tableViewEnabled);
    setEbsStorageMonthlyCostTableView(tableViewEnabled);
    setEbsStorageSpendTableView(tableViewEnabled);
    setEbsOperationsUsageCostTableView(tableViewEnabled);
    setEbsSnapshotSpendTableView(tableViewEnabled);
    setEbsVolumesSpendTableView(tableViewEnabled);
    setStorageFsCostByAccountsTableView(tableViewEnabled);
    setStorageFsCostByRegionsTableView(tableViewEnabled);
    setStorageFsCostByProductCodeTableView(tableViewEnabled);
    setStorageFsCostByUsageTypeTableView(tableViewEnabled);
    setStorageFsCostByOperationTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    dispatch(
      setExportToExcelData(
        getAWSStorageExcelData(
          selectedDashboard!.connectorName,
          selectedDashboard!.connectorProvider,
          selectedAccounts,
          {
            ebsSpendByAccounts: {
              graph: StorageDashboardGraphs.EBS_SPEND_BY_ACCOUNTS,
              sheetName: getSpendByAccountsLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: ebsSpendByAccounts,
              dateRange: ebsSpendByAccountsDateRange,
            },
            ebsVolumeUsage: {
              graph: StorageDashboardGraphs.EBS_VOLUME_COVERAGE,
              sheetName: getVolumeCoverageLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: ebsVolumeCoverage,
              dateRange: [
                moment()
                  .subtract(1, 'month')
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                moment()
                  .subtract(1, 'month')
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT),
              ],
            },
            ebsStorageMonthlyCost: {
              graph: StorageDashboardGraphs.EBS_STORAGE_MONTHLY_COST,
              sheetName: getStorageMonthlyCostLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: ebsStorageMonthlyCost,
              dateRange: ebsStorageMonthlyCostDateRange,
            },
            ebsStorageSpend: {
              graph: StorageDashboardGraphs.EBS_STORAGE_SPEND,
              sheetName: getStorageSpendLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: ebsStorageSpend,
              dateRange: ebsSpendByAccountsDateRange,
            },
            ebsOperationsUsageCost: {
              graph: StorageDashboardGraphs.EBS_OPERATION_USAGE_COST,
              sheetName: t('graphHeadings.ebsOperationsUsageCost'),
              data: ebsOperationsUsageCost,
              dateRange: ebsOperationsUsageCostDateRange,
            },
            ebsSnapshotSpend: {
              graph: StorageDashboardGraphs.EBS_SNAPSHOT_SPEND,
              sheetName: t('graphHeadings.ebsSnapshotSpend'),
              data: ebsSnapshotSpend,
              dateRange: ebsSnapshotSpendDateRange,
            },
            ebsVolumesSpend: {
              graph: StorageDashboardGraphs.EBS_VOLUMES_SPEND,
              sheetName: t('graphHeadings.ebsVolumesSpend'),
              data: ebsVolumesSpend,
              dateRange: ebsVolumesSpendDateRange,
            },
            storageFsCostByAccounts: {
              graph: StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_ACCOUNT,
              sheetName: getStorageFsUsageCostLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: storageFsCostByAccounts,
              dateRange: storageFsCostByAccountsDateRange,
            },
            storageFsCostByRegions: {
              graph: StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_REGION,
              sheetName: getStorageFsCostByRegionsLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: storageFsCostByRegions,
              dateRange: storageFsCostByRegionsDateRange,
            },
            storageFsCostByProductCode: {
              graph:
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE,
              sheetName: t('graphHeadings.storageFsUsageCostByProductCode'),
              data: storageFsCostByProductCode,
              dateRange: storageFsCostByProductCodeDateRange,
            },
            storageFsCostByUsageType: {
              graph: StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_USAGE_TYPE,
              sheetName: t('graphHeadings.storageFsUsageCostByUsageType'),
              data: storageFsCostByUsageType,
              dateRange: storageFsCostByUsageTypeDateRange,
            },
            storageFsCostByOperation: {
              graph: StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_OPERATION,
              sheetName: getStorageFsCostByOperationLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: storageFsCostByOperation,
              dateRange: storageFsCostByOperationDateRange,
            },
          }
        )
      )
    );
  }, [
    selectedDashboard,
    selectedAccounts,
    ebsSpendByAccounts,
    ebsSpendByAccountsDateRange,
    ebsVolumeCoverage,
    ebsStorageMonthlyCost,
    ebsStorageMonthlyCostDateRange,
    ebsOperationsUsageCost,
    ebsOperationsUsageCostDateRange,
    ebsSnapshotSpend,
    ebsSnapshotSpendDateRange,
    ebsVolumesSpend,
    ebsVolumesSpendDateRange,
    storageFsCostByAccounts,
    storageFsCostByAccountsDateRange,
    storageFsCostByRegions,
    storageFsCostByRegionsDateRange,
    storageFsCostByProductCode,
    storageFsCostByProductCodeDateRange,
    storageFsCostByUsageType,
    storageFsCostByUsageTypeDateRange,
    storageFsCostByOperation,
    storageFsCostByOperationDateRange,
  ]);

  /**
   * @function fetchEbsSpendByAccounts
   * @description Function to fetch the EBS Spend by Accounts
   */
  const fetchEbsSpendByAccounts = () => {
    setEbsSpendByAccountsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getSpendByAccountsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ebsSpendByAccountsDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            month: moment(
              item.month,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
            cost: Number(item.cost),
          })) ?? [];
        const accounts = uniqBy(data, 'account').map((item) => item.account);
        const months = getMonthYearShortList(
          ebsSpendByAccountsDateRange[0],
          ebsSpendByAccountsDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          accounts.forEach((account) => {
            const dataItem = data.find(
              (item) => item.month === month && item.account === account
            );
            formattedData.push({
              time: month,
              type: account,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setEbsSpendByAccounts(formattedData);
        setEbsSpendByAccountsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEbsSpendByAccountsReqStatus);
      });
  };

  /**
   * @function fetchEbsVolumeCoverage
   * @description Function to fetch the EBS Volume Coverage
   */
  const fetchEbsVolumeCoverage = () => {
    setEbsVolumeCoverageReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getStorageCoverageQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
          })) ?? [];
        const volumes = uniqBy(data, 'volume').map((item) => item.volume);

        const formattedData: any[] = [];
        const colors = generateGraphColors(volumes.length);
        volumes.forEach((volume, index) => {
          formattedData.push({
            type: volume,
            color: colors[index],
            value: data.find((item) => item.volume === volume)?.cost ?? 0,
          });
        });
        setEbsVolumeCoverage(formattedData);
        setEbsVolumeCoverageReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEbsVolumeCoverageReqStatus);
      });
  };

  /**
   * @function fetchEbsStorageMonthlyCost
   * @description Function to fetch the EBS Storage Monthly Cost
   */
  const fetchEbsStorageMonthlyCost = () => {
    setEbsStorageMonthlyCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getStorageMonthlyCostQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ebsStorageMonthlyCostDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
            [UnitCostTimePeriod.DAILY]: Number(item[UnitCostTimePeriod.DAILY]),
            [UnitCostTimePeriod.HOURLY]: Number(
              item[UnitCostTimePeriod.HOURLY]
            ),
            [UnitCostTimePeriod.WEEKLY]: Number(
              item[UnitCostTimePeriod.WEEKLY]
            ),
            month: moment(
              item.month,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
          })) ?? [];

        const months = getMonthYearShortList(
          ebsStorageMonthlyCostDateRange[0],
          ebsStorageMonthlyCostDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          const dataItem = data.find((item) => item.month === month);
          formattedData.push({
            time: month,
            value: dataItem?.cost ?? 0,
            type: t('awsStorageDashboard.costSum'),
            [UnitCostTimePeriod.DAILY]:
              dataItem?.[UnitCostTimePeriod.DAILY] ?? 0,
            [UnitCostTimePeriod.HOURLY]:
              dataItem?.[UnitCostTimePeriod.HOURLY] ?? 0,
            [UnitCostTimePeriod.WEEKLY]:
              dataItem?.[UnitCostTimePeriod.WEEKLY] ?? 0,
            unitCostType:
              'Average Unit Cost - ' + t('awsStorageDashboard.costSum'),
          });
        });

        setEbsStorageMonthlyCost(formattedData);
        setEbsStorageMonthlyCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEbsStorageMonthlyCostReqStatus);
      });
  };

  /**
   * @function fetchEbsStorageSpend
   * @description Function to fetch the EBS Storage Spend
   */
  const fetchEbsStorageSpend = () => {
    setEbsStorageSpendReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getStorageSpendByStorageTypeQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ebsStorageSpendDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            volume: item.volume ?? OTHERS_LABEL,
            cost: Number(item.cost),
            month: moment(
              item.month,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
          })) ?? [];
        const volumes = uniqBy(data, 'volume').map((item) => item.volume);
        const months = getMonthYearShortList(
          ebsStorageSpendDateRange[0],
          ebsStorageSpendDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          volumes.forEach((volume) => {
            const dataItem = data.find(
              (item) => item.volume === volume && item.month === month
            );
            formattedData.push({
              time: month,
              type: volume,
              value: dataItem?.cost ?? 0,
            });
          });
        });

        setEbsStorageSpend(formattedData);
        setEbsStorageSpendReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEbsStorageSpendReqStatus);
      });
  };

  /**
   * @function fetchEbsSnapshotSpend
   * @description Function to fetch the EBS Snapshot Spend
   */
  const fetchEbsSnapshotSpend = () => {
    setEbsSnapshotSpendReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEbsSnapshotSpendQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ebsSnapshotSpendDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
            [UnitCostTimePeriod.DAILY]: Number(item[UnitCostTimePeriod.DAILY]),
            [UnitCostTimePeriod.HOURLY]: Number(
              item[UnitCostTimePeriod.HOURLY]
            ),
            [UnitCostTimePeriod.WEEKLY]: Number(
              item[UnitCostTimePeriod.WEEKLY]
            ),
            month: moment(
              item.month,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
          })) ?? [];
        const accounts = uniqBy(data, 'account').map((item) => item.account);
        const months = getMonthYearShortList(
          ebsStorageSpendDateRange[0],
          ebsStorageSpendDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          accounts.forEach((account) => {
            const dataItem = data.find(
              (item) => item.account === account && item.month === month
            );
            formattedData.push({
              time: month,
              type: account,
              value: dataItem?.cost ?? 0,
              [UnitCostTimePeriod.DAILY]:
                dataItem?.[UnitCostTimePeriod.DAILY] ?? 0,
              [UnitCostTimePeriod.HOURLY]:
                dataItem?.[UnitCostTimePeriod.HOURLY] ?? 0,
              [UnitCostTimePeriod.WEEKLY]:
                dataItem?.[UnitCostTimePeriod.WEEKLY] ?? 0,
              unitCostType: 'Average Unit Cost - ' + account,
            });
          });
        });

        setEbsSnapshotSpend(formattedData);
        setEbsSnapshotSpendReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEbsSnapshotSpendReqStatus);
      });
  };

  /**
   * @function fetchEbsOperationsUsageCost
   * @description Function to fetch the EBS Operations Usage Cost
   */
  const fetchEbsOperationsUsageCost = () => {
    setEbsOperationsUsageCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEbsOperationUsageCostQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ebsOperationsUsageCostDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
          })) ?? [];
        const operations = uniqBy(data, 'operation').map(
          (item) => item.operation
        );
        const datesList = enumerateDaysBetweenDates(
          ebsOperationsUsageCostDateRange,
          DATE_FORMAT,
          'day'
        );

        const formattedLeftAxisData: any[] = [];
        const formattedRightAxisData: any[] = [];
        datesList.forEach((date) => {
          const currentDateData = data.filter((item) => date === item.time);
          formattedRightAxisData.push({
            time: date,
            type: t('awsStorageDashboard.costSum'),
            value: sumBy(currentDateData, 'cost'),
          });

          operations.forEach((operation) => {
            const dataItem = currentDateData.find(
              (item) => item.operation === operation
            );
            formattedLeftAxisData.push({
              time: date,
              type: operation,
              value: dataItem?.cost ?? 0,
            });
          });
        });

        setEbsOperationsUsageCost({
          leftAxisData: formattedLeftAxisData,
          rightAxisData: formattedRightAxisData,
        });
        setEbsOperationsUsageCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEbsOperationsUsageCostReqStatus);
      });
  };

  /**
   * @function fetchEbsVolumesSpend
   * @description Function to fetch the EBS Volumes Spend
   */
  const fetchEbsVolumesSpend = () => {
    setEbsVolumesSpendReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEbsVolumesSpendQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ebsVolumesSpendDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data.map((item: any) => ({
            ...item,
            type: item.resourceId,
            value: Number(item.cost),
          })) ?? [];
        setEbsVolumesSpend(data);
        setEbsVolumesSpendReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEbsVolumesSpendReqStatus);
      });
  };

  /**
   * @function fetchStorageFsCostByAccounts
   * @description Function to fetch the Storage FS Usage Cost by Account
   */
  const fetchStorageFsCostByAccounts = () => {
    setStorageFsCostByAccountsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getStorageFsCostByAccountsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      storageFsCostByAccountsDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            month: moment(
              item.month,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
            cost: Number(item.cost),
            [UnitCostTimePeriod.DAILY]: Number(item[UnitCostTimePeriod.DAILY]),
            [UnitCostTimePeriod.HOURLY]: Number(
              item[UnitCostTimePeriod.HOURLY]
            ),
            [UnitCostTimePeriod.WEEKLY]: Number(
              item[UnitCostTimePeriod.WEEKLY]
            ),
          })) ?? [];
        const accounts = uniqBy(data, 'account').map((item) => item.account);
        const months = getMonthYearShortList(
          storageFsCostByAccountsDateRange[0],
          storageFsCostByAccountsDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          accounts.forEach((account) => {
            const dataItem = data.find(
              (item) => item.account === account && item.month === month
            );
            formattedData.push({
              time: month,
              type: account,
              value: dataItem?.cost ?? 0,
              [UnitCostTimePeriod.DAILY]:
                dataItem?.[UnitCostTimePeriod.DAILY] ?? 0,
              [UnitCostTimePeriod.HOURLY]:
                dataItem?.[UnitCostTimePeriod.HOURLY] ?? 0,
              [UnitCostTimePeriod.WEEKLY]:
                dataItem?.[UnitCostTimePeriod.WEEKLY] ?? 0,
              unitCostType: 'Average Unit Cost - ' + account,
            });
          });
        });
        setStorageFsCostByAccounts(formattedData);
        setStorageFsCostByAccountsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setStorageFsCostByAccountsReqStatus);
      });
  };

  /**
   * @function fetchStorageFsCostByRegions
   * @description Function to fetch the Storage FS Usage Cost by Region
   */
  const fetchStorageFsCostByRegions = () => {
    setStorageFsCostByRegionsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getStorageFsCostByRegionsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      storageFsCostByRegionsDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            month: moment(
              item.month,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
            cost: Number(item.cost),
          })) ?? [];
        const regions = uniqBy(data, 'region').map((item) => item.region);
        const months = getMonthYearShortList(
          storageFsCostByRegionsDateRange[0],
          storageFsCostByRegionsDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          regions.forEach((region) => {
            const dataItem = data.find(
              (item) => item.region === region && item.month === month
            );
            formattedData.push({
              time: month,
              type: region,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setStorageFsCostByRegions(formattedData);
        setStorageFsCostByRegionsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setStorageFsCostByRegionsReqStatus);
      });
  };

  /**
   * @function fetchStorageFsCostByProductCode
   * @description Function to fetch the Storage FS Usage Cost by Product Code
   */
  const fetchStorageFsCostByProductCode = () => {
    setStorageFsCostByProductCodeReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getStorageFsCostByProductCodeQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      storageFsCostByProductCodeDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            month: moment(
              item.month,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
            cost: Number(item.cost),
          })) ?? [];

        const productCodes = uniqBy(data, 'productCode').map(
          (item) => item.productCode
        );
        const months = getMonthYearShortList(
          storageFsCostByProductCodeDateRange[0],
          storageFsCostByProductCodeDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          productCodes.forEach((productCode) => {
            const dataItem = data.find(
              (item) => item.productCode === productCode && item.month === month
            );
            formattedData.push({
              time: month,
              type: productCode,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setStorageFsCostByProductCode(formattedData);
        setStorageFsCostByProductCodeReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setStorageFsCostByProductCodeReqStatus);
      });
  };

  /**
   * @function fetchStorageFsCostByUsageType
   * @description Function to fetch the Storage FS Usage Cost by Usage Type
   */
  const fetchStorageFsCostByUsageType = () => {
    setStorageFsCostByUsageTypeReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getStorageFsCostByUsageTypeQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      storageFsCostByUsageTypeDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            month: moment(
              item.month,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
            cost: Number(item.cost),
          })) ?? [];
        const usageTypes = uniqBy(data, 'usageType').map(
          (item) => item.usageType
        );
        const months = getMonthYearShortList(
          storageFsCostByUsageTypeDateRange[0],
          storageFsCostByUsageTypeDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          usageTypes.forEach((usageType) => {
            const dataItem = data.find(
              (item) => item.usageType === usageType && item.month === month
            );
            formattedData.push({
              time: month,
              type: usageType,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setStorageFsCostByUsageType(formattedData);
        setStorageFsCostByUsageTypeReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setStorageFsCostByUsageTypeReqStatus);
      });
  };

  /**
   * @function fetchStorageFsCostByOperation
   * @description Function to fetch the Storage FS Usage Cost by Operation
   */
  const fetchStorageFsCostByOperation = () => {
    setStorageFsCostByOperationReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getStorageFsCostByOperationQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      storageFsCostByOperationDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
          })) ?? [];
        const operations = uniqBy(data, 'operation').map(
          (item) => item.operation
        );
        const timeList = enumerateDaysBetweenDates(
          storageFsCostByOperationDateRange,
          DATE_FORMAT,
          'day'
        );

        const formattedData: any[] = [];
        timeList.forEach((time) => {
          operations.forEach((operation) => {
            const dataItem = data.find(
              (item) => item.operation === operation && item.time === time
            );
            formattedData.push({
              time,
              type: operation,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setStorageFsCostByOperation(formattedData);
        setStorageFsCostByOperationReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setStorageFsCostByOperationReqStatus);
      });
  };

  /**
   * @function getStoragePdfMetaData
   * @description Function to return the meta data for pdf export
   * @returns Object containing the pdf meta data
   */
  const getStoragePdfMetaData = () => {
    return {
      viewName: t('dashNav.storage'),
      fileName: selectedDashboard?.name!,
      heading: selectedDashboard?.name!,
      subtitle1: selectedDashboard?.connectorName!,
      subtitle2: selectedConnection?.dataSourceType!,
      provider: selectedDashboard?.connectorProvider!,
    };
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case StorageDashboardGraphs.EBS_SPEND_BY_ACCOUNTS:
        return (
          <StackedColumnCharts
            title={getSpendByAccountsLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={ebsSpendByAccounts}
            xField="time"
            seriesField="type"
            yTitle={t('awsStorageDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[accountsReqStatus, ebsSpendByAccountsReqStatus]}
            isTableView={ebsSpendByAccountsTableView}
            setIsTableView={setEbsSpendByAccountsTableView}
            dateRange={ebsSpendByAccountsDateRange}
            setDateRange={setEbsSpendByAccountsDateRange}
            pdfView={pdfView}
            columns={getAccountWithGroupingValuesColumns(
              getMonthYearShortList(
                ebsSpendByAccountsDateRange[0],
                ebsSpendByAccountsDateRange[1],
                MONTH_YEAR_FORMAT
              ),
              getAccountLabelByProvider(selectedDashboard!.connectorProvider)
            )}
            tableData={getUsageCostTableData(ebsSpendByAccounts, 'time')}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case StorageDashboardGraphs.EBS_VOLUME_COVERAGE:
        return (
          <DashboardPieDonutCharts
            title={getVolumeCoverageLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={ebsVolumeCoverage}
            requestStatus={[accountsReqStatus, ebsVolumeCoverageReqStatus]}
            isTableView={ebsVolumeCoverageTableView}
            setIsTableView={setEbsVolumeCoverageTableView}
            pdfView={pdfView}
            columns={getVolumesCoverageColumns(
              selectedDashboard!.connectorProvider
            )}
            tableData={insertIndex(ebsVolumeCoverage).map((item) => ({
              ...item,
              value: numberCommaSeparator(item.value),
            }))}
            selectedAccounts={selectedAccounts}
            suffixSymbol="GB-Mo"
          />
        );

      case StorageDashboardGraphs.EBS_STORAGE_MONTHLY_COST:
        return (
          <StackedColumnLineWithUnitCostCharts
            title={getStorageMonthlyCostLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={ebsStorageMonthlyCost}
            xField="type"
            requestStatus={[accountsReqStatus, ebsStorageMonthlyCostReqStatus]}
            isTableView={ebsStorageMonthlyCostTableView}
            setIsTableView={setEbsStorageMonthlyCostTableView}
            dateRange={ebsStorageMonthlyCostDateRange}
            setDateRange={setEbsStorageMonthlyCostDateRange}
            pdfView={pdfView}
            columns={getEbsStorageMonthlyCostColumns(
              ebsStorageMonthlyCostDateRange
            )}
            tableData={ebsStorageMonthlyCost.map((item) => ({
              ...item,
              value: currencySymbol + numberCommaSeparator(item.value),
            }))}
            selectedAccounts={selectedAccounts}
            unitCostTimePeriod={ebsStorageMonthlyUnitCostTimePeriod}
            setUnitCostTimePeriod={setEbsStorageMonthlyUnitCostTimePeriod}
          />
        );

      case StorageDashboardGraphs.EBS_STORAGE_SPEND:
        return (
          <StackedColumnCharts
            title={getStorageSpendLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={ebsStorageSpend}
            xField="time"
            seriesField="type"
            yTitle={t('awsStorageDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[accountsReqStatus, ebsStorageSpendReqStatus]}
            isTableView={ebsStorageSpendTableView}
            setIsTableView={setEbsStorageSpendTableView}
            dateRange={ebsStorageSpendDateRange}
            setDateRange={setEbsStorageSpendDateRange}
            pdfView={pdfView}
            columns={getStorageSpendColumns(
              selectedDashboard!.connectorProvider,
              ebsStorageSpendDateRange
            )}
            tableData={getUsageCostTableData(ebsStorageSpend, 'time')}
            selectedAccounts={selectedAccounts}
            isStack={false}
            isGroup={true}
            prefixSymbol={currencySymbol}
          />
        );

      case StorageDashboardGraphs.EBS_OPERATION_USAGE_COST:
        return (
          <StackedDualAxisCharts
            title={t('graphHeadings.ebsOperationsUsageCost')}
            graph={graphName}
            data={ebsOperationsUsageCost}
            xTitle={t('awsStorageDashboard.date')}
            leftYTitle={t('awsStorageDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            syncYAxis={true}
            requestStatus={[accountsReqStatus, ebsOperationsUsageCostReqStatus]}
            isTableView={ebsOperationsUsageCostTableView}
            setIsTableView={setEbsOperationsUsageCostTableView}
            dateRange={ebsOperationsUsageCostDateRange}
            setDateRange={setEbsOperationsUsageCostDateRange}
            pdfView={pdfView}
            columns={getEbsOperationUsageCostColumns(
              ebsOperationsUsageCostDateRange
            )}
            tableData={getDualAxisCostByTypeTableData(ebsOperationsUsageCost)}
            selectedAccounts={selectedAccounts}
            maxMonths={3}
          />
        );

      case StorageDashboardGraphs.EBS_SNAPSHOT_SPEND:
        return (
          <StackedColumnLineWithUnitCostCharts
            title={t('graphHeadings.ebsSnapshotSpend')}
            graph={graphName}
            data={ebsSnapshotSpend}
            xField="type"
            requestStatus={[accountsReqStatus, ebsSnapshotSpendReqStatus]}
            isTableView={ebsSnapshotSpendTableView}
            setIsTableView={setEbsSnapshotSpendTableView}
            dateRange={ebsSnapshotSpendDateRange}
            setDateRange={setEbsSnapshotSpendDateRange}
            pdfView={pdfView}
            columns={getAccountWithGroupingValuesColumns(
              getMonthYearShortList(
                ebsSnapshotSpendDateRange[0],
                ebsSnapshotSpendDateRange[1],
                MONTH_YEAR_FORMAT
              )
            )}
            tableData={getUsageCostTableData(ebsSnapshotSpend, 'time')}
            selectedAccounts={selectedAccounts}
            unitCostTimePeriod={ebsSnapshotSpendUnitCostTimePeriod}
            setUnitCostTimePeriod={setEbsSnapshotSpendUnitCostTimePeriod}
          />
        );

      case StorageDashboardGraphs.EBS_VOLUMES_SPEND:
        return (
          <StackedColumnCharts
            title={t('graphHeadings.ebsVolumesSpend')}
            graph={graphName}
            data={ebsVolumesSpend}
            xField="type"
            seriesField="type"
            xTitle={t('awsStorageDashboard.resourceId')}
            yTitle={t('awsStorageDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[accountsReqStatus, ebsVolumesSpendReqStatus]}
            isTableView={ebsVolumesSpendTableView}
            setIsTableView={setEbsVolumesSpendTableView}
            dateRange={ebsVolumesSpendDateRange}
            setDateRange={setEbsVolumesSpendDateRange}
            pdfView={pdfView}
            columns={getEbsVolumesSpendColumns()}
            tableData={insertIndex(ebsVolumesSpend).map((item) => ({
              ...item,
              value: currencySymbol + numberCommaSeparator(item.value),
            }))}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_ACCOUNT:
        return (
          <StackedColumnLineWithUnitCostCharts
            title={getStorageFsUsageCostLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={storageFsCostByAccounts}
            xField="type"
            requestStatus={[
              accountsReqStatus,
              storageFsCostByAccountsReqStatus,
            ]}
            isTableView={storageFsCostByAccountsTableView}
            setIsTableView={setStorageFsCostByAccountsTableView}
            dateRange={storageFsCostByAccountsDateRange}
            setDateRange={setStorageFsCostByAccountsDateRange}
            pdfView={pdfView}
            columns={getAccountWithGroupingValuesColumns(
              getMonthYearShortList(
                storageFsCostByAccountsDateRange[0],
                storageFsCostByAccountsDateRange[1],
                MONTH_YEAR_FORMAT
              ),
              getAccountLabelByProvider(selectedDashboard!.connectorProvider)
            )}
            tableData={getUsageCostTableData(storageFsCostByAccounts, 'time')}
            selectedAccounts={selectedAccounts}
            unitCostTimePeriod={storageFsCostByAccountsUnitCostPeriod}
            setUnitCostTimePeriod={setStorageFsCostByAccountsUnitCostPeriod}
          />
        );

      case StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_REGION:
        return (
          <StackedColumnCharts
            title={getStorageFsCostByRegionsLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={storageFsCostByRegions}
            xField="time"
            seriesField="type"
            yTitle={t('awsStorageDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[accountsReqStatus, storageFsCostByRegionsReqStatus]}
            isTableView={storageFsCostByRegionsTableView}
            setIsTableView={setStorageFsCostByRegionsTableView}
            dateRange={storageFsCostByRegionsDateRange}
            setDateRange={setStorageFsCostByRegionsDateRange}
            pdfView={pdfView}
            columns={getRegionWithGroupingValuesColumns(
              getMonthYearShortList(
                storageFsCostByRegionsDateRange[0],
                storageFsCostByRegionsDateRange[1],
                MONTH_YEAR_FORMAT
              )
            )}
            tableData={getUsageCostTableData(storageFsCostByRegions, 'time')}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE:
        return (
          <StackedColumnCharts
            title={t('graphHeadings.storageFsUsageCostByProductCode')}
            graph={graphName}
            data={storageFsCostByProductCode}
            xField="time"
            seriesField="type"
            yTitle={t('awsStorageDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[
              accountsReqStatus,
              storageFsCostByProductCodeReqStatus,
            ]}
            isTableView={storageFsCostByProductCodeTableView}
            setIsTableView={setStorageFsCostByProductCodeTableView}
            dateRange={storageFsCostByProductCodeDateRange}
            setDateRange={setStorageFsCostByProductCodeDateRange}
            pdfView={pdfView}
            columns={getAccountWithGroupingValuesColumns(
              getMonthYearShortList(
                storageFsCostByProductCodeDateRange[0],
                storageFsCostByProductCodeDateRange[1],
                MONTH_YEAR_FORMAT
              ),
              t('awsStorageDashboard.productCode')
            )}
            tableData={getUsageCostTableData(
              storageFsCostByProductCode,
              'time'
            )}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_USAGE_TYPE:
        return (
          <StackedColumnCharts
            title={t('graphHeadings.storageFsUsageCostByUsageType')}
            graph={graphName}
            data={storageFsCostByUsageType}
            xField="time"
            seriesField="type"
            yTitle={t('awsStorageDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[
              accountsReqStatus,
              storageFsCostByUsageTypeReqStatus,
            ]}
            isTableView={storageFsCostByUsageTypeTableView}
            setIsTableView={setStorageFsCostByUsageTypeTableView}
            dateRange={storageFsCostByUsageTypeDateRange}
            setDateRange={setStorageFsCostByUsageTypeDateRange}
            pdfView={pdfView}
            columns={getAccountWithGroupingValuesColumns(
              getMonthYearShortList(
                storageFsCostByUsageTypeDateRange[0],
                storageFsCostByUsageTypeDateRange[1],
                MONTH_YEAR_FORMAT
              ),
              t('awsStorageDashboard.usageType')
            )}
            tableData={getUsageCostTableData(storageFsCostByUsageType, 'time')}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_OPERATION:
        return (
          <StackedColumnCharts
            title={getStorageFsCostByOperationLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={storageFsCostByOperation}
            xField="time"
            seriesField="type"
            yTitle={t('awsStorageDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[
              accountsReqStatus,
              storageFsCostByOperationReqStatus,
            ]}
            isTableView={storageFsCostByOperationTableView}
            setIsTableView={setStorageFsCostByOperationTableView}
            dateRange={storageFsCostByOperationDateRange}
            setDateRange={setStorageFsCostByOperationDateRange}
            pdfView={pdfView}
            columns={getAccountWithGroupingValuesColumns(
              enumerateDaysBetweenDates(
                storageFsCostByOperationDateRange,
                DATE_FORMAT,
                'day'
              ),
              getStorageFsUsageCostByOperationColumnLabel(
                selectedDashboard!.connectorProvider
              )
            )}
            tableData={getUsageCostTableData(storageFsCostByOperation, 'time')}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
            maxMonths={3}
          />
        );
    }
  };

  return (
    <div>
      <ServiceViewsControls
        accountsProps={{
          selectedAccounts,
          setSelectedAccounts,
          accountsReqStatus,
          setAccountsReqStatus,
        }}
      />
      <div className="aws-storage-container inner-dashboard-content flex flex-column flex-gap-24">
        <TagFilters />
        <Row gutter={24}>
          <Col span={12}>
            {getGraphComponent(StorageDashboardGraphs.EBS_SPEND_BY_ACCOUNTS)}
          </Col>
          <Col span={12}>
            {getGraphComponent(StorageDashboardGraphs.EBS_VOLUME_COVERAGE)}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            {getGraphComponent(StorageDashboardGraphs.EBS_STORAGE_MONTHLY_COST)}
          </Col>
          <Col span={12}>
            {getGraphComponent(StorageDashboardGraphs.EBS_STORAGE_SPEND)}
          </Col>
        </Row>
        {hasChartBasedOnProvider(
          selectedDashboard!.connectorProvider,
          StorageDashboardGraphs.EBS_OPERATION_USAGE_COST
        ) && (
          <div>
            {getGraphComponent(StorageDashboardGraphs.EBS_OPERATION_USAGE_COST)}
            <Row gutter={24}>
              <Col span={12}>
                {getGraphComponent(StorageDashboardGraphs.EBS_SNAPSHOT_SPEND)}
              </Col>
              <Col span={12}>
                {getGraphComponent(StorageDashboardGraphs.EBS_VOLUMES_SPEND)}
              </Col>
            </Row>
          </div>
        )}
        <Row gutter={24}>
          {hasChartBasedOnProvider(
            selectedDashboard!.connectorProvider,
            StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_ACCOUNT
          ) && (
            <Col span={12}>
              {getGraphComponent(
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_ACCOUNT
              )}
            </Col>
          )}
          <Col
            span={
              hasChartBasedOnProvider(
                selectedDashboard!.connectorProvider,
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_ACCOUNT
              )
                ? 12
                : 24
            }
          >
            {getGraphComponent(
              StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_REGION
            )}
          </Col>
        </Row>
        {hasChartBasedOnProvider(
          selectedDashboard!.connectorProvider,
          StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE
        ) && (
          <Row gutter={24}>
            <Col span={12}>
              {getGraphComponent(
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE
              )}
            </Col>
            <Col span={12}>
              {getGraphComponent(
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_USAGE_TYPE
              )}
            </Col>
          </Row>
        )}
        {getGraphComponent(
          StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_OPERATION
        )}
      </div>
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getStoragePdfMetaData()}
          pdfContent={[
            {
              element: getGraphComponent(
                StorageDashboardGraphs.EBS_SPEND_BY_ACCOUNTS,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: StorageDashboardGraphs.EBS_SPEND_BY_ACCOUNTS,
              column: modifyToExportColumns(
                getAccountWithGroupingValuesColumns(
                  getMonthYearShortList(
                    ebsSpendByAccountsDateRange[0],
                    ebsSpendByAccountsDateRange[1],
                    MONTH_YEAR_FORMAT
                  ),
                  getAccountLabelByProvider(
                    selectedDashboard!.connectorProvider
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(ebsSpendByAccounts, 'time')
              ),
              tableName: getSpendByAccountsLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: ebsSpendByAccountsTableView,
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.EBS_VOLUME_COVERAGE,
                true
              ),
              contentType: CHART_TYPES.DOUGHNUT_CHART,
              graphName: StorageDashboardGraphs.EBS_VOLUME_COVERAGE,
              column: modifyToExportColumns(
                getVolumesCoverageColumns(selectedDashboard!.connectorProvider)
              ),
              body: insertIndex(ebsVolumeCoverage).map((item) => ({
                ...item,
                value: numberCommaSeparator(item.value),
              })),
              tableName: getVolumeCoverageLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: ebsVolumeCoverageTableView,
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.EBS_STORAGE_MONTHLY_COST,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: StorageDashboardGraphs.EBS_STORAGE_MONTHLY_COST,
              column: modifyToExportColumns(
                getEbsStorageMonthlyCostColumns(ebsStorageMonthlyCostDateRange)
              ),
              body: insertIndex(
                ebsStorageMonthlyCost.map((item) => ({
                  ...item,
                  value: currencySymbol + numberCommaSeparator(item.value),
                }))
              ),
              tableName: getStorageMonthlyCostLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: ebsStorageMonthlyCostTableView,
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.EBS_STORAGE_SPEND,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: StorageDashboardGraphs.EBS_STORAGE_SPEND,
              column: modifyToExportColumns(
                getStorageSpendColumns(
                  selectedDashboard!.connectorProvider,
                  ebsStorageSpendDateRange
                )
              ),
              body: insertIndex(getUsageCostTableData(ebsStorageSpend, 'time')),
              tableName: getStorageSpendLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: ebsStorageSpendTableView,
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.EBS_OPERATION_USAGE_COST,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: StorageDashboardGraphs.EBS_OPERATION_USAGE_COST,
              column: modifyToExportColumns(
                getEbsOperationUsageCostColumns(ebsOperationsUsageCostDateRange)
              ),
              body: insertIndex(
                getDualAxisCostByTypeTableData(ebsOperationsUsageCost)
              ),
              tableName: t('graphHeadings.ebsOperationsUsageCost'),
              isTableView: ebsOperationsUsageCostTableView,
              excludeInGlobalDownload: !hasChartBasedOnProvider(
                selectedDashboard!.connectorProvider,
                StorageDashboardGraphs.EBS_OPERATION_USAGE_COST
              ),
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.EBS_SNAPSHOT_SPEND,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: StorageDashboardGraphs.EBS_SNAPSHOT_SPEND,
              column: modifyToExportColumns(
                getAccountWithGroupingValuesColumns(
                  getMonthYearShortList(
                    ebsSnapshotSpendDateRange[0],
                    ebsSnapshotSpendDateRange[1],
                    MONTH_YEAR_FORMAT
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(ebsSnapshotSpend, 'time')
              ),
              tableName: t('graphHeadings.ebsSnapshotSpend'),
              isTableView: ebsSnapshotSpendTableView,
              excludeInGlobalDownload: !hasChartBasedOnProvider(
                selectedDashboard!.connectorProvider,
                StorageDashboardGraphs.EBS_SNAPSHOT_SPEND
              ),
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.EBS_VOLUMES_SPEND,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: StorageDashboardGraphs.EBS_VOLUMES_SPEND,
              column: modifyToExportColumns(getEbsVolumesSpendColumns()),
              body: insertIndex(ebsVolumesSpend).map((item) => ({
                ...item,
                value: currencySymbol + numberCommaSeparator(item.value),
              })),
              tableName: t('graphHeadings.ebsVolumesSpend'),
              isTableView: ebsVolumesSpendTableView,
              excludeInGlobalDownload: !hasChartBasedOnProvider(
                selectedDashboard!.connectorProvider,
                StorageDashboardGraphs.EBS_VOLUMES_SPEND
              ),
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_ACCOUNT,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName:
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_ACCOUNT,
              column: modifyToExportColumns(
                getAccountWithGroupingValuesColumns(
                  getMonthYearShortList(
                    storageFsCostByAccountsDateRange[0],
                    storageFsCostByAccountsDateRange[1],
                    MONTH_YEAR_FORMAT
                  ),
                  getAccountLabelByProvider(
                    selectedDashboard!.connectorProvider
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(storageFsCostByAccounts, 'time')
              ),
              tableName: getStorageFsUsageCostLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: storageFsCostByAccountsTableView,
              excludeInGlobalDownload: !hasChartBasedOnProvider(
                selectedDashboard!.connectorProvider,
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_ACCOUNT
              ),
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_REGION,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_REGION,
              column: modifyToExportColumns(
                getRegionWithGroupingValuesColumns(
                  getMonthYearShortList(
                    storageFsCostByRegionsDateRange[0],
                    storageFsCostByRegionsDateRange[1],
                    MONTH_YEAR_FORMAT
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(storageFsCostByRegions, 'time')
              ),
              tableName: getStorageFsCostByRegionsLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: storageFsCostByRegionsTableView,
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName:
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE,
              column: modifyToExportColumns(
                getAccountWithGroupingValuesColumns(
                  getMonthYearShortList(
                    storageFsCostByProductCodeDateRange[0],
                    storageFsCostByProductCodeDateRange[1],
                    MONTH_YEAR_FORMAT
                  ),
                  t('awsStorageDashboard.productCode')
                )
              ),
              body: insertIndex(
                getUsageCostTableData(storageFsCostByProductCode, 'time')
              ),
              tableName: t('graphHeadings.storageFsUsageCostByProductCode'),
              isTableView: storageFsCostByProductCodeTableView,
              excludeInGlobalDownload: !hasChartBasedOnProvider(
                selectedDashboard!.connectorProvider,
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE
              ),
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_USAGE_TYPE,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName:
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_USAGE_TYPE,
              column: modifyToExportColumns(
                getAccountWithGroupingValuesColumns(
                  getMonthYearShortList(
                    storageFsCostByUsageTypeDateRange[0],
                    storageFsCostByUsageTypeDateRange[1],
                    MONTH_YEAR_FORMAT
                  ),
                  t('awsStorageDashboard.usageType')
                )
              ),
              body: insertIndex(
                getUsageCostTableData(storageFsCostByUsageType, 'time')
              ),
              tableName: t('graphHeadings.storageFsUsageCostByUsageType'),
              isTableView: storageFsCostByUsageTypeTableView,
              excludeInGlobalDownload: !hasChartBasedOnProvider(
                selectedDashboard!.connectorProvider,
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_USAGE_TYPE
              ),
            },
            {
              element: getGraphComponent(
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_OPERATION,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName:
                StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_OPERATION,
              column: modifyToExportColumns(
                getAccountWithGroupingValuesColumns(
                  enumerateDaysBetweenDates(
                    storageFsCostByOperationDateRange,
                    DATE_FORMAT,
                    'day'
                  ),
                  getStorageFsUsageCostByOperationColumnLabel(
                    selectedDashboard!.connectorProvider
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(storageFsCostByOperation, 'time')
              ),
              tableName: getStorageFsCostByOperationLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: storageFsCostByOperationTableView,
            },
          ]}
        />
      )}
    </div>
  );
};

export default StorageDashboard;
