import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import axios from 'axios';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import {
  generateGraphColors,
  getAWSTagFiltersData,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  QUERY_VALUES,
  REQUEST_STATUS,
} from 'constants/requestBody';
import {
  MonthlyCostType,
  CreditCostMetricType,
  CreditDistributionByLocationColorTypes,
  MonthlyCreditCostMetricType,
} from 'types/dataTypes';
import { getChartData } from 'utils/services';
import { onApiCallError, useNull } from 'utils/handleErrors';
import { CreditComparisonListType } from 'types/dashboard';
import NewComparisonCard from 'components/NewComparisonCard';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import {
  formatDateFieldByProvider,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  TIMESTAMP_FORMAT_PERSIST_Z,
  YEAR_HYPHEN_MONTH,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { PROVIDER } from 'constants/cloudProviders';
import { getPdfMetaData } from 'pages/OverviewPage/components/ConnectionsDashboard/utils';

import {
  CostAndCreditBreakDownExportColumns,
  CreditDiscountBreakdownExportColumns,
  CreditDistributionByRegionExportColumns,
} from './constants';
import { CreditDistributionByRegion } from './components/CreditDistributionByRegion';
import { CostAndCreditBreakDown } from './components/CostAndCreditBreakDown';
import { CreditDiscountBreakdown } from './components/CreditDiscountBreakdown';
import { getAWSCreditSummaryExcelData } from './utils/exportToExcel';

const AWSCreditSummaryDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    expandedGraphName,
    tableViewEnabled,
    selectedDashboard,
    selectedConnection,
    pdfDownloadMode,
    showExpandGraphModal,
    tagsFilters,
    selectedDashboardView,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [creditSummaryList, setCreditSummaryList] = useState<
    CreditComparisonListType[]
  >([]);

  //States for YTD Cards
  const [ytdBundledDiscount, setYtdBundledDiscount] = useState(0);
  const [ytdBundledDiscountRequestStatus, setYtdBundledDiscountRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [ytdCredit, setYtdCredit] = useState(0);
  const [ytdCreditRequestStatus, setYtdCreditRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [ytdDiscountedUsage, setYtdDiscountedUsage] = useState(0);
  const [ytdDiscountedUsageRequestStatus, setYtdDiscountedUsageRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  //Credit Distribution by Location states
  const [creditDistributionByRegionData, setCreditDistributionByRegionData] =
    useState<CreditDistributionByLocationColorTypes[]>([]);
  const [
    creditDistributionByRegionRequestStatus,
    setCreditDistributionByRegionRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [costByRegionStartDate, setCostByRegionStartDate] = useState(
    moment().startOf('year').format(HYPHEN_DATE_FORMAT)
  );
  const [costByRegionEndDate, setCostByRegionEndDate] = useState(
    moment().format(HYPHEN_DATE_FORMAT)
  );

  //Cost Credit Breakdown states
  const [costCreditBreakdownCostData, setCostCreditBreakdownCostData] =
    useState<number[]>([]);
  const [
    costCreditBreakdownDataRequestStatus,
    setCostCreditBreakdownDataRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [costCreditBreakdownCreditData, setCostCreditBreakdownCreditData] =
    useState<number[]>([]);
  const [costCreditBreakdownLabels, setCostCreditBreakdownLabels] = useState<
    string[]
  >([]);
  const [costAndCreditStartDate, setCostAndCreditStartDate] = useState(
    moment().subtract(1, 'year').startOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [costAndCreditEndDate, setCostAndCreditEndDate] = useState(
    moment().startOf('month').format(HYPHEN_DATE_FORMAT)
  );

  //Credit or Discount Breakdown states
  const [monthlyCreditByCostTypeData, setMonthlyCreditByCostTypeData] =
    useState<MonthlyCreditCostMetricType[]>([]);
  const [creditDiscountDataRequestStatus, setCreditDiscountDataRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [creditDiscountBreakdownLabels, setCreditDiscountBreakdownLabels] =
    useState<string[]>([]);
  const [creditDiscountStartDate, setCreditDiscountStartDate] = useState(
    moment().subtract(11, 'month').startOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [creditDiscountEndDate, setCreditDiscountEndDate] = useState(
    moment().startOf('month').format(HYPHEN_DATE_FORMAT)
  );

  // Table view states
  const [
    isCostAndCreditBreakdownTableView,
    setIsCostAndCreditBreakdownTableView,
  ] = useState(false);
  const [
    isCreditDiscountBreakdownTableView,
    setIsCreditDiscountBreakdownTableView,
  ] = useState(false);

  useEffect(() => {
    setMonthlyCreditByCostTypeData([]);
    setYtdBundledDiscount(0);
    if (selectedDashboard) {
      getAWSYTDBundledDiscount();
      getAWSYTDCredit();
      getAWSYTDDiscountedUsage();
    }
  }, [selectedDashboard, tagsFilters]);

  useEffect(() => {
    setCreditDistributionByRegionData([]);
    if (selectedDashboard) {
      getAWSCreditDistributionByRegion();
    }
  }, [
    selectedDashboard,
    costByRegionStartDate,
    costByRegionEndDate,
    tagsFilters,
  ]);

  useEffect(() => {
    setCostCreditBreakdownLabels([]);
    setCostCreditBreakdownCostData([]);
    setCostCreditBreakdownCreditData([]);
    if (selectedDashboard) {
      getAwsCostAndCreditBreakdownData();
    }
  }, [
    selectedDashboard,
    costAndCreditStartDate,
    costAndCreditEndDate,
    tagsFilters,
  ]);

  useEffect(() => {
    setCreditDiscountBreakdownLabels([]);
    setMonthlyCreditByCostTypeData([]);
    setMonthlyCreditByCostTypeData([]);
    if (selectedDashboard) {
      getAWSCreditInCreditOrDiscountBreakdownData();
      getAWSBundledDiscountInCreditOrDiscountBreakdownData();
    }
  }, [
    selectedDashboard,
    creditDiscountStartDate,
    creditDiscountEndDate,
    tagsFilters,
  ]);

  useEffect(() => {
    setCreditSummaryList([
      {
        credit: ytdBundledDiscount,
        creditType: t('awsCreditComparisonCard.ytdBundledDiscount'),
        requestStatus: ytdBundledDiscountRequestStatus,
      },
      {
        credit: ytdCredit,
        creditType: t('awsCreditComparisonCard.ytdCredit'),
        requestStatus: ytdCreditRequestStatus,
      },
      {
        credit: ytdDiscountedUsage,
        creditType: t('awsCreditComparisonCard.ytdDiscountedUsage'),
        requestStatus: ytdDiscountedUsageRequestStatus,
      },
    ]);
  }, [
    ytdBundledDiscount,
    ytdCredit,
    ytdDiscountedUsage,
    ytdBundledDiscountRequestStatus,
    ytdCreditRequestStatus,
    ytdDiscountedUsageRequestStatus,
  ]);

  useEffect(() => {
    setIsCostAndCreditBreakdownTableView(tableViewEnabled);
    setIsCreditDiscountBreakdownTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    if (selectedDashboard) {
      dispatch(
        setExportToExcelData(
          getAWSCreditSummaryExcelData(
            selectedDashboard.connectorName,
            creditDistributionByRegionData,
            costCreditBreakdownLabels.map((value, index) => {
              return {
                month: value,
                cost: costCreditBreakdownCostData[index],
                unblendedCost: costCreditBreakdownCreditData[index],
              };
            }),
            creditDiscountBreakdownLabels.map((value, index) => {
              let thisMonthData: any = {
                month: value,
                key: index,
              };
              monthlyCreditByCostTypeData.forEach((costData) => {
                if (costData.creditData)
                  thisMonthData[costData.costMetricType] = Number(
                    costData.creditData[index]
                  );
                else thisMonthData[costData.costMetricType] = 0;
              });
              return thisMonthData;
            }),
            monthlyCreditByCostTypeData
          )
        )
      );
    }
  }, [
    selectedDashboard,
    creditDistributionByRegionData,
    costCreditBreakdownCostData,
    costCreditBreakdownCreditData,
    monthlyCreditByCostTypeData,
  ]);

  const onChangeCostByRegionDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostByRegionStartDate(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(HYPHEN_DATE_FORMAT)
    );
    setCostByRegionEndDate(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(HYPHEN_DATE_FORMAT)
    );
  };

  const onChangeCostAndCreditDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostAndCreditStartDate(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(HYPHEN_DATE_FORMAT)
    );
    setCostAndCreditEndDate(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(HYPHEN_DATE_FORMAT)
    );
  };

  const onChangeCreditDiscountDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCreditDiscountStartDate(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(HYPHEN_DATE_FORMAT)
    );
    setCreditDiscountEndDate(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(HYPHEN_DATE_FORMAT)
    );
  };

  const getAWSYTDBundledDiscount = () => {
    setYtdBundledDiscountRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_LINE_ITEM_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.EQUALS,
              value: isStaticData
                ? QUERY_VALUES.USAGE
                : QUERY_VALUES.BUNDLED_DISCOUNT,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                isStaticData
                  ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
                  : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: isStaticData
                ? moment().startOf('year').format(HYPHEN_DATE_FORMAT)
                : moment().startOf('year').format(YEAR_MONTH_WITHOUT_ZERO),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                isStaticData
                  ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
                  : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: isStaticData
                ? moment().format(HYPHEN_DATE_FORMAT)
                : moment().format(YEAR_MONTH_WITHOUT_ZERO),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };
    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        setYtdBundledDiscount(Number(Number(res?.data?.[0].cost).toFixed(2)));
        setYtdBundledDiscountRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setYtdBundledDiscountRequestStatus);
      });
  };

  const getAWSYTDCredit = () => {
    setYtdCreditRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_LINE_ITEM_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.EQUALS,
              value: isStaticData
                ? QUERY_VALUES.ADJUSTMENT
                : QUERY_VALUES.CREDIT,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                isStaticData
                  ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
                  : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: isStaticData
                ? moment().startOf('year').format(HYPHEN_DATE_FORMAT)
                : moment().startOf('year').format(YEAR_MONTH_WITHOUT_ZERO),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                isStaticData
                  ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
                  : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: isStaticData
                ? moment().format(HYPHEN_DATE_FORMAT)
                : moment().format(YEAR_MONTH_WITHOUT_ZERO),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };
    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        setYtdCredit(Number(Number(res?.data?.[0].cost).toFixed(2)));
        setYtdCreditRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setYtdCreditRequestStatus);
      });
  };

  const getAWSYTDDiscountedUsage = () => {
    setYtdDiscountedUsageRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_LINE_ITEM_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: isStaticData ? COMPARATORS.EQUALS : COMPARATORS.IN,
              value: isStaticData
                ? QUERY_VALUES.PURCHASE
                : QUERY_VALUES.DISCOUNTED_USAGE,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                isStaticData
                  ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
                  : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: isStaticData
                ? moment().startOf('year').format(HYPHEN_DATE_FORMAT)
                : moment().startOf('year').format(YEAR_MONTH_WITHOUT_ZERO),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                isStaticData
                  ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
                  : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: isStaticData
                ? moment().format(HYPHEN_DATE_FORMAT)
                : moment().format(YEAR_MONTH_WITHOUT_ZERO),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };
    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        setYtdDiscountedUsage(Number(Number(res?.data?.[0].cost).toFixed(2)));
        setYtdDiscountedUsageRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setYtdDiscountedUsageRequestStatus);
      });
  };

  const getAWSCreditDistributionByRegion = () => {
    setCreditDistributionByRegionRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'credit',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'region',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PRODUCT_REGION,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'credit',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['region'],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_LINE_ITEM_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.IN,
              value: isStaticData
                ? QUERY_VALUES.CREDIT_BREAKDOWN_FOCUS
                : QUERY_VALUES.CREDIT_BREAKDOWN,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costByRegionStartDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costByRegionEndDate,
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const colors = generateGraphColors(res?.data?.length);
        setCreditDistributionByRegionData(
          res?.data.map((item: any, index: any) => ({
            ...item,
            color: colors[index],
          })) || []
        );
        setCreditDistributionByRegionRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCreditDistributionByRegionRequestStatus);
      });
  };

  const getAwsCostAndCreditBreakdownData = () => {
    setCostCreditBreakdownDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const costRequestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            isStaticData
              ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
              : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costAndCreditStartDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costAndCreditEndDate,
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    const creditRequestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'invoiceMonth',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            isStaticData
              ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
              : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['invoiceMonth'],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_LINE_ITEM_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.IN,
              value: isStaticData
                ? QUERY_VALUES.CREDIT_BREAKDOWN_FOCUS
                : QUERY_VALUES.CREDIT_BREAKDOWN,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costAndCreditStartDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costAndCreditStartDate,
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    const requests = [
      getChartData(costRequestBody, selectedDashboard!.connectorId).catch(
        useNull
      ),
      getChartData(creditRequestBody, selectedDashboard!.connectorId).catch(
        useNull
      ),
    ];

    axios
      .all(requests)
      .then((responses: any[]) => {
        const labels: string[] = getMonthYearShortList(
          costAndCreditStartDate,
          costAndCreditEndDate
        );

        const costData: number[] = [];
        const creditData: number[] = [];
        labels.forEach((month) => {
          const costItem = responses?.[0]?.data?.find(
            (item: MonthlyCostType) =>
              moment(item.month, YEAR_HYPHEN_MONTH).format(MONTH_YEAR_SHORT) ===
              month
          );
          costData.push(Number(costItem?.cost ?? 0));
          const creditItem = responses?.[1]?.data?.find(
            (item: MonthlyCostType) =>
              moment(item.month, YEAR_HYPHEN_MONTH).format(MONTH_YEAR_SHORT) ===
              month
          );
          creditData.push(Number(creditItem?.cost ?? 0));
        });
        setCostCreditBreakdownLabels(labels);
        setCostCreditBreakdownCostData(costData);
        setCostCreditBreakdownCreditData(creditData);
        setCostCreditBreakdownDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((error) => {
        onApiCallError(error, false, setCostCreditBreakdownDataRequestStatus);
      });
  };

  const getAWSBundledDiscountInCreditOrDiscountBreakdownData = () => {
    setCreditDiscountDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'credit',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            isStaticData
              ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
              : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'credit',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_LINE_ITEM_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.EQUALS,
              value: isStaticData
                ? QUERY_VALUES.USAGE
                : QUERY_VALUES.BUNDLED_DISCOUNT,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditDiscountStartDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditDiscountEndDate,
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        let labels: string[] = getMonthYearShortList(
          creditDiscountStartDate,
          creditDiscountEndDate
        );
        let bundledDiscountData: number[] = Array(labels.length).fill(0);
        res?.data?.forEach((item: CreditCostMetricType) => {
          let index = costCreditBreakdownLabels.indexOf(
            moment(
              item.month,
              isStaticData ? TIMESTAMP_FORMAT_PERSIST_Z : YEAR_HYPHEN_MONTH
            ).format(MONTH_YEAR_SHORT)
          );
          bundledDiscountData[index] = item.credit;
        });

        let creditByMonth: MonthlyCreditCostMetricType[] = [
          {
            costMetricType: 'BundledDiscount',
            creditData: bundledDiscountData,
          },
          {
            costMetricType: 'Credit',
            creditData:
              monthlyCreditByCostTypeData.find(
                (item) => item.costMetricType === 'Credit'
              )?.creditData ?? Array(labels.length).fill(0),
          },
        ];

        setCreditDiscountBreakdownLabels(labels);
        setMonthlyCreditByCostTypeData(creditByMonth);
        setCreditDiscountDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, false, setCreditDiscountDataRequestStatus)
      );
  };

  const getAWSCreditInCreditOrDiscountBreakdownData = () => {
    setCreditDiscountDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'credit',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            isStaticData
              ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
              : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'credit',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_LINE_ITEM_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.EQUALS,
              value: isStaticData
                ? QUERY_VALUES.ADJUSTMENT
                : QUERY_VALUES.CREDIT,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditDiscountStartDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditDiscountEndDate,
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        let labels: string[] = getMonthYearShortList(
          creditDiscountStartDate,
          creditDiscountEndDate
        );
        let creditData: number[] = Array(12).fill(0);

        res?.data?.forEach((item: CreditCostMetricType) => {
          let index = costCreditBreakdownLabels.indexOf(
            moment(
              item.month,
              isStaticData ? TIMESTAMP_FORMAT_PERSIST_Z : YEAR_HYPHEN_MONTH
            ).format(MONTH_YEAR_SHORT)
          );
          creditData[index] = item.credit;
        });

        const costMetricType = isStaticData
          ? QUERY_VALUES.USAGE
          : QUERY_VALUES.BUNDLED_DISCOUNT;
        let creditByMonth: MonthlyCreditCostMetricType[] = [
          {
            costMetricType: 'BundledDiscount',
            creditData: monthlyCreditByCostTypeData.find(
              (item) => item.costMetricType === costMetricType
            )?.creditData!,
          },
          { costMetricType: 'Credit', creditData: creditData },
        ];

        setCreditDiscountBreakdownLabels(labels);
        setMonthlyCreditByCostTypeData(creditByMonth);
        setCreditDiscountDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, false, setCreditDiscountDataRequestStatus)
      );
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'credit-distribution-by-location':
        return (
          <CreditDistributionByRegion
            requestStatus={creditDistributionByRegionRequestStatus}
            creditDistributionByRegionData={creditDistributionByRegionData}
            pdfView={pdfView}
            costByRegionStartDate={costByRegionStartDate}
            costByRegionEndDate={costByRegionEndDate}
            onChangeCostByRegionDateRange={onChangeCostByRegionDateRange}
          />
        );
      case 'cost-and-credit-breakdown':
        return (
          <CostAndCreditBreakDown
            requestStatus={[costCreditBreakdownDataRequestStatus]}
            costCreditBreakdownLabels={costCreditBreakdownLabels}
            costCreditBreakdownCostData={costCreditBreakdownCostData}
            costCreditBreakdownCreditData={costCreditBreakdownCreditData}
            isCostAndCreditBreakdownTableView={
              isCostAndCreditBreakdownTableView
            }
            setCostAndCreditBreakdownTableView={
              setIsCostAndCreditBreakdownTableView
            }
            pdfView={pdfView}
            costAndCreditStartDate={costAndCreditStartDate}
            costAndCreditEndDate={costAndCreditEndDate}
            onChangeCostAndCreditDateRange={onChangeCostAndCreditDateRange}
          />
        );
      case 'credit-discount-breakdown':
        return (
          <CreditDiscountBreakdown
            requestStatus={creditDiscountDataRequestStatus}
            creditDiscountBreakdownLabels={creditDiscountBreakdownLabels}
            isCreditDiscountBreakdownTableView={
              isCreditDiscountBreakdownTableView
            }
            setCreditDiscountBreakdownTableView={
              setIsCreditDiscountBreakdownTableView
            }
            monthlyCreditByCostTypeData={monthlyCreditByCostTypeData}
            pdfView={pdfView}
            creditDiscountStartDate={creditDiscountStartDate}
            creditDiscountEndDate={creditDiscountEndDate}
            onChangeCreditDiscountDateRange={onChangeCreditDiscountDateRange}
          />
        );
    }
  };

  const getCostComparisonCards = () =>
    creditSummaryList.map((item, index) => {
      return (
        <NewComparisonCard
          key={item.creditType}
          index={index}
          value={item.credit}
          valuePrefix={currencySymbol}
          heading={item.creditType}
          requestStatus={[item.requestStatus]}
        />
      );
    });

  const getPdfContentData = () => {
    return {
      costCardsData: getCostComparisonCards(),
    };
  };

  return (
    <div className="aws-credit-summary flex flex-column flex-gap-24">
      <div className="cost-cards flex flex-space-between">
        {getCostComparisonCards()}
      </div>
      {getGraphComponent('credit-distribution-by-location')}
      {getGraphComponent('cost-and-credit-breakdown')}
      {getGraphComponent('credit-discount-breakdown')}
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData(
            t('dashNav.creditSummary'),
            selectedDashboard!,
            selectedConnection!
          )}
          pdfContent={[
            {
              element: getGraphComponent(
                'credit-distribution-by-location',
                true
              ),
              contentType: CHART_TYPES.MAP,
              graphName: 'credit-distribution-by-location',
              column: CreditDistributionByRegionExportColumns,
              body: insertIndex(creditDistributionByRegionData),
              tableName: t('graphHeadings.creditDistributionbyRegion'),
              isTableView: false,
              legends: creditDistributionByRegionData.map((item) => ({
                color: item.color,
                name: `${item.region || 'Others'} - ${
                  currencySymbol + numberCommaSeparator(item.credit)
                }`,
              })),
            },
            {
              element: getGraphComponent('cost-and-credit-breakdown', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'cost-and-credit-breakdown',
              column: CostAndCreditBreakDownExportColumns,
              body: costCreditBreakdownLabels.map((value, index) => {
                return {
                  month: value,
                  cost:
                    currencySymbol +
                    numberCommaSeparator(costCreditBreakdownCostData[index]),
                  unblendedCost:
                    currencySymbol +
                    numberCommaSeparator(costCreditBreakdownCreditData[index]),
                  slNo: index + 1,
                };
              }),
              tableName: t('graphHeadings.costandCreditBreakdown'),
              isTableView: isCostAndCreditBreakdownTableView,
            },
            {
              element: getGraphComponent('credit-discount-breakdown', true),
              contentType: CHART_TYPES.LINE_CHART,
              graphName: 'credit-discount-breakdown',
              column: CreditDiscountBreakdownExportColumns(
                monthlyCreditByCostTypeData
              ),
              body: creditDiscountBreakdownLabels.map((value, index) => {
                let monthData: any = {
                  month: value,
                  slNo: index + 1,
                };
                monthlyCreditByCostTypeData.forEach((costData) => {
                  if (costData.creditData)
                    monthData[costData.costMetricType] =
                      currencySymbol +
                      numberCommaSeparator(Number(costData.creditData[index]));
                  else monthData[costData.costMetricType] = 0;
                });
                return monthData;
              }),
              tableName: t('graphHeadings.creditDiscountBreakdown'),
              isTableView: isCreditDiscountBreakdownTableView,
            },
          ]}
          pdfCardData={getPdfContentData()}
        />
      )}
    </div>
  );
};

export default AWSCreditSummaryDashboard;
