import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoginTypes } from 'pages/LoginPage/constants';
import { ProfileIcon } from 'assets/icons';
import { getUserprofilePhoto } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { Config } from 'config';

import './index.scss';

const ProfilePage = () => {
  const { t } = useTranslation();
  const loginType = Config.login_type;

  const [profileImage, setProfileImage] = useState<any>();

  useEffect(() => {
    if (loginType !== LoginTypes.MFA) {
      fetchImage();
    } else {
      setProfileImage(ProfileIcon);
    }
  }, []);
  /**
   * @function fetchImage
   * @description Function to fetch profile image
   */
  const fetchImage = async () => {
    try {
      const res: any = await getUserprofilePhoto();
      if (res.status > 400) {
        setProfileImage(ProfileIcon);
      } else {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setProfileImage(imageObjectURL);
      }
    } catch (err) {
      onApiCallError(err);
    }
  };

  return (
    <div className="page-content stretch flex flex-fit flex-center">
      <div className="profile-container flex flex-column flex-center ">
        <img src={profileImage} alt="profileimage" className="logo" />
        <div className="input-section flex flex-column full-width">
          <div className="section flex flex-column">
            <div className="flex-align-self-start font-caption-bold">
              {t('profile.nameLabel')}
            </div>
            <div className="font-subHeader value">
              {localStorage.getItem('name')}
            </div>
          </div>
          <div className="section flex flex-column">
            <div className="flex-align-self-start font-caption-bold">
              {t('profile.emailLabel')}
            </div>
            <div className="font-subHeader value">
              {localStorage.getItem('email')}
            </div>
          </div>
          <div className="section flex flex-column">
            <div className="flex-align-self-start font-caption-bold">
              {t('profile.roleLabel')}
            </div>
            <div className="font-subHeader value">
              {localStorage.getItem('role')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
