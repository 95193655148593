import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectPurchase } from 'redux/purchaseSlice';
import Button from 'components/Button';
import Table from 'components/Table';
import { BUTTON_SIZE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';
import { REQUEST_STATUS } from 'constants/requestBody';

import { AWSElastiCacheRisType } from '../../types';
import { getAllAwsElastiCachePurchasedRis } from '../../services';
import { ReservedInstancesStatusLabels } from '../../constants';

const ElastiCachePurchased = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedConnection } = useSelector(selectPurchase);

  const [elastiCacheRis, setElastiCacheRis] = useState<AWSElastiCacheRisType[]>(
    []
  );
  const [
    fetchElastiCacheRisRequestStatus,
    setFetchElastiCacheRisRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  useEffect(() => {
    if (selectedConnection) {
      fetchAllElastiCacheRis();
    }
  }, [selectedConnection]);

  /**
   * @function fetchAllElastiCacheRis
   * @description Function to fetch and set all the AWS ElastiCache RIs
   */
  const fetchAllElastiCacheRis = () => {
    setFetchElastiCacheRisRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      region: selectedConnection?.awsRegion,
    };

    getAllAwsElastiCachePurchasedRis(params)
      .then((res: any) => {
        setElastiCacheRis(res?.data?.responseData || []);
        setFetchElastiCacheRisRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchElastiCacheRisRequestStatus);
      });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('purchasePage.reservedInstancesLabels.nodeId'),
      dataIndex: 'nodeId',
      key: 'nodeId',
    },
    {
      title: t('purchasePage.reservedInstancesLabels.engine'),
      dataIndex: 'engine',
      key: 'engine',
    },
    {
      title: t('purchasePage.reservedInstancesLabels.nodeType'),
      dataIndex: 'nodeType',
      key: 'nodeType',
    },
    {
      title: t('purchasePage.cart.paymentOption'),
      dataIndex: 'offeringType',
      key: 'offeringType',
    },
    {
      title: t('purchasePage.cart.term'),
      dataIndex: 'term',
      key: 'term',
      render: (text: string) =>
        t('purchasePage.cart.countMonths', { count: (Number(text) || 0) * 12 }),
    },
    {
      title: t('purchasePage.reservedInstancesLabels.quantity'),
      dataIndex: 'count',
      key: 'quantity',
    },
    {
      title: t('purchasePage.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => (
        <span className={`status ${text}`}>
          {
            ReservedInstancesStatusLabels.find((item) => item.key === text)
              ?.label
          }
        </span>
      ),
    },
  ];

  return (
    <section className="flex flex-column flex-gap-16">
      <div className="flex flex-align-items-center flex-space-between">
        <div className="form-header">
          {t('purchasePage.reservedInstancesLabels.purchasedResevedInstance')}
        </div>
        <Button
          title={t('purchasePage.reservedInstancesLabels.purchaseNewRi')}
          size={BUTTON_SIZE.SMALL}
          onClick={() => navigate(NAVIGATION_MENU_PATH.PURCHASE_ELASTICACHE_RI)}
        />
      </div>
      <Table
        pagination={false}
        dataSource={elastiCacheRis.map((item, index) => ({
          ...item,
          key: index,
        }))}
        columns={columns}
        loading={fetchElastiCacheRisRequestStatus === REQUEST_STATUS.PROCESSING}
        designVersion2={true}
        scroll={{ y: 'calc(100vh - 20em)' }}
      />
    </section>
  );
};

export default ElastiCachePurchased;
