import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGProfiler,
  setGProfilerConnectionView,
} from 'redux/gProfilerSlice';
import { userAuthorization } from 'redux/authorizationSlice';

import SuccessComponent from 'components/SuccessComponent';
import ErrorComponent from 'components/ErrorComponent';
import NavigationPath from 'components/NavigationPath';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { GProfilerConnectionView } from 'constants/gProfiler';
import { GProfilerIcon, SuccessIcon } from 'assets/icons';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import TableView from './TableView';
import AddConnection from './components/AddConnection';

import './index.scss';

const GProfilerConnectionPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gProfilerConnectionView } = useSelector(selectGProfiler);
  const { permissions } = useSelector(userAuthorization);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
      dispatch(setGProfilerConnectionView(GProfilerConnectionView.TABLE_VIEW));
    };
  }, []);

  const setToConnectionForm = () => {
    dispatch(
      setGProfilerConnectionView(GProfilerConnectionView.CONNECTION_FORM)
    );
  };

  const getComponent = () => {
    switch (gProfilerConnectionView) {
      case GProfilerConnectionView.TABLE_VIEW:
        return <TableView />;
      case GProfilerConnectionView.CONNECTION_FORM:
        return <AddConnection />;
      case GProfilerConnectionView.SUCCESS:
        return (
          <SuccessComponent
            mainTitle={t('gProfilerConnectionPage.success.mainTitle')}
            subTitle={t('gProfilerConnectionPage.success.subTitle')}
            buttonTitle={t('gProfilerConnectionPage.success.buttonTitle')}
            linkTitle={t('gProfilerConnectionPage.success.linkTitle')}
            onHandleButtonClick={() =>
              dispatch(
                setGProfilerConnectionView(GProfilerConnectionView.TABLE_VIEW)
              )
            }
            onHandleLinkClick={setToConnectionForm}
            icon={<SuccessIcon />}
          />
        );
      case GProfilerConnectionView.ERROR:
        return (
          <ErrorComponent
            mainTitle={t('gProfilerConnectionPage.error.mainTitle')}
            subTitle={t('gProfilerConnectionPage.error.subTitle')}
            buttonTitle={t('gProfilerConnectionPage.error.buttonTitle')}
            onHandleButtonClick={setToConnectionForm}
          />
        );
    }
  };
  return (
    <div className="gprofiler-connection">
      <div className="gprofiler-connection-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="flex flex-align-items-center flex-gap-8">
          <Icon icon={GProfilerIcon} className="icon" />
          <div className="text-group flex flex-column">
            <span className="modal-heading">
              {gProfilerConnectionView === GProfilerConnectionView.TABLE_VIEW
                ? t('subMenu.gprofilerConnection')
                : t('subMenu.setupGprofilerConnection')}
            </span>
            <span className="font-caption-bold">{t('subMenu.gprofiler')}</span>
          </div>
        </div>
        {gProfilerConnectionView === GProfilerConnectionView.TABLE_VIEW &&
          (permissions.thirdPartyAppsModify ||
            permissions.thirdPartyAppsWrite) && (
            <Button title={t('addConnection')} onClick={setToConnectionForm} />
          )}
      </div>
      <div className="page-content full-height">
        <NavigationPath />
        {getComponent()}
      </div>
    </div>
  );
};

export default GProfilerConnectionPage;
