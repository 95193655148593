import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { cmdbSetup, setCmdbSetupConnection } from 'redux/cmdbSetupSlice';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import { INPUT_SIZE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import FieldValidationMessage from 'components/FieldValidationMessage';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';

import { fetchDatasourceProviders, validateDuplicateName } from './utils';

type CmdbBasicDetailsProps = {
  setIsDuplicateName: (val: boolean) => void;
};
const CmdbBasicDetails = ({ setIsDuplicateName }: CmdbBasicDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cmdbSetupConnection, isEditConnection } = useSelector(cmdbSetup);

  const [datasourceProviders, setDatasourceProviders] = useState<string[]>([]);
  const [datasourceProvidersReqStatus, setDatasourceProvidersReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [datasourceProviderValidation, setDatasourceProviderValidation] =
    useState('');
  const [nameValidation, setNameValidation] = useState('');

  useEffect(() => {
    fetchDatasourceProviders({
      setDatasourceProviders: setDatasourceProviders,
      setReqStatus: setDatasourceProvidersReqStatus,
    });
  }, []);

  const validateName = (value: string, validateDuplicate?: boolean) => {
    if (validateEmptyField(value, t('name'), setNameValidation)) {
      return false;
    }

    if (!validateAlphanumericNames(value, t('name'), setNameValidation)) {
      return false;
    }

    if (
      validateStringLengthLessThan(
        value,
        MAX_CHARACTER_LIMIT,
        t('name'),
        setNameValidation
      )
    ) {
      return false;
    }

    if (validateDuplicate) {
      validateDuplicateName(value, setNameValidation, setIsDuplicateName);
    }

    return true;
  };

  return (
    <div className="flex flex-column flex-gap-16">
      <div>
        <FormLabel
          title={t('datasourceProvider')}
          required
          disabled={isEditConnection} // Disable for the edit flow
        />
        <SelectDropdown
          rootClassName="full-width"
          size={INPUT_SIZE.MEDIUM}
          placeholder={t('chooseDatasourceProvider')}
          value={cmdbSetupConnection!.dataSourceProvider}
          options={datasourceProviders.map((provider) => ({
            label: provider,
            value: provider,
          }))}
          loading={datasourceProvidersReqStatus === REQUEST_STATUS.PROCESSING}
          onSelect={(value: any) => {
            dispatch(
              setCmdbSetupConnection({
                ...cmdbSetupConnection!,
                dataSourceProvider: value,
              })
            );
            setDatasourceProviderValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              cmdbSetupConnection!.dataSourceProvider,
              t('datasourceProvider'),
              setDatasourceProviderValidation
            )
          }
          disabled={isEditConnection} // Disable for the edit flow
          designVersion2
        />
        <FieldValidationMessage message={datasourceProviderValidation} />
      </div>
      <div>
        <FormLabel
          title={t('name')}
          required
          disabled={isEditConnection} // Disable for the edit flow
        />
        <Input
          placeholder={t('enterName')}
          value={cmdbSetupConnection!.name}
          onChange={(e: any) => {
            dispatch(
              setCmdbSetupConnection({
                ...cmdbSetupConnection!,
                name: e.target.value,
              })
            );
            validateName(e.target.value);
            setIsDuplicateName(true);
          }}
          onBlur={(e: any) => validateName(e.target.value, true)}
          data-testid="name-input"
          disabled={isEditConnection} // Disable for the edit flow
        />
        <FieldValidationMessage message={nameValidation} />
      </div>
    </div>
  );
};

export default CmdbBasicDetails;
