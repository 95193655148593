import SpendProfileChart from '../SpendProfileChart';
import SpendProfileFooter from '../SpendProfileFooter';
import SpendProfileHeader from '../SpendProfileHeader';
import { SpendProfileDataType } from '../../types';

import './index.scss';

type SpendProfileChartContentProps = {
  spendProfileData: SpendProfileDataType[];
  pdfView: boolean;
};

const SpendProfileChartContent = ({
  spendProfileData,
  pdfView,
}: SpendProfileChartContentProps) => {
  return (
    <div
      className="application-spend-chart flex"
      data-testid="application-spend-chart"
    >
      {spendProfileData
        .filter((spend) => spend.appsCount)
        .map((spend) => (
          <div
            className="spend-profile flex-fit text-align-center flex flex-column flex-gap-8"
            key={spend.spendType}
          >
            <SpendProfileHeader spendData={spend} />
            <SpendProfileChart spendData={spend} />
            <SpendProfileFooter spendData={spend} pdfView={pdfView} />
          </div>
        ))}
    </div>
  );
};

export default SpendProfileChartContent;
