import i18n from 'i18n';

import { PROVIDER } from 'constants/cloudProviders';

export enum RuleEngineComponents {
  PROVIDER_LIST = 'PROVIDER_LIST_COMPONENT',
  RULESETS_FORM = 'RULESETS_FORM',
  SUCCESS_PAGE = 'SUCCESS_PAGE',
  ERROR_PAGE = 'ERROR_PAGE',
}

export enum RulesetQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
}

export const RULESET_QUICK_ACTION_MENU_ITEMS = [
  { id: RulesetQuickActions.EDIT, title: i18n.t('ruleEngineLabels.edit') },
  { id: RulesetQuickActions.DELETE, title: i18n.t('ruleEngineLabels.delete') },
  {
    id: RulesetQuickActions.ACTIVATE,
    title: i18n.t('ruleEngineLabels.activate'),
  },
  {
    id: RulesetQuickActions.DEACTIVATE,
    title: i18n.t('ruleEngineLabels.deactivate'),
  },
];

export const DEFAULT_STATEMENT = {
  field: '',
  operator: '',
  value: {},
};

export const DEFAULT_RULE = {
  recommendationType: '',
  recommendationStatementType: '',
  statementValuesList: [DEFAULT_STATEMENT],
};

export const DEFAULT_FORM_DATA = {
  ruleSetName: '',
  description: '',
  autopilotOn: false,
  cloudProvider: PROVIDER.GCP,
  createViewFilter: false,
  rules: [DEFAULT_RULE],
};

export enum FIELD_VALUE_TYPES {
  INTEGER = 'Integer',
  STRING = 'String',
  DATE = 'DATE',
  FLOAT = 'Float',
  BOOLEAN = 'boolean',
}

export enum OPERATORS {
  IN = 'in',
  BETWEEN = 'between',
}
