import { ICONS } from 'constants/icons';
import {
  NAVIGATION_MENU_DATA,
  NAVIGATION_MENU_ID,
} from 'constants/navigationMenu';
import { NavigationMenuDataState } from 'types/navigationMenu';

export const ALL_FEATURES_NAV_MENU: NavigationMenuDataState = {
  id: NAVIGATION_MENU_ID.ALL_FEATURES,
  title: 'allFeatures',
  subSections: [],
  permissions: [],
  subMenu: NAVIGATION_MENU_DATA,
  iconName: ICONS.FUNCTION_LINE,
};
