import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';

import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { userAuthorization } from 'redux/authorizationSlice';
import {
  selectScorecard,
  setSelectedScorecardView,
} from 'redux/scorecardSlice';
import NavigationPath from 'components/NavigationPath';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';

import ScorecardDashboardHeader from './components/ScorecardDashboardHeader';
import WasteManagementScorecardDashboard from './components/WasteManagementScorecardDashboard';
import { SCORECARD_DASHBOARD_NAVIGATION, SCORECARD_NAVS } from './constants';
import PurchaseTactics from './components/PurchaseTactics';
import ScorecardSummaryDashboard from './components/ScorecardSummaryDashboard';

import './index.scss';

const ScorecardsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permissions } = useSelector(userAuthorization);
  const { selectedScorecardView } = useSelector(selectScorecard);

  const [isEmptyDashboard, setIsEmptyDashboard] = useState(false);
  const [connectionsRequestStatus, setConnectionsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  if (!permissions.cloudConnectorList) {
    return <div className="page-content">{t('noConnectionsPermission')}</div>;
  }

  if (isEmptyDashboard) {
    return <div className="page-content">{t('noConnectionsFound')}</div>;
  }

  const getComponent = () => {
    switch (selectedScorecardView) {
      case SCORECARD_NAVS.SUMMARY:
        return <ScorecardSummaryDashboard />;

      case SCORECARD_NAVS.PURCHASE_TACTICS:
        return <PurchaseTactics />;

      case SCORECARD_NAVS.WASTE_MANAGEMENT_EFFECTIVENESS:
        return <WasteManagementScorecardDashboard />;
    }
  };

  return (
    <div className="scorecards">
      <ScorecardDashboardHeader
        setIsEmptyDashboard={setIsEmptyDashboard}
        setRequestStatus={setConnectionsRequestStatus}
      />
      <div className="page-content">
        <NavigationPath />
        <HorizontalNavigationMenu
          menuItems={SCORECARD_DASHBOARD_NAVIGATION.map((navMenu) => (
            <Menu.Item
              onClick={() => {
                dispatch(setSelectedScorecardView(navMenu.id));
              }}
              key={navMenu.id}
              eventKey={navMenu.id}
              className={`font-caption-bold menu ${
                navMenu.id === selectedScorecardView && 'active-menu'
              }`}
            >
              {navMenu.title}
            </Menu.Item>
          ))}
          selectedKeys={[selectedScorecardView]}
        />
        <div className="scorecard-content">
          <DashboardComponent
            component={getComponent()}
            requestStatus={connectionsRequestStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default ScorecardsPage;
