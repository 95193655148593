import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  QUERY_FIELDS,
} from 'constants/requestBody';
import {
  doesDataSourceSupportBillingMapping,
  getFieldCategory,
} from 'pages/CustomDashboardPage/utils';
import {
  FIELD_TYPE,
  FieldSource,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import {
  AvailableCustomGroupFieldsType,
  ChartType,
  ColumnType,
  ConnectionProviderType,
  FilterGroupType,
  FilterType,
  QueryType,
} from 'types/dashboard';
import { store } from 'redux/store';
import { PROVIDER } from 'constants/cloudProviders';
import { setTableChartData } from 'redux/customDashboardSlice';
import { replaceAllSpecialCharactersBy } from 'utils/dataFormatterUtils';
import { getQueryTagField, getTagsQueryFilters } from 'utils/dashboardUtils';
import { formatDateFieldByProvider } from 'utils/date';

/**
 * @function isCustomChartSelected
 * @description Function to check if the custom chart is selected or not
 * @param rowPosition row position of the chart
 * @param chartPosition Chart position of the chart
 * @returns boolean true if the chart is selected, else false
 */
export const isCustomChartSelected = (
  rowPosition: number,
  chartPosition: number
) => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;

  return (
    customDashboardState.selectedChartRow === rowPosition &&
    customDashboardState.selectedChartIndex === chartPosition
  );
};

/**
 * @function getFieldByLabel
 * @description Function to fetch the field by the label by provider
 * @param connectionDetails connection details for which the label is fetched
 * @param dimensionDetails optional dimension details for a dimension label
 * @returns string query field
 */
const getFieldByLabel = (
  dimensionDetails: ColumnType,
  connectionDetails: ConnectionProviderType
) => {
  const state = store.getState();
  const customDashboard = state.customDashboard;
  const { tagMaps, tagKeyValues } = customDashboard;

  if (dimensionDetails.dimensionType === FieldSource.TAGS) {
    return getQueryTagField(
      {
        ...dimensionDetails,
        dimension: dimensionDetails.field.substring(
          0,
          dimensionDetails.field.lastIndexOf('_')
        ),
      },
      connectionDetails,
      tagKeyValues,
      tagMaps
    );
  }

  return dimensionDetails.field;
};

/**
 * @function modifyDimensionLabelForCustomQuery
 * @description Function to modify the dimension labels for query
 * @param dimension Dimension details for a dimension label
 * @returns string query label
 */
export const modifyDimensionLabelForCustomQuery = (dimension: ColumnType) => {
  if (dimension.dimensionType === FieldSource.TAGS) {
    return (
      replaceAllSpecialCharactersBy(dimension.label) +
      '_' +
      dimension.tagDimensionType
    );
  }

  return replaceAllSpecialCharactersBy(dimension.field);
};

/**
 * @function getTagsQueryFilterGroup
 * @description Function to construct the tags query filter group
 * @param dimensions list of dimensions
 * @param connectionDetails connection details containing the connector id and provider
 */
export const getTagsQueryFilterGroup = (
  dimensions: ColumnType[],
  connectionDetails: ConnectionProviderType
) => {
  const state = store.getState();
  const customDashboard = state.customDashboard;
  const { tagMaps, tagKeyValues } = customDashboard;
  return getTagsQueryFilters(
    dimensions.map((item) => ({
      dimension: item.field.substring(0, item.field.lastIndexOf('_')),
      dimensionType: item.dimensionType,
      tagDimensionType: item.tagDimensionType,
    })),
    connectionDetails,
    tagKeyValues,
    tagMaps
  );
};

/**
 * @function getChartTableFilterArrayQuery
 * @description Function to construct the chart/table query filter array which can be added to a filter group
 * @param filterGroup filter group from table/chart
 * @param columns columns from selected dimensions and metrics
 * @param provider connection cloud provider
 * @returns Modified query filter array
 */
export const getChartTableFilterArrayQuery = (
  filterGroup: FilterType[],
  columns: ColumnType[],
  provider: string
) => {
  const state = store.getState();
  const { dashboardType } = state.customDashboard;

  const allFilters: FilterType[] = [];
  filterGroup
    .filter(
      (filter) =>
        filter.selectedValues !== undefined &&
        columns.map((column) => column.field).includes(filter.field)
    )
    .forEach((filter) => {
      if (getFieldCategory(filter.field) === FIELD_TYPE.LITERAL)
        allFilters.push({
          ...filter,
          field: replaceAllSpecialCharactersBy(filter.field),
          value: "('" + filter.selectedValues?.join("','") + "')",
          selectedValues: undefined,
        });
      else if (getFieldCategory(filter.field) === FIELD_TYPE.TIME) {
        allFilters.push(
          {
            ...filter,
            field: replaceAllSpecialCharactersBy(filter.field),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value:
              (provider === PROVIDER.AWS
                ? formatDateFieldByProvider(
                    `${filter.selectedValues?.at(0)}`,
                    PROVIDER.AWS,
                    dashboardType === MY_DASHBOARD_TYPES.IMPORTS
                  )
                : filter?.selectedValues?.at(0)) ?? '',
            selectedValues: undefined,
          },
          {
            ...filter,
            field: replaceAllSpecialCharactersBy(filter.field),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value:
              (provider === PROVIDER.AWS
                ? formatDateFieldByProvider(
                    `${filter.selectedValues?.at(1)}`,
                    PROVIDER.AWS,
                    dashboardType === MY_DASHBOARD_TYPES.IMPORTS
                  )
                : filter?.selectedValues?.at(1)) ?? '',
            selectedValues: undefined,
          }
        );
      }
    });

  return allFilters;
};

/**
 * @function getConditionsQueryFilterArray
 * @description Function to construct the conditions query filter array which can be added to a filter group
 * @param conditionsGroup conditions group from table/chart
 * @param provider connection cloud provider
 * @returns Modified query conditions array
 */
export const getConditionsQueryFilterArray = (
  conditionsGroup: FilterType[],
  provider: string
) => {
  const state = store.getState();
  const { dashboardType } = state.customDashboard;

  let conditions: FilterType[] = [];
  conditionsGroup
    .filter(
      (condition) => condition.field && condition.value && condition.comparator
    )
    .forEach((condition) => {
      if (getFieldCategory(condition.field) === FIELD_TYPE.NUMERIC) {
        conditions.push({
          ...condition,
          field: replaceAllSpecialCharactersBy(condition.field),
          value: '#' + condition.value,
        });
        return;
      }
      if (getFieldCategory(condition.field) === FIELD_TYPE.LITERAL) {
        conditions.push({
          ...condition,
          field: replaceAllSpecialCharactersBy(condition.field),
          value: "('" + condition.value + "')",
        });
        return;
      }
      if (getFieldCategory(condition.field) === FIELD_TYPE.TIME) {
        if (condition.comparator === COMPARATORS.IN) {
          conditions.push({
            ...condition,
            field: replaceAllSpecialCharactersBy(condition.field),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value:
              (provider === PROVIDER.AWS
                ? formatDateFieldByProvider(
                    `${condition.value.split(',').at(0)}`,
                    PROVIDER.AWS,
                    dashboardType === MY_DASHBOARD_TYPES.IMPORTS
                  )
                : condition.value.split(',').at(0)) ?? '',
          });
          conditions.push({
            ...condition,
            field: replaceAllSpecialCharactersBy(condition.field),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value:
              (provider === PROVIDER.AWS
                ? formatDateFieldByProvider(
                    `${condition.value.split(',').at(1)}`,
                    PROVIDER.AWS,
                    dashboardType === MY_DASHBOARD_TYPES.IMPORTS
                  )
                : condition.value.split(',').at(1)) ?? '',
          });
          return;
        }
        conditions.push({
          ...condition,
          field: replaceAllSpecialCharactersBy(condition.field),
          value:
            provider === PROVIDER.AWS
              ? formatDateFieldByProvider(
                  condition.value,
                  PROVIDER.AWS,
                  dashboardType === MY_DASHBOARD_TYPES.IMPORTS
                )
              : condition.value,
        });
        return;
      }
      conditions.push(condition);
    });

  return conditions;
};

/**
 * @function getModifiedChartQuery
 * @description Function to construct a modified query to add filters on top of the existing query
 * @param query chart query
 * @param provider connection cloud provider
 * @param filterGroup filter group
 * @param conditionsGroup conditions group
 * @param connectionDetails connection details containing the connector id and provider
 * @returns Modified query
 */
export const getModifiedChartQuery = (
  query: QueryType | undefined,
  provider: string,
  filterGroup: FilterType[],
  conditionsGroup: FilterType[],
  connectionDetails: ConnectionProviderType,
  dashboardType?: string
) => {
  const dimensions: ColumnType[] = [];
  const metrics: ColumnType[] = [];
  const columns: ColumnType[] =
    query?.columns?.map((item) => {
      if (query?.groupBy?.some((grouped) => grouped === item.field))
        dimensions.push({
          ...item,
          label: modifyDimensionLabelForCustomQuery(item),
        });
      if (
        query?.aggregators?.some(
          (aggregator) => aggregator.label === item.field
        )
      )
        metrics.push({
          ...item,
          label: modifyDimensionLabelForCustomQuery(item),
        });
      return {
        label: modifyDimensionLabelForCustomQuery(item),
        field: getFieldByLabel(item, connectionDetails),
        dimensionType: item.dimensionType,
        tagDimensionType: item.tagDimensionType,
      };
    }) ?? [];

  const allFilters: FilterType[] = getChartTableFilterArrayQuery(
    filterGroup,
    columns,
    provider
  );

  const filterGroups: FilterGroupType[] = [];
  allFilters.length &&
    filterGroups.push({
      filters: allFilters,
      conjunctToNextGroup: CONJUNCTIONS.AND,
    });

  const conditions = getConditionsQueryFilterArray(conditionsGroup, provider);

  conditions.length &&
    filterGroups.push({
      filters: conditions,
      conjunctToNextGroup: CONJUNCTIONS.AND,
    });

  const tagFilterGroup = getTagsQueryFilterGroup(dimensions, connectionDetails);

  const structColumns =
    connectionDetails.provider === PROVIDER.GCP &&
    doesDataSourceSupportBillingMapping()
      ? [{ label: QUERY_FIELDS.TAG, field: QUERY_FIELDS.PROJECT_LABEL_CUSTOM }]
      : undefined;

  return {
    columns: [
      {
        field: '*',
      },
    ],
    filterGroups: filterGroups,
    dashBoardType: dashboardType ?? query?.dashBoardType,
    cached: query?.cached,
    orderBy: query?.orderBy
      ?.filter((item) => item.label && item.sort)
      .map((item) => ({
        ...item,
        label: replaceAllSpecialCharactersBy(item.label),
      })),
    subQuery: {
      ...query,
      dashBoardType: dashboardType ?? query?.dashBoardType,
      columns: columns.map((item) => ({
        ...item,
        dimensionType: undefined,
        tagDimensionType: undefined,
      })),
      structColumns: structColumns,
      groupBy: dimensions.map((item) => item.label),
      aggregators: metrics.map((item) => ({
        label: item.label,
        function: AGGREGATORS.SUM,
      })),
      orderBy: undefined,
      filterGroups: tagFilterGroup.length
        ? [
            {
              filters: tagFilterGroup,
              conjunctToNextGroup: CONJUNCTIONS.AND,
            },
          ]
        : [],
    },
  };
};

/**
 * @function replaceFieldsInGroupFilters
 * @description Function to replace the field names by connection provider field
 * @param requestBody request body of the query
 * @param provider connection cloud provider
 * @param customGroupAvailableFields available fields for custom group
 * @returns Modified request body
 */
export const replaceFieldsInGroupsQuery = (
  requestBody: any,
  provider: string,
  customGroupAvailableFields: AvailableCustomGroupFieldsType[]
) => {
  return {
    ...requestBody,
    filterGroups: replaceFieldsInGroupFilters(
      requestBody.filterGroups,
      provider,
      customGroupAvailableFields
    ),
    columns: requestBody.columns.map((column: any) => {
      return {
        label: column.label,
        field: customGroupAvailableFields.find(
          (field) => field.label === column.label
        )?.fields[provider as PROVIDER.GCP | PROVIDER.AWS | PROVIDER.AZURE],
      };
    }),
  };
};

/**
 * @function replaceFieldsInGroupFilters
 * @description Function to replace the field names by connection provider field
 * @param filterGroups list of filter groups
 * @param provider connection cloud provider
 * @param customGroupAvailableFields available fields for custom group
 * @returns List of modified filter group
 */
export const replaceFieldsInGroupFilters = (
  filterGroups: FilterGroupType[],
  provider: string,
  customGroupAvailableFields: AvailableCustomGroupFieldsType[]
) => {
  const state = store.getState();
  const { dashboardType } = state.customDashboard;

  const filters = filterGroups?.map((filterGroup: any) => {
    return {
      ...filterGroup,
      filters: filterGroup.filters?.map((filter: any) => {
        let modifiedValue = filter.value;
        if (provider === PROVIDER.AWS && filter.field === 'date')
          modifiedValue = formatDateFieldByProvider(
            filter.value,
            PROVIDER.AWS,
            dashboardType === MY_DASHBOARD_TYPES.IMPORTS
          );
        return {
          ...filter,
          value: modifiedValue,
          field: customGroupAvailableFields.find(
            (field) => field.label === filter.field
          )?.fields[provider as PROVIDER.GCP | PROVIDER.AWS | PROVIDER.AZURE],
        };
      }),
    };
  });
  return filters || [];
};

/**
 * @function normalizeGroupData
 * @description Function to normalize the group data to remove the duplicate entries
 * @param isTableView is the view in table view mode
 * @param chart chart for which the data is normalized
 * @param apiResponse response from the api call
 * @returns Modified data
 */
export const normalizeGroupData = (
  isTableView: boolean,
  chart: ChartType,
  apiResponse: any
) => {
  // Get the metric from the chart
  let metrics = [
    ...(isTableView
      ? chart.tableQuery?.aggregators?.map((aggregator) => aggregator.label) ??
        []
      : chart.chartQuery?.aggregators?.map((aggregator) => aggregator.label) ??
        []),
  ];

  // Group all the data in one array with metrics as number
  const allData: any[] = [];
  apiResponse.forEach((response: any) => {
    allData.push(
      ...response.data.map((data: any) => {
        let convertedMetricObj = {};
        metrics.forEach((metricName) => {
          convertedMetricObj = {
            ...convertedMetricObj,
            [metricName]: Number(data[metricName]),
          };
        });

        return {
          ...data,
          ...convertedMetricObj,
        };
      })
    );
  });

  // Merge the data with same dimension values
  let dimensionWiseMergedData: any[] = [];

  allData.forEach((dataToBeMerged: any) => {
    // Find if same dimension values already exists
    const index = dimensionWiseMergedData.findIndex((existingMergedData) => {
      const itemKeys = Object.keys(existingMergedData).filter(
        (key) => !metrics.includes(key)
      );
      return itemKeys.every(
        (key) => dataToBeMerged[key] === existingMergedData[key]
      );
    });

    // If same dimension values already exists then add the metric values to existing values
    if (index > -1) {
      let addedMetricObj = {};
      metrics.forEach((metricName) => {
        addedMetricObj = {
          ...addedMetricObj,
          [metricName]:
            dataToBeMerged[metricName] +
            dimensionWiseMergedData[index][metricName],
        };
      });
      dimensionWiseMergedData[index] = {
        ...dimensionWiseMergedData[index],
        ...addedMetricObj,
      };
    } else {
      dimensionWiseMergedData.push(dataToBeMerged);
    }
  });

  return dimensionWiseMergedData;
};

export const updateTableChartData = (key: string, data: any) => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  let newTableChartData = { ...customDashboardState.tableChartData };
  newTableChartData[key] = data;
  store.dispatch(setTableChartData(newTableChartData));
};
