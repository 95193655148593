import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useDidMountEffect from 'hooks/useDidMountEffect';
import Button from 'components/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import { ICONS } from 'constants/icons';
import { costMonitor, setCostMonitorFormData } from 'redux/costMonitorSlice';
import { DEFAULT_ALERT_SUBSCRIPTION_FORM_VALUES } from 'pages/CostMonitorPage/constants';
import { AnamolySubscriptionType } from 'pages/CostMonitorPage/types';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import AlertSubscriptionForm from '../AlertSubscriptionForm';
import { getAwsAlertSubscriptionByConnection } from '../services';
import { validateAllSubscriptions } from './utils';

type SetupCostMonitorProps = {
  createButtonClick: boolean;
  setAlertSubscriptionsValidation: (value: boolean) => void;
};

const SetupCostMonitor = ({
  createButtonClick,
  setAlertSubscriptionsValidation,
}: SetupCostMonitorProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', {
    keyPrefix: 'costMonitor.createCostMonitorLabels.createAlertSubscription',
  });
  const { costMonitorFormData, existingAttachedSubscriptions } =
    useSelector(costMonitor);

  const [existingAlertSubscriptions, setExistingAlertSubscriptions] = useState<
    AnamolySubscriptionType[]
  >([]);
  const [
    existingAlertSubscriptionsRequestStatus,
    setExistingAlertSubscriptionsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    if (costMonitorFormData.connectorId) {
      fetchAlertSubscriptions();
    }
  }, [costMonitorFormData.connectorId]);

  useDidMountEffect(() => {
    removeUnavailableSubscriptions();
  }, [existingAlertSubscriptions]);

  useEffect(() => {
    setAlertSubscriptionsValidation(
      validateAllSubscriptions(
        costMonitorFormData.anomalySubscriptions,
        existingAttachedSubscriptions,
        existingAlertSubscriptions,
        setValidationMessage
      )
    );
  }, [
    costMonitorFormData.anomalySubscriptions,
    existingAttachedSubscriptions,
    existingAlertSubscriptions,
  ]);

  /**
   * @function fetchAlertSubscriptions
   * @description Function to fetch all the subscriptions for the selected AWS connection
   */
  const fetchAlertSubscriptions = () => {
    setExistingAlertSubscriptionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: costMonitorFormData.connectorId,
    };

    getAwsAlertSubscriptionByConnection(params)
      .then((res: any) => {
        if (res.status === 200) {
          setExistingAlertSubscriptions(res.data.responseData);
          setExistingAlertSubscriptionsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setExistingAlertSubscriptions([]);
        setExistingAlertSubscriptionsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setExistingAlertSubscriptionsRequestStatus);
        setExistingAlertSubscriptions([]);
      });
  };

  /**
   * @function removeUnavailableSubscriptions
   * @description Function to remove unavailable subscriptions on changing the selected connection
   */
  const removeUnavailableSubscriptions = () => {
    const alertSubscriptions = costMonitorFormData?.anomalySubscriptions?.map(
      (subscription) => {
        if (
          !subscription.existingSubscription ||
          existingAlertSubscriptions.some(
            (item) => item.subscriptionArn === subscription.subscriptionArn
          )
        ) {
          return subscription;
        }

        return {
          ...subscription,
          subscriptionName: '',
          subscriptionArn: undefined,
        };
      }
    );

    dispatch(
      setCostMonitorFormData({
        ...costMonitorFormData,
        anomalySubscriptions: alertSubscriptions,
      })
    );
  };

  return (
    <div className="setup-subscription flex flex-column full-width styled-scroll">
      <div className="flex flex-space-between">
        <div className="flex flex-column flex-gap-4">
          <div className="form-header">{t('pageTitle')}</div>
          <div className="font-caption help-text">{t('subTitle')}</div>
        </div>
        <Button
          type={BUTTON_TYPE.NEUTRAL}
          iconName={ICONS.ADD_LINE}
          size={BUTTON_SIZE.SMALL}
          title={t('addAlertSubscription')}
          onClick={() => {
            dispatch(
              setCostMonitorFormData({
                ...costMonitorFormData,
                anomalySubscriptions: [
                  ...costMonitorFormData.anomalySubscriptions,
                  DEFAULT_ALERT_SUBSCRIPTION_FORM_VALUES,
                ],
              })
            );
          }}
        />
      </div>
      {costMonitorFormData.anomalySubscriptions.length === 0 && (
        <div className="subscriptions-validation font-validation-error flex flex-center full-height">
          {validationMessage}
        </div>
      )}
      <div className="subscription-list flex flex-column flex-gap-16">
        {costMonitorFormData.anomalySubscriptions
          .map((obj, index) => ({ ...obj, key: index }))
          .map((alertSubscription) => (
            <AlertSubscriptionForm
              key={alertSubscription.key}
              index={alertSubscription.key}
              alertSubscriptionDetails={alertSubscription}
              createButtonClick={createButtonClick}
              setAlertSubscriptionsValidation={setAlertSubscriptionsValidation}
              existingAlertSubscriptions={existingAlertSubscriptions}
              existingAlertSubscriptionsRequestStatus={
                existingAlertSubscriptionsRequestStatus
              }
            />
          ))}
      </div>
    </div>
  );
};

export default SetupCostMonitor;
