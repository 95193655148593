import { PROVIDER } from 'constants/cloudProviders';
import { APIS } from 'constants/endpoints';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getRecommendationFilters } from 'pages/CostOptimizationInsightsPage/services';
import { RecommendationFiltersType } from 'pages/CostOptimizationInsightsPage/types';
import { ConnectionListType } from 'types/dashboard';
import { getProviderForConnection } from 'utils/dashboardUtils';
import { onApiCallError } from 'utils/handleErrors';

/**
 * @function getRecommendationEndpointByProvider
 * @description Function to return the recommendation filters API endpoint based on the provider given
 * @param provider The provider for which the endpoint is returned
 * @returns String recommendation endpoint. Defaults to empty string
 */
const getRecommendationEndpointByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.GCP:
      return APIS.GET_GCP_RECOMMENDATION_AVAILABLE_FILTERS;

    case PROVIDER.AWS:
      return APIS.GET_AWS_RECOMMENDATION_AVAILABLE_FILTERS;

    case PROVIDER.AZURE:
      return APIS.GET_AZURE_RECOMMENDATION_AVAILABLE_FILTERS;

    default:
      return '';
  }
};

/**
 * @function fetchRecommendationFilters
 * @description Function to fetch and set the recommendation filter details
 * @param connection Connection for which the filters are fetched
 * @param category Category for which the filters are fetched
 * @param setData Callback function to set the data
 * @param Callback function to set the request status of the api
 */
export const fetchRecommendationFilters = (
  connection: ConnectionListType,
  category: string,
  setData: (val: RecommendationFiltersType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const params = {
    connectorId: connection.connectorId,
    category: category.toLowerCase().replace('_', '-'),
  };

  getRecommendationFilters(
    getRecommendationEndpointByProvider(getProviderForConnection(connection)),
    params
  )
    .then((res: any) => {
      if (res.status === 200) {
        const data = res.data.responseData;
        setData(
          data.map((item: any) => ({
            ...item,
            values: item.values
              ? item.values.map((value: any) => ({
                  value: value,
                }))
              : null,
          }))
        );
        setRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      setData([]);
      setRequestStatus(REQUEST_STATUS.ERROR);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
      setData([]);
    });
};
