import i18n from 'i18n';
import { DASHBOARD } from 'pages/OverviewPage/components/ConnectionsDashboard/components/HorizontalNavigation/constants';

export enum SHARE_DASHBOARD_COMPONENTS {
  INVITE = 'INVITE',
  SHARED_WITH = 'SHARED_WITH',
  SUCCESS_PAGE = 'SUCCESS_PAGE',
}

export const SHARE_DASHBAORD_NAV_LIST = [
  {
    id: SHARE_DASHBOARD_COMPONENTS.INVITE,
    title: i18n.t('shareDashboard.invite'),
  },
  {
    id: SHARE_DASHBOARD_COMPONENTS.SHARED_WITH,
    title: i18n.t('shareDashboard.sharedWith'),
  },
];

export enum SHARED_WITH_ACTIONS {
  REMOVE = 'REMOVE',
}

export const SHARED_WITH_QUICKACTIONS = [
  { id: SHARED_WITH_ACTIONS.REMOVE, title: i18n.t('shareDashboard.remove') },
];

export const DASHBOARD_WITH_POPOVER_DOWNLOAD: string[] = [
  DASHBOARD.COST_RECOMMENDATION_SUMMARY,
  DASHBOARD.COST_SUMMARY,
];
