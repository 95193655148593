import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectScorecard } from 'redux/scorecardSlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import {
  AWS_RI_SERVICES,
  PURCHASE_TACTICS_TAB,
  SAVING_PLAN_REPORT_TYPES,
} from 'pages/ScorecardPage/constants';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import {
  getAWSRICoverageDetails,
  getAWSRIUtilizationDetails,
} from 'pages/ScorecardPage/services';
import {
  getAwsRiServiceLabels,
  getReportType,
} from 'pages/ScorecardPage/utils';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MonthValueType } from 'types/dataTypes';
import {
  getDateMonthShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
} from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import CoverageUtilizationGraph from '../CoverageUtilizationGraph';
import { UtilizationCoverageExportColumns } from '../../constants';
import { getAwsRiExcelData } from './utils';

const ReservedInstance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, selectedPurchaseTacticsTab } =
    useSelector(selectScorecard);
  const { tableViewEnabled, showExpandGraphModal, expandedGraphName } =
    useSelector(selectDashboard);

  // Compute - EC2
  const [computeMonthlyCoverage, setComputeMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [computeMonthlyUtilization, setComputeMonthlyUtilization] = useState<
    MonthValueType[]
  >([]);
  const [computeTableView, setComputeTableView] = useState(false);
  const [computeReportType, setComputeReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [computeUtilizationRequestStatus, setComputeUtilizationRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [computeCoverageRequestStatus, setComputeCoverageRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  // ElastiCache
  const [elastiCacheMonthlyCoverage, setElastiCacheMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [elastiCacheMonthlyUtilization, setElastiCacheMonthlyUtilization] =
    useState<MonthValueType[]>([]);
  const [elastiCacheTableView, setElastiCacheTableView] = useState(false);
  const [elastiCacheReportType, setElastiCacheReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [
    elastiCacheUtilizationRequestStatus,
    setElastiCacheUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [
    elastiCacheCoverageRequestStatus,
    setElastiCacheCoverageRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  // MemoryDB
  const [memoryDbMonthlyCoverage, setMemoryDbMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [memoryDbMonthlyUtilization, setMemoryDbMonthlyUtilization] = useState<
    MonthValueType[]
  >([]);
  const [memoryDbTableView, setMemoryDbTableView] = useState(false);
  const [memoryDbReportType, setMemoryDbReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [
    memoryDbUtilizationRequestStatus,
    setMemoryDbUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [memoryDbCoverageRequestStatus, setMemoryDbCoverageRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  // ElasticSearch
  const [elasticSearchMonthlyCoverage, setElasticSearchMonthlyCoverage] =
    useState<MonthValueType[]>([]);
  const [elasticSearchMonthlyUtilization, setElasticSearchMonthlyUtilization] =
    useState<MonthValueType[]>([]);
  const [elasticSearchTableView, setElasticSearchTableView] = useState(false);
  const [elasticSearchReportType, setElasticSearchReportType] =
    useState<string>(SAVING_PLAN_REPORT_TYPES.UTILIZATION);
  const [
    elasticSearchUtilizationRequestStatus,
    setElasticSearchUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [
    elasticSearchCoverageRequestStatus,
    setElasticSearchCoverageRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  // OpenSearch
  const [openSearchMonthlyCoverage, setOpenSearchMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [openSearchMonthlyUtilization, setOpenSearchMonthlyUtilization] =
    useState<MonthValueType[]>([]);
  const [openSearchTableView, setOpenSearchTableView] = useState(false);
  const [openSearchReportType, setOpenSearchReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [
    openSearchUtilizationRequestStatus,
    setOpenSearchUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [openSearchCoverageRequestStatus, setOpenSearchCoverageRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  // Redshift
  const [redshiftMonthlyCoverage, setRedshiftMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [redshiftMonthlyUtilization, setRedshiftMonthlyUtilization] = useState<
    MonthValueType[]
  >([]);
  const [redshiftTableView, setRedshiftTableView] = useState(false);
  const [redshiftReportType, setRedshiftReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [
    redshiftUtilizationRequestStatus,
    setRedshiftUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [redshiftCoverageRequestStatus, setRedshiftCoverageRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  // RDS
  const [rdsMonthlyCoverage, setRdsMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [rdsMonthlyUtilization, setRdsMonthlyUtilization] = useState<
    MonthValueType[]
  >([]);
  const [rdsTableView, setRdsTableView] = useState(false);
  const [rdsReportType, setRdsReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [rdsUtilizationRequestStatus, setRdsUtilizationRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [rdsCoverageRequestStatus, setRdsCoverageRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    if (selectedConnection) {
      getUtilizationData();
      getCoverageData();
    }
  }, [selectedConnection]);

  useEffect(() => {
    setComputeTableView(tableViewEnabled);
    setElastiCacheTableView(tableViewEnabled);
    setMemoryDbTableView(tableViewEnabled);
    setElasticSearchTableView(tableViewEnabled);
    setOpenSearchTableView(tableViewEnabled);
    setRedshiftTableView(tableViewEnabled);
    setRdsTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    if (
      selectedConnection &&
      selectedPurchaseTacticsTab === PURCHASE_TACTICS_TAB.RESERVED_INSTANCE
    ) {
      dispatch(
        setExportToExcelData(
          getAwsRiExcelData(
            {
              compute: getExcelExportData(
                AWS_RI_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE,
                t('scorecard.awsReservedInstance.elasticComputeCloudEc2')
              ),
              elastiCache: getExcelExportData(
                AWS_RI_SERVICES.AMAZON_ELASTICACHE,
                t('scorecard.awsReservedInstance.elastiCache')
              ),
              memoryDb: getExcelExportData(
                AWS_RI_SERVICES.AMAZON_MEMORY_DB,
                t('scorecard.awsReservedInstance.memoryDB')
              ),
              elasticSearch: getExcelExportData(
                AWS_RI_SERVICES.AMAZON_ELASTIC_SEARCH,
                t('scorecard.awsReservedInstance.elasticSearch')
              ),
              openSearch: getExcelExportData(
                AWS_RI_SERVICES.AMAZON_OPENSEARCH,
                t('scorecard.awsReservedInstance.openSearch')
              ),
              redshift: getExcelExportData(
                AWS_RI_SERVICES.AMAZON_REDSHIFT,
                t('scorecard.awsReservedInstance.redshift')
              ),
              rds: getExcelExportData(
                AWS_RI_SERVICES.AMAZON_RELATIONAL_DATABASE_SERVICE,
                t('scorecard.awsReservedInstance.rds')
              ),
            },
            selectedConnection
          )
        )
      );
    }
  }, [
    selectedConnection,
    selectedPurchaseTacticsTab,
    computeMonthlyUtilization,
    computeMonthlyCoverage,
    elastiCacheMonthlyUtilization,
    elastiCacheMonthlyCoverage,
    memoryDbMonthlyUtilization,
    memoryDbMonthlyCoverage,
    elasticSearchMonthlyUtilization,
    elasticSearchMonthlyCoverage,
    openSearchMonthlyUtilization,
    openSearchMonthlyCoverage,
    openSearchMonthlyUtilization,
    openSearchMonthlyCoverage,
    redshiftMonthlyUtilization,
    redshiftMonthlyCoverage,
    rdsMonthlyUtilization,
    rdsMonthlyCoverage,
  ]);

  /**
   * @function getUtilizationData
   * @description Function to fetch the utilization details for all the services
   */
  const getUtilizationData = () => {
    fetchUtilizationDetails(
      AWS_RI_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE,
      setComputeMonthlyUtilization,
      setComputeUtilizationRequestStatus
    );

    fetchUtilizationDetails(
      AWS_RI_SERVICES.AMAZON_ELASTICACHE,
      setElastiCacheMonthlyUtilization,
      setElastiCacheUtilizationRequestStatus
    );

    fetchUtilizationDetails(
      AWS_RI_SERVICES.AMAZON_MEMORY_DB,
      setMemoryDbMonthlyUtilization,
      setMemoryDbUtilizationRequestStatus
    );

    fetchUtilizationDetails(
      AWS_RI_SERVICES.AMAZON_ELASTIC_SEARCH,
      setElasticSearchMonthlyUtilization,
      setElasticSearchUtilizationRequestStatus
    );

    fetchUtilizationDetails(
      AWS_RI_SERVICES.AMAZON_OPENSEARCH,
      setOpenSearchMonthlyUtilization,
      setOpenSearchUtilizationRequestStatus
    );

    fetchUtilizationDetails(
      AWS_RI_SERVICES.AMAZON_REDSHIFT,
      setRedshiftMonthlyUtilization,
      setRedshiftUtilizationRequestStatus
    );

    fetchUtilizationDetails(
      AWS_RI_SERVICES.AMAZON_RELATIONAL_DATABASE_SERVICE,
      setRdsMonthlyUtilization,
      setRdsUtilizationRequestStatus
    );
  };

  /**
   * @function getCoverageData
   * @description Function to fetch the coverage details for all the services
   */
  const getCoverageData = () => {
    fetchCoverageDetails(
      AWS_RI_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE,
      setComputeMonthlyCoverage,
      setComputeCoverageRequestStatus
    );

    fetchCoverageDetails(
      AWS_RI_SERVICES.AMAZON_ELASTICACHE,
      setElastiCacheMonthlyCoverage,
      setElastiCacheCoverageRequestStatus
    );

    fetchCoverageDetails(
      AWS_RI_SERVICES.AMAZON_MEMORY_DB,
      setMemoryDbMonthlyCoverage,
      setMemoryDbCoverageRequestStatus
    );

    fetchCoverageDetails(
      AWS_RI_SERVICES.AMAZON_ELASTIC_SEARCH,
      setElasticSearchMonthlyCoverage,
      setElasticSearchCoverageRequestStatus
    );

    fetchCoverageDetails(
      AWS_RI_SERVICES.AMAZON_OPENSEARCH,
      setOpenSearchMonthlyCoverage,
      setOpenSearchCoverageRequestStatus
    );

    fetchCoverageDetails(
      AWS_RI_SERVICES.AMAZON_REDSHIFT,
      setRedshiftMonthlyCoverage,
      setRedshiftCoverageRequestStatus
    );

    fetchCoverageDetails(
      AWS_RI_SERVICES.AMAZON_RELATIONAL_DATABASE_SERVICE,
      setRdsMonthlyCoverage,
      setRdsCoverageRequestStatus
    );
  };

  /**
   * @function fetchUtilizationDetails
   * @description Function to fetch the utilization details
   * @param services service for which the data is fetched
   * @param setData setter method for utilization data
   * @param setRequestStatus setter method for request status
   */
  const fetchUtilizationDetails = (
    service: string,
    setData: (val: MonthValueType[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      startDate: moment().subtract(11, 'months').format(HYPHEN_DATE_FORMAT),
      endDate: moment().format(HYPHEN_DATE_FORMAT),
      granularity: 'MONTHLY',
      service: service,
    };

    getAWSRIUtilizationDetails(params)
      .then((res: any) => {
        getAndSetDataFromResponse(res, setData, setRequestStatus);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  /**
   * @function fetchCoverageDetails
   * @description Function to fetch the coverage details
   * @param services service for which the data is fetched
   * @param setData setter method for coverage data
   * @param setRequestStatus setter method for request status
   */
  const fetchCoverageDetails = (
    service: string,
    setData: (val: MonthValueType[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      startDate: moment().subtract(11, 'months').format(HYPHEN_DATE_FORMAT),
      endDate: moment().format(HYPHEN_DATE_FORMAT),
      granularity: 'MONTHLY',
      service: service,
    };

    getAWSRICoverageDetails(params)
      .then((res: any) => {
        getAndSetDataFromResponse(res, setData, setRequestStatus);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  /**
   * @function getAndSetDataFromResponse
   * @description Function to get the data, modify and set the data from response
   * @param res Response from API call
   * @param setData setter method for data
   * @param setRequestStatus setter method for request status
   */
  const getAndSetDataFromResponse = (
    res: any,
    setData: (val: MonthValueType[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    const data = Object.entries<number>(res?.data?.responseData || {});
    const dateLabels = getDateMonthShortList(
      moment().subtract(11, 'months').format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
      'month',
      MONTH_YEAR_FORMAT
    );

    const formattedData = dateLabels.map((date) => {
      const currentDateData = data.find(
        (item: any) => moment(item[0]).format(MONTH_YEAR_FORMAT) === date
      );

      return {
        month: date,
        value: currentDateData ? currentDateData[1] : 0,
      };
    });

    setData(formattedData);
    setRequestStatus(REQUEST_STATUS.SUCCESS);
  };

  /**
   * @function getTrendGraphTitle
   * @description Function to return the trend graph title
   * @param reportType Type of report UTILIZATION or COVERAGE
   * @returns string title
   */
  const getTrendGraphTitle = (reportType: string) => {
    return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
      ? t('scorecard.awsReservedInstance.riUtilizationByMonth')
      : t('scorecard.awsReservedInstance.riCoverageByMonth');
  };

  /**
   * @function getGaugeGraphTitle
   * @description Function to return the gauge graph title
   * @param reportType Type of report UTILIZATION or COVERAGE
   * @returns string title
   */
  const getGaugeGraphTitle = (reportType: string) => {
    return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
      ? t('scorecard.awsReservedInstance.riMonthlyAverageUtilization')
      : t('scorecard.awsReservedInstance.riMonthlyAverageCoverage');
  };

  /**
   * @function getGraphData
   * @description Function to return the chart data
   * @param graph graph for which the data is fetched
   * @param reportType report type for which the data is fetched. UTILIZATION or COVERAGE
   * @returns list of coverage or utilization data
   */
  const getGraphData = (graph: string, reportType: string) => {
    switch (graph) {
      case AWS_RI_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? computeMonthlyUtilization
          : computeMonthlyCoverage;

      case AWS_RI_SERVICES.AMAZON_ELASTICACHE:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? elastiCacheMonthlyUtilization
          : elastiCacheMonthlyCoverage;

      case AWS_RI_SERVICES.AMAZON_MEMORY_DB:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? memoryDbMonthlyUtilization
          : memoryDbMonthlyCoverage;

      case AWS_RI_SERVICES.AMAZON_ELASTIC_SEARCH:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? elasticSearchMonthlyUtilization
          : elasticSearchMonthlyCoverage;
      case AWS_RI_SERVICES.AMAZON_OPENSEARCH:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? openSearchMonthlyUtilization
          : openSearchMonthlyCoverage;
      case AWS_RI_SERVICES.AMAZON_REDSHIFT:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? redshiftMonthlyUtilization
          : redshiftMonthlyCoverage;
      case AWS_RI_SERVICES.AMAZON_RELATIONAL_DATABASE_SERVICE:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? rdsMonthlyUtilization
          : rdsMonthlyCoverage;

      default:
        return [];
    }
  };

  /**
   * @function getIsTableViewByGraph
   * @description Function to return if the table view is enabled or not
   * @param graph graph for which the data is required
   * @returns boolean value, true if enabaled else false
   */
  const getIsTableViewByGraph = (graph: string) => {
    switch (graph) {
      case AWS_RI_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE:
        return computeTableView;

      case AWS_RI_SERVICES.AMAZON_ELASTICACHE:
        return elastiCacheTableView;

      case AWS_RI_SERVICES.AMAZON_MEMORY_DB:
        return memoryDbTableView;

      case AWS_RI_SERVICES.AMAZON_ELASTIC_SEARCH:
        return elasticSearchTableView;

      case AWS_RI_SERVICES.AMAZON_OPENSEARCH:
        return openSearchTableView;

      case AWS_RI_SERVICES.AMAZON_REDSHIFT:
        return redshiftTableView;

      case AWS_RI_SERVICES.AMAZON_RELATIONAL_DATABASE_SERVICE:
        return rdsTableView;
    }
  };

  /**
   * @function getAveragePercentage
   * @description Function to return the average percentage of utilization or coverage
   * @param data list of data for which the average is calculated
   * @returns average percentage data
   */
  const getAveragePercentage = (data: MonthValueType[]) => {
    return data.reduce((sum, item) => sum + item.value, 0) / (data.length || 1);
  };

  /**
   * @function getPdfTableData
   * @description Function to insert index and format data for pdf export
   * @param data Data to be manipulated
   * @returns list of data for pdf export
   */
  const getPdfTableData = (data: MonthValueType[]) => {
    return insertIndex(data).map((item) => ({
      ...item,
      value: numberCommaSeparator(item.value),
    }));
  };

  /**
   * @function getExcelExportData
   * @description Function to ireturn the data for excel export
   * @param graph Graph for which the data is required
   * @param graphHeading heading of the graph
   * @returns Object containing the utilization and coverage data for excel export
   */
  const getExcelExportData = (graph: string, graphHeading: string) => {
    return {
      utilization: {
        sheetName: `${graphHeading} (${t(
          'scorecard.savingsTypes.utilization'
        )})`,
        ...getCoverageUtilizationData(graph).utilization,
      },
      coverage: {
        sheetName: `${graphHeading} (${t('scorecard.savingsTypes.coverage')})`,
        ...getCoverageUtilizationData(graph).coverage,
      },
    };
  };

  /**
   * @function tooltipFormulaeContent
   * @description Function to return the tooltip content formulae for calculating utilization or coverage
   * @param reportType type of report selected
   * @returns JSX Element containing the formulae for calculating utilization or coverage
   */
  const tooltipFormulaeContent = (reportType: string) => (
    <div className="table-typography flex flex-align-items-center flex-gap-4">
      <div className="font-button">
        {reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? t('scorecard.savingsTypes.utilization')
          : t('scorecard.savingsTypes.coverage')}
        =
      </div>
      <div>
        <span>{t('scorecard.awsReservedInstance.riCoveredCosts')}</span>
        <Divider className="formulae-divider" />
        <span>
          {reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
            ? t('scorecard.awsReservedInstance.onDemandSpendEquivalent')
            : t('scorecard.awsReservedInstance.totalCostAtOnDemandRates')}
        </span>
      </div>
      <span>*100</span>
    </div>
  );

  /**
   * @function getCoverageUtilizationData
   * @description Function to return the utilization and coverage data combined
   * @param graph graph for which the data is required
   * @returns Object containing the utilization and coverage data
   */
  const getCoverageUtilizationData = (graph: string) => {
    return {
      utilization: {
        data: getGraphData(graph, SAVING_PLAN_REPORT_TYPES.UTILIZATION),
        averagePercentage: getAveragePercentage(
          getGraphData(graph, SAVING_PLAN_REPORT_TYPES.UTILIZATION)
        ),
        tooltipContent: tooltipFormulaeContent(
          SAVING_PLAN_REPORT_TYPES.UTILIZATION
        ),
      },
      coverage: {
        data: getGraphData(graph, SAVING_PLAN_REPORT_TYPES.COVERAGE),
        averagePercentage: getAveragePercentage(
          getGraphData(graph, SAVING_PLAN_REPORT_TYPES.COVERAGE)
        ),
        tooltipContent: tooltipFormulaeContent(
          SAVING_PLAN_REPORT_TYPES.COVERAGE
        ),
      },
    };
  };

  /**
   * @function getCoverageUtilizationGraphComponent
   * @description Function to return the chart component
   * @param graph graph for which the chart component is fetched
   * @param reportType report type for which the chart component is fetched. UTILIZATION or COVERAGE
   * @param pdfView Optional param if the component is for pdf view
   * @returns JSX element
   */
  const getCoverageUtilizationGraphComponent = (
    graph: string,
    reportType?: string,
    pdfView: boolean = false
  ) => {
    switch (graph) {
      case AWS_RI_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE:
        return (
          <CoverageUtilizationGraph
            heading={getAwsRiServiceLabels(graph)}
            graphName={AWS_RI_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE}
            pdfView={pdfView}
            isTableView={computeTableView}
            setIsTableView={setComputeTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              computeUtilizationRequestStatus,
              computeCoverageRequestStatus,
            ]}
            selectedReportType={getReportType(computeReportType, reportType)}
            setSelectedReportType={setComputeReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(computeReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(computeReportType, reportType)
            )}
          />
        );

      case AWS_RI_SERVICES.AMAZON_ELASTICACHE:
        return (
          <CoverageUtilizationGraph
            heading={getAwsRiServiceLabels(graph)}
            graphName={AWS_RI_SERVICES.AMAZON_ELASTICACHE}
            pdfView={pdfView}
            isTableView={elastiCacheTableView}
            setIsTableView={setElastiCacheTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              elastiCacheUtilizationRequestStatus,
              elastiCacheCoverageRequestStatus,
            ]}
            selectedReportType={getReportType(
              elastiCacheReportType,
              reportType
            )}
            setSelectedReportType={setElastiCacheReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(elastiCacheReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(elastiCacheReportType, reportType)
            )}
          />
        );

      case AWS_RI_SERVICES.AMAZON_MEMORY_DB:
        return (
          <CoverageUtilizationGraph
            heading={getAwsRiServiceLabels(graph)}
            graphName={AWS_RI_SERVICES.AMAZON_MEMORY_DB}
            pdfView={pdfView}
            isTableView={memoryDbTableView}
            setIsTableView={setMemoryDbTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              memoryDbUtilizationRequestStatus,
              memoryDbCoverageRequestStatus,
            ]}
            selectedReportType={getReportType(memoryDbReportType, reportType)}
            setSelectedReportType={setMemoryDbReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(memoryDbReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(memoryDbReportType, reportType)
            )}
          />
        );

      case AWS_RI_SERVICES.AMAZON_ELASTIC_SEARCH:
        return (
          <CoverageUtilizationGraph
            heading={getAwsRiServiceLabels(graph)}
            graphName={AWS_RI_SERVICES.AMAZON_ELASTIC_SEARCH}
            pdfView={pdfView}
            isTableView={elasticSearchTableView}
            setIsTableView={setElasticSearchTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              elasticSearchUtilizationRequestStatus,
              elasticSearchCoverageRequestStatus,
            ]}
            selectedReportType={getReportType(
              elasticSearchReportType,
              reportType
            )}
            setSelectedReportType={setElasticSearchReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(elasticSearchReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(elasticSearchReportType, reportType)
            )}
          />
        );

      case AWS_RI_SERVICES.AMAZON_OPENSEARCH:
        return (
          <CoverageUtilizationGraph
            heading={getAwsRiServiceLabels(graph)}
            graphName={AWS_RI_SERVICES.AMAZON_OPENSEARCH}
            pdfView={pdfView}
            isTableView={openSearchTableView}
            setIsTableView={setOpenSearchTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              openSearchUtilizationRequestStatus,
              openSearchCoverageRequestStatus,
            ]}
            selectedReportType={getReportType(openSearchReportType, reportType)}
            setSelectedReportType={setOpenSearchReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(openSearchReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(openSearchReportType, reportType)
            )}
          />
        );

      case AWS_RI_SERVICES.AMAZON_REDSHIFT:
        return (
          <CoverageUtilizationGraph
            heading={getAwsRiServiceLabels(graph)}
            graphName={AWS_RI_SERVICES.AMAZON_REDSHIFT}
            pdfView={pdfView}
            isTableView={redshiftTableView}
            setIsTableView={setRedshiftTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              redshiftUtilizationRequestStatus,
              redshiftCoverageRequestStatus,
            ]}
            selectedReportType={getReportType(redshiftReportType, reportType)}
            setSelectedReportType={setRedshiftReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(redshiftReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(redshiftReportType, reportType)
            )}
          />
        );

      case AWS_RI_SERVICES.AMAZON_RELATIONAL_DATABASE_SERVICE:
        return (
          <CoverageUtilizationGraph
            heading={getAwsRiServiceLabels(graph)}
            graphName={AWS_RI_SERVICES.AMAZON_RELATIONAL_DATABASE_SERVICE}
            pdfView={pdfView}
            isTableView={rdsTableView}
            setIsTableView={setRdsTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              rdsUtilizationRequestStatus,
              rdsCoverageRequestStatus,
            ]}
            selectedReportType={getReportType(rdsReportType, reportType)}
            setSelectedReportType={setRdsReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(rdsReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(rdsReportType, reportType)
            )}
          />
        );
    }
  };

  /**
   * @function getPdfExportContent
   * @description Function to return the pdf export content
   * @returns List of pdf content for all the graphs and report type
   */
  const getPdfExportContent = () => {
    const content: any[] = [];
    Object.values(AWS_RI_SERVICES).forEach((graph) => {
      Object.values(SAVING_PLAN_REPORT_TYPES).forEach((reportType) => {
        content.push({
          element: getCoverageUtilizationGraphComponent(
            graph,
            reportType,
            true
          ),
          contentType: CHART_TYPES.BAR_LINE_CHART,
          graphName: graph,
          isTableView: getIsTableViewByGraph(graph),
          column: UtilizationCoverageExportColumns,
          body: getPdfTableData(getGraphData(graph, reportType)),
          tableName: `${getAwsRiServiceLabels(graph)} (${
            reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
              ? t('scorecard.savingsTypes.utilization')
              : t('scorecard.savingsTypes.coverage')
          })`,
          tableFooterData: {
            totalTableData: [
              '',
              t('scorecard.committedUsageDiscount.monthlyAverage'),
              `${numberCommaSeparator(
                getAveragePercentage(getGraphData(graph, reportType))
              )}%`,
            ],
          },
        });
      });
    });

    return content;
  };

  return (
    <>
      <div className="flex flex-column flex-gap-16">
        {getCoverageUtilizationGraphComponent(
          AWS_RI_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE
        )}
        {getCoverageUtilizationGraphComponent(
          AWS_RI_SERVICES.AMAZON_ELASTICACHE
        )}
        {getCoverageUtilizationGraphComponent(AWS_RI_SERVICES.AMAZON_MEMORY_DB)}
        {getCoverageUtilizationGraphComponent(
          AWS_RI_SERVICES.AMAZON_ELASTIC_SEARCH
        )}
        {getCoverageUtilizationGraphComponent(
          AWS_RI_SERVICES.AMAZON_OPENSEARCH
        )}
        {getCoverageUtilizationGraphComponent(AWS_RI_SERVICES.AMAZON_REDSHIFT)}
        {getCoverageUtilizationGraphComponent(
          AWS_RI_SERVICES.AMAZON_RELATIONAL_DATABASE_SERVICE
        )}
      </div>
      {showExpandGraphModal &&
        selectedPurchaseTacticsTab ===
          PURCHASE_TACTICS_TAB.RESERVED_INSTANCE && (
          <ExpandModal
            show={showExpandGraphModal}
            graphContent={getCoverageUtilizationGraphComponent(
              expandedGraphName
            )}
            width={'100%'}
          />
        )}

      {selectedPurchaseTacticsTab ===
        PURCHASE_TACTICS_TAB.RESERVED_INSTANCE && (
        <PdfDownloadComponent
          pdfContent={getPdfExportContent()}
          pdfMetaData={{
            viewName: t('scorecard.purchaseTactics.tabs.reservedInstance'),
            fileName: selectedConnection?.name ?? '',
            heading: selectedConnection?.name ?? '',
            subtitle1: selectedConnection?.dataSourceType ?? '',
            provider: selectedConnection?.provider ?? '',
          }}
        />
      )}
    </>
  );
};

export default ReservedInstance;
