import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function installGCPGranulate
 * @description Function to get install granulate for GCP
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const installGCPGranulate = async (body: any) => {
  return ServiceCalls.post(APIS.GRANULATE_GCP_INSTALL, body);
};

/**
 * @function getGranulateDashboardServices
 * @description Function to fetch Granulate Dashboard services
 * @return axios response from GET request
 */
export const getGranulateDashboardServices = async () => {
  return ServiceCalls.get(APIS.GRANULATE_DASHBOARD_SERVICES);
};

/**
 * @function getGCPClusterData
 * @description Function to get the cluster based on connectorId and zone
 * @param connectorId string key parameter for searching
 * @param zone string key parameter for searching
 * @return axios response from GET request
 */
export const getGCPClusterData = async (params: {
  connectorId: string;
  zoneId: string;
}) => {
  return ServiceCalls.get(APIS.GRANULATE_GCP_CLUSTER, null, params);
};

/**
 * @function installGCPGranulate
 * @description Function to get install granulate for AWS
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const installAWSGranulate = async (body: any) => {
  return ServiceCalls.post(APIS.GRANULATE_AWS_INSTALL, body);
};

/**
 * @function getAWSClusterData
 * @description Function to get the cluster based on connectorId and zone
 * @param connectorId string key parameter for searching
 * @param zone string key parameter for searching
 * @return axios response from GET request
 */
export const getAWSClusterData = async (params: {
  connectorId: string;
  region: string;
}) => {
  return ServiceCalls.get(APIS.GRANULATE_AWS_CLUSTER, null, params);
};

/**
 * @function uninstallAWSGranulate
 * @description Function to get uninstall granulate for AWS
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const uninstallAWSGranulate = async (body: any) => {
  return ServiceCalls.post(APIS.GRANULATE_AWS_UNINSTALL, body);
};

/**
 * @function uninstallGCPGranulate
 * @description Function to get uninstall granulate for GCP
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const uninstallGCPGranulate = async (body: any) => {
  return ServiceCalls.post(APIS.GRANULATE_GCP_UNINSTALL, body);
};
