import moment from 'moment';
import i18n from 'i18n';
import { PROVIDER } from 'constants/cloudProviders';
import { ChartCriteria } from 'constants/dashboard';
import {
  AGGREGATORS,
  COMPARATORS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { OTHERS_LABEL } from 'constants/graphLabels';
import { store } from 'redux/store';
import {
  ConnectionListType,
  DashboardMetaData,
  TagsFilterType,
} from 'types/dashboard';
import { DashboardListType } from 'types/navigationMenu';
import {
  ColouredCostByServiceType,
  ColouredCostByProjectType,
  ColouredCostByResourceType,
  MonthlyCostByNameDataType,
} from 'types/dataTypes';
import {
  generateGraphColors,
  getAzureTagFiltersData,
  getGCPTagFiltersData,
  getMonthAndYearLabels,
  getOCITagFiltersData,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  formatArrayToStringByProvider,
  numberCommaSeparator,
} from 'utils/dataFormatterUtils';
import {
  MONTH_YEAR_FORMAT,
  formatDateFieldByProvider,
  getMonthYearShortList,
} from 'utils/date';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

/**
 * @function getProjectCriteriaByProvider
 * @description Returns the projects equivalent chart criteria by provider
 * @param provider cloud provider
 * @returns Object having details on the chart criteria for project equivalent
 */
const getProjectCriteriaByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return {
        title: i18n.t('resourceGroup'),
        value: ChartCriteria.RESOURCE_GROUP,
      };

    case PROVIDER.OCI:
      return { title: i18n.t('compartment'), value: ChartCriteria.COMPARTMENT };

    case PROVIDER.GCP:
    default:
      return { title: i18n.t('project'), value: ChartCriteria.PROJECT };
  }
};

/**
 * @function getChartCriteriaList
 * @description Returns the list of chart criteria for export and render.
 * @param provider cloud provider
 * @returns list of chart criteria
 */
export const getChartCriteriaList = (provider: string) => [
  {
    title: i18n.t('timePeriod'),
    value: ChartCriteria.TIME_PERIOD,
  },
  getProjectCriteriaByProvider(provider),
  {
    title: i18n.t('service'),
    value: ChartCriteria.SERVICE,
  },
  {
    title: i18n.t('resource'),
    value: ChartCriteria.RESOURCE,
  },
];

const getTopProjectsChartHeadingByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('graphHeadings.topResourceGroups');

    case PROVIDER.OCI:
      return i18n.t('projectDeepDiveSummary.costTable.topCompartments');

    case PROVIDER.GCP:
    default:
      return i18n.t('projectDeepDiveSummary.costTable.projects.topProjects');
  }
};

/**
 * @function getTopServiceAggregatorsChartHeading
 * @description Returns the top projects table/chart heading for export and render.
 * @param selectedProject project selected in drilldown
 * @param selectedService selected service selected in drilldown
 * @returns top projects table/chart heading
 */
export const getTopServiceAggregatorsChartHeading = (
  selectedProject: string,
  selectedService: string,
  provider: string
) => {
  if (!selectedProject) {
    return getTopProjectsChartHeadingByProvider(provider);
  }
  if (!selectedService) return i18n.t('graphHeadings.costByService');
  return i18n.t('graphHeadings.costByResources');
};

/**
 * @function getTopServiceAggregatorsDataSource
 * @description Returns the top projects table/chart data source for export and render.
 * @param topProjectsData top projects data
 * @param projectWiseServiceData project wise service data
 * @param serviceWiseResourceData service wise resource data
 * @param selectedProject project selected in drilldown
 * @param selectedService selected service selected in drilldown
 * @returns top projects table/chart data source
 */
export const getTopServiceAggregatorsDataSource = (
  topProjectsData: ColouredCostByProjectType[],
  projectWiseServiceData: ColouredCostByServiceType[],
  serviceWiseResourceData: ColouredCostByResourceType[],
  selectedProject: string,
  selectedService: string
) => {
  if (!selectedProject) return topProjectsData;
  else if (!selectedService) return projectWiseServiceData;
  else return serviceWiseResourceData;
};

/**
 * @function getTopServiceAggregatorsColumns
 * @description Returns the top projects/resource groups columns for export and render.
 * @param serviceAggregatorLabel service aggregator label
 * @param selectedServiceAggregator selected service aggregator
 * @param selectedService selected service
 * @returns top projects/resource groups columns
 */
export const getTopServiceAggregatorsColumns = (
  serviceAggregatorLabel: string,
  selectedServiceAggregator: string,
  selectedService: string
) => {
  let columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '3%',
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: serviceAggregatorLabel,
      dataIndex: 'project',
      key: 'project',
      width: '20%',
    },
    {
      title: i18n.t('projectDeepDiveSummary.costTable.costInCurrency', {
        currencySymbol,
      }),
      dataIndex: 'cost',
      key: 'cost',
      width: '8%',
      align: 'center',
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  if (!selectedServiceAggregator) return columns;
  else if (!selectedService)
    columns.splice(1, 1, {
      title: i18n.t('projectDeepDiveSummary.costTable.service'),
      dataIndex: 'service',
      key: 'service',
      width: '20%',
    });
  else
    columns.splice(1, 1, {
      title: i18n.t('projectDeepDiveSummary.costTable.resource'),
      dataIndex: 'resource',
      key: 'resource',
      width: '20%',
    });
  return columns;
};

/**
 * @function getProjectColumnNameByProvider
 * @description Returns the project column title string based on the provider given
 * @param provider Provider for which the string is return
 * @returns Project column title string based on the provider
 */
const getProjectColumnNameByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return 'resourceGroup';

    case PROVIDER.OCI:
      return 'compartment';

    case PROVIDER.GCP:
    default:
      return 'project';
  }
};

/**
 * @function getCostTimeLineDataColumns
 * @description Returns the cost time line data columns for export and render.
 * @param graph graph name
 * @param startDate start date filter
 * @param endDate end date filter
 * @returns cost time line data columns
 */
export const getCostTimeLineDataColumns = (
  provider: string,
  graph: string,
  startDate: string,
  endDate: string
) => [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    width: 40,
    render: (_text: any, _record: any, index: number) => index + 1,
    fixed: true,
  },
  {
    title: i18n.t(
      `projectDeepDiveSummary.projectTimeLine.${
        graph === 'service-time-line'
          ? 'service'
          : getProjectColumnNameByProvider(provider)
      }`
    ),
    dataIndex: `${graph === 'service-time-line' ? 'name' : 'project'}`,
    key: `${graph === 'service-time-line' ? 'name' : 'project'}`,
    width: 150,
    fixed: true,
  },
  ...getMonthYearShortList(startDate, endDate, MONTH_YEAR_FORMAT).map(
    (month) => ({
      title: month,
      key: `${month}-cost`,
      dataIndex: `${month}-cost`,
      width: 100,
      render: (text: any) => {
        return currencySymbol + numberCommaSeparator(text);
      },
    })
  ),
];

/**
 * @function getDateAndServiceAggregatorFilterQuery
 * @description Returns the date filter query by provider
 * @param startDate start date
 * @param endDate end date
 * @param selectedServiceAggregators selected service aggregators
 * @param selectedDashboard Dashboard details
 * @param selectedConnection Object containing the connection details
 * @returns date filter query
 */
const getDateAndServiceAggregatorFilterQuery = (
  startDate: string,
  endDate: string,
  selectedServiceAggregators: string[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType
) => {
  const isStaticData = isDashboardWithStaticData(selectedDashboard);

  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                isStaticData
                  ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                  : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                startDate,
                PROVIDER.AZURE,
                isDashboardWithStaticData(selectedDashboard),
                selectedConnection.focusConversionEnabled
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                isStaticData
                  ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_END_DATE
                  : QUERY_FIELDS.BILLING_PERIOD_END_DATE,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                endDate,
                PROVIDER.AZURE,
                isDashboardWithStaticData(selectedDashboard),
                selectedConnection.focusConversionEnabled
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.RESOURCE_GROUP,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.IN,
              value: formatArrayToStringByProvider(
                selectedServiceAggregators,
                selectedDashboard.connectorProvider
              ),
            },
          ],
        },
      ];

    case PROVIDER.OCI:
      return [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END_YYYY_MM_DD,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                startDate,
                PROVIDER.OCI,
                isDashboardWithStaticData(selectedDashboard),
                selectedConnection.focusConversionEnabled
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END_YYYY_MM_DD,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                endDate,
                PROVIDER.OCI,
                isDashboardWithStaticData(selectedDashboard),
                selectedConnection.focusConversionEnabled
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.PRD_COMPARTMENT_NAME,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.IN,
              value: formatArrayToStringByProvider(
                selectedServiceAggregators,
                selectedDashboard.connectorProvider
              ),
            },
          ],
        },
      ];

    case PROVIDER.GCP:
    default:
      return [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.USAGE_START_TIME,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: startDate,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.USAGE_END_TIME,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: endDate,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.PROJECT_NAME,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.IN,
              value: formatArrayToStringByProvider(
                selectedServiceAggregators,
                selectedDashboard.connectorProvider
              ),
            },
          ],
        },
      ];
  }
};

/**
 * @function getTopServiceAggregatorsQuery
 * @description Returns the query to fetch the top service aggregators
 * @param selectedDashboard selected dashboard data
 * @param selectedConnection Object containing the connection details
 * @param selectedDashboardView id of selected view
 * @param selectedServiceAggregators List of selected service aggregators
 * @param tagsFilters List of tag filters
 * @param dateFilter Start and end date
 * @returns Query Object for top service aggregators
 */
const getTopServiceAggregatorsQuery = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  selectedServiceAggregators: string[],
  tagsFilters: TagsFilterType[],
  dateFilter: {
    startDate: string;
    endDate: string;
  }
) => {
  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return {
        columns: [
          {
            label: 'project',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.RESOURCE_GROUP,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_BILLING_CURRENCY,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['project'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            selectedServiceAggregators,
            selectedDashboard,
            selectedConnection
          ),
          ...getAzureTagFiltersData(
            tagsFilters,
            selectedDashboard.id,
            selectedDashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.OCI:
      return {
        columns: [
          {
            label: 'project',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.PRD_COMPARTMENT_NAME,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_MY_COST,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['project'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            selectedServiceAggregators,
            selectedDashboard,
            selectedConnection
          ),
          ...getOCITagFiltersData(
            tagsFilters,
            selectedDashboard.id,
            selectedDashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.GCP:
    default:
      return {
        columns: [
          {
            label: 'project',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['project'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: getDateAndServiceAggregatorFilterQuery(
          dateFilter.startDate,
          dateFilter.endDate,
          selectedServiceAggregators,
          selectedDashboard,
          selectedConnection
        ),
        keyValueStructFilterGroups: getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard.id,
          selectedDashboardView
        ),
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };
  }
};

/**
 * @function getTopServiceAggregatorsData
 * @description Function to fetch the cost data for top projects/resource groups
 */
export const getTopServiceAggregatorsData = (
  tagsFilters: TagsFilterType[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  setRequestStatus: (value: string) => void,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregators: string[],
  setTopServiceAggregatorsData: (value: any[]) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const requestBody = getTopServiceAggregatorsQuery(
    selectedDashboard,
    selectedConnection,
    selectedDashboardView,
    selectedServiceAggregators,
    tagsFilters,
    dateFilter
  );

  getChartData(requestBody, selectedDashboard.connectorId)
    .then((res: any) => {
      const data =
        res?.data?.filter(
          (item: any) =>
            (selectedDashboard.connectorProvider === PROVIDER.OCI
              ? item.COST
              : item.cost) > 0
        ) ?? [];
      const colors = generateGraphColors(data.length);
      setTopServiceAggregatorsData(
        data.map((item: any, index: number) => ({
          ...(selectedDashboard.connectorProvider === PROVIDER.OCI
            ? { project: item.PROJECT, cost: item.COST }
            : item),
          color: colors[index],
        }))
      );

      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getCostByServiceForServiceAggregatorQuery
 * @description Returns the cost by service for selected project/resource group query
 * @param dashboardMetaData dashboard meta data
 * @param selectedConnection Object containing the connection details
 * @param dateFilter date filter
 * @param selectedServiceAggregator selected service aggregator
 * @returns Query for cost by service for service aggregator
 */
const getCostByServiceForServiceAggregatorQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedConnection: ConnectionListType,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregator: string
) => {
  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return {
        columns: [
          {
            label: 'service',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.CONSUMED_SERVICE,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.COST_BILLING_CURRENCY,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['service'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            [selectedServiceAggregator],
            dashboardMetaData.dashboard,
            selectedConnection
          ),
          ...getAzureTagFiltersData(
            dashboardMetaData.tagsFilters,
            dashboardMetaData.dashboard.id,
            dashboardMetaData.dashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.OCI:
      return {
        columns: [
          {
            label: 'service',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRD_SERVICE,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.COST_MY_COST,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['service'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            [selectedServiceAggregator],
            dashboardMetaData.dashboard,
            selectedConnection
          ),
          ...getOCITagFiltersData(
            dashboardMetaData.tagsFilters,
            dashboardMetaData.dashboard.id,
            dashboardMetaData.dashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.GCP:
    default:
      return {
        columns: [
          {
            label: 'service',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['service'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: getDateAndServiceAggregatorFilterQuery(
          dateFilter.startDate,
          dateFilter.endDate,
          [selectedServiceAggregator],
          dashboardMetaData.dashboard,
          selectedConnection
        ),
        keyValueStructFilterGroups: getGCPTagFiltersData(
          dashboardMetaData.tagsFilters,
          dashboardMetaData.dashboard.id,
          dashboardMetaData.dashboardView
        ),
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };
  }
};

/**
 * @function getCostByServiceForServiceAggregator
 * @description Function to fetch the cost by service for selected project/resource group
 * @param dashboardMetaData dashboard meta data
 * @param selectedConnection Object containing the connection details
 * @param setRequestStatus set request status
 * @param dateFilter date filter
 * @param selectedServiceAggregator selected service aggregator
 * @param serviceAggregatorWiseServiceData service aggregator wise service data
 * @param setServiceAggregatorWiseServiceData set service aggregator wise service data
 */
export const getCostByServiceForServiceAggregator = (
  dashboardMetaData: DashboardMetaData,
  selectedConnection: ConnectionListType,
  setRequestStatus: (value: string) => void,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregator: string,
  serviceAggregatorWiseServiceData: {
    [key: string]: ColouredCostByServiceType[];
  },
  setServiceAggregatorWiseServiceData: (value: {
    [key: string]: ColouredCostByServiceType[];
  }) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);
  const requestBody = getCostByServiceForServiceAggregatorQuery(
    dashboardMetaData,
    selectedConnection,
    dateFilter,
    selectedServiceAggregator
  );

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      const data =
        res?.data?.filter(
          (item: any) =>
            (dashboardMetaData.dashboard.connectorProvider === PROVIDER.OCI
              ? item.COST
              : item.cost) > 0
        ) ?? [];
      const colors = generateGraphColors(data.length);
      setServiceAggregatorWiseServiceData({
        ...serviceAggregatorWiseServiceData,
        [selectedServiceAggregator ?? '']: data.map(
          (item: any, index: number) => ({
            ...(dashboardMetaData.dashboard.connectorProvider === PROVIDER.OCI
              ? { cost: item.COST, service: item.SERVICE }
              : item),
            color: colors[index],
          })
        ),
      });
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getCostByResourceForServiceQuery
 * @description Returns the cost by resource for selected service Query
 * @param dashboardMetaData dashboard meta data
 * @param selectedConnection Object containing the connection details
 * @param dateFilter date filter
 * @param selectedServiceAggregator selected service aggregator
 * @param selectedService selected service
 * @returns Query Object containing the query for cost by resource for a service
 */
const getCostByResourceForServiceQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedConnection: ConnectionListType,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregator: string,
  selectedService: string
) => {
  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return {
        columns: [
          {
            label: 'resource',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.RESOURCE_ID,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.COST_BILLING_CURRENCY,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['resource'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            [selectedServiceAggregator],
            dashboardMetaData.dashboard,
            selectedConnection
          ),
          {
            filters: [
              {
                field: getQueryFieldByDataSource(
                  dashboardMetaData.dashboard.dashBoardType,
                  QUERY_FIELDS.CONSUMED_SERVICE,
                  selectedConnection.focusConversionEnabled
                ),
                comparator: COMPARATORS.EQUALS,
                value: selectedService,
              },
            ],
          },
          ...getAzureTagFiltersData(
            dashboardMetaData.tagsFilters,
            dashboardMetaData.dashboard.id,
            dashboardMetaData.dashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.OCI:
      return {
        columns: [
          {
            label: 'name',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRD_RESOURCE,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.COST_MY_COST,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['name'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          {
            filters: [
              ...getDateAndServiceAggregatorFilterQuery(
                dateFilter.startDate,
                dateFilter.endDate,
                [selectedServiceAggregator],
                dashboardMetaData.dashboard,
                selectedConnection
              )[0].filters,
              {
                field: getQueryFieldByDataSource(
                  dashboardMetaData.dashboard.dashBoardType,
                  QUERY_FIELDS.PRD_SERVICE,
                  selectedConnection.focusConversionEnabled
                ),
                comparator: COMPARATORS.EQUALS,
                value: selectedService,
              },
            ],
          },
          ...getOCITagFiltersData(
            dashboardMetaData.tagsFilters,
            dashboardMetaData.dashboard.id,
            dashboardMetaData.dashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.GCP:
    default:
      return {
        columns: [
          {
            label: 'resource',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_RESOURCE_NAME,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['resource'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            [selectedServiceAggregator],
            dashboardMetaData.dashboard,
            selectedConnection
          ),
          {
            filters: [
              {
                field: getQueryFieldByDataSource(
                  dashboardMetaData.dashboard.dashBoardType,
                  QUERY_FIELDS.SERVICE_DESCRIPTION,
                  selectedConnection.focusConversionEnabled
                ),
                comparator: COMPARATORS.EQUALS,
                value: selectedService,
              },
            ],
          },
        ],
        keyValueStructFilterGroups: getGCPTagFiltersData(
          dashboardMetaData.tagsFilters,
          dashboardMetaData.dashboard.id,
          dashboardMetaData.dashboardView
        ),
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };
  }
};

/**
 * @function getCostByResourceForService
 * @description Function to fetch the cost by resource for selected service
 * @param dashboardMetaData dashboard meta data
 * @param selectedConnection Object containing the connection details
 * @param setRequestStatus set request status
 * @param dateFilter date filter
 * @param selectedServiceAggregator selected service aggregator
 * @param selectedService selected service
 * @param serviceWiseResourceData service wise resource data
 * @param setServiceWiseResourceData set service wise resource data
 */
export const getCostByResourceForService = (
  dashboardMetaData: DashboardMetaData,
  selectedConnection: ConnectionListType,
  setRequestStatus: (value: string) => void,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregator: string,
  selectedService: string,
  serviceWiseResourceData: {
    [key: string]: ColouredCostByResourceType[];
  },
  setServiceWiseResourceData: (value: {
    [key: string]: ColouredCostByResourceType[];
  }) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const requestBody = getCostByResourceForServiceQuery(
    dashboardMetaData,
    selectedConnection,
    dateFilter,
    selectedServiceAggregator,
    selectedService
  );

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      const data =
        res?.data?.filter(
          (item: any) =>
            (dashboardMetaData.dashboard.connectorProvider === PROVIDER.OCI
              ? item.COST
              : item.cost) > 0
        ) ?? [];
      const colors = generateGraphColors(data.length);
      setServiceWiseResourceData({
        ...serviceWiseResourceData,
        [`${selectedServiceAggregator}${selectedService}`]:
          data.map((item: any, index: number) => ({
            cost:
              dashboardMetaData.dashboard.connectorProvider === PROVIDER.OCI
                ? item.COST
                : item.cost,
            color: colors[index],
            resource:
              (dashboardMetaData.dashboard.connectorProvider === PROVIDER.OCI
                ? item.RESOURCE
                : item.resource) ?? OTHERS_LABEL,
          })) ?? [],
      });
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getServiceAggregatorTimeLineQuery
 * @description Returns the service aggregator timeline Query
 * @param selectedDashboard Selected Dashboard details
 * @param selectedConnection Oject containing the connection details
 * @param tagsFilters List of tags filters
 * @param selectedDashboardView Selected view ID
 * @param dateFilter date filter
 * @param selectedServiceAggregator selected service aggregator
 * @returns Query Object containing the query for service aggregator timeline query
 */
const getServiceAggregatorTimeLineQuery = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  tagsFilters: TagsFilterType[],
  selectedDashboardView: string,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregators: string[]
) => {
  const isStaticData = isDashboardWithStaticData(selectedDashboard);

  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return {
        columns: [
          {
            label: 'name',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.RESOURCE_GROUP,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_BILLING_CURRENCY,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'month',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['name', 'month'],
        orderBy: [
          {
            label: 'month',
            sort: ORDER_BY.ASCENDING,
          },
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            selectedServiceAggregators,
            selectedDashboard,
            selectedConnection
          ),
          ...getAzureTagFiltersData(
            tagsFilters,
            selectedDashboard.id,
            selectedDashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.OCI:
      return {
        columns: [
          {
            label: 'name',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.PRD_COMPARTMENT_NAME,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_MY_COST,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'month',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['name', 'month'],
        orderBy: [
          {
            label: 'month',
            sort: ORDER_BY.ASCENDING,
          },
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            selectedServiceAggregators,
            selectedDashboard,
            selectedConnection
          ),
          ...getOCITagFiltersData(
            tagsFilters,
            selectedDashboard.id,
            selectedDashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.GCP:
    default:
      return {
        columns: [
          {
            label: 'name',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'month',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['name', 'month'],
        orderBy: [
          {
            label: 'month',
            sort: ORDER_BY.ASCENDING,
          },
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: getDateAndServiceAggregatorFilterQuery(
          dateFilter.startDate,
          dateFilter.endDate,
          selectedServiceAggregators,
          selectedDashboard,
          selectedConnection
        ),
        keyValueStructFilterGroups: getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard.id,
          selectedDashboardView
        ),
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };
  }
};

/**
 * @function getServiceAggregatorTimeLineData
 * @description Function to fetch the cost data for top projects/resource groups over time
 */
export const getServiceAggregatorTimeLineData = (
  tagsFilters: TagsFilterType[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  setRequestStatus: (value: string) => void,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregators: string[],
  setServiceAggregatorTimeLineData: (value: any[]) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const requestBody = getServiceAggregatorTimeLineQuery(
    selectedDashboard,
    selectedConnection,
    tagsFilters,
    selectedDashboardView,
    dateFilter,
    selectedServiceAggregators
  );

  getChartData(requestBody, selectedDashboard.connectorId)
    .then((res: any) => {
      let costByServiceAggregator: MonthlyCostByNameDataType[] = [];
      const fromMonthAndYear = dateFilter.startDate
        .replaceAll('-', '')
        .slice(0, -2);
      const toMonthAndYear = dateFilter.endDate
        .replaceAll('-', '')
        .slice(0, -2);
      let labels: string[] = getMonthAndYearLabels(
        fromMonthAndYear,
        toMonthAndYear
      );

      res?.data?.map((eachItem: any) => {
        const item =
          selectedDashboard.connectorProvider === PROVIDER.OCI
            ? {
                name: eachItem.NAME,
                month: eachItem.MONTH,
                cost: eachItem.COST,
              }
            : { ...eachItem };
        const existingIndex = costByServiceAggregator?.findIndex(
          (eachItem) => eachItem.name === item.name
        );
        // Check if item.month is 5 or 6 digits, as the month can be in YYYYM or YYYYMM format
        const monthIndexStart = item.month.length === 5 ? -1 : -2;
        const month =
          moment.monthsShort()[Number(item.month.slice(monthIndexStart)) - 1];
        const label = month + ' ' + item?.month.slice(2, 4);
        const index = labels.findIndex((eachLabel) => eachLabel === label);

        if (existingIndex >= 0) {
          costByServiceAggregator[existingIndex].costData[index] = item?.cost;
        } else {
          let costData: number[] = Array(24).fill(0);
          costData[index] = item?.cost;
          costByServiceAggregator.push({
            name: item.name,
            costData: costData,
            project: item.name,
          });
        }
        return item;
      });
      setServiceAggregatorTimeLineData(costByServiceAggregator);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getServiceTimeLineQuery
 * @description Returns the service timeline Query
 * @param selectedDashboard Selected Dashboard details
 * @param selectedConnection Selected Dashboard connection details
 * @param tagsFilters List of tags filters
 * @param selectedDashboardView Selected view ID
 * @param dateFilter date filter
 * @param selectedServiceAggregator selected service aggregator
 * @returns Query Object containing the query for service timeline query
 */
const getServiceTimeLineQuery = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  tagsFilters: TagsFilterType[],
  selectedDashboardView: string,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregators: string[]
) => {
  const isStaticData = isDashboardWithStaticData(selectedDashboard);

  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return {
        columns: [
          {
            label: 'name',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.CONSUMED_SERVICE,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_BILLING_CURRENCY,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'month',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['name', 'month'],
        orderBy: [
          {
            label: 'month',
            sort: ORDER_BY.ASCENDING,
          },
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            selectedServiceAggregators,
            selectedDashboard,
            selectedConnection
          ),
          ...getAzureTagFiltersData(
            tagsFilters,
            selectedDashboard.id,
            selectedDashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.OCI:
      return {
        columns: [
          {
            label: 'name',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.PRD_SERVICE,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_MY_COST,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'month',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['name', 'month'],
        orderBy: [
          {
            label: 'month',
            sort: ORDER_BY.ASCENDING,
          },
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            selectedServiceAggregators,
            selectedDashboard,
            selectedConnection
          ),
          ...getOCITagFiltersData(
            tagsFilters,
            selectedDashboard.id,
            selectedDashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.GCP:
    default:
      return {
        columns: [
          {
            label: 'name',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'month',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['name', 'month'],
        orderBy: [
          {
            label: 'month',
            sort: ORDER_BY.ASCENDING,
          },
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: getDateAndServiceAggregatorFilterQuery(
          dateFilter.startDate,
          dateFilter.endDate,
          selectedServiceAggregators,
          selectedDashboard,
          selectedConnection
        ),
        keyValueStructFilterGroups: getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard.id,
          selectedDashboardView
        ),
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };
  }
};

/**
 * @function getServiceTimeLineData
 * @description Function to fetch the cost data for top services over time
 */
export const getServiceTimeLineData = (
  tagsFilters: TagsFilterType[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  setRequestStatus: (value: string) => void,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregators: string[],
  setServiceTimeLineData: (value: any[]) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);
  const requestBody = getServiceTimeLineQuery(
    selectedDashboard,
    selectedConnection,
    tagsFilters,
    selectedDashboardView,
    dateFilter,
    selectedServiceAggregators
  );

  getChartData(requestBody, selectedDashboard.connectorId)
    .then((res: any) => {
      let costByServiceAggregator: MonthlyCostByNameDataType[] = [];
      const fromMonthAndYear = dateFilter.startDate
        .replaceAll('-', '')
        .slice(0, -2);
      const toMonthAndYear = dateFilter.endDate
        .replaceAll('-', '')
        .slice(0, -2);
      let labels: string[] = getMonthAndYearLabels(
        fromMonthAndYear,
        toMonthAndYear
      );

      res.data.map((eachItem: any) => {
        const item =
          selectedDashboard.connectorProvider === PROVIDER.OCI
            ? {
                name: eachItem.NAME,
                month: eachItem.MONTH,
                cost: eachItem.COST,
              }
            : { ...eachItem };
        const existingIndex = costByServiceAggregator.findIndex(
          (eachItem) => eachItem.name === item.name
        );
        // Check if item.month is 5 or 6 digits, as the month can be in YYYYM or YYYYMM format
        const monthIndexStart = item.month.length === 5 ? -1 : -2;
        const month =
          moment.monthsShort()[Number(item.month.slice(monthIndexStart)) - 1];
        const label = month + ' ' + item.month.slice(2, 4);
        const index = labels.findIndex((eachLabel) => eachLabel === label);

        if (existingIndex >= 0) {
          costByServiceAggregator[existingIndex].costData[index] = item.cost;
        } else {
          let costData: number[] = Array(24).fill(0);
          costData[index] = item.cost;
          costByServiceAggregator.push({
            name: item.name,
            costData: costData,
          });
        }
        return item;
      });

      setServiceTimeLineData(costByServiceAggregator);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function fetchTopServicesQuery
 * @description Returns the top services Query
 * @param selectedDashboard Selected Dashboard details
 * @param selectedConnection Selected Connection details
 * @param tagsFilters List of tags filters
 * @param selectedDashboardView Selected view ID
 * @param dateFilter date filter
 * @param selectedServiceAggregator selected service aggregator
 * @returns Query Object containing the query for top services query
 */
const fetchTopServicesQuery = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  tagsFilters: TagsFilterType[],
  selectedDashboardView: string,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregators: string[]
) => {
  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return {
        columns: [
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_BILLING_CURRENCY,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'service',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.CONSUMED_SERVICE,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['service'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            selectedServiceAggregators,
            selectedDashboard,
            selectedConnection
          ),
          ...getAzureTagFiltersData(
            tagsFilters,
            selectedDashboard.id,
            selectedDashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.OCI:
      return {
        columns: [
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_MY_COST,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'service',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.PRD_SERVICE,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['service'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: [
          ...getDateAndServiceAggregatorFilterQuery(
            dateFilter.startDate,
            dateFilter.endDate,
            selectedServiceAggregators,
            selectedDashboard,
            selectedConnection
          ),
          ...getOCITagFiltersData(
            tagsFilters,
            selectedDashboard.id,
            selectedDashboardView
          ),
        ],
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };

    case PROVIDER.GCP:
    default:
      return {
        columns: [
          {
            label: 'service',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            label: 'cost',
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        aggregators: [
          {
            label: 'cost',
            function: AGGREGATORS.SUM,
          },
        ],
        groupBy: ['service'],
        orderBy: [
          {
            label: 'cost',
            sort: ORDER_BY.DESCENDING,
          },
        ],
        filterGroups: getDateAndServiceAggregatorFilterQuery(
          dateFilter.startDate,
          dateFilter.endDate,
          selectedServiceAggregators,
          selectedDashboard,
          selectedConnection
        ),
        keyValueStructFilterGroups: getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard.id,
          selectedDashboardView
        ),
        dashBoardType: DASHBOARD_TYPES.BILLING,
        cached: true,
      };
  }
};

/**
 * @function fetchTopServicesData
 * @description Function to fetch the cost data for top services
 */
export const fetchTopServicesData = (
  tagsFilters: TagsFilterType[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  setRequestStatus: (value: string) => void,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  selectedServiceAggregators: string[],
  setTopServicesData: (value: any[]) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const requestBody = fetchTopServicesQuery(
    selectedDashboard,
    selectedConnection,
    tagsFilters,
    selectedDashboardView,
    dateFilter,
    selectedServiceAggregators
  );

  getChartData(requestBody, selectedDashboard.connectorId)
    .then((res: any) => {
      const colors = generateGraphColors(res.data.length);
      setTopServicesData(
        res.data.map((item: any, index: number) => ({
          cost:
            selectedDashboard.connectorProvider === PROVIDER.OCI
              ? item.COST
              : item.cost,
          service:
            (selectedDashboard.connectorProvider === PROVIDER.OCI
              ? item.SERVICE
              : item.service) ?? OTHERS_LABEL,
          color: colors[index],
        }))
      );
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getServiceAggregatorLabelByProvider
 * @description Returns the service aggregator label by provider.
 * @param provider selected cloud provider
 * @returns service aggregator label -> projects/resource groups
 */
export const getServiceAggregatorLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('resourceGroups');

    case PROVIDER.OCI:
      return i18n.t('compartments');

    case PROVIDER.GCP:
    default:
      return i18n.t('projects');
  }
};

/**
 * @function getServiceAggregatorTimeLineGraphHeading
 * @description Returns the service aggregator time line graph heading for export and render.
 * @param provider selected cloud provider
 * @returns service aggregator time line graph heading -> projects/resource groups and cost time line
 */
export const getServiceAggregatorTimeLineGraphHeading = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('graphHeadings.costAndResourceGroupTimeline');

    case PROVIDER.OCI:
      return i18n.t(
        'projectDeepDiveSummary.projectTimeLine.costCompartmentsTimeLine'
      );

    case PROVIDER.GCP:
    default:
      return i18n.t(
        'projectDeepDiveSummary.projectTimeLine.costProjectTimeLine'
      );
  }
};

/**
 * @function getProjectDeepDiveDashboardViewName
 * @description Returns the project deep dive dashboard view name for export and render.
 * @param provider selected cloud provider
 * @returns project deep dive dashboard view name -> project/resource group deep dive
 */
export const getProjectDeepDiveDashboardViewName = (provider: string) =>
  provider === PROVIDER.GCP
    ? i18n.t('dashNav.projectDeepDive')
    : i18n.t('dashNav.resourceGroupDeepDive');

/**
 * @function getExcelServiceAggregatorFilterKey
 * @description Returns the service aggregator filter key for export and render.
 * @param provider selected cloud provider
 * @returns service aggregator filter key -> projects/resourceGroups/compartments
 */
export const getExcelServiceAggregatorFilterKey = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return 'resourceGroup';

    case PROVIDER.OCI:
      return 'compartment';

    case PROVIDER.GCP:
    default:
      return 'project';
  }
};

/**
 * @function getServiceAggregatorChartCriteriaKey
 * @description Returns the service aggregator chart criteria key for export and render.
 * @param provider selected cloud provider
 * @returns service aggregator chart criteria key -> PROJECT / RESOURCE_GROUP
 */
export const getServiceAggregatorChartCriteriaKey = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return ChartCriteria.RESOURCE_GROUP;

    case PROVIDER.OCI:
      return ChartCriteria.COMPARTMENT;

    case PROVIDER.GCP:
    default:
      return ChartCriteria.PROJECT;
  }
};
