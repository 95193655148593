import { useSelector } from 'react-redux';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import './index.scss';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';

const InfoCards = () => {
  const { currencySymbol } = useSelector(selectCommonUtility);

  const data = [
    {
      key: 1,
      heading: 'Selected Infrastructure Cost',
      cost: 216.3,
      change: 0,
      duration: 'per month',
    },
    {
      key: 2,
      heading: 'Selected Week Cost Saving with Granulate',
      cost: 14,
      change: -96.4,
      duration: 'from previous week',
    },
    {
      key: 3,
      heading: 'Selected Month Cost Saving with Granulate',
      cost: 58,
      change: -96.4,
      duration: 'from last month',
    },
    {
      key: 4,
      heading: 'Last two months cost savings with granulate',
      cost: 107,
      change: -96.4,
      duration: 'from previous 60 days',
    },
    {
      key: 5,
      heading: 'Last three months cost savings with granulate',
      cost: 200,
      change: 96.4,
      duration: 'from previous 90 days',
    },
  ];

  return (
    <div className="info-card flex flex-space-between" id="graph-container">
      {data.map((item) => (
        <div
          key={item.key}
          className="info-card-item flex flex-column flex-gap-12 flex-fit"
        >
          <span className="font-caption-bold info-card-item-heading">
            {item.heading}
          </span>
          <span className="font-graph-total">
            {currencySymbol} {item.cost}
          </span>
          <div className="font-annotation-light flex flex-gap-4 flex-align-items-center">
            {item.change !== 0 &&
              (item.change < 0 ? (
                <Icon iconName={ICONS.ARROW_RIGHT_DOWN_LINE} color="#d23927" />
              ) : (
                <Icon iconName={ICONS.ARROW_RIGHT_UP_LINE} color="#70ec48" />
              ))}
            <span
              className={`info-card-item-status ${
                item.change < 0 ? 'decrease' : 'increase'
              }`}
            >
              {item.change}%
            </span>
            <span>{item.duration}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfoCards;
