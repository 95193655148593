import i18n from 'i18n';
import { InstanceExcelColumns } from './constants';

export const getInstanceTableExcelData = (
  serviceName: string,
  data: {
    sheetName: string;
    tableData: any[];
  }[]
) => {
  return data.map((eachData) => ({
    sheetName: eachData.sheetName,
    columns: InstanceExcelColumns,
    data: eachData.tableData.map((item, index) => ({
      ...item,
      slNo: index + 1,
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.serviceName'),
        value: serviceName,
      },
    ],
  }));
};
