import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  setServiceNowData,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';
import SelectDropdown from 'components/Select';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import DrawerComponent from 'components/DrawerComponent';
import { BUTTON_TYPE } from 'constants/appearance';
import {
  getAssigneeGroups,
  getAssignToList,
  getCategories,
} from 'utils/services';
import { AssignmentToType } from 'pages/TicketIntegrationPage/types';
import { getValidationStyle, validateEmptyField } from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';

import { INCIDENT_TYPE_OPTIONS } from './constants';

type CreateTicketDrawerProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  selectedRecommendationsLength: number;
  onClickCreate: Function;
  loading: boolean;
  fetchServiceNowConnection: () => void;
  validationMessage: string;
};

const CreateTicketDrawer = ({
  show,
  setShow,
  selectedRecommendationsLength,
  onClickCreate,
  loading,
  fetchServiceNowConnection,
  validationMessage,
}: CreateTicketDrawerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ticketIntegrationData, serviceNowData } =
    useSelector(ticketIntegration);

  const [assignmentGroups, setAssignmentGroups] = useState<string[]>([]);
  const [assignmentToList, setAssignmentToList] = useState<AssignmentToType[]>(
    []
  );
  const [categories, setCategories] = useState<string[]>([]);
  const [
    assignmentGroupValidationMessage,
    setAssignmentGroupValidationMessage,
  ] = useState('');
  const [assignedToValidationMessage, setAssignedToValidationMessage] =
    useState('');
  const [categoryValidationMessage, setCategoryValidationMessage] =
    useState('');
  const [assignmentGroupsLoading, setAssignmentGroupsLoading] = useState(false);
  const [assigneeToListLoading, setAssigneeToListLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  useEffect(() => {
    if (show) {
      fetchServiceNowConnection();
    }
  }, [show]);

  useEffect(() => {
    if (!show) {
      return;
    }

    if (
      ticketIntegrationData.url &&
      ticketIntegrationData.usernameHost &&
      ticketIntegrationData.password
    ) {
      getAllGroups();
      getAllAssignees();
      getAllCategories();
      dispatch(
        setServiceNowData({
          ...serviceNowData,
          assignmentGroup: ticketIntegrationData.defaultAssignmentGroup,
          assignedTo: ticketIntegrationData.defaultAssignedTo,
          category: ticketIntegrationData.category,
        })
      );
    }
  }, [show, ticketIntegrationData]);

  /**
   * @function getAllGroups
   * @description Function to fetch all the service now groups.
   */
  const getAllGroups = () => {
    setAssignmentGroupsLoading(true);

    const requestBody = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
    };

    getAssigneeGroups(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setAssignmentGroups(res.data.responseData);
          setAssignmentGroupsLoading(false);
          return;
        }
        setAssignmentGroupsLoading(false);
        setAssignmentGroups([]);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setAssignmentGroupsLoading(false);
        setAssignmentGroups([]);
      });
  };

  /**
   * @function getAllAssignees
   * @description Function to fetch all the assignees list.
   */
  const getAllAssignees = () => {
    setAssigneeToListLoading(true);

    const requestBody = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
    };

    getAssignToList(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setAssigneeToListLoading(false);
          setAssignmentToList(res.data.responseData);
          return;
        }

        setAssigneeToListLoading(false);
        setAssignmentToList([]);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setAssigneeToListLoading(false);
        setAssignmentToList([]);
      });
  };

  /**
   * @function getAllCategories
   * @description Function to fetch all the categories
   */
  const getAllCategories = () => {
    setCategoriesLoading(true);

    const requestBody = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
    };

    getCategories(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setCategoriesLoading(false);
          setCategories(res.data.responseData);
          return;
        }

        setCategoriesLoading(false);
        setCategories([]);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setCategoriesLoading(false);
        setCategories([]);
      });
  };

  /**
   * @function getDropdown
   * @description Function to construct options list for dropdown.
   * @param data data for options.
   * @returns List of dropdown options
   */
  const getDropdown = (data: string[]) =>
    data?.map((option) => (
      <Select.Option key={option} value={option}>
        {option}
      </Select.Option>
    ));

  const AssignedToDropdown = (
    <>
      {assignmentToList?.map((option) => (
        <Select.Option key={option.email} value={option.email}>
          {option.name}
        </Select.Option>
      ))}
    </>
  );

  return (
    <DrawerComponent
      open={show}
      title={t('createTicketDrawer.createSnowIncident')}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-end">
          <Button
            title={t('createTicketDrawer.close')}
            onClick={() => setShow(false)}
            type={BUTTON_TYPE.LINK}
          />
          <Button
            title={t('createTicketDrawer.create')}
            onClick={onClickCreate}
            loading={loading}
          />
        </div>
      }
      dataTestId="create-ticket-drawer"
    >
      <div className="create-ticket-body flex flex-column">
        <div className="create-ticket-form flex flex-column flex-gap-16">
          {selectedRecommendationsLength > 1 && (
            <div className="form-item flex flex-column">
              <FormLabel
                title={t('createTicketDrawer.incidentType')}
                required
              />
              <Radio.Group
                className="flex flex-column"
                onChange={(event) =>
                  dispatch(
                    setServiceNowData({
                      ...serviceNowData,
                      incidentType: event.target.value,
                    })
                  )
                }
                value={serviceNowData.incidentType}
              >
                {INCIDENT_TYPE_OPTIONS.map((incident) => (
                  <Radio value={incident.type} key={incident.type}>
                    {t(`createTicketDrawer.${incident.title}`)}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          )}
          <div className="form-item flex flex-column">
            <FormLabel
              title={t(
                'ticketIntegrationLabels.formLabels.step2.defaultAssignmentGroup'
              )}
              required={true}
            />
            <SelectDropdown
              showSearch
              value={serviceNowData.assignmentGroup}
              placeholder={t(
                'ticketIntegrationLabels.formLabels.step2.defaultAssignmentGroupPlaceholder'
              )}
              menu={getDropdown(assignmentGroups)}
              onSelect={(value: any) => {
                validateEmptyField(
                  value,
                  t(
                    'ticketIntegrationLabels.formLabels.step2.defaultAssignmentGroup'
                  ),
                  setAssignmentGroupValidationMessage
                );
                dispatch(
                  setServiceNowData({
                    ...serviceNowData,
                    assignmentGroup: value,
                  })
                );
              }}
              onBlur={() => {
                validateEmptyField(
                  serviceNowData.assignmentGroup,
                  t(
                    'ticketIntegrationLabels.formLabels.step2.defaultAssignmentGroup'
                  ),
                  setAssignmentGroupValidationMessage
                );
              }}
              loading={assignmentGroupsLoading}
              designVersion2
            />
            <span
              style={{
                display: `${getValidationStyle(
                  assignmentGroupValidationMessage
                )}`,
              }}
              className="font-validation-error"
            >
              {assignmentGroupValidationMessage}
            </span>
          </div>
          <div className="form-item flex flex-column">
            <FormLabel
              title={t(
                'ticketIntegrationLabels.formLabels.step2.defaultAssignedTo'
              )}
              required={true}
            />
            <SelectDropdown
              showSearch
              value={serviceNowData.assignedTo}
              placeholder={t(
                'ticketIntegrationLabels.formLabels.step2.defaultAssignedToPlaceholder'
              )}
              menu={AssignedToDropdown}
              onSelect={(value: any) => {
                validateEmptyField(
                  value,
                  t(
                    'ticketIntegrationLabels.formLabels.step2.defaultAssignedTo'
                  ),
                  setAssignedToValidationMessage
                );
                dispatch(
                  setServiceNowData({
                    ...serviceNowData,
                    assignedTo: value,
                  })
                );
              }}
              onBlur={() => {
                validateEmptyField(
                  serviceNowData.assignedTo,
                  t(
                    'ticketIntegrationLabels.formLabels.step2.defaultAssignedTo'
                  ),
                  setAssignedToValidationMessage
                );
              }}
              loading={assigneeToListLoading}
              designVersion2
            />
            <span
              style={{
                display: `${getValidationStyle(assignedToValidationMessage)}`,
              }}
              className="font-validation-error"
            >
              {assignedToValidationMessage}
            </span>
          </div>
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('ticketIntegrationLabels.formLabels.step2.category')}
              required={true}
            />
            <SelectDropdown
              showSearch
              value={serviceNowData.category}
              placeholder={t(
                'ticketIntegrationLabels.formLabels.step2.categoryPlaceholder'
              )}
              menu={getDropdown(categories)}
              onSelect={(value: any) => {
                validateEmptyField(
                  value,
                  t('ticketIntegrationLabels.formLabels.step2.category'),
                  setCategoryValidationMessage
                );
                dispatch(
                  setServiceNowData({
                    ...serviceNowData,
                    category: value,
                  })
                );
              }}
              onBlur={() => {
                validateEmptyField(
                  serviceNowData.category,
                  t('ticketIntegrationLabels.formLabels.step2.category'),
                  setCategoryValidationMessage
                );
              }}
              loading={categoriesLoading}
              designVersion2
            />
            <span
              style={{
                display: `${getValidationStyle(categoryValidationMessage)}`,
              }}
              className="font-validation-error"
            >
              {categoryValidationMessage}
            </span>
          </div>
        </div>
        {validationMessage && (
          <div className="error-message flex flex-justify-content-center font-caption">
            {validationMessage}
          </div>
        )}
      </div>
    </DrawerComponent>
  );
};

export default CreateTicketDrawer;
