import { CHART_TYPES } from './graphConfig';

export const PDF_CONTENT_CONSTANTS = {
  initialPageOffset: 20,
  paddingLeftRight: 10,
  gapBetweenCostCards: 20,
  gapBetweenCharts: 20,
  CARDS_CHUNK_SIZE: 3,
};

export const PDF_META_DATA_CONSTANTS = {
  PAGE_X_AXIS_OFFSET: 10,
  PAGE_Y_AXIS_OFFSET: 10,
  CB_360_LOGO_WIDTH: 70,
  CB_360_LOGO_HEIGHT: 10,
  WATERMARK_X_AXIS_OFFSET_LANDSCAPE: 80,
  WATERMARK_Y_AXIS_OFFSET_LANDSCAPE: 30,
  WATERMARK_X_AXIS_OFFSET_PORTRAIT: 50,
  WATERMARK_Y_AXIS_OFFSET_PORTRAIT: 70,
  WATERMARK_WIDTH: 120,
  WATERMARK_HEIGHT: 120,
  FOOTER_FONT_SIZE: 10,
  DASHBOARD_TITLE_FONT_SIZE: 14,
  DASHBOARD_TITLE_X_AXIS_OFFSET: 27,
  DASHBOARD_TITLE_Y_AXIS_OFFSET: 15,
  DASHBOARD_SUBTITLE_Y_AXIS_OFFSET: 20,
  PRINT_DATE_Y_AXIS_OFFSET: 25,
  PIE_CHART_LEGEND_CONSTANTS: {
    LEGEND_CHART_X_OFFSET: 15,
    LEGEND_CHART_Y_OFFSET: 32,
    LEGEND_TABLE_TOP_OFFSET: 50,
    LEGEND_TABLE_BOTTOM_OFFSET: 25,
    LEGEND_TABLE_X_OFFSET: 170,
  },
  MAP_LEGEND_CONSTANTS: {
    LEGEND_CHART_X_OFFSET: 15,
    LEGEND_CHART_Y_OFFSET: 32,
    LEGEND_TABLE_TOP_OFFSET: 50,
    LEGEND_TABLE_BOTTOM_OFFSET: 25,
    LEGEND_TABLE_X_OFFSET: 220,
  },
  BAR_CHART_LEGEND_CONSTANTS: {
    LEGEND_CHART_X_OFFSET: 15,
    LEGEND_CHART_Y_OFFSET: 32,
    LEGEND_TABLE_TOP_OFFSET: 50,
    LEGEND_TABLE_BOTTOM_OFFSET: 25,
    LEGEND_TABLE_X_OFFSET: 250,
  },
  LEGEND_TABLE_WIDTH: 36,
  LEGEND_TABLE_SPACING: 20,
  LEGEND_TABLES_PER_PAGE: 2,
  LEGEND_COLOR_WIDTH: 5,
  LEGEND_COLOR_HEIGHT: 5,
  LEGEND_COLOR_RADIUS: 1,
  CHART_BORDER_WIDTH: 0.1,
  CHART_BORDER_Y_OFFSET: 30,
  CHART_BORDER_ROUND_RADIUS: 5,
};

export enum FILL_TYPE {
  FILL = 'F',
  STROKE = 'S',
}

export enum PDF_PAGE_ORIENTATION {
  PORTRAIT = 'p',
  LANDSCAPE = 'l',
}

export const PDF_TABLE_CONSTANTS = {
  MARGIN_HORIZONTAL: 10,
  MARGIN_TOP: 30,
  MARGIN_BOTTOM: 15,
  MIN_COLUMN_WIDTH: 10,
  SPLIT_TABLE_IF_MORE_THAN_COLUMNS: 15,
};

export const PDF_ALL_GRAPHS = 'ALL_GRAPHS';
export const PDF_ALL_TABLE_GRAPHS = 'ALL_TABLE_GRAPHS';

export const LANDSCAPE_VIEW_SUPPORTED_CONTENTS: string[] = [
  CHART_TYPES.BAR_CHART,
  CHART_TYPES.BAR_LINE_CHART,
  CHART_TYPES.LINE_CHART,
  CHART_TYPES.AREA_CHART,
  CHART_TYPES.HORIZONTAL_BAR_CHART,
  CHART_TYPES.STACK_CHART,
  CHART_TYPES.TABLE,
  CHART_TYPES.DOUGHNUT_CHART,
  CHART_TYPES.PIE_CHART,
  CHART_TYPES.GROUPED_CHART,
  CHART_TYPES.MAP,
];

export const PDF_QUALITY = 2;
