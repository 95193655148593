import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AccessibleDiv from 'components/AccessibleDiv';
import { selectNavMenu } from 'redux/activeNavMenuSlice';
import {
  InstancesWithoutLogo,
  Logo,
  LogoIcon,
  ShortLogoWithoutIcon,
} from 'assets/icons';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';

import './index.scss';

const LogoComponent = () => {
  const navigate = useNavigate();
  const { isSubMenuOpen, showMenuWithTitle } = useSelector(selectNavMenu);

  const ShortLogo = InstancesWithoutLogo.includes(window.location.hostname) ? (
    <ShortLogoWithoutIcon className="short-app-logo" />
  ) : (
    <LogoIcon className="app-logo" />
  );

  return (
    <div className="logo-container flex flex-column flex-space-between flex-justify-content-center">
      <div className="element flex flex-center flex-fit">
        {showMenuWithTitle || isSubMenuOpen ? (
          <Logo height={30} width={200} className="app-logo black-app-logo" />
        ) : (
          ShortLogo
        )}
      </div>
      <AccessibleDiv
        className="version cursor-pointer flex flex-fit flex-justify-content-left flex-align-items-end"
        onClick={() => navigate(NAVIGATION_MENU_PATH.RELEASE_NOTES)}
      >
        V {process.env.REACT_APP_CB360_APPLICATION_VERSION}
      </AccessibleDiv>
    </div>
  );
};

export default LogoComponent;
