import i18n from 'i18n';

export const DOLLAR = '$';

export const YES_NO_OPTIONS = [
  {
    value: true,
    label: i18n.t('radioYes'),
  },
  {
    value: false,
    label: i18n.t('radioNo'),
  },
];

export const BYTES_TO_TB_VALUE = 1e-12;

export const UNKNOWN_VALUE = '--';
