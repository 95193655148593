import { ICONS } from 'constants/icons';
import { ProfileSpendTypes } from '../../constants';

/**
 * @function getIconBySpendType
 * @description Function to get the icon by spend type
 * @param spendType spend type for which the icon is returned
 * @returns string icon name
 */
export const getIconBySpendType = (spendType: ProfileSpendTypes) => {
  switch (spendType) {
    case ProfileSpendTypes.INCREASING_SPEND:
      return ICONS.ARROW_RIGHT_UP_LINE;

    case ProfileSpendTypes.DECREASING_SPEND:
      return ICONS.ARROW_RIGHT_DOWN_LINE;

    case ProfileSpendTypes.INCONSISTENT_SPEND:
      return ICONS.RI_FLASHLIGHT_FILL;
  }
};
