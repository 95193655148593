import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DeleteBin6LineIcon from 'remixicon-react/DeleteBin6LineIcon';

import { cmdbSetup, setCmdbSetupConnection } from 'redux/cmdbSetupSlice';
import Table from 'components/Table';
import { PROVIDER } from 'constants/cloudProviders';
import { getProviderSmallLogo } from 'utils/providerDetails';
import {
  CloudTagsType,
  CMDBFieldTagsMappingType,
} from 'pages/CmdbSetupListPage/types';
import { ConnectionListType } from 'types/dashboard';
import TagsList from 'components/TagsList';

import './index.scss';

type ConnectionTagsMapTableProps = {
  tagMapData: CMDBFieldTagsMappingType;
  connections: ConnectionListType[];
};

const ConnectionTagsMapTable = ({
  tagMapData,
  connections,
}: ConnectionTagsMapTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cmdbSetupConnection } = useSelector(cmdbSetup);

  /**
   * @function onClickDelete
   * @description Function to handle the delete connection row
   * @param connectorId connectorId which needs to be deleted
   */
  const onClickDelete = (connectorId: string) => {
    const fieldTagsMappings = [...cmdbSetupConnection!.fieldTagsMappings];

    const currentPosition = fieldTagsMappings.findIndex(
      (fieldTagMap) => fieldTagMap.cmdbField === tagMapData.cmdbField
    );

    const cloudTags = tagMapData.cloudTags.filter(
      (cloudTag) => cloudTag.connectorId !== connectorId
    );

    if (cloudTags.length === 0) {
      fieldTagsMappings.splice(currentPosition, 1);
    } else {
      fieldTagsMappings.splice(currentPosition, 1, {
        ...tagMapData,
        cloudTags: cloudTags,
      });
    }

    dispatch(
      setCmdbSetupConnection({
        ...cmdbSetupConnection!,
        fieldTagsMappings: fieldTagsMappings,
      })
    );
  };

  const expandRowColumns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: '3%',
      render: (_text: any, _record: CloudTagsType, index: number) => index + 1,
    },
    {
      title: t('connections'),
      dataIndex: 'connectorId',
      key: 'connectorId',
      render: (conectorId: string) => {
        const connection = connections.find(
          (connection) => connection.connectorId === conectorId
        );
        if (!connection) {
          return <div>N/A</div>;
        }

        return (
          <div className="flex flex-align-items-center flex-gap-4">
            <img
              className="provider-icon"
              src={getProviderSmallLogo(connection.provider)}
              alt={`${PROVIDER.GCP} Logo`}
            />
            {connection.displayName}
          </div>
        );
      },
      width: '20%',
    },
    {
      title: t('tagsMapped'),
      dataIndex: 'tagKeys',
      key: 'tagKeys',
      render: (tagKeys: string[]) => <TagsList tags={tagKeys} showMore />,
    },
    {
      title: t('delete'),
      dataIndex: 'delete',
      key: 'delete',
      render: (_text: string, record: CloudTagsType) => {
        return (
          <DeleteBin6LineIcon
            className="delete-connection-cta cursor-pointer"
            onClick={() => onClickDelete(record.connectorId)}
            data-testid="delete-connection-cta"
          />
        );
      },
      align: 'center' as const,
    },
  ];

  return (
    <>
      <Table
        rootClassName="connection-tags-expand-row-table"
        data-testid="connection-tags-expand-row-table"
        pagination={false}
        columns={expandRowColumns}
        dataSource={tagMapData.cloudTags.map((data) => ({
          ...data,
          key: data.connectorId,
        }))}
        designVersion2={true}
      />
    </>
  );
};

export default ConnectionTagsMapTable;
