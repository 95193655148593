import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/Input';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import DropdownCheckbox from 'components/DropdownCheckbox';
import DrawerComponent from 'components/DrawerComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  FREE_TEXT_CHARACTER_LIMIT,
  MAX_CHARACTER_LIMIT,
  WBS_CODE_CHARACTER_LIMIT,
} from 'constants/validation';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateByRegex,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';
import { fetchConnectionData, getAllDataCenters } from 'utils/services';
import { BUTTON_TYPE } from 'constants/appearance';

import { TcoProjectListType } from '../../types';
import { createTcoProject, updateTcoProject } from '../../services';

import './index.scss';

type CreateProjectModalProps = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  selectedTcoProject?: TcoProjectListType;
  onSuccess: () => void;
};

const CreateProjectModal = ({
  showModal,
  setShowModal,
  selectedTcoProject,
  onSuccess,
}: CreateProjectModalProps) => {
  const { t } = useTranslation();

  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [wbsCode, setWbsCode] = useState('');
  const [dataCenters, setDataCenters] = useState<string[]>([]);
  const [cloudConnections, setCloudConnections] = useState<string[]>([]);
  const [dataCenterOptions, setDataCenterOptions] = useState<any[]>([]);
  const [cloudConnectionOptions, setCloudConnectionOptions] = useState<any[]>(
    []
  );

  //State for validations
  const [projectNameValidation, setProjectNameValidation] = useState('');
  const [descriptionValidation, setDescriptionValidation] = useState('');
  const [wbsCodeValidation, setWbsCodeValidation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Request Statuses
  const [dataCenterOptionsLoading, setDataCenterOptionsLoading] = useState('');
  const [cloudConnectionsOptionsLoading, setCloudConnectionsOptionsLoading] =
    useState('');
  const [createTcoProjectLoading, setCreateTcoProjectLoading] = useState('');

  useEffect(() => {
    fetchDataCenterList();
    fetchCloudConnectionList();
    if (selectedTcoProject) {
      setProjectName(selectedTcoProject.name);
      setProjectId(selectedTcoProject.projectId);
      setDescription(selectedTcoProject.description);
      setWbsCode(selectedTcoProject.wbsCode);
      setDataCenters(selectedTcoProject.dataCentersList);
      setCloudConnections(selectedTcoProject.connectorsList);
    }
  }, []);

  /**
   * @function fetchDataCenterList
   * @description Function to fetch and set the data centers list for dropdown
   */
  const fetchDataCenterList = () => {
    setDataCenterOptionsLoading(REQUEST_STATUS.PROCESSING);

    getAllDataCenters()
      .then((res: any) => {
        setDataCenterOptions(
          res.data.responseData.content.map((dataCenter: any) => ({
            value: dataCenter.dataCenterCode,
            title: dataCenter.name,
          }))
        );
        setDataCenterOptionsLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, true, setDataCenterOptionsLoading));
  };

  /**
   * @function fetchCloudConnectionList
   * @description Function to fetch and set the cloud connections list for dropdown
   */
  const fetchCloudConnectionList = () => {
    setCloudConnectionsOptionsLoading(REQUEST_STATUS.PROCESSING);

    fetchConnectionData()
      .then((res: any) => {
        setCloudConnectionOptions(
          res.data.responseData.content.map((cloudConnection: any) => ({
            value: cloudConnection.connectorId,
            title: cloudConnection.displayName,
          }))
        );
        setCloudConnectionsOptionsLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, true, setCloudConnectionsOptionsLoading));
  };

  /**
   * @function validateProjectName
   * @description Function to validate the project name
   * @param value project name to be validated
   * @returns true if the validation is successful, else false
   */
  const validateProjectName = (value: string) => {
    if (
      validateEmptyField(
        value.trim(),
        t('tcoCreateProject.name'),
        setProjectNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value,
        t('tcoCreateProject.name'),
        setProjectNameValidation
      )
    ) {
      return false;
    }

    return !validateStringLengthLessThan(
      value.trim(),
      MAX_CHARACTER_LIMIT,
      t('tcoCreateProject.name'),
      setProjectNameValidation
    );
  };

  /**
   * @function validateDescription
   * @description Function to validate the Description field
   * @param value description to be validated
   * @returns true if the validation is successful, else false
   */
  const validateDescription = (value: string) => {
    if (
      validateEmptyField(
        value.trim(),
        t('tcoCreateProject.description'),
        setDescriptionValidation
      )
    ) {
      return false;
    }

    return !validateStringLengthLessThan(
      value.trim(),
      FREE_TEXT_CHARACTER_LIMIT,
      t('tcoCreateProject.description'),
      setDescriptionValidation
    );
  };

  /**
   * @function validateWbsCode
   * @description Function to validate the WBS code
   * @param code wbs Code to be validated
   * @returns true if the validation is successful, else false
   */
  const validateWbsCode = (code: string) => {
    if (
      validateEmptyField(
        code.trim(),
        t('tcoCreateProject.wbsCode'),
        setWbsCodeValidation
      )
    ) {
      return false;
    }

    if (
      validateStringLengthLessThan(
        code.trim(),
        WBS_CODE_CHARACTER_LIMIT,
        t('tcoCreateProject.wbsCode'),
        setWbsCodeValidation
      )
    ) {
      return false;
    }

    return validateByRegex(
      code,
      /^[a-z\d][a-z\d-]*[a-z\d]+$/i,
      t('tcoCreateProject.wbsCode'),
      setWbsCodeValidation,
      t('tcoCreateProject.invalidWbsCode')
    );
  };

  /**
   * @function validateInputFields
   * @description Function to validate all input fields while clicking submit button.
   * @return this funtion returns true if all validations are ok either return false if any validation fails
   */
  const validateInputFields = () => {
    let validation = true;
    validation = validateProjectName(projectName) && validation;

    validation = validateDescription(description) && validation;

    validation = validateWbsCode(wbsCode) && validation;

    validation = validateMinimumSelections() && validation;

    return validation;
  };

  /**
   * @function validateMinimumSelections
   * @description Function to validate the minimum number of selections
   * @returns boolean if the number of selections are valid or not.
   */
  const validateMinimumSelections = () => {
    if (dataCenters.length + cloudConnections.length >= 2) {
      setErrorMessage('');
      return true;
    }
    setErrorMessage(t('tcoCreateProject.selectMinimumOptions'));
    return false;
  };

  /**
   * @function handleCreateProject
   * @description Function to handle create project
   */
  const handleCreateProject = () => {
    if (!validateInputFields()) {
      return;
    }

    setCreateTcoProjectLoading(REQUEST_STATUS.PROCESSING);
    let body: any = {
      name: projectName,
      description: description,
      wbsCode: wbsCode,
      dataCentersList: dataCenters,
      connectorsList: cloudConnections,
    };
    let successMessage = t('tcoCreateProject.createProjectSuccess');
    let failureMessage = t('tcoCreateProject.createProjectFailure');

    if (projectId) {
      body.projectId = projectId;
      successMessage = t('tcoCreateProject.updateProjectSuccess');
      failureMessage = t('tcoCreateProject.updateProjectFailure');
    }

    (projectId ? updateTcoProject(body) : createTcoProject(body))
      .then((res: any) => {
        if (res?.status === 200) {
          setCreateTcoProjectLoading(REQUEST_STATUS.SUCCESS);
          message.success(successMessage);
          setShowModal(false);
          onSuccess();
          return;
        }
        setErrorMessage(res?.message || failureMessage);
        setCreateTcoProjectLoading(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setCreateTcoProjectLoading);
        setErrorMessage(e?.response?.data?.message || failureMessage);
      });
  };

  return (
    <DrawerComponent
      className="tco-create-project-modal"
      open={showModal}
      onClose={() => setShowModal(false)}
      title={
        projectId
          ? t('tcoCreateProject.editProjectTitle')
          : t('tcoCreateProject.createProjectTitle')
      }
      footer={
        <div className="flex flex-column flex-gap-8">
          <span
            style={{
              display: getValidationStyle(errorMessage),
            }}
            className="font-validation-error"
          >
            {errorMessage}
          </span>
          <div className="flex flex-align-items-center flex-end">
            <Button
              title={t('tcoCreateProject.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShowModal(false)}
            />
            <Button
              title={
                projectId
                  ? t('tcoCreateProject.update')
                  : t('tcoCreateProject.create')
              }
              onClick={handleCreateProject}
              loading={createTcoProjectLoading === REQUEST_STATUS.PROCESSING}
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel title={t('tcoCreateProject.name')} required={true} />
          <Input
            default
            type="input"
            value={projectName}
            placeholder={t('tcoCreateProject.enterProjectName')}
            onChange={(e: any) => {
              setProjectName(e.target.value);
              validateProjectName(e.target.value);
            }}
            onBlur={(e: any) => validateProjectName(e.target.value)}
          />
          <span
            style={{
              display: getValidationStyle(projectNameValidation),
            }}
            className="font-validation-error"
          >
            {projectNameValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('tcoCreateProject.description')}
            required={true}
          />
          <Input
            default
            type="textarea"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={description}
            placeholder={t('tcoCreateProject.enterDescription')}
            onChange={(e: any) => {
              setDescription(e.target.value);
              validateDescription(e.target.value);
            }}
            onBlur={(e: any) => validateDescription(e.target.value)}
          />
          <span
            style={{
              display: getValidationStyle(descriptionValidation),
            }}
            className="font-validation-error"
          >
            {descriptionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('tcoCreateProject.wbsCode')} required={true} />
          <Input
            default
            type="input"
            value={wbsCode}
            placeholder={t('tcoCreateProject.wbsPlaceholder')}
            onChange={(e: any) => {
              setWbsCode(e.target.value);
              validateWbsCode(e.target.value);
            }}
            onBlur={(e: any) => {
              validateWbsCode(e.target.value);
            }}
          />
          <span
            style={{
              display: getValidationStyle(wbsCodeValidation),
            }}
            className="font-validation-error"
          >
            {wbsCodeValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('tcoCreateProject.dataCenterForTco')} />
          <DropdownCheckbox
            itemOptions={dataCenterOptions}
            value={dataCenters}
            placeholder={t('tcoCreateProject.chooseDataCenters')}
            selectedItems={dataCenters}
            setSelectedItems={setDataCenters}
            showSearch
            loading={dataCenterOptionsLoading === REQUEST_STATUS.PROCESSING}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('tcoCreateProject.cloudConnectionForTco')} />
          <DropdownCheckbox
            itemOptions={cloudConnectionOptions}
            value={
              cloudConnectionsOptionsLoading === REQUEST_STATUS.PROCESSING
                ? []
                : cloudConnections
            }
            placeholder={t('tcoCreateProject.chooseConnections')}
            selectedItems={cloudConnections}
            setSelectedItems={setCloudConnections}
            showSearch
            loading={
              cloudConnectionsOptionsLoading === REQUEST_STATUS.PROCESSING
            }
          />
        </div>
      </div>
    </DrawerComponent>
  );
};

export default CreateProjectModal;
