import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import GraphHeader from 'components/GraphHeader';
import { evaluateRequestArray } from 'utils/handleErrors';
import LineChart from 'components/LineChart';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { MonthlyCostByCategory } from '../../types';
import { getEstimatedMonthlySavingsPerCategoryColumns } from '../../utils';

type EstimatedMonthlySavingsPerCategoryProps = {
  isEstimatedMonthlySavingsPerCategoryTableView: boolean;
  setEstimatedMonthlySavingsPerCategoryTableView: (value: boolean) => void;
  requestStatus: string[];
  estimatedMonthlySavingsPerCategoryData: MonthlyCostByCategory[];
  estimatedMonthlySavingsPerCategoryLabels: string[];
  pdfView: boolean;
};

export const EstimatedMonthlySavingsPerCategory = ({
  isEstimatedMonthlySavingsPerCategoryTableView,
  setEstimatedMonthlySavingsPerCategoryTableView,
  requestStatus,
  estimatedMonthlySavingsPerCategoryData,
  estimatedMonthlySavingsPerCategoryLabels,
  pdfView,
}: EstimatedMonthlySavingsPerCategoryProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { selectedDashboard } = useSelector(selectDashboard);

  const getTableDataSource = () => {
    let tableData: any[] = [];
    estimatedMonthlySavingsPerCategoryData.forEach((value) => {
      let data: any = {};
      data['category'] = value.category;
      estimatedMonthlySavingsPerCategoryLabels?.forEach((label, index) => {
        data[label] = value.cost[index];
      });
      tableData.push(data);
    });
    return tableData;
  };

  const getDataSource = () => {
    let data: any[] = [];
    estimatedMonthlySavingsPerCategoryLabels?.forEach((label, index) => {
      estimatedMonthlySavingsPerCategoryData.forEach((value) =>
        data.push({
          month: label,
          category: value.category,
          cost: value.cost[index],
        })
      );
    });
    return data;
  };

  const getComponent = () =>
    isEstimatedMonthlySavingsPerCategoryTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={getTableDataSource().map((data, index) => ({
            key: index,
            ...data,
          }))}
          columns={getEstimatedMonthlySavingsPerCategoryColumns(
            estimatedMonthlySavingsPerCategoryLabels
          )}
          scroll={{ y: 220 }}
          designVersion2
        />
      </div>
    ) : (
      <LineChart
        data={getDataSource()}
        xField="month"
        yField="cost"
        xTitle={t('gcpRecommendationSummary.month')}
        yTitle={t('gcpRecommendationSummary.cost', {
          currencySymbol: currencySymbol,
        })}
        groupingField="category"
        showAllLegend={pdfView}
        disableAnimation={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  return (
    <div
      className="estimated-monthly-savings-per-category graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.estimatedMonthlySavingsPerCategory')}
        graphName={'estimated-monthly-savings-per-category'}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isEstimatedMonthlySavingsPerCategoryTableView}
        setIsTableView={setEstimatedMonthlySavingsPerCategoryTableView}
        excelData={getChartExcelExportData(
          t('graphHeadings.estimatedMonthlySavingsPerCategory'),
          getTableDataSource(),
          getEstimatedMonthlySavingsPerCategoryColumns(
            estimatedMonthlySavingsPerCategoryLabels
          ),
          {
            connectionName: selectedDashboard?.connectorName,
          }
        )}
        designVersion2
      />
      <div className="graph no-filters">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};
