import { useTranslation } from 'react-i18next';
import { getProviderLogo } from 'utils/providerDetails';

import './index.scss';

type ConnectionFormHeaderProps = {
  provider: string;
};

const ConnectionFormHeader = ({ provider }: ConnectionFormHeaderProps) => {
  const { t } = useTranslation();

  return (
    <header className="connection-form-header text-align-center">
      <img src={getProviderLogo(provider)} alt={`${provider}-logo`} />
      <div className="modal-heading connection-form-header-heading">
        {t('gProfilerConnectionPage.connectionForm.heading')}
      </div>
      <p className="font-caption connection-form-header-sub-heading">
        {t('gProfilerConnectionPage.connectionForm.subHeading')}
      </p>
    </header>
  );
};

export default ConnectionFormHeader;
