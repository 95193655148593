const envSettings = window as any;
export class Config {
  static azure_client_id = envSettings.REACT_APP_AD_CLIENT_ID;
  static azure_tenant_id = envSettings.REACT_APP_AD_TENANT_ID;
  static be_service_url = envSettings.REACT_APP_BE_SERVICE_URL;
  static login_type = envSettings.REACT_APP_LOGIN_TYPE;
  static supported_domains = envSettings.REACT_APP_SUPPORTED_DOMAINS;
  static chat_bot_modal = envSettings.REACT_APP_CHAT_BOT_MODEL;
  static azure_ad_url = 'https://graph.microsoft.com/';
}
