import { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectReport,
  setReportDimensions,
  setReportMetrics,
  setSelectedReportDimensions,
  setSelectedReportMetrics,
  setReportOptions,
  setSelectedDatasourceMenu,
  setReportData,
} from 'redux/reportSlice';
import useDidMountEffect from 'hooks/useDidMountEffect';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import {
  ReportsDataSourceNavs,
  REPORTS_DATASOURCE_NAV_LIST,
} from 'pages/CreateReportPage/constants';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { DASHBOARD_TYPES } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { defaultReportOptions } from 'constants/defaultValues';
import { CHART_TYPES } from 'constants/graphConfig';

import DataSourceForm from '../DataSourceForm';
import TableOptions from '../TableOptions';
import ChartOptions from '../ChartOptions';

import './index.scss';

const DataSourcesOptions = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    selectedDatasourceMenu,
    typeOfConnection,
    reportDatasource,
    reportConnection,
    reportGroup,
    reportOptions,
  } = useSelector(selectReport);

  const [datasourceValidation, setDatasourceValidation] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reportName = queryParams.get('report');
    setDatasourceValidation(true);
    if (reportName) {
      dispatch(
        setSelectedDatasourceMenu(
          reportOptions.chartType === CHART_TYPES.TABLE
            ? ReportsDataSourceNavs.TABLE
            : ReportsDataSourceNavs.CHART
        )
      );
    } else if (reportOptions.chartDimension.length > 0) {
      dispatch(setSelectedDatasourceMenu(ReportsDataSourceNavs.CHART));
    } else if (
      reportOptions.dimension.length + reportOptions.metric.length >
      0
    ) {
      dispatch(setSelectedDatasourceMenu(ReportsDataSourceNavs.TABLE));
    } else {
      dispatch(setSelectedDatasourceMenu(ReportsDataSourceNavs.DATASOURCE));
      setDatasourceValidation(false);
    }
  }, [location]);

  useDidMountEffect(() => {
    setDatasourceValidation(false);
    dispatch(
      setReportOptions({
        ...defaultReportOptions,
        reportName: reportOptions.reportName,
        startDate: reportOptions.startDate,
        endDate: reportOptions.endDate,
        dateRanges: reportOptions.dateRanges,
      })
    );
    dispatch(setReportDimensions([]));
    dispatch(setReportMetrics([]));
    dispatch(setSelectedReportDimensions([]));
    dispatch(setSelectedReportMetrics([]));
    dispatch(setReportData({ chartData: [], tableData: [] }));
  }, [
    reportDatasource.dataSet,
    reportDatasource.dashBoardType,
    reportDatasource.customQuery,
  ]);

  /**
   * @function validateDatasourceForm
   * @description Function to validate the datasource form
   * @returns true if validation is successful else false
   */
  const validateDatasourceForm = () => {
    if (typeOfConnection === MY_DASHBOARD_TYPES.GROUP) {
      return validateGroupConnectionsDatasetTypes();
    }

    if (!reportConnection) {
      return false;
    }

    if (
      reportConnection.provider === PROVIDER.AZURE &&
      !reportDatasource.dataSet
    ) {
      return false;
    }

    if (reportDatasource.useCustomQuery && reportDatasource.customQuery) {
      return true;
    }

    if (!reportDatasource.useCustomQuery && reportDatasource.dataSet) {
      return true;
    }

    return false;
  };

  /**
   * @function validateGroupConnectionsDatasetTypes
   * @description Function to validate the dataset type for the group selected
   * @returns boolean true if validation is successful else false
   */
  const validateGroupConnectionsDatasetTypes = () => {
    // Validate if all the connections in the group has the type of dataset selected
    if (!reportGroup || !reportDatasource.dashBoardType) {
      return false;
    }

    let hasDataset = false;
    switch (reportDatasource.dashBoardType) {
      case DASHBOARD_TYPES.BILLING:
        hasDataset = reportGroup.connectorDtos.every(
          (connection) => connection.wantBilling
        );
        break;
      case DASHBOARD_TYPES.RECOMMENDATIONS:
        hasDataset = reportGroup.connectorDtos.every(
          (connection) => connection.wantRecommendations
        );
        break;
      case DASHBOARD_TYPES.CARBON_FOOTPRINT:
        hasDataset = reportGroup.connectorDtos.every(
          (connection) => connection.wantCarbonFootprint
        );
        break;
    }

    return hasDataset;
  };

  /**
   * @function isDatasourceNavDisabled
   * @description Function to validate if the datasource options nav menu is disabled
   * @returns boolean true if disabled else false
   */
  const isDatasourceNavDisabled = (navKey: string) => {
    if (navKey === ReportsDataSourceNavs.DATASOURCE) {
      return false;
    }

    return !validateDatasourceForm() || !datasourceValidation;
  };

  const getDataSourceComponent = () => {
    switch (selectedDatasourceMenu) {
      case ReportsDataSourceNavs.DATASOURCE:
        return (
          <DataSourceForm
            validateDatasourceForm={validateDatasourceForm}
            setDatasourceValidation={setDatasourceValidation}
          />
        );

      case ReportsDataSourceNavs.TABLE:
        return <TableOptions />;

      case ReportsDataSourceNavs.CHART:
        return <ChartOptions />;
    }
  };

  return (
    <div className="datasource-options flex flex-column">
      <HorizontalNavigationMenu
        className="datasource-options-navs"
        menuItems={REPORTS_DATASOURCE_NAV_LIST.map((option) => (
          <Menu.Item
            key={option.key}
            eventKey={option.key}
            className={`font-caption-bold flex-fit ${
              option.key === selectedDatasourceMenu && 'active-menu'
            }`}
            disabled={isDatasourceNavDisabled(option.key)}
            onClick={() => dispatch(setSelectedDatasourceMenu(option.key))}
          >
            {option.label}
          </Menu.Item>
        ))}
        selectedKeys={[]}
      />
      {getDataSourceComponent()}
    </div>
  );
};

export default DataSourcesOptions;
