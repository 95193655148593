import i18n from 'i18n';

export enum DASHBOARD_STATUS {
  PUBLISH = 'PUBLISH',
  DE_ACTIVATED = 'DE_ACTIVATED',
  DRAFT = 'DRAFT',
}

export const DASHBOARD_STATUS_LABEL = {
  [DASHBOARD_STATUS.PUBLISH]: i18n.t('dashboardLabels.published'),
  [DASHBOARD_STATUS.DE_ACTIVATED]: i18n.t('dashboardLabels.deactivated'),
  [DASHBOARD_STATUS.DRAFT]: i18n.t('dashboardLabels.draft'),
};

export enum DASHBOARD_TYPE {
  PRE_BUILT = 'PRE_BUILT',
  CUSTOM = 'CUSTOM',
}

export const DASHBOARD_TYPE_LABEL = {
  [DASHBOARD_TYPE.PRE_BUILT]: i18n.t('dashboardLabels.preBuilt'),
  [DASHBOARD_TYPE.CUSTOM]: i18n.t('dashboardLabels.custom'),
};
