import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  cloudConnection,
  setAccessDetails,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import { FormLabel } from 'components/FormLabel';
import { REQUEST_STATUS } from 'constants/requestBody';
import { validateEmptyField } from 'utils/validations';
import { getAccessDetails } from 'pages/ConnectingCSPPage/services';
import { PROVIDER } from 'constants/cloudProviders';
import { onApiCallError } from 'utils/handleErrors';
import {
  ACCESS_DATA_VALUES,
  SECRET_KEY_PLACEHOLDER,
} from 'pages/ConnectingCSPPage/constants';
import AccessSummaryTable from 'pages/ConnectingCSPPage/components/AccessSummaryTable';

import { fetchAWSregions } from '../../service';

export const AccessDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, accessDetails, isEditConnection } =
    useSelector(cloudConnection);

  const [
    awsAccessKeyIdRequiredValidation,
    setAwsAccessKeyIdRequiredValidation,
  ] = useState<string>('');
  const [awsSecretKeyRequiredValidation, setAwsSecretKeyRequiredValidation] =
    useState<string>('');
  const [awsRegionRequiredValidation, setAwsRegionRequiredValidation] =
    useState('');
  const [regions, setRegions] = useState<string[]>([]);
  const [fetchRegionReqStatus, setFetchRegionReqStatus] = useState('');
  const [
    awsAthenaOutputBucketRequiredValidation,
    setAwsAthenaOutputBucketRequiredValidation,
  ] = useState<string>('');
  const [accessDetailsRequestStatus, setAccessDetailsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    fetchRegions();

    if (
      currentConnection?.awsAccessKeyId !== SECRET_KEY_PLACEHOLDER &&
      currentConnection?.awsSecretKey !== SECRET_KEY_PLACEHOLDER
    ) {
      fetchConnectionAccessDetails(currentConnection?.awsRegion);
    }
  }, []);

  /**
   * @function fetchRegions
   * @description Function to fetch regions data
   */
  const fetchRegions = () => {
    setFetchRegionReqStatus(REQUEST_STATUS.PROCESSING);
    fetchAWSregions()
      .then((res: any) => {
        setFetchRegionReqStatus(REQUEST_STATUS.SUCCESS);
        setRegions(res.data.responseData);
        if (currentConnection) {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              awsRegion: currentConnection?.awsRegion,
            })
          );
        } else {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              awsRegion: res?.data?.responseData[0],
            })
          );
        }
      })
      .catch((e) => {
        onApiCallError(e);
      });
  };

  /**
   * @function fetchConnectionAccessDetails
   * @description Function to fetch the access details
   */
  const fetchConnectionAccessDetails = (awsRegion: string) => {
    if (
      currentConnection?.awsAccessKeyId &&
      currentConnection?.awsSecretKey &&
      awsRegion
    ) {
      dispatch(
        setAccessDetails(
          accessDetails.map((item) => ({ ...item, haveAccess: false }))
        )
      );
      setAccessDetailsRequestStatus(REQUEST_STATUS.PROCESSING);
      const body = {
        awsAccessKeyId: currentConnection?.awsAccessKeyId,
        awsSecretKey: currentConnection?.awsSecretKey,
        awsRegion: awsRegion,
      };
      const param = {
        provider: PROVIDER.AWS,
        connectorId: currentConnection?.connectorId,
      };
      getAccessDetails(body, param)
        .then((res: any) => {
          setAccessDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
          dispatch(
            setAccessDetails(
              Object.entries(res?.data?.responseData).map((objectArr) => {
                return {
                  role: objectArr[0],
                  haveAccess: objectArr[1] as boolean,
                };
              })
            )
          );
        })
        .catch((e) => onApiCallError(e, false, setAccessDetailsRequestStatus));
    }
  };

  return (
    <div className="collapse-body flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          required={true}
          title={t(`awsConnectionCSPForm.accessKeyId`)}
        />
        <Input
          default
          type="password"
          placeholder={t('awsConnectionCSPForm.accessKeyIdPlaceholder')}
          value={
            isEditConnection
              ? SECRET_KEY_PLACEHOLDER
              : currentConnection?.awsAccessKeyId
          }
          disabled={isEditConnection}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                awsAccessKeyId: e.target.value,
              })
            );
            setAwsAccessKeyIdRequiredValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t(`awsConnectionCSPForm.accessKeyId`),
              setAwsAccessKeyIdRequiredValidation
            );
            fetchConnectionAccessDetails(currentConnection?.awsRegion);
          }}
        />
        <span
          style={{
            display: `${awsAccessKeyIdRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {awsAccessKeyIdRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          required={true}
          title={t('awsConnectionCSPForm.secretKeyLabel')}
        />
        <Input
          default
          type="password"
          placeholder={t('awsConnectionCSPForm.secretKeyPlaceholder')}
          value={
            isEditConnection
              ? SECRET_KEY_PLACEHOLDER
              : currentConnection?.awsSecretKey
          }
          disabled={isEditConnection}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                awsSecretKey: e.target.value,
              })
            );
            setAwsSecretKeyRequiredValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t('awsConnectionCSPForm.secretKeyLabel'),
              setAwsSecretKeyRequiredValidation
            );
            fetchConnectionAccessDetails(currentConnection?.awsRegion);
          }}
        />
        <span
          style={{
            display: `${awsSecretKeyRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {awsSecretKeyRequiredValidation}
        </span>
      </div>
      <div className="flex flex-column">
        <div className="form-item flex flex-column">
          <FormLabel title={t('awsConnectionCSPForm.region')} required={true} />
          <SelectDropdown
            value={currentConnection?.awsRegion}
            options={regions.map((region) => ({
              value: region,
              label: region,
            }))}
            loading={fetchRegionReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  awsRegion: value,
                })
              );
              setAwsRegionRequiredValidation('');
              fetchConnectionAccessDetails(value);
            }}
            designVersion2
          />
          <span
            style={{
              display: `${awsRegionRequiredValidation ? 'inline' : 'none'}`,
            }}
            className="font-validation-error"
          >
            {awsRegionRequiredValidation}
          </span>
        </div>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          required={true}
          title={t('awsConnectionCSPForm.athenaOutputBucket')}
        />
        <Input
          default
          placeholder={t('awsConnectionCSPForm.athenaOutputBucketPlaceholder')}
          value={currentConnection?.awsS3OutputLocation}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                awsS3OutputLocation: e.target.value,
              })
            );
            setAwsAthenaOutputBucketRequiredValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t('awsConnectionCSPForm.athenaOutputBucket'),
              setAwsAthenaOutputBucketRequiredValidation
            );
          }}
        />
        <span
          style={{
            display: `${
              awsAthenaOutputBucketRequiredValidation ? 'inline' : 'none'
            }`,
          }}
          className="font-validation-error"
        >
          {awsAthenaOutputBucketRequiredValidation}
        </span>
      </div>
      {currentConnection?.awsAccessKeyId &&
        currentConnection?.awsSecretKey &&
        currentConnection?.awsRegion &&
        !isEditConnection && (
          <>
            <div className="form-item flex flex-column">
              <FormLabel title={t(`connectionCSPForm.accessSummary`)} />
              <AccessSummaryTable
                accessDetailsRequestStatus={accessDetailsRequestStatus}
              />
            </div>
            {!accessDetails.find(
              (value) => value.role === ACCESS_DATA_VALUES.HAS_BILLING_ACCESS
            )?.haveAccess &&
              accessDetailsRequestStatus !== REQUEST_STATUS.PROCESSING && (
                <div className="error-message flex flex-justify-content-center font-caption">
                  {t('connectionCSPForm.invalidCredentials')}
                </div>
              )}
          </>
        )}
    </div>
  );
};
