import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { selectCostAllocationDashboard } from 'redux/costAllocationDashboardSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import ExpandModal from 'components/ExpandModal';
import { CHART_TYPES } from 'constants/graphConfig';
import {
  AllocationTrendType,
  CostAllocationType,
  CostByBusinessUnitType,
  CostByCostCenterCodeType,
} from 'pages/CostAllocationDashboardPage/types';
import {
  fetchAllAllocations,
  fetchAllocationTrendData,
  fetchBUAllocationByMonths,
  fetchCSCAllocationByMonthsAndBU,
} from 'pages/CostAllocationDashboardPage/services';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { COST_ALLOCATION_SAVE_STATUS } from 'pages/CostAllocationPage/components/CostAllocationForm/SetupBusinessUnit/constants';
import {
  getMonthAndYearLabels,
  getPreviousMonthAndYear,
} from 'utils/dashboardUtils';
import {
  getMonthAndYearDigitsFormat,
  getMonthAndYearStringFormat,
} from 'utils/date';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { onApiCallError } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { AllocationTrend } from './components/AllocationTrend';
import { AllocationByBusinessUnit } from './components/AllocationByBusinessUnit';
import AllocationByCSC from './components/AllocationByCSC';
import { getCostAllocationExcelData } from './utils/exportToExcel';
import {
  getAllocationByBusinessUnitExportColumns,
  getAllocationByCostCenterCodeExportColumns,
  AllocationTrendExportColumns,
} from './constants';

const CostAllocationDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    expandedGraphName,
    tableViewEnabled,
    pdfDownloadMode,
    showExpandGraphModal,
  } = useSelector(selectDashboard);
  const { selectedCostAllocationDashboard } = useSelector(
    selectCostAllocationDashboard
  );
  const { currencySymbol } = useSelector(selectCommonUtility);

  //State for Allocation Trend
  const [allocationTrendData, setAllocationTrendData] = useState<
    AllocationTrendType[]
  >([]);
  const [isAllocationTrendTableView, setIsAllocationTrendTableView] =
    useState(false);
  const [allocationTrendRequestStatus, setAllocationTrendRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  // States for Allocation by Business Unit
  const [businessUnitsData, setBusinessUnitsData] = useState<
    CostByBusinessUnitType[]
  >([]);
  const [filteredBusinessUnitsData, setFilteredBusinessUnitsData] = useState<
    CostByBusinessUnitType[]
  >([]);
  const [selectedBusinessUnitMonths, setSelectedBusinessUnitMonths] = useState<
    string[]
  >([]);
  const [selectedBUs, setSelectedBUs] = useState<string[]>([]);
  const [
    isAllocationByBusinessUnitTableView,
    setIsAllocationByBusinessUnitTableView,
  ] = useState(false);
  const [
    allocationByBusinessUnitRequestStatus,
    setAllocationByBusinessUnitRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  //States for Allocation by Cost Center Code
  const [costByCostCenterCode, setCostByCostCenterCode] = useState<
    CostByCostCenterCodeType[]
  >([]);
  const [filteredCostByCostCenterCode, setFilteredCostByCostCenterCode] =
    useState<CostByCostCenterCodeType[]>([]);
  const [months, setMonths] = useState<string[]>([]);
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const [businessUnits, setBusinessUnits] = useState<string[]>([]);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<string[]>(
    []
  );
  const [selectedCostCenters, setSelectedCostCenters] = useState<string[]>([]);
  const [allocationByCSCRequestStatus, setAllocationByCSCRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [isAllocationByCSCTableView, setIsAllocationByCSCTableView] =
    useState(false);

  const [allAllocations, setAllAllocations] = useState<CostAllocationType[]>(
    []
  );
  const [allAllocationsRequestStatus, setAllAllocationsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    setAllocationTrendData([]);
    setAllAllocations([]);

    if (selectedCostAllocationDashboard) {
      getAllocationTrendData();
      getAllAllocations();
    }
  }, [selectedCostAllocationDashboard]);

  useEffect(() => {
    const monthAllocated: string[] = allAllocations.map((item) =>
      getMonthAndYearStringFormat(item.invoiceMonth)
    );

    setSelectedBusinessUnitMonths(monthAllocated);
    setMonths(monthAllocated);
    setSelectedMonths(monthAllocated);
  }, [allAllocations]);

  useEffect(() => {
    let allBusinessUnits: string[] = [];
    allAllocations
      .filter((item) =>
        selectedMonths.includes(getMonthAndYearStringFormat(item.invoiceMonth))
      )
      .forEach((item) => {
        item.businessUnits.forEach((bu) => {
          if (!allBusinessUnits.includes(bu.businessUnitName)) {
            allBusinessUnits.push(bu.businessUnitName);
          }
        });
      });

    setBusinessUnits(allBusinessUnits);
    setSelectedBusinessUnits(allBusinessUnits);
  }, [selectedMonths]);

  useEffect(() => {
    setBusinessUnitsData([]);

    if (
      selectedCostAllocationDashboard &&
      months.length > 0 &&
      selectedBusinessUnitMonths.length > 0
    ) {
      getBusinessUnitsCost();
    }
  }, [selectedCostAllocationDashboard, months, selectedBusinessUnitMonths]);

  useEffect(() => {
    setSelectedBUs(businessUnitsData.map((item) => item.name));
  }, [businessUnitsData]);

  useEffect(() => {
    setFilteredBusinessUnitsData(
      businessUnitsData.filter((item) => selectedBUs.includes(item.name))
    );
  }, [selectedBUs]);

  useEffect(() => {
    setCostByCostCenterCode([]);

    if (
      selectedCostAllocationDashboard &&
      months.length > 0 &&
      selectedMonths.length > 0 &&
      selectedBusinessUnits.length > 0
    ) {
      getCostCentersCost();
    }
  }, [
    selectedCostAllocationDashboard,
    months,
    selectedMonths,
    selectedBusinessUnits,
  ]);

  useEffect(() => {
    setSelectedCostCenters(costByCostCenterCode.map((item) => item.name));
  }, [costByCostCenterCode]);

  useEffect(() => {
    setFilteredCostByCostCenterCode(
      costByCostCenterCode.filter((item) =>
        selectedCostCenters.includes(item.name)
      )
    );
  }, [selectedCostCenters]);

  useEffect(() => {
    if (selectedCostAllocationDashboard) {
      dispatch(
        setExportToExcelData(
          getCostAllocationExcelData(
            selectedCostAllocationDashboard.connectorName,
            allocationTrendData,
            {
              data: filteredBusinessUnitsData,
              selectedMonths: selectedBusinessUnitMonths,
              selectedBusinessUnits: selectedBUs,
            },
            {
              data: filteredCostByCostCenterCode,
              selectedMonths: selectedMonths,
              selectedBusinessUnits: selectedBusinessUnits,
              selectedCostCenters: selectedCostCenters,
            }
          )
        )
      );
    }
  }, [
    selectedCostAllocationDashboard,
    allocationTrendData,
    filteredBusinessUnitsData,
    filteredCostByCostCenterCode,
  ]);

  useEffect(() => {
    setIsAllocationTrendTableView(tableViewEnabled);
    setIsAllocationByBusinessUnitTableView(tableViewEnabled);
    setIsAllocationByCSCTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  /**
   * @function getAllAllocations
   * @description Function to fetch all the allocations
   */
  const getAllAllocations = () => {
    setAllAllocationsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedCostAllocationDashboard?.connectorId,
      minimal: true,
    };

    fetchAllAllocations(params)
      .then((res: any) => {
        setAllAllocations(
          res.data.responseData.content.filter(
            (item: any) =>
              item.status !== COST_ALLOCATION_SAVE_STATUS.SAVED_AS_DRAFT
          )
        );
        setAllAllocationsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setAllAllocationsRequestStatus);
      });
  };

  /**
   * @function getAllocationTrendData
   * @description Function to fetch the allocation trend data
   */
  const getAllocationTrendData = () => {
    setAllocationTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedCostAllocationDashboard?.connectorId,
    };

    fetchAllocationTrendData(params)
      .then((res: any) => {
        const labels = getMonthAndYearLabels(
          getPreviousMonthAndYear(11),
          getPreviousMonthAndYear(0)
        );
        const responseData: AllocationTrendType[] = res.data.responseData;
        let trendData: AllocationTrendType[] = [];
        let residualCost = 0;
        labels.forEach((label) => {
          const eachData: AllocationTrendType | undefined = responseData.find(
            (item: any) => item.month === getMonthAndYearDigitsFormat(label)
          );
          if (eachData === undefined) {
            trendData.push({
              month: label,
              allocatedCost: 0,
              residualCost: residualCost,
              sharedCost: 0,
            });
          } else {
            residualCost = eachData.residualCost;
            trendData.push({ ...eachData, month: label });
          }
        });

        setAllocationTrendData(trendData);
        setAllocationTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setAllocationTrendRequestStatus);
      });
  };

  /**
   * @function getBusinessUnitsCost
   * @description Function to fetch the business units cost
   */
  const getBusinessUnitsCost = () => {
    setAllocationByBusinessUnitRequestStatus(REQUEST_STATUS.PROCESSING);
    let params = {
      connectorId: selectedCostAllocationDashboard?.connectorId,
      months: selectedBusinessUnitMonths.map((month) =>
        getMonthAndYearDigitsFormat(month)
      ),
    };

    fetchBUAllocationByMonths(params)
      .then((res: any) => {
        const data: CostByBusinessUnitType[] = res.data.responseData;
        data.sort((a, b) => b.cost - a.cost);
        setBusinessUnitsData(data);
        setAllocationByBusinessUnitRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setAllocationByBusinessUnitRequestStatus);
      });
  };
  /**
   * @function getCostCentersCost
   * @description Function to fetch the cost centers cost
   */
  const getCostCentersCost = () => {
    setAllocationByCSCRequestStatus(REQUEST_STATUS.PROCESSING);

    let params = {
      connectorId: selectedCostAllocationDashboard?.connectorId,
      months: selectedMonths.map((month) => getMonthAndYearDigitsFormat(month)),
      businessUnitNames: selectedBusinessUnits,
    };

    fetchCSCAllocationByMonthsAndBU(params)
      .then((res: any) => {
        const data: CostByCostCenterCodeType[] = res.data.responseData;
        data.sort((a, b) => b.cost - a.cost);
        setCostByCostCenterCode(data);
        setAllocationByCSCRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setAllocationByCSCRequestStatus);
      });
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'allocation-trend':
        return (
          <AllocationTrend
            allocationTrendData={allocationTrendData}
            isAllocationTrendTableView={isAllocationTrendTableView}
            setIsAllocationTrendTableView={setIsAllocationTrendTableView}
            requestStatus={allocationTrendRequestStatus}
            pdfView={pdfView}
          />
        );
      case 'allocation-by-business-unit':
        return (
          <AllocationByBusinessUnit
            businessUnitsData={businessUnitsData}
            filteredBusinessUnitsData={filteredBusinessUnitsData}
            months={months}
            selectedMonths={selectedBusinessUnitMonths}
            setSelectedMonths={setSelectedBusinessUnitMonths}
            selectedBus={selectedBUs}
            setSelectedBUs={setSelectedBUs}
            isAllocationByBusinessUnitTableView={
              isAllocationByBusinessUnitTableView
            }
            setIsAllocationByBusinessUnitTableView={
              setIsAllocationByBusinessUnitTableView
            }
            requestStatus={[
              allocationByBusinessUnitRequestStatus,
              allAllocationsRequestStatus,
            ]}
            pdfView={pdfView}
          />
        );

      case 'allocation-by-csc':
        return (
          <AllocationByCSC
            costByCostCenterCode={costByCostCenterCode}
            filteredCostByCostCenterCode={filteredCostByCostCenterCode}
            months={months}
            selectedMonths={selectedMonths}
            setSelectedMonths={setSelectedMonths}
            businessUnits={businessUnits}
            selectedBusinessUnits={selectedBusinessUnits}
            setSelectedBusinessUnits={setSelectedBusinessUnits}
            selectedCostCenters={selectedCostCenters}
            setSelectedCostCenters={setSelectedCostCenters}
            requestStatus={[
              allocationByCSCRequestStatus,
              allAllocationsRequestStatus,
            ]}
            isAllocationByCSCTableView={isAllocationByCSCTableView}
            setIsAllocationByCSCTableView={setIsAllocationByCSCTableView}
            pdfView={pdfView}
          />
        );
    }
  };

  const getPdfMetaData = () => {
    return {
      viewName: t('dashNav.costAllocationSummary'),
      fileName: selectedCostAllocationDashboard?.name ?? '',
      heading: selectedCostAllocationDashboard?.name!,
      subtitle1: selectedCostAllocationDashboard?.connectorName!,
      subtitle2: '',
      provider: selectedCostAllocationDashboard?.connectorProvider!,
    };
  };

  return (
    <div className="cost-allocation-summary flex flex-column flex-gap-24">
      {getGraphComponent('allocation-trend')}
      <Row gutter={24}>
        <Col span={12}>{getGraphComponent('allocation-by-business-unit')}</Col>
        <Col span={12}>{getGraphComponent('allocation-by-csc')}</Col>
      </Row>
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData()}
          pdfContent={[
            {
              element: getGraphComponent('allocation-trend', true),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: 'allocation-trend',
              column: AllocationTrendExportColumns,
              body: insertIndex(allocationTrendData).map((item) => ({
                ...item,
                sharedCost:
                  currencySymbol + numberCommaSeparator(item.sharedCost),
                allocatedCost:
                  currencySymbol + numberCommaSeparator(item.allocatedCost),
                residualCost:
                  currencySymbol + numberCommaSeparator(item.residualCost),
              })),
              tableName: t('graphHeadings.allocationTrend'),
              isTableView: isAllocationTrendTableView,
            },
            {
              element: getGraphComponent('allocation-by-business-unit', true),
              contentType: CHART_TYPES.PIE_CHART,
              graphName: 'allocation-by-business-unit',
              column: getAllocationByBusinessUnitExportColumns(currencySymbol),
              body: insertIndex(businessUnitsData).map((item) => ({
                ...item,
                cost: currencySymbol + numberCommaSeparator(item.cost),
              })),
              tableName: t('graphHeadings.allocationCostByBusinessUnit'),
              isTableView: isAllocationByBusinessUnitTableView,
            },
            {
              element: getGraphComponent('allocation-by-csc', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'allocation-by-csc',
              column:
                getAllocationByCostCenterCodeExportColumns(currencySymbol),
              body: insertIndex(costByCostCenterCode).map((item) => ({
                ...item,
                cost: currencySymbol + numberCommaSeparator(item.cost),
              })),
              tableName: t('graphHeadings.costCenterCode'),
              isTableView: isAllocationByCSCTableView,
            },
          ]}
        />
      )}
    </div>
  );
};

export default CostAllocationDashboard;
