import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectGProfiler } from 'redux/gProfilerSlice';
import CloudProviders from 'components/CloudProviders';
import ProgressBar from 'components/ProgressBar';
import { PROGRESS_BAR_ITEMS, GProfilerProviderView } from 'constants/gProfiler';
import { CONNECTION_PROVIDER_LIST_DATA } from 'constants/providerConnectionData';
import { PROVIDER } from 'constants/cloudProviders';
import Footer from '../Footer';
import GCPConnectionForm from '../ConnectionForm/GCPConnectionForm';
import AWSConnectionForm from '../ConnectionForm/AWSConnectionForm';
import AzureConnectionForm from '../ConnectionForm/AzureConnectionForm';

import './index.scss';

const AddConnection = () => {
  const { t } = useTranslation();

  const { currentStep, gProfilerProviderView } = useSelector(selectGProfiler);

  const [selectedProvider, setSelectedProvider] = useState<string>('');
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const getComponent = () => {
    switch (gProfilerProviderView) {
      case GProfilerProviderView.PROVIDER_LIST:
        return (
          <CloudProviders
            providerList={CONNECTION_PROVIDER_LIST_DATA}
            selectedProvider={selectedProvider}
            handleClick={setSelectedProvider}
            header={t('gProfilerConnectionPage.addConnection.header')}
            subHeader={t('gProfilerConnectionPage.addConnection.subHeader')}
            disabledList={[PROVIDER.OCI, PROVIDER.AZURE]}
          />
        );
      case GProfilerProviderView.GCP:
        return <GCPConnectionForm setSubmitDisabled={setSubmitDisabled} />;
      case GProfilerProviderView.AWS:
        return <AWSConnectionForm setSubmitDisabled={setSubmitDisabled} />;
      case GProfilerProviderView.AZURE:
        return <AzureConnectionForm />;
    }
  };

  return (
    <div className="gprofiler-add-connection flex flex-fit full-height">
      <ProgressBar
        items={PROGRESS_BAR_ITEMS}
        current={currentStep}
        heading={t('gProfilerConnectionPage.steps.heading')}
        subHeading={t('gProfilerConnectionPage.steps.subHeading')}
        additionalClassNames="add-connection-progress-bar"
      />
      <div className="form-section flex flex-column flex-fit flex-space-between">
        {getComponent()}
        <Footer
          selectedProvider={selectedProvider}
          itemsLength={PROGRESS_BAR_ITEMS.length}
          submitDisabled={submitDisabled}
        />
      </div>
    </div>
  );
};

export default AddConnection;
