import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchCmdbSetupList
 * @description Function to fetch CMDB Setup data
 * @return axios response from GET request
 */
export const fetchCmdbSetupList = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_CMDB_SETUP, null, params);
};

/**
 * @function searchCmdbSetupList
 * @description Function to search the CMDB Setup data
 * @return axios response from GET request
 */
export const searchCmdbSetupList = async (params: any) => {
  return ServiceCalls.get(APIS.SEARCH_CMDB_SETUP, null, params);
};

/**
 * @function deleteCmdbSetup
 * @description Function to set the CMDB setup.
 * @param params Query parameters containing CMDB name
 * @return axios response from DELETE request
 */
export const deleteCmdbSetup = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_CMDB_SETUP, null, params);
};
