import { ImportFileDataType } from 'pages/StaticFilesPage/types';

/**
 * @function getSchemaToSave
 * @description Returns the schema post modification by removing the mappedField based on the transaction details
 * @param data Import static file details
 * @returns string schema definition based on the details entered
 */
export const getSchemaToSave = (data: ImportFileDataType) => {
  // If Custom Schema definition and opted for Spend Diagnostics Dashboard, send the data entered
  if (data.isCreateSchemaDefinition && data.wantSpendDiagnosticsDashboard) {
    return JSON.stringify(data.schemaDefinition);
  }

  // If Custom Schema definition and not opted for Spend Diagnostics Dashboard, send the data without mappedFieldName
  if (data.isCreateSchemaDefinition) {
    return JSON.stringify(
      data.schemaDefinition.map((schemaRow) => ({
        ...schemaRow,
        mappedFieldName: undefined,
      }))
    );
  }

  // If free text schema and send the data entered in the text field
  return data.schema;
};
