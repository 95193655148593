import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCostAllocation,
  setCostAllocationData,
} from 'redux/costAllocationSlice';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import DrawerComponent from 'components/DrawerComponent';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  AMOUNT_MINIMUM,
  PERCENT,
  PERCENTAGE_MAXIMUM,
  PERCENTAGE_MINIMUM,
} from 'constants/validation';
import { BUTTON_SIZE, BUTTON_TYPE, INPUT_SIZE } from 'constants/appearance';

import { BusinessUnitType, CostCenterDataType } from '../types';

import './index.scss';

type CostCenterDrawerProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  isEdit?: boolean;
  index?: number;
  selectedRows?: BusinessUnitType[];
  setSelectedRows?: (val: BusinessUnitType[]) => void;
};

const CostCenterDrawer = ({
  show,
  setShow,
  isEdit,
  index,
  selectedRows,
  setSelectedRows,
}: CostCenterDrawerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { costAllocationData, totalSharedCost } =
    useSelector(selectCostAllocation);
  const lastCostCenterRef = useRef<HTMLDivElement>(null);

  const [businessUnitCode, setBusinessUnitCode] = useState('');
  const [initialBusinessUnitCode, setInitialBusinessUnitCode] = useState('');
  const [businessUnitCost, setBusinessUnitCost] = useState(0);
  const [businessUnitShare, setBusinessUnitShare] = useState<number>();
  const [businessUnitPendingCost, setBusinessUnitPendingCost] =
    useState<number>();
  const [costCentersData, setCostCentersData] = useState<CostCenterDataType[]>(
    []
  );
  const [higherCostCenterCostError, setHigherCostCenterCostError] =
    useState('');

  useEffect(() => {
    if (isEdit) {
      const businessUnitData = costAllocationData?.businessUnits?.at(index!);
      setBusinessUnitCode(businessUnitData?.businessUnitCode ?? '');
      setInitialBusinessUnitCode(businessUnitData?.businessUnitCode ?? '');
      setBusinessUnitShare(businessUnitData?.share);
      setBusinessUnitCost(businessUnitData?.cost ?? 0);
      setBusinessUnitPendingCost(businessUnitData?.pendingCostCenter);
      setCostCentersData(businessUnitData?.costCenters ?? []);
    } else {
      setBusinessUnitCode('');
      setBusinessUnitShare(0);
      setBusinessUnitCost(0);
      setBusinessUnitPendingCost(0);
      setCostCentersData([]);
    }
  }, []);

  useEffect(() => {
    updatePendingBusinessUnitCost();
  }, [businessUnitCost, costCentersData]);

  const moveScrollToView = () => {
    lastCostCenterRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  /**
   * @function updatePendingBusinessUnitCost
   * @description Function to update the pending cost to be allocated for the business unit and set error message accordingly.
   */
  const updatePendingBusinessUnitCost = () => {
    let addedCost = 0;
    costCentersData.forEach((costCenter) => (addedCost += costCenter.cost));
    const pendingCost = (businessUnitCost || 0) - addedCost;
    setBusinessUnitPendingCost(pendingCost);
    if (pendingCost < 0) {
      setHigherCostCenterCostError(
        t('costAllocations.setupBusinessUnit.costGreaterThanBusinessShare')
      );
    } else {
      setHigherCostCenterCostError('');
    }
  };

  /**
   * @function handleAddCostCenter
   * @description Function to add a new cost center row.
   */
  const handleAddCostCenter = () => {
    setCostCentersData([
      ...costCentersData,
      { costCenterCode: '', share: 0, cost: 0 },
    ]);
    setTimeout(() => moveScrollToView());
  };

  /**
   * @function onHandleDeleteCostCenter
   * @description Function to delete existing cost center row.
   */
  const onHandleDeleteCostCenter = (costCenterIndex: number) => {
    const tempArr = [...costCentersData];
    tempArr.splice(costCenterIndex, 1);
    setCostCentersData([...tempArr]);
  };

  /**
   * @function onHandleClickSave
   * @description Function to save or update the the business unit data.
   */
  const onHandleClickSave = () => {
    const tempArr = [...costAllocationData.businessUnits];
    const businessUnit: BusinessUnitType = {
      businessUnitCode: businessUnitCode,
      share: businessUnitShare ?? 0,
      cost: businessUnitCost,
      pendingCostCenter: businessUnitPendingCost ?? 0,
      costCenters: costCentersData,
    };
    if (isEdit) {
      tempArr.splice(index!, 1, businessUnit);
      if (initialBusinessUnitCode !== businessUnitCode) {
        updateSelectedRows();
      }
    } else {
      tempArr.push(businessUnit);
    }
    dispatch(
      setCostAllocationData({
        ...costAllocationData,
        businessUnits: tempArr,
      })
    );
    setShow(false);
  };

  /**
   * @function updateSelectedRows
   * @description Function to update the latest updated business unit code for the selected rows in previous step.
   */
  const updateSelectedRows = () => {
    const rowIndex = selectedRows?.findIndex(
      (item) => item.businessUnitCode === initialBusinessUnitCode
    );
    if (rowIndex !== undefined && rowIndex >= 0) {
      const tempArray = [...(selectedRows ?? [])];
      tempArray.splice(rowIndex, 1, {
        ...selectedRows?.at(rowIndex)!,
        businessUnitCode: businessUnitCode,
      });
      setSelectedRows!(tempArray);
    }
  };

  /**
   * @function onHandleBusinessUnitShareChange
   * @description Call back function to update the business unit share and cost & cost centers costs accordingly.
   */
  const onHandleBusinessUnitShareChange = (value: any) => {
    const cost = (value * totalSharedCost) / PERCENT;
    setBusinessUnitShare(value);
    setBusinessUnitCost(cost);
    setCostCentersData(
      costCentersData.map((costCenter) => ({
        ...costCenter,
        cost: (costCenter.share / PERCENT) * cost,
      }))
    );
  };

  /**
   * @function onHandleBusinessUnitCostChange
   * @description Call back function to update the business unit cost and share & cost centers costs accordingly.
   */
  const onHandleBusinessUnitCostChange = (value: any) => {
    let buShare = (value / totalSharedCost) * PERCENT;
    if (buShare > PERCENT) {
      buShare = PERCENT;
      value = Number(totalSharedCost);
    }
    setBusinessUnitCost(value);
    setBusinessUnitShare(buShare);
    setCostCentersData(
      costCentersData.map((costCenter) => ({
        ...costCenter,
        cost: (costCenter.share / PERCENT) * value,
      }))
    );
  };

  /**
   * @function onHandleBusinessUnitCostChange
   * @description Call back function to update the cost center code.
   */
  const onHandleCostCenterCodeChange = (e: any, position: number) => {
    const tempArr = [...costCentersData];
    const costCenter = costCentersData.at(position);
    tempArr.splice(position, 1, {
      costCenterCode: e.target.value,
      share: costCenter?.share ?? 0,
      cost: costCenter?.cost ?? 0,
    });
    setCostCentersData([...tempArr]);
  };

  /**
   * @function onHandleCostCenterShareChange
   * @description Call back function to update the cost center share and cost accordingly.
   */
  const onHandleCostCenterShareChange = (value: number, position: number) => {
    const tempArr = [...costCentersData];
    const costCenter = costCentersData.at(position);
    tempArr.splice(position, 1, {
      costCenterCode: costCenter?.costCenterCode ?? '',
      share: value,
      cost: (value * businessUnitCost) / PERCENT,
    });
    setCostCentersData([...tempArr]);
  };

  /**
   * @function onHandleCostCenterCostChange
   * @description Call back function to update the cost center cost and share accordingly.
   */
  const onHandleCostCenterCostChange = (value: number, position: number) => {
    const tempArr = [...costCentersData];
    const costCenter = costCentersData.at(position);
    let cscShare = (value / businessUnitCost) * PERCENT;
    if (cscShare > PERCENT) {
      cscShare = PERCENT;
      value = Number(businessUnitCost);
    }
    tempArr.splice(position, 1, {
      costCenterCode: costCenter?.costCenterCode ?? '',
      share: cscShare,
      cost: value,
    });
    setCostCentersData([...tempArr]);
  };

  /**
   * @function getPendingCostErrorMessage
   * @description Function to return the error message for the pending business unit cost.
   * @returns string error message.
   */
  const getPendingCostErrorMessage = () => {
    if (businessUnitCost && businessUnitCost > 0) {
      if (higherCostCenterCostError) {
        return higherCostCenterCostError;
      } else {
        return `$${Number(businessUnitPendingCost?.toFixed(2))} ${t(
          'costAllocations.setupBusinessUnit.pendingBusinessUnitCostMessage'
        )}`;
      }
    } else {
      return t('costAllocations.setupBusinessUnit.addBusinessUnitShare');
    }
  };

  /**
   * @function isDuplicateBusinessUnitCode
   * @description Function to validate if the business unit code is a duplicate code.
   * @returns boolean, true if it's a duplicate code else false.
   */
  const isDuplicateBusinessUnitCode = () => {
    if (isEdit) {
      return (
        businessUnitCode !== initialBusinessUnitCode &&
        costAllocationData?.businessUnits?.some(
          (businessUnit: BusinessUnitType) =>
            businessUnit.businessUnitCode === businessUnitCode
        )
      );
    } else {
      return costAllocationData?.businessUnits?.some(
        (businessUnit: BusinessUnitType) =>
          businessUnit.businessUnitCode === businessUnitCode
      );
    }
  };

  /**
   * @function isDuplicateCostCenterCode
   * @description Function to validate if the provided cost center code is a duplicate code.
   * @param costCenterCode string: the cost center code against which the validation needs to be performed.
   * @param psition number: index value of the cost center against which the validation is performed.
   * @returns boolean, true if it's a duplicate code else false.
   */
  const isDuplicateCostCenterCode = (
    costCenterCode: string,
    position: number
  ) => {
    return costCentersData.some(
      (costCenter, idx) =>
        idx !== position && costCenter.costCenterCode === costCenterCode
    );
  };

  /**
   * @function hasDuplicateCostCenterCode
   * @description Function to validate if the there are two cost centers with same cost center code.
   * @returns boolean, true if there are two entries with same cost center code else false.
   */
  const hasDuplicateCostCenterCode = () => {
    return costCentersData.some((item1, index1) =>
      costCentersData.some(
        (item2, index2) =>
          index1 !== index2 && item1.costCenterCode === item2.costCenterCode
      )
    );
  };

  return (
    <DrawerComponent
      className="cost-center-drawer"
      open={show}
      width="481px"
      title={t('costAllocations.setupBusinessUnit.addBusinessUnit')}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-align-item-center flex-end">
          <Button
            title={t('costAllocations.setupBusinessUnit.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={t('costAllocations.setupBusinessUnit.save')}
            onClick={onHandleClickSave}
            disabled={
              !businessUnitCode ||
              !businessUnitCost ||
              higherCostCenterCostError ||
              isDuplicateBusinessUnitCode() ||
              hasDuplicateCostCenterCode() ||
              costCentersData.some(
                (costCenter) => costCenter.costCenterCode.length === 0
              )
            }
          />
        </div>
      }
    >
      <div className="modal-body flex flex-column flex-gap-16">
        <Row gutter={16}>
          <Col span={24} className="row-item flex flex-column">
            <FormLabel
              title={t('costAllocations.setupBusinessUnit.businessUnitCode')}
              required={true}
            />
            <Input
              placeholder={t(
                'costAllocations.setupBusinessUnit.businessUnitCodePlaceholder'
              )}
              value={businessUnitCode}
              onChange={(e: any) => setBusinessUnitCode(e.target.value)}
            />
            {isDuplicateBusinessUnitCode() && (
              <span
                style={{
                  display: `${
                    isDuplicateBusinessUnitCode() ? 'inline' : 'none'
                  }`,
                }}
                className="font-validation-error"
              >
                {t(
                  'costAllocations.setupBusinessUnit.duplicateBusinessUnitCode'
                )}
              </span>
            )}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12} className="row-item flex flex-column">
            <FormLabel
              title={t('costAllocations.setupBusinessUnit.share')}
              required={true}
            />
            <Input
              type="number"
              placeholder={t(
                'costAllocations.setupBusinessUnit.businessUnitSharePlaceholder'
              )}
              value={
                businessUnitShare && businessUnitShare > 0
                  ? businessUnitShare
                  : ''
              }
              onChange={onHandleBusinessUnitShareChange}
              max={PERCENTAGE_MAXIMUM}
              min={PERCENTAGE_MINIMUM}
            />
          </Col>
          <Col span={12} className="row-item flex flex-column">
            <FormLabel
              title={t('costAllocations.setupBusinessUnit.totalAllocatedCost')}
              required={true}
            />
            <Input
              type="number"
              placeholder={t(
                'costAllocations.setupBusinessUnit.businessUnitCostPlaceholder'
              )}
              value={
                businessUnitCost && businessUnitCost > 0
                  ? businessUnitCost.toFixed(2)
                  : ''
              }
              onChange={onHandleBusinessUnitCostChange}
              min={AMOUNT_MINIMUM}
            />
          </Col>
        </Row>
        <div className="cost-center-container flex flex-column flex-gap-16 styled-scroll">
          <Row className="flex flex-space-between flex-align-items-center flex-fit">
            <Col className="font-caption-bold">
              <div>{t('costAllocations.setupBusinessUnit.addCostCenter')}</div>
              <div className="font-caption-bold pending-message">
                {getPendingCostErrorMessage()}
              </div>
            </Col>
          </Row>
          {!!businessUnitCost && (
            <div className="cost-center flex flex-column flex-gap-8">
              <Row className="cost-center-list flex flex-column flex-gap-16 new-styled-scroll">
                {costCentersData
                  ?.map((item, index1) => ({ ...item, key: index1 }))
                  .map((costCenter, costCenterIndex) => (
                    <Row
                      className="cost-center-item"
                      gutter={16}
                      key={costCenter.key}
                      ref={
                        costCenterIndex === costCentersData?.length - 1
                          ? lastCostCenterRef
                          : null
                      }
                    >
                      <Col span={10} className="row-item flex flex-column">
                        <Input
                          className="csc-input"
                          placeholder={t(
                            'costAllocations.setupBusinessUnit.costCenterCode'
                          )}
                          value={costCenter.costCenterCode}
                          onChange={(e: any) =>
                            onHandleCostCenterCodeChange(e, costCenterIndex)
                          }
                          size={INPUT_SIZE.SMALL}
                          autoFocus={false}
                        />
                      </Col>
                      <Col span={6} className="row-item flex flex-column">
                        <Input
                          className="csc-input"
                          type="number"
                          placeholder={t(
                            'costAllocations.setupBusinessUnit.share'
                          )}
                          value={costCenter.share > 0 ? costCenter.share : ''}
                          onChange={(value: number) =>
                            onHandleCostCenterShareChange(
                              value,
                              costCenterIndex
                            )
                          }
                          min={PERCENTAGE_MINIMUM}
                          max={PERCENTAGE_MAXIMUM}
                          size={INPUT_SIZE.SMALL}
                          autoFocus={false}
                        />
                      </Col>
                      <Col span={6} className="row-item flex flex-column">
                        <Input
                          className="csc-input"
                          type="number"
                          placeholder={t(
                            'costAllocations.setupBusinessUnit.cost'
                          )}
                          value={
                            costCenter.cost > 0
                              ? costCenter.cost.toFixed(2)
                              : ''
                          }
                          onChange={(value: number) =>
                            onHandleCostCenterCostChange(value, costCenterIndex)
                          }
                          min={AMOUNT_MINIMUM}
                          size={INPUT_SIZE.SMALL}
                          autoFocus={false}
                        />
                      </Col>
                      <Col span={2} className="flex flex-center">
                        <Icon
                          className="delete-cost-center-cta"
                          iconName={ICONS.DELETE_BIN_LINE}
                          size={ICONS_SIZE.ONE_X}
                          onClick={() =>
                            onHandleDeleteCostCenter(costCenterIndex)
                          }
                          dataTestId="delete-cc-cta"
                        />
                      </Col>
                      {isDuplicateCostCenterCode(
                        costCenter.costCenterCode,
                        costCenterIndex
                      ) && (
                        <span
                          style={{
                            display: `${
                              isDuplicateCostCenterCode(
                                costCenter.costCenterCode,
                                costCenterIndex
                              )
                                ? 'inline'
                                : 'none'
                            }`,
                          }}
                          className="font-validation-error duplicate-error"
                        >
                          {t(
                            'costAllocations.setupBusinessUnit.duplicateCostCenterCode'
                          )}
                        </span>
                      )}
                    </Row>
                  ))}
              </Row>
              <Row>
                <Col span={24}>
                  <Button
                    className="add-cost-center-button full-width"
                    size={BUTTON_SIZE.SMALL}
                    title={t('costAllocations.setupBusinessUnit.addCostCenter')}
                    type={BUTTON_TYPE.NEUTRAL}
                    iconName={ICONS.ADD_LINE}
                    onClick={handleAddCostCenter}
                    disabled={!businessUnitCost || higherCostCenterCostError}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </DrawerComponent>
  );
};

export default CostCenterDrawer;
