import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionListType } from 'types/dashboard';
import {
  PURCHASE_TACTICS_TAB,
  SCORECARD_NAVS,
} from 'pages/ScorecardPage/constants';
import { RootState } from './store';

export interface ScorecardState {
  selectedConnection: ConnectionListType | null;
  selectedScorecardView: string;
  scorecardConnectionList: ConnectionListType[];
  selectedPurchaseTacticsTab: string;
}

const initialState: ScorecardState = {
  selectedConnection: null,
  selectedScorecardView: SCORECARD_NAVS.SUMMARY,
  scorecardConnectionList: [],
  selectedPurchaseTacticsTab: PURCHASE_TACTICS_TAB.SAVINGS_EFFECTIVENESS,
};

export const scorecardSlice = createSlice({
  name: 'scorecardSlice',
  initialState,
  reducers: {
    setSelectedConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.selectedConnection = action.payload;
    },
    setScorecardConnectionList: (
      state,
      action: PayloadAction<ConnectionListType[]>
    ) => {
      state.scorecardConnectionList = action.payload;
    },
    setSelectedScorecardView: (state, action: PayloadAction<string>) => {
      state.selectedScorecardView = action.payload;
    },

    setSelectedPurchaseTacticsTab: (state, action: PayloadAction<string>) => {
      state.selectedPurchaseTacticsTab = action.payload;
    },
  },
});

export const selectScorecard = (state: RootState) => state.scorecardReducer;
export const {
  setSelectedConnection,
  setScorecardConnectionList,
  setSelectedScorecardView,
  setSelectedPurchaseTacticsTab,
} = scorecardSlice.actions;
export default scorecardSlice.reducer;
