import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TicketIntegrationComponents } from 'pages/TicketIntegrationPage/constants';
import {
  defaultFormData,
  ServiceNowDataType,
  ServiceNowDefaultData,
  TicketIntegrationDataType,
} from 'pages/TicketIntegrationPage/types';
import { RootState } from './store';

export interface TicketIntegrationSlice {
  ticketIntegrationComponent: string;
  ticketIntegrationData: TicketIntegrationDataType;
  serviceNowData: ServiceNowDataType;
}

const initialState: TicketIntegrationSlice = {
  ticketIntegrationComponent: TicketIntegrationComponents.TICKET_PROVIDER_LIST,
  ticketIntegrationData: defaultFormData,
  serviceNowData: ServiceNowDefaultData,
};

export const ticketIntegrationSlice = createSlice({
  name: 'ticketIntegration',
  initialState,
  reducers: {
    setTicketIntegrationComponent: (state, action: PayloadAction<string>) => {
      state.ticketIntegrationComponent = action.payload;
    },
    setTicketIntegrationData: (
      state,
      action: PayloadAction<TicketIntegrationDataType>
    ) => {
      state.ticketIntegrationData = action.payload;
    },
    setServiceNowData: (state, action: PayloadAction<ServiceNowDataType>) => {
      state.serviceNowData = action.payload;
    },
  },
});

export const ticketIntegration = (state: RootState) => state.ticketIntegration;
export const {
  setTicketIntegrationComponent,
  setTicketIntegrationData,
  setServiceNowData,
} = ticketIntegrationSlice.actions;
export default ticketIntegrationSlice.reducer;
