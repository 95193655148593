import { useDispatch, useSelector } from 'react-redux';

import {
  selectReport,
  setReportOptions,
  setSelectedReportDimensions,
} from 'redux/reportSlice';
import Icon from 'components/Icon';
import { COLORS } from 'constants/graphConfig';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { ChartDimensionType } from 'pages/CreateReportPage/types';
import { FieldSource } from 'constants/dashboard';

type DimensionListItemProps = {
  dimension: ChartDimensionType;
  dragHandleProps?: any;
};

const DimensionListItem = ({
  dimension,
  dragHandleProps,
}: DimensionListItemProps) => {
  const dispatch = useDispatch();
  const { selectedReportDimensions, reportOptions } = useSelector(selectReport);

  /**
   * @function onRemoveDimension
   * @description Removes the dimension from the selected dimensions list and updates the report chart data
   * @param dimension - dimension to be removed
   */
  const onRemoveDimension = (dimension: ChartDimensionType) => {
    const updatedDimensions = selectedReportDimensions.filter(
      (item) =>
        !(
          item.dimension === dimension.dimension &&
          item.dimensionType === dimension.dimensionType &&
          item.tagDimensionType === dimension.tagDimensionType
        )
    );
    const updatedColumns = [
      ...updatedDimensions.map((item) => item.dimension),
      ...reportOptions.metric,
    ];
    const updatedSorts = reportOptions.sort.filter((item) =>
      updatedColumns.includes(item.label)
    );

    dispatch(setSelectedReportDimensions(updatedDimensions));
    dispatch(
      setReportOptions({
        ...reportOptions,
        dimension: updatedDimensions,
        sort: updatedSorts,
      })
    );
  };

  return (
    <div
      className="dimension-item flex flex-space-between flex-align-items-center flex-gap-4"
      key={dimension.dimension}
    >
      <div className="flex flex-column flex-gap-2" {...dragHandleProps}>
        <div className="flex flex-gap-2 flex-align-items-start">
          <Icon
            iconName={ICONS.DRAGGABLE}
            size={ICONS_SIZE.SM}
            color={COLORS.colorRegentGrey}
            className="dimension-handle"
          />
          <span className="dimension-label font-subHeader">
            {dimension.dimensionType === FieldSource.TAGS
              ? `${dimension.dimension} (${dimension.tagDimensionType})`
              : dimension.dimension}
          </span>
        </div>
      </div>
      <Icon
        iconName={ICONS.SUBTRACT_LINE}
        dataTestId="remove-dimension"
        size={ICONS_SIZE.SM}
        color={COLORS.colorRegentGrey}
        onClick={() => onRemoveDimension(dimension)}
      />
    </div>
  );
};

export default DimensionListItem;
