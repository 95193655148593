import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import { HYPHEN_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'utils/date';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import GraphHeader from 'components/GraphHeader';
import { selectDashboard } from 'redux/dashboardSlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { CreditDistributionByLocationColorTypes } from 'types/dataTypes';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getRegionCoordinates } from 'utils/dashboardUtils';
import PointerMap from 'components/PointerMap';
import { PROVIDER } from 'constants/cloudProviders';

import { getAWSCreditDistributionByRegionExcelData } from '../../utils/exportToExcel';

type CreditDistributionByRegionProps = {
  requestStatus: string;
  creditDistributionByRegionData: CreditDistributionByLocationColorTypes[];
  pdfView: boolean;
  costByRegionStartDate: string;
  costByRegionEndDate: string;
  onChangeCostByRegionDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

export const CreditDistributionByRegion = ({
  requestStatus,
  creditDistributionByRegionData,
  pdfView,
  costByRegionStartDate,
  costByRegionEndDate,
  onChangeCostByRegionDateRange,
}: CreditDistributionByRegionProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'color',
      key: 'color',
      render: (text: string) => (
        <div className="color-block" style={{ backgroundColor: text }} />
      ),
      width: 30,
    },
    {
      title: t('creditByAwsRegion.region'),
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: t('creditByAwsRegion.credit'),
      dataIndex: 'credit',
      key: 'credit',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(costByRegionStartDate, HYPHEN_DATE_FORMAT),
          moment(costByRegionEndDate, HYPHEN_DATE_FORMAT),
        ]}
        onChange={onChangeCostByRegionDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  const getComponent = () => {
    return (
      <div className="graph flex flex-gap-16">
        <div className="width-30 full-height">
          <Table
            pagination={false}
            dataSource={creditDistributionByRegionData.map((value, index) => ({
              ...value,
              key: index,
            }))}
            columns={columns}
            scroll={{ y: 300 }}
            designVersion2
          />
        </div>
        <div
          className={`graph-area with-table width-70 ${
            pdfView && 'pdf-wrapper'
          }`}
        >
          <PointerMap
            data={creditDistributionByRegionData.map((item) => ({
              ...item,
              coordinates: getRegionCoordinates(item.region, PROVIDER.AWS),
            }))}
            valueLabel={t('creditByAwsRegion.credit')}
            labelField="region"
            valueField="credit"
            colorField="color"
            prefixSymbol={currencySymbol}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className="credit-distribution-by-location map-card graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.creditDistributionbyRegion')}
        graphName={'credit-distribution-by-location'}
        isTableView={false}
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getAWSCreditDistributionByRegionExcelData(
          selectedDashboard?.connectorName ?? '',
          creditDistributionByRegionData
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};
