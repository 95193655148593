export type CostMonitorListType = {
  name: string;
  monitorARN?: string;
  connectorName: string;
  provider: string;
  connectorId: string;
  createdAt?: string;
  anomalySubscriptions: AnamolySubscriptionType[];
};

export enum ALERT_SUBSCRIPTION_TYPE {
  NEW_SUBSCRIPTION = 'NEW_SUBSCRIPTION',
  EXISTING_SUBSCRIPTION = 'EXISTING_SUBSCRIPTION',
}

export enum ALERT_SUBSCRIPTION_THRESHOLD_TYPE {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export type AnamolySubscriptionType = {
  subscriptionName: string;
  subscriptionArn?: string;
  alertRecipientEmails?: string[];
  thresholds?: ThresholdType[];
  conjunction?: string;
  existingSubscription: boolean;
  monitorArnList?: string[];
};

export type ThresholdType = {
  thresholdType: ALERT_SUBSCRIPTION_THRESHOLD_TYPE;
  thresholdValue: string;
};
