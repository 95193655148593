import i18n from 'i18n';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  CarbonFootprintByMonth,
  CarbonFootprintByProject,
  CarbonFootprintByRegion,
  CarbonFootprintByService,
} from '../../../types';
import {
  CarbonEmissionByProjectExportColumns,
  CarbonEmissionByRegionExportColumns,
  CarbonEmissionByServiceExportColumns,
  MonthlyCarbonEmissionExportColumns,
} from '../constants';

const addIndexAndCommaSeprator = (data: any) => {
  return data.map((item: any, index: number) => ({
    ...item,
    slNo: index + 1,
    carbonFootprint: numberCommaSeparator(item.carbonFootprint || 0),
  }));
};

export const getGCPMonthlyCarbonEmissionExcelData = (
  connectorName: string,
  monthlyCarbonEmissions: CarbonFootprintByMonth[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.grossCarbonEmissionsByMonth'),
    columns: MonthlyCarbonEmissionExportColumns,
    data: addIndexAndCommaSeprator(monthlyCarbonEmissions),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

export const getGCPCarbonEmissionByProjectExcelData = (
  connectorName: string,
  selectedCarbonEmissionByProjectData: CarbonFootprintByProject[],
  regionStartDate: string,
  regionEndDate: string
) => {
  return {
    sheetName: i18n.t('graphHeadings.grossCarbonEmissionsByProject'),
    columns: CarbonEmissionByProjectExportColumns,
    data: addIndexAndCommaSeprator(selectedCarbonEmissionByProjectData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: regionStartDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: regionEndDate,
      },
      {
        heading: i18n.t('excelExportLabels.projects'),
        value: selectedCarbonEmissionByProjectData
          .map((data) => data.project)
          .join(', '),
      },
    ],
  };
};

export const getGCPCarbonEmissionByServiceExcelData = (
  connectorName: string,
  selectedCarbonEmissionByServiceData: CarbonFootprintByService[],
  regionStartDate: string,
  regionEndDate: string
) => {
  return {
    sheetName: i18n.t('graphHeadings.grossCarbonEmissionsByServices'),
    columns: CarbonEmissionByServiceExportColumns,
    data: addIndexAndCommaSeprator(selectedCarbonEmissionByServiceData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: regionStartDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: regionEndDate,
      },
      {
        heading: i18n.t('excelExportLabels.services'),
        value: selectedCarbonEmissionByServiceData
          .map((data) => data.service)
          .join(', '),
      },
    ],
  };
};

export const getGCPCarbonEmissionByRegionExcelData = (
  connectorName: string,
  selectedCarbonEmissionByRegionData: CarbonFootprintByRegion[],
  regionStartDate: string,
  regionEndDate: string
) => {
  return {
    sheetName: i18n.t('graphHeadings.grossCarbonEmissionsByRegion'),
    columns: CarbonEmissionByRegionExportColumns,
    data: addIndexAndCommaSeprator(selectedCarbonEmissionByRegionData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: regionStartDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: regionEndDate,
      },
      {
        heading: i18n.t('excelExportLabels.regions'),
        value: selectedCarbonEmissionByRegionData
          .map((item) => item.region)
          .join(', '),
      },
    ],
  };
};

export const getGCPCarbonEmissionSummaryExcelData = (
  connectorName: string,
  regionStartDate: string,
  regionEndDate: string,
  monthlyCarbonEmissions: CarbonFootprintByMonth[],
  carbonEmissionByProjectData: CarbonFootprintByProject[],
  carbonEmissionByServiceData: CarbonFootprintByService[],
  carbonEmissionByRegionData: CarbonFootprintByRegion[]
) => {
  return [
    getGCPMonthlyCarbonEmissionExcelData(connectorName, monthlyCarbonEmissions),
    getGCPCarbonEmissionByProjectExcelData(
      connectorName,
      carbonEmissionByProjectData,
      regionStartDate,
      regionEndDate
    ),
    getGCPCarbonEmissionByServiceExcelData(
      connectorName,
      carbonEmissionByServiceData,
      regionStartDate,
      regionEndDate
    ),
    getGCPCarbonEmissionByRegionExcelData(
      connectorName,
      carbonEmissionByRegionData,
      regionStartDate,
      regionEndDate
    ),
  ];
};
