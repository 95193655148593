import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { flatMap, groupBy, map } from 'lodash';

import { userAuthorization } from 'redux/authorizationSlice';
import { providerList } from 'redux/providerSlice';
import {
  ruleEngine,
  setIsTryAgain,
  setRuleEngineComponent,
  setRuleEngineFormData,
} from 'redux/ruleEngineSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import {
  DEFAULT_FORM_DATA,
  RuleEngineComponents,
} from 'pages/RuleEnginePage/constants';
import {
  RulesetCreateUpdateRequestType,
  RulesetTemplateType,
} from 'pages/RuleEnginePage/types';
import AccessibleDiv from 'components/AccessibleDiv';
import Button from 'components/Button';
import { LeftBackArrowIcon } from 'assets/icons';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError, useNull } from 'utils/handleErrors';

import BasicDetails from '../BasicDetails';
import SetupRules from './components/SetupRules';
import {
  createRuleset,
  getGranulateRulesetTemplates,
  getRulesetTemplates,
  updateRuleset,
} from './services';
import {
  getTemplateForARuleStatement,
  parseRuleEngineTemplate,
} from './components/SetupRules/utils';
import { addHashPrefixToIntegerAndFloat } from './utils';

const RulesetForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isEditForm, ruleEngineFormData, deletedRuleIds } =
    useSelector(ruleEngine);
  const { selectedProvider } = useSelector(providerList);
  const { permissions } = useSelector(userAuthorization);

  const [rulesetTemplateList, setRulesetTemplateList] = useState<
    RulesetTemplateType[]
  >([]);
  const [rulesetTemplateRequestStatus, setRulesetTemplateRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [createButtonClick, setCreateButtonClick] = useState(false);
  const [basicDetailsValidation, setBasicDetailsValidation] = useState(false);
  const [setupRulesValidation, setSetupRulesValidation] = useState(false);
  const [rulesetId, setRulesetId] = useState<string>();
  const [createRulesetRequestStatus, setCreateRulesetRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    fetchRulesetTemplates();

    if (isEditForm) {
      setRulesetId(ruleEngineFormData.rulesetId);
    }
  }, []);

  /**
   * @function fetchRulesetTemplates
   * @description Function to fetch the ruleset
   */
  const fetchRulesetTemplates = () => {
    setRulesetTemplateRequestStatus(REQUEST_STATUS.PROCESSING);

    const requests = [getRulesetTemplates(selectedProvider).catch(useNull)];

    if (permissions.thirdPartyAppsRead) {
      requests.push(getGranulateRulesetTemplates().catch(useNull));
    }

    axios
      .all(requests)
      .then((responses: any[]) => {
        // Parse and combine all the templates in the response
        const templates: RulesetTemplateType[] = [];
        responses.map((res) =>
          templates.push(...parseRuleEngineTemplate(res?.data ?? {}))
        );

        // Group the templates by recommendationType field
        const groupedTemplatesByRecommendationType = groupBy(
          templates,
          'recommendationType'
        );

        // Concatenate the statements of each recommendationType to get the combined list of templates
        const mergedTemplates = map(
          groupedTemplatesByRecommendationType,
          (statements, key) => ({
            recommendationType: key,
            statements: flatMap(statements, 'statements'),
          })
        );

        setRulesetTemplateList(mergedTemplates);
        setRulesetTemplateRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRulesetTemplateRequestStatus);
        setRulesetTemplateList([]);
      });
  };

  /**
   * @function handleBackArrowClick
   * @description Callback function for click event of back arrow
   */
  const handleBackArrowClick = () => {
    dispatch(setIsTryAgain(false));
    if (isEditForm) {
      navigate(NAVIGATION_MENU_PATH.RULE_ENGINE);
    } else {
      dispatch(setRuleEngineComponent(RuleEngineComponents.PROVIDER_LIST));
      dispatch(
        setRuleEngineFormData({
          ...DEFAULT_FORM_DATA,
          cloudProvider: selectedProvider,
        })
      );
    }
  };

  /**
   * @function onClickCreateDataset
   * @description Function for validating and creating or updating a ruleset
   */
  const onClickCreateDataset = () => {
    setCreateButtonClick(!createButtonClick);
    if (!basicDetailsValidation || !setupRulesValidation) {
      return;
    }

    setCreateRulesetRequestStatus(REQUEST_STATUS.PROCESSING);
    let requestBody: RulesetCreateUpdateRequestType = {
      ...ruleEngineFormData,
      rules: ruleEngineFormData.rules.map((rule) => ({
        ...rule,
        statementValuesList: rule.statementValuesList.map((statementValue) => ({
          ...statementValue,
          value: addHashPrefixToIntegerAndFloat(
            getTemplateForARuleStatement(
              rulesetTemplateList,
              rule,
              statementValue
            ),
            statementValue
          ),
        })),
      })),
    };

    if (isEditForm) {
      requestBody = {
        ...requestBody,
        rulesetId: rulesetId,
        deletedRulesIds: deletedRuleIds,
      };
    }

    (isEditForm ? updateRuleset(requestBody) : createRuleset(requestBody))
      .then((res: any) => {
        if (res?.status === 200) {
          setCreateRulesetRequestStatus(REQUEST_STATUS.SUCCESS);
          dispatch(setRuleEngineComponent(RuleEngineComponents.SUCCESS_PAGE));
          return;
        }

        setCreateRulesetRequestStatus(REQUEST_STATUS.ERROR);
        dispatch(setErrorMessage(res.data.message));
        dispatch(setRuleEngineComponent(RuleEngineComponents.ERROR_PAGE));
      })
      .catch((e) => {
        onApiCallError(e, false, setCreateRulesetRequestStatus);
        dispatch(setErrorMessage(e.response.data.message));
        dispatch(setRuleEngineComponent(RuleEngineComponents.ERROR_PAGE));
      });
  };

  return (
    <div className="create-ruleset-form flex flex-column flex-fit">
      <div className="ruleset-form-header flex flex-align-items-center">
        <AccessibleDiv
          className="back-arrow flex cursor-pointer"
          onClick={handleBackArrowClick}
        >
          <LeftBackArrowIcon />
        </AccessibleDiv>
        <div className="flex flex-column title-section">
          <div className="modal-heading">{t('createRulesetLabels.title')}</div>
          <div className="table-typography">
            {t('createRulesetLabels.subTitle')}
          </div>
        </div>
        <div className="create-button">
          <Button
            title={
              isEditForm
                ? t('createRulesetLabels.update')
                : t('createRulesetLabels.create')
            }
            onClick={onClickCreateDataset}
            loading={createRulesetRequestStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
      </div>
      <div className="form-container full-height flex">
        <BasicDetails
          createButtonClick={createButtonClick}
          setBasicDetailsValidation={setBasicDetailsValidation}
        />
        <SetupRules
          createButtonClick={createButtonClick}
          setSetupRulesValidation={setSetupRulesValidation}
          rulesetTemplateList={rulesetTemplateList}
          rulesetTemplateRequestStatus={rulesetTemplateRequestStatus}
        />
      </div>
    </div>
  );
};
export default RulesetForm;
