import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import NavigationPath from 'components/NavigationPath';
import { ManualEntryNavs } from 'pages/DataCenterPage/constants';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import DataCenterHeader from '../DataCenterHeader';
import ComputeEntry from '../ComputeEntry';
import StorageEntry from '../StorageEntry';
import NetworkEntry from '../NetworkEntry';
import DatabaseEntry from '../DatabaseEntry';
import SoftwareEntry from '../SoftwareEntry';
import LaborEntry from '../LaborEntry';

import './index.scss';

const ManualEntry = () => {
  const { t } = useTranslation();

  const [selectedManualEntryMenu, setSelectedManualEntryMenu] = useState(
    ManualEntryNavs.COMPUTE
  );

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  const ManualEntryTabsList = [
    {
      key: ManualEntryNavs.COMPUTE,
      label: t('manualEntry.nav.compute'),
      children: <ComputeEntry />,
    },
    {
      key: ManualEntryNavs.STORAGE,
      label: t('manualEntry.nav.storage'),
      children: <StorageEntry />,
    },
    {
      key: ManualEntryNavs.NETWORK,
      label: t('manualEntry.nav.network'),
      children: <NetworkEntry />,
    },
    {
      key: ManualEntryNavs.DATABASE,
      label: t('manualEntry.nav.database'),
      children: <DatabaseEntry />,
    },
    {
      key: ManualEntryNavs.SOFTWARE,
      label: t('manualEntry.nav.software'),
      children: <SoftwareEntry />,
    },
    {
      key: ManualEntryNavs.LABOUR,
      label: t('manualEntry.nav.labor'),
      children: <LaborEntry />,
    },
  ];

  return (
    <div className="manual-entry-form flex flex-column flex-fit">
      <DataCenterHeader />
      <div className="page-content">
        <NavigationPath />
        <Tabs
          rootClassName="manual-entry-tabs"
          type="card"
          activeKey={selectedManualEntryMenu}
          items={ManualEntryTabsList}
          onChange={function (activeKey) {
            setSelectedManualEntryMenu(activeKey as ManualEntryNavs);
          }}
          destroyInactiveTabPane
        />
      </div>
    </div>
  );
};

export default ManualEntry;
