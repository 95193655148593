import { Divider } from 'antd';

import ChartTypes from './components/ChartTypes';
import DimensionsAndMetrics from './components/DimensionsAndMetrics';

import './index.scss';

const ChartOptions = () => {
  return (
    <div className="custom-chart-options flex-fit">
      <ChartTypes />
      <Divider rootClassName="options-divider" />
      <DimensionsAndMetrics />
    </div>
  );
};

export default ChartOptions;
