import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import { selectDashboard } from 'redux/dashboardSlice';
import GraphHeader from 'components/GraphHeader';
import ColumnChart from 'components/ColumnChart';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import DashboardComponent from 'components/DashboardComponent';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CostByResourceType } from 'types/dataTypes';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { evaluateRequestArray } from 'utils/handleErrors';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getChartExcelExportData } from 'utils/exportToExcel';

import { CostByResourceColumns } from '../../constants';

type CostByResourceProps = {
  costByResource: CostByResourceType[];
  setSelectedCostByResource: (val: CostByResourceType[]) => void;
  requestStatus: string;
  selectedCostByResource: CostByResourceType[];
  isCostByResourceTableView: boolean;
  setIsCostByResourceTableView: (value: boolean) => void;
  pdfView: boolean;
  costByResourceStartDate: string;
  costByResourceEndDate: string;
  onChangeCostByResourceDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};
const CostByResource = ({
  costByResource,
  selectedCostByResource,
  setSelectedCostByResource,
  isCostByResourceTableView,
  setIsCostByResourceTableView,
  requestStatus,
  pdfView,
  costByResourceStartDate,
  costByResourceEndDate,
  onChangeCostByResourceDateRange,
}: CostByResourceProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        className="cursor-pointer"
        defaultValue={[
          moment(costByResourceStartDate),
          moment(costByResourceEndDate),
        ]}
        onChange={onChangeCostByResourceDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
      <GraphFilterDropdown
        allData={costByResource}
        selectedData={selectedCostByResource}
        setSelectedData={setSelectedCostByResource}
        valueSuffix={t('costByResource.resources')}
        fieldName={DROPDOWN_VALUE_FIELDS.RESOURCE}
        designVersion2
      />
    </div>
  );

  const getComponent = () => {
    return isCostByResourceTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={selectedCostByResource.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={CostByResourceColumns}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={selectedCostByResource}
        xField="resource"
        yField="cost"
        groupingField="resource"
        xTitle={t('costByResource.resources')}
        yTitle={t('costByResource.costInCurrency', { currencySymbol })}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
      />
    );
  };

  return (
    <div className="cost-by-resource graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.costByResource')}
        graphName="cost-by-resource"
        filters={filters}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostByResourceTableView}
        setIsTableView={setIsCostByResourceTableView}
        designVersion2
        excelData={getChartExcelExportData(
          t('graphHeadings.costByResource'),
          selectedCostByResource,
          CostByResourceColumns,
          {
            connectionName: selectedDashboard!.connectorName,
          }
        )}
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};

export default CostByResource;
