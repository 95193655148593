import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectGProfilerConnection,
  setGCPConnection,
} from 'redux/gProfilerConnectionSlice';
import GranulateAppsGCPConnection from 'pages/ThirdPartyPage/GranulateAppsComponents/GranulateAppsGCPConnection';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import {
  getGCPServiceData,
  getGCPZonesData,
  getGCPClusterData,
} from '../../../services';
import { extractServiceName } from '../../../utils';

type GCPConnectionFormProps = {
  setSubmitDisabled: (val: boolean) => void;
};

const GCPConnectionForm = ({ setSubmitDisabled }: GCPConnectionFormProps) => {
  const { gcpConnection } = useSelector(selectGProfilerConnection);
  const dispatch = useDispatch();

  const [serviceList, setServiceList] = useState<string[]>([]);
  const [zones, setZones] = useState<string[]>([]);
  const [clusterList, setClusterList] = useState<any[]>([]);
  const [inputLoaderStatus, setInputLoaderStatus] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchingClusterStatus, setFetchingClusterStatus] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );

  const resetGCPConnection = () => {
    dispatch(
      setGCPConnection({
        connectionName: '',
        cloudConnection: '',
        serviceName: '',
        region: '',
        cluster: '',
        file: undefined,
        connectorId: '',
      })
    );
  };

  const addConnectorIdForNewConnection = (connectorId: string) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        connectorId,
      })
    );
  };

  const removeJson = () => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        file: undefined,
        serviceName: '',
        region: '',
        cluster: '',
        connectorId: '',
      })
    );
  };

  const dispatchCluster = (cluster: string) => {
    dispatch(setGCPConnection({ ...gcpConnection, cluster }));
  };

  const dispatchRegionAndResetingCluster = (region: string) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        region,
        cluster: '',
      })
    );
  };

  const dispatchServiceName = (service?: string) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        serviceName: service!,
      })
    );
  };

  const dispatchCloudConnectionAndConnectorId = (
    cloudConnection: string,
    connectorId: string
  ) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        cloudConnection,
        connectorId,
      })
    );
  };

  const dispatchConnectionName = (connectionName: string) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        connectionName,
      })
    );
  };

  const dispatchJsonField = (keyJson: any) => {
    dispatch(
      setGCPConnection({
        ...gcpConnection,
        file: {
          jsonFileId: keyJson,
        },
      })
    );
  };

  /**
   * @function fetchServiceList
   * @description Function to get all services
   */
  const fetchServiceList = () => {
    getGCPServiceData()
      .then((res: any) => {
        setServiceList(
          res?.data?.map((service: string) => extractServiceName(service))
        );
      })
      .catch((e) => onApiCallError(e, true));
  };

  /**
   * @function fetchZones
   * @description Function to get all zones based on connectorId
   */
  const fetchZones = () => {
    setInputLoaderStatus(REQUEST_STATUS.PROCESSING);
    getGCPZonesData({ connectorId: gcpConnection.connectorId })
      .then((res: any) => {
        if (res?.status === 200) {
          setInputLoaderStatus(REQUEST_STATUS.SUCCESS);
          setZones(res?.data);
        }
      })
      .catch((e) => {
        onApiCallError(e, true);
        setInputLoaderStatus(REQUEST_STATUS.ERROR);
      });
  };

  /**
   * @function fetchClusterList
   * @description Function to get all clusters based on zone
   * @param zone cluster related to zone
   */
  const fetchClusterList = (zone: string) => {
    setFetchingClusterStatus(REQUEST_STATUS.PROCESSING);
    getGCPClusterData(gcpConnection.connectorId, zone)
      .then((res: any) => {
        if (res?.status === 200) {
          setFetchingClusterStatus(REQUEST_STATUS.SUCCESS);
          setClusterList(res?.data);
        }
      })
      .catch((e) => {
        setFetchingClusterStatus(REQUEST_STATUS.ERROR);
        onApiCallError(e, true);
      });
  };

  return (
    <GranulateAppsGCPConnection
      setSubmitDisabled={setSubmitDisabled}
      gcpConnection={gcpConnection}
      serviceList={serviceList}
      fetchServiceList={fetchServiceList}
      zones={zones}
      fetchZones={fetchZones}
      clusterList={clusterList}
      setClusterList={setClusterList}
      fetchClusterList={fetchClusterList}
      fetchingClusterStatus={fetchingClusterStatus}
      setFetchingClusterStatus={setFetchingClusterStatus}
      inputLoaderStatus={inputLoaderStatus}
      setInputLoaderStatus={setInputLoaderStatus}
      resetGCPConnection={resetGCPConnection}
      addConnectorIdForNewConnection={addConnectorIdForNewConnection}
      removeJson={removeJson}
      dispatchCluster={dispatchCluster}
      dispatchRegionAndResetingCluster={dispatchRegionAndResetingCluster}
      dispatchCloudConnectionAndConnectorId={
        dispatchCloudConnectionAndConnectorId
      }
      dispatchServiceName={dispatchServiceName}
      dispatchConnectionName={dispatchConnectionName}
      dispatchJsonField={dispatchJsonField}
      hideSetupConnection={true}
    />
  );
};

export default GCPConnectionForm;
