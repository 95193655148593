import i18n from 'i18n';
import { store } from 'redux/store';
import { ComparisonListType, ConnectionListType } from 'types/dashboard';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { SavingsTrackExportColumns } from './constants';

/**
 * @function getSavingsExportData
 * @param data List of savings track data
 * @param formatNumber boolean for formatting the value data into comma separated decimal
 * @returns List of savings track data for export
 */
const getSavingsExportData = (
  data: ComparisonListType[],
  formatNumber: boolean = false
) => {
  return data.map((item) => ({
    savingsType: item.heading,
    savings:
      store.getState().commonUtility.currencySymbol +
      (formatNumber ? numberCommaSeparator(item.value) : item.value),
  }));
};

/**
 * @function getSavingsTrackExcelData
 * @param savingsData List of savings data
 * @param connection connection details of the selected connection
 * @returns Object containing the details of excel export
 */
export const getSavingsTrackExcelData = (
  savingsData: ComparisonListType[],
  connection: ConnectionListType | null
) => {
  return {
    sheetName: i18n.t('graphHeadings.savingsTrack'),
    columns: SavingsTrackExportColumns,
    data: insertIndex(getSavingsExportData(savingsData)),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connection?.displayName,
      },
    ],
  };
};
