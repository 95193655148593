import moment from 'moment';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { HYPHEN_DATE_FORMAT, YEAR_MONTH_WITHOUT_SEPARATOR } from 'utils/date';

/**
 * @function awsCostRequestBody
 * @description Function to get the request body for AWS cost with given date range
 * @param fromMonth : Start date in the format YYYYMM or YYYYM
 * @param toMonth : End date in the format YYYYMM or YYYYM
 * @param isMigrated : Boolean value for checking whether connection is migrated or not
 * @returns the json request body
 */
const awsCostRequestBody = (
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  let fromDate = `#DATE('${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;
  let toDate = `#DATE('${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;
  if (isMigrated) {
    fromDate = `${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
    toDate = `${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
  }
  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: fromDate,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: toDate,
          },
          {
            field: QUERY_FIELDS.BILL_BILL_TYPE,
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function gcpCostRequestBody
 * @description Function to get the request body for GCP cost with given date range
 * @param fromMonth : Start date in the format YYYYMM or YYYYM
 * @param toMonth : End date in the format YYYYMM or YYYYM
 * @returns the json request body
 */
const gcpCostRequestBody = (fromMonth: string, toMonth: string) => {
  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.INVOICE_MONTH,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: fromMonth,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.INVOICE_MONTH,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: toMonth,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function azureCostRequestBody
 * @description Function to get the request body for AZURE cost with given date range
 * @param fromMonth : Start date in the format YYYYMM or YYYYM
 * @param toMonth : End date in the format YYYYMM or YYYYM
 * @returns the json request body
 */
const azureCostRequestBody = (fromMonth: string, toMonth: string) => {
  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.COST_BILLING_CURRENCY,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        conjunctToNextGroup: CONJUNCTIONS.AND,
        filters: [
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment(fromMonth)
              .startOf('month')
              .format(HYPHEN_DATE_FORMAT),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: moment(toMonth).endOf('month').format(HYPHEN_DATE_FORMAT),
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function ociCostRequestBody
 * @description Function to get the request body for OCI cost with given date range
 * @param fromMonth : Start date in the format YYYYMM or YYYYM
 * @param toMonth : End date in the format YYYYMM or YYYYM
 * @returns the json request body
 */
const ociCostRequestBody = (fromMonth: string, toMonth: string) => {
  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.COST_MY_COST,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        conjunctToNextGroup: CONJUNCTIONS.AND,
        filters: [
          {
            field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: fromMonth,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: toMonth,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

export const getCostRequestBodyByProvider = (
  provider: string,
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return gcpCostRequestBody(fromMonth, toMonth);
    case PROVIDER.AWS:
      return awsCostRequestBody(fromMonth, toMonth, isMigrated);
    case PROVIDER.AZURE:
      return azureCostRequestBody(fromMonth, toMonth);
    case PROVIDER.OCI:
      return ociCostRequestBody(fromMonth, toMonth);
  }
};
