import i18n from 'i18n';
import { uniqBy } from 'lodash';
import moment from 'moment';

import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { DateRangeFilter, MonthlyCostType } from 'types/dataTypes';
import {
  getClusterCostExcelData,
  getCostTrendExcelData,
  getNamespaceUsageCostExcelData,
} from 'pages/ContainerInsightsPage/utils';
import {
  ClusterCostType,
  NamespaceUsageCostType,
} from 'pages/ContainerInsightsPage/types';
import { MONTH_YEAR_FORMAT } from 'utils/date';

import {
  PodMemoryUtilizationExportColumns,
  PodNetworkUtilizationExportColumns,
} from '../constants';
import {
  UtilizationByDimensionAndNodeType,
  UtilizationByNodeAndTimeType,
} from '../types';

/**
 * @function getUtilizationByDimensionExportColumns
 * @description Function to return the utilization by dimension excel export columns
 * @param utilizationData List of utilization data
 * @returns List of objects containing excel columns details
 */
export const getUtilizationByDimensionExportColumns = (
  utilizationData: UtilizationByNodeAndTimeType[]
) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.dimension'),
    key: 'dimension',
    dataKey: 'dimension',
    width: 20,
  },
  ...uniqBy(utilizationData, 'time').map((item) => ({
    header: item.time,
    key: `${item.time}-utilization`,
    dataKey: `${item.time}-utilization`,
    width: 15,
    alignment: 'right',
  })),
];

/**
 * @function getPodCpuUtilizationExcelData
 * @description Function to return the Pod CPU utilization excel data
 * @param podCpuUtilizationData List of Pod CPU utilization data
 * @param tableData data to be exported
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getPodCpuUtilizationExcelData = (
  podCpuUtilizationData: UtilizationByNodeAndTimeType[],
  tableData: any[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.podCpuUtilization'),
    columns: getUtilizationByDimensionExportColumns(podCpuUtilizationData),
    data: tableData,
    filters: filters,
  };
};

/**
 * @function getPodMemoryUtilizationExportData
 * @description Function to return the pod memory utilization export data
 * @param podMemoryUtilizationData List of pod memory utilization data
 * @returns List of modified data
 */
export const getPodMemoryUtilizationExportData = (
  podMemoryUtilizationData: UtilizationByDimensionAndNodeType[]
) => {
  return insertIndex(podMemoryUtilizationData).map((item) => ({
    ...item,
    utilization: numberCommaSeparator(item.utilization),
  }));
};

/**
 * @function getNamespaceUsageCostExcelData
 * @description Function to return the pod memory utilization excel data
 * @param podMemoryUtilizationData List of pod memory utilization data
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getPodMemoryUtilizationExcelData = (
  podMemoryUtilizationData: UtilizationByDimensionAndNodeType[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.podMemoryUtilization'),
    columns: PodMemoryUtilizationExportColumns,
    data: getPodMemoryUtilizationExportData(podMemoryUtilizationData),
    filters: filters,
  };
};

/**
 * @function getPodNetworkUtilizationExcelData
 * @description Function to return the pod usage and cost excel data
 * @param podNetworkUtilizationData List of pod usage and cost data
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getPodNetworkUtilizationExcelData = (
  podNetworkUtilizationData: UtilizationByDimensionAndNodeType[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.effectiveMemoryUtilizationByPod'),
    columns: PodNetworkUtilizationExportColumns,
    data: insertIndex(podNetworkUtilizationData),
    filters: filters,
  };
};

/**
 * @function getNodeFileUtilizationExcelData
 * @description Function to return the Node File utilization excel data
 * @param nodeFileUtilizationData List of Pod CPU utilization data
 * @param tableData data to be exported
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getNodeFileUtilizationExcelData = (
  nodeFileUtilizationData: UtilizationByNodeAndTimeType[],
  tableData: any[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.nodeFileUtilization'),
    columns: getUtilizationByDimensionExportColumns(nodeFileUtilizationData),
    data: tableData,
    filters: filters,
  };
};

/**
 * @function getContainerCpuUtilizationExcelData
 * @description Function to return the Container CPU utilization excel data
 * @param containerCpuUtilizationData List of Container CPU utilization data
 * @param tableData data to be exported
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getContainerCpuUtilizationExcelData = (
  containerCpuUtilizationData: UtilizationByNodeAndTimeType[],
  tableData: any[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.containerCpuUtilization'),
    columns: getUtilizationByDimensionExportColumns(
      containerCpuUtilizationData
    ),
    data: tableData,
    filters: filters,
  };
};

/**
 * @function getContainerMemoryUtilizationExcelData
 * @description Function to return the Container Memory utilization excel data
 * @param containerMemoryUtilizationData List of Container Memory utilization data
 * @param tableData data to be exported
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getContainerMemoryUtilizationExcelData = (
  containerMemoryUtilizationData: UtilizationByNodeAndTimeType[],
  tableData: any[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.containerMemoryUtilization'),
    columns: getUtilizationByDimensionExportColumns(
      containerMemoryUtilizationData
    ),
    data: tableData,
    filters: filters,
  };
};

/**
 * @function getUsageMeteringExcelData
 * @description Function to return the excel export data for aws usage metering dashboard
 * @param clusterFilters List of global filters
 * @param costTrend Object containing the cost trend data and filters
 * @param podCpuUtilization Object containing the Pod CPU Utilization data and filters
 * @param podMemoryUtilization Object containing the Pod Memory Utilization data and filters
 * @param podNetworkUtilization Object containing the Pod Network Utilization data and filters
 * @param nodeFileUtilization Object containing the Node File Utilization data and filters
 * @param containerCpuUtilization Object containing the Container CPU Utilization data and filters
 * @param containerMemoryUtilization Object containing the Container Memory Utilization data and filters
 * @returns List of objects containing excel export details
 */
export const getUsageMeteringExcelData = (
  clusterFilters: any[],
  costTrend: {
    data: MonthlyCostType[];
    dateRange: any[];
    selectedNamespaces: string[];
  },
  namespaceUsageCost: {
    data: NamespaceUsageCostType[];
    dateRange: DateRangeFilter;
    selectedNamespaces: string[];
  },
  clusterCost: {
    data: ClusterCostType[];
    filters: any[];
  },
  podCpuMemoryNetworkUtilization: {
    cpuData: UtilizationByNodeAndTimeType[];
    cpuTableData: any[];
    cpuDateFilter: DateRangeFilter;
    selectedCpuNode: string;
    memoryData: UtilizationByDimensionAndNodeType[];
    memoryDateFilter: DateRangeFilter;
    selectedMemoryNode: string;
    networkData: UtilizationByDimensionAndNodeType[];
    networkDateFilter: DateRangeFilter;
    selectedNetworkNode: string;
  },
  nodeFileUtilization: {
    nodeFileUtilizationData: UtilizationByNodeAndTimeType[];
    tableData: any[];
    nodeFileUtilizationDateFilter: DateRangeFilter;
    selectedNode: string;
  },
  containerCpuMemoryUtilization: {
    cpuData: UtilizationByNodeAndTimeType[];
    cpuTableData: any[];
    cpuDateFilter: DateRangeFilter;
    selectedCpuNode: string;
    memoryData: UtilizationByNodeAndTimeType[];
    memoryTableData: any[];
    memoryDateFilter: DateRangeFilter;
    selectedMemoryNode: string;
  }
) => {
  return [
    getCostTrendExcelData(costTrend.data, [
      ...clusterFilters,
      {
        heading: i18n.t('excelExportLabels.namespaces'),
        value: costTrend.selectedNamespaces.toString(),
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: moment(costTrend.dateRange[0]).format(MONTH_YEAR_FORMAT),
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: moment(costTrend.dateRange[1]).format(MONTH_YEAR_FORMAT),
      },
    ]),
    getNamespaceUsageCostExcelData(namespaceUsageCost.data, [
      ...clusterFilters,
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: namespaceUsageCost.dateRange.startDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: namespaceUsageCost.dateRange.endDate,
      },
      {
        heading: i18n.t('excelExportLabels.namespaces'),
        value: namespaceUsageCost.selectedNamespaces.toString(),
      },
    ]),
    getClusterCostExcelData(clusterCost.data, [
      ...clusterFilters,
      ...clusterCost.filters,
    ]),
    getPodCpuUtilizationExcelData(
      podCpuMemoryNetworkUtilization.cpuData,
      podCpuMemoryNetworkUtilization.cpuTableData,
      [
        ...clusterFilters,
        {
          heading: i18n.t('excelExportLabels.node'),
          value: podCpuMemoryNetworkUtilization.selectedCpuNode,
        },
        {
          heading: i18n.t('excelExportLabels.startDate'),
          value: podCpuMemoryNetworkUtilization.cpuDateFilter.startDate,
        },
        {
          heading: i18n.t('excelExportLabels.endDate'),
          value: podCpuMemoryNetworkUtilization.cpuDateFilter.endDate,
        },
      ]
    ),
    getPodMemoryUtilizationExcelData(
      podCpuMemoryNetworkUtilization.memoryData,
      [
        ...clusterFilters,
        {
          heading: i18n.t('excelExportLabels.startDate'),
          value: podCpuMemoryNetworkUtilization.memoryDateFilter.startDate,
        },
        {
          heading: i18n.t('excelExportLabels.endDate'),
          value: podCpuMemoryNetworkUtilization.memoryDateFilter.endDate,
        },
        {
          heading: i18n.t('excelExportLabels.node'),
          value: podCpuMemoryNetworkUtilization.selectedMemoryNode,
        },
      ]
    ),
    getPodNetworkUtilizationExcelData(
      podCpuMemoryNetworkUtilization.networkData,
      [
        ...clusterFilters,
        {
          heading: i18n.t('excelExportLabels.startDate'),
          value: podCpuMemoryNetworkUtilization.networkDateFilter.startDate,
        },
        {
          heading: i18n.t('excelExportLabels.endDate'),
          value: podCpuMemoryNetworkUtilization.networkDateFilter.endDate,
        },
        {
          heading: i18n.t('excelExportLabels.pods'),
          value: podCpuMemoryNetworkUtilization.selectedNetworkNode,
        },
      ]
    ),
    getNodeFileUtilizationExcelData(
      nodeFileUtilization.nodeFileUtilizationData,
      nodeFileUtilization.tableData,
      [
        ...clusterFilters,
        {
          heading: i18n.t('excelExportLabels.node'),
          value: nodeFileUtilization.selectedNode,
        },
        {
          heading: i18n.t('excelExportLabels.startDate'),
          value: nodeFileUtilization.nodeFileUtilizationDateFilter.startDate,
        },
        {
          heading: i18n.t('excelExportLabels.endDate'),
          value: nodeFileUtilization.nodeFileUtilizationDateFilter.endDate,
        },
      ]
    ),
    getContainerCpuUtilizationExcelData(
      containerCpuMemoryUtilization.cpuData,
      containerCpuMemoryUtilization.cpuTableData,
      [
        ...clusterFilters,
        {
          heading: i18n.t('excelExportLabels.node'),
          value: containerCpuMemoryUtilization.selectedCpuNode,
        },
        {
          heading: i18n.t('excelExportLabels.startDate'),
          value: containerCpuMemoryUtilization.cpuDateFilter.startDate,
        },
        {
          heading: i18n.t('excelExportLabels.endDate'),
          value: containerCpuMemoryUtilization.cpuDateFilter.endDate,
        },
      ]
    ),
    getContainerMemoryUtilizationExcelData(
      containerCpuMemoryUtilization.memoryData,
      containerCpuMemoryUtilization.memoryTableData,
      [
        ...clusterFilters,
        {
          heading: i18n.t('excelExportLabels.node'),
          value: containerCpuMemoryUtilization.selectedMemoryNode,
        },
        {
          heading: i18n.t('excelExportLabels.startDate'),
          value: containerCpuMemoryUtilization.memoryDateFilter.startDate,
        },
        {
          heading: i18n.t('excelExportLabels.endDate'),
          value: containerCpuMemoryUtilization.memoryDateFilter.endDate,
        },
      ]
    ),
  ];
};
