import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Checkbox from 'components/Checkbox';
import {
  MY_DASHBOARD_TYPES_DATA,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import AccessibleDiv from 'components/AccessibleDiv';
import { GroupType } from 'pages/GroupsPage/types';
import { ConnectionListType } from 'types/dashboard';
import { ImportFilesListType } from 'types/importFiles';

type EditUserConnectionListProps = {
  cloudConnections: ConnectionListType[];
  selectedCloudConnections: string[];
  groups: GroupType[];
  selectedGroups: string[];
  importConnections: ImportFilesListType[];
  selectedImportConnections: string[];
  dropdownListType: string;
  setDropdownListType: (value: string) => void;
  setSelectedCloudConnections: (value: string[]) => void;
  setSelectedGroups: (value: string[]) => void;
  setSelectedImportConenctions: (value: string[]) => void;
};

const EditUserConnectionList = ({
  cloudConnections,
  selectedCloudConnections,
  groups,
  selectedGroups,
  importConnections,
  selectedImportConnections,
  dropdownListType,
  setDropdownListType,
  setSelectedCloudConnections,
  setSelectedGroups,
  setSelectedImportConenctions,
}: EditUserConnectionListProps) => {
  const { t } = useTranslation();

  const ConnectionDropdownOptions = (
    <>
      {cloudConnections?.map((connection) => (
        <Select.Option
          value={connection?.connectorId}
          key={connection.connectorId}
        >
          <div>
            <Checkbox
              className="dropdown-checkbox"
              checked={selectedCloudConnections.includes(
                connection.connectorId
              )}
            />
            {connection.displayName}
          </div>
        </Select.Option>
      ))}
    </>
  );

  const GroupDropdownOptions = (
    <>
      {groups?.map((group) => (
        <Select.Option value={group.name} key={group.name}>
          <div>
            <Checkbox
              className="dropdown-checkbox"
              checked={selectedGroups.includes(group.name)}
            />
            {group.name}
          </div>
        </Select.Option>
      ))}
    </>
  );

  const ImportDropdownOptions = (
    <>
      {importConnections?.map((importConnection: ImportFilesListType) => (
        <Select.Option
          value={importConnection.transactionName}
          key={importConnection.transactionName}
        >
          <div>
            <Checkbox
              className="dropdown-checkbox"
              checked={selectedImportConnections.includes(
                importConnection.transactionName
              )}
            />
            {importConnection.displayName}
          </div>
        </Select.Option>
      ))}
    </>
  );

  const ConnectionsGroupsSwitch = (
    <div className="connections-groups-switch flex font-caption flex-center">
      {MY_DASHBOARD_TYPES_DATA.map((myDashboardData) => (
        <AccessibleDiv
          key={myDashboardData.type}
          className={`switch-items flex-fit full-height flex flex-center cursor-pointer ${
            dropdownListType === myDashboardData.type &&
            'active-switch font-caption-bold'
          }`}
          onClick={() => setDropdownListType(myDashboardData.type)}
        >
          {t(`dashboardLabels.${myDashboardData.label}`)}
        </AccessibleDiv>
      ))}
    </div>
  );

  const getSelectedConnectionList = () => {
    switch (dropdownListType) {
      case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
        return ConnectionDropdownOptions;
      case MY_DASHBOARD_TYPES.GROUP:
        return GroupDropdownOptions;
      case MY_DASHBOARD_TYPES.IMPORTS:
        return ImportDropdownOptions;
    }
  };

  const connectionValueOnChange = (value: any) => {
    switch (dropdownListType) {
      case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
        setSelectedCloudConnections(value);
        break;
      case MY_DASHBOARD_TYPES.GROUP:
        setSelectedGroups(value);
        break;
      case MY_DASHBOARD_TYPES.IMPORTS:
        setSelectedImportConenctions(value);
        break;
    }
  };

  const setConnectionValue = () => {
    switch (dropdownListType) {
      case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
        return selectedCloudConnections;
      case MY_DASHBOARD_TYPES.GROUP:
        return selectedGroups;
      case MY_DASHBOARD_TYPES.IMPORTS:
        return selectedImportConnections;
    }
  };

  const getConnectionGroupDropdownRender = (menu: JSX.Element) => (
    <>
      {ConnectionsGroupsSwitch}
      {menu}
    </>
  );

  return (
    <>
      <FormLabel title={t('editUserModal.selectCloudConnection')} />
      <div className="font-subHeader-small">
        {t('editUserModal.selectCloudConnectionSubTitle')}
      </div>
      <SelectDropdown
        mode="multiple"
        value={setConnectionValue()}
        menu={getSelectedConnectionList()}
        dropdownRender={getConnectionGroupDropdownRender}
        onChange={(value: string[]) => {
          connectionValueOnChange(value);
        }}
        dropdownClassName="connections-groups-options"
      />
    </>
  );
};

export default EditUserConnectionList;
