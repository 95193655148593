import React, { useCallback } from 'react';
import { Mix, G2 } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import { COLORS } from 'constants/graphConfig';
import { CHART_AXIS_LABEL_LENGTH_LIMIT } from 'constants/userConsole';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import TooltipContent from 'components/TooltipContent';

type ColumnLineChartProps = {
  data?: any[];
  xField?: string;
  yField?: string;
  xTitle?: string;
  yTitle?: string;
  groupingField?: string;
  showFullLabels?: boolean;
  disableAnimation?: boolean;
  columnColorOverride?: string[] | Function;
  legendOverride?: any[];
  lineAnnotations?: any[];
  plots?: any[];
  markerOverride?: any;
  prefixSymbol?: string;
  additionalTooltipContent?: React.ReactNode;
  onClickColumn?: Function;
  isColumnClickable?: boolean;
  columnStyleOverride?: (data: any) => any;
  tooltipContentOverride?: (title: any, data: any[]) => any;
  onClickLegends?: Function;
  lineColorOverride?:
    | string
    | string[]
    | ((datum: any, defaultColor?: string) => string);
  isColumnStack?: boolean;
  isLineStack?: boolean;
  lineStyleOverride?: Function;
  isGroup?: boolean;
};

const ColumnLineChart = ({
  data,
  xField,
  yField,
  xTitle,
  yTitle,
  groupingField,
  showFullLabels,
  disableAnimation,
  columnColorOverride,
  legendOverride,
  lineAnnotations,
  plots,
  markerOverride,
  prefixSymbol = '',
  additionalTooltipContent,
  onClickColumn,
  isColumnClickable = false,
  columnStyleOverride,
  tooltipContentOverride,
  onClickLegends,
  lineColorOverride,
  isColumnStack,
  isLineStack,
  lineStyleOverride,
  isGroup,
}: ColumnLineChartProps) => {
  const { theme } = useSelector(selectTheme);

  G2.registerShape('point', 'custom-point', {
    draw(cfg, container) {
      const point = {
        x: cfg.x,
        y: cfg.y,
      };
      const group = container.addGroup();
      group.addShape('circle', {
        name: 'outer-point',
        attrs: {
          x: point.x as number,
          y: point.y as number,
          fill: cfg.color ?? 'red',
          opacity: 0.2,
          r: 5,
        },
      });
      group.addShape('circle', {
        name: 'inner-point',
        attrs: {
          x: point.x as number,
          y: point.y as number,
          fill: cfg.color ?? 'red',
          opacity: 1,
          r: 2,
        },
      });
      return group;
    },
  });

  const labelFormatter = useCallback((text: string) => {
    if (text.length > CHART_AXIS_LABEL_LENGTH_LIMIT && !showFullLabels) {
      return text.substring(0, CHART_AXIS_LABEL_LENGTH_LIMIT) + '...';
    }
    return text;
  }, []);

  const getColumnStyle = useCallback(
    columnStyleOverride ??
      (() => ({
        cursor: isColumnClickable ? 'pointer' : 'default',
      })),
    []
  );

  const marker = useCallback(() => markerOverride, []);

  const config: any = {
    syncViewPadding: true,
    seriesField: groupingField,
    legend:
      legendOverride && groupingField
        ? {
            [groupingField]: {
              items: legendOverride,
              custom: true,
              position: 'bottom',
              itemHeight: 16,
              label: {
                style: {
                  fill: theme.textColor,
                },
              },
            },
          }
        : {
            marker: marker,
          },
    plots: plots ?? [
      {
        type: 'column',
        options: {
          data: data,
          xField: xField,
          yField: yField,
          isGroup: isGroup,
          isStack: isColumnStack,
          yAxis: {
            line: {
              style: {
                stroke: COLORS.fnGrey,
                lineWidth: 1,
                opacity: 0.7,
              },
            },
            title: {
              text: yTitle,
            },
          },
          xAxis: {
            line: {
              style: {
                stroke: COLORS.fnGrey,
                lineWidth: 1,
                opacity: 0.7,
              },
            },
            label: {
              autoRotate: showFullLabels,
              formatter: labelFormatter,
            },
            title: {
              text: xTitle,
            },
          },
          columnStyle: getColumnStyle,
          maxColumnWidth: 24,
          seriesField: groupingField,
          color: columnColorOverride ?? theme.primaryColor,
          animation: !disableAnimation,
        },
      },
      {
        type: 'line',
        options: {
          data: data,
          xField: xField,
          yField: yField,
          isStack: isLineStack,
          xAxis: false,
          seriesField: groupingField,
          color: lineColorOverride ?? theme.secondaryColor,
          annotations: lineAnnotations,
          animation: !disableAnimation,
          tooltip: false,
          lineStyle: lineStyleOverride,
        },
      },
    ],
    tooltip: {
      shared: true,
      customItems: useCallback(
        (originalItems: any[]) =>
          originalItems.map((item) => ({
            ...item,
            value: numberCommaSeparator(item.value),
          })),
        []
      ),
      customContent: useCallback(
        tooltipContentOverride ??
          ((title: string, data: any[]) => (
            <TooltipContent
              title={title}
              data={data}
              prefixSymbol={prefixSymbol}
              additionalContent={additionalTooltipContent}
            />
          )),
        []
      ),
    },
    onReady: useCallback((plot: any) => {
      onClickColumn && plot.on('element:click', onClickColumn);
      onClickLegends && plot.on('legend:click', onClickLegends);
    }, []),
  };

  return <Mix {...config} />;
};

export default ColumnLineChart;
