import { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectPurchase, setSavingPlansCartData } from 'redux/purchaseSlice';
import QuickActionMenu from 'components/QuickActionMenu';
import {
  CartListQuickActions,
  CART_LIST_QUICKACTIONS,
  SPAndOpenSearchPaymentOptionsList,
  SAVINGS_PLANS_TYPES,
} from 'pages/PurchasePage/constants';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { DATE_TIME_AM_PM } from 'utils/date';

import BuySavingPlansFormModal from '../BuySavingPlansFormModal';
import { getMonthlyPayment, getSubTotalDue } from '../../utils';
import { PurchaseSavingPlansType } from '../../types';

import './index.scss';

type PurchasedSavingPlansCardProps = {
  spData: PurchaseSavingPlansType;
  index: number;
};

const PurchasedSavingPlansCard = ({
  spData,
  index,
}: PurchasedSavingPlansCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { savingPlansCartData } = useSelector(selectPurchase);

  const [showSpForm, setShowSpForm] = useState(false);

  /**
   * @function handleQuickActions
   * @description Function to handle the quick actions of the cart items
   * @param key quick action key
   */
  const handleQuickActions = (key: string) => {
    if (key === CartListQuickActions.EDIT) {
      setShowSpForm(true);
      return;
    }

    if (key === CartListQuickActions.DELETE) {
      const cartData = [...savingPlansCartData];
      cartData.splice(index, 1);
      dispatch(setSavingPlansCartData(cartData));
    }
  };

  return (
    <>
      <Row className="purchased-sp-card flex flex-align-items-center flex-space-between flex-gap-8">
        <Col span={4} className="flex flex-column flex-gap-8">
          <div className="font-button">{`${
            SAVINGS_PLANS_TYPES.find(
              (item) => item.value === spData.savingsPlanType
            )?.label
          } ${t('purchasePage.savingsPlans')}`}</div>
          <div className="payment-type font-small-bold">
            {
              SPAndOpenSearchPaymentOptionsList.find(
                (item) => item.value === spData.paymentOption
              )?.label
            }
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="sp-data-label font-caption-bold">
            {t('purchasePage.cart.region')}
          </div>
          <div className="font-caption-bold">{spData.region}</div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="sp-data-label font-caption-bold">
            {t('purchasePage.savingsPlansLabels.instanceFamily')}
          </div>
          <div className="font-caption-bold">
            {spData.instanceFamily ?? '--'}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="sp-data-label font-caption-bold">
            {t('purchasePage.cart.term')}
          </div>
          <div className="font-caption-bold">
            {t('purchasePage.cart.countMonths', { count: spData.term * 12 })}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="sp-data-label font-caption-bold">
            {t('purchasePage.savingsPlansLabels.start')}
          </div>
          <div className="font-caption-bold">
            {spData.purchaseTime
              ? moment(spData.purchaseTime).format(DATE_TIME_AM_PM)
              : '--'}
          </div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="sp-data-label font-caption-bold">
            {t('purchasePage.savingsPlansLabels.monthlyPayment')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(
              getMonthlyPayment(
                spData.term,
                spData.commitment,
                spData.upfrontPaymentAmount
              )
            )}
          </div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="sp-data-label font-caption-bold">
            {t('purchasePage.savingsPlansLabels.upfrontCost')}
          </div>
          <div className="font-caption-bold">
            {spData.upfrontPaymentAmount
              ? `${currencySymbol}${numberCommaSeparator(
                  spData.upfrontPaymentAmount
                )}`
              : '--'}
          </div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="sp-data-label font-caption-bold">
            {t('purchasePage.cart.subTotalDue')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(
              getSubTotalDue(
                spData.term,
                spData.commitment,
                spData.upfrontPaymentAmount
              )
            )}
          </div>
        </Col>
        <Col span={1} className="text-align-center">
          <QuickActionMenu
            quickActions={CART_LIST_QUICKACTIONS}
            quickActionHandler={handleQuickActions}
          />
        </Col>
      </Row>
      {showSpForm && (
        <BuySavingPlansFormModal
          show={showSpForm}
          setShow={setShowSpForm}
          editData={spData}
          indexPosition={index}
        />
      )}
    </>
  );
};

export default PurchasedSavingPlansCard;
