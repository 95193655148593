import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Radio, Row, Tooltip } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectScorecard } from 'redux/scorecardSlice';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import AreaChart from 'components/AreaChart';
import GaugeChart from 'components/GaugeChart';
import Table from 'components/Table';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  SAVING_PLAN_REPORTS,
  SAVING_PLAN_REPORT_TYPES,
} from 'pages/ScorecardPage/constants';
import { COLORS } from 'constants/graphConfig';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { evaluateRequestArray } from 'utils/handleErrors';
import { getUtilizationCoverageExcelData } from './utils';

import './index.scss';

type CoverageUtilizationGraphProps = {
  heading: string;
  graphName: string;
  pdfView: boolean;
  isTableView: boolean;
  setIsTableView: (val: boolean) => void;
  coverageUtilizationData: any;
  requestStatus: string[];
  selectedReportType: string;
  setSelectedReportType: (val: string) => void;
  trendGraphTitle: string;
  gaugeGraphTitle: string;
};

const CoverageUtilizationGraph = ({
  heading,
  graphName,
  pdfView,
  isTableView,
  setIsTableView,
  coverageUtilizationData,
  requestStatus,
  selectedReportType,
  setSelectedReportType,
  trendGraphTitle,
  gaugeGraphTitle,
}: CoverageUtilizationGraphProps) => {
  const { t } = useTranslation();
  const { showExpandGraphModal } = useSelector(selectDashboard);
  const { selectedConnection } = useSelector(selectScorecard);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('scorecard.committedUsageDiscount.month'),
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: t('scorecard.committedUsageDiscount.percentage'),
      dataIndex: 'value',
      key: 'percentage',
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text || 0) + '%',
    },
  ];

  /**
   * @function getData
   * @description Function to return the data based on the report type selected
   * @returns Object containing the selected report type data
   */
  const getData = () => {
    if (selectedReportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION) {
      return coverageUtilizationData.utilization;
    }

    return coverageUtilizationData.coverage;
  };

  const valueFormatter = useCallback((v: any) => `${v}%`, []);

  const pointSize = useCallback((datum: any) => {
    if (datum.month === moment().format(MONTH_YEAR_FORMAT)) {
      return 5;
    }
    return 0;
  }, []);

  const pointShape = useCallback((datum: any) => {
    if (datum.month === moment().format(MONTH_YEAR_FORMAT)) {
      return 'breath-point';
    }
  }, []);

  const tooltipCustomItems = useCallback(
    (originalItems: any[]) =>
      originalItems.map((item) => ({
        ...item,
        name: SAVING_PLAN_REPORTS.find(
          (reportType) => reportType.value === selectedReportType
        )?.label,
        value: numberCommaSeparator(item.data.value) + '%',
      })),
    []
  );

  const getComponent = () => (
    <Row gutter={48} className="graph">
      <Col span={18} className="flex flex-column flex-gap-8 full-height">
        <div className="font-button flex flex-align-items-center flex-gap-4">
          <span>{trendGraphTitle}</span>
          {!pdfView && (
            <Tooltip
              overlayClassName="formulae-tooltip table-typography"
              title={getData().tooltipContent}
              placement="right"
            >
              <InformationLineIcon className="info-icon cursor-pointer" />
            </Tooltip>
          )}
        </div>
        {isTableView ? (
          <Table
            pagination={false}
            loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
            dataSource={getData().data}
            columns={columns}
            scroll={{ y: 200 }}
            designVersion2
          />
        ) : (
          <AreaChart
            data={getData().data}
            xField="month"
            yField="value"
            xAxisTitleOverride={null}
            yAxisTitleOverride={null}
            meta={{
              value: { formatter: valueFormatter },
            }}
            disableAnimation={pdfView}
            legendOverride={false}
            colorOverride={COLORS.fnPink}
            areaStyleOverride={{
              fill: `l(270) 0:#ffffff 0.5:${COLORS.fnPink} 1:${COLORS.fnPink}`,
            }}
            point={{
              size: pointSize,
              shape: pointShape,
            }}
            tooltipOverride={{
              customItems: tooltipCustomItems,
            }}
            annotations={[
              {
                type: 'html',
                html: `<div class="annotation"><div class="month">${moment().format(
                  MONTH_YEAR_FORMAT
                )}</div><div class="value">${numberCommaSeparator(
                  getData().data.find(
                    (item: any) =>
                      item.month === moment().format(MONTH_YEAR_FORMAT)
                  )?.value || 0
                )}%</div></div>`,
                position: [
                  moment().format(MONTH_YEAR_FORMAT),
                  getData().data.find(
                    (item: any) =>
                      item.month === moment().format(MONTH_YEAR_FORMAT)
                  )?.value || 0,
                ],
                offsetY: -45,
                offsetX: -55,
                top: true,
              },
            ]}
          />
        )}
      </Col>
      <Col span={6} className="gauge-container flex flex-column flex-gap-8">
        <div className="font-button">{gaugeGraphTitle}</div>
        <div className="gauge-graph">
          <GaugeChart
            percent={getData().averagePercentage}
            ticks={[0, 1 / 3, 2 / 3, 1]}
            colorOverride={[COLORS.fnOrange, COLORS.fnYellow, COLORS.fnGreen]}
            title={t('scorecard.committedUsageDiscount.monthlyAverage')}
            contentText={
              numberCommaSeparator(getData().averagePercentage || 0) + '%'
            }
            axisLabelFontSize={8}
            rangeWidth={10}
            statisticsTitleFontSize="8px"
            disableAnimation={pdfView}
          />
        </div>
      </Col>
    </Row>
  );

  const HeadingComponent = (
    <div className="flex flex-gap-12 flex-align-items-center">
      <div>{heading}</div>
      <div>
        <Radio.Group
          options={SAVING_PLAN_REPORTS}
          onChange={(e) => setSelectedReportType(e.target.value)}
          value={selectedReportType}
          optionType="button"
          rootClassName="no-custom-style savings-types"
          style={{ height: 28 }}
        />
      </div>
    </div>
  );

  return (
    <div
      className="graph-card coverage-utilization-report"
      id="graph-container"
    >
      <GraphHeader
        heading={heading}
        headingComponent={HeadingComponent}
        graphName={graphName}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        excelData={{
          multipleSheets: true,
          sheets: getUtilizationCoverageExcelData(
            {
              sheetName: `${heading} (${t(
                'scorecard.savingsTypes.utilization'
              )})`,
              ...coverageUtilizationData.utilization,
            },
            {
              sheetName: `${heading} (${t('scorecard.savingsTypes.coverage')})`,
              ...coverageUtilizationData.coverage,
            },
            selectedConnection
          ),
        }}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
        {!(showExpandGraphModal || pdfView) && (
          <Divider rootClassName="horizontal-divider" />
        )}
      </div>
    </div>
  );
};

export default CoverageUtilizationGraph;
