import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function applyGCPRecommendations
 * @description Function to apply recommendations for GCP
 * @param body Request body
 * @return axios response from POST request
 */
export const applyGCPRecommendations = async (body: any) => {
  return ServiceCalls.post(APIS.APPLY_GCP_RECOMMENDATIONS, body);
};

/**
 * @function getApplicableGCPRecommendations
 * @description Function to get all the applicable recommendations list
 * @return axios response from GET request
 */
export const getApplicableGCPRecommendations = async () => {
  return ServiceCalls.get(APIS.GCP_APPLICABLE_RECOMMENDATIONS);
};

/**
 * @function getGcpCategoryRecommenderMapping
 * @description Function to get the mapping between category and recommenders for GCP
 * @return axios response from GET request
 */
export const getGcpCategoryRecommenderMapping = async () => {
  return ServiceCalls.get(APIS.GET_GCP_CATEGORY_MAPPINGS);
};

/**
 * @function fetchGCPResourceDetails
 * @description Function to fetch the GCP Resource details
 * @param body Request body
 * @return axios response from POST request
 */
export const fetchGCPResourceDetails = async (body: any) => {
  return ServiceCalls.post(APIS.GET_GCP_RESOURCE_DETAILS, body);
};
