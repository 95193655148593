import { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectPurchase, setAwsEc2RisCartData } from 'redux/purchaseSlice';
import QuickActionMenu from 'components/QuickActionMenu';
import {
  CartListQuickActions,
  CART_LIST_QUICKACTIONS,
  OFFERING_CLASS_OPTIONS,
  TENANCY_OPTIONS,
} from 'pages/PurchasePage/constants';
import { RI_PAYMENT_OPTIONS_LIST } from 'pages/PurchasePage/components/ReservedInstances/constants';
import { PurchaseAwsEc2RisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import BuyEc2RiFormModal from '../BuyEc2RiFormModal';
import { getEc2SubTotalDue } from '../../utils';

import './index.scss';

type PurchasedEc2RICardProps = {
  ec2RiData: PurchaseAwsEc2RisType;
  index: number;
};

const PurchasedEc2RICard = ({ ec2RiData, index }: PurchasedEc2RICardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { awsEc2RiCartData } = useSelector(selectPurchase);

  const [showEc2RiForm, setShowEc2RiForm] = useState(false);

  /**
   * @function handleQuickActions
   * @description Function to handle the quick actions of the cart items
   * @param key quick action key
   */
  const handleQuickActions = (key: string) => {
    if (key === CartListQuickActions.EDIT) {
      setShowEc2RiForm(true);
      return;
    }

    if (key === CartListQuickActions.DELETE) {
      const cartData = [...awsEc2RiCartData];
      cartData.splice(index, 1);
      dispatch(setAwsEc2RisCartData(cartData));
    }
  };

  return (
    <>
      <Row className="purchased-ec2-ri-card flex flex-align-items-center">
        <Col span={3} className="flex flex-column flex-gap-8">
          <div className="font-button">{ec2RiData.platform}</div>
          <div className="payment-type font-small-bold">
            {
              RI_PAYMENT_OPTIONS_LIST.find(
                (item) => item.value === ec2RiData.paymentOption
              )?.label
            }
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.instanceType')}
          </div>
          <div className="font-caption-bold">{ec2RiData.instanceType}</div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.region')}
          </div>
          <div className="font-caption-bold">{ec2RiData.region}</div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.availabilityZone')}
          </div>
          <div className="font-caption-bold">{ec2RiData.availabilityZone}</div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.tenancy')}
          </div>
          <div className="font-caption-bold">
            {
              TENANCY_OPTIONS.find((item) => item.value === ec2RiData.tenancy)
                ?.label
            }
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.offeringClass')}
          </div>
          <div className="font-caption-bold">
            {
              OFFERING_CLASS_OPTIONS.find(
                (item) => item.value === ec2RiData.offeringClass
              )?.label
            }
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.term')}
          </div>
          <div className="font-caption-bold">
            {t('purchasePage.cart.countMonths', { count: ec2RiData.term * 12 })}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.quantity')}
          </div>
          <div className="font-caption-bold">{ec2RiData.quantity}</div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t(
              'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
            )}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(
              getEc2SubTotalDue(
                ec2RiData.term,
                ec2RiData.averageMonthlyRecurringCost
              )
            )}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.oneTimePayment')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(ec2RiData.oneTimePayment)}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.subTotalDue')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(ec2RiData.averageMonthlyRecurringCost)}
          </div>
        </Col>
        <Col span={1} className="text-align-center">
          <QuickActionMenu
            quickActions={CART_LIST_QUICKACTIONS}
            quickActionHandler={handleQuickActions}
          />
        </Col>
      </Row>
      {showEc2RiForm && (
        <BuyEc2RiFormModal
          show={showEc2RiForm}
          setShow={setShowEc2RiForm}
          editData={ec2RiData}
          indexPosition={index}
        />
      )}
    </>
  );
};

export default PurchasedEc2RICard;
