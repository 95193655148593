import i18n from 'i18n';
import { onApiCallError } from 'utils/handleErrors';
import { SERVICE_NOW_STATUS } from './constants';
import { getGCPCostImapct } from './services';

export const getTicketStatus = (ticket: string) => {
  switch (ticket) {
    case SERVICE_NOW_STATUS.UNDEFINED:
      return i18n.t('serviceNowLabels.ticketTBC');
    case SERVICE_NOW_STATUS.NEW:
      return i18n.t('serviceNowLabels.new');
    case SERVICE_NOW_STATUS.IN_PROGRESS:
      return i18n.t('serviceNowLabels.inProgress');
    case SERVICE_NOW_STATUS.ON_HOLD:
      return i18n.t('serviceNowLabels.onHold');
    case SERVICE_NOW_STATUS.RESOLVED:
      return i18n.t('serviceNowLabels.resolved');
    case SERVICE_NOW_STATUS.CLOSED:
      return i18n.t('serviceNowLabels.closed');
  }
};

/**
 * @function numberToMebibyteWithSuffix
 * @description Function to convert the given memory into million
 * @param number memory to be converted
 * @returns converted memory
 */
export const numberToMebibyteWithSuffix = (memory: number | null) => {
  if (memory === null) return '-';
  return (memory / 1048576).toFixed(2) + ' Mi';
};

/**
 * @function parseValue
 * @description Function to convert return float value
 * @param value string to be parse to float
 * @return null if value is null else float value
 */
const parseValue = (value: string | null) =>
  value === 'null' ? null : parseFloat(value ?? '');

/**
 * @function convertHpaStringToObject
 * @description Function to convert hpa string to object
 * @param containerString string to be converted to array
 * @return converted object
 */
export const convertHpaStringToObject = (inputString: string) => {
  const result = inputString
    .split(',')
    .map((item) => item.trim())
    .map((item) =>
      item
        .replace('HpaDTO', '')
        .replace('(', '')
        .replace(')', '')
        .replace('{', '')
        .replace('}', '')
    );

  const obj: any = {};
  result.forEach((item) => {
    const [key, value] = item.split('=');

    switch (key) {
      case 'recommendedCpu':
      case 'recommended_cpu':
        obj.recommended_cpu = parseValue(value);
        break;

      case 'recommendedMemory':
      case 'recommended_mem':
        obj.recommended_mem = parseValue(value);
        break;

      default:
        obj[key] = value;
        break;
    }
  });

  return obj;
};

/**
 * @function convertContainerStringToArrayOfObject
 * @description Function to convert container string to array of objects
 * @param containerString string to be converted to array
 * @return array of objects
 */
export const convertContainerStringToArrayOfObject = (
  containerString: string,
  status: boolean
) => {
  const containerStringArray = status
    ? containerString
        .substring(0, containerString.length - 2)
        .split(',')
        .map((item) => item.trim())
        .map((item) =>
          item.replace('[ContainerDTO(', '').replace('ContainerDTO(', '')
        )
    : containerString.substring(2, containerString.length - 2).split(',');

  const keyArray = containerStringArray.map((item) => item.split('=')[0]);

  const count = Array.from(new Set(keyArray)).length;

  const result = [];
  for (let i = 0; i < containerStringArray.length; i += count) {
    const obj: any = {};
    for (let j = 0; j < count; j++) {
      const [key, value] = containerStringArray[i + j].split('=');

      switch (key) {
        case 'name':
        case '{name':
          obj.name = value;
          break;
        case 'currentCpu':
        case 'current_cpu':
          obj.current_cpu = parseValue(value);
          break;
        case 'currentMemory':
        case 'current_mem':
          obj.current_mem = parseValue(value);
          break;
        case 'recommendedCpu':
        case 'recommended_cpu':
          obj.recommended_cpu = parseValue(value);
          break;
        case 'recommendedMemory':
        case 'recommended_memory':
          obj.recommended_memory = parseValue(value);
          break;
        case 'avgImpactOnClusterCpu':
        case 'avg_impact_on_cluster_cpu':
          obj.avg_impact_on_cluster_cpu = parseValue(value);
          break;
        case 'avgImpactOnClusterMemory':
        case 'avg_impact_on_cluster_mem':
          obj.avg_impact_on_cluster_mem = parseValue(value);
          break;
        case 'costImpact':
        case 'cost_impact':
          obj.costImpact = parseValue(value);
          break;
        default:
          obj[key] = value;
          break;
      }
    }

    result.push(obj);
  }

  return result;
};

/**
 * @function fetchCPUMemoryCostImpact
 * @description Function to get the CPU and Memory cost Impact data
 * @param connectorId connector Id for the connection
 * @param requestBody request body for post method
 * @return cost impact
 */
export const fetchCPUMemoryCostImpact = async (
  connectorId: string | undefined,
  requestBody: any[],
  project?: string
) => {
  try {
    const res: any = await getGCPCostImapct(connectorId, requestBody, {
      project: project,
    });

    if (res.status === 200 && res?.data?.length > 0) {
      const costImpact = res.data.map((item: any) => ({
        podName: item.podName,
        costDifference:
          item.currentSkuPricing.totalCost -
          item.recommendedSkuPricing.totalCost,
      }));

      return costImpact;
    } else {
      onApiCallError(res?.error, false);
      return null;
    }
  } catch (e) {
    onApiCallError(e, false);
    return null;
  }
};

/**
 * @function getRequestBodyObject
 * @description Function to get the object for the request body
 * @param serviceName string to be converted to array
 * @param data containing namespace and podName
 * @param skuId skuId
 * @param currentResourceUsageAmount current usage amount
 * @param recommenResourceUsageAmount recommend usage amount
 * @param type MEMORY or CPU
 * @param unitDescription unit type
 * @param usageUnit usage unit
 * @return object for request body
 */
export const getRequestBodyObject = (
  serviceName: string | undefined,
  data: any,
  skuId: string,
  currentResourceUsageAmount: any,
  recommenResourceUsageAmount: any,
  type: string,
  unit: {
    unitDescription: string;
    usageUnit: string;
  }
) => {
  return {
    podName: data.controller_name,
    namespace: data.namespace,
    skuId,
    serviceName,
    currentResourceUsage: currentResourceUsageAmount
      ? {
          type,
          unitDescription: unit.unitDescription,
          usageUnit: unit.usageUnit,
          usageAmount:
            type === 'CPU'
              ? currentResourceUsageAmount / 1000
              : currentResourceUsageAmount,
        }
      : null,
    recommenResourceUsage: recommenResourceUsageAmount
      ? {
          type,
          unitDescription: unit.unitDescription,
          usageUnit: unit.usageUnit,
          usageAmount:
            type === 'CPU'
              ? recommenResourceUsageAmount / 1000
              : recommenResourceUsageAmount,
        }
      : null,
  };
};
