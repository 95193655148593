import { PROVIDER } from 'constants/cloudProviders';
import {
  getAWSRegionsRequestBody,
  getAzureRegionsRequestBody,
  getGCPRegionsRequestBody,
  getOCIRegionsRequestBody,
} from '../constants';

/**
 * @function getRegionsRequestBodyByProvider
 * @description Function to get the request body for regions data
 * @param provider : String provider for which the request body is required
 * @returns the json request body
 */
export const getRegionsRequestBodyByProvider = (
  provider: string,
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGCPRegionsRequestBody(fromMonth, toMonth);
    case PROVIDER.AWS:
      return getAWSRegionsRequestBody(fromMonth, toMonth, isMigrated);
    case PROVIDER.AZURE:
      return getAzureRegionsRequestBody(fromMonth, toMonth);
    case PROVIDER.OCI:
      return getOCIRegionsRequestBody(fromMonth, toMonth);
  }
};
