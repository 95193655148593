import { useSelector } from 'react-redux';
import ChartWithTableWrapper from 'components/ChartWithTableWrapper';
import ColumnChart from 'components/ColumnChart';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { CostByNameType, TypeAndNameByCost } from 'types/dataTypes';
import { ConnectionListType, DashboardFiltersListType } from 'types/dashboard';

import './index.scss';

type ColumnChartWithTableProps = {
  graphTitle: string;
  graphHeadingComponent?: React.ReactNode;
  graphName: string;
  data: (CostByNameType | TypeAndNameByCost)[];
  tableData?: any[];
  chartProperties: {
    isStack?: boolean;
    xTitle: string;
    yTitle: string;
  };
  filters?: DashboardFiltersListType[];
  columns: any[];
  requestStatus: string;
  isTableView: boolean;
  setIsTableView: (val: boolean) => void;
  pdfView: boolean;
  isFullWidth?: boolean;
  connection?: ConnectionListType | null;
  additionalTooltipContent?: React.ReactNode;
  onClickColumn?: Function;
  isColumnClickable?: boolean;
};

const ColumnChartWithTable = ({
  graphTitle,
  graphHeadingComponent,
  graphName,
  data,
  tableData,
  chartProperties,
  filters,
  columns,
  requestStatus,
  isTableView,
  setIsTableView,
  pdfView,
  isFullWidth = true,
  connection,
  additionalTooltipContent,
  onClickColumn,
  isColumnClickable,
}: ColumnChartWithTableProps) => {
  const { currencySymbol } = useSelector(selectCommonUtility);

  return (
    <ChartWithTableWrapper
      graphTitle={graphTitle}
      graphHeadingComponent={graphHeadingComponent}
      graphName={graphName}
      columns={columns}
      data={data}
      tableData={tableData}
      filters={filters}
      requestStatus={[requestStatus]}
      isTableView={isTableView}
      setIsTableView={setIsTableView}
      pdfView={pdfView}
      isFullWidth={isFullWidth}
      connection={connection}
    >
      <ColumnChart
        data={data}
        xField="name"
        yField="cost"
        groupingField={chartProperties.isStack ? 'type' : 'name'}
        xTitle={chartProperties.xTitle}
        yTitle={chartProperties.yTitle}
        isStack={chartProperties.isStack}
        prefixSymbol={currencySymbol}
        disableAnimation={pdfView}
        additionalTooltipContent={additionalTooltipContent}
        onClickColumn={onClickColumn}
        isColumnClickable={isColumnClickable}
      />
    </ChartWithTableWrapper>
  );
};

export default ColumnChartWithTable;
