import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { REQUEST_STATUS } from 'constants/requestBody';

type TimeoutModalProps = {
  show: boolean;
  onClickStayLoggedIn: () => void;
  tokenRefreshStatus: string;
};

const TimeoutModal = ({
  show,
  onClickStayLoggedIn,
  tokenRefreshStatus,
}: TimeoutModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="timeout-modal"
      open={show}
      title={t('sessionTimeout')}
      centered
      footer={
        <Button
          className="flex flex-inline"
          title={t('stayLoggedIn')}
          onClick={onClickStayLoggedIn}
          loading={tokenRefreshStatus === REQUEST_STATUS.PROCESSING}
        />
      }
      closable={false}
      data-testid="timeout-modal"
    >
      <div>{t('sessionTimeoutMessage')}</div>
    </Modal>
  );
};

export default TimeoutModal;
