import { t } from 'i18next';
import Button from 'components/Button';
import { ICONS } from 'constants/icons';
import { BUTTON_SIZE } from 'constants/appearance';
import { MAXIMUM_TAGS } from './constants';
import { TagsType } from '../../types';
import EachTagAndLabel from '../EachTagLabel';

import './index.scss';

type TagsAndLabelsProps = {
  connectionName: string;
  tags: TagsType[];
  setTags: (val: TagsType[]) => void;
};

const TagsAndLabels = ({
  connectionName,
  tags,
  setTags,
}: TagsAndLabelsProps) => {
  /**
   * @function onClickAddTagRow
   * @description Function to add a new row for tags.
   */
  const onClickAddTagRow = () => {
    setTags([...tags, { key: '', mandatory: false }]);
  };

  return (
    <div className="tags-container flex flex-column flex-align-items-center">
      <div className="tags-and-labels card flex flex-column flex-gap-16">
        <div className="description flex flex-space-between">
          <div className="flex flex-column">
            <div className="font-caption-bold">{`${t(
              'tagsOrLabels.descTitle'
            )} ${connectionName}`}</div>
            <div className="font-subHeader-small">
              {t('tagsOrLabels.discSubTitle')}
            </div>
          </div>
          <Button
            title={t('tagsOrLabels.addTagsOrLabels')}
            size={BUTTON_SIZE.SMALL}
            iconName={ICONS.ADD_LINE}
            onClick={onClickAddTagRow}
            disabled={tags.length >= MAXIMUM_TAGS}
          />
        </div>
        <div className="styled-scroll">
          <div className="tags-list flex flex-column">
            {tags
              .map((item, index) => ({ ...item, indexKey: index }))
              .map((tagItem, index) => (
                <EachTagAndLabel
                  tags={tags}
                  setTags={setTags}
                  tagItem={tagItem}
                  index={index}
                  key={tagItem.indexKey}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagsAndLabels;
