import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { cmdbSetup, setCmdbSetupConnection } from 'redux/cmdbSetupSlice';
import Button from 'components/Button';
import { BUTTON_SIZE } from 'constants/appearance';
import Table from 'components/Table';
import { CMDBFieldTagsMappingType } from 'pages/CmdbSetupListPage/types';
import QuickActionMenu from 'components/QuickActionMenu';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ConnectionListType } from 'types/dashboard';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';

import {
  CMDB_FIELD_TAGS_QUICK_ACTION_OPTIONS,
  CmdbFieldTagsQuickActions,
} from './constants';
import CmdbFieldTagsMappingDrawer from './components/CmdbFieldTagsMappingDrawer';
import {
  fetchAndSetCmdbFields,
  fetchAndSetConnections,
  getCmdbFieldOptions,
} from './utils';
import ConnectionTagsMapTable from './components/ConnectionTagsMapTable';

const CmdbFieldTagsMapping = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cmdbSetupConnection } = useSelector(cmdbSetup);

  const [showCmdbTagMappingModal, setShowCmdbTagMappingModal] = useState(false);
  const [cmdbFields, setCmdbFields] = useState<string[]>([]);
  const [cmdbFieldsReqStatus, setCmdbFieldsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [connections, setConnections] = useState<ConnectionListType[]>([]);
  const [fetchConnectionsReqStatus, setFetchConnectionsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [selectedCmdbTagsMap, setSelectedCmdbTagsMap] =
    useState<CMDBFieldTagsMappingType>();
  const [expandedRow, setExpandedRow] = useState(-1);

  useEffect(() => {
    fetchAndSetCmdbFields({
      setCmdbFields: setCmdbFields,
      setReqStatus: setCmdbFieldsReqStatus,
    });

    fetchAndSetConnections({
      setConnections: setConnections,
      setReqStatus: setFetchConnectionsReqStatus,
    });
  }, []);

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions for the table
   * @param record Data item for which the action is triggered
   * @param action Action triggered
   */
  const handleQuickAction = (
    record: CMDBFieldTagsMappingType,
    action: string
  ) => {
    if (action === CmdbFieldTagsQuickActions.EDIT) {
      setSelectedCmdbTagsMap(record);
      setShowCmdbTagMappingModal(true);
    } else {
      dispatch(
        setCmdbSetupConnection({
          ...cmdbSetupConnection!,
          fieldTagsMappings: [
            ...cmdbSetupConnection!.fieldTagsMappings.filter(
              (fieldTagsMap) => fieldTagsMap.cmdbField !== record.cmdbField
            ),
          ],
        })
      );
    }
  };

  const columns = [
    {
      title: t('cmdbField'),
      dataIndex: 'cmdbField',
      key: 'cmdbField',
    },
    {
      title: t('noOfConnections'),
      dataIndex: 'noOfConnections',
      key: 'noOfConnections',
      render: (_text: string, record: CMDBFieldTagsMappingType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          {record.cloudTags.length}
        </div>
      ),
    },
    {
      title: t('actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_text: string, record: CMDBFieldTagsMappingType) => {
        return (
          <QuickActionMenu
            quickActions={CMDB_FIELD_TAGS_QUICK_ACTION_OPTIONS}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      align: 'center' as const,
    },
  ];

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = ({ expanded, onExpand, record }: any) => (
    <Icon
      className="collapse-icon"
      iconName={expanded ? ICONS.ARROW_DOWN_S_LINE : ICONS.ARROW_RIGHT_S_LINE}
      size={ICONS_SIZE.LG}
      onClick={(e) => onExpand(record, e)}
      dataTestId="expand-row-icon"
    />
  );

  /**
   * @function expandedTagsRowRender
   * @description Function to return the component to render for the expanded row
   * @param record Data item for which the row is expanded
   * @return Returns JSX element
   */
  const expandedTagsRowRender = (record: CMDBFieldTagsMappingType) => {
    return (
      <ConnectionTagsMapTable tagMapData={record} connections={connections} />
    );
  };

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="flex flex-end">
        <Button
          title={t('addNewCmdbField')}
          size={BUTTON_SIZE.SMALL}
          onClick={() => setShowCmdbTagMappingModal(true)}
        />
      </div>
      <Table
        pagination={false}
        data-testid="cmdb-field-mapping-table"
        dataSource={cmdbSetupConnection!.fieldTagsMappings.map(
          (fieldTagMap) => ({
            ...fieldTagMap,
            key: fieldTagMap.cmdbField,
          })
        )}
        columns={columns}
        rowClassName="cursor-pointer"
        expandable={{
          columnWidth: 30,
          expandedRowRender: expandedTagsRowRender,
          expandedRowKeys: [expandedRow],
          onExpand: (expanded: boolean, record: any) => {
            if (expanded) {
              setExpandedRow(record.key);
            } else {
              setExpandedRow(-1);
            }
          },
          expandRowByClick: true,
          expandIcon: getExpandIcon,
        }}
        scroll={{ y: 'calc(100vh - 28em)' }}
        designVersion2={true}
        fillContainer={true}
      />
      {showCmdbTagMappingModal && (
        <CmdbFieldTagsMappingDrawer
          show={showCmdbTagMappingModal}
          selectedCmdbTagMap={selectedCmdbTagsMap}
          setShow={(visible: boolean) => {
            setShowCmdbTagMappingModal(visible);
            setSelectedCmdbTagsMap(undefined);
          }}
          cmdbFieldOptions={getCmdbFieldOptions(
            cmdbFields,
            cmdbSetupConnection!.fieldTagsMappings
          )}
          cmdbFieldOptionsReqStatus={cmdbFieldsReqStatus}
          connections={connections}
          connectionsReqStatus={fetchConnectionsReqStatus}
        />
      )}
    </div>
  );
};

export default CmdbFieldTagsMapping;
