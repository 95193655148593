import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectPurchase,
  setSelectedReservedInstancesTab,
} from 'redux/purchaseSlice';

import { SavingsPlansInstancesTabs } from './constants';
import ComputeSavingPlans from './components/Compute';

const AzureSavingsPlans = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedReservedInstancesTab } = useSelector(selectPurchase);

  useEffect(() => {
    dispatch(
      setSelectedReservedInstancesTab(SavingsPlansInstancesTabs.COMPUTE)
    );
  }, []);

  const PurchaseReservedInstanceTabsList = [
    {
      label: t('purchasePage.savingsPlansLabels.compute'),
      key: SavingsPlansInstancesTabs.COMPUTE,
      children: <ComputeSavingPlans />,
    },
  ];

  return (
    <div>
      <Tabs
        rootClassName="purchase-reserved-instances"
        type="card"
        activeKey={selectedReservedInstancesTab}
        items={PurchaseReservedInstanceTabsList}
        onChange={function (activeKey) {
          dispatch(setSelectedReservedInstancesTab(activeKey));
        }}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default AzureSavingsPlans;
