import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FilterType } from 'types/dashboard';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { COLORS } from 'constants/graphConfig';
import AutoComplete from 'components/AutoComplete';
import Icon from 'components/Icon';
import Input from 'components/Input';
import DatePicker from 'components/DatePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import Tooltip from 'components/Tooltip';
import { INPUT_SIZE, KEYCODE } from 'constants/appearance';
import { customDashboard } from 'redux/customDashboardSlice';
import {
  getAllAvailableValues,
  getChartConditions,
  getColumnLabelByField,
  getFieldCategory,
  updateConditionsQuery,
} from 'pages/CustomDashboardPage/utils';
import { FIELD_TYPE, FieldSource } from 'constants/dashboard';

import { ConditionsValueType, CONDITIONS_FOR_FIELDS } from './constants';

type ConditionListItemProps = {
  condition: FilterType;
  index: number;
};

const ConditionListItem = ({ condition, index }: ConditionListItemProps) => {
  const { t } = useTranslation();
  const { selectedMetrics, selectedDimensions } = useSelector(customDashboard);
  const [showConditionsDropdown, setShowConditionsDropdown] = useState(true);
  const [showConditionsTooltip, setShowConditionsTooltip] = useState(false);

  /**
   * @function getConditionProperties
   * @description Returns the condition field, condition operator and condition value
   * @returns Condition field, condition operator and condition value in object
   */
  const getConditionProperties = (comparator?: string) => {
    return CONDITIONS_FOR_FIELDS[
      getFieldCategory(condition.field) as FIELD_TYPE
    ].find(
      (conditionObj: any) =>
        conditionObj.value === (comparator ?? condition.comparator)
    );
  };

  /**
   * @function removeConditionField
   * @description Removes the condition field from the condition query
   */
  const removeConditionField = () => {
    let newConditions = [...getChartConditions()].filter(
      (_, conditionIndex) => conditionIndex !== index
    );
    updateConditionsQuery(newConditions);
  };

  /**
   * @function onKeyDown
   * @description Handles the keydown event on the condition input
   * @param e Keydown event
   */
  const onKeyDown = (e: any) => {
    let newConditions = [...getChartConditions()];
    if (newConditions.length === 0) return;
    if (e.keyCode === KEYCODE.BACKSPACE) {
      if (condition.value) {
        switch (getConditionProperties()?.optionsType) {
          case ConditionsValueType.SINGLE:
          case ConditionsValueType.DATE:
          case ConditionsValueType.DATE_RANGE:
            newConditions.splice(index, 1, {
              ...condition,
              value: '',
            });
            setShowConditionsDropdown(true);
            break;
          case ConditionsValueType.MULTIPLE:
            newConditions.splice(index, 1, {
              ...condition,
              value: condition.value.split(',').slice(0, -1).join(','),
            });
            setShowConditionsDropdown(true);
            break;
          case ConditionsValueType.NUMERIC:
            newConditions.splice(index, 1, {
              ...condition,
              value: condition.value.toString().slice(0, -1),
            });
            setShowConditionsTooltip(true);
            break;
        }
      } else if (condition.comparator) {
        newConditions.splice(index, 1, {
          ...condition,
          comparator: '',
        });
        setShowConditionsDropdown(true);
        setShowConditionsTooltip(false);
      } else if (condition.field) {
        newConditions.splice(index, 1, {
          ...condition,
          field: '',
        });
        setShowConditionsDropdown(true);
      } else if (!condition.field) {
        newConditions.splice(index, 1);
        setShowConditionsDropdown(false);
      }
    }
    updateConditionsQuery(newConditions);
  };

  /**
   * @function onConditionChange
   * @description Handles when a new condition field (metric or dimension) is added
   * or condition operator is changed or condition value is changed
   * @param value New condition field
   */
  const onConditionChange = (value: string) => {
    let newConditions = [...getChartConditions()];
    if (!condition.field) {
      newConditions.splice(index, 1, {
        ...condition,
        field: value,
      });
      setShowConditionsDropdown(true);
    } else if (!condition.comparator) {
      newConditions.splice(index, 1, {
        ...condition,
        comparator: value,
      });
      if (
        getConditionProperties(value)?.optionsType ===
          ConditionsValueType.SINGLE ||
        getConditionProperties(value)?.optionsType ===
          ConditionsValueType.MULTIPLE
      ) {
        setShowConditionsDropdown(true);
        setShowConditionsTooltip(false);
      } else {
        setShowConditionsDropdown(false);
        setShowConditionsTooltip(true);
      }
    } else {
      const optionsType = getConditionProperties()?.optionsType;
      switch (optionsType) {
        case ConditionsValueType.SINGLE:
        case ConditionsValueType.NUMERIC:
        case ConditionsValueType.DATE:
          if (condition.value === value) return;
          newConditions.splice(index, 1, {
            ...condition,
            value: value,
          });
          setShowConditionsDropdown(false);
          break;
        case ConditionsValueType.MULTIPLE:
        case ConditionsValueType.DATE_RANGE:
          newConditions.splice(index, 1, {
            ...condition,
            value: [...condition.value.split(','), value]
              .filter((value) => value)
              .join(','),
          });
          setShowConditionsDropdown(true);
          break;
      }
    }
    updateConditionsQuery(newConditions);
  };

  /**
   * @function getOptionsForCondition
   * @description Returns the options for the condition field autocomplete popup
   * @returns Options for the condition field autocomplete popup
   */
  const getOptionsForCondition = () => {
    if (!condition.field) {
      let options = [];
      const availableDimensions = selectedDimensions.filter(
        (dimension) => dimension.dimensionType !== FieldSource.TAGS
      );
      const availableMetrics = selectedMetrics.filter(
        (metric) => metric.dimensionType !== FieldSource.TAGS
      );
      if (availableDimensions.length > 0) {
        options.push({
          label: (
            <span className="font-small-bold">
              {t('customDashboard.optionsLabels.dimension')}
            </span>
          ),
          options: availableDimensions.map((dimension) => ({
            label: <span className="font-caption">{dimension.label}</span>,
            value: dimension.field,
          })),
        });
      }
      if (availableMetrics.length > 0) {
        options.push({
          label: (
            <span className="font-small-bold">
              {t('customDashboard.optionsLabels.metrics')}
            </span>
          ),
          options: availableMetrics.map((metric) => ({
            label: <span className="font-caption">{metric.label}</span>,
            value: metric.field,
          })),
        });
      }
      return options;
    } else if (!condition.comparator) {
      return [
        {
          label: (
            <span className="font-small-bold">
              {t('customDashboard.optionsLabels.condition')}
            </span>
          ),
          options: CONDITIONS_FOR_FIELDS[
            getFieldCategory(condition.field) as FIELD_TYPE
          ].map((conditionObj: any) => ({
            label: <span className="font-caption">{conditionObj.label}</span>,
            value: conditionObj.value,
          })),
        },
      ];
    }

    const optionsType = getConditionProperties()?.optionsType;
    if (
      optionsType === ConditionsValueType.SINGLE ||
      optionsType === ConditionsValueType.MULTIPLE
    ) {
      return [
        {
          label: (
            <span className="font-small-bold">
              {getColumnLabelByField(condition.field)}
            </span>
          ),
          options: getAllAvailableValues(condition.field)
            .filter(
              (availableValue) =>
                !condition.value.split(',').includes(availableValue)
            )
            .map((value: any) => ({
              label: <span className="font-caption">{value}</span>,
              value: value,
            })),
        },
      ];
    }
    return [];
  };

  const getConditionsValueOverlay = () => {
    if (getConditionProperties()?.optionsType === ConditionsValueType.NUMERIC)
      return (
        <div className="flex flex-column flex-gap-4">
          <span className="font-small-bold">
            {t('customDashboard.optionsLabels.value')}
          </span>
          <Input
            type="number"
            size={INPUT_SIZE.SMALL}
            ref={(ref) => {
              if (!showConditionsDropdown && showConditionsTooltip) {
                ref?.focus();
              }
            }}
            value={condition.value}
            onPressEnter={(e: any) => {
              onConditionChange(e.target.value.toString());
              setShowConditionsTooltip(false);
            }}
            onBlur={(e: any) => {
              onConditionChange(e.target.value.toString());
              setShowConditionsTooltip(false);
              setShowConditionsDropdown(true);
            }}
          />
        </div>
      );
    else if (
      getConditionProperties()?.optionsType === ConditionsValueType.DATE ||
      getConditionProperties()?.optionsType === ConditionsValueType.DATE_RANGE
    )
      return (
        <div className="flex flex-column flex-gap-4">
          <span className="font-small-bold">
            {t('customDashboard.optionsLabels.date')}
          </span>
          <DatePicker
            pickerType={
              getConditionProperties()?.optionsType === ConditionsValueType.DATE
                ? DATE_PICKER_TYPE.DATE_PICKER
                : DATE_PICKER_TYPE.RANGE_PICKER
            }
            onChange={(_dates: any, value: string | [string, string]) => {
              if (typeof value === 'string') {
                onConditionChange(value);
                setShowConditionsTooltip(false);
              } else {
                onConditionChange(value.join(','));
              }
              setShowConditionsTooltip(false);
            }}
          />
        </div>
      );
  };

  return (
    <div className="flex flex-gap-16 flex-align-items-center">
      <Tooltip
        overlay={getConditionsValueOverlay}
        placement="bottom"
        className="full-width"
        open={showConditionsTooltip}
        trigger={false}
        arrow={false}
      >
        <AutoComplete
          additionalClassNames="full-width"
          placeholder={t('customDashboard.optionsLabels.writeCondition')}
          value={`${getColumnLabelByField(condition.field)} ${
            getConditionProperties()?.label ?? condition.comparator
          } ${condition.value}`}
          onKeyDown={onKeyDown}
          open={showConditionsDropdown}
          onFocus={() => {
            const optionsType = getConditionProperties()?.optionsType;
            if (
              !condition.comparator ||
              optionsType === ConditionsValueType.SINGLE ||
              optionsType === ConditionsValueType.MULTIPLE
            )
              setShowConditionsDropdown(true);
            else if (condition.comparator && !showConditionsTooltip) {
              setShowConditionsTooltip(true);
            }
          }}
          onBlur={() => {
            setShowConditionsDropdown(false);
          }}
          options={getOptionsForCondition()}
          inputSize={INPUT_SIZE.SMALL}
          onSelect={onConditionChange}
        />
      </Tooltip>
      <Icon
        iconName={ICONS.SUBTRACT_LINE}
        size={ICONS_SIZE.SM}
        color={COLORS.colorRegentGrey}
        onClick={removeConditionField}
      />
    </div>
  );
};

export default ConditionListItem;
