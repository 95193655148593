import { Menu, Skeleton, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DrawerComponent from 'components/DrawerComponent';
import Input from 'components/Input';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import Empty from 'components/Empty';
import { INPUT_SIZE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  TCO_DROPDOWN_MENU_LIST,
  TCO_DROPDOWN_MENU_TYPES,
} from 'pages/TcoDashboardPage/constants';
import { DataCenterListType } from 'pages/DataCenterPage/types';
import { TcoProjectListType } from 'pages/TcoProjectsPage/types';
import {
  setSelectedOnPremise,
  setSelectedTco,
  setSelectedTcoDashboardType,
  tcoDashboard,
} from 'redux/tcoDashboardSlice';
import { getAllDataCenters, getAllTcoProjects } from 'utils/services';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';

import TcoDashboardListItem from './components/TcoDashboardListItem';
import DataCenterDashboardListItem from './components/DataCenterDashboardListItem';
import './index.scss';

type TcoDashboardListDrawerProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  setIsEmptyDashboard: (val: boolean) => void;
  fetchDataCenterDataRequestStatus: string;
  setFetchDataCenterDataRequestStatus: (val: string) => void;
  fetchTcoProjectDataRequestStatus: string;
  setFetchTcoProjectDataRequestStatus: (val: string) => void;
};

const TcoDashboardListDrawer = ({
  show,
  setShow,
  setIsEmptyDashboard,
  fetchDataCenterDataRequestStatus,
  setFetchDataCenterDataRequestStatus,
  fetchTcoProjectDataRequestStatus,
  setFetchTcoProjectDataRequestStatus,
}: TcoDashboardListDrawerProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedOnPremise, selectedTco, selectedTcoDashboardType } =
    useSelector(tcoDashboard);

  const [dataCentersList, setDataCentersList] =
    useState<DataCenterListType[]>();
  const [tcoProjectsList, setTcoProjectsList] =
    useState<TcoProjectListType[]>();

  const [searchKey, setSearchKey] = useState('');
  const [filteredTcoProjectsList, setFilteredTcoProjectsList] =
    useState<TcoProjectListType[]>();
  const [filteredDataCentersList, setFilteredDataCentersList] =
    useState<DataCenterListType[]>();

  useEffect(() => {
    fetchDataCenterData();
    fetchTcoProjectData();
  }, []);

  useEffect(() => {
    if (show) {
      dispatch(
        setSelectedTcoDashboardType(
          selectedTco
            ? TCO_DROPDOWN_MENU_TYPES.TCO
            : TCO_DROPDOWN_MENU_TYPES.ON_PREMISE
        )
      );
    }
  }, [show]);

  useEffect(() => {
    if (selectedTcoDashboardType === TCO_DROPDOWN_MENU_TYPES.TCO) {
      setFilteredTcoProjectsList(
        tcoProjectsList?.filter(
          (item) =>
            item.name.toLowerCase().includes(searchKey.toLowerCase()) ||
            item.description.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilteredDataCentersList(
        dataCentersList?.filter(
          (item) =>
            item.dataCenterCode
              .toLowerCase()
              .includes(searchKey.toLowerCase()) ||
            item.name.toLowerCase().includes(searchKey.toLowerCase()) ||
            item.description.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    }
  }, [searchKey, selectedTcoDashboardType, dataCentersList, tcoProjectsList]);

  useEffect(() => {
    if (
      tcoProjectsList &&
      tcoProjectsList.length === 0 &&
      dataCentersList &&
      dataCentersList.length === 0
    )
      setIsEmptyDashboard(true);

    if (dataCentersList && dataCentersList.length > 0) {
      selectedOnPremise
        ? dispatch(
            setSelectedOnPremise(
              dataCentersList?.find(
                (item) =>
                  item.dataCenterCode === selectedOnPremise.dataCenterCode
              )
            )
          )
        : dispatch(setSelectedOnPremise(dataCentersList?.at(0)));
      dispatch(setSelectedTco(undefined));
      dispatch(setSelectedTcoDashboardType(TCO_DROPDOWN_MENU_TYPES.ON_PREMISE));
      return;
    }

    if (tcoProjectsList && tcoProjectsList.length > 0) {
      selectedTco
        ? dispatch(
            setSelectedTco(
              tcoProjectsList.find((item) => item.name === selectedTco.name)
            )
          )
        : dispatch(setSelectedTco(tcoProjectsList[0]));
      dispatch(setSelectedOnPremise(undefined));
      dispatch(setSelectedTcoDashboardType(TCO_DROPDOWN_MENU_TYPES.TCO));
    }
  }, [dataCentersList, tcoProjectsList]);

  /**
   * @function fetchDataCenterData
   * @description Function to fetch Data Center data
   */
  const fetchDataCenterData = () => {
    setFetchDataCenterDataRequestStatus(REQUEST_STATUS.PROCESSING);

    getAllDataCenters()
      .then((res: any) => {
        if (res?.status === 200) {
          const list = res.data.responseData.content;
          setDataCentersList(list);
          setFetchDataCenterDataRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setFetchDataCenterDataRequestStatus(REQUEST_STATUS.ERROR);
        message.error({ content: res?.message ?? t('apiCallErrorMessage') });
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchDataCenterDataRequestStatus);
        setIsEmptyDashboard(true);
      });
  };

  /**
   * @function fetchTcoProjectData
   * @description Function to fetch TcoProjects data and with search and filters
   */
  const fetchTcoProjectData = () => {
    setFetchTcoProjectDataRequestStatus(REQUEST_STATUS.PROCESSING);

    getAllTcoProjects()
      .then((res: any) => {
        if (res?.status === 200) {
          const list = res.data.responseData.content;
          setTcoProjectsList(list);
          setFetchTcoProjectDataRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setFetchTcoProjectDataRequestStatus(REQUEST_STATUS.ERROR);
        message.error({ content: res?.message ?? t('apiCallErrorMessage') });
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchTcoProjectDataRequestStatus);
        setIsEmptyDashboard(true);
      });
  };

  const DashboardList =
    selectedTcoDashboardType === TCO_DROPDOWN_MENU_TYPES.TCO
      ? (filteredTcoProjectsList ?? []).map((dashboard) => (
          <TcoDashboardListItem
            key={dashboard.name}
            dashboard={dashboard}
            onSelectDashboard={() => {
              dispatch(setSelectedOnPremise(undefined));
              dispatch(
                setSelectedTco(
                  filteredTcoProjectsList?.find(
                    (project) => project.name === dashboard.name
                  )
                )
              );
              setShow(false);
            }}
          />
        ))
      : (filteredDataCentersList ?? []).map((dashboard) => (
          <DataCenterDashboardListItem
            key={dashboard.dataCenterCode}
            dashboard={dashboard}
            onSelectDashboard={() => {
              dispatch(setSelectedTco(undefined));
              dispatch(
                setSelectedOnPremise(
                  filteredDataCentersList?.find(
                    (dataCenter) =>
                      dataCenter.dataCenterCode === dashboard.dataCenterCode
                  )
                )
              );
              setShow(false);
            }}
          />
        ));

  return (
    <DrawerComponent
      className="tco-dashboard-list-modal"
      dataTestId="tco-dashboard-list-modal"
      open={show}
      title={
        <header className="flex flex-align-items-center flex-space-between">
          <span>{t('dashboardLabels.allDashboards')}</span>
          <Input
            placeholder={t('dashboardLabels.searchByName')}
            type="search"
            className="search-input"
            value={searchKey}
            size={INPUT_SIZE.SMALL}
            onChange={(e: any) => setSearchKey(e.target.value)}
          />
        </header>
      }
      onClose={() => setShow(false)}
    >
      {evaluateRequestArray([
        fetchDataCenterDataRequestStatus,
        fetchTcoProjectDataRequestStatus,
      ]) === REQUEST_STATUS.PROCESSING ? (
        <>
          <Skeleton avatar active />
          <Skeleton avatar active />
          <Skeleton avatar active />
        </>
      ) : (
        <>
          <HorizontalNavigationMenu
            menuItems={TCO_DROPDOWN_MENU_LIST.map((navMenu) => (
              <Menu.Item
                key={navMenu.type}
                eventKey={navMenu.type}
                className={`font-caption-bold flex-fit ${
                  navMenu.type === selectedTcoDashboardType && 'active-menu'
                }`}
                onClick={() =>
                  dispatch(setSelectedTcoDashboardType(navMenu.type))
                }
              >
                {navMenu.label}
              </Menu.Item>
            ))}
            selectedKeys={[]}
          />
          <div className="dashboard-list flex flex-column">
            {DashboardList.length === 0 ? <Empty /> : DashboardList}
          </div>
        </>
      )}
    </DrawerComponent>
  );
};

export default TcoDashboardListDrawer;
