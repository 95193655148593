import { useCallback, useEffect, useState } from 'react';
import { Area, G2 } from '@ant-design/plots';
import { COLORS, GRAPH_OPTIONS } from 'constants/graphConfig';
import { generateGraphColors } from 'utils/dashboardUtils';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import TooltipContent from 'components/TooltipContent';
import { graphSliderValueFormatter } from 'utils/graphUtils';

type AreaChartProps = {
  data: any[];
  xField: string;
  yField: string;
  xTitle?: string;
  yTitle?: string;
  xAxisTitleOverride?: any;
  yAxisTitleOverride?: any;
  groupingField?: string;
  flipPage?: boolean;
  disableAnimation?: boolean;
  colorOverride?: { [key: string]: string } | string;
  areaStyleOverride?: any;
  legendOverride?: any;
  meta?: any;
  annotations?: any[];
  point?: any;
  tooltipOverride?: any;
  prefixSymbol?: string;
  suffixSymbol?: string;
  showSlider?: boolean;
  sliderCountLimit?: number;
  onSliderValueChange?: (x: number, y: number) => void;
  sliderValues?: { x: number; y: number };
};

const AreaChart = ({
  data,
  xField,
  yField,
  xTitle,
  yTitle,
  xAxisTitleOverride,
  yAxisTitleOverride,
  groupingField,
  flipPage = false,
  disableAnimation = false,
  colorOverride,
  areaStyleOverride,
  legendOverride,
  meta,
  annotations,
  point,
  tooltipOverride,
  prefixSymbol = '',
  suffixSymbol = '',
  showSlider,
  sliderCountLimit = GRAPH_OPTIONS.sliderCountLimit,
  onSliderValueChange,
  sliderValues,
}: AreaChartProps) => {
  const [groupingFieldColorsMap, setGroupingFieldColorsMap] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    getColors();
  }, [data, groupingField, colorOverride]);

  const getColors = () => {
    if (groupingField && colorOverride) {
      setGroupingFieldColorsMap(colorOverride as { [key: string]: string });
      return;
    }

    const groupingFields: string[] = Array.from(
      new Set(data.map((item) => item.groupingField))
    );
    const colors = generateGraphColors(groupingFields.length);

    const groupingFieldColorMap: { [key: string]: string } = {};
    groupingFields.forEach((field, index) => {
      groupingFieldColorMap[field] = colors.at(index) ?? '';
    });

    setGroupingFieldColorsMap(groupingFieldColorMap);
  };

  G2.registerShape('point', 'breath-point', {
    draw(cfg, container) {
      const shapePoint = {
        x: cfg.x,
        y: cfg.y,
      };
      const group = container.addGroup();

      const decorator1 = group.addShape('circle', {
        attrs: {
          x: shapePoint.x as number,
          y: shapePoint.y as number,
          r: 6,
          fill: cfg.color ?? 'red',
          opacity: 0.5,
        },
      });
      decorator1.animate(
        {
          r: 10,
          opacity: 0,
        },
        {
          duration: 1800,
          easing: 'easeLinear',
          repeat: true,
          delay: 1200,
        }
      );
      group.addShape('circle', {
        attrs: {
          x: shapePoint.x as number,
          y: shapePoint.y as number,
          r: 5,
          fill: cfg.color ?? 'red',
          opacity: 0.7,
        },
      });
      group.addShape('circle', {
        attrs: {
          x: shapePoint.x as number,
          y: shapePoint.y as number,
          r: 1.5,
          fill: cfg.color ?? 'red',
        },
      });

      return group;
    },
  });

  const getColorCallback = useCallback(
    (datum: any) => {
      return groupingFieldColorsMap[datum.groupingField];
    },
    [groupingFieldColorsMap]
  );

  const getAreaStyle = useCallback(
    (datum: any) => ({
      fill: `l(270) 0:#ffffff 0.5:${
        groupingFieldColorsMap[datum.groupingField]
      } 1:${groupingFieldColorsMap[datum.groupingField]}`,
    }),
    [groupingFieldColorsMap]
  );

  const getTooltipCustomItems = useCallback(
    (originalItems: any[]) =>
      originalItems.map((item) => ({
        ...item,
        value: numberCommaSeparator(item.value),
      })),
    []
  );

  const getTooltipCustomContent = useCallback(
    (title: string, data: any[]) => (
      <TooltipContent
        title={title}
        data={data}
        prefixSymbol={prefixSymbol}
        postfixSymbol={suffixSymbol}
      />
    ),
    []
  );

  const getSliderTextFormatter = useCallback(
    (_value: any, _data: any, index: number) => {
      return graphSliderValueFormatter(index, data.length);
    },
    []
  );

  const config = {
    data,
    xField,
    yField,
    xAxis: {
      title:
        xAxisTitleOverride !== undefined
          ? xAxisTitleOverride
          : {
              text: xTitle ?? null,
            },
    },
    yAxis: {
      title:
        yAxisTitleOverride !== undefined
          ? yAxisTitleOverride
          : {
              text: yTitle ?? null,
            },
      line: {
        style: {
          stroke: COLORS.fnGrey,
          lineWidth: 1,
          opacity: 0.7,
        },
      },
    },
    legend:
      legendOverride !== undefined
        ? legendOverride
        : {
            position: 'bottom',
            flipPage: flipPage,
            marker: { symbol: 'hyphen' },
            pageNavigator: {
              marker: {
                style: {
                  fill: COLORS.primaryButton,
                  size: 8,
                },
              },
              text: {
                style: {
                  fill: COLORS.secondaryBlack,
                  fontSize: 12,
                },
              },
            },
          },
    seriesField: groupingField,
    color: groupingField ? getColorCallback : (colorOverride as string),
    areaStyle: areaStyleOverride ?? getAreaStyle,
    point: point,
    animation: false,
    meta: meta,
    annotations: annotations,
    tooltip: tooltipOverride
      ? { ...tooltipOverride, customContent: getTooltipCustomContent }
      : {
          customItems: getTooltipCustomItems,
          customContent: getTooltipCustomContent,
        },
    onReady: useCallback((plot: any) => {
      onSliderValueChange &&
        plot.on('slider:valuechanged', (evt: any) =>
          onSliderValueChange(
            evt.gEvent.originalEvent.value[0],
            evt.gEvent.originalEvent.value[1]
          )
        );
    }, []),
    slider:
      showSlider && data.length > sliderCountLimit
        ? {
            start: sliderValues ? sliderValues.x : 0,
            end: sliderValues ? sliderValues.y : sliderCountLimit / data.length,
            backgroundStyle: {
              fill: COLORS.fnGrey13,
              fillOpacity: 0.9,
              radius: 6,
            },
            foregroundStyle: {
              fill: COLORS.colorScrollBar,
              fillOpacity: 0.5,
            },
            handlerStyle: {
              fill: COLORS.colorScrollBar,
              highLightFill: COLORS.colorScrollBar,
              stroke: COLORS.white,
              height: 18,
              width: 12,
            },
            textStyle: {
              fill: COLORS.secondaryBlack,
              opacity: 0.9,
            },
            padding: [0, 10, 0, 0],
            formatter: getSliderTextFormatter,
          }
        : false,
  };

  return <Area {...config} />;
};

export default AreaChart;
