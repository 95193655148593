import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import ExpandModal from 'components/ExpandModal';
import { IncidentRecommendationMappingType } from 'pages/CostOptimizationInsightsPage/types';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ProjectNumberIdMappingType } from 'types/dashboard';
import { getProviderForConnection } from 'utils/dashboardUtils';
import { PROVIDER } from 'constants/cloudProviders';

import RecommendationsHeader from './components/RecommendationsHeader';
import RecommendationTableWithWorkFlow from './components/RecommendationTableWithWorkFlow';
import { getAllProjectNumberIdMapping, getAllSnowIncidents } from './utils';

import './index.scss';

const ConsolidatedRecommendationTable = () => {
  const { selectedCostOptimizationInsightsConnection } = useSelector(
    costOptimizationInsights
  );

  const [serviceNowTickets, setServiceNowTickets] = useState<
    IncidentRecommendationMappingType[]
  >([]);
  const [snowTicketsRequestStatus, setSnowTicketsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  // GCP
  const [projectNumberIdMapping, setProjectNumberIdMapping] = useState<
    ProjectNumberIdMappingType[]
  >([]);

  useEffect(() => {
    setProjectNumberIdMapping([]);

    if (selectedCostOptimizationInsightsConnection) {
      fetchSnowIncidents();

      // Fetch GCP project number to project id mapping
      if (
        getProviderForConnection(selectedCostOptimizationInsightsConnection) ===
        PROVIDER.GCP
      ) {
        getAllProjectNumberIdMapping(
          selectedCostOptimizationInsightsConnection,
          setProjectNumberIdMapping
        );
      }
    }
  }, [selectedCostOptimizationInsightsConnection]);

  const fetchSnowIncidents = () => {
    getAllSnowIncidents(
      selectedCostOptimizationInsightsConnection!,
      setServiceNowTickets,
      setSnowTicketsRequestStatus
    );
  };

  const Component = (
    <div className="consolidated-recommendation-table-container">
      <RecommendationsHeader
        fetchSnowIncidents={fetchSnowIncidents}
        projectNumberIdMapping={projectNumberIdMapping}
      />
      <RecommendationTableWithWorkFlow
        serviceNowTickets={serviceNowTickets}
        snowTicketsRequestStatus={snowTicketsRequestStatus}
        fetchSnowIncidents={fetchSnowIncidents}
        projectNumberIdMapping={projectNumberIdMapping}
      />
    </div>
  );

  return (
    <div>
      {Component}
      <ExpandModal graphContent={Component} />
    </div>
  );
};

export default ConsolidatedRecommendationTable;
