import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Checkbox, Collapse } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';

import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import {
  customDashboard,
  setSelectedDimensions,
} from 'redux/customDashboardSlice';
import Input from 'components/Input';
import Tooltip from 'components/Tooltip';
import Empty from 'components/Empty';
import { INPUT_SIZE } from 'constants/appearance';
import {
  dispatchUpdatedCustomViewData,
  doesDataSourceSupportBillingMapping,
} from 'pages/CustomDashboardPage/utils';
import {
  FIELD_SOURCE_LABELS,
  FieldSource,
  TagSelectionType,
} from 'constants/dashboard';
import { AvailableFieldsType, ColumnType } from 'types/dashboard';

import DimensionList from '../DimensionList';

import './index.scss';

const { Panel } = Collapse;

const Dimensions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customViewDimensions, selectedDimensions } =
    useSelector(customDashboard);

  const [searchKey, setSearchKey] = useState('');
  const [showDimensionDropdown, setShowDimensionDropdown] = useState(false);

  const onChangeDimensionsCheckbox = (
    e: CheckboxChangeEvent,
    dimension: string,
    dimensionField: string,
    source?: FieldSource,
    tagSelectionType?: TagSelectionType
  ) => {
    let selectedValues: ColumnType[];

    if (e.target.checked) {
      selectedValues = [
        ...selectedDimensions,
        {
          label: dimension,
          field: dimensionField,
          dimensionType: source,
          tagDimensionType: tagSelectionType,
        },
      ];
    } else {
      selectedValues = selectedDimensions.filter(
        (item) =>
          item.label !== dimension ||
          item.field !== dimensionField ||
          item.tagDimensionType !== tagSelectionType
      );
    }

    dispatch(setSelectedDimensions(selectedValues));
  };

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="collapse-arrow" />
    );

  /**
   * @function getSelectedDimensionsCount
   * @description get selected dimensions count
   * @param source the field source
   * @returns length of selected dimensions for the given source
   */
  const getSelectedDimensionsCount = (source: string) =>
    customViewDimensions.filter(
      (fieldItem) =>
        fieldItem.name.toLowerCase().includes(searchKey.toLowerCase()) &&
        fieldItem.source === source &&
        selectedDimensions
          .map((dimension) => dimension.field)
          .includes(fieldItem.name)
    ).length;

  /**
   * @function getDimensionsListComponent
   * @description get dimension list component for the given fields
   * @param availableFields the available fields
   * @param source the field source
   * @returns JSX element
   */
  const getDimensionsListComponent = (
    availableFields: AvailableFieldsType[],
    source?: FieldSource
  ) => {
    if (availableFields.length === 0) {
      return <Empty />;
    }
    return availableFields.map((fieldItem) => {
      if (source === FieldSource.TAGS) {
        return (
          <>
            <Checkbox
              key={`${fieldItem.name}${TagSelectionType.KEY}`}
              checked={selectedDimensions.some(
                (dimension) =>
                  dimension.label === fieldItem.name &&
                  dimension.tagDimensionType === TagSelectionType.KEY
              )}
              onChange={(e) =>
                onChangeDimensionsCheckbox(
                  e,
                  fieldItem.name,
                  fieldItem.field + '_' + TagSelectionType.KEY,
                  FieldSource.TAGS,
                  TagSelectionType.KEY
                )
              }
            >
              {`${fieldItem.name} (${TagSelectionType.KEY})`}
            </Checkbox>
            <Checkbox
              key={`${fieldItem.name}${TagSelectionType.VALUE}`}
              checked={selectedDimensions.some(
                (dimension) =>
                  dimension.label === fieldItem.name &&
                  dimension.tagDimensionType === TagSelectionType.VALUE
              )}
              onChange={(e) =>
                onChangeDimensionsCheckbox(
                  e,
                  fieldItem.name,
                  fieldItem.field + '_' + TagSelectionType.VALUE,
                  FieldSource.TAGS,
                  TagSelectionType.VALUE
                )
              }
            >
              {`${fieldItem.name} (${TagSelectionType.VALUE})`}
            </Checkbox>
          </>
        );
      }
      return (
        <Checkbox
          key={fieldItem.name}
          checked={selectedDimensions.some((dimension) =>
            dimension.dimensionType
              ? dimension.label === fieldItem.name
              : dimension.field === fieldItem.name
          )}
          onChange={(e) =>
            onChangeDimensionsCheckbox(
              e,
              fieldItem.name,
              fieldItem.field ?? fieldItem.name,
              source
            )
          }
        >
          {fieldItem.name}
        </Checkbox>
      );
    });
  };

  return (
    <div className="table-dimensions">
      <div className="dimension-heading flex flex-column flex-gap-4">
        <div className="flex flex-align-items-center flex-space-between">
          <div className="font-caption-bold">
            {t('customDashboard.optionsLabels.dimension')}
          </div>
          <Tooltip
            overlayClassName="dimension-dropdown"
            overlay={
              <div className="flex flex-column flex-gap-8">
                <Input
                  type="search"
                  rootClassName="full-width"
                  placeholder={t(
                    'customDashboard.optionsLabels.searchDimensions'
                  )}
                  size={INPUT_SIZE.SMALL}
                  value={searchKey}
                  onChange={(e: any) => setSearchKey(e.target.value)}
                  autoFocus
                />
                {doesDataSourceSupportBillingMapping() ? (
                  <Collapse
                    className="dimensions-group new-styled-scroll"
                    accordion={true}
                    bordered={false}
                    expandIcon={getExpandIcon}
                    defaultActiveKey={[FieldSource.SYSTEM]}
                  >
                    {Object.values(FieldSource).map((source) => (
                      <Panel
                        className="field-panel"
                        header={
                          <div className="flex flex-align-items-center flex-gap-8">
                            {
                              FIELD_SOURCE_LABELS.find(
                                (item) => item.value === source
                              )!.label
                            }
                          </div>
                        }
                        key={source}
                        extra={
                          getSelectedDimensionsCount(source) ? (
                            <div className="font-small-bold flex flex-center values-count">
                              {getSelectedDimensionsCount(source)}
                            </div>
                          ) : null
                        }
                      >
                        <div className="flex flex-column flex-gap-8">
                          {getDimensionsListComponent(
                            customViewDimensions.filter(
                              (fieldItem) =>
                                fieldItem.name
                                  .toLowerCase()
                                  .includes(searchKey.toLowerCase()) &&
                                fieldItem.source === source
                            ),
                            source
                          )}
                        </div>
                      </Panel>
                    ))}
                  </Collapse>
                ) : (
                  <div className="dimensions-group flex flex-column flex-gap-8 new-styled-scroll">
                    {getDimensionsListComponent(
                      customViewDimensions.filter((fieldItem) =>
                        fieldItem.name
                          .toLowerCase()
                          .includes(searchKey.toLowerCase())
                      )
                    )}
                  </div>
                )}
              </div>
            }
            placement="bottomRight"
            open={showDimensionDropdown}
            onOpenChange={(open: boolean) => {
              if (open) {
                setSearchKey('');
              } else {
                dispatchUpdatedCustomViewData();
              }
              setShowDimensionDropdown(open);
            }}
            trigger="click"
            arrow={false}
          >
            <Icon className="add-icon" iconName={ICONS.ADD_LINE} />
          </Tooltip>
        </div>
        <div className="sub-title font-subHeader-small">
          {t('customDashboard.optionsLabels.dimensionSubHeading')}
        </div>
      </div>
      <DimensionList />
    </div>
  );
};

export default Dimensions;
