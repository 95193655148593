import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Empty, message } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { ColumnProps } from 'antd/lib/table';
import { debounce } from 'lodash';
import moment from 'moment';

import NavigationPath from 'components/NavigationPath';
import Table from 'components/Table';
import Input from 'components/Input';
import QuickActionMenu from 'components/QuickActionMenu';
import DeleteModal from 'components/DeleteModal';
import Button from 'components/Button';
import { ConnectionListType } from 'types/dashboard';
import { fetchConnectionData, searchConnectionData } from 'utils/services';
import { DATE_FORMAT } from 'utils/date';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import Pagination from 'components/Pagination';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CLOUD_PROVIDERS_LIST } from 'constants/cloudProviders';
import { setSelectedProvider } from 'redux/providerSlice';
import {
  cloudConnection,
  setCloudConnectionComponent,
  setCurrentConnection,
  setCurrentStep,
  setIsEditConnection,
  setIsTryAgain,
} from 'redux/cloudConnectionSlice';
import { selectDashboard, setConnectionList } from 'redux/dashboardSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import { CONNECTION_QUICK_ACTIONS } from 'constants/quickAction';
import { onApiCallError } from 'utils/handleErrors';
import TableFilterDropdown from 'components/TableFilterDropdown';
import { INPUT_SIZE } from 'constants/appearance';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { getProviderSmallLogo } from 'utils/providerDetails';

import { deleteConnectionData } from './services';
import {
  CONNECTION_STATUS,
  CloudConnectionComponents,
  CloudConnectionQuickActions,
  ConnectionStatusLabels,
} from './constants';
import ConnectionDependencyDisclaimer from './components/ConnectionDependencyDisclaimer';
import { ConnectionDependencyType } from './types';
import { getDependencyList } from './utils';

import './index.scss';

const ConnectingCSPPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { connectionList } = useSelector(selectDashboard);
  const { currentConnection } = useSelector(cloudConnection);
  const { permissions } = useSelector(userAuthorization);

  const [searchKey, setSearchKey] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConnectionRequestStatus, setDeleteConnectionRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [showDependencyDisclaimer, setShowDependencyDisclaimer] =
    useState(false);
  const [dependencies, setDependencies] = useState<ConnectionDependencyType[]>(
    []
  );
  const [
    fetchConnectionDataRequestStatus,
    setFetchConnectionDataRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalConnectionsCount, setTotalConnectionsCount] = useState<number>(0);
  const [filterProviders, setFilterProviders] = useState<string[] | null>([]);

  useEffect(() => {
    addZeroMarginClass();
    fetchAndSetConnectionData(currentPage);

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function addConnectionOnClick
   * @description function to handle add connection
   */
  const addConnectionOnClick = () => {
    dispatch(
      setCloudConnectionComponent(
        CloudConnectionComponents.CLOUD_CONNECTION_FORM
      )
    );
    dispatch(setCurrentStep(0));
    dispatch(setCurrentConnection(undefined));
    dispatch(setIsEditConnection(false));
    navigate(NAVIGATION_MENU_PATH.CREATE_CONNECTIONS);
  };

  /**
   * @function fetchAndSetConnectionData
   * @description Function to fetch connections data and set connections state
   *
   */
  const fetchAndSetConnectionData = (page: number = 1) => {
    setFetchConnectionDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      page: page - 1,
      size: PAGINATION_SIZE,
    };
    fetchConnectionData(params)
      .then((res: any) => {
        dispatch(setConnectionList(res?.data?.responseData?.content));
        setFetchConnectionDataRequestStatus(REQUEST_STATUS.SUCCESS);
        setTotalConnectionsCount(res?.data?.responseData?.totalElements);
      })
      .catch((e) =>
        onApiCallError(e, false, setFetchConnectionDataRequestStatus)
      );
  };

  /**
   * @function fetchSearchConnectionData
   * @description Function to fetch connections data and with search and filters
   * @param searchString string to be searched for.
   * @param providers selected providers for filter.
   * @param page page number defaults to first page
   */
  const fetchSearchConnectionData = (
    providers: string[] | null,
    page: number = 1,
    searchString: string = searchKey
  ) => {
    setFetchConnectionDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      page: page - 1,
      size: PAGINATION_SIZE,
      key: searchString,
      provider: providers?.join(','),
    };
    searchConnectionData(params)
      .then((res: any) => {
        dispatch(setConnectionList(res?.data?.responseData?.content));
        setTotalConnectionsCount(res?.data?.responseData?.totalElements);
        setFetchConnectionDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, true, setFetchConnectionDataRequestStatus)
      );
  };

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    fetchSearchConnectionData(filterProviders, page);
  };

  /**
   * @function handleSearchConnection
   * @description Function to handle search by connection name
   * @param e :accepts search input event
   */
  const handleSearchConnection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchKey(e.target.value);
    fetchSearchConnectionData(filterProviders, 1, e.target.value);
  };

  const handleQuickAction = (action: string) => {
    switch (action) {
      case CloudConnectionQuickActions.EDIT:
        dispatch(setCurrentStep(1));
        dispatch(setIsEditConnection(true));
        navigate(NAVIGATION_MENU_PATH.CREATE_CONNECTIONS);
        break;
      case CloudConnectionQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  /**
   * @function deleteConnectionFromId
   * @description Function to delete connection
   * @param id:accepts the id of the connection to be deleted
   */
  const deleteConnectionFromId = (id: any) => {
    setDeleteConnectionRequestStatus(REQUEST_STATUS.PROCESSING);
    deleteConnectionData(id)
      .then((res: any) => {
        if (res?.status === 200) {
          fetchAndSetConnectionData();
          setCurrentPage(1);
          message.success(t('connectionDeleteModal.deleteSuccessMessage'));
          setDeleteConnectionRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setDeleteConnectionRequestStatus(REQUEST_STATUS.ERROR);
          message.error(t('connectionDeleteModal.deleteFailureMessage'));
        }
        setShowDeleteModal(false);
      })
      .catch((e: any) => {
        if (e.response.status === 409) {
          setDependencies(getDependencyList(e.response.data.responseData));
          setShowDeleteModal(false);
          setShowDependencyDisclaimer(true);
          setDeleteConnectionRequestStatus(REQUEST_STATUS.ERROR);
          return;
        }

        const errorMessage = `${t(
          'connectionDeleteModal.deleteFailureMessage'
        )} ${e?.response?.data?.message}`;
        onApiCallError(e, true, setDeleteConnectionRequestStatus, errorMessage);
        setShowDeleteModal(false);
      });
  };

  /**
   * @function getCloudProviderFilter
   * @description Function to return the cloud provider dropdown for table filter
   * @param Object containing the filter dropdown props
   * @returns JSX element
   */
  const getCloudProviderFilter = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
  }: FilterDropdownProps) => (
    <TableFilterDropdown
      allKeys={filters ?? []}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      designVersion2
    />
  );

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 30,
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
    },
    {
      title: () => t('connectionTable.connectionName'),
      dataIndex: 'displayName',
      key: 'provider',
      filters: CLOUD_PROVIDERS_LIST,
      filterIcon: <Icon iconName={ICONS.FILTER_2_FILL} />,
      filterDropdown: getCloudProviderFilter,
      render: (text: any, record: ConnectionListType) => (
        <div className="flex flex-gap-8 flex-align-items-center">
          <div className="logo">
            <img
              src={getProviderSmallLogo(record.provider)}
              alt={`${record.provider} Logo`}
            />
          </div>
          {text}
        </div>
      ),
    },
    {
      title: t('connectionTable.createdDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 500,
      render: (text: string) => {
        return text !== null ? moment(text).format(DATE_FORMAT) : null;
      },
    },
    {
      title: () => t('connectionTable.connectionStatus'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => (
        <span
          className={`status ${
            text === CONNECTION_STATUS.ACTIVE ? 'active' : 'deactivated'
          }`}
        >
          {ConnectionStatusLabels.find((item) => item.id === text)?.label ??
            text}
        </span>
      ),
    },
    {
      title: t('connectionTable.action'),
      dataIndex: 'quickAction',
      key: 'quickAction',
      align: 'right',
      width: 50,
      render: (_text: string, record: ConnectionListType) => {
        return (
          <QuickActionMenu
            setCurrentConnectionData={() => {
              dispatch(setCurrentConnection(record));
              dispatch(setSelectedProvider(record.provider));
              dispatch(
                setCloudConnectionComponent(
                  CloudConnectionComponents.CLOUD_CONNECTION_FORM
                )
              );
              dispatch(setIsTryAgain(false));
            }}
            quickActions={CONNECTION_QUICK_ACTIONS}
            quickActionHandler={handleQuickAction}
            disabled={!permissions.cloudConnectorModify}
          />
        );
      },
    },
  ];

  return (
    <div className="cloud-connections flex flex-column flex-fit">
      <header className="new-page-header">
        <div className="title-container flex flex-align-items-center flex-space-between">
          <span className="modal-heading">
            {t('navigationMenu.cloudConnections')}
          </span>
          {permissions.cloudConnectorWrite && (
            <Button title={t('addConnection')} onClick={addConnectionOnClick} />
          )}
        </div>
      </header>
      <section className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="table-section">
          <Input
            placeholder={t('connectionTable.searchByName')}
            type="search"
            rootClassName="search-input"
            size={INPUT_SIZE.SMALL}
            onChange={debounce(handleSearchConnection, DEBOUNCE_TIME_DELAY)}
          />
          <Table
            pagination={false}
            dataSource={connectionList.map((connection: any, index) => ({
              ...connection,
              key: index,
            }))}
            columns={columns}
            designVersion2
            loading={
              fetchConnectionDataRequestStatus === REQUEST_STATUS.PROCESSING
            }
            locale={{
              emptyText: fetchConnectionDataRequestStatus !==
                REQUEST_STATUS.PROCESSING && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    fetchConnectionDataRequestStatus === REQUEST_STATUS.ERROR
                      ? t('graphErrorMessage')
                      : t('connectionTable.noConnectionListMessage')
                  }
                />
              ),
            }}
            onChange={(_pagination: any, filters: any) => {
              fetchSearchConnectionData(filters.provider, 1);
              setCurrentPage(1);
              setFilterProviders(filters.provider);
            }}
          />
        </div>
      </section>
      <footer className="page-footer flex">
        {totalConnectionsCount > 0 && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalConnectionsCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </footer>
      {currentConnection !== undefined && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={() => {
            deleteConnectionFromId(currentConnection.connectorId);
          }}
          loading={deleteConnectionRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
      {showDependencyDisclaimer && (
        <ConnectionDependencyDisclaimer
          show={showDependencyDisclaimer}
          onClose={() => {
            setShowDependencyDisclaimer(false);
            setDependencies([]);
          }}
          dependencies={dependencies}
        />
      )}
    </div>
  );
};

export default ConnectingCSPPage;
