import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { SuccessIcon, UploadingIcon } from 'assets/icons';
import SuccessComponent from 'components/SuccessComponent';
import ProviderList from 'components/ConnectionProviderList';
import ProgressBar from 'components/ProgressBar';
import FormFooter from 'components/FormFooter';
import ErrorComponent from 'components/ErrorComponent';
import NavigationPath from 'components/NavigationPath';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { ERROR_KEY, REQUEST_STATUS } from 'constants/requestBody';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import {
  defaultFormData,
  ImportStaticFilesComponents,
  PROGRESS_BAR_ITEMS,
} from 'pages/StaticFilesPage/constants';
import { providerList } from 'redux/providerSlice';
import {
  importStaticFiles,
  setCurrentStep,
  setImportFileData,
  setImportStaticFilesComponent,
} from 'redux/importStaticFileSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import { getTransactionByName } from 'pages/StaticFilesPage/services';
import { ImportFileStatus } from 'types/importFiles';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { validateStringLengthLessThan } from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';

import ImportFileForm from '../ImportFileForm';
import { getSchemaDefaultFields } from '../SchemaDetails/utils';

import './index.scss';

const ImportStaticFile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedProvider } = useSelector(providerList);
  const { staticFilesComponent, importFileData, currentStep, isEdit } =
    useSelector(importStaticFiles);

  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [isValidSchema, setIsValidSchema] = useState(false);
  const [isDuplicateTransactionName, setIsDuplicateTransactionName] =
    useState(true);
  const [formSubmitRequestStatus, setFormSubmitRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fileToBeUploaded, setFileToBeUploaded] = useState<File | undefined>();

  useEffect(() => {
    addZeroMarginClass();
    if (!isEdit) {
      dispatch(
        setImportFileData({
          ...importFileData,
          schemaDefinition: [getSchemaDefaultFields()],
          schema: '',
        })
      );
    }

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function getComponent
   * @description Function to set the page to provider list
   */
  const showProviderList = () => {
    dispatch(setImportFileData(defaultFormData));
    dispatch(setCurrentStep(0));
    dispatch(
      setImportStaticFilesComponent(
        ImportStaticFilesComponents.IMPORT_FILE_FORM
      )
    );
  };

  /**
   * @function setUploadStatusPage
   * @description Function to set the page to based on status and set error message
   * @param status status of upload
   * @param statusMessage upload status message
   */
  const setUploadStatusPage = (status: string, statusMessage: string) => {
    switch (status) {
      case ImportFileStatus.READY: {
        message.success({
          content: statusMessage ?? t('fileUploadSuccess'),
          key: ERROR_KEY,
        });
        dispatch(
          setImportStaticFilesComponent(
            ImportStaticFilesComponents.SUCCESS_PAGE
          )
        );
        break;
      }

      case ImportFileStatus.ERROR: {
        message.error({
          content: statusMessage,
          key: ERROR_KEY,
        });
        dispatch(setErrorMessage(statusMessage));
        dispatch(
          setImportStaticFilesComponent(ImportStaticFilesComponents.ERROR_PAGE)
        );
        break;
      }

      case ImportFileStatus.UPLOADING:
      case ImportFileStatus.PROCESSING:
        message.info({
          content: statusMessage,
          key: ERROR_KEY,
        });
        break;
    }
  };

  /**
   * @function checkUploadStatus
   * @description Function to make a get API call and update the status
   */
  const checkUploadStatus = () => {
    const requestParams = {
      transactionName: importFileData.transactionName,
    };
    getTransactionByName(requestParams)
      .then((res: any) => {
        if (res.status === 200) {
          const responseData = res.data.responseData;
          setUploadStatusPage(
            responseData.fileImportStatus,
            responseData.statusMessage
          );
          return;
        }
        message.error({
          content: t('importFileLabels.uploadPageLabels.errorFetchingStatus'),
          key: ERROR_KEY,
        });
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          undefined,
          t('importFileLabels.uploadPageLabels.errorFetchingStatus')
        );
      });
  };

  /**
   * @function isNextButtonDisabled
   * @description function to check if next button should be disabled
   */
  const isNextButtonDisabled = () => {
    switch (currentStep) {
      case 0:
        return !selectedProvider;
      case 1:
        if (
          importFileData.displayName &&
          !validateStringLengthLessThan(
            importFileData.displayName,
            MAX_CHARACTER_LIMIT
          ) &&
          !isDuplicateTransactionName &&
          importFileData.typeOfDataset
        ) {
          return false;
        }
        return true;
      case 2:
        if (importFileData.focusSchema || importFileData.isExistingSchema) {
          return false;
        }

        if (
          importFileData.schemaName &&
          !validateStringLengthLessThan(
            importFileData.schemaName,
            MAX_CHARACTER_LIMIT
          ) &&
          isValidSchema
        ) {
          return false;
        }
        return true;
      case 3:
        if (fileToBeUploaded) {
          return false;
        }
        return true;
      default:
        return false;
    }
  };

  /**
   * @function getNextButtonTitle
   * @returns next button title
   */
  const getNextButtonTitle = () => {
    if (currentStep !== 3) return t('importFileLabels.next');
    return isEdit ? t('importFileLabels.update') : t('importFileLabels.submit');
  };

  /**
   * @function getComponent
   * @description Function to get the import file component
   */
  const getComponent = () => {
    switch (staticFilesComponent) {
      case ImportStaticFilesComponents.IMPORT_FILE_FORM:
        return (
          <div className="import-file-form-content flex">
            <ProgressBar
              items={PROGRESS_BAR_ITEMS}
              current={currentStep}
              additionalClassNames="progress-bar new-styled-scroll width-30"
              heading={
                isEdit
                  ? t('importFileLabels.formLabels.editHeading')
                  : t('importFileLabels.formLabels.heading')
              }
              subHeading={t('importFileLabels.formLabels.subHeading', {
                stepCount: PROGRESS_BAR_ITEMS.length,
              })}
            />
            <div className="flex flex-column flex-space-between flex-fit">
              <div className="form-content new-styled-scroll">
                {currentStep === 0 ? (
                  <ProviderList
                    heading={t('importFileLabels.formLabels.step1.heading')}
                    subHeading={t(
                      'importFileLabels.formLabels.step1.subHeading'
                    )}
                  />
                ) : (
                  <div className="flex flex-column flex-gap-24">
                    <div className="flex flex-column flex-gap-8 flex-center">
                      <span className="modal-heading">
                        {PROGRESS_BAR_ITEMS[currentStep].title}
                      </span>
                      <span className="table-typography">
                        {PROGRESS_BAR_ITEMS[currentStep].description}
                      </span>
                    </div>
                    <ImportFileForm
                      isValidSchema={isValidSchema}
                      setIsValidSchema={setIsValidSchema}
                      setIsDuplicateTransactionName={
                        setIsDuplicateTransactionName
                      }
                      clickedSubmit={clickedSubmit}
                      setClickedSubmit={setClickedSubmit}
                      setFormSubmitRequestStatus={setFormSubmitRequestStatus}
                      fileToBeUploaded={fileToBeUploaded}
                      setFileToBeUploaded={setFileToBeUploaded}
                    />
                  </div>
                )}
              </div>
              <FormFooter
                showPreviousButton={currentStep !== 0 && !isEdit}
                onClickNext={() => {
                  if (currentStep !== 3) {
                    return dispatch(setCurrentStep(currentStep + 1));
                  }
                  setClickedSubmit(true);
                }}
                nextButtonTitle={getNextButtonTitle()}
                isNextDisabled={isNextButtonDisabled()}
                onClickPrevious={() =>
                  dispatch(setCurrentStep(currentStep - 1))
                }
                submitRequestStatus={formSubmitRequestStatus}
                onClickCancel={() =>
                  navigate(NAVIGATION_MENU_PATH.STATIC_FILES)
                }
              />
            </div>
          </div>
        );

      case ImportStaticFilesComponents.SUCCESS_PAGE:
        return (
          <SuccessComponent
            mainTitle={t('importFileLabels.successPageLabels.header')}
            subTitle={t('importFileLabels.successPageLabels.subHeader')}
            buttonTitle={t(
              'importFileLabels.successPageLabels.goToImportFileList'
            )}
            linkTitle={t('importFileLabels.successPageLabels.importNewFile')}
            onHandleButtonClick={() =>
              navigate(NAVIGATION_MENU_PATH.STATIC_FILES)
            }
            onHandleLinkClick={showProviderList}
            icon={<SuccessIcon />}
          />
        );

      case ImportStaticFilesComponents.UPLOADING_PAGE:
        return (
          <SuccessComponent
            mainTitle={t('importFileLabels.uploadPageLabels.header')}
            subTitle={
              <div className="flex flex-center flex-gap-8">
                <div>{t('importFileLabels.uploadPageLabels.subHeader')}</div>
                <Button
                  title={t('importFileLabels.uploadPageLabels.refresh')}
                  onClick={checkUploadStatus}
                  type={BUTTON_TYPE.LINK}
                />
              </div>
            }
            buttonTitle={t(
              'importFileLabels.successPageLabels.goToImportFileList'
            )}
            linkTitle={t('importFileLabels.successPageLabels.importNewFile')}
            onHandleButtonClick={() =>
              navigate(NAVIGATION_MENU_PATH.STATIC_FILES)
            }
            onHandleLinkClick={showProviderList}
            icon={<UploadingIcon />}
            additionalComponent={
              <span className="font-validation-error error-message">
                {t('importFileLabels.uploadPageLabels.uploadAlertMessage')}
              </span>
            }
          />
        );

      case ImportStaticFilesComponents.ERROR_PAGE:
        return (
          <ErrorComponent
            mainTitle={t('importFileLabels.errorPageLabels.header')}
            buttonTitle={t('importFileLabels.errorPageLabels.tryAgain')}
            linkTitle={t('importFileLabels.errorPageLabels.goToImportFileList')}
            onHandleButtonClick={() =>
              dispatch(
                setImportStaticFilesComponent(
                  ImportStaticFilesComponents.IMPORT_FILE_FORM
                )
              )
            }
            onHandleLinkClick={() =>
              navigate(NAVIGATION_MENU_PATH.STATIC_FILES)
            }
          />
        );
    }
  };
  return (
    <div className="import-file-form flex flex-column flex-fit">
      <header className="new-page-header">
        <div className="title-container flex flex-align-items-center flex-space-between">
          <span className="modal-heading">
            {t('navigationMenu.importStaticFile')}
          </span>
        </div>
        <NavigationPath />
      </header>
      <section className="page-content flex flex-column flex-fit">
        {getComponent()}
      </section>
    </div>
  );
};

export default ImportStaticFile;
