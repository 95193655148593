export const PAGINATION_SIZE = 10;
export const DEBOUNCE_TIME_DELAY = 500;
export const INITIAL_PAGE_NUMBER = 1;
export const TABLE_HEADER_LENGTH_LIMIT = 10;
export const CHART_AXIS_LABEL_LENGTH_LIMIT = 8;
export const CHART_Y_AXIS_LABEL_LENGTH_LIMIT = 15;
export const LEGEND_ITEM_NAME_LENGTH_LIMIT = 15;
export const CHART_TOOLTIP_LABEL_LENGTH_LIMIT = 25;
export const CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT = 15;
export const TAG_FILTER_LABEL_LENGTH_LIMIT = 20;
export const INFINITE_SCROLL_PAGE_SIZE = 20;
export const FETCH_DROPDOWN_PAGINATION_SIZE = 5;

export enum USER_STATUS {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DEACTIVATED = 'DEACTIVATED',
}

export enum Keys {
  ENTER = 'Enter',
  SPACE = ' ',
}
