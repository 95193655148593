import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BarChart from 'components/BarChart';
import GraphHeader from 'components/GraphHeader';
import DashboardComponent from 'components/DashboardComponent';
import AccessibleDiv from 'components/AccessibleDiv';
import { SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP } from 'pages/CostOptimizationInsightsPage/constants';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { evaluateRequestArray } from 'utils/handleErrors';

type CostSavingsSummaryProps = {
  graphHeading: string;
  graphName: string;
  pdfView: boolean;
  savingsSummaryData: number[];
  requestStatus: string;
  selectedSummaryGraph: string;
  setSelectedSummaryGraph: (val: string) => void;
  selectedSavingsType: string | undefined;
  setSelectedSavingsType: (val: string | undefined) => void;
};

const CostSavingsSummary = ({
  graphHeading,
  graphName,
  pdfView,
  savingsSummaryData,
  requestStatus,
  selectedSummaryGraph,
  setSelectedSummaryGraph,
  selectedSavingsType,
  setSelectedSavingsType,
}: CostSavingsSummaryProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  /**
   * @function getComponent
   * @description Function to return the graph or table component
   * @returns JSX element containing graph or the table
   */
  const getComponent = () => {
    return (
      <div className="graph with-sub-title">
        <BarChart
          data={SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.map(
            (item, index) => ({
              name: item.label,
              value: savingsSummaryData[index],
            })
          )}
          xField="value"
          yField="name"
          groupingField="name"
          prefixSymbol={currencySymbol}
          disableAnimation={pdfView}
          isBarClickable
          onClickBar={(e: any) => {
            setSelectedSavingsType(
              SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
                (item) => item.label === e?.data?.data?.name
              )?.type
            );
          }}
          additionalTooltipContent={
            !selectedSavingsType && (
              <span className="tooltip-deep-dive-label font-subHeader-small">
                {t('clickDeepDive')}
              </span>
            )
          }
        />
      </div>
    );
  };

  return (
    <AccessibleDiv
      className={`graph-area summary-table flex flex-column flex-gap-16 flex-fit ${
        selectedSummaryGraph === graphName && 'selected'
      }`}
      id="graph-container"
      onClick={() => setSelectedSummaryGraph(graphName)}
    >
      <GraphHeader
        heading={graphHeading}
        headingComponent={
          <div className="flex flex-column">
            {graphHeading}
            <span className="sub-title font-caption-bold">
              {t('clickBarInfo')}
            </span>
          </div>
        }
        graphName={graphName}
        isTableViewSwitch={false}
        isTableView={false}
        showExpandIcon={!pdfView}
        isDownloadable={!pdfView}
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray([requestStatus])}
      />
    </AccessibleDiv>
  );
};

export default CostSavingsSummary;
