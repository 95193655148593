import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchAwsRegions
 * @description Function to fetch AWS regions
 * @param params request parameters
 * @return axios response from GET request
 */
export const fetchAwsRegions = async (params: any) => {
  const requestAPI = APIS.GET_ALL_AWS_REGIONS + params.connectorId;
  return ServiceCalls.get(requestAPI);
};
