export enum COST_ALLOCATION_NAVS {
  COST_ALLOCATION_SUMMARY = 'COST_ALLOCATION_SUMMARY',
}

export const COST_ALLOCATION_DASHBOARD_NAVIGATION = [
  {
    id: COST_ALLOCATION_NAVS.COST_ALLOCATION_SUMMARY,
    title: 'costAllocationSummary',
  },
];
