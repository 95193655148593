import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import Modal from 'components/Modal';

import './index.scss';

type ConfirmModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  title: React.ReactElement;
  subTitle?: React.ReactElement;
  showSubTitle?: boolean;
  loadingYes?: boolean;
  loadingNo?: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
  titleYes?: string;
  titleNo?: string;
};

const ConfirmModal = ({
  show,
  setShow,
  title,
  subTitle,
  showSubTitle = true,
  loadingYes = false,
  loadingNo = false,
  onClickYes,
  onClickNo,
  titleYes,
  titleNo,
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        open={show}
        footer={null}
        closable={false}
        onCancel={() => setShow(false)}
        width={404}
        centered
        maskClosable={false}
        data-testid="confirm-modal"
      >
        <div className="confirm-modal flex flex-column flex-start text flex-align-items-center">
          <div>
            <span>
              <div className="modal-heading">{title}</div>
              {showSubTitle && (
                <div className="body table-typography">
                  {subTitle ?? t('confirmModal.subTitle')}
                </div>
              )}
            </span>
          </div>
          <div className="button flex">
            <Button
              title={titleYes ?? t('confirmModal.yes')}
              onClick={onClickYes}
              loading={loadingYes}
              disabled={loadingYes || loadingNo}
            />
            <Button
              type={BUTTON_TYPE.OUTLINED}
              title={titleNo ?? t('confirmModal.no')}
              onClick={onClickNo}
              loading={loadingNo}
              disabled={loadingYes || loadingNo}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
