import { Tooltip as BaseTooltip } from 'antd';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';

const Tooltip = ({ ...remainingProps }) => {
  const { theme } = useSelector(selectTheme);

  return (
    <BaseTooltip
      overlayStyle={
        {
          color: theme.textColor,
          '--text-color': theme.textColor,
          '--primary-color': theme.primaryColor,
          '--button-icon-color': theme.buttonIconColor,
        } as React.CSSProperties
      }
      {...remainingProps}
    />
  );
};

export default Tooltip;
