import i18n from 'i18n';
import { QUERY_FIELDS } from './requestBody';

export enum RECOMMENDATION_CATEGORIES {
  WASTE_MANAGEMENT = 'WASTE_MANAGEMENT',
  CONSUMPTION_MANAGEMENT = 'CONSUMPTION_MANAGEMENT',
  PURCHASE_TACTICS = 'PURCHASE_TACTICS',
}

export const RecommendationCategoriesLabels = [
  {
    key: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
    label: i18n.t('recommendationCategoryLabels.wasteManagement'),
  },
  {
    key: RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
    label: i18n.t('recommendationCategoryLabels.consumptionManagement'),
  },
  {
    key: RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
    label: i18n.t('recommendationCategoryLabels.purchaseTactics'),
  },
];

export enum AWS_CHECK_IDS {
  IDLE_RDS_DB_INSTANCE = 'Ti39halfu8',
  IDLE_LOAD_BALANCER = 'hjLMh88uM8',
  UNDERUTILIZED_EC2_INSTANCES = 'Qch7DwouX1',
  IDLE_IP_ADDRESS = 'Z4AUBRNSmz',
  UNDERUTILIZED_EBS_VOLUMES = 'DAvU99Dc4C',
  UNDERUTILIZED_REDSHIFT_CLUSTERS = 'G31sQ1E9U',
  OVERPROVISIONED_EBS_VOLUMES = 'COr6dfpM03',
  EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER = 'Qsdfp3A4L2', // No cost field(null)
  EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER = 'Qsdfp3A4L1',
  ROUTE_53_LATENCY_RECORD_SETS = '51fC20e7I2', // No cost field(null)
  LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT = 'L4dfs2Q3C3', // No cost field(null)
  LAMBDA_FUNCTIONS_HIGH_ERROR_RATES = 'L4dfs2Q3C2', // No cost field(null)
  LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY = 'COr6dfpM05',
  UNDERUTILIZED_ENDPOINTS = 'Cm24dfsM12', // No cost field
  WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR = 'Wxdfp4B1L1', // No cost field
  EC2_INSTANCES_RI_LEASE = '1e93e4c0b5',
  EC2_INSTANCES_RI_OPTIMIZATION = 'cX3c2R1chu', // No partition
  ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION = 'h3L1otH3re', // No partition
  OPEN_SEARCH_RI_OPTIMIZATION = '7ujm6yhn5t', // No partition
  REDSHIFT_RESERVED_NODE_OPTIMIZATION = '1qw23er45t', // No partition
  RDS_RI_OPTIMIZATION = '1qazXsw23e', // No partition
  SAVINGS_PLAN = 'vZ2c2W1srf', // No partition,
  RIGHT_SIZING_EC2 = 'rightsizing_ec2',
}

export const AWS_CHECKS_MAP = [
  {
    check: AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
    uniqueKeyLabel: 'instanceId',
    uniqueKeyField: QUERY_FIELDS.INSTANCE_ID,
    rulesetFiletrsAdditionalColumns: [
      {
        label: '"estimated monthly savings"',
        field:
          "CAST(regexp_replace(\"estimated monthly savings\",'[$]','') AS DOUBLE)",
      },
      {
        label: '"number of days low utilization"',
        field:
          "CAST(regexp_replace(\"number of days low utilization\",'[ days]','') AS INTEGER)",
      },
      {
        label: '"14-day average cpu utilization"',
        field:
          "CAST(regexp_replace(\"14-day average cpu utilization\",'[%]','') AS DOUBLE)",
      },
      {
        label: '"14-day average network i/o"',
        field:
          "CAST(regexp_replace(\"14-day average network i/o\",'[MB]','') AS DOUBLE)",
      },
    ],
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.IDLE_IP_ADDRESS,
    uniqueKeyLabel: 'ipAddress',
    uniqueKeyField: QUERY_FIELDS.IP_ADDRESS,
    rulesetFiletrsAdditionalColumns: [
      {
        label: 'costSavings',
        field: '3.2',
      },
    ],
    savingsCostField: 'costSavings',
  },
  {
    check: AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
    uniqueKeyLabel: 'cluster',
    uniqueKeyField: QUERY_FIELDS.CLUSTER,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE,
    uniqueKeyLabel: 'dbInstanceName',
    uniqueKeyField: QUERY_FIELDS.DB_INSTANCE_NAME,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.IDLE_LOAD_BALANCER,
    uniqueKeyLabel: 'loadBalancerName',
    uniqueKeyField: QUERY_FIELDS.LOAD_BALANCER_NAME,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    rulesetFiletrsAdditionalColumns: [
      {
        label: '"estimated monthly savings"',
        field:
          "CAST(regexp_replace(\"estimated monthly savings\",'[$]','') AS DOUBLE)",
      },
    ],
  },
  {
    check: AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES,
    uniqueKeyLabel: 'volumeId',
    uniqueKeyField: QUERY_FIELDS.VOLUME_ID,
    rulesetFiletrsAdditionalColumns: [
      {
        label: '"monthly storage cost"',
        field:
          "CAST(regexp_replace(\"monthly storage cost\",'[$]','') AS DOUBLE)",
      },
    ],
    savingsCostField: `"${QUERY_FIELDS.MONTHLY_STORAGE_COST}"`,
  },
  {
    check: AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE,
    uniqueKeyLabel: 'reservedInstanceId',
    uniqueKeyField: QUERY_FIELDS.RESERVED_INSTANCE_ID,
    rulesetFiletrsAdditionalColumns: [
      {
        label: '"estimated monthly savings"',
        field:
          "CAST(regexp_replace(\"estimated monthly savings\",'[$]','') AS DOUBLE)",
      },
    ],
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS,
    uniqueKeyLabel: 'resourceName',
    uniqueKeyField: QUERY_FIELDS.RESOURCE_RECORD_SET_NAME,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
    uniqueKeyField: QUERY_FIELDS.VOLUME_ID,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
    uniqueKeyField: QUERY_FIELDS.INSTANCE_ID,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
    uniqueKeyField: QUERY_FIELDS.INSTANCE_ID,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
    uniqueKeyField: QUERY_FIELDS.FUNCTION_ARN,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
    uniqueKeyField: QUERY_FIELDS.FUNCTION_ARN,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
    uniqueKeyField: QUERY_FIELDS.FUNCTION_NAME,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS,
    uniqueKeyField: QUERY_FIELDS.ENDPOINT_ARN,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR,
    uniqueKeyField: QUERY_FIELDS.WORKLOAD_ARN,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
  },
  {
    check: AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
  },
  {
    check: AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
  },
  {
    check: AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
  },
  {
    check: AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
  },
  {
    check: AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
  },
  {
    check: AWS_CHECK_IDS.SAVINGS_PLAN,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_PERCENTAGE}"`,
  },
  {
    check: AWS_CHECK_IDS.RIGHT_SIZING_EC2,
    uniqueKeyLabel: 'instanceId',
    uniqueKeyField: QUERY_FIELDS.INSTANCE_ID_NO_SPACE,
    savingsCostField: `"${QUERY_FIELDS.ESTIMATED_SAVINGS}"`,
    rulesetFiletrsAdditionalColumns: [
      {
        label: '"estimated savings"',
        field: `"${QUERY_FIELDS.ESTIMATED_SAVINGS}"`,
      },
    ],
  },
];

export const CHECK_NAME_ID_MAP = [
  {
    id: AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE,
    name: 'Idle RDS DB Instances',
  },
  {
    id: AWS_CHECK_IDS.IDLE_LOAD_BALANCER,
    name: 'Idle Load Balancers',
  },
  {
    id: AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
    name: 'Underutilized EC2 Instances',
  },
  {
    id: AWS_CHECK_IDS.IDLE_IP_ADDRESS,
    name: 'Unassociated Elastic IP Addresses',
  },
  {
    id: AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES,
    name: 'Underutilized EBS Volumes',
  },
  {
    id: AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
    name: 'Underutilized Redshift Clusters',
  },
  {
    id: AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
    name: 'Overprovisioned EBS volumes',
  },
  {
    id: AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
    name: 'EC2 Instances Consolidation - MSSQL Server',
  },
  {
    id: AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
    name: 'EC2 Instances Overprovisioned - MSSQL Server',
  },
  {
    id: AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS,
    name: 'Route 53 Latency Record Sets',
  },
  {
    id: AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
    name: 'Lambda Functions Excessive Timeout',
  },
  {
    id: AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
    name: 'Lambda Functions High Error Rates',
  },
  {
    id: AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
    name: 'Lambda Functions Overprovisioned Memory',
  },
  {
    id: AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS,
    name: 'Underutilized Endpoints',
  },
  {
    id: AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR,
    name: 'Well-Architected high risk issues for cost optimization',
  },
  {
    id: AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE,
    name: 'EC2 Instances RI Lease Expiration',
  },
  {
    id: AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
    name: 'EC2 Instances RI Optimization',
  },
  {
    id: AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
    name: 'Elastic Cache Reserved Node Optimization',
  },
  {
    id: AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
    name: 'Open Search RI Optimization',
  },
  {
    id: AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
    name: 'Redshift Reserved Node Optimization',
  },
  {
    id: AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
    name: 'RDS RI Optimization',
  },
  {
    id: AWS_CHECK_IDS.SAVINGS_PLAN,
    name: 'Savings Plan',
  },
  {
    id: AWS_CHECK_IDS.RIGHT_SIZING_EC2,
    name: 'Right Sizing EC2',
  },
];

export const AWSRecommendationSummaryColumns = [
  {
    title: i18n.t('awsRecommendationTableLabels.accountId'),
    key: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    dataIndex: 'accountId',
    useDataIndexAsLabel: true,
  },
  {
    title: i18n.t('awsRecommendationTableLabels.accountName'),
    key: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    dataIndex: 'accountName',
    width: '20%',
    checkIds: [
      AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE,
      AWS_CHECK_IDS.IDLE_LOAD_BALANCER,
      AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
      AWS_CHECK_IDS.IDLE_IP_ADDRESS,
      AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES,
      AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
      AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
      AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
      AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
      AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS,
      AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
      AWS_CHECK_IDS.SAVINGS_PLAN,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.status'),
    key: `"${QUERY_FIELDS.STATUS}"`,
    dataIndex: 'status',
    checkIds: [
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
      AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR,
      AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.region'),
    key: `"${QUERY_FIELDS.REGION}"`,
    dataIndex: 'region',
    checkIds: [
      AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS,
      AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
      AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
      AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR,
      AWS_CHECK_IDS.IDLE_LOAD_BALANCER,
      AWS_CHECK_IDS.IDLE_IP_ADDRESS,
      AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES,
      AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
      AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.instanceId'),
    key: `"${QUERY_FIELDS.INSTANCE_ID_NO_SPACE}"`,
    dataIndex: 'instanceId',
    checkIds: [AWS_CHECK_IDS.RIGHT_SIZING_EC2],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.instanceName'),
    key: `"${QUERY_FIELDS.INSTANCE_NAME_NO_SPACE}"`,
    dataIndex: 'instanceName',
    checkIds: [AWS_CHECK_IDS.RIGHT_SIZING_EC2],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.instanceType'),
    key: `"${QUERY_FIELDS.INSTANCE_TYPE_NO_SPACE}"`,
    dataIndex: 'instanceType',
    checkIds: [AWS_CHECK_IDS.RIGHT_SIZING_EC2],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.recommendedInstanceType'),
    key: `"${QUERY_FIELDS.RECOMMENDED_INSTANCE_TYPE_NO_SPACE}"`,
    dataIndex: 'recommendedInstanceType',
    checkIds: [AWS_CHECK_IDS.RIGHT_SIZING_EC2],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.generationTime'),
    key: `"${QUERY_FIELDS.GENERATION_TIME}"`,
    dataIndex: 'generationTime',
    checkIds: [AWS_CHECK_IDS.RIGHT_SIZING_EC2],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.cpuUtilization'),
    key: `"${QUERY_FIELDS.CPU_UTILIZATION}"`,
    dataIndex: 'cpuUtilization',
    checkIds: [AWS_CHECK_IDS.RIGHT_SIZING_EC2],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.estimatedSavings'),
    key: `"${QUERY_FIELDS.ESTIMATED_SAVINGS}"`,
    dataIndex: 'estimatedSavings',
    checkIds: [AWS_CHECK_IDS.RIGHT_SIZING_EC2],
    costField: true,
  },
  {
    title: i18n.t('awsRecommendationTableLabels.cluster'),
    key: `"${QUERY_FIELDS.CLUSTER}"`,
    dataIndex: 'cluster',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.ipAddress'),
    key: `"${QUERY_FIELDS.IP_ADDRESS}"`,
    dataIndex: 'ipAddress',
    checkIds: [AWS_CHECK_IDS.IDLE_IP_ADDRESS],
    align: 'left',
  },
  {
    title: i18n.t('awsRecommendationTableLabels.endpointArn'),
    key: `"${QUERY_FIELDS.ENDPOINT_ARN}"`,
    dataIndex: 'endpointArn',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.provisionedInferenceUnit'),
    key: `"${QUERY_FIELDS.PROVISIONED_INFERENCE_UNIT}"`,
    dataIndex: 'provisionedInferenceUnit',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.autoScalingStatus'),
    key: `"${QUERY_FIELDS.AUTOSCALING_STATUS}"`,
    dataIndex: 'autoScalingStatus',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.loadBalancerName'),
    key: `"${QUERY_FIELDS.LOAD_BALANCER_NAME}"`,
    dataIndex: 'loadBalancerName',
    checkIds: [AWS_CHECK_IDS.IDLE_LOAD_BALANCER],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.reason'),
    key: `"${QUERY_FIELDS.REASON}"`,
    dataIndex: 'reason',
    checkIds: [
      AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE,
      AWS_CHECK_IDS.IDLE_LOAD_BALANCER,
      AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.volumeId'),
    key: `"${QUERY_FIELDS.VOLUME_ID}"`,
    dataIndex: 'volumeId',
    checkIds: [
      AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
      AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.volumeName'),
    key: `"${QUERY_FIELDS.VOLUME_NAME}"`,
    dataIndex: 'volumeName',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.volumeType'),
    key: `"${QUERY_FIELDS.VOLUME_TYPE}"`,
    dataIndex: 'volumeType',
    checkIds: [
      AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
      AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.volumeSize'),
    key: `"${QUERY_FIELDS.VOLUME_SIZE}"`,
    dataIndex: 'volumeSize',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.volumeSizeGb'),
    key: `"${QUERY_FIELDS.VOLUME_SIZE_GB}"`,
    dataIndex: 'volumeSizeGb',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.volumeBaselineIops'),
    key: `"${QUERY_FIELDS.VOLUME_BASELINE_IOPS}"`,
    dataIndex: 'volumeBaselineIops',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.volumeBurstIops'),
    key: `"${QUERY_FIELDS.VOLUME_BURST_IOPS}"`,
    dataIndex: 'volumeBurstIops',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.volumeBurstThroughput'),
    key: `"${QUERY_FIELDS.VOLUME_BURST_THROUGHPUT}"`,
    dataIndex: 'volumeBurstThroughput',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.recommendedVolumeType'),
    key: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_TYPE}"`,
    dataIndex: 'recommendedVolumeType',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.recommendedVolumeSizeGb'),
    key: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_SIZE_GB}"`,
    dataIndex: 'recommendedVolumeSizeGb',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.recommendedVolumeBaselineIops'),
    key: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_BASELINE_IOPS}"`,
    dataIndex: 'recommendedVolumeBaselineIops',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.recommendedVolumeBurstIops'),
    key: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_BURST_IOPS}"`,
    dataIndex: 'recommendedVolumeBurstIops',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.recommendedVolumeBaselineThroughput'
    ),
    key: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_BASELINE_THROUGHPUT}"`,
    dataIndex: 'recommendedVolumeBaselineThroughput',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.recommendedVolumeBurstThroughput'
    ),
    key: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_BURST_THROUGHPUT}"`,
    dataIndex: 'recommendedVolumeBurstThroughput',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.lookbackPeriodDays'),
    key: `"${QUERY_FIELDS.LOOKBACK_PERIOD_DAYS}"`,
    dataIndex: 'lookbackPeriodDays',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.savingsOpportunityPercentage'),
    key: `"${QUERY_FIELDS.SAVINGS_OPPORTUNITY_PERCENTAGE}"`,
    dataIndex: 'savingsOpportunityPercentage',
    checkIds: [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.instanceId'),
    key: `"${QUERY_FIELDS.INSTANCE_ID}"`,
    dataIndex: 'instanceId',
    checkIds: [
      AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
      AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.instanceName'),
    key: `"${QUERY_FIELDS.INSTANCE_NAME}"`,
    dataIndex: 'instanceName',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.instanceType'),
    key: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
    dataIndex: 'instanceType',
    checkIds: [
      AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
      AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
      AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
      AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.vCpu'),
    key: `"${QUERY_FIELDS.VCPU}"`,
    dataIndex: 'vCpu',
    checkIds: [
      AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.minimumVCpu'),
    key: `"${QUERY_FIELDS.MINIMUM_VCPU}"`,
    dataIndex: 'minimumVCpu',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.sqlServerEdition'),
    key: `"${QUERY_FIELDS.SQL_SERVER_EDITION}"`,
    dataIndex: 'sqlServerEdition',
    checkIds: [
      AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.maximumVCpu'),
    key: `"${QUERY_FIELDS.MAXIMUM_VCPU}"`,
    dataIndex: 'maximumVCpu',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.recommendedInstanceType'),
    key: `"${QUERY_FIELDS.RECOMMENDED_INSTANCE_TYPE}"`,
    dataIndex: 'recommendedInstanceType',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.zone'),
    key: `"${QUERY_FIELDS.ZONE}"`,
    dataIndex: 'zone',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.platform'),
    key: `"${QUERY_FIELDS.PLATFORM}"`,
    dataIndex: 'platform',
    checkIds: [
      AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.instanceCount'),
    key: `"${QUERY_FIELDS.INSTANCE_COUNT}"`,
    dataIndex: 'instanceCount',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.currentMonthlyCost'),
    key: `"${QUERY_FIELDS.CURRENT_MONTHLY_COST}"`,
    dataIndex: 'currentMonthlyCost',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE],
  },

  {
    title: i18n.t(
      'awsRecommendationTableLabels.recommendedNumberOfRisToPurchase'
    ),
    key: `"${QUERY_FIELDS.RECOMMENDED_NUMBER_OF_RIS_TO_PURCHASE}"`,
    dataIndex: 'recommendedNumberOfRisToPurchase',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.expectedAverageRiUtilization'),
    key: `"${QUERY_FIELDS.EXPECTED_AVERAGE_RI_UTILIZATION}"`,
    dataIndex: 'expectedAverageRiUtilization',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.estimatedSavingsWithRecommendations'
    ),
    key: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
    dataIndex: 'estimatedSavingsWithRecommendations',
    checkIds: [
      AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.uprontCostOfRis'),
    key: `"${QUERY_FIELDS.UPFRONT_COST_OF_RIS}"`,
    dataIndex: 'uprontCostOfRis',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.estimatedCostOfRis'),
    key: `"${QUERY_FIELDS.ESTIMATED_COST_OF_RIS}"`,
    dataIndex: 'estimatedCostOfRis',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.estimatedOnDemandCostPostRecommendedRiPurchase'
    ),
    key: `"${QUERY_FIELDS.ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RI_PURCHASE}"`,
    dataIndex: 'estimatedOnDemandCostPostRecommendedRiPurchase',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.functionName'),
    key: `"${QUERY_FIELDS.FUNCTION_NAME}"`,
    dataIndex: 'functionName',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.functionVersion'),
    key: `"${QUERY_FIELDS.FUNCTION_VERSION}"`,
    dataIndex: 'functionVersion',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.memorySize'),
    key: `"${QUERY_FIELDS.MEMORY_SIZE}"`,
    dataIndex: 'memorySize',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.recommendedMemorySize'),
    key: `"${QUERY_FIELDS.RECOMMENDED_MEMORY_SIZE}"`,
    dataIndex: 'recommendedMemorySize',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.estimatedBreakEven'),
    key: `"${QUERY_FIELDS.ESTIMATED_BREAK_EVEN}"`,
    dataIndex: 'estimatedBreakEven',
    checkIds: [
      AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.lookbackPeriod'),
    key: `"${QUERY_FIELDS.LOOKBACK_PERIOD}"`,
    dataIndex: 'lookbackPeriod',
    checkIds: [
      AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
      AWS_CHECK_IDS.SAVINGS_PLAN,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.savingsOpportunity'),
    key: `"${QUERY_FIELDS.SAVINGS_OPPORTUNITY}"`,
    dataIndex: 'savingsOpportunity',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.term'),
    key: `"${QUERY_FIELDS.TERM}"`,
    dataIndex: 'term',
    checkIds: [
      AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
      AWS_CHECK_IDS.SAVINGS_PLAN,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.family'),
    key: `"${QUERY_FIELDS.FAMILY}"`,
    dataIndex: 'family',
    checkIds: [
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.nodeType'),
    key: `"${QUERY_FIELDS.NODE_TYPE}"`,
    dataIndex: 'nodeType',
    checkIds: [
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.productDescription'),
    key: `"${QUERY_FIELDS.PRODUCT_DESCRIPTION}"`,
    dataIndex: 'productDescription',
    checkIds: [AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.recommendedNumberOfReservedNodesToPurchase'
    ),
    key: `"${QUERY_FIELDS.RECOMMENDED_NUMBER_OF_RESERVED_NODES_TO_PURCHASE}"`,
    dataIndex: 'recommendedNumberOfReservedNodesToPurchase',
    checkIds: [
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.expectedAverageReservedNodeUtilization'
    ),
    key: `"${QUERY_FIELDS.EXPECTED_AVERAGE_RESERVED_NODE_UTILIZARION}"`,
    dataIndex: 'expectedAverageReservedNodeUtilization',
    checkIds: [
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.upfrontCostOfReservedNodes'),
    key: `"${QUERY_FIELDS.UPFRONT_COST_OF_RESERVED_NODES}"`,
    dataIndex: 'upfrontCostOfReservedNodes',
    checkIds: [
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.estimatedCostOfReservedNodes'),
    key: `"${QUERY_FIELDS.ESTIMATED_COST_OF_RESERVED_NODES}"`,
    dataIndex: 'estimatedCostOfReservedNodes',
    checkIds: [
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.estimatedOnDemandCostPostRecommendedReservedNodesPurchase'
    ),
    key: `"${QUERY_FIELDS.ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVED_NODES_PURCHASE}"`,
    dataIndex: 'estimatedOnDemandCostPostRecommendedReservedNodesPurchase',
    checkIds: [
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.instanceClass'),
    key: `"${QUERY_FIELDS.INSTANCE_CLASS}"`,
    dataIndex: 'instanceClass',
    checkIds: [AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.instanceSize'),
    key: `"${QUERY_FIELDS.INSTANCE_SIZE}"`,
    dataIndex: 'instanceSize',
    checkIds: [AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.recommendedNumberOfReservedInstancesToPurchase'
    ),
    key: `"${QUERY_FIELDS.RECOMMENDED_NUMBER_OF_RESERVED_INSTANCES_TO_PURCHASE}"`,
    dataIndex: 'recommendedNumberOfReservedInstancesToPurchase',
    checkIds: [
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.expectedAverageReservedInstancesToPurchase'
    ),
    key: `"${QUERY_FIELDS.EXPECTED_AVERAGE_RESERVED_INSTANCES_TO_PURCHASE}"`,
    dataIndex: 'expectedAverageReservedInstancesToPurchase',
    checkIds: [
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.upfrontCostOfReservedInstances'
    ),
    key: `"${QUERY_FIELDS.UPFRONT_COST_OF_RESERVED_INSATNCES}"`,
    dataIndex: 'upfrontCostOfReservedInstances',
    checkIds: [
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.estimatedCostOfReservedInstances'
    ),
    key: `"${QUERY_FIELDS.ESTIMATED_COST_OF_RESERVED_INSTANCES}"`,
    dataIndex: 'estimatedCostOfReservedInstances',
    checkIds: [
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
    ],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.estimatedOnDemandCostPostRecommendedReservedInstancePurchase'
    ),
    key: `"${QUERY_FIELDS.ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVED_INSTANCE_PURCHASE}"`,
    dataIndex: 'estimatedOnDemandCostPostRecommendedReservedInstancePurchase',
    checkIds: [AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.dbInstanceName'),
    key: `"${QUERY_FIELDS.DB_INSTANCE_NAME}"`,
    dataIndex: 'dbInstanceName',
    checkIds: [AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.multiAz'),
    key: `"${QUERY_FIELDS.MULTI_AZ}"`,
    dataIndex: 'multiAz',
    checkIds: [AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.storageProvisioned'),
    key: `"${QUERY_FIELDS.STORAGE_PROVISIONED}"`,
    dataIndex: 'storageProvisioned',
    checkIds: [AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.daysSinceLastConnection'),
    key: `"${QUERY_FIELDS.DAYS_SINCE_LAST_CONNECTION}"`,
    dataIndex: 'daysSinceLastConnection',
    checkIds: [AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.licenceModel'),
    key: `"${QUERY_FIELDS.LICENCE_MODEL}"`,
    dataIndex: 'licenceModel',
    checkIds: [AWS_CHECK_IDS.RDS_RI_OPTIMIZATION],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.databaseEdition'),
    key: `"${QUERY_FIELDS.DATABASE_EDITION}"`,
    dataIndex: 'databaseEdition',
    checkIds: [AWS_CHECK_IDS.RDS_RI_OPTIMIZATION],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.databaseEngine'),
    key: `"${QUERY_FIELDS.DATABASE_ENGINE}"`,
    dataIndex: 'databaseEngine',
    checkIds: [AWS_CHECK_IDS.RDS_RI_OPTIMIZATION],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.deploymentOption'),
    key: `"${QUERY_FIELDS.DEPLOYMENT_OPTION}"`,
    dataIndex: 'deploymentOption',
    checkIds: [AWS_CHECK_IDS.RDS_RI_OPTIMIZATION],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.estimatedOnDemandCostPostRecommendedReserveInstancePurchaseMonthly'
    ),
    key: `"${QUERY_FIELDS.ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVE_INSTANCE_PURCHASE_MONTHLY}"`,
    dataIndex:
      'estimatedOnDemandCostPostRecommendedReserveInstancePurchaseMonthly',
    checkIds: [AWS_CHECK_IDS.RDS_RI_OPTIMIZATION],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.hostedZoneName'),
    key: `"${QUERY_FIELDS.HOSTED_ZONE_NAME}"`,
    dataIndex: 'hostedZoneName',
    checkIds: [AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.hostedZoneId'),
    key: `"${QUERY_FIELDS.HOSTED_ZONE_ID}"`,
    dataIndex: 'hostedZoneId',
    checkIds: [AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.resourceRecordSetName'),
    key: `"${QUERY_FIELDS.RESOURCE_RECORD_SET_NAME}"`,
    dataIndex: 'resourceRecordSetName',
    checkIds: [AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.resourceRecordSetType'),
    key: `"${QUERY_FIELDS.RESOURCE_RECORD_SET_TYPE}"`,
    dataIndex: 'resourceRecordSetType',
    checkIds: [AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.functionArn'),
    key: `"${QUERY_FIELDS.FUNCTION_ARN}"`,
    dataIndex: 'functionArn',
    checkIds: [
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.maxDailyTimeoutRate'),
    key: `"${QUERY_FIELDS.MAX_DAILY_TIMEOUT_RATE}"`,
    dataIndex: 'maxDailyTimeoutRate',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.dateOfMaxDailyTimeoutRate'),
    key: `"${QUERY_FIELDS.DATE_OF_MAX_DAILY_TIMEOUT_RATE}"`,
    dataIndex: 'dateOfMaxDailyTimeoutRate',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.averageDailyTimeoutRate'),
    key: `"${QUERY_FIELDS.AVERAGE_DAILY_TIMEOUT_RATE}"`,
    dataIndex: 'averageDailyTimeoutRate',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.functionTimeoutSettings'),
    key: `"${QUERY_FIELDS.FUNCTION_TIMEOUT_SETTINGS}"`,
    dataIndex: 'functionTimeoutSettings',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.maxDailyErrorRate'),
    key: `"${QUERY_FIELDS.MAX_DAILY_ERROR_RATE}"`,
    dataIndex: 'maxDailyErrorRate',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.dateForMaxErrorRate'),
    key: `"${QUERY_FIELDS.DATE_FOR_MAX_ERROR_RATE}"`,
    dataIndex: 'dateForMaxErrorRate',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.averageDailyErrorRate'),
    key: `"${QUERY_FIELDS.AVERAGE_DAILY_ERROR_RATE}"`,
    dataIndex: 'averageDailyErrorRate',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.lostDailyComputeCost'),
    key: `"${QUERY_FIELDS.LOST_DAILY_COMPUTE_COST}"`,
    dataIndex: 'lostDailyComputeCost',
    checkIds: [
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.averageDailyInvokes'),
    key: `"${QUERY_FIELDS.AVERAGE_DAILY_INVOKES}"`,
    dataIndex: 'averageDailyInvokes',
    checkIds: [
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.currentDayInvokes'),
    key: `"${QUERY_FIELDS.CURRENT_DAY_INVOKES}"`,
    dataIndex: 'currentDayInvokes',
    checkIds: [
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.currentDayErrorRate'),
    key: `"${QUERY_FIELDS.CURRENT_DAY_ERROR_RATE}"`,
    dataIndex: 'currentDayErrorRate',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.currentDayTimeoutRate'),
    key: `"${QUERY_FIELDS.CURRENT_DAY_TIMEOUT_RATE}"`,
    dataIndex: 'currentDayTimeoutRate',
    checkIds: [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.savingsPlanType'),
    key: `"${QUERY_FIELDS.SAVINGS_PLAN_TYPE}"`,
    dataIndex: 'savingsPlanType',
    checkIds: [AWS_CHECK_IDS.SAVINGS_PLAN],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.paymentOption'),
    key: `"${QUERY_FIELDS.PAYMENT_OPTION}"`,
    dataIndex: 'paymentOption',
    checkIds: [AWS_CHECK_IDS.SAVINGS_PLAN],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.upfrontCost'),
    key: `"${QUERY_FIELDS.UPFRONT_COST}"`,
    dataIndex: 'upfrontCost',
    checkIds: [AWS_CHECK_IDS.SAVINGS_PLAN],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.hourlyCommitmentToPurchase'),
    key: `"${QUERY_FIELDS.HOURLY_COMMITMENT_TO_PURCHASE}"`,
    dataIndex: 'hourlyCommitmentToPurchase',
    checkIds: [AWS_CHECK_IDS.SAVINGS_PLAN],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.estimatedAverageUtilization'),
    key: `"${QUERY_FIELDS.ESTIMATED_AVERAGE_UTILIZATION}"`,
    dataIndex: 'estimatedAverageUtilization',
    checkIds: [AWS_CHECK_IDS.SAVINGS_PLAN],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.snapshotId'),
    key: `"${QUERY_FIELDS.SNAPSHOT_ID}"`,
    dataIndex: 'snapshotId',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.snapshotName'),
    key: `"${QUERY_FIELDS.SNAPSHOT_NAME}"`,
    dataIndex: 'snapshotName',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.snapshotAge'),
    key: `"${QUERY_FIELDS.SNAPSHOT_AGE}"`,
    dataIndex: 'snapshotAge',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.monthlyStorageCost'),
    key: `"${QUERY_FIELDS.MONTHLY_STORAGE_COST}"`,
    dataIndex: 'monthlyStorageCost',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES],
    costField: true,
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.cpuUtilizationFourteenDayAverage'
    ),
    key: `"${QUERY_FIELDS.FOURTEEN_DAYS_AVERAGE_CPU_UTILIZATION}"`,
    dataIndex: 'cpuUtilizationFourteenDayAverage',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.networkIoFourteenDayAverage'),
    key: `"${QUERY_FIELDS.FOURTEEN_DAYS_AVERAGE_NETWORK_IO}"`,
    dataIndex: 'networkIoFourteenDayAverage',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.numberOfDaysLowUtilization'),
    key: `"${QUERY_FIELDS.NUMBER_OF_DAYS_LOW_UTILIZATION}"`,
    dataIndex: 'numberOfDaysLowUtilization',
    checkIds: [AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.lastRefreshedAt'),
    key: `"${QUERY_FIELDS.DATETIME}"`,
    dataIndex: 'lastRefreshedAt',
    checkIds: [
      AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE,
      AWS_CHECK_IDS.IDLE_LOAD_BALANCER,
      AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
      AWS_CHECK_IDS.IDLE_IP_ADDRESS,
      AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES,
      AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
      AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
      AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
      AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
      AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS,
      AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION,
      AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION,
      AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION,
      AWS_CHECK_IDS.RDS_RI_OPTIMIZATION,
      AWS_CHECK_IDS.SAVINGS_PLAN,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.estimatedMonthlySavings'),
    key: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    dataIndex: 'estimatedMonthlySavings',
    checkIds: [
      AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
      AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE,
      AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
      AWS_CHECK_IDS.IDLE_LOAD_BALANCER,
      AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
      AWS_CHECK_IDS.SAVINGS_PLAN,
      AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
    ],
    costField: true,
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.estimatedMonthlySavingsCurrency'
    ),
    key: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS_CURRENCY}"`,
    dataIndex: 'estimatedMonthlySavingsCurrency',
    checkIds: [
      AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.estimatedSavingsPercentage'),
    key: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_PERCENTAGE}"`,
    dataIndex: 'estimatedSavingsPercentage',
    checkIds: [AWS_CHECK_IDS.SAVINGS_PLAN],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.expirationDate'),
    key: `"${QUERY_FIELDS.EXPIRATION_DATE}"`,
    dataIndex: 'expirationDate',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.reservedInstanceId'),
    key: `"${QUERY_FIELDS.RESERVED_INSTANCE_ID}"`,
    dataIndex: 'reservedInstanceId',
    checkIds: [AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.workloadArn'),
    key: `"${QUERY_FIELDS.WORKLOAD_ARN}"`,
    dataIndex: 'workloadArn',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.workloadName'),
    key: `"${QUERY_FIELDS.WORKLOAD_NAME}"`,
    dataIndex: 'workloadName',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.reviewerName'),
    key: `"${QUERY_FIELDS.REVIEWER_NAME}"`,
    dataIndex: 'reviewerName',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.workloadType'),
    key: `"${QUERY_FIELDS.WORKLOAD_TYPE}"`,
    dataIndex: 'workloadType',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.workloadStartedDate'),
    key: `"${QUERY_FIELDS.WORKLOAD_STARTED_DATE}"`,
    dataIndex: 'workloadStartedDate',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.workloadLastModifiedDate'),
    key: `"${QUERY_FIELDS.WORKLOAD_LAST_MODIFIED_DATE}"`,
    dataIndex: 'workloadLastModifiedDate',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.numberOfIdentifiedHrisForCostOptimization'
    ),
    key: `"${QUERY_FIELDS.NUMBER_OF_IDENTIFIED_HRIS_FOR_COST_OPTIMIZATION}"`,
    dataIndex: 'numberOfIdentifiedHrisForCostOptimization',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.numberOfHrisResolvedForCostOptimization'
    ),
    key: `"${QUERY_FIELDS.NUMBER_OF_HRIS_RESOLVED_FOR_COST_OPTIMIZATION}"`,
    dataIndex: 'numberOfHrisResolvedForCostOptimization',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.numberOfQuestionsAnsweredForCostOptimization'
    ),
    key: `"${QUERY_FIELDS.NUMBER_OF_QUESTIONS_ANSWERED_FOR_COST_OPTIMIZATION}"`,
    dataIndex: 'numberOfQuestionsAnsweredForCostOptimization',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t(
      'awsRecommendationTableLabels.totalNumberOfQuestionsInCostOptimizationPillar'
    ),
    key: `"${QUERY_FIELDS.TOTAL_NUMBER_OF_QUESTIONS_IN_COST_OPTIMIZATION_PILLAR}"`,
    dataIndex: 'totalNumberOfQuestionsInCostOptimizationPillar',
    checkIds: [AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.lastUpdatedTime'),
    key: `"${QUERY_FIELDS.LAST_UPDATED_TIME}"`,
    dataIndex: 'lastUpdatedTime',
    checkIds: [
      AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS,
      AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES,
      AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER,
      AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES,
      AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY,
      AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR,
    ],
  },
  {
    title: i18n.t('awsRecommendationTableLabels.costSavings'),
    key: 'costSavings',
    dataIndex: 'costSavings',
    checkIds: [AWS_CHECK_IDS.IDLE_IP_ADDRESS],
    costField: true,
  },
];

export const GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES = {
  IDLE_INSTANCE: 'google.compute.instance.IdleResourceRecommender',
  IDLE_DISKS: 'google.compute.disk.IdleResourceRecommender',
  IDLE_IP_ADDRESSES: 'google.compute.address.IdleResourceRecommender',
  IDLE_IMAGES: 'google.compute.image.IdleResourceRecommender',
  IDLE_CLOUD_SQL_INSTANCES: 'google.cloudsql.instance.IdleRecommender',
  OVERPROVISIONED_CLOUD_SQL_INSTANCES:
    'google.cloudsql.instance.OverprovisionedRecommender',
  PROJECT_UTILIZATION: 'google.resourcemanager.projectUtilization.Recommender',
};

export const GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES = {
  MACHINE_TYPE_INSTANCE: 'google.compute.instance.MachineTypeRecommender',
  MACHINE_TYPE_INSTANCE_GROUP_MANAGER:
    'google.compute.instanceGroupManager.MachineTypeRecommender',
};

export const GCP_PURCHASE_TACTICS_RECOMMENDATION_TYPES = {
  CAPACITY_COMMITMENTS: 'google.bigquery.capacityCommitments.Recommender',
  USAGE_COMMITMENTS: 'google.compute.commitment.UsageCommitmentRecommender',
  SPEND_BASED_COMMITMENTS:
    'google.cloudbilling.commitment.SpendBasedCommitmentRecommender',
};
