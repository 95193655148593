import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import SearchInput from 'components/Input';
import Empty from 'components/Empty';
import { RecommendationFiltersType } from 'pages/CostOptimizationInsightsPage/types';
import { ValueLabelType } from 'types/dashboard';
import Checkbox from 'components/Checkbox';

import './index.scss';

type FilterValuesListProps = {
  filter: RecommendationFiltersType;
  selectedValues: ValueLabelType[];
  onChangeFilterValues: (
    filter: RecommendationFiltersType,
    values: ValueLabelType[] | null
  ) => void;
};

const FilterValuesList = ({
  filter,
  selectedValues,
  onChangeFilterValues,
}: FilterValuesListProps) => {
  const { t } = useTranslation();

  const [values, setValues] = useState<ValueLabelType[]>([]);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    setValues(
      filter.values?.filter((valueLabel) =>
        (valueLabel.label ?? valueLabel.value)
          .toLowerCase()
          .includes(searchKey.toLowerCase())
      ) ?? []
    );
  }, [filter, searchKey]);

  /**
   * @function isChecked
   * @description Function to return if the checkbox is checked or not
   * @param value value to be validated for
   * @returns boolean true if checked else false
   */
  const isChecked = (value: string) => {
    return selectedValues.some((item) => item.value === value);
  };

  /**
   * @function onSelectAll
   * @description Callback function for selecting All checkbox
   * @param e Event for checkbox onChange
   */
  const onSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      onChangeFilterValues(filter, filter.values);
      return;
    }

    onChangeFilterValues(filter, []);
  };

  /**
   * @function onSelectCheckbox
   * @description Callback function for selecting each checkbox
   * @param e Event for checkbox onChange
   * @param valueUpdated value to be added or removed
   */
  const onSelectCheckbox = (
    e: CheckboxChangeEvent,
    valueUpdated: ValueLabelType
  ) => {
    if (e.target.checked) {
      onChangeFilterValues(filter, [...selectedValues, valueUpdated]);
      return;
    }

    onChangeFilterValues(
      filter,
      selectedValues.filter(
        (eachValue) => eachValue.value !== valueUpdated.value
      )
    );
  };

  // Displays the empty values message if there is no value present for the filter
  if (!filter.values || filter.values.length === 0) {
    return <Empty description={t('noFilterValues')} />;
  }

  return (
    <div className="filter-values-list flex flex-column flex-gap-16">
      <SearchInput
        className="full-width values-search"
        value={searchKey}
        placeholder={t('dashboardLabels.tagsLabels.searchByValue')}
        type="search"
        onChange={(e: any) => setSearchKey(e.target.value)}
      />
      <div className="flex flex-column flex-gap-16">
        {!searchKey && values.length > 1 && (
          <Checkbox
            value={'ALL'}
            key={'ALL'}
            checked={selectedValues.length === filter.values!.length}
            onChange={onSelectAll}
          >
            {t('dashboardLabels.tagsLabels.all')}
          </Checkbox>
        )}
        {values.map((item) => (
          <Checkbox
            value={item.value}
            key={item.value}
            checked={isChecked(item.value)}
            onChange={(e: CheckboxChangeEvent) => onSelectCheckbox(e, item)}
          >
            {/* Shows value if the label is not present */}
            {item.label ?? item.value}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export default FilterValuesList;
