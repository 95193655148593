import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RangeValue } from 'rc-picker/lib/interface';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { REQUEST_STATUS } from 'constants/requestBody';
import ColumnChart from 'components/ColumnChart';
import GraphBreadcrumb from 'components/GraphBreadcrumb';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import DashboardComponent from 'components/DashboardComponent';
import { selectDashboard } from 'redux/dashboardSlice';
import GraphHeader from 'components/GraphHeader';
import ColumnLineChart from 'components/ColumnLineChart';
import AccessibleDiv from 'components/AccessibleDiv';
import {
  ColouredCostByResourceType,
  ColouredCostByProviderServiceType,
  MonthlyCostType,
} from 'types/dataTypes';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import {
  getCostTrendsData,
  getCostTrendsExportColumns,
  getCostTrendsHeading,
} from '../../utils/costTrendUtils';
import { getGroupsCostTrendExcelData } from '../../utils/exportToExcel';

type ConsolidatedCostTrendProps = {
  monthlyCost: MonthlyCostType[];
  costTrendRequestStatus: string;
  setIsCostTrendTableView: (value: boolean) => void;
  isCostTrendTableView: boolean;
  pdfView: boolean;
  costTrendStartMonth: string;
  costTrendEndMonth: string;
  onChangeCostTrendDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  selectedTrendMonth: string;
  setSelectedTrendMonth: (val: string) => void;
  selectedTrendService: {
    provider: string;
    service: string;
  };
  setSelectedTrendService: (val: { provider: string; service: string }) => void;
  serviceData: ColouredCostByProviderServiceType[];
  resourceData: ColouredCostByResourceType[];
  sliderValue?: { x: number; y: number };
  setSliderValue: (value: { x: number; y: number } | undefined) => void;
};

const ConsolidatedCostTrend = ({
  monthlyCost,
  costTrendRequestStatus,
  setIsCostTrendTableView,
  isCostTrendTableView,
  pdfView,
  costTrendStartMonth,
  costTrendEndMonth,
  onChangeCostTrendDateRange,
  selectedTrendMonth,
  setSelectedTrendMonth,
  selectedTrendService,
  setSelectedTrendService,
  serviceData,
  resourceData,
  sliderValue,
  setSliderValue,
}: ConsolidatedCostTrendProps) => {
  const { t } = useTranslation();
  const { selectedGroupMetaData } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [breadcrumbPaths, setBreadcrumbPaths] = useState<string[]>([]);

  useEffect(() => {
    setBreadcrumbPathsByGraph();
  }, [selectedTrendMonth, selectedTrendService.service]);

  const setBreadcrumbPathsByGraph = () => {
    if (!selectedTrendMonth) {
      setBreadcrumbPaths([]);
      return;
    }

    if (!selectedTrendService.service) {
      setBreadcrumbPaths([
        t('graphHeadings.costTrend') + ' - ' + selectedTrendMonth,
        t('graphHeadings.costByService'),
      ]);
      return;
    }

    setBreadcrumbPaths([
      t('graphHeadings.costTrend') + ' - ' + selectedTrendMonth,
      t('graphHeadings.costByService') + ' - ' + selectedTrendService.service,
      t('graphHeadings.costByResources'),
    ]);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 40,
    },
    {
      title: t('costTrend.month'),
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: t('costTrend.costInCurrency', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'cost',
      key: 'cost',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  const getTableColumns = () => {
    let tableColumns: any[] = columns;

    if (!selectedTrendMonth) {
      return columns;
    }

    if (!selectedTrendService.service) {
      tableColumns.splice(
        1,
        1,
        {
          title: t('carbonFootprint.carbonEmissionByServices.services'),
          dataIndex: 'service',
          key: 'service',
          width: 170,
        },
        {
          title: t('consolidatedYtdCost.provider'),
          dataIndex: 'provider',
          key: 'provider',
          width: 70,
        }
      );
    }

    if (selectedTrendService.service) {
      tableColumns.splice(1, 1, {
        title: t('costByResource.resources'),
        dataIndex: 'resource',
        key: 'resource',
      });
    }

    return tableColumns;
  };

  const filters = (
    <div>
      <DatePicker
        defaultValue={[moment(costTrendStartMonth), moment(costTrendEndMonth)]}
        onChange={onChangeCostTrendDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        designVersion2
      />
    </div>
  );

  const getGraphComponent = () => {
    if (!selectedTrendMonth)
      return (
        <ColumnLineChart
          data={monthlyCost.map((value) => ({
            ...value,
            type: t('costTrend.costWithoutSuffix'),
          }))}
          xField="month"
          yField="cost"
          groupingField="type"
          xTitle={t('costTrend.month')}
          yTitle={t('costTrend.costInCurrency', {
            currencySymbol: currencySymbol,
          })}
          disableAnimation={pdfView}
          prefixSymbol={currencySymbol}
          additionalTooltipContent={
            (!selectedTrendMonth || !selectedTrendService.service) && (
              <span className="tooltip-deep-dive-label font-subHeader-small">
                {t('clickDeepDive')}
              </span>
            )
          }
          onClickColumn={(e: any) =>
            setSelectedTrendMonth(e?.data?.data?.month)
          }
          isColumnClickable
        />
      );

    if (!selectedTrendService.service)
      return (
        <ColumnChart
          data={serviceData?.map((item) => ({
            ...item,
            groupingField: item.service,
          }))}
          xField="service"
          yField="cost"
          groupingField="groupingField"
          xTitle={t('carbonFootprint.carbonEmissionByServices.services')}
          yTitle={t('costTrend.costInCurrency', {
            currencySymbol: currencySymbol,
          })}
          showAllLegend={pdfView}
          prefixSymbol={currencySymbol}
          disableAnimation={pdfView}
          columnColorOverride={serviceData.map((item: any) => item.color)}
          additionalClassNames={`${pdfView && 'pdf-wrapper'}`}
          additionalTooltipContent={
            <span className="tooltip-deep-dive-label font-subHeader-small">
              {t('clickDeepDive')}
            </span>
          }
          onClickColumn={(e: any) =>
            setSelectedTrendService({
              provider: e?.data?.data?.provider,
              service: e?.data?.data?.service,
            })
          }
          showSlider={true}
          key="service"
          isColumnClickable
          sliderValues={pdfView ? sliderValue : undefined}
          onSliderValueChange={(x: number, y: number) => {
            setSliderValue({ x, y });
          }}
          showLegend={!pdfView}
        />
      );

    return (
      <ColumnChart
        data={resourceData?.map((item) => ({
          ...item,
          serviceGroupingField: item.resource,
        }))}
        xField="resource"
        yField="cost"
        groupingField="serviceGroupingField"
        xTitle={t('costByResource.resources')}
        yTitle={t('costTrend.costInCurrency', {
          currencySymbol: currencySymbol,
        })}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
        disableAnimation={pdfView}
        columnColorOverride={resourceData.map((item: any) => item.color)}
        additionalClassNames={`${pdfView && 'pdf-wrapper'}`}
        showLegend={!pdfView}
        showSlider={true}
        sliderValues={pdfView ? sliderValue : undefined}
        onSliderValueChange={(x: number, y: number) => {
          setSliderValue({ x, y });
        }}
        key="resource"
      />
    );
  };

  const getComponent = () => {
    return (
      <div className={`graph ${isCostTrendTableView && 'flex flex-gap-16'}`}>
        <div
          className={`graph-area ${
            isCostTrendTableView && 'with-table expand-hide'
          }`}
        >
          {getGraphComponent()}
        </div>
        {isCostTrendTableView && (
          <div className="tabular-view width-30">
            <Table
              pagination={false}
              loading={costTrendRequestStatus.includes(
                REQUEST_STATUS.PROCESSING
              )}
              dataSource={getCostTrendsData(
                selectedTrendMonth,
                selectedTrendService.service,
                {
                  trends: monthlyCost,
                  service: serviceData,
                  resource: resourceData,
                }
              )?.map((value, index) => {
                return { ...value, key: index };
              })}
              columns={getTableColumns()}
              scroll={{ y: 200 }}
              designVersion2
            />
          </div>
        )}
      </div>
    );
  };

  const getHeadingComponent = () => {
    if (!selectedTrendMonth) {
      return (
        <div className="graph-heading flex flex-column">
          <span>{t('graphHeadings.costTrend')}</span>
          {!isCostTrendTableView && !pdfView && (
            <span className="sub-title font-caption-bold">
              {t('clickBarInfo')}
            </span>
          )}
        </div>
      );
    }

    if (!selectedTrendService.service) {
      return (
        <div className="graph-heading flex flex-column">
          <AccessibleDiv
            className="flex flex-align-items-center cursor-pointer"
            onClick={() => setSelectedTrendMonth('')}
          >
            <Icon
              iconName={ICONS.ARROW_LEFT_S_LINE}
              className="previous-icon flex flex-end"
            />
            <span>{t('graphHeadings.costByService')}</span>
          </AccessibleDiv>
          <GraphBreadcrumb pathItems={breadcrumbPaths} />
        </div>
      );
    }

    return (
      <div className="graph-heading flex flex-column">
        <AccessibleDiv
          className="flex flex-align-items-center cursor-pointer"
          onClick={() =>
            setSelectedTrendService({
              provider: '',
              service: '',
            })
          }
        >
          <Icon
            iconName={ICONS.ARROW_LEFT_S_LINE}
            className="previous-icon flex flex-end"
          />
          <span>{t('graphHeadings.costByResources')}</span>
        </AccessibleDiv>
        <GraphBreadcrumb pathItems={breadcrumbPaths} />
      </div>
    );
  };

  return (
    <div className="graph-card cost-trend" id="graph-container">
      <GraphHeader
        heading={getCostTrendsHeading(
          selectedTrendMonth,
          selectedTrendService.service
        )}
        headingComponent={getHeadingComponent()}
        graphName="cost-trend"
        filters={!selectedTrendMonth ? filters : undefined}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostTrendTableView}
        setIsTableView={setIsCostTrendTableView}
        excelData={getGroupsCostTrendExcelData(
          selectedGroupMetaData?.name ?? '',
          getCostTrendsData(selectedTrendMonth, selectedTrendService.service, {
            trends: monthlyCost,
            service: serviceData,
            resource: resourceData,
          }) ?? [],
          getCostTrendsExportColumns(
            selectedTrendMonth,
            selectedTrendService.service
          ),
          {
            startDate: costTrendStartMonth,
            endDate: costTrendEndMonth,
            selectedTrendMonth: selectedTrendMonth,
            selectedTrendService: selectedTrendService.service,
          }
        )}
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray([costTrendRequestStatus])}
      />
    </div>
  );
};

export default ConsolidatedCostTrend;
