import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { Routes, BrowserRouter, Route } from 'react-router-dom';

import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import LoginRoute from 'pages/LoginPage';
import HealthReadinessPage from 'pages/HealthReadinessPage';
import { selectTheme } from 'redux/themeSlice';

import './i18n';

function App() {
  const { theme } = useSelector(selectTheme);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.primaryColor,
        },
      }}
      csp={{ nonce: 'YmhkY2JoZGJjaGJkY2RiY2prYmFkY2JkYWNkYmNkYw==' }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path={NAVIGATION_MENU_PATH.HEALTH_READINESS}
            element={<HealthReadinessPage />}
          />
          <Route path="*" element={<LoginRoute />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
