import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import {
  selectDashboard,
  setPdfGraphToDownload,
  setPdfDownloadMode,
  setTableViewEnabled,
} from 'redux/dashboardSlice';
import {
  selectCostAllocationDashboard,
  setCostAllocationDashboardList,
  setSelectedCostAllocationDashboard,
} from 'redux/costAllocationDashboardSlice';
import Switch from 'components/Switch';
import { PDF_ALL_GRAPHS } from 'constants/pdfConstants';
import { LoadingIcon } from 'assets/icons';
import { DASHBOARD_STATUS } from 'pages/DashboardsPage/constants';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DashboardListType } from 'types/navigationMenu';
import { fetchDashboardList } from 'utils/services';
import { exportToExcel } from 'utils/exportToExcel';
import { REFRESH_INTERVAL } from 'constants/defaultValues';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import { selectTheme } from 'redux/themeSlice';
import DashboardListDropdown from '../DashboardListDropdown';
import DashboardTitle from '../DashboardTitle';
import { COST_ALLOCATION_DASHBOARD_NAVIGATION } from '../HorizontalNavigation/constants';

import './index.scss';

type DashboardHeaderProps = {
  setIsEmptyDashboard: (val: boolean) => void;
  fetchDashboardsRequestStatus: string;
  setFetchDashboardsRequestStatus: (val: string) => void;
};

const DashboardHeader = ({
  setIsEmptyDashboard,
  fetchDashboardsRequestStatus,
  setFetchDashboardsRequestStatus,
}: DashboardHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);
  const { exportToExcelData, tableViewEnabled, pdfDownloadMode } =
    useSelector(selectDashboard);
  const {
    selectedCostAllocationDashboard,
    costAllocationDashboardList,
    selectedCostAllocationView,
  } = useSelector(selectCostAllocationDashboard);
  const costAllocationDashboardListRef = useRef(costAllocationDashboardList);

  const [isDownloadingData, setIsDownloadingData] = useState(false);

  useEffect(() => {
    fetchCostAllocationDashboards();

    const interval = setInterval(
      () => fetchCostAllocationDashboards(),
      REFRESH_INTERVAL
    );
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  useEffect(() => {
    costAllocationDashboardListRef.current = costAllocationDashboardList;

    if (costAllocationDashboardList.length === 0) {
      return;
    }

    if (
      !selectedCostAllocationDashboard ||
      costAllocationDashboardList.filter(
        (availableDashboard) =>
          availableDashboard.id === selectedCostAllocationDashboard.id
      ).length === 0
    ) {
      dispatch(
        setSelectedCostAllocationDashboard(costAllocationDashboardList[0])
      );
    }
  }, [costAllocationDashboardList]);

  /**
   * @function fetchCostAllocationDashboards
   * @description Function to fetch the cost allocation dashboards data
   */
  const fetchCostAllocationDashboards = () => {
    fetchDashboardList()
      .then((res: any) => {
        setFetchDashboardsRequestStatus(REQUEST_STATUS.SUCCESS);
        const dashboards: DashboardListType[] =
          res?.data?.responseData?.content;
        const filteredCostAllocationDashboards = dashboards.filter(
          (value) =>
            value.dashboardStatus !== DASHBOARD_STATUS.DE_ACTIVATED &&
            value.dashboardStatus !== DASHBOARD_STATUS.DRAFT &&
            value.dashBoardType === MY_DASHBOARD_TYPES.COST_ALLOCATION
        );
        if (
          !isEqual(
            costAllocationDashboardListRef.current,
            filteredCostAllocationDashboards
          )
        ) {
          dispatch(
            setCostAllocationDashboardList(filteredCostAllocationDashboards)
          );
        }
        setIsEmptyDashboard(filteredCostAllocationDashboards.length === 0);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchDashboardsRequestStatus);
        dispatch(setCostAllocationDashboardList([]));
        setIsEmptyDashboard(true);
      });
  };

  /**
   * @function getDashboardNavMenuTitle
   * @description Function to get selected view title
   * @returns string name of the selected view
   */
  const getDashboardNavMenuTitle = () => {
    const viewName = COST_ALLOCATION_DASHBOARD_NAVIGATION.find(
      (view) => view.id === selectedCostAllocationView
    )?.title;

    return t(`dashNav.${viewName}`);
  };

  /**
   * @function onHandleDownloadDashboardData
   * @description function to handle common download of dashboard view. Will download pdf dashboard view is static except for cost optimization summary other wise will download data as excel
   * @returns either pdf or excel
   */
  const onHandleDownloadDashboardData = () => {
    setIsDownloadingData(true);
    if (!tableViewEnabled) {
      dispatch(setPdfGraphToDownload(PDF_ALL_GRAPHS));
      dispatch(setPdfDownloadMode(true));
      return;
    }
    setIsDownloadingData(false);
    return exportToExcel(
      selectedCostAllocationDashboard?.name + ' ' + getDashboardNavMenuTitle(),
      exportToExcelData
    );
  };

  return (
    <div className="cost-allocation-header flex flex-align-items-center flex-space-between flex-wrap">
      {selectedCostAllocationDashboard ? (
        <DashboardTitle fetchDashboards={fetchCostAllocationDashboards} />
      ) : (
        <Icon icon={LoadingIcon} className="rotate" />
      )}
      <div className="header-right flex flex-align-items-center flex-gap-16">
        {isDownloadingData ? (
          <Icon icon={LoadingIcon} className="rotate" />
        ) : (
          <Icon
            iconName={ICONS.DOWNLOAD_2_LINE}
            onClick={onHandleDownloadDashboardData}
            size={ICONS_SIZE.ONE_X}
            color={theme.buttonIconColor}
          />
        )}
        <div className="table-view-title table-typography flex flex-align-items-center flex-gap-8">
          {t('tableView')}
          <Switch
            size="small"
            checked={tableViewEnabled}
            onChange={(value: boolean) => {
              dispatch(setTableViewEnabled(value));
            }}
          />
        </div>
        <DashboardListDropdown
          selectedDashboard={selectedCostAllocationDashboard}
          onSelectDropdown={(key: string) =>
            dispatch(
              setSelectedCostAllocationDashboard(
                costAllocationDashboardList.find(
                  (dashboard) => dashboard.id === key
                ) ?? null
              )
            )
          }
          loadingDropdown={
            fetchDashboardsRequestStatus === REQUEST_STATUS.PROCESSING
          }
        />
      </div>
    </div>
  );
};

export default DashboardHeader;
