import { useState } from 'react';
import { Checkbox, Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';

import {
  customDashboard,
  setSelectedMetrics,
} from 'redux/customDashboardSlice';
import Icon from 'components/Icon';
import Empty from 'components/Empty';
import Tooltip from 'components/Tooltip';
import Input from 'components/Input';
import { ICONS } from 'constants/icons';
import { FieldSource, FIELD_SOURCE_LABELS } from 'constants/dashboard';
import { INPUT_SIZE } from 'constants/appearance';
import { AvailableFieldsType, ColumnType } from 'types/dashboard';

import {
  dispatchUpdatedCustomViewData,
  doesDataSourceSupportBillingMapping,
} from 'pages/CustomDashboardPage/utils';

import MetricsList from '../MetricsList';
import './index.scss';

const { Panel } = Collapse;

const Metrics = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { customViewMetrics, selectedMetrics } = useSelector(customDashboard);

  const [searchKey, setSearchKey] = useState('');
  const [showMetricsDropdown, setShowMetricsDropdown] = useState(false);

  const onChangeMetricsCheckbox = (
    e: CheckboxChangeEvent,
    metric: string,
    metricField: string,
    source?: FieldSource
  ) => {
    let selectedValues: ColumnType[];

    if (e.target.checked) {
      selectedValues = [
        ...selectedMetrics,
        { label: metric, field: metricField, dimensionType: source },
      ];
    } else {
      selectedValues = selectedMetrics.filter(
        (item) =>
          item.label !== metric ||
          item.field !== metricField ||
          item.dimensionType !== source
      );
    }

    dispatch(setSelectedMetrics(selectedValues));
  };

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="collapse-arrow" />
    );

  /**
   * @function getSelectedMetricsCount
   * @description get selected metrics count
   * @param source the field source
   * @returns length of selected metrics for the given source
   */
  const getSelectedMetricsCount = (source: string) =>
    customViewMetrics.filter(
      (fieldItem) =>
        fieldItem.name.toLowerCase().includes(searchKey.toLowerCase()) &&
        fieldItem.source === source &&
        selectedMetrics.map((metric) => metric.field).includes(fieldItem.name)
    ).length;

  /**
   * @function getMetricsListComponent
   * @description get metrics list component for the given fields
   * @param availableFields the available fields
   * @param source the field source
   * @returns JSX element
   */
  const getMetricsListComponent = (
    availableFields: AvailableFieldsType[],
    source?: FieldSource
  ) =>
    availableFields.length ? (
      availableFields.map((fieldItem) => (
        <Checkbox
          key={fieldItem.name}
          checked={selectedMetrics.some((metric) =>
            metric.dimensionType
              ? metric.label === fieldItem.name
              : metric.field === fieldItem.name
          )}
          onChange={(e) =>
            onChangeMetricsCheckbox(
              e,
              fieldItem.name,
              fieldItem.field ?? fieldItem.name,
              source
            )
          }
        >
          {fieldItem.name}
        </Checkbox>
      ))
    ) : (
      <Empty />
    );

  return (
    <div className="table-metrics">
      <div className="metrics-heading flex flex-align-items-center flex-space-between">
        <div className="font-caption-bold">
          {t('customDashboard.optionsLabels.metrics')}
        </div>
        <Tooltip
          overlayClassName="metrics-dropdown"
          overlay={
            <div className="flex flex-column flex-gap-8">
              <Input
                type="search"
                rootClassName="full-width"
                placeholder={t('customDashboard.optionsLabels.searchMetrics')}
                size={INPUT_SIZE.SMALL}
                value={searchKey}
                onChange={(e: any) => setSearchKey(e.target.value)}
                autoFocus
              />
              {doesDataSourceSupportBillingMapping() ? (
                <Collapse
                  className="metrics-group new-styled-scroll"
                  accordion={true}
                  bordered={false}
                  expandIcon={getExpandIcon}
                  defaultActiveKey={[FieldSource.SYSTEM]}
                >
                  {[FieldSource.SYSTEM, FieldSource.USER].map((source) => (
                    <Panel
                      className="field-panel"
                      header={
                        <div className="flex flex-align-items-center flex-gap-8">
                          {
                            FIELD_SOURCE_LABELS.find(
                              (item) => item.value === source
                            )!.label
                          }
                        </div>
                      }
                      key={source}
                      extra={
                        getSelectedMetricsCount(source) ? (
                          <div className="font-small-bold flex flex-center values-count">
                            {getSelectedMetricsCount(source)}
                          </div>
                        ) : null
                      }
                    >
                      <div className="flex flex-column flex-gap-8">
                        {getMetricsListComponent(
                          customViewMetrics.filter(
                            (fieldItem) =>
                              fieldItem.name
                                .toLowerCase()
                                .includes(searchKey.toLowerCase()) &&
                              fieldItem.source === source
                          ),
                          source
                        )}
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <div className="metrics-group flex flex-column flex-gap-8 new-styled-scroll">
                  {getMetricsListComponent(
                    customViewMetrics.filter((fieldItem) =>
                      fieldItem.name
                        .toLowerCase()
                        .includes(searchKey.toLowerCase())
                    )
                  )}
                </div>
              )}
            </div>
          }
          placement="bottomRight"
          open={showMetricsDropdown}
          onOpenChange={(visible: any) => {
            if (visible) {
              setSearchKey('');
            } else {
              dispatchUpdatedCustomViewData();
            }
            setShowMetricsDropdown(visible);
          }}
          trigger="click"
          arrow={false}
        >
          <Icon className="add-icon" iconName={ICONS.ADD_LINE} />
        </Tooltip>
      </div>
      <MetricsList />
    </div>
  );
};

export default Metrics;
