import i18n from 'i18n';

export enum GProfilerTabsView {
  TABS = 'TABS',
  LOADING = 'LOADING',
}

export enum GProfilerConnectionView {
  TABLE_VIEW = 'TABLE_VIEW',
  CONNECTION_FORM = 'CONNECTION_FORM',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum GProfilerProviderView {
  PROVIDER_LIST = 'PROVIDER_LIST',
  GCP = 'GCP',
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum GProfilerConnectionRadioValue {
  EXISTING_CLOUD_CONNECTION = 'EXISTING_CLOUD_CONNECTION',
  NEW_CLOUD_CONNECTION = 'NEW_CLOUD_CONNECTION',
}

export enum StatusEnum {
  COMPLETED = 'COMPLETED',
  ONGOING = 'ONGOING',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  DEACTIVATED = 'DEACTIVATED',
}

export const PROGRESS_BAR_ITEMS = [
  {
    title: i18n.t('gProfilerConnectionPage.steps.titleFirst'),
    description: i18n.t('gProfilerConnectionPage.steps.descriptionFirst'),
  },
  {
    title: i18n.t('gProfilerConnectionPage.steps.titleSecond'),
    description: i18n.t('gProfilerConnectionPage.steps.descriptionSecond'),
  },
];
