export enum SwitchConnectionTypes {
  CB_360 = 'CB_360',
  GRANULATE = 'GRANULATE',
}

export const SWITCH_CONNECTION_TYPES_DATA = [
  {
    type: SwitchConnectionTypes.CB_360,
    label: 'cb360Connections',
  },
  {
    type: SwitchConnectionTypes.GRANULATE,
    label: 'granulateConnections',
  },
];
