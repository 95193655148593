import i18n from 'i18n';
import moment from 'moment';
import { addIndexAndCommaSeparator } from 'pages/CostOptimizationInsightsPage/utils';
import {
  SAVINGS_TYPES,
  SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { formatArrayToString } from 'utils/dataFormatterUtils';
import { HYPHEN_DATE_FORMAT } from 'utils/date';
import { CategoryCostDetailsType } from './types';

/**
 * @function getDateRangeFiltersBySavingsType
 * @description Function to return the date range filters by savings type.
 * @param savingsType type of savings for which the date range is required.
 * @returns List of date range filters for request query.
 */
const getDateRangeFiltersBySavingsType = (savingsType: string | undefined) => {
  switch (savingsType) {
    case SAVINGS_TYPES.POTENTIAL_SAVINGS:
      return [
        {
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: QUERY_VALUES.DATE_INTERVAL_30_DAYS,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
    case SAVINGS_TYPES.COST_SAVINGS_IN_PROGRESS:
      return [
        {
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: QUERY_VALUES.DATE_INTERVAL_90_DAYS,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
    case SAVINGS_TYPES.COST_SAVED:
      return [
        {
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: QUERY_VALUES.DATE_INTERVAL_365_DAYS,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];

    default:
      return [];
  }
};

/**
 * @function getSummaryRequestBody
 * @description Function to return the request body for the cost summary
 * @param resources resource(s) for which the data is fetched
 * @param status recommendation status(s)
 * @param selectedSavingsType savings type selected on the chart
 * @returns Object containing the request body
 */
export const getSummaryRequestBody = (
  resources: string[],
  status: string[],
  selectedSavingsType: string | undefined
) => {
  let filters = [
    {
      field: QUERY_FIELDS.STATE,
      comparator: COMPARATORS.IN,
      value: formatArrayToString(status),
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
    {
      field: QUERY_FIELDS.RECOMMENDER,
      comparator: COMPARATORS.IN,
      value: formatArrayToString(resources),
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
    ...getDateRangeFiltersBySavingsType(selectedSavingsType),
  ];

  return {
    columns: [
      {
        label: 'totalCostSaved',
        field: QUERY_FIELDS.SUM_UNITS_PLUS_NANOS,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'seqnum',
          field:
            'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: filters,
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getCostSavingsTrendRequestBody
 * @description Function to return the request body for the cost savings trend
 * @param resources resource(s) for which the data is fetched
 * @param status recommendation status(s)
 * @returns Object containing the request body
 */
export const getCostSavingsTrendRequestBody = (
  resources: string[],
  status: string[]
) => {
  return {
    columns: [
      {
        label: 'totalCost',
        field: QUERY_FIELDS.SUM_UNITS_PLUS_NANOS,
      },
      {
        label: 'month',
        field: QUERY_FIELDS.CONCAT_YEAR_MONTH_FROM_LAST_REFRESH_TIME,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'seqnum',
          field:
            'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: formatArrayToString(status),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: moment()
                  .subtract(11, 'month')
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT),
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: moment().endOf('month').format(HYPHEN_DATE_FORMAT),
              },
              {
                field: QUERY_FIELDS.RECOMMENDER,
                comparator: COMPARATORS.IN,
                value: formatArrayToString(resources),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    groupBy: ['month'],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getCategoryCostSavingsRequestBody
 * @description Function to return the request body for the category resource list data
 * @param resources resource(s) for which the data is fetched
 * @param status recommendation status(s)
 * @param selectedSavingsType savings type selected on the chart
 * @returns Object containing the request body
 */
export const getCategoryCostSavingsRequestBody = (
  resources: string[],
  status: string[],
  selectedSavingsType: string | undefined
) => {
  let filters = [
    {
      field: QUERY_FIELDS.STATE,
      comparator: COMPARATORS.IN,
      value: formatArrayToString(status),
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
    {
      field: QUERY_FIELDS.RECOMMENDER,
      comparator: COMPARATORS.IN,
      value: formatArrayToString(resources),
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
    ...getDateRangeFiltersBySavingsType(selectedSavingsType),
  ];

  return {
    columns: [
      {
        label: 'targetResource',
        field: QUERY_FIELDS.TARGET_RESOURCES,
      },
      {
        label: 'projectNumber',
        field: QUERY_FIELDS.CLOUD_ENTITY_ID,
      },
      {
        label: 'state',
        field: QUERY_FIELDS.STATE,
      },
      {
        label: 'priority',
        field: QUERY_FIELDS.PRIORITY,
      },
      {
        label: 'recommendationName',
        field: QUERY_FIELDS.NAME,
      },
      {
        label: 'cost',
        field: QUERY_FIELDS.SUM_UNITS_PLUS_NANOS,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'seqnum',
          field:
            'row_number() over (partition by TO_JSON_STRING(target_resources) order by last_refresh_time desc)',
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: filters,
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    groupBy: [
      'targetResource',
      'projectNumber',
      'state',
      'priority',
      'recommendationName',
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function fetchCategoryCostSavingsExcelExportColumns
 * @description Function to get the resources table excel columns
 * @param selectedSavingsType savings type selected in summary graph
 * @returns List of excel columns
 */
export const fetchCategoryCostSavingsExcelExportColumns = (
  selectedSavingsType: string | undefined
) => {
  return [
    {
      header: '#',
      key: 'slNo',
      width: 10,
      alignment: 'center',
      dataKey: 'slNo',
    },
    {
      header: i18n.t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.resourceId'
      ),
      key: 'resourceId',
      dataKey: 'resourceId',
      width: 30,
      alignment: 'left',
    },
    {
      header: i18n.t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.projectName'
      ),
      key: 'projectName',
      dataKey: 'projectName',
      width: 30,
      alignment: 'left',
    },
    {
      header: i18n.t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.priority'
      ),
      key: 'priority',
      dataKey: 'priority',
      width: 15,
      alignment: 'center',
    },
    {
      header: i18n.t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.state'
      ),
      key: 'state',
      dataKey: 'state',
      width: 25,
      alignment: 'left',
    },
    {
      header: SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
        (item) => item.type === selectedSavingsType
      )?.label,
      key: 'cost',
      dataKey: 'cost',
      width: 25,
      alignment: 'right',
    },
  ];
};

/**
 * @function getCostSavingsSummaryExcelData
 * @description Function to get the cost savings summary excel data
 * @param connectorName Name of the connection selected
 * @param tableHeading Table heading
 * @param data excel data
 * @param selectedSavingsType savings type selected in summary graph
 * @returns List of excel file configartions and data
 */
export const getCostSavingsSummaryExcelData = (
  connectorName: string,
  tableHeading: string,
  data: CategoryCostDetailsType[],
  selectedSavingsType: string | undefined
) => {
  return {
    sheetName: tableHeading,
    columns: fetchCategoryCostSavingsExcelExportColumns(selectedSavingsType),
    data: addIndexAndCommaSeparator(data),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};
