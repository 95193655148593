import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function shareDashboard
 * @description Function to update the access of multiple users to dashboards
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const shareDashboard = async (body: any) => {
  return ServiceCalls.post(APIS.USER_MANAGEMENT, body);
};

/**
 * @function getUserByEmail
 * @description Function to get user details by email
 * @params body : Object containing the request body
 * @return axios response from GET request
 */
export const getUserByEmail = async (email: string) => {
  return ServiceCalls.get(APIS.GET_USER_BY_EMAIL + email);
};

/**
 * @function getConnectorIdsByUser
 * @description Function to get connector ids by user
 * @return axios response from GET request
 */
export const getConnectorIdsByUser = async (email?: string) => {
  const userEmail = email ?? localStorage.getItem('email');
  return ServiceCalls.get(
    APIS.USER_MANAGEMENT + '/' + userEmail + APIS.GET_CONNECTORS_BY_USER_SUFFIX
  );
};

/**
 * @function updateAccess
 * @description Function to update the access of multiple users to dashboards
 * @params body : Object containing the request body
 * @return axios response from PUT request
 */
export const updateAccess = async (body: any) => {
  return ServiceCalls.put(APIS.USER_MANAGEMENT, body);
};

/**
 * @function deleteUserAccessToDashboard
 * @description Function to get connector ids by user
 * @params params : Object containing the request body
 * @return axios response from GET request
 */
export const deleteUserAccessToDashboard = async (
  email: string,
  params: any
) => {
  return ServiceCalls.delete(
    APIS.USER_MANAGEMENT +
      '/' +
      email +
      APIS.DELETE_DASHBOARD_ACCESS_FOR_USER_SUFFIX,
    null,
    params
  );
};
