import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchAllRoles
 * @description Function to fetch all roles
 * @param params query parameters for get request.
 * @return axios response from GET request
 */
export const fetchAllRoles = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_ALL_ROLES, null, params);
};

/**
 * @function createNewRole
 * @description Function to create a new custom role
 * @param body body for post request.
 * @return axios response from POST request
 */
export const createNewRole = async (body: any) => {
  return ServiceCalls.post(APIS.CUSTOM_ROLE, body);
};

/**
 * @function activateDeactivateCustomRole
 * @description Function to activate/deactivate custom role
 * @param roleId body for post request.
 * @param params query params for post request.
 * @return axios response from POST request
 */
export const activateDeactivateCustomRole = async (
  roleId: number,
  params: any
) => {
  return ServiceCalls.post(
    APIS.CHANGE_NEW_ROLE_STATUS + roleId,
    null,
    null,
    params
  );
};

/**
 * @function deleteCustomRole
 * @description Function to delete a custom role
 * @param param body for delete request.
 * @return axios response from delete request
 */
export const deleteCustomRole = async (param: any) => {
  return ServiceCalls.delete(APIS.CUSTOM_ROLE, null, param);
};

/**
 * @function deleteCustomRole
 * @description Function to delete a custom role
 * @param param body for get request.
 * @return axios response from GET request
 */
export const getUseresForRole = async (param: any) => {
  return ServiceCalls.get(APIS.GET_USERS_FOR_ROLE, null, param);
};

/**
 * @function editCustomRole
 * @description Function to edit a custom role
 * @param body body for put request.
 * @return axios response from PUT request
 */
export const editCustomRole = async (body: any) => {
  return ServiceCalls.put(APIS.CUSTOM_ROLE, body);
};
