import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import { validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { SECRET_KEY_PLACEHOLDER } from 'pages/ConnectingCSPPage/constants';
import { onApiCallError } from 'utils/handleErrors';

import {
  fetchAWScatalogs,
  fetchAWSdatabase,
  fetchAWStable,
} from '../../service';

export const BillingDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection } = useSelector(cloudConnection);

  // Dropdown enabling
  const [disableCatalogs, setDisableCatalogs] = useState<boolean>(true);
  const [disableDatabases, setDisableDatabases] = useState<boolean>(true);
  const [disableTables, setDisableTables] = useState<boolean>(true);

  // Dropdown states
  const [catalogs, setCatalogs] = useState<string[]>([]);
  const [databases, setDatabases] = useState<string[]>([]);
  const [tables, setTables] = useState<string[]>([]);

  //Validation status
  const [
    awsDataCatalogRequiredValidation,
    setAwsDataCatalogRequiredValidation,
  ] = useState('');
  const [awsDataBaseRequiredValidation, setAwsDataBaseRequiredValidation] =
    useState('');
  const [awsTableRequiredValidation, setAwsTableRequiredValidation] =
    useState('');

  //Dropdown values request Status
  const [catalogValuesReqStatus, setCatalogValuesReqStatus] = useState('');
  const [databaseValuesReqStatus, setDatabaseValuesReqStatus] = useState('');
  const [billingTableValuesReqStatus, setBillingTableValuesReqStatus] =
    useState('');

  useEffect(() => {
    if (
      currentConnection?.awsAccessKeyId &&
      currentConnection?.awsSecretKey &&
      currentConnection?.awsRegion
    ) {
      fetchCatalogs();
    }
  }, [
    currentConnection?.awsAccessKeyId,
    currentConnection?.awsSecretKey,
    currentConnection?.awsRegion,
  ]);

  useEffect(() => {
    if (
      currentConnection?.dataCatalog &&
      currentConnection?.awsAccessKeyId &&
      currentConnection?.awsSecretKey &&
      currentConnection?.awsRegion
    ) {
      fetchDatabase();
    }
  }, [
    currentConnection?.awsAccessKeyId,
    currentConnection?.awsSecretKey,
    currentConnection?.awsRegion,
    currentConnection?.dataCatalog,
  ]);

  useEffect(() => {
    if (
      currentConnection?.dataCatalog &&
      currentConnection?.awsAccessKeyId &&
      currentConnection?.awsSecretKey &&
      currentConnection?.awsRegion &&
      currentConnection?.billingDataset
    ) {
      fetchTable();
    }
  }, [
    currentConnection?.awsAccessKeyId,
    currentConnection?.awsSecretKey,
    currentConnection?.awsRegion,
    currentConnection?.dataCatalog,
    currentConnection?.billingDataset,
  ]);

  const fetchCatalogs = () => {
    setCatalogValuesReqStatus(REQUEST_STATUS.PROCESSING);
    const body =
      currentConnection?.awsAccessKeyId !== SECRET_KEY_PLACEHOLDER &&
      currentConnection?.awsSecretKey !== SECRET_KEY_PLACEHOLDER
        ? {
            awsAccessKeyId: currentConnection?.awsAccessKeyId,
            awsSecretKey: currentConnection?.awsSecretKey,
            awsRegion: currentConnection?.awsRegion,
          }
        : {
            connectorId: currentConnection?.connectorId,
          };
    fetchAWScatalogs(body)
      .then((res: any) => {
        setCatalogValuesReqStatus(REQUEST_STATUS.SUCCESS);
        setCatalogs(res.data.responseData);
        if (currentConnection) {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              dataCatalog: currentConnection?.dataCatalog,
            })
          );
        } else {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              dataCatalog: res?.data?.responseData[0],
            })
          );
        }
        if (res.data.responseData.length !== 0) {
          setDisableCatalogs(false);
        } else {
          setDisableCatalogs(true);
        }
        setAwsDataCatalogRequiredValidation('');
      })
      .catch((e) => {
        onApiCallError(e, false, setCatalogValuesReqStatus);
        dispatch(
          setCurrentConnection({
            ...currentConnection,
            dataCatalog: '',
          })
        );
        setDisableCatalogs(true);
      });
  };

  const fetchDatabase = () => {
    setDatabaseValuesReqStatus(REQUEST_STATUS.PROCESSING);
    const body =
      currentConnection?.awsAccessKeyId !== SECRET_KEY_PLACEHOLDER &&
      currentConnection?.awsSecretKey !== SECRET_KEY_PLACEHOLDER
        ? {
            awsAccessKeyId: currentConnection?.awsAccessKeyId,
            awsSecretKey: currentConnection?.awsSecretKey,
            awsRegion: currentConnection?.awsRegion,
            dataCatalog: currentConnection?.dataCatalog,
          }
        : {
            connectorId: currentConnection?.connectorId,
            dataCatalog: currentConnection?.dataCatalog,
          };
    fetchAWSdatabase(body)
      .then((res: any) => {
        setDatabaseValuesReqStatus(REQUEST_STATUS.SUCCESS);
        setDatabases(res.data.responseData);
        if (currentConnection) {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              billingDataset: currentConnection?.billingDataset,
            })
          );
        } else {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              billingDataset: res?.data?.responseData[0],
            })
          );
        }
        if (res.data.responseData.length !== 0) {
          setDisableDatabases(false);
        } else {
          setDisableDatabases(true);
        }
        setAwsDataBaseRequiredValidation('');
      })
      .catch((e) => {
        onApiCallError(e, false, setDatabaseValuesReqStatus);
        dispatch(
          setCurrentConnection({
            ...currentConnection,
            billingDataset: '',
          })
        );
        setDisableDatabases(true);
      });
  };

  const fetchTable = () => {
    setBillingTableValuesReqStatus(REQUEST_STATUS.PROCESSING);
    const body =
      currentConnection?.awsAccessKeyId !== SECRET_KEY_PLACEHOLDER &&
      currentConnection?.awsSecretKey !== SECRET_KEY_PLACEHOLDER
        ? {
            awsAccessKeyId: currentConnection?.awsAccessKeyId,
            awsSecretKey: currentConnection?.awsSecretKey,
            awsRegion: currentConnection?.awsRegion,
            dataCatalog: currentConnection?.dataCatalog,
            billingDataset: currentConnection?.billingDataset,
          }
        : {
            connectorId: currentConnection?.connectorId,
            dataCatalog: currentConnection?.dataCatalog,
            billingDataset: currentConnection?.billingDataset,
          };
    fetchAWStable(body)
      .then((res: any) => {
        setTables(res.data.responseData);
        setBillingTableValuesReqStatus(REQUEST_STATUS.SUCCESS);
        if (currentConnection) {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              billingTableName: currentConnection?.billingTableName,
            })
          );
        } else {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              billingTableName: res?.data?.responseData[0],
            })
          );
        }

        if (res.data.responseData.length !== 0) {
          setDisableTables(false);
        } else {
          setDisableTables(true);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setBillingTableValuesReqStatus);
        dispatch(
          setCurrentConnection({
            ...currentConnection,
            billingTableName: '',
          })
        );
        setDisableTables(true);
        setAwsTableRequiredValidation('');
      });
  };

  const CatalogDropDown = (
    <>
      {catalogs?.map((catalog: any) => (
        <Select.Option key={catalog} value={catalog}>
          {catalog}
        </Select.Option>
      ))}
    </>
  );

  const DatabaseDropDown = (
    <>
      {databases?.map((database: string) => (
        <Select.Option key={database} value={database}>
          {database}
        </Select.Option>
      ))}
    </>
  );

  const ConnectionBillingTableDropdown = (
    <>
      {tables?.map((table: string) => (
        <Select.Option key={table} value={table}>
          {table}
        </Select.Option>
      ))}
    </>
  );

  return (
    <div className="collapse-body flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t('awsConnectionCSPForm.dataCatalog')} />
        <SelectDropdown
          value={currentConnection?.dataCatalog}
          menu={CatalogDropDown}
          disabled={disableCatalogs}
          loading={catalogValuesReqStatus === REQUEST_STATUS.PROCESSING}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                dataCatalog: value,
              })
            );
            setAwsDataCatalogRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.dataCatalog,
              t('awsConnectionCSPForm.dataCatalog'),
              setAwsDataCatalogRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${awsDataCatalogRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {awsDataCatalogRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('awsConnectionCSPForm.database')} />
        <SelectDropdown
          value={currentConnection?.billingDataset}
          menu={DatabaseDropDown}
          disabled={disableDatabases}
          loading={databaseValuesReqStatus === REQUEST_STATUS.PROCESSING}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                billingDataset: value,
              })
            );
            setAwsDataBaseRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.billingDataset,
              t('awsConnectionCSPForm.database'),
              setAwsDataBaseRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${awsDataBaseRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {awsDataBaseRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('awsConnectionCSPForm.billingTable')} />
        <SelectDropdown
          value={currentConnection?.billingTableName}
          menu={ConnectionBillingTableDropdown}
          disabled={disableTables}
          loading={billingTableValuesReqStatus === REQUEST_STATUS.PROCESSING}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                billingTableName: value,
              })
            );
            setAwsTableRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.billingTableName,
              t('awsConnectionCSPForm.billingTable'),
              setAwsTableRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${awsTableRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {awsTableRequiredValidation}
        </span>
      </div>
    </div>
  );
};
