export enum PERMISSION_RIGHTS {
  READ = 'READ',
  WRITE = 'WRITE',
  LIST = 'LIST',
  MODIFY = 'MODIFY',
}

export enum PERMISSION_MODULES {
  DASHBOARD = 'DASHBOARD',
  CLOUD_CONNECTOR = 'CLOUD_CONNECTOR',
  BUDGET_ALERTS = 'BUDGET_ALERTS',
  COST_CONTROL = 'COST_CONTROL',
  COST_ALLOCATION = 'COST_ALLOCATION',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  THIRD_PARTY_APPS = 'THIRD_PARTY_APPS',
}
