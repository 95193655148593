import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { HYPHEN_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import ColumnChart from 'components/ColumnChart';
import { selectDashboard } from 'redux/dashboardSlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getAWSCostAndCreditBreakDownExcelData } from '../../utils/exportToExcel';

type CostAndCreditBreakDownProps = {
  requestStatus: string[];
  costCreditBreakdownLabels: string[];
  costCreditBreakdownCostData: number[];
  costCreditBreakdownCreditData: number[];
  isCostAndCreditBreakdownTableView: boolean;
  setCostAndCreditBreakdownTableView: (value: boolean) => void;
  pdfView: boolean;
  costAndCreditStartDate: string;
  costAndCreditEndDate: string;
  onChangeCostAndCreditDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

export const CostAndCreditBreakDown = ({
  requestStatus,
  costCreditBreakdownLabels,
  costCreditBreakdownCostData,
  costCreditBreakdownCreditData,
  isCostAndCreditBreakdownTableView,
  setCostAndCreditBreakdownTableView,
  pdfView,
  costAndCreditStartDate,
  costAndCreditEndDate,
  onChangeCostAndCreditDateRange,
}: CostAndCreditBreakDownProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('costAndCreditBreakDown.month'),
      dataIndex: 'month',
      key: 'month',
      width: 50,
    },
    {
      title: t('costAndCreditBreakDown.unblendedCost'),
      dataIndex: 'unblendedCost',
      key: 'unblendedCost',
      width: 180,
      align: 'center' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
    {
      title: t('costAndCreditBreakDown.cost'),
      dataIndex: 'cost',
      key: 'cost',
      width: 180,
      align: 'center' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(costAndCreditStartDate, HYPHEN_DATE_FORMAT),
          moment(costAndCreditEndDate, HYPHEN_DATE_FORMAT),
        ]}
        onChange={onChangeCostAndCreditDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  const getGraphData = () => {
    const graphData: any[] = [];
    costCreditBreakdownLabels.forEach((value, index) => {
      graphData.push(
        ...[
          {
            month: value,
            category: t('costAndCreditBreakDown.unblendedCost'),
            value: Number(costCreditBreakdownCostData[index]),
          },
          {
            month: value,
            category: t('costAndCreditBreakDown.credit'),
            value: Number(costCreditBreakdownCreditData[index]),
          },
        ]
      );
    });
    return graphData;
  };

  const getTableDataSource = () =>
    costCreditBreakdownLabels.map((value, index) => {
      return {
        month: value,
        cost: costCreditBreakdownCostData[index] || 0,
        unblendedCost: costCreditBreakdownCreditData[index] || 0,
      };
    });

  const getComponent = () =>
    isCostAndCreditBreakdownTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={getTableDataSource().map((value, index) => ({
            ...value,
            key: index,
          }))}
          columns={columns}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={getGraphData()}
        xField="month"
        yField="value"
        xTitle={t('costAndCreditBreakDown.month')}
        yTitle={t('costAndCreditBreakDown.grossUnblendedCostOrCredit')}
        isGroup
        groupingField="category"
        disableAnimation={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  return (
    <div className="cost-and-credit-breakdown graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.costandCreditBreakdown')}
        graphName={'cost-and-credit-breakdown'}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        isTableView={isCostAndCreditBreakdownTableView}
        setIsTableView={setCostAndCreditBreakdownTableView}
        designVersion2
        excelData={getAWSCostAndCreditBreakDownExcelData(
          selectedDashboard?.connectorName ?? '',
          getTableDataSource()
        )}
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};
