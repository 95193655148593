import i18n from 'i18n';

import { store } from 'redux/store';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export enum INSTANCE_STATUS {
  ALL = 'ALL',
  PASSIVE = 'PASSIVE',
  LEARNING = 'LEARNING',
  ACTIVE = 'ACTIVE',
  LOADED = 'LOADED',
}

export const InstanceStatusItems = [
  {
    key: INSTANCE_STATUS.ALL,
    label: i18n.t('granulateInsightDashboard.all'),
  },
  {
    key: INSTANCE_STATUS.PASSIVE,
    label: i18n.t('granulateInsightDashboard.passive'),
  },
  {
    key: INSTANCE_STATUS.LEARNING,
    label: i18n.t('granulateInsightDashboard.learning'),
  },
  {
    key: INSTANCE_STATUS.ACTIVE,
    label: i18n.t('granulateInsightDashboard.activated'),
  },
  {
    key: INSTANCE_STATUS.LOADED,
    label: i18n.t('granulateInsightDashboard.loaded'),
  },
];

export const InstanceExcelColumns = [
  { header: '#', key: 'slNo', width: 10, alignment: 'center', dataKey: 'slNo' },
  {
    header: i18n.t('granulateInsightDashboard.instanceName'),
    dataKey: 'instanceName',
    key: 'instanceName',
    alignment: 'left',
    width: 40,
  },
  {
    header: i18n.t('granulateInsightDashboard.agentVersion'),
    key: 'agentVersion',
    dataKey: 'agentVersion',
    width: 40,
    alignment: 'left',
  },
  {
    header: i18n.t('granulateInsightDashboard.status'),
    key: 'status',
    dataKey: 'status',
    width: 40,
    alignment: 'left',
  },
];
