import { Keys } from 'constants/userConsole';

const AccessibleDiv = ({
  onEnterSpace,
  onOtherKey,
  dataTestId,
  ...remainingProps
}: any) => {
  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.SPACE) {
      onEnterSpace ? onEnterSpace() : remainingProps.onClick?.();
    } else {
      onOtherKey?.(e.key);
    }
  };

  return (
    <div
      data-testid={dataTestId}
      tabIndex={0}
      onKeyUp={handleKeyUp}
      {...remainingProps}
    />
  );
};

export default AccessibleDiv;
