import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { selectReport, setReportOptions } from 'redux/reportSlice';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { OrderByType } from 'types/dashboard';

import SortList from '../SortList';

import './index.scss';

const Sort = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reportOptions } = useSelector(selectReport);

  const onClickAddSort = () => {
    const oldSorts = [...reportOptions.sort];
    const newSorts: OrderByType[] = [
      ...oldSorts,
      { label: '', order: '' },
    ] as OrderByType[];
    dispatch(setReportOptions({ ...reportOptions, sort: newSorts }));
  };

  return (
    <div className="reports-table-sort">
      <div className="sort-heading flex flex-align-items-center flex-space-between">
        <div className="font-caption-bold">{t('reports.sort')}</div>
        {[...reportOptions.dimension, ...reportOptions.metric].length >
          reportOptions.sort.length && (
          <Icon
            className="add-icon"
            iconName={ICONS.ADD_LINE}
            onClick={onClickAddSort}
            dataTestId="add-sort"
          />
        )}
      </div>
      <SortList />
    </div>
  );
};

export default Sort;
