import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from 'components/InfiniteScrollTable';

import { DEFAULT_ROWS } from '../../constants';
import { sortTableData } from '../../utils';

import './index.scss';

type SortType = {
  key: string;
  order: string | undefined;
};

type InsightTableProps = {
  data: any[];
  totalSamples: number;
  setExcelData: (data: any[]) => void;
  showExpandedGraphModal: boolean;
};

const InsightTable = ({
  data,
  totalSamples,
  setExcelData,
  showExpandedGraphModal,
}: InsightTableProps) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState<number>(DEFAULT_ROWS);
  const [filteredTableData, setFilteredTableData] = useState<any[]>([]);
  const [sortApplied, setSortApplied] = useState<SortType>({
    key: '',
    order: undefined,
  });

  const ids = data.map((row) => row.id);
  const labels = data.map((row) => row.label);
  const values = data.map((row) => row.value);
  const colors = data.map((row) => row.color);
  const languages = data.map((row) => row.language);

  /**
   * @function calculateTotalTimePercent
   * @description Function to calculate the totalTime percentage
   * @param value total time of each row
   * @returns calculated totalTime percentage till 2 decimal places.
   */
  const calculateTotalTimePercent = (value: number) =>
    ((value / totalSamples) * 100).toFixed(2);

  /**
   * @function calculateOwnTimePercentage
   * @description Function to calculate the Own time from table data
   * @param row each row of table data
   * @returns calculated own time in percentage till 2 decimal places.
   */
  const calculateOwnTimePercentage = (row: any) => {
    const sumChildrenValues = data
      .filter((item) => item.parent === row.id)
      .reduce((sum, item) => sum + item.value, 0);
    return (((row.value - sumChildrenValues) / totalSamples) * 100).toFixed(2);
  };

  /**
   * @function handleChangePagination
   * @description Function to handle the pagination change
   */
  const handleChangePagination = () => {
    setRows((prev) => prev + DEFAULT_ROWS);
  };

  /**
   * @function onChangeSortApplied
   * @description Function to get sorted data
   * @param key field against which the sorting is done.
   * @param order order of sorting, either ascending or descending
   */
  const onChangeSortApplied = (key: string, order: string | undefined) => {
    if (sortApplied.key === key && sortApplied.order === order) return;

    if (order === undefined) {
      setFilteredTableData(tableData);
    } else {
      const sortedData = sortTableData(tableData, key, order);
      setFilteredTableData(sortedData);
    }

    setSortApplied({ key, order });
  };

  const totalTimes = values.map((value) => calculateTotalTimePercent(value));
  const ownTimes = data.map((row) => calculateOwnTimePercentage(row));

  const tableData: any[] = [];
  for (let i = 0; i < ids.length; i++) {
    if (labels[i]) {
      const obj = {
        functionName: labels[i],
        ownTime: ownTimes[i],
        samples: values[i],
        totalCPU: totalTimes[i],
        color: colors[i],
        language: languages[i],
      };
      tableData.push(obj);
    }
  }

  useEffect(() => {
    setRows(DEFAULT_ROWS);
    setExcelData(tableData);

    const sortedTableData = [...tableData].sort(
      (item1, item2) => parseFloat(item2.totalCPU) - parseFloat(item1.totalCPU)
    );

    setFilteredTableData(sortedTableData);
  }, [data]);

  const columns = [
    {
      title: t('gprofilerInsightsDashboard.runtime'),
      dataIndex: 'runtime',
      key: 'runtime',
      width: '10%',
      render: (_text: any, record: any, _index: number) => {
        return (
          <div className="runtime flex flex-gap-4 flex-align-items-center">
            <div
              className="runtime-box"
              style={{ background: record.color }}
            ></div>
            <span>{record.language}</span>
          </div>
        );
      },
    },
    {
      title: t('gprofilerInsightsDashboard.functionName'),
      dataIndex: 'functionName',
      key: 'functionName',
      width: '32%',
      sorter: true,
      render: (text: any, _record: any, _index: number) => {
        return <span className="font-caption">{text}</span>;
      },
    },
    {
      title: t('gprofilerInsightsDashboard.samples'),
      dataIndex: 'samples',
      key: 'samples',
      width: '14%',
      align: 'center' as const,
      render: (text: any) => <span className="date">{text}</span>,
      sorter: true,
    },
    {
      title: t('gprofilerInsightsDashboard.totalCPU'),
      key: 'totalCPU',
      dataIndex: 'totalCPU',
      width: '14%',
      align: 'center' as const,
      defaultSortOrder: 'descend',
      sorter: true,
      render: (text: any) => {
        return <span className={'font-caption'}>{text}</span>;
      },
    },
    // {
    //   title: t('gprofilerInsightsDashboard.trend'),
    //   key: 'trend',
    //   dataIndex: 'trend',
    //   width: '14%',
    //   align: 'center' as const,
    //   sorter: true,
    //   render: (text: any) => {
    //     return <span className={'font-caption'}>{text}</span>;
    //   },
    // },
    {
      title: t('gprofilerInsightsDashboard.ownTime'),
      key: 'ownTime',
      dataIndex: 'ownTime',
      width: '14%',
      align: 'center' as const,
      sorter: true,
      render: (text: any) => {
        return <span className={'font-small'}>{text}</span>;
      },
    },
    // {
    //   title: t('gprofilerInsightsDashboard.occurrences'),
    //   key: 'occurrences',
    //   dataIndex: 'occurrences',
    //   align: 'right',
    //   width: '14%',
    //   sorter: true,
    //   render: (text: any) => {
    //     return <span className={'font-small'}>{text}</span>;
    //   },
    // },
  ];

  return (
    <div className="insight-table">
      <InfiniteScrollTable
        bordered
        className="design-v2"
        pagination={false}
        dataSource={filteredTableData.slice(0, rows)}
        columns={columns}
        handleChangePageNumber={handleChangePagination}
        designVersion2
        scroll={{
          y: `calc(100vh - ${showExpandedGraphModal ? '19em' : '28em'})`,
          scrollToFirstRowOnChange: false,
        }}
        onChange={(_newPagination: any, _filters: any, sorter: any) => {
          if (
            sortApplied.key !== sorter.field ||
            sortApplied.order !== sorter.order
          ) {
            onChangeSortApplied(sorter.field, sorter.order);
          }
        }}
      />
    </div>
  );
};

export default InsightTable;
