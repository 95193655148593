import i18n from 'i18n';

export const QUARTER_COST_SUMMARY = [
  i18n.t('quarterCostSummary.currentQuarter'),
  i18n.t('quarterCostSummary.previousQuarter'),
];

export const OTHERS_LABEL = 'Others';

export const MISCELLANEOUS_FAMILY = 'Miscellaneous';
