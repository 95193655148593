import { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { useSelector } from 'react-redux';

import { selectNavMenu } from 'redux/activeNavMenuSlice';
import { COLORS } from 'constants/graphConfig';

import './index.scss';

type TreeMapProps = {
  className?: string;
  data: any[];
  tooltipContent: (
    label: string,
    totalTime: string,
    ownTime: string,
    value: number
  ) => string;
  zoom: {};
  totalSamples: number;
  onClick?: (event: any) => void;
};

const TreeMapComponent = ({
  className,
  data,
  tooltipContent,
  zoom,
  totalSamples = 0,
  onClick,
}: TreeMapProps) => {
  const { isSubMenuOpen } = useSelector(selectNavMenu);

  const [increasedHeight, setIncreasedHeight] = useState<number>(0);

  const rootItems = data.filter((item) => item.parent === undefined).length;

  const ids = data.map((row) => row.id);
  const labels = data.map((row) => row.label);
  const parents = data.map((row) => row.parent);
  const values = data.map((row) => row.value);

  const calculateTotalTimePercent = (value: number) =>
    ((value / totalSamples) * 100).toFixed(2);

  const calculateOwnTimePercentage = (row: any) => {
    const sumChildrenValues = data
      .filter((item) => item.parent === row.id)
      .reduce((sum, item) => sum + item.value, 0);
    return (((row.value - sumChildrenValues) / totalSamples) * 100).toFixed(2);
  };

  const totalTimes = values.map((value) => calculateTotalTimePercent(value));
  const ownTimes = data.map((row) => calculateOwnTimePercentage(row));

  useEffect(() => {
    if (rootItems > 15) {
      const factor = Math.floor((rootItems - 15) / 5);
      setIncreasedHeight((prevHeight) => (factor + 1) * 50 + prevHeight);
    } else {
      setIncreasedHeight(0);
    }
  }, [rootItems]);

  return (
    <section className={`treemap new-styled-scroll ${className}`}>
      <Plot
        style={{ height: `calc(100% + ${increasedHeight}px)` }}
        className={`plot new-styled-scroll ${isSubMenuOpen && 'menu-open'}`}
        data={[
          {
            type: 'treemap',
            ids,
            labels,
            parents,
            values,
            marker: { colors: data.map((row) => row.color) },
            x: totalTimes,
            hovertemplate: ids.map((_id, index: number) =>
              tooltipContent(
                labels[index],
                totalTimes[index],
                ownTimes[index],
                values[index]
              )
            ),
          },
        ]}
        layout={{
          hovermode: 'closest',
          hoverlabel: { bgcolor: COLORS.secondaryBackground, align: 'left' },
          autosize: true,
          margin: { t: 0, b: 0, l: 0, r: 0 },
          grid: { xgap: 30 },
          ...zoom,
          transition: { duration: 500, easing: 'cubic-in-out' },
        }}
        config={{
          responsive: true,
          displayModeBar: false,
        }}
        onClick={onClick}
      />
    </section>
  );
};

export default TreeMapComponent;
