import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function createGranulateIncident
 * @description Function to create the workflow ticket integration for Granulate
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createGranulateIncident = async (body: any) => {
  return ServiceCalls.post(APIS.GRANULATE_CREATE_TICKET, body);
};

/**
 * @function getTicketIncidentInfo
 * @description Function to get information about the ticket
 * @param sysId sysId for the ticket
 * @return axios response from GET request
 */
export const getTicketIncidentInfo = async (sysId: string) => {
  return ServiceCalls.get(APIS.GPROFILER_GET_SNOW_TICKET + sysId);
};

/**
 * @function getGCPCostImapct
 * @description Function to create the workflow ticket integration
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const getGCPCostImapct = async (
  connectorId: string | undefined,
  body: any,
  params: any
) => {
  return ServiceCalls.post(
    APIS.GCP_COST_IMPACT + connectorId,
    body,
    null,
    params
  );
};
