import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import { useTranslation } from 'react-i18next';
import DashboardComponent from 'components/DashboardComponent';
import { selectDashboard } from 'redux/dashboardSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MonthlyCostByNameType } from 'types/dataTypes';
import ColumnChart from 'components/ColumnChart';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ConnectionsFilter from '../ConnectionsFilter';
import { getMonthlyCostByCloudProviderExcelData } from '../../utils/exportToExcel';
import { getFilteredConnections } from '../../utils/commonUtils';

type MonthlyCostByCloudProviderProps = {
  requestStatus: string;
  isTableView: boolean;
  setIsTableView: (val: boolean) => void;
  monthlyCostByCloudProvider: MonthlyCostByNameType[];
  monthlyCostByCloudProviderTableData: any[];
  providers: string[];
  selectedConnections: string[];
  setSelectedConnections: (val: string[]) => void;
  pdfView: boolean;
  costByProviderStartMonth: string;
  costByProviderEndMonth: string;
  onChangeCostByProviderDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

const MonthlyCostByCloudProvider = ({
  requestStatus,
  isTableView,
  setIsTableView,
  monthlyCostByCloudProvider,
  monthlyCostByCloudProviderTableData,
  providers,
  selectedConnections,
  setSelectedConnections,
  pdfView,
  costByProviderStartMonth,
  costByProviderEndMonth,
  onChangeCostByProviderDateRange,
}: MonthlyCostByCloudProviderProps) => {
  const { t } = useTranslation();
  const { selectedGroupMetaData } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('consolidatedYtdCost.month'),
      dataIndex: 'month',
      key: 'month',
    },
    ...providers.map((provider) => ({
      title: provider,
      dataIndex: provider,
      key: provider,
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    })),
  ];

  const filters = (
    <div className="filters flex flex-align-items-center full-width">
      <DatePicker
        defaultValue={[
          moment(costByProviderStartMonth),
          moment(costByProviderEndMonth),
        ]}
        onChange={onChangeCostByProviderDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        designVersion2
      />
      <ConnectionsFilter
        selectedConnections={selectedConnections}
        onSelectedDropdown={setSelectedConnections}
      />
    </div>
  );

  const getComponent = () => {
    return isTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={monthlyCostByCloudProviderTableData.map((item, index) => {
            return { ...item, key: index };
          })}
          columns={columns}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={monthlyCostByCloudProvider}
        xField="month"
        yField="cost"
        groupingField="name"
        isStack
        xTitle={t('consolidatedYtdCost.month')}
        yTitle={t('costTrend.costInCurrency', {
          currencySymbol: currencySymbol,
        })}
        prefixSymbol={currencySymbol}
      />
    );
  };

  return (
    <div
      className="monthly-cost-by-cloud-provider graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.monthlyCostbyCloudProvider')}
        filters={filters}
        graphName="monthly-cost-by-cloud-provider"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        excelData={getMonthlyCostByCloudProviderExcelData(
          selectedGroupMetaData?.name ?? '',
          monthlyCostByCloudProviderTableData,
          providers,
          getFilteredConnections(
            selectedGroupMetaData?.connectorDtos ?? [],
            selectedConnections
          ).map((connection) => connection.name)
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};

export default MonthlyCostByCloudProvider;
