import { PROVIDER } from 'constants/cloudProviders';

export const STANDARD_SCHEMA_SUPPORTED_FILE_TYPE_BY_PROVIDER = {
  [PROVIDER.GCP]: { extensions: ['.parquet'], label: 'Parquet' },
  [PROVIDER.AWS]: { extensions: ['.csv', '.parquet'], label: 'CSV/Parquet' },
  [PROVIDER.AZURE]: {
    extensions: ['.csv', '.parquet'],
    label: 'CSV/Parquet',
  },
  [PROVIDER.OCI]: {
    extensions: ['.csv', '.parquet'],
    label: 'CSV/Parquet',
  },
};

export const FOCUS_SCHEMA_SUPPORTED_FILE_TYPE_BY_PROVIDER = {
  [PROVIDER.GCP]: { extensions: ['.parquet'], label: 'Parquet' },
  [PROVIDER.AWS]: { extensions: ['.csv', '.parquet'], label: 'CSV/Parquet' },
  [PROVIDER.AZURE]: {
    extensions: ['.csv', '.parquet'],
    label: 'CSV/Parquet',
  },
  [PROVIDER.OCI]: {
    extensions: ['.csv', '.parquet'],
    label: 'CSV/Parquet',
  },
};
