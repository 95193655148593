import moment from 'moment';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
} from 'constants/requestBody';
import { HYPHEN_DATE_FORMAT } from 'utils/date';
import { ComparisonListType, ConnectionListType } from 'types/dashboard';
import { CoverageUtilizationExcelMetaData } from 'pages/ScorecardPage/types';
import { AWS_CHECKS_MAP, AWS_CHECK_IDS } from 'constants/recommendations';

import { getSavingsTrackExcelData } from '../SavingsTrack/utils';
import { getUtilizationCoverageSummaryExcelData } from '../CoverageUtilizationSummaryGraph/utils';

/**
 * @function geCostSavingsQuery
 * @description Function to construct and return the cost savings query for AWS
 * @param checkId string checkId for which the query is constructed
 * @param states List of string recommendation status
 * @param dateRange Object containing the start date and end date
 * @returns Object containing the cost savings query
 */
export const geCostSavingsQuery = (
  checkId: string,
  state: string[],
  dateRange: { startDate: string; endDate: string }
) => {
  const checkIdMap = AWS_CHECKS_MAP.find((map) => map.check === checkId);

  let dateField = QUERY_FIELDS.DATE_PARSE_DATE_TIME;
  let monthField = QUERY_FIELDS.FORMAT_DATE_TIME_YYYYMM;
  let rownumField = `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}", format_datetime(cast(date_parse(datetime,'%Y-%m-%d %H:%i:%s') as date),'YYYYMM') ORDER BY status_priority DESC, datetime DESC)`;
  if (checkId.toLowerCase() === AWS_CHECK_IDS.RIGHT_SIZING_EC2.toLowerCase()) {
    dateField = QUERY_FIELDS.DATE_PARSE_GENERATION_TIME;
    monthField = QUERY_FIELDS.FORMAT_GENERATION_TIME_YYYYMM;
    rownumField = `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}", format_datetime(cast(date_parse(generationtime,'%Y-%m-%dT%H:%i:%sZ') as date),'YYYYMM') ORDER BY status_priority DESC, generationtime DESC)`;
  }

  const recomStatusFilters = state.map((eachState) => ({
    field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    comparator: COMPARATORS.EQUALS,
    value: eachState,
    conjunctToNextFilter: CONJUNCTIONS.OR,
  }));

  return {
    checkName: Object.entries(AWS_CHECK_IDS).find(
      ([, value]) => value === checkId
    )?.[0],
    checkId: checkId.toLowerCase(),
    recommendationsDto: {
      columns: [
        ...(checkIdMap?.rulesetFiletrsAdditionalColumns ?? []),
        {
          label: 'month',
          field: monthField,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
          {
            label: 'rownum',
            field: rownumField,
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: dateField,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment(dateRange.endDate).format(
                  HYPHEN_DATE_FORMAT
                )} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: dateField,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment(dateRange.startDate).format(
                  HYPHEN_DATE_FORMAT
                )} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.ROW_NUM,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            ...recomStatusFilters,
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    filterDto: {
      columns: [
        {
          label: 'month',
          field: QUERY_FIELDS.MONTH,
        },
        {
          label: 'estimatedSavings',
          field: checkIdMap?.savingsCostField,
        },
      ],
      aggregators: [
        {
          label: 'estimatedSavings',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      cached: true,
    },
  };
};

/**
 * @function getAWSScorecardSummaryExcelExportData
 * @description Function to construct and return AWS scorecard summary excel export data
 * @param savingsTrackData List of svings track data
 * @param spExcelData Utilization and coverage data for Saving Plan
 * @param riExcelData Utilization and coverage data for Reserved Instance
 * @param connection Connection details of the selected dashboard
 * @returns List containing the excel export data
 */
export const getAWSScorecardSummaryExcelExportData = (
  savingsTrackData: ComparisonListType[],
  riExcelData: {
    utilization: CoverageUtilizationExcelMetaData;
    coverage: CoverageUtilizationExcelMetaData;
  },
  spExcelData: {
    utilization: CoverageUtilizationExcelMetaData;
    coverage: CoverageUtilizationExcelMetaData;
  },
  connection: ConnectionListType | null
) => {
  return [
    getSavingsTrackExcelData(savingsTrackData, connection),
    ...getUtilizationCoverageSummaryExcelData(
      riExcelData.utilization,
      riExcelData.coverage,
      connection
    ),
    ...getUtilizationCoverageSummaryExcelData(
      spExcelData.utilization,
      spExcelData.coverage,
      connection
    ),
  ];
};

/**
 * @function getMonthWiseDates
 * @description Function to get the start date and current date of each month
 * If the current date is first of the month, return previous month's start date and end date
 * @returns Object containing the start date and end date
 */
export const getMonthWiseDates = () => {
  if (moment().date() <= 2) {
    const monthStartDate = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT);
    const monthEndDate = moment()
      .subtract(1, 'month')
      .endOf('month')
      .format(HYPHEN_DATE_FORMAT);
    return { startDate: monthStartDate, endDate: monthEndDate };
  }
  const startDate = moment().startOf('month').format(HYPHEN_DATE_FORMAT);
  const endDate = moment().format(HYPHEN_DATE_FORMAT);
  return { startDate: startDate, endDate: endDate };
};
