import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Empty, TablePaginationConfig, message } from 'antd';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';

import { selectTheme } from 'redux/themeSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import { selectPurchase } from 'redux/purchaseSlice';
import {
  setTicketIntegrationData,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';
import ControlComponent from 'components/DashboardControl';
import ConfirmModal from 'components/ConfirmModal';
import { ControlFilterType } from 'components/DashboardControl/types';
import ComparisonCard from 'components/NewComparisonCard';
import RadioGroup from 'components/RadioGroup';
import Button from 'components/Button';
import Icon from 'components/Icon';
import TableFilterDropdown from 'components/TableFilterDropdown';
import Table from 'components/Table';
import CreateTicketDrawer from 'components/CreateTicketDrawer';
import TicketInfoDrawer from 'components/TicketInfoDrawer';
import { INCIDENT_TYPES } from 'components/CreateTicketDrawer/constants';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { StatusFilterType } from 'pages/CostOptimizationInsightsPage/types';
import { defaultFormData } from 'pages/TicketIntegrationPage/types';
import {
  IncidentRecommendationStatusLabels,
  SERVICE_NOW_STATUS_LABELS,
  ServiceNowStatus,
  TICKET_RECOMMENDATION_STATUS,
} from 'constants/workflowIntegrations';
import { ComparisonListType } from 'types/dashboard';
import { BUTTON_SIZE } from 'constants/appearance';
import { QUERY_FIELDS, REQUEST_STATUS } from 'constants/requestBody';
import { UNKNOWN_VALUE } from 'constants/utilityConstants';
import { SortType } from 'types/dataTypes';
import { getStatusTextClassName } from 'utils/workflowIntegrations';
import { createSnowIncident, getTicketConnection } from 'utils/services';
import { exportToExcel } from 'utils/exportToExcel';
import { LoadingIcon } from 'assets/icons';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { onApiCallError } from 'utils/handleErrors';

import { PurchaseSavingPlansType } from '../AwsSavingsPlans/components/PurchaseSavingPlans/types';
import { PurchaseReservedInstanceType } from '../ReservedInstances/types';
import { getRecommendationTableExcelData } from './utils';
import { isPurchaseEnabled } from './constants';

import './index.scss';

type RecommendationsWrapperProps = {
  controlFilters: ControlFilterType[];
  purchaseRecommendationCtaTitle: string;
  columns: any[];
  filterValues: any;
  fetchRecommendationData: (
    setData: (val: any[]) => void,
    setMetricCards: (val: ComparisonListType[]) => void,
    setReqStatus: (val: string) => void
  ) => void;
  onClickPurchase: (
    val: any[],
    setPurchaseRequestStatus: (val: string) => void,
    setPurchaseOfferingDetails: (
      val: (PurchaseReservedInstanceType | PurchaseSavingPlansType)[]
    ) => void
  ) => void;
  purchaseOfferings: (
    val: (PurchaseReservedInstanceType | PurchaseSavingPlansType)[],
    setPurchaseRequestStatus: (val: string) => void,
    setData: (val: any[]) => void,
    setMetricCards: (val: ComparisonListType[]) => void,
    setReqStatus: (val: string) => void
  ) => void;
  heading: string;
  snowTicketIdentifier: string;
  snowStatusColumnIndex?: number;
};

const RecommendationsWrapper = ({
  controlFilters,
  purchaseRecommendationCtaTitle,
  columns,
  filterValues,
  fetchRecommendationData,
  onClickPurchase,
  purchaseOfferings,
  heading,
  snowStatusColumnIndex = 2,
  snowTicketIdentifier,
}: RecommendationsWrapperProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection } = useSelector(selectPurchase);
  const { theme } = useSelector(selectTheme);
  const { permissions } = useSelector(userAuthorization);
  const { serviceNowData } = useSelector(ticketIntegration);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [fetchingDefaultTicketData, setFetchingDefaultTicketData] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [selectedSnowStatus, setSelectedSnowStatus] = useState(
    ServiceNowStatus.TICKET_TBC
  );
  const [recommendationData, setRecommendationData] = useState<any[]>([]);
  const [metricCards, setMetricCards] = useState<ComparisonListType[]>([]);
  const [selectedRecommendations, setSelectedRecommendations] = useState<any[]>(
    []
  );
  const [statusFilters, setStatusFilters] = useState<any>({
    incidentStatus: [],
    incidentRecommendationStatus: [],
  });
  const [sortApplied, setSortApplied] = useState<SortType>({
    key: '',
    order: undefined,
  });
  const [recommendationTableData, setRecommendationTableData] = useState<any[]>(
    []
  );
  const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.SUCCESS);
  const [showCreateTicketDrawer, setShowCreateTicketDrawer] = useState(false);
  const [createTicketLoading, setCreateTicketLoading] = useState(false);
  const [createTicketValidationMessage, setCreateTicketValidationMessage] =
    useState('');
  const [selectedRecommendation, setSelectedRecommendation] = useState<any>();
  const [showTicketInfoDrawer, setShowTicketInfoDrawer] = useState(false);
  const [purchaseRequestStatus, setPurchaseRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isDownloadingData, setIsDownloadingData] = useState(false);
  const [purchaseOfferingDetails, setPurchaseOfferingDetails] = useState<
    (PurchaseReservedInstanceType | PurchaseSavingPlansType)[]
  >([]);
  const [showPurchaseConfirmModal, setShowPurchaseConfirmModal] =
    useState(false);

  useEffect(() => {
    fetchServiceNowConnection();
  }, []);

  useEffect(() => {
    if (purchaseOfferingDetails?.length) {
      setShowPurchaseConfirmModal(true);
    }
  }, [purchaseOfferingDetails]);

  useEffect(() => {
    if (selectedConnection) {
      fetchRecommendationData(
        setRecommendationData,
        setMetricCards,
        setRequestStatus
      );
      setSelectedRecommendations([]);
    }
  }, [selectedConnection, filterValues]);

  useEffect(() => {
    const data = filterRecommendationTableDataSource(
      recommendationData,
      statusFilters,
      selectedSnowStatus
    );
    setRecommendationTableData(data);
  }, [recommendationData, statusFilters, selectedSnowStatus]);

  /**
   * @function fetchServiceNowConnection
   * @description Function to fetch the service now connection details.
   */
  const fetchServiceNowConnection = () => {
    setFetchingDefaultTicketData(REQUEST_STATUS.PROCESSING);
    getTicketConnection()
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res.data.responseData;
          dispatch(
            setTicketIntegrationData({
              url: data.endpoint,
              usernameHost: data.username,
              password: data.password,
              setDefault: data.default,
              defaultAssignmentGroup: data.defaultAssigneeGroup,
              defaultAssignedTo: data.defaultAssignTo,
              category: data.defaultCategory,
            })
          );
          setFetchingDefaultTicketData(REQUEST_STATUS.SUCCESS);
          return;
        }
        dispatch(setTicketIntegrationData(defaultFormData));
        setFetchingDefaultTicketData(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchingDefaultTicketData);
        dispatch(setTicketIntegrationData(defaultFormData));
      });
  };

  /**
   * @function filterRecommendationTableDataSource
   * @description Function that filters table data on the frontend for service now status filters
   * @returns Recommendation table data filtered based on the snow filters
   */
  const filterRecommendationTableDataSource = (
    recDataToBeFiltered: any[],
    filters: StatusFilterType,
    selectedStatusNav: string
  ) => {
    let recommendationTableFilteredData = [...recDataToBeFiltered];

    let status = [selectedStatusNav];
    if (selectedStatusNav === ServiceNowStatus.TICKET_TBC) {
      status =
        filters.incidentStatus?.length > 0
          ? filters.incidentStatus
          : [selectedStatusNav, UNKNOWN_VALUE];
    }

    recommendationTableFilteredData = recommendationTableFilteredData.filter(
      (value) => status.includes(value.serviceNowStatus)
    );

    if (filters.incidentRecommendationStatus?.length) {
      recommendationTableFilteredData = recommendationTableFilteredData.filter(
        (value) =>
          ![
            ServiceNowStatus.RESOLVED.valueOf(),
            ServiceNowStatus.CLOSED.valueOf(),
          ].includes(value.serviceNowStatus) ||
          filters.incidentRecommendationStatus.includes(
            value?.incidentRecommendationStatus ?? ''
          )
      );
    }

    return recommendationTableFilteredData;
  };

  /**
   * @function onSelectTableRow
   * @description Callback function for row selection
   * @param record selected row data.
   * @param checked boolean value to indicate row selection or deselection
   */
  const onSelectTableRow = (record: any, checked: boolean) => {
    if (checked) {
      // Filter selections based on the snow status tab selected
      const filterSelectedRecoms = selectedRecommendations.filter((item) => {
        if (
          [ServiceNowStatus.RESOLVED, ServiceNowStatus.CLOSED].includes(
            selectedSnowStatus
          ) &&
          [ServiceNowStatus.RESOLVED, ServiceNowStatus.CLOSED].includes(
            item.serviceNowStatus
          )
        ) {
          return true;
        }

        return item.serviceNowStatus === selectedSnowStatus;
      });
      setSelectedRecommendations([...filterSelectedRecoms, record]);
      return;
    }

    setSelectedRecommendations([
      ...selectedRecommendations.filter((item) => item.id !== record.id),
    ]);
  };

  /**
   * @function onChangeSortApplied
   * @description Function to sort the data
   * @param key field against which the sorting is done.
   * @param order order of sorting, either ascending or descending
   */
  const onChangeSortApplied = (key: string, order: string | undefined) => {
    if (sortApplied.key === key && sortApplied.order === order) return;

    if (order === undefined) {
      setRecommendationTableData(recommendationData);
      return;
    }

    const data = [...recommendationData].sort((item1, item2) => {
      if (key === 'instance') {
        if (order === 'ascend')
          return item1.instance.localeCompare(item2.instance);
        return item2.instance.localeCompare(item1.instance);
      }
      // if (key === 'costSavings') {
      //   return numberArraySortFunction(
      //     item1.costSavings,
      //     item2.costSavings,
      //     order
      //   );
      // }
      // return compareToValue(
      //   item1[key as keyof RecommendationListType] as any,
      //   item2[key as keyof RecommendationListType] as any,
      //   key,
      //   order
      // )
      //   ? 1
      //   : -1;
      return -1;
    });
    setRecommendationData(data);
    setSortApplied({ key: key, order: order });
  };

  /**
   * @function isRecommendationTableLoading
   * @description Function to return a boolean value to for loading table
   * @returns boolean value true or false
   */
  const isRecommendationTableLoading = () => {
    return (
      recommendationTableData.length === 0 &&
      requestStatus === REQUEST_STATUS.PROCESSING
    );
  };

  /**
   * @function getStatusElement
   * @description Function to return a styled element for status text
   * @param text text to be displayed
   * @param key status key for style
   * @returns a span element with styled text
   */
  const getStatusElement = (text: string, key: string) => (
    <span className={`font-button status-text ${getStatusTextClassName(key)}`}>
      {text}
    </span>
  );

  /**
   * @function getFilterDropdown
   * @description Function to return the filter dropdown
   * @returns JSX element with filters
   */
  const getFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
  }: FilterDropdownProps) => (
    <TableFilterDropdown
      allKeys={filters ?? []}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      designVersion2={true}
    />
  );

  /**
   * @function getColumns
   * @description Function to return the list of table columns
   * @param snowStatus Status of the snow ticket for which the columns are returned
   * @returns List of columns
   */
  const getColumns = (snowStatus: ServiceNowStatus = selectedSnowStatus) => {
    const modifiedColumns: ColumnsType<any> = [...columns];

    if (
      [
        ServiceNowStatus.TICKET_TBC,
        ServiceNowStatus.RESOLVED,
        ServiceNowStatus.CLOSED,
      ].includes(snowStatus)
    ) {
      const snowStatusColumn: ColumnType<any> = {
        title: t('purchasePage.serviceNowStatus'),
        dataIndex:
          snowStatus === ServiceNowStatus.TICKET_TBC
            ? 'serviceNowStatus'
            : 'incidentRecommendationStatus',
        key:
          snowStatus === ServiceNowStatus.TICKET_TBC
            ? 'serviceNowStatus'
            : 'incidentRecommendationStatus',
        width: 240,
        render: (_text: string, record: any) => {
          return (
            <div className="flex flex-gap-8">
              {record?.serviceNowStatus === ServiceNowStatus.TICKET_TBC &&
                getStatusElement(
                  SERVICE_NOW_STATUS_LABELS.find(
                    (item) => item.key === record.serviceNowStatus
                  )?.label ?? record.serviceNowStatus,
                  record.serviceNowStatus
                )}
              {[
                ServiceNowStatus.RESOLVED.valueOf(),
                ServiceNowStatus.CLOSED.valueOf(),
              ].includes(record?.serviceNowStatus) &&
                record?.incidentRecommendationStatus &&
                getStatusElement(
                  IncidentRecommendationStatusLabels.find(
                    (item) => item.key === record?.incidentRecommendationStatus
                  )?.label ?? record?.incidentRecommendationStatus,
                  record?.incidentRecommendationStatus
                )}
            </div>
          );
        },
        filters:
          snowStatus === ServiceNowStatus.TICKET_TBC
            ? [
                {
                  value: ServiceNowStatus.TICKET_TBC,
                  text: t('serviceNowLabels.ticketTBC'),
                },
                {
                  value: UNKNOWN_VALUE,
                  text: UNKNOWN_VALUE,
                },
              ]
            : IncidentRecommendationStatusLabels.map((item) => ({
                value: item.key,
                text: item.label,
              })),
        filterDropdown: getFilterDropdown,
        filterIcon: (
          <Icon iconName={ICONS.FILTER_3_FILL} dataTestId="snow-filter-cta" />
        ),
        sorter: true,
        showSorterTooltip: false,
      };
      modifiedColumns.splice(snowStatusColumnIndex, 0, snowStatusColumn);
    }

    if (snowStatus !== ServiceNowStatus.TICKET_TBC) {
      const ticketInfoColumn: ColumnType<any> = {
        title: t('purchasePage.ticketInfo'),
        dataIndex: 'ticketInfo',
        render: (_text: string, record: any) => (
          <Icon
            iconName={ICONS.RI_SHARE_BOX_LINE}
            color={theme.buttonIconColor}
            onClick={() => {
              setSelectedRecommendation(record);
              setShowTicketInfoDrawer(true);
            }}
          />
        ),
        width: 100,
        fixed: 'right',
        align: 'center',
      };

      modifiedColumns.push(ticketInfoColumn);
    }

    return modifiedColumns;
  };

  /**
   * @function onClickDownload
   * @description Callback function for downloading the recommendation data
   * @returns either pdf or excel
   */
  const onClickDownload = () => {
    setIsDownloadingData(true);
    exportToExcel(
      heading + ' ' + t('purchasePage.recommendations'),
      getRecommendationTableExcelData(
        selectedConnection?.name ?? '',
        SERVICE_NOW_STATUS_LABELS.map((status) => ({
          sheetName: status.tabTitle,
          recommendations: filterRecommendationTableDataSource(
            recommendationData,
            statusFilters,
            status.key
          ),
          columns: getColumns(status.key),
        }))
      )
    ).then(() => setIsDownloadingData(false));
  };

  /**
   * @function onClickRefresh
   * @description Callback function to refresh the data
   */
  const onClickRefresh = () => {
    fetchRecommendationData(
      setRecommendationData,
      setMetricCards,
      setRequestStatus
    );
    setSelectedRecommendations([]);
  };

  /**
   * @function onClickCreateTicket
   * @description Callback function for create ticket
   */
  const onClickCreateTicket = () => {
    setCreateTicketLoading(true);

    const isBulkTicket =
      serviceNowData.incidentType === INCIDENT_TYPES.BULK_INCIDENT;

    let requestBody: any = {
      connectorId: selectedConnection?.connectorId + snowTicketIdentifier,
      bulkTicket: selectedRecommendations?.length === 1 ? false : isBulkTicket,
      multipleTicket:
        selectedRecommendations?.length === 1 ? false : !isBulkTicket,
      assignTo: serviceNowData.assignedTo,
      category: serviceNowData.category,
      isRIAndSP: true,
    };

    requestBody = {
      ...requestBody,
      recomDetails: selectedRecommendations?.map((recommendation) => ({
        name: recommendation.id + snowTicketIdentifier,
        description: t('purchasePage.saveCostSnowDescription', {
          currencySymbol: currencySymbol,
          estimatedMonthlySavings: recommendation.estimatedMonthlySavings,
          quantity: recommendation.recommendedInstanceQuantityPurchase,
          instanceType: recommendation.instanceType,
        }),
        category: QUERY_FIELDS.COST_ALL_CAPS,
        targetResource: recommendation.id,
      })),
    };

    createSnowIncident(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setCreateTicketLoading(false);
          message.success(t('createTicketDrawer.ticketCreateSuccess'));
          setSelectedRecommendations([]);
          fetchRecommendationData(
            setRecommendationData,
            setMetricCards,
            setRequestStatus
          );
          setShowCreateTicketDrawer(false);
          return;
        }
        setCreateTicketLoading(false);
        setCreateTicketValidationMessage(
          res?.data?.message ?? t('createTicketDrawer.errorCreateTicket')
        );
      })
      .catch((e) => {
        onApiCallError(e);
        setCreateTicketLoading(false);
        setCreateTicketValidationMessage(
          e?.response?.data?.message ??
            t('createTicketDrawer.errorCreateTicket')
        );
      });
  };

  /**
   * @function getCreateTicketsCtaLabel
   * @description Function to return the label for create ticket cta
   * @returns String label
   */
  const getCreateTicketsCtaLabel = () => {
    switch (fetchingDefaultTicketData) {
      case REQUEST_STATUS.PROCESSING:
        return t('purchasePage.loading');

      case REQUEST_STATUS.SUCCESS:
        return t('purchasePage.createTicket');

      case REQUEST_STATUS.ERROR:
        return t('purchasePage.ticketIntegrationError');
    }
  };

  return (
    <div className="commitments-recommendation-wrapper flex flex-column flex-gap-24">
      <ControlComponent
        additionalClassName="commitments-recommendation-filter flex-fit"
        filters={controlFilters}
      />
      <div className="metric-cards flex flex-space-between">
        {metricCards.map((item, index) => (
          <ComparisonCard
            key={item.heading}
            index={index}
            value={item.value}
            heading={item.heading}
            valueMinimumFractionDigits={item.valueMinimumFractionDigits}
            valuePrefix={item.valuePrefix}
            valueSuffix={item.valueSuffix}
            comparisonValue={item.comparisonValue}
            comparisonFrom={item.comparisonFrom}
            requestStatus={item.requestStatus}
            errorMessage={item.errorMessage}
          />
        ))}
      </div>
      <div className="commitments-recommendations-container flex flex-column flex-gap-16">
        <div className="flex flex-align-items-center flex-space-between">
          <div className="form-header">{t('purchasePage.recommendations')}</div>
          <div className="flex flex-align-items-center flex-gap-24">
            {isDownloadingData ? (
              <Icon
                icon={LoadingIcon}
                className="rotate download-icon"
                color={theme.buttonIconColor}
              />
            ) : (
              <Icon
                iconName={ICONS.DOWNLOAD_2_LINE}
                color={theme.buttonIconColor}
                size={ICONS_SIZE.XL}
                onClick={onClickDownload}
                dataTestId="download-cta"
              />
            )}
            <Icon
              iconName={ICONS.REFRESH_LINE}
              color={theme.buttonIconColor}
              size={ICONS_SIZE.XL}
              onClick={onClickRefresh}
              dataTestId="refresh-cta"
            />
            {permissions.costControlWrite && (
              <>
                <Button
                  className={`create-ticket-cta ${
                    fetchingDefaultTicketData === REQUEST_STATUS.ERROR &&
                    'error'
                  }`}
                  title={getCreateTicketsCtaLabel()}
                  size={BUTTON_SIZE.SMALL}
                  onClick={() => setShowCreateTicketDrawer(true)}
                  disabled={
                    !selectedRecommendations.some(
                      (item) =>
                        item.serviceNowStatus === ServiceNowStatus.TICKET_TBC
                    )
                  }
                />
                <Button
                  title={purchaseRecommendationCtaTitle}
                  size={BUTTON_SIZE.SMALL}
                  loading={purchaseRequestStatus === REQUEST_STATUS.PROCESSING}
                  onClick={() =>
                    onClickPurchase(
                      selectedRecommendations,
                      setPurchaseRequestStatus,
                      setPurchaseOfferingDetails
                    )
                  }
                  disabled={
                    !selectedRecommendations.some((item) =>
                      [
                        ServiceNowStatus.RESOLVED,
                        ServiceNowStatus.CLOSED,
                      ].includes(item.serviceNowStatus)
                    )
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className="snow-status-tabs flex flex-align-items-center flex-gap-24">
          <div className="flex flex-column font-caption-bold">
            {t(
              'costOptimizationInsight.recommendationTableHeader.ticketStatus'
            )}
          </div>
          <RadioGroup
            options={SERVICE_NOW_STATUS_LABELS.map((status) => ({
              label: status.tabTitle,
              value: status.key,
            }))}
            onChange={(e: any) => setSelectedSnowStatus(e.target.value)}
            value={selectedSnowStatus}
            optionType="button"
            rootClassName="no-custom-style status-tabs font-caption-bold"
            style={{ height: 28 }}
          />
        </div>
        <Table
          rootClassName="recommendations-table"
          rowSelection={{
            type: 'checkbox',
            hideSelectAll: true,
            getCheckboxProps: (record: any) => {
              return {
                disabled:
                  !permissions.costControlWrite ||
                  [
                    ServiceNowStatus.NEW,
                    ServiceNowStatus.IN_PROGRESS,
                    ServiceNowStatus.ON_HOLD,
                  ].includes(record.serviceNowStatus) ||
                  record.incidentRecommendationStatus ===
                    TICKET_RECOMMENDATION_STATUS.DISAPPROVED,
              };
            },
            onSelect: onSelectTableRow,
            selectedRowKeys: selectedRecommendations.map((item) => item.id),
          }}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          tableLoading={isRecommendationTableLoading()}
          pagination={false}
          dataSource={recommendationTableData.map((value) => ({
            ...value,
            key: value.id,
          }))}
          columns={getColumns()}
          sortDirections={['ascend', 'descend', 'ascend']}
          onChange={(
            _newPagination: TablePaginationConfig,
            filters: any,
            sorter: any
          ) => {
            setStatusFilters({
              incidentStatus: filters?.serviceNowStatus?.filter((item: any) =>
                SERVICE_NOW_STATUS_LABELS.some(
                  (status) => status.key === item || item === UNKNOWN_VALUE
                )
              ),
              incidentRecommendationStatus:
                filters?.incidentRecommendationStatus?.filter((item: any) =>
                  IncidentRecommendationStatusLabels.some(
                    (status) => status.key === item
                  )
                ),
            });
            if (
              sortApplied.key !== sorter.field ||
              sortApplied.order !== sorter.order
            ) {
              onChangeSortApplied(sorter.field, sorter.order);
            }
          }}
          locale={{
            emptyText: !isRecommendationTableLoading() && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  requestStatus === REQUEST_STATUS.ERROR
                    ? t('graphErrorMessage')
                    : t('costOptimizationInsight.noRecommendations', {
                        ticketStatus: SERVICE_NOW_STATUS_LABELS.find(
                          (item) => item.key === selectedSnowStatus
                        )?.tabTitle,
                      })
                }
              />
            ),
          }}
          designVersion2
          fillContainer
          scroll={{
            y: 'inherit',
            scrollToFirstRowOnChange: false,
          }}
        />
      </div>
      {showCreateTicketDrawer && (
        <CreateTicketDrawer
          show={showCreateTicketDrawer}
          setShow={setShowCreateTicketDrawer}
          fetchServiceNowConnection={fetchServiceNowConnection}
          selectedRecommendationsLength={selectedRecommendations.length}
          onClickCreate={onClickCreateTicket}
          loading={createTicketLoading}
          validationMessage={createTicketValidationMessage}
        />
      )}
      {selectedRecommendation && showTicketInfoDrawer && (
        <TicketInfoDrawer
          ticket={selectedRecommendation}
          show={showTicketInfoDrawer}
          setShow={setShowTicketInfoDrawer}
        />
      )}
      <ConfirmModal
        show={showPurchaseConfirmModal}
        setShow={setShowPurchaseConfirmModal}
        title={t('purchasePage.purchaseConfirmLabel')}
        loadingYes={purchaseRequestStatus === REQUEST_STATUS.PROCESSING}
        onClickYes={() => {
          if (isPurchaseEnabled)
            purchaseOfferings(
              purchaseOfferingDetails,
              setPurchaseRequestStatus,
              setRecommendationData,
              setMetricCards,
              setRequestStatus
            );
          setPurchaseOfferingDetails([]);
          setSelectedRecommendations([]);
          setShowPurchaseConfirmModal(false);
        }}
        onClickNo={() => setShowPurchaseConfirmModal(false)}
        titleYes={t('purchasePage.ok')}
        titleNo={t('purchasePage.cancel')}
      />
    </div>
  );
};

export default RecommendationsWrapper;
