import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';

import Icon from 'components/Icon';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import {
  CUSTOM_DASHBOARD_MODE,
  FIELD_TYPE,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { CHART_TYPES, COLORS } from 'constants/graphConfig';
import {
  getColumnLabelByField,
  getFieldCategory,
} from 'pages/CustomDashboardPage/utils';
import {
  customDashboard,
  setCustomGroupAvailableFields,
  setCustomViewData,
  setSelectedChartIndex,
  setSelectedChartRow,
  setTableChartData,
} from 'redux/customDashboardSlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { PdfContent } from 'types/exportTypes';
import { ChartType, LayoutDesignsType } from 'types/dashboard';
import {
  generateGraphColors,
  getDashboardDatasourceName,
  getDashboardProvider,
} from 'utils/dashboardUtils';
import {
  insertIndex,
  numberCommaSeparator,
  replaceAllSpecialCharactersBy,
} from 'utils/dataFormatterUtils';
import { getCustomGroupAvailableFields } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

import CustomRowLayout from '../CustomRowLayout';
import CustomTableOrChartData from '../CustomTableOrChartData';
import { MOVE_DIRECTIONS } from './constants';

import './index.scss';

const CustomTablesOrGraphs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    customViewData,
    customDashBoardMode,
    dashboardType,
    tableChartData,
    selectedChartRow,
  } = useSelector(customDashboard);
  const { selectedDashboard, selectedConnection } =
    useSelector(selectDashboard);

  const [chartSliderValues, setChartSliderValues] = useState<{
    [key: string]: { x: number; y: number };
  }>({});

  useEffect(() => {
    dashboardType === MY_DASHBOARD_TYPES.GROUP &&
      customDashBoardMode !== CUSTOM_DASHBOARD_MODE.DEV &&
      fetchCustomGroupFieldMap();
    return () => {
      dispatch(setTableChartData({}));
    };
  }, []);

  useEffect(() => {
    let excelData: any[] = [];
    customViewData?.layoutDesigns?.forEach((layout) =>
      layout.charts.forEach((chart) => {
        tableChartData[`${layout.position}${chart.chartPosition}`]?.tableData &&
          excelData.push({
            sheetName:
              (chart.chartName ?? t('customDashboard.headerLabels.addATitle')) +
              `${layout.position}${chart.chartPosition}`,
            columns: [
              { header: '#', key: 'slNo', alignment: 'center' },
              ...(chart.tableQuery?.columns ?? []).map((column) => ({
                header: column.label,
                key: replaceAllSpecialCharactersBy(column.field),
              })),
            ],
            data: insertIndex(
              tableChartData[`${layout.position}${chart.chartPosition}`]
                .tableData || []
            ),
            filters: [
              {
                heading: t('excelExportLabels.connectionName'),
                value: getDashboardDatasourceName(selectedDashboard),
              },
            ],
          });
      })
    );
    dispatch(setExportToExcelData(excelData));
  }, [tableChartData]);

  /**
   * @function fetchCustomGroupFieldMap
   * @description Function to fetch the custom group field map.
   */
  const fetchCustomGroupFieldMap = () => {
    getCustomGroupAvailableFields()
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setCustomGroupAvailableFields(res?.data?.responseData));
        }
      })
      .catch((e) => onApiCallError(e, true));
  };

  /**
   * @function resetIndexOfLayoutDesigns
   * @description Function to reset the index of the layout designs.
   * @param layoutDesigns The layout designs array.
   * @returns The layout designs array with the index reset.
   */
  const resetIndexOfLayoutDesigns = (layoutDesigns: LayoutDesignsType[]) =>
    layoutDesigns.map((value, index) => {
      return {
        ...value,
        position: index,
      };
    });

  /**
   * @function duplicateLayout
   * @description Function to add a new layout which will be a duplicate of another layout.
   * @param chartTypes The layout type of the row which is to be duplicated. E.g. [30,70] or [70,30] etc.
   * @param rowPosition The row position/index of the layout where the new layout will be placed.
   */
  const duplicateLayout = (chartTypes: ChartType[], rowPosition: number) => {
    const charts: ChartType[] = [
      ...chartTypes.map((chart) => ({ ...chart, chartName: '' })),
    ];
    const layoutDesign: LayoutDesignsType = {
      position: customViewData?.layoutDesigns?.length || 0,
      charts: charts,
    };

    let layoutDesigns = [...(customViewData?.layoutDesigns || [])];
    layoutDesigns.splice(rowPosition, 0, layoutDesign);
    layoutDesigns = resetIndexOfLayoutDesigns(layoutDesigns);

    dispatch(setSelectedChartRow(rowPosition));
    dispatch(
      setCustomViewData({
        ...customViewData,
        layoutDesigns: layoutDesigns,
      })
    );
  };

  /**
   * @function moveRow
   * @description Function to move/swap a layout row with another row.
   * @param rowIndex The initial index position of the row to be moved.
   * @param moveDirection The initial index position of the row where it will be moved.
   */
  const moveRow = (rowIndex: number, moveDirection: MOVE_DIRECTIONS) => {
    let layoutDesigns = [...(customViewData?.layoutDesigns || [])];

    const newPosition =
      rowIndex + (moveDirection === MOVE_DIRECTIONS.MOVE_UP ? -1 : 1);
    [layoutDesigns[rowIndex], layoutDesigns[newPosition]] = [
      layoutDesigns[newPosition],
      layoutDesigns[rowIndex],
    ];
    layoutDesigns = resetIndexOfLayoutDesigns(layoutDesigns);

    dispatch(setSelectedChartRow(newPosition));
    dispatch(
      setCustomViewData({
        ...customViewData,
        layoutDesigns: layoutDesigns,
      })
    );
  };

  /**
   * @function deleteLayout
   * @description Function to delete a layout row from the dashboard.
   * @param index The row position/index of the layout which is to be deleted.
   */
  const deleteLayout = (index: number) => {
    let layoutDesigns: LayoutDesignsType[] = [
      ...(customViewData?.layoutDesigns || []),
    ];
    layoutDesigns.splice(index, 1);
    layoutDesigns = resetIndexOfLayoutDesigns(layoutDesigns);

    dispatch(
      setSelectedChartRow(
        index > layoutDesigns.length - 1 ? layoutDesigns.length - 1 : index
      )
    );
    dispatch(setSelectedChartIndex(0));
    dispatch(
      setCustomViewData({
        ...customViewData,
        layoutDesigns: layoutDesigns,
      })
    );
  };

  /**
   * @function setSliderValue
   * @description Function to set the slider value based on chart row and chart position.
   * @param value slider x and y value
   * @param chartRow chart row
   * @param chartPosition chart position
   */
  const setSliderValue = (
    value: { x: number; y: number },
    chartRow: number,
    chartPosition: number
  ) => {
    setChartSliderValues({
      ...chartSliderValues,
      [`${chartRow}${chartPosition}`]: value,
    });
  };

  const getPdfMetaData = () => {
    const provider = getDashboardProvider(selectedDashboard);
    return {
      viewName: customViewData?.viewName,
      fileName: selectedDashboard?.name!,
      heading: selectedDashboard?.name!,
      subtitle1: getDashboardDatasourceName(selectedDashboard) ?? '',
      subtitle2:
        dashboardType === MY_DASHBOARD_TYPES.GROUP
          ? ''
          : selectedConnection?.dataSourceType!,
      provider:
        dashboardType !== MY_DASHBOARD_TYPES.GROUP ? provider : undefined,
      groupProvider:
        dashboardType === MY_DASHBOARD_TYPES.GROUP ? provider : undefined,
    };
  };

  /**
   * @function getPdfLegendsForStackChart
   * @description Function to get pdf legends for stack chart.
   * sort data based on the metric.
   * This is done to get the legends in the same order as the antd graph component
   * @param chart selected chart
   * @param layout selected layout
   * @returns legends for stack chart
   */
  const getPdfLegendsForStackChart = (
    chart: ChartType,
    layout: LayoutDesignsType
  ) => {
    const dimensions =
      chart.chartQuery?.columns
        ?.filter((item) => chart?.chartQuery?.groupBy?.includes(item.field))
        .map((column) => ({
          title: getColumnLabelByField(column.field, chart),
          key: replaceAllSpecialCharactersBy(column.field),
          category: getFieldCategory(column.field),
        })) ?? [];

    const metrics =
      chart.chartQuery?.columns
        ?.filter((item) =>
          chart?.chartQuery?.aggregators?.some(
            (aggregator) => aggregator.label === item.field
          )
        )
        .map((column) => ({
          title: getColumnLabelByField(column.field, chart),
          key: replaceAllSpecialCharactersBy(column.field),
        })) ?? [];

    let chartData = [
      ...(tableChartData[`${layout.position}${chart.chartPosition}`]
        .chartData ?? []),
    ];
    if (
      !dimensions.some((dimension) => dimension.category === FIELD_TYPE.TIME) &&
      !chart.chartQuery?.orderBy?.filter((item) => item.label && item.sort)
        ?.length
    ) {
      if (
        !chart.chartQuery?.orderBy?.filter((item) => item.label && item.sort)
          ?.length
      ) {
        chartData.sort((a: any, b: any) => {
          return b[metrics[0]?.key] - a[metrics[0]?.key];
        });
      }
    }

    let allLegends = uniq(
      chartData.map((data) => data[dimensions.at(1)?.key ?? ''] as string)
    );
    const colors = generateGraphColors(allLegends.length);

    return allLegends
      .filter((legend) => legend)
      .map((data, index) => ({
        name: data,
        color: colors[index],
      }));
  };

  /**
   * @function getPdfChartContent
   * @description Function to get pdf graph element.
   * @param chart chart data
   * @param layout layout data
   * @returns chart view component
   */
  const getPdfChartContent = (chart: ChartType, layout: LayoutDesignsType) => {
    let content: PdfContent = {
      element: (
        <CustomTableOrChartData
          chart={chart}
          layout={layout}
          pdfView
          sliderValue={
            chartSliderValues[`${layout.position}${chart.chartPosition}`]
          }
        />
      ),
      contentType: chart.chartType,
      graphName: `custom-graph-${layout.position}${chart.chartPosition}`,
      graphHeaderOverride:
        chart.chartName ?? t('customDashboard.headerLabels.addATitle'),
    };
    if (
      !tableChartData[`${layout.position}${chart.chartPosition}`]?.chartData
        ?.length
    )
      return content;

    if (
      chart.chartType === CHART_TYPES.PIE_CHART ||
      chart.chartType === CHART_TYPES.DOUGHNUT_CHART ||
      chart.chartType === CHART_TYPES.BAR_CHART ||
      chart.chartType === CHART_TYPES.HORIZONTAL_BAR_CHART
    ) {
      const dimension = chart.chartQuery?.columns
        ?.filter((item) => chart?.chartQuery?.groupBy?.includes(item.field))
        .map((column) => replaceAllSpecialCharactersBy(column.field))
        .at(0);
      const colors = generateGraphColors(
        tableChartData[`${layout.position}${chart.chartPosition}`].chartData
          .length ?? 0
      );
      content.legends = tableChartData[
        `${layout.position}${chart.chartPosition}`
      ].chartData.map((data, index) => ({
        name: data[dimension ?? ''],
        color: colors[index],
      }));
    } else if (chart.chartType === CHART_TYPES.STACK_CHART) {
      content.legends = getPdfLegendsForStackChart(chart, layout);
    }
    return content;
  };

  /**
   * @function getPdfTableColumn
   * @description Function to get pdf table column data.
   * @returns pdf table column data.
   */
  const getPdfTableColumn = (chart: ChartType) => {
    const columnData = [];
    columnData.push({ header: '#', dataKey: 'index' });
    chart.tableQuery?.columns?.forEach((eachColumnData) => {
      columnData.push({
        header: eachColumnData.label,
        dataKey: eachColumnData.field,
      });
    });
    return columnData;
  };

  /**
   * @function getPdfTableBody
   * @description Function to get pdf table body data.
   * @returns pdf table body data.
   */
  const getPdfTableBody = (chart: ChartType, layout: LayoutDesignsType) => {
    return tableChartData[
      `${layout.position}${chart.chartPosition}`
    ]?.tableData?.map((tableData: any, index: number) => {
      const newObj: any = {};
      for (const key in tableData) {
        if (typeof tableData[key] === 'number') {
          newObj[key] = numberCommaSeparator(tableData[key]);
        } else {
          newObj[key] = tableData[key];
        }
      }
      return { ...newObj, index: index + 1 };
    });
  };

  /**
   * @function getPdfContent
   * @description Function to get pdf graph content.
   * @returns pdf content array.
   */
  const getPdfContent = () => {
    const pdfContents: PdfContent[] = [];
    customViewData?.layoutDesigns?.forEach((layout) => {
      layout?.charts?.forEach((chart: ChartType) => {
        if (chart.chartType !== CHART_TYPES.TABLE) {
          pdfContents.push({ ...getPdfChartContent(chart, layout) });
        } else {
          pdfContents.push({
            contentType: CHART_TYPES.TABLE,
            graphHeaderOverride:
              chart.chartName ?? t('customDashboard.headerLabels.addATitle'),
            graphName: `custom-graph-${layout.position}${chart.chartPosition}`,
            column: getPdfTableColumn(chart),
            body: getPdfTableBody(chart, layout),
            tableName:
              chart.chartName ?? t('customDashboard.headerLabels.addATitle'),
          });
        }
      });
    });
    return pdfContents;
  };

  return (
    <div
      className={`custom-tables-or-graphs ${
        customViewData?.layoutDesigns.length > 0 && 'has-layouts'
      }`}
    >
      <div
        className={`flex flex-column ${
          customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED &&
          'flex-gap-16'
        }`}
      >
        {customViewData?.layoutDesigns?.map((layout, index) => (
          <div
            className="flex flex-column"
            key={layout.position}
            ref={(ref) =>
              selectedChartRow === index &&
              ref &&
              ref.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
          >
            <CustomRowLayout layout={layout} setSliderValue={setSliderValue} />
            {customDashBoardMode === CUSTOM_DASHBOARD_MODE.DEV && (
              <div className="row-actions-wrapper">
                <div className="row-actions flex flex-gap-8">
                  <Icon
                    iconName={ICONS.FILE_COPY_LINE}
                    size={ICONS_SIZE.SM}
                    color={COLORS.colorRegentGrey}
                    onClick={() => {
                      duplicateLayout(layout.charts, index + 1);
                    }}
                  />
                  {layout.position !== 0 && (
                    <Icon
                      iconName={ICONS.ARROW_UP_S_LINE}
                      size={ICONS_SIZE.SM}
                      color={COLORS.colorRegentGrey}
                      onClick={() => {
                        moveRow(index, MOVE_DIRECTIONS.MOVE_UP);
                      }}
                    />
                  )}
                  {layout.position !==
                    customViewData?.layoutDesigns?.length - 1 && (
                    <Icon
                      iconName={ICONS.ARROW_DOWN_S_LINE}
                      size={ICONS_SIZE.SM}
                      color={COLORS.colorRegentGrey}
                      onClick={() => {
                        moveRow(index, MOVE_DIRECTIONS.MOVE_DOWN);
                      }}
                    />
                  )}
                  <Icon
                    iconName={ICONS.DELETE_BIN_LINE}
                    size={ICONS_SIZE.SM}
                    color={COLORS.colorRegentGrey}
                    onClick={() => {
                      deleteLayout(index);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
        {customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED && (
          <PdfDownloadComponent
            pdfContent={getPdfContent()}
            pdfMetaData={getPdfMetaData()}
          />
        )}
      </div>
    </div>
  );
};

export default CustomTablesOrGraphs;
