import i18n from 'i18n';
import { USER_STATUS } from 'constants/userConsole';

export const SWITCH_STATUS_OPTIONS = [
  {
    value: USER_STATUS.DEACTIVATED,
    label: i18n.t('editUserModal.deactivate'),
  },
  {
    value: USER_STATUS.ACTIVE,
    label: i18n.t('editUserModal.active'),
  },
];
