import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import DataSourceComponent from 'components/DataSourceComponent';
import DrawerComponent from 'components/DrawerComponent';
import { BUTTON_TYPE } from 'constants/appearance';
import {
  CUSTOM_DASHBOARD_TYPES,
  MY_DASHBOARD_TYPES,
  FIELD_TYPE,
  FieldSource,
} from 'constants/dashboard';
import { PROVIDER } from 'constants/cloudProviders';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { DASHBOARD_TYPES, REQUEST_STATUS } from 'constants/requestBody';
import { NUMERIC_CATEGORY } from 'constants/newCustomView';
import {
  defaultCustomDashboardDataSource,
  defaultCustomViewData,
  defaultSelectedChartIndex,
  defaultSelectedChartRow,
} from 'constants/defaultValues';
import { DASHBOARD } from 'pages/OverviewPage/components/ConnectionsDashboard/components/HorizontalNavigation/constants';
import {
  customDashboard,
  setCustomDashboardConnection,
  setCustomDashboardDataSource,
  setCustomDashboardGroup,
  setCustomGroupAvailableFields,
  setCustomViewData,
  setCustomViewDimensions,
  setCustomViewMetrics,
  setDashboardType,
  setSelectedDimensions,
  setSelectedMetrics,
  setSelectedChartIndex,
  setSelectedChartRow,
  setTagKeyValues,
  setTagMaps,
  setCustomDashboardIntegrationConnection,
} from 'redux/customDashboardSlice';
import {
  selectDashboard,
  setSelectedDashboardView,
} from 'redux/dashboardSlice';
import {
  AvailableCustomGroupFieldsType,
  AvailableFieldsType,
} from 'types/dashboard';
import {
  createAvailableFieldsTypeForGroup,
  getProviderForConnection,
} from 'utils/dashboardUtils';
import { onApiCallError } from 'utils/handleErrors';
import {
  getBillingMappingData,
  getCustomGroupAvailableFields,
  getLabels,
  getSystemBillingMappingData,
  getTagMappingData,
} from 'utils/services';

import {
  createCustomQuery,
  getAvailableFieldsForSnowflake,
  validateQueryAndGetAvailableFields,
} from './services';

type CustomViewDataSourceDrawerProps = {
  show: boolean;
  setShow: (val: boolean) => void;
};

const CustomViewDataSourceDrawer = ({
  show,
  setShow,
}: CustomViewDataSourceDrawerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedConnection,
    selectedDashboard,
    selectedGroupMetaData,
    selectedIntegrationConnection,
  } = useSelector(selectDashboard);
  const {
    dashboardType,
    customDashboardDataSource,
    customDashboardConnection,
    customDashboardGroup,
    isAddNewDashboard,
    customDashboardIntegrationConnection,
  } = useSelector(customDashboard);

  const [queryValidationMessage, setQueryValidationMessage] =
    useState<string>('');
  const [loadingValidateQuery, setLoadingValidateQuery] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    dispatch(setCustomDashboardDataSource(defaultCustomDashboardDataSource));
    if (isAddNewDashboard) {
      dispatch(setCustomDashboardConnection(null));
      dispatch(setCustomDashboardGroup(null));
      return;
    }

    switch (dashboardType) {
      case MY_DASHBOARD_TYPES.GROUP:
        dispatch(setCustomDashboardConnection(null));
        dispatch(setCustomDashboardIntegrationConnection(null));
        dispatch(setCustomDashboardGroup(selectedGroupMetaData));
        break;
      case MY_DASHBOARD_TYPES.SNOWFLAKE:
        dispatch(setCustomDashboardConnection(null));
        dispatch(setCustomDashboardGroup(null));
        dispatch(
          setCustomDashboardIntegrationConnection(selectedIntegrationConnection)
        );
        break;
      default:
        dispatch(setCustomDashboardGroup(null));
        dispatch(setCustomDashboardIntegrationConnection(null));
        dispatch(setCustomDashboardConnection(selectedConnection));
        break;
    }
  }, []);

  useEffect(() => {
    dispatch(setCustomDashboardDataSource(defaultCustomDashboardDataSource));
  }, [customDashboardConnection]);

  /**
   * @function onSelectDataSource
   * @description Function to update the connection data or the group data and set the values based on the dashboard type selected (Group or Connection or Transaction)
   */
  const onSelectDataSource = (datasource: any, typeOfDashboard: string) => {
    dispatch(
      setCustomDashboardDataSource({
        ...customDashboardDataSource,
        dataSet: '',
        dashBoardType: '',
      })
    );
    dispatch(setDashboardType(typeOfDashboard));
    switch (typeOfDashboard) {
      case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
      case MY_DASHBOARD_TYPES.IMPORTS:
        dispatch(setCustomDashboardGroup(null));
        dispatch(setCustomDashboardIntegrationConnection(null));
        dispatch(setCustomDashboardConnection(datasource));
        break;

      case MY_DASHBOARD_TYPES.GROUP:
        dispatch(setCustomDashboardConnection(null));
        dispatch(setCustomDashboardIntegrationConnection(null));
        dispatch(setCustomDashboardGroup(datasource));
        break;

      case MY_DASHBOARD_TYPES.SNOWFLAKE:
        dispatch(setCustomDashboardConnection(null));
        dispatch(setCustomDashboardGroup(null));
        dispatch(setCustomDashboardIntegrationConnection(datasource));
        break;
    }
  };

  /**
   * @function onSelectDatasetOrTypeOfDataset
   * @description Callback function for the dataset or type of dataset selection
   * @param dataset dataset selected
   * @param datasetType type of dataset selected (BILLING, RECOMMENDATION, CO2, etc.)
   */
  const onSelectDatasetOrTypeOfDataset = (
    dataset: string,
    datasetType: string
  ) => {
    if (dashboardType === MY_DASHBOARD_TYPES.SNOWFLAKE) {
      dispatch(
        setCustomDashboardDataSource({
          ...customDashboardDataSource,
          dataSet: dataset,
          dashBoardType: dashboardType,
        })
      );
      return;
    }
    if (dashboardType !== MY_DASHBOARD_TYPES.GROUP) {
      dispatch(
        setCustomDashboardDataSource({
          ...customDashboardDataSource,
          dataSet: dataset,
          dashBoardType: datasetType,
        })
      );
      return;
    }

    dispatch(
      setCustomDashboardDataSource({
        ...customDashboardDataSource,
        dataSet: '',
        dashBoardType: dataset,
      })
    );
  };

  /**
   * @function validateGroupConnectionsDatasetTypes
   * @description Function to validate the dataset type for the group selected
   * @returns boolean true if validation is successful else false
   */
  const validateGroupConnectionsDatasetTypes = () => {
    // Validate if all the connections in the group has the type of dataset selected
    if (!customDashboardGroup) {
      return false;
    }

    let hasDataset = false;
    switch (customDashboardDataSource.dashBoardType) {
      case DASHBOARD_TYPES.BILLING:
        hasDataset = customDashboardGroup.connectorDtos.every(
          (connection) => connection.wantBilling
        );
        break;
      case DASHBOARD_TYPES.RECOMMENDATIONS:
        hasDataset = customDashboardGroup.connectorDtos.every(
          (connection) => connection.wantRecommendations
        );
        break;
      case DASHBOARD_TYPES.CARBON_FOOTPRINT:
        hasDataset = customDashboardGroup.connectorDtos.every(
          (connection) => connection.wantCarbonFootprint
        );
        break;
    }

    return hasDataset;
  };

  const onChangeCustomQuerySwitch = (value: boolean) => {
    dispatch(
      setCustomDashboardDataSource({
        ...customDashboardDataSource,
        useCustomQuery: value,
      })
    );
  };

  const onChangeCustomQuery = (e: any) =>
    dispatch(
      setCustomDashboardDataSource({
        ...customDashboardDataSource,
        customQuery: e.target.value,
      })
    );

  /**
   * @function isContinueButtonDisabled
   * @description Function to validate if the continue button is disabled
   * @returns true if disabled else false
   */
  const isContinueButtonDisabled = () => {
    if (dashboardType === MY_DASHBOARD_TYPES.SNOWFLAKE) {
      return !customDashboardDataSource.dataSet;
    }
    if (
      dashboardType === MY_DASHBOARD_TYPES.GROUP &&
      customDashboardDataSource.dashBoardType &&
      validateGroupConnectionsDatasetTypes()
    ) {
      return false;
    }

    if (
      customDashboardConnection?.provider === PROVIDER.AZURE &&
      !customDashboardDataSource.dataSet
    ) {
      return true;
    }

    if (
      customDashboardDataSource.useCustomQuery &&
      customDashboardDataSource.customQuery &&
      customDashboardDataSource.customQuery.length > 0
    ) {
      return false;
    }
    return !(
      !customDashboardDataSource.useCustomQuery &&
      customDashboardDataSource.dataSet
    );
  };

  /**
   * @function fetchDimensionsAndTagMap
   * @description Function to fetch the billing mapping and tag mapping
   */
  const fetchDimensionsAndTagMap = () => {
    setLoadingValidateQuery(REQUEST_STATUS.PROCESSING);
    const labelParams = {
      connectorId: customDashboardConnection?.connectorId,
      dashBoardType: customDashboardDataSource.dashBoardType,
    };

    const requests = [
      getSystemBillingMappingData(),
      getBillingMappingData(),
      getTagMappingData(),
      getLabels(labelParams),
    ];

    Promise.all(requests)
      .then((responses: any[]) => {
        const systemDimensionList = responses[0]?.data?.responseData ?? [];
        const billingMapDimensionList =
          responses[1]?.data?.responseData?.content ?? [];
        const tags =
          responses[2]?.data?.responseData?.content?.filter((tagItem: any) =>
            tagItem?.cloudMapList?.some(
              (connection: any) =>
                connection.connectionId ===
                customDashboardConnection?.connectorId
            )
          ) ?? [];
        const tagKeyValues =
          getProviderForConnection(customDashboardConnection) === PROVIDER.AWS
            ? responses[3]?.data
            : [];

        dispatch(setTagMaps(tags));

        const allFields: AvailableFieldsType[] = [
          ...systemDimensionList.map((item: any) => {
            const fieldDetails = item.billingDimensionMap.find(
              (item: any) =>
                item.provider ===
                getProviderForConnection(customDashboardConnection)
            );
            return {
              name: item.dimensionName,
              field: fieldDetails?.billingField,
              category: fieldDetails?.fieldType,
              source: FieldSource.SYSTEM,
            };
          }),
          ...billingMapDimensionList.map((item: any) => {
            const fieldDetails = item.billingDimensionMap.find(
              (item: any) =>
                item.provider ===
                getProviderForConnection(customDashboardConnection)
            );
            return {
              name: item.dimensionName,
              field: fieldDetails?.billingField,
              category: fieldDetails?.fieldType,
              source: FieldSource.USER,
            };
          }),
          ...tags.map((item: any) => ({
            name: item.customTagName,
            field: item.customTagName,
            category: FIELD_TYPE.LITERAL,
            source: FieldSource.TAGS,
          })),
        ];

        dispatch(
          setCustomViewMetrics(
            allFields?.filter(
              (availableField: AvailableFieldsType) =>
                availableField.field &&
                availableField.category === FIELD_TYPE.NUMERIC
            )
          )
        );
        dispatch(
          setCustomViewDimensions(
            allFields?.filter(
              (availableField: AvailableFieldsType) =>
                availableField.field &&
                [
                  FIELD_TYPE.TIME.valueOf(),
                  FIELD_TYPE.LITERAL.valueOf(),
                ].includes(availableField.category)
            )
          )
        );
        dispatch(
          setTagKeyValues(
            tagKeyValues?.map((item: any) => ({
              key: item.field,
              value: item.rawField,
            })) ?? []
          )
        );

        dispatch(setCustomViewData(defaultCustomViewData));
        dispatch(setSelectedChartRow(defaultSelectedChartRow));
        dispatch(setSelectedChartIndex(defaultSelectedChartIndex));
        dispatch(setSelectedDashboardView(DASHBOARD.NEW_VIEW));
        dispatch(setSelectedDimensions([]));
        dispatch(setSelectedMetrics([]));
        dispatch(
          setCustomDashboardDataSource({
            ...customDashboardDataSource,
            availableFields: allFields ?? [],
            dashboardTitle: getCustomDashboardTitle(),
            useCustomQuery: false,
            customQueryId: '',
          })
        );
        navigate(NAVIGATION_MENU_PATH.CUSTOM_DASHBOARD);
      })
      .catch((e) => {
        onApiCallError(e, false, setLoadingValidateQuery);
      });
  };

  /**
   * @function onClickContinueAndValidateConnection
   * @description Callback function for the continue button click for single connection or import connection
   */
  const onClickContinueAndValidateConnection = () => {
    setLoadingValidateQuery(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: customDashboardConnection?.connectorId!,
      type: customDashboardDataSource.useCustomQuery
        ? CUSTOM_DASHBOARD_TYPES.CUSTOM
        : customDashboardDataSource.dashBoardType,
      database: customDashboardDataSource.dataSet,
    };

    const requestBody = {
      queryString: customDashboardDataSource.customQuery ?? '',
    };

    validateQueryAndGetAvailableFields(requestBody, params)
      .then((res: any) => {
        if (res?.status === 200) {
          const { data } = res;
          const { responseData } = data;
          dispatch(setCustomViewData(defaultCustomViewData));
          dispatch(setSelectedChartRow(defaultSelectedChartRow));
          dispatch(setSelectedChartIndex(defaultSelectedChartIndex));
          dispatch(
            setCustomViewMetrics(
              responseData?.filter(
                (availableField: AvailableFieldsType) =>
                  availableField.category === FIELD_TYPE.NUMERIC
              ) || []
            )
          );
          dispatch(
            setCustomViewDimensions(
              responseData?.filter(
                (availableField: AvailableFieldsType) =>
                  availableField.category !== FIELD_TYPE.NUMERIC
              ) || []
            )
          );
          dispatch(setSelectedDashboardView(DASHBOARD.NEW_VIEW));
          dispatch(setSelectedDimensions([]));
          dispatch(setSelectedMetrics([]));

          if (customDashboardDataSource.useCustomQuery) {
            uploadCustomQuery(responseData);
            return;
          }
          dispatch(
            setCustomDashboardDataSource({
              ...customDashboardDataSource,
              availableFields: responseData ?? [],
              dashboardTitle: getCustomDashboardTitle(),
              useCustomQuery: false,
              customQueryId: '',
            })
          );
          navigate(NAVIGATION_MENU_PATH.CUSTOM_DASHBOARD);
        } else {
          setQueryValidationMessage(res?.message);
          setLoadingValidateQuery(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, false, setLoadingValidateQuery);
        setQueryValidationMessage(e?.response?.data?.message);
      });
  };

  /**
   * @function onClickContinueIntegration
   * @description Callback function for the continue button click for integrations
   */
  const onClickContinueIntegration = () => {
    setLoadingValidateQuery(REQUEST_STATUS.PROCESSING);
    const params = {
      integrationId: customDashboardIntegrationConnection?.integrationId!,
      table: customDashboardDataSource.dataSet,
    };

    getAvailableFieldsForSnowflake(params)
      .then((res: any) => {
        if (res?.status === 200) {
          const { data } = res;
          const { responseData } = data;
          dispatch(setCustomViewData(defaultCustomViewData));
          dispatch(setSelectedChartRow(defaultSelectedChartRow));
          dispatch(setSelectedChartIndex(defaultSelectedChartIndex));
          dispatch(
            setCustomViewMetrics(
              responseData?.filter(
                (availableField: AvailableFieldsType) =>
                  availableField.category === FIELD_TYPE.NUMERIC
              ) || []
            )
          );
          dispatch(
            setCustomViewDimensions(
              responseData?.filter(
                (availableField: AvailableFieldsType) =>
                  availableField.category !== FIELD_TYPE.NUMERIC
              ) || []
            )
          );
          dispatch(setSelectedDashboardView(DASHBOARD.NEW_VIEW));
          dispatch(setSelectedDimensions([]));
          dispatch(setSelectedMetrics([]));

          dispatch(
            setCustomDashboardDataSource({
              ...customDashboardDataSource,
              availableFields: responseData ?? [],
              dashboardTitle: getCustomDashboardTitle(),
              useCustomQuery: false,
              customQueryId: '',
            })
          );
          navigate(NAVIGATION_MENU_PATH.CUSTOM_DASHBOARD);
        } else {
          setQueryValidationMessage(res?.message);
          setLoadingValidateQuery(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, false, setLoadingValidateQuery);
        setQueryValidationMessage(e?.response?.data?.message);
      });
  };

  /**
   * @function onClickContinueAndValidateGroup
   * @description Callback function for the continue button click for group connection
   */
  const onClickContinueAndValidateGroup = () => {
    setLoadingValidateQuery(REQUEST_STATUS.PROCESSING);

    getCustomGroupAvailableFields()
      .then((res: any) => {
        if (res?.status === 200) {
          const data: AvailableCustomGroupFieldsType[] =
            res?.data?.responseData;
          dispatch(setCustomViewData(defaultCustomViewData));
          dispatch(setSelectedChartRow(defaultSelectedChartRow));
          dispatch(setSelectedChartIndex(defaultSelectedChartIndex));
          dispatch(
            setCustomViewMetrics(
              data
                ?.filter(
                  (availableField: AvailableCustomGroupFieldsType) =>
                    availableField.category === NUMERIC_CATEGORY
                )
                ?.map((availableField: AvailableCustomGroupFieldsType) => {
                  return createAvailableFieldsTypeForGroup(availableField);
                })
            )
          );
          dispatch(
            setCustomViewDimensions(
              data
                .filter(
                  (availableField: AvailableCustomGroupFieldsType) =>
                    availableField.category !== NUMERIC_CATEGORY
                )
                .map((availableField: AvailableCustomGroupFieldsType) => {
                  return createAvailableFieldsTypeForGroup(availableField);
                }) || []
            )
          );
          dispatch(setCustomGroupAvailableFields(data));
          dispatch(setSelectedDashboardView(DASHBOARD.NEW_VIEW));
          dispatch(setSelectedDimensions([]));
          dispatch(setSelectedMetrics([]));

          dispatch(
            setCustomDashboardDataSource({
              ...customDashboardDataSource,
              availableFields:
                data.map((availableField: AvailableCustomGroupFieldsType) => {
                  return createAvailableFieldsTypeForGroup(availableField);
                }) || [],
              dashboardTitle: getCustomDashboardTitle(),
              useCustomQuery: false,
              customQueryId: '',
            })
          );
          navigate(NAVIGATION_MENU_PATH.CUSTOM_DASHBOARD);
        } else {
          setQueryValidationMessage(res?.message);
          setLoadingValidateQuery(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, false, setLoadingValidateQuery);
        setQueryValidationMessage(e?.response?.data?.message);
      });
  };

  /**
   * @function uploadCustomQuery
   * @description Function to upload the custom query
   * @param availableFields list of available fields
   */
  const uploadCustomQuery = (availableFields: AvailableFieldsType[]) => {
    const params = {
      connectorId: customDashboardConnection?.connectorId!,
    };

    const requestBody = {
      queryString: customDashboardDataSource.customQuery,
    };
    createCustomQuery(requestBody, params)
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(
            setCustomDashboardDataSource({
              ...customDashboardDataSource,
              availableFields: availableFields ?? [],
              dashboardTitle: getCustomDashboardTitle(),
              customQueryId: res?.data?.responseData,
              dashBoardType: CUSTOM_DASHBOARD_TYPES.CUSTOM,
            })
          );

          setLoadingValidateQuery(REQUEST_STATUS.SUCCESS);
          navigate(NAVIGATION_MENU_PATH.CUSTOM_DASHBOARD);
        } else {
          setLoadingValidateQuery(REQUEST_STATUS.ERROR);
          message.error(
            t('dashboardLabels.connectToDataSourceModalLabels.errorUploadQuery')
          );
        }
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setLoadingValidateQuery,
          t('dashboardLabels.connectToDataSourceModalLabels.errorUploadQuery')
        );
      });
  };

  const getCustomDashboardTitle = () => {
    return isAddNewDashboard ? '' : selectedDashboard?.name ?? '';
  };

  const onHandleValidateAndContinue = () => {
    if (dashboardType === MY_DASHBOARD_TYPES.GROUP) {
      onClickContinueAndValidateGroup();
      return;
    }
    if (dashboardType === MY_DASHBOARD_TYPES.SNOWFLAKE) {
      onClickContinueIntegration();
      return;
    }
    if (
      dashboardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION &&
      customDashboardConnection?.billingDataset ===
        customDashboardDataSource.dataSet &&
      !customDashboardDataSource.useCustomQuery
    ) {
      fetchDimensionsAndTagMap();
      return;
    }
    onClickContinueAndValidateConnection();
  };

  return (
    <DrawerComponent
      className="datasource-modal"
      open={show}
      width={600}
      title={t(
        'dashboardLabels.connectToDataSourceModalLabels.connectToDataSource'
      )}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-align-items-center flex-end">
          <Button
            title={t('dashboardLabels.connectToDataSourceModalLabels.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={
              dashboardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION ||
              dashboardType === MY_DASHBOARD_TYPES.IMPORTS
                ? t(
                    'dashboardLabels.connectToDataSourceModalLabels.validateAndContinue'
                  )
                : t('dashboardLabels.connectToDataSourceModalLabels.continue')
            }
            onClick={onHandleValidateAndContinue}
            disabled={isContinueButtonDisabled()}
            loading={loadingValidateQuery === REQUEST_STATUS.PROCESSING}
          />
        </div>
      }
    >
      <DataSourceComponent
        showConnections={isAddNewDashboard}
        dashboardType={dashboardType}
        connection={customDashboardConnection}
        integrationConnection={customDashboardIntegrationConnection}
        group={customDashboardGroup}
        datasource={customDashboardDataSource}
        onSelectDataSource={onSelectDataSource}
        onSelectDatasetOrTypeOfDataset={onSelectDatasetOrTypeOfDataset}
        onChangeCustomQuerySwitch={onChangeCustomQuerySwitch}
        onChangeCustomQuery={onChangeCustomQuery}
        validationMessage={queryValidationMessage}
      />
    </DrawerComponent>
  );
};

export default CustomViewDataSourceDrawer;
