import { PROVIDER } from 'constants/cloudProviders';
import {
  FOCUS_SCHEMA_SUPPORTED_FILE_TYPE_BY_PROVIDER,
  STANDARD_SCHEMA_SUPPORTED_FILE_TYPE_BY_PROVIDER,
} from './constants';

/**
 * @function getSaticFilesAllowedFileTypes
 * @description returns the file type based on the provider and schema used
 * @param provider Provider for which the file format is fetched
 * @param isFocusSchema Boolean to indicate if the FOCUS schema is being used
 * @param isStandardSchema Boolean to indicate if the Standard schema is being used
 * @return Returns an object containing the format and the label
 */
export const getSaticFilesAllowedFileTypes = (
  provider: string,
  isFocusSchema: boolean,
  isStandardSchema: boolean
) => {
  if (isFocusSchema) {
    return FOCUS_SCHEMA_SUPPORTED_FILE_TYPE_BY_PROVIDER[provider as PROVIDER];
  }

  if (isStandardSchema) {
    return STANDARD_SCHEMA_SUPPORTED_FILE_TYPE_BY_PROVIDER[
      provider as PROVIDER
    ];
  }

  return {
    extensions: ['.csv', '.parquet'],
    label: 'CSV/Parquet',
  };
};
