import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';
import { GroupRequestType } from './types';

/**
 * @function createGroup
 * @description Function to create new Group
 * @param body body for post request.
 * @return axios response from POST request
 */
export const createGroup = async (body: GroupRequestType) => {
  return ServiceCalls.post(APIS.CREATE_GROUP, body);
};

/**
 * @function updateGroup
 * @description Function to update a Group
 * @param body body for post request.
 * @return axios response from POST request
 */
export const updateGroup = async (body: GroupRequestType) => {
  return ServiceCalls.post(APIS.UPDATE_GROUP, body);
};

/**
 * @function deleteGroup
 * @description Function to delete a Group
 * @param groupName path variable groupName for delete request.
 * @return axios response from DELETE request
 */
export const deleteGroup = async (groupName: string) => {
  const requestApi = APIS.DELETE_GROUP + groupName;
  return ServiceCalls.delete(requestApi);
};

/**
 * @function fetchGroups
 * @description Function to fetch all Groups
 * @param params request params for GET request.
 * @return axios response from GET request
 */
export const fetchGroups = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_ALL_GROUPS, null, params);
};

/**
 * @function searchGroupData
 * @description Function to search all Groups
 * @param params request params for GET request.
 * @return axios response from GET request
 */
export const searchGroupData = async (params: any) => {
  return ServiceCalls.get(APIS.GET_SEARCH_GROUP, null, params);
};
