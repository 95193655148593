import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectScorecard } from 'redux/scorecardSlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import {
  PURCHASE_TACTICS_TAB,
  SAVING_PLAN_REPORT_TYPES,
} from 'pages/ScorecardPage/constants';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MonthValueType } from 'types/dataTypes';
import {
  getDateMonthShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import { getGCPCoverageAndUtilizationDetails } from 'pages/ScorecardPage/services';
import { getReportType } from 'pages/ScorecardPage/utils';

import CoverageUtilizationGraph from '../CoverageUtilizationGraph';
import { GCP_CUD_GRAPHS, GCP_GRAPHS_TO_SERVICES_MAP } from './constants';
import { UtilizationCoverageExportColumns } from '../../constants';
import { getGcpCudExcelData } from './utils';

const CommittedUsageDiscount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, selectedPurchaseTacticsTab } =
    useSelector(selectScorecard);
  const { tableViewEnabled, showExpandGraphModal, expandedGraphName } =
    useSelector(selectDashboard);

  // Cloud SQL
  const [cloudSqlMonthlyCoverage, setCloudSqlMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [cloudSqlMonthlyUtilization, setCloudSqlMonthlyUtilization] = useState<
    MonthValueType[]
  >([]);
  const [cloudSqlTableView, setCloudSqlTableView] = useState(false);
  const [cloudSqlReportType, setCloudSqlReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );

  // Cloud Spanner
  const [cloudSpannerMonthlyCoverage, setCloudSpannerMonthlyCoverage] =
    useState<MonthValueType[]>([]);
  const [cloudSpannerMonthlyUtilization, setCloudSpannerMonthlyUtilization] =
    useState<MonthValueType[]>([]);
  const [cloudSpannerTableView, setCloudSpannerTableView] = useState(false);
  const [cloudSpannerReportType, setCloudSpannerReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );

  // Compute
  const [computeMonthlyCoverage, setComputeMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [computeMonthlyUtilization, setComputeMonthlyUtilization] = useState<
    MonthValueType[]
  >([]);
  const [computeTableView, setComputeTableView] = useState(false);
  const [computeReportType, setComputeReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );

  const [
    cudCoverageUtilizationRequestStatus,
    setCudCoverageUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  useEffect(() => {
    if (selectedConnection?.wantBilling) {
      fetchCoverageAndUtilizationDetails();
    }
  }, [selectedConnection]);

  useEffect(() => {
    setCloudSqlTableView(tableViewEnabled);
    setCloudSpannerTableView(tableViewEnabled);
    setComputeTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    if (
      selectedConnection &&
      selectedPurchaseTacticsTab ===
        PURCHASE_TACTICS_TAB.COMMITTED_USAGE_DISCOUNT
    ) {
      dispatch(
        setExportToExcelData(
          getGcpCudExcelData(
            getExcelExportData(
              GCP_CUD_GRAPHS.CLOUD_SQL,
              t('scorecard.committedUsageDiscount.cloudSql')
            ),
            getExcelExportData(
              GCP_CUD_GRAPHS.CLOUD_SPANNER,
              t('scorecard.committedUsageDiscount.cloudSpanner')
            ),
            getExcelExportData(
              GCP_CUD_GRAPHS.COMPUTE,
              t('scorecard.committedUsageDiscount.compute')
            ),
            selectedConnection
          )
        )
      );
    }
  }, [
    selectedConnection,
    selectedPurchaseTacticsTab,
    cloudSqlMonthlyUtilization,
    cloudSqlMonthlyCoverage,
    cloudSpannerMonthlyUtilization,
    cloudSpannerMonthlyCoverage,
    computeMonthlyUtilization,
    computeMonthlyCoverage,
  ]);

  /**
   * @function fetchCoverageAndUtilizationDetails
   * @description Function to fetch the coverage and utilization details
   */
  const fetchCoverageAndUtilizationDetails = () => {
    setCudCoverageUtilizationRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      startDate: moment()
        .subtract(11, 'months')
        .format(YEAR_MONTH_WITHOUT_SEPARATOR),
      endDate: moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
      granularity: 'MONTHLY',
    };

    getGCPCoverageAndUtilizationDetails(params)
      .then((res: any) => {
        const data = res?.data?.responseData || [];
        const dateLabels = getDateMonthShortList(
          moment().subtract(11, 'months').format(HYPHEN_DATE_FORMAT),
          moment().format(HYPHEN_DATE_FORMAT),
          'month',
          MONTH_YEAR_FORMAT
        );
        const cloudSqlUtilization: MonthValueType[] = [];
        const cloudSqlCoverage: MonthValueType[] = [];
        const cloudSpannerUtilization: MonthValueType[] = [];
        const cloudSpannerCoverage: MonthValueType[] = [];
        const computeUtilization: MonthValueType[] = [];
        const computeCoverage: MonthValueType[] = [];

        dateLabels.forEach((date) => {
          const currentDateData = data.filter(
            (item: any) =>
              moment(item.startTime).format(MONTH_YEAR_FORMAT) === date
          );

          pushCoverageAndUtilizationData(
            date,
            cloudSqlUtilization,
            cloudSqlCoverage,
            currentDateData,
            GCP_GRAPHS_TO_SERVICES_MAP.find(
              (item) => item.key === GCP_CUD_GRAPHS.CLOUD_SQL
            )?.services ?? []
          );

          pushCoverageAndUtilizationData(
            date,
            cloudSpannerUtilization,
            cloudSpannerCoverage,
            currentDateData,
            GCP_GRAPHS_TO_SERVICES_MAP.find(
              (item) => item.key === GCP_CUD_GRAPHS.CLOUD_SPANNER
            )?.services ?? []
          );

          pushCoverageAndUtilizationData(
            date,
            computeUtilization,
            computeCoverage,
            currentDateData,
            GCP_GRAPHS_TO_SERVICES_MAP.find(
              (item) => item.key === GCP_CUD_GRAPHS.CLOUD_SPANNER
            )?.services ?? []
          );
        });

        setCloudSqlMonthlyUtilization(cloudSqlUtilization);
        setCloudSqlMonthlyCoverage(cloudSqlCoverage);
        setCloudSpannerMonthlyUtilization(cloudSpannerUtilization);
        setCloudSpannerMonthlyCoverage(cloudSpannerCoverage);
        setComputeMonthlyUtilization(computeUtilization);
        setComputeMonthlyCoverage(computeCoverage);

        setCudCoverageUtilizationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCudCoverageUtilizationRequestStatus);
      });
  };

  /**
   * @function pushCoverageAndUtilizationData
   * @description Function to filter and add the coverage and utilization details into the respective parameters
   * @param param date Date for which the data is added
   * @param utilizationData Array for which the utilization data is added
   * @param coverageData Array for which the coverage data is added
   * @param data Data containing the all the coverage and utilization details
   * @param services services for which the data is filtered
   */
  const pushCoverageAndUtilizationData = (
    date: string,
    utilizationData: MonthValueType[],
    coverageData: MonthValueType[],
    data: any[],
    services: string[]
  ) => {
    const filteredUtilizationData = data.filter(
      (item) => services.includes(item.service) && item.utilization
    );

    const filteredCoverageData = data.filter(
      (item) => services.includes(item.service) && item.coverage
    );

    utilizationData.push({
      month: date,
      value:
        filteredUtilizationData.reduce(
          (sum, item) => sum + (item.utilization || 0),
          0
        ) / (filteredUtilizationData.length || 1),
    });

    coverageData.push({
      month: date,
      value:
        filteredCoverageData.reduce(
          (sum, item) => sum + (item.coverage || 0),
          0
        ) / (filteredCoverageData.length || 1),
    });
  };

  /**
   * @function getTrendGraphTitle
   * @description Function to return the trend graph title
   * @param reportType Type of report UTILIZATION or COVERAGE
   * @returns string title
   */
  const getTrendGraphTitle = (reportType: string) => {
    return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
      ? t('scorecard.committedUsageDiscount.cudUtilizationByMonth')
      : t('scorecard.committedUsageDiscount.cudCoverageByMonth');
  };

  /**
   * @function getGaugeGraphTitle
   * @description Function to return the gauge graph title
   * @param reportType Type of report UTILIZATION or COVERAGE
   * @returns string title
   */
  const getGaugeGraphTitle = (reportType: string) => {
    return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
      ? t('scorecard.committedUsageDiscount.cudMonthlyAverageUtilization')
      : t('scorecard.committedUsageDiscount.cudMonthlyAverageCoverage');
  };

  /**
   * @function getGraphData
   * @description Function to return the chart data
   * @param graph graph for which the data is fetched
   * @param reportType report type for which the data is fetched. UTILIZATION or COVERAGE
   * @returns list of coverage or utilization data
   */
  const getGraphData = (graph: string, reportType: string) => {
    switch (graph) {
      case GCP_CUD_GRAPHS.CLOUD_SQL:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? cloudSqlMonthlyUtilization
          : cloudSqlMonthlyCoverage;

      case GCP_CUD_GRAPHS.CLOUD_SPANNER:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? cloudSpannerMonthlyUtilization
          : cloudSpannerMonthlyCoverage;

      case GCP_CUD_GRAPHS.COMPUTE:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? computeMonthlyUtilization
          : computeMonthlyCoverage;

      default:
        return [];
    }
  };

  /**
   * @function getAveragePercentage
   * @description Function to return the average percentage of utilization or coverage
   * @param data list of data for which the average is calculated
   * @returns average percentage data
   */
  const getAveragePercentage = (data: MonthValueType[]) => {
    return data.reduce((sum, item) => sum + item.value, 0) / (data.length || 1);
  };

  /**
   * @function getPdfTableData
   * @description Function to insert index and format data for pdf export
   * @param data Data to be manipulated
   * @returns list of data for pdf export
   */
  const getPdfTableData = (data: MonthValueType[]) => {
    return insertIndex(data).map((item) => ({
      ...item,
      value: numberCommaSeparator(item.value),
    }));
  };

  /**
   * @function getExcelExportData
   * @description Function to return the data for excel export
   * @param graph Graph for which the data is required
   * @param graphHeading heading of the graph
   * @returns Object containing the utilization and coverage data for excel export
   */
  const getExcelExportData = (graph: string, graphHeading: string) => {
    return {
      utilization: {
        sheetName: `${graphHeading} (${t(
          'scorecard.savingsTypes.utilization'
        )})`,
        ...getCoverageUtilizationData(graph).utilization,
      },
      coverage: {
        sheetName: `${graphHeading} (${t('scorecard.savingsTypes.coverage')})`,
        ...getCoverageUtilizationData(graph).coverage,
      },
    };
  };

  /**
   * @function tooltipFormulaeContent
   * @description Function to return the tooltip content formulae for calculating utilization or coverage
   * @param reportType type of report selected
   * @returns JSX Element containing the formulae for calculating utilization or coverage
   */
  const tooltipFormulaeContent = (reportType: string) => (
    <div className="table-typography flex flex-align-items-center flex-gap-4">
      <div className="font-button">
        {reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? t('scorecard.savingsTypes.utilization')
          : t('scorecard.savingsTypes.coverage')}
        =
      </div>
      <div>
        <span>
          {t('scorecard.committedUsageDiscount.commitmentCoveredCosts')}
        </span>
        <Divider className="formulae-divider" />
        <span>
          {reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
            ? t('scorecard.committedUsageDiscount.commitmentAtOnDemandRates')
            : t('scorecard.committedUsageDiscount.totalCostAtOnDemandRates')}
        </span>
      </div>
      <span>*100</span>
    </div>
  );

  /**
   * @function getCoverageUtilizationData
   * @description Function to return the utilization and coverage data combined
   * @param graph graph for which the data is required
   * @returns Object containing the utilization and coverage data
   */
  const getCoverageUtilizationData = (graph: string) => {
    return {
      utilization: {
        data: getGraphData(graph, SAVING_PLAN_REPORT_TYPES.UTILIZATION),
        averagePercentage: getAveragePercentage(
          getGraphData(graph, SAVING_PLAN_REPORT_TYPES.UTILIZATION)
        ),
        tooltipContent: tooltipFormulaeContent(
          SAVING_PLAN_REPORT_TYPES.UTILIZATION
        ),
      },
      coverage: {
        data: getGraphData(graph, SAVING_PLAN_REPORT_TYPES.COVERAGE),
        averagePercentage: getAveragePercentage(
          getGraphData(graph, SAVING_PLAN_REPORT_TYPES.COVERAGE)
        ),
        tooltipContent: tooltipFormulaeContent(
          SAVING_PLAN_REPORT_TYPES.COVERAGE
        ),
      },
    };
  };

  /**
   * @function getCoverageUtilizationGraphComponent
   * @description Function to return the chart component
   * @param graph graph for which the chart component is fetched
   * @param reportType report type for which the chart component is fetched. UTILIZATION or COVERAGE
   * @param pdfView Optional param if the component is for pdf view
   * @returns JSX element
   */
  const getCoverageUtilizationGraphComponent = (
    graph: string,
    reportType?: string,
    pdfView: boolean = false
  ) => {
    switch (graph) {
      case GCP_CUD_GRAPHS.CLOUD_SQL:
        return (
          <CoverageUtilizationGraph
            heading={t('scorecard.committedUsageDiscount.cloudSql')}
            graphName={GCP_CUD_GRAPHS.CLOUD_SQL}
            pdfView={pdfView}
            isTableView={cloudSqlTableView}
            setIsTableView={setCloudSqlTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[cudCoverageUtilizationRequestStatus]}
            selectedReportType={getReportType(cloudSqlReportType, reportType)}
            setSelectedReportType={setCloudSqlReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(cloudSqlReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(cloudSqlReportType, reportType)
            )}
          />
        );

      case GCP_CUD_GRAPHS.CLOUD_SPANNER:
        return (
          <CoverageUtilizationGraph
            heading={t('scorecard.committedUsageDiscount.cloudSpanner')}
            graphName={GCP_CUD_GRAPHS.CLOUD_SPANNER}
            pdfView={pdfView}
            isTableView={cloudSpannerTableView}
            setIsTableView={setCloudSpannerTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[cudCoverageUtilizationRequestStatus]}
            selectedReportType={getReportType(
              cloudSpannerReportType,
              reportType
            )}
            setSelectedReportType={setCloudSpannerReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(cloudSpannerReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(cloudSpannerReportType, reportType)
            )}
          />
        );

      case GCP_CUD_GRAPHS.COMPUTE:
        return (
          <CoverageUtilizationGraph
            heading={t('scorecard.committedUsageDiscount.compute')}
            graphName={GCP_CUD_GRAPHS.COMPUTE}
            pdfView={pdfView}
            isTableView={computeTableView}
            setIsTableView={setComputeTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[cudCoverageUtilizationRequestStatus]}
            selectedReportType={getReportType(computeReportType, reportType)}
            setSelectedReportType={setComputeReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(computeReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(computeReportType, reportType)
            )}
          />
        );
    }
  };

  if (selectedConnection && !selectedConnection.wantBilling) {
    return <div>{t('scorecard.noBillingAccess')}</div>;
  }

  return (
    <>
      <div className="flex flex-column flex-gap-16">
        {getCoverageUtilizationGraphComponent(GCP_CUD_GRAPHS.CLOUD_SQL)}
        {getCoverageUtilizationGraphComponent(GCP_CUD_GRAPHS.CLOUD_SPANNER)}
        {getCoverageUtilizationGraphComponent(GCP_CUD_GRAPHS.COMPUTE)}
      </div>
      {showExpandGraphModal &&
        selectedPurchaseTacticsTab ===
          PURCHASE_TACTICS_TAB.COMMITTED_USAGE_DISCOUNT && (
          <ExpandModal
            show={showExpandGraphModal}
            graphContent={getCoverageUtilizationGraphComponent(
              expandedGraphName
            )}
            width={'100%'}
          />
        )}

      {selectedPurchaseTacticsTab ===
        PURCHASE_TACTICS_TAB.COMMITTED_USAGE_DISCOUNT && (
        <PdfDownloadComponent
          pdfContent={[
            {
              element: getCoverageUtilizationGraphComponent(
                GCP_CUD_GRAPHS.CLOUD_SQL,
                SAVING_PLAN_REPORT_TYPES.UTILIZATION,
                true
              ),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: GCP_CUD_GRAPHS.CLOUD_SQL,
              isTableView: cloudSqlTableView,
              column: UtilizationCoverageExportColumns,
              body: getPdfTableData(
                getGraphData(
                  GCP_CUD_GRAPHS.CLOUD_SQL,
                  SAVING_PLAN_REPORT_TYPES.UTILIZATION
                )
              ),
              tableName: `${t(
                'scorecard.committedUsageDiscount.cloudSql'
              )} (${t('scorecard.savingsTypes.utilization')})`,
              tableFooterData: {
                totalTableData: [
                  '',
                  t('scorecard.committedUsageDiscount.monthlyAverage'),
                  `${numberCommaSeparator(
                    getAveragePercentage(
                      getGraphData(
                        GCP_CUD_GRAPHS.CLOUD_SQL,
                        SAVING_PLAN_REPORT_TYPES.UTILIZATION
                      )
                    )
                  )}%`,
                ],
              },
            },
            {
              element: getCoverageUtilizationGraphComponent(
                GCP_CUD_GRAPHS.CLOUD_SQL,
                SAVING_PLAN_REPORT_TYPES.COVERAGE,
                true
              ),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: GCP_CUD_GRAPHS.CLOUD_SQL,
              isTableView: cloudSqlTableView,
              column: UtilizationCoverageExportColumns,
              body: getPdfTableData(
                getGraphData(
                  GCP_CUD_GRAPHS.CLOUD_SQL,
                  SAVING_PLAN_REPORT_TYPES.COVERAGE
                )
              ),
              tableName: `${t(
                'scorecard.committedUsageDiscount.cloudSql'
              )} (${t('scorecard.savingsTypes.coverage')})`,
              tableFooterData: {
                totalTableData: [
                  '',
                  t('scorecard.committedUsageDiscount.monthlyAverage'),
                  `${numberCommaSeparator(
                    getAveragePercentage(
                      getGraphData(
                        GCP_CUD_GRAPHS.CLOUD_SQL,
                        SAVING_PLAN_REPORT_TYPES.COVERAGE
                      )
                    )
                  )}%`,
                ],
              },
            },
            {
              element: getCoverageUtilizationGraphComponent(
                GCP_CUD_GRAPHS.CLOUD_SPANNER,
                SAVING_PLAN_REPORT_TYPES.UTILIZATION,
                true
              ),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: GCP_CUD_GRAPHS.CLOUD_SPANNER,
              isTableView: cloudSpannerTableView,
              column: UtilizationCoverageExportColumns,
              body: getPdfTableData(
                getGraphData(
                  GCP_CUD_GRAPHS.CLOUD_SPANNER,
                  SAVING_PLAN_REPORT_TYPES.UTILIZATION
                )
              ),
              tableName: `${t(
                'scorecard.committedUsageDiscount.cloudSpanner'
              )} (${t('scorecard.savingsTypes.utilization')})`,
              tableFooterData: {
                totalTableData: [
                  '',
                  t('scorecard.committedUsageDiscount.monthlyAverage'),
                  `${numberCommaSeparator(
                    getAveragePercentage(
                      getGraphData(
                        GCP_CUD_GRAPHS.CLOUD_SPANNER,
                        SAVING_PLAN_REPORT_TYPES.UTILIZATION
                      )
                    )
                  )}%`,
                ],
              },
            },
            {
              element: getCoverageUtilizationGraphComponent(
                GCP_CUD_GRAPHS.CLOUD_SPANNER,
                SAVING_PLAN_REPORT_TYPES.COVERAGE,
                true
              ),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: GCP_CUD_GRAPHS.CLOUD_SPANNER,
              isTableView: cloudSpannerTableView,
              column: UtilizationCoverageExportColumns,
              body: getPdfTableData(
                getGraphData(
                  GCP_CUD_GRAPHS.CLOUD_SPANNER,
                  SAVING_PLAN_REPORT_TYPES.COVERAGE
                )
              ),
              tableName: `${t(
                'scorecard.committedUsageDiscount.cloudSpanner'
              )} (${t('scorecard.savingsTypes.coverage')})`,
              tableFooterData: {
                totalTableData: [
                  '',
                  t('scorecard.committedUsageDiscount.monthlyAverage'),
                  `${numberCommaSeparator(
                    getAveragePercentage(
                      getGraphData(
                        GCP_CUD_GRAPHS.CLOUD_SPANNER,
                        SAVING_PLAN_REPORT_TYPES.COVERAGE
                      )
                    )
                  )}%`,
                ],
              },
            },
            {
              element: getCoverageUtilizationGraphComponent(
                GCP_CUD_GRAPHS.COMPUTE,
                SAVING_PLAN_REPORT_TYPES.UTILIZATION,
                true
              ),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: GCP_CUD_GRAPHS.COMPUTE,
              isTableView: computeTableView,
              column: UtilizationCoverageExportColumns,
              body: getPdfTableData(
                getGraphData(
                  GCP_CUD_GRAPHS.COMPUTE,
                  SAVING_PLAN_REPORT_TYPES.UTILIZATION
                )
              ),
              tableName: `${t('scorecard.committedUsageDiscount.compute')} (${t(
                'scorecard.savingsTypes.utilization'
              )})`,
              tableFooterData: {
                totalTableData: [
                  '',
                  t('scorecard.committedUsageDiscount.monthlyAverage'),
                  `${numberCommaSeparator(
                    getAveragePercentage(
                      getGraphData(
                        GCP_CUD_GRAPHS.COMPUTE,
                        SAVING_PLAN_REPORT_TYPES.UTILIZATION
                      )
                    )
                  )}%`,
                ],
              },
            },
            {
              element: getCoverageUtilizationGraphComponent(
                GCP_CUD_GRAPHS.COMPUTE,
                SAVING_PLAN_REPORT_TYPES.COVERAGE,
                true
              ),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: GCP_CUD_GRAPHS.COMPUTE,
              isTableView: computeTableView,
              column: UtilizationCoverageExportColumns,
              body: getPdfTableData(
                getGraphData(
                  GCP_CUD_GRAPHS.COMPUTE,
                  SAVING_PLAN_REPORT_TYPES.COVERAGE
                )
              ),
              tableName: `${t('scorecard.committedUsageDiscount.compute')} (${t(
                'scorecard.savingsTypes.coverage'
              )})`,
              tableFooterData: {
                totalTableData: [
                  '',
                  t('scorecard.committedUsageDiscount.monthlyAverage'),
                  `${numberCommaSeparator(
                    getAveragePercentage(
                      getGraphData(
                        GCP_CUD_GRAPHS.COMPUTE,
                        SAVING_PLAN_REPORT_TYPES.COVERAGE
                      )
                    )
                  )}%`,
                ],
              },
            },
          ]}
          pdfMetaData={{
            viewName: t(
              'scorecard.purchaseTactics.tabs.committedUsageDiscount'
            ),
            fileName: selectedConnection?.name ?? '',
            heading: selectedConnection?.name ?? '',
            subtitle1: selectedConnection?.dataSourceType ?? '',
            provider: selectedConnection?.provider ?? '',
          }}
        />
      )}
    </>
  );
};

export default CommittedUsageDiscount;
