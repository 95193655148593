import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, message } from 'antd';
import { useDispatch } from 'react-redux';

import NavigationPath from 'components/NavigationPath';
import Button from 'components/Button';
import Table from 'components/Table';
import DeleteModal from 'components/DeleteModal';
import QuickActionMenu from 'components/QuickActionMenu';
import Icon from 'components/Icon';
import Pagination from 'components/Pagination';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { PROVIDER } from 'constants/cloudProviders';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { onApiCallError } from 'utils/handleErrors';
import { fetchConnectionData, getTagMappingData } from 'utils/services';
import { setAllConnectionsMap } from 'redux/dimensionMappingSlice';

import ExpandedTagMapRow from './components/ExpandedTagMapRow';
import AddOrEditTagMappingModal from './components/AddOrEditTagMappingModal';
import { TagMappingType } from './types';
import {
  TagMappingQuickActions,
  TAG_MAPPING_QUICK_ACTIONS_LIST,
} from './constants';
import { deleteTagMapping } from './services';

import './index.scss';

const TagMappingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tagMappingData, setTagMappingData] = useState<TagMappingType[]>([]);
  const [tagMapReqStatus, setTagMapReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [totalTagMapCount, setTotalTagMapCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [actionRecord, setActionRecord] = useState<TagMappingType>();
  const [showTagMapModal, setShowTagMapModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteRequestStatus, setDeleteRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [expandedRow, setExpandedRow] = useState(-1);
  const [fetchConnectionReqStatus, setFetchConnectionReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    addZeroMarginClass();
    fetchTagMapData();
    fetchConnectionList();

    return () => {
      removeZeroMarginClass();
      dispatch(setAllConnectionsMap([]));
    };
  }, []);

  /**
   * @function fetchTagMapData
   * @description Function to fetch the Tag map data
   * @param page pagination page for fetching the data
   */
  const fetchTagMapData = (page: number = currentPage) => {
    setTagMapReqStatus(REQUEST_STATUS.PROCESSING);

    const requestParams = {
      page: page,
      size: PAGINATION_SIZE,
    };

    getTagMappingData(requestParams)
      .then((res: any) => {
        setTagMappingData(res?.data?.responseData?.content ?? []);
        setTotalTagMapCount(res?.data?.responseData?.totalElements ?? 0);
        setTagMapReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTagMapReqStatus);
      });
  };

  /**
   * @function fetchConnectionList
   * @description Function to fetch the connection list
   */
  const fetchConnectionList = () => {
    setFetchConnectionReqStatus(REQUEST_STATUS.PROCESSING);

    fetchConnectionData()
      .then((res: any) => {
        dispatch(
          setAllConnectionsMap(
            res?.data?.responseData?.content
              ?.filter((item: any) => item.wantBilling)
              ?.map((item: any) => ({
                connection: item,
              })) ?? []
          )
        );
        setFetchConnectionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchConnectionReqStatus);
      });
  };

  /**
   * @function onDeleteTagMap
   * @description Function to delete the tag map
   * @param tagName tag name to be deleted
   */
  const onDeleteTagMap = (tagName: string) => {
    if (!tagName) return;

    setDeleteRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      tagName: tagName,
    };

    deleteTagMapping(params)
      .then((res: any) => {
        if (res?.status === 200) {
          fetchTagMapData();
          setCurrentPage(1);
          message.success(
            t('tagMapping.deleteSuccessMessage', {
              tagName: tagName,
            })
          );
          setDeleteRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setDeleteRequestStatus(REQUEST_STATUS.ERROR);
          message.error(
            t('tagMapping.deleteFailureMessage', {
              tagName: tagName,
            })
          );
        }
        setShowDeleteModal(false);
      })
      .catch((e: any) => {
        onApiCallError(
          e,
          true,
          setDeleteRequestStatus,
          t('tagMapping.deleteFailureMessage', {
            tagName: tagName,
          })
        );
        setShowDeleteModal(false);
      });
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions
   * @param record record for which the action is taken
   * @param action quick action clicked
   */
  const handleQuickAction = (record: TagMappingType, action: string) => {
    setActionRecord(record);

    if (action === TagMappingQuickActions.EDIT) {
      setShowTagMapModal(true);
    } else {
      setShowDeleteModal(true);
    }
  };

  const columns = [
    {
      title: t('tagMapping.tagName'),
      dataIndex: 'customTagName',
      key: 'customTagName',
      render: (text: string) => (
        <div className="tag-name flex flex-align-items-center flex-space-between">
          <span>{text}</span>
          <Icon className="arrow" iconName={ICONS.ARROW_RIGHT} />
        </div>
      ),
    },
    {
      title: (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={getProviderSmallLogo(PROVIDER.GCP)}
            alt={`${PROVIDER.GCP} Logo`}
          />
          <span>{t('tagMapping.connections')}</span>
          <Divider type="vertical" className="vertical-divider" />
          <span>{t('tagMapping.tags')}</span>
        </div>
      ),
      dataIndex: 'fieldMapping',
      key: 'fieldMapping',
      render: (_text: string, record: TagMappingType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <span>
            {record?.cloudMapList?.reduce((prev, curr) => {
              if (curr.providerName === PROVIDER.GCP) prev += 1;
              return prev;
            }, 0)}
          </span>
          <Divider type="vertical" className="vertical-divider" />
          <span>
            {record?.cloudMapList?.reduce((prev, curr) => {
              if (curr.providerName === PROVIDER.GCP)
                prev += curr.values.length;
              return prev;
            }, 0)}
          </span>
        </div>
      ),
    },
    {
      title: (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={getProviderSmallLogo(PROVIDER.AWS)}
            alt={`${PROVIDER.AWS} Logo`}
          />
          <span>{t('tagMapping.connections')}</span>
          <Divider type="vertical" className="vertical-divider" />
          <span>{t('tagMapping.tags')}</span>
        </div>
      ),
      dataIndex: 'fieldMapping',
      key: 'fieldMapping',
      render: (_text: string, record: TagMappingType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <span>
            {record?.cloudMapList?.reduce((prev, curr) => {
              if (curr.providerName === PROVIDER.AWS) prev += 1;
              return prev;
            }, 0)}
          </span>
          <Divider type="vertical" className="vertical-divider" />
          <span>
            {record?.cloudMapList?.reduce((prev, curr) => {
              if (curr.providerName === PROVIDER.AWS)
                prev += curr.values.length;
              return prev;
            }, 0)}
          </span>
        </div>
      ),
    },
    {
      title: (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={getProviderSmallLogo(PROVIDER.AZURE)}
            alt={`${PROVIDER.AZURE} Logo`}
          />
          <span>{t('tagMapping.connections')}</span>
          <Divider type="vertical" className="vertical-divider" />
          <span>{t('tagMapping.tags')}</span>
        </div>
      ),
      dataIndex: 'fieldMapping',
      key: 'fieldMapping',
      render: (_text: string, record: TagMappingType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <span>
            {record?.cloudMapList?.reduce((prev, curr) => {
              if (curr.providerName === PROVIDER.AZURE) prev += 1;
              return prev;
            }, 0)}
          </span>
          <Divider type="vertical" className="vertical-divider" />
          <span>
            {record?.cloudMapList?.reduce((prev, curr) => {
              if (curr.providerName === PROVIDER.AZURE)
                prev += curr.values.length;
              return prev;
            }, 0)}
          </span>
        </div>
      ),
    },
    {
      title: t('tagMapping.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: TagMappingType) => {
        return (
          <QuickActionMenu
            quickActions={TAG_MAPPING_QUICK_ACTIONS_LIST}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      align: 'center' as const,
      width: '10%',
    },
  ];

  const expandedTagsRowRender = (record: TagMappingType) => {
    return (
      <ExpandedTagMapRow
        tagMapData={record}
        setActionRecord={setActionRecord}
        setShowTagMapModal={setShowTagMapModal}
        fetchTagMapData={fetchTagMapData}
      />
    );
  };

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = ({ _expandable, expanded, onExpand, record }: any) => (
    <Icon
      className="collapse-icon"
      iconName={expanded ? ICONS.ARROW_DOWN_S_LINE : ICONS.ARROW_RIGHT_S_LINE}
      size={ICONS_SIZE.LG}
      onClick={(e) => onExpand(record, e)}
    />
  );

  return (
    <div className="tag-mapping flex flex-column flex-fit">
      <header className="new-page-header tag-mapping-header flex flex-align-items-center flex-space-between">
        <span className="modal-heading">{t('navigationMenu.tagMapping')}</span>
        <Button
          title={t('tagMapping.addNewField')}
          loading={fetchConnectionReqStatus === REQUEST_STATUS.PROCESSING}
          onClick={() => {
            setActionRecord(undefined);
            setShowTagMapModal(true);
          }}
        />
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="table-container margin-24 flex flex-column flex-fit">
          <Table
            pagination={false}
            data-testid="tag-mapping-table"
            rootClassName="table-section"
            dataSource={tagMappingData?.map((tagMap, index) => ({
              ...tagMap,
              key: index + 1,
            }))}
            columns={columns}
            rowClassName="cursor-pointer"
            expandable={{
              columnWidth: 30,
              expandedRowRender: expandedTagsRowRender,
              expandedRowKeys: [expandedRow],
              onExpand: (expanded: boolean, record: any) => {
                if (expanded) {
                  setExpandedRow(record.key);
                } else {
                  setExpandedRow(-1);
                }
              },
              expandRowByClick: true,
              expandIcon: getExpandIcon,
            }}
            scroll={{ y: '100%' }}
            loading={tagMapReqStatus === REQUEST_STATUS.PROCESSING}
            designVersion2={true}
            fillContainer={true}
          />
          {totalTagMapCount > PAGINATION_SIZE && (
            <Pagination
              rootClassName="pagination flex flex-align-items-end flex-fit"
              current={currentPage}
              onChange={(page: number) => {
                setCurrentPage(page);
                setTagMappingData([]);
                fetchTagMapData(currentPage);
              }}
              total={totalTagMapCount}
              defaultPageSize={PAGINATION_SIZE}
            />
          )}
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={() =>
            onDeleteTagMap(actionRecord?.customTagName ?? '')
          }
          loading={deleteRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
      {showTagMapModal && (
        <AddOrEditTagMappingModal
          show={showTagMapModal}
          setShow={setShowTagMapModal}
          isEditTagMapping={!!actionRecord}
          editData={actionRecord}
          onClickSubmit={() => {
            setCurrentPage(1);
            fetchTagMapData(1);
            setShowTagMapModal(false);
          }}
        />
      )}
    </div>
  );
};

export default TagMappingPage;
