import { useDispatch, useSelector } from 'react-redux';

import {
  selectReport,
  setReportOptions,
  setSelectedReportMetrics,
} from 'redux/reportSlice';
import Icon from 'components/Icon';
import { COLORS } from 'constants/graphConfig';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { ColumnType } from 'pages/ReportsPage/types';

type MetricListItemProps = {
  metric: ColumnType;
  dragHandleProps?: any;
};

const MetricListItem = ({ metric, dragHandleProps }: MetricListItemProps) => {
  const dispatch = useDispatch();
  const { selectedReportMetrics, reportOptions } = useSelector(selectReport);

  /**
   * @function onRemoveMetric
   * @description Removes the metric from the selected metrics list and updates the report data
   * @param metric - metric to be removed
   */
  const onRemoveMetric = (metric: string) => {
    const updatedMetrics = selectedReportMetrics.filter(
      (item) => item.field !== metric
    );
    const updatedColumns = [
      ...reportOptions.dimension,
      ...updatedMetrics.map((item) => item.label),
    ];
    const updatedSorts = reportOptions.sort.filter((item) =>
      updatedColumns.includes(item.label)
    );

    dispatch(setSelectedReportMetrics(updatedMetrics));

    dispatch(
      setReportOptions({
        ...reportOptions,
        metric: updatedMetrics.map((item) => item.label),
        sort: updatedSorts,
      })
    );
  };

  return (
    <div
      className="metric-item flex flex-space-between flex-align-items-center flex-gap-4"
      key={metric.label}
    >
      <div className="flex flex-column flex-gap-2" {...dragHandleProps}>
        <div className="flex flex-gap-2 flex-align-items-start">
          <Icon
            iconName={ICONS.DRAGGABLE}
            size={ICONS_SIZE.SM}
            color={COLORS.colorRegentGrey}
            className="metric-handle"
          />
          <span className="metric-label font-subHeader">{metric.label}</span>
        </div>
      </div>
      <Icon
        iconName={ICONS.SUBTRACT_LINE}
        size={ICONS_SIZE.SM}
        color={COLORS.colorRegentGrey}
        onClick={() => onRemoveMetric(metric.field)}
        dataTestId="remove-metric"
      />
    </div>
  );
};

export default MetricListItem;
