import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getInstanceTableData
 * @description Function to get the instance data based on service name and some optional params
 * @param params object containing query params for searching
 * @return axios response from GET request
 */
export const getInstanceTableData = async (params: {
  service: string;
  status?: string;
}) => {
  return ServiceCalls.get(APIS.GRANULATE_INSTANCE_TABLE, null, params);
};

/**
 * @function deactivateInstancesWithHostNames
 * @description Function to deactivate instances
 * @Param body containing service name and hostnames
 * @return axios response from POST request
 */
export const deactivateInstancesWithHostNames = async (body: {
  service: string;
  hostNames: string[];
}) => {
  return ServiceCalls.post(APIS.GRANULATE_DEACTIVATE_INSTANCES, body);
};

/**
 * @function activateInstancesWithHostNames
 * @description Function to activate instances
 * @Param body containing service name and hostnames
 * @return axios response from POST request
 */
export const activateInstancesWithHostNames = async (body: {
  service: string;
  hostNames: string[];
}) => {
  return ServiceCalls.post(APIS.GRANULATE_ACTIVATE_INSTANCES, body);
};

/**
 * @function activateInstancesWithServiceName
 * @description Function to activate instances
 * @Param params containing service name
 * @return axios response from POST request
 */
export const activateInstancesWithServiceName = async (params: {
  service: string;
  activeAgentsPercentage: number;
  availabilityZone?: string;
}) => {
  return ServiceCalls.post(
    APIS.GRANULATE_ACTIVATE_ALL_INSTANCES,
    null,
    null,
    params
  );
};

/**
 * @function deactivateInstancesWithServiceName
 * @description Function to deactivate instances
 * @Param params containing service name
 * @return axios response from POST request
 */
export const deactivateInstancesWithServiceName = async (params: {
  service: string;
  availabilityZone?: string;
}) => {
  return ServiceCalls.post(
    APIS.GRANULATE_DEACTIVATE_ALL_INSTANCES,
    null,
    null,
    params
  );
};
