import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { QUERY_FIELDS } from 'constants/requestBody';

/**
 * @function getDataSourceTypeField
 * @description Function to get datasource type query field based on dashboard type.
 * @param {string} selectedDashboardType - selected dashboard type
 * @returns {string} datasource type query field
 */
export const getDataSourceTypeField = (
  selectedDashboardType: string
): string => {
  switch (selectedDashboardType) {
    case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
      return QUERY_FIELDS.DATASOURCE_TYPE_CONNECTION;
    case MY_DASHBOARD_TYPES.GROUP:
      return QUERY_FIELDS.DATASOURCE_TYPE_GROUP;
    case MY_DASHBOARD_TYPES.IMPORTS:
      return QUERY_FIELDS.DATASOURCE_TYPE_STATIC_FILE_IMPORT;
    default:
      return QUERY_FIELDS.DATASOURCE_TYPE_CONNECTION;
  }
};
