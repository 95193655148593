import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import SelectDropdown from 'components/Select';
import { REQUEST_STATUS } from 'constants/requestBody';
import { fetchAllOciCompartments } from 'pages/BudgetsAndAlertsPage/services';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import {
  getValidationStyle,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';

import { BUDGET_SCHEDULE } from '../SetupBudgetDetails/constants';

const ScopeForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { budgetData, isEdit } = useSelector(budgetsAndAlerts);

  const [budgetNameValidation, setBudgetNameValidation] = useState('');
  const [budgetDescReqValidation, setBudgetDescReqValidation] = useState('');
  const [targetCompartmentOptions, setTargetCompartmentOptions] = useState<
    string[][]
  >([]);
  const [targetCompartmentLoading, setTargetCompartmentLoading] =
    useState<string>('');

  useEffect(() => {
    fetchTargetCompartments();
  }, []);

  /**
   * @function fetchTargetCompartments
   * @description Function to set target compartment dropdown
   */
  const fetchTargetCompartments = () => {
    setTargetCompartmentLoading(REQUEST_STATUS.PROCESSING);
    const params = { connectorId: budgetData.connectorId };
    fetchAllOciCompartments(params)
      .then((res: any) => {
        setTargetCompartmentOptions(Object.entries(res.data.responseData));
        setTargetCompartmentLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, true, setTargetCompartmentLoading));
  };

  /**
   * @function validateBudgetName
   * @description Function to validate the budget name
   * @param value value to be validated
   * @return boolean true if the validation is successful else false
   */
  const validateBudgetName = (value: string) => {
    if (
      validateEmptyField(
        value,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    if (
      validateStringLengthLessThan(
        value,
        MAX_CHARACTER_LIMIT,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="collapse-body flex flex-column">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.name')}
          required={true}
        />
        <Input
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.namePlaceholder'
          )}
          value={budgetData.displayName}
          disabled={isEdit}
          onChange={(e: any) => {
            validateBudgetName(e.target.value);
            dispatch(
              setBudgetData({
                ...budgetData,
                displayName: e.target.value,
              })
            );
          }}
          onBlur={(e: any) => validateBudgetName(e.target.value)}
        />
        <span
          style={{
            display: `${getValidationStyle(budgetNameValidation)}`,
          }}
          className="font-validation-error"
        >
          {budgetNameValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`ociConnectionCSPForm.description`)}
          required={true}
        />
        <Input
          type="textarea"
          autoSize={{ minRows: 2, maxRows: 6 }}
          placeholder={t(
            `addBudgetAlert.createBudgetLabels.scope.descriptionPlaceholder`
          )}
          value={budgetData.description}
          onChange={(e: any) => {
            setBudgetDescReqValidation('');
            dispatch(
              setBudgetData({
                ...budgetData,
                description: e.target.value,
              })
            );
          }}
          onBlur={(e: any) =>
            validateEmptyField(
              e.target.value,
              t(`ociConnectionCSPForm.description`),
              setBudgetDescReqValidation
            )
          }
        />
        <span
          style={{
            display: `${getValidationStyle(budgetDescReqValidation)}`,
          }}
          className="font-validation-error"
        >
          {budgetDescReqValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.targetCompartment')}
          required={true}
        />
        <SelectDropdown
          disabled={isEdit}
          loading={targetCompartmentLoading === REQUEST_STATUS.PROCESSING}
          value={budgetData.targetCompartment}
          options={targetCompartmentOptions.map((option) => ({
            value: option[1],
            label: option[0],
          }))}
          onChange={(value: string) => {
            dispatch(
              setBudgetData({
                ...budgetData,
                targetCompartment: value,
              })
            );
          }}
        />
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.schedule')}
          required={true}
        />
        <Radio.Group buttonStyle="solid" value={budgetData.budgetDuration}>
          <Radio key="yes" value={BUDGET_SCHEDULE.key}>
            {BUDGET_SCHEDULE.title}
          </Radio>
        </Radio.Group>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(
            'addBudgetAlert.createBudgetLabels.scope.monthToBeginProcessing'
          )}
          required={true}
        />
        <SelectDropdown
          value={budgetData.budgetProcessingStartDay}
          options={Array.from({ length: 30 }, (_, i) => ({
            value: i + 1,
            label: i + 1,
          }))}
          onChange={(value: number) =>
            dispatch(
              setBudgetData({
                ...budgetData,
                budgetProcessingStartDay: value,
              })
            )
          }
        />
      </div>
    </div>
  );
};

export default ScopeForm;
