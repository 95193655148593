import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ChartDataset } from 'chart.js';
import moment from 'moment';

import ExpandModal from 'components/ExpandModal';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectAnomalyDetectionDashboard } from 'redux/anomalyDetectionDashboardSlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { onApiCallError } from 'utils/handleErrors';
import { selectTheme } from 'redux/themeSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import { DD_MMM_YYYY, HYPHEN_DATE_FORMAT } from 'utils/date';
import CostAndUsage from './components/UsageAndCost';
import DetectionHostory from './components/DetectionHistory';
import AnomalyDetails from './components/AnomalyDetails';
import {
  CostAndUsageExportColumns,
  DetectionHistoryExportColumns,
} from './constants';
import { AWSAnomalyType, DaywiseExpectedAndActualCostType } from './types';
import { fetchAWSAnomalies, fetchAWSCostAndUsageMetrics } from './services';
import { getAwsAnomalyExcelData } from './utils';

const AWSAnomalyDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  const { manualChartColors } = useSelector(selectTheme);
  const { expandedGraphName, pdfDownloadMode, tableViewEnabled } =
    useSelector(selectDashboard);
  const { selectedAnomalyDetectionConnection } = useSelector(
    selectAnomalyDetectionDashboard
  );

  const [showExpandedGraphModal, setShowExpandedGraphModal] = useState(false);

  // Detection History
  const [detectionHistoryData, setDetectionHistoryData] = useState<
    AWSAnomalyType[]
  >([]);
  const [detectionHistoryRequestStatus, setDetectionHistoryRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [selectedRowKey, setSelectedRowKey] = useState<React.Key>();
  const [selectedRow, setSelectedRow] = useState<AWSAnomalyType>();

  // Usage and Cost
  const [isCostAndUsageTableView, setIsCostAndUsageTableView] = useState(false);
  const [costAndUsageRequestStatus, setCostAndUsageRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [costAndUsageData, setCostAndUsageData] = useState<
    DaywiseExpectedAndActualCostType[]
  >([]);
  const [costAndUsageDataSet, setCostAndUsageDataSet] = useState<
    ChartDataset<'bar', number[]>[]
  >([]);

  useEffect(() => {
    setDetectionHistoryData([]);
    setSelectedRowKey(undefined);
    setSelectedRow(undefined);

    if (selectedAnomalyDetectionConnection) {
      fetchAnomalyData();
    }
  }, [selectedAnomalyDetectionConnection]);

  useEffect(() => {
    setCostAndUsageData([]);
    if (selectedRow) {
      getAwsCostAndUsageData();
    }
  }, [selectedRow]);

  useEffect(() => {
    setCostAndUsageDataSet([
      {
        label: t('anomalyDetectionDashboard.usageAndCost.actualCost', {
          currencySymbol: currencySymbol,
        }),
        data: costAndUsageData.map((item) => item.actualCost),
        backgroundColor: manualChartColors[0].hexCode,
        barThickness: 28,
        order: 2,
      },
      {
        label: t('anomalyDetectionDashboard.usageAndCost.expectedCost', {
          currencySymbol: currencySymbol,
        }),
        data: costAndUsageData.map((item) => item.expectedCost),
        type: 'line' as any,
        borderColor: manualChartColors[1].hexCode,
        order: 1,
      },
    ]);
  }, [costAndUsageData]);

  useEffect(() => {
    setIsCostAndUsageTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    if (selectedAnomalyDetectionConnection) {
      dispatch(
        setExportToExcelData(
          getAwsAnomalyExcelData(
            selectedAnomalyDetectionConnection.name,
            detectionHistoryData,
            costAndUsageData
          )
        )
      );
    }
  }, [
    selectedAnomalyDetectionConnection,
    detectionHistoryData,
    costAndUsageData,
  ]);

  /**
   * @function fetchAnomalyData
   * @description Function to fetch the anomaly data
   */
  const fetchAnomalyData = () => {
    setDetectionHistoryRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedAnomalyDetectionConnection?.connectorId,
    };

    fetchAWSAnomalies(params)
      .then((res: any) => {
        if (res.status === 200) {
          const data = res?.data?.responseData;
          setDetectionHistoryData(data || []);
          setSelectedRow(data[0]);
          setSelectedRowKey(0);
          setDetectionHistoryRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setDetectionHistoryRequestStatus(REQUEST_STATUS.ERROR);
        setSelectedRow(undefined);
        setSelectedRowKey(undefined);
      })
      .catch((e) => {
        onApiCallError(e, false, setDetectionHistoryRequestStatus);
        setSelectedRow(undefined);
        setSelectedRowKey(undefined);
      });
  };

  /**
   * @function getAwsCostAndUsageData
   * @description Function to fetch aws cost and usage data
   */
  const getAwsCostAndUsageData = () => {
    setCostAndUsageRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedAnomalyDetectionConnection?.connectorId,
    };

    const requestBody = {
      endDate: moment(selectedRow?.lastDetectedDate)
        .add(1, 'day')
        .format(HYPHEN_DATE_FORMAT),
      linkedAccountId: selectedRow?.rootCauses[0].linkedAccountId,
      service: selectedRow?.rootCauses[0].service,
      days:
        moment(selectedRow?.lastDetectedDate).diff(
          moment(selectedRow?.startDate),
          'days'
        ) + 1,
    };

    fetchAWSCostAndUsageMetrics(requestBody, params)
      .then((res: any) => {
        if (res.status === 200) {
          const data = res.data.responseData;
          const days =
            moment(selectedRow?.lastDetectedDate).diff(
              moment(selectedRow?.startDate),
              'days'
            ) + 1;
          const expectedSpend = (selectedRow?.expectedSpend ?? 0) / days;
          const usageData = (data ?? []).map((item: any) => {
            const date = moment(item?.startTime, HYPHEN_DATE_FORMAT);
            const actual = Number(item?.awsCostAndUsageGroups[0]?.amount ?? 0);
            const hasAnomaly = date.isBetween(
              moment(selectedRow?.startDate),
              moment(selectedRow?.lastDetectedDate),
              'date',
              '[]'
            );
            const expected = hasAnomaly ? expectedSpend : actual;
            return {
              date: date.format(DD_MMM_YYYY),
              expectedCost: expected,
              actualCost: actual,
            };
          });
          setCostAndUsageData(usageData);
          setCostAndUsageRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setCostAndUsageRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostAndUsageRequestStatus);
      });
  };

  /**
   * @function getPdfMetaData
   * @description Function to return the pdf metadata
   * @returns Object containing the metadata for the pdf export
   */
  const getPdfMetaData = () => {
    return {
      viewName: t('navigationMenu.anomalyDetectionInsights'),
      fileName: selectedAnomalyDetectionConnection?.name ?? '',
      heading: selectedAnomalyDetectionConnection?.name ?? '',
      subtitle1: '',
      provider: selectedAnomalyDetectionConnection?.provider ?? '',
    };
  };

  /**
   * @function getGraphComponent
   * @description Function to return the graph component
   * @param graphName Name of the graph
   * @param isPdfView is the graph in pdf view, boolean value.
   * @returns JSX element containing graph
   */
  const getGraphComponent = (graphName: string, isPdfView = false) => {
    switch (graphName) {
      case 'cost-and-usage':
        return (
          <CostAndUsage
            costAndUsageDataSet={costAndUsageDataSet}
            costAndUsageLabels={costAndUsageData.map((item) => item.date)}
            selectedRow={selectedRow}
            setShowExpandedGraphModal={setShowExpandedGraphModal}
            requestStatus={[
              detectionHistoryRequestStatus,
              costAndUsageRequestStatus,
            ]}
            costAndUsageData={costAndUsageData}
            isCostAndUsageTableView={isCostAndUsageTableView}
            pdfView={isPdfView}
            setIsCostAndUsageTableView={setIsCostAndUsageTableView}
          />
        );

      case 'detection-history':
        return (
          <DetectionHostory
            pdfView={isPdfView}
            detectionHistoryData={detectionHistoryData}
            requestStatus={detectionHistoryRequestStatus}
            setShowExpandedGraphModal={setShowExpandedGraphModal}
            selectedRowKey={selectedRowKey}
            setSelectedRowKey={setSelectedRowKey}
            setSelectedRow={setSelectedRow}
          />
        );

      case 'anomaly-details':
        return (
          <AnomalyDetails
            anomalyDetails={selectedRow}
            pdfView={isPdfView}
            requestStatus={detectionHistoryRequestStatus}
            setShowExpandedGraphModal={setShowExpandedGraphModal}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="flex flex-gap-24">
        <div className="width-60">{getGraphComponent('detection-history')}</div>
        <div className="width-40">{getGraphComponent('anomaly-details')}</div>
      </div>
      {getGraphComponent('cost-and-usage')}
      <ExpandModal
        show={showExpandedGraphModal}
        onClose={useCallback(() => {
          setShowExpandedGraphModal(!showExpandedGraphModal);
        }, [showExpandedGraphModal])}
        graphContent={getGraphComponent(expandedGraphName)}
      />
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData()}
          pdfContent={[
            {
              contentType: CHART_TYPES.TABLE,
              graphName: 'detection-history',
              column: DetectionHistoryExportColumns,
              element: getGraphComponent('detection-history', true),
              body: detectionHistoryData.map((item, index) => ({
                ...item,
                rootCauses: null,
                slNo: index + 1,
              })),
              tableName: t('graphHeadings.detectionHistory'),
            },
            {
              element: getGraphComponent('anomaly-details', true),
              contentType: CHART_TYPES.NONE,
              graphName: 'anomaly-details',
              isTableView: false,
              excludeInGlobalDownload: true,
            },
            {
              element: getGraphComponent('cost-and-usage', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'cost-and-usage',
              isTableView: isCostAndUsageTableView,
              column: CostAndUsageExportColumns,
              body: costAndUsageData.map((item, index) => ({
                ...item,
                slNo: index + 1,
              })),
              tableName: t(
                'anomalyDetectionDashboard.usageAndCost.graphHeading'
              ),
            },
          ]}
          pdfCardData={{
            costOptmization: {
              cards:
                selectedRow === undefined ||
                detectionHistoryRequestStatus === REQUEST_STATUS.ERROR
                  ? undefined
                  : getGraphComponent('anomaly-details', true),
              insertAtStart: false,
            },
          }}
        />
      )}
    </div>
  );
};

export default AWSAnomalyDashboard;
