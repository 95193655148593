import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import yaml from 'js-yaml';

import Modal from 'components/Modal';
import { onApiCallError } from 'utils/handleErrors';
import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { COLORS } from 'constants/graphConfig';

import './index.scss';

type YamlDetailsModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  recommendation: RecommendationList;
};

const YamlDetailsModal = ({
  show,
  setShow,
  recommendation,
}: YamlDetailsModalProps) => {
  const { t } = useTranslation();

  const [yamlData, setYamlData] = useState('');
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  useEffect(() => {
    try {
      const yamlObject = yaml.load(recommendation.patch_yaml!);
      const formattedYaml = yaml.dump(yamlObject);
      setYamlData(formattedYaml);
    } catch (error) {
      setYamlData(recommendation.patch_yaml!);
      onApiCallError(error, false);
    }
  }, [recommendation]);

  /**
   * @function onHandleCopy
   * @description function to copy the yaml to clipboard
   */
  const onHandleCopy = async () => {
    try {
      await navigator.clipboard.writeText(yamlData);
      setShowCopyMessage(true);

      //Hide copy message after 2 sec
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 2000);
    } catch (err) {
      onApiCallError(err, false);
    }
  };

  return (
    <Modal
      className="yaml-modal"
      visible={show}
      onCancel={() => {
        setShow(false);
        setShowCopyMessage(false);
        setYamlData('');
      }}
      data-testid="yaml-details-modal"
    >
      <div className="yaml-modal-container flex flex-column flex-gap-16">
        <header className="yaml-modal-header flex flex-gap-24">
          <div className="flex flex-gap-8">
            <span className="font-caption-bold">
              {t('granulateInsightDashboard.resourceName')}
            </span>
            <span className="font-caption">
              {recommendation.controller_name}
            </span>
          </div>
          <div className="flex flex-gap-8">
            <span className="font-caption-bold">
              {t('granulateInsightDashboard.namespace')}
            </span>
            <span className="font-caption">{recommendation.namespace}</span>
          </div>
        </header>
        <div className="flex flex-gap-8 flex-space-between flex-align-items-start">
          <pre data-testid="yaml-data">{yamlData}</pre>
          <Icon
            iconName={ICONS.FILE_COPY_LINE}
            color={COLORS.primaryGreen}
            className="copy-icon"
            onClick={onHandleCopy}
            dataTestId="copy-cta"
          />
          {showCopyMessage && (
            <span className="copy-text font-caption">
              {t('granulateInsightDashboard.copyMessage')}
            </span>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default YamlDetailsModal;
