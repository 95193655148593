import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Tooltip } from 'antd';
import { ColumnProps } from 'antd/es/table';

import Icon from 'components/Icon';
import Table from 'components/Table';
import ColumnChart from 'components/ColumnChart';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import './index.scss';

type ProfileBarProps = {
  data: any;
  cpuTrendData: { [key: string]: number } | undefined;
  metaData: { [key: string]: any } | undefined;
  metricsData: { [key: string]: number } | undefined;
  loading: boolean;
};

const ProfileBar = ({
  data,
  cpuTrendData,
  metaData,
  metricsData,
  loading,
}: ProfileBarProps) => {
  const { t } = useTranslation();

  const columns: ColumnProps<any>[] = [
    {
      title: t('gprofilerInsightsDashboard.name'),
      dataIndex: 'instance_type',
      key: 'instance_type',
      align: 'left',
    },
    {
      title: t('gprofilerInsightsDashboard.percentage'),
      dataIndex: 'instance_count',
      key: 'percentage',
      align: 'right',
      render: (value: number) =>
        numberCommaSeparator(
          (value / (metricsData?.uniq_hostnames ?? 1)) * 100
        ) + '%',
    },
  ];

  const TableComponent = (
    <Table
      pagination={false}
      dataSource={metaData?.instance_details?.map(
        (instance: any, index: number) => ({
          ...instance,
          key: index,
        })
      )}
      columns={columns}
      designVersion2
    />
  );

  const getComparisonArrowIcon = (value: number) => {
    return value >= 0 ? (
      <Icon
        iconName={ICONS.ARROW_RIGHT_UP_LINE}
        size={ICONS_SIZE.ONE_X}
        className="positive"
      />
    ) : (
      <Icon
        iconName={ICONS.ARROW_RIGHT_DOWN_LINE}
        size={ICONS_SIZE.ONE_X}
        className="negative"
      />
    );
  };

  const getInstancesMinimizedText = () => {
    if (
      !metaData?.instance_details ||
      metaData?.instance_details?.length === 0
    ) {
      return t('gprofilerInsightsDashboard.none');
    }

    if (metaData?.instance_details?.length > 1) {
      return (
        metaData?.instance_details[0]?.instance_type +
        '(+' +
        (metaData?.instance_details?.length - 1) +
        ')'
      );
    }

    return metaData?.instance_details[0]?.instance_type;
  };

  /**
   * @function getCpuConsumptionDifference
   * @description Function to calculate the CPU consumption difference
   * @return CPU consumption difference for current consumption to last week average consumption
   */
  const getCpuConsumptionDifference = () => {
    return (cpuTrendData?.avg_cpu ?? 0) - (cpuTrendData?.compared_avg_cpu ?? 0);
  };

  /**
   * @function cpuConsumptionTooltipCustomItems
   * @description Function to return the tooltip content items
   * @param originalItems tooltip items before modification
   * @return List of tooltip items
   */
  const cpuConsumptionTooltipCustomItems = useCallback(
    (originalItems: any[]) =>
      originalItems.map((item) => ({
        ...item,
        name: t('gprofilerInsightsDashboard.cpuConsumtion'),
        value: numberCommaSeparator(item.data.value) + '%',
      })),
    []
  );

  /**
   * @function valueFormatter
   * @description Function to for the graph value
   * @param v original value
   * @return String containing the modified value
   */
  const valueFormatter = useCallback((v: any) => `${v}%`, []);

  const CpuConsumptionTooltipContent = (
    <div className="consumption-overlay">
      <div className="heading font-button">
        {t('gprofilerInsightsDashboard.cpuConsumtion')}
      </div>
      <ColumnChart
        data={[
          {
            type: t('gprofilerInsightsDashboard.lastWeek'),
            value: cpuTrendData?.compared_avg_cpu ?? 0,
          },
          {
            type: t('gprofilerInsightsDashboard.current'),
            value: cpuTrendData?.avg_cpu ?? 0,
          },
        ]}
        xField="type"
        yField="value"
        meta={{
          value: { formatter: valueFormatter },
        }}
        tooltipOverride={{
          customItems: cpuConsumptionTooltipCustomItems,
        }}
      />
    </div>
  );

  /**
   * @function memoryConsumptionTooltipCustomItems
   * @description Function to return the tooltip content items
   * @param originalItems tooltip items before modification
   * @return List of tooltip items
   */
  const memoryConsumptionTooltipCustomItems = useCallback(
    (originalItems: any[]) =>
      originalItems.map((item) => ({
        ...item,
        name: t('gprofilerInsightsDashboard.memoryConsumption'),
        value: numberCommaSeparator(item.data.value) + '%',
      })),
    []
  );

  const MemoryConsumptionTooltipContent = (
    <div className="consumption-overlay">
      <div className="heading font-button">
        {t('gprofilerInsightsDashboard.memoryConsumption')}
      </div>
      <ColumnChart
        data={[
          {
            type: t('gprofilerInsightsDashboard.lastWeek'),
            value: cpuTrendData?.avg_memory ?? 0,
          },
          {
            type: t('gprofilerInsightsDashboard.current'),
            value: cpuTrendData?.compared_avg_memory ?? 0,
          },
        ]}
        xField="type"
        yField="value"
        meta={{
          value: { formatter: valueFormatter },
        }}
        tooltipOverride={{
          customItems: memoryConsumptionTooltipCustomItems,
        }}
      />
    </div>
  );

  /**
   * @function getMemoryConsumptionDifference
   * @description Function to calculate the memory consumption difference
   * @return Memory consumption difference for current consumption to last week average consumption
   */
  const getMemoryConsumptionDifference = () => {
    return (
      (cpuTrendData?.avg_memory ?? 0) - (cpuTrendData?.compared_avg_memory ?? 0)
    );
  };

  const profileBarItems = [
    {
      label: t('gprofilerInsightsDashboard.samplesCollected'),
      value: data.value || 0,
    },
    {
      icon: getComparisonArrowIcon(getCpuConsumptionDifference()),
      value: (
        <Tooltip
          rootClassName="comparison-tooltip"
          overlay={CpuConsumptionTooltipContent}
        >
          {numberCommaSeparator(Math.abs(getCpuConsumptionDifference())) + '%'}
        </Tooltip>
      ),

      isIcon: true,
    },
    {
      label: t('gprofilerInsightsDashboard.cpuUtilization'),
      value: `${numberCommaSeparator(
        cpuTrendData?.avg_cpu ?? 0
      )}% avg - ${numberCommaSeparator(cpuTrendData?.max_cpu ?? 0)}% max`,
    },
    {
      icon: getComparisonArrowIcon(getMemoryConsumptionDifference()),
      value: (
        <Tooltip
          rootClassName="comparison-tooltip"
          overlay={MemoryConsumptionTooltipContent}
        >
          {numberCommaSeparator(Math.abs(getMemoryConsumptionDifference())) +
            '%'}
        </Tooltip>
      ),

      isIcon: true,
    },
    {
      label: t('gprofilerInsightsDashboard.memoryUtilization'),
      value: `${numberCommaSeparator(
        cpuTrendData?.avg_memory ?? 0
      )}% avg - ${numberCommaSeparator(cpuTrendData?.max_memory ?? 0)}% max`,
    },
    {
      label: t('gprofilerInsightsDashboard.nodes'),
      value: metaData?.avg_nodes || 0,
    },
    {
      label: t('gprofilerInsightsDashboard.cores'),
      value: metaData?.avg_cores || 0,
    },
    {
      label: t('gprofilerInsightsDashboard.instanceTypes'),
      value: (
        <Tooltip overlay={TableComponent}>
          {getInstancesMinimizedText()}
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="profile-bar full-width flex flex-align-items-center flex-gap-16">
      {profileBarItems.map((item) => (
        <div
          className="profile-bar-item flex flex-align-items-center"
          key={item.label}
        >
          {item.icon}
          <span className="label font-caption">{item?.label}</span>
          {loading ? (
            <Skeleton
              className="active-loader"
              active
              paragraph={{
                rows: 1,
                width: 50,
                style: { marginBottom: 0, marginLeft: 4 },
              }}
              title={false}
            />
          ) : (
            <span className="value font-caption-bold">{item?.value}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProfileBar;
