import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, message, Radio, Row } from 'antd';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';

import { dataCenter } from 'redux/dataCenterSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { SoftwareEntryListType } from 'pages/DataCenterPage/types';
import { SoftwareSubscriptionTypes } from 'pages/DataCenterPage/constants';
import {
  saveSoftwareResource,
  updateSoftwareResource,
} from 'pages/DataCenterPage/services';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MONTH_YEAR_FORMAT, TIMESTAMP_FORMAT_WITHOUT_ZONE } from 'utils/date';
import {
  getValidationStyle,
  isNumber,
  validateEmptyField,
} from 'utils/validations';
import DrawerComponent from 'components/DrawerComponent';
import DatePicker from 'components/DatePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import { BUTTON_TYPE } from 'constants/appearance';
import { onApiCallError } from 'utils/handleErrors';

import { TypesOfSoftwareSubscriptions } from '../ManualEntry/constants';

type SoftwareRowModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  onSaveOrUpdateEntry: () => void;
  softwareEntry?: SoftwareEntryListType;
};

const SoftwareRowModal = ({
  show,
  setShow,
  onSaveOrUpdateEntry,
  softwareEntry,
}: SoftwareRowModalProps) => {
  const { t } = useTranslation();
  const { selectedDataCenter } = useSelector(dataCenter);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [name, setName] = useState('');
  const [nameValidation, setNameValidation] = useState('');
  const [licenceNo, setLicenceNo] = useState('');
  const [licenceNoValidation, setLicenceNoValidation] = useState('');
  const [noOfUsers, setNoOfUsers] = useState('');
  const [noOfUsersValidation, setNoOfUsersValidation] = useState('');
  const [type, setType] = useState(SoftwareSubscriptionTypes.SUBSCRIPTION);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [startDateValidation, setStartDateValidation] = useState('');
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [endDateValidation, setEndDateValidation] = useState('');
  const [cost, setCost] = useState('');
  const [costValidation, setCostValidation] = useState('');

  const [saveSoftwareEntryRequestStatus, setSaveSoftwareEntryRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (softwareEntry !== undefined) {
      setName(softwareEntry.name);
      setLicenceNo(softwareEntry.licenseNo);
      setNoOfUsers(softwareEntry.noOfUsers.toString());
      setType(
        TypesOfSoftwareSubscriptions.find(
          (item) => item.key === softwareEntry.subscriptionType
        )?.key ?? SoftwareSubscriptionTypes.SUBSCRIPTION
      );
      setStartDate(moment(softwareEntry.startDate));
      setEndDate(softwareEntry.endDate ? moment(softwareEntry.endDate) : null);
      setCost(softwareEntry.cost.toString());
    }
  }, []);

  /**
   * @function validateName
   * @description Function to validate the Name.
   * @param value string input value
   */
  const validateName = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.softwareLabels.name'),
      setNameValidation
    );
  };

  /**
   * @function validateLicenceNo
   * @description Function to validate the licence number.
   * @param value string input value
   */
  const validateLicenceNo = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.softwareLabels.licenceNo'),
      setLicenceNoValidation
    );
  };

  /**
   * @function validateNoOfUsers
   * @description Function to validate the number of Users.
   * @param value string input value
   */
  const validateNoOfUsers = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.softwareLabels.noOfUsers'),
        setNoOfUsersValidation
      )
    ) {
      return isNumber(
        value,
        false,
        t('manualEntry.softwareLabels.noOfUsers'),
        setNoOfUsersValidation
      );
    }

    return false;
  };

  /**
   * @function validateStartDate
   * @description Function to validate the Start Date.
   * @param value string input value
   */
  const validateStartDate = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.softwareLabels.startDate'),
      setStartDateValidation
    );
  };

  /**
   * @function validateEndDate
   * @description Function to validate the End Date.
   * @param value string input value
   */
  const validateEndDate = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.softwareLabels.endDate'),
      setEndDateValidation
    );
  };

  /**
   * @function validateCost
   * @description Function to validate the Cost.
   * @param value string input value
   */
  const validateCost = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.softwareLabels.cost'),
        setCostValidation
      )
    ) {
      return isNumber(
        value,
        true,
        t('manualEntry.softwareLabels.cost'),
        setCostValidation
      );
    }

    return false;
  };

  /**
   * @function onClickAdd
   * @description Function to add software row for the entered values
   */
  const onClickAdd = () => {
    if (!validateAllFields()) {
      return;
    }

    setSaveSoftwareEntryRequestStatus(REQUEST_STATUS.PROCESSING);
    let requestBody: any = {
      dataCenterCode: selectedDataCenter?.dataCenterCode,
      name: name,
      licenseNo: licenceNo,
      noOfUsers: noOfUsers,
      subscriptionType: type,
      startDate: moment(startDate)
        .startOf('month')
        .startOf('day')
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      endDate:
        type === SoftwareSubscriptionTypes.SUBSCRIPTION
          ? moment(endDate)
              .endOf('month')
              .startOf('day')
              .format(TIMESTAMP_FORMAT_WITHOUT_ZONE)
          : undefined,
      cost: cost,
    };

    let successMessage = t('manualEntry.softwareLabels.saveSoftwareSuccess');
    let failureMessage = t('manualEntry.softwareLabels.saveSoftwareFailed');

    if (softwareEntry !== undefined) {
      requestBody.id = softwareEntry.id;

      successMessage = t('manualEntry.softwareLabels.updateSoftwareSuccess');
      failureMessage = t('manualEntry.softwareLabels.updateSoftwareFailed');
    }

    (softwareEntry === undefined
      ? saveSoftwareResource(requestBody)
      : updateSoftwareResource(requestBody)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setSaveSoftwareEntryRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success(successMessage);
          onSaveOrUpdateEntry();
          setShow(false);
          return;
        }

        setSaveSoftwareEntryRequestStatus(REQUEST_STATUS.ERROR);
        setErrorMessage(res?.message ?? failureMessage);
      })
      .catch((e) => {
        onApiCallError(e, false, setSaveSoftwareEntryRequestStatus);
        setErrorMessage(e?.response?.data?.message ?? failureMessage);
      });
  };

  /**
   * @function validateAllFields
   * @description Function to validate the software fields
   * @returns boolean true validated else false
   */
  const validateAllFields = () => {
    validateName(name);
    validateLicenceNo(licenceNo);
    validateNoOfUsers(noOfUsers);
    validateStartDate(startDate?.toString() ?? '');
    validateEndDate(endDate?.toString() ?? '');
    validateCost(cost);

    return (
      validateName(name) &&
      validateLicenceNo(licenceNo) &&
      validateNoOfUsers(noOfUsers) &&
      validateStartDate(startDate?.toString() ?? '') &&
      validateCost(cost) &&
      (type === SoftwareSubscriptionTypes.PERMANENT_PURCHASE ||
        validateEndDate(endDate?.toString() ?? ''))
    );
  };

  return (
    <DrawerComponent
      className="row-modal"
      width={600}
      open={show}
      onClose={() => setShow(false)}
      title={
        (softwareEntry
          ? t('manualEntry.editRow')
          : t('manualEntry.addNewRow')) +
        ' - ' +
        t('manualEntry.nav.software')
      }
      footer={
        <div className="flex flex-column flex-gap-8">
          <span
            style={{
              display: getValidationStyle(errorMessage),
            }}
            className="font-validation-error"
          >
            {errorMessage}
          </span>
          <div className="flex flex-align-items-center flex-end">
            <Button
              title={t('manualEntry.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShow(false)}
            />
            <Button
              title={
                softwareEntry === undefined
                  ? t('manualEntry.add')
                  : t('manualEntry.update')
              }
              onClick={onClickAdd}
              loading={
                saveSoftwareEntryRequestStatus === REQUEST_STATUS.PROCESSING
              }
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-column flex-gap-16">
        <Row>
          <Col span={24}>
            <FormLabel title={t('manualEntry.softwareLabels.name')} required />
          </Col>
          <Col span={24}>
            <Input
              type="input"
              value={name}
              placeholder={t('manualEntry.softwareLabels.namePlaceholder')}
              onChange={(e: any) => {
                setName(e.target.value);
                validateName(e.target.value);
              }}
              onBlur={() => validateName(name)}
            />
          </Col>
          <Col span={24}>
            <span
              style={{
                display: `${getValidationStyle(nameValidation)}`,
              }}
              className="font-validation-error"
            >
              {nameValidation}
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.softwareLabels.licenceNo')}
              required
            />
            <Input
              type="input"
              value={licenceNo}
              placeholder={t('manualEntry.softwareLabels.licenceNoPlaceholder')}
              onChange={(e: any) => {
                setLicenceNo(e.target.value);
                validateLicenceNo(e.target.value);
              }}
              onBlur={() => validateLicenceNo(licenceNo)}
            />
            <span
              style={{
                display: `${getValidationStyle(licenceNoValidation)}`,
              }}
              className="font-validation-error"
            >
              {licenceNoValidation}
            </span>
          </Col>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.softwareLabels.noOfUsers')}
              required
            />
            <Input
              type="input"
              value={noOfUsers}
              placeholder={t('manualEntry.softwareLabels.noOfUsersPlaceholder')}
              onChange={(e: any) => {
                setNoOfUsers(e.target.value);
                validateNoOfUsers(e.target.value);
              }}
              onBlur={() => validateNoOfUsers(noOfUsers)}
            />
            <span
              style={{
                display: `${getValidationStyle(noOfUsersValidation)}`,
              }}
              className="font-validation-error"
            >
              {noOfUsersValidation}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormLabel title={t('manualEntry.softwareLabels.type')} required />
          </Col>
          <Col span={24}>
            <Radio.Group
              onChange={(event) => setType(event.target.value)}
              value={type}
            >
              {TypesOfSoftwareSubscriptions.map((mode) => (
                <Radio value={mode.key} key={mode.key}>
                  {mode.label}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <Divider className="horizontal-divider" />
        <Row>
          <Col span={24}>
            <FormLabel title={t('manualEntry.softwareLabels.cost')} required />
            <Input
              className="full-width"
              type="input"
              prefix={currencySymbol}
              value={cost}
              placeholder={t('manualEntry.softwareLabels.costPlaceholder')}
              onChange={(e: any) => {
                setCost(e.target.value);
                validateCost(e.target.value);
              }}
              onBlur={() => validateCost(cost)}
            />
            <span
              style={{
                display: `${getValidationStyle(costValidation)}`,
              }}
              className="font-validation-error"
            >
              {costValidation}
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.softwareLabels.startDate')}
              required
            />
            <DatePicker
              className="full-width"
              picker="month"
              disabledDate={(current: any) =>
                current && current > moment().endOf('day')
              }
              suffixIcon={
                <Icon
                  iconName={ICONS.ARROW_DOWN_S_LINE}
                  size={ICONS_SIZE.TWO_X}
                />
              }
              pickerType={DATE_PICKER_TYPE.DATE_PICKER}
              format={MONTH_YEAR_FORMAT}
              value={startDate}
              placeholder={t('manualEntry.softwareLabels.startDatePlaceholder')}
              onChange={(value: any, date: any) => {
                setStartDate(value);
                if (endDate?.isBefore(value, 'months')) {
                  setEndDate(null);
                }
                validateStartDate(date);
              }}
              onBlur={() => validateStartDate(startDate?.toString() ?? '')}
            />
            <span
              style={{
                display: getValidationStyle(startDateValidation),
              }}
              className="font-validation-error"
            >
              {startDateValidation}
            </span>
          </Col>
          {type === SoftwareSubscriptionTypes.SUBSCRIPTION && (
            <Col span={12}>
              <FormLabel
                title={t('manualEntry.softwareLabels.endDate')}
                required
              />
              <DatePicker
                className="full-width"
                picker="month"
                disabledDate={(current: any) =>
                  current && current < moment(startDate)
                }
                suffixIcon={
                  <Icon
                    iconName={ICONS.ARROW_DOWN_S_LINE}
                    size={ICONS_SIZE.TWO_X}
                  />
                }
                pickerType={DATE_PICKER_TYPE.DATE_PICKER}
                format={MONTH_YEAR_FORMAT}
                value={endDate}
                placeholder={t('manualEntry.softwareLabels.endDatePlaceholder')}
                onChange={(value: any, date: any) => {
                  setEndDate(value);
                  validateEndDate(date);
                }}
                onBlur={() => validateEndDate(endDate?.toString() ?? '')}
              />
              <span
                style={{
                  display: getValidationStyle(endDateValidation),
                }}
                className="font-validation-error"
              >
                {endDateValidation}
              </span>
            </Col>
          )}
        </Row>
      </div>
    </DrawerComponent>
  );
};

export default SoftwareRowModal;
