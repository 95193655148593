import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'constants/requestBody';
import {
  costOptimizationInsights,
  setCostOptimizationInsightsConnectionList,
  setSelectedCostOptimizationInsightsConnection,
} from 'redux/costOptimizationInsightsSlice';
import { LoadingIcon } from 'assets/icons';
import { fetchConnectionData } from 'utils/services';
import { ConnectionListType } from 'types/dashboard';
import { getProviderLogo } from 'utils/providerDetails';
import { CONNECTION_STATUS } from 'pages/ConnectingCSPPage/constants';
import Icon from 'components/Icon';
import Button from 'components/Button';
import ConnectionsListDrawer from 'components/ConnectionsListDrawer';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  selectDashboard,
  setPdfDownloadMode,
  setPdfGraphToDownload,
} from 'redux/dashboardSlice';
import { COST_OPTIMIZATION_INSIGHTS_NAV } from 'pages/CostOptimizationInsightsPage/constants';
import { ConsumptionManagementType } from 'pages/CostOptimizationInsightsPage/types';
import { PDF_ALL_GRAPHS } from 'constants/pdfConstants';
import { onApiCallError } from 'utils/handleErrors';
import { selectTheme } from 'redux/themeSlice';

import './index.scss';

type InsightsHeaderProps = {
  connectionListLoading: string;
  setConnectionListLoading: (val: string) => void;
  setIsEmptyConnectionList: (val: boolean) => void;
};

const InsightsHeader = ({
  connectionListLoading,
  setConnectionListLoading,
  setIsEmptyConnectionList,
}: InsightsHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { tableViewEnabled, pdfDownloadMode } = useSelector(selectDashboard);
  const { theme } = useSelector(selectTheme);
  const {
    costOptimizationInsightsConnectionList,
    selectedCostOptimizationInsightsConnection,
    selectedCostOptimizationInsightsNav,
    selectedGranulateService,
  } = useSelector(costOptimizationInsights);

  const [isDownloadingData, setIsDownloadingData] = useState(false);
  const [showSwitchConnectionDrawer, setShowSwitchConnectionDrawer] =
    useState(false);

  useEffect(() => {
    fetchConnectionList();
  }, []);

  useEffect(() => {
    if (
      !selectedCostOptimizationInsightsConnection &&
      costOptimizationInsightsConnectionList
    ) {
      dispatch(
        setSelectedCostOptimizationInsightsConnection(
          costOptimizationInsightsConnectionList[0]
        )
      );
    }
  }, [costOptimizationInsightsConnectionList]);

  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  /**
   * @function fetchConnectionList
   * @description Function to fetch connection list data
   */
  const fetchConnectionList = () => {
    setConnectionListLoading(REQUEST_STATUS.PROCESSING);
    fetchConnectionData()
      .then((res: any) => {
        let connections: ConnectionListType[] =
          res?.data?.responseData?.content || [];
        connections = connections.filter(
          (connection) =>
            connection.status === CONNECTION_STATUS.ACTIVE &&
            !connection.migrated &&
            connection.wantRecommendations
        );
        dispatch(setCostOptimizationInsightsConnectionList(connections));
        setIsEmptyConnectionList(connections.length === 0);
        setConnectionListLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setConnectionListLoading);
        dispatch(setCostOptimizationInsightsConnectionList([]));
        setIsEmptyConnectionList(true);
      });
  };

  /**
   * @function onHandleDownloadDashboardData
   * @description function to handle common download of dashboard view. Will download pdf dashboard view is static except for cost optimization summary other wise will download data as excel
   * @returns either pdf or excel
   */
  const onHandleDownloadDashboardData = () => {
    setIsDownloadingData(true);
    if (!tableViewEnabled) {
      dispatch(setPdfGraphToDownload(PDF_ALL_GRAPHS));
      dispatch(setPdfDownloadMode(true));
      return;
    }
    setIsDownloadingData(false);
  };

  let provider;
  let displayName;
  let dataSourceType;

  if (selectedGranulateService) {
    provider = selectedGranulateService.provider;
    displayName = selectedGranulateService.name;
    dataSourceType = selectedGranulateService.connectionName;
  } else {
    provider = selectedCostOptimizationInsightsConnection?.provider!;
    displayName = selectedCostOptimizationInsightsConnection?.displayName;
    dataSourceType = selectedCostOptimizationInsightsConnection?.dataSourceType;
  }

  const ConsumptionHeader = (
    <div className="flex">
      <img
        width={35}
        height={35}
        src={getProviderLogo(provider)}
        alt={`${provider} Logo`}
        className="connection-icon flex-align-self-center"
      />
      <div className="connection-title flex flex-column">
        <div className="modal-heading">{displayName}</div>
        <div className="font-caption-bold sub-title">{dataSourceType}</div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-align-items-center flex-space-between flex-wrap">
      {selectedCostOptimizationInsightsNav ===
      COST_OPTIMIZATION_INSIGHTS_NAV.CONSUMPTION_MANAGEMENT_RECOMMENDATIONS ? (
        ConsumptionHeader
      ) : (
        <div className="flex">
          <img
            width={35}
            height={35}
            src={getProviderLogo(
              selectedCostOptimizationInsightsConnection?.provider!
            )}
            alt={`${selectedCostOptimizationInsightsConnection?.provider!} Logo`}
            className="connection-icon flex-align-self-center"
          />
          <div className="connection-title flex flex-column">
            <div className="modal-heading">
              {selectedCostOptimizationInsightsConnection?.displayName}
            </div>
            <div className="font-caption-bold sub-title">
              {selectedCostOptimizationInsightsConnection?.dataSourceType}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-align-items-center flex-gap-24">
        {selectedCostOptimizationInsightsNav ===
          COST_OPTIMIZATION_INSIGHTS_NAV.COST_SAVINGS_STATUS_SUMMARY && (
          <>
            {isDownloadingData ? (
              <Icon icon={LoadingIcon} className="rotate" />
            ) : (
              <Icon
                iconName={ICONS.DOWNLOAD_2_LINE}
                onClick={onHandleDownloadDashboardData}
                size={ICONS_SIZE.ONE_X}
                color={theme.buttonIconColor}
              />
            )}
          </>
        )}
        <Button
          title={t('dashboardLabels.switchConnection')}
          iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
          iconSize={ICONS_SIZE.ONE_X}
          onClick={() => setShowSwitchConnectionDrawer(true)}
        />
      </div>
      <ConnectionsListDrawer
        isCB360TabSelected
        show={showSwitchConnectionDrawer}
        setShow={setShowSwitchConnectionDrawer}
        selectedConnection={selectedCostOptimizationInsightsConnection}
        connectionList={
          costOptimizationInsightsConnectionList as ConsumptionManagementType[]
        }
        onClick={(key: any) => {
          dispatch(
            setSelectedCostOptimizationInsightsConnection(
              costOptimizationInsightsConnectionList?.find(
                (connection) => connection.connectorId === key
              ) ?? null
            )
          );
          setShowSwitchConnectionDrawer(false);
        }}
        loading={connectionListLoading === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );
};

export default InsightsHeader;
