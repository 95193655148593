import moment, { Moment } from 'moment';
import { Table as AntTable } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { calculateTotalCostSum } from 'utils/CostSum';
import DashboardComponent from 'components/DashboardComponent';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { selectDashboard } from 'redux/dashboardSlice';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { SpendDataType } from '../../types';
import { UsageSpendColumns } from '../../constants';

type UsageSpendProps = {
  spendData: SpendDataType[];
  requestStatus: string;
  pdfView: boolean;
  usageSpendStartDate: string;
  usageSpendEndDate: string;
  onChangeUsageSpendDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

const UsageSpend = ({
  spendData,
  requestStatus,
  pdfView,
  usageSpendStartDate,
  usageSpendEndDate,
  onChangeUsageSpendDateRange,
}: UsageSpendProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[moment(usageSpendStartDate), moment(usageSpendEndDate)]}
        onChange={onChangeUsageSpendDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        designVersion2
      />
    </div>
  );

  const getSummaryRowComponent = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row className="font-caption-bold">
        <AntTable.Summary.Cell index={0} colSpan={2}>
          {t('usageSpend.grandTotal')}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell index={1} align="right">
          {currencySymbol +
            calculateTotalCostSum(spendData.map((data) => Number(data.cost)))}
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  const getComponent = () => {
    return (
      <Table
        pagination={false}
        scroll={{ y: 140 }}
        dataSource={spendData.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        })}
        columns={UsageSpendColumns}
        designVersion2
        summary={getSummaryRowComponent}
      />
    );
  };

  return (
    <div className="usage-spend graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.usageSpend')}
        graphName="usage-spend"
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        designVersion2
        filters={filters}
        excelData={getChartExcelExportData(
          t('graphHeadings.usageSpend'),
          spendData,
          UsageSpendColumns,
          {
            connectionName: selectedDashboard?.connectorName ?? '',
          }
        )}
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};

export default UsageSpend;
