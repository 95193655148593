import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';

type ContainersProps = {
  recommendation: RecommendationList;
};

const Containers = ({ recommendation }: ContainersProps) => {
  if (!recommendation.containers || !recommendation.containers.length) {
    return <div>N/A</div>;
  }

  return (
    <div className="flex flex-column flex-gap-8">
      {recommendation.containers!.map((container) => (
        <span key={container.name}>{container.name}</span>
      ))}
    </div>
  );
};

export default Containers;
