import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionListType } from 'types/dashboard';
import { RootState } from './store';

export interface AnomalyDetectionConnectionState {
  anomalyDetectionConnectionList: ConnectionListType[];
  selectedAnomalyDetectionConnection: ConnectionListType | null;
}

const initialState: AnomalyDetectionConnectionState = {
  anomalyDetectionConnectionList: [],
  selectedAnomalyDetectionConnection: null,
};

export const anomalyDetectionDashboardSlice = createSlice({
  name: 'anomalyDetectionDashboardSlice',
  initialState,
  reducers: {
    setAnomalyDetectionConnectionList: (
      state,
      action: PayloadAction<ConnectionListType[]>
    ) => {
      state.anomalyDetectionConnectionList = action.payload;
    },
    setSelectedAnomalyDetectionConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.selectedAnomalyDetectionConnection = action.payload;
    },
  },
});

export const selectAnomalyDetectionDashboard = (state: RootState) =>
  state.anomalyDetectionDashboard;
export const {
  setSelectedAnomalyDetectionConnection,
  setAnomalyDetectionConnectionList,
} = anomalyDetectionDashboardSlice.actions;
export default anomalyDetectionDashboardSlice.reducer;
