import { PROVIDER } from 'constants/cloudProviders';
import { DashboardMetaData } from 'types/dashboard';
import { getQueryFieldByDataSource } from 'utils/dashboardUtils';
import {
  COMPARATORS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { getChartData } from 'utils/services';
import { formatArrayToStringByProvider } from 'utils/dataFormatterUtils';

import { GCP_SERVICE_AGGREGATORS_BASE_QUERY } from './constants';

const getAzureServiceAggregatorsBaseQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[]
) => {
  return {
    columns: [
      {
        label: 'name',
        field: QUERY_FIELDS.RESOURCE_GROUP,
      },
    ],
    groupBy: ['name'],
    orderBy: [
      {
        label: 'name',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.RESOURCE_GROUP,
            comparator: COMPARATORS.NOT_EQUAL_TO,
            value: '',
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

const getOCIServiceAggregatorsBaseQuery = (
  dashboardMetaData: DashboardMetaData
) => {
  return {
    columns: [
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRD_COMPARTMENT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['name'],
    orderBy: [
      {
        label: 'name',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

const getServiceAggregatorsQueryByProvider = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[]
) => {
  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureServiceAggregatorsBaseQuery(
        dashboardMetaData,
        selectedAccounts
      );

    case PROVIDER.OCI:
      return getOCIServiceAggregatorsBaseQuery(dashboardMetaData);

    case PROVIDER.GCP:
    default:
      return GCP_SERVICE_AGGREGATORS_BASE_QUERY;
  }
};

/**
 * @function fetchServiceAggregatorsList
 * @description Function to fetch the list of service aggregators -> List of project / resource groups
 * @param setRequestStatus set request status
 * @param selectedDashboard selected dashboard
 * @param setSelectedServiceAggregators set selected service aggregators
 * @param selectedAccounts List of accounts selected
 */
export const fetchServiceAggregatorsList = (
  dashboardMetaData: DashboardMetaData,
  setRequestStatus: (value: string) => void,
  setServiceAggregatorFilters: (value: string[]) => void,
  setSelectedServiceAggregators: (value: string[]) => void,
  selectedAccounts: string[]
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);
  const requestBody = getServiceAggregatorsQueryByProvider(
    dashboardMetaData,
    selectedAccounts
  );

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      const allServiceAggregators = res.data.map((item: any) =>
        dashboardMetaData.dashboard.connectorProvider === PROVIDER.OCI
          ? item.NAME
          : item.name
      );
      setServiceAggregatorFilters(allServiceAggregators);
      setSelectedServiceAggregators(allServiceAggregators);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};
