import i18n from 'i18n';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { GraphProperties } from 'types/dashboard';

export const exportToExcel = async (fileName: string, fileDetails: any[]) => {
  const workbook = new Excel.Workbook();

  try {
    // Iterate for each sheet in excel
    fileDetails
      .filter(
        (sheet) =>
          sheet.excludeInGlobalDownload === undefined ||
          !sheet.excludeInGlobalDownload
      )
      .forEach((sheet: any) => {
        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(
          sheet.sheetName.replace(/[\\*?:/[\]]/g, '_')
        );

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = sheet.columns;

        // Add filters applied in the starting rows.
        addFiltersToExcel(sheet, worksheet);

        // Add column headers for data after the filter filter rows
        worksheet.getRow(sheet.filters.length + 2).values = sheet.columns.map(
          (column: any) => column.header
        );

        // updated the font for heading row.
        worksheet.getRow(sheet.filters.length + 2).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column: any) => {
          column.alignment = { horizontal: 'left', wrapText: true };
        });

        // loop through data and add each one to worksheet
        sheet.data.forEach((singleData: any, index: number) => {
          let row = worksheet.addRow(singleData);

          if (sheet.boldRows?.includes(index)) {
            row.eachCell(function (_, colNumber) {
              row.getCell(colNumber).font = { bold: true };
            });
          }
        });

        if (sheet.footer) {
          worksheet.addRow({});
          const footer = worksheet.addRow(sheet.footer);
          footer.eachCell(function (_, colNumber) {
            footer.getCell(colNumber).font = { bold: true };
          });
        }

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, (row: any) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell: any) => {
            // Conditionally add border for table only
            if (
              singleCell._row._number > sheet.filters.length &&
              singleCell._column._number <= sheet.columns.length
            ) {
              // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
              const cellAddress = singleCell._address;

              // Set Alignment for each cell as per the configurations provided in fileDetails columns property.
              let alignment =
                sheet.columns.find(
                  (column: any) => column.key === singleCell._column._key
                )?.alignment || 'left';
              worksheet.getCell(cellAddress).alignment = {
                horizontal: alignment,
              };

              // apply border
              worksheet.getCell(cellAddress).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
            }
          });
        });

        // Auto adjust column widths
        autoSizeColumns(worksheet, sheet);
      });

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error: any) {
    console.error('<<<ERROR>>>', error);
    console.error('Something Went Wrong', error.message);
  }
};

/**
 * @function addFiltersToExcel
 * @description Function to add the filters to the excel sheet
 * @param sheetDetails excel sheet details for which the filter data is added
 * @param worksheet worksheet for which the data is added
 */
const addFiltersToExcel = (sheetDetails: any, worksheet: Excel.Worksheet) => {
  if (sheetDetails.filters) {
    sheetDetails.filters?.forEach((filter: any, index: number) => {
      // Add filter heading
      worksheet.mergeCells(`A${index + 1}`, `B${index + 1}`);
      worksheet.getCell(`A${index + 1}`).value = filter.heading;
      worksheet.getCell(`A${index + 1}`).font = {
        size: 12,
        bold: true,
      };

      let endColumnNumber = sheetDetails.columns.length;
      if (endColumnNumber <= 2) {
        endColumnNumber = 3;
      }

      // Add filter value
      worksheet.mergeCells(
        `C${index + 1}`,
        `${getNameFromNumber(endColumnNumber - 1)}${index + 1}`
      );
      worksheet.getCell(`C${index + 1}`).value = filter.value;
    });
  }
};

/**
 * @function getNameFromNumber
 * @description Converts number to excel column name eg. 1 -> A, 26 -> Z, 28 -> AB
 * @param num - number, index of column
 * @returns string, name of column
 */
const getNameFromNumber = (num: number): string => {
  let numeric = num % 26;
  let letter = String.fromCharCode(65 + numeric);
  let num2 = num / 26;
  if (num2 > 0) {
    return getNameFromNumber(num2 - 1) + letter;
  } else {
    return letter;
  }
};

const autoSizeColumns = (worksheet: Excel.Worksheet, sheetItem: any) => {
  worksheet.columns.forEach((column) => {
    const lengths =
      column?.values?.map((v, index) =>
        index > sheetItem.filters.length ? v?.toString().length ?? 0 : 0
      ) ?? [];
    const maxLength = Math.max(...lengths.filter((v) => typeof v === 'number'));
    // add 5 to the maxLength to add some padding
    column.width = maxLength + 5;
  });
};

/**
 * @function modifyToExportColumns
 * @description Function to modify table columns to excel & pdf export columns
 * @param columns table columns
 * @returns excel/pdf export columns
 */
export const modifyToExportColumns = (columns: any[]) => {
  return columns.map((item: any) => ({
    header: item.title,
    key: item.dataIndex,
    dataKey: item.dataIndex,
  }));
};

/**
 * @function getGlobalExcelExportData
 * @description Function to get the global excel export data for all the charts
 * @param allExcelData the excel data of all the charts
 * @returns the global excel export data
 */
export const getGlobalExcelExportData = (allExcelData: GraphProperties[]) => {
  let filteredData = allExcelData;
  return filteredData
    .filter((graphData) => graphData.excelExport && graphData.tableView)
    .map((graphData) => {
      return getChartExcelExportData(
        graphData.graphHeading,
        graphData.excelExport!.excelData,
        graphData.tableView!.columns,
        graphData.excelExport!.excelFilters
      );
    });
};

/**
 * @function getChartExcelExportData
 * @description Function to get the excel export data for a single chart
 * @param tableName table name
 * @param excelData excel data
 * @param tableColumns table columns
 * @param excelFilters excel filters
 * @returns data for excel export
 */
export const getChartExcelExportData = (
  tableName: string,
  excelData: any[],
  tableColumns: any[],
  excelFilters: NonNullable<GraphProperties['excelExport']>['excelFilters']
) => {
  const filters = [];
  if (excelFilters) {
    excelFilters.connectionName &&
      filters.push({
        heading: i18n.t('excelExportLabels.connectionName'),
        value: excelFilters.connectionName,
      });
    excelFilters.startDate &&
      filters.push({
        heading: i18n.t('excelExportLabels.startDate'),
        value: excelFilters.startDate,
      });
    excelFilters.endDate &&
      filters.push({
        heading: i18n.t('excelExportLabels.endDate'),
        value: excelFilters.endDate,
      });
    excelFilters.month &&
      filters.push({
        heading: i18n.t('excelExportLabels.month'),
        value: excelFilters.month,
      });
    excelFilters.service &&
      filters.push({
        heading: i18n.t('excelExportLabels.service'),
        value: excelFilters.service,
      });
    excelFilters.checkName &&
      filters.push({
        heading: i18n.t('excelExportLabels.checkName'),
        value: excelFilters.checkName,
      });
    excelFilters.project &&
      filters.push({
        heading: i18n.t('excelExportLabels.project'),
        value: excelFilters.project,
      });
    excelFilters.resourceGroup &&
      filters.push({
        heading: i18n.t('excelExportLabels.resourceGroup'),
        value: excelFilters.resourceGroup,
      });
    excelFilters.compartment &&
      filters.push({
        heading: i18n.t('excelExportLabels.compartments'),
        value: excelFilters.compartment,
      });
  }

  return {
    sheetName: tableName,
    columns: modifyToExportColumns(tableColumns),
    data: excelData.map((row: any, index: number) => ({
      ...row,
      index: index + 1,
    })),
    filters: filters,
  };
};
