import { useState } from 'react';
import { Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import {
  selectReport,
  setReportOptions,
  setSelectedReportMetrics,
} from 'redux/reportSlice';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Input from 'components/Input';
import { ICONS } from 'constants/icons';
import { isAConnectionWithBillingDatasource } from 'pages/CreateReportPage/utils';
import { ColumnType } from 'pages/ReportsPage/types';
import { INPUT_SIZE } from 'constants/appearance';
import Empty from 'components/Empty';

import MetricsList from '../MetricsList';
import MetricsWithCategories from '../MetricsWithCategories';

import './index.scss';

const Metrics = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reportMetrics, selectedReportMetrics, reportOptions } =
    useSelector(selectReport);

  const [searchKey, setSearchKey] = useState('');
  const [showMetricsDropdown, setShowMetricsDropdown] = useState(false);

  const onChangeMetricsCheckbox = (e: CheckboxChangeEvent, metric: string) => {
    let selectedValues: ColumnType[];

    if (e.target.checked) {
      selectedValues = [
        ...selectedReportMetrics,
        { label: metric, field: metric },
      ];
    } else {
      selectedValues = selectedReportMetrics.filter(
        (item) => item.field !== metric
      );
    }

    dispatch(setSelectedReportMetrics(selectedValues));
  };

  const MetricDropdownContent = reportMetrics.length ? (
    reportMetrics
      .filter((fieldItem) =>
        fieldItem.name.toLowerCase().includes(searchKey.toLowerCase())
      )
      .map((fieldItem) => (
        <Checkbox
          key={fieldItem.name}
          checked={selectedReportMetrics
            .map((metric) => metric.field)
            .includes(fieldItem.name)}
          onChange={(e) => onChangeMetricsCheckbox(e, fieldItem.name)}
        >
          {fieldItem.name}
        </Checkbox>
      ))
  ) : (
    <Empty />
  );

  return (
    <div className="report-table-metrics">
      <div className="metrics-heading flex flex-align-items-center flex-space-between">
        <div className="font-caption-bold">{t('reports.metrics')}</div>
        <Tooltip
          overlayClassName="metrics-dropdown"
          overlay={
            <div className="flex flex-column flex-gap-8">
              <Input
                type="search"
                rootClassName="full-width"
                placeholder={t('reports.searchMetrics')}
                size={INPUT_SIZE.SMALL}
                value={searchKey}
                onChange={(e: any) => setSearchKey(e.target.value)}
                autoFocus
              />
              <div className="report-metrics-group  flex flex-column flex-gap-8 new-styled-scroll">
                {isAConnectionWithBillingDatasource() ? (
                  <MetricsWithCategories searchKey={searchKey} />
                ) : (
                  MetricDropdownContent
                )}
              </div>
            </div>
          }
          placement="bottomRight"
          open={showMetricsDropdown}
          onOpenChange={(visible: any) => {
            if (visible) {
              setSearchKey('');
            } else {
              dispatch(
                setReportOptions({
                  ...reportOptions,
                  metric: selectedReportMetrics.map((item) => item.label),
                })
              );
            }
            setShowMetricsDropdown(visible);
          }}
          trigger="click"
          arrow={false}
        >
          <Icon
            className="add-icon"
            iconName={ICONS.ADD_LINE}
            dataTestId="add-metric"
          />
        </Tooltip>
      </div>
      <MetricsList />
    </div>
  );
};

export default Metrics;
