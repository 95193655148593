import { useTranslation } from 'react-i18next';

import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { evaluateRequestArray } from 'utils/handleErrors';
import { KeyValueTypes } from 'types/dataTypes';

type ResourceDetailsProps = {
  graphHeading: string;
  graphName: string;
  resourceDetails: KeyValueTypes[];
  pdfView: boolean;
  requestStatus: string[];
  selectedRecommendationType: string | undefined;
  resourceId: string | undefined;
  errorMessage?: string;
};

const ResourceDetails = ({
  graphHeading,
  graphName,
  resourceDetails,
  pdfView,
  requestStatus,
  selectedRecommendationType,
  resourceId,
  errorMessage,
}: ResourceDetailsProps) => {
  const { t } = useTranslation();

  /**
   * @function getComponent
   * @description Function to return the graph or table component
   * @returns JSX element containing graph or the table
   */
  const getComponent = () => {
    if (!selectedRecommendationType) {
      return (
        <div className="flex flex-column flex-center full-height no-chart-selection">
          <div className="font-body heading">
            {t(
              'costOptimizationInsight.statusDashboard.recommendationStatusTable.noDataFound'
            )}
          </div>
          <div className="font-caption sub-heading">
            {t(
              'costOptimizationInsight.statusDashboard.recommendationStatusTable.selectChartsToPopulateData'
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="graph resource-data new-styled-scroll">
        <div className="graph-heading-typography">{resourceId}</div>
        <div className="data flex flex-column flex-gap-8">
          {resourceDetails.map((item) => (
            <div key={item.key} className="flex flex-gap-8">
              <div className="font-caption-bold">{item.key}:</div>
              <div className="value font-validation-error">{item.value}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className="graph-area full-width flex flex-column"
      id="graph-container"
    >
      <GraphHeader
        heading={graphHeading}
        graphName={graphName}
        isTableViewSwitch={false}
        showExpandIcon={!pdfView}
        isDownloadable={false}
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray(requestStatus)}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default ResourceDetails;
