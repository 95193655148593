import i18n from 'i18n';
import { uniqBy } from 'lodash';
import { insertIndex } from 'utils/dataFormatterUtils';
import {
  getCostTrendExcelData,
  getNamespaceUsageCostExcelData,
  getClusterCostExcelData,
} from 'pages/ContainerInsightsPage/utils';
import {
  NamespaceUsageCostType,
  ClusterCostType,
} from 'pages/ContainerInsightsPage/types';
import { MonthlyCostType, DateRangeFilter } from 'types/dataTypes';
import { PodUsageCostExportColumns } from '../constants';
import {
  ClusterBreakdownType,
  EffectiveCpuUtilizationType,
  PodUsageCostType,
  EffectiveCpuUtilizationByClusterType,
} from '../types';

/**
 * @function getClusterBreakdownExportColumns
 * @description Function to return the cluster breakdown excel export columns
 * @param clusterBreakdownData List of cluster breakdown cost data
 * @returns List of objects containing excel columns details
 */
export const getClusterBreakdownExportColumns = (
  clusterBreakdownData: ClusterBreakdownType[]
) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.clusters'),
    key: 'cluster',
    dataKey: 'cluster',
    width: 30,
  },
  ...uniqBy(clusterBreakdownData, 'time').map((item) => ({
    header: item.time,
    key: `${item.time}-cost`,
    dataKey: `${item.time}-cost`,
    width: 15,
    alignment: 'right',
  })),
];

/**
 * @function getClusterBreakdownExcelData
 * @description Function to return the cluster breakdown excel data
 * @param clusterBreakdownData List of cluster breakdown cost data
 * @param tableData Cluster breakdown table data
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getClusterBreakdownExcelData = (
  clusterBreakdownData: ClusterBreakdownType[],
  tableData: any,
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.clusterBreakdown'),
    columns: getClusterBreakdownExportColumns(clusterBreakdownData),
    data: insertIndex(tableData),
    filters: filters,
  };
};

/**
 * @function getEffectiveCpuUtilizationExportColumns
 * @description Function to return the effective cpu utilization excel export columns
 * @param effectiveCPUUtilizationData List of effective cpu utilization data
 * @returns List of objects containing excel columns details
 */
export const getEffectiveCpuUtilizationExportColumns = (
  effectiveCPUUtilizationData: EffectiveCpuUtilizationType[]
) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.cluster'),
    key: 'cluster',
    dataKey: 'cluster',
    width: 20,
  },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.namespace'),
    key: 'namespace',
    dataKey: 'namespace',
    width: 20,
  },
  ...uniqBy(effectiveCPUUtilizationData, 'time').map((item) => ({
    header: item.time,
    key: `${item.time}-utilization`,
    dataKey: `${item.time}-utilization`,
    width: 15,
    alignment: 'right',
  })),
];

/**
 * @function getEffectiveCpuUtilizationExcelData
 * @description Function to return the effective CPU utilization excel data
 * @param effectiveCPUUtilizationData List of effective CPU utilization data
 * @param tableData data to be exported
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getEffectiveCpuUtilizationExcelData = (
  effectiveCPUUtilizationData: EffectiveCpuUtilizationType[],
  tableData: EffectiveCpuUtilizationByClusterType[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.effectiveCpuUtilization'),
    columns: getEffectiveCpuUtilizationExportColumns(
      effectiveCPUUtilizationData
    ),
    data: tableData,
    filters: filters,
  };
};

/**
 * @function getPodUsageCostExcelData
 * @description Function to return the pod usage and cost excel data
 * @param podUsageCostData List of pod usage and cost data
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getPodUsageCostExcelData = (
  podUsageCostData: PodUsageCostType[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.effectiveMemoryUtilizationByPod'),
    columns: PodUsageCostExportColumns,
    data: insertIndex(podUsageCostData),
    filters: filters,
  };
};

/**
 * @function getUsageMeteringExcelData
 * @description Function to return the excel export data for usage metering dashboard
 * @param costTrendByNamespace List of cost trend data
 * @param projectClusterFilters List of global filters
 * @param clusterBreakdown Object containing the cluster breakdown data and filters
 * @param clusterCost Object containing the cluster cost data and filters
 * @param effectiveCPUUtilization Object containing the effective CPU Utilization cost data and filters
 * @param namespaceUsage Object containing the Namespace Usage and cost data and filters
 * @param podUsageCost Object containing the Pod Usage and cost data and filters.
 * @returns List of objects containing excel export details
 */
export const getUsageMeteringExcelData = (
  costTrendByNamespace: MonthlyCostType[],
  projectClusterFilters: any[],
  clusterBreakdownAndClusterCost: {
    clusterBreakdownData: ClusterBreakdownType[];
    tableData: any;
    clusterBreakdownDateFilter: DateRangeFilter;
    clusterCostData: ClusterCostType[];
    clusterCostDateFilter: DateRangeFilter;
  },
  effectiveCPUUtilization: {
    effectiveCPUUtilizationData: EffectiveCpuUtilizationType[];
    tableData: EffectiveCpuUtilizationByClusterType[];
    effectiveCPUUtilizationDateFilter: DateRangeFilter;
  },
  namespaceUsage: {
    namespaceUsageCostData: NamespaceUsageCostType[];
    namespaceUsageCostDateFilter: DateRangeFilter;
    selectedNamespaces: string[];
  },
  podUsageCost: {
    podUsageCostData: PodUsageCostType[];
    podUsageCostDateFilter: DateRangeFilter;
    selectedPods: string[];
  }
) => {
  return [
    getCostTrendExcelData(costTrendByNamespace, projectClusterFilters),
    getClusterBreakdownExcelData(
      clusterBreakdownAndClusterCost.clusterBreakdownData,
      clusterBreakdownAndClusterCost.tableData,
      [
        ...projectClusterFilters,
        {
          heading: i18n.t('excelExportLabels.startDate'),
          value:
            clusterBreakdownAndClusterCost.clusterBreakdownDateFilter.startDate,
        },
        {
          heading: i18n.t('excelExportLabels.endDate'),
          value:
            clusterBreakdownAndClusterCost.clusterBreakdownDateFilter.endDate,
        },
      ]
    ),
    getClusterCostExcelData(clusterBreakdownAndClusterCost.clusterCostData, [
      ...projectClusterFilters,
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: clusterBreakdownAndClusterCost.clusterCostDateFilter.startDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: clusterBreakdownAndClusterCost.clusterCostDateFilter.endDate,
      },
    ]),
    getEffectiveCpuUtilizationExcelData(
      effectiveCPUUtilization.effectiveCPUUtilizationData,
      effectiveCPUUtilization.tableData,
      [
        ...projectClusterFilters,
        {
          heading: i18n.t('excelExportLabels.startDate'),
          value:
            effectiveCPUUtilization.effectiveCPUUtilizationDateFilter.startDate,
        },
        {
          heading: i18n.t('excelExportLabels.endDate'),
          value:
            effectiveCPUUtilization.effectiveCPUUtilizationDateFilter.endDate,
        },
      ]
    ),
    getNamespaceUsageCostExcelData(namespaceUsage.namespaceUsageCostData, [
      ...projectClusterFilters,
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: namespaceUsage.namespaceUsageCostDateFilter.startDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: namespaceUsage.namespaceUsageCostDateFilter.endDate,
      },
      {
        heading: i18n.t('excelExportLabels.namespaces'),
        value: namespaceUsage.selectedNamespaces.toString(),
      },
    ]),
    getPodUsageCostExcelData(podUsageCost.podUsageCostData, [
      ...projectClusterFilters,
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: podUsageCost.podUsageCostDateFilter.startDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: podUsageCost.podUsageCostDateFilter.endDate,
      },
      {
        heading: i18n.t('excelExportLabels.pods'),
        value: podUsageCost.selectedPods.toString(),
      },
    ]),
  ];
};
