import {
  AutoComplete as BaseAutoComplete,
  AutoCompleteProps as BaseAutoCompleteProps,
} from 'antd';
import { Variants } from 'antd/es/form/hooks/useVariants';
import type { BaseSelectRef } from 'rc-select';
import { forwardRef } from 'react';

import { INPUT_SIZE } from 'constants/appearance';
import { getClassnameBySize } from 'utils/dashboardUtils';

type AutoCompleteProps = BaseAutoCompleteProps & {
  inputSize?: string;
  additionalClassNames?: string;
  borderless?: boolean;
};

const AutoComplete = forwardRef<BaseSelectRef, AutoCompleteProps>(
  (
    {
      inputSize = INPUT_SIZE.MEDIUM,
      additionalClassNames = '',
      borderless = false,
      ...remainingProps
    },
    ref
  ) => {
    return (
      <BaseAutoComplete
        {...remainingProps}
        className={`${getClassnameBySize(
          inputSize
        )} ${additionalClassNames} design-v2`}
        variant={borderless ? Variants[2] : undefined}
        ref={ref}
      />
    );
  }
);

export default AutoComplete;
