import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchPurchasedGcpCudList
 * @description Function to fetch purchased GCP CUD list
 * @param params - query params
 * @return axios response from GET request
 */
export const fetchPurchasedGcpComputeEngineList = async (params: any) => {
  return ServiceCalls.get(APIS.GET_GCP_PURCHASED_CUD_LIST, null, params);
};

/**
 * @function fetchGcpRegions
 * @description Function to fetch GCP regions
 * @param params - query params
 * @param cancelToken - cancel token
 * @return axios response from GET request
 */
export const fetchGcpRegions = async (params: any, cancelToken: any) => {
  return ServiceCalls.get(
    APIS.GET_GCP_COMPUTE_ENGINE_REGIONS,
    null,
    params,
    cancelToken
  );
};

/**
 * @function fetchGcpCommitmentTypes
 * @description Function to fetch GCP commitment types
 * @param params - query params
 * @param cancelToken - cancel token
 * @return axios response from GET request
 */
export const fetchGcpComputeEngineCommitmentTypes = async (
  params: any,
  cancelToken: any
) => {
  return ServiceCalls.get(
    APIS.GET_GCP_COMPUTE_ENGINE_COMMITMENT_TYPES,
    null,
    params,
    cancelToken
  );
};

/**
 * @function fetchGcpComputeEngineCostEstimates
 * @description Function to fetch GCP compute engine cost estimates
 * @param params - query params
 * @param body - request body
 * @param cancelToken - cancel token
 * @return axios response from POST request
 */
export const fetchGcpComputeEngineCostEstimates = async (
  params: any,
  body: any,
  cancelToken: any
) => {
  return ServiceCalls.post(
    APIS.GET_GCP_COMPUTE_ENGINE_COST_ESTIMATES,
    body,
    null,
    params,
    cancelToken
  );
};

/**
 * @function purchaseGcpComputeEngine
 * @description Function to purchase GCP Compute Engine
 * @param params - query params
 * @param body - request body
 * @return axios response from POST request
 */
export const purchaseGcpComputeEngine = async (params: any, body: any) => {
  return ServiceCalls.post(
    APIS.PURCHASE_GCP_COMPUTE_ENGINE_COST_ESTIMATES,
    body,
    null,
    params
  );
};
