import i18n from 'i18n';

export enum GranulateQuickActions {
  DEACTIVATE = 'DEACTIVATE',
  ACTIVATE = 'ACTIVATE',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  UNINSTALL = 'UNINSTALL',
}

export const GRANULATE_CONNECTION_QUICKACTIONS = [
  {
    id: GranulateQuickActions.DEACTIVATE,
    title: i18n.t('granulateConnectionPage.deactivate'),
  },
  {
    id: GranulateQuickActions.ACTIVATE,
    title: i18n.t('granulateConnectionPage.activate'),
  },
  {
    id: GranulateQuickActions.DELETE,
    title: i18n.t('granulateConnectionPage.delete'),
  },
  {
    id: GranulateQuickActions.EDIT,
    title: i18n.t('granulateConnectionPage.edit'),
  },
  {
    id: GranulateQuickActions.UNINSTALL,
    title: i18n.t('granulateConnectionPage.uninstall'),
  },
];
