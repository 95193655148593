import i18n from 'i18n';

export const MonthlyCarbonEmissionExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10 },
  {
    header: i18n.t('carbonFootprint.monthlyCarbonEmission.month'),
    key: 'month',
    dataKey: 'month',
    width: 15,
  },
  {
    header: i18n.t(
      'carbonFootprint.monthlyCarbonEmission.carbonEmissionKgco2e'
    ),
    key: 'carbonFootprint',
    dataKey: 'carbonFootprint',
    width: 30,
  },
];

export const CarbonEmissionByProjectExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10 },
  {
    header: i18n.t('carbonFootprint.carbonEmissionByProjects.project'),
    key: 'project',
    dataKey: 'project',
    width: 15,
  },
  {
    header: i18n.t(
      'carbonFootprint.monthlyCarbonEmission.carbonEmissionKgco2e'
    ),
    key: 'carbonFootprint',
    dataKey: 'carbonFootprint',
    width: 30,
  },
];

export const CarbonEmissionByServiceExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10 },
  {
    header: i18n.t('carbonFootprint.monthlyCarbonEmission.service'),
    key: 'service',
    dataKey: 'service',
    width: 15,
  },
  {
    header: i18n.t(
      'carbonFootprint.monthlyCarbonEmission.carbonEmissionKgco2e'
    ),
    key: 'carbonFootprint',
    dataKey: 'carbonFootprint',
    width: 30,
  },
];

export const CarbonEmissionByRegionExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10 },
  {
    header: i18n.t('carbonFootprint.monthlyCarbonEmission.region'),
    key: 'region',
    dataKey: 'region',
    width: 15,
  },
  {
    header: i18n.t(
      'carbonFootprint.monthlyCarbonEmission.carbonEmissionKgco2e'
    ),
    key: 'carbonFootprint',
    dataKey: 'carbonFootprint',
    width: 30,
  },
];
