import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AccessibleDiv from 'components/AccessibleDiv';
import GraphBreadcrumb from 'components/GraphBreadcrumb';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';

import './index.scss';

type SavingsChartHeadingProps = {
  month: string | null;
  setMonth: (val: string | null) => void;
  isTableViw: boolean;
  isPdfView: boolean;
};

const SavingsChartHeading = ({
  month,
  setMonth,
  isTableViw,
  isPdfView,
}: SavingsChartHeadingProps) => {
  const { t } = useTranslation();

  const [breadcrumbPaths, setBreadcrumbPaths] = useState<string[]>([]);

  useEffect(() => {
    if (!month) {
      setBreadcrumbPaths([]);
    } else {
      setBreadcrumbPaths([
        t('graphHeadings.autoPilotSavingsByCategory') + ' - ' + month,
        t('dailyCost'),
      ]);
    }
  }, [month]);

  if (!month) {
    return (
      <div className="graph-heading flex flex-column">
        <span>{t('graphHeadings.autoPilotSavingsByCategory')}</span>
        {!isTableViw && !isPdfView && (
          <span className="sub-title font-caption-bold">
            {t('clickBarInfo')}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="graph-heading flex flex-column">
      <AccessibleDiv
        className="flex flex-align-items-center cursor-pointer"
        onClick={() => setMonth(null)}
        data-testid="back-nav-cta"
      >
        <Icon
          iconName={ICONS.ARROW_LEFT_S_LINE}
          className="previous-icon flex flex-end"
        />
        <span>{t('graphHeadings.autoPilotSavingsByCategory')}</span>
      </AccessibleDiv>
      <GraphBreadcrumb pathItems={breadcrumbPaths} />
    </div>
  );
};

export default SavingsChartHeading;
