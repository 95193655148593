import { store } from 'redux/store';
import { AGGREGATORS } from 'constants/requestBody';
import {
  FIELD_TYPE,
  FieldSource,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import {
  ChartType,
  ColumnType,
  FilterGroupType,
  FilterType,
  LayoutDesignsType,
  OrderByType,
} from 'types/dashboard';
import { setCustomViewData } from 'redux/customDashboardSlice';

/**
 * @function getSelectedChartLayout
 * @description Function to fetch the selected chart layout from the customViewData
 * @returns layout data or undefined
 */
export const getSelectedChartLayout = () => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;

  if (customDashboardState.customViewData.layoutDesigns.length > 0) {
    return customDashboardState.customViewData.layoutDesigns.find(
      (layout) => layout.position === customDashboardState.selectedChartRow
    );
  }
};

/**
 * @function getSelectedChart
 * @description Function to fetch the selected chart from the customViewData
 * @returns chart data or undefined
 */
export const getSelectedChart = () => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;

  let charts: ChartType[] | undefined = getSelectedChartLayout()?.charts;
  if (charts && charts.length > 0) {
    return charts.find(
      (chart) => chart.chartPosition === customDashboardState.selectedChartIndex
    );
  }
};

/**
 * @function getTableColumns
 * @description Function to fetch the selected chart table columns
 * @returns list of table columns or undefined
 */
export const getTableColumns = () => {
  return getSelectedChart()?.tableQuery?.columns;
};

/**
 * @function getTableDimensions
 * @description Function to fetch the selected chart table dimensions
 * @returns list of table dimensions or undefined
 */
export const getTableDimensions = () => {
  return getSelectedChart()?.tableQuery?.groupBy;
};

/**
 * @function getTableMetrics
 * @description Function to fetch the selected chart table metrics
 * @returns list of table metrics or undefined
 */
export const getTableMetrics = () => {
  return getSelectedChart()?.tableQuery?.aggregators;
};

/**
 * @function getTableSorts
 * @description Function to fetch the selected chart table sorts
 * @returns list of table metrics or undefined
 */
export const getTableSorts = (chart?: ChartType) => {
  return (chart ?? getSelectedChart())?.tableQuery?.orderBy ?? [];
};

/**
 * @function getChartConditions
 * @description Function to fetch the selected chart table conditions
 * @returns list of conditions or an empty array
 */
export const getChartConditions = (chart?: ChartType) => {
  return (chart ?? getSelectedChart())?.conditions ?? [];
};

/**
 * @function getFirstTableFilterGroup
 * @description Function to fetch the selected chart table first filter group
 * @returns list of table metrics or undefined
 */
export const getFirstTableFilterGroup = (chart?: ChartType) => {
  if (chart) return chart.tableQuery?.filterGroups?.at(0)?.filters ?? [];
  return getSelectedChart()?.tableQuery?.filterGroups?.at(0)?.filters ?? [];
};

/**
 * @function getColumnLabelByField
 * @description Function to fetch the column label by field
 * @param columnField Column field
 * @param chart Chart data
 * @returns column label or undefined
 */
export const getColumnLabelByField = (
  columnField: string,
  chart?: ChartType
) => {
  const columnProperties = (
    chart ?? getSelectedChart()
  )?.tableQuery?.columns?.find((column) => column.field === columnField);

  if (!columnProperties) return columnField;
  if (columnProperties.dimensionType === FieldSource.TAGS)
    return `${columnProperties.label} (${columnProperties.tagDimensionType})`;
  return columnProperties.label;
};

/**
 * @function getFilterForColumn
 * @description Function to fetch the filter inside filter groups for a column
 * @param columnField Column field
 * @param chart Chart data
 * @returns filter or undefined
 */
export const getFilterForColumn = (columnField: string, chart?: ChartType) => {
  return (chart ?? getSelectedChart())?.tableQuery?.filterGroups
    ?.at(0)
    ?.filters.find((filter) => filter.field === columnField);
};

/**
 * @function getFieldCategory
 * @description get category of a particular field from custom view available fields
 * @param field field name
 * @returns field category as FIELD_TYPE
 */
export const getFieldCategory = (field: string) => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  return (
    customDashboardState.customViewData.dataSource.availableFields.find(
      (dimension) => field === dimension.name || field === dimension.field
    )?.category ??
    customDashboardState.customViewDimensions.find(
      (dimension) => field === dimension.name || field === dimension.field
    )?.category ??
    customDashboardState.customViewMetrics.find(
      (dimension) => field === dimension.name || field === dimension.field
    )?.category ??
    FIELD_TYPE.LITERAL
  );
};

/**
 * @function getAllAvailableValues
 * @description get all available values for a particular field
 * @param field field name
 * @returns field category as FIELD_TYPE
 */
export const getAllAvailableValues = (field: string) => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  return (
    customDashboardState.allFieldValuesMap.find((map) => map.field === field)
      ?.values ?? []
  );
};

/**
 * @function dispatchUpdatedCustomViewData
 * @description Function to fetch the updated custom view data when dimensions are added or removed
 * @returns updated custom view data
 */
export const dispatchUpdatedCustomViewData = () => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  const {
    customViewData,
    selectedChartRow,
    selectedChartIndex,
    selectedDimensions,
    selectedMetrics,
  } = customDashboardState;

  const layoutDesigns: LayoutDesignsType[] = [
    ...(customViewData?.layoutDesigns || []),
  ];
  const updatedCharts: ChartType[] =
    layoutDesigns
      .find((row) => row.position === selectedChartRow)
      ?.charts?.map((eachChart) => {
        if (eachChart.chartPosition === selectedChartIndex) {
          const newColumns: ColumnType[] = [
            ...selectedDimensions.map((dimension) => ({
              label: dimension.label,
              field: dimension.field,
              dimensionType: dimension.dimensionType,
              tagDimensionType: dimension.tagDimensionType,
            })),
            ...selectedMetrics.map((metric) => ({
              label: metric.label,
              field: metric.field,
              dimensionType: metric.dimensionType,
            })),
          ];

          let newFilterGroup: FilterGroupType[] = [
            {
              filters:
                eachChart?.tableQuery?.filterGroups
                  ?.at(0)
                  ?.filters.filter((filter) =>
                    selectedDimensions
                      .map((dimensions) => dimensions.field)
                      .includes(filter.field)
                  ) ?? [],
            },
          ];
          if (!newFilterGroup.at(0)?.filters.length) {
            newFilterGroup = [];
          }
          return {
            ...eachChart,
            tableQuery: {
              ...eachChart.tableQuery!,
              columns: newColumns,
              groupBy: selectedDimensions.map((dimension) => dimension.field),
              aggregators: selectedMetrics.map((metric) => ({
                label: metric.field,
                function: AGGREGATORS.SUM,
              })),
              orderBy: eachChart.tableQuery?.orderBy?.filter((order) =>
                [...selectedMetrics, ...selectedDimensions]
                  .map((metric) => metric.field)
                  .includes(order.label)
              ),
              filterGroups: newFilterGroup,
            },
            conditions: eachChart.conditions?.filter((condition) =>
              [...selectedDimensions, ...selectedMetrics]
                .map((dimension) => dimension.field)
                .includes(condition.field)
            ),
          };
        } else {
          return { ...eachChart };
        }
      }) ?? [];

  layoutDesigns.splice(selectedChartRow, 1, {
    charts: updatedCharts,
    position: selectedChartRow,
  });

  store.dispatch(
    setCustomViewData({
      ...customViewData,
      layoutDesigns: layoutDesigns,
    })
  );
};

/**
 * @function updateSortQuery
 * @description Updates the sorting query with the new sort query
 * @param newSorts New sort query
 */
export const updateSortQuery = (newSorts: OrderByType[]) => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  const { customViewData, selectedChartRow, selectedChartIndex } =
    customDashboardState;
  store.dispatch(
    setCustomViewData({
      ...customViewData,
      layoutDesigns: customViewData?.layoutDesigns?.map((row) => {
        if (row.position === selectedChartRow) {
          return {
            ...row,
            charts: row?.charts?.map((chart) => {
              if (chart.chartPosition === selectedChartIndex) {
                return {
                  ...chart,
                  tableQuery: {
                    ...chart.tableQuery,
                    orderBy: newSorts,
                  },
                };
              }
              return chart;
            }),
          };
        }
        return row;
      }),
    })
  );
};

/**
 * @function removePartialSortQuery
 * @description Removes the sorts with empty label or empty sort value
 */
export const removePartialSortQuery = () => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  const { customViewData, selectedChartRow, selectedChartIndex } =
    customDashboardState;
  store.dispatch(
    setCustomViewData({
      ...customViewData,
      layoutDesigns: customViewData?.layoutDesigns?.map((row) => {
        if (row.position === selectedChartRow) {
          return {
            ...row,
            charts: row?.charts?.map((chart) => {
              if (chart.chartPosition === selectedChartIndex) {
                return {
                  ...chart,
                  tableQuery: {
                    ...chart.tableQuery,
                    orderBy: getTableSorts().filter(
                      (sort) => sort.label && sort.sort
                    ),
                  },
                };
              }
              return chart;
            }),
          };
        }
        return row;
      }),
    })
  );
};

/**
 * @function updateFilterQuery
 * @description Updates the filter group query with the new filter group
 * @param newFilters New filter group query
 * @param chartRow Row index of the chart to update for
 * @param chartIndex Chart index of the chart to update for
 */
export const updateFilterQuery = (
  newFilters: FilterType[],
  chartRow?: number,
  chartIndex?: number
) => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  const { customViewData, selectedChartRow, selectedChartIndex } =
    customDashboardState;
  chartRow = chartRow ?? selectedChartRow;
  chartIndex = chartIndex ?? selectedChartIndex;
  store.dispatch(
    setCustomViewData({
      ...customViewData,
      layoutDesigns: customViewData?.layoutDesigns?.map((row) => {
        if (row.position === chartRow) {
          return {
            ...row,
            charts: row?.charts?.map((chart) => {
              if (chart.chartPosition === chartIndex) {
                return {
                  ...chart,
                  tableQuery: {
                    ...chart.tableQuery,
                    filterGroups: [{ filters: newFilters }],
                  },
                };
              }
              return chart;
            }),
          };
        }
        return row;
      }),
    })
  );
};

/**
 * @function updateConditionsQuery
 * @description Updates the conditions array with the new conditions array
 * @param newConditions New conditions array
 */
export const updateConditionsQuery = (newConditions: FilterType[]) => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  const { customViewData, selectedChartRow, selectedChartIndex } =
    customDashboardState;
  store.dispatch(
    setCustomViewData({
      ...customViewData,
      layoutDesigns: customViewData?.layoutDesigns?.map((row) => {
        if (row.position === selectedChartRow) {
          return {
            ...row,
            charts: row?.charts?.map((chart) => {
              if (chart.chartPosition === selectedChartIndex) {
                return {
                  ...chart,
                  conditions: newConditions,
                };
              }
              return chart;
            }),
          };
        }
        return row;
      }),
    })
  );
};

/**
 * @function removePartialConditionsQuery
 * @description Removes the conditions with empty field or empty selected values or empty comparator
 */
export const removePartialConditionsQuery = () => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  const { customViewData, selectedChartRow, selectedChartIndex } =
    customDashboardState;
  store.dispatch(
    setCustomViewData({
      ...customViewData,
      layoutDesigns: customViewData?.layoutDesigns?.map((row) => {
        if (row.position === selectedChartRow) {
          return {
            ...row,
            charts: row?.charts?.map((chart) => {
              if (chart.chartPosition === selectedChartIndex) {
                return {
                  ...chart,
                  conditions: getChartConditions().filter(
                    (condition) =>
                      condition.field &&
                      condition.selectedValues &&
                      condition.comparator &&
                      condition.conjunctToNextFilter
                  ),
                };
              }
              return chart;
            }),
          };
        }
        return row;
      }),
    })
  );
};

/**
 * @function doesDatasourceSupportBillingMapping
 * @description Function to check if the datasource supports billing mapping
 * @returns boolean true if datasource is single connection and billing dataset is selected
 * and custom query is not selected
 */
export const doesDataSourceSupportBillingMapping = () => {
  const state = store.getState();
  const customDashboardState = state.customDashboard;
  const {
    customDashboardDataSource,
    customDashboardConnection,
    dashboardType,
  } = customDashboardState;
  return (
    customDashboardConnection?.billingDataset ===
      customDashboardDataSource.dataSet &&
    !customDashboardDataSource.useCustomQuery &&
    dashboardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION
  );
};
