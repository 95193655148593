import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { userAuthorization } from 'redux/authorizationSlice';
import {
  costOptimizationInsights,
  setApplicableRecommendations,
  setConsolidatedExportData,
  setSelectedRecommendations,
} from 'redux/costOptimizationInsightsSlice';
import RadioGroup from 'components/RadioGroup';
import {
  IncidentRecommendationStatusLabels,
  ORDERED_SERVICE_NOW_STATUS_LIST,
  SERVICE_NOW_STATUS_LABELS,
  ServiceNowStatus,
} from 'constants/workflowIntegrations';

import {
  CategoryRecommenderMappingType,
  ConsolidatedStatusFilterType,
  IncidentRecommendationMappingType,
} from 'pages/CostOptimizationInsightsPage/types';
import ApplyAwsRecommendation from 'pages/CostOptimizationInsightsPage/components/AWS/ApplyAwsRecommendation';
import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { DATE_TIME_AM_PM } from 'utils/date';
import { hasRulesetFilters } from 'pages/CostOptimizationInsightsPage/utils';
import {
  RECOMMENDATION_TABLE_QUICK_ACTIONS,
  RecommendationSource,
  RECOMMENDATION_STATUS_LABELS,
  RecommendationTableActions,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  FilterDropdownProps,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import TableFilterDropdown from 'components/TableFilterDropdown';
import QuickActionMenu from 'components/QuickActionMenu';
import TicketInfoDrawer from 'components/TicketInfoDrawer';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getProviderForConnection } from 'utils/dashboardUtils';
import { ProjectNumberIdMappingType } from 'types/dashboard';
import { PROVIDER } from 'constants/cloudProviders';
import { INFINITE_SCROLL_PAGE_SIZE } from 'constants/userConsole';
import { UNKNOWN_VALUE } from 'constants/utilityConstants';
import { RulesetFilterGroupsType } from 'types/dataTypes';

import RecommendationsTable from '../RecommendationsTable';
import { RecommendationList } from '../../types';
import {
  addServiceNowStatusToRecommendationData,
  filterRecommendationTableDataSource,
  getAllRecommenderMapping,
  getRecommendationList,
  getTotalRecommendationsCount,
  getRulesetViewFilters,
  checkRecordStatusForApplyRec,
  isRecommendationApplyEligible,
  fetchApplicableRecommenders,
  applyRecommendation,
  getGranulateConnectionForCspConnection,
  getAllGranulateRecommendations,
  getRecommendationDataForExport,
  hasRecommendationFilters,
} from './utils';
import StatusElement from '../StatusElement';
import { GranulateConnectionsType } from './types';

import './index.scss';

type RecommendationTableWithWorkFlowProps = {
  serviceNowTickets: IncidentRecommendationMappingType[];
  snowTicketsRequestStatus: string;
  fetchSnowIncidents: () => void;
  projectNumberIdMapping?: ProjectNumberIdMappingType[];
};

const RecommendationTableWithWorkFlow = ({
  serviceNowTickets,
  snowTicketsRequestStatus,
  fetchSnowIncidents,
  projectNumberIdMapping = [],
}: RecommendationTableWithWorkFlowProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permissions } = useSelector(userAuthorization);
  const {
    selectedCostOptimizationInsightsConnection,
    currentRecommendationView,
    viewList,
    selectedCostOptimizationInsightsNav,
    applicableRecommendations,
  } = useSelector(costOptimizationInsights);

  const [selectedSnowStatus, setSelectedSnowStatus] = useState(
    ServiceNowStatus.TICKET_TBC
  );
  const [categoryRecommenderMapping, setCategoryRecommenderMapping] =
    useState<CategoryRecommenderMappingType[]>();
  const [
    categoryRecommenderMappingReqStatus,
    setCategoryRecommenderMappingReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [granulateConnections, setGranulateConnections] =
    useState<GranulateConnectionsType[]>();
  const [granulateConnectionsReqStatus, setGranulateConnectionsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [granulateRecommendations, setGranulateRecommendations] = useState<
    RecommendationList[]
  >([]);
  const [
    granulateRecommendationsReqStatus,
    setGranulateRecommendationsReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [rawCspRecommendationData, setRawCspRecommendationData] = useState<
    RecommendationList[]
  >([]);
  const [
    recommendationDataWithTicketInfo,
    setRecommendationDataWithTicketInfo,
  ] = useState<RecommendationList[]>([]);
  const [recommendationData, setRecommendationData] = useState<
    RecommendationList[]
  >([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [
    nextPageRawCspRecommendationData,
    setNextPageRawCspRecommendationData,
  ] = useState<RecommendationList[]>([]);
  const [recommendationDataRequestStatus, setRecommendationDataRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [rulesetFilters, setRulesetFilters] = useState<
    RulesetFilterGroupsType[]
  >([]);
  const [rulesetFiltersRequestStatus, setRulesetFiltersRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [currentPage, setCurrentPage] = useState(-1);
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<RecommendationList>();
  const [showTicketInfoDrawer, setShowTicketInfoDrawer] = useState(false);
  const [statusFilters, setStatusFilters] =
    useState<ConsolidatedStatusFilterType>({
      incidentStatus: [],
      incidentRecommendationStatus: [],
      recommendationStatus: [],
      recommendationSource: [],
    });
  const [showConfirmApplyRecommendation, setShowConfirmApplyRecommendation] =
    useState(false);
  const [
    applyRecommendationRequestStatus,
    setApplyRecommendationRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    resetRecommendationTable();
    setCategoryRecommenderMapping(undefined);
    dispatch(setApplicableRecommendations([]));
    setGranulateConnections(undefined);
    setRulesetFilters([]);

    if (selectedCostOptimizationInsightsConnection) {
      getAllRecommenderMapping(
        getProviderForConnection(selectedCostOptimizationInsightsConnection),
        setCategoryRecommenderMapping,
        setCategoryRecommenderMappingReqStatus
      );

      getGranulateConnectionForCspConnection(
        permissions,
        selectedCostOptimizationInsightsConnection,
        setGranulateConnections,
        setGranulateConnectionsReqStatus
      );

      fetchApplicableRecommenders(
        getProviderForConnection(selectedCostOptimizationInsightsConnection),
        (data: string[]) => dispatch(setApplicableRecommendations(data))
      );
    }
  }, [selectedCostOptimizationInsightsConnection]);

  useEffect(() => {
    if (!viewList) {
      return;
    }

    resetRecommendationTable();

    if (hasRulesetFilters(viewList, currentRecommendationView)) {
      getRulesetViewFilters(
        getProviderForConnection(selectedCostOptimizationInsightsConnection),
        setRulesetFilters,
        setRulesetFiltersRequestStatus,
        viewList,
        currentRecommendationView
      );
    }
  }, [currentRecommendationView, viewList]);

  useEffect(() => {
    setGranulateRecommendations([]);

    if (!viewList || !granulateConnections) {
      return;
    }

    // Do not fetch the Granulate recommendations if the selected view is not of the view created through filters cta
    if (!viewList.some((view) => view.key === currentRecommendationView.key)) {
      return;
    }

    getAllGranulateRecommendations(
      selectedCostOptimizationInsightsConnection!,
      granulateConnections,
      currentRecommendationView,
      setGranulateRecommendations,
      setGranulateRecommendationsReqStatus
    );
  }, [currentRecommendationView, viewList, granulateConnections]);

  useEffect(() => {
    resetRecommendationTable();
  }, [rulesetFilters]);

  useEffect(() => {
    if (currentPage === -1) {
      setCurrentPage(1);
      return;
    }
    if (
      selectedCostOptimizationInsightsConnection &&
      categoryRecommenderMapping &&
      viewList &&
      currentPage === 1
    ) {
      fetchRecommendationData(setRawCspRecommendationData);

      if (
        getProviderForConnection(selectedCostOptimizationInsightsConnection) !==
        PROVIDER.AWS
      ) {
        getTotalRecommendationsCount(
          selectedCostOptimizationInsightsConnection,
          setTotalCount,
          currentRecommendationView,
          viewList,
          categoryRecommenderMapping,
          selectedCostOptimizationInsightsNav,
          rulesetFilters
        );
      }
    }

    if (currentPage > 1) {
      fetchRecommendationData(setNextPageRawCspRecommendationData);
    }
  }, [categoryRecommenderMapping, currentPage]);

  useEffect(() => {
    setRecommendationDataWithTicketInfo(
      addServiceNowStatusToRecommendationData(
        rawCspRecommendationData,
        serviceNowTickets,
        projectNumberIdMapping
      )
    );
  }, [rawCspRecommendationData, serviceNowTickets, projectNumberIdMapping]);

  useEffect(() => {
    const cspData = filterRecommendationTableDataSource(
      recommendationDataWithTicketInfo,
      statusFilters,
      selectedSnowStatus
    );
    const granulateData = filterRecommendationTableDataSource(
      granulateRecommendations,
      statusFilters,
      selectedSnowStatus
    );

    setRecommendationData([...cspData, ...granulateData]);

    if (cspData.length === 0) {
      handleChangePagination();
    }
  }, [
    recommendationDataWithTicketInfo,
    granulateRecommendations,
    statusFilters,
    selectedSnowStatus,
  ]);

  useEffect(() => {
    // Set the data for excel export
    if (selectedCostOptimizationInsightsConnection) {
      dispatch(
        setConsolidatedExportData(
          getRecommendationDataForExport(
            selectedCostOptimizationInsightsConnection!,
            [...recommendationDataWithTicketInfo, ...granulateRecommendations],
            statusFilters,
            permissions
          )
        )
      );
    }
  }, [
    selectedCostOptimizationInsightsConnection,
    recommendationDataWithTicketInfo,
    granulateRecommendations,
    statusFilters,
  ]);

  useEffect(() => {
    if (currentPage > 1) {
      const data = addServiceNowStatusToRecommendationData(
        nextPageRawCspRecommendationData,
        serviceNowTickets,
        projectNumberIdMapping
      );
      const filteredData = filterRecommendationTableDataSource(
        data,
        statusFilters,
        selectedSnowStatus
      );

      const hasCspRecommendationsOnTable = recommendationData.some(
        (recommendation) =>
          recommendation.recommendationSource === RecommendationSource.CSP
      );

      // Fetch next set of recommendations if there are no data post filter until the data is fetched or end of data
      if (hasCspRecommendationsOnTable && filteredData.length === 0) {
        handleChangePagination();
      }
    }
  }, [nextPageRawCspRecommendationData]);

  /**
   * @function resetRecommendationTable
   * @description Function to reset the recommendation to the initial state
   */
  const resetRecommendationTable = () => {
    setCurrentPage(-1);
    setTotalCount(0);
    setRawCspRecommendationData([]);
    setNextPageRawCspRecommendationData([]);
    dispatch(setSelectedRecommendations([]));
  };

  /**
   * @function fetchRecommendationData
   * @description Function to make the api call and set the recommendation data with required function arguments
   * @param setData Callback to set the data
   */
  const fetchRecommendationData = (
    setData: (val: RecommendationList[]) => void
  ) => {
    getRecommendationList(
      selectedCostOptimizationInsightsConnection!,
      setData,
      setRecommendationDataRequestStatus,
      true,
      currentPage,
      setCurrentPage,
      currentRecommendationView,
      viewList,
      categoryRecommenderMapping,
      selectedCostOptimizationInsightsNav,
      rulesetFilters
    );
  };

  /**
   * @function handleChangePagination
   * @description Function to handle the pagination change
   */
  const handleChangePagination = () => {
    if (recommendationDataRequestStatus === REQUEST_STATUS.PROCESSING) return;

    const provider = getProviderForConnection(
      selectedCostOptimizationInsightsConnection
    );

    if (provider === PROVIDER.AWS) {
      handleChangePaginationForAws();
      return;
    }

    handleChangePaginationByTotalCount();
  };

  /**
   * @function handleChangePaginationByTotalCount
   * @description Function to handle the pagination change by the total count of recommendations
   */
  const handleChangePaginationByTotalCount = () => {
    if (
      currentPage * INFINITE_SCROLL_PAGE_SIZE <= totalCount &&
      rawCspRecommendationData.length +
        nextPageRawCspRecommendationData.length ===
        currentPage * INFINITE_SCROLL_PAGE_SIZE
    ) {
      setRecommendationDataRequestStatus(REQUEST_STATUS.PROCESSING);
      setCurrentPage(currentPage + 1);
    }
    if (rawCspRecommendationData.length < totalCount) {
      setRawCspRecommendationData([
        ...rawCspRecommendationData,
        ...nextPageRawCspRecommendationData,
      ]);
    }
  };

  /**
   * @function handleChangePaginationForAws
   * @description Function to handle the pagination change for AWS
   */
  const handleChangePaginationForAws = () => {
    if (nextPageRawCspRecommendationData.length > 0) {
      setRawCspRecommendationData([
        ...rawCspRecommendationData,
        ...nextPageRawCspRecommendationData,
      ]);
    }

    if (nextPageRawCspRecommendationData.length >= INFINITE_SCROLL_PAGE_SIZE) {
      setRecommendationDataRequestStatus(REQUEST_STATUS.PROCESSING);
      setCurrentPage(currentPage + 1);
    }
  };

  /**
   * @function getFilterDropdown
   * @description Function to return the filter dropdown
   * @returns JSX element with filters
   */
  const getFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
  }: FilterDropdownProps) => (
    <TableFilterDropdown
      allKeys={filters ?? []}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  );

  /**
   * @function onChangeTableFilters
   * @description Function to handle the pagination, filters and sorts changes in the table
   * @param _newPagination Pagination configurations
   * @param filters filters configuration
   */
  const onChangeTableFilters = (
    _newPagination: TablePaginationConfig,
    filters: any
  ) => {
    setStatusFilters({
      incidentStatus: filters?.serviceNowStatus?.filter((item: any) =>
        SERVICE_NOW_STATUS_LABELS.some(
          (status) => status.key === item || item === UNKNOWN_VALUE
        )
      ),
      incidentRecommendationStatus:
        filters?.incidentRecommendationStatus?.filter((item: any) =>
          IncidentRecommendationStatusLabels.some(
            (status) => status.key === item
          )
        ),
      recommendationStatus: filters?.recStatus,
      recommendationSource: filters?.recommendationSource,
    });
  };

  const getQuickActions = (record: RecommendationList) => {
    return RECOMMENDATION_TABLE_QUICK_ACTIONS.filter((quickAction: any) => {
      // Conditions for apply recommendation quick action
      if (quickAction.id === RecommendationTableActions.APPLY_RECOMMENDATIONS) {
        return (
          permissions.costControlWrite &&
          record.recommendationSource === RecommendationSource.CSP &&
          checkRecordStatusForApplyRec(record) &&
          isRecommendationApplyEligible(
            getProviderForConnection(
              selectedCostOptimizationInsightsConnection
            ),
            record,
            applicableRecommendations
          )
        );
      }

      // Conditions for Ticket info quick action
      else if (quickAction.id === RecommendationTableActions.TICKET_INFO)
        return (
          ORDERED_SERVICE_NOW_STATUS_LIST.includes(record.serviceNowStatus) &&
          record.serviceNowStatus !== ServiceNowStatus.TICKET_TBC
        );
      else return true;
    });
  };

  /**
   * @function applyRecommendationCallback
   * @description Function to to apply the recommendation
   * @param recommendation Recommendation record data
   * @param additionalRequestBody Additional request body for apply api calls
   */
  const applyRecommendationCallback = (
    recommendation: RecommendationList,
    additionalRequestBody?: Object
  ) => {
    applyRecommendation(
      selectedCostOptimizationInsightsConnection!,
      recommendation,
      setApplyRecommendationRequestStatus,
      onApplyRecommendationSuccess,
      onApplyRecommendationFailure,
      additionalRequestBody
    );
  };

  /**
   * @function onApplyRecommendationSuccess
   * @description Callback function for successful applying recommendation
   */
  const onApplyRecommendationSuccess = () => {
    fetchSnowIncidents();
    setShowConfirmApplyRecommendation(false);
    resetRecommendationTable();
  };

  /**
   * @function onApplyRecommendationFailure
   * @description Callback function for failure to apply recommendation
   */
  const onApplyRecommendationFailure = () => {
    setShowConfirmApplyRecommendation(false);
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions on click
   * @param record Data record for which the quick action is action is performed
   * @param action action performed
   */
  const handleQuickAction = (record: RecommendationList, action: string) => {
    setSelectedRecommendation(record);

    switch (action) {
      case RecommendationTableActions.APPLY_RECOMMENDATIONS:
        getProviderForConnection(selectedCostOptimizationInsightsConnection) ===
        PROVIDER.AWS
          ? setShowConfirmApplyRecommendation(true)
          : applyRecommendationCallback(record);
        break;

      case RecommendationTableActions.TICKET_INFO:
        setShowTicketInfoDrawer(true);
        break;
    }
  };

  const columns: ColumnsType<RecommendationList> = [
    {
      title: (
        <div className="flex flex-gap-4">
          <span>
            {t('costOptimizationInsight.recommendationTable.accountId')}
          </span>
          <Tooltip
            title={t(
              'costOptimizationInsight.recommendationTable.accountIdInfo'
            )}
          >
            <span>
              <Icon iconName={ICONS.INFORMATION_LINE} size={ICONS_SIZE.ONE_X} />
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: 'accountId',
    },
    {
      title: t(
        'costOptimizationInsight.recommendationTable.recommendationSource'
      ),
      dataIndex: 'recommendationSource',
      filters: Object.values(RecommendationSource).map((item: string) => ({
        value: item,
        text: item,
      })),
      filterIcon: (
        <Icon iconName={ICONS.FILTER_3_FILL} dataTestId="filter-cta" />
      ),
      filterDropdown: getFilterDropdown,
    },
    {
      title: t('costOptimizationInsight.recommendationTable.resource'),
      dataIndex: 'resource',
    },
    {
      title: t('costOptimizationInsight.recommendationTable.recommendation'),
      dataIndex: 'recommendation',
    },
    {
      title: t('costOptimizationInsight.recommendationTable.timeStamp'),
      dataIndex: 'timeStamp',
      render: (text: string) => moment(text).format(DATE_TIME_AM_PM),
    },
    {
      title: t('costOptimizationInsight.recommendationTable.recStatus'),
      dataIndex: 'recStatus',
      render: (text: string) => {
        const statusLabel = RECOMMENDATION_STATUS_LABELS.find(
          (item) => item.key === text
        )?.label;
        return <StatusElement statusKey={text} status={statusLabel} />;
      },
      filters: RECOMMENDATION_STATUS_LABELS.map((item) => ({
        value: item.key,
        text: item.label,
      })),
      filterIcon: (
        <Icon iconName={ICONS.FILTER_3_FILL} dataTestId="filter-cta" />
      ),
      filterDropdown: getFilterDropdown,
    },
    {
      title: t('costOptimizationInsight.recommendationTable.actions'),
      dataIndex: 'quickAction',
      render: (_text: string, record: RecommendationList) => {
        return (
          <QuickActionMenu
            quickActions={getQuickActions(record)}
            disabled={getQuickActions(record).length === 0}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      width: 80,
      fixed: 'right',
      align: 'center',
    },
  ];

  /**
   * @function isRecommendationTableLoading
   * @description Function to return a boolean value to for loading table
   * @returns boolean value true or false
   */
  const isRecommendationTableLoading = () => {
    return (
      (recommendationData.length === 0 &&
        [
          recommendationDataRequestStatus,
          granulateRecommendationsReqStatus,
        ].includes(REQUEST_STATUS.PROCESSING)) ||
      [
        granulateConnectionsReqStatus,
        categoryRecommenderMappingReqStatus,
        rulesetFiltersRequestStatus,
        snowTicketsRequestStatus,
      ].includes(REQUEST_STATUS.PROCESSING)
    );
  };

  return (
    <div
      className={`${
        hasRecommendationFilters(currentRecommendationView) && 'with-filter'
      } recommendation-table-with-workflow flex flex-column flex-gap-16`}
      data-testid="recommendation-table-with-workflow"
    >
      <div className="snow-status-tabs flex flex-align-items-center flex-gap-16 flex-wrap">
        <div className="flex flex-column font-caption-bold">
          {t(
            'costOptimizationInsight.recommendationTableHeader.serviceNowStatus'
          )}
        </div>
        <RadioGroup
          options={SERVICE_NOW_STATUS_LABELS.map((status) => ({
            label: status.tabTitle,
            value: status.key,
          }))}
          onChange={(e: any) => setSelectedSnowStatus(e.target.value)}
          value={selectedSnowStatus}
          optionType="button"
          rootClassName="no-custom-style status-tabs font-caption-bold"
          style={{ height: 28 }}
        />
      </div>
      <RecommendationsTable
        isFetchingMoreData={isRecommendationTableLoading()}
        isLoading={[
          recommendationDataRequestStatus,
          granulateRecommendationsReqStatus,
        ].includes(REQUEST_STATUS.PROCESSING)}
        recommendationData={recommendationData}
        columns={columns}
        onChangeTableFilters={onChangeTableFilters}
        handleChangePagination={handleChangePagination}
        hasErrorFetchingData={
          recommendationDataRequestStatus === REQUEST_STATUS.ERROR &&
          granulateRecommendationsReqStatus === REQUEST_STATUS.ERROR
        }
        noDataMessage={t('costOptimizationInsight.noRecommendations', {
          ticketStatus: SERVICE_NOW_STATUS_LABELS.find(
            (item) => item.key === selectedSnowStatus
          )?.tabTitle,
        })}
      />
      <TicketInfoDrawer
        ticket={selectedRecommendation!}
        show={showTicketInfoDrawer && !!selectedRecommendation}
        setShow={setShowTicketInfoDrawer}
      />
      <ApplyAwsRecommendation
        show={showConfirmApplyRecommendation && !!selectedRecommendation}
        setShow={setShowConfirmApplyRecommendation}
        selectedAwsCheck={selectedRecommendation?.checkId!}
        loadApplyRecommendation={applyRecommendationRequestStatus}
        applyRecommendation={(additionalRequestBody: Object) =>
          applyRecommendationCallback(
            selectedRecommendation!,
            additionalRequestBody
          )
        }
      />
    </div>
  );
};

export default RecommendationTableWithWorkFlow;
