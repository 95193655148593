import { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import PdfDownloadComponent from 'components/PdfDownloadComponent';
import ExpandModal from 'components/ExpandModal';
import {
  selectDashboard,
  setExportToExcelData,
  setPptDownloadMode,
} from 'redux/dashboardSlice';
import { customDashboard } from 'redux/customDashboardSlice';
import { PdfMetaDataType } from 'types/exportTypes';
import {
  ConnectionListType,
  GraphProperties,
  GraphRowContentType,
} from 'types/dashboard';
import {
  getGlobalExcelExportData,
  modifyToExportColumns,
} from 'utils/exportToExcel';
import { downloadPptFromGraphData } from 'utils/exportToPpt';
import { getExportFileName } from 'utils/dashboardUtils';
import { filterGraphRowsByCriteria } from './utils';

import './index.scss';

type DashboardGraphContainerProps = {
  getGraphComponent: (graphName: string) => React.ReactNode;
  rows: GraphRowContentType;
  chartCriteriaFilters?: string[];
  pdfMetaData: PdfMetaDataType;
  costCardComponents?: JSX.Element[];
  connection?: ConnectionListType | null;
};

const DashboardGraphContainer = ({
  getGraphComponent,
  rows,
  chartCriteriaFilters,
  pdfMetaData,
  costCardComponents,
  connection,
}: DashboardGraphContainerProps) => {
  const dispatch = useDispatch();
  const {
    selectedDashboard,
    pdfDownloadMode,
    pptDownloadMode,
    expandedGraphName,
    tableViewEnabled,
    selectedDashboardView,
    dashboardViewsList,
  } = useSelector(selectDashboard);
  const { selectedDashboardCustomViews } = useSelector(customDashboard);

  const [filteredRows, setFilteredRows] = useState<GraphRowContentType>([]);
  const [filteredGraphs, setFilteredGraphs] = useState<GraphProperties[]>([]);

  useEffect(() => {
    const rowsFiltered = filterGraphRowsByCriteria(rows, chartCriteriaFilters);
    setFilteredRows(rowsFiltered);
    setFilteredGraphs(rowsFiltered.flat());
  }, [rows, chartCriteriaFilters]);

  useEffect(() => {
    filteredGraphs.forEach((each) => {
      each.tableView?.setTableView?.(tableViewEnabled);
    });
  }, [tableViewEnabled]);

  useEffect(() => {
    if (selectedDashboard || connection) {
      dispatch(setExportToExcelData(getGlobalExcelExportData(filteredGraphs)));
    }
  }, [selectedDashboard, filteredGraphs, connection]);

  useEffect(() => {
    if (pptDownloadMode) {
      initiateGlobalPptDownload();
    }
  }, [pptDownloadMode]);

  const initiateGlobalPptDownload = async () => {
    await downloadPptFromGraphData(
      getExportFileName(
        selectedDashboardCustomViews,
        selectedDashboardView,
        dashboardViewsList,
        selectedDashboard?.name ?? connection?.displayName ?? ''
      ),
      filteredGraphs
    );
    dispatch(setPptDownloadMode(false));
  };

  return (
    <>
      {costCardComponents && (
        <div className="cost-cards flex flex-space-between">
          {costCardComponents}
        </div>
      )}
      {filteredRows.map((rowGroup) => {
        if (rowGroup.length === 1)
          return (
            <div key={rowGroup[0].graphName}>
              {getGraphComponent(rowGroup[0].graphName)}
            </div>
          );
        return (
          <Row
            gutter={24}
            key={rowGroup.map((chart) => chart.graphName).join(',')}
          >
            {rowGroup.map(({ graphName, colSpanWidth = 24 }) => (
              <Col span={colSpanWidth} key={graphName}>
                {getGraphComponent(graphName)}
              </Col>
            ))}
          </Row>
        );
      })}
      <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={pdfMetaData}
          pdfContent={filteredGraphs.map((eachChart) => ({
            ...eachChart,
            tableName: eachChart.graphHeading,
            isTableView: eachChart.tableView?.isTableView,
            body: eachChart.pdfTableExport?.pdfTableData?.map(
              (row: any, index: number) => ({
                ...row,
                index: index + 1,
              })
            ),
            tableFooterData: eachChart.pdfExport?.tableFooterData,
            legends: eachChart.pdfExport?.legends,
            column:
              eachChart.tableView?.columns &&
              modifyToExportColumns(eachChart.tableView.columns),
          }))}
          pdfCardData={{
            costCardsData: costCardComponents,
          }}
        />
      )}
    </>
  );
};

export default DashboardGraphContainer;
