import { PROVIDER } from 'constants/cloudProviders';

export const CONNECTION_PROVIDER_LIST_DATA = [
  {
    provider: PROVIDER.AWS,
    header: 'awsHeader',
    subHeader: 'awsSubHeader',
  },
  {
    provider: PROVIDER.AZURE,
    header: 'azureHeader',
    subHeader: 'azureSubHeader',
  },
  {
    provider: PROVIDER.GCP,
    header: 'gcpHeader',
    subHeader: 'gcpSubHeader',
  },
  {
    provider: PROVIDER.OCI,
    header: 'ociHeader',
    subHeader: 'ociSubHeader',
  },
];
