import i18n from 'i18n';
import { TicketIntegrationDataType, ServiceNowDataType } from './type';

export const DEFAULT_ROWS = 100;

export enum WEIGHTS_FILTERS {
  ALL = '100',
  P50 = '50',
  P25 = '25',
  P5 = '5',
  P1 = '1',
}

export enum DASHBOARD_ITEMS {
  DASHBOARD = 'DASHBOARD',
  OPTIMIZATION_INSIGHT = 'OPTIMIZATION INSIGHT',
}

export enum SERVICE_NOW_STATUS {
  UNDEFINED = 'UNDEFINED',
  NEW = 'NEW',
  IN_PROGRESS = 'INPROGRESS',
  ON_HOLD = 'ONHOLD',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED',
}

export enum IMPACT_CATEGORY {
  LOW = 'LOW',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
}

export const ImpactCategoryLabels = [
  {
    key: IMPACT_CATEGORY.LOW,
    label: i18n.t('gprofilerInsightsDashboard.low'),
  },
  {
    key: IMPACT_CATEGORY.HIGH,
    label: i18n.t('gprofilerInsightsDashboard.high'),
  },
  {
    key: IMPACT_CATEGORY.MEDIUM,
    label: i18n.t('gprofilerInsightsDashboard.medium'),
  },
];

export const WeightFiltersOptions = [
  {
    value: Number(WEIGHTS_FILTERS.ALL),
    label: i18n.t('gprofilerInsightsDashboard.all'),
  },
  {
    value: Number(WEIGHTS_FILTERS.P50),
    label: 'P50',
  },
  {
    value: Number(WEIGHTS_FILTERS.P25),
    label: 'P25',
  },
  {
    value: Number(WEIGHTS_FILTERS.P5),
    label: 'P5',
  },
  {
    value: Number(WEIGHTS_FILTERS.P1),
    label: 'P1',
  },
];

export enum DateRanges {
  CUSTOM_RANGE = 'CUSTOM_RANGE',
  LAST_SNAPSHOT = 'LAST_SNAPSHOT',
}

export const DateRangeOptions = [
  {
    value: DateRanges.CUSTOM_RANGE,
    label: i18n.t('gprofilerInsightsDashboard.customRange'),
  },
  {
    value: DateRanges.LAST_SNAPSHOT,
    label: i18n.t('gprofilerInsightsDashboard.lastSnapshot'),
  },
];

export const InsightDashboardColumn = [
  {
    header: i18n.t('gprofilerInsightsDashboard.runtime'),
    key: 'runtime',
    width: 10,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.functionName'),
    key: 'functionName',
    width: 40,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.samples'),

    key: 'samples',
    width: 10,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.totalCPU'),
    key: 'totalCPU',
    width: 10,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.trend'),
    key: 'trend',
    width: 10,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.ownTime'),
    key: 'ownTime',
    width: 15,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.occurrences'),
    key: 'occurrences',
    width: 10,
  },
];

export const DashboardItems = [
  {
    key: DASHBOARD_ITEMS.DASHBOARD,
    label: i18n.t('gprofilerInsightsDashboard.dashboard'),
  },
  {
    key: DASHBOARD_ITEMS.OPTIMIZATION_INSIGHT,
    label: i18n.t('gprofilerInsightsDashboard.optimizationInsight'),
  },
];

export const ServiceNowStatusLabels = [
  {
    key: SERVICE_NOW_STATUS.UNDEFINED,
    label: i18n.t('serviceNowLabels.ticketTBC'),
    tabTitle: i18n.t('serviceNowLabels.undefined'),
  },
  {
    key: SERVICE_NOW_STATUS.NEW,
    label: i18n.t('serviceNowLabels.new'),
    tabTitle: i18n.t('serviceNowLabels.new'),
  },
  {
    key: SERVICE_NOW_STATUS.IN_PROGRESS,
    label: i18n.t('serviceNowLabels.inProgress'),
    tabTitle: i18n.t('serviceNowLabels.inProgress'),
  },
  {
    key: SERVICE_NOW_STATUS.ON_HOLD,
    label: i18n.t('serviceNowLabels.onHold'),
    tabTitle: i18n.t('serviceNowLabels.onHold'),
  },
  {
    key: SERVICE_NOW_STATUS.RESOLVED,
    label: i18n.t('serviceNowLabels.resolved'),
    tabTitle: i18n.t('serviceNowLabels.resolved'),
  },
  {
    key: SERVICE_NOW_STATUS.CLOSED,
    label: i18n.t('serviceNowLabels.closed'),
    tabTitle: i18n.t('serviceNowLabels.closed'),
  },
];

export const INCIDENT_TYPES = {
  BULK_INCIDENT: 'BULK_INCIDENT',
  MULTI_INCIDENTS: 'MULTI_INCIDENTS',
};

export const INCIDENT_TYPE_OPTIONS = [
  { type: INCIDENT_TYPES.BULK_INCIDENT, title: 'bulkIncident' },
  { type: INCIDENT_TYPES.MULTI_INCIDENTS, title: 'multiIncidents' },
];

export const DefaultFormData: TicketIntegrationDataType = {
  url: '',
  usernameHost: '',
  password: '',
  setDefault: true,
  defaultAssignmentGroup: '',
  defaultAssignedTo: '',
  category: '',
};

export const InitialServiceNowData: ServiceNowDataType = {
  incidentType: '',
  assignmentGroup: '',
  assignedTo: '',
  category: '',
  createSnapshot: false,
};

export const TableExportColumns = [
  { header: '#', key: 'slNo', width: 10, alignment: 'center', dataKey: 'slNo' },
  {
    header: i18n.t('gprofilerInsightsDashboard.name'),
    dataKey: 'name',
    key: 'name',
    alignment: 'left',
    width: 40,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.category'),
    dataKey: 'category',
    key: 'category',
    alignment: 'left',
    width: 20,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.impact'),
    key: 'impact',
    dataKey: 'impact',
    alignment: 'left',
    width: 15,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.effort'),
    key: 'effort',
    dataKey: 'effort',
    alignment: 'left',
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.description'),
    key: 'description',
    dataKey: 'description',
    alignment: 'left',
    width: 80,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.snowStatus'),
    key: 'incidentStatus',
    dataKey: 'incidentStatus',
    alignment: 'left',
    width: 80,
  },
  {
    header: i18n.t('gprofilerInsightsDashboard.resolution'),
    key: 'resolution',
    dataKey: 'resolution',
    width: 40,
  },
];
