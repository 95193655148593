import axios from 'axios';
import moment from 'moment';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectContainerInsights } from 'redux/containerInsightsSlice';
import {
  DATE_INTERVALS,
  DATE_RANGES,
} from 'pages/ContainerInsightsPage/constants';
import { getDateInterval } from 'pages/ContainerInsightsPage/utils';
import ControlComponent from 'components/DashboardControl';
import DropdownCheckbox from 'components/DropdownCheckbox';
import { DateRangeFilter } from 'types/dataTypes';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  QUERY_VALUES,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { CHART_TYPES } from 'constants/graphConfig';
import { PROVIDER } from 'constants/cloudProviders';
import { INPUT_SIZE } from 'constants/appearance';
import { CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT } from 'constants/userConsole';
import { generateGraphColors } from 'utils/dashboardUtils';
import {
  YEAR_MONTH_WITHOUT_SEPARATOR,
  getMonthAndYearStringFormat,
  getFormattedDateForTimestamp,
  HYPHEN_DATE_FORMAT,
} from 'utils/date';
import { fetchAWSApplicationDetailsData, getChartData } from 'utils/services';
import { insertIndex } from 'utils/dataFormatterUtils';

import GCPUsageMetering from '../GCPUsageMetering';
import AWSUsageMetering from '../AWSUsageMetering';
import { CLUSTER_COUNT, CLUSTER_UTILIZED } from '../GCPUsageMetering/constants';
import {
  getApplicationDetailsTableDataForGCP,
  getTotalCpuPodCount,
  getAWSApplicationDetailsTableData,
} from '../GCPUsageMetering/utils';
import ApplicationDetails from './components/ApplicationDetails';
import InfoCards from './components/InfoCards';
import ClusterCount from './components/LineChartsComponents/ClusterCount';
import ClusterUtilizedAndReserved from './components/LineChartsComponents/ClusterUtilizedAndReserved';
import CpuUtilizationChart from './components/PieChartComponents/CpuUtilizationChart';
import MemoryUtilizationChart from './components/PieChartComponents/MemoryUtilizationChart';
import NodeDistribution from './components/PieChartComponents/NodeDistributionType';
import { ApplicationDeatilsExportColumns } from './components/ApplicationDetails/constants';
import { getUsageMeteringExcelData } from './utils/exportToExcel';

import {
  ApplicationDetailsDataType,
  MemoryUtilizationType,
  CpuUtilizationType,
} from './type';

import {
  memoryUtilizationRequestBody,
  cpuUtilizationrequestBody,
  clusterCountMemoryRequestBody,
  clusterCountCpuRequestBody,
  clusterCountRequestBody,
  clusterCountAWSRequestBody,
  podAndCPUCountAWSBodyRequest,
  ClusterCountCoresExportColumns,
  ClusterCountPodsExportColumns,
  ClusterUtilizationExportColumns,
  CpuUtilizationExportColumns,
  MemoryUtilizationExportColumns,
} from './constants';
import { clusterUtilizationAndPodCountTableData } from './utils';

import './index.scss';

const GranulateUsageMetering = () => {
  const { t } = useTranslation();

  const { selectedGranulateService, showConsolidatedDashboard } = useSelector(
    costOptimizationInsights
  );
  const { selectedConnection } = useSelector(selectContainerInsights);
  const { showExpandGraphModal, tableViewEnabled } =
    useSelector(selectDashboard);

  const isGCPProvider = showConsolidatedDashboard
    ? selectedGranulateService?.provider === PROVIDER.GCP
    : selectedConnection?.provider === PROVIDER.GCP;

  //States for filters
  const [projects, setProjects] = useState<string[]>([]);
  const [projectsRequestStatus, setProjectsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [clusters, setClusters] = useState<string[]>([]);
  const [clustersRequestStatus, setClustersRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedClusters, setSelectedClusters] = useState<string[]>([]);

  const namespaceUsageCostDateFilter: DateRangeFilter = {
    option: DATE_RANGES.THIS_YEAR,
    startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
    endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
  };

  //Application Details
  const [applicationDetailsData, setApplicationDetailsData] = useState<
    ApplicationDetailsDataType[]
  >([]);
  const [
    applicationDetailsDataRequestStatus,
    setApplicationDetailsDataRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  const [clusterCountCpuRequestStatus, setClusterCountCpuRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  //Memory Utilization
  const [memoryUtilizationData, setMemoryUtilizationData] = useState<
    MemoryUtilizationType[]
  >([]);

  //CPU utilization
  const [cpuUtilizationData, setCpuUtilizationData] = useState<
    CpuUtilizationType[]
  >([]);

  //Cluster Count CPU
  const [clusterCountCpuData, setClusterCountCpuData] = useState<any[]>([]);

  //Cluster Count Memory
  const [clusterCountMemoryData, setClusterCountMemoryData] = useState<any[]>(
    []
  );

  const [selectedCpuUtilized, setSelectedCpuUtilized] = useState<string>(
    CLUSTER_UTILIZED.CPU
  );
  const [selectedCpuCount, setSelectedCpuCount] = useState<string>(
    CLUSTER_COUNT.PODS
  );

  const [cpuPODCountData, setCpuPODCountData] = useState<any[]>([]);
  const [cpuCoresCountData, setCpuCoresCountData] = useState<any[]>([]);
  const [memoryUtilizationTableView, setMemoryUtilizationTableView] =
    useState(false);
  const [cpuUtilizationTableView, setCpuUtilizationTableView] = useState(false);
  const [isClusterUtilizedTableView, setIsClusterUtilizedTableView] =
    useState(false);
  const [isClusterCountTableView, setIsClusterCountTableView] = useState(false);

  const [granulateChartsExcelData, setGranulateChartsExcelData] = useState<
    any[]
  >([]);

  const [selectedNamespaces, setSelectedNamespaces] = useState<string[]>([]);

  const filteredApplicationDetailsData = applicationDetailsData.filter((item) =>
    selectedNamespaces.includes(item.namespace)
  );

  const uniqueNamespaces: Set<string> = new Set();
  const namespaceData: { namespace: string }[] = [];

  applicationDetailsData.forEach((item: any) => {
    if (!uniqueNamespaces.has(item.namespace)) {
      uniqueNamespaces.add(item.namespace);
      namespaceData.push({ namespace: item.namespace });
    }
  });

  useEffect(() => {
    setSelectedNamespaces(
      namespaceData.slice(0, 10).map((item) => item.namespace)
    );
  }, [applicationDetailsData]);

  const connectorId = selectedGranulateService
    ? selectedGranulateService.connectorId
    : selectedConnection?.connectorId;

  useEffect(() => {
    setGranulateChartsExcelData(
      getUsageMeteringExcelData(
        applicationDetails,
        clusterUtilized,
        clusterCost,
        cpuUtilisation,
        memoryUtilization
      )
    );
  }, [
    filteredApplicationDetailsData,
    cpuCoresCountData,
    cpuPODCountData,
    cpuUtilizationData,
    clusterCountMemoryData,
    clusterCountCpuData,
    selectedGranulateService,
    selectedProjects,
    selectedClusters,
    selectedCpuUtilized,
    selectedCpuCount,
    selectedNamespaces,
  ]);

  useEffect(() => {
    setGranulateChartsExcelData([]);
  }, [selectedGranulateService]);

  useEffect(() => {
    selectedGranulateService?.provider === PROVIDER.GCP
      ? fetchApplicationDeatilsDataGCP()
      : fetchApplicationDeatilsDataAWS();
  }, [selectedGranulateService]);

  useEffect(() => {
    setIsClusterUtilizedTableView(tableViewEnabled);
    setIsClusterCountTableView(tableViewEnabled);
    setCpuUtilizationTableView(tableViewEnabled);
    setMemoryUtilizationTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  /**
   * @function getExcelFilters
   * @description Function to return the filters applied for excel export
   * @returns List of filters applied
   */
  const getExcelFilters = () => {
    return [
      {
        heading: t('excelExportLabels.connectionName'),
        value: selectedGranulateService?.connectionName,
      },
      {
        heading: t('excelExportLabels.projects'),
        value: selectedProjects.toString(),
      },
      {
        heading: t('excelExportLabels.clusters'),
        value: selectedClusters.toString(),
      },
    ];
  };

  const applicationDetails = {
    applicationDetailsData: filteredApplicationDetailsData,
    filters: [
      ...getExcelFilters(),
      {
        heading: t('consolidatedDashboard.applicationDetails.namespace'),
        value: selectedNamespaces.join(', '),
      },
    ],
  };
  const clusterUtilized = {
    clusterUtilizedChartData:
      selectedCpuUtilized === CLUSTER_UTILIZED.CPU
        ? clusterCountCpuData
        : clusterCountMemoryData,
    clusterUtilizedTableData: clusterUtilizationAndPodCountTableData(
      selectedCpuUtilized === CLUSTER_UTILIZED.CPU
        ? clusterCountCpuData
        : clusterCountMemoryData
    ),
    clusterUtilizedFilters: [
      ...getExcelFilters(),
      {
        heading: t('graphHeadings.graphType'),
        value:
          selectedCpuUtilized === CLUSTER_UTILIZED.CPU
            ? t('graphHeadings.clusterUtilizationCPU')
            : t('graphHeadings.clusterUtilizationMemory'),
      },
    ],
    sheetName:
      selectedCpuUtilized === CLUSTER_UTILIZED.CPU
        ? t('graphHeadings.clusterUtilizationCPU')
        : t('graphHeadings.clusterUtilizationMemory'),
    yTitle: 'utilization',
  };
  const clusterCost = {
    clusterCostChartData:
      selectedCpuCount === CLUSTER_COUNT.PODS
        ? cpuPODCountData
        : cpuCoresCountData,
    clusterCostTableData: clusterUtilizationAndPodCountTableData(
      selectedCpuCount === CLUSTER_COUNT.PODS
        ? cpuPODCountData
        : cpuCoresCountData
    ),
    clusterCostFilters: [
      ...getExcelFilters(),
      {
        heading: t('graphHeadings.graphType'),
        value:
          selectedCpuCount === CLUSTER_COUNT.PODS
            ? t('graphHeadings.clusterCountPods')
            : t('graphHeadings.clusterCountCores'),
      },
    ],
    sheetName:
      selectedCpuCount === CLUSTER_COUNT.PODS
        ? t('graphHeadings.clusterCountPods')
        : t('graphHeadings.clusterCountCores'),
    yTitle:
      selectedCpuCount === CLUSTER_COUNT.PODS
        ? t('graphHeadings.pods')
        : t('graphHeadings.utilization'),
  };

  const memoryUtilization = {
    data: memoryUtilizationData,
    filters: getExcelFilters(),
    sheetName: t('consolidatedDashboard.memoryUtilization'),
    angleField: 'memoryUtilisation',
  };

  const cpuUtilisation = {
    data: cpuUtilizationData,
    filters: getExcelFilters(),
    sheetName: t('consolidatedDashboard.cpuUtilization'),
    angleField: 'cpuUtilisation',
  };

  /**
   * @function getAdditionalFiltersForHourInterval
   * @description Function to get the additional filters for hour interval
   * @param startDate start date
   * @param endDate end date
   * @returns additional filters array
   */
  const getAdditionalFiltersForHourInterval = (
    startDate: string,
    endDate: string
  ) => {
    const interval = getDateInterval(startDate, endDate);
    if (interval === DATE_INTERVALS.HOUR) {
      return [
        {
          field: QUERY_FIELDS.USAGE_END_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: `(TIMESTAMP('${moment(startDate).format(
            HYPHEN_DATE_FORMAT
          )} 00:00:00 UTC'))`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.USAGE_END_TIME,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: `(TIMESTAMP('${moment(endDate).format(
            HYPHEN_DATE_FORMAT
          )} 23:00:00 UTC'))`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
    }
    return [];
  };

  const fetchApplicationDeatilsDataGCP = () => {
    setApplicationDetailsDataRequestStatus(REQUEST_STATUS.PROCESSING);
    setClusterCountCpuRequestStatus(REQUEST_STATUS.PROCESSING);

    const costSavingRequestBody = {
      columns: [
        {
          label: 'name',
          field: QUERY_FIELDS.POD_LABELS_VALUE,
        },
        {
          label: 'namespace',
          field: QUERY_FIELDS.CLUSTER_LABELS_VALUE,
        },
        {
          label: 'costAfterCredit',
          field: QUERY_FIELDS.NAMESPACE_COST_AFTER_CREDIT,
        },
      ],
      structColumns: [
        {
          label: 'cluster_labels',
          field: QUERY_FIELDS.LABELS,
        },
        {
          label: 'pod_labels',
          field: QUERY_FIELDS.LABELS,
        },
      ],
      aggregators: [
        {
          label: 'costAfterCredit',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['namespace', 'name'],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(namespaceUsageCostDateFilter.startDate).format(
                YEAR_MONTH_WITHOUT_SEPARATOR
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(namespaceUsageCostDateFilter.endDate).format(
                YEAR_MONTH_WITHOUT_SEPARATOR
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.CLUSTER_LABELS_KEY,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_VALUES.K8S_NAMESPACE,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: 'pod_labels.key',
              comparator: 'IN',
              value:
                '("k8s-label/component","k8s-label/k8s-app","k8s-label/app")',
              conjunctToNextFilter: 'AND',
            },
            {
              field: QUERY_FIELDS.PROJECT_NAME,
              comparator: COMPARATORS.IN,
              value: '("cb360-foundations-devops-cicd")',
            },
            ...getAdditionalFiltersForHourInterval(
              namespaceUsageCostDateFilter.startDate,
              namespaceUsageCostDateFilter.endDate
            ),
          ],
        },
      ],
      keyValueStructFilterGroups: [
        {
          keyValueStructfilters: [
            {
              arrayName: 'labels',
              key: 'goog-k8s-cluster-name',
              value: 'granulate-cluster',
              conjunctToNextFilter: 'OR',
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
      cached: true,
    };

    const params = {
      project: 'cb360-foundations-devops-cicd',
    };

    const granulateUsageRequests = [
      getChartData(memoryUtilizationRequestBody, connectorId, params),
      getChartData(cpuUtilizationrequestBody, connectorId, params),
      getChartData(clusterCountMemoryRequestBody, connectorId, params),
      getChartData(clusterCountCpuRequestBody, connectorId, params),
      getChartData(clusterCountRequestBody, connectorId, params),
      getChartData(costSavingRequestBody, connectorId, params),
    ];

    setApplicationDetailsData([]);
    setCpuUtilizationData([]);
    setMemoryUtilizationData([]);
    setCpuCoresCountData([]);
    setCpuPODCountData([]);
    setClusterCountMemoryData([]);
    setClusterCountCpuData([]);

    axios
      .all(granulateUsageRequests)
      .then((response: any[]) => {
        let data: ApplicationDetailsDataType[] = [];

        setApplicationDetailsDataRequestStatus(REQUEST_STATUS.SUCCESS);
        setClusterCountCpuRequestStatus(REQUEST_STATUS.SUCCESS);

        data = getApplicationDetailsTableDataForGCP([
          ...response[0].data,
          ...response[1].data,
          ...response[5].data,
        ]);

        setApplicationDetailsData(data);

        const cpuColors = generateGraphColors(data.length ?? 0);
        const memoryColors = generateGraphColors(data.length ?? 0);
        setCpuUtilizationData(
          data.map((item: ApplicationDetailsDataType) => ({
            ...item,
            cpuUtilisation: +(item.cpuUtilisation ?? 0).toFixed(4),
            color: cpuColors.pop() ?? '',
          }))
        );
        setMemoryUtilizationData(
          data.map((item: ApplicationDetailsDataType) => ({
            ...item,
            memoryUtilisation: +(item.memoryUtilisation ?? 0).toFixed(4),
            color: memoryColors.pop() ?? '',
          }))
        );
        setClusterCountCpuData(
          response[3].data.map((item: any) => ({
            ...item,
            time: getMonthAndYearStringFormat(item.time),
            category: 'utilized',
          }))
        );
        setClusterCountMemoryData(
          response[2].data.map((item: any) => ({
            ...item,
            time: getMonthAndYearStringFormat(item.time),
            category: 'utilized',
          }))
        );

        const totolPODCount = getTotalCpuPodCount(
          response[4].data,
          getMonthAndYearStringFormat
        );

        setCpuPODCountData(
          totolPODCount.map((item) => ({
            ...item,
            yField: item.totalPodsForNamespace,
          }))
        );

        const totolCpuUtilization: any[] = [];

        response[4].data.forEach((item: any) => {
          const time = item.time;
          const existingEntry = totolCpuUtilization.find(
            (entry) => entry.time === time
          );

          if (existingEntry) {
            existingEntry.totalCpuUtilisation += item.cpuUtilisation ?? 0;
          } else {
            totolCpuUtilization.push({
              time: time,
              totalCpuUtilisation: item.cpuUtilisation ?? 0,
            });
          }
        });

        setCpuCoresCountData(
          totolCpuUtilization.map((item) => ({
            ...item,
            time: getMonthAndYearStringFormat(item.time),
            yField: +item.totalCpuUtilisation.toFixed(2),
            category: 'utilized',
          }))
        );
      })

      .catch(() => {
        setApplicationDetailsDataRequestStatus(REQUEST_STATUS.ERROR);
        setClusterCountCpuRequestStatus(REQUEST_STATUS.ERROR);
      });
  };

  const fetchApplicationDeatilsDataAWS = () => {
    setApplicationDetailsDataRequestStatus(REQUEST_STATUS.PROCESSING);
    setClusterCountCpuRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      project: 'cb360-foundations-devops-cicd',
    };

    const granulateUsageRequests = [
      fetchAWSApplicationDetailsData(connectorId, params),
      getChartData(clusterCountAWSRequestBody, connectorId, params),
      getChartData(podAndCPUCountAWSBodyRequest, connectorId, params),
    ];

    setApplicationDetailsData([]);
    setCpuUtilizationData([]);
    setMemoryUtilizationData([]);
    setCpuCoresCountData([]);
    setCpuPODCountData([]);
    setClusterCountMemoryData([]);
    setClusterCountCpuData([]);

    axios
      .all(granulateUsageRequests)
      .then((response: any[]) => {
        setApplicationDetailsDataRequestStatus(REQUEST_STATUS.SUCCESS);
        setClusterCountCpuRequestStatus(REQUEST_STATUS.SUCCESS);

        const awsData: any[] = getAWSApplicationDetailsTableData(
          response[0].data
        );

        const cpuColors = generateGraphColors(awsData.length ?? 0);
        const memoryColors = generateGraphColors(awsData.length ?? 0);

        setApplicationDetailsData(
          awsData.map((item) => ({
            ...item,
            cpuUtilisation: item.cpuUtilisation / 1000,
          }))
        );
        setCpuUtilizationData(
          awsData.map((item: CpuUtilizationType) => ({
            ...item,
            color: cpuColors.pop() ?? '',
            cpuUtilisation: +(item.cpuUtilisation / 1000).toFixed(4),
          }))
        );
        setMemoryUtilizationData(
          awsData.map((item: MemoryUtilizationType) => ({
            ...item,
            color: memoryColors.pop() ?? '',
            memoryUtilisation: +(item.memoryUtilisation ?? 0).toFixed(4),
          }))
        );

        setClusterCountCpuData(
          response[1].data.map((item: any) => ({
            ...item,
            time: getFormattedDateForTimestamp(item.time),
            utilization: parseFloat(item.cpuUtilization),
            category: 'utilized',
          }))
        );

        setClusterCountMemoryData(
          response[1].data.map((item: any) => ({
            ...item,
            time: getFormattedDateForTimestamp(item.time),
            utilization: parseFloat(item.memoryUtilization),
            category: 'utilized',
          }))
        );

        const totolPODCount = getTotalCpuPodCount(
          response[2].data,
          getFormattedDateForTimestamp
        );

        setCpuPODCountData(
          totolPODCount.map((item) => ({
            ...item,
            yField: item.totalPodsForNamespace,
            time: item.time,
          }))
        );

        const totolCpuUtilization: any[] = [];

        response[2].data.forEach((item: any) => {
          const time = item.TimeStamp;
          const existingEntry = totolCpuUtilization.find(
            (entry) => entry.time === time
          );

          if (existingEntry) {
            existingEntry.totalCpuUtilisation +=
              parseFloat(item.CPUUtilization) ?? 0;
          } else {
            totolCpuUtilization.push({
              time: time,
              totalCpuUtilisation: parseFloat(item.CPUUtilization) ?? 0,
            });
          }
        });

        setCpuCoresCountData(
          totolCpuUtilization.map((item) => ({
            ...item,
            time: getFormattedDateForTimestamp(item.time),
            yField: +item.totalCpuUtilisation.toFixed(2),
            category: 'utilized',
          }))
        );
      })
      .catch(() => {
        setApplicationDetailsDataRequestStatus(REQUEST_STATUS.ERROR);
        setClusterCountCpuRequestStatus(REQUEST_STATUS.ERROR);
      });
  };

  const getClusterCountComponent = (pdfView: boolean) => {
    return (
      <ClusterCount
        data={
          selectedCpuCount === CLUSTER_COUNT.PODS
            ? cpuPODCountData
            : cpuCoresCountData
        }
        requestStatus={applicationDetailsDataRequestStatus}
        selectedValue={selectedCpuCount}
        setSelectedValue={setSelectedCpuCount}
        xField="time"
        yField="yField"
        yTitle={
          selectedCpuCount === CLUSTER_COUNT.PODS
            ? t('graphHeadings.pods')
            : t('graphHeadings.utilization')
        }
        xTitle={selectedCpuCount === CLUSTER_COUNT.PODS ? 'Namespace' : 'Month'}
        groupField={
          selectedCpuCount === CLUSTER_COUNT.PODS ? 'namespace' : 'category'
        }
        postfixSymbol={selectedCpuCount === CLUSTER_COUNT.CORES ? 'cores' : ''}
        pdfView={pdfView}
        setIsClusterCountTableView={setIsClusterCountTableView}
        isClusterCountTableView={isClusterCountTableView}
        tableData={clusterUtilizationAndPodCountTableData(
          selectedCpuCount === CLUSTER_COUNT.PODS
            ? cpuPODCountData
            : cpuCoresCountData
        )}
        excelFilters={[
          ...getExcelFilters(),
          {
            heading: t('graphHeadings.graphType'),
            value:
              selectedCpuCount === CLUSTER_COUNT.PODS
                ? t('graphHeadings.clusterCountPods')
                : t('graphHeadings.clusterCountCores'),
          },
        ]}
      />
    );
  };

  /**
   * @function getGraphComponent
   * @description Function to return the react components for graphs
   * @param graphName Name of the graph
   * @param pdfView Is the graph for pdf download, defaults to false
   */
  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'application-details':
        return (
          <ApplicationDetails
            data={filteredApplicationDetailsData}
            isLoading={
              applicationDetailsDataRequestStatus === REQUEST_STATUS.PROCESSING
            }
            showExpandedGraphModal={showExpandGraphModal}
            excelFilters={[
              ...getExcelFilters(),
              {
                heading: t(
                  'consolidatedDashboard.applicationDetails.namespace'
                ),
                value: selectedNamespaces.join(', '),
              },
            ]}
            namespaceData={namespaceData}
            selectedNamespaces={selectedNamespaces}
            setSelectedNamespaces={setSelectedNamespaces}
          />
        );
      case 'node-distribution':
        return <NodeDistribution />;
      case 'cpu-utilization':
        return (
          <CpuUtilizationChart
            data={cpuUtilizationData}
            requestStatus={applicationDetailsDataRequestStatus}
            pdfView={pdfView}
            cpuUtilizationTableView={cpuUtilizationTableView}
            setCpuUtilizationTableView={setCpuUtilizationTableView}
            excelFilters={getExcelFilters()}
          />
        );
      case 'memory-utilization':
        return (
          <MemoryUtilizationChart
            data={memoryUtilizationData}
            requestStatus={applicationDetailsDataRequestStatus}
            pdfView={pdfView}
            memoryUtilizationTableView={memoryUtilizationTableView}
            setMemoryUtilizationTableView={setMemoryUtilizationTableView}
            excelFilters={getExcelFilters()}
          />
        );
      case 'cluster-count':
        return getClusterCountComponent(pdfView);
      case 'utilized-and-reserved':
        return (
          <ClusterUtilizedAndReserved
            data={
              selectedCpuUtilized === CLUSTER_UTILIZED.CPU
                ? clusterCountCpuData
                : clusterCountMemoryData
            }
            requestStatus={clusterCountCpuRequestStatus}
            selectedValue={selectedCpuUtilized}
            setSelectedValue={setSelectedCpuUtilized}
            pdfView={pdfView}
            setIsClusterUtilizedTableView={setIsClusterUtilizedTableView}
            isClusterUtilizedTableView={isClusterUtilizedTableView}
            tableData={clusterUtilizationAndPodCountTableData(
              selectedCpuUtilized === CLUSTER_UTILIZED.CPU
                ? clusterCountCpuData
                : clusterCountMemoryData
            )}
            excelFilters={[
              ...getExcelFilters(),
              {
                heading: t('graphHeadings.graphType'),
                value:
                  selectedCpuUtilized === CLUSTER_UTILIZED.CPU
                    ? t('graphHeadings.clusterUtilizationCPU')
                    : t('graphHeadings.clusterUtilizationMemory'),
              },
            ]}
          />
        );
      case 'info-cards':
        return <InfoCards />;
    }
  };

  const PdfComponents = [
    {
      element: getGraphComponent('utilized-and-reserved', true),
      contentType: CHART_TYPES.LINE_CHART,
      graphName: 'utilized-and-reserved',
      column: ClusterUtilizationExportColumns,
      body:
        selectedCpuUtilized === CLUSTER_UTILIZED.CPU
          ? insertIndex(clusterCountCpuData)
          : insertIndex(clusterCountMemoryData),
      tableName: t('graphHeadings.utilizedAndReserved'),
      isTableView: isClusterUtilizedTableView,
    },
    {
      element: getGraphComponent('cluster-count', true),
      contentType: CHART_TYPES.LINE_CHART,
      graphName: 'cluster-count',
      column:
        selectedCpuCount === CLUSTER_COUNT.PODS
          ? ClusterCountPodsExportColumns
          : ClusterCountCoresExportColumns,
      body:
        selectedCpuCount === CLUSTER_COUNT.PODS
          ? insertIndex(cpuPODCountData)
          : insertIndex(cpuCoresCountData),
      tableName: t('graphHeadings.clusterCount'),
      isTableView: isClusterCountTableView,
    },
    {
      element: getGraphComponent('memory-utilization', true),
      contentType: CHART_TYPES.DOUGHNUT_CHART,
      graphName: 'memory-utilization',
      column: MemoryUtilizationExportColumns,
      body: insertIndex(memoryUtilizationData),
      tableName: t('graphHeadings.memoryUtilization'),
      isTableView: memoryUtilizationTableView,
      legends: memoryUtilizationData.map((item) => ({
        name: item.name,
        color: item.color,
      })),
    },
    {
      element: getGraphComponent('cpu-utilization', true),
      contentType: CHART_TYPES.DOUGHNUT_CHART,
      graphName: 'cpu-utilization',
      column: CpuUtilizationExportColumns,
      body: insertIndex(cpuUtilizationData),
      tableName: t('graphHeadings.cpuUtilization'),
      isTableView: cpuUtilizationTableView,
      legends: cpuUtilizationData.map((item) => ({
        name: item.name,
        color: item.color,
      })),
    },
    {
      element: getGraphComponent('application-details', true),
      contentType: CHART_TYPES.TABLE,
      graphName: 'application-details',
      column: ApplicationDeatilsExportColumns,
      body: insertIndex(applicationDetailsData).map((item) => ({
        ...item,
        cpuUtilisation: `${item.cpuUtilisation.toFixed(4)} cores`,
        memoryUtilisation: `${item.memoryUtilisation.toFixed(4)} Mi`,
        costAfterCredit: (item.costAfterCredit ?? 0).toFixed(2),
      })),
      tableName: t('graphHeadings.applicationDetails'),
    },
  ];

  const getControlComponent = () => {
    if (isGCPProvider)
      return (
        <ControlComponent
          filters={[
            {
              title: t('containerInsight.usageMeteringLabels.project'),
              filter: (
                <DropdownCheckbox
                  itemOptions={projects.map((project) => ({
                    value: project,
                    title: project,
                  }))}
                  value={selectedProjects}
                  selectedItems={selectedProjects}
                  setSelectedItems={setSelectedProjects}
                  loading={projectsRequestStatus === REQUEST_STATUS.PROCESSING}
                  designVersion2={true}
                  size={INPUT_SIZE.SMALL}
                  dataTestId="project-filter"
                />
              ),
              minimizedText:
                selectedProjects
                  .join(', ')
                  .substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...',
              onClear: () => setSelectedProjects([]),
            },
            {
              title: t('containerInsight.usageMeteringLabels.cluster'),
              filter: (
                <DropdownCheckbox
                  itemOptions={clusters.map((cluster: any) => ({
                    value: cluster,
                    title: cluster,
                  }))}
                  value={selectedClusters}
                  selectedItems={selectedClusters}
                  setSelectedItems={setSelectedClusters}
                  loading={clustersRequestStatus === REQUEST_STATUS.PROCESSING}
                  designVersion2={true}
                  size={INPUT_SIZE.SMALL}
                  dataTestId="cluster-filter"
                />
              ),
              minimizedText:
                selectedClusters
                  .join(', ')
                  .substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...',
              onClear: () => setSelectedClusters([]),
            },
          ]}
        />
      );

    return (
      <ControlComponent
        filters={[
          {
            title: t('containerInsight.usageMeteringLabels.cluster'),
            filter: (
              <DropdownCheckbox
                itemOptions={clusters.map((cluster: any) => ({
                  value: cluster,
                  title: cluster,
                }))}
                value={selectedClusters}
                selectedItems={selectedClusters}
                setSelectedItems={setSelectedClusters}
                loading={clustersRequestStatus === REQUEST_STATUS.PROCESSING}
                designVersion2={true}
                size={INPUT_SIZE.SMALL}
                dataTestId="cluster-filter"
              />
            ),
            minimizedText:
              selectedClusters
                .join(', ')
                .substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...',
            onClear: () => setSelectedClusters([]),
          },
        ]}
      />
    );
  };

  const getUsageMeteringComponent = () => {
    if (isGCPProvider)
      return (
        <GCPUsageMetering
          projects={projects}
          setProjects={setProjects}
          projectsRequestStatus={projectsRequestStatus}
          setProjectsRequestStatus={setProjectsRequestStatus}
          clusters={clusters}
          setClusters={setClusters}
          clustersRequestStatus={clustersRequestStatus}
          setClustersRequestStatus={setClustersRequestStatus}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          selectedClusters={selectedClusters}
          setSelectedClusters={setSelectedClusters}
          PdfComponents={showConsolidatedDashboard ? PdfComponents : []}
          getGranulateGraphComponent={getGraphComponent}
          selectedCpuCount={selectedCpuCount}
          selectedCpuUtilized={selectedCpuUtilized}
          granulateChartsExcelData={
            showConsolidatedDashboard ? granulateChartsExcelData : []
          }
        />
      );

    return (
      <AWSUsageMetering
        setClusters={setClusters}
        clustersRequestStatus={clustersRequestStatus}
        setClustersRequestStatus={setClustersRequestStatus}
        selectedClusters={selectedClusters}
        setSelectedClusters={setSelectedClusters}
        PdfComponents={showConsolidatedDashboard ? PdfComponents : []}
        getGranulateGraphComponent={getGraphComponent}
        selectedCpuCount={selectedCpuCount}
        selectedCpuUtilized={selectedCpuUtilized}
        granulateChartsExcelData={
          showConsolidatedDashboard ? granulateChartsExcelData : []
        }
      />
    );
  };

  return (
    <div className="usage-metering">
      {!showConsolidatedDashboard && getControlComponent()}
      <div className="margin-24 flex flex-column flex-gap-24">
        {showConsolidatedDashboard && (
          <>
            {getGraphComponent('info-cards')}
            {
              <Row gutter={24}>
                <Col span={12}>
                  {getGraphComponent('utilized-and-reserved')}
                </Col>
                <Col span={12}>{getGraphComponent('cluster-count')}</Col>
              </Row>
            }
            {
              <Row gutter={24}>
                <Col span={12}>{getGraphComponent('memory-utilization')}</Col>
                <Col span={12}>{getGraphComponent('cpu-utilization')}</Col>
              </Row>
            }
            {getGraphComponent('application-details')}
          </>
        )}
        {getUsageMeteringComponent()}
      </div>
    </div>
  );
};

export default GranulateUsageMetering;
