import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormLabel } from 'components/FormLabel';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import SelectDropdown from 'components/Select';
import { REQUEST_STATUS } from 'constants/requestBody';
import { validateEmptyField } from 'utils/validations';
import { fetchDatasetTables } from '../../utils';

type GCPRecommendationDetailsProps = {
  gcpDatasetOptionsRequestStatus: string;
  gcpDatasetOptions: string[];
  gcpRecommendationDatasetTableOptions: string[];
  setGcpRecommendationDatasetTableOptions: (val: string[]) => void;
};

const GCPRecommendationDetails = ({
  gcpDatasetOptionsRequestStatus,
  gcpDatasetOptions,
  gcpRecommendationDatasetTableOptions,
  setGcpRecommendationDatasetTableOptions,
}: GCPRecommendationDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection, isTryAgain } =
    useSelector(cloudConnection);

  const [
    gcpRecommendationDatasetRequiredValidation,
    setGcpRecommendationDatasetRequiredValidation,
  ] = useState('');
  const [
    gcpRecommendationDatasetTableRequiredValidation,
    setGcpRecommendationDatasetTableRequiredValidation,
  ] = useState('');
  const [
    gcpRecommendationDatasetTableRequestStatus,
    setGcpRecommendationDatasetTableRequestStatus,
  ] = useState('');

  useEffect(() => {
    if (
      currentConnection?.recommendationDataset &&
      (isEditConnection || isTryAgain)
    ) {
      fetchDatasetTables(
        currentConnection?.jsonFileId,
        currentConnection?.projectId,
        currentConnection?.recommendationDataset,
        setGcpRecommendationDatasetTableOptions,
        setGcpRecommendationDatasetTableRequestStatus,
        isEditConnection,
        currentConnection?.connectorId
      );
    }
  }, []);

  const getDatasetDropdown = (options: string[]) => (
    <>
      {options?.map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </>
  );

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t(`connectionCSPForm.gcpRecommendationDataset`)} />
        <SelectDropdown
          value={currentConnection?.recommendationDataset}
          loading={gcpDatasetOptionsRequestStatus === REQUEST_STATUS.PROCESSING}
          menu={getDatasetDropdown(gcpDatasetOptions)}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                recommendationDataset: value,
                recommendationsTableName: '',
              })
            );
            fetchDatasetTables(
              currentConnection?.jsonFileId,
              currentConnection?.projectId,
              value,
              setGcpRecommendationDatasetTableOptions,
              setGcpRecommendationDatasetTableRequestStatus,
              isEditConnection,
              currentConnection?.connectorId
            );
            setGcpRecommendationDatasetRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.recommendationDataset,
              t(`connectionCSPForm.gcpRecommendationDataset`),
              setGcpRecommendationDatasetRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${
              gcpRecommendationDatasetRequiredValidation ? 'inline' : 'none'
            }`,
          }}
          className="font-validation-error"
        >
          {gcpRecommendationDatasetRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`connectionCSPForm.gcpRecommendationDatasetTable`)}
        />
        <SelectDropdown
          value={currentConnection?.recommendationsTableName}
          menu={getDatasetDropdown(gcpRecommendationDatasetTableOptions)}
          loading={
            gcpRecommendationDatasetTableRequestStatus ===
            REQUEST_STATUS.PROCESSING
          }
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                recommendationsTableName: value,
              })
            );
            setGcpRecommendationDatasetTableRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.recommendationsTableName,
              t(`connectionCSPForm.gcpRecommendationDatasetTable`),
              setGcpRecommendationDatasetTableRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${
              gcpRecommendationDatasetTableRequiredValidation
                ? 'inline'
                : 'none'
            }`,
          }}
          className="font-validation-error"
        >
          {gcpRecommendationDatasetTableRequiredValidation}
        </span>
      </div>
    </div>
  );
};

export default GCPRecommendationDetails;
