import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchAllAWSBillingServices
 * @description Function all services for budget
 * @params body : Object containing the accesskey id and secret key
 * @return axios response from POST request
 */
export const fetchCostAllocationByConnectionAndMonth = async (
  connectorId: string,
  month: string
) => {
  const requestAPI =
    APIS.GET_COST_ALLOCATION_BY_CONNECTION_AND_MONTH +
    '?connectorId=' +
    connectorId +
    '&month=' +
    month;
  return ServiceCalls.get(requestAPI);
};

/**
 * @function createOrUpdateCostAllocation
 * @description Function to create or update cost allocation
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createOrUpdateCostAllocation = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_COST_ALLOCATION, body);
};
