import { useDispatch, useSelector } from 'react-redux';

import { selectPurchase, setCommitmentsSwitch } from 'redux/purchaseSlice';
import RadioGroup from 'components/RadioGroup';
import { CommitmentsSwitchEnum } from 'pages/PurchasePage/constants';

import { COMMITMENTS_SWITCH_OPTIONS } from './constants';

type CommitmentsSwitchProps = {
  heading: string;
  purchasedComponent: JSX.Element;
  recommendationComponent: JSX.Element;
};

const CommitmentsSwitch = ({
  heading,
  purchasedComponent,
  recommendationComponent,
}: CommitmentsSwitchProps) => {
  const dispatch = useDispatch();
  const { commitmentsSwitch } = useSelector(selectPurchase);

  return (
    <div className="commitments-switch flex flex-column flex-gap-16">
      <div className="flex flex-align-items-center flex-gap-16">
        <div className="form-header">{heading}</div>
        <RadioGroup
          options={COMMITMENTS_SWITCH_OPTIONS}
          onChange={(e: any) => dispatch(setCommitmentsSwitch(e.target.value))}
          value={commitmentsSwitch}
          optionType="button"
          rootClassName="no-custom-style font-caption-bold radio-button-options"
          style={{ height: 28 }}
        />
      </div>
      {commitmentsSwitch === CommitmentsSwitchEnum.PURCHASED ? (
        <div className="purchased-component">{purchasedComponent}</div>
      ) : (
        recommendationComponent
      )}
    </div>
  );
};

export default CommitmentsSwitch;
