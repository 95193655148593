import { OTHERS_LABEL } from 'constants/graphLabels';
import { GroupConnectorDto } from 'pages/GroupsPage/types';

/**
 * @function getAllConnectorIdsInGroup
 * @description Function to returnall connector Ids in the group.
 * @param connections List of Connection Dto to filter the connector Ids
 * @returns List of connectorIds
 */
export const getAllConnectorIdsInGroup = (connections: GroupConnectorDto[]) => {
  return connections.map((connection) => connection.connectorId);
};

/**
 * @function getFilteredConnections
 * @description Function to return filtered connection DTO for the selected connections.
 * @param connections Connection Dto in each selected group dashboard meta data
 * @param filteredConnectorIds connectorIds for which the connections are filtered.
 * @returns List of filtered connections of type GroupConnectorDto[]
 */
export const getFilteredConnections = (
  connections: GroupConnectorDto[],
  filteredConnectorIds: string[]
) => {
  return connections.filter((connection) =>
    filteredConnectorIds.includes(connection.connectorId)
  );
};

/**
 * @function getDefaultLabel
 * @description Function to return default string when the value id null or undefined or empty, else returns same value
 * @param value value which needs to be replced by default
 * @returns String either value or Others
 */
export const getDefaultLabel = (value: string | null | undefined) => {
  return !value ? OTHERS_LABEL : value;
};
