import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import { useTranslation } from 'react-i18next';
import DashboardComponent from 'components/DashboardComponent';
import { selectDashboard } from 'redux/dashboardSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CostByProviderServiceType } from 'types/dataTypes';
import ColumnChart from 'components/ColumnChart';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ConnectionsFilter from '../ConnectionsFilter';
import { getCostByServiceCategoryExcelData } from '../../utils/exportToExcel';
import { getFilteredConnections } from '../../utils/commonUtils';

type CostByStorageServiceProps = {
  requestStatus: string;
  isTableView: boolean;
  setIsTableView: (val: boolean) => void;
  costByStorageServiceData: CostByProviderServiceType[];
  selectedConnections: string[];
  setSelectedConnections: (val: string[]) => void;
  pdfView: boolean;
  costByStorageServiceStartMonth: string;
  costByStorageServiceEndMonth: string;
  onChangeCostByStorageServiceDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

const CostByStorageService = ({
  requestStatus,
  isTableView,
  setIsTableView,
  costByStorageServiceData,
  selectedConnections,
  setSelectedConnections,
  pdfView,
  costByStorageServiceStartMonth,
  costByStorageServiceEndMonth,
  onChangeCostByStorageServiceDateRange,
}: CostByStorageServiceProps) => {
  const { t } = useTranslation();
  const { selectedGroupMetaData } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('costByStorageServices.service'),
      dataIndex: 'service',
      key: 'service',
      width: '50%',
    },
    {
      title: t('costByStorageServices.provider'),
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: t('costByStorageServices.cost', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'cost',
      key: 'cost',
      align: 'center',
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  const filters = (
    <div className="filters flex flex-align-items-center full-width">
      <DatePicker
        defaultValue={[
          moment(costByStorageServiceStartMonth),
          moment(costByStorageServiceEndMonth),
        ]}
        onChange={onChangeCostByStorageServiceDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        designVersion2
      />
      <ConnectionsFilter
        selectedConnections={selectedConnections}
        onSelectedDropdown={setSelectedConnections}
      />
    </div>
  );

  const getComponent = () => {
    return isTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={costByStorageServiceData.map((item, index) => {
            return { ...item, key: index };
          })}
          columns={columns}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={costByStorageServiceData.map((item) => ({
          ...item,
          cost: +item.cost,
        }))}
        xField="service"
        yField="cost"
        groupingField="provider"
        xTitle={t('costByStorageServices.service')}
        yTitle={t('costByStorageServices.cost', {
          currencySymbol: currencySymbol,
        })}
        prefixSymbol={currencySymbol}
      />
    );
  };

  return (
    <div className="cost-by-storage-service graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.costByStorageServices')}
        filters={filters}
        graphName="cost-by-storage-service"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        excelData={getCostByServiceCategoryExcelData(
          t('graphHeadings.costByStorageServices').toString(),
          selectedGroupMetaData?.name ?? '',
          costByStorageServiceData,
          getFilteredConnections(
            selectedGroupMetaData?.connectorDtos ?? [],
            selectedConnections
          ).map((connection) => connection.name)
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};

export default CostByStorageService;
