import { Empty, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table/Column';

import { userAuthorization } from 'redux/authorizationSlice';
import {
  dataCenter,
  setDataCenterComponent,
  setSelectedDataCenter,
} from 'redux/dataCenterSlice';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import {
  DATA_CENTER_QUICKACTIONS,
  DataCenterQuickActions,
} from 'constants/quickAction';
import { REQUEST_STATUS } from 'constants/requestBody';
import Button from 'components/Button';
import DeleteModal from 'components/DeleteModal';
import SearchInput from 'components/Input';
import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import Pagination from 'components/Pagination';
import NavigationPath from 'components/NavigationPath';
import { DataCenterComponents } from 'pages/DataCenterPage/constants';
import { DataCenterListType } from 'pages/DataCenterPage/types';
import { deleteDataCenter } from 'pages/DataCenterPage/services';
import { BUTTON_TYPE, INPUT_SIZE } from 'constants/appearance';
import { DATE_FORMAT } from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import { getAllDataCenters } from 'utils/services';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import CreateDataCenterModal from '../CreateDataCenterModal';
import UploadDataFileModal from '../UploadDataFileModal';

import './index.scss';

const DataCenterTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedDataCenter } = useSelector(dataCenter);
  const { permissions } = useSelector(userAuthorization);

  // Table states
  const [dataCenterData, setDataCenterData] = useState<DataCenterListType[]>(
    []
  );
  const [
    fetchDataCenterDataRequestStatus,
    setFetchDataCenterDataRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Pagination and search states
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [totalDataCenterCount, setTotalDataCenterCount] = useState(0);

  // Delete states
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDataCenterRequestStatus, setDeleteDataCenterRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  //Create DataCenter States
  const [showCreateDataCenterModal, setShowCreateDataCenterModal] =
    useState(false);

  //Upload Data States
  const [showUploadDataModal, setShowUploadDataModal] = useState(false);

  useEffect(() => {
    addZeroMarginClass();
    fetchDataCenterData();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function handleSearchDataCenter
   * @description Function to handle data center search
   * @param e :accepts search input event
   */
  const handleSearchDataCenter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchKey(e.target.value);
    fetchDataCenterData(e.target.value);
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick action buttons from the table
   * @param action : The clicked action
   */
  const handleQuickAction = (action: string) => {
    switch (action) {
      case DataCenterQuickActions.DELETE:
        setShowDeleteModal(true);
        return;

      case DataCenterQuickActions.MANUAL_ENTRY:
        dispatch(setDataCenterComponent(DataCenterComponents.MANUAL_ENTRY));
        return;

      case DataCenterQuickActions.EDIT:
        setShowCreateDataCenterModal(true);
        return;
    }
  };
  /**
   * @function fetchDataCenterData
   * @description Function to fetch Data Center data
   * @param searchString string to be searched for.
   * @param page page number defaults to first page
   */
  const fetchDataCenterData = (searchString: string = '', page: number = 1) => {
    if (permissions.costControlList) {
      setFetchDataCenterDataRequestStatus(REQUEST_STATUS.PROCESSING);
      const params = {
        key: searchString,
        page: page - 1,
        size: PAGINATION_SIZE,
      };
      getAllDataCenters(params)
        .then((res: any) => {
          if (res?.status === 200) {
            setDataCenterData(res.data.responseData.content);
            setTotalDataCenterCount(res.data.responseData.totalElements);
            setFetchDataCenterDataRequestStatus(REQUEST_STATUS.SUCCESS);
            return;
          }
          setFetchDataCenterDataRequestStatus(REQUEST_STATUS.ERROR);
        })
        .catch((e) =>
          onApiCallError(e, false, setFetchDataCenterDataRequestStatus)
        );
    }
  };

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    if (searchKey.length === 0) {
      fetchDataCenterData('', page);
    } else {
      fetchDataCenterData(searchKey, page);
    }
  };

  /**
   * @function deleteSelectedDataCenter
   * @description Function to delete a data center
   */
  const deleteSelectedDataCenter = () => {
    setDeleteDataCenterRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      dataCenterCode: selectedDataCenter?.dataCenterCode,
    };

    deleteDataCenter(params)
      .then((res: any) => {
        if (res?.status === 200) {
          message.success(t('dataCenterTable.deleteSuccessMessage'));
          setDeleteDataCenterRequestStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          onCreateDataCenter();
          return;
        }
        setDeleteDataCenterRequestStatus(REQUEST_STATUS.ERROR);
        message.error({
          content: res?.message ?? t('dataCenterTable.deleteFailureMessage'),
        });
        setShowDeleteModal(false);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteDataCenterRequestStatus,
          e?.response?.data?.message ??
            t('dataCenterTable.deleteFailureMessage')
        );
        setShowDeleteModal(false);
      });
  };

  /**
   * @function onCreateDataCenter
   * @description Callback function after successful creation of data center
   */
  const onCreateDataCenter = () => {
    fetchDataCenterData();
    setCurrentPage(1);
    setSearchKey('');
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + index + 1,
      width: '4%',
    },
    {
      title: t('dataCenterTable.dataCenterName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('dataCenterTable.dataCenterCode'),
      dataIndex: 'dataCenterCode',
      key: 'dataCenterCode',
    },
    {
      title: t('dataCenterTable.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('dataCenterTable.dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (text: string) => {
        return text ? moment(text).format(DATE_FORMAT) : '--';
      },
    },
    {
      title: t('dataCenterTable.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_text: string, record: DataCenterListType) => (
        <QuickActionMenu
          setCurrentConnectionData={() =>
            dispatch(setSelectedDataCenter(record))
          }
          quickActions={DATA_CENTER_QUICKACTIONS}
          quickActionHandler={(action: string) => {
            handleQuickAction(action);
          }}
          disabled={!permissions.costControlModify}
        />
      ),
      width: '8%',
      align: 'center',
    },
  ];

  return (
    <div className="data-center-container flex flex-column flex-fit">
      <header className="data-center-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="modal-heading">{t('navigationMenu.dataCenter')}</div>
        {permissions.costControlWrite && (
          <div className="flex flex-gap-16">
            <Button
              type={BUTTON_TYPE.OUTLINED}
              title={t('dataCenterTable.uploadData')}
              onClick={() => setShowUploadDataModal(true)}
            />
            <Button
              title={t('dataCenterTable.createDataCenter')}
              onClick={() => {
                setShowCreateDataCenterModal(true);
                dispatch(setSelectedDataCenter(undefined));
              }}
            />
          </div>
        )}
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="data-center-content margin-24 flex flex-column flex-fit">
          <SearchInput
            placeholder={t('dataCenterTable.searchPlaceholder')}
            type="search"
            size={INPUT_SIZE.SMALL}
            onChange={debounce(handleSearchDataCenter, DEBOUNCE_TIME_DELAY)}
          />
          <Table
            rootClassName="data-center-table"
            pagination={false}
            dataSource={dataCenterData.map((item, index) => ({
              key: index,
              ...item,
            }))}
            columns={columns}
            scroll={{ y: '100%' }}
            loading={
              fetchDataCenterDataRequestStatus === REQUEST_STATUS.PROCESSING
            }
            locale={{
              emptyText: permissions.costControlList
                ? fetchDataCenterDataRequestStatus === REQUEST_STATUS.ERROR && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={t('graphErrorMessage')}
                    />
                  )
                : t('noPermissionForTable'),
            }}
            designVersion2
            fillContainer
          />
        </div>
      </div>
      <div className="page-footer flex flex-fit flex-align-items-end">
        {totalDataCenterCount > 0 && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalDataCenterCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </div>
      {selectedDataCenter !== undefined && showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={deleteSelectedDataCenter}
          loading={deleteDataCenterRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
      {showCreateDataCenterModal && (
        <CreateDataCenterModal
          showModal={showCreateDataCenterModal}
          setShowModal={setShowCreateDataCenterModal}
          onCreateDataCenter={onCreateDataCenter}
        />
      )}
      {showUploadDataModal && (
        <UploadDataFileModal
          showModal={showUploadDataModal}
          setShowModal={setShowUploadDataModal}
        />
      )}
    </div>
  );
};

export default DataCenterTable;
