import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import { ICONS } from 'constants/icons';
import { BUTTON_TYPE } from 'constants/appearance';
import {
  selectCostAllocation,
  setCostAllocationComponent,
  setCostAllocationData,
  setCurrentStep,
} from 'redux/costAllocationSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { VALIDATION_STATUS } from 'pages/ConnectingCSPPage/constants';
import { createOrUpdateCostAllocation } from 'pages/CostAllocationPage/services';
import { CostAllocationComponents } from 'pages/CostAllocationPage/constants';
import { defaultCostAllocationData } from 'constants/defaultValues';
import { onApiCallError } from 'utils/handleErrors';

import { COST_ALLOCATION_SAVE_STATUS } from '../SetupBusinessUnit/constants';

import './index.scss';

type FooterProps = {
  selectedProvider: string | undefined;
  itemsLength: number;
};

const Footer = ({ selectedProvider, itemsLength }: FooterProps) => {
  const { t } = useTranslation();
  const {
    currentStep,
    currentStepValidation,
    costAllocationData,
    totalSharedCost,
  } = useSelector(selectCostAllocation);
  const dispatch = useDispatch();

  const [submitRequestStatus, setSubmitRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [saveAsDraftReqStatus, setSaveAsDraftReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  const onSaveSubmitError = (saveStatus: string, message: string) => {
    const errorMessage =
      saveStatus === COST_ALLOCATION_SAVE_STATUS.SUBMITTED
        ? t('costAllocations.setupBusinessUnit.failedSubmissionMessage')
        : t('costAllocations.setupBusinessUnit.failureSavedAsDraft');
    dispatch(setErrorMessage(message || errorMessage));
    dispatch(setCostAllocationComponent(CostAllocationComponents.ERROR_PAGE));
  };

  /**
   * @function saveOrSubmitCostAllocationData
   * @description Function to save as draft or submit the allocations
   * @param saveStatus: string value to determine the type of save. It can be SAVED_AS_DRAFT or SUBMITTED.
   */
  const saveOrSubmitCostAllocationData = (saveStatus: string) => {
    const setReqStatus =
      saveStatus === COST_ALLOCATION_SAVE_STATUS.SAVED_AS_DRAFT
        ? setSaveAsDraftReqStatus
        : setSubmitRequestStatus;
    setReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: costAllocationData?.connection,
      connectorName: costAllocationData?.connectionName,
      invoiceMonth: costAllocationData?.month,
      status: saveStatus,
      selectedProjects: costAllocationData?.projects,
      sharedCost: totalSharedCost,
      businessUnits: costAllocationData?.businessUnits.map((businessUnit) => ({
        businessUnitName: businessUnit.businessUnitCode,
        sharePercentage: businessUnit.share,
        cost: businessUnit.cost,
        costCenterList: businessUnit.costCenters.map((costCenter) => ({
          costCenterCode: costCenter.costCenterCode,
          sharePercentage: costCenter.share,
          cost: costCenter.cost,
        })),
      })),
    };

    createOrUpdateCostAllocation(requestBody)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(
            setCostAllocationComponent(CostAllocationComponents.SUCCESS_PAGE)
          );
          setReqStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        onSaveSubmitError(saveStatus, res.data.message);
        setReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setReqStatus);
        onSaveSubmitError(saveStatus, e.response.data.message);
      });
  };

  const onClickNext = () => {
    if (currentStep < itemsLength - 1) {
      dispatch(setCurrentStep(currentStep + 1));
      return;
    }
    saveOrSubmitCostAllocationData(COST_ALLOCATION_SAVE_STATUS.SUBMITTED);
  };

  const onClickPrevious = () => {
    if (currentStep > 0) {
      dispatch(setCurrentStep(currentStep - 1));
    }
  };

  const getNextButtonTitle = () => {
    if (currentStep === itemsLength - 1)
      return t('costAllocations.setupBusinessUnit.submit');

    return t('connectionCSPForm.nextStep');
  };

  if (currentStep > 0)
    return (
      <footer className="cost-allocation-provider-footer flex flex-space-between flex-align-items-center">
        <div className="flex">
          <Button
            onClick={() => {
              dispatch(setCurrentStep(0));
              dispatch(setCostAllocationData(defaultCostAllocationData));
            }}
            title={t('connectionCSPForm.cancel')}
            type={BUTTON_TYPE.LINK}
          />
        </div>
        <div className="flex flex-gap-10">
          <Button
            onClick={onClickPrevious}
            title={t('connectionCSPForm.previousStep')}
            iconName={ICONS.ARROW_LEFT}
            type={BUTTON_TYPE.OUTLINED}
            reverseIconTitleOrder
          />
          {currentStep === itemsLength - 1 && (
            <Button
              onClick={() =>
                saveOrSubmitCostAllocationData(
                  COST_ALLOCATION_SAVE_STATUS.SAVED_AS_DRAFT
                )
              }
              title={t('costAllocations.setupBusinessUnit.saveAsDraft')}
              type={BUTTON_TYPE.NEUTRAL}
              loading={saveAsDraftReqStatus === REQUEST_STATUS.PROCESSING}
            />
          )}
          <Button
            onClick={onClickNext}
            title={getNextButtonTitle()}
            iconName={
              currentStep === itemsLength - 1 ? null : ICONS.ARROW_RIGHT
            }
            disabled={currentStepValidation === VALIDATION_STATUS.INVALID}
            loading={submitRequestStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
      </footer>
    );

  return (
    <footer className="cost-allocation-provider-footer flex flex-end flex-align-items-center">
      <Button
        onClick={onClickNext}
        title={t('connectionCSPForm.continue')}
        iconName={ICONS.ARROW_RIGHT}
        disabled={!selectedProvider}
      />
    </footer>
  );
};

export default Footer;
