import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
  selectPurchase,
  setAwsOpenSearchRisCartData,
  setSavingPlansCartData,
} from 'redux/purchaseSlice';
import { selectNavMenu } from 'redux/activeNavMenuSlice';
import EmptyCart from 'pages/PurchasePage/components/EmptyCart';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';
import { purchaseAwsOpenSearchRis } from 'pages/PurchasePage/components/ReservedInstances/services';
import { PurchaseAwsOpenSearchRisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import { PurchaseCartComponents } from 'pages/PurchasePage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  getRiTotalDue,
  getRiTotalMonthlyRecurringCost,
  getTotalOnetimePayment,
} from 'pages/PurchasePage/components/ReservedInstances/utils';

import BuyOpenSearchRiFormModal from '../BuyOpenSearchRiFormModal';
import PurchasedOpenSearchRICard from '../PurchasedOpenSearchRICard';

import './index.scss';

type OpenSearchRiCartProps = {
  setOpenSearchRiCartComponent: (val: PurchaseCartComponents) => void;
};

const OpenSearchRiCart = ({
  setOpenSearchRiCartComponent,
}: OpenSearchRiCartProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { awsOpenSearchRiCartData, selectedConnection } =
    useSelector(selectPurchase);
  const { isSubMenuOpen } = useSelector(selectNavMenu);

  const [showOpenSearchRiForm, setShowOpenSearchRiForm] = useState(false);
  const [placeOrderRequestStatus, setPlaceOrderRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  /**
   * @function purchaseOpenSearchRIs
   * @description Function to purchase the Open Search RIs
   */
  const _purchaseOpenSearchRIs = (cartData: PurchaseAwsOpenSearchRisType[]) => {
    const requests = cartData.map((item) => {
      const requestBody = {
        connectorId: selectedConnection?.connectorId,
        region: item.region,
        instanceCount: item.quantity,
        offeringId: item.offeringId,
        reservationName: item.reservationName,
      };

      return purchaseAwsOpenSearchRis(requestBody);
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        if (responses?.every((res: any) => res?.status === 200)) {
          setOpenSearchRiCartComponent(PurchaseCartComponents.SUCCESS);
          setPlaceOrderRequestStatus(REQUEST_STATUS.SUCCESS);
          dispatch(setSavingPlansCartData([]));
          return;
        }
        setOpenSearchRiCartComponent(PurchaseCartComponents.ERROR);
        setPlaceOrderRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setPlaceOrderRequestStatus);
        setOpenSearchRiCartComponent(PurchaseCartComponents.ERROR);
      });
  };

  const getComponent = () => {
    if (awsOpenSearchRiCartData.length === 0) {
      return (
        <EmptyCart
          description={t(
            'purchasePage.reservedInstancesLabels.emptyRiCartDescription'
          )}
          buttonTitle={t(
            'purchasePage.reservedInstancesLabels.addReservedInstances'
          )}
          setOpenForm={setShowOpenSearchRiForm}
        />
      );
    }

    return (
      <div className="aws-open-search-ri-cart flex flex-column flex-gap-16">
        <div className="flex flex-align-items-center flex-space-between">
          <div>
            <div className="modal-heading">
              {t(
                'purchasePage.reservedInstancesLabels.purchaseReservedInstances'
              )}
            </div>
            <div className="cart-heading-subtitle small-grey-button">
              {t('purchasePage.cart.cartHeadingSubTitle')}
            </div>
          </div>
          <Button
            title={t('purchasePage.cart.addNew')}
            type={BUTTON_TYPE.NEUTRAL}
            onClick={() => setShowOpenSearchRiForm(true)}
          />
        </div>
        <div className="cart-list flex flex-column flex-gap-10">
          {awsOpenSearchRiCartData
            .map((item, index) => ({ ...item, key: index }))
            .map((data, index) => (
              <PurchasedOpenSearchRICard
                openSearchRiData={data}
                index={index}
                key={data.key}
              />
            ))}
        </div>
        <PurchaseFooter
          totalHeading={t(
            'purchasePage.reservedInstancesLabels.riTotalDueNow',
            {
              count: awsOpenSearchRiCartData.length,
            }
          )}
          totalValue={getRiTotalDue(awsOpenSearchRiCartData)}
          costSummary={[
            {
              key: t(
                'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
              ),
              value: getRiTotalMonthlyRecurringCost(awsOpenSearchRiCartData),
            },
            {
              key: t('purchasePage.reservedInstancesLabels.oneTimePayment'),
              value: getTotalOnetimePayment(awsOpenSearchRiCartData),
            },
          ]}
          okTitle={t('purchasePage.cart.placeOrder')}
          onClickCancel={() => navigate(NAVIGATION_MENU_PATH.COMMITMENTS)}
          onClickOk={() => {
            setOpenSearchRiCartComponent(PurchaseCartComponents.SUCCESS);
            setPlaceOrderRequestStatus(REQUEST_STATUS.SUCCESS);
            dispatch(setAwsOpenSearchRisCartData([]));
          }}
          loadingOk={placeOrderRequestStatus === REQUEST_STATUS.PROCESSING}
          className={`cart-footer ${isSubMenuOpen && 'nav-pinned'}`}
        />
      </div>
    );
  };

  return (
    <section>
      {getComponent()}
      {showOpenSearchRiForm && (
        <BuyOpenSearchRiFormModal
          show={showOpenSearchRiForm}
          setShow={setShowOpenSearchRiForm}
        />
      )}
    </section>
  );
};

export default OpenSearchRiCart;
