export type SubSectionType = {
  id: string;
  title: string;
  rootPath?: string;
  otherPaths?: string[];
  paramPaths?: string[];
  permissions: string[];
  permissionConjunction: string;
};

export type NavigationMenuDataState = {
  id: string;
  title: string;
  rootPath?: string;
  otherPaths?: string[];
  subSections: SubSectionType[];
  icon?: any;
  iconName?: any;
  permissions: string[];
  subMenu?: NavigationMenuDataState[];
  hasSubMenu?: boolean;
  subSectionsFirst?: boolean;
};

export type ActiveNavIdTypes = {
  sectionId: string | undefined;
  subSectionId: string | undefined;
};

export type ActiveNavDataTypes = {
  sectionId: string;
  selectedNavData: SubSectionType;
};

export type DashboardViewType = {
  id: string;
  title: string;
};

export type DashboardListType = {
  connectorId: string;
  connectorName: string;
  customViews: any[];
  dashboardStatus: string;
  connectorProvider: string;
  id: string;
  name: string;
  type: string;
  defaultDashboard: boolean;
  dashBoardType: string;
  groupName: string;
  transactionName: string;
  customViewIds: string[];
  updatedAt: string;
  integrationName: string;
  integrationId: string;
};

export enum DASHBOARD_TYPE {
  PRE_BUILT = 'PRE_BUILT',
  CUSTOM = 'CUSTOM',
}
