import { getStatusTextClassName } from 'pages/CostOptimizationInsightsPage/utils';

import './index.scss';

type StatusElementProps = {
  statusKey: string;
  status?: string;
  className?: string;
};

const StatusElement = ({
  statusKey,
  status,
  className,
}: StatusElementProps) => {
  return (
    <span className={`${className} font-button status-element`}>
      <span className={`${className} ${getStatusTextClassName(statusKey)}`}>
        {status ?? statusKey}
      </span>
    </span>
  );
};

export default StatusElement;
