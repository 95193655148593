import i18n from 'i18n';

export enum CmdbFieldTagsQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const CMDB_FIELD_TAGS_QUICK_ACTION_OPTIONS = [
  { id: CmdbFieldTagsQuickActions.EDIT, title: i18n.t('edit') },
  { id: CmdbFieldTagsQuickActions.DELETE, title: i18n.t('delete') },
];
