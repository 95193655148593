import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getAllRulesets
 * @description Function to get all the ruleset list
 * @param params Object containing the request params
 * @return axios response from GET request
 */
export const getAllRulesets = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_ALL_RULESETS, null, params);
};

/**
 * @function deleteRuleset
 * @description Function to delete a ruleset based on ruleset id
 * @param params Object containing the request params
 * @return axios response from DELETE request
 */
export const deleteRuleset = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_RULESET, null, params);
};

/**
 * @function updateRulesetAutoPilotStatus
 * @description Function to update the autopilot status for a ruleset
 * @param body Object containing the request body
 * @return axios response from PUT request
 */
export const updateRulesetAutoPilotStatus = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_RULESET_AUTOPILOT_STATUS, body);
};
