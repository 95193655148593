import { useTranslation } from 'react-i18next';

import { RecommendationFiltersType } from 'pages/CostOptimizationInsightsPage/types';

import EachFilter from '../EachFilter';

import './index.scss';

type SelectedFiltersProps = {
  filters: RecommendationFiltersType[];
  selectedFilters: RecommendationFiltersType[];
};

const SelectedFilters = ({
  filters,
  selectedFilters,
}: SelectedFiltersProps) => {
  const { t } = useTranslation();

  return (
    <div className="views-tag-filters flex flex-align-items-center flex-gap-16">
      <div className="tag-title font-caption-bold">
        {t('costOptimizationInsight.viewDropdown.filteredBy')}
      </div>
      {selectedFilters.map(
        (eachFilter) =>
          eachFilter.values &&
          eachFilter.values.length > 0 && (
            <EachFilter
              filter={eachFilter}
              key={eachFilter.key}
              allValues={
                filters.find((tag) => tag.key === eachFilter.key)?.values
              }
            />
          )
      )}
    </div>
  );
};

export default SelectedFilters;
