import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchGcpCudEffectivenessDetails
 * @description Function to fetch the GCP CUD effectiveness details
 * @param params request parameters
 * @return axios response from GET request
 */
export const fetchGcpCudEffectivenessDetails = async (params: any) => {
  return ServiceCalls.get(APIS.GET_GCP_CUD_EFFECTIVENESS_DETAILS, null, params);
};

/**
 * @function fetchWMAssignedWeightage
 * @description Function to fetch the Waste Management Assigned Weightage
 * @return axios response from GET request
 */
export const fetchWMAssignedWeightage = async () => {
  return ServiceCalls.get(APIS.GET_WM_ASSIGNED_WEIGHTAGE);
};

/**
 * @function getGCPCoverageAndUtilizationDetails
 * @description Function to fetch the gcp coverage and utilization details
 * @param params request parameters
 * @return axios response from GET request
 */
export const getGCPCoverageAndUtilizationDetails = async (params: any) => {
  return ServiceCalls.get(
    APIS.GET_GCP_CUD_UTILIZATION_AND_COVERAGE_DETAILS,
    null,
    params
  );
};

/**
 * @function fetchAwsUtilizationDetails
 * @description Function to fetch the aws utilization details for scorecard
 * @param params request parameters
 * @return axios response from GET request
 */
export const fetchAwsUtilizationDetails = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_UTILIZATION_DETAILS, null, params);
};

/**
 * @function getAWSRIUtilizationDetails
 * @description Function to fetch the AWS RI utilization details
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAWSRIUtilizationDetails = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_RI_UTILIZATION, null, params);
};

/**
 * @function getAWSRICoverageDetails
 * @description Function to fetch the AWS RI coverage details
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAWSRICoverageDetails = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_RI_COVERAGE, null, params);
};

/**
 * @function getAWSSPUtilizationDetails
 * @description Function to fetch the AWS SP utilization details
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAWSSPUtilizationDetails = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_SP_UTILIZATION, null, params);
};

/**
 * @function getAWSSPCoverageDetails
 * @description Function to fetch the AWS SP coverage details
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAWSSPCoverageDetails = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_SP_COVERAGE, null, params);
};
