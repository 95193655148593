import { message } from 'antd';
import jsPDF from 'jspdf';
import { chunk } from 'lodash';
import { useEffect } from 'react';
import { toPng } from 'html-to-image';
import autoTable from 'jspdf-autotable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  PDF_ALL_GRAPHS,
  PDF_CONTENT_CONSTANTS,
  PDF_PAGE_ORIENTATION,
  PDF_META_DATA_CONSTANTS,
  LANDSCAPE_VIEW_SUPPORTED_CONTENTS,
  PDF_QUALITY,
  FILL_TYPE,
  PDF_TABLE_CONSTANTS,
  PDF_ALL_TABLE_GRAPHS,
} from 'constants/pdfConstants';
import { DATE_TIME_AM_PM, getCurrentDate } from 'utils/date';
import { selectDashboard, setPdfDownloadMode } from 'redux/dashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import {
  PdfContent,
  PdfMetaDataType,
  PdfCardDataType,
} from 'types/exportTypes';
import { logoPng } from 'assets/icons';
import deloitteWatermark from 'assets/icons/deloitteWatermark.png';
import {
  getGroupProviderPngLogo,
  getProviderPngLogo,
} from 'utils/providerDetails';
import { CHART_TYPES, COLORS } from 'constants/graphConfig';

import './index.scss';

type PdfDownloadComponentProps = {
  pdfContent: PdfContent[];
  pdfCardData?: PdfCardDataType;
  pdfMetaData: PdfMetaDataType;
};

const PdfDownloadComponent = ({
  pdfContent,
  pdfCardData,
  pdfMetaData,
}: PdfDownloadComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pdfGraphToDownload, pdfDownloadMode } = useSelector(selectDashboard);
  const { CARDS_CHUNK_SIZE } = PDF_CONTENT_CONSTANTS;
  const {
    PAGE_X_AXIS_OFFSET,
    PAGE_Y_AXIS_OFFSET,
    CB_360_LOGO_HEIGHT,
    CB_360_LOGO_WIDTH,
    WATERMARK_X_AXIS_OFFSET_LANDSCAPE,
    WATERMARK_Y_AXIS_OFFSET_LANDSCAPE,
    WATERMARK_X_AXIS_OFFSET_PORTRAIT,
    WATERMARK_Y_AXIS_OFFSET_PORTRAIT,
    WATERMARK_WIDTH,
    WATERMARK_HEIGHT,
    FOOTER_FONT_SIZE,
    DASHBOARD_TITLE_FONT_SIZE,
    DASHBOARD_TITLE_X_AXIS_OFFSET,
    DASHBOARD_TITLE_Y_AXIS_OFFSET,
    DASHBOARD_SUBTITLE_Y_AXIS_OFFSET,
    PRINT_DATE_Y_AXIS_OFFSET,
    PIE_CHART_LEGEND_CONSTANTS,
    MAP_LEGEND_CONSTANTS,
    BAR_CHART_LEGEND_CONSTANTS,
    LEGEND_TABLE_WIDTH,
    LEGEND_TABLE_SPACING,
    CHART_BORDER_WIDTH,
    CHART_BORDER_Y_OFFSET,
    CHART_BORDER_ROUND_RADIUS,
    LEGEND_TABLES_PER_PAGE,
    LEGEND_COLOR_WIDTH,
    LEGEND_COLOR_HEIGHT,
    LEGEND_COLOR_RADIUS,
  } = PDF_META_DATA_CONSTANTS;

  const { theme } = useSelector(selectTheme);

  const costCardChunks = chunk(pdfCardData?.costCardsData, CARDS_CHUNK_SIZE);
  pdfContent = pdfContent.filter(
    (item) =>
      (pdfGraphToDownload !== PDF_ALL_GRAPHS &&
        pdfGraphToDownload !== PDF_ALL_TABLE_GRAPHS) ||
      !item.excludeInGlobalDownload
  );

  useEffect(() => {
    if (pdfDownloadMode) {
      // Adding timeout as there is a small delay in rendering the graph and its dropdowns.
      setTimeout(() => {
        pdfGraphToDownload === PDF_ALL_TABLE_GRAPHS
          ? handleDownloadPdfAsTables()
          : handleDownloadPdf();
      }, 100);
    }
  }, [pdfDownloadMode]);

  const getPageOrientation = (
    contentType: string,
    columnLength?: number,
    isTableView = false
  ) => {
    if (contentType === CHART_TYPES.TABLE || isTableView) {
      return columnLength && columnLength > 3
        ? PDF_PAGE_ORIENTATION.LANDSCAPE
        : PDF_PAGE_ORIENTATION.PORTRAIT;
    }
    if (LANDSCAPE_VIEW_SUPPORTED_CONTENTS.includes(contentType)) {
      return PDF_PAGE_ORIENTATION.LANDSCAPE;
    } else {
      return PDF_PAGE_ORIENTATION.PORTRAIT;
    }
  };

  /**
   * @function addGraphToPdf
   * @description Function which adds graphs to a given jsPdf instance
   * @param doc the jsPdf element
   * @param pdfElement the element which is present in dom
   * @param pdfProperties the PdfContent object which contains information
   * for orientation and chart type
   */
  const addGraphToPdf = async (
    doc: any,
    pdfElement: Element,
    pdfProperties: PdfContent
  ) => {
    // Check if graph has custom HTML legends
    const legends = pdfElement.querySelector('.legends-wrapper');
    if (legends) {
      // Get the pie chart png
      const chart = await toPng(
        pdfElement.querySelector('.pie-wrapper') as HTMLElement,
        {
          pixelRatio: PDF_QUALITY,
        }
      );

      const graphHeaderWidth =
        pdfElement.querySelector('.graph-header')?.clientWidth!;
      const graphHeaderHeight =
        pdfElement.querySelector('.graph-header')?.clientHeight!;
      const graphHeaderHtml = pdfElement.querySelector(
        '.graph-header'
      ) as HTMLElement;
      let graphHeader = '';
      if (graphHeaderHtml)
        graphHeader = await toPng(graphHeaderHtml, {
          pixelRatio: PDF_QUALITY,
        });

      let legendText: string[] = [];
      let legendColor: number[][] = [];

      // Get the legends data from dom, the legend text and color
      legends?.childNodes.forEach((node) => {
        legendText = [...legendText, node.lastChild?.textContent!];

        // Get the RGB values from dom style using regex
        const rgbString = (node.firstChild as HTMLElement).style
          .backgroundColor;

        const rgbValues = rgbString
          .substring(rgbString.indexOf('(') + 1, rgbString.lastIndexOf(')'))
          .split(',')
          .map((value) => Number(value));

        legendColor = [...legendColor, rgbValues];
      });

      // Setup autotable for legends
      doc.addPage('a4', PDF_PAGE_ORIENTATION.LANDSCAPE);
      autoTable(doc, {
        columns: [{ header: '', dataKey: 'key' }],
        body: legendText.map((value) => {
          return { key: value };
        }),
        theme: 'plain',
        showHead: 'never',
        margin: {
          top: PIE_CHART_LEGEND_CONSTANTS.LEGEND_TABLE_TOP_OFFSET,
          left: PIE_CHART_LEGEND_CONSTANTS.LEGEND_TABLE_X_OFFSET,
          right: PAGE_X_AXIS_OFFSET,
          bottom: PIE_CHART_LEGEND_CONSTANTS.LEGEND_TABLE_BOTTOM_OFFSET,
        },
        tableWidth: LEGEND_TABLE_WIDTH,
        didDrawPage: function (data) {
          // Add border on the page
          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();
          doc.setLineWidth(CHART_BORDER_WIDTH);
          doc.roundedRect(
            PAGE_X_AXIS_OFFSET,
            CHART_BORDER_Y_OFFSET,
            pageWidth - 20,
            pageHeight - 50,
            CHART_BORDER_ROUND_RADIUS,
            CHART_BORDER_ROUND_RADIUS,
            FILL_TYPE.STROKE
          );
          doc.addImage(
            chart,
            'png',
            PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_X_OFFSET,
            PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_Y_OFFSET + 2,
            pageHeight - 70,
            pageHeight - 50
          );

          // Add graph header and filters
          // Width is calculated according to how big the original width was in the DOM.
          // So width and height are reduced keeping the aspect ratio same for the element.
          if (graphHeaderHtml) {
            const calculatedScaledDownWidth =
              (DASHBOARD_TITLE_FONT_SIZE / graphHeaderHeight) *
              graphHeaderWidth;
            doc.addImage(
              graphHeader,
              PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_X_OFFSET,
              PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_Y_OFFSET,
              calculatedScaledDownWidth,
              DASHBOARD_TITLE_FONT_SIZE
            );
          } else {
            // Add Graph name on the page instead of graph header html
            doc
              .setFont(undefined, 'bold')
              .setFontSize(14)
              .setTextColor('#0A1E40')
              .text(
                pdfProperties.graphName,
                PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_X_OFFSET,
                PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_Y_OFFSET + 10
              );
          }

          // Make 2 tables come in one page and set margin of table respectively
          if (data.table.pageNumber % LEGEND_TABLES_PER_PAGE) {
            doc.setPage(doc.internal.getNumberOfPages() - 1);
            data.table.settings.margin.left +=
              LEGEND_TABLE_WIDTH + LEGEND_TABLE_SPACING;
          } else {
            data.table.settings.margin.left =
              PIE_CHART_LEGEND_CONSTANTS.LEGEND_TABLE_X_OFFSET;
          }
        },
        didDrawCell: function (data) {
          // Add legend color in front of legend text
          if (legendColor[data.row.index]?.length >= 3)
            doc.setFillColor(
              legendColor[data.row.index][0],
              legendColor[data.row.index][1],
              legendColor[data.row.index][2]
            );
          doc.roundedRect(
            data.cell.x - 5,
            data.cell.y + 1,
            LEGEND_COLOR_WIDTH,
            LEGEND_COLOR_HEIGHT,
            LEGEND_COLOR_RADIUS,
            LEGEND_COLOR_RADIUS,
            FILL_TYPE.FILL
          );
        },
      });
      return;
    }

    // Check if graph legends are passed in pdfProperties prop
    if (pdfProperties.legends) {
      await addLegendsToPdf(doc, pdfElement, pdfProperties);
      return;
    }

    // For graphs which do not have custom html legends we add that graph directly
    const image = await toPng(pdfElement as HTMLElement, {
      pixelRatio: PDF_QUALITY,
    });
    doc.addPage('a4', getPageOrientation(pdfProperties.contentType));
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    doc.addImage(image, 'png', 10, 30, width - 20, height - 50);
  };

  /**
   * @function addLegendsToPdf
   * @description Add legends to pdf
   * @param doc the jsPdf element
   * @param pdfElement the element which is present in dom
   * @param pdfProperties the PdfContent object which contains information
   */
  const addLegendsToPdf = async (
    doc: any,
    pdfElement: Element,
    pdfProperties: PdfContent
  ) => {
    let chart: any;
    if (
      pdfProperties.contentType === CHART_TYPES.PIE_CHART ||
      pdfProperties.contentType === CHART_TYPES.DOUGHNUT_CHART ||
      pdfProperties.contentType === CHART_TYPES.BAR_CHART ||
      pdfProperties.contentType === CHART_TYPES.STACK_CHART ||
      pdfProperties.contentType === CHART_TYPES.MAP ||
      pdfProperties.contentType === CHART_TYPES.HORIZONTAL_BAR_CHART
    ) {
      chart = await toPng(
        pdfElement.querySelector('.pdf-wrapper') as HTMLElement,
        {
          pixelRatio: PDF_QUALITY,
        }
      );

      // Get the legends data from dom, the legend text and color
      const allLegends = pdfProperties.legends!;
      if (allLegends.length === 0)
        allLegends.push({
          color: COLORS.secondaryBlack,
          name: t('pdfLabels.noDataAvailable'),
        });
      let legendText: string[] = allLegends.map((legend) => legend.name);
      let legendColor: string[] = allLegends.map((legend) => legend.color);

      let pdfConstants;
      switch (pdfProperties.contentType) {
        case CHART_TYPES.MAP:
          pdfConstants = MAP_LEGEND_CONSTANTS;
          break;
        case CHART_TYPES.BAR_CHART:
        case CHART_TYPES.STACK_CHART:
        case CHART_TYPES.HORIZONTAL_BAR_CHART:
          pdfConstants = BAR_CHART_LEGEND_CONSTANTS;
          break;
        case CHART_TYPES.PIE_CHART:
        case CHART_TYPES.DOUGHNUT_CHART:
        default:
          pdfConstants = PIE_CHART_LEGEND_CONSTANTS;
          break;
      }
      let {
        LEGEND_TABLE_TOP_OFFSET,
        LEGEND_TABLE_X_OFFSET,
        LEGEND_TABLE_BOTTOM_OFFSET,
      } = pdfConstants;

      // Setup autotable for legends
      doc.addPage('a4', PDF_PAGE_ORIENTATION.LANDSCAPE);
      autoTable(doc, {
        columns: [{ header: '', dataKey: 'key' }],
        body: legendText.map((value) => {
          return { key: value };
        }),
        theme: 'plain',
        showHead: 'never',
        margin: {
          top: LEGEND_TABLE_TOP_OFFSET,
          left: LEGEND_TABLE_X_OFFSET,
          right: PAGE_X_AXIS_OFFSET,
          bottom: LEGEND_TABLE_BOTTOM_OFFSET,
        },
        tableWidth: LEGEND_TABLE_WIDTH,
        didDrawPage: function (data) {
          addLegendTableAndGraphPages(
            doc,
            data,
            chart,
            pdfProperties,
            pdfElement
          );
        },
        didDrawCell: function (data) {
          // Add legend color in front of legend text
          if (legendColor[data.row.index])
            doc.setFillColor(legendColor[data.row.index]);
          doc.roundedRect(
            data.cell.x - 5,
            data.cell.y + 1,
            LEGEND_COLOR_WIDTH,
            LEGEND_COLOR_HEIGHT,
            LEGEND_COLOR_RADIUS,
            LEGEND_COLOR_RADIUS,
            FILL_TYPE.FILL
          );
        },
      });
    }
  };

  /**
   * @function addLegendTableAndGraphPages
   * @description Add legend table in different pages if required
   * @param doc the jsPdf element
   * @param data the data of the table from didDrawPage callback
   * @param chart the chart image
   * @param pdfProperties the PdfContent object which contains information
   * @param pdfElement the element which is present in dom
   */
  const addLegendTableAndGraphPages = async (
    doc: any,
    data: any,
    chart: any,
    pdfProperties: PdfContent,
    pdfElement: Element
  ) => {
    // Add border on the page
    const graphHeaderWidth =
      pdfElement.querySelector('.graph-header')?.clientWidth!;
    const graphHeaderHeight =
      pdfElement.querySelector('.graph-header')?.clientHeight!;
    const graphHeaderHtml = pdfElement.querySelector(
      '.graph-header'
    ) as HTMLElement;
    let graphHeader = '';
    if (graphHeaderHtml)
      graphHeader = await toPng(graphHeaderHtml, {
        pixelRatio: PDF_QUALITY,
      });
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setLineWidth(CHART_BORDER_WIDTH);
    doc.roundedRect(
      PAGE_X_AXIS_OFFSET,
      CHART_BORDER_Y_OFFSET,
      pageWidth - 20,
      pageHeight - 50,
      CHART_BORDER_ROUND_RADIUS,
      CHART_BORDER_ROUND_RADIUS,
      FILL_TYPE.STROKE
    );
    switch (pdfProperties.contentType) {
      case CHART_TYPES.PIE_CHART:
      case CHART_TYPES.DOUGHNUT_CHART:
        doc.addImage(
          chart,
          'png',
          PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_X_OFFSET,
          PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_Y_OFFSET + 20,
          pageHeight - 80,
          pageHeight - 80
        );
        break;
      case CHART_TYPES.MAP:
        doc.addImage(
          chart,
          'png',
          PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_X_OFFSET - 10,
          PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_Y_OFFSET + 30,
          pageHeight - 20,
          pageHeight - 100
        );
        break;
      case CHART_TYPES.BAR_CHART:
      case CHART_TYPES.STACK_CHART:
      case CHART_TYPES.HORIZONTAL_BAR_CHART:
        doc.addImage(
          chart,
          'png',
          PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_X_OFFSET,
          PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_Y_OFFSET + 30,
          pageWidth - 75,
          pageHeight - 100
        );
        break;
    }

    // Add graph header and filters
    // Width is calculated according to how big the original width was in the DOM.
    // So width and height are reduced keeping the aspect ratio same for the element.
    if (graphHeaderHtml) {
      const calculatedScaledDownWidth =
        (DASHBOARD_TITLE_FONT_SIZE / graphHeaderHeight) * graphHeaderWidth;
      doc.addImage(
        graphHeader,
        PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_X_OFFSET,
        PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_Y_OFFSET,
        calculatedScaledDownWidth,
        DASHBOARD_TITLE_FONT_SIZE
      );
    } else {
      // Add Graph name on the page instead of graph header html
      doc
        .setFont(undefined, 'bold')
        .setFontSize(14)
        .setTextColor('#0A1E40')
        .text(
          pdfProperties.graphHeaderOverride ??
            pdfProperties.tableName ??
            pdfProperties.graphName ??
            '',
          PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_X_OFFSET,
          PIE_CHART_LEGEND_CONSTANTS.LEGEND_CHART_Y_OFFSET + 10
        );
    }

    // Make tables come in one page and set margin of table respectively
    if (
      pdfProperties.contentType === CHART_TYPES.PIE_CHART ||
      pdfProperties.contentType === CHART_TYPES.DOUGHNUT_CHART
    ) {
      if (data.table.pageNumber % LEGEND_TABLES_PER_PAGE) {
        doc.setPage(doc.internal.getNumberOfPages() - 1);
        data.table.settings.margin.left +=
          LEGEND_TABLE_WIDTH + LEGEND_TABLE_SPACING;
      } else {
        data.table.settings.margin.left =
          PIE_CHART_LEGEND_CONSTANTS.LEGEND_TABLE_X_OFFSET;
      }
    }
  };

  /**
   * @function addTableToPdf
   * @description Function which adds table to a given jsPdf instance
   * @param doc the jsPdf element
   * @param pdfProperties the PdfContent object which contains information
   * for orientation and chart type
   */
  const addTableToPdf = (doc: any, pdfProperties: PdfContent) => {
    doc.addPage(
      'a4',
      getPageOrientation(
        pdfProperties.contentType,
        pdfProperties.column?.length,
        pdfProperties.isTableView
      )
    );

    // Calculate auto sizing of columns with respect to both content and header
    autoTable(doc, {
      columns: pdfProperties.column,
      columnStyles:
        (pdfProperties.column?.length ?? 0) <
          PDF_TABLE_CONSTANTS.SPLIT_TABLE_IF_MORE_THAN_COLUMNS &&
        calculateColumnWidths(doc, pdfProperties),
      body: pdfProperties.body,
      theme: 'plain',
      horizontalPageBreak:
        (pdfProperties.column?.length ?? 0) >=
        PDF_TABLE_CONSTANTS.SPLIT_TABLE_IF_MORE_THAN_COLUMNS,
      horizontalPageBreakRepeat: 0,
      headStyles: {
        fontStyle: 'bold',
        fillColor: COLORS.fnGrey13,
        valign: 'middle',
      },
      foot: pdfProperties.tableFooterData?.totalTableData
        ? [pdfProperties.tableFooterData?.totalTableData]
        : undefined,
      showFoot: 'lastPage',
      bodyStyles: {
        valign: 'middle',
      },
      margin: {
        top: PDF_TABLE_CONSTANTS.MARGIN_TOP,
        left: PDF_TABLE_CONSTANTS.MARGIN_HORIZONTAL,
        right: PDF_TABLE_CONSTANTS.MARGIN_HORIZONTAL,
        bottom: PDF_TABLE_CONSTANTS.MARGIN_BOTTOM,
      },
      startY: 37,
      didParseCell: function (hookData) {
        if (hookData.section === 'body') {
          if (pdfProperties.boldRows?.includes(hookData.row.index)) {
            for (const cell of Object.values(hookData.row.cells)) {
              cell.styles.fontStyle = 'bold';
            }
          }
        }
      },
      didDrawPage: function (data) {
        if (data.pageNumber === 1) {
          doc
            .setFont(undefined, 'bold')
            .setFontSize(15)
            .setTextColor(40)
            .text(pdfProperties.tableName!, data.settings.margin.left, 35)
            .setFontSize(10);
        }
      },
    });

    addTableFooterData(doc, pdfProperties);
  };

  /**
   * @function addTableFooterData
   * @description Function which adds table footer data to a given jsPdf instance
   * @param doc the jsPdf element
   * @param pdfProperties the PdfContent object which contains information
   */
  const addTableFooterData = (doc: any, pdfProperties: PdfContent) => {
    pdfProperties.tableFooterData?.recommendationData?.forEach(
      (eachRecommendationData, index) => {
        if (index === 0) {
          const text = eachRecommendationData.key + ': ';
          const value = eachRecommendationData.value;
          const xOffset = 12 + doc.getStringUnitWidth(text) * 3.5;
          doc
            .setFont(undefined, 'normal')
            .setFontSize(10)
            .setTextColor('#0A1E40')
            .text(text, 12, doc.lastAutoTable.finalY + 5);
          doc
            .setFont(undefined, 'bold')
            .setFontSize(10)
            .setTextColor('#0A1E40')
            .text(value, xOffset, doc.lastAutoTable.finalY + 5);
        }
        if (index === 1) {
          const text = '| ' + eachRecommendationData.key + ': ';
          const value = eachRecommendationData.value;
          const xOffset = 60 + doc.getStringUnitWidth(text) * 3.5;
          doc
            .setFont(undefined, 'normal')
            .setFontSize(10)
            .setTextColor('#0A1E40')
            .text(text, 60, doc.lastAutoTable.finalY + 5);
          doc
            .setFont(undefined, 'bold')
            .setFontSize(10)
            .setTextColor('#0A1E40')
            .text(value, xOffset, doc.lastAutoTable.finalY + 5);
        }
      }
    );
  };

  /**
   * @function calculateColumnWidths
   * @description Function which calculates the custom width of each column
   * @param doc the jsPdf element
   * @param pdfProperties the PdfContent object which contains information
   * for orientation and chart type
   * @returns the columnStyles object which contains the custom width of each column
   */
  const calculateColumnWidths = (doc: any, pdfProperties: PdfContent) => {
    // Get the total width of the table
    const totalTableWidth =
      doc.internal.pageSize.getWidth() -
      PDF_TABLE_CONSTANTS.MARGIN_HORIZONTAL * 2;

    // Get the max width of each column wrt both header and content
    const originalMaxColumnWidths =
      pdfProperties.column?.map((column) =>
        Math.max(
          PDF_TABLE_CONSTANTS.MIN_COLUMN_WIDTH,
          column.header?.length ?? PDF_TABLE_CONSTANTS.MIN_COLUMN_WIDTH,
          ...(pdfProperties.body?.map(
            (row) => (row[column.dataKey ?? ''] ?? '__').toString().length
          ) ?? [])
        )
      ) ?? [];

    // Sum of all the max widths
    const totalOriginalMaxColumnWidths = originalMaxColumnWidths.reduce(
      (a, b) => a + b,
      0
    );

    // Resize the max width of each column wrt the total width of the table
    const columnStyles: any = {};
    pdfProperties.column?.forEach(
      (_, i) =>
        (columnStyles[i] = {
          cellWidth:
            (originalMaxColumnWidths[i] / totalOriginalMaxColumnWidths) *
            totalTableWidth,
        })
    );
    return columnStyles;
  };

  /**
   * @function addCostCardsToPdf
   * @description Function which adds cost cards to a given jsPdf instance
   * @param doc the jsPdf element
   */
  const addCostCardsToPdf = async (
    doc: any,
    className: string = '.pdf-content-cards'
  ) => {
    const costElement = document.querySelector(className);
    if (costElement && pdfGraphToDownload === PDF_ALL_GRAPHS) {
      const image = await toPng(costElement as HTMLElement, {
        pixelRatio: PDF_QUALITY,
      });
      doc.addPage('a4', PDF_PAGE_ORIENTATION.LANDSCAPE);
      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();
      doc.addImage(image, 'png', 10, 30, width - 20, height - 50);
    }
  };

  /**
   * @function addContentsToPdf
   * @description Function which handles the addition of
   * graphs and tables to a given jsPdf instance
   * @param doc the jsPdf element
   */
  const addContentsToPdf = async (doc: any) => {
    // Graphs
    const pdfDomElements = document.querySelectorAll('.pdf-contents');

    for (let i = 0; i < pdfDomElements.length; i++) {
      const shouldAddGraphOrTable =
        pdfGraphToDownload === pdfContent.at(i)?.graphName ||
        pdfGraphToDownload === PDF_ALL_GRAPHS ||
        pdfGraphToDownload === PDF_ALL_TABLE_GRAPHS;

      if (pdfDomElements[i].querySelector('.pdf-content-graphs')) {
        if (shouldAddGraphOrTable) {
          await addGraphToPdf(doc, pdfDomElements[i], pdfContent[i]);
        }
      } else if (shouldAddGraphOrTable && pdfContent[i].body?.length) {
        addTableToPdf(doc, pdfContent[i]);
      }
    }
  };

  /**
   * @function handleDownloadPdf
   * @description Function which creates pdf and adds table/graphs/cost cards to the pdf
   * In the end it automatically downloads the data
   */
  const handleDownloadPdf = async () => {
    try {
      const doc = new jsPDF({ compress: true });

      await addCostCardsToPdf(doc);
      await addContentsToPdf(doc);
      await addCostCardsToPdf(doc, '.pdf-content-cards-insert-last');

      doc.deletePage(1);
      await addMetaData({ doc: doc });
      doc.save(`${pdfMetaData.fileName}_${pdfMetaData.viewName}`);
    } catch (error) {
      message.error(t('pdfDownloadErrorMessage'));
    } finally {
      dispatch(setPdfDownloadMode(false));
    }
  };

  /**
   * @function handleDownloadPdfAsTables
   * @description Function which creates pdf and adds tables to the pdf
   */
  const handleDownloadPdfAsTables = async () => {
    try {
      const doc = new jsPDF({ compress: true });

      await addCostCardsToPdf(doc);

      // Add those graphs as tables which have pdf table body available
      pdfContent
        .filter((item) => item.body?.length)
        .forEach((tableGraph) => {
          addTableToPdf(doc, tableGraph);
        });

      await addMetaData({ doc: doc });

      // remove the first page as it is empty
      doc.deletePage(1);
      doc.save(`${pdfMetaData.fileName}_${pdfMetaData.viewName}`);
    } catch (error) {
      message.error(t('pdfDownloadErrorMessage'));
    } finally {
      dispatch(setPdfDownloadMode(false));
    }
  };

  /**
   * @function addMetaData
   * @description Function adds meta data to pdf for example the header, footer,
   * headings, subheadings and provider
   */
  const addMetaData = async ({ doc }: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    const date = getCurrentDate(DATE_TIME_AM_PM);

    // For each page, print the page number and the total pages and date printed
    for (let i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      // CB360 logo
      doc.addImage(
        logoPng,
        'png',
        pageWidth - 80,
        PAGE_Y_AXIS_OFFSET,
        CB_360_LOGO_WIDTH,
        CB_360_LOGO_HEIGHT,
        {
          align: 'right',
        }
      );

      // Landscape watermark placement
      if (pageWidth > pageHeight) {
        doc.addImage(
          deloitteWatermark,
          'png',
          WATERMARK_X_AXIS_OFFSET_LANDSCAPE,
          WATERMARK_Y_AXIS_OFFSET_LANDSCAPE,
          WATERMARK_WIDTH,
          WATERMARK_HEIGHT
        );
      }
      // Portrait watermark placement
      else {
        doc.addImage(
          deloitteWatermark,
          'png',
          WATERMARK_X_AXIS_OFFSET_PORTRAIT,
          WATERMARK_Y_AXIS_OFFSET_PORTRAIT,
          WATERMARK_WIDTH,
          WATERMARK_HEIGHT
        );
      }

      // Add date and time of print
      doc
        .setFontSize(FOOTER_FONT_SIZE)
        .setTextColor(theme.textColor)
        .text(
          `${t('pdfLabels.printDate')}: ${date}`,
          pageWidth - PAGE_Y_AXIS_OFFSET,
          PRINT_DATE_Y_AXIS_OFFSET,
          {
            align: 'right',
          }
        );
      // Add footer
      doc
        .setTextColor(theme.textColor)
        .text(
          `${i}/${pageCount}`,
          PAGE_X_AXIS_OFFSET,
          pageHeight - PAGE_Y_AXIS_OFFSET
        )
        .text(
          pdfMetaData.viewName,
          pageWidth - PAGE_X_AXIS_OFFSET,
          pageHeight - PAGE_Y_AXIS_OFFSET,
          {
            align: 'right',
          }
        );

      // Provider logo
      if (pdfMetaData.provider) {
        doc.addImage(
          getProviderPngLogo(pdfMetaData.provider),
          'png',
          PAGE_X_AXIS_OFFSET,
          PAGE_Y_AXIS_OFFSET,
          12,
          12
        );
      }

      // Group Provider logo
      if (pdfMetaData.groupProvider) {
        doc.addImage(
          getGroupProviderPngLogo(pdfMetaData.groupProvider),
          'png',
          PAGE_X_AXIS_OFFSET,
          PAGE_Y_AXIS_OFFSET,
          12,
          12
        );
      }

      // Add dashboard title
      doc
        .setFont(undefined, 'bold')
        .setTextColor(theme.textColor)
        .setFontSize(DASHBOARD_TITLE_FONT_SIZE)
        .text(
          pdfMetaData.heading,
          DASHBOARD_TITLE_X_AXIS_OFFSET,
          DASHBOARD_TITLE_Y_AXIS_OFFSET
        );

      // Add dashboard sub title
      doc
        .setFontSize(FOOTER_FONT_SIZE)
        .text(
          pdfMetaData.subtitle1 ?? '',
          DASHBOARD_TITLE_X_AXIS_OFFSET,
          DASHBOARD_SUBTITLE_Y_AXIS_OFFSET
        );

      doc
        .setTextColor(theme.textColor)
        .text(
          pdfMetaData.subtitle2 ?? '',
          DASHBOARD_TITLE_X_AXIS_OFFSET +
            doc.getStringUnitWidth(pdfMetaData.subtitle1) * 4,
          DASHBOARD_SUBTITLE_Y_AXIS_OFFSET
        );
    }
  };

  const getGraphClassName = (contentType: string) => {
    if (getPageOrientation(contentType) === PDF_PAGE_ORIENTATION.PORTRAIT)
      return 'potrait';
    return 'landscape';
  };

  return (
    <div
      style={{
        overflow: 'hidden',
        height: 0,
      }}
    >
      <div>
        <div className="flex flex-column flex-center flex-gap-24">
          {pdfCardData?.costCardsData?.length && (
            <div className="flex flex-column flex-gap-8">
              <div className="pdf-content-cards preview-content flex flex-column landscape">
                <div className="card-chunk flex flex-column">
                  {costCardChunks
                    .map((item, index) => ({ chunk: item, key: index }))
                    .map((eachChunk) => (
                      <div
                        key={eachChunk.key}
                        className={`each-card flex ${
                          eachChunk.chunk.length < CARDS_CHUNK_SIZE &&
                          'remove-flex-fit'
                        }`}
                      >
                        {eachChunk.chunk}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {pdfCardData?.costOptmization &&
            pdfCardData?.costOptmization.cards && (
              <div
                className={`pdf-content-cards${
                  pdfCardData?.costOptmization.insertAtStart
                    ? ''
                    : '-insert-last'
                } landscape`}
              >
                {pdfCardData?.costOptmization.cards}
              </div>
            )}
          {pdfContent.map((eachContent) => (
            <div
              key={eachContent.graphName}
              className="pdf-contents flex flex-column flex-gap-8"
            >
              {eachContent.contentType !== CHART_TYPES.TABLE &&
              !eachContent.isTableView ? (
                <div
                  className={`pdf-content-graphs preview-content flex flex-column  ${getGraphClassName(
                    eachContent.contentType
                  )}`}
                  id="pdf-images"
                >
                  <div className="preview-graph-wrapper">
                    {eachContent.element}
                  </div>
                </div>
              ) : (
                <div className="pdf-content-table"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PdfDownloadComponent;
