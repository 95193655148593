import i18n from 'i18n';

import { CommitmentsSwitchEnum } from 'pages/PurchasePage/constants';

export const isPurchaseEnabled = false;

export const COMMITMENTS_SWITCH_OPTIONS = [
  {
    value: CommitmentsSwitchEnum.PURCHASED,
    label: i18n.t('purchasePage.purchased'),
  },
  {
    value: CommitmentsSwitchEnum.RECOMMENDATION,
    label: i18n.t('purchasePage.recommendation'),
  },
];
