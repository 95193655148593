import i18n from 'i18n';

export const PASSPHRASE_RADIO_OPTIONS = [
  {
    value: true,
    label: i18n.t('yes'),
  },
  {
    value: false,
    label: i18n.t('no'),
  },
];
