import { Upload } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DrawerComponent from 'components/DrawerComponent';
import { XlsFileIcon } from 'assets/icons';
import Button from 'components/Button';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getAllDataCenters } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import { getValidationStyle } from 'utils/validations';

import {
  checkAllColumns,
  convertExcelToJson,
  generateEmptyExcelFile,
  generateErrorExcelFile,
  validateXlsData,
} from '../../utils';
import { ExcelErrorType } from '../../types';
import { ManualEntryNavs, ValidationStatus } from '../../constants';
import {
  saveMultipleCompute,
  saveMultipleDatabase,
  saveMultipleLabor,
  saveMultipleNetwork,
  saveMultipleSoftware,
  saveMultipleStorage,
} from '../../services';

import './index.scss';

const { Dragger } = Upload;

type UploadDataFileModalProps = {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
};

const UploadDataFileModal = ({
  showModal,
  setShowModal,
}: UploadDataFileModalProps) => {
  const { t } = useTranslation();

  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileToValidate, setFileToValidate] = useState<File>();
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>();
  const [excelValidationErrors, setExcelValidationErrors] = useState<
    ExcelErrorType[]
  >([]);
  const [existingDataCenters, setExistingDataCenters] = useState<string[]>([]);
  const [dataCentersStatus, setDataCentersStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [computeStatus, setComputeStatus] = useState({
    label: ManualEntryNavs.COMPUTE,
    status: REQUEST_STATUS.SUCCESS,
  });
  const [storageStatus, setStorageStatus] = useState({
    label: ManualEntryNavs.STORAGE,
    status: REQUEST_STATUS.SUCCESS,
  });
  const [networkStatus, setNetworkStatus] = useState({
    label: ManualEntryNavs.NETWORK,
    status: REQUEST_STATUS.SUCCESS,
  });
  const [databaseStatus, setDatabaseStatus] = useState({
    label: ManualEntryNavs.DATABASE,
    status: REQUEST_STATUS.SUCCESS,
  });
  const [softwareStatus, setSoftwareStatus] = useState({
    label: ManualEntryNavs.SOFTWARE,
    status: REQUEST_STATUS.SUCCESS,
  });
  const [labourStatus, setLabourStatus] = useState({
    label: ManualEntryNavs.LABOUR,
    status: REQUEST_STATUS.SUCCESS,
  });
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setDataCentersStatus(REQUEST_STATUS.PROCESSING);
    getAllDataCenters()
      .then((res: any) => {
        setExistingDataCenters(
          res.data.responseData.content.map(
            (dataCenterObj: any) => dataCenterObj.dataCenterCode
          )
        );
        setDataCentersStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setDataCentersStatus));
  }, []);

  useEffect(() => {
    if (!isSubmitClicked || checkIfSomeStatuses(REQUEST_STATUS.PROCESSING))
      return;
    checkIfAllStatuses(REQUEST_STATUS.SUCCESS) && setShowModal(false);
    if (checkIfSomeStatuses(REQUEST_STATUS.ERROR)) {
      setErrorMessage(
        t('uploadTcoFile.errorSavingEntriesMessage', {
          resources: [
            computeStatus,
            storageStatus,
            networkStatus,
            databaseStatus,
            softwareStatus,
            labourStatus,
          ]
            .filter(
              (resourceStatus) => resourceStatus.status === REQUEST_STATUS.ERROR
            )
            .map((resourceStatus) => resourceStatus.label)
            .toString(),
        })
      );
    }
  }, [
    computeStatus,
    storageStatus,
    networkStatus,
    databaseStatus,
    softwareStatus,
    labourStatus,
  ]);

  /**
   * @function onClickValidate
   * @description Function to validate and save the manual entries data and errors
   */
  const onClickValidate = () => {
    if (fileToValidate) {
      checkAllColumns(fileToValidate).then((columnsMissing) => {
        if (columnsMissing) setValidationStatus(ValidationStatus.MISSING_COLS);
        else {
          validateXlsData(fileToValidate, existingDataCenters)
            .then((errors) => {
              if (errors.length) {
                setExcelValidationErrors(errors);
                setValidationStatus(ValidationStatus.VALIDATION_ERROR);
                return;
              }
              setValidationStatus(ValidationStatus.SUCCESS);
            })
            .catch((e) => {
              if (e === ValidationStatus.NO_ROWS_DETECTED) {
                setValidationStatus(ValidationStatus.NO_ROWS_DETECTED);
                return;
              }
              onApiCallError(e);
              setValidationStatus(ValidationStatus.VALIDATION_ERROR);
            });
        }
      });
    }
  };

  /**
   * @function onClickSubmit
   * @description Function to convert excel into json object and upload the manual entries
   */
  const onClickSubmit = () => {
    if (fileToValidate) {
      setIsSubmitClicked(true);
      convertExcelToJson(fileToValidate).then((body) => {
        const resources = Object.keys(body);
        resources.forEach((key) => {
          switch (key) {
            case ManualEntryNavs.COMPUTE:
              setComputeStatus({
                ...computeStatus,
                status: REQUEST_STATUS.PROCESSING,
              });
              saveMultipleCompute(body[key])
                .then((res) =>
                  onSuccessSaveResource(res, computeStatus, setComputeStatus)
                )
                .catch((e) =>
                  onErrorSaveResource(e, computeStatus, setComputeStatus)
                );
              break;

            case ManualEntryNavs.STORAGE:
              setStorageStatus({
                ...storageStatus,
                status: REQUEST_STATUS.PROCESSING,
              });
              saveMultipleStorage(body[key])
                .then((res) =>
                  onSuccessSaveResource(res, storageStatus, setStorageStatus)
                )
                .catch((e) =>
                  onErrorSaveResource(e, storageStatus, setStorageStatus)
                );
              break;

            case ManualEntryNavs.NETWORK:
              setNetworkStatus({
                ...storageStatus,
                status: REQUEST_STATUS.PROCESSING,
              });
              saveMultipleNetwork(body[key])
                .then((res) =>
                  onSuccessSaveResource(res, networkStatus, setNetworkStatus)
                )
                .catch((e) =>
                  onErrorSaveResource(e, networkStatus, setNetworkStatus)
                );
              break;

            case ManualEntryNavs.DATABASE:
              setDatabaseStatus({
                ...storageStatus,
                status: REQUEST_STATUS.PROCESSING,
              });
              saveMultipleDatabase(body[key])
                .then((res) =>
                  onSuccessSaveResource(res, databaseStatus, setDatabaseStatus)
                )
                .catch((e) =>
                  onErrorSaveResource(e, databaseStatus, setDatabaseStatus)
                );
              break;

            case ManualEntryNavs.SOFTWARE:
              setSoftwareStatus({
                ...storageStatus,
                status: REQUEST_STATUS.PROCESSING,
              });
              saveMultipleSoftware(body[key])
                .then((res) =>
                  onSuccessSaveResource(res, softwareStatus, setSoftwareStatus)
                )
                .catch((e) =>
                  onErrorSaveResource(e, softwareStatus, setSoftwareStatus)
                );
              break;

            case ManualEntryNavs.LABOUR:
              setLabourStatus({
                ...storageStatus,
                status: REQUEST_STATUS.PROCESSING,
              });
              saveMultipleLabor(body[key])
                .then((res) =>
                  onSuccessSaveResource(res, labourStatus, setLabourStatus)
                )
                .catch((e) =>
                  onErrorSaveResource(e, labourStatus, setLabourStatus)
                );
              break;
          }
        });
      });
    }
  };

  /**
   * @function onSuccessSaveResource
   * @description Function to handle when multiple entries of one resources are uploaded.
   * @param res Response from the API call.
   * @param setResourceStatus function to set the status for a resource
   */
  const onSuccessSaveResource = (
    res: any,
    resourceStatus: any,
    setResourceStatus: (val: any) => void
  ) => {
    setResourceStatus({
      ...resourceStatus,
      status:
        res.status === 200 ? REQUEST_STATUS.SUCCESS : REQUEST_STATUS.ERROR,
    });
  };

  /**
   * @function onErrorSaveResource
   * @description Function to handle when error is faced when updating multiple entries of one resource.
   * @param e error string.
   * @param setResourceStatus function to set the status for a resource
   */
  const onErrorSaveResource = (
    e: any,
    resourceStatus: any,
    setResourceStatus: (val: any) => void
  ) => {
    onApiCallError(e, false);
    setResourceStatus({ ...resourceStatus, status: REQUEST_STATUS.ERROR });
  };

  /**
   * @function checkIfAllStatuses Checks if all the requests that were processing are equal to given status condition.
   * Does an OR between status
   * @param statusCondition status condition for which to compare to
   * @returns boolean if all conditions are equal to given status condition
   */
  const checkIfAllStatuses = (statusCondition: string) =>
    [
      computeStatus,
      storageStatus,
      networkStatus,
      databaseStatus,
      softwareStatus,
      labourStatus,
    ].every((requestStatus) => requestStatus.status === statusCondition);

  /**
   * @function checkIfSomeStatuses Checks if some the requests that were processing are equal to given status condition.
   * Does an OR between status
   * @param statusCondition status condition for which to compare to
   * @returns boolean if some conditions are equal to given status condition
   */
  const checkIfSomeStatuses = (statusCondition: string) =>
    [
      computeStatus,
      storageStatus,
      networkStatus,
      databaseStatus,
      softwareStatus,
      labourStatus,
    ].some((requestStatus) => requestStatus.status === statusCondition);

  /**
   * @function downloadErrorLog
   * @description Function to download validated error file.
   */
  const downloadErrorLog = () => {
    if (fileToValidate && excelValidationErrors.length)
      generateErrorExcelFile(excelValidationErrors, fileToValidate);
  };

  /**
   * @function handleSelectFile
   * @description Function to handle the file upload.
   * @param options : options give the details of file
   */
  const handleSelectFile = async (options: any) => {
    const { onSuccess, file } = options;
    setFileToValidate(file);
    setFileName(file.name);
    setErrorMessage('');
    await onSuccess('ok');
    setIsFileUploaded(true);
  };

  /**
   * @function onRemoveFile
   * @description Function to handle remove file
   */
  const onRemoveFile = () => {
    setIsFileUploaded(false);
    setFileToValidate(undefined);
    setValidationStatus(undefined);
    setFileName('');
    setErrorMessage('');
    setIsSubmitClicked(false);
  };

  const getStatusComponent = () => {
    switch (validationStatus) {
      case ValidationStatus.VALIDATION_ERROR:
        return (
          <div className="error-message flex flex-justify-content-center flex-gap-8 font-caption">
            {t('uploadTcoFile.errorValidationXls')}
            <AccessibleDiv
              className="click-here-text cursor-pointer"
              onClick={downloadErrorLog}
              data-testid="error-file-cta"
            >
              {t('uploadTcoFile.clickHere')}
            </AccessibleDiv>
          </div>
        );
      case ValidationStatus.MISSING_COLS:
        return (
          <div className="error-message flex flex-justify-content-center flex-gap-8 font-caption">
            {t('uploadTcoFile.missingColumnsError')}
          </div>
        );
      case ValidationStatus.NO_ROWS_DETECTED:
        return (
          <div className="error-message flex flex-justify-content-center flex-gap-8 font-caption">
            {t('uploadTcoFile.noRowsDetected')}
          </div>
        );
      case ValidationStatus.SUCCESS:
        return (
          <div className="success-message flex flex-justify-content-center flex-gap-8 font-caption">
            {t('uploadTcoFile.successfullyValidated')}
          </div>
        );
    }
  };

  return (
    <DrawerComponent
      className="upload-tco-file"
      open={showModal}
      onClose={() => setShowModal(false)}
      title={t('uploadTcoFile.uploadFileHeader')}
      footer={
        <div className="flex flex-column flex-gap-8">
          <span
            style={{
              display: getValidationStyle(errorMessage),
            }}
            className="font-validation-error"
          >
            {errorMessage}
          </span>
          <div className="flex flex-align-items-center flex-end">
            <Button
              title={t('uploadTcoFile.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShowModal(false)}
            />
            <Button
              title={t(`uploadTcoFile.submit`)}
              disabled={ValidationStatus.SUCCESS !== validationStatus}
              loading={checkIfSomeStatuses(REQUEST_STATUS.PROCESSING)}
              onClick={onClickSubmit}
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-column flex-gap-16">
        <div className="card upload-form flex flex-column flex-gap-24">
          <div className="flex flex-column flex-gap-8">
            <div className="flex flex-space-between flex-align-items-center">
              <span className="font-caption-bold">
                {t('uploadTcoFile.xlsxFile')}
              </span>
              <Button
                title={t('uploadTcoFile.downloadFile')}
                onClick={generateEmptyExcelFile}
                type={BUTTON_TYPE.LINK}
                size={BUTTON_SIZE.SMALL}
              />
            </div>
            <Dragger
              customRequest={handleSelectFile}
              accept={'.xlsx'}
              className="dragger"
              maxCount={1}
              multiple={false}
              showUploadList={false}
              onRemove={onRemoveFile}
              openFileDialogOnClick={!isFileUploaded}
            >
              <div className="dragger-content flex flex-column flex-center flex-gap-4">
                <XlsFileIcon
                  width={25}
                  height={30}
                  className={isFileUploaded ? 'uploaded-icon' : 'normal-icon'}
                />
                {isFileUploaded ? (
                  <div className="flex flex-gap-4">
                    <span className="font-overline">{fileName}</span>
                    <Icon
                      iconName={ICONS.CLOSE_FILL}
                      size={ICONS_SIZE.SM}
                      className="small-close-icon flex flex-center"
                      onClick={onRemoveFile}
                      dataTestId="remove-file"
                    />
                  </div>
                ) : (
                  <>
                    <span className="font-overline">
                      {t('uploadTcoFile.selectExcelFile')}
                    </span>
                    <span className="font-subHeader-small">
                      {t('uploadTcoFile.orDragAndDrop')}
                    </span>
                  </>
                )}
              </div>
            </Dragger>
          </div>
          <Button
            title={t(`uploadTcoFile.validateSchema`)}
            className="validate-button"
            disabled={
              dataCentersStatus === REQUEST_STATUS.PROCESSING || !fileToValidate
            }
            onClick={onClickValidate}
          />
        </div>
        <div className="validation-message">{getStatusComponent()}</div>
      </div>
    </DrawerComponent>
  );
};

export default UploadDataFileModal;
