import { useTranslation } from 'react-i18next';

import Accordion from 'components/Accordion';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { ConnectionDependencyType } from 'pages/ConnectingCSPPage/types';
import Icon from 'components/Icon';
import { TickFillWarning } from 'assets/icons';

import DependencyHeading from './components/DependencyHeading';
import DependentsList from './components/DependentsList';

import './index.scss';

type ConnectionDependencyDisclaimerProps = {
  show: boolean;
  onClose: () => void;
  dependencies: ConnectionDependencyType[];
};

const ConnectionDependencyDisclaimer = ({
  show,
  onClose,
  dependencies,
}: ConnectionDependencyDisclaimerProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      rootClassName="connection-dependency-disclaimer"
      open={show}
      centered
      title={
        <div className="flex flex-align-items-center flex-gap-8">
          <Icon
            icon={TickFillWarning}
            className="tick-error"
            dataTestId="warning-cta"
          />
          {t('dependencyDisclaimer')}
        </div>
      }
      footer={
        <Button
          className="flex flex-inline"
          title={t('close')}
          onClick={onClose}
        />
      }
      closable={false}
      data-testid="connection-dependency-disclaimer"
      destroyOnClose={true}
      width="50%"
    >
      <div>
        <div className="title">{t('dependencyDisclaimerMessage')}</div>
        <Accordion
          className="connection-dependencies-list"
          items={dependencies.map((dependency) => ({
            key: dependency.dependentSectionId,
            heading: <DependencyHeading dependency={dependency} />,
            children: (
              <DependentsList dependents={dependency.dependenciesList} />
            ),
          }))}
          expandIconPosition="end"
        />
      </div>
    </Modal>
  );
};

export default ConnectionDependencyDisclaimer;
