import i18n from 'i18n';
import { PurchaseComputeSpType } from './types';

export enum SavingsPlansInstancesTabs {
  COMPUTE = 'COMPUTE',
}

export enum RIPaymentOptions {
  ALL_UPFRONT = 'All Upfront',
  PARTIAL_UPFRONT = 'Partial Upfront',
  NO_UPFRONT = 'No Upfront',
}

export enum ReservedInstancesStatus {
  PAYMENT_PENDING = 'payment-pending',
  PAYMENT_FAILED = 'payment-failed',
  ACTIVE = 'active',
  RETIRED = 'retired',
}

export const ReservedInstancesStatusLabels = [
  {
    key: ReservedInstancesStatus.PAYMENT_PENDING,
    label: i18n.t('purchasePage.paymentPending'),
  },
  {
    key: ReservedInstancesStatus.PAYMENT_FAILED,
    label: i18n.t('purchasePage.paymentFailed'),
  },
  {
    key: ReservedInstancesStatus.ACTIVE,
    label: i18n.t('purchasePage.active'),
  },
  {
    key: ReservedInstancesStatus.RETIRED,
    label: i18n.t('purchasePage.retired'),
  },
];

export const AZURE_COMPUTE_SP_DEFAULT_VALUES: PurchaseComputeSpType = {
  name: '',
  billingSubscription: '',
  applyEligibility: '',
  onDemandCommitment: 0,
  term: 1,
  billingFrequency: '',
};
