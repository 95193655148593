import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchAdminControls
 * @description Function to get the admin controls
 * @return axios response from GET request
 */
export const fetchAdminControls = async () => {
  return ServiceCalls.get(APIS.ADMIN_CONTROLS);
};

/**
 * @function updateAdminControls
 * @description Function to update the admin controls
 * @param body Request body containing the admin controls settings to be updated
 * @return axios response from PUT request
 */
export const updateAdminControls = async (body: any) => {
  return ServiceCalls.put(APIS.ADMIN_CONTROLS, body);
};
