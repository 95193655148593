import i18n from 'i18n';

export enum PURCHASE_PAGE_VIEWS {
  RESERVED_INSTANCE = 'Reserved Instance',
  SAVINGS_PLAN = 'Savings Plan',
  COMMITTED_USE_DISCOUNTS = 'Committed Use Discounts',
}

export const AwsPurchasePageViewsList = [
  {
    id: PURCHASE_PAGE_VIEWS.RESERVED_INSTANCE,
    title: i18n.t('purchasePage.reservedInstances'),
  },
  {
    id: PURCHASE_PAGE_VIEWS.SAVINGS_PLAN,
    title: i18n.t('purchasePage.savingsPlans'),
  },
];

export const GcpPurchasePageViewsList = [
  {
    id: PURCHASE_PAGE_VIEWS.COMMITTED_USE_DISCOUNTS,
    title: i18n.t('purchasePage.committedUseDiscounts'),
  },
];

export const AzurePurchasePageViewsList = [
  {
    id: PURCHASE_PAGE_VIEWS.SAVINGS_PLAN,
    title: i18n.t('purchasePage.savingsPlans'),
  },
];

export enum PurchasedListQuickActions {
  DELETE = 'DELETE',
}

export const PURCHASED_LIST_QUICKACTIONS = [
  {
    id: PurchasedListQuickActions.DELETE,
    title: i18n.t('purchasePage.deleteQueued'),
  },
];

export enum PurchaseCartComponents {
  CART = 'CART',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum Terms {
  ONE_YEAR = 1,
  THREE_YEARS = 3,
}

export enum TERMS_TYPES {
  BOTH = 'BOTH',
  ONE = 'ONE',
  THREE = 'THREE',
}

export enum RecommendationTerm {
  ONE_YEAR = 'ONE_YEAR',
  THREE_YEARS = 'THREE_YEARS',
}

export const TERMS_LIST = [
  {
    value: Terms.ONE_YEAR,
    recommendationValue: RecommendationTerm.ONE_YEAR,
    label: i18n.t('purchasePage.cart.countMonths', { count: 12 }),
    disabledTermsList: [TERMS_TYPES.THREE.valueOf()],
  },
  {
    value: Terms.THREE_YEARS,
    recommendationValue: RecommendationTerm.THREE_YEARS,
    label: i18n.t('purchasePage.cart.countMonths', { count: 36 }),
    disabledTermsList: [TERMS_TYPES.ONE.valueOf()],
  },
];

export enum CartListQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const CART_LIST_QUICKACTIONS = [
  {
    id: CartListQuickActions.EDIT,
    title: i18n.t('purchasePage.cart.edit'),
  },
  {
    id: CartListQuickActions.DELETE,
    title: i18n.t('purchasePage.cart.remove'),
  },
];

export enum SPAndEC2RIStatus {
  PAYMENT_PENDING = 'payment-pending',
  PAYMENT_FAILED = 'payment-failed',
  ACTIVE = 'active',
  RETIRED = 'retired',
  QUEUED = 'queued',
  QUEUED_DELETED = 'queued-deleted',
}

export const SPAndEC2RIStatusLabels = [
  {
    key: SPAndEC2RIStatus.PAYMENT_PENDING,
    label: i18n.t('purchasePage.paymentPending'),
  },
  {
    key: SPAndEC2RIStatus.PAYMENT_FAILED,
    label: i18n.t('purchasePage.paymentFailed'),
  },
  {
    key: SPAndEC2RIStatus.ACTIVE,
    label: i18n.t('purchasePage.active'),
  },
  {
    key: SPAndEC2RIStatus.RETIRED,
    label: i18n.t('purchasePage.retired'),
  },
  {
    key: SPAndEC2RIStatus.QUEUED,
    label: i18n.t('purchasePage.queued'),
  },
  {
    key: SPAndEC2RIStatus.QUEUED_DELETED,
    label: i18n.t('purchasePage.queuedDeleted'),
  },
];

export enum SPAndOpenSearchPaymentOptions {
  ALL_UPFRONT = 'ALL_UPFRONT',
  PARTIAL_UPFRONT = 'PARTIAL_UPFRONT',
  NO_UPFRONT = 'NO_UPFRONT',
}

export const SPAndOpenSearchPaymentOptionsList = [
  {
    value: SPAndOpenSearchPaymentOptions.ALL_UPFRONT,
    label: i18n.t('purchasePage.cart.allUpfront'),
  },
  {
    value: SPAndOpenSearchPaymentOptions.PARTIAL_UPFRONT,
    label: i18n.t('purchasePage.cart.partialUpfront'),
  },
  {
    value: SPAndOpenSearchPaymentOptions.NO_UPFRONT,
    label: i18n.t('purchasePage.cart.noUpfront'),
  },
];

export enum CommitmentsSwitchEnum {
  PURCHASED = 'PURCHASED',
  RECOMMENDATION = 'RECOMMENDATION',
}

export enum Days {
  SEVEN_DAYS = 'SEVEN_DAYS',
  THIRTY_DAYS = 'THIRTY_DAYS',
  SIXTY_DAYS = 'SIXTY_DAYS',
}

export const DAYS_LIST = [
  {
    value: Days.SEVEN_DAYS,
    label: i18n.t('purchasePage.countDays', { days: 7 }),
  },
  {
    value: Days.THIRTY_DAYS,
    label: i18n.t('purchasePage.countDays', { days: 30 }),
  },
  {
    value: Days.SIXTY_DAYS,
    label: i18n.t('purchasePage.countDays', { days: 60 }),
  },
];

export enum OfferingClass {
  CONVERTIBLES = 'convertible',
  STANDARD = 'standard',
}

export enum RecommendationOfferingClass {
  CONVERTIBLES = 'CONVERTIBLE',
  STANDARD = 'STANDARD',
}

export const OFFERING_CLASS_OPTIONS = [
  {
    value: OfferingClass.CONVERTIBLES,
    recommendationValue: RecommendationOfferingClass.CONVERTIBLES,
    label: i18n.t('purchasePage.reservedInstancesLabels.convertibles'),
  },
  {
    value: OfferingClass.STANDARD,
    recommendationValue: RecommendationOfferingClass.STANDARD,
    label: i18n.t('purchasePage.reservedInstancesLabels.standard'),
  },
];

export enum Tenancy {
  DEFAULT = 'default',
  DEDICATED = 'dedicated',
}

export enum RecommendationTenancy {
  SHARED = 'SHARED',
  DEDICATED = 'DEDICATED',
}

export const TENANCY_OPTIONS = [
  {
    value: Tenancy.DEFAULT,
    recommendationValue: RecommendationTenancy.SHARED,
    label: i18n.t('purchasePage.reservedInstancesLabels.default'),
  },
  {
    value: Tenancy.DEDICATED,
    recommendationValue: RecommendationTenancy.DEDICATED,
    label: i18n.t('purchasePage.reservedInstancesLabels.dedicated'),
  },
];

export const RI_SNOW_IDENTIFIER = 'RISP';
export const SP_SNOW_IDENTIFIER = 'SPRI';

export enum SPSavingPlanTypes {
  EC2_INSTANCE = 'EC2_INSTANCE',
  COMPUTE = 'COMPUTE',
  SAGE_MAKER = 'SAGE_MAKER',
}

export enum RecommendationSavingsPlanType {
  COMPUTE_SP = 'COMPUTE_SP',
  EC2_INSTANCE_SP = 'EC2_INSTANCE_SP',
  SAGEMAKER_SP = 'SAGEMAKER_SP',
}

export const SAVINGS_PLANS_TYPES = [
  {
    value: SPSavingPlanTypes.EC2_INSTANCE,
    recommendationValue: RecommendationSavingsPlanType.EC2_INSTANCE_SP,
    label: i18n.t('purchasePage.savingsPlansLabels.ec2Instance'),
  },
  {
    value: SPSavingPlanTypes.COMPUTE,
    recommendationValue: RecommendationSavingsPlanType.COMPUTE_SP,
    label: i18n.t('purchasePage.savingsPlansLabels.compute'),
  },
  {
    value: SPSavingPlanTypes.SAGE_MAKER,
    recommendationValue: RecommendationSavingsPlanType.SAGEMAKER_SP,
    label: i18n.t('purchasePage.savingsPlansLabels.sageMaker'),
  },
];
