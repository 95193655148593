import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { LoadingIcon } from 'assets/icons';
import { INPUT_SIZE } from 'constants/appearance';
import { getClassnameBySize } from 'utils/dashboardUtils';

const SelectDropdown = ({
  children,
  menu,
  loading,
  size = INPUT_SIZE.MEDIUM,
  iconOverride,
  ...remainingProps
}: any) => {
  const { theme } = useSelector(selectTheme);

  return (
    <Select
      disabled={loading}
      suffixIcon={
        loading ? (
          <Icon icon={LoadingIcon} className="rotate" />
        ) : (
          iconOverride ?? (
            <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
          )
        )
      }
      virtual={false}
      {...remainingProps}
      value={remainingProps?.value || undefined}
      dropdownStyle={
        {
          color: theme.textColor,
          '--button-icon-color': theme.buttonIconColor,
          '--primary-color': theme.primaryColor,
        } as React.CSSProperties
      }
      rootClassName={`${
        remainingProps.designVersion2
          ? `design-v2 ${getClassnameBySize(size)}`
          : 'design-v1'
      } ${remainingProps.rootClassName}`}
    >
      {menu || children}
    </Select>
  );
};

export const DROPDOWN_NONE = 'NONE';

export default SelectDropdown;
