import { useDispatch, useSelector } from 'react-redux';

import { selectReport, setReportOptions } from 'redux/reportSlice';
import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { CHART_TYPES } from 'constants/graphConfig';
import { CHARTS_LIST } from 'constants/dashboard';

import './index.scss';

const ChartsList = () => {
  const dispatch = useDispatch();

  const { reportOptions } = useSelector(selectReport);

  /**
   * @function isChartTypeDisabled
   * @description Function to return if the chart type is disabled
   * @param chartType chart type validated
   * @returns boolean true if teh chart type is disabled else false
   */
  const isChartTypeDisabled = (chartType: string) => {
    if (chartType === CHART_TYPES.TABLE) {
      return false;
    }

    return (
      reportOptions.dimension.length === 0 || reportOptions.metric.length === 0
    );
  };

  /**
   * @function onClickChartType
   * @description Callback function for selecting the chart type
   * @param chartType type of chart selected
   */
  const onClickChartType = (chartType: string) => {
    if (chartType === CHART_TYPES.TABLE) {
      dispatch(
        setReportOptions({
          ...reportOptions,
          chartType: chartType,
        })
      );
    }

    // Update dimensions based on the dimensions or metrics count required
    const maxDimensionCountAllowed =
      CHARTS_LIST.find((item) => item.chartType === chartType)?.dimensionsCount
        ?.max ?? 1;
    const currentDimensionsCount = reportOptions.chartDimension.length;
    const maxMetricsCountAllowed =
      CHARTS_LIST.find((item) => item.chartType === chartType)?.metricsCount
        ?.max ?? 1;
    const currentMetricsCount = reportOptions.chartMetric.length;

    if (
      (currentDimensionsCount > 0 &&
        currentDimensionsCount > maxDimensionCountAllowed) ||
      (currentMetricsCount > 0 && currentMetricsCount > maxMetricsCountAllowed)
    ) {
      dispatch(
        setReportOptions({
          ...reportOptions,
          chartDimension: [],
          chartMetric: [],
          chartType: chartType,
        })
      );
    } else {
      dispatch(
        setReportOptions({
          ...reportOptions,
          chartType: chartType,
        })
      );
    }
  };

  return (
    <div className="report-charts-type-list flex flex-center flex-gap-8 flex-wrap">
      {CHARTS_LIST.map((item) => (
        <Tooltip key={item.chartType} overlay={<span>{item.label}</span>}>
          <AccessibleDiv
            className={`chart-type-container cursor-pointer flex flex-center ${
              isChartTypeDisabled(item.chartType) && 'disabled'
            } ${reportOptions.chartType === item.chartType && 'selected'}`}
            key={item.chartType}
            dataTestId="chart-icon"
            onClick={() => onClickChartType(item.chartType)}
          >
            <Icon
              className="chart-icon flex flex-center flex-fit"
              iconName={item.iconName}
              icon={item.icon}
            />
          </AccessibleDiv>
        </Tooltip>
      ))}
    </div>
  );
};

export default ChartsList;
