import pptxgenjs from 'pptxgenjs';

import { CHART_TYPES, COLORS } from 'constants/graphConfig';
import { store } from 'redux/store';
import {
  GraphProperties,
  PptChartExportDataType,
  PptSlideCustomContent,
} from 'types/dashboard';
import { ColorPalleteType } from 'pages/AppearancePage/types';

import { generateGraphColors } from './dashboardUtils';

// Length unit is in inches
export const PPT_SLIDE_MARGIN = 0.2;
export const PPT_SLIDE_WIDTH = 10;
export const PPT_SLIDE_HEIGHT = 5.5;

export const downloadPptFromGraphData = async (
  fileName: string,
  graphData: GraphProperties[]
) => {
  const theme = store.getState().theme.theme;

  // 1. Create a Presentation
  let pres = new pptxgenjs();

  graphData
    .filter(
      (graph) =>
        !!graph.pptExport?.pptData?.length ||
        !!graph.pptExport?.pptCustomContent?.length
    )
    .forEach((graph) => {
      // 1. Add a Slide to the presentation
      let slide = pres.addSlide();

      // 2. Check for custom chart types and add Chart to the Slide
      if (graph.pptExport!.pptCustomContent) {
        addCustomChartSlides(slide, graph.pptExport!.pptCustomContent);
        return;
      }

      // 3. Check for default chart types and add Chart to the Slide
      switch (graph.contentType) {
        case CHART_TYPES.BAR_LINE_CHART:
          return addBarLineChartSlide(pres, graph, slide, theme);

        case CHART_TYPES.BAR_CHART:
          return addBarChartSlide(
            graph.pptExport!.pptData as PptChartExportDataType[],
            pres,
            graph,
            slide
          );

        case CHART_TYPES.STACK_CHART:
          return addBarChartSlide(
            graph.pptExport!.pptData as PptChartExportDataType[],
            pres,
            graph,
            slide,
            true
          );

        case CHART_TYPES.HORIZONTAL_BAR_CHART:
          return addBarChartSlide(
            graph.pptExport!.pptData as PptChartExportDataType[],
            pres,
            graph,
            slide,
            false,
            true
          );

        case CHART_TYPES.LINE_CHART:
          return addLineChartSlide(
            graph.pptExport!.pptData as PptChartExportDataType[],
            pres,
            graph,
            slide
          );

        case CHART_TYPES.TABLE:
        case CHART_TYPES.MAP:
          return addTableSlide(graph, slide);
      }
    });

  // 4. Save the Presentation
  await pres.writeFile({ fileName: `${fileName}.pptx` });
};

/**
 * @function addBarLineChartSlide
 * @description Add a Bar Line Chart Slide to the Presentation
 * @param pres presentation object
 * @param graph graph properties
 * @param slide slide object
 * @param theme color theme
 */
const addBarLineChartSlide = (
  pres: pptxgenjs,
  graph: GraphProperties,
  slide: pptxgenjs.Slide,
  theme: ColorPalleteType
) => {
  const chartData: pptxgenjs.OptsChartData[] = [
    {
      name: graph.chartView!.customLegends!.at(0),
      labels: (graph.pptExport!.pptData as PptChartExportDataType[]).at(0)!
        .labels,
      values: (graph.pptExport!.pptData as PptChartExportDataType[]).at(0)!
        .values,
    },
    {
      name: graph.chartView!.customLegends!.at(1),
      labels: (graph.pptExport!.pptData as PptChartExportDataType[]).at(0)!
        .labels,
      values: (graph.pptExport!.pptData as PptChartExportDataType[]).at(0)!
        .values,
    },
  ];

  const comboChartProperties: pptxgenjs.IChartMulti[] = [
    {
      type: pres.ChartType.bar,
      data: [chartData[0]],
      options: {
        chartColors: [theme.primaryColor],
      },
    },
    {
      type: pres.ChartType.line,
      data: [chartData[1]],
      options: {
        chartColors: [theme.secondaryColor],
        secondaryValAxis: true,
        secondaryCatAxis: true,
      },
    },
  ];

  const comboProps: pptxgenjs.IChartOpts = {
    x: 0.5,
    y: 0.5,
    w: '90%',
    h: '90%',
    barDir: 'col',
    //
    showLegend: true,
    legendPos: 'b',
    //
    showTitle: true,
    titleFontSize: 14,
    title: graph.pptExport!.chartHeading ?? graph.graphHeading,
    titleBold: true,
    //
    valAxes: [
      {
        showValAxisTitle: true,
        valAxisTitle: graph.chartView?.yAxisLabel,
      },
      {
        valAxisHidden: true,
      },
    ],
    //
    catAxes: [
      {
        showCatAxisTitle: true,
        catAxisTitle: graph.chartView?.xAxisLabel,
      },
      {
        catAxisHidden: true,
      },
    ],
  };

  slide.addChart(comboChartProperties, comboProps as any);
};

/**
 * @function addBarChartSlide
 * @description Add a Bar Chart Slide to the Presentation
 * @param pres presentation object
 * @param graph graph properties
 * @param slide slide object
 */
const addBarChartSlide = (
  chartData: pptxgenjs.OptsChartData[],
  pres: pptxgenjs,
  graph: GraphProperties,
  slide: pptxgenjs.Slide,
  isStacked = false,
  isHorizontal = false
) => {
  const barChartProperties: pptxgenjs.IChartOpts = {
    x: 0.5,
    y: 0.5,
    w: '90%',
    h: '90%',
    barDir: isHorizontal ? 'bar' : 'col',
    barGrouping: isStacked ? 'stacked' : 'clustered',
    chartColors: generateGraphColors(chartData.at(0)?.values?.length ?? 0).map(
      (value) => value.replace('#', '')
    ),
    //
    showLegend: true,
    legendPos: 'b',
    //
    showValue: !isStacked,
    dataLabelFormatCode: getFormatCode(graph),
    dataLabelFontSize: 8,
    //
    showTitle: true,
    title: graph.pptExport!.chartHeading ?? graph.graphHeading,
    titleBold: true,
    //
    valAxes: [
      {
        showValAxisTitle: true,
        valAxisTitle: graph.chartView?.yAxisLabel,
      },
    ],
    //
    catAxes: [
      {
        showCatAxisTitle: true,
        catAxisTitle: graph.chartView?.xAxisLabel,
      },
    ],
  };

  slide.addChart(pres.ChartType.bar, chartData, barChartProperties);
};

/**
 * @function addLineChartSlide
 * @description Add a Line Chart Slide to the Presentation
 * @param pres presentation object
 * @param graph graph properties
 * @param slide slide object
 */
const addLineChartSlide = (
  chartData: pptxgenjs.OptsChartData[],
  pres: pptxgenjs,
  graph: GraphProperties,
  slide: pptxgenjs.Slide
) => {
  const barChartProperties: pptxgenjs.IChartOpts = {
    x: 0.5,
    y: 0.5,
    w: '90%',
    h: '90%',
    chartColors: generateGraphColors(chartData[0].values!.length).map((value) =>
      value.replace('#', '')
    ),
    //
    showLegend: true,
    legendPos: 'b',
    //
    showValue: true,
    dataLabelFormatCode: getFormatCode(graph),
    dataLabelFontSize: 8,
    //
    showTitle: true,
    title: graph.pptExport!.chartHeading ?? graph.graphHeading,
    titleBold: true,
    //
    valAxes: [
      {
        showValAxisTitle: true,
        valAxisTitle: graph.chartView?.yAxisLabel,
      },
    ],
    //
    catAxes: [
      {
        showCatAxisTitle: true,
        catAxisTitle: graph.chartView?.xAxisLabel,
      },
    ],
  };

  slide.addChart(pres.ChartType.line, chartData, barChartProperties);
};

/**
 * @function addSpendProfileChartSlide
 * @description Add a Spend Profile Chart Slide to the Presentation
 * @param slide slide object
 * @param pptSlideCustomContentList custom content list to be added to the slide
 */
const addCustomChartSlides = (
  slide: pptxgenjs.Slide,
  pptSlideCustomContentList: PptSlideCustomContent
) => {
  pptSlideCustomContentList.forEach((slideContent) => {
    if (slideContent.contentType === 'text') {
      slide.addText(
        slideContent.content as string,
        slideContent.contentOptions as pptxgenjs.TextPropsOptions
      );
      return;
    }
    slide.addChart(
      slideContent.contentType,
      slideContent.content as pptxgenjs.OptsChartData[],
      slideContent.contentOptions as pptxgenjs.IChartOpts
    );
  });
};

/**
 * @function addTableSlide
 * @description Add a Table Slide to the Presentation
 * @param pres presentation object
 * @param graph graph properties
 * @param slide slide object
 */
const addTableSlide = (graph: GraphProperties, slide: pptxgenjs.Slide) => {
  if (graph.pptExport!.pptData!.length < 2) return;
  const tableProperties: pptxgenjs.TableToSlidesProps = {
    x: PPT_SLIDE_MARGIN,
    y: PPT_SLIDE_MARGIN * 2,
    w: PPT_SLIDE_WIDTH - PPT_SLIDE_MARGIN * 2,
    h: PPT_SLIDE_HEIGHT - PPT_SLIDE_MARGIN * 3,
    autoPage: true,
    autoPageRepeatHeader: true,
    newSlideStartY: PPT_SLIDE_MARGIN,
    fontSize: 6,
    border: { pt: 1, color: COLORS.fnGrey5 },
    align: 'center',
    valign: 'middle',
  };

  slide.addText(graph.graphHeading, {
    x: PPT_SLIDE_MARGIN,
    y: PPT_SLIDE_MARGIN,
    w: PPT_SLIDE_WIDTH - PPT_SLIDE_MARGIN * 2,
    align: 'center',
    fontSize: 14,
    bold: true,
  });
  slide.addTable(
    (graph.pptExport?.pptData as string[][]).map((row) =>
      row.map((cell) => ({ text: cell }))
    ),
    tableProperties
  );
};

const getFormatCode = (graphProperties: GraphProperties) => {
  if (graphProperties.pptExport?.prefixSymbol)
    return `${graphProperties.pptExport.prefixSymbol} 0.00`;
  if (graphProperties.pptExport?.suffixSymbol)
    return `0.00 \\${graphProperties.pptExport.suffixSymbol}`;

  const currencySymbol = store.getState().commonUtility.currencySymbol;
  return `${currencySymbol} 0.00`;
};
