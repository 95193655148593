import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCostAllocationComponent } from 'redux/costAllocationSlice';
import { CostAllocationComponents } from './constants';

const CostAllocationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setCostAllocationComponent(CostAllocationComponents.ALLOCATION_FORM)
    );
    navigate(NAVIGATION_MENU_PATH.CREATE_COST_ALLOCATION);
  });
  return <></>;
};

export default CostAllocationPage;
