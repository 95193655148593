import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectDashboard } from 'redux/dashboardSlice';
import ControlComponent from 'components/DashboardControl';
import DropdownCheckbox from 'components/DropdownCheckbox';
import { INPUT_SIZE } from 'constants/appearance';
import { CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT } from 'constants/userConsole';
import { REQUEST_STATUS } from 'constants/requestBody';

import { getAccountsFilterLabelByProvider, getAccountData } from './utils';

type DatabaseControlsProps = {
  accountsProps: {
    selectedAccounts: string[];
    setSelectedAccounts: (val: string[]) => void;
    accountsReqStatus: string;
    setAccountsReqStatus: (val: string) => void;
  };
};

const ServiceViewsControls = ({ accountsProps }: DatabaseControlsProps) => {
  const { selectedDashboard, selectedConnection } =
    useSelector(selectDashboard);

  const [accounts, setAccounts] = useState<string[]>([]);

  useEffect(() => {
    setAccounts([]);
    accountsProps.setSelectedAccounts([]);

    if (selectedDashboard) {
      getAccountData(
        selectedDashboard,
        selectedConnection!,
        setAccounts,
        accountsProps.setSelectedAccounts,
        accountsProps.setAccountsReqStatus
      );
    }
  }, []);

  return (
    <ControlComponent
      filters={[
        {
          title: getAccountsFilterLabelByProvider(
            selectedDashboard?.connectorProvider
          ),
          filter: (
            <DropdownCheckbox
              itemOptions={accounts.map((account) => ({
                title: account,
                value: account,
              }))}
              selectedItems={accountsProps.selectedAccounts}
              setSelectedItems={accountsProps.setSelectedAccounts}
              designVersion2
              size={INPUT_SIZE.SMALL}
              dataTestId="accounts-filter"
              loading={
                accountsProps.accountsReqStatus === REQUEST_STATUS.PROCESSING
              }
            />
          ),
          minimizedText:
            accountsProps.selectedAccounts
              .join(', ')
              .substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...',
          onClear: () => accountsProps.setSelectedAccounts([]),
        },
      ]}
    />
  );
};

export default ServiceViewsControls;
