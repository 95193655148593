import i18n from 'i18n';

export const UtilizationCoverageExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10 },
  {
    header: i18n.t('scorecard.committedUsageDiscount.month'),
    dataKey: 'month',
    key: 'month',
  },
  {
    header: i18n.t('scorecard.committedUsageDiscount.percentage'),
    dataKey: 'value',
    key: 'value',
  },
];
