import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { selectDashboard } from 'redux/dashboardSlice';
import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import {
  fetchRecommenderCategoryTypes,
  addIndexAndCommaSeparator,
} from 'pages/CostOptimizationInsightsPage/utils';
import {
  RECOMMENDATION_STATUS_LABELS,
  SAVINGS_TYPES,
  SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  CategoryRecommenderMappingType,
  MonthlyCostSavingsType,
} from 'pages/CostOptimizationInsightsPage/types';
import { CHART_TYPES } from 'constants/graphConfig';
import Loader from 'components/Loader';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { getChartData, getGCPProjectNumberIdMapping } from 'utils/services';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import {
  DATE_TIME_AM_PM,
  getMonthYearShortList,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import { getPreviousMonthAndYear } from 'utils/dashboardUtils';
import { ProjectNumberIdMappingType } from 'types/dashboard';
import { KeyValueTypes } from 'types/dataTypes';
import {
  RecommendationCategoriesLabels,
  RECOMMENDATION_CATEGORIES,
} from 'constants/recommendations';
import {
  fetchCategoryCostSavingsExcelExportColumns,
  getCategoryCostSavingsRequestBody,
  getCostSavingsTrendRequestBody,
  getSummaryRequestBody,
} from './utils';
import CostSavingsSummary from '../../CostSavingsSummary';
import SummarizedCostSavings from '../../SummarizedCostSavings';
import CostSavingsTrend from '../../CostSavingsTrend';
import ResourceDetails from '../../ResourceDetails';
import CategoryCostSavingsTable from './components/CategoryCostSavingsTable';
import { CategoryCostDetailsType } from './types';
import {
  fetchGCPResourceDetails,
  getGcpCategoryRecommenderMapping,
} from '../RecommendationTable/services';

const GCPStatusSummaryDashbaord = () => {
  const { t } = useTranslation();
  const { pdfDownloadMode, expandedGraphName, showExpandGraphModal } =
    useSelector(selectDashboard);
  const { selectedCostOptimizationInsightsConnection } = useSelector(
    costOptimizationInsights
  );

  const [categoryRecommenderMapping, setCategoryRecommenderMapping] =
    useState<CategoryRecommenderMappingType[]>();
  const [
    categoryRecommenderMappingRequestStatus,
    setCategoryRecommenderMappingRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  //Savings Summary States - Waste Management
  const [
    wasteManagementCostSavingSummaryData,
    setWasteManagementCostSavingSummaryData,
  ] = useState<number[]>([0, 0, 0]);
  const [
    wasteManagementSavingsSummaryRequestStatus,
    setWasteManagementSavingsSummaryRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  //Savings Summary States - Consumption Management
  const [
    consumptionManagementCostSavingSummaryData,
    setConsumptionManagementCostSavingSummaryData,
  ] = useState<number[]>([0, 0, 0]);
  const [
    consumptionManagementSavingsSummaryRequestStatus,
    setConsumptionManagementSavingsSummaryRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  //Savings Summary States - Purchase Tactics
  const [
    purchaseTacticsCostSavingSummaryData,
    setPurchaseTacticsCostSavingSummaryData,
  ] = useState<number[]>([0, 0, 0]);
  const [
    purchaseTacticsSavingsSummaryRequestStatus,
    setPurchaseTacticsSavingsSummaryRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  // Summarized Cost Savings states
  const [summarizedSavingsSummaryData, setSummarizedSavingsSummaryData] =
    useState<any[]>([]);
  const [potentialSavingsData, setPotentialSavingsData] = useState<number[]>(
    []
  );
  const [costSavingsInProgressData, setCostSavingsInProgressData] = useState<
    number[]
  >([]);
  const [costSavedData, setCostSavedData] = useState<number[]>([]);
  const [potentialSavingsRequestStatus, setPotentialSavingsRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [
    costSavingsInProgressRequestStatus,
    setCostSavingsInProgressRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [costSavedRequestStatus, setCostSavedRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  // Cost Savings Trend states
  const [costSavingsTrendData, setCostSavingsTrendData] = useState<
    MonthlyCostSavingsType[]
  >([]);
  const [costSavingsTrendRequestStatus, setCostSavingsTrendRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  // Category Cost Savings Table states
  const [categoryCostSavingsData, setCategoryCostSavingsData] = useState<
    CategoryCostDetailsType[]
  >([]);
  const [
    categoryCostSavingsRequestStatus,
    setCategoryCostSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [projectNumberIdMapping, setProjectNumberIdMapping] = useState<
    ProjectNumberIdMappingType[]
  >([]);

  // Resource Details states
  const [selectedRow, setSelectedRow] = useState<CategoryCostDetailsType>();
  const [selectedRowKey, setSelectedRowKey] = useState<React.Key>();
  const [resourceDetails, setResourceDetails] = useState<KeyValueTypes[]>([]);
  const [resourceDetailsRequestStatus, setResourceDetailsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [resourceDetailsErrorMessage, setResourceDetailsErrorMessage] =
    useState('');

  const [selectedSummaryGraph, setSelectedSummaryGraph] = useState<string>(
    RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
  );
  const [selectedSavingsType, setSelectedSavingsType] = useState<string>();
  const [selectedRecommendationType, setSelectedRecommendationType] =
    useState<string>();

  useEffect(() => {
    getAllRecommenderMapping();
  }, []);

  useEffect(() => {
    setProjectNumberIdMapping([]);
    setSelectedSummaryGraph(RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT);
    setSelectedSavingsType(undefined);
    setSelectedRecommendationType(undefined);
    if (selectedCostOptimizationInsightsConnection) {
      getAllProjectNumberIdMapping();
    }
  }, [selectedCostOptimizationInsightsConnection]);

  useEffect(() => {
    if (
      selectedCostOptimizationInsightsConnection &&
      categoryRecommenderMapping
    ) {
      getSummaryGraphData();
    }
  }, [selectedCostOptimizationInsightsConnection, categoryRecommenderMapping]);

  useEffect(() => {
    if (
      selectedCostOptimizationInsightsConnection &&
      categoryRecommenderMapping
    ) {
      getSummarizedCostSummary();
    }
  }, [
    selectedCostOptimizationInsightsConnection,
    categoryRecommenderMapping,
    selectedSummaryGraph,
  ]);

  useEffect(() => {
    getSummarizedCostSummaryDataset();
  }, [
    potentialSavingsData,
    costSavingsInProgressData,
    costSavedData,
    selectedSavingsType,
  ]);

  useEffect(() => {
    setCostSavingsTrendData([]);
    setCategoryCostSavingsData([]);
    setSelectedRow(undefined);
    if (selectedRecommendationType) {
      getCostSavingsTrendData(
        [selectedRecommendationType],
        SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
          (item) => item.type === selectedSavingsType
        )?.status ?? []
      );
      getCategoryCostSavingsData(
        [selectedRecommendationType],
        SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
          (item) => item.type === selectedSavingsType
        )?.status ?? []
      );
    }
  }, [selectedRecommendationType]);

  useEffect(() => {
    setResourceDetails([]);
    if (selectedRow) {
      fetchResourceDetails();
    }
  }, [selectedRow]);

  useEffect(() => {
    setSelectedRecommendationType(undefined);
  }, [selectedSummaryGraph, selectedSavingsType]);

  /**
   * @function getAllRecommenderMapping
   * @description Function to fetch all the available filters for connection.
   */
  const getAllRecommenderMapping = () => {
    setCategoryRecommenderMappingRequestStatus(REQUEST_STATUS.PROCESSING);

    getGcpCategoryRecommenderMapping()
      .then((res: any) => {
        if (res?.status === 200) {
          setCategoryRecommenderMapping(res.data.responseData);
          setCategoryRecommenderMappingRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setCategoryRecommenderMappingRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) =>
        onApiCallError(e, false, setCategoryRecommenderMappingRequestStatus)
      );
  };

  /**
   * @function getRecommendersByCategory
   * @description Function to get the recommenders for the current selected category
   * @param category category for ehich the recommenders are filtered
   * @returns List of recommenders
   */
  const getRecommendersByCategory = (category: string) =>
    categoryRecommenderMapping?.find((value) => value.category === category)
      ?.recommenders!;

  /**
   * @function getSummaryGraphData
   * @description Function to fetch and set the summary graph data.
   */
  const getSummaryGraphData = () => {
    getCostSummaryData(
      getRecommendersByCategory(RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT),
      setWasteManagementCostSavingSummaryData,
      setWasteManagementSavingsSummaryRequestStatus
    );
    getCostSummaryData(
      getRecommendersByCategory(
        RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT
      ),
      setConsumptionManagementCostSavingSummaryData,
      setConsumptionManagementSavingsSummaryRequestStatus
    );
    getCostSummaryData(
      getRecommendersByCategory(RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS),
      setPurchaseTacticsCostSavingSummaryData,
      setPurchaseTacticsSavingsSummaryRequestStatus
    );
  };

  /**
   * @function getSummarizedCostSummary
   * @description Function to fetch and set the cost summary data for each category.
   */
  const getSummarizedCostSummary = () => {
    const categories = fetchRecommenderCategoryTypes(selectedSummaryGraph).map(
      (item) => item.key
    );

    getSummarizedCostSummaryData(
      categories,
      SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
        (item) => item.type === SAVINGS_TYPES.POTENTIAL_SAVINGS
      )?.status ?? [],
      setPotentialSavingsData,
      setPotentialSavingsRequestStatus
    );
    getSummarizedCostSummaryData(
      categories,
      SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
        (item) => item.type === SAVINGS_TYPES.COST_SAVINGS_IN_PROGRESS
      )?.status ?? [],
      setCostSavingsInProgressData,
      setCostSavingsInProgressRequestStatus
    );
    getSummarizedCostSummaryData(
      categories,
      SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
        (item) => item.type === SAVINGS_TYPES.COST_SAVED
      )?.status ?? [],
      setCostSavedData,
      setCostSavedRequestStatus
    );
  };

  /**
   * @function getCostSummaryData
   * @description Function to fetch and set the cost summary data by categories.
   * @param categories List of categories for which the cost data is fetched
   * @param setData callback function for setting the data
   * @param setRequestStatus Callback function for setting the request status
   */
  const getCostSummaryData = (
    categories: string[],
    setData: (val: number[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.forEach((type) => {
      requests.push(
        getChartData(
          getSummaryRequestBody(categories, type.status, selectedSavingsType),
          selectedCostOptimizationInsightsConnection?.connectorId
        )
      );
    });

    axios
      .all(requests)
      .then((res) => {
        let costData: number[] = [];
        res.forEach((_resource, index) => {
          costData.push(res[index]?.data[0]?.totalCostSaved || 0);
        });
        setData(costData);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
        setData([0, 0, 0]);
      });
  };

  /**
   * @function getSummarizedCostSummaryData
   * @description Function to fetch and set the cost summary data for each category and status given.
   * @param categories Recommendations categories for which the data is fetched
   * @param status recommendation status for which the data is fetched
   * @param setData Call back function for setting the data fetched
   * @param setRequestStatus Callback function for setting the request status
   */
  const getSummarizedCostSummaryData = (
    categories: string[],
    status: string[],
    setData: (val: number[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    categories.forEach((category) => {
      requests.push(
        getChartData(
          getSummaryRequestBody([category], status, selectedSavingsType),
          selectedCostOptimizationInsightsConnection?.connectorId
        )
      );
    });

    axios
      .all(requests)
      .then((res) => {
        let summarizedCostData: number[] = [];
        res.forEach((_resource, index) => {
          summarizedCostData.push(res[index]?.data[0]?.totalCostSaved || 0);
        });
        setData(summarizedCostData);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
        setData([0, 0, 0]);
      });
  };

  /**
   * @function getCostSavingsTrendData
   * @description Function to fetch and set the cost trend data for the given categories and status.
   * @param categories Recommendations categories for which the data is fetched
   * @param status recommendation status for which the data is fetched
   */
  const getCostSavingsTrendData = (categories: string[], status: string[]) => {
    setCostSavingsTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    getChartData(
      getCostSavingsTrendRequestBody(categories, status),
      selectedCostOptimizationInsightsConnection?.connectorId
    )
      .then((res: any) => {
        let data: MonthlyCostSavingsType[] = res?.data || [];
        const labels = getMonthYearShortList(
          getPreviousMonthAndYear(11),
          getPreviousMonthAndYear(0)
        );
        setCostSavingsTrendData(
          labels.map((month) => ({
            month: month,
            totalCost:
              data.find(
                (item) =>
                  month ===
                  moment(item.month, YEAR_MONTH_WITHOUT_ZERO).format(
                    MONTH_YEAR_SHORT
                  )
              )?.totalCost ?? 0,
          }))
        );
        setCostSavingsTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostSavingsTrendRequestStatus);
        setCostSavingsTrendData([]);
      });
  };

  /**
   * @function getAllProjectNumberIdMapping
   * @description Function to fetch project Number to Id mapping.
   */
  const getAllProjectNumberIdMapping = () => {
    getGCPProjectNumberIdMapping(
      selectedCostOptimizationInsightsConnection?.connectorId ?? ''
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setProjectNumberIdMapping(res?.data?.responseData ?? []);
          return;
        }
        setProjectNumberIdMapping([]);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setProjectNumberIdMapping([]);
      });
  };

  /**
   * @function getCategoryCostSavingsData
   * @description Function to fetch and set the resource list data for the categories selected.
   * @param categories Recommendations categories for which the data is fetched
   * @param status recommendation status for which the data is fetched
   */
  const getCategoryCostSavingsData = (
    categories: string[],
    status: string[]
  ) => {
    setCategoryCostSavingsRequestStatus(REQUEST_STATUS.PROCESSING);

    getChartData(
      getCategoryCostSavingsRequestBody(
        categories,
        status,
        selectedSavingsType
      ),
      selectedCostOptimizationInsightsConnection?.connectorId
    )
      .then((res: any) => {
        const data: any[] = res?.data || [];
        const modifiedData = data.map((item) => ({
          ...item,
          resourceId: item?.targetResource?.substring(
            item?.targetResource?.lastIndexOf('/') + 1,
            item?.targetResource?.lastIndexOf('"')
          ),
          projectName:
            projectNumberIdMapping.find(
              (eachId) =>
                eachId.projectNumber.toString() ===
                item?.projectNumber.toString()
            )?.projectId ?? item?.projectNumber,
          state:
            RECOMMENDATION_STATUS_LABELS.find(
              (state) => state.key === item?.state
            )?.label ?? item?.state,
        }));
        setCategoryCostSavingsData(modifiedData);
        setSelectedRowKey(0);
        setSelectedRow(modifiedData[0]);
        setCategoryCostSavingsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCategoryCostSavingsRequestStatus);
        setCategoryCostSavingsData([]);
        setSelectedRowKey(undefined);
        setSelectedRow(undefined);
      });
  };

  /**
   * @function fetchResourceDetails
   * @description Function to fetch and set the resource details for the resource selected
   */
  const fetchResourceDetails = () => {
    setResourceDetailsRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedCostOptimizationInsightsConnection?.connectorId,
      resourceId: selectedRow?.resourceId,
      recommendationId: selectedRow?.recommendationName,
      targetResource: selectedRow?.targetResource.substring(
        2,
        selectedRow?.targetResource.lastIndexOf('"]')
      ),
    };

    fetchGCPResourceDetails(requestBody)
      .then((res: any) => {
        const data = res?.data?.responseData;
        const basicData = [
          {
            key: t(
              'costOptimizationInsight.statusDashboard.costSavingsTable.name'
            ),
            value: data?.name,
          },
          {
            key: t(
              'costOptimizationInsight.statusDashboard.costSavingsTable.description'
            ),
            value: data?.description,
          },
          {
            key: t(
              'costOptimizationInsight.statusDashboard.costSavingsTable.creationTime'
            ),
            value: moment(data?.createTime).format(DATE_TIME_AM_PM),
          },
        ];
        const detailedData = Object.entries(data?.resourceDetails || {}).map(
          (item) => ({ key: item[0], value: item[1] })
        );
        setResourceDetails([...basicData, ...detailedData]);
        setResourceDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setResourceDetailsRequestStatus);
        setResourceDetails([]);
        setResourceDetailsErrorMessage(e.response.data.message);
      });
  };

  /**
   * @function getSummarizedCostSummaryDataset
   * @description Function to set the cost summary dataset for each category.
   */
  const getSummarizedCostSummaryDataset = () => {
    const categories = fetchRecommenderCategoryTypes(selectedSummaryGraph).map(
      (item) => item.label
    );

    const allPotentialSavingsData = potentialSavingsData.map((item, index) => ({
      label: t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.potentialSavings'
      ),
      category: categories[index],
      data: item,
    }));
    const allCostSavingsInProgressData = costSavingsInProgressData.map(
      (item, index) => ({
        label: t(
          'costOptimizationInsight.statusDashboard.costSavingsTable.costSavingInProgress'
        ),
        category: categories[index],
        data: item,
      })
    );
    const allCostSavedData = costSavedData.map((item, index) => ({
      label: t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.costSaved'
      ),
      category: categories[index],
      data: item,
    }));

    let graphData: any[] = [];

    if (selectedSavingsType === undefined) {
      graphData = [
        ...allPotentialSavingsData,
        ...allCostSavingsInProgressData,
        ...allCostSavedData,
      ];
    }

    if (selectedSavingsType === SAVINGS_TYPES.POTENTIAL_SAVINGS) {
      graphData = allPotentialSavingsData;
    }

    if (selectedSavingsType === SAVINGS_TYPES.COST_SAVINGS_IN_PROGRESS) {
      graphData = allCostSavingsInProgressData;
    }

    if (selectedSavingsType === SAVINGS_TYPES.COST_SAVED) {
      graphData = allCostSavedData;
    }

    setSummarizedSavingsSummaryData(graphData);
  };

  /**
   * @function getPdfMetaData
   * @description Function to return the pdf metadata
   * @returns Object containing the metadata for the pdf export
   */
  const getPdfMetaData = () => {
    return {
      viewName: t('dashNav.costSavingsStatusSummary'),
      fileName: selectedCostOptimizationInsightsConnection?.name ?? '',
      heading: selectedCostOptimizationInsightsConnection?.name ?? '',
      subtitle1:
        selectedCostOptimizationInsightsConnection?.dataSourceType ?? '',
      subtitle2: '',
      provider: selectedCostOptimizationInsightsConnection?.provider ?? '',
    };
  };

  /**
   * @function getGraphComponent
   * @description Function to return the graph component by graph name
   * @param graphName Name of the graph
   * @param pdfView Optional param for stating whether the graph is for pdf view or not
   * @returns React component of the graph
   */
  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT:
        return (
          <CostSavingsSummary
            graphHeading={t('recommendationCategoryLabels.wasteManagement')}
            graphName={RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT}
            pdfView={pdfView}
            savingsSummaryData={wasteManagementCostSavingSummaryData}
            requestStatus={wasteManagementSavingsSummaryRequestStatus}
            selectedSummaryGraph={selectedSummaryGraph}
            setSelectedSummaryGraph={setSelectedSummaryGraph}
            selectedSavingsType={selectedSavingsType}
            setSelectedSavingsType={setSelectedSavingsType}
          />
        );

      case RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT:
        return (
          <CostSavingsSummary
            graphHeading={t(
              'recommendationCategoryLabels.consumptionManagement'
            )}
            graphName={RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT}
            pdfView={pdfView}
            savingsSummaryData={consumptionManagementCostSavingSummaryData}
            requestStatus={consumptionManagementSavingsSummaryRequestStatus}
            selectedSummaryGraph={selectedSummaryGraph}
            setSelectedSummaryGraph={setSelectedSummaryGraph}
            selectedSavingsType={selectedSavingsType}
            setSelectedSavingsType={setSelectedSavingsType}
          />
        );

      case RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS:
        return (
          <CostSavingsSummary
            graphHeading={t('recommendationCategoryLabels.purchaseTactics')}
            graphName={RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS}
            pdfView={pdfView}
            savingsSummaryData={purchaseTacticsCostSavingSummaryData}
            requestStatus={purchaseTacticsSavingsSummaryRequestStatus}
            selectedSummaryGraph={selectedSummaryGraph}
            setSelectedSummaryGraph={setSelectedSummaryGraph}
            selectedSavingsType={selectedSavingsType}
            setSelectedSavingsType={setSelectedSavingsType}
          />
        );

      case 'summarized-cost-savings':
        return getSummarizedCostGraph(pdfView);

      case 'category-cost-savings-table':
        return (
          <CategoryCostSavingsTable
            graphHeading={
              fetchRecommenderCategoryTypes(selectedSummaryGraph).find(
                (item: any) => item.key === selectedRecommendationType
              )?.label ?? ''
            }
            graphName="category-cost-savings-table"
            pdfView={pdfView}
            categoryCostSavingsData={categoryCostSavingsData}
            requestStatus={categoryCostSavingsRequestStatus}
            selectedRecommendationType={selectedRecommendationType}
            selectedSavingsType={selectedSavingsType}
            selectedRowKey={selectedRowKey}
            setSelectedRowKey={setSelectedRowKey}
            setSelectedRow={setSelectedRow}
          />
        );

      case 'resource-details':
        return (
          <ResourceDetails
            graphHeading={
              selectedRecommendationType
                ? t(
                    'costOptimizationInsight.statusDashboard.costSavingsTable.resourceId'
                  )
                : ''
            }
            graphName="resource-details"
            resourceDetails={resourceDetails}
            pdfView={pdfView}
            requestStatus={[
              categoryCostSavingsRequestStatus,
              resourceDetailsRequestStatus,
            ]}
            selectedRecommendationType={selectedRecommendationType}
            resourceId={selectedRow?.resourceId}
            errorMessage={resourceDetailsErrorMessage}
          />
        );
    }
  };

  /**
   * @function getSummarizedCostGraph
   * @description Function to return the summarized category graph component
   * @param pdfView Optional param for stating whether the graph is for pdf view or not
   * @returns React component of the graph
   */
  const getSummarizedCostGraph = (pdfView: boolean) => {
    if (selectedRecommendationType === undefined) {
      return (
        <SummarizedCostSavings
          graphHeading={
            (SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
              (item) => item.type === selectedSavingsType
            )?.label ?? t('graphHeadings.costSavings')) +
            ' - ' +
            RecommendationCategoriesLabels.find(
              (item) => item.key === selectedSummaryGraph
            )?.label
          }
          graphName="summarized-cost-savings"
          data={summarizedSavingsSummaryData}
          pdfView={pdfView}
          requestStatus={[
            potentialSavingsRequestStatus,
            costSavingsInProgressRequestStatus,
            costSavedRequestStatus,
          ]}
          selectedSavingsType={selectedSavingsType}
          setSelectedSavingsType={setSelectedSavingsType}
          selectedRecommendationType={selectedRecommendationType}
          setSelectedRecommendationType={(typeLabel) =>
            setSelectedRecommendationType(
              fetchRecommenderCategoryTypes(selectedSummaryGraph).find(
                (item) => item.label === typeLabel
              )?.key
            )
          }
        />
      );
    }

    return (
      <CostSavingsTrend
        graphName="summarized-cost-savings"
        data={costSavingsTrendData}
        pdfView={pdfView}
        requestStatus={[costSavingsTrendRequestStatus]}
        selectedSavingsType={selectedSavingsType}
        selectedRecommendationType={
          fetchRecommenderCategoryTypes(selectedSummaryGraph).find(
            (item) => item.key === selectedRecommendationType
          )?.label
        }
        setSelectedRecommendationType={setSelectedRecommendationType}
      />
    );
  };

  if (categoryRecommenderMappingRequestStatus === REQUEST_STATUS.PROCESSING) {
    return <Loader />;
  }

  return (
    <div className="status-summary-container flex flex-column flex-gap-16">
      <div className="full-width flex flex-gap-24">
        {getGraphComponent(RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT)}
        {getGraphComponent(RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT)}
        {getGraphComponent(RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS)}
      </div>
      {getGraphComponent('summarized-cost-savings')}
      <div className="flex flex-gap-16 flex-space-between">
        <div className="width-60">
          {getGraphComponent('category-cost-savings-table')}
        </div>
        <div className="width-40">{getGraphComponent('resource-details')}</div>
      </div>
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData()}
          pdfContent={[
            {
              element: getGraphComponent(
                RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
              isTableView: false,
            },
            {
              element: getGraphComponent(
                RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
              isTableView: false,
            },
            {
              element: getGraphComponent(
                RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
              isTableView: false,
            },
            {
              element: getGraphComponent('summarized-cost-savings', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'summarized-cost-savings',
              isTableView: false,
            },
            {
              contentType: CHART_TYPES.TABLE,
              graphName: 'category-cost-savings-table',
              column:
                fetchCategoryCostSavingsExcelExportColumns(selectedSavingsType),
              element: getGraphComponent('category-cost-savings-table', true),
              body: addIndexAndCommaSeparator(categoryCostSavingsData),
              tableName:
                fetchRecommenderCategoryTypes(selectedSummaryGraph).find(
                  (item: any) => item.key === selectedRecommendationType
                )?.label ?? '',
            },
          ]}
          pdfCardData={{
            costOptmization: {
              cards:
                selectedRow === undefined ||
                evaluateRequestArray([
                  categoryCostSavingsRequestStatus,
                  resourceDetailsRequestStatus,
                ]) === REQUEST_STATUS.ERROR
                  ? undefined
                  : getGraphComponent('resource-details', true),
              insertAtStart: false,
            },
          }}
        />
      )}
    </div>
  );
};

export default GCPStatusSummaryDashbaord;
