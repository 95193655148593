import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import ColumnChart from 'components/ColumnChart';
import { selectCostAllocationDashboard } from 'redux/costAllocationDashboardSlice';
import { CostByCostCenterCodeType } from 'pages/CostAllocationDashboardPage/types';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getAllocationByCostCenterCodeExcelData } from '../../utils/exportToExcel';

type AllocationByCSCProps = {
  costByCostCenterCode: CostByCostCenterCodeType[];
  filteredCostByCostCenterCode: CostByCostCenterCodeType[];
  months: string[];
  selectedMonths: string[];
  setSelectedMonths: (val: string[]) => void;
  businessUnits: string[];
  selectedBusinessUnits: string[];
  setSelectedBusinessUnits: (val: string[]) => void;
  selectedCostCenters: string[];
  setSelectedCostCenters: (val: string[]) => void;
  requestStatus: string[];
  isAllocationByCSCTableView: boolean;
  setIsAllocationByCSCTableView: (value: boolean) => void;
  pdfView: boolean;
};

const AllocationByCSC = ({
  costByCostCenterCode,
  filteredCostByCostCenterCode,
  months,
  selectedMonths,
  setSelectedMonths,
  businessUnits,
  selectedBusinessUnits,
  setSelectedBusinessUnits,
  selectedCostCenters,
  setSelectedCostCenters,
  requestStatus,
  isAllocationByCSCTableView,
  setIsAllocationByCSCTableView,
  pdfView,
}: AllocationByCSCProps) => {
  const { t } = useTranslation();
  const { selectedCostAllocationDashboard } = useSelector(
    selectCostAllocationDashboard
  );
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('costAllocationDashboardLabels.allocationByCSC.costCenterCode'),
      dataIndex: 'name',
      key: 'costCenterCode',
      width: 200,
    },
    {
      title: t('costAllocationDashboardLabels.allocationByCSC.cost', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'cost',
      key: 'cost',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text || 0),
    },
  ];

  const getComponent = () => {
    return isAllocationByCSCTableView ? (
      <div className="tabular-view">
        <Table
          pagination={false}
          dataSource={filteredCostByCostCenterCode.map((data, index) => ({
            ...data,
            key: index,
          }))}
          columns={columns}
          scroll={{ y: 200 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={filteredCostByCostCenterCode}
        xField="name"
        yField="cost"
        groupingField="name"
        xTitle={t(
          'costAllocationDashboardLabels.allocationByCSC.costCenterCode'
        )}
        yTitle={t('costAllocationDashboardLabels.allocationByCSC.cost', {
          currencySymbol: currencySymbol,
        })}
        prefixSymbol={currencySymbol}
      />
    );
  };

  const filters = (
    <div className="filters flex flex-align-items-center">
      <GraphFilterDropdown
        allData={months.map((item) => ({
          month: item,
        }))}
        selectedData={selectedMonths.map((item) => ({
          month: item,
        }))}
        setSelectedData={(selectedData: { month: string }[]) =>
          setSelectedMonths(selectedData.map((item) => item.month))
        }
        setLabels={setSelectedMonths}
        valueSuffix={t(
          'costAllocationDashboardLabels.allocationByBusinessUnit.months'
        )}
        fieldName={DROPDOWN_VALUE_FIELDS.MONTH}
        includeAdditionalOptions={false}
        additionalClassNames="width-25"
        designVersion2
      />
      <GraphFilterDropdown
        allData={businessUnits.map((item) => ({ businessUnit: item }))}
        selectedData={selectedBusinessUnits.map((item) => ({
          businessUnit: item,
        }))}
        setSelectedData={(selectedData: any[]) =>
          setSelectedBusinessUnits(
            selectedData.map((item) => item.businessUnit)
          )
        }
        setLabels={setSelectedBusinessUnits}
        valueSuffix={t(
          'costAllocationDashboardLabels.allocationByCSC.businessUnits'
        )}
        fieldName={DROPDOWN_VALUE_FIELDS.BUSINESS_UNIT}
        includeAdditionalOptions={false}
        additionalClassNames="width-30"
        designVersion2
      />
      <GraphFilterDropdown
        allData={costByCostCenterCode.map((item) => ({
          costCenter: item.name,
        }))}
        selectedData={selectedCostCenters.map((item) => ({
          costCenter: item,
        }))}
        setSelectedData={(selectedData: any[]) =>
          setSelectedCostCenters(selectedData.map((item) => item.costCenter))
        }
        setLabels={setSelectedCostCenters}
        valueSuffix={t(
          'costAllocationDashboardLabels.allocationByCSC.costCenters'
        )}
        fieldName={DROPDOWN_VALUE_FIELDS.COST_CENTER}
        includeAdditionalOptions={false}
        additionalClassNames="width-30"
        designVersion2
      />
    </div>
  );

  return (
    <div className="allocation-by-csc graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.costCenterCode')}
        graphName="allocation-by-csc"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isAllocationByCSCTableView}
        setIsTableView={setIsAllocationByCSCTableView}
        filters={filters}
        excelData={getAllocationByCostCenterCodeExcelData(
          selectedCostAllocationDashboard?.connectorName ?? '',
          filteredCostByCostCenterCode,
          selectedMonths,
          selectedBusinessUnits,
          selectedCostCenters
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default AllocationByCSC;
