import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';

import {
  selectPurchase,
  setAwsRdsRisCartData,
  setAwsRegions,
  setRiProductDescriptions,
} from 'redux/purchaseSlice';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import { TERMS_LIST } from 'pages/PurchasePage/constants';
import { PurchaseAwsRdsRisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import { AWS_RDS_RI_DEFAULT_VALUES } from 'pages/PurchasePage/components/ReservedInstances/constants';
import {
  getAwsRdsRiInstanceClasses,
  getAwsRdsRiIsMultiAz,
  getAwsRdsRiOfferingDetails,
  getAwsRdsRiPaymentOptions,
  getAwsRdsRiProductDescriptions,
  getAwsRdsRiTerm,
} from 'pages/PurchasePage/components/ReservedInstances/services';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import RadioGroup from 'components/RadioGroup';
import {
  convertYearToSeconds,
  getAverageMonthlyRecurringCost,
  getSubTotalDue,
} from 'pages/PurchasePage/components/ReservedInstances/utils';
import { fetchAwsRegions } from 'pages/PurchasePage/services';
import { isEmptyField, isNumber, validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { YES_NO_OPTIONS } from 'constants/utilityConstants';

import './index.scss';

type BuyRdsRiFormModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  editData?: PurchaseAwsRdsRisType;
  indexPosition?: number;
};

const BuyRdsRiFormModal = ({
  show,
  setShow,
  editData,
  indexPosition,
}: BuyRdsRiFormModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedConnection,
    awsRdsRiCartData,
    riProductDescriptions,
    awsRegions,
  } = useSelector(selectPurchase);

  const [rdsRiFormData, setRdsRiFormData] = useState<PurchaseAwsRdsRisType>(
    AWS_RDS_RI_DEFAULT_VALUES
  );
  const [dbInstanceClasses, setDbInstanceClasses] = useState<string[]>([]);
  const [isMultiAz, setIsMultiAz] = useState<boolean>();
  const [terms, setTerms] = useState<string>();
  const [paymentOptions, setPaymentOptions] = useState<string[]>();
  const [
    fetchProductDescriptionsReqStatus,
    setFetchProductDescriptionsReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [fetchRegionReqStatus, setFetchRegionReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [
    fetchInstanceClassesRequestStatus,
    setFetchInstanceClassesRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [fetchIsMultiAzRequestStatus, setFetchIsMultiAzRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [fetchTermsReqStatus, setFetchTermsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchPaymentOptionsReqStatus, setFetchPaymentOptionsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [productDescriptionValidation, setProductDescriptionValidation] =
    useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [dbInstanceClassValidation, setDbInstanceClassValidation] =
    useState('');
  const [paymentOptionValidation, setPaymentOptionValidation] = useState('');
  const [quantityValidation, setQuantityValidation] = useState('');
  const [offeringDetailsRequestStatus, setOfferingDetailsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [offeringDetailsValidation, setOfferingDetailsValidation] =
    useState('');
  const [editLoaded, setEditLoaded] = useState(false);

  useEffect(() => {
    if (editData) {
      setRdsRiFormData(editData);
    }

    if (riProductDescriptions.length === 0) {
      fetchProductDescriptions();
    }

    if (awsRegions.length === 0) {
      getAwsRegions();
    }
  }, []);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setDbInstanceClasses([]);
    if (
      validateProductDescription(rdsRiFormData.productDescription, false) &&
      validateRegion(rdsRiFormData.region, false)
    ) {
      fetchInstanceClasses(source);
    }

    return () => {
      source.cancel();
    };
  }, [rdsRiFormData.productDescription, rdsRiFormData.region]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setIsMultiAz(undefined);
    if (validateDbInstanceClass(rdsRiFormData.dbInstanceClass, false)) {
      fetchIsMultiAz(source);
    }

    return () => {
      source.cancel();
    };
  }, [rdsRiFormData.dbInstanceClass]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setTerms(undefined);
    if (isMultiAz !== undefined) {
      fetchTerms(source);
    }

    return () => {
      source.cancel();
    };
  }, [isMultiAz, rdsRiFormData.isMultiAzDbInstance]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setPaymentOptions(undefined);
    if (terms !== undefined) {
      fetchPaymentOptions(source);
    }

    return () => {
      source.cancel();
    };
  }, [terms, rdsRiFormData.term]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (editLoaded) {
      setRdsRiFormData({
        ...rdsRiFormData,
        hourlyUsageCharge: 0,
        oneTimePayment: 0,
        offeringId: '',
      });
      setOfferingDetailsValidation('');
    } else {
      setEditLoaded(true);
    }

    if (validateAllFields(false)) {
      fetchOfferingDetails(source);
    }

    return () => {
      source.cancel();
    };
  }, [
    rdsRiFormData.productDescription,
    rdsRiFormData.dbInstanceClass,
    rdsRiFormData.term,
    rdsRiFormData.paymentOption,
    rdsRiFormData.quantity,
    rdsRiFormData.isMultiAzDbInstance,
    isMultiAz,
    terms,
    paymentOptions,
  ]);

  /**
   * @function fetchProductDescriptions
   * @description Function to fetch product descriptions data
   */
  const fetchProductDescriptions = () => {
    setFetchProductDescriptionsReqStatus(REQUEST_STATUS.PROCESSING);

    getAwsRdsRiProductDescriptions()
      .then((res: any) => {
        dispatch(setRiProductDescriptions(res.data.responseData || []));
        setFetchProductDescriptionsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchProductDescriptionsReqStatus);
      });
  };

  /**
   * @function fetchRegions
   * @description Function to fetch regions data
   */
  const getAwsRegions = () => {
    setFetchRegionReqStatus(REQUEST_STATUS.PROCESSING);

    fetchAwsRegions({ connectorId: selectedConnection?.connectorId })
      .then((res: any) => {
        dispatch(setAwsRegions(res.data.responseData));
        setFetchRegionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchRegionReqStatus);
      });
  };

  /**
   * @function fetchInstanceClasses
   * @description Function to fetch the instance classes
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchInstanceClasses = (cancelTokenSource: CancelTokenSource) => {
    setFetchInstanceClassesRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      productDescription: rdsRiFormData.productDescription,
      region: rdsRiFormData?.region,
    };

    getAwsRdsRiInstanceClasses(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        setDbInstanceClasses(res.data.responseData || []);
        setFetchInstanceClassesRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchInstanceClassesRequestStatus);
      });
  };

  /**
   * @function fetchIsMultiAz
   * @description Function to fetch if Multi AZ or not
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchIsMultiAz = (cancelTokenSource: CancelTokenSource) => {
    setFetchIsMultiAzRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      productDescription: rdsRiFormData.productDescription,
      region: rdsRiFormData?.region,
      dbInstanceClass: rdsRiFormData.dbInstanceClass,
    };

    getAwsRdsRiIsMultiAz(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        const multiAZ = res.data.responseData;
        setIsMultiAz(multiAZ);
        setRdsRiFormData({ ...rdsRiFormData, isMultiAzDbInstance: multiAZ });
        setFetchIsMultiAzRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchIsMultiAzRequestStatus);
      });
  };

  /**
   * @function fetchTerms
   * @description Function to fetch the RDS terms
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchTerms = (cancelTokenSource: CancelTokenSource) => {
    setFetchTermsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      productDescription: rdsRiFormData.productDescription,
      region: rdsRiFormData?.region,
      dbInstanceClass: rdsRiFormData.dbInstanceClass,
      multiAZ: rdsRiFormData.isMultiAzDbInstance,
    };

    getAwsRdsRiTerm(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        const term = res.data.responseData;
        setTerms(term);
        setRdsRiFormData({ ...rdsRiFormData, term: term === 'THREE' ? 3 : 1 });
        setFetchTermsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchTermsReqStatus);
      });
  };

  /**
   * @function fetchPaymentOptions
   * @description Function to fetch the RDS Payment Options
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchPaymentOptions = (cancelTokenSource: CancelTokenSource) => {
    setFetchPaymentOptionsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      productDescription: rdsRiFormData.productDescription,
      region: rdsRiFormData?.region,
      dbInstanceClass: rdsRiFormData.dbInstanceClass,
      multiAZ: rdsRiFormData.isMultiAzDbInstance,
      duration: convertYearToSeconds(rdsRiFormData.term),
    };

    getAwsRdsRiPaymentOptions(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        const payments = res.data.responseData;
        if (!payments.includes(rdsRiFormData.paymentOption)) {
          setRdsRiFormData({ ...rdsRiFormData, paymentOption: '' });
        }
        setPaymentOptions(payments);
        setFetchPaymentOptionsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchPaymentOptionsReqStatus);
      });
  };

  /**
   * @function fetchOfferingDetails
   * @description Function to fetch the offering details
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchOfferingDetails = (cancelTokenSource: CancelTokenSource) => {
    setOfferingDetailsRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      productDescription: rdsRiFormData.productDescription,
      dbInstanceClass: rdsRiFormData.dbInstanceClass,
      duration: convertYearToSeconds(rdsRiFormData.term),
      offeringType: rdsRiFormData.paymentOption,
      connectorId: selectedConnection?.connectorId,
      region: rdsRiFormData.region,
      quantity: Number(rdsRiFormData.quantity),
      multiAZ: rdsRiFormData.isMultiAzDbInstance,
    };

    getAwsRdsRiOfferingDetails(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          if (data.offeringId) {
            setRdsRiFormData({
              ...rdsRiFormData,
              hourlyUsageCharge: data?.hourlyUsageCharge || 0,
              oneTimePayment: data?.oneTimePayment || 0,
              offeringId: data?.offeringId || '',
            });
            setOfferingDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
            return;
          }
          setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
          setOfferingDetailsValidation(
            t('purchasePage.reservedInstancesLabels.offeringIdNullError')
          );
          return;
        }
        setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
        setOfferingDetailsValidation(
          t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      })
      .catch((e) => {
        onApiCallError(e, false, setOfferingDetailsRequestStatus);
        if (!axios.isCancel(e)) {
          setOfferingDetailsValidation(
            e?.response?.data?.message ||
              t('purchasePage.reservedInstancesLabels.offeringDetailsError')
          );
        }
      });
  };

  /**
   * @function validateProductDescription
   * @description Function to validate the product description field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateProductDescription = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.reservedInstancesLabels.productDescription`),
      setProductDescriptionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateRegion
   * @description Function to validate the region field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateRegion = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.region`),
      setRegionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateDbInstanceClass
   * @description Function to validate the Db Instance Class field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateDbInstanceClass = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.reservedInstancesLabels.dbInstanceClass`),
      setDbInstanceClassValidation,
      addErrorMessage
    );
  };

  /**
   * @function validatePaymentOption
   * @description Function to validate the payment option field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validatePaymentOption = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.paymentOptions`),
      setPaymentOptionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateQuantityField
   * @description Function to validate the quantity field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityField = (
    value: number | string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (
      !validateEmptyField(
        value,
        t(`purchasePage.reservedInstancesLabels.quantity`),
        setQuantityValidation,
        addErrorMessage
      )
    ) {
      return (
        isNumber(
          value?.toString(),
          false,
          t(`purchasePage.reservedInstancesLabels.quantity`),
          addErrorMessage ? setQuantityValidation : undefined
        ) && validateQuantityRangeLimit(value, addErrorMessage)
      );
    }

    return false;
  };

  /**
   * @function validateQuantityRangeLimit
   * @description Function to validate the quantity limit and range
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityRangeLimit = (
    value: string | number | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (!value || !isNumber(value.toString(), false)) {
      return false;
    }

    if (Number(value) <= 0) {
      addErrorMessage &&
        setQuantityValidation(
          t('purchasePage.reservedInstancesLabels.quantityLimitError')
        );
      return false;
    }

    addErrorMessage && setQuantityValidation('');

    return true;
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAllFields = (addErrorMessage: boolean = true) => {
    let validation = true;

    if (
      !validateProductDescription(
        rdsRiFormData?.productDescription,
        addErrorMessage
      )
    ) {
      validation = false;
    }

    if (!validateRegion(rdsRiFormData.region, addErrorMessage)) {
      validation = false;
    }

    if (
      !validateDbInstanceClass(rdsRiFormData?.dbInstanceClass, addErrorMessage)
    ) {
      validation = false;
    }

    if (!validatePaymentOption(rdsRiFormData?.paymentOption, addErrorMessage)) {
      validation = false;
    }

    if (!validateQuantityField(rdsRiFormData?.quantity, addErrorMessage)) {
      validation = false;
    }

    if (
      addErrorMessage &&
      validation &&
      (isEmptyField(rdsRiFormData?.hourlyUsageCharge) ||
        isEmptyField(rdsRiFormData?.offeringId))
    ) {
      setOfferingDetailsValidation(
        t('purchasePage.reservedInstancesLabels.missingOfferingDetails')
      );
      validation = false;
    }

    if (isMultiAz === undefined || !terms || !paymentOptions) {
      validation = false;
    }

    return validation;
  };

  /**
   * @function onClickBuy
   * @description Callback function for buy button click
   */
  const onClickBuy = () => {
    if (!validateAllFields()) {
      return;
    }

    if (editData !== undefined && indexPosition !== undefined) {
      const cartData = [...awsRdsRiCartData];
      cartData.splice(indexPosition, 1, rdsRiFormData);
      dispatch(setAwsRdsRisCartData(cartData));
    } else {
      dispatch(setAwsRdsRisCartData([...awsRdsRiCartData, rdsRiFormData]));
    }
    setShow(false);
  };

  return (
    <DrawerComponent
      className="rds-ri-purchase-form"
      dataTestId="rds-ri-purchase-form"
      width={481}
      open={show}
      title={`${t(
        'purchasePage.reservedInstancesLabels.purchaseReservedInstances'
      )} - ${t('purchasePage.reservedInstancesLabels.rds')}`}
      onClose={() => setShow(false)}
      footer={
        <PurchaseFooter
          totalHeading={t('purchasePage.cart.subTotalDueNow')}
          totalValue={getSubTotalDue(
            rdsRiFormData.term,
            rdsRiFormData.hourlyUsageCharge
          )}
          costSummary={[
            {
              key: t(
                'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
              ),
              value: getAverageMonthlyRecurringCost(
                rdsRiFormData.term,
                rdsRiFormData.hourlyUsageCharge
              ),
            },
            {
              key: t('purchasePage.reservedInstancesLabels.oneTimePayment'),
              value: rdsRiFormData?.oneTimePayment,
            },
          ]}
          okTitle={
            editData
              ? t('purchasePage.cart.update')
              : t('purchasePage.cart.buy')
          }
          onClickCancel={() => setShow(false)}
          onClickOk={onClickBuy}
          loading={offeringDetailsRequestStatus === REQUEST_STATUS.PROCESSING}
          validationMessage={offeringDetailsValidation}
        />
      }
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.productDescription')}
            required={true}
          />
          <SelectDropdown
            value={rdsRiFormData?.productDescription}
            options={riProductDescriptions.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t(
              'purchasePage.reservedInstancesLabels.selectProductDescription'
            )}
            loading={
              fetchProductDescriptionsReqStatus === REQUEST_STATUS.PROCESSING
            }
            onSelect={(value: any) => {
              setRdsRiFormData({
                ...rdsRiFormData,
                productDescription: value,
                dbInstanceClass: '',
              });
              validateProductDescription(value);
            }}
            onBlur={() => {
              validateProductDescription(rdsRiFormData?.productDescription);
            }}
            designVersion2
          />
          <span
            style={{
              display: productDescriptionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {productDescriptionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.region')} required={true} />
          <SelectDropdown
            value={rdsRiFormData?.region}
            options={awsRegions.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t('purchasePage.cart.selectRegion')}
            loading={fetchRegionReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setRdsRiFormData({
                ...rdsRiFormData,
                region: value,
                dbInstanceClass: '',
              });
              validateRegion(value);
            }}
            onBlur={() => {
              validateRegion(rdsRiFormData?.region);
            }}
            designVersion2
          />
          <span
            style={{
              display: regionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {regionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.dbInstanceClass')}
            required={true}
          />
          <SelectDropdown
            value={rdsRiFormData?.dbInstanceClass}
            options={dbInstanceClasses.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t(
              'purchasePage.reservedInstancesLabels.selectDbInstanceClass'
            )}
            loading={
              fetchInstanceClassesRequestStatus === REQUEST_STATUS.PROCESSING
            }
            onSelect={(value: any) => {
              setRdsRiFormData({
                ...rdsRiFormData,
                dbInstanceClass: value,
              });
              validateDbInstanceClass(value);
            }}
            onBlur={() => {
              validateDbInstanceClass(rdsRiFormData?.dbInstanceClass);
            }}
            disabled={
              !validateProductDescription(
                rdsRiFormData.productDescription,
                false
              )
            }
            designVersion2
          />
          <span
            style={{
              display: dbInstanceClassValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {dbInstanceClassValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t(
              'purchasePage.reservedInstancesLabels.isMultiAzDbInstance'
            )}
            required={true}
          />
          <RadioGroup
            rootClassName="flex-column"
            options={YES_NO_OPTIONS}
            onChange={(e: any) => {
              setRdsRiFormData({
                ...rdsRiFormData,
                isMultiAzDbInstance: e.target.value,
              });
            }}
            value={rdsRiFormData.isMultiAzDbInstance}
            disabled={
              !validateDbInstanceClass(rdsRiFormData.dbInstanceClass, false) ||
              !isMultiAz
            }
            loading={fetchIsMultiAzRequestStatus === REQUEST_STATUS.PROCESSING}
            rows={2}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.term')} required={true} />
          <RadioGroup
            options={TERMS_LIST.map((item) => ({
              ...item,
              disabled:
                terms !== undefined && item.disabledTermsList.includes(terms),
            }))}
            onChange={(e: any) => {
              setRdsRiFormData({ ...rdsRiFormData, term: e.target.value });
            }}
            value={rdsRiFormData.term}
            optionType="button"
            style={{ height: 28 }}
            disabled={isMultiAz === undefined || !terms}
            loading={fetchTermsReqStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.cart.paymentOptions')}
            required={true}
          />
          <SelectDropdown
            value={rdsRiFormData?.paymentOption}
            options={paymentOptions?.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t('purchasePage.cart.selectPaymentOption')}
            onSelect={(value: any) => {
              setRdsRiFormData({
                ...rdsRiFormData,
                paymentOption: value,
              });
              validatePaymentOption(value);
            }}
            onBlur={() => {
              validatePaymentOption(rdsRiFormData?.paymentOption);
            }}
            loading={fetchPaymentOptionsReqStatus === REQUEST_STATUS.PROCESSING}
            disabled={terms === undefined}
            designVersion2
          />
          <span
            style={{
              display: paymentOptionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {paymentOptionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.quantity')}
            required={true}
          />
          <Input
            value={rdsRiFormData?.quantity}
            type="stepNumber"
            rootClassName="width-30"
            steps={1}
            min={1}
            onChange={(value: number) => {
              setRdsRiFormData({
                ...rdsRiFormData,
                quantity: value,
              });
              validateQuantityField(value);
            }}
            onBlur={() => {
              validateQuantityField(rdsRiFormData?.quantity);
            }}
          />
          <span
            style={{
              display: quantityValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {quantityValidation}
          </span>
        </div>
      </section>
    </DrawerComponent>
  );
};

export default BuyRdsRiFormModal;
