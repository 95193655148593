import i18n from 'i18n';

export enum ServiceNowStatus {
  TICKET_TBC = 'TICKET_TBC',
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED',
}

// Do not change the order of the below list as this is the order of Service now status numbers from BE
export const ORDERED_SERVICE_NOW_STATUS_LIST = [
  ServiceNowStatus.TICKET_TBC,
  ServiceNowStatus.NEW,
  ServiceNowStatus.IN_PROGRESS,
  ServiceNowStatus.ON_HOLD,
  '', // Empty since there is no status map of SNOW for 4
  '', // Empty since there is no status map of SNOW for 5
  ServiceNowStatus.RESOLVED,
  ServiceNowStatus.CLOSED,
];

export const SERVICE_NOW_STATUS_LABELS = [
  {
    key: ServiceNowStatus.TICKET_TBC,
    label: i18n.t('serviceNowLabels.ticketTBC'),
    tabTitle: i18n.t('serviceNowLabels.undefined'),
  },
  {
    key: ServiceNowStatus.NEW,
    label: i18n.t('serviceNowLabels.new'),
    tabTitle: i18n.t('serviceNowLabels.new'),
  },
  {
    key: ServiceNowStatus.IN_PROGRESS,
    label: i18n.t('serviceNowLabels.inProgress'),
    tabTitle: i18n.t('serviceNowLabels.inProgress'),
  },
  {
    key: ServiceNowStatus.ON_HOLD,
    label: i18n.t('serviceNowLabels.onHold'),
    tabTitle: i18n.t('serviceNowLabels.onHold'),
  },
  {
    key: ServiceNowStatus.RESOLVED,
    label: i18n.t('serviceNowLabels.resolved'),
    tabTitle: i18n.t('serviceNowLabels.resolved'),
  },
  {
    key: ServiceNowStatus.CLOSED,
    label: i18n.t('serviceNowLabels.closed'),
    tabTitle: i18n.t('serviceNowLabels.closed'),
  },
];

export enum TICKET_RECOMMENDATION_STATUS {
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
}

export const IncidentRecommendationStatusLabels = [
  {
    key: TICKET_RECOMMENDATION_STATUS.APPROVED,
    label: i18n.t('approvalStatusLabels.approved'),
  },
  {
    key: TICKET_RECOMMENDATION_STATUS.DISAPPROVED,
    label: i18n.t('approvalStatusLabels.disapproved'),
  },
];
