import { useTranslation } from 'react-i18next';
import { RolesType } from 'pages/RolesPage/constants';
import Modal from 'components/Modal';
import PermissionsTable from '../PermissionsTable';

type ViewPermissionsModalProps = {
  showPermissionsModal: boolean;
  setShowPermissionsModal: (val: boolean) => void;
  currentRoleForModal: RolesType;
};

const ViewPermissionsModal = ({
  showPermissionsModal,
  setShowPermissionsModal,
  currentRoleForModal,
}: ViewPermissionsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="role-permissions"
      visible={showPermissionsModal}
      title={currentRoleForModal.name}
      footer={null}
      onCancel={() => setShowPermissionsModal(false)}
      width={700}
      centered
    >
      <div className="flex flex-column flex-start flex-space-between flex-gap-16">
        <div className="body table-typography">
          {t('rolePermissionsModal.allPermissions')}
        </div>
        <PermissionsTable role={currentRoleForModal} disabled={true} />
      </div>
    </Modal>
  );
};

export default ViewPermissionsModal;
