import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function validateAzureParsedSchema
 * @description Function to get the mapping between category and recommenders
 * @return axios response from GET request
 */
export const validateGcpParsedSchema = async (body: any) => {
  return ServiceCalls.post(APIS.VALIDATE_GCP_LABELS, body);
};

/**
 * @function validateAzureParsedSchema
 * @description Function to get the mapping between category and recommenders
 * @return axios response from GET request
 */
export const validateAzureParsedSchema = async (body: any) => {
  return ServiceCalls.post(APIS.VALIDATE_AZURE_LABELS, body);
};

/**
 * @function applyGcpSchema
 * @description Function to get the mapping between category and recommenders
 * @return axios response from GET request
 */
export const applyGcpSchema = async (body: any) => {
  return ServiceCalls.post(APIS.APPLY_GCP_LABELS, body);
};

/**
 * @function applyAzureSchema
 * @description Function to get the mapping between category and recommenders
 * @return axios response from GET request
 */
export const applyAzureSchema = async (body: any) => {
  return ServiceCalls.post(APIS.APPLY_AZURE_LABELS, body);
};

/**
 * @function getTagsAndLabels
 * @description Function to get all tags by connection
 * @param params Object containing request parameters.
 * @return axios response from GET request
 */
export const getTagsAndLabels = async (params: any) => {
  return ServiceCalls.get(APIS.TAGS_AND_LABELS, null, params);
};

/**
 * @function createTagsAndLabels
 * @description Function to create Tags for a connection
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createTagsAndLabels = async (body: any) => {
  return ServiceCalls.post(APIS.TAGS_AND_LABELS, body);
};

/**
 * @function updateTagsAndLabels
 * @description Function to update the tags for a connection
 * @param body Object containing the request body
 * @return axios response from PUT request
 */
export const updateTagsAndLabels = async (body: any) => {
  return ServiceCalls.put(APIS.TAGS_AND_LABELS, body);
};

/**
 * @function exportGCPTagsAndLabels
 * @description Function to export the GCP tags and labels with values if exist
 * @param params Object containing request parameters.
 * @return axios response from GET request
 */
export const exportGCPTagsAndLabels = async (params: any) => {
  return ServiceCalls.get(APIS.EXPORT_GCP_TAGS_AND_LABELS, null, params);
};

/**
 * @function exportAzureTagsAndLabels
 * @description Function to export the Azure tags and labels with values if exist
 * @param params Object containing request parameters.
 * @return axios response from GET request
 */
export const exportAzureTagsAndLabels = async (params: any) => {
  return ServiceCalls.get(APIS.EXPORT_AZURE_TAGS_AND_LABELS, null, params);
};
