import i18n from 'i18n';

export enum PROVIDER {
  GCP = 'GCP',
  AWS = 'AWS',
  AZURE = 'AZURE',
  OCI = 'OCI',
}

export enum TICKET_PROVIDER {
  JIRA = 'JIRA',
  SERVICE_NOW = 'SERVICE_NOW',
}

export const CLOUD_PROVIDERS_LIST = [
  {
    text: i18n.t('providers.awsTitle'),
    value: PROVIDER.AWS,
  },
  {
    text: i18n.t('providers.gcpTitle'),
    value: PROVIDER.GCP,
  },
  {
    text: i18n.t('providers.azureTitle'),
    value: PROVIDER.AZURE,
  },
  {
    text: i18n.t('providers.ociTitle'),
    value: PROVIDER.OCI,
  },
];
