import { CancelToken } from 'axios';
import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getAllSubscriptions
 * @description Function to get all the subscriptions
 * @param params request params
 * @param cancelToken cancel token
 * @return axios response from GET request
 */
export const getAllSubscriptions = async (
  params: any,
  cancelToken?: CancelToken
) => {
  return ServiceCalls.get(
    APIS.GET_ALL_SUBSCRIPTIONS,
    null,
    params,
    cancelToken
  );
};

/**
 * @function searchSubscriptions
 * @description Function to search the subscriptions by key
 * @param params request params
 * @param cancelToken cancel token
 * @return axios response from GET request
 */
export const searchSubscriptions = async (
  params: any,
  cancelToken?: CancelToken
) => {
  return ServiceCalls.get(APIS.SEARCH_SUBSCRIPTIONS, null, params, cancelToken);
};

/**
 * @function fetchSubscriptionNameValidation
 * @description Function to validate the subscription name
 * @param params Object containing the request params
 * @param cancelToken token for cancelling the request
 * @return axios response from GET request
 */
export const fetchSubscriptionNameValidation = async (
  params: any,
  cancelToken: any
) => {
  return ServiceCalls.get(
    APIS.VALIDATE_SUBSCRIPTION_NAME,
    null,
    params,
    cancelToken
  );
};

/**
 * @function deleteSubscription
 * @description Function to delete the subscription
 * @param params Object containing the request params
 * @return axios response from DELETE request
 */
export const deleteSubscription = async (params: any) => {
  return ServiceCalls.delete(APIS.SUBSCRIPTION, null, params);
};

/**
 * @function createSubscription
 * @description Function to create the subscription
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createSubscription = async (body: any) => {
  return ServiceCalls.post(APIS.SUBSCRIPTION, body);
};

/**
 * @function updateSubscription
 * @description Function to update the subscription
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const updateSubscription = async (body: any) => {
  return ServiceCalls.post(APIS.UPDATE_SUBSCRIPTION, body);
};
