import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { sum } from 'lodash';
import { RangeValue } from 'rc-picker/lib/interface';

import ComparisonCard from 'components/NewComparisonCard';
import ControlComponent from 'components/DashboardControl';
import { INPUT_SIZE } from 'constants/appearance';
import { CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT } from 'constants/userConsole';
import { ManualEntryNavs } from 'pages/DataCenterPage/constants';
import ExpandModal from 'components/ExpandModal';
import { CHART_TYPES } from 'constants/graphConfig';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import DropdownCheckbox from 'components/DropdownCheckbox';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { tcoDashboard } from 'redux/tcoDashboardSlice';
import { MANUAL_ENTRY_NAVIGATION } from 'pages/DataCenterPage/components/ManualEntry/constants';
import { TcoCostType } from 'pages/TcoDashboardPage/types';
import {
  getCostByDataCenterCode,
  getCostByTcoConnection,
} from 'pages/TcoDashboardPage/services';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { ComparisonListType } from 'types/dashboard';
import { getPercentageDifference } from 'utils/dashboardUtils';
import { MONTH_YEAR_FORMAT, TIMESTAMP_FORMAT_WITHOUT_ZONE } from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import { modifyToExportColumns } from 'utils/exportToExcel';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import TotalCost from './components/TotalCost';
import { getDatesForTcoDashboard } from './utils/getDates';
import {
  getTcoColumns,
  getTcoCostSummaryExcelData,
} from './utils/exportToExcel';

import './index.scss';

const TcoCostSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { expandedGraphName, tableViewEnabled, pdfDownloadMode } =
    useSelector(selectDashboard);
  const { selectedOnPremise, selectedTco } = useSelector(tcoDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [costComparisonList, setCostComparisonList] = useState<
    ComparisonListType[]
  >([]);

  //States for on premise comparison cards
  const [onPremComparisonCostMetaData, setOnPremComparisonCostMetaData] =
    useState<any[]>([]);
  const [onPremCurrentYearCost, setOnPremCurrentYearCost] = useState<
    TcoCostType[]
  >([]);
  const [
    onPremCurrentYearCostRequestStatus,
    setOnPremCurrentYearCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [onPremPreviousYearCost, setOnPremPreviousYearCost] = useState<
    TcoCostType[]
  >([]);
  const [
    onPremPreviousYearCostRequestStatus,
    setOnPremPreviousYearCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [onPremCurrentQuarterCost, setOnPremCurrentQuarterCost] = useState<
    TcoCostType[]
  >([]);
  const [
    onPremCurrentQuarterCostRequestStatus,
    setOnPremCurrentQuarterCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [onPremPreviousQuarterCost, setOnPremPreviousQuarterCost] = useState<
    TcoCostType[]
  >([]);
  const [
    onPremPreviousQuarterCostRequestStatus,
    setOnPremPreviousQuarterCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [onPremCurrentMonthCost, setOnPremCurrentMonthCost] = useState<
    TcoCostType[]
  >([]);
  const [
    onPremCurrentMonthCostRequestStatus,
    setOnPremCurrentMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [onPremPreviousMonthCost, setOnPremPreviousMonthCost] = useState<
    TcoCostType[]
  >([]);
  const [
    onPremPreviousMonthCostRequestStatus,
    setOnPremPreviousMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [onPremPreviousSecondMonthCost, setOnPremPreviousSecondMonthCost] =
    useState<TcoCostType[]>([]);
  const [
    onPremPreviousSecondMonthCostRequestStatus,
    setOnPremPreviousSecondMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  //States for Connection comparison cards
  const [
    connectionComparisonCostMetaData,
    setConnectionComparisonCostMetaData,
  ] = useState<any[]>([]);
  const [connectionCurrentYearCost, setConnectionCurrentYearCost] = useState<
    TcoCostType[]
  >([]);
  const [
    connectionCurrentYearCostRequestStatus,
    setConnectionCurrentYearCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [connectionPreviousYearCost, setConnectionPreviousYearCost] = useState<
    TcoCostType[]
  >([]);
  const [
    connectionPreviousYearCostRequestStatus,
    setConnectionPreviousYearCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [connectionCurrentQuarterCost, setConnectionCurrentQuarterCost] =
    useState<TcoCostType[]>([]);
  const [
    connectionCurrentQuarterCostRequestStatus,
    setConnectionCurrentQuarterCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [connectionPreviousQuarterCost, setConnectionPreviousQuarterCost] =
    useState<TcoCostType[]>([]);
  const [
    connectionPreviousQuarterCostRequestStatus,
    setConnectionPreviousQuarterCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [connectionCurrentMonthCost, setConnectionCurrentMonthCost] = useState<
    TcoCostType[]
  >([]);
  const [
    connectionCurrentMonthCostRequestStatus,
    setConnectionCurrentMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [connectionPreviousMonthCost, setConnectionPreviousMonthCost] =
    useState<TcoCostType[]>([]);
  const [
    connectionPreviousMonthCostRequestStatus,
    setConnectionPreviousMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    connectionPreviousSecondMonthCost,
    setConnectionPreviousSecondMonthCost,
  ] = useState<TcoCostType[]>([]);
  const [
    connectionPreviousSecondMonthCostRequestStatus,
    setConnectionPreviousSecondMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  //State for total Cost Data
  const [onPremiseCostData, setOnPremiseCostData] = useState<TcoCostType[]>([]);
  const [connectionCostData, setConnectionCostData] = useState<TcoCostType[]>(
    []
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedConnections, setSelectedConnections] = useState<string[]>([]);
  const [onPremCostRequestStatus, setOnPremCostRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [connectionCostRequestStatus, setConnectionCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [startDate, setStartDate] = useState(
    moment()
      .startOf('year')
      .startOf('day')
      .format(TIMESTAMP_FORMAT_WITHOUT_ZONE)
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('year').endOf('day').format(TIMESTAMP_FORMAT_WITHOUT_ZONE)
  );

  //States for table view
  const [isCostTableView, setIsCostTableView] = useState(false);

  useEffect(() => {
    setOnPremComparisonCostMetaData([
      {
        setData: setOnPremCurrentYearCost,
        setRequestStatus: setOnPremCurrentYearCostRequestStatus,
        startDate: moment()
          .startOf('year')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment().endOf('day').format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setOnPremPreviousYearCost,
        setRequestStatus: setOnPremPreviousYearCostRequestStatus,
        startDate: moment()
          .subtract(1, 'year')
          .startOf('year')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .subtract(1, 'year')
          .endOf('year')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setOnPremCurrentQuarterCost,
        setRequestStatus: setOnPremCurrentQuarterCostRequestStatus,
        startDate: moment()
          .startOf('quarter')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment().endOf('day').format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setOnPremPreviousQuarterCost,
        setRequestStatus: setOnPremPreviousQuarterCostRequestStatus,
        startDate: moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .subtract(1, 'quarter')
          .endOf('quarter')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setOnPremCurrentMonthCost,
        setRequestStatus: setOnPremCurrentMonthCostRequestStatus,
        startDate: moment()
          .startOf('month')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .endOf('month')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setOnPremPreviousMonthCost,
        setRequestStatus: setOnPremPreviousMonthCostRequestStatus,
        startDate: moment()
          .subtract(1, 'month')
          .startOf('month')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .subtract(1, 'month')
          .endOf('month')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setOnPremPreviousSecondMonthCost,
        setRequestStatus: setOnPremPreviousSecondMonthCostRequestStatus,
        startDate: moment()
          .subtract(2, 'month')
          .startOf('month')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .subtract(2, 'month')
          .endOf('month')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
    ]);

    setConnectionComparisonCostMetaData([
      {
        setData: setConnectionCurrentYearCost,
        setRequestStatus: setConnectionCurrentYearCostRequestStatus,
        startDate: moment()
          .startOf('year')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment().endOf('day').format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setConnectionPreviousYearCost,
        setRequestStatus: setConnectionPreviousYearCostRequestStatus,
        startDate: moment()
          .subtract(1, 'year')
          .startOf('year')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .subtract(1, 'year')
          .endOf('year')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setConnectionCurrentQuarterCost,
        setRequestStatus: setConnectionCurrentQuarterCostRequestStatus,
        startDate: moment()
          .startOf('quarter')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment().endOf('day').format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setConnectionPreviousQuarterCost,
        setRequestStatus: setConnectionPreviousQuarterCostRequestStatus,
        startDate: moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .subtract(1, 'quarter')
          .endOf('quarter')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setConnectionCurrentMonthCost,
        setRequestStatus: setConnectionCurrentMonthCostRequestStatus,
        startDate: moment()
          .startOf('month')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .endOf('month')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setConnectionPreviousMonthCost,
        setRequestStatus: setConnectionPreviousMonthCostRequestStatus,
        startDate: moment()
          .subtract(1, 'month')
          .startOf('month')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .subtract(1, 'month')
          .endOf('month')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
      {
        setData: setConnectionPreviousSecondMonthCost,
        setRequestStatus: setConnectionPreviousSecondMonthCostRequestStatus,
        startDate: moment()
          .subtract(2, 'month')
          .startOf('month')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
        endDate: moment()
          .subtract(2, 'month')
          .endOf('month')
          .endOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      },
    ]);
  }, []);

  useEffect(() => {
    setOnPremCurrentYearCost([]);
    setOnPremPreviousYearCost([]);
    setOnPremCurrentQuarterCost([]);
    setOnPremPreviousQuarterCost([]);
    setOnPremCurrentMonthCost([]);
    setOnPremPreviousMonthCost([]);
    setOnPremPreviousSecondMonthCost([]);
    setConnectionCurrentYearCost([]);
    setConnectionPreviousYearCost([]);
    setConnectionCurrentQuarterCost([]);
    setConnectionPreviousQuarterCost([]);
    setConnectionCurrentMonthCost([]);
    setConnectionPreviousMonthCost([]);
    setConnectionPreviousSecondMonthCost([]);
    setSelectedCategories(MANUAL_ENTRY_NAVIGATION.map((item) => item.id));
    setSelectedConnections([]);

    if (selectedOnPremise !== undefined || selectedTco !== undefined) {
      setSelectedConnections(selectedTco?.connectorsList ?? []);
      getCostComparisonCardsData();
    }
  }, [selectedOnPremise, selectedTco, onPremComparisonCostMetaData]);

  useEffect(() => {
    setCostComparisonList([
      {
        value: consolidatedCost(
          calculateTotalCost([
            ...onPremCurrentYearCost,
            ...connectionCurrentYearCost,
          ])
        ),
        heading: t('tcoDashboard.comparisonCards.ytdTco'),
        comparisonValue: getPercentageDifference(
          consolidatedCost(
            calculateTotalCost([
              ...onPremCurrentYearCost,
              ...connectionCurrentYearCost,
            ])
          ),
          consolidatedCost(
            calculateTotalCost([
              ...onPremPreviousYearCost,
              ...connectionPreviousYearCost,
            ])
          )
        ),
        comparisonFrom: t('tcoDashboard.comparisonCards.fromPreviousYear'),
        requestStatus: [
          onPremCurrentYearCostRequestStatus,
          onPremPreviousYearCostRequestStatus,
          connectionCurrentYearCostRequestStatus,
          connectionPreviousYearCostRequestStatus,
        ],
      },
      {
        value: consolidatedCost(
          calculateTotalCost([
            ...onPremCurrentQuarterCost,
            ...connectionCurrentQuarterCost,
          ])
        ),
        heading: t('tcoDashboard.comparisonCards.qtdTco'),
        comparisonValue: getPercentageDifference(
          consolidatedCost(
            calculateTotalCost([
              ...onPremCurrentQuarterCost,
              ...connectionCurrentQuarterCost,
            ])
          ),
          consolidatedCost(
            calculateTotalCost([
              ...onPremPreviousQuarterCost,
              ...connectionPreviousQuarterCost,
            ])
          )
        ),
        comparisonFrom: t('tcoDashboard.comparisonCards.fromPreviousQuarter'),
        requestStatus: [
          onPremCurrentQuarterCostRequestStatus,
          onPremPreviousQuarterCostRequestStatus,
          connectionCurrentQuarterCostRequestStatus,
          connectionPreviousQuarterCostRequestStatus,
        ],
      },
      {
        value: consolidatedCost(
          calculateTotalCost([
            ...onPremCurrentMonthCost,
            ...connectionCurrentMonthCost,
          ])
        ),
        heading: t('tcoDashboard.comparisonCards.currentMonthTco'),
        comparisonValue: getPercentageDifference(
          consolidatedCost(
            calculateTotalCost([
              ...onPremCurrentMonthCost,
              ...connectionCurrentMonthCost,
            ])
          ),
          consolidatedCost(
            calculateTotalCost([
              ...onPremPreviousMonthCost,
              ...connectionPreviousMonthCost,
            ])
          )
        ),
        comparisonFrom: t('tcoDashboard.comparisonCards.fromPreviousMonth'),
        requestStatus: [
          onPremCurrentMonthCostRequestStatus,
          onPremPreviousMonthCostRequestStatus,
          connectionCurrentMonthCostRequestStatus,
          connectionPreviousMonthCostRequestStatus,
        ],
      },
      {
        value: consolidatedCost(
          calculateTotalCost([
            ...onPremPreviousMonthCost,
            ...connectionPreviousMonthCost,
          ])
        ),
        heading: t('tcoDashboard.comparisonCards.lastMonthTco'),
        comparisonValue: getPercentageDifference(
          consolidatedCost(
            calculateTotalCost([
              ...onPremPreviousMonthCost,
              ...connectionPreviousMonthCost,
            ])
          ),
          consolidatedCost(
            calculateTotalCost([
              ...onPremPreviousSecondMonthCost,
              ...connectionPreviousSecondMonthCost,
            ])
          )
        ),
        comparisonFrom: t('tcoDashboard.comparisonCards.fromTwoMonthsBack'),
        requestStatus: [
          onPremPreviousMonthCostRequestStatus,
          onPremPreviousSecondMonthCostRequestStatus,
          connectionPreviousMonthCostRequestStatus,
          connectionPreviousSecondMonthCostRequestStatus,
        ],
      },
    ]);
  }, [
    onPremCurrentYearCost,
    onPremPreviousYearCost,
    onPremCurrentQuarterCost,
    onPremPreviousQuarterCost,
    onPremCurrentMonthCost,
    onPremPreviousMonthCost,
    onPremPreviousSecondMonthCost,
    connectionCurrentYearCost,
    connectionPreviousYearCost,
    connectionCurrentQuarterCost,
    connectionPreviousQuarterCost,
    connectionCurrentMonthCost,
    connectionPreviousMonthCost,
    connectionPreviousSecondMonthCost,
    selectedCategories,
    selectedConnections,
  ]);

  useEffect(() => {
    setOnPremiseCostData([]);
    setConnectionCostData([]);
    if (selectedOnPremise || selectedTco) {
      getTotalCostData();
    }
  }, [selectedOnPremise, selectedTco, startDate, endDate]);

  useEffect(() => {
    if (selectedOnPremise ?? selectedTco) {
      dispatch(
        setExportToExcelData(
          getTcoCostSummaryExcelData(getTableData(), !!selectedOnPremise, [
            {
              heading: selectedOnPremise
                ? t('tcoDashboard.totalCost.dataCenter')
                : t('tcoDashboard.totalCost.project'),
              value: selectedOnPremise?.name ?? selectedTco?.name,
            },
          ])
        )
      );
    }
  }, [
    selectedOnPremise,
    selectedTco,
    onPremiseCostData,
    connectionCostData,
    selectedCategories,
    selectedConnections,
  ]);

  useEffect(() => {
    setIsCostTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  /**
   * @function calculateTotalCost
   * @description function to consolidate all connections or data centers cost of each category
   * @param data to consolidate each connection or on premise cost
   * @returns consolidated cost
   */
  const calculateTotalCost = (data: TcoCostType[]): TcoCostType => {
    let consolidatedData = {
      compute: 0,
      database: 0,
      network: 0,
      storage: 0,
      labor: 0,
      software: 0,
    };

    data
      .filter((item) =>
        [
          ...selectedConnections,
          ...((selectedOnPremise
            ? [selectedOnPremise?.dataCenterCode]
            : selectedTco?.dataCentersList) ?? []),
        ].includes(item.key!)
      )
      .forEach((item) => {
        consolidatedData = {
          compute: consolidatedData.compute + item.compute,
          database: consolidatedData.database + item.database,
          network: consolidatedData.network + item.network,
          storage: consolidatedData.storage + item.storage,
          labor: consolidatedData.labor + item.labor,
          software: consolidatedData.software + item.software,
        };
      });

    return consolidatedData;
  };

  const consolidatedCost = (costData: TcoCostType) => {
    return sum(filterDataByCategories(costData));
  };

  const getCostComparisonCardsData = () => {
    if (selectedOnPremise) {
      getAndSetComparisonCardData([selectedOnPremise.dataCenterCode]);
    }

    if (selectedTco) {
      getAndSetComparisonCardData(
        selectedTco.dataCentersList,
        selectedTco.connectorsList
      );
    }
  };

  /**
   * @function getAndSetComparisonCardData
   * @description function to get and set the cost comparison cards of each category of on Premise and TCO
   * @param dataCenterList List of selected data centers
   * @param connectionList list of selected tco connections
   */
  const getAndSetComparisonCardData = (
    dataCenterList: string[] = [],
    connectionList: string[] = []
  ) => {
    if (dataCenterList.length > 0) {
      onPremComparisonCostMetaData.forEach((item) => {
        getConsolidatedOnPremiseCost(
          dataCenterList,
          item.startDate,
          item.endDate,
          item.setData,
          item.setRequestStatus
        );
      });
    }

    if (selectedTco && connectionList.length > 0) {
      connectionComparisonCostMetaData.forEach((item) => {
        getConsolidatedTcoConnectionCost(
          item.startDate,
          item.endDate,
          item.setData,
          item.setRequestStatus
        );
      });
    }
  };

  /**
   * @function getTotalCostData
   * @description Function to get the total cost of all the categories, data-centers and connections.
   */
  const getTotalCostData = () => {
    if (selectedOnPremise) {
      getAndSetTotalCostData([selectedOnPremise.dataCenterCode]);
    }

    if (selectedTco) {
      getAndSetTotalCostData(
        selectedTco.dataCentersList,
        selectedTco.connectorsList
      );
    }
  };

  /**
   * @function getAndSetTotalCostData
   * @description function to get and set the total cost of each category of on Premise and TCO
   * @param dataCenterList List of selected data centers
   * @param connectionList list of selected tco connections
   */
  const getAndSetTotalCostData = (
    dataCenterList: string[] = [],
    connectionList: string[] = []
  ) => {
    if (dataCenterList.length > 0) {
      getConsolidatedOnPremiseCost(
        dataCenterList,
        startDate,
        endDate,
        setOnPremiseCostData,
        setOnPremCostRequestStatus
      );
    }

    if (connectionList.length > 0) {
      getConsolidatedTcoConnectionCost(
        startDate,
        endDate,
        setConnectionCostData,
        setConnectionCostRequestStatus
      );
    }
  };

  /**
   * @function getConnectionCostFromResponse
   * @description function to get cost of each category from each connection response
   * @param res response of tco dashboard api
   * @param category means 'SOFTWARE', 'DATABASE', etc...
   * @returns cost of each category
   */
  const getConnectionCostFromResponse = (res: any, category: string) => {
    const categorizedResponse = res.find(
      (response: any) =>
        JSON.parse(response?.config?.data)?.tcoCategory === category
    );
    return Number(categorizedResponse?.data[0]?.cost) || 0;
  };

  /**
   * @function onApiError
   * @description Callback function for error fetching TCo data
   * @param e error response
   * @param setRequestStatus setter for request status
   * @param setData for setState for the fetched data
   */
  const onApiError = (
    e: any,
    setRequestStatus: (val: string) => void,
    setData: (val: TcoCostType[]) => void
  ) => {
    onApiCallError(e, false, setRequestStatus);
    setData([]);
  };

  /**
   * @function getConsolidatedTcoConnectionCost
   * @description function to fetch TCO for connections.
   * @param startingDate start date for dashboard api
   * @param endingDate end date for dashboard api
   * @param setData for setState for the fetched data
   * @param setRequestStatus for setting request status of the api
   */
  const getConsolidatedTcoConnectionCost = (
    startingDate: string,
    endingDate: string,
    setData: (val: TcoCostType[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);
    let requests: any[] = [];
    selectedTco?.connectorDetailsList.forEach((connection) => {
      Object.keys(ManualEntryNavs)
        .filter(
          (filter) =>
            ![
              ManualEntryNavs.SOFTWARE.valueOf(),
              ManualEntryNavs.LABOUR.valueOf(),
            ].includes(filter)
        )
        .forEach((eachCategory) => {
          requests.push(
            getCostByTcoConnection({
              tcoCategory: eachCategory,
              connectorId: connection.connectorId,
              startDate: getDatesForTcoDashboard(
                startingDate,
                connection.cloudProvider
              ),
              endDate: getDatesForTcoDashboard(
                endingDate,
                connection.cloudProvider
              ),
            })
          );
        });
    });

    axios
      .all(requests)
      .then((res) => {
        const responseData: TcoCostType[] = [];
        selectedTco?.connectorDetailsList.forEach((eachConnection) => {
          const connectionResponses = res.filter(
            (response) =>
              JSON.parse(response?.config?.data)?.connectorId ===
              eachConnection.connectorId
          );
          responseData.push({
            key: eachConnection.connectorId,
            compute: getConnectionCostFromResponse(
              connectionResponses,
              ManualEntryNavs.COMPUTE
            ),
            database: getConnectionCostFromResponse(
              connectionResponses,
              ManualEntryNavs.DATABASE
            ),
            network: getConnectionCostFromResponse(
              connectionResponses,
              ManualEntryNavs.NETWORK
            ),
            storage: getConnectionCostFromResponse(
              connectionResponses,
              ManualEntryNavs.STORAGE
            ),
            labor: getConnectionCostFromResponse(
              connectionResponses,
              ManualEntryNavs.LABOUR
            ),
            software: getConnectionCostFromResponse(
              connectionResponses,
              ManualEntryNavs.SOFTWARE
            ),
          });
        });
        setRequestStatus(REQUEST_STATUS.SUCCESS);
        setData(responseData);
      })
      .catch((e) => {
        onApiError(e, setRequestStatus, setData);
      });
  };

  /**
   * @function getConsolidatedOnPremiseCost
   * @description function to fetch dashboard data for on premise.
   * @param dataCenterList List of selected data centers
   * @param startingDate start date for dashboard api
   * @param endingDate end date for dashboard api
   * @param setData for setState for the fetched data
   * @param setRequestStatus for setting request status of the api
   */
  const getConsolidatedOnPremiseCost = (
    dataCenterList: string[],
    startingDate: string,
    endingDate: string,
    setData: (val: TcoCostType[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);
    let requests: any[] = [];
    dataCenterList.forEach((dataCenter) => {
      requests.push(
        getCostByDataCenterCode({
          dataCenterCode: dataCenter,
          startDate: startingDate,
          endDate: endingDate,
        })
      );
    });

    axios
      .all(requests)
      .then((res) => {
        setRequestStatus(REQUEST_STATUS.SUCCESS);
        setData(
          dataCenterList.map((dataCenter, index) => ({
            key: dataCenter,
            ...res[index]?.data?.responseData,
          }))
        );
      })
      .catch((e) => {
        onApiError(e, setRequestStatus, setData);
      });
  };

  const filterDataByCategories = (costData: TcoCostType) => {
    let data: number[] = [];
    selectedCategories.forEach((category) =>
      data.push(getCostByCategory(category, costData))
    );

    return data;
  };

  const getCostByCategory = (category: string, costData: TcoCostType) => {
    switch (category) {
      case ManualEntryNavs.COMPUTE:
        return costData.compute;
      case ManualEntryNavs.DATABASE:
        return costData.database;
      case ManualEntryNavs.STORAGE:
        return costData.storage;
      case ManualEntryNavs.NETWORK:
        return costData.network;
      case ManualEntryNavs.SOFTWARE:
        return costData.software;
      case ManualEntryNavs.LABOUR:
        return costData.labor;
      default:
        return 0;
    }
  };

  /**
   * @function getTableData
   * @description Function to get the data for table
   * @returns table data
   */
  const getTableData = () => {
    const onPremiseCost = calculateTotalCost(onPremiseCostData);
    const connectionCost = calculateTotalCost(connectionCostData);
    const tcoCost = {
      compute: onPremiseCost.compute + connectionCost.compute,
      storage: onPremiseCost.storage + connectionCost.storage,
      database: onPremiseCost.database + connectionCost.database,
      network: onPremiseCost.network + connectionCost.network,
      software: onPremiseCost.software + connectionCost.software,
      labor: onPremiseCost.labor + connectionCost.labor,
    };
    if (selectedOnPremise) {
      return selectedCategories.map((category) => ({
        category:
          MANUAL_ENTRY_NAVIGATION.find((item) => item.id === category)?.title ??
          category,
        onPremise: getCostByCategory(category, onPremiseCost),
      }));
    } else if (selectedTco)
      return selectedCategories.map((category) => {
        return {
          category:
            MANUAL_ENTRY_NAVIGATION.find((item) => item.id === category)
              ?.title ?? category,
          onPremise: getCostByCategory(category, onPremiseCost),
          tco: getCostByCategory(category, tcoCost),
          cloud: getCostByCategory(category, connectionCost),
        };
      });

    return [];
  };

  /**
   * @function onChangeTotalCostDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeTotalCostDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setStartDate(
      moment(dateString[0], MONTH_YEAR_FORMAT)
        .startOf('month')
        .startOf('day')
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE)
    );
    setEndDate(
      moment(dateString[1], MONTH_YEAR_FORMAT)
        .endOf('month')
        .endOf('day')
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE)
    );
  };

  const sortArray = (itemArray: any[], sortingArray: any[]) => {
    return itemArray.sort(
      (a, b) => sortingArray.indexOf(a) - sortingArray.indexOf(b)
    );
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    if (graphName === 'total-cost') {
      return (
        <TotalCost
          startMonth={startDate}
          endMonth={endDate}
          tableData={getTableData()}
          onChangeTotalCostDateRange={onChangeTotalCostDateRange}
          totalCostRequestStatus={[
            onPremCostRequestStatus,
            connectionCostRequestStatus,
          ]}
          isTableView={isCostTableView}
          pdfView={pdfView}
          setIsTableView={setIsCostTableView}
          excelFilters={[
            {
              heading: t('tcoDashboard.category'),
              value: selectedCategories
                .map(
                  (category) =>
                    MANUAL_ENTRY_NAVIGATION.find((item) => item.id === category)
                      ?.title ?? category
                )
                .join(', '),
            },
            [
              ...(selectedTco
                ? [
                    {
                      heading: t('tcoDashboard.connection'),
                      value: selectedConnections
                        .map(
                          (connection) =>
                            selectedTco?.connectorDetailsList.find(
                              (item) => item.connectorId === connection
                            )?.connectorName ?? ''
                        )
                        .join(', '),
                    },
                  ]
                : []),
            ],
          ]}
        />
      );
    }
  };

  const getPdfContentData = () => {
    return {
      costCardsData: getCostComparisonCards(),
    };
  };

  const getPdfMetaData = () => {
    return {
      viewName: t('tcoDashboard.horizontalNavMenu.costSummary'),
      fileName: selectedOnPremise?.name ?? selectedTco?.name ?? '',
      heading: selectedOnPremise?.name ?? selectedTco?.name ?? '',
      subtitle1:
        selectedOnPremise?.dataCenterCode ?? selectedTco?.wbsCode ?? '',
    };
  };

  const getCostComparisonCards = () =>
    costComparisonList.map((item, index) => {
      return (
        <ComparisonCard
          index={index}
          key={item.heading}
          value={item.value}
          heading={item.heading}
          valuePrefix={currencySymbol}
          comparisonValue={item.comparisonValue}
          comparisonFrom={item.comparisonFrom}
          requestStatus={item.requestStatus}
        />
      );
    });

  return (
    <div className="tco-cost-summary">
      <ControlComponent
        filters={[
          {
            title: t('tcoDashboard.category'),
            filter: (
              <DropdownCheckbox
                itemOptions={MANUAL_ENTRY_NAVIGATION.map((category) => ({
                  value: category.id,
                  title: category.title,
                }))}
                value={selectedCategories}
                selectedItems={selectedCategories}
                setSelectedItems={(selected: string[]) => {
                  setSelectedCategories(
                    sortArray(
                      selected,
                      MANUAL_ENTRY_NAVIGATION.map((item) => item.id.valueOf())
                    )
                  );
                }}
                designVersion2
                size={INPUT_SIZE.SMALL}
                dataTestId="project-filter"
              />
            ),
            minimizedText:
              MANUAL_ENTRY_NAVIGATION.filter((item) =>
                selectedCategories.includes(item.id)
              )
                .map((item) => item.title)
                .join(', ')
                .substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...',
            onClear: () => setSelectedCategories([]),
          },
          ...(selectedTco && selectedTco.connectorsList?.length > 0
            ? [
                {
                  title: t('tcoDashboard.connection'),
                  filter: (
                    <DropdownCheckbox
                      itemOptions={selectedTco.connectorDetailsList.map(
                        (connection) => ({
                          value: connection.connectorId,
                          title: connection.connectorName,
                        })
                      )}
                      value={selectedConnections}
                      selectedItems={selectedConnections}
                      setSelectedItems={(selected: string[]) => {
                        setSelectedConnections(selected);
                      }}
                      designVersion2
                      size={INPUT_SIZE.SMALL}
                      dataTestId="project-filter"
                    />
                  ),
                  minimizedText:
                    selectedTco.connectorDetailsList
                      .filter((item) =>
                        selectedConnections.includes(item.connectorId)
                      )
                      .map((item) => item.connectorName)
                      .join(', ')
                      .substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) +
                    '...',
                  onClear: () => setSelectedConnections([]),
                },
              ]
            : []),
        ]}
      />
      <div className="margin-24 flex flex-column flex-gap-16">
        <div className="cost-cards flex flex-space-between">
          {getCostComparisonCards()}
        </div>
        {getGraphComponent('total-cost')}
      </div>
      <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData()}
          pdfContent={[
            {
              element: getGraphComponent('total-cost', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'total-cost',
              isTableView: isCostTableView,
              column: modifyToExportColumns(getTcoColumns(!!selectedOnPremise)),
              body: getTableData().map((value: any, index) => {
                return {
                  ...value,
                  index: index + 1,
                  onPremise: `${currencySymbol} ${numberCommaSeparator(
                    value.onPremise
                  )}`,
                  tco: `${currencySymbol} ${numberCommaSeparator(value.tco)}`,
                  cloud: `${currencySymbol} ${numberCommaSeparator(
                    value.cloud
                  )}`,
                };
              }),
              tableName: t('graphHeadings.totalCost'),
            },
          ]}
          pdfCardData={getPdfContentData()}
        />
      )}
    </div>
  );
};

export default TcoCostSummary;
