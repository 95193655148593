import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import AreaChart from 'components/AreaChart';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { CARBON_EMISSION_UNIT } from 'pages/OverviewPage/components/ConnectionsDashboard/components/CarbonFootprintDashboard/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CarbonFootprintByMonth } from 'pages/OverviewPage/components/ConnectionsDashboard/components/CarbonFootprintDashboard/types';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { selectDashboard } from 'redux/dashboardSlice';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectTheme } from 'redux/themeSlice';
import { getGCPMonthlyCarbonEmissionExcelData } from '../../utils/exportToExcel';

type MonthlyCarbonEmissionProps = {
  monthlyCarbonEmissionRequestStatus: string;
  monthlyCarbonEmissionTableView: boolean;
  setMonthlyCarbonEmissionTableView: (value: boolean) => void;
  monthlyCarbonEmissionData: CarbonFootprintByMonth[];
  pdfView: boolean;
  carbonEmissionEndMonth: string;
  carbonEmissionStartMonth: string;
  onChangeMonthlyEmissionDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

const MonthlyCarbonEmission = ({
  monthlyCarbonEmissionRequestStatus,
  monthlyCarbonEmissionTableView,
  setMonthlyCarbonEmissionTableView,
  monthlyCarbonEmissionData,
  pdfView,
  carbonEmissionEndMonth,
  carbonEmissionStartMonth,
  onChangeMonthlyEmissionDateRange,
}: MonthlyCarbonEmissionProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { theme } = useSelector(selectTheme);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 30,
    },
    {
      title: t('carbonFootprint.monthlyCarbonEmission.month'),
      dataIndex: 'month',
      key: 'month',
      width: '30%',
    },
    {
      title: t('carbonFootprint.monthlyCarbonEmission.carbonEmissionKgco2e'),
      dataIndex: 'carbonFootprint',
      key: 'carbonFootprint',
      align: 'right' as const,
      width: '70%',
      render: (text: any) => numberCommaSeparator(text || 0),
    },
  ];

  const filters = (
    <div className="filters">
      <DatePicker
        defaultValue={[
          moment(carbonEmissionStartMonth),
          moment(carbonEmissionEndMonth),
        ]}
        onChange={onChangeMonthlyEmissionDateRange}
        disabledDate={(current: any) =>
          current > moment().subtract(1, 'month').endOf('day')
        }
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  const getComponent = () => (
    <div
      className={`graph ${
        monthlyCarbonEmissionTableView && 'flex flex-gap-16'
      }`}
    >
      <div
        className={`graph-area ${
          monthlyCarbonEmissionTableView && 'with-table expand-hide'
        }`}
      >
        <AreaChart
          data={monthlyCarbonEmissionData.map((item) => ({
            ...item,
            groupingField: t(
              'carbonFootprint.monthlyCarbonEmission.carbonEmission'
            ),
          }))}
          xField="month"
          yField="carbonFootprint"
          groupingField="groupingField"
          xTitle={t('carbonFootprint.monthlyCarbonEmission.month')}
          yTitle={CARBON_EMISSION_UNIT}
          flipPage={!pdfView}
          disableAnimation={pdfView}
          suffixSymbol={CARBON_EMISSION_UNIT}
          colorOverride={{
            [t('carbonFootprint.monthlyCarbonEmission.carbonEmission')]:
              theme.primaryColor,
          }}
        />
      </div>
      {monthlyCarbonEmissionTableView && (
        <div className="tabular-view">
          <Table
            pagination={false}
            loading={monthlyCarbonEmissionRequestStatus.includes(
              REQUEST_STATUS.PROCESSING
            )}
            dataSource={monthlyCarbonEmissionData.map((data, index) => ({
              ...data,
              key: index,
            }))}
            columns={columns}
            scroll={{ y: 190 }}
            designVersion2
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="graph-card monthly-carbon-emission" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.grossMonthlyCarbonEmissions')}
        graphName="monthly-carbon-emission"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        isTableView={monthlyCarbonEmissionTableView}
        setIsTableView={setMonthlyCarbonEmissionTableView}
        excelData={getGCPMonthlyCarbonEmissionExcelData(
          selectedDashboard?.connectorName ?? '',
          monthlyCarbonEmissionData
        )}
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={monthlyCarbonEmissionRequestStatus}
      />
    </div>
  );
};

export default MonthlyCarbonEmission;
