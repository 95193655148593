import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import {
  getValidationStyle,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import Modal from 'components/Modal';

import './index.scss';

type ViewNameModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  viewName: string;
  setViewName: (val: string) => void;
  onClickCreate: () => void;
  loading: boolean;
};

const ViewNameModal = ({
  show,
  setShow,
  viewName,
  setViewName,
  onClickCreate,
  loading,
}: ViewNameModalProps) => {
  const { t } = useTranslation();
  const VIEW_NAME_INPUT_LENGTH = 20;

  const [viewNameValidationMessage, setViewNameValidationMessage] =
    useState('');

  const validateViewName = (value: string) => {
    setViewNameValidationMessage('');
    setViewName(value);
    if (
      validateEmptyField(
        value,
        t('costOptimizationInsight.tagsModal.recommendationViewName'),
        setViewNameValidationMessage
      )
    ) {
      return false;
    }

    if (validateStringLengthLessThan(value, VIEW_NAME_INPUT_LENGTH)) {
      setViewNameValidationMessage(
        t('costOptimizationInsight.tagsModal.viewNameLengthValidationMessage')
      );
      return false;
    }
    return true;
  };

  const onClickCta = () => {
    if (!validateViewName(viewName)) {
      return;
    }

    onClickCreate();
  };

  return (
    <Modal
      className="view-name-modal"
      visible={show}
      title={t('costOptimizationInsight.tagsModal.viewModalHeading')}
      onCancel={() => setShow(false)}
      footer={null}
      closeIcon={<Icon iconName={ICONS.CLOSE_FILL} dataTestId="close-cta" />}
      data-testid="view-name-modal"
    >
      <div className="view-name-body flex flex-column">
        <div className="view-name-form flex flex-column">
          <div className="form-item flex flex-column">
            <FormLabel
              title={t(
                'costOptimizationInsight.tagsModal.recommendationViewName'
              )}
              required={true}
            />
            <Input
              autoFocus
              placeholder={t(
                'costOptimizationInsight.tagsModal.recommendationViewNamePlaceholder'
              )}
              value={viewName}
              onChange={(e: any) => validateViewName(e.target.value)}
              onBlur={(e: any) =>
                validateEmptyField(
                  e.target.value,
                  t('costOptimizationInsight.tagsModal.recommendationViewName'),
                  setViewNameValidationMessage
                )
              }
            />
            <span
              style={{
                display: `${getValidationStyle(viewNameValidationMessage)}`,
              }}
              className="font-validation-error"
            >
              {viewNameValidationMessage}
            </span>
          </div>
        </div>
        <Button
          title={t('costOptimizationInsight.tagsModal.create')}
          onClick={onClickCta}
          loading={loading}
        />
      </div>
    </Modal>
  );
};

export default ViewNameModal;
