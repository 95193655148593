import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CmdbConfigurationSteps } from 'pages/CmdbConfigurationPage/constants';
import { CMDBListType } from 'pages/CmdbSetupListPage/types';

import { RootState } from './store';

export interface CmdbSetupSlice {
  cmdbConfigurationStep: number;
  cmdbSetupConnection: CMDBListType | null;
  isEditConnection: boolean;
}

const initialState: CmdbSetupSlice = {
  cmdbConfigurationStep: CmdbConfigurationSteps.BASIC_INFORMATION,
  cmdbSetupConnection: null,
  isEditConnection: false,
};

export const cmdbSetupSlice = createSlice({
  name: 'cmdbSetup',
  initialState,
  reducers: {
    setCmdbConfigurationStep: (state, action: PayloadAction<number>) => {
      state.cmdbConfigurationStep = action.payload;
    },
    setCmdbSetupConnection: (
      state,
      action: PayloadAction<CMDBListType | null>
    ) => {
      state.cmdbSetupConnection = action.payload;
    },
    setIsEditConnection: (state, action: PayloadAction<boolean>) => {
      state.isEditConnection = action.payload;
    },
  },
});

export const cmdbSetup = (state: RootState) => state.cmdbSetup;
export const {
  setCmdbConfigurationStep,
  setCmdbSetupConnection,
  setIsEditConnection,
} = cmdbSetupSlice.actions;
export default cmdbSetupSlice.reducer;
