import { RecommendationList } from '../../types';

/**
 * @function getColumnsWithCellActions
 * @description Function to get the list of columns with cell actions added
 * @param columns Columns for which the cell actions to be added
 * @param columnsDataIndexWithoutCellClick List of dataIndex for which the cell action is excluded
 * @param onCell Callback for cell action events
 * @returns List of columns
 */
export const getColumnsWithCellActions = (
  columns: any[],
  columnsDataIndexWithoutCellClick: string[],
  onCell: (record: RecommendationList) => Object
) => {
  return columns.map((column) => {
    if (columnsDataIndexWithoutCellClick.includes(column.dataIndex)) {
      return column;
    }

    return {
      ...column,
      onCell: onCell,
    };
  });
};
