import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectScorecard,
  setSelectedPurchaseTacticsTab,
} from 'redux/scorecardSlice';
import { PURCHASE_TACTICS_TAB } from 'pages/ScorecardPage/constants';
import { PROVIDER } from 'constants/cloudProviders';

import SavingsEffectivenessScorecardDashboard from './components/SavingsEffectivenessScorecardDashboard';
import CommittedUsageDiscount from './components/CommittedUsageDiscount';
import ReservedInstance from './components/ReservedInstance';
import SavingsPlan from './components/SavingsPlan';

const PurchaseTactics = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, selectedPurchaseTacticsTab } =
    useSelector(selectScorecard);

  useEffect(() => {
    if (
      !getPurchaseTacticsTabs()?.some(
        (item) => item.key === selectedPurchaseTacticsTab
      )
    ) {
      dispatch(
        setSelectedPurchaseTacticsTab(
          PURCHASE_TACTICS_TAB.SAVINGS_EFFECTIVENESS
        )
      );
    }
  }, [selectedConnection]);

  const getPurchaseTacticsTabs = () => {
    if (selectedConnection?.provider === PROVIDER.GCP) {
      return [
        {
          label: t('scorecard.purchaseTactics.tabs.savingsEffectiveness'),
          key: PURCHASE_TACTICS_TAB.SAVINGS_EFFECTIVENESS,
          children: <SavingsEffectivenessScorecardDashboard />,
        },
        {
          label: t('scorecard.purchaseTactics.tabs.committedUsageDiscount'),
          key: PURCHASE_TACTICS_TAB.COMMITTED_USAGE_DISCOUNT,
          children: <CommittedUsageDiscount />,
        },
      ];
    }

    if (selectedConnection?.provider === PROVIDER.AWS) {
      return [
        {
          label: t('scorecard.purchaseTactics.tabs.savingsEffectiveness'),
          key: PURCHASE_TACTICS_TAB.SAVINGS_EFFECTIVENESS,
          children: <SavingsEffectivenessScorecardDashboard />,
        },
        {
          label: t('scorecard.purchaseTactics.tabs.reservedInstance'),
          key: PURCHASE_TACTICS_TAB.RESERVED_INSTANCE,
          children: <ReservedInstance />,
        },
        {
          label: t('scorecard.purchaseTactics.tabs.savingsPlan'),
          key: PURCHASE_TACTICS_TAB.SAVINGS_PLAN,
          children: <SavingsPlan />,
        },
      ];
    }
  };

  return (
    <div>
      <Tabs
        type="card"
        activeKey={selectedPurchaseTacticsTab}
        items={getPurchaseTacticsTabs()}
        onChange={function (activeKey) {
          dispatch(setSelectedPurchaseTacticsTab(activeKey));
        }}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default PurchaseTactics;
