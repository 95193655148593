import { ValueLabelType } from 'types/dashboard';

import { COMPARATORS_LIST } from '../FiltersModal/components/CostSavingsInput/components/FilterFields/constants';

/**
 * @function getDisplayTextForCostSavings
 * @description Function to return the display string for the cost savings filter
 * @param data The cost savings filter value
 * @param addConjunction Boolean value to indicate whether to add the conjunction to the display string or not
 * @returns String containing the display text
 */
export const getDisplayTextForCostSavings = (
  data: ValueLabelType,
  addConjunction: boolean
) => {
  const dataList = data.value.split(',');
  let displayString =
    COMPARATORS_LIST.find((comp) => comp.symbol === dataList[0])?.label +
    ' ' +
    dataList[1];

  if (addConjunction) {
    displayString = displayString + ' ' + dataList[2];
  }
  return displayString;
};
