import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import moment from 'moment';
import { sumBy, uniqBy } from 'lodash';

import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import TagFilters from 'components/TagFilters';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { getChartData } from 'utils/services';
import {
  addZeroMarginClass,
  getBillingPeriodDateFormatByProvider,
  isDashboardWithStaticData,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { DualAxisChartData } from 'types/dashboard';
import {
  DATE_FORMAT,
  DATE_TIME_AM_PM,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  enumerateDaysBetweenDates,
  getMonthYearShortList,
} from 'utils/date';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import { insertIndex } from 'utils/dataFormatterUtils';
import { modifyToExportColumns } from 'utils/exportToExcel';
import { OTHERS_LABEL } from 'constants/graphLabels';

import {
  getComputeExcelData,
  getDailyElasticityByPurchaseOptionQuery,
  getDailyElasticityByPurchaseOptionColumns,
  getDailyCostByInstanceFamilyQuery,
  getDailyCostByInstanceFamilyColumns,
  getTotalAmortizedCostByAccountsQuery,
  getMonthlyCostByAccountsColumns,
  getTotalAmortizedCostByRegionsQuery,
  getCostByServiceProductFamilyQuery,
  getCostByDatabaseEnginesQuery,
  getTotalAmortizedCostByAccountsLabelByProvider,
  getCostByServiceProductLabelByProvider,
  getProductTypeLabelByProvider,
  hasCostByDatabaseEngineChart,
  getDailyCostByInstanceFamilyLabelByProvider,
} from './utils';
import StackedDualAxisCharts from '../StackedDualAxisCharts';
import StackedColumnLineWithUnitCostCharts from '../StackedColumnLineWithUnitCostCharts';
import {
  getAccountLabelByProvider,
  getDualAxisCostByTypeTableData,
  getUsageCostTableData,
} from '../../utils';
import { UnitCostTimePeriod } from '../../constants';
import ServiceViewsControls from '../ServiceViewsControls';

const DatabaseDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedDashboard,
    selectedConnection,
    showExpandGraphModal,
    expandedGraphName,
    tableViewEnabled,
    pdfDownloadMode,
    tagsFilters,
    selectedDashboardView,
  } = useSelector(selectDashboard);

  // Accounts States
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [accountsReqStatus, setAccountsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  // Total Amortized Cost by Accounts
  const [totalAmortizedCostByAccounts, setTotalAmortizedCostByAccounts] =
    useState<any[]>([]);
  const [
    totalAmortizedCostByAccountsReqStatus,
    setTotalAmortizedCostByAccountsReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    totalAmortizedCostByAccountsTableView,
    setTotalAmortizedCostByAccountsTableView,
  ] = useState(false);
  const [
    totalAmortizedCostByAccountsDateRange,
    setTotalAmortizedCostByAccountsDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);
  const [
    totalAmortizedCostByAccountsUnitCostTimePeriod,
    setTotalAmortizedCostByAccountsUnitCostTimePeriod,
  ] = useState(UnitCostTimePeriod.HOURLY);

  // Total Amortized Cost by Regions
  const [totalAmortizedCostByRegions, setTotalAmortizedCostByRegions] =
    useState<DualAxisChartData>({
      leftAxisData: [],
      rightAxisData: [],
    });
  const [
    totalAmortizedCostByRegionsReqStatus,
    setTotalAmortizedCostByRegionsReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    totalAmortizedCostByRegionsTableView,
    setTotalAmortizedCostByRegionsTableView,
  ] = useState(false);
  const [
    totalAmortizedCostByRegionsDateRange,
    setTotalAmortizedCostByRegionsDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);

  // Cost by Service Product Family
  const [costByServiceProductFamily, setCostByServiceProductFamily] =
    useState<DualAxisChartData>({
      leftAxisData: [],
      rightAxisData: [],
    });
  const [
    costByServiceProductFamilyReqStatus,
    setCostByServiceProductFamilyReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    costByServiceProductFamilyTableView,
    setCostByServiceProductFamilyTableView,
  ] = useState(false);
  const [
    costByServiceProductFamilyDateRange,
    setCostByServiceProductFamilyDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);

  // Cost by Database Engines
  const [costByDatabaseEngines, setCostByDatabaseEngines] =
    useState<DualAxisChartData>({
      leftAxisData: [],
      rightAxisData: [],
    });
  const [costByDatabaseEnginesReqStatus, setCostByDatabaseEnginesReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [costByDatabaseEnginesTableView, setCostByDatabaseEnginesTableView] =
    useState(false);
  const [costByDatabaseEnginesDateRange, setCostByDatabaseEnginesDateRange] =
    useState([
      moment()
        .subtract(5, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
    ]);

  // Daily Elasticity in Normalized Hours by Purchase Option
  const [dailyElasticityByPurchaseOption, setDailyElasticityByPurchaseOption] =
    useState<DualAxisChartData>({
      leftAxisData: [],
      rightAxisData: [],
    });
  const [
    dailyElasticityByPurchaseOptionReqStatus,
    setDailyElasticityByPurchaseOptionReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    dailyElasticityByPurchaseOptionTableView,
    setDailyElasticityByPurchaseOptionTableView,
  ] = useState(false);
  const [
    dailyElasticityByPurchaseOptionDateRange,
    setDailyElasticityByPurchaseOptionDateRange,
  ] = useState([
    moment()
      .subtract(1, 'months')
      .subtract(1, 'day')
      .format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);

  // Daily Cost by Instance Family
  const [dailyCostByInstanceFamily, setDailyCostByInstanceFamily] =
    useState<DualAxisChartData>({
      leftAxisData: [],
      rightAxisData: [],
    });
  const [
    dailyCostByInstanceFamilyReqStatus,
    setDailyCostByInstanceFamilyReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    dailyCostByInstanceFamilyTableView,
    setDailyCostByInstanceFamilyTableView,
  ] = useState(false);
  const [
    dailyCostByInstanceFamilyDateRange,
    setDailyCostByInstanceFamilyDateRange,
  ] = useState([
    moment()
      .subtract(2, 'months')
      .subtract(1, 'day')
      .format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);

  useEffect(() => {
    addZeroMarginClass('dashboard-view');

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setTotalAmortizedCostByAccounts([]);
    } else {
      fetchTotalAmortizedCostByAccounts();
    }
  }, [selectedAccounts, totalAmortizedCostByAccountsDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setTotalAmortizedCostByRegions({ leftAxisData: [], rightAxisData: [] });
    } else {
      fetchTotalAmortizedCostByRegions();
    }
  }, [selectedAccounts, totalAmortizedCostByRegionsDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setCostByServiceProductFamily({ leftAxisData: [], rightAxisData: [] });
    } else {
      fetchCostByServiceProductFamily();
    }
  }, [selectedAccounts, costByServiceProductFamilyDateRange, tagsFilters]);

  useEffect(() => {
    if (
      selectedAccounts.length > 0 &&
      hasCostByDatabaseEngineChart(selectedDashboard!.connectorProvider)
    ) {
      fetchCostByDatabaseEngines();
    } else {
      setCostByDatabaseEngines({ leftAxisData: [], rightAxisData: [] });
    }
  }, [selectedAccounts, costByDatabaseEnginesDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setDailyElasticityByPurchaseOption({
        leftAxisData: [],
        rightAxisData: [],
      });
    } else {
      fetchDailyElasticityByPurchaseOption();
    }
  }, [selectedAccounts, dailyElasticityByPurchaseOptionDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setDailyCostByInstanceFamily({ leftAxisData: [], rightAxisData: [] });
    } else {
      fetchDailyCostByInstanceFamily();
    }
  }, [selectedAccounts, dailyCostByInstanceFamilyDateRange, tagsFilters]);

  useEffect(() => {
    setTotalAmortizedCostByAccountsTableView(tableViewEnabled);
    setTotalAmortizedCostByRegionsTableView(tableViewEnabled);
    setCostByServiceProductFamilyTableView(tableViewEnabled);
    setCostByDatabaseEnginesTableView(tableViewEnabled);
    setDailyElasticityByPurchaseOptionTableView(tableViewEnabled);
    setDailyCostByInstanceFamilyTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    dispatch(
      setExportToExcelData(
        getComputeExcelData(
          selectedDashboard!.connectorName,
          selectedDashboard!.connectorProvider,
          selectedAccounts,
          {
            totalAmortizedCostByAccounts: {
              sheetName: getTotalAmortizedCostByAccountsLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: totalAmortizedCostByAccounts,
              dateRange: totalAmortizedCostByAccountsDateRange,
            },
            totalAmortizedCostByRegions: {
              sheetName: t('graphHeadings.totalAmortizedCostByRegions'),
              data: totalAmortizedCostByRegions,
              dateRange: totalAmortizedCostByRegionsDateRange,
            },
            costByServiceProductFamily: {
              sheetName: getCostByServiceProductLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: costByServiceProductFamily,
              dateRange: costByServiceProductFamilyDateRange,
            },
            costByDatabaseEngines: {
              sheetName: t('graphHeadings.costByDatabaseEngine'),
              data: costByDatabaseEngines,
              dateRange: costByDatabaseEnginesDateRange,
            },
            dailyElasticityByPurchaseOptionData: {
              sheetName: t('graphHeadings.dailyElasticityByPurchaseOption'),
              data: dailyElasticityByPurchaseOption,
              dateRange: dailyElasticityByPurchaseOptionDateRange,
            },
            dailyCostByInstanceFamily: {
              sheetName: getDailyCostByInstanceFamilyLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              data: dailyCostByInstanceFamily,
              dateRange: dailyCostByInstanceFamilyDateRange,
            },
          }
        )
      )
    );
  }, [
    selectedDashboard,
    selectedAccounts,
    totalAmortizedCostByAccounts,
    totalAmortizedCostByAccountsDateRange,
    totalAmortizedCostByRegions,
    totalAmortizedCostByRegionsDateRange,
    costByServiceProductFamily,
    costByServiceProductFamilyDateRange,
    costByDatabaseEngines,
    costByDatabaseEnginesDateRange,
    dailyElasticityByPurchaseOption,
    dailyElasticityByPurchaseOptionDateRange,
    dailyCostByInstanceFamily,
    dailyCostByInstanceFamilyDateRange,
  ]);

  /**
   * @function fetchTotalAmortizedCostByAccounts
   * @description Function to fetch the Total Amortized Cost by Accounts
   */
  const fetchTotalAmortizedCostByAccounts = () => {
    setTotalAmortizedCostByAccountsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getTotalAmortizedCostByAccountsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      totalAmortizedCostByAccountsDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
            account: item.account ? item.account : OTHERS_LABEL,
            [UnitCostTimePeriod.DAILY]: Number(item[UnitCostTimePeriod.DAILY]),
            [UnitCostTimePeriod.HOURLY]: Number(
              item[UnitCostTimePeriod.HOURLY]
            ),
            [UnitCostTimePeriod.WEEKLY]: Number(
              item[UnitCostTimePeriod.WEEKLY]
            ),
          })) ?? [];
        const accounts = uniqBy(data, 'account')
          .map((item) => item.account)
          .reverse();
        const months = getMonthYearShortList(
          totalAmortizedCostByAccountsDateRange[0],
          totalAmortizedCostByAccountsDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          const monthData = data.filter(
            (item) =>
              item.month ===
              moment(month, MONTH_YEAR_FORMAT).format(
                getBillingPeriodDateFormatByProvider(
                  selectedDashboard!.connectorProvider
                )
              )
          );
          accounts.forEach((account) => {
            const dataItem = monthData.find((item) => item.account === account);
            formattedData.push({
              time: month,
              type: account,
              value: dataItem?.cost ?? 0,
              [UnitCostTimePeriod.DAILY]:
                dataItem?.[UnitCostTimePeriod.DAILY] ?? 0,
              [UnitCostTimePeriod.HOURLY]:
                dataItem?.[UnitCostTimePeriod.HOURLY] ?? 0,
              [UnitCostTimePeriod.WEEKLY]:
                dataItem?.[UnitCostTimePeriod.WEEKLY] ?? 0,
              unitCostType: 'Average Unit Cost - ' + account,
            });
          });
        });
        setTotalAmortizedCostByAccounts(formattedData);
        setTotalAmortizedCostByAccountsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTotalAmortizedCostByAccountsReqStatus);
      });
  };

  /**
   * @function fetchTotalAmortizedCostByRegions
   * @description Function to fetch the Total Amortized Cost by Regions
   */
  const fetchTotalAmortizedCostByRegions = () => {
    setTotalAmortizedCostByRegionsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getTotalAmortizedCostByRegionsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      totalAmortizedCostByRegionsDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
            region: item.region ? item.region : OTHERS_LABEL,
          })) ?? [];
        const regions = uniqBy(data, 'region')
          .map((item) => item.region)
          .reverse();
        const months = getMonthYearShortList(
          totalAmortizedCostByRegionsDateRange[0],
          totalAmortizedCostByRegionsDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedLeftAxisData: any[] = [];
        const formattedRightAxisData: any[] = [];
        months.forEach((month) => {
          const monthData = data.filter(
            (item) =>
              item.month ===
              moment(month, MONTH_YEAR_FORMAT).format(
                getBillingPeriodDateFormatByProvider(
                  selectedDashboard!.connectorProvider
                )
              )
          );
          formattedRightAxisData.push({
            time: month,
            type: t('awsDatabaseDashboard.costSum'),
            value: sumBy(monthData, 'cost'),
          });
          regions.forEach((region) => {
            const dataItem = monthData.find((item) => item.region === region);
            formattedLeftAxisData.push({
              time: month,
              type: region,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setTotalAmortizedCostByRegions({
          leftAxisData: formattedLeftAxisData,
          rightAxisData: formattedRightAxisData,
        });
        setTotalAmortizedCostByRegionsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTotalAmortizedCostByRegionsReqStatus);
      });
  };

  /**
   * @function fetchCostByServiceProductFamily
   * @description Function to fetch the Cost by Service Product Family
   */
  const fetchCostByServiceProductFamily = () => {
    setCostByServiceProductFamilyReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getCostByServiceProductFamilyQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      costByServiceProductFamilyDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
            productFamily: item.productFamily
              ? item.productFamily
              : OTHERS_LABEL,
          })) ?? [];
        const serviceProductFamilies = uniqBy(data, 'productFamily')
          .map((item) => item.productFamily)
          .reverse();
        const months = getMonthYearShortList(
          costByServiceProductFamilyDateRange[0],
          costByServiceProductFamilyDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedLeftAxisData: any[] = [];
        const formattedRightAxisData: any[] = [];
        months.forEach((month) => {
          const monthData = data.filter(
            (item) =>
              item.month ===
              moment(month, MONTH_YEAR_FORMAT).format(
                getBillingPeriodDateFormatByProvider(
                  selectedDashboard!.connectorProvider
                )
              )
          );
          formattedRightAxisData.push({
            time: month,
            type: t('awsDatabaseDashboard.costSum'),
            value: sumBy(monthData, 'cost'),
          });
          serviceProductFamilies.forEach((family) => {
            const dataItem = monthData.find(
              (item) => item.productFamily === family
            );
            formattedLeftAxisData.push({
              time: month,
              type: family,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setCostByServiceProductFamily({
          leftAxisData: formattedLeftAxisData,
          rightAxisData: formattedRightAxisData,
        });
        setCostByServiceProductFamilyReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostByServiceProductFamilyReqStatus);
      });
  };

  /**
   * @function fetchCostByDatabaseEngines
   * @description Function to fetch the Cost by Database Engines
   */
  const fetchCostByDatabaseEngines = () => {
    setCostByDatabaseEnginesReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getCostByDatabaseEnginesQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      costByDatabaseEnginesDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
            databaseEngine: item.databaseEngine
              ? item.databaseEngine
              : OTHERS_LABEL,
          })) ?? [];
        const databaseEngines = uniqBy(data, 'databaseEngine')
          .map((item) => item.databaseEngine)
          .reverse();
        const months = getMonthYearShortList(
          costByDatabaseEnginesDateRange[0],
          costByDatabaseEnginesDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedLeftAxisData: any[] = [];
        const formattedRightAxisData: any[] = [];
        months.forEach((month) => {
          const monthData = data.filter(
            (item) =>
              item.month ===
              moment(month, MONTH_YEAR_FORMAT).format(
                getBillingPeriodDateFormatByProvider(
                  selectedDashboard!.connectorProvider
                )
              )
          );
          formattedRightAxisData.push({
            time: month,
            type: t('awsDatabaseDashboard.costSum'),
            value: sumBy(monthData, 'cost'),
          });
          databaseEngines.forEach((engine) => {
            const dataItem = monthData.find(
              (item) => item.databaseEngine === engine
            );
            formattedLeftAxisData.push({
              time: month,
              type: engine,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setCostByDatabaseEngines({
          leftAxisData: formattedLeftAxisData,
          rightAxisData: formattedRightAxisData,
        });
        setCostByDatabaseEnginesReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostByDatabaseEnginesReqStatus);
      });
  };

  /**
   * @function fetchDailyElasticityByPurchaseOption
   * @description Function to fetch the Daily Elasticity by Purchase Option
   */
  const fetchDailyElasticityByPurchaseOption = () => {
    setDailyElasticityByPurchaseOptionReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getDailyElasticityByPurchaseOptionQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      dailyElasticityByPurchaseOptionDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_TIME_AM_PM),
            cost: Number(item.cost),
          })) ?? [];
        const pricingTerms = uniqBy(data, 'pricingTerm').map(
          (item) => item.pricingTerm
        );
        const timeList = enumerateDaysBetweenDates(
          dailyElasticityByPurchaseOptionDateRange
        );

        const formattedLeftAxisData: any[] = [];
        const formattedRightAxisData: any[] = [];
        timeList.forEach((time) => {
          const currentTimeData = data.filter((item) => time === item.time);
          formattedRightAxisData.push({
            time: time,
            type: t('awsDatabaseDashboard.costSum'),
            value: sumBy(currentTimeData, 'cost'),
          });
          pricingTerms.forEach((term) => {
            const dataItem = currentTimeData.find(
              (item) => item.pricingTerm === term
            );
            formattedLeftAxisData.push({
              time: time,
              type: term,
              value: dataItem?.cost ?? 0,
            });
          });
        });

        setDailyElasticityByPurchaseOption({
          leftAxisData: formattedLeftAxisData,
          rightAxisData: formattedRightAxisData,
        });
        setDailyElasticityByPurchaseOptionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setDailyElasticityByPurchaseOptionReqStatus);
      });
  };

  /**
   * @function fetchDailyCostByInstanceFamily
   * @description Function to fetch the Daily Cost by Instance Family
   */
  const fetchDailyCostByInstanceFamily = () => {
    setDailyCostByInstanceFamilyReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getDailyCostByInstanceFamilyQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      dailyCostByInstanceFamilyDateRange
    );

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
            instanceFamily: item.instanceFamily
              ? item.instanceFamily
              : OTHERS_LABEL,
          })) ?? [];
        const instanceFamilies = uniqBy(data, 'instanceFamily')
          .map((item) => item.instanceFamily)
          .reverse();
        const datesList = enumerateDaysBetweenDates(
          dailyCostByInstanceFamilyDateRange,
          DATE_FORMAT,
          'day'
        );

        const formattedLeftAxisData: any[] = [];
        const formattedRightAxisData: any[] = [];
        datesList.forEach((date) => {
          const currentDateData = data.filter((item) => date === item.time);
          formattedRightAxisData.push({
            time: date,
            type: t('awsDatabaseDashboard.costSum'),
            value: sumBy(currentDateData, 'cost'),
          });
          instanceFamilies.forEach((family) => {
            const dataItem = currentDateData.find(
              (item) => item.instanceFamily === family
            );
            formattedLeftAxisData.push({
              time: date,
              type: family,
              value: dataItem?.cost ?? 0,
            });
          });
        });

        setDailyCostByInstanceFamily({
          leftAxisData: formattedLeftAxisData,
          rightAxisData: formattedRightAxisData,
        });
        setDailyCostByInstanceFamilyReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setDailyCostByInstanceFamilyReqStatus);
      });
  };

  /**
   * @function getComputePdfMetaData
   * @description Function to return the meta data for pdf export
   * @returns Object containing the pdf meta data
   */
  const getComputePdfMetaData = () => {
    return {
      viewName: t('dashNav.compute'),
      fileName: selectedDashboard?.name!,
      heading: selectedDashboard?.name!,
      subtitle1: selectedDashboard?.connectorName!,
      subtitle2: selectedConnection?.dataSourceType!,
      provider: selectedDashboard?.connectorProvider!,
    };
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'total-amortized-cost-by-accounts':
        return (
          <StackedColumnLineWithUnitCostCharts
            title={getTotalAmortizedCostByAccountsLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={totalAmortizedCostByAccounts}
            requestStatus={[
              accountsReqStatus,
              totalAmortizedCostByAccountsReqStatus,
            ]}
            isTableView={totalAmortizedCostByAccountsTableView}
            setIsTableView={setTotalAmortizedCostByAccountsTableView}
            dateRange={totalAmortizedCostByAccountsDateRange}
            setDateRange={setTotalAmortizedCostByAccountsDateRange}
            pdfView={pdfView}
            columns={getMonthlyCostByAccountsColumns(
              totalAmortizedCostByAccountsDateRange,
              getAccountLabelByProvider(selectedDashboard!.connectorProvider)
            )}
            tableData={getUsageCostTableData(
              totalAmortizedCostByAccounts,
              'time'
            )}
            selectedAccounts={selectedAccounts}
            unitCostTimePeriod={totalAmortizedCostByAccountsUnitCostTimePeriod}
            setUnitCostTimePeriod={
              setTotalAmortizedCostByAccountsUnitCostTimePeriod
            }
            xField="type"
          />
        );

      case 'total-amortized-cost-by-regions':
        return (
          <StackedDualAxisCharts
            title={t('graphHeadings.totalAmortizedCostByRegions')}
            graph={graphName}
            data={totalAmortizedCostByRegions}
            xTitle={t('awsDatabaseDashboard.month')}
            leftYTitle={t('awsDatabaseDashboard.cost')}
            syncYAxis={true}
            requestStatus={[
              accountsReqStatus,
              totalAmortizedCostByRegionsReqStatus,
            ]}
            isTableView={totalAmortizedCostByRegionsTableView}
            setIsTableView={setTotalAmortizedCostByRegionsTableView}
            dateRange={totalAmortizedCostByRegionsDateRange}
            setDateRange={setTotalAmortizedCostByRegionsDateRange}
            pdfView={pdfView}
            columns={getMonthlyCostByAccountsColumns(
              totalAmortizedCostByRegionsDateRange,
              t('awsDatabaseDashboard.region')
            )}
            tableData={getDualAxisCostByTypeTableData(
              totalAmortizedCostByRegions
            )}
            selectedAccounts={selectedAccounts}
          />
        );

      case 'cost-by-service-product-family':
        return (
          <StackedDualAxisCharts
            title={getCostByServiceProductLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={costByServiceProductFamily}
            xTitle={t('awsDatabaseDashboard.month')}
            leftYTitle={t('awsDatabaseDashboard.cost')}
            syncYAxis={true}
            requestStatus={[
              accountsReqStatus,
              costByServiceProductFamilyReqStatus,
            ]}
            isTableView={costByServiceProductFamilyTableView}
            setIsTableView={setCostByServiceProductFamilyTableView}
            dateRange={costByServiceProductFamilyDateRange}
            setDateRange={setCostByServiceProductFamilyDateRange}
            pdfView={pdfView}
            columns={getMonthlyCostByAccountsColumns(
              costByServiceProductFamilyDateRange,
              getProductTypeLabelByProvider(
                selectedDashboard!.connectorProvider
              )
            )}
            tableData={getDualAxisCostByTypeTableData(
              costByServiceProductFamily
            )}
            selectedAccounts={selectedAccounts}
          />
        );

      case 'cost-by-database-engines':
        return (
          <StackedDualAxisCharts
            title={t('graphHeadings.costByDatabaseEngine')}
            graph={graphName}
            data={costByDatabaseEngines}
            xTitle={t('awsDatabaseDashboard.month')}
            leftYTitle={t('awsDatabaseDashboard.cost')}
            syncYAxis={true}
            requestStatus={[accountsReqStatus, costByDatabaseEnginesReqStatus]}
            isTableView={costByDatabaseEnginesTableView}
            setIsTableView={setCostByDatabaseEnginesTableView}
            dateRange={costByDatabaseEnginesDateRange}
            setDateRange={setCostByDatabaseEnginesDateRange}
            pdfView={pdfView}
            columns={getMonthlyCostByAccountsColumns(
              costByDatabaseEnginesDateRange,
              t('awsDatabaseDashboard.databaseEngine')
            )}
            tableData={getDualAxisCostByTypeTableData(costByDatabaseEngines)}
            selectedAccounts={selectedAccounts}
          />
        );

      case 'daily-elasticity-by-purchase-option':
        return (
          <StackedDualAxisCharts
            title={t('graphHeadings.dailyElasticityByPurchaseOption')}
            graph={graphName}
            data={dailyElasticityByPurchaseOption}
            xTitle={t('awsDatabaseDashboard.time')}
            leftYTitle={t('awsDatabaseDashboard.cost')}
            syncYAxis={true}
            requestStatus={[
              accountsReqStatus,
              dailyElasticityByPurchaseOptionReqStatus,
            ]}
            isTableView={dailyElasticityByPurchaseOptionTableView}
            setIsTableView={setDailyElasticityByPurchaseOptionTableView}
            dateRange={dailyElasticityByPurchaseOptionDateRange}
            setDateRange={setDailyElasticityByPurchaseOptionDateRange}
            pdfView={pdfView}
            columns={getDailyElasticityByPurchaseOptionColumns(
              dailyElasticityByPurchaseOptionDateRange
            )}
            tableData={getDualAxisCostByTypeTableData(
              dailyElasticityByPurchaseOption
            )}
            selectedAccounts={selectedAccounts}
            showSlider={true}
            maxMonths={3}
          />
        );

      case 'daily-cost-by-instance-family':
        return (
          <StackedDualAxisCharts
            title={getDailyCostByInstanceFamilyLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={dailyCostByInstanceFamily}
            xTitle={t('awsDatabaseDashboard.date')}
            leftYTitle={t('awsDatabaseDashboard.cost')}
            syncYAxis={true}
            requestStatus={[
              accountsReqStatus,
              dailyCostByInstanceFamilyReqStatus,
            ]}
            isTableView={dailyCostByInstanceFamilyTableView}
            setIsTableView={setDailyCostByInstanceFamilyTableView}
            dateRange={dailyCostByInstanceFamilyDateRange}
            setDateRange={setDailyCostByInstanceFamilyDateRange}
            pdfView={pdfView}
            columns={getDailyCostByInstanceFamilyColumns(
              dailyCostByInstanceFamilyDateRange,
              selectedDashboard!.connectorProvider
            )}
            tableData={getDualAxisCostByTypeTableData(
              dailyCostByInstanceFamily
            )}
            selectedAccounts={selectedAccounts}
            maxMonths={3}
          />
        );
    }
  };

  return (
    <div>
      <ServiceViewsControls
        accountsProps={{
          selectedAccounts,
          setSelectedAccounts,
          accountsReqStatus,
          setAccountsReqStatus,
        }}
      />
      <div className="aws-compute-container inner-dashboard-content flex flex-column flex-gap-24">
        <TagFilters />
        <Row gutter={24}>
          <Col span={12}>
            {getGraphComponent('total-amortized-cost-by-accounts')}
          </Col>
          <Col span={12}>
            {getGraphComponent('total-amortized-cost-by-regions')}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            span={
              hasCostByDatabaseEngineChart(selectedDashboard!.connectorProvider)
                ? 12
                : 24
            }
          >
            {getGraphComponent('cost-by-service-product-family')}
          </Col>
          {hasCostByDatabaseEngineChart(
            selectedDashboard!.connectorProvider
          ) && (
            <Col span={12}>{getGraphComponent('cost-by-database-engines')}</Col>
          )}
        </Row>
        {getGraphComponent('daily-elasticity-by-purchase-option')}
        {getGraphComponent('daily-cost-by-instance-family')}
      </div>
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getComputePdfMetaData()}
          pdfContent={[
            {
              element: getGraphComponent(
                'total-amortized-cost-by-accounts',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'total-amortized-cost-by-accounts',
              column: modifyToExportColumns(
                getMonthlyCostByAccountsColumns(
                  totalAmortizedCostByAccountsDateRange,
                  getAccountLabelByProvider(
                    selectedDashboard!.connectorProvider
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(totalAmortizedCostByAccounts, 'time')
              ),
              tableName: getTotalAmortizedCostByAccountsLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: totalAmortizedCostByAccountsTableView,
            },
            {
              element: getGraphComponent(
                'total-amortized-cost-by-regions',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'total-amortized-cost-by-regions',
              column: modifyToExportColumns(
                getMonthlyCostByAccountsColumns(
                  totalAmortizedCostByRegionsDateRange,
                  t('awsDatabaseDashboard.region')
                )
              ),
              body: insertIndex(
                getDualAxisCostByTypeTableData(totalAmortizedCostByRegions)
              ),
              tableName: t('graphHeadings.totalAmortizedCostByRegions'),
              isTableView: totalAmortizedCostByRegionsTableView,
            },
            {
              element: getGraphComponent(
                'cost-by-service-product-family',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'cost-by-service-product-family',
              column: modifyToExportColumns(
                getMonthlyCostByAccountsColumns(
                  costByServiceProductFamilyDateRange,
                  getAccountLabelByProvider(
                    selectedDashboard!.connectorProvider
                  )
                )
              ),
              body: insertIndex(
                getDualAxisCostByTypeTableData(costByServiceProductFamily)
              ),
              tableName: getCostByServiceProductLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: costByServiceProductFamilyTableView,
            },
            {
              element: getGraphComponent('cost-by-database-engines', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'cost-by-database-engines',
              column: modifyToExportColumns(
                getMonthlyCostByAccountsColumns(
                  costByDatabaseEnginesDateRange,
                  t('awsDatabaseDashboard.databaseEngine')
                )
              ),
              body: insertIndex(
                getDualAxisCostByTypeTableData(costByDatabaseEngines)
              ),
              tableName: t('graphHeadings.costByDatabaseEngine'),
              isTableView: costByDatabaseEnginesTableView,
              excludeInGlobalDownload: !hasCostByDatabaseEngineChart(
                selectedDashboard!.connectorProvider
              ),
            },
            {
              element: getGraphComponent(
                'daily-elasticity-by-purchase-option',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'daily-elasticity-by-purchase-option',
              column: modifyToExportColumns(
                getDailyElasticityByPurchaseOptionColumns(
                  dailyElasticityByPurchaseOptionDateRange
                )
              ),
              body: insertIndex(
                getDualAxisCostByTypeTableData(dailyElasticityByPurchaseOption)
              ),
              tableName: t('graphHeadings.dailyElasticityByPurchaseOption'),
              isTableView: dailyElasticityByPurchaseOptionTableView,
            },
            {
              element: getGraphComponent('daily-cost-by-instance-family', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'daily-cost-by-instance-family',
              column: modifyToExportColumns(
                getDailyCostByInstanceFamilyColumns(
                  dailyCostByInstanceFamilyDateRange,
                  selectedDashboard!.connectorProvider
                )
              ),
              body: insertIndex(
                getDualAxisCostByTypeTableData(dailyCostByInstanceFamily)
              ),
              tableName: getDailyCostByInstanceFamilyLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              isTableView: dailyCostByInstanceFamilyTableView,
            },
          ]}
        />
      )}
    </div>
  );
};

export default DatabaseDashboard;
