import { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import { useSelector } from 'react-redux';

import SearchInput from 'components/Input';
import Checkbox from 'components/Checkbox';
import DrawerComponent from 'components/DrawerComponent';
import Button from 'components/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import { PROVIDER } from 'constants/cloudProviders';
import { selectDashboard } from 'redux/dashboardSlice';
import { TagsType, ValueLabelType } from 'types/dashboard';

import './index.scss';

const { Panel } = Collapse;

type TagsDrawerProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  selectedViewTags: TagsType[];
  onClickApply: (selectedTags: TagsType[]) => void;
};

const TagsDrawer = ({
  show,
  setShow,
  selectedViewTags,
  onClickApply,
}: TagsDrawerProps) => {
  const { t } = useTranslation();

  const { selectedDashboard, allAvailableTags } = useSelector(selectDashboard);

  const [selectedTags, setSelectedTags] = useState<TagsType[]>([]);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    setSelectedTags(
      allAvailableTags.map((tag) => ({
        ...tag,
        key: tag.key,
        values:
          selectedViewTags.find((item) => item.key === tag.key)?.values ??
          selectedTags.find((item) => item.key === tag.key)?.values ??
          [],
      }))
    );
  }, [allAvailableTags, selectedViewTags]);

  /**
   * @function getSearchFilteredValues
   * @description Function to filter the tag values by search key
   * @param values values that are to be searched
   * @return list of search result filtered values
   */
  const getSearchFilteredValues = (tag: TagsType) => {
    if (tag.key?.toLowerCase().includes(searchKey.toLowerCase())) {
      return tag.values;
    }

    return tag.values.filter((item) =>
      (item.label ? item.label : item.value)
        .toLowerCase()
        .includes(searchKey.toLowerCase())
    );
  };

  /**
   * @function getSearchFilteredTags
   * @description Function to filter the tags by search key
   * @return list of search result filtered tags
   */
  const getSearchFilteredTags = () => {
    if (selectedDashboard?.connectorProvider === PROVIDER.AWS) {
      return allAvailableTags.filter((tag) =>
        tag.label?.toLowerCase().includes(searchKey.toLowerCase())
      );
    }
    return allAvailableTags.filter(
      (tag) => getSearchFilteredValues(tag).length > 0
    );
  };

  /**
   * @function isAllChecked
   * @description Function to return if All checkbox is checked or not
   * @param tag tag for which the checkbox is validated
   * @return boolean true if checked or false
   */
  const isAllChecked = (tag: TagsType) => {
    const selectedValues: ValueLabelType[] =
      selectedTags.find((item) => item.key === tag?.key)?.values ?? [];

    return getSearchFilteredValues(tag)?.every((item) =>
      selectedValues.some((value) => value.value === item.value)
    );
  };

  /**
   * @function onSelectAll
   * @description Callback function for selecting All checkbox
   * @param e Event for checkbox onChange
   * @param tag tag for which the All checkbox is clicked
   */
  const onSelectAll = (e: CheckboxChangeEvent, tag: TagsType) => {
    if (e.target.checked) {
      const previousTags = [...selectedTags];
      const position = selectedTags.findIndex((item) => item.key === tag?.key);
      previousTags.splice(position, 1, tag);
      setSelectedTags(previousTags);
      return;
    }
    setSelectedTags([...selectedTags.filter((item) => item.key !== tag?.key)]);
  };

  /**
   * @function isChecked
   * @description Function to return if the checkbox is checked or not
   * @param value value to be validated for
   * @param tag tag for which the checkbox is validated
   * @returns boolean true if checked else false
   */
  const isChecked = (value: string, tag: TagsType) => {
    const selectedValues: ValueLabelType[] =
      selectedTags.find((item) => item.key === tag?.key)?.values ?? [];

    return selectedValues.some((item) => item.value === value);
  };

  /**
   * @function onSelectCheckbox
   * @description Callback function for selecting each checkbox
   * @param e Event for checkbox onChange
   * @param value value to be added or removed
   * @param tag tag for which the checkbox is clicked
   */
  const onSelectCheckbox = (
    e: CheckboxChangeEvent,
    value: string,
    tag: TagsType
  ) => {
    const allSelectedTags = [...selectedTags];
    const previousSelected: ValueLabelType[] =
      selectedTags.find((item) => item.key === tag?.key)?.values ?? [];
    const position = selectedTags.findIndex((item) => item.key === tag?.key);

    if (e.target.checked) {
      allSelectedTags.splice(position, 1, {
        ...tag,
        key: tag?.key ?? '',
        values: [...previousSelected, { value: value }],
      });
      setSelectedTags(allSelectedTags);
      return;
    }

    allSelectedTags.splice(position, 1, {
      ...tag,
      key: tag?.key ?? '',
      values: previousSelected.filter((eachValue) => eachValue.value !== value),
    });
    setSelectedTags(allSelectedTags);
  };

  /**
   * @function getValueComponent
   * @description Function to return the values component for the tag
   * @param tag tag for which the values are to be displayed
   * @returns JSX element
   */
  const getValueComponent = (tag: TagsType) => {
    if (tag.values.length > 0) {
      return (
        <div className="values-list flex flex-column flex-gap-16">
          <div className="flex flex-column flex-gap-16">
            {!searchKey && tag.values.length > 1 && (
              <Checkbox
                value={'ALL'}
                key={'ALL'}
                checked={isAllChecked(tag)}
                onChange={(e) => onSelectAll(e, tag)}
              >
                {t('dashboardLabels.tagsLabels.all')}
              </Checkbox>
            )}
            {getSearchFilteredValues(tag).map((item) => (
              <Checkbox
                value={item.value}
                key={item.value}
                checked={isChecked(item.value, tag)}
                onChange={(e: CheckboxChangeEvent) =>
                  onSelectCheckbox(e, item.value, tag)
                }
              >
                <div className="value">{item?.label ?? item.value}</div>
              </Checkbox>
            ))}
          </div>
        </div>
      );
    }

    if (getSearchFilteredValues(tag).length === 0) {
      return t('dashboardLabels.tagsLabels.noValues');
    }
  };

  /**
   * @function getSelectedValuesCountComponent
   * @description Function to return the selected values count component
   * @param tag tag for which the selected values count is to be displayed
   * @returns JSX element
   */
  const getSelectedValuesCountComponent = (tag: TagsType) => {
    const selectedTagsCount = selectedTags.find((item) => item.key === tag?.key)
      ?.values.length;

    if (selectedTagsCount === 0) {
      return;
    }

    return (
      <div className="values-count flex flex-align-items-center">
        {selectedTagsCount}
      </div>
    );
  };

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="tag-collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="tag-collapse-arrow" />
    );

  const TagsModalTitle = (
    <div className="flex flex-align-items-center flex-space-between">
      <div className="form-header">{t('dashboardLabels.tagsLabels.tags')}</div>
      <SearchInput
        className="tag-value-search"
        value={searchKey}
        placeholder={
          selectedDashboard?.connectorProvider === PROVIDER.AWS
            ? t('dashboardLabels.tagsLabels.searchByTags')
            : t('dashboardLabels.tagsLabels.searchByTagsOrValue')
        }
        type="search"
        onChange={(e: any) => setSearchKey(e.target.value)}
      />
    </div>
  );

  const TagsModalActionCta = (
    <div className="flex flex-end">
      <Button
        title={t('dashboardLabels.tagsLabels.cancel')}
        type={BUTTON_TYPE.LINK}
        size={BUTTON_SIZE.MEDIUM}
        onClick={() => setShow(false)}
      />
      <Button
        title={t('dashboardLabels.tagsLabels.apply')}
        size={BUTTON_SIZE.MEDIUM}
        onClick={() => onClickApply(selectedTags)}
        disabled={
          selectedTags.filter((tag) => tag.values.length > 0).length === 0
        }
      />
    </div>
  );

  return (
    <DrawerComponent
      className="spend-diagnostics-tags-modal"
      dataTestId="spend-diagnostics-tags-modal"
      open={show}
      title={TagsModalTitle}
      onClose={() => setShow(false)}
      footer={TagsModalActionCta}
    >
      {allAvailableTags.length > 0 ? (
        <Collapse
          className="tags-collapse full-width flex flex-column new-styled-scroll"
          accordion={true}
          bordered={false}
          expandIcon={getExpandIcon}
        >
          {getSearchFilteredTags().map((tag) => (
            <Panel
              className="tag-panel font-subHeader"
              header={
                selectedDashboard?.connectorProvider === PROVIDER.AWS
                  ? tag.label
                  : tag.key
              }
              key={tag.key}
              extra={getSelectedValuesCountComponent(tag)}
            >
              {getValueComponent(tag)}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <div>{t('dashboardLabels.tagsLabels.noTagsToShow')}</div>
      )}
    </DrawerComponent>
  );
};

export default TagsDrawer;
