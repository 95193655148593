import { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectPurchase, setAwsMemoryDbRisCartData } from 'redux/purchaseSlice';
import QuickActionMenu from 'components/QuickActionMenu';
import {
  CartListQuickActions,
  CART_LIST_QUICKACTIONS,
} from 'pages/PurchasePage/constants';
import { RI_PAYMENT_OPTIONS_LIST } from 'pages/PurchasePage/components/ReservedInstances/constants';
import { PurchaseAwsMemoryDbRisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  getAverageMonthlyRecurringCost,
  getSubTotalDue,
} from 'pages/PurchasePage/components/ReservedInstances/utils';

import BuyMemoryDbRiFormModal from '../BuyMemoryDbRiFormModal';

import './index.scss';

type PurchasedMemoryDbRICardProps = {
  memoryDbRiData: PurchaseAwsMemoryDbRisType;
  index: number;
};

const PurchasedMemoryDbRICard = ({
  memoryDbRiData,
  index,
}: PurchasedMemoryDbRICardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { awsMemoryDbRiCartData } = useSelector(selectPurchase);

  const [showMemoryDbRiForm, setShowMemoryDbRiForm] = useState(false);

  /**
   * @function handleQuickActions
   * @description Function to handle the quick actions of the cart items
   * @param key quick action key
   */
  const handleQuickActions = (key: string) => {
    if (key === CartListQuickActions.EDIT) {
      setShowMemoryDbRiForm(true);
      return;
    }

    if (key === CartListQuickActions.DELETE) {
      const cartData = [...awsMemoryDbRiCartData];
      cartData.splice(index, 1);
      dispatch(setAwsMemoryDbRisCartData(cartData));
    }
  };

  return (
    <>
      <Row className="purchased-memory-db-ri-card flex flex-align-items-center">
        <Col span={4} className="flex flex-column flex-gap-8">
          <div className="font-button">{memoryDbRiData.nodeType}</div>
          <div className="payment-type font-small-bold">
            {
              RI_PAYMENT_OPTIONS_LIST.find(
                (item) => item.value === memoryDbRiData.paymentOption
              )?.label
            }
          </div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.region')}
          </div>
          <div className="font-caption-bold">{memoryDbRiData.region}</div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.term')}
          </div>
          <div className="font-caption-bold">
            {t('purchasePage.cart.countMonths', {
              count: memoryDbRiData.term * 12,
            })}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.quantity')}
          </div>
          <div className="font-caption-bold">{memoryDbRiData.quantity}</div>
        </Col>
        <Col span={5} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t(
              'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
            )}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(
              getSubTotalDue(
                memoryDbRiData.term,
                memoryDbRiData.hourlyUsageCharge
              )
            )}
          </div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.oneTimePayment')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(memoryDbRiData.oneTimePayment)}
          </div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.subTotalDue')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(
              getAverageMonthlyRecurringCost(
                memoryDbRiData.term,
                memoryDbRiData.hourlyUsageCharge
              )
            )}
          </div>
        </Col>
        <Col span={1} className="text-align-center">
          <QuickActionMenu
            quickActions={CART_LIST_QUICKACTIONS}
            quickActionHandler={handleQuickActions}
          />
        </Col>
      </Row>
      {showMemoryDbRiForm && (
        <BuyMemoryDbRiFormModal
          show={showMemoryDbRiForm}
          setShow={setShowMemoryDbRiForm}
          editData={memoryDbRiData}
          indexPosition={index}
        />
      )}
    </>
  );
};

export default PurchasedMemoryDbRICard;
