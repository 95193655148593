import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import ColumnChart from 'components/ColumnChart';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { CARBON_EMISSION_UNIT } from 'pages/OverviewPage/components/ConnectionsDashboard/components/CarbonFootprintDashboard/constants';
import { CarbonFootprintByProject } from 'pages/OverviewPage/components/ConnectionsDashboard/components/CarbonFootprintDashboard/types';
import { REQUEST_STATUS } from 'constants/requestBody';
import Table from 'components/Table';
import { selectDashboard } from 'redux/dashboardSlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import DatePicker from 'components/DatePicker';
import { DATE_FORMAT } from 'utils/date';
import { getGCPCarbonEmissionByProjectExcelData } from '../../utils/exportToExcel';

type CarbonEmissionByProjectProps = {
  carbonEmissionByProjectData: CarbonFootprintByProject[];
  selectedCarbonEmissionByProjectData: CarbonFootprintByProject[];
  setSelectedCarbonEmissionByProjectData: (
    val: CarbonFootprintByProject[]
  ) => void;
  requestStatus: string;
  projectStartDate: string;
  projectEndDate: string;
  onChangeProjectDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  carbonEmissionProjectTableView: boolean;
  setCarbonEmissionProjectTableView: (value: boolean) => void;
  pdfView: boolean;
};

const CarbonEmissionByProject = ({
  carbonEmissionByProjectData,
  selectedCarbonEmissionByProjectData,
  setSelectedCarbonEmissionByProjectData,
  requestStatus,
  projectStartDate,
  projectEndDate,
  onChangeProjectDateRange,
  carbonEmissionProjectTableView,
  setCarbonEmissionProjectTableView,
  pdfView,
}: CarbonEmissionByProjectProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('carbonFootprint.carbonEmissionByProjects.project'),
      dataIndex: 'project',
      key: 'project',
    },
    {
      title: t('carbonFootprint.monthlyCarbonEmission.carbonEmissionKgco2e'),
      dataIndex: 'carbonFootprint',
      key: 'carbonFootprint',
      width: 200,
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text || 0),
    },
  ];

  const getComponent = () =>
    carbonEmissionProjectTableView ? (
      <div className="tabular-view">
        <Table
          pagination={false}
          loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
          dataSource={selectedCarbonEmissionByProjectData.map(
            (data, index) => ({
              ...data,
              key: index,
            })
          )}
          columns={columns}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={selectedCarbonEmissionByProjectData}
        xField={'project'}
        yField={'carbonFootprint'}
        groupingField={'project'}
        xTitle={t('carbonFootprint.carbonEmissionByProjects.projects')}
        yTitle={CARBON_EMISSION_UNIT}
        showAllLegend={pdfView}
        suffixSymbol={CARBON_EMISSION_UNIT}
      />
    );

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[moment(projectStartDate), moment(projectEndDate)]}
        onChange={onChangeProjectDateRange}
        disabledDate={(current: any) => current > moment().endOf('day')}
        format={DATE_FORMAT}
      />
      <GraphFilterDropdown
        allData={carbonEmissionByProjectData}
        selectedData={selectedCarbonEmissionByProjectData}
        setSelectedData={setSelectedCarbonEmissionByProjectData}
        valueSuffix={t('carbonFootprint.carbonEmissionByProjects.projects')}
        fieldName={DROPDOWN_VALUE_FIELDS.PROJECT}
        designVersion2
      />
    </div>
  );

  return (
    <div
      className="carbon-emission-by-projects graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.grossCarbonEmissionsByProject')}
        graphName="carbon-emission-by-projects"
        ignorePaddingBottom={true}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={carbonEmissionProjectTableView}
        filters={filters}
        setIsTableView={setCarbonEmissionProjectTableView}
        excelData={getGCPCarbonEmissionByProjectExcelData(
          selectedDashboard?.connectorName ?? '',
          selectedCarbonEmissionByProjectData,
          projectStartDate,
          projectEndDate
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default CarbonEmissionByProject;
