import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EmptyCartIcon } from 'assets/icons';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';

type EmptyCartProps = {
  description: string;
  buttonTitle: string;
  setOpenForm: (val: boolean) => void;
};

const EmptyCart = ({
  description,
  buttonTitle,
  setOpenForm,
}: EmptyCartProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-column flex-center flex-fit full-height">
      <EmptyCartIcon />
      <section className="flex flex-column flex-gap-16">
        <section className="flex flex-column flex-center flex-gap-4">
          <div className="modal-heading">
            {t('purchasePage.cart.emptyCartTitle')}
          </div>
          <div className="sub-title font-subHeader">{description}</div>
        </section>
        <section className="flex flex-column flex-center flex-gap-4">
          <Button
            title={buttonTitle}
            type={BUTTON_TYPE.PRIMARY}
            onClick={() => setOpenForm(true)}
          />
          <Button
            title={t('purchasePage.cart.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => navigate(NAVIGATION_MENU_PATH.COMMITMENTS)}
          />
        </section>
      </section>
    </div>
  );
};

export default EmptyCart;
