import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import ColumnChart from 'components/ColumnChart';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import { REQUEST_STATUS } from 'constants/requestBody';
import GraphHeader from 'components/GraphHeader';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { CARBON_EMISSION_UNIT } from 'pages/OverviewPage/components/ConnectionsDashboard/components/CarbonFootprintDashboard/constants';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { CarbonFootprintByService } from 'pages/OverviewPage/components/ConnectionsDashboard/components/CarbonFootprintDashboard/types';
import { selectDashboard } from 'redux/dashboardSlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import DatePicker from 'components/DatePicker';
import { DATE_FORMAT } from 'utils/date';
import { getGCPCarbonEmissionByServiceExcelData } from '../../utils/exportToExcel';

type CarbonEmissionByServiceProps = {
  carbonEmissionByServiceData: CarbonFootprintByService[];
  selectedCarbonEmissionByServiceData: CarbonFootprintByService[];
  setSelectedCarbonEmissionByServiceData: (
    val: CarbonFootprintByService[]
  ) => void;
  requestStatus: string;
  serviceStartDate: string;
  serviceEndDate: string;
  onChangeServiceDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  carbonEmissionServicesTableView: boolean;
  setCarbonEmissionServicesTableView: (value: boolean) => void;
  pdfView: boolean;
};

const CarbonEmissionByService = ({
  carbonEmissionByServiceData,
  selectedCarbonEmissionByServiceData,
  setSelectedCarbonEmissionByServiceData,
  requestStatus,
  serviceStartDate,
  serviceEndDate,
  onChangeServiceDateRange,
  carbonEmissionServicesTableView,
  setCarbonEmissionServicesTableView,
  pdfView,
}: CarbonEmissionByServiceProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('carbonFootprint.monthlyCarbonEmission.service'),
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: t('carbonFootprint.monthlyCarbonEmission.carbonEmissionKgco2e'),
      dataIndex: 'carbonFootprint',
      key: 'carbonFootprint',
      width: 200,
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text || 0),
    },
  ];

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[moment(serviceStartDate), moment(serviceEndDate)]}
        onChange={onChangeServiceDateRange}
        disabledDate={(current: any) => current > moment().endOf('day')}
        format={DATE_FORMAT}
      />
      <GraphFilterDropdown
        allData={carbonEmissionByServiceData}
        selectedData={selectedCarbonEmissionByServiceData}
        setSelectedData={setSelectedCarbonEmissionByServiceData}
        valueSuffix={t('carbonFootprint.carbonEmissionByServices.services')}
        fieldName={DROPDOWN_VALUE_FIELDS.SERVICE}
        designVersion2
      />
    </div>
  );

  const getComponent = () =>
    carbonEmissionServicesTableView ? (
      <div className="tabular-view">
        <Table
          pagination={false}
          loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
          dataSource={selectedCarbonEmissionByServiceData.map(
            (data, index) => ({
              ...data,
              key: index,
            })
          )}
          columns={columns}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={selectedCarbonEmissionByServiceData}
        xField={'service'}
        yField={'carbonFootprint'}
        groupingField={'service'}
        xTitle={t('carbonFootprint.carbonEmissionByServices.services')}
        yTitle={CARBON_EMISSION_UNIT}
        showAllLegend={pdfView}
        suffixSymbol={CARBON_EMISSION_UNIT}
      />
    );

  return (
    <div
      className="carbon-emission-by-services graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.grossCarbonEmissionsByServices')}
        graphName="carbon-emission-by-services"
        ignorePaddingBottom={true}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={carbonEmissionServicesTableView}
        setIsTableView={setCarbonEmissionServicesTableView}
        filters={filters}
        excelData={getGCPCarbonEmissionByServiceExcelData(
          selectedDashboard?.connectorName ?? '',
          selectedCarbonEmissionByServiceData,
          serviceStartDate,
          serviceEndDate
        )}
        designVersion2
      />
      <div className="graph with-filters">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default CarbonEmissionByService;
