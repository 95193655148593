import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
import {
  selectGProfilerConnection,
  setConnectionList,
} from 'redux/gProfilerConnectionSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import GranulateAppsTable from 'pages/ThirdPartyPage/GranulateAppsComponents/GranulateAppsTable';
import { getStatusString } from 'pages/ThirdPartyPage/GranulateAppsComponents/utils';
import QuickActionMenu from 'components/QuickActionMenu';
import Icon from 'components/Icon';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { StatusEnum } from 'constants/gProfiler';
import { ICONS } from 'constants/icons';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { onApiCallError } from 'utils/handleErrors';
import { SLASH_DATE_FORMAT } from 'utils/date';
import { getGProfilerDashboardServices } from 'utils/services';
import { ConnectionList } from 'types/gProfiler';

import {
  GProfilerQuickActions,
  GPROFILER_CONNECTION_QUICKACTIONS,
} from './constants';
import { updateServiceStatus } from '../services';

const TableView = () => {
  const { t } = useTranslation();
  const { connectionList } = useSelector(selectGProfilerConnection);
  const { permissions } = useSelector(userAuthorization);
  const dispatch = useDispatch();

  const [requestStatus, setRequestStatus] = useState<string>(
    REQUEST_STATUS.PROCESSING
  );
  const [showActivateConfirmModal, setShowActivateConfirmModal] =
    useState(false);
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionRecord, setActionRecord] = useState<ConnectionList>();
  const [statusUpdateRequestStatus, setStatusUpdateRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  /**
   * @function fetchDashboardServices
   * @description Function to fetch GProfiler Dashboard services
   * @param addLoader boolean to indicate whether to add the loader or not
   */
  const fetchDashboardServices = (addLoader: boolean = true) => {
    addLoader && setRequestStatus(REQUEST_STATUS.PROCESSING);
    getGProfilerDashboardServices()
      .then((res: any) => {
        if (res.status === 200) {
          const list = res.data.map((item: any, index: number) => {
            return {
              key: (index + 1).toString(),
              connectionName: item.connectionName,
              provider: item.cloud,
              createdDate: moment(item.creationDate).format(SLASH_DATE_FORMAT),
              connectionStatus: getStatusString(item.status),
              serviceName: item.serviceName,
              serviceDisplayName: item.serviceDisplayName,
            };
          });
          dispatch(setConnectionList(list));
          setRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  /**
   * @function onStatusUpdateError
   * @description Function to handle the error scenario on status update
   * @param errorMessage Optional message to display when error.
   */
  const onStatusUpdateError = (errorMessage?: string) => {
    setShowDeactivateConfirmModal(false);
    setShowActivateConfirmModal(false);
    setStatusUpdateRequestStatus(REQUEST_STATUS.ERROR);
    message.error({
      content: errorMessage ?? t('gProfilerConnectionPage.errorUpdatingStatus'),
      key: ERROR_KEY,
    });
  };

  /**
   * @function updateGProfilerServiceStatus
   * @description Function to update the gProfiler connection service status
   * @param status status to be updated to.
   */
  const updateGProfilerServiceStatus = (status: string) => {
    setStatusUpdateRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      serviceName: actionRecord?.serviceName,
      status: status,
    };

    updateServiceStatus(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setShowDeactivateConfirmModal(false);
          setShowActivateConfirmModal(false);
          message.success({
            content: t('gProfilerConnectionPage.successUpdatingStatus'),
            key: SUCCESS_KEY,
          });
          setStatusUpdateRequestStatus(REQUEST_STATUS.SUCCESS);
          fetchDashboardServices();
        } else {
          onStatusUpdateError(res.data.Message);
        }
      })
      .catch((e) => {
        onApiCallError(e, false);
        onStatusUpdateError(e.response.data.Message);
      });
  };

  /**
   * @function handleDelete
   * @description Function to handle the delete connection
   */
  const handleDelete = () => {
    setShowDeleteModal(false);
    fetchDashboardServices();
  };

  const getQuickActionsMenu = (record: any) => {
    const actionList = GPROFILER_CONNECTION_QUICKACTIONS.filter(
      (item) =>
        ![
          GProfilerQuickActions.ACTIVATE,
          GProfilerQuickActions.DEACTIVATE,
          GProfilerQuickActions.DELETE,
        ].includes(item.id)
    );

    if (record.connectionStatus === getStatusString(StatusEnum.DEACTIVATED)) {
      actionList.push(
        ...GPROFILER_CONNECTION_QUICKACTIONS.filter(
          (item) => item.id === GProfilerQuickActions.ACTIVATE
        )
      );
    }

    if (record.connectionStatus === getStatusString(StatusEnum.COMPLETED)) {
      actionList.push(
        ...GPROFILER_CONNECTION_QUICKACTIONS.filter(
          (item) => item.id === GProfilerQuickActions.DEACTIVATE
        )
      );
    }

    return actionList.reverse();
  };

  const handleQuickAction = (action: any, record: any) => {
    setActionRecord(record);
    switch (action) {
      case GProfilerQuickActions.ACTIVATE:
        setShowActivateConfirmModal(true);
        break;
      case GProfilerQuickActions.DEACTIVATE:
        setShowDeactivateConfirmModal(true);
        break;
      case GProfilerQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '3%',
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: t('gProfilerConnectionPage.connectionName'),
      dataIndex: 'connectionName',
      key: 'connectionName',
      render: (text: any, record: any, _index: number) => {
        return (
          <div className="connection flex flex-align-items-end">
            <img
              src={getProviderSmallLogo(record.provider)}
              alt={`${record.provider}-logo`}
            />
            <span className="font-caption-bold">{text}</span>
          </div>
        );
      },
    },
    {
      title: t('gProfilerConnectionPage.serviceName'),
      dataIndex: 'serviceDisplayName',
      key: 'serviceName',
      render: (text: any) => <span className="font-caption-bold">{text}</span>,
    },
    {
      title: t('gProfilerConnectionPage.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text: any) => <span className="date">{text}</span>,
    },
    {
      title: t('gProfilerConnectionPage.connectionStatus'),
      key: 'connectionStatus',
      dataIndex: 'connectionStatus',
      render: (text: any) => {
        return (
          <div
            className={`status status-${text} font-small-bold ${
              text === getStatusString(StatusEnum.ONGOING) &&
              'flex flex-align-items-center flex-gap-4'
            }`}
          >
            {text === getStatusString(StatusEnum.ONGOING) && (
              <Icon iconName={ICONS.INSTALLING_LINE} />
            )}
            {text}
          </div>
        );
      },
    },
    {
      title: t('gProfilerConnectionPage.action'),
      key: 'action',
      align: 'center',
      render: (_text: string, record: any) => (
        <QuickActionMenu
          quickActions={getQuickActionsMenu(record)}
          quickActionHandler={(action) => handleQuickAction(action, record)}
          disabled={
            !permissions.thirdPartyAppsModify ||
            !['active', 'deactivated'].includes(record.connectionStatus)
          }
        />
      ),
    },
  ];

  return (
    <GranulateAppsTable
      connectionList={connectionList}
      columns={columns}
      requestStatus={requestStatus}
      fetchDashboardServices={fetchDashboardServices}
      actionRecord={actionRecord}
      statusUpdateRequestStatus={statusUpdateRequestStatus}
      updateConnectionStatus={updateGProfilerServiceStatus}
      showActivateConfirmModal={showActivateConfirmModal}
      showDeactivateConfirmModal={showDeactivateConfirmModal}
      showDeleteModal={showDeleteModal}
      setShowActivateConfirmModal={setShowActivateConfirmModal}
      setShowDeactivateConfirmModal={setShowDeactivateConfirmModal}
      setShowDeleteModal={setShowDeleteModal}
      handleDelete={handleDelete}
    />
  );
};

export default TableView;
