import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataCenterListType } from 'pages/DataCenterPage/types';
import {
  TCO_DROPDOWN_MENU_TYPES,
  TCO_HORIZONTAL_NAV_MENU,
} from 'pages/TcoDashboardPage/constants';
import { TcoProjectListType } from 'pages/TcoProjectsPage/types';
import { RootState } from './store';

export interface TcoDashboardState {
  selectedTcoDashboardType: string;
  selectedOnPremise: DataCenterListType | undefined;
  selectedTco: TcoProjectListType | undefined;
  selectedTcoMenu: string;
}

const initialState: TcoDashboardState = {
  selectedTcoDashboardType: TCO_DROPDOWN_MENU_TYPES.ON_PREMISE,
  selectedOnPremise: undefined,
  selectedTco: undefined,
  selectedTcoMenu: TCO_HORIZONTAL_NAV_MENU.COST_SUMMAY,
};

export const tcoDashboardSlice = createSlice({
  name: 'tcoDashboard',
  initialState,
  reducers: {
    setSelectedTcoDashboardType: (state, action: PayloadAction<string>) => {
      state.selectedTcoDashboardType = action.payload;
    },
    setSelectedOnPremise: (
      state,
      action: PayloadAction<DataCenterListType | undefined>
    ) => {
      state.selectedOnPremise = action.payload;
    },
    setSelectedTco: (
      state,
      action: PayloadAction<TcoProjectListType | undefined>
    ) => {
      state.selectedTco = action.payload;
    },
    setSelectedTcoMenu: (state, action: PayloadAction<string>) => {
      state.selectedTcoMenu = action.payload;
    },
  },
});

export const tcoDashboard = (state: RootState) => state.tcoDashboard;
export const {
  setSelectedTcoDashboardType,
  setSelectedOnPremise,
  setSelectedTco,
  setSelectedTcoMenu,
} = tcoDashboardSlice.actions;
export default tcoDashboardSlice.reducer;
