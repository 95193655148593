import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchAWSAnomalies
 * @description Function to fetch the AWS Anomalies
 * @param params Request parameters
 * @return axios response from GET request
 */
export const fetchAWSAnomalies = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_ANOMALIES, null, params);
};

/**
 * @function fetchAWSCostAndUsageMetrics
 * @description Function to fetch the AWS Cost and Usage Metrics
 * @param body Request body
 * @param params Request parameters
 * @return axios response from POST request
 */
export const fetchAWSCostAndUsageMetrics = async (body: any, params: any) => {
  return ServiceCalls.post(
    APIS.GET_AWS_COST_AND_USAGE_METRICS,
    body,
    null,
    params
  );
};
