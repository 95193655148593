import i18n from 'i18n';

export enum ImportStaticFilesComponents {
  IMPORT_FILE_FORM = 'IMPORT_FILE_FORM',
  SUCCESS_PAGE = 'SUCCESS_PAGE',
  UPLOADING_PAGE = 'UPLOADING_PAGE',
  ERROR_PAGE = 'ERROR_PAGE',
}

export const defaultFormData = {
  displayName: '',
  transactionName: '',
  typeOfDataset: '',
  focusSchema: false,
  isExistingSchema: true,
  wantSpendDiagnosticsDashboard: false,
  schemaName: '',
  isCreateSchemaDefinition: true,
  schema: '',
  schemaDefinition: [],
};

export const PROGRESS_BAR_ITEMS = [
  {
    title: i18n.t('importFileLabels.formLabels.step1.heading'),
    description: i18n.t('importFileLabels.formLabels.step1.subHeading'),
  },
  {
    title: i18n.t('importFileLabels.formLabels.step2.heading'),
    description: i18n.t('importFileLabels.formLabels.step2.subHeading'),
  },
  {
    title: i18n.t('importFileLabels.formLabels.step3.heading'),
    description: i18n.t('importFileLabels.formLabels.step3.subHeading'),
  },
  {
    title: i18n.t('importFileLabels.formLabels.step4.heading'),
    description: i18n.t('importFileLabels.formLabels.step4.subHeading'),
  },
];
