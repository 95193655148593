import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ruleEngine, setRuleEngineFormData } from 'redux/ruleEngineSlice';
import { providerList } from 'redux/providerSlice';
import {
  DEFAULT_FORM_DATA,
  DEFAULT_RULE,
} from 'pages/RuleEnginePage/constants';
import { RulesetTemplateType } from 'pages/RuleEnginePage/types';
import Button from 'components/Button';
import DashboardComponent from 'components/DashboardComponent';
import { ICONS } from 'constants/icons';
import { evaluateRequestArray } from 'utils/handleErrors';
import { REQUEST_STATUS } from 'constants/requestBody';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { BUTTON_SIZE } from 'constants/appearance';

import { getTemplateForARuleStatement, validateStatement } from './utils';
import RuleCard from '../RuleCard';
import {
  getDefaultRule,
  removeHashPrefixFromIntegerAndFloat,
} from '../../utils';
import RulesLimitModal from '../RulesLimitModal';

type SetupRulesProps = {
  createButtonClick: boolean;
  setSetupRulesValidation: (val: boolean) => void;
  rulesetTemplateList: RulesetTemplateType[];
  rulesetTemplateRequestStatus: string;
};

const SetupRules = ({
  createButtonClick,
  setSetupRulesValidation,
  rulesetTemplateList,
  rulesetTemplateRequestStatus,
}: SetupRulesProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ruleEngineFormData, isEditForm, isTryAgain } =
    useSelector(ruleEngine);
  const { selectedProvider } = useSelector(providerList);

  const [rulesErrorMessage, setRulesErrorMessage] = useState('');
  const [showRulesLimitModal, setShowRulesLimitModal] = useState(false);

  useEffect(() => {
    if (isTryAgain) {
      return;
    }

    if (!isEditForm && rulesetTemplateList.length > 0) {
      dispatch(
        setRuleEngineFormData({
          ...DEFAULT_FORM_DATA,
          cloudProvider: selectedProvider,
          rules: [
            getDefaultRule(rulesetTemplateList, ruleEngineFormData) ??
              DEFAULT_RULE,
          ],
        })
      );
    } else if (!isEditForm) {
      dispatch(
        setRuleEngineFormData({
          ...DEFAULT_FORM_DATA,
          cloudProvider: selectedProvider,
          rules: [],
        })
      );
    }

    if (isEditForm) {
      dispatch(
        setRuleEngineFormData({
          ...ruleEngineFormData,
          rules: ruleEngineFormData.rules.map((rule) => ({
            ...rule,
            statementValuesList: rule.statementValuesList.map(
              (statementValue) => ({
                ...statementValue,
                value: removeHashPrefixFromIntegerAndFloat(
                  getTemplateForARuleStatement(
                    rulesetTemplateList,
                    rule,
                    statementValue
                  ),
                  statementValue
                ),
              })
            ),
          })),
        })
      );
    }
  }, [rulesetTemplateList]);

  useEffect(() => {
    setSetupRulesValidation(validateRuleSets(false));
  }, [ruleEngineFormData.rules]);

  useDidMountEffect(() => {
    validateRuleSets();
  }, [createButtonClick]);

  /**
   * @function addNewRule
   * @description Function to add a new rule card
   */
  const addNewRule = () => {
    if (rulesErrorMessage) {
      setRulesErrorMessage('');
    }

    if (ruleEngineFormData.rules.length === 5) {
      setShowRulesLimitModal(true);
      return;
    }

    dispatch(
      setRuleEngineFormData({
        ...ruleEngineFormData,
        rules: [
          ...(ruleEngineFormData.rules || []),
          getDefaultRule(rulesetTemplateList, ruleEngineFormData) ??
            DEFAULT_RULE,
        ],
      })
    );
  };

  /**
   * @function validateRuleSets
   * @description Function to validate the rulesets
   * @param setErrorMessage Optional param to indicate whether to set the error messages for validations or not. Defaults to true.
   * @returns boolean true if validation succeeds else false
   */
  const validateRuleSets = (setErrorMessage: boolean = true) => {
    if (!ruleEngineFormData?.rules?.length) {
      setErrorMessage &&
        setRulesErrorMessage(
          t('createRulesetLabels.rulesets.noRulesErrorMessage')
        );
      return false;
    }

    if (
      ruleEngineFormData.rules.some(
        (rule) => !rule?.statementValuesList?.length
      )
    ) {
      return false;
    }

    return ruleEngineFormData.rules.every((rule) =>
      rule.statementValuesList.every(
        (statement) =>
          validateStatement(
            statement,
            getTemplateForARuleStatement(rulesetTemplateList, rule, statement)
          ).length === 0
      )
    );
  };

  /**
   * @function getRuleCardComponent
   * @description Function to return the rule cards component
   * @returns JSX element containing the rule cards or error message
   */
  const getRuleCardComponent = () => {
    if (rulesErrorMessage) {
      return <div className="font-validation-error">{rulesErrorMessage}</div>;
    }

    return (
      <div className="ruleset-list flex flex-column flex-gap-16">
        {ruleEngineFormData?.rules?.map((rule, index) => (
          <RuleCard
            key={`${rule.recommendationStatementType}${rule.recommendationType}`}
            rule={rule}
            ruleIndex={index}
            rulesetTemplateList={rulesetTemplateList}
            createButtonClick={createButtonClick}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="rule-setup full-width">
      <div className="flex flex-center flex-space-between">
        <div className="form-header">
          {t('createRulesetLabels.rulesets.setupRules')}
        </div>
        {rulesetTemplateRequestStatus === REQUEST_STATUS.SUCCESS && (
          <Button
            className="add-new-rule-cta"
            size={BUTTON_SIZE.SMALL}
            title={t('createRulesetLabels.rulesets.addNewRule')}
            iconName={ICONS.ADD_LINE}
            onClick={addNewRule}
            disabled={
              getDefaultRule(rulesetTemplateList, ruleEngineFormData) ===
              undefined
            }
          />
        )}
      </div>
      <div className="ruleset-list-container">
        <DashboardComponent
          component={getRuleCardComponent()}
          requestStatus={evaluateRequestArray([rulesetTemplateRequestStatus])}
        />
      </div>
      <RulesLimitModal
        show={showRulesLimitModal}
        onClickOk={() => setShowRulesLimitModal(false)}
        message={t('createRulesetLabels.rulesets.rulesLimitMessage')}
      />
    </div>
  );
};

export default SetupRules;
