import { useSelector, useDispatch } from 'react-redux';

import { selectNavMenu, setShowMenuWithTitle } from 'redux/activeNavMenuSlice';
import { selectChatBot } from 'redux/chatBotSlice';
import NewNavigationMenu from 'components/NewNavigationMenu';
import SubMenuComponent from 'components/SubMenuComponent';
import ChatBoxModal from 'components/ChatBoxModal';

import AppLogo from '../AppLogo';

import './index.scss';

const MenuManager = () => {
  const dispatch = useDispatch();
  const { showMenuWithTitle, isSubMenuOpen, expandedMenu } =
    useSelector(selectNavMenu);
  const { isChatBoxOpen } = useSelector(selectChatBot);

  return (
    <>
      <div
        className={`flex menu-manager ${
          showMenuWithTitle && 'show-menu-title'
        } ${isSubMenuOpen && 'with-sub-menu'}`}
        onMouseEnter={() => dispatch(setShowMenuWithTitle(true))}
        onMouseLeave={() => dispatch(setShowMenuWithTitle(false))}
      >
        <div className={`main-menu flex flex-column`}>
          <AppLogo />
          <NewNavigationMenu />
        </div>
        {isSubMenuOpen && expandedMenu && (
          <SubMenuComponent menu={expandedMenu} />
        )}
      </div>
      {isChatBoxOpen && <ChatBoxModal />}
    </>
  );
};

export default MenuManager;
