import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import Table from 'components/Table';
import Checkbox from 'components/Checkbox';
import { PermissionsType, RolesType } from 'pages/RolesPage/constants';
import { PERMISSION_MODULES, PERMISSION_RIGHTS } from 'types/roleManagement';

import './index.scss';

type PermissionsTableProps = {
  role: RolesType;
  disabled?: boolean;
  setNewRole?: (val: RolesType) => void;
};

const PermissionsTable = ({
  role,
  disabled = false,
  setNewRole,
}: PermissionsTableProps) => {
  const { t } = useTranslation();

  /**
   * @function hasDashboardListPermission
   * @description Function to check if the role has dashboard list All selected
   * @returns true if dashboard list is selected, else false
   */
  const hasDashboardListPermission = () => {
    const permissions = role.permissions;
    return permissions.some(
      (permission) =>
        permission.module === PERMISSION_MODULES.DASHBOARD &&
        permission.rights.includes(PERMISSION_RIGHTS.LIST)
    );
  };

  /**
   * @function handleCheckboxSelect
   * @description Function to update the permission on clicking the checkbox
   * @params checked boolean status of checkbox
   * @params record row data of the updated checkbox
   * @params permission column data of the updated checkbox.
   */
  const handleCheckboxSelect = (
    checked: boolean,
    record: PermissionsType,
    permission: string
  ) => {
    let addCloudConnectorListPermission = false;
    if (checked && !record.rights.some((value) => value === permission)) {
      record.rights = [...record.rights, permission];
      if (
        record.module === PERMISSION_MODULES.DASHBOARD &&
        permission === PERMISSION_RIGHTS.LIST
      ) {
        addCloudConnectorListPermission = true;
      }
    } else if (
      !(
        record.module === PERMISSION_MODULES.CLOUD_CONNECTOR &&
        permission === PERMISSION_RIGHTS.LIST &&
        hasDashboardListPermission()
      )
    ) {
      record.rights = record.rights.filter((value) => value !== permission);
    }

    setNewRole?.({
      ...role,
      permissions: role.permissions.map((value) => {
        if (value.module === record.module) {
          return record;
        }

        if (
          addCloudConnectorListPermission &&
          value.module === PERMISSION_MODULES.CLOUD_CONNECTOR &&
          !value.rights.some((right) => right === PERMISSION_RIGHTS.LIST)
        ) {
          return {
            ...value,
            rights: [...value.rights, PERMISSION_RIGHTS.LIST],
          };
        }

        return value;
      }),
    });
  };

  const columns = [
    {
      title: t('rolePermissionsModal.features'),
      dataIndex: 'module',
      key: 'module',
      align: 'left' as const,
    },
    {
      title: t('rolePermissionsModal.read'),
      dataIndex: 'roleName',
      key: 'roleName',
      align: 'center' as const,
      width: 80,
      render: (_text: string, record: any) => {
        return (
          <Checkbox
            checked={record.rights.some(
              (value: string) => value === PERMISSION_RIGHTS.READ
            )}
            disabled={disabled}
            onChange={(e: CheckboxChangeEvent) =>
              handleCheckboxSelect(
                e.target.checked,
                { ...record },
                PERMISSION_RIGHTS.READ
              )
            }
          />
        );
      },
    },
    {
      title: t('rolePermissionsModal.write'),
      dataIndex: 'roleType',
      key: 'roleType',
      align: 'center' as const,
      width: 80,
      render: (_text: string, record: any) => {
        return (
          <Checkbox
            checked={record.rights.some(
              (value: string) => value === PERMISSION_RIGHTS.WRITE
            )}
            disabled={disabled}
            onChange={(e: CheckboxChangeEvent) =>
              handleCheckboxSelect(
                e.target.checked,
                { ...record },
                PERMISSION_RIGHTS.WRITE
              )
            }
          />
        );
      },
    },
    {
      title: () => t('rolePermissionsModal.list'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center' as const,
      width: 80,
      render: (_text: string, record: any) => {
        return (
          <Checkbox
            checked={record.rights.some(
              (value: string) => value === PERMISSION_RIGHTS.LIST
            )}
            disabled={disabled}
            onChange={(e: CheckboxChangeEvent) =>
              handleCheckboxSelect(
                e.target.checked,
                { ...record },
                PERMISSION_RIGHTS.LIST
              )
            }
          />
        );
      },
    },
    {
      title: () => t('rolePermissionsModal.modify'),
      dataIndex: 'status',
      key: 'status',
      align: 'center' as const,
      width: 80,
      render: (_text: string, record: any) => {
        return (
          <Checkbox
            checked={record.rights.some(
              (value: string) => value === PERMISSION_RIGHTS.MODIFY
            )}
            disabled={disabled}
            onChange={(e: CheckboxChangeEvent) =>
              handleCheckboxSelect(
                e.target.checked,
                { ...record },
                PERMISSION_RIGHTS.MODIFY
              )
            }
          />
        );
      },
    },
  ];

  const getDataSource = () => {
    for (let item in PERMISSION_MODULES) {
      if (!role.permissions.some((value) => value.module === item)) {
        role.permissions.push({
          module: item,
          rights: [],
        });
      }
    }

    return role.permissions.map((value, index) => {
      return { ...value, key: index };
    });
  };

  return (
    <Table
      pagination={false}
      columns={columns}
      className="card permissions-table"
      dataSource={getDataSource()}
    />
  );
};

export default PermissionsTable;
