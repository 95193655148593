import i18n from 'i18n';

export const BUDGET_SCHEDULE = {
  key: 'MONTHLY',
  title: i18n.t('addBudgetAlert.createBudgetLabels.scope.monthly'),
};

export const OCI_TRIGGER_AT = [
  {
    value: 'ACTUAL',
    label: i18n.t('addBudgetAlert.createBudgetLabels.actions.actualCost'),
  },
  {
    value: 'FORECAST',
    label: i18n.t('addBudgetAlert.createBudgetLabels.actions.forecastSpend'),
  },
];

export const OCI_BUDGET_PERIOD_TYPE = 'INVOICE';
