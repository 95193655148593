import { PROVIDER } from 'constants/cloudProviders';
import { COMPARATORS, CONJUNCTIONS, QUERY_FIELDS } from 'constants/requestBody';
import { modifyQueryForCount } from 'utils/requestDtos';
import { BASE_AWS_QUERY, BASE_GCP_QUERY, getBaseAzureQuery } from './constants';

/**
 * @function getSearchKeyFilters
 * @description Get the search key filters by provider
 * @param searchKey The search key
 * @returns The search key filters array
 */
export const getSearchKeyFilters = (searchKey: string, provider: string) => {
  if (!searchKey) return [];
  switch (provider) {
    case PROVIDER.AWS:
      return [
        {
          filters: [
            {
              field: `LOWER(${QUERY_FIELDS.LINE_ITEM_RESOURCE_ID})`,
              comparator: COMPARATORS.LIKE,
              value: `%${searchKey}%`,
              conjunctToNextFilter: CONJUNCTIONS.OR,
            },
            {
              field: `LOWER(${QUERY_FIELDS.PRODUCT_SERVICENAME})`,
              comparator: COMPARATORS.LIKE,
              value: `%${searchKey}%`,
              conjunctToNextFilter: CONJUNCTIONS.OR,
            },
          ],
          conjunctToNextGroup: CONJUNCTIONS.AND,
        },
      ];

    case PROVIDER.GCP:
      return [
        {
          filters: [
            {
              field: `LOWER(${QUERY_FIELDS.BILLING_RESOURCE_NAME})`,
              comparator: COMPARATORS.LIKE,
              value: `%${searchKey}%`,
              conjunctToNextFilter: CONJUNCTIONS.OR,
            },
            {
              field: `LOWER(${QUERY_FIELDS.SERVICE_DESCRIPTION})`,
              comparator: COMPARATORS.LIKE,
              value: `%${searchKey}%`,
              conjunctToNextFilter: CONJUNCTIONS.OR,
            },
          ],
          conjunctToNextGroup: CONJUNCTIONS.AND,
        },
      ];

    case PROVIDER.AZURE:
      return [
        {
          filters: [
            {
              field: `LOWER(${QUERY_FIELDS.RESOURCE_ID})`,
              comparator: COMPARATORS.LIKE,
              value: `%${searchKey}%`,
              conjunctToNextFilter: CONJUNCTIONS.OR,
            },
            {
              field: `LOWER(${QUERY_FIELDS.CONSUMED_SERVICE})`,
              comparator: COMPARATORS.LIKE,
              value: `%${searchKey}%`,
              conjunctToNextFilter: CONJUNCTIONS.OR,
            },
          ],
          conjunctToNextGroup: CONJUNCTIONS.AND,
        },
      ];

    default:
      return [];
  }
};

/**
 * @function getGcpUntaggedResourcesQuery
 * @description Function to return the GCP query for fetching the untagged resources
 * @param tags List of mandatory tags
 * @param searchKey search string for filter
 * @return Object containing the query
 */
const getGcpUntaggedResourcesQuery = (tags: string[], searchKey: string) => {
  const tagColumns = tags.map((tag) => ({
    label: tag.replaceAll('-', '_'),
    field: `CASE WHEN tag.key = '${tag}' THEN tag.value ELSE NULL END`,
  }));

  return {
    ...BASE_GCP_QUERY,
    subQuery: {
      ...BASE_GCP_QUERY.subQuery,
      columns: [
        ...BASE_GCP_QUERY.subQuery.columns,
        ...tagColumns,
        {
          label: 'existingTags',
          field: QUERY_FIELDS.STRING_AGG_DISTINCT_TAG_KEY,
        },
      ],
      groupBy: [
        ...BASE_GCP_QUERY.subQuery.groupBy,
        ...tagColumns.map((item) => item.label),
      ],
      filterGroups: [
        ...BASE_GCP_QUERY.subQuery.filterGroups,
        ...getSearchKeyFilters(searchKey, PROVIDER.GCP),
      ],
    },
  };
};

/**
 * @function getAwsUntaggedResourcesQuery
 * @description Function to return the AWS query for fetching the untagged resources
 * @param persistedTags List of mandatory tags
 * @param searchKey search string for filter
 * @return Object containing the query
 */
const getAwsUntaggedResourcesQuery = (
  persistedTags: string[],
  searchKey: string,
  allTags: string[]
) => {
  return {
    ...BASE_AWS_QUERY,
    columns: [
      ...BASE_AWS_QUERY.columns,
      ...allTags.map((tag) => ({ label: tag, field: tag })),
    ],
    groupBy: [...BASE_AWS_QUERY.groupBy, ...allTags],
    filterGroups: [
      ...BASE_AWS_QUERY.filterGroups,
      {
        filters: persistedTags.map((tag) => ({
          field: tag,
          comparator: COMPARATORS.EQUALS,
          value: '',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        })),
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      ...getSearchKeyFilters(searchKey, PROVIDER.AWS),
    ],
  };
};

/**
 * @function getAzureUntaggedResourcesQuery
 * @description Function to return the AZURE query for fetching the untagged resources
 * @param tags List of mandatory tags
 * @param searchKey search string for filter
 * @param azureBillingTableName Azure billing table name
 * @return Object containing the query
 */
const getAzureUntaggedResourcesQuery = (
  tags: string[],
  searchKey: string,
  azureBillingTableName: string
) => {
  const BASE_AZURE_QUERY = getBaseAzureQuery(azureBillingTableName);
  return {
    ...BASE_AZURE_QUERY,
    columns: [
      ...BASE_AZURE_QUERY.columns,
      ...tags.map((tag) => ({
        label: tag.replaceAll('-', '_'),
        field: `JSON_VALUE(Tags, '$."${tag}"')`,
      })),
      { label: 'existingTags', field: QUERY_FIELDS.TAGS },
    ],
    groupBy: [
      ...BASE_AZURE_QUERY.groupBy,
      ...tags.map((tag) => tag.replaceAll('-', '_')),
      'existingTags',
    ],
    filterGroups: [
      ...BASE_AZURE_QUERY.filterGroups,
      {
        filters: tags.map((tag) => ({
          field: `CASE WHEN JSON_VALUE(Tags, '$."${tag}"') IS NOT NULL THEN 'true' ELSE 'false' END`,
          comparator: COMPARATORS.EQUALS,
          value: false,
          conjunctToNextFilter: CONJUNCTIONS.OR,
        })),
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      ...getSearchKeyFilters(searchKey, PROVIDER.AZURE),
    ],
  };
};

/**
 * @function getUntaggedResourcesQuery
 * @description Function to return the query for fetching the untagged resources by provider
 * @param persistedTags List of mandatory tags
 * @param provider CSP for which the query is required
 * @param searchKey search string for filter
 * @param azureBillingTableName Azure billing table name
 * @return Object containing the query
 */
export const getUntaggedResourcesQuery = (
  persistedTags: string[],
  provider: string,
  searchKey: string,
  azureBillingTableName: string,
  allTags: string[]
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGcpUntaggedResourcesQuery(persistedTags, searchKey);

    case PROVIDER.AWS:
      return getAwsUntaggedResourcesQuery(persistedTags, searchKey, allTags);

    case PROVIDER.AZURE:
      return getAzureUntaggedResourcesQuery(
        persistedTags,
        searchKey,
        azureBillingTableName
      );
  }
};

/**
 * @function getUntaggedResourcesCountQuery
 * @description Function to return the query for fetching the untagged resources count by provider
 * @param tags List of mandatory tags
 * @param provider CSP for which the query is required
 * @param searchKey search string for filter
 * @param azureBillingTableName Azure billing table name
 * @return Object containing the query
 */
export const getUntaggedResourcesCountQuery = (
  tags: string[],
  provider: string,
  searchKey: string,
  azureBillingTableName: string,
  allTags: string[]
) => {
  return modifyQueryForCount(
    getUntaggedResourcesQuery(
      tags,
      provider,
      searchKey,
      azureBillingTableName,
      allTags
    )
  );
};

/**
 * @function getMissingTags
 * @description Function to return the list of missing tags
 * @param persistedTags List of mandatory tags
 * @param data row data for which the missing tags is returned
 * @return List of missing tags
 */
export const getMissingTags = (persistedTags: string[], data: any) => {
  return persistedTags.filter((tag) => {
    const modifiedTag = tag.replaceAll('-', '_');
    return !data[modifiedTag] || data[modifiedTag] === '';
  });
};

/**
 * @function getExistingTags
 * @description Function to return the existing tags from the response
 * @param data response data
 * @param provider CSP for which the query is required
 * @param allTags List of already existing tags
 * @returns List of existing tags
 */
export const getExistingTags = (
  data: any,
  provider: string,
  allTags: string[]
) => {
  if (!data.existingTags) {
    return [];
  }

  switch (provider) {
    case PROVIDER.GCP:
      return data.existingTags.split(',');
    case PROVIDER.AWS:
      return allTags.filter((tag) => data[tag]);
    case PROVIDER.AZURE:
      return Object.keys(JSON.parse(data.existingTags));
  }
};
