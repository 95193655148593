import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Collapse, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import { selectDashboard, setConnectionList } from 'redux/dashboardSlice';
import { GroupRequestType } from 'pages/GroupsPage/types';
import Icon from 'components/Icon';
import { LoadingIcon } from 'assets/icons';
import Checkbox from 'components/Checkbox';
import { REQUEST_STATUS } from 'constants/requestBody';
import { fetchConnectionData } from 'utils/services';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { PROVIDER } from 'constants/cloudProviders';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

const { Panel } = Collapse;

type ConnectionListProps = {
  groupData: GroupRequestType;
  setGroupData: (val: GroupRequestType) => void;
  setConnectionsValidationMessage: (val: string) => void;
};

const ConnectionList = ({
  groupData,
  setGroupData,
  setConnectionsValidationMessage,
}: ConnectionListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { connectionList } = useSelector(selectDashboard);

  const [loadingFetchConnection, setLoadingFetchConnection] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    fetchConnectionList();
  }, []);

  const fetchConnectionList = () => {
    setLoadingFetchConnection(REQUEST_STATUS.PROCESSING);

    fetchConnectionData()
      .then((res: any) => {
        dispatch(setConnectionList(res?.data?.responseData?.content));
        setLoadingFetchConnection(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, true, setLoadingFetchConnection);
      });
  };

  /**
   * @function onSelectConnection
   * @description Function to select or deselect connection
   * @param event to select or deselect the connection
   */
  const onSelectConnection = (connectorId: string) => {
    setConnectionsValidationMessage('');
    if (groupData.connectorIds.includes(connectorId)) {
      setGroupData({
        ...groupData,
        connectorIds: groupData.connectorIds.filter(
          (connection) => connection !== connectorId
        ),
      });
    } else {
      setGroupData({
        ...groupData,
        connectorIds: [...groupData.connectorIds, connectorId],
      });
    }
  };

  /**
   * @function isAllChecked
   * @description Function to check if all the connections of a provider are selected
   * @param provider provider of the connection
   * @returns boolean value
   */
  const isAllChecked = (provider: string) => {
    return getConnectionListByProvider(provider).every((connection) =>
      groupData.connectorIds.includes(connection.connectorId)
    );
  };

  /**
   * @function getConnectionListByProvider
   * @description Function to get the connection list by provider
   * @param provider provider of the connection
   * @returns connection list of the provider
   */
  const getConnectionListByProvider = (provider: string) =>
    connectionList.filter((connection) => connection.provider === provider);

  /**
   * @function onSelectAll
   * @description Function to select or deselect all the connections of a provider
   * @param value to select or deselect all the connections
   * @param provider provider of the connection
   */
  const onSelectAll = (value: boolean, provider: string) => {
    setConnectionsValidationMessage('');
    if (value) {
      const newConnectionList = [
        ...groupData.connectorIds,
        ...getConnectionListByProvider(provider).map(
          (connection) => connection.connectorId
        ),
      ];
      setGroupData({
        ...groupData,
        connectorIds: newConnectionList.filter(
          (connection, index) => newConnectionList.indexOf(connection) === index
        ),
      });
    } else {
      setGroupData({
        ...groupData,
        connectorIds: groupData.connectorIds.filter(
          (connection) =>
            !getConnectionListByProvider(provider)
              .map((filteredConnection) => filteredConnection.connectorId)
              .includes(connection)
        ),
      });
    }
  };

  /**
   * @function getFilteredConnectionsBody
   * @description Function to get the filtered connections body containing
   * the list of connections and checkbox to select or deselect the connection
   * @param provider provider of the connection
   * @returns filtered connections body
   */
  const getFilteredConnectionsBody = (provider: string) => (
    <div className="flex flex-column flex-gap-8">
      {getConnectionListByProvider(provider).length > 1 && (
        <Checkbox
          key={'ALL'}
          checked={isAllChecked(provider)}
          onChange={(value) => onSelectAll(value.target.checked, provider)}
        >
          {t('checkBoxDropdown.all')}
        </Checkbox>
      )}
      {getConnectionListByProvider(provider).map((connection) => (
        <Row
          className="flex flex-align-items-center"
          key={connection.connectorId}
        >
          <Col span={16} className="table-typography">
            <Checkbox
              checked={groupData.connectorIds.includes(connection.connectorId)}
              onChange={() => onSelectConnection(connection.connectorId)}
            >
              <span className="flex flex-gap-8 flex-center">
                <span className="provider-logo">
                  <img
                    src={getProviderSmallLogo(connection?.provider)}
                    alt={`${connection?.provider} Logo`}
                  />
                </span>
                {connection.displayName}
              </span>
            </Checkbox>
          </Col>
          {connection.migrated && (
            <Col
              span={8}
              className="migrated-label flex flex-end font-subHeader-small"
            >
              {t('groupsLabels.datasetMigratedfrom') +
                ' ' +
                connection.migrationProvider}
            </Col>
          )}
        </Row>
      ))}
    </div>
  );

  const getSelectedConnectionsByProvider = (provider: string) =>
    getConnectionListByProvider(provider).filter((connection) =>
      groupData.connectorIds.includes(connection.connectorId)
    );

  if (loadingFetchConnection === REQUEST_STATUS.PROCESSING) {
    return <Icon icon={LoadingIcon} className="rotate full-width" />;
  }

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="provider-collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="provider-collapse-arrow" />
    );

  return (
    <div
      className="connection-list full-width"
      data-testid="create-group-modal-connection-list"
    >
      <Collapse
        className="provider-collapse full-width flex flex-column flex-gap-16"
        accordion={true}
        bordered={false}
        expandIcon={getExpandIcon}
      >
        {Object.values(PROVIDER)
          .filter((provider) => getConnectionListByProvider(provider).length)
          .map((provider) => (
            <Panel
              className="provider-panel font-subHeader"
              header={provider}
              key={provider}
              extra={
                getSelectedConnectionsByProvider(provider).length ? (
                  <div className="flex flex-center values-count">
                    {getSelectedConnectionsByProvider(provider).length}
                  </div>
                ) : null
              }
              data-testid={`${provider}-panel`}
            >
              {getFilteredConnectionsBody(provider)}
            </Panel>
          ))}
      </Collapse>
    </div>
  );
};

export default ConnectionList;
