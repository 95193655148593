import { message } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupIcon } from 'assets/icons';
import NavigationPath from 'components/NavigationPath';
import Input from 'components/Input';
import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import Pagination from 'components/Pagination';
import DeleteModal from 'components/DeleteModal';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { INPUT_SIZE } from 'constants/appearance';
import { SubscriptionQuickActions } from 'pages/SubscriptionsPage/constants';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { onApiCallError } from 'utils/handleErrors';

import EditSubscriptionDrawer from './components/EditSubscriptionDrawer';
import { SUBSCRIPTION_QUICK_ACTIONS_LIST } from './constants';
import {
  deleteSubscription,
  getAllSubscriptions,
  searchSubscriptions,
} from './services';
import { SubscriptionType } from './types';

import './index.scss';

const SubscriptionsPage = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);
  const [totalSubscriptionsCount, setTotalSubscriptionsCount] = useState(0);
  const [actionRecord, setActionRecord] = useState<SubscriptionType>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditSubscriptionDrawer, setShowEditSubscriptionDrawer] =
    useState(false);
  const [fetchSubscriptionsReqStatus, setFetchSubscriptionsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [deleteSubscriptionReqStatus, setDeleteSubscriptionReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    let source = axios.CancelToken.source();
    getSubscriptions(currentPage, source);

    return () => {
      source.cancel();
    };
  }, [searchKey]);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function getSubscriptions
   * @description function to fetch the subscriptions
   * @param page page to fetch the data
   * @param cancelToken cancel token for cancelling the frequent requests
   */
  const getSubscriptions = (
    page: number = currentPage,
    cancelToken?: CancelTokenSource
  ) => {
    setFetchSubscriptionsReqStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      page: page - 1,
      pageSize: PAGINATION_SIZE,
      searchKey: searchKey || undefined,
    };
    (searchKey
      ? searchSubscriptions(params, cancelToken?.token)
      : getAllSubscriptions(params, cancelToken?.token)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setSubscriptions(res?.data?.responseData?.content);
          setTotalSubscriptionsCount(res?.data?.responseData?.totalElements);
          setFetchSubscriptionsReqStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setFetchSubscriptionsReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchSubscriptionsReqStatus);
      });
  };

  /**
   * @function onDeleteError
   * @description Callback function for error on deleting a subscription
   * @param errorMessage error message to be displayed
   */
  const onDeleteError = (errorMessage: string) => {
    message.error({
      content:
        errorMessage ||
        t('subscriptions.errorDeletingSubscription', {
          subscriptionName: actionRecord?.subscriptionViewName,
        }),
      key: ERROR_KEY,
    });
    setDeleteSubscriptionReqStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function onClickDelete
   * @description function to delete a subscription
   */
  const onClickDelete = () => {
    setDeleteSubscriptionReqStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      subscriptionName: actionRecord?.subscriptionName,
    };

    deleteSubscription(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setDeleteSubscriptionReqStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          message.success({
            content: t('subscriptions.successDeletingSubscription', {
              subscriptionName: actionRecord?.subscriptionViewName,
            }),
            key: SUCCESS_KEY,
          });
          getSubscriptions(1);
          setCurrentPage(1);
          return;
        }
        onDeleteError(res.data.message);
      })
      .catch((e) => {
        onApiCallError(e, false, setDeleteSubscriptionReqStatus);
        onDeleteError(e?.response?.data?.message);
      });
  };

  /**
   * @function onClickUpdate
   * @description Callback function for update
   */
  const onClickUpdate = () => {
    getSubscriptions();
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions
   */
  const handleQuickAction = (record: SubscriptionType, action: string) => {
    setActionRecord(record);

    switch (action) {
      case SubscriptionQuickActions.EDIT:
        setShowEditSubscriptionDrawer(true);
        break;

      case SubscriptionQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: 50,
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
    },
    {
      title: t('subscriptions.subscriptionName'),
      dataIndex: 'subscriptionViewName',
      key: 'subscriptionViewName',
      render: (text: string) => (
        <span className="font-caption-bold">{text}</span>
      ),
    },
    {
      title: t('subscriptions.reportName'),
      dataIndex: 'reportName',
      key: 'reportName',
    },
    {
      title: t('subscriptions.connectionOrGroupName'),
      dataIndex: 'cloudConnectionName',
      key: 'cloudConnectionName',
      render: (_text: string, record: SubscriptionType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={
              record.cloudProvider
                ? getProviderSmallLogo(record.cloudProvider)
                : GroupIcon
            }
            alt={`${record.cloudProvider} Logo`}
          />
          {record.cloudConnectionName ?? record.groupName}
        </div>
      ),
    },
    {
      title: t('subscriptions.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: SubscriptionType) => {
        return (
          <QuickActionMenu
            quickActions={SUBSCRIPTION_QUICK_ACTIONS_LIST}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      width: 200,
      align: 'center' as const,
    },
  ];

  return (
    <div className="subscriptions flex flex-column flex-fit">
      <header className="new-page-header subscriptions-header flex flex-align-items-center flex-space-between">
        <span className="modal-heading">
          {t('navigationMenu.subscriptions')}
        </span>
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="subscriptions-content margin-24 flex flex-column flex-gap-16 flex-fit">
          <Input
            type="search"
            value={searchKey}
            placeholder={t('subscriptions.searchBySubscriptionName')}
            onChange={(e: any) => setSearchKey(e.target.value)}
            size={INPUT_SIZE.SMALL}
          />
          <Table
            pagination={false}
            dataSource={subscriptions?.map((subscription, index) => {
              return { ...subscription, key: index + 1 };
            })}
            columns={columns}
            loading={fetchSubscriptionsReqStatus === REQUEST_STATUS.PROCESSING}
            designVersion2={true}
          />
          {totalSubscriptionsCount > PAGINATION_SIZE && (
            <Pagination
              rootClassName="flex flex-align-items-end flex-fit"
              current={currentPage}
              onChange={(page: number) => {
                setCurrentPage(page);
                getSubscriptions(page);
              }}
              total={totalSubscriptionsCount}
              defaultPageSize={PAGINATION_SIZE}
            />
          )}
          {showDeleteModal && (
            <DeleteModal
              showDeleteModal={showDeleteModal}
              setShowDeleteModal={setShowDeleteModal}
              deletionFunction={onClickDelete}
              loading={
                deleteSubscriptionReqStatus === REQUEST_STATUS.PROCESSING
              }
            />
          )}
          {showEditSubscriptionDrawer && actionRecord && (
            <EditSubscriptionDrawer
              show={showEditSubscriptionDrawer}
              setShow={setShowEditSubscriptionDrawer}
              isEditSubscription
              subscription={actionRecord}
              onClickUpdate={onClickUpdate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsPage;
