import { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import {
  selectPurchase,
  setAwsOpenSearchRisCartData,
} from 'redux/purchaseSlice';
import QuickActionMenu from 'components/QuickActionMenu';
import {
  CartListQuickActions,
  CART_LIST_QUICKACTIONS,
  SPAndOpenSearchPaymentOptionsList,
} from 'pages/PurchasePage/constants';
import { PurchaseAwsOpenSearchRisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  getAverageMonthlyRecurringCost,
  getSubTotalDue,
} from 'pages/PurchasePage/components/ReservedInstances/utils';

import BuyOpenSearchRiFormModal from '../BuyOpenSearchRiFormModal';

import './index.scss';

type PurchasedOpenSearchRICardProps = {
  openSearchRiData: PurchaseAwsOpenSearchRisType;
  index: number;
};

const PurchasedOpenSearchRICard = ({
  openSearchRiData,
  index,
}: PurchasedOpenSearchRICardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { awsOpenSearchRiCartData } = useSelector(selectPurchase);

  const [showOpenSearchRiForm, setShowOpenSearchRiForm] = useState(false);

  /**
   * @function handleQuickActions
   * @description Function to handle the quick actions of the cart items
   * @param key quick action key
   */
  const handleQuickActions = (key: string) => {
    if (key === CartListQuickActions.EDIT) {
      setShowOpenSearchRiForm(true);
      return;
    }

    if (key === CartListQuickActions.DELETE) {
      const cartData = [...awsOpenSearchRiCartData];
      cartData.splice(index, 1);
      dispatch(setAwsOpenSearchRisCartData(cartData));
    }
  };

  return (
    <>
      <Row className="purchased-open-search-ri-card flex flex-align-items-center flex-space-between">
        <Col span={3} className="flex flex-column flex-gap-8">
          <div className="font-button">{openSearchRiData.reservationName}</div>
          <div className="payment-type font-small-bold">
            {
              SPAndOpenSearchPaymentOptionsList.find(
                (item) => item.value === openSearchRiData.paymentOption
              )?.label
            }
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.region')}
          </div>
          <div className="font-caption-bold">{openSearchRiData.region}</div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.instanceType')}
          </div>
          <div className="font-caption-bold">
            {openSearchRiData.instanceType}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.term')}
          </div>
          <div className="font-caption-bold">
            {t('purchasePage.cart.countMonths', {
              count: openSearchRiData.term * 12,
            })}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.quantity')}
          </div>
          <div className="font-caption-bold">{openSearchRiData.quantity}</div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t(
              'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
            )}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(
              getSubTotalDue(
                openSearchRiData.term,
                openSearchRiData.hourlyUsageCharge
              )
            )}
          </div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.oneTimePayment')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(openSearchRiData.oneTimePayment)}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.subTotalDue')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(
              getAverageMonthlyRecurringCost(
                openSearchRiData.term,
                openSearchRiData.hourlyUsageCharge
              )
            )}
          </div>
        </Col>
        <Col span={1} className="text-align-center">
          <QuickActionMenu
            quickActions={CART_LIST_QUICKACTIONS}
            quickActionHandler={handleQuickActions}
          />
        </Col>
      </Row>
      {showOpenSearchRiForm && (
        <BuyOpenSearchRiFormModal
          show={showOpenSearchRiForm}
          setShow={setShowOpenSearchRiForm}
          editData={openSearchRiData}
          indexPosition={index}
        />
      )}
    </>
  );
};

export default PurchasedOpenSearchRICard;
