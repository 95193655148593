import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ConnectionList } from 'types/gProfiler';
import { RootState } from './store';

export interface GranulateInsightsState {
  selectedService: ConnectionList | undefined;
  granulateServices: ConnectionList[];
  granulateServicesReqStatus: string;
}

const initialState: GranulateInsightsState = {
  selectedService: undefined,
  granulateServices: [],
  granulateServicesReqStatus: REQUEST_STATUS.PROCESSING,
};

export const granulateInsightsSlice = createSlice({
  name: 'granulateInsights',
  initialState,
  reducers: {
    setSelectedService(
      state,
      action: PayloadAction<ConnectionList | undefined>
    ) {
      state.selectedService = action.payload;
    },
    setGranulateServices(state, action: PayloadAction<ConnectionList[]>) {
      state.granulateServices = action.payload;
    },
    setGranulateServicesReqStatus(state, action: PayloadAction<string>) {
      state.granulateServicesReqStatus = action.payload;
    },
  },
});
export const selectGranulateInsights = (state: RootState) =>
  state.granulateInsights;
export const {
  setSelectedService,
  setGranulateServices,
  setGranulateServicesReqStatus,
} = granulateInsightsSlice.actions;

export default granulateInsightsSlice.reducer;
