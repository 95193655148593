import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';

import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';

type CpuRequestValueProps = {
  recommendation: RecommendationList;
};

const CpuRequestValue = ({ recommendation }: CpuRequestValueProps) => {
  const { theme } = useSelector(selectTheme);

  if (!recommendation.containers || !recommendation.containers.length) {
    return <div>N/A</div>;
  }

  return (
    <div className="flex flex-column flex-gap-8 flex-align-items-center">
      {recommendation.containers.map((data) => {
        const currentCpu = data.current_cpu
          ? Number((data.current_cpu / 1000).toFixed(4))
          : '-';

        let arrowIcon = null;
        if (data.current_cpu && data.recommended_cpu) {
          if (data.current_cpu < data.recommended_cpu) {
            arrowIcon = (
              <Icon
                iconName={ICONS.ARROW_RIGHT_UP_LINE}
                size={ICONS_SIZE.XL}
                color={theme.primaryColor}
                dataTestId="arrow-up"
              />
            );
          } else {
            arrowIcon = (
              <Icon
                iconName={ICONS.ARROW_RIGHT_DOWN_LINE}
                size={ICONS_SIZE.XL}
                color={theme.primaryColor}
                dataTestId="arrow-down"
              />
            );
          }
        }

        const recommendedCpu =
          data.recommended_cpu &&
          Number((data.recommended_cpu / 1000).toFixed(4));

        return (
          <div
            key={data.name}
            className="flex flex-gap-4 flex-align-items-center"
          >
            <span>{currentCpu}</span>
            {arrowIcon}
            <span>{recommendedCpu}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CpuRequestValue;
