import {
  Chart as ChartJS,
  ChartDataset,
  LegendItem,
  registerables,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { selectTheme } from 'redux/themeSlice';
import { COLORS, GRAPH_OPTIONS } from 'constants/graphConfig';
import { PDF_QUALITY } from 'constants/pdfConstants';
import { selectDashboard } from 'redux/dashboardSlice';
import { generateGraphColors } from 'utils/dashboardUtils';
import {
  getPlugins,
  isLeftScrollButtonArea,
  isRightScrollButtonArea,
  scrollEffect,
} from './utils';

ChartJS.register(...registerables);
ChartJS.defaults.devicePixelRatio = PDF_QUALITY;

type BarGraphProps = {
  labels: string[];
  dataSet: ChartDataset<'bar', number[]>[];
  legendPosition?: string;
  legendAlignment?: string;
  legendBoxWidth?: number;
  legendpadding?: number;
  legendpointStyle?: string;
  layoutPaddingRight?: number;
  layoutPaddingLeft?: number;
  layoutPaddingBottom?: number;
  layoutPaddingTop?: number;
  showXAxisGrid?: boolean;
  showYAxisGrid?: boolean;
  showXAxisTitle?: boolean;
  xAxisTitle?: string;
  showYAxisTitle?: boolean;
  yAxisTitle?: string;
  showY1AxisTitle?: boolean;
  y1AxisTitle?: string;
  beginAtZero?: boolean;
  xAxisMaximumValue?: number;
  xAxisMinimumValue?: number;
  yAxisMaximumValue?: number;
  yAxisMinimumValue?: number;
  y1AxisMaximumValue?: number;
  y1AxisMinimumValue?: number;
  stepSize?: number;
  y1StepSize?: number;
  xAxisPadding?: number;
  yAxisPadding?: number;
  indexAxis?: string;
  xAxisStacked?: boolean;
  yAxisStacked?: boolean;
  showXAxisTicks?: boolean;
  showYAxisTicks?: boolean;
  showY1AxisTicks?: boolean;
  pointRadius?: number;
  onClickLegend?: (click: any, legendItem: any, legend: any) => void;
  generateLabels?: (chart: any) => LegendItem[];
  displayLegends?: boolean;
  onClickBar?: (event: any, i: any, chart: any) => void;
  cursor?: string;
  generateColors?: boolean;
  plugins?: any[];
  scrollable?: boolean;
};

const BarGraph = ({
  labels,
  dataSet,
  legendPosition = GRAPH_OPTIONS.legendPositionBottom,
  legendBoxWidth = GRAPH_OPTIONS.legendBoxWidth,
  legendpadding = GRAPH_OPTIONS.legendpadding,
  legendpointStyle = GRAPH_OPTIONS.legendpointStyle,
  layoutPaddingLeft = GRAPH_OPTIONS.layoutPadding,
  layoutPaddingRight = GRAPH_OPTIONS.layoutPadding,
  layoutPaddingBottom = GRAPH_OPTIONS.layoutPadding,
  layoutPaddingTop = GRAPH_OPTIONS.layoutPadding,
  showXAxisGrid = false,
  showYAxisGrid = false,
  showXAxisTitle = true,
  xAxisTitle: xAxisTtile = '',
  showYAxisTitle = true,
  yAxisTitle: yAxisTtile = '',
  showY1AxisTitle = false,
  y1AxisTitle = '',
  beginAtZero = true,
  xAxisMaximumValue = GRAPH_OPTIONS.xAxisMaximumValue,
  xAxisMinimumValue = GRAPH_OPTIONS.xAxisMinimumValue,
  yAxisMaximumValue = GRAPH_OPTIONS.yAxisMaximumValue,
  yAxisMinimumValue = GRAPH_OPTIONS.yAxisMinimumValue,
  y1AxisMaximumValue = GRAPH_OPTIONS.yAxisMaximumValue,
  y1AxisMinimumValue = GRAPH_OPTIONS.yAxisMinimumValue,
  stepSize = GRAPH_OPTIONS.stepSize,
  y1StepSize = GRAPH_OPTIONS.stepSize,
  xAxisPadding = GRAPH_OPTIONS.xAxisPadding,
  yAxisPadding = GRAPH_OPTIONS.yAxisPadding,
  indexAxis = GRAPH_OPTIONS.indexAxis,
  xAxisStacked = false,
  yAxisStacked = false,
  showXAxisTicks = true,
  showYAxisTicks = true,
  showY1AxisTicks = false,
  legendAlignment = GRAPH_OPTIONS.legendAlignment,
  pointRadius = GRAPH_OPTIONS.pointRadius,
  onClickLegend,
  generateLabels,
  displayLegends = true,
  onClickBar,
  cursor = GRAPH_OPTIONS.cursorDefault,
  generateColors = true,
  plugins = [],
  scrollable = false,
}: BarGraphProps) => {
  const { pdfDownloadMode } = useSelector(selectDashboard);
  const { theme } = useSelector(selectTheme);

  const onClickCanvas = (event: any, i: any, chart: any) => {
    const xCoor = event.x;
    const yCoor = event.y;

    if (
      scrollable &&
      (isLeftScrollButtonArea(chart, xCoor, yCoor) ||
        isRightScrollButtonArea(chart, xCoor, yCoor))
    ) {
      scrollEffect(event, i, chart, { xAxisMinimumValue: xAxisMinimumValue });
      return;
    }

    if (onClickBar) {
      onClickBar(event, i, chart);
    }
  };

  const labelsOptions = generateLabels
    ? {
        usePointStyle: true,
        boxWidth: legendBoxWidth,
        padding: legendpadding,
        color: theme.textColor,
        generateLabels: generateLabels,
      }
    : {
        usePointStyle: true,
        boxWidth: legendBoxWidth,
        padding: legendpadding,
        pointStyle: legendpointStyle,
      };

  const legendOptions = onClickLegend
    ? {
        position: legendPosition as 'bottom',
        align: legendAlignment as 'center',
        onClick: onClickLegend,
        labels: { ...labelsOptions },
        display: displayLegends,
      }
    : {
        position: legendPosition as 'bottom',
        align: legendAlignment as 'center',
        onClick: () => {
          /* No action is required on click of legends */
        },
        labels: { ...labelsOptions },
        display: displayLegends,
      };

  const options = {
    animation: {
      duration: pdfDownloadMode ? 0 : 1000,
    },
    indexAxis: indexAxis as 'x',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { ...legendOptions },
      tooltip: {
        backgroundColor: COLORS.secondaryBackground,
        callbacks: {
          labelTextColor: () => theme.textColor,
        },
        titleColor: theme.textColor,
      },
    },
    layout: {
      padding: {
        left: layoutPaddingLeft,
        right: layoutPaddingRight,
        bottom: layoutPaddingBottom,
        top: layoutPaddingTop,
      },
    },
    scales: {
      x: {
        grid: {
          display: showXAxisGrid,
        },
        title: {
          display: showXAxisTitle,
          text: xAxisTtile,
          color: theme.textColor,
        },
        beginAtZero: beginAtZero,
        max: xAxisMaximumValue,
        min: xAxisMinimumValue,
        ticks: {
          padding: xAxisPadding,
          display: showXAxisTicks,
          color: theme.textColor,
        },
        stacked: xAxisStacked,
      },
      y: {
        grid: {
          display: showYAxisGrid,
        },
        title: {
          display: showYAxisTitle,
          text: yAxisTtile,
          color: theme.textColor,
        },
        beginAtZero: beginAtZero,
        max: yAxisMaximumValue,
        min: yAxisMinimumValue,
        ticks: {
          display: showYAxisTicks,
          stepSize: stepSize,
          padding: yAxisPadding,
          color: theme.textColor,
        },
        stacked: yAxisStacked,
      },
      y1: {
        type: 'linear' as any,
        position: 'right' as any,
        grid: {
          display: false,
        },
        title: {
          display: showY1AxisTitle,
          text: y1AxisTitle,
          color: theme.textColor,
        },
        beginAtZero: beginAtZero,
        max: y1AxisMaximumValue + y1AxisMaximumValue * 0.1,
        min: y1AxisMinimumValue,
        ticks: {
          display: showY1AxisTicks,
          stepSize: y1StepSize,
          padding: yAxisPadding,
          color: theme.textColor,
        },
      },
    },
    elements: {
      point: {
        radius: pointRadius,
      },
    },
    onClick: onClickCanvas,
    onHover: (evt: any, chart: any) => {
      if (chart[0]) {
        evt.native.target.style.cursor = cursor;
      }
    },
  };

  const data = {
    labels,
    datasets: generateColors
      ? dataSet.map((dataset) => ({
          ...dataset,
          backgroundColor: generateGraphColors(dataset.data.length),
        }))
      : dataSet,
  };

  return (
    <Bar
      className="stretch"
      options={options}
      data={data}
      plugins={[
        ...getPlugins({
          scrollable: scrollable,
          xAxisMinimumValue: xAxisMinimumValue,
          xAxisMaximumValue: xAxisMaximumValue,
        }),
        ...plugins,
      ]}
    />
  );
};

export default BarGraph;
