import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGranulateInsights,
  setSelectedService,
  setGranulateServices,
  setGranulateServicesReqStatus,
} from 'redux/granulateInsightsSlice';
import { GranulateIcon } from 'assets/icons';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { getGranulateDashboardServices } from 'utils/services';

import ServiceModalDashboard from '../ServiceModalDashboard';

import './index.scss';

const GranulateInsightHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedService } = useSelector(selectGranulateInsights);

  const [showServicesModal, setShowServicesModal] = useState(false);

  useEffect(() => {
    fetchGranulateServices();
  }, []);

  const fetchGranulateServices = () => {
    dispatch(setGranulateServicesReqStatus(REQUEST_STATUS.PROCESSING));

    getGranulateDashboardServices()
      .then((res: any) => {
        if (res?.status === 200) {
          const list = res?.data?.response?.map((item: any, index: number) => {
            return {
              key: (index + 1).toString(),
              connectionName: item.connectionName,
              provider: item.cloud,
              createdDate: item.creationDate,
              serviceDisplayName: item.serviceDisplayName,
              serviceName: item.serviceName,
            };
          });
          if (list.length > 0) {
            const storedService = sessionStorage.getItem(
              'granulateInsightService'
            );

            const initialSelectedService = storedService
              ? list.find((item: any) => item.serviceName === storedService)
              : list[0];

            dispatch(setSelectedService(initialSelectedService));
          }

          dispatch(setGranulateServices(list));
          dispatch(setGranulateServicesReqStatus(REQUEST_STATUS.SUCCESS));
        } else {
          dispatch(setGranulateServicesReqStatus(REQUEST_STATUS.ERROR));
        }
      })
      .catch((e: any) => {
        onApiCallError(e, false, (val) =>
          dispatch(setGranulateServicesReqStatus(val))
        );
      });
  };

  return (
    <header className="granulate-insights-dashboard-header new-page-header flex flex-align-items-center flex-space-between">
      <div className="flex flex-align-items-center font-caption-bold flex-gap-12">
        <Icon icon={GranulateIcon} className="icon" />
        <div className="text-group flex flex-column">
          <span className="heading-text modal-heading">
            {t('subMenu.granulate')}
          </span>
          <div className="flex flex-align-items-center flex-gap-4">
            <span className="font-caption-bold">
              {selectedService?.serviceDisplayName}
            </span>
          </div>
        </div>
      </div>
      <Button
        title={t('granulateInsightDashboard.switchDashboard')}
        iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
        onClick={() => {
          setShowServicesModal(true);
        }}
      />
      {showServicesModal && (
        <ServiceModalDashboard
          show={showServicesModal}
          setShow={setShowServicesModal}
        />
      )}
    </header>
  );
};

export default GranulateInsightHeader;
