import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SuccessIcon } from 'assets/icons';
import ProviderList from 'components/ConnectionProviderList';
import ErrorComponent from 'components/ErrorComponent';
import SuccessComponent from 'components/SuccessComponent';
import ProgressBar from 'components/ProgressBar';
import NavigationPath from 'components/NavigationPath';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { providerList } from 'redux/providerSlice';
import {
  selectCostAllocation,
  setCostAllocationComponent,
  setCostAllocationData,
  setCurrentStep,
} from 'redux/costAllocationSlice';
import { CostAllocationComponents } from 'pages/CostAllocationPage/constants';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { PROVIDER } from 'constants/cloudProviders';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { defaultCostAllocationData } from 'constants/defaultValues';

import GCPCostAllocationConnectionForm from './GCP/CostAllocationConnectionForm';
import AwsCostAllocationConnectionForm from './AWS/CostAllocationConnectionForm';
import AzureCostAllocationConnectionForm from './Azure/CostAllocationConnectionForm';
import Footer from './Footer';
import { PROGRESS_BAR_ITEMS } from './constants';

import './index.scss';

const CostAllocationForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedProvider } = useSelector(providerList);
  const { costAllocationComponent, currentStep } =
    useSelector(selectCostAllocation);

  useEffect(() => {
    addZeroMarginClass();
    dispatch(setCurrentStep(0));

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    dispatch(setCostAllocationData(defaultCostAllocationData));
  }, [selectedProvider]);

  /**
   * @function getConnectionAndAllocationFormComponent
   * @description Function to get the connections and allocations form component for the cloud provider selected
   */
  const getConnectionAndAllocationFormComponent = () => {
    switch (selectedProvider) {
      case PROVIDER.GCP:
        return <GCPCostAllocationConnectionForm />;

      case PROVIDER.AWS:
        return <AwsCostAllocationConnectionForm />;

      case PROVIDER.AZURE:
        return <AzureCostAllocationConnectionForm />;
    }
  };

  /**
   * @function getComponent
   * @description Function to get the cost allocation component
   */
  const getComponent = () => {
    const { ALLOCATION_FORM, SUCCESS_PAGE, ERROR_PAGE } =
      CostAllocationComponents;

    switch (costAllocationComponent) {
      case ALLOCATION_FORM:
        return (
          <div className="allocation-form-wrapper flex">
            <ProgressBar
              items={PROGRESS_BAR_ITEMS}
              current={currentStep}
              additionalClassNames="new-styled-scroll width-30"
              heading={t('costAllocations.heading')}
              subHeading={t('costAllocations.subHeading', {
                stepCount: PROGRESS_BAR_ITEMS.length,
              })}
            />
            <div className="flex flex-column flex-space-between width-70">
              <div
                className={`form-content full-height new-styled-scroll ${
                  currentStep === 3 && 'business-unit-page'
                }`}
              >
                {currentStep === 0 ? (
                  <ProviderList disabledList={[PROVIDER.OCI]} />
                ) : (
                  <div className="flex flex-column flex-gap-24">
                    <div className="form-header flex flex-column flex-gap-8 flex-center">
                      <img
                        src={getProviderSmallLogo(selectedProvider)}
                        alt="Provider Icon"
                      />
                      <span className="modal-heading">
                        {PROGRESS_BAR_ITEMS[currentStep].title}
                      </span>
                      <span className="table-typography">
                        {PROGRESS_BAR_ITEMS[currentStep].description}
                      </span>
                    </div>
                    {getConnectionAndAllocationFormComponent()}
                  </div>
                )}
              </div>
              <Footer
                selectedProvider={selectedProvider}
                itemsLength={PROGRESS_BAR_ITEMS.length}
              />
            </div>
          </div>
        );

      case SUCCESS_PAGE:
        return (
          <SuccessComponent
            mainTitle={t('costAllocations.successPage.header')}
            subTitle={t('costAllocations.successPage.subHeader')}
            buttonTitle={t('costAllocations.successPage.gotoDashboard')}
            linkTitle={t('costAllocations.successPage.createAllocation')}
            onHandleButtonClick={() =>
              navigate(NAVIGATION_MENU_PATH.COST_ALLOCATION_DASHBOARD)
            }
            onHandleLinkClick={() => {
              dispatch(
                setCostAllocationComponent(
                  CostAllocationComponents.ALLOCATION_FORM
                )
              );
              dispatch(setCurrentStep(0));
              dispatch(setCostAllocationData(defaultCostAllocationData));
            }}
            icon={<SuccessIcon />}
          />
        );
      case ERROR_PAGE:
        return (
          <ErrorComponent
            mainTitle={t('costAllocations.errorPage.header')}
            buttonTitle={t('costAllocations.errorPage.tryAgain')}
            linkTitle={t('costAllocations.errorPage.gotoDashboard')}
            onHandleButtonClick={() => {
              dispatch(
                setCostAllocationComponent(
                  CostAllocationComponents.ALLOCATION_FORM
                )
              );
            }}
            onHandleLinkClick={() =>
              navigate(NAVIGATION_MENU_PATH.COST_ALLOCATION_DASHBOARD)
            }
          />
        );
    }
  };

  return (
    <div className="cost-allocation-form">
      <header className="cost-allocation-page-header new-page-header modal-heading flex flex-align-items-center">
        {t('costAllocations.createCostAllocation')}
      </header>
      <div className="page-content">
        <NavigationPath />
        {getComponent()}
      </div>
    </div>
  );
};

export default CostAllocationForm;
