import i18n from 'i18n';
import { uniqBy } from 'lodash';

import { PROVIDER } from 'constants/cloudProviders';
import {
  AGGREGATORS,
  COMPARATORS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { store } from 'redux/store';
import { DashboardMetaData } from 'types/dashboard';
import { formatArrayToStringByProvider } from 'utils/dataFormatterUtils';
import {
  getAWSTagFiltersData,
  getAzureTagFiltersData,
  getGCPTagFiltersData,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import {
  DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  enumerateDaysBetweenDates,
  formatDateFieldByProvider,
  getMonthYearShortList,
} from 'utils/date';

import { ExcelData } from '../../../types';
import {
  getAccountLabelByProvider,
  getAccountWithGroupingValuesColumns,
  getAWSUnitCostTimePeriodQuery,
  getAzureUnitCostTimePeriodQuery,
  getChartExcelDataWithDateFilter,
  getDualAxisCostByTypeTableData,
  getEc2UsagePercentageTableData,
  getGcpUnitCostTimePeriodQuery,
  getUsageCostTableData,
} from '../../../utils';

/**
 * @function getAwsEc2ComputeUnitCostByPurchaseOptionsQuery
 * @description Function to return the Ec2 Compute Average Unit Cost By Purchase Options query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsEc2ComputeUnitCostByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_TERM,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAWSUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['pricingTerm', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EC2,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_TERM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureEc2ComputeUnitCostByPurchaseOptionsQuery
 * @description Function to return the Ec2 Compute Average Unit Cost By Purchase Options query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureEc2ComputeUnitCostByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_MODEL,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAzureUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.VIRTUAL_MACHINES,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpComputeUnitCostByPurchaseOptionsQuery
 * @description Function to return the Ec2 Compute Average Unit Cost By Purchase Options query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpComputeUnitCostByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getGcpUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.COMPUTE_ENGINE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getEc2ComputeUnitCostByPurchaseOptionsQuery
 * @description Function to return the Ec2 Compute Average Unit Cost By Purchase Options query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getEc2ComputeUnitCostByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureEc2ComputeUnitCostByPurchaseOptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEc2ComputeUnitCostByPurchaseOptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpComputeUnitCostByPurchaseOptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsEc2UsageByPlatformQuery
 * @description Function to return the EC2 Normalized Hours by Platform query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsEc2UsageByPlatformQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'os',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_OPERATING_SYSTEM,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['os', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EC2,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_OPERATING_SYSTEM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureEc2UsageByPlatformQuery
 * @description Function to return the EC2 Normalized Hours by Platform query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureEc2UsageByPlatformQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'os',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.METER_SUB_CATEGORY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['os', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.VIRTUAL_MACHINES,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpComputeEngineUsageBySKUQuery
 * @description Function to return the GCP Compute Engine Usage by SKU Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpComputeEngineUsageBySKUQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'os',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SKU_DESCRIPTION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['os', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.COMPUTE_ENGINE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getEc2UsageByPlatformQuery
 * @description Function to return the EC2 Normalized Hours by Platform query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getEc2UsageByPlatformQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureEc2UsageByPlatformQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEc2UsageByPlatformQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpComputeEngineUsageBySKUQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsEc2ElasticityQuery
 * @description Function to return the EC2 Elasticity query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsEc2ElasticityQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_TERM,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: isStaticData
          ? `FORMAT_TIMESTAMP('%Y-%m-%d %H:00:00', ${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )})`
          : `DATE_FORMAT(${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )}, '%Y-%m-%d %H:00:00')`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EC2,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_TERM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpComputeEngineElasticityQuery
 * @description Function to return the Compute Engine Elasticity query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpComputeEngineElasticityQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: `FORMAT_TIMESTAMP('%Y-%m-%d %H:00:00', ${getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.USAGE_END_TIME,
          dashboardMetaData.connection.focusConversionEnabled
        )})`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.COMPUTE_ENGINE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureEc2ElasticityQuery
 * @description Function to return the EC2 Elasticity query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureEc2ElasticityQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_MODEL,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE
            : QUERY_FIELDS.CONVERT_DATETIME_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.VIRTUAL_MACHINES,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getEc2ElasticityQuery
 * @description Function to return the EC2 Elasticity query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getEc2ElasticityQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureEc2ElasticityQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEc2ElasticityQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpComputeEngineElasticityQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsEc2RunningHoursCostByAccountQuery
 * @description Function to return the EC2 Running Hours Cost by Account query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsEc2RunningHoursCostByAccountQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_TERM,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['account', 'pricingTerm'],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EC2,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_TERM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getGCPComputeEngineRunningHoursCostBySKUQuery
 * @description Function to return the GCP Compute Engine Running Hours Cost By SKU Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGCPComputeEngineRunningHoursCostBySKUQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PROJECT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['account', 'pricingTerm'],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.COMPUTE_ENGINE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
  };
};

/**
 * @function getAzureEc2RunningHoursCostByAccountQuery
 * @description Function to return the EC2 Running Hours Cost by Account query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureEc2RunningHoursCostByAccountQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SUBSCRIPTION_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_MODEL,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['account', 'pricingTerm'],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.VIRTUAL_MACHINES,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getEc2RunningHoursCostByAccountQuery
 * @description Function to return the EC2 Running Hours Cost by Account query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getEc2RunningHoursCostByAccountQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureEc2RunningHoursCostByAccountQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEc2RunningHoursCostByAccountQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGCPComputeEngineRunningHoursCostBySKUQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsEc2CostByInstanceFamilyQuery
 * @description Function to return the EC2 Daily Cost by Instance Family query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsEc2CostByInstanceFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'instanceFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_INSTANCE_TYPE_FAMILY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: isStaticData
          ? `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )})`
          : `DATE_FORMAT(${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )}, '%Y-%m-%d')`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['instanceFamily', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EC2,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_TERM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpComputeEngineCostBySKUQuery
 * @description Function to return the Compute Engine Daily Cost by SKU for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpComputeEngineCostBySKUQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'instanceFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SKU_DESCRIPTION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.USAGE_END_TIME,
          dashboardMetaData.connection.focusConversionEnabled
        )})`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['instanceFamily', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.COMPUTE_ENGINE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureEc2CostByInstanceFamilyQuery
 * @description Function to return the EC2 Daily Cost by Instance Family query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureEc2CostByInstanceFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'instanceFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.ADDITIONAL_INFO_SERVICE_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE
            : QUERY_FIELDS.CONVERT_DATETIME_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['instanceFamily', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.VIRTUAL_MACHINES,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getEc2CostByInstanceFamilyQuery
 * @description Function to return the EC2 Daily Cost by Instance Family query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getEc2CostByInstanceFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureEc2CostByInstanceFamilyQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEc2CostByInstanceFamilyQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpComputeEngineCostBySKUQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsEc2RunningHoursCostByInstanceFamilyQuery
 * @description Function to return the EC2 Running Hours Cost by Instance Family query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsEc2RunningHoursCostByInstanceFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'instanceFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_INSTANCE_TYPE_FAMILY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_TERM,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['instanceFamily', 'pricingTerm'],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EC2,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_TERM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_INSTANCE_TYPE_FAMILY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getGcpComputeEngineRunningHoursCostBySKUQuery
 * @description Function to return the GCP Compute Engine Running Hours Cost by SKU query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpComputeEngineRunningHoursCostBySKUQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'instanceFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SKU_DESCRIPTION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['instanceFamily', 'pricingTerm'],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.COMPUTE_ENGINE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
  };
};

/**
 * @function getAzureEc2RunningHoursCostByInstanceFamilyQuery
 * @description Function to return the EC2 Running Hours Cost by Instance Family query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureEc2RunningHoursCostByInstanceFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'instanceFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.ADDITIONAL_INFO_SERVICE_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_MODEL,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['instanceFamily', 'pricingTerm'],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.VIRTUAL_MACHINES,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getEc2RunningHoursCostByInstanceFamilyQuery
 * @description Function to return the EC2 Running Hours Cost by Instance Family query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getEc2RunningHoursCostByInstanceFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureEc2RunningHoursCostByInstanceFamilyQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEc2RunningHoursCostByInstanceFamilyQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpComputeEngineRunningHoursCostBySKUQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/** @function getEC2CpuCreditsUsageCostByAccountsQuery
 * @description Function to return the the EC2 CPU Credits Usage Cost by Accounts query
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getEC2CpuCreditsUsageCostByAccountsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const { tagsFilters, selectedDashboard, selectedDashboardView } =
    store.getState().dashboard;

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  return {
    columns: [
      {
        label: 'accountId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.BILL_PAYER_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['accountId', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EC2,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_OPERATION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.T3_CPU_CREDITS,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        tagsFilters,
        selectedDashboard?.id,
        selectedDashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAwsTopLambdaAccountsByCostQuery
 * @description Function to return the Top Lambda Accounts By Cost query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsTopLambdaAccountsByCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAWSUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['month', 'account'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AWS_LAMBDA,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getGcpTopCloudRunAccountsByCostQuery
 * @description Function to return the Top Cloud Run/Functions Accounts By Cost query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpTopCloudRunAccountsByCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PROJECT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getGcpUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['month', 'account'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.CLOUD_RUN,
                QUERY_VALUES.CLOUD_RUN_FUNCTIONS,
                QUERY_VALUES.CLOUD_FUNCTIONS,
              ],
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
  };
};

/**
 * @function getAzureTopLambdaAccountsByCostQuery
 * @description Function to return the Top Lambda Accounts By Cost query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureTopLambdaAccountsByCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SUBSCRIPTION_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAzureUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['month', 'account'],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.FUNCTIONS,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getTopLambdaAccountsByCostQuery
 * @description Function to return the Top Lambda Accounts By Cost query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getTopLambdaAccountsByCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureTopLambdaAccountsByCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsTopLambdaAccountsByCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpTopCloudRunAccountsByCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsLambdaByPurchaseOptionsQuery
 * @description Function to return the Lambda By Purchase Options query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsLambdaByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_TERM,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AWS_LAMBDA,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_TERM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpCloudFunctionsByPurchaseOptionsQuery
 * @description Function to return the Cloud Run/Functions By Purchase Options query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpCloudFunctionsByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.CLOUD_RUN,
                QUERY_VALUES.CLOUD_RUN_FUNCTIONS,
                QUERY_VALUES.CLOUD_FUNCTIONS,
              ],
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureLambdaByPurchaseOptionsQuery
 * @description Function to return the Lambda By Purchase Options query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureLambdaByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_MODEL,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.FUNCTIONS,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getLambdaByPurchaseOptionsQuery
 * @description Function to return the Lambda By Purchase Options query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getLambdaByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureLambdaByPurchaseOptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsLambdaByPurchaseOptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpCloudFunctionsByPurchaseOptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsTopLambdasByResourceIdQuery
 * @description Function to return the Top Lambdas By Resource Id query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsTopLambdasByResourceIdQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'resourceId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['account', 'resourceId'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AWS_LAMBDA,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpTopFunctionsByResourceIdQuery
 * @description Function to return the Top Cloud Run/Functions By Resource Id query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpTopFunctionsByResourceIdQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PROJECT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'resourceId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.BILLING_RESOURCE_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['account', 'resourceId'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.CLOUD_RUN,
                QUERY_VALUES.CLOUD_RUN_FUNCTIONS,
                QUERY_VALUES.CLOUD_FUNCTIONS,
              ],
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureTopLambdasByResourceIdQuery
 * @description Function to return the Top Lambdas By Resource Id query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureTopLambdasByResourceIdQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SUBSCRIPTION_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'resourceId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.RESOURCE_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['account', 'resourceId'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.FUNCTIONS,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getTopLambdasByResourceIdQuery
 * @description Function to return the Top Lambdas By Resource Id query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @returns Object containing the query
 */
export const getTopLambdasByResourceIdQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureTopLambdasByResourceIdQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsTopLambdasByResourceIdQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpTopFunctionsByResourceIdQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsTopLambdasByDailyCostQuery
 * @description Function to return the Top Lambdas By Daily Cost query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsTopLambdasByDailyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'resourceId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: isStaticData
          ? `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )})`
          : `DATE_FORMAT(${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )}, '%Y-%m-%d')`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['resourceId', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AWS_LAMBDA,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpTopFunctionsByDailyCostQuery
 * @description Function to return the Top Cloud Run/Functions By Daily Cost query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpTopFunctionsByDailyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'resourceId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.BILLING_RESOURCE_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.USAGE_END_TIME,
          dashboardMetaData.connection.focusConversionEnabled
        )})`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['resourceId', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.CLOUD_RUN,
                QUERY_VALUES.CLOUD_RUN_FUNCTIONS,
                QUERY_VALUES.CLOUD_FUNCTIONS,
              ],
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureTopLambdasByDailyCostQuery
 * @description Function to return the Top Lambdas By Daily Cost query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureTopLambdasByDailyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'resourceId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.RESOURCE_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE
            : QUERY_FIELDS.CONVERT_DATETIME_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['resourceId', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.FUNCTIONS,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getTopLambdasByDailyCostQuery
 * @description Function to return the Top Lambdas By Daily Cost query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @returns Object containing the query
 */
export const getTopLambdasByDailyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureTopLambdasByDailyCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsTopLambdasByDailyCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpTopFunctionsByDailyCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsFargateAccountsByCostQuery
 * @description Function to return the the Fargate Accounts by Cost query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsFargateAccountsByCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'accountId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.BILL_PAYER_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAWSUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['accountId', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_OPERATION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.FARGATE_TASK,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpKubernetesEngineProjectsByCostQuery
 * @description Function to return the theKubernetes Engine Projects by Cost query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpKubernetesEngineProjectsByCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'accountId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PROJECT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getGcpUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['accountId', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.KUBERNETES_ENGINE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureFargateAccountsByCostQuery
 * @description Function to return the the Fargate Accounts by Cost query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureFargateAccountsByCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'accountId',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SUBSCRIPTION_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAzureUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['accountId', 'time'],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.CONTAINER_INSTANCES,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getFargateAccountsByCostQuery
 * @description Function to return the the Fargate Accounts by Cost query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getFargateAccountsByCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureFargateAccountsByCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsFargateAccountsByCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpKubernetesEngineProjectsByCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAwsFargateCostByPurchaseOptionsQuery
 * @description Function to return the the Fargate Cost By Purchase Options query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsFargateCostByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_TERM,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_OPERATION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.FARGATE_TASK,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_TERM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpKubernetesEnginesCostByPurchaseOptionsQuery
 * @description Function to return the the Kubernetes Engine Cost By Purchase Options query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpKubernetesEnginesCostByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.KUBERNETES_ENGINE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureFargateCostByPurchaseOptionsQuery
 * @description Function to return the the Fargate Cost By Purchase Options query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureFargateCostByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_MODEL,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.CONTAINER_INSTANCES,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getFargateCostByPurchaseOptionsQuery
 * @description Function to return the the Fargate Cost By Purchase Options query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getFargateCostByPurchaseOptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureFargateCostByPurchaseOptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsFargateCostByPurchaseOptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpKubernetesEnginesCostByPurchaseOptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getFargateEcsEksCostQuery
 * @description Function to return the the Fargate ECS and EKS Cost query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getFargateEcsEksCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const { tagsFilters } = store.getState().dashboard;

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  return {
    columns: [
      {
        label: 'fargateCluster',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.BILL_PAYER_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'ecsEksOperation',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.BILL_PAYER_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['fargateCluster', 'ecsEksOperation'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_OPERATION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.FARGATE_TASK,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EKS,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_ECS,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.EKS_CONTROL_PANEL,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        tagsFilters,
        dashboardMetaData.dashboard?.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAwsFargateTopClustersByDailyCostQuery
 * @description Function to return the the Fargate Top Clusters By Daily Cost query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAwsFargateTopClustersByDailyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.BILL_PAYER_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: isStaticData
          ? `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )})`
          : `DATE_FORMAT(${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )}, '%Y-%m-%d')`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['time', 'account'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],

    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_OPERATION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.FARGATE_TASK,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpKubernetesEngineTopClustersByDailyCostQuery
 * @description Function to return the the Kubernetes Engine Top Clusters By Daily Cost query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getGcpKubernetesEngineTopClustersByDailyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PROJECT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.USAGE_END_TIME,
          dashboardMetaData.connection.focusConversionEnabled
        )})`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['time', 'account'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],

    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.KUBERNETES_ENGINE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureFargateTopClustersByDailyCostQuery
 * @description Function to return the the Fargate Top Clusters By Daily Cost query for AZURE
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getAzureFargateTopClustersByDailyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SUBSCRIPTION_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE
            : QUERY_FIELDS.CONVERT_DATETIME_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['time', 'account'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],

    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.CONTAINER_INSTANCES,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getFargateTopClustersByDailyCostQuery
 * @description Function to return the the Fargate Top Clusters By Daily Cost query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @returns Object containing the query
 */
export const getFargateTopClustersByDailyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureFargateTopClustersByDailyCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsFargateTopClustersByDailyCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpKubernetesEngineTopClustersByDailyCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getEc2CoverageByPurchaseOptionsColumns
 * @description Function to return the Ec2 Coverage By Purchase Options Columns
 * @param dateRange of dates for which the columns are required
 * @returns List of columns
 */
export const getEc2CoverageByPurchaseOptionsColumns = (dateRange: string[]) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: i18n.t('computeDashboard.purchaseOption'),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...getMonthYearShortList(dateRange[0], dateRange[1], MONTH_YEAR_FORMAT).map(
      (month) => ({
        title: month,
        key: `${month}-value`,
        dataIndex: `${month}-value`,
        width: 100,
      })
    ),
  ];
};

/**
 * @function getEc2UsageByPlatformColumns
 * @description Function to return the EC2 Normalized Hours by Platform Columns
 * @param dateRange Range of dates for which the columns are required
 * @param provider Selected dashboard provider
 * @returns List of columns
 */
export const getEc2UsageByPlatformColumns = (
  dateRange: string[],
  provider: string
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: getPlatformLabelByProvider(provider),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...getMonthYearShortList(dateRange[0], dateRange[1], MONTH_YEAR_FORMAT).map(
      (month) => ({
        title: month,
        key: `${month}-value`,
        dataIndex: `${month}-value`,
        width: 100,
      })
    ),
  ];
};

/**
 * @function getEc2ElasticityColumns
 * @description Function to return the Ec2 Elasticity Columns
 * @param dateRange of dates for which the columns are required
 * @returns List of columns
 */
export const getEc2ElasticityColumns = (dateRange: string[]) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: i18n.t('computeDashboard.purchaseOptionOrCost'),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...enumerateDaysBetweenDates(dateRange).map((time) => ({
      title: time,
      key: `${time}-value`,
      dataIndex: `${time}-value`,
      width: 100,
    })),
  ];
};

/**
 * @function getPurchaseOptionWithGroupingValuesColumns
 * @description Function to return the Purchase option by grouping values Columns
 * @param groupingValues List of grouping values for which the columns are required
 * @returns List of columns
 */
export const getPurchaseOptionWithGroupingValuesColumns = (
  groupingValues: string[]
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: i18n.t('computeDashboard.purchaseOption'),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...groupingValues.map((groupingValue) => ({
      title: groupingValue,
      key: `${groupingValue}-value`,
      dataIndex: `${groupingValue}-value`,
      width: 100,
    })),
  ];
};

/**
 * @function getResourceIdWithDailyCostColumns
 * @description Function to return the Resource Id with Daily Cost Columns
 * @param dateRange of dates for which the columns are required
 * @returns List of columns
 */
export const getResourceIdWithDailyCostColumns = (dateRange: string[]) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      fixed: true,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: i18n.t('computeDashboard.resourceId'),
      dataIndex: 'type',
      key: 'type',
      width: 250,
    },
    ...enumerateDaysBetweenDates(dateRange, DATE_FORMAT, 'day').map((time) => ({
      title: time,
      key: `${time}-value`,
      dataIndex: `${time}-value`,
      width: 100,
    })),
  ];
};

/**
 * @function getEc2CostByInstanceFamilyColumns
 * @description Function to return the EC2 Daily Cost by Instance Family Columns
 * @param dateRange of dates for which the columns are required
 * @param provider Selected dashboard provider
 * @returns List of columns
 */
export const getEc2CostByInstanceFamilyColumns = (
  dateRange: string[],
  provider: string
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: getInstanceFamilyLabelByProvider(provider),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...enumerateDaysBetweenDates(dateRange, DATE_FORMAT, 'day').map((time) => ({
      title: time,
      key: `${time}-value`,
      dataIndex: `${time}-value`,
      width: 100,
    })),
  ];
};

/**
 * @function getDailyCostByAccountsColumns
 * @description Function to return the Daily Cost by Accounts Columns
 * @param dateRange Range of dates for which the columns are required
 * @param provider selected dashboard provider
 * @returns List of columns
 */
export const getDailyCostByAccountsColumns = (
  dateRange: string[],
  provider: string
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: getAccountLabelByProvider(provider),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...enumerateDaysBetweenDates(dateRange, DATE_FORMAT, 'day').map((time) => ({
      title: time,
      key: `${time}-value`,
      dataIndex: `${time}-value`,
      width: 100,
    })),
  ];
};

/**
 * @function getMonthlyCostByAccountsColumns
 * @description Function to return the the Monthly Cost by Accounts Columns
 * @param dateRange Range of dates for which the columns are required
 * @param provider Selected dashboard provider
 * @returns List of columns
 */
export const getMonthlyCostByAccountsColumns = (
  dateRange: string[],
  provider: string
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: getAccountLabelByProvider(provider),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...getMonthYearShortList(dateRange[0], dateRange[1], MONTH_YEAR_FORMAT).map(
      (month) => ({
        title: month,
        key: `${month}-value`,
        dataIndex: `${month}-value`,
        width: 100,
      })
    ),
  ];
};

/**
 * @function getFargateClusterEksAndEcsCostColumns
 * @description Function to return the Fargate Cluster EKS and ECS Cost Columns
 * @param groupingValues List of grouping values for which the columns are required
 * @returns List of columns
 */
export const getFargateClusterEksAndEcsCostColumns = (
  groupingValues: string[]
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: i18n.t('computeDashboard.fargateCluster'),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...groupingValues.map((groupingValue) => ({
      title: groupingValue,
      key: `${groupingValue}-value`,
      dataIndex: `${groupingValue}-value`,
      width: 100,
    })),
  ];
};

/**
 * @function getComputeExcelData
 * @description Returns the excel export data for compute
 * @param provider name of the provider
 * @param connectorName name of the connection chosen
 * @param selectedAccounts List of accounts selected
 * @param data export data for all tables
 * @returns List of excel export data
 */
export const getComputeExcelData = (
  provider: string,
  connectorName: string,
  selectedAccounts: string[],
  data: {
    ec2ComputeByPOData: ExcelData;
    ec2CoverageByPOData: ExcelData;
    ec2UsageByPlatformData: ExcelData;
    ec2ElasticityData: ExcelData;
    ec2RunningHoursCostByAccountData: ExcelData;
    ec2CostByInstanceFamily: ExcelData;
    ec2RunningHoursCostByInstanceFamily: ExcelData;
    topLambdaAccountsByCost: ExcelData;
    lambdaByPurchaseOption: ExcelData;
    topLambdasByResourceId: ExcelData;
    topLambdasByDailyCost: ExcelData;
    ec2CpuCreditsUsageCostByAccounts: ExcelData;
    fargateAccountsByCost: ExcelData;
    fargateCostByPO: ExcelData;
    fargateEcsEksCost: ExcelData;
    fargateTopClustersByDailyCost: ExcelData;
  }
) => {
  return [
    getChartExcelDataWithDateFilter({
      ...data.ec2ComputeByPOData,
      columns: getPurchaseOptionWithGroupingValuesColumns(
        getMonthYearShortList(
          data.ec2ComputeByPOData.dateRange[0],
          data.ec2ComputeByPOData.dateRange[1],
          MONTH_YEAR_FORMAT
        )
      ),
      data: getUsageCostTableData(data.ec2ComputeByPOData.data, 'time'),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.ec2CoverageByPOData,
      columns: getEc2CoverageByPurchaseOptionsColumns(
        data.ec2CoverageByPOData.dateRange
      ),
      data: getEc2UsagePercentageTableData(data.ec2CoverageByPOData.data),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.ec2UsageByPlatformData,
      columns: getEc2UsageByPlatformColumns(
        data.ec2UsageByPlatformData.dateRange,
        provider
      ),
      data: getEc2UsagePercentageTableData(data.ec2UsageByPlatformData.data),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.ec2ElasticityData,
      columns: getEc2ElasticityColumns(data.ec2ElasticityData.dateRange),
      data: getDualAxisCostByTypeTableData(data.ec2ElasticityData.data),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.ec2RunningHoursCostByAccountData,
      columns: getPurchaseOptionWithGroupingValuesColumns(
        uniqBy(data.ec2RunningHoursCostByAccountData.data, 'account').map(
          (item: any) => item.account
        )
      ),
      data: getUsageCostTableData(
        data.ec2RunningHoursCostByAccountData.data,
        'account'
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.ec2CostByInstanceFamily,
      columns: getEc2CostByInstanceFamilyColumns(
        data.ec2CostByInstanceFamily.dateRange,
        provider
      ),
      data: getDualAxisCostByTypeTableData(data.ec2CostByInstanceFamily.data),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.ec2RunningHoursCostByInstanceFamily,
      columns: getPurchaseOptionWithGroupingValuesColumns(
        uniqBy(
          data.ec2RunningHoursCostByInstanceFamily.data,
          'instanceFamily'
        ).map((item: any) => item.instanceFamily)
      ),
      data: getUsageCostTableData(
        data.ec2RunningHoursCostByInstanceFamily.data,
        'instanceFamily'
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
    {
      ...getChartExcelDataWithDateFilter({
        ...data.ec2CpuCreditsUsageCostByAccounts,
        columns: getMonthlyCostByAccountsColumns(
          data.ec2CpuCreditsUsageCostByAccounts.dateRange,
          provider
        ),
        data: getUsageCostTableData(
          data.ec2CpuCreditsUsageCostByAccounts.data,
          'time'
        ),
        connectorName,
        provider,
        selectedAccounts,
      }),
      excludeInGlobalDownload:
        !hasEc2CpuCreditsUsageCostByAccountsChart(provider),
    },
    getChartExcelDataWithDateFilter({
      ...data.topLambdaAccountsByCost,
      columns: getMonthlyCostByAccountsColumns(
        data.topLambdaAccountsByCost.dateRange,
        provider
      ),
      data: getUsageCostTableData(data.topLambdaAccountsByCost.data, 'time'),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.lambdaByPurchaseOption,
      columns: getPurchaseOptionWithGroupingValuesColumns(
        uniqBy(data.lambdaByPurchaseOption.data, 'time').map(
          (item: any) => item.time
        )
      ),
      data: getUsageCostTableData(data.lambdaByPurchaseOption.data, 'time'),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.topLambdasByResourceId,
      columns: getAccountWithGroupingValuesColumns(
        uniqBy(data.topLambdasByResourceId.data, 'resourceId').map(
          (item: any) => item.resourceId
        ),
        getAccountLabelByProvider(provider)
      ),
      data: getUsageCostTableData(
        data.topLambdasByResourceId.data,
        'resourceId'
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.topLambdasByDailyCost,
      columns: getResourceIdWithDailyCostColumns(
        data.topLambdasByDailyCost.dateRange
      ),
      data: getUsageCostTableData(data.topLambdasByDailyCost.data, 'time'),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.fargateAccountsByCost,
      columns: getMonthlyCostByAccountsColumns(
        data.fargateAccountsByCost.dateRange,
        provider
      ),
      data: getUsageCostTableData(data.fargateAccountsByCost.data, 'time'),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.fargateCostByPO,
      columns: getPurchaseOptionWithGroupingValuesColumns(
        getMonthYearShortList(
          data.fargateCostByPO.dateRange[0],
          data.fargateCostByPO.dateRange[1],
          MONTH_YEAR_FORMAT
        )
      ),
      data: getUsageCostTableData(data.fargateCostByPO.data, 'time'),
      connectorName,
      provider,
      selectedAccounts,
    }),
    {
      ...getChartExcelDataWithDateFilter({
        ...data.fargateEcsEksCost,
        columns: getFargateClusterEksAndEcsCostColumns(
          uniqBy(data.fargateEcsEksCost.data, 'operation').map(
            (item: any) => item.operation
          )
        ),
        data: getUsageCostTableData(data.fargateEcsEksCost.data, 'operation'),
        connectorName,
        provider,
        selectedAccounts,
      }),
      excludeInGlobalDownload:
        !hasEcsEksFargateAndEksControlPanelCost(provider),
    },
    getChartExcelDataWithDateFilter({
      ...data.fargateTopClustersByDailyCost,
      columns: getDailyCostByAccountsColumns(
        data.fargateTopClustersByDailyCost.dateRange,
        provider
      ),
      data: getUsageCostTableData(
        data.fargateTopClustersByDailyCost.data,
        'time'
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
  ];
};

/**
 * @function getEc2LabelByProvider
 * @description Function to return the EC2 label by provider
 * @param provider Provider name
 * @param awsIncludeComputeInLabel boolean to indicate if the label should include the word "Compute" for Aws
 * @returns label based on the provider
 */
export const getEc2LabelByProvider = (
  provider: string,
  awsIncludeComputeInLabel = false
) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('virtualMachines');

    case PROVIDER.AWS:
      return awsIncludeComputeInLabel ? i18n.t('ec2Compute') : i18n.t('ec2');

    case PROVIDER.GCP:
    default:
      return i18n.t('computeEngine');
  }
};

/**
 * @function getInstanceFamilyLabelByProvider
 * @description Function to return the Instance Family label by provider
 * @param provider Provider name
 * @returns label based on the provider
 */
export const getInstanceFamilyLabelByProvider = (provider: string) =>
  provider === PROVIDER.GCP ? i18n.t('sku') : i18n.t('instanceFamily');

/**
 * @function getPlatformLabelByProvider
 * @description Function to return the Platform label by provider
 * @param provider Provider name
 * @returns label based on the provider
 */
export const getPlatformLabelByProvider = (provider: string) =>
  provider === PROVIDER.GCP ? i18n.t('sku') : i18n.t('platform');

/**
 * @function getLambdaLabelByProvider
 * @description Function to return the Lambda label by provider
 * @param provider Provider name
 * @returns label based on the provider
 */
export const getLambdaLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('functions');

    case PROVIDER.AWS:
      return i18n.t('lambda');

    case PROVIDER.GCP:
    default:
      return i18n.t('cloudRunOrFunctions');
  }
};

/**
 * @function getTopLambdasByResourceIdGraphHeader
 * @description Function to return the Top Lambdas By Resource Id graph header
 * @param provider Provider name
 * @returns header based on the provider
 */
export const getTopLambdasByResourceIdGraphHeader = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('graphHeadings.topFunctionsByResourceName');

    case PROVIDER.AWS:
      return i18n.t('graphHeadings.topLambdasByResourceId');

    case PROVIDER.GCP:
    default:
      return i18n.t('graphHeadings.topCloudRunOrFunctionsByResourceName');
  }
};

/**
 * @function getFargateLabelByProvider
 * @description Function to return the Fargate label by provider
 * @param provider Provider name
 * @returns label based on the provider
 */
export const getFargateLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('containerInstances');

    case PROVIDER.AWS:
      return i18n.t('fargate');

    case PROVIDER.GCP:
    default:
      return i18n.t('kubernetesEngines');
  }
};

/**
 * @function hasEcsEksFargateAndEksControlPanelCost
 * @description Function to check if the Ecs Eks Fargate and Eks Control Panel Cost should be displayed
 * @param provider Provider name
 * @returns boolean indicating if the cost should be displayed
 */
export const hasEcsEksFargateAndEksControlPanelCost = (provider: string) =>
  provider === PROVIDER.AWS;

/**
 * @function hasEc2CpuCreditsUsageCostByAccountsChart
 * @description Function to check if the EC2 Cpu Credits Usage Cost By Accounts chart should be displayed
 * @param provider Provider name
 * @returns boolean indicating if the chart should be displayed
 */
export const hasEc2CpuCreditsUsageCostByAccountsChart = (provider: string) =>
  provider === PROVIDER.AWS;
