import i18n from 'i18n';
import moment from 'moment';

import { store } from 'redux/store';
import {
  MonthlyCostType,
  ColouredCostByServiceType,
  ColouredCostByResourceType,
  MonthlyCostByServiceType,
} from 'types/dataTypes';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  getGCPTagFiltersData,
  getPreviousMonthAndYear,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import {
  formatDateFieldByProvider,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { PROVIDER } from 'constants/cloudProviders';
import {
  QUERY_FIELDS,
  AGGREGATORS,
  ORDER_BY,
  DASHBOARD_TYPES,
  COMPARATORS,
  QUERY_VALUES,
  CONJUNCTIONS,
} from 'constants/requestBody';
import { ConnectionListType, TagsFilterType } from 'types/dashboard';
import { DashboardListType } from 'types/navigationMenu';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

/**
 * @function getCostTrendsExportColumns
 * @description Function to return the columns for excel and pdf export.
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @param data trends graph data for all the drilldown levels
 * @return List of columns for export
 */
export const getCostTrendsData = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined,
  dateRange: string[],
  data: {
    trends?: MonthlyCostType[];
    forecasted?: MonthlyCostType[];
    service?: ColouredCostByServiceType[];
    resource?: ColouredCostByResourceType[];
  }
) => {
  const { trends = [], forecasted = [], service = [], resource = [] } = data;
  if (!selectedTrendMonth) {
    const months = getMonthYearShortList(
      dateRange[0],
      moment(dateRange[1], HYPHEN_DATE_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    const consolidatedData = months.map((month) => ({
      month: month,
      cost: trends.find((item) => item.month === month)?.cost,
      forecastedCost: forecasted.find((item) => item.month === month)?.cost,
    }));

    return consolidatedData;
  }

  if (!selectedTrendService) {
    return service;
  }

  if (selectedTrendService) {
    return resource;
  }

  return [];
};

/**
 * @function getMonthlyCostByProjectColumns
 * @param startDate start date for monthly cost by project graph
 * @param endDate end date for monthly cost by project graph
 * @returns List of columns for monthly cost by project graph
 */
export const getMonthlyCostByProjectColumns = (
  startDate: string,
  endDate: string
) => {
  return [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
      fixed: true,
    },
    {
      title: i18n.t('monthlyCostByProject.projects'),
      dataIndex: 'project',
      key: 'project',
      width: 150,
      fixed: true,
    },
    ...getMonthYearShortList(startDate, endDate, MONTH_YEAR_FORMAT).map(
      (month) => ({
        title: month,
        key: `${month}-cost`,
        dataIndex: `${month}-cost`,
        width: 100,
        render: (text: string) => currencySymbol + numberCommaSeparator(text),
      })
    ),
  ];
};

/**
 * @function getCostByServiceColumns
 * @param selectedMonthlyCostByServiceData selected monthly cost by service data
 * @returns List of columns for cost by service graph
 */
export const getCostByServiceColumns = (
  selectedMonthlyCostByServiceData: MonthlyCostByServiceType[]
) => {
  return [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
      fixed: true,
    },
    {
      title: i18n.t('ytdCostByGCPService.services'),
      dataIndex: 'service',
      key: 'service',
      width: 150,
      fixed: true,
    },
    ...getMonthYearShortList(
      getPreviousMonthAndYear(11),
      getPreviousMonthAndYear(0),
      MONTH_YEAR_FORMAT
    ).map((month) => ({
      title: month,
      dataIndex: `${month}-cost`,
      key: `${month}-cost`,
      width: 100,
      render: (_text: string, record: MonthlyCostByServiceType) =>
        currencySymbol +
        numberCommaSeparator(
          selectedMonthlyCostByServiceData.find(
            (item) => item.month === month && item.service === record.service
          )?.cost ?? 0
        ),
    })),
  ];
};

/**
 * @function getGcpCloudSpendQuery
 * @description Function to return the query for cloud spend
 * @param startDate start date for the query filter
 * @param endDate end date for the query filter
 * @param tagFilterData tag filter data for the query
 * @param selectedDashboard selected dashboard
 * @param selectedConnection Object containing the connection details
 * @returns Query for cloud spend
 */
export const getGcpCloudSpendQuery = (
  startDate: string,
  endDate: string,
  tagFilterData: any[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType
) => {
  const isStaticData = isDashboardWithStaticData(selectedDashboard);
  return {
    columns: [
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.PROJECT_NAME,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [{ label: 'cost', function: AGGREGATORS.SUM }],
    groupBy: ['name'],
    orderBy: [{ label: 'cost', sort: ORDER_BY.DESCENDING }],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startDate,
              PROVIDER.GCP,
              isStaticData,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              endDate,
              PROVIDER.GCP,
              isStaticData,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: [...tagFilterData],
  };
};

/**
 * @function getGcpTaggedUntaggedSpendQuery
 * @description Function to return the query for tagged and untagged spend
 * @param startMonth start month for the query filter
 * @param endMonth end month for the query filter
 * @param tagFilters tag filters for the query
 * @param selectedDashboard selected dashboard
 * @param selectedConnection Object containing the connection details
 * @param selectedDashboardView selected dashboard view
 * @returns List of queries for tagged and untagged spend
 */
export const getGcpTaggedUntaggedSpendQuery = (
  startMonth: string,
  endMonth: string,
  tagFilters: TagsFilterType[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string
) => {
  const selectedTagsFilterGroups = getGCPTagFiltersData(
    tagFilters,
    selectedDashboard.id,
    selectedDashboardView,
    true
  );
  const baseQuery = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.COST,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['name'],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startMonth,
              PROVIDER.GCP,
              false,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              endMonth,
              PROVIDER.GCP,
              false,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
    cached: true,
  };

  if (selectedTagsFilterGroups.length > 0) {
    return [
      {
        ...baseQuery,
        keyValueStructFilterGroups: selectedTagsFilterGroups,
      },
    ];
  }
  return [
    {
      ...baseQuery,
      filterGroups: [
        ...baseQuery.filterGroups,
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.SELECT_STRUCT_PROJECT_LABELS,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.IS,
              value: QUERY_VALUES.NOT_NULL,
            },
          ],
        },
      ],
    },
    {
      ...baseQuery,
      filterGroups: [
        ...baseQuery.filterGroups,
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard.dashBoardType,
                QUERY_FIELDS.SELECT_STRUCT_PROJECT_LABELS,
                selectedConnection.focusConversionEnabled
              ),
              comparator: COMPARATORS.IS,
              value: QUERY_VALUES.NULL,
            },
          ],
        },
      ],
    },
  ];
};

/**
 * @function getGCPServiceSpendProfileQuery
 * @description Function to return the query for service spend profile
 * @param startMonth start month for the query filter
 * @param endMonth end month for the query filter
 * @param selectedDashboard selected dashboard
 * @param selectedConnection Object containing the connection details
 * @param selectedDashboardView selected dashboard view
 * @param tagFilters tag filters for the query
 * @returns Query for service spend profile
 */
export const getGCPServiceSpendProfileQuery = (
  startMonth: string,
  endMonth: string,
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  tagFilters: TagsFilterType[]
) => {
  const selectedTagsFilterGroups = getGCPTagFiltersData(
    tagFilters,
    selectedDashboard.id,
    selectedDashboardView
  );

  const isStaticData = isDashboardWithStaticData(selectedDashboard);

  return {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'service',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.SERVICE_DESCRIPTION,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['service', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startMonth,
              PROVIDER.GCP,
              isStaticData,
              selectedConnection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              endMonth,
              PROVIDER.GCP,
              isStaticData,
              selectedConnection.focusConversionEnabled
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: selectedTagsFilterGroups,
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};
