import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UnAuthorizedPage from 'pages/UnauthorisedPage';
import NotFoundPage from 'pages/NotFoundPage';
import ServerErrorPage from 'pages/ServerErrorPage';
import LoginPage from 'pages/LoginPage';
import { userAuthorization } from 'redux/authorizationSlice';
import { NAVIGATION_MENU_PATH, ROUTES_DATA } from 'constants/navigationMenu';
import { getDefaultLandingPath } from 'utils/navigationMenu';
import { CONJUNCTIONS } from 'constants/requestBody';
import PrivateRoute from './PrivateRoute';
import AuthorizedRoute from './AuthorizedRoute';

/**
 * @function PrivateNavigation
 * @description Function to check the path is authorized or not. If authorized will navigate to corresponding component. If not will navigate to not authorized page
 * @param path : url path
 * @param auth : authorization boolean value
 * @param children : child component
 * @returns Route component.
 */
const PrivateNavigation = (path: string, auth: boolean, children: any) => {
  return (
    <Route
      key={path}
      path={path}
      element={<PrivateRoute auth={auth}>{children}</PrivateRoute>}
    />
  );
};

const AppRouter = () => {
  const { isUserAuthorized, permissions } = useSelector(userAuthorization);

  const isNavAuthorized = (route: any) => {
    if (route.permissionConjunction === CONJUNCTIONS.AND) {
      return route.permissions.every(
        (permission: string) => permissions[permission]
      );
    }

    return route.permissions.some(
      (permission: string) => permissions[permission]
    );
  };

  return (
    <Routes>
      <Route
        element={
          <PrivateRoute auth={isUserAuthorized}>
            <AuthorizedRoute />
          </PrivateRoute>
        }
      >
        <Route
          path={NAVIGATION_MENU_PATH.DEFAULT}
          element={
            <Navigate
              to={
                getDefaultLandingPath(permissions) ??
                NAVIGATION_MENU_PATH.PROFILE
              }
            />
          }
        />
        {ROUTES_DATA.map((route) =>
          PrivateNavigation(
            route.path,
            isNavAuthorized(route),
            <route.element />
          )
        )}
      </Route>
      <Route path={NAVIGATION_MENU_PATH.LOGIN} element={<LoginPage />} />
      <Route
        path={NAVIGATION_MENU_PATH.NOT_AUTHORIZED}
        element={<UnAuthorizedPage />}
      />
      <Route path={NAVIGATION_MENU_PATH.ERROR} element={<ServerErrorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRouter;
