import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getTicketInfo
 * @description Function to get information about the ticket
 * @param sysId sysId for the ticket
 * @return axios response from GET request
 */
export const getTicketInfo = async (sysId: string) => {
  return ServiceCalls.get(APIS.GET_SNOW_TICKET + sysId);
};

/**
 * @function createRecommendationView
 * @description Function to create a recommendation view based on the filters provided
 * @param body The request body of the API
 * @return axios response from POST request
 */
export const createRecommendationView = async (body: any) => {
  return ServiceCalls.post(APIS.RECOMMENDATION_VIEW, body);
};

/**
 * @function getAllSnowIncidentDetails
 * @description Function to fetch all the snow incident details
 * @param connectorId Connector id for which the incidents are fetched
 * @return axios response from GET request
 */
export const getAllSnowIncidentDetails = async (connectorId: string) => {
  const requestAPI = APIS.GET_ALL_SNOW_INCIDENT_DETAILS + connectorId;
  return ServiceCalls.get(requestAPI);
};

/**
 * @function getRecommendationViews
 * @description Function to get all the available views for a recommendation connection
 * @return axios response from GET request
 */
export const getRecommendationViews = async (extraHeaders: any) => {
  return ServiceCalls.get(APIS.RECOMMENDATION_VIEW, extraHeaders);
};

/**
 * @function deleteRecommendationViews
 * @description Function to delete an opened recommendation filter view
 * @return axios response from DELETE request
 */
export const deleteRecommendationViews = async (extraHeaders: any) => {
  return ServiceCalls.delete(APIS.RECOMMENDATION_VIEW, extraHeaders);
};

/**
 * @function getRecommendationFilters
 * @description Function to get all the available filters for a recommendation connection
 * @param endpoint The recommendation filters endpoint
 * @param params request parameters
 * @return axios response from GET request
 */
export const getRecommendationFilters = async (
  endpoint: string,
  params: any
) => {
  return ServiceCalls.get(endpoint, null, params);
};
