import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userAuthorization } from 'redux/authorizationSlice';
import { NAVIGATION_MENU_DATA } from 'constants/navigationMenu';

import SubMenuList from '../SubMenuList';

import './index.scss';

const AllFeaturesSubMenu = () => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  return (
    <div className="all-features-sub-menu flex flex-column flex-gap-8">
      {NAVIGATION_MENU_DATA.filter((navMenu) =>
        navMenu.permissions.some((permission) => permissions[permission])
      ).map((menu) => (
        <div className="flex flex-column flex-gap-4" key={menu.id}>
          <div className="menu-title font-caption-bold">
            {t(`navigationMenu.${menu.title}`)}
          </div>
          <SubMenuList
            menu={menu}
            isAllFeature={true}
            additionalClassName="all-features-sub-menu"
          />
        </div>
      ))}
    </div>
  );
};

export default AllFeaturesSubMenu;
