import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  cloudConnection,
  setAccessDetails,
  setCloudConnectionComponent,
  setCurrentConnection,
  setCurrentStep,
  setIsTryAgain,
} from 'redux/cloudConnectionSlice';
import {
  CloudConnectionComponents,
  SECRET_KEY_PLACEHOLDER,
} from 'pages/ConnectingCSPPage/constants';
import { PROVIDER } from 'constants/cloudProviders';
import { providerList, setSelectedProvider } from 'redux/providerSlice';
import { SuccessIcon } from 'assets/icons';
import {
  addNewCSPConnection,
  deleteConnectionFile,
  updateConnectionData,
} from 'utils/services';
import ErrorComponent from 'components/ErrorComponent';
import SuccessComponent from 'components/SuccessComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import ProviderList from 'components/ConnectionProviderList';
import ProgressBar from 'components/ProgressBar';
import NavigationPath from 'components/NavigationPath';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { setNavSubPath } from 'redux/activeNavMenuSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';

import GCPConnectionForm from '../GCPConnectionForm';
import AzureConnectionForm from '../AzureConnectionForm';
import AWSConnectionForm from '../AWSConnectionForm';
import OCIConnectionForm from '../OCIConnectionForm';
import Footer from '../Footer';
import FormHeader from '../FormHeader';
import {
  BUDGET_ACCESS_PROGRESS_ITEM,
  MANDATORY_BILLING_ACCESS_PROGRESS_ITEM,
  PROGRESS_BAR_ITEMS,
} from './constants';
import './index.scss';

const AddOrEditConnection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    currentConnection,
    isEditConnection,
    cloudConnectionComponent,
    editedData,
    currentStep,
    isTryAgain,
  } = useSelector(cloudConnection);
  const { selectedProvider } = useSelector(providerList);

  const [formSubmitRequestStatus, setFormSubmitRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    addZeroMarginClass();
    if (isEditConnection) {
      dispatch(setSelectedProvider(currentConnection?.provider));
      dispatch(setNavSubPath([t('connectionCSPForm.steps.editHeading')]));
    } else {
      dispatch(setNavSubPath([t('connectionCSPForm.addNewConnection')]));
      dispatch(setCurrentStep(0));
    }
    dispatch(setIsTryAgain(false));

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    if (!isEditConnection && !isTryAgain) {
      dispatch(setCurrentConnection(undefined));
      dispatch(setAccessDetails([]));
    }
  }, [selectedProvider]);

  /**
   * @function providerForms
   * @description Function to set the form for budget and dashboard
   *
   */
  const providerForms = () => {
    switch (selectedProvider) {
      case PROVIDER.AWS:
        return <AWSConnectionForm />;

      case PROVIDER.GCP:
        return <GCPConnectionForm />;

      case PROVIDER.AZURE:
        return <AzureConnectionForm />;

      case PROVIDER.OCI:
        return <OCIConnectionForm />;
    }
  };

  /**
   * @function showProvidersList
   * @description Function to set the cloud connection page to provider list
   *
   */
  const showProvidersList = () => {
    dispatch(setCurrentConnection(undefined));
    dispatch(setCurrentStep(0));
    dispatch(
      setCloudConnectionComponent(
        CloudConnectionComponents.CLOUD_CONNECTION_FORM
      )
    );
  };

  /**
   * @function onClickTryAgain
   * @description Callback function for click event of try again button
   *
   */
  const onClickTryAgain = () => {
    dispatch(setIsTryAgain(true));
    setFormSubmitRequestStatus('');
    dispatch(setCurrentStep(1));
    dispatch(
      setCloudConnectionComponent(
        CloudConnectionComponents.CLOUD_CONNECTION_FORM
      )
    );
  };

  /**
   * @function getGCPRequestBody
   * @description Function to get the request body for GCP for add and edit connection
   * @param body Request body
   * @returns Request body for GCP
   */
  const getGCPRequestBody = (body: any) => {
    return {
      ...body,
      name: currentConnection?.displayName,
      jsonFileId: isEditConnection ? undefined : currentConnection?.jsonFileId,
      wantBilling:
        !!currentConnection?.billingDataset &&
        !!currentConnection?.billingTableName,
      wantCarbonFootprint:
        !!currentConnection?.carbonFootprintDataset &&
        !!currentConnection?.carbonFootprintTableName,
      wantRecommendations:
        !!currentConnection?.recommendationDataset &&
        !!currentConnection?.recommendationsTableName,
      wantContainerCost: !!currentConnection?.projectDatasetMap?.length,
    };
  };

  /**
   * @function getAWSRequestBody
   * @description Function to get the request body for AWS for add and edit connection
   * @param body Request body
   * @returns Request body for AWS
   */
  const getAWSRequestBody = (body: any) => {
    return {
      ...body,
      name: currentConnection?.displayName,
      awsAccessKeyId:
        currentConnection?.awsAccessKeyId === SECRET_KEY_PLACEHOLDER
          ? undefined
          : currentConnection?.awsAccessKeyId,
      awsSecretKey:
        currentConnection?.awsSecretKey === SECRET_KEY_PLACEHOLDER
          ? undefined
          : currentConnection?.awsSecretKey,
      wantBilling:
        !!currentConnection?.dataCatalog &&
        !!currentConnection?.billingDataset &&
        !!currentConnection?.billingTableName,
      wantRecommendations:
        !!currentConnection?.recommendationsDataCatalog &&
        !!currentConnection?.recommendationDataset &&
        !!currentConnection?.accEnvMapFileId,
      wantContainerCost:
        !!currentConnection?.containerInsightDataCatLog &&
        !!currentConnection?.containerInsightDataset &&
        !!currentConnection?.containerInsightTableName,
    };
  };

  /**
   * @function getAzureRequestBody
   * @description Function to get the request body for Azure for add and edit connection
   * @param body Request body
   * @returns Request body for Azure
   */
  const getAzureRequestBody = (body: any) => {
    return {
      ...body,
      name: currentConnection?.displayName,
      azureTenantId:
        currentConnection?.azureTenantId === SECRET_KEY_PLACEHOLDER
          ? undefined
          : currentConnection?.azureTenantId,
      azureSubId:
        currentConnection?.azureSubscriptionId === SECRET_KEY_PLACEHOLDER
          ? undefined
          : currentConnection?.azureSubscriptionId,
      azureSubscriptionId: undefined,
      azureAppId: undefined,
      azureClientId:
        currentConnection?.azureAppId === SECRET_KEY_PLACEHOLDER
          ? undefined
          : currentConnection?.azureAppId,
      azureClientSecret:
        currentConnection?.azureClientSecret === SECRET_KEY_PLACEHOLDER
          ? undefined
          : currentConnection?.azureClientSecret,
      azureSynapseWorkspace: currentConnection?.azureSynapseWorkspaceName,
      azureSqlServer: currentConnection?.azureSynapseWorkspaceName,
      billingDataset: currentConnection?.azureDatabase,
      billingTableName: currentConnection?.azureBillingTable,
      wantRecommendations:
        !!currentConnection?.azureRecommendationDataset &&
        !!currentConnection?.azureRecommendationDatasetTable,
      recommendationDataset: currentConnection?.azureRecommendationDataset,
      recommendationsTableName:
        currentConnection?.azureRecommendationDatasetTable,
      wantCarbonFootprint:
        !!currentConnection?.azureCarbonFootprintDataset &&
        !!currentConnection?.azureCarbonFootprintDatasetTable,
      carbonFootprintDataset: currentConnection?.azureCarbonFootprintDataset,
      carbonFootprintTableName:
        currentConnection?.azureCarbonFootprintDatasetTable,
    };
  };

  /**
   * @function getOCIRequestBody
   * @description Function to get the request body for OCI for add and edit connection
   * @param body Request body
   * @returns Request body for OCI
   */
  const getOCIRequestBody = (body: any) => {
    return {
      ...body,
      name: currentConnection?.displayName,
      displayName: undefined,
    };
  };

  /**
   * @function handleSubmit
   * @description Function to handle submit connection
   */
  const handleSubmit = async () => {
    setFormSubmitRequestStatus(REQUEST_STATUS.PROCESSING);
    let body = {
      ...currentConnection,
      provider: selectedProvider,
    };
    switch (selectedProvider) {
      case PROVIDER.GCP:
        body = getGCPRequestBody(body);
        break;
      case PROVIDER.AWS:
        body = getAWSRequestBody(body);
        break;
      case PROVIDER.AZURE:
        body = getAzureRequestBody(body);
        break;

      case PROVIDER.OCI:
        body = getOCIRequestBody(body);
        break;
    }

    try {
      if (isEditConnection) {
        await updateConnectionData(body);
        dispatch(
          setCloudConnectionComponent(CloudConnectionComponents.SUCCESS_PAGE)
        );
        return;
      }
      await addNewCSPConnection(body);
      setFormSubmitRequestStatus(REQUEST_STATUS.SUCCESS);
      dispatch(
        setCloudConnectionComponent(CloudConnectionComponents.SUCCESS_PAGE)
      );
    } catch (error: any) {
      dispatch(
        setErrorMessage(
          error.response.data.message || t('connectionCSPErrorPage.subHeader')
        )
      );
      dispatch(
        setCloudConnectionComponent(CloudConnectionComponents.ERROR_PAGE)
      );
    }
  };

  /**
   * @function getProgressList
   * @description Function to get the progress list based on the provider
   * @returns For GCP, all the steps are shown. For AWS and Azure, only first 5 steps are shown
   */
  const getProgressList = () => {
    switch (selectedProvider) {
      case PROVIDER.GCP:
        return PROGRESS_BAR_ITEMS;
      case PROVIDER.AWS:
        return [
          ...PROGRESS_BAR_ITEMS.slice(0, 5),
          ...PROGRESS_BAR_ITEMS.slice(-1),
        ];
      case PROVIDER.AZURE:
        return [
          ...PROGRESS_BAR_ITEMS.slice(0, 3),
          MANDATORY_BILLING_ACCESS_PROGRESS_ITEM,
          ...PROGRESS_BAR_ITEMS.slice(4, 6),
        ];
      case PROVIDER.OCI:
        return [...PROGRESS_BAR_ITEMS.slice(0, 4), BUDGET_ACCESS_PROGRESS_ITEM];
      default:
        return PROGRESS_BAR_ITEMS;
    }
  };

  /**
   * @function getComponent
   * @description Function to get the import file component
   */
  const getComponent = () => {
    switch (cloudConnectionComponent) {
      case CloudConnectionComponents.CLOUD_CONNECTION_FORM:
        return (
          <div className="connection-form flex">
            <ProgressBar
              items={getProgressList()}
              current={currentStep}
              additionalClassNames="new-csp-progress-bar new-styled-scroll width-40"
              heading={
                isEditConnection
                  ? t('connectionCSPForm.steps.editHeading')
                  : t('connectionCSPForm.steps.heading')
              }
              subHeading={t('connectionCSPForm.steps.subHeading', {
                stepCount: getProgressList().length,
              })}
            />
            <div className="flex flex-column flex-space-between flex-fit">
              <div className="form-content new-styled-scroll">
                {currentStep === 0 ? (
                  <ProviderList />
                ) : (
                  <div className="flex flex-column flex-gap-24">
                    <FormHeader
                      title={getProgressList()[currentStep].title}
                      description={getProgressList()[currentStep].description}
                    />
                    {providerForms()}
                  </div>
                )}
              </div>
              <Footer
                selectedProvider={selectedProvider}
                itemsLength={getProgressList().length}
                onSubmit={handleSubmit}
                submitRequestStatus={formSubmitRequestStatus}
              />
            </div>
          </div>
        );

      case CloudConnectionComponents.SUCCESS_PAGE:
        return (
          <SuccessComponent
            mainTitle={t('connectionCSPSuccessPage.header')}
            subTitle={t('connectionCSPSuccessPage.subHeader')}
            buttonTitle={t('connectionCSPSuccessPage.goToDashboardButton')}
            linkTitle={t('connectionCSPSuccessPage.setUpNewLink')}
            onHandleButtonClick={() => navigate(NAVIGATION_MENU_PATH.DEFAULT)}
            onHandleLinkClick={showProvidersList}
            icon={<SuccessIcon />}
          />
        );

      case CloudConnectionComponents.ERROR_PAGE:
        return (
          <ErrorComponent
            mainTitle={t('connectionCSPErrorPage.header')}
            buttonTitle={t('connectionCSPErrorPage.tryAgainButton')}
            linkTitle={t('connectionCSPErrorPage.goToCloudConnections')}
            onHandleButtonClick={onClickTryAgain}
            onHandleLinkClick={() => {
              editedData?.jsonFileId &&
                deleteConnectionFile(editedData?.jsonFileId);
              navigate(NAVIGATION_MENU_PATH.CLOUD_CONNECTIONS);
            }}
          />
        );
    }
  };

  return (
    <div className="add-edit-csp-connection">
      <header className="csp-page-header new-page-header modal-heading flex flex-align-items-center">
        {t('navigationMenu.cloudConnections')}
      </header>
      <div className="page-content connection-form">
        <NavigationPath />
        {getComponent()}
      </div>
    </div>
  );
};

export default AddOrEditConnection;
