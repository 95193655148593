import i18n from 'i18n';
import moment from 'moment';
import { SCORECARDS_TIMERANGE } from 'pages/ScorecardPage/constants';
import { getDateLabelsByGranularity } from 'pages/ScorecardPage/utils';
import { AWS_CHECKS_MAP, AWS_CHECK_IDS } from 'constants/recommendations';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { ConnectionListType } from 'types/dashboard';
import { WasteManagementItemsType, WasteManagementType } from 'types/dataTypes';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  DD_MMM_YYYY,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { PROVIDER } from 'constants/cloudProviders';
import {
  AWS_WASTE_MANAGEMENT_ITEMS,
  AWS_WASTE_MANAGEMENT_ITEM_LABELS,
  AWS_WM_TOTAL_COST_QUERY_DATA,
  GCP_WASTE_MANAGEMENT_ITEMS,
  GCP_WASTE_MANAGEMENT_ITEM_LABELS,
  GCP_WM_QUERY_DATA,
  WASTE_MANAGEMENT_SUB_ITEMS,
} from './constants';

/**
 * @function getWasteManagementConsolidatedData
 * @description Function to consolidate the cost by category
 * @param provider selected connection service provider
 * @param wasteManagementData the data used for table
 * @returns list of category wise cost/ratio details
 */
export const getWasteManagementConsolidatedData = (
  provider: string | undefined,
  wasteManagementData: WasteManagementType
) => {
  let data: WasteManagementItemsType[] = [];

  data = wasteManagementData.savingsCost.filter(
    (item) =>
      getCategories(provider).some((value) => value === item.category) &&
      item.subItem === undefined
  );

  return data;
};

/**
 * @function getTableData
 * @description Function to return the table data for the categories
 * @param provider selected connection service provider
 * @param data source data used for the data
 * @param timeRangeLabels list of date labels
 * @param formatNumber boolean for formatting the number or cost field
 * @returns list of category wise cost details
 */
export const getTableData = (
  provider: string | undefined,
  data: any[],
  timeRangeLabels: string[],
  formatNumber: boolean = false
) => {
  const tableData: any[] = [];

  getCategories(provider).forEach((category) => {
    const thisRow: any = {};
    const categoryItems = data.filter((item) => item.category === category);
    timeRangeLabels.forEach((label, index) => {
      thisRow[label] = formatNumber
        ? numberCommaSeparator(categoryItems[index]?.value)
        : categoryItems[index]?.value;
    });
    thisRow.item = category;
    tableData.push(thisRow);
  });

  return tableData;
};

/**
 * @function getWasteManagementCategoriesByProvider
 * @description Function to return the categories enum by provider
 * @param provider selected connection service provider
 * @returns Enum containing categories
 */
export const getWasteManagementCategoriesByProvider = (
  provider: string | undefined
) => {
  if (provider === PROVIDER.GCP) {
    return GCP_WASTE_MANAGEMENT_ITEMS;
  }

  return AWS_WASTE_MANAGEMENT_ITEMS;
};

/**
 * @function getCategories
 * @description Function to return the categories by provider
 * @param provider selected connection service provider
 * @returns List of categories
 */
export const getCategories = (provider: string | undefined) => {
  if (provider === PROVIDER.GCP) {
    return Object.values(GCP_WASTE_MANAGEMENT_ITEMS).map(
      (item) =>
        GCP_WASTE_MANAGEMENT_ITEM_LABELS.find(
          (labelItem) => labelItem.value === item
        )?.label ?? ''
    );
  }

  return Object.values(AWS_WASTE_MANAGEMENT_ITEMS).map(
    (item) =>
      AWS_WASTE_MANAGEMENT_ITEM_LABELS.find(
        (labelItem) => labelItem.value === item
      )?.label ?? ''
  );
};

/**
 * @function getTableExpandedItemsData
 * @description Function to return the table data for the expanded rows
 * @param data source data used for the data
 * @param timeRangeLabels list of date labels
 * @param category Category of the expanded row
 * @param formatNumber boolean for formatting the number or cost field
 * @returns list of sub item cost details
 */
export const getTableExpandedItemsData = (
  data: any[],
  timeRangeLabels: string[],
  category: string,
  formatNumber: boolean = false
) => {
  const tableData: any[] = [];
  Object.values(WASTE_MANAGEMENT_SUB_ITEMS).forEach((subItem) => {
    const thisRow: any = {};
    const serviceItems = data.filter(
      (item) => item.category === category && item.subItem === subItem
    );

    timeRangeLabels.forEach((label, index) => {
      const value = serviceItems[index] ? serviceItems[index].value : 0;
      thisRow[label] = `$${formatNumber ? numberCommaSeparator(value) : value}`;
    });
    thisRow.item = subItem;
    tableData.push(thisRow);
  });
  return tableData;
};

/**
 * @function getWasteManagementExportColumns
 * @param selectedTimeRange - selected time range of waste management chart
 * @param wasteManagementDateRange Date range selected for waste management
 * @returns columns for waste management export for both pdf and excel
 */
export const getWasteManagementExportColumns = (
  selectedTimeRange: string,
  wasteManagementDateRange: string[]
) => {
  const columns: any[] = [
    {
      header: i18n.t('scorecard.wasteManagement.items'),
      alignment: 'left',
      dataKey: 'item',
      key: 'item',
    },
  ];
  const timeRangeLabels = getDateLabelsByGranularity(
    selectedTimeRange,
    wasteManagementDateRange,
    selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
      ? MONTH_YEAR_FORMAT
      : DD_MMM_YYYY
  );
  timeRangeLabels.forEach((label) => {
    columns.push({
      header: label,
      dataKey: label,
      alignment: 'right',
      key: label,
    });
  });
  return columns;
};

/**
 * @function getWasteManagementExportData
 * @param provider selected connection service provider
 * @param wasteManagementData - all waste management items/rows
 * @param selectedTimeRange - selected time range of waste management chart
 * @param wasteManagementDateRange Date range selected for waste management
 * @param formatNumber Boolean to indicate whether to format the number field or the cost field
 * @returns array of object containing item name and cost by label
 * eg. Array of {item:"Compute Instance", "Sep 22":"0.1", "Oct 22":"0.2" ...}
 */
export const getWasteManagementExportData = (
  provider: string | undefined,
  wasteManagementData: WasteManagementType,
  selectedTimeRange: string,
  wasteManagementDateRange: string[],
  formatNumber: boolean = false
) => {
  let data: any[] = [];
  const timeRangeLabels = getDateLabelsByGranularity(
    selectedTimeRange,
    wasteManagementDateRange,
    selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
      ? MONTH_YEAR_FORMAT
      : DD_MMM_YYYY
  );

  const consolidatedData = getTableData(
    provider,
    getWasteManagementConsolidatedData(provider, wasteManagementData),
    timeRangeLabels,
    formatNumber
  );

  getCategories(provider).forEach((category) => {
    const categoryConsolidatedData = consolidatedData.find(
      (dataItem) => dataItem.item === category
    );
    data.push(categoryConsolidatedData);
    const expandedRowData = getTableExpandedItemsData(
      wasteManagementData.savingsCost,
      timeRangeLabels,
      category,
      formatNumber
    );
    Object.values(WASTE_MANAGEMENT_SUB_ITEMS)?.forEach((subItem) => {
      data.push({
        ...expandedRowData.find((dataItem) => dataItem.item === subItem),
        isSubItem: true,
      });
    });
  });

  return data;
};

/**
 * @function getWasteManagementExcelData
 * @param wasteManagementData waste management data for export
 * @param selectedTimeRange - selected time range of waste management chart
 * @param wasteManagementDateRange - date range of waste management chart
 * @param connection connection details of the selected connection
 * @returns Object containing the details of excel export
 */
export const getWasteManagementExcelData = (
  wasteManagementData: WasteManagementType,
  selectedTimeRange: string,
  wasteManagementDateRange: string[],
  connection: ConnectionListType | null
) => {
  const footer: any = {};
  footer.item = i18n.t(
    'scorecard.wasteManagement.effectiveWasteManagementRate'
  );
  getDateLabelsByGranularity(
    selectedTimeRange,
    wasteManagementDateRange,
    selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
      ? MONTH_YEAR_FORMAT
      : DD_MMM_YYYY
  ).forEach((label, index) => {
    footer[label] =
      wasteManagementData.wasteManagementRate[index]?.percentage + '%';
  });

  return {
    sheetName: i18n.t('graphHeadings.wasteManagementEffectiveness'),
    columns: getWasteManagementExportColumns(
      selectedTimeRange,
      wasteManagementDateRange
    ),
    data: insertIndex(
      getWasteManagementExportData(
        connection?.provider,
        wasteManagementData,
        selectedTimeRange,
        wasteManagementDateRange
      )
    ),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connection?.displayName,
      },
      {
        heading: i18n.t('excelExportLabels.timeRange'),
        value: selectedTimeRange,
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: wasteManagementDateRange[0],
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: wasteManagementDateRange[1],
      },
    ],
    boldRows: getWasteManagementExportData(
      connection?.provider,
      wasteManagementData,
      selectedTimeRange,
      wasteManagementDateRange
    )
      .map((value, index) => (!value.isSubItem ? index : -1))
      .filter((value) => value !== -1),
    footer: footer,
  };
};

/**
 * @function getCostOfWasteRequestBody
 * @description Function to return the cost of waste request body by provider
 * @param provider provider for connection
 * @param categoryId category Id unique key
 * @param dateRange startDate and endDate of the data fetched
 * @param granularity The date range granularity chosen
 * @returns Object containing the request body
 */
export const getCostOfWasteRequestBody = (
  provider: string,
  categoryId: string,
  dateRange: { startDate: string; endDate: string },
  granularity: string
) => {
  if (provider === PROVIDER.GCP) {
    return getGCPCostOfWasteRequestBody(categoryId, dateRange, granularity);
  }
  const checkId = AWS_CHECK_IDS[categoryId as keyof typeof AWS_CHECK_IDS];
  return getAWSCostOfWasteRequestBody(checkId, dateRange, granularity);
};

/**
 * @function getGCPCostOfWasteRequestBody
 * @description Function to return the cost of waste request body for GCP
 * @param categoryId category or resource ID
 * @param dateRange startDate and endDate of the data fetched
 * @param granularity The date range granularity chosen
 * @returns Object containing the request body
 */
export const getGCPCostOfWasteRequestBody = (
  categoryId: string,
  dateRange: {
    startDate: string;
    endDate: string;
  },
  granularity: string
) => {
  let dateField = QUERY_FIELDS.CONCAT_YEAR_MONTH_FROM_LAST_REFRESH_TIME;
  if (granularity === SCORECARDS_TIMERANGE.DAILY.valueOf()) {
    dateField = QUERY_FIELDS.EXTRACT_DATE_FROM_LAST_REFRESH_TIME;
  }

  return {
    columns: [
      {
        label: 'estimatedSavings',
        field: QUERY_FIELDS.SUM_UNITS_PLUS_NANOS,
      },
      {
        label: 'month',
        field: dateField,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'seqnum',
          field: `row_number() over (partition by name, ${dateField} order by last_refresh_time desc)`,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: '("ACTIVE","FAILED")',
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: dateRange.startDate,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: dateRange.endDate,
              },
              {
                field: QUERY_FIELDS.RECOMMENDER,
                comparator: COMPARATORS.EQUALS,
                value: GCP_WM_QUERY_DATA.find((item) => item.id === categoryId)
                  ?.recommender,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    groupBy: ['month'],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getAWSCostOfWasteRequestBody
 * @description Function to return the cost of waste request body for aws checkIds
 * @param checkId check Id unique key
 * @param dateRange startDate and endDate of the data fetched
 * @param granularity The date range granularity chosen
 * @returns Object containing the request body
 */
export const getAWSCostOfWasteRequestBody = (
  checkId: string,
  dateRange: { startDate: string; endDate: string },
  granularity: string
) => {
  const checkIdMap = AWS_CHECKS_MAP.find((map) => map.check === checkId);

  let aggregators = [
    {
      label: 'estimatedSavings',
      function: AGGREGATORS.SUM,
    },
  ];

  if (checkId === AWS_CHECK_IDS.IDLE_IP_ADDRESS) {
    aggregators = [];
  }

  let dateFormat = YEAR_MONTH_WITHOUT_SEPARATOR;
  if (granularity === SCORECARDS_TIMERANGE.DAILY.valueOf()) {
    dateFormat = 'YYYY-MM-dd';
  }

  return {
    checkName: Object.entries(AWS_CHECK_IDS).find(
      ([, value]) => value === checkId
    )?.[0],
    checkId: checkId.toLowerCase(),
    recommendationsDto: {
      columns: [
        {
          field: `CAST(regexp_replace(${checkIdMap?.savingsCostField},'[$]','') AS DOUBLE)`,
          label: checkIdMap?.savingsCostField,
        },
        {
          field: `format_datetime(cast(date_parse(datetime,'%Y-%m-%d %H:%i:%s') as date),'${dateFormat}')`,
          label: 'month',
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
          {
            label: 'rownum',
            field: `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}", format_datetime(cast(date_parse(datetime,'%Y-%m-%d %H:%i:%s') as date),'${dateFormat}') ORDER BY status_priority DESC, datetime DESC)`,
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${dateRange.endDate} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${dateRange.startDate} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.ROW_NUM,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
              comparator: COMPARATORS.EQUALS,
              value: 'ACTIVE',
              conjunctToNextFilter: CONJUNCTIONS.OR,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    filterDto: {
      columns: [
        {
          label: 'month',
          field: QUERY_FIELDS.MONTH,
        },
        {
          label: 'estimatedSavings',
          field: checkIdMap?.savingsCostField,
        },
      ],

      aggregators: aggregators,
      groupBy: ['month'],
    },
  };
};

/**
 * @function getTotalCostRequestBody
 * @description Function to return the total cost request body by provider
 * @param provider provider for connection
 * @param categoryId category Id unique key
 * @param dateRange startDate and endDate of the data fetched
 * @param granularity The date range granularity chosen
 * @returns Object containing the request body
 */
export const getTotalCostRequestBody = (
  provider: string,
  categoryId: string,
  dateRange: { startDate: string; endDate: string },
  granularity: string
) => {
  if (provider === PROVIDER.GCP) {
    return getGCPTotalCostRequestBody(categoryId, dateRange, granularity);
  }
  const checkId = AWS_CHECK_IDS[categoryId as keyof typeof AWS_CHECK_IDS];
  return getAWSTotalCostRequestBody(checkId, dateRange, granularity);
};

/**
 * @function getGCPTotalCostRequestBody
 * @description Function to return the total cost request body for aws checkIds
 * @param checkId check Id unique key
 * @param dateRange startDate and endDate of the data fetched
 * @param granularity The date range granularity chosen
 * @returns Object containing the request body
 */
export const getGCPTotalCostRequestBody = (
  categoryId: string,
  dateRange: { startDate: string; endDate: string },
  granularity: string
) => {
  let dateField = QUERY_FIELDS.INVOICE_MONTH;
  if (granularity === SCORECARDS_TIMERANGE.DAILY.valueOf()) {
    dateField = QUERY_FIELDS.EXTRACT_DATE_FROM_USAGE_START_TIME;
  }

  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
      },
      {
        label: 'month',
        field: dateField,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: 'SUM',
      },
    ],
    groupBy: ['month'],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.INVOICE_MONTH,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment(dateRange.startDate).format(
              YEAR_MONTH_WITHOUT_SEPARATOR
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.INVOICE_MONTH,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: moment(dateRange.endDate).format(
              YEAR_MONTH_WITHOUT_SEPARATOR
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
      {
        filters: [
          ...(GCP_WM_QUERY_DATA.find((item) => item.id === categoryId)
            ?.totalCostFilters ?? []),
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAWSTotalCostRequestBody
 * @description Function to return the total cost request body for aws checkIds
 * @param checkId check Id unique key
 * @param dateRange startDate and endDate of the data fetched
 * @returns Object containing the request body
 */
export const getAWSTotalCostRequestBody = (
  checkId: string,
  dateRange: { startDate: string; endDate: string },
  granularity: string
) => {
  let dateField = QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD;
  if (granularity === SCORECARDS_TIMERANGE.DAILY.valueOf()) {
    dateField = QUERY_FIELDS.DATE_LINE_ITEM_USAGE_START_DATE;
  }
  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
      },
      {
        label: 'month',
        field: dateField,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month'],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: `(cast('${dateRange.startDate}' as timestamp))`,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.LINE_ITEM_USAGE_END_DATE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: `(cast('${dateRange.endDate}' as timestamp))`,
          },
          {
            field: QUERY_FIELDS.BILL_BILL_TYPE,
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          ...(AWS_WM_TOTAL_COST_QUERY_DATA.find(
            (item) => item.checkId.toLowerCase() === checkId.toLowerCase()
          )?.filters ?? []),
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getWasteManagementCategoriesLabelsByProvider
 * @description Function to return the waste management items labels by provider
 * @param provider - selected connection cloud provider
 * @returns list of labels
 */
export const getWasteManagementCategoriesLabelsByProvider = (
  provider: string | undefined
) => {
  if (provider === PROVIDER.GCP) {
    return GCP_WASTE_MANAGEMENT_ITEM_LABELS;
  }

  return AWS_WASTE_MANAGEMENT_ITEM_LABELS;
};
