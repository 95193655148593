import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import UploadFile from 'components/UploadFile';
import { uploadConnectionFile } from 'utils/services';
import { useState } from 'react';
import { validateInputLength } from 'pages/ConnectingCSPPage/utils';
import { validateByRegex, validateEmptyField } from 'utils/validations';
import { FileInfoType } from '../../types';

type OCIAccessDetailsProps = {
  removeFile: (
    fileId: string,
    setFileInfo?: (val: FileInfoType) => void,
    setValidationError?: (val: string) => void,
    fieldName?: string
  ) => void;
  walletFileInfo: FileInfoType;
  setWalletFileInfo: (fileInfo: FileInfoType) => void;
};

const OCIAccessDetails = ({
  removeFile,
  walletFileInfo,
  setWalletFileInfo,
}: OCIAccessDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection } = useSelector(cloudConnection);

  const [walletFileRequiredValidation, setWalletFileRequiredValidation] =
    useState('');
  const [usernameValidation, setUsernameValidation] = useState('');
  const [passFieldValidation, setPassFieldValidation] = useState('');

  return (
    <div className="flex flex-column flex-gap-24">
      {!isEditConnection && (
        <div className="form-item flex flex-column">
          <FormLabel
            title={t(`ociConnectionCSPForm.uploadLabel`)}
            required={true}
          />
          <UploadFile
            accept={'.zip'}
            isFileUploaded={!!walletFileInfo.fileId}
            filename={walletFileInfo.fileName}
            buttonTitle={t(`ociConnectionCSPForm.upload`)}
            onSelectFile={async (file, onSuccess, onError) => {
              if (
                !validateByRegex(
                  file.name.slice(0, file.name.lastIndexOf('.')),
                  /^[a-zA-Z0-9][a-zA-Z0-9-_]*$/,
                  undefined,
                  setWalletFileRequiredValidation,
                  t('ociFileFormatError')
                )
              ) {
                onError();
                return;
              }

              try {
                const resData: any = await uploadConnectionFile(file);
                onSuccess(resData);
                setWalletFileInfo({
                  fileId: resData.data.responseData,
                  fileName: file.name,
                });
                dispatch(
                  setCurrentConnection({
                    ...currentConnection,
                    jsonFileId: resData.data.responseData,
                  })
                );
                setWalletFileRequiredValidation('');
              } catch (error) {
                onError({ error });
              }
            }}
            onRemoveFile={() =>
              removeFile(
                walletFileInfo.fileId,
                setWalletFileInfo,
                setWalletFileRequiredValidation,
                t(`ociConnectionCSPForm.uploadLabel`)
              )
            }
          />
          <span
            style={{
              display: walletFileRequiredValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {walletFileRequiredValidation}
          </span>
        </div>
      )}
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`ociConnectionCSPForm.userName`)}
          required={true}
          disabled={isEditConnection}
        />
        <Input
          default
          type="input"
          placeholder={t(`ociConnectionCSPForm.userNamePlaceholder`)}
          value={
            isEditConnection ? '********' : currentConnection?.ociAdwUsername
          }
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                ociAdwUsername: e.target.value,
              })
            );
            setUsernameValidation('');
            validateInputLength(
              e.target.value,
              t(`ociConnectionCSPForm.userName`),
              setUsernameValidation
            );
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t(`ociConnectionCSPForm.userName`),
              setUsernameValidation
            );
          }}
          disabled={isEditConnection}
        />
        <span
          style={{
            display: `${usernameValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {usernameValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`ociConnectionCSPForm.password`)}
          required={true}
          disabled={isEditConnection}
        />
        <Input
          default
          type="password"
          placeholder={t(`ociConnectionCSPForm.passwordPlaceholder`)}
          value={
            isEditConnection ? '********' : currentConnection?.ociAdwPassword
          }
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                ociAdwPassword: e.target.value,
              })
            );
            setPassFieldValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t(`ociConnectionCSPForm.password`),
              setPassFieldValidation
            );
          }}
          disabled={isEditConnection}
        />
        <span
          style={{
            display: `${passFieldValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {passFieldValidation}
        </span>
      </div>
    </div>
  );
};

export default OCIAccessDetails;
