import { Divider, Empty, Menu, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import DrawerComponent from 'components/DrawerComponent';
import Input from 'components/Input';
import DashboardListItem from 'components/DashboardListItem';
import { INPUT_SIZE } from 'constants/appearance';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import { ConsumptionManagementType } from 'pages/CostOptimizationInsightsPage/types';
import { getLabelledDayFromDate } from 'utils/date';
import {
  SwitchConnectionTypes,
  SWITCH_CONNECTION_TYPES_DATA,
} from './constants';

import './index.scss';

type ConnectionsListDrawerProps = {
  show: boolean;
  setShow: (value: boolean) => void;
  selectedConnection: ConsumptionManagementType | null;
  onClick: (key: any) => void;
  connectionList: ConsumptionManagementType[];
  loading?: boolean;
  showConnectionTypeNav?: boolean;
  selectedConnectionListType?: SwitchConnectionTypes;
  setSelectedConnectionListType?: (dataSource: SwitchConnectionTypes) => void;
  isCB360TabSelected: boolean;
};

const ConnectionsListDrawer = ({
  show,
  setShow,
  connectionList,
  onClick,
  selectedConnection,
  loading,
  showConnectionTypeNav = false,
  selectedConnectionListType = SwitchConnectionTypes.CB_360,
  setSelectedConnectionListType,
  isCB360TabSelected,
}: ConnectionsListDrawerProps) => {
  const { t } = useTranslation();

  const { selectedGranulateService } = useSelector(costOptimizationInsights);

  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    if (selectedGranulateService) {
      setSelectedConnectionListType?.(SwitchConnectionTypes.GRANULATE);
    } else {
      setSelectedConnectionListType?.(SwitchConnectionTypes.CB_360);
    }
  }, [show]);

  /**
   * @function filterByDate
   * @description function to filter connections list based on date
   * @param date to filter
   * @returns true if search key matches date
   */
  const filterByDate = (date: string) => {
    return getLabelledDayFromDate(date)
      .toLowerCase()
      .includes(searchKey.toLowerCase());
  };

  /**
   * @function onClickConnectionItem
   * @param connection clicked connection
   */
  const onClickConnectionItem = (connection: any) => {
    if (isCB360TabSelected) {
      if (selectedConnection?.connectorId === connection.connectorId) return;
      onClick(connection?.connectorId);
    } else {
      if (selectedConnection?.key === connection.key) return;
      onClick(connection);
    }
  };

  return (
    <DrawerComponent
      className="switch-connection-list-modal"
      open={show}
      title={
        <header className="flex flex-align-items-center flex-space-between">
          <span>{t('dashboardLabels.allConnections')}</span>
          <Input
            placeholder={t('dashboardLabels.searchByNameOrDate')}
            type="search"
            className="search-input"
            value={searchKey}
            size={INPUT_SIZE.SMALL}
            onChange={(e: any) => setSearchKey(e.target.value)}
          />
        </header>
      }
      onClose={() => setShow(false)}
    >
      {loading ? (
        <>
          <Skeleton avatar active />
          <Skeleton avatar active />
          <Skeleton avatar active />
        </>
      ) : (
        <>
          {showConnectionTypeNav ? (
            <HorizontalNavigationMenu
              menuItems={SWITCH_CONNECTION_TYPES_DATA.map((navMenu) => (
                <Menu.Item
                  key={navMenu.type}
                  eventKey={navMenu.type}
                  className={`font-caption-bold flex-fit ${
                    navMenu.type === selectedConnectionListType && 'active-menu'
                  }`}
                  onClick={() => setSelectedConnectionListType?.(navMenu.type)}
                >
                  {t(`costOptimizationInsight.${navMenu.label}`)}
                </Menu.Item>
              ))}
              selectedKeys={[]}
            />
          ) : (
            <Divider rootClassName="horizontal-divider" />
          )}
          {connectionList.length > 0 ? (
            connectionList
              .filter(
                (connection) =>
                  (connection.displayName ?? connection.name)?.includes(
                    searchKey
                  ) || filterByDate(connection.createdAt)
              )
              .map((connection) => (
                <DashboardListItem
                  key={
                    isCB360TabSelected ? connection.connectorId : connection.key
                  }
                  isChecked={
                    isCB360TabSelected
                      ? selectedConnection?.connectorId ===
                        connection.connectorId
                      : selectedConnection?.key === connection.key
                  }
                  connection={connection}
                  onClick={() => onClickConnectionItem(connection)}
                />
              ))
          ) : (
            <Empty />
          )}
        </>
      )}
    </DrawerComponent>
  );
};
export default ConnectionsListDrawer;
