import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { providerList } from 'redux/providerSlice';
import { costMonitor, setCostMonitorFormData } from 'redux/costMonitorSlice';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import { validateInputLength } from 'pages/ConnectingCSPPage/utils';
import { getValidationStyle, validateEmptyField } from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';
import useDidMountEffect from 'hooks/useDidMountEffect';
import SelectDropdown from 'components/Select';
import { CostMonitorListType } from 'pages/CostMonitorPage/types';
import { CONNECTION_STATUS } from 'pages/ConnectingCSPPage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ConnectionListType } from 'types/dashboard';
import { fetchConnectionData } from 'utils/services';
import {
  getAllAwsMonitors,
  getMonitorsByConnectorId,
} from '../AWSCostMonitorForm/services';

type BasicDetailsProps = {
  createButtonClick: boolean;
  setBasicDetailsValidation: (val: boolean) => void;
};

const BasicDetails = ({
  createButtonClick,
  setBasicDetailsValidation,
}: BasicDetailsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'costMonitor.createCostMonitorLabels.basicDetailsLabels',
  });
  const dispatch = useDispatch();
  const { selectedProvider } = useSelector(providerList);
  const { costMonitorFormData, isEditForm } = useSelector(costMonitor);

  const [connections, setConnections] = useState<ConnectionListType[]>([]);
  const [fetchConnectionsRequestStatus, setFetchConnectionsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [connectionValidationMessage, setConnectionValidationMessage] =
    useState('');
  const [monitorNameValidationMessage, setMonitorNameValidationMessage] =
    useState('');

  const [costMonitors, setCostMonitors] = useState<CostMonitorListType[]>([]);

  useEffect(() => {
    fetchAllConnections();
    fetchAllMonitors();
  }, []);

  useEffect(() => {
    checkDuplicateMonitorName(costMonitorFormData?.name);
  }, [costMonitorFormData?.name, costMonitors]);

  useEffect(() => {
    if (costMonitorFormData?.connectorId && !isEditForm)
      validateConnectionExistingMonitor();
  }, [costMonitorFormData.connectorId]);

  useEffect(() => {
    validateBasicDetails(false);
  }, [costMonitorFormData.connectorId, costMonitorFormData.name]);

  useDidMountEffect(() => {
    validateBasicDetails(true);
  }, [createButtonClick]);

  /**
   * @function validateConnectionExistingMonitor
   * @description Function to fetch the monitors by a connector id
   */
  const validateConnectionExistingMonitor = async () => {
    setFetchConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: costMonitorFormData?.connectorId,
    };
    try {
      const res: any = await getMonitorsByConnectorId(params);
      if (res.status === 200) {
        setFetchConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
        const monitorExist = Boolean(res.data.responseData);
        if (monitorExist) {
          setConnectionValidationMessage(t('connectionMonitorAlreadyExists'));
          setBasicDetailsValidation(false);
        }
        return !monitorExist;
      }
      setFetchConnectionsRequestStatus(REQUEST_STATUS.ERROR);
      return false;
    } catch (e) {
      onApiCallError(e, true, setFetchConnectionsRequestStatus);
      return false;
    }
  };

  /**
   * @function fetchAllConnections
   * @description fetches all the connections for the anomaly detection dashboard
   */
  const fetchAllConnections = () => {
    setFetchConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: selectedProvider,
    };

    fetchConnectionData(params)
      .then((res: any) => {
        if (res.status === 200) {
          setConnections(
            res?.data?.responseData?.content?.filter(
              (eachConnection: any) =>
                eachConnection.status !== CONNECTION_STATUS.ERROR
            )
          );
          setFetchConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setFetchConnectionsRequestStatus(REQUEST_STATUS.ERROR);
        setConnections([]);
      })
      .catch((e) => {
        onApiCallError(e, true, setFetchConnectionsRequestStatus);
        setConnections([]);
      });
  };

  /**
   * @function fetchAllMonitors
   * @description Function to fetch Cost Monitor data
   */
  const fetchAllMonitors = () => {
    getAllAwsMonitors()
      .then((res: any) => {
        const data: any[] = res.data.responseData;
        if (isEditForm) {
          setCostMonitors(
            data.filter(
              (monitor) => monitor.monitorARN !== costMonitorFormData.monitorARN
            )
          );
          return;
        }
        setCostMonitors(data);
      })
      .catch((e) => onApiCallError(e, true));
  };

  /**
   * @function checkDuplicateMonitorName
   * @description Function to validate for duplicate monitor name
   * @param currentMonitorName monitor name
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean false if the monitor name is duplicate else false
   */
  const checkDuplicateMonitorName = (
    currentMonitorName: string,
    setErrorMessage: boolean = true
  ) => {
    if (costMonitors.some((monitor) => monitor.name === currentMonitorName)) {
      setErrorMessage &&
        setMonitorNameValidationMessage(t('duplicateMonitorNameMessage'));
      return false;
    }
    return true;
  };

  /**
   * @function validateMonitorName
   * @description Function to validate the monitor name
   * @param value Value to be validated
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateMonitorName = (
    value: string,
    setErrorMessage: boolean = true
  ) => {
    if (
      !validateEmptyField(
        value,
        t('monitorName'),
        setMonitorNameValidationMessage,
        setErrorMessage
      ) &&
      validateInputLength(
        value,
        t('monitorName'),
        setMonitorNameValidationMessage,
        setErrorMessage
      ) &&
      checkDuplicateMonitorName(value, setErrorMessage)
    ) {
      return true;
    }

    return false;
  };

  /**
   * @function validateConnection
   * @description Function to validate the connection field
   * @param value Value to be validated
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateConnection = (
    value: string,
    setErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('connection'),
      setConnectionValidationMessage,
      setErrorMessage && !connectionValidationMessage
    );
  };

  /**
   * @function validateBasicDetails
   * @description Function to validate the fields in basic details
   * @param value Value to be validated
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateBasicDetails = (setErrorMessage: boolean) => {
    let validation = true;
    validation =
      validateConnection(costMonitorFormData.connectorId, setErrorMessage) &&
      validation;
    validation =
      validateMonitorName(costMonitorFormData.name, setErrorMessage) &&
      validation;
    setBasicDetailsValidation(validation);
  };

  return (
    <div className="basic-details full-height flex flex-column flex-gap-24">
      <span className="form-header">{t('basicDetails')}</span>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('selectCloudConnection')}
          required={true}
          disabled={isEditForm}
        />
        <SelectDropdown
          value={costMonitorFormData?.connectorId || undefined}
          options={connections.map((connection) => ({
            value: connection.connectorId,
            label: connection.displayName,
          }))}
          placeholder={t('selectCloudConnection')}
          loading={fetchConnectionsRequestStatus === REQUEST_STATUS.PROCESSING}
          onSelect={(_value: any, option: any) => {
            dispatch(
              setCostMonitorFormData({
                ...costMonitorFormData,
                connectorId: option.value,
                connectorName: option.label,
              })
            );
            validateConnection(option.value);
          }}
          onBlur={() => validateConnection(costMonitorFormData?.connectorId)}
          disabled={isEditForm}
        />
        <span
          style={{
            display: `${getValidationStyle(connectionValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {connectionValidationMessage}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('monitorName')} required={true} />
        <Input
          default
          type="input"
          placeholder={t('monitorName')}
          value={costMonitorFormData?.name}
          onChange={(e: any) => {
            dispatch(
              setCostMonitorFormData({
                ...costMonitorFormData,
                name: e.target.value,
              })
            );
            validateMonitorName(e.target.value.trim());
          }}
          onBlur={() => {
            validateMonitorName(costMonitorFormData?.name.trim());
          }}
        />
        <span
          style={{
            display: `${getValidationStyle(monitorNameValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {monitorNameValidationMessage}
        </span>
      </div>
    </div>
  );
};

export default BasicDetails;
