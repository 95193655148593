import { useCallback } from 'react';
import { Bar } from '@ant-design/plots';

import TooltipContent from 'components/TooltipContent';
import { COLORS } from 'constants/graphConfig';
import { CHART_Y_AXIS_LABEL_LENGTH_LIMIT } from 'constants/userConsole';
import { generateGraphColors } from 'utils/dashboardUtils';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

type BarChartProps = {
  data: any[];
  xField: string;
  yField: string;
  groupingField?: string;
  showLabels?: boolean;
  showLegends?: boolean;
  disableAnimation?: boolean;
  showAllLegend?: boolean;
  xTitle?: string;
  yTitle?: string;
  colorOverride?: string[];
  meta?: any;
  tooltipOverride?: any;
  prefixSymbol?: string;
  additionalClassNames?: string;
  additionalTooltipContent?: React.ReactNode;
  onClickBar?: Function;
  isBarClickable?: boolean;
};

const BarChart = ({
  data,
  xField,
  yField,
  groupingField,
  showLabels = true,
  showLegends = true,
  disableAnimation = true,
  showAllLegend = false,
  xTitle,
  yTitle,
  colorOverride,
  meta,
  tooltipOverride,
  prefixSymbol,
  additionalClassNames = '',
  additionalTooltipContent,
  onClickBar,
  isBarClickable = false,
}: BarChartProps) => {
  const getTooltipCustomItems = useCallback(
    (originalItems: any[]) =>
      originalItems.map((item) => ({
        ...item,
        value: numberCommaSeparator(item.value),
      })),
    []
  );

  const getTooltipCustomContent = useCallback(
    (title: string, data: any[]) => (
      <TooltipContent
        title={title}
        data={data}
        prefixSymbol={prefixSymbol}
        additionalContent={additionalTooltipContent}
      />
    ),
    []
  );

  const labelFormatter = useCallback((text: string) => {
    if (text.length > CHART_Y_AXIS_LABEL_LENGTH_LIMIT) {
      return text.substring(0, CHART_Y_AXIS_LABEL_LENGTH_LIMIT) + '...';
    }
    return text;
  }, []);

  const config: any = {
    data,
    xField: xField,
    yField: yField,
    seriesField: groupingField,
    color: colorOverride ?? generateGraphColors(data.length),
    xAxis: {
      line: {
        style: {
          stroke: COLORS.fnGrey,
          lineWidth: 1,
          opacity: 0.7,
        },
      },
      title: {
        text: xTitle ?? null,
      },
    },
    yAxis: {
      title: {
        text: yTitle ?? null,
      },
      label: !showLabels
        ? showLabels
        : {
            formatter: labelFormatter,
          },
    },
    legend: showLegends
      ? {
          position: 'bottom',
          flipPage: !showAllLegend,
          pageNavigator: {
            marker: {
              style: {
                fill: COLORS.primaryButton,
                size: 8,
              },
            },
            text: {
              style: {
                fill: COLORS.secondaryBlack,
                fontSize: 12,
              },
            },
          },
        }
      : false,
    maxBarWidth: 24,
    animation: !disableAnimation,
    meta: meta,
    barStyle: {
      cursor: isBarClickable ? 'pointer' : 'default',
    },
    onReady: useCallback((plot: any) => {
      onClickBar && plot.on('element:click', onClickBar);
    }, []),
    tooltip: tooltipOverride
      ? { ...tooltipOverride, customContent: getTooltipCustomContent }
      : {
          customItems: getTooltipCustomItems,
          customContent: getTooltipCustomContent,
        },
  };
  return <Bar className={additionalClassNames} {...config} />;
};

export default BarChart;
