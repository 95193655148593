import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { KeyValueTypes } from 'types/dataTypes';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import './index.scss';

type PurchaseFooterProps = {
  totalHeading: string;
  totalValue: number;
  costSummary?: KeyValueTypes[];
  okTitle: string;
  onClickCancel: () => void;
  onClickOk: () => void;
  className?: string;
  loadingOk?: boolean;
  loading?: boolean;
  validationMessage?: string;
};

const PurchaseFooter = ({
  totalHeading,
  totalValue,
  costSummary,
  okTitle,
  onClickCancel,
  onClickOk,
  className,
  loadingOk,
  loading,
  validationMessage,
}: PurchaseFooterProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  return (
    <footer
      className={`buy-modal-footer flex flex-align-items-center flex-space-between ${className}`}
    >
      {loading ? (
        <Skeleton active paragraph={{ rows: 2 }} title={false} />
      ) : (
        <div className="flex flex-column flex-gap-4 flex-fit">
          <span
            style={{
              display: validationMessage ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {validationMessage}
          </span>
          <span className="form-header">
            {totalHeading}: {currencySymbol}
            {numberCommaSeparator(totalValue)}
          </span>
          {costSummary?.map((item) => (
            <span
              className="flex flex-align-items-center flex-gap-4"
              key={item.key}
            >
              <span className="summary-items-key small-grey-button">
                {item.key}
              </span>
              <span className="font-caption-bold">
                {currencySymbol}
                {numberCommaSeparator(item.value)}
              </span>
            </span>
          ))}
        </div>
      )}
      <div className="flex flex-align-items-center flex-gap-16">
        <Button
          title={t('purchasePage.cart.cancel')}
          type={BUTTON_TYPE.LINK}
          onClick={onClickCancel}
        />
        <Button title={okTitle} onClick={onClickOk} loading={loadingOk} />
      </div>
    </footer>
  );
};

export default PurchaseFooter;
