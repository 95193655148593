import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { getGcpDatasets, getGcpDatasetTables } from 'utils/services';

/**
 * @function fetchGcpDatasets
 * @description Function fetch the gcp datasets
 * @param jsonFileId file id of the file uploaded
 * @param projectId project id associated with the file uploaded
 * @param setOptions setter for the fetched data
 * @param setRequestStatus setter for the request status
 */
export const fetchGcpDatasets = (
  jsonFileId: string,
  projectId: string,
  setOptions: (options: string[]) => void,
  setRequestStatus: (val: string) => void,
  isEditConnection: boolean,
  connectorId: string
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);
  const body = isEditConnection
    ? {
        connectorId: connectorId,
        projectId: projectId,
      }
    : {
        jsonFileId: jsonFileId,
        projectId: projectId,
      };
  getGcpDatasets(body)
    .then((res: any) => {
      setOptions(res.data.responseData);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e: any) => {
      onApiCallError(e, true, setRequestStatus);
      setOptions([]);
    });
};

/**
 * @function fetchDatasetTables
 * @description Function to fetch dataset tables when a gcp data set is selected
 * @param jsonFileId file id of the file uploaded
 * @param projectId project id associated with the file uploaded
 * @param dataset dataset for which the tables are fetched
 * @param setOptions setter for the fetched data
 * @param setRequestStatus setter for the request status
 */
export const fetchDatasetTables = (
  jsonFileId: string,
  projectId: string,
  dataset: string,
  setOptions: (options: string[]) => void,
  setRequestStatus: (val: string) => void,
  isEditConnection: boolean,
  connectorId: string
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);
  const body = isEditConnection
    ? {
        connectorId: connectorId,
        projectId: projectId,
        billingDataset: dataset,
      }
    : {
        jsonFileId: jsonFileId,
        projectId: projectId,
        billingDataset: dataset,
      };
  getGcpDatasetTables(body)
    .then((res: any) => {
      setOptions(res.data.responseData);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e: any) => {
      onApiCallError(e, true, setRequestStatus);
      setOptions([]);
    });
};
