import i18n from 'i18n';
import { PurchaseComputeEngineType } from './types';

export enum CommittedUseDiscountsTabs {
  COMPUTE_ENGINE = 'COMPUTE_ENGINE',
}

export enum GCP_TERMS {
  ONE_YEAR = 'TWELVE_MONTH',
  THREE_YEARS = 'THIRTY_SIX_MONTH',
}

export const GCP_TERMS_LIST = [
  {
    value: GCP_TERMS.ONE_YEAR,
    label: i18n.t('purchasePage.cart.countMonths', { count: 12 }),
  },
  {
    value: GCP_TERMS.THREE_YEARS,
    label: i18n.t('purchasePage.cart.countMonths', { count: 36 }),
  },
];

export const GCP_CUD_NAME_LENGTH = 63;

export const GCP_COMPUTE_ENGINE_DEFAULT_VALUES: PurchaseComputeEngineType = {
  name: '',
  region: '',
  machineFamily: '',
  plan: GCP_TERMS.ONE_YEAR,
  cores: 1,
  memory: 1,
  totalMonthlyEst: 0,
  originalCost: 0,
  committedUseDiscount: 0,
};
