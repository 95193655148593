import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import parse from 'html-react-parser';
import licences from 'assets/data/licences.json';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import './index.scss';

const AcknowledgementPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'acknowledgement' });

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  return (
    <div className="flex flex-column acknowledgment styled-scroll">
      <span className="acknowledgment-header new-page-header modal-heading flex flex-align-items-center">
        {t('heading')}
      </span>
      <div className="card licence-container page-content">
        {licences.map((item) => (
          <>
            <div className="modal-heading">{item.licence}</div>
            <div className="table-typography">
              {parse(
                Array.isArray(item.description)
                  ? item.description.join('')
                  : item.description
              )}
            </div>
            {item.libraries && item.libraries.length > 0 && (
              <div className="libraries table-typography">
                <span className="font-caption-bold">{t('libraries')}</span>
                <ol>
                  {item.libraries?.map((library) => (
                    <li key={library}>{library}</li>
                  ))}
                </ol>
              </div>
            )}
            <Divider dashed={true} />
          </>
        ))}
      </div>
    </div>
  );
};

export default AcknowledgementPage;
