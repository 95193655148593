import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Empty } from 'antd';

import {
  selectTagCompliance,
  setSelectedConnection,
  setTagComplianceConnectionList,
} from 'redux/tagComplianceSlice';
import { selectTheme } from 'redux/themeSlice';
import NavigationPath from 'components/NavigationPath';
import Input from 'components/Input';
import Table from 'components/Table';
import Button from 'components/Button';
import Pagination from 'components/Pagination';
import DashboardComponent from 'components/DashboardComponent';
import Icon from 'components/Icon';
import TagsList from 'components/TagsList';
import { ICONS } from 'constants/icons';
import { DASHBOARD_TYPES, REQUEST_STATUS } from 'constants/requestBody';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { BUTTON_SIZE, INPUT_SIZE } from 'constants/appearance';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import { getChartData, getLabels } from 'utils/services';
import { PROVIDER } from 'constants/cloudProviders';

import { fetchPersistedTags } from './services';
import { LabelValueType, UntaggedResourceType } from './types';
import Header from './components/Header';
import AddRequiredTagsDrawer from './components/AddRequiredTagsDrawer';
import AddResourceTagValuesDrawer from './components/AddResourceTagValuesDrawer';
import ComplianceScore from './components/ComplianceScore';
import {
  getMissingTags,
  getExistingTags,
  getUntaggedResourcesCountQuery,
  getUntaggedResourcesQuery,
} from './utils';

import './index.scss';

const TagCompliancePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection } = useSelector(selectTagCompliance);
  const { theme } = useSelector(selectTheme);

  const [isEmptyConnection, setIsEmptyConnection] = useState(false);
  const [connectionReqStatus, setConnectionReqStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [showMandatoryTagsDrawer, setShowMandatoryTagsDrawer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [resources, setResources] = useState<UntaggedResourceType[]>([]);
  const [totalResourcesCount, setTotalResourcesCount] = useState(0);
  const [allTags, setAllTags] = useState<LabelValueType[]>([]);
  const [fetchTagsRequestStatus, setFetchTagsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [persistedTags, setPersistedTags] = useState<string[]>([]);
  const [selectedResource, setSelectedResource] =
    useState<UntaggedResourceType>();
  const [showAddResourceTagsModal, setShowAddResourceTagsModal] =
    useState(false);
  const [fetchResourcesReqStatus, setFetchResourcesReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchPersistentTagsReqStatus, setFetchPersistentTagsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
      dispatch(setSelectedConnection(null));
      dispatch(setTagComplianceConnectionList([]));
    };
  }, []);

  useEffect(() => {
    setAllTags([]);
    setPersistedTags([]);
    setCurrentPage(1);
    setSearchKey('');
    setResources([]);
    setTotalResourcesCount(0);
    if (selectedConnection) {
      getAllTags();
      getPersistedTags();
    }
  }, [selectedConnection]);

  useEffect(() => {
    if (currentPage === -1) {
      setCurrentPage(1);
      return;
    }

    let source = axios.CancelToken.source();
    getResources(source);

    return () => {
      source.cancel();
    };
  }, [searchKey, persistedTags, currentPage, allTags]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    getResourcesCount(source);

    return () => {
      source.cancel();
    };
  }, [searchKey, persistedTags, allTags]);

  /**
   * @function getAllTags
   * @description Function to fetch all the tag labels
   */
  const getAllTags = () => {
    setFetchTagsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedConnection!.connectorId,
      dashBoardType: DASHBOARD_TYPES.BILLING,
    };

    getLabels(params)
      .then((res: any) => {
        let tags: LabelValueType[] = [];
        if (res.status === 200) {
          if (
            [PROVIDER.GCP.valueOf(), PROVIDER.AZURE.valueOf()].includes(
              selectedConnection!.provider
            )
          )
            tags = res.data.map((eachTag: any) => ({
              label: eachTag.label_keys,
              value: eachTag.label_keys,
            }));
          if (selectedConnection!.provider === PROVIDER.AWS)
            tags = res.data.map((eachTag: any) => ({
              label: eachTag.field,
              value: eachTag.rawField,
            }));
        }
        setAllTags(tags);
        setFetchTagsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchTagsRequestStatus);
        setAllTags([]);
      });
  };

  /**
   * @function getPersistedTags
   * @description Function to fetch the persisted tags
   */
  const getPersistedTags = () => {
    setFetchPersistentTagsReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection!.connectorId,
    };

    fetchPersistedTags(params)
      .then((res: any) => {
        setPersistedTags(res.data.responseData.tagNames ?? []);
        setFetchPersistentTagsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchPersistentTagsReqStatus);
        setPersistedTags([]);
      });
  };

  /**
   * @function getResources
   * @description function to fetch the untagged resources
   * @param cancelToken cancel token for cancelling the frequent requests
   */
  const getResources = (cancelToken: CancelTokenSource) => {
    if (persistedTags.length === 0 || allTags.length === 0) {
      return;
    }

    setFetchResourcesReqStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = getUntaggedResourcesQuery(
      persistedTags,
      selectedConnection!.provider,
      searchKey.trim().toLowerCase(),
      selectedConnection!.billingTableName!,
      allTags.map((tag) => tag.value)
    );

    const params = {
      page: currentPage,
      pageSize: PAGINATION_SIZE,
    };

    getChartData(
      requestBody,
      selectedConnection!.connectorId,
      params,
      cancelToken.token
    )
      .then((res: any) => {
        const data = res.data ?? [];
        setResources(
          data.map((dataItem: any) => ({
            resource: dataItem.resourceId,
            service: dataItem.service,
            untaggedTags: getMissingTags(persistedTags, dataItem),
            regionCode: dataItem.regionCode,
            operation: dataItem.operation,
            resourceGlobalName: dataItem.resourceGlobalName,
            projectName: dataItem.projectName,
            existingTags: getExistingTags(
              dataItem,
              selectedConnection!.provider,
              allTags.map((tag) => tag.value)
            ),
          }))
        );
        setFetchResourcesReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchResourcesReqStatus);
        setResources([]);
      });
  };

  /**
   * @function getResourcesCount
   * @description function to fetch the untagged resources count
   * @param cancelToken cancel token for cancelling the frequent requests
   */
  const getResourcesCount = (cancelToken: CancelTokenSource) => {
    if (persistedTags.length === 0 || allTags.length === 0) {
      return;
    }

    const requestBody = getUntaggedResourcesCountQuery(
      persistedTags,
      selectedConnection!.provider,
      searchKey.trim().toLowerCase(),
      selectedConnection!.billingTableName!,
      allTags.map((tag) => tag.value)
    );

    getChartData(
      requestBody,
      selectedConnection!.connectorId,
      {},
      cancelToken.token
    )
      .then((res: any) => {
        const data = res.data[0].totalCount ?? 0;
        setTotalResourcesCount(Number(data));
      })
      .catch((e) => {
        onApiCallError(e, false);
        setTotalResourcesCount(0);
      });
  };

  /**
   * @function onClickUpdate
   * @description Callback function for update
   */
  const onClickUpdate = () => {
    setResources([]);
    setSearchKey('');
    setCurrentPage(-1);
  };

  /**
   * @function onClickPersistTags
   * @description Callback function for post persisting tags
   */
  const onClickPersistTags = () => {
    getPersistedTags();
    setPersistedTags([]);
    setResources([]);
    setSearchKey('');
    setCurrentPage(1);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: 50,
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
    },
    {
      title: t('tagCompliance.resource'),
      dataIndex: 'resource',
      key: 'resource',
      width: 200,
    },
    {
      title: t('tagCompliance.service'),
      dataIndex: 'service',
      key: 'service',
      width: 150,
    },
    {
      title: t('tagCompliance.existingAddedTags'),
      dataIndex: 'existingTags',
      key: 'existingTags',
      render: (_text: string, record: UntaggedResourceType) => (
        <TagsList tags={record.existingTags} maxTagsToShow={3} showMore />
      ),
    },
    {
      title: t('tagCompliance.tagsMissing'),
      dataIndex: 'tagsMissing',
      key: 'tagsMissing',
      render: (_text: string, record: UntaggedResourceType) => (
        <TagsList
          tags={record.untaggedTags.map(
            (tag) => allTags.find((item) => item.value === tag)?.label ?? tag
          )}
          maxTagsToShow={3}
          showMore
        />
      ),
    },
    {
      title: t('tagCompliance.addTags'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: UntaggedResourceType) => (
        <Icon
          iconName={ICONS.RI_SHARE_BOX_LINE}
          color={theme.buttonIconColor}
          onClick={() => {
            setSelectedResource(record);
            setShowAddResourceTagsModal(true);
          }}
          dataTestId="apply-tags-cta"
        />
      ),
      width: 100,
      align: 'center' as const,
    },
  ];

  const TableComponent = (
    <>
      <div className="flex flex-align-items-center flex-space-between">
        <Input
          type="search"
          value={searchKey}
          placeholder={t('tagCompliance.searchByResourceOrService')}
          onChange={(e: any) => {
            setCurrentPage(1);
            setSearchKey(e.target.value);
          }}
          size={INPUT_SIZE.SMALL}
        />
        <Button
          title={t('tagCompliance.setupMandatoryTags')}
          size={BUTTON_SIZE.SMALL}
          onClick={() => setShowMandatoryTagsDrawer(true)}
          disabled={!selectedConnection}
        />
      </div>
      <div className="flex flex-column flex-gap-24">
        <ComplianceScore
          tableRequestStatus={evaluateRequestArray([
            fetchPersistentTagsReqStatus,
            fetchTagsRequestStatus,
          ])}
          mandatoryTags={persistedTags}
          searchKey={searchKey.trim().toLowerCase()}
        />
        <Table
          pagination={false}
          dataSource={resources.map((resource, index) => ({
            ...resource,
            key: index + 1,
          }))}
          columns={columns}
          loading={[
            fetchResourcesReqStatus,
            fetchPersistentTagsReqStatus,
            fetchTagsRequestStatus,
          ].includes(REQUEST_STATUS.PROCESSING)}
          locale={{
            emptyText: ![
              fetchResourcesReqStatus,
              fetchPersistentTagsReqStatus,
              fetchTagsRequestStatus,
            ].includes(REQUEST_STATUS.PROCESSING) && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  fetchResourcesReqStatus === REQUEST_STATUS.ERROR
                    ? t('graphErrorMessage')
                    : t('noData')
                }
              />
            ),
          }}
          scroll={{ y: '100%' }}
          designVersion2={true}
          fillContainer={true}
          data-testid="tag-compliance-table"
        />
      </div>
      {totalResourcesCount > PAGINATION_SIZE && (
        <Pagination
          rootClassName="flex flex-align-items-end flex-fit"
          current={currentPage}
          onChange={(page: number) => {
            setCurrentPage(page);
          }}
          total={totalResourcesCount}
          defaultPageSize={PAGINATION_SIZE}
        />
      )}
    </>
  );

  return (
    <div className="tag-compliance flex flex-column flex-fit">
      <Header
        setIsEmptyConnection={setIsEmptyConnection}
        setConnectionReqStatus={setConnectionReqStatus}
        resources={resources}
        allTags={allTags}
      />
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="tag-compliance-content margin-24 flex flex-column flex-gap-16 flex-fit">
          {isEmptyConnection ? (
            <div className="flex flex-center">{t('noConnectionsFound')}</div>
          ) : (
            <DashboardComponent
              component={TableComponent}
              requestStatus={connectionReqStatus}
              errorMessage={t('tagCompliance.errorFetchingConnections')}
            />
          )}
          <AddRequiredTagsDrawer
            show={showMandatoryTagsDrawer}
            setShow={setShowMandatoryTagsDrawer}
            allTags={allTags}
            persistedTags={persistedTags}
            onClickAddOrUpdate={onClickPersistTags}
          />
          <AddResourceTagValuesDrawer
            show={showAddResourceTagsModal && !!selectedResource}
            setShow={setShowAddResourceTagsModal}
            record={selectedResource}
            setSelectedRecord={setSelectedResource}
            onClickAddTags={onClickUpdate}
            allTags={allTags}
          />
        </div>
      </div>
    </div>
  );
};

export default TagCompliancePage;
