import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import { sum } from 'lodash';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import GraphHeader from 'components/GraphHeader';
import { selectDashboard } from 'redux/dashboardSlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import DashboardComponent from 'components/DashboardComponent';
import PieDonutChart from 'components/PieChartAnt';
import { CreditDistributionByProjectColorTypes } from 'types/dataTypes';

import { getGCPCreditDistributionByProjectExcelData } from '../../utils/exportToExcel';

import './index.scss';

type CreditDistributionByProjectProps = {
  isCreditDistributionByProjectTableView: boolean;
  setCreditDistributionByProjectTableView: (value: boolean) => void;
  requestStatus: string;
  creditDistributionByProjectData: CreditDistributionByProjectColorTypes[];
  pdfView: boolean;
  creditByProjectStartMonth: string;
  creditByProjectEndMonth: string;
  onChangeCreditByProjectDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

export const CreditDistributionByProject = ({
  isCreditDistributionByProjectTableView,
  setCreditDistributionByProjectTableView,
  requestStatus,
  creditDistributionByProjectData,
  pdfView,
  creditByProjectStartMonth,
  creditByProjectEndMonth,
  onChangeCreditByProjectDateRange,
}: CreditDistributionByProjectProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const CreditDistributionByProjectColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('gcpCreditDistributionByProject.project'),
      dataIndex: 'project',
      key: 'project',
    },
    {
      title: t('gcpCreditDistributionByProject.credit'),
      dataIndex: 'credit',
      key: 'credit',
      width: 180,
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text || 0),
    },
  ];
  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(creditByProjectStartMonth),
          moment(creditByProjectEndMonth),
        ]}
        onChange={onChangeCreditByProjectDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  const getComponent = () =>
    isCreditDistributionByProjectTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={creditDistributionByProjectData.map((value, index) => {
            return {
              ...value,
              key: index,
            };
          })}
          columns={CreditDistributionByProjectColumns}
          scroll={{ y: 165 }}
          designVersion2
        />
      </div>
    ) : (
      <PieDonutChart
        data={creditDistributionByProjectData}
        angleField={'credit'}
        colorField={'project'}
        isDonut
        showLegends={!pdfView}
        disableAnimation={pdfView}
        additionalClassNames={`${pdfView && 'pdf-wrapper pdf-pie-wrapper'}`}
        colorOverride={creditDistributionByProjectData.map(
          (value) => value.color
        )}
        prefixSymbol={currencySymbol}
      />
    );

  return (
    <div
      className="gcp-credit-distribution-by-project graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.creditDistributionbyProject')}
        headingComponent={
          <div className="flex flex-column flex-gap-8">
            <span className="graph-heading font-caption-bold">
              {t('graphHeadings.creditDistributionbyProject')}
            </span>
            <span className="font-graph-total">
              {currencySymbol}
              {numberCommaSeparator(
                sum(
                  creditDistributionByProjectData.map((value) => value.credit)
                )
              )}
            </span>
          </div>
        }
        graphName={'credit-distribution-by-project'}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        isTableView={isCreditDistributionByProjectTableView}
        setIsTableView={setCreditDistributionByProjectTableView}
        excelData={getGCPCreditDistributionByProjectExcelData(
          selectedDashboard?.connectorName ?? '',
          creditDistributionByProjectData
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};
