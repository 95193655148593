import { useDispatch, useSelector } from 'react-redux';

import { customDashboard, setCustomViewData } from 'redux/customDashboardSlice';
import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { CHART_TYPES } from 'constants/graphConfig';
import {
  getSelectedChart,
  getSelectedChartLayout,
} from 'pages/CustomDashboardPage/utils';
import { ChartType } from 'types/dashboard';
import { CHARTS_LIST } from 'constants/dashboard';

import './index.scss';

const ChartsList = () => {
  const dispatch = useDispatch();

  const {
    selectedChartRow,
    selectedChartIndex,
    customViewData,
    selectedDimensions,
    selectedMetrics,
  } = useSelector(customDashboard);

  /**
   * @function isChartTypeDisabled
   * @description Function to return if the chart type is disabled
   * @param chartType chart type validated
   * @returns boolean true if teh chart type is disabled else false
   */
  const isChartTypeDisabled = (chartType: string) => {
    if (chartType === CHART_TYPES.TABLE) {
      return false;
    }

    return selectedDimensions.length === 0 || selectedMetrics.length === 0;
  };

  /**
   * @function getUpdatedChartQuery
   * @description Function to return the updated query based on the metrics and dimensions allowed
   * @param chart selected chart data
   * @param chartType chart type updated to
   * @returns boolean true if teh chart type is disabled else false
   */
  const getUpdatedChartQuery = (chart: ChartType, chartType: string) => {
    if (chartType === CHART_TYPES.TABLE) {
      return { ...chart, chartType: chartType };
    }

    // Update dimensions based on the dimensions or metrics count required
    const maxDimensionCountAllowed =
      CHARTS_LIST.find((item) => item.chartType === chartType)?.dimensionsCount
        ?.max ?? 1;
    const currentDimensionsCount = chart.chartQuery?.groupBy?.length ?? 0;
    const maxMetricsCountAllowed =
      CHARTS_LIST.find((item) => item.chartType === chartType)?.metricsCount
        ?.max ?? 1;
    const currentMetricsCount =
      (chart.chartQuery?.columns?.length ?? 0) - currentDimensionsCount;

    let chartQuery = chart.chartQuery;
    if (
      (currentDimensionsCount > 0 &&
        currentDimensionsCount > maxDimensionCountAllowed) ||
      (currentMetricsCount > 0 && currentMetricsCount > maxMetricsCountAllowed)
    ) {
      chartQuery = {
        ...chart.chartQuery,
        columns: [],
        groupBy: [],
        aggregators: [],
      };
    }

    return { ...chart, chartType: chartType, chartQuery: chartQuery };
  };

  /**
   * @function onClickChartType
   * @description Callback function for selecting the chart type
   * @param chartType type of chart selected
   */
  const onClickChartType = (chartType: string) => {
    const allLayouts = Array.from(customViewData.layoutDesigns);
    let layout = getSelectedChartLayout();
    const allCharts = Array.from(layout?.charts ?? []);
    let chart = allCharts?.at(selectedChartIndex);
    if (chart) {
      chart = getUpdatedChartQuery(chart, chartType);
      allCharts?.splice(selectedChartIndex, 1, chart);
    }

    if (layout) {
      layout = { ...layout, charts: allCharts };
      allLayouts.splice(selectedChartRow, 1, layout);
    }

    dispatch(
      setCustomViewData({ ...customViewData, layoutDesigns: allLayouts })
    );
  };

  return (
    <div className="charts-type-list flex flex-center flex-gap-8 flex-wrap">
      {CHARTS_LIST.map((item) => (
        <Tooltip key={item.chartType} overlay={<span>{item.label}</span>}>
          <AccessibleDiv
            className={`chart-type-container cursor-pointer flex flex-center ${
              isChartTypeDisabled(item.chartType) && 'disabled'
            } ${
              getSelectedChart()?.chartType === item.chartType && 'selected'
            }`}
            key={item.chartType}
            onClick={() => onClickChartType(item.chartType)}
          >
            <Icon
              className="chart-icon flex flex-center flex-fit"
              iconName={item.iconName}
              icon={item.icon}
            />
          </AccessibleDiv>
        </Tooltip>
      ))}
    </div>
  );
};

export default ChartsList;
