import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'constants/requestBody';
import Button from 'components/Button';
import FiltersModal from 'pages/CostOptimizationInsightsPage/components/FiltersModal';
import ConfirmModal from 'components/ConfirmModal';
import { createRecommendationView } from 'pages/CostOptimizationInsightsPage/services';
import {
  RecommendationFiltersType,
  ViewListType,
} from 'pages/CostOptimizationInsightsPage/types';
import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import { ICONS } from 'constants/icons';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';

import ViewNameModal from './components/ViewNameModal';

type FiltersProps = {
  filters: RecommendationFiltersType[];
  disabled: boolean;
  onClickSubmit: (val: ViewListType) => void;
  loading?: string[];
};

const Filters = ({
  filters,
  disabled,
  loading,
  onClickSubmit,
}: FiltersProps) => {
  const { t } = useTranslation();
  const {
    selectedCostOptimizationInsightsConnection,
    selectedCostOptimizationInsightsNav,
  } = useSelector(costOptimizationInsights);

  const [viewName, setViewName] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<
    RecommendationFiltersType[]
  >([]);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showViewNameModal, setShowViewNameModal] = useState(false);
  const [loadingCreateView, setLoadingCreateView] = useState('');

  const onCreateView = () => {
    setLoadingCreateView(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      viewName: viewName,
      connectorId: selectedCostOptimizationInsightsConnection!.connectorId,
      recommendationType: selectedCostOptimizationInsightsNav,
      recommendationsFilterDtoList: selectedFilters.map((filter) => ({
        ...filter,
        values: filter.values!.map((val) => val.value),
      })),
    };

    createRecommendationView(requestBody)
      .then((res: any) => {
        if (res.status === 200) {
          setLoadingCreateView(REQUEST_STATUS.SUCCESS);
          onClickSubmit({
            ...requestBody,
            key: requestBody.viewName,
            recommendationsFilterDtoList: selectedFilters,
          });
          setShowViewNameModal(false);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setLoadingCreateView);
      });
  };

  return (
    <div>
      <Button
        size={BUTTON_SIZE.SMALL}
        type={BUTTON_TYPE.NEUTRAL}
        title={t('costOptimizationInsight.recommendationTableHeader.filter')}
        iconName={ICONS.EQUALIZER_FILL}
        onClick={() => {
          setSelectedFilters([]);
          setShowTagsModal(true);
          setViewName('');
        }}
        loading={
          loading && evaluateRequestArray(loading) === REQUEST_STATUS.PROCESSING
        }
        disabled={disabled}
      />
      {showTagsModal && (
        <FiltersModal
          show={showTagsModal}
          setShow={setShowTagsModal}
          filters={filters}
          existingFilter={selectedFilters}
          onClickApply={(filters: RecommendationFiltersType[]) => {
            setSelectedFilters(
              filters.filter((eachFilter) => eachFilter.values!.length > 0)
            );
            setShowTagsModal(false);
            setShowConfirmModal(true);
          }}
          minimumTags={1}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          show={showConfirmModal}
          setShow={setShowConfirmModal}
          title={t('costOptimizationInsight.tagsModal.confirmHeading')}
          showSubTitle={false}
          onClickYes={() => {
            setShowConfirmModal(false);
            setShowViewNameModal(true);
          }}
          onClickNo={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
      {showViewNameModal && (
        <ViewNameModal
          show={showViewNameModal}
          setShow={setShowViewNameModal}
          viewName={viewName}
          setViewName={setViewName}
          onClickCreate={onCreateView}
          loading={loadingCreateView === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default Filters;
