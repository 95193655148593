import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getAwsAlertSubscriptionByConnection
 * @description Function to fetch all the alert subscriptions for a AWS connection
 * @param params request params for GET request.
 * @return axios response from GET request
 */
export const getAwsAlertSubscriptionByConnection = async (params: any) => {
  return ServiceCalls.get(
    APIS.GET_AWS_ALERT_SUBSCRIPTION_BY_CONNECTION,
    null,
    params
  );
};

/**
 * @function getAllAwsMonitors
 * @description Function to fetch all the aws cost monitors
 * @param params request params for GET request.
 * @return axios response from GET request
 */
export const getAllAwsMonitors = async () => {
  return ServiceCalls.get(APIS.GET_ALL_AWS_MONITORS);
};

/**
 * @function createAwsMonitor
 * @description Function to create an AWS Monitor
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createAwsMonitor = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_AWS_COST_MONITOR, body);
};

/**
 * @function updateAwsMonitor
 * @description Function to update an AWS Monitor
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const updateAwsMonitor = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_AWS_COST_MONITOR, body);
};

/**
 * @function deleteAwsMonitor
 * @description Function to delete an AWS Monitor
 * @return axios response from GET request
 */
export const deleteAwsMonitor = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_AWS_COST_MONITOR, null, params);
};

/**
 * @function detachAwsMonitor
 * @description Function to detach an AWS Monitor from Subscription
 * @param params Object containing the request body
 * @return axios response from PUT request
 */
export const detachAwsMonitor = async (params: any) => {
  return ServiceCalls.put(APIS.DETACH_AWS_MONITOR, {}, null, false, params);
};

/**
 * @function getMonitorsByConnectorId
 * @description Function to fetch all the monitors for a connector
 * @return axios response from GET request
 */
export const getMonitorsByConnectorId = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_MONITOR_BY_CONNECTION, null, params);
};
