import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { uniqBy } from 'lodash';

import LineChart from 'components/LineChart';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Cascader from 'components/Cascader';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { evaluateRequestArray } from 'utils/handleErrors';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';

import { displayRender, onChangeDateFilter, onChangeDates } from '../../utils';
import {
  EffectiveCpuUtilizationByClusterType,
  EffectiveCpuUtilizationType,
} from '../../types';
import { getEffectiveCpuUtilizationExcelData } from '../../utils/exportToExcel';
import { getDurationAxisLabel } from 'pages/ContainerInsightsPage/utils';

type EffectiveCPUUtilizationProps = {
  effectiveUtilizationByCluster: EffectiveCpuUtilizationByClusterType[];
  tableData: EffectiveCpuUtilizationType[];
  allNamespaces: string[];
  selectedNamespaces: string[];
  setSelectedNamespaces: (val: string[]) => void;
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  effectiveCPUUtilizationData: EffectiveCpuUtilizationType[];
  pdfView: boolean;
  cpuUtilizationDateFilter: DateRangeFilter;
  setCpuUtilizationDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};

const EffectiveCPUUtilization = ({
  effectiveUtilizationByCluster,
  tableData,
  allNamespaces,
  selectedNamespaces,
  setSelectedNamespaces,
  requestStatus,
  isTableView,
  setIsTableView,
  effectiveCPUUtilizationData,
  pdfView,
  cpuUtilizationDateFilter,
  setCpuUtilizationDateFilter,
  excelFilters,
}: EffectiveCPUUtilizationProps) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: t('containerInsight.usageMeteringLabels.cluster'),
      dataIndex: 'cluster',
      key: 'cluster',
      width: 150,
    },
    {
      title: t('containerInsight.usageMeteringLabels.namespace'),
      dataIndex: 'namespace',
      key: 'namespace',
      width: 150,
    },
    ...uniqBy(effectiveCPUUtilizationData, 'time').map((item) => ({
      title: item.time,
      key: `${item.time}-utilization`,
      dataIndex: `${item.time}-utilization`,
      width: 100,
    })),
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  defaultValue={[
                    moment(cpuUtilizationDateFilter.startDate),
                    moment(cpuUtilizationDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(dateString, setCpuUtilizationDateFilter)
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters flex flex-align-items-center flex-gap-16">
      <Cascader
        className="width-25"
        options={DateMenu}
        defaultValue={[cpuUtilizationDateFilter.option]}
        displayRender={() => displayRender(cpuUtilizationDateFilter)}
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setCpuUtilizationDateFilter)
        }
      />
      <GraphFilterDropdown
        additionalClassNames="width-25"
        allData={allNamespaces.map((item) => ({
          namespace: item,
        }))}
        selectedData={selectedNamespaces.map((item) => ({
          namespace: item,
        }))}
        setSelectedData={(selectedItems: any[]) => {
          setSelectedNamespaces(selectedItems.map((item) => item.namespace));
        }}
        setLabels={setSelectedNamespaces}
        valueSuffix={t('containerInsight.usageMeteringLabels.namespaces')}
        fieldName={DROPDOWN_VALUE_FIELDS.NAMESPACE}
        loading={
          evaluateRequestArray(requestStatus) === REQUEST_STATUS.PROCESSING
        }
      />
    </div>
  );

  const getComponent = () => {
    return (
      <div
        className={`graph-area full-height ${
          isTableView && 'flex flex-fit flex-gap-16'
        }`}
      >
        <div
          className={`with-filters full-height ${
            isTableView && 'with-table width-60 expand-hide'
          }`}
        >
          <LineChart
            data={effectiveUtilizationByCluster}
            xField="time"
            yField="utilization"
            groupingField="cluster"
            xTitle={getDurationAxisLabel(cpuUtilizationDateFilter)}
            yTitle={t('containerInsight.usageMeteringLabels.cpuUtilization')}
            showAllLegend={pdfView}
            disableAnimation={pdfView}
            postfixSymbol="%"
          />
        </div>
        {isTableView && (
          <div className="tabular-view width-40 full-height">
            <Table
              pagination={false}
              dataSource={tableData}
              columns={columns}
              scroll={{ y: '100%' }}
              designVersion2={true}
              fillContainer={true}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="effective-cpu-utiilization graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.effectiveCpuUtilization')}
        graphName="effective-cpu-utilization"
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getEffectiveCpuUtilizationExcelData(
          effectiveCPUUtilizationData,
          tableData,
          [
            ...excelFilters,
            {
              heading: t('excelExportLabels.namespace'),
              value: selectedNamespaces.toString(),
            },
            {
              heading: t('excelExportLabels.startDate'),
              value: cpuUtilizationDateFilter.startDate,
            },
            {
              heading: t('excelExportLabels.endDate'),
              value: cpuUtilizationDateFilter.endDate,
            },
          ]
        )}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default EffectiveCPUUtilization;
