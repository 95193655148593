import i18n from 'i18n';
import moment from 'moment';
import { sumBy } from 'lodash';

import { addIndexAndCommaSeparator } from 'pages/CostOptimizationInsightsPage/utils';
import {
  SAVINGS_TYPES,
  SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
} from 'constants/requestBody';
import { AWS_CHECK_IDS } from 'constants/recommendations';
import { HYPHEN_DATE_FORMAT } from 'utils/date';
import { CHECK_ID_TO_KEY_MAPPING } from './constants';
import {
  CategoryResourceDataType,
  CostByCategoryAndSavingsType,
} from './types';

/**
 * @function getDateRangeFiltersBySavingsType
 * @description Function to return the date range filters by savings type.
 * @param savingsType type of savings for which the date range is required.
 * @returns List of date range filters for request query.
 */
const getDateRangeFiltersBySavingsType = (savingsType: string | undefined) => {
  switch (savingsType) {
    case SAVINGS_TYPES.POTENTIAL_SAVINGS:
      return [
        {
          field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
          comparator: COMPARATORS.LESS_THAN,
          value: `#TIMESTAMP '${moment()
            .add(1, 'day')
            .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: `#TIMESTAMP '${moment()
            .subtract(90, 'day')
            .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];

    case SAVINGS_TYPES.COST_SAVINGS_IN_PROGRESS:
      return [
        {
          field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
          comparator: COMPARATORS.LESS_THAN,
          value: `#TIMESTAMP '${moment()
            .add(1, 'day')
            .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: `#TIMESTAMP '${moment()
            .subtract(90, 'days')
            .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];

    case SAVINGS_TYPES.COST_SAVED:
      return [
        {
          field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: `#TIMESTAMP '${moment()
            .endOf('month')
            .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: `#TIMESTAMP '${moment()
            .subtract(11, 'month')
            .startOf('month')
            .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
  }
};

/**
 * @function getStatusFilter
 * @description Function to return the request body for the status filter group
 * @param status recommendation status(s)
 * @returns Object containing the request body
 */
const getStatusFilter = (status: string[]) =>
  status.map((item) => ({
    field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    comparator: COMPARATORS.EQUALS,
    value: item,
    conjunctToNextFilter: CONJUNCTIONS.OR,
  }));

/**
 * @function getCostSavingsByCheckIdRequestBody
 * @description Function to return the request body for the cost savings
 * @param checkIdUniqueKey check Id unique key
 * @param checkId check Id of the recommendation
 * @param status recommendation status(s)
 * @param selectedSavingsType savings type selected on the chart
 * @returns Object containing the request body
 */
export const getCostSavingsByCheckIdRequestBody = (
  checkIdUniqueKey: string,
  checkId: string,
  status: string[],
  selectedSavingsType: string | undefined
) => {
  let aggregators = [
    {
      label: 'totalCostSaved',
      function: AGGREGATORS.SUM,
    },
  ];

  if (checkId === AWS_CHECK_IDS.IDLE_IP_ADDRESS) {
    aggregators = [];
  }

  return {
    columns: [
      {
        label: 'totalCostSaved',
        field:
          CHECK_ID_TO_KEY_MAPPING.find((item) => item.checkId === checkId)
            ?.costField ??
          QUERY_FIELDS.REPLACE_ESTIMATED_MONTHLY_SAVINGS_DOLLAR_BY_EMPTY,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'rownum',
          field: `ROW_NUMBER() OVER (PARTITION BY "${checkIdUniqueKey}" ORDER BY status_priority DESC, datetime DESC)`,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: getDateRangeFiltersBySavingsType(selectedSavingsType),
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.ROW_NUM,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          ...getStatusFilter(status),
        ],
      },
    ],
    aggregators: aggregators,
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getCostSavingsTrendRequestBody
 * @description Function to return the request body for the cost savings trend
 * @param checkId check Id of the recommendation
 * @param checkIdUniqueKey check Id unique key
 * @param status recommendation status(s)
 * @returns Object containing the request body
 */
export const getCostSavingsTrendRequestBody = (
  checkId: string | undefined,
  checkIdUniqueKey: string | undefined,
  status: string[]
) => {
  let aggregators = [
    {
      label: 'totalCost',
      function: AGGREGATORS.SUM,
    },
  ];

  if (checkId === AWS_CHECK_IDS.IDLE_IP_ADDRESS) {
    aggregators = [];
  }

  return {
    columns: [
      {
        label: 'totalCost',
        field:
          CHECK_ID_TO_KEY_MAPPING.find((item) => item.checkId === checkId)
            ?.costField ??
          QUERY_FIELDS.REPLACE_ESTIMATED_MONTHLY_SAVINGS_DOLLAR_BY_EMPTY,
      },
      {
        label: 'month',
        field: QUERY_FIELDS.FORMAT_DATE_TIME_YYYYMM,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'rownum',
          field: `ROW_NUMBER() OVER (PARTITION BY "${checkIdUniqueKey}", format_datetime(cast(date_parse(datetime,'%Y-%m-%d %H:%i:%s') as date),'YYYYMM') ORDER BY status_priority DESC, datetime DESC)`,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: `#TIMESTAMP '${moment()
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: `#TIMESTAMP '${moment()
                .subtract(11, 'month')
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.ROW_NUM,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          ...getStatusFilter(status),
        ],
      },
    ],
    aggregators: aggregators,
    groupBy: ['month'],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getCategoryCostSavingsRequestBody
 * @description Function to return the request body for resource details
 * @param checkId check Id of the recommendation
 * @param status recommendation status(s)
 * @param selectedSavingsType savings type selected on the chart
 * @returns Object containing the request body
 */
export const getCategoryCostSavingsRequestBody = (
  checkId: string | undefined,
  status: string[],
  selectedSavingsType: string | undefined
) => {
  const checkIdUniqueKey = CHECK_ID_TO_KEY_MAPPING.find(
    (item) => item.checkId === checkId
  )?.uniqueKey;

  let aggregators = [
    {
      label: 'totalCostSaved',
      function: AGGREGATORS.SUM,
    },
  ];

  let columns = [
    {
      label: 'resourceId',
      field: `"${checkIdUniqueKey}"`,
    },
    {
      label: 'state',
      field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    },
    {
      label: 'lastRefreshedAt',
      field: QUERY_FIELDS.DATETIME,
    },
    {
      label: 'cost',
      field:
        CHECK_ID_TO_KEY_MAPPING.find((item) => item.checkId === checkId)
          ?.costField ??
        QUERY_FIELDS.REPLACE_ESTIMATED_MONTHLY_SAVINGS_DOLLAR_BY_EMPTY,
    },
    ...(CHECK_ID_TO_KEY_MAPPING.find((item) => item.checkId === checkId)
      ?.resourceDetailsQueryColumns ?? []),
  ];

  if (checkId === AWS_CHECK_IDS.IDLE_IP_ADDRESS) {
    aggregators = [];
    columns = columns.filter((column) => column.label !== 'cost');
  }

  return {
    columns: columns,
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'rownum',
          field: `ROW_NUMBER() OVER (PARTITION BY "${checkIdUniqueKey}" ORDER BY status_priority DESC, datetime DESC)`,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: getDateRangeFiltersBySavingsType(selectedSavingsType),
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.ROW_NUM,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          ...getStatusFilter(status),
        ],
      },
    ],
    aggregators: aggregators,
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function fetchCategoryCostSavingsExcelExportColumns
 * @description Function to get the resources table excel columns
 * @param selectedSavingsType savings type selected in summary graph
 * @param selectedRecommendationType recommendation selected in summarized gr
 * @returns List of excel columns
 */
export const fetchCategoryCostSavingsExcelExportColumns = (
  selectedSavingsType: string | undefined,
  selectedRecommendationType: string | undefined
) => {
  return [
    {
      header: '#',
      key: 'slNo',
      width: 10,
      alignment: 'center',
      dataKey: 'slNo',
    },
    {
      header: CHECK_ID_TO_KEY_MAPPING.find(
        (item) => item.checkId === selectedRecommendationType
      )?.resourceLabel,
      key: 'resourceId',
      dataKey: 'resourceId',
      width: 30,
      alignment: 'left',
    },
    {
      header: i18n.t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.state'
      ),
      key: 'state',
      dataKey: 'state',
      width: 30,
      alignment: 'left',
    },
    {
      header: i18n.t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.lastRefreshedAt'
      ),
      key: 'lastRefreshedAt',
      dataKey: 'lastRefreshedAt',
      width: 30,
      alignment: 'left',
    },
    {
      header: SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
        (item) => item.type === selectedSavingsType
      )?.label,
      key: 'cost',
      dataKey: 'cost',
      width: 25,
      alignment: 'right',
    },
  ];
};

/**
 * @function getCostSavingsSummaryExcelData
 * @description Function to get the cost savings summary excel data
 * @param connectorName Name of the connection selected
 * @param tableHeading Table heading
 * @param data excel data
 * @param selectedSavingsType savings type selected in summary graph
 * @param selectedRecommendationType recommendation selected in summarized graph
 * @returns List of excel file configartions and data
 */
export const getCostSavingsSummaryExcelData = (
  connectorName: string,
  tableHeading: string,
  data: CategoryResourceDataType[],
  selectedSavingsType: string | undefined,
  selectedRecommendationType: string | undefined
) => {
  return {
    sheetName: tableHeading,
    columns: fetchCategoryCostSavingsExcelExportColumns(
      selectedSavingsType,
      selectedRecommendationType
    ),
    data: addIndexAndCommaSeparator(data),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

/**
 * @function getTotalCostBySavingsType
 * @description Function to calculate the total cost by savings type
 * @param costSummary Cost details by checkId and savings type
 * @returns List of cost ordered by POTENTIAL_SAVINGS, COST_SAVINGS_IN_PROGRESS, COST_SAVED
 */
export const getTotalCostBySavingsType = (
  costSummary: CostByCategoryAndSavingsType[]
) => {
  return Object.values(SAVINGS_TYPES).map((savingsType) =>
    sumBy(
      costSummary.filter((costData) => costData.savingsType === savingsType),
      'cost'
    )
  );
};
