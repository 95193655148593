import { GraphRowContentType } from 'types/dashboard';

/**
 * @function filterGraphRowsByCriteria
 * @description Filters rows based on the provided chart criteria filters.
 * @param rows - The rows to filter.
 * @param chartCriteriaFilters - The chart criteria filters to apply.
 * @returns The filtered rows. Each row group is filtered separately, and any row groups that are empty after filtering are removed.
 */
export function filterGraphRowsByCriteria(
  rows: GraphRowContentType,
  chartCriteriaFilters: string[] = []
): GraphRowContentType {
  return rows
    .map((rowGroup) =>
      rowGroup.filter(
        (row) =>
          // Filter the chart if the display is not set to false
          (row.display === undefined || row.display === true) &&
          // Filter by criteria if the dashboard contains the chartCriteriaFilters
          (!chartCriteriaFilters.length ||
            row.criteria?.some((chartCriteria) =>
              chartCriteriaFilters.includes(chartCriteria)
            ))
      )
    )
    .filter((rowGroup) => rowGroup.length > 0);
}
