import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { useSelector } from 'react-redux';

import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import { SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP } from 'pages/CostOptimizationInsightsPage/constants';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import { getCostSavingsSummaryExcelData } from '../../utils';
import { CategoryCostDetailsType } from '../../types';

type CategoryCostSavingsTableProps = {
  graphHeading: string;
  graphName: string;
  pdfView: boolean;
  categoryCostSavingsData: CategoryCostDetailsType[];
  requestStatus: string;
  selectedRecommendationType: string | undefined;
  selectedSavingsType: string | undefined;
  selectedRowKey: React.Key | undefined;
  setSelectedRowKey: (val: React.Key | undefined) => void;
  setSelectedRow: (val: CategoryCostDetailsType | undefined) => void;
};

const CategoryCostSavingsTable = ({
  graphHeading,
  graphName,
  pdfView,
  categoryCostSavingsData,
  requestStatus,
  selectedRecommendationType,
  selectedSavingsType,
  selectedRowKey,
  setSelectedRowKey,
  setSelectedRow,
}: CategoryCostSavingsTableProps) => {
  const { t } = useTranslation();
  const { selectedCostOptimizationInsightsConnection } = useSelector(
    costOptimizationInsights
  );

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 40,
    },
    {
      title: t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.resourceId'
      ),
      dataIndex: 'resourceId',
      key: 'resourceId',
      width: '25%',
    },
    {
      title: t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.projectName'
      ),
      dataIndex: 'projectName',
      key: 'projectName',
      width: '25%',
    },
    {
      title: t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.priority'
      ),
      dataIndex: 'priority',
      key: 'priority',
      width: '15%',
    },
    {
      title: t(
        'costOptimizationInsight.statusDashboard.costSavingsTable.state'
      ),
      dataIndex: 'state',
      key: 'state',
      width: '15%',
    },
    {
      title: SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
        (item) => item.type === selectedSavingsType
      )?.label,
      dataIndex: 'cost',
      key: 'cost',
      render: (text: any) => numberCommaSeparator(text || 0),
      width: '20%',
      align: 'right',
    },
  ];

  /**
   * @function getComponent
   * @description Function to return the graph or table component
   * @returns JSX element containing graph or the table
   */
  const getComponent = () => {
    if (!selectedRecommendationType) {
      return (
        <div className="flex flex-column flex-center full-height no-chart-selection">
          <div className="font-body heading">
            {t(
              'costOptimizationInsight.statusDashboard.recommendationStatusTable.noDataFound'
            )}
          </div>
          <div className="font-caption sub-heading">
            {t(
              'costOptimizationInsight.statusDashboard.recommendationStatusTable.selectChartsToPopulateData'
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="graph cost-savings-table">
        <Table
          rowClassName={(_record: any, index: number) => {
            if (selectedRowKey === index) {
              return 'selected-row';
            }
          }}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: [selectedRowKey],
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
              setSelectedRowKey(selectedRowKeys[0]);
              setSelectedRow(selectedRows[0]);
            },
          }}
          pagination={false}
          dataSource={categoryCostSavingsData.map((item, index) => ({
            key: index,
            ...item,
          }))}
          columns={columns}
          loading={
            evaluateRequestArray([requestStatus]) === REQUEST_STATUS.PROCESSING
          }
          scroll={{ y: '100%' }}
          designVersion2={true}
          fillContainer={true}
        />
      </div>
    );
  };

  return (
    <div
      className="graph-area summary-table flex flex-column flex-gap-16 full-width"
      id="graph-container"
    >
      <GraphHeader
        heading={graphHeading}
        graphName={graphName}
        isTableViewSwitch={false}
        isTableView={true}
        showExpandIcon={!pdfView}
        isDownloadable={!pdfView && !!selectedRecommendationType}
        excelData={getCostSavingsSummaryExcelData(
          selectedCostOptimizationInsightsConnection?.name ?? '',
          graphHeading,
          categoryCostSavingsData,
          selectedSavingsType
        )}
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray([requestStatus])}
      />
    </div>
  );
};

export default CategoryCostSavingsTable;
