import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectDashboard,
  setPdfGraphToDownload,
  setPdfDownloadMode,
  setTableViewEnabled,
} from 'redux/dashboardSlice';
import {
  costOptimizationInsights,
  setSelectedGranulateService,
  setShowConsolidatedDashboard,
} from 'redux/costOptimizationInsightsSlice';
import {
  selectContainerInsights,
  setContainerCostConnectionList,
  setSelectedConnection,
} from 'redux/containerInsightsSlice';
import { selectTheme } from 'redux/themeSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import { CONTAINER_INSIGHTS_DASHBOARD_NAVIGATION } from 'pages/ContainerInsightsPage/constants';
import { ConsumptionManagementType } from 'pages/CostOptimizationInsightsPage/types';
import Button from 'components/Button';
import ConnectionsListDrawer from 'components/ConnectionsListDrawer';
import Switch from 'components/Switch';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { SwitchConnectionTypes } from 'components/ConnectionsListDrawer/constants';
import { PDF_ALL_GRAPHS } from 'constants/pdfConstants';
import { LoadingIcon } from 'assets/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  searchConnectionData,
  getGranulateConnections,
  getGranulateDashboardServices,
} from 'utils/services';
import { exportToExcel } from 'utils/exportToExcel';
import { REFRESH_INTERVAL } from 'constants/defaultValues';
import { ConnectionListType } from 'types/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import { PROVIDER } from 'constants/cloudProviders';
import ContainerDashboardTitle from '../ContainerDashboardTitle';
import GranulateDashboardHeader from '../GranulateDashboardHeader';

import './index.scss';

type ContainerDashboardHeaderProps = {
  setIsEmptyDashboard: (val: boolean) => void;
  setRequestStatus: (val: string) => void;
};

const ContainerDashboardHeader = ({
  setIsEmptyDashboard,
  setRequestStatus,
}: ContainerDashboardHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { exportToExcelData, tableViewEnabled, pdfDownloadMode } =
    useSelector(selectDashboard);
  const {
    selectedConnection,
    containerCostConnectionList,
    selectedContainerCostDashboardView,
  } = useSelector(selectContainerInsights);
  const { selectedGranulateService, showConsolidatedDashboard } = useSelector(
    costOptimizationInsights
  );
  const { theme } = useSelector(selectTheme);
  const { permissions } = useSelector(userAuthorization);

  const [isDownloadingData, setIsDownloadingData] = useState(false);
  const [fetchConnectionsRequestStatus, setFetchConnectionsRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [showSwitchConnectionDrawer, setShowSwitchConnectionDrawer] =
    useState(false);
  const [selectedDashboardListType, setSelectedDashboardListType] = useState(
    SwitchConnectionTypes.CB_360
  );
  const [granulateServiceList, setGranulateServiceList] = useState<
    ConsumptionManagementType[]
  >([]);
  const [granulateServicesReqStatus, setGranulateServicesReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  const isCB360TabSelected =
    selectedDashboardListType === SwitchConnectionTypes.CB_360;

  useEffect(() => {
    fetchConnections();
    const interval = setInterval(
      () => fetchConnections(false),
      REFRESH_INTERVAL
    );

    //Don't call granulate API when permissions not granted
    if (permissions.granulate && permissions.thirdPartyAppsRead) {
      fetchGranulateServices();
    }

    return () => {
      clearInterval(interval);
      dispatch(setSelectedGranulateService(null));
      dispatch(setShowConsolidatedDashboard(false));
    };
  }, []);

  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  useEffect(() => {
    if (containerCostConnectionList.length === 0) {
      return;
    }

    if (
      !selectedConnection ||
      !containerCostConnectionList.some(
        (availableDashboard) =>
          availableDashboard.connectorId === selectedConnection.connectorId
      )
    ) {
      dispatch(setSelectedConnection(containerCostConnectionList[0]));
    }
  }, [containerCostConnectionList]);

  /**
   * @function fetchConnections
   * @description Function to fetch the connection list
   */
  const fetchConnections = (addLoader: boolean = true) => {
    addLoader && setFetchConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: [PROVIDER.AWS, PROVIDER.GCP].join(','),
    };

    searchConnectionData(params)
      .then((res: any) => {
        let connections: ConnectionListType[] =
          res?.data?.responseData?.content;
        connections = connections?.filter(
          (connection) => connection.wantContainerCost && connection.wantBilling
        );
        dispatch(setContainerCostConnectionList(connections));
        setIsEmptyDashboard(connections.length === 0);
        if (addLoader) {
          setFetchConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
          setRequestStatus(REQUEST_STATUS.SUCCESS);
        }
      })
      .catch((e) => {
        onApiCallError(
          e,
          false,
          addLoader ? setFetchConnectionsRequestStatus : undefined
        );
        dispatch(setContainerCostConnectionList([]));
        addLoader && setRequestStatus(REQUEST_STATUS.ERROR);
      });
  };

  /**
   * @function fetchGranulateServices
   * @description Function to fetch granulate connection list data
   */
  const fetchGranulateServices = () => {
    setGranulateServicesReqStatus(REQUEST_STATUS.PROCESSING);

    const requests = [
      getGranulateDashboardServices(),
      getGranulateConnections(),
    ];

    axios
      .all(requests)
      .then((response: any[]) => {
        if (response.every((res) => res.status === 200)) {
          const list: ConsumptionManagementType[] = [];

          response[0]?.data?.response?.forEach((res: any, index: number) => {
            const matchedData = response[1]?.data?.find(
              (item: any) =>
                item?.serviceClusterMapList[0]?.serviceName === res.serviceName
            );

            if (matchedData) {
              list.push({
                key: (index + 1).toString(),
                connectionName: res.connectionName,
                provider: res.cloud,
                createdAt: res.creationDate,
                name: res.serviceDisplayName,
                serviceName: res.serviceName,
                connectorId: matchedData.connectorId,
              });
            }
          });
          setGranulateServiceList(list);
          setGranulateServicesReqStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setGranulateServicesReqStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, false, (val) => setGranulateServicesReqStatus(val));
      });
  };

  /**
   * @function onHandleDownloadDashboardData
   * @description function to handle common download of dashboard view.
   * @returns either pdf or excel
   */
  const onHandleDownloadDashboardData = () => {
    setIsDownloadingData(true);
    if (!tableViewEnabled) {
      dispatch(setPdfGraphToDownload(PDF_ALL_GRAPHS));
      dispatch(setPdfDownloadMode(true));
      return;
    }
    exportToExcel(
      showConsolidatedDashboard
        ? selectedGranulateService?.connectionName + ' '
        : selectedConnection?.name +
            ' ' +
            CONTAINER_INSIGHTS_DASHBOARD_NAVIGATION.find(
              (view) => view.id === selectedContainerCostDashboardView
            )?.title,
      exportToExcelData
    ).then(() => setIsDownloadingData(false));
  };

  let SelectedConnection;

  if (isCB360TabSelected) {
    if (!selectedGranulateService) {
      SelectedConnection = selectedConnection as ConsumptionManagementType;
    } else {
      SelectedConnection = null;
    }
  } else {
    SelectedConnection = selectedGranulateService;
  }

  const getDashboardHeaderComponent = () => {
    if (selectedGranulateService) {
      return <GranulateDashboardHeader />;
    } else if (selectedConnection) {
      return <ContainerDashboardTitle />;
    } else {
      return <Icon icon={LoadingIcon} className="rotate" />;
    }
  };

  return (
    <div className="container-cost-dashboard-header flex flex-align-items-center flex-space-between flex-wrap">
      {getDashboardHeaderComponent()}
      <div className="header-right flex flex-align-items-center flex-gap-16">
        {isDownloadingData ? (
          <Icon
            icon={LoadingIcon}
            className="rotate"
            color={theme.buttonIconColor}
          />
        ) : (
          <Icon
            iconName={ICONS.DOWNLOAD_2_LINE}
            onClick={() => onHandleDownloadDashboardData()}
            size={ICONS_SIZE.ONE_X}
            color={theme.buttonIconColor}
            dataTestId="download-cta"
          />
        )}
        <div className="table-view-title table-typography flex flex-align-items-center flex-gap-8">
          {t('tableView')}
          <Switch
            size="small"
            checked={tableViewEnabled}
            onChange={(value: boolean) => {
              dispatch(setTableViewEnabled(value));
            }}
          />
        </div>
        <Button
          title={t('dashboardLabels.switchConnection')}
          iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
          iconSize={ICONS_SIZE.ONE_X}
          onClick={() => setShowSwitchConnectionDrawer(true)}
        />
      </div>
      <ConnectionsListDrawer
        isCB360TabSelected={isCB360TabSelected}
        show={showSwitchConnectionDrawer}
        setShow={setShowSwitchConnectionDrawer}
        selectedConnection={SelectedConnection}
        connectionList={
          isCB360TabSelected
            ? (containerCostConnectionList as ConsumptionManagementType[])
            : granulateServiceList
        }
        onClick={(key: any) => {
          if (isCB360TabSelected) {
            dispatch(setSelectedGranulateService(null));
            dispatch(setShowConsolidatedDashboard(false));
            dispatch(
              setSelectedConnection(
                containerCostConnectionList.find(
                  (connection) => connection.connectorId === key
                ) ?? null
              )
            );
          } else {
            dispatch(setSelectedConnection(null));
            dispatch(setShowConsolidatedDashboard(true));
            dispatch(setSelectedGranulateService(key));
          }
          setShowSwitchConnectionDrawer(false);
        }}
        showConnectionTypeNav={
          permissions.granulate && permissions.thirdPartyAppsRead
        }
        selectedConnectionListType={selectedDashboardListType}
        setSelectedConnectionListType={setSelectedDashboardListType}
        loading={
          isCB360TabSelected
            ? fetchConnectionsRequestStatus === REQUEST_STATUS.PROCESSING
            : granulateServicesReqStatus === REQUEST_STATUS.PROCESSING
        }
      />
    </div>
  );
};

export default ContainerDashboardHeader;
