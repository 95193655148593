import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { uniqBy } from 'lodash';

import ColumnLineChart from 'components/ColumnLineChart';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Cascader from 'components/Cascader';
import SelectDropdown from 'components/Select';
import { INPUT_SIZE } from 'constants/appearance';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import TooltipContent from 'components/TooltipContent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { evaluateRequestArray } from 'utils/handleErrors';
import { generateGraphColors } from 'utils/dashboardUtils';
import { COLORS } from 'constants/graphConfig';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';
import { getDurationAxisLabel } from 'pages/ContainerInsightsPage/utils';

import {
  ContainerMemoryUtilizationDimensionEnum,
  CONTAINER_MEMORY_UTILIZATION_DIMENSIONS,
} from '../../constants';
import { displayRender, onChangeDateFilter, onChangeDates } from '../../utils';
import { UtilizationByNodeAndTimeType } from '../../types';
import { getContainerMemoryUtilizationExcelData } from '../../utils/exportToExcel';

type ContainerMemoryUtilizationProps = {
  tableData: any[];
  allNodes: string[];
  selectedNode: string;
  setSelectedNode: (val: string) => void;
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  filteredContainerMemoryUtilizationData: UtilizationByNodeAndTimeType[];
  pdfView: boolean;
  containerMemoryUtilizationDateFilter: DateRangeFilter;
  setContainerMemoryUtilizationDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};

const ContainerMemoryUtilization = ({
  tableData,
  allNodes,
  selectedNode,
  setSelectedNode,
  requestStatus,
  isTableView,
  setIsTableView,
  filteredContainerMemoryUtilizationData,
  pdfView,
  containerMemoryUtilizationDateFilter,
  setContainerMemoryUtilizationDateFilter,
  excelFilters,
}: ContainerMemoryUtilizationProps) => {
  const { t } = useTranslation();

  const [chartColors, setChartColors] = useState<string[]>([]);

  useEffect(() => {
    setChartColors(
      generateGraphColors(CONTAINER_MEMORY_UTILIZATION_DIMENSIONS.length)
    );
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      fixed: true,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: t('containerInsight.usageMeteringLabels.dimension'),
      dataIndex: 'dimension',
      key: 'dimension',
      fixed: true,
      width: 150,
    },
    ...uniqBy(filteredContainerMemoryUtilizationData, 'time').map((item) => ({
      title: item.time,
      key: `${item.time}-utilization`,
      dataIndex: `${item.time}-utilization`,
      width: 100,
    })),
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  defaultValue={[
                    moment(containerMemoryUtilizationDateFilter.startDate),
                    moment(containerMemoryUtilizationDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(
                      dateString,
                      setContainerMemoryUtilizationDateFilter
                    )
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters flex flex-align-items-center flex-gap-16">
      <Cascader
        className="width-25"
        options={DateMenu}
        defaultValue={[containerMemoryUtilizationDateFilter.option]}
        displayRender={() =>
          displayRender(containerMemoryUtilizationDateFilter)
        }
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setContainerMemoryUtilizationDateFilter)
        }
      />
      <SelectDropdown
        rootClassName="width-25"
        value={selectedNode}
        options={allNodes.map((item) => ({
          value: item,
          label: item,
        }))}
        placeholder={t('containerInsight.usageMeteringLabels.chooseNode')}
        size={INPUT_SIZE.SMALL}
        loading={
          evaluateRequestArray(requestStatus) === REQUEST_STATUS.PROCESSING
        }
        onSelect={(value: string) => setSelectedNode(value)}
        designVersion2
      />
    </div>
  );

  const getColumnGraphData = () => {
    const barDimensions = CONTAINER_MEMORY_UTILIZATION_DIMENSIONS.filter(
      (item) =>
        ![
          ContainerMemoryUtilizationDimensionEnum.MEMORY_UTILIZATION_BY_CONTAINER,
        ].includes(item.dataLabel)
    ).map((item) => item.displayLabel);
    return filteredContainerMemoryUtilizationData
      .filter((item) => barDimensions.includes(item.dimension))
      .map((item) => {
        if (
          item.dimension ===
          CONTAINER_MEMORY_UTILIZATION_DIMENSIONS.find(
            (item) =>
              item.dataLabel ===
              ContainerMemoryUtilizationDimensionEnum.MEMORY_UTILIZATION_BY_CONTAINER
          )?.displayLabel
        ) {
          return {
            ...item,
            groupingField: 'column1',
          };
        }
        return {
          ...item,
          groupingField: 'column2',
        };
      });
  };

  const getLineGraphData = () => {
    const lineDimensions = CONTAINER_MEMORY_UTILIZATION_DIMENSIONS.filter(
      (item) =>
        [
          ContainerMemoryUtilizationDimensionEnum.MEMORY_UTILIZATION_BY_CONTAINER,
        ].includes(item.dataLabel)
    ).map((item) => item.displayLabel);
    return filteredContainerMemoryUtilizationData
      .filter((item) => lineDimensions.includes(item.dimension))
      .map((item) => ({ ...item, lineGroupingField: item.dimension }));
  };

  const getValueFormatter = useCallback((v: any) => `${v}%`, []);

  const MemoryUtilizationPercentDisplayLabel =
    CONTAINER_MEMORY_UTILIZATION_DIMENSIONS.find(
      (item) =>
        ContainerMemoryUtilizationDimensionEnum.MEMORY_UTILIZATION_BY_CONTAINER ===
        item.dataLabel
    )?.displayLabel;

  const getTooltipContentOverride = useCallback(
    (title: string, data: any[]) => {
      return (
        <TooltipContent
          title={title}
          data={data.map((obj) => ({
            ...obj,
            suffix:
              obj.name === MemoryUtilizationPercentDisplayLabel
                ? '%'
                : t('containerInsight.usageMeteringLabels.bytes'),
          }))}
        />
      );
    },
    []
  );

  const getComponent = () => {
    return isTableView ? (
      <Table
        pagination={false}
        dataSource={tableData}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer={true}
      />
    ) : (
      <ColumnLineChart
        plots={[
          {
            type: 'column',
            options: {
              data: getColumnGraphData(),
              xField: 'time',
              yField: 'utilization',
              isStack: true,
              isGroup: true,
              seriesField: 'dimension',
              groupField: 'groupingField',
              xAxis: {
                title: {
                  text: getDurationAxisLabel(
                    containerMemoryUtilizationDateFilter
                  ),
                },
              },
              yAxis: {
                title: {
                  text: t('containerInsight.usageMeteringLabels.memoryUsage'),
                },
                line: {
                  style: {
                    stroke: COLORS.fnGrey,
                    lineWidth: 1,
                    opacity: 0.7,
                  },
                },
              },
              color: chartColors,
              animation: !pdfView,
            },
          },
          {
            type: 'line',
            options: {
              data: getLineGraphData(),
              xField: 'time',
              seriesField: 'lineGroupingField',
              yField: 'utilization',
              xAxis: {
                title: {
                  text: getDurationAxisLabel(
                    containerMemoryUtilizationDateFilter
                  ),
                },
              },
              yAxis: {
                line: {
                  style: {
                    stroke: COLORS.fnGrey,
                    lineWidth: 1,
                    opacity: 0.7,
                  },
                },
                grid: null,
                position: 'right',
                title: {
                  text:
                    t(
                      'containerInsight.usageMeteringLabels.memoryUtilizationByContainer'
                    ) + ' (%)',
                },
              },
              meta: {
                value: {
                  formatter: getValueFormatter,
                },
              },
              color: chartColors.slice(-1),
              line: {
                style: {
                  lineDash: [5, 5],
                },
              },
              animation: !pdfView,
            },
          },
        ]}
        tooltipContentOverride={getTooltipContentOverride}
      />
    );
  };

  return (
    <div
      className="container-memory-utilization graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.containerMemoryUtilization')}
        graphName="container-memory-utilization"
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getContainerMemoryUtilizationExcelData(
          filteredContainerMemoryUtilizationData,
          tableData,
          [
            ...excelFilters,
            {
              heading: t('excelExportLabels.node'),
              value: selectedNode?.toString(),
            },
            {
              heading: t('excelExportLabels.startDate'),
              value: containerMemoryUtilizationDateFilter.startDate,
            },
            {
              heading: t('excelExportLabels.endDate'),
              value: containerMemoryUtilizationDateFilter.endDate,
            },
          ]
        )}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default ContainerMemoryUtilization;
