import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';

import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import PointerMap from 'components/PointerMap';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { CreditDistributionByLocationColorTypes } from 'types/dataTypes';
import { getRegionCoordinates } from 'utils/dashboardUtils';

import { getGCPCreditDistributionByLocationExcelData } from '../../utils/exportToExcel';

type CreditDistributionByLocationProps = {
  requestStatus: string;
  creditDistributionByLocationData: CreditDistributionByLocationColorTypes[];
  pdfView: boolean;
  creditByLocationStartMonth: string;
  creditByLocationEndMonth: string;
  onChangeCreditByLocationDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

export const CreditDistributionByLocation = ({
  requestStatus,
  creditDistributionByLocationData,
  pdfView,
  creditByLocationStartMonth,
  creditByLocationEndMonth,
  onChangeCreditByLocationDateRange,
}: CreditDistributionByLocationProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const CreditDistributionByLocationColumns = [
    {
      title: '#',
      dataIndex: 'color',
      key: 'color',
      render: (text: string) => (
        <div className="color-block" style={{ backgroundColor: text }} />
      ),
      width: 30,
    },
    {
      title: t('gcpCreditDistributionByLocation.location'),
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: t('gcpCreditDistributionByLocation.credit', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'credit',
      key: 'credit',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  const filters = (
    <div className="filters">
      <DatePicker
        defaultValue={[
          moment(creditByLocationStartMonth),
          moment(creditByLocationEndMonth),
        ]}
        onChange={onChangeCreditByLocationDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  const getComponent = () => (
    <div className="graph flex flex-gap-16">
      <div className="width-30 full-height">
        <Table
          pagination={false}
          dataSource={creditDistributionByLocationData.map((value, index) => ({
            ...value,
            key: index,
          }))}
          columns={CreditDistributionByLocationColumns}
          scroll={{ y: 300 }}
          designVersion2
        />
      </div>
      <div
        className={`graph-area with-table width-70 ${pdfView && 'pdf-wrapper'}`}
      >
        <PointerMap
          data={creditDistributionByLocationData.map((item) => ({
            ...item,
            coordinates: getRegionCoordinates(item.region),
          }))}
          valueLabel={t('gcpCreditDistributionByLocation.credit', {
            currencySymbol: currencySymbol,
          })}
          labelField="region"
          valueField="credit"
          colorField="color"
          prefixSymbol={currencySymbol}
        />
      </div>
    </div>
  );

  return (
    <div
      className="credit-distribution-by-location map-card graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.creditDistributionbyLocation')}
        graphName={'credit-distribution-by-location'}
        isDownloadable={!pdfView}
        isTableViewSwitch={false}
        showExpandIcon={!pdfView}
        filters={filters}
        isTableView={false}
        excelData={getGCPCreditDistributionByLocationExcelData(
          selectedDashboard?.connectorName ?? '',
          creditDistributionByLocationData
        )}
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={requestStatus}
      />
    </div>
  );
};
