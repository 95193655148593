import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectPurchase,
  setSelectedCommittedUseDiscountsTab,
} from 'redux/purchaseSlice';

import { CommittedUseDiscountsTabs } from './constants';
import ComputeEngine from './components/ComputeEngine';

const CommittedUseDiscounts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedCommittedUseDiscountsTab } = useSelector(selectPurchase);

  const PurchaseCommittedUseDiscountsTabsList = [
    {
      label: t('purchasePage.committedUseDiscountsLabels.computeEngine'),
      key: CommittedUseDiscountsTabs.COMPUTE_ENGINE,
      children: <ComputeEngine />,
    },
  ];

  return (
    <div>
      <Tabs
        rootClassName="purchase-committed-use-discounts"
        type="card"
        activeKey={selectedCommittedUseDiscountsTab}
        items={PurchaseCommittedUseDiscountsTabsList}
        onChange={function (activeKey) {
          dispatch(setSelectedCommittedUseDiscountsTab(activeKey));
        }}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default CommittedUseDiscounts;
