import i18n from 'i18n';

export const PodUsageCostExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.pod'),
    key: 'pod',
    dataKey: 'pod',
    width: 20,
  },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.memoryInBytesPerGiB'),
    key: 'usageAmount',
    dataKey: 'usageAmount',
    width: 20,
    alignment: 'right',
  },
];

export enum CLUSTER_UTILIZED {
  CPU = 'CPU',
  MEMORY = 'MEMORY',
}

export const CLUSTER_UTILIZED_OPTIONS = [
  { value: CLUSTER_UTILIZED.CPU, label: 'CPU' },
  { value: CLUSTER_UTILIZED.MEMORY, label: 'Memory' },
];

export enum CLUSTER_COUNT {
  CORES = 'CORES',
  PODS = 'PODS',
}

export const CLUSTER_COUNT_OPTIONS = [
  { value: CLUSTER_COUNT.PODS, label: 'Pods' },
  { value: CLUSTER_COUNT.CORES, label: 'Cores' },
];
