import { useSelector } from 'react-redux';
import TimeLineIcon from 'remixicon-react/TimeLineIcon';
import { selectTheme } from 'redux/themeSlice';
import MomentAntDatePicker from 'components/MomentAntDatePicker';
import { changeAlphaOfColor } from 'utils/dashboardUtils';

import './index.scss';

const DatePicker = ({ ...remainingProps }) => {
  const { theme } = useSelector(selectTheme);

  const popupStyle = {
    '--primary-color': theme.primaryColor,
    '--button-icon-color': theme.buttonIconColor,
    '--primary-bg-color': changeAlphaOfColor(theme.primaryColor, 10),
    '--text-color': theme.textColor,
  } as React.CSSProperties;

  return (
    <MomentAntDatePicker
      picker="time"
      popupStyle={popupStyle}
      suffixIcon={<TimeLineIcon className="suffix-icon" />}
      allowClear={false}
      {...remainingProps}
      className={`time-picker-wrapper cursor-pointer ${remainingProps.className}`}
    />
  );
};

export default DatePicker;
