import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CHART_TYPES } from 'constants/graphConfig';
import {
  customDashboard,
  setSelectedDimensions,
  setSelectedMetrics,
  setSelectedChartType,
} from 'redux/customDashboardSlice';
import { CustomDashboardChartTypes } from 'pages/CustomDashboardPage/constants';
import {
  getSelectedChart,
  getTableColumns,
  getTableDimensions,
  getTableMetrics,
} from 'pages/CustomDashboardPage/utils';

import TableOptions from '../TableOptions';
import ChartOptions from '../ChartOptions';

import './index.scss';

const GraphOptions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    customViewData,
    selectedChartType,
    selectedChartIndex,
    selectedChartRow,
  } = useSelector(customDashboard);

  useEffect(() => {
    dispatch(
      setSelectedChartType(
        getSelectedChart()?.chartType === CHART_TYPES.TABLE
          ? CustomDashboardChartTypes.TABLE
          : CustomDashboardChartTypes.CHART
      )
    );
    dispatch(
      setSelectedDimensions(
        getTableColumns()?.filter((item) =>
          getTableDimensions()?.includes(item.field)
        ) ?? []
      )
    );
    dispatch(
      setSelectedMetrics(
        getTableColumns()?.filter((item) =>
          getTableMetrics()
            ?.map((metric) => metric.label)
            .includes(item.field)
        ) ?? []
      )
    );
  }, [selectedChartIndex, selectedChartRow]);

  const GetGraphOptions =
    selectedChartType === CustomDashboardChartTypes.TABLE ? (
      <TableOptions />
    ) : (
      <ChartOptions />
    );

  return (
    <div className="graph-options-container flex flex-fit new-styled-scroll">
      {customViewData.layoutDesigns.length === 0 ? (
        <div className="font-caption-bold flex flex-center flex-fit">
          {t('customDashboard.optionsLabels.chooseLayout')}
        </div>
      ) : (
        GetGraphOptions
      )}
    </div>
  );
};

export default GraphOptions;
