import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectReport } from 'redux/reportSlice';

import SortListItem from './components/SortListItem';

import './index.scss';

const SortList = () => {
  const { t } = useTranslation();
  const { reportOptions } = useSelector(selectReport);

  return (
    <div className="sort-list">
      {reportOptions.sort.length === 0 ? (
        <div className="empty-sort-message font-subHeader-small">
          {t('reports.clickToAddSort')}
        </div>
      ) : (
        <div className="flex flex-column flex-gap-8">
          {reportOptions.sort.map((sort, index) => (
            <SortListItem sort={sort} index={index} key={sort.label} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SortList;
