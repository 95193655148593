import { useCallback } from 'react';
import { Column } from '@ant-design/plots';
import TooltipContent from 'components/TooltipContent';
import { COLORS, GRAPH_OPTIONS } from 'constants/graphConfig';
import { generateGraphColors } from 'utils/dashboardUtils';
import {
  graphSliderValueFormatter,
  graphTooltipFormatter,
} from 'utils/graphUtils';

type ColumnChartProps = {
  data: any[];
  xField: string;
  yField: string;
  xTitle?: string;
  yTitle?: string;
  isStack?: boolean;
  isGroup?: boolean;
  groupingField?: string;
  columnGroupField?: string;
  colorField?: string;
  columnColorOverride?: string[];
  showLabels?: boolean;
  showAllLegend?: boolean;
  showLegend?: boolean;
  disableAnimation?: boolean;
  meta?: any;
  additionalClassNames?: string;
  tooltipOverride?: any;
  prefixSymbol?: string;
  suffixSymbol?: string;
  onClickColumn?: Function;
  showSlider?: boolean;
  sliderCountLimit?: number;
  onSliderValueChange?: (x: number, y: number) => void;
  sliderValues?: { x: number; y: number };
  additionalTooltipContent?: React.ReactNode;
  graphKey?: string;
  isColumnClickable?: boolean;
};

const ColumnChart = ({
  data,
  xField,
  yField,
  xTitle,
  yTitle,
  isStack,
  isGroup,
  groupingField,
  columnGroupField,
  colorField,
  columnColorOverride,
  showLabels = true,
  showAllLegend = false,
  showLegend = true,
  disableAnimation = false,
  meta,
  additionalClassNames = '',
  tooltipOverride,
  prefixSymbol,
  suffixSymbol,
  onClickColumn,
  showSlider,
  sliderCountLimit = GRAPH_OPTIONS.sliderCountLimit,
  onSliderValueChange,
  sliderValues,
  additionalTooltipContent,
  graphKey,
  isColumnClickable = false,
}: ColumnChartProps) => {
  const getTooltipFormatter = useCallback(
    (value: any) => graphTooltipFormatter(value, groupingField ?? '', yField),
    [groupingField]
  );

  const getTooltipCustomContent = useCallback(
    (title: string, data: any[]) => (
      <TooltipContent
        title={title}
        data={data}
        prefixSymbol={prefixSymbol}
        postfixSymbol={suffixSymbol}
        additionalContent={additionalTooltipContent}
      />
    ),
    [suffixSymbol]
  );

  const getSliderTextFormatter = useCallback(
    (_value: any, _data: any, index: number) => {
      return graphSliderValueFormatter(index, data.length);
    },
    []
  );

  const config: any = {
    data,
    isStack: isStack,
    isGroup: isGroup,
    xField,
    yField,
    xAxis: {
      title: {
        text: xTitle ?? null,
      },
      label: !showLabels
        ? showLabels
        : {
            autoEllipsis: true,
          },
    },
    yAxis: {
      title: {
        text: yTitle ?? null,
      },
      line: {
        style: {
          stroke: COLORS.fnGrey,
          lineWidth: 1,
          opacity: 0.7,
        },
      },
    },
    legend: showLegend
      ? {
          position: 'bottom',
          flipPage: !showAllLegend,
          pageNavigator: {
            marker: {
              style: {
                fill: COLORS.primaryButton,
                size: 8,
              },
            },
            text: {
              style: {
                fill: COLORS.secondaryBlack,
                fontSize: 12,
              },
            },
          },
        }
      : false,
    seriesField: groupingField,
    groupField: columnGroupField,
    maxColumnWidth: 24,
    columnStyle: {
      cursor: isColumnClickable ? 'pointer' : 'default',
    },
    colorField: colorField,
    color: columnColorOverride ?? generateGraphColors(data.length),
    tooltip: tooltipOverride
      ? { ...tooltipOverride, customContent: getTooltipCustomContent }
      : {
          formatter: getTooltipFormatter,
          customContent: getTooltipCustomContent,
        },
    animation: !disableAnimation,
    meta: meta,
    onReady: useCallback((plot: any) => {
      onClickColumn && plot.on('element:click', onClickColumn);
      onSliderValueChange &&
        plot.on('slider:valuechanged', (evt: any) =>
          onSliderValueChange(
            evt.gEvent.originalEvent.value[0],
            evt.gEvent.originalEvent.value[1]
          )
        );
    }, []),
    slider:
      showSlider && data.length > sliderCountLimit
        ? {
            start: sliderValues ? sliderValues.x : 0,
            end: sliderValues ? sliderValues.y : sliderCountLimit / data.length,
            backgroundStyle: {
              fill: COLORS.fnGrey13,
              fillOpacity: 0.9,
              radius: 6,
            },
            foregroundStyle: {
              fill: COLORS.colorScrollBar,
              fillOpacity: 0.5,
            },
            handlerStyle: {
              fill: COLORS.colorScrollBar,
              highLightFill: COLORS.colorScrollBar,
              stroke: COLORS.white,
              height: 18,
              width: 12,
            },
            textStyle: {
              fill: COLORS.secondaryBlack,
              opacity: 0.9,
            },
            padding: [0, 10, 0, 0],
            formatter: getSliderTextFormatter,
          }
        : false,
  };

  return <Column className={additionalClassNames} {...config} key={graphKey} />;
};

export default ColumnChart;
