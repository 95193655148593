import { useSelector } from 'react-redux';

import { selectReport } from 'redux/reportSlice';
import { ReportsViewType } from 'pages/CreateReportPage/constants';

import DataSourcesOptions from '../DataSourcesOptions';
import ReportDashboard from '../ReportDashboard';

const ReportDashboardCanvas = () => {
  const { reportView } = useSelector(selectReport);

  return (
    <div className="report-dashboard-canvas flex flex-fit">
      <ReportDashboard />
      {reportView === ReportsViewType.EDIT && <DataSourcesOptions />}
    </div>
  );
};

export default ReportDashboardCanvas;
