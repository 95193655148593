import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CostMonitorComponents,
  DEFAULT_COST_MONITOR_FORM_VALUES,
} from 'pages/CostMonitorPage/constants';
import { CostMonitorListType } from 'pages/CostMonitorPage/types';
import { RootState } from './store';

export interface CostMonitorState {
  costMonitorComponent: string;
  costMonitorFormData: CostMonitorListType;
  isEditForm: boolean;
  isTryAgain: boolean;
  existingAttachedSubscriptions: string[];
}

const initialState: CostMonitorState = {
  costMonitorComponent: CostMonitorComponents.PROVIDER_LIST,
  costMonitorFormData: DEFAULT_COST_MONITOR_FORM_VALUES,
  isEditForm: false,
  isTryAgain: false,
  existingAttachedSubscriptions: [],
};

export const costMonitorSlice = createSlice({
  name: 'costMonitor',
  initialState,
  reducers: {
    setCostMonitorComponent: (state, action: PayloadAction<string>) => {
      state.costMonitorComponent = action.payload;
    },
    setCostMonitorFormData: (
      state,
      action: PayloadAction<CostMonitorListType>
    ) => {
      state.costMonitorFormData = action.payload;
    },
    setIsEditForm: (state, action: PayloadAction<boolean>) => {
      state.isEditForm = action.payload;
    },
    setIsTryAgain: (state, action: PayloadAction<boolean>) => {
      state.isTryAgain = action.payload;
    },
    setExistingAttachedSubscriptions: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.existingAttachedSubscriptions = action.payload;
    },
  },
});

export const costMonitor = (state: RootState) => state.costMonitor;
export const {
  setCostMonitorComponent,
  setCostMonitorFormData,
  setIsEditForm,
  setIsTryAgain,
  setExistingAttachedSubscriptions,
} = costMonitorSlice.actions;
export default costMonitorSlice.reducer;
