import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/Checkbox';
import { CustomViewDataType } from 'types/dashboard';
import { DashboardViewType } from 'types/navigationMenu';

import './index.scss';

type SelectDashboardViewsProps = {
  prebuiltDashboardViews: DashboardViewType[];
  customDashboardViews: CustomViewDataType[];
  selectedDashboardViews: string[];
  setSelectedDashboardViews: (val: string[]) => void;
};

const SelectDashboardViews = ({
  prebuiltDashboardViews,
  customDashboardViews,
  selectedDashboardViews,
  setSelectedDashboardViews,
}: SelectDashboardViewsProps) => {
  const { t } = useTranslation();

  const [allOptionCheck, setAllOptionCheck] = useState(false);

  /**
   * @function handleCheckboxClick
   * @description Callback function for dashboard view selection
   * @param checked boolean value to indicate dashboard view selection or deselection
   * @param viewId view id of selected view.
   */
  const handleCheckboxClick = (checked: boolean, viewId: string) => {
    if (checked) {
      setSelectedDashboardViews([...selectedDashboardViews, viewId]);
      return;
    }
    setSelectedDashboardViews([
      ...selectedDashboardViews.filter((item) => item !== viewId),
    ]);
  };

  return (
    <div className="view-list card rounded-styled-scroll">
      <div className="list-container flex flex-column flex-gap-16">
        <div className="flex flex-gap-8">
          <Checkbox
            checked={allOptionCheck}
            onChange={(e) => {
              setAllOptionCheck(e.target.checked);
              if (e.target.checked) {
                setSelectedDashboardViews([
                  ...prebuiltDashboardViews.map((view) => view.id),
                  ...customDashboardViews.map(
                    (view) => view.id ?? view.viewName
                  ),
                ]);
                return;
              }
              setSelectedDashboardViews([]);
            }}
          />
          <span>{t('checkBoxDropdown.all')}</span>
        </div>
        {prebuiltDashboardViews.map((view) => (
          <div className="flex flex-gap-8" key={view.id}>
            <Checkbox
              checked={selectedDashboardViews.some((id) => id === view.id)}
              onChange={(e) => handleCheckboxClick(e.target.checked, view.id)}
            />
            <span>{t(`dashNav.${view.title}`)}</span>
          </div>
        ))}
        {customDashboardViews.map((view) => (
          <div className="flex flex-gap-8" key={view.id}>
            <Checkbox
              checked={selectedDashboardViews.some((id) => id === view.id)}
              onChange={(e) =>
                handleCheckboxClick(e.target.checked, view.id ?? view.viewName)
              }
            />
            <span>{view.viewName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectDashboardViews;
