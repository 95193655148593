import { useEffect, useState } from 'react';
import { message, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

import { CsvFileIcon } from 'assets/icons';
import Button from 'components/Button';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  setTagsAndLabelComponent,
  tagsAndLabels,
} from 'redux/tagsAndLabelsSlice';
import {
  AzureSubscriptionTagsType,
  ProjectsTagsType,
} from 'pages/TagsAndLabelsPage/types';
import { TAGS_AND_LABELS_COMPONENTS } from 'pages/TagsAndLabelsPage/constants';
import {
  applyAzureSchema,
  applyGcpSchema,
  validateAzureParsedSchema,
  validateGcpParsedSchema,
} from 'pages/TagsAndLabelsPage/services';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { providerList } from 'redux/providerSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import { PROVIDER } from 'constants/cloudProviders';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import Header from '../Header';

import './index.scss';

const { Dragger } = Upload;

const ApplyTagsAndLabels = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [gcpParsedTags, setGcpParsedTags] = useState<ProjectsTagsType[]>([]);
  const [azureParsedTags, setAzureParsedTags] =
    useState<AzureSubscriptionTagsType>();
  const [fileValidationRequestStatus, setFileValidationRequestStatus] =
    useState('');
  const [fileValidationErrorMessage, setFileValidationErrorMessage] =
    useState('');
  const [applySchemaRequestStatus, setApplySchemaRequestStatus] = useState('');
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const [applySchemaEnabled, setApplySchemaEnabled] = useState(false);

  const { selectedTagConnection, selectedBillingAccount } =
    useSelector(tagsAndLabels);
  const { selectedProvider } = useSelector(providerList);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function parsedTagsToDto
   * @description Function to parse the excel json data to DTO structure
   * @param tags Tags parsed from the DTO
   */
  const parsedTagsToDto = (tags: any[]) => {
    if (selectedProvider === PROVIDER.GCP) {
      let modifiedTags: ProjectsTagsType[] = [];
      tags?.forEach((eachTag) => {
        modifiedTags.push({
          projectId: eachTag.projectId,
          labelValues: Object.entries<string | null>(eachTag)
            .filter(
              (eachEntry) =>
                eachEntry[0] !== 'projectId' && eachEntry[0] !== '__EMPTY'
            )
            .map((eachEntry) => ({
              key: eachEntry[0],
              value: eachEntry[1],
            })),
        });
      });

      setGcpParsedTags(modifiedTags);
    } else if (selectedProvider === PROVIDER.AZURE) {
      const modifiedTags: AzureSubscriptionTagsType = {
        subscriptionId: tags.at(0)?.subscriptionId,
        labelValues: Object.entries<string | null>(tags.at(0))
          .filter(
            (eachEntry) =>
              eachEntry[0] !== 'subscriptionId' && eachEntry[0] !== '__EMPTY'
          )
          .map((eachEntry) => ({
            key: eachEntry[0],
            value: eachEntry[1],
          })),
      };

      setAzureParsedTags(modifiedTags);
    }
  };

  /**
   * @function onRemoveFile
   * @description Function to handle remove file
   */
  const onRemoveFile = () => {
    setIsFileUploaded(false);
    setFileName('');
    setApplySchemaEnabled(false);
    setFileValidationRequestStatus('');
  };

  /**
   * @function onHandleChange
   * @description Function to handle change events of uploading file.
   * @param info : info will give the values of uploaded file
   */
  const onHandleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setFileName(info.file.name);
    } else if (info.file.status === 'error') {
      message.error(t('fileUploadFailedMessage'));
    }
  };

  /**
   * @function handleSelectFile
   * @description Function to handle the file upload.
   * @param options : options give the details of file
   */
  const handleSelectFile = async (options: any) => {
    const { onSuccess, file } = options;
    await onSuccess('ok');
    setIsFileUploaded(true);
    const reader = new FileReader();

    reader.onload = (evt: any) => {
      const data = evt.target.result;
      const wb = XLSX.read(data, { type: 'binary' });
      const sheetName = wb.SheetNames[0];
      const ws = wb.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(ws, {
        defval: null,
        range: 2,
      });
      parsedTagsToDto(json);
    };
    reader.readAsBinaryString(file);
  };

  /**
   * @function onSuccessValidateSchema
   * @description Function to handle if schema is validated successfully
   */
  const onSuccessValidateSchema = () => {
    setFileValidationRequestStatus(REQUEST_STATUS.SUCCESS);
    message.success(t('tagsOrLabels.successValidatingSchema'));
    setApplySchemaEnabled(true);
  };

  /**
   * @function onErrorValidateSchema
   * @description Function to handle if schema is not validated
   */
  const onErrorValidateSchema = (errMessage: string) => {
    setFileValidationErrorMessage(errMessage);
    setFileValidationRequestStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function onHandleSuccessValidation
   * @description Function to handle success if schema is validated
   */
  const onHandleSuccessValidation = (res: any) => {
    if (res?.status === 200) {
      onSuccessValidateSchema();
      return;
    }
    onErrorValidateSchema(res?.data?.message);
  };

  /**
   * @function handleValidateSchema
   * @description Function to handle the validation of the file.
   */
  const handleValidateSchema = () => {
    setFileValidationRequestStatus(REQUEST_STATUS.PROCESSING);
    if (selectedProvider === PROVIDER.GCP) {
      const body = {
        connectorId: selectedTagConnection?.connectorId,
        billingAccountId: selectedBillingAccount,
        projectLabelsDtoList: gcpParsedTags,
      };
      validateGcpParsedSchema(body)
        .then((res: any) => {
          onHandleSuccessValidation(res);
        })
        .catch((e) => {
          onErrorValidateSchema(e.response.data.message);
        });
    } else if (selectedProvider === PROVIDER.AZURE) {
      const body = {
        connectorId: selectedTagConnection?.connectorId,
        subscriptionId: azureParsedTags?.subscriptionId,
        labelValues: azureParsedTags?.labelValues,
      };
      validateAzureParsedSchema(body)
        .then((res: any) => {
          onHandleSuccessValidation(res);
        })
        .catch((e) => {
          onErrorValidateSchema(e.response.data.message);
        });
    }
  };

  /**
   * @function onSuccessApplySchema
   * @description Function to handle if schema is validated successfully
   */
  const onSuccessApplySchema = () => {
    setApplySchemaRequestStatus(REQUEST_STATUS.SUCCESS);
    message.success(t('tagsOrLabels.successApplyingSchema'));
    setApplySchemaEnabled(true);
    dispatch(setTagsAndLabelComponent(TAGS_AND_LABELS_COMPONENTS.SUCCESS_PAGE));
  };

  /**
   * @function onErrorApplySchema
   * @description Function to handle if schema is not validated
   */
  const onErrorApplySchema = (errMessage: string) => {
    dispatch(
      setErrorMessage(errMessage || t('tagsOrLabels.errorApplyingSchema'))
    );
    setApplySchemaRequestStatus(REQUEST_STATUS.ERROR);
    dispatch(setTagsAndLabelComponent(TAGS_AND_LABELS_COMPONENTS.ERROR_PAGE));
  };

  /**
   * @function onSuccessApplySchema
   * @description Function to handle if schema is validated
   */
  const onHandleResponse = (res: any) => {
    if (res?.status === 200) {
      onSuccessApplySchema();
      return;
    }
    onErrorApplySchema(res?.data?.message);
  };

  /**
   * @function handleApplySchema
   * @description Function to handle submit button.
   */
  const handleApplySchema = () => {
    setApplySchemaRequestStatus(REQUEST_STATUS.PROCESSING);
    if (selectedProvider === PROVIDER.GCP) {
      const body = {
        connectorId: selectedTagConnection?.connectorId,
        billingAccountId: selectedBillingAccount,
        projectLabelsDtoList: gcpParsedTags,
      };
      applyGcpSchema(body)
        .then((res: any) => {
          onHandleResponse(res);
        })
        .catch((e) => {
          onErrorApplySchema(e.response.data.message);
        });
    } else if (selectedProvider === PROVIDER.AZURE) {
      const body = {
        connectorId: selectedTagConnection?.connectorId,
        subscriptionId: azureParsedTags?.subscriptionId,
        labelValues: azureParsedTags?.labelValues,
      };
      applyAzureSchema(body)
        .then((res: any) => {
          onHandleResponse(res);
        })
        .catch((e) => {
          onErrorApplySchema(e.response.data.message);
        });
    }
  };

  return (
    <div>
      <Header
        disableSubmitButton={!applySchemaEnabled}
        onSubmit={handleApplySchema}
        isSubmitting={applySchemaRequestStatus === REQUEST_STATUS.PROCESSING}
        buttonTitle={t('tagsOrLabels.applySchema')}
      />
      <div className="page-content flex flex-column flex-center">
        <div className="upload-csv-container card flex flex-column flex-gap-24">
          <div className="flex flex-column">
            <span className="form-header">
              {t('tagsOrLabels.uploadAttachExcelFile')}
            </span>
            <span className="font-overline">
              {t('tagsOrLabels.uploadAttachExcelFileSubtitle', {
                connectorName: selectedTagConnection?.name,
              })}
            </span>
          </div>
          <div className="flex flex-column">
            <span className="font-caption-bold">
              {t('tagsOrLabels.xlsxFile')}
            </span>
            <Dragger
              customRequest={handleSelectFile}
              accept={'.xlsx'}
              className="dragger"
              maxCount={1}
              multiple={false}
              onChange={onHandleChange}
              showUploadList={false}
              onRemove={onRemoveFile}
              openFileDialogOnClick={!isFileUploaded}
            >
              <div className="dragger-content flex flex-column flex-center flex-gap-4">
                <CsvFileIcon
                  width={25}
                  height={30}
                  className={isFileUploaded ? 'uploaded-icon' : 'normal-icon'}
                />
                {isFileUploaded ? (
                  <div className="flex flex-gap-4">
                    <span className="font-overline">{fileName}</span>
                    <Icon
                      iconName={ICONS.CLOSE_FILL}
                      size={ICONS_SIZE.SM}
                      className="small-close-icon flex flex-center"
                      onClick={onRemoveFile}
                    />
                  </div>
                ) : (
                  <>
                    <span className="font-overline">
                      {t('tagsOrLabels.selectExcelFile')}
                    </span>
                    <span className="font-subHeader-small">
                      {t('tagsOrLabels.orDragAndDrop')}
                    </span>
                  </>
                )}
              </div>
            </Dragger>
          </div>
          {fileValidationRequestStatus === REQUEST_STATUS.ERROR && (
            <div className="error-message flex flex-justify-content-center font-caption">
              {fileValidationErrorMessage ||
                t('tagsOrLabels.errorValidatingSchema')}
            </div>
          )}
          <div className="flex flex-end">
            <Button
              title={t('tagsOrLabels.validateSchema')}
              onClick={handleValidateSchema}
              loading={
                fileValidationRequestStatus === REQUEST_STATUS.PROCESSING
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyTagsAndLabels;
