import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Cascader from 'components/Cascader';
import GraphHeader from 'components/GraphHeader';
import PieDonutChart from 'components/PieChartAnt';
import SelectDropdown from 'components/Select';
import { REQUEST_STATUS } from 'constants/requestBody';
import { INPUT_SIZE } from 'constants/appearance';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { DATE_FORMAT, HYPHEN_DATE_FORMAT } from 'utils/date';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';

import {
  POD_NETWORK_UTILIZATION_DIMENSIONS,
  PodNetworkUtilizationDimensionEnum,
} from '../../constants';
import { displayRender, onChangeDateFilter, onChangeDates } from '../../utils';
import { UtilizationByDimensionAndNodeType } from '../../types';
import { getPodNetworkUtilizationExcelData } from '../../utils/exportToExcel';

type PodNetworkUtilizationProps = {
  podNetworkUtilizationData: UtilizationByDimensionAndNodeType[];
  allNodes: string[];
  podNetworkUtilizationSelectedNode: string;
  setPodNetworkUtilizationSelectedNode: (val: string) => void;
  requestStatus: string[];
  podNetworkUtilizationTableView: boolean;
  setPodNetworkUtilizationTableView: (value: boolean) => void;
  pdfView: boolean;
  podNetworkUtilizationDateFilter: DateRangeFilter;
  setPodNetworkUtilizationDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};

const PodNetworkUtilization = ({
  podNetworkUtilizationData,
  allNodes,
  podNetworkUtilizationSelectedNode,
  setPodNetworkUtilizationSelectedNode,
  requestStatus,
  podNetworkUtilizationTableView,
  setPodNetworkUtilizationTableView,
  pdfView,
  podNetworkUtilizationDateFilter,
  setPodNetworkUtilizationDateFilter,
  excelFilters,
}: PodNetworkUtilizationProps) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('containerInsight.usageMeteringLabels.dimension'),
      dataIndex: 'dimension',
      key: 'dimension',
    },
    {
      title: t('containerInsight.usageMeteringLabels.bytes'),
      dataIndex: 'utilization',
      key: 'utilization',
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text ?? 0),
    },
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  format={DATE_FORMAT}
                  defaultValue={[
                    moment(podNetworkUtilizationDateFilter.startDate),
                    moment(podNetworkUtilizationDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(
                      [
                        moment(dateString[0], DATE_FORMAT).format(
                          HYPHEN_DATE_FORMAT
                        ),
                        moment(dateString[1], DATE_FORMAT).format(
                          HYPHEN_DATE_FORMAT
                        ),
                      ],
                      setPodNetworkUtilizationDateFilter
                    )
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters flex flex-row flex-align-items-center flex-gap-16">
      <Cascader
        className="width-40"
        options={DateMenu}
        defaultValue={[podNetworkUtilizationDateFilter.option]}
        displayRender={() => displayRender(podNetworkUtilizationDateFilter)}
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setPodNetworkUtilizationDateFilter)
        }
      />
      <SelectDropdown
        rootClassName="width-40"
        value={podNetworkUtilizationSelectedNode}
        options={allNodes.map((item) => ({
          label: item,
          value: item,
        }))}
        onSelect={(value: any) => {
          setPodNetworkUtilizationSelectedNode(value);
        }}
        placeholder={t('containerInsight.usageMeteringLabels.chooseNode')}
        size={INPUT_SIZE.SMALL}
        designVersion2
      />
    </div>
  );

  const totalAmountOfNetworkTrafficLabel =
    POD_NETWORK_UTILIZATION_DIMENSIONS.find(
      (dimension) =>
        dimension.dataLabel ===
        PodNetworkUtilizationDimensionEnum.TOTAL_AMOUNT_OF_NETWORK_TRAFFIC
    )?.displayLabel;

  const getStatisticContent = useCallback(
    (value: any) => {
      let valueToDisplay = 0;
      if (value === undefined)
        valueToDisplay =
          podNetworkUtilizationData.find(
            (data) => data.dimension === totalAmountOfNetworkTrafficLabel
          )?.utilization ?? 0;
      else valueToDisplay = value?.utilization ?? 0;

      return (
        numberCommaSeparator(valueToDisplay) +
        ' ' +
        t('containerInsight.usageMeteringLabels.bytes')
      );
    },
    [podNetworkUtilizationData]
  );

  const getComponent = () =>
    podNetworkUtilizationTableView ? (
      <Table
        pagination={false}
        loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
        dataSource={podNetworkUtilizationData.map((data, index) => ({
          ...data,
          key: index,
        }))}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer={true}
      />
    ) : (
      <PieDonutChart
        data={podNetworkUtilizationData.filter(
          (data) => data.dimension !== totalAmountOfNetworkTrafficLabel
        )}
        angleField="utilization"
        colorField="dimension"
        isDonut
        disableAnimation={pdfView}
        suffixSymbol={t('containerInsight.usageMeteringLabels.bytes')}
        statisticTitle={totalAmountOfNetworkTrafficLabel}
        statisticValueFormatter={getStatisticContent}
      />
    );

  return (
    <div
      className="pod-network-utilization graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.podNetworkUtilization')}
        graphName="pod-network-utilization"
        ignorePaddingBottom={true}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={podNetworkUtilizationTableView}
        setIsTableView={setPodNetworkUtilizationTableView}
        filters={filters}
        excelData={getPodNetworkUtilizationExcelData(
          podNetworkUtilizationData,
          [
            ...excelFilters,
            {
              heading: t('excelExportLabels.startDate'),
              value: podNetworkUtilizationDateFilter.startDate,
            },
            {
              heading: t('excelExportLabels.endDate'),
              value: podNetworkUtilizationDateFilter.endDate,
            },
            {
              heading: t('excelExportLabels.node'),
              value: podNetworkUtilizationSelectedNode,
            },
          ]
        )}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default PodNetworkUtilization;
