import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';

import {
  selectPurchase,
  setAwsEc2RisCartData,
  setAwsPlatforms,
  setAwsRegions,
} from 'redux/purchaseSlice';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import {
  TERMS_LIST,
  OFFERING_CLASS_OPTIONS,
  TENANCY_OPTIONS,
} from 'pages/PurchasePage/constants';
import { PurchaseAwsEc2RisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import { AWS_EC2_RI_DEFAULT_VALUES } from 'pages/PurchasePage/components/ReservedInstances/constants';
import {
  getAwsEc2RiOfferingClasses,
  getAwsEc2RiOfferingDetails,
  getAwsEc2RiPaymentOptions,
  getAwsEc2RiTenancy,
  getAwsRiAvailabilityZones,
  getAwsRiInstanceTypes,
  getAwsRiPlatforms,
} from 'pages/PurchasePage/components/ReservedInstances/services';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import { isEmptyField, isNumber, validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { convertYearToSeconds } from 'pages/PurchasePage/components/ReservedInstances/utils';
import { fetchAwsRegions } from 'pages/PurchasePage/services';

import { getEc2SubTotalDue } from '../../utils';

import './index.scss';

type BuyEc2RiFormModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  editData?: PurchaseAwsEc2RisType;
  indexPosition?: number;
};

const BuyEc2RiFormModal = ({
  show,
  setShow,
  editData,
  indexPosition,
}: BuyEc2RiFormModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, awsEc2RiCartData, awsPlatforms, awsRegions } =
    useSelector(selectPurchase);

  const [ec2RiFormData, setEc2RiFormData] = useState<PurchaseAwsEc2RisType>(
    AWS_EC2_RI_DEFAULT_VALUES
  );
  const [availabilityZones, setAvailabilityZones] = useState<string[]>();
  const [tenancies, setTenancies] = useState<string[]>();
  const [offeringClasses, setOfferingClasses] = useState<string[]>();
  const [instanceTypes, setInstanceTypes] = useState<string[]>();
  const [paymentOptions, setPaymentOptions] = useState<string[]>();
  const [fetchPlatformsReqStatus, setFetchPlatformsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchRegionReqStatus, setFetchRegionReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [
    fetchAvailabilityZonesRequestStatus,
    setFetchAvailabilityZonesRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [fetchTenancyRequestStatus, setFetchTenancyRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [
    fetchOfferingClassesRequestStatus,
    setFetchOfferingClassesRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [fetchInstanceTypesRequestStatus, setFetchInstanceTypesRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [
    fetchPaymentOptionsRequestStatus,
    setFetchPaymentOptionsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [platformValidation, setPlatformValidation] = useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [availabilityZoneValidation, setAvailabilityZoneValidation] =
    useState('');
  const [instanceTypeValidation, setInstanceTypeValidation] = useState('');
  const [paymentOptionValidation, setPaymentOptionValidation] = useState('');
  const [quantityValidation, setQuantityValidation] = useState('');
  const [offeringDetailsRequestStatus, setOfferingDetailsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [offeringDetailsValidation, setOfferingDetailsValidation] =
    useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (editData) {
      setEc2RiFormData(editData);
    }

    if (awsPlatforms.length === 0) {
      fetchAwsPlatforms();
    }

    if (awsRegions.length === 0) {
      getAwsRegions();
    }
  }, []);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setAvailabilityZones(undefined);
    if (validateRegion(ec2RiFormData.region, false)) {
      fetchAvailabilityZones(source);
    }

    return () => {
      source.cancel();
    };
  }, [ec2RiFormData.region]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setTenancies(undefined);
    if (
      validatePlatform(ec2RiFormData.platform, false) &&
      validateAvailabilityZone(ec2RiFormData.availabilityZone, false)
    ) {
      fetchTenancy(source);
    }

    return () => {
      source.cancel();
    };
  }, [ec2RiFormData.platform, ec2RiFormData.availabilityZone]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setOfferingClasses(undefined);
    if (tenancies && ec2RiFormData.tenancy) {
      fetchOfferingClasses(source);
    }

    return () => {
      source.cancel();
    };
  }, [ec2RiFormData.tenancy, tenancies]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setInstanceTypes(undefined);
    if (offeringClasses && ec2RiFormData.offeringClass) {
      fetchInstanceTypes(source);
    }

    return () => {
      source.cancel();
    };
  }, [ec2RiFormData.offeringClass, offeringClasses]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setPaymentOptions(undefined);
    if (
      instanceTypes &&
      validateInstanceType(ec2RiFormData.instanceType, false) &&
      ec2RiFormData.term
    ) {
      fetchPaymentOptions(source);
    }

    return () => {
      source.cancel();
    };
  }, [instanceTypes, ec2RiFormData.instanceType, ec2RiFormData.term]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    if (isDataLoaded) {
      setEc2RiFormData({
        ...ec2RiFormData,
        averageMonthlyRecurringCost: 0,
        oneTimePayment: 0,
        offeringId: '',
      });
      setOfferingDetailsValidation('');
    } else {
      setIsDataLoaded(true);
    }

    if (validateAllFields(false)) {
      fetchOfferingDetails(source);
    }

    return () => {
      source.cancel();
    };
  }, [
    ec2RiFormData.platform,
    ec2RiFormData.availabilityZone,
    ec2RiFormData.instanceType,
    ec2RiFormData.offeringClass,
    ec2RiFormData.tenancy,
    ec2RiFormData.term,
    ec2RiFormData.paymentOption,
    ec2RiFormData.quantity,
    availabilityZones,
    tenancies,
    offeringClasses,
    instanceTypes,
    paymentOptions,
  ]);

  /**
   * @function fetchAwsPlatforms
   * @description Function to fetch AWS platforms data
   */
  const fetchAwsPlatforms = () => {
    setFetchPlatformsReqStatus(REQUEST_STATUS.PROCESSING);

    getAwsRiPlatforms()
      .then((res: any) => {
        dispatch(setAwsPlatforms(res.data.responseData || []));
        setFetchPlatformsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchPlatformsReqStatus);
      });
  };

  /**
   * @function fetchRegions
   * @description Function to fetch regions data
   */
  const getAwsRegions = () => {
    setFetchRegionReqStatus(REQUEST_STATUS.PROCESSING);

    fetchAwsRegions({ connectorId: selectedConnection?.connectorId })
      .then((res: any) => {
        dispatch(setAwsRegions(res.data.responseData));
        setFetchRegionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchRegionReqStatus);
      });
  };

  /**
   * @function fetchAvailabilityZones
   * @description Function to fetch the Availability Zones
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchAvailabilityZones = (cancelTokenSource: CancelTokenSource) => {
    setFetchAvailabilityZonesRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedConnection?.connectorId,
      region: ec2RiFormData.region,
    };

    getAwsRiAvailabilityZones(params, cancelTokenSource.token)
      .then((res: any) => {
        setAvailabilityZones(res.data.responseData || []);
        setFetchAvailabilityZonesRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchAvailabilityZonesRequestStatus);
      });
  };

  /**
   * @function fetchTenancy
   * @description Function to fetch the tenancy
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchTenancy = (cancelTokenSource: CancelTokenSource) => {
    setFetchTenancyRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      platform: ec2RiFormData.platform,
      region: ec2RiFormData.region,
      availabilityZone: ec2RiFormData.availabilityZone,
    };

    getAwsEc2RiTenancy(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        const tenancy = res.data.responseData || [];
        setTenancies(tenancy);
        if (tenancy.length > 0) {
          setEc2RiFormData({ ...ec2RiFormData, tenancy: tenancy[0] });
        }
        setFetchTenancyRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchTenancyRequestStatus);
      });
  };

  /**
   * @function fetchOfferingClasses
   * @description Function to fetch the offering classes
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchOfferingClasses = (cancelTokenSource: CancelTokenSource) => {
    setFetchOfferingClassesRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      platform: ec2RiFormData.platform,
      region: ec2RiFormData.region,
      availabilityZone: ec2RiFormData.availabilityZone,
      tenancy: ec2RiFormData.tenancy,
    };

    getAwsEc2RiOfferingClasses(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        const classes = res.data.responseData || [];
        setOfferingClasses(classes);
        if (classes.length > 0) {
          setEc2RiFormData({ ...ec2RiFormData, offeringClass: classes[0] });
        }
        setFetchOfferingClassesRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchOfferingClassesRequestStatus);
      });
  };

  /**
   * @function fetchInstanceTypes
   * @description Function to fetch the instance types
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchInstanceTypes = (cancelTokenSource: CancelTokenSource) => {
    setFetchInstanceTypesRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      platform: ec2RiFormData.platform,
      region: ec2RiFormData.region,
      availabilityZone: ec2RiFormData.availabilityZone,
      tenancy: ec2RiFormData.tenancy,
      offeringClass: ec2RiFormData.offeringClass,
    };

    getAwsRiInstanceTypes(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        setInstanceTypes(res.data.responseData || []);
        setFetchInstanceTypesRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchInstanceTypesRequestStatus);
      });
  };

  /**
   * @function fetchPaymentOptions
   * @description Function to fetch the Payment Options
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchPaymentOptions = (cancelTokenSource: CancelTokenSource) => {
    setFetchPaymentOptionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      platform: ec2RiFormData.platform,
      region: ec2RiFormData.region,
      availabilityZone: ec2RiFormData.availabilityZone,
      tenancy: ec2RiFormData.tenancy,
      offeringClass: ec2RiFormData.offeringClass,
      instanceType: ec2RiFormData.instanceType,
      term: convertYearToSeconds(ec2RiFormData.term),
    };

    getAwsEc2RiPaymentOptions(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        const payments = res.data.responseData || [];
        if (!payments?.includes(ec2RiFormData.paymentOption)) {
          setEc2RiFormData({ ...ec2RiFormData, paymentOption: '' });
        }
        setPaymentOptions(payments);
        setFetchPaymentOptionsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchPaymentOptionsRequestStatus);
      });
  };

  /**
   * @function fetchOfferingDetails
   * @description Function to fetch the offering details
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchOfferingDetails = (cancelTokenSource: CancelTokenSource) => {
    setOfferingDetailsRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      availabilityZone: ec2RiFormData.availabilityZone,
      instanceType: ec2RiFormData.instanceType,
      offeringClass: ec2RiFormData.offeringClass,
      tenancy: ec2RiFormData.tenancy,
      platform: ec2RiFormData.platform,
      term: convertYearToSeconds(ec2RiFormData.term),
      paymentOption: ec2RiFormData.paymentOption,
      quantity: Number(ec2RiFormData.quantity),
      region: ec2RiFormData.region,
    };

    getAwsEc2RiOfferingDetails(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          if (data.offeringId) {
            setEc2RiFormData({
              ...ec2RiFormData,
              averageMonthlyRecurringCost:
                data?.averageMonthlyRecurringCost ?? 0,
              oneTimePayment: data?.totalDue ?? 0,
              offeringId: data?.offeringId ?? '',
            });
            setOfferingDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
            return;
          }
          setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
          setOfferingDetailsValidation(
            t('purchasePage.reservedInstancesLabels.offeringIdNullError')
          );
          return;
        }
        setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
        setOfferingDetailsValidation(
          t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }

        onApiCallError(e, false, setOfferingDetailsRequestStatus);
        if (!axios.isCancel(e)) {
          setOfferingDetailsValidation(
            e?.response?.data?.message ??
              t('purchasePage.reservedInstancesLabels.offeringDetailsError')
          );
        }
      });
  };

  /**
   * @function validatePlatform
   * @description Function to validate the platform field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validatePlatform = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.reservedInstancesLabels.platform`),
      setPlatformValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateRegion
   * @description Function to validate the region field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateRegion = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.region`),
      setRegionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateDbInstanceClass
   * @description Function to validate the Availability Zone field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAvailabilityZone = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.reservedInstancesLabels.availabilityZone`),
      setAvailabilityZoneValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateInstanceType
   * @description Function to validate the instance type field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateInstanceType = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.reservedInstancesLabels.instanceType`),
      setInstanceTypeValidation,
      addErrorMessage
    );
  };

  /**
   * @function validatePaymentOption
   * @description Function to validate the payment option field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validatePaymentOption = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.paymentOptions`),
      setPaymentOptionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateQuantityField
   * @description Function to validate the quantity field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityField = (
    value: number | string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (
      !validateEmptyField(
        value,
        t(`purchasePage.reservedInstancesLabels.quantity`),
        setQuantityValidation,
        addErrorMessage
      )
    ) {
      return (
        isNumber(
          value?.toString(),
          false,
          t(`purchasePage.reservedInstancesLabels.quantity`),
          addErrorMessage ? setQuantityValidation : undefined
        ) && validateQuantityRangeLimit(value, addErrorMessage)
      );
    }

    return false;
  };

  /**
   * @function validateQuantityRangeLimit
   * @description Function to validate the quantity limit and range
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityRangeLimit = (
    value: string | number | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (!value || !isNumber(value.toString(), false)) {
      return false;
    }

    if (Number(value) <= 0) {
      addErrorMessage &&
        setQuantityValidation(
          t('purchasePage.reservedInstancesLabels.quantityLimitError')
        );
      return false;
    }

    addErrorMessage && setQuantityValidation('');

    return true;
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAllFields = (addErrorMessage: boolean = true) => {
    let validation = true;

    if (!validatePlatform(ec2RiFormData?.platform, addErrorMessage)) {
      validation = false;
    }

    if (!validateRegion(ec2RiFormData?.region, addErrorMessage)) {
      validation = false;
    }

    if (
      !validateAvailabilityZone(
        ec2RiFormData?.availabilityZone,
        addErrorMessage
      )
    ) {
      validation = false;
    }

    if (!validateInstanceType(ec2RiFormData?.instanceType, addErrorMessage)) {
      validation = false;
    }

    if (!validatePaymentOption(ec2RiFormData?.paymentOption, addErrorMessage)) {
      validation = false;
    }

    if (!validateQuantityField(ec2RiFormData?.quantity, addErrorMessage)) {
      validation = false;
    }

    if (
      addErrorMessage &&
      validation &&
      isEmptyField(ec2RiFormData?.offeringId)
    ) {
      setOfferingDetailsValidation(
        t('purchasePage.reservedInstancesLabels.missingOfferingDetails')
      );
      validation = false;
    }

    if (
      !availabilityZones ||
      !tenancies ||
      !offeringClasses ||
      !instanceTypes ||
      !paymentOptions
    ) {
      validation = false;
    }

    return validation;
  };

  /**
   * @function onClickBuy
   * @description Callback function for buy button click
   */
  const onClickBuy = () => {
    if (!validateAllFields()) {
      return;
    }

    if (editData !== undefined && indexPosition !== undefined) {
      const cartData = [...awsEc2RiCartData];
      cartData.splice(indexPosition, 1, ec2RiFormData);
      dispatch(setAwsEc2RisCartData(cartData));
    } else {
      dispatch(setAwsEc2RisCartData([...awsEc2RiCartData, ec2RiFormData]));
    }
    setShow(false);
  };

  return (
    <DrawerComponent
      className="ec2-ri-purchase-form"
      dataTestId="ec2-ri-purchase-form"
      width={481}
      open={show}
      title={`${t(
        'purchasePage.reservedInstancesLabels.purchaseReservedInstances'
      )} - ${t('purchasePage.reservedInstancesLabels.ec2')}`}
      onClose={() => setShow(false)}
      footer={
        <PurchaseFooter
          totalHeading={t('purchasePage.cart.subTotalDueNow')}
          totalValue={getEc2SubTotalDue(
            ec2RiFormData.term,
            ec2RiFormData.averageMonthlyRecurringCost
          )}
          costSummary={[
            {
              key: t(
                'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
              ),
              value: ec2RiFormData.averageMonthlyRecurringCost,
            },
            {
              key: t('purchasePage.reservedInstancesLabels.oneTimePayment'),
              value: ec2RiFormData?.oneTimePayment,
            },
          ]}
          okTitle={
            editData
              ? t('purchasePage.cart.update')
              : t('purchasePage.cart.buy')
          }
          onClickCancel={() => setShow(false)}
          onClickOk={onClickBuy}
          loading={offeringDetailsRequestStatus === REQUEST_STATUS.PROCESSING}
          validationMessage={offeringDetailsValidation}
        />
      }
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.platform')}
            required={true}
          />
          <SelectDropdown
            value={ec2RiFormData?.platform}
            options={awsPlatforms.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t(
              'purchasePage.reservedInstancesLabels.selectPlatform'
            )}
            loading={fetchPlatformsReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setEc2RiFormData({
                ...ec2RiFormData,
                platform: value,
              });
              validatePlatform(value);
            }}
            onBlur={() => {
              validatePlatform(ec2RiFormData?.platform);
            }}
            designVersion2
          />
          <span
            style={{
              display: platformValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {platformValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.region')} required={true} />
          <SelectDropdown
            value={ec2RiFormData?.region}
            options={awsRegions.map((item) => ({ value: item, label: item }))}
            placeholder={t('purchasePage.cart.selectRegion')}
            loading={fetchRegionReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setEc2RiFormData({
                ...ec2RiFormData,
                region: value,
                availabilityZone: '',
              });
              validateRegion(value);
            }}
            onBlur={() => {
              validateRegion(ec2RiFormData?.region);
            }}
            designVersion2
          />
          <span
            style={{
              display: regionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {regionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.availabilityZone')}
            required={true}
          />
          <SelectDropdown
            value={ec2RiFormData?.availabilityZone}
            options={availabilityZones?.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t(
              'purchasePage.reservedInstancesLabels.selectAvailabilityZone'
            )}
            loading={
              fetchAvailabilityZonesRequestStatus === REQUEST_STATUS.PROCESSING
            }
            onSelect={(value: any) => {
              setEc2RiFormData({
                ...ec2RiFormData,
                availabilityZone: value,
              });
              validateAvailabilityZone(value);
            }}
            onBlur={() => {
              validateAvailabilityZone(ec2RiFormData?.availabilityZone);
            }}
            designVersion2
          />
          <span
            style={{
              display: availabilityZoneValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {availabilityZoneValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.tenancy')}
            required={true}
          />
          <RadioGroup
            rootClassName="flex-column"
            options={TENANCY_OPTIONS.map((item) => ({
              ...item,
              disabled: !tenancies?.includes(item.value),
            }))}
            onChange={(e: any) => {
              setEc2RiFormData({
                ...ec2RiFormData,
                tenancy: e.target.value,
              });
            }}
            value={ec2RiFormData.tenancy}
            loading={fetchTenancyRequestStatus === REQUEST_STATUS.PROCESSING}
            rows={2}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.offeringClass')}
            required={true}
          />
          <RadioGroup
            options={OFFERING_CLASS_OPTIONS.map((item) => ({
              ...item,
              disabled: !offeringClasses?.includes(item.value),
            }))}
            onChange={(e: any) => {
              setEc2RiFormData({
                ...ec2RiFormData,
                offeringClass: e.target.value,
                instanceType: '',
              });
            }}
            value={ec2RiFormData.offeringClass}
            optionType="button"
            style={{ height: 28 }}
            loading={
              fetchOfferingClassesRequestStatus === REQUEST_STATUS.PROCESSING
            }
            rows={1}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.instanceType')}
            required={true}
          />
          <SelectDropdown
            value={ec2RiFormData?.instanceType}
            options={instanceTypes?.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t(
              'purchasePage.reservedInstancesLabels.selectInstanceType'
            )}
            loading={
              fetchInstanceTypesRequestStatus === REQUEST_STATUS.PROCESSING
            }
            onSelect={(value: any) => {
              setEc2RiFormData({
                ...ec2RiFormData,
                instanceType: value,
              });
              validateInstanceType(value);
            }}
            onBlur={() => {
              validateInstanceType(ec2RiFormData?.instanceType);
            }}
            designVersion2
          />
          <span
            style={{
              display: instanceTypeValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {instanceTypeValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.term')} required={true} />
          <RadioGroup
            options={TERMS_LIST}
            onChange={(e: any) => {
              setEc2RiFormData({ ...ec2RiFormData, term: e.target.value });
            }}
            value={ec2RiFormData.term}
            optionType="button"
            rootClassName="no-custom-style"
            style={{ height: 28 }}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.cart.paymentOptions')}
            required={true}
          />
          <SelectDropdown
            value={ec2RiFormData?.paymentOption}
            options={paymentOptions?.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t('purchasePage.cart.selectPaymentOption')}
            onSelect={(value: any) => {
              setEc2RiFormData({
                ...ec2RiFormData,
                paymentOption: value,
              });
              validatePaymentOption(value);
            }}
            onBlur={() => {
              validatePaymentOption(ec2RiFormData?.paymentOption);
            }}
            loading={
              fetchPaymentOptionsRequestStatus === REQUEST_STATUS.PROCESSING
            }
            designVersion2
          />
          <span
            style={{
              display: paymentOptionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {paymentOptionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.quantity')}
            required={true}
          />
          <Input
            value={ec2RiFormData?.quantity}
            type="stepNumber"
            rootClassName="width-30"
            steps={1}
            min={1}
            onChange={(value: number) => {
              setEc2RiFormData({
                ...ec2RiFormData,
                quantity: value,
              });
              validateQuantityField(value);
            }}
            onBlur={() => {
              validateQuantityField(ec2RiFormData?.quantity);
            }}
          />
          <span
            style={{
              display: quantityValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {quantityValidation}
          </span>
        </div>
      </section>
    </DrawerComponent>
  );
};

export default BuyEc2RiFormModal;
