import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getAllAwsSavingPlans
 * @description Function to fetch all the AWS Saving plans
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAllAwsSavingPlans = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_AWS_SP_PLANS, null, params);
};

/**
 * @function deleteAwsSavingPlan
 * @description Function to delete AWS Saving Plan
 * @param params Object containing the request parameters.
 * @return axios response from POST request
 */
export const deleteAwsSavingPlan = async (params: any) => {
  return ServiceCalls.post(APIS.REMOVE_AWS_SP, null, null, params);
};
