import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Avatar, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  selectDashboard,
  setDashboardList,
  setPdfGraphToDownload,
  setPdfDownloadMode,
  setSelectedDashboard,
  setSelectedGroupMetaData,
  setSelectedMyDashboardType,
  setTableViewEnabled,
  setIsEmptyDashboardList,
  setDashboardUsers,
  setPptDownloadMode,
} from 'redux/dashboardSlice';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { PDF_ALL_GRAPHS, PDF_ALL_TABLE_GRAPHS } from 'constants/pdfConstants';
import {
  customDashboard,
  setCustomDashboardGroup,
  setDashboardType,
} from 'redux/customDashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import { LoadingIcon, ProfileIcon } from 'assets/icons';
import DashboardTitle from 'components/DashboardTitle';
import Switch from 'components/Switch';
import Button from 'components/Button';
import AccessibleDiv from 'components/AccessibleDiv';
import DashboardListDrawer from 'components/DashboardListDrawer';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DashboardListType } from 'types/navigationMenu';
import {
  fetchDashboardList,
  fetchGroupDashboardMetaData,
  getDashboardUsers,
  getOtherUsersProfilePhoto,
} from 'utils/services';
import { exportToExcel } from 'utils/exportToExcel';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { REFRESH_INTERVAL } from 'constants/defaultValues';
import { onApiCallError } from 'utils/handleErrors';
import {
  addZeroMarginClass,
  getExportFileName,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { DASHBOARD_STATUS } from 'pages/DashboardsPage/constants';

import ShareDashboardModal from './components/ShareDashboardModal';
import { DASHBOARD_WITH_POPOVER_DOWNLOAD } from './constants';

import './index.scss';

const DashboardHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    dashboardList,
    selectedDashboard,
    selectedDashboardView,
    exportToExcelData,
    tableViewEnabled,
    dashboardViewsList,
    pdfDownloadMode,
    pptDownloadMode,
    dashboardUsers,
  } = useSelector(selectDashboard);
  const { selectedDashboardCustomViews } = useSelector(customDashboard);
  const { theme } = useSelector(selectTheme);
  const { urlDashboardId } = useParams();

  const [isDownloadingData, setIsDownloadingData] = useState(false);
  const [fetchDashboardsRequestStatus, setFetchDashboardsRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [
    fetchDashboardUsersRequestStatus,
    setFetchDashboardUsersRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [showShareDashboardModal, setShowShareDashboardModal] = useState(false);
  const [showDashboardListModal, setShowDashboardListModal] = useState(false);
  const [firstTwoUserPhotos, setFirstTwoUserPhotos] = useState<string[]>([
    ProfileIcon,
    ProfileIcon,
  ]);

  useEffect(() => {
    fetchDashboards();
    addZeroMarginClass();
    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => fetchDashboards(false),
      REFRESH_INTERVAL
    );
    return () => clearInterval(interval);
  }, [dashboardList]);

  useEffect(() => {
    if (selectedDashboard) {
      fetchAndSetDashboardUsers();
    }
  }, [selectedDashboard]);

  useEffect(() => {
    if (dashboardUsers.length > 0) {
      fetchFirstTwoUserPhotos();
    }
  }, [dashboardUsers]);

  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  useEffect(() => {
    if (!pptDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pptDownloadMode]);

  useEffect(() => {
    if (
      fetchDashboardsRequestStatus === REQUEST_STATUS.PROCESSING ||
      dashboardList.length === 0
    ) {
      return;
    }
    let defaultDashboard = dashboardList?.find(
      (dashboard) =>
        dashboard.defaultDashboard &&
        dashboard.dashboardStatus !== DASHBOARD_STATUS.DE_ACTIVATED
    );

    if (urlDashboardId && selectedDashboard?.id !== urlDashboardId) {
      setSelectedDashboardData(
        urlDashboardId,
        dashboardList?.find((dashboard) => dashboard.id === urlDashboardId)
          ?.dashBoardType ?? ''
      );
    } else if (
      !selectedDashboard ||
      dashboardList.filter(
        (availableDashboard) =>
          availableDashboard.id === selectedDashboard['id']
      ).length === 0 ||
      (selectedDashboard && selectedDashboard?.id !== urlDashboardId)
    ) {
      defaultDashboard = defaultDashboard ?? dashboardList[0];
      goToDashboard(defaultDashboard.id);
    }
  }, [urlDashboardId, dashboardList, fetchDashboardsRequestStatus]);

  const goToDashboard = (key: string) => {
    navigate(NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS + `/${key}`);
  };

  const fetchDashboards = (emptyOnError: boolean = true) => {
    fetchDashboardList()
      .then((res: any) => {
        if (res.status === 200) {
          setFetchDashboardsRequestStatus(REQUEST_STATUS.SUCCESS);
          const dashboards: DashboardListType[] =
            res?.data?.responseData?.content;
          const filteredDashboardList = dashboards.filter(
            (value) =>
              value.dashBoardType !== MY_DASHBOARD_TYPES.COST_ALLOCATION
          );
          dispatch(setIsEmptyDashboardList(filteredDashboardList.length === 0));
          if (!isEqual(dashboardList, filteredDashboardList)) {
            dispatch(setDashboardList(filteredDashboardList));
          }
          return;
        }
        dispatch(setIsEmptyDashboardList(true));
        dispatch(setDashboardList([]));
        setFetchDashboardsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchDashboardsRequestStatus);
        if (emptyOnError) {
          dispatch(setIsEmptyDashboardList(true));
          dispatch(setDashboardList([]));
        }
      });
  };

  const setSelectedDashboardData = (key: string, dropdownListType: string) => {
    dispatch(
      setSelectedDashboard(
        dashboardList?.find((dashboard) => dashboard.id === key)!
      )
    );
    dispatch(setSelectedMyDashboardType(dropdownListType));
    dispatch(setDashboardType(dropdownListType));
    if (dropdownListType === MY_DASHBOARD_TYPES.GROUP) {
      fetchGroupDashboardMetaData(
        dashboardList?.find((dashboard) => dashboard.id === key)?.groupName!
      )
        .then((res: any) => {
          dispatch(setSelectedGroupMetaData(res?.data?.responseData));
          dispatch(setCustomDashboardGroup(res?.data?.responseData));
        })
        .catch((error) => onApiCallError(error, false));
    } else {
      dispatch(setSelectedGroupMetaData(null));
      dispatch(setCustomDashboardGroup(null));
    }
  };

  /**
   * @function fetchAndSetDashboardUsers
   * @description Function to fetch the list of users with whom dashboard is shared
   */
  const fetchAndSetDashboardUsers = () => {
    setFetchDashboardUsersRequestStatus(REQUEST_STATUS.PROCESSING);

    getDashboardUsers(selectedDashboard?.id ?? '')
      .then((res: any) => {
        dispatch(setDashboardUsers(res.data.responseData));
        setFetchDashboardUsersRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) =>
        onApiCallError(e, false, setFetchDashboardUsersRequestStatus)
      );
  };

  /**
   * @function fetchFirstTwoUserPhotos
   * @description function to fetch first two user photos to whom dashboard is shared
   */
  const fetchFirstTwoUserPhotos = async () => {
    const firstTwoUsers = dashboardUsers.slice(0, 2);
    const requests = firstTwoUsers.map((user: any) =>
      getOtherUsersProfilePhoto(user.userEmail)
    );
    const responses = await axios.all(requests);
    let firstTwoPhotos = [ProfileIcon, ProfileIcon];
    for (const response of responses) {
      if (response.status === 200) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        firstTwoPhotos[responses.indexOf(response)] = imageObjectURL;
      }
    }
    setFirstTwoUserPhotos(firstTwoPhotos);
  };

  /**
   * @function getSharedUserIcons
   * @description function which returns a JSX element about info of shared users.
   * @returns JSX element containing the profile icons and number of shared users.
   */
  const getSharedUserIcons = () => {
    return (
      <Avatar.Group
        maxCount={2}
        maxStyle={{ color: '#FDFFF9', backgroundColor: '#62AD41' }}
        size="small"
        maxPopoverTrigger="focus"
      >
        {dashboardUsers.map((user, index) => (
          <Avatar
            key={user.userEmail}
            src={firstTwoUserPhotos.at(index) ?? ProfileIcon}
            alt={`${user.userEmail}-pic`}
            data-testid={`${user.userEmail}-pic`}
          />
        ))}
      </Avatar.Group>
    );
  };

  /**
   * @function onHandleDownloadDashboardData
   * @description function to handle common download of dashboard view.
   * @returns either pdf or excel
   */
  const onHandleDownloadDashboardData = () => {
    setIsDownloadingData(true);
    if (!tableViewEnabled) {
      dispatch(setPdfGraphToDownload(PDF_ALL_GRAPHS));
      dispatch(setPdfDownloadMode(true));
      return;
    }
    setIsDownloadingData(false);
    return exportToExcel(
      getExportFileName(
        selectedDashboardCustomViews,
        selectedDashboardView,
        dashboardViewsList,
        selectedDashboard?.name!
      ),
      exportToExcelData
    );
  };

  const DownloadOptions = (
    <div className="flex flex-column flex-gap-4">
      <AccessibleDiv
        className="font-caption-bold cursor-pointer"
        style={{ color: theme.buttonIconColor, textAlign: 'center' }}
        onClick={() => {
          dispatch(setPptDownloadMode(true));
          setIsDownloadingData(true);
        }}
      >
        {t('spendDiagnosticsPptExport')}
      </AccessibleDiv>
      <AccessibleDiv
        className="font-caption-bold cursor-pointer"
        style={{ color: theme.buttonIconColor, textAlign: 'center' }}
        onClick={() => {
          setIsDownloadingData(true);
          dispatch(setPdfGraphToDownload(PDF_ALL_GRAPHS));
          dispatch(setPdfDownloadMode(true));
        }}
      >
        {t('exportAsPdf')}
      </AccessibleDiv>
      <AccessibleDiv
        className="font-caption-bold cursor-pointer"
        style={{ color: theme.buttonIconColor, textAlign: 'center' }}
        onClick={() => {
          setIsDownloadingData(true);
          dispatch(setPdfGraphToDownload(PDF_ALL_TABLE_GRAPHS));
          dispatch(setPdfDownloadMode(true));
        }}
      >
        {t('exportTableAsPdf')}
      </AccessibleDiv>
      <AccessibleDiv
        className="font-caption-bold cursor-pointer"
        style={{ color: theme.buttonIconColor, textAlign: 'center' }}
        onClick={async () => {
          setIsDownloadingData(true);
          await exportToExcel(
            getExportFileName(
              selectedDashboardCustomViews,
              selectedDashboardView,
              dashboardViewsList,
              selectedDashboard?.name!
            ),
            exportToExcelData
          );
          setIsDownloadingData(false);
        }}
      >
        {t('exportTableAsExcel')}
      </AccessibleDiv>
    </div>
  );

  const getDownloadIcon = () => {
    if (isDownloadingData)
      return (
        <Icon
          icon={LoadingIcon}
          className="rotate"
          color={theme.buttonIconColor}
          dataTestId="download-loading"
        />
      );
    if (DASHBOARD_WITH_POPOVER_DOWNLOAD.includes(selectedDashboardView))
      return (
        <Popover content={DownloadOptions} trigger="click">
          <div>
            <Icon
              iconName={ICONS.DOWNLOAD_2_LINE}
              size={ICONS_SIZE.ONE_X}
              color={theme.buttonIconColor}
              dataTestId="download-cta"
            />
          </div>
        </Popover>
      );
    return (
      <Icon
        iconName={ICONS.DOWNLOAD_2_LINE}
        size={ICONS_SIZE.ONE_X}
        onClick={onHandleDownloadDashboardData}
        color={theme.buttonIconColor}
        dataTestId="download-cta"
      />
    );
  };

  return (
    <div className="new-page-header header dashboard-header flex flex-align-items-center flex-space-between flex-wrap">
      {selectedDashboard ? (
        <DashboardTitle fetchDashboards={fetchDashboards} />
      ) : (
        <Icon
          icon={LoadingIcon}
          className="rotate"
          color={theme.buttonIconColor}
        />
      )}
      <div className="header-right flex flex-align-items-center">
        <div className="flex flex-align-items-center flex-gap-2">
          {getSharedUserIcons()}
          <Icon
            iconName={ICONS.SHARE_LINE}
            onClick={() => setShowShareDashboardModal(true)}
            size={ICONS_SIZE.ONE_X}
            color={theme.buttonIconColor}
            dataTestId="share-cta"
          />
        </div>
        {getDownloadIcon()}
        <div className="table-view-title table-typography flex flex-align-items-center flex-gap-8">
          {t('tableView')}
          <Switch
            size="small"
            checked={tableViewEnabled}
            onChange={(value: boolean) => {
              dispatch(setTableViewEnabled(value));
            }}
          />
        </div>
        <Button
          title={t('dashboardLabels.switchDashboard')}
          iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
          iconSize={ICONS_SIZE.ONE_X}
          onClick={() => setShowDashboardListModal(true)}
        />
        <DashboardListDrawer
          show={showDashboardListModal}
          setShow={setShowDashboardListModal}
          onSelectDashboard={goToDashboard}
        />
        <ShareDashboardModal
          showShareDashboardModal={showShareDashboardModal}
          setShowShareDashboardModal={setShowShareDashboardModal}
          fetchAndSetDashboardUsers={fetchAndSetDashboardUsers}
          fetchUsersReqStatus={fetchDashboardUsersRequestStatus}
        />
      </div>
    </div>
  );
};

export default DashboardHeader;
