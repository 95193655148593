import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import axios from 'axios';

import NavigationPath from 'components/NavigationPath';
import Button from 'components/Button';
import Table from 'components/Table';
import DeleteModal from 'components/DeleteModal';
import QuickActionMenu from 'components/QuickActionMenu';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import {
  DASHBOARD_TYPES,
  ERROR_KEY,
  REQUEST_STATUS,
  SUCCESS_KEY,
} from 'constants/requestBody';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { PROVIDER } from 'constants/cloudProviders';
import { onApiCallError } from 'utils/handleErrors';
import { PAGINATION_SIZE } from 'constants/userConsole';
import Pagination from 'components/Pagination';
import { AvailableFieldsType } from 'types/dashboard';
import { getBillingMappingData } from 'utils/services';

import { BillingMappingType } from './types';
import {
  BillingMappingQuickActions,
  BILLING_MAPPING_QUICK_ACTIONS_LIST,
} from './constants';
import AddOrEditBillingMappingModal from './components/AddOrEditBillingMappingModal';
import {
  deleteBillingMappingData,
  getAllFieldsByProviderAndDatasetType,
} from './services';

import './index.scss';

const BillingMappingPage = () => {
  const { t } = useTranslation();

  const [billingMappingData, setBillingMappingData] = useState<
    BillingMappingType[]
  >([]);
  const [totalBillingMapCount, setTotalBillingMapCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [billingMapReqStatus, setBillingMapReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [actionRecord, setActionRecord] = useState<BillingMappingType>();
  const [showBillingMapModal, setShowBillingMapModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteBillingMapReqStatus, setDeleteBillingMapReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [billingFields, setBillingFields] = useState<{
    [key: string]: AvailableFieldsType[];
  }>({});
  const [fetchAllFieldsReqStatus, setFetchAllFieldsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    addZeroMarginClass();
    fetchBillingMapData();
    fetchAllBillingFields();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function fetchBillingMapData
   * @description Function to fetch the billing map data
   * @param page pagination page for fetching the data
   */
  const fetchBillingMapData = (page: number = currentPage) => {
    setBillingMapReqStatus(REQUEST_STATUS.PROCESSING);

    const requestParams = {
      page: page - 1,
      size: PAGINATION_SIZE,
    };

    getBillingMappingData(requestParams)
      .then((res: any) => {
        setBillingMappingData(res?.data?.responseData?.content ?? []);
        setTotalBillingMapCount(res?.data?.responseData?.totalElements ?? 0);
        setBillingMapReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setBillingMapReqStatus);
      });
  };

  /**
   * @function fetchAllBillingFields
   * @description Function to fetch all the billing fields
   */
  const fetchAllBillingFields = () => {
    setFetchAllFieldsReqStatus(REQUEST_STATUS.PROCESSING);
    const requests = Object.values(PROVIDER).map((provider) => {
      const params = {
        cloudProvider: provider,
        dbType: DASHBOARD_TYPES.BILLING,
      };

      return getAllFieldsByProviderAndDatasetType(params);
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        let fieldsMap: { [key: string]: AvailableFieldsType[] } = {};
        responses.forEach((res) => {
          fieldsMap[res?.config?.params?.cloudProvider] =
            res?.data?.responseData ?? [];
        });
        setBillingFields(fieldsMap);
        setFetchAllFieldsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchAllFieldsReqStatus);
      });
  };

  /**
   * @function onDeleteError
   * @description Callback function for error on deleting a billing mapping
   */
  const onDeleteError = (errorMessage: string) => {
    message.error({
      content:
        errorMessage ||
        t('billingMapping.errorDeletingDimension', {
          dimensionName: actionRecord?.dimensionName,
        }),
      key: ERROR_KEY,
    });
    setDeleteBillingMapReqStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function deleteBillingMapData
   * @description Function to delete the billing mapping
   */
  const deleteBillingMapData = () => {
    setDeleteBillingMapReqStatus(REQUEST_STATUS.PROCESSING);

    deleteBillingMappingData(actionRecord?.dimensionName ?? '')
      .then((res: any) => {
        if (res?.status === 200) {
          setDeleteBillingMapReqStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          message.success({
            content: t('billingMapping.successDeletingDimension', {
              dimensionName: actionRecord?.dimensionName,
            }),
            key: SUCCESS_KEY,
          });
          setCurrentPage(1);
          fetchBillingMapData(1);
          return;
        }
        onDeleteError(res?.data?.message);
      })
      .catch((e) => {
        onApiCallError(e, false);
        onDeleteError(e?.response?.data?.message);
      });
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions
   * @param record record for which the action is taken
   * @param action quick action clicked
   */
  const handleQuickAction = (record: BillingMappingType, action: string) => {
    setActionRecord(record);

    if (action === BillingMappingQuickActions.EDIT) {
      setShowBillingMapModal(true);
    } else {
      setShowDeleteModal(true);
    }
  };

  /**
   * @function getBillingFieldByProvider
   * @description Function to get the billing field by provider
   * @param provider provider for which the billing field is required
   * @param billingMapData billingMapData from which the billing field is required
   * @returns billing field or hyphen string
   */
  const getBillingFieldByProvider = (
    provider: string,
    billingMapData: BillingMappingType
  ) => {
    return (
      billingMapData?.billingDimensionMap?.find(
        (item) => item.provider === provider
      )?.billingField ?? '--'
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: 50,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: t('billingMapping.billingName'),
      dataIndex: 'dimensionName',
      key: 'dimensionName',
      render: (text: string) => (
        <div className="billing-name flex flex-align-items-center flex-space-between">
          <span className="font-caption-bold">{text}</span>
          <Icon className="arrow" iconName={ICONS.ARROW_RIGHT} />
        </div>
      ),
    },
    {
      title: t('billingMapping.fieldMapping'),
      dataIndex: 'fieldMapping',
      key: 'fieldMapping',
      render: (_text: string, record: BillingMappingType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={getProviderSmallLogo(PROVIDER.GCP)}
            alt={`${PROVIDER.GCP} Logo`}
          />
          {getBillingFieldByProvider(PROVIDER.GCP, record)}
        </div>
      ),
    },
    {
      dataIndex: 'fieldMapping',
      key: 'fieldMapping',
      render: (_text: string, record: BillingMappingType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={getProviderSmallLogo(PROVIDER.AWS)}
            alt={`${PROVIDER.AWS} Logo`}
          />
          {getBillingFieldByProvider(PROVIDER.AWS, record)}
        </div>
      ),
    },
    {
      dataIndex: 'fieldMapping',
      key: 'fieldMapping',
      render: (_text: string, record: BillingMappingType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={getProviderSmallLogo(PROVIDER.AZURE)}
            alt={`${PROVIDER.AZURE} Logo`}
          />
          {getBillingFieldByProvider(PROVIDER.AZURE, record)}
        </div>
      ),
    },
    {
      title: t('billingMapping.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: BillingMappingType) => {
        return (
          <QuickActionMenu
            quickActions={BILLING_MAPPING_QUICK_ACTIONS_LIST}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      align: 'center' as const,
    },
  ];

  return (
    <div className="billing-mapping flex flex-column flex-fit">
      <header className="new-page-header billing-mapping-header flex flex-align-items-center flex-space-between">
        <span className="modal-heading">
          {t('navigationMenu.billingMapping')}
        </span>
        <Button
          title={t('billingMapping.addNewField')}
          onClick={() => {
            setActionRecord(undefined);
            setShowBillingMapModal(true);
          }}
        />
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="table-container margin-24 flex flex-column flex-fit">
          <Table
            pagination={false}
            rootClassName="table-section"
            dataSource={billingMappingData?.map((billingMap, index) => {
              return { ...billingMap, key: index + 1 };
            })}
            columns={columns}
            scroll={{ y: '100%' }}
            loading={billingMapReqStatus === REQUEST_STATUS.PROCESSING}
            designVersion2={true}
            fillContainer={true}
            data-testid="billing-mapping-table"
          />
          {totalBillingMapCount > PAGINATION_SIZE && (
            <Pagination
              rootClassName="pagination flex flex-align-items-end flex-fit"
              current={currentPage}
              onChange={(page: number) => {
                setCurrentPage(page);
                setBillingMappingData([]);
                fetchBillingMapData(currentPage);
              }}
              total={totalBillingMapCount}
              defaultPageSize={PAGINATION_SIZE}
            />
          )}
        </div>
      </div>
      {showBillingMapModal && (
        <AddOrEditBillingMappingModal
          show={showBillingMapModal}
          setShow={setShowBillingMapModal}
          editData={actionRecord}
          onClickSubmit={() => {
            setCurrentPage(1);
            fetchBillingMapData(1);
          }}
          billingFields={billingFields}
          fetchAllFieldsReqStatus={fetchAllFieldsReqStatus}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={deleteBillingMapData}
          loading={deleteBillingMapReqStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default BillingMappingPage;
