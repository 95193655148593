import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { sum } from 'lodash';
import { RangeValue } from 'rc-picker/lib/interface';

import ControlComponent from 'components/DashboardControl';
import DropdownCheckbox from 'components/DropdownCheckbox';
import DashboardGraphContainer from 'components/DashboardGraphContainer';
import TagFilters from 'components/TagFilters';
import { INPUT_SIZE } from 'constants/appearance';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getForecastedCost } from 'pages/OverviewPage/components/ConnectionsDashboard/services';
import { ForecastRequestBody } from 'pages/OverviewPage/components/ConnectionsDashboard/types';
import { getPdfMetaData } from 'pages/OverviewPage/components/ConnectionsDashboard/utils';
import { ChartCriteria } from 'constants/dashboard';
import { REQUEST_STATUS } from 'constants/requestBody';
import { QUARTER_COST_SUMMARY } from 'constants/graphLabels';
import {
  ComparisonListType,
  GraphRowContentType,
  DashboardMetaData,
} from 'types/dashboard';
import {
  ColouredCostByServiceType,
  MonthlyCostByServiceType,
  ColouredCostByResourceType,
  MonthlyCostType,
  CostByNameType,
  TypeAndNameByCost,
  MonthlyCostByCompartmentType,
  ColouredCostByRegionType,
} from 'types/dataTypes';
import {
  addZeroMarginClass,
  getCurrentQuarterStartMonthAndYear,
  getFirstMonthAndCurrentYear,
  getPercentageDifference,
  getPreviousMonthAndYear,
  getPreviousMonthDayAndYear,
  getPreviousQuarterEndMonthAndYear,
  getPreviousQuarterStartMonthAndYear,
  isDashboardWithStaticData,
  removeZeroMarginClass,
  transformStackedDataByGroupForPptExport,
} from 'utils/dashboardUtils';
import {
  getDateFilterAsString,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { CHART_TYPES, GRAPH_OPTIONS } from 'constants/graphConfig';
import ComparisonCard from 'components/NewComparisonCard';
import { onApiCallError } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { getMonthRangeHandler } from 'utils/graphUtils';

import CostTrend from './components/CostTrend';
import QuarterCostSummary from './components/QuarterCostSummary';
import YtdCostByService from './components/YtdCostByService';
import MonthlyCostByCompartment from './components/MonthlyCostByCompartment';
import CostByRegion from './components/CostByRegion';
import {
  getOCICostByPeriodData,
  getCostTrendData,
  getOCIMonthlyCostByCompartmentData,
  getOCICostByServiceData,
  getOCICostByRegionData,
  getCostByServiceColumns,
  getCostTrendsData,
  getMonthlyCostByCompartmentColumns,
  getCostByServiceForTrends,
  getCostByResourceForTrends,
} from './utils';
import { getUniqueCompartments } from './components/MonthlyCostByCompartment/utils';
import { getUniqueServices } from './components/YtdCostByService/utils';
import SpendProfileChartWrapper from '../SpendProfileChartWrapper';
import { SpendProfileDataType } from '../SpendProfileChartWrapper/types';
import { CHART_CRITERIA_LIST, CostByRegionColumns } from './constants';
import {
  getCostTrendsHeading,
  getCostTrendsColumns,
  getCostTrendChartLegends,
  getCostTrendXAxisLabel,
} from '../../utils';

const OCICostSummaryDashboard = () => {
  const { t } = useTranslation();
  const {
    selectedDashboard,
    selectedConnection,
    tagsFilters,
    selectedDashboardView,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [chartCriteriaFilters, setChartCriteriaFilters] = useState<string[]>(
    CHART_CRITERIA_LIST.map((item) => item.value)
  );
  const [costComparisonList, setCostComparisonList] = useState<
    ComparisonListType[]
  >([]);

  //States for comparison cards
  const [currentYearCost, setCurrentYearCost] = useState(0);
  const [currentYearCostRequestStatus, setCurrentYearCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousYearCost, setPreviousYearCost] = useState(0);
  const [previousYearCostRequestStatus, setPreviousYearCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [currentQuarterCost, setCurrentQuarterCost] = useState(0);
  const [currentQuarterCostRequestStatus, setCurrentQuarterCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousQuarterCost, setPreviousQuarterCost] = useState(0);
  const [
    previousQuarterCostRequestStatus,
    setPreviousQuarterCostRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [currentMonthCost, setCurrentMonthCost] = useState(0);
  const [currentMonthCostRequestStatus, setCurrentMonthCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousMonthCost, setPreviousMonthCost] = useState(0);
  const [previousMonthCostRequestStatus, setPreviousMonthCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousSecondMonthCost, setPreviousSecondMonthCost] = useState(0);
  const [
    previousSecondMonthCostRequestStatus,
    setPreviousSecondMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  // ML forecasted cost
  const [nextMonthForeCastedCost, setNextMonthForeCastedCost] = useState(0);
  const [
    nextMonthForecastedCostReqStatus,
    setNextMonthForecastedCostReqStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [foreCastedCost, setForeCastedCost] = useState<MonthlyCostType[]>([]);
  const [forecastErrorMessage, setForecastErrorMessage] = useState('');
  const [foreCastedCostRequestStatus, setForeCastedCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [forecastDateRange, setForecastDateRange] = useState([
    moment().format(HYPHEN_DATE_FORMAT),
    moment().add(3, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
  ]);

  //State for Cost Trend Data
  const [costTrendData, setCostTrendData] = useState<MonthlyCostType[]>([]);
  const [costTrendRequestStatus, setCostTrendRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [costTrendStartMonth, setCostTrendStartMonth] = useState(
    getPreviousMonthAndYear(11)
  );
  const [costTrendEndMonth, setCostTrendEndMonth] = useState(
    getPreviousMonthAndYear(0)
  );
  const [selectedTrendMonth, setSelectedTrendMonth] = useState<string>();
  const [selectedTrendService, setSelectedTrendService] = useState<string>();
  const [monthWiseServiceData, setMonthWiseServiceData] = useState<{
    [key: string]: ColouredCostByServiceType[];
  }>();
  const [monthServiceResourceData, setMonthServiceResourceData] = useState<{
    [key: string]: ColouredCostByResourceType[];
  }>();
  const [costTrendSliderValue, setCostTrendSliderValue] = useState<{
    x: number;
    y: number;
  }>();

  // States for Cloud spend
  const [cloudSpendData, setCloudSpendData] = useState<CostByNameType[]>([]);
  const [selectedCloudSpendData, setSelectedCloudSpendData] = useState<
    CostByNameType[]
  >([]);
  const [cloudSpendStartDate, setCloudSpendStartDate] = useState<string>(
    getPreviousMonthDayAndYear(12)
  );
  const [cloudSpendEndDate, setCloudSpendEndDate] = useState<string>(
    getPreviousMonthDayAndYear(0)
  );
  const [cloudSpendRequestStatus, setCloudSpendRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [isCloudSpendTableView, setIsCloudSpendTableView] = useState(false);

  // States for Tagged UnTagged Spend
  const [taggedUntaggedSpendData, setTaggedUntaggedSpendData] = useState<
    TypeAndNameByCost[]
  >([]);
  const [taggedUntaggedTableData, setTaggedUntaggedTableData] = useState<any[]>(
    []
  );
  const [taggedUntaggedSpendStartMonth, setTaggedUntaggedSpendStartMonth] =
    useState(
      moment().startOf('month').subtract(12, 'month').format(HYPHEN_DATE_FORMAT)
    );
  const [taggedUntaggedSpendEndMonth, setTaggedUntaggedSpendEndMonth] =
    useState(moment().format(HYPHEN_DATE_FORMAT));
  const [
    taggedUntaggedSpendRequestStatus,
    setTaggedUntaggedSpendRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [isTaggedUntaggedSpendTableView, setIsTaggedUntaggedSpendTableView] =
    useState(false);

  //State for Monthly Cost By Compartment Data
  const [monthWiseCostByCompartment, setMonthWiseCostByCompartment] = useState<
    MonthlyCostByCompartmentType[]
  >([]);
  const [
    selectedMonthlyCostByCompartmentData,
    setSelectedMonthlyCostByCompartmentData,
  ] = useState<MonthlyCostByCompartmentType[]>([]);
  const [
    monthlyCostByCompartmentRequestStatus,
    setMonthlyCostByCompartmentRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [
    monthlyCostByCompartmentStartDate,
    setMonthlyCostByCompartmentStartDate,
  ] = useState(getPreviousMonthAndYear(11));
  const [monthlyCostByCompartmentEndDate, setMonthlyCostByCompartmentEndDate] =
    useState(getPreviousMonthAndYear(0));

  //Cost By Region Data
  const [costByRegionData, setCostByRegionData] = useState<
    ColouredCostByRegionType[]
  >([]);
  const [costByRegionDataRequestStatus, setCostByRegionDataRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [costByRegionStartDate, setCostByRegionStartDate] = useState(
    moment().subtract(1, 'year').startOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [costByRegionEndDate, setCostByRegionEndDate] = useState(
    moment().startOf('month').format(HYPHEN_DATE_FORMAT)
  );

  //State for Cost by Service Data
  const [monthlyCostByServiceData, setMonthlyCostByServiceData] = useState<
    MonthlyCostByServiceType[]
  >([]);
  const [
    selectedMonthlyCostByServiceData,
    setSelectedMonthlyCostByServiceData,
  ] = useState<MonthlyCostByServiceType[]>([]);
  const [costByServiceRequestStatus, setCostByServiceRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  // States for Application Profile Spend
  const [applicationProfileSpendData, setApplicationProfileSpendData] =
    useState<SpendProfileDataType[]>([]);
  const [
    applicationProfileSpendReqStatus,
    setApplicationProfileSpendReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [spendProfileStartMonth, setSpendProfileStartMonth] = useState(
    moment().startOf('month').subtract(12, 'month').format(HYPHEN_DATE_FORMAT)
  );
  const [spendProfileEndMonth, setSpendProfileEndMonth] = useState(
    moment().format(HYPHEN_DATE_FORMAT)
  );

  //States for table view
  const [isCostTrendTableView, setIsCostTrendTableView] = useState(false);
  const [isCostByServiceTableView, setIsCostByServiceTableView] =
    useState(false);
  const [
    isMonthlyCostByCompartmentTableView,
    setIsMonthlyCostByCompartmentTableView,
  ] = useState(false);
  const [isCostByRegionTableView, setIsCostByRegionTableView] = useState(false);

  useEffect(() => {
    addZeroMarginClass('dashboard-view');

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    setCurrentYearCost(0);
    setPreviousYearCost(0);
    setCurrentQuarterCost(0);
    setPreviousQuarterCost(0);
    setCurrentMonthCost(0);
    setPreviousMonthCost(0);
    setPreviousSecondMonthCost(0);
    setNextMonthForeCastedCost(0);
    setMonthlyCostByServiceData([]);

    // API calls
    getCostComparisonCardsData();

    // Do not fetch the forecast data for static file import as the forecast model is not implemented for static data
    if (!isDashboardWithStaticData(selectedDashboard)) {
      getNextMonthForecastCost();
    }

    getOCICostByServiceData(
      {
        dashboard: selectedDashboard!,
        tagsFilters: tagsFilters,
        dashboardView: selectedDashboardView,
        connection: selectedConnection!,
      },
      setMonthlyCostByServiceData,
      setCostByServiceRequestStatus
    );
  }, [selectedDashboard, tagsFilters]);

  useEffect(() => {
    setForeCastedCost([]);
    // Do not fetch the forecast data for static file import as the forecast model is not implemented for static data
    if (!isDashboardWithStaticData(selectedDashboard)) {
      getForecastCost();
    }
  }, [selectedDashboard, forecastDateRange]);

  useEffect(() => {
    const services = getUniqueServices(monthlyCostByServiceData).slice(0, 10);
    setSelectedMonthlyCostByServiceData(
      monthlyCostByServiceData.filter((item) =>
        services.some((service) => service.service === item.service)
      )
    );
  }, [monthlyCostByServiceData]);

  useEffect(() => {
    const compartments = getUniqueCompartments(
      monthWiseCostByCompartment
    ).slice(0, 10);
    setSelectedMonthlyCostByCompartmentData(
      monthWiseCostByCompartment.filter((item) =>
        compartments.some(
          (compartment) => compartment.compartment === item.compartment
        )
      )
    );
  }, [monthWiseCostByCompartment]);

  useEffect(() => {
    const comparisonCardDataList: ComparisonListType[] = [
      {
        value: currentYearCost,
        heading: t('costComparisonCard.ytdCosts'),
        comparisonValue: getPercentageDifference(
          currentYearCost,
          previousYearCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousYear'),
        requestStatus: [
          currentYearCostRequestStatus,
          previousYearCostRequestStatus,
        ],
      },
      {
        value: currentQuarterCost,
        heading: t('costComparisonCard.qtdCosts'),
        comparisonValue: getPercentageDifference(
          currentQuarterCost,
          previousQuarterCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousQuarter'),
        requestStatus: [
          currentQuarterCostRequestStatus,
          previousQuarterCostRequestStatus,
        ],
      },
      {
        value: currentMonthCost,
        heading: t('costComparisonCard.currentMonthCost'),
        comparisonValue: getPercentageDifference(
          currentMonthCost,
          previousMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousMonth'),
        requestStatus: [
          currentMonthCostRequestStatus,
          previousMonthCostRequestStatus,
        ],
      },
      {
        value: previousMonthCost,
        heading: t('costComparisonCard.lastMonthCost'),
        comparisonValue: getPercentageDifference(
          previousMonthCost,
          previousSecondMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromTwoMonthsBack'),
        requestStatus: [
          previousMonthCostRequestStatus,
          previousSecondMonthCostRequestStatus,
        ],
      },
    ];

    if (!isDashboardWithStaticData(selectedDashboard)) {
      comparisonCardDataList.push({
        value: nextMonthForeCastedCost,
        heading: t('costComparisonCard.nextMonthForecastedCost'),
        comparisonValue: getPercentageDifference(
          nextMonthForeCastedCost,
          previousMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousMonth'),
        requestStatus: [
          previousMonthCostRequestStatus,
          nextMonthForecastedCostReqStatus,
        ],
        errorMessage: forecastErrorMessage,
      });
    }

    setCostComparisonList(comparisonCardDataList);
  }, [
    currentYearCost,
    previousYearCost,
    currentQuarterCost,
    previousQuarterCost,
    currentMonthCost,
    previousMonthCost,
    previousSecondMonthCost,
    costTrendData,
    currentYearCostRequestStatus,
    previousYearCostRequestStatus,
    currentQuarterCostRequestStatus,
    previousQuarterCostRequestStatus,
    currentMonthCostRequestStatus,
    previousMonthCostRequestStatus,
    previousSecondMonthCostRequestStatus,
    nextMonthForecastedCostReqStatus,
    forecastErrorMessage,
  ]);

  useEffect(() => {
    setCostTrendData([]);
    setSelectedTrendMonth(undefined);
    setSelectedTrendService(undefined);
    getCostTrendData(
      {
        dashboard: selectedDashboard!,
        tagsFilters: tagsFilters,
        dashboardView: selectedDashboardView,
        connection: selectedConnection!,
      },
      costTrendStartMonth,
      costTrendEndMonth,
      setCostTrendData,
      setCostTrendRequestStatus
    );
  }, [selectedDashboard, costTrendEndMonth, costTrendStartMonth, tagsFilters]);

  useEffect(() => {
    setCostTrendSliderValue(undefined);
    if (selectedTrendMonth && !monthWiseServiceData?.[selectedTrendMonth]) {
      getCostByServiceForTrends(
        {
          dashboard: selectedDashboard!,
          tagsFilters: tagsFilters,
          dashboardView: selectedDashboardView,
          connection: selectedConnection!,
        },
        selectedTrendMonth,
        monthWiseServiceData,
        setMonthWiseServiceData,
        setCostTrendRequestStatus
      );
    }
  }, [selectedTrendMonth]);

  useEffect(() => {
    setCostTrendSliderValue(undefined);
    if (
      selectedTrendMonth &&
      selectedTrendService &&
      !monthServiceResourceData?.[
        `${selectedTrendMonth}${selectedTrendService}`
      ]
    ) {
      getCostByResourceForTrends(
        {
          dashboard: selectedDashboard!,
          tagsFilters: tagsFilters,
          dashboardView: selectedDashboardView,
          connection: selectedConnection!,
        },
        selectedTrendMonth,
        selectedTrendService,
        monthServiceResourceData,
        setMonthServiceResourceData,
        setCostTrendRequestStatus
      );
    }
  }, [selectedTrendMonth, selectedTrendService]);

  useEffect(() => {
    setMonthWiseCostByCompartment([]);

    // Do not fetch the compartment related data when the data source is static file as there is no FOCUS mapping
    if (!isDashboardWithStaticData(selectedDashboard)) {
      getOCIMonthlyCostByCompartmentData(
        {
          dashboard: selectedDashboard!,
          tagsFilters: tagsFilters,
          dashboardView: selectedDashboardView,
          connection: selectedConnection!,
        },
        monthlyCostByCompartmentStartDate,
        monthlyCostByCompartmentEndDate,
        setMonthWiseCostByCompartment,
        setMonthlyCostByCompartmentRequestStatus
      );
    }
  }, [
    selectedDashboard,
    monthlyCostByCompartmentStartDate,
    monthlyCostByCompartmentEndDate,
    tagsFilters,
  ]);

  useEffect(() => {
    setCostByRegionData([]);
    getOCICostByRegionData(
      {
        dashboard: selectedDashboard!,
        tagsFilters: tagsFilters,
        dashboardView: selectedDashboardView,
        connection: selectedConnection!,
      },
      costByRegionStartDate,
      costByRegionEndDate,
      setCostByRegionData,
      setCostByRegionDataRequestStatus
    );
  }, [
    selectedDashboard,
    costByRegionStartDate,
    costByRegionEndDate,
    tagsFilters,
  ]);

  useEffect(() => {
    // Do not fetch the compartment related data when the data source is static file as there is no FOCUS mapping
    // if (!isDashboardWithStaticData(selectedDashboard)) {
    //   fetchCloudSpendData(
    //     setCloudSpendRequestStatus,
    //     selectedDashboard!,
    //     selectedConnection!,
    //     {
    //       startDate: cloudSpendStartDate,
    //       endDate: cloudSpendEndDate,
    //     },
    //     tagsFilters,
    //     selectedDashboardView,
    //     {
    //       setData: setCloudSpendData,
    //       setSelectedData: setSelectedCloudSpendData,
    //     }
    //   );
    // }
  }, [selectedDashboard, cloudSpendStartDate, cloudSpendEndDate, tagsFilters]);

  useEffect(() => {
    // Do not fetch the compartment related data when the data source is static file as there is no FOCUS mapping
    // if (!isDashboardWithStaticData(selectedDashboard)) {
    //   fetchTaggedUntaggedSpendData(
    //     setTaggedUntaggedSpendRequestStatus,
    //     {
    //       startMonth: taggedUntaggedSpendStartMonth,
    //       endMonth: taggedUntaggedSpendEndMonth,
    //     },
    //     {
    //       setData: setTaggedUntaggedSpendData,
    //       setTableData: setTaggedUntaggedTableData,
    //     },
    //     selectedDashboard!,
    //     selectedConnection!,
    //     tagsFilters,
    //     selectedDashboardView
    //   );
    // }
  }, [
    selectedDashboard,
    taggedUntaggedSpendStartMonth,
    taggedUntaggedSpendEndMonth,
    tagsFilters,
  ]);

  /**
   * @function onChangeCostTrendDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostTrendDateRange = useCallback(
    (_dates: RangeValue<Moment>, dateString: [string, string]) => {
      const startMonth = moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      );
      const endMonth = moment(dateString[1], MONTH_YEAR_FORMAT).isAfter(
        moment(),
        'months'
      )
        ? moment().format(YEAR_MONTH_WITHOUT_SEPARATOR)
        : moment(dateString[1], MONTH_YEAR_FORMAT).format(
            YEAR_MONTH_WITHOUT_SEPARATOR
          );
      const forecastStartMonth = moment(
        dateString[0],
        MONTH_YEAR_FORMAT
      ).isSameOrBefore(moment(), 'months')
        ? moment().format(HYPHEN_DATE_FORMAT)
        : moment(dateString[0], MONTH_YEAR_FORMAT)
            .startOf('month')
            .format(HYPHEN_DATE_FORMAT);
      const forecastEndMonth = moment(dateString[1], MONTH_YEAR_FORMAT)
        .endOf('month')
        .format(HYPHEN_DATE_FORMAT);
      setCostTrendStartMonth(startMonth);
      setCostTrendEndMonth(endMonth);
      setForecastDateRange([forecastStartMonth, forecastEndMonth]);
    },
    []
  );

  /**
   * @function onChangeCostByCompartmentDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostByCompartmentDateRange = useCallback(
    (_dates: RangeValue<Moment>, dateString: [string, string]) => {
      setMonthlyCostByCompartmentStartDate(
        moment(dateString[0], MONTH_YEAR_FORMAT).format(
          YEAR_MONTH_WITHOUT_SEPARATOR
        )
      );
      setMonthlyCostByCompartmentEndDate(
        moment(dateString[1], MONTH_YEAR_FORMAT).format(
          YEAR_MONTH_WITHOUT_SEPARATOR
        )
      );
    },
    []
  );

  const getCostComparisonCardsData = async () => {
    const dashboardMetaData: DashboardMetaData = {
      dashboard: selectedDashboard!,
      tagsFilters: tagsFilters,
      dashboardView: selectedDashboardView,
      connection: selectedConnection!,
    };

    //Current Year Cost
    getOCICostByPeriodData(
      dashboardMetaData,
      getFirstMonthAndCurrentYear(),
      getPreviousMonthAndYear(0),
      setCurrentYearCost,
      setCurrentYearCostRequestStatus
    );

    // Previous year Cost
    getOCICostByPeriodData(
      dashboardMetaData,
      moment()
        .subtract(1, 'year')
        .startOf('year')
        .format(YEAR_MONTH_WITHOUT_SEPARATOR),
      moment()
        .subtract(1, 'year')
        .endOf('year')
        .format(YEAR_MONTH_WITHOUT_SEPARATOR),
      setPreviousYearCost,
      setPreviousYearCostRequestStatus
    );

    // Current Quarter Cost
    getOCICostByPeriodData(
      dashboardMetaData,
      getCurrentQuarterStartMonthAndYear(),
      getPreviousMonthAndYear(0),
      setCurrentQuarterCost,
      setCurrentQuarterCostRequestStatus
    );

    // Previous Quarter Cost
    getOCICostByPeriodData(
      dashboardMetaData,
      getPreviousQuarterStartMonthAndYear(),
      getPreviousQuarterEndMonthAndYear(),
      setPreviousQuarterCost,
      setPreviousQuarterCostRequestStatus
    );

    // Current Month Cost
    getOCICostByPeriodData(
      dashboardMetaData,
      moment().startOf('month').format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
      setCurrentMonthCost,
      setCurrentMonthCostRequestStatus
    );

    // Previous Month Cost
    getOCICostByPeriodData(
      dashboardMetaData,
      moment().subtract(1, 'month').startOf('month').format(HYPHEN_DATE_FORMAT),
      moment().subtract(1, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
      setPreviousMonthCost,
      setPreviousMonthCostRequestStatus
    );

    // Previous 2nd Month Cost
    getOCICostByPeriodData(
      dashboardMetaData,
      moment().subtract(2, 'month').startOf('month').format(HYPHEN_DATE_FORMAT),
      moment().subtract(2, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
      setPreviousSecondMonthCost,
      setPreviousSecondMonthCostRequestStatus
    );
  };

  const parseAndSetForecastErrorMessage = (errorMessage: string) => {
    try {
      const isPermissionDenied =
        JSON.parse(errorMessage ?? null)?.error?.status === 'PERMISSION_DENIED';
      setForecastErrorMessage(
        isPermissionDenied ? t('costComparisonCard.permissionDenied') : ''
      );
    } catch {
      setForecastErrorMessage(errorMessage);
    }
  };

  const getNextMonthForecastCost = () => {
    setNextMonthForecastedCostReqStatus(REQUEST_STATUS.PROCESSING);
    const requestBody: ForecastRequestBody = {
      connectorId: selectedDashboard?.connectorId!,
      start: moment()
        .add(1, 'month')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      end: moment().add(1, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
    };

    getForecastedCost(requestBody)
      .then((res: any) => {
        setForecastErrorMessage('');
        if (res?.status === 200) {
          const forecastCost =
            JSON.parse(res?.data?.responseData)?.predictions ?? [];
          setNextMonthForeCastedCost(sum(forecastCost));
          setNextMonthForecastedCostReqStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setForeCastedCost([]);
          setNextMonthForecastedCostReqStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setNextMonthForecastedCostReqStatus);
        setForeCastedCost([]);
        parseAndSetForecastErrorMessage(e?.response?.data?.message);
      });
  };

  const getForecastCost = () => {
    setForeCastedCostRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody: ForecastRequestBody = {
      connectorId: selectedDashboard?.connectorId!,
      start: forecastDateRange[0],
      end: forecastDateRange[1],
    };

    getForecastedCost(requestBody)
      .then((res: any) => {
        setForecastErrorMessage('');
        if (res?.status === 200) {
          const forecastCost =
            JSON.parse(res?.data?.responseData)?.predictions ?? [];
          const monthwiseForecast: MonthlyCostType[] = [];
          const months = getMonthYearShortList(
            moment(forecastDateRange[0]).format(YEAR_MONTH_WITHOUT_SEPARATOR),
            moment(forecastDateRange[1]).format(YEAR_MONTH_WITHOUT_SEPARATOR)
          );

          let index = 0;
          months.forEach((month) => {
            let days = moment(month, MONTH_YEAR_SHORT).daysInMonth();
            if (month === moment().format(MONTH_YEAR_SHORT)) {
              days = moment()
                .endOf('month')
                .diff(moment().subtract(1, 'days'), 'days');
            }

            monthwiseForecast.push({
              month: month,
              cost: sum(forecastCost.slice(index, index + days)),
            });
            index += days;
          });
          setForeCastedCost(monthwiseForecast);
          setForeCastedCostRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setForeCastedCost([]);
          setForeCastedCostRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setNextMonthForecastedCostReqStatus);
        setForeCastedCostRequestStatus(REQUEST_STATUS.ERROR);
        setForeCastedCost([]);
        parseAndSetForecastErrorMessage(e?.response?.data?.message);
      });
  };

  /**
   * @function getServiceDataBySelectedMonth
   * @description Function to return the service data for the selected month from the month to service data map.
   * @returns list of service data
   */
  const getServiceDataBySelectedMonth = () => {
    if (
      !monthWiseServiceData ||
      !selectedTrendMonth ||
      !monthWiseServiceData[selectedTrendMonth]
    ) {
      return [];
    }

    return monthWiseServiceData[selectedTrendMonth];
  };

  /**
   * @function getResourceDataBySelectedMonthAndService
   * @description Function to return the service data for the selected month and service from the month service and resource data map.
   * @returns list of resource data
   */
  const getResourceDataBySelectedMonthAndService = () => {
    if (
      !monthServiceResourceData ||
      !selectedTrendMonth ||
      !selectedTrendService ||
      !monthServiceResourceData[`${selectedTrendMonth}${selectedTrendService}`]
    ) {
      return [];
    }

    return monthServiceResourceData[
      `${selectedTrendMonth}${selectedTrendService}`
    ];
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'cost-trend':
        return (
          <CostTrend
            monthlyCost={costTrendData}
            forecastedCost={foreCastedCost}
            costTrendStartMonth={costTrendStartMonth}
            costTrendEndMonth={costTrendEndMonth}
            forecastDateRange={forecastDateRange}
            onChangeCostTrendDateRange={onChangeCostTrendDateRange}
            costTrendRequestStatus={[
              costTrendRequestStatus,
              foreCastedCostRequestStatus,
            ]}
            isCostTrendTableView={isCostTrendTableView}
            pdfView={pdfView}
            setIsCostTrendTableView={setIsCostTrendTableView}
            hasForecastedCost={
              !isDashboardWithStaticData(selectedDashboard) &&
              foreCastedCostRequestStatus === REQUEST_STATUS.SUCCESS &&
              moment(costTrendEndMonth).isSameOrAfter(moment(), 'month')
            }
            selectedTrendMonth={selectedTrendMonth}
            setSelectedTrendMonth={setSelectedTrendMonth}
            selectedTrendService={selectedTrendService}
            setSelectedTrendService={setSelectedTrendService}
            serviceData={getServiceDataBySelectedMonth()}
            resourceData={getResourceDataBySelectedMonthAndService()}
            sliderValue={costTrendSliderValue}
            setSliderValue={setCostTrendSliderValue}
          />
        );

      case 'quarter-cost-summary':
        return (
          <QuarterCostSummary
            currentQuarterCost={currentQuarterCost}
            previousQuarterCost={previousQuarterCost}
            requestStatus={[
              currentQuarterCostRequestStatus,
              previousQuarterCostRequestStatus,
            ]}
            pdfView={pdfView}
          />
        );

      case 'monthly-cost-by-compartments':
        return (
          <MonthlyCostByCompartment
            requestStatus={monthlyCostByCompartmentRequestStatus}
            selectedMonthlyCostByCompartmentData={
              selectedMonthlyCostByCompartmentData
            }
            monthWiseCostByCompartment={monthWiseCostByCompartment}
            setSelectedMonthlyCostByCompartmentData={
              setSelectedMonthlyCostByCompartmentData
            }
            isMonthlyCostByCompartmentTableView={
              isMonthlyCostByCompartmentTableView
            }
            setIsMonthlyCostByCompartmentTableView={
              setIsMonthlyCostByCompartmentTableView
            }
            pdfView={pdfView}
            monthlyCostByCompartmentStartDate={
              monthlyCostByCompartmentStartDate
            }
            monthlyCostByCompartmentEndDate={monthlyCostByCompartmentEndDate}
            onChangeCostByCompartmentDateRange={
              onChangeCostByCompartmentDateRange
            }
          />
        );

      case 'ytd-cost-by-service':
        return (
          <YtdCostByService
            requestStatus={costByServiceRequestStatus}
            selectedMonthlyCostByServiceData={selectedMonthlyCostByServiceData}
            monthlyCostByServiceData={monthlyCostByServiceData}
            setSelectedMonthlyCostByServiceData={
              setSelectedMonthlyCostByServiceData
            }
            isCostByServiceTableView={isCostByServiceTableView}
            setIsCostByServiceTableView={setIsCostByServiceTableView}
            pdfView={pdfView}
          />
        );

      case 'cost-by-region':
        return (
          <CostByRegion
            requestStatus={costByRegionDataRequestStatus}
            costByRegionData={costByRegionData}
            pdfView={pdfView}
            costByRegionStartDate={costByRegionStartDate}
            costByRegionEndDate={costByRegionEndDate}
            onChangeCostByRegionDateRange={getMonthRangeHandler(
              setCostByRegionStartDate,
              setCostByRegionEndDate
            )}
          />
        );

      case 'service-spend-profile':
        return (
          <SpendProfileChartWrapper
            data={applicationProfileSpendData}
            setData={setApplicationProfileSpendData}
            requestStatus={applicationProfileSpendReqStatus}
            setRequestStatus={setApplicationProfileSpendReqStatus}
            pdfView={pdfView}
            spendProfileStartMonth={spendProfileStartMonth}
            setSpendProfileStartMonth={setSpendProfileStartMonth}
            spendProfileEndMonth={spendProfileEndMonth}
            setSpendProfileEndMonth={setSpendProfileEndMonth}
          />
        );
    }
  };

  const getCostComparisonCards = () =>
    costComparisonList.map((item, index) => {
      return (
        <ComparisonCard
          key={item.heading}
          index={index}
          value={item.value}
          heading={item.heading}
          valuePrefix={currencySymbol}
          comparisonValue={item.comparisonValue}
          comparisonFrom={item.comparisonFrom}
          requestStatus={item.requestStatus}
          errorMessage={item.errorMessage}
        />
      );
    });

  const monthlyCostByCompartmentExportData = (addNumberComma = false) => {
    const groupedByCompartment: any[] = [];
    selectedMonthlyCostByCompartmentData.forEach((item) => {
      const existingCompartment = groupedByCompartment.find(
        (obj) => obj.compartment === item.compartment
      );
      if (existingCompartment) {
        existingCompartment[`${item.month}-cost`] = addNumberComma
          ? `${currencySymbol}${numberCommaSeparator(item.cost)}`
          : `${currencySymbol}${item.cost}`;
      } else {
        groupedByCompartment.push({
          compartment: item.compartment,
          [`${item.month}-cost`]: addNumberComma
            ? `${currencySymbol}${numberCommaSeparator(item.cost)}`
            : `${currencySymbol}${item.cost}`,
        });
      }
    });
    return groupedByCompartment;
  };

  const costByServiceExportData = (addNumberComma = false) => {
    const groupedByService: any[] = [];
    selectedMonthlyCostByServiceData.forEach((item) => {
      const existingService = groupedByService.find(
        (obj) => obj.service === item.service
      );
      if (existingService) {
        existingService[`${item.month}-cost`] = addNumberComma
          ? `${currencySymbol}${numberCommaSeparator(item.cost)}`
          : `${currencySymbol}${item.cost}`;
      } else {
        groupedByService.push({
          service: item.service,
          [`${item.month}-cost`]: addNumberComma
            ? `${currencySymbol}${numberCommaSeparator(item.cost)}`
            : `${currencySymbol}${item.cost}`,
        });
      }
    });
    return groupedByService;
  };

  /**
   * @function getCostTrendLegendsForPdfExport
   * @description Function to fetch the legends list based on the slider position
   * @returns list of legends with color
   */
  const getCostTrendLegendsForPdfExport = () => {
    if (!selectedTrendMonth) {
      return undefined;
    }

    const startIndex = costTrendSliderValue
      ? costTrendSliderValue.x * (CostTrendTableData.length - 1)
      : 0;

    const endIndex = costTrendSliderValue
      ? costTrendSliderValue.y * (CostTrendTableData.length - 1) + 1
      : GRAPH_OPTIONS.sliderCountLimit + 1;

    return CostTrendTableData.slice(startIndex, endIndex).map((data: any) => ({
      name: data.month ?? data.service ?? data.resource ?? '',
      color: data.color ?? '',
    }));
  };

  const CostTrendTableData = getCostTrendsData(
    selectedTrendMonth,
    selectedTrendService,
    [costTrendStartMonth, forecastDateRange[1]],
    {
      trends: costTrendData,
      forecasted: foreCastedCost,
      service: getServiceDataBySelectedMonth(),
      resource: getResourceDataBySelectedMonthAndService(),
    }
  );

  // Memoized data for the graph rows to improve performance
  const GraphRows: GraphRowContentType = useMemo(
    () => [
      [
        {
          element: getGraphComponent('cost-trend', true),
          contentType: selectedTrendMonth
            ? CHART_TYPES.BAR_CHART
            : CHART_TYPES.BAR_LINE_CHART,
          graphName: 'cost-trend',
          graphHeading: getCostTrendsHeading(
            selectedTrendMonth,
            selectedTrendService
          ),
          criteria: [ChartCriteria.TIME_PERIOD, ChartCriteria.SERVICE],
          chartView: {
            customLegends: getCostTrendChartLegends(
              selectedTrendMonth,
              selectedTrendService
            ),
            xAxisLabel: getCostTrendXAxisLabel(
              selectedTrendMonth,
              selectedTrendService
            ),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getCostTrendsColumns(
              selectedTrendMonth,
              selectedTrendService
            ),
            isTableView: isCostTrendTableView,
            setTableView: setIsCostTrendTableView,
          },
          pdfTableExport: {
            pdfTableData: CostTrendTableData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
              forecastedCost:
                'forecastedCost' in spend
                  ? currencySymbol + numberCommaSeparator(spend.forecastedCost)
                  : undefined,
            })),
            legends: getCostTrendLegendsForPdfExport(),
          },
          excelExport: {
            excelData: CostTrendTableData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              endDate: costTrendEndMonth,
              startDate: costTrendStartMonth,
              month: selectedTrendMonth,
              service: selectedTrendService,
            },
          },
          pptExport: {
            chartHeading: !selectedTrendMonth
              ? `${t('graphHeadings.costTrend')} (${getDateFilterAsString(
                  costTrendStartMonth,
                  costTrendEndMonth
                )})`
              : getCostTrendsHeading(selectedTrendMonth, selectedTrendService),
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: CostTrendTableData.map((spend) => {
                  if ('resource' in spend) {
                    return spend.resource;
                  } else if ('service' in spend) {
                    return spend.service;
                  } else {
                    return spend.month;
                  }
                }),
                values: CostTrendTableData.map(
                  (spend: any) => spend.forecastedCost ?? spend.cost ?? 0
                ),
              },
            ],
          },
        },
      ],
      // [
      //   {
      //     element: getGraphComponent('service-spend-profile', true),
      //     contentType: CHART_TYPES.PIE_CHART,
      //     graphName: 'service-spend-profile',
      //     graphHeading: t('graphHeadings.serviceSpendProfile'),
      //     criteria: [ChartCriteria.SPEND_PROFILE],
      //     pptExport: {
      //       pptCustomContent: getSpendProfilePptContent(
      //         applicationProfileSpendData
      //       ),
      //     },
      //   },
      // ],
      // [
      //   {
      //     element: getGraphComponent('tagged-untagged-cost', true),
      //     contentType: CHART_TYPES.STACK_CHART,
      //     graphName: 'tagged-untagged-cost',
      //     graphHeading: t('graphHeadings.taggedUntaggedSpend'),
      //     display: !isDashboardWithStaticData(selectedDashboard),
      //     criteria: [ChartCriteria.SPEND_PROFILE],
      //     chartView: {
      //       xAxisLabel: t('months'),
      //       yAxisLabel: t('costInCurrency', { currencySymbol }),
      //     },
      //     tableView: {
      //       columns: TaggedUntaggedSpendColumns,
      //       isTableView: isTaggedUntaggedSpendTableView,
      //       setTableView: setIsTaggedUntaggedSpendTableView,
      //     },
      //     pdfTableExport: {
      //       pdfTableData: taggedUntaggedTableData.map((spend) => ({
      //         ...spend,
      //         Tagged: currencySymbol + numberCommaSeparator(spend.Tagged),
      //         Untagged: currencySymbol + numberCommaSeparator(spend.Untagged),
      //       })),
      //     },
      //     excelExport: {
      //       excelData: taggedUntaggedTableData,
      //       excelFilters: {
      //         connectionName: selectedDashboard!.name,
      //         startDate: taggedUntaggedSpendStartMonth,
      //         endDate: taggedUntaggedSpendEndMonth,
      //       },
      //     },
      //     pptExport: {
      //       chartHeading: `${t(
      //         'graphHeadings.taggedUntaggedSpend'
      //       )} (${getDateFilterAsString(
      //         taggedUntaggedSpendStartMonth,
      //         taggedUntaggedSpendEndMonth
      //       )})`,
      //       pptData: transformStackedDataByGroupForPptExport(
      //         taggedUntaggedSpendData,
      //         'type',
      //         'cost',
      //         'name'
      //       ),
      //     },
      //   },
      // ],
      [
        {
          colSpanWidth: 10,
          element: getGraphComponent('quarter-cost-summary', true),
          contentType: CHART_TYPES.HORIZONTAL_BAR_CHART,
          graphName: 'quarter-cost-summary',
          criteria: [ChartCriteria.TIME_PERIOD],
          chartView: {
            customLegends: QUARTER_COST_SUMMARY,
            xAxisLabel: t('costInCurrency', { currencySymbol }),
            yAxisLabel: t('quarters'),
          },
          graphHeading: t('graphHeadings.costByQuarter'),
          pptExport: {
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: QUARTER_COST_SUMMARY,
                values: [currentQuarterCost, previousQuarterCost],
              },
            ],
          },
        },
        {
          element: getGraphComponent('monthly-cost-by-compartments', true),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: 'monthly-cost-by-compartments',
          graphHeading: t('graphHeadings.monthlyCostByCompartments'),
          criteria: [ChartCriteria.COMPARTMENT],
          display: !isDashboardWithStaticData(selectedDashboard),
          colSpanWidth: 14,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getMonthlyCostByCompartmentColumns(
              monthlyCostByCompartmentStartDate,
              monthlyCostByCompartmentEndDate
            ),
            isTableView: isMonthlyCostByCompartmentTableView,
            setTableView: setIsMonthlyCostByCompartmentTableView,
          },
          pdfTableExport: {
            pdfTableData: monthlyCostByCompartmentExportData(true),
          },
          excelExport: {
            excelData: monthlyCostByCompartmentExportData(),
            excelFilters: {
              connectionName: selectedDashboard!.name,
              endDate: monthlyCostByCompartmentStartDate,
              startDate: monthlyCostByCompartmentEndDate,
            },
          },
          pptExport: {
            chartHeading: `${t(
              'graphHeadings.monthlyCostByCompartments'
            )} (${getDateFilterAsString(
              monthlyCostByCompartmentStartDate,
              monthlyCostByCompartmentEndDate
            )})`,
            pptData: transformStackedDataByGroupForPptExport(
              selectedMonthlyCostByCompartmentData,
              'compartment',
              'cost',
              'month'
            ),
          },
        },
      ],
      [
        {
          colSpanWidth: 12,
          contentType: CHART_TYPES.STACK_CHART,
          graphName: 'ytd-cost-by-service',
          graphHeading: t('graphHeadings.ytdCostByService'),
          element: getGraphComponent('ytd-cost-by-service', true),
          criteria: [ChartCriteria.SERVICE],
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getCostByServiceColumns(selectedMonthlyCostByServiceData),
            isTableView: isCostByServiceTableView,
            setTableView: setIsCostByServiceTableView,
          },
          pdfTableExport: {
            pdfTableData: costByServiceExportData(true).map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
          },
          excelExport: {
            excelData: costByServiceExportData(),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: transformStackedDataByGroupForPptExport(
              selectedMonthlyCostByServiceData,
              'service',
              'cost',
              'month'
            ),
          },
        },
      ],
      [
        {
          element: getGraphComponent('cost-by-region', true),
          contentType: CHART_TYPES.MAP,
          graphName: 'cost-by-region',
          graphHeading: t('graphHeadings.costByRegion'),
          criteria: [ChartCriteria.REGION],
          tableView: {
            columns: CostByRegionColumns.filter(
              (column) => column.key !== 'color'
            ),
            isTableView: false,
          },
          pdfTableExport: {
            pdfTableData: costByRegionData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
            legends: costByRegionData.map((item) => ({
              color: item.color,
              name: `${item.region || 'Others'} - ${
                currencySymbol + numberCommaSeparator(item.cost)
              }`,
            })),
          },
          excelExport: {
            excelData: costByRegionData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              endDate: costByRegionEndDate,
              startDate: costByRegionStartDate,
            },
          },
          pptExport: {
            chartHeading: `${t(
              'graphHeadings.costByRegion'
            )} (${getDateFilterAsString(
              costByRegionStartDate,
              costByRegionEndDate
            )})`,
            pptData: [
              CostByRegionColumns.map((column) => column.title),
              ...costByRegionData.map((eachData, index) => [
                (index + 1).toString(),
                eachData.region,
                eachData.cost.toString(),
              ]),
            ],
          },
        },
      ],
    ],
    [
      applicationProfileSpendData,
      cloudSpendEndDate,
      cloudSpendStartDate,
      costTrendEndMonth,
      costTrendStartMonth,
      currentQuarterCost,
      isCloudSpendTableView,
      isCostByServiceTableView,
      isCostTrendTableView,
      isTaggedUntaggedSpendTableView,
      monthlyCostByCompartmentEndDate,
      monthlyCostByCompartmentStartDate,
      previousQuarterCost,
      selectedCloudSpendData,
      costByRegionData,
      costByRegionStartDate,
      costByRegionEndDate,
      isCostByRegionTableView,
      selectedMonthlyCostByCompartmentData,
      selectedMonthlyCostByServiceData,
      selectedTrendMonth,
      selectedTrendService,
      taggedUntaggedSpendData,
      taggedUntaggedSpendEndMonth,
      taggedUntaggedSpendStartMonth,
      taggedUntaggedTableData,
    ]
  );

  return (
    <>
      <ControlComponent
        filters={[
          {
            title: t('listByDimensions'),
            filter: (
              <DropdownCheckbox
                itemOptions={CHART_CRITERIA_LIST}
                selectedItems={chartCriteriaFilters}
                setSelectedItems={setChartCriteriaFilters}
                designVersion2
                size={INPUT_SIZE.SMALL}
              />
            ),
            minimizedText:
              chartCriteriaFilters.length === CHART_CRITERIA_LIST.length
                ? t('all')
                : CHART_CRITERIA_LIST.filter((each) =>
                    chartCriteriaFilters.includes(each.value)
                  )
                    .map((each) => each.title)
                    .join(', '),
            onClear: () => setChartCriteriaFilters([]),
          },
        ]}
      />
      <div className="oci-cost-summary inner-dashboard-content flex flex-column flex-gap-24">
        <TagFilters />
        <DashboardGraphContainer
          getGraphComponent={getGraphComponent}
          chartCriteriaFilters={chartCriteriaFilters}
          rows={GraphRows}
          pdfMetaData={getPdfMetaData(
            t('dashNav.costSummary'),
            selectedDashboard!,
            selectedConnection!
          )}
          costCardComponents={getCostComparisonCards()}
        />
      </div>
    </>
  );
};

export default OCICostSummaryDashboard;
