import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { selectPurchase, setAwsEc2RisCartData } from 'redux/purchaseSlice';
import { selectNavMenu } from 'redux/activeNavMenuSlice';
import EmptyCart from 'pages/PurchasePage/components/EmptyCart';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';
import { purchaseAwsEc2Ris } from 'pages/PurchasePage/components/ReservedInstances/services';
import { PurchaseAwsEc2RisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import { PurchaseCartComponents } from 'pages/PurchasePage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getTotalOnetimePayment } from 'pages/PurchasePage/components/ReservedInstances/utils';

import BuyEc2RiFormModal from '../BuyEc2RiFormModal';
import PurchasedEc2RICard from '../PurchasedEc2RICard';
import {
  getEc2RiTotalDue,
  getEc2RiTotalMonthlyRecurringCost,
} from '../../utils';

import './index.scss';

type Ec2RiCartProps = {
  setEc2RiCartComponent: (val: PurchaseCartComponents) => void;
};

const Ec2RiCart = ({ setEc2RiCartComponent }: Ec2RiCartProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { awsEc2RiCartData, selectedConnection } = useSelector(selectPurchase);
  const { isSubMenuOpen } = useSelector(selectNavMenu);

  const [showEc2RiForm, setShowEc2RiForm] = useState(false);
  const [placeOrderRequestStatus, setPlaceOrderRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  /**
   * @function purchaseEc2RIs
   * @description Function to purchase the EC2 RIs
   */
  const _purchaseEc2RIs = (cartData: PurchaseAwsEc2RisType[]) => {
    const requests = cartData.map((item) => {
      const requestBody = {
        riOfferingId: item.offeringId,
        count: item.quantity,
      };

      const params = {
        connectorId: selectedConnection?.connectorId,
        region: item.region,
      };

      return purchaseAwsEc2Ris(requestBody, params);
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        if (responses?.every((res: any) => res?.status === 200)) {
          setEc2RiCartComponent(PurchaseCartComponents.SUCCESS);
          setPlaceOrderRequestStatus(REQUEST_STATUS.SUCCESS);
          dispatch(setAwsEc2RisCartData([]));
          return;
        }
        setEc2RiCartComponent(PurchaseCartComponents.ERROR);
        setPlaceOrderRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setPlaceOrderRequestStatus);
        setEc2RiCartComponent(PurchaseCartComponents.ERROR);
      });
  };

  const getComponent = () => {
    if (awsEc2RiCartData.length === 0) {
      return (
        <EmptyCart
          description={t(
            'purchasePage.reservedInstancesLabels.emptyRiCartDescription'
          )}
          buttonTitle={t(
            'purchasePage.reservedInstancesLabels.addReservedInstances'
          )}
          setOpenForm={setShowEc2RiForm}
        />
      );
    }

    return (
      <div className="aws-ec2-ri-cart flex flex-column flex-gap-16">
        <div className="flex flex-align-items-center flex-space-between">
          <div>
            <div className="modal-heading">
              {t(
                'purchasePage.reservedInstancesLabels.purchaseReservedInstances'
              )}
            </div>
            <div className="cart-heading-subtitle small-grey-button">
              {t('purchasePage.cart.cartHeadingSubTitle')}
            </div>
          </div>
          <Button
            title={t('purchasePage.cart.addNew')}
            type={BUTTON_TYPE.NEUTRAL}
            onClick={() => setShowEc2RiForm(true)}
          />
        </div>
        <div className="cart-list flex flex-column flex-gap-10">
          {awsEc2RiCartData
            .map((item, index) => ({ ...item, key: index }))
            .map((data, index) => (
              <PurchasedEc2RICard
                ec2RiData={data}
                index={index}
                key={data.key}
              />
            ))}
        </div>
        <PurchaseFooter
          totalHeading={t(
            'purchasePage.reservedInstancesLabels.riTotalDueNow',
            {
              count: awsEc2RiCartData.length,
            }
          )}
          totalValue={getEc2RiTotalDue(awsEc2RiCartData)}
          costSummary={[
            {
              key: t(
                'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
              ),
              value: getEc2RiTotalMonthlyRecurringCost(awsEc2RiCartData),
            },
            {
              key: t('purchasePage.reservedInstancesLabels.oneTimePayment'),
              value: getTotalOnetimePayment(awsEc2RiCartData),
            },
          ]}
          okTitle={t('purchasePage.cart.placeOrder')}
          onClickCancel={() => navigate(NAVIGATION_MENU_PATH.COMMITMENTS)}
          onClickOk={() => {
            setEc2RiCartComponent(PurchaseCartComponents.SUCCESS);
            setPlaceOrderRequestStatus(REQUEST_STATUS.SUCCESS);
            dispatch(setAwsEc2RisCartData([]));
          }}
          loadingOk={placeOrderRequestStatus === REQUEST_STATUS.PROCESSING}
          className={`cart-footer ${isSubMenuOpen && 'nav-pinned'}`}
        />
      </div>
    );
  };

  return (
    <section>
      {getComponent()}
      {showEc2RiForm && (
        <BuyEc2RiFormModal show={showEc2RiForm} setShow={setShowEc2RiForm} />
      )}
    </section>
  );
};

export default Ec2RiCart;
