import { Collapse, Empty } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';

import { LoadingIcon } from 'assets/icons';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { INPUT_SIZE } from 'constants/appearance';
import { DASHBOARD_TYPES } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { TagMappingType } from 'pages/TagMappingPage/types';
import { selectTheme } from 'redux/themeSlice';
import {
  selectDimensionMapping,
  setAllConnectionsMap,
} from 'redux/dimensionMappingSlice';
import { ConnectionListType } from 'types/dashboard';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { getLabels } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

import TagComponent from './components/TagComponent';

import './index.scss';

const { Panel } = Collapse;

type ConnectionsTagDropdownProps = {
  tagMapData: TagMappingType;
  setTagMapData: (val: TagMappingType) => void;
};

const ConnectionsTagDropdown = ({
  tagMapData,
  setTagMapData,
}: ConnectionsTagDropdownProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { theme } = useSelector(selectTheme);
  const { allConnectionsMap } = useSelector(selectDimensionMapping);

  const [searchKey, setSearchKey] = useState('');

  /**
   * @function fetchConnectionTags
   * @description Function to fetch the tags for the connection
   * @param connectorId connectorId of the connection
   */
  const fetchConnectionTags = (connectorId: string) => {
    // If the tags are already fetched for the connection, then return
    if (
      !connectorId ||
      allConnectionsMap.find(
        (item) => item.connection.connectorId === connectorId
      )?.tags !== undefined
    )
      return;

    const params = {
      connectorId: connectorId,
      dashBoardType: DASHBOARD_TYPES.BILLING,
    };

    getLabels(params)
      .then((res: any) => {
        let data: any[] = [];
        if (res?.status === 200) {
          const provider = allConnectionsMap.find(
            (item) => item.connection.connectorId === connectorId
          )?.connection.provider;
          if (provider === PROVIDER.GCP)
            data = res.data.map((eachTag: any) => eachTag.label_keys);
          if (provider === PROVIDER.AWS)
            data = res.data.map((eachTag: any) => eachTag.field);
          if (provider === PROVIDER.AZURE)
            data = res.data.map((eachTag: any) => eachTag.label_keys);
        }
        setUpdatedTagMap(data, connectorId);
      })
      .catch((e) => {
        onApiCallError(e, true);
        setUpdatedTagMap([], connectorId);
      });
  };

  /**
   * @function setUpdatedTagMap
   * @description Function to update the tags for the connection in redux
   * @param tags tags to be updated
   * @param connectorId connectorId of the connection
   */
  const setUpdatedTagMap = (tags: string[], connectorId: string) =>
    dispatch(
      setAllConnectionsMap(
        allConnectionsMap.map((item) => {
          if (item.connection.connectorId === connectorId) {
            return {
              ...item,
              tags: tags,
            };
          }
          return item;
        })
      )
    );

  /**
   * @function findTagPriority
   * @description Function to find the priority of the tag selected
   * @param tag tag for which the priority is retrieved
   * @param connection Connection in which the tag is present
   * @returns integer priority of the tag if selected else undefined
   */
  const findTagPriority = (tag: string, connection: ConnectionListType) => {
    return tagMapData.cloudMapList
      .find(
        (eachConnection) =>
          eachConnection.connectionId === connection.connectorId
      )
      ?.values?.find((item) => item.fieldName === tag)?.priority;
  };

  /**
   * @function getConnectionsAfterUpdate
   * @description Function to update the connections with the tag selection
   * @param tag tag for to be updated (add or remove)
   * @param connection Connection to which the tag is updated
   * @param priority priority of the tag updated
   * @returns List of updated connections
   */
  const getConnectionsAfterUpdate = (
    tag: string,
    connection: ConnectionListType,
    priority?: number
  ) => {
    const connectionsList = [...tagMapData.cloudMapList];
    const connectionIndex = connectionsList.findIndex(
      (item) => item.connectionId === connection.connectorId
    );
    const existingConnection = connectionsList.find(
      (item) => item.connectionId === connection.connectorId
    );

    // Remove tag when exists and remove connection when no tag selected
    if (priority && existingConnection) {
      const fields = [...existingConnection.values];
      fields.splice(
        fields.findIndex((item) => item.fieldName === tag),
        1
      );

      if (fields.length > 0) {
        connectionsList.splice(connectionIndex, 1, {
          ...existingConnection,
          values: fields.map((item, index) => ({
            ...item,
            priority: index + 1,
          })),
        });
      } else {
        connectionsList.splice(connectionIndex, 1);
      }

      return connectionsList;
    }

    // Add tag for an existing connection
    if (existingConnection) {
      connectionsList.splice(connectionIndex, 1, {
        ...existingConnection,
        values: [
          ...existingConnection.values,
          { fieldName: tag, priority: existingConnection.values.length + 1 },
        ],
      });

      return connectionsList;
    }

    // Add connection when there is no connection
    connectionsList.splice(connectionsList.length, 1, {
      connectionName: connection.displayName ?? connection.name,
      connectionId: connection.connectorId,
      providerName: connection.provider,
      values: [{ fieldName: tag, priority: 1 }],
    });

    return connectionsList;
  };

  /**
   * @function onClickTag
   * @description Callback function for selection and deselection of the tag
   * @param tag Tag clicked
   * @param connection Connection to which the tag belongs to
   */
  const onClickTag = (tag: string, connection: ConnectionListType) => {
    const priority = findTagPriority(tag, connection);
    const connectionList = getConnectionsAfterUpdate(tag, connection, priority);
    setTagMapData({
      ...tagMapData,
      cloudMapList: connectionList,
    });
  };

  /**
   * @function isConnectionIncludeSearchKey
   * @description Function to check if the search key is included in the connection name
   * @param connectionName Name of the connection to be searched for
   */
  const isConnectionIncludeSearchKey = (connectionName: string) => {
    return connectionName
      ?.toLowerCase()
      ?.includes(searchKey?.trim()?.toLowerCase());
  };

  /**
   * @function filterConnections
   * @description Function to to filter the connections by the search key
   * @returns List of connection tag data
   */
  const filterConnections = () => {
    return allConnectionsMap.filter(
      (item) =>
        isConnectionIncludeSearchKey(item.connection.name) ||
        item.tags?.some((eachTag) =>
          eachTag.toLowerCase().includes(searchKey.trim().toLowerCase())
        )
    );
  };

  /**
   * @function filterTags
   * @description Function to to filter the tags by the search key
   * @param connectionTagsData connection tags data to be filtered.
   * @returns List of tags data
   */
  const filterTags = (tags: string[], connection: ConnectionListType) => {
    if (
      isConnectionIncludeSearchKey(connection.displayName ?? connection.name)
    ) {
      return tags;
    }

    return tags.filter((eachTag) =>
      eachTag.toLowerCase().includes(searchKey.trim().toLowerCase())
    );
  };

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="collapse-arrow" />
    );

  /**
   * @function getTagsListComponent
   * @description Function to return the tags list component
   * @param tags string array of tags or undefined
   * @param connection connection for which the tags are retrieved
   * @returns JSX element
   */
  const getTagsListComponent = (
    tags: string[] | undefined,
    connection: ConnectionListType
  ) => {
    if (tags === undefined)
      return (
        <div className="flex flex-center">
          <Icon
            icon={LoadingIcon}
            className="rotate"
            color={theme.buttonIconColor}
          />
        </div>
      );
    if (tags.length === 0)
      return (
        <div className="flex flex-center">
          <Empty
            className="flex flex-column flex-justify-content-center"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('tagMapping.noTags')}
          />
        </div>
      );
    return (
      <div className="flex flex-column flex-gap-8">
        {filterTags(tags, connection).map((tag) => (
          <TagComponent
            key={tag}
            tag={tag}
            priority={findTagPriority(tag, connection)}
            onClickTag={() => onClickTag(tag, connection)}
          />
        ))}
      </div>
    );
  };

  /**
   * @function getSelectedTagCountByConnectorId
   * @description Function to get the selected tag count by connectorId
   * @param connectorId connectorId of the connection
   * @returns integer count of the selected tags
   */
  const getSelectedTagCountByConnectorId = (connectorId: string) =>
    tagMapData.cloudMapList.find(
      (eachConnection) => eachConnection.connectionId === connectorId
    )?.values.length;

  return (
    <div className="connection-tag-dropdown">
      <Input
        className="full-width"
        type="search"
        value={searchKey}
        onChange={(e: any) => setSearchKey(e.target.value)}
        placeholder={t('tagMapping.searchConnectionOrField')}
        size={INPUT_SIZE.SMALL}
      />
      <Collapse
        className="connection-tag-collapse new-styled-scroll full-width flex flex-column"
        accordion={true}
        bordered={false}
        expandIcon={getExpandIcon}
        onChange={(e) => {
          fetchConnectionTags(e.at(0) ?? '');
        }}
      >
        {filterConnections().map((item) => (
          <Panel
            className="connection-tag-panel"
            header={
              <div className="flex flex-align-items-center flex-gap-8">
                <div>
                  <img
                    src={getProviderSmallLogo(item.connection.provider)}
                    alt={`${item.connection.provider} Logo`}
                  />
                </div>
                <span className="table-typography">
                  {item.connection.displayName ?? item.connection.name}
                </span>
              </div>
            }
            key={item.connection.connectorId}
            extra={
              getSelectedTagCountByConnectorId(item.connection.connectorId) ? (
                <div className="font-small-bold flex flex-center values-count">
                  {getSelectedTagCountByConnectorId(
                    item.connection.connectorId
                  )}
                </div>
              ) : null
            }
          >
            {getTagsListComponent(item.tags, item.connection)}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ConnectionsTagDropdown;
