import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';

import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import DatePicker from 'components/DatePicker';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ColumnChart from 'components/ColumnChart';
import { generateGraphColors } from 'utils/dashboardUtils';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { MonthlyCostByCompartmentType } from 'types/dataTypes';

import { getUniqueCompartments } from './utils';
import { getMonthlyCostByCompartmentColumns } from '../../utils';

type MonthlyCostByCompartmentProps = {
  setSelectedMonthlyCostByCompartmentData: (
    val: MonthlyCostByCompartmentType[]
  ) => void;
  selectedMonthlyCostByCompartmentData: MonthlyCostByCompartmentType[];
  requestStatus: string;
  monthWiseCostByCompartment: MonthlyCostByCompartmentType[];
  isMonthlyCostByCompartmentTableView: boolean;
  setIsMonthlyCostByCompartmentTableView: (value: boolean) => void;
  pdfView?: boolean;
  monthlyCostByCompartmentStartDate: string;
  monthlyCostByCompartmentEndDate: string;
  onChangeCostByCompartmentDateRange: (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};
const MonthlyCostByCompartment = ({
  setSelectedMonthlyCostByCompartmentData,
  requestStatus,
  isMonthlyCostByCompartmentTableView,
  selectedMonthlyCostByCompartmentData,
  monthWiseCostByCompartment,
  setIsMonthlyCostByCompartmentTableView,
  pdfView,
  monthlyCostByCompartmentStartDate,
  monthlyCostByCompartmentEndDate,
  onChangeCostByCompartmentDateRange,
}: MonthlyCostByCompartmentProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(monthlyCostByCompartmentStartDate),
          moment(monthlyCostByCompartmentEndDate),
        ]}
        onChange={onChangeCostByCompartmentDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
      <GraphFilterDropdown
        allData={getUniqueCompartments(monthWiseCostByCompartment)}
        selectedData={getUniqueCompartments(
          selectedMonthlyCostByCompartmentData
        )}
        setSelectedData={(selectedItems: MonthlyCostByCompartmentType[]) =>
          setSelectedMonthlyCostByCompartmentData(
            monthWiseCostByCompartment.filter((eachCompartment) =>
              selectedItems.some(
                (item) => item.compartment === eachCompartment.compartment
              )
            )
          )
        }
        valueSuffix={t('compartment')}
        fieldName={DROPDOWN_VALUE_FIELDS.COMPARTMENT}
        designVersion2
      />
    </div>
  );

  const getComponent = () =>
    isMonthlyCostByCompartmentTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={getTableDataSource().map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={getMonthlyCostByCompartmentColumns(
            monthlyCostByCompartmentStartDate,
            monthlyCostByCompartmentEndDate
          )}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={selectedMonthlyCostByCompartmentData}
        isStack={true}
        xField="month"
        yField="cost"
        groupingField="compartment"
        xTitle={t('month')}
        yTitle={t('costInCurrency', {
          currencySymbol: currencySymbol,
        })}
        colorField="compartment"
        columnColorOverride={generateGraphColors(
          selectedMonthlyCostByCompartmentData.length
        )}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  /**
   * @function getTableDataSource
   * @description function to get table data source grouped by compartment
   * @returns object array of table data source
   */
  const getTableDataSource = () => {
    const groupedByCompartment: any[] = [];
    selectedMonthlyCostByCompartmentData.forEach((item) => {
      const existingCompartment = groupedByCompartment.find(
        (compartment) => compartment.compartment === item.compartment
      );
      if (existingCompartment) {
        existingCompartment[`${item.month}-cost`] = item.cost;
      } else {
        groupedByCompartment.push({
          compartment: item.compartment,
          [`${item.month}-cost`]: item.cost,
        });
      }
    });
    return groupedByCompartment;
  };

  return (
    <div
      className="graph-card monthly-cost-by-compartments"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.monthlyCostByCompartments')}
        graphName="monthly-cost-by-compartments"
        filters={filters}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isMonthlyCostByCompartmentTableView}
        setIsTableView={setIsMonthlyCostByCompartmentTableView}
        excelData={getChartExcelExportData(
          t('graphHeadings.monthlyCostByCompartments'),
          getTableDataSource(),
          getMonthlyCostByCompartmentColumns(
            monthlyCostByCompartmentStartDate,
            monthlyCostByCompartmentEndDate
          ),
          {
            connectionName: selectedDashboard!.connectorName,
            startDate: monthlyCostByCompartmentStartDate,
            endDate: monthlyCostByCompartmentEndDate,
          }
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default MonthlyCostByCompartment;
