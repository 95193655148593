import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { GProfilerIcon } from 'assets/icons';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  selectGProfilerInsights,
  setGProfilerServices,
  setGProfilerServicesReqStatus,
  setSelectedService,
} from 'redux/gProfilerInsights';
import { onApiCallError } from 'utils/handleErrors';
import {
  getGProfilerDashboardDeployedServices,
  getGProfilerDashboardServices,
} from 'utils/services';
import { StatusEnum } from 'constants/gProfiler';

import ServiceListModal from '../ServiceListModal';
import { ImpactCategoryLabels } from '../../constants';
import { getImpactCategory } from '../../utils';

import './index.scss';

const GprofilerInsightsHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedService } = useSelector(selectGProfilerInsights);

  const [showServicesModal, setShowServicesModal] = useState(false);

  useEffect(() => {
    fetchGProfilerServices();
  }, []);

  const fetchGProfilerServices = () => {
    dispatch(setGProfilerServicesReqStatus(REQUEST_STATUS.PROCESSING));
    const requests = [
      getGProfilerDashboardServices(),
      getGProfilerDashboardDeployedServices(),
    ];

    axios
      .all(requests)
      .then((responses: any) => {
        if (responses.every((res: any) => res.status === 200)) {
          const list: any = [];
          responses[1]?.data?.forEach((item: any, index: number) => {
            const connectionData = responses[0]?.data?.find(
              (service: any) => service.serviceName === item.serviceName
            );
            if (
              connectionData &&
              connectionData.status === StatusEnum.COMPLETED
            ) {
              list.push({
                key: (index + 1).toString(),
                connectionName: item.connectionName,
                provider: item.cloud,
                createdDate: item.creationDate,
                serviceName: item.serviceDisplayName,
                optimizationPotential: getImpactCategory(item?.impactRate),
              });
            }
          });
          if (list.length > 0) {
            dispatch(setSelectedService(list[0]));
          }
          dispatch(setGProfilerServices(list));
          dispatch(setGProfilerServicesReqStatus(REQUEST_STATUS.SUCCESS));
          return;
        }
        dispatch(setGProfilerServicesReqStatus(REQUEST_STATUS.ERROR));
      })
      .catch((e) => {
        onApiCallError(e, false, (val) =>
          dispatch(setGProfilerServicesReqStatus(val))
        );
      });
  };

  return (
    <header className="gProfiler-insights-dashboard-header new-page-header flex flex-align-items-center flex-space-between">
      <div className="flex flex-align-items-center font-caption-bold flex-gap-12">
        <Icon icon={GProfilerIcon} className="icon" />
        <div className="text-group flex flex-column">
          <span className="heading-text modal-heading">
            {t('gprofilerInsightsDashboard.gProfilerInsightsDashboard')}
          </span>
          <div className="flex flex-align-items-center flex-gap-4">
            <span className="font-caption-bold">
              {selectedService?.serviceName}
            </span>
            {selectedService?.optimizationPotential && (
              <span className="optimization-potential font-caption-bold">
                {
                  ImpactCategoryLabels.find(
                    (potential) =>
                      potential.key === selectedService?.optimizationPotential
                  )?.label
                }
              </span>
            )}
          </div>
        </div>
      </div>
      <Button
        title={t('gprofilerInsightsDashboard.switchDashboard')}
        onClick={() => {
          setShowServicesModal(true);
        }}
        iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
      />
      {showServicesModal && (
        <ServiceListModal
          show={showServicesModal}
          setShow={setShowServicesModal}
        />
      )}
    </header>
  );
};

export default GprofilerInsightsHeader;
