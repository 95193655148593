import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { providerList } from 'redux/providerSlice';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import SelectDropdown from 'components/Select';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import {
  validateDuplicateConnectionName,
  validateInputLength,
} from 'pages/ConnectingCSPPage/utils';
import { PROVIDER } from 'constants/cloudProviders';
import { FREE_TEXT_CHARACTER_LIMIT } from 'constants/validation';
import {
  getDataSourceTypesByCloudProvider,
  getPlanTypeOptionsByProvider,
} from './utils';
import MigratedFromCloudProviderInput from '../MigratedFromCloudProviderInput';

const { Option } = Select;

type BasicDetailsProps = {
  setIsDuplicateConnectionName: (val: boolean) => void;
  connectionName?: string;
};

const BasicDetails = ({
  setIsDuplicateConnectionName,
  connectionName,
}: BasicDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection } = useSelector(cloudConnection);
  const { selectedProvider } = useSelector(providerList);

  const [connectionNameValidation, setConnectionNameValidation] = useState('');
  const [dataSourceValidation, setDataSourceValidation] = useState('');
  const [descriptionValidation, setDescriptionValidation] = useState('');
  const [planTypeValidation, setPlanTypeValidation] = useState('');

  const DataSourceTypeDropdown = (
    <>
      {getDataSourceTypesByCloudProvider(selectedProvider)?.map((options) => (
        <Option key={options.title} value={options.value}>
          {options.title}
        </Option>
      ))}
    </>
  );

  const validateConnectionName = (
    value: string,
    validateDuplicate?: boolean
  ) => {
    if (
      validateEmptyField(
        value,
        t(`connectionCSPForm.connectionName`),
        setConnectionNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value,
        t(`connectionCSPForm.connectionName`),
        setConnectionNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateInputLength(
        value,
        t(`connectionCSPForm.connectionName`),
        setConnectionNameValidation
      )
    ) {
      return false;
    }

    if (validateDuplicate) {
      validateDuplicateConnectionName(
        value,
        setConnectionNameValidation,
        setIsDuplicateConnectionName,
        isEditConnection,
        connectionName
      );
    }

    return true;
  };

  /**
   * @function validateDescription
   * @description Function to validate the description field
   * @param value Value to be validated
   * @returns boolean true if validation is successful else false
   */
  const validateDescription = (value: string) => {
    return !validateStringLengthLessThan(
      value,
      FREE_TEXT_CHARACTER_LIMIT,
      t('connectionCSPForm.description'),
      setDescriptionValidation
    );
  };

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`connectionCSPForm.dataSourceType`)}
          required={true}
        />
        <SelectDropdown
          value={currentConnection?.dataSourceType}
          menu={DataSourceTypeDropdown}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                dataSourceType: value,
              })
            );
            validateEmptyField(
              value,
              t(`connectionCSPForm.dataSourceType`),
              setDataSourceValidation
            );
          }}
          onBlur={() => {
            validateEmptyField(
              currentConnection?.dataSourceType,
              t('connectionCSPForm.dataSourceType'),
              setDataSourceValidation
            );
          }}
          designVersion2
        />
        <span
          style={{
            display: getValidationStyle(dataSourceValidation),
          }}
          className="font-validation-error"
        >
          {dataSourceValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`connectionCSPForm.connectionName`)}
          required={true}
        />
        <Input
          default
          type="input"
          value={currentConnection?.displayName}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                displayName: e.target.value,
              })
            );
            !(
              isEditConnection &&
              currentConnection?.displayName === connectionName
            ) && validateConnectionName(e.target.value);
            setIsDuplicateConnectionName(true);
          }}
          onBlur={(e: any) =>
            !(
              isEditConnection &&
              currentConnection?.displayName === connectionName
            ) && validateConnectionName(e.target.value, true)
          }
          data-testid="name-input"
        />
        <span
          style={{
            display: getValidationStyle(connectionNameValidation),
          }}
          className="font-validation-error"
        >
          {connectionNameValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t(`connectionCSPForm.description`)} />
        <Input
          type="textarea"
          autoSize={{ minRows: 3, maxRows: 6 }}
          value={currentConnection?.description}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                description: e.target.value,
              })
            );
            validateDescription(e.target.value);
          }}
          onBlur={() => validateDescription(currentConnection?.description)}
          data-testid="description-input"
        />
        <span
          style={{
            display: getValidationStyle(descriptionValidation),
          }}
          className="font-validation-error"
        >
          {descriptionValidation}
        </span>
      </div>
      {[PROVIDER.AWS, PROVIDER.AZURE].includes(
        selectedProvider as PROVIDER
      ) && (
        <div className="form-item flex flex-column">
          <FormLabel title={t(`connectionCSPForm.planType`)} required={true} />
          <SelectDropdown
            value={currentConnection?.planType}
            options={getPlanTypeOptionsByProvider(selectedProvider)}
            onSelect={(value: string) => {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  planType: value,
                })
              );
              validateEmptyField(
                value,
                t('connectionCSPForm.planType'),
                setPlanTypeValidation
              );
            }}
            onBlur={() => {
              validateEmptyField(
                currentConnection?.planType,
                t('connectionCSPForm.planType'),
                setPlanTypeValidation
              );
            }}
            designVersion2
          />
          <span
            style={{
              display: getValidationStyle(planTypeValidation),
            }}
            className="font-validation-error"
          >
            {planTypeValidation}
          </span>
        </div>
      )}
      {selectedProvider === PROVIDER.GCP && <MigratedFromCloudProviderInput />}
    </div>
  );
};

export default BasicDetails;
