export const GRAPH_ADDITIONAL_DROPDOWN = [
  { key: 'top10', title: 'top10', count: 10 },
  { key: 'top20', title: 'top20', count: 20 },
];

export const DROPDOWN_VALUE_FIELDS = {
  PROJECT: 'project',
  SERVICE: 'service',
  REGION: 'region',
  RESOURCE: 'resource',
  RESOURCE_GROUP: 'resource_group',
  MONTH: 'month',
  BUSINESS_UNIT: 'businessUnit',
  COST_CENTER: 'costCenter',
  COMPARTMENT: 'compartment',
  NAMESPACE: 'namespace',
  NODE: 'node',
  POD: 'pod',
  DATABASE: 'database',
  ACCOUNT: 'account',
  WAREHOUSE: 'warehouse',
  NAME: 'name',
};
