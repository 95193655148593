import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import {
  getTableSorts,
  updateSortQuery,
} from 'pages/CustomDashboardPage/utils';
import { customDashboard } from 'redux/customDashboardSlice';
import { OrderByType } from 'types/dashboard';

import SortList from '../SortList';

import './index.scss';

const Sort = () => {
  const { t } = useTranslation();
  const { selectedMetrics, selectedDimensions } = useSelector(customDashboard);

  const onClickAddSort = () => {
    const oldSorts = getTableSorts() || [];
    if (oldSorts.some((sort) => !sort.label || !sort.sort)) return;
    const newSorts: OrderByType[] = [...oldSorts, { label: '', sort: '' }];
    updateSortQuery(newSorts);
  };

  /**
   * @canAddSorts
   * @description Function to check if there is atleast one dimension or metric to add and there are less than 3 sorts
   * @returns Returns true if there is atleast one dimension or metric to add and there are less than 3 sorts
   */
  const canAddSorts = () => {
    const dimensionsToAdd = selectedDimensions.filter(
      (dimension) =>
        getTableSorts().findIndex((sort) => sort.label === dimension.field) ===
        -1
    );
    const metricsToAdd = selectedMetrics.filter(
      (metric) =>
        getTableSorts().findIndex((sort) => sort.label === metric.field) === -1
    );
    return (
      (dimensionsToAdd.length > 0 || metricsToAdd.length > 0) &&
      getTableSorts().length < 3
    );
  };

  return (
    <div className="table-sort">
      <div className="sort-heading flex flex-align-items-center flex-space-between">
        <div className="font-caption-bold">
          {t('customDashboard.optionsLabels.sort')}
        </div>
        {canAddSorts() && (
          <Icon
            className="add-icon"
            iconName={ICONS.ADD_LINE}
            onClick={onClickAddSort}
          />
        )}
      </div>
      <SortList />
    </div>
  );
};

export default Sort;
