import { useTranslation } from 'react-i18next';

import { getChartConditions } from 'pages/CustomDashboardPage/utils';

import ConditionListItem from './components/ConditionsListItem';
import './index.scss';

const ConditionsList = () => {
  const { t } = useTranslation();

  return (
    <div className="conditions-list">
      {getChartConditions()?.length === 0 ? (
        <div className="empty-conditions-message font-subHeader-small">
          {t('customDashboard.optionsLabels.clickToAddCondition')}
        </div>
      ) : (
        <div className="flex flex-column flex-gap-8">
          {getChartConditions().map((condition, index) => (
            <ConditionListItem
              condition={condition}
              index={index}
              key={`${condition.field}${condition.comparator}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ConditionsList;
