import { useSelector } from 'react-redux';
import { providerList } from 'redux/providerSlice';
import { getProviderSmallLogo } from 'utils/providerDetails';

type FormHeaderProps = {
  title: string;
  description: string;
};

const FormHeader = ({ title, description }: FormHeaderProps) => {
  const { selectedProvider } = useSelector(providerList);
  return (
    <div className="csp-form-header flex flex-column flex-gap-8 flex-center">
      <img src={getProviderSmallLogo(selectedProvider)} alt="Provider Icon" />
      <span className="modal-heading">{title}</span>
      <span className="table-typography">{description}</span>
    </div>
  );
};

export default FormHeader;
