import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import {
  cloudConnection,
  setAccessDetails,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getAccessDetails } from 'pages/ConnectingCSPPage/services';
import { ACCESS_DATA_VALUES } from 'pages/ConnectingCSPPage/constants';
import { onApiCallError } from 'utils/handleErrors';
import { PROVIDER } from 'constants/cloudProviders';
import { validateEmptyField } from 'utils/validations';
import { DEBOUNCE_TIME_DELAY } from 'constants/userConsole';
import AccessSummaryTable from 'pages/ConnectingCSPPage/components/AccessSummaryTable';

const AccessDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection, accessDetails } =
    useSelector(cloudConnection);

  const [
    azureSubscriptionIdRequiredValidation,
    setAzureSubscriptionIdRequiredValidation,
  ] = useState('');
  const [azureAppIdRequiredValidation, setAzureAppIdRequiredValidation] =
    useState('');
  const [azureTenantIdRequiredValidation, setAzureTenantIdRequiredValidation] =
    useState('');
  const [
    azureClientSecretRequiredValidation,
    setAzureClientSecretRequiredValidation,
  ] = useState('');
  const [accessDetailsRequestStatus, setAccessDetailsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    if (!isEditConnection)
      fetchConnectionAccessDetails(
        currentConnection?.azureTenantId,
        currentConnection?.azureSubscriptionId,
        currentConnection?.azureAppId,
        currentConnection?.azureClientSecret
      );
  }, []);

  /**
   * @function fetchConnectionAccessDetails
   * @description Function to fetch the access details
   */
  const fetchConnectionAccessDetails = (
    azureTenantId: string,
    azureSubscriptionId: string,
    azureAppId: string,
    azureClientSecret: string
  ) => {
    if (
      azureTenantId &&
      azureSubscriptionId &&
      azureAppId &&
      azureClientSecret
    ) {
      dispatch(
        setAccessDetails(
          accessDetails.map((item) => ({ ...item, haveAccess: false }))
        )
      );
      setAccessDetailsRequestStatus(REQUEST_STATUS.PROCESSING);
      const body = {
        azureTenantId: azureTenantId,
        azureSubId: azureSubscriptionId,
        azureClientId: azureAppId,
        azureClientSecret: azureClientSecret,
      };
      const param = { provider: PROVIDER.AZURE };
      getAccessDetails(body, param)
        .then((res: any) => {
          setAccessDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
          dispatch(
            setAccessDetails(
              Object.entries(res?.data?.responseData).map((objectArr) => {
                return {
                  role: objectArr[0],
                  haveAccess: objectArr[1] as boolean,
                };
              })
            )
          );
        })
        .catch((e) => onApiCallError(e, false, setAccessDetailsRequestStatus));
    }
  };

  /**
   * @function debouncedFetchConnectionAccessDetails
   * @description Function to debounce the fetchConnectionAccessDetails function
   * @param azureTenantId
   * @param azureSubscriptionId
   * @param azureAppId
   * @param azureClientSecret
   * @returns debounced function call
   */
  const debouncedFetchConnectionAccessDetails = useCallback(
    debounce(
      (azureTenantId, azureSubscriptionId, azureAppId, azureClientSecret) =>
        fetchConnectionAccessDetails(
          azureTenantId,
          azureSubscriptionId,
          azureAppId,
          azureClientSecret
        ),
      DEBOUNCE_TIME_DELAY
    ),
    []
  );

  return (
    <div className="flex flex-column">
      <div className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column flex-fit">
          <FormLabel
            title={t(`azureConnectionCSPForm.step2.tenantId`)}
            required={true}
          />
          <Input
            default
            type={isEditConnection ? 'password' : 'input'}
            placeholder={t(`azureConnectionCSPForm.step2.tenantIdPlaceholder`)}
            value={currentConnection?.azureTenantId}
            disabled={isEditConnection}
            onChange={(e: any) => {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  azureTenantId: e.target.value,
                })
              );
              setAzureTenantIdRequiredValidation('');
              debouncedFetchConnectionAccessDetails(
                e.target.value,
                currentConnection?.azureSubscriptionId,
                currentConnection?.azureAppId,
                currentConnection?.azureClientSecret
              );
            }}
            onBlur={(e: any) => {
              validateEmptyField(
                e.target.value,
                t('azureConnectionCSPForm.step2.tenantId'),
                setAzureTenantIdRequiredValidation
              );
            }}
          />
          <span
            style={{
              display: `${azureTenantIdRequiredValidation ? 'inline' : 'none'}`,
            }}
            className="font-validation-error"
          >
            {azureTenantIdRequiredValidation}
          </span>
        </div>
        <div className="form-item flex flex-column flex-fit">
          <FormLabel
            title={t(`azureConnectionCSPForm.step2.subscriptionId`)}
            required={true}
          />
          <Input
            default
            type={isEditConnection ? 'password' : 'input'}
            placeholder={t(
              `azureConnectionCSPForm.step2.subscriptionIdPlaceholder`
            )}
            value={currentConnection?.azureSubscriptionId}
            disabled={isEditConnection}
            onChange={(e: any) => {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  azureSubscriptionId: e.target.value,
                })
              );
              setAzureSubscriptionIdRequiredValidation('');
              debouncedFetchConnectionAccessDetails(
                currentConnection?.azureTenantId,
                e.target.value,
                currentConnection?.azureAppId,
                currentConnection?.azureClientSecret
              );
            }}
            onBlur={(e: any) => {
              validateEmptyField(
                e.target.value,
                t('azureConnectionCSPForm.step2.subscriptionId'),
                setAzureSubscriptionIdRequiredValidation
              );
            }}
          />
          <span
            style={{
              display: `${
                azureSubscriptionIdRequiredValidation ? 'inline' : 'none'
              }`,
            }}
            className="font-validation-error"
          >
            {azureSubscriptionIdRequiredValidation}
          </span>
        </div>
        <div className="form-item flex flex-column flex-fit">
          <FormLabel
            title={t(`azureConnectionCSPForm.step2.appId`)}
            required={true}
          />
          <Input
            default
            type={isEditConnection ? 'password' : 'input'}
            placeholder={t(`azureConnectionCSPForm.step2.appIdPlaceholder`)}
            value={currentConnection?.azureAppId}
            disabled={isEditConnection}
            onChange={(e: any) => {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  azureAppId: e.target.value,
                })
              );
              setAzureAppIdRequiredValidation('');
              debouncedFetchConnectionAccessDetails(
                currentConnection?.azureTenantId,
                currentConnection?.azureSubscriptionId,
                e.target.value,
                currentConnection?.azureClientSecret
              );
            }}
            onBlur={(e: any) => {
              validateEmptyField(
                e.target.value,
                t('azureConnectionCSPForm.step2.appId'),
                setAzureAppIdRequiredValidation
              );
            }}
          />
          <span
            style={{
              display: `${azureAppIdRequiredValidation ? 'inline' : 'none'}`,
            }}
            className="font-validation-error"
          >
            {azureAppIdRequiredValidation}
          </span>
        </div>
        <div className="form-item flex flex-column flex-fit">
          <FormLabel
            title={t(`azureConnectionCSPForm.step2.clientSecret`)}
            required={true}
          />
          <Input
            default
            type="password"
            placeholder={t(
              `azureConnectionCSPForm.step2.clientSecretPlaceholder`
            )}
            value={currentConnection?.azureClientSecret}
            disabled={isEditConnection}
            onChange={(e: any) => {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  azureClientSecret: e.target.value,
                })
              );
              setAzureClientSecretRequiredValidation('');
              debouncedFetchConnectionAccessDetails(
                currentConnection?.azureTenantId,
                currentConnection?.azureSubscriptionId,
                currentConnection?.azureAppId,
                e.target.value
              );
            }}
            onBlur={(e: any) => {
              validateEmptyField(
                e.target.value,
                t('azureConnectionCSPForm.step2.clientSecret'),
                setAzureClientSecretRequiredValidation
              );
            }}
          />
          <span
            style={{
              display: `${
                azureClientSecretRequiredValidation ? 'inline' : 'none'
              }`,
            }}
            className="font-validation-error"
          >
            {azureClientSecretRequiredValidation}
          </span>
        </div>
        {currentConnection?.azureTenantId &&
          currentConnection?.azureSubscriptionId &&
          currentConnection?.azureAppId &&
          currentConnection?.azureClientSecret &&
          !isEditConnection && (
            <>
              <div className="form-item flex flex-column">
                <FormLabel title={t(`connectionCSPForm.accessSummary`)} />
                <AccessSummaryTable
                  accessDetailsRequestStatus={accessDetailsRequestStatus}
                />
              </div>
              {!accessDetails.find(
                (value) => value.role === ACCESS_DATA_VALUES.HAS_BILLING_ACCESS
              )?.haveAccess &&
                accessDetailsRequestStatus !== REQUEST_STATUS.PROCESSING && (
                  <div className="error-message flex flex-justify-content-center font-caption">
                    {t('connectionCSPForm.invalidCredentials')}
                  </div>
                )}
            </>
          )}
      </div>
    </div>
  );
};

export default AccessDetails;
