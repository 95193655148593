import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import GraphHeader from 'components/GraphHeader';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import DatePicker from 'components/DatePicker';
import PointerMap from 'components/PointerMap';
import { CARBON_EMISSION_UNIT } from 'pages/OverviewPage/components/ConnectionsDashboard/components/CarbonFootprintDashboard/constants';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { ColorCarbonFootprintByRegion } from 'pages/OverviewPage/components/ConnectionsDashboard/components/CarbonFootprintDashboard/types';
import { selectDashboard } from 'redux/dashboardSlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { getRegionCoordinates } from 'utils/dashboardUtils';
import { DATE_FORMAT } from 'utils/date';
import { getGCPCarbonEmissionByRegionExcelData } from '../../utils/exportToExcel';

import './index.scss';

type CarbonEmissionByRegionProps = {
  carbonEmissionByRegionData: ColorCarbonFootprintByRegion[];
  selectedCarbonEmissionByRegionData: ColorCarbonFootprintByRegion[];
  setSelectedCarbonEmissionByRegionData: (
    val: ColorCarbonFootprintByRegion[]
  ) => void;
  requestStatus: string;
  regionStartDate: string;
  regionEndDate: string;
  onChangeRegionDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  pdfView: boolean;
};

const CarbonEmissionByRegion = ({
  carbonEmissionByRegionData,
  selectedCarbonEmissionByRegionData,
  setSelectedCarbonEmissionByRegionData,
  requestStatus,
  regionStartDate,
  regionEndDate,
  onChangeRegionDateRange,
  pdfView,
}: CarbonEmissionByRegionProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);

  const columns = [
    {
      title: '#',
      dataIndex: 'color',
      key: 'color',
      render: (text: string) => (
        <div className="color-block" style={{ backgroundColor: text }} />
      ),
      width: 30,
    },
    {
      title: t('carbonFootprint.monthlyCarbonEmission.region'),
      dataIndex: 'region',
      key: 'region',
      width: 70,
    },
    {
      title: t('carbonFootprint.monthlyCarbonEmission.carbonEmissionKgco2e'),
      dataIndex: 'carbonFootprint',
      key: 'carbonFootprint',
      width: 150,
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text || 0),
    },
  ];

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[moment(regionStartDate), moment(regionEndDate)]}
        onChange={onChangeRegionDateRange}
        disabledDate={(current: any) => current > moment().endOf('day')}
        format={DATE_FORMAT}
      />
      <GraphFilterDropdown
        allData={carbonEmissionByRegionData}
        selectedData={selectedCarbonEmissionByRegionData}
        setSelectedData={setSelectedCarbonEmissionByRegionData}
        valueSuffix={t('carbonFootprint.carbonEmissionByRegions.regions')}
        fieldName={DROPDOWN_VALUE_FIELDS.REGION}
        designVersion2
      />
    </div>
  );

  const getComponent = () => (
    <div className="graph flex flex-gap-16">
      <div className="width-30 full-height">
        <Table
          pagination={false}
          dataSource={selectedCarbonEmissionByRegionData.map(
            (value, index) => ({
              ...value,
              key: index,
            })
          )}
          columns={columns}
          scroll={{ y: 300 }}
          designVersion2
        />
      </div>
      <div
        className={`graph-area with-table width-70 ${pdfView && 'pdf-wrapper'}`}
      >
        <PointerMap
          data={selectedCarbonEmissionByRegionData
            .filter((item) => item.region !== 'Others')
            .map((item) => ({
              ...item,
              coordinates: getRegionCoordinates(item.region),
            }))}
          valueLabel={t('carbonFootprint.monthlyCarbonEmission.carbonEmission')}
          labelField="region"
          valueField="carbonFootprint"
          colorField="color"
          postfixSymbol={CARBON_EMISSION_UNIT}
        />
      </div>
    </div>
  );

  return (
    <div
      className="carbon-emission-by-regions map-card graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.grossCarbonEmissionsByRegion')}
        graphName="carbon-emission-by-regions"
        ignorePaddingBottom={true}
        isDownloadable={!pdfView}
        isTableView={false}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getGCPCarbonEmissionByRegionExcelData(
          selectedDashboard?.connectorName ?? '',
          selectedCarbonEmissionByRegionData,
          regionStartDate,
          regionEndDate
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default CarbonEmissionByRegion;
