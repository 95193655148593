import { CUSTOM_DASHBOARD_MODE } from 'constants/dashboard';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  defaultCustomDashboardDataSource,
  defaultCustomViewData,
  defaultSelectedChartIndex,
  defaultSelectedChartRow,
} from 'constants/defaultValues';
import { CustomDashboardChartTypes } from 'pages/CustomDashboardPage/constants';
import { TagMappingType } from 'pages/TagMappingPage/types';
import { GroupType } from 'pages/GroupsPage/types';
import { SnowflakeIntegrationType } from 'pages/IntegrationsPage/types';
import {
  AvailableCustomGroupFieldsType,
  AvailableFieldsType,
  ColumnType,
  ConnectionListType,
  CustomDashboardDataSourceType,
  CustomViewDataType,
} from 'types/dashboard';
import { KeyValueTypes } from 'types/dataTypes';
import { RootState } from './store';

export interface CustomDashboardState {
  customViewDimensions: AvailableFieldsType[];
  customViewMetrics: AvailableFieldsType[];
  selectedDimensions: ColumnType[];
  selectedMetrics: ColumnType[];
  customViewData: CustomViewDataType;
  selectedChartRow: number;
  selectedChartIndex: number;
  selectedDashboardCustomViews: CustomViewDataType[] | [];
  customDashBoardMode: string;
  customDashboardConnection: ConnectionListType | null;
  customDashboardGroup: GroupType | null;
  customDashboardIntegrationConnection: SnowflakeIntegrationType | null;
  customDashboardDataSource: CustomDashboardDataSourceType;
  isAddNewDashboard: boolean;
  selectedChartType: string;
  dashboardType: string;
  customGroupAvailableFields: AvailableCustomGroupFieldsType[];
  allFieldValuesMap: { field: string; values: string[] }[];
  tableChartData: { [key: string]: { chartData: any[]; tableData: any[] } };
  tagKeyValues: KeyValueTypes[];
  tagMaps: TagMappingType[];
}

const initialState: CustomDashboardState = {
  customViewDimensions: [],
  customViewMetrics: [],
  selectedDimensions: [],
  selectedMetrics: [],
  customViewData: defaultCustomViewData,
  selectedChartRow: defaultSelectedChartRow,
  selectedChartIndex: defaultSelectedChartIndex,
  selectedDashboardCustomViews: [],
  customDashBoardMode: CUSTOM_DASHBOARD_MODE.PUBLISHED,
  customDashboardConnection: null,
  customDashboardGroup: null,
  customDashboardIntegrationConnection: null,
  customDashboardDataSource: defaultCustomDashboardDataSource,
  isAddNewDashboard: false,
  selectedChartType: CustomDashboardChartTypes.TABLE,
  dashboardType: '',
  customGroupAvailableFields: [],
  allFieldValuesMap: [],
  tableChartData: {},
  tagKeyValues: [],
  tagMaps: [],
};

export const CustomDashboardSlice = createSlice({
  name: 'CustomDashboardSlice',
  initialState,
  reducers: {
    setCustomViewDimensions: (
      state,
      action: PayloadAction<AvailableFieldsType[]>
    ) => {
      state.customViewDimensions = action.payload;
    },
    setCustomViewMetrics: (
      state,
      action: PayloadAction<AvailableFieldsType[]>
    ) => {
      state.customViewMetrics = action.payload;
    },
    setSelectedDimensions: (state, action: PayloadAction<ColumnType[]>) => {
      state.selectedDimensions = action.payload;
    },
    setSelectedMetrics: (state, action: PayloadAction<ColumnType[]>) => {
      state.selectedMetrics = action.payload;
    },
    setCustomViewData: (state, action: PayloadAction<CustomViewDataType>) => {
      state.customViewData = action.payload;
    },
    setSelectedChartRow: (state, action: PayloadAction<number>) => {
      state.selectedChartRow = action.payload;
    },
    setSelectedChartIndex: (state, action: PayloadAction<number>) => {
      state.selectedChartIndex = action.payload;
    },
    setSelectedDashboardCustomViews: (
      state,
      action: PayloadAction<CustomViewDataType[] | []>
    ) => {
      state.selectedDashboardCustomViews = action.payload;
    },
    setCustomDashBoardMode: (state, action: PayloadAction<string>) => {
      state.customDashBoardMode = action.payload;
    },
    setCustomDashboardConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.customDashboardConnection = action.payload;
    },
    setCustomDashboardGroup: (
      state,
      action: PayloadAction<GroupType | null>
    ) => {
      state.customDashboardGroup = action.payload;
    },
    setCustomDashboardIntegrationConnection: (
      state,
      action: PayloadAction<SnowflakeIntegrationType | null>
    ) => {
      state.customDashboardIntegrationConnection = action.payload;
    },
    setCustomDashboardDataSource: (
      state,
      action: PayloadAction<CustomDashboardDataSourceType>
    ) => {
      state.customDashboardDataSource = action.payload;
    },
    setIsAddNewDashboard: (state, action: PayloadAction<boolean>) => {
      state.isAddNewDashboard = action.payload;
    },
    setSelectedChartType: (state, action: PayloadAction<string>) => {
      state.selectedChartType = action.payload;
    },
    setDashboardType: (state, action: PayloadAction<string>) => {
      state.dashboardType = action.payload;
    },
    setCustomGroupAvailableFields: (
      state,
      action: PayloadAction<AvailableCustomGroupFieldsType[]>
    ) => {
      state.customGroupAvailableFields = action.payload;
    },
    setAllFieldValuesMap: (
      state,
      action: PayloadAction<{ field: string; values: string[] }[]>
    ) => {
      state.allFieldValuesMap = action.payload;
    },
    setTableChartData: (
      state,
      action: PayloadAction<{
        [key: string]: { chartData: any[]; tableData: any[] };
      }>
    ) => {
      state.tableChartData = action.payload;
    },
    setTagKeyValues: (state, action: PayloadAction<KeyValueTypes[]>) => {
      state.tagKeyValues = action.payload;
    },
    setTagMaps: (state, action: PayloadAction<TagMappingType[]>) => {
      state.tagMaps = action.payload;
    },
  },
});

export const customDashboard = (state: RootState) => state.customDashboard;
export const {
  setCustomViewDimensions,
  setCustomViewMetrics,
  setSelectedDimensions,
  setSelectedMetrics,
  setCustomViewData,
  setSelectedChartRow,
  setSelectedChartIndex,
  setSelectedDashboardCustomViews,
  setCustomDashBoardMode,
  setCustomDashboardConnection,
  setCustomDashboardGroup,
  setCustomDashboardIntegrationConnection,
  setCustomDashboardDataSource,
  setIsAddNewDashboard,
  setSelectedChartType,
  setDashboardType,
  setCustomGroupAvailableFields,
  setAllFieldValuesMap,
  setTableChartData,
  setTagKeyValues,
  setTagMaps,
} = CustomDashboardSlice.actions;
export default CustomDashboardSlice.reducer;
