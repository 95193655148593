import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ComparisonCard from 'components/NewComparisonCard';
import { ComparisonListType } from 'types/dashboard';
import {
  getCurrentQuarterStartMonthAndYear,
  getFirstMonthAndCurrentYear,
  getPercentageDifference,
  getPreviousMonthAndYear,
  getPreviousQuarterEndMonthAndYear,
  getPreviousQuarterStartMonthAndYear,
  getProviderForConnection,
} from 'utils/dashboardUtils';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { YEAR_MONTH_WITHOUT_SEPARATOR } from 'utils/date';
import { getCostRequestBodyByProvider } from './utils';

const ConsolidatedComparisonCards = () => {
  const { t } = useTranslation();
  const { selectedGroupMetaData } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [costComparisonList, setCostComparisonList] = useState<
    ComparisonListType[]
  >([]);
  const [currentYearCost, setCurrentYearCost] = useState(0);
  const [currentYearCostRequestStatus, setCurrentYearCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [previousYearCost, setPreviousYearCost] = useState(0);
  const [previousYearCostRequestStatus, setPreviousYearCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [currentQuarterCost, setCurrentQuarterCost] = useState(0);
  const [currentQuarterCostRequestStatus, setCurrentQuarterCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [previousQuarterCost, setPreviousQuarterCost] = useState(0);
  const [
    previousQuarterCostRequestStatus,
    setPreviousQuarterCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [currentMonthCost, setCurrentMonthCost] = useState(0);
  const [currentMonthCostRequestStatus, setCurrentMonthCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [previousMonthCost, setPreviousMonthCost] = useState(0);
  const [previousMonthCostRequestStatus, setPreviousMonthCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [previousSecondMonthCost, setPreviousSecondMonthCost] = useState(0);
  const [
    previousSecondMonthCostRequestStatus,
    setPreviousSecondMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    setCurrentYearCost(0);
    setPreviousYearCost(0);
    setCurrentQuarterCost(0);
    setPreviousQuarterCost(0);
    setCurrentMonthCost(0);
    setPreviousMonthCost(0);
    setPreviousSecondMonthCost(0);

    if (selectedGroupMetaData) {
      getCostComparisonCardsData();
    }
  }, [selectedGroupMetaData]);

  useEffect(() => {
    setCostComparisonList([
      {
        value: currentYearCost,
        heading: t('costComparisonCard.ytdCosts'),
        comparisonValue: getPercentageDifference(
          currentYearCost,
          previousYearCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousYear'),
        requestStatus: [
          currentYearCostRequestStatus,
          previousYearCostRequestStatus,
        ],
      },
      {
        value: currentQuarterCost,
        heading: t('costComparisonCard.qtdCosts'),
        comparisonValue: getPercentageDifference(
          currentQuarterCost,
          previousQuarterCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousQuarter'),
        requestStatus: [
          currentQuarterCostRequestStatus,
          previousQuarterCostRequestStatus,
        ],
      },
      {
        value: currentMonthCost,
        heading: t('costComparisonCard.currentMonthCost'),
        comparisonValue: getPercentageDifference(
          currentMonthCost,
          previousMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousMonth'),
        requestStatus: [
          currentMonthCostRequestStatus,
          previousMonthCostRequestStatus,
        ],
      },
      {
        value: previousMonthCost,
        heading: t('costComparisonCard.lastMonthCost'),
        comparisonValue: getPercentageDifference(
          previousMonthCost,
          previousSecondMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromTwoMonthsBack'),
        requestStatus: [
          previousMonthCostRequestStatus,
          previousSecondMonthCostRequestStatus,
        ],
      },
    ]);
  }, [
    currentYearCost,
    previousYearCost,
    currentQuarterCost,
    previousQuarterCost,
    currentMonthCost,
    previousMonthCost,
    previousSecondMonthCost,
    currentYearCostRequestStatus,
    previousYearCostRequestStatus,
    currentQuarterCostRequestStatus,
    previousQuarterCostRequestStatus,
    currentMonthCostRequestStatus,
    previousMonthCostRequestStatus,
    previousSecondMonthCostRequestStatus,
  ]);

  const getCostComparisonCardsData = async () => {
    //Current Year Cost
    getCostByPeriodData(
      getFirstMonthAndCurrentYear(),
      getPreviousMonthAndYear(0),
      setCurrentYearCost,
      setCurrentYearCostRequestStatus
    );

    // Previous year Cost
    getCostByPeriodData(
      moment()
        .subtract(1, 'year')
        .startOf('year')
        .format(YEAR_MONTH_WITHOUT_SEPARATOR),
      moment()
        .subtract(1, 'year')
        .endOf('year')
        .format(YEAR_MONTH_WITHOUT_SEPARATOR),
      setPreviousYearCost,
      setPreviousYearCostRequestStatus
    );

    // Current Quarter Cost
    getCostByPeriodData(
      getCurrentQuarterStartMonthAndYear(),
      getPreviousMonthAndYear(0),
      setCurrentQuarterCost,
      setCurrentQuarterCostRequestStatus
    );

    // Previous Quarter Cost
    getCostByPeriodData(
      getPreviousQuarterStartMonthAndYear(),
      getPreviousQuarterEndMonthAndYear(),
      setPreviousQuarterCost,
      setPreviousQuarterCostRequestStatus
    );

    // Current Month Cost
    getCostByPeriodData(
      getPreviousMonthAndYear(0),
      getPreviousMonthAndYear(0),
      setCurrentMonthCost,
      setCurrentMonthCostRequestStatus
    );

    // Previous Month Cost
    getCostByPeriodData(
      getPreviousMonthAndYear(1),
      getPreviousMonthAndYear(1),
      setPreviousMonthCost,
      setPreviousMonthCostRequestStatus
    );

    // Previous 2nd Month Cost
    getCostByPeriodData(
      getPreviousMonthAndYear(2),
      getPreviousMonthAndYear(2),
      setPreviousSecondMonthCost,
      setPreviousSecondMonthCostRequestStatus
    );
  };

  /**
   * @function getCostByPeriodData
   * @description Function to make API call and get the cost for a date range
   * @param fromMonth : Start date in the format YYYYMM or YYYYM
   * @param toMonth : End date in the format YYYYMM or YYYYM
   * @param setData : Setter method for storing the data
   * @params setRequestStatus : Setter method for storing the request status
   */
  const getCostByPeriodData = (
    fromMonth: string,
    toMonth: string,
    setData: (val: number) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    axios
      .all(
        (selectedGroupMetaData?.connectorDtos ?? [])
          .filter((connection) => connection.wantBilling)
          .map((connection) =>
            getChartData(
              getCostRequestBodyByProvider(
                getProviderForConnection(connection),
                fromMonth,
                toMonth,
                connection.migrated
              ),
              connection.connectorId
            )
          )
      )
      .then((res: any) => {
        if (res.some((response: any) => response.status !== 200)) {
          setRequestStatus(REQUEST_STATUS.ERROR);
          return;
        }

        let consolidatedCost = 0;
        (selectedGroupMetaData?.connectorDtos ?? [])
          .filter((connection) => connection.wantBilling)
          .forEach(
            (_item, index) =>
              (consolidatedCost += +res[index]?.data[0]?.cost || 0)
          );
        setData(Number(consolidatedCost?.toFixed(2)));
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })

      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  return costComparisonList.map((item, index) => (
    <ComparisonCard
      key={item.heading}
      index={index}
      value={item.value}
      heading={item.heading}
      valuePrefix={currencySymbol}
      comparisonValue={item.comparisonValue}
      comparisonFrom={item.comparisonFrom}
      requestStatus={item.requestStatus}
    />
  ));
};

export default ConsolidatedComparisonCards;
