import { Popover } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import GaugeChart from 'components/GaugeChart';
import { COLORS } from 'constants/graphConfig';
import GraphHeader from 'components/GraphHeader';
import DashboardComponent from 'components/DashboardComponent';
import ExpandModal from 'components/ExpandModal';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectTagCompliance } from 'redux/tagComplianceSlice';
import { getChartData } from 'utils/services';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  getFullyCompliantCountQuery,
  getNonCompliantCountQuery,
  getTotalCountQuery,
} from './utils';

type ComplianceScoreProps = {
  tableRequestStatus: string;
  mandatoryTags: string[];
  searchKey: string;
};

const ComplianceScore = ({
  tableRequestStatus,
  mandatoryTags,
  searchKey,
}: ComplianceScoreProps) => {
  const { t } = useTranslation();
  const { showExpandGraphModal } = useSelector(selectDashboard);
  const { selectedConnection } = useSelector(selectTagCompliance);

  const [fullyCompliantCount, setFullyCompliantCount] = useState(0);
  const [partiallyCompliantCount, setPartiallyCompliantCount] = useState(0);
  const [nonCompliantCount, setNonCompliantCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [
    fetchFullyCompliantCountReqStatus,
    setFetchFullyCompliantCountReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [fetchNonCompliantReqStatus, setFetchNonCompliantReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchTotalCountReqStatus, setFetchTotalCountReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    if (mandatoryTags.length === 0) {
      setFetchFullyCompliantCountReqStatus(REQUEST_STATUS.SUCCESS);
      setFetchNonCompliantReqStatus(REQUEST_STATUS.SUCCESS);
      setFetchTotalCountReqStatus(REQUEST_STATUS.SUCCESS);
      return;
    }
    let source = axios.CancelToken.source();
    setFullyCompliantCount(0);
    setNonCompliantCount(0);
    setTotalCount(0);
    if (selectedConnection) {
      fetchCount(
        setFetchFullyCompliantCountReqStatus,
        getFullyCompliantCountQuery,
        setFullyCompliantCount,
        source
      );
      fetchCount(
        setFetchNonCompliantReqStatus,
        getNonCompliantCountQuery,
        setNonCompliantCount,
        source
      );
      fetchCount(
        setFetchTotalCountReqStatus,
        getTotalCountQuery,
        setTotalCount,
        source
      );
    }
    return () => {
      source.cancel();
    };
  }, [selectedConnection, mandatoryTags, searchKey]);

  useEffect(() => {
    if (
      evaluateRequestArray([
        fetchFullyCompliantCountReqStatus,
        fetchNonCompliantReqStatus,
        fetchTotalCountReqStatus,
      ]) === REQUEST_STATUS.SUCCESS
    ) {
      setPartiallyCompliantCount(
        totalCount - fullyCompliantCount - nonCompliantCount
      );
    }
  }, [
    fetchFullyCompliantCountReqStatus,
    fetchNonCompliantReqStatus,
    fetchTotalCountReqStatus,
    fullyCompliantCount,
    nonCompliantCount,
  ]);

  /**
   * @function fetchCount
   * @description Generic function to fetch the count of fully compliant and non-compliant resources
   * @param setRequestStatus set the request status for the count
   * @param getCountQuery function to get the query for fetching the count
   * @param setCount function to set state for the count
   */
  const fetchCount = (
    setRequestStatus: (status: string) => void,
    getCountQuery: (
      provider: string,
      searchKey: string,
      tags: string[],
      azureBillingTableName: string
    ) => any,
    setCount: (count: number) => void,
    cancelToken: CancelTokenSource
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = getCountQuery(
      selectedConnection!.provider,
      searchKey,
      mandatoryTags,
      selectedConnection!.billingTableName!
    );

    getChartData(
      requestBody,
      selectedConnection!.connectorId,
      {},
      cancelToken.token
    )
      .then((res: any) => {
        setCount(res.data[0].totalCount ?? 0);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        setCount(0);
        onApiCallError(e, false, setRequestStatus);
      });
  };

  const ChartsProperties = [
    {
      title: t('tagCompliance.fullyCompliant'),
      count: fullyCompliantCount,
      description: t('tagCompliance.fullyCompliantDescription'),
      descriptionExample: t('tagCompliance.fullyCompliantDescriptionExample'),
    },
    {
      title: t('tagCompliance.partiallyCompliant'),
      count: partiallyCompliantCount,
      description: t('tagCompliance.partiallyCompliantDescription'),
      descriptionExample: t(
        'tagCompliance.partiallyCompliantDescriptionExample'
      ),
    },
    {
      title: t('tagCompliance.nonCompliant'),
      count: nonCompliantCount,
      description: t('tagCompliance.nonCompliantDescription'),
      descriptionExample: t('tagCompliance.nonCompliantDescriptionExample'),
    },
  ];

  const ChartsComponent = (
    <div className="charts-container flex flex-space-around flex-center">
      {ChartsProperties.map((chartProps) => {
        const percentage = (chartProps.count / totalCount) * 100 || 0;
        return (
          <Popover
            placement="top"
            trigger="hover"
            overlayClassName="gauge-chart-description font-caption-bold"
            content={
              <div className="flex flex-column">
                <span>{chartProps.description}</span>
                <span className="font-caption">
                  {chartProps.descriptionExample}
                </span>
              </div>
            }
            key={chartProps.title}
          >
            <div className="gauge-chart">
              <GaugeChart
                key={chartProps.title}
                percent={percentage}
                ticks={[0, 1 / 5, 2 / 5, 3 / 5, 4 / 5, 1]}
                title={chartProps.title}
                contentText={`${numberCommaSeparator(percentage)}% (${
                  chartProps.count
                }/${totalCount})`}
                colorOverride={[
                  COLORS.green1,
                  COLORS.green2,
                  COLORS.green3,
                  COLORS.green4,
                  COLORS.primaryButton,
                ]}
              />
            </div>
          </Popover>
        );
      })}
    </div>
  );

  const GraphContainer = (
    <>
      <GraphHeader
        heading={t('tagCompliance.tagComplianceScore')}
        graphName="tag-compliance-score"
        designVersion2
      />
      <DashboardComponent
        requestStatus={evaluateRequestArray([
          fetchFullyCompliantCountReqStatus,
          tableRequestStatus,
        ])}
        component={ChartsComponent}
      />
    </>
  );

  return (
    <>
      <div className="score-graphs">{GraphContainer}</div>
      <ExpandModal show={showExpandGraphModal} graphContent={GraphContainer} />
    </>
  );
};

export default ComplianceScore;
