import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customDashboard, setCustomViewData } from 'redux/customDashboardSlice';
import { selectDashboard } from 'redux/dashboardSlice';
import CustomTablesOrGraphs from 'pages/CustomDashboardPage/components/CustomTablesOrGraphs';
import { defaultCustomViewData } from 'constants/defaultValues';

import './index.scss';

const CustomViewDashboard = () => {
  const dispatch = useDispatch();
  const { selectedDashboardView } = useSelector(selectDashboard);
  const { selectedDashboardCustomViews } = useSelector(customDashboard);

  useEffect(() => {
    dispatch(
      setCustomViewData(
        selectedDashboardCustomViews.find(
          (customViewData) => customViewData.id === selectedDashboardView
        ) ?? defaultCustomViewData
      )
    );
  }, [selectedDashboardView, selectedDashboardCustomViews]);

  return (
    <div className="custom-view-dashboard">
      <CustomTablesOrGraphs />
    </div>
  );
};

export default CustomViewDashboard;
