import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function saveDataCenter
 * @description Function to save a new Data Center
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveDataCenter = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_DATA_CENTER, body);
};

/**
 * @function updateDataCenter
 * @description Function to update a Data Center
 * @param body body for put request.
 * @return axios response from PUT request
 */
export const updateDataCenter = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_DATA_CENTER, body);
};

/**
 * @function deleteDataCenter
 * @description Function to delete data center
 * @param params Request parameters
 * @return axios response from POST request
 */
export const deleteDataCenter = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_DATA_CENTER, null, params);
};

/**
 * @function getAllComputeResources
 * @description Function to fetch all Compute Resources
 * @param params query parameters for get request.
 * @return axios response from GET request
 */
export const getAllComputeResources = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_COMPUTE_RESOURCES, null, params);
};

/**
 * @function saveComputeResource
 * @description Function to save a Compute Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveComputeResource = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_COMPUTE_RESOURCE, body);
};

/**
 * @function updateComputeResource
 * @description Function to update a Compute Resource
 * @param body body for put request.
 * @return axios response from PUT request
 */
export const updateComputeResource = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_COMPUTE_RESOURCE, body);
};

/**
 * @function deleteComputeResource
 * @description Function to delete a Compute Resource
 * @param params query parameters for delete request.
 * @return axios response from DELETE request
 */
export const deleteComputeResource = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_COMPUTE_RESOURCE, null, params);
};

/**
 * @function getAllStorageResources
 * @description Function to fetch all Storage Resources
 * @param params query parameters for get request.
 * @return axios response from GET request
 */
export const getAllStorageResources = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_STORAGE_RESOURCES, null, params);
};

/**
 * @function saveStorageResource
 * @description Function to save a Storage Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveStorageResource = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_STORAGE_RESOURCE, body);
};

/**
 * @function updateStorageResource
 * @description Function to update a Storage Resource
 * @param body body for put request.
 * @return axios response from PUT request
 */
export const updateStorageResource = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_STORAGE_RESOURCE, body);
};

/**
 * @function deleteStorageResource
 * @description Function to delete a Storage Resource
 * @param params query parameters for delete request.
 * @return axios response from DELETE request
 */
export const deleteStorageResource = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_STORAGE_RESOURCE, null, params);
};

/**
 * @function getAllNetworkResources
 * @description Function to fetch all Network Resources
 * @param params query parameters for get request.
 * @return axios response from GET request
 */
export const getAllNetworkResources = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_NETWORK_RESOURCES, null, params);
};

/**
 * @function saveNetworkResource
 * @description Function to save a Network Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveNetworkResource = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_NETWORK_RESOURCE, body);
};

/**
 * @function updateNetworkResource
 * @description Function to update a Network Resource
 * @param body body for put request.
 * @return axios response from PUT request
 */
export const updateNetworkResource = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_NETWORK_RESOURCE, body);
};

/**
 * @function deleteNetworkResource
 * @description Function to delete a Network Resource
 * @param params query parameters for delete request.
 * @return axios response from DELETE request
 */
export const deleteNetworkResource = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_NETWORK_RESOURCE, null, params);
};

/**
 * @function getAllDatabaseResources
 * @description Function to fetch all Database Resources
 * @param params query parameters for get request.
 * @return axios response from GET request
 */
export const getAllDatabaseResources = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_DATABASE_RESOURCES, null, params);
};

/**
 * @function saveDatabaseResource
 * @description Function to save a Database Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveDatabaseResource = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_DATABASE_RESOURCE, body);
};

/**
 * @function updateDatabaseResource
 * @description Function to update a Database Resource
 * @param body body for put request.
 * @return axios response from PUT request
 */
export const updateDatabaseResource = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_DATABASE_RESOURCE, body);
};

/**
 * @function deleteDatabaseResource
 * @description Function to delete a Database Resource
 * @param params query parameters for delete request.
 * @return axios response from DELETE request
 */
export const deleteDatabaseResource = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_DATABASE_RESOURCE, null, params);
};

/**
 * @function getAllSoftwareResources
 * @description Function to fetch all Software Resources
 * @param params query parameters for get request.
 * @return axios response from GET request
 */
export const getAllSoftwareResources = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_SOFTWARE_RESOURCES, null, params);
};

/**
 * @function saveSoftwareResource
 * @description Function to save a Software Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveSoftwareResource = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_SOFTWARE_RESOURCE, body);
};

/**
 * @function updateSoftwareResource
 * @description Function to update a Software Resource
 * @param body body for put request.
 * @return axios response from PUT request
 */
export const updateSoftwareResource = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_SOFTWARE_RESOURCE, body);
};

/**
 * @function deleteSoftwareResource
 * @description Function to delete a Software Resource
 * @param params query parameters for delete request.
 * @return axios response from DELETE request
 */
export const deleteSoftwareResource = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_SOFTWARE_RESOURCE, null, params);
};

/**
 * @function getAllLaborResources
 * @description Function to fetch all Labor Resources
 * @param params query parameters for get request.
 * @return axios response from GET request
 */
export const getAllLaborResources = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_LABOR_RESOURCES, null, params);
};

/**
 * @function saveLaborResource
 * @description Function to save a Labor Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveLaborResource = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_LABOR_RESOURCE, body);
};

/**
 * @function updateLaborResource
 * @description Function to update a Software Resource
 * @param body body for put request.
 * @return axios response from PUT request
 */
export const updateLaborResource = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_LABOR_RESOURCE, body);
};

/**
 * @function deleteLaborResource
 * @description Function to delete a Labor Resource
 * @param params query parameters for delete request.
 * @return axios response from DELETE request
 */
export const deleteLaborResource = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_LABOR_RESOURCE, null, params);
};

/**
 * @function saveMultipleCompute
 * @description Function to save a Compute Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveMultipleCompute = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_MULTIPLE_COMPUTE, body);
};

/**
 * @function saveMultipleStorage
 * @description Function to save a Storage Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveMultipleStorage = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_MULTIPLE_STORAGE, body);
};

/**
 * @function saveMultipleNetwork
 * @description Function to save a Network Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveMultipleNetwork = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_MULTIPLE_NETWORK, body);
};

/**
 * @function saveMultipleDatabase
 * @description Function to save a Database Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveMultipleDatabase = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_MULTIPLE_DATABASE, body);
};

/**
 * @function saveMultipleSoftware
 * @description Function to save a Software Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveMultipleSoftware = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_MULTIPLE_SOFTWARE, body);
};

/**
 * @function saveMultipleLabor
 * @description Function to save a Labor Resource
 * @param body body for post request.
 * @return axios response from POST request
 */
export const saveMultipleLabor = async (body: any) => {
  return ServiceCalls.post(APIS.SAVE_MULTIPLE_LABOR, body);
};
