import i18n from 'i18n';
import moment from 'moment';
import {
  QUERY_FIELDS,
  AGGREGATORS,
  ORDER_BY,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
} from 'constants/requestBody';
import {
  HYPHEN_DATE_TIME_HH_MM_SS,
  dateToday,
  HYPHEN_DATE_FORMAT,
} from 'utils/date';

export const GRANULATE_REDIRECT_URL = 'https://app.granulate.io/k8s';

export enum SERVICE_NOW_STATUS {
  UNDEFINED = 'UNDEFINED',
  NEW = 'NEW',
  IN_PROGRESS = 'INPROGRESS',
  ON_HOLD = 'ONHOLD',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED',
}

export const ServiceNowStatusLabels = [
  {
    key: SERVICE_NOW_STATUS.UNDEFINED,
    label: i18n.t('serviceNowLabels.ticketTBC'),
    tabTitle: i18n.t('serviceNowLabels.undefined'),
  },
  {
    key: SERVICE_NOW_STATUS.NEW,
    label: i18n.t('serviceNowLabels.new'),
    tabTitle: i18n.t('serviceNowLabels.new'),
  },
  {
    key: SERVICE_NOW_STATUS.IN_PROGRESS,
    label: i18n.t('serviceNowLabels.inProgress'),
    tabTitle: i18n.t('serviceNowLabels.inProgress'),
  },
  {
    key: SERVICE_NOW_STATUS.ON_HOLD,
    label: i18n.t('serviceNowLabels.onHold'),
    tabTitle: i18n.t('serviceNowLabels.onHold'),
  },
  {
    key: SERVICE_NOW_STATUS.RESOLVED,
    label: i18n.t('serviceNowLabels.resolved'),
    tabTitle: i18n.t('serviceNowLabels.resolved'),
  },
  {
    key: SERVICE_NOW_STATUS.CLOSED,
    label: i18n.t('serviceNowLabels.closed'),
    tabTitle: i18n.t('serviceNowLabels.closed'),
  },
];

export const INCIDENT_TYPES = {
  BULK_INCIDENT: 'BULK_INCIDENT',
  MULTI_INCIDENTS: 'MULTI_INCIDENTS',
};

export const INCIDENT_TYPE_OPTIONS = [
  { type: INCIDENT_TYPES.BULK_INCIDENT, title: 'bulkIncident' },
  { type: INCIDENT_TYPES.MULTI_INCIDENTS, title: 'multiIncidents' },
];

export const DefaultFormData = {
  url: '',
  usernameHost: '',
  password: '',
  setDefault: true,
  defaultAssignmentGroup: '',
  defaultAssignedTo: '',
  category: '',
};

export const InitialServiceNowData = {
  incidentType: '',
  assignmentGroup: '',
  assignedTo: '',
  category: '',
  createSnapshot: false,
};

export const memoryRequestBody = {
  columns: [
    {
      label: 'name',
      field: QUERY_FIELDS.POD_LABELS_VALUE,
    },
    {
      label: 'pods',
      field: QUERY_FIELDS.POD_COUNT_LABELS_VALUE,
    },
    {
      label: 'namespace',
      field: QUERY_FIELDS.NAMESPACE,
    },
    {
      label: 'memoryUtilisation',
      field: '((cloud_resource_size*fraction)/(1024*1024))',
    },
    {
      label: 'sku_id',
      field: 'sku_id',
    },
    {
      label: 'time',
      field: QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME,
    },
  ],
  structColumns: [
    {
      label: 'pod_labels',
      field: QUERY_FIELDS.LABELS,
    },
    {
      label: 'pod_count_labels',
      field: QUERY_FIELDS.LABELS,
    },
  ],
  aggregators: [
    {
      label: 'memoryUtilisation',
      function: AGGREGATORS.AVG,
    },
  ],
  groupBy: ['time', 'name', 'pods', 'namespace', 'sku_id'],
  orderBy: [
    {
      label: 'time',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: 'start_time',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment().subtract(1, 'days').format(HYPHEN_DATE_TIME_HH_MM_SS),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'end_time',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: dateToday(HYPHEN_DATE_FORMAT),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'pod_labels.key',
          comparator: COMPARATORS.IN,
          value: '("app","component","k8s-app")',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'pod_count_labels.key',
          comparator: COMPARATORS.IN,
          value: '("pod-template-generation")',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'resource_name',
          comparator: COMPARATORS.EQUALS,
          value: 'memory',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'cluster_name',
          comparator: COMPARATORS.IN,
          value: '("granulate-cluster")',
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
  cached: true,
};

export const cpuRequestBody = {
  columns: [
    {
      label: 'name',
      field: QUERY_FIELDS.LABELS_VALUE,
    },
    {
      label: 'sku_id',
      field: 'sku_id',
    },
    {
      label: 'cpuUtilisation',
      field: '(cloud_resource_size*fraction)',
    },
    {
      label: 'time',
      field: QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME,
    },
    {
      label: 'namespace',
      field: QUERY_FIELDS.NAMESPACE,
    },
  ],
  structColumns: [
    {
      label: 'labels',
      field: QUERY_FIELDS.LABELS,
    },
  ],
  aggregators: [
    {
      label: 'cpuUtilisation',
      function: AGGREGATORS.AVG,
    },
  ],
  groupBy: ['time', 'name', 'namespace', 'sku_id'],
  orderBy: [
    {
      label: 'time',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: 'start_time',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment().subtract(1, 'days').format(HYPHEN_DATE_FORMAT),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'end_time',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: dateToday(HYPHEN_DATE_FORMAT),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'labels.key',
          comparator: COMPARATORS.IN,
          value: '("app","component","k8s-app")',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },

        {
          field: 'resource_name',
          comparator: COMPARATORS.EQUALS,
          value: 'cpu',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'cluster_name',
          comparator: COMPARATORS.IN,
          value: '("granulate-cluster")',
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
  cached: true,
};
