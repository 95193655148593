import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function deleteDashboard
 * @description Function to set the default dashboard.
 * @param params Query parameters containing dashboardId
 * @return axios response from GET request
 */
export const deleteDashboard = async (params: any) => {
  const api = APIS.DELETE_DASHBOARD;
  return ServiceCalls.delete(api, null, params);
};

/**
 * @function setDashboardStatus
 * @description Function to set the default dashboard.
 * @param params Query parameters containing dashboardId
 * @return axios response from GET request
 */
export const setDashboardStatus = async (dashboardId: string, params: any) => {
  const api = APIS.SET_DASHBOARD_STATUS + dashboardId;
  return ServiceCalls.post(api, {}, null, params);
};
