import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCostAllocation,
  setAllProjectsList,
  setCostAllocationData,
  setCurrentStepValidation,
  setSelectedProjects,
  setTotalSharedCost,
} from 'redux/costAllocationSlice';
import DropdownCheckbox from 'components/DropdownCheckbox';
import Input from 'components/Input';
import { FormLabel } from 'components/FormLabel';
import { getValidationStyle } from 'utils/validations';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { VALIDATION_STATUS } from 'pages/ConnectingCSPPage/constants';

const AccountsSelectionStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    costAllocationData,
    totalSharedCost,
    allProjectsList,
    selectedProjects,
  } = useSelector(selectCostAllocation);

  const [fetchAccountsReqStatus, setFetchAccountsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [accountsReqValidation, setAccountsReqValidation] =
    useState<string>('');
  const [totalCost, setTotalCost] = useState<number>(0);
  const [totalCostReqStatus, setTotalCostReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [selectedAccountsCostReqStatus, setSelectedAccountsCostReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    getAccountsList();
    getCostForAllTheAccounts();
  }, []);

  useEffect(() => {
    dispatch(
      setCostAllocationData({
        ...costAllocationData,
        projects: selectedProjects,
      })
    );

    const source = axios.CancelToken.source();

    if (selectedProjects.length > 0) {
      setAccountsReqValidation('');
      getTotalSharedCost(source);
    } else {
      dispatch(setTotalSharedCost(0));
      setSelectedAccountsCostReqStatus(REQUEST_STATUS.SUCCESS);
    }

    return () => {
      source.cancel();
    };
  }, [selectedProjects]);

  useEffect(() => {
    dispatch(setCurrentStepValidation(validateSelectAccountsStep()));
  }, [
    costAllocationData.projects,
    fetchAccountsReqStatus,
    totalCostReqStatus,
    selectedAccountsCostReqStatus,
  ]);

  /**
   * @function getAccountsList
   * @description Function to fetch the list of accounts for the selected month.
   */
  const getAccountsList = () => {
    setFetchAccountsReqStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'account',
          field: QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
        },
      ],
      aggregators: [
        {
          label: 'account',
          function: AGGREGATORS.DISTINCT,
        },
      ],
      distinct: true,
      filterGroups: [
        {
          filters: [
            {
              field:
                QUERY_FIELDS.DATE_FORMAT_BILL_BILLING_PERIOD_START_DATE_YEAR_MONTH,
              comparator: COMPARATORS.EQUALS,
              value: costAllocationData.month,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, costAllocationData.connection)
      .then((res: any) => {
        dispatch(
          setAllProjectsList(
            res?.data?.map((eachAccount: any) => eachAccount.account)
          )
        );
        setFetchAccountsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setFetchAccountsReqStatus));
  };

  /**
   * @function getCostForAllTheAccounts
   * @description Function to fetch the cost of all the accounts for the selected month.
   */
  const getCostForAllTheAccounts = () => {
    setTotalCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      columns: [
        { label: 'cost', field: QUERY_FIELDS.SUM_LINE_ITEM_UNBLENDED_COST },
      ],
      filterGroups: [
        {
          filters: [
            {
              field:
                QUERY_FIELDS.DATE_FORMAT_BILL_BILLING_PERIOD_START_DATE_YEAR_MONTH,
              comparator: COMPARATORS.EQUALS,
              value: costAllocationData.month,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, costAllocationData.connection)
      .then((res: any) => {
        setTotalCost(Number(Number(res?.data[0]?.cost ?? 0).toFixed(2)));
        setTotalCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setTotalCostReqStatus));
  };

  /**
   * @function selectedAccountsToString
   * @description Function to convert list of string quoted comma separated string
   * @returns quoted comma separated string
   */
  const selectedAccountsToString = () => {
    const selectedAccountsString = "('";
    return selectedAccountsString + selectedProjects.join("','") + "')";
  };

  /**
   * @function getTotalSharedCost
   * @description Function to fetch the cost for the selected accounts.
   * @param cancelTokenSource cancel token
   */
  const getTotalSharedCost = (cancelTokenSource: CancelTokenSource) => {
    setSelectedAccountsCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      columns: [
        { label: 'cost', field: QUERY_FIELDS.SUM_LINE_ITEM_UNBLENDED_COST },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              comparator: COMPARATORS.IN,
              value: selectedAccountsToString(),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field:
                QUERY_FIELDS.DATE_FORMAT_BILL_BILLING_PERIOD_START_DATE_YEAR_MONTH,
              comparator: COMPARATORS.EQUALS,
              value: costAllocationData.month,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(
      requestBody,
      costAllocationData.connection,
      null,
      cancelTokenSource.token
    )
      .then((res: any) => {
        dispatch(
          setTotalSharedCost(
            Number(Number(res?.data?.[0]?.cost ?? 0).toFixed(2))
          )
        );
        setSelectedAccountsCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setSelectedAccountsCostReqStatus));
  };

  /**
   * @function validateSelectAccountsStep
   * @description Function to validate the accounts selection step
   * @returns string validation status (VALID, INVALID or SKIP)
   */
  const validateSelectAccountsStep = () => {
    if (
      [
        fetchAccountsReqStatus,
        totalCostReqStatus,
        selectedAccountsCostReqStatus,
      ].includes(REQUEST_STATUS.PROCESSING)
    ) {
      return VALIDATION_STATUS.INVALID;
    }

    if (costAllocationData?.projects?.length > 0) {
      return VALIDATION_STATUS.VALID;
    }

    return VALIDATION_STATUS.INVALID;
  };

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('costAllocations.selectAccounts')}
          required={true}
        />
        <DropdownCheckbox
          itemOptions={allProjectsList.map((project) => ({
            title: project,
            value: project,
          }))}
          selectedItems={costAllocationData?.projects || []}
          setSelectedItems={(values) => {
            dispatch(setSelectedProjects(values));
            values.length > 0
              ? setAccountsReqValidation('')
              : setAccountsReqValidation(t('costAllocations.accountsRequired'));
          }}
          onHandleBlur={() =>
            costAllocationData?.projects?.length > 0
              ? setAccountsReqValidation('')
              : setAccountsReqValidation(t('costAllocations.accountsRequired'))
          }
          designVersion2
          showSearch={true}
          showSelectionCount={true}
          valueLabelSuffix={t('costAllocations.accounts')}
          loading={fetchAccountsReqStatus === REQUEST_STATUS.PROCESSING}
          isDisabled={fetchAccountsReqStatus === REQUEST_STATUS.PROCESSING}
        />
        <span
          style={{
            display: getValidationStyle(accountsReqValidation),
          }}
          className="font-validation-error"
        >
          {accountsReqValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('costAllocations.totalSharedCost')} />
        <Input
          value={
            totalCostReqStatus === REQUEST_STATUS.PROCESSING
              ? 'Loading...'
              : totalSharedCost.toFixed(2)
          }
          disabled
          designVersion2
        />
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('costAllocations.residualCostMonth')} />
        <Input
          value={
            [selectedAccountsCostReqStatus, totalCostReqStatus].includes(
              REQUEST_STATUS.PROCESSING
            )
              ? 'Loading...'
              : (totalCost - totalSharedCost).toFixed(2)
          }
          disabled
          designVersion2
        />
      </div>
    </div>
  );
};

export default AccountsSelectionStep;
