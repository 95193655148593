import i18n from 'i18n';
import { PROVIDER } from 'constants/cloudProviders';
import {
  MonthlyCostType,
  ColouredCostByProviderServiceType,
  ColouredCostByResourceType,
} from 'types/dataTypes';
import {
  ConsolidatedGCPCostTrendExportColumns,
  getAWSCostByResourceRequestBody,
  getAWSCostByServiceRequestBody,
  getAWSCostTrendRequestBody,
  getAzureCostByResourceRequestBody,
  getAzureCostByServiceRequestBody,
  getAzureCostTrendRequestBody,
  getGCPCostByResourceRequestBody,
  getGCPCostByServiceRequestBody,
  getGCPCostTrendRequestBody,
  getOCICostTrendRequestBody,
} from '../constants';

/**
 * @function getCostTrendRequestBodyByProvider
 * @description Function to get the request body for monthly cost
 * @param provider : String provider for which the request body is required
 * @returns the json request body
 */
export const getCostTrendRequestBodyByProvider = (
  provider: string,
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGCPCostTrendRequestBody(fromMonth, toMonth);
    case PROVIDER.AWS:
      return getAWSCostTrendRequestBody(fromMonth, toMonth, isMigrated);
    case PROVIDER.AZURE:
      return getAzureCostTrendRequestBody(fromMonth, toMonth);
    case PROVIDER.OCI:
      return getOCICostTrendRequestBody(fromMonth, toMonth);
  }
};

/**
 * @function getCostByServiceForTrendsRequestBody
 * @description Function to get the request body for monthly cost
 * @param provider : String provider for which the request body is required
 * @returns the json request body
 */
export const getCostByServiceForTrendsRequestBody = (
  provider: string,
  selectedTrendMonth: string,
  isMigrated: boolean = false
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGCPCostByServiceRequestBody(selectedTrendMonth);
    case PROVIDER.AWS:
      return getAWSCostByServiceRequestBody(selectedTrendMonth, isMigrated);
    case PROVIDER.AZURE:
      return getAzureCostByServiceRequestBody(selectedTrendMonth);
  }
};

/**
 * @function getCostByResourceForTrendsRequestBody
 * @description Function to get the request body for monthly cost
 * @param provider : String provider for which the request body is required
 * @returns the json request body
 */
export const getCostByResourceForTrendsRequestBody = (
  provider: string,
  selectedTrendMonth: string,
  selectedTrendService: string,
  isMigrated: boolean = false
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGCPCostByResourceRequestBody(
        selectedTrendMonth,
        selectedTrendService
      );
    case PROVIDER.AWS:
      return getAWSCostByResourceRequestBody(
        selectedTrendMonth,
        selectedTrendService,
        isMigrated
      );
    case PROVIDER.AZURE:
      return getAzureCostByResourceRequestBody(
        selectedTrendMonth,
        selectedTrendService
      );
  }
};

/**
 * @function getCostTrendsExportColumns
 * @description Function to return the columns for excel and pdf export.
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @param data trends graph data for all the drilldown levels
 * @return List of columns for export
 */
export const getCostTrendsData = (
  selectedTrendMonth: string,
  selectedTrendService: string,
  data: {
    trends?: MonthlyCostType[];
    service?: ColouredCostByProviderServiceType[];
    resource?: ColouredCostByResourceType[];
  }
) => {
  if (!selectedTrendMonth) {
    return data.trends;
  }

  if (!selectedTrendService) {
    return data.service;
  }

  if (selectedTrendService) {
    return data.resource;
  }
};

/**
 * @function getCostTrendsHeading
 * @description Function to return the gcp cost trends graph heading based on the drilldown level
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @return String containing the graph heading based on the drilldown
 */
export const getCostTrendsHeading = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined
) => {
  if (!selectedTrendMonth) {
    return i18n.t('graphHeadings.costTrend');
  }

  if (!selectedTrendService) {
    return `${i18n.t('graphHeadings.costByService')}(${selectedTrendMonth})`;
  }

  if (selectedTrendService) {
    return `${i18n.t(
      'graphHeadings.costByResources'
    )}(${selectedTrendMonth})(${selectedTrendService})`;
  }

  return i18n.t('graphHeadings.costTrend');
};

/**
 * @function getCostTrendsExportColumns
 * @description Function to return the columns for excel and pdf export.
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @return List of columns for export
 */
export const getCostTrendsExportColumns = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined
) => {
  let columns: any[] = [...ConsolidatedGCPCostTrendExportColumns];

  if (!selectedTrendMonth) {
    return columns;
  }

  if (!selectedTrendService) {
    columns.splice(
      1,
      1,
      {
        header: i18n.t('carbonFootprint.carbonEmissionByServices.services'),
        key: 'service',
        dataKey: 'service',
      },
      {
        header: i18n.t('consolidatedYtdCost.provider'),
        key: 'provider',
        dataKey: 'provider',
      }
    );
  }

  if (selectedTrendService) {
    columns.splice(1, 1, {
      header: i18n.t('costByResource.resources'),
      key: 'resource',
      dataKey: 'resource',
    });
  }

  return columns;
};
