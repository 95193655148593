import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select as AntSelect } from 'antd';
import moment from 'moment';

import SearchInput from 'components/Input';
import { FilterKeys } from 'pages/CostOptimizationInsightsPage/constants';
import Icon from 'components/Icon';
import Checkbox from 'components/Checkbox';
import Select from 'components/Select';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { ValueLabelType } from 'types/dashboard';
import { RecommendationFiltersType } from 'pages/CostOptimizationInsightsPage/types';
import { DATE_FORMAT } from 'utils/date';

import { getFilterLabelByKey } from '../FiltersModal/utils';
import { getDisplayTextForCostSavings } from './utils';

import './index.scss';

const { Option } = AntSelect;

type EachFilterProps = {
  filter: RecommendationFiltersType;
  allValues?: ValueLabelType[] | null;
};

const EachFilter = ({ filter, allValues }: EachFilterProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [selectedValues, setSelectedValues] = useState<ValueLabelType[] | null>(
    []
  );
  const [filteredValues, setFilteredValues] = useState<
    ValueLabelType[] | null
  >();

  useEffect(() => {
    setFilteredValues(allValues);
  }, [allValues]);

  useEffect(() => {
    setSelectedValues(filter.values);
  }, [filter]);

  useEffect(() => {
    // Filter values by search key
    const valuesBySearchKey = searchKey
      ? allValues?.filter((item) =>
          (item?.label ?? item.value)?.toLowerCase()?.includes(searchKey)
        )
      : allValues;
    setFilteredValues(valuesBySearchKey);
  }, [searchKey]);

  const TagSuffix = (
    <div className="flex flex-align-items-center flex-gap-8">
      <div className="tag-value-count">
        <div className="count font-caption flex flex-center">
          {filter.values!.length}
        </div>
      </div>
      {!isOpen ? (
        <Icon
          iconName={ICONS.ARROW_DOWN_S_LINE}
          className="tag-suffix-icon flex flex-align-items-center full-height"
          size={ICONS_SIZE.XL}
        />
      ) : (
        <Icon
          iconName={ICONS.ARROW_UP_S_LINE}
          className="tag-suffix-icon flex flex-align-items-center full-height"
          size={ICONS_SIZE.XL}
        />
      )}
    </div>
  );

  const getTagContent = () => {
    switch (filter.key) {
      case FilterKeys.RECOMMENDATION_DESCRIPTION:
        return (
          <Option key={filter.values![0].value}>
            <Checkbox value={filter.values![0].value} checked disabled>
              <span className="label">{filter.values![0].value}</span>
            </Checkbox>
          </Option>
        );

      case FilterKeys.LAST_REFRESH_DATE_RANGE:
        return (
          <Option key={filter.values![0].value}>
            <Checkbox value={filter.values![0].value} checked disabled>
              <span className="label">
                {t(
                  'costOptimizationInsight.viewDropdown.dateRangeFilterDropdown',
                  {
                    startDate: moment(
                      filter.values![0].value.split(',')[0]
                    ).format(DATE_FORMAT),
                    endDate: moment(filter.values![0].value.split(',')[1]),
                  }
                )}
              </span>
            </Checkbox>
          </Option>
        );

      case FilterKeys.COST_SAVINGS:
        return filter.values!.map((item, index) => (
          <Option key={item.value}>
            <Checkbox value={item.value} checked disabled>
              <span className="label">
                {getDisplayTextForCostSavings(
                  item,
                  filter.values!.length !== index + 1
                )}
              </span>
            </Checkbox>
          </Option>
        ));

      default:
        return filteredValues?.map((item) => (
          <Option key={item.value}>
            <Checkbox
              value={item.value}
              checked={selectedValues?.some(
                (item1) => item1.value === item.value
              )}
              disabled
            >
              <span className="label">{item.label ?? item.value}</span>
            </Checkbox>
          </Option>
        ));
    }
  };

  const getTagRender = () => {
    return (
      <div className="tag-content flex flex-gap-24">
        {getFilterLabelByKey(filter.key)}
      </div>
    );
  };

  const getTagValuesDropdownRender = (menu: any) => (
    <div className="tag-dropdown-wrapper flex flex-column flex-gap-8">
      {filter.values &&
        filter.values.length > 1 &&
        filter.key !== FilterKeys.COST_SAVINGS && (
          <SearchInput
            value={searchKey}
            placeholder={t('dashboardLabels.tagsLabels.searchByValue')}
            type="search"
            className="full-width"
            onChange={(e: any) => setSearchKey(e.target.value)}
          />
        )}
      {menu}
    </div>
  );

  return (
    <Select
      value={[filter.key]}
      mode="multiple"
      showSearch={false}
      onDropdownVisibleChange={(open: any) => {
        setIsOpen(open);
        if (!open) {
          setFilteredValues(allValues);
          setSelectedValues(filter.values);
          setSearchKey('');
        }
      }}
      tagRender={getTagRender}
      open={isOpen}
      popupClassName="tag-dropdown"
      suffixIcon={TagSuffix}
      dropdownRender={getTagValuesDropdownRender}
    >
      {getTagContent()}
    </Select>
  );
};

export default EachFilter;
