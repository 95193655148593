import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  cmdbSetup,
  setCmdbConfigurationStep,
  setCmdbSetupConnection,
} from 'redux/cmdbSetupSlice';
import Button from 'components/Button';
import { ICONS } from 'constants/icons';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { BUTTON_TYPE } from 'constants/appearance';
import {
  CMDB_PROGRESS_BAR_CONTENT,
  CmdbConfigurationSteps,
} from 'pages/CmdbConfigurationPage/constants';
import { validateByRegex } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';

import { CONNECTION_URL_REGEX } from '../CmdbAccessDetails/constants';
import { onSaveOrUpdateCmdbSetup } from './utils';

import './index.scss';

type CmdbConfigurationFooterProps = {
  isDuplicateName: boolean;
};

const CmdbConfigurationFooter = ({
  isDuplicateName,
}: CmdbConfigurationFooterProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { cmdbConfigurationStep, cmdbSetupConnection, isEditConnection } =
    useSelector(cmdbSetup);
  const dispatch = useDispatch();

  const [saveOrUpdateReqStatus, setSaveOrUpdateReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  const onClickNext = () => {
    if (cmdbConfigurationStep < CMDB_PROGRESS_BAR_CONTENT.length - 1) {
      dispatch(setCmdbConfigurationStep(cmdbConfigurationStep + 1));
    } else {
      onSaveOrUpdateCmdbSetup(
        cmdbSetupConnection!,
        isEditConnection,
        setSaveOrUpdateReqStatus,
        () => navigate(NAVIGATION_MENU_PATH.CMDB_SETUP)
      );
    }
  };

  const onClickPrevious = () => {
    if (cmdbConfigurationStep > 0) {
      dispatch(setCmdbConfigurationStep(cmdbConfigurationStep - 1));
    }
  };

  const getNextButtonTitle = () => {
    switch (cmdbConfigurationStep) {
      case CmdbConfigurationSteps.BASIC_INFORMATION:
      case CmdbConfigurationSteps.ACCESS_DETAILS:
        return t('continue');
      case CmdbConfigurationSteps.FIELD_MAPPING:
        return t('submit');
    }
  };

  const onClickResetForm = () => {
    switch (cmdbConfigurationStep) {
      case CmdbConfigurationSteps.BASIC_INFORMATION: {
        dispatch(
          setCmdbSetupConnection({
            ...cmdbSetupConnection!,
            dataSourceProvider: '',
            name: '',
          })
        );
        break;
      }

      case CmdbConfigurationSteps.ACCESS_DETAILS: {
        dispatch(
          setCmdbSetupConnection({
            ...cmdbSetupConnection!,
            connectionUrl: '',
            username: undefined,
            password: undefined,
            database: '',
          })
        );
        break;
      }

      case CmdbConfigurationSteps.FIELD_MAPPING: {
        dispatch(
          setCmdbSetupConnection({
            ...cmdbSetupConnection!,
            fieldTagsMappings: [],
          })
        );
        break;
      }
    }
  };

  const isNextCtaDisabled = () => {
    switch (cmdbConfigurationStep) {
      case CmdbConfigurationSteps.BASIC_INFORMATION:
        return (
          !cmdbSetupConnection!.dataSourceProvider ||
          !cmdbSetupConnection!.name ||
          (!isEditConnection && isDuplicateName)
        );

      case CmdbConfigurationSteps.ACCESS_DETAILS:
        return (
          !cmdbSetupConnection!.connectionUrl ||
          !cmdbSetupConnection!.database ||
          (!isEditConnection &&
            (!cmdbSetupConnection!.username ||
              !cmdbSetupConnection!.password ||
              !validateByRegex(
                cmdbSetupConnection!.connectionUrl,
                CONNECTION_URL_REGEX
              )))
        );

      case CmdbConfigurationSteps.FIELD_MAPPING:
        return cmdbSetupConnection!.fieldTagsMappings.length === 0;
    }
  };

  return (
    <footer
      className="cmdb-configuration-setup-footer flex flex-space-between flex-align-items-center"
      data-testid="cmdb-configuration-footer"
    >
      <div className="flex">
        <Button
          onClick={() => navigate(NAVIGATION_MENU_PATH.CMDB_SETUP)}
          title={t('cancel')}
          type={BUTTON_TYPE.LINK}
        />
        <div className="vertical-rule"></div>
        <Button
          onClick={onClickResetForm}
          title={t('reset')}
          type={BUTTON_TYPE.LINK}
        />
      </div>
      <div className="flex flex-gap-10">
        {cmdbConfigurationStep !== CmdbConfigurationSteps.BASIC_INFORMATION && (
          <Button
            onClick={onClickPrevious}
            title={t('previousStep')}
            iconName={ICONS.ARROW_LEFT}
            type={BUTTON_TYPE.OUTLINED}
            reverseIconTitleOrder
          />
        )}
        <Button
          onClick={onClickNext}
          title={getNextButtonTitle()}
          iconName={ICONS.ARROW_RIGHT}
          disabled={isNextCtaDisabled()}
          loading={saveOrUpdateReqStatus === REQUEST_STATUS.PROCESSING}
        />
      </div>
    </footer>
  );
};

export default CmdbConfigurationFooter;
