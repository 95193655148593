import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ColumnChart from 'components/ColumnChart';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Cascader from 'components/Cascader';
import { REQUEST_STATUS } from 'constants/requestBody';
import GraphHeader from 'components/GraphHeader';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { NamespaceUsageCostType } from 'pages/ContainerInsightsPage/types';
import { getNamespaceUsageCostExcelData } from 'pages/ContainerInsightsPage/utils';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { DATE_FORMAT } from 'utils/date';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';

import {
  displayRender,
  onChangeDateFilter,
  onChangeDates,
} from '../GCPUsageMetering/utils';

type NamespaceUsageCostProps = {
  namespaceUsageCostData: NamespaceUsageCostType[];
  filteredNamespaceUsageCostData: NamespaceUsageCostType[];
  selectedNamespaces: string[];
  setSelectedNamespaces: (val: string[]) => void;
  requestStatus: string[];
  namespaceUsageCostTableView: boolean;
  setNamespaceUsageCostTableView: (value: boolean) => void;
  pdfView: boolean;
  namespaceUsageCostDateFilter: DateRangeFilter;
  setNamespaceUsageCostDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};

const NamespaceUsageCost = ({
  namespaceUsageCostData,
  filteredNamespaceUsageCostData,
  selectedNamespaces,
  setSelectedNamespaces,
  requestStatus,
  namespaceUsageCostTableView,
  setNamespaceUsageCostTableView,
  pdfView,
  namespaceUsageCostDateFilter,
  setNamespaceUsageCostDateFilter,
  excelFilters,
}: NamespaceUsageCostProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 40,
    },
    {
      title: t('containerInsight.usageMeteringLabels.namespace'),
      dataIndex: 'namespace',
      key: 'namespace',
    },
    {
      title: t('containerInsight.usageMeteringLabels.costAfterCredit', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'costAfterCredit',
      key: 'costAfterCredit',
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text || 0),
    },
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  format={DATE_FORMAT}
                  defaultValue={[
                    moment(namespaceUsageCostDateFilter.startDate),
                    moment(namespaceUsageCostDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(dateString, setNamespaceUsageCostDateFilter)
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters flex flex-row flex-align-items-center flex-gap-16">
      <Cascader
        className="width-40"
        options={DateMenu}
        defaultValue={[namespaceUsageCostDateFilter.option]}
        displayRender={() => displayRender(namespaceUsageCostDateFilter)}
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setNamespaceUsageCostDateFilter)
        }
      />
      <GraphFilterDropdown
        additionalClassNames="width-50"
        allData={namespaceUsageCostData}
        selectedData={namespaceUsageCostData.filter((item) =>
          selectedNamespaces.includes(item.namespace)
        )}
        setSelectedData={(selectedItems: any[]) => {
          setSelectedNamespaces(selectedItems.map((item) => item.namespace));
        }}
        setLabels={setSelectedNamespaces}
        valueSuffix={t('containerInsight.usageMeteringLabels.namespaces')}
        fieldName={DROPDOWN_VALUE_FIELDS.NAMESPACE}
      />
    </div>
  );

  const getComponent = () =>
    namespaceUsageCostTableView ? (
      <Table
        pagination={false}
        loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
        dataSource={filteredNamespaceUsageCostData.map((data, index) => ({
          ...data,
          key: index,
        }))}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer={true}
      />
    ) : (
      <ColumnChart
        data={filteredNamespaceUsageCostData.map((item) => ({
          ...item,
          groupingField: item.namespace,
        }))}
        yField="costAfterCredit"
        xField="namespace"
        groupingField="groupingField"
        xTitle={t('containerInsight.usageMeteringLabels.namespaces')}
        yTitle={t('containerInsight.usageMeteringLabels.costAfterCredit', {
          currencySymbol: currencySymbol,
        })}
        disableAnimation={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  return (
    <div
      className="namespace-usage-cost graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.namespaceUsageCost')}
        graphName="namespace-usage-cost"
        ignorePaddingBottom={true}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={namespaceUsageCostTableView}
        setIsTableView={setNamespaceUsageCostTableView}
        filters={filters}
        excelData={getNamespaceUsageCostExcelData(
          filteredNamespaceUsageCostData,
          [
            ...excelFilters,
            {
              heading: t('excelExportLabels.startDate'),
              value: namespaceUsageCostDateFilter.startDate,
            },
            {
              heading: t('excelExportLabels.endDate'),
              value: namespaceUsageCostDateFilter.endDate,
            },
            {
              heading: t('excelExportLabels.namespaces'),
              value: selectedNamespaces.toString(),
            },
          ]
        )}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default NamespaceUsageCost;
