import { Select } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getAzureDatabaseTables } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

export const CarbonFootprintDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection } = useSelector(cloudConnection);

  const [
    azureCarbonFootprintDatasetRequiredValidation,
    setAzureCarbonFootprintDatasetRequiredValidation,
  ] = useState('');
  const [
    azureCarbonFootprintDatasetTableOptions,
    setAzureCarbonFootprintDatasetTableOptions,
  ] = useState<string[]>([]);
  const [
    azureCarbonFootprintDatasetTableOptionsRequestStatus,
    setAzureCarbonFootprintDatasetTableOptionsRequestStatus,
  ] = useState('');

  /**
   * @function fetchAzureCarbonFootprintDatabaseTables
   * @description Function to fetch recommendation dataset tables
   */
  const fetchAzureCarbonFootprintDatabaseTables = () => {
    if (
      currentConnection?.azureTenantId &&
      currentConnection?.azureSubscriptionId &&
      currentConnection?.azureAppId &&
      currentConnection?.azureClientSecret &&
      currentConnection?.azureCarbonFootprintDataset
    ) {
      setAzureCarbonFootprintDatasetTableOptionsRequestStatus(
        REQUEST_STATUS.PROCESSING
      );
      const body = isEditConnection
        ? {
            connectorId: currentConnection?.connectorId,
            billingDataset: currentConnection?.azureCarbonFootprintDataset,
            azureSynapseWorkspace: currentConnection?.azureSynapseWorkspaceName,
            azureSqlServer: currentConnection.azureSynapseWorkspaceName,
            dataSourceType: currentConnection.dataSourceType,
          }
        : {
            azureTenantId: currentConnection?.azureTenantId,
            azureSubId: currentConnection?.azureSubscriptionId,
            azureClientId: currentConnection?.azureAppId,
            azureClientSecret: currentConnection?.azureClientSecret,
            billingDataset: currentConnection?.azureCarbonFootprintDataset,
            azureSynapseWorkspace: currentConnection?.azureSynapseWorkspaceName,
            azureSqlServer: currentConnection.azureSynapseWorkspaceName,
            dataSourceType: currentConnection.dataSourceType,
          };
      getAzureDatabaseTables(body)
        .then((res: any) => {
          setAzureCarbonFootprintDatasetTableOptions(res.data.responseData);
          setAzureCarbonFootprintDatasetTableOptionsRequestStatus(
            REQUEST_STATUS.SUCCESS
          );
        })
        .catch((e) =>
          onApiCallError(
            e,
            true,
            setAzureCarbonFootprintDatasetTableOptionsRequestStatus
          )
        );
    }
  };

  const getDropdown = (options: any[]) => (
    <>
      {options?.map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </>
  );

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`azureConnectionCSPForm.azureCarbonFootprintDataset`)}
        />
        <Input
          default
          type="input"
          value={currentConnection?.azureCarbonFootprintDataset}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                azureCarbonFootprintDataset: e.target.value,
              })
            );
            setAzureCarbonFootprintDatasetRequiredValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t(`azureConnectionCSPForm.azureCarbonFootprintDataset`),
              setAzureCarbonFootprintDatasetRequiredValidation
            );
            fetchAzureCarbonFootprintDatabaseTables();
          }}
          data-testid="dataset-input"
        />
        <span
          style={{
            display: `${
              azureCarbonFootprintDatasetRequiredValidation ? 'inline' : 'none'
            }`,
          }}
          className="font-validation-error"
        >
          {azureCarbonFootprintDatasetRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`azureConnectionCSPForm.azureCarbonFootprintDatasetTable`)}
        />
        <SelectDropdown
          value={currentConnection?.azureCarbonFootprintDatasetTable}
          menu={getDropdown(azureCarbonFootprintDatasetTableOptions)}
          loading={
            azureCarbonFootprintDatasetTableOptionsRequestStatus ===
            REQUEST_STATUS.PROCESSING
          }
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                azureCarbonFootprintDatasetTable: value,
              })
            );
          }}
          designVersion2
        />
      </div>
    </div>
  );
};
