import { Col, Row, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

import {
  isStartWithLowerCaseLetter,
  isStringContainsLowercaseNumbersHyphensUnderscore,
} from 'utils/validations';
import Input from 'components/Input';
import { DeleteIconButton } from 'assets/icons';
import SelectDropdown from 'components/Select';
import { FormLabel } from 'components/FormLabel';
import { TagsType } from 'pages/TagsAndLabelsPage/types';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';

import { REQUIRED_KEYS, REQUIRED_OPTIONS } from '../TagsAndLabels/constants';

type EachTagAndLabelProps = {
  tags: TagsType[];
  tagItem: TagsType;
  index: number;
  setTags: (val: TagsType[]) => void;
};

const EachTagAndLabel = ({
  tags,
  tagItem,
  index,
  setTags,
}: EachTagAndLabelProps) => {
  const { t } = useTranslation();
  const [tagValidationMessage, setTagValidationMessage] = useState('');

  useDidMountEffect(() => {
    if (tagItem.key.trim().length === 0) {
      setTagValidationMessage(t('tagsOrLabels.tagsIsRequired'));
    } else if (tagItem.key.trim().length > MAX_CHARACTER_LIMIT) {
      setTagValidationMessage(t('tagsOrLabels.tagLengthLimitError'));
    } else if (isTagExists(tagItem, index)) {
      setTagValidationMessage(tagItem.key + ' ' + t('alreadyExistsValidation'));
    } else if (!isStartWithLowerCaseLetter(tagItem.key)) {
      setTagValidationMessage(t('firstLetterCharacterValidation'));
    } else if (
      !isStringContainsLowercaseNumbersHyphensUnderscore(tagItem.key)
    ) {
      setTagValidationMessage(t('tagCharacterValidation'));
    } else {
      setTagValidationMessage('');
    }
  }, [tags]);

  /**
   * @function onHandleChangeTagName
   * @description Function for onChange event for tag name.
   * @param value: value to be updated with
   * @param changePosition: position of the array to be updated
   */
  const onHandleChangeTagName = (value: string, changePosition: number) => {
    const tempArr = [...tags];
    tempArr.splice(changePosition, 1, {
      key: value,
      mandatory: tags.find((_tag, i) => i === changePosition)?.mandatory,
    });
    setTags([...tempArr]);
  };

  /**
   * @function onHandleSelectRequired
   * @description Function for onSelect event for Required dropdown.
   * @param value: value to be updated with
   * @param selectPosition: position of the array to be selected
   */
  const onHandleSelectRequired = (value: string, selectPosition: number) => {
    const tempArr = [...tags];
    tempArr.splice(selectPosition, 1, {
      key: tags.find((_tag, i) => i === selectPosition)?.key!,
      mandatory: value === REQUIRED_KEYS.MANDATORY,
    });
    setTags([...tempArr]);
  };

  /**
   * @function removeTag
   * @description Function to remove a tag.
   * @param deletePosition: position of the array to be removed
   */
  const removeTag = (deletePosition: number) => {
    const tempArr = [...tags];
    tempArr.splice(deletePosition, 1);
    setTags([...tempArr]);
  };

  /**
   * @function isTagExists
   * @description function to check tag already exist or not
   * @param tagItemToCheck each tag Item
   * @returns boolean value after checking duplication
   */
  const isTagExists = (tagItemToCheck: TagsType, tagIndex: number) => {
    if (
      tags.some(
        (eachItem, itemIndex) =>
          tagIndex !== itemIndex && eachItem.key === tagItemToCheck.key
      )
    ) {
      return true;
    }
    return false;
  };

  const RequiredDropdown = (
    <>
      {REQUIRED_OPTIONS.map((options) => (
        <Select.Option key={options.key} value={options.key}>
          {t(`tagsOrLabels.${options.title}`)}
        </Select.Option>
      ))}
    </>
  );
  return (
    <Row gutter={16} className="tag-row" key={index}>
      <Col span={14} className="form-item flex flex-column">
        <FormLabel title={t('tagsOrLabels.tags')} required={true} />
        <Input
          autoFocus
          placeholder={t('tagsOrLabels.tagsPlaceholder')}
          value={tagItem.key}
          onChange={(e: any) => onHandleChangeTagName(e.target.value, index)}
        />
      </Col>
      <Col span={8} className="form-item flex flex-column">
        <FormLabel title={t('tagsOrLabels.required')} required={true} />
        <SelectDropdown
          value={
            tagItem.mandatory ? REQUIRED_KEYS.MANDATORY : REQUIRED_KEYS.OPTIONAL
          }
          menu={RequiredDropdown}
          onSelect={(value: any) => onHandleSelectRequired(value, index)}
        />
      </Col>
      <Col span={2} className="flex-align-self-end">
        {tags.length > 1 && (
          <DeleteIconButton
            className="cursor-pointer"
            width={32}
            height={32}
            onClick={() => removeTag(index)}
          />
        )}
      </Col>
      <span
        style={{
          display: `${tagValidationMessage ? 'inline' : 'none'}`,
        }}
        className="font-validation-error validation-message"
      >
        {tagValidationMessage}
      </span>
    </Row>
  );
};

export default EachTagAndLabel;
