import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { uniqBy } from 'lodash';

import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Cascader from 'components/Cascader';
import SelectDropdown from 'components/Select';
import { INPUT_SIZE } from 'constants/appearance';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import ColumnLineChart from 'components/ColumnLineChart';
import TooltipContent from 'components/TooltipContent';
import { COLORS } from 'constants/graphConfig';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { evaluateRequestArray } from 'utils/handleErrors';
import { generateGraphColors } from 'utils/dashboardUtils';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';
import { getDurationAxisLabel } from 'pages/ContainerInsightsPage/utils';

import {
  FileUtilizationDimensionEnum,
  FILE_UTILIZATION_DIMENSIONS,
} from '../../constants';
import { displayRender, onChangeDateFilter, onChangeDates } from '../../utils';
import { UtilizationByNodeAndTimeType } from '../../types';
import { getNodeFileUtilizationExcelData } from '../../utils/exportToExcel';

type NodeFileUtilizationProps = {
  tableData: UtilizationByNodeAndTimeType[];
  allNodes: string[];
  selectedNode: string;
  setSelectedNode: (val: string) => void;
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  filteredNodeFileUtilizationData: UtilizationByNodeAndTimeType[];
  pdfView: boolean;
  nodeFileUtilizationDateFilter: DateRangeFilter;
  setNodeFileUtilizationDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};

const NodeFileUtilization = ({
  tableData,
  allNodes,
  selectedNode,
  setSelectedNode,
  requestStatus,
  isTableView,
  setIsTableView,
  filteredNodeFileUtilizationData,
  pdfView,
  nodeFileUtilizationDateFilter,
  setNodeFileUtilizationDateFilter,
  excelFilters,
}: NodeFileUtilizationProps) => {
  const { t } = useTranslation();
  const [chartColors, setChartColors] = useState<string[]>([]);

  useEffect(() => {
    setChartColors(generateGraphColors(4));
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      fixed: true,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: t('containerInsight.usageMeteringLabels.dimension'),
      dataIndex: 'dimension',
      key: 'dimension',
      width: 150,
      fixed: true,
    },
    ...uniqBy(filteredNodeFileUtilizationData, 'time').map((item) => ({
      title: item.time,
      key: `${item.time}-utilization`,
      dataIndex: `${item.time}-utilization`,
      width: 120,
    })),
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  defaultValue={[
                    moment(nodeFileUtilizationDateFilter.startDate),
                    moment(nodeFileUtilizationDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(dateString, setNodeFileUtilizationDateFilter)
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters flex flex-align-items-center flex-gap-16">
      <Cascader
        className="width-40"
        options={DateMenu}
        defaultValue={[nodeFileUtilizationDateFilter.option]}
        displayRender={() => displayRender(nodeFileUtilizationDateFilter)}
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setNodeFileUtilizationDateFilter)
        }
      />
      <SelectDropdown
        rootClassName="width-40"
        value={selectedNode}
        options={allNodes.map((item) => ({
          value: item,
          label: item,
        }))}
        placeholder={t('containerInsight.usageMeteringLabels.chooseNode')}
        size={INPUT_SIZE.SMALL}
        loading={
          evaluateRequestArray(requestStatus) === REQUEST_STATUS.PROCESSING
        }
        onSelect={(value: string) => setSelectedNode(value)}
        designVersion2
      />
    </div>
  );

  const getColumnGraphData = () => {
    const barDimensions = FILE_UTILIZATION_DIMENSIONS.filter(
      (item) =>
        ![FileUtilizationDimensionEnum.DISK_UTILIZATION_PERCENTAGE].includes(
          item.dataLabel
        )
    ).map((item) => item.displayLabel);
    return filteredNodeFileUtilizationData
      .filter((item) => barDimensions.includes(item.dimension))
      .map((item) => {
        if (
          item.dimension ===
          FILE_UTILIZATION_DIMENSIONS.find(
            (item) =>
              item.dataLabel === FileUtilizationDimensionEnum.DISK_CAPACITY
          )?.displayLabel
        ) {
          return {
            ...item,
            groupingField: 'column1',
          };
        }
        return {
          ...item,
          groupingField: 'column2',
        };
      });
  };

  const getLineGraphData = () => {
    const barDimensions = FILE_UTILIZATION_DIMENSIONS.filter((item) =>
      [FileUtilizationDimensionEnum.DISK_UTILIZATION_PERCENTAGE].includes(
        item.dataLabel
      )
    ).map((item) => item.displayLabel);
    return filteredNodeFileUtilizationData
      .filter((item) => barDimensions.includes(item.dimension))
      .map((item) => ({ ...item, lineGroupingField: item.dimension }));
  };

  const getValueFormatter = useCallback((v: any) => `${v}%`, []);

  const DiskUtilPercentDisplayLabel = FILE_UTILIZATION_DIMENSIONS.find(
    (item) =>
      FileUtilizationDimensionEnum.DISK_UTILIZATION_PERCENTAGE ===
      item.dataLabel
  )?.displayLabel;

  const getTooltipContentOverride = useCallback(
    (title: string, data: any[]) => {
      return (
        <TooltipContent
          title={title}
          data={data.map((obj) => ({
            ...obj,
            suffix:
              obj.name === DiskUtilPercentDisplayLabel
                ? '%'
                : t('containerInsight.usageMeteringLabels.bytes'),
          }))}
        />
      );
    },
    []
  );

  const getComponent = () => {
    return isTableView ? (
      <Table
        pagination={false}
        dataSource={tableData}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer={true}
      />
    ) : (
      <ColumnLineChart
        plots={[
          {
            type: 'column',
            options: {
              data: getColumnGraphData(),
              xField: 'time',
              yField: 'utilization',
              isStack: true,
              isGroup: true,
              seriesField: 'dimension',
              groupField: 'groupingField',
              xAxis: {
                title: {
                  text: getDurationAxisLabel(nodeFileUtilizationDateFilter),
                },
              },
              yAxis: {
                title: {
                  text: t(
                    'containerInsight.usageMeteringLabels.fileUtilization'
                  ),
                },
                line: {
                  style: {
                    stroke: COLORS.fnGrey,
                    lineWidth: 1,
                    opacity: 0.7,
                  },
                },
              },
              color: chartColors,
              animation: !pdfView,
            },
          },
          {
            type: 'line',
            options: {
              data: getLineGraphData(),
              xField: 'time',
              seriesField: 'lineGroupingField',
              yField: 'utilization',
              xAxis: {
                title: {
                  text: getDurationAxisLabel(nodeFileUtilizationDateFilter),
                },
              },
              yAxis: {
                line: {
                  style: {
                    stroke: COLORS.fnGrey,
                    lineWidth: 1,
                    opacity: 0.7,
                  },
                },
                grid: null,
                position: 'right',
                title: {
                  text: DiskUtilPercentDisplayLabel,
                },
              },
              meta: {
                value: {
                  formatter: getValueFormatter,
                },
              },
              color: chartColors.slice(-1),
              line: {
                style: {
                  lineDash: [5, 5],
                },
              },
              animation: !pdfView,
            },
          },
        ]}
        markerOverride={(_name: any, _index: any, item: any) => {
          if (item.name === DiskUtilPercentDisplayLabel) {
            return {
              symbol: 'hyphen',
              style: {
                lineDash: [2, 2],
              },
            };
          }
        }}
        tooltipContentOverride={getTooltipContentOverride}
      />
    );
  };

  return (
    <div
      className="node-file-utilization graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.nodeFileUtilization')}
        graphName="node-file-utilization"
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getNodeFileUtilizationExcelData(
          filteredNodeFileUtilizationData,
          tableData,
          [
            ...excelFilters,
            {
              heading: t('excelExportLabels.namespace'),
              value: selectedNode?.toString(),
            },
            {
              heading: t('excelExportLabels.startDate'),
              value: nodeFileUtilizationDateFilter.startDate,
            },
            {
              heading: t('excelExportLabels.endDate'),
              value: nodeFileUtilizationDateFilter.endDate,
            },
          ]
        )}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default NodeFileUtilization;
