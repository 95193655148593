import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCostAllocation,
  setAllProjectsList,
  setCostAllocationData,
  setCurrentStepValidation,
  setSelectedProjects,
  setTotalSharedCost,
} from 'redux/costAllocationSlice';
import DropdownCheckbox from 'components/DropdownCheckbox';
import Input from 'components/Input';
import { FormLabel } from 'components/FormLabel';
import { getValidationStyle } from 'utils/validations';

import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { OTHERS_LABEL } from 'constants/graphLabels';
import { VALIDATION_STATUS } from 'pages/ConnectingCSPPage/constants';

const ProjectsSelectionStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    costAllocationData,
    totalSharedCost,
    allProjectsList,
    selectedProjects,
  } = useSelector(selectCostAllocation);

  const [fetchProjectsReqStatus, setFetchProjectsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [projectsReqValidation, setProjectsReqValidation] =
    useState<string>('');
  const [totalCost, setTotalCost] = useState<number>(0);
  const [totalCostReqStatus, setTotalCostReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [selectedProjectsCostReqStatus, setSelectedProjectsCostReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    getProjectsList();
    getCostForAllTheProjects();
  }, []);

  useEffect(() => {
    dispatch(
      setCostAllocationData({
        ...costAllocationData,
        projects: selectedProjects,
      })
    );

    const source = axios.CancelToken.source();

    if (selectedProjects.length > 0) {
      setProjectsReqValidation('');
      getTotalSharedCost(source);
    } else {
      dispatch(setTotalSharedCost(0));
      setSelectedProjectsCostReqStatus(REQUEST_STATUS.SUCCESS);
    }

    return () => {
      source.cancel();
    };
  }, [selectedProjects]);

  useEffect(() => {
    dispatch(setCurrentStepValidation(validateSelectProjectsStep()));
  }, [
    costAllocationData.projects,
    fetchProjectsReqStatus,
    totalCostReqStatus,
    selectedProjectsCostReqStatus,
  ]);

  /**
   * @function getProjectsList
   * @description Function to fetch the list of projects for the selected month.
   */
  const getProjectsList = () => {
    setFetchProjectsReqStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'project',
          field: QUERY_FIELDS.PROJECT_NAME,
        },
      ],
      aggregators: [
        {
          label: 'project',
          function: AGGREGATORS.DISTINCT,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.EQUALS,
              value: costAllocationData.month,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, costAllocationData.connection)
      .then((res: any) => {
        dispatch(
          setAllProjectsList(
            res?.data?.map((project: any) => project?.project ?? OTHERS_LABEL)
          )
        );
        setFetchProjectsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setFetchProjectsReqStatus));
  };

  /**
   * @function getCostForAllTheProjects
   * @description Function to fetch the cost of all the projects for the selected month.
   */
  const getCostForAllTheProjects = () => {
    setTotalCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      columns: [{ label: 'cost', field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT }],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.EQUALS,
              value: costAllocationData.month,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, costAllocationData.connection)
      .then((res: any) => {
        setTotalCost((res?.data[0]?.cost ?? 0).toFixed(2));
        setTotalCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setTotalCostReqStatus));
  };

  /**
   * @function selectedProjectToString
   * @description Function to convert list of string quoted comma separated string
   * @returns quoted comma separated string
   */
  const selectedProjectToString = () => {
    const projects = selectedProjects.map((item) =>
      item === OTHERS_LABEL ? 'null' : item
    );
    const selectedProjectsString = '("';
    return selectedProjectsString + projects.join('","') + '")';
  };

  /**
   * @function getTotalSharedCost
   * @description Function to fetch the cost for the selected projects.
   * @param cancelTokenSource cancel token
   */
  const getTotalSharedCost = (cancelTokenSource: CancelTokenSource) => {
    setSelectedProjectsCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.PROJECT_NAME,
              comparator: COMPARATORS.IN,
              value: selectedProjectToString(),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.INVOICE_MONTH,
              comparator: COMPARATORS.EQUALS,
              value: costAllocationData.month,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(
      requestBody,
      costAllocationData.connection,
      null,
      cancelTokenSource.token
    )
      .then((res: any) => {
        dispatch(
          setTotalSharedCost(Number((res?.data[0]?.cost ?? 0).toFixed(2)))
        );
        setSelectedProjectsCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setSelectedProjectsCostReqStatus));
  };

  /**
   * @function validateSelectProjectsStep
   * @description Function to validate the projects selection step
   * @returns string validation status (VALID, INVALID or SKIP)
   */
  const validateSelectProjectsStep = () => {
    if (
      [
        fetchProjectsReqStatus,
        totalCostReqStatus,
        selectedProjectsCostReqStatus,
      ].includes(REQUEST_STATUS.PROCESSING)
    ) {
      return VALIDATION_STATUS.INVALID;
    }

    if (costAllocationData?.projects?.length > 0) {
      return VALIDATION_STATUS.VALID;
    }

    return VALIDATION_STATUS.INVALID;
  };

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('costAllocations.selectProjects')}
          required={true}
        />
        <DropdownCheckbox
          itemOptions={allProjectsList.map((project) => ({
            title: project,
            value: project,
          }))}
          placeholder={t('costAllocations.projectsPlaceholder')}
          selectedItems={costAllocationData?.projects || []}
          setSelectedItems={(values) => {
            dispatch(setSelectedProjects(values));
            values.length > 0
              ? setProjectsReqValidation('')
              : setProjectsReqValidation(t('costAllocations.projectsRequired'));
          }}
          onHandleBlur={() =>
            costAllocationData?.projects?.length > 0
              ? setProjectsReqValidation('')
              : setProjectsReqValidation(t('costAllocations.projectsRequired'))
          }
          designVersion2
          showSearch={true}
          showSelectionCount={true}
          valueLabelSuffix={t('costAllocations.projects')}
          loading={fetchProjectsReqStatus === REQUEST_STATUS.PROCESSING}
          isDisabled={fetchProjectsReqStatus === REQUEST_STATUS.PROCESSING}
        />
        <span
          style={{
            display: getValidationStyle(projectsReqValidation),
          }}
          className="font-validation-error"
        >
          {projectsReqValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('costAllocations.totalSharedCost')} />
        <Input
          value={
            totalCostReqStatus === REQUEST_STATUS.PROCESSING
              ? 'Loading...'
              : totalSharedCost.toFixed(2)
          }
          disabled
          designVersion2
        />
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('costAllocations.residualCostMonth')} />
        <Input
          value={
            [selectedProjectsCostReqStatus, totalCostReqStatus].includes(
              REQUEST_STATUS.PROCESSING
            )
              ? 'Loading...'
              : (totalCost - totalSharedCost).toFixed(2)
          }
          disabled
          designVersion2
        />
      </div>
    </div>
  );
};

export default ProjectsSelectionStep;
