import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';

import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import DatePicker from 'components/DatePicker';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CostByProjectType } from 'types/dataTypes';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ColumnChart from 'components/ColumnChart';
import { generateGraphColors } from 'utils/dashboardUtils';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { getChartExcelExportData } from 'utils/exportToExcel';

import { getUniqueProjects } from './utils';
import { getMonthlyCostByProjectColumns } from '../../utils';

type MonthlyCostByProjectProps = {
  setSelectedMonthlyCostByProjectData: (val: CostByProjectType[]) => void;
  selectedMonthlyCostByProjectData: CostByProjectType[];
  requestStatus: string;
  monthWiseCostByProject: CostByProjectType[];
  isMonthlyCostByProjectTableView: boolean;
  setIsMonthlyCostByProjectTableView: (value: boolean) => void;
  pdfView?: boolean;
  monthlyCostByProjectStartDate: string;
  monthlyCostByProjectEndDate: string;
  onChangeCostByProjectDateRange: (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};
const MonthlyCostByProject = ({
  setSelectedMonthlyCostByProjectData,
  requestStatus,
  isMonthlyCostByProjectTableView,
  selectedMonthlyCostByProjectData,
  monthWiseCostByProject,
  setIsMonthlyCostByProjectTableView,
  pdfView,
  monthlyCostByProjectStartDate,
  monthlyCostByProjectEndDate,
  onChangeCostByProjectDateRange,
}: MonthlyCostByProjectProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(monthlyCostByProjectStartDate),
          moment(monthlyCostByProjectEndDate),
        ]}
        onChange={onChangeCostByProjectDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
      <GraphFilterDropdown
        allData={getUniqueProjects(monthWiseCostByProject)}
        selectedData={getUniqueProjects(selectedMonthlyCostByProjectData)}
        setSelectedData={(selectedItems: CostByProjectType[]) =>
          setSelectedMonthlyCostByProjectData(
            monthWiseCostByProject.filter((eachProject) =>
              selectedItems.some((item) => item.project === eachProject.project)
            )
          )
        }
        valueSuffix={t('costByProjectMixed.project')}
        fieldName={DROPDOWN_VALUE_FIELDS.PROJECT}
        designVersion2
      />
    </div>
  );

  const getComponent = () =>
    isMonthlyCostByProjectTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={getTableDataSource().map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={getMonthlyCostByProjectColumns(
            monthlyCostByProjectStartDate,
            monthlyCostByProjectEndDate
          )}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={selectedMonthlyCostByProjectData}
        isStack={true}
        xField="month"
        yField="cost"
        groupingField="project"
        xTitle={t('monthlyCostByProject.month')}
        yTitle={t('monthlyCostByProject.cost', {
          currencySymbol: currencySymbol,
        })}
        colorField="project"
        columnColorOverride={generateGraphColors(
          selectedMonthlyCostByProjectData.length
        )}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  /**
   * @function getTableDataSource
   * @description function to get table data source grouped by project
   * @returns object array of table data source
   */
  const getTableDataSource = () => {
    const groupedByProject: any[] = [];
    selectedMonthlyCostByProjectData.forEach((item) => {
      const existingProject = groupedByProject.find(
        (project) => project.project === item.project
      );
      if (existingProject) {
        existingProject[`${item.month}-cost`] = item.cost;
      } else {
        groupedByProject.push({
          project: item.project,
          [`${item.month}-cost`]: item.cost,
        });
      }
    });
    return groupedByProject;
  };

  return (
    <div className="graph-card monthly-cost-by-project" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.monthlyCostByProject')}
        graphName="monthly-cost-by-project"
        filters={filters}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isMonthlyCostByProjectTableView}
        setIsTableView={setIsMonthlyCostByProjectTableView}
        excelData={getChartExcelExportData(
          t('graphHeadings.monthlyCostByProject'),
          getTableDataSource(),
          getMonthlyCostByProjectColumns(
            monthlyCostByProjectStartDate,
            monthlyCostByProjectEndDate
          ),
          {
            connectionName: selectedDashboard!.connectorName,
            startDate: monthlyCostByProjectStartDate,
            endDate: monthlyCostByProjectEndDate,
          }
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default MonthlyCostByProject;
