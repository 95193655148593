export type RolesType = {
  roleId: number;
  name: string;
  status: string;
  createdAt: string;
  roleType: string;
  permissions: PermissionsType[];
};

export enum ROLE_TYPE {
  CUSTOM = 'CUSTOM',
  SYSTEM = 'SYSTEM',
}

export type PermissionsType = { module: string; rights: string[] };

export const NONE_ROLE_OPTION = {
  name: 'NONE',
  roleId: 0,
  status: '',
  createdAt: '',
  roleType: '',
  permissions: [],
};
