import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function createReport
 * @description Function to create a new report
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createReport = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_REPORT, body);
};

/**
 * @function updateReport
 * @description Function to update an existing report
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const updateReport = async (body: any) => {
  return ServiceCalls.post(APIS.UPDATE_REPORT, body);
};

/**
 * @function validateReportName
 * @description Function to validate the report name
 * @param params Object containing the request params
 * @param cancelToken token for cancelling the request
 * @return axios response from GET request
 */
export const validateReportName = async (params: any, cancelToken: any) => {
  return ServiceCalls.get(APIS.VALIDATE_REPORT_NAME, null, params, cancelToken);
};

/**
 * @function getReportByReportName
 * @description Function to fetch the report by the report name
 * @param params Object containing the request params
 * @return axios response from GET request
 */
export const getReportByReportName = async (params: any) => {
  return ServiceCalls.get(APIS.GET_REPORT_BY_NAME, null, params);
};
