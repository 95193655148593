//Remix icons
export enum ICONS {
  SEARCH_2_LINE = 'ri-search-2-line',
  FILTER_2_FILL = 'ri-filter-2-fill',
  FILTER_3_FILL = 'ri-filter-3-fill',
  EQUALIZER_FILL = 'ri-equalizer-fill',
  CLOSE_LINE = 'ri-close-line',
  CLOSE_FILL = 'ri-close-fill',
  ARROW_DOWN_S_FILL = 'ri-arrow-down-s-fill',
  ARROW_UP_S_LINE = 'ri-arrow-up-s-line',
  ARROW_DOWN_S_LINE = 'ri-arrow-down-s-line',
  ARROW_LEFT = 'ri-arrow-left-line',
  ARROW_RIGHT = 'ri-arrow-right-line',
  ARROW_RIGHT_S_LINE = 'ri-arrow-right-s-line',
  ARROW_LEFT_S_LINE = 'ri-arrow-left-s-line',
  ARROW_RIGHT_DOWN_LINE = 'ri-arrow-right-down-line',
  ARROW_RIGHT_UP_LINE = 'ri-arrow-right-up-line',
  RI_FLASHLIGHT_FILL = 'ri-flashlight-fill',
  INFORMATION_LINE = 'ri-information-line',
  ADD_LINE = 'ri-add-line',
  SUBTRACT_LINE = 'ri-subtract-line',
  DELETE_BIN_LINE = 'ri-delete-bin-6-line',
  CHECK_LINE = 'ri-check-line',
  CHECKBOX_CIRCLE_FILL = 'ri-checkbox-circle-fill',
  FULLSCREEN_FILL = 'ri-fullscreen-fill',
  FULLSCREEN_EXIT_FILL = 'ri-fullscreen-exit-fill',
  EDIT_LINE = 'ri-edit-line',
  DOWNLOAD_LINE = 'ri-download-line',
  DOWNLOAD_2_LINE = 'ri-download-2-line',
  EYE_LINE = 'ri-eye-line',
  PIE_CHART_FILL = 'ri-pie-chart-fill',
  REFRESH_LINE = 'ri-refresh-line',
  LIST_CHECK = 'ri-list-check',
  SHARE_LINE = 'ri-share-line',
  UPLOAD_2_LINE = 'ri-upload-2-line',
  MORE_FILL = 'ri-more-fill',
  LOGOUT_BOX_LINE = 'ri-logout-box-line',
  CONTRAST_LINE = 'ri-contrast-2-line',
  HOME_LINE = 'ri-home-3-line',
  ALARM_WARNING_LINE = 'ri-alarm-warning-line',
  CLOUD_LINE = 'ri-cloud-line',
  DONUT_CHART_FILL = 'ri-donut-chart-fill',
  FILE_TEXT_LINE = 'ri-file-text-line',
  USER_LINE = 'ri-user-3-line',
  SETTINGS_LINE = 'ri-settings-4-line',
  UPLOAD_LINE = 'ri-upload-line',
  ARROW_LEFT_RIGHT_LINE = 'ri-arrow-left-right-line',
  INSTALLING_LINE = 'ri-settings-5-line',
  EXTERNAL_LINK_LINE = 'ri-external-link-line',
  MOON_LINE = 'ri-moon-line',
  SUN_LINE = 'ri-sun-line',
  MENU_FOLD_LINE = 'ri-menu-fold-line',
  FILE_COPY_LINE = 'ri-file-copy-line',
  PROHIBITED_LINE = 'ri-prohibited-line',
  LINE_CHART_FILL = 'ri-line-chart-fill',
  BAR_CHART_2_FILL = 'ri-bar-chart-2-fill',
  BAR_CHART_GROUPED_FILL = 'ri-bar-chart-grouped-fill',
  BAR_CHART_HORIZONTAL_FILL = 'ri-bar-chart-horizontal-fill',
  DRAGGABLE = 'ri-draggable',
  DATABASE_2_LINE = 'ri-database-2-line',
  NOTIFICATION_4_LINE = 'ri-notification-4-line',
  FUNCTION_LINE = 'ri-function-line',
  LINE_CHART_LINE = 'ri-line-chart-line',
  RI_SHARE_BOX_LINE = 'ri-share-box-line',
  ROBOT_2_LINE = 'ri-robot-2-line',
  EDIT_BOX_FILL = 'ri-edit-box-fill',
}

//Remix icon sizes
export enum ICONS_SIZE {
  // fixed width
  FW = 'ri-fw',
  // 0.5em
  XXS = 'ri-xxs',
  // 0.75em
  XS = 'ri-xs',
  // 0.875em
  SM = 'ri-sm',
  // 1em
  ONE_X = 'ri-1x',
  // 1.3333em
  LG = 'ri-lg',
  // 1.5em
  XL = 'ri-xl',
  // 2em
  TWO_X = 'ri-2x',
  // 3em
  THREE_X = 'ri-3x',
  // 4em
  FOUR_X = 'ri-4x',
  // 5em
  FIVE_X = 'ri-5x',
  // 6em
  SIX_X = 'ri-6x',
  // 7em
  SEVEN_X = 'ri-7x',
  // 8em
  EIGHT_X = 'ri-8x',
  // 9em
  NINE_X = 'ri-9x',
  // 10em
  TEN_X = 'ri-10x',
}
