import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import { validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { SECRET_KEY_PLACEHOLDER } from 'pages/ConnectingCSPPage/constants';
import { onApiCallError } from 'utils/handleErrors';

import {
  fetchAWScatalogs,
  fetchAWSdatabase,
  fetchAWStable,
} from '../../service';

export const ContainerCostDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection } = useSelector(cloudConnection);

  // Dropdown enabling
  const [disableCatalogs, setDisableCatalogs] = useState<boolean>(true);
  const [disableDatabases, setDisableDatabases] = useState<boolean>(true);
  const [disableTables, setDisableTables] = useState<boolean>(true);

  // Dropdown states
  const [catalogs, setCatalogs] = useState<string[]>([]);
  const [databases, setDatabases] = useState<string[]>([]);
  const [tables, setTables] = useState<string[]>([]);

  //Validation status
  const [
    awsContainerDataCatalogRequiredValidation,
    setAwsContainerDataCatalogRequiredValidation,
  ] = useState('');
  const [awsDataBaseRequiredValidation, setAwsDataBaseRequiredValidation] =
    useState('');
  const [awsTableRequiredValidation, setAwsTableRequiredValidation] =
    useState('');

  //Dropdown values request Status
  const [catalogValuesReqStatus, setCatalogValuesReqStatus] = useState('');
  const [databaseValuesReqStatus, setDatabaseValuesReqStatus] = useState('');
  const [
    containerCostTableValuesReqStatus,
    setContainerCostTableValuesReqStatus,
  ] = useState('');

  useEffect(() => {
    if (
      currentConnection?.awsAccessKeyId &&
      currentConnection?.awsSecretKey &&
      currentConnection?.awsRegion
    ) {
      fetchCatalogs();
    }
  }, [
    currentConnection?.awsAccessKeyId,
    currentConnection?.awsSecretKey,
    currentConnection?.awsRegion,
  ]);

  useEffect(() => {
    if (
      currentConnection?.containerInsightDataCatLog &&
      currentConnection?.awsAccessKeyId &&
      currentConnection?.awsSecretKey &&
      currentConnection?.awsRegion
    ) {
      fetchDatabase();
    }
  }, [
    currentConnection?.awsAccessKeyId,
    currentConnection?.awsSecretKey,
    currentConnection?.awsRegion,
    currentConnection?.containerInsightDataCatLog,
  ]);

  useEffect(() => {
    if (
      currentConnection?.containerInsightDataCatLog &&
      currentConnection?.awsAccessKeyId &&
      currentConnection?.awsSecretKey &&
      currentConnection?.awsRegion &&
      currentConnection?.containerInsightDataset
    ) {
      fetchTable();
    }
  }, [
    currentConnection?.awsAccessKeyId,
    currentConnection?.awsSecretKey,
    currentConnection?.awsRegion,
    currentConnection?.containerInsightDataCatLog,
    currentConnection?.containerInsightDataset,
  ]);

  const fetchCatalogs = () => {
    setCatalogValuesReqStatus(REQUEST_STATUS.PROCESSING);
    const body =
      currentConnection?.awsAccessKeyId !== SECRET_KEY_PLACEHOLDER &&
      currentConnection?.awsSecretKey !== SECRET_KEY_PLACEHOLDER
        ? {
            awsAccessKeyId: currentConnection?.awsAccessKeyId,
            awsSecretKey: currentConnection?.awsSecretKey,
            awsRegion: currentConnection?.awsRegion,
          }
        : {
            connectorId: currentConnection?.connectorId,
          };
    fetchAWScatalogs(body)
      .then((res: any) => {
        setCatalogValuesReqStatus(REQUEST_STATUS.SUCCESS);
        setCatalogs(res.data.responseData);
        if (currentConnection) {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              containerInsightDataCatLog:
                currentConnection?.containerInsightDataCatLog,
            })
          );
        }

        if (res.data.responseData.length !== 0) {
          setDisableCatalogs(false);
        } else {
          setDisableCatalogs(true);
        }
        setAwsContainerDataCatalogRequiredValidation('');
      })
      .catch((e) => {
        dispatch(
          setCurrentConnection({
            ...currentConnection,
            containerInsightDataCatLog: '',
          })
        );
        setDisableCatalogs(true);
        onApiCallError(e, false, setCatalogValuesReqStatus);
      });
  };

  const fetchDatabase = () => {
    setDatabaseValuesReqStatus(REQUEST_STATUS.PROCESSING);
    const body =
      currentConnection?.awsAccessKeyId !== SECRET_KEY_PLACEHOLDER &&
      currentConnection?.awsSecretKey !== SECRET_KEY_PLACEHOLDER
        ? {
            awsAccessKeyId: currentConnection?.awsAccessKeyId,
            awsSecretKey: currentConnection?.awsSecretKey,
            awsRegion: currentConnection?.awsRegion,
            dataCatalog: currentConnection?.containerInsightDataCatLog,
          }
        : {
            connectorId: currentConnection?.connectorId,
            dataCatalog: currentConnection?.containerInsightDataCatLog,
          };
    fetchAWSdatabase(body)
      .then((res: any) => {
        setDatabaseValuesReqStatus(REQUEST_STATUS.SUCCESS);
        setDatabases(res.data.responseData);
        if (currentConnection) {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              containerInsightDataset:
                currentConnection?.containerInsightDataset,
            })
          );
        }

        if (res.data.responseData.length !== 0) {
          setDisableDatabases(false);
        } else {
          setDisableDatabases(true);
        }
        setAwsDataBaseRequiredValidation('');
      })
      .catch((e) => {
        dispatch(
          setCurrentConnection({
            ...currentConnection,
            containerInsightDataset: '',
          })
        );
        setDisableDatabases(true);
        onApiCallError(e, false, setDatabaseValuesReqStatus);
      });
  };

  const fetchTable = () => {
    setContainerCostTableValuesReqStatus(REQUEST_STATUS.PROCESSING);
    const body =
      currentConnection?.awsAccessKeyId !== SECRET_KEY_PLACEHOLDER &&
      currentConnection?.awsSecretKey !== SECRET_KEY_PLACEHOLDER
        ? {
            awsAccessKeyId: currentConnection?.awsAccessKeyId,
            awsSecretKey: currentConnection?.awsSecretKey,
            awsRegion: currentConnection?.awsRegion,
            dataCatalog: currentConnection?.containerInsightDataCatLog,
            billingDataset: currentConnection?.containerInsightDataset,
          }
        : {
            connectorId: currentConnection?.connectorId,
            dataCatalog: currentConnection?.containerInsightDataCatLog,
            billingDataset: currentConnection?.containerInsightDataset,
          };
    fetchAWStable(body)
      .then((res: any) => {
        setTables(res.data.responseData);
        setContainerCostTableValuesReqStatus(REQUEST_STATUS.SUCCESS);
        if (currentConnection) {
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              containerInsightTableName:
                currentConnection?.containerInsightTableName,
            })
          );
        }

        if (res.data.responseData.length !== 0) {
          setDisableTables(false);
        } else {
          setDisableTables(true);
        }
      })
      .catch((e) => {
        dispatch(
          setCurrentConnection({
            ...currentConnection,
            containerInsightTableName: '',
          })
        );
        setDisableTables(true);
        setAwsTableRequiredValidation('');
        onApiCallError(e, false, setContainerCostTableValuesReqStatus);
      });
  };

  return (
    <div className="collapse-body flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t('awsConnectionCSPForm.dataCatalog')} />
        <SelectDropdown
          value={currentConnection?.containerInsightDataCatLog}
          options={catalogs?.map((catalog: any) => ({
            value: catalog,
            label: catalog,
          }))}
          disabled={disableCatalogs}
          loading={catalogValuesReqStatus === REQUEST_STATUS.PROCESSING}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                containerInsightDataCatLog: value,
              })
            );
            setAwsContainerDataCatalogRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.containerInsightDataCatLog,
              t('awsConnectionCSPForm.dataCatalog'),
              setAwsContainerDataCatalogRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${
              awsContainerDataCatalogRequiredValidation ? 'inline' : 'none'
            }`,
          }}
          className="font-validation-error"
        >
          {awsContainerDataCatalogRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('awsConnectionCSPForm.database')} />
        <SelectDropdown
          value={currentConnection?.containerInsightDataset}
          options={databases?.map((database: string) => ({
            value: database,
            label: database,
          }))}
          disabled={disableDatabases}
          loading={databaseValuesReqStatus === REQUEST_STATUS.PROCESSING}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                containerInsightDataset: value,
              })
            );
            setAwsDataBaseRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.containerInsightDataset,
              t('awsConnectionCSPForm.database'),
              setAwsDataBaseRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${awsDataBaseRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {awsDataBaseRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('awsConnectionCSPForm.containerCostTable')} />
        <SelectDropdown
          value={currentConnection?.containerInsightTableName}
          options={tables?.map((table: string) => ({
            label: table,
            value: table,
          }))}
          disabled={disableTables}
          loading={
            containerCostTableValuesReqStatus === REQUEST_STATUS.PROCESSING
          }
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                containerInsightTableName: value,
              })
            );
            setAwsTableRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.containerInsightTableName,
              t('awsConnectionCSPForm.containerCostTable'),
              setAwsTableRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${awsTableRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {awsTableRequiredValidation}
        </span>
      </div>
    </div>
  );
};
