import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import Table from 'components/Table';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { TickFillError } from 'assets/icons';
import { cloudConnection } from 'redux/cloudConnectionSlice';
import { ACCESS_DATA_LABELS } from 'pages/ConnectingCSPPage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';

type AccessSummaryTableProps = {
  accessDetailsRequestStatus: string;
};

const AccessSummaryTable = ({
  accessDetailsRequestStatus,
}: AccessSummaryTableProps) => {
  const { t } = useTranslation();
  const { accessDetails } = useSelector(cloudConnection);

  const columns: ColumnProps<any>[] = [
    {
      title: t('connectionCSPForm.roles'),
      dataIndex: 'role',
      key: 'role',
      align: 'left',
      render: (value: string) =>
        ACCESS_DATA_LABELS.find((labelObj) => labelObj.value === value)
          ?.label ?? value,
    },
    {
      title: t('connectionCSPForm.haveAccess'),
      dataIndex: 'haveAccess',
      key: 'haveAccess',
      align: 'right',
      render: (value: boolean) => {
        return value ? (
          <Icon
            iconName={ICONS.CHECKBOX_CIRCLE_FILL}
            className="tick-success"
          />
        ) : (
          <TickFillError />
        );
      },
    },
  ];

  return (
    <div className="card">
      <Table
        className="access-summary-table"
        pagination={false}
        dataSource={accessDetails.map((connection: any, index) => ({
          ...connection,
          key: index,
        }))}
        columns={columns}
        loading={accessDetailsRequestStatus === REQUEST_STATUS.PROCESSING}
        designVersion2
        data-testid="access-summary-table"
      />
    </div>
  );
};

export default AccessSummaryTable;
