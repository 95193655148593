import { message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import DeleteModal from 'components/DeleteModal';
import { PROVIDER } from 'constants/cloudProviders';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  TagMappingQuickActions,
  TAG_MAPPING_QUICK_ACTIONS_LIST,
} from 'pages/TagMappingPage/constants';
import { ConnectionsData, TagMappingType } from 'pages/TagMappingPage/types';
import {
  deleteTagMapping,
  updateTagMapping,
} from 'pages/TagMappingPage/services';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { onApiCallError } from 'utils/handleErrors';

type ExpandedTagMapRowProps = {
  tagMapData: TagMappingType;
  setActionRecord: (val: TagMappingType | undefined) => void;
  setShowTagMapModal: (val: boolean) => void;
  fetchTagMapData: Function;
};

const ExpandedTagMapRow = ({
  tagMapData,
  setActionRecord,
  setShowTagMapModal,
  fetchTagMapData,
}: ExpandedTagMapRowProps) => {
  const { t } = useTranslation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteActionRecord, setDeleteActionRecord] =
    useState<ConnectionsData>();
  const [deleteRequestStatus, setDeleteRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  /**
   * @function onDeleteConnectionFromTagMap
   * @description Function to delete the connection from tag map
   * @param connectionName connector id to be deleted
   */
  const onDeleteConnectionFromTagMap = (connectionName: string) => {
    if (!connectionName) return;

    setDeleteRequestStatus(REQUEST_STATUS.PROCESSING);

    const body = {
      ...tagMapData,
      cloudMapList: tagMapData.cloudMapList.filter(
        (item) => item.connectionName !== connectionName
      ),
    };

    (tagMapData.cloudMapList.length > 1
      ? updateTagMapping(body)
      : deleteTagMapping({
          tagName: tagMapData.customTagName,
        })
    )
      .then((res: any) => {
        if (res?.status === 200) {
          fetchTagMapData();
          message.success(
            t('tagMapping.deleteConnectionFromTagSuccess', {
              tagName: tagMapData.customTagName,
              connectionName: connectionName,
            })
          );
          setDeleteRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setDeleteRequestStatus(REQUEST_STATUS.ERROR);
          message.error(
            t('tagMapping.deleteConnectionFromTagFailure', {
              tagName: tagMapData.customTagName,
              connectionName: connectionName,
            })
          );
        }
        setShowDeleteModal(false);
      })
      .catch((e: any) => {
        const errorMessage =
          t('tagMapping.deleteConnectionFromTagFailure', {
            tagName: tagMapData.customTagName,
            connectionName: connectionName,
          }) +
          ' ' +
          e?.response?.data?.message;
        onApiCallError(e, true, setDeleteRequestStatus, errorMessage);
        setShowDeleteModal(false);
      });
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions
   * @param action quick action clicked
   */
  const handleQuickAction = (action: string, record: ConnectionsData) => {
    setActionRecord(tagMapData);

    if (action === TagMappingQuickActions.EDIT) {
      setShowTagMapModal(true);
    } else {
      setDeleteActionRecord(record);
      setShowDeleteModal(true);
    }
  };

  const expandRowColumns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: '3%',
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: t('tagMapping.connections'),
      dataIndex: 'connectionName',
      key: 'connectionName',
      render: (_text: string, record: ConnectionsData) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={getProviderSmallLogo(record.providerName)}
            alt={`${PROVIDER.GCP} Logo`}
          />
          {record?.connectionName}
        </div>
      ),
      width: '20%',
    },
    {
      title: t('tagMapping.fields'),
      dataIndex: 'fields',
      key: 'fields',
      render: (_text: string, record: ConnectionsData) => (
        <div className="flex flex-align-items-center flex-gap-4 flex-wrap">
          {record.values.map((item) => (
            <div className="field-name status" key={item.fieldName}>
              {item.fieldName}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: t('tagMapping.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: ConnectionsData) => {
        return (
          <QuickActionMenu
            quickActions={TAG_MAPPING_QUICK_ACTIONS_LIST}
            quickActionHandler={(action: string) => {
              handleQuickAction(action, record);
            }}
          />
        );
      },
      align: 'center' as const,
      width: '10%',
    },
  ];

  return (
    <>
      <Table
        pagination={false}
        columns={expandRowColumns}
        dataSource={tagMapData.cloudMapList.map((data, index) => ({
          ...data,
          key: index,
        }))}
        designVersion2={true}
      />
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={() =>
            onDeleteConnectionFromTagMap(
              deleteActionRecord?.connectionName ?? ''
            )
          }
          loading={deleteRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </>
  );
};

export default ExpandedTagMapRow;
