import { Instances } from 'constants/endpoints';
import { ROLES, AUTHORITIES } from 'constants/roles';

export const getPermissionsMap = (authorities: string[]) => {
  const permissions: { [key: string]: boolean } = {};
  authorities.forEach((authority) => {
    switch (authority) {
      case AUTHORITIES.DASHBOARD_LIST: {
        permissions.dashboardList = true;
        permissions.dashboardListAll = true;
        break;
      }
      case AUTHORITIES.DASHBOARD_LIST_ASSIGNED: {
        permissions.dashboardList = true;
        break;
      }
      case AUTHORITIES.DASHBOARD_READ: {
        permissions.dashboardRead = true;
        break;
      }
      case AUTHORITIES.DASHBOARD_WRITE: {
        permissions.dashboardWrite = true;
        break;
      }
      case AUTHORITIES.DASHBOARD_MODIFY: {
        permissions.dashboardModify = true;
        break;
      }
      case AUTHORITIES.CLOUD_CONNECTOR_LIST: {
        permissions.cloudConnectorListALL = true;
        permissions.cloudConnectorList = true;
        break;
      }

      case AUTHORITIES.CLOUD_CONNECTOR_LIST_ASSIGNED: {
        permissions.cloudConnectorList = true;
        break;
      }
      case AUTHORITIES.CLOUD_CONNECTOR_READ: {
        permissions.cloudConnectorRead = true;
        break;
      }
      case AUTHORITIES.CLOUD_CONNECTOR_WRITE: {
        permissions.cloudConnectorWrite = true;
        break;
      }
      case AUTHORITIES.CLOUD_CONNECTOR_MODIFY: {
        permissions.cloudConnectorModify = true;
        break;
      }
      case AUTHORITIES.BUDGET_ALERTS_LIST: {
        permissions.budgetAlertsList = true;
        break;
      }
      case AUTHORITIES.BUDGET_ALERTS_READ: {
        permissions.budgetAlertsRead = true;
        break;
      }
      case AUTHORITIES.BUDGET_ALERTS_WRITE: {
        permissions.budgetAlertsWrite = true;
        break;
      }
      case AUTHORITIES.BUDGET_ALERTS_MODIFY: {
        permissions.budgetAlertsModify = true;
        break;
      }
      case AUTHORITIES.COST_CONTROL_LIST: {
        permissions.costControlList = true;
        break;
      }
      case AUTHORITIES.COST_CONTROL_READ: {
        permissions.costControlRead = true;
        break;
      }
      case AUTHORITIES.COST_CONTROL_WRITE: {
        permissions.costControlWrite = true;
        break;
      }
      case AUTHORITIES.COST_CONTROL_MODIFY: {
        permissions.costControlModify = true;
        break;
      }
      case AUTHORITIES.COST_ALLOCATION_LIST: {
        permissions.costAllocationList = true;
        break;
      }
      case AUTHORITIES.COST_ALLOCATION_READ: {
        permissions.costAllocationRead = true;
        break;
      }
      case AUTHORITIES.COST_ALLOCATION_WRITE: {
        permissions.costAllocationWrite = true;
        break;
      }
      case AUTHORITIES.COST_ALLOCATION_MODIFY: {
        permissions.costAllocationModify = true;
        break;
      }
      case AUTHORITIES.USER_MANAGEMENT_LIST: {
        permissions.userManagementList = true;
        break;
      }
      case AUTHORITIES.USER_MANAGEMENT_READ: {
        permissions.userManagementRead = true;
        break;
      }
      case AUTHORITIES.USER_MANAGEMENT_WRITE: {
        permissions.userManagementWrite = true;
        break;
      }
      case AUTHORITIES.USER_MANAGEMENT_MODIFY: {
        permissions.userManagementModify = true;
        break;
      }
      case AUTHORITIES.THIRD_PARTY_APPS_LIST: {
        permissions.thirdPartyAppsList = true;
        break;
      }
      case AUTHORITIES.THIRD_PARTY_APPS_READ: {
        permissions.thirdPartyAppsRead = true;
        break;
      }
      case AUTHORITIES.THIRD_PARTY_APPS_WRITE: {
        permissions.thirdPartyAppsWrite = true;
        break;
      }
      case AUTHORITIES.THIRD_PARTY_APPS_MODIFY: {
        permissions.thirdPartyAppsModify = true;
        break;
      }
    }
  });
  if (localStorage.getItem('role') === ROLES.ADMIN) {
    permissions.ticketIntegration = true;
    permissions.dimensions = true;
    permissions.adminControls = true;
  }

  permissions.profile = true;
  permissions.integrations = true;
  permissions.granulate = true;

  // Permission based on the instance
  if (window.location.hostname === Instances.PRUDENTIAL) {
    permissions.granulate = false;
  }

  return permissions;
};
