import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentStep,
  selectGranulate,
  setGranulateConnectionView,
  setGranulateProviderView,
  setSelectedProvider,
} from 'redux/granulateSlice';
import {
  selectGranulateConnection,
  setGCPConnection,
  setAWSConnection,
} from 'redux/granulateConnectionSlice';
import Button from 'components/Button';
import {
  GranulateConnectionView,
  GranulateProviderView,
} from 'constants/granulate';
import { ICONS } from 'constants/icons';
import { BUTTON_TYPE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { PROVIDER } from 'constants/cloudProviders';
import { installAWSGranulate, installGCPGranulate } from '../../../services';

import './index.scss';

type FooterProps = {
  selectedProvider: string;
  itemsLength: number;
  submitDisabled?: boolean;
};

const Footer = ({
  selectedProvider,
  itemsLength,
  submitDisabled,
}: FooterProps) => {
  const { t } = useTranslation();

  const { currentStep } = useSelector(selectGranulate);
  const { gcpConnection, awsConnection } = useSelector(
    selectGranulateConnection
  );
  const dispatch = useDispatch();

  const [installingStatus, setInstallingStatus] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );

  const handleSubmit = () => {
    setInstallingStatus(REQUEST_STATUS.PROCESSING);
    const userEmail = localStorage.getItem('email');

    const handleInstallation = (
      body: any,
      installFunction: (body: any) => Promise<unknown>
    ) => {
      installFunction(body)
        .then((res: any) => {
          if (res.status === 200) {
            setInstallingStatus(REQUEST_STATUS.SUCCESS);
            dispatch(
              setGranulateConnectionView(GranulateConnectionView.SUCCESS)
            );
          } else {
            setInstallingStatus(REQUEST_STATUS.ERROR);
            dispatch(setGranulateConnectionView(GranulateConnectionView.ERROR));
          }
        })
        .catch((e: any) => {
          setInstallingStatus(REQUEST_STATUS.ERROR);
          dispatch(setGranulateConnectionView(GranulateConnectionView.ERROR));
          onApiCallError(e, true);
        });
    };

    if (selectedProvider === PROVIDER.GCP) {
      const body = {
        cloudConnectionName:
          gcpConnection.cloudConnection || gcpConnection.connectionName,
        serviceName: gcpConnection.serviceName,
        clusterName: gcpConnection.cluster,
        zone: gcpConnection.region,
        userEmail,
        connectorId: gcpConnection.connectorId,
        project: gcpConnection.project,
      };

      handleInstallation(body, installGCPGranulate);
      resetGCPConnection();
    } else if (selectedProvider === PROVIDER.AWS) {
      const body = {
        cloudConnectionName:
          awsConnection.cloudConnection || awsConnection.connectionName,
        serviceName: awsConnection.serviceName,
        clusterName: awsConnection.cluster,
        region: awsConnection.region,
        userEmail,
        connectorId: awsConnection.connectorId,
      };

      handleInstallation(body, installAWSGranulate);
      resetAWSConnection();
    }
  };

  const resetGCPConnection = () => {
    dispatch(
      setGCPConnection({
        connectionName: '',
        cloudConnection: '',
        serviceName: '',
        cluster: '',
        region: '',
        connectorId: '',
        file: undefined,
        project: '',
      })
    );
  };

  const resetAWSConnection = () => {
    dispatch(
      setAWSConnection({
        connectionName: '',
        cloudConnection: '',
        serviceName: '',
        cluster: '',
        region: '',
        connectorId: '',
        secretKey: '',
        accessKey: '',
      })
    );
  };

  return (
    <footer className="provider-footer flex flex-space-between flex-align-items-center">
      <Button
        className="cursor-pointer"
        type={BUTTON_TYPE.LINK}
        title={t('gProfilerConnectionPage.addConnection.cancel')}
        onClick={() => {
          resetGCPConnection();
          dispatch(
            setGranulateConnectionView(GranulateConnectionView.TABLE_VIEW)
          );
          dispatch(
            setGranulateProviderView(GranulateProviderView.PROVIDER_LIST)
          );
          dispatch(setSelectedProvider(''));
          setInstallingStatus(REQUEST_STATUS.SUCCESS);
          currentStep > 0 && dispatch(setCurrentStep(currentStep - 1));
        }}
      />
      {currentStep === 0 && (
        <Button
          onClick={() => {
            dispatch(setGranulateProviderView(selectedProvider));
            currentStep < itemsLength - 1 &&
              dispatch(setCurrentStep(currentStep + 1));
          }}
          title={`${t('gProfilerConnectionPage.addConnection.continue')}`}
          iconName={ICONS.ARROW_RIGHT}
          disabled={!selectedProvider}
        />
      )}
      {currentStep === 1 && (
        <div className="flex flex-gap-8">
          <Button
            title={`${t('gProfilerConnectionPage.addConnection.previousStep')}`}
            iconName={ICONS.ARROW_LEFT}
            reverseIconTitleOrder
            type={BUTTON_TYPE.OUTLINED}
            onClick={() => {
              resetGCPConnection();
              dispatch(
                setGranulateProviderView(GranulateProviderView.PROVIDER_LIST)
              );
              dispatch(setCurrentStep(currentStep - 1));
              setInstallingStatus(REQUEST_STATUS.SUCCESS);
            }}
          />
          <Button
            title={`${t('gProfilerConnectionPage.addConnection.finished')}`}
            iconName={ICONS.ARROW_RIGHT}
            onClick={handleSubmit}
            disabled={submitDisabled}
            loading={installingStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
      )}
    </footer>
  );
};

export default Footer;
