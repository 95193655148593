import i18n from 'i18n';

import { BillingMappingType } from './types';

export enum BillingMappingQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const BILLING_MAPPING_QUICK_ACTIONS_LIST = [
  {
    id: BillingMappingQuickActions.EDIT,
    title: i18n.t('billingMapping.edit'),
  },
  {
    id: BillingMappingQuickActions.DELETE,
    title: i18n.t('billingMapping.delete'),
  },
];

export const defaultBillingMapData: BillingMappingType = {
  dimensionName: '',
  billingDimensionMap: [],
  metricUsage: false,
};

export const DROPDOWN_NONE = 'NONE';

export const DROPDOWN_NONE_OPTION = {
  label: i18n.t('billingMapping.none'),
  value: DROPDOWN_NONE,
};
