import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SuccessIcon } from 'assets/icons';
import SuccessComponent from 'components/SuccessComponent';
import ErrorComponent from 'components/ErrorComponent';
import Button from 'components/Button';
import QuickActionMenu from 'components/QuickActionMenu';
import Table from 'components/Table';
import { REQUEST_STATUS } from 'constants/requestBody';
import { BUTTON_SIZE } from 'constants/appearance';
import {
  PurchaseCartComponents,
  SPAndEC2RIStatusLabels,
} from 'pages/PurchasePage/constants';
import { selectPurchase } from 'redux/purchaseSlice';

import BuyEc2RiFormModal from '../BuyEc2RiFormModal';
import { AzureComputeSpType } from '../../types';

const ComputeSavingPlans = () => {
  const { t } = useTranslation();
  const { selectedConnection } = useSelector(selectPurchase);

  const computeSpList: AzureComputeSpType[] = [];
  const fetchComputeSpRequestStatus = REQUEST_STATUS.SUCCESS;
  const [computeSpForm, setComputeSpForm] = useState(false);
  const [computeSpComponent, setComputeSpComponent] = useState(
    PurchaseCartComponents.CART
  );

  useEffect(() => {
    if (selectedConnection) {
      fetchAllComputeSavingPlans();
    }
  }, [selectedConnection]);

  /**
   * @function fetchAllComputeSavingPlans
   * @description Function to fetch and set all the Azure Compute SP
   */
  const fetchAllComputeSavingPlans = () => {
    // Fetch all the Compute SP
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('purchasePage.savingsPlansLabels.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('purchasePage.savingsPlansLabels.billingSubscription'),
      dataIndex: 'billingSubscription',
      key: 'billingSubscription',
    },
    {
      title: t('purchasePage.savingsPlansLabels.term'),
      dataIndex: 'term',
      key: 'term',
    },
    {
      title: t('purchasePage.savingsPlansLabels.hourly'),
      dataIndex: 'hourly',
      key: 'hourly',
    },
    {
      title: t('purchasePage.savingsPlansLabels.billingFrequency'),
      dataIndex: 'billingFrequency',
      key: 'billingFrequency',
    },
    {
      title: t('purchasePage.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => (
        <span className={`status ${text.toLowerCase()}`}>
          {
            SPAndEC2RIStatusLabels.find(
              (item) => item.key === text.toLowerCase()
            )?.label
          }
        </span>
      ),
    },
    {
      title: t('purchasePage.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: any) => {
        return (
          <QuickActionMenu
            disabled
            quickActions={[]}
            quickActionHandler={() => {}}
          />
        );
      },
      align: 'center',
      width: 80,
    },
  ];

  if (computeSpComponent === PurchaseCartComponents.SUCCESS)
    return (
      <SuccessComponent
        mainTitle={t('purchasePage.cart.successfullyPurchased')}
        subTitle={t(
          'purchasePage.savingsPlansLabels.computeSuccessfullyPurchased'
        )}
        buttonTitle={t('purchasePage.savingsPlansLabels.goToPurchasedCompute')}
        onHandleButtonClick={() =>
          setComputeSpComponent(PurchaseCartComponents.CART)
        }
        icon={<SuccessIcon />}
      />
    );

  if (computeSpComponent === PurchaseCartComponents.ERROR)
    return (
      <ErrorComponent
        mainTitle={t('purchasePage.cart.error')}
        buttonTitle={t('purchasePage.cart.tryAgain')}
        linkTitle={t('purchasePage.savingsPlansLabels.goToPurchasedCompute')}
        onHandleButtonClick={() => {
          setComputeSpComponent(PurchaseCartComponents.CART);
          setComputeSpForm(true);
        }}
        onHandleLinkClick={() =>
          setComputeSpComponent(PurchaseCartComponents.CART)
        }
      />
    );

  return (
    <>
      <section className="flex flex-column flex-gap-16">
        <div className="flex flex-align-items-center flex-space-between">
          <div className="form-header">
            {t('purchasePage.savingsPlansLabels.purchasedCompute')}
          </div>
          <Button
            title={t('purchasePage.savingsPlansLabels.purchase')}
            size={BUTTON_SIZE.SMALL}
            onClick={() => setComputeSpForm(true)}
          />
        </div>
        <Table
          pagination={false}
          columns={columns}
          loading={fetchComputeSpRequestStatus === REQUEST_STATUS.PROCESSING}
          designVersion2={true}
          scroll={{ y: 'calc(100vh - 20em)' }}
        />
      </section>
      {computeSpForm && (
        <BuyEc2RiFormModal show={computeSpForm} setShow={setComputeSpForm} />
      )}
    </>
  );
};

export default ComputeSavingPlans;
