import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import Button from 'components/Button';
import DrawerComponent from 'components/DrawerComponent';
import { BUTTON_TYPE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getTicketInfo } from 'pages/CostOptimizationInsightsPage/services';
import { getTicketConnection } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  AwsRecommendationListType,
  AzureRecommendationListType,
  GcpRecommendationListType,
} from 'pages/CostOptimizationInsightsPage/types';
import { getStatusTextClassName } from 'pages/CostOptimizationInsightsPage/utils';
import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';

import { TicketInfoType } from './constants';
import './index.scss';

type TicketInfoDrawerProps = {
  ticket:
    | GcpRecommendationListType
    | AwsRecommendationListType
    | AzureRecommendationListType
    | RecommendationList;
  show: boolean;
  setShow: (val: boolean) => void;
};

const TicketInfoDrawer = ({ ticket, show, setShow }: TicketInfoDrawerProps) => {
  const { t } = useTranslation();

  const [ticketInfoData, setTicketInfoData] = useState<TicketInfoType>();
  const [ticketGoToUrlEndpoint, setTicketGoToUrlEndpoint] = useState();
  const [ticketInfoRequestStatus, setTicketInfoRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [ticketUrlRequestStatus, setTicketUrlRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    getSnowUrl();
  }, []);

  useEffect(() => {
    if (ticket) {
      getTicketInformation();
    }
  }, [ticket]);

  /**
   * @function getSnowUrl
   * @description Function to get the ServiceNow url for navigation
   */
  const getSnowUrl = () => {
    setTicketUrlRequestStatus(REQUEST_STATUS.PROCESSING);
    getTicketConnection()
      .then((res: any) => {
        if (res?.status === 200) {
          setTicketUrlRequestStatus(REQUEST_STATUS.SUCCESS);
          setTicketGoToUrlEndpoint(res.data.responseData.endpoint);
        }
      })
      .catch((e) => onApiCallError(e, false, setTicketUrlRequestStatus));
  };

  /**
   * @function getSnowUrl
   * @description Function to fetch the ticket information for a sys id
   */
  const getTicketInformation = () => {
    setTicketInfoRequestStatus(REQUEST_STATUS.PROCESSING);
    getTicketInfo(ticket.sysId!)
      .then((res: any) => {
        if (res?.status === 200) {
          setTicketInfoRequestStatus(REQUEST_STATUS.SUCCESS);
          setTicketInfoData(res.data.responseData);
        }
      })
      .catch((e) => onApiCallError(e, false, setTicketInfoRequestStatus));
  };

  /**
   * @function onGoToIncident
   * @description Opens the ticket url in a new tab when clicked on Go To Incident
   */
  const onGoToIncident = () => {
    const link = `${ticketGoToUrlEndpoint}incident.do?sys_id=${ticket.sysId}`;
    window.open(link, '_blank');
  };

  const getComponent = () => {
    if (
      ticketInfoRequestStatus === REQUEST_STATUS.PROCESSING ||
      ticketUrlRequestStatus === REQUEST_STATUS.PROCESSING
    )
      return <Loader />;
    else if (
      ticketInfoRequestStatus === REQUEST_STATUS.SUCCESS &&
      ticketUrlRequestStatus === REQUEST_STATUS.SUCCESS &&
      ticketInfoData
    )
      return (
        <div className="flex flex-column flex-gap-24 full-height">
          <div className="flex flex-column flex-gap-8">
            <span className="font-caption-bold">
              {t('ticketInfoModal.shortDescription')}
            </span>
            <span className="font-subHeader">
              {ticketInfoData.short_description}
            </span>
          </div>
          <div className="flex flex-column flex-gap-8">
            <span className="font-caption-bold">
              {t('ticketInfoModal.description')}
            </span>
            <span className="font-subHeader">{ticketInfoData.description}</span>
          </div>
          <div className="flex flex-row flex-space-between">
            <div className="flex flex-column flex-gap-8 flex-fit">
              <span className="font-caption-bold">
                {t('ticketInfoModal.status')}
              </span>
              <span
                className={`font-button status-text ${getStatusTextClassName(
                  ticket.serviceNowStatus
                )}`}
              >
                {ticket.serviceNowStatus}
              </span>
            </div>
            <div className="flex flex-column flex-gap-8 flex-fit">
              <span className="font-caption-bold">
                {t('ticketInfoModal.priority')}
              </span>
              <span className="font-subHeader">{ticketInfoData.priority}</span>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div className="flex stretch flex-center text-align-center form-header">
          {t('apiCallErrorMessage')}
        </div>
      );
  };

  return (
    <DrawerComponent
      className="ticket-info-drawer"
      open={show}
      title={
        ticketInfoData?.number
          ? t('ticketInfoModal.incidentIdHeader', {
              id: ticketInfoData?.number,
            })
          : undefined
      }
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-end">
          <Button
            title={t('createTicketDrawer.close')}
            onClick={() => setShow(false)}
            type={BUTTON_TYPE.LINK}
          />
          <Button
            title={t('ticketInfoModal.goToIncident')}
            onClick={onGoToIncident}
          />
        </div>
      }
      dataTestId="ticket-info-drawer"
    >
      {getComponent()}
    </DrawerComponent>
  );
};

export default TicketInfoDrawer;
