import { useDispatch, useSelector } from 'react-redux';
import {
  selectDashboard,
  setIsEmptyDashboardList,
  setSelectedDashboard,
  setTableViewEnabled,
} from 'redux/dashboardSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  customDashboard,
  setCustomDashBoardMode,
  setIsAddNewDashboard,
} from 'redux/customDashboardSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import ConnectionsDashboard from 'pages/OverviewPage/components/ConnectionsDashboard';
import CustomViewDataSourceDrawer from 'components/CustomViewDataSourceDrawer';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { CUSTOM_DASHBOARD_MODE, MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { REQUEST_STATUS } from 'constants/requestBody';
import GroupsDashboard from './components/GroupsDashboard';

import './index.scss';

const OverviewPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedMyDashboardType,
    isEmptyDashboardList,
    dashboardList,
    selectedDashboard,
  } = useSelector(selectDashboard);
  const { customDashBoardMode } = useSelector(customDashboard);
  const { permissions } = useSelector(userAuthorization);
  const { urlDashboardId } = useParams();

  const [showDataSourceModal, setShowDataSourceModal] = useState(false);
  const [dashboardRequestStatus, setDashboardRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    dispatch(setSelectedDashboard(null));
    if (
      customDashBoardMode === CUSTOM_DASHBOARD_MODE.DEV ||
      customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED
    ) {
      dispatch(setCustomDashBoardMode(CUSTOM_DASHBOARD_MODE.PUBLISHED));
      dispatch(setIsAddNewDashboard(false));
    }

    dispatch(setIsEmptyDashboardList(false));

    return () => {
      dispatch(setCustomDashBoardMode(CUSTOM_DASHBOARD_MODE.PUBLISHED));
    };
  }, []);

  useEffect(() => {
    setTableViewEnabled(false);
  }, [selectedDashboard]);

  if (!permissions.dashboardList) {
    return (
      <div className="page-content dashboard-message">
        {t('dashboardLabels.noDashboardPermission')}
      </div>
    );
  }

  if (isEmptyDashboardList) {
    return (
      <div className="page-content dashboard-message">
        {t('dashboardLabels.noDashboardListMessage')}
      </div>
    );
  }

  if (
    urlDashboardId &&
    dashboardRequestStatus !== REQUEST_STATUS.PROCESSING &&
    dashboardList.length &&
    !dashboardList.some((dashboard) => dashboard.id === urlDashboardId)
  ) {
    return (
      <div className="page-content dashboard-message flex flex-align-items-center">
        {t('dashboardLabels.dashboardIdDoesntExist')}
        <Button
          title={`Goto ${t('navigationMenu.spendDiagnostics')}`}
          type={BUTTON_TYPE.LINK}
          onClick={() => navigate(NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS)}
        />
      </div>
    );
  }

  return (
    <>
      {selectedMyDashboardType === MY_DASHBOARD_TYPES.GROUP ? (
        <GroupsDashboard
          setShowDataSourceModal={setShowDataSourceModal}
          dashboardRequestStatus={dashboardRequestStatus}
          setDashboardRequestStatus={setDashboardRequestStatus}
        />
      ) : (
        <ConnectionsDashboard
          setShowDataSourceModal={setShowDataSourceModal}
          dashboardRequestStatus={dashboardRequestStatus}
          setDashboardRequestStatus={setDashboardRequestStatus}
        />
      )}
      {showDataSourceModal && (
        <CustomViewDataSourceDrawer
          show={showDataSourceModal}
          setShow={setShowDataSourceModal}
        />
      )}
    </>
  );
};

export default OverviewPage;
