import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { dispatchUpdatedCustomViewData } from 'pages/CustomDashboardPage/utils';
import {
  customDashboard,
  setSelectedMetrics,
} from 'redux/customDashboardSlice';

import MetricListItem from './components/MetricsListItem';
import './index.scss';

const MetricList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedMetrics } = useSelector(customDashboard);

  /**
   * @function handleOnDragEnd
   * @description Updates the selected metrics list based on the drag and drop
   * @param result - result of the drag and drop
   */
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedMetrics = Array.from(selectedMetrics);
    const [reorderedItem] = updatedMetrics.splice(result.source.index, 1);
    updatedMetrics.splice(result.destination.index, 0, reorderedItem);
    dispatch(setSelectedMetrics(updatedMetrics));
    dispatchUpdatedCustomViewData();
  };

  return (
    <div className="metric-list">
      {selectedMetrics?.length === 0 ? (
        <div className="empty-metrics-message font-subHeader-small">
          {t('customDashboard.optionsLabels.clickToAddMetrics')}
        </div>
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="metrics">
            {(provided) => (
              <ul
                className="draggable-metric-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selectedMetrics.map((metric, index) => (
                  <Draggable
                    key={metric.field}
                    draggableId={metric.field}
                    index={index}
                  >
                    {(provided) => (
                      <li ref={provided.innerRef} {...provided.draggableProps}>
                        <MetricListItem
                          metric={metric}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default MetricList;
