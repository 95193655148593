import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import UploadFile from 'components/UploadFile';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { validateEmptyField } from 'utils/validations';
import { uploadConnectionFile } from 'utils/services';
import { SECRET_KEY_PLACEHOLDER } from 'pages/ConnectingCSPPage/constants';
import { onApiCallError } from 'utils/handleErrors';

import { fetchAWScatalogs, fetchAWSdatabase } from '../../service';

type RecommendationDetailsProps = {
  removeFile: () => void;
  fileName: string;
  setFileName: (val: string) => void;
  accEnvMapFileIdValidation: string;
  setAccEnvMapFileIdValidation: (val: string) => void;
};

export const RecommendationDetails = ({
  removeFile,
  fileName,
  setFileName,
  accEnvMapFileIdValidation,
  setAccEnvMapFileIdValidation,
}: RecommendationDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection } = useSelector(cloudConnection);

  const [catalogs, setCatalogs] = useState<string[]>([]);
  const [catalogValuesReqStatus, setCatalogValuesReqStatus] = useState('');
  const [
    awsDataCatalogRequiredValidation,
    setAwsDataCatalogRequiredValidation,
  ] = useState('');

  const [recommendationDatabases, setRecommendationDatabases] = useState<
    string[]
  >([]);
  const [databaseValuesReqStatus, setDatabaseValuesReqStatus] = useState('');
  const [awsDataBaseRequiredValidation, setAwsDataBaseRequiredValidation] =
    useState('');

  useEffect(() => {
    fetchCatalogs();
  }, []);

  useEffect(() => {
    currentConnection?.recommendationsDataCatalog && fetchDatabase();
  }, [currentConnection?.recommendationsDataCatalog]);

  const fetchCatalogs = () => {
    setCatalogValuesReqStatus(REQUEST_STATUS.PROCESSING);
    const body =
      currentConnection?.awsAccessKeyId !== SECRET_KEY_PLACEHOLDER &&
      currentConnection?.awsSecretKey !== SECRET_KEY_PLACEHOLDER
        ? {
            awsAccessKeyId: currentConnection?.awsAccessKeyId,
            awsSecretKey: currentConnection?.awsSecretKey,
            awsRegion: currentConnection?.awsRegion,
          }
        : {
            connectorId: currentConnection?.connectorId,
          };
    fetchAWScatalogs(body)
      .then((res: any) => {
        setCatalogValuesReqStatus(REQUEST_STATUS.SUCCESS);
        setCatalogs(res.data.responseData);
        setAwsDataCatalogRequiredValidation('');
      })
      .catch((e) => {
        onApiCallError(e, false, setCatalogValuesReqStatus);
        dispatch(
          setCurrentConnection({
            ...currentConnection,
            recommendationsDataCatalog: undefined,
          })
        );
      });
  };

  const fetchDatabase = () => {
    setDatabaseValuesReqStatus(REQUEST_STATUS.PROCESSING);

    const body =
      currentConnection?.awsAccessKeyId !== SECRET_KEY_PLACEHOLDER &&
      currentConnection?.awsSecretKey !== SECRET_KEY_PLACEHOLDER
        ? {
            awsAccessKeyId: currentConnection?.awsAccessKeyId,
            awsSecretKey: currentConnection?.awsSecretKey,
            awsRegion: currentConnection?.awsRegion,
            dataCatalog: currentConnection?.recommendationsDataCatalog,
          }
        : {
            connectorId: currentConnection?.connectorId,
            dataCatalog: currentConnection?.recommendationsDataCatalog,
          };
    fetchAWSdatabase(body)
      .then((res: any) => {
        setDatabaseValuesReqStatus(REQUEST_STATUS.SUCCESS);
        setRecommendationDatabases(res.data.responseData);
        setAwsDataBaseRequiredValidation('');
      })
      .catch((e) => {
        onApiCallError(e, false, setDatabaseValuesReqStatus);
        dispatch(
          setCurrentConnection({
            ...currentConnection,
            recommendationDataset: undefined,
          })
        );
      });
  };

  const CatalogDropDown = (
    <>
      {catalogs?.map((catalog: any) => (
        <Select.Option key={catalog} value={catalog}>
          {catalog}
        </Select.Option>
      ))}
    </>
  );

  const DatabaseDropDown = (
    <>
      {recommendationDatabases?.map((database: string) => (
        <Select.Option key={database} value={database}>
          {database}
        </Select.Option>
      ))}
    </>
  );

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t('awsConnectionCSPForm.awsRecommendationCatalog')} />
        <SelectDropdown
          value={currentConnection?.recommendationsDataCatalog}
          menu={CatalogDropDown}
          loading={catalogValuesReqStatus === REQUEST_STATUS.PROCESSING}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                recommendationsDataCatalog: value,
              })
            );
            setAwsDataCatalogRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.recommendationsDataCatalog,
              t('awsConnectionCSPForm.awsRecommendationCatalog'),
              setAwsDataCatalogRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${awsDataCatalogRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {awsDataCatalogRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t(`awsConnectionCSPForm.recommendationDatabase`)} />
        <SelectDropdown
          value={currentConnection?.recommendationDataset}
          menu={DatabaseDropDown}
          loading={databaseValuesReqStatus === REQUEST_STATUS.PROCESSING}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                recommendationDataset: value,
              })
            );
            setAwsDataBaseRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.recommendationDataset,
              t('awsConnectionCSPForm.recommendationDatabase'),
              setAwsDataBaseRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${awsDataBaseRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {awsDataBaseRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t(`awsConnectionCSPForm.accountIdToEnvType`)} />
        <UploadFile
          accept={'.csv'}
          isFileUploaded={currentConnection?.accEnvMapFileId !== undefined}
          filename={fileName}
          buttonTitle={t(`connectionCSPForm.gcpUpload`)}
          onSelectFile={async (file, onSuccess, onError) => {
            try {
              const resData: any = await uploadConnectionFile(file);
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  accEnvMapFileId: resData.data.responseData,
                })
              );
              onSuccess(resData);
              setFileName(file.name);
              setAccEnvMapFileIdValidation('');
            } catch (error) {
              onError({ error });
            }
          }}
          onRemoveFile={removeFile}
        />
        <span
          style={{
            display: accEnvMapFileIdValidation ? 'inline' : 'none',
          }}
          className="font-validation-error"
        >
          {accEnvMapFileIdValidation}
        </span>
      </div>
    </div>
  );
};
