import {
  ServiceNowStatus,
  TICKET_RECOMMENDATION_STATUS,
} from 'constants/workflowIntegrations';

export const getStatusTextClassName = (text: string) => {
  switch (text) {
    case ServiceNowStatus.TICKET_TBC:
      return 'status-purple';

    case ServiceNowStatus.IN_PROGRESS:
      return 'status-orange';

    case ServiceNowStatus.ON_HOLD:
      return 'status-cyan';
    case ServiceNowStatus.RESOLVED:
      return 'status-green';
    case ServiceNowStatus.CLOSED:
      return 'status-grey';

    case ServiceNowStatus.NEW:
      return 'status-violet';

    case TICKET_RECOMMENDATION_STATUS.APPROVED:
      return 'status-chip status-chip-green font-small-bold';
    case TICKET_RECOMMENDATION_STATUS.DISAPPROVED:
      return 'status-chip status-chip-red font-small-bold';
  }
};
