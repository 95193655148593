import { Cascader as BaseCascader } from 'antd';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import Icon from 'components/Icon';
import { LoadingIcon } from 'assets/icons';
import { ICONS, ICONS_SIZE } from 'constants/icons';

const Cascader = ({ designVersion2 = false, ...remainingProps }) => {
  const { theme } = useSelector(selectTheme);
  return (
    <BaseCascader
      allowClear={false}
      rootClassName={`${designVersion2 ? 'design-version-2' : ''}`}
      suffixIcon={
        remainingProps.loading ? (
          <Icon
            icon={LoadingIcon}
            className="rotate"
            color={designVersion2 ? undefined : theme.buttonIconColor}
          />
        ) : (
          <Icon
            iconName={ICONS.ARROW_DOWN_S_LINE}
            size={ICONS_SIZE.TWO_X}
            color={designVersion2 ? undefined : theme.buttonIconColor}
          />
        )
      }
      {...remainingProps}
      dropdownMenuColumnStyle={{ color: theme.textColor }}
    />
  );
};

export default Cascader;
