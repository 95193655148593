import i18n from 'i18n';

export enum GroupsQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const GROUPS_QUICK_ACTIONS = [
  {
    id: GroupsQuickActions.EDIT,
    title: i18n.t('groupsLabels.groupsTableLabels.edit'),
  },
  {
    id: GroupsQuickActions.DELETE,
    title: i18n.t('groupsLabels.groupsTableLabels.delete'),
  },
];
