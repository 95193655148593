import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { selectDashboard } from 'redux/dashboardSlice';
import Table from 'components/Table';
import PieDonutChart from 'components/PieChartAnt';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { evaluateRequestArray } from 'utils/handleErrors';
import DatePicker from 'components/DatePicker';
import { HYPHEN_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'utils/date';

import { getChartExcelDataWithDateFilter } from '../../utils';

type DashboardPieDonutChartsProps = {
  title: string;
  graph: string;
  data: any[];
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  dateRange?: string[];
  setDateRange?: (val: string[]) => void;
  pdfView: boolean;
  columns: any[];
  tableData: any[];
  selectedAccounts: string[];
  prefixSymbol?: string;
  suffixSymbol?: string;
};

const DashboardPieDonutCharts = ({
  title,
  graph,
  data,
  requestStatus,
  isTableView,
  setIsTableView,
  dateRange,
  setDateRange,
  pdfView,
  columns,
  tableData,
  selectedAccounts,
  prefixSymbol,
  suffixSymbol,
}: DashboardPieDonutChartsProps) => {
  const { selectedDashboard } = useSelector(selectDashboard);

  const onChangeDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setDateRange?.([
      moment(dateString[0], MONTH_YEAR_FORMAT)
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment(dateString[1], MONTH_YEAR_FORMAT)
        .endOf('month')
        .format(HYPHEN_DATE_FORMAT),
    ]);
  };

  const getComponent = () =>
    isTableView ? (
      <Table
        pagination={false}
        dataSource={tableData}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2
        fillContainer
      />
    ) : (
      <PieDonutChart
        data={data}
        angleField="value"
        colorField="type"
        isDonut={true}
        disableAnimation={pdfView}
        prefixSymbol={prefixSymbol}
        suffixSymbol={suffixSymbol}
        showCurrencySymbol={false}
        suffixText={suffixSymbol}
      />
    );

  const filters = (
    <div className="filters flex flex-align-items-center">
      {setDateRange && dateRange && (
        <DatePicker
          defaultValue={[
            moment(dateRange[0], HYPHEN_DATE_FORMAT),
            moment(dateRange[1], HYPHEN_DATE_FORMAT),
          ]}
          onChange={onChangeDateRange}
          disabledDate={(current: Moment) => current > moment().endOf('day')}
          picker="month"
          format={MONTH_YEAR_FORMAT}
        />
      )}
    </div>
  );

  return (
    <div
      className={`pie-donut-charts graph-card ${graph} full-height flex flex-column flex-fit`}
      id="graph-container"
    >
      <GraphHeader
        heading={title}
        graphName={graph}
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getChartExcelDataWithDateFilter({
          sheetName: title,
          columns,
          data: tableData,
          connectorName: selectedDashboard?.connectorName ?? '',
          provider: selectedDashboard!.connectorProvider,
          dateRange,
          selectedAccounts,
        })}
        designVersion2
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default DashboardPieDonutCharts;
