import { sum } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectDashboard } from 'redux/dashboardSlice';
import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import PieDonutChart from 'components/PieChartAnt';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { LEGEND_ITEM_NAME_LENGTH_LIMIT } from 'constants/userConsole';
import { REQUEST_STATUS } from 'constants/requestBody';

import { getGranulatePieChartsExcelData } from './utils';

import './index.scss';

type GranulateUsageMeteringPieChartType = {
  data: any[];
  heading: string;
  headingComponent?: React.ReactNode;
  filters?: React.ReactNode;
  graphName: string;
  requestStatus: string;
  suffixText?: string;
  angleField: string;
  colorField: string;
  pdfView?: boolean;
  isTableView?: boolean;
  setIsTableView?: (value: boolean) => void;
  excelFilters?: any[];
};

const GranulateUsageMeteringPieChart = ({
  data,
  heading,
  headingComponent,
  filters,
  graphName,
  requestStatus,
  suffixText,
  angleField,
  colorField,
  pdfView,
  isTableView,
  setIsTableView,
  excelFilters,
}: GranulateUsageMeteringPieChartType) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { showExpandGraphModal } = useSelector(selectDashboard);

  const columns = [
    {
      title: t('consolidatedDashboard.applicationDetails.name'),
      dataIndex: 'name',
      key: 'name',
      width: 120,
      render: (text: any, _record: any, _index: number) => {
        return <span className="font-caption">{text}</span>;
      },
      fixed: true,
    },
    {
      title: t('consolidatedDashboard.applicationDetails.namespace'),
      dataIndex: 'namespace',
      key: 'namespace',
      width: 120,
      sorter: (a: any, b: any) => a.namespace.localeCompare(b.namespace),
      render: (text: any, _record: any, _index: number) => {
        return <span className="font-caption">{text}</span>;
      },
    },
    {
      title: t(`consolidatedDashboard.applicationDetails.${angleField}`),
      dataIndex: angleField,
      key: angleField,
      width: 150,
      align: 'center',
      sorter: (a: any, b: any) => a[angleField] - b[angleField],
      render: (_text: any, record: any, _index: number) => {
        return <span className="font-caption">{record[angleField]}</span>;
      },
    },
    {
      title: t('consolidatedDashboard.applicationDetails.costCredit', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'costAfterCredit',
      key: 'costAfterCredit',
      width: 120,
      align: 'center',
      sorter: (a: any, b: any) => a.costAfterCredit - b.costAfterCredit,
      render: (text: any, _record: any, _index: number) => {
        return <span className="font-caption">{(text ?? 0).toFixed(2)}</span>;
      },
    },
  ];

  const getStatisticContent = useCallback(
    (value: any) => {
      if (value === undefined)
        return (
          numberCommaSeparator(sum(data.map((item) => item[angleField]))) +
          ` ${suffixText}` +
          `\n ${currencySymbol} ${numberCommaSeparator(
            sum(data.map((item) => item.costAfterCredit))
          )}`
        );

      return (
        numberCommaSeparator(value[angleField]) +
        ` ${suffixText}` +
        `\n ${currencySymbol} ${numberCommaSeparator(value.costAfterCredit)}`
      );
    },
    [data]
  );

  const formatLegends = useCallback(
    (text: string) => {
      return text.length > LEGEND_ITEM_NAME_LENGTH_LIMIT &&
        !showExpandGraphModal
        ? `${text.substring(0, LEGEND_ITEM_NAME_LENGTH_LIMIT)}...`
        : text;
    },
    [showExpandGraphModal]
  );

  const getComponent = () =>
    isTableView ? (
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        fillContainer
        designVersion2={true}
        scroll={{ y: '100%' }}
        loading={requestStatus === REQUEST_STATUS.PROCESSING}
      />
    ) : (
      <PieDonutChart
        data={data}
        angleField={angleField}
        colorField={colorField}
        isDonut
        showLegends={!pdfView}
        disableAnimation={pdfView}
        colorOverride={data.map((value: any) => value.color)}
        showCurrencySymbol={false}
        additionalClassNames={`${pdfView && 'pdf-wrapper pdf-pie-wrapper'}`}
        suffixText={suffixText}
        statisticValueFormatter={getStatisticContent}
        legendFormatter={formatLegends}
      />
    );
  return (
    <div
      className="cluster-cost graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={heading}
        headingComponent={headingComponent}
        graphName={graphName}
        filters={filters}
        showExpandIcon={!pdfView}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        isTableViewSwitch={!pdfView}
        isDownloadable={true}
        excelData={getGranulatePieChartsExcelData(
          data,
          excelFilters ?? [],
          graphName,
          angleField
        )}
        designVersion2
      />

      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default GranulateUsageMeteringPieChart;
