import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userAuthorization } from 'redux/authorizationSlice';
import NavigationPath from 'components/NavigationPath';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray } from 'utils/handleErrors';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import DashboardHeader from './components/DashboardHeader';
import CostAllocationDashboard from './components/CostAllocationDashboard';
import HorizontalNavigation from './components/HorizontalNavigation';

import './index.scss';

const CostAllocationDashboardPage = () => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  const [isEmptyDashboard, setIsEmptyDashboard] = useState(false);
  const [fetchDashboardsRequestStatus, setFetchDashboardsRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  if (!permissions.cloudConnectorList || !permissions.dashboardList) {
    return (
      <div className="page-content">
        {t('dashboardLabels.noDashboardPermission')}
      </div>
    );
  }

  if (isEmptyDashboard) {
    return (
      <div className="page-content">
        {t('dashboardLabels.noCostAllocationDashboard')}
      </div>
    );
  }

  return (
    <div className="cost-allocation-dashboard">
      <div className="cost-allocation-dashboard-header new-page-header">
        <DashboardHeader
          setIsEmptyDashboard={setIsEmptyDashboard}
          fetchDashboardsRequestStatus={fetchDashboardsRequestStatus}
          setFetchDashboardsRequestStatus={setFetchDashboardsRequestStatus}
        />
      </div>
      <div className="cost-allocation-dashboard-content page-content">
        <NavigationPath />
        <HorizontalNavigation />
        <div className="dashboard-area">
          <DashboardComponent
            component={<CostAllocationDashboard />}
            requestStatus={evaluateRequestArray([fetchDashboardsRequestStatus])}
          />
        </div>
      </div>
    </div>
  );
};

export default CostAllocationDashboardPage;
