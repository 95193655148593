import moment from 'moment';
import { sum } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TagFilters from 'components/TagFilters';
import DashboardGraphContainer from 'components/DashboardGraphContainer';
import { ChartCriteria } from 'constants/dashboard';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectDashboard } from 'redux/dashboardSlice';
import { getPdfMetaData } from 'pages/OverviewPage/components/ConnectionsDashboard/utils';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import {
  ColouredCostByServiceType,
  MonthlyCostByNameDataType,
  ColouredCostByResourceType,
  ColouredCostByProjectType,
} from 'types/dataTypes';
import { GraphRowContentType } from 'types/dashboard';
import { CHART_TYPES, GRAPH_OPTIONS } from 'constants/graphConfig';
import {
  dateNYearsFromToday,
  HYPHEN_DATE_FORMAT,
  monthsBetweenDates,
  MONTH_YEAR_FORMAT,
} from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import {
  getTopServiceAggregatorsDataSource,
  getTopServiceAggregatorsChartHeading,
  getTopServiceAggregatorsColumns,
  getCostTimeLineDataColumns,
  getChartCriteriaList,
  fetchTopServicesData,
  getServiceTimeLineData,
  getServiceAggregatorTimeLineData,
  getTopServiceAggregatorsData,
  getCostByServiceForServiceAggregator,
  getCostByResourceForService,
  getServiceAggregatorLabelByProvider,
  getServiceAggregatorTimeLineGraphHeading,
  getProjectDeepDiveDashboardViewName,
  getExcelServiceAggregatorFilterKey,
  getServiceAggregatorChartCriteriaKey,
} from './utils';
import CostTimeLine from './components/CostTimeLine';
import TopServices from './components/TopServices';
import TopServiceAggregators from './components/TopProjects';
import ProjectDeepDiveDashboardControls from './components/ProjectDeepDiveDashboardControls';
import { TopServicesColumns } from './constants';

const ProjectDeepDiveDashBoard = () => {
  const { t } = useTranslation();
  const {
    selectedDashboard,
    selectedConnection,
    tagsFilters,
    selectedDashboardView,
    showExpandGraphModal,
  } = useSelector(selectDashboard);

  const [costDateLabels, setCostDateLabels] = useState<string[]>([]);
  const [serviceCostDateLabels, setServiceCostDateLabels] = useState<string[]>(
    []
  );

  // States for control components
  const [chartCriteriaFilters, setChartCriteriaFilters] = useState<string[]>(
    getChartCriteriaList(selectedDashboard!.connectorProvider).map(
      (item) => item.value
    )
  );
  const [accounts, setAccounts] = useState<string[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [accountsReqStatus, setAccountsReqStatus] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );
  const [selectedServiceAggregators, setSelectedServiceAggregators] = useState<
    string[]
  >([]);
  const [serviceAggregatorsReqStatus, setServiceAggregatorsReqStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [startDate, setStartDate] = useState<string>(dateNYearsFromToday(1));
  const [endDate, setEndDate] = useState<string>(
    moment().format(HYPHEN_DATE_FORMAT)
  );

  //States for Top projects/resource groups
  const [topServiceAggregatorsData, setTopServiceAggregatorsData] = useState<
    ColouredCostByProjectType[]
  >([]);
  const [
    topServiceAggregatorsDataRequestStatus,
    setTopServiceAggregatorsDataRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    isTopServiceAggregatorsTableView,
    setIsTopServiceAggregatorsTableView,
  ] = useState(false);
  const [selectedServiceAggregator, setSelectedServiceAggregator] =
    useState<string>('');
  const [selectedService, setSelectedService] = useState<string>('');
  const [
    serviceAggregatorWiseServiceData,
    setServiceAggregatorWiseServiceData,
  ] = useState<{
    [key: string]: ColouredCostByServiceType[];
  }>({});
  const [serviceWiseResourceData, setServiceWiseResourceData] = useState<{
    [key: string]: ColouredCostByResourceType[];
  }>({});
  const [
    topServiceAggregatorsSliderValue,
    setTopServiceAggregatorsSliderValue,
  ] = useState<{
    x: number;
    y: number;
  }>();

  //States for Project/Resource Group timeline data
  const [serviceAggregatorTimeLineData, setServiceAggregatorTimeLineData] =
    useState<MonthlyCostByNameDataType[]>([]);
  const [
    serviceAggregatorTimeLineRequestStatus,
    setServiceAggregatorTimeLineRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    isServiceAggregatorTimeLineTableView,
    setIsServiceAggregatorTimeLineTableView,
  ] = useState(false);

  //States for Service timeline data
  const [serviceTimeLineData, setServiceTimeLineData] = useState<
    MonthlyCostByNameDataType[]
  >([]);
  const [serviceTimeLineRequestStatus, setServiceTimeLineRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [isCostServiceTimeLineTableView, setIsCostServiceTimeLineTableView] =
    useState(false);

  //States for Top Services
  const [topServicesData, setTopServicesData] = useState<
    ColouredCostByServiceType[]
  >([]);
  const [topServicesDataRequestStatus, setTopServicesDataRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [isTopServiceTableView, setIsTopServiceTableView] =
    useState<boolean>(false);

  useEffect(() => {
    addZeroMarginClass('dashboard-view');

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    let labels: string[] = monthsBetweenDates(
      startDate.replaceAll('-', '').slice(0, -2),
      endDate.replaceAll('-', '').slice(0, -2),
      MONTH_YEAR_FORMAT
    );
    let serviceLabels: string[] = monthsBetweenDates(
      startDate.replaceAll('-', '').slice(0, -2),
      endDate.replaceAll('-', '').slice(0, -2),
      MONTH_YEAR_FORMAT
    );

    setCostDateLabels(labels);
    setServiceCostDateLabels(serviceLabels);
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedServiceAggregators.length > 0) {
      getTopServiceAggregatorsData(
        tagsFilters,
        selectedDashboard!,
        selectedConnection!,
        selectedDashboardView,
        setTopServiceAggregatorsDataRequestStatus,
        { startDate, endDate },
        selectedServiceAggregators,
        setTopServiceAggregatorsData
      );
      getServiceAggregatorTimeLineData(
        tagsFilters,
        selectedDashboard!,
        selectedConnection!,
        selectedDashboardView,
        setServiceAggregatorTimeLineRequestStatus,
        { startDate, endDate },
        selectedServiceAggregators,
        setServiceAggregatorTimeLineData
      );
      fetchTopServicesData(
        tagsFilters,
        selectedDashboard!,
        selectedConnection!,
        selectedDashboardView,
        setTopServicesDataRequestStatus,
        { startDate, endDate },
        selectedServiceAggregators,
        setTopServicesData
      );
      getServiceTimeLineData(
        tagsFilters,
        selectedDashboard!,
        selectedConnection!,
        selectedDashboardView,
        setServiceTimeLineRequestStatus,
        { startDate, endDate },
        selectedServiceAggregators,
        setServiceTimeLineData
      );
    } else {
      setTopServiceAggregatorsData([]);
      setTopServicesData([]);
      setServiceTimeLineData([]);
      setServiceAggregatorTimeLineData([]);
    }
  }, [startDate, endDate, selectedServiceAggregators, tagsFilters]);

  useEffect(() => {
    if (
      selectedServiceAggregator &&
      !serviceAggregatorWiseServiceData[selectedServiceAggregator]
    ) {
      getCostByServiceForServiceAggregator(
        {
          dashboard: selectedDashboard!,
          connection: selectedConnection!,
          tagsFilters,
          dashboardView: selectedDashboardView,
        },
        selectedConnection!,
        setTopServiceAggregatorsDataRequestStatus,
        { startDate, endDate },
        selectedServiceAggregator,
        serviceAggregatorWiseServiceData,
        setServiceAggregatorWiseServiceData
      );
    }
  }, [selectedServiceAggregator]);

  useEffect(() => {
    if (
      selectedService &&
      !serviceWiseResourceData[`${selectedServiceAggregator}${selectedService}`]
    ) {
      getCostByResourceForService(
        {
          dashboard: selectedDashboard!,
          connection: selectedConnection!,
          tagsFilters,
          dashboardView: selectedDashboardView,
        },
        selectedConnection!,
        setTopServiceAggregatorsDataRequestStatus,
        { startDate, endDate },
        selectedServiceAggregator,
        selectedService,
        serviceWiseResourceData,
        setServiceWiseResourceData
      );
    }
  }, [selectedService]);

  const generateTableData = (
    data: any[],
    keyName: string,
    labelData: string[],
    pdfView = false
  ) => {
    return data.reduce((tableData, value) => {
      const existingObject = tableData.find(
        (item: any) => item[keyName] === value[keyName]
      );
      if (existingObject) {
        labelData.forEach((label, index) => {
          existingObject[`${label}-cost`] = pdfView
            ? numberCommaSeparator(value.costData[index])
            : value.costData[index];
        });
      } else {
        const obj = { [keyName]: value[keyName] };
        labelData.forEach((label, index) => {
          obj[`${label}-cost`] = pdfView
            ? numberCommaSeparator(value.costData[index])
            : value.costData[index];
        });
        tableData.push(obj);
      }
      return tableData;
    }, []);
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'top-service-aggregators':
        return (
          <TopServiceAggregators
            topServiceAggregatorsData={topServiceAggregatorsData}
            serviceAggregatorLabel={getServiceAggregatorLabelByProvider(
              selectedDashboard!.connectorProvider
            )}
            requestStatus={[
              topServiceAggregatorsDataRequestStatus,
              serviceAggregatorsReqStatus,
              accountsReqStatus,
            ]}
            startDate={startDate}
            endDate={endDate}
            pdfView={pdfView}
            isTableViewSwitch={!pdfView}
            isTableView={isTopServiceAggregatorsTableView}
            setTableView={setIsTopServiceAggregatorsTableView}
            selectedServiceAggregator={selectedServiceAggregator}
            setSelectedServiceAggregator={setSelectedServiceAggregator}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            ServiceAggregatorWiseServiceData={
              serviceAggregatorWiseServiceData[selectedServiceAggregator] ?? []
            }
            serviceWiseResourceData={
              serviceWiseResourceData[
                `${selectedServiceAggregator}${selectedService}`
              ] ?? []
            }
            sliderValue={topServiceAggregatorsSliderValue}
            setSliderValue={setTopServiceAggregatorsSliderValue}
          />
        );

      case 'service-aggregator-time-line':
        return (
          <CostTimeLine
            title={getServiceAggregatorTimeLineGraphHeading(
              selectedDashboard!.connectorProvider
            )}
            labels={costDateLabels}
            graph="service-aggregator-time-line"
            requestStatus={[
              serviceAggregatorTimeLineRequestStatus,
              serviceAggregatorsReqStatus,
              accountsReqStatus,
            ]}
            isTableView={isServiceAggregatorTimeLineTableView}
            setIsTableView={setIsServiceAggregatorTimeLineTableView}
            startDate={startDate}
            endDate={endDate}
            selectedServiceAggregators={selectedServiceAggregators}
            timeLineData={serviceAggregatorTimeLineData}
            pdfView={pdfView}
          />
        );

      case 'service-time-line':
        return (
          <CostTimeLine
            title={t(
              'projectDeepDiveSummary.projectTimeLine.costServiceTimeLine'
            )}
            labels={serviceCostDateLabels}
            graph="service-time-line"
            requestStatus={[
              serviceTimeLineRequestStatus,
              serviceAggregatorsReqStatus,
              accountsReqStatus,
            ]}
            isTableView={isCostServiceTimeLineTableView}
            setIsTableView={setIsCostServiceTimeLineTableView}
            startDate={startDate}
            endDate={endDate}
            selectedServiceAggregators={selectedServiceAggregators}
            timeLineData={serviceTimeLineData}
            pdfView={pdfView}
          />
        );

      case 'top-services':
        return (
          <TopServices
            data={topServicesData}
            requestStatus={[
              topServicesDataRequestStatus,
              serviceAggregatorsReqStatus,
              accountsReqStatus,
            ]}
            startDate={startDate}
            endDate={endDate}
            selectedServiceAggregators={selectedServiceAggregators}
            pdfView={pdfView}
            isTableViewSwitch={!pdfView}
            isTableView={isTopServiceTableView}
            setTableView={setIsTopServiceTableView}
            showExpandedGraphModal={showExpandGraphModal}
          />
        );
    }
  };

  const TopServiceAggregatorsTableDataSource =
    getTopServiceAggregatorsDataSource(
      topServiceAggregatorsData,
      serviceAggregatorWiseServiceData[selectedServiceAggregator] || [],
      serviceWiseResourceData[
        `${selectedServiceAggregator}${selectedService}`
      ] || [],
      selectedServiceAggregator,
      selectedService
    );

  const GraphRows: GraphRowContentType = useMemo(
    () => [
      [
        {
          element: getGraphComponent('top-service-aggregators', true),
          graphName: 'top-service-aggregators',
          contentType: CHART_TYPES.BAR_CHART,
          colSpanWidth: 12,
          graphHeading: getTopServiceAggregatorsChartHeading(
            selectedServiceAggregator,
            selectedService,
            selectedDashboard!.connectorProvider
          ),
          criteria: [
            getServiceAggregatorChartCriteriaKey(
              selectedDashboard!.connectorProvider
            ),
            ChartCriteria.SERVICE,
            ChartCriteria.RESOURCE,
          ],
          tableView: {
            isTableView: isTopServiceAggregatorsTableView,
            setTableView: setIsTopServiceAggregatorsTableView,
            columns: getTopServiceAggregatorsColumns(
              getServiceAggregatorLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              selectedServiceAggregator,
              selectedService
            ),
          },
          pdfExport: {
            tableData: TopServiceAggregatorsTableDataSource.map(
              (topProject) => ({
                ...topProject,
                cost: numberCommaSeparator(topProject.cost),
              })
            ),
            legends: TopServiceAggregatorsTableDataSource.slice(
              topServiceAggregatorsSliderValue
                ? topServiceAggregatorsSliderValue.x *
                    (TopServiceAggregatorsTableDataSource.length - 1)
                : 0,
              topServiceAggregatorsSliderValue
                ? topServiceAggregatorsSliderValue.y *
                    (TopServiceAggregatorsTableDataSource.length - 1) +
                    1
                : GRAPH_OPTIONS.sliderCountLimit + 1
            ).map((data: any) => ({
              name: data.project ?? data.service ?? data.resource ?? '',
              color: data.color ?? '',
            })),
          },
          excelExport: {
            excelData: TopServiceAggregatorsTableDataSource,
            excelFilters: {
              connectionName: selectedDashboard!.connectorName,
              [getExcelServiceAggregatorFilterKey(
                selectedDashboard!.connectorProvider
              )]: selectedServiceAggregator,
              service: selectedService,
              startDate,
              endDate,
            },
          },
        },
        {
          element: getGraphComponent('service-aggregator-time-line', true),
          graphName: 'service-aggregator-time-line',
          contentType: CHART_TYPES.BAR_CHART,
          colSpanWidth: 12,
          graphHeading: getServiceAggregatorTimeLineGraphHeading(
            selectedDashboard!.connectorProvider
          ),
          criteria: [
            getServiceAggregatorChartCriteriaKey(
              selectedDashboard!.connectorProvider
            ),
            ChartCriteria.TIME_PERIOD,
          ],
          tableView: {
            columns: getCostTimeLineDataColumns(
              selectedDashboard!.connectorProvider,
              'service-aggregator-time-line',
              startDate,
              endDate
            ),
            isTableView: isServiceAggregatorTimeLineTableView,
            setTableView: setIsServiceAggregatorTimeLineTableView,
          },
          pdfExport: {
            tableData: generateTableData(
              serviceAggregatorTimeLineData,
              'name',
              costDateLabels,
              true
            ),
          },
          excelExport: {
            excelData: generateTableData(
              serviceAggregatorTimeLineData,
              'project',
              costDateLabels,
              false
            ),
            excelFilters: {
              connectionName: selectedDashboard!.connectorName,
              [getExcelServiceAggregatorFilterKey(
                selectedDashboard!.connectorProvider
              )]: selectedServiceAggregators.join(', '),
              startDate,
              endDate,
            },
          },
        },
      ],
      [
        {
          element: getGraphComponent('service-time-line', true),
          graphName: 'service-time-line',
          contentType: CHART_TYPES.BAR_CHART,
          colSpanWidth: 16,
          graphHeading: t(
            'projectDeepDiveSummary.projectTimeLine.costServiceTimeLine'
          ),
          criteria: [ChartCriteria.SERVICE, ChartCriteria.TIME_PERIOD],
          tableView: {
            columns: getCostTimeLineDataColumns(
              selectedDashboard!.connectorProvider,
              'service-time-line',
              startDate,
              endDate
            ),
            isTableView: isCostServiceTimeLineTableView,
            setTableView: setIsCostServiceTimeLineTableView,
          },
          pdfExport: {
            tableData: generateTableData(
              serviceTimeLineData,
              'name',
              costDateLabels,
              true
            ),
          },
          excelExport: {
            excelData: generateTableData(
              serviceTimeLineData,
              'name',
              costDateLabels,
              false
            ),
            excelFilters: {
              connectionName: selectedDashboard!.connectorName,
              [getExcelServiceAggregatorFilterKey(
                selectedDashboard!.connectorProvider
              )]: selectedServiceAggregators.join(', '),
              startDate,
              endDate,
            },
          },
        },
        {
          element: getGraphComponent('top-services', true),
          graphName: 'top-services',
          contentType: CHART_TYPES.PIE_CHART,
          colSpanWidth: 8,
          graphHeading: t(
            'projectDeepDiveSummary.costTable.projects.topServices'
          ),
          criteria: [ChartCriteria.SERVICE],
          tableView: {
            isTableView: isTopServiceTableView,
            setTableView: setIsTopServiceTableView,
            columns: TopServicesColumns,
          },
          pdfExport: {
            tableData: topServicesData.map((topService) => ({
              ...topService,
              cost: numberCommaSeparator(topService.cost),
            })),
            tableFooterData: {
              totalTableData: [
                '',
                t('projectDeepDiveSummary.costTable.grandTotal'),
                `$ ${numberCommaSeparator(
                  sum(topServicesData.map((value) => value.cost))
                )}`,
              ],
            },
            legends: topServicesData.map((topProject) => ({
              name: topProject.service,
              color: topProject.color,
            })),
          },
          excelExport: {
            excelData: topServicesData,
            excelFilters: {
              connectionName: selectedDashboard!.connectorName,
              [getExcelServiceAggregatorFilterKey(
                selectedDashboard!.connectorProvider
              )]: selectedServiceAggregators.join(', '),
              startDate,
              endDate,
            },
          },
        },
      ],
    ],
    [
      topServiceAggregatorsData,
      serviceAggregatorWiseServiceData,
      selectedServiceAggregator,
      serviceWiseResourceData,
      selectedService,
      costDateLabels,
      startDate,
      endDate,
      isCostServiceTimeLineTableView,
      isServiceAggregatorTimeLineTableView,
      isTopServiceAggregatorsTableView,
      isTopServiceTableView,
      selectedDashboard,
      selectedServiceAggregators,
      serviceAggregatorTimeLineData,
      serviceTimeLineData,
      topServiceAggregatorsSliderValue,
      topServicesData,
    ]
  );

  return (
    <div>
      <ProjectDeepDiveDashboardControls
        accountsStates={{
          accounts,
          setAccounts,
          selectedAccounts,
          setSelectedAccounts,
          accountsReqStatus,
          setAccountsReqStatus,
        }}
        serviceAggregatorStates={{
          selectedServiceAggregators: selectedServiceAggregators,
          setSelectedServiceAggregators: setSelectedServiceAggregators,
          serviceAggregatorsReqStatus: serviceAggregatorsReqStatus,
          setServiceAggregatorsReqStatus: setServiceAggregatorsReqStatus,
        }}
        chartCriteriaStates={{
          chartCriteriaFilters: chartCriteriaFilters,
          setChartCriteriaFilters: setChartCriteriaFilters,
        }}
        dateFilterStates={{
          startDate: startDate,
          setStartDate: setStartDate,
          endDate: endDate,
          setEndDate: setEndDate,
        }}
      />
      <div className="deep-dive-container inner-dashboard-content flex flex-column flex-gap-24">
        <TagFilters />
        <DashboardGraphContainer
          getGraphComponent={getGraphComponent}
          chartCriteriaFilters={chartCriteriaFilters}
          rows={GraphRows}
          pdfMetaData={getPdfMetaData(
            getProjectDeepDiveDashboardViewName(
              selectedDashboard!.connectorProvider
            ),
            selectedDashboard!,
            selectedConnection!
          )}
        />
      </div>
    </div>
  );
};

export default ProjectDeepDiveDashBoard;
