import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getGranulateConnectionsForConnector
 * @description Calls an api to fetch the granulate connections for a CSP connector
 * @param params Request parameters
 * @return axios response from GET request
 */
export const getGranulateConnectionsForConnector = async (params: any) => {
  return ServiceCalls.get(APIS.GRANULATE_FETCH_CONNECTIONS, null, params);
};

/**
 * @function getGranulateRecommendationsForConnector
 * @description Calls an api to fetch the granulate recommendations for a connector
 * @param params Request parameters
 * @param requestBody Request body for the post request
 * @return axios response from POST request
 */
export const getGranulateRecommendationsForConnector = async (
  params: any,
  requestBody: any
) => {
  return ServiceCalls.post(
    APIS.RECOMMENDATION_DATA_COST,
    requestBody,
    null,
    params
  );
};
