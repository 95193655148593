import { Empty, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table/Column';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import {
  TcoProjectQuickActions,
  TCO_PROJECTS_QUICKACTIONS,
} from 'constants/quickAction';
import { REQUEST_STATUS } from 'constants/requestBody';
import Button from 'components/Button';
import DeleteModal from 'components/DeleteModal';
import SearchInput from 'components/Input';
import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import Pagination from 'components/Pagination';
import NavigationPath from 'components/NavigationPath';
import { INPUT_SIZE } from 'constants/appearance';
import { DATE_FORMAT } from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import { getAllTcoProjects } from 'utils/services';
import { userAuthorization } from 'redux/authorizationSlice';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import CreateProjectModal from './components/CreateProjectModal';
import { TcoProjectListType } from './types';
import { deleteTcoProject } from './services';

import './index.scss';

const TcoProjectsPage = () => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  // Table states
  const [tcoProjectsData, setTcoProjectsData] =
    useState<TcoProjectListType[]>();
  const [currentTcoProject, setCurrentTcoProject] =
    useState<TcoProjectListType>();
  const [
    fetchTcoProjectDataRequestStatus,
    setFetchTcoProjectDataRequestStatus,
  ] = useState('');

  // Pagination and search states
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [totalTcoProjectsCount, setTotalTcoProjectsCount] = useState(0);

  // Delete states
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTcoProjectRequestStatus, setDeleteTcoProjectRequestStatus] =
    useState('');

  //Create Project States
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    !currentTcoProject && fetchSearchTcoProjectData();
  }, [currentTcoProject]);

  /**
   * @function handleSearchTcoProject
   * @description Function to handle custom dashboard search
   * @param e :accepts search input event
   */
  const handleSearchTcoProject = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchKey(e.target.value);
    fetchSearchTcoProjectData(e.target.value);
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick action buttons from the table
   * @param action : The clicked action
   */
  const handleQuickAction = (action: string) => {
    switch (action) {
      case TcoProjectQuickActions.DELETE:
        setShowDeleteModal(true);
        return;
      case TcoProjectQuickActions.EDIT:
        setShowCreateProjectModal(true);
        return;
    }
  };

  /**
   * @function fetchSearchTcoProjectData
   * @description Function to fetch TcoProjects data and with search and filters
   * @param searchString string to be searched for.
   * @param page page number defaults to first page
   */
  const fetchSearchTcoProjectData = (
    searchString: string = '',
    page: number = 1
  ) => {
    if (permissions.costControlList) {
      setFetchTcoProjectDataRequestStatus(REQUEST_STATUS.PROCESSING);
      const params = {
        key: searchString,
        page: page - 1,
        size: PAGINATION_SIZE,
      };
      getAllTcoProjects(params)
        .then((res: any) => {
          if (res?.status === 200) {
            setTcoProjectsData(res.data.responseData.content);
            setTotalTcoProjectsCount(res.data.responseData.totalElements);
            setFetchTcoProjectDataRequestStatus(REQUEST_STATUS.SUCCESS);
            return;
          }
          setFetchTcoProjectDataRequestStatus(REQUEST_STATUS.ERROR);
          message.error({ content: res?.message ?? t('apiCallErrorMessage') });
        })
        .catch((e) =>
          onApiCallError(e, false, setFetchTcoProjectDataRequestStatus)
        );
    }
  };

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    if (searchKey.length === 0) {
      fetchSearchTcoProjectData('', page);
    } else {
      fetchSearchTcoProjectData(searchKey, page);
    }
  };

  /**
   * @function deleteSelectedTcoProject
   * @description Function to delete a TcoProject
   */
  const deleteSelectedTcoProject = () => {
    setDeleteTcoProjectRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = { projectId: currentTcoProject?.projectId };
    deleteTcoProject(params)
      .then((_res) => {
        message.success(t('tcoProjectTable.deleteProjectSuccess'));
        setCurrentTcoProject(undefined);
        setDeleteTcoProjectRequestStatus(REQUEST_STATUS.SUCCESS);
        setShowDeleteModal(false);
      })
      .catch((e) =>
        onApiCallError(
          e,
          true,
          setDeleteTcoProjectRequestStatus,
          e?.response?.data?.message ?? t('tcoProjectTable.deleteProjectError')
        )
      );
  };

  /**
   * @function styleAndReduceAllSourceNames
   * @description Function to return the styled component of the source names
   * @param names List of source names for which the component is built.
   * @returns JSX element
   */
  const styleAndReduceAllSourceNames = (names: string[]) => {
    if (names.length === 0) {
      return '--';
    }

    if (names.length > 2) {
      return [
        names.slice(0, 2).map((name: string) => (
          <span className="name-list" key={name}>
            {name}
          </span>
        )),
        <span className="name-list" key={names.length}>
          {`+${names.length - 2}`}
        </span>,
      ];
    }
    return names.map((name: string) => (
      <span className="name-list" key={name}>
        {name}
      </span>
    ));
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + index + 1,
      width: '4%',
    },
    {
      title: t('tcoProjectTable.projectName'),
      dataIndex: 'name',
      key: 'name',
      width: '13%',
    },
    {
      title: t('tcoProjectTable.description'),
      dataIndex: 'description',
      key: 'description',
      width: '10%',
    },
    {
      title: t('tcoProjectTable.wbsCode'),
      dataIndex: 'wbsCode',
      key: 'wbsCode',
      width: '10%',
    },
    {
      title: t('tcoProjectTable.dataCenters'),
      dataIndex: 'dataCenterDetailsList',
      key: 'dataCenterDetailsList',
      render: (_text: any, record: TcoProjectListType) =>
        styleAndReduceAllSourceNames(
          record.dataCenterDetailsList.map((dataCenter) => dataCenter.name)
        ),
      width: '20%',
    },
    {
      title: t('tcoProjectTable.cloudConnections'),
      dataIndex: 'connectorDetailsList',
      key: 'connectorDetailsList',
      render: (_text: any, record: TcoProjectListType) =>
        styleAndReduceAllSourceNames(
          record.connectorDetailsList.map(
            (connection) => connection.connectorName
          )
        ),
      width: '25%',
    },
    {
      title: t('tcoProjectTable.dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (text: string) => {
        return text !== null ? moment(text).format(DATE_FORMAT) : '--';
      },
      width: '10%',
    },
    {
      title: t('tcoProjectTable.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_text: string, record: TcoProjectListType) => {
        return (
          <QuickActionMenu
            setCurrentConnectionData={() => setCurrentTcoProject(record)}
            quickActions={TCO_PROJECTS_QUICKACTIONS}
            quickActionHandler={(action: string) => {
              handleQuickAction(action);
            }}
            disabled={!permissions.costControlModify}
          />
        );
      },
      width: '8%',
      align: 'center',
    },
  ];

  return (
    <div className="tco-projects flex flex-column flex-fit">
      <header className="tco-projects-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="modal-heading">{t('navigationMenu.tcoProject')}</div>
        {permissions.costControlWrite && (
          <Button
            title={t('tcoProjectTable.createProject')}
            onClick={() => {
              setCurrentTcoProject(undefined);
              setShowCreateProjectModal(true);
            }}
          />
        )}
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="tco-projects-content margin-24 flex flex-column flex-fit">
          <SearchInput
            placeholder={t('tcoProjectTable.searchPlaceholder')}
            type="search"
            size={INPUT_SIZE.SMALL}
            onChange={debounce(handleSearchTcoProject, DEBOUNCE_TIME_DELAY)}
          />
          <Table
            rootClassName="tco-projects-table"
            pagination={false}
            dataSource={tcoProjectsData?.map((value, index) => {
              return {
                ...value,
                key: index,
              };
            })}
            columns={columns}
            scroll={{ y: '100%' }}
            loading={
              fetchTcoProjectDataRequestStatus === REQUEST_STATUS.PROCESSING
            }
            locale={{
              emptyText: permissions.costControlList
                ? fetchTcoProjectDataRequestStatus === REQUEST_STATUS.ERROR && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={t('graphErrorMessage')}
                    />
                  )
                : t('noPermissionForTable'),
            }}
            designVersion2
            fillContainer
          />
        </div>
      </div>
      <div className="page-footer flex flex-fit flex-align-items-end">
        {totalTcoProjectsCount > 0 && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalTcoProjectsCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </div>
      {currentTcoProject !== undefined && showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={deleteSelectedTcoProject}
          loading={deleteTcoProjectRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
      {showCreateProjectModal && (
        <CreateProjectModal
          showModal={showCreateProjectModal}
          setShowModal={setShowCreateProjectModal}
          selectedTcoProject={currentTcoProject}
          onSuccess={fetchSearchTcoProjectData}
        />
      )}
    </div>
  );
};

export default TcoProjectsPage;
