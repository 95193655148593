import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionList } from 'types/gProfiler';
import { RootState } from './store';

export interface GCPConnection {
  connectionName: string;
  cloudConnection: string;
  serviceName: string;
  region: string;
  cluster: string;
  connectorId: string;
  file: any;
  project: string;
}

export interface AWSConnection {
  connectionName: string;
  cloudConnection: string;
  serviceName: string;
  region: string;
  cluster: string;
  secretKey: string;
  accessKey: string;
  connectorId: string;
}

const awsConnection: AWSConnection = {
  connectionName: '',
  cloudConnection: '',
  serviceName: '',
  region: '',
  cluster: '',
  accessKey: '',
  secretKey: '',
  connectorId: '',
};

const gcpConnection: GCPConnection = {
  connectionName: '',
  cloudConnection: '',
  serviceName: '',
  region: '',
  cluster: '',
  file: undefined,
  connectorId: '',
  project: '',
};

export interface GranulateConnectionState {
  gcpConnection: GCPConnection;
  awsConnection: AWSConnection;
  connectionList: ConnectionList[];
}

const initialState: GranulateConnectionState = {
  gcpConnection,
  awsConnection,
  connectionList: [],
};

export const granulateConnectionSlice = createSlice({
  name: 'granulateConnection',
  initialState,
  reducers: {
    setGCPConnection(state, action: PayloadAction<GCPConnection>) {
      state.gcpConnection = action.payload;
    },
    setConnectionList(state, action: PayloadAction<ConnectionList[]>) {
      state.connectionList = action.payload;
    },
    setAWSConnection(state, action: PayloadAction<AWSConnection>) {
      state.awsConnection = action.payload;
    },
  },
});
export const selectGranulateConnection = (state: RootState) =>
  state.granulateConnection;
export const { setGCPConnection, setConnectionList, setAWSConnection } =
  granulateConnectionSlice.actions;

export default granulateConnectionSlice.reducer;
