import { Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import { convertHexToHsl } from 'utils/dashboardUtils';

import './index.scss';

const DrawerComponent = ({ children, dataTestId, ...remainingProps }: any) => {
  const { theme } = useSelector(selectTheme);

  return (
    <Drawer
      placement="right"
      width={remainingProps?.width ?? '400.98px'}
      closable={false}
      rootClassName={`drawer-component ${remainingProps.className}`}
      {...remainingProps}
      style={{
        ...remainingProps.style,
        color: theme.textColor,
        '--button-icon-color': theme.buttonIconColor,
        '--text-color': theme.textColor,
        '--primary-color': theme.primaryColor,
        '--table-header-color': convertHexToHsl(theme.primaryColor, { l: 96 }),
      }}
      data-testid={dataTestId}
    >
      {children}
    </Drawer>
  );
};

export default DrawerComponent;
