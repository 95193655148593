import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchPromptResponse
 * @description Function to fetch the recommended questions
 * @return axios response from GET request
 */
export const fetchRecommendedQuestions = async () => {
  return ServiceCalls.get(APIS.GET_CHATBOT_RECOMMENDED_QUESTIONS);
};

/**
 * @function fetchPromptResponse
 * @description Function to fetch the response for the chat queries
 * @param body  Object containing the request body
 * @return axios response from POST request
 */
export const fetchPromptResponse = async (body: any) => {
  return ServiceCalls.post(APIS.GET_CHATBOT_RESPONSE, body);
};

/**
 * @function fetchChatHistory
 * @description Function to fetch the chat history
 * @return axios response from GET request
 */
export const fetchChatHistory = async () => {
  return ServiceCalls.get(APIS.GET_CHAT_HISTORY);
};

/**
 * @function selectChatConnection
 * @description Function to update the selected chat connection
 * @param body  Object containing the request body
 * @return axios response from POST request
 */
export const selectChatConnection = async (body: any) => {
  return ServiceCalls.post(APIS.SELECT_CHAT_CONNECTION, body);
};

/**
 * @function clearConversationHistory
 * @description Function to clear the conversation history
 * @return axios response from POST request
 */
export const clearConversationHistory = async () => {
  return ServiceCalls.post(APIS.CLEAR_CONVERSATION_HISTORY);
};

/**
 * @function getAllSupportedGenAIProviders
 * @description Function to fetch all the supported GenAI providers and its sub models
 * @returns axios response from GET request
 */
export const getAllSupportedGenAIProviders = async () => {
  return ServiceCalls.get(APIS.GET_ALL_SUPPORTED_GEN_AI_PROVIDERS);
};

/**
 * @function getSelectedModel
 * @description Function to fetch the selected model
 * @return axios response from GET request
 */
export const getSelectedModel = async () => {
  return ServiceCalls.get(APIS.GET_SELECTED_MODEL);
};

/**
 * @function setSelectedModel
 * @description Function to update the selected chat connection
 * @param body  Object containing the request body
 * @return axios response from POST request
 */
export const setSelectedModel = async (body: any) => {
  return ServiceCalls.post(APIS.SELECT_MODEL, body);
};
