import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectPurchase, setSelectedGcpProjectId } from 'redux/purchaseSlice';
import Button from 'components/Button';
import Table from 'components/Table';
import { BUTTON_SIZE, INPUT_SIZE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import SelectDropdown from 'components/Select';
import { getGCPProjectNumberIdMapping } from 'utils/services';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';

import { GCPComputeEngineType } from '../../types';
import { fetchPurchasedGcpComputeEngineList } from '../../services';
import BuyComputeEngineFormModal from '../BuyComputeEngineFormModal';

import './index.scss';

const ComputeEngine = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, selectedGcpProjectId } =
    useSelector(selectPurchase);

  const [projectNumberIdMapping, setProjectNumberIdMapping] = useState([]);
  const [purchasedComputeEngines, setPurchasedComputeEngines] = useState<
    GCPComputeEngineType[]
  >([]);
  const [fetchProjectIdsRequestStatus, setFetchProjectIdsRequestStatus] =
    useState('');
  const [fetchComputeEngineRequestStatus, setFetchComputeEngineRequestStatus] =
    useState('');
  const [showBuyComputeEngineModal, setShowBuyComputeEngineModal] =
    useState(false);
  const [permissionValidationMessage, setPermissionValidationMessage] =
    useState('');

  useEffect(() => {
    if (selectedConnection) {
      dispatch(setSelectedGcpProjectId(''));
      fetchAllProjectIds();
    }
  }, [selectedConnection]);

  useEffect(() => {
    setPurchasedComputeEngines([]);
    setPermissionValidationMessage('');
    if (selectedGcpProjectId) {
      fetchPurchasedComputeEngines();
    }
  }, [selectedGcpProjectId]);

  /**
   * @function fetchAllProjectIds
   * @description Function to fetch and set all the GCP ProjectIds and name mapping
   */
  const fetchAllProjectIds = () => {
    setFetchProjectIdsRequestStatus(REQUEST_STATUS.PROCESSING);
    getGCPProjectNumberIdMapping(selectedConnection?.connectorId ?? '')
      .then((res: any) => {
        if (res?.status === 200) {
          const map = res?.data?.responseData || [];
          setProjectNumberIdMapping(map);
          map.length && dispatch(setSelectedGcpProjectId(map[0].projectId));
          setFetchProjectIdsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setProjectNumberIdMapping([]);
        setFetchProjectIdsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, true, setFetchProjectIdsRequestStatus);
        setProjectNumberIdMapping([]);
      });
  };

  /**
   * @function fetchPurchasedComputeEngines
   * @description Function to fetch all the purchased GCP Compute Engines
   */
  const fetchPurchasedComputeEngines = () => {
    setFetchComputeEngineRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedConnection?.connectorId,
      project: selectedGcpProjectId,
    };
    fetchPurchasedGcpComputeEngineList(params)
      .then((res: any) => {
        setPurchasedComputeEngines(res?.data?.responseData || []);
        setFetchComputeEngineRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchComputeEngineRequestStatus);
        if (e.response.data.message.includes('"code": 403')) {
          setPermissionValidationMessage(
            t(
              'purchasePage.committedUseDiscountsLabels.committmentPermissionError'
            )
          );
        } else {
          setPermissionValidationMessage('');
        }
      });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.region'),
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.vcpu'),
      dataIndex: 'vcpu',
      key: 'vcpu',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.memory'),
      dataIndex: 'memory',
      key: 'memory',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.localSSD'),
      dataIndex: 'localSSD',
      key: 'localSSD',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.gpus'),
      dataIndex: 'gpus',
      key: 'gpus',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.startTime'),
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.endTime'),
      dataIndex: 'endTime',
      key: 'endTime',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.duration'),
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: t('purchasePage.committedUseDiscountsLabels.autoRenew'),
      dataIndex: 'autoRenew',
      key: 'autoRenew',
    },
  ];

  return (
    <>
      <section className="compute-engine-table flex flex-column flex-gap-16">
        <div className="flex flex-column flex-gap-16">
          <div className="flex flex-align-items-center flex-space-between">
            <div className="form-header">
              {t(
                'purchasePage.committedUseDiscountsLabels.purchasedComputeEngine'
              )}
            </div>
            <Button
              title={t('purchasePage.committedUseDiscountsLabels.purchase')}
              size={BUTTON_SIZE.SMALL}
              loading={
                evaluateRequestArray([
                  fetchProjectIdsRequestStatus,
                  fetchComputeEngineRequestStatus,
                ]) === REQUEST_STATUS.PROCESSING
              }
              disabled={
                evaluateRequestArray([
                  fetchProjectIdsRequestStatus,
                  fetchComputeEngineRequestStatus,
                ]) === REQUEST_STATUS.ERROR
              }
              onClick={() => setShowBuyComputeEngineModal(true)}
            />
          </div>
          <SelectDropdown
            value={selectedGcpProjectId}
            onChange={(value: string) =>
              dispatch(setSelectedGcpProjectId(value))
            }
            options={projectNumberIdMapping.map((item: any) => ({
              label: item.projectId,
              value: item.projectId,
            }))}
            loading={
              evaluateRequestArray([
                fetchProjectIdsRequestStatus,
                fetchComputeEngineRequestStatus,
              ]) === REQUEST_STATUS.PROCESSING
            }
            rootClassName="project-id-dropdown"
            designVersion2
            size={INPUT_SIZE.SMALL}
          />
        </div>
        <Table
          pagination={false}
          dataSource={purchasedComputeEngines.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={columns}
          loading={
            evaluateRequestArray([
              fetchProjectIdsRequestStatus,
              fetchComputeEngineRequestStatus,
            ]) === REQUEST_STATUS.PROCESSING
          }
          locale={{
            emptyText: fetchComputeEngineRequestStatus ===
              REQUEST_STATUS.ERROR &&
              permissionValidationMessage && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={permissionValidationMessage}
                />
              ),
          }}
          designVersion2={true}
          scroll={{ y: 'calc(100vh - 20em)' }}
        />
      </section>
      {showBuyComputeEngineModal && (
        <BuyComputeEngineFormModal
          show={showBuyComputeEngineModal}
          setShow={setShowBuyComputeEngineModal}
        />
      )}
    </>
  );
};

export default ComputeEngine;
