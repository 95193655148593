import { CancelToken } from 'axios';

import { Config } from 'config';
import { store } from 'redux/store';
import { APIS } from 'constants/endpoints';
import {
  setHeaders,
  ServiceCalls,
  getAuthorizationHeader,
} from 'utils/ServiceCalls';

import { onApiCallError } from './handleErrors';

/**
 * @function fetchAuthToken
 * @description Function to fetch Auth token
 * @param accessToken - AD Bearer token
 * @return axios response from GET request
 */
export const fetchAuthToken = async (accessToken: string | null) => {
  const authTokenHeader = getAuthorizationHeader(accessToken);
  return ServiceCalls.get(APIS.GET_AUTH_TOKEN, authTokenHeader);
};

/**
 * @function logout
 * @description Function to logout from CB360
 * @return axios response from POST request
 */
export const logout = async () => {
  return ServiceCalls.post(APIS.LOGOUT);
};

/**
 * @function fetchConnectionData
 * @description Function to fetch connections data
 * @return axios response from GET request
 */
export const fetchConnectionData = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_ALL_CONNECTIONS, null, params);
};

/**
 * @function fetchConnectionById
 * @description Function to fetch connections data
 * @return axios response from GET request
 */
export const fetchConnectionById = async (connectionId: string) => {
  return ServiceCalls.get(APIS.GET_CONNECTION_BY_ID + connectionId);
};

/**
 * @function fetchFileMetaData
 * @description Function to fetch file meta data
 * @return axios response from GET request
 */
export const fetchFileMetaData = async (fileId?: string) => {
  const api = APIS.GET_FILE_META_DATA + fileId;
  return ServiceCalls.get(api);
};

/**
 * @function deleteConnectionFile
 * @description Function to delete connections file
 * @return axios response from POST request
 */
export const deleteConnectionFile = async (fileId: string) => {
  return ServiceCalls.delete(APIS.DELETE_CONNECTION_FILE + fileId);
};

/**
 * @function updateConnectionData
 * @description Function to update connections data
 * @return axios response from POST request
 */
export const updateConnectionData = async (body: any) => {
  return ServiceCalls.post(APIS.UPDATE_CONNECTION, body);
};

/**
 * @function uploadConnectionFile
 * @description Function to upload connecting CSP file
 * @return axios response from POST request
 */
export const uploadConnectionFile = async (file: any) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const formData = new FormData();
  formData.append('file', file);
  return ServiceCalls.post(APIS.UPLOAD_CONNECTION_FILE, formData, headers);
};

/**
 * @function addNewCSPConnection
 * @description Function to upload connecting CSP file
 * @return axios response from POST request
 */
export const addNewCSPConnection = async (body: any) => {
  return ServiceCalls.post(APIS.NEW_CSP_CONNECTION, body);
};

/**
 * @function getGcpProjectIdFromJson
 * @description Function to get project id from json file id
 * @param jsonId The json id that was received when uploading the file
 * @return axios response from GET request
 */
export const getGcpProjectIdFromJson = async (jsonId: any) => {
  return ServiceCalls.get(APIS.GET_GCP_PROJECT_ID + jsonId);
};

/**
 * @function getGcpDatasets
 * @description Function to get dataset list from json file id and project id
 * @param body The body which contains the project id and file
 * @return axios response from POST request
 */
export const getGcpDatasets = async (body: any) => {
  return ServiceCalls.post(APIS.GET_GCP_DATASET, body);
};

/**
 * @function getGcpDatasetTables
 * @description Function to get dataset tables list from json file id and project id and dataset
 * @param body The body which contains the project id and file
 * @return axios response from POST request
 */
export const getGcpDatasetTables = async (body: any) => {
  return ServiceCalls.post(APIS.GET_GCP_DATASET_TABLE, body);
};

/**
 * @function getAzureDatabaseTables
 * @description Function to get billing dataset tables list for azure
 * @param body The body which contains the project id and file
 * @return axios response from POST request
 */
export const getAzureDatabaseTables = async (body: any) => {
  return ServiceCalls.post(APIS.GET_AZURE_DATABASE_TABLES, body);
};

/**
 * @function fetchAddUserList
 * @description Function to get the add user search results from azure ad
 * @params username : value entered for search
 * @return axios response from POST request
 */
export async function fetchAddUserList(username: string): Promise<string[]> {
  const searchValue = username;
  const supportedDomains: string[] = Config.supported_domains.split(',');
  let domainFilter = '';
  supportedDomains.forEach((domain, index) => {
    domainFilter = domainFilter + `endsWith(mail,'@${domain}')`;
    if (index !== supportedDomains.length - 1) {
      domainFilter = domainFilter + ' or ';
    }
  });

  const api = `v1.0/users?$filter=(startsWith(displayName,'${searchValue}') or startsWith(mail,'${searchValue}')) and (${domainFilter})&$count=true&ConsistencyLevel=eventual`;
  const authorizationSlice = store.getState().userAuthorization;
  const authTokenHeader = getAuthorizationHeader(
    authorizationSlice.azureADAccessToken
  );
  return ServiceCalls.getFromAzure(api, authTokenHeader)
    .then((response) => response)
    .then((body: any) => {
      const { data } = body;
      const { value } = data;
      return value.map((user: { displayName: string; mail: string }) => ({
        email: user.mail,
        name: user.displayName,
      }));
    })
    .catch((err) => {
      onApiCallError(err);
    });
}

/**
 * @function getAllUsersData
 * @description Function to fetch users data
 * @param params request parameters
 * @param cancelToken cancel token
 * @return axios response from GET request
 */
export const getAllUsersData = async (
  params: any,
  cancelToken?: CancelToken
) => {
  return ServiceCalls.get(APIS.GET_ALL_USERS, null, params, cancelToken);
};

/**
 * @function getSearchUserData
 * @description Function to fetch users data
 * @param params request parameters
 * @param cancelToken cancel token
 * @return axios response from GET request
 */
export const getSearchUserData = async (
  params: any,
  cancelToken?: CancelToken
) => {
  return ServiceCalls.get(APIS.GET_SEARCH_USER, null, params, cancelToken);
};

/**
 * @function getAllTeams
 * @description Function to get all the teams
 * @param params request params
 * @param cancelToken cancel token
 * @return axios response from GET request
 */
export const getAllTeams = async (params: any, cancelToken?: CancelToken) => {
  return ServiceCalls.get(APIS.GET_ALL_TEAMS, null, params, cancelToken);
};

/**
 * @function searchTeams
 * @description Function to search the teams by key
 * @param params request params
 * @param cancelToken cancel token
 * @return axios response from GET request
 */
export const searchTeams = async (params: any, cancelToken?: CancelToken) => {
  return ServiceCalls.get(APIS.SEARCH_TEAMS, null, params, cancelToken);
};

/**
 * @function getUserprofilePhoto
 * @description Function to fetch users profile photo
 */
export const getUserprofilePhoto = () => {
  const authorizationSlice = store.getState().userAuthorization;
  const headers = setHeaders(
    getAuthorizationHeader(authorizationSlice.azureADAccessToken)
  );
  const AZURE_AD_SERVICE_URL = Config.azure_ad_url;
  const api = AZURE_AD_SERVICE_URL + APIS.GET_USER_PROFILE_PIC;
  return fetch(api, { headers: headers });
};

/**
 * @function getOtherUsersProfilePhoto
 * @description Function to fetch users profile photo
 */
export const getOtherUsersProfilePhoto = (email: string) => {
  const authorizationSlice = store.getState().userAuthorization;
  const headers = setHeaders(
    getAuthorizationHeader(authorizationSlice.azureADAccessToken)
  );
  const AZURE_AD_SERVICE_URL = Config.azure_ad_url;
  const api =
    AZURE_AD_SERVICE_URL +
    APIS.GET_OTHER_USER_PROFILE_PIC_STARTING +
    email +
    APIS.GET_OTHER_USER_PROFILE_PIC_ENDING;
  return fetch(api, { headers: headers });
};

/**
 * @function getChartData
 * @description Function to get the chart data to plot
 * @params body : object containing the request body
 * @params connectorId : connection id of GCP/AWS for which the data is requested
 * @return axios response from POST request
 */
export const getChartData = async (
  body: any,
  connectorId: string | undefined,
  params?: any,
  cancelToken?: CancelToken
) => {
  const requestAPI = APIS.GET_CHART_DATA + connectorId;
  if (cancelToken)
    return ServiceCalls.post(requestAPI, body, null, params, cancelToken);
  return ServiceCalls.post(requestAPI, body, null, params);
};

/**
 * @function updateDashboardTitle
 * @description Function to update the dashboard title
 * @param params object containing the dashboard id and new title
 * @return axios response from POST request
 */
export const updateDashboardTitle = async (params: any) => {
  const requestAPI = APIS.UPDATE_DASHBOARD_TITLE;
  return ServiceCalls.post(requestAPI, null, null, params);
};

/**
 * @function fetchAllBillingAccounts
 * @description Function to fetch all billing accounts
 * @params fileId : uploaded file id
 * @return axios response from GET request
 */
export const fetchAllBillingAccounts = async (params: any) => {
  const requestAPI = APIS.GET_GCP_BUDGET_BILLING_ACCOUNT;
  return ServiceCalls.get(requestAPI, null, params);
};

/**
 * @function fetchAllBudgets
 * @description Function to fetch all budgets
 * @return axios response from GET request
 */
export const fetchAllBudgets = async () => {
  const requestAPI = APIS.GCP_GET_ALL_BUDGET;
  return ServiceCalls.get(requestAPI);
};

/**
 * @function createBudget
 * @description Function to create new budget
 * @param connectorId : id for the budget
 * @param body : Object containing the request body
 * @return axios response from POST request
 */
export const createBudget = async (connectorId: string, body: any) => {
  const requestAPI = `${APIS.GCP_CREATE_BUDGET}?connectorId=${connectorId}`;
  return ServiceCalls.post(requestAPI, body);
};

/**
 * @function deleteAzureBudget
 * @description Function to delete Azure budget
 * @param params : Object containing having the request parameters.
 * @return axios response from POST request
 */
export const deleteAzureBudget = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_AZURE_BUDGET, null, params);
};

/**
 * @function deleteAwsBudget
 * @description Function to delete Aws budget
 * @return axios response from POST request
 */
export const deleteAwsBudget = async (params: any) => {
  return ServiceCalls.post(APIS.DELETE_AWS_BUDGET, null, null, params);
};

/**
 * @function deleteGcpBudget
 * @description Function to delete GCP budget
 * @return axios response from POST request
 */
export const deleteGcpBudget = async (params: any) => {
  return ServiceCalls.post(APIS.DELETE_GCP_BUDGET, null, null, params);
};

/**
 * @function getAwsBudget
 * @param params Contains body for request parameters
 * @description Function to get AWS budget
 * @return axios response from get request
 */
export const getAwsBudget = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_BUDGET, null, params);
};

/**
 * @function getAwsBudget
 * @description Function to get AWS budget
 * @param params Contains body for request params
 * @return axios response from get request
 */
export const getGcpBudget = async (params: any) => {
  return ServiceCalls.get(APIS.GET_GCP_BUDGET, null, params);
};

/**
 * @function editUser
 * @description Function to edit user
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const editUser = async (body: any) => {
  const requestAPI = APIS.USER_MANAGEMENT;
  return ServiceCalls.put(requestAPI, body);
};

/**
 * @function updateAwsBudget
 * @description Function to update AwsBudget
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const updateAwsBudget = async (body: any) => {
  return ServiceCalls.post(APIS.UPDATE_AWS_BUDGET, body);
};

/**
 * @function updateGcpBudget
 * @description Function to update GcpBudget
 * @body body : Object containing the request body
 * @params params: Object containing the request params
 * @return axios response from POST request
 */
export const updateGcpBudget = async (body: any, params: any) => {
  return ServiceCalls.post(APIS.UPDATE_GCP_BUDGET, body, null, params);
};

/**
 * @function getGcpBudgetServices
 * @params params : Object containing the params
 * @return axios response from GET request
 */
export const getGcpBudgetServices = async (params: any) => {
  return ServiceCalls.get(APIS.GET_GCP_BUDGET_SERVICES, null, params);
};

/**
 * @function getGcpBudgetProjects
 * @params params : Object containing the params
 * @return axios response from GET request
 */
export const getGcpBudgetProjects = async (params: any) => {
  return ServiceCalls.get(APIS.GET_GCP_BUDGET_PROJECTS, null, params);
};

/**
 * @function fetchDashboardList
 * @description Function to fetch dashboard data
 * @return axios response from GET request
 */
export const fetchDashboardList = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_ALL_CUSTOM_DASHBOARDS, null, params);
};

/**
 * @function getCustomDashboardDetails
 * @description Function to get selected dashboard details.
 * @param params params include id : dashboardId, user-email : user's email id
 * @return axios response from GET request
 */
export const getCustomDashboardDetails = async (params: any) => {
  return ServiceCalls.get(APIS.GET_CUSTOM_DASHBOARD_DETAILS, null, params);
};

/**
 * @function getDashboardUsers
 * @description Function to get dashboard users
 * @params body : Object containing the request body
 * @return axios response from GET request
 */
export const getDashboardUsers = async (dashboardId: string) => {
  return ServiceCalls.get(APIS.GET_DASHBOARD_USERS + dashboardId);
};

/**
 * @function fetchAllRolesNames
 * @description Function to fetch all roles names
 * @return axios response from GET request
 */
export const fetchAllRolesNames = async () => {
  return ServiceCalls.get(APIS.GET_ALL_ROLES_NAMES);
};

/**
 * @function fetchAllRolesNames
 * @description Function to fetch all roles names
 * @return axios response from GET request
 */
export const fetchGroupDashboardMetaData = async (groupName: string) => {
  return ServiceCalls.get(APIS.GET_GROUP + groupName);
};

/**
 * @function fetchImportFilesData
 * @description Function to fetch import files for table.
 * @return axios response from GET request
 */
export const fetchImportFilesData = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_ALL_IMPORT_FILES, null, params);
};

/**
 * @function deleteImportFile
 * @description Function to delete an import file from table.
 * @return axios response from DELETE request
 */
export const deleteImportFile = async (params?: any) => {
  return ServiceCalls.delete(APIS.DELETE_IMPORT_FILE, null, params);
};

/**
 * @function getGCPProjectNumberIdMapping
 * @description Function to fetch the list of project number to id mapping.
 * @param connectorId Connector ID for which the data is fetched
 * @return axios response from GET request
 */
export const getGCPProjectNumberIdMapping = async (connectorId: string) => {
  const requestAPI = APIS.GCP_PROJECT_NUMBER_ID_MAPPING + connectorId;
  return ServiceCalls.get(requestAPI);
};

/**
 * @function getAllDataCenters
 * @description Function to get all Data Centers
 * @param params Request parameters
 * @return axios response from GET request
 */
export const getAllDataCenters = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_ALL_DATA_CENTERS, null, params);
};

/**
 * @function getAllTcoProjects
 * @description Function to get all Tco projects
 * @return axios response from GET request
 */
export const getAllTcoProjects = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_ALL_TCO_PROJECT, null, params);
};

/**
 * @function getRulesetFilters
 * @description Function to get all the ruleset filters
 * @param params Object containing the request params
 * @return axios response from GET request
 */
export const getRulesetFilters = async (params: any) => {
  return ServiceCalls.get(APIS.GET_RULEST_FILTERS, null, params);
};

/**
 * @function getFilteredRecommendations
 * @description Function fetch the filtered recommendations
 * @param body request body for the post request
 * @param params request params for the post request
 * @return axios response from POST request
 */
export const getFilteredRecommendations = async (body: any, params: any) => {
  return ServiceCalls.post(
    APIS.GET_FILTERED_RECOMMENDATIONS,
    body,
    null,
    params
  );
};

/**
 * @function getAWSCategoryMapping
 * @description Function to get the aws checks mapping to category
 * @return axios response from GET request
 */
export const getAWSCategoryMapping = async () => {
  return ServiceCalls.get(APIS.GET_AWS_CATEGORY_MAPPING);
};

/**
 * @function searchConnectionData
 * @description Function to fetch connections data
 * @param params param object for url
 * @return axios response from GET request
 */
export const searchConnectionData = async (params: any) => {
  return ServiceCalls.get(APIS.SEARCH_CONNECTIONS, null, params);
};

/**
 * @function getGProfilerDashboardServices
 * @description Function to fetch G Profiler Dashboard services
 * @return axios response from GET request
 */
export const getGProfilerDashboardServices = async () => {
  return ServiceCalls.get(APIS.GPROFILER_DASHBOARD_SERVICES);
};

/**
 * @function getGProfilerDashboardDeployedServices
 * @description Function to fetch G Profiler Dashboard services
 * @return axios response from GET request
 */
export const getGProfilerDashboardDeployedServices = async () => {
  return ServiceCalls.get(APIS.GPROFILER_DASHBOARD_DEPLOYED_SERVICES);
};

/**
 * @function getAssigneeGroups
 * @description Function to fetch all the service now assignee groups
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const getAssigneeGroups = async (body: any) => {
  return ServiceCalls.post(APIS.GET_ASSIGNEE_GROUPS, body);
};

/**
 * @function getAssignToList
 * @description Function to fetch all the service now assign data
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const getAssignToList = async (body: any) => {
  return ServiceCalls.post(APIS.GET_ASSIGN_TO_LIST, body);
};

/**
 * @function getCategories
 * @description Function to fetch all the service now categories data
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const getCategories = async (body: any) => {
  return ServiceCalls.post(APIS.GET_CATEGORIES, body);
};

/**
 * @function getTicketConnection
 * @description Function to fetch the ticket connection details
 * @return axios response from GET request
 */
export const getTicketConnection = async () => {
  return ServiceCalls.get(APIS.GET_TICKET_CONNECTION);
};

/**
 * @function setDefaultDashboard
 * @description Function to set the default dashboard.
 * @param params Query parameters containing dashboardId
 * @return axios response from GET request
 */
export const setDefaultDashboard = async (params: any) => {
  const api = APIS.SET_DEFAULT_DASHBOARD;
  return ServiceCalls.post(api, null, null, params);
};

/**
 * @function getCustomGroupAvailableFields
 * @description Function to get the available fields for custom group
 * @return axios response from GET request
 */
export const getCustomGroupAvailableFields = async () => {
  return ServiceCalls.get(APIS.GET_CUSTOM_GROUP_AVAILABLE_FIELDS);
};

/**
 * @function getLabels
 * @description Function to get all the available labels for connection
 * @param params request parameters
 * @return axios response from GET request
 */
export const getLabels = async (params: any) => {
  return ServiceCalls.get(APIS.GET_LABELS, null, params);
};

/**
 * @function getStaticFileLabels
 * @description Function to get all the available labels for import static files
 * @param params request parameters
 * @return axios response from GET request
 */
export const getStaticFileLabels = async (params: any) => {
  return ServiceCalls.get(APIS.STATIC_FILE_LABELS, null, params);
};

/**
 * @function getBillingMappingData
 * @description Function to fetch the billing mapping data
 * @param params Object containing the request params
 * @return axios response from GET request
 */
export const getBillingMappingData = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_BILLING_DIMENSIONS_DATA, null, params);
};

/**
 * @function getSystemBillingMappingData
 * @description Function to fetch the system billing mapping data
 * @param params Object containing the request params
 * @return axios response from GET request
 */
export const getSystemBillingMappingData = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_SYSTEM_BILLING_DIMENSIONS, null, params);
};

/**
 * @function getTagMappingData
 * @description Function to fetch the tag mapping data
 * @param params Object containing the request params
 * @return axios response from GET request
 */
export const getTagMappingData = async (params?: any) => {
  return ServiceCalls.get(APIS.GET_TAG_DIMENSIONS_DATA, null, params);
};

/**
 * @function getGranulateDashboardServices
 * @description Function to fetch Granulate Dashboard services
 * @return axios response from GET request
 */
export const getGranulateDashboardServices = async () => {
  return ServiceCalls.get(APIS.GRANULATE_DASHBOARD_SERVICES);
};

/**
 * @function getGranulateConnections
 * @description Function to fetch Granulate connections
 * @return axios response from GET request
 */
export const getGranulateConnections = async () => {
  return ServiceCalls.get(APIS.GRANULATE_CONNECTORS);
};

/**
 * @function fetchSnowflakeIntegrations
 * @description Function to fetch all or search key based Snowflake Integrations data
 * @param params request params for GET request.
 * @return axios response from GET request
 */
export const fetchSnowflakeIntegrations = async (params: any) => {
  return ServiceCalls.get(APIS.SEARCH_SNOWFLAKE_INTEGRATIONS, null, params);
};

/**
 * @function getSnowflakeChartData
 * @description Function to get the chart data from snowflake database to plot
 * @param body object containing the request body
 * @param cancelToken token for cancelling the request
 * @return axios response from POST request
 */
export const getSnowflakeChartData = async (
  body: any,
  cancelToken?: CancelToken
) => {
  return ServiceCalls.post(
    APIS.SNOWFLAKE_GET_CHART_DATA,
    body,
    null,
    {},
    cancelToken
  );
};

/**
 * @function fetchIntegrationById
 * @description Function to fetch Integrations data
 * @return axios response from GET request
 */
export const fetchIntegrationById = async (params: any) => {
  return ServiceCalls.get(APIS.SNOWFLAKE_INTEGRATION, null, params);
};

/**
 * @function fetchAWSApplicationDetailsData
 * @description Function to fetch the application details for AWS
 * @param connectorId Connector Id for the connection
 * @param params Object for the request
 * @param cancelToken cancel token
 * @return axios response from GET request
 */
export const fetchAWSApplicationDetailsData = async (
  connectorId: string | undefined,
  params: any,
  cancelToken?: CancelToken
) => {
  return ServiceCalls.get(
    APIS.AWS_APPLICATION_DETAILS + connectorId,
    null,
    params,
    cancelToken
  );
};

/**
 * @function createSnowIncident
 * @description Function to create SNOW Incident(s)
 * @param body Request body
 * @return axios response from POST request
 */
export const createSnowIncident = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_SNOW_INCIDENT, body, null);
};

/**
 * @function getRecommendationData
 * @description Function to fetch G Profiler Dashboard services
 * @return axios response from GET request
 */
export const getRecommendationData = async (params: {
  serviceName: string;
  provider?: string;
  connectorId?: string;
}) => {
  return ServiceCalls.get(APIS.RECOMMENDATION_DATA, null, params);
};

/**
 * @function getRecommendationData
 * @description Function to fetch G Profiler Dashboard services
 * @return axios response from GET request
 */
export const getRecommendationCostData = async (params: {
  serviceName: string;
  provider?: string;
  connectorId?: string;
}) => {
  return ServiceCalls.get(APIS.RECOMMENDATION_DATA_COST, null, params);
};

/**
 * @function getServiceSpendProfile
 * @description Function to get the chart data to plot
 * @params body : object containing the request body
 * @params connectorId : connection id of GCP/AWS for which the data is requested
 * @return axios response from POST request
 */
export const getServiceSpendProfile = async (
  body: any,
  connectorId: string | undefined
) => {
  const requestAPI = APIS.GET_APPLICATION_SPEND_PROFILE_DATA + connectorId;
  return ServiceCalls.post(requestAPI, body);
};

/**
 * @function getGranulateTotalCostImpact
 * @description Calls the Granulate total cost impact api
 * @return axios response from GET request
 */
export const getGranulateTotalCostImpact = (params: any) => {
  return ServiceCalls.get(APIS.GRANULATE_TOTAL_COST_IMPACT, null, params);
};
