import i18n from 'i18n';

import ColorBlock from 'components/LegendColorBlock';
import { store } from 'redux/store';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const CostTrendColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 50,
  },
  {
    title: i18n.t('costTrend.month'),
    dataIndex: 'month',
    key: 'month',
    align: 'center' as const,
  },
  {
    title: i18n.t('costTrend.costInCurrency', {
      currencySymbol: currencySymbol,
    }),
    dataIndex: 'cost',
    key: 'cost',
    align: 'right' as const,
    render: (text: any) =>
      text ? currencySymbol + numberCommaSeparator(text) : '--',
  },
  {
    title: i18n.t('costTrend.forecastedCostWithCurrency', {
      currencySymbol: currencySymbol,
    }),
    dataIndex: 'forecastedCost',
    key: 'forecastedCost',
    align: 'right' as const,
    render: (text: any) =>
      text ? currencySymbol + numberCommaSeparator(text) : '--',
  },
];

export const TaggedUntaggedSpendColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('months'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: i18n.t('taggedCostInCurrency', { currencySymbol }),
    dataIndex: 'Tagged',
    key: 'Tagged',
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
  },
  {
    title: i18n.t('untaggedCostInCurrency', { currencySymbol }),
    dataIndex: 'Untagged',
    key: 'Untagged',
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
    align: 'right' as const,
  },
];

export const CostByRegionColumns = [
  {
    title: '#',
    dataIndex: 'color',
    key: 'color',
    render: ColorBlock,
    width: 30,
  },
  {
    title: i18n.t('costByAwsRegion.region'),
    dataIndex: 'region',
    key: 'region',
  },
  {
    title: i18n.t('costByAwsRegion.cost'),
    dataIndex: 'cost',
    key: 'cost',
    align: 'right' as const,
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
  },
];
