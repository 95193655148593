import moment from 'moment';

import {
  DATE_INTERVALS,
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';
import {
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
} from 'constants/requestBody';
import {
  DATE_FORMAT,
  DATE_MONTH_SHORT,
  FULL_YEAR_FORMAT,
  HOUR_MINUTE_SECONDS,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_SHORT,
  QUARTER,
  WEEK,
} from 'utils/date';
import { DateRangeFilter } from 'types/dataTypes';

import {
  POD_CPU_UTILIZATION_DIMENSIONS,
  FILE_UTILIZATION_DIMENSIONS,
  POD_MEMORY_UTILIZATION_DIMENSIONS,
  POD_NETWORK_UTILIZATION_DIMENSIONS,
  CONTAINER_CPU_UTILIZATION_DIMENSIONS,
  CONTAINER_MEMORY_UTILIZATION_DIMENSIONS,
} from './constants';

/**
 * @function getDateFormatForGraphLabels
 * @description Function to return the date format based on the interval
 * @param interval interval of the dates
 * @returns string query field
 */
export const getDateFormatForGraphLabels = (interval: string) => {
  switch (interval) {
    case DATE_INTERVALS.HOUR:
      return HOUR_MINUTE_SECONDS;
    case DATE_INTERVALS.DAY:
    case DATE_INTERVALS.WEEK:
      return DATE_MONTH_SHORT;

    case DATE_INTERVALS.MONTH:
    case DATE_INTERVALS.QUARTER:
      return MONTH_YEAR_SHORT;

    case DATE_INTERVALS.YEAR:
      return FULL_YEAR_FORMAT;
  }
};

/**
 * @function getFormattedStartDate
 * @description Function to format the start date based on the date interval
 * @param startDate Date to be formatted
 * @param interval date interval eg: day, month, week, quarter, year
 * @returns formatted date
 */
export const getFormattedStartDate = (startDate: string, interval: string) => {
  if (interval === DATE_INTERVALS.WEEK) {
    const week = moment(startDate).week();
    const year = moment(startDate).year();
    return moment().year(year).week(week).startOf('week');
  }

  if (interval === DATE_INTERVALS.QUARTER) {
    return moment(startDate).startOf('quarter');
  }

  return moment(startDate);
};

/**
 * @function formatDateFieldData
 * @description Function to format the date based on the date interval
 * @param date Date to be formatted
 * @param interval date interval eg: day, month, week, quarter, year
 * @returns formatted date
 */
export const formatDateFieldData = (date: string, interval: string) => {
  switch (interval) {
    case DATE_INTERVALS.WEEK:
      return moment(date).startOf(WEEK);

    case DATE_INTERVALS.QUARTER:
      return moment(date).startOf(QUARTER);

    default:
      return moment(date);
  }
};

/**
 * @function onChangeDateFilter
 * @description Function handles change in date range filter option.
 * @param value option selected
 * @param setDateFilter callback function for setting the data
 */
export const onChangeDateFilter = (
  value: string,
  setDateFilter: (val: DateRangeFilter) => void
) => {
  switch (value) {
    case DATE_RANGES.TODAY:
      setDateFilter({
        option: value,
        startDate: moment().startOf('day').format(HYPHEN_DATE_FORMAT),
        endDate: moment().endOf('day').format(HYPHEN_DATE_FORMAT),
      });
      return;

    case DATE_RANGES.YESTERDAY:
      setDateFilter({
        option: value,
        startDate: moment()
          .subtract(1, 'day')
          .startOf('day')
          .format(HYPHEN_DATE_FORMAT),
        endDate: moment()
          .subtract(1, 'day')
          .endOf('day')
          .format(HYPHEN_DATE_FORMAT),
      });
      return;

    case DATE_RANGES.THIS_MONTH:
      setDateFilter({
        option: value,
        startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
        endDate: moment().endOf('month').format(HYPHEN_DATE_FORMAT),
      });
      return;

    case DATE_RANGES.THIS_QUARTER:
      setDateFilter({
        option: value,
        startDate: moment().startOf('quarter').format(HYPHEN_DATE_FORMAT),
        endDate: moment().endOf('quarter').format(HYPHEN_DATE_FORMAT),
      });
      return;

    case DATE_RANGES.THIS_YEAR:
      setDateFilter({
        option: value,
        startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
        endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
      });
      return;
  }
};

/**
 * @function onChangeDates
 * @description Function handles change in start and end date of custom date range.
 * @param dateString is an array whose first element is start date and second element is end date.
 * @param setDateFilter callback function for setting up the date range for custom ranges.
 */
export const onChangeDates = (
  dateString: [string, string],
  setDateFilter: (val: DateRangeFilter) => void
) => {
  setDateFilter({
    option: DATE_RANGES.CUSTOM_RANGE,
    startDate: dateString[0],
    endDate: dateString[1],
  });
};

/**
 * @function displayRender
 * @description Function to display selected dates (if date range is custom) or date range type.
 * @param dateRangeFilter is an object containing the option selected and the date range.
 * @return Returns label as is if date range is not custom. Returns the dates selected if date range is custom in a string form
 */
export const displayRender = (dateRangeFilter: DateRangeFilter) => {
  if (dateRangeFilter.option === DATE_RANGES.CUSTOM_RANGE) {
    return `${moment(dateRangeFilter.startDate).format(DATE_FORMAT)} - ${moment(
      dateRangeFilter.endDate
    ).format(DATE_FORMAT)}`;
  }
  return (
    DATE_RANGE_OPTIONS.find((item) => item.key === dateRangeFilter.option)
      ?.title ?? ''
  );
};

/**
 * @function formatMetricField
 * @description Function to format the metric field for query.
 * @param field string field for which the formatting is done.
 * @return Returns the string formatted query field
 */
const formatMetricField = (field: string) => {
  return `(AVG(CAST(CASE WHEN ${field} = '' THEN null ELSE ${field} END AS DOUBLE)))`;
};

/**
 * @function getQueryFilters
 * @description Function to return the query filters
 * @param dateRange Array containing the start and end date.
 * @param clusters array of selected clusters
 * @return Returns the list of query filters
 */
const getQueryFilters = (dateRange: string[], clusters: string[]) => {
  const filters = [
    {
      field: QUERY_FIELDS.FROM_UNIXTIME_CAST_BIGINT,
      comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
      value: `(TIMESTAMP '${moment(dateRange[0]).format(
        HYPHEN_DATE_FORMAT
      )} 00:00:00')`,
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
    {
      field: QUERY_FIELDS.FROM_UNIXTIME_CAST_BIGINT,
      comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
      value: `(TIMESTAMP '${moment(dateRange[1]).format(
        HYPHEN_DATE_FORMAT
      )} 23:59:59')`,
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
    {
      field: QUERY_FIELDS.INSTANCE_ID_NO_SPACE,
      comparator: COMPARATORS.NOT_EQUAL_TO,
      value: '',
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
    {
      field: QUERY_FIELDS.CLUSTER_NAME_WITHOUT_SPACE,
      comparator: COMPARATORS.IN,
      value: `('${clusters.join("','")}')`,
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
  ];

  return filters;
};

/**
 * @function awsContainerInsightsDateFieldByInterval
 * @description Function to return the aws container insights date query field based on the interval
 * @param interval interval of the dates
 * @returns string query field
 */
const awsContainerInsightsDateFieldByInterval = (interval: string) => {
  switch (interval) {
    case DATE_INTERVALS.HOUR:
      return "(DATE_TRUNC('HOUR', from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))))";

    case DATE_INTERVALS.DAY:
      return "(DATE_TRUNC('DAY', from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))))";

    case DATE_INTERVALS.WEEK:
      return "(DATE_TRUNC('WEEK', from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))))";

    case DATE_INTERVALS.MONTH:
      return "(DATE_TRUNC('MONTH', from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))))";

    case DATE_INTERVALS.QUARTER:
      return "(DATE_TRUNC('QUARTER', from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))))";

    case DATE_INTERVALS.YEAR:
      return "(DATE_TRUNC('YEAR', from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))))";
  }
};

/**
 * @function getPodCpuUtilizationQuery
 * @description Function to return the pod cpu utilization query.
 * @param dateRange Array containing the start and end date.
 * @param interval interval of the dates
 * @param clusters array of selected clusters
 * @return Returns the list of query filters
 */
export const getPodCpuUtilizationQuery = (
  dateRange: string[],
  interval: string,
  clusters: string[]
) => {
  return {
    columns: [
      {
        label: 'node',
        field: QUERY_FIELDS.NODE_NAME,
      },
      {
        label: 'time',
        field: awsContainerInsightsDateFieldByInterval(interval),
      },
      ...POD_CPU_UTILIZATION_DIMENSIONS.map((dimension) => ({
        label: dimension.dataLabel,
        field: formatMetricField(dimension.field),
      })),
    ],
    groupBy: ['time', 'node'],
    filterGroups: [
      {
        filters: getQueryFilters(dateRange, clusters),
      },
    ],
    dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
    cached: true,
  };
};

/**
 * @function getPodMemoryUtilizationQuery
 * @description Function to return the pod memory utilization query.
 * @param dateRange Array containing the start and end date.
 * @param clusters array of selected clusters
 * @return Returns the list of query filters
 */
export const getPodMemoryUtilizationQuery = (
  dateRange: string[],
  clusters: string[]
) => {
  return {
    columns: [
      {
        label: 'node',
        field: QUERY_FIELDS.NODE_NAME,
      },
      ...POD_MEMORY_UTILIZATION_DIMENSIONS.map((dimension: any) => ({
        label: dimension.dataLabel,
        field: formatMetricField(dimension.field),
      })),
    ],
    groupBy: ['node'],
    filterGroups: [
      {
        filters: getQueryFilters(dateRange, clusters),
      },
    ],
    dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
    cached: true,
  };
};

/**
 * @function getPodNetworkUtilizationQuery
 * @description Function to return the pod network utilization query.
 * @param dateRange Array containing the start and end date.
 * @param clusters array of selected clusters
 * @return Returns the list of query filters
 */
export const getPodNetworkUtilizationQuery = (
  dateRange: string[],
  clusters: string[]
) => {
  return {
    columns: [
      {
        label: 'node',
        field: QUERY_FIELDS.NODE_NAME,
      },
      ...POD_NETWORK_UTILIZATION_DIMENSIONS.map((dimension: any) => ({
        label: dimension.dataLabel,
        field: formatMetricField(dimension.field),
      })),
    ],
    groupBy: ['node'],
    filterGroups: [
      {
        filters: getQueryFilters(dateRange, clusters),
      },
    ],
    dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
    cached: true,
  };
};

/**
 * @function getNodeFileUtilizationQuery
 * @description Function to return the node file utilization query.
 * @param dateRange Array containing the start and end date.
 * @param interval interval of the dates
 * @param clusters array of selected clusters
 * @return Returns the list of query filters
 */
export const getNodeFileUtilizationQuery = (
  dateRange: string[],
  interval: string,
  clusters: string[]
) => {
  return {
    columns: [
      {
        label: 'node',
        field: QUERY_FIELDS.NODE_NAME,
      },
      {
        label: 'time',
        field: awsContainerInsightsDateFieldByInterval(interval),
      },
      ...FILE_UTILIZATION_DIMENSIONS.map((dimension) => ({
        label: dimension.dataLabel,
        field: formatMetricField(dimension.field),
      })),
    ],
    groupBy: ['time', 'node'],
    filterGroups: [
      {
        filters: getQueryFilters(dateRange, clusters),
      },
    ],
    dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
    cached: true,
  };
};

/**
 * @function getContainerCpuUtilizationQuery
 * @description Function to return the container cpu utilization query.
 * @param dateRange Array containing the start and end date.
 * @param interval interval of the dates
 * @param clusters array of selected clusters
 * @return Returns the list of query filters
 */
export const getContainerCpuUtilizationQuery = (
  dateRange: string[],
  interval: string,
  clusters: string[]
) => {
  return {
    columns: [
      {
        label: 'node',
        field: QUERY_FIELDS.NODE_NAME,
      },
      {
        label: 'time',
        field: awsContainerInsightsDateFieldByInterval(interval),
      },
      ...CONTAINER_CPU_UTILIZATION_DIMENSIONS.map((dimension) => ({
        label: dimension.dataLabel,
        field: formatMetricField(dimension.field),
      })),
    ],
    groupBy: ['time', 'node'],
    filterGroups: [
      {
        filters: getQueryFilters(dateRange, clusters),
      },
    ],
    dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
    cached: true,
  };
};

/**
 * @function getContainerMemoryUtilizationQuery
 * @description Function to return the container memory utilization query.
 * @param dateRange Array containing the start and end date.
 * @param interval interval of the dates
 * @param clusters array of selected clusters
 * @return Returns the list of query filters
 */
export const getContainerMemoryUtilizationQuery = (
  dateRange: string[],
  interval: string,
  clusters: string[]
) => {
  return {
    columns: [
      {
        label: 'node',
        field: QUERY_FIELDS.NODE_NAME,
      },
      {
        label: 'time',
        field: awsContainerInsightsDateFieldByInterval(interval),
      },
      ...CONTAINER_MEMORY_UTILIZATION_DIMENSIONS.map((dimension) => ({
        label: dimension.dataLabel,
        field: formatMetricField(dimension.field),
      })),
    ],
    groupBy: ['time', 'node'],
    filterGroups: [
      {
        filters: getQueryFilters(dateRange, clusters),
      },
    ],
    dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
    cached: true,
  };
};
