import moment, { Moment } from 'moment';
import { Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import {
  generateGraphColors,
  getFirstMonthAndCurrentYear,
  getGCPTagFiltersData,
  getPreviousMonthAndYear,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
  REQUEST_STATUS,
} from 'constants/requestBody';
import {
  CostAndCreditBreakdownType,
  CreditCostMetricType,
  CreditDistributionByLocationColorTypes,
  CreditDistributionByLocationTypes,
  CreditDistributionByProjectColorTypes,
  CreditDistributionByProjectTypes,
  MonthlyCreditCostMetricType,
} from 'types/dataTypes';
import {
  formatDateFieldByProvider,
  getMonthAndYearDigitsFormat,
  getMonthYearShortList,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { getChartData } from 'utils/services';
import ExpandModal from 'components/ExpandModal';
import { CreditComparisonListType, GraphRowContentType } from 'types/dashboard';
import ComparisonCard from 'components/NewComparisonCard';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { onApiCallError } from 'utils/handleErrors';
import { CHART_TYPES } from 'constants/graphConfig';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { getPdfMetaData } from 'pages/OverviewPage/components/ConnectionsDashboard/utils';
import DashboardGraphContainer from 'components/DashboardGraphContainer';
import { PROVIDER } from 'constants/cloudProviders';

import { CreditDistributionByLocation } from './components/CreditDistributionByLocation';
import { CreditDistributionByProject } from './components/CreditDistributionByProject';
import { CreditDiscountBreakdown } from './components/CreditDiscountBreakdown';
import { CostAndCreditBreakdown } from './components/CostAndCreditBreakdown';
import { getCreditDiscountBreakdownExportColumns } from './utils/exportToExcel';

import {
  CostAndCreditBreakdownExportColumns,
  CreditDistributionByLocationExportColumns,
  CreditDistributionByProjectExportColumns,
} from './constants';

const GCPCreditSummaryDashboard = () => {
  const {
    selectedDashboard,
    selectedConnection,
    tagsFilters,
    selectedDashboardView,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [creditSummaryList, setCreditSummaryList] = useState<
    CreditComparisonListType[]
  >([]);
  const [creditDiscountBreakdownLabels, setCreditDiscountBreakdownLabels] =
    useState<string[]>([]);

  //State for YTD Promotional Credit
  const [ytdPromotionalCredit, setYtdPromotionalCredit] = useState(0);
  const [
    ytdPromotionalCreditRequestStatus,
    setYtdPromotionalCreditRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  //Credit Distribution by Location states
  const [
    creditDistributionByLocationData,
    setCreditDistributionByLocationData,
  ] = useState<CreditDistributionByLocationColorTypes[]>([]);
  const [
    creditDistributionByLocationRequestStatus,
    setCreditDistributionByLocationRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [creditByLocationStartMonth, setCreditByLocationStartMonth] = useState(
    getPreviousMonthAndYear(11)
  );
  const [creditByLocationEndMonth, setCreditByLocationEndMonth] = useState(
    getPreviousMonthAndYear(0)
  );

  //Cost Credit Breakdown states
  const [costCreditBreakdownData, setCostCreditBreakdownData] = useState<
    CostAndCreditBreakdownType[]
  >([]);
  const [
    costCreditBreakdownRequestStatus,
    setCostCreditBreakdownRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [costAndCreditStartMonth, setCostAndCreditStartMonth] = useState(
    getPreviousMonthAndYear(11)
  );
  const [costAndCreditEndMonth, setCostAndCreditEndMonth] = useState(
    getPreviousMonthAndYear(0)
  );

  //Credit or Discount Breakdown states
  const [monthlyCreditByCostTypeData, setMonthlyCreditByCostTypeData] =
    useState<MonthlyCreditCostMetricType[]>([]);
  const [
    monthlyCreditByCostRequestStatus,
    setMonthlyCreditByCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [creditAndDiscountStartMonth, setCreditAndDiscountStartMonth] =
    useState(getPreviousMonthAndYear(11));
  const [creditAndDiscountEndMonth, setCreditAndDiscountEndMonth] = useState(
    getPreviousMonthAndYear(0)
  );

  //Credit Distribution by project states
  const [creditDistributionByProjectData, setCreditDistributionByProjectData] =
    useState<CreditDistributionByProjectColorTypes[]>([]);
  const [
    creditDistributionByProjectRequestStatus,
    setCreditDistributionByProjectRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [creditByProjectStartMonth, setCreditByProjectStartMonth] = useState(
    getPreviousMonthAndYear(11)
  );
  const [creditByProjectEndMonth, setCreditByProjectEndMonth] = useState(
    getPreviousMonthAndYear(0)
  );

  // Table view states
  const [
    isCostAndCreditBreakdownTableView,
    setIsCostAndCreditBreakdownTableView,
  ] = useState(false);
  const [
    isCreditDiscountBreakdownTableView,
    setIsCreditDiscountBreakdownTableView,
  ] = useState(false);
  const [
    isCreditDistributionByProjectTableView,
    setIsCreditDistributionByProjectTableView,
  ] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setYtdPromotionalCredit(0);
    if (selectedDashboard) {
      getGCPYTDPromotionalCredit();
    }
  }, [selectedDashboard, tagsFilters]);

  useEffect(() => {
    setCreditSummaryList([
      {
        credit: ytdPromotionalCredit,
        creditType: t('creditComparisonCard.ytdPromotionalCredit'),
        requestStatus: ytdPromotionalCreditRequestStatus,
      },
    ]);
  }, [
    ytdPromotionalCredit,
    costAndCreditStartMonth,
    costAndCreditEndMonth,
    ytdPromotionalCreditRequestStatus,
  ]);

  useEffect(() => {
    setCostCreditBreakdownData([]);
    if (selectedDashboard) {
      getGCPCostAndCreditBreakdownData();
    }
  }, [
    selectedDashboard,
    costAndCreditStartMonth,
    costAndCreditEndMonth,
    tagsFilters,
  ]);

  useEffect(() => {
    setCreditDiscountBreakdownLabels([]);
    setMonthlyCreditByCostTypeData([]);
    if (selectedDashboard) {
      getGCPCreditOrDiscountBreakdownData();
    }
  }, [
    selectedDashboard,
    creditAndDiscountStartMonth,
    creditAndDiscountEndMonth,
    tagsFilters,
  ]);

  useEffect(() => {
    setCreditDistributionByLocationData([]);
    if (selectedDashboard) {
      getGCPCreditDistributionByLocation();
    }
  }, [
    selectedDashboard,
    creditByLocationStartMonth,
    creditByLocationEndMonth,
    tagsFilters,
  ]);

  useEffect(() => {
    setCreditDistributionByProjectData([]);

    // Do not fetch the project related data when the data source is static file as there is no FOCUS mapping
    if (selectedDashboard && !isDashboardWithStaticData(selectedDashboard)) {
      getGCPCreditDistributionByProject();
    }
  }, [
    selectedDashboard,
    creditByProjectStartMonth,
    creditByProjectEndMonth,
    tagsFilters,
  ]);

  const getGCPYTDPromotionalCredit = () => {
    setYtdPromotionalCreditRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'credit',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'credit',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.COST_METRICS_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL_TO,
              value: QUERY_VALUES.SUSTAINED_USAGE_DISCOUNT,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.COST_METRICS_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL_TO,
              value: QUERY_VALUES.COMMITTED_USAGE_DISCOUNT,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                getFirstMonthAndCurrentYear(),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                getPreviousMonthAndYear(0),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };
    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        setYtdPromotionalCredit(res?.data?.[0]?.credit?.toFixed(2) ?? 0);
        setYtdPromotionalCreditRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setYtdPromotionalCreditRequestStatus);
      });
  };

  /**
   * @function onChangeCostAndCreditDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostAndCreditDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostAndCreditStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setCostAndCreditEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  /**
   * @function onChangeCreditByLocationDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCreditByLocationDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCreditByLocationStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setCreditByLocationEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  /**
   * @function onChangeCreditByProjectDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCreditByProjectDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCreditByProjectStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setCreditByProjectEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  /**
   * @function onChangeCreditAndDiscountDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCreditAndDiscountDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCreditAndDiscountStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    setCreditAndDiscountEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
  };

  const getGCPCreditDistributionByLocation = () => {
    setCreditDistributionByLocationRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'credit',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'region',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LOCATION_REGION,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'credit',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditByLocationStartMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditByLocationEndMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      groupBy: ['region'],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const regionCreditData: CreditDistributionByLocationTypes[] =
          res?.data?.filter((item: any) => item.region !== null);
        const colors = generateGraphColors(regionCreditData.length);
        regionCreditData.sort((item1, item2) => item2.credit - item1.credit);
        setCreditDistributionByLocationData(
          regionCreditData.map((item: any, index: any) => ({
            ...item,
            color: colors.at(index),
          }))
        );
        setCreditDistributionByLocationRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCreditDistributionByLocationRequestStatus);
      });
  };

  const getGCPCostAndCreditBreakdownData = () => {
    setCostCreditBreakdownRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'credit',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'costAfterCredit',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.INVOICE_MONTH,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
        {
          label: 'credit',
          function: AGGREGATORS.SUM,
        },
        {
          label: 'costAfterCredit',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costAndCreditStartMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costAndCreditEndMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        let labels: string[] = getMonthYearShortList(
          costAndCreditStartMonth,
          costAndCreditEndMonth,
          MONTH_YEAR_FORMAT
        );

        let breakdownData: CostAndCreditBreakdownType[] = [];

        labels.forEach((label) => {
          let currentItem = res?.data?.find(
            (item: any) =>
              moment(item.month).format(YEAR_MONTH_WITHOUT_SEPARATOR) ===
              getMonthAndYearDigitsFormat(label)
          );
          breakdownData.push({
            month: label,
            cost: currentItem?.cost || 0,
            credit: currentItem?.credit || 0,
            costAfterCredit: currentItem?.costAfterCredit || 0,
          });
        });
        setCostCreditBreakdownData(breakdownData);
        setCostCreditBreakdownRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostCreditBreakdownRequestStatus);
      });
  };

  const getGCPCreditOrDiscountBreakdownData = () => {
    setMonthlyCreditByCostRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'credit',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'cost_metric_type',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST_METRICS_TYPE,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.INVOICE_MONTH,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'credit',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['cost_metric_type', 'month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditAndDiscountStartMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditAndDiscountEndMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        let creditByMonth: MonthlyCreditCostMetricType[] = [];
        let labels: string[] = getMonthYearShortList(
          creditAndDiscountStartMonth,
          creditAndDiscountEndMonth
        );
        res?.data?.forEach((item: CreditCostMetricType) => {
          const existingIndex = creditByMonth?.findIndex(
            (eachItem) => eachItem.costMetricType === item.cost_metric_type
          );
          const index = labels.findIndex(
            (eachLabel) =>
              eachLabel === moment(item.month).format(MONTH_YEAR_SHORT)
          );

          if (existingIndex >= 0) {
            creditByMonth[existingIndex].creditData[index] = item?.credit;
          } else {
            let creditData: number[] = Array(12).fill(0);
            creditData[index] = item?.credit;
            creditByMonth.push({
              costMetricType: item.cost_metric_type,
              creditData: creditData,
            });
          }
        });
        setCreditDiscountBreakdownLabels(labels);
        setMonthlyCreditByCostTypeData(creditByMonth);
        setMonthlyCreditByCostRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setMonthlyCreditByCostRequestStatus);
      });
  };

  const getGCPCreditDistributionByProject = () => {
    setCreditDistributionByProjectRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'credit',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'project',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PROJECT_NAME,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'credit',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditByProjectStartMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                creditByProjectEndMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      groupBy: ['project'],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const colors = generateGraphColors(res?.data?.length || 0);
        setCreditDistributionByProjectData(
          (res?.data || []).map((item: CreditDistributionByProjectTypes) => ({
            ...item,
            project: item.project || 'Others',
            color: colors.pop(),
          }))
        );
        setCreditDistributionByProjectRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCreditDistributionByProjectRequestStatus);
      });
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'credit-distribution-by-location':
        return (
          <CreditDistributionByLocation
            requestStatus={creditDistributionByLocationRequestStatus}
            creditDistributionByLocationData={creditDistributionByLocationData}
            pdfView={pdfView}
            creditByLocationStartMonth={creditByLocationStartMonth}
            creditByLocationEndMonth={creditByLocationEndMonth}
            onChangeCreditByLocationDateRange={
              onChangeCreditByLocationDateRange
            }
          />
        );
      case 'cost-and-credit-breakdown':
        return (
          <CostAndCreditBreakdown
            isCostAndCreditBreakdownTableView={
              isCostAndCreditBreakdownTableView
            }
            setCostAndCreditBreakdownTableView={
              setIsCostAndCreditBreakdownTableView
            }
            requestStatus={costCreditBreakdownRequestStatus}
            costCreditBreakdownData={costCreditBreakdownData}
            pdfView={pdfView}
            costAndCreditStartMonth={costAndCreditStartMonth}
            costAndCreditEndMonth={costAndCreditEndMonth}
            onChangeCostAndCreditDateRange={onChangeCostAndCreditDateRange}
          />
        );
      case 'credit-discout-breakdown':
        return (
          <CreditDiscountBreakdown
            isCreditDiscountBreakdownTableView={
              isCreditDiscountBreakdownTableView
            }
            setCreditDiscountBreakdownTableView={
              setIsCreditDiscountBreakdownTableView
            }
            requestStatus={monthlyCreditByCostRequestStatus}
            creditDiscountBreakdownLabels={creditDiscountBreakdownLabels}
            monthlyCreditByCostTypeData={monthlyCreditByCostTypeData}
            pdfView={pdfView}
            creditAndDiscountStartMonth={creditAndDiscountStartMonth}
            creditAndDiscountEndMonth={creditAndDiscountEndMonth}
            onChangeCreditAndDiscountDateRange={
              onChangeCreditAndDiscountDateRange
            }
          />
        );
      case 'credit-distribution-by-project':
        return (
          <CreditDistributionByProject
            isCreditDistributionByProjectTableView={
              isCreditDistributionByProjectTableView
            }
            setCreditDistributionByProjectTableView={
              setIsCreditDistributionByProjectTableView
            }
            requestStatus={creditDistributionByProjectRequestStatus}
            creditDistributionByProjectData={creditDistributionByProjectData}
            pdfView={pdfView}
            creditByProjectStartMonth={creditByProjectStartMonth}
            creditByProjectEndMonth={creditByProjectEndMonth}
            onChangeCreditByProjectDateRange={onChangeCreditByProjectDateRange}
          />
        );
    }
  };

  const getCostComparisonCards = () =>
    creditSummaryList.map((item, index) => {
      return (
        <ComparisonCard
          key={item.creditType}
          index={index}
          value={item.credit}
          heading={item.creditType}
          valuePrefix={currencySymbol}
          requestStatus={[item.requestStatus]}
        />
      );
    });

  // Memoized data for the graph rows to improve performance
  const GraphRows: GraphRowContentType = useMemo(
    () => [
      [
        {
          element: getGraphComponent('cost-and-credit-breakdown', true),
          contentType: CHART_TYPES.GROUPED_CHART,
          graphName: 'cost-and-credit-breakdown',
          graphHeading: t('graphHeadings.costandCreditBreakdown'),
          chartView: {
            xAxisLabel: t('costAndCreditBreakDown.month'),
            yAxisLabel: t(
              'costAndCreditBreakDown.grossCostOrCostAfterCreditOrCredit'
            ),
          },
          tableView: {
            columns: CostAndCreditBreakdownExportColumns,
            isTableView: isCostAndCreditBreakdownTableView,
            setTableView: setIsCostAndCreditBreakdownTableView,
          },
          pdfExport: {
            tableData: costCreditBreakdownData.map((item) => ({
              ...item,
              cost: currencySymbol + numberCommaSeparator(item.cost),
              credit: currencySymbol + numberCommaSeparator(item.credit),
              costAfterCredit:
                currencySymbol + numberCommaSeparator(item.costAfterCredit),
            })),
          },
          excelExport: {
            excelData: costCreditBreakdownData.map((item) => ({
              ...item,
              cost: currencySymbol + numberCommaSeparator(item.cost),
              credit: currencySymbol + numberCommaSeparator(item.credit),
              costAfterCredit:
                currencySymbol + numberCommaSeparator(item.costAfterCredit),
            })),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
        },
      ],
      [
        {
          element: getGraphComponent('credit-distribution-by-location', true),
          contentType: CHART_TYPES.MAP,
          graphName: 'credit-distribution-by-location',
          graphHeading: t('graphHeadings.creditDistributionbyLocation'),
          tableView: {
            columns: CreditDistributionByLocationExportColumns,
          },
          pdfExport: {
            tableData: creditDistributionByLocationData.map((spend) => ({
              ...spend,
              credit: currencySymbol + numberCommaSeparator(spend.credit),
            })),
            legends: creditDistributionByLocationData.map((item) => ({
              color: item.color,
              name: `${item.region ?? 'Others'} - ${
                currencySymbol + numberCommaSeparator(item.credit)
              }`,
            })),
          },
          excelExport: {
            excelData: creditDistributionByLocationData.map((spend) => ({
              ...spend,
              credit: currencySymbol + numberCommaSeparator(spend.credit),
            })),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
        },
      ],
      [
        {
          colSpanWidth: 14,
          contentType: CHART_TYPES.LINE_CHART,
          graphName: 'credit-discout-breakdown',
          graphHeading: t('graphHeadings.creditDiscountBreakdown'),
          element: getGraphComponent('credit-discout-breakdown', true),
          chartView: {
            xAxisLabel: t('creditDiscountBreakdown.month'),
            yAxisLabel: t('creditDiscountBreakdown.creditAmount'),
          },
          tableView: {
            columns: getCreditDiscountBreakdownExportColumns(
              monthlyCreditByCostTypeData
            ),
            isTableView: isCreditDiscountBreakdownTableView,
            setTableView: setIsCreditDiscountBreakdownTableView,
          },
          pdfExport: {
            tableData: creditDiscountBreakdownLabels.map((value, index) => {
              let thisMonthData: any = {
                month: value,
                slNo: index + 1,
              };
              monthlyCreditByCostTypeData.forEach(
                (costData) =>
                  (thisMonthData[costData.costMetricType] =
                    costData.creditData[index])
              );
              return thisMonthData;
            }),
          },
          excelExport: {
            excelData: creditDiscountBreakdownLabels.map((value, index) => {
              let thisMonthData: any = {
                month: value,
                slNo: index + 1,
              };
              monthlyCreditByCostTypeData.forEach(
                (costData) =>
                  (thisMonthData[costData.costMetricType] =
                    costData.creditData[index])
              );
              return thisMonthData;
            }),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
        },
        {
          colSpanWidth: 10,
          contentType: CHART_TYPES.DOUGHNUT_CHART,
          graphName: 'credit-distribution-by-project',
          graphHeading: t('graphHeadings.creditDistributionbyProject'),
          element: getGraphComponent('credit-distribution-by-project', true),
          display: !isDashboardWithStaticData(selectedDashboard),
          tableView: {
            columns: CreditDistributionByProjectExportColumns,
            isTableView: isCreditDistributionByProjectTableView,
            setTableView: setIsCreditDistributionByProjectTableView,
          },
          pdfExport: {
            tableData: creditDistributionByProjectData.map((item) => ({
              ...item,
              credit: currencySymbol + numberCommaSeparator(item.credit),
            })),
            legends: creditDistributionByProjectData.map((item) => ({
              color: item.color,
              name: item.project,
            })),
          },
          excelExport: {
            excelData: creditDistributionByProjectData.map((item) => ({
              ...item,
              credit: currencySymbol + numberCommaSeparator(item.credit),
            })),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
        },
      ],
    ],
    [
      isCostAndCreditBreakdownTableView,
      costCreditBreakdownData,
      creditDistributionByLocationData,
      monthlyCreditByCostTypeData,
      isCreditDiscountBreakdownTableView,
      creditDiscountBreakdownLabels,
      creditDistributionByProjectData,
      isCreditDistributionByProjectTableView,
    ]
  );

  return (
    <div className="gcp-credit-summary flex flex-column flex-gap-24">
      <DashboardGraphContainer
        getGraphComponent={getGraphComponent}
        rows={GraphRows}
        pdfMetaData={getPdfMetaData(
          t('dashNav.creditSummary'),
          selectedDashboard!,
          selectedConnection!
        )}
        costCardComponents={getCostComparisonCards()}
      />
    </div>
  );
};

export default GCPCreditSummaryDashboard;
