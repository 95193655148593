import i18n from 'i18n';
import { store } from 'redux/store';
import { ItemOptionsType } from 'components/DropdownCheckbox/types';
import ColorBlock from 'components/LegendColorBlock';
import { ChartCriteria } from 'constants/dashboard';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

// export const GcpCloudSpendColumns = [
//   {
//     title: '#',
//     dataIndex: 'index',
//     key: 'index',
//     render: (_text: any, _record: any, index: number) => index + 1,
//     width: 30,
//   },
//   {
//     title: i18n.t('projects'),
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: i18n.t('costInCurrency', { currencySymbol }),
//     dataIndex: 'cost',
//     key: 'cost',
//     width: 80,
//     render: (text: any) => currencySymbol + numberCommaSeparator(text),
//     align: 'right' as const,
//   },
// ];

// export const CostByProjectMixedColumns = [
//   {
//     title: '#',
//     dataIndex: 'index',
//     key: 'index',
//     render: (_text: any, _record: any, index: number) => index + 1,
//     width: 50,
//   },
//   {
//     title: i18n.t('costByProjectMixed.project'),
//     dataIndex: 'project',
//     key: 'project',
//   },
//   {
//     title: i18n.t('costByProjectMixed.cost', {
//       currencySymbol: currencySymbol,
//     }),
//     dataIndex: 'cost',
//     key: 'cost',
//     width: 180,
//     align: 'right' as const,
//     render: (text: any) => currencySymbol + numberCommaSeparator(text),
//   },
// ];

export const CHART_CRITERIA_LIST: ItemOptionsType[] = [
  {
    title: i18n.t('timePeriod'),
    value: ChartCriteria.TIME_PERIOD,
  },
  {
    title: i18n.t('compartment'),
    value: ChartCriteria.COMPARTMENT,
  },
  {
    title: i18n.t('service'),
    value: ChartCriteria.SERVICE,
  },
  {
    title: i18n.t('region'),
    value: ChartCriteria.REGION,
  },
  {
    title: i18n.t('spendProfile'),
    value: ChartCriteria.SPEND_PROFILE,
  },
];

export const CostByRegionColumns = [
  {
    title: '#',
    dataIndex: 'color',
    key: 'color',
    render: ColorBlock,
    width: 30,
  },
  {
    title: i18n.t('costByAwsRegion.region'),
    dataIndex: 'region',
    key: 'region',
  },
  {
    title: i18n.t('costByAwsRegion.cost'),
    dataIndex: 'cost',
    key: 'cost',
    align: 'right' as const,
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
  },
];
