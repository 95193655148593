import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import GraphHeader from 'components/GraphHeader';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { selectDashboard } from 'redux/dashboardSlice';
import { ColouredCostByRegionType } from 'types/dataTypes';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import PointerMap from 'components/PointerMap';
import { PROVIDER } from 'constants/cloudProviders';
import { getRegionCoordinates } from 'utils/dashboardUtils';
import { DATE_FORMAT } from 'utils/date';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { CostByRegionColumns } from '../../../../constants';

type CostByRegionProps = {
  requestStatus: string;
  costByRegionData: ColouredCostByRegionType[];
  pdfView: boolean;
  costByRegionStartDate: string;
  costByRegionEndDate: string;
  onChangeCostByRegionDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

const CostByRegion = ({
  requestStatus,
  costByRegionData,
  pdfView,
  costByRegionStartDate,
  costByRegionEndDate,
  onChangeCostByRegionDateRange,
}: CostByRegionProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(costByRegionStartDate),
          moment(costByRegionEndDate),
        ]}
        onChange={onChangeCostByRegionDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        format={DATE_FORMAT}
      />
    </div>
  );

  const getComponent = () => {
    return (
      <div className="graph flex flex-gap-16">
        <div className="width-30 full-height">
          <Table
            pagination={false}
            dataSource={costByRegionData.map((value, index) => ({
              ...value,
              key: index,
            }))}
            columns={CostByRegionColumns}
            scroll={{ y: 300 }}
            designVersion2
          />
        </div>
        <div
          className={`graph-area with-table width-70 ${
            pdfView && 'pdf-wrapper'
          }`}
        >
          <PointerMap
            data={costByRegionData.map((item) => ({
              ...item,
              coordinates: getRegionCoordinates(item.region, PROVIDER.AZURE),
            }))}
            valueLabel={t('costByAwsRegion.cost')}
            labelField="region"
            valueField="cost"
            colorField="color"
            prefixSymbol={currencySymbol}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="cost-by-region map-card graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.costByRegion')}
        graphName="cost-by-region"
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        designVersion2
        excelData={getChartExcelExportData(
          t('graphHeadings.costByRegion'),
          costByRegionData,
          CostByRegionColumns,
          {
            connectionName: selectedDashboard!.connectorName,
          }
        )}
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray([requestStatus])}
      />
    </div>
  );
};

export default CostByRegion;
