import moment from 'moment';
import DatePicker from 'components/DatePicker';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { ChartFilterType } from 'constants/dashboard';
import {
  DashboardDropdownFilterType,
  DashboardDateRangeFilterType,
  SingleSelectionDropdownFilterType,
} from 'types/dashboard';
import { DATE_FORMAT } from 'utils/date';
import SelectDropdown from 'components/Select';
import { REQUEST_STATUS } from 'constants/requestBody';

type FilterComponentProps = {
  filterType: string;
  filterProperties:
    | DashboardDropdownFilterType
    | SingleSelectionDropdownFilterType
    | DashboardDateRangeFilterType;
};

const FilterComponent = ({
  filterType,
  filterProperties,
}: FilterComponentProps) => {
  switch (filterType) {
    case ChartFilterType.DROPDOWN: {
      const { allData, selectedData, setSelectedData, valueSuffix, fieldName } =
        filterProperties as DashboardDropdownFilterType;

      return (
        <GraphFilterDropdown
          allData={allData}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          valueSuffix={valueSuffix}
          fieldName={fieldName}
          designVersion2
        />
      );
    }

    case ChartFilterType.SINGLE_SELECT_DROPDOWN: {
      const {
        allData,
        selectedValue,
        setSelectedValue,
        dropdownFilterRequestStatus,
      } = filterProperties as SingleSelectionDropdownFilterType;

      return (
        <SelectDropdown
          className="width-25 flex flex-center"
          value={selectedValue}
          onChange={setSelectedValue}
          loading={dropdownFilterRequestStatus === REQUEST_STATUS.PROCESSING}
          options={allData.map((item) => ({
            value: item.value,
            label: item.label ?? item.value,
          }))}
        />
      );
    }

    case ChartFilterType.DATE_RANGE: {
      const {
        filterStartDate,
        filterEndDate,
        onChangeDateRange,
        disabledDate,
        format = DATE_FORMAT,
        picker,
      } = filterProperties as DashboardDateRangeFilterType;

      return (
        <DatePicker
          defaultValue={[moment(filterStartDate), moment(filterEndDate)]}
          onChange={onChangeDateRange}
          disabledDate={disabledDate}
          picker={picker}
          format={format}
          designVersion2
        />
      );
    }

    default:
      return null;
  }
};

export default FilterComponent;
