import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { debounce } from 'lodash';

import {
  setDashboardType,
  setIsAddNewDashboard,
} from 'redux/customDashboardSlice';
import { selectDashboard, setDashboardList } from 'redux/dashboardSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import NavigationPath from 'components/NavigationPath';
import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import DeleteModal from 'components/DeleteModal';
import Button from 'components/Button';
import Input from 'components/Input';
import { fetchDashboardList, setDefaultDashboard } from 'utils/services';
import { dateFormat } from 'utils/date';
import Pagination from 'components/Pagination';
import CustomViewDataSourceDrawer from 'components/CustomViewDataSourceDrawer';
import { INPUT_SIZE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import {
  CustomDashboardActions,
  CUSTOM_DASHBOARD_QUICKACTIONS,
} from 'constants/quickAction';
import { onApiCallError } from 'utils/handleErrors';
import {
  addZeroMarginClass,
  getDashboardDatasourceName,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { DashboardListType } from 'types/navigationMenu';

import { DASHBOARD_STATUS, DASHBOARD_TYPE } from './constants';
import { deleteDashboard, setDashboardStatus } from './services';

import './index.scss';

const DashboardsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dashboardList } = useSelector(selectDashboard);
  const { permissions } = useSelector(userAuthorization);

  const [searchKey, setSearchKey] = useState('');
  const [totalDashboardsCount, setTotalDashboardsCount] = useState(0);
  const [currentCustomDashboard, setCurrentCustomDashboard] = useState<
    DashboardListType | undefined
  >(undefined);
  const [showDataSourceModal, setShowDataSourceModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [
    deleteCustomDashboardRequestStatus,
    setDeleteCustomDashboardRequestStatus,
  ] = useState('');
  const [
    fetchCustomDashboardDataRequestStatus,
    setFetchCustomDashboardDataRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterProviders, setFilterProviders] = useState<string[] | null>([]);

  useEffect(() => {
    addZeroMarginClass();
    fetchAndSetDashboardData();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  const setNewDashboardList = (res: any) => {
    dispatch(setDashboardList(res?.data?.responseData?.content));
    setTotalDashboardsCount(res?.data?.responseData?.totalElements);
    setFetchCustomDashboardDataRequestStatus(REQUEST_STATUS.SUCCESS);
  };

  /**
   * @function fetchSearchDashboardData
   * @description Function to fetch dashboards data and with search and filters
   * @param searchString string to be searched for.
   * @param providers selected providers for filter.
   * @param page page number defaults to first page
   */
  const fetchSearchDashboardData = (
    searchString: string,
    providers: string[] | null,
    page: number = 1
  ) => {
    setFetchCustomDashboardDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      key: searchString,
      page: page - 1,
      size: PAGINATION_SIZE,
      provider: providers?.join(','),
    };
    fetchDashboardList(params)
      .then(setNewDashboardList)
      .catch((e) => {
        onApiCallError(e, false, setFetchCustomDashboardDataRequestStatus);
      });
  };

  const fetchAndSetDashboardData = (page: number = 1) => {
    setFetchCustomDashboardDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      page: page - 1,
      size: PAGINATION_SIZE,
    };
    fetchDashboardList(params)
      .then(setNewDashboardList)
      .catch((e) => {
        onApiCallError(e, false, setFetchCustomDashboardDataRequestStatus);
        dispatch(setDashboardList([]));
        setTotalDashboardsCount(0);
      });
  };

  /**
   * @function deleteSelectedDashboard
   * @description Function to delete a dashboard
   */
  const deleteSelectedDashboard = () => {
    setDeleteCustomDashboardRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      id: currentCustomDashboard?.id,
    };
    deleteDashboard(params)
      .then(() => {
        message.success(
          `${currentCustomDashboard?.name}${t(
            'dashboardLabels.deleteDashboardSuccess'
          )}.`
        );
        fetchAndSetDashboardData();
        setDeleteCustomDashboardRequestStatus(REQUEST_STATUS.SUCCESS);
        setShowDeleteModal(false);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteCustomDashboardRequestStatus,
          t('dashboardLabels.deleteDashboardError')
        );
      });
  };

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    if (searchKey.length === 0) {
      fetchAndSetDashboardData(page);
    } else {
      fetchSearchDashboardData(searchKey, filterProviders, page);
    }
  };

  /**
   * @function setAsDefault
   * @description Function to make a dashboard default
   * @param dashboard Dashboard which is to be made default.
   */
  const setAsDefault = (dashboard: DashboardListType) => {
    setFetchCustomDashboardDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      dashboardId: dashboard.id,
    };
    setDefaultDashboard(params)
      .then(() => {
        message.success(
          `${t('dashboardLabels.defaultDashboardSuccess')}${dashboard.name}.`
        );
        fetchAndSetDashboardData();
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          undefined,
          t('dashboardLabels.defaultDashboardError')
        );
      });
  };

  /**
   * @function changeStatus
   * @description Function to change a dashboard's status ie -> PUBLISH, DE_ACTIVATED
   * @param dashboard Dashboard which is to be made default.
   * @param status Status for the dashboard.
   */
  const changeStatus = (dashboard: DashboardListType, status: string) => {
    setFetchCustomDashboardDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      status: status,
    };
    setDashboardStatus(dashboard.id, params)
      .then(() => {
        message.success(
          `${dashboard.name}${t(
            'dashboardLabels.changeDashboardStatusSuccess'
          )}${status}.`
        );
        fetchAndSetDashboardData();
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          undefined,
          `${t('dashboardLabels.changeDashboardStatusError')}${dashboard.name}`
        );
      });
  };

  /**
   * @function handleSearchDashboard
   * @description Function to handle custom dashboard search
   * @param e :accepts search input event
   */
  const handleSearchDashboard = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchKey(e.target.value);
    fetchSearchDashboardData(e.target.value, filterProviders);
  };

  const getStatusClassName = (text: string) => {
    switch (text) {
      case DASHBOARD_STATUS.PUBLISH:
        return 'publish';
      case DASHBOARD_STATUS.DE_ACTIVATED:
        return 'deactivated';
      case DASHBOARD_STATUS.DRAFT:
        return 'draft';
    }
  };

  const dashboardQuickActionMenus = (dashboard: any) =>
    CUSTOM_DASHBOARD_QUICKACTIONS.filter((value) => {
      switch (value.id) {
        case CustomDashboardActions.PUBLISH_DRAFT:
          return (
            permissions.dashboardModify &&
            dashboard.dashboardStatus === DASHBOARD_STATUS.DRAFT
          );
        case CustomDashboardActions.DEACTIVATE:
          return (
            permissions.dashboardModify &&
            dashboard.dashboardStatus !== DASHBOARD_STATUS.DE_ACTIVATED &&
            dashboard.dashboardStatus !== DASHBOARD_STATUS.DRAFT
          );
        case CustomDashboardActions.ACTIVATE:
          return (
            permissions.dashboardModify &&
            dashboard.dashboardStatus !== DASHBOARD_STATUS.PUBLISH &&
            dashboard.dashboardStatus !== DASHBOARD_STATUS.DRAFT
          );
        case CustomDashboardActions.SET_AS_DEFAULT:
          return (
            !dashboard.defaultDashboard &&
            dashboard.dashboardStatus === DASHBOARD_STATUS.PUBLISH &&
            dashboard.dashBoardType !== MY_DASHBOARD_TYPES.COST_ALLOCATION
          );
        case CustomDashboardActions.DELETE:
          return (
            permissions.dashboardModify &&
            dashboard.type !== DASHBOARD_TYPE.PRE_BUILT
          );
        default:
          return true;
      }
    });

  const handleQuickAction = (dashboard: any, action: string) => {
    switch (action) {
      case CustomDashboardActions.PUBLISH_DRAFT:
        changeStatus(dashboard, DASHBOARD_STATUS.PUBLISH);
        break;
      case CustomDashboardActions.SET_AS_DEFAULT:
        setAsDefault(dashboard);
        break;
      case CustomDashboardActions.ACTIVATE:
        changeStatus(dashboard, DASHBOARD_STATUS.PUBLISH);
        break;
      case CustomDashboardActions.DEACTIVATE:
        changeStatus(dashboard, DASHBOARD_STATUS.DE_ACTIVATED);
        break;
      case CustomDashboardActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + index + 1,
      width: '5%',
    },
    {
      title: () => t('customDashboardTable.dashboardName'),
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => (
        <div className="flex flex-align-items-center flex-gap-16 flex-wrap">
          {value}
          {record.defaultDashboard && (
            <span className="tag font-subHeader-small">{t('defaultTag')}</span>
          )}
        </div>
      ),
      width: '25%',
    },
    {
      title: () => t('customDashboardTable.connectionOrGroupName'),
      key: 'connectorOrGroupName',
      render: (_text: string, record: DashboardListType) => {
        return getDashboardDatasourceName(record);
      },
      width: '25%',
    },
    {
      title: t('customDashboardTable.lastUpdatedOn'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => {
        return text !== null ? dateFormat(text) : null;
      },
      width: '15%',
    },
    {
      title: t('customDashboardTable.tag'),
      dataIndex: 'type',
      key: 'type',
      width: '13%',
    },
    {
      title: () => t('customDashboardTable.dashboardStatus'),
      dataIndex: 'dashboardStatus',
      key: 'dashboardStatus',
      render: (text: string) => {
        return (
          <span className={`status ${getStatusClassName(text)}`}>{text}</span>
        );
      },
      width: '13%',
    },
    {
      title: t('customDashboardTable.quickAction'),
      dataIndex: 'quickAction',
      key: 'quickAction',
      render: (_text: string, record: DashboardListType) => {
        return (
          <QuickActionMenu
            setCurrentConnectionData={() => setCurrentCustomDashboard(record)}
            quickActions={dashboardQuickActionMenus(record)}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      width: '10%',
      align: 'center',
    },
  ];

  return (
    <div className="dashboards flex flex-fit flex-column">
      <header className="new-page-header">
        <div className="title-container flex flex-align-items-center flex-space-between">
          <span className="modal-heading">
            {t('navigationMenu.dashboards')}
          </span>
          {permissions.cloudConnectorWrite && (
            <Button
              title={t('customDashboard.addNewDashboard')}
              onClick={() => {
                dispatch(setIsAddNewDashboard(true));
                dispatch(
                  setDashboardType(MY_DASHBOARD_TYPES.SINGLE_CONNECTION)
                );
                setShowDataSourceModal(true);
              }}
            />
          )}
        </div>
      </header>
      <section className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="table-section">
          <Input
            placeholder={t('searchDashboardPlaceholder')}
            type="search"
            rootClassName="search-input"
            size={INPUT_SIZE.SMALL}
            onChange={debounce(handleSearchDashboard, DEBOUNCE_TIME_DELAY)}
          />
          <Table
            className="dashboards-table"
            pagination={false}
            dataSource={dashboardList?.map((dashboard: any, index) => ({
              ...dashboard,
              key: index,
            }))}
            columns={columns}
            designVersion2
            fillContainer
            scroll={{ x: '100%', y: '100%' }}
            loading={
              fetchCustomDashboardDataRequestStatus ===
              REQUEST_STATUS.PROCESSING
            }
            locale={{
              emptyText: fetchCustomDashboardDataRequestStatus !==
                REQUEST_STATUS.PROCESSING && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    fetchCustomDashboardDataRequestStatus ===
                    REQUEST_STATUS.ERROR
                      ? t('graphErrorMessage')
                      : t('dashboardLabels.noDashboardListMessage')
                  }
                />
              ),
            }}
            onChange={(_pagination: any, filters: any) => {
              fetchSearchDashboardData(searchKey, filters.provider, 1);
              setCurrentPage(1);
              setFilterProviders(filters.provider);
            }}
          />
        </div>
      </section>
      <footer className="page-footer flex">
        {totalDashboardsCount > 0 && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalDashboardsCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </footer>
      {showDataSourceModal && (
        <CustomViewDataSourceDrawer
          show={showDataSourceModal}
          setShow={setShowDataSourceModal}
        />
      )}
      {currentCustomDashboard !== undefined && showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={deleteSelectedDashboard}
          loading={
            deleteCustomDashboardRequestStatus === REQUEST_STATUS.PROCESSING
          }
        />
      )}
    </div>
  );
};

export default DashboardsPage;
