import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { Col, Row } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import ComparisonCard from 'components/NewComparisonCard';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import ExpandModal from 'components/ExpandModal';
import { ComparisonListType } from 'types/dashboard';
import { CHART_TYPES } from 'constants/graphConfig';
import { getChartData } from 'utils/services';
import {
  generateGraphColors,
  getPercentageDifference,
} from 'utils/dashboardUtils';
import {
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH,
  MONTH_YEAR_FORMAT,
} from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';

import { getGCPCarbonEmissionSummaryExcelData } from './utils/exportToExcel';
import CarbonEmissionByProject from './components/CarbonEmissionByProject';
import CarbonEmissionByService from './components/CarbonEmissionByService';
import CarbonEmissionByRegion from './components/CarbonEmissionByRegion';
import MonthlyCarbonEmission from './components/MonthlyCarbonEmission';
import { CARBON_EMISSION_UNIT } from '../../constants';
import {
  CarbonFootprintByMonth,
  CarbonFootprintByProject,
  CarbonFootprintByRegion,
  CarbonFootprintByService,
  ColorCarbonFootprintByRegion,
} from '../../types';
import {
  CarbonEmissionByProjectExportColumns,
  CarbonEmissionByRegionExportColumns,
  CarbonEmissionByServiceExportColumns,
  MonthlyCarbonEmissionExportColumns,
} from './constants';

const GCPCarbonFootprintDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedDashboard,
    expandedGraphName,
    tableViewEnabled,
    selectedConnection,
    pdfDownloadMode,
    showExpandGraphModal,
  } = useSelector(selectDashboard);

  const [carbonEmissionComparisonList, setCarbonEmissionComparisonList] =
    useState<ComparisonListType[]>([]);

  //States for comparison cards
  const [currentYearCarbon, setCurrentYearCarbon] = useState(0);
  const [currentYearCarbonRequestStatus, setCurrentYearCarbonRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousYearCarbon, setPreviousYearCarbon] = useState(0);
  const [previousYearCarbonRequestStatus, setPreviousYearCarbonRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [currentQuarterCarbon, setCurrentQuarterCarbon] = useState(0);
  const [
    currentQuarterCarbonRequestStatus,
    setCurrentQuarterCarbonRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [previousQuarterCarbon, setPreviousQuarterCarbon] = useState(0);
  const [
    previousQuarterCarbonRequestStatus,
    setPreviousQuarterCarbonRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [previousSecondMonthCarbon, setPreviousSecondMonthCarbon] = useState(0);
  const [
    previousSecondMonthCarbonRequestStatus,
    setPreviousSecondMonthCarbonRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [previousMonthCarbon, setPreviousMonthCarbon] = useState(0);
  const [
    previousMonthCarbonCarbonRequestStatus,
    setPreviousMonthCarbonCarbonRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  //State for Monthly Carbon Emission Data
  const [monthlyCarbonEmissionData, setMonthlyCarbonEmissionData] = useState<
    CarbonFootprintByMonth[]
  >([]);
  const [
    monthlyCarbonEmissionRequestStatus,
    setMonthlyCarbonEmissionRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [carbonEmissionStartMonth, setCarbonEmissionStartMonth] =
    useState<string>(
      moment()
        .subtract(12, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT)
    );
  const [carbonEmissionEndMonth, setCarbonEmissionEndMonth] = useState<string>(
    moment().subtract(1, 'months').endOf('month').format(HYPHEN_DATE_FORMAT)
  );

  //State for Carbon Emission by Project Data
  const [carbonEmissionByProjectData, setCarbonEmissionByProjectData] =
    useState<CarbonFootprintByProject[]>([]);
  const [
    selectedCarbonEmissionByProjectData,
    setSelectedCarbonEmissionByProjectData,
  ] = useState<CarbonFootprintByProject[]>([]);

  const [projectStartDate, setProjectStartDate] = useState<string>(
    moment().subtract(12, 'months').startOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [projectEndDate, setProjectEndDate] = useState<string>(
    moment().subtract(1, 'months').endOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [
    carbonEmissionByProjectRequestStatus,
    setCarbonEmissionByProjectRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  //State for Carbon Emission by Service Data
  const [carbonEmissionByServiceData, setCarbonEmissionByServiceData] =
    useState<CarbonFootprintByService[]>([]);
  const [
    selectedCarbonEmissionByServiceData,
    setSelectedCarbonEmissionByServiceData,
  ] = useState<CarbonFootprintByService[]>([]);
  const [serviceStartDate, setServiceStartDate] = useState<string>(
    moment().subtract(12, 'months').startOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [serviceEndDate, setServiceEndDate] = useState<string>(
    moment().subtract(1, 'months').endOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [
    carbonEmissionByServiceRequestStatus,
    setCarbonEmissionByServiceRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  //State for Carbon Emission by Region Data
  const [carbonEmissionByRegionData, setCarbonEmissionByRegionData] = useState<
    ColorCarbonFootprintByRegion[]
  >([]);
  const [
    selectedCarbonEmissionByRegionData,
    setSelectedCarbonEmissionByRegionData,
  ] = useState<ColorCarbonFootprintByRegion[]>([]);
  const [regionStartDate, setRegionStartDate] = useState<string>(
    moment().subtract(12, 'months').startOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [regionEndDate, setRegionEndDate] = useState<string>(
    moment().subtract(1, 'months').endOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [
    carbonEmissionByRegionRequestStatus,
    setCarbonEmissionByRegionRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  //State for tableview Switch
  const [monthlyCarbonEmissionTableView, setMonthlyCarbonEmissionTableView] =
    useState(false);
  const [carbonEmissionProjectTableView, setCarbonEmissionProjectTableView] =
    useState(false);
  const [carbonEmissionServicesTableView, setCarbonEmissionServicesTableView] =
    useState(false);

  useEffect(() => {
    setMonthlyCarbonEmissionTableView(tableViewEnabled);
    setCarbonEmissionProjectTableView(tableViewEnabled);
    setCarbonEmissionServicesTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    setCurrentYearCarbon(0);
    setPreviousYearCarbon(0);
    setCurrentQuarterCarbon(0);
    setPreviousQuarterCarbon(0);
    setPreviousSecondMonthCarbon(0);
    setPreviousMonthCarbon(0);
    if (selectedDashboard) {
      getCarbonEmissionComparisonCardsData();
    }
  }, [selectedDashboard]);

  useEffect(() => {
    setMonthlyCarbonEmissionData([]);
    if (selectedDashboard) {
      getMonthlyCarbonEmissionData();
    }
  }, [selectedDashboard, carbonEmissionStartMonth, carbonEmissionEndMonth]);

  useEffect(() => {
    setCarbonEmissionByProjectData([]);
    if (selectedDashboard) {
      getCarbonEmissionByProjectData();
    }
  }, [selectedDashboard, projectStartDate, projectEndDate]);

  useEffect(() => {
    if (selectedDashboard) {
      dispatch(
        setExportToExcelData(
          getGCPCarbonEmissionSummaryExcelData(
            selectedDashboard.connectorName,
            regionStartDate,
            regionEndDate,
            monthlyCarbonEmissionData,
            selectedCarbonEmissionByProjectData,
            selectedCarbonEmissionByServiceData,
            selectedCarbonEmissionByRegionData
          )
        )
      );
    }
  }, [
    selectedDashboard,
    monthlyCarbonEmissionData,
    selectedCarbonEmissionByProjectData,
    selectedCarbonEmissionByServiceData,
    selectedCarbonEmissionByRegionData,
    regionStartDate,
    regionEndDate,
  ]);

  useEffect(() => {
    setCarbonEmissionByServiceData([]);
    if (selectedDashboard) {
      getCarbonEmissionByServiceData();
    }
  }, [selectedDashboard, serviceStartDate, serviceEndDate]);

  useEffect(() => {
    setCarbonEmissionByRegionData([]);
    if (selectedDashboard) {
      getCarbonEmissionByRegionData();
    }
  }, [selectedDashboard, regionStartDate, regionEndDate]);

  useEffect(() => {
    setSelectedCarbonEmissionByProjectData(
      carbonEmissionByProjectData.slice(0, 10)
    );
  }, [carbonEmissionByProjectData]);

  useEffect(() => {
    setSelectedCarbonEmissionByServiceData(
      carbonEmissionByServiceData.slice(0, 10)
    );
  }, [carbonEmissionByServiceData]);

  useEffect(() => {
    setSelectedCarbonEmissionByRegionData(
      carbonEmissionByRegionData.slice(0, 10)
    );
  }, [carbonEmissionByRegionData]);

  useEffect(() => {
    setCarbonEmissionComparisonList([
      {
        value: currentYearCarbon,
        heading: t('carbonFootprint.comparisonCards.yearlyCarbonFootprint'),
        comparisonValue: getPercentageDifference(
          currentYearCarbon,
          previousYearCarbon
        ),
        comparisonFrom:
          t('carbonFootprint.comparisonCards.from') +
          ' ' +
          (moment().year() - 1),
        requestStatus: [
          currentYearCarbonRequestStatus,
          previousYearCarbonRequestStatus,
        ],
      },
      {
        value: currentQuarterCarbon,
        heading: t('carbonFootprint.comparisonCards.quarterlyCarbonFootprint'),
        comparisonValue: getPercentageDifference(
          currentQuarterCarbon,
          previousQuarterCarbon
        ),
        comparisonFrom: t('carbonFootprint.comparisonCards.fromLastQuarter'),
        requestStatus: [
          currentQuarterCarbonRequestStatus,
          previousQuarterCarbonRequestStatus,
        ],
      },
      {
        value: previousMonthCarbon,
        heading: t('carbonFootprint.comparisonCards.lastMonthCarbonFootprint'),
        comparisonValue: getPercentageDifference(
          previousMonthCarbon,
          previousSecondMonthCarbon
        ),
        comparisonFrom:
          t('carbonFootprint.comparisonCards.from') +
          ' ' +
          moment().subtract(2, MONTH).format(MONTH_YEAR_FORMAT),
        requestStatus: [
          previousMonthCarbonCarbonRequestStatus,
          moment().date() >= 15
            ? previousSecondMonthCarbonRequestStatus
            : REQUEST_STATUS.ERROR,
        ],
        errorMessage: t(
          'carbonFootprint.comparisonCards.lastMonthCarbonFootprintNotAvailable'
        ),
      },
      {
        value: previousSecondMonthCarbon,
        heading: t(
          'carbonFootprint.comparisonCards.twoMonthsBackCarbonFootprint'
        ),
        comparisonValue: getPercentageDifference(
          previousSecondMonthCarbon,
          previousMonthCarbon
        ),
        comparisonFrom:
          t('carbonFootprint.comparisonCards.from') +
          ' ' +
          moment().subtract(1, MONTH).format(MONTH_YEAR_FORMAT),
        requestStatus: [
          previousSecondMonthCarbonRequestStatus,
          previousMonthCarbonCarbonRequestStatus,
        ],
      },
    ]);
  }, [
    currentYearCarbon,
    previousYearCarbon,
    currentQuarterCarbon,
    previousQuarterCarbon,
    previousSecondMonthCarbon,
    previousMonthCarbon,
    currentYearCarbonRequestStatus,
    previousYearCarbonRequestStatus,
    currentQuarterCarbonRequestStatus,
    previousQuarterCarbonRequestStatus,
    previousSecondMonthCarbonRequestStatus,
    previousMonthCarbonCarbonRequestStatus,
  ]);

  const getCarbonEmissionByPeriodData = (
    fromDate: string,
    toDate: string,
    setData: (val: number) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'carbon_footprint',
          field: QUERY_FIELDS.CARBON_FOOTPRINT_KGCO2E,
        },
      ],
      aggregators: [
        {
          label: 'carbon_footprint',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: fromDate,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: toDate,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CARBON_FOOTPRINT,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        setData(res?.data[0]?.carbon_footprint?.toFixed(2));
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  const getCarbonEmissionComparisonCardsData = async () => {
    //Current Year Carbon Emission
    getCarbonEmissionByPeriodData(
      moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      moment().endOf('year').format(HYPHEN_DATE_FORMAT),
      setCurrentYearCarbon,
      setCurrentYearCarbonRequestStatus
    );

    // Previous year Carbon Emission
    getCarbonEmissionByPeriodData(
      moment().subtract(1, 'year').startOf('year').format(HYPHEN_DATE_FORMAT),
      moment().subtract(1, 'year').endOf('year').format(HYPHEN_DATE_FORMAT),
      setPreviousYearCarbon,
      setPreviousYearCarbonRequestStatus
    );

    // Current Quarter Carbon Emission
    getCarbonEmissionByPeriodData(
      moment().startOf('quarter').format(HYPHEN_DATE_FORMAT),
      moment().endOf('quarter').format(HYPHEN_DATE_FORMAT),
      setCurrentQuarterCarbon,
      setCurrentQuarterCarbonRequestStatus
    );

    // Previous Quarter Carbon Emission
    getCarbonEmissionByPeriodData(
      moment()
        .subtract(1, 'quarter')
        .startOf('quarter')
        .format(HYPHEN_DATE_FORMAT),
      moment()
        .subtract(1, 'quarter')
        .endOf('quarter')
        .format(HYPHEN_DATE_FORMAT),
      setPreviousQuarterCarbon,
      setPreviousQuarterCarbonRequestStatus
    );

    // Previous Month Carbon Emission
    getCarbonEmissionByPeriodData(
      moment().subtract(1, 'month').startOf('month').format(HYPHEN_DATE_FORMAT),
      moment().subtract(1, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
      setPreviousMonthCarbon,
      setPreviousMonthCarbonCarbonRequestStatus
    );

    // Previous 2nd Month Carbon Emission
    getCarbonEmissionByPeriodData(
      moment().subtract(2, 'month').startOf('month').format(HYPHEN_DATE_FORMAT),
      moment().subtract(2, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
      setPreviousSecondMonthCarbon,
      setPreviousSecondMonthCarbonRequestStatus
    );
  };

  const getMonthlyCarbonEmissionData = () => {
    setMonthlyCarbonEmissionRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'month',
          field: QUERY_FIELDS.USAGE_MONTH,
        },
        {
          label: 'carbonFootprint',
          field: QUERY_FIELDS.CARBON_FOOTPRINT_KGCO2E,
        },
      ],
      aggregators: [
        {
          label: 'carbonFootprint',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: carbonEmissionStartMonth,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: carbonEmissionEndMonth,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CARBON_FOOTPRINT,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        let carbonFootprintData: CarbonFootprintByMonth[] = [];
        const data: CarbonFootprintByMonth[] = res?.data || [];
        const labels: string[] = getMonthYearShortList(
          carbonEmissionStartMonth,
          carbonEmissionEndMonth,
          MONTH_YEAR_FORMAT
        );
        labels.forEach((label) => {
          carbonFootprintData.push({
            month: label,
            carbonFootprint:
              data.find(
                (item) => moment(item.month).format(MONTH_YEAR_FORMAT) === label
              )?.carbonFootprint ?? 0,
          });
        });
        setMonthlyCarbonEmissionData(carbonFootprintData);
        setMonthlyCarbonEmissionRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setMonthlyCarbonEmissionRequestStatus);
      });
  };

  const getCarbonEmissionByProjectData = () => {
    setCarbonEmissionByProjectRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'project',
          field: QUERY_FIELDS.PROJECT_ID,
        },
        {
          label: 'carbonFootprint',
          field: QUERY_FIELDS.CARBON_FOOTPRINT_KGCO2E,
        },
      ],
      aggregators: [
        {
          label: 'carbonFootprint',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['project'],
      orderBy: [
        {
          label: 'carbonFootprint',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: projectStartDate,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: projectEndDate,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CARBON_FOOTPRINT,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const carbonFootprintByProject: CarbonFootprintByProject[] =
          res?.data || [];
        setCarbonEmissionByProjectData(
          carbonFootprintByProject.map((item) => ({
            ...item,
            project: item.project ? item.project : 'null',
          }))
        );
        setCarbonEmissionByProjectRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCarbonEmissionByProjectRequestStatus);
      });
  };

  const getCarbonEmissionByServiceData = () => {
    setCarbonEmissionByServiceRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'service',
          field: QUERY_FIELDS.SERVICE_DESCRIPTION,
        },
        {
          label: 'carbonFootprint',
          field: QUERY_FIELDS.CARBON_FOOTPRINT_KGCO2E,
        },
      ],
      aggregators: [
        {
          label: 'carbonFootprint',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['service'],
      orderBy: [
        {
          label: 'carbonFootprint',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: serviceStartDate,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: serviceEndDate,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CARBON_FOOTPRINT,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        setCarbonEmissionByServiceData(res?.data);
        setCarbonEmissionByServiceRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCarbonEmissionByServiceRequestStatus);
      });
  };

  const getCarbonEmissionByRegionData = () => {
    setCarbonEmissionByRegionRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'region',
          field: QUERY_FIELDS.LOCATION_REGION,
        },
        {
          label: 'carbonFootprint',
          field: QUERY_FIELDS.CARBON_FOOTPRINT_KGCO2E,
        },
      ],
      aggregators: [
        {
          label: 'carbonFootprint',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['region'],
      orderBy: [
        {
          label: 'carbonFootprint',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: regionStartDate,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_MONTH,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: regionEndDate,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CARBON_FOOTPRINT,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const carbonFootprintByRegion: CarbonFootprintByRegion[] =
          res?.data || [];
        const colors = generateGraphColors(res?.data?.length || 0);
        setCarbonEmissionByRegionData(
          carbonFootprintByRegion.map((item, index) => ({
            ...item,
            region: item.region ? item.region : 'Others',
            color: colors[index],
          }))
        );
        setCarbonEmissionByRegionRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCarbonEmissionByRegionRequestStatus);
      });
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'monthly-carbon-emission':
        return (
          <MonthlyCarbonEmission
            carbonEmissionStartMonth={carbonEmissionStartMonth}
            carbonEmissionEndMonth={carbonEmissionEndMonth}
            onChangeMonthlyEmissionDateRange={onChangeMonthlyEmissionDateRange}
            monthlyCarbonEmissionRequestStatus={
              monthlyCarbonEmissionRequestStatus
            }
            monthlyCarbonEmissionTableView={monthlyCarbonEmissionTableView}
            setMonthlyCarbonEmissionTableView={
              setMonthlyCarbonEmissionTableView
            }
            monthlyCarbonEmissionData={monthlyCarbonEmissionData}
            pdfView={pdfView}
          />
        );
      case 'carbon-emission-by-projects':
        return (
          <CarbonEmissionByProject
            carbonEmissionByProjectData={carbonEmissionByProjectData}
            selectedCarbonEmissionByProjectData={
              selectedCarbonEmissionByProjectData
            }
            setSelectedCarbonEmissionByProjectData={
              setSelectedCarbonEmissionByProjectData
            }
            requestStatus={carbonEmissionByProjectRequestStatus}
            projectStartDate={projectStartDate}
            projectEndDate={projectEndDate}
            onChangeProjectDateRange={onChangeProjectDateRange}
            carbonEmissionProjectTableView={carbonEmissionProjectTableView}
            setCarbonEmissionProjectTableView={
              setCarbonEmissionProjectTableView
            }
            pdfView={pdfView}
          />
        );
      case 'carbon-emission-by-services':
        return (
          <CarbonEmissionByService
            carbonEmissionByServiceData={carbonEmissionByServiceData}
            selectedCarbonEmissionByServiceData={
              selectedCarbonEmissionByServiceData
            }
            setSelectedCarbonEmissionByServiceData={
              setSelectedCarbonEmissionByServiceData
            }
            requestStatus={carbonEmissionByServiceRequestStatus}
            serviceStartDate={serviceStartDate}
            serviceEndDate={serviceEndDate}
            onChangeServiceDateRange={onChangeServiceDateRange}
            carbonEmissionServicesTableView={carbonEmissionServicesTableView}
            setCarbonEmissionServicesTableView={
              setCarbonEmissionServicesTableView
            }
            pdfView={pdfView}
          />
        );
      case 'carbon-emission-by-regions':
        return (
          <CarbonEmissionByRegion
            carbonEmissionByRegionData={carbonEmissionByRegionData}
            selectedCarbonEmissionByRegionData={
              selectedCarbonEmissionByRegionData
            }
            setSelectedCarbonEmissionByRegionData={
              setSelectedCarbonEmissionByRegionData
            }
            requestStatus={carbonEmissionByRegionRequestStatus}
            regionStartDate={regionStartDate}
            regionEndDate={regionEndDate}
            onChangeRegionDateRange={onChangeRegionDateRange}
            pdfView={pdfView}
          />
        );
    }
  };

  const onChangeMonthlyEmissionDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCarbonEmissionStartMonth(
      moment(dateString[0], MONTH_YEAR_FORMAT).format(HYPHEN_DATE_FORMAT)
    );
    setCarbonEmissionEndMonth(
      moment(dateString[1], MONTH_YEAR_FORMAT).format(HYPHEN_DATE_FORMAT)
    );
  };

  const onChangeServiceDateRange = (
    dates: RangeValue<Moment>,
    _dateString: [string, string]
  ) => {
    if (!dates?.[0] || !dates?.[1]) {
      return;
    }

    setServiceStartDate(dates[0].format(HYPHEN_DATE_FORMAT));
    setServiceEndDate(dates[1].format(HYPHEN_DATE_FORMAT));
  };

  const onChangeProjectDateRange = (
    dates: RangeValue<Moment>,
    _dateString: [string, string]
  ) => {
    if (!dates?.[0] || !dates?.[1]) {
      return;
    }

    setProjectStartDate(dates[0].format(HYPHEN_DATE_FORMAT));
    setProjectEndDate(dates[1].format(HYPHEN_DATE_FORMAT));
  };

  const onChangeRegionDateRange = (
    dates: RangeValue<Moment>,
    _dateString: [string, string]
  ) => {
    if (!dates?.[0] || !dates?.[1]) {
      return;
    }

    setRegionStartDate(dates[0].format(HYPHEN_DATE_FORMAT));
    setRegionEndDate(dates[1].format(HYPHEN_DATE_FORMAT));
  };

  const getCarbonComparisonCards = () =>
    carbonEmissionComparisonList.map((item, index) => {
      return (
        <ComparisonCard
          key={item.heading}
          index={index}
          value={item.value}
          heading={item.heading + ' (' + CARBON_EMISSION_UNIT + ')'}
          comparisonValue={item.comparisonValue}
          comparisonFrom={item.comparisonFrom}
          requestStatus={item.requestStatus}
          errorMessage={item.errorMessage}
        />
      );
    });

  const getPdfContentData = () => {
    return {
      costCardsData: getCarbonComparisonCards(),
    };
  };

  const getPdfMetaData = () => {
    return {
      viewName: t('dashNav.carbonFootprint'),
      fileName: selectedDashboard?.name!,
      heading: selectedDashboard?.name!,
      subtitle1: selectedDashboard?.connectorName!,
      subtitle2: selectedConnection?.dataSourceType!,
      provider: selectedDashboard?.connectorProvider!,
    };
  };

  return (
    <div className="gcp-carbon-footprint flex flex-column flex-gap-24">
      <div className="cost-cards flex flex-space-between">
        {getCarbonComparisonCards()}
      </div>
      {getGraphComponent('monthly-carbon-emission')}
      <Row gutter={24}>
        <Col span={12}>{getGraphComponent('carbon-emission-by-projects')}</Col>
        <Col span={12}>{getGraphComponent('carbon-emission-by-services')}</Col>
      </Row>
      {getGraphComponent('carbon-emission-by-regions')}
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData()}
          pdfContent={[
            {
              element: getGraphComponent('monthly-carbon-emission', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'monthly-carbon-emission',
              isTableView: monthlyCarbonEmissionTableView,
              tableName: t('graphHeadings.grossMonthlyCarbonEmissions'),
              column: MonthlyCarbonEmissionExportColumns,
              body: insertIndex(monthlyCarbonEmissionData),
            },
            {
              element: getGraphComponent('carbon-emission-by-projects', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'carbon-emission-by-projects',
              isTableView: carbonEmissionProjectTableView,
              tableName: t('graphHeadings.grossCarbonEmissionsByProject'),
              column: CarbonEmissionByProjectExportColumns,
              body: insertIndex(selectedCarbonEmissionByProjectData),
            },
            {
              element: getGraphComponent('carbon-emission-by-services', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'carbon-emission-by-services',
              isTableView: carbonEmissionServicesTableView,
              tableName: t('graphHeadings.grossCarbonEmissionsByServices'),
              column: CarbonEmissionByServiceExportColumns,
              body: insertIndex(selectedCarbonEmissionByServiceData),
            },
            {
              element: getGraphComponent('carbon-emission-by-regions', true),
              contentType: CHART_TYPES.MAP,
              graphName: 'carbon-emission-by-regions',
              isTableView: false,
              tableName: t('graphHeadings.grossCarbonEmissionsByRegion'),
              column: CarbonEmissionByRegionExportColumns,
              body: insertIndex(selectedCarbonEmissionByRegionData),
              legends: selectedCarbonEmissionByRegionData.map((item) => ({
                color: item.color,
                name: `${item.region || 'Others'} - ${numberCommaSeparator(
                  item.carbonFootprint
                )} ${CARBON_EMISSION_UNIT}`,
              })),
            },
          ]}
          pdfCardData={getPdfContentData()}
        />
      )}
    </div>
  );
};

export default GCPCarbonFootprintDashboard;
