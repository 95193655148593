import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormLabel } from 'components/FormLabel';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import SelectDropdown from 'components/Select';
import { REQUEST_STATUS } from 'constants/requestBody';
import { validateEmptyField } from 'utils/validations';
import { fetchDatasetTables } from '../../utils';

type GCPCarbonFootprintDetailsProps = {
  gcpDatasetOptionsRequestStatus: string;
  gcpDatasetOptions: string[];
  gcpCarbonFootprintDatasetTableOptions: string[];
  setGcpCarbonFootprintDatasetTableOptions: (val: string[]) => void;
};

const GCPCarbonFootprintDetails = ({
  gcpDatasetOptionsRequestStatus,
  gcpDatasetOptions,
  gcpCarbonFootprintDatasetTableOptions,
  setGcpCarbonFootprintDatasetTableOptions,
}: GCPCarbonFootprintDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection, isTryAgain } =
    useSelector(cloudConnection);

  const [
    gcpCarbonFootprintDatasetRequiredValidation,
    setGcpCarbonFootprintDatasetRequiredValidation,
  ] = useState('');
  const [
    gcpCarbonFootprintDatasetTableRequiredValidation,
    setGcpCarbonFootprintDatasetTableRequiredValidation,
  ] = useState('');
  const [
    gcpCarbonFootprintDatasetTableRequestStatus,
    setGcpCarbonFootprintDatasetTableRequestStatus,
  ] = useState('');

  useEffect(() => {
    if (
      currentConnection?.carbonFootprintDataset &&
      (isEditConnection || isTryAgain)
    ) {
      fetchDatasetTables(
        currentConnection?.jsonFileId,
        currentConnection?.projectId,
        currentConnection?.carbonFootprintDataset,
        setGcpCarbonFootprintDatasetTableOptions,
        setGcpCarbonFootprintDatasetTableRequestStatus,
        isEditConnection,
        currentConnection?.connectorId
      );
    }
  }, []);

  const getDatasetDropdown = (options: string[]) => (
    <>
      {options?.map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </>
  );

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t(`connectionCSPForm.gcpCarbonFootprintDataset`)} />
        <SelectDropdown
          value={currentConnection?.carbonFootprintDataset}
          loading={gcpDatasetOptionsRequestStatus === REQUEST_STATUS.PROCESSING}
          menu={getDatasetDropdown(gcpDatasetOptions)}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                carbonFootprintDataset: value,
                carbonFootprintTableName: '',
              })
            );
            fetchDatasetTables(
              currentConnection?.jsonFileId,
              currentConnection?.projectId,
              value,
              setGcpCarbonFootprintDatasetTableOptions,
              setGcpCarbonFootprintDatasetTableRequestStatus,
              isEditConnection,
              currentConnection?.connectorId
            );
            setGcpCarbonFootprintDatasetRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.carbonFootprintDataset,
              t(`connectionCSPForm.gcpCarbonFootprintDataset`),
              setGcpCarbonFootprintDatasetRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${
              gcpCarbonFootprintDatasetRequiredValidation ? 'inline' : 'none'
            }`,
          }}
          className="font-validation-error"
        >
          {gcpCarbonFootprintDatasetRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`connectionCSPForm.gcpCarbonFootprintDatasetTable`)}
        />
        <SelectDropdown
          value={currentConnection?.carbonFootprintTableName}
          menu={getDatasetDropdown(gcpCarbonFootprintDatasetTableOptions)}
          loading={
            gcpCarbonFootprintDatasetTableRequestStatus ===
            REQUEST_STATUS.PROCESSING
          }
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                carbonFootprintTableName: value,
              })
            );
            setGcpCarbonFootprintDatasetTableRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.carbonFootprintTableName,
              t(`connectionCSPForm.gcpCarbonFootprintDatasetTable`),
              setGcpCarbonFootprintDatasetTableRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${
              gcpCarbonFootprintDatasetTableRequiredValidation
                ? 'inline'
                : 'none'
            }`,
          }}
          className="font-validation-error"
        >
          {gcpCarbonFootprintDatasetTableRequiredValidation}
        </span>
      </div>
    </div>
  );
};

export default GCPCarbonFootprintDetails;
