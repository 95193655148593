import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ConnectionList } from 'types/gProfiler';
import { RootState } from './store';

export interface GProfilerInsightsState {
  selectedService: ConnectionList | undefined;
  gProfilerServices: ConnectionList[];
  gProfilerServicesReqStatus: string;
}

const initialState: GProfilerInsightsState = {
  selectedService: undefined,
  gProfilerServices: [],
  gProfilerServicesReqStatus: REQUEST_STATUS.PROCESSING,
};

export const gProfilerInsightsSlice = createSlice({
  name: 'gProfilerInsights',
  initialState,
  reducers: {
    setSelectedService(
      state,
      action: PayloadAction<ConnectionList | undefined>
    ) {
      state.selectedService = action.payload;
    },
    setGProfilerServices(state, action: PayloadAction<ConnectionList[]>) {
      state.gProfilerServices = action.payload;
    },
    setGProfilerServicesReqStatus(state, action: PayloadAction<string>) {
      state.gProfilerServicesReqStatus = action.payload;
    },
  },
});
export const selectGProfilerInsights = (state: RootState) =>
  state.gProfilerInsights;
export const {
  setSelectedService,
  setGProfilerServices,
  setGProfilerServicesReqStatus,
} = gProfilerInsightsSlice.actions;

export default gProfilerInsightsSlice.reducer;
