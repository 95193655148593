import AccessibleDiv from 'components/AccessibleDiv';

import './index.scss';

type InsightBarProps = {
  selectedItem: string;
  setSelectedItem: (item: string) => void;
  dashboardItems: { key: string; label: string }[];
};

const InsightBar = ({
  selectedItem,
  setSelectedItem,
  dashboardItems,
}: InsightBarProps) => {
  return (
    <header className="insight-bar full-width flex flex-align-items-center flex-gap-16">
      {dashboardItems.map((item) => (
        <AccessibleDiv
          key={item.key}
          className={`insight-bar-item font-caption-bold cursor-pointer ${
            selectedItem === item.key ? 'selected' : ''
          }`}
          onClick={() => setSelectedItem(item.key)}
        >
          {item.label}
        </AccessibleDiv>
      ))}
    </header>
  );
};

export default InsightBar;
