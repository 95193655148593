import i18n from 'i18n';
import {
  CostByRegionType,
  CostByServiceFamilyType,
  MonthlyCostType,
  CostByProviderServiceType,
  ColouredCostByResourceType,
  ColouredCostByProviderServiceType,
} from 'types/dataTypes';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  ConsolidatedCloudServicesExportColumns,
  ConsolidatedRegionsExcelExportColumns,
  getConsolidatedYTDCostExportColumns,
  CostByServiceCategoryExportColumns,
} from '../constants';
import { getCostTrendsHeading } from './costTrendUtils';

/**
 * @function getGroupsCostTrendExcelData
 * @description Function to get the cost trend excel data for export
 * @param groupName String selected Group Name
 * @param costTrendData monthly cost data
 * @returns the json data containing export excel details
 */
export const getGroupsCostTrendExcelData = (
  connectorName: string,
  costTrendData:
    | MonthlyCostType[]
    | ColouredCostByProviderServiceType[]
    | ColouredCostByResourceType[],
  columns: any[],
  filterData: {
    startDate: string;
    endDate: string;
    selectedTrendMonth?: string;
    selectedTrendService?: string;
  }
) => {
  return {
    sheetName: getCostTrendsHeading(
      filterData.selectedTrendMonth,
      filterData.selectedTrendService
    ),
    columns: columns,
    data: insertIndex(costTrendData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      ...getCostTrendExportFilters(filterData),
    ],
  };
};

/**
 * @function getCostTrendExportFilters
 * @description Function to return the filters for export data
 * @param filterData Object containing all the filters
 * @returns List of filters.
 */
const getCostTrendExportFilters = (filterData: {
  startDate: string;
  endDate: string;
  selectedTrendMonth?: string;
  selectedTrendService?: string;
}) => {
  if (!filterData.selectedTrendMonth) {
    return [
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: filterData.startDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: filterData.endDate,
      },
    ];
  }

  if (!filterData.selectedTrendService) {
    return [
      {
        heading: i18n.t('excelExportLabels.month'),
        value: filterData.selectedTrendMonth,
      },
    ];
  }

  if (filterData.selectedTrendService) {
    return [
      {
        heading: i18n.t('excelExportLabels.month'),
        value: filterData.selectedTrendMonth,
      },
      {
        heading: i18n.t('excelExportLabels.service'),
        value: filterData.selectedTrendService,
      },
    ];
  }

  return [];
};

/**
 * @function getMonthlyCostByCloudProviderExcelData
 * @description Function to get the monthly cost by provider excel data for export
 * @param groupName String selected Group Name
 * @param monthlyCostByCloudProvider monthly cost by provider data
 * @param connections List of connection names
 * @returns the json data containing export excel details
 */
export const getMonthlyCostByCloudProviderExcelData = (
  groupName: string,
  monthlyCostByCloudProvider: any[],
  providers: string[],
  connections: string[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.monthlyCostbyCloudProvider'),
    columns: getConsolidatedYTDCostExportColumns(providers),
    data: insertIndex(monthlyCostByCloudProvider).map((item) => ({
      ...item,
      cost: numberCommaSeparator(item.cost),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.groupName'),
        value: groupName,
      },
      {
        heading: i18n.t('excelExportLabels.connections'),
        value: connections.join(', '),
      },
    ],
  };
};

/**
 * @function getCloudServicesExcelData
 * @description Function to get the Cloud Services excel data for export
 * @param groupName String selected Group Name
 * @param cloudServiceData cloud service cost data
 * @param connections List of connection names
 * @returns the json data containing export excel details
 */
export const getCloudServicesExcelData = (
  groupName: string,
  cloudServiceData: CostByServiceFamilyType[],
  connections: string[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.cloudServices'),
    columns: ConsolidatedCloudServicesExportColumns,
    data: insertIndex(cloudServiceData).map((item) => ({
      ...item,
      cost: numberCommaSeparator(item.cost),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.groupName'),
        value: groupName,
      },
      {
        heading: i18n.t('excelExportLabels.connections'),
        value: connections.join(', '),
      },
    ],
  };
};

/**
 * @function getCostByComputeServiceExcelData
 * @description Function to get the cost by compute services excel data for export
 * @param groupName String selected Group Name
 * @param costByServiceCategory monthly YTD cost data
 * @param connections List of connection names
 * @returns the json data containing export excel details
 */
export const getCostByServiceCategoryExcelData = (
  graphName: string,
  groupName: string,
  costByServiceCategory: CostByProviderServiceType[],
  connections: string[]
) => {
  return {
    sheetName: graphName,
    columns: CostByServiceCategoryExportColumns,
    data: insertIndex(costByServiceCategory),
    filters: [
      {
        heading: i18n.t('excelExportLabels.groupName'),
        value: groupName,
      },
      {
        heading: i18n.t('excelExportLabels.connections'),
        value: connections.join(', '),
      },
    ],
  };
};

/**
 * @function getRegionsExcelData
 * @description Function to get the Consolidated Regions excel data for export
 * @param groupName String selected Group Name
 * @param costByRegionData cost by region data
 * @param connections List of connection names
 * @returns the json data containing export excel details
 */
export const getRegionsExcelData = (
  groupName: string,
  costByRegionData: CostByRegionType[],
  connections: string[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.costByRegion'),
    columns: ConsolidatedRegionsExcelExportColumns,
    data: insertIndex(costByRegionData).map((item) => ({
      ...item,
      cost: numberCommaSeparator(item.cost),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.groupName'),
        value: groupName,
      },
      {
        heading: i18n.t('excelExportLabels.connections'),
        value: connections.join(', '),
      },
    ],
  };
};

/**
 * @function getConsolidatedCostSummaryExcelData
 * @description Function to get the consolidated cost summary excel data for export
 * @param groupName String selected Group Name
 * @param costTrendData monthly cost data
 * @param costByCloudProvider Object containing the monthly cost by provider and connections filtered
 * @param costByServiceData Object containing the cost by service and connections filtered
 * @param costByRegionData Object containing the cost by region and connections filtered
 * @returns the json data containing export excel details
 */
export const getConsolidatedCostSummaryExcelData = (
  groupName: string,
  costTrendData: {
    data:
      | MonthlyCostType[]
      | ColouredCostByProviderServiceType[]
      | ColouredCostByResourceType[];
    columns: any[];
    filterData: {
      startDate: string;
      endDate: string;
      selectedTrendMonth?: string;
      selectedTrendService?: string;
    };
  },
  costByCloudProvider: {
    data: any[];
    providers: string[];
    connections: string[];
  },
  costByServiceData: {
    data: CostByServiceFamilyType[];
    connections: string[];
  },
  costByServiceCategory: {
    computeData: CostByProviderServiceType[];
    storageData: CostByProviderServiceType[];
    connections: string[];
  },
  costByRegionData: {
    data: CostByRegionType[];
    connections: string[];
  }
) => {
  return [
    getGroupsCostTrendExcelData(
      groupName,
      costTrendData.data,
      costTrendData.columns,
      costTrendData.filterData
    ),
    getMonthlyCostByCloudProviderExcelData(
      groupName,
      costByCloudProvider.data,
      costByCloudProvider.providers,
      costByCloudProvider.connections
    ),
    getCloudServicesExcelData(
      groupName,
      costByServiceData.data,
      costByServiceData.connections
    ),
    getCostByServiceCategoryExcelData(
      i18n.t('graphHeadings.costByComputeServices'),
      groupName,
      costByServiceCategory.computeData,
      costByServiceCategory.connections
    ),
    getCostByServiceCategoryExcelData(
      i18n.t('graphHeadings.costByStorageServices'),
      groupName,
      costByServiceCategory.storageData,
      costByServiceCategory.connections
    ),
    getRegionsExcelData(
      groupName,
      costByRegionData.data,
      costByRegionData.connections
    ),
  ];
};
