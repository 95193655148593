import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultCostAllocationData } from 'constants/defaultValues';
import { VALIDATION_STATUS } from 'pages/ConnectingCSPPage/constants';
import { CostAllocationsDataType } from 'pages/CostAllocationPage/components/CostAllocationForm/types';
import { CostAllocationComponents } from 'pages/CostAllocationPage/constants';
import { ConnectionListType } from 'types/dashboard';
import { RootState } from './store';

export interface CostAllocationState {
  costAllocationComponent: string;
  connectionList: ConnectionListType[];
  costAllocationData: CostAllocationsDataType;
  totalSharedCost: number;
  allProjectsList: string[];
  selectedProjects: string[];
  currentStep: number;
  currentStepValidation: string;
}

const initialState: CostAllocationState = {
  costAllocationComponent: CostAllocationComponents.ALLOCATION_FORM,
  connectionList: [],
  costAllocationData: defaultCostAllocationData,
  totalSharedCost: 0,
  allProjectsList: [],
  selectedProjects: [],
  currentStep: 0,
  currentStepValidation: VALIDATION_STATUS.INVALID,
};

export const costAllocationSlice = createSlice({
  name: 'costAllocationSlice',
  initialState,
  reducers: {
    setCostAllocationComponent: (state, action: PayloadAction<string>) => {
      state.costAllocationComponent = action.payload;
    },
    setConnectionList: (state, action: PayloadAction<ConnectionListType[]>) => {
      state.connectionList = action.payload;
    },
    setCostAllocationData: (
      state,
      action: PayloadAction<CostAllocationsDataType>
    ) => {
      state.costAllocationData = action.payload;
    },
    setTotalSharedCost: (state, action: PayloadAction<number>) => {
      state.totalSharedCost = action.payload;
    },
    setAllProjectsList: (state, action: PayloadAction<string[]>) => {
      state.allProjectsList = action.payload;
    },
    setSelectedProjects: (state, action: PayloadAction<string[]>) => {
      state.selectedProjects = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setCurrentStepValidation: (state, action: PayloadAction<string>) => {
      state.currentStepValidation = action.payload;
    },
  },
});

export const selectCostAllocation = (state: RootState) => state.costAllocation;
export const {
  setCostAllocationComponent,
  setConnectionList,
  setCostAllocationData,
  setTotalSharedCost,
  setAllProjectsList,
  setSelectedProjects,
  setCurrentStep,
  setCurrentStepValidation,
} = costAllocationSlice.actions;
export default costAllocationSlice.reducer;
