import { useSelector } from 'react-redux';

import { customDashboard } from 'redux/customDashboardSlice';
import { selectDashboard } from 'redux/dashboardSlice';
import ExpandModal from 'components/ExpandModal';
import { CUSTOM_DASHBOARD_MODE } from 'constants/dashboard';
import { LayoutDesignsType } from 'types/dashboard';

import CustomTableOrChartData from '../CustomTableOrChartData';
import { isCustomChartSelected } from '../CustomTableOrChartData/utils';

type CustomRowLayoutProps = {
  layout: LayoutDesignsType;
  setSliderValue?: Function;
};

const CustomRowLayout = ({ layout, setSliderValue }: CustomRowLayoutProps) => {
  const { customDashBoardMode } = useSelector(customDashboard);
  const { showExpandGraphModal } = useSelector(selectDashboard);

  return (
    <div
      className={`${
        customDashBoardMode === CUSTOM_DASHBOARD_MODE.DEV && 'margin-24'
      } flex flex-align-items-center flex-gap-24`}
    >
      {layout?.charts?.map((chart) => (
        <div
          key={`${layout.position}${chart.chartPosition}`}
          className={`width-${chart.percentage}`}
        >
          <CustomTableOrChartData
            chart={chart}
            layout={layout}
            setSliderValue={setSliderValue}
          />
          {showExpandGraphModal &&
            isCustomChartSelected(layout.position, chart.chartPosition) && (
              <ExpandModal
                graphContent={
                  <CustomTableOrChartData
                    chart={chart}
                    layout={layout}
                    isExpanded={true}
                  />
                }
              />
            )}
        </div>
      ))}
    </div>
  );
};

export default CustomRowLayout;
