import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';

type MemoryReductionValueProps = {
  recommendation: RecommendationList;
};

const MemoryReductionValue = ({
  recommendation,
}: MemoryReductionValueProps) => {
  if (!recommendation.containers || !recommendation.containers.length) {
    return <div>N/A</div>;
  }

  return (
    <div className="flex flex-column flex-gap-8">
      <div className="flex flex-column flex-gap-8">
        {recommendation.containers.map((container) => (
          <span key={container.name}>
            {container.avg_impact_on_cluster_mem}%
          </span>
        ))}
      </div>
    </div>
  );
};

export default MemoryReductionValue;
