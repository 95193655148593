import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { CLUSTER_UTILIZED_OPTIONS } from 'pages/ContainerInsightsPage/components/GCPUsageMetering/constants';
import GranulateUsageMeteringLineChart from '../GranulateUsageMeteringLineChart';

type ClusterUtilizaedAndReservedType = {
  data: any[];
  requestStatus: string;
  selectedValue: string;
  setSelectedValue: (val: string) => void;
  pdfView: boolean;
  isClusterUtilizedTableView: boolean;
  setIsClusterUtilizedTableView: (value: boolean) => void;
  tableData: any[];
  excelFilters: any[];
};

const ClusterUtilizaedAndReserved = ({
  data,
  requestStatus,
  selectedValue,
  setSelectedValue,
  pdfView,
  isClusterUtilizedTableView,
  setIsClusterUtilizedTableView,
  tableData,
  excelFilters,
}: ClusterUtilizaedAndReservedType) => {
  const { t } = useTranslation();

  const HeadingComponent = (
    <div className="flex flex-gap-12 flex-align-items-center">
      <div>{t('consolidatedDashboard.clusterUtilized')}</div>
      <div>
        <Radio.Group
          options={CLUSTER_UTILIZED_OPTIONS}
          onChange={(e) => setSelectedValue(e.target.value)}
          value={selectedValue}
          optionType="button"
          rootClassName="no-custom-style savings-types"
          style={{ height: 28 }}
        />
      </div>
    </div>
  );

  return (
    <GranulateUsageMeteringLineChart
      data={data}
      heading={t('consolidatedDashboard.clusterUtilized')}
      graphName="utilized-and-reserved"
      xTitle={t('consolidatedDashboard.month')}
      yTitle={t('consolidatedDashboard.utilization')}
      headingComponent={HeadingComponent}
      groupingField="category"
      requestStatus={requestStatus}
      xField="time"
      yField="utilization"
      postfixSymbol="%"
      pdfView={pdfView}
      setIsTableView={setIsClusterUtilizedTableView}
      isTableView={isClusterUtilizedTableView}
      tableData={tableData}
      excelFilters={excelFilters}
    />
  );
};

export default ClusterUtilizaedAndReserved;
