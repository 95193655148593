import { REQUEST_STATUS } from 'constants/requestBody';
import { ORDERED_SERVICE_NOW_STATUS_LIST } from 'constants/workflowIntegrations';
import { getAllSnowIncidentDetails } from 'pages/CostOptimizationInsightsPage/services';
import { IncidentRecommendationMappingType } from 'pages/CostOptimizationInsightsPage/types';
import {
  ConnectionListType,
  ProjectNumberIdMappingType,
} from 'types/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import { getGCPProjectNumberIdMapping } from 'utils/services';

/**
 * @function getAllSnowIncidents
 * @description Function to fetch the service now status for all recommendations and ticket under a connection.
 */
export const getAllSnowIncidents = (
  connection: ConnectionListType,
  setData: (val: IncidentRecommendationMappingType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  getAllSnowIncidentDetails(connection.connectorId)
    .then((res: any) => {
      if (res.status === 200) {
        const data = res.data.responseData;
        const tickets = data.incidentRecommendationMappings.map(
          (incident: IncidentRecommendationMappingType) => ({
            ...incident,
            incidentStatus: ORDERED_SERVICE_NOW_STATUS_LIST.at(
              Number(incident.incidentStatus)
            ),
          })
        );
        setData(tickets);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      setData([]);
      setRequestStatus(REQUEST_STATUS.ERROR);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
      setData([]);
    });
};

/**
 * @function getAllProjectNumberIdMapping
 * @description Function to fetch project Number to Id mapping.
 * @param connection Connection for which the data is fetched
 * @param setData Callback to set the fetched data
 */
export const getAllProjectNumberIdMapping = (
  connection: ConnectionListType,
  setData: (val: ProjectNumberIdMappingType[]) => void
) => {
  getGCPProjectNumberIdMapping(connection.connectorId)
    .then((res: any) => {
      if (res.status === 200) {
        setData(res.data.responseData);
        return;
      }
      setData([]);
    })
    .catch((e) => {
      onApiCallError(e, false);
      setData([]);
    });
};
