import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import Loader from 'components/Loader';
import { REQUEST_STATUS } from 'constants/requestBody';

type DashboardComponentProps = {
  component?: JSX.Element;
  requestStatus: string;
  errorMessage?: string;
};

const DashboardComponent = ({
  component,
  requestStatus,
  errorMessage,
}: DashboardComponentProps) => {
  const { t } = useTranslation();

  const getComponent = () => {
    switch (requestStatus) {
      case REQUEST_STATUS.PROCESSING:
        return <Loader />;
      case REQUEST_STATUS.ERROR:
        return (
          <Empty
            className="flex flex-column flex-justify-content-center"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={errorMessage ?? t('graphErrorMessage')}
          />
        );
      case REQUEST_STATUS.SUCCESS:
        return component;
    }
  };

  return <>{getComponent()}</>;
};

export default DashboardComponent;
