import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, message, Radio, Row } from 'antd';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';

import { dataCenter } from 'redux/dataCenterSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { StorageEntryListType } from 'pages/DataCenterPage/types';
import {
  saveStorageResource,
  updateStorageResource,
} from 'pages/DataCenterPage/services';
import {
  MODE_OF_PURCHASES,
  PurchaseModes,
} from 'pages/DataCenterPage/constants';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import Icon from 'components/Icon';
import DrawerComponent from 'components/DrawerComponent';
import DatePicker from 'components/DatePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import { BUTTON_TYPE } from 'constants/appearance';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MONTH_YEAR_FORMAT, TIMESTAMP_FORMAT_WITHOUT_ZONE } from 'utils/date';
import {
  getValidationStyle,
  isNumber,
  validateEmptyField,
} from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';

type StorageRowModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  onSaveOrUpdateEntry: () => void;
  storageEntry?: StorageEntryListType;
};

const StorageRowModal = ({
  show,
  setShow,
  onSaveOrUpdateEntry,
  storageEntry,
}: StorageRowModalProps) => {
  const { t } = useTranslation();
  const { selectedDataCenter } = useSelector(dataCenter);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [name, setName] = useState('');
  const [nameValidation, setNameValidation] = useState('');
  const [totalStorage, setTotalStorage] = useState('');
  const [totalStorageValidation, setTotalStorageValidation] = useState('');
  const [modeOfPurchase, setModeOfPurchase] = useState(
    PurchaseModes.RENTAL_OR_LEASE
  );
  const [cost, setCost] = useState('');
  const [costValidation, setCostValidation] = useState('');
  const [purchaseDate, setPurchaseDate] = useState<Moment | null>(null);
  const [purchaseDateValidation, setPurchaseDateValidation] = useState('');
  const [duration, setDuration] = useState('');
  const [durationValidation, setDurationValidation] = useState('');
  const [saveStorageEntryRequestStatus, setSaveStorageEntryRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (storageEntry !== undefined) {
      setName(storageEntry.name);
      setTotalStorage(storageEntry.totalStorageInGB.toString());
      setModeOfPurchase(
        MODE_OF_PURCHASES.find(
          (item) => item.key === storageEntry.modeOfPurchase
        )?.key ?? PurchaseModes.RENTAL_OR_LEASE
      );
      setPurchaseDate(moment(storageEntry.dateOfPurchase));
      setDuration(storageEntry.durationInMonths?.toString() ?? '');
      setCost(
        (storageEntry.modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
          ? storageEntry.monthlyCost
          : storageEntry.totalCost
        )?.toString() ?? ''
      );
    }
  }, []);

  /**
   * @function onClickAdd
   * @description Function to add storage row for the entered values
   */
  const onClickAdd = () => {
    if (!validateAllFields()) {
      return;
    }

    setSaveStorageEntryRequestStatus(REQUEST_STATUS.PROCESSING);
    let requestBody: any = {
      dataCenterCode: selectedDataCenter?.dataCenterCode,
      name: name,
      totalStorageInGB: totalStorage,
      modeOfPurchase: modeOfPurchase,
      dateOfPurchase: moment(purchaseDate)
        .startOf('month')
        .startOf('day')
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      monthlyCost:
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE ? cost : undefined,
      totalCost:
        modeOfPurchase === PurchaseModes.PERMANENT_PURCHASE ? cost : undefined,
      durationInMonths:
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE ? duration : undefined,
    };

    let successMessage = t('manualEntry.storageLabels.saveStorageSuccess');
    let failureMessage = t('manualEntry.storageLabels.saveStorageFailed');

    if (storageEntry !== undefined) {
      requestBody.id = storageEntry.id;

      successMessage = t('manualEntry.storageLabels.updateStorageSuccess');
      failureMessage = t('manualEntry.storageLabels.updateStorageFailed');
    }

    (storageEntry === undefined
      ? saveStorageResource(requestBody)
      : updateStorageResource(requestBody)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setSaveStorageEntryRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success(successMessage);
          onSaveOrUpdateEntry();
          setShow(false);
          return;
        }

        setSaveStorageEntryRequestStatus(REQUEST_STATUS.ERROR);
        setErrorMessage(res?.message ?? failureMessage);
      })
      .catch((e) => {
        onApiCallError(e, false, setSaveStorageEntryRequestStatus);
        setErrorMessage(e?.response?.data?.message ?? failureMessage);
      });
  };

  /**
   * @function validateName
   * @description Function to validate the Name.
   * @param value string input value
   */
  const validateName = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.storageLabels.name'),
      setNameValidation
    );
  };

  /**
   * @function validateTotalStorage
   * @description Function to validate the Total Storage.
   * @param value string input value
   */
  const validateTotalStorage = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.storageLabels.totalStorage'),
        setTotalStorageValidation
      )
    ) {
      return isNumber(
        value,
        false,
        t('manualEntry.storageLabels.totalStorage'),
        setTotalStorageValidation
      );
    }

    return false;
  };

  /**
   * @function validateCost
   * @description Function to validate the Cost.
   * @param value string input value
   */
  const validateCost = (value: string) => {
    if (
      !validateEmptyField(
        value,
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
          ? t('manualEntry.storageLabels.costMonthly')
          : t('manualEntry.storageLabels.cost'),
        setCostValidation
      )
    ) {
      return isNumber(
        value,
        true,
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
          ? t('manualEntry.storageLabels.costMonthly')
          : t('manualEntry.storageLabels.cost'),
        setCostValidation
      );
    }

    return false;
  };

  /**
   * @function validatePurchaseDate
   * @description Function to validate the Purchase Date.
   * @param value string input value
   */
  const validatePurchaseDate = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.storageLabels.dateMonthOfPurchase'),
      setPurchaseDateValidation
    );
  };

  /**
   * @function validateDuration
   * @description Function to validate the Duration.
   * @param value string input value
   */
  const validateDuration = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.storageLabels.duration'),
        setDurationValidation
      )
    ) {
      return isNumber(
        value,
        false,
        t('manualEntry.storageLabels.duration'),
        setDurationValidation
      );
    }

    return false;
  };

  /**
   * @function validateAllFields
   * @description Function to validate the storage fields
   * @returns boolean true validated else false
   */
  const validateAllFields = () => {
    validateName(name);
    validateTotalStorage(totalStorage);
    validateCost(cost);
    validatePurchaseDate(purchaseDate?.toString() ?? '');
    validateDuration(duration);

    return (
      validateName(name) &&
      validateTotalStorage(totalStorage) &&
      validateCost(cost) &&
      validatePurchaseDate(purchaseDate?.toString() ?? '') &&
      (modeOfPurchase === PurchaseModes.PERMANENT_PURCHASE ||
        validateDuration(duration))
    );
  };

  return (
    <DrawerComponent
      className="row-modal"
      width={600}
      open={show}
      onClose={() => setShow(false)}
      title={
        (storageEntry ? t('manualEntry.editRow') : t('manualEntry.addNewRow')) +
        ' - ' +
        t('manualEntry.nav.storage')
      }
      footer={
        <div className="flex flex-column flex-gap-8">
          <span
            style={{
              display: getValidationStyle(errorMessage),
            }}
            className="font-validation-error"
          >
            {errorMessage}
          </span>
          <div className="flex flex-align-items-center flex-end">
            <Button
              title={t('manualEntry.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShow(false)}
            />
            <Button
              title={
                storageEntry === undefined
                  ? t('manualEntry.add')
                  : t('manualEntry.update')
              }
              onClick={onClickAdd}
              loading={
                saveStorageEntryRequestStatus === REQUEST_STATUS.PROCESSING
              }
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-column flex-gap-16">
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel title={t('manualEntry.storageLabels.name')} required />
            <Input
              value={name}
              placeholder={t('manualEntry.storageLabels.namePlaceholder')}
              onChange={(e: any) => {
                setName(e.target.value);
                validateName(e.target.value);
              }}
              onBlur={() => validateName(name)}
            />
            <span
              style={{
                display: `${getValidationStyle(nameValidation)}`,
              }}
              className="font-validation-error"
            >
              {nameValidation}
            </span>
          </Col>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.storageLabels.totalStorage')}
              required
            />
            <Input
              type="input"
              value={totalStorage}
              placeholder={t(
                'manualEntry.storageLabels.totalStoragePlaceholder'
              )}
              onChange={(e: any) => {
                setTotalStorage(e.target.value);
                validateTotalStorage(e.target.value);
              }}
              onBlur={() => validateTotalStorage(totalStorage)}
            />
            <span
              style={{
                display: `${getValidationStyle(totalStorageValidation)}`,
              }}
              className="font-validation-error"
            >
              {totalStorageValidation}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormLabel
              title={t('manualEntry.storageLabels.modeOfPurchase')}
              required
            />
          </Col>
          <Col span={24}>
            <Radio.Group
              onChange={(event) => setModeOfPurchase(event.target.value)}
              value={modeOfPurchase}
            >
              {MODE_OF_PURCHASES.map((mode) => (
                <Radio value={mode.key} key={mode.key}>
                  {mode.label}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <Divider className="horizontal-divider" />
        <Row>
          <Col span={24}>
            <FormLabel
              title={
                modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
                  ? t('manualEntry.storageLabels.costMonthly')
                  : t('manualEntry.storageLabels.cost')
              }
              required
            />
          </Col>
          <Col span={24}>
            <Input
              type="input"
              prefix={currencySymbol}
              value={cost}
              placeholder={t('manualEntry.storageLabels.costPlaceholder')}
              onChange={(e: any) => {
                setCost(e.target.value);
                validateCost(e.target.value);
              }}
              onBlur={() => validateCost(cost)}
            />
          </Col>
          <Col span={24}>
            <span
              style={{
                display: `${getValidationStyle(costValidation)}`,
              }}
              className="font-validation-error"
            >
              {costValidation}
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.storageLabels.dateMonthOfPurchase')}
              required
            />
            <DatePicker
              className="full-width"
              picker="month"
              disabledDate={(current: any) =>
                current && current > moment().endOf('day')
              }
              suffixIcon={
                <Icon
                  iconName={ICONS.ARROW_DOWN_S_LINE}
                  size={ICONS_SIZE.TWO_X}
                />
              }
              pickerType={DATE_PICKER_TYPE.DATE_PICKER}
              format={MONTH_YEAR_FORMAT}
              value={purchaseDate}
              placeholder={t('manualEntry.storageLabels.monthPlaceholder')}
              onChange={(value: any, date: any) => {
                setPurchaseDate(value);
                validatePurchaseDate(date);
              }}
              onBlur={() =>
                validatePurchaseDate(purchaseDate?.toString() ?? '')
              }
            />
            <span
              style={{
                display: `${getValidationStyle(purchaseDateValidation)}`,
              }}
              className="font-validation-error"
            >
              {purchaseDateValidation}
            </span>
          </Col>
          {modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE && (
            <Col span={12}>
              <FormLabel
                title={t('manualEntry.storageLabels.duration')}
                required
              />
              <Input
                className="full-width"
                type="input"
                value={duration}
                suffix={`/ ${t('manualEntry.computeLabels.months')}`}
                placeholder={t('manualEntry.storageLabels.durationPlaceholder')}
                onChange={(e: any) => {
                  setDuration(e.target.value);
                  validateDuration(e.target.value);
                }}
                onBlur={() => validateDuration(duration)}
              />
              <span
                style={{
                  display: `${getValidationStyle(durationValidation)}`,
                }}
                className="font-validation-error"
              >
                {durationValidation}
              </span>
            </Col>
          )}
        </Row>
      </div>
    </DrawerComponent>
  );
};

export default StorageRowModal;
