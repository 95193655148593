import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import moment, { Moment } from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import {
  selectPurchase,
  setAwsRegions,
  setSavingPlansCartData,
} from 'redux/purchaseSlice';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import {
  SPAndOpenSearchPaymentOptions,
  SPAndOpenSearchPaymentOptionsList,
  SAVINGS_PLANS_TYPES,
  SPSavingPlanTypes,
  TERMS_LIST,
} from 'pages/PurchasePage/constants';
import { isNumber, validateEmptyField } from 'utils/validations';
import { DATE_FORMAT, TIMESTAMP_FORMAT } from 'utils/date';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { fetchAwsRegions } from 'pages/PurchasePage/services';

import { getDisabledTime } from './utils';
import { getMonthlyPayment, getSubTotalDue } from '../../utils';
import { SAVING_PLANS_PURCHASE_FORM_EMPTY_DATA } from '../../constants';
import { getAwsSpEc2InstanceFamilies } from '../../services';
import { PurchaseSavingPlansType } from '../../types';

type BuySavingPlansFormModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  editData?: PurchaseSavingPlansType;
  indexPosition?: number;
};

const BuySavingPlansFormModal = ({
  show,
  setShow,
  editData,
  indexPosition,
}: BuySavingPlansFormModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { savingPlansCartData, awsRegions, selectedConnection } =
    useSelector(selectPurchase);

  const [spFormData, setSpFormData] = useState<PurchaseSavingPlansType>(
    SAVING_PLANS_PURCHASE_FORM_EMPTY_DATA
  );
  const [fetchRegionReqStatus, setFetchRegionReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [instanceFamilies, setInstanceFamilies] = useState<string[]>([]);
  const [
    fetchInstanceFamiliesRequestStatus,
    setFetchInstanceFamiliesRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [savingPlansTypeValidation, setSavingPlansTypeValidation] =
    useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [instanceFamilyValidation, setInstanceFamilyValidation] = useState('');
  const [commitmentValidation, setCommitmentValidation] = useState('');
  const [paymentOptionValidation, setPaymentOptionValidation] = useState('');
  const [upfrontCostValidation, setUpfrontCostValidation] = useState('');

  useEffect(() => {
    if (awsRegions.length === 0) {
      getAwsRegions();
    }

    if (editData) {
      setSpFormData(editData);
    }
  }, []);

  useEffect(() => {
    if (
      spFormData.savingsPlanType &&
      spFormData?.savingsPlanType === SPSavingPlanTypes.EC2_INSTANCE &&
      spFormData.region
    ) {
      fetchInstanceFamilies();
    } else {
      setInstanceFamilies([]);
    }
  }, [spFormData.savingsPlanType, spFormData.region]);

  /**
   * @function fetchRegions
   * @description Function to fetch regions data
   */
  const getAwsRegions = () => {
    setFetchRegionReqStatus(REQUEST_STATUS.PROCESSING);

    fetchAwsRegions({ connectorId: selectedConnection?.connectorId })
      .then((res: any) => {
        dispatch(setAwsRegions(res.data.responseData));
        setFetchRegionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchRegionReqStatus);
      });
  };

  /**
   * @function fetchInstanceFamilies
   * @description Function to fetch the instance families
   */
  const fetchInstanceFamilies = () => {
    setFetchInstanceFamiliesRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      region: spFormData.region,
    };

    getAwsSpEc2InstanceFamilies(params)
      .then((res: any) => {
        setInstanceFamilies(res?.data?.responseData || []);
        setFetchInstanceFamiliesRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchInstanceFamiliesRequestStatus);
        setInstanceFamilies([]);
      });
  };

  /**
   * @function validateSavingPlansType
   * @description Function to validate the saving plan type field
   * @param value to be validated
   * @returns true if validation is successful else false
   */
  const validateSavingPlansType = (value: string | undefined) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.savingsPlansLabels.savingsPlanType`),
      setSavingPlansTypeValidation
    );
  };

  /**
   * @function validateRegion
   * @description Function to validate the region field
   * @param value to be validated
   * @returns true if validation is successful else false
   */
  const validateRegion = (value: string | undefined) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.region`),
      setRegionValidation
    );
  };

  /**
   * @function validateInstanceFamily
   * @description Function to validate the instance family field
   * @param value to be validated
   * @returns true if validation is successful else false
   */
  const validateInstanceFamily = (value: string | undefined) => {
    if (spFormData?.savingsPlanType !== SPSavingPlanTypes.EC2_INSTANCE) {
      return true;
    }

    return !validateEmptyField(
      value,
      t(`purchasePage.savingsPlansLabels.instanceFamily`),
      setInstanceFamilyValidation
    );
  };

  /**
   * @function validateHourlyCommitment
   * @description Function to validate the hourly commitment field
   * @param value to be validated
   * @returns true if validation is successful else false
   */
  const validateHourlyCommitment = (value: string | undefined) => {
    if (
      !validateEmptyField(
        value,
        t(`purchasePage.savingsPlansLabels.hourlyCommitment`),
        setCommitmentValidation
      )
    ) {
      return (
        isNumber(
          value,
          true,
          t(`purchasePage.savingsPlansLabels.hourlyCommitment`),
          setCommitmentValidation
        ) && validateHourlyCommitmentRangeLimit(value)
      );
    }

    return false;
  };

  /**
   * @function validateHourlyCommitmentRangeLimit
   * @description Function to validate the hourly commitment limit and range
   * @param value to be validated
   * @returns true if validation is successful else false
   */
  const validateHourlyCommitmentRangeLimit = (value: string | undefined) => {
    if (!value || !isNumber(value)) {
      return false;
    }

    if (Number(value) <= 0) {
      setCommitmentValidation(
        t('purchasePage.savingsPlansLabels.commitmentRangeError', {
          currencySymbol: currencySymbol,
        })
      );
      return false;
    }

    if (Number(value) >= 0.1) {
      setCommitmentValidation(
        t('purchasePage.savingsPlansLabels.commitmentLimitError', {
          currencySymbol: currencySymbol,
        })
      );
      return false;
    }

    setCommitmentValidation('');

    return true;
  };

  /**
   * @function validatePaymentType
   * @description Function to validate the payment type field
   * @param value to be validated
   * @returns true if validation is successful else false
   */
  const validatePaymentType = (value: string | undefined) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.paymentOptions`),
      setPaymentOptionValidation
    );
  };

  /**
   * @function validateUpfrontCostRangeLimit
   * @description Function to validate the upfront cost limit
   * @param commitment Commitment per hour
   * @param term term chosen
   * @param upfront upfront cost entered
   * @returns true if validation is successful else false
   */
  const validateUpfrontCostRangeLimit = (
    commitment: string | undefined,
    term: number,
    upfront: string | undefined
  ) => {
    if (upfront && isNumber(upfront) && Number(upfront) <= 0) {
      setUpfrontCostValidation(
        t('purchasePage.savingsPlansLabels.upfrontCostRangeError', {
          currencySymbol: currencySymbol,
        })
      );
      return false;
    }

    if (
      !commitment ||
      !isNumber(commitment) ||
      !upfront ||
      !isNumber(upfront)
    ) {
      return true;
    }

    const upperLimit = Number(commitment) * term * 365 * 24;
    if (Number(upfront) > upperLimit) {
      setUpfrontCostValidation(
        t('purchasePage.savingsPlansLabels.commitmentLimitError', {
          currencySymbol: currencySymbol,
          cost: upperLimit + '',
        })
      );
      return false;
    } else {
      setUpfrontCostValidation('');
    }

    return true;
  };

  /**
   * @function validateUpfrontCost
   * @description Function to validate the upfront cost field
   * @param value to be validated
   * @returns true if validation is successful else false
   */
  const validateUpfrontCost = (value: string | undefined) => {
    if (!spFormData?.paymentOption) {
      return false;
    }

    if (
      spFormData?.paymentOption === SPAndOpenSearchPaymentOptions.NO_UPFRONT
    ) {
      return true;
    }

    if (
      !validateEmptyField(
        value,
        t(`purchasePage.savingsPlansLabels.upfrontCost`),
        setUpfrontCostValidation
      )
    ) {
      return (
        isNumber(
          value,
          true,
          t(`purchasePage.savingsPlansLabels.upfrontCost`),
          setUpfrontCostValidation
        ) &&
        validateUpfrontCostRangeLimit(
          spFormData.commitment,
          spFormData.term,
          value
        )
      );
    }

    return false;
  };

  /**
   * @function onChangeStartDate
   * @description Callback function for change in start date
   * @param value value changed
   */
  const onChangeStartDate = (value: Moment) => {
    if (!value) {
      setSpFormData({ ...spFormData, purchaseTime: undefined });
      return;
    }

    const day = value.date();
    const month = value.month();
    const year = value.year();

    let purchaseTime = moment(spFormData?.purchaseTime).set({
      date: day,
      month: month,
      year: year,
    });

    // Add one hour from now as time if the date is today and time is less than or equal to now
    if (
      moment().isSame(purchaseTime, 'date') &&
      moment().isSameOrAfter(purchaseTime, 'hours')
    ) {
      purchaseTime.set({
        hours: moment().add(1, 'hours').hours(),
        minutes: moment().add(1, 'minutes').minutes(),
      });
    }

    setSpFormData({
      ...spFormData,
      purchaseTime: purchaseTime.format(TIMESTAMP_FORMAT),
    });
  };

  /**
   * @function onChangeStartTime
   * @description Callback function for change in start time
   * @param value value changed
   */
  const onChangeStartTime = (value: Moment) => {
    const hours = value.hours();
    const minutes = value.minutes();
    const seconds = value.seconds();
    const purchaseTime = moment(spFormData?.purchaseTime)
      .set({
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      })
      .format(TIMESTAMP_FORMAT);
    setSpFormData({ ...spFormData, purchaseTime: purchaseTime });
  };

  /**
   * @function getUpfrontCostByPaymentOption
   * @description Function to return the upfront cost by payment option
   * @param value type of payment option
   * @returns upfront cost of type string
   */
  const getUpfrontCostByPaymentOption = (value: string) => {
    if (value === SPAndOpenSearchPaymentOptions.NO_UPFRONT) {
      return undefined;
    }

    if (value === SPAndOpenSearchPaymentOptions.ALL_UPFRONT) {
      return `${getSubTotalDue(
        spFormData?.term,
        spFormData?.commitment,
        spFormData?.upfrontPaymentAmount
      )}`;
    }

    return spFormData.upfrontPaymentAmount;
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @returns true if validation is successful else false
   */
  const validateAllFields = () => {
    let validation = true;

    if (!validateSavingPlansType(spFormData?.savingsPlanType)) {
      validation = false;
    }

    if (!validateRegion(spFormData?.region)) {
      validation = false;
    }

    if (!validateInstanceFamily(spFormData?.instanceFamily)) {
      validation = false;
    }

    if (!validateHourlyCommitment(spFormData?.commitment)) {
      validation = false;
    }

    if (!validatePaymentType(spFormData?.paymentOption)) {
      validation = false;
    }

    if (!validateUpfrontCost(spFormData?.upfrontPaymentAmount)) {
      validation = false;
    }

    return validation;
  };

  /**
   * @function onClickBuy
   * @description Callback function for buy button click
   */
  const onClickBuy = () => {
    if (!validateAllFields()) {
      return;
    }

    if (editData !== undefined && indexPosition !== undefined) {
      const cartData = [...savingPlansCartData];
      cartData.splice(indexPosition, 1, spFormData);
      dispatch(setSavingPlansCartData(cartData));
    } else {
      dispatch(setSavingPlansCartData([...savingPlansCartData, spFormData]));
    }
    setShow(false);
  };

  return (
    <DrawerComponent
      className="sp-purchase-form"
      width={481}
      open={show}
      title={t('purchasePage.savingsPlansLabels.purchaseSavingPlans')}
      onClose={() => setShow(false)}
      footer={
        <PurchaseFooter
          totalHeading={t('purchasePage.cart.subTotalDueNow')}
          totalValue={getSubTotalDue(
            spFormData?.term,
            spFormData?.commitment,
            spFormData?.upfrontPaymentAmount
          )}
          costSummary={[
            {
              key: t('purchasePage.savingsPlansLabels.monthlyPayment'),
              value: getMonthlyPayment(
                spFormData?.term,
                spFormData?.commitment,
                spFormData?.upfrontPaymentAmount
              ),
            },
            {
              key: t('purchasePage.savingsPlansLabels.upfrontCost'),
              value:
                spFormData?.upfrontPaymentAmount &&
                isNumber(spFormData.upfrontPaymentAmount)
                  ? spFormData.upfrontPaymentAmount
                  : 0,
            },
          ]}
          okTitle={
            editData
              ? t('purchasePage.cart.update')
              : t('purchasePage.cart.buy')
          }
          onClickCancel={() => setShow(false)}
          onClickOk={onClickBuy}
        />
      }
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.savingsPlansLabels.savingsPlanType')}
            required={true}
          />
          <SelectDropdown
            value={spFormData?.savingsPlanType}
            options={SAVINGS_PLANS_TYPES}
            placeholder={t(
              'purchasePage.savingsPlansLabels.selectSavingsPlanType'
            )}
            onSelect={(value: any) => {
              setSpFormData({
                ...spFormData,
                savingsPlanType: value,
                instanceFamily: undefined,
              });
              validateSavingPlansType(value);
              setInstanceFamilyValidation('');
            }}
            onBlur={() => {
              validateSavingPlansType(spFormData?.savingsPlanType);
            }}
            designVersion2
          />
          <span
            style={{
              display: savingPlansTypeValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {savingPlansTypeValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.region')} required={true} />
          <SelectDropdown
            value={spFormData?.region}
            options={awsRegions.map((item) => ({ value: item, label: item }))}
            placeholder={t('purchasePage.cart.selectRegion')}
            loading={fetchRegionReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setSpFormData({
                ...spFormData,
                region: value,
                instanceFamily: undefined,
              });
              validateRegion(value);
              setInstanceFamilyValidation('');
            }}
            onBlur={() => {
              validateRegion(spFormData?.region);
            }}
            designVersion2
          />
          <span
            style={{
              display: regionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {regionValidation}
          </span>
        </div>
        {spFormData?.savingsPlanType === SPSavingPlanTypes.EC2_INSTANCE && (
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('purchasePage.savingsPlansLabels.instanceFamily')}
              required={true}
            />
            <SelectDropdown
              value={spFormData?.instanceFamily}
              options={instanceFamilies.map((item) => ({
                value: item,
                label: item,
              }))}
              placeholder={t(
                'purchasePage.savingsPlansLabels.selectInstanceFamily'
              )}
              loading={
                fetchInstanceFamiliesRequestStatus === REQUEST_STATUS.PROCESSING
              }
              onSelect={(value: any) => {
                setSpFormData({ ...spFormData, instanceFamily: value });
                validateInstanceFamily(value);
              }}
              onBlur={() => {
                validateInstanceFamily(spFormData?.instanceFamily);
              }}
              designVersion2
            />
            <span
              style={{
                display: instanceFamilyValidation ? 'inline' : 'none',
              }}
              className="font-validation-error"
            >
              {instanceFamilyValidation}
            </span>
          </div>
        )}
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.term')} required={true} />
          <Radio.Group
            options={TERMS_LIST}
            onChange={(e) => {
              setSpFormData({ ...spFormData, term: e.target.value });
              validateUpfrontCostRangeLimit(
                spFormData.commitment,
                e.target.value,
                spFormData.upfrontPaymentAmount
              );
            }}
            value={spFormData.term}
            optionType="button"
            rootClassName="no-custom-style"
            style={{ height: 28 }}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.savingsPlansLabels.hourlyCommitment')}
            required={true}
          />
          <Input
            placeholder={t(
              'purchasePage.savingsPlansLabels.enterHourlyCommitment'
            )}
            value={spFormData.commitment}
            addonAfter={`${currencySymbol}/hr`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSpFormData({ ...spFormData, commitment: e.target.value });
              validateHourlyCommitment(e.target.value);
              validateUpfrontCostRangeLimit(
                e.target.value,
                spFormData.term,
                spFormData.upfrontPaymentAmount
              );
            }}
            onBlur={() => {
              validateHourlyCommitment(spFormData?.commitment);
              validateUpfrontCostRangeLimit(
                spFormData?.commitment,
                spFormData.term,
                spFormData.upfrontPaymentAmount
              );
            }}
          />
          <span
            style={{
              display: commitmentValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {commitmentValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.savingsPlansLabels.startDate')}
            required={false}
          />
          <div className="flex flex-gap-16">
            <DatePicker
              className="width-70"
              pickerType={DATE_PICKER_TYPE.DATE_PICKER}
              value={
                spFormData?.purchaseTime
                  ? moment(spFormData?.purchaseTime)
                  : undefined
              }
              format={DATE_FORMAT}
              allowClear={true}
              showToday={false}
              onChange={onChangeStartDate}
              disabledDate={(date: Moment) => moment().isAfter(date, 'date')}
            />
            <TimePicker
              className="width-30"
              value={
                spFormData?.purchaseTime
                  ? moment(spFormData?.purchaseTime)
                  : undefined
              }
              showNow={false}
              onSelect={onChangeStartTime}
              disabledTime={() => getDisabledTime(spFormData?.purchaseTime)}
            />
          </div>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.cart.paymentOptions')}
            required={true}
          />
          <SelectDropdown
            value={spFormData?.paymentOption}
            options={SPAndOpenSearchPaymentOptionsList}
            placeholder={t('purchasePage.cart.selectPaymentOption')}
            onSelect={(value: any) => {
              setSpFormData({
                ...spFormData,
                paymentOption: value,
                upfrontPaymentAmount: getUpfrontCostByPaymentOption(value),
              });
              validatePaymentType(value);
              value !== SPAndOpenSearchPaymentOptions.PARTIAL_UPFRONT &&
                setUpfrontCostValidation('');
            }}
            onBlur={() => {
              validatePaymentType(spFormData?.paymentOption);
            }}
            designVersion2
          />
          <span
            style={{
              display: paymentOptionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {paymentOptionValidation}
          </span>
        </div>
        {spFormData?.paymentOption &&
          spFormData?.paymentOption ===
            SPAndOpenSearchPaymentOptions.PARTIAL_UPFRONT && (
            <div className="form-item flex flex-column">
              <FormLabel
                title={t('purchasePage.savingsPlansLabels.upfrontCost')}
                required={true}
              />
              <Input
                placeholder={t(
                  'purchasePage.savingsPlansLabels.enterUpfrontCost'
                )}
                value={spFormData.upfrontPaymentAmount}
                addonBefore={currencySymbol}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSpFormData({
                    ...spFormData,
                    upfrontPaymentAmount: e.target.value,
                  });
                  validateUpfrontCost(e.target.value);
                }}
                onBlur={() => {
                  validateUpfrontCost(spFormData?.upfrontPaymentAmount);
                }}
              />
              <span
                style={{
                  display: upfrontCostValidation ? 'inline' : 'none',
                }}
                className="font-validation-error"
              >
                {upfrontCostValidation}
              </span>
            </div>
          )}
      </section>
    </DrawerComponent>
  );
};

export default BuySavingPlansFormModal;
