import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImportFilesListType } from 'types/importFiles';
import {
  defaultFormData,
  ImportStaticFilesComponents,
} from 'pages/StaticFilesPage/constants';
import { ImportFileDataType } from 'pages/StaticFilesPage/types';
import { RootState } from './store';

export interface ImportStaticFilesState {
  staticFilesComponent: string;
  importFilesList: ImportFilesListType[];
  importFileData: ImportFileDataType;
  isEdit: boolean;
  uploadingFileTransactions: string[];
  currentStep: number;
}

const initialState: ImportStaticFilesState = {
  staticFilesComponent: ImportStaticFilesComponents.IMPORT_FILE_FORM,
  importFilesList: [],
  importFileData: defaultFormData,
  isEdit: false,
  uploadingFileTransactions: [],
  currentStep: 0,
};

export const importStaticFilesState = createSlice({
  name: 'importStaticFiles',
  initialState,
  reducers: {
    setImportStaticFilesComponent: (state, action: PayloadAction<string>) => {
      state.staticFilesComponent = action.payload;
    },
    setImportFilesList: (
      state,
      action: PayloadAction<ImportFilesListType[]>
    ) => {
      state.importFilesList = action.payload;
    },
    setImportFileData: (state, action: PayloadAction<ImportFileDataType>) => {
      state.importFileData = action.payload;
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    addFileUploadTransaction: (state, action: PayloadAction<string>) => {
      state.uploadingFileTransactions = [
        ...state.uploadingFileTransactions,
        action.payload,
      ];
    },
    removeFileUploadTransactions: (state, action: PayloadAction<string>) => {
      state.uploadingFileTransactions = state.uploadingFileTransactions.filter(
        (item) => item !== action.payload
      );
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
  },
});

export const importStaticFiles = (state: RootState) => state.importStaticFiles;
export const {
  setImportStaticFilesComponent,
  setImportFilesList,
  setImportFileData,
  setIsEdit,
  addFileUploadTransaction,
  removeFileUploadTransactions,
  setCurrentStep,
} = importStaticFilesState.actions;
export default importStaticFilesState.reducer;
