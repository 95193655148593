import i18n from 'i18n';
import moment from 'moment';
import { uniqBy } from 'lodash';

import { GroupConnectorDto } from 'pages/GroupsPage/types';
import { PERCENT } from 'constants/validation';
import {
  AvailableCustomGroupFieldsType,
  ConnectionListType,
  ConnectionProviderType,
  CustomViewDataType,
  FilterType,
  TagsFilterType,
} from 'types/dashboard';
import { COMPARATORS, CONJUNCTIONS, QUERY_FIELDS } from 'constants/requestBody';
import { DashboardListType, DashboardViewType } from 'types/navigationMenu';
import {
  CSP_TO_FOCUS_FIELD_MAP,
  FieldSource,
  MY_DASHBOARD_TYPES,
  TagSelectionType,
} from 'constants/dashboard';
import { INPUT_SIZE } from 'constants/appearance';
import gcpRegionCoordinates from 'assets/data/gcpRegionsCoordinatesMap.json';
import awsRegionCoordinates from 'assets/data/awsRegionsCoordinatesMap.json';
import azureRegionCoordinates from 'assets/data/azureRegionsCoordinatesMap.json';
import ociRegionCoordinates from 'assets/data/ociRegionsCoordinatesMap.json';
import { PROVIDER } from 'constants/cloudProviders';
import { ChartDimensionType } from 'pages/CreateReportPage/types';
import { IntegrationTypes } from 'pages/IntegrationsPage/constants';
import {
  TagMappingType,
  TagsByConnectionType,
} from 'pages/TagMappingPage/types';
import { KeyValueTypes } from 'types/dataTypes';

import {
  HYPHEN_DATE_FORMAT,
  YEAR_HYPHEN_MONTH,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  YEAR_MONTH_WITHOUT_ZERO,
} from './date';
import { formatArrayToStringByProvider } from './dataFormatterUtils';
import { GROUP_TYPE } from './providerDetails';
import { store } from '../redux/store';

/**
 * @function getPercentageDifference
 * @description Function to get difference percentage between two values
 * @params value1 : value to be compared with
 * @params value2 : second value for the comparison
 * @return number with two decimal precision
 */
export const getPercentageDifference = (value1: number, value2: number) => {
  const percentage = ((value1 - value2) / value2) * PERCENT;
  return Number(percentage.toFixed(2));
};

/**
 * @function getRoundedMaxFromArray
 * @description Function to get nearest rounded max value from array
 * @params array : array for which the max to be calculated
 * @params nearestRoundValue : nearest rounded value for Eg: if nearestRoundValue=100, 750 will be rounded to 800
 * @return nearest rounded max value of the array
 */
export const getRoundedMaxFromArray = (
  array: any[],
  nearestRoundValue: number
) => {
  return (
    Math.ceil(
      (Math.max(...array) * 0.1 + Math.max(...array)) / nearestRoundValue
    ) * nearestRoundValue
  );
};

/**
 * @function getRoundedMinFromArray
 * @description Function to get nearest rounded min value from array
 * @params array : array for which the min to be calculated
 * @params nearestRoundValue : nearest rounded value for Eg: if nearestRoundValue=100, 750 will be rounded to 700
 * @return nearest rounded min value of the array
 */
export const getRoundedMinFromArray = (
  array: any[],
  nearestRoundValue: number
) => {
  return Math.floor(Math.min(...array) / nearestRoundValue) * nearestRoundValue;
};

/**
 * @function getPreviousMonthAndYear
 * @description Function to get year and month subtracting numOfMonths from current date.
 * @params numOfMonths : Months to be subtracted from current month. Use 0 to get current month and date.
 * @params excludeMonthZero : Boolean if false will prefix the month with a zero. Eg 202203 else will return 20223
 * @return string in the format [year][month]. Eg 202203 or 20223
 */
export const getPreviousMonthAndYear = (
  numOfMonths: number,
  excludeMonthZero = false
) => {
  const newDate = moment().subtract(numOfMonths, 'months');
  const modifiedMonth = (
    (excludeMonthZero ? '' : '0') +
    (newDate.month() + 1)
  ).slice(-2);
  return newDate.year() + modifiedMonth;
};

/**
 * @function getPreviousMonthDayAndYear
 * @description Function to get year and month subtracting numOfMonths from current date.
 * @param numOfMonths : Months to be subtracted from current month. Use 0 to get current month and date.
 * @return string in the format [year]-[month]-[day]. Eg 2022-03-01
 */
export const getPreviousMonthDayAndYear = (numOfMonths: number) => {
  const newDate = moment().subtract(numOfMonths, 'months');
  return newDate.format(HYPHEN_DATE_FORMAT);
};

/**
 * @function getFirstMonthAndCurrentYear
 * @description Function to get year and month for today.
 * @params excludeMonthZero : Boolean if false will prefix the month with a zero. Eg 202203 else will return 20223
 * @return string in the format [year][month]. Eg 202201 or 20221
 */
export const getFirstMonthAndCurrentYear = (excludeMonthZero = false) => {
  const date = new Date();
  return date.getFullYear() + (excludeMonthZero ? '' : '0') + '1';
};

/**
 * @function getCurrentQuarterStartMonthAndYear
 * @description Function to get start year and start month for the current quarter.
 * @params excludeMonthZero : Boolean if false will prefix the month with a zero. Eg 202203 else will return 20223
 * @return string in the format [year][month]. Eg 202203 or 20223
 */
export const getCurrentQuarterStartMonthAndYear = (
  excludeMonthZero = false
) => {
  const date = new Date();
  const quarter = Math.floor(date.getMonth() / 3);
  const month = ((excludeMonthZero ? '' : '0') + (quarter * 3 + 1)).slice(-2);
  return date.getFullYear() + month;
};

/**
 * @function getPreviousQuarterStartMonthAndYear
 * @description Function to get start year and start month for the previous quarter.
 * @params excludeMonthZero : Boolean if false will prefix the month with a zero. Eg 202203 else will return 20223
 * @return string in the format [year][month]. Eg 202203 or 20223
 */
export const getPreviousQuarterStartMonthAndYear = (
  excludeMonthZero = false
) => {
  const date = new Date();
  let quarter = Math.floor(date.getMonth() / 3);
  let year = date.getFullYear();
  if (quarter === 0) {
    quarter = 3;
    year = date.getFullYear() - 1;
  } else {
    quarter = quarter - 1;
  }
  const month = ((excludeMonthZero ? '' : '0') + (quarter * 3 + 1)).slice(-2);
  return year + month;
};

/**
 * @function getPreviousQuarterEndMonthAndYear
 * @description Function to get end year and end month for the previous quarter.
 * @params excludeMonthZero : Boolean if false will prefix the month with a zero. Eg 202203 else will return 20223
 * @return string in the format [year][month]. Eg 202203 or 20223
 */
export const getPreviousQuarterEndMonthAndYear = (excludeMonthZero = false) => {
  const date = new Date();
  let quarter = Math.floor(date.getMonth() / 3);
  if (quarter === 0) {
    quarter = 3;
  } else {
    quarter = quarter - 1;
  }
  const month = ((excludeMonthZero ? '' : '0') + (quarter * 3 + 3)).slice(-2);
  return date.getFullYear() + month;
};

/**
 * @function trimMonthZero
 * @description Function to remove the zero prefix for month in the YYYYMM format.
 * @params month : String year and month in the format YYYYMM. Eg 202203.
 * @return string in the format YYYYM. Eg 202203 to 20223
 */
export const trimMonthZero = (month: string) => {
  return month.slice(0, 4) + Number(month.slice(4));
};

/**
 * @function getGraphLabelFromMonthAndYear
 * @description Function to get the label for graph with month and year.
 * @param month String which contains year and month in the format [year][month] Eg: 202203
 * @return string in the format [month][year]. Eg Apr 22
 */
export const getGraphLabelFromMonthAndYear = (month: string) => {
  const year = month.slice(2, 4);
  const monthLabel = moment.monthsShort()[Number(month.slice(4)) - 1];
  return monthLabel + ' ' + year;
};

/**
 * @function getMonthAndYearLabels
 * @description Function to get the list of labels for graph with month and year.
 * @param fromMonthAndYear String which contains start year and month in the format [year][month] Eg: 202203
 * @param toMonthAndYear String which contains end year and month in the format [year][month] Eg: 202203
 * @return list of string in the format [month][year]. Eg Apr 22
 */
export const getMonthAndYearLabels = (
  fromMonthAndYear: string,
  toMonthAndYear: string
) => {
  const labels: string[] = [];
  const fromYear: number = Number(fromMonthAndYear.slice(2, 4));
  const fromMonth: number = Number(fromMonthAndYear.slice(-2)) - 1;
  const toYear: number = Number(toMonthAndYear.slice(2, 4));
  const toMonth: number = Number(toMonthAndYear.slice(-2)) - 1;
  let currentYear = fromYear;
  let currentMonth = fromMonth;
  while (true) {
    labels.push(moment.monthsShort()[currentMonth] + ' ' + currentYear);
    if (currentMonth === toMonth && currentYear === toYear) {
      return labels;
    }
    if (currentMonth === 11) {
      currentMonth = 0;
      currentYear += 1;
    } else {
      currentMonth += 1;
    }
  }
};

/**
 * @function getTagFilterData
 * @description Function selected view tag data
 * @param tagsFilters contains tag filter data stored in redux
 * @param selectedDashboardId id of selected dashboard
 * @param selectedDashboardView id of selected view
 * @returns selected view tag data
 */
export const getTagFilterData = (
  tagsFilters: TagsFilterType[],
  selectedDashboardId: string | undefined,
  selectedDashboardView: string
) => {
  return (
    tagsFilters
      .find(
        (dashboardTagFilter) =>
          dashboardTagFilter.dashboardId === selectedDashboardId
      )
      ?.tagViews.find(
        (viewTagFilter) => viewTagFilter.viewId === selectedDashboardView
      )?.tags ?? []
  );
};

/**
 * @function getGCPTagFiltersData
 * @description Function to return selected tag filter group for GCP request body
 * @param tagsFilters contains tag filter data stored in redux
 * @param selectedDashboardId id of selected dashboard
 * @param selectedDashboardView id of selected view
 * @param isBillingDefault boolean to indicate whether it's a billing default dashboard type or billing, true if it's billing default else false. Defaults to false.
 * @returns filter group data for request body
 */
export const getGCPTagFiltersData = (
  tagsFilters: TagsFilterType[],
  selectedDashboardId: string | undefined,
  selectedDashboardView: string,
  isBillingDefault: boolean = false
) =>
  getTagFilterData(tagsFilters, selectedDashboardId, selectedDashboardView).map(
    (eachTag) => ({
      conjunctToNextGroup: CONJUNCTIONS.AND,
      keyValueStructfilters: eachTag.values.map((eachValue) => ({
        arrayName: isBillingDefault
          ? QUERY_FIELDS.PROJECT_LABEL_CUSTOM // TODO FOCUS There is no mapping for FOCUS schema
          : QUERY_FIELDS.RESOURCE_LABEL_STATIC, // TODO FOCUS There is no mapping for FOCUS schema
        key: eachTag.key,
        value: eachValue.value,
        conjunctToNextFilter: CONJUNCTIONS.OR,
      })),
    })
  );

/**
 * @function getAWSTagFiltersData
 * @description Function to return selected tag filter group for AWS request body
 * @param tagsFilters contains tag filter data stored in redux
 * @param selectedDashboardId id of selected dashboard
 * @param selectedDashboardView id of selected view
 * @returns filter group data for request body
 */
export const getAWSTagFiltersData = (
  tagsFilters: TagsFilterType[],
  selectedDashboardId: string | undefined,
  selectedDashboardView: string
) =>
  getTagFilterData(tagsFilters, selectedDashboardId, selectedDashboardView).map(
    (eachTag) => ({
      conjunctToNextGroup: CONJUNCTIONS.AND,
      filters: [
        {
          field: eachTag.key,
          comparator: COMPARATORS.IN,
          value: `${formatArrayToStringByProvider(
            eachTag.values.map((eachValue) => eachValue.value),
            PROVIDER.AWS
          )}`,
          conjunctToNextFilter: CONJUNCTIONS.OR,
        },
      ],
    })
  );

/**
 * @function getAzureTagFiltersData
 * @description Function to return selected tag filter group for Azure request body
 * @param tagsFilters contains tag filter data stored in redux
 * @param selectedDashboardId id of selected dashboard
 * @param selectedDashboardView id of selected view
 * @returns filter group data for request body
 */
export const getAzureTagFiltersData = (
  tagsFilters: TagsFilterType[],
  selectedDashboardId: string | undefined,
  selectedDashboardView: string
) =>
  getTagFilterData(tagsFilters, selectedDashboardId, selectedDashboardView).map(
    (eachTag) => ({
      conjunctToNextGroup: CONJUNCTIONS.AND,
      filters: eachTag.values.map((eachValue) => ({
        field: QUERY_FIELDS.TAGS,
        comparator: COMPARATORS.LIKE,
        value: `%"${eachTag.key}": "${eachValue.value}"%`,
        conjunctToNextFilter: CONJUNCTIONS.OR,
      })),
    })
  );

/**
 * @function getOCITagFiltersData
 * @description Function to return selected tag filter group for OCI request body
 * @param tagsFilters contains tag filter data stored in redux
 * @param selectedDashboardId id of selected dashboard
 * @param selectedDashboardView id of selected view
 * @returns filter group data for request body
 */
export const getOCITagFiltersData = (
  tagsFilters: TagsFilterType[],
  selectedDashboardId: string | undefined,
  selectedDashboardView: string
) =>
  getTagFilterData(tagsFilters, selectedDashboardId, selectedDashboardView).map(
    (eachTag) => ({
      conjunctToNextGroup: CONJUNCTIONS.AND,
      filters: eachTag.values.map((eachValue) => ({
        field: QUERY_FIELDS.TAGS_DATA,
        comparator: COMPARATORS.LIKE,
        value: `%${eachTag.key}=${eachValue.value}%`,
        conjunctToNextFilter: CONJUNCTIONS.OR,
      })),
    })
  );

/**
 * @function getDashboardProvider
 * @description Function to get the provider name based on the type of dashboard
 * @param dashboard selected Dashboard
 * @returns provider GCP or AWS or AZURE or OCI or SNOWFLAKE or GROUPS
 */
export const getDashboardProvider = (dashboard: DashboardListType | null) => {
  switch (dashboard?.dashBoardType) {
    case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
    case MY_DASHBOARD_TYPES.COST_ALLOCATION:
    case MY_DASHBOARD_TYPES.IMPORTS:
      return dashboard?.connectorProvider;
    case MY_DASHBOARD_TYPES.GROUP: {
      const groupProviders = dashboard?.connectorProvider.split(',');
      if (groupProviders?.every((provider) => provider === groupProviders[0])) {
        return groupProviders[0];
      }
      return GROUP_TYPE;
    }
    case MY_DASHBOARD_TYPES.SNOWFLAKE:
      return IntegrationTypes.SNOWFLAKE;
  }
};

/**
 * @function getDashboardDatasourceName
 * @description Function to get the datasource name based on the type of dashboard
 * @param dashboard selected Dashboard
 * @returns connector name or group name or transaction name based on the selected dashboard
 */
export const getDashboardDatasourceName = (
  dashboard: DashboardListType | null
) => {
  switch (dashboard?.dashBoardType) {
    case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
    case MY_DASHBOARD_TYPES.COST_ALLOCATION:
      return dashboard?.connectorName;
    case MY_DASHBOARD_TYPES.GROUP:
      return dashboard.groupName;
    case MY_DASHBOARD_TYPES.IMPORTS:
      return dashboard.transactionName;
    case IntegrationTypes.SNOWFLAKE:
      return dashboard.integrationName;
  }
};

/**
 * @function getDashboardDatasourceId
 * @description Function to get the datasource id based on the type of dashboard
 * @param dashboard selected Dashboard
 * @param connection Optional connection data
 * @returns connector id or group name or transaction name based on the selected dashboard
 */
export const getDashboardDatasourceId = (
  dashboard: DashboardListType | null,
  connection?: ConnectionListType | null
) => {
  switch (dashboard?.dashBoardType) {
    case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
      return dashboard?.connectorId;
    case MY_DASHBOARD_TYPES.GROUP:
      return dashboard.groupName;
    case MY_DASHBOARD_TYPES.IMPORTS:
      return connection?.name;
  }
};

/**
 * @function getProviderForConnection
 * @description Function to return cloud provider, if the connection dto is migrated then return migration provider and if not return connection provider
 * @param connection Connection Dto in each selected group dashboard metadata
 * @returns Cloud Provider
 */
export const getProviderForConnection = (
  connection: GroupConnectorDto | ConnectionListType | null
) => {
  const provider = connection?.migrated
    ? connection?.migrationProvider
    : connection?.provider;
  return provider ?? '';
};

/**
 * @function generateGraphColors
 * @description Function to generate the colors for graphs based on the color schema.
 * @param colorCountsRequired Count of colors required
 * @returns List of colors in hexa color code format
 */
export const generateGraphColors = (colorCountsRequired: number) => {
  const state = store.getState();
  const availableColors = state.theme.manualChartColors;
  let noOfVariants = colorCountsRequired / availableColors.length;
  if (noOfVariants > 10) {
    noOfVariants = 10;
  } else {
    noOfVariants = Math.ceil(noOfVariants);
  }

  const lightnessSkip = Math.floor(50 / noOfVariants);

  let colors: string[] = [];

  let variant = 50;
  while (colors.length < colorCountsRequired) {
    if (variant >= 100) {
      variant = 50;
    }

    availableColors.forEach((color) => {
      colors.push(
        convertHslToHex(convertHexToHsl(color.hexCode, { l: variant }))
      );

      if (colorCountsRequired === colors.length) {
        return colors;
      }
    });
    variant += lightnessSkip;
  }

  return colors;
};

/**
 * @function changeAlphaOfColor
 * @description Change the alpha of a given color
 * @param color hex code of the color
 * @param alpha percentage of the alpha between 0 and 100
 * @returns hex code of color with the new alpha
 */
export const changeAlphaOfColor = (color: string, alpha: number) => {
  const colorWithoutAlpha = color.slice(0, 7);
  let alphaHex = Math.round((alpha * 255) / 100).toString(16);
  if (alphaHex.length < 2) alphaHex = '0' + alpha;
  return colorWithoutAlpha + alphaHex;
};

/**
 * @function getIconTextColorByBackground
 * @description Function to get the icon color based on the background lightness
 * @param hex hex code of the background color
 * @returns color to be used for the icon or text
 */
export function getIconTextColorByBackground(hex: string) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  // Calculate lightness based on the formula from https://www.w3.org/TR/WCAG20/#relativeluminancedef
  const lightness = (r * 299 + g * 587 + b * 114) / 1000;
  const lightnessPercentage = (lightness / 255) * 100;
  // Return white if color is too dark and vice versa
  return lightnessPercentage > 65 ? '#000' : '#fff';
}

/**
 * @function convertHexToHsl
 * @description Convert a hex color to HSL
 * @param hexColor hex code of the color to be converted
 * @param replaceWith An object containing the HSL values to be replaced with the converted hsl values
 * @returns Converted HSL color
 */
export const convertHexToHsl = (
  hexColor: string,
  replaceWith?: { h?: number; s?: number; l?: number }
) => {
  // Convert hex to RGB first
  let r: any, g: any, b: any;
  const result =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor) ?? [];
  r = parseInt(result[1], 16);
  g = parseInt(result[2], 16);
  b = parseInt(result[3], 16);

  r /= 255;
  g /= 255;
  b /= 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s;
  let l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  if (replaceWith) {
    return replaceHslValues(h, s, l, replaceWith);
  }

  return 'hsl(' + h + ',' + s + '%,' + l + '%)';
};

/**
 * @function replaceHslValues
 * @description Replace with the converted hsl values of a given HSL color
 * @param h Hue value of the HSL color
 * @param s Saturation value of the HSL color
 * @param l Lightness value of the HSL color
 * @param replaceWith An object containing the HSL values to be replaced with the converted hsl values
 * @returns Converted HSL color
 */
const replaceHslValues = (
  h: number,
  s: number,
  l: number,
  replaceWith: { h?: number; s?: number; l?: number }
) => {
  const newH = replaceWith.h ? replaceWith.h : h;
  const newS = replaceWith.s ? replaceWith.s : s;
  const newL = replaceWith.l ? replaceWith.l : l;

  return 'hsl(' + newH + ',' + newS + '%,' + newL + '%)';
};

/**
 * @function convertHslToHex
 * @description Convert HSL color to Hex
 * @param hslString HSL string color to be converted
 * @returns Converted Hex color
 */
export const convertHslToHex = (hslString: string) => {
  const hslValues = hslString.substring(
    hslString.indexOf('(') + 1,
    hslString.indexOf(')')
  );

  const hsl = hslValues.split(',').map((value, index) => {
    if (index === 0) return Number(value);
    return Number(value.substring(0, value.length - 1));
  });

  const hDecimal = hsl[2] / 100;
  const a = (hsl[1] * Math.min(hDecimal, 1 - hDecimal)) / 100;
  const f = (n: number) => {
    const k = (n + hsl[0] / 30) % 12;
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    // Convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

/**
 * @function addZeroMarginClass
 * @description Add a class to the body to remove the margin of the app content class
 */
export const addZeroMarginClass = (className = 'app-content') => {
  document
    .getElementsByClassName(className)[0]
    ?.classList.add('margin-less-content');
};

/**
 * @function removeZeroMarginClass
 * @description Remove the class from the body to remove the margin of the app content class
 */
export const removeZeroMarginClass = (className = 'app-content') => {
  document
    .getElementsByClassName(className)[0]
    ?.classList.remove('margin-less-content');
};

/**
 * @function getRegionCoordinatesByProvider
 * @description Returns the region coordinates based on the provider
 * @param provider The cloud provider for which the region coordinates is fetched
 * @returns The List of region coordinates
 */
const getRegionCoordinatesByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.GCP:
      return gcpRegionCoordinates;

    case PROVIDER.AWS:
      return awsRegionCoordinates;

    case PROVIDER.AZURE:
      return azureRegionCoordinates;

    case PROVIDER.OCI:
      return ociRegionCoordinates;

    default:
      return [];
  }
};

/**
 * @function getRegionCoordinates
 * @description Get the coordinates of a region from gcpRegionsCoordinatesMap or awsRegionsCoordinatesMap
 * @param region The region for which the coordinates is fetched
 * @param provider The cloud provider
 * @returns The coordinates of for the given provider and region
 */
export const getRegionCoordinates = (
  region: string,
  provider: string = PROVIDER.GCP
): number[] => {
  const searchObj = getRegionCoordinatesByProvider(provider).find(
    (coordinate) => coordinate.region.toLowerCase() === region?.toLowerCase()
  );
  if (searchObj?.fallbackRegion) {
    return getRegionCoordinates(searchObj.fallbackRegion, provider);
  }

  return searchObj?.coordinates ?? [];
};

/**
 * @function getCustomGroupFieldTypeByCategory
 * @description Function to return the group field type by the category of the field
 * @returns string field
 */
export const getCustomGroupFieldTypeByCategory = (category: string) => {
  switch (category) {
    case 'NUMERIC':
      return 'FLOAT';
    case 'DATE':
      return 'TIMESTAMP';
    case 'LITERAL':
    case 'YEAR_MONTH':
    default:
      return 'STRING';
  }
};

/**
 * @function createAvailableFieldsTypeForGroup
 * @description Create available field for dimensions and metrics in the type of [AvailableFieldsType]
 * @returns Object containing the name category and type of field
 */
export const createAvailableFieldsTypeForGroup = (
  availableField: AvailableCustomGroupFieldsType
) => {
  return {
    name: availableField.label,
    category: availableField.category,
    type: getCustomGroupFieldTypeByCategory(availableField.category),
  };
};

/**
 * @function getClassnameBySize
 * @description Function to return the classname based on the size for input components
 * @param size string size of the input
 * @returns string classname
 */
export const getClassnameBySize = (size: string) => {
  if (size === INPUT_SIZE.SMALL) return 'input-small';

  return 'input-medium';
};

/**
 * @function getQueryTagField
 * @description Function to construct the tag query field
 * @param dimension dimension details
 * @param connectionData connection details
 * @param tagValues tag key value data
 * @param tagMaps tag mapping data
 * @returns string query field
 */
export const getQueryTagField = (
  dimension: ChartDimensionType,
  connectionData: ConnectionProviderType,
  tagValues: KeyValueTypes[],
  tagMaps: TagMappingType[]
) => {
  if (PROVIDER.OCI === connectionData.provider) {
    return dimension.dimension;
  }

  // GCP
  if (connectionData.provider === PROVIDER.GCP) {
    return dimension.tagDimensionType === TagSelectionType.KEY
      ? QUERY_FIELDS.TAG_KEY
      : QUERY_FIELDS.TAG_VALUE;
  }

  // AWS
  if (connectionData.provider === PROVIDER.AWS) {
    const tagsKeys = tagMaps
      .find((tagMap) => tagMap.customTagName === dimension.dimension)
      ?.cloudMapList?.find(
        (item) => item.connectionId === connectionData.connectorId
      )?.values;

    let tagQuery = 'CASE';
    tagsKeys?.forEach((item) => {
      const rawField = tagValues.find(
        (tagVal) => tagVal.key === item.fieldName
      )?.value;

      // Key will be resource_tags['tag_key'] for AWS as resource_tags is a column that contains the map of tag_key and values
      const key = `resource_tags['${
        `${rawField}`.split('resource_tags_')[1]
      }']`;

      // value field, will be the tag value if the tagDimensionType is VALUE else the field display value coming from backend.
      const val =
        dimension.tagDimensionType === TagSelectionType.KEY
          ? `'${item.fieldName}'`
          : key;
      tagQuery += ` WHEN ${key} IS NOT NULL THEN ${val}`;
    });

    tagQuery += " ELSE 'No Value' END ";

    return tagQuery;
  }

  // AZURE
  const tagsKeys = tagMaps
    .find((tagMap) => tagMap.customTagName === dimension.dimension)
    ?.cloudMapList?.find(
      (item) => item.connectionId === connectionData.connectorId
    )?.values;

  let tagQuery = 'CASE';
  tagsKeys?.forEach((item) => {
    if (dimension.tagDimensionType === TagSelectionType.KEY) {
      tagQuery += ` WHEN JSON_VALUE(Tags, '$.${item.fieldName}') != '' THEN '${item.fieldName}'`;
      return;
    }
    tagQuery += ` WHEN JSON_VALUE(Tags, '$.${item.fieldName}') != '' THEN JSON_VALUE(Tags, '$.${item.fieldName}')`;
  });

  tagQuery += " ELSE 'No Value' END ";
  return tagQuery;
};

/**
 * @function getTagsQueryFilters
 * @description Function to construct the tag query filters
 * @param dimensions dimension details
 * @param connectionData connection details
 * @param tagValues tag key value data
 * @param tagMaps tag mapping data
 * @returns List of query filters
 */
export const getTagsQueryFilters = (
  dimensions: ChartDimensionType[],
  connectionData: ConnectionProviderType,
  tagValues: KeyValueTypes[],
  tagMaps: TagMappingType[]
) => {
  let queryFilters: FilterType[] = [];
  let tagsKeys: TagsByConnectionType[] = [];
  dimensions
    .filter((item) => item.dimensionType === FieldSource.TAGS)
    .forEach((dimension) => {
      const tagMapKeys =
        tagMaps
          .find((tagMap) => tagMap.customTagName === dimension.dimension)
          ?.cloudMapList?.find(
            (item) => item.connectionId === connectionData.connectorId
          )?.values ?? [];
      tagsKeys.push(...tagMapKeys);
    });

  tagsKeys = uniqBy(tagsKeys, 'fieldName');

  if (connectionData.provider === PROVIDER.GCP && tagsKeys.length > 0) {
    queryFilters = [
      {
        field: QUERY_FIELDS.TAG_KEY,
        comparator: COMPARATORS.IN,
        value: `('${tagsKeys.map((item) => item.fieldName).join("','")}')`,
      },
    ];
  }

  if (connectionData.provider === PROVIDER.AWS && tagsKeys.length > 0) {
    queryFilters = tagsKeys.map((item) => ({
      field: `${
        tagValues.find((tagVal) => tagVal.key === item.fieldName)?.value
      }`,
      comparator: COMPARATORS.NOT_EQUAL_TO,
      value: '',
      conjunctToNextFilter: CONJUNCTIONS.OR,
    }));
  }

  if (connectionData.provider === PROVIDER.AZURE && tagsKeys.length > 0) {
    queryFilters = tagsKeys.map((item) => ({
      field: `JSON_VALUE(Tags, '$.${item.fieldName}')`,
      comparator: COMPARATORS.NOT_EQUAL_TO,
      value: '',
      conjunctToNextFilter: CONJUNCTIONS.OR,
    }));
  }

  return queryFilters ?? [];
};

/**
 * @function getSelectedDashboardConnectionName
 * @description Function to get the connection name of the selected dashboard
 * @returns connection name for single connection dashboard or group name for group dashboard
 * or transaction name for imports dashboard or integration name for snowflake dashboard
 */
export const getSelectedDashboardConnectionName = () => {
  const dashboardSlice = store.getState().dashboard;
  const { selectedDashboard } = dashboardSlice;

  if (selectedDashboard?.dashBoardType === MY_DASHBOARD_TYPES.GROUP) {
    return selectedDashboard?.groupName;
  } else if (selectedDashboard?.dashBoardType === MY_DASHBOARD_TYPES.IMPORTS) {
    return selectedDashboard?.transactionName;
  } else if (selectedDashboard?.integrationId) {
    return selectedDashboard?.integrationName;
  } else {
    return selectedDashboard?.connectorName;
  }
};

/**
 * @function getAwsRegionIdFromName
 * @description Function to get aws region id from region name
 * @param regionName region name. For eg: US East (N. Virginia)
 * @returns returns region id. For eg: us-east-1 for US East (N. Virginia)
 */
export const getAwsRegionIdFromName = (regionName: string) => {
  return awsRegionCoordinates.find(
    (coordinate) =>
      coordinate.awsRegionName.toLowerCase() === regionName.toLowerCase()
  )?.region;
};

/**
 * @function isDashboardWithStaticData
 * @description Function to return whether the dashboard is with static data or not
 * @param dashboard dashboard data
 * @returns boolean true if the data is static else false
 */
export const isDashboardWithStaticData = (
  dashboard: DashboardListType | null
) => {
  return dashboard?.dashBoardType === MY_DASHBOARD_TYPES.IMPORTS;
};

/**
 * @function transformDataByGroupAndType
 * @description Transforms an array of objects into a different structure, grouping the data by a specific field and organizing it by type.
 * @param data - The data to transform.
 * @param groupByField - The field to group by.
 * @param valueField - The field to use for the values.
 * @param typeField - The field to use for the types.
 * @returns The transformed data, grouped by the specified field and organized by type.
 *
 * @example Input:
 * data = [
 *   { group: 'A', type: 'X', value: 10 },
 *   { group: 'A', type: 'Y', value: 20 },
 *   { group: 'B', type: 'X', value: 30 },
 *   { group: 'B', type: 'Y', value: 40 },
 * ]
 * groupByField = 'group'
 * valueField = 'value'
 * typeField = 'type'
 *
 * Output:
 * [
 *   { group: 'A', X: 10, Y: 20 },
 *   { group: 'B', X: 30, Y: 40 },
 * ]
 */
export const transformDataByGroupAndType = (
  data: any[],
  groupByField: string,
  valueField: string,
  typeField: string
) => {
  const groupList = Array.from(new Set(data.map((item) => item[groupByField])));
  const transformedData: any[] = [];
  groupList.forEach((group) => {
    const groupData = data.filter((item) => item[groupByField] === group);
    const transformedItem = { [groupByField]: group };
    data.forEach((item) => {
      const itemData = groupData.find(
        (dataItem) => dataItem[typeField] === item[typeField]
      );
      transformedItem[item[typeField]] = itemData?.[valueField] ?? 0;
    });
    transformedData.push(transformedItem);
  });
  return transformedData;
};

/**
 * @function transformStackedDataByGroupForPptExport
 * @description Function to transform the data into a stacked format by a specific field.
 * @param data - The data to transform.
 * @param groupByField - The field to group by.
 * @returns The transformed data, grouped by the specified field.
 * @example Input:
 * data = [
 *  { group: 'A', type: 'X', value: 10 },
 *  { group: 'A', type: 'Y', value: 20 },
 *  { group: 'B', type: 'X', value: 30 },
 *  { group: 'B', type: 'Y', value: 40 },
 * ]
 * groupByField = 'group'
 * Output:
 * [
 *  { name: 'A', labels: ['X', 'Y'], values: [10, 20] },
 *  { name: 'B', labels: ['X', 'Y'], values: [30, 40] },
 * ]
 */
export const transformStackedDataByGroupForPptExport = (
  data: any[],
  groupByField: string,
  valueField: string,
  typeField: string
) => {
  return data.reduce(
    (acc: { name: string; labels: string[]; values: number[] }[], item) => {
      const existingGroup = acc.find((obj) => obj.name === item[groupByField]);
      if (existingGroup) {
        existingGroup.labels.push(item[typeField]);
        existingGroup.values.push(item[valueField]);
      } else {
        acc.push({
          name: item[groupByField],
          labels: [item[typeField]],
          values: [item[valueField]],
        });
      }
      return acc;
    },
    []
  );
};

/**
 * @function getAwsRegionIdFromName
 * @description Function to get aws region id from region name
 * @param regionName region name. For eg: US East (N. Virginia)
 * @returns returns region id. For eg: us-east-1 for US East (N. Virginia)
 */
export const getConnectionDetailsByDashboard = (
  dashboard: DashboardListType,
  groupConnectionsData: GroupConnectorDto[] = []
) => {
  if (dashboard.dashBoardType === MY_DASHBOARD_TYPES.GROUP) {
    return groupConnectionsData.map((connection) => ({
      provider: getProviderForConnection(connection),
      connectorId: connection.connectorId,
    }));
  }

  return [
    {
      provider: dashboard.connectorProvider,
      connectorId: dashboard.connectorId,
    },
  ];
};

/**
 * @function getExportFileName
 * @description Function to get the export file name based on the selected view
 * @returns string name of the file
 */
export const getExportFileName = (
  selectedDashboardCustomViews: CustomViewDataType[],
  selectedDashboardView: string,
  dashboardViewsList: DashboardViewType[],
  selectedDashboardName: string
) => {
  const viewName = selectedDashboardCustomViews.find(
    (view) => view.id === selectedDashboardView
  )?.viewName;
  if (viewName) {
    return selectedDashboardName + ' ' + viewName;
  }

  return (
    selectedDashboardName +
    ' ' +
    i18n.t(
      `dashNav.${
        dashboardViewsList.find(
          (navItem) => navItem.id === selectedDashboardView
        )?.title
      }`
    )
  );
};

/**
 * @function getDateFormatByProvider
 * @description Returns the date format based on the provider
 * @param provider provider for which the date format is fetched
 * @returns String date format
 */
export const getBillingPeriodDateFormatByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return YEAR_MONTH_WITHOUT_ZERO;

    case PROVIDER.AWS:
      return YEAR_HYPHEN_MONTH;

    case PROVIDER.GCP:
    default:
      return YEAR_MONTH_WITHOUT_SEPARATOR;
  }
};

export const getQueryFieldByDataSource = (
  dataSource: string,
  field: string,
  isFocusSchema: boolean | undefined
) => {
  return dataSource === MY_DASHBOARD_TYPES.IMPORTS && isFocusSchema
    ? CSP_TO_FOCUS_FIELD_MAP[field]
    : field;
};
