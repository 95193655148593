import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ControlComponent from 'components/DashboardControl';
import DropdownCheckbox from 'components/DropdownCheckbox';
import { INPUT_SIZE } from 'constants/appearance';
import { CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT } from 'constants/userConsole';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { RulesetsType } from 'pages/RuleEnginePage/types';
import { selectDashboard } from 'redux/dashboardSlice';
import { isDashboardWithStaticData } from 'utils/dashboardUtils';

import { CHART_CRITERIA_LIST } from '../../constants';
import { fetchRulesets } from './utils';

type CostRecomSummaryDashboardControlsProps = {
  chartCriteriaStates: {
    chartCriteriaFilters: string[];
    setChartCriteriaFilters: (value: string[]) => void;
  };
  rulesetStates: {
    selectedRulesets: RulesetsType[];
    setSelectedRulesets: (value: RulesetsType[]) => void;
    rulesetsRequestStatus: string;
    setRulesetsRequestStatus: (value: string) => void;
  };
};

const CostRecomSummaryDashboardControls = ({
  chartCriteriaStates,
  rulesetStates,
}: CostRecomSummaryDashboardControlsProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);

  const [rulesets, setRulesets] = useState<RulesetsType[]>([]);

  useEffect(() => {
    if (
      !isDashboardWithStaticData(selectedDashboard) &&
      selectedDashboard!.connectorProvider !== PROVIDER.AZURE
    )
      fetchRulesets(
        setRulesets,
        rulesetStates.setRulesetsRequestStatus,
        selectedDashboard!
      );
    else rulesetStates.setRulesetsRequestStatus(REQUEST_STATUS.SUCCESS);
  }, []);

  /**
   * @function getMinimizedRulesetText
   * @description function to get minimized ruleset text for control component
   * @returns minimized ruleset text
   */
  const getMinimizedRulesetText = () => {
    const allSelected = rulesetStates.selectedRulesets
      .map((ruleset) => ruleset.ruleSetName)
      .join(', ')
      .trim();
    if (allSelected)
      return (
        allSelected.substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...'
      );
    return t('gcpRecommendationSummary.noRulesetsApplied');
  };

  return (
    <ControlComponent
      filters={[
        {
          title: t('listByDimensions'),
          filter: (
            <DropdownCheckbox
              itemOptions={CHART_CRITERIA_LIST}
              selectedItems={chartCriteriaStates.chartCriteriaFilters}
              setSelectedItems={chartCriteriaStates.setChartCriteriaFilters}
              designVersion2
              size={INPUT_SIZE.SMALL}
            />
          ),
          minimizedText:
            chartCriteriaStates.chartCriteriaFilters.length ===
            CHART_CRITERIA_LIST.length
              ? t('all')
              : CHART_CRITERIA_LIST.filter((each) =>
                  chartCriteriaStates.chartCriteriaFilters.includes(each.value)
                )
                  .map((each) => each.title)
                  .join(', '),
          onClear: () => chartCriteriaStates.setChartCriteriaFilters([]),
        },
        ...(selectedDashboard!.connectorProvider !== PROVIDER.AZURE
          ? [
              {
                title: t('gcpRecommendationSummary.ruleset'),
                filter: (
                  <DropdownCheckbox
                    itemOptions={rulesets.map((ruleset) => ({
                      title: ruleset.ruleSetName,
                      value: ruleset.rulesetId ?? '',
                    }))}
                    selectedItems={rulesetStates.selectedRulesets.map(
                      (ruleset) => ruleset.rulesetId ?? ''
                    )}
                    setSelectedItems={(items) => {
                      rulesetStates.setSelectedRulesets(
                        rulesets.filter((ruleset) =>
                          items.includes(ruleset.rulesetId ?? '')
                        )
                      );
                    }}
                    loading={
                      rulesetStates.rulesetsRequestStatus ===
                      REQUEST_STATUS.PROCESSING
                    }
                    designVersion2
                    size={INPUT_SIZE.SMALL}
                  />
                ),
                minimizedText: getMinimizedRulesetText(),
                onClear: () => rulesetStates.setSelectedRulesets([]),
              },
            ]
          : []),
      ]}
    />
  );
};

export default CostRecomSummaryDashboardControls;
