import { REQUEST_STATUS } from 'constants/requestBody';
import { getAllRulesets } from 'pages/RuleEnginePage/services';
import { RulesetsType } from 'pages/RuleEnginePage/types';
import { DashboardListType } from 'types/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';

/**
 * @function fetchRulesets
 * @description Function to fetch the rulesets data
 * @param setRulesets function to set the rulesets data
 * @param setRulesetsRequestStatus function to set the rulesets request status
 * @param selectedDashboard selected dashboard data
 */
export const fetchRulesets = (
  setRulesets: (rulesets: RulesetsType[]) => void,
  setRulesetsRequestStatus: (status: string) => void,
  selectedDashboard: DashboardListType
) => {
  setRulesetsRequestStatus(REQUEST_STATUS.PROCESSING);
  const params = {
    provider: selectedDashboard.connectorProvider,
  };
  getAllRulesets(params)
    .then((res: any) => {
      setRulesets(res.data.responseData.content);
      setRulesetsRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRulesetsRequestStatus);
      setRulesets([]);
    });
};
