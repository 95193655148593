import DashboardComponent from 'components/DashboardComponent';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { formatNumberToCompact } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';

import './index.scss';

type ComparisonCardProps = {
  value: number;
  valueMinimumFractionDigits?: number;
  valueMaximumFractionDigits?: number;
  index: number;
  heading: string;
  valueSuffix?: string;
  valuePrefix?: string;
  comparisonValue?: number;
  comparisonFrom?: string;
  requestStatus: string[];
  errorMessage?: string;
};

const ComparisonCard = ({
  value,
  valueMinimumFractionDigits,
  valueMaximumFractionDigits,
  index,
  heading,
  valueSuffix,
  valuePrefix,
  comparisonValue,
  comparisonFrom,
  requestStatus,
  errorMessage,
}: ComparisonCardProps) => {
  const getComponent = () => {
    return (
      <div className="flex flex-column flex-gap-16">
        <div className="cost-details flex flex-column flex-gap-8">
          <div className="cost-type font-caption-bold">{heading}</div>
          <div className="cost font-heading-5">
            {valuePrefix}
            {isFinite(value)
              ? formatNumberToCompact(
                  value,
                  valueMaximumFractionDigits,
                  valueMinimumFractionDigits
                )
              : 0}
            {valueSuffix}
          </div>
        </div>
        {comparisonValue !== undefined && isFinite(comparisonValue) && (
          <div className="footer flex flex-align-items-center flex-fit">
            <div
              className={`comparison font-comparison-label ${
                comparisonValue > 0 ? 'positive' : 'negative'
              } flex flex-center`}
            >
              {comparisonValue > 0 ? (
                <Icon iconName={ICONS.ARROW_RIGHT_UP_LINE} />
              ) : (
                <Icon iconName={ICONS.ARROW_RIGHT_DOWN_LINE} />
              )}
              <span>
                {comparisonValue > 0 ? `+${comparisonValue}` : comparisonValue}%
              </span>
            </div>
            {comparisonFrom && (
              <div className="font-comparison-label">{comparisonFrom}</div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`cost-comparison-card flex-fit ${
        index !== 0 && 'vertical-divider'
      }`}
      id="cost-card"
      data-testid="cost-card"
    >
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray(requestStatus)}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default ComparisonCard;
