import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TAGS_AND_LABELS_COMPONENTS,
  TAGS_OBJECTIVES,
} from 'pages/TagsAndLabelsPage/constants';
import { ConnectionListType } from 'types/dashboard';
import { RootState } from './store';

export interface TagsAndLabelsState {
  tagsAndLabelComponent: string;
  selectedTagsObjective: string;
  selectedTagConnection: ConnectionListType | undefined;
  selectedBillingAccount: string;
}

const initialState: TagsAndLabelsState = {
  tagsAndLabelComponent: TAGS_AND_LABELS_COMPONENTS.SELECT_PROVIDER,
  selectedTagsObjective: TAGS_OBJECTIVES.CREATE_TAG_SCHEMA,
  selectedTagConnection: undefined,
  selectedBillingAccount: '',
};

export const tagsAndLabelsSlice = createSlice({
  name: 'tagsAndLabels',
  initialState,
  reducers: {
    setTagsAndLabelComponent: (state, action: PayloadAction<string>) => {
      state.tagsAndLabelComponent = action.payload;
    },
    setSelectedTagsObjective: (state, action: PayloadAction<string>) => {
      state.selectedTagsObjective = action.payload;
    },
    setSelectedTagConnection: (
      state,
      action: PayloadAction<ConnectionListType | undefined>
    ) => {
      state.selectedTagConnection = action.payload;
    },
    setSelectedBillingAccount: (state, action: PayloadAction<string>) => {
      state.selectedBillingAccount = action.payload;
    },
  },
});

export const tagsAndLabels = (state: RootState) => state.tagsAndLabels;
export const {
  setTagsAndLabelComponent,
  setSelectedTagsObjective,
  setSelectedTagConnection,
  setSelectedBillingAccount,
} = tagsAndLabelsSlice.actions;
export default tagsAndLabelsSlice.reducer;
