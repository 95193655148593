import { createElement, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  selectNavMenu,
  setExpandedMenu,
  setSelectedParentMenu,
  setSubMenuOpen,
} from 'redux/activeNavMenuSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import { selectChatBot, setIsChatBoxOpen } from 'redux/chatBotSlice';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { NavigationMenuDataState } from 'types/navigationMenu';
import { NAVIGATION_MENU_ID } from 'constants/navigationMenu';
import {
  getFirstSubSection,
  findFirstSubMenuWithSubsection,
} from 'utils/navigationMenu';
import { ICONS_SIZE } from 'constants/icons';

type ParentMenuItemProps = {
  menu: NavigationMenuDataState;
  selectedMenuRef: RefObject<HTMLDivElement>;
  showActiveBlink?: boolean;
};

const ParentMenuItem = ({
  menu,
  selectedMenuRef,
  showActiveBlink,
}: ParentMenuItemProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permissions } = useSelector(userAuthorization);
  const { isSubMenuOpen, showMenuWithTitle, selectedParentMenu } =
    useSelector(selectNavMenu);
  const { isChatBoxOpen } = useSelector(selectChatBot);

  const moveScrollToView = () => {
    selectedMenuRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  /**
   * @function onClickParentMenu
   * @description Onclick callback for parent menu
   */
  const onClickParentMenu = () => {
    if (menu.id === NAVIGATION_MENU_ID.CHAT_BOT) {
      dispatch(setIsChatBoxOpen(!isChatBoxOpen));
      return;
    }

    dispatch(setExpandedMenu(menu));
    dispatch(setSubMenuOpen(true));

    if (
      menu.id !== NAVIGATION_MENU_ID.ALL_FEATURES &&
      selectedParentMenu !== menu.id
    ) {
      const path = getFirstSubSection(menu, permissions)?.rootPath;
      if (path) {
        navigate(path);
      }

      const nestedSubMenuPath = getFirstSubSection(
        findFirstSubMenuWithSubsection(menu),
        permissions
      )?.rootPath;
      if (nestedSubMenuPath) {
        navigate(nestedSubMenuPath);
      }
    }

    if (menu.id === NAVIGATION_MENU_ID.ALL_FEATURES) {
      dispatch(setSelectedParentMenu(NAVIGATION_MENU_ID.ALL_FEATURES));
    }

    if (isSubMenuOpen && selectedParentMenu === menu.id) {
      dispatch(setSubMenuOpen(false));
    }

    moveScrollToView();
  };

  /**
   * @function getMenuIcon
   * @description Function to return the menu icon
   * @returns icon component.
   */
  const getMenuIcon = menu.icon ? (
    createElement(menu.icon, {
      className: 'menu-icon stretch',
    })
  ) : (
    <Icon iconName={menu.iconName} size={ICONS_SIZE.XL} className="menu-icon" />
  );

  return (
    <AccessibleDiv
      className={`parent-menu-container cursor-pointer flex flex-align-items-center ${
        selectedParentMenu === menu.id && 'selected-menu'
      } ${isSubMenuOpen && 'with-sub-menu'}`}
      ref={selectedParentMenu === menu.id ? selectedMenuRef : undefined}
      onClick={onClickParentMenu}
      key={menu.id}
      data-testid={`parent-menu-container-${menu.id}`}
    >
      <div className="flex flex-align-items-center flex-fit">
        <div className="parent-menu-icon flex flex-center">
          <span>{getMenuIcon}</span>
        </div>
        {(showMenuWithTitle || isSubMenuOpen) && (
          <div className="menu-title font-button">
            {t(`navigationMenu.${menu.title}`)}
          </div>
        )}
      </div>
      {showActiveBlink && (
        <div className="blink-circle" data-testid="chat-blink-circle" />
      )}
    </AccessibleDiv>
  );
};

export default ParentMenuItem;
