import { useState } from 'react';
import { Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SelectDropdown from 'components/Select';
import { FormLabel } from 'components/FormLabel';
import { CONNECTION_PROVIDER_LIST_DATA } from 'constants/providerConnectionData';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { validateEmptyField } from 'utils/validations';

const MigratedFromCloudProviderInput = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection } = useSelector(cloudConnection);

  const [cloudProviderRequredValidation, setCloudProviderRequredValidation] =
    useState('');

  const providerOptions = (
    <>
      {CONNECTION_PROVIDER_LIST_DATA.map((provider) => (
        <Select.Option key={provider.provider} value={provider.provider}>
          {t(`providerPageList.${provider.header}`)}
        </Select.Option>
      ))}
    </>
  );

  return (
    <>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`connectionCSPForm.isItMigratedFromOtherCloudConnection`)}
          required={true}
        />
        <Radio.Group
          buttonStyle="solid"
          value={currentConnection?.migrated || false}
          onChange={(e) => {
            if (!e.target.value) {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  migrated: e.target.value,
                  migrationProvider: '',
                })
              );
              setCloudProviderRequredValidation('');
              return;
            }
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                migrated: e.target.value,
              })
            );
          }}
        >
          <Radio.Button key="Yes" value={true}>
            {t('radioYes')}
          </Radio.Button>
          <Radio.Button key="No" value={false}>
            {t('radioNo')}
          </Radio.Button>
        </Radio.Group>
      </div>
      {currentConnection?.migrated && (
        <div className="form-item flex flex-column">
          <FormLabel
            title={t(`connectionCSPForm.migratedCloudProvider`)}
            required={true}
          />
          <SelectDropdown
            value={currentConnection?.migrationProvider}
            menu={providerOptions}
            onSelect={(value: string) => {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  migrationProvider: value,
                })
              );
              setCloudProviderRequredValidation('');
            }}
            onBlur={() =>
              validateEmptyField(
                currentConnection?.migrationProvider,
                t(`connectionCSPForm.migratedCloudProvider`),
                setCloudProviderRequredValidation
              )
            }
            designVersion2
          />
          <span
            style={{
              display: `${cloudProviderRequredValidation ? 'inline' : 'none'}`,
            }}
            className="font-validation-error"
          >
            {cloudProviderRequredValidation}
          </span>
        </div>
      )}
    </>
  );
};

export default MigratedFromCloudProviderInput;
