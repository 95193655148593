import { Radio, Skeleton } from 'antd';

function RadioGroup({ ...remainingProps }: any) {
  if (remainingProps.loading) {
    return (
      <Skeleton
        active
        paragraph={{ rows: remainingProps.rows || 1 }}
        title={false}
      />
    );
  }

  return (
    <Radio.Group
      {...remainingProps}
      rootClassName={`${
        remainingProps.optionType === 'button' && 'no-custom-style'
      } ${remainingProps.rootClassName}`}
    />
  );
}

export default RadioGroup;
