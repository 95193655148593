import i18n from 'i18n';
import moment from 'moment';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  SNOWFLAKE_TABLES,
} from 'constants/requestBody';
import { HYPHEN_DATE_FORMAT } from 'utils/date';

export const TopDbByCostExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('snowflakeDashboards.databases'),
    key: 'name',
    dataKey: 'name',
    width: 30,
  },
  {
    header:
      i18n.t('snowflakeDashboards.databaseUsage') +
      ' (' +
      i18n.t('snowflakeDashboards.tb') +
      ')',
    key: 'dbCost',
    dataKey: 'dbCost',
    width: 20,
  },
  {
    header:
      i18n.t('snowflakeDashboards.failsafeUsage') +
      ' (' +
      i18n.t('snowflakeDashboards.tb') +
      ')',
    key: 'fsCost',
    dataKey: 'fsCost',
    width: 20,
  },
];

export const TotalStorageUsageQuery = {
  queryDto: {
    columns: [
      {
        label: 'value',
        field: QUERY_FIELDS.AVG_STAGE_BYTES,
      },
    ],
    aggregators: [
      {
        label: 'value',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        conjunctToNextGroup: CONJUNCTIONS.AND,
        filters: [
          {
            field: QUERY_FIELDS.USAGE_DATE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment()
              .subtract(11, 'months')
              .startOf('month')
              .format(HYPHEN_DATE_FORMAT),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.USAGE_DATE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: moment().endOf('month').format(HYPHEN_DATE_FORMAT),
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
    cached: true,
  },
  table: SNOWFLAKE_TABLES.STAGE_STORAGE_USAGE_HISTORY,
};

export const TotalStorageCostQuery = {
  queryDto: {
    columns: [
      {
        label: 'value',
        field: QUERY_FIELDS.CREDITS,
      },
    ],
    aggregators: [
      {
        label: 'value',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        conjunctToNextGroup: CONJUNCTIONS.AND,
        filters: [
          {
            field: QUERY_FIELDS.USAGE_DATE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment()
              .subtract(11, 'months')
              .startOf('month')
              .format(HYPHEN_DATE_FORMAT),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.USAGE_DATE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: moment().endOf('month').format(HYPHEN_DATE_FORMAT),
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
    cached: true,
  },
  table: SNOWFLAKE_TABLES.STORAGE_DAILY_HISTORY,
};
