import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SuccessIcon } from 'assets/icons';
import ProviderList from 'components/ConnectionProviderList';
import SuccessComponent from 'components/SuccessComponent';
import ErrorComponent from 'components/ErrorComponent';
import {
  setTicketIntegrationComponent,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';
import { TICKET_PROVIDER } from 'constants/cloudProviders';
import {
  TicketIntegrationComponents,
  TICKET_PROVIDER_LIST_DATA,
} from './constants';
import TicketIntegrationForm from './components/TicketIntegrationForm';

const TicketIntegrationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ticketIntegrationComponent } = useSelector(ticketIntegration);

  const {
    TICKET_PROVIDER_LIST,
    TICKET_INTEGRATION_FORM,
    SUCCESS_PAGE,
    ERROR_PAGE,
  } = TicketIntegrationComponents;

  const getComponent = () => {
    switch (ticketIntegrationComponent) {
      case TICKET_PROVIDER_LIST:
        return (
          <div>
            <ProviderList
              heading={t('providerPageList.itsmIntegrationSetup')}
              subHeading={t('providerPageList.ticketProviderSubHeading')}
              handleContinueButtonClick={() => {
                dispatch(
                  setTicketIntegrationComponent(TICKET_INTEGRATION_FORM)
                );
              }}
              listData={TICKET_PROVIDER_LIST_DATA}
              disabledList={[TICKET_PROVIDER.JIRA]}
            />
          </div>
        );
      case TICKET_INTEGRATION_FORM:
        return <TicketIntegrationForm />;

      case SUCCESS_PAGE:
        return (
          <SuccessComponent
            mainTitle={t('ticketIntegrationLabels.successPageLabels.header')}
            subTitle={t('ticketIntegrationLabels.successPageLabels.subHeader')}
            buttonTitle={t(
              'ticketIntegrationLabels.successPageLabels.goToTicketIntegration'
            )}
            onHandleButtonClick={() => {
              dispatch(setTicketIntegrationComponent(TICKET_PROVIDER_LIST));
            }}
            icon={<SuccessIcon />}
          />
        );
      case ERROR_PAGE:
        return (
          <ErrorComponent
            mainTitle={t('ticketIntegrationLabels.errorPageLabels.header')}
            buttonTitle={t('ticketIntegrationLabels.errorPageLabels.tryAgain')}
            onHandleButtonClick={() =>
              dispatch(setTicketIntegrationComponent(TICKET_PROVIDER_LIST))
            }
          />
        );
    }
  };

  return (
    <div className="page-content vertical-margin-24">{getComponent()}</div>
  );
};

export default TicketIntegrationPage;
