import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sum } from 'lodash';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import PieDonutChart from 'components/PieChartAnt';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { evaluateRequestArray } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { CHART_AXIS_LABEL_LENGTH_LIMIT } from 'constants/userConsole';
import { ColouredCostByServiceType } from 'types/dataTypes';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { TopServicesColumns } from '../../constants';
import { getExcelServiceAggregatorFilterKey } from '../../utils';

type TopServicesProps = {
  data: ColouredCostByServiceType[];
  showExpandedGraphModal: boolean;
  startDate: string;
  endDate: string;
  selectedServiceAggregators: string[];
  pdfView: boolean;
  requestStatus: string[];
  isTableViewSwitch: boolean;
  isTableView: boolean;
  setTableView: (value: boolean) => void;
};

const TopServices = ({
  data,
  showExpandedGraphModal,
  startDate,
  endDate,
  selectedServiceAggregators,
  pdfView,
  requestStatus,
  isTableViewSwitch,
  isTableView,
  setTableView,
}: TopServicesProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const getComponent = () =>
    isTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={data.map((item, index) => {
            return {
              ...item,
              key: index,
            };
          })}
          columns={TopServicesColumns}
          scroll={{ y: 220 }}
          designVersion2
        />
      </div>
    ) : (
      <PieDonutChart
        data={data.map((item, index) => {
          return {
            ...item,
            service:
              !showExpandedGraphModal && !pdfView
                ? item.service.substring(0, CHART_AXIS_LABEL_LENGTH_LIMIT) +
                  '...'
                : item.service || '',
            key: index,
          };
        })}
        angleField="cost"
        colorField="service"
        isDonut
        disableAnimation={pdfView}
        showLegends={!pdfView}
        additionalClassNames={`${pdfView && 'pdf-wrapper pdf-pie-wrapper'}`}
        colorOverride={data.map((item) => item.color)}
        prefixSymbol={currencySymbol}
      />
    );

  return (
    <div
      className="flex-column top-service-graph graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('projectDeepDiveSummary.costTable.projects.topServices')}
        headingComponent={
          !isTableView ? (
            <div className="flex flex-column flex-gap-16">
              <span className="graph-heading font-caption-bold">
                {t('projectDeepDiveSummary.costTable.projects.topServices')}
              </span>
              <span className="font-graph-total">
                {currencySymbol}
                {numberCommaSeparator(sum(data.map((value) => value.cost)))}
              </span>
            </div>
          ) : (
            ''
          )
        }
        graphName="top-services"
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        isTableViewSwitch={isTableViewSwitch}
        isTableView={isTableView}
        setIsTableView={setTableView}
        excelData={getChartExcelExportData(
          t('projectDeepDiveSummary.costTable.projects.topServices'),
          data,
          TopServicesColumns,
          {
            connectionName: selectedDashboard!.connectorName,
            [getExcelServiceAggregatorFilterKey(
              selectedDashboard!.connectorProvider
            )]: selectedServiceAggregators.join(', '),
            startDate,
            endDate,
          }
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default TopServices;
