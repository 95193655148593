import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { xor } from 'lodash';
import { message } from 'antd';
import { useSelector } from 'react-redux';

import { selectTagCompliance } from 'redux/tagComplianceSlice';
import { selectTheme } from 'redux/themeSlice';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Button from 'components/Button';
import AccessibleDiv from 'components/AccessibleDiv';
import { BUTTON_TYPE } from 'constants/appearance';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getValidationStyle } from 'utils/validations';
import { LabelValueType } from 'pages/TagCompliancePage/types';
import { addTagCompliance } from 'pages/TagCompliancePage/services';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

type AddRequiredTagsDrawerProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  allTags: LabelValueType[];
  persistedTags: string[];
  onClickAddOrUpdate: Function;
};

const AddRequiredTagsDrawer = ({
  show,
  setShow,
  allTags,
  persistedTags,
  onClickAddOrUpdate,
}: AddRequiredTagsDrawerProps) => {
  const { t } = useTranslation();
  const { selectedConnection } = useSelector(selectTagCompliance);
  const { theme } = useSelector(selectTheme);
  const searchRef = useRef<any>(null);

  const [selectedTags, setSelectedTags] = useState<string[]>(persistedTags);
  const [searchKey, setSearchKey] = useState('');
  const [addOrUpdateTagsReqStatus, setAddOrUpdateTagsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    setSelectedTags(persistedTags);
  }, [persistedTags]);

  /**
   * @function onCLickAddOrUpdateCta
   * @description Callback function for add or update selected tags
   */
  const onCLickAddOrUpdateCta = () => {
    if (xor(persistedTags, selectedTags).length === 0) {
      setValidationMessage(t('tagCompliance.noChangesInTags'));
      return;
    }

    setAddOrUpdateTagsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection!.connectorId,
      tagNames: selectedTags,
    };

    addTagCompliance(requestBody)
      .then((res: any) => {
        if (res.status === 200) {
          setAddOrUpdateTagsReqStatus(REQUEST_STATUS.SUCCESS);
          message.success(t('tagCompliance.tagsAddedSuccessful'));
          onClickAddOrUpdate();
          setShow(false);
          return;
        }

        setValidationMessage(t('tagCompliance.errorAddingTags'));
        setAddOrUpdateTagsReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setAddOrUpdateTagsReqStatus);
        setValidationMessage(t('tagCompliance.errorAddingTags'));
      });
  };

  /**
   * @function onClickRemoveTag
   * @description Function to remove the selected tag
   * @param tag tag to be removed from the selected list of tags
   */
  const onClickRemoveTag = (tag: string) => {
    setSelectedTags(selectedTags.filter((tagItem) => tagItem !== tag));
    setValidationMessage('');
  };

  const isTagAdded = (tag: LabelValueType) => {
    return selectedTags.includes(tag.value);
  };

  const onClickTag = (tag: LabelValueType) => {
    if (!isTagAdded(tag)) {
      setSelectedTags([...selectedTags, tag.value]);
      setSearchKey('');
      setValidationMessage('');
    }
  };

  /**
   * @function getTagsDropdownRender
   * @description Function to return the tags dropdown render
   * @returns JSX element to render in the dropdown
   */
  const getTagsDropdownRender = () => {
    searchRef?.current?.focus();
    return (
      <div className="tags-dropdown flex flex-column flex-gap-8">
        <Input
          value={searchKey}
          className="full-width"
          placeholder={t('tagCompliance.searchTags')}
          type="search"
          onChange={(e: any) => {
            setSearchKey(e.target.value);
          }}
          ref={searchRef}
        />
        <div className="flex flex-column">
          <div className="flex flex-column flex-gap-8">
            {allTags
              .filter((tag) =>
                tag.label.toLowerCase().includes(searchKey.toLowerCase().trim())
              )
              .map((tag) => (
                <AccessibleDiv
                  className={`tag-key font-caption flex flex-align-items-center flex-space-between ${
                    isTagAdded(tag)
                      ? 'disabled cursor-disabled'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => onClickTag(tag)}
                  key={tag.value}
                  data-testId={`tag-key-${tag.value}`}
                >
                  <div className="key-label">{tag.label}</div>
                  {isTagAdded(tag) && (
                    <Icon
                      iconName={ICONS.CHECK_LINE}
                      color={theme.buttonIconColor}
                    />
                  )}
                </AccessibleDiv>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <DrawerComponent
      className="required-tags-drawer"
      open={show}
      title={t('tagCompliance.mandatoryTags')}
      onClose={() => setShow(false)}
      footer={
        <div>
          <div
            style={{
              display: getValidationStyle(validationMessage),
            }}
            className="error font-validation-error"
          >
            {validationMessage}
          </div>
          <div className="flex flex-gap-8 flex-end">
            <Button
              title={t('tagCompliance.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShow(false)}
            />
            <Button
              title={
                persistedTags.length > 0
                  ? t('tagCompliance.update')
                  : t('tagCompliance.add')
              }
              onClick={onCLickAddOrUpdateCta}
              loading={addOrUpdateTagsReqStatus === REQUEST_STATUS.PROCESSING}
            />
          </div>
        </div>
      }
      afterOpenChange={(open: boolean) => {
        if (!open) {
          setSelectedTags(persistedTags);
        }
      }}
      dataTestId="required-tags-drawer"
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel title={t('tagCompliance.chooseOrAddTags')} required />
          <SelectDropdown
            placeholder={t('tagCompliance.chooseOrAddTags')}
            className="select-item"
            dropdownRender={getTagsDropdownRender}
            designVersion2
            dataTestId="tags-dropdown"
          />
        </div>
        <div className="flex flex-column flex-gap-8">
          <div className="flex flex-align-items-center flex-space-between">
            <FormLabel title={t('tagCompliance.selectedTags')} />
            <span className="selected-tags-count font-small-bold flex flex-center">
              {selectedTags.length}
            </span>
          </div>
          {selectedTags.map((tag) => (
            <div
              className="selected-tag flex flex-align-items-center flex-space-between"
              key={tag}
              data-testid="selected-tag"
            >
              <div className="font-caption">
                {allTags.find((item) => item.value === tag)?.label ?? tag}
              </div>
              <Icon
                className="remove-icon"
                iconName={ICONS.DELETE_BIN_LINE}
                size={ICONS_SIZE.SM}
                onClick={() => onClickRemoveTag(tag)}
                dataTestId="remove-cta"
              />
            </div>
          ))}
        </div>
      </section>
    </DrawerComponent>
  );
};

export default AddRequiredTagsDrawer;
