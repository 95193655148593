import i18n from 'i18n';
import moment from 'moment';

import { store } from 'redux/store';
import { DateRangeFilter, MonthlyCostType } from 'types/dataTypes';
import { addIndexAndCommaSeparator } from 'pages/CostOptimizationInsightsPage/utils';
import { insertIndex } from 'utils/dataFormatterUtils';

import { DATE_INTERVALS } from './constants';
import { NamespaceUsageCostType, ClusterCostType } from './types';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

/**
 * @function getCostTrendExportColumns
 * @description Function to return the cost trend export columns
 * @returns List of export columns
 */
export const getCostTrendExportColumns = () => {
  return [
    {
      header: '#',
      key: 'slNo',
      dataKey: 'slNo',
      width: 10,
      alignment: 'center',
    },
    {
      header: i18n.t('containerInsight.usageMeteringLabels.month'),
      key: 'month',
      dataKey: 'month',
      width: 20,
    },
    {
      header: i18n.t('containerInsight.usageMeteringLabels.cost', {
        currencySymbol: currencySymbol,
      }),
      key: 'cost',
      dataKey: 'cost',
      width: 20,
      alignment: 'right',
    },
  ];
};

/**
 * @function getNamespaceUsageCostExportColumns
 * @description Function to return the namespace usage and cost export columns
 * @returns List of export columns
 */
export const getNamespaceUsageCostExportColumns = () => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.namespace'),
    key: 'namespace',
    dataKey: 'namespace',
    width: 20,
  },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.costAfterCredit', {
      currencySymbol: currencySymbol,
    }),
    key: 'costAfterCredit',
    dataKey: 'costAfterCredit',
    width: 20,
    alignment: 'right',
  },
];

/**
 * @function getCostTrendExcelData
 * @description Function to return the cost trend excel data
 * @param data List of cost trend data
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getCostTrendExcelData = (
  data: MonthlyCostType[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.costTrend'),
    columns: getCostTrendExportColumns(),
    data: addIndexAndCommaSeparator(data),
    filters: filters,
  };
};

/**
 * @function getNamespaceUsageCostExcelData
 * @description Function to return the namespace usage and cost excel data
 * @param namespaceUsageCostData List of namespace usage and cost data
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getNamespaceUsageCostExcelData = (
  namespaceUsageCostData: NamespaceUsageCostType[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.namespaceUsageCost'),
    columns: getNamespaceUsageCostExportColumns(),
    data: insertIndex(namespaceUsageCostData),
    filters: filters,
  };
};

/**
 * @function getCostClusterCostExportColumns
 * @description Function to return the cluster cost export columns
 * @returns List of export columns
 */
export const getCostClusterCostExportColumns = () => {
  return [
    {
      header: '#',
      key: 'slNo',
      dataKey: 'slNo',
      width: 10,
      alignment: 'center',
    },
    {
      header: i18n.t('containerInsight.usageMeteringLabels.cluster'),
      key: 'cluster',
      dataKey: 'cluster',
      width: 20,
    },
    {
      header: i18n.t('containerInsight.usageMeteringLabels.cost', {
        currencySymbol: currencySymbol,
      }),
      key: 'cost',
      dataKey: 'cost',
      width: 20,
      alignment: 'right',
    },
  ];
};

/**
 * @function getClusterCostExcelData
 * @description Function to return the cluster cost excel data
 * @param clusterCostData List of cluster cost data
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getClusterCostExcelData = (
  clusterCostData: ClusterCostType[],
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.clusterCost'),
    columns: getCostClusterCostExportColumns(),
    data: addIndexAndCommaSeparator(clusterCostData),
    filters: filters,
  };
};

/**
 * @function getDateInterval
 * @description Function to return the date interval based on the number of days between the days
 * @param startDate starting date
 * @param endDate ending date
 * @returns string date interval (one of days, week, month, quarter or year)
 */
export const getDateInterval = (startDate: string, endDate: string) => {
  const start = moment(startDate);
  const end = moment(endDate);

  if (end.diff(start, DATE_INTERVALS.QUARTER) > 20) return DATE_INTERVALS.YEAR;

  if (end.diff(start, DATE_INTERVALS.MONTH) > 20) return DATE_INTERVALS.QUARTER;

  if (end.diff(start, DATE_INTERVALS.WEEK) > 20) return DATE_INTERVALS.MONTH;

  if (end.diff(start, DATE_INTERVALS.DAY) > start.daysInMonth())
    return DATE_INTERVALS.WEEK;

  if (end.diff(start, DATE_INTERVALS.DAY) === 0) return DATE_INTERVALS.HOUR;

  return DATE_INTERVALS.DAY;
};

/**
 * @function getDurationAxisLabel
 * @description Function to return the duration axis label based on the interval
 * @param dateRangeFilter date range filter
 * @returns string duration axis label
 */
export const getDurationAxisLabel = (dateRangeFilter: DateRangeFilter) => {
  const interval = getDateInterval(
    dateRangeFilter.startDate,
    dateRangeFilter.endDate
  );
  switch (interval) {
    case DATE_INTERVALS.HOUR:
      return i18n.t('containerInsight.usageMeteringLabels.hour');
    case DATE_INTERVALS.DAY:
      return i18n.t('containerInsight.usageMeteringLabels.date');
    case DATE_INTERVALS.WEEK:
      return i18n.t('containerInsight.usageMeteringLabels.week');
    case DATE_INTERVALS.QUARTER:
      return i18n.t('containerInsight.usageMeteringLabels.quarter');
    case DATE_INTERVALS.YEAR:
      return i18n.t('containerInsight.usageMeteringLabels.year');
    case DATE_INTERVALS.MONTH:
    default:
      return i18n.t('containerInsight.usageMeteringLabels.month');
  }
};
