import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { message } from 'antd';

import { selectPurchase } from 'redux/purchaseSlice';
import Button from 'components/Button';
import QuickActionMenu from 'components/QuickActionMenu';
import Table from 'components/Table';
import DeleteModal from 'components/DeleteModal';
import { SUCCESS_KEY } from 'components/DashboardTitle/constants';
import { BUTTON_SIZE } from 'constants/appearance';
import {
  PURCHASED_LIST_QUICKACTIONS,
  SPAndEC2RIStatus,
  SPAndEC2RIStatusLabels,
} from 'pages/PurchasePage/constants';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';
import { ERROR_KEY, REQUEST_STATUS } from 'constants/requestBody';
import { DATE_TIME_AM_PM } from 'utils/date';

import { AWSEc2RisType } from '../../types';
import { deleteQueuedAwsEc2Ri, getAllAwsEc2PurchasedRis } from '../../services';

const EC2Purchased = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedConnection } = useSelector(selectPurchase);

  const [ec2Ris, setEc2Ris] = useState<AWSEc2RisType[]>([]);
  const [fetchEc2RisRequestStatus, setFetchEc2RisRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [actionRowData, setActionRowData] = useState<AWSEc2RisType>();
  const [deleteRequestStatus, setDeleteRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    if (selectedConnection) {
      fetchAllEc2Ris();
    }
  }, [selectedConnection]);

  /**
   * @function fetchAllEc2Ris
   * @description Function to fetch and set all the AWS EC2 RIs
   */
  const fetchAllEc2Ris = () => {
    setFetchEc2RisRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      region: selectedConnection?.awsRegion,
    };

    getAllAwsEc2PurchasedRis(params)
      .then((res: any) => {
        setEc2Ris(res?.data?.responseData ?? []);
        setFetchEc2RisRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchEc2RisRequestStatus);
      });
  };

  /**
   * @function deleteQueuedEc2Ri
   * @description Function to delete the Queued AWS EC2 RI
   */
  const deleteQueuedEc2Ri = () => {
    setDeleteRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      region: selectedConnection?.awsRegion,
      riId: actionRowData?.createDate,
    };

    deleteQueuedAwsEc2Ri(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setDeleteRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success({
            content: t('purchasePage.reservedInstancesLabels.riDeleteSuccess'),
            key: SUCCESS_KEY,
          });
          return;
        }
        message.error({
          content: t('purchasePage.reservedInstancesLabels.riDeleteFailed'),
          key: ERROR_KEY,
        });
        setDeleteRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setDeleteRequestStatus);
        message.error({
          content: t('purchasePage.reservedInstancesLabels.riDeleteFailed'),
          key: ERROR_KEY,
        });
      });
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions clicks
   * @param record record for which the quick action is clicked
   */
  const handleQuickAction = (record: AWSEc2RisType) => {
    setActionRowData(record);
    setShowConfirmDeleteModal(true);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('purchasePage.reservedInstancesLabels.instanceType'),
      dataIndex: 'instanceType',
      key: 'instanceType',
    },
    {
      title: t('purchasePage.reservedInstancesLabels.scope'),
      dataIndex: 'scope',
      key: 'scope',
    },
    {
      title: t('purchasePage.reservedInstancesLabels.quantity'),
      dataIndex: 'instanceCount',
      key: 'instanceCount',
    },
    {
      title: t('purchasePage.cart.createdDate'),
      dataIndex: 'createDate',
      key: 'createDate',
      render: (text: string) => moment(text).format(DATE_TIME_AM_PM),
    },
    {
      title: t('purchasePage.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => (
        <span className={`status ${text.toLowerCase()}`}>
          {
            SPAndEC2RIStatusLabels.find(
              (item) => item.key === text.toLowerCase()
            )?.label
          }
        </span>
      ),
    },
    {
      title: t('purchasePage.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: any) => {
        return (
          <QuickActionMenu
            quickActions={PURCHASED_LIST_QUICKACTIONS}
            disabled={record.status !== SPAndEC2RIStatus.QUEUED}
            quickActionHandler={() => {
              handleQuickAction(record);
            }}
          />
        );
      },
      align: 'center',
      width: 80,
    },
  ];

  return (
    <>
      <section className="flex flex-column flex-gap-16">
        <div className="flex flex-align-items-center flex-space-between">
          <div className="form-header">
            {t('purchasePage.reservedInstancesLabels.purchasedResevedInstance')}
          </div>
          <Button
            title={t('purchasePage.reservedInstancesLabels.purchaseNewRi')}
            size={BUTTON_SIZE.SMALL}
            onClick={() => navigate(NAVIGATION_MENU_PATH.PURCHASE_EC2_RI)}
          />
        </div>
        <Table
          pagination={false}
          dataSource={ec2Ris.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={columns}
          loading={fetchEc2RisRequestStatus === REQUEST_STATUS.PROCESSING}
          designVersion2={true}
          scroll={{ y: 'calc(100vh - 20em)' }}
        />
      </section>
      {showConfirmDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowConfirmDeleteModal}
          showDeleteModal={showConfirmDeleteModal}
          deletionFunction={deleteQueuedEc2Ri}
          loading={deleteRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </>
  );
};

export default EC2Purchased;
