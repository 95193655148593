import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

import Input from 'components/Input';
import Pagination from 'components/Pagination';
import QuickActionMenu from 'components/QuickActionMenu';
import Table from 'components/Table';
import Empty from 'components/Empty';
import {
  ReportPermissions,
  ReportQuickActions,
  REPORT_PERMISSIONS_LIST,
  REPORT_QUICK_ACTIONS_LIST,
} from 'pages/ReportsPage/constants';
import { INPUT_SIZE } from 'constants/appearance';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { DATE_TIME_AM_PM } from 'utils/date';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ReportType } from 'pages/ReportsPage/types';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { GroupIcon } from 'assets/icons';
import { getAllReports, searchReports } from 'pages/ReportsPage/services';
import { onApiCallError } from 'utils/handleErrors';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { ReportsViewType } from 'pages/CreateReportPage/constants';

import UserData from '../UserData';

import './index.scss';

const Shared = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [reports, setReports] = useState<ReportType[]>([]);
  const [totalReportsCount, setTotalReportsCount] = useState(0);
  const [fetchReportsReqStatus, setFetchReportsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    let source = axios.CancelToken.source();
    setReports([]);
    getSharedReports(currentPage, source);

    return () => {
      source.cancel();
    };
  }, [searchKey]);

  /**
   * @function getSharedReports
   * @description function to fetch the shared reports
   * @param page page to fetch the data
   * @param cancelToken cancel token for cancelling the frequent requests
   */
  const getSharedReports = (
    page: number = currentPage,
    cancelToken?: CancelTokenSource
  ) => {
    setFetchReportsReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      page: page - 1,
      size: PAGINATION_SIZE,
      shared: true,
      key: searchKey || undefined,
    };
    (searchKey
      ? searchReports(params, cancelToken?.token)
      : getAllReports(params, cancelToken?.token)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setReports(res?.data?.responseData?.content);
          setTotalReportsCount(res?.data?.responseData?.totalElements);
          setFetchReportsReqStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setFetchReportsReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchReportsReqStatus);
      });
  };

  /**
   * @function navigateToOpenOrEditReport
   * @description Function to to navigate based on the view type
   * @param view vie type to which the navigation is made
   * @param record report type record
   */
  const navigateToOpenOrEditReport = (view: string, record: ReportType) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('view', view);
    queryParams.set('report', record.reportName);
    navigate(NAVIGATION_MENU_PATH.CREATE_REPORT + '?' + queryParams.toString());
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions
   */
  const handleQuickAction = (record: ReportType, action: string) => {
    switch (action) {
      case ReportQuickActions.OPEN:
        navigateToOpenOrEditReport(ReportsViewType.OPEN, record);
        break;

      case ReportQuickActions.EDIT:
        navigateToOpenOrEditReport(ReportsViewType.EDIT, record);
        break;
    }
  };

  /**
   * @function getSharedReportsQuickActions
   * @description Function to fetch the quick actions based on the permission
   * @param record report details
   * @returns list of quick actions
   */
  const getSharedReportsQuickActions = (record: ReportType) => {
    let actions = REPORT_QUICK_ACTIONS_LIST.filter(
      (item) => item.id !== ReportQuickActions.DELETE
    );

    if (record.reportPermission === ReportPermissions.READ) {
      actions = actions.filter((item) => item.id !== ReportQuickActions.EDIT);
    }

    return actions;
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: 50,
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
    },
    {
      title: t('reports.reportName'),
      dataIndex: 'reportName',
      key: 'reportName',
      render: (text: string) => (
        <span className="font-caption-bold">{text}</span>
      ),
    },
    {
      title: t('reports.connectionNameOrGroupName'),
      dataIndex: 'connectionName',
      key: 'connectionName',
      render: (_text: string, record: ReportType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={
              record.cloudProvider
                ? getProviderSmallLogo(record.cloudProvider)
                : GroupIcon
            }
            alt={`${record.cloudProvider} Logo`}
          />
          {record.connectionName ?? record.groupName}
        </div>
      ),
    },
    {
      title: t('reports.owner'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (_text: string, record: ReportType) =>
        record.createdBy && record.userName ? (
          <UserData user={{ email: record.createdBy, name: record.userName }} />
        ) : (
          '--'
        ),
    },
    {
      title: t('reports.permission'),
      dataIndex: 'reportPermission',
      key: 'reportPermission',
      render: (text: string) =>
        REPORT_PERMISSIONS_LIST.find((item) => item.id === text)?.title ?? '--',
    },
    {
      title: t('reports.dateCreated'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) =>
        text !== null ? moment(text).format(DATE_TIME_AM_PM) : '--',
    },
    {
      title: t('reports.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: ReportType) => {
        return (
          <QuickActionMenu
            quickActions={getSharedReportsQuickActions(record)}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      align: 'center' as const,
    },
  ];

  return (
    <div className="shared-report flex flex-column flex-fit">
      <Input
        type="search"
        value={searchKey}
        placeholder={t('reports.searchByReportName')}
        onChange={(e: any) => setSearchKey(e.target.value)}
        size={INPUT_SIZE.SMALL}
      />
      <Table
        pagination={false}
        rootClassName="table-section"
        dataSource={reports?.map((report, index) => {
          return { ...report, key: index + 1 };
        })}
        columns={columns}
        locale={{
          emptyText: fetchReportsReqStatus !== REQUEST_STATUS.PROCESSING && (
            <Empty
              description={
                fetchReportsReqStatus === REQUEST_STATUS.ERROR
                  ? t('graphErrorMessage')
                  : t('reports.noReportsAvailable')
              }
            />
          ),
        }}
        loading={fetchReportsReqStatus === REQUEST_STATUS.PROCESSING}
        designVersion2={true}
      />
      {totalReportsCount > PAGINATION_SIZE && (
        <Pagination
          rootClassName="flex flex-align-items-end flex-fit"
          current={currentPage}
          onChange={(page: number) => {
            setCurrentPage(page);
            getSharedReports(currentPage);
          }}
          total={totalReportsCount}
          defaultPageSize={PAGINATION_SIZE}
        />
      )}
    </div>
  );
};

export default Shared;
