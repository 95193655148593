import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DashboardComponent from 'components/DashboardComponent';
import ColumnChart from 'components/ColumnChart';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS } from 'constants/icons';
import {
  SAVINGS_TYPES,
  SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP,
} from 'pages/CostOptimizationInsightsPage/constants';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { evaluateRequestArray } from 'utils/handleErrors';
import { generateGraphColors } from 'utils/dashboardUtils';

type SummarizedCostSavingsProps = {
  graphHeading: string;
  graphName: string;
  data: any[];
  pdfView: boolean;
  requestStatus: string[];
  selectedSavingsType: string | undefined;
  setSelectedSavingsType: (val: string | undefined) => void;
  selectedRecommendationType: string | undefined;
  setSelectedRecommendationType: (val: string | undefined) => void;
};

const SummarizedCostSavings = ({
  graphHeading,
  graphName,
  data,
  pdfView,
  requestStatus,
  selectedSavingsType,
  setSelectedSavingsType,
  selectedRecommendationType,
  setSelectedRecommendationType,
}: SummarizedCostSavingsProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  /**
   * @function getComponent
   * @description Function to return the graph or table component
   * @returns JSX element containing graph or the table
   */
  const getComponent = () => {
    const colors = generateGraphColors(3);
    let colorOverride = [...colors];
    if (selectedSavingsType === SAVINGS_TYPES.POTENTIAL_SAVINGS)
      colorOverride = [colors[0]];
    else if (selectedSavingsType === SAVINGS_TYPES.COST_SAVINGS_IN_PROGRESS)
      colorOverride = [colors[1]];
    else if (selectedSavingsType === SAVINGS_TYPES.COST_SAVED)
      colorOverride = [colors[2]];

    return (
      <ColumnChart
        data={data}
        xField="category"
        yField="data"
        groupingField="label"
        columnColorOverride={colorOverride}
        xTitle={t(
          'costOptimizationInsight.statusDashboard.recommendationStatusTable.typeOfRecommendations'
        )}
        yTitle={t(
          'costOptimizationInsight.statusDashboard.costSavingsTable.cost',
          { currencySymbol: currencySymbol }
        )}
        prefixSymbol={currencySymbol}
        disableAnimation={pdfView}
        isColumnClickable
        isGroup
        onClickColumn={(e: any) => {
          setSelectedSavingsType(
            SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
              (map) => map.label === e?.data?.data?.label
            )?.type
          );
          setSelectedRecommendationType(e?.data?.data?.category);
        }}
        additionalTooltipContent={
          (!selectedRecommendationType || !selectedSavingsType) && (
            <span className="tooltip-deep-dive-label font-subHeader-small">
              {t('clickDeepDive')}
            </span>
          )
        }
      />
    );
  };

  const getHeadingComponent = () => {
    return selectedSavingsType ? (
      <div className="graph-heading flex flex-column">
        <AccessibleDiv
          className="flex flex-align-items-center cursor-pointer"
          onClick={() => setSelectedSavingsType(undefined)}
        >
          <Icon
            iconName={ICONS.ARROW_LEFT_S_LINE}
            className="previous-icon flex flex-end"
          />
          <div className="flex flex-column">
            {graphHeading}
            <span className="sub-title font-caption-bold">
              {t('clickBarInfo')}
            </span>
          </div>
        </AccessibleDiv>
      </div>
    ) : (
      <div className="flex flex-column">
        {graphHeading}
        <span className="sub-title font-caption-bold">{t('clickBarInfo')}</span>
      </div>
    );
  };

  return (
    <div
      className="graph-area summarized-cost-savings flex flex-column flex-gap-16"
      id="graph-container"
    >
      <GraphHeader
        heading={graphHeading}
        headingComponent={getHeadingComponent()}
        graphName={graphName}
        isDownloadable={!pdfView}
        isTableView={false}
        showExpandIcon={!pdfView}
        designVersion2
      />
      <div className="graph with-sub-title">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default SummarizedCostSavings;
