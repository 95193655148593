import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConnectionListType } from 'types/dashboard';
import {
  DEFAULT_VIEW_DROPDOWN_OPTIONS,
  COST_OPTIMIZATION_INSIGHTS_NAV,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  GcpRecommendationListType,
  AzureRecommendationListType,
  ViewListType,
  SelectedAwsRecommendationType,
  AwsRecommendationListType,
  ConsumptionManagementType,
} from 'pages/CostOptimizationInsightsPage/types';
import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';

import { RootState } from './store';

export interface CostOptimizationInsightsState {
  selectedCostOptimizationInsightsConnection: ConnectionListType | null;
  selectedCostOptimizationInsightsNav: string;
  costOptimizationInsightsConnectionList: ConnectionListType[];
  selectedRecommendations: (
    | GcpRecommendationListType
    | AwsRecommendationListType
    | AzureRecommendationListType
    | RecommendationList
  )[];
  viewList: ViewListType[] | undefined;
  currentRecommendationView: ViewListType;
  applicableRecommendations: string[];
  selectedAwsRecommendations: SelectedAwsRecommendationType | undefined;
  selectedGranulateService: ConsumptionManagementType | null;
  showConsolidatedDashboard: boolean;
  consolidatedExportData: any[];
}

const initialState: CostOptimizationInsightsState = {
  selectedCostOptimizationInsightsConnection: null,
  selectedCostOptimizationInsightsNav:
    COST_OPTIMIZATION_INSIGHTS_NAV.COST_SAVINGS_STATUS_SUMMARY,
  costOptimizationInsightsConnectionList: [],
  selectedRecommendations: [],
  viewList: undefined,
  currentRecommendationView: DEFAULT_VIEW_DROPDOWN_OPTIONS[0],
  applicableRecommendations: [],
  selectedAwsRecommendations: undefined,
  selectedGranulateService: null,
  showConsolidatedDashboard: false,
  consolidatedExportData: [],
};

export const costOptimizationInsightsSlice = createSlice({
  name: 'costOptimizationInsightsSlice',
  initialState,
  reducers: {
    setSelectedCostOptimizationInsightsConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.selectedCostOptimizationInsightsConnection = action.payload;
    },
    setSelectedCostOptimizationInsightsNav: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedCostOptimizationInsightsNav = action.payload;
    },
    setCostOptimizationInsightsConnectionList: (
      state,
      action: PayloadAction<ConnectionListType[]>
    ) => {
      state.costOptimizationInsightsConnectionList = action.payload;
    },
    setSelectedRecommendations: (
      state,
      action: PayloadAction<
        (
          | GcpRecommendationListType
          | AzureRecommendationListType
          | RecommendationList
        )[]
      >
    ) => {
      state.selectedRecommendations = action.payload;
    },
    setViewList: (state, action: PayloadAction<ViewListType[] | undefined>) => {
      state.viewList = action.payload;
    },
    setCurrentRecommendationView: (
      state,
      action: PayloadAction<ViewListType>
    ) => {
      state.currentRecommendationView = action.payload;
    },
    setApplicableRecommendations: (state, action: PayloadAction<string[]>) => {
      state.applicableRecommendations = action.payload;
    },
    setSelectedAwsRecommendations: (
      state,
      action: PayloadAction<SelectedAwsRecommendationType | undefined>
    ) => {
      state.selectedAwsRecommendations = action.payload;
    },
    setSelectedGranulateService: (state, action: PayloadAction<any>) => {
      state.selectedGranulateService = action.payload;
    },
    setShowConsolidatedDashboard: (state, action: PayloadAction<boolean>) => {
      state.showConsolidatedDashboard = action.payload;
    },
    setConsolidatedExportData: (state, action: PayloadAction<any[]>) => {
      state.consolidatedExportData = action.payload;
    },
  },
});

export const costOptimizationInsights = (state: RootState) =>
  state.costOptimizationInsights;
export const {
  setSelectedCostOptimizationInsightsConnection,
  setSelectedCostOptimizationInsightsNav,
  setCostOptimizationInsightsConnectionList,
  setSelectedRecommendations,
  setViewList,
  setCurrentRecommendationView,
  setApplicableRecommendations,
  setSelectedAwsRecommendations,
  setSelectedGranulateService,
  setShowConsolidatedDashboard,
  setConsolidatedExportData,
} = costOptimizationInsightsSlice.actions;
export default costOptimizationInsightsSlice.reducer;
