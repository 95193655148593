import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { LoadingIcon } from 'assets/icons';
import {
  setExpandedGraphName,
  setPdfGraphToDownload,
  setPdfDownloadMode,
  selectDashboard,
  setShowExpandGraphModal,
} from 'redux/dashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import { exportToExcel } from 'utils/exportToExcel';
import { ActionButtonTypes } from './types';

import './index.scss';

type GraphHeaderProps = {
  heading: string;
  dropdownHeading?: React.ReactNode;
  setExpandedGraphHeader?: (val: string) => void;
  graphName: string;
  excelData?: any;
  ignorePadding?: boolean;
  isDownloadable?: boolean;
  isTableViewSwitch?: boolean;
  isTableView?: boolean;
  setIsTableView?: (value: boolean) => void;
  showExpandIcon?: boolean;
  viewDropdown?: React.ReactNode;
  actionButtons?: ActionButtonTypes[];
  headingPrefix?: React.ReactElement;
};

const GraphHeader = ({
  heading,
  dropdownHeading,
  setExpandedGraphHeader,
  graphName,
  excelData,
  ignorePadding,
  isDownloadable,
  isTableViewSwitch,
  isTableView,
  setIsTableView,
  showExpandIcon = true,
  viewDropdown,
  actionButtons,
  headingPrefix,
}: GraphHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pdfDownloadMode, showExpandGraphModal } =
    useSelector(selectDashboard);
  const { theme } = useSelector(selectTheme);

  const [isDownloadingData, setIsDownloadingData] = useState(false);
  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  const onClickExpandGraphEvent = () => {
    setExpandedGraphHeader?.(heading);
    dispatch(setShowExpandGraphModal(true));
    dispatch(setExpandedGraphName(graphName));
  };

  /**
   * @function onClickDownload
   * @description function to handle download of chart or table view.
   * @returns either pdf or excel
   */
  const onClickDownload = () => {
    setIsDownloadingData(true);
    if (isTableView === undefined || isTableView) {
      setIsDownloadingData(false);
      return exportToExcel(heading, excelData);
    }
    dispatch(setPdfGraphToDownload(graphName));
    dispatch(setPdfDownloadMode(true));
  };

  return (
    <div
      className={`cost-optimization-insight-graph-header graph-heading-typography flex flex-column ${
        ignorePadding ? 'ignore-padding' : ''
      }`}
    >
      <div className="flex flex-space-between flex-gap-16 flex-wrap full-width">
        <div className="flex flex-gap-16 flex-center">
          {dropdownHeading ?? (
            <div className="graph-heading-text flex flex-align-items-center flex-gap-8">
              {headingPrefix}
              {heading}
            </div>
          )}
          {viewDropdown}
        </div>
        <div className="flex flex-center flex-gap-16 flex-wrap">
          {isDownloadable &&
            (isDownloadingData ? (
              <Icon
                icon={LoadingIcon}
                className="rotate download-icon"
                color={theme.buttonIconColor}
              />
            ) : (
              <Icon
                iconName={ICONS.DOWNLOAD_LINE}
                size={ICONS_SIZE.ONE_X}
                className="download-icon"
                id="pdf-hide"
                onClick={onClickDownload}
                color={theme.buttonIconColor}
              />
            ))}
          {actionButtons
            ?.filter((button) => button.permission)
            .map((item, index) => ({ ...item, key: index }))
            .map((button) => (
              <div className="flex" key={button.key}>
                {button.component}
              </div>
            ))}
          {isTableViewSwitch && (
            <div
              id="pdf-hide"
              className="table-view-title table-typography flex flex-align-items-center"
            >
              {t('tableView')}
              <Switch
                size="small"
                checked={isTableView}
                onChange={(value: boolean) => {
                  setIsTableView!(value);
                }}
              />
            </div>
          )}
          {showExpandIcon &&
            (showExpandGraphModal ? (
              <Icon
                iconName={ICONS.FULLSCREEN_EXIT_FILL}
                className="maximize-icon"
                onClick={() => {
                  dispatch(setShowExpandGraphModal(false));
                }}
                id="pdf-hide"
                color={theme.buttonIconColor}
                dataTestId="expand-cta-minimize"
              />
            ) : (
              <Icon
                iconName={ICONS.FULLSCREEN_FILL}
                className="maximize-icon"
                onClick={onClickExpandGraphEvent}
                id="pdf-hide"
                color={theme.buttonIconColor}
                dataTestId="expand-cta-maximize"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default GraphHeader;
