import { getGranulateLineChartsExcelData } from '../components/LineChartsComponents/GranulateUsageMeteringLineChart/utils';
import { getApplicationDetailsExcelData } from '../components/ApplicationDetails/utils';
import {
  ApplicationDetailsDataType,
  CpuUtilizationType,
  MemoryUtilizationType,
} from '../type';
import { getGranulatePieChartsExcelData } from '../components/PieChartComponents/GranulateUsageMeteringPieChart/utils';

/**
 * @function getUsageMeteringExcelData
 * @description Function to return the excel export data for usage metering dashboard
 * @param applicationDetails Object containing the Applications Details and the service name.
 * @param clusterUtilized Object containing the cluster utilizaed data.
 * @param clusterCost Object containing the cluster cost data.
 * @returns List of objects containing excel export details
 */
export const getUsageMeteringExcelData = (
  applicationDetails: {
    applicationDetailsData: ApplicationDetailsDataType[];
    filters: any[];
  },
  clusterUtilized: {
    clusterUtilizedTableData: any[];
    clusterUtilizedChartData: any[];
    clusterUtilizedFilters: any[];
    sheetName: string;
    yTitle: string;
  },
  clusterCost: {
    clusterCostTableData: any[];
    clusterCostChartData: any[];
    clusterCostFilters: any[];
    sheetName: string;
    yTitle: string;
  },
  cpuUtilization: {
    data: CpuUtilizationType[];
    filters: any[];
    sheetName: string;
    angleField: string;
  },
  memoryUtilization: {
    data: MemoryUtilizationType[];
    filters: any[];
    sheetName: string;
    angleField: string;
  }
) => {
  return [
    getApplicationDetailsExcelData(
      applicationDetails.applicationDetailsData,
      applicationDetails.filters
    ),
    getGranulateLineChartsExcelData(
      clusterUtilized.clusterUtilizedChartData,
      clusterUtilized.clusterUtilizedTableData,
      clusterUtilized.clusterUtilizedFilters,
      clusterUtilized.sheetName,
      clusterUtilized.yTitle
    ),
    getGranulateLineChartsExcelData(
      clusterCost.clusterCostChartData,
      clusterCost.clusterCostTableData,
      clusterCost.clusterCostFilters,
      clusterCost.sheetName,
      clusterCost.yTitle
    ),
    getGranulatePieChartsExcelData(
      cpuUtilization.data,
      cpuUtilization.filters,
      cpuUtilization.sheetName,
      cpuUtilization.angleField
    ),
    getGranulatePieChartsExcelData(
      memoryUtilization.data,
      memoryUtilization.filters,
      memoryUtilization.sheetName,
      memoryUtilization.angleField
    ),
  ];
};
