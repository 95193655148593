import {
  SoftwareSubscriptionTypes,
  EmploymentTypes,
} from 'pages/DataCenterPage/constants';
import i18n from 'i18n';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { SLASH_DATE_FORMAT, TIMESTAMP_FORMAT_WITHOUT_ZONE } from 'utils/date';
import { MANUAL_ENTRY_NAVIGATION } from './components/ManualEntry/constants';
import {
  EXCEL_COLUMNS,
  PurchaseModes,
  RESOURCE_REQUIRED_COLUMNS,
  ValidationStatus,
  VALIDTAION_REGEX,
} from './constants';
import { ExcelErrorType } from './types';

/**
 * @function validateXlsColumnData
 * @description Function to check if all the columns are available
 * @param file File type object for the excel file
 * @returns Promise function with resolved Boolean true if all columns are present else false.
 */
export const checkAllColumns = (file: File) => {
  return new Promise<boolean>((resolve) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const fileBuffer = evt.target?.result;
      const workbook = XLSX.read(fileBuffer, {
        type: 'binary',
        cellDates: true,
      });
      resolve(
        !workbook.SheetNames.every((sheetName) => {
          const firstRow: string[] = XLSX.utils
            .sheet_to_json<any>(workbook.Sheets[sheetName], { header: 1 })
            .at(0);
          return RESOURCE_REQUIRED_COLUMNS.find(
            (resource) => sheetName === resource.name
          )?.columns.every((column) => firstRow.includes(column));
        })
      );
    };
    reader.readAsBinaryString(file);
  });
};

/**
 * @function validateXlsColumnData
 * @description Function to validate xls by worksheets
 * @param file File type object for the excel file
 * @param existingDataCenters Existing data center codes for validations
 * @returns Promise function with resolved messages and reject function
 */
export const validateXlsData = (file: File, existingDataCenters: string[]) => {
  return new Promise<ExcelErrorType[]>((resolve, reject) => {
    const reader = new FileReader();
    const errors: ExcelErrorType[] = [];
    reader.onload = (evt) => {
      try {
        // Read excel file
        const fileBuffer = evt.target?.result;
        const workbook = XLSX.read(fileBuffer, {
          type: 'binary',
          cellDates: true,
        });

        let isExcelFileEmpty = true;
        MANUAL_ENTRY_NAVIGATION.forEach((sheet) => {
          const worksheet = workbook.Sheets[sheet.id];
          if (worksheet) {
            const numRows = getNumberOfRows(worksheet);
            if (numRows) isExcelFileEmpty = false;
            const sheetErrors = validateSheet(
              worksheet,
              sheet.id,
              numRows,
              existingDataCenters
            );
            sheetErrors && errors.push(...sheetErrors);
          }
        });
        isExcelFileEmpty && reject(ValidationStatus.NO_ROWS_DETECTED);
        resolve(errors);
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsBinaryString(file);
  });
};

/**
 * @function validateSheet
 * @description Function to validate every column in a worksheet
 * @param worksheet Worksheet to check in
 * @param sheet Sheetname that will be checked
 * @param existingDataCenters Existing data center codes for validations
 * @returns Error array of the current worksheet.
 */
const validateSheet = (
  worksheet: XLSX.WorkSheet,
  sheet: string,
  numRows: number,
  existingDataCenters: string[]
) => {
  const thisSheetErrors: ExcelErrorType[] = [];
  // Go to every column and check its rows for errors.
  for (let colIndex = 1; colIndex < getNumberOfCols(sheet) + 1; colIndex++) {
    const colChar = getColumnChar(colIndex);

    // Get column properties to check validations for.
    const columnProperties = EXCEL_COLUMNS.find(
      (column) => worksheet[colChar + 1].w === column.name
    );

    // Check required validation
    columnProperties?.requiredValidation &&
      thisSheetErrors.push(
        ...checkNullRows(
          worksheet,
          sheet,
          colChar,
          columnProperties.name,
          numRows
        )
      );

    // Check regex Validation
    columnProperties?.regexValidation &&
      thisSheetErrors.push(
        ...checkRowsForRegex(
          worksheet,
          sheet,
          colChar,
          columnProperties.name,
          numRows,
          columnProperties?.regexValidation.regex
        )
      );

    // Data center code validation
    if (
      columnProperties?.name ===
      i18n.t('uploadTcoFile.validation.dataCenterColumn')
    ) {
      thisSheetErrors.push(
        ...checkDataCenterCodes(
          worksheet,
          sheet,
          colChar,
          numRows,
          existingDataCenters
        )
      );
    }
  }
  return thisSheetErrors;
};

/**
 * @function getColumnChar
 * @description Function to get the column character for excel file
 * @param columnIndex File type object for the excel file
 * @returns A for first column, B for second column, C for third column and so on.
 */
const getColumnChar = (columnIndex: number) => {
  return String.fromCharCode(64 + columnIndex);
};

/**
 * @function checkNullRows
 * @description Function to check all the null rows for a given column
 * @param worksheet Excel file worksheet from library XLSX
 * @param columnChar The column which will be checked ie. A,B,C
 * @param columnName The column name to display in error message
 * @param noOfRows The maximum number of rows to check
 * @returns List of strings that contains errors
 */
const checkNullRows = (
  worksheet: XLSX.WorkSheet,
  worksheetName: string,
  columnChar: string,
  columnName: string,
  noOfRows: number
) => {
  const errors: ExcelErrorType[] = [];
  for (let rowIndex = 2; rowIndex <= noOfRows + 1; rowIndex++) {
    if (
      !checkIfColumnOptional(worksheet, columnName, worksheetName, rowIndex)
    ) {
      if (!worksheet[columnChar + rowIndex]) {
        errors.push({
          sheet: worksheetName,
          rowIndex: rowIndex,
          error:
            columnName + i18n.t('uploadTcoFile.validation.nullErrorSuffix'),
        });
      }
    }
  }
  return errors;
};

/**
 * @function checkIfColumnOptional
 * @description Function to check if a certain cell is optional or not based on other cells in the same row.
 * @param worksheet Excel file worksheet from library XLSX
 * @param columnToCheck The column which will be checked ie. Duration column should be checked for PERMANENT_PURCHASE
 * @param sheetName The sheet to check
 * @param rowIndex The row index to check.
 * @returns Boolean if the null error validation should be skipped or not
 */
const checkIfColumnOptional = (
  worksheet: XLSX.WorkSheet,
  columnToCheck: string,
  sheetName: string,
  rowIndex: number
) => {
  // For duration check if mode of purchase is permanent
  if (columnToCheck === i18n.t('manualEntry.computeLabels.duration')) {
    return checkIfDurationIsOptional(worksheet, sheetName, rowIndex);
  }
  // For End date check if software subscription is permanent or employment type is permanent
  if (columnToCheck === i18n.t('manualEntry.softwareLabels.endDate')) {
    return checkIfEndDateOptional(worksheet, sheetName, rowIndex);
  }
  return false;
};

/**
 * @function checkIfDurationIsOptional
 * @description Function to check if duration column is optional for a particular row index.
 * @param worksheet Excel file worksheet from library XLSX
 * @param sheetName The sheet to check
 * @param rowIndex The row index to check.
 * @returns Boolean if the null error validation should be skipped or not
 */
const checkIfDurationIsOptional = (
  worksheet: XLSX.WorkSheet,
  sheetName: string,
  rowIndex: number
) => {
  for (
    let colIndex = 1;
    colIndex < getNumberOfCols(sheetName) + 1;
    colIndex++
  ) {
    const colChar = getColumnChar(colIndex);
    if (
      worksheet[colChar + 1]?.w ===
        i18n.t('manualEntry.computeLabels.modeOfPurchase') &&
      worksheet[colChar + rowIndex]?.w === PurchaseModes.PERMANENT_PURCHASE
    )
      return true;
  }
  return false;
};

/**
 * @function checkIfEndDateOptional
 * @description Function to check if end date column is optional for a particular row index.
 * @param worksheet Excel file worksheet from library XLSX
 * @param sheetName The sheet to check
 * @param rowIndex The row index to check.
 * @returns Boolean if the null error validation should be skipped or not
 */
const checkIfEndDateOptional = (
  worksheet: XLSX.WorkSheet,
  sheetName: string,
  rowIndex: number
) => {
  for (
    let colIndex = 1;
    colIndex < getNumberOfCols(sheetName) + 1;
    colIndex++
  ) {
    const colChar = getColumnChar(colIndex);
    if (
      (worksheet[colChar + 1].w === i18n.t('manualEntry.softwareLabels.type') &&
        worksheet[colChar + rowIndex].w ===
          SoftwareSubscriptionTypes.PERMANENT_PURCHASE) ||
      (worksheet[colChar + 1].w ===
        i18n.t('manualEntry.laborLabels.employmentType') &&
        worksheet[colChar + rowIndex].w === EmploymentTypes.PERMANENT)
    )
      return true;
  }
  return false;
};

/**
 * @function checkRowsForRegex
 * @description Function to check if all the rows are integers for a given column
 * @param worksheet Excel file worksheet from library XLSX
 * @param columnChar The column which will be checked ie. A,B,C
 * @param columnName The column name to display in error message
 * @param noOfRows The maximum number of rows to check
 * @param regexExp Regex expression to check for
 * @returns List of strings that contains errors
 */
const checkRowsForRegex = (
  worksheet: XLSX.WorkSheet,
  worksheetName: string,
  columnChar: string,
  columnName: string,
  noOfRows: number,
  regexExp: RegExp
) => {
  const regexErrors: ExcelErrorType[] = [];

  for (let rowIndex = 2; rowIndex <= noOfRows + 1; rowIndex++) {
    if (worksheet[columnChar + rowIndex]) {
      if (!regexExp.test(worksheet[columnChar + rowIndex].w)) {
        regexErrors.push({
          sheet: worksheetName,
          rowIndex: rowIndex,
          error:
            columnName +
            i18n.t('uploadTcoFile.validation.regexErrorSuffix') +
            Object.entries(VALIDTAION_REGEX).find(
              (keyVal) => keyVal[1].regex === regexExp
            )?.[1].errorInfo,
        });
      }
    }
  }
  return regexErrors;
};

/**
 * @function checkDataCenterCodes
 * @description Function to check if any data center code is not present in backend.
 * @param worksheet Excel file worksheet from library XLSX
 * @param columnChar The column which will be checked ie. A,B,C
 * @param noOfRows The maximum number of rows to check
 * @param existingDataCenters Existing data center codes for validations
 * @returns List of strings that contains errors
 */
const checkDataCenterCodes = (
  worksheet: XLSX.WorkSheet,
  worksheetName: string,
  columnChar: string,
  noOfRows: number,
  existingDataCenters: string[]
) => {
  const dataCenterErrors: ExcelErrorType[] = [];

  for (let rowIndex = 2; rowIndex <= noOfRows + 1; rowIndex++) {
    if (worksheet[columnChar + rowIndex]) {
      if (
        !existingDataCenters.includes(
          worksheet[columnChar + rowIndex].w.toString()
        )
      ) {
        dataCenterErrors.push({
          sheet: worksheetName,
          rowIndex: rowIndex,
          error: i18n.t('uploadTcoFile.validation.dataCenterNotExistError'),
        });
      }
    }
  }
  return dataCenterErrors;
};

/**
 * @function generateErrorExcelFile
 * @description Function to generate a new excel file with existing data and new errors and downloads new file.
 * @param errors Validation errors to add as a column
 * @param file File type object for the excel file
 */
export const generateErrorExcelFile = (
  errors: ExcelErrorType[],
  file: File
) => {
  // Group errors based on sheetname.
  const errorsGroupedBySheet: any = errors.reduce((result: any, errorObj) => {
    let sheetname = errorObj.sheet;
    if (!result[sheetname]) {
      result[sheetname] = [];
    }
    result[sheetname].push({
      rowIndex: errorObj.rowIndex,
      error: errorObj.error,
    });
    return result;
  }, {});

  const reader = new FileReader();
  reader.onload = (evt) => {
    // Read excel file
    const fileBuffer = evt.target?.result;
    const workbook = XLSX.read(fileBuffer, {
      type: 'binary',
      cellDates: true,
    });

    MANUAL_ENTRY_NAVIGATION.forEach((sheet) => {
      addErrorToWorksheet(workbook, errorsGroupedBySheet, sheet.id);
    });

    // Save the new xlsx file
    XLSX.writeFileXLSX(
      workbook,
      `${file.name.substring(0, file.name.length - 5)}_error.xlsx`,
      { bookSST: true }
    );
  };
  reader.readAsBinaryString(file);
};

/**
 * @function addErrorToWorksheet
 * @description Function to add error columns to a specific worksheet
 * @param workbook Workbook which contains the worksheets
 * @param errorsGroupedBySheet Object of errors that contains errors for every sheet
 * @param sheet Name of the sheet in which to add the error column
 */
const addErrorToWorksheet = (
  workbook: XLSX.WorkBook,
  errorsGroupedBySheet: any,
  sheet: string
) => {
  const worksheet = workbook.Sheets[sheet];
  if (!worksheet || !errorsGroupedBySheet[sheet]) return;
  // Group errors inside a sheet based on row index.
  const errorsGroupedByRowIndex: any = errorsGroupedBySheet[sheet].reduce(
    (result: any, error: any) => {
      let rowIndex = error.rowIndex;
      if (!result[rowIndex]) {
        result[rowIndex] = [];
      }
      result[rowIndex].push(error.error);
      return result;
    },
    {}
  );

  const numRows = getNumberOfRows(worksheet);
  const numCols = getNumberOfCols(sheet);

  // Convert grouped errors to array of string arrays so that XLSX library can interpret it.
  const arrayOfErrors: any = [[i18n.t('uploadTcoFile.validation.errorColumn')]];
  for (let i = 2; i <= numRows + 1; i++) {
    if (errorsGroupedByRowIndex[i])
      arrayOfErrors.push(errorsGroupedByRowIndex[i]);
    else arrayOfErrors.push(['']);
  }

  // Specify width for columns. WPX means width in pixels.
  const colStyles = getColumnStyling(numCols);
  colStyles.push({ wpx: 500 });
  worksheet['!cols'] = colStyles;

  // Specify height for rows. More errors will result in more height. HPX means height in pixels
  let wsrows: any[] = [];
  wsrows.push({ hpx: 15 });
  for (let i = 1; i < numRows + 1; i++) {
    wsrows.push({ hpx: 15 * (arrayOfErrors.at(i)?.length || 1) });
  }
  worksheet['!rows'] = wsrows;

  // Add numbering to errors and join array with a line break.
  const errorRows = arrayOfErrors.map((row: string[], rowIndex: number) => [
    row
      .map((error, lineIndex) => {
        if (rowIndex === 0 || error === '') return error;
        return lineIndex + 1 + '. ' + error;
      })
      .join('\n'),
  ]);

  // XLSX utility fuction to add array of arrays (aoa) to add a new column.
  XLSX.utils.sheet_add_aoa(worksheet, errorRows, {
    origin: getColumnChar(numCols + 1) + '1',
  });
};

/**
 * @function getNumberOfRows
 * @description Function to get the total rows in a worksheet
 * @param worksheet Worksheet to check for
 * @returns number of rows
 */
const getNumberOfRows = (worksheet: XLSX.WorkSheet) => {
  return XLSX.utils.sheet_to_json(worksheet, { blankrows: false }).length;
};

/**
 * @function getNumberOfCols
 * @description Function to get the total cols in a worksheet
 * @param sheetname Sheetname to check for
 * @returns number of columns
 */
const getNumberOfCols = (sheetname: string) => {
  return (
    RESOURCE_REQUIRED_COLUMNS.find((resource) => resource.name === sheetname)
      ?.columns?.length ?? 0
  );
};

/**
 * @function generateEmptyExcelFile
 * @description Function to generate a new excel file with required columns
 */
export const generateEmptyExcelFile = () => {
  const workbook = XLSX.utils.book_new();
  RESOURCE_REQUIRED_COLUMNS.forEach((resource) => {
    const worksheet = XLSX.utils.aoa_to_sheet([resource.columns]);
    worksheet['!cols'] = getColumnStyling(resource.columns.length);

    XLSX.utils.book_append_sheet(workbook, worksheet, resource.name);
  });
  // Save the new xlsx file
  XLSX.writeFileXLSX(workbook, 'sampleExcelFile.xlsx');
};

/**
 * @function getColumnStyling
 * @description get columns styling for a worksheet
 */
const getColumnStyling = (numCols: number) => {
  let wscols: any[] = [];
  for (let i = 0; i < numCols; i++) {
    wscols.push({ wpx: 100 });
  }
  return wscols;
};

/**
 * @function allocateCostByPurchaseModes
 * @description Function to allocate cost based on the mode of purchase
 * @param arrayOfEntries List of excel entries
 * @returns list of modified entries
 */
const allocateCostByPurchaseModes = (arrayOfEntries: any[]) => {
  return arrayOfEntries.map((entry) => {
    if (entry.modeOfPurchase === PurchaseModes.PERMANENT_PURCHASE) {
      return {
        ...entry,
        monthlyCost: undefined,
        modeOfPurchase: PurchaseModes.PERMANENT_PURCHASE,
        totalCost: entry.monthlyCost,
      };
    } else if (entry.modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE) {
      return {
        ...entry,
        modeOfPurchase: PurchaseModes.RENTAL_OR_LEASE,
      };
    }
    return { ...entry };
  });
};

/**
 * @function formatDates
 * @description Function to format date fields
 * @param arrayOfEntries List of excel entries
 * @returns list of modified entries
 */
const formatDates = (arrayOfEntries: any[]) => {
  return arrayOfEntries.map((entry) => {
    let modifiedEntry = { ...entry };
    if (entry['dateOfPurchase'])
      modifiedEntry = {
        ...modifiedEntry,
        dateOfPurchase: moment(entry.dateOfPurchase, SLASH_DATE_FORMAT)
          .startOf('month')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      };
    if (entry['startDate'])
      modifiedEntry = {
        ...modifiedEntry,
        startDate: moment(entry.startDate, SLASH_DATE_FORMAT)
          .startOf('month')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      };
    if (entry['endDate'])
      modifiedEntry = {
        ...modifiedEntry,
        endDate: moment(entry.endDate, SLASH_DATE_FORMAT)
          .endOf('month')
          .startOf('day')
          .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      };
    return modifiedEntry;
  });
};

/**
 * @function groupDataByRequestFields
 * @description Function to group excel data by request fields.
 * @param rows The array of rows containing key value pair of column name to row.
 * @returns Object of grouped data with request fields as keys.
 */
const groupDataByRequestFields = (rows: any[][]) => {
  const groupedDataByRequestField: any = {};
  rows.forEach((row, rowIndex) =>
    row.forEach((cell, cellIndex) => {
      const columnName = rows[0][cellIndex];
      const requestFieldName = EXCEL_COLUMNS.find(
        (column) => column.name === columnName
      )?.requestField;

      if (requestFieldName) {
        if (rowIndex === 0)
          groupedDataByRequestField[requestFieldName] = [cell];
        else groupedDataByRequestField[requestFieldName].push(cell);
      }
    })
  );
  return groupedDataByRequestField;
};

/**
 * @function convertExcelToJson
 * @description get columns styling for a worksheet
 * @param file Excel file from which to extract data and convert to json
 * @returns Promise function which resolves into the body for API calls.
 */
export const convertExcelToJson = (file: File) => {
  return new Promise<any>((resolve) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const fileBuffer = evt.target?.result;
      const entries: any = {};
      const workbook = XLSX.read(fileBuffer, {
        type: 'binary',
        cellDates: true,
      });
      workbook.SheetNames.forEach((sheetName) => {
        // Convert data to object format.
        const rows: any[][] = XLSX.utils.sheet_to_json<any>(
          workbook.Sheets[sheetName],
          {
            header: 1,
            defval: '',
            blankrows: false,
          }
        );

        // This check is needed to prevent sending empty data to API calls.
        if (rows.length > 1) {
          // Group data by request fields instead of grouping by rows.
          const groupedDataByRequestField: any = groupDataByRequestFields(rows);

          // Make an array of entries based on the gropued data.
          let arrayOfEntries: any[] = [];
          for (let i = 1; i < rows.length; i++) {
            arrayOfEntries.push({});
            Object.keys(groupedDataByRequestField).forEach((key) => {
              arrayOfEntries[i - 1][key] = groupedDataByRequestField[key][i];
            });
          }

          // Correct the mode of purchase and monthly cost request fields.
          arrayOfEntries = allocateCostByPurchaseModes(arrayOfEntries);

          // Correct the format for dates.
          arrayOfEntries = formatDates(arrayOfEntries);

          entries[sheetName] = arrayOfEntries;
        }
      });
      resolve(entries);
    };
    reader.readAsBinaryString(file);
  });
};
