import { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import { useTranslation } from 'react-i18next';

import DrawerComponent from 'components/DrawerComponent';
import { ValueLabelType } from 'types/dashboard';
import Button from 'components/Button';
import { RecommendationFiltersType } from 'pages/CostOptimizationInsightsPage/types';
import { FilterKeys } from 'pages/CostOptimizationInsightsPage/constants';
import Empty from 'components/Empty';

import FilterValuesList from './components/FilterValuesList';
import RecommendationInputField from './components/RecommendationInputField';
import DateRangeInputField from './components/DateRangeInputField';
import CostSavingsInput from './components/CostSavingsInput';
import { getFilterLabelByKey } from './utils';

import './index.scss';

const { Panel } = Collapse;

type FiltersModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  filters: RecommendationFiltersType[];
  existingFilter: RecommendationFiltersType[];
  onClickApply: (selectedTags: RecommendationFiltersType[]) => void;
  minimumTags?: number;
};

const FiltersModal = ({
  show,
  setShow,
  filters,
  existingFilter,
  onClickApply,
  minimumTags = 0,
}: FiltersModalProps) => {
  const { t } = useTranslation();

  const [selectedFilters, setSelectedFilters] = useState<
    RecommendationFiltersType[]
  >([]);

  useEffect(() => {
    setSelectedFilters(
      filters.map((filter) => ({
        ...filter,
        values:
          existingFilter.find((item) => item.key === filter.key)?.values ??
          getSelectedValuesByFilterKey(filter.key),
      }))
    );
  }, [filters, existingFilter]);

  /**
   * @function getSelectedFilters
   * @description Function to get the selected filters.
   * @returns filters that has values selected or added.
   */
  const getSelectedFilters = () => {
    return selectedFilters.filter((filter) => filter.values!.length > 0);
  };

  /**
   * @function setSelectedFilterValues
   * @description Updated the selected filters list by adding or updating the provided filter values
   * @param filter The filter to be updated or added.
   * @param values The list values to be updated or added
   */
  const setSelectedFilterValues = (
    filter: RecommendationFiltersType,
    values: ValueLabelType[] | null
  ) => {
    // Remove the filter from selected filters list if the filter values doesn't exists or empty
    if (!values || values.length === 0) {
      setSelectedFilters([
        ...selectedFilters.filter(
          (eachFilter) => eachFilter.key !== filter.key
        ),
      ]);
      return;
    }

    const position = selectedFilters.findIndex(
      (eachFilter) => eachFilter.key === filter.key
    );
    const previousFilters = [...selectedFilters];

    if (position === -1) {
      // Add the filter if not exists
      previousFilters.push({ ...filter, values });
    } else {
      // Update the values for existing filter.
      previousFilters.splice(position, 1, { ...filter, values });
    }
    setSelectedFilters(previousFilters);
  };

  /**
   * @function getSelectedValuesByFilterKey
   * @description Returns the values selected or added for a given filter key
   * @param filterKey The filter key for which the values are returned
   * @returns List of values selected or added. Defaults to empty list
   */
  const getSelectedValuesByFilterKey = (filterKey: string) => {
    return selectedFilters.find((item) => item.key === filterKey)?.values ?? [];
  };

  /**
   * @function getSelectedFilterValuesCount
   * @description Function to get the count of selected filter values for the given key
   * @param filterKey filter key to count the selected tags
   * @returns count of selected filter values for the given key
   */
  const getSelectedFilterValuesCount = (filterKey: string) => {
    const length =
      selectedFilters.find((eachTag) => eachTag.key === filterKey)?.values
        ?.length ?? 0;
    return length > 0 ? <span className="values-count">{length}</span> : null;
  };

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="tag-collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="tag-collapse-arrow" />
    );

  /**
   * @function getValuesComponent
   * @description Returns the JSX component based on the filter key
   * @param filter Filter for which the component is returned
   * @return Returns JSX element
   */
  const getValuesComponent = (filter: RecommendationFiltersType) => {
    switch (filter.key) {
      case FilterKeys.RECOMMENDATION_DESCRIPTION:
        return (
          <RecommendationInputField
            filter={filter}
            value={getSelectedValuesByFilterKey(filter.key)}
            onChangeFilterValues={setSelectedFilterValues}
          />
        );

      case FilterKeys.LAST_REFRESH_DATE_RANGE:
        return (
          <DateRangeInputField
            filter={filter}
            onChangeFilterValues={setSelectedFilterValues}
          />
        );

      case FilterKeys.COST_SAVINGS:
        return (
          <CostSavingsInput
            filter={filter}
            onChangeFilterValues={setSelectedFilterValues}
          />
        );

      default:
        return (
          <FilterValuesList
            filter={filter}
            selectedValues={getSelectedValuesByFilterKey(filter.key)}
            onChangeFilterValues={setSelectedFilterValues}
          />
        );
    }
  };

  return (
    <DrawerComponent
      className="recommendation-filter-modal"
      open={show}
      title={t('costOptimizationInsight.tagsModal.filters')}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-end">
          <Button
            title={t('dashboardLabels.tagsLabels.apply')}
            onClick={() => onClickApply(selectedFilters)}
            disabled={getSelectedFilters().length < minimumTags}
          />
        </div>
      }
      dataTestId="recommendation-filter-drawer"
    >
      {filters.length > 0 ? (
        <Collapse
          className="tags-collapse full-width flex flex-column"
          accordion={true}
          bordered={false}
          expandIcon={getExpandIcon}
        >
          {filters.map((filter) => (
            <Panel
              className="tag-panel font-subHeader"
              header={getFilterLabelByKey(filter.key)}
              key={filter.key}
              extra={getSelectedFilterValuesCount(filter.key)}
            >
              {getValuesComponent(filter)}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Empty description={t('noFiltersToShow')} />
      )}
    </DrawerComponent>
  );
};

export default FiltersModal;
