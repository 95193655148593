import { useTranslation } from 'react-i18next';

import CommitmentsSwitch from '../CommitmentsSwitch';
import PurchasedSavingPlans from './components/PurchasedSavingPlans';
import SavingsPlansRecommendation from './components/SavingsPlansRecommendation';

const AwsSavingsPlans = () => {
  const { t } = useTranslation();

  return (
    <CommitmentsSwitch
      heading={t('purchasePage.savingsPlans')}
      purchasedComponent={<PurchasedSavingPlans />}
      recommendationComponent={<SavingsPlansRecommendation />}
    />
  );
};

export default AwsSavingsPlans;
