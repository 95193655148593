import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RolesAccessSummaryType } from 'types/dataTypes';
import {
  CloudConnectionComponents,
  VALIDATION_STATUS,
} from './../pages/ConnectingCSPPage/constants';
import { RootState } from './store';

export interface CloudConnectionSlice {
  cloudConnectionComponent: string;
  currentConnection: any;
  editedData: any;
  isEditConnection: boolean;
  isTryAgain: boolean;
  accessDetails: RolesAccessSummaryType[];
  currentStep: number;
  currentStepValidation: VALIDATION_STATUS;
  resetStepForm: boolean;
}

const initialState: CloudConnectionSlice = {
  cloudConnectionComponent: CloudConnectionComponents.CLOUD_CONNECTION_FORM,
  currentConnection: undefined,
  editedData: '',
  isEditConnection: false,
  isTryAgain: false,
  accessDetails: [],
  currentStep: 0,
  currentStepValidation: VALIDATION_STATUS.INVALID,
  resetStepForm: false,
};

export const cloudConnectionSlice = createSlice({
  name: 'cloudConnection',
  initialState,
  reducers: {
    setCloudConnectionComponent: (state, action: PayloadAction<string>) => {
      state.cloudConnectionComponent = action.payload;
    },
    setCurrentConnection: (state, action: PayloadAction<any>) => {
      state.currentConnection = action.payload;
    },
    setEditedData: (state, action: PayloadAction<any>) => {
      state.editedData = action.payload;
    },
    setIsEditConnection: (state, action: PayloadAction<boolean>) => {
      state.isEditConnection = action.payload;
    },
    setIsTryAgain: (state, action: PayloadAction<boolean>) => {
      state.isTryAgain = action.payload;
    },
    setAccessDetails: (
      state,
      action: PayloadAction<RolesAccessSummaryType[]>
    ) => {
      state.accessDetails = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setCurrentStepValidation: (
      state,
      action: PayloadAction<VALIDATION_STATUS>
    ) => {
      state.currentStepValidation = action.payload;
    },
    setResetStepForm: (state, action: PayloadAction<boolean>) => {
      state.resetStepForm = action.payload;
    },
  },
});

export const cloudConnection = (state: RootState) => state.cloudConnection;
export const {
  setCloudConnectionComponent,
  setCurrentConnection,
  setEditedData,
  setIsEditConnection,
  setIsTryAgain,
  setAccessDetails,
  setCurrentStep,
  setCurrentStepValidation,
  setResetStepForm,
} = cloudConnectionSlice.actions;
export default cloudConnectionSlice.reducer;
