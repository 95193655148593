import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setTableViewEnabled } from 'redux/dashboardSlice';
import {
  selectCostAllocationDashboard,
  setSelectedCostAllocationView,
} from 'redux/costAllocationDashboardSlice';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import { COST_ALLOCATION_DASHBOARD_NAVIGATION } from './constants';

import './index.scss';

const HorizontalNavigation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedCostAllocationView } = useSelector(
    selectCostAllocationDashboard
  );

  return (
    <HorizontalNavigationMenu
      menuItems={COST_ALLOCATION_DASHBOARD_NAVIGATION.map((navMenu) => (
        <Menu.Item
          onClick={() => {
            dispatch(setSelectedCostAllocationView(navMenu.id));
            dispatch(setTableViewEnabled(false));
          }}
          key={navMenu.id}
          eventKey={navMenu.id}
          className={`font-caption-bold ${
            navMenu.id === selectedCostAllocationView && 'active-menu'
          }`}
        >
          {t(`dashNav.${navMenu.title}`)}
        </Menu.Item>
      ))}
      selectedKeys={[selectedCostAllocationView]}
    />
  );
};

export default HorizontalNavigation;
