import i18n from 'i18n';
import { insertIndex } from 'utils/dataFormatterUtils';
import {
  CostAndUsageExportColumns,
  DetectionHistoryExportColumns,
} from './constants';
import { AWSAnomalyType, DaywiseExpectedAndActualCostType } from './types';

/**
 * @function getDetectionHistoryExcelData
 * @description Function to get the detection history table excel data
 * @param connectorName Name of the connection selected
 * @param data excel data
 * @returns excel file configartion and data for detection history
 */
export const getDetectionHistoryExcelData = (
  connectorName: string,
  data: AWSAnomalyType[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.detectionHistory'),
    columns: DetectionHistoryExportColumns,
    data: insertIndex(data),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

/**
 * @function getCostAndUsageExcelData
 * @description Function to get the cost and usage table excel data
 * @param connectorName Name of the connection selected
 * @param data excel data
 * @returns excel file configartion and data for detection history
 */
export const getCostAndUsageExcelData = (
  connectorName: string,
  data: DaywiseExpectedAndActualCostType[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.costAndUsage'),
    columns: CostAndUsageExportColumns,
    data: insertIndex(data),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

/**
 * @function getAwsAnomalyExcelData
 * @description Function to get the AWS Anomaly excel data
 * @param connectorName Name of the connection selected
 * @param detectionHistoryData detection history excel data
 * @returns List of excel file configartions and data
 */
export const getAwsAnomalyExcelData = (
  connectorName: string,
  detectionHistoryData: AWSAnomalyType[],
  costAndUsageData: DaywiseExpectedAndActualCostType[]
) => {
  return [
    getDetectionHistoryExcelData(connectorName, detectionHistoryData),
    getCostAndUsageExcelData(connectorName, costAndUsageData),
  ];
};
