import moment from 'moment';

import { CHART_TYPES } from 'constants/graphConfig';
import { ChartType } from 'types/dashboard';
import { DATE_FORMAT, YEAR_MONTH_WITHOUT_SEPARATOR } from 'utils/date';
import { ReportOptionsType } from 'pages/ReportsPage/types';

export const defaultCustomDashboardDataSource = {
  dashboardTitle: '',
  dataSet: '',
  dashBoardType: '',
  useCustomQuery: false,
  customQuery: '',
  customQueryId: '',
  availableFields: [],
};

export const defaultCostAllocationData = {
  connection: '',
  connectionName: '',
  month: moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
  projects: [],
  businessUnits: [],
};

export const defaultDataSourceItem = {
  useCustomQuery: false,
  customQueryId: '',
  dataset: '',
  dashBoardType: '',
  availableFields: [],
  viewStatus: '',
};

export const defaultCustomViewData = {
  id: '',
  layoutDesigns: [],
  dataSource: defaultDataSourceItem,
  viewName: '',
  viewStatus: '',
};

export const defaultChartData: ChartType = {
  chartPosition: -1,
  percentage: 0,
  chartType: CHART_TYPES.TABLE,
};

export const defaultReportOptions: ReportOptionsType = {
  reportName: '',
  createdBy: '',
  dimension: [],
  metric: [],
  chartType: CHART_TYPES.TABLE,
  chartDimension: [],
  chartMetric: [],
  sort: [],
  startDate: moment().subtract(6, 'days').format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  dateRanges: 'CUSTOM',
};

export const defaultSelectedChartRow = -1;

export const defaultSelectedChartIndex = -1;

export const REFRESH_INTERVAL = 10000;
export const REFRESH_INTERVAL_5000 = 5000;
export const REFRESH_INTERVAL_30000 = 30000;
