import { useSelector } from 'react-redux';

import { selectCostAllocation } from 'redux/costAllocationSlice';
import SetupBusinessUnit from 'pages/CostAllocationPage/components/CostAllocationForm/SetupBusinessUnit';

import ResourceGroupSelectionStep from '../ResourceGroupSelectionStep';
import ConnectionAndMonthForm from '../../ConnectionAndMonthForm';

const AzureCostAllocationConnectionForm = () => {
  const { currentStep } = useSelector(selectCostAllocation);

  const getStepWiseComponent = () => {
    switch (currentStep) {
      case 1:
        return <ConnectionAndMonthForm />;

      case 2:
        return <ResourceGroupSelectionStep />;

      case 3:
        return <SetupBusinessUnit />;
    }
  };

  return <>{getStepWiseComponent()}</>;
};

export default AzureCostAllocationConnectionForm;
