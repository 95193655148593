import { debounce } from 'lodash';
import { message } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { userAuthorization } from 'redux/authorizationSlice';
import {
  DEBOUNCE_TIME_DELAY,
  INITIAL_PAGE_NUMBER,
  PAGINATION_SIZE,
} from 'constants/userConsole';
import Table from 'components/Table';
import Button from 'components/Button';
import AddUser from 'pages/UserPage/components/AddUser';
import SearchInput from 'components/Input';
import EditUser from './components/EditUser';
import Pagination from 'components/Pagination';
import DeleteModal from 'components/DeleteModal';
import QuickActionMenu from 'components/QuickActionMenu';
import { REQUEST_STATUS } from 'constants/requestBody';
import { UserListDataType } from 'types/userManagementConsole';
import { getAllUsersData, getSearchUserData } from 'utils/services';
import { UserQuickActions, USER_QUICKACTIONS } from 'constants/quickAction';
import { DATE_TIME_AM_PM, HYPHEN_DATE_TIME_HH_MM_SS_AM_PM } from 'utils/date';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { Config } from 'config';
import { LoginTypes } from 'pages/LoginPage/constants';
import { onApiCallError } from 'utils/handleErrors';

import { deleteUserByID } from './services';

import './index.scss';

const UserPage = () => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);
  const loginType = Config.login_type;

  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [userData, setUserData] = useState<UserListDataType[]>([]);
  const [userDataRequestStatus, setUserDataRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [selectedUserRecord, setSelectedUserRecord] = useState<any>();

  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUserRequestStatus, setDeleteUserRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    onFetchingUserData(currentPage);
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function fetchUserDataFromStart
   * @description Function to fetch user data from start
   */
  const fetchUserDataFromStart = () => {
    setCurrentPage(1);
    onFetchingUserData(1);
    setSearchKey('');
  };

  /**
   * @function onFetchingUserData
   * @description Function to fetch all user data
   * @param page :accepts the current page number
   */
  const onFetchingUserData = (page: number) => {
    setUserDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = { page: page - 1, size: PAGINATION_SIZE };

    getAllUsersData(params)
      .then((respose: any) => {
        const { data } = respose;
        setUserData(data.content);
        setUserDataRequestStatus(REQUEST_STATUS.SUCCESS);
        setTotalUserCount(data.totalElements);
      })
      .catch((err) => {
        onApiCallError(err, false, setUserDataRequestStatus);
        setUserData([]);
      });
  };

  /**
   * @function deleteUser
   * @description Callback function for deleting a user
   */
  const deleteUser = () => {
    setDeleteUserRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = { id: selectedUserRecord.id };
    deleteUserByID(params)
      .then((res: any) => {
        if (res?.status === 200) {
          fetchUserDataFromStart();
          setDeleteUserRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success(t('deleteUserModal.deleteSuccessMessage'));
          setShowDeleteModal(false);
        } else {
          setDeleteUserRequestStatus(REQUEST_STATUS.ERROR);
          message.error(t('deleteUserModal.deleteFailureMessage'));
        }
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteUserRequestStatus,
          t('deleteUserModal.deleteFailureMessage')
        );
      });
  };

  /**
   * @function handleQuickAction
   * @description Function to handle quick action functionalities
   * @param record user record to be actioned upon
   * @param key quick action key
   */
  const handleQuickAction = (record: any, key: string) => {
    setSelectedUserRecord(record);
    if (key === UserQuickActions.UPDATE) {
      setShowEditUserModal(true);
    }

    if (key === UserQuickActions.DELETE) {
      setShowDeleteModal(true);
    }
  };

  const getStatusStyle = (text: string) => {
    switch (text) {
      case 'ACTIVE':
        return 'active';
      case 'Deactivited':
        return 'deactivated';
      default:
        return 'pending';
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
    },
    {
      title: t('usersTable.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('usersTable.emailAddress'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('usersTable.role'),
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: t('usersTable.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        return (
          <span className={`font-button ${getStatusStyle(text)}`}>{text}</span>
        );
      },
    },
    {
      title: t('usersTable.lastAccessedAt'),
      dataIndex: 'lastAccessed',
      key: 'lastAccessed',
      render: (text: string) =>
        text
          ? moment
              .utc(text, HYPHEN_DATE_TIME_HH_MM_SS_AM_PM)
              .local()
              .format(DATE_TIME_AM_PM)
          : '--',
    },
    {
      title: t('usersTable.quickAction'),
      dataIndex: 'quickAction',
      key: 'quickAction',
      render: (_text: any, record: any) => (
        <QuickActionMenu
          quickActions={USER_QUICKACTIONS}
          quickActionHandler={(action: string) => {
            handleQuickAction(record, action);
          }}
          disabled={
            !permissions.userManagementModify
            // || record.email === localStorage.getItem('email')
          }
        />
      ),
    },
  ];

  /**
   * @function fetchSearchUserData
   * @description Function to fetch searched user data
   * @param searchKeyString accepts search input value
   * @param page accepts the current page number
   */
  const fetchSearchUserData = (
    searchKeyString: string,
    page = INITIAL_PAGE_NUMBER
  ) => {
    setUserDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      key: searchKeyString,
      page: page - 1,
      size: PAGINATION_SIZE,
    };

    getSearchUserData(params)
      .then((response: any) => {
        const { data } = response;
        setUserData(data.content);
        setUserDataRequestStatus(REQUEST_STATUS.SUCCESS);
        setTotalUserCount(data.totalElements);
      })
      .catch((error) => {
        onApiCallError(error);
      });
  };

  /**
   * @function handleSearchUser
   * @description Function to handle user search inputs
   * @param e :accepts search input event
   */
  const handleSearchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    fetchSearchUserData(e.target.value);
  };

  const onHandleChange = useCallback(
    debounce(handleSearchUser, DEBOUNCE_TIME_DELAY),
    []
  );

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    if (searchKey.length === 0) {
      onFetchingUserData(page);
    } else {
      setUserDataRequestStatus(REQUEST_STATUS.PROCESSING);
      fetchSearchUserData(searchKey, page);
    }
  };

  return (
    <div className="user-page">
      <div className="user-page-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="modal-heading">{t('navigationMenu.users')}</div>
        {permissions.userManagementWrite && loginType !== LoginTypes.MFA && (
          <Button
            title={t('addUser')}
            onClick={() => setShowAddUsersModal(true)}
          />
        )}
      </div>
      <div className="user-page-content page-content flex flex-column flex-fit">
        <SearchInput
          placeholder={t('searchUserPlaceholder')}
          type="search"
          onChange={onHandleChange}
        />
        <Table
          pagination={false}
          rootClassName="table-section"
          loading={userDataRequestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={userData.map((user) => {
            return {
              ...user,
              key: user.email,
            };
          })}
          columns={columns}
        />
      </div>
      <Pagination
        rootClassName="flex flex-end"
        current={currentPage}
        onChange={onChangePagination}
        total={totalUserCount}
      />
      <AddUser
        showAddUsersModal={showAddUsersModal}
        setShowAddUsersModal={setShowAddUsersModal}
        fetchUserData={fetchUserDataFromStart}
      />
      {showEditUserModal && (
        <EditUser
          showEditUserModal={showEditUserModal}
          setShowEditUserModal={setShowEditUserModal}
          selectedUserRecord={selectedUserRecord}
          setSelectedUserRecord={setSelectedUserRecord}
          fetchUserData={fetchUserDataFromStart}
        />
      )}
      <DeleteModal
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
        deletionFunction={deleteUser}
        loading={deleteUserRequestStatus === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );
};

export default UserPage;
