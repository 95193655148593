import i18n from 'i18n';

import { REQUEST_STATUS } from 'constants/requestBody';
import {
  fetchCmdbDatasourceProviders,
  validateDuplicateCmdbName,
} from 'pages/CmdbConfigurationPage/services';
import { onApiCallError } from 'utils/handleErrors';

/**
 * @function fetchDatasourceProviders
 * @description Function to fetch CMDB data source providers
 * @param setData Object containing callbacks  to set the data fetched
 */
export const fetchDatasourceProviders = (setData: {
  setDatasourceProviders: (val: string[]) => void;
  setReqStatus: (val: string) => void;
}) => {
  setData.setReqStatus(REQUEST_STATUS.PROCESSING);

  fetchCmdbDatasourceProviders()
    .then((res: any) => {
      setData.setDatasourceProviders(res.data.responseData ?? []);
      setData.setReqStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setData.setReqStatus);
    });
};

/**
 * @function validateDuplicateName
 * @description Function to validate if the given name is exists or not
 * @param name String name to be validated
 * @param setValidation Callback to set the validation error message based on the status
 * @param setIsDuplicateName Callback to set if the name exists or not
 */
export const validateDuplicateName = (
  name: string,
  setValidation: (val: string) => void,
  setIsDuplicateName: (val: boolean) => void
) => {
  validateDuplicateCmdbName({ name })
    .then((res: any) => {
      if (!res.data.responseData) {
        setValidation('');
        setIsDuplicateName(false);
        return;
      }

      setValidation(i18n.t('duplicateName'));
    })
    .catch((e) => {
      onApiCallError(e, false);
      setValidation(i18n.t('errorValidatingName'));
    });
};
