import i18n from 'i18n';
import {
  QUERY_FIELDS,
  AGGREGATORS,
  ORDER_BY,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
} from 'constants/requestBody';

export const GRANULATE_GRAPHS = [
  'memory-utilization',
  'cpu-utilization',
  'utilized-and-reserved',
  'cluster-count',
  'application-details',
];

export const GRANULATE_EXCEL_GRAPHS = [
  'Application Details',
  'CPU Utilization (%)',
  'Memory Utilization (%)',
  'Cores Count (cores)',
  'PODS Count',
];

export const MemoryUtilizationExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('consolidatedDashboard.applicationDetails.name'),
    key: 'name',
    dataKey: 'name',
    width: 20,
  },
  {
    header: i18n.t(
      'consolidatedDashboard.applicationDetails.memoryUtilization'
    ),
    key: 'memoryUtilisation',
    dataKey: 'memoryUtilisation',
    width: 20,
    alignment: 'right',
  },
];

export const CpuUtilizationExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('consolidatedDashboard.applicationDetails.name'),
    key: 'name',
    dataKey: 'name',
    width: 20,
  },
  {
    header: i18n.t('consolidatedDashboard.applicationDetails.cpuUtilization'),
    key: 'cpuUtilisation',
    dataKey: 'cpuUtilisation',
    width: 20,
    alignment: 'right',
  },
];

export const ClusterUtilizationExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('consolidatedDashboard.cluster'),
    key: 'cluster',
    dataKey: 'cluster',
    width: 20,
  },
  {
    header: i18n.t('consolidatedDashboard.utilization'),
    key: 'utilization',
    dataKey: 'utilization',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t('consolidatedDashboard.time'),
    key: 'time',
    dataKey: 'time',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t('consolidatedDashboard.category'),
    key: 'category',
    dataKey: 'category',
    width: 20,
    alignment: 'right',
  },
];

export const ClusterCountPodsExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('consolidatedDashboard.time'),
    key: 'time',
    dataKey: 'time',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t('consolidatedDashboard.category'),
    key: 'category',
    dataKey: 'category',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t('consolidatedDashboard.pods'),
    key: 'yField',
    dataKey: 'yField',
    width: 20,
    alignment: 'right',
  },
];

export const ClusterCountCoresExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('consolidatedDashboard.time'),
    key: 'time',
    dataKey: 'time',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t('consolidatedDashboard.category'),
    key: 'category',
    dataKey: 'category',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t('consolidatedDashboard.utilization'),
    key: 'yField',
    dataKey: 'yField',
    width: 20,
    alignment: 'right',
  },
];

export const memoryUtilizationRequestBody = {
  columns: [
    {
      label: 'name',
      field: QUERY_FIELDS.POD_LABELS_VALUE,
    },
    {
      label: 'pods',
      field: QUERY_FIELDS.POD_COUNT_LABELS_VALUE,
    },
    {
      label: 'namespace',
      field: QUERY_FIELDS.NAMESPACE,
    },
    {
      label: 'memoryUtilisation',
      field: '((cloud_resource_size*fraction)/(1024*1024))',
    },
    {
      label: 'time',
      field: QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME,
    },
  ],
  structColumns: [
    {
      label: 'pod_labels',
      field: QUERY_FIELDS.LABELS,
    },
    {
      label: 'pod_count_labels',
      field: QUERY_FIELDS.LABELS,
    },
  ],
  aggregators: [
    {
      label: 'memoryUtilisation',
      function: AGGREGATORS.AVG,
    },
  ],
  groupBy: ['time', 'name', 'pods', 'namespace'],
  orderBy: [
    {
      label: 'time',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: 'start_time',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: '2023-11-08 11:35:00',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'start_time',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: '2024-01-01',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'pod_labels.key',
          comparator: COMPARATORS.IN,
          value: '("app","component","k8s-app")',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'pod_count_labels.key',
          comparator: COMPARATORS.IN,
          value: '("pod-template-generation")',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'resource_name',
          comparator: COMPARATORS.EQUALS,
          value: 'memory',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'cluster_name',
          comparator: COMPARATORS.IN,
          value: '("granulate-cluster")',
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
  cached: true,
};

export const cpuUtilizationrequestBody = {
  columns: [
    {
      label: 'name',
      field: QUERY_FIELDS.LABELS_VALUE,
    },
    {
      label: 'cpuUtilisation',
      field: '(cloud_resource_size*fraction)',
    },
    {
      label: 'time',
      field: QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME,
    },
    {
      label: 'namespace',
      field: QUERY_FIELDS.NAMESPACE,
    },
  ],
  structColumns: [
    {
      label: 'labels',
      field: QUERY_FIELDS.LABELS,
    },
  ],
  aggregators: [
    {
      label: 'cpuUtilisation',
      function: AGGREGATORS.AVG,
    },
  ],
  groupBy: ['time', 'name', 'namespace'],
  orderBy: [
    {
      label: 'time',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: 'start_time',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: '2023-10-30 10:50:00',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'start_time',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: '2024-01-01',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'labels.key',
          comparator: COMPARATORS.IN,
          value: '("app","component","k8s-app")',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'resource_name',
          comparator: COMPARATORS.EQUALS,
          value: 'cpu',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'cluster_name',
          comparator: COMPARATORS.IN,
          value: '("granulate-cluster")',
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
  cached: true,
};

export const clusterCountCpuRequestBody = {
  columns: [
    {
      label: 'utilization',
      field: `(fraction*100)`,
    },
    {
      label: 'time',
      field: QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME,
    },
    {
      label: 'cluster',
      field: QUERY_FIELDS.CLUSTER_NAME,
    },
  ],
  aggregators: [
    {
      label: 'utilization',
      function: AGGREGATORS.AVG,
    },
  ],
  groupBy: ['time', 'cluster'],
  orderBy: [
    {
      label: 'time',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: 'start_time',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: '2023-01-01',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'start_time',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: '2024-01-01',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'resource_name',
          comparator: COMPARATORS.EQUALS,
          value: 'cpu',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.CLUSTER_NAME,
          comparator: COMPARATORS.IN,
          value: '("granulate-cluster")',
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
  cached: true,
};

export const clusterCountMemoryRequestBody = {
  columns: [
    {
      label: 'utilization',
      field: `(fraction*100)`,
    },
    {
      label: 'time',
      field: QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME,
    },
    {
      label: 'cluster',
      field: QUERY_FIELDS.CLUSTER_NAME,
    },
  ],
  aggregators: [
    {
      label: 'utilization',
      function: AGGREGATORS.AVG,
    },
  ],
  groupBy: ['time', 'cluster'],
  orderBy: [
    {
      label: 'time',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: 'start_time',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: '2023-01-01',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'start_time',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: '2024-01-01',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'resource_name',
          comparator: COMPARATORS.EQUALS,
          value: 'memory',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.CLUSTER_NAME,
          comparator: COMPARATORS.IN,
          value: '("granulate-cluster")',
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
  cached: true,
};

export const clusterCountRequestBody = {
  columns: [
    {
      label: 'name',
      field: QUERY_FIELDS.POD_LABELS_VALUE,
    },
    {
      label: 'pods',
      field: QUERY_FIELDS.POD_COUNT_LABELS_VALUE,
    },
    {
      label: 'namespace',
      field: QUERY_FIELDS.NAMESPACE,
    },
    {
      label: 'cpuUtilisation',
      field: '(cloud_resource_size*fraction)',
    },
    {
      label: 'time',
      field: QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME,
    },
  ],
  structColumns: [
    {
      label: 'pod_labels',
      field: QUERY_FIELDS.LABELS,
    },
    {
      label: 'pod_count_labels',
      field: QUERY_FIELDS.LABELS,
    },
  ],
  aggregators: [
    {
      label: 'cpuUtilisation',
      function: AGGREGATORS.AVG,
    },
  ],
  groupBy: ['time', 'name', 'pods', 'namespace'],
  orderBy: [
    {
      label: 'time',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: 'start_time',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: '2023-01-01',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'start_time',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: '2024-01-01',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'pod_labels.key',
          comparator: COMPARATORS.IN,
          value: '("app","component","k8s-app")',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'pod_count_labels.key',
          comparator: COMPARATORS.IN,
          value: '("pod-template-generation")',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'resource_name',
          comparator: COMPARATORS.EQUALS,
          value: 'cpu',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'cluster_name',
          comparator: COMPARATORS.IN,
          value: '("granulate-cluster")',
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_USAGE,
  cached: true,
};

export const applicationDeatilsAWSRequestBody = {
  columns: [
    {
      label: 'timeStamp',
      field:
        "(DATE_TRUNC('MONTH', from_unixtime(CAST(CAST(timestamp AS BIGINT)/1000 AS BIGINT))))",
    },
    {
      label: 'cpuUtilization',
      field:
        "(AVG(CAST(CASE WHEN pod_cpu_utilization = '' OR pod_cpu_limit = '' THEN null ELSE (CAST(pod_cpu_utilization AS DOUBLE) * CAST(pod_cpu_limit AS DOUBLE)) END AS DOUBLE)) / 1000)",
    },
    {
      label: 'memoryUtilization',
      field:
        "(AVG(CAST(CASE WHEN pod_memory_utilization = '' OR pod_memory_limit = '' THEN null ELSE (CAST(pod_memory_utilization AS DOUBLE) * CAST(pod_memory_limit AS DOUBLE)/(1024*1024)) END AS DOUBLE)))",
    },
    {
      label: 'namespace',
      field: QUERY_FIELDS.NAMESPACE,
    },
    {
      label: 'name',
      field: 'podname',
    },
    {
      label: 'podCount',
      field: 'status_replicas_available',
    },
  ],
  groupBy: ['timeStamp', 'namespace', 'name', 'podCount'],
  filterGroups: [
    {
      filters: [
        {
          field:
            'from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: "(TIMESTAMP '2023-11-06')",
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field:
            'from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: "(TIMESTAMP '2024-01-01')",
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'clustername',
          comparator: COMPARATORS.EQUALS,
          value: 'granulate',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
  cached: true,
};

export const clusterCountAWSRequestBody = {
  columns: [
    {
      label: 'time',
      field:
        "(DATE_TRUNC('MONTH', from_unixtime(CAST(CAST(timestamp AS BIGINT)/1000 AS BIGINT))))",
    },
    {
      label: 'cpuUtilization',
      field:
        "(SUM(CAST(CASE WHEN pod_cpu_utilization = '' OR pod_cpu_limit = '' THEN null ELSE (CAST(pod_cpu_utilization AS DOUBLE) * CAST(pod_cpu_limit AS DOUBLE)) END AS DOUBLE))/ SUM(CAST(pod_cpu_limit AS DOUBLE))) * 100",
    },
    {
      label: 'memoryUtilization',
      field:
        "(AVG(CAST(CASE WHEN pod_memory_utilization = '' THEN null ELSE pod_memory_utilization END AS DOUBLE))) * 100",
    },
  ],
  groupBy: ['time'],
  orderBy: [
    {
      label: 'time',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field:
            'from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: "(TIMESTAMP '2023-01-01')",
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field:
            'from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: "(TIMESTAMP '2024-01-01')",
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'clustername',
          comparator: COMPARATORS.EQUALS,
          value: 'granulate',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
  cached: true,
};
export const podAndCPUCountAWSBodyRequest = {
  columns: [
    {
      label: 'TimeStamp',
      field:
        "(DATE_TRUNC('MONTH', from_unixtime(CAST(CAST(timestamp AS BIGINT)/1000 AS BIGINT))))",
    },
    {
      label: 'CPUUtilization',
      field:
        "(AVG(CAST(CASE WHEN pod_cpu_utilization = '' OR pod_cpu_limit = '' THEN null ELSE (CAST(pod_cpu_utilization AS DOUBLE) * CAST(pod_cpu_limit AS DOUBLE)) END AS DOUBLE)) / 1000)",
    },
    {
      label: 'namespace',
      field: QUERY_FIELDS.NAMESPACE,
    },
    {
      label: 'PodName',
      field: 'podname',
    },
    {
      label: 'PodCount',
      field: 'status_replicas_available',
    },
  ],
  groupBy: ['TimeStamp', 'namespace', 'PodName', 'PodCount'],
  orderBy: [
    {
      label: 'TimeStamp',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field:
            'from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: "(TIMESTAMP '2023-01-01')",
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field:
            'from_unixtime(CAST(CAST(timestamp AS BIGINT) / 1000 AS BIGINT))',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: "(TIMESTAMP '2024-01-01')",
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'clustername',
          comparator: COMPARATORS.EQUALS,
          value: 'granulate',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
  cached: true,
};
