import { numberCommaSeparator } from 'utils/dataFormatterUtils';

const SavingsPerCategoryTableCell = (
  value: any,
  valuePrefix: string,
  record: any
) => {
  return (
    <div className="flex flex-column flex-gap-4">
      <span>{record.category}</span>
      <span className="font-button">
        {valuePrefix + numberCommaSeparator(value)}
      </span>
    </div>
  );
};

export default SavingsPerCategoryTableCell;
