import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/Input';
import AutoComplete from 'components/AutoComplete';
import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { COLORS } from 'constants/graphConfig';
import { INPUT_SIZE, KEYCODE } from 'constants/appearance';
import { CostSavingFilterType } from 'pages/CostOptimizationInsightsPage/types';

import { COMPARATORS_LIST } from './constants';
import { getComparatorsList } from './utils';

import './index.scss';

type FilterFieldsProps = {
  filters: CostSavingFilterType[];
  filter: CostSavingFilterType;
  position: number;
  addOrUpdateFilter: (filter: CostSavingFilterType, position: number) => void;
  removeFilter: (position: number) => void;
};
const FilterFields = ({
  filters,
  filter,
  position,
  addOrUpdateFilter,
  removeFilter,
}: FilterFieldsProps) => {
  const { t } = useTranslation();

  const [showConditionsDropdown, setShowConditionsDropdown] = useState(true);
  const [showValueField, setShowValueField] = useState(false);

  /**
   * @function onKeyDown
   * @description Handles the keydown event on the condition input
   * @param e Keydown event
   */
  const onKeyDown = (e: any) => {
    let newFilter = { ...filter };

    if (e.keyCode === KEYCODE.BACKSPACE) {
      // Remove a last digit of the value if value is added
      if (newFilter.value) {
        newFilter = {
          ...newFilter,
          value: newFilter.value.toString().slice(0, -1),
        };
        setShowValueField(true);
      } else if (newFilter.comparator) {
        // Remove a the comparator if the comparator is present
        newFilter = {
          ...newFilter,
          comparator: '',
        };
        setShowConditionsDropdown(true);
        setShowValueField(false);
      } else {
        removeFilter(position);
      }
    }
    addOrUpdateFilter(newFilter, position);
  };

  /**
   * @function onConditionChange
   * @description Handles when a new condition field (metric or dimension) is added
   * or condition operator is changed or condition value is changed
   * @param value New condition field
   */
  const onConditionChange = (value: string) => {
    let newFilter = { ...filter };
    if (!filter.comparator) {
      newFilter = {
        ...newFilter,
        comparator: value,
      };
      setShowConditionsDropdown(false);
      setShowValueField(true);
    } else {
      newFilter = {
        ...newFilter,
        value: value,
      };
      setShowConditionsDropdown(false);
      setShowValueField(false);
    }
    addOrUpdateFilter(newFilter, position);
  };

  /**
   * @function getOptionsForCondition
   * @description Returns the options for the condition field autocomplete popup
   * @returns Options for the condition field autocomplete popup
   */
  const getOptionsForCondition = () => {
    return [
      {
        label: (
          <span className="font-small-bold">
            {t('customDashboard.optionsLabels.condition')}
          </span>
        ),
        options: getComparatorsList(filters, filter.conjunctToNextFilter).map(
          (comparator) => ({
            label: (
              <span className="font-caption" data-testid={comparator.label}>
                {comparator.label}
              </span>
            ),
            value: comparator.symbol,
            disabled: comparator.disabled,
          })
        ),
      },
    ];
  };

  const valueOverlay = () => (
    <div className="flex flex-column flex-gap-4">
      <span className="font-small-bold">
        {t('customDashboard.optionsLabels.value')}
      </span>
      <Input
        type="number"
        size={INPUT_SIZE.SMALL}
        autoFocus={showValueField}
        ref={(ref) => {
          if (!showConditionsDropdown && showValueField) {
            ref?.focus();
          }
        }}
        value={filter.value}
        onPressEnter={(e: any) => {
          onConditionChange(e.target.value.toString());
        }}
        onBlur={(e: any) => {
          onConditionChange(e.target.value.toString());
        }}
        data-testid="value-field"
      />
    </div>
  );

  return (
    <div
      className="auto-complete-filter-fields flex flex-column flex-gap-8"
      key={position}
      data-testid={`filter-field-${position + 1}`}
    >
      <div className="flex flex-align-items-center flex-gap-8">
        <Tooltip
          overlay={valueOverlay}
          placement="bottom"
          className="full-width"
          open={showValueField}
          trigger={false}
          arrow={false}
        >
          <AutoComplete
            additionalClassNames={`condition-autocomplete full-width ${
              showConditionsDropdown || showValueField ? 'focused' : ''
            }`}
            autoFocus={showConditionsDropdown}
            placeholder={t('customDashboard.optionsLabels.writeCondition')}
            value={`${
              COMPARATORS_LIST.find(
                (comparator) => comparator.symbol === filter.comparator
              )?.label ?? ''
            } ${filter.value}`}
            onKeyDown={onKeyDown}
            open={showConditionsDropdown}
            onFocus={() => {
              if (filter.comparator) {
                setShowValueField(true);
                setShowConditionsDropdown(false);
              } else {
                setShowConditionsDropdown(true);
                setShowValueField(false);
              }
            }}
            onDropdownVisibleChange={(visible: any) => {
              setShowConditionsDropdown(visible);
            }}
            onBlur={() => {
              setShowConditionsDropdown(false);
            }}
            options={getOptionsForCondition()}
            inputSize={INPUT_SIZE.SMALL}
            onSelect={onConditionChange}
            borderless
          />
        </Tooltip>
        <Icon
          iconName={ICONS.SUBTRACT_LINE}
          size={ICONS_SIZE.SM}
          color={COLORS.colorRegentGrey}
          onClick={() => removeFilter(position)}
          dataTestId={`remove-filter-cta-${position + 1}`}
        />
      </div>
      {position !== filters.length - 1 && (
        <span
          className="conjunction"
          data-testid={`conjunction-${filter.conjunctToNextFilter}`}
        >
          {filter.conjunctToNextFilter}
        </span>
      )}
    </div>
  );
};

export default FilterFields;
