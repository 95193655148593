import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isStartWithLowerCaseLetter,
  isStringContainsLowercaseNumbersHyphensUnderscore,
} from 'utils/validations';
import {
  setTagsAndLabelComponent,
  tagsAndLabels,
} from 'redux/tagsAndLabelsSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { TagsType } from 'pages/TagsAndLabelsPage/types';
import { TAGS_AND_LABELS_COMPONENTS } from 'pages/TagsAndLabelsPage/constants';
import {
  createTagsAndLabels,
  getTagsAndLabels,
  updateTagsAndLabels,
} from 'pages/TagsAndLabelsPage/services';
import Loader from 'components/Loader';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import { onApiCallError } from 'utils/handleErrors';

import Header from '../Header';
import TagsAndLabels from '../TagsAndLabels';

import './index.scss';

const UpdateTagsAndLabels = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { selectedTagConnection, selectedBillingAccount } =
    useSelector(tagsAndLabels);

  const [tags, setTags] = useState<TagsType[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [tagsRequestStatus, setTagsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [submitRequestStatus, setSubmitRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [connectionLabelId, setConnectionLabelId] = useState('');

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    if (selectedTagConnection) {
      getAllTagsForConnection();
    }
  }, [selectedTagConnection]);

  /**
   * @function getAllTagsForConnection
   * @description Function to get all the tags for selected connection.
   */
  const getAllTagsForConnection = () => {
    setTagsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectionId: selectedTagConnection?.connectorId,
      billingAccountId: selectedBillingAccount || null,
      cloudType: selectedTagConnection?.provider,
    };

    getTagsAndLabels(params)
      .then((res: any) => {
        if (res?.status === 200) {
          const responseTags = res?.data?.labelList;
          const labelId = res?.data?.connectionLabelId;
          responseTags
            ? setTags(responseTags)
            : setTags([{ key: '', mandatory: false }]);
          setConnectionLabelId(labelId);
          setIsUpdate(responseTags !== null);
          setTagsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setTags([{ key: '', mandatory: false }]);
        setConnectionLabelId('');
        setIsUpdate(false);
        setTagsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setTagsRequestStatus);
        setTags([{ key: '', mandatory: false }]);
        setConnectionLabelId('');
        setIsUpdate(false);
      });
  };

  /**
   * @function onSubmitSuccess
   * @description Function to handle on successfully submitted
   */
  const onSubmitSuccess = () => {
    setSubmitRequestStatus(REQUEST_STATUS.SUCCESS);
    dispatch(setTagsAndLabelComponent(TAGS_AND_LABELS_COMPONENTS.SUCCESS_PAGE));
  };

  /**
   * @function createTagsForConnection
   * @description Function to create new set of tags for the selected connection and refresh the page with new tags.
   */
  const createTagsForConnection = () => {
    setSubmitRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      connectionId: selectedTagConnection?.connectorId,
      billingAccountId: selectedBillingAccount || null,
      labelList: tags,
    };

    createTagsAndLabels(requestBody)
      .then(onSubmitSuccess)
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setSubmitRequestStatus,
          t('tagsOrLabels.failureCreateMessage')
        );
      });
  };

  /**
   * @function updateTagsForConnection
   * @description Function to update the existing tags for the selected connection.
   */
  const updateTagsForConnection = () => {
    setSubmitRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      connectionId: selectedTagConnection?.connectorId,
      billingAccountId: selectedBillingAccount || null,
      connectionLabelId: connectionLabelId,
      labelList: tags,
    };
    updateTagsAndLabels(requestBody)
      .then(onSubmitSuccess)
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setSubmitRequestStatus,
          t('tagsOrLabels.failureUpdateMessage')
        );
      });
  };

  /**
   * @function onSubmitTags
   * @description onSubmit callback for the submit tags button. It's either create new tags or update existing tags.
   */
  const onSubmitTags = () => {
    isUpdate ? updateTagsForConnection() : createTagsForConnection();
  };

  /**
   * @function isSubmitButtonDisabled
   * @description Function to determine the disabled sate of the submit tags button.
   * Disabled when there are no tags for create or tags with empty tag key for create and update.
   * Enabled when there are no tags for update.
   */
  const isSubmitButtonDisabled = () => {
    const duplicateTag = tags.some((eachTag, index1) =>
      tags.some(
        (tagItem, index2) => index1 !== index2 && tagItem.key === eachTag.key
      )
    );

    const overloadedCharacterLimit = tags.some(
      (eachTag) => eachTag.key.length > MAX_CHARACTER_LIMIT
    );

    if (
      duplicateTag ||
      !tags.length ||
      overloadedCharacterLimit ||
      tags?.some(
        (tag) =>
          tag.key.length === 0 ||
          !isStartWithLowerCaseLetter(tag.key) ||
          !isStringContainsLowercaseNumbersHyphensUnderscore(tag.key)
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="update-tags-and-labels">
      <Header
        disableSubmitButton={isSubmitButtonDisabled()}
        onSubmit={onSubmitTags}
        isSubmitting={submitRequestStatus === REQUEST_STATUS.PROCESSING}
        buttonTitle={t('tagsOrLabels.saveSchema')}
      />
      <div className="page-content tags-labels-list">
        {tagsRequestStatus === REQUEST_STATUS.PROCESSING ? (
          <Loader />
        ) : (
          <TagsAndLabels
            connectionName={selectedTagConnection?.name!}
            tags={tags}
            setTags={setTags}
          />
        )}
      </div>
    </div>
  );
};

export default UpdateTagsAndLabels;
