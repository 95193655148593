import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import {
  setServiceNowData,
  setTicketIntegrationData,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';
import {
  costOptimizationInsights,
  setSelectedRecommendations,
} from 'redux/costOptimizationInsightsSlice';
import Button from 'components/Button';
import { BUTTON_SIZE } from 'constants/appearance';
import {
  ServiceNowDefaultData,
  defaultFormData,
} from 'pages/TicketIntegrationPage/types';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import CreateTicketDrawer from 'components/CreateTicketDrawer';
import { getTicketConnection } from 'utils/services';
import { REQUEST_STATUS } from 'constants/requestBody';
import { RecommendationSource } from 'pages/CostOptimizationInsightsPage/constants';
import { onApiCallError } from 'utils/handleErrors';

import { RecommendationList } from '../../types';
import { createSnowTicket } from './utils';

type CreateSnowTicketButtonProps = {
  fetchSnowIncidents: Function;
};

const CreateSnowTicketButton = ({
  fetchSnowIncidents,
}: CreateSnowTicketButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedRecommendations,
    selectedCostOptimizationInsightsConnection,
  } = useSelector(costOptimizationInsights);
  const { serviceNowData } = useSelector(ticketIntegration);

  const [hasTicketIntegration, setHasTicketIntegration] = useState(false);
  const [fetchingDefaultTicketData, setFetchingDefaultTicketData] =
    useState(true);
  const [showCreateTicketDrawer, setShowCreateTicketDrawer] = useState(false);
  const [createTicketLoading, setCreateTicketLoading] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [createTicketValidationMessage, setCreateTicketValidationMessage] =
    useState('');

  useEffect(() => {
    fetchServiceNowConnection();
  }, []);

  /**
   * @function fetchServiceNowConnection
   * @description Function to fetch the service now connection details.
   */
  const fetchServiceNowConnection = () => {
    getTicketConnection()
      .then((res: any) => {
        if (res.status === 200) {
          const data = res.data.responseData;
          dispatch(
            setTicketIntegrationData({
              url: data.endpoint,
              usernameHost: data.username,
              password: data.password,
              setDefault: data.default,
              defaultAssignmentGroup: data.defaultAssigneeGroup,
              defaultAssignedTo: data.defaultAssignTo,
              category: data.defaultCategory,
            })
          );
          setHasTicketIntegration(true);
          setFetchingDefaultTicketData(false);
          return;
        }
        dispatch(setTicketIntegrationData(defaultFormData));
        setHasTicketIntegration(false);
        setFetchingDefaultTicketData(false);
      })
      .catch((e) => {
        onApiCallError(e);
        dispatch(setTicketIntegrationData(defaultFormData));
        setHasTicketIntegration(false);
        setFetchingDefaultTicketData(false);
      });
  };

  /**
   * @function onTicketCreateSuccess
   * @description Callback function for successful ticket creation
   */
  const onTicketCreateSuccess = () => {
    message.success(t('createTicketDrawer.ticketCreateSuccess'));
    dispatch(setSelectedRecommendations([]));
    fetchSnowIncidents();
    setShowCreateTicketDrawer(false);
    setCreateTicketLoading(REQUEST_STATUS.SUCCESS);
  };

  /**
   * @function onTicketCreateError
   * @description Callback function for failure to create ticket
   */
  const onTicketCreateError = (errorMessage: string) => {
    setCreateTicketLoading(REQUEST_STATUS.ERROR);
    setCreateTicketValidationMessage(errorMessage);
  };

  /**
   * @function onClickCreateTicket
   * @description Callback function for create ticket
   */
  const onClickCreateTicket = () => {
    setCreateTicketLoading(REQUEST_STATUS.PROCESSING);

    const cspRecommendations = (
      selectedRecommendations as RecommendationList[]
    ).filter((item) => item.recommendationSource === RecommendationSource.CSP);

    const granulateRecommendations = (
      selectedRecommendations as RecommendationList[]
    ).filter(
      (item) => item.recommendationSource === RecommendationSource.GRANULATE
    );

    if (cspRecommendations.length) {
      createSnowTicket(
        selectedCostOptimizationInsightsConnection!,
        serviceNowData,
        cspRecommendations,
        RecommendationSource.CSP,
        onTicketCreateSuccess,
        onTicketCreateError
      );
    }

    if (granulateRecommendations.length) {
      createSnowTicket(
        selectedCostOptimizationInsightsConnection!,
        serviceNowData,
        granulateRecommendations,
        RecommendationSource.GRANULATE,
        onTicketCreateSuccess,
        onTicketCreateError
      );
    }
  };

  const getCreateTicketsCtaLabel = () => {
    if (fetchingDefaultTicketData) {
      return t('costOptimizationInsight.recommendationTableHeader.loading');
    }

    if (hasTicketIntegration) {
      return t(
        'costOptimizationInsight.recommendationTableHeader.createTicket'
      );
    }

    return t(
      'costOptimizationInsight.recommendationTableHeader.ticketIntegrationError'
    );
  };

  const onClickCreateSnowTicketCta = () => {
    if (hasTicketIntegration) {
      dispatch(setServiceNowData(ServiceNowDefaultData));
      setShowCreateTicketDrawer(true);
      return;
    }

    navigate(NAVIGATION_MENU_PATH.TICKET_INTEGRATION);
  };

  return (
    <div>
      <Button
        className={`create-ticket-cta ${
          !hasTicketIntegration && !fetchingDefaultTicketData && 'error'
        }`}
        size={BUTTON_SIZE.SMALL}
        title={getCreateTicketsCtaLabel()}
        onClick={onClickCreateSnowTicketCta}
        disabled={!hasTicketIntegration || selectedRecommendations.length === 0}
      />
      <CreateTicketDrawer
        show={showCreateTicketDrawer}
        setShow={setShowCreateTicketDrawer}
        fetchServiceNowConnection={fetchServiceNowConnection}
        selectedRecommendationsLength={selectedRecommendations.length}
        onClickCreate={onClickCreateTicket}
        loading={createTicketLoading === REQUEST_STATUS.PROCESSING}
        validationMessage={createTicketValidationMessage}
      />
    </div>
  );
};

export default CreateSnowTicketButton;
