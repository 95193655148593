import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectTheme } from 'redux/themeSlice';
import GraphHeader from 'components/GraphHeader';
import { QUARTER_COST_SUMMARY } from 'constants/graphLabels';
import { getPercentageDifference } from 'utils/dashboardUtils';
import DashboardComponent from 'components/DashboardComponent';
import { evaluateRequestArray } from 'utils/handleErrors';
import BarChart from 'components/BarChart';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { selectCommonUtility } from 'redux/commonUtilitySlice';

type QuarterCostSummaryProps = {
  currentQuarterCost: number;
  previousQuarterCost: number;
  requestStatus: string[];
  pdfView?: boolean;
};

const QuarterCostSummary = ({
  currentQuarterCost,
  previousQuarterCost,
  requestStatus,
  pdfView,
}: QuarterCostSummaryProps) => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const getComponent = () => (
    <BarChart
      data={[
        {
          quarter: QUARTER_COST_SUMMARY[0],
          cost: currentQuarterCost,
        },
        { quarter: QUARTER_COST_SUMMARY[1], cost: previousQuarterCost },
      ]}
      xField="cost"
      yField="quarter"
      groupingField="quarter"
      showLabels={false}
      showLegends={false}
      xTitle={t('quarterCostSummary.cost', { currencySymbol: currencySymbol })}
      colorOverride={[theme.primaryColor, theme.secondaryColor]}
      prefixSymbol={currencySymbol}
    />
  );

  return (
    <div
      className="gcp-quarter-cost graph-card flex flex-column flex-gap-12"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.costByQuarter')}
        comparisonBelowHeading
        comparisonValue={getPercentageDifference(
          currentQuarterCost,
          previousQuarterCost
        )}
        comparisonFrom={t('costComparisonCard.fromPreviousQuarter')}
        graphName="quarter-cost-summary"
        isDownloadable={!pdfView}
        isTableView={false}
        showExpandIcon={!pdfView}
        designVersion2
      />
      <div className="horizontal-rule" />
      <div className="flex">
        <div className="flex flex-column flex-gap-8 width-50">
          <div className="flex flex-gap-4">
            <div
              className="legend-color"
              style={{ background: theme.primaryColor }}
            />
            <span className="legend-text font-caption-bold">
              {QUARTER_COST_SUMMARY[0]}
            </span>
          </div>
          <span className="modal-heading">
            ${numberCommaSeparator(currentQuarterCost)}
          </span>
        </div>
        <div className="flex flex-column flex-gap-8 width-50">
          <div className="flex flex-gap-4">
            <div
              className="legend-color"
              style={{ background: theme.secondaryColor }}
            />
            <span className="legend-text font-caption-bold">
              {QUARTER_COST_SUMMARY[1]}
            </span>
          </div>
          <span className="modal-heading">
            ${numberCommaSeparator(previousQuarterCost)}
          </span>
        </div>
      </div>
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray(requestStatus)}
      />
    </div>
  );
};

export default QuarterCostSummary;
