import { useDispatch, useSelector } from 'react-redux';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import SearchInput from 'components/Input';
import { BUTTON_TYPE } from 'constants/appearance';
import { TagsType, TagView, ValueLabelType } from 'types/dashboard';
import { selectDashboard, setTagsFilters } from 'redux/dashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import { TAG_FILTER_LABEL_LENGTH_LIMIT } from 'constants/userConsole';

import './index.scss';

const { Option } = Select;

type EachTagProps = {
  tagData: TagsType;
  allValues: ValueLabelType[];
};

const EachTag = ({ tagData, allValues }: EachTagProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { theme } = useSelector(selectTheme);

  const [isOpen, setIsOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [selectedValues, setSelectedValues] = useState<ValueLabelType[]>([]);
  const [filteredValues, setFilteredValues] = useState<
    ValueLabelType[] | undefined
  >([]);

  const { tagsFilters, selectedDashboardView, selectedDashboard } =
    useSelector(selectDashboard);

  useEffect(() => {
    setFilteredValues(allValues);
  }, [allValues]);

  useEffect(() => {
    setSelectedValues(tagData.values);
  }, [tagData]);

  useEffect(() => {
    if (searchKey)
      setFilteredValues(
        allValues.filter((item) =>
          (item?.label ?? item.value)?.toLowerCase()?.includes(searchKey)
        )
      );
    if (searchKey === '') setFilteredValues(allValues);
  }, [searchKey]);

  /**
   * @function onSelectAll
   * @description Callback function for selecting All checkbox
   * @param e Event for checkbox onChange
   */
  const onSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedValues([...allValues]);
      return;
    }
    setSelectedValues([]);
  };

  /**
   * @function onSelectCheckbox
   * @description Callback function for selecting each checkbox
   * @param e Event for checkbox onChange
   * @param value value to be added or removed
   */
  const onSelectCheckbox = (e: CheckboxChangeEvent, value: ValueLabelType) => {
    if (e.target.checked) {
      setSelectedValues([...selectedValues, value]);
      return;
    }
    setSelectedValues(
      selectedValues.filter((eachValue) => eachValue.value !== value.value)
    );
  };

  /**
   * @function onClickApply
   * @description Function to handle apply
   * @param removeTag boolean value to check function is used to remove tag
   */
  const onClickApply = (removeTag: boolean) => {
    setIsOpen(false);
    const filteredViewTags: TagView[] | undefined = tagsFilters
      .find((tagFilter) => tagFilter.dashboardId === selectedDashboard?.id)
      ?.tagViews.filter((viewTag) => viewTag.viewId !== selectedDashboardView);

    const OtherTagsData: TagsType[] | undefined = tagsFilters
      .find((tagFilter) => tagFilter.dashboardId === selectedDashboard?.id)
      ?.tagViews.find((viewTag) => viewTag.viewId === selectedDashboardView)
      ?.tags.filter((eachTag) => eachTag.key !== tagData.key);

    const tagPosition: number = tagsFilters
      .find((tagFilter) => tagFilter.dashboardId === selectedDashboard?.id)
      ?.tagViews.find((viewTag) => viewTag.viewId === selectedDashboardView)
      ?.tags.findIndex((eachTag) => eachTag.key === tagData.key)!;

    if (filteredViewTags && OtherTagsData) {
      const filteredTagData = [...OtherTagsData];
      if (selectedValues.length > 0 && !removeTag) {
        filteredTagData.splice(tagPosition, 0, {
          key: tagData.key,
          values: selectedValues,
        });
      }
      dispatch(
        setTagsFilters([
          ...tagsFilters.filter(
            (tagFilter) => tagFilter.dashboardId !== selectedDashboard?.id
          ),
          {
            dashboardId: selectedDashboard?.id ?? '',
            tagViews: [
              ...filteredViewTags,
              {
                viewId: selectedDashboardView,
                tags: filteredTagData,
              },
            ],
          },
        ])
      );
    }
  };

  const tagRenderValue = () => {
    const allTagValues = selectedValues
      .map((tag) => tag.label ?? tag.value)
      .join(',');
    if (allTagValues.length > TAG_FILTER_LABEL_LENGTH_LIMIT)
      return `${allTagValues.substring(0, TAG_FILTER_LABEL_LENGTH_LIMIT)}...`;
    return allTagValues;
  };

  const tagRenderSuffix = (
    <div className="flex flex-align-items-center">
      <div className="count font-caption flex flex-center">
        {tagData.values.length}
      </div>
    </div>
  );

  const getTagRender = () => (
    <div className="dashboard-tag-content flex flex-gap-8">
      {tagRenderValue()}
      {tagRenderSuffix}
    </div>
  );

  const getDropdownRender = (menu: ReactElement) => (
    <div className="tag-dropdown-wrapper">
      <SearchInput
        value={searchKey}
        placeholder={t('dashboardLabels.tagsLabels.searchByValue')}
        type="search"
        onChange={(e: any) => setSearchKey(e.target.value)}
      />
      {menu}
      <Divider />
      <div className="flex flex-align-items-center flex-end flex-gap-8">
        <Button
          title={t('dashboardLabels.tagsLabels.clearAll')}
          type={BUTTON_TYPE.LINK}
          onClick={() => onClickApply(true)}
        />
        <Button
          title={t('dashboardLabels.tagsLabels.apply')}
          onClick={() => onClickApply(false)}
        />
      </div>
    </div>
  );

  return (
    <Select
      value={tagData.key}
      mode="multiple"
      showSearch={false}
      onDropdownVisibleChange={(open) => {
        setIsOpen(open);
        if (!open) {
          setFilteredValues(allValues);
          setSelectedValues(tagData.values);
        }
      }}
      tagRender={getTagRender}
      open={isOpen}
      popupClassName="dashboard-tag-dropdown"
      dropdownStyle={
        {
          '--button-icon-color': theme.buttonIconColor,
        } as React.CSSProperties
      }
      dropdownRender={getDropdownRender}
    >
      {!searchKey && (
        <Option>
          <Checkbox
            value={'ALL'}
            key={'ALL'}
            checked={allValues.every((eachValue) =>
              selectedValues.map((item) => item.value).includes(eachValue.value)
            )}
            onChange={onSelectAll}
          >
            {t('dashboardLabels.tagsLabels.all')}
          </Checkbox>
        </Option>
      )}
      {filteredValues?.map((item) => (
        <Option key={item.value}>
          <Checkbox
            value={item.value}
            checked={selectedValues.some((item1) => item1.value === item.value)}
            onChange={(e: CheckboxChangeEvent) => onSelectCheckbox(e, item)}
          >
            {item?.label ?? item.value}
          </Checkbox>
        </Option>
      ))}
    </Select>
  );
};

export default EachTag;
