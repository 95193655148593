import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AccessibleDiv from 'components/AccessibleDiv';

import './index.scss';

type TagsListProps = {
  tags: string[];
  maxTagsToShow?: number;
  showMore?: boolean;
};

const TagsList = ({ tags, maxTagsToShow = 5, showMore }: TagsListProps) => {
  const { t } = useTranslation();
  const [tagsToShow, setTagsToShow] = useState<string[]>([]);

  useEffect(() => {
    filterTagsByMaxCount();
  }, [tags, maxTagsToShow]);

  const filterTagsByMaxCount = () => {
    return setTagsToShow(tags.slice(0, maxTagsToShow));
  };

  if (!tags.length) return <>--</>;

  return (
    <div className="tags-list flex flex-align-items-center flex-wrap">
      {tagsToShow.map((tag) => (
        <div className="tag-item" key={tag}>
          {tag}
        </div>
      ))}
      {tagsToShow.length < tags.length && (
        <div className="flex flex-align-items-center">
          <div className="tag-item">+{tags.length - tagsToShow.length}</div>
          {showMore && (
            <AccessibleDiv
              className="tag-item link cursor-pointer"
              onClick={() => setTagsToShow(tags)}
              data-testid="show-more-cta"
            >
              {t('loadMore')}
            </AccessibleDiv>
          )}
        </div>
      )}
      {tagsToShow.length > maxTagsToShow && (
        <AccessibleDiv
          className="tag-item link cursor-pointer"
          onClick={() => filterTagsByMaxCount()}
          data-testid="show-less-cta"
        >
          {t('loadLess')}
        </AccessibleDiv>
      )}
    </div>
  );
};

export default TagsList;
