import i18n from 'i18n';
import { ReportSharePermissions } from 'pages/CreateReportPage/constants';

export const ShareReportPermissionOptions = [
  {
    label: i18n.t('reports.read'),
    value: ReportSharePermissions.READ,
  },
  {
    label: i18n.t('reports.write'),
    value: ReportSharePermissions.WRITE,
  },
];
