import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGranulate,
  setGranulateConnectionView,
} from 'redux/granulateSlice';
import SuccessComponent from 'components/SuccessComponent';
import ErrorComponent from 'components/ErrorComponent';
import NavigationPath from 'components/NavigationPath';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { GranulateConnectionView } from 'constants/granulate';
import { GranulateIcon, SuccessIcon } from 'assets/icons';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import GranulateConnectionTable from './TableView';
import GranulateAddConnection from './AddConnection';

import './index.scss';

const GranulateConnectionPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { granulateConnectionView } = useSelector(selectGranulate);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
      dispatch(setGranulateConnectionView(GranulateConnectionView.TABLE_VIEW));
    };
  }, []);

  const setToConnectionForm = () => {
    dispatch(
      setGranulateConnectionView(GranulateConnectionView.CONNECTION_FORM)
    );
  };

  const getComponent = () => {
    switch (granulateConnectionView) {
      case GranulateConnectionView.TABLE_VIEW:
        return <GranulateConnectionTable />;
      case GranulateConnectionView.CONNECTION_FORM:
        return <GranulateAddConnection />;
      case GranulateConnectionView.SUCCESS:
        return (
          <SuccessComponent
            mainTitle={t('granulateConnectionPage.success.mainTitle')}
            subTitle={t('granulateConnectionPage.success.subTitle')}
            buttonTitle={t('granulateConnectionPage.success.buttonTitle')}
            linkTitle={t('granulateConnectionPage.success.linkTitle')}
            onHandleButtonClick={() =>
              dispatch(
                setGranulateConnectionView(GranulateConnectionView.TABLE_VIEW)
              )
            }
            onHandleLinkClick={setToConnectionForm}
            icon={<SuccessIcon />}
          />
        );
      case GranulateConnectionView.ERROR:
        return (
          <ErrorComponent
            mainTitle={t('granulateConnectionPage.error.mainTitle')}
            subTitle={t('granulateConnectionPage.error.subTitle')}
            buttonTitle={t('granulateConnectionPage.error.buttonTitle')}
            onHandleButtonClick={setToConnectionForm}
          />
        );
    }
  };

  return (
    <div className="granulate-connection">
      <div className="granulate-connection-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="flex flex-align-items-center flex-gap-8">
          <Icon icon={GranulateIcon} className="icon" />
          <div className="text-group flex flex-column">
            <span className="modal-heading">
              {granulateConnectionView === GranulateConnectionView.TABLE_VIEW
                ? t('subMenu.connections')
                : t('subMenu.configure')}
            </span>
            <span className="font-caption-bold">{t('subMenu.granulate')}</span>
          </div>
        </div>
        {granulateConnectionView === GranulateConnectionView.TABLE_VIEW && (
          <Button title={t('installsAgent')} onClick={setToConnectionForm} />
        )}
      </div>
      <div className="page-content">
        <NavigationPath />
        {getComponent()}
      </div>
    </div>
  );
};

export default GranulateConnectionPage;
