import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { uniqBy } from 'lodash';

import Button from 'components/Button';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import Checkbox from 'components/Checkbox';
import { BUTTON_TYPE } from 'constants/appearance';
import {
  defaultBillingMapData,
  DROPDOWN_NONE,
  DROPDOWN_NONE_OPTION,
} from 'pages/BillingMappingPage/constants';
import { BillingMappingType } from 'pages/BillingMappingPage/types';
import {
  createBillingMapping,
  updateBillingMapping,
  validateBillingDimensionName,
} from 'pages/BillingMappingPage/services';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { PROVIDER } from 'constants/cloudProviders';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { FIELD_TYPE } from 'constants/dashboard';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { AvailableFieldsType } from 'types/dashboard';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';

import './index.scss';

type AddOrEditBillingMappingModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  editData?: BillingMappingType;
  onClickSubmit: Function;
  billingFields: { [key: string]: AvailableFieldsType[] };
  fetchAllFieldsReqStatus: string;
};

const AddOrEditBillingMappingModal = ({
  show,
  setShow,
  editData,
  onClickSubmit,
  billingFields,
  fetchAllFieldsReqStatus,
}: AddOrEditBillingMappingModalProps) => {
  const { t } = useTranslation();

  const [billingMapData, setBillingMapData] = useState<BillingMappingType>(
    defaultBillingMapData
  );
  const [billingNameValidation, setBillingNameValidation] = useState('');
  const [fieldMappingValidation, setFieldMappingValidation] = useState('');
  const [useAsMetricValidation, setUseAsMetricValidation] = useState('');
  const [createBillingMapReqStatus, setCreateBillingMapReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isDuplicateBillingName, setIsDuplicateBillingName] = useState(false);

  useEffect(() => {
    if (editData) {
      setBillingMapData(editData);
    }
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (
      !billingMapData.dimensionName ||
      billingMapData.dimensionName === editData?.dimensionName
    ) {
      setIsDuplicateBillingName(false);
      return;
    }

    validateDuplicateBillingDimensionName(billingMapData.dimensionName, source);

    return () => {
      source.cancel();
    };
  }, [billingMapData.dimensionName]);

  useEffect(() => {
    if (isDuplicateBillingName && !billingNameValidation) {
      setBillingNameValidation(t('billingMapping.duplicateBillingName'));
    }
  }, [isDuplicateBillingName, billingNameValidation]);

  /**
   * @function validateDuplicateBillingDimensionName
   * @description Function to validate the duplicate billing dimension name
   * @param billingName string value to be validated
   * @param source cancel token source
   */
  const validateDuplicateBillingDimensionName = (
    billingName: string,
    source: CancelTokenSource
  ) => {
    validateBillingDimensionName(billingName, source.token)
      .then((res: any) => {
        const isDuplicate = res?.data?.responseData;
        setIsDuplicateBillingName(isDuplicate);
        isDuplicate &&
          setBillingNameValidation(t('billingMapping.duplicateBillingName'));
      })
      .catch((error) => {
        onApiCallError(error);
      });
  };

  /**
   * @function validateBillingName
   * @description Function to validate the billing name
   * @param value string value to be validated
   * @returns true if the validation is successful, else false
   */
  const validateBillingName = (value: string) => {
    if (
      validateEmptyField(
        value?.trim(),
        t('billingMapping.billingName'),
        setBillingNameValidation
      )
    ) {
      return false;
    }

    if (
      validateStringLengthLessThan(
        value?.trim(),
        MAX_CHARACTER_LIMIT,
        t('billingMapping.billingName'),
        setBillingNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value.trim(),
        t('billingMapping.billingName'),
        setBillingNameValidation
      )
    ) {
      return false;
    }

    if (isDuplicateBillingName) {
      setBillingNameValidation(t('billingMapping.duplicateBillingName'));
      return false;
    }

    return true;
  };

  /**
   * @function validateFieldMapping
   * @description Function to validate the field mapping
   * @returns true if the validation is successful, else false
   */
  const validateFieldMapping = () => {
    if (billingMapData?.billingDimensionMap?.length === 0) {
      setFieldMappingValidation(t('billingMapping.chooseBillingField'));
      return false;
    }

    setFieldMappingValidation('');
    return true;
  };

  /**
   * @function validateUseAsMetricField
   * @description Function to validate the use as metric field
   * @param isChecked boolean to indicate if the checkboc is checked or not
   * @returns true if the validation is successful, else false
   */
  const validateUseAsMetricField = (isChecked: boolean) => {
    if (
      !isChecked ||
      billingMapData?.billingDimensionMap?.every(
        (item) => item.fieldType === FIELD_TYPE.NUMERIC
      )
    ) {
      setUseAsMetricValidation('');
      return true;
    }

    setUseAsMetricValidation(t('billingMapping.useAsMetricValidation'));
    return false;
  };

  /**
   * @function validateForm
   * @description Function to validate the form fields
   * @returns true if the validation is successful, else false
   */
  const validateForm = () => {
    let validation = true;

    validation =
      validateBillingName(billingMapData.dimensionName) && validation;

    validation = validateFieldMapping() && validation;

    validation =
      validateUseAsMetricField(billingMapData.metricUsage) && validation;

    return validation;
  };

  const onCreateOrUpdateError = (errorMessage?: string) => {
    const defaultMessage = editData
      ? t('billingMapping.errorUpdatingDimension')
      : t('billingMapping.errorCreatingDimension');
    message.error({
      content: errorMessage ?? defaultMessage,
      key: ERROR_KEY,
    });
    setCreateBillingMapReqStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function onClickAddOrUpdate
   * @description Function to add or update the billing map fields
   */
  const onClickAddOrUpdate = () => {
    if (!validateForm()) {
      return;
    }

    setCreateBillingMapReqStatus(REQUEST_STATUS.PROCESSING);

    (editData ? updateBillingMapping : createBillingMapping)(billingMapData)
      .then((res: any) => {
        if (res?.status === 200) {
          message.success({
            content: editData
              ? t('billingMapping.successUpdatingDimension', {
                  dimensionName: billingMapData.dimensionName,
                })
              : t('billingMapping.successCreatingDimension', {
                  dimensionName: billingMapData.dimensionName,
                }),
            key: SUCCESS_KEY,
          });
          onClickSubmit();
          setCreateBillingMapReqStatus(REQUEST_STATUS.SUCCESS);
          setShow(false);
          return;
        }

        onCreateOrUpdateError(res?.data?.message);
      })
      .catch((e) => {
        onApiCallError(e, false);
        onCreateOrUpdateError(e?.response?.data?.message);
      });
  };

  /**
   * @function getFieldType
   * @description Function to fetch the field type of a field
   * @param field field for which the field is required
   * @param provider provider for which the field is selected
   * @returns string field type. Defaults to 'LITERAL'
   */
  const getFieldType = (field: string, provider: string) => {
    return (
      billingFields[provider]?.find((item) => item.name === field)?.category ??
      FIELD_TYPE.LITERAL
    );
  };

  /**
   * @function onSelectFieldMapping
   * @description Callback function for selecting the field mapping
   * @param field selected field value
   * @param provider provider for which the field is selected
   */
  const onSelectFieldMapping = (field: string, provider: string) => {
    const billingDimensionMap = [
      ...billingMapData.billingDimensionMap.filter(
        (item) => item.provider !== provider
      ),
    ];

    if (field !== DROPDOWN_NONE) {
      billingDimensionMap.push({
        provider: provider,
        billingField: field,
        fieldType: getFieldType(field, provider),
      });
    }

    setBillingMapData({
      ...billingMapData,
      billingDimensionMap: billingDimensionMap,
    });
  };

  /**
   * @function getSelectedFieldByProvider
   * @description Function to fetch the field name by provider
   * @param provider provider for which the field is fetched
   * @returns field name else NONE
   */
  const getSelectedFieldByProvider = (provider: string) => {
    return (
      billingMapData?.billingDimensionMap?.find(
        (item) => item.provider === provider
      )?.billingField ?? DROPDOWN_NONE
    );
  };

  /**
   * @function getAllDropdownFieldsByProvider
   * @description Function to fetch all the fields by provider for dropdown
   * @param provider provider for which the fields are fetched
   * @returns list of label value map
   */
  const getAllDropdownFieldsByProvider = (provider: string) => {
    const uniqueFields = uniqBy(
      billingFields[provider]?.map((item) => ({
        label: item.name,
        value: item.name,
      })),
      (obj) => obj.value
    );

    return [DROPDOWN_NONE_OPTION, ...uniqueFields];
  };

  return (
    <DrawerComponent
      className="billing-mapping-drawer"
      open={show}
      title={t('billingMapping.billingMapping')}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-gap-8 flex-end">
          <Button
            title={t('billingMapping.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={
              editData ? t('billingMapping.update') : t('billingMapping.save')
            }
            onClick={onClickAddOrUpdate}
            loading={createBillingMapReqStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
      }
      dataTestId="add-billing-map-drawer"
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel title={t('billingMapping.billingName')} required />
          <Input
            value={billingMapData.dimensionName}
            placeholder={t('billingMapping.enterBillingName')}
            onChange={(e: any) => {
              setBillingMapData({
                ...billingMapData,
                dimensionName: e.target.value,
              });
              validateBillingName(e.target.value);
            }}
            onBlur={() => {
              validateBillingName(billingMapData.dimensionName);
            }}
          />
          <span
            style={{
              display: getValidationStyle(billingNameValidation),
            }}
            className="font-validation-error"
          >
            {billingNameValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('billingMapping.fieldMapping')} required />
          <div className="flex flex-column flex-gap-12">
            {Object.values(PROVIDER)
              .filter((provider) => provider !== PROVIDER.OCI)
              .map((provider) => (
                <SelectDropdown
                  key={provider}
                  value={getSelectedFieldByProvider(provider)}
                  options={getAllDropdownFieldsByProvider(provider)?.map(
                    (item) => ({
                      label: (
                        <div className="flex flex-align-items-center flex-gap-4">
                          <img
                            className="provider-icon"
                            src={getProviderSmallLogo(provider)}
                            alt={`${provider} Logo`}
                          />
                          <span>{item.label}</span>
                        </div>
                      ),
                      value: item.value,
                    })
                  )}
                  popupClassName="field-mapping-overlay"
                  showSearch
                  onSelect={(value: string) =>
                    onSelectFieldMapping(value, provider)
                  }
                  loading={
                    fetchAllFieldsReqStatus === REQUEST_STATUS.PROCESSING
                  }
                  designVersion2
                />
              ))}
          </div>
          <span
            style={{
              display: getValidationStyle(fieldMappingValidation),
            }}
            className="font-validation-error"
          >
            {fieldMappingValidation}
          </span>
        </div>
        <div>
          <div className="flex flex-align-items-center flex-gap-8">
            <Checkbox
              checked={billingMapData.metricUsage}
              onChange={(e) => {
                setBillingMapData({
                  ...billingMapData,
                  metricUsage: e.target.checked,
                });
                validateUseAsMetricField(e.target.checked);
              }}
              data-testid="metrix-consent-checkbox"
            />
            <div className="font-caption-bold">
              {t('billingMapping.useAsMetric')}
            </div>
          </div>
          <span
            style={{
              display: getValidationStyle(useAsMetricValidation),
            }}
            className="font-validation-error"
          >
            {useAsMetricValidation}
          </span>
        </div>
      </section>
    </DrawerComponent>
  );
};

export default AddOrEditBillingMappingModal;
