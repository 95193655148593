import i18n from 'i18n';
import { SnowflakeIntegrationType } from 'pages/IntegrationsPage/types';

export const PROGRESS_BAR_ITEMS = [
  {
    title: i18n.t('integrations.titleFirst'),
    description: i18n.t('integrations.descriptionFirst'),
  },
  {
    title: i18n.t('integrations.titleSecond'),
    description: i18n.t('integrations.descriptionSecond'),
  },
];

export const defaultSnowflakeFormValues: SnowflakeIntegrationType = {
  name: '',
  serverURL: '',
  username: '',
  password: '',
  database: '',
  schema: '',
};
