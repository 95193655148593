import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDashboard } from 'redux/dashboardSlice';
import GCPConsolidatedCostSummaryDashboard from './components/GCPConsolidatedCostSummaryDashboard';

const ConsolidatedCostSummaryDashboard = () => {
  const { t } = useTranslation();
  const { selectedGroupMetaData } = useSelector(selectDashboard);

  if (
    !selectedGroupMetaData?.connectorDtos.some(
      (connection) => connection.wantBilling
    )
  ) {
    return <div>{t('dashboardLabels.noBillingConnection')}</div>;
  }

  return <GCPConsolidatedCostSummaryDashboard />;
};

export default ConsolidatedCostSummaryDashboard;
