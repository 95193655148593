import { QUERY_FIELDS } from 'constants/requestBody';

export const RECOMMENDATION_QUERY_COLUMNS = [
  {
    label: 'projectNumber',
    field: QUERY_FIELDS.CLOUD_ENTITY_ID,
  },
  {
    label: 'priority',
    field: QUERY_FIELDS.PRIORITY,
  },
  {
    label: 'recommendation',
    field: QUERY_FIELDS.DESCRIPTION,
  },
  {
    label: 'lastRefreshed',
    field: QUERY_FIELDS.LAST_REFRESH_TIME,
  },
  {
    label: 'costSavings',
    field:
      'IF((IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1=-0,0,(IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1)',
  },
  {
    label: 'recommender',
    field: QUERY_FIELDS.RECOMMENDER,
  },
  {
    label: 'recommender_subtype',
    field: QUERY_FIELDS.RECOMMENDER_SUBTYPE,
  },
  {
    label: 'recommendationName',
    field: QUERY_FIELDS.NAME,
  },
  {
    label: 'recommendationState',
    field: QUERY_FIELDS.STATE,
  },
  {
    label: 'targetResource',
    field: QUERY_FIELDS.TARGET_RESOURCES,
  },
  {
    label: 'recommendationCategory',
    field: QUERY_FIELDS.CATEGORY,
  },
];

export const RULESET_FILTERS_ADDITIONAL_QUERY_COLUMNS = [
  {
    label: 'currentMachineType',
    field:
      "REPLACE(JSON_EXTRACT(recommendation_details, '$.overview.currentMachineType.name'),'\"','')",
  },
  {
    label: 'recommendedMachineType',
    field:
      "REPLACE(JSON_EXTRACT(recommendation_details, '$.overview.recommendedMachineType.name'),'\"','')",
  },
  {
    label: 'commitmentYear',
    field:
      "CASE REPLACE(JSON_EXTRACT(recommendation_details, '$.overview.plan'),'\"','') WHEN 'TWELVE_MONTH' THEN '1' WHEN 'THIRTY_SIX_MONTH' THEN '3' ELSE '0' END",
  },
  {
    label: 'commitmentType',
    field:
      "REPLACE(JSON_EXTRACT(recommendation_details, '$.overview.type'),'\"','')",
  },
];
