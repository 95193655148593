import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import axios, { CancelTokenSource } from 'axios';

import {
  selectReport,
  setIsReportNameExists,
  setReportNameValidation,
  setReportOptions,
} from 'redux/reportSlice';
import { selectTheme } from 'redux/themeSlice';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import Input from 'components/Input';
import AccessibleDiv from 'components/AccessibleDiv';
import { ReportsViewType } from 'pages/CreateReportPage/constants';
import EditSubscriptionDrawer from 'pages/SubscriptionsPage/components/EditSubscriptionDrawer';
import { SubscriptionFrequency } from 'pages/SubscriptionsPage/constants';
import { INPUT_SIZE } from 'constants/appearance';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { CHART_TYPES } from 'constants/graphConfig';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import {
  numberCommaSeparator,
  replaceAllSpecialCharactersBy,
} from 'utils/dataFormatterUtils';
import { DATE_FORMAT, DD_MMM_YYYY } from 'utils/date';
import { REQUEST_STATUS } from 'constants/requestBody';
import { validateReportName } from 'pages/CreateReportPage/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  getDimensionDisplayText,
  modifyDimensionLabelForCustomQuery,
} from 'pages/CreateReportPage/utils';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';

import ShareReportModal from '../ShareReportModal';

import './index.scss';

type ReportTableOrChartHeaderProps = {
  isTableView: boolean;
  setIsTableView: (val: boolean) => void;
  pdfView?: boolean;
};

const ReportTableOrChartHeader = ({
  isTableView,
  setIsTableView,
  pdfView,
}: ReportTableOrChartHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    reportOptions,
    reportView,
    reportData,
    reportConnection,
    reportGroup,
    reportNameValidation,
    existingReport,
  } = useSelector(selectReport);
  const { theme } = useSelector(selectTheme);

  const [showEditTitle, setShowEditTitle] = useState(false);
  const [titleValidationReqStatus, setTitleValidationReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (
      existingReport &&
      existingReport.reportName === reportOptions.reportName
    ) {
      dispatch(setIsReportNameExists(false));
    } else if (reportOptions.reportName) {
      validateDuplicateReportName(source);
    }

    return () => {
      source?.cancel();
    };
  }, [reportOptions.reportName, existingReport]);

  /**
   * @function validateDuplicateReportName
   * @description Function to validate the duplicate name
   * @param cancelTokenSource cancel token
   */
  const validateDuplicateReportName = (
    cancelTokenSource: CancelTokenSource
  ) => {
    setTitleValidationReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      reportName: reportOptions.reportName,
    };

    validateReportName(params, cancelTokenSource?.token)
      .then((res: any) => {
        if (res?.data?.responseData) {
          dispatch(setIsReportNameExists(false));
        } else {
          dispatch(setIsReportNameExists(true));
          dispatch(
            setReportNameValidation(t('reports.duplicateReportNameError'))
          );
        }
        setTitleValidationReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTitleValidationReqStatus);
      });
  };

  /**
   * @function validateTitle
   * @description Function to validate report title
   * @param name name input
   */
  const validateTitle = (name: string) => {
    if (
      validateEmptyField(name, t('reports.reportName'), (value: string) =>
        dispatch(setReportNameValidation(value))
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        name,
        t('reports.reportName'),
        (value: string) => dispatch(setReportNameValidation(value))
      )
    ) {
      return false;
    }

    if (
      validateStringLengthLessThan(
        name,
        MAX_CHARACTER_LIMIT,
        t('reports.reportName'),
        (value: string) => dispatch(setReportNameValidation(value))
      )
    ) {
      return false;
    }

    return true;
  };

  /**
   * @function onUpdateTableTitle
   * @description Function to update report title
   * @param e input event object
   */
  const onUpdateTableTitle = (e: any) => {
    dispatch(setReportNameValidation(''));
    dispatch(
      setReportOptions({ ...reportOptions, reportName: e.target.value.trim() })
    );
    validateTitle(e.target.value.trim());
    setShowEditTitle(false);
  };

  /**
   * @function getGraphHeadingComponent
   * @description Function to return the chart heading component
   * @returns string or JSX element containing the graph heading
   */
  const getGraphHeadingComponent = () => {
    if (showEditTitle) {
      return (
        <>
          <Input
            defaultValue={reportOptions.reportName}
            placeholder={t('reports.addAReportName')}
            size={INPUT_SIZE.SMALL}
            onChange={(e: any) => validateTitle(e.target.value)}
            onBlur={onUpdateTableTitle}
            onPressEnter={onUpdateTableTitle}
            loading={titleValidationReqStatus === REQUEST_STATUS.PROCESSING}
            autoFocus
          />
          <span
            style={{
              display: `${getValidationStyle(reportNameValidation)}`,
            }}
            className="font-validation-error"
          >
            {reportNameValidation}
          </span>
        </>
      );
    }

    return (
      <>
        <AccessibleDiv
          className="flex flex-align-items-center flex-gap-4"
          onClick={() => {
            if (reportView === ReportsViewType.EDIT) {
              setShowEditTitle(true);
            }
          }}
        >
          <div>{reportOptions.reportName || t('reports.addAReportName')}</div>
          {reportView === ReportsViewType.EDIT && !showEditTitle && (
            <Icon
              className="edit-pencil"
              iconName={ICONS.EDIT_LINE}
              size={ICONS_SIZE.ONE_X}
            />
          )}
        </AccessibleDiv>
        {reportView === ReportsViewType.EDIT && (
          <span
            style={{
              display: `${getValidationStyle(reportNameValidation)}`,
            }}
            className="font-validation-error"
          >
            {reportNameValidation}
          </span>
        )}
      </>
    );
  };

  /**
   * @function getExcelExportData
   * @description Function to modify the data for excel export
   * @returns Modified Data
   */
  const getExcelExportData = () => {
    const chartOrTableData = isTableView
      ? reportData.tableData
      : reportData.chartData;

    const metrics = isTableView
      ? reportOptions.metric
      : reportOptions.chartMetric;

    const numberFormattedData: any[] = chartOrTableData.map(
      (dataItem, index) => {
        let metricData: any = {};
        metrics.forEach((metric) => {
          const metricFieldKey = replaceAllSpecialCharactersBy(metric) ?? '';
          metricData[metricFieldKey] = numberCommaSeparator(
            dataItem[metricFieldKey]
          );
        });
        return { ...dataItem, ...metricData, slNo: index + 1 };
      }
    );

    return numberFormattedData;
  };

  return (
    <>
      <GraphHeader
        className="report-chart-table-header"
        heading={reportOptions.reportName || t('reports.addAReportName')}
        headingComponent={getGraphHeadingComponent()}
        graphName={reportOptions.reportName || t('reports.addAReportName')}
        designVersion2
        isTableViewSwitch={
          reportOptions.chartType !== CHART_TYPES.TABLE && !pdfView
        }
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        showExpandIcon={reportView !== ReportsViewType.EDIT && !pdfView}
        isDownloadable={reportView !== ReportsViewType.EDIT && !pdfView}
        additionalComponents={
          !pdfView &&
          reportView === ReportsViewType.OPEN &&
          (!existingReport || existingReport.isOwner)
            ? {
                prefix: [
                  <Icon
                    iconName={ICONS.SHARE_LINE}
                    color={theme.buttonIconColor}
                    size={ICONS_SIZE.ONE_X}
                    onClick={() => setShowShareModal(true)}
                    key="share_cta"
                  />,
                  <Icon
                    iconName={ICONS.NOTIFICATION_4_LINE}
                    color={theme.buttonIconColor}
                    size={ICONS_SIZE.ONE_X}
                    onClick={() => setShowSubscribeModal(true)}
                    key="subscribe_cta"
                  />,
                ],
              }
            : undefined
        }
        excelData={{
          sheetName: reportOptions.reportName || t('reports.addAReportName'),
          columns: [
            { header: '#', key: 'slNo', alignment: 'center' },
            ...(isTableView
              ? reportOptions.dimension
              : reportOptions.chartDimension
            ).map((dimension) => ({
              header: getDimensionDisplayText(dimension),
              key: modifyDimensionLabelForCustomQuery(dimension),
            })),
            ...(isTableView
              ? reportOptions.metric
              : reportOptions.chartMetric
            ).map((metric) => ({
              header: metric,
              key: replaceAllSpecialCharactersBy(metric),
            })),
          ],
          data: getExcelExportData(),
          filters: [
            {
              heading: t('excelExportLabels.reportName'),
              value: reportOptions.reportName,
            },
            {
              heading: t('excelExportLabels.startDate'),
              value: moment(reportOptions.startDate).format(DATE_FORMAT),
            },
            {
              heading: t('excelExportLabels.endDate'),
              value: moment(reportOptions.endDate).format(DATE_FORMAT),
            },
          ],
        }}
      />
      {showShareModal && (
        <ShareReportModal show={showShareModal} setShow={setShowShareModal} />
      )}
      {showSubscribeModal && (
        <EditSubscriptionDrawer
          show={showSubscribeModal}
          setShow={setShowSubscribeModal}
          subscription={{
            subscriptionViewName: '',
            reportId: existingReport?.reportId ?? '',
            reportName: reportOptions.reportName,
            cloudConnectionName: `${reportConnection?.name}:${reportConnection?.provider}`,
            groupName: reportGroup?.name,
            frequency: SubscriptionFrequency.DAILY,
            startDate: moment().format(DD_MMM_YYYY),
            hour: moment().hours().toString(),
            minute: moment().minutes().toString(),
          }}
        />
      )}
    </>
  );
};

export default ReportTableOrChartHeader;
