import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userAuthorization } from 'redux/authorizationSlice';
import Button from 'components/Button';
import NavigationPath from 'components/NavigationPath';
import ErrorComponent from 'components/ErrorComponent';
import SuccessComponent from 'components/SuccessComponent';
import { SuccessIcon } from 'assets/icons';
import { BUTTON_SIZE } from 'constants/appearance';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import TeamsTable from './components/TeamsTable';
import { DefaultTeamData, TeamsComponents } from './constants';
import AddOrEditTeamDrawer from './components/AddOrEditTeamDrawer';
import { TeamsType } from './types';

import './index.scss';

const TeamsPage = () => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  const [componentRendered, setComponentRendered] = useState(
    TeamsComponents.TABLE
  );
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [teamData, setTeamData] = useState<TeamsType>(DefaultTeamData);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  const getComponent = () => {
    switch (componentRendered) {
      case TeamsComponents.TABLE:
        return (
          <TeamsTable
            setTeamData={setTeamData}
            setShowEditModal={setShowCreateTeamModal}
            setIsEdit={setIsEdit}
          />
        );

      case TeamsComponents.SUCCESS_COMPONENT:
        return (
          <SuccessComponent
            mainTitle={
              isEdit
                ? t('teamsLabels.successUpdate')
                : t('teamsLabels.successHeader')
            }
            subTitle={
              isEdit
                ? t('teamsLabels.successUpdateSubTitle', {
                    teamName: teamData.teamName,
                  })
                : t('teamsLabels.successSubTitle', {
                    usersCount: teamData.userDetailDtoList.length,
                    teamName: teamData.teamName,
                  })
            }
            buttonTitle={t('teamsLabels.gotoTeamsList')}
            onHandleButtonClick={() =>
              setComponentRendered(TeamsComponents.TABLE)
            }
            icon={<SuccessIcon />}
          />
        );

      case TeamsComponents.ERROR_COMPONENT:
        return (
          <ErrorComponent
            mainTitle={
              isEdit
                ? t('teamsLabels.errorUpdate')
                : t('teamsLabels.errorHeader')
            }
            buttonTitle={t('teamsLabels.tryAgain')}
            linkTitle={t('teamsLabels.gotoTeamsList')}
            onHandleButtonClick={() => {
              setShowCreateTeamModal(true);
              setComponentRendered(TeamsComponents.TABLE);
            }}
            onHandleLinkClick={() =>
              setComponentRendered(TeamsComponents.TABLE)
            }
          />
        );
    }
  };

  return (
    <section className="teams-page flex flex-column full-height">
      <header className="teams-page-header new-page-header modal-heading flex flex-align-items-center flex-space-between">
        <div>{t('navigationMenu.teams')}</div>
        {componentRendered === TeamsComponents.TABLE &&
          permissions.userManagementWrite && (
            <Button
              title={t('teamsLabels.createATeam')}
              size={BUTTON_SIZE.MEDIUM}
              onClick={() => {
                setIsEdit(false);
                setTeamData(DefaultTeamData);
                setShowCreateTeamModal(true);
              }}
            />
          )}
      </header>
      <section className="page-content flex flex-column flex-fit">
        <NavigationPath />
        {getComponent()}
      </section>
      {showCreateTeamModal && (
        <AddOrEditTeamDrawer
          show={showCreateTeamModal}
          setShow={setShowCreateTeamModal}
          teamData={teamData}
          setTeamData={setTeamData}
          isEdit={isEdit}
          setComponentRendered={setComponentRendered}
        />
      )}
    </section>
  );
};

export default TeamsPage;
