import { Select as BaseSelect } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { xor } from 'lodash';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectTheme } from 'redux/themeSlice';
import { LoadingIcon } from 'assets/icons';
import Select from 'components/Select';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { INPUT_SIZE } from 'constants/appearance';

import { ItemOptionsType } from './types';

import './index.scss';

type DropdownCheckboxProps = {
  itemOptions: ItemOptionsType[];
  value?: string | string[];
  selectedItems: string[];
  setSelectedItems: (list: string[]) => void;
  isDisabled?: boolean;
  additionalOptions?: React.ReactNode;
  includeAllCheckbox?: boolean;
  checkAllCallBack?: (e: CheckboxChangeEvent) => void;
  isAllChecked?: boolean;
  onClickOption?: (val: ItemOptionsType) => void;
  isChecked?: (val: ItemOptionsType) => boolean;
  placeholder?: string;
  autoFocus?: boolean;
  onHandleBlur?: () => void;
  onHandleChange?: (list: any) => void;
  loading?: boolean;
  additionalClassNames?: string;
  designVersion2?: boolean;
  size?: INPUT_SIZE;
  showSearch?: boolean;
  showSelectionCount?: boolean;
  valueLabelSuffix?: string;
  dataTestId?: string;
};

const DropdownCheckbox = ({
  itemOptions,
  selectedItems,
  value,
  setSelectedItems,
  isDisabled,
  additionalOptions,
  includeAllCheckbox = true,
  checkAllCallBack,
  isAllChecked,
  onClickOption,
  isChecked,
  placeholder,
  autoFocus,
  onHandleBlur,
  onHandleChange,
  loading,
  additionalClassNames,
  designVersion2,
  size = INPUT_SIZE.MEDIUM,
  showSearch,
  showSelectionCount,
  valueLabelSuffix = '',
  dataTestId,
}: DropdownCheckboxProps) => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);

  const [searchKey, setSearchKey] = useState<string>('');

  function onChange(option: ItemOptionsType) {
    if (!selectedItems.includes(option.value)) {
      setSelectedItems([...selectedItems, option.value]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== option.value));
    }
  }

  const checkAllonChange = (e: CheckboxChangeEvent) => {
    if (e?.target?.checked) {
      setSelectedItems(itemOptions?.map((item) => item.value));
    } else if (e.target !== undefined) {
      onHandleChange?.([]);
      setSelectedItems([]);
    }
  };

  const getCheckedAttributeForOption = (option: ItemOptionsType) =>
    isChecked !== undefined
      ? isChecked(option)
      : selectedItems.includes(option.value);

  const getValue = () => {
    if (value) {
      return value;
    }

    if (selectedItems.length === 0) {
      return t('checkBoxDropdown.none');
    }

    if (
      xor(
        itemOptions?.map((option) => option.value),
        selectedItems
      ).length === 0
    ) {
      return t('checkBoxDropdown.all');
    }

    if (showSelectionCount) {
      return selectedItems.length + ' ' + valueLabelSuffix;
    }

    return itemOptions
      ?.filter((item) => selectedItems.includes(item.value))
      ?.map((item) => item.title)
      ?.join(', ')
      .slice(0, 10000);
  };

  const getDropdownRender = (originNode: ReactNode) => (
    <>
      {showSearch && (
        <Input
          value={searchKey}
          onChange={(e: any) => setSearchKey(e.target.value)}
          placeholder={t('checkBoxDropdown.search')}
          size={INPUT_SIZE.SMALL}
        />
      )}
      {originNode}
    </>
  );

  const isAllOptionChecked = () => {
    return (
      isAllChecked ??
      xor(
        itemOptions?.map((option) => option.value),
        selectedItems
      ).length === 0
    );
  };

  return (
    <Select
      disabled={isDisabled}
      dropdownStyle={
        {
          color: theme.textColor,
          '--button-icon-color': theme.buttonIconColor,
        } as React.CSSProperties
      }
      mode="multiple"
      showSearch
      rootClassName={`full-width ${additionalClassNames}`}
      popupClassName="multi-select-dropdown-popup"
      value={getValue()}
      virtual={true}
      maxTagCount="responsive"
      showArrow
      suffixIcon={
        loading ? (
          <Icon icon={LoadingIcon} className="rotate" />
        ) : (
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        )
      }
      autoFocus={autoFocus}
      onBlur={onHandleBlur}
      placeholder={placeholder}
      onChange={onHandleChange}
      loading={loading}
      onDropdownVisibleChange={() => setSearchKey('')}
      designVersion2={designVersion2}
      size={size}
      dropdownRender={getDropdownRender}
      data-testid={dataTestId}
    >
      {includeAllCheckbox && !searchKey && itemOptions?.length > 1 && (
        <BaseSelect.Option>
          <Checkbox
            rootClassName={`all-select-option ${
              isAllOptionChecked() && 'all-checked'
            }`}
            defaultChecked
            onChange={checkAllCallBack ?? checkAllonChange}
            checked={isAllOptionChecked()}
            value={t('checkBoxDropdown.all')}
          >
            {t('checkBoxDropdown.all')}
          </Checkbox>
        </BaseSelect.Option>
      )}
      {additionalOptions}
      {itemOptions
        ?.filter((option) =>
          option?.title?.toLowerCase().includes(searchKey?.toLowerCase())
        )
        ?.map((option) => {
          return option ? (
            <BaseSelect.Option
              key={option.value}
              value={option.value}
              data-testid="select-option"
            >
              <AccessibleDiv
                className="select-option flex flex-align-items-center flex-gap-4"
                onClick={() =>
                  onClickOption ? onClickOption(option) : onChange(option)
                }
              >
                <Checkbox
                  key={option.value}
                  value={option.value}
                  checked={getCheckedAttributeForOption(option)}
                />
                <span>{option.title}</span>
              </AccessibleDiv>
            </BaseSelect.Option>
          ) : (
            []
          );
        })}
    </Select>
  );
};

export default DropdownCheckbox;
