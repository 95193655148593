import { useEffect, useState } from 'react';
import moment from 'moment';
import { Avatar, message, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';

import Input from 'components/Input';
import Pagination from 'components/Pagination';
import QuickActionMenu from 'components/QuickActionMenu';
import Table from 'components/Table';
import Tooltip from 'components/Tooltip';
import ConfirmModal from 'components/ConfirmModal';
import DeleteModal from 'components/DeleteModal';
import { INPUT_SIZE } from 'constants/appearance';
import { PAGINATION_SIZE } from 'constants/userConsole';
import {
  TEAMS_QUICKACTIONS,
  TeamsQuickActions,
  TeamStatus,
} from 'pages/TeamsPage/constants';
import { userAuthorization } from 'redux/authorizationSlice';
import { DATE_TIME_AM_PM } from 'utils/date';
import { ProfileIcon } from 'assets/icons';
import {
  getAllTeams,
  getOtherUsersProfilePhoto,
  searchTeams,
} from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { UserInfoType } from 'types/userManagementConsole';
import { deleteTeam, updateTeam } from 'pages/TeamsPage/services';

import { TeamsType } from '../../types';

import './index.scss';

type TeamsTableProps = {
  setTeamData: (val: TeamsType) => void;
  setShowEditModal: (val: boolean) => void;
  setIsEdit: (val: boolean) => void;
};

const TeamsTable = ({
  setTeamData,
  setShowEditModal,
  setIsEdit,
}: TeamsTableProps) => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [teamsData, setTeamsData] = useState<TeamsType[]>([]);
  const [totalTeamsCount, setTotalTeamsCount] = useState(0);
  const [profileImages, setProfileImages] = useState<{ [key: string]: string }>(
    {}
  );
  const [profileImageRequestStatus, setProfileImageRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [actionRecord, setActionRecord] = useState<TeamsType>();
  const [showActivateConfirmModal, setShowActivateConfirmModal] =
    useState(false);
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fetchTeamsReqStatus, setFetchTeamsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [deleteTeamReqStatus, setDeleteTeamReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [updateTeamStatusReqStatus, setUpdateTeamStatusReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    let source = axios.CancelToken.source();
    getTeams(source);

    return () => {
      source.cancel();
    };
  }, [currentPage, searchKey]);

  useEffect(() => {
    fetchProfileImages();
  }, [teamsData]);

  /**
   * @function fetchProfileImages
   * @description function to fetch the profile images
   */
  const fetchProfileImages = async () => {
    setProfileImageRequestStatus(REQUEST_STATUS.PROCESSING);

    const uniqueEmails: string[] = [];
    teamsData.forEach((item) => {
      item.userDetailDtoList.forEach((user) => {
        if (!uniqueEmails.includes(user.email)) {
          uniqueEmails.push(user.email);
        }
      });
    });

    const requests = uniqueEmails.map((email: any) =>
      getOtherUsersProfilePhoto(email)
    );

    axios
      .all(requests)
      .then(async (responses: any) => {
        let profilePics: { [key: string]: string } = {};
        for (const [index, res] of responses.entries()) {
          const email = uniqueEmails.at(index) ?? '';
          if (res?.status === 200) {
            const imageBlob = await res.blob();
            profilePics[email] = URL.createObjectURL(imageBlob);
          }
        }
        setProfileImages({ ...profilePics });
        setProfileImageRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setProfileImageRequestStatus);
      });
  };

  /**
   * @function getTeams
   * @description function to fetch the teams
   * @param cancelToken cancel token for cancelling the frequent requests
   */
  const getTeams = (cancelToken?: CancelTokenSource) => {
    setFetchTeamsReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      pageNo: currentPage - 1,
      pageSize: PAGINATION_SIZE,
      key: searchKey ?? undefined,
    };

    (searchKey
      ? searchTeams(params, cancelToken?.token)
      : getAllTeams(params, cancelToken?.token)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setTeamsData(res?.data?.responseData?.teamsRespDtoList);
          setTotalTeamsCount(res?.data?.responseData?.totalElements);
          setFetchTeamsReqStatus(REQUEST_STATUS.SUCCESS);
          return;
        }

        setFetchTeamsReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchTeamsReqStatus);
      });
  };

  /**
   * @function onStatusUpdateError
   * @description Callback function for error on updating the status of a team
   */
  const onStatusUpdateError = (errorMessage: string) => {
    message.error({
      content: errorMessage || t('teamsLabels.errorUpdatingStatus'),
      key: ERROR_KEY,
    });
    setUpdateTeamStatusReqStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function updateTeamStatus
   * @description Function to update the status of the team
   */
  const updateTeamStatus = (status: string) => {
    setUpdateTeamStatusReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      ...actionRecord,
      cbUserList: actionRecord?.userDetailDtoList?.map((item) => item.email),
      updatedBy: localStorage.getItem('email'),
      teamStatus: status,
    };

    updateTeam(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setUpdateTeamStatusReqStatus(REQUEST_STATUS.SUCCESS);
          setShowActivateConfirmModal(false);
          setShowDeactivateConfirmModal(false);
          message.success({
            content: t('teamsLabels.successUpdatingStatus'),
            key: SUCCESS_KEY,
          });
          getTeams();
          return;
        }
        onStatusUpdateError(res.data.message);
      })
      .catch((e) => {
        onApiCallError(e, false);
        onStatusUpdateError(e?.response?.data?.message);
      });
  };

  /**
   * @function onDeleteError
   * @description Callback function for error on deleting a team
   */
  const onDeleteError = (errorMessage: string) => {
    message.error({
      content:
        errorMessage ||
        t('teamsLabels.successDeletingTeam', {
          teamName: actionRecord?.teamName,
        }),
      key: ERROR_KEY,
    });
    setDeleteTeamReqStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function onClickDelete
   * @description function to delete a team
   */
  const onClickDelete = () => {
    setDeleteTeamReqStatus(REQUEST_STATUS.PROCESSING);

    deleteTeam(actionRecord?.teamName)
      .then((res: any) => {
        if (res?.status === 200) {
          setDeleteTeamReqStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          message.success({
            content: t('teamsLabels.successDeletingTeam', {
              teamName: actionRecord?.teamName,
            }),
            key: SUCCESS_KEY,
          });
          getTeams();
          return;
        }
        onDeleteError(res.data.message);
      })
      .catch((e) => {
        onApiCallError(e, false);
        onDeleteError(e?.response?.data?.message);
      });
  };

  const getProfileIcon = (user: UserInfoType, index: number) => {
    if (profileImageRequestStatus === REQUEST_STATUS.PROCESSING) {
      return <Skeleton.Avatar key={index} size="small" active />;
    }

    return (
      <Avatar key={index} src={profileImages[user.email] || ProfileIcon} />
    );
  };

  const getQuickActionsMenuItems = (record: TeamsType) => {
    let actions = [...TEAMS_QUICKACTIONS];

    if (record.teamStatus === TeamStatus.ACTIVE) {
      actions = actions.filter(
        (item) => item.id !== TeamsQuickActions.ACTIVATE
      );
    }

    if (record.teamStatus === TeamStatus.DEACTIVATED) {
      actions = actions.filter(
        (item) => item.id !== TeamsQuickActions.DEACTIVATE
      );
    }

    return actions;
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions
   */
  const handleQuickAction = (record: TeamsType, action: string) => {
    setTeamData(record);
    setActionRecord(record);

    switch (action) {
      case TeamsQuickActions.EDIT:
        setIsEdit(true);
        setShowEditModal(true);
        break;

      case TeamsQuickActions.ACTIVATE:
        setShowActivateConfirmModal(true);
        break;

      case TeamsQuickActions.DEACTIVATE:
        setShowDeactivateConfirmModal(true);
        break;

      case TeamsQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
    },
    {
      title: t('teamsLabels.teamName'),
      dataIndex: 'teamName',
      key: 'teamName',
      render: (text: string) => (
        <span className="font-caption-bold">{text}</span>
      ),
    },
    {
      title: t('teamsLabels.noOfUsers'),
      dataIndex: 'noOfUsers',
      key: 'noOfUsers',
      render: (_text: string, record: TeamsType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <Avatar.Group
            maxCount={3}
            maxStyle={{ color: '#FDFFF9', backgroundColor: '#62AD41' }}
            size="small"
            maxPopoverTrigger="focus"
          >
            {record?.userDetailDtoList
              ?.slice(0, 3)
              ?.map((user, index) => getProfileIcon(user, index))}
          </Avatar.Group>
          <div className="users-count font-caption-bold">
            {t('teamsLabels.countUsers', {
              usersCount: record.userDetailDtoList.length,
            })}
          </div>
        </div>
      ),
    },
    {
      title: t('teamsLabels.createdBy'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (text: any, record: TeamsType) => (
        <Tooltip overlay={<span className="font-caption">{text}</span>}>
          {record?.userName}
        </Tooltip>
      ),
    },
    {
      title: t('teamsLabels.dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (text: string) =>
        text !== null ? moment(text).format(DATE_TIME_AM_PM) : '--',
    },
    {
      title: t('teamsLabels.status'),
      dataIndex: 'teamStatus',
      key: 'teamStatus',
      render: (text: string) => {
        return (
          <span
            className={`font-button status ${
              text === TeamStatus.ACTIVE ? 'active' : 'deactivated'
            }`}
          >
            {text.toLowerCase()}
          </span>
        );
      },
    },
    {
      title: t('teamsLabels.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: TeamsType) => {
        return (
          <QuickActionMenu
            quickActions={getQuickActionsMenuItems(record)}
            disabled={!permissions.userManagementModify}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      width: 200,
      align: 'center' as const,
    },
  ];

  return (
    <div className="teams-table flex flex-column flex-fit">
      <Input
        type="search"
        value={searchKey}
        placeholder={t('teamsLabels.searchByName')}
        onChange={(e: any) => setSearchKey(e.target.value)}
        size={INPUT_SIZE.SMALL}
      />
      <Table
        pagination={false}
        rootClassName="table-section"
        dataSource={teamsData?.map((value, index) => {
          return { ...value, key: index + 1 };
        })}
        columns={columns}
        loading={fetchTeamsReqStatus === REQUEST_STATUS.PROCESSING}
        designVersion2={true}
      />
      {totalTeamsCount > 0 && (
        <Pagination
          rootClassName="flex flex-align-items-end flex-fit"
          current={currentPage}
          onChange={(page: number) => setCurrentPage(page)}
          total={totalTeamsCount}
          defaultPageSize={PAGINATION_SIZE}
        />
      )}
      {showActivateConfirmModal && (
        <ConfirmModal
          show={showActivateConfirmModal}
          setShow={setShowActivateConfirmModal}
          title={t('teamsLabels.doYouConfirm')}
          subTitle={
            <>
              {t('teamsLabels.activateConfirm')}{' '}
              <span className="font-button">{actionRecord?.teamName}</span>
              {'. '}
              {t('teamsLabels.deactivateAgainInfo')}
            </>
          }
          onClickYes={() => updateTeamStatus(TeamStatus.ACTIVE)}
          onClickNo={() => setShowActivateConfirmModal(false)}
          loadingYes={updateTeamStatusReqStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
      {showDeactivateConfirmModal && (
        <ConfirmModal
          show={showDeactivateConfirmModal}
          setShow={setShowDeactivateConfirmModal}
          title={t('teamsLabels.doYouConfirm')}
          subTitle={
            <>
              {t('teamsLabels.deActivateConfirm')}{' '}
              <span className="font-button">{actionRecord?.teamName}</span>
              {'. '}
              {t('teamsLabels.activateAgainInfo')}
            </>
          }
          onClickYes={() => updateTeamStatus(TeamStatus.DEACTIVATED)}
          onClickNo={() => setShowDeactivateConfirmModal(false)}
          loadingYes={updateTeamStatusReqStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={onClickDelete}
          loading={deleteTeamReqStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default TeamsTable;
