import i18n from 'i18n';
import moment from 'moment';

import { store } from 'redux/store';
import { HYPHEN_DATE_FORMAT } from 'utils/date';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  SNOWFLAKE_TABLES,
} from 'constants/requestBody';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const TopAccountsByCostExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('snowflakeDashboards.accounts'),
    key: 'name',
    dataKey: 'name',
    width: 30,
  },
  {
    header: i18n.t('snowflakeDashboards.costInCurrency', {
      currencySymbol: currencySymbol,
    }),
    key: 'cost',
    dataKey: 'cost',
    width: 20,
    alignment: 'right',
  },
];

export const TopWarehousesByCostExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('snowflakeDashboards.warehouses'),
    key: 'name',
    dataKey: 'name',
    width: 30,
  },
  {
    header: i18n.t('snowflakeDashboards.costInCurrency', {
      currencySymbol: currencySymbol,
    }),
    key: 'cost',
    dataKey: 'cost',
    width: 20,
    alignment: 'right',
  },
];

export const AccountsCountQuery = {
  queryDto: {
    columns: [
      {
        label: 'value',
        field: QUERY_FIELDS.COUNT_DISTINCT_ACCOUNT_NAME,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
    cached: true,
  },
  table: SNOWFLAKE_TABLES.WAREHOUSE_METERING_HISTORY,
};

export const WarehousesCountQuery = {
  queryDto: {
    columns: [
      {
        label: 'value',
        field: QUERY_FIELDS.COUNT_DISTINCT_WAREHOUSE_ID,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
    cached: true,
  },
  table: SNOWFLAKE_TABLES.WAREHOUSE_METERING_HISTORY,
};

export const CreditsConsumedQuery = {
  queryDto: {
    columns: [
      {
        label: 'value',
        field: QUERY_FIELDS.CREDITS_USED,
      },
    ],
    aggregators: [
      {
        label: 'value',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.START_TIME_CAPS,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.END_TIME_CAPS,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: moment().format(HYPHEN_DATE_FORMAT),
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
    cached: true,
  },
  table: SNOWFLAKE_TABLES.WAREHOUSE_METERING_HISTORY,
};

export const ComputeCostQuery = {
  queryDto: {
    columns: [
      {
        label: 'value',
        field: QUERY_FIELDS.CREDITS_USED_COMPUTE,
      },
    ],
    aggregators: [
      {
        label: 'value',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.START_TIME_CAPS,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.END_TIME_CAPS,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: moment().format(HYPHEN_DATE_FORMAT),
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
    cached: true,
  },
  table: SNOWFLAKE_TABLES.WAREHOUSE_METERING_HISTORY,
};

export const AvgDailyCreditsUsedQuery = {
  queryDto: {
    columns: [
      {
        label: 'value',
        field: QUERY_FIELDS.CREDITS_USED,
      },
    ],
    aggregators: [
      {
        label: 'value',
        function: AGGREGATORS.AVG,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
    cached: true,
  },
  table: SNOWFLAKE_TABLES.WAREHOUSE_METERING_HISTORY,
};

export const AvgDailyCostQuery = {
  queryDto: {
    columns: [
      {
        label: 'value',
        field: QUERY_FIELDS.CREDITS_BILLED,
      },
    ],
    aggregators: [
      {
        label: 'value',
        function: AGGREGATORS.AVG,
      },
    ],
    filterGroups: [
      {
        conjunctToNextGroup: CONJUNCTIONS.AND,
        filters: [
          {
            field: QUERY_FIELDS.SERVICE_TYPE,
            comparator: COMPARATORS.IN,
            value: "('WAREHOUSE_METERING', 'WAREHOUSE_METERING_READER')",
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
    cached: true,
  },
  table: SNOWFLAKE_TABLES.METERING_DAILY_HISTORY,
};
