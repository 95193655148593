import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';

type CpuReductionValueProps = {
  recommendation: RecommendationList;
};

const CpuReductionValue = ({ recommendation }: CpuReductionValueProps) => {
  if (!recommendation.containers || !recommendation.containers.length) {
    return <div>N/A</div>;
  }

  return (
    <div className="flex flex-column flex-gap-8">
      {recommendation.containers.map((container) => (
        <span key={container.name}>{container.avg_impact_on_cluster_cpu}%</span>
      ))}
    </div>
  );
};

export default CpuReductionValue;
