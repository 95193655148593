import i18n from 'i18n';

import { store } from 'redux/store';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const TopServicesColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    width: '5%',
    render: (_text: any, _record: any, index: number) => index + 1,
  },
  {
    title: i18n.t('projectDeepDiveSummary.costTable.service'),
    dataIndex: 'service',
    key: 'service',
    width: '20%',
  },
  {
    title: i18n.t('projectDeepDiveSummary.costTable.costInCurrency', {
      currencySymbol,
    }),
    dataIndex: 'cost',
    key: 'cost',
    width: '8%',
    align: 'center',
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
  },
];
