import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getTagDimensionNameValidation
 * @description Function to fetch the tag dimension name validation
 * @param params Object containing the request params
 * @return axios response from GET request
 */
export const getTagDimensionNameValidation = async (
  params: any,
  cancelToken: any
) => {
  return ServiceCalls.get(
    APIS.TAG_DIMENSION_NAME_VALIDATION,
    null,
    params,
    cancelToken
  );
};

/**
 * @function createTagMapping
 * @description Function to create the tag mapping data
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createTagMapping = async (body: any) => {
  return ServiceCalls.post(APIS.TAG_DIMENSIONS_DATA, body);
};

/**
 * @function updateTagMapping
 * @description Function to update the tag mapping data
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const updateTagMapping = async (body: any) => {
  return ServiceCalls.post(APIS.UPDATE_TAG_DIMENSIONS_DATA, body);
};

/**
 * @function deleteTagMapping
 * @description Function to delete the tag mapping data
 * @param params Object containing the request params
 * @return axios response from DELETE request
 */
export const deleteTagMapping = async (params: any) => {
  return ServiceCalls.delete(APIS.TAG_DIMENSIONS_DATA, null, params);
};
