import i18n from 'i18n';

import { ItemOptionsType } from 'components/DropdownCheckbox/types';
import { ChartCriteria } from 'constants/dashboard';
import { RecommendationCategoriesLabels } from 'constants/recommendations';
import { store } from 'redux/store';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import ColorBlock from 'components/LegendColorBlock';
import SavingsPerCategoryTableCell from '../SavingsPerCategoryTableCell';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const CategorySavingsExportColumns = [
  { title: '#', key: 'index', dataIndex: 'index' },
  {
    title: i18n.t('gcpRecommendationSummary.month'),
    key: 'month',
    dataIndex: 'month',
  },
  {
    title: i18n.t('gcpRecommendationSummary.category'),
    key: 'category',
    dataIndex: 'category',
  },
  {
    title: i18n.t('gcpRecommendationSummary.cost', {
      currencySymbol: currencySymbol,
    }),
    key: 'cost',
    dataIndex: 'cost',
  },
];

export const SavingsPerCategoryColumns = [
  {
    title: '#',
    dataIndex: 'color',
    key: 'color',
    width: 1,
    render: ColorBlock,
  },
  {
    title: i18n.t('awsRecommendationSummary.categoryAvgCost', {
      currencySymbol: currencySymbol,
    }),
    dataIndex: 'cost',
    key: 'cost',
    align: 'left',
    width: 5,
    render: (value: string | number, record: any) =>
      SavingsPerCategoryTableCell(value, currencySymbol, record),
  },
];

export const EstimatedSavingsPerProjectColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('gcpRecommendationSummary.project'),
    dataIndex: 'project',
    key: 'project',
    width: '65%',
  },
  {
    title: i18n.t('gcpRecommendationSummary.cost', {
      currencySymbol: currencySymbol,
    }),
    dataIndex: 'cost',
    key: 'cost',
    align: 'right',
    width: '30%',
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
  },
];

export const SavingsPlanTableColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('months'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: i18n.t('savingsEffectiveness'),
    dataIndex: 'cost',
    key: 'cost',
    render: (text: any) => numberCommaSeparator(text) + '%',
  },
];

export const GcpDetailedSavingsTableColumns = [
  {
    title: i18n.t('gcpRecommendationSummary.detailedSavingsTable.project'),
    dataIndex: 'projectName',
    align: 'left',
    fixed: 'left',
  },
  {
    title: i18n.t('gcpRecommendationSummary.detailedSavingsTable.recStatus'),
    dataIndex: 'state',
  },
  {
    title: i18n.t('gcpRecommendationSummary.detailedSavingsTable.priority'),
    dataIndex: 'priority',
  },
  {
    title: i18n.t(
      'gcpRecommendationSummary.detailedSavingsTable.recommendation'
    ),
    dataIndex: 'recommendation',
  },
  {
    title: i18n.t(
      'gcpRecommendationSummary.detailedSavingsTable.recommendationId'
    ),
    dataIndex: 'recommendationName',
    render: (text: string) => text?.substring(text?.lastIndexOf('/') + 1),
  },
  {
    title: i18n.t('gcpRecommendationSummary.detailedSavingsTable.type'),
    dataIndex: 'recommender_subtype',
  },
  {
    title: i18n.t('gcpRecommendationSummary.detailedSavingsTable.category'),
    dataIndex: 'recommendationCategory',
    render: (text: string) =>
      RecommendationCategoriesLabels.find((item) => item.key === text)?.label ??
      text,
  },
  {
    title: i18n.t(
      'gcpRecommendationSummary.detailedSavingsTable.lastRefreshed'
    ),
    dataIndex: 'lastRefreshed',
  },
  {
    title: i18n.t(
      'gcpRecommendationSummary.detailedSavingsTable.potentialSavings'
    ),
    dataIndex: 'cost',
    align: 'right',
    render: (text: string) => currencySymbol + numberCommaSeparator(text),
  },
];

export const AzureDetailedSavingsTableColumns = [
  {
    title: i18n.t('costOptimizationInsight.recommendationTable.impactedField'),
    dataIndex: 'impactedField',
    align: 'left',
    fixed: 'left',
  },
  {
    title: i18n.t('costOptimizationInsight.recommendationTable.impactedValue'),
    dataIndex: 'impactedValue',
  },
  {
    title: i18n.t('costOptimizationInsight.recommendationTable.recStatus'),
    dataIndex: 'state',
  },
  {
    title: i18n.t('costOptimizationInsight.recommendationTable.recommendation'),
    dataIndex: 'recommendationProblem',
    width: '30%',
  },
  {
    title: i18n.t('costOptimizationInsight.recommendationTable.category'),
    dataIndex: 'recommendationCategory',
  },
  {
    title: i18n.t('costOptimizationInsight.recommendationTable.lastRefreshed'),
    dataIndex: 'lastRefreshed',
  },
  {
    title: i18n.t(
      'gcpRecommendationSummary.detailedSavingsTable.potentialSavings'
    ),
    dataIndex: 'cost',
    align: 'right',
    render: (text: string) => currencySymbol + numberCommaSeparator(text),
  },
];

export const CHART_CRITERIA_LIST: ItemOptionsType[] = [
  {
    title: i18n.t('timePeriod'),
    value: ChartCriteria.TIME_PERIOD,
  },
  {
    title: i18n.t('project'),
    value: ChartCriteria.PROJECT,
  },
  {
    title: i18n.t('recommendation'),
    value: ChartCriteria.RECOMMENDATION,
  },
  {
    title: i18n.t('savingsPlan'),
    value: ChartCriteria.SAVINGS_PLAN,
  },
];
