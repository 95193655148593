import i18n from 'i18n';
import { message } from 'antd';

import { REQUEST_STATUS } from 'constants/requestBody';
import { CMDBListType } from 'pages/CmdbSetupListPage/types';
import { onApiCallError } from 'utils/handleErrors';

import { createCmdbSetup, updateCmdbSetup } from './services';

/**
 * @function onSaveOrUpdateCmdbSetup
 * @description Function to save or update the CMDB Setup
 * @param cmdbSetupData CMDB data which needs to be saved or updated
 * @param isUpdate Boolean to indicate whether to create a new CMDB setup or update
 * @param setReqStatus Callback to set the status of create or update
 * @param onSaveOrUpdate Callback to be called on successful save or update
 */
export const onSaveOrUpdateCmdbSetup = (
  cmdbSetupData: CMDBListType,
  isUpdate: boolean,
  setReqStatus: (val: string) => void,
  onSaveOrUpdate: () => void
) => {
  setReqStatus(REQUEST_STATUS.PROCESSING);

  let requestBody = { ...cmdbSetupData };
  let successMessage: string = i18n.t('cmdbCreateSuccess');
  let errorMessage: string = i18n.t('cmdbCreateFailure');

  if (isUpdate) {
    requestBody = {
      ...cmdbSetupData,
      username: undefined,
      password: undefined,
    };

    successMessage = i18n.t('cmdbUpdateSuccess');
    errorMessage = i18n.t('cmdbUpdateFailure');
  }

  (isUpdate ? updateCmdbSetup : createCmdbSetup)(requestBody)
    .then(() => {
      message.success(successMessage);
      setReqStatus(REQUEST_STATUS.SUCCESS);
      onSaveOrUpdate();
    })
    .catch((e) => {
      onApiCallError(
        e,
        true,
        setReqStatus,
        errorMessage + ' ' + e.response.data.message
      );
    });
};
