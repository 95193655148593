import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchPersistedTags
 * @description Function to fetch the tags persisted
 * @param params request params
 * @return axios response from GET request
 */
export const fetchPersistedTags = async (params: any) => {
  return ServiceCalls.get(APIS.TAG_COMPLIANCE, null, params);
};

/**
 * @function addTagCompliance
 * @description Function to add the mandatory tags
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const addTagCompliance = async (body: any) => {
  return ServiceCalls.post(APIS.TAG_COMPLIANCE, body);
};

/**
 * @function applyResourceTags
 * @description Function to apply the tags for a resource
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const applyResourceTags = async (body: any) => {
  return ServiceCalls.post(APIS.TAG_COMPLIANCE_APPLY_TAGS, body);
};
