import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dataCenter } from 'redux/dataCenterSlice';

import Input from 'components/Input';
import DrawerComponent from 'components/DrawerComponent';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { FormLabel } from 'components/FormLabel';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import {
  FREE_TEXT_CHARACTER_LIMIT,
  MAX_CHARACTER_LIMIT,
} from 'constants/validation';
import {
  saveDataCenter,
  updateDataCenter,
} from 'pages/DataCenterPage/services';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

type CreateDataCenterModalProps = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onCreateDataCenter: () => void;
};

const CreateDataCenterModal = ({
  showModal,
  setShowModal,
  onCreateDataCenter,
}: CreateDataCenterModalProps) => {
  const { t } = useTranslation();
  const { selectedDataCenter } = useSelector(dataCenter);

  const [dataCenterCode, setDataCenterCode] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  //State for validations
  const [dataCenterCodeValidation, setDataCenterCodeValidation] = useState('');
  const [nameValidation, setNameValidation] = useState('');
  const [descriptionValidation, setDescriptionValidation] = useState('');

  const [saveDataCenterRequestStatus, setSaveDataCenterRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setDataCenterCode(selectedDataCenter?.dataCenterCode ?? '');
    setName(selectedDataCenter?.name ?? '');
    setDescription(selectedDataCenter?.description ?? '');
  }, []);

  /**
   * @function validateCostCenterCode
   * @description Function to validate Cost Center Code.
   * @param value value to be validated
   * @returns true if the validation is successful, else false
   */
  const validateCostCenterCode = (value: string) => {
    if (
      validateEmptyField(
        value.trim(),
        t('createDataCenterLabels.dataCenterCode'),
        setDataCenterCodeValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value,
        t('createDataCenterLabels.dataCenterCode'),
        setDataCenterCodeValidation
      )
    ) {
      return false;
    }

    return !validateStringLengthLessThan(
      value.trim(),
      MAX_CHARACTER_LIMIT,
      t('createDataCenterLabels.dataCenterCode'),
      setDataCenterCodeValidation
    );
  };

  /**
   * @function validateName
   * @description Function to validate Name.
   * @param value value to be validated
   * @returns true if the validation is successful, else false
   */
  const validateName = (value: string) => {
    if (
      validateEmptyField(
        value.trim(),
        t('createDataCenterLabels.name'),
        setNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value,
        t('createDataCenterLabels.name'),
        setNameValidation
      )
    ) {
      return false;
    }

    return !validateStringLengthLessThan(
      value.trim(),
      MAX_CHARACTER_LIMIT,
      t('createDataCenterLabels.name'),
      setNameValidation
    );
  };

  /**
   * @function validateDescription
   * @description Function to validate the Description field
   * @param value description to be validated
   * @returns true if the validation is successful, else false
   */
  const validateDescription = (value: string) => {
    if (
      validateEmptyField(
        value.trim(),
        t('createDataCenterLabels.description'),
        setDescriptionValidation
      )
    ) {
      return false;
    }

    return !validateStringLengthLessThan(
      value.trim(),
      FREE_TEXT_CHARACTER_LIMIT,
      t('createDataCenterLabels.description'),
      setDescriptionValidation
    );
  };

  /**
   * @function validateInputFields
   * @description Function to validate all input fields while clicking submit button.
   * @return this funtion returns true if all validations are ok either return false if any validation fails
   */
  const validateInputFields = () => {
    let validation = true;

    validation = validateCostCenterCode(dataCenterCode) && validation;

    validation = validateName(name) && validation;

    validation = validateDescription(description) && validation;

    return validation;
  };

  /**
   * @function handleCreateDataCenter
   * @description Function to handle create data center
   */
  const handleCreateDataCenter = () => {
    if (!validateInputFields()) {
      return;
    }

    setSaveDataCenterRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      dataCenterCode: dataCenterCode,
      name: name,
      description: description,
    };

    let successMessage = t('createDataCenterLabels.createDataCenterSuccess');
    let failureMessage = t('createDataCenterLabels.createDataCenterFailure');

    if (selectedDataCenter !== undefined) {
      successMessage = t('createDataCenterLabels.updateDataCenterSuccess');
      failureMessage = t('createDataCenterLabels.updateDataCenterFailure');
    }

    (selectedDataCenter === undefined
      ? saveDataCenter(requestBody)
      : updateDataCenter(requestBody)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setSaveDataCenterRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success(successMessage);
          onCreateDataCenter();
          setShowModal(false);
          return;
        }
        setErrorMessage(res?.message ?? failureMessage);
        setSaveDataCenterRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setSaveDataCenterRequestStatus);
        setErrorMessage(e?.response?.data?.message ?? failureMessage);
      });
  };

  return (
    <DrawerComponent
      open={showModal}
      onClose={() => setShowModal(false)}
      title={
        selectedDataCenter
          ? t('createDataCenterLabels.editDataCenter')
          : t('createDataCenterLabels.createDataCenter')
      }
      footer={
        <div className="flex flex-column flex-gap-8">
          <span
            style={{
              display: getValidationStyle(errorMessage),
            }}
            className="font-validation-error"
          >
            {errorMessage}
          </span>
          <div className="flex flex-align-items-center flex-end">
            <Button
              title={t('createDataCenterLabels.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShowModal(false)}
            />
            <Button
              title={
                selectedDataCenter
                  ? t('createDataCenterLabels.update')
                  : t('createDataCenterLabels.create')
              }
              onClick={handleCreateDataCenter}
              loading={
                saveDataCenterRequestStatus === REQUEST_STATUS.PROCESSING
              }
            />
          </div>
        </div>
      }
    >
      <div className="create-data-center-modal flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('createDataCenterLabels.dataCenterCode')}
            required={true}
            disabled={selectedDataCenter !== undefined}
          />
          <Input
            type="input"
            value={dataCenterCode}
            placeholder={t('createDataCenterLabels.enterDataCenterCode')}
            onChange={(e: any) => {
              setDataCenterCode(e.target.value);
              validateCostCenterCode(e.target.value);
            }}
            onBlur={(e: any) => {
              validateCostCenterCode(e.target.value);
            }}
            disabled={selectedDataCenter !== undefined}
          />
          <span
            style={{
              display: getValidationStyle(dataCenterCodeValidation),
            }}
            className="font-validation-error"
          >
            {dataCenterCodeValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('createDataCenterLabels.name')} required={true} />
          <Input
            type="input"
            value={name}
            placeholder={t('createDataCenterLabels.enterName')}
            onChange={(e: any) => {
              setName(e.target.value);
              validateName(e.target.value);
            }}
            onBlur={(e: any) => {
              validateName(e.target.value);
            }}
          />
          <span
            style={{
              display: getValidationStyle(nameValidation),
            }}
            className="font-validation-error"
          >
            {nameValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('createDataCenterLabels.description')}
            required={true}
          />
          <Input
            type="textarea"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={description}
            placeholder={t('createDataCenterLabels.enterDescription')}
            onChange={(e: any) => {
              setDescription(e.target.value);
              validateDescription(e.target.value);
            }}
            onBlur={(e: any) => validateDescription(e.target.value)}
          />
          <span
            style={{
              display: getValidationStyle(descriptionValidation),
            }}
            className="font-validation-error"
          >
            {descriptionValidation}
          </span>
        </div>
      </div>
    </DrawerComponent>
  );
};

export default CreateDataCenterModal;
