import { createElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import AccessibleDiv from 'components/AccessibleDiv';
import { userAuthorization } from 'redux/authorizationSlice';
import { NavigationMenuDataState, SubSectionType } from 'types/navigationMenu';
import {
  isSubMenuItemIncludePath,
  isSubSectionAuthorized,
  isSubSectionItemIncludePath,
  hasNestedSubMenu,
} from 'utils/navigationMenu';

type SubMenuListProps = {
  additionalClassName?: string;
  menu: NavigationMenuDataState;
  isAllFeature?: boolean;
};

const SubMenuList = ({
  additionalClassName,
  menu,
  isAllFeature,
}: SubMenuListProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { permissions } = useSelector(userAuthorization);

  // This state toggles the Menu having subSectoins
  const [expandedMenu, setExpandedMenu] = useState<NavigationMenuDataState>();

  // This state toggles the Menu which has nested subMenu
  const [superSubMenuExpanded, setSuperSubMenuExpanded] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    setExpandedMenu(
      menu.subMenu?.find((subMenu) =>
        isSubMenuItemIncludePath(subMenu, location.pathname)
      )
    );

    if (menu.subMenu) {
      const nestedMenuWithPath = menu?.subMenu[0]?.subMenu?.find((subMenu) =>
        isSubMenuItemIncludePath(subMenu, location.pathname)
      );
      if (nestedMenuWithPath && hasNestedSubMenu(menu)) {
        setExpandedMenu(
          menu?.subMenu[0]?.subMenu?.find((subMenu) =>
            isSubMenuItemIncludePath(subMenu, location.pathname)
          )
        );
        //setting first submenu as expanded initially
        setSuperSubMenuExpanded(menu.subMenu[0].id);
      }
    }
  }, [location]);

  /**
   * @function getMenuIcon
   * @description Function to return the menu icon
   * @param menuItem menu data.
   * @returns icon component.
   */
  const getMenuIcon = (menuItem: NavigationMenuDataState) => {
    return menuItem.icon ? (
      createElement(menuItem.icon, {
        className: 'menu-icon stretch',
      })
    ) : (
      <Icon
        iconName={menuItem.iconName}
        size={ICONS_SIZE.XL}
        className="menu-icon"
      />
    );
  };

  /**
   * @function getMenuList
   * @description Function to return the menu list
   * @returns JSX element containing the menu items
   */
  const getMenuList = (menuItems: SubSectionType[]) => {
    return menuItems
      .filter((subSection) => isSubSectionAuthorized(subSection, permissions))
      .map((subSection) => (
        <AccessibleDiv
          className={`sub-section-item font-button cursor-pointer ${
            isSubSectionItemIncludePath(subSection, location.pathname) &&
            'selected'
          }`}
          key={subSection.id}
          onClick={() => {
            navigate(subSection.rootPath ?? '');
            if (
              !expandedMenu?.subSections.some((item) =>
                menuItems.some((menuItem) => menuItem.id === item.id)
              )
            ) {
              setExpandedMenu(undefined);
            }
          }}
        >
          {t(`navigationMenu.${subSection.title}`)}
        </AccessibleDiv>
      ));
  };

  const getDropdowm = (menu: NavigationMenuDataState) =>
    menu.subMenu
      ?.filter((subMenu) =>
        subMenu.subSections.some((subSection) =>
          isSubSectionAuthorized(subSection, permissions)
        )
      )
      .map((subMenu) => (
        <div
          className={`sub-menu-item flex flex-column flex-gap-4 ${
            (expandedMenu?.id === subMenu.id || isAllFeature) && 'expanded'
          } ${
            isSubMenuItemIncludePath(subMenu, location.pathname) && 'selected'
          }`}
          key={subMenu.id}
        >
          <AccessibleDiv
            className="sub-menu-title cursor-pointer flex flex-align-items-center flex-space-between"
            onClick={() => {
              if (expandedMenu?.id === subMenu.id || isAllFeature) {
                setExpandedMenu(undefined);
              } else {
                setExpandedMenu(subMenu);
              }
            }}
          >
            <div className="flex flex-align-items-center flex-gap-4">
              {(subMenu.icon || subMenu.iconName) && (
                <span className="menu-icon-container">
                  {getMenuIcon(subMenu)}
                </span>
              )}
              <span className="font-button">
                {t(`navigationMenu.${subMenu.title}`)}
              </span>
            </div>
            <Icon
              iconName={ICONS.ARROW_DOWN_S_LINE}
              className={`arrow-icon ${
                (expandedMenu?.id === subMenu.id || isAllFeature) && 'expanded'
              }`}
            />
          </AccessibleDiv>
          {(expandedMenu?.id === subMenu.id || isAllFeature) &&
            getMenuList(subMenu.subSections)}
        </div>
      ));

  return (
    <div
      className={`sub-menu-items flex flex-column flex-gap-4 ${additionalClassName}`}
    >
      {menu.subSectionsFirst && getMenuList(menu.subSections)}
      {getDropdowm(menu)}

      {hasNestedSubMenu(menu) &&
        menu?.subMenu?.map((subMenu) => (
          <div
            className={`sub-menu-item flex flex-column flex-gap-4  ${
              isSubMenuItemIncludePath(subMenu, location.pathname) && 'selected'
            }`}
            key={subMenu.id}
          >
            <AccessibleDiv
              className="sub-menu-title cursor-pointer flex flex-align-items-center flex-space-between"
              onClick={() => {
                if (superSubMenuExpanded !== subMenu.id) {
                  setSuperSubMenuExpanded(subMenu.id);
                } else {
                  setSuperSubMenuExpanded(undefined);
                }
              }}
            >
              <div className="flex flex-align-items-center flex-gap-4">
                {(subMenu.icon || subMenu.iconName) && (
                  <span className="menu-icon-container">
                    {getMenuIcon(subMenu)}
                  </span>
                )}
                <span className="font-button">
                  {t(`navigationMenu.${subMenu.title}`)}
                </span>
              </div>
              <Icon
                iconName={ICONS.ARROW_DOWN_S_LINE}
                className={`arrow-icon ${
                  superSubMenuExpanded === subMenu.id && 'expanded'
                }`}
              />
            </AccessibleDiv>
            {superSubMenuExpanded === subMenu.id && getDropdowm(subMenu)}
          </div>
        ))}

      {!menu.subSectionsFirst && getMenuList(menu.subSections)}
    </div>
  );
};

export default SubMenuList;
