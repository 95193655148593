import { PROVIDER } from 'constants/cloudProviders';
import { AWS_CHECK_IDS } from 'constants/recommendations';
import { RecommendationSource } from 'pages/CostOptimizationInsightsPage/constants';

import { RecommendationDetailsListType } from './types';
import {
  AWS_EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER_FIELDS,
  AWS_EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER_FIELDS,
  AWS_LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT_FIELDS,
  AWS_LAMBDA_FUNCTIONS_HIGH_ERROR_RATES_FIELDS,
  AWS_LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY_FIELDS,
  AWS_OVERPROVISIONED_EBS_VOLUMES_FIELDS,
  AWS_RIGHT_SIZING_EC2_FIELDS,
  AWS_ROUTE_53_LATENCY_RECORD_SETS_FIELDS,
  AWS_UNDERUTILIZED_ENDPOINTS_FIELD,
  AWS_WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR_FIELDS,
  AZURE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS,
  CONSUMPTION_MANAGEMENT_COMMON_RECOMMENDATION_FIELDS,
  GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS,
  GRANULATE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS,
} from './constants';
import { RecommendationList } from '../../types';

/**
 * @function getDataByField
 * @description Retrieve the data by field name from the record provided
 * @param record Record from which the data is retrieved
 * @param field Field for which the data is retrieved from the record
 * @returns Data retrieved from the record
 */
export const getDataByField = (record: RecommendationList, field: string) => {
  return record[field as keyof RecommendationList];
};

/**
 * @function getAwsRecommendationFieldsByCheckId
 * @description Function to get the list of fields by checkId for AWS
 * @param checkId CheckId for which the fields are required
 * @param field Field for which the data is retrieved from the record
 * @returns List of fields
 */
const getAwsRecommendationFieldsByCheckId = (
  checkId: string
): RecommendationDetailsListType[] => {
  switch (checkId) {
    case AWS_CHECK_IDS.UNDERUTILIZED_ENDPOINTS:
      return AWS_UNDERUTILIZED_ENDPOINTS_FIELD;

    case AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES:
      return AWS_OVERPROVISIONED_EBS_VOLUMES_FIELDS;

    case AWS_CHECK_IDS.EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER:
      return AWS_EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER_FIELDS;

    case AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER:
      return AWS_EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER_FIELDS;

    case AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS:
      return AWS_ROUTE_53_LATENCY_RECORD_SETS_FIELDS;

    case AWS_CHECK_IDS.LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT:
      return AWS_LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT_FIELDS;

    case AWS_CHECK_IDS.LAMBDA_FUNCTIONS_HIGH_ERROR_RATES:
      return AWS_LAMBDA_FUNCTIONS_HIGH_ERROR_RATES_FIELDS;

    case AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY:
      return AWS_LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY_FIELDS;

    case AWS_CHECK_IDS.WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR:
      return AWS_WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR_FIELDS;

    case AWS_CHECK_IDS.RIGHT_SIZING_EC2:
      return AWS_RIGHT_SIZING_EC2_FIELDS;

    default:
      return [];
  }
};

/**
 * @function getConsumptionManagementRecommendationFieldsByProvider
 * @description Function to get the list of fields by provider
 * @param provider Provider for which the fields are required
 * @param recommendation Recommendation for which the details are required
 * @returns List of fields
 */
export const getConsumptionManagementRecommendationFieldsByProvider = (
  provider: string,
  recommendation: RecommendationList
): RecommendationDetailsListType[] => {
  const dataSource =
    recommendation.recommendationSource === RecommendationSource.GRANULATE
      ? recommendation.recommendationSource
      : provider;
  let fields: RecommendationDetailsListType[] = [];

  switch (dataSource) {
    case PROVIDER.GCP:
      fields = GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS;
      break;

    case PROVIDER.AWS:
      fields = getAwsRecommendationFieldsByCheckId(recommendation.checkId!);
      break;

    case PROVIDER.AZURE:
      fields = AZURE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS;
      break;

    case RecommendationSource.GRANULATE:
      fields = GRANULATE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS;
      break;

    default:
      fields = [];
      break;
  }

  return [...CONSUMPTION_MANAGEMENT_COMMON_RECOMMENDATION_FIELDS, ...fields];
};
