import i18n from 'i18n';

export enum GProfilerQuickActions {
  DEACTIVATE = 'DEACTIVATE',
  ACTIVATE = 'ACTIVATE',
  DELETE = 'DELETE',
}

export const GPROFILER_CONNECTION_QUICKACTIONS = [
  {
    id: GProfilerQuickActions.DEACTIVATE,
    title: i18n.t('gProfilerConnectionPage.deactivate'),
  },
  {
    id: GProfilerQuickActions.ACTIVATE,
    title: i18n.t('gProfilerConnectionPage.activate'),
  },
  {
    id: GProfilerQuickActions.DELETE,
    title: i18n.t('gProfilerConnectionPage.delete'),
  },
];

export enum ServiceStatus {
  DEACTIVATE = 'deactivate',
  ACTIVATE = 'activate',
}
