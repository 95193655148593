import i18n from 'i18n';
import {
  AnamolySubscriptionType,
  ALERT_SUBSCRIPTION_THRESHOLD_TYPE,
  CostMonitorListType,
} from './types';

export enum CostMonitorsQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const COST_MONORS_QUICKACTIONS = [
  {
    id: CostMonitorsQuickActions.EDIT,
    title: i18n.t('costMonitor.costMonitorTable.quickActions.edit'),
  },
  {
    id: CostMonitorsQuickActions.DELETE,
    title: i18n.t('costMonitor.costMonitorTable.quickActions.delete'),
  },
];

export enum CostMonitorComponents {
  PROVIDER_LIST = 'PROVIDER_LIST',
  COST_MONITOR_FORM = 'COST_MONITOR_FORM',
  SUCCESS_PAGE = 'SUCCESS_PAGE',
  ERROR_PAGE = 'ERROR_PAGE',
}

export const DEFAULT_ALERT_SUBSCRIPTION_FORM_VALUES: AnamolySubscriptionType = {
  subscriptionName: '',
  existingSubscription: false,
  thresholds: [
    {
      thresholdType: ALERT_SUBSCRIPTION_THRESHOLD_TYPE.AMOUNT,
      thresholdValue: '',
    },
  ],
};

export const DEFAULT_COST_MONITOR_FORM_VALUES: CostMonitorListType = {
  name: '',
  connectorName: '',
  provider: '',
  connectorId: '',
  anomalySubscriptions: [DEFAULT_ALERT_SUBSCRIPTION_FORM_VALUES],
};

export const THRESHOLD_TYPE_LABELS = [
  {
    type: ALERT_SUBSCRIPTION_THRESHOLD_TYPE.AMOUNT,
    label: i18n.t(
      'costMonitor.createCostMonitorLabels.createAlertSubscription.amountAboveExpectedSpend'
    ),
  },
  {
    type: ALERT_SUBSCRIPTION_THRESHOLD_TYPE.PERCENTAGE,
    label: i18n.t(
      'costMonitor.createCostMonitorLabels.createAlertSubscription.percentageAboveExpectedSpend'
    ),
  },
];
