import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataCenterComponents } from 'pages/DataCenterPage/constants';
import {
  ComputeEntryListType,
  DatabaseEntryListType,
  LaborEntryListType,
  SoftwareEntryListType,
  StorageEntryListType,
  DataCenterListType,
  NetworkEntryListType,
} from 'pages/DataCenterPage/types';
import { RootState } from './store';

export interface DataCenterState {
  dataCenterComponent: string;
  selectedDataCenter: DataCenterListType | undefined;
  computeEntries: ComputeEntryListType[];
  storageEntries: StorageEntryListType[];
  networkEntries: NetworkEntryListType[];
  databaseEntries: DatabaseEntryListType[];
  softwareEntries: SoftwareEntryListType[];
  laborEntries: LaborEntryListType[];
}

const initialState: DataCenterState = {
  dataCenterComponent: DataCenterComponents.DATA_CENTER_TABLE,
  selectedDataCenter: undefined,
  computeEntries: [],
  storageEntries: [],
  networkEntries: [],
  databaseEntries: [],
  softwareEntries: [],
  laborEntries: [],
};

export const dataCenterSlice = createSlice({
  name: 'dataCenter',
  initialState,
  reducers: {
    setDataCenterComponent: (state, action: PayloadAction<string>) => {
      state.dataCenterComponent = action.payload;
    },
    setSelectedDataCenter: (
      state,
      action: PayloadAction<DataCenterListType | undefined>
    ) => {
      state.selectedDataCenter = action.payload;
    },
    setComputeEntries: (
      state,
      action: PayloadAction<ComputeEntryListType[]>
    ) => {
      state.computeEntries = action.payload;
    },
    setStorageEntries: (
      state,
      action: PayloadAction<StorageEntryListType[]>
    ) => {
      state.storageEntries = action.payload;
    },
    setNetworkEntries: (
      state,
      action: PayloadAction<NetworkEntryListType[]>
    ) => {
      state.networkEntries = action.payload;
    },
    setDatabaseEntries: (
      state,
      action: PayloadAction<DatabaseEntryListType[]>
    ) => {
      state.databaseEntries = action.payload;
    },
    setSoftwareEntries: (
      state,
      action: PayloadAction<SoftwareEntryListType[]>
    ) => {
      state.softwareEntries = action.payload;
    },
    setLaborEntries: (state, action: PayloadAction<LaborEntryListType[]>) => {
      state.laborEntries = action.payload;
    },
  },
});

export const dataCenter = (state: RootState) => state.dataCenter;
export const {
  setDataCenterComponent,
  setSelectedDataCenter,
  setComputeEntries,
  setStorageEntries,
  setNetworkEntries,
  setDatabaseEntries,
  setSoftwareEntries,
  setLaborEntries,
} = dataCenterSlice.actions;
export default dataCenterSlice.reducer;
