import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  customDashboard,
  setSelectedChartType,
} from 'redux/customDashboardSlice';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';

import AddNewLayoutPlaceholder from '../AddNewLayoutPlaceholder';
import CustomTablesOrGraphs from '../CustomTablesOrGraphs';
import GraphOptions from '../GraphOptions';
import { CUTSOM_DASHBOARD_CHART_TYPE_OPTIONS } from '../../constants';

import './index.scss';

const CustomViewContainer = () => {
  const dispatch = useDispatch();
  const { selectedChartType } = useSelector(customDashboard);

  return (
    <div className="custom-view-container flex flex-fit">
      <div className="tables-and-graphs-container flex-fit new-styled-scroll">
        <CustomTablesOrGraphs />
        <AddNewLayoutPlaceholder />
      </div>
      <div className="graph-options flex flex-column">
        <HorizontalNavigationMenu
          className="graph-options-navs"
          menuItems={CUTSOM_DASHBOARD_CHART_TYPE_OPTIONS.map((option) => (
            <Menu.Item
              key={option.key}
              eventKey={option.key}
              className={`font-caption-bold flex-fit ${
                option.key === selectedChartType && 'active-menu'
              }`}
              onClick={() => dispatch(setSelectedChartType(option.key))}
            >
              {option.label}
            </Menu.Item>
          ))}
          selectedKeys={[]}
        />
        <GraphOptions />
      </div>
    </div>
  );
};

export default CustomViewContainer;
