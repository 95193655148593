import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { customDashboard } from 'redux/customDashboardSlice';
import { getSelectedChart } from 'pages/CustomDashboardPage/utils';
import { CHART_TYPES } from 'constants/graphConfig';
import { CHARTS_LIST } from 'constants/dashboard';

import ChartMetricList from '../ChartMetricList';

import './index.scss';

const ChartMetrics = () => {
  const { t } = useTranslation();
  const { selectedMetrics } = useSelector(customDashboard);

  /**
   * @function getValidationMessage
   * @description Function to return the validation message for metric in chart view
   * @returns string validation message
   */
  const getValidationMessage = () => {
    if (selectedMetrics.length === 0) {
      return t('customDashboard.optionsLabels.chooseMetricInTable');
    }

    const chart = getSelectedChart();
    if (chart?.chartType === CHART_TYPES.TABLE) {
      return t('customDashboard.optionsLabels.chooseChartType');
    }

    const selectedChartcMetriCount = CHARTS_LIST.find(
      (item) => item.chartType === chart?.chartType
    )?.metricsCount;
    if ((selectedChartcMetriCount?.min ?? 0) > selectedMetrics.length) {
      return t('customDashboard.optionsLabels.chooseAtleastNMetric', {
        count: selectedChartcMetriCount?.min ?? 0,
      });
    }

    return null;
  };

  /**
   * @function getMetricListComponent
   * @description Function to return the metric list component based on the validation
   * @returns JSX component
   */
  const getMetricListComponent = () => {
    const validationMessage = getValidationMessage();

    return validationMessage ? (
      <div className="metric-validation-message font-caption-bold">
        {validationMessage}
      </div>
    ) : (
      <ChartMetricList />
    );
  };

  return (
    <div className="chart-metrics">
      <div className="font-caption-bold">
        {t('customDashboard.optionsLabels.metrics')}
      </div>
      {getMetricListComponent()}
    </div>
  );
};

export default ChartMetrics;
