import { useEffect, useState } from 'react';
import moment from 'moment';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

import Input from 'components/Input';
import Pagination from 'components/Pagination';
import QuickActionMenu from 'components/QuickActionMenu';
import Table from 'components/Table';
import Empty from 'components/Empty';
import DeleteModal from 'components/DeleteModal';
import {
  ReportQuickActions,
  REPORT_QUICK_ACTIONS_LIST,
} from 'pages/ReportsPage/constants';
import { INPUT_SIZE } from 'constants/appearance';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { DATE_TIME_AM_PM } from 'utils/date';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { ReportType } from 'pages/ReportsPage/types';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { GroupIcon } from 'assets/icons';
import {
  deleteReport,
  getAllReports,
  searchReports,
} from 'pages/ReportsPage/services';
import { onApiCallError } from 'utils/handleErrors';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { ReportsViewType } from 'pages/CreateReportPage/constants';

import './index.scss';

const MyReport = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [reports, setReports] = useState<ReportType[]>([]);
  const [totalReportsCount, setTotalReportsCount] = useState(0);
  const [actionRecord, setActionRecord] = useState<ReportType>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fetchReportsReqStatus, setFetchReportsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [deleteReportReqStatus, setDeleteReportReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    let source = axios.CancelToken.source();
    setReports([]);
    getReports(currentPage, source);

    return () => {
      source.cancel();
    };
  }, [searchKey]);

  /**
   * @function getReports
   * @description function to fetch the reports
   * @param page page to fetch the data
   * @param cancelToken cancel token for cancelling the frequent requests
   */
  const getReports = (
    page: number = currentPage,
    cancelToken?: CancelTokenSource
  ) => {
    setFetchReportsReqStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      page: page - 1,
      size: PAGINATION_SIZE,
      shared: false,
      key: searchKey || undefined,
    };

    (searchKey
      ? searchReports(params, cancelToken?.token)
      : getAllReports(params, cancelToken?.token)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setReports(res?.data?.responseData?.content);
          setTotalReportsCount(res?.data?.responseData?.totalElements);
          setFetchReportsReqStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setFetchReportsReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchReportsReqStatus);
      });
  };

  /**
   * @function onDeleteError
   * @description Callback function for error on deleting a report
   */
  const onDeleteError = (errorMessage: string) => {
    message.error({
      content:
        errorMessage ||
        t('reports.errorDeletingReport', {
          reportName: actionRecord?.reportName,
        }),
      key: ERROR_KEY,
    });
    setDeleteReportReqStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function onClickDelete
   * @description function to delete a report
   */
  const onClickDelete = () => {
    setDeleteReportReqStatus(REQUEST_STATUS.PROCESSING);

    deleteReport({ reportName: actionRecord?.reportName })
      .then((res: any) => {
        if (res?.status === 200) {
          setDeleteReportReqStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          message.success({
            content: t('reports.successDeletingReport', {
              reportName: actionRecord?.reportName,
            }),
            key: SUCCESS_KEY,
          });
          getReports(1);
          setCurrentPage(1);
          return;
        }
        onDeleteError(res?.data?.message);
      })
      .catch((e) => {
        onApiCallError(e, false);
        onDeleteError(e?.response?.data?.message);
      });
  };

  /**
   * @function navigateToOpenOrEditReport
   * @description Function to to navigate based on the view type
   * @param view vie type to which the navigation is made
   * @param record report type record
   */
  const navigateToOpenOrEditReport = (view: string, record: ReportType) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('view', view);
    queryParams.set('report', record.reportName);
    navigate(NAVIGATION_MENU_PATH.CREATE_REPORT + '?' + queryParams.toString());
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions
   */
  const handleQuickAction = (record: ReportType, action: string) => {
    setActionRecord(record);

    switch (action) {
      case ReportQuickActions.OPEN:
        navigateToOpenOrEditReport(ReportsViewType.OPEN, record);
        break;

      case ReportQuickActions.EDIT:
        navigateToOpenOrEditReport(ReportsViewType.EDIT, record);
        break;

      case ReportQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: 50,
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
    },
    {
      title: t('reports.reportName'),
      dataIndex: 'reportName',
      key: 'reportName',
      render: (text: string) => (
        <span className="font-caption-bold">{text}</span>
      ),
    },
    {
      title: t('reports.connectionNameOrGroupName'),
      dataIndex: 'connectionName',
      key: 'connectionName',
      render: (_text: string, record: ReportType) => (
        <div className="flex flex-align-items-center flex-gap-4">
          <img
            className="provider-icon"
            src={
              record.cloudProvider
                ? getProviderSmallLogo(record.cloudProvider)
                : GroupIcon
            }
            alt={`${record.cloudProvider} Logo`}
          />
          {record.connectionName ?? record.groupName}
        </div>
      ),
    },
    {
      title: t('reports.dateCreated'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) =>
        text !== null ? moment(text).format(DATE_TIME_AM_PM) : '--',
    },
    {
      title: t('reports.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: ReportType) => {
        return (
          <QuickActionMenu
            quickActions={REPORT_QUICK_ACTIONS_LIST}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      align: 'center' as const,
    },
  ];

  return (
    <div className="my-report flex flex-column flex-fit">
      <Input
        type="search"
        value={searchKey}
        placeholder={t('reports.searchByReportName')}
        onChange={(e: any) => setSearchKey(e.target.value)}
        size={INPUT_SIZE.SMALL}
      />
      <Table
        pagination={false}
        rootClassName="table-section"
        dataSource={reports?.map((report, index) => {
          return { ...report, key: index + 1 };
        })}
        columns={columns}
        locale={{
          emptyText: fetchReportsReqStatus !== REQUEST_STATUS.PROCESSING && (
            <Empty
              description={
                fetchReportsReqStatus === REQUEST_STATUS.ERROR
                  ? t('graphErrorMessage')
                  : t('reports.noReportsAvailable')
              }
            />
          ),
        }}
        loading={fetchReportsReqStatus === REQUEST_STATUS.PROCESSING}
        designVersion2={true}
      />
      {totalReportsCount > PAGINATION_SIZE && (
        <Pagination
          rootClassName="flex flex-align-items-end flex-fit"
          current={currentPage}
          onChange={(page: number) => {
            setCurrentPage(page);
            setReports([]);
            getReports(currentPage);
          }}
          total={totalReportsCount}
          defaultPageSize={PAGINATION_SIZE}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={onClickDelete}
          loading={deleteReportReqStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default MyReport;
