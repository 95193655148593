import { REQUEST_STATUS } from 'constants/requestBody';
import { CMDBListType } from 'pages/CmdbSetupListPage/types';
import { onApiCallError } from 'utils/handleErrors';

import { fetchCmdbConfigurationById } from './services';

/**
 * @function fetchAndSetCmdbSetupConnectionDetails
 * @description Function to fetch CMDB connection details by CMDB name
 * @param id CMDB id for which the details are fetched
 * @param setData Object containing callbacks  to set the data fetched
 */
export const fetchAndSetCmdbSetupConnectionDetails = (
  id: string | null,
  setData: {
    setCmdbConnection: (val: CMDBListType | null) => void;
    setReqStatus: (val: string) => void;
    setError: (val: string | undefined) => void;
  }
) => {
  setData.setReqStatus(REQUEST_STATUS.PROCESSING);

  fetchCmdbConfigurationById({ cmdbConnectorId: id })
    .then((res: any) => {
      setData.setCmdbConnection(res.data.responseData);
      setData.setReqStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setData.setReqStatus);
      setData.setCmdbConnection(null);
      setData.setError(e.response.data.message);
    });
};
