import i18n from 'i18n';
import { ICONS } from 'constants/icons';
import { getDateMonthShortList, MONTH_YEAR_FORMAT } from 'utils/date';
import { AWS_RI_SERVICES, SCORECARDS_TIMERANGE } from './constants';

/**
 * @function getDateLabelsByGranularity
 * @param granularity - granularity selected
 * @param dateRange - date range for labels
 * @returns array of time labels for savings effectiveness chart
 */
export const getDateLabelsByGranularity = (
  granularity: string,
  dateRange: string[],
  dateFormat?: string
) => {
  if (granularity === SCORECARDS_TIMERANGE.MONTHLY.valueOf()) {
    return getDateMonthShortList(
      dateRange[0],
      dateRange[1],
      'month',
      dateFormat ?? MONTH_YEAR_FORMAT
    );
  }

  return getDateMonthShortList(dateRange[0], dateRange[1], 'day', dateFormat);
};

/**
 * @function getAwsRiServiceLabels
 * @description Function to return the aws RI label by service
 * @param service service name for which the data is required
 * @returns string label
 */
export const getAwsRiServiceLabels = (service: string) => {
  switch (service) {
    case AWS_RI_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE:
      return i18n.t('scorecard.awsReservedInstance.elasticComputeCloudEc2');

    case AWS_RI_SERVICES.AMAZON_ELASTICACHE:
      return i18n.t('scorecard.awsReservedInstance.elastiCache');

    case AWS_RI_SERVICES.AMAZON_MEMORY_DB:
      return i18n.t('scorecard.awsReservedInstance.memoryDB');

    case AWS_RI_SERVICES.AMAZON_ELASTIC_SEARCH:
      return i18n.t('scorecard.awsReservedInstance.elasticSearch');

    case AWS_RI_SERVICES.AMAZON_OPENSEARCH:
      return i18n.t('scorecard.awsReservedInstance.openSearch');

    case AWS_RI_SERVICES.AMAZON_REDSHIFT:
      return i18n.t('scorecard.awsReservedInstance.redshift');

    case AWS_RI_SERVICES.AMAZON_RELATIONAL_DATABASE_SERVICE:
      return i18n.t('scorecard.awsReservedInstance.rds');

    default:
      return '';
  }
};

/**
 * @function getReportType
 * @description Function to return the graph report type
 * @param defaultReportType default report type to be returned
 * @param reportType reportType to be returned
 * @returns string report type
 */
export const getReportType = (
  defaultReportType: string,
  reportType?: string
) => {
  return reportType ?? defaultReportType;
};

/**
 * @function getRowExpandIcon
 * @description Function to return the expand icon for the row
 * @param isExpanded boolean to indicate if the row is expanded or not
 * @returns string icon name
 */
export const getRowExpandIcon = (isExpanded: boolean) => {
  return isExpanded ? ICONS.ARROW_DOWN_S_LINE : ICONS.ARROW_RIGHT_S_LINE;
};
