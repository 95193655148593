import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import Table from 'components/Table';
import ColumnChart from 'components/ColumnChart';
import DashboardComponent from 'components/DashboardComponent';
import DatePicker from 'components/DatePicker';
import GraphHeader from 'components/GraphHeader';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { tcoDashboard } from 'redux/tcoDashboardSlice';
import { CostByCategory } from 'pages/TcoDashboardPage/types';
import { evaluateRequestArray } from 'utils/handleErrors';
import { MONTH_YEAR_FORMAT } from 'utils/date';

import { getTcoColumns, getTcoExcelData } from '../../utils/exportToExcel';

type TotalCostProps = {
  tableData: CostByCategory[];
  totalCostRequestStatus: string[];
  isTableView: boolean;
  pdfView?: boolean;
  setIsTableView: (value: boolean) => void;
  startMonth: string;
  endMonth: string;
  onChangeTotalCostDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  excelFilters: any[];
};

const TotalCost = ({
  tableData,
  totalCostRequestStatus,
  isTableView,
  pdfView,
  setIsTableView,
  startMonth,
  endMonth,
  onChangeTotalCostDateRange,
  excelFilters,
}: TotalCostProps) => {
  const { t } = useTranslation();
  const { selectedOnPremise, selectedTco } = useSelector(tcoDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[moment(startMonth), moment(endMonth)]}
        onChange={onChangeTotalCostDateRange}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  const getGraphData = () =>
    tableData.reduce((acc: any, item) => {
      acc.push({
        category: item.category,
        type: t('tcoDashboard.totalCost.onPremise'),
        value: item.onPremise,
      });
      if (selectedTco) {
        acc.push({
          category: item.category,
          type: t('tcoDashboard.totalCost.tco'),
          value: item.tco,
        });
        acc.push({
          category: item.category,
          type: t('tcoDashboard.totalCost.cloud'),
          value: item.cloud,
        });
      }
      return acc;
    }, []);

  const getComponent = () => (
    <div
      className={`graph-area full-height ${
        isTableView ? 'flex flex-gap-8' : ''
      }`}
    >
      <div
        className={`graph with-filters ${
          isTableView && 'with-table width-60 expand-hide'
        }`}
      >
        <ColumnChart
          data={getGraphData()}
          xField="category"
          yField="value"
          groupingField="type"
          isGroup
          xTitle={t('tcoDashboard.totalCost.category')}
          yTitle={t('tcoDashboard.totalCost.cost', {
            currencySymbol: currencySymbol,
          })}
          prefixSymbol={currencySymbol}
        />
      </div>
      {isTableView && (
        <div className="tabular-view width-40 full-height">
          <Table
            pagination={false}
            loading={totalCostRequestStatus.includes(REQUEST_STATUS.PROCESSING)}
            dataSource={tableData}
            columns={getTcoColumns(!!selectedOnPremise)}
            scroll={{ y: '100%' }}
            designVersion2={true}
            fillContainer={true}
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      className="total-cost full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.totalCost')}
        filters={filters}
        graphName="total-cost"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        excelData={getTcoExcelData(tableData, !!selectedOnPremise, [
          ...excelFilters,
          {
            heading: selectedOnPremise
              ? t('tcoDashboard.totalCost.dataCenter')
              : t('tcoDashboard.totalCost.project'),
            value: selectedOnPremise?.name ?? selectedTco?.name,
          },
        ])}
        className="extended-filter"
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray(totalCostRequestStatus)}
      />
    </div>
  );
};

export default TotalCost;
