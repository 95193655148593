import i18n from 'i18n';

export const PROGRESS_BAR_ITEMS = [
  {
    title: i18n.t('costAllocations.progressList.titleFirst'),
    description: i18n.t('costAllocations.progressList.descriptionFirst'),
  },
  {
    title: i18n.t('costAllocations.progressList.titleSecond'),
    description: i18n.t('costAllocations.progressList.descriptionSecond'),
  },
  {
    title: i18n.t('costAllocations.progressList.titleThird'),
    description: i18n.t('costAllocations.progressList.descriptionThird'),
  },
  {
    title: i18n.t('costAllocations.progressList.titleFourth'),
    description: i18n.t('costAllocations.progressList.descriptionFourth'),
  },
];
