import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';

import './index.scss';

type TeamRowProps = {
  teamName: string;
  onClickRemoveTeam: (val: string) => void;
};

const TeamRow = ({ teamName, onClickRemoveTeam }: TeamRowProps) => {
  return (
    <div
      key={teamName}
      className="team-row flex flex-align-items-center flex-gap-8"
    >
      <div className="team-pic flex flex-center font-button">T</div>
      <div className="font-button">{teamName}</div>
      <span className="flex flex-end flex-fit">
        <Icon
          className="remove-icon"
          iconName={ICONS.CLOSE_LINE}
          size={ICONS_SIZE.FW}
          onClick={() => onClickRemoveTeam(teamName)}
          dataTestId="remove-team-icon"
        />
      </span>
    </div>
  );
};

export default TeamRow;
