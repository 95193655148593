import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LineChart from 'components/LineChart';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { selectDashboard } from 'redux/dashboardSlice';
import { MonthlyCostByNameDataType } from 'types/dataTypes';
import { getMonthYearShortList, MONTH_YEAR_FORMAT } from 'utils/date';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getChartExcelExportData } from 'utils/exportToExcel';
import {
  getCostTimeLineDataColumns,
  getExcelServiceAggregatorFilterKey,
} from '../../utils';

type CostTimeLineProps = {
  title: string;
  labels: string[];
  graph: string;
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  startDate: string;
  endDate: string;
  selectedServiceAggregators: string[];
  timeLineData: MonthlyCostByNameDataType[];
  pdfView: boolean;
};
const CostTimeLine = ({
  title,
  labels,
  graph,
  requestStatus,
  isTableView,
  setIsTableView,
  startDate,
  endDate,
  selectedServiceAggregators,
  timeLineData,
  pdfView,
}: CostTimeLineProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const getComponent = () =>
    isTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={getGroupedTableData().map((item: any, index: number) => ({
            ...item,
            key: index,
          }))}
          columns={getCostTimeLineDataColumns(
            selectedDashboard!.connectorProvider,
            graph,
            startDate,
            endDate
          )}
          scroll={{ y: 230 }}
          designVersion2
        />
      </div>
    ) : (
      <LineChart
        data={getGraphData()}
        xField={'month'}
        yField={'value'}
        groupingField={'category'}
        xTitle={t('projectDeepDiveSummary.projectTimeLine.month')}
        yTitle={t('projectDeepDiveSummary.projectTimeLine.costAfterCredits', {
          currencySymbol: currencySymbol,
        })}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
        disableAnimation={pdfView}
      />
    );

  const getGraphData = () => {
    let graphData: any[] = [];
    let months = getMonthYearShortList(startDate, endDate, MONTH_YEAR_FORMAT);
    timeLineData.forEach((value) => {
      for (let i = 0; i < value.costData.length; i++) {
        graphData.push({
          month: months[i],
          value: value.costData[i] || 0,
          category: value.name?.trim() ?? '',
        });
      }
    });
    return graphData;
  };

  /**
   * @function getTableDataSource
   * @description function to get table data source for project time line
   * @param labels array of labels
   * @param timeLineData array of time line data
   * @returns object array of data with month, project, name and cost
   */
  const getTableDataSource = (
    labels: string[],
    timeLineData: MonthlyCostByNameDataType[]
  ) => {
    let tableData: any[] = [];
    labels?.forEach((label, index) => {
      timeLineData.forEach((value) =>
        tableData.push({
          month: label,
          project: value.project,
          name: value.name,
          cost: value.costData[index],
        })
      );
    });
    return tableData;
  };

  /**
   * @function getGroupedTableData
   * @description function to get table data source grouped by project or name
   * @returns object array of table data source
   */
  const getGroupedTableData = () => {
    const keyName = graph === 'service-time-line' ? 'name' : 'project';
    return getTableDataSource(labels, timeLineData).reduce((acc, item) => {
      const existingProject = acc.find(
        (project: any) => project[keyName] === item[keyName]
      );
      if (existingProject) {
        existingProject[`${item.month}-cost`] = item.cost;
      } else {
        acc.push({
          [keyName]: item[keyName],
          [`${item.month}-cost`]: item.cost,
        });
      }
      return acc;
    }, []);
  };

  return (
    <div
      className={`project-deep-dive-graph graph-card ${graph}`}
      id="graph-container"
    >
      <GraphHeader
        heading={title}
        graphName={graph}
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        excelData={getChartExcelExportData(
          title,
          getGroupedTableData(),
          getCostTimeLineDataColumns(
            selectedDashboard!.connectorProvider,
            graph,
            startDate,
            endDate
          ),
          {
            connectionName: selectedDashboard!.connectorName,
            [getExcelServiceAggregatorFilterKey(
              selectedDashboard!.connectorProvider
            )]: selectedServiceAggregators.join(', '),
            startDate,
            endDate,
          }
        )}
        designVersion2
      />
      <div className="graph no-filters">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default CostTimeLine;
