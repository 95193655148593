import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { getValidationStyle, validateEmptyField } from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';
import { getAzureDatabaseTables } from 'utils/services';
import { getWorkspaceLabelByDataSource } from './utils';

const BillingDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection } = useSelector(cloudConnection);

  // Validation states
  const [
    azureWorkspaceRequiredValidation,
    setAzureWorkspaceRequiredValidation,
  ] = useState('');
  const [azureDatabaseRequiredValidation, setAzureDatabaseRequiredValidation] =
    useState('');
  // Dropdown States
  const [azureBillingTableOptions, setAzureBillingTableOptions] = useState<
    string[]
  >([]);
  const [
    billingDatabaseTablesOptionsRequestStatus,
    setBillingDatabaseTablesOptionsRequestStatus,
  ] = useState('');

  useEffect(() => {
    if (
      currentConnection?.azureTenantId &&
      currentConnection?.azureSubscriptionId &&
      currentConnection?.azureAppId &&
      currentConnection?.azureClientSecret &&
      currentConnection?.azureDatabase
    ) {
      fetchAzureBillingDatabaseTables();
    }
  }, [
    currentConnection?.azureTenantId,
    currentConnection?.azureSubscriptionId,
    currentConnection?.azureAppId,
    currentConnection?.azureClientSecret,
    currentConnection?.azureSynapseWorkspaceName,
    currentConnection?.azureDatabase,
  ]);

  const getDropdown = (options: any[]) => (
    <>
      {options?.map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </>
  );

  /**
   * @function fetchAzureBillingDatabaseTables
   * @description Function to fetch billing dataset tables
   */
  const fetchAzureBillingDatabaseTables = () => {
    setBillingDatabaseTablesOptionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const body = isEditConnection
      ? {
          connectorId: currentConnection?.connectorId,
          billingDataset: currentConnection?.azureDatabase,
          azureSynapseWorkspace: currentConnection?.azureSynapseWorkspaceName,
          azureSqlServer: currentConnection?.azureSynapseWorkspaceName,
          dataSourceType: currentConnection?.dataSourceType,
        }
      : {
          azureTenantId: currentConnection?.azureTenantId,
          azureSubId: currentConnection?.azureSubscriptionId,
          azureClientId: currentConnection?.azureAppId,
          azureClientSecret: currentConnection?.azureClientSecret,
          billingDataset: currentConnection?.azureDatabase,
          azureSynapseWorkspace: currentConnection?.azureSynapseWorkspaceName,
          azureSqlServer: currentConnection?.azureSynapseWorkspaceName,
          dataSourceType: currentConnection?.dataSourceType,
        };
    getAzureDatabaseTables(body)
      .then((res: any) => {
        setAzureBillingTableOptions(res.data.responseData);
        setBillingDatabaseTablesOptionsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        setAzureBillingTableOptions([]);
        onApiCallError(e, true, setBillingDatabaseTablesOptionsRequestStatus);
      });
  };

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          title={getWorkspaceLabelByDataSource(
            currentConnection?.dataSourceType
          )}
          required
        />
        <Input
          default
          type="input"
          value={currentConnection?.azureSynapseWorkspaceName}
          onChange={(e: any) => {
            if (!e.target.value) {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  azureSynapseWorkspaceName: e.target.value,
                  azureBillingTable: '',
                })
              );
              return;
            }
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                azureSynapseWorkspaceName: e.target.value,
              })
            );
            setAzureWorkspaceRequiredValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              getWorkspaceLabelByDataSource(currentConnection?.dataSourceType),
              setAzureWorkspaceRequiredValidation
            );
          }}
          data-testid="workspace-input"
        />
        <span
          style={{
            display: getValidationStyle(azureWorkspaceRequiredValidation),
          }}
          className="font-validation-error"
        >
          {azureWorkspaceRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('azureConnectionCSPForm.step3.database')}
          required
        />
        <Input
          default
          type="input"
          value={currentConnection?.azureDatabase}
          onChange={(e: any) => {
            if (!e.target.value) {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  azureDatabase: e.target.value,
                  azureBillingTable: '',
                })
              );
              return;
            }
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                azureDatabase: e.target.value,
              })
            );
            setAzureDatabaseRequiredValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t('azureConnectionCSPForm.step3.database'),
              setAzureDatabaseRequiredValidation
            );
          }}
          data-testid="database-input"
        />
        <span
          style={{
            display: `${azureDatabaseRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {azureDatabaseRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('azureConnectionCSPForm.step3.billingTable')}
          required
        />
        <SelectDropdown
          value={currentConnection?.azureBillingTable}
          loading={
            billingDatabaseTablesOptionsRequestStatus ===
            REQUEST_STATUS.PROCESSING
          }
          menu={getDropdown(azureBillingTableOptions)}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                azureBillingTable: value,
              })
            );
          }}
          designVersion2
        />
      </div>
    </div>
  );
};

export default BillingDetails;
