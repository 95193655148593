import { useTranslation } from 'react-i18next';

import Switch from 'components/Switch';
import { REQUEST_STATUS } from 'constants/requestBody';

type FocusConversionToggleProps = {
  isFocusConversionEnabled: boolean;
  onChangeAdminControls: (field: string, value: boolean) => void;
  requestStatus: string;
};

const FocusConversionToggle = ({
  isFocusConversionEnabled,
  onChangeAdminControls,
  requestStatus,
}: FocusConversionToggleProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-space-between flex-align-items-center flex-gap-16"
      data-testid="control-row"
    >
      <div>
        <div className="modal-heading">{t('focusConversionPrompt')}</div>
        <div className="small-grey-button">
          {t('focusConversionPromptNote')}
        </div>
      </div>
      <Switch
        checked={isFocusConversionEnabled}
        onChange={(value: boolean) =>
          onChangeAdminControls('focusConversionEnabled', value)
        }
        loading={requestStatus === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );
};

export default FocusConversionToggle;
