import { Divider } from 'antd';

import ChartDimensions from '../ChartDimensions';
import ChartMetrics from '../ChartMetrics';

import './index.scss';

const DimensionsAndMetrics = () => {
  return (
    <div>
      <ChartDimensions />
      <Divider />
      <ChartMetrics />
    </div>
  );
};

export default DimensionsAndMetrics;
