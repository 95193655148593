import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getGcpAutoPilotAppliedRecommendations
 * @description Function to fetch the Autopilot Applied recommendations for a connection for GCP
 * @param params request parameters
 * @return axios response from GET request
 */
export const getGcpAutoPilotAppliedRecommendations = async (params: any) => {
  return ServiceCalls.get(
    APIS.GET_GCP_AUTOPILOT_APPLIED_RECOMMENDATIONS,
    null,
    params
  );
};

/**
 * @function getAwsAutoPilotAppliedRecommendations
 * @description Function to fetch the Autopilot Applied recommendations for a connection for AWS
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAwsAutoPilotAppliedRecommendations = async (params: any) => {
  return ServiceCalls.get(
    APIS.GET_AWS_AUTOPILOT_APPLIED_RECOMMENDATIONS,
    null,
    params
  );
};
