import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingIcon } from 'assets/icons';
import Switch from 'components/Switch';
import ConnectionListDropdown from 'components/ConnectionListDropdown';
import Icon from 'components/Icon';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PDF_ALL_GRAPHS } from 'constants/pdfConstants';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  selectDashboard,
  setPdfGraphToDownload,
  setPdfDownloadMode,
  setTableViewEnabled,
} from 'redux/dashboardSlice';
import {
  selectAnomalyDetectionDashboard,
  setSelectedAnomalyDetectionConnection,
} from 'redux/anomalyDetectionDashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import { exportToExcel } from 'utils/exportToExcel';
import { getProviderLogo } from 'utils/providerDetails';

import './index.scss';

type DashboardHeaderProps = {
  fetchConnectionsRequestStatus: string;
};

const DashboardHeader = ({
  fetchConnectionsRequestStatus,
}: DashboardHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { exportToExcelData, tableViewEnabled, pdfDownloadMode } =
    useSelector(selectDashboard);
  const { anomalyDetectionConnectionList, selectedAnomalyDetectionConnection } =
    useSelector(selectAnomalyDetectionDashboard);
  const { theme } = useSelector(selectTheme);

  const [isDownloadingData, setIsDownloadingData] = useState(false);

  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  useEffect(() => {
    if (anomalyDetectionConnectionList.length === 0) {
      return;
    }

    if (!selectedAnomalyDetectionConnection) {
      dispatch(
        setSelectedAnomalyDetectionConnection(anomalyDetectionConnectionList[0])
      );
    }
  }, [anomalyDetectionConnectionList]);

  /**
   * @function onHandleDownloadDashboardData
   * @description function to handle common download of dashboard view. Will download pdf dashboard view is static except for cost optimization summary other wise will download data as excell
   * @returns either pdf or excel
   */
  const onHandleDownloadDashboardData = () => {
    setIsDownloadingData(true);
    if (!tableViewEnabled) {
      dispatch(setPdfGraphToDownload(PDF_ALL_GRAPHS));
      dispatch(setPdfDownloadMode(true));
      return;
    }
    setIsDownloadingData(false);
    return exportToExcel(
      selectedAnomalyDetectionConnection?.name ?? '',
      exportToExcelData
    );
  };

  return (
    <div className="anomaly-detection-header new-page-header flex flex-align-items-center flex-space-between flex-wrap">
      {selectedAnomalyDetectionConnection ? (
        <div className="anomaly-detection-dashboard-title flex flex-align-items-center flex-gap-16">
          <img
            width={35}
            height={35}
            src={getProviderLogo(selectedAnomalyDetectionConnection.provider)}
            alt={`${selectedAnomalyDetectionConnection.provider} Logo`}
          />
          <div className="title-container flex flex-row">
            <div className="edit flex flex-center">
              <div className="modal-heading">
                {selectedAnomalyDetectionConnection.displayName}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Icon icon={LoadingIcon} className="rotate" />
      )}
      <div className="header-right flex flex-align-items-center flex-gap-16">
        {isDownloadingData ? (
          <Icon
            icon={LoadingIcon}
            className="rotate"
            color={theme.buttonIconColor}
          />
        ) : (
          <Icon
            iconName={ICONS.DOWNLOAD_LINE}
            onClick={() => onHandleDownloadDashboardData()}
            size={ICONS_SIZE.ONE_X}
            color={theme.buttonIconColor}
          />
        )}
        <div className="table-view-title table-typography flex flex-align-items-center flex-gap-8">
          {t('tableView')}
          <Switch
            size="small"
            checked={tableViewEnabled}
            onChange={(value: boolean) => {
              dispatch(setTableViewEnabled(value));
            }}
          />
        </div>
        <div className="connection-list">
          <ConnectionListDropdown
            selectedConnection={selectedAnomalyDetectionConnection}
            connectionList={anomalyDetectionConnectionList}
            onSelectDropdown={(key: any) =>
              dispatch(
                setSelectedAnomalyDetectionConnection(
                  anomalyDetectionConnectionList?.find(
                    (connection) => connection.connectorId === key
                  ) ?? null
                )
              )
            }
            loadingDropdown={
              fetchConnectionsRequestStatus === REQUEST_STATUS.PROCESSING
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
