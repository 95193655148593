import { useTranslation } from 'react-i18next';
import { ChartDataset } from 'chart.js';
import { useSelector } from 'react-redux';
import { selectAnomalyDetectionDashboard } from 'redux/anomalyDetectionDashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import BarGraph from 'components/BarGraph';
import Table from 'components/Table';
import GraphHeader from 'components/GraphHeader';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getMonthAndYearStringFormat } from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { generateLabelsWithLine, onClickHideDataset } from 'utils/graphUtils';
import { getCostAndUsageExcelData } from '../../utils';
import { AWSAnomalyType, DaywiseExpectedAndActualCostType } from '../../types';

type CostAndUsageProps = {
  costAndUsageDataSet: ChartDataset<'bar', number[]>[];
  costAndUsageLabels: string[];
  selectedRow: AWSAnomalyType | undefined;
  setShowExpandedGraphModal: (val: boolean) => void;
  requestStatus: string[];
  costAndUsageData: DaywiseExpectedAndActualCostType[];
  isCostAndUsageTableView: boolean;
  setIsCostAndUsageTableView: (value: boolean) => void;
  pdfView?: boolean;
};
const CostAndUsage = ({
  costAndUsageDataSet,
  costAndUsageLabels,
  selectedRow,
  setShowExpandedGraphModal,
  requestStatus,
  isCostAndUsageTableView,
  costAndUsageData,
  setIsCostAndUsageTableView,
  pdfView,
}: CostAndUsageProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'anomalyDetectionDashboard.usageAndCost',
  });
  const { selectedAnomalyDetectionConnection } = useSelector(
    selectAnomalyDetectionDashboard
  );
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('actualCost', { currencySymbol: currencySymbol }),
      dataIndex: 'actualCost',
      key: 'actualCost',
      render: (text: any) => numberCommaSeparator(text),
    },
    {
      title: t('expectedCost', { currencySymbol: currencySymbol }),
      dataIndex: 'expectedCost',
      key: 'expectedCost',
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text),
    },
  ];

  const getYAxisMaximum = () => {
    const maxCostByMonth = Math.max(
      ...costAndUsageData.map((item) => item.actualCost)
    );
    return Math.ceil(maxCostByMonth * 0.1 + maxCostByMonth);
  };

  const getStepSize = () => {
    return getYAxisMaximum() <= 1 ? 0.01 : 0.1;
  };

  const getComponent = () => {
    if (!selectedRow) {
      return (
        <DashboardComponent
          requestStatus={REQUEST_STATUS.ERROR}
          errorMessage={t('chooseAnAnomaly')}
        />
      );
    }

    return isCostAndUsageTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={
            evaluateRequestArray(requestStatus) === REQUEST_STATUS.PROCESSING
          }
          dataSource={costAndUsageData.map((item, index) => ({
            ...item,
            month: getMonthAndYearStringFormat(item.date),
            slNo: index + 1,
            key: index,
          }))}
          columns={columns}
          scroll={{ y: 250 }}
        />
      </div>
    ) : (
      <BarGraph
        labels={costAndUsageLabels}
        dataSet={costAndUsageDataSet}
        showXAxisTitle={true}
        xAxisTitle={t('date')}
        showYAxisTitle={true}
        yAxisTitle={t('cost', { currencySymbol: currencySymbol })}
        showXAxisGrid={false}
        showYAxisGrid={true}
        yAxisMinimumValue={0}
        yAxisMaximumValue={getYAxisMaximum()}
        stepSize={getStepSize()}
        xAxisStacked={true}
        yAxisStacked={true}
        beginAtZero={false}
        generateColors={false}
        pointRadius={4}
        generateLabels={generateLabelsWithLine}
        onClickLegend={onClickHideDataset}
      />
    );
  };

  return (
    <div className="cost-and-usage graph-area" id="graph-container">
      <div className="flex flex-column">
        <GraphHeader
          heading={t('graphHeading')}
          setShowExpandedGraph={setShowExpandedGraphModal}
          graphName="cost-and-usage"
          isDownloadable={!pdfView}
          isTableViewSwitch={!pdfView}
          showExpandIcon={!pdfView}
          isTableView={isCostAndUsageTableView}
          setIsTableView={setIsCostAndUsageTableView}
          excelData={getCostAndUsageExcelData(
            selectedAnomalyDetectionConnection?.name ?? '',
            costAndUsageData
          )}
        />
        <span className="font-caption sub-heading">
          {selectedRow?.rootCauses[0]?.service}
        </span>
      </div>
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default CostAndUsage;
