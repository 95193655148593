import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { selectCostAllocationDashboard } from 'redux/costAllocationDashboardSlice';
import { LoadingIcon } from 'assets/icons';
import Input from 'components/Input';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { updateDashboardTitle } from 'utils/services';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectTheme } from 'redux/themeSlice';
import { onApiCallError } from 'utils/handleErrors';

import { ERROR_KEY, SUCCESS_KEY } from './constants';

import './index.scss';

type DashboardTitleProps = {
  fetchDashboards?: () => void;
  showEditIcon?: boolean;
};

const DashboardTitle = ({
  fetchDashboards,
  showEditIcon = true,
}: DashboardTitleProps) => {
  const { t } = useTranslation();
  const { selectedCostAllocationDashboard, costAllocationDashboardList } =
    useSelector(selectCostAllocationDashboard);
  const { theme } = useSelector(selectTheme);

  const [editTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState('');

  useEffect(() => {
    setTitle(selectedCostAllocationDashboard?.name!);
  }, [selectedCostAllocationDashboard]);

  /**
   * @function isDuplicateDashboardTitle
   * @description Function to check the duplicate dashboard title
   * @param newTitle new title to valdate
   * @returns boolean true if it's duplicate title else false
   */
  const isDuplicateDashboardTitle = (newTitle: string) => {
    return costAllocationDashboardList.some(
      (dashboard) =>
        dashboard.id !== selectedCostAllocationDashboard?.id &&
        dashboard.connectorId ===
          selectedCostAllocationDashboard?.connectorId &&
        dashboard.name === newTitle
    );
  };

  /**
   * @function updateTitle
   * @description Function to update the new dashobd title
   * @param e input field event
   */
  const updateTitle = (e: any) => {
    if (isDuplicateDashboardTitle(e.target.value)) {
      message.error({
        content: t('dashboardLabels.duplicateDashboardName'),
        key: ERROR_KEY,
      });
      return;
    }

    setLoading(REQUEST_STATUS.PROCESSING);
    setTitle(e.target.value);
    const params = {
      dashboardId: selectedCostAllocationDashboard?.id!,
      updateDashboardName: e.target.value,
    };
    updateDashboardTitle(params)
      .then((_res) => {
        fetchDashboards?.();
        setEditTitle(false);
        setLoading(REQUEST_STATUS.SUCCESS);
        message.success({
          content: t('dashboardLabels.titleUpdateSuccessMessage'),
          key: SUCCESS_KEY,
        });
      })
      .catch((error) => {
        onApiCallError(
          e,
          true,
          setLoading,
          t('dashboardLabels.titleUpdateFailureMessage')
        );
      });
  };

  return (
    <div className="cost-allocation-dashboard-title flex flex-align-items-center flex-gap-16">
      <img
        width={50}
        height={50}
        src={getProviderSmallLogo(
          selectedCostAllocationDashboard?.connectorProvider!
        )}
        alt={`${selectedCostAllocationDashboard?.connectorProvider} Logo`}
      />
      <div className="flex flex-column">
        <div className="title-container flex flex-row">
          {editTitle ? (
            <div className="flex flex-align-items-center">
              <Input
                defaultValue={title}
                onPressEnter={(e: any) => updateTitle(e)}
                onBlur={(e: any) => updateTitle(e)}
              />
              {loading === REQUEST_STATUS.PROCESSING && (
                <Icon icon={LoadingIcon} className="rotate" />
              )}
            </div>
          ) : (
            <div className="edit flex flex-center">
              <div className="modal-heading">{title}</div>
              {showEditIcon && (
                <Icon
                  className="edit-icon"
                  iconName={ICONS.EDIT_LINE}
                  onClick={() => setEditTitle(true)}
                  color={theme.buttonIconColor}
                />
              )}
            </div>
          )}
        </div>
        <div className="sub-title-container flex font-caption-bold">
          <div>{selectedCostAllocationDashboard?.connectorName}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTitle;
