import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import { selectDashboard, setShowExpandGraphModal } from 'redux/dashboardSlice';

import './index.scss';

type ExpandModalProps = {
  show?: boolean;
  onClose?: () => void;
  graphContent: React.ReactNode;
  width?: string | number;
  height?: string | number;
};

const ExpandModal = ({
  show,
  onClose,
  graphContent,
  width = '90%',
  height = 'calc(100vh - 8em)',
}: ExpandModalProps) => {
  const dispatch = useDispatch();
  const { showExpandGraphModal } = useSelector(selectDashboard);

  const onCloseExpandModal = () => {
    onClose?.();
    dispatch(setShowExpandGraphModal(false));
  };

  return (
    <Modal
      className="expand-modal"
      centered
      open={show ?? showExpandGraphModal}
      closable={false}
      onCancel={onCloseExpandModal}
      width={width}
      bodyStyle={{ height: height }}
      data-testid="expand-modal"
    >
      {graphContent}
    </Modal>
  );
};

export default ExpandModal;
