import i18n from 'i18n';

import { REQUEST_STATUS } from 'constants/requestBody';
import { store } from 'redux/store';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const getColumns = () => {
  return [
    {
      title: i18n.t('purchasePage.instance'),
      dataIndex: 'instanceType',
      key: 'instanceType',
      width: 100,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: i18n.t('purchasePage.risk'),
      dataIndex: 'risk',
      key: 'risk',
      width: 150,
      render: (text: string) => text.replace('Risk', ''),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: i18n.t('purchasePage.return'),
      dataIndex: 'profit',
      key: 'profit',
      render: (text: string) => text.replace('Return', ''),
      width: 120,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: i18n.t('purchasePage.region'),
      dataIndex: 'region',
      key: 'region',
      width: 120,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: `${i18n.t(
        'purchasePage.estimatedOnDemandCost'
      )} (${currencySymbol})`,
      dataIndex: 'estimatedOnDemandCost',
      key: 'estimatedOnDemandCost',
      width: 200,
      render: (text: string) => currencySymbol + numberCommaSeparator(text),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: `${i18n.t(
        'purchasePage.estimatedMonthlyOnDemandCost'
      )} (${currencySymbol})`,
      dataIndex: 'monthlyOnDemandCost',
      key: 'monthlyOnDemandCost',
      width: 200,
      render: (text: string) => currencySymbol + numberCommaSeparator(text),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: `${i18n.t(
        'purchasePage.estimatedSavingsPlansCost'
      )} (${currencySymbol})`,
      dataIndex: 'estimatedSavingsPlansCost',
      key: 'estimatedSavingsPlansCost',
      width: 200,
      render: (text: string) => currencySymbol + numberCommaSeparator(text),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: `${i18n.t('purchasePage.monthlyCostAfterSP')} (${currencySymbol})`,
      dataIndex: 'monthlyCostAfterSP',
      key: 'monthlyCostAfterSP',
      width: 180,
      render: (text: string) => currencySymbol + numberCommaSeparator(text),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: `${i18n.t(
        'purchasePage.estimatedMonthlySavings'
      )} (${currencySymbol})`,
      dataIndex: 'estimatedMonthlySavings',
      key: 'estimatedMonthlySavings',
      width: 150,
      render: (text: string) => currencySymbol + numberCommaSeparator(text),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: i18n.t('purchasePage.estimatedSavingsPercentage'),
      dataIndex: 'estimatedSavingsPercentage',
      key: 'estimatedSavingsPercentage',
      width: 150,
      render: (text: string) => numberCommaSeparator(text) + '%',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: i18n.t('purchasePage.estimatedROI'),
      dataIndex: 'estimatedROI',
      key: 'estimatedROI',
      width: 120,
      render: (text: string) => numberCommaSeparator(text) + '%',
      sorter: true,
      showSorterTooltip: false,
    },
  ];
};

export const getDefaultRiMetricCards = () => {
  return [
    {
      value: 0,
      heading: i18n.t('purchasePage.recommendedSavingsPlans'),
      requestStatus: [REQUEST_STATUS.PROCESSING],
      valueMinimumFractionDigits: 0,
    },
    {
      value: 0,
      heading: i18n.t('purchasePage.monthlyOnDemandCost'),
      requestStatus: [REQUEST_STATUS.PROCESSING],
      valuePrefix: currencySymbol,
    },
    {
      value: 0,
      heading: i18n.t('purchasePage.estimatedMonthlySpend'),
      requestStatus: [REQUEST_STATUS.PROCESSING],
      valuePrefix: currencySymbol,
      valueMaximumFractionDigits: 2,
    },
    {
      value: 0,
      heading: i18n.t('purchasePage.estimatedMonthlySavings'),
      requestStatus: [REQUEST_STATUS.PROCESSING],
      valuePrefix: currencySymbol,
      valueMaximumFractionDigits: 2,
    },
  ];
};
