import i18n from 'i18n';

import { PROVIDER } from 'constants/cloudProviders';

import {
  AzureDataSourceTypeOptions,
  AWSDataSourceTypeOptions,
  GCPDataSourceTypeOptions,
  OciDatasourceOptions,
  PlanType,
} from './constants';

export const getDataSourceTypesByCloudProvider = (cloudProvider: string) => {
  switch (cloudProvider) {
    case PROVIDER.GCP:
      return GCPDataSourceTypeOptions;
    case PROVIDER.AZURE:
      return AzureDataSourceTypeOptions;
    case PROVIDER.AWS:
      return AWSDataSourceTypeOptions;
    case PROVIDER.OCI:
      return OciDatasourceOptions;
  }
};

/**
 * @function getPlanTypeOptionsByProvider
 * @description Returns the list of plan types for the given provider
 * @param provider CSP provider for which the plan types is returned
 * @return List containing the label and value of the Plan Types
 */
export const getPlanTypeOptionsByProvider = (provider: string) => {
  if (provider === PROVIDER.AWS) {
    return [
      {
        label: i18n.t('default'),
        value: PlanType.NORMAL,
      },
      {
        label: PlanType.EDP,
        value: PlanType.EDP,
      },
    ];
  }

  return [
    {
      label: i18n.t('default'),
      value: PlanType.NORMAL,
    },
    {
      label: PlanType.MOSA,
      value: PlanType.MOSA,
    },
    {
      label: PlanType.EA,
      value: PlanType.EA,
    },
    {
      label: PlanType.MCA,
      value: PlanType.MCA,
    },
    {
      label: PlanType.MANAGEMENT_GROUP,
      value: PlanType.MANAGEMENT_GROUP,
    },
    {
      label: PlanType.CSP,
      value: PlanType.CSP,
    },
  ];
};
