import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'components/Input';
import { FormLabel } from 'components/FormLabel';
import DatePicker from 'components/DatePicker';
import DropdownCheckbox from 'components/DropdownCheckbox';
import Cascader from 'components/Cascader';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import { REQUEST_STATUS } from 'constants/requestBody';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { DATE_FORMAT, HYPHEN_DATE_FORMAT } from 'utils/date';
import { getGcpBudgetProjects, getGcpBudgetServices } from 'utils/services';
import { KeyValueTypes } from 'types/dataTypes';
import { onApiCallError } from 'utils/handleErrors';

import {
  BILLING_ACCOUNT_SLICE_END,
  BILLING_ACCOUNT_SLICE_START,
  BUDGET_DATE_RANGES,
  DATE_RANGE,
} from '../SetupBudgetsDetails/constants';

const ScopeForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { budgetData, isEdit } = useSelector(budgetsAndAlerts);

  const [allProjects, setAllProjects] = useState<KeyValueTypes[]>([]);
  const [allServices, setAllServices] = useState<KeyValueTypes[]>([]);
  const [budgetNameValidation, setBudgetNameValidation] = useState('');
  const [projectsLoading, setProjectsLoading] = useState<string>('');
  const [servicesLoading, setServicesLoading] = useState<string>('');
  const [projectsReqValidation, setProjectsReqValidation] = useState('');
  const [servicesReqValidation, setServicesReqValidation] = useState('');

  useEffect(() => {
    if (budgetData.connectorId) {
      fetchBudgetServices();
      fetchBudgetProjects();
    }
  }, [budgetData.connectorId]);

  /**
   * @function fetchBudgetServices
   * @description Function to set services dropdown based on the fetched budget data
   */
  const fetchBudgetServices = () => {
    const params = {
      connectorId: budgetData.connectorId,
    };
    setServicesLoading(REQUEST_STATUS.PROCESSING);
    getGcpBudgetServices(params)
      .then((res: any) => {
        const list = res.data.responseData;
        const services: KeyValueTypes[] = [];
        for (const serviceData in list) {
          services.push({
            key: serviceData,
            value: list[serviceData],
          });
        }
        setAllServices(services);
        setServicesLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setServicesLoading);
      });
  };

  /**
   * @function fetchBudgetProjects
   * @description Function to set projects dropdown based on the fetched budget data
   */
  const fetchBudgetProjects = () => {
    const params = {
      connectorId: budgetData.connectorId,
      billingAccount: isEdit
        ? budgetData.name?.slice(
            BILLING_ACCOUNT_SLICE_START,
            BILLING_ACCOUNT_SLICE_END
          )
        : budgetData.billingAccount,
    };
    setProjectsLoading(REQUEST_STATUS.PROCESSING);
    getGcpBudgetProjects(params)
      .then((res: any) => {
        const list = res.data.responseData;
        const projects: KeyValueTypes[] = [];
        list.forEach((element: any) => {
          projects.push({
            key: `projects/${element.projectNumber}`,
            value: element.projectId,
          });
        });
        setAllProjects(projects);
        setProjectsLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setProjectsLoading);
      });
  };

  /**
   * @function validateBudgetName
   * @description Function to validate the budget name
   * @param value value to be validated
   * @return boolean true if the validation is successful else false
   */
  const validateBudgetName = (value: string) => {
    if (
      validateEmptyField(
        value,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    if (
      validateStringLengthLessThan(
        value,
        MAX_CHARACTER_LIMIT,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    return true;
  };

  /**
   * @function onChangeDates
   * @description Function handles change in start and end date and does validation.
   * @param _dates is not used.
   * @param dateString is an array whose first element is start date and second element is end date.
   */
  const onChangeDates = (
    dates: [Moment, Moment],
    _dateString: [string, string]
  ) => {
    const start = dates[0].format(HYPHEN_DATE_FORMAT);
    const end = dates[1].format(HYPHEN_DATE_FORMAT);
    dispatch(
      setBudgetData({
        ...budgetData,
        timeRange: BUDGET_DATE_RANGES.CUSTOM_PERIOD,
        from: start,
        to: end,
      })
    );
  };

  /**
   * @function displayDateRange
   * @description Function to display selected dates (if date range is custom) or date range type.
   * @param label isn't used.
   * @param selectedOptions is an array which includes the label.
   * @return Returns label as is if date range is not custom. Returns the dates selected if date range is custom in a string form
   */
  const displayDateRange = (_label: string[], selectedOptions: any) => {
    if (selectedOptions[0].value === BUDGET_DATE_RANGES.CUSTOM_PERIOD) {
      return `${moment(budgetData.from, HYPHEN_DATE_FORMAT).format(
        DATE_FORMAT
      )} - ${moment(budgetData.to, HYPHEN_DATE_FORMAT).format(DATE_FORMAT)}`;
    }
    return selectedOptions[0].label;
  };

  const BudgetRangeOptions: any = [
    ...DATE_RANGE.map((option) => {
      if (option.key === BUDGET_DATE_RANGES.CUSTOM_PERIOD) {
        return {
          value: option.key,
          label: t(`addBudgetAlert.createBudgetLabels.scope.${option.title}`),
          children: [
            {
              label: (
                <DatePicker
                  format={DATE_FORMAT}
                  value={
                    budgetData.from && budgetData.to
                      ? [moment(budgetData.from), moment(budgetData.to)]
                      : undefined
                  }
                  disabledDate={(current: any) =>
                    current < moment('2017-01-01').toDate()
                  }
                  onChange={onChangeDates}
                  designVersion2
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: t(`addBudgetAlert.createBudgetLabels.scope.${option.title}`),
      };
    }),
  ];

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.name')}
          required={true}
        />
        <Input
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.namePlaceholder'
          )}
          value={budgetData.displayName}
          onChange={(e: any) => {
            validateBudgetName(e.target.value);
            dispatch(
              setBudgetData({
                ...budgetData,
                displayName: e.target.value,
              })
            );
          }}
          onBlur={(e: any) => validateBudgetName(e.target.value)}
        />
        <span
          style={{
            display: `${getValidationStyle(budgetNameValidation)}`,
          }}
          className="font-validation-error"
        >
          {budgetNameValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.timeRange')}
          required={true}
        />
        <Cascader
          options={BudgetRangeOptions}
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.timeRangePlaceholder'
          )}
          value={[budgetData.timeRange]}
          displayRender={displayDateRange}
          dropdownMatchSelectWidth
          onChange={(value: any) => {
            if (value[0] !== BUDGET_DATE_RANGES.CUSTOM_PERIOD)
              dispatch(
                setBudgetData({
                  ...budgetData,
                  timeRange: value[0],
                  from: undefined,
                  to: undefined,
                })
              );
          }}
          autoFocus={budgetData.timeRange === BUDGET_DATE_RANGES.CUSTOM_PERIOD}
          designVersion2
        />
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.projects')}
          required={true}
        />
        <DropdownCheckbox
          loading={projectsLoading === REQUEST_STATUS.PROCESSING}
          itemOptions={allProjects.map((service) => ({
            value: service.key,
            title: service.value.toString(),
          }))}
          selectedItems={budgetData.projects}
          showSearch={true}
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.projectsPlaceholder'
          )}
          setSelectedItems={(values: string[]) => {
            values.length > 0
              ? setProjectsReqValidation('')
              : setProjectsReqValidation(
                  t('addBudgetAlert.createBudgetLabels.scope.projectsRequired')
                );
            dispatch(setBudgetData({ ...budgetData, projects: values }));
          }}
          designVersion2
        />
        <span
          style={{
            display: `${getValidationStyle(projectsReqValidation)}`,
          }}
          className="font-validation-error"
        >
          {projectsReqValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.services')}
          required={true}
        />
        <DropdownCheckbox
          loading={servicesLoading === REQUEST_STATUS.PROCESSING}
          itemOptions={allServices.map((service) => ({
            value: service.key,
            title: service.value.toString(),
          }))}
          selectedItems={budgetData.services}
          showSearch={true}
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.servicesPlaceholder'
          )}
          setSelectedItems={(values: string[]) => {
            values.length > 0
              ? setServicesReqValidation('')
              : setServicesReqValidation(
                  t('addBudgetAlert.createBudgetLabels.scope.servicesRequired')
                );
            dispatch(setBudgetData({ ...budgetData, services: values }));
          }}
          designVersion2={true}
        />
        <span
          style={{
            display: `${getValidationStyle(servicesReqValidation)}`,
          }}
          className="font-validation-error"
        >
          {servicesReqValidation}
        </span>
      </div>
    </div>
  );
};

export default ScopeForm;
