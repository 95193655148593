import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import { Col, Row } from 'antd';

import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ComparisonCard from 'components/NewComparisonCard';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { IntegrationTypes } from 'pages/IntegrationsPage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CHART_TYPES } from 'constants/graphConfig';
import { ComparisonListType } from 'types/dashboard';
import {
  CostByNameType,
  MonthlyCostByNameType,
  MonthlyCostType,
} from 'types/dataTypes';
import {
  getMonthYearShortList,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { getSnowflakeChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

import TopAccountsByCost from './TopAccountsByCost';
import TopWarehousesByCost from './TopWarehousesByCost';
import { CostTrendExportColumns } from '../SnowflakeDashboardComponents/constants';
import { getCostByNamePerMonthExportColumns } from '../SnowflakeDashboardComponents/utils';
import {
  AccountsCountQuery,
  AvgDailyCostQuery,
  AvgDailyCreditsUsedQuery,
  ComputeCostQuery,
  CreditsConsumedQuery,
  TopAccountsByCostExportColumns,
  TopWarehousesByCostExportColumns,
  WarehousesCountQuery,
} from './constants';
import {
  getCreditsUsedByWarehousePerMonthQuery,
  getSelectedData,
  getSnowflakeWarehouseExcelData,
  getTopAccountsByComputeCostQuery,
  getTopWarehousesByComputeCostQuery,
  getWarehouseCostTrendQuery,
} from './utils';
import CostByNamePerMonth from '../SnowflakeDashboardComponents/CostByServiceTypePerMonth';
import CostTrend from '../SnowflakeDashboardComponents/CostTrend';

const SnowflakeWarehouseDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedDashboard,
    tableViewEnabled,
    expandedGraphName,
    showExpandGraphModal,
    pdfDownloadMode,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  //States for comparison cards
  const [costComparisonList, setCostComparisonList] = useState<
    ComparisonListType[]
  >([]);
  const [accountsCount, setAccountsCount] = useState(0);
  const [accountsCountReqStatus, setAccountsCountReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [warehouseCount, setWarehouseCount] = useState(0);
  const [warehouseCountReqStatus, setWarehouseCountReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [creditsConsumed, setCreditsConsumed] = useState(0);
  const [creditsConsumedReqStatus, setCreditsConsumedReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [computeCost, setComputeCost] = useState(0);
  const [computeCostReqStatus, setComputeCostReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [avgDailyCreditsUsed, setAvgDailyCreditsUsed] = useState(0);
  const [avgDailyCreditsUsedReqStatus, setAvgDailyCreditsUsedReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [avgDailyCost, setAvgDailyCost] = useState(0);
  const [avgDailyCostReqStatus, setAvgDailyCostReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  // Cost Trend
  const [monthlyCost, setMonthlyCost] = useState<MonthlyCostType[]>([]);
  const [monthlyCostReqStatus, setMonthlyCostReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isCostTrendTableView, setIsCostTrendTableView] = useState(false);
  const [costTrendDateRange, setCostTrendDateRange] = useState([
    moment().subtract(11, 'month').format(YEAR_MONTH_WITHOUT_SEPARATOR),
    moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
  ]);

  // Estimated Credit Usage per warehouse per Month
  const [creditUsageByWarehousePerMonth, setCreditUsageByWarehousePerMonth] =
    useState<MonthlyCostByNameType[]>([]);
  const [
    creditUsageByWarehousePerMonthReqStatus,
    setCreditUsageByWarehousePerMonthReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    isCreditUsageByWarehousePerMonthTableView,
    setIsCreditUsageByWarehousePerMonthTableView,
  ] = useState(false);
  const [
    creditUsageByWarehousePerMonthDateRange,
    setCreditUsageByWarehousePerMonthDateRange,
  ] = useState([
    moment().subtract(11, 'month').format(YEAR_MONTH_WITHOUT_SEPARATOR),
    moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
  ]);

  // Top Accounts by Cost
  const [allAccountsCostData, setAllAccountsCostData] = useState<
    CostByNameType[]
  >([]);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [topAccountsByCostDateRange, setTopAccountsByCostDateRange] = useState([
    moment().subtract(11, 'month').format(YEAR_MONTH_WITHOUT_SEPARATOR),
    moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
  ]);
  const [topAccountsByCostRequestStatus, setTopAccountsByCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [isTopAccountsByCostTableView, setIsTopAccountsByCostTableView] =
    useState(false);

  // Top Warehouses by Cost
  const [allWarehousesCostData, setAllWarehousesCostData] = useState<
    CostByNameType[]
  >([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState<string[]>([]);
  const [topWarehousesByCostDateRange, setTopWarehousesByCostDateRange] =
    useState([
      moment().subtract(11, 'month').format(YEAR_MONTH_WITHOUT_SEPARATOR),
      moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
    ]);
  const [
    topWarehousesByCostRequestStatus,
    setTopWarehousesByCostRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [isTopWarehousesByCostTableView, setIsTopWarehousesByCostTableView] =
    useState(false);

  useEffect(() => {
    setAccountsCount(0);
    setWarehouseCount(0);
    setCreditsConsumed(0);
    setComputeCost(0);
    setAvgDailyCreditsUsed(0);
    setAvgDailyCost(0);

    if (selectedDashboard) {
      fetchAndAssignCostCardsData();
    }
  }, [selectedDashboard]);

  useEffect(() => {
    setMonthlyCost([]);

    if (selectedDashboard) {
      fetchCostTrendData();
    }
  }, [selectedDashboard, costTrendDateRange]);

  useEffect(() => {
    setCreditUsageByWarehousePerMonth([]);

    if (selectedDashboard) {
      fetchCreditUsageByWarehousePerMonthData();
    }
  }, [selectedDashboard, creditUsageByWarehousePerMonthDateRange]);

  useEffect(() => {
    setAllAccountsCostData([]);

    if (selectedDashboard) {
      fetchTopAccountsUsageByCostData();
    }
  }, [selectedDashboard, topAccountsByCostDateRange]);

  useEffect(() => {
    setAllWarehousesCostData([]);

    if (selectedDashboard) {
      fetchTopWarehousesUsageByCostData();
    }
  }, [selectedDashboard, topWarehousesByCostDateRange]);

  useEffect(() => {
    setCostComparisonList([
      {
        value: accountsCount,
        heading: t('snowflakeDashboards.numberOfAccounts'),
        requestStatus: [accountsCountReqStatus],
        valueMinimumFractionDigits: 0,
      },
      {
        value: warehouseCount,
        heading: t('snowflakeDashboards.numberOfWarehouses'),
        requestStatus: [warehouseCountReqStatus],
        valueMinimumFractionDigits: 0,
      },
      {
        value: creditsConsumed,
        heading: t('snowflakeDashboards.creditsConsumed'),
        requestStatus: [creditsConsumedReqStatus],
        valuePrefix: currencySymbol,
      },
      {
        value: computeCost,
        heading: t('snowflakeDashboards.computeCost'),
        requestStatus: [computeCostReqStatus],
        valuePrefix: currencySymbol,
      },
      {
        value: avgDailyCreditsUsed,
        heading: t('snowflakeDashboards.avgDailyCreditsUsed'),
        requestStatus: [avgDailyCreditsUsedReqStatus],
        valuePrefix: currencySymbol,
      },
      {
        value: avgDailyCost,
        heading: t('snowflakeDashboards.avgDailyCost'),
        requestStatus: [avgDailyCostReqStatus],
        valuePrefix: currencySymbol,
      },
    ]);
  }, [
    accountsCount,
    accountsCountReqStatus,
    warehouseCount,
    warehouseCountReqStatus,
    creditsConsumed,
    creditsConsumedReqStatus,
    computeCost,
    computeCostReqStatus,
    avgDailyCreditsUsed,
    avgDailyCreditsUsedReqStatus,
    avgDailyCost,
    avgDailyCostReqStatus,
  ]);

  useEffect(() => {
    setIsCostTrendTableView(tableViewEnabled);
    setIsCreditUsageByWarehousePerMonthTableView(tableViewEnabled);
    setIsTopAccountsByCostTableView(tableViewEnabled);
    setIsTopWarehousesByCostTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    if (selectedDashboard) {
      dispatch(
        setExportToExcelData(
          getSnowflakeWarehouseExcelData(
            {
              data: monthlyCost,
              dateRange: costTrendDateRange,
            },
            {
              data: getCostByNamePerMonthTableData(),
              dateRange: creditUsageByWarehousePerMonthDateRange,
            },
            {
              data: getSelectedData(allAccountsCostData, selectedAccounts),
              dateRange: topAccountsByCostDateRange,
              selectedAccounts: selectedAccounts,
            },
            {
              data: getSelectedData(allWarehousesCostData, selectedWarehouses),
              dateRange: topWarehousesByCostDateRange,
              selectedWarehouses: selectedWarehouses,
            }
          )
        )
      );
    }
  }, [
    selectedDashboard,
    monthlyCost,
    costTrendDateRange,
    creditUsageByWarehousePerMonth,
    creditUsageByWarehousePerMonthDateRange,
    allAccountsCostData,
    selectedAccounts,
    topAccountsByCostDateRange,
    allWarehousesCostData,
    selectedWarehouses,
    topWarehousesByCostDateRange,
  ]);

  /**
   * @function fetchAndAssignCostCardsData
   * @description Function to fetch the data for all the metric cards
   */
  const fetchAndAssignCostCardsData = () => {
    fetchCostCardData(
      AccountsCountQuery,
      setAccountsCount,
      setAccountsCountReqStatus
    );
    fetchCostCardData(
      WarehousesCountQuery,
      setWarehouseCount,
      setWarehouseCountReqStatus
    );
    fetchCostCardData(
      CreditsConsumedQuery,
      setCreditsConsumed,
      setCreditsConsumedReqStatus
    );
    fetchCostCardData(
      ComputeCostQuery,
      setComputeCost,
      setComputeCostReqStatus
    );
    fetchCostCardData(
      AvgDailyCreditsUsedQuery,
      setAvgDailyCreditsUsed,
      setAvgDailyCreditsUsedReqStatus
    );
    fetchCostCardData(
      AvgDailyCostQuery,
      setAvgDailyCost,
      setAvgDailyCostReqStatus
    );
  };

  /**
   * @function fetchCostCardData
   * @description Function to fetch the cost card data based on the input query
   * @param query Query for fetching the data
   * @param setData Callback function for setting the data fetched
   * @param setRequestStatus Callback function to set the request status
   */
  const fetchCostCardData = (
    query: Object,
    setData: (val: number) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      ...query,
      snowflakeIntegrationId: selectedDashboard?.integrationId,
    };

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        setData(JSON.parse(res?.data?.responseData)?.[0]?.VALUE ?? 0);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
        setData(0);
      });
  };

  /**
   * @function fetchCostTrendData
   * @description Function to fetch the cost trend data
   */
  const fetchCostTrendData = () => {
    setMonthlyCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getWarehouseCostTrendQuery(
      selectedDashboard?.integrationId ?? '',
      costTrendDateRange
    );

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        const data = JSON.parse(res?.data?.responseData);
        const months = getMonthYearShortList(
          costTrendDateRange[0],
          costTrendDateRange[1]
        );
        const trendsData: MonthlyCostType[] = [];
        months.forEach((month) => {
          trendsData.push({
            month: month,
            cost:
              data.find(
                (item: any) =>
                  moment(item.MONTH, YEAR_MONTH_WITHOUT_SEPARATOR).format(
                    MONTH_YEAR_SHORT
                  ) === month
              )?.COST ?? 0,
          });
        });

        setMonthlyCost(trendsData);
        setMonthlyCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setMonthlyCostReqStatus);
      });
  };

  /**
   * @function fetchCreditUsageByWarehousePerMonthData
   * @description Function to fetch the credit usage per warehouse by month data
   */
  const fetchCreditUsageByWarehousePerMonthData = () => {
    setCreditUsageByWarehousePerMonthReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getCreditsUsedByWarehousePerMonthQuery(
      selectedDashboard?.integrationId ?? '',
      creditUsageByWarehousePerMonthDateRange
    );

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        const data = JSON.parse(res?.data?.responseData);
        const months = getMonthYearShortList(
          creditUsageByWarehousePerMonthDateRange[0],
          creditUsageByWarehousePerMonthDateRange[1],
          MONTH_YEAR_SHORT
        );

        const creditsUsedData: MonthlyCostByNameType[] = [];
        months.forEach((month) => {
          const dataItems = data
            .filter(
              (item: any) =>
                moment(item.MONTH, YEAR_MONTH_WITHOUT_SEPARATOR).format(
                  MONTH_YEAR_SHORT
                ) === month
            )
            .map((item: any) => ({
              month: month,
              name: item.WAREHOUSE,
              cost: item.CREDITSUSED,
            }));

          creditsUsedData.push(...dataItems);
        });

        setCreditUsageByWarehousePerMonth(creditsUsedData);
        setCreditUsageByWarehousePerMonthReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCreditUsageByWarehousePerMonthReqStatus);
      });
  };

  /**
   * @function fetchTopAccountsUsageByCostData
   * @description Function to fetch the top accounts by cost data
   */
  const fetchTopAccountsUsageByCostData = () => {
    setTopAccountsByCostRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getTopAccountsByComputeCostQuery(
      selectedDashboard?.integrationId ?? '',
      topAccountsByCostDateRange
    );

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        const data = JSON.parse(res?.data?.responseData);
        const topAccountsData: CostByNameType[] = data?.map((item: any) => ({
          name: item.ACCOUNTNAME,
          cost: item.USAGE,
        }));

        setAllAccountsCostData(topAccountsData);
        setSelectedAccounts(
          topAccountsData?.slice(0, 10)?.map((item) => item.name)
        );
        setTopAccountsByCostRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTopAccountsByCostRequestStatus);
      });
  };

  /**
   * @function fetchTopWarehousesUsageByCostData
   * @description Function to fetch the top warehouses by cost data
   */
  const fetchTopWarehousesUsageByCostData = () => {
    setTopWarehousesByCostRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getTopWarehousesByComputeCostQuery(
      selectedDashboard?.integrationId ?? '',
      topWarehousesByCostDateRange
    );

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        const data = JSON.parse(res?.data?.responseData);
        const topWarehousesData: CostByNameType[] = data?.map((item: any) => ({
          name: item.WAREHOUSENAME,
          cost: item.USAGE,
        }));

        setAllWarehousesCostData(topWarehousesData);
        setSelectedWarehouses(
          topWarehousesData?.slice(0, 10)?.map((item) => item.name)
        );
        setTopWarehousesByCostRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTopWarehousesByCostRequestStatus);
      });
  };

  /**
   * @function onChangeCostTrendDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostTrendDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostTrendDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
    ]);
  };

  /**
   * @function onChangeCreditUsageByWarehousePerMonthDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCreditUsageByWarehousePerMonthDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCreditUsageByWarehousePerMonthDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
    ]);
  };

  /**
   * @function onChangeTopAccountsByCostDateRange
   * @description Function to update start and end month of the top accounts by cost graph
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeTopAccountsByCostDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setTopAccountsByCostDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
    ]);
  };

  /**
   * @function onChangeTopWarehousesByCostDateRange
   * @description Function to update start and end month of the top warehouses by cost graph
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeTopWarehousesByCostDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setTopWarehousesByCostDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
    ]);
  };

  const getPdfContentData = () => {
    return {
      costCardsData: getCostComparisonCards(),
    };
  };

  const getPdfMetaData = () => {
    return {
      viewName: t('dashNav.warehouse'),
      fileName: selectedDashboard?.name!,
      heading: selectedDashboard?.name!,
      subtitle1: selectedDashboard?.integrationName!,
      provider: IntegrationTypes.SNOWFLAKE,
    };
  };

  /**
   * @function getCostByNamePerMonthTableData
   * @description function to get table data source grouped by name
   * @returns object array of table data source
   */
  const getCostByNamePerMonthTableData = () => {
    const groupedByName: any[] = [];
    creditUsageByWarehousePerMonth.forEach((item) => {
      const existingName = groupedByName.find((obj) => obj.name === item.name);
      if (existingName) {
        existingName[`${item.month}-cost`] = numberCommaSeparator(item.cost);
      } else {
        groupedByName.push({
          name: item.name,
          [`${item.month}-cost`]: numberCommaSeparator(item.cost),
        });
      }
    });
    return groupedByName;
  };

  const getCostComparisonCards = () =>
    costComparisonList.map((item, index) => {
      return (
        <ComparisonCard
          key={item.heading}
          index={index}
          value={item.value}
          valueMinimumFractionDigits={item.valueMinimumFractionDigits}
          valueMaximumFractionDigits={item.valueMaximumFractionDigits}
          heading={item.heading}
          valuePrefix={item.valuePrefix}
          comparisonValue={item.comparisonValue}
          comparisonFrom={item.comparisonFrom}
          requestStatus={item.requestStatus}
          errorMessage={item.errorMessage}
        />
      );
    });

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'cost-trend':
        return (
          <CostTrend
            monthlyCost={monthlyCost}
            costTrendRequestStatus={monthlyCostReqStatus}
            isCostTrendTableView={isCostTrendTableView}
            setIsCostTrendTableView={setIsCostTrendTableView}
            pdfView={pdfView}
            costTrendDateRange={costTrendDateRange}
            onChangeCostTrendDateRange={onChangeCostTrendDateRange}
          />
        );
      case 'credit-usage-by-warehouse-per-month':
        return (
          <CostByNamePerMonth
            graphHeading={t('graphHeadings.creditUsageByWarehousePerMonth')}
            graphName="credit-usage-by-warehouse-per-month"
            costByNamePerMonthTableData={getCostByNamePerMonthTableData()}
            costByNamePerMonth={creditUsageByWarehousePerMonth}
            costByNamePerMonthRequestStatus={
              creditUsageByWarehousePerMonthReqStatus
            }
            isCostByNamePerMonthTableView={
              isCreditUsageByWarehousePerMonthTableView
            }
            setIsCostByNamePerMonthTableView={
              setIsCreditUsageByWarehousePerMonthTableView
            }
            costByNamePerMonthDateRange={
              creditUsageByWarehousePerMonthDateRange
            }
            onChangeCostByNamePerMonthDateRange={
              onChangeCreditUsageByWarehousePerMonthDateRange
            }
            pdfView={pdfView}
          />
        );

      case 'top-accounts-by-cost':
        return (
          <TopAccountsByCost
            allAccountsCostData={allAccountsCostData}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            topAccountsByCostRequestStatus={topAccountsByCostRequestStatus}
            isTopAccountsByCostTableView={isTopAccountsByCostTableView}
            setIsTopAccountsByCostTableView={setIsTopAccountsByCostTableView}
            topAccountsByCostDateRange={topAccountsByCostDateRange}
            onChangeTopAccountsByCostDateRange={
              onChangeTopAccountsByCostDateRange
            }
            pdfView={pdfView}
          />
        );

      case 'top-warehouses-by-cost':
        return (
          <TopWarehousesByCost
            allWarehousesCostData={allWarehousesCostData}
            selectedWarehouses={selectedWarehouses}
            setSelectedWarehouses={setSelectedWarehouses}
            topWarehousesByCostRequestStatus={topWarehousesByCostRequestStatus}
            isTopWarehousesByCostTableView={isTopWarehousesByCostTableView}
            setIsTopWarehousesByCostTableView={
              setIsTopWarehousesByCostTableView
            }
            topWarehousesByCostDateRange={topWarehousesByCostDateRange}
            onChangeTopWarehousesByCostDateRange={
              onChangeTopWarehousesByCostDateRange
            }
            pdfView={pdfView}
          />
        );
    }
  };

  return (
    <div className="snowflake-warehouse-dashboard flex flex-column flex-gap-24">
      <div className="cost-cards flex flex-space-between">
        {getCostComparisonCards()}
      </div>
      {getGraphComponent('cost-trend')}
      {getGraphComponent('credit-usage-by-warehouse-per-month')}
      <Row gutter={24}>
        <Col span={12}>{getGraphComponent('top-accounts-by-cost')}</Col>
        <Col span={12}>{getGraphComponent('top-warehouses-by-cost')}</Col>
      </Row>
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData()}
          pdfContent={[
            {
              element: getGraphComponent('cost-trend', true),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: 'cost-trend',
              column: CostTrendExportColumns,
              body: monthlyCost.map((item, index) => ({
                ...item,
                cost: numberCommaSeparator(item.cost),
                slNo: index,
              })),
              tableName: t('graphHeadings.costTrend'),
              isTableView: isCostTrendTableView,
            },
            {
              element: getGraphComponent(
                'credit-usage-by-warehouse-per-month',
                true
              ),
              contentType: CHART_TYPES.STACK_CHART,
              graphName: 'credit-usage-by-warehouse-per-month',
              column: getCostByNamePerMonthExportColumns(
                t('graphHeadings.creditUsageByWarehousePerMonth'),
                creditUsageByWarehousePerMonthDateRange[0],
                creditUsageByWarehousePerMonthDateRange[1]
              ),
              body: getCostByNamePerMonthTableData().map((item, index) => ({
                ...item,
                slNo: index,
              })),
              tableName: t('graphHeadings.creditUsageByWarehousePerMonth'),
              isTableView: isCreditUsageByWarehousePerMonthTableView,
            },
            {
              element: getGraphComponent('top-accounts-by-cost', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'top-accounts-by-cost',
              column: TopAccountsByCostExportColumns,
              body: getSelectedData(allAccountsCostData, selectedAccounts)?.map(
                (item, index) => ({
                  ...item,
                  cost: numberCommaSeparator(item.cost),
                  slNo: index,
                })
              ),
              tableName: t('graphHeadings.topAccountsByComputeCost'),
              isTableView: isTopAccountsByCostTableView,
            },
            {
              element: getGraphComponent('top-warehouses-by-cost', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'top-warehouses-by-cost',
              column: TopWarehousesByCostExportColumns,
              body: getSelectedData(
                allWarehousesCostData,
                selectedWarehouses
              )?.map((item, index) => ({
                ...item,
                cost: numberCommaSeparator(item.cost),
                slNo: index,
              })),
              tableName: t('graphHeadings.topWarehousesByComputeCost'),
              isTableView: isTopWarehousesByCostTableView,
            },
          ]}
          pdfCardData={getPdfContentData()}
        />
      )}
    </div>
  );
};

export default SnowflakeWarehouseDashboard;
