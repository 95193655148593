import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PROVIDER } from 'constants/cloudProviders';
import { Radio, Space } from 'antd';
import { FormLabel } from 'components/FormLabel';
import CheckRadio from 'components/CheckRadio';
import SelectDropDown from 'components/Select';
import Input from 'components/Input';
import { GProfilerConnectionRadioValue } from 'constants/gProfiler';
import ConnectionFormHeader from '../components/ConnectionFormHeader';
import ConnectionFormCard from '../components/Card';

const AzureConnectionForm = () => {
  const { t } = useTranslation();

  const [checkValue, setCheckValue] = useState<string>(
    GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION
  );
  return (
    <ConnectionFormCard>
      <ConnectionFormHeader provider={PROVIDER.AZURE} />
      <section className="connection-form-section flex-fit full-width flex flex-column flex-gap-16">
        <div className="form-item flex flex-column">
          <FormLabel
            title={t(
              'gProfilerConnectionPage.connectionForm.setupConnectionFrom'
            )}
            required={true}
          />
          <Radio.Group
            onChange={(e: any) => {
              setCheckValue(e.target.value);
            }}
            value={checkValue}
          >
            <Space direction="vertical">
              <CheckRadio
                value={GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION}
                className="font-caption"
                checked={true}
              >
                {t(
                  'gProfilerConnectionPage.connectionForm.existingCloudConnection'
                )}
              </CheckRadio>
              <CheckRadio
                value={GProfilerConnectionRadioValue.NEW_CLOUD_CONNECTION}
                className="font-caption"
              >
                {t('gProfilerConnectionPage.connectionForm.newCloudConnectoin')}
              </CheckRadio>
            </Space>
          </Radio.Group>
        </div>
        {checkValue ===
        GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION ? (
          <div className="form-item flex flex-column">
            <FormLabel
              title={t(
                'gProfilerConnectionPage.connectionForm.cloudConnection'
              )}
              required={true}
            />
            <SelectDropDown
              placeholder={t(
                'gProfilerConnectionPage.connectionForm.selectCloudConnection'
              )}
              className="select-item"
              designVersion2
            />
          </div>
        ) : (
          <>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t(
                  'gProfilerConnectionPage.connectionForm.connectionName'
                )}
                required={true}
              />
              <Input className="select-item" />
            </div>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t('gProfilerConnectionPage.connectionForm.tenantId')}
                required={true}
              />
              <Input className="select-item" />
            </div>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t(
                  'gProfilerConnectionPage.connectionForm.subscriptionId'
                )}
                required={true}
              />
              <Input className="select-item" />
            </div>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t('gProfilerConnectionPage.connectionForm.appId')}
                required={true}
              />
              <Input className="select-item" />
            </div>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t('gProfilerConnectionPage.connectionForm.clientSecret')}
                required={true}
              />
              <Input className="select-item" type="password" />
            </div>
          </>
        )}
        <div className="form-item flex flex-column">
          <FormLabel
            title={t(
              'gProfilerConnectionPage.connectionForm.chooseAServiceName'
            )}
            required={true}
          />
          <SelectDropDown
            placeholder={t(
              'gProfilerConnectionPage.connectionForm.serviceName'
            )}
            className="select-item"
            designVersion2
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('gProfilerConnectionPage.connectionForm.regionAndCluster')}
            required={true}
          />
          <SelectDropDown
            placeholder={t(
              'gProfilerConnectionPage.connectionForm.regionAndCluster'
            )}
            className="select-item"
            designVersion2
          />
        </div>
      </section>
    </ConnectionFormCard>
  );
};

export default AzureConnectionForm;
