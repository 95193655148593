import { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  importStaticFiles,
  setImportFileData,
} from 'redux/importStaticFileSlice';
import { DeleteIconButton } from 'assets/icons';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown, { DROPDOWN_NONE } from 'components/Select';
import { SchemaDefinitionType } from 'pages/StaticFilesPage/types';
import { getValidationStyle } from 'utils/validations';
import { getSchemaDefaultFields } from '../SchemaDetails/utils';

import {
  getStandardSchemaFieldsDropdownOptions,
  validateFieldName,
} from './utils';
import { SCHEMA_MODES, SCHEMA_TYPES } from './constants';

const { Option } = Select;

type SchemaRowProps = {
  position: number;
  schema: SchemaDefinitionType;
  setIsValidSchema: (val: boolean) => void;
  onUpdateSchemaDefinition: () => void;
  standardSchema: SchemaDefinitionType[];
};

const SchemaRow = ({
  schema,
  position,
  setIsValidSchema,
  onUpdateSchemaDefinition,
  standardSchema,
}: SchemaRowProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { importFileData } = useSelector(importStaticFiles);

  const [fieldName, setFieldName] = useState(schema.name);
  const [fieldNameValidation, setFieldNameValidation] = useState('');

  useEffect(() => {
    validateFieldName(
      schema.name,
      position,
      importFileData.schemaDefinition,
      setFieldNameValidation
    );
  }, [schema.name, importFileData.schemaDefinition]);

  const FieldTypeMenu = (
    <>
      {SCHEMA_TYPES.map((type) => (
        <Option key={type} value={type}>
          {type}
        </Option>
      ))}
    </>
  );

  const FieldModeMenu = (
    <>
      {SCHEMA_MODES.map((mode) => (
        <Option key={mode} value={mode}>
          {mode}
        </Option>
      ))}
    </>
  );

  /**
   * @function validateField
   * @description Function to validate the field name and set the validation message
   * @param value string value typed
   */
  const validateField = (value: string) => {
    validateFieldName(
      value,
      position,
      importFileData.schemaDefinition,
      setFieldNameValidation
    );
  };

  /**
   * @function onBlurField
   * @description callback for onBlur event of input field for field
   * @param value string value typed
   */
  const onBlurField = (value: string) => {
    setIsValidSchema(false);
    onUpdateSchemaDefinition();
    let schemas = [...importFileData.schemaDefinition];
    schemas.splice(position, 1, {
      ...schemas.at(position)!,
      name: value,
    });
    dispatch(
      setImportFileData({ ...importFileData, schemaDefinition: schemas })
    );
  };

  /**
   * @function onSelectType
   * @description callback function for onSelect event of the type dropdown
   * @param value string value selected
   */
  const onSelectType = (value: string) => {
    setIsValidSchema(false);
    onUpdateSchemaDefinition();
    let schemas = [...importFileData.schemaDefinition];
    schemas.splice(position, 1, {
      ...schemas.at(position)!,
      type: value,
    });
    dispatch(
      setImportFileData({ ...importFileData, schemaDefinition: schemas })
    );
  };

  /**
   * @function onSelectMode
   * @description callback function for onSelect event of the mode dropdown
   * @param value string value selected
   */
  const onSelectMode = (value: string) => {
    setIsValidSchema(false);
    onUpdateSchemaDefinition();
    let schemas = [...importFileData.schemaDefinition];
    schemas.splice(position, 1, {
      ...schemas.at(position)!,
      mode: value,
    });
    dispatch(
      setImportFileData({ ...importFileData, schemaDefinition: schemas })
    );
  };

  /**
   * @function onSelectStandardField
   * @description callback function for onSelect event of the standard schema field dropdown
   * @param field string value selected
   */
  const onSelectStandardField = (field: string) => {
    setIsValidSchema(false);
    onUpdateSchemaDefinition();
    let updatedSchema = { ...schema };
    if (field === DROPDOWN_NONE) {
      updatedSchema = { ...updatedSchema, mappedFieldName: undefined };
    } else {
      const schemaDetails = standardSchema.find((item) => item.name === field);
      updatedSchema = {
        ...updatedSchema,
        mappedFieldName: schemaDetails!.name,
        mode: schemaDetails!.mode,
        type: schemaDetails!.type,
      };
    }

    let schemas = [...importFileData.schemaDefinition];
    schemas.splice(position, 1, updatedSchema);
    dispatch(
      setImportFileData({ ...importFileData, schemaDefinition: schemas })
    );
  };

  /**
   * @function onDeleteField
   * @description Function to delete a row in schema definition
   */
  const onDeleteField = () => {
    setIsValidSchema(false);
    onUpdateSchemaDefinition();
    let schemas = [...importFileData.schemaDefinition];
    const replacementSchema =
      importFileData.schemaDefinition.length === 1
        ? [getSchemaDefaultFields()]
        : [];
    schemas.splice(position, 1, ...replacementSchema);
    dispatch(
      setImportFileData({ ...importFileData, schemaDefinition: schemas })
    );
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={importFileData.wantSpendDiagnosticsDashboard ? 7 : 10}>
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('importFileLabels.formLabels.step3.field')}
              required={true}
            />
            <Input
              value={fieldName}
              placeholder={t(
                'importFileLabels.formLabels.step3.fieldPlaceholder'
              )}
              onChange={(e: any) => {
                setFieldName(e.target.value);
                validateField(e.target.value);
              }}
              onBlur={() => onBlurField(fieldName)}
            />
          </div>
        </Col>
        {importFileData.wantSpendDiagnosticsDashboard && (
          <Col span={7}>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t(
                  'importFileLabels.formLabels.step3.standardSchemaField'
                )}
                required={false}
              />
              <SelectDropdown
                value={schema.mappedFieldName ?? DROPDOWN_NONE}
                defaultValue={DROPDOWN_NONE}
                options={getStandardSchemaFieldsDropdownOptions(
                  standardSchema,
                  importFileData
                )}
                onSelect={onSelectStandardField}
                designVersion2
                showSearch
              />
            </div>
          </Col>
        )}
        <Col span={importFileData.wantSpendDiagnosticsDashboard ? 4 : 6}>
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('importFileLabels.formLabels.step3.type')}
              required={true}
              disabled={
                importFileData.wantSpendDiagnosticsDashboard &&
                !!schema.mappedFieldName
              }
            />
            <SelectDropdown
              value={schema.type}
              menu={FieldTypeMenu}
              onSelect={onSelectType}
              designVersion2
              disabled={
                importFileData.wantSpendDiagnosticsDashboard &&
                !!schema.mappedFieldName
              }
            />
          </div>
        </Col>
        <Col span={importFileData.wantSpendDiagnosticsDashboard ? 4 : 6}>
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('importFileLabels.formLabels.step3.mode')}
              required={true}
              disabled={
                importFileData.wantSpendDiagnosticsDashboard &&
                !!schema.mappedFieldName
              }
            />
            <SelectDropdown
              value={schema.mode}
              menu={FieldModeMenu}
              onSelect={onSelectMode}
              designVersion2
              disabled={
                importFileData.wantSpendDiagnosticsDashboard &&
                !!schema.mappedFieldName
              }
            />
          </div>
        </Col>
        <Col span={2} className="flex flex-align-self-end">
          <DeleteIconButton
            height={38}
            className="cursor-pointer"
            onClick={onDeleteField}
            data-testid="delete-cta"
          />
        </Col>
      </Row>
      <span
        style={{
          display: getValidationStyle(fieldNameValidation),
        }}
        className="font-validation-error"
      >
        {fieldNameValidation}
      </span>
    </div>
  );
};

export default SchemaRow;
