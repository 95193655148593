import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import { ICONS } from 'constants/icons';
import { PROVIDER } from 'constants/cloudProviders';
import { BUTTON_TYPE } from 'constants/appearance';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import { providerList } from 'redux/providerSlice';

import { GCP_TRIGGER_AT } from '../GCP/SetupBudgetsDetails/constants';
import { AWS_TRIGGER_AT } from '../AWS/SetupBudgetDetails/constants';
import { AZURE_TRIGGER_AT } from '../Azure/SetupBudgetDetails/constants';
import { OCI_TRIGGER_AT } from '../OCI/SetupBudgetDetails/constants';
import BudgetRow from './BudgetRow';

const ActionsForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { budgetData, isEdit } = useSelector(budgetsAndAlerts);
  const { selectedProvider } = useSelector(providerList);

  useEffect(() => {
    if (isEdit || budgetData.thresholdRules.length) return;
    onClickAddThreshold();
  }, []);

  const getTriggerAtOptions = () => {
    switch (selectedProvider) {
      case PROVIDER.GCP:
        return GCP_TRIGGER_AT.filter((option) =>
          option.forDateRange.includes(budgetData.timeRange)
        );
      case PROVIDER.AWS:
        return AWS_TRIGGER_AT;
      case PROVIDER.AZURE:
        return AZURE_TRIGGER_AT;
      case PROVIDER.OCI:
        return OCI_TRIGGER_AT;
      default:
        return [];
    }
  };

  /**
   * @function onClickAddThreshold
   * @description Function to add a new threshold field.
   */
  const onClickAddThreshold = () => {
    dispatch(
      setBudgetData({
        ...budgetData,
        thresholdRules: [
          ...budgetData.thresholdRules,
          {
            percentage: -1,
            amount: -1,
            triggerOn: getTriggerAtOptions().at(0)?.value ?? '',
          },
        ],
      })
    );
  };

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="budgets-rows flex flex-column flex-gap-16">
        {budgetData.thresholdRules
          .map((item, index) => ({ ...item, key: index }))
          .map((budget, index) => (
            <BudgetRow
              key={budget.key}
              triggerAtOptions={getTriggerAtOptions()}
              index={index}
              budget={budget}
            />
          ))}
      </div>
      <Button
        title={t('addBudgetAlert.createBudgetLabels.actions.addThreshold')}
        iconName={ICONS.ADD_LINE}
        type={BUTTON_TYPE.NEUTRAL}
        onClick={onClickAddThreshold}
      />
    </div>
  );
};

export default ActionsForm;
