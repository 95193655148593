import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import { ICONS } from 'constants/icons';
import { BUTTON_TYPE } from 'constants/appearance';
import { setResetStepForm } from 'redux/cloudConnectionSlice';
import { REQUEST_STATUS } from 'constants/requestBody';

import './index.scss';

type FormFooterProps = {
  nextButtonTitle?: string;
  showResetButton?: boolean;
  showPreviousButton?: boolean;
  onClickNext: () => void;
  isNextDisabled?: boolean;
  onClickPrevious?: () => void;
  onClickCancel: () => void;
  submitRequestStatus: string;
};

const FormFooter = ({
  nextButtonTitle,
  showResetButton = false,
  showPreviousButton = true,
  onClickNext,
  isNextDisabled = false,
  onClickPrevious,
  onClickCancel,
  submitRequestStatus,
}: FormFooterProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <footer className="provider-footer flex flex-space-between flex-align-items-center">
      <div className="flex">
        <Button
          onClick={() => onClickCancel()}
          title={t('connectionCSPForm.cancel')}
          type={BUTTON_TYPE.LINK}
        />
        {showResetButton && (
          <>
            <div className="vertical-rule"></div>
            <Button
              onClick={() => dispatch(setResetStepForm(true))}
              title={t('connectionCSPForm.reset')}
              type={BUTTON_TYPE.LINK}
            />
          </>
        )}
      </div>
      <div className="flex flex-gap-10">
        {showPreviousButton && (
          <Button
            onClick={onClickPrevious}
            title={t('connectionCSPForm.previousStep')}
            iconName={ICONS.ARROW_LEFT}
            type={BUTTON_TYPE.OUTLINED}
            reverseIconTitleOrder
          />
        )}
        <Button
          onClick={onClickNext}
          title={nextButtonTitle ?? t('connectionCSPForm.nextStep')}
          disabled={isNextDisabled}
          iconName={ICONS.ARROW_RIGHT}
          loading={submitRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      </div>
    </footer>
  );
};

export default FormFooter;
