import i18n from 'i18n';
import { CostByCategory } from 'pages/TcoDashboardPage/types';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { store } from 'redux/store';
import { modifyToExportColumns } from 'utils/exportToExcel';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

/**
 * @function getTcoColumns
 * @description Function to filter columns based on the selected dashboard type
 * @param isOnPremise boolean value to check if the selected dashboard type is on premise
 * @returns list of columns
 */
export const getTcoColumns = (isOnPremise: boolean) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: i18n.t('tcoDashboard.totalCost.category'),
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: i18n.t('tcoDashboard.totalCost.onPremise'),
      dataIndex: 'onPremise',
      key: 'onPremise',
      render: (text: any) =>
        `${currencySymbol}${numberCommaSeparator(text ?? 0)}`,
      align: 'right',
      ellipsis: true,
    },
    {
      title: i18n.t('tcoDashboard.totalCost.cloud'),
      dataIndex: 'cloud',
      key: 'cloud',
      render: (text: any) =>
        `${currencySymbol}${numberCommaSeparator(text ?? 0)}`,
      align: 'right',
      ellipsis: true,
    },
    {
      title: i18n.t('tcoDashboard.totalCost.tco'),
      dataIndex: 'tco',
      key: 'tco',
      render: (text: any) =>
        `${currencySymbol}${numberCommaSeparator(text ?? 0)}`,
      align: 'right',
      ellipsis: true,
    },
  ];

  if (isOnPremise) {
    return columns.filter(
      (column) => !['tco', 'cloud'].includes(column.dataIndex)
    );
  }
  return columns;
};

export const getTcoExcelData = (
  costData: CostByCategory[],
  isOnPremise: boolean,
  filters: any[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.totalCost'),
    columns: modifyToExportColumns(getTcoColumns(isOnPremise)),
    data: insertIndex(costData).map((item) => ({
      ...item,
      onPremise: numberCommaSeparator(item.onPremise ?? 0),
      tco: numberCommaSeparator(item.tco ?? 0),
    })),
    filters: filters,
  };
};

export const getTcoCostSummaryExcelData = (
  costData: CostByCategory[],
  isOnPremise: boolean,
  filters: any[]
) => {
  return [getTcoExcelData(costData, isOnPremise, filters)];
};
