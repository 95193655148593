import {
  AwsGroup,
  AWSLogo,
  AWSMediumIcon,
  AzureGroup,
  AzureLogo,
  AzureMediumIcon,
  GcpGroup,
  GcpIcon,
  GCPLogo,
  GroupIcon,
  JiraIcon,
  OciLogo,
  OCIMediumIcon,
  ServiceNowIcon,
  SnowflakeIcon,
} from 'assets/icons';
import gcpIconPng from 'assets/icons/gcpIconPng.png';
import awsIconPng from 'assets/icons/awsIconPng.png';
import azureIconPng from 'assets/icons/azureIconPng.png';
import ociIconPng from 'assets/icons/ociIconPng.png';
import groupIconPng from 'assets/icons/groupIconPng.png';
import gcpGroupPng from 'assets/icons/gcpGroupPng.png';
import awsGroupPng from 'assets/icons/awsGroupPng.png';
import azureGroupPng from 'assets/icons/azureGroupPng.png';
import snowflakeIconPng from 'assets/icons/snowflakeIconPng.png';
import { PROVIDER, TICKET_PROVIDER } from 'constants/cloudProviders';
import { GroupType } from 'pages/GroupsPage/types';
import { IntegrationTypes } from 'pages/IntegrationsPage/constants';
import { getProviderForConnection } from './dashboardUtils';

export const GROUP_TYPE = 'GROUP';

/**
 * @function getProviderLogo
 * @description Function to get cloud provider logo by name.
 * @params provider : name of the provider
 * @returns cloud provider logo as React component
 */
export const getProviderLogo = (provider: string) => {
  switch (provider) {
    case PROVIDER.AWS:
      return AWSMediumIcon;
    case PROVIDER.GCP:
      return GcpIcon;
    case PROVIDER.AZURE:
      return AzureMediumIcon;
    case PROVIDER.OCI:
      return OCIMediumIcon;
    case TICKET_PROVIDER.JIRA:
      return JiraIcon;
    case TICKET_PROVIDER.SERVICE_NOW:
      return ServiceNowIcon;
    case IntegrationTypes.SNOWFLAKE:
      return SnowflakeIcon;
    case GROUP_TYPE:
      return GroupIcon;
  }
};

/**
 * @function getProviderSmallLogo
 * @description Function to get small cloud provider logo by name.
 * @params provider : name of the provider
 * @returns cloud provider logo as React component
 */
export const getProviderSmallLogo = (provider: string) => {
  switch (provider) {
    case PROVIDER.AWS:
      return AWSLogo;
    case PROVIDER.GCP:
      return GCPLogo;
    case PROVIDER.AZURE:
      return AzureLogo;
    case PROVIDER.OCI:
      return OciLogo;
    case IntegrationTypes.SNOWFLAKE:
      return SnowflakeIcon;
  }
};

/**
 * @function getProviderPngLogo
 * @description Function to get logo of provider but as a png
 * @params provider : name of the provider
 * @returns Imported PNG of the provider
 */
export const getProviderPngLogo = (provider: string) => {
  switch (provider) {
    case PROVIDER.AWS:
      return awsIconPng;
    case PROVIDER.GCP:
      return gcpIconPng;
    case PROVIDER.AZURE:
      return azureIconPng;
    case PROVIDER.OCI:
      return ociIconPng;
    case GROUP_TYPE:
      return groupIconPng;
    case IntegrationTypes.SNOWFLAKE:
      return snowflakeIconPng;
  }
};

/**
 * @function getGroupProviderPngLogo
 * @description Function to get group cloud provider logo by name but as a png
 * @param provider name of the provider
 * @returns cloud provider logo as React component
 */
export const getGroupProviderPngLogo = (provider: string) => {
  switch (provider) {
    case PROVIDER.AWS:
      return awsGroupPng;
    case PROVIDER.GCP:
      return gcpGroupPng;
    case PROVIDER.AZURE:
      return azureGroupPng;
    default:
      return groupIconPng;
  }
};

/**
 * @function getGroupProviderLogo
 * @description Function to get group cloud provider logo by name.
 * @param provider name of the provider
 * @returns cloud provider logo as React component
 */
export const getGroupProviderLogo = (provider: string) => {
  switch (provider) {
    case PROVIDER.AWS:
      return AwsGroup;
    case PROVIDER.GCP:
      return GcpGroup;
    case PROVIDER.AZURE:
      return AzureGroup;
    default:
      return GroupIcon;
  }
};

/**
 * @function getGroupProvider
 * @description Function to get group cloud provider based on the connections provider.
 * @param group group metadata
 * @returns cloud provider
 */
export const getGroupProvider = (group: GroupType | null) => {
  const providers: string[] =
    group?.connectorDtos?.map((connection) =>
      getProviderForConnection(connection)
    ) ?? [];

  if (providers?.every((provider) => provider === providers[0])) {
    return providers[0];
  }

  return GROUP_TYPE;
};
