import i18n from 'i18n';
import { getDateLabelsByGranularity } from 'pages/ScorecardPage/utils';
import { ConnectionListType } from 'types/dashboard';
import {
  SavingsCostItemsType,
  SavingsEffectivenessType,
} from 'types/dataTypes';
import {
  getAlphabetByIndex,
  insertIndex,
  numberCommaSeparator,
} from 'utils/dataFormatterUtils';
import { PROVIDER } from 'constants/cloudProviders';
import {
  AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS,
  AWS_SAVINGS_EFFECTIVENESS_ITEMS,
  AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS,
  GCP_SAVINGS_EFFECTIVENESS_ITEMS,
  GCP_SAVINGS_EFFECTIVENESS_SUB_ITEMS,
} from './constants';

/**
 * @function getSavingsEffectivenessConsolidatedData
 * @description Function to consolidate the cost by category
 * @param provider selected connection service provider
 * @param savingsEffectivenessData the data used for table
 * @returns list of category wise cost details
 */
export const getSavingsEffectivenessConsolidatedData = (
  provider: string | undefined,
  savingsEffectivenessData: SavingsEffectivenessType
) => {
  let data: SavingsCostItemsType[] = [];

  data = savingsEffectivenessData.savingsCostItems.filter(
    (item) =>
      Object.values(getCategoryEnum(provider)).some(
        (value) => value === item.category
      ) && item.subItem === undefined
  );

  return data;
};

/**
 * @function getTableData
 * @description Function to return the table data for the categories
 * @param provider selected connection service provider
 * @param data source data used for the data
 * @param timeRangeLabels list of date labels
 * @param formatNumber boolean for formatting the number or cost field
 * @returns list of category wise cost details
 */
export const getTableData = (
  provider: string | undefined,
  data: any[],
  timeRangeLabels: string[],
  formatNumber: boolean = false
) => {
  const tableData: any[] = [];

  Object.values(getCategoryEnum(provider)).forEach((category) => {
    const thisRow: any = {};
    const categoryItems = data.filter((item) => item.category === category);
    timeRangeLabels.forEach((label, index) => {
      thisRow[label] = formatNumber
        ? numberCommaSeparator(categoryItems[index]?.cost)
        : categoryItems[index]?.cost;
    });
    thisRow.item = category;
    tableData.push(thisRow);
  });

  return tableData;
};

/**
 * @function getCategoryEnum
 * @description Function to return the categories enum by provider
 * @param provider selected connection service provider
 * @returns Enum containing the categories
 */
export const getCategoryEnum = (provider: string | undefined) => {
  return provider === PROVIDER.GCP
    ? GCP_SAVINGS_EFFECTIVENESS_ITEMS
    : AWS_SAVINGS_EFFECTIVENESS_ITEMS;
};

/**
 * @function getSubItemEnum
 * @description Function to return the enums of expanded rows of savings effectiveness
 * @param provider selected connection service provider
 * @param category category of the expanded row
 * @returns Enum containing the sub items
 */
export const getSubItemEnum = (
  provider: string | undefined,
  category: string
) => {
  if (provider === PROVIDER.GCP) {
    if (
      category !==
      GCP_SAVINGS_EFFECTIVENESS_ITEMS.COST_TO_ACHIEVE_SAVINGS.valueOf()
    ) {
      return GCP_SAVINGS_EFFECTIVENESS_SUB_ITEMS;
    }
  }

  if (provider === PROVIDER.AWS) {
    switch (category) {
      case AWS_SAVINGS_EFFECTIVENESS_ITEMS.ON_DEMAND_SPEND.valueOf():
        return AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS;

      case AWS_SAVINGS_EFFECTIVENESS_ITEMS.COMPUTE_RI_SP_SAVINGS.valueOf():
        return AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS;
    }
  }

  return {};
};

/**
 * @function getTableExpandedItemsData
 * @description Function to return the table data for the expanded rows
 * @param provider selected connection service provider
 * @param data source data used for the data
 * @param timeRangeLabels list of date labels
 * @param category Category of the expanded row
 * @param formatNumber boolean for formatting the number or cost field
 * @returns list of sub item cost details
 */
export const getTableExpandedItemsData = (
  provider: string | undefined,
  data: any[],
  timeRangeLabels: string[],
  category: string,
  formatNumber: boolean = false
) => {
  const tableData: any[] = [];
  Object.values(getSubItemEnum(provider, category)).forEach((subItem) => {
    const thisRow: any = {};
    const serviceItems = data.filter(
      (item) => item.category === category && item.subItem === subItem
    );

    timeRangeLabels.forEach((label, index) => {
      const cost = serviceItems[index] ? serviceItems[index].cost : 0;
      thisRow[label] = formatNumber ? numberCommaSeparator(cost) : cost;
    });
    thisRow.item = subItem;
    tableData.push(thisRow);
  });
  return tableData;
};

/**
 * @function getSavingsEffectivenessExportColumns
 * @param selectedTimeRange - selected time range of savings effectiveness chart
 * @param savingsEffectivenessDateRange Date range selected for savings effectiveness
 * @returns columns for savings effectiveness export for both pdf and excel
 */
export const getSavingsEffectivenessExportColumns = (
  selectedTimeRange: string,
  savingsEffectivenessDateRange: string[]
) => {
  const columns: any[] = [
    {
      header: i18n.t('scorecard.savingsEffectiveness.items'),
      alignment: 'left',
      dataKey: 'item',
      key: 'item',
    },
  ];
  const timeRangeLabels = getDateLabelsByGranularity(
    selectedTimeRange,
    savingsEffectivenessDateRange
  );
  timeRangeLabels.forEach((label) => {
    columns.push({
      header: label,
      dataKey: label,
      alignment: 'center',
      key: label,
    });
  });
  return columns;
};

/**
 * @function addRowItemSuffix
 * @param data - savings effectiveness data for export
 * @param provider selected connection service provider
 * @returns List of indexed data
 */
const addRowItemSuffix = (data: any[], provider: string | undefined) => {
  let startIndex = 1;
  let endIndex = 0;

  data = data.map((dataItem) => {
    let replaceItem = '';
    if (
      !dataItem.isSubItem &&
      Object.keys(getSubItemEnum(provider, dataItem.item)).length > 0
    ) {
      endIndex =
        endIndex + Object.keys(getSubItemEnum(provider, dataItem.item)).length;
      replaceItem =
        dataItem.item +
        '(' +
        getAlphabetByIndex(startIndex) +
        '...' +
        getAlphabetByIndex(endIndex) +
        ')';
      return { ...dataItem, item: replaceItem };
    }

    if (dataItem.isSubItem) {
      replaceItem = dataItem.item + '(' + getAlphabetByIndex(startIndex) + ')';
      startIndex = startIndex + 1;
      return { ...dataItem, item: replaceItem };
    }

    return dataItem;
  });
  return data;
};

/**
 * @function getSavingsEffectivenessExportData
 * @param provider selected connection service provider
 * @param savingsEffectivenessData - all savings effectiveness items/rows
 * @param selectedTimeRange - selected time range of savings effectiveness chart
 * @param savingsEffectivenessDateRange Date range selected for savings effectiveness
 * @param formatNumber Boolean to indicate whether to format the number field or the cost field
 * @returns array of object containing item name and cost by label
 * eg. Array of {item:"Compute RI & SP Savings", "Sep 22":"0.1", "Oct 22":"0.2" ...}
 */
export const getSavingsEffectivenessExportData = (
  provider: string | undefined,
  savingsEffectivenessData: SavingsEffectivenessType,
  selectedTimeRange: string,
  savingsEffectivenessDateRange: string[],
  formatNumber: boolean = false
) => {
  let data: any[] = [];
  const timeRangeLabels = getDateLabelsByGranularity(
    selectedTimeRange,
    savingsEffectivenessDateRange
  );

  const consolidatedData = getTableData(
    provider,
    getSavingsEffectivenessConsolidatedData(provider, savingsEffectivenessData),
    timeRangeLabels,
    formatNumber
  );

  Object.values(getCategoryEnum(provider)).forEach((category) => {
    const categoryConsolidatedData = consolidatedData.find(
      (dataItem) => dataItem.item === category
    );
    data.push(categoryConsolidatedData);
    const expandedRowData = getTableExpandedItemsData(
      provider,
      savingsEffectivenessData.savingsCostItems,
      timeRangeLabels,
      category,
      formatNumber
    );
    Object.values(getSubItemEnum(provider, category))?.forEach((subItem) => {
      data.push({
        ...expandedRowData.find((dataItem) => dataItem.item === subItem),
        isSubItem: true,
      });
    });
  });

  data = addRowItemSuffix(data, provider);
  return data;
};

/**
 * @function getSavingsEffectivenessExcelData
 * @param savingsEffectivenessData savings effectiveness data for export
 * @param selectedTimeRange - selected time range of savings effectiveness chart
 * @param savingsEffectivenessDateRange - date range of savings effectiveness chart
 * @param connection connection details of the selected connection
 * @returns Object containing the details of excel export
 */
export const getSavingsEffectivenessExcelData = (
  savingsEffectivenessData: SavingsEffectivenessType,
  selectedTimeRange: string,
  savingsEffectivenessDateRange: string[],
  connection: ConnectionListType | null
) => {
  const footer: any = {};
  footer.item = i18n.t('scorecard.savingsEffectiveness.footer');
  getDateLabelsByGranularity(
    selectedTimeRange,
    savingsEffectivenessDateRange
  ).forEach((label, index) => {
    footer[label] =
      savingsEffectivenessData.savingsEffectivenessPercentage[index]
        ?.percentage + '%';
  });

  return {
    sheetName: i18n.t('graphHeadings.overallSavingsEffectiveness'),
    columns: getSavingsEffectivenessExportColumns(
      selectedTimeRange,
      savingsEffectivenessDateRange
    ),
    data: insertIndex(
      getSavingsEffectivenessExportData(
        connection?.provider,
        savingsEffectivenessData,
        selectedTimeRange,
        savingsEffectivenessDateRange
      )
    ),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connection?.displayName,
      },
      {
        heading: i18n.t('excelExportLabels.timeRange'),
        value: selectedTimeRange,
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: savingsEffectivenessDateRange[0],
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: savingsEffectivenessDateRange[1],
      },
    ],
    boldRows: getSavingsEffectivenessExportData(
      connection?.provider,
      savingsEffectivenessData,
      selectedTimeRange,
      savingsEffectivenessDateRange
    )
      .map((value, index) => (!value.isSubItem ? index : -1))
      .filter((value) => value !== -1),
    footer: footer,
  };
};
