import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import LineChart from 'components/LineChart';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { MonthlyCostByNameType } from 'types/dataTypes';
import {
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  getMonthYearShortList,
} from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import { getCostOverTimeByAccountExcelData } from '../utils';

type CostOverTimeByAccountProps = {
  costOverTimeByAccount: MonthlyCostByNameType[];
  costOverTimeByAccountTableData: any[];
  costOverTimeByAccountRequestStatus: string;
  isCostOverTimeByAccountTableView: boolean;
  setIsCostOverTimeByAccountTableView: (value: boolean) => void;
  costOverTimeByAccountDateRange: string[];
  onChangeCostOverTimeByAccountDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  pdfView: boolean;
};

const CostOverTimeByAccount = ({
  costOverTimeByAccount,
  costOverTimeByAccountTableData,
  costOverTimeByAccountRequestStatus,
  isCostOverTimeByAccountTableView,
  setIsCostOverTimeByAccountTableView,
  costOverTimeByAccountDateRange,
  onChangeCostOverTimeByAccountDateRange,
  pdfView,
}: CostOverTimeByAccountProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
      fixed: true,
    },
    {
      title: t('snowflakeDashboards.accounts'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      fixed: true,
    },
    ...getMonthYearShortList(
      costOverTimeByAccountDateRange[0],
      costOverTimeByAccountDateRange[1],
      MONTH_YEAR_SHORT
    ).map((month) => ({
      title: month,
      key: `${month}-cost`,
      dataIndex: `${month}-cost`,
      width: 100,
      render: (text: string) =>
        currencySymbol + (text ? numberCommaSeparator(text) : '0.00'),
    })),
  ];

  const filters = (
    <div className="filters">
      <DatePicker
        defaultValue={[
          moment(costOverTimeByAccountDateRange[0]),
          moment(costOverTimeByAccountDateRange[1]),
        ]}
        onChange={onChangeCostOverTimeByAccountDateRange}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        disabledDate={(current: any) => current > moment().endOf('day')}
      />
    </div>
  );

  const getComponent = () => (
    <div className="graph">
      {isCostOverTimeByAccountTableView ? (
        <div className="tabular-view">
          <Table
            pagination={false}
            loading={
              costOverTimeByAccountRequestStatus === REQUEST_STATUS.PROCESSING
            }
            dataSource={costOverTimeByAccountTableData.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            scroll={{ y: 190 }}
            designVersion2
          />
        </div>
      ) : (
        <LineChart
          data={costOverTimeByAccount}
          xField="month"
          yField="cost"
          xTitle={t('snowflakeDashboards.month')}
          yTitle={t('snowflakeDashboards.costInCurrency', {
            currencySymbol: currencySymbol,
          })}
          groupingField="name"
          prefixSymbol={currencySymbol}
          disableAnimation={pdfView}
        />
      )}
    </div>
  );

  return (
    <div
      className="graph-card cost-by-name-per-month flex flex-column flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.costOverTimeByAccount')}
        filters={filters}
        graphName="cost-over-time-by-account"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostOverTimeByAccountTableView}
        setIsTableView={setIsCostOverTimeByAccountTableView}
        excelData={getCostOverTimeByAccountExcelData(
          costOverTimeByAccountTableData,
          costOverTimeByAccountDateRange
        )}
        className="extended-filter"
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={costOverTimeByAccountRequestStatus}
      />
    </div>
  );
};

export default CostOverTimeByAccount;
