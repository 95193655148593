import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { userAuthorization } from 'redux/authorizationSlice';
import DropdownCheckbox from 'components/DropdownCheckbox';
import DashboardGraphContainer from 'components/DashboardGraphContainer';
import TagFilters from 'components/TagFilters';
import { ChartCriteria, MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  CHECK_NAME_ID_MAP,
  RECOMMENDATION_CATEGORIES,
} from 'constants/recommendations';
import { selectDashboard } from 'redux/dashboardSlice';
import { getAllRulesets } from 'pages/RuleEnginePage/services';
import { RulesetsType } from 'pages/RuleEnginePage/types';
import {
  fetchGranulateCostImpact,
  getPdfMetaData,
} from 'pages/OverviewPage/components/ConnectionsDashboard/utils';
import { CHART_TYPES } from 'constants/graphConfig';
import { onApiCallError } from 'utils/handleErrors';
import {
  AwsCategoryMappingType,
  RulesetFilterGroupsType,
} from 'types/dataTypes';
import {
  getAWSCategoryMapping,
  getFilteredRecommendations,
  getRulesetFilters,
} from 'utils/services';
import {
  addZeroMarginClass,
  generateGraphColors,
  isDashboardWithStaticData,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import {
  getLastNMonthLabels,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import ControlComponent from 'components/DashboardControl';
import { CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT } from 'constants/userConsole';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { INPUT_SIZE } from 'constants/appearance';
import { GranulateCostImpactType, GraphRowContentType } from 'types/dashboard';
import { RecommendationSource } from 'pages/CostOptimizationInsightsPage/constants';
import { GranulateConnectionsType } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationTableWithWorkFlow/types';
import { getAndSetGranulateRecommendations } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationTableWithWorkFlow/utils';

import { EstimatedMonthlySavingsPerCategory } from './components/EstimatedMonthlySavingsPerCategory';
import EstimatedSavingsPerAccount from './components/EstimatedSavingsPerAccount';
import { SavingsPerCategory } from './components/SavingsPerCategory';
import DetailedSavingsTable from './components/DetailedSavingsTable';
import {
  CHART_CRITERIA_LIST,
  EstimatedSavingsPerAccountColumns,
} from './constants';
import {
  getConsolidatedConsumptionManagementCost,
  getConsolidatedEstimatedSavingsPerAccount,
  getCostPerAccountQuery,
  getDetailedSavingsQuery,
  getDetailedSavingsTableColumns,
  getRulesetFilteredRequestBodyForAwsCheckId,
} from './utils';
import {
  CostByAccountId,
  MonthlyCostByCategory,
  ColouredMonthlyCostByCheckId,
} from './types';
import {
  fetchGranulateConnections,
  getDetailsSavingsTableDataForExport,
  getEstimatedMonthlySavingsPerCategoryColumns,
} from '../CostRecommendationSummaryDashboard/utils';
import { CategorySavingsExportColumns } from '../CostRecommendationSummaryDashboard/constants';
import { getRecommendersOptions } from '../CostRecommendationSummaryDashboard/components/DetailedSavingsTable/utils';

const AWSCostRecommendationSummaryDashboard = () => {
  const { t } = useTranslation();

  const { selectedDashboard, selectedConnection } =
    useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { permissions } = useSelector(userAuthorization);

  const [chartCriteriaFilters, setChartCriteriaFilters] = useState<string[]>(
    CHART_CRITERIA_LIST.map((item) => item.value)
  );
  const [rulesets, setRulesets] = useState<RulesetsType[]>();
  const [selectedRulesets, setSelectedRulesets] = useState<RulesetsType[]>();
  const [rulesetsRequestStatus, setRulesetsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [rulesetFilters, setRulesetFilters] =
    useState<RulesetFilterGroupsType[]>();
  const [rulesetFiltersRequestStatus, setRulesetFiltersRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  // Estimated Monthly Savings Per Category
  const [
    estimatedMonthlySavingsPerCategoryData,
    setEstimatedMonthlySavingsPerCategoryData,
  ] = useState<MonthlyCostByCategory[]>([]);
  const [
    isEstimatedMonthlySavingsPerCategoryTableView,
    setIsEstimatedMonthlySavingsPerCategoryTableView,
  ] = useState(false);

  // Estimated Savings Per Account
  const [estimatedSavingsPerAccountData, setEstimatedSavingsPerAccountData] =
    useState<CostByAccountId[]>([]);
  const [
    cspEstimatedSavingsPerAccountData,
    setCspEstimatedSavingsPerAccountData,
  ] = useState<CostByAccountId[]>([]);
  const [
    isEstimatedSavingsPerAccountTableView,
    setIsEstimatedSavingsPerAccountTableView,
  ] = useState(false);
  const [
    estimatedSavingsPerAccountRequestStatus,
    setEstimatedSavingsPerAccountRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Consumption Management Savings
  const [
    consumptionManagementSavingsData,
    setConsumptionManagementSavingsData,
  ] = useState<ColouredMonthlyCostByCheckId[]>([]);
  const [
    cspConsumptionManagementSavingsData,
    setCspConsumptionManagementSavingsData,
  ] = useState<ColouredMonthlyCostByCheckId[]>([]);
  const [
    consumptionManagementSavingsRequestStatus,
    setConsumptionManagementSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Waste management savings
  const [wasteManagementSavingsData, setWasteManagementSavingsData] = useState<
    ColouredMonthlyCostByCheckId[]
  >([]);
  const [
    wasteManagementSavingsRequestStatus,
    setWasteManagementSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Purchase Tactics Savings
  const [purchaseTacticsSavingsData, setPurchaseTacticsSavingsData] = useState<
    ColouredMonthlyCostByCheckId[]
  >([]);
  const [
    purchaseTacticsSavingsRequestStatus,
    setPurchaseTacticsSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Saving details by category filters
  const [selectedCheckId, setSelectedCheckId] = useState('');
  const [detailedRecomSelectedDateRange, setDetailedRecomSelectedDateRange] =
    useState<RangeValue<Moment>>([
      moment().subtract(2, 'month').startOf('month'),
      moment().endOf('day'),
    ]);

  // Saving details by category data
  const [selectedGraphCategory, setSelectedGraphCategory] = useState<string>(
    RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
  );

  const [detailedSavingsData, setDetailedSavingsData] = useState<any[]>([]);
  const [detailedSavingsRequestStatus, setDetailedSavingsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  // Check name list and category mapping
  const [awsCategoryMapping, setAwsCategoryMapping] = useState<
    AwsCategoryMappingType[]
  >([]);
  const [awsCheckMappingRequestStatus, setAwsCheckMappingRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  // Granulate
  const [granulateCostImpact, setGranulateCostImpact] = useState<
    GranulateCostImpactType[]
  >([]);
  const [granulateCostImpactReqStatus, setGranulateCostImpactReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [granulateConnections, setGranulateConnections] = useState<
    GranulateConnectionsType[]
  >([]);

  useEffect(() => {
    fetchAwsCategoryMapping();
    if (!isDashboardWithStaticData(selectedDashboard)) {
      fetchRulesets();
    }
    fetchGranulateConnections(
      selectedDashboard!.connectorId,
      setGranulateConnections
    );
    addZeroMarginClass('dashboard-view');

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    fetchRulesetFilters();
  }, [selectedRulesets]);

  useEffect(() => {
    if (
      permissions.thirdPartyAppsRead &&
      selectedDashboard?.dashBoardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION
    ) {
      fetchGranulateCostImpact(
        selectedDashboard!.connectorId,
        setGranulateCostImpact,
        setGranulateCostImpactReqStatus
      );
    }
  }, [selectedDashboard]);

  useEffect(() => {
    setCspEstimatedSavingsPerAccountData([]);
    setWasteManagementSavingsData([]);
    setCspConsumptionManagementSavingsData([]);
    setPurchaseTacticsSavingsData([]);
    setEstimatedMonthlySavingsPerCategoryData([]);
    if (
      selectedDashboard &&
      rulesets &&
      (!selectedRulesets || selectedRulesets.length === 0 || rulesetFilters)
    ) {
      fetchEstimatedSavingsPerAccountData();
      getCostSummaryData(
        RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
        setWasteManagementSavingsData,
        setWasteManagementSavingsRequestStatus
      );
      getCostSummaryData(
        RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
        setCspConsumptionManagementSavingsData,
        setConsumptionManagementSavingsRequestStatus
      );
      getCostSummaryData(
        RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
        setPurchaseTacticsSavingsData,
        setPurchaseTacticsSavingsRequestStatus
      );
    }
  }, [selectedDashboard, rulesetFilters, rulesets]);

  useEffect(() => {
    setEstimatedSavingsPerAccountData(
      getConsolidatedEstimatedSavingsPerAccount(
        cspEstimatedSavingsPerAccountData,
        granulateCostImpact
      )
    );
  }, [cspEstimatedSavingsPerAccountData, granulateCostImpact]);

  useEffect(() => {
    setDetailedSavingsData([]);
    if (
      selectedDashboard &&
      rulesets &&
      (!selectedRulesets || selectedRulesets.length === 0 || rulesetFilters) &&
      selectedCheckId &&
      selectedCheckId !== RecommendationSource.GRANULATE &&
      detailedRecomSelectedDateRange
    ) {
      fetchDetailedSavingsData();
    }
  }, [
    selectedDashboard,
    rulesetFilters,
    selectedCheckId,
    detailedRecomSelectedDateRange,
  ]);

  useEffect(() => {
    setSelectedCheckId(
      awsCategoryMapping.find(
        (category) => category.category === selectedGraphCategory
      )?.recommenders[0] ?? ''
    );
  }, [awsCategoryMapping, selectedGraphCategory]);

  useEffect(() => {
    if (
      selectedConnection &&
      selectedCheckId === RecommendationSource.GRANULATE
    ) {
      getAndSetGranulateRecommendations(
        selectedConnection,
        granulateConnections,
        setDetailedSavingsData,
        setDetailedSavingsRequestStatus
      );
    }
  }, [granulateConnections, selectedCheckId, selectedConnection]);

  useEffect(() => {
    setEstimatedMonthlySavingsPerCategoryData([
      {
        category: t('awsRecommendationSummary.wasteManagement'),
        cost: getLastNMonthLabels(3).map((_, index) =>
          wasteManagementSavingsData
            .map((item) => item.cost[index] || 0)
            .reduce((a, b) => a + b, 0)
        ),
      },
      {
        category: t('awsRecommendationSummary.consumptionManagement'),
        cost: getLastNMonthLabels(3).map((_, index) =>
          consumptionManagementSavingsData
            .map((item) => item.cost[index] || 0)
            .reduce((a, b) => a + b, 0)
        ),
      },
      {
        category: t('awsRecommendationSummary.purchaseTactics'),
        cost: getLastNMonthLabels(3).map((_, index) =>
          purchaseTacticsSavingsData
            .map((item) => item.cost[index] || 0)
            .reduce((a, b) => a + b, 0)
        ),
      },
    ]);
  }, [
    wasteManagementSavingsData,
    consumptionManagementSavingsData,
    purchaseTacticsSavingsData,
    granulateCostImpact,
  ]);

  useEffect(() => {
    setConsumptionManagementSavingsData(
      getConsolidatedConsumptionManagementCost(
        permissions,
        selectedDashboard!,
        cspConsumptionManagementSavingsData,
        granulateCostImpact
      )
    );
  }, [cspConsumptionManagementSavingsData, granulateCostImpact]);

  /**
   * @function fetchAwsCategoryMapping
   * @description Function to fetch the aws check names and check ids.
   */
  const fetchAwsCategoryMapping = () => {
    setAwsCheckMappingRequestStatus(REQUEST_STATUS.PROCESSING);
    getAWSCategoryMapping()
      .then((res: any) => {
        setAwsCheckMappingRequestStatus(REQUEST_STATUS.SUCCESS);
        setAwsCategoryMapping(res?.data?.responseData);
      })
      .catch((e) => onApiCallError(e, true, setAwsCheckMappingRequestStatus));
  };

  /**
   * @function fetchRulesets
   * @description Function to fetch the rulesets data
   */
  const fetchRulesets = () => {
    setRulesetsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: selectedDashboard!.connectorProvider,
    };
    getAllRulesets(params)
      .then((res: any) => {
        setRulesets(res?.data?.responseData?.content);
        setRulesetsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRulesetsRequestStatus);
        setRulesets([]);
      });
  };

  /**
   * @function fetchRulesetFilters
   * @description Function to fetch the rulesets filters
   */
  const fetchRulesetFilters = () => {
    if (!selectedRulesets || selectedRulesets.length === 0) {
      setRulesetFilters(undefined);
      return;
    }

    setRulesetFiltersRequestStatus(REQUEST_STATUS.PROCESSING);

    axios
      .all(
        selectedRulesets.map((ruleset) =>
          getRulesetFilters({ rulesetId: ruleset.rulesetId })
        )
      )
      .then((res) => {
        const filters: RulesetFilterGroupsType[] = [];
        res?.forEach((item: any) => {
          filters.push(...(item?.data?.responseData ?? []));
        });
        setRulesetFilters(filters);
        setRulesetFiltersRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, true, setRulesetFiltersRequestStatus);
      });
  };

  /**
   * @function getCostSummaryData
   * @description Function to fetch and set the cost summary data by categories.
   * @param category Category for which the cost data is fetched
   * @param setData callback function for setting the data
   * @param setRequestStatus Callback function for setting the request status
   */
  const getCostSummaryData = (
    category: string,
    setData: (val: ColouredMonthlyCostByCheckId[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    const awsChecks =
      awsCategoryMapping.find((item) => item.category === category)
        ?.recommenders ?? [];
    awsChecks.forEach((check) => {
      requests.push(
        getFilteredRecommendations(
          getRulesetFilteredRequestBodyForAwsCheckId(check, rulesetFilters),
          { connectorId: selectedDashboard!.connectorId }
        )
      );
    });

    axios
      .all(requests)
      .then((responses) => {
        const labels = getLastNMonthLabels(3);
        const categoryData = responses.map((res, index) => {
          const data: any[] = res?.data;
          return {
            check:
              CHECK_NAME_ID_MAP.find((check) => check.id === awsChecks[index])
                ?.name ?? '',
            cost: labels.map((label) =>
              Number(
                data.find(
                  (item: any) =>
                    moment(item.month, YEAR_MONTH_WITHOUT_SEPARATOR).format(
                      MONTH_YEAR_FORMAT
                    ) === label
                )?.estimatedSavings || 0
              )
            ),
          };
        });
        const graphColors = generateGraphColors(categoryData.length);
        const colouredData = categoryData.map((item, index) => ({
          ...item,
          color: graphColors[index],
        }));
        setData(colouredData);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
        setData([]);
      });
  };

  /**
   * @function fetchEstimatedSavingsPerAccountData
   * @description Function to fetch estimated savings per account Id
   */
  const fetchEstimatedSavingsPerAccountData = () => {
    setEstimatedSavingsPerAccountRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests = CHECK_NAME_ID_MAP.map((check) =>
      getFilteredRecommendations(
        getCostPerAccountQuery(check.id, rulesetFilters),
        {
          connectorId: selectedDashboard!.connectorId,
        }
      )
    );

    axios
      .all(requests)
      .then((responses: any[]) => {
        const data: any[] = [];
        responses.forEach((res) => data.push(...(res?.data ?? [])));

        const consolidatedCost: CostByAccountId[] = [];
        data.forEach((item) => {
          const existing = consolidatedCost.find(
            (item1) => item1.accountId === item.accountid
          );
          if (existing) {
            const index = consolidatedCost.findIndex(
              (item1) => item1.accountId === item.accountid
            );
            consolidatedCost.splice(index, 1, {
              ...existing,
              cost: existing?.cost + Number(item.estimatedSavings),
            });
          } else {
            consolidatedCost.push({
              accountId: item.accountid,
              cost: Number(item.estimatedSavings),
            });
          }
        });
        setCspEstimatedSavingsPerAccountData(consolidatedCost);

        setEstimatedSavingsPerAccountRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEstimatedSavingsPerAccountRequestStatus);
      });
  };

  /**
   * @function fetchDetailedSavingsData
   * @description Function to fetch the detailed savings
   */
  const fetchDetailedSavingsData = () => {
    setDetailedSavingsRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedDashboard!.connectorId,
    };

    getFilteredRecommendations(
      getDetailedSavingsQuery(
        selectedCheckId,
        detailedRecomSelectedDateRange,
        rulesetFilters
      ),
      params
    )
      .then((res: any) => {
        let data = res?.data || [];
        setDetailedSavingsData(data);
        setDetailedSavingsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setDetailedSavingsRequestStatus);
      });
  };

  /**
   * @function onChangeDetailedRecomTableDateRange
   * @description Callback function for detailed recommendation table date range selection
   * @param dates date range selected
   * @param _dateString string list of dates
   */
  const onChangeDetailedRecomTableDateRange = (dates: RangeValue<Moment>) => {
    if (!dates?.[0] || !dates?.[1]) {
      return;
    }

    setDetailedRecomSelectedDateRange(dates);
  };

  /**
   * @function getDetailedSavingsTableHeading
   * @description Function to get the detailed savings table heading
   * @returns Detailed savings table heading
   */
  const getDetailedSavingsTableHeading = () => {
    switch (selectedGraphCategory) {
      case RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT:
        return t('awsRecommendationSummary.wasteManagement');
      case RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT:
        return t('awsRecommendationSummary.consumptionManagement');
      case RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS:
        return t('awsRecommendationSummary.purchaseTactics');
      default:
        return '';
    }
  };

  const allCategoryCheckIdLabels =
    awsCategoryMapping
      .find((category) => category.category === selectedGraphCategory)
      ?.recommenders.map((recommender) => {
        return {
          value: recommender,
          label:
            CHECK_NAME_ID_MAP.find((check) => check.id === recommender)?.name ??
            '',
        };
      }) ?? [];

  const getGraphComponent = (componentName: string, pdfView = false) => {
    switch (componentName) {
      case 'estimated-monthly-savings-per-category':
        return (
          <EstimatedMonthlySavingsPerCategory
            isEstimatedMonthlySavingsPerCategoryTableView={
              isEstimatedMonthlySavingsPerCategoryTableView
            }
            setEstimatedMonthlySavingsPerCategoryTableView={
              setIsEstimatedMonthlySavingsPerCategoryTableView
            }
            requestStatus={[
              wasteManagementSavingsRequestStatus,
              consumptionManagementSavingsRequestStatus,
              purchaseTacticsSavingsRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
              granulateCostImpactReqStatus,
            ]}
            estimatedMonthlySavingsPerCategoryData={
              estimatedMonthlySavingsPerCategoryData
            }
            estimatedMonthlySavingsPerCategoryLabels={getLastNMonthLabels(3)}
            pdfView={pdfView}
          />
        );
      case 'estimated-savings-per-account':
        return (
          <EstimatedSavingsPerAccount
            pdfView={pdfView}
            requestStatus={[
              estimatedSavingsPerAccountRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
              granulateCostImpactReqStatus,
            ]}
            estimatedSavingsPerProjectData={estimatedSavingsPerAccountData}
            isEstimatedSavingsPerAccountTableView={
              isEstimatedSavingsPerAccountTableView
            }
            setIsEstimatedSavingsPerAccountTableView={
              setIsEstimatedSavingsPerAccountTableView
            }
          />
        );
      case RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT:
        return (
          <SavingsPerCategory
            requestStatus={[
              wasteManagementSavingsRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
            ]}
            savingsPerCategoryData={wasteManagementSavingsData}
            savingsPerCategoryLabels={getLastNMonthLabels(3)}
            pdfView={pdfView}
            graphHeading={t('awsRecommendationSummary.wasteManagement')}
            graphName={RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT}
            selectedGraphCategory={selectedGraphCategory}
            setSelectedGraphCategory={setSelectedGraphCategory}
          />
        );
      case RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT:
        return (
          <SavingsPerCategory
            requestStatus={[
              consumptionManagementSavingsRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
              granulateCostImpactReqStatus,
            ]}
            savingsPerCategoryData={consumptionManagementSavingsData}
            savingsPerCategoryLabels={getLastNMonthLabels(3)}
            pdfView={pdfView}
            graphHeading={t('awsRecommendationSummary.consumptionManagement')}
            graphName={RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT}
            selectedGraphCategory={selectedGraphCategory}
            setSelectedGraphCategory={setSelectedGraphCategory}
          />
        );
      case RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS:
        return (
          <SavingsPerCategory
            requestStatus={[
              purchaseTacticsSavingsRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
            ]}
            savingsPerCategoryData={purchaseTacticsSavingsData}
            savingsPerCategoryLabels={getLastNMonthLabels(3)}
            pdfView={pdfView}
            graphHeading={t('awsRecommendationSummary.purchaseTactics')}
            graphName={RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS}
            selectedGraphCategory={selectedGraphCategory}
            setSelectedGraphCategory={setSelectedGraphCategory}
          />
        );
      case 'detailed-savings-table':
        return (
          <DetailedSavingsTable
            detailedSavingsData={detailedSavingsData}
            graphHeading={getDetailedSavingsTableHeading()}
            requestStatus={[
              detailedSavingsRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
            ]}
            pdfView={pdfView}
            allCategoryCheckIds={allCategoryCheckIdLabels}
            selectedCheckId={selectedCheckId}
            setSelectedCheckId={setSelectedCheckId}
            selectedDateRange={detailedRecomSelectedDateRange}
            onChangeDateRange={onChangeDetailedRecomTableDateRange}
            dropdownFilterRequestStatus={awsCheckMappingRequestStatus}
            selectedGraphCategory={selectedGraphCategory}
          />
        );
    }
  };

  /**
   * @function getEstimatedMonthlySavingsPerCategoryExportData
   * @description function to get estimated monthly savings per category pdf table data
   * @returns table data for estimated monthly savings per category as array
   */
  const getEstimatedMonthlySavingsPerCategoryExportData = () => {
    let tableData: any[] = [];
    estimatedMonthlySavingsPerCategoryData.forEach((value) => {
      let data: any = {};
      data['category'] = value.category;
      getLastNMonthLabels(3).forEach((label, index) => {
        data[label] = currencySymbol + numberCommaSeparator(value.cost[index]);
      });
      tableData.push(data);
    });
    return tableData;
  };

  /**
   * @function getMonthlySavingsOfCategoryPdfTableData
   * @description function to get estimated monthly savings per category pdf table data
   * @returns table data for estimated monthly savings per category as array
   */
  const getMonthlySavingsOfCategoryExportTableData = (
    monthlySavingsData: ColouredMonthlyCostByCheckId[]
  ) => {
    let tableData: any[] = [];
    getLastNMonthLabels(3).forEach((label, index) => {
      monthlySavingsData.forEach((value) =>
        tableData.push({
          month: label,
          category: value.check,
          cost: value.cost[index] || 0,
        })
      );
    });
    return tableData;
  };

  /**
   * @function getMinimizedRulesetText
   * @description function to get minimized ruleset text for control component
   * @returns minimized ruleset text
   */
  const getMinimizedRulesetText = () => {
    const allSelected = selectedRulesets
      ?.map((ruleset) => ruleset.ruleSetName)
      .join(', ')
      .trim();
    if (allSelected)
      return (
        allSelected.substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...'
      );
    return t('awsRecommendationSummary.noRulesetsApplied');
  };

  const GraphRows: GraphRowContentType = useMemo(
    () => [
      [
        {
          element: getGraphComponent(
            'estimated-monthly-savings-per-category',
            true
          ),
          graphHeading: t('graphHeadings.estimatedMonthlySavingsPerCategory'),
          contentType: CHART_TYPES.LINE_CHART,
          graphName: 'estimated-monthly-savings-per-category',
          criteria: [ChartCriteria.TIME_PERIOD],
          colSpanWidth: 16,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getEstimatedMonthlySavingsPerCategoryColumns(
              getLastNMonthLabels(3)
            ),
            isTableView: isEstimatedMonthlySavingsPerCategoryTableView,
            setTableView: setIsEstimatedMonthlySavingsPerCategoryTableView,
          },
          pdfTableExport: {
            pdfTableData: getEstimatedMonthlySavingsPerCategoryExportData(),
          },
          excelExport: {
            excelData: getEstimatedMonthlySavingsPerCategoryExportData(),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: estimatedMonthlySavingsPerCategoryData.map((item) => ({
              name: item.category,
              labels: getLastNMonthLabels(3),
              values: item.cost,
            })),
          },
        },
        {
          element: getGraphComponent('estimated-savings-per-account', true),
          graphHeading: t('graphHeadings.ytdEstimatedSavingsPerAccount'),
          contentType: CHART_TYPES.HORIZONTAL_BAR_CHART,
          graphName: 'estimated-savings-per-account',
          criteria: [ChartCriteria.ACCOUNT],
          colSpanWidth: 8,
          chartView: {
            xAxisLabel: t('costInCurrency', { currencySymbol }),
            yAxisLabel: t('accountIds'),
          },
          tableView: {
            columns: EstimatedSavingsPerAccountColumns,
            isTableView: isEstimatedSavingsPerAccountTableView,
            setTableView: setIsEstimatedSavingsPerAccountTableView,
          },
          pdfTableExport: {
            pdfTableData: estimatedSavingsPerAccountData.map((item) => ({
              ...item,
              cost: currencySymbol + numberCommaSeparator(item.cost),
            })),
          },
          excelExport: {
            excelData: estimatedSavingsPerAccountData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: estimatedSavingsPerAccountData.map(
                  (item) => item.accountId
                ),
                values: estimatedSavingsPerAccountData.map((item) => item.cost),
              },
            ],
          },
        },
      ],
      [
        {
          element: getGraphComponent(
            RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
            true
          ),
          graphHeading: t('awsRecommendationSummary.wasteManagement'),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
          criteria: [ChartCriteria.RECOMMENDATION],
          colSpanWidth:
            selectedGraphCategory === RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
              ? 10
              : 7,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: CategorySavingsExportColumns,
          },
          pdfTableExport: {
            pdfTableData: getMonthlySavingsOfCategoryExportTableData(
              wasteManagementSavingsData
            ),
          },
          excelExport: {
            excelData: getMonthlySavingsOfCategoryExportTableData(
              wasteManagementSavingsData
            ),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: wasteManagementSavingsData.map((item) => ({
              name: item.check,
              labels: getLastNMonthLabels(3),
              values: item.cost,
            })),
          },
        },
        {
          element: getGraphComponent(
            RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
            true
          ),
          graphHeading: t('awsRecommendationSummary.consumptionManagement'),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
          criteria: [ChartCriteria.RECOMMENDATION],
          colSpanWidth:
            selectedGraphCategory ===
            RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT
              ? 10
              : 7,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: CategorySavingsExportColumns,
          },
          pdfTableExport: {
            pdfTableData: getMonthlySavingsOfCategoryExportTableData(
              consumptionManagementSavingsData
            ),
          },
          excelExport: {
            excelData: getMonthlySavingsOfCategoryExportTableData(
              consumptionManagementSavingsData
            ),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: wasteManagementSavingsData.map((item) => ({
              name: item.check,
              labels: getLastNMonthLabels(3),
              values: item.cost,
            })),
          },
        },
        {
          element: getGraphComponent(
            RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
            true
          ),
          graphHeading: t('awsRecommendationSummary.purchaseTactics'),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
          criteria: [ChartCriteria.RECOMMENDATION],
          colSpanWidth:
            selectedGraphCategory === RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS
              ? 10
              : 7,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: CategorySavingsExportColumns,
          },
          pdfTableExport: {
            pdfTableData: getMonthlySavingsOfCategoryExportTableData(
              purchaseTacticsSavingsData
            ),
          },
          excelExport: {
            excelData: getMonthlySavingsOfCategoryExportTableData(
              purchaseTacticsSavingsData
            ),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: wasteManagementSavingsData.map((item) => ({
              name: item.check,
              labels: getLastNMonthLabels(3),
              values: item.cost,
            })),
          },
        },
      ],
      [
        {
          element: getGraphComponent('detailed-savings-table', true),
          graphHeading:
            getDetailedSavingsTableHeading() +
            getRecommendersOptions(
              permissions,
              selectedDashboard!,
              selectedGraphCategory,
              allCategoryCheckIdLabels
            ).find((item) => item.value === selectedCheckId)?.label,
          contentType: CHART_TYPES.TABLE,
          graphName: 'detailed-savings-table',
          criteria: [ChartCriteria.RECOMMENDATION],
          tableView: {
            columns: getDetailedSavingsTableColumns(selectedCheckId, false),
          },
          pdfTableExport: {
            pdfTableData: getDetailsSavingsTableDataForExport(
              selectedCheckId,
              detailedSavingsData
            ),
          },
          excelExport: {
            excelData: getDetailsSavingsTableDataForExport(
              selectedCheckId,
              detailedSavingsData
            ),
            excelFilters: {
              connectionName: selectedDashboard!.name,
              checkName: getRecommendersOptions(
                permissions,
                selectedDashboard!,
                selectedGraphCategory,
                allCategoryCheckIdLabels
              ).find((item) => item.value === selectedCheckId)?.label,
              startDate:
                detailedRecomSelectedDateRange?.[0]?.format(MONTH_YEAR_FORMAT),
              endDate:
                detailedRecomSelectedDateRange?.[1]?.format(MONTH_YEAR_FORMAT),
            },
          },
          pptExport: {
            chartHeading:
              getDetailedSavingsTableHeading() +
              allCategoryCheckIdLabels.find(
                (item) => item.value === selectedCheckId
              )?.label,
            pptData: [
              getDetailedSavingsTableColumns(selectedCheckId, false).map(
                (column) => column.title
              ),
              ...getDetailsSavingsTableDataForExport(
                selectedCheckId,
                detailedSavingsData
              ).map((item) =>
                getDetailedSavingsTableColumns(selectedCheckId, false).map(
                  (column) => item[column.dataIndex]
                )
              ),
            ],
          },
        },
      ],
    ],
    [
      awsCategoryMapping,
      consumptionManagementSavingsData,
      detailedRecomSelectedDateRange,
      detailedSavingsData,
      estimatedMonthlySavingsPerCategoryData,
      estimatedSavingsPerAccountData,
      isEstimatedMonthlySavingsPerCategoryTableView,
      isEstimatedSavingsPerAccountTableView,
      purchaseTacticsSavingsData,
      selectedCheckId,
      selectedGraphCategory,
      wasteManagementSavingsData,
    ]
  );

  return (
    <>
      <ControlComponent
        filters={[
          {
            title: t('listByDimensions'),
            filter: (
              <DropdownCheckbox
                itemOptions={CHART_CRITERIA_LIST}
                selectedItems={chartCriteriaFilters}
                setSelectedItems={setChartCriteriaFilters}
                designVersion2
                size={INPUT_SIZE.SMALL}
              />
            ),
            minimizedText:
              chartCriteriaFilters.length === CHART_CRITERIA_LIST.length
                ? t('all')
                : CHART_CRITERIA_LIST.filter((each) =>
                    chartCriteriaFilters.includes(each.value)
                  )
                    .map((each) => each.title)
                    .join(', '),
            onClear: () => setChartCriteriaFilters([]),
          },
          {
            title: t('awsRecommendationSummary.ruleset'),
            filter: (
              <DropdownCheckbox
                itemOptions={(rulesets ?? []).map((ruleset) => ({
                  title: ruleset.ruleSetName,
                  value: ruleset.rulesetId ?? '',
                }))}
                selectedItems={
                  selectedRulesets?.map((ruleset) => ruleset.rulesetId ?? '') ??
                  []
                }
                setSelectedItems={(items) => {
                  setSelectedRulesets(
                    rulesets?.filter((ruleset) =>
                      items.includes(ruleset.rulesetId ?? '')
                    )
                  );
                }}
                loading={rulesetsRequestStatus === REQUEST_STATUS.PROCESSING}
                designVersion2
                size={INPUT_SIZE.SMALL}
              />
            ),
            minimizedText: getMinimizedRulesetText(),
            onClear: () => setSelectedRulesets([]),
          },
        ]}
      />
      <div className="aws-recommendation-summary inner-dashboard-content flex flex-column flex-gap-24">
        <TagFilters />
        <DashboardGraphContainer
          getGraphComponent={getGraphComponent}
          chartCriteriaFilters={chartCriteriaFilters}
          rows={GraphRows}
          pdfMetaData={getPdfMetaData(
            t('dashNav.costRecommendationSummary'),
            selectedDashboard!,
            selectedConnection!
          )}
        />
      </div>
    </>
  );
};

export default AWSCostRecommendationSummaryDashboard;
