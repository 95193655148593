import { useSelector } from 'react-redux';
import { selectDashboard } from 'redux/dashboardSlice';
import { PROVIDER } from 'constants/cloudProviders';
import GCPCostSummaryDashboard from './components/GCPCostSummaryDashboard';
import AWSCostSummaryDashboard from './components/AWSCostSummaryDashboard';
import OCICostSummaryDashboard from './components/OCICostSummaryDashboard';
import AzureCostSummaryDashboard from './components/AzureCostSummaryDashboard';

const CostSummaryDashboard = () => {
  const { selectedDashboard } = useSelector(selectDashboard);

  /**
   * @function getCostSummaryDashboard
   * @description Function to get the cost summary dashboard component based on the provider
   * @return Function will return corresponding cost summary dashboard for selected provider
   */
  const getCostSummaryDashboard = () => {
    switch (selectedDashboard?.connectorProvider) {
      case PROVIDER.GCP:
        return <GCPCostSummaryDashboard />;

      case PROVIDER.AWS:
        return <AWSCostSummaryDashboard />;

      case PROVIDER.AZURE:
        return <AzureCostSummaryDashboard />;

      case PROVIDER.OCI:
        return <OCICostSummaryDashboard />;

      default:
        return <GCPCostSummaryDashboard />;
    }
  };

  return getCostSummaryDashboard();
};

export default CostSummaryDashboard;
