import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ColumnChart from 'components/ColumnChart';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import Cascader from 'components/Cascader';
import DatePicker from 'components/DatePicker';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { evaluateRequestArray } from 'utils/handleErrors';
import { generateGraphColors } from 'utils/dashboardUtils';
import { DATE_FORMAT } from 'utils/date';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';
import { getDurationAxisLabel } from 'pages/ContainerInsightsPage/utils';

import { ClusterBreakdownType } from '../../types';
import { displayRender, onChangeDateFilter, onChangeDates } from '../../utils';
import { getClusterBreakdownExcelData } from '../../utils/exportToExcel';

type ClusterBreakdownProps = {
  requestStatus: string[];
  clusterBreakdownData: ClusterBreakdownType[];
  tableData: any;
  isClusterBreakdownTableView: boolean;
  setIsClusterBreakdownTableView: (value: boolean) => void;
  pdfView?: boolean;
  clusterBreakdownDateFilter: DateRangeFilter;
  setClusterBreakdownDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};
const ClusterBreakdown = ({
  requestStatus,
  isClusterBreakdownTableView,
  setIsClusterBreakdownTableView,
  clusterBreakdownData,
  tableData,
  pdfView,
  clusterBreakdownDateFilter,
  setClusterBreakdownDateFilter,
  excelFilters,
}: ClusterBreakdownProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('containerInsight.usageMeteringLabels.clusters'),
      dataIndex: 'cluster',
      key: 'cluster',
      width: 150,
    },
    ...uniqBy(clusterBreakdownData, 'time').map((item) => ({
      title: item.time,
      key: `${item.time}-cost`,
      dataIndex: `${item.time}-cost`,
      width: 100,
    })),
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  format={DATE_FORMAT}
                  defaultValue={[
                    moment(clusterBreakdownDateFilter.startDate),
                    moment(clusterBreakdownDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(dateString, setClusterBreakdownDateFilter)
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters">
      <Cascader
        className="width-30"
        options={DateMenu}
        defaultValue={[clusterBreakdownDateFilter.option]}
        displayRender={() => displayRender(clusterBreakdownDateFilter)}
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setClusterBreakdownDateFilter)
        }
      />
    </div>
  );

  const getComponent = () =>
    isClusterBreakdownTableView ? (
      <Table
        pagination={false}
        loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
        dataSource={tableData}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer={true}
      />
    ) : (
      <ColumnChart
        data={clusterBreakdownData}
        isStack={true}
        xField="time"
        yField="cost"
        groupingField="cluster"
        xTitle={getDurationAxisLabel(clusterBreakdownDateFilter)}
        yTitle={t('containerInsight.usageMeteringLabels.cost', {
          currencySymbol: currencySymbol,
        })}
        colorField="cluster"
        columnColorOverride={generateGraphColors(clusterBreakdownData.length)}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  return (
    <div
      className="cluster-breakdown graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.clusterBreakdown')}
        graphName="cluster-breakdown"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isClusterBreakdownTableView}
        setIsTableView={setIsClusterBreakdownTableView}
        filters={filters}
        excelData={getClusterBreakdownExcelData(
          clusterBreakdownData,
          tableData,
          [
            ...excelFilters,
            {
              heading: t('excelExportLabels.startDate'),
              value: clusterBreakdownDateFilter.startDate,
            },
            {
              heading: t('excelExportLabels.endDate'),
              value: clusterBreakdownDateFilter.endDate,
            },
          ]
        )}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default ClusterBreakdown;
