import i18n from 'i18n';

import {
  GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES,
  GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES,
  GCP_PURCHASE_TACTICS_RECOMMENDATION_TYPES,
} from 'constants/recommendations';

import { ViewListType } from './types';

export enum COST_OPTIMIZATION_INSIGHTS_NAV {
  COST_SAVINGS_STATUS_SUMMARY = 'COST_SAVINGS_STATUS_SUMMARY',
  WASTE_MANAGEMENT_RECOMMENDATIONS = 'WASTE_MANAGEMENT',
  CONSUMPTION_MANAGEMENT_RECOMMENDATIONS = 'CONSUMPTION_MANAGEMENT',
  PURCHASE_TACTICS_RECOMMENDATIONS = 'PURCHASE_TACTICS',
}

export const COST_OPTIMIZATION_INSIGHTS_DASHBOARD_NAVIGATION = [
  {
    id: COST_OPTIMIZATION_INSIGHTS_NAV.COST_SAVINGS_STATUS_SUMMARY,
    title: 'costSavingsStatusSummary',
    permissions: ['costControlRead'],
  },
  {
    id: COST_OPTIMIZATION_INSIGHTS_NAV.WASTE_MANAGEMENT_RECOMMENDATIONS,
    title: 'wasteManagementRecommendations',
    permissions: ['costControlList'],
  },
  {
    id: COST_OPTIMIZATION_INSIGHTS_NAV.CONSUMPTION_MANAGEMENT_RECOMMENDATIONS,
    title: 'consumptionManagementRecommendations',
    permissions: ['costControlList'],
  },
  {
    id: COST_OPTIMIZATION_INSIGHTS_NAV.PURCHASE_TACTICS_RECOMMENDATIONS,
    title: 'purchaseTacticsRecommendations',
    permissions: ['costControlList'],
  },
];

export const RecommendationTableExportColumns = [
  { header: '#', key: 'slNo', width: 10, alignment: 'center', dataKey: 'slNo' },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.project'),
    dataKey: 'projectId',
    key: 'projectId',
    alignment: 'left',
    width: 40,
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.ticketStatus'),
    dataKey: 'serviceNowStatus',
    key: 'serviceNowStatus',
    alignment: 'left',
    width: 20,
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.recStatus'),
    dataKey: 'recStatus',
    key: 'recStatus',
    alignment: 'left',
    width: 15,
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.priority'),
    dataKey: 'priority',
    key: 'priority',
    alignment: 'left',
  },
  {
    header: i18n.t(
      'costOptimizationInsight.recommendationTable.recommendation'
    ),
    dataKey: 'recommendation',
    key: 'recommendation',
    alignment: 'left',
    width: 80,
  },
  {
    header: i18n.t(
      'costOptimizationInsight.recommendationTable.recommendationId'
    ),
    dataKey: 'recommendationId',
    key: 'recommendationId',
    alignment: 'left',
    width: 80,
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.type'),
    dataKey: 'type',
    key: 'type',
    alignment: 'left',
    width: 40,
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.category'),
    dataKey: 'category',
    key: 'category',
    alignment: 'left',
    width: 30,
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.lastRefreshed'),
    dataKey: 'lastRefreshed',
    key: 'lastRefreshed',
    alignment: 'left',
    width: 30,
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.costSavings'),
    dataKey: 'costSavings',
    key: 'costSavings',
    alignment: 'right',
    width: 15,
  },
];

export const DEFAULT_VIEW = 'DEFAULT';

export const DEFAULT_VIEW_DROPDOWN_OPTIONS: ViewListType[] = [
  {
    viewName: i18n.t('costOptimizationInsight.viewDropdown.defaultView'),
    key: DEFAULT_VIEW,
    recommendationsFilterDtoList: [],
  },
];

export enum RecommendationTableActions {
  TICKET_INFO = 'TICKET_INFO',
  APPLY_RECOMMENDATIONS = 'APPLY_REC',
}

export const RECOMMENDATION_TABLE_QUICK_ACTIONS = [
  {
    id: RecommendationTableActions.TICKET_INFO,
    title: i18n.t(
      'costOptimizationInsight.recommendationTableAction.ticketInfo'
    ),
  },
  {
    id: RecommendationTableActions.APPLY_RECOMMENDATIONS,
    title: i18n.t('costOptimizationInsight.recommendationTableAction.applyRec'),
  },
];

export enum RecommendationStatus {
  ACTIVE = 'ACTIVE',
  CLAIMED = 'CLAIMED',
  SUCCEEDED = 'SUCCEEDED',
  DISMISSED = 'DISMISSED',
  FAILED = 'FAILED',
  NA = 'N/A',
}

export enum SnowStatuses {
  INCIDENT_STATUS = 'INCIDENT_STATUS',
  INCIDENT_RECOMMENDATION_STATUS = 'INCIDENT_RECOMMENDATION_STATUS',
}

export enum RecommenderType {
  DISK_IDLE_RESOURCE = 'google.compute.disk.IdleResourceRecommender',
}

export enum RecommendationCategories {
  SECURITY = 'SECURITY',
  COST = 'COST',
  PERFORMANCE = 'PERFORMANCE',
}

export const RECOMMENDATION_CATEGORIES_LABELS = [
  {
    key: RecommendationCategories.SECURITY,
    label: i18n.t('recommendationCategories.security'),
  },
  {
    key: RecommendationCategories.COST,
    label: i18n.t('recommendationCategories.cost'),
  },
  {
    key: RecommendationCategories.PERFORMANCE,
    label: i18n.t('recommendationCategories.performance'),
  },
];

export enum RecommenderSubtypes {
  REMOVE_ROLE_STORAGE_BUCKET = 'REMOVE_ROLE_STORAGE_BUCKET',
  REMOVE_ROLE = 'REMOVE_ROLE',
  REPLACE_ROLE_CUSTOMIZABLE = 'REPLACE_ROLE_CUSTOMIZABLE',
  REPLACE_ROLE = 'REPLACE_ROLE',
  SERVICE_AGENT_WITHOUT_DEFAULT_ROLE = 'SERVICE_AGENT_WITHOUT_DEFAULT_ROLE',
  SERVICE_AGENT_WITH_DEFAULT_ROLE = 'SERVICE_AGENT_WITH_DEFAULT_ROLE',
  CREATE_DEDICATED_SERVICE_IDENTITY = 'CREATE_DEDICATED_SERVICE_IDENTITY',
  DELETE_IMAGE = 'DELETE_IMAGE',
  SNAPSHOT_AND_DELETE_DISK = 'SNAPSHOT_AND_DELETE_DISK',
  DELETE_DISK = 'DELETE_DISK',
  DELETE_ADDRESS = 'DELETE_ADDRESS',
  STOP_VM = 'STOP_VM',
  RECLAIM_PROJECT = 'RECLAIM_PROJECT',
  CLEANUP_PROJECT = 'CLEANUP_PROJECT',
  CHANGE_MACHINE_TYPE = 'CHANGE_MACHINE_TYPE',
  PROJECT_SCOPED_COMMITMENTS = 'PROJECT_SCOPED_COMMITMENTS',
}

export enum GranulateRecommenderSubTypes {
  GRANULATE_CPU = 'GRANULATE_CPU',
  GRANULATE_MEMORY = 'GRANULATE_MEMORY',
}

export const RECOMMENDATION_SUBTYPE_LABELS = [
  {
    key: RecommenderSubtypes.REMOVE_ROLE_STORAGE_BUCKET,
    label: i18n.t('recommenderSubtype.removeRoleStorageBucket'),
  },
  {
    key: RecommenderSubtypes.REMOVE_ROLE,
    label: i18n.t('recommenderSubtype.removeRole'),
  },
  {
    key: RecommenderSubtypes.REPLACE_ROLE_CUSTOMIZABLE,
    label: i18n.t('recommenderSubtype.replaceRoleCustomizable'),
  },
  {
    key: RecommenderSubtypes.REPLACE_ROLE,
    label: i18n.t('recommenderSubtype.replaceRole'),
  },
  {
    key: RecommenderSubtypes.SERVICE_AGENT_WITHOUT_DEFAULT_ROLE,
    label: i18n.t('recommenderSubtype.serviceAgentWithoutDefaultRole'),
  },
  {
    key: RecommenderSubtypes.SERVICE_AGENT_WITH_DEFAULT_ROLE,
    label: i18n.t('recommenderSubtype.removeRoleStorageBucket'),
  },
  {
    key: RecommenderSubtypes.CREATE_DEDICATED_SERVICE_IDENTITY,
    label: i18n.t('recommenderSubtype.createDedicatedServiceIdentity'),
  },
  {
    key: RecommenderSubtypes.DELETE_IMAGE,
    label: i18n.t('recommenderSubtype.deleteImage'),
  },
  {
    key: RecommenderSubtypes.SNAPSHOT_AND_DELETE_DISK,
    label: i18n.t('recommenderSubtype.snapshotAndDeleteDisk'),
  },
  {
    key: RecommenderSubtypes.DELETE_DISK,
    label: i18n.t('recommenderSubtype.deleteDisk'),
  },
  {
    key: RecommenderSubtypes.DELETE_ADDRESS,
    label: i18n.t('recommenderSubtype.deleteAddress'),
  },
  {
    key: RecommenderSubtypes.STOP_VM,
    label: i18n.t('recommenderSubtype.stopVm'),
  },
  {
    key: RecommenderSubtypes.RECLAIM_PROJECT,
    label: i18n.t('recommenderSubtype.reclaimProject'),
  },
  {
    key: RecommenderSubtypes.CLEANUP_PROJECT,
    label: i18n.t('recommenderSubtype.cleanupProject'),
  },
  {
    key: RecommenderSubtypes.CHANGE_MACHINE_TYPE,
    label: i18n.t('recommenderSubtype.changeMachineType'),
  },
  {
    key: RecommenderSubtypes.PROJECT_SCOPED_COMMITMENTS,
    label: i18n.t('recommenderSubtype.projectScopedCommitments'),
  },
  {
    key: GranulateRecommenderSubTypes.GRANULATE_CPU,
    label: i18n.t('recommenderSubtype.granulateCpu'),
  },
  {
    key: GranulateRecommenderSubTypes.GRANULATE_MEMORY,
    label: i18n.t('recommenderSubtype.granulateMemory'),
  },
];

export const RECOMMENDATION_STATUS_LABELS = [
  {
    key: RecommendationStatus.ACTIVE,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationStatusTable.active'
    ),
  },
  {
    key: RecommendationStatus.CLAIMED,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationStatusTable.claimed'
    ),
  },
  {
    key: RecommendationStatus.SUCCEEDED,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationStatusTable.succeeded'
    ),
  },
  {
    key: RecommendationStatus.FAILED,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationStatusTable.failed'
    ),
  },
  {
    key: RecommendationStatus.DISMISSED,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationStatusTable.dismissed'
    ),
  },
  {
    key: RecommendationStatus.NA,
    label: 'N/A',
  },
];

export const WASTE_MANAGEMENT_RECOMMENDATION_TYPES_LABELS = [
  {
    key: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_INSTANCE,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.idleInstances'
    ),
  },
  {
    key: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_DISKS,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.idleDisks'
    ),
  },
  {
    key: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_IP_ADDRESSES,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.idleIpAddresses'
    ),
  },
  {
    key: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_IMAGES,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.idleImages'
    ),
  },
  {
    key: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_CLOUD_SQL_INSTANCES,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.idleCloudSQLInstances'
    ),
  },
  {
    key: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.OVERPROVISIONED_CLOUD_SQL_INSTANCES,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.overprovisionedCloudSQLInstances'
    ),
  },
  {
    key: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.PROJECT_UTILIZATION,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.projectUtilization'
    ),
  },
];

export const CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES_LABELS = [
  {
    key: GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES.MACHINE_TYPE_INSTANCE,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.machineTypeInstance'
    ),
  },
  {
    key: GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES.MACHINE_TYPE_INSTANCE_GROUP_MANAGER,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.machineTypeInstanceGroupManager'
    ),
  },
];

export const PURCHASE_TACTICS_RECOMMENDATION_TYPES_LABELS = [
  {
    key: GCP_PURCHASE_TACTICS_RECOMMENDATION_TYPES.CAPACITY_COMMITMENTS,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.capacityCommitments'
    ),
  },
  {
    key: GCP_PURCHASE_TACTICS_RECOMMENDATION_TYPES.USAGE_COMMITMENTS,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.usageCommitments'
    ),
  },
  {
    key: GCP_PURCHASE_TACTICS_RECOMMENDATION_TYPES.SPEND_BASED_COMMITMENTS,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.spendBasedCommitments'
    ),
  },
];

export enum SAVINGS_TYPES {
  POTENTIAL_SAVINGS = 'POTENTIAL_SAVINGS',
  COST_SAVINGS_IN_PROGRESS = 'COST_SAVINGS_IN_PROGRESS',
  COST_SAVED = 'COST_SAVED',
}

export const SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP = [
  {
    type: SAVINGS_TYPES.POTENTIAL_SAVINGS,
    status: [RecommendationStatus.ACTIVE, RecommendationStatus.FAILED],
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.potentialSavings'
    ),
  },
  {
    type: SAVINGS_TYPES.COST_SAVINGS_IN_PROGRESS,
    status: [RecommendationStatus.CLAIMED],
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.costSavingInProgress'
    ),
  },
  {
    type: SAVINGS_TYPES.COST_SAVED,
    status: [RecommendationStatus.SUCCEEDED],
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.costSaved'
    ),
  },
];

export const FilterKeys = {
  CATEGORY: 'category',
  RECOMMENDER_SUBTYPE: 'recommender_subtype',
  PRIMARY_IMPACT_CATEGORY: 'primary_impact_category',
  CLOUD_ENTITY_ID: 'cloud_entity_id',
  RECOMMENDER: 'recommender',
  RECOMMENDATION_DESCRIPTION: 'recommendation_description',
  LAST_REFRESH_DATE_RANGE: 'last_refresh_date_range',
  COST_SAVINGS: 'cost_savings',
  STATUS: 'status',
  REGION: 'region',
  RULESET: 'ruleset',
};

export enum RecommendationSource {
  CSP = 'CSP',
  GRANULATE = 'GRANULATE',
}
