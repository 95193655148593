import { CostByProjectType } from 'types/dataTypes';

/**
 * @function getUniqueProjects
 * @description Function to return unique data of project cost (Will return only the first entry of the cost for each project)
 * @param projectData List of project cost by month (only the first entry of each project)
 * @return List of project cost
 */
export const getUniqueProjects = (projectData: CostByProjectType[]) => {
  const projects: CostByProjectType[] = [];
  projectData.forEach((item) => {
    if (!projects.some((entry) => entry.project === item.project)) {
      projects.push(item);
    }
  });
  return projects;
};
