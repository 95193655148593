import i18n from 'i18n';

import { ProfileSpendTypes } from '../../constants';

/**
 * @function getHeaderBySpendType
 * @description Function to get the heading by spend type
 * @param spendType spend type for which the heading is returned
 * @returns string heading
 */
export const getHeaderBySpendType = (spendType: ProfileSpendTypes) => {
  switch (spendType) {
    case ProfileSpendTypes.INCREASING_SPEND:
      return i18n.t('increasingSpend');

    case ProfileSpendTypes.DECREASING_SPEND:
      return i18n.t('decreasingSpend');

    case ProfileSpendTypes.INCONSISTENT_SPEND:
      return i18n.t('inconsistentSpend');

    case ProfileSpendTypes.CONSTANT_SPEND:
      return i18n.t('constantSpend');
  }
};

/**
 * @function getProfileDescriptionBySpendType
 * @description Function to get the description by spend type
 * @param spendType spend type for which the description is returned
 * @returns string description
 */
export const getProfileDescriptionBySpendType = (
  spendType: ProfileSpendTypes
) => {
  switch (spendType) {
    case ProfileSpendTypes.INCREASING_SPEND:
      return i18n.t('increasingSpendDescription');

    case ProfileSpendTypes.DECREASING_SPEND:
      return i18n.t('decreasingSpendDescription');

    case ProfileSpendTypes.INCONSISTENT_SPEND:
      return i18n.t('inconsistentSpendDescription');

    case ProfileSpendTypes.CONSTANT_SPEND:
      return i18n.t('constantSpendDescription');
  }
};
