import { useSelector } from 'react-redux';
import { Radio } from 'antd';

import AccessibleDiv from 'components/AccessibleDiv';
import { DataCenterListType } from 'pages/DataCenterPage/types';
import { tcoDashboard } from 'redux/tcoDashboardSlice';

import './index.scss';

type DataCenterDashboardListItemProps = {
  dashboard: DataCenterListType;
  onSelectDashboard: () => void;
};

const DataCenterDashboardListItem = ({
  dashboard,
  onSelectDashboard,
}: DataCenterDashboardListItemProps) => {
  const { selectedOnPremise } = useSelector(tcoDashboard);

  return (
    <AccessibleDiv
      className="dashboard-list-item flex flex-gap-16 cursor-pointer"
      onClick={onSelectDashboard}
    >
      <Radio
        checked={selectedOnPremise?.dataCenterCode === dashboard.dataCenterCode}
      />
      <div className={`dashboard-details flex flex-column flex-gap-4`}>
        <span className="font-button">{dashboard.name}</span>
        <div className="flex flex-gap-4">
          <span className="description font-caption-bold">
            {dashboard.dataCenterCode}
          </span>
          <span className="description font-caption">
            {dashboard.description}
          </span>
        </div>
      </div>
    </AccessibleDiv>
  );
};

export default DataCenterDashboardListItem;
