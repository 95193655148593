import { useSelector } from 'react-redux';
import { selectDashboard } from 'redux/dashboardSlice';
import { PROVIDER } from 'constants/cloudProviders';
import ProjectDeepDiveDashBoard from './components/ProjectDeepDiveDashboard';
import AWSAccountDeepDiveDashboard from './components/AWSAccountDeepDive';

const DeepDiveDashboard = () => {
  const { selectedDashboard } = useSelector(selectDashboard);

  /**
   * @function getProjectDeepDiveDashboard
   * @description Function to get the project deep dive dashboard component based on the provider
   * @return Function will return project deep dive dashboard for selected provider
   */
  const getProjectDeepDiveDashboard = () => {
    return selectedDashboard?.connectorProvider === PROVIDER.AWS ? (
      <AWSAccountDeepDiveDashboard />
    ) : (
      <ProjectDeepDiveDashBoard />
    );
  };

  return getProjectDeepDiveDashboard();
};

export default DeepDiveDashboard;
