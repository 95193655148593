import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, message, Radio, Row } from 'antd';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';

import { dataCenter } from 'redux/dataCenterSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { LaborEntryListType } from 'pages/DataCenterPage/types';
import {
  saveLaborResource,
  updateLaborResource,
} from 'pages/DataCenterPage/services';
import { EmploymentTypes } from 'pages/DataCenterPage/constants';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MONTH_YEAR_FORMAT, TIMESTAMP_FORMAT_WITHOUT_ZONE } from 'utils/date';
import {
  getValidationStyle,
  isNumber,
  validateContainsOnlyAlphabets,
  validateEmptyField,
} from 'utils/validations';
import DrawerComponent from 'components/DrawerComponent';
import DatePicker from 'components/DatePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import { BUTTON_TYPE } from 'constants/appearance';
import { onApiCallError } from 'utils/handleErrors';

import { TypesOfEmployment } from '../ManualEntry/constants';

type LaborRowModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  onSaveOrUpdateEntry: () => void;
  laborEntry?: LaborEntryListType;
};

const LaborRowModal = ({
  show,
  setShow,
  onSaveOrUpdateEntry,
  laborEntry,
}: LaborRowModalProps) => {
  const { t } = useTranslation();
  const { selectedDataCenter } = useSelector(dataCenter);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [employeeName, setEmployeeName] = useState('');
  const [employeeNameValidation, setEmployeeNameValidation] = useState('');
  const [employmentType, setEmploymentType] = useState(
    EmploymentTypes.CONTRACTUAL
  );
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [startDateValidation, setStartDateValidation] = useState('');
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [endDateValidation, setEndDateValidation] = useState('');
  const [monthlySalary, setMonthlySalary] = useState('');
  const [monthlySalaryValidation, setMonthlySalaryValidation] = useState('');

  const [saveLaborEntryRequestStatus, setSaveLaborEntryRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (laborEntry !== undefined) {
      setEmployeeName(laborEntry.empName);
      setEmploymentType(
        TypesOfEmployment.find((item) => item.key === laborEntry.employmentType)
          ?.key ?? EmploymentTypes.CONTRACTUAL
      );
      setStartDate(moment(laborEntry.startDate));
      setEndDate(laborEntry.endDate ? moment(laborEntry.endDate) : null);
      setMonthlySalary(laborEntry.salaryCost.toString());
    }
  }, []);

  /**
   * @function validateEmployeeName
   * @description Function to validate the Employee Name.
   * @param value string input value
   */
  const validateEmployeeName = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.laborLabels.employeeName'),
        setEmployeeNameValidation
      )
    ) {
      return validateContainsOnlyAlphabets(
        value,
        t('manualEntry.laborLabels.employeeName'),
        setEmployeeNameValidation
      );
    }
  };

  /**
   * @function validateStartDate
   * @description Function to validate the Start Date.
   * @param value string input value
   */
  const validateStartDate = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.laborLabels.startDate'),
      setStartDateValidation
    );
  };

  /**
   * @function validateEndDate
   * @description Function to validate the End Date.
   * @param value string input value
   */
  const validateEndDate = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.laborLabels.endDate'),
      setEndDateValidation
    );
  };

  /**
   * @function validateMonthlySalary
   * @description Function to validate the Cost.
   * @param value string input value
   */
  const validateMonthlySalary = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.laborLabels.monthlySalary'),
        setMonthlySalaryValidation
      )
    ) {
      return isNumber(
        value,
        true,
        t('manualEntry.laborLabels.monthlySalary'),
        setMonthlySalaryValidation
      );
    }

    return false;
  };

  /**
   * @function onClickAdd
   * @description Function to add software row for the entered values
   */
  const onClickAdd = () => {
    if (!validateAllFields()) {
      return;
    }

    setSaveLaborEntryRequestStatus(REQUEST_STATUS.PROCESSING);
    let requestBody: any = {
      dataCenterCode: selectedDataCenter?.dataCenterCode,
      empName: employeeName,
      employmentType: employmentType,
      startDate: moment(startDate)
        .startOf('month')
        .startOf('day')
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      endDate:
        employmentType === EmploymentTypes.CONTRACTUAL
          ? moment(endDate)
              .endOf('month')
              .startOf('day')
              .format(TIMESTAMP_FORMAT_WITHOUT_ZONE)
          : undefined,
      salaryCost: monthlySalary,
    };

    let successMessage = t('manualEntry.laborLabels.saveLaborSuccess');
    let failureMessage = t('manualEntry.laborLabels.saveLaborFailed');

    if (laborEntry !== undefined) {
      requestBody.id = laborEntry.id;

      successMessage = t('manualEntry.laborLabels.updateLaborSuccess');
      failureMessage = t('manualEntry.laborLabels.updateLaborFailed');
    }

    (laborEntry === undefined
      ? saveLaborResource(requestBody)
      : updateLaborResource(requestBody)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setSaveLaborEntryRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success(successMessage);
          onSaveOrUpdateEntry();
          setShow(false);
          return;
        }

        setSaveLaborEntryRequestStatus(REQUEST_STATUS.ERROR);
        setErrorMessage(res?.message ?? failureMessage);
      })
      .catch((e) => {
        onApiCallError(e, false, setSaveLaborEntryRequestStatus);
        setErrorMessage(e?.response?.data?.message ?? failureMessage);
      });
  };

  /**
   * @function validateAllFields
   * @description Function to validate the labor fields
   * @returns boolean true validated else false
   */
  const validateAllFields = () => {
    validateEmployeeName(employeeName);
    validateStartDate(startDate?.toString() ?? '');
    validateEndDate(endDate?.toString() ?? '');
    validateMonthlySalary(monthlySalary);

    return (
      validateEmployeeName(employeeName) &&
      validateStartDate(startDate?.toString() ?? '') &&
      validateMonthlySalary(monthlySalary) &&
      (employmentType === EmploymentTypes.PERMANENT ||
        validateEndDate(endDate?.toString() ?? ''))
    );
  };

  return (
    <DrawerComponent
      className="row-modal"
      width={600}
      open={show}
      onClose={() => setShow(false)}
      title={
        (laborEntry ? t('manualEntry.editRow') : t('manualEntry.addNewRow')) +
        ' - ' +
        t('manualEntry.nav.labor')
      }
      footer={
        <div className="flex flex-column flex-gap-8">
          <span
            style={{
              display: getValidationStyle(errorMessage),
            }}
            className="font-validation-error"
          >
            {errorMessage}
          </span>
          <div className="flex flex-align-items-center flex-end">
            <Button
              title={t('manualEntry.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShow(false)}
            />
            <Button
              title={
                laborEntry === undefined
                  ? t('manualEntry.add')
                  : t('manualEntry.update')
              }
              onClick={onClickAdd}
              loading={
                saveLaborEntryRequestStatus === REQUEST_STATUS.PROCESSING
              }
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-column flex-gap-16">
        <Row>
          <Col span={24}>
            <FormLabel
              title={t('manualEntry.laborLabels.employeeName')}
              required
            />
          </Col>
          <Col span={24}>
            <Input
              type="input"
              value={employeeName}
              placeholder={t('manualEntry.laborLabels.employeeNamePlaceholder')}
              onChange={(e: any) => {
                setEmployeeName(e.target.value);
                validateEmployeeName(e.target.value);
              }}
              onBlur={() => validateEmployeeName(employeeName)}
            />
          </Col>
          <Col span={24}>
            <span
              style={{
                display: `${getValidationStyle(employeeNameValidation)}`,
              }}
              className="font-validation-error"
            >
              {employeeNameValidation}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormLabel
              title={t('manualEntry.laborLabels.employmentType')}
              required
            />
          </Col>
          <Col span={24}>
            <Radio.Group
              onChange={(event) => setEmploymentType(event.target.value)}
              value={employmentType}
            >
              {TypesOfEmployment.map((type) => (
                <Radio value={type.key} key={type.key}>
                  {type.label}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <Divider className="horizontal-divider" />
        <Row>
          <Col span={24}>
            <FormLabel
              title={t('manualEntry.laborLabels.monthlySalary')}
              required
            />
            <Input
              className="full-width"
              type="input"
              prefix={currencySymbol}
              value={monthlySalary}
              placeholder={t(
                'manualEntry.laborLabels.monthlySalaryPlaceholder'
              )}
              onChange={(e: any) => {
                setMonthlySalary(e.target.value);
                validateMonthlySalary(e.target.value);
              }}
              onBlur={() => validateMonthlySalary(monthlySalary)}
            />
            <span
              style={{
                display: `${getValidationStyle(monthlySalaryValidation)}`,
              }}
              className="font-validation-error"
            >
              {monthlySalaryValidation}
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.laborLabels.startDate')}
              required
            />
            <DatePicker
              className="full-width"
              picker="month"
              disabledDate={(current: any) =>
                current && current > moment().endOf('day')
              }
              suffixIcon={
                <Icon
                  iconName={ICONS.ARROW_DOWN_S_LINE}
                  size={ICONS_SIZE.TWO_X}
                />
              }
              pickerType={DATE_PICKER_TYPE.DATE_PICKER}
              format={MONTH_YEAR_FORMAT}
              value={startDate}
              placeholder={t('manualEntry.laborLabels.startDatePlaceholder')}
              onChange={(value: any, date: any) => {
                setStartDate(value);
                validateStartDate(date);
                if (endDate?.isBefore(value, 'months')) {
                  setEndDate(null);
                }
              }}
              onBlur={() => validateStartDate(startDate?.toString() ?? '')}
            />
            <span
              style={{
                display: getValidationStyle(startDateValidation),
              }}
              className="font-validation-error"
            >
              {startDateValidation}
            </span>
          </Col>
          {employmentType === EmploymentTypes.CONTRACTUAL && (
            <Col span={12}>
              <FormLabel
                title={t('manualEntry.laborLabels.endDate')}
                required
              />
              <DatePicker
                className="full-width"
                picker="month"
                disabledDate={(current: any) =>
                  current && current < moment(startDate)
                }
                suffixIcon={
                  <Icon
                    iconName={ICONS.ARROW_DOWN_S_LINE}
                    size={ICONS_SIZE.TWO_X}
                  />
                }
                pickerType={DATE_PICKER_TYPE.DATE_PICKER}
                format={MONTH_YEAR_FORMAT}
                value={endDate}
                placeholder={t('manualEntry.laborLabels.endDatePlaceholder')}
                onChange={(value: any, date: any) => {
                  setEndDate(value);
                  validateEndDate(date);
                }}
                onBlur={() => validateEndDate(endDate?.toString() ?? '')}
              />
              <span
                style={{
                  display: getValidationStyle(endDateValidation),
                }}
                className="font-validation-error"
              >
                {endDateValidation}
              </span>
            </Col>
          )}
        </Row>
      </div>
    </DrawerComponent>
  );
};

export default LaborRowModal;
