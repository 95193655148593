import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getReleaseNotes
 * @description Function to get the release notes
 * @return axios response from GET request
 */
export const getReleaseNotes = async () => {
  return ServiceCalls.get(APIS.RELEASE_NOTES);
};
