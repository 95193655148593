import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import ComparisonCard from 'components/NewComparisonCard';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ComparisonListType } from 'types/dashboard';

import './index.scss';

type SavingsTrackProps = {
  savingsTrackCards: ComparisonListType[];
  pdfView: boolean;
};

const SavingsTrack = ({ savingsTrackCards, pdfView }: SavingsTrackProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  const getComponent = () => (
    <Row gutter={[16, 16]}>
      {savingsTrackCards.map((item, index) => (
        <Col span={8} key={item.heading}>
          <ComparisonCard
            key={item.heading}
            index={index}
            value={item.value}
            heading={item.heading}
            valuePrefix={currencySymbol}
            requestStatus={item.requestStatus}
            errorMessage={item.errorMessage}
          />
        </Col>
      ))}
    </Row>
  );

  return (
    <div className="savings-track graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.savingsTrack')}
        graphName="savings-track"
        isDownloadable={!pdfView}
        showExpandIcon={false}
        isTableViewSwitch={false}
        designVersion2={true}
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={REQUEST_STATUS.SUCCESS}
        />
      </div>
    </div>
  );
};

export default SavingsTrack;
