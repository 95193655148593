import i18n from 'i18n';

import { onApiCallError } from 'utils/handleErrors';

import {
  RULESET_QUICK_ACTION_MENU_ITEMS,
  RulesetQuickActions,
} from './constants';
import { updateRulesetAutoPilotStatus } from './services';
import { RulesetsType } from './types';

/**
 * @function getQuickActionMenuItems
 * @description Returns the list of quick action menu items
 * @param isAutoPilotOn Boolean to indicate whether the autopilot is enabled or not
 */
export const getQuickActionMenuItems = (isAutoPilotOn: boolean) => {
  const quickActionToRemove = isAutoPilotOn
    ? RulesetQuickActions.ACTIVATE
    : RulesetQuickActions.DEACTIVATE;

  return RULESET_QUICK_ACTION_MENU_ITEMS.filter(
    (item) => item.id !== quickActionToRemove
  );
};

/**
 * @function updateTheRulesetAutopilotStatus
 * @description Makes an api call to update the status of the autopilot with given status
 * @param status status to be updated with (ACTIVATE/DEACTIVATE)
 * @param ruleset Ruleset for which the status is updated
 * @param onUpdateSuccess Callback to be called on updating the ruleset
 */
export const updateTheRulesetAutopilotStatus = (
  status: string,
  ruleset: RulesetsType,
  onUpdateSuccess: () => void
) => {
  const requestBody = {
    rulesetId: ruleset.rulesetId,
    autopilotStatus: status,
  };

  updateRulesetAutoPilotStatus(requestBody)
    .then(() => {
      onUpdateSuccess();
    })
    .catch((e) => {
      onApiCallError(
        e,
        true,
        undefined,
        i18n.t('ruleEngineLabels.autoPilotStatusUpdateFailure', {
          rulesetName: ruleset.ruleSetName,
        })
      );
    });
};
