import i18n from 'i18n';

import {
  AreaChart,
  BarLineChart,
  BarStackChart,
  Prohibited,
} from 'assets/icons';
import { ICONS } from 'constants/icons';
import { CHART_TYPES } from 'constants/graphConfig';

import { DASHBOARD_TYPES, QUERY_FIELDS } from './requestBody';
import { PROVIDER } from './cloudProviders';

export const CUSTOM_DASHBOARD_MODE = {
  DEV: 'DEVELOPER',
  PUBLISHED: 'PUBLISHED',
};

export enum MY_DASHBOARD_TYPES {
  SINGLE_CONNECTION = 'SINGLE_CONNECTION',
  GROUP = 'GROUP',
  IMPORTS = 'STATIC_FILE_CONNECTION',
  COST_ALLOCATION = 'COST_ALLOCATION',
  SNOWFLAKE = 'SNOWFLAKE',
}

export const MY_DASHBOARD_TYPES_DATA = [
  {
    type: MY_DASHBOARD_TYPES.SINGLE_CONNECTION,
    label: 'connectionDashboard',
  },
  {
    type: MY_DASHBOARD_TYPES.GROUP,
    label: 'groupsDashboard',
  },
  {
    type: MY_DASHBOARD_TYPES.IMPORTS,
    label: 'importsDashboard',
  },
  {
    type: MY_DASHBOARD_TYPES.SNOWFLAKE,
    label: 'integrationsDashboard',
  },
];

export const DATASET_TYPES = [
  {
    value: DASHBOARD_TYPES.BILLING,
    label: i18n.t('datasetTypes.billing'),
    supportedClouds: [PROVIDER.GCP, PROVIDER.AWS, PROVIDER.AZURE, PROVIDER.OCI],
  },
  {
    value: DASHBOARD_TYPES.RECOMMENDATIONS,
    label: i18n.t('datasetTypes.recommendation'),
    supportedClouds: [PROVIDER.GCP, PROVIDER.AWS, PROVIDER.AZURE],
  },
  {
    value: DASHBOARD_TYPES.CARBON_FOOTPRINT,
    label: i18n.t('datasetTypes.carbonFootprint'),
    supportedClouds: [PROVIDER.GCP],
  },
];

export const CUSTOM_DASHBOARD_TYPES = {
  BILLING_DEFAULT: 'BILLING_DEFAULT',
  RECOMMENDATIONS_DEFAULT: 'RECOMMENDATIONS_DEFAULT',
  CARBON_FOOTPRINT_DEFAULT: 'CARBON_FOOTPRINT_DEFAULT',
  CUSTOM: 'CUSTOM',
};

export enum FIELD_TYPE {
  NUMERIC = 'NUMERIC',
  TIME = 'TIME',
  LITERAL = 'LITERAL',
}

export enum FieldSource {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
  TAGS = 'TAGS',
}

export const FIELD_SOURCE_LABELS = [
  {
    value: FieldSource.SYSTEM,
    label: i18n.t('customDashboard.optionsLabels.system'),
  },
  {
    value: FieldSource.USER,
    label: i18n.t('customDashboard.optionsLabels.user'),
  },
  {
    value: FieldSource.TAGS,
    label: i18n.t('customDashboard.optionsLabels.tags'),
  },
];

export const CHARTS_LIST = [
  {
    icon: Prohibited,
    chartType: CHART_TYPES.TABLE,
    label: i18n.t('customDashboard.optionsLabels.table'),
  },
  {
    iconName: ICONS.PIE_CHART_FILL,
    chartType: CHART_TYPES.PIE_CHART,
    dimensionsCount: {
      min: 1,
      max: 1,
    },
    metricsCount: {
      min: 1,
      max: 1,
    },
    label: i18n.t('customDashboard.optionsLabels.pieChart'),
  },
  {
    iconName: ICONS.DONUT_CHART_FILL,
    chartType: CHART_TYPES.DOUGHNUT_CHART,
    dimensionsCount: {
      min: 1,
      max: 1,
    },
    metricsCount: {
      min: 1,
      max: 1,
    },
    label: i18n.t('customDashboard.optionsLabels.doughnutChart'),
  },
  {
    iconName: ICONS.LINE_CHART_FILL,
    chartType: CHART_TYPES.LINE_CHART,
    dimensionsCount: {
      min: 1,
      max: 1,
    },
    metricsCount: {
      min: 1,
      max: 1,
    },
    label: i18n.t('customDashboard.optionsLabels.lineChart'),
  },
  {
    iconName: ICONS.BAR_CHART_2_FILL,
    chartType: CHART_TYPES.BAR_CHART,
    dimensionsCount: {
      min: 1,
      max: 1,
    },
    metricsCount: {
      min: 1,
      max: 1,
    },
    label: i18n.t('customDashboard.optionsLabels.barChart'),
  },
  {
    icon: BarLineChart,
    chartType: CHART_TYPES.BAR_LINE_CHART,
    dimensionsCount: {
      min: 1,
      max: 1,
    },
    metricsCount: {
      min: 1,
      max: 1,
    },
    label: i18n.t('customDashboard.optionsLabels.barLineChart'),
  },
  {
    iconName: ICONS.BAR_CHART_GROUPED_FILL,
    chartType: CHART_TYPES.GROUPED_CHART,
    dimensionsCount: {
      min: 1,
      max: 1,
    },
    metricsCount: {
      min: 1,
      max: 3,
    },
    label: i18n.t('customDashboard.optionsLabels.groupedBarChart'),
  },
  {
    iconName: ICONS.BAR_CHART_HORIZONTAL_FILL,
    chartType: CHART_TYPES.HORIZONTAL_BAR_CHART,
    dimensionsCount: {
      min: 1,
      max: 1,
    },
    metricsCount: {
      min: 1,
      max: 1,
    },
    label: i18n.t('customDashboard.optionsLabels.horizontalBarChart'),
  },
  {
    icon: BarStackChart,
    chartType: CHART_TYPES.STACK_CHART,
    dimensionsCount: {
      min: 2,
      max: 2,
    },
    metricsCount: {
      min: 1,
      max: 1,
    },
    label: i18n.t('customDashboard.optionsLabels.stackedBarChart'),
  },
  {
    icon: AreaChart,
    chartType: CHART_TYPES.AREA_CHART,
    dimensionsCount: {
      min: 1,
      max: 1,
    },
    metricsCount: {
      min: 1,
      max: 1,
    },
    label: i18n.t('customDashboard.optionsLabels.areaChart'),
  },
];

export enum TagSelectionType {
  KEY = 'KEY',
  VALUE = 'VALUE',
}

export enum ChartCriteria {
  TIME_PERIOD = 'TIME_PERIOD',
  SERVICE = 'SERVICE',
  PROJECT = 'PROJECT',
  COMPARTMENT = 'COMPARTMENT',
  REGION = 'REGION',
  RESOURCE = 'RESOURCE',
  RESOURCE_GROUP = 'RESOURCE_GROUP',
  ACCOUNT = 'ACCOUNT',
  RECOMMENDATION = 'RECOMMENDATION',
  SAVINGS_PLAN = 'SAVINGS_PLAN',
  SPEND_PROFILE = 'SPEND_PROFILE',
}

export enum ChartFilterType {
  DROPDOWN = 'DROPDOWN',
  SINGLE_SELECT_DROPDOWN = 'SINGLE_SELECT_DROPDOWN',
  DATE_RANGE = 'DATE_RANGE',
}

export const CSP_TO_FOCUS_FIELD_MAP: { [key: string]: string } = {
  [QUERY_FIELDS.BILLING_RESOURCE_NAME]: 'ResourceName',
  [QUERY_FIELDS.COST]: 'BilledCost',
  [QUERY_FIELDS.LOCATION_REGION]: 'RegionId',
  [QUERY_FIELDS.USAGE_START_TIME]: 'ChargePeriodStart',
  [QUERY_FIELDS.USAGE_END_TIME]: 'ChargePeriodEnd',
  [QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT]: 'BilledCost',
  [QUERY_FIELDS.INVOICE_MONTH]: 'BillingPeriodStart',
  [QUERY_FIELDS.SERVICE_DESCRIPTION]: 'ServiceCategory',
  [QUERY_FIELDS.CREDIT_AMOUNT]: 'EffectiveCost*-1',
  [QUERY_FIELDS.COST_METRICS_TYPE]: 'ChargeCategory',
  [QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE]: 'BillingPeriodStart',
  [QUERY_FIELDS.BILL_BILL_TYPE]: 'ChargeFrequency',
  [QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE]: 'ChargePeriodStart',
  [QUERY_FIELDS.LINE_ITEM_USAGE_END_DATE]: 'ChargePeriodEnd',
  [QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID]: 'SubAccountId',
  [QUERY_FIELDS.LINE_ITEM_LINE_ITEM_TYPE]: 'ChargeCategory',
  [QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST]: 'BilledCost',
  [QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD]: 'BillingPeriodStart',
  [QUERY_FIELDS.PRODUCT_SERVICENAME]: 'ServiceName',
  [QUERY_FIELDS.PRODUCT_REGION]: 'RegionId',
  [QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE]: 'ServiceCategory',
  [QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE]:
    'BillingPeriodStart',
  [QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_END_DATE]:
    'BillingPeriodEnd',
  [QUERY_FIELDS.COST_BILLING_CURRENCY]: 'BilledCost',
  [QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE]:
    'CONCAT(EXTRACT(YEAR from BillingPeriodStart),EXTRACT(MONTH from BillingPeriodStart))',
  [QUERY_FIELDS.BILLING_PERIOD_START_DATE]: 'BillingPeriodStart',
  [QUERY_FIELDS.BILLING_PERIOD_END_DATE]: 'BillingPeriodEnd',
  [QUERY_FIELDS.RESOURCE_NAME]: 'ResourceName',
  [QUERY_FIELDS.RESOURCE_LOCATION]: 'RegionId',
  [QUERY_FIELDS.CONSUMED_SERVICE]: 'ServiceName',
  [QUERY_FIELDS.RESOURCE_ID]: 'ResourceId',
  [QUERY_FIELDS.SUBSCRIPTION_NAME]: 'SubAccountName',
  [QUERY_FIELDS.PRICING_MODEL]: 'PricingCategory',
  [QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE]: 'ChargePeriodEnd',
  [QUERY_FIELDS.BILL_PAYER_ACCOUNT_ID]: 'BillingAccountId',
  [QUERY_FIELDS.LINE_ITEM_RESOURCE_ID]: 'ResourceId',
  [QUERY_FIELDS.PRICING_UNIT]: 'PricingUnit',
};
