import { useCallback } from 'react';
import { Pie } from '@ant-design/plots';
import { sum } from 'lodash';
import { useSelector } from 'react-redux';

import { COLORS } from 'constants/graphConfig';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { generateGraphColors } from 'utils/dashboardUtils';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import TooltipContent from 'components/TooltipContent';

type PieDonutChartProps = {
  data: any[];
  angleField: string;
  colorField: string;
  isDonut?: boolean;
  disableAnimation?: boolean;
  additionalClassNames?: string;
  colorOverride?: string[];
  showAllLegend?: boolean;
  showLegends?: boolean;
  tooltipOverride?: any;
  prefixSymbol?: string;
  suffixSymbol?: string;
  statisticTitle?: string;
  statisticValueFormatter?: Function;
  legendFormatter?: (text: string) => string;
  showCurrencySymbol?: boolean;
  suffixText?: string;
  radius?: number;
  label?: Object | boolean;
  height?: number;
};

const PieDonutChart = ({
  data,
  angleField,
  colorField,
  isDonut = false,
  disableAnimation = false,
  additionalClassNames = '',
  colorOverride,
  showAllLegend,
  showLegends = true,
  tooltipOverride,
  prefixSymbol = '',
  suffixSymbol = '',
  statisticTitle,
  statisticValueFormatter,
  legendFormatter,
  showCurrencySymbol = true,
  suffixText,
  radius = 0.9,
  label = false,
  height,
}: PieDonutChartProps) => {
  const { currencySymbol } = useSelector(selectCommonUtility);

  const getTooltipCustomContent = useCallback(
    (title: string, data: any[]) => (
      <TooltipContent
        title={title}
        data={data}
        prefixSymbol={prefixSymbol}
        postfixSymbol={suffixSymbol}
      />
    ),
    []
  );

  const getTooltipCustomItems = useCallback(
    (originalItems: any[]) =>
      originalItems.map((item) => ({
        ...item,
        value: numberCommaSeparator(item.value),
      })),
    []
  );

  const getInteractions = useCallback(() => {
    const interactions = [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ];
    if (isDonut) {
      interactions.push({
        type: 'pie-statistic-active',
      });
    }

    return interactions;
  }, []);

  const getStatisticContent = useCallback((value: any) => {
    return (
      (showCurrencySymbol ? currencySymbol : '') +
      (value?.[angleField]
        ? numberCommaSeparator(value[angleField])
        : numberCommaSeparator(sum(data.map((item) => item[angleField])))) +
      (suffixText ? ` ${suffixText}` : '')
    );
  }, []);

  // TODO: add PieConfig (from @ant-design/plots) type to this config for better typescript support
  const config: any = {
    data: data.map((item) => ({
      ...item,
      [angleField]: Number(item[angleField]),
    })),
    angleField: angleField,
    colorField: colorField,
    radius: radius,
    innerRadius: isDonut ? 0.65 : 0,
    legend: showLegends
      ? {
          position: showAllLegend ? 'bottom' : 'right',
          flipPage: !showAllLegend,
          pageNavigator: {
            marker: {
              style: {
                fill: COLORS.primaryButton,
                size: 8,
              },
            },
            text: {
              style: {
                fill: COLORS.secondaryBlack,
                fontSize: 12,
              },
            },
          },
          itemName: {
            formatter: legendFormatter,
          },
        }
      : false,
    label: label,
    color: colorOverride ?? generateGraphColors(data.length),
    tooltip: tooltipOverride
      ? { ...tooltipOverride, customContent: getTooltipCustomContent }
      : {
          customItems: getTooltipCustomItems,
          customContent: getTooltipCustomContent,
        },
    statistic: {
      title: {
        content: statisticTitle,
        style: {
          fontSize: '12px',
          fontWeight: 400,
          color: COLORS.fnGrey5,
        },
      },
      content: {
        formatter: statisticValueFormatter ?? getStatisticContent,
        style: {
          fontSize: '14px',
          fontWeight: 600,
          whiteSpace: 'pre-line',
        },
      },
    },
    interactions: getInteractions(),
    animation: !disableAnimation,
    autoFit: true,
    limitInPlot: true,
    height,
  };

  return (
    <Pie
      className={additionalClassNames}
      {...config}
      data-testid="pie-donut-chart"
    />
  );
};

export default PieDonutChart;
