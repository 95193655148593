import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SelectDropdown from 'components/Select';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import DropdownCheckbox from 'components/DropdownCheckbox';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import { fetchAllAWSBillingServices } from 'pages/BudgetsAndAlertsPage/services';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { getAllMonthNames, getComingYearsList } from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';

import {
  BUDGET_RENEWAL_TYPES,
  TIME_RANGES,
  YEARS_LIMIT,
} from '../SetupBudgetDetails/constants';

const ScopeForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { budgetData, isEdit } = useSelector(budgetsAndAlerts);

  const [budgetNameValidation, setBudgetNameValidation] = useState('');
  const [budgetRangeReqValidation, setBudgetRangeReqValidation] = useState('');
  const [budgetStartMonthValidation, setBudgetStartMonthValidation] =
    useState('');
  const [allServices, setAllServices] = useState<string[]>([]);
  const [servicesLoading, setServicesLoading] = useState<string>('');
  const [servicesReqValidation, setServicesReqValidation] = useState('');

  useEffect(() => {
    fetchBudgetServices();
  }, [budgetData.connectorId]);

  useEffect(() => {
    if (
      moment().isAfter(`${budgetData.selectedYear}${budgetData.selectedMonth}`)
    ) {
      setBudgetStartMonthValidation(
        t('addBudgetAlert.createBudgetLabels.scope.startMonthPast')
      );
    } else {
      setBudgetStartMonthValidation('');
    }
  }, [budgetData.selectedMonth, budgetData.selectedYear]);

  /**
   * @function fetchBudgetServices
   * @description Function to fetch the list of AWS services
   */
  const fetchBudgetServices = () => {
    setServicesLoading(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: budgetData.connectorId,
    };
    fetchAllAWSBillingServices(params)
      .then((res: any) => {
        setAllServices(res.data.responseData);
        setServicesLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setServicesLoading);
      });
  };

  /**
   * @function validateBudgetName
   * @description Function to validate the budget name
   * @param value value to be validated
   * @return boolean true if the validation is successful else false
   */
  const validateBudgetName = (value: string) => {
    if (
      validateEmptyField(
        value,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    if (
      validateStringLengthLessThan(
        value,
        MAX_CHARACTER_LIMIT,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    return true;
  };

  /**
   * @function validateListsRequired
   * @description Function to validate the required list of values
   * @param list list to be validated
   * @param setValidation function to set the validation message
   * @param validationMessage validation message to be set
   * @returns boolean true if the validation is successful else false
   */
  const validateListsRequired = (
    list: any,
    setValidation: (val: string) => void,
    validationMessage: string
  ) => (list.length > 0 ? setValidation('') : setValidation(validationMessage));

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.name')}
          required={true}
        />
        <Input
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.namePlaceholder'
          )}
          value={budgetData.displayName}
          disabled={isEdit}
          onChange={(e: any) => {
            validateBudgetName(e.target.value);
            dispatch(
              setBudgetData({
                ...budgetData,
                displayName: e.target.value,
              })
            );
          }}
          onBlur={(e: any) => validateBudgetName(e.target.value)}
        />
        <span
          style={{
            display: `${getValidationStyle(budgetNameValidation)}`,
          }}
          className="font-validation-error"
        >
          {budgetNameValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.timeRange')}
          required={true}
        />
        <SelectDropdown
          value={budgetData.timeRange}
          options={TIME_RANGES}
          onChange={(value: string) => {
            dispatch(setBudgetData({ ...budgetData, timeRange: value }));
            setBudgetRangeReqValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              budgetData.timeRange,
              t('addBudgetAlert.createBudgetLabels.scope.timeRange'),
              setBudgetRangeReqValidation,
              t('addBudgetAlert.createBudgetLabels.scope.timeRangeRequired')
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${getValidationStyle(budgetRangeReqValidation)}`,
          }}
          className="font-validation-error"
        >
          {budgetRangeReqValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.budgetRenewalType')}
          required={true}
        />
        <SelectDropdown
          value={budgetData.budgetRenewalType}
          options={BUDGET_RENEWAL_TYPES}
          onChange={(value: string) =>
            dispatch(setBudgetData({ ...budgetData, budgetRenewalType: value }))
          }
          designVersion2
        />
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.startMonth')}
          required={true}
        />
        <div className="flex flex-align-items-center flex-gap-8 month-start-field">
          <SelectDropdown
            options={getAllMonthNames().map((month) => ({
              value: month,
              label: month,
            }))}
            value={budgetData.selectedMonth}
            className="partial-dropdown width-15"
            onChange={(value: string) =>
              dispatch(setBudgetData({ ...budgetData, selectedMonth: value }))
            }
            designVersion2
          />
          <SelectDropdown
            options={getComingYearsList(YEARS_LIMIT).map((year) => ({
              value: year,
              label: year,
            }))}
            value={budgetData.selectedYear}
            className="partial-dropdown width-15"
            onChange={(value: string) =>
              dispatch(setBudgetData({ ...budgetData, selectedYear: value }))
            }
            designVersion2
          />
        </div>
        <span
          style={{
            display: `${getValidationStyle(budgetStartMonthValidation)}`,
          }}
          className="font-validation-error"
        >
          {budgetStartMonthValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.services')}
          required={true}
        />
        <DropdownCheckbox
          loading={servicesLoading === REQUEST_STATUS.PROCESSING}
          itemOptions={allServices.map((service) => ({
            value: service,
            title: service,
          }))}
          value={budgetData.services}
          selectedItems={budgetData.services}
          setSelectedItems={(list: string[]) =>
            dispatch(setBudgetData({ ...budgetData, services: list }))
          }
          onHandleChange={(list: any) =>
            validateListsRequired(
              list,
              setServicesReqValidation,
              t('addBudgetAlert.createBudgetLabels.scope.servicesRequired')
            )
          }
          onHandleBlur={() =>
            validateListsRequired(
              budgetData.services,
              setServicesReqValidation,
              t('addBudgetAlert.createBudgetLabels.scope.servicesRequired')
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${getValidationStyle(servicesReqValidation)}`,
          }}
          className="font-validation-error"
        >
          {servicesReqValidation}
        </span>
      </div>
    </div>
  );
};

export default ScopeForm;
