import { useSelector } from 'react-redux';
import { selectDashboard } from 'redux/dashboardSlice';
import { PROVIDER } from 'constants/cloudProviders';
import GCPCarbonFootprintDashboard from './components/GCPCarbonFootprintDashboard';

const CarbonFootprintDashboard = () => {
  const { selectedDashboard } = useSelector(selectDashboard);

  /**
   * @function getCarbonFootprintDashboard
   * @description Function to get the carbon footprint dashboard component based on the provider
   * @return Funciton will return corresponding carbon footprint dashboard for selected provider
   */
  const getCarbonFootprintDashboard = () => {
    switch (selectedDashboard?.connectorProvider) {
      case PROVIDER.GCP:
        return <GCPCarbonFootprintDashboard />;

      case PROVIDER.AWS:
        return <>Coming soon</>;

      case PROVIDER.AZURE:
        return <>Coming soon</>;

      default:
        return <GCPCarbonFootprintDashboard />;
    }
  };

  return getCarbonFootprintDashboard();
};

export default CarbonFootprintDashboard;
