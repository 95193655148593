import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NavigationPath from 'components/NavigationPath';
import Button from 'components/Button';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { REQUEST_STATUS } from 'constants/requestBody';
import { message } from 'antd';
import { onApiCallError } from 'utils/handleErrors';

import FocusConversionToggle from './components/FocusConversionToggle';
import ChatBotLLMsControls from './components/ChatBotLLMsControls';
import { AdminControlsType, AnyAdminCOntrolsType } from './types';
import { fetchAdminControls, updateAdminControls } from './services';

import './index.scss';

const AdminControlsPage = () => {
  const { t } = useTranslation();

  const [adminControls, setAdminControls] = useState<AdminControlsType>({
    focusConversionEnabled: false,
    genAIModels: [],
  });
  const [fetchAdminControlsRequestStatus, setFetchAdminControlsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [
    updateAdminControlsRequestStatus,
    setUpdateAdminControlsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    addZeroMarginClass();
    fetchAllAdminControls();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function fetchAllAdminControls
   * @description Fetches the admin controls settings
   */
  const fetchAllAdminControls = () => {
    setFetchAdminControlsRequestStatus(REQUEST_STATUS.PROCESSING);

    fetchAdminControls()
      .then((res: any) => {
        if (res.status === 200) {
          setAdminControls(res.data);
          setFetchAdminControlsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }

        setFetchAdminControlsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((error) => {
        onApiCallError(error, false, setFetchAdminControlsRequestStatus);
      });
  };

  /**
   * @function onClickUpdateAdminControls
   * @description Calls an API to update the Admin controls settings
   */
  const onClickUpdateAdminControls = () => {
    setUpdateAdminControlsRequestStatus(REQUEST_STATUS.PROCESSING);

    updateAdminControls(adminControls)
      .then((res: any) => {
        if (res.status === 200) {
          message.success(t('settingsUpdateSuccessMessage'));
          setUpdateAdminControlsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }

        message.error(t('settingsUpdateFailureMessage'));
        setUpdateAdminControlsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((error) => {
        onApiCallError(
          error,
          true,
          setUpdateAdminControlsRequestStatus,
          t('settingsUpdateFailureMessage')
        );
      });
  };

  /**
   * @function onChangeAdminControls
   * @description Updates the admin controls state with the value for the given field
   * @param field Field name to be updated
   * @param value Value to be updated for the given field name
   */
  const onChangeAdminControls = (
    field: string,
    value: AnyAdminCOntrolsType
  ) => {
    setAdminControls({ ...adminControls, [field]: value });
  };

  return (
    <div className="admin-controls flex flex-column flex-fit">
      <header className="admin-controls-header new-page-header modal-heading flex flex-align-items-center flex-space-between">
        {t('navigationMenu.adminControls')}
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <section className="controls-container flex flex-column flex-gap-16">
          <div className="controls-list flex flex-column flex-gap-24">
            <FocusConversionToggle
              isFocusConversionEnabled={adminControls.focusConversionEnabled}
              onChangeAdminControls={onChangeAdminControls}
              requestStatus={fetchAdminControlsRequestStatus}
            />
            <ChatBotLLMsControls
              selectedChatBotdLLMs={adminControls.genAIModels}
              setSelectedModels={onChangeAdminControls}
            />
          </div>
          <div className="flex flex-align-items-center flex-gap-16 full-width">
            {fetchAdminControlsRequestStatus === REQUEST_STATUS.ERROR && (
              <div className="font-validation-error">
                {t('errorFetchingSettings')}
              </div>
            )}
            <Button
              className="update-cta"
              title={t('update')}
              onClick={onClickUpdateAdminControls}
              loading={
                updateAdminControlsRequestStatus === REQUEST_STATUS.PROCESSING
              }
              disabled={[
                fetchAdminControlsRequestStatus,
                updateAdminControlsRequestStatus,
              ].includes(REQUEST_STATUS.PROCESSING)}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdminControlsPage;
