import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { FormLabel } from 'components/FormLabel';
import Icon from 'components/Icon';
import Switch from 'components/Switch';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { STATIC_COLORS_FOR_CHART } from 'pages/AppearancePage/constants';
import { getValidationStyle } from 'utils/validations';
import { ManualChartColorsType } from 'pages/AppearancePage/types';

import ColorDisc from '../ColorDisc';

type ChartAppearanceProps = {
  autoGenerateColors: boolean;
  setAutoGenerateColors: (value: boolean) => void;
  manualColors: ManualChartColorsType[];
  setManualColors: (value: ManualChartColorsType[]) => void;
  manualColorsValidation: string;
  setManualColorsValidation: (value: string) => void;
};

const ChartAppearance = ({
  autoGenerateColors,
  setAutoGenerateColors,
  manualColors,
  setManualColors,
  manualColorsValidation,
  setManualColorsValidation,
}: ChartAppearanceProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'appearance' });

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="form-item flex flex-space-between">
        <FormLabel title={t('autoGenerateColors')} />
        <Switch
          size="small"
          checked={autoGenerateColors}
          onChange={(value: boolean) => {
            setAutoGenerateColors(value);
          }}
        />
      </div>
      {!autoGenerateColors && (
        <div className="form-item flex flex-column flex-align-items-start">
          <FormLabel title={t('addColorManually')} />
          <div className="flex flex-align-items-start flex-gap-4">
            {manualColors.map((colorObj) => (
              <ColorDisc
                key={colorObj.hexCode}
                color={colorObj.hexCode}
                className="color-disc-large cursor-pointer"
                iconName={ICONS.DELETE_BIN_LINE}
                showIconOnHover={true}
                onClick={() => {
                  if (
                    !manualColors.some(
                      (color) => color.hexCode === colorObj.hexCode
                    )
                  ) {
                    setManualColors([...manualColors, colorObj]);
                    return;
                  }
                  setManualColors(
                    manualColors.filter(
                      (color) => color.hexCode !== colorObj.hexCode
                    )
                  );
                }}
              />
            ))}
            <Popover
              placement="bottomLeft"
              trigger="click"
              overlayClassName="color-picker-popover static-colors-popover"
              content={
                <div className="flex flex-column flex-gap-8">
                  <span className="font-caption-bold">{t('addNewColors')}</span>
                  <div className="static-color-group flex flex-gap-8 flex-wrap">
                    {STATIC_COLORS_FOR_CHART.map((colorObj, index) => (
                      <ColorDisc
                        key={colorObj.hexCode}
                        color={colorObj.hexCode}
                        className="color-disc-large cursor-pointer"
                        iconName={ICONS.CHECK_LINE}
                        showIcon={manualColors.some(
                          (color) => color.hexCode === colorObj.hexCode
                        )}
                        iconSize={ICONS_SIZE.ONE_X}
                        onClick={() => {
                          if (
                            !manualColors.some(
                              (color) => color.hexCode === colorObj.hexCode
                            )
                          ) {
                            manualColors.length >= 4 &&
                              setManualColorsValidation('');
                            setManualColors([...manualColors, colorObj]);
                            return;
                          }
                          setManualColors(
                            manualColors.filter(
                              (color) => color.hexCode !== colorObj.hexCode
                            )
                          );
                        }}
                      />
                    ))}
                  </div>
                </div>
              }
            >
              <Icon
                iconName={ICONS.ADD_LINE}
                size={ICONS_SIZE.SM}
                className="color-disc-large cursor-pointer flex flex-center"
              />
            </Popover>
          </div>
          <span
            style={{
              display: `${getValidationStyle(manualColorsValidation)}`,
            }}
            className="font-validation-error"
          >
            {manualColorsValidation}
          </span>
        </div>
      )}
    </div>
  );
};

export default ChartAppearance;
