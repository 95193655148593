import { message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectGranulateConnection,
  setConnectionList,
} from 'redux/granulateConnectionSlice';
import GranulateAppsTable from 'pages/ThirdPartyPage/GranulateAppsComponents/GranulateAppsTable';
import { getStatusString } from 'pages/ThirdPartyPage/GranulateAppsComponents/utils';
import QuickActionMenu from 'components/QuickActionMenu';
import Icon from 'components/Icon';
import { REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { StatusEnum } from 'constants/granulate';
import { ICONS } from 'constants/icons';
import { REFRESH_INTERVAL_30000 } from 'constants/defaultValues';
import { PROVIDER } from 'constants/cloudProviders';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { onApiCallError } from 'utils/handleErrors';
import { DD_MMM_YYYY } from 'utils/date';
import { getGranulateConnections } from 'utils/services';
import { ConnectionList } from 'types/gProfiler';
import {
  getGranulateDashboardServices,
  uninstallAWSGranulate,
  uninstallGCPGranulate,
} from '../services';

import {
  GranulateQuickActions,
  GRANULATE_CONNECTION_QUICKACTIONS,
} from './constants';

const GranulateConnectionTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { connectionList } = useSelector(selectGranulateConnection);

  const [requestStatus, setRequestStatus] = useState<string>(
    REQUEST_STATUS.PROCESSING
  );
  const [showActivateConfirmModal, setShowActivateConfirmModal] =
    useState(false);
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUninstallModal, setShowUninstallModal] = useState(false);
  const [actionRecord, setActionRecord] = useState<ConnectionList>();
  const [uninstallStatus, setUninstallStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  /**
   * @function fetchDashboardServices
   * @description Function to fetch Granulate Dashboard services
   * @param addLoader boolean to indicate whether to add the loader or not
   */
  const fetchDashboardServices = (addLoader: boolean = true) => {
    addLoader && setRequestStatus(REQUEST_STATUS.PROCESSING);
    getGranulateDashboardServices()
      .then((res: any) => {
        if (res.status === 200) {
          const list = res.data.response.map((item: any, index: number) => {
            return {
              key: (index + 1).toString(),
              connectionName: item.connectionName,
              provider: item.cloud,
              createdDate: moment(item.creationDate).format(DD_MMM_YYYY),
              connectionStatus: getStatusString(item.status),
              serviceName: item.serviceName,
              serviceDisplayName: item.serviceDisplayName,
              percentage: item.percentage,
            };
          });
          dispatch(setConnectionList(list));
          setRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  /**
   * @function updateGranulateServiceStatus
   * @description Function to update the granulate connection service status
   * @param status status to be updated to.
   */
  const updateGranulateServiceStatus = (status: string) => {
    setShowDeleteModal(false);
  };

  /**
   * @function handleDelete
   * @description Function to handle the delete connection
   */
  const handleDelete = () => {
    setShowDeleteModal(false);
    fetchDashboardServices();
  };

  const getQuickActionsMenu = (record: any) => {
    const actionList = GRANULATE_CONNECTION_QUICKACTIONS.filter(
      (item) =>
        ![
          GranulateQuickActions.ACTIVATE,
          GranulateQuickActions.DEACTIVATE,
          GranulateQuickActions.EDIT,
          GranulateQuickActions.DELETE,
          GranulateQuickActions.UNINSTALL,
        ].includes(item.id)
    );

    if (+record.percentage === 100) {
      actionList.push(
        ...GRANULATE_CONNECTION_QUICKACTIONS.filter(
          (item) => item.id === GranulateQuickActions.UNINSTALL
        )
      );
    }

    return actionList.reverse();
  };

  const handleQuickAction = (action: any, record: any) => {
    setActionRecord(record);
    switch (action) {
      case GranulateQuickActions.ACTIVATE:
        setShowActivateConfirmModal(true);
        break;
      case GranulateQuickActions.DEACTIVATE:
        setShowDeactivateConfirmModal(true);
        break;
      case GranulateQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
      case GranulateQuickActions.UNINSTALL:
        setShowUninstallModal(true);
        break;
    }
  };

  /**
   * @function onUninstallUpdateError
   * @description Function to handle the error scenario while uninstalling
   * @param errorMessage Optional message to display when error.
   */
  const onUninstallUpdateError = (errorMessage?: string) => {
    onApiCallError(
      errorMessage ?? t('granulateConnectionPage.uninstallFail'),
      true,
      setUninstallStatus
    );
    setShowUninstallModal(false);
  };

  /**
   * @function getConnections
   * @description Function to get Connection list of granulate
   * @returns list of connections
   */
  const getConnections = async () => {
    try {
      const res: any = await getGranulateConnections();
      if (res.status === 200) {
        return res.data;
      } else {
        onUninstallUpdateError(res?.data?.message);
      }
    } catch (err: any) {
      onUninstallUpdateError(err);
    }
  };

  /**
   * @function onUninstallation
   * @description Function to handle Uninstallation of AWS and GCP
   * @param body contains request body or request params
   * @param apiCall Api call function
   */
  const onUninstallation = (
    body: any,
    apiCall: (body: any) => Promise<unknown>
  ) => {
    apiCall(body)
      .then((res: any) => {
        if (res.status === 200) {
          message.success({
            content: t('granulateConnectionPage.uninstallSuccess'),
            key: SUCCESS_KEY,
          });
          setUninstallStatus(REQUEST_STATUS.SUCCESS);
          setShowUninstallModal(false);
          fetchDashboardServices();
        } else {
          onApiCallError(res?.data?.message, false, setUninstallStatus);
        }
      })
      .catch((err: any) => {
        onUninstallUpdateError(err);
      });
  };

  /**
   * @function handleUninstallation
   * @description Function to handle uninstallation of sAgent
   */
  const handleUninstallation = async () => {
    if (actionRecord) {
      setUninstallStatus(REQUEST_STATUS.PROCESSING);

      try {
        const connectionList = await getConnections();
        const connection = connectionList.find((item: any) =>
          item.serviceClusterMapList.some(
            (service: any) => service.serviceName === actionRecord.serviceName
          )
        );

        if (connection) {
          const { connectorId } = connection;
          const { region, clusterName, userEmail, zone } =
            connection.serviceClusterMapList[0].clusterRegions[0];

          if (actionRecord.provider === PROVIDER.AWS) {
            const body = {
              cloudConnectionName: actionRecord.connectionName,
              serviceName: actionRecord.serviceDisplayName,
              clusterName,
              region,
              userEmail,
              connectorId,
            };
            onUninstallation(body, uninstallAWSGranulate);
          } else if (actionRecord.provider === PROVIDER.GCP) {
            const body = {
              cloudConnectionName: actionRecord.connectionName,
              serviceName: actionRecord.serviceDisplayName,
              clusterName,
              zone,
              userEmail,
              connectorId,
            };
            onUninstallation(body, uninstallGCPGranulate);
          }
        } else {
          onUninstallUpdateError();
        }
      } catch (err: any) {
        onUninstallUpdateError(err);
      }
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: t('granulateConnectionPage.connectionName'),
      dataIndex: 'connectionName',
      key: 'connectionName',
      render: (text: any, record: any, _index: number) => {
        return (
          <div className="connection flex flex-align-items-end">
            <img
              src={getProviderSmallLogo(record.provider)}
              alt={`${record.provider}-logo`}
            />
            <span className="font-caption-bold">{text}</span>
          </div>
        );
      },
    },
    {
      title: t('granulateConnectionPage.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text: any) => <span className="date">{text}</span>,
    },
    {
      title: t('granulateConnectionPage.connectionStatus'),
      key: 'connectionStatus',
      dataIndex: 'connectionStatus',
      render: (text: any) => {
        return (
          <div
            className={`status status-${text} font-small-bold ${
              text === getStatusString(StatusEnum.ONGOING) &&
              'flex flex-align-items-center flex-gap-4'
            }`}
          >
            {text === getStatusString(StatusEnum.ONGOING) && (
              <Icon iconName={ICONS.INSTALLING_LINE} />
            )}
            {text}
          </div>
        );
      },
    },
    {
      title: t('granulateConnectionPage.action'),
      key: 'action',
      align: 'center',
      render: (_text: string, record: any) => (
        <QuickActionMenu
          quickActions={getQuickActionsMenu(record)}
          quickActionHandler={(action) => handleQuickAction(action, record)}
          disabled={
            record.connectionStatus ===
              getStatusString(StatusEnum.UNINSTALLED) ||
            +record.percentage !== 100
          }
        />
      ),
    },
  ];

  return (
    <GranulateAppsTable
      connectionList={connectionList}
      columns={columns}
      requestStatus={requestStatus}
      fetchDashboardServices={fetchDashboardServices}
      actionRecord={actionRecord}
      updateConnectionStatus={updateGranulateServiceStatus}
      showActivateConfirmModal={showActivateConfirmModal}
      showDeactivateConfirmModal={showDeactivateConfirmModal}
      showDeleteModal={showDeleteModal}
      setShowActivateConfirmModal={setShowActivateConfirmModal}
      setShowDeactivateConfirmModal={setShowDeactivateConfirmModal}
      setShowDeleteModal={setShowDeleteModal}
      handleDelete={handleDelete}
      refreshInterval={REFRESH_INTERVAL_30000}
      showUninstallModal={showUninstallModal}
      setShowUninstallModal={setShowUninstallModal}
      handleUninstallation={handleUninstallation}
      loadUninstall={uninstallStatus === REQUEST_STATUS.PROCESSING}
    />
  );
};

export default GranulateConnectionTable;
