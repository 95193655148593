import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface ProviderSlice {
  selectedProvider: string;
}

const initialState: ProviderSlice = {
  selectedProvider: '',
};

export const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    setSelectedProvider: (state, action: PayloadAction<string>) => {
      state.selectedProvider = action.payload;
    },
  },
});

export const providerList = (state: RootState) => state.provider;
export const { setSelectedProvider } = providerSlice.actions;
export default providerSlice.reducer;
