import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  selectCostAllocation,
  setCostAllocationData,
  setCurrentStepValidation,
} from 'redux/costAllocationSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import Button from 'components/Button';
import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import { VALIDATION_STATUS } from 'pages/ConnectingCSPPage/constants';

import CostCenterDrawer from '../CostCenterDrawer';
import { BusinessUnitType } from '../types';
import { BUSINESS_UNIT_QUICKACTIONS } from './constants';

const SetupBusinessUnit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { costAllocationData, totalSharedCost } =
    useSelector(selectCostAllocation);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [pendingSharedCost, setPendingSharedCost] = useState<number>();
  const [showCostCenterModal, setShowCostCenterModal] = useState(false);
  const [isEditBusinessUnit, setIsEditBusinessUnit] = useState(false);
  const [businessUnitIndex, setBusinessUnitIndex] = useState<number>(-1);
  const [selectedRows, setSelectedRows] = useState<BusinessUnitType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    let totalCost = 0;
    if (costAllocationData && costAllocationData.businessUnits?.length > 0) {
      costAllocationData?.businessUnits?.forEach(
        (businessUnit) => (totalCost += businessUnit.cost)
      );
    }
    setPendingSharedCost(Number((totalSharedCost - totalCost).toFixed(2)));
  }, [costAllocationData]);

  useEffect(() => {
    dispatch(setCurrentStepValidation(validateBusinessUnitsStep()));
  }, [costAllocationData.businessUnits, pendingSharedCost]);

  /**
   * @function validateBusinessUnitsStep
   * @description Function to validate the business units step
   * @returns string validation status (VALID, INVALID or SKIP)
   */
  const validateBusinessUnitsStep = () => {
    if (pendingSharedCost !== 0) {
      return VALIDATION_STATUS.INVALID;
    }

    // Sum of all the share of all the cost centers in a business unit must be 100%
    if (
      costAllocationData?.businessUnits?.some(
        (businessUnit) =>
          businessUnit?.costCenters?.reduce(
            (sum, costCenter) => sum + costCenter.share,
            0
          ) !== 100
      )
    ) {
      return VALIDATION_STATUS.INVALID;
    }

    // Sum of all the share of all the business units must be 100%
    if (
      costAllocationData?.businessUnits?.reduce(
        (sum, businessUnit) => sum + businessUnit.share,
        0
      ) !== 100
    ) {
      return VALIDATION_STATUS.INVALID;
    }

    return VALIDATION_STATUS.VALID;
  };

  const columns = [
    {
      title: t('costAllocations.setupBusinessUnit.businessUnitCode'),
      dataIndex: 'businessUnitCode',
      key: 'businessUnitCode',
      width: '30%',
    },
    {
      title: t('costAllocations.setupBusinessUnit.share'),
      dataIndex: 'share',
      key: 'share',
      width: '15%',
      render: (text: any) => text + '%',
    },
    {
      title: t('costAllocations.setupBusinessUnit.cost'),
      dataIndex: 'cost',
      key: 'cost',
      width: '10%',
      render: (text: any) => currencySymbol + Number(text).toFixed(2),
    },
    {
      title: t('costAllocations.setupBusinessUnit.pendingCostCenter', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'pendingCostCenter',
      key: 'pendingCostCenter',
      width: '30%',
      render: (text: any) => (
        <div className={`${Number(text) > 0 && 'pending-cost'}`}>
          {Number(text) > 0
            ? `-${currencySymbol}${Number(text).toFixed(2)}`
            : `${currencySymbol}${Number(text).toFixed(2)}`}
        </div>
      ),
    },
    {
      title: t('costAllocations.setupBusinessUnit.action'),
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_text: any, _record: any, index: number) => (
        <QuickActionMenu
          quickActions={BUSINESS_UNIT_QUICKACTIONS}
          quickActionHandler={(_action: string) => {
            onClickUpdateBusinessUnit(index);
          }}
        />
      ),
    },
  ];

  const costCenterColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, record: any) => record.key + 1,
    },
    {
      title: t('costAllocations.setupBusinessUnit.costCenterCode'),
      dataIndex: 'costCenterCode',
      key: 'costCenterCode',
      align: 'left',
      width: '70%',
    },
    {
      title: t('costAllocations.setupBusinessUnit.share'),
      dataIndex: 'share',
      key: 'share',
      render: (text: any) => Number(text).toFixed(2) + '%',
    },
    {
      title: t('costAllocations.setupBusinessUnit.cost'),
      dataIndex: 'cost',
      key: 'cost',
      render: (text: any) => currencySymbol + Number(text).toFixed(2),
    },
  ];

  const customExpandIcon = (record: any, expanded: boolean, onExpand: any) => {
    if (expanded) {
      return (
        <Icon
          iconName={ICONS.ARROW_DOWN_S_LINE}
          onClick={(e) => onExpand(record, e)}
          dataTestId="expand-icon-expanded"
        />
      );
    } else {
      return (
        <Icon
          iconName={ICONS.ARROW_RIGHT_S_LINE}
          onClick={(e) => onExpand(record, e)}
          dataTestId="expand-icon-minimized"
        />
      );
    }
  };

  /**
   * @function expandedRowRender
   * @description Call back function for rendering expanded row content.
   * @param record - Business Unit record to be rendered.
   * @return React Element to be rendered.
   */
  const expandedRowRender = (record: any) => {
    return (
      <div className="cost-center-table card">
        <Table
          className="expanded-table"
          dataSource={record.costCenters?.map((row: any, index: number) => ({
            ...row,
            key: index,
          }))}
          columns={costCenterColumns}
          pagination={false}
          designVersion2
        />
      </div>
    );
  };

  /**
   * @function onClickAddBusinessUnit
   * @description call back function for opening business unit modal for adding new business unit.
   */
  const onClickAddBusinessUnit = () => {
    setIsEditBusinessUnit(false);
    setBusinessUnitIndex(-1);
    setShowCostCenterModal(true);
  };

  /**
   * @function onClickUpdateBusinessUnit
   * @description call back function for opening business unit modal for editing existing business unit.
   */
  const onClickUpdateBusinessUnit = (index: number) => {
    setIsEditBusinessUnit(true);
    setBusinessUnitIndex(index);
    setShowCostCenterModal(true);
  };

  /**
   * @function onClickDeleteSelecteBusinessUnits
   * @description function to delete the selected bysiness units.
   */
  const onClickDeleteSelecteBusinessUnits = () => {
    const newBusinessUnits = costAllocationData?.businessUnits?.filter(
      (item) =>
        !selectedRows.some(
          (row) => row.businessUnitCode === item.businessUnitCode
        )
    );
    setSelectedRowKeys([]);
    setSelectedRows([]);
    dispatch(
      setCostAllocationData({
        ...costAllocationData,
        businessUnits: newBusinessUnits || [],
      })
    );
  };

  return (
    <div className="full-width flex flex-column flex-gap-16 flex-align-self-center">
      <div className="flex flex-align-items-center flex-space-between">
        <div className="flex flex-column flex-gap-2">
          <span className="font-button">
            {t('costAllocations.setupBusinessUnit.allocations')}
          </span>
          <div className="font-caption-bold pending-message">
            {t('costAllocations.setupBusinessUnit.pendingSharedCost') +
              ' ' +
              currencySymbol +
              pendingSharedCost}
          </div>
        </div>
        <div className="add-delete-buttons flex flex-align-items-center">
          <Button
            className="delete-btn"
            size={BUTTON_SIZE.SMALL}
            type={BUTTON_TYPE.LINK}
            title={t('costAllocations.delete')}
            onClick={onClickDeleteSelecteBusinessUnits}
            disabled={selectedRows.length === 0}
          />

          <Button
            className="add-business-unit-button"
            size={BUTTON_SIZE.SMALL}
            type={BUTTON_TYPE.NEUTRAL}
            title={t('costAllocations.setupBusinessUnit.addBusinessUnit')}
            iconName={ICONS.ADD_LINE}
            onClick={onClickAddBusinessUnit}
            disabled={!pendingSharedCost || pendingSharedCost <= 0}
          />
        </div>
      </div>
      <div className="business-unit-list">
        <Table
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: (newSelectedRowKeys: any, newSelectedRows: any) => {
              setSelectedRowKeys(newSelectedRowKeys);
              setSelectedRows(newSelectedRows);
            },
          }}
          columns={columns}
          dataSource={costAllocationData?.businessUnits?.map(
            (eachData, index) => ({
              ...eachData,
              key: index,
            })
          )}
          expandable={{ expandedRowRender: expandedRowRender }}
          expandIcon={(props: any) =>
            customExpandIcon(props.record, props.expanded, props.onExpand)
          }
          scroll={{ y: 'auto' }}
          pagination={false}
          designVersion2
          fillContainer={true}
        />
      </div>
      {showCostCenterModal && (
        <CostCenterDrawer
          show={showCostCenterModal}
          setShow={setShowCostCenterModal}
          isEdit={isEditBusinessUnit}
          index={businessUnitIndex}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
    </div>
  );
};

export default SetupBusinessUnit;
