import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import ColumnChart from 'components/ColumnChart';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { TypeAndNameByCost } from 'types/dataTypes';
import { MONTH_YEAR_FORMAT } from 'utils/date';

import { getTopDbByCostExcelData } from '../utils';

type TopDatabasesByUsageProps = {
  topDbByUsage: TypeAndNameByCost[];
  topDbByUsageTableData: any[];
  topDbByUsageRequestStatus: string;
  isTopDbByUsageTableView: boolean;
  setIsTopDbByUsageTableView: (value: boolean) => void;
  topDbByUsageDateRange: string[];
  onChangeTopDbByUsageDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  allDbList: string[];
  selectedDbList: string[];
  setSelectedDbList: (value: string[]) => void;
  pdfView?: boolean;
};

const TopDatabasesByUsage = ({
  topDbByUsage,
  topDbByUsageTableData,
  topDbByUsageRequestStatus,
  isTopDbByUsageTableView,
  setIsTopDbByUsageTableView,
  topDbByUsageDateRange,
  onChangeTopDbByUsageDateRange,
  allDbList,
  selectedDbList,
  setSelectedDbList,
  pdfView,
}: TopDatabasesByUsageProps) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('snowflakeDashboards.databases'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('snowflakeDashboards.databaseUsage'),
      dataIndex: 'dbCost',
      key: 'dbCost',
      render: (text: any) => text + ' ' + t('snowflakeDashboards.tb'),
    },
    {
      title: t('snowflakeDashboards.failsafeUsage'),
      dataIndex: 'fsCost',
      key: 'fsCost',
      render: (text: any) => text + ' ' + t('snowflakeDashboards.tb'),
    },
  ];

  const filters = (
    <div className="filters">
      <DatePicker
        defaultValue={[
          moment(topDbByUsageDateRange[0]),
          moment(topDbByUsageDateRange[1]),
        ]}
        onChange={onChangeTopDbByUsageDateRange}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        disabledDate={(current: any) => current > moment().endOf('day')}
      />
      <GraphFilterDropdown
        allData={allDbList.map((item) => ({ name: item }))}
        selectedData={selectedDbList.map((item) => ({ name: item }))}
        setSelectedData={(values: any[]) =>
          setSelectedDbList(values.map((item: any) => item.name))
        }
        valueSuffix={t('snowflakeDashboards.databases')}
        fieldName={DROPDOWN_VALUE_FIELDS.NAME}
        loading={topDbByUsageRequestStatus === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );

  const getComponent = () => (
    <div className={`graph ${isTopDbByUsageTableView && 'flex flex-gap-16'}`}>
      {isTopDbByUsageTableView ? (
        <div className="tabular-view">
          <Table
            pagination={false}
            loading={topDbByUsageRequestStatus === REQUEST_STATUS.PROCESSING}
            dataSource={topDbByUsageTableData.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            scroll={{ y: 190 }}
            designVersion2
            fillContainer
          />
        </div>
      ) : (
        <ColumnChart
          data={topDbByUsage.filter((item) =>
            selectedDbList.includes(item.name)
          )}
          xField="name"
          yField="cost"
          yTitle={t('snowflakeDashboards.usageInTb')}
          groupingField="type"
          isStack
          suffixSymbol={t('snowflakeDashboards.tb')}
          disableAnimation={pdfView}
        />
      )}
    </div>
  );

  return (
    <div
      className="graph-card cost-by-name-per-month flex flex-column flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.topDbByStorageUsage')}
        filters={filters}
        graphName="top-db-by-cost"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isTopDbByUsageTableView}
        setIsTableView={setIsTopDbByUsageTableView}
        excelData={getTopDbByCostExcelData(
          topDbByUsageTableData,
          topDbByUsageDateRange,
          selectedDbList
        )}
        className="extended-filter"
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={topDbByUsageRequestStatus}
      />
    </div>
  );
};

export default TopDatabasesByUsage;
