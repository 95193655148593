import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setNavSubPath } from 'redux/activeNavMenuSlice';
import { selectPurchase, setAwsRegions } from 'redux/purchaseSlice';
import { PurchaseCartComponents } from 'pages/PurchasePage/constants';
import PurchaseHeader from 'pages/PurchasePage/components/PurchaseHeader';
import ErrorComponent from 'components/ErrorComponent';
import NavigationPath from 'components/NavigationPath';
import SuccessComponent from 'components/SuccessComponent';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { SuccessIcon } from 'assets/icons';

import ElastiCacheRiCart from './components/ElastiCacheRiCart';

const PurchaseElastiCacheRI = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedConnection } = useSelector(selectPurchase);

  const [elastiCacheRiCartComponent, setElastiCacheRiCartComponent] = useState(
    PurchaseCartComponents.CART
  );

  useEffect(() => {
    if (!selectedConnection) {
      navigate(NAVIGATION_MENU_PATH.COMMITMENTS);
      return;
    }

    dispatch(
      setNavSubPath([t('purchasePage.reservedInstancesLabels.purchaseRi')])
    );

    return () => {
      dispatch(setAwsRegions([]));
    };
  }, []);

  const getComponent = () => {
    switch (elastiCacheRiCartComponent) {
      case PurchaseCartComponents.CART:
        return (
          <ElastiCacheRiCart
            setElastiCacheRiCartComponent={setElastiCacheRiCartComponent}
          />
        );

      case PurchaseCartComponents.SUCCESS:
        return (
          <SuccessComponent
            mainTitle={t('purchasePage.cart.successfullyPurchased')}
            subTitle={t(
              'purchasePage.reservedInstancesLabels.riSuccessfullyPurchased'
            )}
            buttonTitle={t(
              'purchasePage.reservedInstancesLabels.goToReservedInstance'
            )}
            onHandleButtonClick={() =>
              navigate(NAVIGATION_MENU_PATH.COMMITMENTS)
            }
            icon={<SuccessIcon />}
          />
        );

      case PurchaseCartComponents.ERROR:
        return (
          <ErrorComponent
            mainTitle={t('purchasePage.cart.error')}
            buttonTitle={t('purchasePage.cart.tryAgain')}
            linkTitle={t(
              'purchasePage.reservedInstancesLabels.goToReservedInstance'
            )}
            onHandleButtonClick={() =>
              setElastiCacheRiCartComponent(PurchaseCartComponents.CART)
            }
            onHandleLinkClick={() => navigate(NAVIGATION_MENU_PATH.COMMITMENTS)}
          />
        );
    }
  };

  return (
    <div className="flex flex-column full-height">
      <div className="new-page-header">
        <PurchaseHeader showConnectionDropdown={false} />
      </div>
      <div className="page-content">
        <NavigationPath />
        <div className="margin-24 full-height">{getComponent()}</div>
      </div>
    </div>
  );
};

export default PurchaseElastiCacheRI;
