import i18n from 'i18n';

export const CONNECTION_QUICK_ACTIONS = [
  { id: 'EDIT', title: 'Edit' },
  { id: 'DELETE', title: 'Delete' },
];

export enum UserQuickActions {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const USER_QUICKACTIONS = [
  { id: UserQuickActions.UPDATE, title: 'update' },
  { id: UserQuickActions.DELETE, title: 'delete' },
];

export enum CustomDashboardActions {
  SET_AS_DEFAULT = 'SET AS DEFAULT',
  PUBLISH_DRAFT = 'PUBLISH DRAFT',
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  DELETE = 'DELETE',
  UNINSTALL = 'UNINSTALL',
}

export const CUSTOM_DASHBOARD_QUICKACTIONS = [
  {
    id: CustomDashboardActions.SET_AS_DEFAULT,
    title: i18n.t('dashboardTableQuickActions.setAsDefault'),
  },
  {
    id: CustomDashboardActions.PUBLISH_DRAFT,
    title: i18n.t('dashboardTableQuickActions.publishDraft'),
  },
  {
    id: CustomDashboardActions.DEACTIVATE,
    title: i18n.t('dashboardTableQuickActions.deactivate'),
  },
  {
    id: CustomDashboardActions.ACTIVATE,
    title: i18n.t('dashboardTableQuickActions.activate'),
  },
  {
    id: CustomDashboardActions.DELETE,
    title: i18n.t('dashboardTableQuickActions.delete'),
  },
];

export enum RoleQuickActions {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum RoleStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export const ROLE_QUICKACTIONS = [
  {
    id: RoleQuickActions.DEACTIVATED,
    title: i18n.t('rolesTable.quickActionsMenu.deactivate'),
  },
  {
    id: RoleQuickActions.EDIT,
    title: i18n.t('rolesTable.quickActionsMenu.edit'),
  },
  {
    id: RoleQuickActions.ACTIVE,
    title: i18n.t('rolesTable.quickActionsMenu.activate'),
  },
  {
    id: RoleQuickActions.DELETE,
    title: i18n.t('rolesTable.quickActionsMenu.delete'),
  },
];

export enum DataCenterQuickActions {
  MANUAL_ENTRY = 'MANUAL_ENTRY',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const DATA_CENTER_QUICKACTIONS = [
  {
    id: DataCenterQuickActions.MANUAL_ENTRY,
    title: i18n.t('dataCenterQuickActions.manualEntry'),
  },
  {
    id: DataCenterQuickActions.EDIT,
    title: i18n.t('dataCenterQuickActions.edit'),
  },
  {
    id: DataCenterQuickActions.DELETE,
    title: i18n.t('dataCenterQuickActions.delete'),
  },
];

export enum TcoProjectQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const TCO_PROJECTS_QUICKACTIONS = [
  {
    id: TcoProjectQuickActions.EDIT,
    title: i18n.t('tcoProjectQuickActions.edit'),
  },
  {
    id: TcoProjectQuickActions.DELETE,
    title: i18n.t('tcoProjectQuickActions.delete'),
  },
];
