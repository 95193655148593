import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import {
  defaultCustomDashboardDataSource,
  defaultReportOptions,
} from 'constants/defaultValues';
import { ReportsViewType } from 'pages/CreateReportPage/constants';
import {
  ChartDimensionType,
  ExistingReportMetaDataType,
} from 'pages/CreateReportPage/types';
import { GroupType } from 'pages/GroupsPage/types';
import { ReportOptionsType, ColumnType } from 'pages/ReportsPage/types';
import { TagMappingType } from 'pages/TagMappingPage/types';
import {
  AvailableCustomGroupFieldsType,
  AvailableFieldsType,
  ConnectionListType,
  CustomDashboardDataSourceType,
  TableOrChartData,
} from 'types/dashboard';
import { KeyValueTypes } from 'types/dataTypes';
import { RootState } from './store';

export interface ReportSlice {
  reportView: string;
  selectedDatasourceMenu: string | undefined;
  reportDatasource: CustomDashboardDataSourceType;
  typeOfConnection: string;
  reportConnection: ConnectionListType | null;
  reportGroup: GroupType | null;
  reportDimensions: AvailableFieldsType[];
  reportMetrics: AvailableFieldsType[];
  selectedReportDimensions: ChartDimensionType[];
  selectedReportMetrics: ColumnType[];
  reportGroupAvailableFields: AvailableCustomGroupFieldsType[];
  reportOptions: ReportOptionsType;
  reportData: TableOrChartData;
  isReportNameExists: boolean;
  reportNameValidation: string;
  existingReport: ExistingReportMetaDataType | null;
  tagMaps: TagMappingType[];
  tagKeyValues: KeyValueTypes[];
}

const initialState: ReportSlice = {
  reportView: ReportsViewType.OPEN,
  selectedDatasourceMenu: undefined,
  reportDatasource: defaultCustomDashboardDataSource,
  typeOfConnection: MY_DASHBOARD_TYPES.SINGLE_CONNECTION,
  reportConnection: null,
  reportGroup: null,
  reportDimensions: [],
  reportMetrics: [],
  selectedReportDimensions: [],
  selectedReportMetrics: [],
  reportGroupAvailableFields: [],
  reportOptions: defaultReportOptions,
  reportData: { chartData: [], tableData: [] },
  isReportNameExists: false,
  reportNameValidation: '',
  existingReport: null,
  tagMaps: [],
  tagKeyValues: [],
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setReportView: (state, action: PayloadAction<string>) => {
      state.reportView = action.payload;
    },
    setSelectedDatasourceMenu: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedDatasourceMenu = action.payload;
    },
    setReportDatasource: (
      state,
      action: PayloadAction<CustomDashboardDataSourceType>
    ) => {
      state.reportDatasource = action.payload;
    },
    setTypeOfConnection: (state, action: PayloadAction<string>) => {
      state.typeOfConnection = action.payload;
    },
    setReportConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.reportConnection = action.payload;
    },
    setReportGroup: (state, action: PayloadAction<GroupType | null>) => {
      state.reportGroup = action.payload;
    },
    setReportDimensions: (
      state,
      action: PayloadAction<AvailableFieldsType[]>
    ) => {
      state.reportDimensions = action.payload;
    },
    setReportMetrics: (state, action: PayloadAction<AvailableFieldsType[]>) => {
      state.reportMetrics = action.payload;
    },
    setSelectedReportDimensions: (
      state,
      action: PayloadAction<ChartDimensionType[]>
    ) => {
      state.selectedReportDimensions = action.payload;
    },
    setSelectedReportMetrics: (state, action: PayloadAction<ColumnType[]>) => {
      state.selectedReportMetrics = action.payload;
    },
    setReportGroupAvailableFields: (
      state,
      action: PayloadAction<AvailableCustomGroupFieldsType[]>
    ) => {
      state.reportGroupAvailableFields = action.payload;
    },
    setReportOptions: (state, action: PayloadAction<ReportOptionsType>) => {
      state.reportOptions = action.payload;
    },
    setReportData: (state, action: PayloadAction<TableOrChartData>) => {
      state.reportData = action.payload;
    },
    setIsReportNameExists: (state, action: PayloadAction<boolean>) => {
      state.isReportNameExists = action.payload;
    },
    setReportNameValidation: (state, action: PayloadAction<string>) => {
      state.reportNameValidation = action.payload;
    },
    setExistingReport: (
      state,
      action: PayloadAction<ExistingReportMetaDataType | null>
    ) => {
      state.existingReport = action.payload;
    },
    setTagMaps: (state, action: PayloadAction<TagMappingType[]>) => {
      state.tagMaps = action.payload;
    },
    setTagKeyValues: (state, action: PayloadAction<KeyValueTypes[]>) => {
      state.tagKeyValues = action.payload;
    },
  },
});

export const selectReport = (state: RootState) => state.report;
export const {
  setReportView,
  setSelectedDatasourceMenu,
  setReportDatasource,
  setTypeOfConnection,
  setReportConnection,
  setReportGroup,
  setReportDimensions,
  setReportMetrics,
  setSelectedReportDimensions,
  setSelectedReportMetrics,
  setReportGroupAvailableFields,
  setReportOptions,
  setReportData,
  setIsReportNameExists,
  setReportNameValidation,
  setExistingReport,
  setTagMaps,
  setTagKeyValues,
} = reportSlice.actions;
export default reportSlice.reducer;
