import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';

import { selectScorecard } from 'redux/scorecardSlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import ExpandModal from 'components/ExpandModal';
import { CategoryMonthlyCost, ItemValueType } from 'pages/ScorecardPage/types';
import {
  GCP_SERVICES,
  SAVING_PLAN_REPORT_TYPES,
  SCORECARDS_TIMERANGE,
} from 'pages/ScorecardPage/constants';
import { RecommendationStatus } from 'pages/CostOptimizationInsightsPage/constants';
import { ComparisonListType } from 'types/dashboard';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES,
  GCP_PURCHASE_TACTICS_RECOMMENDATION_TYPES,
  GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES,
} from 'constants/recommendations';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import {
  fetchGcpCudEffectivenessDetails,
  fetchWMAssignedWeightage,
  getGCPCoverageAndUtilizationDetails,
} from 'pages/ScorecardPage/services';
import { KeyValueTypes } from 'types/dataTypes';
import {
  getCostOfWasteRequestBody,
  getTotalCostRequestBody,
  getWasteManagementCategoriesByProvider,
  getWasteManagementCategoriesLabelsByProvider,
} from 'pages/ScorecardPage/components/WasteManagementScorecardDashboard/utils';
import { CHART_TYPES } from 'constants/graphConfig';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';

import { SUMMARY_GRAPHS } from '../../constants';
import SavingsTrack from '../SavingsTrack';
import SavingsEffectivenessSummary from '../SavingsEffectivenessSummary';
import CoverageUtilizationSummaryGraph from '../CoverageUtilizationSummaryGraph';
import {
  geCostSavingsQuery,
  getGCPScorecardSummaryExcelExportData,
} from './utils';
import { getUtilizationCoverageSummaryExportColumns } from '../CoverageUtilizationSummaryGraph/utils';

const GCPSummaryDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showExpandGraphModal, expandedGraphName, tableViewEnabled } =
    useSelector(selectDashboard);
  const { selectedConnection } = useSelector(selectScorecard);

  const [savingsTrackCards, setSavingsTrackCards] = useState<
    ComparisonListType[]
  >([]);

  // YTD Savings
  const [ytdPurchaseTacticsSavings, setYtdPurchaseTacticsSavings] = useState(0);
  const [ytdNetSavings, setYtdNetSavings] = useState(0);
  const [ytdConsumptionSavings, setYtdConsumptionSavings] = useState(0);
  const [ytdWasteSavings, setYtdWasteSavings] = useState(0);
  const [
    ytdPurchaseTacticsSavingsRequestStatus,
    setYtdPurchaseTacticsSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [ytdNetSavingsRequestStatus, setYtdNetSavingsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [
    ytdConsumptionSavingsRequestStatus,
    setYtdConsumptionSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [ytdWasteSavingsRequestStatus, setYtdWasteSavingsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  // Current Month Savings
  const [purchaseTacticsSavings, setPurchaseTacticsSavings] = useState(0);
  const [netSavings, setNetSavings] = useState(0);
  const [consumptionSavings, setConsumptionSavings] = useState(0);
  const [wasteSavings, setWasteSavings] = useState(0);
  const [
    purchaseTacticsSavingsRequestStatus,
    setPurchaseTacticsSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [consumptionSavingsRequestStatus, setConsumptionSavingsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [wasteSavingsRequestStatus, setWasteSavingsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  // FY Forecast Savings
  const [purchaseTacticsForecastSavings, setPurchaseTacticsForecastSavings] =
    useState(0);
  const [consumptionForecastSavings, setConsumptionForecastSavings] =
    useState(0);
  const [wasteForecastSavings, setWasteForecastSavings] = useState(0);
  const [
    purchaseTacticsForecastSavingsRequestStatus,
    setPurchaseTacticsForecastSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    consumptionForecastSavingsRequestStatus,
    setConsumptionForecastSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    wasteForecastSavingsRequestStatus,
    setWasteForecastSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Savings Effectiveness
  const [
    consolidatedSavingsEffectivenessPercentage,
    setConsolidatedSavingsEffectivenessPercentage,
  ] = useState(0);
  const [savingsEffectivenessPercentage, setSavingsEffectivenessPercentage] =
    useState(0);
  const [
    savingsEffectivenessRquestStatus,
    setSavingsEffectivenessRquestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  const [weightagePercentage, setWeightagePercentage] = useState<
    KeyValueTypes[]
  >([]);
  const [
    weightagePercentageRequestStatus,
    setWeightagePercentageRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [costOfWaste, setCostOfWaste] = useState<CategoryMonthlyCost[]>([]);
  const [costOfWasteRequestStatus, setCostOfWasteRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [totalCost, setTotalCost] = useState<CategoryMonthlyCost[]>([]);
  const [totalCostRequestStatus, setTotalCostRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [wasteManagementRate, setWasteManagementRate] = useState(0);
  const [onDemandSpend, setOnDemandSpend] = useState(0);
  const [wmTotalCostSum, setWmTotalCostSum] = useState(0);

  // CUD
  const [cudUtilizationData, setCudUtilizationData] = useState<ItemValueType[]>(
    []
  );
  const [cudCoverageData, setCudCoverageData] = useState<ItemValueType[]>([]);
  const [cudRequestStatus, setCudRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [cudSelectedReportType, setCudSelectedReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [cudTableView, setCudTableView] = useState(false);

  useEffect(() => {
    setYtdPurchaseTacticsSavings(0);
    setYtdNetSavings(0);
    setYtdConsumptionSavings(0);
    setYtdWasteSavings(0);
    setPurchaseTacticsSavings(0);
    setNetSavings(0);
    setConsumptionSavings(0);
    setWasteSavings(0);
    setPurchaseTacticsForecastSavings(0);
    setConsumptionForecastSavings(0);
    setWasteForecastSavings(0);
    setConsolidatedSavingsEffectivenessPercentage(0);
    setSavingsEffectivenessPercentage(0);
    setWeightagePercentage([]);
    setCostOfWaste([]);
    setTotalCost([]);
    setWasteManagementRate(0);
    setOnDemandSpend(0);
    setWmTotalCostSum(0);
    setCudUtilizationData([]);
    setCudCoverageData([]);
    setCudSelectedReportType(SAVING_PLAN_REPORT_TYPES.UTILIZATION);
    setCudTableView(false);

    if (!selectedConnection) {
      return;
    }

    if (selectedConnection.wantBilling) {
      // YTD Savings
      getGCPYtdSavingsEffectivenessDetails();

      // Savings Effectiveness
      getGCPSavingsEffectivenessDetails();
      fetchWeightagePercentage();
      fetchTotalCost();

      // CUD
      fetchCoverageAndUtilizationDetails();
    }

    if (selectedConnection.wantRecommendations) {
      // YTD Savings
      getSavingsCost(
        Object.values(GCP_PURCHASE_TACTICS_RECOMMENDATION_TYPES),
        [RecommendationStatus.SUCCEEDED],
        {
          startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setYtdPurchaseTacticsSavings,
        setYtdPurchaseTacticsSavingsRequestStatus
      );

      getSavingsCost(
        Object.values(GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES),
        [RecommendationStatus.SUCCEEDED],
        {
          startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setYtdConsumptionSavings,
        setYtdConsumptionSavingsRequestStatus
      );

      getSavingsCost(
        Object.values(GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES),
        [RecommendationStatus.SUCCEEDED],
        {
          startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setYtdWasteSavings,
        setYtdWasteSavingsRequestStatus
      );

      // Current Month Svaings
      getSavingsCost(
        Object.values(GCP_PURCHASE_TACTICS_RECOMMENDATION_TYPES),
        [RecommendationStatus.SUCCEEDED],
        {
          startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setPurchaseTacticsSavings,
        setPurchaseTacticsSavingsRequestStatus
      );

      getSavingsCost(
        Object.values(GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES),
        [RecommendationStatus.SUCCEEDED],
        {
          startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setConsumptionSavings,
        setConsumptionSavingsRequestStatus
      );

      getSavingsCost(
        Object.values(GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES),
        [RecommendationStatus.SUCCEEDED],
        {
          startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setWasteSavings,
        setWasteSavingsRequestStatus
      );

      // FY Savings Forecast
      getSavingsCost(
        Object.values(GCP_PURCHASE_TACTICS_RECOMMENDATION_TYPES),
        [RecommendationStatus.ACTIVE, RecommendationStatus.FAILED],
        {
          startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setPurchaseTacticsForecastSavings,
        setPurchaseTacticsForecastSavingsRequestStatus
      );

      getSavingsCost(
        Object.values(GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES),
        [RecommendationStatus.ACTIVE, RecommendationStatus.FAILED],
        {
          startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setConsumptionForecastSavings,
        setConsumptionForecastSavingsRequestStatus
      );

      getSavingsCost(
        Object.values(GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES),
        [RecommendationStatus.ACTIVE, RecommendationStatus.FAILED],
        {
          startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        setWasteForecastSavings,
        setWasteForecastSavingsRequestStatus
      );

      // Savings Effectiveness
      fetchCostOfWaste();
    }
  }, [selectedConnection]);

  useEffect(() => {
    setSavingsTrackCards([
      {
        heading: t('scorecard.summary.ytdSavings'),
        value:
          ytdPurchaseTacticsSavings +
          ytdConsumptionSavings +
          ytdWasteSavings +
          ytdNetSavings,
        requestStatus: [
          ytdPurchaseTacticsSavingsRequestStatus,
          ytdConsumptionSavingsRequestStatus,
          ytdWasteSavingsRequestStatus,
          ytdNetSavingsRequestStatus,
        ],
      },
      {
        heading: t('scorecard.summary.currentMonthSavings'),
        value:
          purchaseTacticsSavings +
          consumptionSavings +
          wasteSavings +
          netSavings,
        requestStatus: [
          purchaseTacticsSavingsRequestStatus,
          consumptionSavingsRequestStatus,
          wasteSavingsRequestStatus,
          savingsEffectivenessRquestStatus,
        ],
      },
      {
        heading: t('scorecard.summary.fySavingsForecast'),
        value:
          purchaseTacticsForecastSavings +
          consumptionForecastSavings +
          wasteForecastSavings,
        requestStatus: [
          purchaseTacticsForecastSavingsRequestStatus,
          consumptionForecastSavingsRequestStatus,
          wasteForecastSavingsRequestStatus,
        ],
      },
      {
        heading: t('scorecard.summary.currentMonthPurchaseTacticsSavings'),
        value: purchaseTacticsSavings + netSavings,
        requestStatus: [
          purchaseTacticsSavingsRequestStatus,
          savingsEffectivenessRquestStatus,
        ],
      },
      {
        heading: t('scorecard.summary.currentMonthConsumptionSavings'),
        value: consumptionSavings,
        requestStatus: [consumptionSavingsRequestStatus],
      },
      {
        heading: t('scorecard.summary.currentMonthWasteSavings'),
        value: wasteSavings,
        requestStatus: [wasteSavingsRequestStatus],
      },
    ]);
  }, [
    ytdPurchaseTacticsSavings,
    ytdNetSavings,
    ytdConsumptionSavings,
    ytdWasteSavings,
    purchaseTacticsForecastSavings,
    ytdNetSavingsRequestStatus,
    consumptionForecastSavings,
    wasteForecastSavings,
    purchaseTacticsSavings,
    netSavings,
    consumptionSavings,
    wasteSavings,
    ytdPurchaseTacticsSavingsRequestStatus,
    ytdConsumptionSavingsRequestStatus,
    ytdWasteSavingsRequestStatus,
    purchaseTacticsForecastSavingsRequestStatus,
    consumptionForecastSavingsRequestStatus,
    wasteForecastSavingsRequestStatus,
    purchaseTacticsSavingsRequestStatus,
    consumptionSavingsRequestStatus,
    wasteSavingsRequestStatus,
    savingsEffectivenessRquestStatus,
  ]);

  useEffect(() => {
    if (costOfWaste.length && totalCost.length && weightagePercentage.length) {
      let wmRate = 0;
      let totalCostSum = 0;
      const categoryLabels = getWasteManagementCategoriesLabelsByProvider(
        selectedConnection?.provider
      );

      // Calculate Waste Management cost ratio for each category
      Object.values(
        getWasteManagementCategoriesByProvider(
          selectedConnection?.provider ?? ''
        )
      ).forEach((category) => {
        const categoryLabel =
          categoryLabels.find((item) => item.value === category)?.label ?? '';

        const totalCostValue =
          totalCost.find((totalCostItem) => totalCostItem.category === category)
            ?.costData[0]?.cost ?? 0;

        const costOfWasteValue =
          costOfWaste.find(
            (costOfWasteItem) => costOfWasteItem.category === category
          )?.costData[0]?.cost ?? 0;

        const ratio = totalCostValue ? costOfWasteValue / totalCostValue : 0;
        totalCostSum += totalCostValue;
        const weightage = weightagePercentage.find(
          (weightageItem) => weightageItem.key === categoryLabel
        );
        wmRate += Math.min(1, ratio) * Number(weightage?.value ?? 0);
      });

      setWasteManagementRate(wmRate);
      setWmTotalCostSum(totalCostSum);
    }
  }, [costOfWaste, totalCost, weightagePercentage]);

  useEffect(() => {
    let savingsEffectivenessWeightage = 0.5;
    let wasteManagementSavingsWeightage = 0.5;

    if (onDemandSpend === 0) {
      wasteManagementSavingsWeightage = 1;
      savingsEffectivenessWeightage = 0;
    }

    if (wmTotalCostSum === 0) {
      savingsEffectivenessWeightage = 1;
      wasteManagementSavingsWeightage = 0;
    }

    setConsolidatedSavingsEffectivenessPercentage(
      savingsEffectivenessPercentage * savingsEffectivenessWeightage +
        wasteManagementRate * wasteManagementSavingsWeightage
    );
  }, [
    onDemandSpend,
    wmTotalCostSum,
    savingsEffectivenessPercentage,
    wasteManagementRate,
  ]);

  useEffect(() => {
    setCudTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    dispatch(
      setExportToExcelData(
        getGCPScorecardSummaryExcelExportData(
          savingsTrackCards,
          {
            utilization: {
              sheetName: `${t('graphHeadings.committedUsageDiscount')} (${t(
                'scorecard.savingsTypes.utilization'
              )})`,
              data: cudUtilizationData,
            },
            coverage: {
              sheetName: `${t('graphHeadings.committedUsageDiscount')} (${t(
                'scorecard.savingsTypes.coverage'
              )})`,
              data: cudCoverageData,
            },
          },
          selectedConnection
        )
      )
    );
  }, [
    selectedConnection,
    savingsTrackCards,
    cudUtilizationData,
    cudCoverageData,
  ]);

  /**
   * @function getSavingsCost
   * @description Function to fetch and set the saving cost
   * @param recommenders List of recommenders
   * @param states List of recommendation status for which the data is fetched
   * @param dateRange Object containing the startDate and endDate
   * @param setData Callback function for setting the data
   * @param setRequestStatus Callback function for setting the request status
   */
  const getSavingsCost = (
    recommenders: string[],
    states: string[],
    dateRange: { startDate: string; endDate: string },
    setData: (val: number) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = geCostSavingsQuery(recommenders, states, dateRange);

    getChartData(requestBody, selectedConnection?.connectorId)
      .then((res: any) => {
        setData(Number(res?.data[0]?.totalCostSaved || 0));
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setRequestStatus));
  };

  /**
   * @function getGCPYtdSavingsEffectivenessDetails
   * @description Function fetch the YTD GCP savings effectiveness details
   */
  const getGCPYtdSavingsEffectivenessDetails = () => {
    setYtdNetSavingsRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      granularity: SCORECARDS_TIMERANGE.MONTHLY,
      startDate: moment().startOf('year').format(YEAR_MONTH_WITHOUT_SEPARATOR),
      endDate: moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
    };

    fetchGcpCudEffectivenessDetails(params)
      .then((res: any) => {
        const data = res?.data?.responseData || [];
        let totalSavings = 0;
        data.forEach((item: any) => {
          totalSavings += item?.totalNetSavings || 0;
        });
        setYtdNetSavings(totalSavings);
        setYtdNetSavingsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setYtdNetSavingsRequestStatus);
      });
  };

  /**
   * @function getGCPSavingsEffectivenessDetails
   * @description Function fetch the GCP savings effectiveness details
   */
  const getGCPSavingsEffectivenessDetails = () => {
    setSavingsEffectivenessRquestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      granularity: SCORECARDS_TIMERANGE.MONTHLY,
      startDate: moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
      endDate: moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
    };

    fetchGcpCudEffectivenessDetails(params)
      .then((res: any) => {
        const data = res?.data?.responseData || [];
        if (data.length > 0) {
          setSavingsEffectivenessPercentage(data[0]?.savingsEffectiveness || 0);
          setNetSavings(data[0]?.totalNetSavings || 0);
          setOnDemandSpend(data[0]?.totalOnDemandSpend || 0);
        } else {
          setSavingsEffectivenessPercentage(0);
          setNetSavings(0);
          setOnDemandSpend(0);
        }

        setSavingsEffectivenessRquestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSavingsEffectivenessRquestStatus);
      });
  };

  /**
   * @function fetchWeightagePercentage
   * @description Function to fetch the weightage percentage
   */
  const fetchWeightagePercentage = () => {
    setWeightagePercentageRequestStatus(REQUEST_STATUS.PROCESSING);

    fetchWMAssignedWeightage()
      .then((res: any) => {
        let responseData = JSON.parse(res?.data?.responseData)[
          selectedConnection?.provider ?? ''
        ];

        const data = Object.entries(responseData).map((item) => ({
          key:
            getWasteManagementCategoriesLabelsByProvider(
              selectedConnection?.provider
            ).find((labelItem) => labelItem.value === item[0])?.label ??
            item[0],
          value: Number(item[1]),
        }));
        setWeightagePercentage(data);
        setWeightagePercentageRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setWeightagePercentageRequestStatus);
      });
  };

  /**
   * @function fetchCostOfWaste
   * @description Function to fetch the cost of waste for all waste management categories
   */
  const fetchCostOfWaste = () => {
    setCostOfWasteRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    const wmKeys = Object.keys(
      getWasteManagementCategoriesByProvider(selectedConnection?.provider)
    );
    wmKeys.forEach((category) => {
      const requestBody = getCostOfWasteRequestBody(
        selectedConnection?.provider ?? '',
        category,
        {
          startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
          endDate: moment().format(HYPHEN_DATE_FORMAT),
        },
        SCORECARDS_TIMERANGE.MONTHLY
      );

      requests.push(getChartData(requestBody, selectedConnection?.connectorId));
    });

    axios
      .all(requests)
      .then((responses) => {
        let consolidatedData: CategoryMonthlyCost[] = [];
        wmKeys.forEach((key, index) => {
          consolidatedData.push({
            category: key,
            costData: (responses.at(index)?.data || []).map((item: any) => {
              const cost =
                (Number(item.estimatedSavings) / moment().daysInMonth()) *
                (moment().date() - 1);
              return {
                month: moment(item.month, YEAR_MONTH_WITHOUT_ZERO).format(
                  MONTH_YEAR_FORMAT
                ),
                cost: cost,
              };
            }),
          });
        });
        setCostOfWaste(consolidatedData);
        setCostOfWasteRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostOfWasteRequestStatus);
      });
  };

  /**
   * @function fetchTotalCost
   * @description Function to fetch the total cost for all waste management categories
   */
  const fetchTotalCost = () => {
    setTotalCostRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    const wmKeys = Object.keys(
      getWasteManagementCategoriesByProvider(selectedConnection?.provider ?? '')
    );
    wmKeys.forEach((category) => {
      requests.push(
        getChartData(
          getTotalCostRequestBody(
            selectedConnection?.provider ?? '',
            category,
            {
              startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
              endDate: moment().format(HYPHEN_DATE_FORMAT),
            },
            SCORECARDS_TIMERANGE.MONTHLY
          ),
          selectedConnection?.connectorId
        )
      );
    });

    axios
      .all(requests)
      .then((responses) => {
        let consolidatedData: CategoryMonthlyCost[] = [];
        wmKeys.forEach((category, index) => {
          consolidatedData.push({
            category: category,
            costData: (responses.at(index)?.data || []).map((item: any) => {
              return {
                ...item,
                month: moment(item.month, YEAR_MONTH_WITHOUT_ZERO).format(
                  MONTH_YEAR_FORMAT
                ),
                cost: Number(item.cost),
              };
            }),
          });
        });
        setTotalCost(consolidatedData);
        setTotalCostRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTotalCostRequestStatus);
      });
  };

  /**
   * @function fetchCoverageAndUtilizationDetails
   * @description Function to fetch the CUD coverage and utilization details
   */
  const fetchCoverageAndUtilizationDetails = () => {
    setCudRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      startDate: moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
      endDate: moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
      granularity: SCORECARDS_TIMERANGE.MONTHLY,
    };

    getGCPCoverageAndUtilizationDetails(params)
      .then((res: any) => {
        const data = res?.data?.responseData || [];
        const utilizationData: ItemValueType[] = [];
        const coverageData: ItemValueType[] = [];

        Object.values(GCP_SERVICES).forEach((service) => {
          const currentServiceData = data.find(
            (item: any) => item.service === service
          );

          utilizationData.push({
            item: service,
            value: currentServiceData?.utilization || 0,
          });
          coverageData.push({
            item: service,
            value: currentServiceData?.coverage || 0,
          });
        });

        setCudUtilizationData(utilizationData);
        setCudCoverageData(coverageData);

        setCudRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCudRequestStatus);
      });
  };

  const getGraphComponent = (
    graph: string,
    pdfView: boolean = false,
    cudReportTypeOverride?: string
  ) => {
    switch (graph) {
      case SUMMARY_GRAPHS.SAVINGS_TRACK:
        return (
          <SavingsTrack
            savingsTrackCards={savingsTrackCards}
            pdfView={pdfView}
          />
        );

      case SUMMARY_GRAPHS.SAVINGS_EFFECTIVENESS:
        return (
          <SavingsEffectivenessSummary
            pdfView={pdfView}
            savingsPercentage={consolidatedSavingsEffectivenessPercentage}
            requestStatus={[
              savingsEffectivenessRquestStatus,
              weightagePercentageRequestStatus,
              costOfWasteRequestStatus,
              totalCostRequestStatus,
            ]}
          />
        );

      case SUMMARY_GRAPHS.COMMITTED_USAGE_DISCOUNT:
        return (
          <CoverageUtilizationSummaryGraph
            heading={t('graphHeadings.committedUsageDiscount')}
            graphName={SUMMARY_GRAPHS.COMMITTED_USAGE_DISCOUNT}
            pdfView={pdfView}
            isTableView={cudTableView}
            setIsTableView={setCudTableView}
            coverageUtilizationData={{
              utilization: cudUtilizationData,
              coverage: cudCoverageData,
            }}
            requestStatus={[cudRequestStatus]}
            selectedReportType={cudReportTypeOverride ?? cudSelectedReportType}
            setSelectedReportType={setCudSelectedReportType}
          />
        );
    }
  };

  return (
    <>
      <div className="flex flex-column flex-gap-24">
        <Row gutter={24}>
          <Col span={12}>{getGraphComponent(SUMMARY_GRAPHS.SAVINGS_TRACK)}</Col>
          <Col span={12}>
            {getGraphComponent(SUMMARY_GRAPHS.SAVINGS_EFFECTIVENESS)}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {getGraphComponent(SUMMARY_GRAPHS.COMMITTED_USAGE_DISCOUNT)}
          </Col>
        </Row>
      </div>
      {showExpandGraphModal && (
        <ExpandModal
          show={showExpandGraphModal}
          graphContent={getGraphComponent(expandedGraphName)}
        />
      )}
      <PdfDownloadComponent
        pdfContent={[
          {
            element: getGraphComponent(SUMMARY_GRAPHS.SAVINGS_TRACK, true),
            contentType: CHART_TYPES.BAR_LINE_CHART,
            graphName: SUMMARY_GRAPHS.SAVINGS_TRACK,
            isTableView: false,
          },
          {
            element: getGraphComponent(
              SUMMARY_GRAPHS.SAVINGS_EFFECTIVENESS,
              true
            ),
            contentType: CHART_TYPES.PIE_CHART,
            graphName: SUMMARY_GRAPHS.SAVINGS_EFFECTIVENESS,
            isTableView: false,
          },
          {
            element: getGraphComponent(
              SUMMARY_GRAPHS.COMMITTED_USAGE_DISCOUNT,
              true,
              SAVING_PLAN_REPORT_TYPES.UTILIZATION
            ),
            contentType: CHART_TYPES.BAR_CHART,
            graphName: SUMMARY_GRAPHS.COMMITTED_USAGE_DISCOUNT,
            isTableView: cudTableView,
            column: getUtilizationCoverageSummaryExportColumns(
              SAVING_PLAN_REPORT_TYPES.UTILIZATION
            ),
            body: insertIndex(
              cudUtilizationData.map((item) => ({
                ...item,
                value: numberCommaSeparator(item.value),
              }))
            ),
            tableName: `${t('graphHeadings.committedUsageDiscount')} (${t(
              'scorecard.savingsTypes.utilization'
            )})`,
          },
          {
            element: getGraphComponent(
              SUMMARY_GRAPHS.COMMITTED_USAGE_DISCOUNT,
              true,
              SAVING_PLAN_REPORT_TYPES.COVERAGE
            ),
            contentType: CHART_TYPES.BAR_LINE_CHART,
            graphName: SUMMARY_GRAPHS.COMMITTED_USAGE_DISCOUNT,
            isTableView: cudTableView,
            column: getUtilizationCoverageSummaryExportColumns(
              SAVING_PLAN_REPORT_TYPES.COVERAGE
            ),
            body: insertIndex(
              cudCoverageData.map((item) => ({
                ...item,
                value: numberCommaSeparator(item.value),
              }))
            ),
            tableName: `${t('graphHeadings.committedUsageDiscount')} (${t(
              'scorecard.savingsTypes.coverage'
            )})`,
          },
        ]}
        pdfMetaData={{
          viewName: t('scorecard.views.summary'),
          fileName: selectedConnection?.name ?? '',
          heading: selectedConnection?.name ?? '',
          subtitle1: selectedConnection?.dataSourceType ?? '',
          provider: selectedConnection?.provider ?? '',
        }}
      />
    </>
  );
};

export default GCPSummaryDashboard;
