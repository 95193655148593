import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Empty, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { userAuthorization } from 'redux/authorizationSlice';
import NavigationPath from 'components/NavigationPath';
import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import DeleteModal from 'components/DeleteModal';
import Button from 'components/Button';
import Input from 'components/Input';
import {
  fetchCmdbSetupList,
  deleteCmdbSetup,
  searchCmdbSetupList,
} from './services';
import { DATE_FORMAT } from 'utils/date';
import Pagination from 'components/Pagination';
import { INPUT_SIZE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import { onApiCallError } from 'utils/handleErrors';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';

import { CMDBListType } from './types';
import { CMDB_LIST_QUICK_ACTION_OPTIONS, CmdbQuickActions } from './constants';

import './index.scss';

const CmdbSetupListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { permissions } = useSelector(userAuthorization);

  const [searchKey, setSearchKey] = useState('');
  const [cmdbSetupList, setCmdbSetupList] = useState<CMDBListType[]>([]);
  const [totalCmdbSetupListCount, setTotalCmdbSetupListCount] = useState(0);
  const [cmdbSetupSelected, setCmdbSetupSelected] = useState<
    CMDBListType | undefined
  >(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCmdbSetupdRequestStatus, setDeleteCmdbSetupdRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [fetchCmdbSetupListRequestStatus, setFetchCmdbSetupListRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    addZeroMarginClass();
    fetchAndSetCmdbListData();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  const setCmdbSetupListFromResponse = (res: any) => {
    setCmdbSetupList(res.data.responseData.content);
    setTotalCmdbSetupListCount(res.data.responseData.totalElements);
    setFetchCmdbSetupListRequestStatus(REQUEST_STATUS.SUCCESS);
  };

  /**
   * @function fetchSearchCmdbSetupListData
   * @description Function to fetch CMDB setup data and with search and filters
   * @param searchString string to be searched for.
   * @param page page number defaults to first page
   */
  const fetchSearchCmdbSetupListData = (
    searchString: string | undefined = undefined,
    page: number = 1
  ) => {
    setFetchCmdbSetupListRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      key: searchString,
      page: page - 1,
      size: PAGINATION_SIZE,
    };

    (searchString ? searchCmdbSetupList : fetchCmdbSetupList)(params)
      .then(setCmdbSetupListFromResponse)
      .catch((e) => {
        onApiCallError(e, false, setFetchCmdbSetupListRequestStatus);
      });
  };

  const fetchAndSetCmdbListData = (page: number = 1) => {
    setFetchCmdbSetupListRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      page: page - 1,
      size: PAGINATION_SIZE,
    };

    fetchCmdbSetupList(params)
      .then(setCmdbSetupListFromResponse)
      .catch((e) => {
        onApiCallError(e, false, setFetchCmdbSetupListRequestStatus);
        setCmdbSetupList([]);
        setTotalCmdbSetupListCount(0);
      });
  };

  /**
   * @function deleteSelectedCmdbSetup
   * @description Function to delete a CMDB setup
   */
  const deleteSelectedCmdbSetup = () => {
    setDeleteCmdbSetupdRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      id: cmdbSetupSelected!.id,
    };

    deleteCmdbSetup(params)
      .then(() => {
        message.success(
          t('cmdbSetupDeleteSuccess', { name: cmdbSetupSelected!.name })
        );
        fetchAndSetCmdbListData();
        setDeleteCmdbSetupdRequestStatus(REQUEST_STATUS.SUCCESS);
        setShowDeleteModal(false);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteCmdbSetupdRequestStatus,
          t('cmdbSetupDeleteFailure', { name: cmdbSetupSelected!.name })
        );
      });
  };

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    if (searchKey.length === 0) {
      fetchAndSetCmdbListData(page);
    } else {
      fetchSearchCmdbSetupListData(searchKey, page);
    }
  };

  /**
   * @function handleSearchCmdbData
   * @description Function to handle CMDB setup search
   * @param e accepts search input event
   */
  const handleSearchCmdbData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCmdbSetupList([]);
    setCurrentPage(1);
    setSearchKey(e.target.value);
    fetchSearchCmdbSetupListData(e.target.value);
  };

  const handleQuickAction = (record: CMDBListType, action: string) => {
    if (action === CmdbQuickActions.EDIT) {
      navigate(NAVIGATION_MENU_PATH.CONFIGURE_CMDB_SETUP + '?id=' + record!.id);
    } else {
      setShowDeleteModal(true);
    }
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + index + 1,
      width: '5%',
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: t('datasourceProvider'),
      dataIndex: 'dataSourceProvider',
      key: 'dataSourceProvider',
      width: '25%',
    },
    {
      title: t('lastUpdatedOn'),
      dataIndex: 'lastUpdatedOn',
      key: 'lastUpdatedOn',
      render: (text: string) => {
        return moment(text).format(DATE_FORMAT);
      },
      width: '15%',
    },
    {
      title: t('actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_text: string, record: CMDBListType) => {
        return (
          <QuickActionMenu
            setCurrentConnectionData={() => setCmdbSetupSelected(record)}
            quickActions={CMDB_LIST_QUICK_ACTION_OPTIONS}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      width: '10%',
      align: 'center',
    },
  ];

  return (
    <div className="cmdb-list flex flex-fit flex-column">
      <header className="new-page-header">
        <div className="title-container flex flex-align-items-center flex-space-between">
          <span className="modal-heading">{t('navigationMenu.cmdbSetup')}</span>
          {permissions.userManagementWrite && (
            <Button
              title={t('addCmdbSetup')}
              onClick={() => {
                navigate(NAVIGATION_MENU_PATH.CONFIGURE_CMDB_SETUP);
              }}
            />
          )}
        </div>
      </header>
      <section className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="table-section">
          <Input
            placeholder={t('searchCmdbSetup')}
            type="search"
            rootClassName="search-input"
            size={INPUT_SIZE.SMALL}
            onChange={debounce(handleSearchCmdbData, DEBOUNCE_TIME_DELAY)}
          />
          <Table
            className="cmdb-list-table"
            pagination={false}
            dataSource={cmdbSetupList.map((cmdbSetup: CMDBListType) => ({
              ...cmdbSetup,
              key: cmdbSetup.name,
            }))}
            columns={columns}
            designVersion2
            fillContainer
            scroll={{ x: '100%', y: '100%' }}
            loading={
              fetchCmdbSetupListRequestStatus === REQUEST_STATUS.PROCESSING
            }
            locale={{
              emptyText: fetchCmdbSetupListRequestStatus !==
                REQUEST_STATUS.PROCESSING && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    fetchCmdbSetupListRequestStatus === REQUEST_STATUS.ERROR
                      ? t('graphErrorMessage')
                      : t('noCmdbSetupExists')
                  }
                />
              ),
            }}
          />
        </div>
      </section>
      <footer className="page-footer flex">
        {totalCmdbSetupListCount > 0 && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalCmdbSetupListCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </footer>
      {cmdbSetupSelected !== undefined && showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={deleteSelectedCmdbSetup}
          loading={deleteCmdbSetupdRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default CmdbSetupListPage;
