import moment from 'moment';

/**
 * @function disabledHours
 * @description Function to return the list of disabled hours
 * @param date selected date
 * @returns list of disabled hours
 */
const disabledHours = (date: string | undefined) => {
  const momentData = moment(date);
  if (!date || moment().isAfter(momentData, 'date')) {
    return Array.from(Array(24).keys());
  }

  if (moment().isSame(momentData, 'date')) {
    const disabledHoursList: number[] = [];
    let start = 0;
    while (start <= moment().hours()) {
      disabledHoursList.push(start);
      start += 1;
    }
    return disabledHoursList;
  }
};

/**
 * @function disabledMinutes
 * @description Function to return the list of disabled hours
 * @param date selected date
 * @param selectedHour hour selected
 * @returns list of disabled minutes
 */
const disabledMinutes = (date: string | undefined, selectedHour: number) => {
  const momentData = moment(date);
  if (!date || moment().isAfter(momentData, 'date')) {
    return Array.from(Array(60).keys());
  }

  if (
    moment().isSame(momentData, 'date') &&
    selectedHour === moment().add(1, 'hours').hours()
  ) {
    const disabledMinutesList: number[] = [];
    let start = 0;
    while (start <= moment().minutes()) {
      disabledMinutesList.push(start);
      start += 1;
    }
    return disabledMinutesList;
  }
};

/**
 * @function disabledSeconds
 * @description Function to return the list of disabled seconds
 * @param date selected date
 * @returns list of disabled seconds
 */
const disabledSeconds = (date: string | undefined) => {
  if (!date) {
    return Array.from(Array(60).keys());
  }
};

/**
 * @function getDisabledTime
 * @description Function to return the disabled time for time field
 * @param date selected date
 * @returns Object containing the disabled hours, minutes and seconds
 */
export const getDisabledTime = (date: string | undefined) => {
  return {
    disabledHours: () => disabledHours(date),
    disabledMinutes: (selectedHour: number) =>
      disabledMinutes(date, selectedHour),
    disabledSeconds: () => disabledSeconds(date),
  };
};
