import i18n from 'i18n';

import { store } from 'redux/store';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { SnowflakeIntegrationType } from 'pages/IntegrationsPage/types';
import {
  ConnectionListType,
  DashboardMetaData,
  DualAxisChartData,
  GranulateCostImpactType,
} from 'types/dashboard';
import { DashboardListType, DashboardViewType } from 'types/navigationMenu';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { modifyToExportColumns } from 'utils/exportToExcel';
import { PROVIDER } from 'constants/cloudProviders';
import { QUERY_FIELDS, REQUEST_STATUS } from 'constants/requestBody';
import { getGranulateTotalCostImpact } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { getQueryFieldByDataSource } from 'utils/dashboardUtils';

import { UnitCostTimePeriod } from '../constants';

const state = store.getState();
const currencySymbol = state.commonUtility.currencySymbol;

/**
 * @function getDefaultViewId
 * @description Function to get the default view ID of dashboard navigation menu
 * @param dashboard Dashboard details of selected dashboard
 * @param connection connection details of selected dashboard
 * @param integrationConnection integration connection details of selected dashboard
 * @param views all views of selected dashboard
 * @returns string default view
 */
export const getDefaultViewId = (
  dashboard: DashboardListType | null,
  connection: ConnectionListType | null,
  integrationConnection: SnowflakeIntegrationType | null,
  views: DashboardViewType[]
): string => {
  if (!dashboard) {
    return '';
  }

  if (
    dashboard.dashBoardType !== MY_DASHBOARD_TYPES.GROUP &&
    (!connection || dashboard.connectorId !== connection.connectorId) &&
    (!integrationConnection ||
      dashboard.integrationId !== integrationConnection.integrationId)
  ) {
    return '';
  }

  if (views && views.length > 0) {
    return views[0].id;
  }

  return '';
};

/**
 * @function getDualAxisCostByTypeTableData
 * @description Function to return the dual axis cost by type table data
 * @param data Data for which the table data is required
 * @returns List of columns
 */
export const getDualAxisCostByTypeTableData = (
  data: DualAxisChartData,
  excludePrefixTypes: string[] = []
) => {
  const consolidatedTableData: any[] = [];
  data.leftAxisData.forEach((item) => {
    const existingData = consolidatedTableData.find(
      (obj) => obj.type === item.type
    );

    const prefix = excludePrefixTypes.includes(item.type) ? '' : currencySymbol;

    if (existingData) {
      existingData[`${item.time}-value`] =
        prefix + numberCommaSeparator(item.value ?? 0);
    } else {
      consolidatedTableData.push({
        type: item.type,
        [`${item.time}-value`]: prefix + numberCommaSeparator(item.value ?? 0),
      });
    }
  });

  data.rightAxisData.forEach((item) => {
    const existingData = consolidatedTableData.find(
      (obj) => obj.type === item.type
    );

    const prefix = excludePrefixTypes.includes(item.type) ? '' : currencySymbol;

    if (existingData) {
      existingData[`${item.time}-value`] =
        prefix + numberCommaSeparator(item.value ?? 0);
    } else {
      consolidatedTableData.push({
        type: item.type,
        [`${item.time}-value`]: prefix + numberCommaSeparator(item.value ?? 0),
      });
    }
  });

  return consolidatedTableData;
};

/**
 * @function getEc2UsagePercentageTableData
 * @description Function to return the Usage percentage table data
 * @param data Data for which the table data is required
 * @returns List of columns
 */
export const getEc2UsagePercentageTableData = (data: any[]) => {
  const consolidatedTableData: any[] = [];
  data.forEach((item) => {
    const existingData = consolidatedTableData.find(
      (obj) => obj.type === item.type
    );
    if (existingData) {
      existingData[`${item.time}-value`] =
        numberCommaSeparator(item.value ?? 0) + '%';
    } else {
      consolidatedTableData.push({
        type: item.type,
        [`${item.time}-value`]: numberCommaSeparator(item.value ?? 0) + '%',
      });
    }
  });

  return consolidatedTableData;
};

/**
 * @function getUsageCostTableData
 * @description Function to return the usage cost table data
 * @param data Data for which the table data is required
 * @param groupingField field name used for grouping
 * @returns List of columns
 */
export const getUsageCostTableData = (data: any[], groupingField: string) => {
  const consolidatedTableData: any[] = [];
  data.forEach((item) => {
    const existingTypeData = consolidatedTableData.find(
      (obj) => obj.type === item.type
    );
    if (existingTypeData) {
      existingTypeData[`${item[groupingField]}-value`] =
        currencySymbol + numberCommaSeparator(item.value ?? 0);
    } else {
      consolidatedTableData.push({
        type: item.type,
        [`${item[groupingField]}-value`]:
          currencySymbol + numberCommaSeparator(item.value ?? 0),
      });
    }
  });
  return consolidatedTableData;
};

/**
 * @function getAccountWithGroupingValuesColumns
 * @description Function to return the Account by grouping values Columns
 * @param groupingValues List of grouping values for which the columns are required
 * @returns List of columns
 */
export const getAccountWithGroupingValuesColumns = (
  groupingValues: string[],
  typeColumnTitle: string = i18n.t('computeDashboard.account')
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: typeColumnTitle,
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...groupingValues.map((groupingValue) => ({
      title: groupingValue,
      key: `${groupingValue}-value`,
      dataIndex: `${groupingValue}-value`,
      width: 140,
    })),
  ];
};

/**
 * @function getEbsVolumesSpendColumns
 * @description Function to return the EBS Volumes Spend Columns
 * @returns List of columns
 */
export const getEbsVolumesSpendColumns = () => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: i18n.t('awsStorageDashboard.resourceId'),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    {
      title: i18n.t('awsStorageDashboard.cost', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'value',
      key: 'value',
      width: 140,
    },
  ];
};

/**
 * @function getChartExcelDataWithDateFilter
 * @description Returns the excel export data for chart with date range and accounts filter
 * @param sheetName Sheet name of the excel
 * @param columns excel export columns
 * @param data data to be exported
 * @param connectorName name of the connection chosen
 * @param provider Selected dashboard provider
 * @param dateRange Date range chosen
 * @param selectedAccounts List of accounts selected
 * @returns Object containing the excel data
 */
export const getChartExcelDataWithDateFilter = (excelData: {
  sheetName: string;
  columns: any;
  data: any[];
  connectorName: string;
  provider: string;
  dateRange?: string[];
  selectedAccounts?: string[];
}) => {
  const filters = [
    {
      heading: i18n.t('excelExportLabels.connectionName'),
      value: excelData.connectorName,
    },
  ];

  if (excelData.dateRange) {
    filters.push(
      ...[
        {
          heading: i18n.t('excelExportLabels.startDate'),
          value: excelData.dateRange?.[0],
        },
        {
          heading: i18n.t('excelExportLabels.endDate'),
          value: excelData.dateRange?.[1],
        },
      ]
    );
  }

  if (excelData.selectedAccounts) {
    filters.push({
      heading: getAccountsLabelByProvider(excelData.provider),
      value: excelData.selectedAccounts?.join(', '),
    });
  }

  return {
    sheetName: excelData.sheetName,
    columns: modifyToExportColumns(excelData.columns),
    data: insertIndex(excelData.data),
    filters: filters,
  };
};

/**
 * @function getPdfMetaData
 * @description Returns the metadata for pdf export for connections dashboards
 * @param title Title of the pdf
 * @param dashboard Selected dashboard
 * @param connection Selected connection
 * @returns Object containing the metadata for pdf
 */
export const getPdfMetaData = (
  title: string,
  dashboard: DashboardListType,
  connection: ConnectionListType
) => {
  return {
    viewName: title,
    fileName: dashboard.name,
    heading: dashboard.name,
    subtitle1:
      dashboard.dashBoardType === MY_DASHBOARD_TYPES.IMPORTS
        ? dashboard.transactionName
        : dashboard.connectorName,
    subtitle2: connection.dataSourceType,
    provider: dashboard.connectorProvider,
  };
};

/**
 * @function getAccountLabelByProvider
 * @description Function to return the account label by provider
 * @param provider provider for which the label is fetched
 * @returns Returns string containing the accounts filter label
 */
export const getAccountLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('subscription');

    case PROVIDER.AWS:
      return i18n.t('account');

    case PROVIDER.GCP:
    default:
      return i18n.t('project');
  }
};

/**
 * @function getAccountsLabelByProvider
 * @description Function to return the accounts label by provider
 * @param provider provider for which the label is fetched
 * @returns Returns string containing the accounts filter label
 */
export const getAccountsLabelByProvider = (provider: string) =>
  provider === PROVIDER.GCP ? i18n.t('projects') : i18n.t('accounts');

/**
 * @function fetchGranulateCostImpact
 * @description Fetches the and sets Granulate total cost impact cost by project/account for a connection
 * @param connectorId COnnector Id for which the data is fetched
 * @param setData Callback to set the data
 * @param setRequestStatus Callback to set the request status
 */
export const fetchGranulateCostImpact = async (
  connectorId: string,
  setData: (val: GranulateCostImpactType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);
  setData([]);

  const params = {
    connectorId,
  };

  await getGranulateTotalCostImpact(params)
    .then((res: any) => {
      const costData = Object.entries<number>(res.data).map(
        ([projectId, totalCostImpact]) => ({
          projectId,
          totalCostImpact,
        })
      );
      setData(costData);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

export const getAzureUnitCostTimePeriodQuery = (
  dashboardMetaData: DashboardMetaData
) => {
  return [
    {
      label: UnitCostTimePeriod.HOURLY,
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.COST_BILLING_CURRENCY,
        dashboardMetaData.connection.focusConversionEnabled
      )})/720`,
    },
    {
      label: UnitCostTimePeriod.DAILY,
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.COST_BILLING_CURRENCY,
        dashboardMetaData.connection.focusConversionEnabled
      )})/30`,
    },
    {
      label: UnitCostTimePeriod.WEEKLY,
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.COST_BILLING_CURRENCY,
        dashboardMetaData.connection.focusConversionEnabled
      )})/4`,
    },
    {
      label: 'cost',
      field: getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.COST_BILLING_CURRENCY,
        dashboardMetaData.connection.focusConversionEnabled
      ),
    },
  ];
};

export const getGcpUnitCostTimePeriodQuery = (
  dashboardMetaData: DashboardMetaData
) => {
  return [
    {
      label: UnitCostTimePeriod.HOURLY,
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
        dashboardMetaData.connection.focusConversionEnabled
      )})/720`,
    },
    {
      label: UnitCostTimePeriod.DAILY,
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
        dashboardMetaData.connection.focusConversionEnabled
      )})/30`,
    },
    {
      label: UnitCostTimePeriod.WEEKLY,
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
        dashboardMetaData.connection.focusConversionEnabled
      )})/4`,
    },
    {
      label: 'cost',
      field: getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
        dashboardMetaData.connection.focusConversionEnabled
      ),
    },
  ];
};

export const getAWSUnitCostTimePeriodQuery = (
  dashboardMetaData: DashboardMetaData
) => {
  return [
    {
      label: UnitCostTimePeriod.HOURLY,
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
        dashboardMetaData.connection.focusConversionEnabled
      )})/720`,
    },
    {
      label: UnitCostTimePeriod.DAILY,
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
        dashboardMetaData.connection.focusConversionEnabled
      )})/30`,
    },
    {
      label: UnitCostTimePeriod.WEEKLY,
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
        dashboardMetaData.connection.focusConversionEnabled
      )})/4`,
    },
    {
      label: 'cost',
      field: `SUM(${getQueryFieldByDataSource(
        dashboardMetaData.dashboard.dashBoardType,
        QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
        dashboardMetaData.connection.focusConversionEnabled
      )})`,
    },
  ];
};
