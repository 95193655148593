import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import DrawerComponent from 'components/DrawerComponent';
import { RECOMMENDATION_STATUS_LABELS } from 'pages/CostOptimizationInsightsPage/constants';
import { getProviderForConnection } from 'utils/dashboardUtils';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  IncidentRecommendationStatusLabels,
  SERVICE_NOW_STATUS_LABELS,
} from 'constants/workflowIntegrations';
import { ContainerType, HPAType } from 'types/GranulateRecommendation';

import Containers from './components/Containers';
import HpaPolicy from './components/HpaPolicy';
import CpuRequestField from './components/CpuRequestField';
import CpuRequestValue from './components/CpuRequestValue';
import MemoryRequestField from './components/MemoryRequestField';
import MemoryRequestValue from './components/MemoryRequestValue';
import CpuReductionValue from './components/CpuReductionValue';
import MemoryReductionValue from './components/MemoryReductionValue';
import YamlDetails from './components/YamlDetails';
import StatusElement from '../StatusElement';
import { RecommendationDetailsListType } from './types';
import {
  getConsumptionManagementRecommendationFieldsByProvider,
  getDataByField,
} from './utils';
import { RecommendationList } from '../../types';

import './index.scss';

type RecommendationDetailsModalProps = {
  show: boolean;
  onClose: Function;
  recommendation: RecommendationList;
};

const RecommendationDetailsModal = ({
  show,
  onClose,
  recommendation,
}: RecommendationDetailsModalProps) => {
  const { t } = useTranslation();
  const { selectedCostOptimizationInsightsConnection } = useSelector(
    costOptimizationInsights
  );
  const { currencySymbol } = useSelector(selectCommonUtility);

  const getFieldLabelComponent = (field: string, label: string) => {
    switch (field) {
      case 'cpuRequest':
        return <CpuRequestField />;

      case 'memoryRequest':
        return <MemoryRequestField />;

      default:
        return label;
    }
  };

  const getFieldValueComponent = (field: string, data: string) => {
    switch (field) {
      case 'serviceNowStatus': {
        const label = SERVICE_NOW_STATUS_LABELS.find(
          (item) => item.key === data
        )?.label;
        return <StatusElement statusKey={data} status={label} />;
      }

      case 'incidentRecommendationStatus': {
        const label = IncidentRecommendationStatusLabels.find(
          (item) => item.key === data
        )?.label;
        return <StatusElement statusKey={data} status={label} />;
      }

      case 'recStatus': {
        const label = RECOMMENDATION_STATUS_LABELS.find(
          (item) => item.key === data
        )?.label;
        return <StatusElement statusKey={data} status={label} />;
      }

      case 'containers':
        return <Containers recommendation={recommendation} />;

      case 'hpaPolicy':
        return <HpaPolicy recommendation={recommendation} />;

      case 'cpuRequest':
        return <CpuRequestValue recommendation={recommendation} />;

      case 'memoryRequest':
        return <MemoryRequestValue recommendation={recommendation} />;

      case 'cpuReduction':
        return <CpuReductionValue recommendation={recommendation} />;

      case 'memoryReduction':
        return <MemoryReductionValue recommendation={recommendation} />;

      case 'yaml':
        return <YamlDetails recommendation={recommendation} />;
    }
  };

  const getFieldValueByField = (item: RecommendationDetailsListType) => {
    let dataByField = getDataByField(recommendation, item.field) ?? 'N/A';
    let data: string | number | ContainerType[] | HPAType = dataByField;

    if (item.isCostField) {
      data = currencySymbol + ' ' + numberCommaSeparator(dataByField as number);
    }

    if (item.isPercent) {
      data = numberCommaSeparator(dataByField as number) + ' %';
    }

    if (item.isComponent) {
      return getFieldValueComponent(item.field, dataByField as string);
    }

    return data;
  };

  return (
    <DrawerComponent
      className="recommendation-details-modal"
      open={show}
      onClose={onClose}
      title={t('recommendationDetails')}
      dataTestId="recommendation-details-modal"
    >
      <div className="flex flex-column flex-gap-16">
        {getConsumptionManagementRecommendationFieldsByProvider(
          getProviderForConnection(selectedCostOptimizationInsightsConnection),
          recommendation
        ).map((item) => (
          <div
            key={item.field}
            className="data-item-row flex flex-align-items-start flex-gap-8"
          >
            <div className="data-item-label font-button width-50 flex flex-align-items-start flex-space-between flex-gap-8">
              {getFieldLabelComponent(item.field, item.label)}
              <span className="flex flex-end">:</span>
            </div>
            <div className="data-item-value font-subHeader width-50">
              {getFieldValueByField(item)}
            </div>
          </div>
        ))}
      </div>
    </DrawerComponent>
  );
};

export default RecommendationDetailsModal;
