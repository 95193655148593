import i18n from 'i18n';
import {
  CostAndCreditBreakdownType,
  CreditDistributionByLocationTypes,
  CreditDistributionByProjectTypes,
  MonthlyCreditCostMetricType,
} from 'types/dataTypes';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  CostAndCreditBreakdownExportColumns,
  CreditDistributionByLocationExportColumns,
  CreditDistributionByProjectExportColumns,
} from '../constants';

const addIndexAndCommaSeprator = (data: any) => {
  return data.map((item: any, index: number) => ({
    ...item,
    slNo: index + 1,
    credit: numberCommaSeparator(item.credit || 0),
  }));
};

export const getGCPCreditDistributionByLocationExcelData = (
  connectorName: string,
  creditDistributionByLocation: CreditDistributionByLocationTypes[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.creditDistributionbyLocation'),
    columns: CreditDistributionByLocationExportColumns,
    data: addIndexAndCommaSeprator(creditDistributionByLocation),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

export const getGCPCostAndCreditBreakdownExcelData = (
  connectorName: string,
  costAndCreditBreakdownData: CostAndCreditBreakdownType[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.costandCreditBreakdown'),
    columns: CostAndCreditBreakdownExportColumns,
    data: costAndCreditBreakdownData.map((item, index) => ({
      ...item,
      slNo: index + 1,
      cost: numberCommaSeparator(item.cost),
      costAfterCredit: numberCommaSeparator(item.costAfterCredit),
      credit: numberCommaSeparator(item.credit),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

export const getCreditDiscountBreakdownExportColumns = (
  monthlyCreditByCostTypeData: MonthlyCreditCostMetricType[]
) => {
  let columns: any[] = [
    {
      title: '#',
      key: 'index',
      dataIndex: 'index',
      alignment: 'center',
      width: 10,
    },
    {
      title: i18n.t('creditDiscountBreakdown.month'),
      dataIndex: 'month',
      key: 'month',
      width: 15,
      alignment: 'center',
    },
  ];
  monthlyCreditByCostTypeData.forEach((value) => {
    columns = [
      ...columns,
      {
        title: value.costMetricType,
        dataIndex: value.costMetricType,
        key: value.costMetricType,
        width: 30,
        alignment: 'center',
      },
    ];
  });
  return columns;
};

export const getGCPCreditDistributionByProjectExcelData = (
  connectorName: string,
  creditDistributionByProject: CreditDistributionByProjectTypes[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.creditDistributionbyProject'),
    columns: CreditDistributionByProjectExportColumns,
    data: addIndexAndCommaSeprator(creditDistributionByProject),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

export const getGCPCreditDiscountBreakdownExcelData = (
  connectorName: string,
  creditDiscountBreakdown: any[],
  monthlyCreditByCostTypeData: MonthlyCreditCostMetricType[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.creditDiscountBreakdown'),
    columns: getCreditDiscountBreakdownExportColumns(
      monthlyCreditByCostTypeData
    ),
    data: creditDiscountBreakdown.map((item, index) => ({
      ...item,
      slNo: index + 1,
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

export const getGCPCreditSummaryExcelData = (
  connectorName: string,
  creditDistributionByLocation: CreditDistributionByLocationTypes[],
  costAndCreditBreakdownData: CostAndCreditBreakdownType[],
  creditDistributionByProject: CreditDistributionByProjectTypes[],
  creditDiscountBreakdown: any[],
  monthlyCreditByCostTypeData: MonthlyCreditCostMetricType[]
) => {
  return [
    getGCPCreditDistributionByLocationExcelData(
      connectorName,
      creditDistributionByLocation
    ),
    getGCPCostAndCreditBreakdownExcelData(
      connectorName,
      costAndCreditBreakdownData
    ),
    getGCPCreditDistributionByProjectExcelData(
      connectorName,
      creditDistributionByProject
    ),
    getGCPCreditDiscountBreakdownExcelData(
      connectorName,
      creditDiscountBreakdown,
      monthlyCreditByCostTypeData
    ),
  ];
};
