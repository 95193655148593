import { Table as AntTable } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';

import { userAuthorization } from 'redux/authorizationSlice';
import DatePicker from 'components/DatePicker';
import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import SelectDropdown from 'components/Select';
import DashboardComponent from 'components/DashboardComponent';
import { RecommendationSource } from 'pages/CostOptimizationInsightsPage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectDashboard } from 'redux/dashboardSlice';
import { ValueLabelType } from 'types/dashboard';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { calculateTotalCostSum } from 'utils/CostSum';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { getRecommendersOptions } from './utils';
import CpuRequestField from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationDetailsModal/components/CpuRequestField';
import MemoryRequestField from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationDetailsModal/components/MemoryRequestField';
import Containers from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationDetailsModal/components/Containers';
import HpaPolicy from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationDetailsModal/components/HpaPolicy';
import CpuRequestValue from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationDetailsModal/components/CpuRequestValue';
import MemoryRequestValue from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationDetailsModal/components/MemoryRequestValue';
import CpuReductionValue from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationDetailsModal/components/CpuReductionValue';
import MemoryReductionValue from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationDetailsModal/components/MemoryReductionValue';
import YamlDetails from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationDetailsModal/components/YamlDetails';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import {
  getDetailedSavingsTableColumns,
  getDetailsSavingsTableDataForExport,
} from '../../utils';

import './index.scss';

type DetailedSavingsTableProps = {
  detailedSavingsData: any[];
  requestStatus: string[];
  graphHeading: string;
  pdfView: boolean;
  allRecommenders: ValueLabelType[];
  selectedRecommender: string;
  setSelectedRecommender: (val: string) => void;
  selectedDateRange: RangeValue<Moment>;
  onChangeDateRange: (val: RangeValue<Moment>) => void;
  dropdownFilterRequestStatus: string;
  selectedGraphCategory: string;
};

const DetailedSavingsTable = ({
  detailedSavingsData,
  requestStatus,
  graphHeading,
  pdfView,
  allRecommenders,
  selectedRecommender,
  setSelectedRecommender,
  selectedDateRange,
  onChangeDateRange,
  dropdownFilterRequestStatus,
  selectedGraphCategory,
}: DetailedSavingsTableProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { permissions } = useSelector(userAuthorization);

  const filters = (
    <div className="filters flex flex-align-items-center flex-gap-8 full-width">
      <SelectDropdown
        className="width-25"
        value={selectedRecommender}
        onChange={setSelectedRecommender}
        loading={dropdownFilterRequestStatus === REQUEST_STATUS.PROCESSING}
        options={getRecommendersOptions(
          permissions,
          selectedDashboard!,
          selectedGraphCategory,
          allRecommenders
        )}
      />
      <DatePicker
        defaultValue={selectedDateRange}
        picker="month"
        onChange={onChangeDateRange}
        disabledDate={(current: any) => current > moment().endOf('day')}
        format={MONTH_YEAR_FORMAT}
        disabled={selectedRecommender === RecommendationSource.GRANULATE}
      />
    </div>
  );

  /**
   * @function getFieldLabelComponent
   * @description Returns the component for the column heading based on the field
   * @param column Column for which the component is returned
   * @returns JSX component or string heading
   */
  const getFieldLabelComponent = (column: any) => {
    switch (column.dataIndex) {
      case 'cpuRequest':
        return <CpuRequestField />;

      case 'memoryRequest':
        return <MemoryRequestField />;

      default:
        return column.title;
    }
  };

  /**
   * @function getFieldValueComponent
   * @description Returns the component for the column value
   * @param column Column for which the component is returned
   * @param recommendation Recommendation details to be displayed
   * @returns JSX component or string value
   */
  const getFieldValueComponent = (column: any, recommendation: any) => {
    switch (column.dataIndex) {
      case 'containers':
        return <Containers recommendation={recommendation} />;

      case 'hpaPolicy':
        return <HpaPolicy recommendation={recommendation} />;

      case 'cpuRequest':
        return <CpuRequestValue recommendation={recommendation} />;

      case 'memoryRequest':
        return <MemoryRequestValue recommendation={recommendation} />;

      case 'cpuReduction':
        return <CpuReductionValue recommendation={recommendation} />;

      case 'memoryReduction':
        return <MemoryReductionValue recommendation={recommendation} />;

      case 'yaml':
        return <YamlDetails recommendation={recommendation} />;
    }
  };

  const columns = getDetailedSavingsTableColumns(
    selectedDashboard!.connectorProvider,
    selectedRecommender
  ).map((item: any) => ({
    ...item,
    title: getFieldLabelComponent(item),
    render: item.isComponent
      ? (_: any, recommendation: any) =>
          getFieldValueComponent(item, recommendation)
      : item.render,
    width: item.dataIndex === 'index' ? '50px' : '150px',
  }));

  const TableFooter = () => (
    <div className="font-caption-bold flex flex-space-between">
      <div>
        {t(
          'gcpRecommendationSummary.detailedSavingsTable.totalEstimatedMonthlySavings'
        )}
      </div>
      <div>
        {currencySymbol +
          numberCommaSeparator(
            calculateTotalCostSum(
              detailedSavingsData.map((item) => item.cost ?? item.costImpact)
            )
          )}
      </div>
    </div>
  );

  const getComponent = () => {
    return (
      <Table
        pagination={false}
        scroll={{ y: 200 }}
        dataSource={detailedSavingsData.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        })}
        columns={columns}
        designVersion2
        footer={TableFooter}
      />
    );
  };

  return (
    <div className="gcp-detailed-savings-table graph-card" id="graph-container">
      <GraphHeader
        heading={graphHeading}
        graphName="detailed-savings-table"
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getChartExcelExportData(
          graphHeading,
          getDetailsSavingsTableDataForExport(
            selectedRecommender,
            detailedSavingsData
          ),
          getDetailedSavingsTableColumns(
            selectedDashboard!.connectorProvider,
            selectedRecommender,
            true
          ),
          {
            connectionName: selectedDashboard!.connectorName,
            startDate: selectedDateRange?.[0]?.format(MONTH_YEAR_FORMAT),
            endDate: selectedDateRange?.[1]?.format(MONTH_YEAR_FORMAT),
          }
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default DetailedSavingsTable;
