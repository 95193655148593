import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ChartWithTableWrapper from 'components/ChartWithTableWrapper';
import HelpPopover from 'components/HelpPopover';
import Empty from 'components/Empty';
import { ChartFilterType } from 'constants/dashboard';
import { selectDashboard } from 'redux/dashboardSlice';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { getMonthRangeHandler } from 'utils/graphUtils';
import { PROVIDER } from 'constants/cloudProviders';

import SpendProfileChartContent from './components/SpendProfileChartContent';
import { SpendProfileDataType } from './types';
import { fetchServiceSpendProfileData } from '../../utils';

type SpendProfileChartWrapperProps = {
  data: SpendProfileDataType[];
  setData: (val: SpendProfileDataType[]) => void;
  requestStatus: string;
  setRequestStatus: (val: string) => void;
  pdfView: boolean;
  spendProfileStartMonth: string;
  setSpendProfileStartMonth: (val: string) => void;
  spendProfileEndMonth: string;
  setSpendProfileEndMonth: (val: string) => void;
  selectedAccounts?: string[];
};

const SpendProfileChartWrapper = ({
  data,
  setData,
  requestStatus,
  setRequestStatus,
  pdfView,
  spendProfileStartMonth,
  setSpendProfileStartMonth,
  spendProfileEndMonth,
  setSpendProfileEndMonth,
  selectedAccounts,
}: SpendProfileChartWrapperProps) => {
  const { t } = useTranslation();
  const {
    selectedDashboard,
    selectedConnection,
    selectedDashboardView,
    tagsFilters,
  } = useSelector(selectDashboard);

  useEffect(() => {
    setData([]);

    if (
      selectedDashboard &&
      !pdfView &&
      (selectedDashboard.connectorProvider !== PROVIDER.AZURE ||
        selectedAccounts?.length)
    ) {
      fetchServiceSpendProfileData(
        selectedDashboard,
        selectedConnection!,
        {
          startMonth: spendProfileStartMonth,
          endMonth: spendProfileEndMonth,
        },
        {
          setData,
          setRequestStatus,
        },
        selectedDashboardView,
        tagsFilters,
        selectedAccounts
      );
    }
  }, [
    selectedDashboard,
    spendProfileStartMonth,
    spendProfileEndMonth,
    tagsFilters,
    selectedAccounts,
  ]);

  return (
    <ChartWithTableWrapper
      graphTitle={t('graphHeadings.serviceSpendProfile')}
      graphName="service-spend-profile"
      graphHeadingComponent={
        <div className="graph-heading flex flex-center flex-gap-8">
          <span>{t('graphHeadings.serviceSpendProfile')}</span>
          <HelpPopover message={t('spendProfileHelpText')} />
        </div>
      }
      requestStatus={[requestStatus]}
      pdfView={pdfView}
      hasTableView={false}
      isTableView={false}
      filters={[
        {
          filterType: ChartFilterType.DATE_RANGE,
          filterProperties: {
            filterStartDate: spendProfileStartMonth,
            filterEndDate: spendProfileEndMonth,
            onChangeDateRange: getMonthRangeHandler(
              setSpendProfileStartMonth,
              setSpendProfileEndMonth
            ),
            format: MONTH_YEAR_FORMAT,
            picker: 'month',
          },
        },
      ]}
    >
      {data.length ? (
        <SpendProfileChartContent spendProfileData={data} pdfView={pdfView} />
      ) : (
        <Empty />
      )}
    </ChartWithTableWrapper>
  );
};

export default SpendProfileChartWrapper;
