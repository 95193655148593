import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function createBillingMapping
 * @description Function to create a billing mapping
 * @param requestBody Object containing the request body
 * @return axios response from POST request
 */
export const createBillingMapping = async (requestBody: any) => {
  return ServiceCalls.post(APIS.CREATE_BILLING_MAPPING, requestBody);
};

/**
 * @function updateBillingMapping
 * @description Function to update a billing mapping
 * @param requestBody Object containing the request body
 * @return axios response from POST request
 */
export const updateBillingMapping = async (requestBody: any) => {
  return ServiceCalls.post(APIS.UPDATE_BILLING_MAPPING, requestBody);
};

/**
 * @function deleteBillingMappingData
 * @description Function to delete the billing mapping data
 * @param billingDimensionName billing dimension name to be deleted
 * @return axios response from DELETE response
 */
export const deleteBillingMappingData = async (
  billingDimensionName: string
) => {
  const api = APIS.DELETE_BILLING_MAPPING + billingDimensionName;
  return ServiceCalls.delete(api);
};

/**
 * @function validateBillingDimensionName
 * @description Function to validate the billing dimension name
 * @param billingDimensionName billing dimension name to be validated
 * @param cancelToken cancel token
 * @return axios response from GET response
 */
export const validateBillingDimensionName = async (
  billingDimensionName: string,
  cancelToken: any
) => {
  const api = APIS.VERIFY_BILLING_DIMENSION_NAME + billingDimensionName;
  return ServiceCalls.get(api, null, undefined, cancelToken);
};

/**
 * @function getAllFieldsByProviderAndDatasetType
 * @description Function to fetch all the fields by provider and dataset type
 * @param params billing dimension name to be validated
 * @return axios response from GET response
 */
export const getAllFieldsByProviderAndDatasetType = async (params: any) => {
  return ServiceCalls.get(APIS.GET_ALL_FIELDS_BY_PROVIDER, null, params);
};
