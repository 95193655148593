import i18n from 'i18n';
import { store } from 'redux/store';

const state = store.getState();
const currencySymbol = state.commonUtility.currencySymbol;

export const TopServicesExportColumns = [
  {
    header: '#',
    dataKey: 'slNo',
    key: 'slNo',
    width: 5,
  },
  {
    header: i18n.t('accountDeepDiveSummary.services'),
    dataKey: 'service',
    key: 'service',
    width: 25,
  },
  {
    header: i18n.t('accountDeepDiveSummary.cost', {
      currencySymbol: currencySymbol,
    }),
    dataKey: 'cost',
    key: 'cost',
    width: 20,
  },
];
