import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { REQUEST_STATUS } from 'constants/requestBody';
import ConfirmModal from 'components/ConfirmModal';
import { AWS_CHECK_IDS } from 'constants/recommendations';

type ApplyAwsRecommendationProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  selectedAwsCheck: string;
  loadApplyRecommendation: string;
  applyRecommendation: (val: any) => void;
};

const ApplyAwsRecommendation = ({
  show,
  setShow,
  selectedAwsCheck,
  loadApplyRecommendation,
  applyRecommendation,
}: ApplyAwsRecommendationProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'costOptimizationInsight.awsConfirmApplyRecommendation',
  });

  const [showPostActionModal, setShowPostActionModal] = useState(false);
  const [clickedButton, setClickedButton] = useState('');
  const [requestBody, setRequestBody] = useState<any>();

  const getActions = () => {
    switch (selectedAwsCheck) {
      case AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE:
        return {
          modalTitle: t('stopDeleteRdsDbInstances'),
          actions: [
            {
              title: t('stop'),
              onClick: () =>
                applyRecommendation({
                  ...requestBody,
                  createRDSSnapshot: false,
                  stopRDSInstance: true,
                }),
            },
            {
              title: t('delete'),
              onClick: () => {
                setShowPostActionModal(true);
                setRequestBody({
                  ...requestBody,
                  createRDSSnapshot: true,
                  stopRDSInstance: false,
                });
              },
            },
          ],
        };

      case AWS_CHECK_IDS.IDLE_LOAD_BALANCER:
        return {
          modalTitle: t('registerOrDeleteLoadBalancer'),
          actions: [
            {
              title: t('register'),
              onClick: () => {
                setShowPostActionModal(true);
                setRequestBody({
                  ...requestBody,
                  registerLBToInstance: true,
                });
              },
            },
            {
              title: t('delete'),
              onClick: () => {
                applyRecommendation({
                  ...requestBody,
                  registerLBToInstance: false,
                });
              },
            },
          ],
        };

      case AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES:
        return {
          modalTitle: t('terminateOrStopEc2Instance'),
          actions: [
            {
              title: t('terminate'),
              onClick: () => {
                applyRecommendation({
                  ...requestBody,
                  terminateInstance: true,
                });
              },
            },
            {
              title: t('stop'),
              onClick: () => {
                applyRecommendation({
                  ...requestBody,
                  terminateInstance: false,
                });
              },
            },
          ],
        };

      case AWS_CHECK_IDS.IDLE_IP_ADDRESS:
        return {
          modalTitle: t('registerOrDeleteIpAddress'),
          actions: [
            {
              title: t('register'),
              onClick: () => {
                setShowPostActionModal(true);
                setRequestBody({
                  ...requestBody,
                  associateIpToInstance: true,
                });
              },
            },
            {
              title: t('delete'),
              onClick: () => {
                applyRecommendation({
                  ...requestBody,
                  associateIpToInstance: false,
                });
              },
            },
          ],
        };

      case AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES:
        return {
          modalTitle: t('takeSnapshotBeforeDeleting'),
          actions: [
            {
              title: t('yes'),
              onClick: () => {
                applyRecommendation({
                  ...requestBody,
                  createEBSSnapshot: true,
                });
              },
            },
            {
              title: t('no'),
              onClick: () => {
                applyRecommendation({
                  ...requestBody,
                  createEBSSnapshot: false,
                });
              },
            },
          ],
        };

      case AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS:
        return {
          modalTitle: t('downsizeOrDeleteCluster'),
          actions: [
            {
              title: t('downsize'),
              onClick: () => {
                setShowPostActionModal(true);
                setRequestBody({
                  ...requestBody,
                  downSizeCluster: true,
                });
              },
            },
            {
              title: t('delete'),
              onClick: () => {
                setShowPostActionModal(true);
                setRequestBody({
                  ...requestBody,
                  downSizeCluster: false,
                });
              },
            },
          ],
        };
    }
  };

  const getPostAction = () => {
    switch (selectedAwsCheck) {
      case AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE:
        return {
          modalTitle: t('takeSnapshotBeforeDeleting'),
          actions: [
            {
              title: t('yes'),
              onClick: () => {
                applyRecommendation({
                  ...requestBody,
                  createRDSSnapshot: true,
                });
              },
            },
            {
              title: t('no'),
              onClick: () => {
                applyRecommendation({
                  ...requestBody,
                  createRDSSnapshot: false,
                });
              },
            },
          ],
        };

      case AWS_CHECK_IDS.IDLE_LOAD_BALANCER:
        return {
          modalTitle: t('registerToInstanceNotSupported'),
          actions: [
            {
              title: t('yes'),
              onClick: () =>
                applyRecommendation({
                  ...requestBody,
                  registerLBToInstance: true,
                }),
            },
            {
              title: t('no'),
              onClick: () => {
                setShowPostActionModal(false);
                setRequestBody({
                  ...requestBody,
                  registerLBToInstance: false,
                });
              },
            },
          ],
        };

      case AWS_CHECK_IDS.IDLE_IP_ADDRESS:
        return {
          modalTitle: t('registerToInstanceNotSupported'),
          actions: [
            {
              title: t('yes'),
              onClick: () =>
                applyRecommendation({
                  ...requestBody,
                  associateIpToInstance: true,
                }),
            },
            {
              title: t('no'),
              onClick: () => {
                setShowPostActionModal(false);
                setRequestBody({
                  ...requestBody,
                  associateIpToInstance: false,
                });
              },
            },
          ],
        };

      case AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS:
        if (requestBody.downSizeCluster)
          return {
            modalTitle: t('downsizeClusterNotSupported'),
            actions: [
              {
                title: t('yes'),
                onClick: () =>
                  applyRecommendation({
                    ...requestBody,
                    downSizeCluster: true,
                  }),
              },
              {
                title: t('no'),
                onClick: () => {
                  setShowPostActionModal(false);
                  setRequestBody({
                    ...requestBody,
                    downSizeCluster: false,
                  });
                },
              },
            ],
          };
        else
          return {
            modalTitle: t('takeSnapshotBeforeDeleting'),
            actions: [
              {
                title: t('yes'),
                onClick: () =>
                  applyRecommendation({
                    ...requestBody,
                    createRSSnapshot: true,
                  }),
              },
              {
                title: t('no'),
                onClick: () => {
                  applyRecommendation({
                    ...requestBody,
                    createRSSnapshot: false,
                  });
                },
              },
            ],
          };
    }
  };

  return (
    <>
      <ConfirmModal
        show={show}
        setShow={setShow}
        title={<>{getActions()?.modalTitle}</>}
        onClickYes={() => {
          setClickedButton(getActions()?.actions.at(0)?.title ?? '');
          getActions()?.actions.at(0)?.onClick();
        }}
        onClickNo={() => {
          setClickedButton(getActions()?.actions.at(1)?.title ?? '');
          getActions()?.actions.at(1)?.onClick();
        }}
        loadingYes={
          clickedButton === getActions()?.actions.at(0)?.title &&
          loadApplyRecommendation === REQUEST_STATUS.PROCESSING
        }
        loadingNo={
          clickedButton === getActions()?.actions.at(1)?.title &&
          loadApplyRecommendation === REQUEST_STATUS.PROCESSING
        }
        titleYes={getActions()?.actions.at(0)?.title}
        titleNo={getActions()?.actions.at(1)?.title}
      />
      {showPostActionModal && (
        <ConfirmModal
          show={showPostActionModal}
          setShow={setShowPostActionModal}
          title={<>{getPostAction()?.modalTitle}</>}
          onClickYes={() => {
            setClickedButton(getPostAction()?.actions.at(0)?.title ?? '');
            getPostAction()?.actions.at(0)?.onClick();
          }}
          onClickNo={() => {
            setClickedButton(getPostAction()?.actions.at(1)?.title ?? '');
            getPostAction()?.actions.at(1)?.onClick();
          }}
          loadingYes={
            clickedButton === getPostAction()?.actions.at(0)?.title &&
            loadApplyRecommendation === REQUEST_STATUS.PROCESSING
          }
          loadingNo={
            clickedButton === getPostAction()?.actions.at(1)?.title &&
            loadApplyRecommendation === REQUEST_STATUS.PROCESSING
          }
          titleYes={getPostAction()?.actions.at(0)?.title}
          titleNo={getPostAction()?.actions.at(1)?.title}
        />
      )}
    </>
  );
};

export default ApplyAwsRecommendation;
