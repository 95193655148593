import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import ColumnLineChart from 'components/ColumnLineChart';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { REQUEST_STATUS } from 'constants/requestBody';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import ColumnChart from 'components/ColumnChart';
import AccessibleDiv from 'components/AccessibleDiv';
import {
  ColouredCostByResourceType,
  ColouredCostByServiceType,
  MonthlyCostType,
} from 'types/dataTypes';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectTheme } from 'redux/themeSlice';
import { MONTH_YEAR_FORMAT, MONTH_YEAR_SHORT } from 'utils/date';
import {
  changeAlphaOfColor,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import TooltipContent from 'components/TooltipContent';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import GraphBreadcrumb from 'components/GraphBreadcrumb';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { getCostTrendsData } from '../../utils';
import { getCostTrendsColumns, getCostTrendsHeading } from '../../../../utils';

type CostTrendProps = {
  costTrendData: MonthlyCostType[];
  forecastedCost: MonthlyCostType[];
  costTrendRequestStatus: string[];
  isCostTrendTableView: boolean;
  setIsCostTrendTableView: (value: boolean) => void;
  hasForecastedCost: boolean;
  pdfView: boolean;
  costTrendStartMonth: string;
  costTrendEndMonth: string;
  forecastDateRange: string[];
  onChangeCostTrendDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  selectedTrendMonth: string;
  setSelectedTrendMonth: (val: string) => void;
  selectedTrendService: string;
  setSelectedTrendService: (val: string) => void;
  serviceData: ColouredCostByServiceType[];
  resourceData: ColouredCostByResourceType[];
  sliderValue?: { x: number; y: number };
  setSliderValue: (value: { x: number; y: number } | undefined) => void;
};

const CostTrend = ({
  costTrendData,
  forecastedCost,
  costTrendRequestStatus,
  isCostTrendTableView,
  hasForecastedCost,
  setIsCostTrendTableView,
  costTrendEndMonth,
  costTrendStartMonth,
  forecastDateRange,
  onChangeCostTrendDateRange,
  pdfView,
  selectedTrendMonth,
  setSelectedTrendMonth,
  selectedTrendService,
  setSelectedTrendService,
  serviceData,
  resourceData,
  sliderValue,
  setSliderValue,
}: CostTrendProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { theme } = useSelector(selectTheme);

  const [breadcrumbPaths, setBreadcrumbPaths] = useState<string[]>([]);
  const [activeLegends, setActiveLegends] = useState<string[]>([]);

  useEffect(() => {
    setBreadcrumbPathsByGraph();
  }, [selectedTrendMonth, selectedTrendService]);

  useEffect(() => {
    setActiveLegends(
      hasForecastedCost
        ? [
            t('costTrend.costWithoutSuffix'),
            t('costTrend.forecastedCost'),
            t('costTrend.trend'),
            t('costTrend.forecastedTrend'),
          ]
        : [t('costTrend.costWithoutSuffix'), t('costTrend.trend')]
    );
  }, [hasForecastedCost]);

  const setBreadcrumbPathsByGraph = () => {
    if (!selectedTrendMonth) {
      setBreadcrumbPaths([]);
      return;
    }

    if (!selectedTrendService) {
      setBreadcrumbPaths([
        t('graphHeadings.costTrend') + ' - ' + selectedTrendMonth,
        t('graphHeadings.costByService'),
      ]);
      return;
    }

    setBreadcrumbPaths([
      t('graphHeadings.costTrend') + ' - ' + selectedTrendMonth,
      t('graphHeadings.costByService') + ' - ' + selectedTrendService,
      t('graphHeadings.costByResources'),
    ]);
  };

  const filters = (
    <div>
      <DatePicker
        className="cursor-pointer"
        defaultValue={[
          moment(costTrendStartMonth),
          moment(forecastDateRange[1]),
        ]}
        onChange={onChangeCostTrendDateRange}
        picker="month"
        disabledDate={(current: any) => current > moment().endOf('day')}
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  const isDataIncluded = useCallback(
    (id: string) => {
      return activeLegends.includes(id);
    },
    [activeLegends]
  );

  const getData = useCallback(() => {
    const data: any[] = [];

    if (
      (isDataIncluded(t('costTrend.costWithoutSuffix')) ||
        isDataIncluded(t('costTrend.trend'))) &&
      (isDataIncluded(t('costTrend.forecastedCost')) ||
        isDataIncluded(t('costTrend.forecastedTrend')))
    ) {
      data.push({
        month: moment(costTrendStartMonth).format(MONTH_YEAR_SHORT),
        cost: null,
        type: t('costTrend.forecastedCost'),
      });
    }

    if (
      isDataIncluded(t('costTrend.costWithoutSuffix')) ||
      isDataIncluded(t('costTrend.trend'))
    ) {
      data.push(
        ...costTrendData.map((item) => ({
          ...item,
          type: t('costTrend.costWithoutSuffix'),
        }))
      );
    } else {
      data.push({
        month: null,
        cost: null,
        type: t('costTrend.costWithoutSuffix'),
      });
    }

    if (
      isDataIncluded(t('costTrend.forecastedCost')) ||
      isDataIncluded(t('costTrend.forecastedTrend'))
    ) {
      data.push(
        ...forecastedCost.map((item) => ({
          ...item,
          type: t('costTrend.forecastedCost'),
        }))
      );
    }

    return data;
  }, [costTrendData, forecastedCost, activeLegends]);

  const getLineAnnotations = useCallback(() => {
    const lineAnnotations: any[] = [];
    if (isDataIncluded(t('costTrend.forecastedTrend'))) {
      lineAnnotations.push({
        type: 'line',
        start: [
          costTrendData.length - 2,
          costTrendData[costTrendData.length - 2]?.cost,
        ],
        end: [
          costTrendData.length - 1,
          (forecastedCost.find(
            (item) => item.month === moment().format(MONTH_YEAR_SHORT)
          )?.cost ?? 0) +
            (costTrendData.find(
              (item) => item.month === moment().format(MONTH_YEAR_SHORT)
            )?.cost ?? 0),
        ],
        style: {
          stroke: isDataIncluded(t('costTrend.forecastedTrend'))
            ? theme.secondaryColor
            : 'transparent',
          lineDash: [3, 2],
          lineWidth: 2,
        },
      });
    }

    return lineAnnotations;
  }, [activeLegends, costTrendData, forecastedCost]);

  const lineStyleOverride = useCallback(
    ({ type }: any) => {
      if (type === t('costTrend.forecastedCost')) {
        return {
          lineDash: [1, 4],
          lineWidth: isDataIncluded(t('costTrend.forecastedTrend')) ? 2 : 0,
        };
      }
    },
    [activeLegends]
  );

  const getColumnColor = useCallback(
    (data: any) => {
      if (!isDataIncluded(data.type)) {
        return 'transparent';
      }

      if (data.type === t('costTrend.costWithoutSuffix')) {
        return theme.primaryColor;
      }

      return changeAlphaOfColor(theme.primaryColor, 40);
    },
    [activeLegends]
  );

  const getLineColor = useCallback(
    (_data: any) => {
      return !isDataIncluded(t('costTrend.trend'))
        ? 'transparent'
        : theme.secondaryColor;
    },
    [activeLegends]
  );

  const getLegendOverrides = useCallback(() => {
    const legends: any[] = [
      {
        name: t('costTrend.costWithoutSuffix'),
        unchecked: !isDataIncluded(t('costTrend.costWithoutSuffix')),
        marker: {
          symbol: 'square',
          style: {
            fill: theme.primaryColor,
          },
        },
      },
      {
        name: t('costTrend.trend'),
        unchecked: !isDataIncluded(t('costTrend.trend')),
        marker: {
          symbol: 'hyphen',
          style: {
            lineWidth: 2,
            stroke: theme.secondaryColor,
          },
        },
      },
    ];
    if (hasForecastedCost) {
      legends.splice(1, 0, {
        name: t('costTrend.forecastedCost'),
        unchecked: !isDataIncluded(t('costTrend.forecastedCost')),
        marker: {
          symbol: 'square',
          style: {
            fill: changeAlphaOfColor(theme.primaryColor, 40),
          },
        },
      });

      legends.splice(3, 0, {
        name: t('costTrend.forecastedTrend'),
        unchecked: !isDataIncluded(t('costTrend.forecastedTrend')),
        marker: {
          symbol: 'hyphen',
          style: {
            stroke: theme.secondaryColor,
            lineDash: [2, 1],
            lineWidth: 2,
          },
        },
      });
    }
    return legends;
  }, [activeLegends]);

  const getTooltipOverride = (title: string, data: any) => {
    if (
      !data?.some(
        (item: any) => item.data['type'] === t('costTrend.costWithoutSuffix')
      )
    )
      return (
        <TooltipContent
          title={title}
          data={data}
          prefixSymbol={currencySymbol}
        />
      );

    return (
      <TooltipContent
        title={title}
        data={data}
        prefixSymbol={currencySymbol}
        additionalContent={
          !selectedTrendService && (
            <span className="tooltip-deep-dive-label font-subHeader-small">
              {t('clickDeepDive')}
            </span>
          )
        }
      />
    );
  };

  const getGraphComponent = () => {
    if (!selectedTrendMonth)
      return (
        <ColumnLineChart
          data={getData()}
          xField="month"
          yField="cost"
          groupingField="type"
          xTitle={t('costTrend.month')}
          yTitle={t('costTrend.costInCurrency', {
            currencySymbol: currencySymbol,
          })}
          disableAnimation={pdfView}
          prefixSymbol={currencySymbol}
          additionalTooltipContent={
            (!selectedTrendMonth || !selectedTrendService) && (
              <span className="tooltip-deep-dive-label font-subHeader-small">
                {t('clickDeepDive')}
              </span>
            )
          }
          onClickColumn={(e: any) => {
            if (
              !isDashboardWithStaticData(selectedDashboard) &&
              moment(e?.data?.data?.month, MONTH_YEAR_SHORT).isSameOrBefore(
                moment(),
                'months'
              )
            )
              setSelectedTrendMonth(e?.data?.data?.month);
          }}
          isColumnClickable
          columnColorOverride={getColumnColor}
          lineAnnotations={getLineAnnotations()}
          lineStyleOverride={lineStyleOverride}
          lineColorOverride={getLineColor}
          legendOverride={getLegendOverrides()}
          tooltipContentOverride={getTooltipOverride}
          columnStyleOverride={(data: any) =>
            data.type === t('costTrend.costWithoutSuffix')
              ? {
                  cursor: 'pointer',
                }
              : {
                  cursor: 'default',
                }
          }
        />
      );

    if (!selectedTrendService)
      return (
        <ColumnChart
          data={serviceData?.map((item) => ({
            ...item,
            groupingField: item.service,
          }))}
          xField="service"
          yField="cost"
          groupingField="groupingField"
          xTitle={t('carbonFootprint.carbonEmissionByServices.services')}
          yTitle={t('costTrend.costInCurrency', {
            currencySymbol: currencySymbol,
          })}
          showAllLegend={pdfView}
          prefixSymbol={currencySymbol}
          disableAnimation={pdfView}
          columnColorOverride={serviceData.map((item: any) => item.color)}
          additionalClassNames={`${pdfView && 'pdf-wrapper'}`}
          additionalTooltipContent={
            <span className="tooltip-deep-dive-label font-subHeader-small">
              {t('clickDeepDive')}
            </span>
          }
          onClickColumn={(e: any) => {
            if (!isDashboardWithStaticData(selectedDashboard)) {
              setSelectedTrendService(e?.data?.data?.service);
            }
          }}
          showSlider={true}
          key="service"
          isColumnClickable
          sliderValues={pdfView ? sliderValue : undefined}
          onSliderValueChange={(x: number, y: number) => {
            setSliderValue({ x, y });
          }}
          showLegend={!pdfView}
        />
      );

    return (
      <ColumnChart
        data={resourceData?.map((item) => ({
          ...item,
          serviceGroupingField: item.resource,
        }))}
        xField="resource"
        yField="cost"
        groupingField="serviceGroupingField"
        xTitle={t('costByResource.resources')}
        yTitle={t('costTrend.costInCurrency', {
          currencySymbol: currencySymbol,
        })}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
        disableAnimation={pdfView}
        columnColorOverride={resourceData.map((item: any) => item.color)}
        additionalClassNames={`${pdfView && 'pdf-wrapper'}`}
        showLegend={!pdfView}
        showSlider={true}
        sliderValues={pdfView ? sliderValue : undefined}
        onSliderValueChange={(x: number, y: number) => {
          setSliderValue({ x, y });
        }}
        key="resource"
      />
    );
  };

  const getComponent = () => {
    return (
      <div className={`graph ${isCostTrendTableView && 'flex flex-gap-16'}`}>
        <div
          className={`graph-area ${
            isCostTrendTableView && 'with-table expand-hide'
          }`}
        >
          {getGraphComponent()}
        </div>
        {isCostTrendTableView && (
          <div className="tabular-view width-30">
            <Table
              pagination={false}
              loading={costTrendRequestStatus.includes(
                REQUEST_STATUS.PROCESSING
              )}
              dataSource={getCostTrendsData(
                selectedTrendMonth,
                selectedTrendService,
                {
                  trends: costTrendData,
                  service: serviceData,
                  resource: resourceData,
                }
              ).map((value, index) => {
                return { ...value, key: index };
              })}
              columns={getCostTrendsColumns(
                selectedTrendMonth,
                selectedTrendService
              )}
              scroll={{ y: 190 }}
              designVersion2
            />
          </div>
        )}
      </div>
    );
  };

  const getHeadingComponent = () => {
    if (!selectedTrendMonth) {
      return (
        <div className="graph-heading flex flex-column">
          <span>{t('graphHeadings.costTrend')}</span>
          {!isCostTrendTableView && !pdfView && (
            <span className="sub-title font-caption-bold">
              {t('clickBarInfo')}
            </span>
          )}
        </div>
      );
    }

    if (!selectedTrendService) {
      return (
        <div className="graph-heading flex flex-column">
          <AccessibleDiv
            className="flex flex-align-items-center cursor-pointer"
            onClick={() => setSelectedTrendMonth('')}
          >
            <Icon
              iconName={ICONS.ARROW_LEFT_S_LINE}
              className="previous-icon flex flex-end"
            />
            <span>{t('graphHeadings.costByService')}</span>
          </AccessibleDiv>
          <GraphBreadcrumb pathItems={breadcrumbPaths} />
        </div>
      );
    }

    return (
      <div className="graph-heading flex flex-column">
        <AccessibleDiv
          className="flex flex-align-items-center cursor-pointer"
          onClick={() => setSelectedTrendService('')}
        >
          <Icon
            iconName={ICONS.ARROW_LEFT_S_LINE}
            className="previous-icon flex flex-end"
          />
          <span>{t('graphHeadings.costByResources')}</span>
        </AccessibleDiv>
        <GraphBreadcrumb pathItems={breadcrumbPaths} />
      </div>
    );
  };

  return (
    <div className="cost-trend graph-card" id="graph-container">
      <GraphHeader
        heading={getCostTrendsHeading(selectedTrendMonth, selectedTrendService)}
        headingComponent={getHeadingComponent()}
        graphName="cost-trend"
        filters={!selectedTrendMonth ? filters : undefined}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostTrendTableView}
        setIsTableView={setIsCostTrendTableView}
        designVersion2
        excelData={getChartExcelExportData(
          getCostTrendsHeading(selectedTrendMonth, selectedTrendService),
          getCostTrendsData(selectedTrendMonth, selectedTrendService, {
            trends: costTrendData,
            service: serviceData,
            resource: resourceData,
          }),
          getCostTrendsColumns(selectedTrendMonth, selectedTrendService),
          {
            connectionName: selectedDashboard!.connectorName,
            startDate: costTrendStartMonth,
            endDate: costTrendEndMonth,
            month: selectedTrendMonth,
            service: selectedTrendService,
          }
        )}
      />
      <div className="stretch">
        <DashboardComponent
          component={getComponent()}
          requestStatus={costTrendRequestStatus[0]}
        />
      </div>
    </div>
  );
};

export default CostTrend;
