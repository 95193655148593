import i18n from 'i18n';
import { MonthlyCreditCostMetricType } from 'types/dataTypes';

export const CreditDistributionByRegionExportColumns = [
  { header: '#', key: 'slNo', width: 10, alignment: 'center', dataKey: 'slNo' },
  {
    header: i18n.t('creditByAwsRegion.region'),
    key: 'region',
    dataKey: 'region',
    width: 25,
    alignment: 'center',
  },
  {
    header: i18n.t('creditByAwsRegion.credit'),
    key: 'credit',
    dataKey: 'credit',
    width: 20,
    alignment: 'right',
  },
];

export const CostAndCreditBreakDownExportColumns = [
  { header: '#', key: 'slNo', width: 10, alignment: 'center', dataKey: 'slNo' },
  {
    header: i18n.t('costAndCreditBreakDown.month'),
    key: 'month',
    dataKey: 'month',
    width: 25,
    alignment: 'center',
  },
  {
    header: i18n.t('costAndCreditBreakDown.unblendedCost'),
    key: 'unblendedCost',
    dataKey: 'unblendedCost',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t('costAndCreditBreakDown.cost'),
    key: 'cost',
    dataKey: 'cost',
    width: 20,
    alignment: 'right',
  },
];

export const CreditDiscountBreakdownExportColumns = (
  monthlyCreditByCostTypeData: MonthlyCreditCostMetricType[]
) => {
  let columns: any[] = [
    {
      header: '#',
      key: 'slNo',
      width: 10,
      alignment: 'center',
      dataKey: 'slNo',
    },
    {
      header: i18n.t('creditDiscountBreakdown.month'),
      key: 'month',
      dataKey: 'month',
      width: 20,
      alignment: 'center',
    },
  ];
  monthlyCreditByCostTypeData.forEach((value) => {
    columns = [
      ...columns,
      {
        header: value.costMetricType,
        key: value.costMetricType,
        dataKey: value.costMetricType,
        width: 20,
        alignment: 'center',
      },
    ];
  });
  return columns;
};
