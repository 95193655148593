import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';

import {
  selectNavMenu,
  setExpandedMenu,
  setNavSubPath,
  setSelectedParentMenu,
} from 'redux/activeNavMenuSlice';
import { selectTheme } from 'redux/themeSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import { selectChatBot, setChats, setIsNewSession } from 'redux/chatBotSlice';
import { LoginTypes } from 'pages/LoginPage/constants';
import LogoutOverlay from 'components/LogoutOverlay';
import { ChatBot, ProfileIcon } from 'assets/icons';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import {
  NAVIGATION_MENU_DATA,
  NAVIGATION_MENU_ID,
} from 'constants/navigationMenu';
import { isPathPresentInMenuItems } from 'utils/navigationMenu';
import { getUserprofilePhoto } from 'utils/services';
import { Config } from 'config';
import { fetchChatHistory } from 'components/ChatBoxModal/services';
import { Sender } from 'components/ChatBoxModal/constants';
import { onApiCallError } from 'utils/handleErrors';

import ParentMenuItem from './components/ParentMenuItem';
import { ALL_FEATURES_NAV_MENU } from './constants';

import './index.scss';

const NavigationMenu = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { permissions } = useSelector(userAuthorization);
  const { expandedMenu, selectedParentMenu, showMenuWithTitle, isSubMenuOpen } =
    useSelector(selectNavMenu);
  const { theme } = useSelector(selectTheme);
  const { isChatBoxOpen, chats } = useSelector(selectChatBot);
  const loginType = Config.login_type;
  const selectedMenuRef = useRef<HTMLDivElement>(null);

  const [profileImage, setProfileImage] = useState<any>(ProfileIcon);

  useEffect(() => {
    if (loginType !== LoginTypes.MFA) {
      fetchImage();
    } else {
      setProfileImage(ProfileIcon);
    }

    fetchPreviousSessionChat();
  }, []);

  useEffect(() => {
    if (
      expandedMenu?.id === NAVIGATION_MENU_ID.ALL_FEATURES &&
      selectedParentMenu !== NAVIGATION_MENU_ID.ALL_FEATURES
    ) {
      return;
    }

    dispatch(
      setSelectedParentMenu(
        NAVIGATION_MENU_DATA.find((menu) =>
          isPathPresentInMenuItems(menu, location.pathname)
        )?.id
      )
    );

    dispatch(
      setExpandedMenu(
        NAVIGATION_MENU_DATA.find((menu) =>
          isPathPresentInMenuItems(menu, location.pathname)
        )
      )
    );

    dispatch(setNavSubPath([]));
  }, [location]);

  useEffect(() => {
    moveScrollToView();
  }, [selectedParentMenu]);

  /**
   * @function fetchImage
   * @description Function to fetch profile image
   */
  const fetchImage = async () => {
    try {
      const res: any = await getUserprofilePhoto();
      if (res.status > 400) {
        setProfileImage(ProfileIcon);
      } else {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setProfileImage(imageObjectURL);
      }
    } catch (error) {
      onApiCallError(error, false);
      setProfileImage(ProfileIcon);
    }
  };

  /**
   * @function fetchPreviousSessionChat
   * @description Function to fetch the previous chat history in the session
   */
  const fetchPreviousSessionChat = () => {
    fetchChatHistory()
      .then((res: any) => {
        const data = Array.isArray(res?.data) ? res?.data : [];
        const historyChats = data.map((item: any, index: number) => ({
          message: item.User ?? item?.AI,
          sender: item.User ? Sender.USER : Sender.BOT,
          key: index,
          approxCost: item?.approx_costing,
          totalTokens: item?.total_token,
        }));
        dispatch(setChats(historyChats));
      })
      .catch((e) => {
        onApiCallError(e, false);
      })
      .finally(() => {
        dispatch(setIsNewSession(false));
      });
  };

  const moveScrollToView = () => {
    selectedMenuRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  return (
    <div className={`navigation-menu hide-scroll flex flex-fit`}>
      <div className="parent-navigation-menu flex flex-column flex-space-between flex-fit">
        <div className="parent-menu-list flex flex-column flex-gap-4 flex-space-between flex-fit">
          <div className="feature-menu flex flex-column flex-space-between flex-fit">
            <div>
              {[
                ALL_FEATURES_NAV_MENU,
                ...NAVIGATION_MENU_DATA.filter(
                  (navMenu) =>
                    ![
                      NAVIGATION_MENU_ID.USER_MANAGEMENT.valueOf(),
                      NAVIGATION_MENU_ID.SETTINGS.valueOf(),
                    ].includes(navMenu.id) &&
                    navMenu.permissions.some(
                      (permission) => permissions[permission]
                    )
                ),
              ].map((menu) => (
                <ParentMenuItem
                  menu={menu}
                  selectedMenuRef={selectedMenuRef}
                  key={menu.id}
                />
              ))}
            </div>
            <ParentMenuItem
              menu={{
                id: NAVIGATION_MENU_ID.CHAT_BOT,
                title: 'chatBot',
                subSections: [],
                icon: ChatBot,
                permissions: ['profile'],
              }}
              selectedMenuRef={selectedMenuRef}
              key={NAVIGATION_MENU_ID.CHAT_BOT}
              showActiveBlink={!isChatBoxOpen && chats.length > 0}
            />
          </div>
          <div className="non-feature-menu">
            {NAVIGATION_MENU_DATA.filter(
              (navMenu) =>
                [
                  NAVIGATION_MENU_ID.USER_MANAGEMENT.valueOf(),
                  NAVIGATION_MENU_ID.SETTINGS.valueOf(),
                ].includes(navMenu.id) &&
                navMenu.permissions.some(
                  (permission) => permissions[permission]
                )
            ).map((menu) => (
              <ParentMenuItem
                menu={menu}
                selectedMenuRef={selectedMenuRef}
                key={menu.id}
              />
            ))}
          </div>
        </div>
        <div className="custom-options flex flex-column">
          <Tooltip
            overlay={<LogoutOverlay profileImage={profileImage} />}
            trigger="click"
            placement="topRight"
            arrow={false}
            overlayClassName="logout-tooltip"
            overlayStyle={{ left: '60px', top: 'calc(100vh - 11em)' }}
          >
            {showMenuWithTitle || isSubMenuOpen ? (
              <div className="user-banner flex flex-align-items-center flex-space-between">
                <div className="flex flex-align-items-center flex-gap-8">
                  <img
                    src={profileImage}
                    alt="user profile"
                    className="profile-image cursor-pointer"
                  />
                  <span className="user-name font-caption-bold">
                    {localStorage.getItem('name')}
                  </span>
                </div>
                <Icon
                  iconName={ICONS.LOGOUT_BOX_LINE}
                  color={theme.buttonIconColor}
                />
              </div>
            ) : (
              <img
                src={profileImage}
                alt="user profile"
                className="profile-image cursor-pointer"
              />
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default NavigationMenu;
