import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';

import Icon from 'components/Icon';
import { INPUT_SIZE } from 'constants/appearance';
import { ICONS } from 'constants/icons';
import debounce from 'lodash/debounce';
import { selectTheme } from 'redux/themeSlice';
import { UserInfoType } from 'types/userManagementConsole';
import { getClassnameBySize } from 'utils/dashboardUtils';
import { fetchAddUserList } from 'utils/services';

const { Option } = Select;

interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  excludeUserEmail: boolean;
  designVersion2: boolean;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    email: string;
    name: string;
  } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  excludeUserEmail,
  designVersion2,
  ...props
}: Readonly<DebounceSelectProps>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const { theme } = useSelector(selectTheme);
  const fetchRef = useRef(0);
  const { t } = useTranslation();

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        excludeUserEmail
          ? setOptions(
              newOptions?.filter(
                (option) => option.email !== localStorage.getItem('email')
              )
            )
          : setOptions(newOptions);

        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={(value) => debounceFetcher(value.length ? value : 'a')}
      showSearch
      onChange={() => setOptions([])}
      notFoundContent={fetching ? <Spin size="small" /> : t('notFound')}
      suffixIcon={<Icon iconName={ICONS.SEARCH_2_LINE} />}
      dropdownStyle={
        {
          '--primary-color': theme.primaryColor,
        } as React.CSSProperties
      }
      onFocus={() => {
        if (!options?.length) {
          debounceFetcher('a');
        }
      }}
      rootClassName={`${
        designVersion2
          ? `design-v2 ${getClassnameBySize(INPUT_SIZE.MEDIUM)}`
          : ''
      }`}
      {...props}
    >
      {options?.map((option) => (
        <Option key={option.email} label={option.name}>
          {`${option.name} ${option.email}`}
        </Option>
      ))}
    </Select>
  );
}

type AddUserSearchType = {
  placeholder: string;
  setUserInfo: (value: UserInfoType) => void;
  excludeUserEmail?: boolean;
  designVersion2?: boolean;
};

const AddUserSearch = ({
  placeholder,
  setUserInfo,
  excludeUserEmail = false,
  designVersion2 = false,
}: AddUserSearchType) => {
  const [value, setValue] = useState<string>();

  const onHandleSelect = (_e: any, option: any) => {
    setUserInfo({ name: option.label, email: option.value });
    setValue('');
  };

  return (
    <DebounceSelect
      className="full-width"
      value={value}
      placeholder={placeholder}
      fetchOptions={fetchAddUserList}
      onSelect={onHandleSelect}
      excludeUserEmail={excludeUserEmail}
      designVersion2={designVersion2}
    />
  );
};

export default AddUserSearch;
