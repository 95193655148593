import i18n from 'i18n';

import { store } from 'redux/store';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const DetectionHistoryExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('anomalyDetectionDashboard.detectionHistory.startDate'),
    key: 'startDate',
    dataKey: 'startDate',
    width: 20,
  },
  {
    header: i18n.t('anomalyDetectionDashboard.detectionHistory.service'),
    dataKey: 'dimensionValue',
    key: 'dimensionValue',
    width: 30,
  },
  {
    header: i18n.t('anomalyDetectionDashboard.detectionHistory.monitorName'),
    dataKey: 'monitorName',
    key: 'monitorName',
    width: 20,
  },
  {
    header: i18n.t('anomalyDetectionDashboard.detectionHistory.monitorType'),
    dataKey: 'monitorType',
    key: 'monitorType',
    width: 20,
  },
  {
    header: i18n.t(
      'anomalyDetectionDashboard.detectionHistory.impactPercentage'
    ),
    dataKey: 'impactPercentage',
    key: 'impactPercentage',
    alignment: 'right',
    width: 20,
  },
];

export const CostAndUsageExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('anomalyDetectionDashboard.usageAndCost.date'),
    key: 'date',
    dataKey: 'date',
    width: 20,
  },
  {
    header: i18n.t('anomalyDetectionDashboard.usageAndCost.actualCost', {
      currencySymbol: currencySymbol,
    }),
    dataKey: 'actualCost',
    key: 'actualCost',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t('anomalyDetectionDashboard.usageAndCost.expectedCost', {
      currencySymbol: currencySymbol,
    }),
    dataKey: 'expectedCost',
    key: 'expectedCost',
    width: 20,
    alignment: 'right',
  },
];
