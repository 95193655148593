import { GCP_SERVICES } from 'pages/ScorecardPage/constants';

export enum GCP_CUD_GRAPHS {
  CLOUD_SQL = 'cloud-sql',
  CLOUD_SPANNER = 'cloud-spanner',
  COMPUTE = 'compute',
}

export const GCP_GRAPHS_TO_SERVICES_MAP = [
  {
    key: GCP_CUD_GRAPHS.CLOUD_SQL,
    services: [GCP_SERVICES.CLOUD_SQL],
  },
  {
    key: GCP_CUD_GRAPHS.CLOUD_SPANNER,
    services: [GCP_SERVICES.CLOUD_SPANNER],
  },
  {
    key: GCP_CUD_GRAPHS.COMPUTE,
    services: [
      GCP_SERVICES.VMWARE_ENGINE,
      GCP_SERVICES.CLOUD_RUN,
      GCP_SERVICES.GKE,
      GCP_SERVICES.COMPUTE_ENGINE,
    ],
  },
];
