import i18n from 'i18n';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { sumBy, uniqBy } from 'lodash';
import yaml from 'js-yaml';

import {
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import {
  RECOMMENDATION_CATEGORIES,
  RecommendationCategoriesLabels,
} from 'constants/recommendations';
import { PROVIDER } from 'constants/cloudProviders';
import {
  RECOMMENDATION_QUERY_COLUMNS,
  RULESET_FILTERS_ADDITIONAL_QUERY_COLUMNS,
} from 'pages/CostOptimizationInsightsPage/components/GCP/RecommendationTable/constants';
import { SCORECARDS_TIMERANGE } from 'pages/ScorecardPage/constants';
import { fetchGcpCudEffectivenessDetails } from 'pages/ScorecardPage/services';
import {
  ConnectionListType,
  FilterGroupType,
  GranulateCostImpactType,
} from 'types/dashboard';
import { TypeAndNameByCost, CostByNameType } from 'types/dataTypes';
import { DashboardListType } from 'types/navigationMenu';
import { store } from 'redux/store';
import {
  formatArrayToString,
  formatArrayToStringByProvider,
  numberCommaSeparator,
} from 'utils/dataFormatterUtils';
import {
  DATE_FORMAT,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_HYPHEN_MONTH,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  YEAR_MONTH_WITHOUT_ZERO,
  getLastNMonthLabels,
  getMonthYearShortList,
} from 'utils/date';
import { CategoryRecommenderMappingType } from 'pages/CostOptimizationInsightsPage/types';
import { fetchRecommenderCategoryTypes } from 'pages/CostOptimizationInsightsPage/utils';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  generateGraphColors,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import { RecommendationSource } from 'pages/CostOptimizationInsightsPage/constants';
import { ContainerType } from 'types/GranulateRecommendation';
import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';
import { numberToMebibyteWithSuffix } from 'pages/CostOptimizationInsightsPage/components/Granulate/RecommendationPage/utils';
import { getGranulateConnectionsForConnector } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationTableWithWorkFlow/services';
import { GranulateConnectionsType } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationTableWithWorkFlow/types';

import {
  ColouredMonthlyCostByRecommender,
  CostByProject,
  MonthlyCostByCategory,
} from './types';
import { getAwsSavingsPlanTrendQuery } from '../AWSCostRecommendationSummaryDashboard/utils';
import {
  AzureDetailedSavingsTableColumns,
  GcpDetailedSavingsTableColumns,
} from './constants';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

/**
 * @function fetchSavingsPlanTrendData
 * @description Function to fetch the savings plan trend data
 * @param setRequestStatus set the status of the request
 * @param selectedDashboard selected dashboard
 * @param selectedConnection Object containing the connection details
 * @param startMonth start month for the filter
 * @param endMonth end month for the filter
 * @param setData set the data for the graph
 */
export const fetchSavingsPlanTrendData = (
  setRequestStatus: (status: string) => void,
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  startMonth: string,
  endMonth: string,
  setData: (data: any[]) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.AWS: {
      const request = getAwsSavingsPlanTrendQuery(
        selectedDashboard,
        selectedConnection,
        startMonth,
        endMonth
      );

      getChartData(request, selectedDashboard.connectorId)
        .then((res: any) => {
          const monthsList = getMonthYearShortList(startMonth, endMonth);
          const data: TypeAndNameByCost[] = [];
          monthsList.forEach((month) => {
            const currentMonthData = res.data.find(
              (item: any) =>
                moment(item.name, YEAR_HYPHEN_MONTH).format(
                  MONTH_YEAR_SHORT
                ) === month
            );
            if (currentMonthData)
              data.push({
                ...currentMonthData,
                name: month,
                cost: Number(currentMonthData.cost),
              });
          });
          setData(data);
          setRequestStatus(REQUEST_STATUS.SUCCESS);
        })
        .catch((e) => {
          onApiCallError(e, false, setRequestStatus);
        });
      break;
    }

    case PROVIDER.GCP: {
      const params = {
        connectorId: selectedDashboard.connectorId,
        granularity: SCORECARDS_TIMERANGE.MONTHLY,
        startDate: moment(startMonth).format(YEAR_MONTH_WITHOUT_SEPARATOR),
        endDate: moment(endMonth).format(YEAR_MONTH_WITHOUT_SEPARATOR),
      };
      fetchGcpCudEffectivenessDetails(params)
        .then((res: any) => {
          const monthsList = getMonthYearShortList(startMonth, endMonth);
          const data: CostByNameType[] = [];
          monthsList.forEach((month) => {
            const currentMonthData = res.data.responseData.find(
              (item: any) =>
                moment(item.startTime, YEAR_MONTH_WITHOUT_SEPARATOR).format(
                  MONTH_YEAR_SHORT
                ) === month
            );
            if (currentMonthData)
              data.push({
                name: month,
                cost: Number(currentMonthData.savingsEffectiveness),
              });
          });
          setData(data);
          setRequestStatus(REQUEST_STATUS.SUCCESS);
        })
        .catch((e) => {
          onApiCallError(e, false, setRequestStatus);
        });
      break;
    }
  }
};

/**
 * @function getEstimatedMonthlySavingsPerCategoryColumns
 * @description Function to get the columns for the estimated monthly savings per category
 * @param labels labels for the columns/months
 * @returns columns for the estimated monthly savings per category
 */
export const getEstimatedMonthlySavingsPerCategoryColumns = (
  labels: string[]
) => [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    align: 'left',
    width: 30,
  },
  {
    title: i18n.t('awsRecommendationSummary.category'),
    dataIndex: 'category',
    key: 'category',
  },
  ...labels.map((month) => ({
    title: month,
    dataIndex: month,
    key: month,
    render: (text: number) => currencySymbol + numberCommaSeparator(text),
  })),
];

/**
 * @function getMonthlySavingsByCategoryRequestBody
 * @description Function to get the request body for cost summary cards for different categories
 * @param recommenders recommenders of the category
 * @param startDate start date of the selected time range
 * @param endDate end date of the selected time range
 * @param rulesetFilters ruleset filters for which the data is fetched
 * @param isStaticData Boolean to indicate if the datasource is static data or not
 * @returns request body for API
 */
export const getMonthlySavingsByCategoryRequestBody = (
  recommenders: string[],
  startDate: string,
  endDate: string,
  rulesetFilters: FilterGroupType[],
  isStaticData: boolean
) => {
  return {
    recommendationsDto: {
      columns: [
        { label: 'projectNumber', field: QUERY_FIELDS.CLOUD_ENTITY_ID },
        { label: 'priority', field: QUERY_FIELDS.PRIORITY },
        { label: 'recommendation', field: QUERY_FIELDS.DESCRIPTION },
        { label: 'lastRefreshed', field: QUERY_FIELDS.LAST_REFRESH_TIME },
        {
          label: 'costSavings',
          field:
            'IF((IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1=-0,0,(IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1)',
        },
        { label: 'recommender', field: QUERY_FIELDS.RECOMMENDER },
        {
          label: 'recommender_subtype',
          field: QUERY_FIELDS.RECOMMENDER_SUBTYPE,
        },
        { label: 'recommendationName', field: QUERY_FIELDS.NAME },
        {
          label: 'recommendationState',
          field: isStaticData ? QUERY_FIELDS.ACTIVE : QUERY_FIELDS.STATE,
        },
        {
          label: 'targetResource',
          field: QUERY_FIELDS.TARGET_RESOURCES,
        },
        { label: 'recommendationCategory', field: QUERY_FIELDS.CATEGORY },
        ...RULESET_FILTERS_ADDITIONAL_QUERY_COLUMNS,
      ],
      subQuery: {
        columns: [
          { field: '*' },
          {
            label: 'seqnum',
            field:
              'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: isStaticData ? QUERY_FIELDS.ACTIVE : QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: '("ACTIVE","FAILED")',
                conjunctToNextFilter: 'AND',
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: startDate,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: endDate,
              },
            ],
          },
        ],
      },
      orderBy: [{ label: 'recommendationName', sort: ORDER_BY.ASCENDING }],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.SEQUENCE_NUMBER,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.RECOMMENDER,
              comparator: COMPARATORS.IN,
              value: formatArrayToString(recommenders),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    insightsDto: {
      columns: [
        { field: '*' },
        { label: 'insight_subtype', field: QUERY_FIELDS.SUBTYPE },
        {
          label: 'last_used_time',
          field:
            "CAST(TIMESTAMP_DIFF(CURRENT_TIMESTAMP(),TIMESTAMP(JSON_EXTRACT_SCALAR(content, '$.diskLastUseTime')), DAY) as STRING)",
        },
      ],
      structColumns: [
        { label: 'insight', field: QUERY_FIELDS.INSIGHT },
        {
          label: 'quantileFunctionMap',
          field:
            "ARRAY(SELECT as STRUCT CAST(JSON_EXTRACT_SCALAR(x, '$.quantileFunctionValue') AS FLOAT64) quantileFunctionValue, CAST(JSON_EXTRACT_SCALAR(x, '$.sampleProbability') AS FLOAT64) sampleProbability FROM UNNEST(JSON_EXTRACT_ARRAY(content, '$.points')) x)",
        },
      ],
      subQuery: {
        columns: [
          { field: '*' },
          {
            label: 'seqnum',
            field:
              'row_number() over (partition by recommendation_id, CONCAT(EXTRACT(YEAR FROM last_refreshed_date),EXTRACT(MONTH FROM last_refreshed_date)) order by last_refreshed_date desc)',
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.SEQUENCE_NUMBER,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CUSTOM_INSIGHTS,
      cached: true,
    },
    filterDto: {
      columns: [
        {
          label: 'totalPotentialSavings',
          field: QUERY_FIELDS.SUM_COST_SAVINGS,
        },
        {
          label: 'month',
          field: QUERY_FIELDS.CONCAT_EXTRACT_YEAR_LAST_REFRESHED,
        },
        { label: 'recommender', field: QUERY_FIELDS.RECOMMENDER },
      ],
      subQuery: {
        columns: [
          {
            label: 'recommendationName',
            field: QUERY_FIELDS.DISTINCT_RECOMMENDATION_NAME,
          },
          { field: 'lastRefreshed', label: 'lastRefreshed' },
          { field: 'costSavings', label: 'costSavings' },
          { field: 'recommender', label: 'recommender' },
        ],
        filterGroups: rulesetFilters,
      },
      groupBy: ['month', 'recommender'],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    recomTableJoinColumnName: 'recommendationName',
    insightTableJoinColumnName: 'recommendation_id',
  };
};

/**
 * @function getDetailedSavingsRequestBody
 * @description Function to return the request body for the detailed savings API
 * @param resource resource(s) for which the data is fetched
 * @param month month for which the data is fetched
 * @param rulesetFilters ruleset filters for which the data is fetched
 * @param isStaticData Boolean to indicate if the datasource is static data or not
 * @returns Object containing the request body
 */
export const getDetailedSavingsRequestBody = (
  resource: string,
  dateRange: RangeValue<Moment>,
  rulesetFilters: FilterGroupType[],
  isStaticData: boolean
) => {
  const startDate = dateRange?.[0]
    ?.startOf('month')
    ?.format(HYPHEN_DATE_FORMAT);
  const endDate = dateRange?.[1]?.endOf('month')?.format(HYPHEN_DATE_FORMAT);

  return {
    recommendationsDto: {
      columns: [
        {
          label: 'projectNumber',
          field: QUERY_FIELDS.CLOUD_ENTITY_ID,
        },
        {
          label: 'priority',
          field: QUERY_FIELDS.PRIORITY,
        },
        {
          label: 'recommendation',
          field: QUERY_FIELDS.DESCRIPTION,
        },
        {
          label: 'lastRefreshed',
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
        },
        {
          label: 'costSavings',
          field:
            'IF((IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1=-0,0,(IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1)',
        },
        {
          label: 'recommender',
          field: QUERY_FIELDS.RECOMMENDER,
        },
        {
          label: 'recommender_subtype',
          field: QUERY_FIELDS.RECOMMENDER_SUBTYPE,
        },
        { label: 'recommendationName', field: QUERY_FIELDS.NAME },
        {
          label: 'recommendationState',
          field: isStaticData ? QUERY_FIELDS.ACTIVE : QUERY_FIELDS.STATE,
        },
        {
          label: 'targetResource',
          field: QUERY_FIELDS.TARGET_RESOURCES,
        },
        { label: 'recommendationCategory', field: QUERY_FIELDS.CATEGORY },
        ...RULESET_FILTERS_ADDITIONAL_QUERY_COLUMNS,
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
          {
            label: 'seqnum',
            field:
              'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: isStaticData ? QUERY_FIELDS.ACTIVE : QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: '("ACTIVE","FAILED")',
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: startDate,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: endDate,
              },
            ],
          },
        ],
      },
      orderBy: [
        {
          label: 'recommendationName',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.SEQUENCE_NUMBER,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.RECOMMENDER,
              comparator: COMPARATORS.IN,
              value: formatArrayToString([resource]),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    insightsDto: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'insight_subtype',
          field: QUERY_FIELDS.SUBTYPE,
        },
        {
          label: 'last_used_time',
          field:
            "CAST(TIMESTAMP_DIFF(CURRENT_TIMESTAMP(),TIMESTAMP(JSON_EXTRACT_SCALAR(content, '$.diskLastUseTime')), DAY) as STRING)",
        },
      ],
      structColumns: [
        {
          label: 'insight',
          field: QUERY_FIELDS.INSIGHT,
        },
        {
          label: 'quantileFunctionMap',
          field:
            "ARRAY(SELECT as STRUCT CAST(JSON_EXTRACT_SCALAR(x, '$.quantileFunctionValue') AS FLOAT64) quantileFunctionValue,  CAST(JSON_EXTRACT_SCALAR(x, '$.sampleProbability') AS FLOAT64) sampleProbability FROM UNNEST(JSON_EXTRACT_ARRAY(content, '$.points')) x)",
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
          {
            label: 'seqnum',
            field:
              'row_number() over (partition by recommendation_id, CONCAT(EXTRACT(YEAR FROM last_refreshed_date),EXTRACT(MONTH FROM last_refreshed_date)) order by last_refreshed_date desc)',
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.SEQUENCE_NUMBER,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CUSTOM_INSIGHTS,
      cached: true,
    },
    filterDto: {
      columns: [
        {
          label: 'recommendationName',
          field: QUERY_FIELDS.DISTINCT_RECOMMENDATION_NAME,
        },
        ...RECOMMENDATION_QUERY_COLUMNS.filter(
          (item) => item.field !== QUERY_FIELDS.NAME
        ).map((item) => ({
          field: item.label,
          label: item.label,
        })),
      ],
      filterGroups: rulesetFilters,
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    recomTableJoinColumnName: 'recommendationName',
    insightTableJoinColumnName: 'recommendation_id',
  };
};

/**
 * @function getEstimatedSavingsPerProjectRequestBody
 * @description Function to return the request body for the estimated savings per project query
 * @returns Object containing the request body
 */
export const getEstimatedSavingsPerProjectRequestBody = (
  startDate: string,
  endDate: string,
  rulesetFilters: FilterGroupType[],
  isStaticData: boolean
) => {
  return {
    recommendationsDto: {
      columns: [
        {
          label: 'projectNumber',
          field: QUERY_FIELDS.CLOUD_ENTITY_ID,
        },
        {
          label: 'priority',
          field: QUERY_FIELDS.PRIORITY,
        },
        {
          label: 'recommendation',
          field: QUERY_FIELDS.DESCRIPTION,
        },
        {
          label: 'lastRefreshed',
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
        },
        {
          label: 'costSavings',
          field:
            'IF((IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1=-0,0,(IFNULL(units,0)+IFNULL(nanos,0)/1000000000)*-1)',
        },
        {
          label: 'recommender',
          field: QUERY_FIELDS.RECOMMENDER,
        },
        {
          label: 'recommender_subtype',
          field: QUERY_FIELDS.RECOMMENDER_SUBTYPE,
        },
        { label: 'recommendationName', field: QUERY_FIELDS.NAME },
        {
          label: 'recommendationState',
          field: isStaticData ? QUERY_FIELDS.ACTIVE : QUERY_FIELDS.STATE,
        },
        {
          label: 'targetResource',
          field: QUERY_FIELDS.TARGET_RESOURCES,
        },
        { label: 'recommendationCategory', field: QUERY_FIELDS.CATEGORY },
        ...RULESET_FILTERS_ADDITIONAL_QUERY_COLUMNS,
      ],
      subQuery: {
        columns: [
          { field: '*' },
          {
            label: 'seqnum',
            field:
              'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: isStaticData ? QUERY_FIELDS.ACTIVE : QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: '("ACTIVE","FAILED")',
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: startDate,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: endDate,
              },
            ],
          },
        ],
      },
      orderBy: [{ label: 'recommendationName', sort: ORDER_BY.ASCENDING }],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.SEQUENCE_NUMBER,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    insightsDto: {
      columns: [
        { field: '*' },
        {
          label: 'insight_subtype',
          field: QUERY_FIELDS.SUBTYPE,
        },
        {
          label: 'last_used_time',
          field:
            "CAST(TIMESTAMP_DIFF(CURRENT_TIMESTAMP(),TIMESTAMP(JSON_EXTRACT_SCALAR(content, '$.diskLastUseTime')), DAY) as STRING)",
        },
      ],
      structColumns: [
        {
          label: 'insight',
          field: QUERY_FIELDS.INSIGHT,
        },
        {
          label: 'quantileFunctionMap',
          field:
            "ARRAY(SELECT as STRUCT CAST(JSON_EXTRACT_SCALAR(x, '$.quantileFunctionValue') AS FLOAT64) quantileFunctionValue, CAST(JSON_EXTRACT_SCALAR(x, '$.sampleProbability') AS FLOAT64) sampleProbability FROM UNNEST(JSON_EXTRACT_ARRAY(content, '$.points')) x)",
        },
      ],
      subQuery: {
        columns: [
          { field: '*' },
          {
            label: 'seqnum',
            field:
              'row_number() over (partition by recommendation_id, CONCAT(EXTRACT(YEAR FROM last_refreshed_date),EXTRACT(MONTH FROM last_refreshed_date)) order by last_refreshed_date desc)',
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.SEQUENCE_NUMBER,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CUSTOM_INSIGHTS,
      cached: true,
    },
    filterDto: {
      columns: [
        { label: 'projectNumber', field: 'projectNumber' },
        {
          label: 'totalPotentialSavings',
          field: QUERY_FIELDS.SUM_COST_SAVINGS,
        },
      ],
      subQuery: {
        columns: [
          {
            label: 'recommendationName',
            field: QUERY_FIELDS.DISTINCT_RECOMMENDATION_NAME,
          },
          { field: 'projectNumber', label: 'projectNumber' },
          { field: 'costSavings', label: 'costSavings' },
        ],
        filterGroups: rulesetFilters,
      },
      groupBy: ['projectNumber'],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    recomTableJoinColumnName: 'recommendationName',
    insightTableJoinColumnName: 'recommendation_id',
  };
};

/**
 * @function fetchAzureSavingsByCategoriesData
 * @description Function to fetch the savings by categories data for Azure
 * @param connectorId selected dashboard connector ID
 * @param categoryRecommenderMapping category to recommender mapping for Azure
 * @param setRequestStatus set the status of the request
 * @param setMonthlySavingsByCategoryData set the data for the chart
 * @param isStaticData Boolean to indicate if the datasource is static data or not
 */
export const fetchAzureSavingsByCategoriesData = (
  connectorId: string,
  categoryRecommenderMapping: CategoryRecommenderMappingType[],
  setRequestStatus: (status: string) => void,
  setMonthlySavingsByCategoryData: (data: MonthlyCostByCategory[]) => void,
  isStaticData: boolean
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);
  const managementField = categoryRecommenderMapping
    .map(
      (item) =>
        `${formatArrayToStringByProvider(
          item.recommenders,
          PROVIDER.AZURE
        )} THEN '${item.category}'`
    )
    .join(` WHEN ${QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM} IN `);
  const requestBody = {
    columns: [
      {
        label: 'cost',
        field: isStaticData
          ? QUERY_FIELDS.EXTENDED_PROPERTIES_SAVINGS_AMOUNT_FLOAT_64
          : QUERY_FIELDS.EXTENDED_PROPERTIES_SAVINGS_AMOUNT_FLOAT,
      },
      {
        label: 'month',
        field: isStaticData
          ? QUERY_FIELDS.CONCAT_EXTRACT_YEAR_MONTH_PROPERTIES_LAST_UPDATED
          : QUERY_FIELDS.CONCAT_DATEPART_YEAR_MONTH_PROPERTIES_LAST_UPDATED,
      },
      {
        label: 'management',
        field: `CASE WHEN ${QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM} IN ${managementField} END`,
      },
    ],
    groupBy: ['management', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: false,
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
            comparator: COMPARATORS.NOT_EQUAL_TO,
            value: '',
          },
          {
            field: isStaticData
              ? QUERY_FIELDS.PROPERTIES_LAST_UPDATED
              : QUERY_FIELDS.CONVERT_DATETIME_PROPERTIES_LAST_UPDATED,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment()
              .subtract(3, 'months')
              .startOf('month')
              .format(HYPHEN_DATE_FORMAT),
          },
        ],
      },
    ],
  };

  getChartData(requestBody, connectorId)
    .then((res: any) => {
      const data: MonthlyCostByCategory[] = [];
      categoryRecommenderMapping.forEach((mapping) => {
        const resForCurrentCategory = res.data.filter(
          (item: any) => item.management === mapping.category
        );

        const costForCurrentCategory: number[] = [];
        getLastNMonthLabels(3).forEach((month) => {
          const costForMonth = resForCurrentCategory.find(
            (item: any) =>
              moment(item.month, YEAR_MONTH_WITHOUT_ZERO).format(
                MONTH_YEAR_FORMAT
              ) === month
          )?.cost;
          costForCurrentCategory.push(costForMonth || 0);
        });

        data.push({
          category: RecommendationCategoriesLabels.find(
            (item) => item.key === mapping.category
          )!.label,
          cost: costForCurrentCategory,
        });
      });

      setMonthlySavingsByCategoryData(data);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => onApiCallError(e, false, setRequestStatus));
};

/**
 * @function fetchAzureMonthlySavingsByEachCategoryData
 * @description Function to fetch the monthly savings by each category data for Azure
 * @param connectorId selected dashboard connector ID
 * @param categoryRecommenderMapping category to recommender mapping for Azure
 * @param wasteManagementStates waste management states for setting the data and request status
 * @param consumptionManagementStates consumption management states for setting the data and request status
 * @param purchaseTacticsStates purchase tactics states for setting the data and request status
 * @param isStaticData Boolean to indicate if the datasource is static data or not
 */
export const fetchAzureMonthlySavingsByEachCategoryData = (
  connectorId: string,
  categoryRecommenderMapping: CategoryRecommenderMappingType[],
  wasteManagementStates: {
    setData: (data: ColouredMonthlyCostByRecommender[]) => void;
    setRequestStatus: (status: string) => void;
  },
  consumptionManagementStates: {
    setData: (data: ColouredMonthlyCostByRecommender[]) => void;
    setRequestStatus: (status: string) => void;
  },
  purchaseTacticsStates: {
    setData: (data: ColouredMonthlyCostByRecommender[]) => void;
    setRequestStatus: (status: string) => void;
  },
  isStaticData: boolean
) => {
  Object.values(RECOMMENDATION_CATEGORIES).forEach((category) => {
    let setData: (data: ColouredMonthlyCostByRecommender[]) => void;
    let setRequestStatus: (status: string) => void;
    switch (category) {
      case RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT:
        setData = wasteManagementStates.setData;
        setRequestStatus = wasteManagementStates.setRequestStatus;
        break;
      case RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT:
        setData = consumptionManagementStates.setData;
        setRequestStatus = consumptionManagementStates.setRequestStatus;
        break;
      case RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS:
        setData = purchaseTacticsStates.setData;
        setRequestStatus = purchaseTacticsStates.setRequestStatus;
        break;
    }

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: isStaticData
            ? QUERY_FIELDS.EXTENDED_PROPERTIES_SAVINGS_AMOUNT_FLOAT_64
            : QUERY_FIELDS.EXTENDED_PROPERTIES_SAVINGS_AMOUNT_FLOAT,
        },
        {
          label: 'month',
          field: isStaticData
            ? QUERY_FIELDS.CONCAT_EXTRACT_YEAR_MONTH_PROPERTIES_LAST_UPDATED
            : QUERY_FIELDS.CONCAT_DATEPART_YEAR_MONTH_PROPERTIES_LAST_UPDATED,
        },
        {
          label: 'description',
          field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
        },
      ],
      groupBy: ['description', 'month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: false,
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
              comparator: COMPARATORS.IN,
              value: formatArrayToStringByProvider(
                categoryRecommenderMapping.find(
                  (item) => item.category === category
                )!.recommenders,
                PROVIDER.AZURE
              ),
            },
            {
              field: isStaticData
                ? QUERY_FIELDS.PROPERTIES_LAST_UPDATED
                : QUERY_FIELDS.CONVERT_DATETIME_PROPERTIES_LAST_UPDATED,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment()
                .subtract(3, 'months')
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
            },
          ],
        },
      ],
    };

    getChartData(requestBody, connectorId)
      .then((res: any) => {
        const data: ColouredMonthlyCostByRecommender[] = [];
        const recommendersForCategory = categoryRecommenderMapping.find(
          (item) => item.category === category
        )!.recommenders;
        const colors = generateGraphColors(recommendersForCategory.length);
        recommendersForCategory.forEach((recommender, index) => {
          const resForCurrentDescription = res.data.filter(
            (item: any) => item.description === recommender
          );
          const costForCurrentCategory: number[] = [];
          getLastNMonthLabels(3).forEach((month) => {
            const costForMonth = resForCurrentDescription.find(
              (item: any) =>
                moment(item.month, YEAR_MONTH_WITHOUT_ZERO).format(
                  MONTH_YEAR_FORMAT
                ) === month
            )?.cost;
            costForCurrentCategory.push(costForMonth || 0);
          });
          data.push({
            recommender: recommender,
            color: colors[index],
            cost: costForCurrentCategory,
          });
        });

        setData(data);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setRequestStatus));
  });
};

/**
 * @function fetchAzureDetailedSavingsData
 * @description Function to fetch the detailed savings data for a selected recommender for Azure
 * @param connectorId selected dashboard connector ID
 * @param recommender selected recommender
 * @param startDate start date of the selected time range
 * @param endDate end date of the selected time range
 * @param setData set the data for the table
 * @param setRequestStatus set the status of the request
 * @param isStaticData Boolean to indicate if the datasource is static data or not
 */
export const fetchAzureDetailedSavingsData = (
  connectorId: string,
  recommender: string,
  startDate: string,
  endDate: string,
  setData: (data: any[]) => void,
  setRequestStatus: (status: string) => void,
  isStaticData: boolean
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);
  const requestBody = {
    columns: [
      {
        label: 'recommendationProblem',
        field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
      },
      {
        label: 'cost',
        field: QUERY_FIELDS.PROPERTIES_SAVINGS,
      },
      {
        label: 'impactedField',
        field: QUERY_FIELDS.PROPERTIES_IMPACTED_FIELD,
      },
      {
        label: 'impactedValue',
        field: QUERY_FIELDS.PROPERTIES_IMPACTED_VALUE,
      },
      {
        label: 'state',
        field: isStaticData ? QUERY_FIELDS.ACTIVE : QUERY_FIELDS.STATE,
      },
      {
        label: 'recommendationCategory',
        field: QUERY_FIELDS.PROPERTIES_CATEGORY,
      },
      {
        label: 'lastUpdated',
        field: QUERY_FIELDS.PROPERTIES_LAST_UPDATED,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider([recommender], PROVIDER.AZURE),
          },
          {
            field: isStaticData
              ? QUERY_FIELDS.PROPERTIES_LAST_UPDATED
              : QUERY_FIELDS.CONVERT_DATETIME_PROPERTIES_LAST_UPDATED,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: startDate,
          },
          {
            field: isStaticData
              ? QUERY_FIELDS.PROPERTIES_LAST_UPDATED
              : QUERY_FIELDS.CONVERT_DATETIME_PROPERTIES_LAST_UPDATED,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: endDate,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };

  getChartData(requestBody, connectorId)
    .then((res: any) => {
      setData(
        res.data.map((item: any) => ({
          ...item,
          lastRefreshed: moment(item.lastRefreshed).format(DATE_FORMAT),
        }))
      );
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => onApiCallError(e, false, setRequestStatus));
};

/**
 * @function hasSavingsPlanTrendChart
 * @description Function to check if the savings plan trend chart is available for the provider
 * @param provider provider for selected dashboard
 * @returns boolean if the trend chart is available
 */
export const hasSavingsPlanTrendChart = (provider: string) => {
  return provider === PROVIDER.GCP;
};

/**
 * @function hasSavingsByProjectChart
 * @description Function to check if the savings by project chart is available for the provider
 * @param provider provider for selected dashboard
 * @returns boolean if the savings by project chart is available
 */
export const hasSavingsByProjectChart = (provider: string) => {
  return provider === PROVIDER.GCP;
};

/**
 * @function getGranulateDetailedRecommendationColumns
 * @description Returns the the list of columns for Granulate recommendations table
 * @param isExportColumns Boolean to indicate whether the columns are for export or UI
 * @returns List of Object containing the column details
 */
export const getGranulateDetailedRecommendationColumns = (
  isExportColumns: boolean = false
) => {
  return [
    {
      title: i18n.t('costOptimizationInsight.recommendationTable.accountId'),
      dataIndex: 'accountId',
      key: 'accountId',
      fixed: 'left',
    },
    {
      title: i18n.t('costOptimizationInsight.recommendationTable.resource'),
      dataIndex: 'resource',
      key: 'resource',
    },
    {
      title: i18n.t(
        'costOptimizationInsight.recommendationTable.recommendation'
      ),
      dataIndex: 'recommendation',
      key: 'recommendation',
    },
    {
      title: i18n.t('costOptimizationInsight.recommendationTable.timeStamp'),
      dataIndex: 'timeStamp',
      key: 'timeStamp',
    },
    {
      title: i18n.t('granulateInsightDashboard.namespace'),
      dataIndex: 'namespace',
      key: 'namespace',
    },
    {
      title: i18n.t('granulateInsightDashboard.containers'),
      dataIndex: 'containers',
      key: 'containers',
      isComponent: true,
    },
    {
      title: i18n.t('granulateInsightDashboard.hpaPolicy'),
      dataIndex: 'hpaPolicy',
      key: 'hpaPolicy',
      isComponent: true,
    },
    {
      title: isExportColumns
        ? `${i18n.t('granulateInsightDashboard.cpuRequest')} (${i18n.t(
            'granulateInsightDashboard.cpuRequestInfo'
          )})`
        : i18n.t('granulateInsightDashboard.cpuRequest'),
      dataIndex: 'cpuRequest',
      key: 'cpuRequest',
      isComponent: true,
    },
    {
      title: isExportColumns
        ? `${i18n.t('granulateInsightDashboard.memoryRequest')} (${i18n.t(
            'granulateInsightDashboard.memoryRequestInfo'
          )})`
        : i18n.t('granulateInsightDashboard.memoryRequest'),
      dataIndex: 'memoryRequest',
      key: 'memoryRequest',
      isComponent: true,
    },
    {
      title: i18n.t('granulateInsightDashboard.cpuReduction'),
      dataIndex: 'cpuReduction',
      key: 'cpuReduction',
      isComponent: true,
    },
    {
      title: i18n.t('granulateInsightDashboard.memoryReduction'),
      dataIndex: 'memoryReduction',
      key: 'memoryReduction',
      isComponent: true,
    },
    {
      title: i18n.t('granulateInsightDashboard.costImpact', {
        currencySymbol: '$',
      }),
      dataIndex: 'costImpact',
      key: 'costImpact',
      render: (text: any) => '$' + numberCommaSeparator(text),
    },
    {
      title: i18n.t('granulateInsightDashboard.yaml'),
      dataIndex: 'yaml',
      key: 'yaml',
      isComponent: true,
    },
  ];
};

/**
 * @function getDetailedSavingsTableColumns
 * @description Function to get the columns for the detailed savings table
 * @param provider provider for selected dashboard
 * @param recommender Recommender for which the detailed savings table columns are fetched
 * @param isExportColumns Boolean to indicate whether the columns are for export or table on UI
 * @returns columns for the detailed savings table
 */
export const getDetailedSavingsTableColumns = (
  provider: string,
  recommender: string,
  isExportColumns?: boolean
) => {
  if (recommender === RecommendationSource.GRANULATE) {
    return getGranulateDetailedRecommendationColumns(isExportColumns);
  }

  return provider === PROVIDER.GCP
    ? GcpDetailedSavingsTableColumns
    : AzureDetailedSavingsTableColumns;
};

/**
 * @function getSelectedRecommenderLabel
 * @description Function to get the label for the selected recommender
 * @param provider selected provider
 * @param selectedGraphCategory selected graph category
 * @param selectedRecommender selected recommender
 * @returns label for the selected recommender
 */
export const getSelectedRecommenderLabel = (
  provider: string,
  selectedGraphCategory: string,
  selectedRecommender: string
) => {
  if (selectedRecommender === RecommendationSource.GRANULATE) {
    return i18n.t('granulate');
  }

  return provider === PROVIDER.GCP
    ? fetchRecommenderCategoryTypes(selectedGraphCategory).find(
        (recommender) => recommender.key === selectedRecommender
      )?.label
    : selectedRecommender;
};

/**
 * @function getAllRecommenderOptionsByProvider
 * @description Function to get all the recommender options for the selected provider
 * @param provider selected provider
 * @param selectedGraphCategory selected graph category
 * @param azureCategoryRecommenderMapping category to recommender mapping for Azure
 * @returns all the recommender options for the selected provider for dropdown
 */
export const getAllRecommenderOptionsByProvider = (
  provider: string,
  selectedGraphCategory: string,
  azureCategoryRecommenderMapping: CategoryRecommenderMappingType[]
) => {
  return provider === PROVIDER.GCP
    ? fetchRecommenderCategoryTypes(selectedGraphCategory).map(
        (recommender) => ({
          value: recommender.key,
          label: recommender.label,
        })
      )
    : azureCategoryRecommenderMapping
        .find((item) => item.category === selectedGraphCategory)
        ?.recommenders.map((recommender) => ({
          value: recommender,
          label: recommender,
        })) ?? [];
};

/**
 * @function getConsolidatedEstimatedSavingsPerProject
 * @description Returns the consolidated Estimated cost per project of CSP and Granulate
 * @param cspEstimatedSavingsPerProjectData List of CSP estimated cost per project
 * @param granulateCostImpact List of Granulate cost impact per project
 * @returns List of consolidated cost of CSP and Granulate per project
 */
export const getConsolidatedEstimatedSavingsPerProject = (
  cspEstimatedSavingsPerProjectData: CostByProject[],
  granulateCostImpact: GranulateCostImpactType[]
) => {
  if (!granulateCostImpact.length) {
    return cspEstimatedSavingsPerProjectData;
  }

  return cspEstimatedSavingsPerProjectData.map((project) => ({
    ...project,
    cost:
      project.cost +
      (granulateCostImpact.find(
        (granulateCost) => granulateCost.projectId === project.project
      )?.totalCostImpact ?? 0),
  }));
};

/**
 * @function getConsolidatedConsumptionManagementCost
 * @description Returns the consolidated consumption management cost of CSP and Granulate
 * @param permissions Object containing the permissions list
 * @param selectedDashboard Object containing the selected dashbopard details
 * @param cspConsumptionManagementSavingsData List of CSP consumption management cost by recommender
 * @param granulateCostImpact List of Granulate cost impact per project
 * @returns List of consolidated cost of CSP per recommender and Granulate
 */
export const getConsolidatedConsumptionManagementCost = (
  permissions: { [key: string]: boolean },
  selectedDashboard: DashboardListType,
  cspConsumptionManagementSavingsData: ColouredMonthlyCostByRecommender[],
  granulateCostImpact: GranulateCostImpactType[]
): ColouredMonthlyCostByRecommender[] => {
  // Return CSP data if the Third Party Apps permissions is not present or provider is other than GCP
  if (
    !permissions.thirdPartyAppsRead ||
    selectedDashboard.connectorProvider !== PROVIDER.GCP ||
    isDashboardWithStaticData(selectedDashboard)
  ) {
    return cspConsumptionManagementSavingsData;
  }

  // Add color to the Granulate item in the chart
  const cspRecommendersCount = uniqBy(
    cspConsumptionManagementSavingsData,
    'recommender'
  ).length;
  const color = generateGraphColors(cspRecommendersCount + 1)[
    cspRecommendersCount
  ];

  const granulateEntry: ColouredMonthlyCostByRecommender = {
    recommender: i18n.t('granulate'),
    color,
    cost: [0, 0, sumBy(granulateCostImpact, 'totalCostImpact')], // Add Granulate cost impact for the current month
  };

  return [...cspConsumptionManagementSavingsData, granulateEntry];
};

/**
 * @function getHpaPolicyDisplayValue
 * @description Returns the HpaPolicy display value for export
 * @param recommendation Recommendation details for which the value is returned
 * @returns String value containing the HpaPolicy details
 */
const getHpaPolicyDisplayValue = (recommendation: RecommendationList) => {
  if (
    !recommendation.hpa ||
    (!recommendation.hpa.recommended_cpu && !recommendation.hpa.recommended_mem)
  ) {
    return 'N/A';
  }

  let hpaPolicy = '';

  // Add Recommended CPU value
  if (recommendation.hpa.recommended_cpu) {
    hpaPolicy += `${i18n.t('granulateInsightDashboard.cpu')}: ${
      recommendation.hpa.recommended_cpu
    }`;
  }

  // Append slash if both recommended memory and recommended cpu value are present.
  if (
    recommendation.hpa.recommended_mem &&
    recommendation.hpa.recommended_cpu
  ) {
    hpaPolicy += ' / ';
  }

  // Append recommended Memory value
  if (recommendation.hpa.recommended_mem) {
    hpaPolicy += `${i18n.t('granulateInsightDashboard.memory')}: ${
      recommendation.hpa.recommended_mem
    }`;
  }

  return hpaPolicy;
};

/**
 * @function getCpuRequestDisplayValues
 * @description Returns the CPU Request display value for export
 * @param recommendation Recommendation details for which the value is returned
 * @returns Object containing the CPU Request details
 */
const getCpuRequestDisplayValues = (recommendation: RecommendationList) => {
  if (!recommendation.containers || !recommendation.containers.length) {
    return 'N/A';
  }

  return recommendation.containers.map((data) => {
    const currentCpu = data.current_cpu
      ? Number((data.current_cpu / 1000).toFixed(4))
      : '-';

    const recommendedCpu = data.recommended_cpu
      ? Number((data.recommended_cpu / 1000).toFixed(4))
      : '-';

    return {
      container: data.name,
      currentCpu,
      recommendedCpu,
    };
  });
};

/**
 * @function getMemoryRequestDisplayValues
 * @description Returns the Memory Request display value for export
 * @param recommendation Recommendation details for which the value is returned
 * @returns Object containing the Memory Request details
 */
const getMemoryRequestDisplayValues = (recommendation: RecommendationList) => {
  if (!recommendation.containers || !recommendation.containers.length) {
    return 'N/A';
  }

  return recommendation.containers.map((data) => {
    const currentMemory = data.current_mem
      ? numberToMebibyteWithSuffix(data.current_mem)
      : '-';

    const recommendedMemory = data.recommended_memory
      ? numberToMebibyteWithSuffix(data.recommended_memory)
      : '-';

    return {
      container: data.name,
      currentMemory,
      recommendedMemory,
    };
  });
};

/**
 * @function getCpuReductionDisplayValues
 * @description Returns the CPU Reduction display value for export
 * @param recommendation Recommendation details for which the value is returned
 * @returns List containing the CPU Reduction details
 */
const getCpuReductionDisplayValues = (recommendation: RecommendationList) => {
  if (!recommendation.containers || !recommendation.containers.length) {
    return 'N/A';
  }

  return recommendation.containers.map((data) => ({
    container: data.name,
    cpuReduction: data.avg_impact_on_cluster_cpu + '%',
  }));
};

/**
 * @function getMemoryReductionDisplayValues
 * @description Returns the Memory Reduction display value for export
 * @param recommendation Recommendation details for which the value is returned
 * @returns List containing the Memory Reduction details
 */
const getMemoryReductionDisplayValues = (
  recommendation: RecommendationList
) => {
  if (!recommendation.containers || !recommendation.containers.length) {
    return 'N/A';
  }

  return recommendation.containers.map((data) => ({
    container: data.name,
    memoryReduction: data.avg_impact_on_cluster_mem + '%',
  }));
};

/**
 * @function getYamlDisplayValue
 * @description Returns the Yaml display value
 * @param recommendation Recommendation details for which the value is returned
 * @returns String containing the yaml display value
 */
const getYamlDisplayValue = (recommendation: RecommendationList) => {
  const yamlObject = yaml.load(recommendation.patch_yaml!);
  const formattedYaml = yaml.dump(yamlObject);
  return formattedYaml;
};

/**
 * @function getDetailsSavingsTableDataForExport
 * @description Returns the list of recommendations for detailed savings table for export
 * @param recommender Recommender for which the recommendations list are exported
 * @param recommendations Recommendations details for to be exported
 * @returns List of modified recommendations for export
 */
export const getDetailsSavingsTableDataForExport = (
  recommender: string,
  recommendations: any[]
) => {
  if (recommender === RecommendationSource.GRANULATE) {
    return recommendations.map((recommendation) => ({
      ...recommendation,
      containers: recommendation.containers
        ?.map((container: ContainerType) => container.name)
        .join(';'),
      hpaPolicy: getHpaPolicyDisplayValue(recommendation),
      cpuRequest: getCpuRequestDisplayValues(recommendation),
      memoryRequest: getMemoryRequestDisplayValues(recommendation),
      cpuReduction: getCpuReductionDisplayValues(recommendation),
      memoryReduction: getMemoryReductionDisplayValues(recommendation),
      yaml: getYamlDisplayValue(recommendation),
    }));
  }

  return recommendations;
};

export const fetchGranulateConnections = async (
  connectorId: string,
  setData: (val: GranulateConnectionsType[]) => void
) => {
  const params = {
    connectorId,
  };

  await getGranulateConnectionsForConnector(params)
    .then((res: any) => {
      setData(res.data);
    })
    .catch((e) => {
      onApiCallError(e, false);
      setData([]);
    });
};
