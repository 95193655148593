import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ControlComponent from 'components/DashboardControl';
import DropdownCheckbox from 'components/DropdownCheckbox';
import DatePicker from 'components/DatePicker';
import { INPUT_SIZE } from 'constants/appearance';
import { CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT } from 'constants/userConsole';
import { selectDashboard } from 'redux/dashboardSlice';
import {
  DATE_FORMAT,
  dateNYearsFromToday,
  HYPHEN_DATE_FORMAT,
} from 'utils/date';
import { getDateRangeHandler } from 'utils/graphUtils';
import {
  getAccountData,
  getAccountsFilterLabelByProvider,
} from 'pages/OverviewPage/components/ConnectionsDashboard/components/ServiceViewsControls/utils';
import { PROVIDER } from 'constants/cloudProviders';
import { REQUEST_STATUS } from 'constants/requestBody';

import {
  getChartCriteriaList,
  getServiceAggregatorLabelByProvider,
} from '../../utils';
import { fetchServiceAggregatorsList } from './utils';

type ProjectDeepDiveDashboardControlsProps = {
  accountsStates: {
    accounts: string[];
    setAccounts: (val: string[]) => void;
    selectedAccounts: string[];
    setSelectedAccounts: (val: string[]) => void;
    accountsReqStatus: string;
    setAccountsReqStatus: (val: string) => void;
  };
  serviceAggregatorStates: {
    selectedServiceAggregators: string[];
    setSelectedServiceAggregators: (val: string[]) => void;
    serviceAggregatorsReqStatus: string;
    setServiceAggregatorsReqStatus: (val: string) => void;
  };
  chartCriteriaStates: {
    chartCriteriaFilters: string[];
    setChartCriteriaFilters: (val: string[]) => void;
  };
  dateFilterStates: {
    startDate: string;
    setStartDate: (val: string) => void;
    endDate: string;
    setEndDate: (val: string) => void;
  };
};

const ProjectDeepDiveDashboardControls = ({
  accountsStates,
  serviceAggregatorStates,
  chartCriteriaStates,
  dateFilterStates,
}: ProjectDeepDiveDashboardControlsProps) => {
  const { t } = useTranslation();
  const {
    selectedDashboard,
    selectedConnection,
    tagsFilters,
    selectedDashboardView,
  } = useSelector(selectDashboard);

  const [serviceAggregators, setServiceAggregators] = useState<string[]>([]);

  useEffect(() => {
    accountsStates.setAccounts([]);
    accountsStates.setSelectedAccounts([]);

    if (selectedDashboard!.connectorProvider === PROVIDER.AZURE) {
      getAccountData(
        selectedDashboard!,
        selectedConnection!,
        accountsStates.setAccounts,
        accountsStates.setSelectedAccounts,
        accountsStates.setAccountsReqStatus
      );
    }
  }, []);

  useEffect(() => {
    setServiceAggregators([]);
    serviceAggregatorStates.setSelectedServiceAggregators([]);

    // Fetch the service aggegators for AZURE if there are accounts selected
    if (
      selectedDashboard!.connectorProvider !== PROVIDER.AZURE ||
      accountsStates.selectedAccounts.length
    ) {
      fetchServiceAggregatorsList(
        {
          dashboard: selectedDashboard!,
          tagsFilters: tagsFilters,
          dashboardView: selectedDashboardView,
          connection: selectedConnection!,
        },
        serviceAggregatorStates.setServiceAggregatorsReqStatus,
        setServiceAggregators,
        serviceAggregatorStates.setSelectedServiceAggregators,
        accountsStates.selectedAccounts
      );
    }
  }, [accountsStates.selectedAccounts]);

  const disabledDate = (current: any) => {
    return (
      current > moment().endOf('day') ||
      current < moment().endOf('day').subtract(2, 'years')
    );
  };

  const CHART_CRITERIA_LIST = getChartCriteriaList(
    selectedDashboard!.connectorProvider
  );

  return (
    <ControlComponent
      filters={[
        {
          title: t('listByDimensions'),
          filter: (
            <DropdownCheckbox
              itemOptions={CHART_CRITERIA_LIST}
              selectedItems={chartCriteriaStates.chartCriteriaFilters}
              setSelectedItems={chartCriteriaStates.setChartCriteriaFilters}
              designVersion2
              size={INPUT_SIZE.SMALL}
            />
          ),
          minimizedText:
            chartCriteriaStates.chartCriteriaFilters.length ===
            CHART_CRITERIA_LIST.length
              ? t('all')
              : CHART_CRITERIA_LIST.filter((each) =>
                  chartCriteriaStates.chartCriteriaFilters.includes(each.value)
                )
                  .map((each) => each.title)
                  .join(', '),
          onClear: () => chartCriteriaStates.setChartCriteriaFilters([]),
        },
        {
          title: getAccountsFilterLabelByProvider(
            selectedDashboard?.connectorProvider
          ),
          filter: (
            <DropdownCheckbox
              itemOptions={accountsStates.accounts.map((account) => ({
                title: account,
                value: account,
              }))}
              selectedItems={accountsStates.selectedAccounts}
              setSelectedItems={accountsStates.setSelectedAccounts}
              designVersion2
              size={INPUT_SIZE.SMALL}
              dataTestId="accounts-filter"
              loading={
                accountsStates.accountsReqStatus === REQUEST_STATUS.PROCESSING
              }
            />
          ),
          minimizedText:
            accountsStates.selectedAccounts
              .join(', ')
              .substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...',
          onClear: () => accountsStates.setSelectedAccounts([]),
          show: selectedDashboard!.connectorProvider === PROVIDER.AZURE,
        },
        {
          title: getServiceAggregatorLabelByProvider(
            selectedDashboard!.connectorProvider
          ),
          filter: (
            <DropdownCheckbox
              itemOptions={serviceAggregators.map((data) => ({
                title: data,
                value: data,
              }))}
              selectedItems={serviceAggregatorStates.selectedServiceAggregators}
              setSelectedItems={
                serviceAggregatorStates.setSelectedServiceAggregators
              }
              designVersion2
              size={INPUT_SIZE.SMALL}
            />
          ),
          minimizedText:
            serviceAggregatorStates.selectedServiceAggregators
              .join(', ')
              .substring(0, CONTROL_MINIMIZED_TEXT_LENGTH_LIMIT) + '...',
          onClear: () =>
            serviceAggregatorStates.setSelectedServiceAggregators([]),
        },
        {
          title: t('projectDeepDiveSummary.dropDown.duration'),
          filter: (
            <DatePicker
              defaultValue={[
                moment(dateFilterStates.startDate),
                moment(dateFilterStates.endDate),
              ]}
              className="full-width"
              onChange={getDateRangeHandler(
                dateFilterStates.setStartDate,
                dateFilterStates.setEndDate
              )}
              disabledDate={disabledDate}
              format={DATE_FORMAT}
              designVersion2
            />
          ),
          minimizedText:
            moment(dateFilterStates.startDate).format(DATE_FORMAT) +
            ' to ' +
            moment(dateFilterStates.endDate).format(DATE_FORMAT),
          onClear: () => {
            dateFilterStates.setStartDate(dateNYearsFromToday(1));
            dateFilterStates.setEndDate(moment().format(HYPHEN_DATE_FORMAT));
          },
        },
      ]}
    />
  );
};

export default ProjectDeepDiveDashboardControls;
