import { Table as AntTable, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { RangeValue } from 'rc-picker/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';

import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import ColumnLineChart from 'components/ColumnLineChart';
import TooltipContent from 'components/TooltipContent';
import SelectDropdown from 'components/Select';
import DatePicker from 'components/DatePicker';
import DashboardComponent from 'components/DashboardComponent';
import Icon from 'components/Icon';
import { ICONS_SIZE } from 'constants/icons';
import { KeyValueTypes, WasteManagementType } from 'types/dataTypes';

import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  DATE_FORMAT,
  DD_MMM_YYYY,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
} from 'utils/date';
import { selectScorecard } from 'redux/scorecardSlice';
import {
  ScoreCardsTimeRangeOptions,
  SCORECARDS_TIMERANGE,
} from 'pages/ScorecardPage/constants';
import {
  getDateLabelsByGranularity,
  getRowExpandIcon,
} from 'pages/ScorecardPage/utils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { COLORS } from 'constants/graphConfig';
import { generateGraphColors } from 'utils/dashboardUtils';
import { INPUT_SIZE } from 'constants/appearance';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import {
  getWasteManagementExcelData,
  getWasteManagementConsolidatedData,
  getTableData,
  getTableExpandedItemsData,
  getCategories,
  getWasteManagementCategoriesLabelsByProvider,
} from '../WasteManagementScorecardDashboard/utils';

import './index.scss';

type WasteManagementProps = {
  wasteManagementData: WasteManagementType;
  requestStatus: string[];
  pdfView: boolean;
  selectedTimeRange: string;
  setSelectedTimeRange: (val: string) => void;
  wasteManagementDateRange: string[];
  setWasteManagementDateRange: (val: string[]) => void;
  wasteManagementTableView: boolean;
  setWasteManagementTableView: (val: boolean) => void;
  weightagePercentage: KeyValueTypes[];
};

const WasteManagement = ({
  wasteManagementData,
  requestStatus,
  pdfView,
  selectedTimeRange,
  setSelectedTimeRange,
  wasteManagementDateRange,
  setWasteManagementDateRange,
  wasteManagementTableView,
  setWasteManagementTableView,
  weightagePercentage,
}: WasteManagementProps) => {
  const { t } = useTranslation();
  const { selectedConnection } = useSelector(selectScorecard);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const [expandedRow, setExpandedRow] = useState(-1);

  const [timeRangeLabels, setTimeRangeLabels] = useState<string[]>([]);
  const [chartColors, setChartColors] = useState<string[]>([]);

  useEffect(() => {
    setChartColors(
      generateGraphColors(
        getCategories(selectedConnection?.provider).length + 1
      )
    );
  }, [selectedConnection]);

  useEffect(() => {
    setTimeRangeLabels(
      getDateLabelsByGranularity(
        selectedTimeRange,
        wasteManagementDateRange,
        selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
          ? MONTH_YEAR_FORMAT
          : DD_MMM_YYYY
      )
    );
  }, [selectedTimeRange, wasteManagementDateRange]);

  /**
   * @function onChangeTimeRageOption
   * @description Callback function for time range select
   * @param value value selected
   */
  const onChangeTimeRageOption = (value: string) => {
    setSelectedTimeRange(value);
    if (value === SCORECARDS_TIMERANGE.MONTHLY.valueOf()) {
      setWasteManagementDateRange([
        moment()
          .subtract(5, 'months')
          .startOf('month')
          .format(HYPHEN_DATE_FORMAT),
        moment().format(HYPHEN_DATE_FORMAT),
      ]);
      return;
    }

    setWasteManagementDateRange([
      moment().subtract(7, 'days').startOf('day').format(HYPHEN_DATE_FORMAT),
      moment().subtract(1, 'days').format(HYPHEN_DATE_FORMAT),
    ]);
  };

  /**
   * @function onChangeWasteManagementMonthRange
   * @description Callback function for date range change for month picker
   * @param _dates Date ranges selected
   * @param dateString Date range in strings
   */
  const onChangeWasteManagementMonthRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    const startDate = moment(dateString[0])
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT);
    let endDate = moment(dateString[1])
      .endOf('month')
      .format(HYPHEN_DATE_FORMAT);
    if (
      moment(dateString[1]).format(MONTH_YEAR_FORMAT) ===
      moment().format(MONTH_YEAR_FORMAT)
    ) {
      endDate = moment().format(HYPHEN_DATE_FORMAT);
    }
    setWasteManagementDateRange([startDate, endDate]);
  };

  /**
   * @function onChangeWasteManagementDateRange
   * @description Callback function for date range change for date picker
   * @param _dates Date ranges selected
   * @param dateString Date range in strings
   */
  const onChangeWasteManagementDateRange = (
    dates: RangeValue<Moment>,
    _dateString: [string, string]
  ) => {
    if (!dates?.[0] || !dates?.[1]) {
      return;
    }

    const startDate = moment(dates[0], DATE_FORMAT)
      .startOf('day')
      .format(HYPHEN_DATE_FORMAT);
    const endDate = moment(dates[1], DATE_FORMAT)
      .endOf('day')
      .format(HYPHEN_DATE_FORMAT);

    setWasteManagementDateRange([startDate, endDate]);
  };

  const getColumns = () => {
    const columns: ColumnProps<any>[] = [
      {
        title: t('scorecard.wasteManagement.items'),
        align: 'left',
        dataIndex: 'item',
        fixed: 'left',
        key: 'item',
        width: 250,
      },
    ];
    timeRangeLabels.forEach((label) => {
      columns.push({
        title: label,
        dataIndex: label,
        align: 'center',
        key: label,
        width: 100,
      });
    });
    return columns;
  };

  const filters = (
    <div className="filters">
      <SelectDropdown
        value={selectedTimeRange}
        onChange={onChangeTimeRageOption}
        options={ScoreCardsTimeRangeOptions}
        designVersion2
        size={INPUT_SIZE.SMALL}
      />
      <DatePicker
        value={[
          moment(wasteManagementDateRange[0], HYPHEN_DATE_FORMAT),
          moment(wasteManagementDateRange[1], HYPHEN_DATE_FORMAT),
        ]}
        onChange={
          selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
            ? onChangeWasteManagementMonthRange
            : onChangeWasteManagementDateRange
        }
        disabledDate={(current: any) =>
          current > moment().subtract(1, 'days').endOf('day')
        }
        picker={
          selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY ? 'month' : 'date'
        }
        format={
          selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
            ? MONTH_YEAR_FORMAT
            : DATE_FORMAT
        }
        designVersion2={true}
      />
    </div>
  );

  const TableTooltipContent = (
    <div>
      <span className="font-button">
        {t('scorecard.wasteManagement.clientAssignedWeightageUsed')}
      </span>
      {weightagePercentage
        .map(
          (item) =>
            (getWasteManagementCategoriesLabelsByProvider(
              selectedConnection?.provider ?? ''
            ).find((labelObj) => labelObj.value === item.key)?.label ??
              item.key) +
            ' - ' +
            item.value +
            '%'
        )
        .join(' | ')}
    </div>
  );

  const getExpandedRowTable = (record: any) => (
    <Table
      pagination={false}
      columns={getColumns()}
      dataSource={getTableExpandedItemsData(
        wasteManagementData.savingsCost,
        timeRangeLabels,
        record.item,
        true
      ).map((data, index) => ({
        ...data,
        key: index,
      }))}
      designVersion2={true}
    />
  );

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = ({ expandable, expanded, onExpand, record }: any) =>
    expandable ? (
      <Icon
        className="collapse-icon"
        iconName={getRowExpandIcon(expanded)}
        size={ICONS_SIZE.LG}
        onClick={(e) => onExpand(record, e)}
      />
    ) : null;

  const getSummaryRowComponent = () => (
    <AntTable.Summary>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell
          className="footer"
          index={0}
          rowSpan={2}
          colSpan={2}
        >
          <div className="flex flex-gap-8 flex-align-items-center">
            {t('scorecard.wasteManagement.effectiveWasteManagementRate')}
            <Tooltip
              placement="bottom"
              overlayClassName="waste-management-tooltip"
              title={TableTooltipContent}
            >
              <InformationLineIcon className="info-icon cursor-pointer" />
            </Tooltip>
          </div>
        </AntTable.Summary.Cell>
        {wasteManagementData.wasteManagementRate.map((item, index) => (
          <AntTable.Summary.Cell
            index={index + 2}
            align="center"
            key={item.date}
          >
            {`${numberCommaSeparator(item.percentage)}%`}
          </AntTable.Summary.Cell>
        ))}
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  const TableComponent = (
    <div className="table-view">
      <Table
        pagination={false}
        scroll={{ y: 'calc(100vh - 24em)' }}
        dataSource={getTableData(
          selectedConnection?.provider,
          getWasteManagementConsolidatedData(
            selectedConnection?.provider,
            wasteManagementData
          ),
          timeRangeLabels,
          true
        ).map((data, index) => ({
          ...data,
          key: index,
        }))}
        rowClassName={(record: any) =>
          `${'cursor-pointer'} ${
            expandedRow === record.key && 'expanded-row-item'
          }`
        }
        expandable={{
          columnWidth: 20,
          expandedRowRender: getExpandedRowTable,
          expandedRowKeys: [expandedRow],
          onExpand: (expanded: boolean, record: any) => {
            if (expanded) {
              setExpandedRow(record.key);
            } else {
              setExpandedRow(-1);
            }
          },
          expandRowByClick: true,
          expandIcon: getExpandIcon,
        }}
        columns={getColumns()}
        summary={getSummaryRowComponent}
        designVersion2={true}
      />
    </div>
  );

  const getTooltipContentOverride = useCallback(
    (title: string, data: any[]) => {
      return (
        <TooltipContent
          title={title}
          data={data.map((obj) => ({
            ...obj,
            suffix:
              obj.name ===
              t('scorecard.wasteManagement.effectiveWasteManagementRate')
                ? '%'
                : '',
            prefix:
              obj.name ===
              t('scorecard.wasteManagement.effectiveWasteManagementRate')
                ? ''
                : currencySymbol,
          }))}
        />
      );
    },
    [currencySymbol]
  );

  const ChartComponent = (
    <ColumnLineChart
      plots={[
        {
          type: 'line',
          options: {
            data: getWasteManagementConsolidatedData(
              selectedConnection?.provider,
              wasteManagementData
            ),
            xField: 'date',
            yField: 'value',
            seriesField: 'category',
            xAxis: {
              title: {
                text:
                  selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
                    ? t('scorecard.wasteManagement.month')
                    : t('scorecard.wasteManagement.date'),
              },
            },
            yAxis: {
              title: {
                text: t('scorecard.wasteManagement.ratio'),
              },
              line: {
                style: {
                  stroke: COLORS.fnGrey,
                  lineWidth: 1,
                  opacity: 0.7,
                },
              },
            },
            point: {
              size: 5,
              shape: 'custom-point',
            },
            color: chartColors,
            animation: !pdfView,
          },
        },
        {
          type: 'area',
          options: {
            data: wasteManagementData.wasteManagementRate.map((item) => ({
              ...item,
              groupingField: t(
                'scorecard.wasteManagement.effectiveWasteManagementRate'
              ),
            })),
            xField: 'date',
            yField: 'percentage',
            seriesField: 'groupingField',
            xAxis: {
              title: {
                text:
                  selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
                    ? t('scorecard.wasteManagement.month')
                    : t('scorecard.wasteManagement.date'),
              },
            },
            yAxis: {
              line: {
                style: {
                  stroke: COLORS.fnGrey,
                  lineWidth: 1,
                  opacity: 0.7,
                },
              },
              grid: null,
              position: 'right',
              title: {
                text: t(
                  'scorecard.wasteManagement.effectiveWasteManagementRate'
                ),
              },
            },
            meta: {
              value: {
                formatter: (v: any) => `${v}%`,
              },
            },
            smooth: true,
            color: chartColors.slice(-1),
            areaStyle: {
              fill: `l(270) 0:#ffffff 0.5:${chartColors.slice(
                -1
              )} 1:${chartColors.slice(-1)}`,
            },
            line: {
              style: {
                lineDash: [5, 5],
              },
            },
            animation: !pdfView,
          },
        },
      ]}
      markerOverride={(_name: any, _index: any, item: any) => {
        if (
          item.name ===
          t('scorecard.wasteManagement.effectiveWasteManagementRate')
        ) {
          return {
            symbol: 'hyphen',
            style: {
              lineDash: [2, 2],
            },
          };
        }
      }}
      tooltipContentOverride={getTooltipContentOverride}
    />
  );

  const getComponent = () => (
    <div className="graph">
      {wasteManagementTableView ? TableComponent : ChartComponent}
    </div>
  );

  return (
    <div
      className={`waste-management graph-card ${
        wasteManagementTableView && 'tabular-view'
      }`}
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.wasteManagementEffectiveness')}
        graphName="waste-management"
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        isTableViewSwitch={!pdfView}
        isTableView={wasteManagementTableView}
        setIsTableView={setWasteManagementTableView}
        filters={filters}
        excelData={getWasteManagementExcelData(
          wasteManagementData,
          selectedTimeRange,
          wasteManagementDateRange,
          selectedConnection
        )}
        designVersion2={true}
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray(requestStatus)}
      />
    </div>
  );
};

export default WasteManagement;
