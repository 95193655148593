import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import { ApplicationDetailsDataType } from '../../type';
import { getApplicationDetailsExcelData } from './utils';

import './index.scss';

type ApplicationDetailsType = {
  data: ApplicationDetailsDataType[];
  isLoading: boolean;
  showExpandedGraphModal: boolean;
  excelFilters: any[];
  setSelectedNamespaces: (value: string[]) => void;
  selectedNamespaces: string[];
  namespaceData: { namespace: string }[];
};

const ApplicationDetails = ({
  data,
  isLoading,
  showExpandedGraphModal,
  excelFilters,
  namespaceData,
  selectedNamespaces,
  setSelectedNamespaces,
}: ApplicationDetailsType) => {
  const { t } = useTranslation();

  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: t('consolidatedDashboard.applicationDetails.name'),
      dataIndex: 'name',
      key: 'name',
      width: 120,
      render: (text: any, _record: any, _index: number) => {
        return <span className="font-caption">{text}</span>;
      },
      fixed: true,
    },
    {
      title: t('consolidatedDashboard.applicationDetails.namespace'),
      dataIndex: 'namespace',
      key: 'namespace',
      width: 120,
      sorter: (a: any, b: any) => a.namespace.localeCompare(b.namespace),
      render: (text: any, _record: any, _index: number) => {
        return <span className="font-caption">{text}</span>;
      },
    },
    {
      title: t('consolidatedDashboard.applicationDetails.pods'),
      dataIndex: 'pods',
      key: 'pods',
      width: 80,
      align: 'center',
      sorter: (a: any, b: any) => a.pods - b.pods,
      render: (text: any, _record: any, _index: number) => {
        return text ? <span className="font-caption">{text}</span> : '-';
      },
    },
    {
      title: (
        <div>
          <span>
            {t('consolidatedDashboard.applicationDetails.cpuUtilization')}
          </span>
        </div>
      ),
      dataIndex: 'cpuUtilisation',
      key: 'cpuUtilisation',
      width: 150,
      align: 'center',
      sorter: (a: any, b: any) => a.cpuUtilisation - b.cpuUtilisation,
      render: (text: any, _record: any, _index: number) => {
        return (
          <span className="font-caption">
            {text.toFixed(4)} {t('consolidatedDashboard.cores')}
          </span>
        );
      },
    },
    {
      title: t('consolidatedDashboard.applicationDetails.memoryUtilization'),
      dataIndex: 'memoryUtilisation',
      key: 'memoryUtilisation',
      width: 120,
      align: 'center',
      sorter: (a: any, b: any) => a.memoryUtilisation - b.memoryUtilisation,
      render: (text: any, _record: any, _index: number) => {
        return (
          <span className="font-caption">
            {text.toFixed(2)} {t('consolidatedDashboard.MI')}
          </span>
        );
      },
    },
    {
      title: t('consolidatedDashboard.applicationDetails.costCredit', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'costAfterCredit',
      key: 'costAfterCredit',
      width: 120,
      align: 'center',
      sorter: (a: any, b: any) => a.costAfterCredit - b.costAfterCredit,
      render: (text: any, _record: any, _index: number) => {
        return <span className="font-caption">{(text ?? 0).toFixed(2)}</span>;
      },
    },
  ];

  const filters = (
    <div className="filters">
      <GraphFilterDropdown
        additionalClassNames="width-25"
        allData={namespaceData}
        selectedData={namespaceData.filter((item) =>
          selectedNamespaces.includes(item.namespace)
        )}
        setSelectedData={(selectedItems: any[]) => {
          setSelectedNamespaces(selectedItems.map((item) => item.namespace));
        }}
        setLabels={setSelectedNamespaces}
        valueSuffix={t('containerInsight.usageMeteringLabels.namespaces')}
        fieldName={DROPDOWN_VALUE_FIELDS.NAMESPACE}
      />
    </div>
  );

  const getComponent = () => (
    <div
      className={`application-details flex flex-column flex-gap-8 full-height ${
        showExpandedGraphModal && 'full-height'
      } `}
      id="graph-container"
    >
      <GraphHeader
        heading={t(
          'consolidatedDashboard.applicationDetails.applicationDetails'
        )}
        isDownloadable={true}
        graphName="application-details"
        excelData={getApplicationDetailsExcelData(data, excelFilters)}
        filters={filters}
        className="flex-gap-4"
        designVersion2
      />
      <Table
        columns={columns}
        dataSource={data.map((item, index: number) => ({
          ...item,
          key: index + 1,
        }))}
        pagination={true}
        fillContainer
        scroll={{
          y: showExpandedGraphModal ? 517 : 330,
          scrollToFirstRowOnChange: false,
        }}
        loading={isLoading}
        designVersion2
      />
    </div>
  );

  return <>{getComponent()}</>;
};
export default ApplicationDetails;
