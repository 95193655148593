import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  tagsAndLabels,
  setTagsAndLabelComponent,
} from 'redux/tagsAndLabelsSlice';
import Button from 'components/Button';
import { LeftBackArrowIcon } from 'assets/icons';
import { getProviderLogo } from 'utils/providerDetails';
import { TAGS_AND_LABELS_COMPONENTS } from 'pages/TagsAndLabelsPage/constants';

import './index.scss';

type HeaderProps = {
  disableSubmitButton: boolean;
  onSubmit: () => void;
  isSubmitting: boolean;
  buttonTitle: string;
};

const Header = ({
  disableSubmitButton,
  onSubmit,
  isSubmitting,
  buttonTitle,
}: HeaderProps) => {
  const dispatch = useDispatch();
  const { selectedTagConnection } = useSelector(tagsAndLabels);

  /**
   * @function onHandleBackButton
   * @description Function to handle back button clicked
   */
  const onHandleBackButton = () => {
    dispatch(
      setTagsAndLabelComponent(TAGS_AND_LABELS_COMPONENTS.SELECT_CONNECTION)
    );
  };
  return (
    <div className="tags-labels-header new-page-header flex flex-align-items-center">
      <div className="flex flex-space-between full-width">
        <div className="header-title flex flex-align-items-center">
          <LeftBackArrowIcon
            className="back-arrow cursor-pointer"
            onClick={onHandleBackButton}
          />
          <img
            width={35}
            height={35}
            src={getProviderLogo(selectedTagConnection?.provider!)}
            alt={`${selectedTagConnection?.provider} Logo`}
          />
          <div className="flex flex-column">
            <div className="title-container flex flex-row">
              <div className="title modal-heading">
                {t('tagsOrLabels.allTagsOrLabels')}
              </div>
            </div>
            <div className="sub-title-container flex font-caption-bold">
              <div>{selectedTagConnection?.displayName}</div>
              <div className="data-source-type">
                {selectedTagConnection?.dataSourceType}
              </div>
            </div>
          </div>
        </div>
        <div className="header-right flex flex-align-items-center">
          <div className="connection-list"></div>
          <Button
            onClick={onSubmit}
            title={buttonTitle}
            disabled={disableSubmitButton}
            loading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
