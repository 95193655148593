import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  costOptimizationInsights,
  setCurrentRecommendationView,
  setSelectedCostOptimizationInsightsConnection,
  setSelectedCostOptimizationInsightsNav,
} from 'redux/costOptimizationInsightsSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import Loader from 'components/Loader';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { setTableViewEnabled } from 'redux/dashboardSlice';

import InsightsHeader from './components/InsightsHeader';
import GCPRecommendationTable from './components/GCP/RecommendationTable';
import GCPStatusSummaryDashbaord from './components/GCP/GCPStatusSummaryDashboard';
import AWSRecommendationTable from './components/AWS/RecommendationTable';
import AWSStatusSummaryDashboard from './components/AWS/AWSStatusSummaryDashboard';
import AzureStatusSummaryDashbaord from './components/Azure/AzureStatusSummaryDashboard';
import AzureRecommendationTable from './components/Azure/RecommendationTable';
import ConsolidatedRecommendationTable from './components/ConsolidatedRecommendationTable';
import {
  DEFAULT_VIEW_DROPDOWN_OPTIONS,
  COST_OPTIMIZATION_INSIGHTS_DASHBOARD_NAVIGATION,
  COST_OPTIMIZATION_INSIGHTS_NAV,
} from './constants';

import './index.scss';

const CostOptimizationInsightsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedCostOptimizationInsightsNav,
    selectedCostOptimizationInsightsConnection,
  } = useSelector(costOptimizationInsights);
  const { permissions } = useSelector(userAuthorization);

  const [connectionListLoading, setConnectionListLoading] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isEmptyConnectionList, setIsEmptyConnectionList] = useState(false);

  useEffect(() => {
    if (!permissions.costControlRead) {
      dispatch(
        setSelectedCostOptimizationInsightsNav(
          COST_OPTIMIZATION_INSIGHTS_NAV.WASTE_MANAGEMENT_RECOMMENDATIONS
        )
      );
    }
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
      dispatch(setSelectedCostOptimizationInsightsConnection(null));
    };
  }, []);

  const getPageComponent = () => {
    if (connectionListLoading === REQUEST_STATUS.PROCESSING) {
      return <Loader />;
    }

    if (
      selectedCostOptimizationInsightsNav ===
      COST_OPTIMIZATION_INSIGHTS_NAV.CONSUMPTION_MANAGEMENT_RECOMMENDATIONS
    ) {
      return <ConsolidatedRecommendationTable />;
    }

    if (selectedCostOptimizationInsightsConnection?.provider === PROVIDER.GCP) {
      switch (selectedCostOptimizationInsightsNav) {
        case COST_OPTIMIZATION_INSIGHTS_NAV.COST_SAVINGS_STATUS_SUMMARY:
          return <GCPStatusSummaryDashbaord />;
        case COST_OPTIMIZATION_INSIGHTS_NAV.WASTE_MANAGEMENT_RECOMMENDATIONS:
        case COST_OPTIMIZATION_INSIGHTS_NAV.PURCHASE_TACTICS_RECOMMENDATIONS:
          return <GCPRecommendationTable />;
      }
    }
    if (selectedCostOptimizationInsightsConnection?.provider === PROVIDER.AWS) {
      switch (selectedCostOptimizationInsightsNav) {
        case COST_OPTIMIZATION_INSIGHTS_NAV.COST_SAVINGS_STATUS_SUMMARY:
          return <AWSStatusSummaryDashboard />;
        case COST_OPTIMIZATION_INSIGHTS_NAV.WASTE_MANAGEMENT_RECOMMENDATIONS:
        case COST_OPTIMIZATION_INSIGHTS_NAV.PURCHASE_TACTICS_RECOMMENDATIONS:
          return <AWSRecommendationTable />;
      }
    } else if (
      selectedCostOptimizationInsightsConnection?.provider === PROVIDER.AZURE
    ) {
      switch (selectedCostOptimizationInsightsNav) {
        case COST_OPTIMIZATION_INSIGHTS_NAV.COST_SAVINGS_STATUS_SUMMARY:
          return <AzureStatusSummaryDashbaord />;
        case COST_OPTIMIZATION_INSIGHTS_NAV.WASTE_MANAGEMENT_RECOMMENDATIONS:
        case COST_OPTIMIZATION_INSIGHTS_NAV.PURCHASE_TACTICS_RECOMMENDATIONS:
          return <AzureRecommendationTable />;
      }
    }
  };

  if (!permissions?.cloudConnectorList) {
    return <div className="page-content">{t('noConnectionsPermission')}</div>;
  }

  if (isEmptyConnectionList && connectionListLoading === REQUEST_STATUS.ERROR) {
    return (
      <div className="page-content">
        {t('costOptimizationInsight.errorFetchingConnections')}
      </div>
    );
  }

  if (isEmptyConnectionList) {
    return (
      <div className="page-content">
        {t('costOptimizationInsight.noConnectionsFound')}
      </div>
    );
  }

  return (
    <div className="cost-optimization-insights">
      <div className="cost-optimization-insights-header new-page-header">
        <InsightsHeader
          connectionListLoading={connectionListLoading}
          setConnectionListLoading={setConnectionListLoading}
          setIsEmptyConnectionList={setIsEmptyConnectionList}
        />
      </div>
      <div className="page-content">
        <HorizontalNavigationMenu
          menuItems={COST_OPTIMIZATION_INSIGHTS_DASHBOARD_NAVIGATION.filter(
            (nav) => permissions[`${nav.permissions}`]
          ).map((navMenu) => (
            <Menu.Item
              onClick={() => {
                dispatch(
                  setCurrentRecommendationView(DEFAULT_VIEW_DROPDOWN_OPTIONS[0])
                );
                dispatch(setSelectedCostOptimizationInsightsNav(navMenu.id));
                dispatch(setTableViewEnabled(false));
              }}
              key={navMenu.id}
              eventKey={navMenu.id}
              className={`font-caption-bold menu ${
                navMenu.id === selectedCostOptimizationInsightsNav &&
                'active-menu'
              }`}
            >
              {t(`dashNav.${navMenu.title}`)}
            </Menu.Item>
          ))}
          selectedKeys={[selectedCostOptimizationInsightsNav]}
        />
        <div className="margin-24">{getPageComponent()}</div>
      </div>
    </div>
  );
};

export default CostOptimizationInsightsPage;
