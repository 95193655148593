import {
  AGGREGATORS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
} from 'constants/requestBody';
import i18n from 'i18n';

export enum PodCpuUtilizationDimensionEnum {
  MIN_CPU_REQUEST_BY_POD = 'minimumCPURequestByPOD',
  CPU_RESERVED_CAPACITY = 'cpuReservedCapacity',
  CPU_USAGE_BY_SYSTEM = 'cpuUsageBySystem',
  CPU_UTILIZATION_OF_POD = 'cpuUtilizationOfPod',
  CPU_UTILIZATION_PERCENT = 'cpuUtilizationPercent',
}

export const POD_CPU_UTILIZATION_DIMENSIONS = [
  {
    dataLabel: PodCpuUtilizationDimensionEnum.MIN_CPU_REQUEST_BY_POD,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.minimumCPURequestByPOD'
    ),
    field: QUERY_FIELDS.POD_CPU_REQUEST,
  },
  {
    dataLabel: PodCpuUtilizationDimensionEnum.CPU_RESERVED_CAPACITY,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.cpuReservedCapacity'
    ),
    field: QUERY_FIELDS.POD_CPU_RESERVED_CAPACITY,
  },
  {
    dataLabel: PodCpuUtilizationDimensionEnum.CPU_USAGE_BY_SYSTEM,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.cpuUsageBySystem'
    ),
    field: QUERY_FIELDS.POD_CPU_USAGE_SYSTEM,
  },
  {
    dataLabel: PodCpuUtilizationDimensionEnum.CPU_UTILIZATION_OF_POD,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.cpuUtilizationOfPod'
    ),
    field: QUERY_FIELDS.POD_CPU_UTILIZATION,
  },
  {
    dataLabel: PodCpuUtilizationDimensionEnum.CPU_UTILIZATION_PERCENT,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.cpuUtilizationPercent'
    ),
    field: QUERY_FIELDS.POD_CPU_UTILIZATION_OVER_POD_LIMIT,
  },
];

export enum PodMemoryUtilizationDimensionEnum {
  CACHE_MEMORY_OF_POD_MEMORY = 'cacheMemoryOfPodMemory',
  MAX_MEMORY_ALLOCATED_FOR_POD = 'maximumMemoryAllocatedForPod',
  MAX_MEMORY_USES_IN_POD = 'maxMemoryUsesInPod',
  MIN_MEMORY_ALLOCATED = 'minimumMemoryAllocated',
  MEMORY_RESERVED_FOR_SYSTEM_USAGE = 'memoryReservedForSystemUsage',
  CURRENT_MEMORY_USAGE = 'currentMemoryUsage',
  OVERALL_CPU_UTILIZATION_FOR_POD = 'overallCPUUtilizationForPod',
  RATIO_OF_MEMORY_UTILIZATION_TO_INITIAL_REQ = 'ratioOfMemoryUtilizationToInitialReq',
}

export const POD_MEMORY_UTILIZATION_DIMENSIONS = [
  {
    dataLabel: PodMemoryUtilizationDimensionEnum.CACHE_MEMORY_OF_POD_MEMORY,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.cacheMemoryOfPodMemory'
    ),
    field: QUERY_FIELDS.POD_MEMORY_CACHE,
  },
  {
    dataLabel: PodMemoryUtilizationDimensionEnum.MAX_MEMORY_ALLOCATED_FOR_POD,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.maximumMemoryAllocatedForPod'
    ),
    field: QUERY_FIELDS.POD_MEMORY_LIMIT,
  },
  {
    dataLabel: PodMemoryUtilizationDimensionEnum.MAX_MEMORY_USES_IN_POD,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.maxMemoryUsesInPod'
    ),
    field: QUERY_FIELDS.POD_MEMORY_MAX_USAGE,
  },
  {
    dataLabel: PodMemoryUtilizationDimensionEnum.MIN_MEMORY_ALLOCATED,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.minimumMemoryAllocated'
    ),
    field: QUERY_FIELDS.POD_MEMORY_REQUEST,
  },
  {
    dataLabel:
      PodMemoryUtilizationDimensionEnum.MEMORY_RESERVED_FOR_SYSTEM_USAGE,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.memoryReservedForSystemUsage'
    ),
    field: QUERY_FIELDS.POD_MEMORY_RESERVED_CAPACITY,
  },
  {
    dataLabel: PodMemoryUtilizationDimensionEnum.CURRENT_MEMORY_USAGE,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.currentMemoryUsage'
    ),
    field: QUERY_FIELDS.POD_MEMORY_USAGE,
  },
  {
    dataLabel:
      PodMemoryUtilizationDimensionEnum.OVERALL_CPU_UTILIZATION_FOR_POD,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.overallCPUUtilizationForPod'
    ),
    field: QUERY_FIELDS.POD_MEMORY_UTILIZATION,
  },
  {
    dataLabel:
      PodMemoryUtilizationDimensionEnum.RATIO_OF_MEMORY_UTILIZATION_TO_INITIAL_REQ,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.ratioOfMemoryUtilizationToInitialReq'
    ),
    field: QUERY_FIELDS.POD_MEMORY_UTILIZATION_OVER_POD_LIMIT,
  },
];

export enum PodNetworkUtilizationDimensionEnum {
  NETWORK_TRAFFIC_RECEIVED = 'networkTrafficReceived',
  NETWORK_TRAFFIC_TRANSMITTED = 'networkTrafficTransmitted',
  TOTAL_AMOUNT_OF_NETWORK_TRAFFIC = 'totalAmountOfNetworkTraffic',
}

export const POD_NETWORK_UTILIZATION_DIMENSIONS = [
  {
    dataLabel: PodNetworkUtilizationDimensionEnum.NETWORK_TRAFFIC_RECEIVED,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.networkTrafficReceived'
    ),
    field: QUERY_FIELDS.POD_NETWORK_RX_BYTES,
  },
  {
    dataLabel: PodNetworkUtilizationDimensionEnum.NETWORK_TRAFFIC_TRANSMITTED,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.networkTrafficTransmitted'
    ),
    field: QUERY_FIELDS.POD_NETWORK_TX_BYTES,
  },
  {
    dataLabel:
      PodNetworkUtilizationDimensionEnum.TOTAL_AMOUNT_OF_NETWORK_TRAFFIC,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.totalAmountOfNetworkTraffic'
    ),
    field: QUERY_FIELDS.POD_NETWORK_TOTAL_BYTES,
  },
];

export const PodMemoryUtilizationExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.dimension'),
    key: 'dimension',
    dataKey: 'dimension',
  },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.memoryInBytes'),
    key: 'utilization',
    dataKey: 'utilization',
    width: 20,
    alignment: 'right',
  },
];

export const PodNetworkUtilizationExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.dimension'),
    key: 'dimension',
    dataKey: 'dimension',
  },
  {
    header: i18n.t('containerInsight.usageMeteringLabels.bytes'),
    key: 'utilization',
    dataKey: 'utilization',
    alignment: 'right',
  },
];

export enum FileUtilizationDimensionEnum {
  CURRENT_DISK_AVAILABLE = 'currentDiskAvailable',
  DISK_CAPACITY = 'diskCapacity',
  CURRENT_DISK_UTILIZATION = 'currentDiskUtilization',
  DISK_UTILIZATION_PERCENTAGE = 'diskUtilizationInPercentage',
}

export const FILE_UTILIZATION_DIMENSIONS = [
  {
    dataLabel: FileUtilizationDimensionEnum.DISK_CAPACITY,
    displayLabel: i18n.t('containerInsight.usageMeteringLabels.diskCapacity'),
    field: QUERY_FIELDS.NODE_FILESYSTEM_CAPACITY,
  },
  {
    dataLabel: FileUtilizationDimensionEnum.CURRENT_DISK_AVAILABLE,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.currentDiskAvailable'
    ),
    field: QUERY_FIELDS.NODE_FILESYSTEM_AVAILABLE,
  },
  {
    dataLabel: FileUtilizationDimensionEnum.CURRENT_DISK_UTILIZATION,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.currentDiskUtilization'
    ),
    field: QUERY_FIELDS.NODE_FILESYSTEM_USAGE,
  },
  {
    dataLabel: FileUtilizationDimensionEnum.DISK_UTILIZATION_PERCENTAGE,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.diskUtilizationInPercentage'
    ),
    field: QUERY_FIELDS.NODE_FILESYSTEM_UTILIZATION,
  },
];

export const AwsClustersQuery = {
  columns: [
    {
      label: 'cluster',
      field: QUERY_FIELDS.CLUSTER_NAME_WITHOUT_SPACE,
    },
  ],
  aggregators: [
    {
      label: 'cluster',
      function: AGGREGATORS.DISTINCT,
    },
  ],
  dashBoardType: DASHBOARD_TYPES.CONTAINER_INSIGHT,
  cached: true,
};

export enum ContainerCpuUtilizationDimensionEnum {
  CPU_USAGE_BY_SYSTEM = 'cpuUsageBySystem',
  CPU_USAGE_TOTAL = 'cpuUsageTotal',
  CPU_USAGE_USER = 'cpuUsageUser',
  CPU_UTILIZATION_OF_CONTAINER = 'cpuUtilization',
}

export const CONTAINER_CPU_UTILIZATION_DIMENSIONS = [
  {
    dataLabel: ContainerCpuUtilizationDimensionEnum.CPU_USAGE_BY_SYSTEM,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.cpuUsageBySystem'
    ),
    field: QUERY_FIELDS.CONTAINER_CPU_USAGE_SYSTEM,
  },
  {
    dataLabel: ContainerCpuUtilizationDimensionEnum.CPU_USAGE_TOTAL,
    displayLabel: i18n.t('containerInsight.usageMeteringLabels.cpuUsageTotal'),
    field: QUERY_FIELDS.CONTAINER_CPU_USAGE_TOTAL,
  },
  {
    dataLabel: ContainerCpuUtilizationDimensionEnum.CPU_USAGE_USER,
    displayLabel: i18n.t('containerInsight.usageMeteringLabels.cpuUsageUser'),
    field: QUERY_FIELDS.CONTAINER_CPU_USAGE_USER,
  },
  {
    dataLabel:
      ContainerCpuUtilizationDimensionEnum.CPU_UTILIZATION_OF_CONTAINER,
    displayLabel: i18n.t('containerInsight.usageMeteringLabels.cpuUtilization'),
    field: QUERY_FIELDS.CONTAINER_CPU_UTILIZATION,
  },
];

export enum ContainerMemoryUtilizationDimensionEnum {
  MEMORY_USAGE_BY_CONTAINER = 'memoryUsageByContainer',
  MAX_MEMORY_USAGE = 'maxMemoryUsage',
  MEMORY_UTILIZATION_BY_CONTAINER = 'memoryUtilizationByContainer',
}

export const CONTAINER_MEMORY_UTILIZATION_DIMENSIONS = [
  {
    dataLabel:
      ContainerMemoryUtilizationDimensionEnum.MEMORY_USAGE_BY_CONTAINER,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.memoryUsageByContainer'
    ),
    field: QUERY_FIELDS.CONTAINER_MEMORY_USAGE,
  },
  {
    dataLabel: ContainerMemoryUtilizationDimensionEnum.MAX_MEMORY_USAGE,
    displayLabel: i18n.t('containerInsight.usageMeteringLabels.maxMemoryUsage'),
    field: QUERY_FIELDS.CONTAINER_MEMORY_MAX_USAGE,
  },
  {
    dataLabel:
      ContainerMemoryUtilizationDimensionEnum.MEMORY_UTILIZATION_BY_CONTAINER,
    displayLabel: i18n.t(
      'containerInsight.usageMeteringLabels.memoryUtilizationByContainer'
    ),
    field: QUERY_FIELDS.CONTAINER_MEMORY_UTILIZATION,
  },
];

export const DUMMY_NAMESPACES = [
  'kube:system-overhead',
  'kube:unallocated',
  'kube-system',
  'gprofiler',
  'cb360-dev',
  'cb360-qa',
];

export const DUMMY_COST_TREND_VALUES = [
  {
    cost: 780.6672090000093,
    month: '202310',
  },
  {
    cost: 750.2206070000339,
    month: '202309',
  },
  {
    cost: 320.350517999999,
    month: '202304',
  },
  {
    cost: 101.8722159999994,
    month: '202211',
  },
  {
    cost: 152.96674099999834,
    month: '202303',
  },
  {
    cost: 500.0740129999963,
    month: '202305',
  },
  {
    cost: 130.20652999999916,
    month: '202212',
  },
  {
    cost: 433.48512100000573,
    month: '202311',
  },
  {
    cost: 638.9077900000226,
    month: '202306',
  },
  {
    cost: 180.57903399999887,
    month: '202302',
  },
  {
    cost: 709.9918430000383,
    month: '202308',
  },
  {
    cost: 715.9260500000246,
    month: '202307',
  },
  {
    cost: 199.21104899999875,
    month: '202301',
  },
];

export const DUMMY_NAMESPACES_COST = [
  {
    namespace: DUMMY_NAMESPACES[0],
    costAfterCredit: 301.28,
  },
  {
    namespace: DUMMY_NAMESPACES[1],
    costAfterCredit: 759.59,
  },
  {
    namespace: DUMMY_NAMESPACES[2],
    costAfterCredit: 1289.02,
  },
  {
    namespace: DUMMY_NAMESPACES[3],
    costAfterCredit: 678.01,
  },
  {
    namespace: DUMMY_NAMESPACES[4],
    costAfterCredit: 978.01,
  },
  {
    namespace: DUMMY_NAMESPACES[5],
    costAfterCredit: 1376.42,
  },
];

export const DUMMY_CLUSTER_COST_VALUES = [
  {
    cluster: 'granulate',
    cost: 5382.33,
    color: '',
  },
];
