import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConnectionListType } from 'types/dashboard';

import { RootState } from './store';

export interface TagComplianceState {
  selectedConnection: ConnectionListType | null;
  tagComplianceConnectionList: ConnectionListType[];
}

const initialState: TagComplianceState = {
  selectedConnection: null,
  tagComplianceConnectionList: [],
};

export const tagComplianceSlice = createSlice({
  name: 'tagComplianceSlice',
  initialState,
  reducers: {
    setSelectedConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.selectedConnection = action.payload;
    },
    setTagComplianceConnectionList: (
      state,
      action: PayloadAction<ConnectionListType[]>
    ) => {
      state.tagComplianceConnectionList = action.payload;
    },
  },
});

export const selectTagCompliance = (state: RootState) => state.tagCompliance;
export const { setSelectedConnection, setTagComplianceConnectionList } =
  tagComplianceSlice.actions;
export default tagComplianceSlice.reducer;
