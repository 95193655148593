import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectTagCompliance,
  setSelectedConnection,
  setTagComplianceConnectionList,
} from 'redux/tagComplianceSlice';
import { selectTheme } from 'redux/themeSlice';
import Button from 'components/Button';
import Icon from 'components/Icon';
import ConnectionsListDrawer from 'components/ConnectionsListDrawer';
import { CONNECTION_STATUS } from 'pages/ConnectingCSPPage/constants';
import {
  LabelValueType,
  UntaggedResourceType,
} from 'pages/TagCompliancePage/types';
import { UntaggedResourcesExcelColumns } from 'pages/TagCompliancePage/constants';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { fetchConnectionData } from 'utils/services';
import { ConnectionListType } from 'types/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { getProviderLogo } from 'utils/providerDetails';
import { insertIndex } from 'utils/dataFormatterUtils';
import { exportToExcel } from 'utils/exportToExcel';
import { PROVIDER } from 'constants/cloudProviders';

import './index.scss';

type HeaderProps = {
  setIsEmptyConnection: (val: boolean) => void;
  setConnectionReqStatus: (val: string) => void;
  resources: UntaggedResourceType[];
  allTags: LabelValueType[];
};

const Header = ({
  setIsEmptyConnection,
  setConnectionReqStatus,
  resources,
  allTags,
}: HeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedConnection, tagComplianceConnectionList } =
    useSelector(selectTagCompliance);
  const { theme } = useSelector(selectTheme);

  const [showSwitchConnectionDrawer, setShowSwitchConnectionDrawer] =
    useState(false);
  const [fetchConnectionsRequestStatus, setFetchConnectionsRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    fetchConnections();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    if (tagComplianceConnectionList.length === 0) {
      return;
    }

    if (
      !selectedConnection ||
      !tagComplianceConnectionList.some(
        (availableConnection) =>
          availableConnection.connectorId === selectedConnection.connectorId
      )
    ) {
      dispatch(setSelectedConnection(tagComplianceConnectionList[0]));
    }
  }, [tagComplianceConnectionList]);

  /**
   * @function fetchConnections
   * @description Function to fetch the connection list
   */
  const fetchConnections = () => {
    setFetchConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);

    fetchConnectionData()
      .then((res: any) => {
        let connections: ConnectionListType[] =
          res.data.responseData.content.filter(
            (connection: ConnectionListType) =>
              connection.provider !== PROVIDER.OCI &&
              connection.status === CONNECTION_STATUS.ACTIVE &&
              connection.wantBilling
          );

        dispatch(setTagComplianceConnectionList(connections));
        setIsEmptyConnection(connections.length === 0);
        setFetchConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
        setConnectionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchConnectionsRequestStatus);
        dispatch(setTagComplianceConnectionList([]));
        setConnectionReqStatus(REQUEST_STATUS.ERROR);
      });
  };

  const onClickDownloadExcel = () => {
    setIsDownloading(true);
    const excelData = [
      {
        sheetName: t('tagCompliance.untaggedResources'),
        columns: UntaggedResourcesExcelColumns,
        data: insertIndex(
          resources.map((item) => ({
            ...item,
            tagsMissing: item.untaggedTags
              .map(
                (tag) =>
                  allTags.find((eachTag) => eachTag.value === tag)?.label ?? tag
              )
              .join(','),
          }))
        ),
        filters: [
          {
            heading: t('excelExportLabels.connectionName'),
            value: selectedConnection!.displayName,
          },
        ],
      },
    ];

    exportToExcel(t('tagCompliance.untaggedResources'), excelData).finally(
      () => {
        setIsDownloading(false);
      }
    );
  };

  return (
    <header
      className="new-page-header tag-compliance-header flex flex-align-items-center flex-space-between"
      data-testid="tag-compliance-header"
    >
      <div className="tag-compliance-title flex flex-align-items-center flex-gap-16">
        {selectedConnection && (
          <>
            <img
              width={35}
              height={35}
              src={getProviderLogo(selectedConnection.provider)}
              alt={`${selectedConnection.provider} Logo`}
            />
            <div className="flex flex-column">
              <div className="modal-heading">
                {selectedConnection.displayName}
              </div>
              <div className="data-source-type flex font-caption-bold">
                <div>{selectedConnection.dataSourceType}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-align-items-center flex-gap-24">
        {isDownloading ? (
          <Icon className="rotate" iconName={ICONS.DOWNLOAD_LINE} />
        ) : (
          <Icon
            iconName={ICONS.DOWNLOAD_2_LINE}
            color={theme.buttonIconColor}
            onClick={onClickDownloadExcel}
            dataTestId="download-cta"
          />
        )}
        <Button
          title={t('tagCompliance.switchConnection')}
          iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
          iconSize={ICONS_SIZE.ONE_X}
          onClick={() => setShowSwitchConnectionDrawer(true)}
        />
      </div>
      <ConnectionsListDrawer
        isCB360TabSelected={true}
        show={showSwitchConnectionDrawer}
        setShow={setShowSwitchConnectionDrawer}
        selectedConnection={selectedConnection}
        connectionList={tagComplianceConnectionList}
        onClick={(key: any) => {
          dispatch(
            setSelectedConnection(
              tagComplianceConnectionList.find(
                (connection) => connection.connectorId === key
              ) ?? null
            )
          );
          setShowSwitchConnectionDrawer(false);
        }}
        loading={fetchConnectionsRequestStatus === REQUEST_STATUS.PROCESSING}
      />
    </header>
  );
};

export default Header;
