import { useState } from 'react';

import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS_SIZE } from 'constants/icons';

type ColorDiscProps = {
  color: string;
  className?: string;
  iconName?: string;
  showIcon?: boolean;
  showIconOnHover?: boolean;
  iconSize?: ICONS_SIZE;
  onClick?: () => void;
};

const ColorDisc = ({
  color,
  className = 'color-disc',
  iconName,
  showIcon = false,
  showIconOnHover = false,
  iconSize = ICONS_SIZE.SM,
  onClick,
}: ColorDiscProps) => {
  const [showOnHover, setShowOnHover] = useState(false);

  return (
    <AccessibleDiv
      className={className}
      style={{ backgroundColor: color }}
      onClick={onClick}
      onMouseEnter={() => showIconOnHover && setShowOnHover(true)}
      onMouseLeave={() => showIconOnHover && setShowOnHover(false)}
    >
      {iconName && (showIcon || showOnHover) && (
        <Icon
          iconName={iconName}
          size={iconSize}
          color="white"
          className="flex flex-center"
        />
      )}
    </AccessibleDiv>
  );
};

export default ColorDisc;
