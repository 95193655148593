import { Table as AntTable } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import DashboardComponent from 'components/DashboardComponent';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { selectDashboard } from 'redux/dashboardSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import GraphHeader from 'components/GraphHeader';
import { CostByServiceFamilyType } from 'types/dataTypes';
import { calculateTotalCostSum } from 'utils/CostSum';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import ConnectionsFilter from '../ConnectionsFilter';
import { getCloudServicesExcelData } from '../../utils/exportToExcel';
import { getFilteredConnections } from '../../utils/commonUtils';

type ConsolidatedCloudServicesProps = {
  data: CostByServiceFamilyType[];
  requestStatus: string;
  selectedConnections: string[];
  setSelectedConnections: (val: string[]) => void;
  pdfView: boolean;
  consolidatedServicesStartMonth: string;
  consolidatedServicesEndMonth: string;
  onChangeConsolidatedServicesDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};
const ConsolidatedCloudServices = ({
  data,
  requestStatus,
  selectedConnections,
  setSelectedConnections,
  pdfView,
  consolidatedServicesStartMonth,
  consolidatedServicesEndMonth,
  onChangeConsolidatedServicesDateRange,
}: ConsolidatedCloudServicesProps) => {
  const { t } = useTranslation();
  const { selectedGroupMetaData } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: t('consolidatedCloudServices.services'),
      dataIndex: 'service',
      key: 'service',
      width: '20%',
    },
    {
      title: t('consolidatedCloudServices.provider'),
      dataIndex: 'provider',
      key: 'provider',
      width: '10%',
    },
    {
      title: t('consolidatedCloudServices.familyType'),
      dataIndex: 'family',
      key: 'family',
      width: '15%',
    },
    {
      title: t('consolidatedCloudServices.spend', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'cost',
      key: 'cost',
      width: '15%',
      align: 'right' as const,
      render: (text: number) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  const filters = (
    <div className="filters flex flex-align-items-center full-width">
      <DatePicker
        defaultValue={[
          moment(consolidatedServicesStartMonth),
          moment(consolidatedServicesEndMonth),
        ]}
        onChange={onChangeConsolidatedServicesDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
      <ConnectionsFilter
        selectedConnections={selectedConnections}
        onSelectedDropdown={setSelectedConnections}
      />
    </div>
  );

  const getSummaryRowComponent = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell
          index={0}
          colSpan={columns.length - 2}
          className="font-caption-bold"
        >
          {t('grandTotal')}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell
          index={columns.length - 1}
          colSpan={2}
          align="right"
          className="font-caption-bold"
        >
          {currencySymbol +
            calculateTotalCostSum(data.map((eachData) => +eachData.cost))}
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  const getComponent = () => {
    return (
      <Table
        pagination={false}
        scroll={{ y: 140 }}
        dataSource={data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        })}
        columns={columns}
        loading={requestStatus === REQUEST_STATUS.PROCESSING}
        designVersion2
        summary={getSummaryRowComponent}
      />
    );
  };

  return (
    <div
      className="consolidated-cloud-services graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.cloudServices')}
        filters={filters}
        graphName="consolidated-cloud-services"
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        excelData={getCloudServicesExcelData(
          selectedGroupMetaData?.name ?? '',
          data,
          getFilteredConnections(
            selectedGroupMetaData?.connectorDtos ?? [],
            selectedConnections
          ).map((connection) => connection.name)
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};

export default ConsolidatedCloudServices;
