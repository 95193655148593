import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getApplicableAWSRecommendations
 * @description Function to get all the applicable recommendations list
 * @return axios response from GET request
 */
export const getApplicableAWSRecommendations = async () => {
  return ServiceCalls.get(APIS.AWS_APPLICABLE_RECOMMENDATIONS);
};

/**
 * @function applyAWSRecommendations
 * @description Function to apply recommendations for AWS
 * @param body Request body
 * @return axios response from POST request
 */
export const applyAWSRecommendations = async (body: any) => {
  return ServiceCalls.post(APIS.APPLY_AWS_RECOMMENDATIONS, body);
};
