import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';
import { getRulesetTemplateEndpointByProvider } from './utils';

/**
 * @function getRulesetTemplates
 * @description Function to get all the ruleset templates
 * @return axios response from GET request
 */
export const getRulesetTemplates = async (provider: string) => {
  return ServiceCalls.get(getRulesetTemplateEndpointByProvider(provider));
};

/**
 * @function getGranulateRulesetTemplates
 * @description Function to get all the Granulate ruleset templates
 * @return axios response from GET request
 */
export const getGranulateRulesetTemplates = async () => {
  return ServiceCalls.get(APIS.GET_GRANULATE_RULESET_TEMPLATES);
};

/**
 * @function createRuleset
 * @description Function to create a Ruleset
 * @return axios response from POST request
 */
export const createRuleset = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_RULESET, body);
};

/**
 * @function updateRuleset
 * @description Function to update a Ruleset
 * @return axios response from POST request
 */
export const updateRuleset = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_RULEST, body);
};
