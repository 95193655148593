import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getCostByDataCenterCode
 * @description Function to cost of all categories by data center code
 * @param params query parameters for get request.
 * @return axios response from GET request
 */
export const getCostByDataCenterCode = async (params: any) => {
  return ServiceCalls.get(APIS.GET_COST_BY_DATACENTER_CODE, null, params);
};

/**
 * @function getCostByTcoConnection
 * @description Function to get cost by connection and category for tco
 * @param body query parameters for get request.
 * @return axios response from POST request
 */
export const getCostByTcoConnection = async (body: any) => {
  return ServiceCalls.post(APIS.GET_TCO_COST_oF_CONNECTION, body);
};
