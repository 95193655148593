import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function applyAzureRecommendations
 * @description Function to apply recommendations for Azure
 * @param body Request body
 * @return axios response from POST request
 */
export const applyAzureRecommendations = async (body: any) => {
  return ServiceCalls.post(APIS.APPLY_AZURE_RECOMMENDATIONS, body);
};

/**
 * @function getAzureCategoryRecommenderMapping
 * @description Function to get the mapping between category and recommenders for Azure
 * @return axios response from GET request
 */
export const getAzureCategoryRecommenderMapping = async () => {
  return ServiceCalls.get(APIS.GET_AZURE_CATEGORY_MAPPINGS);
};
