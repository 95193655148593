import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';

import { userAuthorization } from 'redux/authorizationSlice';
import DashboardGraphContainer from 'components/DashboardGraphContainer';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  ComparisonListType,
  ConnectionListType,
  GraphRowContentType,
} from 'types/dashboard';
import ComparisonCard from 'components/NewComparisonCard';
import NavigationPath from 'components/NavigationPath';
import DashboardComponent from 'components/DashboardComponent';
import ChartWithTableWrapper from 'components/ChartWithTableWrapper';
import { CHART_TYPES } from 'constants/graphConfig';
import ColumnChartWithTable from 'components/ColumnChartWithTable';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { TypeAndNameByCost } from 'types/dataTypes';
import { ChartFilterType } from 'constants/dashboard';
import { getMonthRangeHandler } from 'utils/graphUtils';
import { RECOMMENDATION_CATEGORIES } from 'constants/recommendations';
import { getProviderForConnection } from 'utils/dashboardUtils';

import AutoPilotSavingsDashboardHeader from './components/AutoPilotSavingsDashboardHeader';
import {
  fetchAndSetAppliedRecommendations,
  fetchDailySavingsCost,
  fetchMonthlySavingsCost,
  fetchRecommendationDetails,
  fetchYtdCostMetrics,
  getAutoPilotSavingsByCategoryColumns,
  getDatesByGraphType,
  getPdfMetaData,
  getRecommendationDetailsTableColumns,
  getRecommendations,
  getRecommendersOptions,
  getSavingsChartsCost,
  getSavingsChartsTableData,
} from './utils';
import SavingsChartHeading from './components/SavingsChartHeading';
import {
  AutoPilotAppliedRecommendations,
  RecommendationDetailsType,
} from './types';

import './index.scss';

const AutoPilotSavings = () => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { permissions } = useSelector(userAuthorization);

  const [isEmptyDashboard, setIsEmptyDashboard] = useState(false);
  const [connectionsRequestStatus, setConnectionsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [selectedConnection, setSelectedConnection] =
    useState<ConnectionListType | null>(null);

  const [metricCardsList, setMetricCardsList] = useState<ComparisonListType[]>(
    []
  );

  //States for metric cards
  const [ytdSavings, setYtdSavings] = useState(0);
  const [ytdSavingsReqStatus, setYtdSavingsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [ytdSavingsMissed, setYtdSavingsMissed] = useState(0);

  // States for savings chart
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [savingsChartStartDate, setSavingsChartStartDate] = useState<string>(
    moment().startOf('year').format(MONTH_YEAR_FORMAT)
  );
  const [savingsChartEndDate, setSavingsChartEndDate] = useState<string>(
    moment().format(MONTH_YEAR_FORMAT)
  );
  const [savingsChartMonthData, setSavingsChartMonthData] = useState<
    TypeAndNameByCost[]
  >([]);
  const [savingsChartDailyData, setSavingsChartDailyData] = useState<{
    [key: string]: TypeAndNameByCost[];
  }>({});
  const [savingsChartReqStatus, setSavingsChartReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isSavingsChartTableView, setIsSavingsChartTableView] = useState(false);

  // States for Waste Management Recommendations Table
  const [wmRecommender, setWmRecommender] = useState<string>();
  const [wmAppliedRecommendations, setWmAppliedRecommendations] =
    useState<AutoPilotAppliedRecommendations>({});
  const [
    wmAppliedRecommendationsReqStatus,
    setWmAppliedRecommendationsReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [wmStartDate, setWmStartDate] = useState<string>(
    moment().startOf('year').format(MONTH_YEAR_FORMAT)
  );
  const [wmEndDate, setWmEndDate] = useState<string>(
    moment().format(MONTH_YEAR_FORMAT)
  );
  const [wmRecommendations, setWmRecommendations] = useState<
    RecommendationDetailsType[]
  >([]);
  const [wmRecommendationsReqStatus, setWmRecommendationsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  // States for Consumption Management Recommendations Table
  const [cmRecommender, setCmRecommender] = useState<string>();
  const [cmAppliedRecommendations, setCmAppliedRecommendations] =
    useState<AutoPilotAppliedRecommendations>({});
  const [
    cmAppliedRecommendationsReqStatus,
    setCmAppliedRecommendationsReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [cmStartDate, setCmStartDate] = useState<string>(
    moment().startOf('year').format(MONTH_YEAR_FORMAT)
  );
  const [cmEndDate, setCmEndDate] = useState<string>(
    moment().format(MONTH_YEAR_FORMAT)
  );
  const [cmRecommendations, setCmRecommendations] = useState<
    RecommendationDetailsType[]
  >([]);
  const [cmRecommendationsReqStatus, setCmRecommendationsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  // States for Purchase Tactics Recommendations Table
  const [ptRecommender, setPtRecommender] = useState<string>();
  const [ptAppliedRecommendations, setPtAppliedRecommendations] =
    useState<AutoPilotAppliedRecommendations>({});
  const [
    ptAppliedRecommendationsReqStatus,
    setPtAppliedRecommendationsReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [ptStartDate, setPtStartDate] = useState<string>(
    moment().startOf('year').format(MONTH_YEAR_FORMAT)
  );
  const [ptEndDate, setPtEndDate] = useState<string>(
    moment().format(MONTH_YEAR_FORMAT)
  );
  const [ptRecommendations, setPtRecommendations] = useState<
    RecommendationDetailsType[]
  >([]);
  const [ptRecommendationsReqStatus, setPtRecommendationsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    setWmRecommender(undefined);
    setCmRecommender(undefined);
    setPtRecommender(undefined);
  }, [selectedConnection]);

  useEffect(() => {
    setYtdSavings(0);
    setYtdSavingsMissed(0);
    setSavingsChartDailyData({});
    if (selectedConnection) {
      fetchYtdCostMetrics(selectedConnection, {
        setYtdSavings,
        setYtdSavingsMissed,
        setReqStatus: setYtdSavingsReqStatus,
      });
    }
  }, [selectedConnection]);

  useEffect(() => {
    const metricCardsDataList: ComparisonListType[] = [
      {
        value: ytdSavings,
        heading: t('ytdAutoPilotSavings'),
        comparisonValue: ytdSavings,
        requestStatus: [ytdSavingsReqStatus],
        valuePrefix: currencySymbol,
      },
      {
        value: ytdSavingsMissed,
        heading: t('ytdSavingsMissed'),
        comparisonValue: ytdSavingsMissed,
        requestStatus: [ytdSavingsReqStatus],
        valuePrefix: currencySymbol,
      },
    ];

    setMetricCardsList(metricCardsDataList);
  }, [ytdSavings, ytdSavingsReqStatus, ytdSavingsMissed]);

  useEffect(() => {
    setSelectedMonth(null);
    setSavingsChartMonthData([]);
    if (selectedConnection) {
      fetchMonthlySavingsCost(
        selectedConnection,
        [savingsChartStartDate, savingsChartEndDate],
        {
          setMonthlyCost: setSavingsChartMonthData,
          setReqStatus: setSavingsChartReqStatus,
        }
      );
    }
  }, [selectedConnection, savingsChartStartDate, savingsChartEndDate]);

  useEffect(() => {
    if (selectedMonth) {
      fetchDailySavingsCost(
        selectedConnection!,
        selectedMonth,
        savingsChartDailyData,
        {
          setDailyCost: setSavingsChartDailyData,
          setReqStatus: setSavingsChartReqStatus,
        }
      );
    }
  }, [selectedMonth]);

  useEffect(() => {
    setWmAppliedRecommendations({});
    if (selectedConnection) {
      fetchAndSetAppliedRecommendations(
        selectedConnection,
        [RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT],
        [wmStartDate, wmEndDate],
        wmRecommender,
        {
          setAppliedRecommendations: setWmAppliedRecommendations,
          setReqStatus: setWmAppliedRecommendationsReqStatus,
          setSelectedRecommender: setWmRecommender,
        }
      );
    }
  }, [selectedConnection, wmStartDate, wmEndDate]);

  useEffect(() => {
    setWmRecommendations([]);

    if (
      wmRecommender &&
      getRecommendations(
        wmAppliedRecommendations,
        [RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT],
        [wmRecommender]
      ).length > 0
    ) {
      fetchRecommendationDetails(
        selectedConnection!,
        [wmStartDate, wmEndDate],
        wmRecommender,
        getRecommendations(
          wmAppliedRecommendations,
          [RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT],
          [wmRecommender]
        ),
        {
          setRecommendations: setWmRecommendations,
          setReqStatus: setWmRecommendationsReqStatus,
        }
      );
    }
  }, [wmRecommender, wmAppliedRecommendations]);

  useEffect(() => {
    setCmAppliedRecommendations({});
    if (selectedConnection) {
      fetchAndSetAppliedRecommendations(
        selectedConnection,
        [RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT],
        [cmStartDate, cmEndDate],
        cmRecommender,
        {
          setAppliedRecommendations: setCmAppliedRecommendations,
          setReqStatus: setCmAppliedRecommendationsReqStatus,
          setSelectedRecommender: setCmRecommender,
        }
      );
    }
  }, [selectedConnection, cmStartDate, cmEndDate]);

  useEffect(() => {
    setCmRecommendations([]);

    if (
      cmRecommender &&
      getRecommendations(
        cmAppliedRecommendations,
        [RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT],
        [cmRecommender]
      ).length > 0
    ) {
      fetchRecommendationDetails(
        selectedConnection!,
        [cmStartDate, cmEndDate],
        cmRecommender,
        getRecommendations(
          cmAppliedRecommendations,
          [RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT],
          [cmRecommender]
        ),
        {
          setRecommendations: setCmRecommendations,
          setReqStatus: setCmRecommendationsReqStatus,
        }
      );
    }
  }, [cmRecommender, cmAppliedRecommendations]);

  useEffect(() => {
    setPtAppliedRecommendations({});
    if (selectedConnection) {
      fetchAndSetAppliedRecommendations(
        selectedConnection,
        [RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS],
        [ptStartDate, ptEndDate],
        ptRecommender,
        {
          setAppliedRecommendations: setPtAppliedRecommendations,
          setReqStatus: setPtAppliedRecommendationsReqStatus,
          setSelectedRecommender: setPtRecommender,
        }
      );
    }
  }, [selectedConnection, ptStartDate, ptEndDate]);

  useEffect(() => {
    setPtRecommendations([]);

    if (
      ptRecommender &&
      getRecommendations(
        ptAppliedRecommendations,
        [RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS],
        [ptRecommender]
      ).length > 0
    ) {
      fetchRecommendationDetails(
        selectedConnection!,
        [ptStartDate, ptEndDate],
        ptRecommender,
        getRecommendations(
          ptAppliedRecommendations,
          [RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS],
          [ptRecommender]
        ),
        {
          setRecommendations: setPtRecommendations,
          setReqStatus: setPtRecommendationsReqStatus,
        }
      );
    }
  }, [ptRecommender, ptAppliedRecommendations]);

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'autopilot-savings-by-category':
        return (
          <ColumnChartWithTable
            graphTitle={t('graphHeadings.autoPilotSavingsByCategory')}
            graphHeadingComponent={
              <SavingsChartHeading
                month={selectedMonth}
                setMonth={setSelectedMonth}
                isTableViw={isSavingsChartTableView}
                isPdfView={pdfView}
              />
            }
            graphName="autopilot-savings-by-category"
            columns={getAutoPilotSavingsByCategoryColumns(
              getDatesByGraphType(selectedMonth, [
                savingsChartStartDate,
                savingsChartEndDate,
              ])
            )}
            data={getSavingsChartsCost(
              selectedMonth,
              savingsChartMonthData,
              savingsChartDailyData
            )}
            tableData={getSavingsChartsTableData(
              selectedMonth,
              savingsChartMonthData,
              savingsChartDailyData
            )}
            chartProperties={{
              xTitle: t('date'),
              yTitle: t('costInCurrency', { currencySymbol }),
              isStack: true,
            }}
            isFullWidth={false}
            filters={[
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: savingsChartStartDate,
                  filterEndDate: savingsChartEndDate,
                  onChangeDateRange: getMonthRangeHandler(
                    setSavingsChartStartDate,
                    setSavingsChartEndDate
                  ),
                  format: MONTH_YEAR_FORMAT,
                  picker: 'month',
                  disabledDate: (current: Moment) =>
                    current.isBefore(moment().startOf('year')) ||
                    current.isAfter(moment()),
                },
              },
            ]}
            requestStatus={savingsChartReqStatus}
            isTableView={isSavingsChartTableView}
            setIsTableView={setIsSavingsChartTableView}
            pdfView={pdfView}
            connection={selectedConnection}
            additionalTooltipContent={
              !selectedMonth && (
                <span className="tooltip-deep-dive-label font-subHeader-small">
                  {t('clickDeepDive')}
                </span>
              )
            }
            onClickColumn={(e: any) => {
              setSelectedMonth((month) => {
                return month ? month : e.data.data.name;
              });
            }}
            isColumnClickable={!selectedMonth}
          />
        );

      case 'waste-management-recommendations':
        return (
          <ChartWithTableWrapper
            graphTitle={t('graphHeadings.wasteManagement')}
            graphName="waste-management-recommendations"
            columns={getRecommendationDetailsTableColumns(
              getProviderForConnection(selectedConnection),
              wmRecommender
            )}
            tableData={wmRecommendations}
            filters={[
              {
                filterType: ChartFilterType.SINGLE_SELECT_DROPDOWN,
                filterProperties: {
                  allData: getRecommendersOptions(
                    wmAppliedRecommendations,
                    RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
                    getProviderForConnection(selectedConnection)
                  ),
                  selectedValue: wmRecommender,
                  setSelectedValue: setWmRecommender,
                  dropdownFilterRequestStatus:
                    wmAppliedRecommendationsReqStatus,
                },
              },
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: wmStartDate,
                  filterEndDate: wmEndDate,
                  onChangeDateRange: getMonthRangeHandler(
                    setWmStartDate,
                    setWmEndDate
                  ),
                  format: MONTH_YEAR_FORMAT,
                  picker: 'month',
                  disabledDate: (current: Moment) =>
                    current.isBefore(moment().startOf('year')) ||
                    current.isAfter(moment()),
                },
              },
            ]}
            requestStatus={[
              wmAppliedRecommendationsReqStatus,
              wmRecommendationsReqStatus,
            ]}
            isTableView={true}
            pdfView={pdfView}
            isFullWidth={false}
            hasTableView={false}
            connection={selectedConnection}
          />
        );

      case 'consumption-management-recommendations':
        return (
          <ChartWithTableWrapper
            graphTitle={t('graphHeadings.consumptionManagement')}
            graphName="consumption-management-recommendations"
            columns={getRecommendationDetailsTableColumns(
              getProviderForConnection(selectedConnection),
              cmRecommender
            )}
            tableData={cmRecommendations}
            filters={[
              {
                filterType: ChartFilterType.SINGLE_SELECT_DROPDOWN,
                filterProperties: {
                  allData: getRecommendersOptions(
                    cmAppliedRecommendations,
                    RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
                    getProviderForConnection(selectedConnection)
                  ),
                  selectedValue: cmRecommender,
                  setSelectedValue: setCmRecommender,
                  dropdownFilterRequestStatus:
                    cmAppliedRecommendationsReqStatus,
                },
              },
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: cmStartDate,
                  filterEndDate: cmEndDate,
                  onChangeDateRange: getMonthRangeHandler(
                    setCmStartDate,
                    setCmEndDate
                  ),
                  format: MONTH_YEAR_FORMAT,
                  picker: 'month',
                  disabledDate: (current: Moment) =>
                    current.isBefore(moment().startOf('year')) ||
                    current.isAfter(moment()),
                },
              },
            ]}
            requestStatus={[
              cmAppliedRecommendationsReqStatus,
              cmRecommendationsReqStatus,
            ]}
            isTableView={true}
            pdfView={pdfView}
            isFullWidth={false}
            hasTableView={false}
            connection={selectedConnection}
          />
        );

      case 'purchase-tactics-recommendations':
        return (
          <ChartWithTableWrapper
            graphTitle={t('graphHeadings.purchaseTactics')}
            graphName="purchase-tactics-recommendations"
            columns={getRecommendationDetailsTableColumns(
              getProviderForConnection(selectedConnection),
              ptRecommender
            )}
            tableData={ptRecommendations}
            filters={[
              {
                filterType: ChartFilterType.SINGLE_SELECT_DROPDOWN,
                filterProperties: {
                  allData: getRecommendersOptions(
                    ptAppliedRecommendations,
                    RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
                    getProviderForConnection(selectedConnection)
                  ),
                  selectedValue: ptRecommender,
                  setSelectedValue: setPtRecommender,
                  dropdownFilterRequestStatus:
                    ptAppliedRecommendationsReqStatus,
                },
              },
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: ptStartDate,
                  filterEndDate: ptEndDate,
                  onChangeDateRange: getMonthRangeHandler(
                    setPtStartDate,
                    setPtEndDate
                  ),
                  format: MONTH_YEAR_FORMAT,
                  picker: 'month',
                  disabledDate: (current: Moment) =>
                    current.isBefore(moment().startOf('year')) ||
                    current.isAfter(moment()),
                },
              },
            ]}
            requestStatus={[
              ptAppliedRecommendationsReqStatus,
              ptRecommendationsReqStatus,
            ]}
            isTableView={true}
            pdfView={pdfView}
            isFullWidth={false}
            hasTableView={false}
            connection={selectedConnection}
          />
        );
    }
  };

  const getCostComparisonCards = () =>
    metricCardsList.map((item, index) => {
      return (
        <ComparisonCard
          key={item.heading}
          index={index}
          value={item.value}
          heading={item.heading}
          valuePrefix={item.valuePrefix}
          valueSuffix={item.valueSuffix}
          requestStatus={item.requestStatus}
        />
      );
    });

  // Memoized data for the graph rows to improve performance
  const GraphRows: GraphRowContentType = useMemo(
    () => [
      [
        {
          element: getGraphComponent('autopilot-savings-by-category', true),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: 'autopilot-savings-by-category',
          graphHeading: t('graphHeadings.autoPilotSavingsByCategory'),
          chartView: {
            xAxisLabel: t('date'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getAutoPilotSavingsByCategoryColumns(
              getDatesByGraphType(selectedMonth, [
                savingsChartStartDate,
                savingsChartEndDate,
              ])
            ),
            isTableView: isSavingsChartTableView,
            setTableView: setIsSavingsChartTableView,
          },
          pdfTableExport: {
            pdfTableData: getSavingsChartsTableData(
              selectedMonth,
              savingsChartMonthData,
              savingsChartDailyData
            ),
          },
          excelExport: {
            excelData: getSavingsChartsTableData(
              selectedMonth,
              savingsChartMonthData,
              savingsChartDailyData
            ),
            excelFilters: {
              connectionName: selectedConnection?.displayName ?? '',
              startDate: savingsChartStartDate,
              endDate: savingsChartEndDate,
            },
          },
        },
      ],
      [
        {
          element: getGraphComponent('waste-management-recommendations', true),
          contentType: CHART_TYPES.TABLE,
          graphName: 'waste-management-recommendations',
          graphHeading: t('graphHeadings.wasteManagement'),
          tableView: {
            columns: getRecommendationDetailsTableColumns(
              getProviderForConnection(selectedConnection),
              wmRecommender
            ),
            isTableView: true,
          },
          pdfTableExport: {
            pdfTableData: wmRecommendations,
          },
          excelExport: {
            excelData: wmRecommendations,
            excelFilters: {
              connectionName: selectedConnection?.displayName ?? '',
              startDate: wmStartDate,
              endDate: wmEndDate,
            },
          },
        },
      ],
      [
        {
          element: getGraphComponent(
            'consumption-management-recommendations',
            true
          ),
          contentType: CHART_TYPES.TABLE,
          graphName: 'consumption-management-recommendations',
          graphHeading: t('graphHeadings.consumptionManagement'),
          tableView: {
            columns: getRecommendationDetailsTableColumns(
              getProviderForConnection(selectedConnection),
              cmRecommender
            ),
            isTableView: true,
          },
          pdfTableExport: {
            pdfTableData: cmRecommendations,
          },
          excelExport: {
            excelData: cmRecommendations,
            excelFilters: {
              connectionName: selectedConnection?.displayName ?? '',
              startDate: cmStartDate,
              endDate: cmEndDate,
            },
          },
        },
      ],
      [
        {
          element: getGraphComponent('purchase-tactics-recommendations', true),
          contentType: CHART_TYPES.TABLE,
          graphName: 'purchase-tactics-recommendations',
          graphHeading: t('graphHeadings.purchaseTactics'),
          tableView: {
            columns: getRecommendationDetailsTableColumns(
              getProviderForConnection(selectedConnection),
              ptRecommender
            ),
            isTableView: true,
          },
          pdfTableExport: {
            pdfTableData: ptRecommendations,
          },
          excelExport: {
            excelData: ptRecommendations,
            excelFilters: {
              connectionName: selectedConnection?.displayName ?? '',
              startDate: ptStartDate,
              endDate: ptEndDate,
            },
          },
        },
      ],
    ],
    [
      savingsChartStartDate,
      savingsChartEndDate,
      savingsChartDailyData,
      savingsChartMonthData,
      selectedMonth,
      wmRecommendations,
      wmStartDate,
      wmEndDate,
      cmRecommendations,
      cmStartDate,
      cmEndDate,
      ptRecommendations,
      ptStartDate,
      ptEndDate,
    ]
  );

  if (!permissions.cloudConnectorList) {
    return <div className="page-content">{t('noConnectionsPermission')}</div>;
  }

  if (isEmptyDashboard) {
    return <div className="page-content">{t('noConnectionsFound')}</div>;
  }

  return (
    <>
      <AutoPilotSavingsDashboardHeader
        selectedConnection={selectedConnection}
        setSelectedConnection={setSelectedConnection}
        setIsEmptyDashboard={setIsEmptyDashboard}
        setRequestStatus={setConnectionsRequestStatus}
      />
      <div className="page-content">
        <NavigationPath />
        <div className="aupto-pilot-savings margin-24 flex flex-column flex-gap-24">
          <DashboardComponent
            component={
              <DashboardGraphContainer
                getGraphComponent={getGraphComponent}
                rows={GraphRows}
                pdfMetaData={getPdfMetaData(selectedConnection!)}
                costCardComponents={getCostComparisonCards()}
                connection={selectedConnection}
              />
            }
            requestStatus={connectionsRequestStatus}
            errorMessage={t('errorFetchingConnections')}
          />
        </div>
      </div>
    </>
  );
};

export default AutoPilotSavings;
