import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { formatNumberToCompact } from 'utils/dataFormatterUtils';

import { SpendProfileDataType } from '../../types';
import {
  getHeaderBySpendType,
  getProfileDescriptionBySpendType,
} from './utils';
import { getClassNameBySpendName } from '../../utils';
import SpendProfileIcon from '../SpendProfileIcon';

import './index.scss';

type SpendProfileHeaderProps = {
  spendData: SpendProfileDataType;
};

const SpendProfileHeader = ({ spendData }: SpendProfileHeaderProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  return (
    <div
      className="spend-profile-header flex flex-column flex-gap-8"
      data-testid="spend-profile-header"
    >
      <div
        className={`spend-type font-button ${getClassNameBySpendName(
          spendData.spendType
        )}`}
      >
        {getHeaderBySpendType(spendData.spendType)}
      </div>
      <div className="font-caption">
        {getProfileDescriptionBySpendType(spendData.spendType)}
      </div>
      <div
        className={`apps-metrics font-button flex flex-center flex-gap-8  ${getClassNameBySpendName(
          spendData.spendType
        )}`}
      >
        <SpendProfileIcon spendType={spendData.spendType} />
        <div>
          {`${spendData.appsCount} ${t(
            'services'
          )} (${currencySymbol} ${formatNumberToCompact(spendData.appsSpend)})`}
        </div>
      </div>
    </div>
  );
};

export default SpendProfileHeader;
