import { useCallback, useRef } from 'react';
import { Table as BaseTable } from 'antd';
import Loader from 'components/Loader';

const InfiniteScrollTable = ({
  loading = false,
  tableLoading = false,
  designVersion2 = false,
  ...remainingProps
}: any) => {
  const observer = useRef<any>();
  const lastRowElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          remainingProps.handleChangePageNumber();
        }
      });
      if (node) observer.current.observe(node);
    },
    [remainingProps]
  );

  return (
    <BaseTable
      bordered
      {...remainingProps}
      rootClassName={`${remainingProps.rootClassName} ${
        designVersion2 ? 'design-v2' : 'design-v1'
      } ${remainingProps.fillContainer ? 'fill-container' : ''}`}
      loading={{ spinning: tableLoading, indicator: <Loader /> }}
      onRow={(_record: any, index: number) => {
        if (index + 1 === remainingProps?.dataSource?.length) {
          return {
            ref: lastRowElementRef,
          };
        }
        return {};
      }}
    />
  );
};

export default InfiniteScrollTable;
