import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getGroupsData
 * @description Function to get services groups
 * @return axios response from GET request
 */
export const getGroupsData = async () => {
  const api = APIS.GET_SERVICE_GROUP;
  return ServiceCalls.get(api);
};
