import i18n from 'i18n';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { sumBy, uniqBy } from 'lodash';

import { store } from 'redux/store';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import {
  AWS_CHECKS_MAP,
  AWS_CHECK_IDS,
  AWSRecommendationSummaryColumns,
} from 'constants/recommendations';
import { PROVIDER } from 'constants/cloudProviders';
import {
  ConnectionListType,
  FilterGroupType,
  GranulateCostImpactType,
} from 'types/dashboard';
import { RulesetFilterGroupsType } from 'types/dataTypes';
import { HYPHEN_DATE_FORMAT, formatDateFieldByProvider } from 'utils/date';
import {
  generateGraphColors,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import { RecommendationSource } from 'pages/CostOptimizationInsightsPage/constants';
import LabelOverflowWithTooltip from 'components/LabelOverflowWithTooltip';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { DashboardListType } from 'types/navigationMenu';

import { ColouredMonthlyCostByCheckId, CostByAccountId } from './types';
import { getGranulateDetailedRecommendationColumns } from '../CostRecommendationSummaryDashboard/utils';
import { TABLE_HEADER_LENGTH_LIMIT } from './constants';

/**
 * @function getFilterFieldsFromFilters
 * @description Function to return the filter fields from filters
 * @param filters list of filter groups
 * @returns List of filter fields
 */
const getFilterFieldsFromFilters = (filters: FilterGroupType[]) => {
  const filterFields: string[] = [];

  filters.forEach((item) =>
    item.filters.forEach((filter) => {
      if (!filterFields.includes(filter.field)) {
        filterFields.push(filter.field);
      }
    })
  );

  return filterFields;
};

/**
 * @function getRecommendationDtoColumns
 * @description Function to return the common columns for recommendation DTO
 * @param checkIdMap checkId to columns data map for the checkId
 * @param filterFields list of filter fields to be added
 * @returns List of columns for recommendation DTO
 */
const getRecommendationDtoColumns = (
  checkIdMap: any,
  filterFields: string[]
) => {
  return [
    ...(checkIdMap?.rulesetFiletrsAdditionalColumns || []),
    ...filterFields
      .filter(
        (field) =>
          !checkIdMap?.rulesetFiletrsAdditionalColumns?.some(
            (filter: any) => filter.label === field
          )
      )
      .map((field) => ({ field: field })),
  ];
};

/**
 * @function getCostPerAccountQuery
 * @description Function to get the savings per account id query
 * @param checkId checkId for which the query is constructed
 * @param rulesetFilters ruleset filters for the selected rulesets
 * @returns Object containing the query DTO
 */
export const getCostPerAccountQuery = (
  checkId: string,
  rulesetFilters: RulesetFilterGroupsType[] | undefined
) => {
  const checkIdMap = AWS_CHECKS_MAP.find(
    (map) => map.check.toLowerCase() === checkId.toLowerCase()
  );

  let dateField = QUERY_FIELDS.DATE_PARSE_DATE_TIME;
  let rownumField = `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}", format_datetime(cast(date_parse(datetime,'%Y-%m-%d %H:%i:%s') as date),'YYYYMM') ORDER BY status_priority DESC, datetime DESC)`;
  if (checkId.toLowerCase() === AWS_CHECK_IDS.RIGHT_SIZING_EC2.toLowerCase()) {
    dateField = QUERY_FIELDS.DATE_PARSE_GENERATION_TIME;
    rownumField = `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}", format_datetime(cast(date_parse(generationtime,'%Y-%m-%dT%H:%i:%sZ') as date),'YYYYMM') ORDER BY status_priority DESC, generationtime DESC)`;
  }

  let filters: FilterGroupType[] = [];
  (rulesetFilters ?? [])
    ?.filter((item) => item.checkId?.toLowerCase() === checkId.toLowerCase())
    .forEach((item) => {
      filters.push(...item.filterGroups);
    });

  const filterFields: string[] = getFilterFieldsFromFilters(filters);

  return {
    checkId: checkId.toLowerCase(),
    recommendationsDto: {
      columns: [
        {
          field: 'accountid',
        },
        ...getRecommendationDtoColumns(checkIdMap, filterFields),
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
          {
            label: 'rownum',
            field: rownumField,
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: dateField,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment().format(
                  HYPHEN_DATE_FORMAT
                )} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: dateField,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment()
                  .startOf('year')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.ROW_NUM,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
              comparator: COMPARATORS.EQUALS,
              value: 'ACTIVE',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    filterDto: {
      columns: [
        {
          field: 'accountid',
        },
        {
          label: 'estimatedSavings',
          field: checkIdMap?.savingsCostField,
        },
      ],
      filterGroups: filters,
      aggregators: [
        {
          label: 'estimatedSavings',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['accountid'],
    },
  };
};

/**
 * @function getRulesetFilteredRequestBodyForAwsCheckId
 * @description Function to return the ruleset filter request body for recommendation table
 * @param checkId check Id unique key
 * @param rulesetFilters ruleset filters
 * @returns Object containing the request body
 */
export const getRulesetFilteredRequestBodyForAwsCheckId = (
  checkId: string,
  rulesetFilters: RulesetFilterGroupsType[] | undefined
) => {
  const checkIdMap = AWS_CHECKS_MAP.find((map) => map.check === checkId);

  let dateField = QUERY_FIELDS.DATE_PARSE_DATE_TIME;
  let monthField = QUERY_FIELDS.FORMAT_DATE_TIME_YYYYMM;
  let rownumField = `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}", format_datetime(cast(date_parse(datetime,'%Y-%m-%d %H:%i:%s') as date),'YYYYMM') ORDER BY status_priority DESC, datetime DESC)`;
  if (checkId.toLowerCase() === AWS_CHECK_IDS.RIGHT_SIZING_EC2.toLowerCase()) {
    dateField = QUERY_FIELDS.DATE_PARSE_GENERATION_TIME;
    monthField = QUERY_FIELDS.FORMAT_GENERATION_TIME_YYYYMM;
    rownumField = `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}", format_datetime(cast(date_parse(generationtime,'%Y-%m-%dT%H:%i:%sZ') as date),'YYYYMM') ORDER BY status_priority DESC, generationtime DESC)`;
  }

  let filters: FilterGroupType[] = [];
  (rulesetFilters ?? [])
    ?.filter((item) => item.checkId?.toLowerCase() === checkId.toLowerCase())
    .forEach((item) => {
      filters.push(...item.filterGroups);
    });

  const filterFields: string[] = getFilterFieldsFromFilters(filters);

  return {
    checkName: Object.entries(AWS_CHECK_IDS).find(
      ([, value]) => value === checkId
    )?.[0],
    checkId: checkId.toLowerCase(),
    recommendationsDto: {
      columns: [
        ...getRecommendationDtoColumns(checkIdMap, filterFields),
        {
          label: 'month',
          field: monthField,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
          {
            label: 'rownum',
            field: rownumField,
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: dateField,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment()
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: dateField,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment()
                  .subtract(2, 'months')
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.ROW_NUM,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
              comparator: COMPARATORS.EQUALS,
              value: 'ACTIVE',
              conjunctToNextFilter: CONJUNCTIONS.OR,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    filterDto: {
      columns: [
        {
          label: 'month',
          field: QUERY_FIELDS.MONTH,
        },
        {
          label: 'estimatedSavings',
          field: checkIdMap?.savingsCostField,
        },
      ],
      filterGroups: filters,
      aggregators: [
        {
          label: 'estimatedSavings',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
    },
  };
};

/**
 * @function getDetailedQueryFilterDtoColumns
 * @description Function to return the detailed query filter dto columns
 * @param columns columns for filter dto
 * @returns List of modified columns
 */
const getDetailedQueryFilterDtoColumns = (columns: any[]) => {
  return columns.map((item: any) => {
    if (item.useDataIndexAsLabel) {
      return { field: item.dataIndex, label: item.dataIndex };
    }

    return {
      field: item.key,
      label: item.dataIndex,
    };
  });
};

/**
 * @function getDetailedQueryRecommendationDtoColumns
 * @description Function to return the detailed query recommendation dto columns
 * @param columns columns for recommendation dto
 * @returns List of modified columns
 */
const getDetailedQueryRecommendationDtoColumns = (
  filteredColumns: any[],
  checkIdMap: any,
  filterFields: string[]
) => {
  return [
    ...filteredColumns.map((item) => {
      if (item.useDataIndexAsLabel) {
        return {
          field: item.key,
          label: item.dataIndex,
        };
      }
      return { field: item.key };
    }),
    ...(checkIdMap?.rulesetFiletrsAdditionalColumns || []),
    ...filterFields.map((field) => ({ field: field })),
  ];
};

/**
 * @function getDetailedSavingsQuery
 * @description Function to return the ruleset filter request body for detailed savings
 * @param checkId check Id unique key
 * @param month selected month
 * @param rulesetFilters ruleset filters
 * @returns Object containing the request body
 */
export const getDetailedSavingsQuery = (
  checkId: string,
  dateRange: RangeValue<Moment>,
  rulesetFilters: RulesetFilterGroupsType[] | undefined
) => {
  const checkIdMap = AWS_CHECKS_MAP.find((item) => item.check === checkId);
  const columns = AWSRecommendationSummaryColumns.filter(
    (item) => !item.checkIds || item.checkIds.includes(checkId as AWS_CHECK_IDS)
  );
  const filteredColumns = columns.filter(
    (item) =>
      !checkIdMap?.rulesetFiletrsAdditionalColumns?.some(
        (field) => field.label === item.key
      )
  );

  let dateField = QUERY_FIELDS.DATE_PARSE_DATE_TIME;
  let rownumField = `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}", format_datetime(cast(date_parse(datetime,'%Y-%m-%d %H:%i:%s') as date),'YYYYMM') ORDER BY status_priority DESC, datetime DESC)`;
  if (checkId.toLowerCase() === AWS_CHECK_IDS.RIGHT_SIZING_EC2.toLowerCase()) {
    dateField = QUERY_FIELDS.DATE_PARSE_GENERATION_TIME;
    rownumField = `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}", format_datetime(cast(date_parse(generationtime,'%Y-%m-%dT%H:%i:%sZ') as date),'YYYYMM') ORDER BY status_priority DESC, generationtime DESC)`;
  }

  let filters: FilterGroupType[] = [];
  (rulesetFilters ?? [])
    ?.filter((item) => item.checkId?.toLowerCase() === checkId.toLowerCase())
    .forEach((item) => {
      filters.push(...item.filterGroups);
    });

  let filterFields: string[] = getFilterFieldsFromFilters(filters);

  const filteredColumnsLabels = filteredColumns.map((item) => {
    if (item.useDataIndexAsLabel) {
      return item.dataIndex;
    }

    return item.key;
  });
  filterFields = filterFields.filter(
    (field) =>
      !checkIdMap?.rulesetFiletrsAdditionalColumns?.some(
        (filter) => filter.label === field
      ) &&
      !(
        filteredColumnsLabels.includes(field) ||
        filteredColumnsLabels.includes(`"${field}"`)
      )
  );

  let additionalColumns: any = {};
  if (checkId.toLowerCase() === AWS_CHECK_IDS.RIGHT_SIZING_EC2.toLowerCase()) {
    additionalColumns.rightsizingRecoms = true;
    additionalColumns.currInstanceTypeFieldLabel = 'instancetype';
    additionalColumns.recommendedInstanceTypeFieldLabel =
      'recommendedinstancetype';
  }

  return {
    checkId: checkId.toLowerCase(),
    ...additionalColumns,
    recommendationsDto: {
      columns: getDetailedQueryRecommendationDtoColumns(
        filteredColumns,
        checkIdMap,
        filterFields
      ),
      subQuery: {
        columns: [
          {
            field: '*',
          },
          {
            label: 'rownum',
            field: rownumField,
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: dateField,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${dateRange?.[1]
                  ?.endOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: dateField,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${dateRange?.[0]
                  ?.startOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.ROW_NUM,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
              comparator: COMPARATORS.EQUALS,
              value: 'ACTIVE',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: true,
    },
    filterDto: {
      columns: getDetailedQueryFilterDtoColumns(columns),
      filterGroups: filters,
    },
  };
};

/**
 * @function getAwsSavingsPlanTrendQuery
 * @description Function to return the savings plan trend query
 * @param selectedDashboard Dashboard details of the selected dashboard
 * @param selectedConnection connection details of the selected dashboard
 * @param startDate start date filter
 * @param endDate end date filter
 * @returns Object containing the query DTO
 */
export const getAwsSavingsPlanTrendQuery = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  startDate: string,
  endDate: string
) => {
  const isStaticData = isDashboardWithStaticData(selectedDashboard);

  const sumSavingsPlanCommitmentField = isStaticData
    ? getQueryFieldByDataSource(
        selectedDashboard!.dashBoardType,
        QUERY_FIELDS.CAST_SUM_SAVINGS_PLAN_USED_COMMITMENT,
        selectedConnection.focusConversionEnabled
      )
    : QUERY_FIELDS.SUM_SAVINGS_PLAN_USED_COMMITMENT;
  const sumSavingsPlanTotal = isStaticData
    ? getQueryFieldByDataSource(
        selectedDashboard!.dashBoardType,
        QUERY_FIELDS.CAST_SUM_SAVINGS_PLAN_TOTAL_COMMITMENT_TO_DATE,
        selectedConnection.focusConversionEnabled
      )
    : QUERY_FIELDS.SUM_SAVINGS_PLAN_TOTAL_COMMITMENT_TO_DATE;
  return {
    columns: [
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          selectedDashboard!.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'type',
        field: getQueryFieldByDataSource(
          selectedDashboard!.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: `(1 - ${sumSavingsPlanCommitmentField} / ${sumSavingsPlanTotal}) * 100`,
      },
    ],
    groupBy: ['name', 'type'],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard!.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.COMPUTE_SAVINGS_PLANS,
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard!.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_LINE_ITEM_TYPE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.SAVINGS_PLAN_RECURRING_FEE,
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard!.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL_TO,
            value: '',
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard!.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startDate,
              PROVIDER.AWS,
              isStaticData
            ),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard!.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              endDate,
              PROVIDER.AWS,
              isStaticData
            ),
          },
        ],
      },
    ],
  };
};

/**
 * @function getConsolidatedEstimatedSavingsPerAccount
 * @description Returns the consolidated Estimated cost per account of CSP and Granulate
 * @param cspEstimatedSavingsPerAccountData List of CSP estimated cost per account
 * @param granulateCostImpact List of Granulate cost impact per account
 * @returns List of consolidated cost of CSP and Granulate per account
 */
export const getConsolidatedEstimatedSavingsPerAccount = (
  cspEstimatedSavingsPerAccountData: CostByAccountId[],
  granulateCostImpact: GranulateCostImpactType[]
) => {
  if (!granulateCostImpact.length) {
    return cspEstimatedSavingsPerAccountData;
  }

  return cspEstimatedSavingsPerAccountData.map((account) => ({
    ...account,
    cost:
      account.cost +
      (granulateCostImpact.find(
        (granulateCost) => granulateCost.projectId === account.accountId
      )?.totalCostImpact ?? 0),
  }));
};

/**
 * @function getConsolidatedConsumptionManagementCost
 * @description Returns the consolidated consumption management cost of CSP and Granulate
 * @param permissions Object containing the permissions list
 * @param cspConsumptionManagementSavingsData List of CSP consumption management cost by checkId
 * @param granulateCostImpact List of Granulate cost impact per project
 * @returns List of consolidated cost of CSP per checkId and Granulate
 */
export const getConsolidatedConsumptionManagementCost = (
  permissions: { [key: string]: boolean },
  selectedDashboard: DashboardListType,
  cspConsumptionManagementSavingsData: ColouredMonthlyCostByCheckId[],
  granulateCostImpact: GranulateCostImpactType[]
): ColouredMonthlyCostByCheckId[] => {
  // Return CSP data if the Third Party Apps permissions is not present
  if (
    !permissions.thirdPartyAppsRead ||
    isDashboardWithStaticData(selectedDashboard)
  ) {
    return cspConsumptionManagementSavingsData;
  }

  // Add color to the Granulate item in the chart
  const cspRecommendersCount = uniqBy(
    cspConsumptionManagementSavingsData,
    'recommender'
  ).length;
  const color = generateGraphColors(cspRecommendersCount + 1)[
    cspRecommendersCount
  ];

  const granulateEntry: ColouredMonthlyCostByCheckId = {
    check: i18n.t('granulate'),
    color,
    cost: [0, 0, sumBy(granulateCostImpact, 'totalCostImpact')], // Add Granulate cost impact for the current month
  };

  return [...cspConsumptionManagementSavingsData, granulateEntry];
};

/**
 * @function getDetailedSavingsTableColumns
 * @description Returns the columns for detailed recommendations table based on the checkId
 * @param selectedCheckId Recommender for which the columns are returned
 * @param addTooltip Boolean to indicate whether the tooltip is to be added or not
 * @returns List of columns for recommendation table
 */
export const getDetailedSavingsTableColumns = (
  selectedCheckId: string,
  addTooltip: boolean
) => {
  const commonUtilitySlice = store.getState().commonUtility;
  const { currencySymbol } = commonUtilitySlice;

  if (selectedCheckId === RecommendationSource.GRANULATE) {
    return getGranulateDetailedRecommendationColumns(addTooltip);
  }

  let columns: any[] = [
    {
      title: '#',
      align: 'left',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: 'left',
    },
    ...AWSRecommendationSummaryColumns.filter(
      (item) =>
        !item.checkIds ||
        item.checkIds.includes(selectedCheckId as AWS_CHECK_IDS)
    ).map((item) => ({
      ...item,
      title: addTooltip
        ? LabelOverflowWithTooltip(item.title, TABLE_HEADER_LENGTH_LIMIT)
        : item.title,
      render: (text: any) =>
        item.costField ? currencySymbol + numberCommaSeparator(text) : text,
    })),
  ];
  columns[columns.length - 1].align = 'right';
  columns[1].fixed = 'left';
  return columns;
};
