import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SelectDropdown from 'components/Select';
import { FormLabel } from 'components/FormLabel';
import {
  setTicketIntegrationData,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';
import { getValidationStyle, validateEmptyField } from 'utils/validations';
import { AssignmentToType } from 'pages/TicketIntegrationPage/types';
import {
  getAssigneeGroups,
  getAssignToList,
  getCategories,
} from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

type DefaultsDetailsProps = {
  isValidCredentials: boolean;
};

const DefaultsDetails = ({ isValidCredentials }: DefaultsDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ticketIntegrationData } = useSelector(ticketIntegration);

  const [assignmentGroups, setAssignmentGroups] = useState<string[]>([]);
  const [assignmentToList, setAssignmentToList] = useState<AssignmentToType[]>(
    []
  );
  const [categories, setCategories] = useState<string[]>([]);
  const [
    defaultAssignmentGroupValidationMessage,
    setDefaultAssignmentGroupValidationMessage,
  ] = useState('');
  const [
    defaultAssignedToValidationMessage,
    setDefaultAssignedToValidationMessage,
  ] = useState('');
  const [categoryValidationMessage, setCategoryValidationMessage] =
    useState('');
  const [assignmentGroupsLoading, setAssignmentGroupsLoading] = useState(false);
  const [assignmentToListLoading, setAssignmentToListLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  useEffect(() => {
    if (isValidCredentials) {
      getAllGroups();
      getAllAssignees();
      getAllCategories();
    } else {
      setAssignmentGroups([]);
      setAssignmentToList([]);
      setCategories([]);
    }
  }, [isValidCredentials]);

  const getAllGroups = () => {
    setAssignmentGroupsLoading(true);

    const requestBody = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
    };

    getAssigneeGroups(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setAssignmentGroups(res.data.responseData);
          setAssignmentGroupsLoading(false);
          return;
        }
        setAssignmentGroupsLoading(false);
        setAssignmentGroups([]);
      })
      .catch((e) => {
        onApiCallError(e);
        setAssignmentGroupsLoading(false);
        setAssignmentGroups([]);
      });
  };
  const getAllAssignees = () => {
    setAssignmentToListLoading(true);

    const requestBody = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
    };

    getAssignToList(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setAssignmentToListLoading(false);
          setAssignmentToList(res.data.responseData);
          return;
        }

        setAssignmentToListLoading(false);
        setAssignmentToList([]);
      })
      .catch((e) => {
        onApiCallError(e);
        setAssignmentToListLoading(false);
        setAssignmentToList([]);
      });
  };

  const getAllCategories = () => {
    setCategoriesLoading(true);

    const requestBody = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
    };

    getCategories(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setCategoriesLoading(false);
          setCategories(res.data.responseData);
          return;
        }

        setCategoriesLoading(false);
        setCategories([]);
      })
      .catch((e) => {
        onApiCallError(e);
        setCategoriesLoading(false);
        setCategories([]);
      });
  };

  const getDropdown = (data: string[]) =>
    data?.map((option) => (
      <Select.Option key={option} value={option}>
        {option}
      </Select.Option>
    ));

  const AssignedToDropdown = (
    <>
      {assignmentToList?.map((option) => (
        <Select.Option key={option.email} value={option.email}>
          {option.name}
        </Select.Option>
      ))}
    </>
  );

  return (
    <div className="collapse-body flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(
            'ticketIntegrationLabels.formLabels.step2.defaultAssignmentGroup'
          )}
          required={true}
        />
        <SelectDropdown
          showSearch
          value={ticketIntegrationData.defaultAssignmentGroup}
          placeholder={t(
            'ticketIntegrationLabels.formLabels.step2.defaultAssignmentGroupPlaceholder'
          )}
          menu={getDropdown(assignmentGroups)}
          onSelect={(value: any) => {
            validateEmptyField(
              value,
              t(
                'ticketIntegrationLabels.formLabels.step2.defaultAssignmentGroup'
              ),
              setDefaultAssignmentGroupValidationMessage
            );
            dispatch(
              setTicketIntegrationData({
                ...ticketIntegrationData,
                defaultAssignmentGroup: value,
              })
            );
          }}
          onBlur={() => {
            validateEmptyField(
              ticketIntegrationData.defaultAssignmentGroup,
              t(
                'ticketIntegrationLabels.formLabels.step2.defaultAssignmentGroup'
              ),
              setDefaultAssignmentGroupValidationMessage
            );
          }}
          loading={assignmentGroupsLoading}
        />
        <span
          style={{
            display: `${getValidationStyle(
              defaultAssignmentGroupValidationMessage
            )}`,
          }}
          className="font-validation-error"
        >
          {defaultAssignmentGroupValidationMessage}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(
            'ticketIntegrationLabels.formLabels.step2.defaultAssignedTo'
          )}
          required={true}
        />
        <SelectDropdown
          showSearch
          value={ticketIntegrationData.defaultAssignedTo}
          placeholder={t(
            'ticketIntegrationLabels.formLabels.step2.defaultAssignedToPlaceholder'
          )}
          menu={AssignedToDropdown}
          onSelect={(value: any) => {
            validateEmptyField(
              value,
              t('ticketIntegrationLabels.formLabels.step2.defaultAssignedTo'),
              setDefaultAssignedToValidationMessage
            );
            dispatch(
              setTicketIntegrationData({
                ...ticketIntegrationData,
                defaultAssignedTo: value,
              })
            );
          }}
          onBlur={() => {
            validateEmptyField(
              ticketIntegrationData.defaultAssignedTo,
              t('ticketIntegrationLabels.formLabels.step2.defaultAssignedTo'),
              setDefaultAssignedToValidationMessage
            );
          }}
          loading={assignmentToListLoading}
        />
        <span
          style={{
            display: `${getValidationStyle(
              defaultAssignedToValidationMessage
            )}`,
          }}
          className="font-validation-error"
        >
          {defaultAssignedToValidationMessage}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('ticketIntegrationLabels.formLabels.step2.category')}
          required={true}
        />
        <SelectDropdown
          showSearch
          value={ticketIntegrationData.category}
          placeholder={t(
            'ticketIntegrationLabels.formLabels.step2.categoryPlaceholder'
          )}
          menu={getDropdown(categories)}
          onSelect={(value: any) => {
            validateEmptyField(
              value,
              t('ticketIntegrationLabels.formLabels.step2.category'),
              setCategoryValidationMessage
            );
            dispatch(
              setTicketIntegrationData({
                ...ticketIntegrationData,
                category: value,
              })
            );
          }}
          onBlur={() => {
            validateEmptyField(
              ticketIntegrationData.category,
              t('ticketIntegrationLabels.formLabels.step2.category'),
              setCategoryValidationMessage
            );
          }}
          loading={categoriesLoading}
        />
        <span
          style={{
            display: `${getValidationStyle(categoryValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {categoryValidationMessage}
        </span>
      </div>
    </div>
  );
};

export default DefaultsDetails;
