import { useTranslation } from 'react-i18next';

import ChartsList from '../ChartsList';

const ChartTypes = () => {
  const { t } = useTranslation();

  return (
    <div className="chart-types">
      <div className="font-caption-bold">
        {t('customDashboard.optionsLabels.type')}
      </div>
      <ChartsList />
    </div>
  );
};

export default ChartTypes;
