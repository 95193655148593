import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { useSelector } from 'react-redux';

import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import { RECOMMENDATION_CATEGORIES_LABELS } from 'pages/CostOptimizationInsightsPage/constants';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import GraphHeader from 'components/GraphHeader';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray } from 'utils/handleErrors';

import { getCostSavingsSummaryExcelData } from '../../utils';
import { CategoryCostDetailsType } from '../../types';

type CategoryCostSavingsTableProps = {
  graphHeading: string;
  graphName: string;
  pdfView: boolean;
  categoryCostSavingsData: CategoryCostDetailsType[];
  requestStatus: string;
  selectedRecommendationType: string | undefined;
  selectedSavingsType: string | undefined;
  selectedRowKey: React.Key | undefined;
  setSelectedRowKey: (val: React.Key | undefined) => void;
  setSelectedRow: (val: CategoryCostDetailsType | undefined) => void;
};

const CategoryCostSavingsTable = ({
  graphHeading,
  graphName,
  pdfView,
  categoryCostSavingsData,
  requestStatus,
  selectedRecommendationType,
  selectedSavingsType,
  selectedRowKey,
  setSelectedRowKey,
  setSelectedRow,
}: CategoryCostSavingsTableProps) => {
  const { t } = useTranslation();
  const { selectedCostOptimizationInsightsConnection } = useSelector(
    costOptimizationInsights
  );

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'left',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 20,
      fixed: 'left',
    },
    {
      title: t('costOptimizationInsight.recommendationTable.recommendationId'),
      dataIndex: 'recommendationId',
      key: 'recommendationId',
      width: 220,
      fixed: 'left',
    },
    {
      title: t(
        'costOptimizationInsight.recommendationTable.recommendationProblem'
      ),
      dataIndex: 'recommendationProblem',
      key: 'recommendationProblem',
      width: 300,
    },
    {
      title: t('costOptimizationInsight.recommendationTable.impactedField'),
      dataIndex: 'impactedField',
      key: 'impactedField',
      width: 150,
    },
    {
      title: t('costOptimizationInsight.recommendationTable.costSavings'),
      dataIndex: 'costSavings',
      key: 'costSavings',
      width: 100,
    },
    {
      title: t('costOptimizationInsight.recommendationTable.recStatus'),
      dataIndex: 'recStatus',
      key: 'recStatus',
      width: 100,
    },
    {
      title: t('costOptimizationInsight.recommendationTable.category'),
      dataIndex: 'recommendationCategory',
      render: (text: string) =>
        RECOMMENDATION_CATEGORIES_LABELS.find((item) => item.key === text)
          ?.label ?? text,
      key: 'recommendationCategory',
      width: 100,
    },
  ];

  /**
   * @function getComponent
   * @description Function to return the graph or table component
   * @returns JSX element containing graph or the table
   */
  const getComponent = () => {
    if (!selectedRecommendationType) {
      return (
        <div className="flex flex-column flex-center full-height no-chart-selection">
          <div className="font-body heading">
            {t(
              'costOptimizationInsight.statusDashboard.recommendationStatusTable.noDataFound'
            )}
          </div>
          <div className="font-caption sub-heading">
            {t(
              'costOptimizationInsight.statusDashboard.recommendationStatusTable.selectChartsToPopulateData'
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="graph">
        <Table
          rowClassName={(_record: any, index: number) => {
            if (selectedRowKey === index) {
              return 'selected-row';
            }
          }}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: [selectedRowKey],
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
              setSelectedRowKey(selectedRowKeys[0]);
              setSelectedRow(selectedRows[0]);
            },
          }}
          pagination={false}
          dataSource={categoryCostSavingsData.map((item, index) => ({
            key: index,
            ...item,
          }))}
          columns={columns}
          loading={
            evaluateRequestArray([requestStatus]) === REQUEST_STATUS.PROCESSING
          }
          scroll={{ y: '100%' }}
          designVersion2
          fillContainer
        />
      </div>
    );
  };

  return (
    <div
      className="graph-area summary-table flex flex-column flex-gap-16 full-width"
      id="graph-container"
    >
      <GraphHeader
        heading={graphHeading}
        graphName={graphName}
        isTableViewSwitch={false}
        isTableView={true}
        showExpandIcon={!pdfView}
        isDownloadable={!pdfView && !!selectedRecommendationType}
        excelData={getCostSavingsSummaryExcelData(
          selectedCostOptimizationInsightsConnection?.name ?? '',
          graphHeading,
          categoryCostSavingsData,
          selectedSavingsType
        )}
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray([requestStatus])}
      />
    </div>
  );
};

export default CategoryCostSavingsTable;
