import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectPurchase,
  setSelectedReservedInstancesTab,
} from 'redux/purchaseSlice';

import { PurchaseReservedInstancesTabs } from './constants';
import EC2Purchased from './components/EC2Purchased';
import RDSPurchased from './components/RDSPurchased';
import RedshiftPurchased from './components/RedshiftPurchased';
import MemoryDbPurchased from './components/MemoryDbPurchased';
import OpenSearchPurchased from './components/OpenSearchPurchased';
import ElastiCachePurchased from './components/ElastiCachePurchased';
import CommitmentsSwitch from '../CommitmentsSwitch';
import EC2Recommendation from './components/EC2Recommendation';
import RDSRecommendation from './components/RDSRecommendation';
import RedshiftRecommendation from './components/RedshiftRecommendation';
import OpenSearchRecommendation from './components/OpenSearchRecommendation';
import ElastiCacheRecommendation from './components/ElastiCacheRecommendation';
import MemoryDbRecommendation from './components/MemoryDbRecommendation';

const ReservedInstances = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedReservedInstancesTab } = useSelector(selectPurchase);

  const PurchaseReservedInstanceTabsList = [
    {
      label: t('purchasePage.reservedInstancesLabels.ec2'),
      key: PurchaseReservedInstancesTabs.EC2,
      children: (
        <CommitmentsSwitch
          heading={`${t('purchasePage.reservedInstancesLabels.ec2')} ${t(
            'purchasePage.reservedInstances'
          )}`}
          purchasedComponent={<EC2Purchased />}
          recommendationComponent={<EC2Recommendation />}
        />
      ),
    },
    {
      label: t('purchasePage.reservedInstancesLabels.rds'),
      key: PurchaseReservedInstancesTabs.RDS,
      children: (
        <CommitmentsSwitch
          heading={`${t('purchasePage.reservedInstancesLabels.rds')} ${t(
            'purchasePage.reservedInstances'
          )}`}
          purchasedComponent={<RDSPurchased />}
          recommendationComponent={<RDSRecommendation />}
        />
      ),
    },
    {
      label: t('purchasePage.reservedInstancesLabels.redshift'),
      key: PurchaseReservedInstancesTabs.REDSHIFT,
      children: (
        <CommitmentsSwitch
          heading={`${t('purchasePage.reservedInstancesLabels.redshift')} ${t(
            'purchasePage.reservedInstances'
          )}`}
          purchasedComponent={<RedshiftPurchased />}
          recommendationComponent={<RedshiftRecommendation />}
        />
      ),
    },
    {
      label: t('purchasePage.reservedInstancesLabels.openSearch'),
      key: PurchaseReservedInstancesTabs.OPENSEARCH,
      children: (
        <CommitmentsSwitch
          heading={`${t('purchasePage.reservedInstancesLabels.openSearch')} ${t(
            'purchasePage.reservedInstances'
          )}`}
          purchasedComponent={<OpenSearchPurchased />}
          recommendationComponent={<OpenSearchRecommendation />}
        />
      ),
    },
    {
      label: t('purchasePage.reservedInstancesLabels.elastiCache'),
      key: PurchaseReservedInstancesTabs.ELASTICACHE,
      children: (
        <CommitmentsSwitch
          heading={`${t(
            'purchasePage.reservedInstancesLabels.elastiCache'
          )} ${t('purchasePage.reservedInstances')}`}
          purchasedComponent={<ElastiCachePurchased />}
          recommendationComponent={<ElastiCacheRecommendation />}
        />
      ),
    },
    {
      label: t('purchasePage.reservedInstancesLabels.memoryDb'),
      key: PurchaseReservedInstancesTabs.MEMORYDB,
      children: (
        <CommitmentsSwitch
          heading={`${t('purchasePage.reservedInstancesLabels.memoryDb')} ${t(
            'purchasePage.reservedInstances'
          )}`}
          purchasedComponent={<MemoryDbPurchased />}
          recommendationComponent={<MemoryDbRecommendation />}
        />
      ),
    },
  ];

  return (
    <div>
      <Tabs
        rootClassName="purchase-reserved-instances"
        type="card"
        activeKey={selectedReservedInstancesTab}
        items={PurchaseReservedInstanceTabsList}
        onChange={function (activeKey) {
          dispatch(setSelectedReservedInstancesTab(activeKey));
        }}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default ReservedInstances;
