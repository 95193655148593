import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NAVIGATION_MENU_DATA } from 'constants/navigationMenu';
import { selectNavMenu } from 'redux/activeNavMenuSlice';
import { getMenuIdByPath, getNavigationPathTitle } from 'utils/navigationMenu';

import './index.scss';

const NavigationPath = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { navSubPath } = useSelector(selectNavMenu);

  const [path, setPath] = useState<string[]>([]);

  useEffect(() => {
    setPath(
      getNavigationPathTitle(
        getMenuIdByPath(NAVIGATION_MENU_DATA, location.pathname)
      )
    );
  }, [location]);

  return (
    <div className="navigation-path flex flex-gap-8 flex-align-items-center">
      {[...path.map((p) => t(`navigationMenu.${p}`)), ...navSubPath].map(
        (value, index) => (
          <div className="flex flex-gap-8" key={value}>
            <span
              className={index === 0 ? 'font-caption-bold' : 'font-caption'}
            >
              {value}
            </span>
            {index !== path.length + navSubPath.length - 1 && (
              <span className="font-caption">/</span>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default NavigationPath;
