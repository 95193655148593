import { useSelector } from 'react-redux';

import { selectTheme } from 'redux/themeSlice';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';
import { numberToMebibyteWithSuffix } from 'pages/CostOptimizationInsightsPage/components/Granulate/RecommendationPage/utils';

type MemoryRequestValueProps = {
  recommendation: RecommendationList;
};

const MemoryRequestValue = ({ recommendation }: MemoryRequestValueProps) => {
  const { theme } = useSelector(selectTheme);

  if (!recommendation.containers || !recommendation.containers.length) {
    return <div>N/A</div>;
  }

  return (
    <div className="flex flex-column flex-gap-8">
      {recommendation.containers.map((data) =>
        !data.recommended_memory && !data.current_mem ? (
          '-'
        ) : (
          <div
            className="flex flex-gap-4 flex-align-items-center"
            key={data.name}
          >
            <span>{numberToMebibyteWithSuffix(data.current_mem)}</span>
            {data.recommended_memory &&
              (data.current_mem < data.recommended_memory ? (
                <Icon
                  iconName={ICONS.ARROW_RIGHT_UP_LINE}
                  size={ICONS_SIZE.XL}
                  color={theme.primaryColor}
                  dataTestId="arrow-up"
                />
              ) : (
                <Icon
                  iconName={ICONS.ARROW_RIGHT_DOWN_LINE}
                  size={ICONS_SIZE.XL}
                  color={theme.primaryColor}
                  dataTestId="arrow-down"
                />
              ))}
            <span>{numberToMebibyteWithSuffix(data.recommended_memory)}</span>
          </div>
        )
      )}
    </div>
  );
};

export default MemoryRequestValue;
