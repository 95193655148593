import i18n from 'i18n';

export const BUDGET_DATE_RANGES = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY',
  CUSTOM_PERIOD: 'CUSTOM_PERIOD',
};

export const DATE_RANGE = [
  {
    key: BUDGET_DATE_RANGES.MONTHLY,
    title: 'monthly',
  },
  {
    key: BUDGET_DATE_RANGES.QUARTERLY,
    title: 'quarterly',
  },
  {
    key: BUDGET_DATE_RANGES.YEARLY,
    title: 'yearly',
  },
  {
    key: BUDGET_DATE_RANGES.CUSTOM_PERIOD,
    title: 'customRange',
  },
];

export const BUDGET_TYPES = [
  {
    value: 'SPECIFIC_AMOUNT',
    label: i18n.t('addBudgetAlert.createBudgetLabels.amount.specificAmount'),
    forDateRange: [
      BUDGET_DATE_RANGES.MONTHLY,
      BUDGET_DATE_RANGES.QUARTERLY,
      BUDGET_DATE_RANGES.YEARLY,
      BUDGET_DATE_RANGES.CUSTOM_PERIOD,
    ],
  },
];

export const GCP_TRIGGER_AT = [
  {
    value: 'ACTUAL',
    label: i18n.t('addBudgetAlert.createBudgetLabels.actions.actualCost'),
    forDateRange: [
      BUDGET_DATE_RANGES.MONTHLY,
      BUDGET_DATE_RANGES.QUARTERLY,
      BUDGET_DATE_RANGES.YEARLY,
      BUDGET_DATE_RANGES.CUSTOM_PERIOD,
    ],
  },
  {
    value: 'FORECAST_SPEND',
    label: i18n.t('addBudgetAlert.createBudgetLabels.actions.forecastSpend'),
    forDateRange: [
      BUDGET_DATE_RANGES.MONTHLY,
      BUDGET_DATE_RANGES.QUARTERLY,
      BUDGET_DATE_RANGES.YEARLY,
    ],
  },
];

export const BILLING_ACCOUNT_SLICE_START = 0;
export const BILLING_ACCOUNT_SLICE_END = 36;

export const BUDGET_PERCENTAGE_MAX_LIMIT = 10000000;
