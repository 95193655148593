import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectTheme } from 'redux/themeSlice';
import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';

const MemoryRequestField = () => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);

  return (
    <div className="flex flex-gap-16 flex-align-items-center">
      <span> {t('granulateInsightDashboard.memoryRequest')}</span>
      <Tooltip title={t('granulateInsightDashboard.memoryRequestInfo')}>
        <span>
          <Icon
            iconName={ICONS.INFORMATION_LINE}
            size={ICONS_SIZE.ONE_X}
            color={theme.primaryColor}
            dataTestId="info-icon"
          />
        </span>
      </Tooltip>
    </div>
  );
};

export default MemoryRequestField;
