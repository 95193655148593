import {
  CONJUNCTIONS,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import moment from 'moment';
import {
  DATE_FORMAT,
  DATE_MONTH_SHORT,
  FULL_YEAR_FORMAT,
  HOUR_MINUTE_SECONDS,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_SHORT,
  QUARTER,
  WEEK,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  YEAR_QQ,
  YEAR_WEEK,
} from 'utils/date';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_INTERVALS,
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';
import { ApplicationDetailsDataType } from '../GranulateUsageMetering/type';

/**
 * @function getEffectiveUtilizationDateQueryField
 * @description Function to return the effective utilization date query field based on the interval
 * @param interval interval of the dates
 * @returns string query field
 */
export const getEffectiveUtilizationDateQueryField = (interval: string) => {
  switch (interval) {
    case DATE_INTERVALS.HOUR:
      return QUERY_FIELDS.CONCAT_EXTRACT_HOUR_FROM_START_TIME;

    case DATE_INTERVALS.DAY:
      return QUERY_FIELDS.EXTRACT_DATE_FROM_START_TIME;

    case DATE_INTERVALS.WEEK:
      return QUERY_FIELDS.EXTRACT_WEEK_FROM_START_TIME;

    case DATE_INTERVALS.MONTH:
      return QUERY_FIELDS.EXTRACT_MONTH_FROM_START_TIME;

    case DATE_INTERVALS.QUARTER:
      return QUERY_FIELDS.EXTRACT_QUARTER_FROM_START_TIME;

    case DATE_INTERVALS.YEAR:
      return QUERY_FIELDS.EXTRACT_YEAR_FROM_START_TIME;
  }
};

/**
 * @function getEffectiveUtilizationPercentageQueryField
 * @description Function to return the effective utilization percentage query field based on the interval
 * @param interval interval of the dates
 * @returns string query field
 */
export const getEffectiveUtilizationPercentageQueryField = (
  interval: string
) => {
  switch (interval) {
    case DATE_INTERVALS.HOUR:
    case DATE_INTERVALS.YEAR:
      return QUERY_FIELDS.FRACTIONX100;

    case DATE_INTERVALS.DAY:
      return QUERY_FIELDS.FRACTIONX100BY24;

    case DATE_INTERVALS.WEEK:
      return QUERY_FIELDS.FRACTIONX100BY24BY7;

    case DATE_INTERVALS.MONTH:
      return QUERY_FIELDS.FRACTIONX100BY24BY30;

    case DATE_INTERVALS.QUARTER:
      return QUERY_FIELDS.FRACTIONX100BY24BY30BY3;
  }
};

/**
 * @function getClusterBreakdownDateQueryField
 * @description Function to return the cluster breakdown date query field based on the interval
 * @param interval interval of the dates
 * @returns string query field
 */
export const getClusterBreakdownDateQueryField = (interval: string) => {
  switch (interval) {
    case DATE_INTERVALS.HOUR:
      return QUERY_FIELDS.CONCAT_EXTRACT_HOUR_FROM_USAGE_START_TIME;

    case DATE_INTERVALS.DAY:
      return QUERY_FIELDS.EXTRACT_DATE_FROM_USAGE_START_TIME;

    case DATE_INTERVALS.WEEK:
      return QUERY_FIELDS.CONCAT_EXTRACT_YEAR_WEEK_FROM_USAGE_START_TIME;

    case DATE_INTERVALS.MONTH:
      return QUERY_FIELDS.CONCAT_EXTRACT_YEAR_MONTH_FROM_USAGE_START_TIME;

    case DATE_INTERVALS.QUARTER:
      return QUERY_FIELDS.CONCAT_EXTRACT_YEAR_QUARTER_FROM_USAGE_START_TIME;

    case DATE_INTERVALS.YEAR:
      return QUERY_FIELDS.EXTRACT_YEAR_FROM_USAGE_START_TIME;
  }
};

/**
 * @function getDateFormatForGraphLabels
 * @description Function to return the date format based on the interval
 * @param interval interval of the dates
 * @returns string query field
 */
export const getDateFormatForGraphLabels = (interval: string) => {
  switch (interval) {
    case DATE_INTERVALS.HOUR:
      return HOUR_MINUTE_SECONDS;
    case DATE_INTERVALS.DAY:
    case DATE_INTERVALS.WEEK:
      return DATE_MONTH_SHORT;

    case DATE_INTERVALS.MONTH:
    case DATE_INTERVALS.QUARTER:
      return MONTH_YEAR_SHORT;

    case DATE_INTERVALS.YEAR:
      return FULL_YEAR_FORMAT;
  }
};

/**
 * @function getFormattedStartDate
 * @description Function to format the start date based on the date interval
 * @param startDate Date to be formatted
 * @param interval date interval eg: day, month, week, quarter, year
 * @returns formatted date
 */
export const getFormattedStartDate = (startDate: string, interval: string) => {
  if (interval === DATE_INTERVALS.WEEK) {
    const week = moment(startDate).week();
    const year = moment(startDate).year();
    return moment().year(year).week(week).startOf('week');
  }

  if (interval === DATE_INTERVALS.QUARTER) {
    return moment(startDate).startOf('quarter');
  }

  return moment(startDate);
};

/**
 * @function formatDateFieldData
 * @description Function to format the date based on the date interval
 * @param date Date to be formatted
 * @param interval date interval eg: day, month, week, quarter, year
 * @returns formatted date
 */
export const formatDateFieldData = (date: string, interval: string) => {
  switch (interval) {
    case DATE_INTERVALS.HOUR:
      return moment().set({
        hour: Number(date.split(':')[0]),
        minute: 0,
        second: 0,
      });

    case DATE_INTERVALS.DAY:
      return moment(date);

    case DATE_INTERVALS.WEEK:
      return moment(date, YEAR_WEEK).startOf(WEEK);

    case DATE_INTERVALS.MONTH:
      return moment(date, YEAR_MONTH_WITHOUT_SEPARATOR);

    case DATE_INTERVALS.QUARTER:
      return moment(date, YEAR_QQ).startOf(QUARTER);

    case DATE_INTERVALS.YEAR:
      return moment(date, FULL_YEAR_FORMAT);

    default:
      return moment(date);
  }
};

/**
 * @function onChangeDateFilter
 * @description Function handles change in date range filter option.
 * @param value option selected
 * @param setDateFilter callback function for setting the data
 */
export const onChangeDateFilter = (
  value: string,
  setDateFilter: (val: DateRangeFilter) => void
) => {
  switch (value) {
    case DATE_RANGES.TODAY:
      setDateFilter({
        option: value,
        startDate: moment().startOf('day').format(HYPHEN_DATE_FORMAT),
        endDate: moment().endOf('day').format(HYPHEN_DATE_FORMAT),
      });
      return;

    case DATE_RANGES.YESTERDAY:
      setDateFilter({
        option: value,
        startDate: moment()
          .subtract(1, 'day')
          .startOf('day')
          .format(HYPHEN_DATE_FORMAT),
        endDate: moment()
          .subtract(1, 'day')
          .endOf('day')
          .format(HYPHEN_DATE_FORMAT),
      });
      return;

    case DATE_RANGES.THIS_MONTH:
      setDateFilter({
        option: value,
        startDate: moment().startOf('month').format(HYPHEN_DATE_FORMAT),
        endDate: moment().endOf('month').format(HYPHEN_DATE_FORMAT),
      });
      return;

    case DATE_RANGES.THIS_QUARTER:
      setDateFilter({
        option: value,
        startDate: moment().startOf('quarter').format(HYPHEN_DATE_FORMAT),
        endDate: moment().endOf('quarter').format(HYPHEN_DATE_FORMAT),
      });
      return;

    case DATE_RANGES.THIS_YEAR:
      setDateFilter({
        option: value,
        startDate: moment().startOf('year').format(HYPHEN_DATE_FORMAT),
        endDate: moment().endOf('year').format(HYPHEN_DATE_FORMAT),
      });
      return;
  }
};

/**
 * @function onChangeDates
 * @description Function handles change in start and end date of custom date range.
 * @param dateString is an array whose first element is start date and second element is end date.
 * @param setDateFilter callback function for setting up the date range for custom ranges.
 */
export const onChangeDates = (
  dateString: [string, string],
  setDateFilter: (val: DateRangeFilter) => void
) => {
  setDateFilter({
    option: DATE_RANGES.CUSTOM_RANGE,
    startDate: dateString[0],
    endDate: dateString[1],
  });
};

/**
 * @function displayRender
 * @description Function to display selected dates (if date range is custom) or date range type.
 * @param dateRangeFilter is an object containing the option selected and the date range.
 * @return Returns label as is if date range is not custom. Returns the dates selected if date range is custom in a string form
 */
export const displayRender = (dateRangeFilter: DateRangeFilter) => {
  if (dateRangeFilter.option === DATE_RANGES.CUSTOM_RANGE) {
    return `${moment(dateRangeFilter.startDate).format(DATE_FORMAT)} - ${moment(
      dateRangeFilter.endDate
    ).format(DATE_FORMAT)}`;
  }
  return (
    DATE_RANGE_OPTIONS.find((item) => item.key === dateRangeFilter.option)
      ?.title ?? ''
  );
};

/**
 * @function getClustersKeyValueStructfilters
 * @description Function to construct key value struct filters for clusters
 * @param clusters List of clusters selected
 * @return Returns list of object containing key value struct filters
 */
export const getClustersKeyValueStructfilters = (clusters: string[]) => {
  return clusters.map((cluster) => ({
    arrayName: QUERY_FIELDS.LABELS,
    key: QUERY_VALUES.GOOG_K8S_CLUSTER_NAME,
    value: cluster,
    conjunctToNextFilter: CONJUNCTIONS.OR,
  }));
};

/**
 * @function getNamespacesKeyValueStructfilters
 * @description Function to construct key value struct filters for namespaces
 * @param namespaces List of namespaces selected
 * @return Returns list of object containing key value struct filters
 */
export const getNamespacesKeyValueStructfilters = (namespaces: string[]) => {
  return namespaces.map((namespace) => ({
    arrayName: QUERY_FIELDS.LABELS,
    key: QUERY_VALUES.K8S_NAMESPACE,
    value: namespace,
    conjunctToNextFilter: CONJUNCTIONS.OR,
  }));
};

/**
 * @function getApplicationDetailsTableDataForGCP
 * @description Function to get application details table data for GCP
 * @param response combined response data
 * @return application details table data
 */
export const getApplicationDetailsTableDataForGCP = (response: any[]) => {
  const tableObj: Record<string, ApplicationDetailsDataType> = {};

  response.forEach((item: any) => {
    const name = item.name;
    const namespace = item.namespace;
    const field = name + namespace;
    if (!tableObj[field]) {
      tableObj[field] = {
        name,
        namespace: namespace,
        cpuUtilisation: 0,
        memoryUtilisation: 0,
        time: '',
        pods: 0,
        costAfterCredit: 0,
      };
    }
    if (item?.cpuUtilisation) {
      tableObj[field].cpuUtilisation = item.cpuUtilisation;
    }
    if (item?.memoryUtilisation) {
      tableObj[field].memoryUtilisation = item.memoryUtilisation;
    }
    if (item?.time) {
      tableObj[field].time = item.time;
    }
    if (item?.pods) {
      tableObj[field].pods = item.pods;
    }
    if (item?.namespace) {
      tableObj[field].namespace = item.namespace;
    }
    if (item?.costAfterCredit) {
      tableObj[field].costAfterCredit = item.costAfterCredit;
    }
  });

  return [...Object.values(tableObj)];
};

/**
 * @function getAWSApplicationDetailsTableData
 * @description Function to get application details table data for AWS
 * @param response combined response data
 * @return application details table data
 */
export const getAWSApplicationDetailsTableData = (response: any[]) => {
  const tableObj: any = {};
  response.forEach((item) => {
    const name = item.name;
    const namespace = item.namespace;
    const time = item.time;

    if (name && namespace) {
      const field = name + namespace;
      if (!tableObj[field]) {
        tableObj[field] = {
          name,
          namespace,
          time,
          cpuUtilisation: 0,
          memoryUtilisation: 0,
          pods: 0,
          costAfterCredit: 0,
        };
      }

      if (parseFloat(item?.cpuUtilisation) > 0) {
        tableObj[field].cpuUtilisation = item?.cpuUtilisation;
      }
      if (parseFloat(item?.memoryUtilisation) > 0) {
        tableObj[field].memoryUtilisation = item?.memoryUtilisation;
      }
      if (parseFloat(item?.pods) > 0) {
        tableObj[field].pods = Number(item?.pods);
      }
      if (parseFloat(item?.cost) > 0) {
        tableObj[field].costAfterCredit = item?.cost;
      }
    }
  });

  return [...Object.values(tableObj)];
};

/**
 * @function getTotalCpuPodCount
 * @description Function to get POD count data
 * @param response combined response data
 * @param getTimeFormat function to format the time
 * @return POD count chart data
 */
export const getTotalCpuPodCount = (
  response: any[],
  getTimeFormat: (timestamp: any) => string
) => {
  const totalPODCount: any[] = [];

  response.forEach((item: any) => {
    const namespace = item.namespace;
    if (namespace) {
      const existingEntry = totalPODCount.find(
        (entry) =>
          entry.namespace === namespace &&
          entry.time === getTimeFormat(item.TimeStamp || item.time)
      );

      if (existingEntry) {
        existingEntry.totalPodsForNamespace +=
          parseFloat(item.PodCount || item.pods) ?? 0;
      } else {
        totalPODCount.push({
          namespace: namespace,
          totalPodsForNamespace: parseFloat(item.PodCount || item.pods) ?? 0,
          time: getTimeFormat(item.TimeStamp || item.time),
        });
      }
    }
  });

  return totalPODCount;
};
