import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { sum } from 'lodash';

import { selectDashboard } from 'redux/dashboardSlice';
import NewComparisonCard from 'components/NewComparisonCard';
import ControlComponent from 'components/DashboardControl';
import DropdownCheckbox from 'components/DropdownCheckbox';
import DashboardGraphContainer from 'components/DashboardGraphContainer';
import ColumnChartWithTable from 'components/ColumnChartWithTable';
import LineChartWithTable from 'components/LineChartWithTable';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import TagFilters from 'components/TagFilters';
import { PROVIDER } from 'constants/cloudProviders';
import { INPUT_SIZE } from 'constants/appearance';
import { QUARTER_COST_SUMMARY } from 'constants/graphLabels';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { CHART_TYPES, GRAPH_OPTIONS } from 'constants/graphConfig';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { ChartCriteria, ChartFilterType } from 'constants/dashboard';
import { ComparisonListType, GraphRowContentType } from 'types/dashboard';
import {
  MonthlyCostType,
  CostByServiceType,
  MonthlyCostByServiceType,
  ColouredCostByRegionType,
  ColouredCostByServiceType,
  ColouredCostByResourceType,
  CostByNameType,
  TypeAndNameByCost,
} from 'types/dataTypes';
import { getChartData } from 'utils/services';
import {
  addZeroMarginClass,
  generateGraphColors,
  getAWSTagFiltersData,
  getPercentageDifference,
  getPreviousMonthDayAndYear,
  getQueryFieldByDataSource,
  getTagFilterData,
  isDashboardWithStaticData,
  removeZeroMarginClass,
  transformDataByGroupAndType,
  transformStackedDataByGroupForPptExport,
} from 'utils/dashboardUtils';
import {
  formatDateFieldByProvider,
  getDateFilterAsString,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_HYPHEN_MONTH,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { getDateRangeHandler, getMonthRangeHandler } from 'utils/graphUtils';
import { ForecastRequestBody } from 'pages/OverviewPage/components/ConnectionsDashboard/types';
import { getForecastedCost } from 'pages/OverviewPage/components/ConnectionsDashboard/services';
import { getPdfMetaData } from 'pages/OverviewPage/components/ConnectionsDashboard/utils';

import {
  AwsCloudSpendColumns,
  CHART_CRITERIA_LIST,
  CostByAwsServiceColumns,
  UsageSpendColumns,
} from './constants';
import { calculateTotalCostSum } from 'utils/CostSum';
import CostTrend from './components/CostTrend';
import QuarterCostSummary from './components/QuarterCostSummary';
import CostByAWSService from './components/CostByAWSService';
import UsageSpend from './components/UsageSpend';
import CostByRegion from './components/CostByRegion';
import { SpendDataType } from './types';
import { getCostTrendsData, getAwsSavingsPlanTrendColumns } from './utils';
import {
  CostByRegionColumns,
  TaggedUntaggedSpendColumns,
} from '../../constants';
import {
  getCostTrendsHeading,
  getCostTrendsColumns,
  fetchCloudSpendData,
  fetchTaggedUntaggedSpendData,
  getCostTrendChartLegends,
  getCostTrendXAxisLabel,
} from '../../utils';
import { fetchSavingsPlanTrendData } from '../../../CostRecommendationSummaryDashboard/utils';
import SpendProfileChartWrapper from '../SpendProfileChartWrapper';
import { SpendProfileDataType } from '../SpendProfileChartWrapper/types';
import { getSpendProfilePptContent } from '../SpendProfileChartWrapper/utils';

const AWSCostSummaryDashboard = () => {
  const { t } = useTranslation();
  const {
    selectedDashboard,
    selectedConnection,
    tagsFilters,
    allAvailableTags,
    selectedDashboardView,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [chartCriteriaFilters, setChartCriteriaFilters] = useState<string[]>(
    CHART_CRITERIA_LIST.map((item) => item.value)
  );

  const [costComparisonList, setCostComparisonList] = useState<
    ComparisonListType[]
  >([]);

  //States for comparison cards
  const [currentYearCost, setCurrentYearCost] = useState(0);
  const [currentYearCostRequestStatus, setCurrentYearCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousYearCost, setPreviousYearCost] = useState(0);
  const [previousYearCostRequestStatus, setPreviousYearCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [currentQuarterCost, setCurrentQuarterCost] = useState(0);
  const [currentQuarterCostRequestStatus, setCurrentQuarterCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousQuarterCost, setPreviousQuarterCost] = useState(0);
  const [
    previousQuarterCostRequestStatus,
    setPreviousQuarterCostRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [currentMonthCost, setCurrentMonthCost] = useState(0);
  const [currentMonthCostRequestStatus, setCurrentMonthCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousMonthCost, setPreviousMonthCost] = useState(0);
  const [previousMonthCostRequestStatus, setPreviousMonthCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousSecondMonthCost, setPreviousSecondMonthCost] = useState(0);
  const [
    previousSecondMonthCostRequestStatus,
    setPreviousSecondMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  // ML forecasted cost
  const [nextMonthForeCastedCost, setNextMonthForeCastedCost] = useState(0);
  const [
    nextMonthForecastedCostReqStatus,
    setNextMonthForecastedCostReqStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [foreCastedCost, setForeCastedCost] = useState<MonthlyCostType[]>([]);
  const [forecastErrorMessage, setForecastErrorMessage] = useState('');
  const [foreCastedCostRequestStatus, setForeCastedCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [forecastDateRange, setForecastDateRange] = useState([
    moment().format(HYPHEN_DATE_FORMAT),
    moment().endOf('month').format(HYPHEN_DATE_FORMAT),
  ]);

  //State for Cost Trend Data
  const [costTrendData, setCostTrendData] = useState<MonthlyCostType[]>([]);
  const [costTrendRequestStatus, setCostTrendRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [costTrendStartMonth, setCostTrendStartMonth] = useState(
    moment().startOf('month').subtract(12, 'month').format(HYPHEN_DATE_FORMAT)
  );
  const [costTrendEndMonth, setCostTrendEndMonth] = useState(
    moment().format(HYPHEN_DATE_FORMAT)
  );
  const [selectedTrendMonth, setSelectedTrendMonth] = useState('');
  const [selectedTrendService, setSelectedTrendService] = useState('');
  const [monthWiseServiceData, setMonthWiseServiceData] = useState<{
    [key: string]: ColouredCostByServiceType[];
  }>({});
  const [monthServiceResourceData, setMonthServiceResourceData] = useState<{
    [key: string]: ColouredCostByResourceType[];
  }>({});
  const [costTrendSliderValue, setCostTrendSliderValue] = useState<{
    x: number;
    y: number;
  }>();

  // States for Cloud spend
  const [cloudSpendData, setCloudSpendData] = useState<CostByNameType[]>([]);
  const [selectedCloudSpendData, setSelectedCloudSpendData] = useState<
    CostByNameType[]
  >([]);
  const [cloudSpendStartDate, setCloudSpendStartDate] = useState<string>(
    getPreviousMonthDayAndYear(12)
  );
  const [cloudSpendEndDate, setCloudSpendEndDate] = useState<string>(
    getPreviousMonthDayAndYear(0)
  );
  const [cloudSpendRequestStatus, setCloudSpendRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [isCloudSpendTableView, setIsCloudSpendTableView] = useState(false);

  // States for Tagged UnTagged Spend
  const [taggedUntaggedSpendData, setTaggedUntaggedSpendData] = useState<
    TypeAndNameByCost[]
  >([]);
  const [taggedUntaggedTableData, setTaggedUntaggedTableData] = useState<any[]>(
    []
  );
  const [taggedUntaggedSpendStartMonth, setTaggedUntaggedSpendStartMonth] =
    useState(
      moment().startOf('month').subtract(12, 'month').format(HYPHEN_DATE_FORMAT)
    );
  const [taggedUntaggedSpendEndMonth, setTaggedUntaggedSpendEndMonth] =
    useState(moment().format(HYPHEN_DATE_FORMAT));
  const [
    taggedUntaggedSpendRequestStatus,
    setTaggedUntaggedSpendRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [isTaggedUntaggedSpendTableView, setIsTaggedUntaggedSpendTableView] =
    useState(false);

  // Savings plan trend
  const [savingsPlanTrendData, setSavingsPlanTrendData] = useState<
    TypeAndNameByCost[]
  >([]);
  const [savingsPlanTrendTableData, setSavingsPlanTrendTableData] = useState<
    any[]
  >([]);
  const [savingsPlanTrendRequestStatus, setSavingsPlanTrendRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [savingsPlanTrendTableView, setSavingsPlanTrendTableView] =
    useState(false);
  const [savingsPlanTrendStartMonth, setSavingsPlanTrendStartMonth] = useState(
    moment().startOf('month').subtract(6, 'month').format(HYPHEN_DATE_FORMAT)
  );
  const [savingsPlanTrendEndMonth, setSavingsPlanTrendEndMonth] = useState(
    moment().format(HYPHEN_DATE_FORMAT)
  );

  //State for Cost by Service Data
  const [costByServiceData, setCostByServiceData] = useState<
    CostByServiceType[]
  >([]);
  const [selectedCostByServiceData, setSelectedCostByServiceData] = useState<
    CostByServiceType[]
  >([]);
  const [serviceGraphStartDate, setServiceGraphStartDate] = useState<string>(
    getPreviousMonthDayAndYear(12)
  );
  const [serviceGraphEndDate, setServiceGraphEndDate] = useState<string>(
    getPreviousMonthDayAndYear(0)
  );
  const [costByServiceRequestStatus, setCostByServiceRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  //Spend Data States
  const [spendData, setSpendData] = useState<SpendDataType[]>([]);
  const [spendDataRequestStatus, setSpendDataRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [usageSpendStartDate, setUsageSpendStartDate] = useState(
    moment().subtract(1, 'year').startOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [usageSpendEndDate, setUsageSpendEndDate] = useState(
    moment().startOf('month').format(HYPHEN_DATE_FORMAT)
  );

  //Cost By Region Data
  const [costByRegionData, setCostByRegionData] = useState<
    ColouredCostByRegionType[]
  >([]);
  const [costByRegionDataRequestStatus, setCostByRegionDataRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [costByRegionStartDate, setCostByRegionStartDate] = useState(
    moment().subtract(1, 'year').startOf('month').format(HYPHEN_DATE_FORMAT)
  );
  const [costByRegionEndDate, setCostByRegionEndDate] = useState(
    moment().startOf('month').format(HYPHEN_DATE_FORMAT)
  );

  // States for Application Profile Spend
  const [applicationProfileSpendData, setApplicationProfileSpendData] =
    useState<SpendProfileDataType[]>([]);
  const [
    applicationProfileSpendReqStatus,
    setApplicationProfileSpendReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [spendProfileStartMonth, setSpendProfileStartMonth] = useState(
    moment().startOf('month').subtract(12, 'month').format(HYPHEN_DATE_FORMAT)
  );
  const [spendProfileEndMonth, setSpendProfileEndMonth] = useState(
    moment().format(HYPHEN_DATE_FORMAT)
  );

  // Table states
  const [isCostTrendTableView, setIsCostTrendTableView] = useState(false);
  const [isCostByServiceTableView, setIsCostByServiceTableView] =
    useState(false);

  useEffect(() => {
    addZeroMarginClass('dashboard-view');

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    setCurrentYearCost(0);
    setPreviousYearCost(0);
    setCurrentQuarterCost(0);
    setPreviousQuarterCost(0);
    setCurrentMonthCost(0);
    setPreviousMonthCost(0);
    setPreviousSecondMonthCost(0);
    setNextMonthForeCastedCost(0);
    setCloudSpendData([]);
    setCostByServiceData([]);

    // API calls
    getCostComparisonCardsData();

    if (!isDashboardWithStaticData(selectedDashboard)) {
      getNextMonthForecastCost();
    }
  }, [selectedDashboard, tagsFilters]);

  useEffect(() => {
    setForeCastedCost([]);

    if (!isDashboardWithStaticData(selectedDashboard)) {
      getForecastCost();
    }
  }, [selectedDashboard, forecastDateRange]);

  useEffect(() => {
    setCostTrendData([]);
    getCostTrendData();
  }, [selectedDashboard, costTrendStartMonth, costTrendEndMonth, tagsFilters]);

  useEffect(() => {
    setCostTrendSliderValue(undefined);
    if (selectedTrendMonth && !monthWiseServiceData[selectedTrendMonth]) {
      getCostByServiceForTrends();
    }
  }, [selectedTrendMonth, tagsFilters]);

  useEffect(() => {
    setCostTrendSliderValue(undefined);
    if (
      selectedTrendMonth &&
      selectedTrendService &&
      !monthServiceResourceData[`${selectedTrendMonth}${selectedTrendService}`]
    ) {
      getCostByResourceForTrends();
    }
  }, [selectedTrendMonth, selectedTrendService, tagsFilters]);

  useEffect(() => {
    fetchCloudSpendData(
      setCloudSpendRequestStatus,
      selectedDashboard!,
      selectedConnection!,
      {
        startDate: cloudSpendStartDate,
        endDate: cloudSpendEndDate,
      },
      tagsFilters,
      selectedDashboardView,
      {
        setData: setCloudSpendData,
        setSelectedData: setSelectedCloudSpendData,
      }
    );
  }, [selectedDashboard, cloudSpendStartDate, cloudSpendEndDate, tagsFilters]);

  useEffect(() => {
    if (isDashboardWithStaticData(selectedDashboard)) {
      setTaggedUntaggedSpendData([]);
      setTaggedUntaggedTableData([]);
      setTaggedUntaggedSpendRequestStatus(REQUEST_STATUS.SUCCESS);
      return;
    }

    const selectedTagsForCurrentView = getTagFilterData(
      tagsFilters,
      selectedDashboard!.id,
      selectedDashboardView
    );
    if (allAvailableTags.length > 0 || selectedTagsForCurrentView.length > 0) {
      fetchTaggedUntaggedSpendData(
        setTaggedUntaggedSpendRequestStatus,
        {
          startMonth: taggedUntaggedSpendStartMonth,
          endMonth: taggedUntaggedSpendEndMonth,
        },
        {
          setData: setTaggedUntaggedSpendData,
          setTableData: setTaggedUntaggedTableData,
        },
        selectedDashboard!,
        selectedConnection!,
        tagsFilters,
        selectedDashboardView,
        allAvailableTags
      );
    }
  }, [
    JSON.stringify(allAvailableTags.map((tag) => tag.key)),
    selectedDashboard,
    taggedUntaggedSpendStartMonth,
    taggedUntaggedSpendEndMonth,
    tagsFilters,
  ]);

  useEffect(() => {
    if (!isDashboardWithStaticData(selectedDashboard)) {
      fetchSavingsPlanTrendData(
        setSavingsPlanTrendRequestStatus,
        selectedDashboard!,
        selectedConnection!,
        savingsPlanTrendStartMonth,
        savingsPlanTrendEndMonth,
        setSavingsPlanTrendData
      );
    }
  }, [selectedDashboard, savingsPlanTrendStartMonth, savingsPlanTrendEndMonth]);

  useEffect(() => {
    setSavingsPlanTrendTableData(
      transformDataByGroupAndType(savingsPlanTrendData, 'type', 'cost', 'name')
    );
  }, [savingsPlanTrendData]);

  useEffect(() => {
    setSpendData([]);

    if (!isDashboardWithStaticData(selectedDashboard)) {
      getUsageSpendData();
    }
  }, [selectedDashboard, usageSpendStartDate, usageSpendEndDate, tagsFilters]);

  useEffect(() => {
    setCostByRegionData([]);
    getCostByRegionData();
  }, [
    selectedDashboard,
    costByRegionStartDate,
    costByRegionEndDate,
    tagsFilters,
  ]);

  useEffect(() => {
    getAWSCostByServiceData();
  }, [serviceGraphStartDate, serviceGraphEndDate, tagsFilters]);

  useEffect(() => {
    setSelectedCostByServiceData(costByServiceData.slice(0, 10));
  }, [costByServiceData]);

  useEffect(() => {
    const costComparisonList: ComparisonListType[] = [
      {
        value: currentYearCost,
        heading: t('costComparisonCard.ytdCosts'),
        comparisonValue: getPercentageDifference(
          currentYearCost,
          previousYearCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousYear'),
        requestStatus: [
          currentYearCostRequestStatus,
          previousYearCostRequestStatus,
        ],
      },
      {
        value: currentQuarterCost,
        heading: t('costComparisonCard.qtdCosts'),
        comparisonValue: getPercentageDifference(
          currentQuarterCost,
          previousQuarterCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousQuarter'),
        requestStatus: [
          currentQuarterCostRequestStatus,
          previousQuarterCostRequestStatus,
        ],
      },
      {
        value: currentMonthCost,
        heading: t('costComparisonCard.currentMonthCost'),
        comparisonValue: getPercentageDifference(
          currentMonthCost,
          previousMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousMonth'),
        requestStatus: [
          currentMonthCostRequestStatus,
          previousMonthCostRequestStatus,
        ],
      },
      {
        value: previousMonthCost,
        heading: t('costComparisonCard.lastMonthCost'),
        comparisonValue: getPercentageDifference(
          previousMonthCost,
          previousSecondMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromTwoMonthsBack'),
        requestStatus: [
          previousMonthCostRequestStatus,
          previousSecondMonthCostRequestStatus,
        ],
      },
    ];
    if (!isDashboardWithStaticData(selectedDashboard)) {
      costComparisonList.push({
        value: nextMonthForeCastedCost,
        heading: t('costComparisonCard.nextMonthForecastedCost'),
        comparisonValue: getPercentageDifference(
          nextMonthForeCastedCost,
          previousMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousMonth'),
        requestStatus: [
          previousMonthCostRequestStatus,
          nextMonthForecastedCostReqStatus,
        ],
        errorMessage: forecastErrorMessage,
      });
    }
    setCostComparisonList(costComparisonList);
  }, [
    currentYearCost,
    previousYearCost,
    currentQuarterCost,
    previousQuarterCost,
    currentMonthCost,
    previousMonthCost,
    previousSecondMonthCost,
    currentYearCostRequestStatus,
    previousYearCostRequestStatus,
    currentQuarterCostRequestStatus,
    previousQuarterCostRequestStatus,
    currentMonthCostRequestStatus,
    previousMonthCostRequestStatus,
    previousSecondMonthCostRequestStatus,
    nextMonthForecastedCostReqStatus,
    forecastErrorMessage,
  ]);

  const onChangeCostTrendDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    const startMonth = moment(dateString[0], MONTH_YEAR_FORMAT).format(
      HYPHEN_DATE_FORMAT
    );
    const endMonth = moment(dateString[1], MONTH_YEAR_FORMAT).isSameOrAfter(
      moment(),
      'months'
    )
      ? moment().format(HYPHEN_DATE_FORMAT)
      : moment(dateString[1], MONTH_YEAR_FORMAT)
          .endOf('month')
          .format(HYPHEN_DATE_FORMAT);
    const forecastStartMonth = moment(
      dateString[0],
      MONTH_YEAR_FORMAT
    ).isSameOrBefore(moment(), 'months')
      ? moment().format(HYPHEN_DATE_FORMAT)
      : moment(dateString[0], MONTH_YEAR_FORMAT)
          .startOf('month')
          .format(HYPHEN_DATE_FORMAT);
    const forecastEndMonth = moment(dateString[1], MONTH_YEAR_FORMAT)
      .endOf('month')
      .format(HYPHEN_DATE_FORMAT);
    setCostTrendStartMonth(startMonth);
    setCostTrendEndMonth(endMonth);
    setForecastDateRange([forecastStartMonth, forecastEndMonth]);
  };

  const getAWSCostByPeriodData = (
    fromMonth: string,
    toMonth: string,
    setData: (val: number) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                fromMonth,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                toMonth,
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard!.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        setData(Number(Number(res?.data[0]?.cost).toFixed(2)));
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  const getCostComparisonCardsData = async () => {
    //Current Year Cost
    getAWSCostByPeriodData(
      moment().startOf('year').format(HYPHEN_DATE_FORMAT),
      moment().endOf('month').format(HYPHEN_DATE_FORMAT),
      setCurrentYearCost,
      setCurrentYearCostRequestStatus
    );

    // Previous year Cost
    getAWSCostByPeriodData(
      moment().subtract(1, 'year').startOf('year').format(HYPHEN_DATE_FORMAT),
      moment().subtract(1, 'year').endOf('year').format(HYPHEN_DATE_FORMAT),
      setPreviousYearCost,
      setPreviousYearCostRequestStatus
    );

    // Current Quarter Cost
    getAWSCostByPeriodData(
      moment()
        .quarter(moment().quarter())
        .startOf('quarter')
        .format(HYPHEN_DATE_FORMAT),
      moment().startOf('month').format(HYPHEN_DATE_FORMAT),
      setCurrentQuarterCost,
      setCurrentQuarterCostRequestStatus
    );

    // Previous Quarter Cost
    getAWSCostByPeriodData(
      moment()
        .quarter(moment().quarter())
        .startOf('quarter')
        .subtract(1, 'Q')
        .format(HYPHEN_DATE_FORMAT),
      moment()
        .quarter(moment().quarter())
        .startOf('quarter')
        .subtract(1, 'month')
        .format(HYPHEN_DATE_FORMAT),
      setPreviousQuarterCost,
      setPreviousQuarterCostRequestStatus
    );

    // Current Month Cost
    getAWSCostByPeriodData(
      moment().startOf('month').format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
      setCurrentMonthCost,
      setCurrentMonthCostRequestStatus
    );

    // Previous Month Cost
    getAWSCostByPeriodData(
      moment().startOf('month').subtract(1, 'month').format(HYPHEN_DATE_FORMAT),
      moment().startOf('month').subtract(1, 'month').format(HYPHEN_DATE_FORMAT),
      setPreviousMonthCost,
      setPreviousMonthCostRequestStatus
    );

    // Previous 2nd Month Cost
    getAWSCostByPeriodData(
      moment().startOf('month').subtract(2, 'month').format(HYPHEN_DATE_FORMAT),
      moment().startOf('month').subtract(2, 'month').format(HYPHEN_DATE_FORMAT),
      setPreviousSecondMonthCost,
      setPreviousSecondMonthCostRequestStatus
    );
  };

  const getCostTrendData = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            isStaticData
              ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
              : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costTrendStartMonth,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costTrendEndMonth,
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard!.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        let labels: string[] = getMonthYearShortList(
          costTrendStartMonth,
          costTrendEndMonth
        );
        const data: MonthlyCostType[] = [];
        labels.forEach((label) => {
          let index = res?.data.findIndex(
            (item: any) =>
              moment(item.month, YEAR_HYPHEN_MONTH).format(MONTH_YEAR_SHORT) ===
              label
          );
          if (index !== -1) {
            data.push({
              month: label,
              cost: Number(res.data[index].cost),
            });
          } else {
            data.push({
              month: label,
              cost: 0,
            });
          }
        });
        setCostTrendData(data);
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendRequestStatus);
      });
  };

  const parseAndSetForecastErrorMessage = (errorMessage: string) => {
    try {
      const isPermissionDenied =
        JSON.parse(errorMessage ?? null)?.error?.status === 'PERMISSION_DENIED';
      setForecastErrorMessage(
        isPermissionDenied ? t('costComparisonCard.permissionDenied') : ''
      );
    } catch {
      setForecastErrorMessage(errorMessage);
    }
  };

  const getNextMonthForecastCost = () => {
    setNextMonthForecastedCostReqStatus(REQUEST_STATUS.PROCESSING);
    const requestBody: ForecastRequestBody = {
      connectorId: selectedDashboard!.connectorId,
      start: moment()
        .add(1, 'month')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      end: moment().add(1, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
    };

    getForecastedCost(requestBody)
      .then((res: any) => {
        setForecastErrorMessage('');
        if (res?.status === 200) {
          const forecastCost =
            JSON.parse(res?.data?.responseData ?? null)?.predictions ?? [];
          setNextMonthForeCastedCost(sum(forecastCost));
          setNextMonthForecastedCostReqStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setForeCastedCost([]);
          setNextMonthForecastedCostReqStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setNextMonthForecastedCostReqStatus);
        setForeCastedCost([]);
        parseAndSetForecastErrorMessage(e?.response?.data?.message);
      });
  };

  const getForecastCost = () => {
    setForeCastedCostRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody: ForecastRequestBody = {
      connectorId: selectedDashboard!.connectorId,
      start: forecastDateRange[0],
      end: forecastDateRange[1],
    };

    getForecastedCost(requestBody)
      .then((res: any) => {
        setForecastErrorMessage('');
        if (res?.status === 200) {
          const forecastCost =
            JSON.parse(res?.data?.responseData ?? null)?.predictions ?? [];
          const monthWiseForecast: MonthlyCostType[] = [];
          const months = getMonthYearShortList(
            moment(forecastDateRange[0]).format(YEAR_MONTH_WITHOUT_SEPARATOR),
            moment(forecastDateRange[1]).format(YEAR_MONTH_WITHOUT_SEPARATOR)
          );

          let index = 0;
          months.forEach((month) => {
            let days = moment(month, MONTH_YEAR_SHORT).daysInMonth();
            if (month === moment().format(MONTH_YEAR_SHORT)) {
              days = moment()
                .endOf('month')
                .diff(moment().subtract(1, 'days'), 'days');
            }

            monthWiseForecast.push({
              month: month,
              cost: sum(forecastCost.slice(index, index + days)),
            });
            index += days;
          });
          setForeCastedCost(monthWiseForecast);
          setForeCastedCostRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setForeCastedCost([]);
          setForeCastedCostRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setForeCastedCostRequestStatus);
        setForeCastedCost([]);
        parseAndSetForecastErrorMessage(e?.response?.data?.message);
      });
  };

  /**
   * @function getCostByServiceForTrends
   * @description Function to fetch the service details for the selected month.
   */
  const getCostByServiceForTrends = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'service',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PRODUCT_SERVICENAME,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['service'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment(selectedTrendMonth, MONTH_YEAR_SHORT).format(
                  HYPHEN_DATE_FORMAT
                ),
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment(selectedTrendMonth, MONTH_YEAR_SHORT)
                  .add(1, 'month')
                  .format(HYPHEN_DATE_FORMAT),
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard!.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data = res?.data?.filter((item: any) => item.cost > 0) || [];
        const colors = generateGraphColors(data.length);
        setMonthWiseServiceData({
          ...monthWiseServiceData,
          [selectedTrendMonth]:
            data.map((item: any, index: number) => ({
              ...item,
              service: item.service ?? 'Others',
              cost: Number(item.cost ?? 0),
              color: colors[index],
            })) || [],
        });
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendRequestStatus);
      });
  };

  /**
   * @function getCostByResourceForTrends
   * @description Function to fetch the resource details for the selected month and resource.
   */
  const getCostByResourceForTrends = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'resource',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_USAGE_TYPE,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['resource'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment(selectedTrendMonth, MONTH_YEAR_SHORT).format(
                  HYPHEN_DATE_FORMAT
                ),
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment(selectedTrendMonth, MONTH_YEAR_SHORT)
                  .add(1, 'month')
                  .format(HYPHEN_DATE_FORMAT),
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.PRODUCT_SERVICENAME,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.EQUALS,
              value: selectedTrendService,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard!.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data = res?.data?.filter((item: any) => item.cost > 0) || [];
        const colors = generateGraphColors(data.length);
        setMonthServiceResourceData({
          ...monthServiceResourceData,
          [`${selectedTrendMonth}${selectedTrendService}`]:
            res?.data?.map((item: any, index: number) => ({
              ...item,
              resource: item.resource ?? 'Others',
              cost: Number(item.cost ?? 0),
              color: colors[index],
            })) || [],
        });
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendRequestStatus);
      });
  };

  const getAWSCostByServiceData = () => {
    setCostByServiceRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'service',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PRODUCT_SERVICENAME,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['service'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: isStaticData
                ? serviceGraphStartDate
                : "(cast('" + serviceGraphStartDate + "' as timestamp))",
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.LINE_ITEM_USAGE_END_DATE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: isStaticData
                ? serviceGraphEndDate
                : "(cast('" + serviceGraphEndDate + "' as timestamp))",
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard!.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        let costByService: CostByServiceType[] = [];
        res?.data?.forEach((item: MonthlyCostByServiceType) => {
          const existingIndex = costByService?.findIndex(
            (eachItem) => eachItem.service === item?.service
          );

          if (existingIndex >= 0) {
            costByService[existingIndex].cost += Number(item?.cost);
          } else {
            costByService.push({
              service: item?.service,
              cost: Number(item.cost),
            });
          }
        });
        costByService.sort((a, b) => b.cost - a.cost);
        setCostByServiceData(costByService);
        setSelectedCostByServiceData(costByService.slice(0, 10));
        setCostByServiceRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostByServiceRequestStatus);
      });
  };

  const getUsageSpendData = () => {
    setSpendDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'usage_type',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_USAGE_TYPE,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['usage_type'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                usageSpendStartDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                usageSpendEndDate,
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard!.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        setSpendData(res?.data);
        setSpendDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSpendDataRequestStatus);
      });
  };

  const getCostByRegionData = () => {
    setCostByRegionDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'region',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PRODUCT_REGION,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['region'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costByRegionStartDate,
                PROVIDER.AWS,
                isStaticData
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costByRegionEndDate,
                PROVIDER.AWS,
                isStaticData
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.BILL_BILL_TYPE,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: QUERY_VALUES.REFUND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.PRODUCT_REGION,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.NOT_EQUAL,
              value: '',
            },
          ],
        },
        ...getAWSTagFiltersData(
          tagsFilters,
          selectedDashboard!.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const colors = generateGraphColors(res?.data?.length);
        setCostByRegionData(
          res?.data.map((item: any, index: number) => {
            return {
              ...item,
              color: colors[index],
            };
          })
        );
        setCostByRegionDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostByRegionDataRequestStatus);
      });
  };

  /**
   * @function getCostTrendLegendsForPdfExport
   * @description Function to fetch the legends list based on the slider position
   * @returns list of legends with color
   */
  const getCostTrendLegendsForPdfExport = () => {
    const startIndex = costTrendSliderValue
      ? costTrendSliderValue.x * (CostTrendTableData.length - 1)
      : 0;

    const endIndex = costTrendSliderValue
      ? costTrendSliderValue.y * (CostTrendTableData.length - 1) + 1
      : GRAPH_OPTIONS.sliderCountLimit + 1;

    return CostTrendTableData.slice(startIndex, endIndex).map((data: any) => ({
      name: data.month ?? data.service ?? data.resource ?? '',
      color: data.color ?? '',
    }));
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'cost-trend':
        return (
          <CostTrend
            costTrendData={costTrendData}
            forecastedCost={foreCastedCost}
            costTrendRequestStatus={[
              costTrendRequestStatus,
              foreCastedCostRequestStatus,
            ]}
            setIsCostTrendTableView={setIsCostTrendTableView}
            isCostTrendTableView={isCostTrendTableView}
            pdfView={pdfView}
            costTrendStartMonth={costTrendStartMonth}
            costTrendEndMonth={costTrendEndMonth}
            forecastDateRange={forecastDateRange}
            onChangeCostTrendDateRange={onChangeCostTrendDateRange}
            selectedTrendMonth={selectedTrendMonth}
            setSelectedTrendMonth={setSelectedTrendMonth}
            selectedTrendService={selectedTrendService}
            setSelectedTrendService={setSelectedTrendService}
            hasForecastedCost={
              !isDashboardWithStaticData(selectedDashboard) &&
              foreCastedCostRequestStatus === REQUEST_STATUS.SUCCESS &&
              moment(costTrendEndMonth).isSameOrAfter(moment(), 'month')
            }
            serviceData={monthWiseServiceData[selectedTrendMonth] || []}
            resourceData={
              monthServiceResourceData[
                `${selectedTrendMonth}${selectedTrendService}`
              ] || []
            }
            sliderValue={costTrendSliderValue}
            setSliderValue={setCostTrendSliderValue}
          />
        );

      case 'cloud-spend':
        return (
          <ColumnChartWithTable
            graphTitle={t('graphHeadings.cloudSpend')}
            graphName="cloud-spend"
            columns={AwsCloudSpendColumns}
            data={selectedCloudSpendData}
            chartProperties={{
              xTitle: t('accountIds'),
              yTitle: t('costInCurrency', { currencySymbol }),
            }}
            filters={[
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: cloudSpendStartDate,
                  filterEndDate: cloudSpendEndDate,
                  onChangeDateRange: getDateRangeHandler(
                    setCloudSpendStartDate,
                    setCloudSpendEndDate
                  ),
                },
              },
              {
                filterType: ChartFilterType.DROPDOWN,
                filterProperties: {
                  allData: cloudSpendData,
                  selectedData: selectedCloudSpendData,
                  setSelectedData: setSelectedCloudSpendData,
                  valueSuffix: t('accountIds'),
                  fieldName: DROPDOWN_VALUE_FIELDS.NAME,
                },
              },
            ]}
            requestStatus={cloudSpendRequestStatus}
            isTableView={isCloudSpendTableView}
            setIsTableView={setIsCloudSpendTableView}
            pdfView={pdfView}
          />
        );

      case 'tagged-untagged-cost':
        return (
          <ColumnChartWithTable
            graphTitle={t('graphHeadings.taggedUntaggedSpend')}
            graphName="tagged-untagged-cost"
            columns={TaggedUntaggedSpendColumns}
            data={taggedUntaggedSpendData}
            tableData={taggedUntaggedTableData}
            chartProperties={{
              xTitle: t('months'),
              yTitle: t('costInCurrency', { currencySymbol }),
              isStack: true,
            }}
            filters={[
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: taggedUntaggedSpendStartMonth,
                  filterEndDate: taggedUntaggedSpendEndMonth,
                  onChangeDateRange: getMonthRangeHandler(
                    setTaggedUntaggedSpendStartMonth,
                    setTaggedUntaggedSpendEndMonth
                  ),
                  format: MONTH_YEAR_FORMAT,
                  picker: 'month',
                },
              },
            ]}
            requestStatus={taggedUntaggedSpendRequestStatus}
            isTableView={isTaggedUntaggedSpendTableView}
            setIsTableView={setIsTaggedUntaggedSpendTableView}
            pdfView={pdfView}
          />
        );

      case 'cost-by-aws-service':
        return (
          <CostByAWSService
            costByServiceValues={costByServiceData}
            selectedCostByServiceData={selectedCostByServiceData}
            setSelectedCostByServiceData={setSelectedCostByServiceData}
            serviceGraphStartDate={serviceGraphStartDate}
            serviceGraphEndDate={serviceGraphEndDate}
            onChangeServiceDateRange={onChangeServiceDateRange}
            requestStatus={costByServiceRequestStatus}
            setIsCostByServiceTableView={setIsCostByServiceTableView}
            isCostByServiceTableView={isCostByServiceTableView}
            pdfView={pdfView}
          />
        );

      case 'quarter-cost-summary':
        return (
          <QuarterCostSummary
            currentQuarterCost={currentQuarterCost}
            previousQuarterCost={previousQuarterCost}
            requestStatus={[
              currentQuarterCostRequestStatus,
              previousQuarterCostRequestStatus,
            ]}
            pdfView={pdfView}
          />
        );

      case 'usage-spend':
        return (
          <UsageSpend
            spendData={spendData}
            requestStatus={spendDataRequestStatus}
            pdfView={pdfView}
            usageSpendStartDate={usageSpendStartDate}
            usageSpendEndDate={usageSpendEndDate}
            onChangeUsageSpendDateRange={getMonthRangeHandler(
              setUsageSpendStartDate,
              setUsageSpendEndDate
            )}
          />
        );

      case 'cost-by-region':
        return (
          <CostByRegion
            requestStatus={costByRegionDataRequestStatus}
            costByRegionData={costByRegionData}
            pdfView={pdfView}
            costByRegionStartDate={costByRegionStartDate}
            costByRegionEndDate={costByRegionEndDate}
            onChangeCostByRegionDateRange={getMonthRangeHandler(
              setCostByRegionStartDate,
              setCostByRegionEndDate
            )}
          />
        );

      case 'service-spend-profile':
        return (
          <SpendProfileChartWrapper
            data={applicationProfileSpendData}
            setData={setApplicationProfileSpendData}
            requestStatus={applicationProfileSpendReqStatus}
            setRequestStatus={setApplicationProfileSpendReqStatus}
            pdfView={pdfView}
            spendProfileStartMonth={spendProfileStartMonth}
            setSpendProfileStartMonth={setSpendProfileStartMonth}
            spendProfileEndMonth={spendProfileEndMonth}
            setSpendProfileEndMonth={setSpendProfileEndMonth}
          />
        );

      case 'savings-plan-trend':
        return (
          <LineChartWithTable
            graphTitle={t('graphHeadings.savingsPlanTrend')}
            graphName="savings-plan-trend"
            data={savingsPlanTrendData}
            chartProperties={{
              xTitle: t('months'),
              yTitle: t('savingsEffectiveness'),
            }}
            columns={getAwsSavingsPlanTrendColumns(
              getMonthYearShortList(
                savingsPlanTrendStartMonth,
                savingsPlanTrendEndMonth
              )
            )}
            requestStatus={savingsPlanTrendRequestStatus}
            tableData={savingsPlanTrendTableData}
            isTableView={savingsPlanTrendTableView}
            setIsTableView={setSavingsPlanTrendTableView}
            pdfView={pdfView}
            isFullWidth={false}
            suffixSymbol="%"
            filters={[
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: savingsPlanTrendStartMonth,
                  filterEndDate: savingsPlanTrendEndMonth,
                  onChangeDateRange: getMonthRangeHandler(
                    setSavingsPlanTrendStartMonth,
                    setSavingsPlanTrendEndMonth
                  ),
                  format: MONTH_YEAR_FORMAT,
                  picker: 'month',
                },
              },
            ]}
          />
        );
    }
  };

  const onChangeServiceDateRange = (
    dates: RangeValue<Moment>,
    _dateString: [string, string]
  ) => {
    if (!dates?.[0] || !dates?.[1]) {
      return;
    }

    setServiceGraphStartDate(dates[0].format(HYPHEN_DATE_FORMAT));
    setServiceGraphEndDate(dates[1].format(HYPHEN_DATE_FORMAT));
  };

  const getCostComparisonCards = () =>
    costComparisonList.map((item, index) => {
      return (
        <NewComparisonCard
          key={item.heading}
          index={index}
          value={item.value}
          heading={item.heading}
          valuePrefix={currencySymbol}
          comparisonValue={item.comparisonValue}
          comparisonFrom={item.comparisonFrom}
          requestStatus={item.requestStatus}
          errorMessage={item.errorMessage}
        />
      );
    });

  const CostTrendTableData = getCostTrendsData(
    selectedTrendMonth,
    selectedTrendService,
    {
      trends: costTrendData,
      service: monthWiseServiceData[selectedTrendMonth] || [],
      resource:
        monthServiceResourceData[
          `${selectedTrendMonth}${selectedTrendService}`
        ] || [],
    }
  );

  const GraphRows: GraphRowContentType = useMemo(
    () => [
      [
        {
          element: getGraphComponent('cost-trend', true),
          contentType: selectedTrendMonth
            ? CHART_TYPES.BAR_CHART
            : CHART_TYPES.BAR_LINE_CHART,
          graphName: 'cost-trend',
          graphHeading: getCostTrendsHeading(
            selectedTrendMonth,
            selectedTrendService
          ),
          criteria: [ChartCriteria.TIME_PERIOD, ChartCriteria.SERVICE],
          chartView: {
            customLegends: getCostTrendChartLegends(
              selectedTrendMonth,
              selectedTrendService
            ),
            xAxisLabel: getCostTrendXAxisLabel(
              selectedTrendMonth,
              selectedTrendService
            ),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getCostTrendsColumns(
              selectedTrendMonth,
              selectedTrendService
            ),
            isTableView: isCostTrendTableView,
            setTableView: setIsCostTrendTableView,
          },
          pdfTableExport: {
            pdfTableData: CostTrendTableData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
            legends: getCostTrendLegendsForPdfExport(),
          },
          excelExport: {
            excelData: CostTrendTableData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              endDate: costTrendEndMonth,
              startDate: costTrendStartMonth,
              month: selectedTrendMonth,
              service: selectedTrendService,
            },
          },
          pptExport: {
            chartHeading: !selectedTrendMonth
              ? `${t('graphHeadings.costTrend')} (${getDateFilterAsString(
                  costTrendStartMonth,
                  costTrendEndMonth
                )})`
              : getCostTrendsHeading(selectedTrendMonth, selectedTrendService),
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: CostTrendTableData.map((spend) => {
                  if ('resource' in spend) {
                    return spend.resource;
                  } else if ('service' in spend) {
                    return spend.service;
                  } else {
                    return spend.month;
                  }
                }),
                values: CostTrendTableData.map(
                  (spend: any) => spend.forecastedCost ?? spend.cost ?? 0
                ),
              },
            ],
          },
        },
      ],
      [
        {
          element: getGraphComponent('cloud-spend', true),
          contentType: CHART_TYPES.BAR_CHART,
          graphName: 'cloud-spend',
          graphHeading: t('graphHeadings.cloudSpend'),
          criteria: [ChartCriteria.ACCOUNT],
          chartView: {
            xAxisLabel: t('projects'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: AwsCloudSpendColumns,
            isTableView: isCloudSpendTableView,
            setTableView: setIsCloudSpendTableView,
          },
          pdfTableExport: {
            pdfTableData: selectedCloudSpendData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
          },
          excelExport: {
            excelData: selectedCloudSpendData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              startDate: cloudSpendStartDate,
              endDate: cloudSpendEndDate,
            },
          },
          pptExport: {
            chartHeading: `${t(
              'graphHeadings.cloudSpend'
            )} (${getDateFilterAsString(
              cloudSpendStartDate,
              cloudSpendEndDate
            )})`,
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: selectedCloudSpendData.map((item) => item.name),
                values: selectedCloudSpendData.map((item) => item.cost),
              },
            ],
          },
        },
      ],
      [
        {
          element: getGraphComponent('service-spend-profile', true),
          contentType: CHART_TYPES.PIE_CHART,
          graphName: 'service-spend-profile',
          graphHeading: t('graphHeadings.serviceSpendProfile'),
          criteria: [ChartCriteria.SPEND_PROFILE],
          pptExport: {
            pptCustomContent: getSpendProfilePptContent(
              applicationProfileSpendData
            ),
          },
        },
      ],
      [
        {
          element: getGraphComponent('tagged-untagged-cost', true),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: 'tagged-untagged-cost',
          graphHeading: t('graphHeadings.taggedUntaggedSpend'),
          display: !isDashboardWithStaticData(selectedDashboard),
          criteria: [ChartCriteria.SPEND_PROFILE],
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: TaggedUntaggedSpendColumns,
            isTableView: isTaggedUntaggedSpendTableView,
            setTableView: setIsTaggedUntaggedSpendTableView,
          },
          pdfTableExport: {
            pdfTableData: taggedUntaggedTableData.map((spend) => ({
              ...spend,
              Tagged: currencySymbol + numberCommaSeparator(spend.Tagged),
              Untagged: currencySymbol + numberCommaSeparator(spend.Untagged),
            })),
          },
          excelExport: {
            excelData: taggedUntaggedTableData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              startDate: taggedUntaggedSpendStartMonth,
              endDate: taggedUntaggedSpendEndMonth,
            },
          },
          pptExport: {
            chartHeading: `${t(
              'graphHeadings.taggedUntaggedSpend'
            )} (${getDateFilterAsString(
              taggedUntaggedSpendStartMonth,
              taggedUntaggedSpendEndMonth
            )})`,
            pptData: transformStackedDataByGroupForPptExport(
              taggedUntaggedSpendData,
              'type',
              'cost',
              'name'
            ),
          },
        },
      ],
      [
        {
          element: getGraphComponent('savings-plan-trend', true),
          graphHeading: t('graphHeadings.savingsPlanTrend'),
          contentType: CHART_TYPES.LINE_CHART,
          graphName: 'savings-plan-trend',
          display: !isDashboardWithStaticData(selectedDashboard),
          criteria: [ChartCriteria.SAVINGS_PLAN],
          colSpanWidth: 12,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('savingsEffectiveness'),
          },
          tableView: {
            columns: getAwsSavingsPlanTrendColumns(
              getMonthYearShortList(
                savingsPlanTrendStartMonth,
                savingsPlanTrendEndMonth
              )
            ),
            isTableView: savingsPlanTrendTableView,
            setTableView: setSavingsPlanTrendTableView,
          },
          pdfTableExport: {
            pdfTableData: savingsPlanTrendTableData,
          },
          excelExport: {
            excelData: savingsPlanTrendTableData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            suffixSymbol: '%',
            chartHeading: `${t(
              'graphHeadings.savingsPlanTrend'
            )} (${getDateFilterAsString(
              savingsPlanTrendStartMonth,
              savingsPlanTrendEndMonth
            )})`,
            pptData: [
              {
                name: t('savingsEffectiveness'),
                labels: savingsPlanTrendData.map((item) => item.name),
                values: savingsPlanTrendData.map((item) => item.cost),
              },
            ],
          },
        },
      ],
      [
        {
          element: getGraphComponent('cost-by-aws-service', true),
          contentType: CHART_TYPES.BAR_CHART,
          graphName: 'cost-by-aws-service',
          graphHeading: t('graphHeadings.costByAwsService'),
          criteria: [ChartCriteria.SERVICE],
          chartView: {
            xAxisLabel: t('services'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: CostByAwsServiceColumns,
            isTableView: isCostByServiceTableView,
            setTableView: setIsCostByServiceTableView,
          },
          pdfTableExport: {
            pdfTableData: selectedCostByServiceData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
          },
          excelExport: {
            excelData: selectedCostByServiceData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              endDate: serviceGraphEndDate,
              startDate: serviceGraphStartDate,
            },
          },
          pptExport: {
            chartHeading: `${t(
              'graphHeadings.costByAwsService'
            )} (${getDateFilterAsString(
              serviceGraphStartDate,
              serviceGraphEndDate
            )})`,
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: selectedCostByServiceData.map((item) => item.service),
                values: selectedCostByServiceData.map((item) => item.cost),
              },
            ],
          },
        },
      ],
      [
        {
          colSpanWidth: 12,
          element: getGraphComponent('quarter-cost-summary', true),
          contentType: CHART_TYPES.HORIZONTAL_BAR_CHART,
          graphName: 'quarter-cost-summary',
          criteria: [ChartCriteria.TIME_PERIOD],
          chartView: {
            customLegends: QUARTER_COST_SUMMARY,
            xAxisLabel: t('costInCurrency', { currencySymbol }),
            yAxisLabel: t('quarters'),
          },
          graphHeading: t('graphHeadings.costByQuarter'),
          pptExport: {
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: QUARTER_COST_SUMMARY,
                values: [currentQuarterCost, previousQuarterCost],
              },
            ],
          },
        },
        {
          colSpanWidth: 12,
          contentType: CHART_TYPES.TABLE,
          graphName: 'usage-spend',
          graphHeading: t('graphHeadings.usageSpend'),
          element: getGraphComponent('usage-spend', true),
          display: !isDashboardWithStaticData(selectedDashboard),
          criteria: [ChartCriteria.SERVICE],
          tableView: {
            columns: UsageSpendColumns,
          },
          pdfTableExport: {
            pdfTableData: spendData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
            tableFooterData: {
              totalTableData: [
                '',
                t('grandTotal'),
                `$ ${calculateTotalCostSum(
                  spendData.map((eachData) => +eachData.cost)
                )}`,
              ],
            },
          },
          excelExport: {
            excelData: spendData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              endDate: usageSpendEndDate,
              startDate: usageSpendStartDate,
            },
          },
          pptExport: {
            pptData: [
              UsageSpendColumns.map((column) => column.title),
              ...spendData.map((eachData, index) => [
                (index + 1).toString(),
                eachData.usage_type,
                `$ ${eachData.cost}`,
              ]),
              [
                '',
                t('grandTotal'),
                `$ ${calculateTotalCostSum(
                  spendData.map((eachData) => +eachData.cost)
                )}`,
              ],
            ],
          },
        },
      ],
      [
        {
          element: getGraphComponent('cost-by-region', true),
          contentType: CHART_TYPES.MAP,
          graphName: 'cost-by-region',
          graphHeading: t('graphHeadings.costByRegion'),
          criteria: [ChartCriteria.REGION],
          tableView: {
            columns: CostByRegionColumns,
            isTableView: false,
          },
          pdfTableExport: {
            pdfTableData: costByRegionData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
            legends: costByRegionData.map((item) => ({
              color: item.color,
              name: `${item.region || 'Others'} - ${
                currencySymbol + numberCommaSeparator(item.cost)
              }`,
            })),
          },
          excelExport: {
            excelData: costByRegionData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              endDate: costByRegionEndDate,
              startDate: costByRegionStartDate,
            },
          },
          pptExport: {
            chartHeading: `${t(
              'graphHeadings.costByRegion'
            )} (${getDateFilterAsString(
              costByRegionStartDate,
              costByRegionEndDate
            )})`,
            pptData: [
              CostByRegionColumns.map((column) => column.title),
              ...costByRegionData.map((eachData, index) => [
                (index + 1).toString(),
                eachData.region,
                eachData.cost.toString(),
              ]),
            ],
          },
        },
      ],
    ],
    [
      applicationProfileSpendData,
      cloudSpendEndDate,
      cloudSpendStartDate,
      costByRegionData,
      costByRegionEndDate,
      costByRegionStartDate,
      costTrendEndMonth,
      costTrendStartMonth,
      currentQuarterCost,
      isCloudSpendTableView,
      isCostByServiceTableView,
      isCostTrendTableView,
      isTaggedUntaggedSpendTableView,
      previousQuarterCost,
      savingsPlanTrendData,
      savingsPlanTrendEndMonth,
      savingsPlanTrendStartMonth,
      savingsPlanTrendTableData,
      savingsPlanTrendTableView,
      selectedCloudSpendData,
      selectedCostByServiceData,
      selectedTrendMonth,
      selectedTrendService,
      serviceGraphEndDate,
      serviceGraphStartDate,
      spendData,
      taggedUntaggedSpendData,
      taggedUntaggedSpendEndMonth,
      taggedUntaggedSpendStartMonth,
      taggedUntaggedTableData,
      usageSpendEndDate,
      usageSpendStartDate,
    ]
  );

  return (
    <>
      <ControlComponent
        filters={[
          {
            title: t('listByDimensions'),
            filter: (
              <DropdownCheckbox
                itemOptions={CHART_CRITERIA_LIST}
                selectedItems={chartCriteriaFilters}
                setSelectedItems={setChartCriteriaFilters}
                designVersion2
                size={INPUT_SIZE.SMALL}
              />
            ),
            minimizedText:
              chartCriteriaFilters.length === CHART_CRITERIA_LIST.length
                ? t('all')
                : CHART_CRITERIA_LIST.filter((each) =>
                    chartCriteriaFilters.includes(each.value)
                  )
                    .map((each) => each.title)
                    .join(', '),
            onClear: () => setChartCriteriaFilters([]),
          },
        ]}
      />
      <div className="aws-cost-summary inner-dashboard-content flex flex-column flex-gap-24">
        <TagFilters />
        <DashboardGraphContainer
          getGraphComponent={getGraphComponent}
          chartCriteriaFilters={chartCriteriaFilters}
          rows={GraphRows}
          pdfMetaData={getPdfMetaData(
            t('dashNav.costSummary'),
            selectedDashboard!,
            selectedConnection!
          )}
          costCardComponents={getCostComparisonCards()}
        />
      </div>
    </>
  );
};

export default AWSCostSummaryDashboard;
