import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';

import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import GraphHeader from 'components/GraphHeader';
import ColumnChart from 'components/ColumnChart';
import { generateGraphColors } from 'utils/dashboardUtils';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { CostAndCreditBreakdownType } from 'types/dataTypes';

import { getGCPCostAndCreditBreakdownExcelData } from '../../utils/exportToExcel';

type CostAndCreditBreakdownProps = {
  isCostAndCreditBreakdownTableView: boolean;
  setCostAndCreditBreakdownTableView: (value: boolean) => void;
  requestStatus: string;
  costCreditBreakdownData: CostAndCreditBreakdownType[];
  pdfView: boolean;
  costAndCreditStartMonth: string;
  costAndCreditEndMonth: string;
  onChangeCostAndCreditDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

export const CostAndCreditBreakdown = ({
  isCostAndCreditBreakdownTableView,
  setCostAndCreditBreakdownTableView,
  requestStatus,
  costCreditBreakdownData,
  pdfView,
  costAndCreditStartMonth,
  costAndCreditEndMonth,
  onChangeCostAndCreditDateRange,
}: CostAndCreditBreakdownProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const CostAndCreditBreakdownColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('gcpCostAndCreditBreakdown.month'),
      dataIndex: 'month',
      key: 'month',
      width: 150,
    },
    {
      title: t('gcpCostAndCreditBreakdown.originalCost'),
      dataIndex: 'cost',
      key: 'cost',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text || 0),
    },
    {
      title: t('gcpCostAndCreditBreakdown.costAfterCredit'),
      dataIndex: 'costAfterCredit',
      key: 'costAfterCredit',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text || 0),
    },
    {
      title: t('gcpCostAndCreditBreakdown.credit'),
      dataIndex: 'credit',
      key: 'credit',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text || 0),
    },
  ];

  const getGraphData = () => {
    const graphData: any[] = [];
    costCreditBreakdownData.forEach((value) => {
      graphData.push(
        ...[
          {
            month: value.month,
            category: t('gcpCostAndCreditBreakdown.originalCost'),
            value: value.cost,
            groupingField: 'column1',
          },
          {
            month: value.month,
            category: t('gcpCostAndCreditBreakdown.credit'),
            value: value.credit,
            groupingField: 'column2',
          },
          {
            month: value.month,
            category: t('gcpCostAndCreditBreakdown.costAfterCredit'),
            value: value.costAfterCredit,
            groupingField: 'column2',
          },
        ]
      );
    });
    return graphData;
  };

  const getComponent = () =>
    isCostAndCreditBreakdownTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={costCreditBreakdownData.map((value, index) => ({
            ...value,
            key: index,
          }))}
          columns={CostAndCreditBreakdownColumns}
          scroll={{ y: 240 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={getGraphData()}
        xField="month"
        yField="value"
        isGroup={true}
        isStack={true}
        groupingField="category"
        columnGroupField="groupingField"
        xTitle={t('costAndCreditBreakDown.month')}
        yTitle={t('costAndCreditBreakDown.grossCostOrCostAfterCreditOrCredit')}
        columnColorOverride={generateGraphColors(3)}
        prefixSymbol={currencySymbol}
      />
    );

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(costAndCreditStartMonth),
          moment(costAndCreditEndMonth),
        ]}
        onChange={onChangeCostAndCreditDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  return (
    <div
      className="aws-cost-and-credit-breakdown graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.costandCreditBreakdown')}
        graphName={'cost-and-credit-breakdown'}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        isTableView={isCostAndCreditBreakdownTableView}
        setIsTableView={setCostAndCreditBreakdownTableView}
        excelData={getGCPCostAndCreditBreakdownExcelData(
          selectedDashboard?.connectorName ?? '',
          costCreditBreakdownData
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};
