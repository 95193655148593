import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';

import {
  selectPurchase,
  setAwsElastiCacheRisCartData,
  setAwsRegions,
} from 'redux/purchaseSlice';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import { TERMS_LIST } from 'pages/PurchasePage/constants';
import { PurchaseAwsElastiCacheRisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import {
  AWS_ELASTICACHE_RI_DEFAULT_VALUES,
  ElastiCacheEngineTypesList,
} from 'pages/PurchasePage/components/ReservedInstances/constants';
import {
  getAwsElastiCacheRiNodeTypes,
  getAwsElastiCacheRiOfferingDetails,
  getAwsElastiCacheRiPaymentOptions,
  getAwsElastiCacheRiTerms,
} from 'pages/PurchasePage/components/ReservedInstances/services';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import RadioGroup from 'components/RadioGroup';
import {
  convertYearToSeconds,
  getAverageMonthlyRecurringCost,
  getSubTotalDue,
} from 'pages/PurchasePage/components/ReservedInstances/utils';
import { fetchAwsRegions } from 'pages/PurchasePage/services';
import { isEmptyField, isNumber, validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

type BuyElastiCacheRiFormModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  editData?: PurchaseAwsElastiCacheRisType;
  indexPosition?: number;
};

const BuyElastiCacheRiFormModal = ({
  show,
  setShow,
  editData,
  indexPosition,
}: BuyElastiCacheRiFormModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, awsElastiCacheCartData, awsRegions } =
    useSelector(selectPurchase);

  const [elastiCacheRiFormData, setElastiCacheRiFormData] =
    useState<PurchaseAwsElastiCacheRisType>(AWS_ELASTICACHE_RI_DEFAULT_VALUES);
  const [nodeTypes, setNodeTypes] = useState<string[]>([]);
  const [terms, setTerms] = useState<string>();
  const [paymentOptions, setPaymentOptions] = useState<string[]>();
  const [fetchRegionReqStatus, setFetchRegionReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchNodeTypesRequestStatus, setFetchNodeTypesRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [fetchTermsReqStatus, setFetchTermsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchPaymentOptionsReqStatus, setFetchPaymentOptionsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [engineValidation, setEngineValidation] = useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [nodeTypeValidation, setNodeTypeValidation] = useState('');
  const [paymentOptionValidation, setPaymentOptionValidation] = useState('');
  const [quantityValidation, setQuantityValidation] = useState('');
  const [offeringDetailsRequestStatus, setOfferingDetailsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [offeringDetailsValidation, setOfferingDetailsValidation] =
    useState('');
  const [editLoaded, setEditLoaded] = useState(false);

  useEffect(() => {
    if (editData) {
      setElastiCacheRiFormData(editData);
    }

    if (awsRegions.length === 0) {
      getAwsRegions();
    }
  }, []);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setNodeTypes([]);
    if (
      validateEngine(elastiCacheRiFormData.engine, false) &&
      validateRegion(elastiCacheRiFormData.region, false)
    ) {
      fetchNodeTypes(source);
    }

    return () => {
      source.cancel();
    };
  }, [elastiCacheRiFormData.engine, elastiCacheRiFormData.region]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setTerms(undefined);
    if (nodeTypes && validateNodeType(elastiCacheRiFormData.nodeType, false)) {
      fetchTerms(source);
    }

    return () => {
      source.cancel();
    };
  }, [elastiCacheRiFormData.nodeType, nodeTypes]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    setPaymentOptions(undefined);
    if (terms !== undefined) {
      fetchPaymentOptions(source);
    }

    return () => {
      source.cancel();
    };
  }, [terms, elastiCacheRiFormData.term]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (editLoaded) {
      setElastiCacheRiFormData({
        ...elastiCacheRiFormData,
        hourlyUsageCharge: 0,
        oneTimePayment: 0,
        offeringId: '',
      });
      setOfferingDetailsValidation('');
    } else {
      setEditLoaded(true);
    }

    if (validateAllFields(false)) {
      fetchOfferingDetails(source);
    }

    return () => {
      source.cancel();
    };
  }, [
    elastiCacheRiFormData.engine,
    elastiCacheRiFormData.region,
    elastiCacheRiFormData.nodeType,
    elastiCacheRiFormData.term,
    elastiCacheRiFormData.paymentOption,
    elastiCacheRiFormData.quantity,
    terms,
    paymentOptions,
  ]);

  /**
   * @function fetchRegions
   * @description Function to fetch regions data
   */
  const getAwsRegions = () => {
    setFetchRegionReqStatus(REQUEST_STATUS.PROCESSING);

    fetchAwsRegions({ connectorId: selectedConnection?.connectorId })
      .then((res: any) => {
        dispatch(setAwsRegions(res.data.responseData));
        setFetchRegionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchRegionReqStatus);
      });
  };

  /**
   * @function fetchNodeTypes
   * @description Function to fetch the node types
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchNodeTypes = (cancelTokenSource: CancelTokenSource) => {
    setFetchNodeTypesRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      engine: elastiCacheRiFormData.engine,
      region: elastiCacheRiFormData?.region,
    };

    getAwsElastiCacheRiNodeTypes(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        setNodeTypes(res.data.responseData || []);
        setFetchNodeTypesRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchNodeTypesRequestStatus);
      });
  };

  /**
   * @function fetchTerms
   * @description Function to fetch the ElastiCache terms
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchTerms = (cancelTokenSource: CancelTokenSource) => {
    setFetchTermsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      engine: elastiCacheRiFormData.engine,
      region: elastiCacheRiFormData?.region,
      nodeType: elastiCacheRiFormData.nodeType,
    };

    getAwsElastiCacheRiTerms(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        const term = res.data.responseData;
        setTerms(term);
        setElastiCacheRiFormData({
          ...elastiCacheRiFormData,
          term: term === 'THREE' ? 3 : 1,
        });
        setFetchTermsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchTermsReqStatus);
      });
  };

  /**
   * @function fetchPaymentOptions
   * @description Function to fetch the ElastiCache Payment Options
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchPaymentOptions = (cancelTokenSource: CancelTokenSource) => {
    setFetchPaymentOptionsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      engine: elastiCacheRiFormData.engine,
      region: elastiCacheRiFormData?.region,
      nodeType: elastiCacheRiFormData.nodeType,
      term: convertYearToSeconds(elastiCacheRiFormData.term),
    };

    getAwsElastiCacheRiPaymentOptions(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        const payments = res.data.responseData;
        if (!payments.includes(elastiCacheRiFormData.paymentOption)) {
          setElastiCacheRiFormData({
            ...elastiCacheRiFormData,
            paymentOption: '',
          });
        }
        setPaymentOptions(payments);
        setFetchPaymentOptionsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchPaymentOptionsReqStatus);
      });
  };

  /**
   * @function fetchOfferingDetails
   * @description Function to fetch the offering details
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchOfferingDetails = (cancelTokenSource: CancelTokenSource) => {
    setOfferingDetailsRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      engine: elastiCacheRiFormData.engine,
      nodeType: elastiCacheRiFormData.nodeType,
      term: convertYearToSeconds(elastiCacheRiFormData.term),
      offeringType: elastiCacheRiFormData.paymentOption,
      connectorId: selectedConnection?.connectorId,
      region: elastiCacheRiFormData.region,
      quantity: Number(elastiCacheRiFormData.quantity),
    };

    getAwsElastiCacheRiOfferingDetails(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          if (data.offeringId) {
            setElastiCacheRiFormData({
              ...elastiCacheRiFormData,
              hourlyUsageCharge: data?.hourlyUsageCharge || 0,
              oneTimePayment: data?.oneTimePayment || 0,
              offeringId: data?.offeringId || '',
            });
            setOfferingDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
            return;
          }
          setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
          setOfferingDetailsValidation(
            t('purchasePage.reservedInstancesLabels.offeringIdNullError')
          );
          return;
        }
        setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
        setOfferingDetailsValidation(
          t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      })
      .catch((e) => {
        onApiCallError(e, false, setOfferingDetailsRequestStatus);
        if (!axios.isCancel(e)) {
          setOfferingDetailsValidation(
            e?.response?.data?.message ||
              t('purchasePage.reservedInstancesLabels.offeringDetailsError')
          );
        }
      });
  };

  /**
   * @function validateEngine
   * @description Function to validate the engine field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateEngine = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.reservedInstancesLabels.engine`),
      setEngineValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateRegion
   * @description Function to validate the region field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateRegion = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.region`),
      setRegionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateNodeType
   * @description Function to validate the node type field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateNodeType = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.reservedInstancesLabels.nodeType`),
      setNodeTypeValidation,
      addErrorMessage
    );
  };

  /**
   * @function validatePaymentOption
   * @description Function to validate the payment option field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validatePaymentOption = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.paymentOptions`),
      setPaymentOptionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateQuantityField
   * @description Function to validate the quantity field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityField = (
    value: number | string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (
      !validateEmptyField(
        value,
        t(`purchasePage.reservedInstancesLabels.quantity`),
        setQuantityValidation,
        addErrorMessage
      )
    ) {
      return (
        isNumber(
          value?.toString(),
          false,
          t(`purchasePage.reservedInstancesLabels.quantity`),
          addErrorMessage ? setQuantityValidation : undefined
        ) && validateQuantityRangeLimit(value, addErrorMessage)
      );
    }

    return false;
  };

  /**
   * @function validateQuantityRangeLimit
   * @description Function to validate the quantity limit and range
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityRangeLimit = (
    value: string | number | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (!value || !isNumber(value.toString(), false)) {
      return false;
    }

    if (Number(value) <= 0) {
      addErrorMessage &&
        setQuantityValidation(
          t('purchasePage.reservedInstancesLabels.quantityLimitError')
        );
      return false;
    }

    addErrorMessage && setQuantityValidation('');

    return true;
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAllFields = (addErrorMessage: boolean = true) => {
    let validation = true;

    if (!validateEngine(elastiCacheRiFormData?.engine, addErrorMessage)) {
      validation = false;
    }

    if (!validateRegion(elastiCacheRiFormData.region, addErrorMessage)) {
      validation = false;
    }

    if (!validateNodeType(elastiCacheRiFormData?.nodeType, addErrorMessage)) {
      validation = false;
    }

    if (
      !validatePaymentOption(
        elastiCacheRiFormData?.paymentOption,
        addErrorMessage
      )
    ) {
      validation = false;
    }

    if (
      !validateQuantityField(elastiCacheRiFormData?.quantity, addErrorMessage)
    ) {
      validation = false;
    }

    if (
      addErrorMessage &&
      validation &&
      isEmptyField(elastiCacheRiFormData?.offeringId)
    ) {
      setOfferingDetailsValidation(
        t('purchasePage.reservedInstancesLabels.missingOfferingDetails')
      );
      validation = false;
    }

    if (!nodeTypes || !terms || !paymentOptions) {
      validation = false;
    }

    return validation;
  };

  /**
   * @function onClickBuy
   * @description Callback function for buy button click
   */
  const onClickBuy = () => {
    if (!validateAllFields()) {
      return;
    }

    if (editData !== undefined && indexPosition !== undefined) {
      const cartData = [...awsElastiCacheCartData];
      cartData.splice(indexPosition, 1, elastiCacheRiFormData);
      dispatch(setAwsElastiCacheRisCartData(cartData));
    } else {
      dispatch(
        setAwsElastiCacheRisCartData([
          ...awsElastiCacheCartData,
          elastiCacheRiFormData,
        ])
      );
    }
    setShow(false);
  };

  return (
    <DrawerComponent
      className="elasticache-ri-purchase-form"
      dataTestId="elasticache-ri-purchase-form"
      width={481}
      open={show}
      title={`${t(
        'purchasePage.reservedInstancesLabels.purchaseReservedInstances'
      )} - ${t('purchasePage.reservedInstancesLabels.elastiCache')}`}
      onClose={() => setShow(false)}
      footer={
        <PurchaseFooter
          totalHeading={t('purchasePage.cart.subTotalDueNow')}
          totalValue={getSubTotalDue(
            elastiCacheRiFormData.term,
            elastiCacheRiFormData.hourlyUsageCharge
          )}
          costSummary={[
            {
              key: t(
                'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
              ),
              value: getAverageMonthlyRecurringCost(
                elastiCacheRiFormData.term,
                elastiCacheRiFormData.hourlyUsageCharge
              ),
            },
            {
              key: t('purchasePage.reservedInstancesLabels.oneTimePayment'),
              value: elastiCacheRiFormData?.oneTimePayment,
            },
          ]}
          okTitle={
            editData
              ? t('purchasePage.cart.update')
              : t('purchasePage.cart.buy')
          }
          onClickCancel={() => setShow(false)}
          onClickOk={onClickBuy}
          loading={offeringDetailsRequestStatus === REQUEST_STATUS.PROCESSING}
          validationMessage={offeringDetailsValidation}
        />
      }
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.engine')}
            required={true}
          />
          <SelectDropdown
            value={elastiCacheRiFormData?.engine}
            options={ElastiCacheEngineTypesList}
            placeholder={t('purchasePage.reservedInstancesLabels.selectEngine')}
            onSelect={(value: any) => {
              setElastiCacheRiFormData({
                ...elastiCacheRiFormData,
                engine: value,
                nodeType: '',
              });
              validateEngine(value);
            }}
            onBlur={() => {
              validateEngine(elastiCacheRiFormData?.engine);
            }}
            designVersion2
          />
          <span
            style={{
              display: engineValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {engineValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.region')} required={true} />
          <SelectDropdown
            value={elastiCacheRiFormData?.region}
            options={awsRegions.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t('purchasePage.cart.selectRegion')}
            loading={fetchRegionReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setElastiCacheRiFormData({
                ...elastiCacheRiFormData,
                region: value,
                nodeType: '',
              });
              validateRegion(value);
            }}
            onBlur={() => {
              validateRegion(elastiCacheRiFormData?.region);
            }}
            designVersion2
          />
          <span
            style={{
              display: regionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {regionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.nodeType')}
            required={true}
          />
          <SelectDropdown
            value={elastiCacheRiFormData?.nodeType}
            options={nodeTypes.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t(
              'purchasePage.reservedInstancesLabels.selectNodeType'
            )}
            loading={fetchNodeTypesRequestStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setElastiCacheRiFormData({
                ...elastiCacheRiFormData,
                nodeType: value,
              });
              validateNodeType(value);
            }}
            onBlur={() => {
              validateNodeType(elastiCacheRiFormData?.nodeType);
            }}
            disabled={
              !validateEngine(elastiCacheRiFormData.engine, false) ||
              !validateRegion(elastiCacheRiFormData.region, false)
            }
            designVersion2
          />
          <span
            style={{
              display: nodeTypeValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {nodeTypeValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.term')} required={true} />
          <RadioGroup
            options={TERMS_LIST.map((item) => ({
              ...item,
              disabled:
                terms !== undefined && item.disabledTermsList.includes(terms),
            }))}
            onChange={(e: any) => {
              setElastiCacheRiFormData({
                ...elastiCacheRiFormData,
                term: e.target.value,
              });
            }}
            value={elastiCacheRiFormData.term}
            optionType="button"
            style={{ height: 28 }}
            disabled={
              !validateNodeType(elastiCacheRiFormData.nodeType, false) || !terms
            }
            loading={fetchTermsReqStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.cart.paymentOptions')}
            required={true}
          />
          <SelectDropdown
            value={elastiCacheRiFormData?.paymentOption}
            options={paymentOptions?.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t('purchasePage.cart.selectPaymentOption')}
            onSelect={(value: any) => {
              setElastiCacheRiFormData({
                ...elastiCacheRiFormData,
                paymentOption: value,
              });
              validatePaymentOption(value);
            }}
            onBlur={() => {
              validatePaymentOption(elastiCacheRiFormData?.paymentOption);
            }}
            loading={fetchPaymentOptionsReqStatus === REQUEST_STATUS.PROCESSING}
            disabled={terms === undefined}
            designVersion2
          />
          <span
            style={{
              display: paymentOptionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {paymentOptionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.quantity')}
            required={true}
          />
          <Input
            value={elastiCacheRiFormData?.quantity}
            type="stepNumber"
            rootClassName="width-30"
            steps={1}
            min={1}
            onChange={(value: number) => {
              setElastiCacheRiFormData({
                ...elastiCacheRiFormData,
                quantity: value,
              });
              validateQuantityField(value);
            }}
            onBlur={() => {
              validateQuantityField(elastiCacheRiFormData?.quantity);
            }}
          />
          <span
            style={{
              display: quantityValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {quantityValidation}
          </span>
        </div>
      </section>
    </DrawerComponent>
  );
};

export default BuyElastiCacheRiFormModal;
