import i18n from 'i18n';

import { PROVIDER } from 'constants/cloudProviders';
import { RecommendationSource } from 'pages/CostOptimizationInsightsPage/constants';
import { ValueLabelType } from 'types/dashboard';
import { RECOMMENDATION_CATEGORIES } from 'constants/recommendations';
import { isDashboardWithStaticData } from 'utils/dashboardUtils';
import { DashboardListType } from 'types/navigationMenu';

/**
 * @function getRecommendersOptions
 * @description Returns the list of recommender options for recommenders dropdown based on permission and CSP provider
 * @param permissions Object containing the permissions
 * @param selectedDashboard Object containing the selected dashboard details
 * @param selectedGraphCategory Category selected (WASTE_MANAGEMENT, CONSUMPTION_MANAGEMENT, PURCHASE_TACTICS)
 * @param recommenders List of recommenders for CSP
 * @returns List of recommender options containing the value and label
 */
export const getRecommendersOptions = (
  permissions: { [key: string]: boolean },
  selectedDashboard: DashboardListType,
  selectedGraphCategory: string,
  recommenders: ValueLabelType[]
) => {
  // Return the CSP recommenders if there is no Third Party Apps permission or provider is other than GCP and AWS
  if (
    !permissions.thirdPartyAppsRead ||
    isDashboardWithStaticData(selectedDashboard) ||
    selectedGraphCategory !==
      RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT ||
    ![PROVIDER.GCP, PROVIDER.AWS].includes(
      selectedDashboard.connectorProvider as PROVIDER
    )
  ) {
    return recommenders;
  }

  // Add Granulate option to existing recommenders
  return [
    ...recommenders,
    { value: RecommendationSource.GRANULATE, label: i18n.t('granulate') },
  ];
};
