import i18n from 'i18n';
import {
  InsightDashboardColumn,
  IMPACT_CATEGORY,
  SERVICE_NOW_STATUS,
  TableExportColumns,
} from './constants';
import { InsightDashboardDataType } from './type';

export const getInsightDashboardExcelData = (
  connectorName: string,
  tableData: InsightDashboardDataType[],
  startDate: string | null,
  endDate: string | null
) => {
  return {
    sheetName: i18n.t('gprofilerInsightsDashboard.insightDashboard'),
    columns: InsightDashboardColumn,
    data: tableData.map((item) => ({
      ...item,
      runtime: item.language,
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: startDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: endDate,
      },
    ],
  };
};

export const getImpactCategory = (value: number) => {
  if (value === null || value === undefined) {
    return null;
  }

  if (value < 15) {
    return IMPACT_CATEGORY.LOW;
  }

  if (value > 25) {
    return IMPACT_CATEGORY.HIGH;
  }

  return IMPACT_CATEGORY.MEDIUM;
};

export const getTicketStatus = (ticket: string) => {
  switch (ticket) {
    case SERVICE_NOW_STATUS.UNDEFINED:
      return i18n.t('serviceNowLabels.ticketTBC');
    case SERVICE_NOW_STATUS.NEW:
      return i18n.t('serviceNowLabels.new');
    case SERVICE_NOW_STATUS.IN_PROGRESS:
      return i18n.t('serviceNowLabels.inProgress');
    case SERVICE_NOW_STATUS.ON_HOLD:
      return i18n.t('serviceNowLabels.onHold');
    case SERVICE_NOW_STATUS.RESOLVED:
      return i18n.t('serviceNowLabels.resolved');
    case SERVICE_NOW_STATUS.CLOSED:
      return i18n.t('serviceNowLabels.closed');
  }
};

export const getTableExcelData = (
  connectorName: string,
  data: {
    sheetName: string;
    tableData: any[];
  }[]
) => {
  return data.map((eachData) => ({
    sheetName: eachData.sheetName,
    columns: TableExportColumns,
    data: eachData.tableData.map((item, index) => ({
      ...item,
      slNo: index + 1,
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  }));
};

/**
 * @function getPlainString
 * @description This function takes a string as input and returns a string by removing all non-alphabetic characters.
 * @param str The string to be converted.
 * @return The plain string with all non-alphabetic characters removed in lowercase.
 */
export const getPlainString = (str: string) => {
  return str.replace(/[^a-zA-Z]/g, '').toLowerCase();
};

/**
 * @function sortTableData
 * @description Function to sort the data
 * @param data Data to be sorted
 * @param key field against which the sorting is done
 * @param order order of sorting, either ascending or descending
 */
export const sortTableData = (
  data: any[],
  key: string,
  order: string | undefined
) => {
  const sortFunctions: { [key: string]: (item1: any, item2: any) => number } = {
    functionName: (item1, item2) => {
      const A = getPlainString(item1.functionName);
      const B = getPlainString(item2.functionName);
      return order === 'descend' ? B.localeCompare(A) : A.localeCompare(B);
    },
    totalCPU: (item1, item2) =>
      order === 'descend'
        ? parseFloat(item2.totalCPU) - parseFloat(item1.totalCPU)
        : parseFloat(item1.totalCPU) - parseFloat(item2.totalCPU),
    ownTime: (item1, item2) =>
      order === 'descend'
        ? parseFloat(item2.ownTime) - parseFloat(item1.ownTime)
        : parseFloat(item1.ownTime) - parseFloat(item2.ownTime),
    occurrences: (item1, item2) =>
      order === 'descend'
        ? item2.occurrences - item1.occurrences
        : item1.occurrences - item2.occurrences,
    samples: (item1, item2) =>
      order === 'descend'
        ? item2.samples - item1.samples
        : item1.samples - item2.samples,
    trend: (item1, item2) =>
      order === 'descend'
        ? parseInt(item2.trend) - parseInt(item1.trend)
        : parseInt(item1.trend) - parseInt(item2.trend),
  };

  return [...data].sort(sortFunctions[key]);
};
