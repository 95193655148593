import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';

import ComparisonCard from 'components/NewComparisonCard';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CHART_TYPES } from 'constants/graphConfig';
import { IntegrationTypes } from 'pages/IntegrationsPage/constants';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { ComparisonListType } from 'types/dashboard';
import {
  TypeAndNameByCost,
  MonthlyCostByNameType,
  MonthlyCostType,
} from 'types/dataTypes';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import { getSnowflakeChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { BYTES_TO_TB_VALUE } from 'constants/utilityConstants';

import TopDatabasesByUsage from './TopDatabasesByUsage';
import CostOverTimeByAccount from './CostOverTimeByAccount';
import {
  getCostOverTimeByAccountColumns,
  getCostOvertimeByAccountQuery,
  getSnowflakeStorageExcelData,
  getStorageCostTrendQuery,
  getStorageUsageByTimePeriod,
  getTopDatabaseByStorageUsageQuery,
  getUsageByDatabasePerMonthQuery,
} from './utils';
import {
  TopDbByCostExportColumns,
  TotalStorageCostQuery,
  TotalStorageUsageQuery,
} from './constants';
import CostTrend from '../SnowflakeDashboardComponents/CostTrend';
import { CostTrendExportColumns } from '../SnowflakeDashboardComponents/constants';
import CostByNamePerMonth from '../SnowflakeDashboardComponents/CostByServiceTypePerMonth';
import { getCostByNamePerMonthExportColumns } from '../SnowflakeDashboardComponents/utils';

const SnowflakeStorageDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedDashboard,
    tableViewEnabled,
    expandedGraphName,
    showExpandGraphModal,
    pdfDownloadMode,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  //States for comparison cards
  const [costComparisonList, setCostComparisonList] = useState<
    ComparisonListType[]
  >([]);
  const [totalStorageUsage, setTotalStorageUsage] = useState(0);
  const [totalStorageUsageReqStatus, setTotalStorageUsageReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [totalStorageCost, setTotalStorageCost] = useState(0);
  const [totalStorageCostReqStatus, setTotalStorageCostReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [yesterdayStorage, setYesterdayStorage] = useState(0);
  const [yesterdayStorageReqStatus, setYesterdayStorageReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [lastWeekStorage, setLastWeekStorage] = useState(0);
  const [lastWeekStorageReqStatus, setLastWeekStorageReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [lastMonthStorage, setLastMonthStorage] = useState(0);
  const [lastMonthStorageReqStatus, setLastMonthStorageReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  // Cost Trend
  const [monthlyCost, setMonthlyCost] = useState<MonthlyCostType[]>([]);
  const [monthlyCostReqStatus, setMonthlyCostReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isCostTrendTableView, setIsCostTrendTableView] = useState(false);
  const [costTrendDateRange, setCostTrendDateRange] = useState([
    moment().subtract(11, 'month').format(YEAR_MONTH_WITHOUT_SEPARATOR),
    moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
  ]);

  // Estimated Storage Cost by Database per Month
  const [usageByDbPerMonth, setUsageByDbPerMonth] = useState<
    MonthlyCostByNameType[]
  >([]);
  const [usageByDbPerMonthReqStatus, setUsageByDbPerMonthReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isUsageByDbPerMonthTableView, setIsUsageByDbPerMonthTableView] =
    useState(false);
  const [usageByDbPerMonthDateRange, setUsageByDbPerMonthDateRange] = useState([
    moment().subtract(11, 'month').format(YEAR_MONTH_WITHOUT_SEPARATOR),
    moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
  ]);

  // Top Databases by Estimated Storage Cost
  const [topDbByUsage, setTopDbByUsage] = useState<TypeAndNameByCost[]>([]);
  const [allDatabaseList, setAllDatabaseList] = useState<string[]>([]);
  const [
    selectedTopDbByUsageDatabaseList,
    setSelectedTopDbByUsageDatabaseList,
  ] = useState<string[]>([]);
  const [topDbByUsageReqStatus, setTopDbByUsageReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isTopDbByUsageTableView, setIsTopDbByUsageTableView] = useState(false);
  const [topDbByUsageDateRange, setTopDbByUsageDateRange] = useState([
    moment().subtract(11, 'month').format(YEAR_MONTH_WITHOUT_SEPARATOR),
    moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
  ]);

  // Cost over time by account
  const [costOverTimeByAccount, setCostOverTimeByAccount] = useState<
    MonthlyCostByNameType[]
  >([]);
  const [costOverTimeByAccountReqStatus, setCostOverTimeByAccountReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [
    isCostOverTimeByAccountTableView,
    setIsCostOverTimeByAccountTableView,
  ] = useState(false);
  const [costOverTimeByAccountDateRange, setCostOverTimeByAccountDateRange] =
    useState([
      moment().subtract(11, 'month').format(YEAR_MONTH_WITHOUT_SEPARATOR),
      moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
    ]);

  useEffect(() => {
    setTotalStorageUsage(0);
    setTotalStorageCost(0);
    setYesterdayStorage(0);
    setLastWeekStorage(0);
    setLastMonthStorage(0);

    if (selectedDashboard) {
      fetchAndAssignCostCardsData();
    }
  }, [selectedDashboard]);

  useEffect(() => {
    setCostComparisonList([
      {
        value: totalStorageUsage * BYTES_TO_TB_VALUE,
        heading: t('snowflakeDashboards.totalStorageUsageTb'),
        requestStatus: [totalStorageUsageReqStatus],
        valueMinimumFractionDigits: 0,
      },
      {
        value: totalStorageCost,
        heading: t('snowflakeDashboards.totalStorageCost'),
        requestStatus: [totalStorageCostReqStatus],
        valuePrefix: currencySymbol,
      },
      {
        value: yesterdayStorage * BYTES_TO_TB_VALUE,
        heading: t('snowflakeDashboards.yesterdayStorageTb'),
        requestStatus: [yesterdayStorageReqStatus],
        valueMinimumFractionDigits: 0,
      },
      {
        value: lastWeekStorage * BYTES_TO_TB_VALUE,
        heading: t('snowflakeDashboards.lastWeekStorageTb'),
        requestStatus: [lastWeekStorageReqStatus],
        valueMinimumFractionDigits: 0,
      },
      {
        value: lastMonthStorage * BYTES_TO_TB_VALUE,
        heading: t('snowflakeDashboards.lastMonthStorageTb'),
        requestStatus: [lastMonthStorageReqStatus],
        valueMinimumFractionDigits: 0,
      },
    ]);
  }, [
    totalStorageUsage,
    totalStorageUsageReqStatus,
    totalStorageCost,
    totalStorageCostReqStatus,
    yesterdayStorage,
    yesterdayStorageReqStatus,
    lastWeekStorage,
    lastWeekStorageReqStatus,
    lastMonthStorage,
    lastMonthStorageReqStatus,
  ]);

  useEffect(() => {
    setIsCostTrendTableView(tableViewEnabled);
    setIsUsageByDbPerMonthTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    if (selectedDashboard) {
      dispatch(
        setExportToExcelData(
          getSnowflakeStorageExcelData(
            monthlyCost,
            costTrendDateRange,
            {
              graphHeading: t('graphHeadings.storageUsageByDatabasePerMonth'),
              data: getGroupedByNameAndMonthTableData(usageByDbPerMonth),
              dateRange: usageByDbPerMonthDateRange,
            },
            {
              data: getTopDbByUsageTableData(),
              dateRange: topDbByUsageDateRange,
              selectedDbs: selectedTopDbByUsageDatabaseList,
            },
            {
              data: getGroupedByNameAndMonthTableData(costOverTimeByAccount),
              dateRange: costOverTimeByAccountDateRange,
            }
          )
        )
      );
    }
  }, [
    selectedDashboard,
    monthlyCost,
    costTrendDateRange,
    usageByDbPerMonth,
    usageByDbPerMonthDateRange,
  ]);

  useEffect(() => {
    setMonthlyCost([]);
    if (selectedDashboard) {
      fetchCostTrendData();
    }
  }, [selectedDashboard, costTrendDateRange]);

  useEffect(() => {
    setUsageByDbPerMonth([]);
    if (selectedDashboard) {
      fetchUsageByDatabasePerMonthData();
    }
  }, [selectedDashboard, usageByDbPerMonthDateRange]);

  useEffect(() => {
    setTopDbByUsage([]);
    if (selectedDashboard) {
      fetchAllDbByUsageData();
    }
  }, [selectedDashboard, topDbByUsageDateRange]);

  useEffect(() => {
    setCostOverTimeByAccount([]);
    if (selectedDashboard) {
      fetchCostOverTimeByAccountData();
    }
  }, [selectedDashboard, costOverTimeByAccountDateRange]);

  /**
   * @function fetchAndAssignCostCardsData
   * @description Function to fetch the data for all the metric cards
   */
  const fetchAndAssignCostCardsData = () => {
    fetchCostCardData(
      TotalStorageUsageQuery,
      setTotalStorageUsage,
      setTotalStorageUsageReqStatus
    );

    fetchCostCardData(
      TotalStorageCostQuery,
      setTotalStorageCost,
      setTotalStorageCostReqStatus
    );

    fetchCostCardData(
      getStorageUsageByTimePeriod([
        moment().subtract(1, 'days').format(HYPHEN_DATE_FORMAT),
        moment().subtract(1, 'days').format(HYPHEN_DATE_FORMAT),
      ]),
      setYesterdayStorage,
      setYesterdayStorageReqStatus
    );

    fetchCostCardData(
      getStorageUsageByTimePeriod([
        moment().subtract(1, 'week').startOf('week').format(HYPHEN_DATE_FORMAT),
        moment().subtract(1, 'week').endOf('week').format(HYPHEN_DATE_FORMAT),
      ]),
      setLastWeekStorage,
      setLastWeekStorageReqStatus
    );

    fetchCostCardData(
      getStorageUsageByTimePeriod([
        moment()
          .subtract(1, 'months')
          .startOf('month')
          .format(HYPHEN_DATE_FORMAT),
        moment()
          .subtract(1, 'months')
          .endOf('month')
          .format(HYPHEN_DATE_FORMAT),
      ]),
      setLastMonthStorage,
      setLastMonthStorageReqStatus
    );
  };

  /**
   * @function fetchCostCardData
   * @description Function to fetch the cost card data based on the input query
   * @param query Query for fetching the data
   * @param setData Callback function for setting the data fetched
   * @param setRequestStatus Callback function to set the request status
   */
  const fetchCostCardData = (
    query: Object,
    setData: (val: number) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      ...query,
      snowflakeIntegrationId: selectedDashboard?.integrationId,
    };

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        setData(JSON.parse(res?.data?.responseData)?.[0]?.VALUE ?? 0);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
        setData(0);
      });
  };

  /**
   * @function fetchCostTrendData
   * @description Function to fetch the cost trend data
   */
  const fetchCostTrendData = () => {
    setMonthlyCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getStorageCostTrendQuery(
      selectedDashboard?.integrationId ?? '',
      costTrendDateRange
    );

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        const data = JSON.parse(res?.data?.responseData);
        const months = getMonthYearShortList(
          costTrendDateRange[0],
          costTrendDateRange[1]
        );
        const trendsData: MonthlyCostType[] = [];
        months.forEach((month) => {
          trendsData.push({
            month: month,
            cost:
              data.find(
                (item: any) =>
                  moment(item.MONTH, YEAR_MONTH_WITHOUT_SEPARATOR).format(
                    MONTH_YEAR_SHORT
                  ) === month
              )?.COST ?? 0,
          });
        });

        setMonthlyCost(trendsData);
        setMonthlyCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setMonthlyCostReqStatus);
      });
  };

  /**
   * @function fetchUsageByDatabasePerMonthData
   * @description Function to fetch the usage per database by month data
   */
  const fetchUsageByDatabasePerMonthData = () => {
    setUsageByDbPerMonthReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getUsageByDatabasePerMonthQuery(
      selectedDashboard?.integrationId ?? '',
      usageByDbPerMonthDateRange
    );

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        const data = JSON.parse(res?.data?.responseData);

        const months = getMonthYearShortList(
          usageByDbPerMonthDateRange[0],
          usageByDbPerMonthDateRange[1],
          MONTH_YEAR_SHORT
        );

        const creditsUsedData: MonthlyCostByNameType[] = [];
        months.forEach((month) => {
          const dataItems = data
            .filter(
              (item: any) =>
                moment(item.MONTH, YEAR_MONTH_WITHOUT_SEPARATOR).format(
                  MONTH_YEAR_SHORT
                ) === month
            )
            .map((item: any) => ({
              month: month,
              name: item.DATABASENAME,
              cost: item.USAGE * BYTES_TO_TB_VALUE,
            }));

          creditsUsedData.push(...dataItems);
        });

        setUsageByDbPerMonth(creditsUsedData);
        setUsageByDbPerMonthReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setUsageByDbPerMonthReqStatus);
      });
  };

  /**
   * @function fetchAllDbByUsageData
   * @description Function to fetch all the db by usage data
   */
  const fetchAllDbByUsageData = () => {
    setTopDbByUsageReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getTopDatabaseByStorageUsageQuery(
      selectedDashboard?.integrationId ?? '',
      topDbByUsageDateRange
    );

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        const data = JSON.parse(res?.data?.responseData);
        const dbData: TypeAndNameByCost[] = [];
        const databaseList: string[] = [];

        data?.forEach((item: any) => {
          dbData.push({
            name: item.DATABASENAME,
            cost: item.DATABASEUSAGE * BYTES_TO_TB_VALUE,
            type: t('snowflakeDashboards.databaseUsage'),
          });
          dbData.push({
            name: item.DATABASENAME,
            cost: item.FAILSAFEUSAGE * BYTES_TO_TB_VALUE,
            type: t('snowflakeDashboards.failsafeUsage'),
          });
          databaseList.push(item.DATABASENAME);
        });

        setTopDbByUsage(dbData);
        setAllDatabaseList(databaseList);
        setSelectedTopDbByUsageDatabaseList(databaseList.slice(0, 10));
        setTopDbByUsageReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTopDbByUsageReqStatus);
      });
  };

  /**
   * @function fetchCostOverTimeByAccountData
   * @description Function to fetch the cost over time by account data
   */
  const fetchCostOverTimeByAccountData = () => {
    setCostOverTimeByAccountReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getCostOvertimeByAccountQuery(
      selectedDashboard?.integrationId ?? '',
      costOverTimeByAccountDateRange
    );

    getSnowflakeChartData(requestBody)
      .then((res: any) => {
        const data = JSON.parse(res?.data?.responseData);
        const costOvertimeData: MonthlyCostByNameType[] = [];

        const months = getMonthYearShortList(
          costOverTimeByAccountDateRange[0],
          costOverTimeByAccountDateRange[1]
        );
        months.forEach((month) => {
          data?.forEach((item: any) => {
            if (
              moment(item.MONTH, YEAR_MONTH_WITHOUT_ZERO).format(
                MONTH_YEAR_SHORT
              ) === month
            )
              costOvertimeData.push({
                month: month,
                cost: item.CREDITS,
                name: item.ACCOUNT_NAME,
              });
          });
        });

        setCostOverTimeByAccount(costOvertimeData);
        setCostOverTimeByAccountReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostOverTimeByAccountReqStatus);
      });
  };

  /**
   * @function onChangeCostTrendDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostTrendDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostTrendDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
    ]);
  };

  /**
   * @function onChangeCostByDbPerMonthDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostByDbPerMonthDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setUsageByDbPerMonthDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
    ]);
  };

  /**
   * @function onChangeTopDbUsageDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeTopDbUsageDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setTopDbByUsageDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
    ]);
  };

  /**
   * @function onChangeCostByAccountDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostByAccountDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setCostOverTimeByAccountDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
    ]);
  };

  /**
   * @function getGroupedByNameAndMonthTableData
   * @description function to get table data source grouped by name
   * @param data array of data to be grouped
   * @returns object array of table data source
   */
  const getGroupedByNameAndMonthTableData = (
    data: MonthlyCostByNameType[],
    suffix = ''
  ) => {
    const groupedByName: any[] = [];
    data.forEach((item) => {
      const existingName = groupedByName.find((obj) => obj.name === item.name);
      if (existingName) {
        existingName[`${item.month}-cost`] =
          numberCommaSeparator(item.cost) + suffix;
      } else {
        groupedByName.push({
          name: item.name,
          [`${item.month}-cost`]: numberCommaSeparator(item.cost) + suffix,
        });
      }
    });
    return groupedByName;
  };

  /**
   * @function getTopDbByCostTableData
   * @description function to get table data source grouped by the cost type that is database cost or failsafe cost
   * @returns object array of table data source
   */
  const getTopDbByUsageTableData = () => {
    const groupedByName: any[] = [];
    topDbByUsage
      .filter((item) => selectedTopDbByUsageDatabaseList.includes(item.name))
      .forEach((item) => {
        const existingName = groupedByName.find(
          (obj) => obj.name === item.name
        );
        if (existingName) {
          existingName[
            item.type === t('snowflakeDashboards.databaseUsage')
              ? 'dbCost'
              : 'fsCost'
          ] = numberCommaSeparator(item.cost);
        } else {
          groupedByName.push({
            name: item.name,
            [item.type === t('snowflakeDashboards.databaseUsage')
              ? 'dbCost'
              : 'fsCost']: numberCommaSeparator(item.cost),
          });
        }
      });
    return groupedByName;
  };

  const getPdfContentData = () => {
    return {
      costCardsData: getCostComparisonCards(),
    };
  };

  const getPdfMetaData = () => {
    return {
      viewName: t('dashNav.storage'),
      fileName: selectedDashboard?.name!,
      heading: selectedDashboard?.name!,
      subtitle1: selectedDashboard?.integrationName!,
      provider: IntegrationTypes.SNOWFLAKE,
    };
  };

  const getCostComparisonCards = () =>
    costComparisonList.map((item, index) => {
      return (
        <ComparisonCard
          key={item.heading}
          index={index}
          value={item.value}
          valueMinimumFractionDigits={item.valueMinimumFractionDigits}
          valueMaximumFractionDigits={item.valueMaximumFractionDigits}
          heading={item.heading}
          valuePrefix={item.valuePrefix}
          comparisonValue={item.comparisonValue}
          comparisonFrom={item.comparisonFrom}
          requestStatus={item.requestStatus}
          errorMessage={item.errorMessage}
        />
      );
    });

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'cost-trend':
        return (
          <CostTrend
            monthlyCost={monthlyCost}
            costTrendRequestStatus={monthlyCostReqStatus}
            isCostTrendTableView={isCostTrendTableView}
            setIsCostTrendTableView={setIsCostTrendTableView}
            pdfView={pdfView}
            costTrendDateRange={costTrendDateRange}
            onChangeCostTrendDateRange={onChangeCostTrendDateRange}
          />
        );
      case 'cost-by-db-per-month':
        return (
          <CostByNamePerMonth
            graphHeading={t('graphHeadings.storageUsageByDatabasePerMonth')}
            graphName="cost-by-db-per-month"
            costByNamePerMonth={usageByDbPerMonth}
            costByNamePerMonthTableData={getGroupedByNameAndMonthTableData(
              usageByDbPerMonth,
              ` ${t('snowflakeDashboards.tb')}`
            )}
            costByNamePerMonthRequestStatus={usageByDbPerMonthReqStatus}
            isCostByNamePerMonthTableView={isUsageByDbPerMonthTableView}
            setIsCostByNamePerMonthTableView={setIsUsageByDbPerMonthTableView}
            costByNamePerMonthDateRange={usageByDbPerMonthDateRange}
            onChangeCostByNamePerMonthDateRange={
              onChangeCostByDbPerMonthDateRange
            }
            pdfView={pdfView}
          />
        );
      case 'top-db-by-cost':
        return (
          <TopDatabasesByUsage
            topDbByUsage={topDbByUsage}
            topDbByUsageTableData={getTopDbByUsageTableData()}
            topDbByUsageRequestStatus={topDbByUsageReqStatus}
            isTopDbByUsageTableView={isTopDbByUsageTableView}
            setIsTopDbByUsageTableView={setIsTopDbByUsageTableView}
            topDbByUsageDateRange={topDbByUsageDateRange}
            onChangeTopDbByUsageDateRange={onChangeTopDbUsageDateRange}
            pdfView={pdfView}
            allDbList={allDatabaseList}
            selectedDbList={selectedTopDbByUsageDatabaseList}
            setSelectedDbList={setSelectedTopDbByUsageDatabaseList}
          />
        );
      case 'cost-over-time-by-account':
        return (
          <CostOverTimeByAccount
            costOverTimeByAccount={costOverTimeByAccount}
            costOverTimeByAccountTableData={getGroupedByNameAndMonthTableData(
              costOverTimeByAccount
            )}
            costOverTimeByAccountRequestStatus={costOverTimeByAccountReqStatus}
            isCostOverTimeByAccountTableView={isCostOverTimeByAccountTableView}
            setIsCostOverTimeByAccountTableView={
              setIsCostOverTimeByAccountTableView
            }
            costOverTimeByAccountDateRange={costOverTimeByAccountDateRange}
            onChangeCostOverTimeByAccountDateRange={
              onChangeCostByAccountDateRange
            }
            pdfView={pdfView}
          />
        );
    }
  };

  return (
    <div className="snowflake-storage-dashboard flex flex-column flex-gap-24">
      <div className="cost-cards flex flex-space-between">
        {getCostComparisonCards()}
      </div>
      {getGraphComponent('cost-trend')}
      {getGraphComponent('cost-by-db-per-month')}
      <Row gutter={24}>
        <Col span={12}>{getGraphComponent('top-db-by-cost')}</Col>
        <Col span={12}>{getGraphComponent('cost-over-time-by-account')}</Col>
      </Row>
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData()}
          pdfContent={[
            {
              element: getGraphComponent('cost-trend', true),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: 'cost-trend',
              column: CostTrendExportColumns,
              body: monthlyCost.map((item, index) => ({
                ...item,
                cost: numberCommaSeparator(item.cost),
                slNo: index + 1,
              })),
              tableName: t('graphHeadings.costTrend'),
              isTableView: isCostTrendTableView,
            },
            {
              element: getGraphComponent('cost-by-db-per-month', true),
              contentType: CHART_TYPES.STACK_CHART,
              graphName: 'cost-by-db-per-month',
              column: getCostByNamePerMonthExportColumns(
                t('graphHeadings.storageUsageByDatabasePerMonth'),
                usageByDbPerMonthDateRange[0],
                usageByDbPerMonthDateRange[1]
              ),
              body: getGroupedByNameAndMonthTableData(usageByDbPerMonth).map(
                (item, index) => ({
                  ...item,
                  slNo: index + 1,
                })
              ),
              tableName: t('graphHeadings.storageUsageByDatabasePerMonth'),
              isTableView: isUsageByDbPerMonthTableView,
            },
            {
              element: getGraphComponent('top-db-by-cost', true),
              contentType: CHART_TYPES.GROUPED_CHART,
              graphName: 'top-db-by-cost',
              column: TopDbByCostExportColumns,
              body: getTopDbByUsageTableData().map((item, index) => ({
                ...item,
                slNo: index + 1,
              })),
              tableName: t('graphHeadings.topDbByStorageUsage'),
              isTableView: isTopDbByUsageTableView,
            },
            {
              element: getGraphComponent('cost-over-time-by-account', true),
              contentType: CHART_TYPES.LINE_CHART,
              graphName: 'cost-over-time-by-account',
              column: getCostOverTimeByAccountColumns(
                costOverTimeByAccountDateRange[0],
                costOverTimeByAccountDateRange[1]
              ),
              body: getGroupedByNameAndMonthTableData(
                costOverTimeByAccount
              ).map((item, index) => ({
                ...item,
                slNo: index + 1,
              })),
              tableName: t('graphHeadings.costOverTimeByAccount'),
              isTableView: isCostOverTimeByAccountTableView,
            },
          ]}
          pdfCardData={getPdfContentData()}
        />
      )}
    </div>
  );
};

export default SnowflakeStorageDashboard;
