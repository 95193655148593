import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import CalendarLineIcon from 'remixicon-react/CalendarLineIcon';

import MomentAntDatePicker from 'components/MomentAntDatePicker';
import { changeAlphaOfColor } from 'utils/dashboardUtils';

import { DATE_PICKER_TYPE } from './constants';
import CustomRangePicker from './components/CustomRangePicker';

import './index.scss';

const { RangePicker } = MomentAntDatePicker;

const DatePicker = ({
  pickerType = DATE_PICKER_TYPE.RANGE_PICKER,
  ...remainingProps
}) => {
  const { theme } = useSelector(selectTheme);

  const popupStyle = {
    '--primary-color': theme.primaryColor,
    '--button-icon-color': theme.buttonIconColor,
    '--primary-bg-color': changeAlphaOfColor(theme.primaryColor, 10),
    '--text-color': theme.textColor,
  } as React.CSSProperties;

  if (pickerType === DATE_PICKER_TYPE.DATE_PICKER) {
    return (
      <MomentAntDatePicker
        popupStyle={popupStyle}
        suffixIcon={<CalendarLineIcon className="suffix-icon" />}
        allowClear={false}
        {...remainingProps}
        className={`date-picker-wrapper cursor-pointer ${
          remainingProps.className
        } ${remainingProps.designVersion2 ? 'design-v2' : 'design-v1'}`}
      />
    );
  }

  if (pickerType === DATE_PICKER_TYPE.CUSTOM_RANGE_PICKER) {
    return <CustomRangePicker {...remainingProps} />;
  }

  return (
    <RangePicker
      popupStyle={popupStyle}
      separator="to"
      suffixIcon={<CalendarLineIcon className="suffix-icon" />}
      allowClear={false}
      {...remainingProps}
      className={`date-picker-wrapper cursor-pointer ${
        remainingProps.className
      } ${remainingProps.designVersion2 ? 'design-v2' : 'design-v1'}`}
    />
  );
};

export default DatePicker;
