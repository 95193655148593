import { INCIDENT_TYPES } from 'components/CreateTicketDrawer/constants';

export type TicketIntegrationDataType = {
  url: string;
  usernameHost: string;
  password: string;
  setDefault: boolean;
  defaultAssignmentGroup: string;
  defaultAssignedTo: string;
  category: string;
};

export type ServiceNowDataType = {
  incidentType: string;
  assignmentGroup: string;
  assignedTo: string;
  category: string;
};

export const ServiceNowDefaultData: ServiceNowDataType = {
  incidentType: INCIDENT_TYPES.BULK_INCIDENT,
  assignmentGroup: '',
  assignedTo: '',
  category: '',
};

export const defaultFormData: TicketIntegrationDataType = {
  url: '',
  usernameHost: '',
  password: '',
  setDefault: true,
  defaultAssignmentGroup: '',
  defaultAssignedTo: '',
  category: '',
};

export type AssignmentToType = { email: string; name: string };
