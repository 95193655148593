import { PROVIDER } from 'constants/cloudProviders';
import { getMonthAndYearStringFormat } from './date';

/**
 * @function calculateIndexAndMonth
 * @description Function to add the index and convert the digit month and year to string format
 * @param data Data that needs to be formatted, prerequisite is to have the month property
 * @returns the modified list of data with string date and slNo as index.
 */
export const calculateIndexAndMonth = (data: any[]) => {
  return data.map((item, index) => ({
    ...item,
    month: item.month ? getMonthAndYearStringFormat(item.month) : undefined,
    slNo: index + 1,
  }));
};

/**
 * @function insertIndex
 * @description Function to add the index to data.
 * @param data Data that needs to be formatted
 * @returns the modified list of data with slNo as index.
 */
export const insertIndex = (data: any[]) => {
  return data.map((item, index) => ({
    ...item,
    slNo: index + 1,
  }));
};

/**
 * @function numberCommaSeparator
 * @description Function to format the numbers as comma separated and fraction digits.
 * @param value number or string value
 * @param minimumFractionDigits Minimum fraction digits.
 * @param maximumFractionDigits Maximum fraction digits.
 * @returns Modified value
 */
export const numberCommaSeparator = (
  value: string | number | undefined,
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
) => {
  let modifiedValue;
  switch (typeof value) {
    case 'string':
      modifiedValue = value.replaceAll(',', '');
      break;

    case 'number':
      modifiedValue = value;
      break;

    default:
      modifiedValue = 0;
  }

  return Number(modifiedValue).toLocaleString('en', {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });
};

/**
 * @function formatArrayToString
 * @description Function to convert string[] to string surrounded by parenthesis, command separated and double-quoted
 * @returns formatted string
 */
export const formatArrayToString = (values: string[]) => {
  return '("' + values.join('","') + '")';
};

/**
 * @function formatArrayToStringByProvider
 * @description Function to convert string[] to string supported by providers
 * @param values list of string values to be formatted
 * @param provider provider for the formatting is required
 * @returns formatted string
 */
export const formatArrayToStringByProvider = (
  values: string[],
  provider: string
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return '("' + values.join('","') + '")';

    case PROVIDER.AWS:
    case PROVIDER.AZURE:
    case PROVIDER.OCI:
      return `('${values.join("','")}')`;

    default:
      return values.join('","');
  }
};

/**
 * @function getAlphabetByIndex
 * @description Function to return the alphabet by the number index value
 * @param indexNumber number index for the alphabet
 * @returns string alphabet(s) or undefined if indexNumber is less than 0. Eg Z for 26 and AA for 27
 */
export const getAlphabetByIndex = (indexNumber: number) => {
  let s = '';
  let t = 0;

  while (indexNumber > 0) {
    t = (indexNumber - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    indexNumber = ((indexNumber - t) / 26) | 0;
  }
  return s || undefined;
};

/**
 * @function replaceAllSpecialCharactersBy
 * @description Function to replace all special characters by the replaceString
 * @param value string value that needs to be formatted
 * @param replaceString string value that needs to be replaced with
 * @returns string formatted
 */
export const replaceAllSpecialCharactersBy = (
  value: string,
  replaceString: string = ''
) => {
  return value?.replaceAll(/[^\w]/gi, replaceString);
};

/**
 * @function formatNumberToCompact
 * @description Function to format the number to compact numbers like 1K, 10M, 100B
 * @param value number value that needs to be formatted
 * @param maximumFractionDigits maximum fraction digits
 * @returns string formatted value
 * @default maximumFractionDigits=2
 */
export const formatNumberToCompact = (
  value: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
) => {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
  }).format(value);
};

/**
 * @function concatenateObjectValuesByKey
 * @description returns the map containing combined data of multiple maps with key given
 * @param objectsList List of objects to combine
 * @returns Combined map containing the data from all the given objects with key {{key}}
 */
const concatenateObjectValuesByKey = (objectsList: any[], key: string) => {
  return objectsList.reduce(
    (combinedObject, objectsList) => ({
      ...combinedObject,
      ...objectsList[key],
    }),
    {}
  );
};

/**
 * @function combineMultipleObjects
 * @description returns the combined data of multiple maps with same key
 * @param objectsList List of maps to combine
 * @returns Combined map containing the data from all the given map
 */
export const combineMultipleObjects = (objectsList: any[]) => {
  const combinedKeys = objectsList.reduce(
    (keys, template) => [...keys, ...Object.keys(template)],
    []
  );

  return Array.from<string>(new Set(combinedKeys)).reduce(
    (consolidatedObject: any, key: string) => ({
      ...consolidatedObject,
      [key]: concatenateObjectValuesByKey(objectsList, key),
    }),
    {}
  );
};

/**
 * @function toTitleCase
 * @description Returns the title case format of the sentence provided
 * @param sentence Sentence to be converted to title case
 * @returns Returns the string in Title Case.
 */
export const toTitleCase = (sentence: string) => {
  return sentence.replace(
    /\w\S*/g,
    (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
  );
};
