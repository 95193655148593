import i18n from 'i18n';

export enum SCORECARD_NAVS {
  SUMMARY = 'SUMMARY',
  PURCHASE_TACTICS = 'PURCHASE_TACTICS',
  WASTE_MANAGEMENT_EFFECTIVENESS = 'WASTE_MANAGEMENT_EFFECTIVENESS',
}

export enum SCORECARDS_TIMERANGE {
  MONTHLY = 'MONTHLY',
  DAILY = 'DAILY',
}

export const ScoreCardsTimeRangeOptions = [
  {
    value: SCORECARDS_TIMERANGE.MONTHLY,
    label: i18n.t('scorecard.monthly'),
  },
  {
    value: SCORECARDS_TIMERANGE.DAILY,
    label: i18n.t('scorecard.custom'),
  },
];

export const SCORECARD_DASHBOARD_NAVIGATION = [
  {
    id: SCORECARD_NAVS.SUMMARY,
    title: i18n.t('scorecard.views.summary'),
  },
  {
    id: SCORECARD_NAVS.PURCHASE_TACTICS,
    title: i18n.t('scorecard.views.purchaseTactics'),
  },
  {
    id: SCORECARD_NAVS.WASTE_MANAGEMENT_EFFECTIVENESS,
    title: i18n.t('scorecard.views.wasteManagementEffectiveness'),
  },
];

export enum PURCHASE_TACTICS_TAB {
  SAVINGS_EFFECTIVENESS = 'SAVINGS_EFFECTIVENESS',
  COMMITTED_USAGE_DISCOUNT = 'COMMITTED_USAGE_DISCOUNT',
  RESERVED_INSTANCE = 'RESERVED_INSTANCE',
  SAVINGS_PLAN = 'SAVINGS_PLAN',
}

export enum SAVING_PLAN_REPORT_TYPES {
  UTILIZATION = 'UTILIZATION',
  COVERAGE = 'COVERAGE',
}

export const SAVING_PLAN_REPORTS = [
  {
    value: SAVING_PLAN_REPORT_TYPES.UTILIZATION,
    label: i18n.t('scorecard.savingsTypes.utilization'),
  },
  {
    value: SAVING_PLAN_REPORT_TYPES.COVERAGE,
    label: i18n.t('scorecard.savingsTypes.coverage'),
  },
];

export enum GCP_SERVICES {
  CLOUD_SQL = 'Cloud SQL',
  CLOUD_SPANNER = 'Cloud Spanner',
  VMWARE_ENGINE = 'Google Cloud VMware Engine',
  CLOUD_RUN = 'Cloud Run',
  GKE = 'Google Kubernetes Engine',
  COMPUTE_ENGINE = 'Compute Engine',
}

export enum AWS_RI_SERVICES {
  AMAZON_ELASTIC_COMPUTE_CLOUD_COMPUTE = 'Amazon Elastic Compute Cloud - Compute',
  AMAZON_ELASTICACHE = 'Amazon ElastiCache',
  AMAZON_MEMORY_DB = 'Amazon MemoryDB',
  AMAZON_ELASTIC_SEARCH = 'Amazon Elasticsearch Service',
  AMAZON_OPENSEARCH = 'Amazon OpenSearch Service',
  AMAZON_REDSHIFT = 'Amazon Redshift',
  AMAZON_RELATIONAL_DATABASE_SERVICE = 'Amazon Relational Database Service',
}

export enum AWS_SP_GRAPHS {
  OVERALL = 'Overall',
  COMPUTE_EC2 = 'Compute + EC2',
  SAGE_MAKER = 'SageMaker',
}

export enum AWS_SP_UTILIZATION_PLANS {
  COMPUTE_SAVINGS_PLANS = 'ComputeSavingsPlans',
  EC2_INSTANCE_SAVINGS_PLANS = 'EC2InstanceSavingsPlans',
  SAGE_MAKER_SAVINGS_PLANS = 'SageMakerSavingsPlans',
}

export enum AWS_SP_COVERAGE_SERVICES {
  AMAZON_ELASTIC_COMPUTE_CLOUD = 'Amazon Elastic Compute Cloud - Compute',
  AMAZON_EC2_CONTAINER_SERVICE = 'Amazon EC2 Container Service',
  AMAZON_ELASTIC_CONTAINER_SERVICE = 'Amazon Elastic Container Service',
  AWS_LAMBDA = 'AWS Lambda',
  AMAZON_ELASTIC_CONTAINER_SERVICE_FOR_KUBERNETES = 'Amazon Elastic Container Service for Kubernetes',
  AMAZON_SAGE_MAKER = 'Amazon SageMaker',
}

export const AWS_SP_GRAPHS_TO_SERVICES_MAP = [
  {
    key: AWS_SP_GRAPHS.COMPUTE_EC2,
    utilization: [
      AWS_SP_UTILIZATION_PLANS.COMPUTE_SAVINGS_PLANS,
      AWS_SP_UTILIZATION_PLANS.EC2_INSTANCE_SAVINGS_PLANS,
    ],
    coverage: [
      AWS_SP_COVERAGE_SERVICES.AMAZON_ELASTIC_COMPUTE_CLOUD,
      AWS_SP_COVERAGE_SERVICES.AMAZON_EC2_CONTAINER_SERVICE,
      AWS_SP_COVERAGE_SERVICES.AMAZON_ELASTIC_CONTAINER_SERVICE,
      AWS_SP_COVERAGE_SERVICES.AWS_LAMBDA,
      AWS_SP_COVERAGE_SERVICES.AMAZON_ELASTIC_CONTAINER_SERVICE_FOR_KUBERNETES,
    ],
  },
  {
    key: AWS_SP_GRAPHS.SAGE_MAKER,
    utilization: [AWS_SP_UTILIZATION_PLANS.SAGE_MAKER_SAVINGS_PLANS],
    coverage: [AWS_SP_COVERAGE_SERVICES.AMAZON_SAGE_MAKER],
  },
];
