import { Select } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getAzureDatabaseTables } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

export const RecommendationDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection } = useSelector(cloudConnection);

  const [
    azureRecommendationDatasetRequiredValidation,
    setAzureRecommendationDatasetRequiredValidation,
  ] = useState('');
  const [
    azureRecommendationDatasetTableOptions,
    setAzureRecommendationDatasetTableOptions,
  ] = useState<string[]>([]);
  const [
    azureRecommendationDatasetTableOptionsRequestStatus,
    setAzureRecommendationDatasetTableOptionsRequestStatus,
  ] = useState('');

  /**
   * @function fetchAzureRecommendationDatabaseTables
   * @description Function to fetch recommendation dataset tables
   */
  const fetchAzureRecommendationDatabaseTables = () => {
    if (
      currentConnection?.azureTenantId &&
      currentConnection?.azureSubscriptionId &&
      currentConnection?.azureAppId &&
      currentConnection?.azureClientSecret &&
      currentConnection?.azureRecommendationDataset
    ) {
      setAzureRecommendationDatasetTableOptionsRequestStatus(
        REQUEST_STATUS.PROCESSING
      );
      const body = isEditConnection
        ? {
            connectorId: currentConnection?.connectorId,
            billingDataset: currentConnection?.azureRecommendationDataset,
            azureSynapseWorkspace: currentConnection?.azureSynapseWorkspaceName,
            azureSqlServer: currentConnection.azureSynapseWorkspaceName,
            dataSourceType: currentConnection.dataSourceType,
          }
        : {
            azureTenantId: currentConnection?.azureTenantId,
            azureSubId: currentConnection?.azureSubscriptionId,
            azureClientId: currentConnection?.azureAppId,
            azureClientSecret: currentConnection?.azureClientSecret,
            billingDataset: currentConnection?.azureRecommendationDataset,
            azureSynapseWorkspace: currentConnection?.azureSynapseWorkspaceName,
            azureSqlServer: currentConnection.azureSynapseWorkspaceName,
            dataSourceType: currentConnection.dataSourceType,
          };
      getAzureDatabaseTables(body)
        .then((res: any) => {
          setAzureRecommendationDatasetTableOptions(res.data.responseData);
          setAzureRecommendationDatasetTableOptionsRequestStatus(
            REQUEST_STATUS.SUCCESS
          );
        })
        .catch((e) =>
          onApiCallError(
            e,
            true,
            setAzureRecommendationDatasetTableOptionsRequestStatus
          )
        );
    }
  };

  const getDropdown = (options: any[]) => (
    <>
      {options?.map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </>
  );

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`azureConnectionCSPForm.azureRecommendationDataset`)}
        />
        <Input
          default
          type="input"
          value={currentConnection?.azureRecommendationDataset}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                azureRecommendationDataset: e.target.value,
              })
            );
            setAzureRecommendationDatasetRequiredValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t(`azureConnectionCSPForm.azureRecommendationDataset`),
              setAzureRecommendationDatasetRequiredValidation
            );
            fetchAzureRecommendationDatabaseTables();
          }}
          data-testid="dataset-input"
        />
        <span
          style={{
            display: `${
              azureRecommendationDatasetRequiredValidation ? 'inline' : 'none'
            }`,
          }}
          className="font-validation-error"
        >
          {azureRecommendationDatasetRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`azureConnectionCSPForm.azureRecommendationDatasetTable`)}
        />
        <SelectDropdown
          value={currentConnection?.azureRecommendationDatasetTable}
          menu={getDropdown(azureRecommendationDatasetTableOptions)}
          loading={
            azureRecommendationDatasetTableOptionsRequestStatus ===
            REQUEST_STATUS.PROCESSING
          }
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                azureRecommendationDatasetTable: value,
              })
            );
          }}
          designVersion2
        />
      </div>
    </div>
  );
};
