import { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DashboardComponent from 'components/DashboardComponent';
import NavigationPath from 'components/NavigationPath';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import { REQUEST_STATUS } from 'constants/requestBody';
import { userAuthorization } from 'redux/authorizationSlice';
import { tcoDashboard } from 'redux/tcoDashboardSlice';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import TcoDashboardHeader from './components/TcoDashboardHeader';
import { TCO_HORIZONTAL_NAV_MENU_LIST } from './constants';
import TcoCostSummary from './components/TcoCostSummaryDashboard';

import './index.scss';

const TcoDashboardPage = () => {
  const { t } = useTranslation();
  const { selectedTcoMenu } = useSelector(tcoDashboard);

  const [isEmptyDashboard, setIsEmptyDashboard] = useState(false);
  const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.PROCESSING);
  const { permissions } = useSelector(userAuthorization);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  if (!permissions.costControlList) {
    return (
      <div className="page-content">
        {t('dashboardLabels.costControlPermission')}
      </div>
    );
  }

  if (isEmptyDashboard) {
    return (
      <div className="page-content">
        {t('dashboardLabels.noCostControlListMessage')}
      </div>
    );
  }

  return (
    <div className="tco-dashboard">
      <div className="tco-dashboard-header new-page-header">
        <TcoDashboardHeader
          setIsEmptyDashboard={setIsEmptyDashboard}
          setRequestStatus={setRequestStatus}
        />
      </div>
      <div className="page-content">
        <div className="navigation">
          <NavigationPath />
          <HorizontalNavigationMenu
            selectedKeys={[selectedTcoMenu]}
            menuItems={TCO_HORIZONTAL_NAV_MENU_LIST.map((navMenu) => (
              <Menu.Item
                key={navMenu.id}
                eventKey={navMenu.id}
                className={`font-caption-bold menu ${
                  navMenu.id === selectedTcoMenu && 'active-menu'
                }`}
              >
                {navMenu.label}
              </Menu.Item>
            ))}
          />
        </div>
        <DashboardComponent
          component={<TcoCostSummary />}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default TcoDashboardPage;
