import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CONTAINER_INSIGHTS_NAVS } from 'pages/ContainerInsightsPage/constants';
import { ConnectionListType } from 'types/dashboard';
import { RootState } from './store';

export interface ContainerInsightsState {
  selectedConnection: ConnectionListType | null;
  containerCostConnectionList: ConnectionListType[];
  selectedContainerCostDashboardView: string;
}

const initialState: ContainerInsightsState = {
  selectedConnection: null,
  containerCostConnectionList: [],
  selectedContainerCostDashboardView: CONTAINER_INSIGHTS_NAVS.USAGE_METERING,
};

export const containerInsightsSlice = createSlice({
  name: 'containerInsightsSlice',
  initialState,
  reducers: {
    setSelectedConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.selectedConnection = action.payload;
    },
    setContainerCostConnectionList: (
      state,
      action: PayloadAction<ConnectionListType[]>
    ) => {
      state.containerCostConnectionList = action.payload;
    },
    setSelectedContainerCostDashboardView: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedContainerCostDashboardView = action.payload;
    },
  },
});

export const selectContainerInsights = (state: RootState) =>
  state.containerInsights;
export const {
  setSelectedConnection,
  setContainerCostConnectionList,
  setSelectedContainerCostDashboardView,
} = containerInsightsSlice.actions;
export default containerInsightsSlice.reducer;
