import { getValidationStyle } from 'utils/validations';

type FieldValidationMessageProps = {
  message: string;
};

const FieldValidationMessage = ({ message }: FieldValidationMessageProps) => {
  return (
    <span
      style={{
        display: getValidationStyle(message),
      }}
      className="font-validation-error"
    >
      {message}
    </span>
  );
};

export default FieldValidationMessage;
