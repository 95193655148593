import {
  AWS_CHECK_IDS,
  GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES,
} from 'constants/recommendations';
import {
  COMPARATORS,
  CONJUNCTIONS,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';

export enum AWS_WASTE_MANAGEMENT_ITEMS {
  IDLE_RDS_DB_INSTANCE = 'IDLE_RDS_DB_INSTANCE',
  IDLE_LOAD_BALANCER = 'IDLE_LOAD_BALANCER',
  UNDERUTILIZED_EC2_INSTANCES = 'UNDERUTILIZED_EC2_INSTANCES',
  // IDLE_IP_ADDRESS = 'IDLE_IP_ADDRESS',
  UNDERUTILIZED_EBS_VOLUMES = 'UNDERUTILIZED_EBS_VOLUMES',
  UNDERUTILIZED_REDSHIFT_CLUSTERS = 'UNDERUTILIZED_REDSHIFT_CLUSTERS',
}

export enum WASTE_MANAGEMENT_SUB_ITEMS {
  TOTAL_COST = 'Total Cost',
  COST_OF_WASTE = 'Estimated Cost of Waste',
}

export const AWS_WASTE_MANAGEMENT_ITEM_LABELS = [
  {
    value: AWS_WASTE_MANAGEMENT_ITEMS.IDLE_RDS_DB_INSTANCE,
    label: 'RDS DB Instance',
  },
  {
    value: AWS_WASTE_MANAGEMENT_ITEMS.IDLE_LOAD_BALANCER,
    label: 'Load Balancer',
  },
  {
    value: AWS_WASTE_MANAGEMENT_ITEMS.UNDERUTILIZED_EC2_INSTANCES,
    label: 'EC2 Instances',
  },
  {
    value: 'IDLE_IP_ADDRESS',
    label: 'IP Address',
  },
  {
    value: AWS_WASTE_MANAGEMENT_ITEMS.UNDERUTILIZED_EBS_VOLUMES,
    label: 'EBS Volumes',
  },
  {
    value: AWS_WASTE_MANAGEMENT_ITEMS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
    label: 'Redshift Clusters',
  },
];

export enum GCP_WASTE_MANAGEMENT_ITEMS {
  UNDERUTILIZED_COMPUTE_INSTANCES = 'UNDERUTILIZED_COMPUTE_INSTANCES',
  UNDERUTILIZED_PD_VOLUMES = 'UNDERUTILIZED_PD_VOLUMES',
  IDLE_IP_ADDRESS = 'IDLE_IP_ADDRESS',
  IDLE_LOAD_BALANCER = 'IDLE_LOAD_BALANCER',
  IDLE_CLOUD_SQL_DB_INSTANCE = 'IDLE_CLOUD_SQL_DB_INSTANCE',
}

export const GCP_WASTE_MANAGEMENT_ITEM_LABELS = [
  {
    value: GCP_WASTE_MANAGEMENT_ITEMS.UNDERUTILIZED_COMPUTE_INSTANCES,
    label: 'Compute Instance',
  },
  {
    value: GCP_WASTE_MANAGEMENT_ITEMS.UNDERUTILIZED_PD_VOLUMES,
    label: 'PD Volumes',
  },
  {
    value: GCP_WASTE_MANAGEMENT_ITEMS.IDLE_IP_ADDRESS,
    label: 'IP Address',
  },
  {
    value: GCP_WASTE_MANAGEMENT_ITEMS.IDLE_LOAD_BALANCER,
    label: 'Load Balancer',
  },
  {
    value: GCP_WASTE_MANAGEMENT_ITEMS.IDLE_CLOUD_SQL_DB_INSTANCE,
    label: 'Cloud SQL DB Instance',
  },
];

export const GCP_WM_QUERY_DATA = [
  {
    id: GCP_WASTE_MANAGEMENT_ITEMS.UNDERUTILIZED_COMPUTE_INSTANCES,
    recommender: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_INSTANCE,
    totalCostFilters: [
      {
        field: QUERY_FIELDS.SERVICE_DESCRIPTION,
        comparator: COMPARATORS.EQUALS,
        value: 'Compute Engine',
        conjunctToNextFilter: CONJUNCTIONS.AND,
      },
      {
        field: QUERY_FIELDS.SKU_DESCRIPTION,
        comparator: COMPARATORS.NOT_LIKE,
        value: "('%PD%')",
        conjunctToNextFilter: CONJUNCTIONS.AND,
      },
      {
        field: QUERY_FIELDS.SKU_DESCRIPTION,
        comparator: COMPARATORS.NOT_LIKE,
        value: "('%IP%')",
      },
    ],
  },
  {
    id: GCP_WASTE_MANAGEMENT_ITEMS.UNDERUTILIZED_PD_VOLUMES,
    recommender: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_DISKS,
    totalCostFilters: [
      {
        field: QUERY_FIELDS.SERVICE_DESCRIPTION,
        comparator: COMPARATORS.EQUALS,
        value: 'Compute Engine',
        conjunctToNextFilter: CONJUNCTIONS.AND,
      },
      {
        field: QUERY_FIELDS.SKU_DESCRIPTION,
        comparator: COMPARATORS.LIKE,
        value: "('%PD%')",
      },
    ],
  },
  {
    id: GCP_WASTE_MANAGEMENT_ITEMS.IDLE_IP_ADDRESS,
    recommender: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_IP_ADDRESSES,
    totalCostFilters: [
      {
        field: QUERY_FIELDS.SKU_DESCRIPTION,
        comparator: COMPARATORS.LIKE,
        value: "('%Ip%')",
        conjunctToNextFilter: CONJUNCTIONS.OR,
      },
      {
        field: QUERY_FIELDS.SKU_DESCRIPTION,
        comparator: COMPARATORS.LIKE,
        value: "('%IP%')",
      },
    ],
  },
  {
    id: GCP_WASTE_MANAGEMENT_ITEMS.IDLE_LOAD_BALANCER,
    recommender: GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_IMAGES,
    totalCostFilters: [
      {
        field: QUERY_FIELDS.SERVICE_DESCRIPTION,
        comparator: COMPARATORS.EQUALS,
        value: 'Compute Engine',
        conjunctToNextFilter: CONJUNCTIONS.AND,
      },
      {
        field: QUERY_FIELDS.SKU_DESCRIPTION,
        comparator: COMPARATORS.LIKE,
        value: "('%Storage Image%')",
      },
    ],
  },
  {
    id: GCP_WASTE_MANAGEMENT_ITEMS.IDLE_CLOUD_SQL_DB_INSTANCE,
    recommender:
      GCP_WASTE_MANAGEMENT_RECOMMENDATION_TYPES.IDLE_CLOUD_SQL_INSTANCES,
    totalCostFilters: [
      {
        field: QUERY_FIELDS.SKU_DESCRIPTION,
        comparator: COMPARATORS.LIKE,
        value: "('%Cloud SQL%')",
      },
    ],
  },
];

export const AWS_WM_TOTAL_COST_QUERY_DATA = [
  {
    checkId: AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
    filters: [
      {
        field: QUERY_FIELDS.PRODUCT_SERVICENAME,
        comparator: COMPARATORS.IN,
        value: QUERY_VALUES.AMAZON_ELASTIC_COMPUTE_CLOUD,
      },
      {
        field: QUERY_FIELDS.LINE_ITEM_USAGE_TYPE,
        comparator: COMPARATORS.LIKE,
        value: '%BoxUsage%',
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE,
    filters: [
      {
        field: QUERY_FIELDS.PRODUCT_SERVICENAME,
        comparator: COMPARATORS.IN,
        value: QUERY_VALUES.AMAZON_RELATIONAL_DATABASE_SERVICE,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES,
    filters: [
      {
        field: QUERY_FIELDS.LINE_ITEM_USAGE_TYPE,
        comparator: COMPARATORS.EQUALS,
        value: QUERY_VALUES.EBS_VOLUME_USAGE_GP2,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.IDLE_LOAD_BALANCER,
    filters: [
      {
        field: QUERY_FIELDS.PRODUCT_SERVICENAME,
        comparator: COMPARATORS.IN,
        value: QUERY_VALUES.ELASTIC_LOAD_BALANCING,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
    filters: [
      {
        field: QUERY_FIELDS.PRODUCT_SERVICENAME,
        comparator: COMPARATORS.IN,
        value: QUERY_VALUES.AMAZON_REDSHIFT,
      },
    ],
  },
];
