import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  importStaticFiles,
  setImportFileData,
} from 'redux/importStaticFileSlice';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import SelectDropdown from 'components/Select';
import { validateDuplicateTransactionName } from 'pages/StaticFilesPage/services';
import { DATASET_TYPES } from 'constants/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import { providerList } from 'redux/providerSlice';
import { PROVIDER } from 'constants/cloudProviders';

type BasicDetailsProps = {
  setIsDuplicateTransactionName: (val: boolean) => void;
};

const BasicDetails = ({ setIsDuplicateTransactionName }: BasicDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { importFileData } = useSelector(importStaticFiles);
  const { selectedProvider } = useSelector(providerList);

  const [
    transactionNameValidationMessage,
    setTransactionNameValidationMessage,
  ] = useState('');
  const [typeOdDatasetValidationMessage, setTypeOdDatasetValidationMessage] =
    useState('');

  const validateTransactionName = (transactionName: string) => {
    setIsDuplicateTransactionName(true);
    if (
      validateEmptyField(
        transactionName,
        t('importFileLabels.formLabels.step2.transactionName'),
        setTransactionNameValidationMessage
      )
    ) {
      return true;
    }

    if (
      !validateAlphanumericNames(
        transactionName,
        t('importFileLabels.formLabels.step2.transactionName'),
        setTransactionNameValidationMessage
      )
    ) {
      return true;
    }

    if (validateStringLengthLessThan(transactionName, MAX_CHARACTER_LIMIT)) {
      setTransactionNameValidationMessage(
        t('importFileLabels.formLabels.step2.transactionNameLengthValidation')
      );
      return true;
    }

    setTransactionNameValidationMessage('');
    return false;
  };

  const isDuplicateTransactionName = (transactionName: string) => {
    const requestBody = {
      transactionName: transactionName.trim(),
    };

    validateDuplicateTransactionName(requestBody)
      .then((res: any) => {
        if (res.status === 200) {
          setIsDuplicateTransactionName(res.data.responseData);
          if (res.data.responseData) {
            setTransactionNameValidationMessage(
              t('importFileLabels.formLabels.step2.duplicateTransactionName')
            );
            return;
          }
          setTransactionNameValidationMessage('');
          return;
        }
        setIsDuplicateTransactionName(false);
        setTransactionNameValidationMessage(
          t('importFileLabels.formLabels.step2.errorValidatingTransactionName')
        );
      })
      .catch((e: any) => {
        onApiCallError(e);
        setIsDuplicateTransactionName(false);
        setTransactionNameValidationMessage(
          t('importFileLabels.formLabels.step2.errorValidatingTransactionName')
        );
      });
  };

  const validateTransactionNameOnBlur = (transactionName: string) => {
    if (validateTransactionName(transactionName)) {
      return;
    }

    isDuplicateTransactionName(transactionName);
  };

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('importFileLabels.formLabels.step2.transactionName')}
          required={true}
        />
        <Input
          placeholder={t(
            'importFileLabels.formLabels.step2.transactionNamePlaceholder'
          )}
          value={importFileData.displayName}
          onChange={(e: any) => {
            validateTransactionName(e.target.value);
            dispatch(
              setImportFileData({
                ...importFileData,
                displayName: e.target.value,
              })
            );
          }}
          onBlur={(e: any) => validateTransactionNameOnBlur(e.target.value)}
        />
        <span
          style={{
            display: `${getValidationStyle(transactionNameValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {transactionNameValidationMessage}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('importFileLabels.formLabels.step2.typeOfDataset')}
          required={true}
        />
        <SelectDropdown
          value={importFileData.typeOfDataset ?? undefined}
          options={DATASET_TYPES.filter((option) =>
            option.supportedClouds.includes(selectedProvider as PROVIDER)
          )}
          placeholder={t(
            'importFileLabels.formLabels.step2.typeOfDatasetPlaceholder'
          )}
          onSelect={(value: any) => {
            dispatch(
              setImportFileData({
                ...importFileData,
                typeOfDataset: value,
              })
            );
            setTypeOdDatasetValidationMessage('');
          }}
          onBlur={() =>
            importFileData.typeOfDataset.length > 0
              ? setTypeOdDatasetValidationMessage('')
              : setTypeOdDatasetValidationMessage(
                  t(
                    'importFileLabels.formLabels.step2.typeOfDatasetReqValidation'
                  )
                )
          }
          designVersion2
        />
        <span
          style={{
            display: `${getValidationStyle(typeOdDatasetValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {typeOdDatasetValidationMessage}
        </span>
      </div>
    </div>
  );
};

export default BasicDetails;
