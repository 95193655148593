import { useEffect, useState } from 'react';
import { Divider, Empty, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormLabel } from 'components/FormLabel';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import Select from 'components/Select';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import AccessibleDiv from 'components/AccessibleDiv';
import { getValidationStyle } from 'utils/validations';
import { getGcpProjectDatasets } from 'pages/ConnectingCSPPage/services';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

const GCPContainerCostDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection } = useSelector(cloudConnection);

  const [selectedProject, setSelectedProject] = useState('');
  const [projectsDatasetValidation, setProjectsDatasetValidation] =
    useState('');
  const [projectDatasetDataRequestStatus, setProjectDatasetDataRequestStatus] =
    useState('');
  const [projectDatasetData, setProjectDatasetData] = useState<any[]>([]);

  useEffect(() => fetchProjectsDatasets(), []);

  /**
   * @function fetchProjectsDatasets
   * @description fetches the projects and datasets to fill the dropdowns
   */
  const fetchProjectsDatasets = () => {
    setProjectDatasetDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = isEditConnection
      ? {
          connectorId: currentConnection?.connectorId,
        }
      : {
          jsonFileId: currentConnection?.jsonFileId,
        };
    getGcpProjectDatasets(params)
      .then((res: any) => {
        setProjectDatasetDataRequestStatus(REQUEST_STATUS.SUCCESS);
        setProjectDatasetData(res.data.responseData || []);
      })
      .catch((e) =>
        onApiCallError(e, true, setProjectDatasetDataRequestStatus)
      );
  };

  /**
   * @function onChangeCheckbox
   * @description Adds or removes a container project in redux based on the value
   * @param checked The checkbox value.
   * @param project The project that has to be added or removed
   */
  const onChangeCheckbox = (checked: boolean, project: string) => {
    setSelectedProject(project);
    if (checked) {
      dispatch(
        setCurrentConnection({
          ...currentConnection,
          wantContainerCost: true,
          projectDatasetMap: [
            ...(currentConnection?.projectDatasetMap || []),
            { project: project, dataset: '' },
          ],
        })
      );
      return;
    }
    const updatedArray = [
      ...(currentConnection?.projectDatasetMap?.filter(
        (data: any) => data.project !== project
      ) ?? []),
    ];
    dispatch(
      setCurrentConnection({
        ...currentConnection,
        wantContainerCost: updatedArray.length,
        projectDatasetMap: updatedArray.length ? updatedArray : undefined,
      })
    );
  };

  /**
   * @function onChangeDatasetRadio
   * @description Adds a container dataset for the selectedProject in redux
   * @param dataset The dataset to be added.
   */
  const onChangeDatasetRadio = (dataset: string) => {
    setProjectsDatasetValidation('');
    dispatch(
      setCurrentConnection({
        ...currentConnection,
        projectDatasetMap: [
          ...(currentConnection?.projectDatasetMap?.filter(
            (data: any) => data.project !== selectedProject
          ) || []),
          { project: selectedProject, dataset: dataset },
        ],
      })
    );
  };

  /**
   * @function validateProjectsDataset
   * @description Validates the fields and adds validation message
   */
  const validateProjectsDataset = () => {
    if (
      currentConnection?.projectDatasetMap?.some(
        (data: any) => data.dataset === ''
      )
    )
      setProjectsDatasetValidation(
        t('connectionCSPForm.projectDatasetValidationMessage')
      );
    else setProjectsDatasetValidation('');
  };

  /**
   * @function getProjectsDropdownRender
   * @description Function to return the component for projects dropdown
   * @returns JSX element
   */
  const getProjectsDropdownRender = () => (
    <div className="projects-dataset-dropdown flex flex-row flex-gap-8">
      <div className="flex flex-fit flex-column flex-gap-8">
        <span className="font-caption-bold">
          {t(`connectionCSPForm.projects`)}
        </span>
        {projectDatasetData.map((data) => (
          <div
            key={data.project}
            className="flex flex-row flex-align-items-center flex-gap-8"
          >
            <Checkbox
              checked={currentConnection?.projectDatasetMap?.some(
                (currentData: any) => currentData.project === data.project
              )}
              disabled={data.dataset.length === 0}
              onChange={(e: CheckboxChangeEvent) => {
                onChangeCheckbox(e.target.checked, data.project);
              }}
            />
            <AccessibleDiv
              className={`flex-fit font-caption cursor-pointer ${
                data.project === selectedProject && 'selected'
              }`}
              onClick={() => setSelectedProject(data.project)}
            >
              {data.project}
            </AccessibleDiv>
            <Icon
              iconName={ICONS.ARROW_RIGHT_S_LINE}
              className="chevron-right"
            />
          </div>
        ))}
      </div>
      <Divider
        type="vertical"
        className={`dropdown-divider ${!selectedProject && 'flex-fit'}`}
      />
      {selectedProject && (
        <div className="flex flex-fit flex-column flex-gap-8">
          <span className={`font-caption-bold `}>
            {t(`connectionCSPForm.dataset`)}
          </span>
          {projectDatasetData
            .filter((data) => data.project === selectedProject)
            .at(0)?.dataset.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('connectionCSPForm.noDatasetAvailableMessage', {
                project: selectedProject,
              })}
            />
          ) : (
            <div className="flex flex-row flex-align-items-center flex-gap-8">
              <Radio.Group
                value={
                  currentConnection?.projectDatasetMap
                    ?.filter((data: any) => data.project === selectedProject)
                    .at(0)?.dataset
                }
                onChange={(e) => {
                  onChangeDatasetRadio(e.target.value);
                }}
              >
                <div className="flex flex-fit flex-column flex-gap-8">
                  {projectDatasetData
                    .filter((data) => data.project === selectedProject)
                    .at(0)
                    ?.dataset?.map((data: string) => (
                      <Radio key={data} value={data}>
                        <span className="font-caption">{data}</span>
                      </Radio>
                    ))}
                </div>
              </Radio.Group>
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t(`connectionCSPForm.gcpContainerCostDataset`)} />
        <Select
          placeholder={t(
            'connectionCSPForm.gcpContainerCostDatasetPlaceholder'
          )}
          designVersion2
          loading={
            projectDatasetDataRequestStatus === REQUEST_STATUS.PROCESSING
          }
          value={currentConnection?.projectDatasetMap
            ?.map((data: any) => data.project)
            .toString()}
          onBlur={validateProjectsDataset}
          dropdownRender={getProjectsDropdownRender}
        />
        <span
          style={{
            display: `${getValidationStyle(projectsDatasetValidation)}`,
          }}
          className="font-validation-error"
        >
          {projectsDatasetValidation}
        </span>
      </div>
    </div>
  );
};

export default GCPContainerCostDetails;
