import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';

import {
  selectPurchase,
  setAwsMemoryDbRisCartData,
  setAwsRegions,
} from 'redux/purchaseSlice';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import { TERMS_LIST } from 'pages/PurchasePage/constants';
import { PurchaseAwsMemoryDbRisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import {
  AWS_MEMORY_DB_RI_DEFAULT_VALUES,
  RI_PAYMENT_OPTIONS_LIST,
} from 'pages/PurchasePage/components/ReservedInstances/constants';
import {
  getAwsMemoryDbRiNodeTypes,
  getAwsMemoryDbRiOfferingDetails,
} from 'pages/PurchasePage/components/ReservedInstances/services';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import RadioGroup from 'components/RadioGroup';
import {
  convertYearToSeconds,
  getAverageMonthlyRecurringCost,
  getSubTotalDue,
} from 'pages/PurchasePage/components/ReservedInstances/utils';
import { fetchAwsRegions } from 'pages/PurchasePage/services';
import { isEmptyField, isNumber, validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

type BuyMemoryDbRiFormModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  editData?: PurchaseAwsMemoryDbRisType;
  indexPosition?: number;
};

const BuyMemoryDbRiFormModal = ({
  show,
  setShow,
  editData,
  indexPosition,
}: BuyMemoryDbRiFormModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, awsMemoryDbRiCartData, awsRegions } =
    useSelector(selectPurchase);

  const [memoryDbRiFormData, setMemoryDbRiFormData] =
    useState<PurchaseAwsMemoryDbRisType>(AWS_MEMORY_DB_RI_DEFAULT_VALUES);
  const [nodeTypes, setNodeTypes] = useState<string[]>();
  const [fetchNodeTypesReqStatus, setFetchNodeTypesReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchRegionReqStatus, setFetchRegionReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [nodeTypeValidation, setNodeTypeValidation] = useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [paymentOptionValidation, setPaymentOptionValidation] = useState('');
  const [quantityValidation, setQuantityValidation] = useState('');
  const [offeringDetailsRequestStatus, setOfferingDetailsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [offeringDetailsValidation, setOfferingDetailsValidation] =
    useState('');
  const [editLoaded, setEditLoaded] = useState(false);

  useEffect(() => {
    if (editData) {
      setMemoryDbRiFormData(editData);
    }

    if (awsRegions.length === 0) {
      getAwsRegions();
    }
  }, []);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (validateRegion(memoryDbRiFormData.region, false)) {
      fetchNodeTypes(source);
    }

    return () => {
      source.cancel();
    };
  }, [memoryDbRiFormData.region]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (editLoaded) {
      setMemoryDbRiFormData({
        ...memoryDbRiFormData,
        hourlyUsageCharge: 0,
        oneTimePayment: 0,
        offeringId: '',
      });
      setOfferingDetailsValidation('');
    } else {
      setEditLoaded(true);
    }

    if (validateAllFields(false)) {
      fetchOfferingDetails(source);
    }

    return () => {
      source.cancel();
    };
  }, [
    memoryDbRiFormData.nodeType,
    memoryDbRiFormData.region,
    memoryDbRiFormData.term,
    memoryDbRiFormData.paymentOption,
    memoryDbRiFormData.quantity,
  ]);

  /**
   * @function fetchNodeTypes
   * @description Function to fetch node types data
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchNodeTypes = (cancelTokenSource: CancelTokenSource) => {
    setFetchNodeTypesReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      region: memoryDbRiFormData.region,
    };

    getAwsMemoryDbRiNodeTypes(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        setNodeTypes(res.data.responseData || []);
        setFetchNodeTypesReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchNodeTypesReqStatus);
      });
  };

  /**
   * @function fetchRegions
   * @description Function to fetch regions data
   */
  const getAwsRegions = () => {
    setFetchRegionReqStatus(REQUEST_STATUS.PROCESSING);

    fetchAwsRegions({ connectorId: selectedConnection?.connectorId })
      .then((res: any) => {
        const responseData = res.data.responseData || [];
        dispatch(
          setAwsRegions(
            responseData?.filter(
              (item: string) =>
                !['af-south-1', 'ap-northeast-3', 'me-south-1'].includes(item)
            )
          )
        );
        setFetchRegionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchRegionReqStatus);
      });
  };

  /**
   * @function fetchOfferingDetails
   * @description Function to fetch the offering details
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchOfferingDetails = (cancelTokenSource: CancelTokenSource) => {
    setOfferingDetailsRequestStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedConnection?.connectorId,
      nodeType: memoryDbRiFormData.nodeType,
      region: memoryDbRiFormData.region,
      term: convertYearToSeconds(memoryDbRiFormData.term),
      offeringType: memoryDbRiFormData.paymentOption,
      quantity: Number(memoryDbRiFormData.quantity),
    };

    getAwsMemoryDbRiOfferingDetails(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          if (data.offeringId) {
            setMemoryDbRiFormData({
              ...memoryDbRiFormData,
              hourlyUsageCharge: data?.hourlyUsageCharge ?? 0,
              oneTimePayment: data?.oneTimePayment ?? 0,
              offeringId: data?.offeringId ?? '',
            });
            setOfferingDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
            return;
          }
          setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
          setOfferingDetailsValidation(
            t('purchasePage.reservedInstancesLabels.offeringIdNullError')
          );
          return;
        }
        setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
        setOfferingDetailsValidation(
          t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      })
      .catch((e) => {
        onApiCallError(e, false, setOfferingDetailsRequestStatus);
        if (!axios.isCancel(e)) {
          setOfferingDetailsValidation(
            e?.response?.data?.message ||
              t('purchasePage.reservedInstancesLabels.offeringDetailsError')
          );
        }
      });
  };

  /**
   * @function validateNodeType
   * @description Function to validate the node type field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateNodeType = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.reservedInstancesLabels.nodeType`),
      setNodeTypeValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateRegion
   * @description Function to validate the region field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateRegion = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.region`),
      setRegionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validatePaymentOption
   * @description Function to validate the payment option field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validatePaymentOption = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.cart.paymentOptions`),
      setPaymentOptionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateQuantityField
   * @description Function to validate the quantity field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityField = (
    value: number | string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (
      !validateEmptyField(
        value,
        t(`purchasePage.reservedInstancesLabels.quantity`),
        setQuantityValidation,
        addErrorMessage
      )
    ) {
      return (
        isNumber(
          value?.toString(),
          false,
          t(`purchasePage.reservedInstancesLabels.quantity`),
          addErrorMessage ? setQuantityValidation : undefined
        ) && validateQuantityRangeLimit(value, addErrorMessage)
      );
    }

    return false;
  };

  /**
   * @function validateQuantityRangeLimit
   * @description Function to validate the quantity limit and range
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateQuantityRangeLimit = (
    value: string | number | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (!value || !isNumber(value.toString(), false)) {
      return false;
    }

    if (Number(value) <= 0) {
      addErrorMessage &&
        setQuantityValidation(
          t('purchasePage.reservedInstancesLabels.quantityLimitError')
        );
      return false;
    }

    addErrorMessage && setQuantityValidation('');

    return true;
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAllFields = (addErrorMessage: boolean = true) => {
    let validation = true;

    if (!validateNodeType(memoryDbRiFormData?.nodeType, addErrorMessage)) {
      validation = false;
    }

    if (!validateRegion(memoryDbRiFormData.region, addErrorMessage)) {
      validation = false;
    }

    if (
      !validatePaymentOption(memoryDbRiFormData?.paymentOption, addErrorMessage)
    ) {
      validation = false;
    }

    if (!validateQuantityField(memoryDbRiFormData?.quantity, addErrorMessage)) {
      validation = false;
    }

    if (
      addErrorMessage &&
      validation &&
      isEmptyField(memoryDbRiFormData?.offeringId)
    ) {
      setOfferingDetailsValidation(
        t('purchasePage.reservedInstancesLabels.missingOfferingDetails')
      );
      validation = false;
    }

    return validation;
  };

  /**
   * @function onClickBuy
   * @description Callback function for buy button click
   */
  const onClickBuy = () => {
    if (!validateAllFields()) {
      return;
    }

    if (editData !== undefined && indexPosition !== undefined) {
      const cartData = [...awsMemoryDbRiCartData];
      cartData.splice(indexPosition, 1, memoryDbRiFormData);
      dispatch(setAwsMemoryDbRisCartData(cartData));
    } else {
      dispatch(
        setAwsMemoryDbRisCartData([
          ...awsMemoryDbRiCartData,
          memoryDbRiFormData,
        ])
      );
    }
    setShow(false);
  };

  return (
    <DrawerComponent
      className="memory-db-ri-purchase-form"
      width={481}
      open={show}
      title={`${t(
        'purchasePage.reservedInstancesLabels.purchaseReservedInstances'
      )} - ${t('purchasePage.reservedInstancesLabels.memoryDb')}`}
      onClose={() => setShow(false)}
      footer={
        <PurchaseFooter
          totalHeading={t('purchasePage.cart.subTotalDueNow')}
          totalValue={getSubTotalDue(
            memoryDbRiFormData.term,
            memoryDbRiFormData.hourlyUsageCharge
          )}
          costSummary={[
            {
              key: t(
                'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
              ),
              value: getAverageMonthlyRecurringCost(
                memoryDbRiFormData.term,
                memoryDbRiFormData.hourlyUsageCharge
              ),
            },
            {
              key: t('purchasePage.reservedInstancesLabels.oneTimePayment'),
              value: memoryDbRiFormData?.oneTimePayment,
            },
          ]}
          okTitle={
            editData
              ? t('purchasePage.cart.update')
              : t('purchasePage.cart.buy')
          }
          onClickCancel={() => setShow(false)}
          onClickOk={onClickBuy}
          loading={offeringDetailsRequestStatus === REQUEST_STATUS.PROCESSING}
          validationMessage={offeringDetailsValidation}
        />
      }
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.region')} required={true} />
          <SelectDropdown
            value={memoryDbRiFormData?.region}
            options={awsRegions.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t('purchasePage.cart.selectRegion')}
            loading={fetchRegionReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setMemoryDbRiFormData({
                ...memoryDbRiFormData,
                region: value,
              });
              validateRegion(value);
            }}
            onBlur={() => {
              validateRegion(memoryDbRiFormData?.region);
            }}
            designVersion2
          />
          <span
            style={{
              display: regionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {regionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.nodeType')}
            required={true}
          />
          <SelectDropdown
            value={memoryDbRiFormData?.nodeType}
            options={nodeTypes?.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t(
              'purchasePage.reservedInstancesLabels.selectNodeType'
            )}
            loading={fetchNodeTypesReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setMemoryDbRiFormData({
                ...memoryDbRiFormData,
                nodeType: value,
              });
              validateNodeType(value);
            }}
            onBlur={() => {
              validateNodeType(memoryDbRiFormData?.nodeType);
            }}
            designVersion2
          />
          <span
            style={{
              display: nodeTypeValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {nodeTypeValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.term')} required={true} />

          <RadioGroup
            options={TERMS_LIST}
            onChange={(e: any) => {
              setMemoryDbRiFormData({
                ...memoryDbRiFormData,
                term: e.target.value,
              });
            }}
            value={memoryDbRiFormData.term}
            optionType="button"
            style={{ height: 28 }}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.cart.paymentOptions')}
            required={true}
          />
          <SelectDropdown
            value={memoryDbRiFormData?.paymentOption}
            options={RI_PAYMENT_OPTIONS_LIST}
            placeholder={t('purchasePage.cart.selectPaymentOption')}
            onSelect={(value: any) => {
              setMemoryDbRiFormData({
                ...memoryDbRiFormData,
                paymentOption: value,
              });
              validatePaymentOption(value);
            }}
            onBlur={() => {
              validatePaymentOption(memoryDbRiFormData?.paymentOption);
            }}
            designVersion2
          />
          <span
            style={{
              display: paymentOptionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {paymentOptionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.reservedInstancesLabels.quantity')}
            required={true}
          />
          <Input
            value={memoryDbRiFormData?.quantity}
            type="stepNumber"
            rootClassName="width-30"
            steps={1}
            min={1}
            onChange={(value: number) => {
              setMemoryDbRiFormData({
                ...memoryDbRiFormData,
                quantity: value,
              });
              validateQuantityField(value);
            }}
            onBlur={() => {
              validateQuantityField(memoryDbRiFormData?.quantity);
            }}
          />
          <span
            style={{
              display: quantityValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {quantityValidation}
          </span>
        </div>
      </section>
    </DrawerComponent>
  );
};

export default BuyMemoryDbRiFormModal;
