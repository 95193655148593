import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getGCPZonesData
 * @description Function to get the zones for GCP connection based on connectorId
 * @param params object containing connectorId and optional projectId
 * @return axios response from GET request
 */
export const getGCPZonesData = async (params: {
  connectorId: string;
  projectId?: string;
}) => {
  return ServiceCalls.get(APIS.GPROFILER_GCP_ZONES, null, params);
};

/**
 * @function getGCPClusterData
 * @description Function to get the cluster based on connectorId and zone
 * @param connectorId string key parameter for searching
 * @param zone string key parameter for searching
 * @return axios response from GET request
 */
export const getGCPClusterData = async (connectorId: string, zone: string) => {
  const api = `${APIS.GPROFILER_GCP_CLUSTER}?connectorId=${connectorId}&zoneId=${zone}`;
  return ServiceCalls.get(api);
};

/**
 * @function getGCPServiceData
 * @description Function to get service data for GCP
 * @return axios response from GET request
 */
export const getGCPServiceData = async () => {
  return ServiceCalls.get(APIS.GPROFILER_GCP_SERVICE_LIST);
};

/**
 * @function installGCPGprofiler
 * @description Function to get install gProfiler for GCP
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const installGCPGprofiler = async (body: any) => {
  return ServiceCalls.post(APIS.GPROFILER_GCP_INSTALL, body);
};

/**
 * @function getAWSServiceData
 * @description Function to get service data for AWS
 * @return axios response from GET request
 */
export const getAWSServiceData = async () => {
  return ServiceCalls.get(APIS.GPROFILER_AWS_SERVICE_LIST);
};
/**
 * @function getAWSZonesData
 * @description Function to get the zones for AWS connection based on connectorId
 * @param connectorId string key parameter for searching
 * @return axios response from GET request
 */
export const getAWSZonesData = async (connectorId: string) => {
  const api = `${APIS.GPROFILER_AWS_ZONES}?connectorId=${connectorId}`;
  return ServiceCalls.get(api);
};

/**
 * @function getAWSClusterData
 * @description Function to get the cluster based on connectorId and zone
 * @param connectorId string key parameter for searching
 * @param zone string key parameter for searching
 * @return axios response from GET request
 */
export const getAWSClusterData = async (connectorId: string, zone: string) => {
  const api = `${APIS.GPROFILER_AWS_CLUSTER}?connectorId=${connectorId}&region=${zone}`;
  return ServiceCalls.get(api);
};

/**
 * @function installAWSGprofiler
 * @description Function to get install gProfiler for AWS
 * @return axios response from POST request
 */
export const installAWSGprofiler = async (body: any) => {
  return ServiceCalls.post(APIS.GPROFILER_AWS_INSTALL, body);
};

/**
 * @function updateServiceStatus
 * @description Function to update the gprofiler service status
 * @param params Object containing the query params
 * @return axios response from POST request
 */
export const updateServiceStatus = async (params: any) => {
  return ServiceCalls.put(
    APIS.GPROFILER_UPDATE_SERVICE_STATUS,
    null,
    null,
    false,
    params
  );
};
