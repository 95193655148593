import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import SelectDropdown from 'components/Select';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { ConnectionListType } from 'types/dashboard';
import { getProviderSmallLogo } from 'utils/providerDetails';

import './index.scss';

const { Option } = Select;

type ConnectionListDropdownProps = {
  additionalClassName?: string;
  selectedConnection: ConnectionListType | null;
  onSelectDropdown: (key: string) => void;
  connectionList: ConnectionListType[];
  loadingDropdown?: boolean;
  additionalDropdownProps?: SelectProps;
};

const ConnectionListDropdown = ({
  additionalClassName,
  selectedConnection,
  onSelectDropdown,
  connectionList,
  loadingDropdown,
  additionalDropdownProps,
}: ConnectionListDropdownProps) => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);

  const Menu = (
    <>
      {connectionList?.map((connection) => {
        return (
          <Option value={connection?.connectorId} key={connection?.connectorId}>
            <div className="menu-item flex flex-align-items-center flex-space-between">
              <div className="menu-content flex flex-align-items-center flex-gap-8">
                <div className="logo">
                  <img
                    src={getProviderSmallLogo(connection?.provider)}
                    alt={`${connection?.provider} Logo`}
                  />
                </div>
                <div
                  className={`${
                    selectedConnection?.connectorId ===
                      connection?.connectorId && 'selected-dropdown-option'
                  }`}
                >
                  {connection?.displayName}
                </div>
              </div>
              {selectedConnection?.connectorId === connection?.connectorId && (
                <Icon
                  iconName={ICONS.CHECK_LINE}
                  className="selected"
                  color={theme.buttonIconColor}
                />
              )}
            </div>
          </Option>
        );
      })}
    </>
  );

  return (
    <div className={`connection-dropdown ${additionalClassName}`}>
      <SelectDropdown
        rootClassName="design-v2"
        value={selectedConnection?.connectorId}
        onSelect={onSelectDropdown}
        menu={Menu}
        loading={loadingDropdown}
        placeholder={t('connectionDropdown.selectConnection')}
        {...additionalDropdownProps}
      />
    </div>
  );
};

export default ConnectionListDropdown;
