import ChartWithTableWrapper from 'components/ChartWithTableWrapper';
import LineChart from 'components/LineChart';
import { CostByNameType, TypeAndNameByCost } from 'types/dataTypes';
import { DashboardFiltersListType } from 'types/dashboard';

type LineChartWithTableProps = {
  graphTitle: string;
  graphName: string;
  data: (CostByNameType | TypeAndNameByCost)[];
  tableData?: any[];
  chartProperties: {
    xTitle: string;
    yTitle: string;
  };
  filters?: DashboardFiltersListType[];
  columns: any[];
  requestStatus: string;
  isTableView: boolean;
  setIsTableView: (val: boolean) => void;
  pdfView: boolean;
  isFullWidth?: boolean;
  prefixSymbol?: string;
  suffixSymbol?: string;
};

const LineChartWithTable = ({
  graphTitle,
  graphName,
  data,
  tableData,
  chartProperties,
  filters,
  columns,
  requestStatus,
  isTableView,
  setIsTableView,
  pdfView,
  isFullWidth = true,
  prefixSymbol,
  suffixSymbol,
}: LineChartWithTableProps) => {
  return (
    <ChartWithTableWrapper
      graphTitle={graphTitle}
      graphName={graphName}
      columns={columns}
      data={data}
      tableData={tableData}
      filters={filters}
      requestStatus={[requestStatus]}
      isTableView={isTableView}
      setIsTableView={setIsTableView}
      pdfView={pdfView}
      isFullWidth={isFullWidth}
    >
      <LineChart
        data={data}
        xField="name"
        yField="cost"
        groupingField="type"
        xTitle={chartProperties.xTitle}
        yTitle={chartProperties.yTitle}
        prefixSymbol={prefixSymbol}
        postfixSymbol={suffixSymbol}
        disableAnimation={pdfView}
      />
    </ChartWithTableWrapper>
  );
};

export default LineChartWithTable;
