import i18n from 'i18n';
import {
  AwsCostAndCreditBreakdownType,
  CreditDistributionByLocationTypes,
  MonthlyCreditCostMetricType,
} from 'types/dataTypes';
import { insertIndex } from 'utils/dataFormatterUtils';
import {
  CostAndCreditBreakDownExportColumns,
  CreditDiscountBreakdownExportColumns,
  CreditDistributionByRegionExportColumns,
} from '../constants';

export const getAWSCreditDistributionByRegionExcelData = (
  connectorName: string,
  creditDistributionByLocation: CreditDistributionByLocationTypes[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.creditDistributionbyRegion'),
    columns: CreditDistributionByRegionExportColumns,
    data: insertIndex(creditDistributionByLocation),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

export const getAWSCostAndCreditBreakDownExcelData = (
  connectorName: string,
  costAndCreditBreakDownData: AwsCostAndCreditBreakdownType[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.costandCreditBreakdown'),
    columns: CostAndCreditBreakDownExportColumns,
    data: insertIndex(costAndCreditBreakDownData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

export const getAWSCreditDiscountBreakdownExcelData = (
  connectorName: string,
  creditDiscountBreakdownData: any[],
  monthlyCreditByCostTypeData: MonthlyCreditCostMetricType[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.creditDiscountBreakdown'),
    columns: CreditDiscountBreakdownExportColumns(monthlyCreditByCostTypeData),
    data: insertIndex(creditDiscountBreakdownData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};

export const getAWSCreditSummaryExcelData = (
  connectorName: string,
  creditDistributionByLocation: CreditDistributionByLocationTypes[],
  costAndCreditBreakDownData: AwsCostAndCreditBreakdownType[],
  creditDiscountBreakdownData: any[],
  monthlyCreditByCostTypeData: MonthlyCreditCostMetricType[]
) => {
  return [
    getAWSCreditDistributionByRegionExcelData(
      connectorName,
      creditDistributionByLocation
    ),
    getAWSCostAndCreditBreakDownExcelData(
      connectorName,
      costAndCreditBreakDownData
    ),
    getAWSCreditDiscountBreakdownExcelData(
      connectorName,
      creditDiscountBreakdownData,
      monthlyCreditByCostTypeData
    ),
  ];
};
