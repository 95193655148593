import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Checkbox from 'components/Checkbox';
import { ICONS } from 'constants/icons';
import { FieldSource } from 'constants/dashboard';
import { COMPARATORS, CONJUNCTIONS } from 'constants/requestBody';
import { customDashboard } from 'redux/customDashboardSlice';
import { ColumnType, FilterType } from 'types/dashboard';
import {
  getFirstTableFilterGroup,
  updateFilterQuery,
} from 'pages/CustomDashboardPage/utils';

import FilterList from '../FilterList';

import './index.scss';

const Filters = () => {
  const { t } = useTranslation();
  const { selectedDimensions, customViewData } = useSelector(customDashboard);
  const [showDimensionsDropdown, setShowDimensionsDropdown] = useState(false);
  const [newFilters, setNewFilters] = useState<FilterType[]>([
    ...getFirstTableFilterGroup(),
  ]);

  useEffect(() => {
    setNewFilters([...getFirstTableFilterGroup()]);
  }, [customViewData]);

  const onChangeMetricsCheckbox = (e: any, dimension: ColumnType) => {
    let filters = [...newFilters];
    if (!e.target.checked) {
      filters = filters?.filter((filter) => filter.field !== dimension.field);
    } else {
      filters.push({
        field: dimension.field,
        comparator: COMPARATORS.IN,
        value: '',
        conjunctToNextFilter: CONJUNCTIONS.AND,
      });
    }

    setNewFilters(filters);
  };

  return (
    <div className="table-filters">
      <div className="filters-heading flex flex-align-items-center flex-space-between">
        <div className="font-caption-bold">
          {t('customDashboard.optionsLabels.filter')}
        </div>
        {getFirstTableFilterGroup().length < 3 &&
        selectedDimensions.filter(
          (column) =>
            !getFirstTableFilterGroup()
              .map((filter) => filter.field)
              .includes(column.field) &&
            column.dimensionType !== FieldSource.TAGS
        ).length ? (
          <Tooltip
            overlayClassName="metrics-dropdown"
            overlay={
              <div className="metrics-group flex flex-column flex-gap-8 new-styled-scroll">
                {selectedDimensions
                  .filter((column) => column.dimensionType !== FieldSource.TAGS)
                  .map((column) => (
                    <Checkbox
                      key={column.field}
                      checked={newFilters
                        .map((filter) => filter.field)
                        .includes(column.field)}
                      onChange={(e) => onChangeMetricsCheckbox(e, column)}
                    >
                      {column.label}
                    </Checkbox>
                  ))}
              </div>
            }
            placement="bottomRight"
            open={showDimensionsDropdown}
            onOpenChange={(visible: any) => {
              setShowDimensionsDropdown(visible);
              if (!visible) {
                updateFilterQuery(newFilters);
              }
            }}
            trigger="click"
            arrow={false}
          >
            <Icon className="add-icon" iconName={ICONS.ADD_LINE} />
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
      <FilterList />
    </div>
  );
};

export default Filters;
