export const STATIC_COLORS_FOR_CHART = [
  {
    colorName: 'Grey',
    hexCode: '#0A101A',
  },
  {
    colorName: 'Blue',
    hexCode: '#036FE3',
  },
  {
    colorName: 'Green',
    hexCode: '#62AD41',
  },
  {
    colorName: 'Red',
    hexCode: '#DA294A',
  },
  {
    colorName: 'Purple',
    hexCode: '#8553E7',
  },
  {
    colorName: 'Orange',
    hexCode: '#CC4500',
  },
  {
    colorName: 'Yellow',
    hexCode: '#FFC835',
  },
  {
    colorName: 'Aqua',
    hexCode: '#35CFFF',
  },
  {
    colorName: 'Magenta',
    hexCode: '#E151D3',
  },
  {
    colorName: 'UFO Green',
    hexCode: '#51E18B',
  },
  {
    colorName: 'Mariner',
    hexCode: '#5193E1',
  },
  {
    colorName: 'Viking',
    hexCode: '#51DCE1',
  },
  {
    colorName: 'Lima',
    hexCode: '#AAE151',
  },
];

export const CUSTOM_PALLETE_NAME_FIELD = 'CUSTOM';

export const DEFAULT_THEME = {
  templateName: 'Deloitte',
  primaryColor: '#86BC25',
  secondaryColor: '#8d686d',
  buttonIconColor: '#4E8934',
  backgroundColor: '#f5f6f5',
  textColor: '#0A101A',
};
