import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectScorecard } from 'redux/scorecardSlice';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import {
  AWS_SP_GRAPHS,
  AWS_SP_GRAPHS_TO_SERVICES_MAP,
  PURCHASE_TACTICS_TAB,
  SAVING_PLAN_REPORT_TYPES,
} from 'pages/ScorecardPage/constants';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import {
  getAWSSPCoverageDetails,
  getAWSSPUtilizationDetails,
} from 'pages/ScorecardPage/services';
import { getReportType } from 'pages/ScorecardPage/utils';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MonthValueType } from 'types/dataTypes';
import {
  getDateMonthShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
} from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import CoverageUtilizationGraph from '../CoverageUtilizationGraph';
import { UtilizationCoverageExportColumns } from '../../constants';
import { getAwsSpExcelData } from './utils';

const SavingsPlan = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, selectedPurchaseTacticsTab } =
    useSelector(selectScorecard);
  const { tableViewEnabled, showExpandGraphModal, expandedGraphName } =
    useSelector(selectDashboard);

  // Overall
  const [overallMonthlyCoverage, setOverallMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [overallMonthlyUtilization, setOverallMonthlyUtilization] = useState<
    MonthValueType[]
  >([]);
  const [overallTableView, setOverallTableView] = useState(false);
  const [overallReportType, setOverallReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [overallUtilizationRequestStatus, setOverallUtilizationRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [overallCoverageRequestStatus, setOverallCoverageRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  // Compute and EC2
  const [computeAndEc2MonthlyCoverage, setComputeAndEc2MonthlyCoverage] =
    useState<MonthValueType[]>([]);
  const [computeAndEc2MonthlyUtilization, setComputeAndEc2MonthlyUtilization] =
    useState<MonthValueType[]>([]);
  const [computeAndEc2TableView, setComputeAndEc2TableView] = useState(false);
  const [computeAndEc2ReportType, setComputeAndEc2ReportType] =
    useState<string>(SAVING_PLAN_REPORT_TYPES.UTILIZATION);
  const [
    computeAndEc2UtilizationRequestStatus,
    setComputeAndEc2UtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [
    computeAndEc2CoverageRequestStatus,
    setComputeAndEc2CoverageRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  // SageMaker
  const [sageMakerMonthlyCoverage, setSageMakerMonthlyCoverage] = useState<
    MonthValueType[]
  >([]);
  const [sageMakerMonthlyUtilization, setSageMakerMonthlyUtilization] =
    useState<MonthValueType[]>([]);
  const [sageMakerTableView, setSageMakerTableView] = useState(false);
  const [sageMakerReportType, setSageMakerReportType] = useState<string>(
    SAVING_PLAN_REPORT_TYPES.UTILIZATION
  );
  const [
    sageMakerUtilizationRequestStatus,
    setSageMakerUtilizationRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [sageMakerCoverageRequestStatus, setSageMakerCoverageRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  useEffect(() => {
    if (selectedConnection) {
      getUtilizationData();
      getCoverageData();
    }
  }, [selectedConnection]);

  useEffect(() => {
    setOverallTableView(tableViewEnabled);
    setComputeAndEc2TableView(tableViewEnabled);
    setSageMakerTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    if (
      selectedConnection &&
      selectedPurchaseTacticsTab === PURCHASE_TACTICS_TAB.SAVINGS_PLAN
    ) {
      dispatch(
        setExportToExcelData(
          getAwsSpExcelData(
            getExcelExportData(
              AWS_SP_GRAPHS.OVERALL,
              t('scorecard.savingsPlan.overall')
            ),
            getExcelExportData(
              AWS_SP_GRAPHS.COMPUTE_EC2,
              t('scorecard.savingsPlan.computePlusEc2')
            ),
            getExcelExportData(
              AWS_SP_GRAPHS.SAGE_MAKER,
              t('scorecard.savingsPlan.sageMaker')
            ),
            selectedConnection
          )
        )
      );
    }
  }, [
    selectedConnection,
    selectedPurchaseTacticsTab,
    overallMonthlyUtilization,
    overallMonthlyCoverage,
    computeAndEc2MonthlyUtilization,
    computeAndEc2MonthlyCoverage,
    sageMakerMonthlyUtilization,
    sageMakerMonthlyCoverage,
  ]);

  /**
   * @function getUtilizationData
   * @description Function to fetch the utilization details for all the services
   */
  const getUtilizationData = () => {
    fetchUtilizationDetails(
      setOverallMonthlyUtilization,
      setOverallUtilizationRequestStatus
    );

    fetchUtilizationDetails(
      setComputeAndEc2MonthlyUtilization,
      setComputeAndEc2UtilizationRequestStatus,
      AWS_SP_GRAPHS_TO_SERVICES_MAP.find(
        (item) => item.key === AWS_SP_GRAPHS.COMPUTE_EC2
      )?.utilization
    );

    fetchUtilizationDetails(
      setSageMakerMonthlyUtilization,
      setSageMakerUtilizationRequestStatus,
      AWS_SP_GRAPHS_TO_SERVICES_MAP.find(
        (item) => item.key === AWS_SP_GRAPHS.SAGE_MAKER
      )?.utilization
    );
  };

  /**
   * @function getCoverageData
   * @description Function to fetch the coverage details for all the services
   */
  const getCoverageData = () => {
    fetchCoverageDetails(
      setOverallMonthlyCoverage,
      setOverallCoverageRequestStatus
    );

    fetchCoverageDetails(
      setComputeAndEc2MonthlyCoverage,
      setComputeAndEc2CoverageRequestStatus,
      AWS_SP_GRAPHS_TO_SERVICES_MAP.find(
        (item) => item.key === AWS_SP_GRAPHS.COMPUTE_EC2
      )?.coverage
    );

    fetchCoverageDetails(
      setSageMakerMonthlyCoverage,
      setSageMakerCoverageRequestStatus,
      AWS_SP_GRAPHS_TO_SERVICES_MAP.find(
        (item) => item.key === AWS_SP_GRAPHS.SAGE_MAKER
      )?.coverage
    );
  };

  /**
   * @function fetchUtilizationDetails
   * @description Function to fetch the utilization details
   * @param setData setter method for utilization data
   * @param setRequestStatus setter method for request status
   * @param savingsPlanTypes list of savings plans
   */
  const fetchUtilizationDetails = (
    setData: (val: MonthValueType[]) => void,
    setRequestStatus: (val: string) => void,
    savingsPlanTypes?: string[]
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      startDate: moment().subtract(11, 'months').format(HYPHEN_DATE_FORMAT),
      endDate: moment().format(HYPHEN_DATE_FORMAT),
      granularity: 'MONTHLY',
      savingsPlanType: savingsPlanTypes?.toString(),
    };

    getAWSSPUtilizationDetails(params)
      .then((res: any) => {
        getAndSetDataFromResponse(res, setData, setRequestStatus);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  /**
   * @function fetchCoverageDetails
   * @description Function to fetch the coverage details
   * @param setData setter method for coverage data
   * @param setRequestStatus setter method for request status
   * @param services list of services
   */
  const fetchCoverageDetails = (
    setData: (val: MonthValueType[]) => void,
    setRequestStatus: (val: string) => void,
    services?: string[]
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      startDate: moment().subtract(11, 'months').format(HYPHEN_DATE_FORMAT),
      endDate: moment().format(HYPHEN_DATE_FORMAT),
      granularity: 'MONTHLY',
      service: services?.join(','),
    };

    getAWSSPCoverageDetails(params)
      .then((res: any) => {
        getAndSetDataFromResponse(res, setData, setRequestStatus);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  /**
   * @function getAndSetDataFromResponse
   * @description Function to get the data, modify and set the data from response
   * @param res Response from API call
   * @param setData setter method for data
   * @param setRequestStatus setter method for request status
   */
  const getAndSetDataFromResponse = (
    res: any,
    setData: (val: MonthValueType[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    const data = Object.entries<number>(res?.data?.responseData || {});
    const dateLabels = getDateMonthShortList(
      moment().subtract(11, 'months').format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
      'month',
      MONTH_YEAR_FORMAT
    );

    const formattedData = dateLabels.map((date) => {
      const currentDateData = data.find(
        (item: any) => moment(item[0]).format(MONTH_YEAR_FORMAT) === date
      );

      return {
        month: date,
        value: currentDateData ? currentDateData[1] : 0,
      };
    });

    setData(formattedData);
    setRequestStatus(REQUEST_STATUS.SUCCESS);
  };

  /**
   * @function getTrendGraphTitle
   * @description Function to return the trend graph title
   * @param reportType Type of report UTILIZATION or COVERAGE
   * @returns string title
   */
  const getTrendGraphTitle = (reportType: string) => {
    return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
      ? t('scorecard.savingsPlan.spUtilizationByMonth')
      : t('scorecard.savingsPlan.spCoverageByMonth');
  };

  /**
   * @function getGaugeGraphTitle
   * @description Function to return the gauge graph title
   * @param reportType Type of report UTILIZATION or COVERAGE
   * @returns string title
   */
  const getGaugeGraphTitle = (reportType: string) => {
    return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
      ? t('scorecard.savingsPlan.spMonthlyAverageUtilization')
      : t('scorecard.savingsPlan.spMonthlyAverageCoverage');
  };

  /**
   * @function getGraphData
   * @description Function to return the chart data
   * @param graph graph for which the data is fetched
   * @param reportType report type for which the data is fetched. UTILIZATION or COVERAGE
   * @returns list of coverage or utilization data
   */
  const getGraphData = (graph: string, reportType: string) => {
    switch (graph) {
      case AWS_SP_GRAPHS.OVERALL:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? overallMonthlyUtilization
          : overallMonthlyCoverage;

      case AWS_SP_GRAPHS.COMPUTE_EC2:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? computeAndEc2MonthlyUtilization
          : computeAndEc2MonthlyCoverage;

      case AWS_SP_GRAPHS.SAGE_MAKER:
        return reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? sageMakerMonthlyUtilization
          : sageMakerMonthlyCoverage;

      default:
        return [];
    }
  };

  /**
   * @function getIsTableViewByGraph
   * @description Function to return if the table view is enabled or not
   * @param graph graph for which the data is required
   * @returns boolean value, true if enabaled else false
   */
  const getIsTableViewByGraph = (graph: string) => {
    switch (graph) {
      case AWS_SP_GRAPHS.OVERALL:
        return overallTableView;

      case AWS_SP_GRAPHS.COMPUTE_EC2:
        return computeAndEc2TableView;

      case AWS_SP_GRAPHS.SAGE_MAKER:
        return sageMakerTableView;
    }
  };

  /**
   * @function getGraphHeading
   * @description Function to return the graph heading
   * @param graph graph for which the data is required
   * @returns string graph heading
   */
  const getGraphHeading = (graph: string) => {
    switch (graph) {
      case AWS_SP_GRAPHS.OVERALL:
        return t('scorecard.savingsPlan.overall');

      case AWS_SP_GRAPHS.COMPUTE_EC2:
        return t('scorecard.savingsPlan.computePlusEc2');

      case AWS_SP_GRAPHS.SAGE_MAKER:
        return t('scorecard.savingsPlan.sageMaker');

      default:
        return '';
    }
  };

  /**
   * @function getAveragePercentage
   * @description Function to return the average percentage of utilization or coverage
   * @param data list of data for which the average is calculated
   * @returns average percentage data
   */
  const getAveragePercentage = (data: MonthValueType[]) => {
    return data.reduce((sum, item) => sum + item.value, 0) / (data.length || 1);
  };

  /**
   * @function getPdfTableData
   * @description Function to insert index and format data for pdf export
   * @param data Data to be manipulated
   * @returns list of data for pdf export
   */
  const getPdfTableData = (data: MonthValueType[]) => {
    return insertIndex(data).map((item) => ({
      ...item,
      value: numberCommaSeparator(item.value),
    }));
  };

  /**
   * @function getExcelExportData
   * @description Function to ireturn the data for excel export
   * @param graph Graph for which the data is required
   * @param graphHeading heading of the graph
   * @returns Object containing the utilization and coverage data for excel export
   */
  const getExcelExportData = (graph: string, graphHeading: string) => {
    return {
      utilization: {
        sheetName: `${graphHeading} (${t(
          'scorecard.savingsTypes.utilization'
        )})`,
        ...getCoverageUtilizationData(graph).utilization,
      },
      coverage: {
        sheetName: `${graphHeading} (${t('scorecard.savingsTypes.coverage')})`,
        ...getCoverageUtilizationData(graph).coverage,
      },
    };
  };

  /**
   * @function tooltipFormulaeContent
   * @description Function to return the tooltip content formulae for calculating utilization or coverage
   * @param reportType type of report selected
   * @returns JSX Element containing the formulae for calculating utilization or coverage
   */
  const tooltipFormulaeContent = (reportType: string) => (
    <div className="table-typography flex flex-align-items-center flex-gap-4">
      <div className="font-button">
        {reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
          ? t('scorecard.savingsTypes.utilization')
          : t('scorecard.savingsTypes.coverage')}
        =
      </div>
      <div>
        <span>{t('scorecard.savingsPlan.spCoveredCosts')}</span>
        <Divider className="formulae-divider" />
        <span>
          {reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
            ? t('scorecard.savingsPlan.onDemandSpendEquivalent')
            : t('scorecard.savingsPlan.totalCostAtOnDemandRates')}
        </span>
      </div>
      <span>*100</span>
    </div>
  );

  /**
   * @function getCoverageUtilizationData
   * @description Function to return the utilization and coverage data combined
   * @param graph graph for which the data is required
   * @returns Object containing the utilization and coverage data
   */
  const getCoverageUtilizationData = (graph: string) => {
    return {
      utilization: {
        data: getGraphData(graph, SAVING_PLAN_REPORT_TYPES.UTILIZATION),
        averagePercentage: getAveragePercentage(
          getGraphData(graph, SAVING_PLAN_REPORT_TYPES.UTILIZATION)
        ),
        tooltipContent: tooltipFormulaeContent(
          SAVING_PLAN_REPORT_TYPES.UTILIZATION
        ),
      },
      coverage: {
        data: getGraphData(graph, SAVING_PLAN_REPORT_TYPES.COVERAGE),
        averagePercentage: getAveragePercentage(
          getGraphData(graph, SAVING_PLAN_REPORT_TYPES.COVERAGE)
        ),
        tooltipContent: tooltipFormulaeContent(
          SAVING_PLAN_REPORT_TYPES.COVERAGE
        ),
      },
    };
  };

  /**
   * @function getCoverageUtilizationGraphComponent
   * @description Function to return the chart component
   * @param graph graph for which the chart component is fetched
   * @param reportType report type for which the chart component is fetched. UTILIZATION or COVERAGE
   * @param pdfView Optional param if the component is for pdf view
   * @returns JSX element
   */
  const getCoverageUtilizationGraphComponent = (
    graph: string,
    reportType?: string,
    pdfView: boolean = false
  ) => {
    switch (graph) {
      case AWS_SP_GRAPHS.OVERALL:
        return (
          <CoverageUtilizationGraph
            heading={getGraphHeading(graph)}
            graphName={AWS_SP_GRAPHS.OVERALL}
            pdfView={pdfView}
            isTableView={overallTableView}
            setIsTableView={setOverallTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              overallUtilizationRequestStatus,
              overallCoverageRequestStatus,
            ]}
            selectedReportType={getReportType(overallReportType, reportType)}
            setSelectedReportType={setOverallReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(overallReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(overallReportType, reportType)
            )}
          />
        );

      case AWS_SP_GRAPHS.COMPUTE_EC2:
        return (
          <CoverageUtilizationGraph
            heading={getGraphHeading(graph)}
            graphName={AWS_SP_GRAPHS.COMPUTE_EC2}
            pdfView={pdfView}
            isTableView={computeAndEc2TableView}
            setIsTableView={setComputeAndEc2TableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              computeAndEc2UtilizationRequestStatus,
              computeAndEc2CoverageRequestStatus,
            ]}
            selectedReportType={getReportType(
              computeAndEc2ReportType,
              reportType
            )}
            setSelectedReportType={setComputeAndEc2ReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(computeAndEc2ReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(computeAndEc2ReportType, reportType)
            )}
          />
        );

      case AWS_SP_GRAPHS.SAGE_MAKER:
        return (
          <CoverageUtilizationGraph
            heading={getGraphHeading(graph)}
            graphName={AWS_SP_GRAPHS.SAGE_MAKER}
            pdfView={pdfView}
            isTableView={sageMakerTableView}
            setIsTableView={setSageMakerTableView}
            coverageUtilizationData={getCoverageUtilizationData(graph)}
            requestStatus={[
              sageMakerUtilizationRequestStatus,
              sageMakerCoverageRequestStatus,
            ]}
            selectedReportType={getReportType(sageMakerReportType, reportType)}
            setSelectedReportType={setSageMakerReportType}
            trendGraphTitle={getTrendGraphTitle(
              getReportType(sageMakerReportType, reportType)
            )}
            gaugeGraphTitle={getGaugeGraphTitle(
              getReportType(sageMakerReportType, reportType)
            )}
          />
        );
    }
  };

  /**
   * @function getPdfExportContent
   * @description Function to return the pdf export content
   * @returns List of pdf content for all the graphs and report type
   */
  const getPdfExportContent = () => {
    const content: any[] = [];
    Object.values(AWS_SP_GRAPHS).forEach((graph) => {
      Object.values(SAVING_PLAN_REPORT_TYPES).forEach((reportType) => {
        content.push({
          element: getCoverageUtilizationGraphComponent(
            graph,
            reportType,
            true
          ),
          contentType: CHART_TYPES.BAR_LINE_CHART,
          graphName: graph,
          isTableView: getIsTableViewByGraph(graph),
          column: UtilizationCoverageExportColumns,
          body: getPdfTableData(getGraphData(graph, reportType)),
          tableName: `${getGraphHeading(graph)} (${
            reportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION
              ? t('scorecard.savingsTypes.utilization')
              : t('scorecard.savingsTypes.coverage')
          })`,
          tableFooterData: {
            totalTableData: [
              '',
              t('scorecard.committedUsageDiscount.monthlyAverage'),
              `${numberCommaSeparator(
                getAveragePercentage(getGraphData(graph, reportType))
              )}%`,
            ],
          },
        });
      });
    });

    return content;
  };

  return (
    <>
      <div className="flex flex-column flex-gap-16">
        {getCoverageUtilizationGraphComponent(AWS_SP_GRAPHS.OVERALL)}
        {getCoverageUtilizationGraphComponent(AWS_SP_GRAPHS.COMPUTE_EC2)}
        {getCoverageUtilizationGraphComponent(AWS_SP_GRAPHS.SAGE_MAKER)}
      </div>
      {showExpandGraphModal &&
        selectedPurchaseTacticsTab === PURCHASE_TACTICS_TAB.SAVINGS_PLAN && (
          <ExpandModal
            show={showExpandGraphModal}
            graphContent={getCoverageUtilizationGraphComponent(
              expandedGraphName
            )}
            width={'100%'}
          />
        )}
      {selectedPurchaseTacticsTab === PURCHASE_TACTICS_TAB.SAVINGS_PLAN && (
        <PdfDownloadComponent
          pdfContent={getPdfExportContent()}
          pdfMetaData={{
            viewName: t('scorecard.purchaseTactics.tabs.savingsPlan'),
            fileName: selectedConnection?.name ?? '',
            heading: selectedConnection?.name ?? '',
            subtitle1: selectedConnection?.dataSourceType ?? '',
            provider: selectedConnection?.provider ?? '',
          }}
        />
      )}
    </>
  );
};

export default SavingsPlan;
