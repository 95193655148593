import { PurchaseSavingPlansType } from '../../types';
import { getMonthlyPayment, getSubTotalDue } from '../../utils';

/**
 * @function getTotalDue
 * @description Function to calculate the total due for the cart
 * @param spCartData cart data
 * @returns total due amount calculated
 */
export const getTotalDue = (spCartData: PurchaseSavingPlansType[]) => {
  return spCartData.reduce(
    (sum, item) =>
      sum +
      getSubTotalDue(item.term, item.commitment, item.upfrontPaymentAmount),
    0
  );
};

/**
 * @function getTotalMonthlyPayment
 * @description Function to calculate the total monthly payment for the cart
 * @param spCartData cart data
 * @returns monthly payment amount calculated
 */
export const getTotalMonthlyPayment = (
  spCartData: PurchaseSavingPlansType[]
) => {
  return spCartData.reduce(
    (sum, item) =>
      sum +
      getMonthlyPayment(item.term, item.commitment, item.upfrontPaymentAmount),
    0
  );
};

/**
 * @function getTotalUpfrontCost
 * @description Function to calculate the total upfront cost for the cart
 * @param spCartData cart data
 * @returns total upfront cost calculated
 */
export const getTotalUpfrontCost = (spCartData: PurchaseSavingPlansType[]) => {
  return spCartData.reduce(
    (sum, item) =>
      sum + (item.upfrontPaymentAmount ? Number(item.upfrontPaymentAmount) : 0),
    0
  );
};
