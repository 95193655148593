import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function createTcoProject
 * @description Function to create a TCO project
 * @param body request body
 * @return axios response from POST request
 */
export const createTcoProject = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_TCO_PROJECT, body);
};

/**
 * @function updateTcoProject
 * @description Function to update a TCO project
 * @param body request body
 * @return axios response from PUT request
 */
export const updateTcoProject = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_TCO_PROJECT, body);
};

/**
 * @function deleteTcoProject
 * @description Function to delete a TCO project
 * @param params request parameters
 * @return axios response from DELETE request
 */
export const deleteTcoProject = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_TCO_PROJECT, null, params);
};
