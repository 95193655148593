export enum TAGS_AND_LABELS_COMPONENTS {
  SELECT_PROVIDER = 'SELECT_PROVIDER',
  SELECT_CONNECTION = 'SELECT_CONNECTION',
  EDIT_TAGS_LABELS = 'EDIT_TAGS_LABELS',
  APPLY_TAGS_LABELS = 'APPLY_TAGS_LABELS',
  SUCCESS_PAGE = 'SUCCESS_PAGE',
  ERROR_PAGE = 'ERROR_PAGE',
}

export enum TAGS_OBJECTIVES {
  APPLY_TAGS = 'APPLY_TAGS',
  CREATE_TAG_SCHEMA = 'CREATE_TAG_SCHEMA',
  DOWNLOAD_SCHEMA = 'DOWNLOAD_SCHEMA',
}
