import { Radio } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SnowflakeIcon } from 'assets/icons';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import QuickActionMenu from 'components/QuickActionMenu';
import AccessibleDiv from 'components/AccessibleDiv';
import {
  CUSTOM_DASHBOARD_QUICKACTIONS,
  CustomDashboardActions,
} from 'constants/quickAction';
import { userAuthorization } from 'redux/authorizationSlice';
import {
  DASHBOARD_STATUS,
  DASHBOARD_STATUS_LABEL,
  DASHBOARD_TYPE_LABEL,
} from 'pages/DashboardsPage/constants';
import { DASHBOARD_TYPE, DashboardListType } from 'types/navigationMenu';
import { getDashboardDatasourceName } from 'utils/dashboardUtils';
import { PROVIDER } from 'constants/cloudProviders';
import { SLASH_DATE_FORMAT } from 'utils/date';

import './index.scss';

type DashboardListItemProps = {
  dashboard: DashboardListType;
  selectedDashboard: DashboardListType | null;
  setCurrentDashboard: (dashboard: DashboardListType) => void;
  handleQuickAction: (action: string) => void;
  onClick: () => void;
};

const DashboardListItem = ({
  dashboard,
  selectedDashboard,
  setCurrentDashboard,
  handleQuickAction,
  onClick,
}: DashboardListItemProps) => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  /**
   * @function getProviderIconClass
   * @description Function to get cloud provider icon class name.
   * @params provider : name of the provider
   * @returns cloud provider icon class
   */
  const getProviderIconClass = (provider: string) => {
    switch (provider) {
      case PROVIDER.GCP:
        return 'gcp-icon';
      case PROVIDER.AWS:
        return 'aws-icon';
      case PROVIDER.AZURE:
        return 'azure-icon';
      case PROVIDER.OCI:
        return 'oci-icon';
    }
  };

  const dashboardQuickActionMenus = (dashboard: any) =>
    CUSTOM_DASHBOARD_QUICKACTIONS.filter((value) => {
      switch (value.id) {
        case CustomDashboardActions.PUBLISH_DRAFT:
          return (
            permissions.dashboardModify &&
            dashboard.dashboardStatus === DASHBOARD_STATUS.DRAFT
          );
        case CustomDashboardActions.DEACTIVATE:
          return (
            permissions.dashboardModify &&
            selectedDashboard?.id !== dashboard.id &&
            dashboard.dashboardStatus !== DASHBOARD_STATUS.DE_ACTIVATED &&
            dashboard.dashboardStatus !== DASHBOARD_STATUS.DRAFT
          );
        case CustomDashboardActions.ACTIVATE:
          return (
            permissions.dashboardModify &&
            dashboard.dashboardStatus !== DASHBOARD_STATUS.PUBLISH &&
            dashboard.dashboardStatus !== DASHBOARD_STATUS.DRAFT
          );
        case CustomDashboardActions.SET_AS_DEFAULT:
          return (
            !dashboard.defaultDashboard &&
            dashboard.dashboardStatus === DASHBOARD_STATUS.PUBLISH &&
            dashboard.dashBoardType !== MY_DASHBOARD_TYPES.COST_ALLOCATION
          );
        case CustomDashboardActions.DELETE:
          return (
            permissions.dashboardModify &&
            dashboard.type !== DASHBOARD_TYPE.PRE_BUILT
          );
        default:
          return true;
      }
    });

  return (
    <div className="dashboard-list-item flex flex-space-between">
      <AccessibleDiv
        className={`flex flex-center flex-gap-16 ${
          dashboard.dashboardStatus === DASHBOARD_STATUS.DE_ACTIVATED
            ? 'cursor-disabled'
            : 'cursor-pointer'
        }`}
        onClick={onClick}
      >
        <Radio checked={selectedDashboard?.id === dashboard.id} />
        {(dashboard.dashBoardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION ||
          dashboard.dashBoardType === MY_DASHBOARD_TYPES.IMPORTS) && (
          <div
            className={`logo ${getProviderIconClass(
              dashboard.connectorProvider
            )}`}
          />
        )}
        {dashboard.dashBoardType === MY_DASHBOARD_TYPES.SNOWFLAKE && (
          <img className="logo" src={SnowflakeIcon} alt="snowflake icon" />
        )}
        <div className={`dashboard-details flex flex-column flex-gap-4`}>
          <div className="dashboard-name-container flex flex-gap-8">
            <span className="font-button">{dashboard.name}</span>
            {dashboard.dashBoardType === MY_DASHBOARD_TYPES.GROUP && (
              <div className="group-logos flex flex-gap-4">
                {dashboard?.connectorProvider?.split(',')?.map((provider) => (
                  <div
                    key={provider}
                    className={`logo ${getProviderIconClass(provider)}`}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-gap-4 flex-align-items-center">
            <div
              className={`dashboard-status ${DASHBOARD_STATUS_LABEL[
                dashboard.dashboardStatus as DASHBOARD_STATUS
              ].toLowerCase()}`}
            />
            <span className="datasource-name font-caption-bold">
              {getDashboardDatasourceName(dashboard)}
            </span>
            <span className="status-chip status-chip-grey font-small-bold">
              {DASHBOARD_TYPE_LABEL[
                dashboard.type as DASHBOARD_TYPE
              ].toUpperCase()}
            </span>
            {dashboard.dashboardStatus !== DASHBOARD_STATUS.PUBLISH && (
              <span
                className={`status-chip font-small-bold ${
                  dashboard.dashboardStatus === DASHBOARD_STATUS.DE_ACTIVATED
                    ? 'status-chip-red'
                    : 'status-chip-yellow'
                }`}
              >
                {
                  DASHBOARD_STATUS_LABEL[
                    dashboard.dashboardStatus as DASHBOARD_STATUS
                  ]
                }
              </span>
            )}
            {dashboard.defaultDashboard && (
              <span className="status-chip font-small-bold status-chip-yellow">
                {t('dashboardLabels.default')}
              </span>
            )}
          </div>
        </div>
      </AccessibleDiv>
      <div className="flex flex-column flex-gap-4 flex-align-items-end">
        <QuickActionMenu
          setCurrentConnectionData={() => setCurrentDashboard(dashboard)}
          quickActions={dashboardQuickActionMenus(dashboard)}
          quickActionHandler={(action: string) => {
            handleQuickAction(action);
          }}
          disabled={dashboardQuickActionMenus(dashboard).length === 0}
        />
        <span className="weeks-label font-caption-bold">
          {moment(dashboard.updatedAt).calendar({
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[Last] dddd',
            nextWeek: '[Next] dddd',
            sameElse: SLASH_DATE_FORMAT,
          })}
        </span>
      </div>
    </div>
  );
};

export default DashboardListItem;
