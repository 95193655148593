import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CONNECTION_STATUS } from 'pages/ConnectingCSPPage/constants';
import { REFRESH_INTERVAL } from 'constants/defaultValues';
import { REQUEST_STATUS } from 'constants/requestBody';
import { setAnomalyDetectionConnectionList } from 'redux/anomalyDetectionDashboardSlice';
import DashboardComponent from 'components/DashboardComponent';
import { onApiCallError } from 'utils/handleErrors';
import { PROVIDER } from 'constants/cloudProviders';
import { fetchConnectionData } from 'utils/services';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import DashboardHeader from './components/DashboardHeader';
import AWSAnomalyDashboard from './components/AWSAnomalyDashboard';

import './index.scss';

const AnomalyDashboardPage = () => {
  const dispatch = useDispatch();

  const [fetchConnectionsRequestStatus, setFetchConnectionsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    fetchAllConnections(true);
    const interval = setInterval(() => fetchAllConnections(), REFRESH_INTERVAL);
    addZeroMarginClass();

    return () => {
      clearInterval(interval);
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function fetchAllConnections
   * @description fetches all the connections for the anomaly detection dashboard
   * @param setRequestStatus will set the request status of the api call if true
   */
  const fetchAllConnections = (setRequestStatus = false) => {
    setRequestStatus &&
      setFetchConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: PROVIDER.AWS,
    };

    fetchConnectionData(params)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(
            setAnomalyDetectionConnectionList(
              res?.data?.responseData?.content?.filter(
                (connection: any) =>
                  connection.status !== CONNECTION_STATUS.ERROR
              )
            )
          );
          setRequestStatus &&
            setFetchConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setRequestStatus &&
          setFetchConnectionsRequestStatus(REQUEST_STATUS.ERROR);
        dispatch(setAnomalyDetectionConnectionList([]));
      })
      .catch((e) => {
        onApiCallError(
          e,
          false,
          setRequestStatus ? setFetchConnectionsRequestStatus : undefined
        );
        dispatch(setAnomalyDetectionConnectionList([]));
      });
  };

  return (
    <div className="anomaly-detection-dashboard flex flex-column">
      <DashboardHeader
        fetchConnectionsRequestStatus={fetchConnectionsRequestStatus}
      />
      <div className="page-content dashboard-content">
        <DashboardComponent
          component={<AWSAnomalyDashboard />}
          requestStatus={fetchConnectionsRequestStatus}
        />
      </div>
    </div>
  );
};

export default AnomalyDashboardPage;
