import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { debounce } from 'lodash';

import { SnowflakeIcon, SuccessIcon } from 'assets/icons';
import Table from 'components/Table';
import Input from 'components/Input';
import Button from 'components/Button';
import SuccessComponent from 'components/SuccessComponent';
import ErrorComponent from 'components/ErrorComponent';
import DeleteModal from 'components/DeleteModal';
import NavigationPath from 'components/NavigationPath';
import Pagination from 'components/Pagination';
import QuickActionMenu from 'components/QuickActionMenu';
import { INPUT_SIZE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import { userAuthorization } from 'redux/authorizationSlice';
import { DATE_FORMAT, HYPHEN_DATE_FORMAT } from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import { fetchSnowflakeIntegrations } from 'utils/services';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import IntegrationForm from './components/IntegrationForm';
import { SnowflakeIntegrationType } from './types';
import {
  IntegrationsQuickActions,
  INTEGRATIONS_QUICK_ACTIONS,
  IntegrationStatus,
  INTEGRATION_STATUS_LABELS,
  IntegrationTypes,
  IntegrationsPageComponent,
} from './constants';
import { deleteSnowflakeIntegrations } from './services';

import './index.scss';

const IntegrationsPage = () => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  const [snowflakeIntegrationsList, setSnowflakeIntegrationsList] = useState<
    SnowflakeIntegrationType[]
  >([]);
  const [selectedIntegration, setSelectedIntegration] =
    useState<SnowflakeIntegrationType>();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [totalIntegrationsCount, setTotalIntegrationsCount] =
    useState<number>(0);
  const [
    loadingGetIntegrationsApiRequest,
    setLoadingGetIntegrationsApiRequest,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [integrationPageComponent, setIntegrationPageComponent] = useState(
    IntegrationsPageComponent.TABLE
  );
  const [integrationToDelete, setIntegrationToDelete] =
    useState<SnowflakeIntegrationType>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    integrationPageComponent === IntegrationsPageComponent.TABLE &&
      fetchSearchIntegrationData();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, [integrationPageComponent]);

  /**
   * @function fetchSearchIntegrationData
   * @description Function to fetch integration data with search key
   * @param searchString search string
   * @param page page number
   */
  const fetchSearchIntegrationData = (searchString = '', page: number = 1) => {
    setLoadingGetIntegrationsApiRequest(REQUEST_STATUS.PROCESSING);
    const params = {
      key: searchString,
      page: page - 1,
      size: PAGINATION_SIZE,
    };
    fetchSnowflakeIntegrations(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setSnowflakeIntegrationsList(res?.data?.responseData?.content);
          setTotalIntegrationsCount(res.data.responseData.totalElements);
          setLoadingGetIntegrationsApiRequest(REQUEST_STATUS.SUCCESS);
        } else {
          setSnowflakeIntegrationsList([]);
          setTotalIntegrationsCount(0);
          setLoadingGetIntegrationsApiRequest(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setLoadingGetIntegrationsApiRequest);
        setSnowflakeIntegrationsList([]);
        setTotalIntegrationsCount(0);
      });
  };

  /**
   * @function deleteIntegrationData
   * @description Function to fetch integration data with search key
   */
  const deleteIntegrationData = (integrationId: string) => {
    setDeleteLoadingStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      integrationId: integrationId,
    };
    deleteSnowflakeIntegrations(params)
      .then((res: any) => {
        if (res?.status === 200) {
          fetchSearchIntegrationData();
          setDeleteLoadingStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          return;
        }
        onApiCallError(res.data.message, true, setDeleteLoadingStatus);
      })
      .catch((e) => {
        onApiCallError(e, true, setDeleteLoadingStatus);
        fetchSearchIntegrationData();
      });
  };

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    fetchSearchIntegrationData(searchKey, page);
  };

  /**
   * @function handleSearchIntegrations
   * @description Function to handle integration search
   * @param e :accepts search input event
   */
  const handleSearchIntegrations = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchKey(e.target.value);
    fetchSearchIntegrationData(e.target.value);
  };

  /**
   * @function handleQuickAction
   * @description Function to handle quick action
   * @param integration integration row on which action is performed
   * @param action action to be performed
   */
  const handleQuickAction = (
    integration: SnowflakeIntegrationType,
    action: string
  ) => {
    switch (action) {
      case IntegrationsQuickActions.EDIT:
        setSelectedIntegration(integration);
        setIntegrationPageComponent(IntegrationsPageComponent.FORM);
        break;
      case IntegrationsQuickActions.DELETE:
        setIntegrationToDelete(integration);
        setShowDeleteModal(true);
        break;
    }
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + index + 1,
    },
    {
      title: () => t('integrations.integrationName'),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => (
        <div className="flex flex-gap-4 flex-align-items-center">
          {record.type === IntegrationTypes.SNOWFLAKE && (
            <img src={SnowflakeIcon} alt="snowflake icon" />
          )}
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t('integrations.createdDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => {
        return text && moment(text, HYPHEN_DATE_FORMAT).format(DATE_FORMAT);
      },
    },
    {
      title: () => t('integrations.connectionStatus'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => (
        <span
          className={`status ${
            text === IntegrationStatus.ACTIVE ? 'active' : 'deactivated'
          }`}
        >
          {INTEGRATION_STATUS_LABELS.find((item) => item.id === text)?.label ??
            text}
        </span>
      ),
    },
    {
      title: t('integrations.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_text: string, record: SnowflakeIntegrationType) => (
        <QuickActionMenu
          quickActions={INTEGRATIONS_QUICK_ACTIONS}
          quickActionHandler={(action: string) => {
            handleQuickAction(record, action);
          }}
          disabled={!permissions.cloudConnectorModify}
        />
      ),
    },
  ];

  const getComponent = () => {
    switch (integrationPageComponent) {
      case IntegrationsPageComponent.FORM:
        return (
          <IntegrationForm
            editIntegrationData={selectedIntegration}
            setIntegrationPageComponent={setIntegrationPageComponent}
          />
        );

      case IntegrationsPageComponent.TABLE:
        return (
          <>
            <div className="table-section">
              <Input
                placeholder={t('connectionTable.searchByName')}
                type="search"
                rootClassName="search-input"
                size={INPUT_SIZE.SMALL}
                onChange={debounce(
                  handleSearchIntegrations,
                  DEBOUNCE_TIME_DELAY
                )}
              />
              <Table
                pagination={false}
                dataSource={snowflakeIntegrationsList?.map(
                  (integration: any, index) => ({
                    ...integration,
                    type: IntegrationTypes.SNOWFLAKE,
                    key: index,
                  })
                )}
                columns={columns}
                designVersion2
                loading={
                  loadingGetIntegrationsApiRequest === REQUEST_STATUS.PROCESSING
                }
                locale={{
                  emptyText: loadingGetIntegrationsApiRequest !==
                    REQUEST_STATUS.PROCESSING && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        loadingGetIntegrationsApiRequest ===
                        REQUEST_STATUS.ERROR
                          ? t('graphErrorMessage')
                          : t('integrations.noIntegrationsMessage')
                      }
                    />
                  ),
                }}
              />
            </div>
            <footer className="page-footer flex">
              {totalIntegrationsCount > 0 && (
                <Pagination
                  current={currentPage}
                  onChange={onChangePagination}
                  total={totalIntegrationsCount}
                  defaultPageSize={PAGINATION_SIZE}
                />
              )}
            </footer>
          </>
        );

      case IntegrationsPageComponent.SUCCESS:
        return (
          <SuccessComponent
            mainTitle={
              selectedIntegration
                ? t('integrations.successfullyUpdated')
                : t('integrations.successfullyCreated')
            }
            buttonTitle={t('integrations.goToIntegrations')}
            linkTitle={t('integrations.addNewIntegration')}
            onHandleButtonClick={() =>
              setIntegrationPageComponent(IntegrationsPageComponent.TABLE)
            }
            onHandleLinkClick={() => {
              setIntegrationPageComponent(IntegrationsPageComponent.FORM);
              setSelectedIntegration(undefined);
            }}
            icon={<SuccessIcon />}
          />
        );

      case IntegrationsPageComponent.ERROR:
        return (
          <ErrorComponent
            mainTitle={t('integrations.errorOccurred')}
            buttonTitle={t('addBudgetAlert.errorPageLabels.tryAgainButton')}
            linkTitle={t('integrations.goToIntegrations')}
            onHandleButtonClick={() => {
              setIntegrationPageComponent(IntegrationsPageComponent.FORM);
              setSelectedIntegration(undefined);
            }}
            onHandleLinkClick={() =>
              setIntegrationPageComponent(IntegrationsPageComponent.TABLE)
            }
            additionalClassName="page-content flex-fit"
          />
        );
    }
  };

  return (
    <>
      <div className="integrations-page flex flex-column flex-fit">
        <header className="new-page-header">
          <div className="title-container flex flex-align-items-center flex-space-between">
            <span className="modal-heading">
              {t('navigationMenu.integrations')}
            </span>
            {permissions.cloudConnectorWrite &&
              integrationPageComponent === IntegrationsPageComponent.TABLE && (
                <Button
                  title={t('integrations.addIntegration')}
                  onClick={() => {
                    setSelectedIntegration(undefined);
                    setIntegrationPageComponent(IntegrationsPageComponent.FORM);
                  }}
                />
              )}
          </div>
          <NavigationPath />
        </header>
        <section className="page-content flex flex-column flex-fit">
          {getComponent()}
        </section>
      </div>
      {showDeleteModal && integrationToDelete && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={() => {
            deleteIntegrationData(integrationToDelete.integrationId ?? '');
          }}
          loading={deleteLoadingStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </>
  );
};

export default IntegrationsPage;
