import { CoverageUtilizationExcelMetaData } from 'pages/ScorecardPage/types';
import { ConnectionListType } from 'types/dashboard';
import { getUtilizationCoverageExcelData } from '../CoverageUtilizationGraph/utils';

/**
 * @function getGcpCudExcelData
 * @description Function to return the excel data for export
 * @param cloudSql Cloud SQL Utilization and Coverage data containing the sheetName, data and average
 * @param cloudSpanner CLoud Spanner Utilization and Coverage data containing the sheetName, data and average
 * @param compute Compute Utilization and Coverage data containing the sheetName, data and average
 * @param connection connection details of the selected connection
 * @returns List of excel export data
 */
export const getGcpCudExcelData = (
  cloudSql: {
    utilization: CoverageUtilizationExcelMetaData;
    coverage: CoverageUtilizationExcelMetaData;
  },
  cloudSpanner: {
    utilization: CoverageUtilizationExcelMetaData;
    coverage: CoverageUtilizationExcelMetaData;
  },
  compute: {
    utilization: CoverageUtilizationExcelMetaData;
    coverage: CoverageUtilizationExcelMetaData;
  },
  connection: ConnectionListType | null
) => {
  return [
    ...getUtilizationCoverageExcelData(
      cloudSql.utilization,
      cloudSql.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      cloudSpanner.utilization,
      cloudSpanner.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      compute.utilization,
      compute.coverage,
      connection
    ),
  ];
};
