import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import DatePicker from 'components/DatePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import DropdownCheckbox from 'components/DropdownCheckbox';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  fetchAllAzureBudgets,
  fetchAllAzureServices,
} from 'pages/BudgetsAndAlertsPage/services';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { DATE_FORMAT, HYPHEN_DATE_FORMAT, dateToday } from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';

import { TIME_RANGES } from '../SetupBudgetDetails/constants';

type ScopeFormProps = {
  setIsDuplicateAzureBudget: (value: boolean) => void;
};

const ScopeForm = ({ setIsDuplicateAzureBudget }: ScopeFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { budgetData, isEdit } = useSelector(budgetsAndAlerts);

  const [azureBudgetsList, setAzureBudgetsList] = useState<any[]>([]);
  const [allServices, setAllServices] = useState<string[]>([]);
  const [azureBudgetsListLoading, setAzureBudgetsListLoading] = useState('');
  const [servicesLoading, setServicesLoading] = useState('');
  const [budgetNameValidation, setBudgetNameValidation] = useState('');
  const [servicesReqValidation, setServicesReqValidation] = useState('');

  useEffect(() => {
    fetchAndSetAllAzureBudgets();
    fetchAzureBudgetServices();
  }, []);

  /**
   * @function fetchBudgetServices
   * @description Function to fetch the list of Azure services
   */
  const fetchAzureBudgetServices = () => {
    setServicesLoading(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: budgetData.connectorId,
    };
    fetchAllAzureServices(params)
      .then((res: any) => {
        setAllServices(res.data.responseData);
        setServicesLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setServicesLoading);
      });
  };

  /**
   * @function fetchAndSetAllAzureBudgets
   * @description Function to fetch all the azure budgets to check if the budget name is duplicate
   */
  const fetchAndSetAllAzureBudgets = async () => {
    setAzureBudgetsListLoading(REQUEST_STATUS.PROCESSING);
    try {
      const resDataAzure: any = await fetchAllAzureBudgets();
      if (resDataAzure.status === 200) {
        setAzureBudgetsListLoading(REQUEST_STATUS.SUCCESS);
        setAzureBudgetsList(resDataAzure.data.responseData);
        return;
      }
      setAzureBudgetsList([]);
      setAzureBudgetsListLoading(REQUEST_STATUS.ERROR);
    } catch (error) {
      onApiCallError(error, false, setAzureBudgetsListLoading);
      setAzureBudgetsList([]);
    }
  };

  /**
   * @function isDuplicateBudget
   * @description Function to check if the budget name is duplicate
   * @param name name of the budget
   * @returns boolean true if the budget name is duplicate else false
   */
  const isDuplicateBudget = (name: string = budgetData.displayName) => {
    const isDuplicate = azureBudgetsList.some(
      (budget: any) => budget.budgetDisplayName === name
    );
    setIsDuplicateAzureBudget(isDuplicate);
    return isDuplicate;
  };

  /**
   * @function validateBudgetName
   * @description Function to validate the budget name
   * @param value value to be validated
   * @return boolean true if the validation is successful else false
   */
  const validateBudgetName = (value: string) => {
    if (
      validateEmptyField(
        value,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    if (
      validateStringLengthLessThan(
        value,
        MAX_CHARACTER_LIMIT,
        t('addBudgetAlert.createBudgetLabels.scope.name'),
        setBudgetNameValidation
      )
    ) {
      return false;
    }

    if (isDuplicateBudget(value)) {
      setBudgetNameValidation(
        t('addBudgetAlert.createBudgetLabels.scope.duplicateName')
      );
      return false;
    }

    return true;
  };

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.name')}
          required={true}
        />
        <Input
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.namePlaceholder'
          )}
          value={budgetData.displayName}
          disabled={
            isEdit || azureBudgetsListLoading === REQUEST_STATUS.PROCESSING
          }
          onChange={(e: any) => {
            validateBudgetName(e.target.value);
            dispatch(
              setBudgetData({
                ...budgetData,
                displayName: e.target.value,
              })
            );
          }}
          onBlur={(e: any) => {
            validateBudgetName(e.target.value);
          }}
        />
        <span
          style={{
            display: `${getValidationStyle(budgetNameValidation)}`,
          }}
          className="font-validation-error"
        >
          {budgetNameValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.recentPeriod')}
          required={true}
        />
        <SelectDropdown
          value={budgetData.timeRange}
          options={TIME_RANGES}
          onChange={(value: string) => {
            dispatch(setBudgetData({ ...budgetData, timeRange: value }));
          }}
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.recentPeriodPlaceholder'
          )}
          designVersion2
        />
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.creationDate')}
          required={true}
        />
        <DatePicker
          value={
            budgetData.startDateTime
              ? moment(budgetData.startDateTime)
              : undefined
          }
          format={DATE_FORMAT}
          pickerType={DATE_PICKER_TYPE.DATE_PICKER}
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.creationDatePlaceholder'
          )}
          showToday={false}
          disabledDate={(date: any) =>
            date.isBefore(moment(), 'months') || date.date() !== 1
          }
          onChange={(value: any) => {
            dispatch(
              setBudgetData({
                ...budgetData,
                startDateTime: value?.format(HYPHEN_DATE_FORMAT),
                endDateTime: '',
              })
            );
          }}
          designVersion2
        />
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.expiryDate')}
          required={true}
        />
        <DatePicker
          value={
            budgetData.endDateTime ? moment(budgetData.endDateTime) : undefined
          }
          format={DATE_FORMAT}
          pickerType={DATE_PICKER_TYPE.DATE_PICKER}
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.scope.expiryDatePlaceholder'
          )}
          showToday={false}
          disabledDate={(date: any) =>
            date.isBefore(budgetData.startDateTime) ||
            date.isBefore(dateToday())
          }
          onChange={(value: any) =>
            dispatch(
              setBudgetData({
                ...budgetData,
                endDateTime: value?.format(HYPHEN_DATE_FORMAT),
              })
            )
          }
          designVersion2
        />
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.scope.services')}
          required={true}
        />
        <DropdownCheckbox
          loading={servicesLoading === REQUEST_STATUS.PROCESSING}
          itemOptions={allServices.map((service) => ({
            value: service,
            title: service,
          }))}
          value={budgetData.services}
          selectedItems={budgetData.services}
          setSelectedItems={(list: string[]) => {
            list.length > 0
              ? setServicesReqValidation('')
              : setServicesReqValidation(
                  t('addBudgetAlert.createBudgetLabels.scope.servicesRequired')
                );
            dispatch(setBudgetData({ ...budgetData, services: list }));
          }}
          onHandleBlur={() => {
            budgetData.services.length > 0
              ? setServicesReqValidation('')
              : setServicesReqValidation(
                  t('addBudgetAlert.createBudgetLabels.scope.servicesRequired')
                );
          }}
          designVersion2
        />
        <span
          style={{
            display: `${getValidationStyle(servicesReqValidation)}`,
          }}
          className="font-validation-error"
        >
          {servicesReqValidation}
        </span>
      </div>
    </div>
  );
};

export default ScopeForm;
