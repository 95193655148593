import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Empty, message } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { userAuthorization } from 'redux/authorizationSlice';
import {
  setCostMonitorComponent,
  setCostMonitorFormData,
  setIsEditForm,
} from 'redux/costMonitorSlice';
import { setSelectedProvider } from 'redux/providerSlice';
import { setIsTryAgain } from 'redux/cloudConnectionSlice';
import Button from 'components/Button';
import DeleteModal from 'components/DeleteModal';
import SearchInput from 'components/Input';
import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import Pagination from 'components/Pagination';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { REQUEST_STATUS } from 'constants/requestBody';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { DATE_FORMAT } from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import { CostMonitorListType } from './types';
import {
  CostMonitorComponents,
  CostMonitorsQuickActions,
  COST_MONORS_QUICKACTIONS,
  DEFAULT_COST_MONITOR_FORM_VALUES,
} from './constants';
import {
  deleteAwsMonitor,
  getAllAwsMonitors,
} from './components/AWSCostMonitorForm/services';

import './index.scss';

const CostMonitorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permissions } = useSelector(userAuthorization);

  // Table states
  const [allCostMonitorData, setAllCostMonitorData] =
    useState<CostMonitorListType[]>();
  const [filteredCostMonitorData, setFilteredCostMonitorData] =
    useState<CostMonitorListType[]>();
  const [selectedCostMonitor, setSelectedCostMonitor] =
    useState<CostMonitorListType>();
  const [
    fetchCostMonitorDataRequestStatus,
    setFetchCostMonitorDataRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  const [currentPage, setCurrentPage] = useState(1);

  // Delete states
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCostMonitorRequestStatus, setDeleteCostMonitorRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    fetchSearchCostMonitorData();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    setFilteredCostMonitorData(allCostMonitorData);
  }, [allCostMonitorData]);

  /**
   * @function handleQuickAction
   * @description Function to handle the quick action buttons from the table
   * @param action The clicked action
   */
  const handleQuickAction = (action: string, record: any) => {
    switch (action) {
      case CostMonitorsQuickActions.DELETE:
        setShowDeleteModal(true);
        return;
      case CostMonitorsQuickActions.EDIT: {
        dispatch(setCostMonitorFormData(getAwsModifiedRecord(record)));
        dispatch(setIsEditForm(true));
        dispatch(setIsTryAgain(false));
        dispatch(setSelectedProvider(record.provider));
        dispatch(
          setCostMonitorComponent(CostMonitorComponents.COST_MONITOR_FORM)
        );
        navigate(NAVIGATION_MENU_PATH.CREATE_COST_MONITOR);
        return;
      }
    }
  };

  /**
   * @function getAwsModifiedRecord
   * @description Function to modify the monitor record for edit
   * @param record data to be modified
   * @returns Modified monitor details
   */
  const getAwsModifiedRecord = (record: any) => {
    return {
      name: record.name,
      monitorARN: record.monitorARN,
      connectorName: record.connectorName,
      provider: record.provider,
      connectorId: record.connectorId,
      anomalySubscriptions: record.anomalySubscriptions?.map((item: any) => ({
        subscriptionName: item.subscriptionName,
        subscriptionArn: item.subscriptionArn,
        existingSubscription: true,
      })),
    };
  };

  /**
   * @function fetchSearchCostMonitorData
   * @description Function to fetch Cost Monitor data
   */
  const fetchSearchCostMonitorData = () => {
    if (!permissions.costControlList) {
      return;
    }

    setFetchCostMonitorDataRequestStatus(REQUEST_STATUS.PROCESSING);
    getAllAwsMonitors()
      .then((res: any) => {
        const data = res.data.responseData;
        setAllCostMonitorData(data);
        setFetchCostMonitorDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, true, setFetchCostMonitorDataRequestStatus)
      );
  };

  /**
   * @function deleteSelectedCostMonitor
   * @description Function to delete a Cost Monitor
   */
  const deleteSelectedCostMonitor = () => {
    setDeleteCostMonitorRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      monitorArn: selectedCostMonitor?.monitorARN,
      connectorId: selectedCostMonitor?.connectorId,
    };
    deleteAwsMonitor(params)
      .then((res: any) => {
        if (res.status === 200) {
          message.success(
            t('costMonitor.costMonitorTable.successfullyDeletedMonitor') +
              selectedCostMonitor?.name
          );
          setDeleteCostMonitorRequestStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          fetchSearchCostMonitorData();
        } else {
          message.error(
            t('costMonitor.costMonitorTable.errorDeletingMonitor') +
              selectedCostMonitor?.name
          );
          setDeleteCostMonitorRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteCostMonitorRequestStatus,
          t('costMonitor.costMonitorTable.errorDeletingMonitor') +
            selectedCostMonitor?.name
        );
      });
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + index + 1,
    },
    {
      title: t('costMonitor.costMonitorTable.monitorOrAlertName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('costMonitor.costMonitorTable.cloudProvider'),
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: t('costMonitor.costMonitorTable.dateCreated'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) =>
        text ? moment(text).format(DATE_FORMAT) : '--',
    },
    {
      title: t('costMonitor.costMonitorTable.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_text: string, record: CostMonitorListType) => {
        return (
          <QuickActionMenu
            setCurrentConnectionData={() => setSelectedCostMonitor(record)}
            quickActions={COST_MONORS_QUICKACTIONS}
            quickActionHandler={(action: string) => {
              handleQuickAction(action, record);
            }}
            disabled={!permissions.costControlModify}
          />
        );
      },
    },
  ];

  return (
    <div className="cost-monitor-page flex flex-column">
      <div className="cost-monitor-page-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="modal-heading">
          {t('navigationMenu.setupMonitorOrAlert')}
        </div>
        {permissions.costControlWrite && (
          <Button
            title={t('costMonitor.costMonitorTable.createMonitor')}
            onClick={() => {
              dispatch(
                setCostMonitorFormData(DEFAULT_COST_MONITOR_FORM_VALUES)
              );
              dispatch(setIsEditForm(false));
              dispatch(setIsTryAgain(false));
              navigate(NAVIGATION_MENU_PATH.CREATE_COST_MONITOR);
            }}
          />
        )}
      </div>
      <div className="page-content cost-monitor-page-content flex flex-column flex-fit">
        <SearchInput
          placeholder={t('costMonitor.costMonitorTable.searchPlaceholder')}
          type="search"
          onChange={(e: any) =>
            setFilteredCostMonitorData(
              allCostMonitorData?.filter((costMonitor) =>
                costMonitor.name
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              )
            )
          }
        />
        <Table
          pagination={false}
          rootClassName="table-section"
          dataSource={filteredCostMonitorData
            ?.slice((currentPage - 1) * 10, currentPage * 10)
            .map((value, index) => ({
              ...value,
              key: index,
            }))}
          locale={{
            emptyText: permissions.costControlList
              ? fetchCostMonitorDataRequestStatus === REQUEST_STATUS.ERROR && (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('graphErrorMessage')}
                  />
                )
              : t('noPermissionForTable'),
          }}
          columns={columns}
          loading={
            fetchCostMonitorDataRequestStatus === REQUEST_STATUS.PROCESSING
          }
        />
      </div>
      <div className="page-footer flex">
        <Pagination
          current={currentPage}
          onChange={setCurrentPage}
          total={filteredCostMonitorData?.length}
          defaultPageSize={PAGINATION_SIZE}
        />
      </div>
      {selectedCostMonitor !== undefined && showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={deleteSelectedCostMonitor}
          loading={deleteCostMonitorRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default CostMonitorPage;
