import i18n from 'i18n';
import { uniqBy } from 'lodash';
import { insertIndex } from 'utils/dataFormatterUtils';

/**
 * @function getClusterBreakdownExcelData
 * @description Function to return the cluster breakdown excel data
 * @param chartData List of cluster breakdown cost data
 * @param tableData Cluster breakdown table data
 * @param filters List of filters
 * @returns Objects containing excel export details
 */
export const getGranulateLineChartsExcelData = (
  chartData: any[],
  tableData: any,
  filters: any[],
  sheetName: string,
  yTitle: string
) => {
  return {
    sheetName: sheetName,
    columns: getGranulateLineChartExportColumns(chartData, yTitle),
    data: insertIndex(tableData),
    filters: filters,
  };
};

export const getGranulateLineChartExportColumns = (
  data: any[],
  yTitle: string
) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header:
      yTitle === 'Pods'
        ? i18n.t('containerInsight.usageMeteringLabels.namespace')
        : i18n.t('containerInsight.usageMeteringLabels.category'),
    key: 'field',
    dataKey: 'field',
    width: 30,
  },
  ...uniqBy(data, 'time').map((item) => ({
    header: item.time,
    key: `${item.time}-value`,
    dataKey: `${item.time}-value`,
    width: 30,
    alignment: 'right',
  })),
];
