import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectTheme } from 'redux/themeSlice';
import {
  setSelectedParentMenu,
  setShowMenuWithTitle,
  setSubMenuOpen,
} from 'redux/activeNavMenuSlice';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { NavigationMenuDataState } from 'types/navigationMenu';
import {
  NAVIGATION_MENU_DATA,
  NAVIGATION_MENU_ID,
} from 'constants/navigationMenu';
import { isPathPresentInMenuItems } from 'utils/navigationMenu';

import SubMenuList from './components/SubMenuList';
import AllFeaturesSubMenu from './components/AllFeaturesSubMenu';

import './index.scss';

type SubMenuComponentProps = {
  menu: NavigationMenuDataState;
};

const SubMenuComponent = ({ menu }: SubMenuComponentProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { theme } = useSelector(selectTheme);

  /**
   * @function onClickMinimize
   * @description Callback function for clicking the minimize icon
   */
  const onClickMinimize = () => {
    dispatch(setSubMenuOpen(false));
    dispatch(setShowMenuWithTitle(false));
    dispatch(
      setSelectedParentMenu(
        NAVIGATION_MENU_DATA.find((menu) =>
          isPathPresentInMenuItems(menu, location.pathname)
        )?.id
      )
    );
  };

  return (
    <div className="sub-menu full-height new-styled-scroll flex-fit">
      <div className="sub-menu-heading flex flex-align-items-center flex-space-between">
        <div className="sub-menu-heading-title font-caption-bold">
          {t(`navigationMenu.${menu.title}`)}
        </div>
        <Icon
          iconName={ICONS.MENU_FOLD_LINE}
          color={theme.primaryColor}
          onClick={onClickMinimize}
        />
      </div>
      {menu.id === NAVIGATION_MENU_ID.ALL_FEATURES ? (
        <AllFeaturesSubMenu />
      ) : (
        <SubMenuList menu={menu} />
      )}
    </div>
  );
};

export default SubMenuComponent;
