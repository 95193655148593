import i18n from 'i18n';
import { PROVIDER } from 'constants/cloudProviders';
import { QUERY_FIELDS } from 'constants/requestBody';
import { getProviderForConnection } from 'utils/dashboardUtils';
import { ConnectionListType } from 'types/dashboard';
import { createSnowIncident } from 'utils/services';
import { ServiceNowDataType } from 'pages/TicketIntegrationPage/types';
import { INCIDENT_TYPES } from 'components/CreateTicketDrawer/constants';
import { RecommendationSource } from 'pages/CostOptimizationInsightsPage/constants';
import { ContainerType } from 'types/GranulateRecommendation';
import { numberToMebibyteWithSuffix } from 'pages/CostOptimizationInsightsPage/components/Granulate/RecommendationPage/utils';
import { createGranulateIncident } from 'pages/CostOptimizationInsightsPage/components/Granulate/RecommendationPage/services';

import { RecommendationList } from '../../types';
import { getAwsUniqueKey } from '../RecommendationTableWithWorkFlow/utils';
import { uniqBy } from 'lodash';

/**
 * @function getGcpSnowTicketApiRequestBody
 * @description Returns the request body for GCP snow ticket creation api request
 * @param selectedRecommendations List of recommendations to include in the request body
 * @returns Object containing the recommendation details
 */
const getGcpSnowTicketApiRequestBody = (
  selectedRecommendations: RecommendationList[]
) => {
  return {
    recomDetails: selectedRecommendations.map((recommendation) => {
      return {
        name: recommendation.recommendationId,
        description: recommendation.recommendation,
        category: recommendation.category,
        targetResource: recommendation.resource,
      };
    }),
  };
};

/**
 * @function getAwsSnowTicketApiRequestBody
 * @description Returns the request body for AWS snow ticket creation api request
 * @param selectedRecommendations List of recommendations to include in the request body
 * @returns Object containing the recommendation details
 */
const getAwsSnowTicketApiRequestBody = (
  selectedRecommendations: RecommendationList[]
) => {
  return {
    recomDetails: selectedRecommendations.map((recommendation) => {
      const uniqueKey = getAwsUniqueKey(recommendation.checkId!);
      return {
        name: recommendation[uniqueKey],
        description: recommendation.checkId,
        category: QUERY_FIELDS.COST_ALL_CAPS,
        targetResource: recommendation[uniqueKey],
      };
    }),
  };
};

/**
 * @function getAzureSnowTicketApiRequestBody
 * @description Returns the request body for AZURE snow ticket creation api request
 * @param selectedRecommendations List of recommendations to include in the request body
 * @returns Object containing the recommendation details
 */
const getAzureSnowTicketApiRequestBody = (
  selectedRecommendations: RecommendationList[]
) => {
  return {
    recomDetails: selectedRecommendations.map((recommendation) => {
      return {
        name: recommendation.recommendation,
        description: recommendation.recommendationProblem,
        category: recommendation.category,
        targetResource: recommendation.recommendationProblem,
      };
    }),
  };
};

/**
 * @function getContainerObject
 * @description Returns the object containing the container data for ticket creation request body
 * @param container Container data to be modified
 * @returns Object containing the container details
 */
const getContainerObject = (container: ContainerType) => ({
  ...container,
  current_mem: container.current_mem,
  recommended_memory: container.recommended_memory,
  current_cpu: container.current_cpu ? container.current_cpu / 1000 : null,
  recommended_cpu: container.recommended_cpu
    ? container.recommended_cpu / 1000
    : null,
});

/**
 * @function getGranulateSnowTicketApiRequestBody
 * @description Returns the request body for GRANULATE snow ticket creation api request
 * @param selectedRecommendations List of recommendations to include in the request body
 * @param provider Provider of the selected connection
 * @returns Object containing the recommendation details for Granulate
 */
const getGranulateSnowTicketApiRequestBody = (
  selectedRecommendations: RecommendationList[],
  provider: string
) => {
  const uniqueServiceNames = uniqBy(selectedRecommendations, 'serviceName').map(
    (recommendation) => recommendation.serviceName
  );

  return {
    serviceName: uniqueServiceNames.join('/'),
    sagentRecomDetails: selectedRecommendations.map((recommendation) => {
      return {
        container: recommendation.containers?.map((container) =>
          getContainerObject(container)
        ),
        hpa: recommendation.hpa,
        creation_time: recommendation.timeStamp,
        service_name: recommendation.serviceName,
        controller_name: recommendation.controller_name,
        namespace: recommendation.namespace,
        patch_yaml: recommendation.patch_yaml,
        provider,
      };
    }),
  };
};

/**
 * @function getCreateSnowTicketApiRequestBody
 * @description Returns the request body for snow ticket creation api request by provider
 * @param dataSource Source of data Either CSP provider or GRANULATE
 * @param provider Provider for the selected connection
 * @param selectedRecommendations List of recommendations to include in the request body
 * @returns Object containing the recommendation details
 */
const getCreateSnowTicketApiRequestBody = (
  dataSource: string,
  provider: string,
  selectedRecommendations: RecommendationList[]
) => {
  switch (dataSource) {
    case PROVIDER.GCP:
      return getGcpSnowTicketApiRequestBody(selectedRecommendations);

    case PROVIDER.AWS:
      return getAwsSnowTicketApiRequestBody(selectedRecommendations);

    case PROVIDER.AZURE:
      return getAzureSnowTicketApiRequestBody(selectedRecommendations);

    case RecommendationSource.GRANULATE:
      return getGranulateSnowTicketApiRequestBody(
        selectedRecommendations,
        provider
      );

    default:
      return {};
  }
};

/**
 * @function createSnowTicket
 * @description Created the service now ticket for ticket creation based on the data source
 * @param connection Selected CSP connection
 * @param serviceNowData Service Now details
 * @param recommendations List of recommendations
 * @param recommendationSource Source of data Either CSP provider or GRANULATE
 * @param onSuccess Callback to be called after successful ticket creation
 * @param onError Callback to be called after failure to create the ticket
 */
export const createSnowTicket = (
  connection: ConnectionListType,
  serviceNowData: ServiceNowDataType,
  recommendations: RecommendationList[],
  recommendationSource: string,
  onSuccess: () => void,
  onError: (errorMessage: string) => void
) => {
  const isBulkTicket =
    serviceNowData.incidentType === INCIDENT_TYPES.BULK_INCIDENT;

  let requestBody: any = {
    connectorId: connection.connectorId,
    bulkTicket: recommendations.length === 1 ? false : isBulkTicket,
    multipleTicket: recommendations.length === 1 ? false : !isBulkTicket,
    assignTo: serviceNowData.assignedTo,
    category: serviceNowData.category,
  };

  requestBody = {
    ...requestBody,
    ...getCreateSnowTicketApiRequestBody(
      recommendationSource === RecommendationSource.CSP
        ? getProviderForConnection(connection)
        : recommendationSource,
      getProviderForConnection(connection),
      recommendations as RecommendationList[]
    ),
  };

  (recommendationSource === RecommendationSource.CSP
    ? createSnowIncident
    : createGranulateIncident)(requestBody)
    .then((res: any) => {
      if (res.status === 200) {
        onSuccess();
        return;
      }
      onError(
        res.data.message ?? i18n.t('createTicketDrawer.errorCreateTicket')
      );
    })
    .catch((e) => {
      onError(
        e.response.data.message ??
          i18n.t('createTicketDrawer.errorCreateTicket')
      );
    });
};
