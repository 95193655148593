import { QUERY_FIELDS } from 'constants/requestBody';
import i18n from 'i18n';

export const RECOMMENDATION_QUERY_COLUMNS = [
  {
    label: 'recommendationId',
    field: QUERY_FIELDS.AZURE_RECOM_ID,
  },
  {
    label: 'recommendationName',
    field: QUERY_FIELDS.NAME,
  },
  {
    label: 'recommendationProblem',
    field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
  },
  {
    label: 'lastRefreshed',
    field: QUERY_FIELDS.PROPERTIES_LAST_UPDATED,
  },
  {
    label: 'costSavings',
    field: QUERY_FIELDS.PROPERTIES_SAVINGS,
  },
  {
    label: 'type',
    field: QUERY_FIELDS.TYPE,
  },
  {
    label: 'impactedField',
    field: QUERY_FIELDS.PROPERTIES_IMPACTED_FIELD,
  },
  {
    label: 'impactedValue',
    field: QUERY_FIELDS.PROPERTIES_IMPACTED_VALUE,
  },
  {
    label: 'recommendationState',
    field: QUERY_FIELDS.STATE,
  },
  {
    label: 'recommendationCategory',
    field: QUERY_FIELDS.PROPERTIES_CATEGORY,
  },
  {
    label: 'resourceMetadata',
    field: QUERY_FIELDS.PROPERTIES_RESOURCE_METADATA_RESOURCE_ID,
  },
];

export const RULESET_FILTERS_ADDITIONAL_QUERY_COLUMNS = [
  {
    label: 'currentMachineType',
    field:
      "REPLACE(JSON_EXTRACT(recommendation_details, '$.overview.currentMachineType.name'),'\"','')",
  },
  {
    label: 'recommendedMachineType',
    field:
      "REPLACE(JSON_EXTRACT(recommendation_details, '$.overview.recommendedMachineType.name'),'\"','')",
  },
  {
    label: 'commitmentYear',
    field:
      "CASE REPLACE(JSON_EXTRACT(recommendation_details, '$.overview.plan'),'\"','') WHEN 'TWELVE_MONTH' THEN '1' WHEN 'THIRTY_SIX_MONTH' THEN '3' ELSE '0' END",
  },
  {
    label: 'commitmentType',
    field:
      "REPLACE(JSON_EXTRACT(recommendation_details, '$.overview.type'),'\"','')",
  },
];

export const RecommendationTableExportColumns = [
  { header: '#', key: 'slNo', width: 10, alignment: 'center', dataKey: 'slNo' },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.impactedField'),
    dataKey: 'impactedField',
    key: 'impactedField',
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.ticketStatus'),
    dataKey: 'serviceNowStatus',
    key: 'serviceNowStatus',
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.recStatus'),
    dataKey: 'recStatus',
    key: 'recStatus',
  },
  {
    header: i18n.t(
      'costOptimizationInsight.recommendationTable.recommendationSolution'
    ),
    dataKey: 'recommendationSolution',
    key: 'recommendationSolution',
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.impactedValue'),
    dataKey: 'impactedValue',
    key: 'impactedValue',
  },
  {
    header: i18n.t(
      'costOptimizationInsight.recommendationTable.recommendationId'
    ),
    dataKey: 'recommendationId',
    key: 'recommendationId',
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.type'),
    dataKey: 'type',
    key: 'type',
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.category'),
    dataKey: 'recommendationCategory',
    key: 'recommendationCategory',
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.lastRefreshed'),
    dataKey: 'lastRefreshed',
    key: 'lastRefreshed',
  },
  {
    header: i18n.t('costOptimizationInsight.recommendationTable.costSavings'),
    dataKey: 'costSavings',
    key: 'costSavings',
  },
];
