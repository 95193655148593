import i18n from 'i18n';

import { store } from 'redux/store';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const CostTrendExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('snowflakeDashboards.month'),
    key: 'month',
    dataKey: 'month',
    width: 20,
  },
  {
    header: i18n.t('snowflakeDashboards.costInCurrency', {
      currencySymbol: currencySymbol,
    }),
    key: 'cost',
    dataKey: 'cost',
    width: 20,
    alignment: 'right',
  },
];
