import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';

import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import GraphHeader from 'components/GraphHeader';
import { MonthlyCreditCostMetricType } from 'types/dataTypes';
import { selectDashboard } from 'redux/dashboardSlice';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import LineChart from 'components/LineChart';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { TABLE_HEADER_LENGTH_LIMIT } from 'constants/userConsole';

import { getGCPCreditDiscountBreakdownExcelData } from '../../utils/exportToExcel';

type CreditDiscountBreakdownProps = {
  isCreditDiscountBreakdownTableView: boolean;
  setCreditDiscountBreakdownTableView: (value: boolean) => void;
  requestStatus: string;
  creditDiscountBreakdownLabels: string[];
  monthlyCreditByCostTypeData: MonthlyCreditCostMetricType[];
  pdfView: boolean;
  creditAndDiscountStartMonth: string;
  creditAndDiscountEndMonth: string;
  onChangeCreditAndDiscountDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

export const CreditDiscountBreakdown = ({
  isCreditDiscountBreakdownTableView,
  setCreditDiscountBreakdownTableView,
  requestStatus,
  creditDiscountBreakdownLabels,
  monthlyCreditByCostTypeData,
  pdfView,
  creditAndDiscountStartMonth,
  creditAndDiscountEndMonth,
  onChangeCreditAndDiscountDateRange,
}: CreditDiscountBreakdownProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const CreditDiscountBreakdownColumns = () => {
    let columns: any[] = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        render: (_text: any, _record: any, index: number) => index + 1,
        width: 50,
      },
      {
        title: t('creditDiscountBreakdown.month'),
        dataIndex: 'month',
        key: 'month',
        width: 80,
      },
    ];
    monthlyCreditByCostTypeData.forEach((value) => {
      columns = [
        ...columns,
        {
          title:
            value.costMetricType.length > TABLE_HEADER_LENGTH_LIMIT ? (
              <Tooltip title={value.costMetricType}>
                {value.costMetricType.substring(0, TABLE_HEADER_LENGTH_LIMIT) +
                  '...'}
              </Tooltip>
            ) : (
              value.costMetricType
            ),
          dataIndex: value.costMetricType,
          key: value.costMetricType,
          render: (text: any) => currencySymbol + numberCommaSeparator(text),
          width: 100,
          align: 'right' as const,
        },
      ];
    });
    return columns;
  };

  const getGraphData = () => {
    let graphData: any[] = [];
    monthlyCreditByCostTypeData.forEach((value) => {
      for (let i = 0; i < value.creditData.length; i++) {
        graphData.push({
          month: creditDiscountBreakdownLabels[i],
          value: value.creditData[i] || 0,
          category: value.costMetricType?.trim(),
        });
      }
    });
    return graphData;
  };

  const getComponent = () =>
    isCreditDiscountBreakdownTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={creditDiscountBreakdownLabels.map((value, index) => {
            let thisMonthData: any = {
              month: value,
              key: index,
            };
            monthlyCreditByCostTypeData.forEach(
              (costData) =>
                (thisMonthData[costData.costMetricType] =
                  costData.creditData[index])
            );
            return thisMonthData;
          })}
          columns={CreditDiscountBreakdownColumns()}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <LineChart
        data={getGraphData()}
        xField={'month'}
        yField={'value'}
        groupingField={'category'}
        xTitle={t('creditDiscountBreakdown.month')}
        yTitle={t('creditDiscountBreakdown.creditAmount')}
        showAllLegend={pdfView}
        disableAnimation={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(creditAndDiscountStartMonth),
          moment(creditAndDiscountEndMonth),
        ]}
        onChange={onChangeCreditAndDiscountDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  return (
    <div className="credit-discout-breakdown graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.creditDiscountBreakdown')}
        graphName={'credit-discout-breakdown'}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        isTableView={isCreditDiscountBreakdownTableView}
        setIsTableView={setCreditDiscountBreakdownTableView}
        excelData={getGCPCreditDiscountBreakdownExcelData(
          selectedDashboard?.connectorName ?? '',
          creditDiscountBreakdownLabels.map((value, index) => {
            let thisMonthData: any = {
              month: value,
            };
            monthlyCreditByCostTypeData.forEach(
              (costData) =>
                (thisMonthData[costData.costMetricType] =
                  costData.creditData[index])
            );
            return thisMonthData;
          }),
          monthlyCreditByCostTypeData
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};
