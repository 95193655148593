import i18n from 'i18n';

export enum StatusEnum {
  COMPLETED = 'COMPLETED',
  ONGOING = 'ONGOING',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  DEACTIVATED = 'DEACTIVATED',
  LEARNING = 'LEARNING',
  READY_FOR_ACTIVATION = 'READY_FOR_ACTIVATION',
  INSTALLATION = 'INSTALLATION',
  OPTIMIZED = 'OPTIMIZED',
  BENCHMARK = 'BENCHMARK',
  LOADED = 'LOADED',
  PASSIVE = 'PASSIVE',
  ACTIVATED = 'ACTIVATED',
  UNINSTALLING = 'UNINSTALLING',
  UNINSTALLED = 'UNINSTALLED',
}

export enum GranulateConnectionView {
  TABLE_VIEW = 'TABLE_VIEW',
  CONNECTION_FORM = 'CONNECTION_FORM',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum GranulateProviderView {
  PROVIDER_LIST = 'PROVIDER_LIST',
  GCP = 'GCP',
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export const PROGRESS_BAR_ITEMS = [
  {
    title: i18n.t('granulateConnectionPage.steps.titleFirst'),
    description: i18n.t('granulateConnectionPage.steps.descriptionFirst'),
  },
  {
    title: i18n.t('granulateConnectionPage.steps.titleSecond'),
    description: i18n.t('granulateConnectionPage.steps.descriptionSecond'),
  },
];
