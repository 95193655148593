import { useSelector } from 'react-redux';

import EachTag from 'components/EachTag';
import { PROVIDER } from 'constants/cloudProviders';
import { selectDashboard } from 'redux/dashboardSlice';

import './index.scss';

const TagFilters = () => {
  const {
    selectedConnection,
    allAvailableTags,
    tagsFilters,
    selectedDashboard,
    selectedDashboardView,
  } = useSelector(selectDashboard);

  const selectedTags =
    tagsFilters
      .find((tag) => tag.dashboardId === selectedDashboard?.id)
      ?.tagViews.find((view) => view.viewId === selectedDashboardView)?.tags ??
    [];

  if (!selectedTags.length) return <></>;

  return (
    <div className="tag-filters flex flex-gap-24">
      {selectedTags.map((eachTag) => (
        <div
          className="flex flex-column flex-gap-8"
          key={eachTag.key}
          data-testid="each-tag-wrapper"
        >
          <span className="font-caption-bold">
            {selectedConnection?.provider === PROVIDER.AWS
              ? eachTag.label
              : eachTag.key}
          </span>
          <div className="flex flex-fit flex-align-items-end">
            <EachTag
              tagData={eachTag}
              key={eachTag.key}
              allValues={
                allAvailableTags.find((tag) => tag.key === eachTag.key)
                  ?.values ?? []
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TagFilters;
