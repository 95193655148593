import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import {
  createNewRole,
  editCustomRole,
  fetchAllRoles,
} from 'pages/RolesPage/services';
import { REQUEST_STATUS } from 'constants/requestBody';
import Modal from 'components/Modal';
import { onApiCallError } from 'utils/handleErrors';
import { NONE_ROLE_OPTION, RolesType } from '../../constants';
import PermissionsTable from '../PermissionsTable';

import './index.scss';

type AddCustomRoleModalProps = {
  showAddCustomRoleModal: boolean;
  setShowAddCustomRoleModal: (val: boolean) => void;
  getAllRoles: () => void;
  currentRoleForModal?: any;
};

const AddCustomRoleModal = ({
  showAddCustomRoleModal,
  setShowAddCustomRoleModal,
  getAllRoles,
  currentRoleForModal,
}: AddCustomRoleModalProps) => {
  const { t } = useTranslation();

  const [rolesData, setRolesData] = useState<RolesType[]>([NONE_ROLE_OPTION]);
  const [newRole, setNewRole] = useState<RolesType>(NONE_ROLE_OPTION);
  const [roleName, setRoleName] = useState('');
  const [roleNameValidationMessage, setRoleNameValidationMessage] =
    useState<string>();
  const [permissionsValidationMessage, setPermissionsValidationMessage] =
    useState<string>();
  const [
    createCustomRoleAPIRequestStatus,
    setCreateCustomRoleAPIRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [rolesDataRequestStatus, setRolesDataRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    getAllRolesList();

    if (currentRoleForModal) {
      setRoleName(currentRoleForModal.name);
      setNewRole(currentRoleForModal);
    }
  }, [currentRoleForModal]);

  const getAllRolesList = () => {
    setRolesDataRequestStatus(REQUEST_STATUS.PROCESSING);

    fetchAllRoles()
      .then((res: any) => {
        setRolesData([NONE_ROLE_OPTION, ...res.data.responseData.content]);
        setRolesDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRolesDataRequestStatus);
      });
  };

  const validateForm = () => {
    let validationStatus = true;
    if (!roleName || roleName.length === 0) {
      setRoleNameValidationMessage(
        t('createCustomRoleForm.roleNameRequiredValidationMessage')
      );
      validationStatus = false;
    } else if (
      currentRoleForModal
        ? rolesData.some((role) => role.name === roleName) &&
          roleName !== currentRoleForModal.name
        : rolesData.some((role) => role.name === roleName)
    ) {
      setRoleNameValidationMessage(
        t('createCustomRoleForm.duplicateRoleNameValidationMessage')
      );
      validationStatus = false;
    } else {
      setRoleNameValidationMessage('');
    }

    if (
      newRole.permissions.length > 0 &&
      newRole.permissions.some((permission) => permission.rights.length > 0)
    ) {
      setPermissionsValidationMessage('');
    } else {
      setPermissionsValidationMessage(
        t('createCustomRoleForm.permissionsRequiredValidationMessage')
      );
      validationStatus = false;
    }
    return validationStatus;
  };

  const onCreateCustomRole = () => {
    setCreateCustomRoleAPIRequestStatus(REQUEST_STATUS.PROCESSING);
    if (!validateForm()) {
      setCreateCustomRoleAPIRequestStatus(REQUEST_STATUS.SUCCESS);
      return;
    }

    const body = {
      roleName: roleName,
      permissions: newRole.permissions,
    };

    createNewRole(body)
      .then(() => {
        getAllRoles();
        message.success(t('createCustomRoleForm.createCustomRoleSuccess'));
        setShowAddCustomRoleModal(false);
        setCreateCustomRoleAPIRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setCreateCustomRoleAPIRequestStatus,
          t('createCustomRoleForm.createCustomRoleFailure')
        );
      });
  };

  const onEditCustomRole = () => {
    setCreateCustomRoleAPIRequestStatus(REQUEST_STATUS.PROCESSING);
    if (!validateForm()) {
      setCreateCustomRoleAPIRequestStatus(REQUEST_STATUS.SUCCESS);
      return;
    }
    const body = {
      id: currentRoleForModal.roleId,
      roleName: roleName,
      permissions: newRole.permissions,
      roleType: currentRoleForModal.roleType,
    };
    editCustomRole(body)
      .then(() => {
        getAllRoles();
        message.success(t('createCustomRoleForm.editCustomRoleSuccess'));
        setShowAddCustomRoleModal(false);
        setCreateCustomRoleAPIRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setCreateCustomRoleAPIRequestStatus,
          t('createCustomRoleForm.editCustomRoleFailure')
        );
      });
  };

  return (
    <Modal
      className="custom-role-modal"
      visible={showAddCustomRoleModal}
      onCancel={() => {
        setShowAddCustomRoleModal(false);
      }}
      title={currentRoleForModal ? t('editCustomRole') : t('createCustomRole')}
      footer={null}
      centered
    >
      <div className="custom-role-body flex flex-column">
        <div className="custom-role-form flex flex-column">
          <div className="form-item flex flex-column">
            <FormLabel title={t('createCustomRoleForm.roleName')} required />
            <Input
              value={roleName}
              placeholder={t('createCustomRoleForm.roleNamePlaceholder')}
              onChange={(e: any) => {
                setRoleName(e.target.value);
              }}
            />
            <span
              style={{
                display: `${roleNameValidationMessage ? 'inline' : 'none'}`,
              }}
              className="font-validation-error"
            >
              {roleNameValidationMessage}
            </span>
          </div>
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('createCustomRoleForm.createFromAnExistingRole')}
              required
            />
            <SelectDropdown
              value={newRole.name}
              defaultValue={rolesData[0].name}
              showSearch
              options={rolesData.map((role) => ({
                value: role.name,
                label: role.name,
              }))}
              onSelect={(e: any) => {
                setNewRole(rolesData.find((value) => value.name === e)!);
              }}
              loading={rolesDataRequestStatus === REQUEST_STATUS.PROCESSING}
            />
          </div>
          <PermissionsTable role={newRole} setNewRole={setNewRole} />
          <span
            style={{
              display: `${permissionsValidationMessage ? 'inline' : 'none'}`,
            }}
            className="font-validation-error"
          >
            {permissionsValidationMessage}
          </span>
        </div>
        <Button
          title={
            currentRoleForModal
              ? t('createCustomRoleForm.update')
              : t('createCustomRoleForm.submit')
          }
          onClick={currentRoleForModal ? onEditCustomRole : onCreateCustomRole}
          loading={
            createCustomRoleAPIRequestStatus === REQUEST_STATUS.PROCESSING
          }
        />
      </div>
    </Modal>
  );
};

export default AddCustomRoleModal;
