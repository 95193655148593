import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RangeValue } from 'rc-picker/lib/interface';
import { selectDashboard } from 'redux/dashboardSlice';
import DashboardComponent from 'components/DashboardComponent';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import { REQUEST_STATUS } from 'constants/requestBody';
import Table from 'components/Table';
import PointerMap from 'components/PointerMap';
import DatePicker from 'components/DatePicker';
import { ColouredProviderCostByRegionType } from 'types/dataTypes';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { getRegionCoordinates } from 'utils/dashboardUtils';
import { selectCommonUtility } from 'redux/commonUtilitySlice';

import ConnectionsFilter from '../ConnectionsFilter';
import { getRegionsExcelData } from '../../utils/exportToExcel';
import { getFilteredConnections } from '../../utils/commonUtils';

type ConsolidatedRegionsProps = {
  requestStatus: string;
  selectedConnections: string[];
  setSelectedConnections: (val: string[]) => void;
  consolidatedRegionsTableData: ColouredProviderCostByRegionType[];
  pdfView: boolean;
  consolidatedRegionStartMonth: string;
  consolidatedRegionEndMonth: string;
  onChangeConsolidatedRegionDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

const ConsolidatedRegions = ({
  requestStatus,
  selectedConnections,
  setSelectedConnections,
  consolidatedRegionsTableData,
  pdfView,
  consolidatedRegionStartMonth,
  consolidatedRegionEndMonth,
  onChangeConsolidatedRegionDateRange,
}: ConsolidatedRegionsProps) => {
  const { t } = useTranslation();
  const { selectedGroupMetaData } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'color',
      key: 'color',
      render: (text: string) => (
        <div className="color-block" style={{ backgroundColor: text }} />
      ),
      width: 30,
    },
    {
      title: t('consolidatedRegions.region'),
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: t('consolidatedRegions.cost', { currencySymbol: currencySymbol }),
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      align: 'center' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(consolidatedRegionStartMonth),
          moment(consolidatedRegionEndMonth),
        ]}
        onChange={onChangeConsolidatedRegionDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        designVersion2
      />
      <ConnectionsFilter
        selectedConnections={selectedConnections}
        onSelectedDropdown={setSelectedConnections}
      />
    </div>
  );

  const getComponent = () => (
    <div className="graph flex flex-gap-16">
      <div className="width-30 full-height">
        <Table
          pagination={false}
          dataSource={consolidatedRegionsTableData.map((value, index) => ({
            ...value,
            key: index,
          }))}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          columns={columns}
          scroll={{ y: 300 }}
          designVersion2
        />
      </div>
      <div
        className={`graph-area with-table width-70 ${pdfView && 'pdf-wrapper'}`}
      >
        <PointerMap
          data={consolidatedRegionsTableData.map((item) => ({
            ...item,
            coordinates: getRegionCoordinates(item.region, item.provider),
          }))}
          valueLabel={t('costByAwsRegion.cost')}
          labelField="region"
          valueField="cost"
          colorField="color"
          prefixSymbol={currencySymbol}
        />
      </div>
    </div>
  );

  return (
    <div
      className="consolidated-regions map-card graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.costByRegion')}
        graphName={'consolidated-regions'}
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        designVersion2
        excelData={getRegionsExcelData(
          selectedGroupMetaData?.name ?? '',
          consolidatedRegionsTableData,
          getFilteredConnections(
            selectedGroupMetaData?.connectorDtos ?? [],
            selectedConnections
          ).map((connection) => connection.name)
        )}
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};

export default ConsolidatedRegions;
