import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { uniq } from 'lodash';

import { selectReport, setReportOptions } from 'redux/reportSlice';
import ControlComponent from 'components/DashboardControl';
import DatePicker from 'components/DatePicker';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { DATE_FORMAT } from 'utils/date';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import { PdfContent } from 'types/exportTypes';
import { CHART_TYPES } from 'constants/graphConfig';
import { generateGraphColors } from 'utils/dashboardUtils';
import {
  getDimensionDisplayText,
  getReportFieldCategory,
  modifyDimensionLabelForCustomQuery,
} from 'pages/CreateReportPage/utils';
import { FIELD_TYPE, MY_DASHBOARD_TYPES } from 'constants/dashboard';
import {
  numberCommaSeparator,
  replaceAllSpecialCharactersBy,
} from 'utils/dataFormatterUtils';
import { getGroupProvider } from 'utils/providerDetails';

import ReportTableOrChart from '../ReportTableOrChart';

import './index.scss';

const ReportDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    reportOptions,
    reportData,
    reportConnection,
    reportGroup,
    typeOfConnection,
  } = useSelector(selectReport);

  const [chartSliderValues, setChartSliderValues] = useState<{
    x: number;
    y: number;
  }>();

  /**
   * @function getStackGraphPdfLegends
   * @description Function to get stack graph pdf legends.
   * @returns stack graph pdf legends array.
   */
  const getStackGraphPdfLegends = () => {
    // sort the data based on the metric. This is done to get the legends in the same order as the graph
    const dimensions =
      reportOptions.chartDimension?.map((eachDimension) => ({
        title: eachDimension.dimension,
        key: modifyDimensionLabelForCustomQuery(eachDimension),
        category: getReportFieldCategory(eachDimension.dimension),
      })) ?? [];

    const metrics =
      reportOptions.chartMetric?.map((metric) => ({
        title: metric,
        key: replaceAllSpecialCharactersBy(metric) ?? '',
      })) ?? [];

    const chartData = [...(reportData?.chartData ?? [])];

    if (
      !dimensions.some((dimension) => dimension.category === FIELD_TYPE.TIME)
    ) {
      chartData.sort((a: any, b: any) => {
        return b[metrics[0]?.key] - a[metrics[0]?.key];
      });
    }

    return uniq(
      chartData.map((data) => data[dimensions.at(1)?.key ?? ''] as string)
    );
  };

  /**
   * @function getPdfChartContent
   * @description Function to get pdf chart contents.
   * @returns chart view component
   */
  const getPdfChartContent = () => {
    let content: PdfContent = {
      element: (
        <ReportTableOrChart pdfView={true} sliderValue={chartSliderValues} />
      ),
      contentType: reportOptions.chartType,
      graphName: reportOptions.reportName ?? t('reports.addAReportName'),
      graphHeaderOverride:
        reportOptions.reportName ?? t('reports.addAReportName'),
    };
    if (!reportData?.chartData?.length) return content;

    if (
      reportOptions.chartType === CHART_TYPES.PIE_CHART ||
      reportOptions.chartType === CHART_TYPES.DOUGHNUT_CHART ||
      reportOptions.chartType === CHART_TYPES.BAR_CHART
    ) {
      const dimension = reportOptions.chartDimension
        ?.map((eachDimension) =>
          modifyDimensionLabelForCustomQuery(eachDimension)
        )
        .at(0);
      const colors = generateGraphColors(reportData?.chartData?.length ?? 0);
      content.legends = reportData.chartData.map((data, index) => ({
        name: data[dimension ?? ''],
        color: colors[index],
      }));
    } else if (reportOptions.chartType === CHART_TYPES.STACK_CHART) {
      const allLegends = getStackGraphPdfLegends();
      const colors = generateGraphColors(allLegends?.length ?? 0);
      content.legends = allLegends.map((data, index) => ({
        name: data,
        color: colors[index],
      }));
    }

    return content;
  };

  /**
   * @function getPdfTableColumn
   * @description Function to get pdf table column data.
   * @returns pdf table column data.
   */
  const getPdfTableColumn = () => {
    const columnData = [];
    columnData.push({ header: '#', dataKey: 'index' });
    reportOptions.dimension?.forEach((dimension) => {
      columnData.push({
        header: getDimensionDisplayText(dimension),
        dataKey: modifyDimensionLabelForCustomQuery(dimension),
      });
    });

    reportOptions.metric?.forEach((metric) => {
      columnData.push({
        header: metric,
        dataKey: replaceAllSpecialCharactersBy(metric),
      });
    });
    return columnData;
  };

  /**
   * @function getPdfTableBody
   * @description Function to get pdf table body data.
   * @returns pdf table body data.
   */
  const getPdfTableBody = () => {
    return reportData?.tableData?.map((tableData: any, index: number) => {
      const newObj: any = {};
      for (const key in tableData) {
        if (typeof tableData[key] === 'number') {
          newObj[key] = numberCommaSeparator(tableData[key]);
        } else {
          newObj[key] = tableData[key];
        }
      }
      return { ...newObj, index: index + 1 };
    });
  };

  /**
   * @function getPdfContent
   * @description Function to get pdf graph content.
   * @returns pdf content array.
   */
  const getPdfContent = () => {
    const pdfContents: PdfContent[] = [];
    if (reportOptions.chartType !== CHART_TYPES.TABLE) {
      pdfContents.push({ ...getPdfChartContent() });
    } else {
      pdfContents.push({
        contentType: CHART_TYPES.TABLE,
        graphHeaderOverride:
          reportOptions.reportName ?? t('reports.addAReportName'),
        graphName: reportOptions.reportName ?? t('reports.addAReportName'),
        column: getPdfTableColumn(),
        body: getPdfTableBody(),
        tableName: reportOptions.reportName ?? t('reports.addAReportName'),
      });
    }
    return pdfContents;
  };

  const getPdfMetaData = () => {
    return {
      viewName: reportOptions.reportName ?? t('reports.addAReportName'),
      fileName: reportOptions.reportName ?? t('reports.addAReportName'),
      heading: reportOptions.reportName ?? t('reports.addAReportName'),
      subtitle1: reportConnection?.displayName ?? reportGroup?.name ?? '',
      subtitle2:
        typeOfConnection === MY_DASHBOARD_TYPES.GROUP
          ? ''
          : reportConnection?.dataSourceType!,
      provider:
        typeOfConnection !== MY_DASHBOARD_TYPES.GROUP
          ? reportConnection?.provider
          : undefined,
      groupProvider:
        typeOfConnection === MY_DASHBOARD_TYPES.GROUP
          ? getGroupProvider(reportGroup)
          : undefined,
    };
  };

  return (
    <div className="report-dashboard flex-fit">
      <ControlComponent
        filters={[
          {
            title: t('reports.date'),
            filter: (
              <DatePicker
                value={[
                  moment(reportOptions.startDate),
                  moment(reportOptions.endDate),
                ]}
                presetValue={reportOptions.dateRanges}
                onChange={(
                  _dates: any,
                  dateString: [string, string],
                  presetValue: string
                ) => {
                  dispatch(
                    setReportOptions({
                      ...reportOptions,
                      startDate: dateString[0],
                      endDate: dateString[1],
                      dateRanges: presetValue,
                    })
                  );
                }}
                disabledDate={(current: any) => current > moment().endOf('day')}
                format={DATE_FORMAT}
                pickerType={DATE_PICKER_TYPE.CUSTOM_RANGE_PICKER}
              />
            ),
            minimizedText: `${reportOptions.startDate} to ${reportOptions.endDate}`,
            onClear: () => {},
          },
        ]}
      />
      <ReportTableOrChart setSliderValue={setChartSliderValues} />
      <PdfDownloadComponent
        pdfContent={getPdfContent()}
        pdfMetaData={getPdfMetaData()}
      />
    </div>
  );
};

export default ReportDashboard;
