import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DEFAULT_FORM_DATA,
  RuleEngineComponents,
} from 'pages/RuleEnginePage/constants';
import { RulesetsType } from 'pages/RuleEnginePage/types';
import { RootState } from './store';

export interface RuleEngineState {
  ruleEngineComponent: string;
  ruleEngineFormData: RulesetsType;
  isEditForm: boolean;
  isTryAgain: boolean;
  deletedRuleIds: string[];
}

const initialState: RuleEngineState = {
  ruleEngineComponent: RuleEngineComponents.PROVIDER_LIST,
  ruleEngineFormData: DEFAULT_FORM_DATA,
  isEditForm: false,
  isTryAgain: false,
  deletedRuleIds: [],
};

export const ruleEngineSlice = createSlice({
  name: 'ruleEngine',
  initialState,
  reducers: {
    setRuleEngineComponent: (state, action: PayloadAction<string>) => {
      state.ruleEngineComponent = action.payload;
    },
    setRuleEngineFormData: (state, action: PayloadAction<RulesetsType>) => {
      state.ruleEngineFormData = action.payload;
    },
    setIsEditForm: (state, action: PayloadAction<boolean>) => {
      state.isEditForm = action.payload;
    },
    setIsTryAgain: (state, action: PayloadAction<boolean>) => {
      state.isTryAgain = action.payload;
    },
    setDeletedRuleIds: (state, action: PayloadAction<string[]>) => {
      state.deletedRuleIds = action.payload;
    },
  },
});

export const ruleEngine = (state: RootState) => state.ruleEngine;
export const {
  setRuleEngineComponent,
  setRuleEngineFormData,
  setIsEditForm,
  setIsTryAgain,
  setDeletedRuleIds,
} = ruleEngineSlice.actions;
export default ruleEngineSlice.reducer;
