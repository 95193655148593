import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectDashboard,
  setAllAvailableTags,
  setTagsFilters,
} from 'redux/dashboardSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import Button from 'components/Button';
import { DASHBOARD_TYPES, REQUEST_STATUS } from 'constants/requestBody';
import {
  CUSTOM_DASHBOARD_MODE,
  CUSTOM_DASHBOARD_TYPES,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import DashboardHeader from 'components/DashboardHeader';
import TagFilters from 'components/TagFilters';
import Loader from 'components/Loader';
import NavigationPath from 'components/NavigationPath';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import {
  customDashboard,
  setDashboardType,
  setIsAddNewDashboard,
} from 'redux/customDashboardSlice';
import { TagsFilterType, TagsType, TagView } from 'types/dashboard';
import HorizontalNavigation from 'pages/OverviewPage/components/ConnectionsDashboard/components/HorizontalNavigation';
import { DASHBOARD } from 'pages/OverviewPage/components/ConnectionsDashboard/components/HorizontalNavigation/constants';
import { DASHBOARD_TYPE } from 'types/navigationMenu';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { PROVIDER } from 'constants/cloudProviders';
import { getLabels, getStaticFileLabels } from 'utils/services';
import { getTagFilterData } from 'utils/dashboardUtils';
import { onApiCallError } from 'utils/handleErrors';

import CustomViewDashboard from './components/CustomViewDashboard';
import CostSummaryDashboard from './components/CostSummaryDashboard';
import CreditSummaryDashboard from './components/CreditSummaryDashboard';
import DeepDiveDashboard from './components/DeepDiveDashboard';
import CarbonFootprintDashboard from './components/CarbonFootprintDashboard';
import CostRecommendationSummaryDashboard from './components/CostRecommendationSummaryDashboard';
import AWSCostRecommendationSummaryDashboard from './components/AWSCostRecommendationSummaryDashboard';
import SnowflakeWarehouseDashboard from './components/SnowflakeWarehouseDashboard';
import SnowflakeStorageDashboard from './components/SnowflakeStorageDashboard';
import ComputeDashboard from './components/ComputeDashboard';
import StorageDashboard from './components/StorageDashboard';
import DatabaseDashboard from './components/DatabaseDashboard';
import { DashboardViewsWithControlComponent } from './constants';
import TagsDrawer from '../TagsDrawer';

import './index.scss';

type ConnectionsDashboardProps = {
  setShowDataSourceModal: (val: boolean) => void;
  dashboardRequestStatus: string;
  setDashboardRequestStatus: (val: string) => void;
};

const ConnectionsDashboard = ({
  setShowDataSourceModal,
  dashboardRequestStatus,
  setDashboardRequestStatus,
}: ConnectionsDashboardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedDashboardView,
    selectedConnection,
    selectedDashboard,
    selectedIntegrationConnection,
    tagsFilters,
    selectedMyDashboardType,
    dashboardViewsList,
  } = useSelector(selectDashboard);
  const { customDashBoardMode, selectedDashboardCustomViews, customViewData } =
    useSelector(customDashboard);
  const { permissions } = useSelector(userAuthorization);

  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showTagsFilter, setShowTagsFilter] = useState(true);

  useEffect(() => {
    dispatch(setAllAvailableTags([]));
    dispatch(setTagsFilters([]));
  }, [selectedDashboard?.connectorProvider]);

  useEffect(() => {
    if (showTagsFilterOption()) {
      setShowTagsFilter(true);
      getAllLabels();
    }
  }, [selectedDashboard, selectedDashboardView]);

  /**
   * @function getDashboardView
   * @description Function to switch between dashboard
   * @return Function will return corresponding dashboard;
   */
  const getDashboardView = () => {
    if (
      selectedDashboard?.type === DASHBOARD_TYPE.CUSTOM &&
      !dashboardViewsList.length &&
      !selectedDashboardCustomViews.length
    )
      return (
        <div className="flex flex-center">
          {t('dashboardLabels.noCustomViews')}
        </div>
      );

    if (
      !selectedDashboard ||
      ((selectedDashboard.dashBoardType ===
        MY_DASHBOARD_TYPES.SINGLE_CONNECTION ||
        selectedDashboard.dashBoardType === MY_DASHBOARD_TYPES.IMPORTS) &&
        (!selectedConnection ||
          selectedDashboard.connectorId !== selectedConnection.connectorId)) ||
      (selectedDashboard.dashBoardType === MY_DASHBOARD_TYPES.SNOWFLAKE &&
        selectedDashboard.integrationId !==
          (!selectedIntegrationConnection ||
            selectedIntegrationConnection.integrationId))
    ) {
      return <></>;
    }

    switch (selectedDashboardView) {
      case DASHBOARD.COST_SUMMARY:
        return selectedDashboard.type === DASHBOARD_TYPE.PRE_BUILT ? (
          <CostSummaryDashboard />
        ) : (
          <></>
        );
      case DASHBOARD.CREDIT_SUMMARY:
        return <CreditSummaryDashboard />;
      case DASHBOARD.COST_RECOMMENDATION_SUMMARY:
        return selectedDashboard.connectorProvider === PROVIDER.AWS ? (
          <AWSCostRecommendationSummaryDashboard />
        ) : (
          <CostRecommendationSummaryDashboard />
        );
      case DASHBOARD.PROJECT_DEEP_DIVE:
        return <DeepDiveDashboard />;
      case DASHBOARD.CARBON_FOOTPRINT:
        return <CarbonFootprintDashboard />;
      case DASHBOARD.SNOWFLAKE_WAREHOUSE:
        return <SnowflakeWarehouseDashboard />;
      case DASHBOARD.SNOWFLAKE_STORAGE:
        return <SnowflakeStorageDashboard />;
      case DASHBOARD.COMPUTE:
        return <ComputeDashboard />;
      case DASHBOARD.STORAGE:
        return <StorageDashboard />;
      case DASHBOARD.DATABASE:
        return <DatabaseDashboard />;
      default:
        return <CustomViewDashboard />;
    }
  };

  /**
   * @function showTagsFilterOption
   * @description Function to determine if the tags filter should be shown or not
   * @returns boolean true if filter is shown else false
   */
  const showTagsFilterOption = () => {
    if (
      [MY_DASHBOARD_TYPES.SNOWFLAKE, MY_DASHBOARD_TYPES.IMPORTS].includes(
        selectedDashboard?.dashBoardType as MY_DASHBOARD_TYPES
      )
    ) {
      return false;
    }

    if (
      selectedDashboardCustomViews.some(
        (view) => view.id === selectedDashboardView
      )
    ) {
      return (
        customViewData.dataSource.dashBoardType ===
        CUSTOM_DASHBOARD_TYPES.BILLING_DEFAULT
      );
    }

    return ![
      DASHBOARD.COST_RECOMMENDATION_SUMMARY.valueOf(),
      DASHBOARD.CARBON_FOOTPRINT.valueOf(),
    ].includes(selectedDashboardView);
  };

  /**
   * @function getLabelsAdditionalParams
   * @description Function to return additional params based on the view for get labels
   * @returns An object containing additional params
   */
  const getLabelsAdditionalParams = () => {
    if (
      selectedDashboardCustomViews.some(
        (view) => view.id === selectedDashboardView
      )
    ) {
      return {
        dashBoardType: customViewData.dataSource.dashBoardType,
        database: customViewData.dataSource.dataset,
        queryId: customViewData.dataSource.customQueryId,
      };
    }

    if (
      [
        DASHBOARD.COST_SUMMARY.valueOf(),
        DASHBOARD.CREDIT_SUMMARY.valueOf(),
        DASHBOARD.PROJECT_DEEP_DIVE.valueOf(),
      ].includes(selectedDashboardView)
    ) {
      return { dashBoardType: DASHBOARD_TYPES.BILLING };
    }

    if (selectedDashboardView === DASHBOARD.COST_RECOMMENDATION_SUMMARY) {
      return { dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS };
    }

    if (selectedDashboardView === DASHBOARD.CARBON_FOOTPRINT) {
      return { dashBoardType: DASHBOARD_TYPES.CARBON_FOOTPRINT };
    }

    return {};
  };

  /**
   * @function getValues
   * @description Function to format the values
   * @param values values in string format
   * @returns List of values
   */
  const getValues = (values: string) => {
    if (!values) {
      return [];
    }

    if (values.trim().startsWith('{') && values.trim().endsWith('}')) {
      return [values.trim()];
    }
    return values
      .trim()
      .split(',')
      .map((item) => ({ value: item }));
  };

  const setTagData = (data: any) => {
    if (selectedDashboard?.connectorProvider === PROVIDER.OCI) {
      dispatch(
        setAllAvailableTags(
          (data ?? []).map((item: any) => ({
            key: item.LABEL_KEYS,
            values: getValues(item.LABEL_VALUES),
          }))
        )
      );
    } else if (selectedDashboard?.connectorProvider === PROVIDER.AWS) {
      dispatch(
        setAllAvailableTags(
          (data ?? []).map((item: any) => ({
            key: item.rawField,
            label: item.field,
            values: getValues(item.values),
          }))
        )
      );
    } else {
      dispatch(
        setAllAvailableTags(
          (data ?? []).map((item: any) => ({
            key: item.label_keys,
            values: getValues(item.label_values),
          }))
        )
      );
    }
  };

  /**
   * @function getAllLabels
   * @description Function to fetch all the labels for a connection
   */
  const getAllLabels = () => {
    const params = {
      connectorId: selectedDashboard?.connectorId,
      ...getLabelsAdditionalParams(),
    };
    if (!params.connectorId || !params.dashBoardType) {
      return;
    }

    setShowTagsFilter(true);

    const getLabelsCall =
      selectedDashboard?.dashBoardType === MY_DASHBOARD_TYPES.IMPORTS &&
      selectedDashboard.connectorProvider === PROVIDER.AWS
        ? getStaticFileLabels
        : getLabels;

    getLabelsCall(params)
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res.data;
          setTagData(data);
          return;
        }
        dispatch(setAllAvailableTags([]));
      })
      .catch((e) => {
        if (e?.response?.status === 500) {
          setShowTagsFilter(false);
        }
        dispatch(setAllAvailableTags([]));
        onApiCallError(e);
      });
  };

  /**
   * @function onClickApplyTags
   * @description Callback function for apply button in tags modal
   */
  const onClickApplyTags = (selectedTags: TagsType[]) => {
    if (selectedTags.every((tag) => tag.values.length === 0)) {
      dispatch(
        setTagsFilters([
          ...tagsFilters.filter(
            (tagFilter) => tagFilter.dashboardId !== selectedDashboard?.id
          ),
        ])
      );
      setShowTagsModal(false);
      return;
    }

    const previousDashboardFilter: TagsFilterType | undefined =
      tagsFilters.find(
        (tagFilter) => tagFilter.dashboardId === selectedDashboard?.id
      );

    const currentViewTags: TagsType[] = selectedTags.filter(
      (tag) => tag.values.length !== 0
    );

    if (previousDashboardFilter) {
      const filteredViewTags: TagView[] =
        previousDashboardFilter.tagViews.filter(
          (view) => view.viewId !== selectedDashboardView
        );

      dispatch(
        setTagsFilters([
          ...tagsFilters.filter(
            (tagFilter) => tagFilter.dashboardId !== selectedDashboard?.id
          ),
          {
            dashboardId: selectedDashboard?.id ?? '',
            tagViews: [
              ...filteredViewTags,
              {
                viewId: selectedDashboardView,
                tags: currentViewTags,
              },
            ],
          },
        ])
      );
      setShowTagsModal(false);
      return;
    }

    dispatch(
      setTagsFilters([
        ...tagsFilters,
        {
          dashboardId: selectedDashboard?.id ?? '',
          tagViews: [
            {
              viewId: selectedDashboardView,
              tags: currentViewTags,
            },
          ],
        },
      ])
    );
    setShowTagsModal(false);
  };

  const HorizontalNavMenuActionsCta = (
    <div className="flex flex-align-items-center flex-gap-12">
      {dashboardRequestStatus !== REQUEST_STATUS.PROCESSING &&
        permissions.dashboardWrite &&
        customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED && (
          <Button
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.SMALL}
            title={t('dashboardLabels.createCustomView')}
            onClick={() => {
              dispatch(setIsAddNewDashboard(false));
              dispatch(setDashboardType(selectedMyDashboardType));
              setShowDataSourceModal(true);
            }}
            disabled={
              selectedDashboard?.dashBoardType === MY_DASHBOARD_TYPES.SNOWFLAKE
                ? !selectedIntegrationConnection
                : !selectedConnection
            }
          />
        )}
      {showTagsFilterOption() && showTagsFilter && (
        <Button
          type={BUTTON_TYPE.NEUTRAL}
          size={BUTTON_SIZE.SMALL}
          title={
            <div className="flex flex-center flex-gap-8">
              {t('dashboardLabels.tagsLabels.filter')}
              <Icon
                className="filter-icon"
                iconName={ICONS.EQUALIZER_FILL}
                size={ICONS_SIZE.XS}
              />
            </div>
          }
          onClick={() => setShowTagsModal(true)}
        />
      )}
    </div>
  );

  return (
    <div className="dashboard-content flex-fit">
      <div className="upper-dashboard">
        <DashboardHeader />
      </div>
      <div className="page-content">
        <div className="dashboard-navigation">
          <NavigationPath />
          <HorizontalNavigation
            setRequestStatus={setDashboardRequestStatus}
            extraComponent={HorizontalNavMenuActionsCta}
          />
        </div>
        <div className="dashboard-view">
          {!DashboardViewsWithControlComponent.includes(
            selectedDashboardView
          ) && <TagFilters />}
          {dashboardRequestStatus === REQUEST_STATUS.PROCESSING ? (
            <Loader />
          ) : (
            <div>
              {getDashboardView()}
              <TagsDrawer
                show={showTagsModal}
                setShow={setShowTagsModal}
                selectedViewTags={getTagFilterData(
                  tagsFilters,
                  selectedDashboard?.id,
                  selectedDashboardView
                )}
                onClickApply={onClickApplyTags}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectionsDashboard;
