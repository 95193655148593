import { isNumber } from 'utils/validations';

/**
 * @function getSubTotalDue
 * @description Function to calculate the sub total due of a purchase
 * @param term The term purchased for in years
 * @param commitment commitment per hour
 * @param upfrontPaymentAmount upfront cost
 * @returns sub total due amount calculated
 */
export const getSubTotalDue = (
  term: number,
  commitment: string | undefined,
  upfrontPaymentAmount: string | undefined
) => {
  if (
    commitment &&
    isNumber(commitment) &&
    term &&
    (!upfrontPaymentAmount || isNumber(upfrontPaymentAmount))
  ) {
    return (
      Number(commitment) * term * 365 * 24 - Number(upfrontPaymentAmount ?? 0)
    );
  } else {
    return 0;
  }
};

/**
 * @function getMonthlyPayment
 * @description Function to calculate the monthly payment for the purchase
 * @param term The term purchased for in years
 * @param commitment commitment per hour
 * @param upfrontPaymentAmount upfront cost
 * @returns monthly payment amount calculated
 */
export const getMonthlyPayment = (
  term: number,
  commitment: string | undefined,
  upfrontPaymentAmount: string | undefined
) => {
  return getSubTotalDue(term, commitment, upfrontPaymentAmount) / (term * 12);
};
