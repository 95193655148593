const ColorBlock = (text: string) => {
  return (
    <div
      className="color-block"
      data-testid="color-block"
      style={{ backgroundColor: text }}
    />
  );
};

export default ColorBlock;
