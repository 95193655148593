import {
  DASHBOARD_TYPES,
  AGGREGATORS,
  QUERY_FIELDS,
  COMPARATORS,
  CONJUNCTIONS,
  QUERY_VALUES,
} from 'constants/requestBody';
import i18n from 'i18n';
import moment from 'moment';
import { HYPHEN_DATE_FORMAT, YEAR_HYPHEN_MONTH } from 'utils/date';

export const UntaggedResourcesExcelColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo' },
  {
    header: i18n.t('tagCompliance.resource'),
    key: 'resource',
    dataKey: 'resource',
  },
  {
    header: i18n.t('tagCompliance.service'),
    key: 'service',
    dataKey: 'service',
  },
  {
    header: i18n.t('tagCompliance.tagsMissing'),
    key: 'tagsMissing',
    dataKey: 'tagsMissing',
  },
];

export const BASE_AWS_QUERY = {
  columns: [
    {
      label: 'resourceId',
      field: QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
    },
    {
      label: 'service',
      field: QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
    },
    {
      label: 'region',
      field: QUERY_FIELDS.PRODUCT_REGION,
    },
  ],
  aggregators: [
    {
      label: 'resourceId',
      function: AGGREGATORS.DISTINCT,
    },
  ],
  groupBy: ['resourceId', 'service', 'region'],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
          comparator: COMPARATORS.NOT_LIKE,
          value: QUERY_VALUES.LOGGING,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
          comparator: COMPARATORS.NOT_EQUAL_TO,
          value: '',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
          comparator: COMPARATORS.NOT_EQUAL_TO,
          value: QUERY_VALUES.PUT_CROSS_ACCOUNT_EVENTS,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
          comparator: COMPARATORS.NOT_EQUAL_TO,
          value: QUERY_VALUES.AMAZON_SIMPLE_NOTIFICATION_SERVICE,
        },
      ],
      conjunctToNextGroup: CONJUNCTIONS.AND,
    },
    {
      filters: [
        {
          field: QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          comparator: COMPARATORS.EQUALS,
          value: moment().format(YEAR_HYPHEN_MONTH),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ],
      conjunctToNextGroup: CONJUNCTIONS.AND,
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: false,
};

export const BASE_GCP_QUERY = {
  columns: [
    {
      field: '*',
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
  cached: true,
  orderBy: [],
  aggregators: [
    {
      label: 'imageName',
      function: AGGREGATORS.DISTINCT,
    },
  ],
  subQuery: {
    columns: [
      {
        label: 'service',
        field: QUERY_FIELDS.SERVICE_DESCRIPTION,
      },
      {
        label: 'projectName',
        field: QUERY_FIELDS.PROJECT_ID,
      },
      {
        label: 'resourceId',
        field: QUERY_FIELDS.BILLING_RESOURCE_NAME,
      },
      {
        label: 'resourceGlobalName',
        field: QUERY_FIELDS.RESOURCE_GLOBAL_NAME,
      },
    ],
    structColumns: [
      {
        label: 'tag',
        field: QUERY_FIELDS.LABELS,
      },
    ],
    groupBy: ['resourceId', 'resourceGlobalName', 'projectName', 'service'],
    aggregators: [],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.BILLING_RESOURCE_NAME,
            comparator: COMPARATORS.NOT_LIKE,
            value: '%instances%',
          },
        ],
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      {
        filters: [
          {
            field: QUERY_FIELDS.RESOURCE_GLOBAL_NAME,
            comparator: COMPARATORS.NOT_LIKE,
            value: '%disk%',
          },
        ],
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      {
        filters: [
          {
            field: QUERY_FIELDS.RESOURCE_GLOBAL_NAME,
            comparator: COMPARATORS.NOT_LIKE,
            value: '%jobs%',
          },
        ],
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      {
        filters: [
          {
            field: QUERY_FIELDS.SERVICE_DESCRIPTION,
            comparator: COMPARATORS.IN,
            value:
              "('Compute Engine','Cloud Storage','BigQuery','Cloud Functions')",
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.RESOURCE_GLOBAL_NAME,
            comparator: COMPARATORS.NOT_EQUAL_TO,
            value: '',
          },
        ],
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      {
        filters: [
          {
            field: QUERY_FIELDS.USAGE_END_TIME,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: `(TIMESTAMP('${moment()
              .subtract(1, 'day')
              .format(HYPHEN_DATE_FORMAT)} 00:00:00 UTC'))`,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.USAGE_END_TIME,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: `(TIMESTAMP('${moment()
              .subtract(1, 'day')
              .format(HYPHEN_DATE_FORMAT)} 23:00:00 UTC'))`,
          },
        ],
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
    ],
  },
};

export const getBaseAzureQuery = (azureBillingTableName: string) => ({
  columns: [
    {
      label: 'resourceId',
      field: QUERY_FIELDS.RESOURCE_ID,
    },
    {
      label: 'service',
      field: QUERY_FIELDS.CONSUMED_SERVICE,
    },
  ],
  groupBy: ['resourceId', 'service'],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.DATE,
          comparator: COMPARATORS.EQUALS,
          value: `(SELECT MAX(Date) FROM ${azureBillingTableName})`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});
