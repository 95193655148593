import i18n from 'i18n';
import { store } from 'redux/store';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const CreditDistributionByLocationExportColumns = [
  {
    title: '#',
    key: 'index',
    width: 10,
    alignment: 'center',
    dataIndex: 'index',
  },
  {
    title: i18n.t('gcpCreditDistributionByLocation.location'),
    key: 'region',
    dataIndex: 'region',
    width: 25,
    alignment: 'center',
  },
  {
    title: i18n.t('gcpCreditDistributionByLocation.credit', {
      currencySymbol: currencySymbol,
    }),
    key: 'credit',
    dataIndex: 'credit',
    width: 20,
    alignment: 'right',
  },
];

export const CostAndCreditBreakdownExportColumns = [
  {
    title: '#',
    key: 'index',
    width: 10,
    alignment: 'center',
    dataIndex: 'index',
  },
  {
    title: i18n.t('gcpCostAndCreditBreakdown.month'),
    key: 'month',
    dataIndex: 'month',
    width: 15,
    alignment: 'center',
  },
  {
    title: i18n.t('gcpCostAndCreditBreakdown.originalCost'),
    key: 'cost',
    dataIndex: 'cost',
    width: 20,
    alignment: 'right',
  },
  {
    title: i18n.t('gcpCostAndCreditBreakdown.costAfterCredit'),
    key: 'costAfterCredit',
    dataIndex: 'costAfterCredit',
    width: 20,
    alignment: 'right',
  },
  {
    title: i18n.t('gcpCostAndCreditBreakdown.credit'),
    key: 'credit',
    dataIndex: 'credit',
    width: 20,
    alignment: 'right',
  },
];

export const CreditDistributionByProjectExportColumns = [
  {
    title: '#',
    key: 'index',
    width: 10,
    alignment: 'center',
    dataIndex: 'index',
  },
  {
    title: i18n.t('gcpCreditDistributionByProject.project'),
    key: 'project',
    width: 30,
    alignment: 'center',
    dataIndex: 'project',
  },
  {
    title: i18n.t('gcpCreditDistributionByProject.credit'),
    key: 'credit',
    width: 20,
    alignment: 'right',
    dataIndex: 'credit',
  },
];
