import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Empty, message } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import moment from 'moment';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedProvider } from 'redux/providerSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import {
  ruleEngine,
  setIsEditForm,
  setIsTryAgain,
  setRuleEngineComponent,
  setRuleEngineFormData,
} from 'redux/ruleEngineSlice';
import Table from 'components/Table';
import Button from 'components/Button';
import SearchInput from 'components/Input';
import NavigationPath from 'components/NavigationPath';
import Pagination from 'components/Pagination';
import TableFilterDropdown from 'components/TableFilterDropdown';
import { REQUEST_STATUS } from 'constants/requestBody';
import { INPUT_SIZE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { CONNECTION_QUICK_ACTIONS } from 'constants/quickAction';
import QuickActionMenu from 'components/QuickActionMenu';
import DeleteModal from 'components/DeleteModal';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { CLOUD_PROVIDERS_LIST } from 'constants/cloudProviders';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { DATE_FORMAT } from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import StatusElement from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/StatusElement';

import {
  DEFAULT_FORM_DATA,
  RuleEngineComponents,
  RulesetQuickActions,
} from './constants';
import { RulesetsType } from './types';
import { deleteRuleset, getAllRulesets } from './services';
import {
  getQuickActionMenuItems,
  updateTheRulesetAutopilotStatus,
} from './utils';

import './index.scss';

const RuleEnginePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { permissions } = useSelector(userAuthorization);
  const { ruleEngineFormData } = useSelector(ruleEngine);

  const [rulesets, setRulesets] = useState<RulesetsType[]>([]);
  const [rulesetsRequestStatus, setRulesetsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [searchText, setSearchText] = useState('');
  const [filterProviders, setFilterProviders] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRulesetsCount, setTotalRulesetsCount] = useState(0);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteRequestStatus, setDeleteRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    fetchRulesets(currentPage, searchText, filterProviders);
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function fetchRulesets
   * @description Function to fetch the rulesets data
   * @param page page number for paginated response
   * @param searchKey search text for filter
   * @param providers list of providers for which the ruleset are filtered
   */
  const fetchRulesets = (
    page: number,
    searchKey: string,
    providers: string[] | null
  ) => {
    setRulesetsRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      page: page - 1,
      size: PAGINATION_SIZE,
      key: searchKey,
      provider: providers?.toString(),
    };

    getAllRulesets(params)
      .then((res: any) => {
        setRulesets(res?.data?.responseData?.content);
        setTotalRulesetsCount(res?.data?.responseData?.totalElements ?? 0);
        setRulesetsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRulesetsRequestStatus);
        setRulesets([]);
        setTotalRulesetsCount(0);
      });
  };

  /**
   * @function onDeleteRuleset
   * @description Function to delete a ruleset
   */
  const onDeleteRuleset = () => {
    setDeleteRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = { rulesetId: ruleEngineFormData.rulesetId };
    deleteRuleset(params)
      .then((res: any) => {
        if (res?.status === 200) {
          const page = rulesets.length === 1 ? currentPage - 1 : currentPage;
          setCurrentPage(page);
          fetchRulesets(page, searchText, filterProviders);
          message.success(
            t('ruleEngineLabels.successfullyDeletedRuleset', {
              rulesetName: ruleEngineFormData.ruleSetName,
            })
          );
          setDeleteRequestStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
        } else {
          setDeleteRequestStatus(REQUEST_STATUS.ERROR);
          message.error(
            t('ruleEngineLabels.errorDeletingRuleset', {
              rulesetName: ruleEngineFormData.ruleSetName,
            })
          );
          dispatch(setRuleEngineFormData(DEFAULT_FORM_DATA));
        }
        setShowDeleteModal(false);
      })
      .catch((e: any) => {
        onApiCallError(e, true, setDeleteRequestStatus);
        setShowDeleteModal(false);
      });
  };

  const onStatusUpdateSuccess = () => {
    fetchRulesets(currentPage, searchText, filterProviders);
  };

  const handleQuickAction = (value: RulesetsType, action: string) => {
    switch (action) {
      case RulesetQuickActions.EDIT:
        dispatch(setRuleEngineFormData(value));
        dispatch(setSelectedProvider(value.cloudProvider));
        dispatch(setIsEditForm(true));
        dispatch(setIsTryAgain(false));
        dispatch(setRuleEngineComponent(RuleEngineComponents.RULESETS_FORM));
        navigate(NAVIGATION_MENU_PATH.CREATE_RULESET);
        break;

      case RulesetQuickActions.DELETE:
        dispatch(setRuleEngineFormData(value));
        setShowDeleteModal(true);
        break;

      case RulesetQuickActions.ACTIVATE:
        updateTheRulesetAutopilotStatus(
          RulesetQuickActions.ACTIVATE,
          value,
          onStatusUpdateSuccess
        );
        break;

      case RulesetQuickActions.DEACTIVATE:
        updateTheRulesetAutopilotStatus(
          RulesetQuickActions.DEACTIVATE,
          value,
          onStatusUpdateSuccess
        );
        break;
    }
  };

  /**
   * @function getCloudProviderFilterDropdown
   * @description Function to return the cloud provider dropdown for table filter
   * @param Object containing the filter dropdown props
   * @returns JSX element
   */
  const getCloudProviderFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
  }: FilterDropdownProps) => (
    <TableFilterDropdown
      allKeys={filters ?? []}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      designVersion2
    />
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (text: any) => (currentPage - 1) * 10 + text + 1,
      width: '3%',
    },
    {
      title: t('ruleEngineLabels.rulesetName'),
      dataIndex: 'ruleSetName',
      key: 'ruleSetName',
      width: '20%',
      filters: CLOUD_PROVIDERS_LIST,
      filterIcon: <Icon iconName={ICONS.FILTER_2_FILL} />,
      filterDropdown: getCloudProviderFilterDropdown,
      render: (text: any, record: RulesetsType) => (
        <div className="flex flex-gap-8 flex-align-items-center">
          <div className="logo">
            <img
              src={getProviderSmallLogo(record.cloudProvider)}
              alt={`${record.cloudProvider} Logo`}
            />
          </div>
          {text}
        </div>
      ),
    },
    {
      title: t('ruleEngineLabels.description'),
      dataIndex: 'description',
      key: 'description',
      render: (text: any) => {
        if (!text) {
          return '';
        }
        return text.length > 70 ? text.slice(0, 70) + '...' : text;
      },
      width: '40%',
    },
    {
      title: t('ruleEngineLabels.autoPilot'),
      dataIndex: 'autopilotOn',
      key: 'autopilotOn',
      render: (isAutoPilotEnabled: boolean) => {
        const autoPilotDisplayText = isAutoPilotEnabled
          ? t('ruleEngineLabels.active')
          : t('ruleEngineLabels.deactivated');
        const statusClassName = isAutoPilotEnabled
          ? 'status-chip status-chip-green font-small-bold'
          : 'status-chip status-chip-red font-small-bold';
        return (
          <StatusElement
            className={statusClassName}
            statusKey={autoPilotDisplayText}
            status={autoPilotDisplayText}
          />
        );
      },
      width: '15%',
    },
    {
      title: t('ruleEngineLabels.timeStamp'),
      dataIndex: 'lastUpdatedOn',
      key: 'lastUpdatedOn',
      render: (text: any) => moment(text).format(DATE_FORMAT),
      width: '15%',
    },
    {
      title: t('ruleEngineLabels.actions'),
      dataIndex: 'quickAction',
      key: 'quickAction',
      render: (_text: string, record: RulesetsType) => (
        <QuickActionMenu
          quickActions={getQuickActionMenuItems(record.autopilotOn)}
          quickActionHandler={(action: string) => {
            handleQuickAction(record, action);
          }}
          disabled={!permissions.costControlModify}
        />
      ),
      width: '7%',
    },
  ];

  /**
   * @function onHandleCreateRuleset
   * @description Callback function for create ruleset button click
   */
  const onHandleCreateRuleset = () => {
    dispatch(setIsEditForm(false));
    dispatch(setIsTryAgain(false));
    navigate(NAVIGATION_MENU_PATH.CREATE_RULESET);
    dispatch(setRuleEngineComponent(RuleEngineComponents.PROVIDER_LIST));
  };

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    fetchRulesets(page, searchText, filterProviders);
  };

  /**
   * @function handleSearchRulesets
   * @description Callback function for search text change
   * @param e text input event
   */
  const handleSearchRulesets = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchText(e.target.value);
    fetchRulesets(1, e.target.value, filterProviders);
  };

  return (
    <div className="rule-engine-list flex flex-column">
      <header className="rule-engine-list-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="modal-heading">{t('navigationMenu.ruleEngine')}</div>
        {permissions.costControlWrite && (
          <Button
            onClick={onHandleCreateRuleset}
            title={t('ruleEngineLabels.createRuleset')}
          />
        )}
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="rule-engine-list-content flex flex-column flex-fit">
          <SearchInput
            placeholder={t('ruleEngineLabels.searchByRulesetName')}
            type="search"
            onChange={debounce(handleSearchRulesets, DEBOUNCE_TIME_DELAY)}
            size={INPUT_SIZE.SMALL}
          />
          <Table
            rootClassName="table-section"
            pagination={false}
            dataSource={rulesets?.map((ruleset, index) => ({
              ...ruleset,
              key: index,
            }))}
            columns={columns}
            loading={rulesetsRequestStatus === REQUEST_STATUS.PROCESSING}
            designVersion2
            locale={{
              emptyText: rulesetsRequestStatus !==
                REQUEST_STATUS.PROCESSING && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    rulesetsRequestStatus === REQUEST_STATUS.ERROR
                      ? t('graphErrorMessage')
                      : t('ruleEngineLabels.noRulesetMessage')
                  }
                />
              ),
            }}
            onChange={(_pagination: any, filters: any) => {
              setCurrentPage(1);
              setFilterProviders(filters.ruleSetName);
              fetchRulesets(1, searchText, filters.ruleSetName);
            }}
          />
        </div>
      </div>
      <div className="page-footer flex">
        {totalRulesetsCount > 0 && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalRulesetsCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </div>
      {showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={onDeleteRuleset}
          loading={deleteRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default RuleEnginePage;
