import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchStaticPalletes
 * @description Function to fetch default palletes
 * @return axios response from GET request
 */
export const fetchStaticPalletes = async () => {
  return ServiceCalls.get(APIS.GET_STATIC_PALLETES);
};

/**
 * @function fetchDefaultPallete
 * @description Function to fetch default palletes
 * @return axios response from GET request
 */
export const fetchDefaultPallete = async () => {
  return ServiceCalls.get(APIS.GET_DEFAULT_PALLETE);
};

/**
 * @function fetchChartColorPreferences
 * @description Function to fetch user's chart color preferences
 * @return axios response from GET request
 */
export const fetchChartColorPreferences = async () => {
  return ServiceCalls.get(APIS.GET_CHART_COLOR_PREFRENCE);
};

/**
 * @function saveDefaultPallete
 * @description Function to set default palletes
 * @return axios response from PUT request
 */
export const saveDefaultPallete = async (templateId: string) => {
  return ServiceCalls.put(
    `${APIS.SET_DEFAULT_PALLETE}?templateId=${templateId}`,
    {}
  );
};

/**
 * @function createCustomPallete
 * @description Function to create a new custom pallete
 * @return axios response from POST request
 */
export const createCustomPallete = async (body: any) => {
  return ServiceCalls.post(APIS.CREATE_CUSTOM_PALLETE, body);
};

/**
 * @function saveChartColors
 * @description Function to save user's chart color preferences
 * @return axios response from POST request
 */
export const saveChartColors = async (body: any) => {
  return ServiceCalls.post(APIS.SET_CHART_COLOR_PREFRENCE, body);
};
