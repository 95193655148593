import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import Table from 'components/Table';
import SelectDropdown from 'components/Select';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { evaluateRequestArray } from 'utils/handleErrors';
import DatePicker from 'components/DatePicker';
import ColumnLineChart from 'components/ColumnLineChart';
import { COLORS } from 'constants/graphConfig';
import { INPUT_SIZE } from 'constants/appearance';
import { HYPHEN_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'utils/date';
import { generateGraphColors } from 'utils/dashboardUtils';

import { getChartExcelDataWithDateFilter } from '../../utils';
import {
  UNIT_COST_TIME_PERIOD_OPTIONS,
  UnitCostTimePeriod,
} from '../../constants';

type StackedColumnLineWithUnitCostChartsProps = {
  title: string;
  graph: string;
  data: any[];
  xField: string;
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  dateRange: string[];
  setDateRange: (val: string[]) => void;
  pdfView: boolean;
  columns: any[];
  tableData: any[];
  selectedAccounts: string[];
  unitCostTimePeriod: UnitCostTimePeriod;
  setUnitCostTimePeriod: (val: UnitCostTimePeriod) => void;
};

const StackedColumnLineWithUnitCostCharts = ({
  title,
  graph,
  data,
  xField,
  requestStatus,
  isTableView,
  setIsTableView,
  dateRange,
  setDateRange,
  pdfView,
  columns,
  tableData,
  selectedAccounts,
  unitCostTimePeriod,
  setUnitCostTimePeriod,
}: StackedColumnLineWithUnitCostChartsProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [chartColors, setChartColors] = useState<string[]>([]);

  useEffect(() => {
    setChartColors(generateGraphColors(4));
  }, []);

  const onChangeDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT)
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment(dateString[1], MONTH_YEAR_FORMAT)
        .endOf('month')
        .format(HYPHEN_DATE_FORMAT),
    ]);
  };

  const getComponent = () =>
    isTableView ? (
      <Table
        pagination={false}
        dataSource={tableData.map((item: any) => ({
          ...item,
          key: item[xField],
        }))}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2
        fillContainer
      />
    ) : (
      <ColumnLineChart
        plots={[
          {
            type: 'line',
            options: {
              data: data,
              xField: 'time',
              yField: unitCostTimePeriod,
              seriesField: 'unitCostType',
              xAxis: {
                title: {
                  text: t('awsRecommendationSummary.month'),
                },
              },
              yAxis: {
                line: {
                  style: {
                    stroke: COLORS.fnGrey,
                    lineWidth: 1,
                    opacity: 0.7,
                  },
                },
                grid: null,
                position: 'right',
                title: {
                  text: t('avgUnitCost'),
                },
              },
              color: chartColors.slice(2, 4),
              line: {
                style: {
                  lineDash: [5, 5],
                },
              },
              animation: !pdfView,
            },
          },
          {
            type: 'column',
            options: {
              data: data,
              xField: 'time',
              yField: 'value',
              isStack: true,
              seriesField: 'type',
              groupField: 'type',
              maxColumnWidth: 24,
              xAxis: {
                title: {
                  text: t('awsRecommendationSummary.month'),
                },
              },
              yAxis: {
                title: {
                  text: t('computeDashboard.cost', {
                    currencySymbol: currencySymbol,
                  }),
                },
                line: {
                  style: {
                    stroke: COLORS.fnGrey,
                    lineWidth: 1,
                    opacity: 0.7,
                  },
                },
              },
              color: chartColors.slice(0, 2),
              animation: !pdfView,
            },
          },
        ]}
        prefixSymbol={currencySymbol}
        groupingField="type"
      />
    );

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(dateRange[0], HYPHEN_DATE_FORMAT),
          moment(dateRange[1], HYPHEN_DATE_FORMAT),
        ]}
        onChange={onChangeDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
      <SelectDropdown
        className="unit-cost-time-period"
        options={UNIT_COST_TIME_PERIOD_OPTIONS}
        value={`Unit Cost Period - ${
          UNIT_COST_TIME_PERIOD_OPTIONS.find(
            (option) => option.value === unitCostTimePeriod
          )!.label
        }`}
        onSelect={setUnitCostTimePeriod}
        size={INPUT_SIZE.SMALL}
        designVersion2
      />
    </div>
  );

  return (
    <div
      className={`stacked-column-charts graph-card ${graph} full-height flex flex-column flex-fit`}
      id="graph-container"
    >
      <GraphHeader
        heading={title}
        graphName={graph}
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getChartExcelDataWithDateFilter({
          sheetName: title,
          columns,
          data: tableData,
          connectorName: selectedDashboard?.connectorName ?? '',
          provider: selectedDashboard!.connectorProvider,
          dateRange,
          selectedAccounts,
        })}
        designVersion2
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default StackedColumnLineWithUnitCostCharts;
