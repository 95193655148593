import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormLabel } from 'components/FormLabel';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import SelectDropdown from 'components/Select';
import { REQUEST_STATUS } from 'constants/requestBody';
import { validateEmptyField } from 'utils/validations';
import { fetchDatasetTables } from '../../utils';

type GCPBillingDetailsProps = {
  gcpDatasetOptionsRequestStatus: string;
  gcpDatasetOptions: string[];
  gcpDatasetTableOptions: string[];
  setGcpDatasetTableOptions: (val: string[]) => void;
};

const GCPBillingDetails = ({
  gcpDatasetOptionsRequestStatus,
  gcpDatasetOptions,
  gcpDatasetTableOptions,
  setGcpDatasetTableOptions,
}: GCPBillingDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection, isTryAgain } =
    useSelector(cloudConnection);

  const [gcpDatasetRequiredValidation, setGcpDatasetRequiredValidation] =
    useState('');
  const [gcpDatasetTableRequestStatus, setGcpDatasetTableRequestStatus] =
    useState('');
  const [
    gcpDatasetTableRequiredValidation,
    setGcpDatasetTableRequiredValidation,
  ] = useState('');

  useEffect(() => {
    if ((isEditConnection || isTryAgain) && currentConnection?.billingDataset) {
      fetchDatasetTables(
        currentConnection?.jsonFileId,
        currentConnection?.projectId,
        currentConnection?.billingDataset,
        setGcpDatasetTableOptions,
        setGcpDatasetTableRequestStatus,
        isEditConnection,
        currentConnection?.connectorId
      );
    }
  }, []);

  const getDatasetDropdown = (options: string[]) => (
    <>
      {options?.map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </>
  );

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t(`connectionCSPForm.gcpDataset`)} />
        <SelectDropdown
          value={currentConnection?.billingDataset}
          loading={gcpDatasetOptionsRequestStatus === REQUEST_STATUS.PROCESSING}
          menu={getDatasetDropdown(gcpDatasetOptions)}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                billingDataset: value,
                billingTableName: '',
              })
            );
            setGcpDatasetRequiredValidation('');
            fetchDatasetTables(
              currentConnection?.jsonFileId,
              currentConnection?.projectId,
              value,
              setGcpDatasetTableOptions,
              setGcpDatasetTableRequestStatus,
              isEditConnection,
              currentConnection?.connectorId
            );
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.billingDataset,
              t(`connectionCSPForm.gcpDataset`),
              setGcpDatasetRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${gcpDatasetRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {gcpDatasetRequiredValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t(`connectionCSPForm.gcpDatasetTable`)} />
        <SelectDropdown
          value={currentConnection?.billingTableName}
          loading={gcpDatasetTableRequestStatus === REQUEST_STATUS.PROCESSING}
          menu={getDatasetDropdown(gcpDatasetTableOptions)}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                billingTableName: value,
              })
            );
            setGcpDatasetTableRequiredValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.billingTableName,
              t(`connectionCSPForm.gcpDatasetTable`),
              setGcpDatasetTableRequiredValidation
            )
          }
          designVersion2
        />
        <span
          style={{
            display: `${gcpDatasetTableRequiredValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {gcpDatasetTableRequiredValidation}
        </span>
      </div>
    </div>
  );
};

export default GCPBillingDetails;
