import i18n from 'i18n';

export enum CustomDashboardChartTypes {
  TABLE = 'TABLE',
  CHART = 'CHART',
}

export const CUTSOM_DASHBOARD_CHART_TYPE_OPTIONS = [
  {
    key: CustomDashboardChartTypes.TABLE,
    label: i18n.t('customDashboard.optionsLabels.table'),
  },
  {
    key: CustomDashboardChartTypes.CHART,
    label: i18n.t('customDashboard.optionsLabels.chart'),
  },
];

export enum CUSTOM_VIEW_PUBLISH_STATUS {
  PUBLISH = 'PUBLISH',
  DRAFT = 'DRAFT',
}
