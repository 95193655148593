import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PurchaseReservedInstancesTabs } from 'pages/PurchasePage/components/ReservedInstances/constants';
import { CommittedUseDiscountsTabs } from 'pages/PurchasePage/components/CommittedUseDiscounts/constants';
import {
  PurchaseAwsEc2RisType,
  PurchaseAwsOpenSearchRisType,
  PurchaseAwsElastiCacheRisType,
  PurchaseAwsMemoryDbRisType,
  PurchaseAwsRdsRisType,
  PurchaseAwsRedshiftRisType,
} from 'pages/PurchasePage/components/ReservedInstances/types';
import { PurchaseSavingPlansType } from 'pages/PurchasePage/components/AwsSavingsPlans/components/PurchaseSavingPlans/types';
import { CommitmentsSwitchEnum } from 'pages/PurchasePage/constants';
import { ConnectionListType } from 'types/dashboard';
import { RootState } from './store';

export interface PurchaseState {
  purchaseConnectionList: ConnectionListType[];
  selectedConnection: ConnectionListType | null;
  selectedPurchasePageView: string;
  savingPlansCartData: PurchaseSavingPlansType[];
  awsRegions: string[];
  awsRdsRiCartData: PurchaseAwsRdsRisType[];
  awsRedshiftRiCartData: PurchaseAwsRedshiftRisType[];
  awsOpenSearchRiCartData: PurchaseAwsOpenSearchRisType[];
  riProductDescriptions: string[];
  selectedReservedInstancesTab: string;
  selectedCommittedUseDiscountsTab: string;
  awsEc2RiCartData: PurchaseAwsEc2RisType[];
  awsPlatforms: string[];
  awsMemoryDbRiCartData: PurchaseAwsMemoryDbRisType[];
  awsElastiCacheCartData: PurchaseAwsElastiCacheRisType[];
  selectedGcpProjectId: string;
  commitmentsSwitch: string;
}

const initialState: PurchaseState = {
  purchaseConnectionList: [],
  selectedConnection: null,
  selectedPurchasePageView: '',
  savingPlansCartData: [],
  awsRegions: [],
  awsRdsRiCartData: [],
  awsRedshiftRiCartData: [],
  awsOpenSearchRiCartData: [],
  riProductDescriptions: [],
  selectedReservedInstancesTab: PurchaseReservedInstancesTabs.EC2,
  selectedCommittedUseDiscountsTab: CommittedUseDiscountsTabs.COMPUTE_ENGINE,
  awsEc2RiCartData: [],
  awsPlatforms: [],
  awsMemoryDbRiCartData: [],
  awsElastiCacheCartData: [],
  selectedGcpProjectId: '',
  commitmentsSwitch: CommitmentsSwitchEnum.PURCHASED,
};

export const purchaseSlice = createSlice({
  name: 'purchaseSlice',
  initialState,
  reducers: {
    setPurchaseConnectionList: (
      state,
      action: PayloadAction<ConnectionListType[]>
    ) => {
      state.purchaseConnectionList = action.payload;
    },
    setSelectedConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.selectedConnection = action.payload;
    },
    setSelectedPurchasePageView: (state, action: PayloadAction<string>) => {
      state.selectedPurchasePageView = action.payload;
    },
    setSavingPlansCartData: (
      state,
      action: PayloadAction<PurchaseSavingPlansType[]>
    ) => {
      state.savingPlansCartData = action.payload;
    },
    setAwsRegions: (state, action: PayloadAction<string[]>) => {
      state.awsRegions = action.payload;
    },
    setAwsRdsRisCartData: (
      state,
      action: PayloadAction<PurchaseAwsRdsRisType[]>
    ) => {
      state.awsRdsRiCartData = action.payload;
    },
    setAwsRedshiftRisCartData: (
      state,
      action: PayloadAction<PurchaseAwsRedshiftRisType[]>
    ) => {
      state.awsRedshiftRiCartData = action.payload;
    },
    setAwsOpenSearchRisCartData: (
      state,
      action: PayloadAction<PurchaseAwsOpenSearchRisType[]>
    ) => {
      state.awsOpenSearchRiCartData = action.payload;
    },
    setRiProductDescriptions: (state, action: PayloadAction<string[]>) => {
      state.riProductDescriptions = action.payload;
    },
    setSelectedReservedInstancesTab: (state, action: PayloadAction<string>) => {
      state.selectedReservedInstancesTab = action.payload;
    },
    setSelectedCommittedUseDiscountsTab: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedCommittedUseDiscountsTab = action.payload;
    },
    setAwsEc2RisCartData: (
      state,
      action: PayloadAction<PurchaseAwsEc2RisType[]>
    ) => {
      state.awsEc2RiCartData = action.payload;
    },
    setAwsPlatforms: (state, action: PayloadAction<string[]>) => {
      state.awsPlatforms = action.payload;
    },
    setAwsMemoryDbRisCartData: (
      state,
      action: PayloadAction<PurchaseAwsMemoryDbRisType[]>
    ) => {
      state.awsMemoryDbRiCartData = action.payload;
    },
    setAwsElastiCacheRisCartData: (
      state,
      action: PayloadAction<PurchaseAwsElastiCacheRisType[]>
    ) => {
      state.awsElastiCacheCartData = action.payload;
    },
    setSelectedGcpProjectId: (state, action: PayloadAction<string>) => {
      state.selectedGcpProjectId = action.payload;
    },
    setCommitmentsSwitch: (state, action: PayloadAction<string>) => {
      state.commitmentsSwitch = action.payload;
    },
  },
});

export const selectPurchase = (state: RootState) => state.purchase;
export const {
  setSelectedConnection,
  setPurchaseConnectionList,
  setSelectedPurchasePageView,
  setSavingPlansCartData,
  setAwsRegions,
  setAwsRdsRisCartData,
  setAwsRedshiftRisCartData,
  setAwsOpenSearchRisCartData,
  setRiProductDescriptions,
  setSelectedReservedInstancesTab,
  setSelectedCommittedUseDiscountsTab,
  setAwsEc2RisCartData,
  setAwsPlatforms,
  setAwsMemoryDbRisCartData,
  setAwsElastiCacheRisCartData,
  setSelectedGcpProjectId,
  setCommitmentsSwitch,
} = purchaseSlice.actions;
export default purchaseSlice.reducer;
