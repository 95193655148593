import { Divider } from 'antd';
import Conditions from './components/Conditions';

import Dimensions from './components/Dimensions';
import Filters from './components/Filters';
import Metrics from './components/Metrics';
import Sort from './components/Sort';

import './index.scss';

const TableOptions = () => {
  return (
    <div className="custom-table-options">
      <Dimensions />
      <Divider rootClassName="options-divider" />
      <Metrics />
      <Divider rootClassName="options-divider" />
      <Sort />
      <Divider rootClassName="options-divider" />
      <Filters />
      <Divider rootClassName="options-divider" />
      <Conditions />
    </div>
  );
};

export default TableOptions;
