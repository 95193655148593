import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { selectDashboard } from 'redux/dashboardSlice';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { evaluateRequestArray } from 'utils/handleErrors';
import ColumnChart from 'components/ColumnChart';
import DatePicker from 'components/DatePicker';
import { HYPHEN_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'utils/date';
import { isDateRangeDisabled, onOpenChange } from 'pages/OverviewPage/utils';
import { RangeValue as MomentRangeValue } from 'pages/OverviewPage/types';

import { getChartExcelDataWithDateFilter } from '../../utils';

type StackedColumnChartsProps = {
  title: string;
  graph: string;
  data: any[];
  xField: string;
  seriesField?: string;
  xTitle?: string;
  yTitle: string;
  isStack?: boolean;
  isGroup?: boolean;
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  dateRange: string[];
  setDateRange: (val: string[]) => void;
  pdfView: boolean;
  columns: any[];
  tableData: any[];
  selectedAccounts: string[];
  prefixSymbol?: string;
  suffixSymbol?: string;
  maxMonths?: number;
};

const StackedColumnCharts = ({
  title,
  graph,
  data,
  xField,
  seriesField,
  xTitle,
  yTitle,
  isStack = true,
  isGroup,
  requestStatus,
  isTableView,
  setIsTableView,
  dateRange,
  setDateRange,
  pdfView,
  columns,
  tableData,
  selectedAccounts,
  prefixSymbol,
  suffixSymbol,
  maxMonths,
}: StackedColumnChartsProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);

  const [dates, setDates] = useState<MomentRangeValue>(null);

  const onChangeDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT)
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment(dateString[1], MONTH_YEAR_FORMAT)
        .endOf('month')
        .format(HYPHEN_DATE_FORMAT),
    ]);
  };

  const getComponent = () =>
    isTableView ? (
      <Table
        pagination={false}
        dataSource={tableData.map((item: any) => ({
          ...item,
          key: item[xField],
        }))}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2
        fillContainer
      />
    ) : (
      <ColumnChart
        data={data}
        xField={xField}
        yField="value"
        isStack={isStack}
        isGroup={isGroup}
        groupingField={seriesField}
        xTitle={xTitle ?? t('awsRecommendationSummary.month')}
        yTitle={yTitle}
        showAllLegend={pdfView}
        prefixSymbol={prefixSymbol}
        suffixSymbol={suffixSymbol}
        disableAnimation={pdfView}
      />
    );

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(dateRange[0], HYPHEN_DATE_FORMAT),
          moment(dateRange[1], HYPHEN_DATE_FORMAT),
        ]}
        value={
          dates || [
            moment(dateRange[0], HYPHEN_DATE_FORMAT),
            moment(dateRange[1], HYPHEN_DATE_FORMAT),
          ]
        }
        onOpenChange={(open: boolean) => onOpenChange(open, setDates)}
        onCalendarChange={(val: MomentRangeValue) => setDates(val)}
        onChange={onChangeDateRange}
        disabledDate={(current: Moment) =>
          isDateRangeDisabled(current, dates, maxMonths)
        }
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  return (
    <div
      className={`stacked-column-charts graph-card ${graph} full-height flex flex-column flex-fit`}
      id="graph-container"
    >
      <GraphHeader
        heading={title}
        graphName={graph}
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getChartExcelDataWithDateFilter({
          sheetName: title,
          columns,
          data: tableData,
          connectorName: selectedDashboard?.connectorName ?? '',
          provider: selectedDashboard!.connectorProvider,
          dateRange,
          selectedAccounts,
        })}
        designVersion2
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default StackedColumnCharts;
