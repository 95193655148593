import i18n from 'i18n';

export const BUSINESS_UNIT_QUICKACTIONS = [
  { id: 'UPDATE', title: i18n.t('costAllocations.setupBusinessUnit.update') },
];

export const COST_ALLOCATION_SAVE_STATUS = {
  SAVED_AS_DRAFT: 'SAVED_AS_DRAFT',
  SUBMITTED: 'SUBMITTED',
};
