import axios from 'axios';
import { Empty, message } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProfileIcon } from 'assets/icons';
import Button from 'components/Button';
import QuickActionMenu from 'components/QuickActionMenu';
import SelectDashboardViews from 'components/SelectDashboardViews';
import { customDashboard } from 'redux/customDashboardSlice';
import { selectDashboard } from 'redux/dashboardSlice';
import ConfirmModal from 'components/ConfirmModal';
import { onApiCallError } from 'utils/handleErrors';
import { QUERY_FIELDS, REQUEST_STATUS } from 'constants/requestBody';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { CUSTOM_VIEW_PUBLISH_STATUS } from 'pages/CustomDashboardPage/constants';
import AccessibleDiv from 'components/AccessibleDiv';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  getDashboardDatasourceId,
  getDashboardDatasourceName,
} from 'utils/dashboardUtils';
import DashboardComponent from 'components/DashboardComponent';

import {
  SHARED_WITH_ACTIONS,
  SHARED_WITH_QUICKACTIONS,
  SHARE_DASHBOARD_COMPONENTS,
} from '../../constants';
import { deleteUserAccessToDashboard, updateAccess } from '../services';
import { getDataSourceTypeField } from '../utils';

type SharedWithProps = {
  setShareDashboardComponent: (val: string) => void;
  fetchAndSetDashboardUsers: () => void;
  fetchUsersReqStatus: string;
};

const SharedWith = ({
  setShareDashboardComponent,
  fetchAndSetDashboardUsers,
  fetchUsersReqStatus,
}: SharedWithProps) => {
  const { t } = useTranslation();

  const {
    dashboardViewsList,
    dashboardUsers,
    selectedConnection,
    selectedDashboard,
    selectedMyDashboardType,
  } = useSelector(selectDashboard);
  const { selectedDashboardCustomViews } = useSelector(customDashboard);

  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [expandedUser, setExpandedUser] = useState('');
  const [updatedDashboardViews, setUpdatedDashboardViews] = useState<any[]>([]);
  const [updateAccessRequestStatus, setUpdateAccessRequestStatus] =
    useState('');
  const [userToDelete, setUserToDelete] = useState<any>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUserRequestStatus, setDeleteUserRequestStatus] = useState('');

  /**
   * @function handleQuickAction
   * @description Function to handle the quick action buttons for a user with whom dashboard is shared
   * @param user : The user for which action is invoked
   * @param action : The clicked action id
   */
  const handleQuickAction = (user: any, action: string) => {
    if (action === SHARED_WITH_ACTIONS.REMOVE) {
      setUserToDelete(user);
      setShowDeleteModal(true);
    }
  };

  /**
   * @function onClickUpdate
   * @description Function to update the dashboard views for all users
   */
  const onClickUpdate = () => {
    setUpdateAccessRequestStatus(REQUEST_STATUS.PROCESSING);
    const requests = updatedDashboardViews.map((user) => {
      const body = {
        name: user.name,
        email: user.userEmail,
        status: QUERY_FIELDS.USER_STATUS_ACTIVE,
        userDatasourceDTOList: [
          {
            datasourceId: getDashboardDatasourceId(
              selectedDashboard,
              selectedConnection
            ),
            datasourceDashboards: [
              {
                dashboardId: selectedDashboard?.id,
                views: user.views.filter(
                  (userView: string) =>
                    dashboardViewsList
                      .map((dashboardView) => dashboardView.id)
                      .includes(userView) ||
                    selectedDashboardCustomViews
                      .filter(
                        (customDashboards) =>
                          customDashboards.viewStatus !==
                          CUSTOM_VIEW_PUBLISH_STATUS.DRAFT
                      )
                      .map((dashboardView) => dashboardView.id)
                      .includes(userView)
                ),
              },
            ],
            datasourceType: getDataSourceTypeField(
              selectedDashboard?.dashBoardType ?? ''
            ),
          },
        ],
        dashboardLink: `${NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS.substring(
          1
        )}/${selectedDashboard?.id}`,
      };
      return updateAccess(body);
    });
    axios
      .all(requests)
      .then(() => {
        setUpdateAccessRequestStatus(REQUEST_STATUS.SUCCESS);
        setShareDashboardComponent(SHARE_DASHBOARD_COMPONENTS.SUCCESS_PAGE);
        fetchAndSetDashboardUsers();
      })
      .catch((e) => onApiCallError(e, true, setUpdateAccessRequestStatus));
  };

  /**
   * @function onClickRemove
   * @description Function to Remove the dashboard views for all users
   */
  const onClickRemove = async () => {
    setDeleteUserRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      'datasource-id':
        selectedMyDashboardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION
          ? selectedConnection?.connectorId
          : getDashboardDatasourceName(selectedDashboard),
      'dashboard-id': selectedDashboard?.id,
    };

    deleteUserAccessToDashboard(userToDelete.userEmail, params)
      .then((res: any) => {
        if (res.status === 200) {
          setDeleteUserRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success(
            t('shareDashboard.successfullyRemovedUser', {
              name: userToDelete.name,
            })
          );
          setShowDeleteModal(false);
          fetchAndSetDashboardUsers();
        }
      })
      .catch((e) => onApiCallError(e, true, setDeleteUserRequestStatus));
  };

  const UsersList =
    dashboardUsers.length > 0 ? (
      <div>
        {dashboardUsers.map((user) => (
          <div className="flex flex-column flex-gap-8" key={user.userEmail}>
            <div className="flex flex-space-between flex-align-items-center">
              <AccessibleDiv
                className="flex flex-gap-8 flex-align-items-center cursor-pointer"
                onClick={() => {
                  if (expandedUser === user.userEmail) {
                    setExpandedUser('');
                    return;
                  }
                  setExpandedUser(user.userEmail);
                }}
              >
                {expandedUser === user.userEmail ? (
                  <Icon
                    iconName={ICONS.ARROW_DOWN_S_LINE}
                    className="collapse-icon"
                    size={ICONS_SIZE.XL}
                    dataTestId="arrow-expanded"
                  />
                ) : (
                  <Icon
                    iconName={ICONS.ARROW_RIGHT_S_LINE}
                    className="collapse-icon"
                    size={ICONS_SIZE.XL}
                    dataTestId="arrow-minimised"
                  />
                )}
                <img
                  src={ProfileIcon}
                  alt="profileimage"
                  className="profile-icon"
                />
                <span className="font-caption-bold">{user.name}</span>
              </AccessibleDiv>
              <QuickActionMenu
                quickActions={SHARED_WITH_QUICKACTIONS}
                quickActionHandler={(action) => handleQuickAction(user, action)}
              />
            </div>
            {expandedUser === user.userEmail && (
              <SelectDashboardViews
                prebuiltDashboardViews={dashboardViewsList}
                customDashboardViews={selectedDashboardCustomViews}
                selectedDashboardViews={
                  updatedDashboardViews.find(
                    (u) => u.userEmail === expandedUser
                  )?.views ||
                  dashboardUsers.find((u) => u.userEmail === expandedUser)
                    ?.views ||
                  []
                }
                setSelectedDashboardViews={(views) => {
                  if (
                    updatedDashboardViews.find(
                      (u) => u.userEmail === expandedUser
                    )
                  ) {
                    setUpdatedDashboardViews(
                      updatedDashboardViews.map((updatedUser) => {
                        if (updatedUser.userEmail === expandedUser)
                          return { ...updatedUser, views: views };
                        return updatedUser;
                      })
                    );
                    return;
                  }
                  setUpdatedDashboardViews([
                    ...updatedDashboardViews,
                    {
                      ...user,
                      views: views,
                    },
                  ]);
                  setHasDataChanged(true);
                }}
              />
            )}
          </div>
        ))}
      </div>
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('shareDashboard.noSharedWithUsers')}
      />
    );

  return (
    <div className="flex flex-column flex-gap-16 rounded-styled-scroll">
      <div className="shared-with flex flex-column flex-gap-16">
        <DashboardComponent
          component={UsersList}
          requestStatus={fetchUsersReqStatus}
          errorMessage={t('dashboardLabels.errorFetchingDashboardUsers')}
        />
      </div>
      {hasDataChanged && (
        <div className="flex flex-end">
          <Button
            className="update-button-cta"
            title={t('shareDashboard.update')}
            loading={updateAccessRequestStatus === REQUEST_STATUS.PROCESSING}
            onClick={onClickUpdate}
          />
        </div>
      )}
      {showDeleteModal && (
        <ConfirmModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          title={t('shareDashboard.removeModalTitle', {
            name: userToDelete.name,
          })}
          onClickYes={onClickRemove}
          onClickNo={() => setShowDeleteModal(false)}
          loadingYes={deleteUserRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default SharedWith;
