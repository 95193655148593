import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { msalConfig } from 'authConfig';

import App from './App';

import 'antd/dist/reset.css';
import 'remixicon/fonts/remixicon.css';
import './styles/global.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement
);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Provider>
);
