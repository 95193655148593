import i18n from 'i18n';

import { OfferingClass, Tenancy } from 'pages/PurchasePage/constants';

import {
  PurchaseAwsEc2RisType,
  PurchaseAwsOpenSearchRisType,
  PurchaseAwsElastiCacheRisType,
  PurchaseAwsMemoryDbRisType,
  PurchaseAwsRdsRisType,
  PurchaseAwsRedshiftRisType,
} from './types';

export enum PurchaseReservedInstancesTabs {
  EC2 = 'EC2',
  RDS = 'RDS',
  REDSHIFT = 'REDSHIFT',
  OPENSEARCH = 'OPENSEARCH',
  ELASTICACHE = 'ELASTICACHE',
  MEMORYDB = 'MEMORYDB',
}

export enum RIPaymentOptions {
  ALL_UPFRONT = 'ALL_UPFRONT',
  PARTIAL_UPFRONT = 'PARTIAL_UPFRONT',
  NO_UPFRONT = 'NO_UPFRONT',
}

export const RI_PAYMENT_OPTIONS_LIST = [
  {
    value: RIPaymentOptions.ALL_UPFRONT,
    label: i18n.t('purchasePage.cart.allUpfront'),
  },
  {
    value: RIPaymentOptions.PARTIAL_UPFRONT,
    label: i18n.t('purchasePage.cart.partialUpfront'),
  },
  {
    value: RIPaymentOptions.NO_UPFRONT,
    label: i18n.t('purchasePage.cart.noUpfront'),
  },
];

export enum ReservedInstancesStatus {
  PAYMENT_PENDING = 'payment-pending',
  PAYMENT_FAILED = 'payment-failed',
  ACTIVE = 'active',
  RETIRED = 'retired',
}

export const ReservedInstancesStatusLabels = [
  {
    key: ReservedInstancesStatus.PAYMENT_PENDING,
    label: i18n.t('purchasePage.paymentPending'),
  },
  {
    key: ReservedInstancesStatus.PAYMENT_FAILED,
    label: i18n.t('purchasePage.paymentFailed'),
  },
  {
    key: ReservedInstancesStatus.ACTIVE,
    label: i18n.t('purchasePage.active'),
  },
  {
    key: ReservedInstancesStatus.RETIRED,
    label: i18n.t('purchasePage.retired'),
  },
];

export enum ElastiCacheEngineTypes {
  REDIS = 'Redis',
  MEMCACHED = 'Memcached',
}

export const ElastiCacheEngineTypesList = [
  {
    value: ElastiCacheEngineTypes.REDIS,
    label: i18n.t('purchasePage.reservedInstancesLabels.redis'),
  },
  {
    value: ElastiCacheEngineTypes.MEMCACHED,
    label: i18n.t('purchasePage.reservedInstancesLabels.memCached'),
  },
];

export const AWS_RDS_RI_DEFAULT_VALUES: PurchaseAwsRdsRisType = {
  productDescription: '',
  region: '',
  dbInstanceClass: '',
  isMultiAzDbInstance: true,
  paymentOption: '',
  term: 1,
  quantity: 1,
  hourlyUsageCharge: 0,
  oneTimePayment: 0,
  offeringId: '',
};

export const AWS_EC2_RI_DEFAULT_VALUES: PurchaseAwsEc2RisType = {
  platform: '',
  region: '',
  availabilityZone: '',
  tenancy: Tenancy.DEFAULT,
  offeringClass: OfferingClass.STANDARD,
  instanceType: '',
  paymentOption: '',
  term: 1,
  quantity: 1,
  averageMonthlyRecurringCost: 0,
  oneTimePayment: 0,
  offeringId: '',
};

export const AWS_REDSHIFT_RI_DEFAULT_VALUES: PurchaseAwsRedshiftRisType = {
  nodeType: '',
  region: '',
  paymentOption: '',
  term: 1,
  quantity: 1,
  hourlyUsageCharge: 0,
  oneTimePayment: 0,
  offeringId: '',
};

export const AWS_OPEN_SEARCH_RI_DEFAULT_VALUES: PurchaseAwsOpenSearchRisType = {
  reservationName: '',
  region: '',
  instanceType: '',
  paymentOption: '',
  term: 1,
  quantity: 1,
  hourlyUsageCharge: 0,
  oneTimePayment: 0,
  offeringId: '',
};

export const AWS_MEMORY_DB_RI_DEFAULT_VALUES: PurchaseAwsMemoryDbRisType = {
  nodeType: '',
  region: '',
  term: 1,
  paymentOption: '',
  quantity: 1,
  hourlyUsageCharge: 0,
  oneTimePayment: 0,
  offeringId: '',
};

export const AWS_ELASTICACHE_RI_DEFAULT_VALUES: PurchaseAwsElastiCacheRisType =
  {
    engine: '',
    region: '',
    nodeType: '',
    term: 1,
    paymentOption: '',
    quantity: 1,
    hourlyUsageCharge: 0,
    oneTimePayment: 0,
    offeringId: '',
  };

export const AWS_SERVICES_REQUEST_FIELDS = {
  EC2: 'Amazon Elastic Compute Cloud - Compute',
  RDS: 'Amazon Relational Database Service',
  ELASTI_CACHE: 'Amazon ElastiCache',
  REDSHIFT: 'Amazon Redshift',
  OPENSEARCH: 'Amazon OpenSearch Service',
  MEMORYDB: 'Amazon MemoryDB Service',
};
