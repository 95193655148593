import {
  QUERY_FIELDS,
  ORDER_BY,
  COMPARATORS,
  DASHBOARD_TYPES,
} from 'constants/requestBody';

// Service aggregators -> List of project / resource groups / Compartments Base Query
export const GCP_SERVICE_AGGREGATORS_BASE_QUERY = {
  columns: [
    {
      label: 'name',
      field: QUERY_FIELDS.PROJECT_NAME,
    },
  ],
  groupBy: ['name'],
  orderBy: [
    {
      label: 'name',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.PROJECT_NAME,
          comparator: COMPARATORS.NOT_EQUAL_TO,
          value: '',
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
};
