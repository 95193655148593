import i18n from 'i18n';

export enum CloudConnectionComponents {
  CLOUD_CONNECTION_FORM = 'CLOUD_CONNECTION_FORM',
  SUCCESS_PAGE = 'SUCCESS_PAGE',
  ERROR_PAGE = 'ERROR_PAGE',
}

export enum CloudConnectionQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const CHECK_CONNECTOR_NAME_SUCCESS_MESSAGE =
  'Connector name can be used';

export enum CONNECTION_STATUS {
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
}

export const ConnectionStatusLabels = [
  {
    id: CONNECTION_STATUS.ACTIVE,
    label: i18n.t('connectionCSPForm.activeLabel'),
  },
  {
    id: CONNECTION_STATUS.ERROR,
    label: i18n.t('connectionCSPForm.errorLabel'),
  },
];

export enum ACCESS_DATA_VALUES {
  HAS_BUDGET_ACCESS = 'hasBudgetAccess',
  HAS_BILLING_ACCESS = 'hasWarehouseAccess',
}

export const ACCESS_DATA_LABELS = [
  {
    value: ACCESS_DATA_VALUES.HAS_BUDGET_ACCESS,
    label: i18n.t('connectionCSPForm.budgetAccessLabel'),
  },
  {
    value: ACCESS_DATA_VALUES.HAS_BILLING_ACCESS,
    label: i18n.t('connectionCSPForm.billingAccessLabel'),
  },
];

export enum VALIDATION_STATUS {
  VALID = 'VALID',
  INVALID = 'INVALID',
  SKIP = 'SKIP',
}

export const SECRET_KEY_PLACEHOLDER = 'SECRET_KEY_PLACEHOLDER';

export const DEPENDENCY_KEY_TO_MENU_ID_MAP = [
  {
    key: 'group',
    id: 'GROUPS',
    title: i18n.t('navigationMenu.groups'),
  },
  {
    key: 'gprofiler',
    id: 'GPROFILER_CONNECTIONS',
    title: i18n.t('navigationMenu.gprofiler'),
  },
  {
    key: 'granulate',
    id: 'GRANULATE_CONNECTIONS',
    title: i18n.t('navigationMenu.granulate'),
  },
];
