import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, message, Radio, Row, Select } from 'antd';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';

import { dataCenter } from 'redux/dataCenterSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { DatabaseEntryListType } from 'pages/DataCenterPage/types';
import {
  saveDatabaseResource,
  updateDatabaseResource,
} from 'pages/DataCenterPage/services';
import {
  DbEngineType,
  MODE_OF_PURCHASES,
  PurchaseModes,
} from 'pages/DataCenterPage/constants';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { MONTH_YEAR_FORMAT, TIMESTAMP_FORMAT_WITHOUT_ZONE } from 'utils/date';
import {
  getValidationStyle,
  isNumber,
  validateEmptyField,
} from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import DrawerComponent from 'components/DrawerComponent';
import DatePicker from 'components/DatePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import { BUTTON_TYPE } from 'constants/appearance';
import { onApiCallError } from 'utils/handleErrors';

type DatabaseRowModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  onSaveOrUpdateEntry: () => void;
  databaseEntry?: DatabaseEntryListType;
};

const DatabaseRowModal = ({
  show,
  setShow,
  onSaveOrUpdateEntry,
  databaseEntry,
}: DatabaseRowModalProps) => {
  const { t } = useTranslation();
  const { selectedDataCenter } = useSelector(dataCenter);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [noOfDbInstances, setNoOfDbInstances] = useState('');
  const [noOfDbInstancesValidation, setNoOfDbInstancesValidation] =
    useState('');
  const [dbEngineType, setDbEngineType] = useState('');
  const [dbEngineTypeValidation, setDbEngineTypeValidation] = useState('');
  const [noOfDbLicenses, setNoOfDbLicenses] = useState('');
  const [noOfDbLicensesValidation, setNoOfDbLicensesValidation] = useState('');
  const [modeOfPurchase, setModeOfPurchase] = useState(
    PurchaseModes.RENTAL_OR_LEASE
  );
  const [cost, setCost] = useState('');
  const [costValidation, setCostValidation] = useState('');
  const [purchaseDate, setPurchaseDate] = useState<Moment | null>(null);
  const [purchaseDateValidation, setPurchaseDateValidation] = useState('');
  const [duration, setDuration] = useState('');
  const [durationValidation, setDurationValidation] = useState('');

  const [saveDatabaseEntryRequestStatus, setSaveDatabaseEntryRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (databaseEntry !== undefined) {
      setNoOfDbInstances(databaseEntry.noOfOnPremDBInstances.toString());
      setDbEngineType(databaseEntry.dbEngineType);
      setNoOfDbLicenses(databaseEntry.noOfDbLicenses.toString());
      setModeOfPurchase(
        MODE_OF_PURCHASES.find(
          (item) => item.key === databaseEntry.modeOfPurchase
        )?.key ?? PurchaseModes.RENTAL_OR_LEASE
      );

      setPurchaseDate(moment(databaseEntry.dateOfPurchase));
      setDuration(databaseEntry.durationInMonths?.toString() ?? '');
      setCost(
        (databaseEntry.modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
          ? databaseEntry.monthlyCost
          : databaseEntry.totalCost
        )?.toString() ?? ''
      );
    }
  }, []);

  const DbEngineTypeDropdown = (
    <>
      {Object.values(DbEngineType).map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </>
  );

  /**
   * @function onClickAdd
   * @description Function to add database row for the entered values
   */
  const onClickAdd = () => {
    if (!validateAllFields()) {
      return;
    }

    setSaveDatabaseEntryRequestStatus(REQUEST_STATUS.PROCESSING);
    let requestBody: any = {
      dataCenterCode: selectedDataCenter?.dataCenterCode,
      noOfOnPremDBInstances: noOfDbInstances,
      dbEngineType: dbEngineType,
      noOfDbLicenses: noOfDbLicenses,
      modeOfPurchase: modeOfPurchase,
      dateOfPurchase: moment(purchaseDate)
        .startOf('month')
        .startOf('day')
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      monthlyCost:
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE ? cost : undefined,
      totalCost:
        modeOfPurchase === PurchaseModes.PERMANENT_PURCHASE ? cost : undefined,
      durationInMonths:
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE ? duration : undefined,
    };

    let successMessage = t('manualEntry.databaseLabels.saveDatabaseSuccess');
    let failureMessage = t('manualEntry.databaseLabels.saveDatabaseFailed');

    if (databaseEntry !== undefined) {
      requestBody.id = databaseEntry.id;

      successMessage = t('manualEntry.databaseLabels.updateDatabaseSuccess');
      failureMessage = t('manualEntry.databaseLabels.updateDatabaseFailed');
    }

    (databaseEntry === undefined
      ? saveDatabaseResource(requestBody)
      : updateDatabaseResource(requestBody)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setSaveDatabaseEntryRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success(successMessage);
          onSaveOrUpdateEntry();
          setShow(false);
          return;
        }

        setSaveDatabaseEntryRequestStatus(REQUEST_STATUS.ERROR);
        setErrorMessage(res?.message ?? failureMessage);
      })
      .catch((e) => {
        onApiCallError(e, false, setSaveDatabaseEntryRequestStatus);
        setErrorMessage(e?.response?.data?.message ?? failureMessage);
      });
  };

  /**
   * @function validateNoOfDbInstances
   * @description Function to validate the number of DB Instances.
   * @param value string input value
   */
  const validateNoOfDbInstances = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.databaseLabels.numberOfOnPremDbInstances'),
        setNoOfDbInstancesValidation
      )
    ) {
      return isNumber(
        value,
        false,
        t('manualEntry.databaseLabels.numberOfOnPremDbInstances'),
        setNoOfDbInstancesValidation
      );
    }

    return false;
  };

  /**
   * @function validateDbEngineType
   * @description Function to validate DB Engine Type.
   * @param value string input value
   */
  const validateDbEngineType = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.databaseLabels.dbEngineType'),
      setDbEngineTypeValidation
    );
  };

  /**
   * @function validateNoOfDbLicenses
   * @description Function to validate Number Of DB Licenses.
   * @param value string input value
   */
  const validateNoOfDbLicenses = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.databaseLabels.numberOfDbLicenses'),
        setNoOfDbLicensesValidation
      )
    ) {
      return isNumber(
        value,
        false,
        t('manualEntry.databaseLabels.numberOfDbLicenses'),
        setNoOfDbLicensesValidation
      );
    }

    return false;
  };

  /**
   * @function validateCost
   * @description Function to validate the Cost.
   * @param value string input value
   */
  const validateCost = (value: string) => {
    if (
      !validateEmptyField(
        value,
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
          ? t('manualEntry.databaseLabels.costMonthly')
          : t('manualEntry.databaseLabels.cost'),
        setCostValidation
      )
    ) {
      return isNumber(
        value,
        true,
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
          ? t('manualEntry.databaseLabels.costMonthly')
          : t('manualEntry.databaseLabels.cost'),
        setCostValidation
      );
    }

    return false;
  };

  /**
   * @function validatePurchaseDate
   * @description Function to validate the Purchase Date.
   * @param value string input value
   */
  const validatePurchaseDate = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.databaseLabels.dateMonthOfPurchase'),
      setPurchaseDateValidation
    );
  };

  /**
   * @function validateDuration
   * @description Function to validate the Duration.
   * @param value string input value
   */
  const validateDuration = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.databaseLabels.duration'),
        setDurationValidation
      )
    ) {
      return isNumber(
        value,
        false,
        t('manualEntry.databaseLabels.duration'),
        setDurationValidation
      );
    }

    return false;
  };

  /**
   * @function validateAllFields
   * @description Function to validate the database fields
   * @returns boolean true validated else false
   */
  const validateAllFields = () => {
    validateNoOfDbInstances(noOfDbInstances);
    validateDbEngineType(dbEngineType);
    validateNoOfDbLicenses(noOfDbLicenses);
    validateCost(cost);
    validatePurchaseDate(purchaseDate?.toString() ?? '');
    validateDuration(duration);

    return (
      validateNoOfDbInstances(noOfDbInstances) &&
      validateDbEngineType(dbEngineType) &&
      validateNoOfDbLicenses(noOfDbLicenses) &&
      validateCost(cost) &&
      validatePurchaseDate(purchaseDate?.toString() ?? '') &&
      (modeOfPurchase === PurchaseModes.PERMANENT_PURCHASE ||
        validateDuration(duration))
    );
  };

  return (
    <DrawerComponent
      className="row-modal"
      width={600}
      open={show}
      onClose={() => setShow(false)}
      title={
        (databaseEntry
          ? t('manualEntry.editRow')
          : t('manualEntry.addNewRow')) +
        ' - ' +
        t('manualEntry.nav.database')
      }
      footer={
        <div className="flex flex-column flex-gap-8">
          <span
            style={{
              display: getValidationStyle(errorMessage),
            }}
            className="font-validation-error"
          >
            {errorMessage}
          </span>
          <div className="flex flex-align-items-center flex-end">
            <Button
              title={t('manualEntry.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShow(false)}
            />
            <Button
              title={
                databaseEntry === undefined
                  ? t('manualEntry.add')
                  : t('manualEntry.update')
              }
              onClick={onClickAdd}
              loading={
                saveDatabaseEntryRequestStatus === REQUEST_STATUS.PROCESSING
              }
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-column flex-gap-16">
        <Row>
          <Col span={24}>
            <FormLabel
              title={t('manualEntry.databaseLabels.numberOfOnPremDbInstances')}
              required
            />
          </Col>
          <Col span={24}>
            <Input
              type="input"
              value={noOfDbInstances}
              placeholder={t(
                'manualEntry.databaseLabels.numberOfOnPremDbInstancesPlaceholder'
              )}
              onChange={(e: any) => {
                setNoOfDbInstances(e.target.value);
                validateNoOfDbInstances(e.target.value);
              }}
              onBlur={() => validateNoOfDbInstances(noOfDbInstances)}
            />
          </Col>
          <Col span={24}>
            <span
              style={{
                display: `${getValidationStyle(noOfDbInstancesValidation)}`,
              }}
              className="font-validation-error"
            >
              {noOfDbInstancesValidation}
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.databaseLabels.dbEngineType')}
              required
            />
            <SelectDropdown
              className="full-width"
              menu={DbEngineTypeDropdown}
              value={dbEngineType}
              placeholder={t(
                'manualEntry.databaseLabels.dbEngineTypePlaceholder'
              )}
              onChange={(value: string) => {
                setDbEngineType(value);
                validateDbEngineType(value);
              }}
              onBlur={() => validateDbEngineType(dbEngineType)}
            />
            <span
              style={{
                display: `${getValidationStyle(dbEngineTypeValidation)}`,
              }}
              className="font-validation-error"
            >
              {dbEngineTypeValidation}
            </span>
          </Col>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.databaseLabels.numberOfDbLicenses')}
              required
            />
            <Input
              type="input"
              value={noOfDbLicenses}
              placeholder={t(
                'manualEntry.databaseLabels.numberOfDbLicensesPlaceholder'
              )}
              onChange={(e: any) => {
                setNoOfDbLicenses(e.target.value);
                validateNoOfDbLicenses(e.target.value);
              }}
              onBlur={() => validateNoOfDbLicenses(noOfDbLicenses)}
            />
            <span
              style={{
                display: `${getValidationStyle(noOfDbLicensesValidation)}`,
              }}
              className="font-validation-error"
            >
              {noOfDbLicensesValidation}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormLabel
              title={t('manualEntry.databaseLabels.modeOfPurchase')}
              required
            />
          </Col>
          <Col span={24}>
            <Radio.Group
              onChange={(event) => setModeOfPurchase(event.target.value)}
              value={modeOfPurchase}
            >
              {MODE_OF_PURCHASES.map((mode) => (
                <Radio value={mode.key} key={mode.key}>
                  {mode.label}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <Divider className="horizontal-divider" />
        <Row>
          <Col span={24}>
            <FormLabel
              title={
                modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
                  ? t('manualEntry.databaseLabels.costMonthly')
                  : t('manualEntry.databaseLabels.cost')
              }
              required
            />
          </Col>
          <Col span={24}>
            <Input
              type="input"
              prefix={currencySymbol}
              value={cost}
              placeholder={t('manualEntry.databaseLabels.costPlaceholder')}
              onChange={(e: any) => {
                setCost(e.target.value);
                validateCost(e.target.value);
              }}
              onBlur={() => validateCost(cost)}
            />
          </Col>
          <Col span={24}>
            <span
              style={{
                display: `${getValidationStyle(costValidation)}`,
              }}
              className="font-validation-error"
            >
              {costValidation}
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.databaseLabels.dateMonthOfPurchase')}
              required
            />
            <DatePicker
              className="full-width"
              picker="month"
              disabledDate={(current: any) =>
                current && current > moment().endOf('day')
              }
              suffixIcon={
                <Icon
                  iconName={ICONS.ARROW_DOWN_S_LINE}
                  size={ICONS_SIZE.TWO_X}
                />
              }
              pickerType={DATE_PICKER_TYPE.DATE_PICKER}
              format={MONTH_YEAR_FORMAT}
              value={purchaseDate}
              placeholder={t('manualEntry.databaseLabels.monthPlaceholder')}
              onChange={(value: any, date: any) => {
                setPurchaseDate(value);
                validatePurchaseDate(date);
              }}
              onBlur={() =>
                validatePurchaseDate(purchaseDate?.toString() ?? '')
              }
            />
            <span
              style={{
                display: `${getValidationStyle(purchaseDateValidation)}`,
              }}
              className="font-validation-error"
            >
              {purchaseDateValidation}
            </span>
          </Col>
          {modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE && (
            <Col span={12}>
              <FormLabel
                title={t('manualEntry.databaseLabels.duration')}
                required
              />
              <Input
                className="full-width"
                type="input"
                value={duration}
                suffix={`/ ${t('manualEntry.databaseLabels.months')}`}
                placeholder={t(
                  'manualEntry.databaseLabels.durationPlaceholder'
                )}
                onChange={(e: any) => {
                  setDuration(e.target.value);
                  validateDuration(e.target.value);
                }}
                onBlur={() => validateDuration(duration)}
              />
              <span
                style={{
                  display: `${getValidationStyle(durationValidation)}`,
                }}
                className="font-validation-error"
              >
                {durationValidation}
              </span>
            </Col>
          )}
        </Row>
      </div>
    </DrawerComponent>
  );
};

export default DatabaseRowModal;
