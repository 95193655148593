import i18n from 'i18n';

import { PROVIDER } from 'constants/cloudProviders';
import {
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  QUERY_VALUES,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { DashboardListType } from 'types/navigationMenu';
import { getQueryFieldByDataSource } from 'utils/dashboardUtils';
import { ConnectionListType } from 'types/dashboard';

/**
 * @function getAccountsFilterLabelByProvider
 * @description Function to return the accounts filter label by provider
 * @param provider provider for which the label is fetched
 * @returns Returns string containing the accounts filter label
 */
export const getAccountsFilterLabelByProvider = (
  provider: string | undefined
) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('subscriptionsText');

    case PROVIDER.AWS:
      return i18n.t('accounts');

    case PROVIDER.GCP:
    default:
      return i18n.t('projects');
  }
};

/**
 * @function getAwsAccountsQuery
 * @description Function to return the query for aws accounts
 * @param selectedDashboard Object containing the dashboard list
 * @param selectedConnection Object containing the connection details
 * @returns Object containing the query
 */
export const getAwsAccountsQuery = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType
) => {
  return {
    columns: [
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['name'],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
  };
};

/**
 * @function getAzureAccountsQuery
 * @description Function to return the query for azure accounts
 * @param selectedDashboard Object containing the dashboard list
 * @param selectedConnection Object containing the connection details
 * @returns Object containing the query
 */
const getAzureAccountsQuery = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType
) => {
  return {
    columns: [
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.SUBSCRIPTION_NAME,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['name'],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpProjectsQuery
 * @description Function to return the query for GCP projects list
 * @param selectedDashboard Object containing the dashboard list
 * @param selectedConnection Object containing the connection details
 * @returns Object containing the query
 */
const getGcpProjectsQuery = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType
) => {
  return {
    columns: [
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.PROJECT_NAME,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['name'],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAccountsQuery
 * @description Function to return the query by provider
 * @param selectedDashboard Object containing the dashboard list
 * @param selectedConnection Object containing the connection details
 * @returns Object containing the query
 */
export const getAccountsQuery = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType
) => {
  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureAccountsQuery(selectedDashboard, selectedConnection);

    case PROVIDER.AWS:
      return getAwsAccountsQuery(selectedDashboard, selectedConnection);

    case PROVIDER.GCP:
    default:
      return getGcpProjectsQuery(selectedDashboard, selectedConnection);
  }
};

/**
 * @function getAccountData
 * @description Function to fetch the list of accounts
 * @param selectedDashboard dashboard details
 * @param setAccounts Callback function to set the accounts fetched
 * @param setSelectedAccounts Callback function to set the selected accounts
 * @param setRequestStatus Callback function to set the request status
 */
export const getAccountData = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  setAccounts: (val: string[]) => void,
  setSelectedAccounts: (val: string[]) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const requestBody = getAccountsQuery(selectedDashboard, selectedConnection);

  getChartData(requestBody, selectedDashboard.connectorId)
    .then((res: any) => {
      const data = res.data;
      const accountsList = data.map((item: any) => item.name);

      setAccounts(accountsList);
      setSelectedAccounts(accountsList);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};
