import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getAwsSpEc2InstanceFamilies
 * @description Function to fetch the Aws SP EC2 Instance Families
 * @param params request parameters
 * @return axios response from GET request
 */
export const getAwsSpEc2InstanceFamilies = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_SP_EC2_INSTANCE_FAMILIES, null, params);
};

/**
 * @function getAwsSpOfferingId
 * @description Function to fetch the Aws SP offering Ids
 * @param requestBody request body
 * @return axios response from POST request
 */
export const getAwsSpOfferingId = async (requestBody: any) => {
  return ServiceCalls.post(APIS.GET_AWS_SP_OFFERING, requestBody);
};

/**
 * @function buyAwsSavingPlans
 * @description Function to fetch the Aws SP offering Ids
 * @param requestBody request body
 * @return axios response from POST request
 */
export const buyAwsSavingPlans = async (requestBody: any) => {
  return ServiceCalls.post(APIS.AWS_SAVING_PLANS_BUY, requestBody);
};
