import { useSelector } from 'react-redux';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import GraphHeader from 'components/GraphHeader';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectDashboard } from 'redux/dashboardSlice';
import { ConnectionListType, DashboardFiltersListType } from 'types/dashboard';
import { evaluateRequestArray } from 'utils/handleErrors';
import { getChartExcelExportData } from 'utils/exportToExcel';

import FilterComponent from './components/FilterComponent';

type ChartWithTableWrapperProps = {
  graphTitle: string;
  graphHeadingComponent?: React.ReactNode;
  graphName: string;
  children?: React.ReactNode;
  columns?: any[];
  data?: any[];
  tableData?: any[];
  filters?: DashboardFiltersListType[];
  requestStatus: string[];
  setIsTableView?: (val: boolean) => void;
  isTableView?: boolean;
  hasTableView?: boolean;
  pdfView: boolean;
  isFullWidth?: boolean;
  connection?: ConnectionListType | null;
};

const ChartWithTableWrapper = ({
  graphTitle,
  graphHeadingComponent,
  graphName,
  children,
  columns = [],
  data = [],
  tableData,
  filters = [],
  requestStatus,
  setIsTableView,
  isTableView,
  hasTableView = true,
  pdfView,
  isFullWidth = true,
  connection,
}: ChartWithTableWrapperProps) => {
  const { selectedDashboard } = useSelector(selectDashboard);

  const filterComponents = (
    <div className="filters flex flex-align-items-center full-width flex-gap-16">
      {filters
        .map((filter, index) => ({ ...filter, key: index }))
        .map((filter) => (
          <FilterComponent
            key={filter.key}
            filterType={filter.filterType}
            filterProperties={filter.filterProperties}
          />
        ))}
    </div>
  );

  const TableComponent = (
    <div
      className={`tabular-view full-height ${isFullWidth ? 'width-30' : ''}`}
    >
      <Table
        pagination={false}
        loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
        dataSource={(tableData ?? data).map((value, index) => {
          return { ...value, key: index };
        })}
        columns={columns}
        scroll={{ y: '100%', x: '100%' }}
        designVersion2
        fillContainer
      />
    </div>
  );

  const getComponent = () => {
    if (isFullWidth)
      return (
        <div
          className={`graph ${isTableView && 'flex flex-gap-16'} ${
            filters.length === 0 ? 'no-filters' : ''
          }`}
        >
          <div
            className={`graph-area ${isTableView && 'with-table expand-hide'}`}
          >
            {children}
          </div>
          {isTableView && TableComponent}
        </div>
      );

    return (
      <div className={`graph ${filters.length === 0 ? 'no-filters' : ''}`}>
        {isTableView ? (
          TableComponent
        ) : (
          <div className="graph-area">{children}</div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`graph-card ${graphName} flex flex-column flex-gap-8`}
      id="graph-container"
      data-testid="chart-with-table-wrapper"
    >
      <GraphHeader
        heading={graphTitle}
        headingComponent={graphHeadingComponent}
        graphName={graphName}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView && hasTableView}
        showExpandIcon={!pdfView}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        filters={filters.length ? filterComponents : undefined}
        designVersion2
        excelData={getChartExcelExportData(
          graphTitle,
          tableData ?? data,
          columns,
          {
            connectionName:
              selectedDashboard?.connectorName ?? connection?.displayName ?? '',
          }
        )}
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={evaluateRequestArray(requestStatus)}
      />
    </div>
  );
};

export default ChartWithTableWrapper;
