import { Table as BaseTable } from 'antd';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import Loader from 'components/Loader';

const Table = ({ loading = false, ...remainingProps }: any) => {
  const { theme } = useSelector(selectTheme);

  return (
    <BaseTable
      bordered
      size="middle"
      {...remainingProps}
      loading={{ spinning: loading, indicator: <Loader /> }}
      style={{
        color: theme.textColor,
      }}
      rootClassName={`${
        remainingProps.designVersion2 ? 'design-v2' : 'design-v1'
      } ${remainingProps.fillContainer ? 'fill-container' : ''} ${
        !remainingProps?.dataSource || remainingProps?.dataSource?.length === 0
          ? 'no-data'
          : ''
      } ${remainingProps.rootClassName}`}
    />
  );
};

export default Table;
