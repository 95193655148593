import { useTranslation } from 'react-i18next';

import DropdownCheckbox from 'components/DropdownCheckbox';
import { INPUT_SIZE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { CMDBFieldTagsMappingType } from 'pages/CmdbSetupListPage/types';
import { ConnectionListType } from 'types/dashboard';

import './index.scss';

type TagsListProps = {
  connection: ConnectionListType;
  tags: string[] | undefined;
  tagsReqStatus: string;
  cmdbFieldTagsMap: CMDBFieldTagsMappingType;
  setCmdbFieldTagsMap: (val: CMDBFieldTagsMappingType) => void;
};

const TagsList = ({
  connection,
  tags,
  tagsReqStatus,
  cmdbFieldTagsMap,
  setCmdbFieldTagsMap,
}: TagsListProps) => {
  const { t } = useTranslation();

  const getSelectedTags = () =>
    cmdbFieldTagsMap.cloudTags.find(
      (cloudTag) => cloudTag.connectorId === connection.connectorId
    )?.tagKeys ?? [];

  const onChangeSelectedTags = (tagsSelected: string[]) => {
    const cloudTags = [
      ...cmdbFieldTagsMap.cloudTags.filter(
        (cloudTag) => cloudTag.connectorId !== connection.connectorId
      ),
    ];

    if (tagsSelected.length) {
      cloudTags.push({
        connectorId: connection.connectorId,
        tagKeys: tagsSelected,
      });
    }

    setCmdbFieldTagsMap({ ...cmdbFieldTagsMap, cloudTags: cloudTags });
  };

  return (
    <DropdownCheckbox
      additionalClassNames="cmdb-tags-dropdown"
      loading={!tags && tagsReqStatus === REQUEST_STATUS.PROCESSING}
      value={t('clickToViewTags')}
      itemOptions={(tags ?? []).map((tag) => ({
        value: tag,
        title: tag,
      }))}
      selectedItems={getSelectedTags()}
      setSelectedItems={onChangeSelectedTags}
      size={INPUT_SIZE.SMALL}
      showSearch
      designVersion2
    />
  );
};

export default TagsList;
