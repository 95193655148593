import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';
import Input from 'components/Input';
import NavigationPath from 'components/NavigationPath';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { INPUT_SIZE } from 'constants/appearance';
import HorizontalNavigation from 'pages/OverviewPage/components/ConnectionsDashboard/components/HorizontalNavigation';
import { DASHBOARD } from 'pages/OverviewPage/components/ConnectionsDashboard/components/HorizontalNavigation/constants';
import { setSelectedDashboardView } from 'redux/dashboardSlice';
import { setNavSubPath } from 'redux/activeNavMenuSlice';
import {
  customDashboard,
  setCustomDashBoardMode,
  setCustomViewData,
  setSelectedChartType,
} from 'redux/customDashboardSlice';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { CUSTOM_DASHBOARD_MODE, MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';

import CustomDashboardHeader from './components/CustomDashboardHeader';
import CustomViewContainer from './components/CustomViewContainer';
import { CustomDashboardChartTypes } from './constants';

import './index.scss';

const CustomDashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { customViewData } = useSelector(customDashboard);
  const {
    customDashboardConnection,
    customDashboardGroup,
    customDashboardIntegrationConnection,
    dashboardType,
  } = useSelector(customDashboard);

  const [editTitle, setEditTitle] = useState(false);
  const [viewName, setViewName] = useState('');

  useEffect(() => {
    addZeroMarginClass();
    if (
      (dashboardType === MY_DASHBOARD_TYPES.GROUP &&
        customDashboardGroup === null) ||
      ((dashboardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION ||
        dashboardType === MY_DASHBOARD_TYPES.IMPORTS) &&
        customDashboardConnection === null) ||
      (dashboardType === MY_DASHBOARD_TYPES.SNOWFLAKE &&
        customDashboardIntegrationConnection === null)
    ) {
      navigate(NAVIGATION_MENU_PATH.DASHBOARDS);
    }

    dispatch(setNavSubPath([t('customDashboard.addNewDashboard')]));
    dispatch(setCustomDashBoardMode(CUSTOM_DASHBOARD_MODE.DEV));
    dispatch(
      setCustomViewData({
        ...customViewData,
        viewName: customViewData?.viewName
          ? customViewData.viewName
          : 'Untitled',
      })
    );
    dispatch(setSelectedDashboardView(DASHBOARD.NEW_VIEW));

    return () => {
      removeZeroMarginClass();
      dispatch(setSelectedChartType(CustomDashboardChartTypes.TABLE));
    };
  }, []);

  /**
   * @function onChangeViewTitle
   * @description change view title of custom view of dashboard
   * @param e text input event
   */
  const onChangeViewTitle = (e: any) => {
    customViewData &&
      dispatch(
        setCustomViewData({
          ...customViewData,
          viewName: e.target.value,
        })
      );
    setEditTitle(false);
  };

  return (
    <div className="dashboard-content flex flex-column flex-fit">
      <div className="upper-dashboard">
        <CustomDashboardHeader />
      </div>
      <div className="custom-dashboard page-content flex flex-column flex-fit">
        <NavigationPath />
        <HorizontalNavigation
          NewViewTitle={
            editTitle ? (
              <Input
                autoFocus
                autoWidth
                className="developer-mode-edit-title"
                size={INPUT_SIZE.SMALL}
                defaultValue={customViewData?.viewName}
                value={viewName}
                onChange={(e: any) => {
                  if (e.target.value.length <= MAX_CHARACTER_LIMIT) {
                    setViewName(e.target.value);
                  }
                }}
                onPressEnter={onChangeViewTitle}
                onBlur={onChangeViewTitle}
              />
            ) : (
              <div className="flex flex-center flex-gap-8">
                {customViewData?.viewName}
                <Icon
                  iconName={ICONS.EDIT_LINE}
                  size={ICONS_SIZE.ONE_X}
                  onClick={() => setEditTitle(true)}
                />
              </div>
            )
          }
        />
        <CustomViewContainer />
      </div>
    </div>
  );
};

export default CustomDashboardPage;
