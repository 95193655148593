export enum Instances {
  LOCALHOST = '127.0.0.1',
  DEV = 'devsite-cb360.us.deloitte.com',
  QA = 'qasite-cb360.us.deloitte.com',
  PRUDENTIAL = 'prudentialsite-cb360.us.deloitte.com',
  CLIENT1 = 'client1pro-cb360.us.deloitte.com',
  CLIENT2 = 'client2pro-cb360.us.deloitte.com',
}

export enum APIS {
  UPLOAD_CONNECTION_FILE = 'cloud-connector/upload-file',
  DELETE_CONNECTION_FILE = 'cloud-connector/delete-file/',
  NEW_CSP_CONNECTION = 'cloud-connector/connector/new',
  GET_GCP_PROJECT_ID = 'cloud-connector/gcp-connection/getProjectId/',
  GET_GCP_DATASET = 'cloud-connector/gcp-connection/getDatasets',
  GET_GCP_DATASET_TABLE = 'cloud-connector/gcp-connection/getTables',
  GET_AZURE_DATABASE_TABLES = 'cloud-connector/azure/getDatabaseTables',
  GET_FILE_META_DATA = 'cloud-connector/get-file-metadata/',
  GET_AUTH_TOKEN = 'authz/auth/token',
  LOGOUT = 'authz/auth/logout',
  VERIFY_OTP = 'authz/auth/verifyOTP',
  VERIFY_LOGIN_CREDENTIALS = 'authz/auth/verifyCredentials',
  GET_ALL_CONNECTIONS = 'cloud-connector/connector/getAll',
  GET_CONNECTION_BY_ID = 'cloud-connector/connector/getById/',
  SET_DEFAULT_CONNECTION = 'reporting/connector/setDefault',
  SEARCH_CONNECTIONS = 'cloud-connector/connector/search',
  UPDATE_CONNECTION = 'cloud-connector/connector/update',
  DELETE_CONENCTION = 'cloud-connector/connector/delete/',
  IS_CONNECTOR_NAME_EXISTS = 'cloud-connector/connector/isConnectorNameAlreadyUsed',
  GET_ALL_IMPORT_FILES = 'cloud-connector/staticFileTransaction/getAll',
  DELETE_IMPORT_FILE = 'cloud-connector/staticFileTransaction/delete',
  USER_MANAGEMENT = 'profile/user',
  GET_ALL_USERS = 'profile/user/getAll',
  GET_SEARCH_USER = 'profile/user/search',
  GET_USER_PROFILE_PIC = 'v1.0/me/photos/120x120/$value',
  GET_OTHER_USER_PROFILE_PIC_STARTING = 'v1.0/users/',
  GET_OTHER_USER_PROFILE_PIC_ENDING = '/photo/$value',
  GET_CHART_DATA = 'reporting/dashboard/billing/getChartData/',
  GET_FORECASTED_COST = 'reporting/dashboard/getForecastedCost',
  GCP_GET_ALL_BUDGET = 'budget-service/gcp/budget/getAll',
  GCP_CREATE_BUDGET = 'budget-service/gcp/budget/create',
  AWS_GET_ALL_BUDGET = 'budget-service/aws/budget/getAll',
  AWS_CREATE_BUDGET = 'budget-service/aws/budget/create',
  AZURE_GET_ALL_BUDGET = 'budget-service/azure/budget/getAll',
  GET_AZURE_BUDGET = 'budget-service/azure/budget/get',
  AZURE_CREATE_BUDGET = 'budget-service/azure/budget/create',
  AZURE_GET_ALL_BILLING_SERVICE = 'budget-service/azure/budget/getService',
  UPDATE_AZURE_BUDGET = 'budget-service/azure/budget/update',
  DELETE_AZURE_BUDGET = 'budget-service/azure/budget/delete',
  TAGS_AND_LABELS = 'cost-allocation/label',
  EXPORT_GCP_TAGS_AND_LABELS = 'cost-allocation/gcp-labels/export',
  EXPORT_AZURE_TAGS_AND_LABELS = 'cost-allocation/azure-labels/export',
  AWS_CATALOGS = 'cloud-connector/aws-connection/athena/catalogs',
  AWS_REGIONS = 'cloud-connector/aws-connection/athena/regions',
  AWS_DATABASES = 'cloud-connector/aws-connection/athena/databases',
  AWS_TABLES = 'cloud-connector/aws-connection/athena/tables',
  GET_COST_ALLOCATION_BY_CONNECTION_AND_MONTH = 'cost-allocation/allocate-cost/getAllocation',
  CREATE_COST_ALLOCATION = 'cost-allocation/allocate-cost/setAllocation',
  GET_ALL_ALLOCATIONS = 'cost-allocation/allocate-cost/getAllAllocations',
  DELETE_AWS_BUDGET = 'budget-service/aws/budget/delete',
  DELETE_GCP_BUDGET = 'budget-service/gcp/budget/delete',
  SEARCH_USER_EXIST = 'profile/user/searchUser',
  GET_AWS_BUDGET = 'budget-service/aws/budget/get',
  GET_AWS_BUDGET_SERVICES = 'budget-service/aws/budget/getService',
  UPDATE_AWS_BUDGET = 'budget-service/aws/budget/update',
  UPDATE_GCP_BUDGET = 'budget-service/gcp/budget/update',
  GET_GCP_BUDGET = 'budget-service/gcp/budget/get',
  GET_GCP_BUDGET_SERVICES = 'budget-service/gcp/budget/getServices',
  GET_GCP_BUDGET_PROJECTS = 'budget-service/gcp/budget/getBillingProjects',
  GET_GCP_BUDGET_BILLING_ACCOUNT = 'budget-service/gcp/budget/getBillingAccounts',
  DELETE_USER = 'profile/user/delete',
  VALIDATE_CUSTOM_QUERY_AND_GET_AVAILABLE_FIELDS = 'reporting/query/getAvailableFields',
  CREATE_CUSTOM_QUERY = 'reporting/query/create',
  GET_ALL_CUSTOM_DASHBOARDS = 'reporting/customDashboard/getAll',
  SET_DEFAULT_DASHBOARD = 'reporting/customDashboard/setDefaultDashboard',
  SET_DASHBOARD_STATUS = 'reporting/customDashboard/change-status/',
  DELETE_DASHBOARD = 'reporting/customDashboard/delete',
  UPDATE_DASHBOARD_TITLE = 'reporting/customDashboard/setDashboardName',
  DELETE_CUSTOM_VIEW = 'reporting/customView/delete/',
  CREATE_CUSTOM_VIEW = 'reporting/customView/create',
  UPDATE_CUSTOM_VIEW = 'reporting/customView/update',
  CREATE_CUSTOM_DASHBOARD = 'reporting/customDashboard/save',
  GET_CUSTOM_DASHBOARD_DETAILS = 'reporting/customDashboard/get',
  GET_ALL_ROLES = 'profile/customRole/getAll',
  CUSTOM_ROLE = 'profile/customRole',
  GET_ALL_ROLES_NAMES = 'profile/customRole/getAllRoleName',
  CHANGE_NEW_ROLE_STATUS = 'profile/customRole/changeStatus/',
  GET_USERS_FOR_ROLE = 'profile/customRole/getUsers',
  CREATE_GROUP = 'cloud-connector/group/create',
  UPDATE_GROUP = 'cloud-connector/group/update',
  DELETE_GROUP = 'cloud-connector/group/delete/',
  GET_ALL_GROUPS = 'cloud-connector/group/getAll',
  GET_GROUP = 'cloud-connector/group/get/',
  GET_SERVICE_GROUP = 'reporting/dashboard/getServiceGroups',
  GET_SEARCH_GROUP = 'cloud-connector/group/search',
  VALIDATE_SCHEMA = 'cloud-connector/staticFileTransaction/validateSchema',
  SAVE_STATIC_TRANSACTION = 'cloud-connector/staticFileTransaction/save',
  VALIDATE_TRANSACTION_NAME = 'cloud-connector/staticFileTransaction/checkTransactionExists',
  GET_TRANSACTION_BY_NAME = 'cloud-connector/staticFileTransaction/get',
  GET_SAMPLE_SCHEMA = 'cloud-connector/staticFileTransaction/getSampleSchema',
  GET_ALLOCATION_TREND_DATA = 'cost-allocation/allocate-cost/getAllocationTrend',
  ALLOCATION_BY_BU = 'cost-allocation/allocate-cost/getBuisnessCostByMonths',
  ALLOCATION_BY_COST_CENTER_CODE = 'cost-allocation/allocate-cost/getCostCentreByBusinessUnit',
  GET_LABELS = 'reporting/dashboard/getLabels',
  STATIC_FILE_LABELS = 'reporting/dashboard/static-file-labels',
  CREATE_TICKET_INTEGRATION = 'cost-control/snow-incidents/create-connection',
  GET_ASSIGNEE_GROUPS = 'cost-control/snow-incidents/get-assigneeGroups',
  GET_ASSIGN_TO_LIST = 'cost-control/snow-incidents/get-assignToList',
  GET_CATEGORIES = 'cost-control/snow-incidents/get-categories',
  GET_TICKET_CONNECTION = 'cost-control/snow-incidents/get-connection',
  GET_ALL_SNOW_INCIDENT_DETAILS = 'cost-control/snow-incidents/get-snowIncidentDetails/',
  CREATE_SNOW_INCIDENT = 'cost-control/snow-incidents/create-incident',
  GET_SNOW_TICKET = 'cost-control/snow-incidents/get-Incident/',
  GET_OCI_DATASCHEMATABLES = 'cloud-connector/oci/autonomousDataWarehouse/getSchemaTables',
  GET_GCP_RECOMMENDATION_AVAILABLE_FILTERS = 'cost-control/GCPRecommendations/recommendation-filters',
  APPLY_GCP_RECOMMENDATIONS = 'cost-control/GCPRecommendations/applyRecommendation',
  GCP_APPLICABLE_RECOMMENDATIONS = 'cost-control/GCPRecommendations/currentApplicableRecommendations',
  GCP_PROJECT_NUMBER_ID_MAPPING = 'cost-control/GCPRecommendations/getProjectNumberIdMapping/',
  GET_GCP_CATEGORY_MAPPINGS = 'cost-control/GCPRecommendations/getCategoryMappings',
  GET_GCP_RESOURCE_DETAILS = 'cost-control/GCPRecommendations/getResourceDetails',
  VALIDATE_GCP_LABELS = 'cost-allocation/gcp-labels/validate',
  APPLY_GCP_LABELS = 'cost-allocation/gcp-labels/apply',
  VALIDATE_AZURE_LABELS = 'cost-allocation/azure-labels/validate',
  APPLY_AZURE_LABELS = 'cost-allocation/azure-labels/apply',
  GET_OCI_REGIONS = 'cloud-connector/oci/getRegions',
  OCI_GET_ALL_BUDGET = 'budget-service/oci/budget/getAll',
  OCI_GET_ALL_COMPARTMENTS = 'budget-service/oci/budget/listCompartments',
  OCI_CREATE_BUDGET = 'budget-service/oci/budget/create',
  GET_OCI_BUDGET = 'budget-service/oci/budget/get',
  DELETE_OCI_BUDGET = 'budget-service/oci/budget/delete',
  UPDATE_OCI_BUDGET = 'budget-service/oci/budget/update',
  GET_ALL_TCO_PROJECT = 'cost-control/tco/project/getAll',
  CREATE_TCO_PROJECT = 'cost-control/tco/project/save',
  UPDATE_TCO_PROJECT = 'cost-control/tco/project/update',
  DELETE_TCO_PROJECT = 'cost-control/tco/project/delete',
  GET_ALL_DATA_CENTERS = 'cost-control/tco/datacenter/getAll',
  SAVE_DATA_CENTER = 'cost-control/tco/datacenter/save',
  UPDATE_DATA_CENTER = 'cost-control/tco/datacenter/update',
  DELETE_DATA_CENTER = 'cost-control/tco/datacenter/delete',
  GET_ALL_COMPUTE_RESOURCES = 'cost-control/onPrem/resources/compute/getAll',
  SAVE_COMPUTE_RESOURCE = 'cost-control/onPrem/resources/compute/save',
  UPDATE_COMPUTE_RESOURCE = 'cost-control/onPrem/resources/compute/update',
  DELETE_COMPUTE_RESOURCE = 'cost-control/onPrem/resources/compute/delete',
  GET_ALL_STORAGE_RESOURCES = 'cost-control/onPrem/resources/storage/getAll',
  SAVE_STORAGE_RESOURCE = 'cost-control/onPrem/resources/storage/save',
  UPDATE_STORAGE_RESOURCE = 'cost-control/onPrem/resources/storage/update',
  DELETE_STORAGE_RESOURCE = 'cost-control/onPrem/resources/storage/delete',
  GET_ALL_NETWORK_RESOURCES = 'cost-control/onPrem/resources/network/getAll',
  SAVE_NETWORK_RESOURCE = 'cost-control/onPrem/resources/network/save',
  UPDATE_NETWORK_RESOURCE = 'cost-control/onPrem/resources/network/update',
  DELETE_NETWORK_RESOURCE = 'cost-control/onPrem/resources/network/delete',
  GET_ALL_DATABASE_RESOURCES = 'cost-control/onPrem/resources/database/getAll',
  SAVE_DATABASE_RESOURCE = 'cost-control/onPrem/resources/database/save',
  UPDATE_DATABASE_RESOURCE = 'cost-control/onPrem/resources/database/update',
  DELETE_DATABASE_RESOURCE = 'cost-control/onPrem/resources/database/delete',
  GET_ALL_SOFTWARE_RESOURCES = 'cost-control/onPrem/resources/software/getAll',
  SAVE_SOFTWARE_RESOURCE = 'cost-control/onPrem/resources/software/save',
  UPDATE_SOFTWARE_RESOURCE = 'cost-control/onPrem/resources/software/update',
  DELETE_SOFTWARE_RESOURCE = 'cost-control/onPrem/resources/software/delete',
  SAVE_LABOR_RESOURCE = 'cost-control/onPrem/resources/labor/save',
  UPDATE_LABOR_RESOURCE = 'cost-control/onPrem/resources/labor/update',
  GET_ALL_LABOR_RESOURCES = 'cost-control/onPrem/resources/labor/getAll',
  DELETE_LABOR_RESOURCE = 'cost-control/onPrem/resources/labor/delete',
  SAVE_MULTIPLE_COMPUTE = 'cost-control/onPrem/resources/compute/saveMultiple',
  SAVE_MULTIPLE_STORAGE = 'cost-control/onPrem/resources/storage/saveMultiple',
  SAVE_MULTIPLE_NETWORK = 'cost-control/onPrem/resources/network/saveMultiple',
  SAVE_MULTIPLE_DATABASE = 'cost-control/onPrem/resources/database/saveMultiple',
  SAVE_MULTIPLE_SOFTWARE = 'cost-control/onPrem/resources/software/saveMultiple',
  SAVE_MULTIPLE_LABOR = 'cost-control/onPrem/resources/labor/saveMultiple',
  GET_COST_BY_DATACENTER_CODE = 'cost-control/onPremise/dashboard/getCostByCategories',
  GET_TCO_COST_oF_CONNECTION = 'reporting/dashboard/TCO/cloud-details',
  GET_GCP_PROJECTS_DATASETS = 'cloud-connector/gcp-connection/getAllProjectsDataSets/',
  GET_ACCESS_DETAILS = 'cloud-connector/connector/verifyAccess',
  VERIFY_GCP_BUDGET_ACCESS = 'budget-service/gcp/budget/verifyAccess',
  VERIFY_AWS_BUDGET_ACCESS = 'budget-service/aws/budget/verifyAccess',
  VERIFY_AZURE_BUDGET_ACCESS = 'budget-service/azure/budget/verifyAccess',
  GET_AWS_RECOMMENDATION_AVAILABLE_FILTERS = 'cost-control/AWSRecommendations/recommendation-filters',
  GET_AWS_CATEGORY_MAPPING = 'cost-control/AWSRecommendations/getCategoryMappings',
  AWS_APPLICABLE_RECOMMENDATIONS = 'cost-control/AWSRecommendations/currentApplicableRecommendations',
  APPLY_AWS_RECOMMENDATIONS = 'cost-control/AWSRecommendations/applyRecommendation',
  RECOMMENDATION_VIEW = 'cost-control/recommendation/view',
  GET_ALL_RULESETS = 'cost-control/rule-engine/ruleset/list',
  DELETE_RULESET = 'cost-control/rule-engine/ruleset/delete',
  UPDATE_RULESET_AUTOPILOT_STATUS = 'cost-control/rule-engine/ruleset/update-autopilot-status',
  GET_GCP_DEFAULT_RULESET_TEMPLATES = 'cost-control/rule-engine/ruleset/gcp/getDefaultRSTemplate',
  GET_AWS_DEFAULT_RULESET_TEMPLATES = 'cost-control/rule-engine/ruleset/aws/getDefaultRSTemplate',
  GET_GRANULATE_RULESET_TEMPLATES = 'cost-control/rule-engine/ruleset/granulate/getDefaultRSTemplate',
  CREATE_RULESET = 'cost-control/rule-engine/ruleset/create',
  UPDATE_RULEST = 'cost-control/rule-engine/ruleset/update',
  GET_USER_BY_EMAIL = 'profile/user/get/',
  GET_CONNECTORS_BY_USER_SUFFIX = '/getConnectorIds',
  DELETE_DASHBOARD_ACCESS_FOR_USER_SUFFIX = '/delete-user-dashboard',
  GET_DASHBOARD_USERS = 'profile/user/dashboardUsers/',
  GET_RULEST_FILTERS = 'cost-control/rule-engine/ruleset/getFilter',
  GET_FILTERED_RECOMMENDATIONS = 'reporting/dashboard/recommendations/getFiltered',
  GET_CUSTOM_GROUP_AVAILABLE_FIELDS = 'reporting/dashboard/getConnectionGroupCommonFieldsMap',
  GET_STATIC_PALLETES = 'reporting/themes/listTemplates',
  GET_DEFAULT_PALLETE = 'reporting/themes/getDefaultTemplate',
  SET_DEFAULT_PALLETE = 'reporting/themes/setDefaultTemplate',
  CREATE_CUSTOM_PALLETE = 'reporting/themes/createDynamicColorScheme',
  GET_CHART_COLOR_PREFRENCE = 'reporting/themes/user/getChartColorPreference',
  SET_CHART_COLOR_PREFRENCE = 'reporting/themes/user/setChartColorPreference',
  GET_AWS_ALERT_SUBSCRIPTION_BY_CONNECTION = 'cost-control/costAnomaly/aws/subscription/get',
  GET_ALL_AWS_MONITORS = 'cost-control/costAnomaly/aws/monitor/getAllMonitors',
  CREATE_AWS_COST_MONITOR = 'cost-control/costAnomaly/aws/monitor/create',
  UPDATE_AWS_COST_MONITOR = 'cost-control/costAnomaly/aws/monitor/update',
  DELETE_AWS_COST_MONITOR = 'cost-control/costAnomaly/aws/monitor/deleteMonitor',
  GET_AWS_MONITOR_BY_CONNECTION = 'cost-control/costAnomaly/aws/monitor/getMonitorByConnectorId',
  DETACH_AWS_MONITOR = 'cost-control/costAnomaly/aws/subscription/detachMonitor',
  GET_AWS_ANOMALIES = 'cost-control/costAnomaly/aws/getAnomalies',
  GET_AWS_COST_AND_USAGE_METRICS = 'cost-control/costAnomaly/aws/getCostAndUsageMetrics',
  GET_AWS_UTILIZATION_DETAILS = 'reporting/scorecard/aws/getUtilizationDetails',
  GET_GCP_CUD_EFFECTIVENESS_DETAILS = 'reporting/scorecard/gcp/getCudEffectivenessDetails',
  GET_WM_ASSIGNED_WEIGHTAGE = 'reporting/scorecard/wmAssignedWeightage',
  GET_GCP_CUD_UTILIZATION_AND_COVERAGE_DETAILS = 'reporting/scorecard/gcp/getCudUtilizationAndCoverageDetails',
  GET_AWS_RI_COVERAGE = 'reporting/scorecard/aws/getRICoverage',
  GET_AWS_RI_UTILIZATION = 'reporting/scorecard/aws/getRIUtilization',
  GET_AWS_SP_COVERAGE = 'reporting/scorecard/aws/getSPCoverage',
  GET_AWS_SP_UTILIZATION = 'reporting/scorecard/aws/getSPUtilization',
  GET_ALL_AWS_SP_PLANS = 'cost-control/aws/savingsPlan/allPlans',
  REMOVE_AWS_SP = 'cost-control/aws/savingsPlan/remove',
  GET_AWS_SP_EC2_INSTANCE_FAMILIES = 'cost-control/aws/savingsPlan/ec2/getInstanceFamilies',
  GET_ALL_AWS_REGIONS = 'cost-control/aws-ri/getAllRegions/',
  GET_AWS_SP_OFFERING = 'cost-control/aws/savingsPlan/getOffering',
  AWS_SAVING_PLANS_BUY = 'cost-control/aws/savingsPlan/buy',
  GET_AWS_RDS_PURCHASED_RIS = 'cost-control/aws-ri/rds/purchasedRIs/',
  GET_AWS_RI_RDS_PRODUCT_DESCRIPTION = 'cost-control/aws-ri/rds/getProductionDescriptions',
  GET_AWS_RI_RDS_INSTANCE_CLASSES = 'cost-control/aws-ri/rds/getInstanceClasses',
  GET_AWS_RI_RDS_IS_MULTI_AZ = 'cost-control/aws-ri/rds/isMultiAZs',
  GET_AWS_RI_RDS_TERM = 'cost-control/aws-ri/rds/getTerm',
  GET_AWS_RI_RDS_PAYMENT_OPTIONS = 'cost-control/aws-ri/rds/getPaymentOptions',
  GET_AWS_RI_RDS_OFFERING_DETAILS = 'cost-control/aws-ri/rds/RIOfferingDetails',
  PURCHASE_AWS_RDS_RIS = 'cost-control/aws-ri/rds/purchaseRIs/',
  GET_AWS_EC2_PURCHASED_RIS = 'cost-control/aws-ri/ec2/purchasedRIs/',
  DELETE_QUEUED_AWS_EC2_RI = 'cost-control/aws-ri/ec2/deleteQueuedRI/',
  GET_AWS_RI_PLATFORMS = 'cost-control/aws-ri/ec2/getPlatforms',
  GET_AWS_RI_AVAILABILITY_ZONES = 'cost-control/aws-ri/ec2/getAZs/',
  GET_AWS_RI_EC2_INSTANCE_TYPES = 'cost-control/aws-ri/ec2/getInstanceTypes',
  GET_AWS_RI_EC2_OFFERING_DETAILS = 'cost-control/aws-ri/ec2/RIOfferingDetails',
  PURCHASE_AWS_EC2_RIS = 'cost-control/aws-ri/ec2/purchaseRIs/',
  GET_AWS_REDSHIFT_NODE_TYPES = 'cost-control/aws-ri/redshift/getNodeTypes',
  GET_AWS_REDSHIFT_PAYMENT_OPTIONS = 'cost-control/aws-ri/redshift/getPaymentOptions',
  PURCHASE_AWS_REDSHIFT_RIS = 'cost-control/aws-ri/redshift/purchaseRIs/',
  GET_AWS_REDSHIFT_OFFERING_DETAILS = 'cost-control/aws-ri/redshift/RIOfferingDetails',
  GET_AWS_REDSHIFT_PURCHASED_RIS = 'cost-control/aws-ri/redshift/purchasedRIs/',
  GET_AWS_RI_EC2_TENANCY = 'cost-control/aws-ri/ec2/getTenancy',
  GET_AWS_RI_EC2_OFFERING_CLASSES = 'cost-control/aws-ri/ec2/getOfferingClasses',
  GET_AWS_RI_EC2_PAYMENT_OPTIONS = 'cost-control/aws-ri/ec2/getPaymentOptions',
  GET_AWS_MEMORY_DB_PURCHASED_RIS = 'cost-control/aws-ri/memorydb/purchasedRIs/',
  PURCHASE_AWS_MEMORY_DB_RIS = 'cost-control/aws-ri/MemoryDB/purchaseRIs/',
  GET_AWS_RI_MEMORY_DB_NODE_TYPES = 'cost-control/aws-ri/MemoryDB/getNodeTypes',
  GET_AWS_RI_MEMORY_DB_OFFERING_DETAILS = 'cost-control/aws-ri/MemoryDB/RIOfferingDetails',
  GET_AWS_OPEN_SEARCH_PURCHASED_RIS = 'cost-control/aws-ri/openSearch/purchasedRIs/',
  GET_AWS_OPEN_SEARCH_INSTANCE_TYPES = 'cost-control/aws-ri/openSearch/getInstances',
  GET_AWS_OPEN_SEARCH_OFFERING_DETAILS = 'cost-control/aws-ri/openSearch/RIOfferingDetails',
  PURCHASE_AWS_OPEN_SEARCH_RIS = 'cost-control/aws-ri/openSearch/purchaseRIs',
  GET_AWS_ELASTICACHE_PURCHASED_RIS = 'cost-control/aws-ri/elastiCache/purchasedRIs/',
  GET_AWS_RI_ELASTICACHE_NODE_TYPES = 'cost-control/aws-ri/elastiCache/getNodeType',
  GET_AWS_RI_ELASTICACHE_TERMS = 'cost-control/aws-ri/elastiCache/getTerms',
  GET_AWS_RI_ELASTICACHE_PAYMENT_OPTIONS = 'cost-control/aws-ri/elastiCache/getOfferingTypes',
  GET_AWS_RI_ELASTICACHE_OFFERING_DETAILS = 'cost-control/aws-ri/elastiCache/RIOfferingDetails',
  PURCHASE_AWS_ELASTICACHE_RIS = 'cost-control/aws-ri/elastiCache/purchaseRIs',
  GPROFILER_GCP_ZONES = 'gprofiler/gcp/get-zone-list',
  GPROFILER_GCP_CLUSTER = 'gprofiler/gcp/get-cluster-list',
  GPROFILER_AWS_ZONES = 'gprofiler/aws/get-region-list',
  GPROFILER_AWS_CLUSTER = 'gprofiler/aws/get-cluster-list',
  GPROFILER_AWS_REGION_CLUSTER = 'gprofiler/aws/get-regions-cluster',
  GPROFILER_AWS_SERVICE_LIST = 'gprofiler/aws/get-existing-services',
  GPROFILER_GCP_SERVICE_LIST = 'gprofiler/gcp/get-existing-services',
  GPROFILER_AWS_INSTALL = 'gprofiler/aws/gprofiler-install',
  GPROFILER_GCP_INSTALL = 'gprofiler/gcp/gprofile-install',
  GPROFILER_DASHBOARD_SERVICES = 'gprofiler/gcp/all-deployment-status',
  GPROFILER_DASHBOARD_DEPLOYED_SERVICES = 'gprofiler/dashboard/deployed-services',
  GPROFILER_DASHBOARD_PROFILE_DATA = 'gprofiler/dashboard/profile-data',
  GPROFILER_OPTIMIZATION_SERVICE = 'gprofiler/dashboard/optimization-service',
  GPROFILER_DASHBOARD_CPU_TREND = 'gprofiler/dashboard/cpu-trend',
  GPROFILER_DASHBOARD_METADATA = 'gprofiler/dashboard/metadata',
  GPROFILER_DASHBOARD_METRICS = 'gprofiler/dashboard/metrics',
  GPROFILER_DASHBOARD_INSIGHTS = 'gprofiler/dashboard/get-incident',
  GPROFILER_CREATE_TICKET = 'gprofiler/dashboard/create-incident',
  GPROFILER_GET_SNOW_TICKET = 'gprofiler/dashboard/get-insight-incident/',
  GPROFILER_DASHBOARD_DATE_TIME_WITH_DATA = 'gprofiler/dashboard/datetime-with-data',
  GPROFILER_UPDATE_SERVICE_STATUS = 'gprofiler/dashboard/update-service-status',
  GET_GCP_PURCHASED_CUD_LIST = 'cost-control/gcp/cud/computeEngine/list',
  GET_GCP_COMPUTE_ENGINE_REGIONS = 'cost-control/gcp/cud/computeEngine/getRegions',
  GET_GCP_COMPUTE_ENGINE_COMMITMENT_TYPES = 'cost-control/gcp/cud/computeEngine/getCommitmentPlanTypes',
  GET_GCP_COMPUTE_ENGINE_COST_ESTIMATES = 'cost-control/gcp/cud/computeEngine/getCostEstimates',
  PURCHASE_GCP_COMPUTE_ENGINE_COST_ESTIMATES = 'cost-control/gcp/cud/computeEngine/buy',
  CREATE_TEAM = 'profile/team/create-team',
  UPDATE_TEAM = 'profile/team/update-team',
  GET_ALL_TEAMS = 'profile/team/get-all-team',
  SEARCH_TEAMS = 'profile/team/search-team',
  DELETE_TEAM = 'profile/team/delete-team',
  VALIDATE_TEAM_NAME = 'profile/team/validate-team-name/',
  GET_ALL_SUBSCRIPTIONS = 'reporting/subscribe/getAll',
  SEARCH_SUBSCRIPTIONS = 'reporting/subscribe/search-subscription',
  VALIDATE_SUBSCRIPTION_NAME = 'reporting/subscribe/validate-subscription',
  SUBSCRIPTION = 'reporting/subscribe',
  UPDATE_SUBSCRIPTION = 'reporting/subscribe/update-subscription',
  CREATE_REPORT = 'reporting/reports/create-report',
  UPDATE_REPORT = 'reporting/reports/update-report',
  VALIDATE_REPORT_NAME = 'reporting/reports/validate-report-name',
  GET_ALL_REPORTS = 'reporting/reports/get-all-reports',
  SEARCH_REPORTS = 'reporting/reports/search-report',
  DELETE_REPORT = 'reporting/reports/delete-report',
  SHARE_REPORT = 'reporting/reports/share-report',
  GET_REPORT_BY_NAME = 'reporting/reports/get-report',
  GET_AZURE_CATEGORY_MAPPINGS = 'cost-control/AzureRecommendations/getCategoryMappings',
  APPLY_AZURE_RECOMMENDATIONS = 'cost-control/AzureRecommendations/applyRecommendation',
  GET_AZURE_RECOMMENDATION_AVAILABLE_FILTERS = 'cost-control/AzureRecommendations/recommendation-filters',
  GET_CHATBOT_RECOMMENDED_QUESTIONS = 'chatbot/recommended-questions',
  GET_CHATBOT_RESPONSE = 'chatbot/process-query',
  GET_CHAT_HISTORY = 'chatbot/chat-history',
  SELECT_CHAT_CONNECTION = 'chatbot/select-connection',
  CLEAR_CONVERSATION_HISTORY = 'chatbot/clear-conversation-history',
  GET_ALL_GEN_AI_PROVIDERS = 'profile/admin-control/get-all-genai-models',
  GET_ALL_SUPPORTED_GEN_AI_PROVIDERS = 'profile/admin-control/get-selected-genai-models',
  GET_SELECTED_MODEL = 'chatbot/get-selected-model',
  SELECT_MODEL = 'chatbot/select-model',
  GET_BILLING_DIMENSIONS_DATA = 'reporting/dimensions/billing',
  CREATE_BILLING_MAPPING = 'reporting/dimensions/billing/create',
  UPDATE_BILLING_MAPPING = 'reporting/dimensions/billing/update',
  GET_SYSTEM_BILLING_DIMENSIONS = 'reporting/dimensions/billing/system-dimensions',
  GET_TAG_DIMENSIONS_DATA = 'reporting/dimensions/tags/get-all-tags',
  DELETE_BILLING_MAPPING = 'reporting/dimensions/billing/',
  VERIFY_BILLING_DIMENSION_NAME = 'reporting/dimensions/billing/verify/',
  GET_ALL_FIELDS_BY_PROVIDER = 'reporting/dimensions/billing/getAllDimensions',
  TAG_DIMENSION_NAME_VALIDATION = 'reporting/dimensions/tags/validate-tag',
  TAG_DIMENSIONS_DATA = 'reporting/dimensions/tags',
  UPDATE_TAG_DIMENSIONS_DATA = 'reporting/dimensions/tags/update-tag',
  GRANULATE_GCP_INSTALL = 'gprofiler/gcp/granulate-install',
  GRANULATE_DASHBOARD_SERVICES = 'gprofiler/granulate/sagent-deployments-status',
  GRANULATE_GCP_CLUSTER = 'gprofiler/gcp/granulate-cluster-list',
  GRANULATE_INSTANCE_TABLE = 'gprofiler/granulate/sagents-instances-list',
  GRANULATE_DEACTIVATE_INSTANCES = 'gprofiler/granulate/sagent-deactivate',
  GRANULATE_ACTIVATE_INSTANCES = 'gprofiler/granulate/sagent-activate',
  GRANULATE_DEACTIVATE_ALL_INSTANCES = 'gprofiler/granulate/sagent-deactivate-all',
  GRANULATE_ACTIVATE_ALL_INSTANCES = 'gprofiler/granulate/sagent-activate-all',
  GRANULATE_AWS_CLUSTER = 'gprofiler/aws/sagent-cluster-list',
  GRANULATE_AWS_INSTALL = 'gprofiler/aws/sagent-install',
  GRANULATE_FETCH_CONNECTIONS = 'gprofiler/granulate/fetch-granulate-connections',
  SEARCH_SNOWFLAKE_INTEGRATIONS = 'cloud-connector/snowflake-integration/search',
  GET_SNOWFLAKE_TABLES = 'cloud-connector/snowflake-integration/get-tables',
  SNOWFLAKE_INTEGRATION = 'cloud-connector/snowflake-integration',
  SNOWFLAKE_GET_AVAILABLE_FIELDS = 'reporting/dashboard/snowflake/get-available-fields',
  SNOWFLAKE_GET_CHART_DATA = 'reporting/dashboard/snowflake/getChartData',
  RECOMMENDATION_DATA = 'gprofiler/dashboard/sagent/get-incident',
  GRANULATE_CREATE_TICKET = 'gprofiler/dashboard/sagent/create-incident',
  GRANULATE_CONNECTORS = 'gprofiler/granulate/granulate-connectors',
  GRANULATE_AWS_UNINSTALL = 'gprofiler/aws/sagent-uninstall',
  GRANULATE_GCP_UNINSTALL = 'gprofiler/gcp/granulate-uninstall',
  AWS_RECOMMENDATION_RESERVATION = 'cost-control/aws-recommendation-reservation',
  AWS_SAVINGS_PLANS_RECOMMENDATION = 'cost-control/aws-recommendation-reservation/aws-saving-plans',
  AWS_APPLICATION_DETAILS = 'reporting/dashboard/applications/aws/',
  RELEASE_NOTES = 'reporting/release',
  GCP_COST_IMPACT = 'reporting/dashboard/pricing/gcp/pods/',
  RECOMMENDATION_DATA_COST = 'gprofiler/dashboard/sagent/get-incident/cost',
  TAG_COMPLIANCE = 'reporting/tag-compliance',
  TAG_COMPLIANCE_APPLY_TAGS = 'reporting/tag-compliance/apply-tags',
  GET_APPLICATION_SPEND_PROFILE_DATA = 'reporting/dashboard/billing/spend-diagnostics/',
  GRANULATE_TOTAL_COST_IMPACT = 'gprofiler/granulate/total-cost-impact',
  ADMIN_CONTROLS = 'profile/admin-control',
  GET_GCP_AUTOPILOT_APPLIED_RECOMMENDATIONS = 'cost-control/GCPRecommendations/getAutopilotAppliedRecommendations',
  GET_AWS_AUTOPILOT_APPLIED_RECOMMENDATIONS = 'cost-control/AWSRecommendations/getAutopilotAppliedRecommendations',
  GET_ALL_CMDB_SETUP = 'cloud-connector/cmdb/getAll',
  SEARCH_CMDB_SETUP = 'cloud-connector/cmdb/search',
  DELETE_CMDB_SETUP = 'cloud-connector/cmdb/delete',
  GET_CMDB_DATASOURCE_PROVIDERS = 'cloud-connector/cmdb/get-datasource',
  VALIDATE_DUPLICATE_CMDB_NAME = 'cloud-connector/cmdb/isConnectorNameAlreadyUsed',
  GET_CMDB_CONFIGURATION_BY_ID = 'cloud-connector/cmdb/get-by-id',
  GET_CMDB_FIELDS = 'cloud-connector/cmdb/getFields',
  CREATE_NEW_CMDB_SETUP = 'cloud-connector/cmdb/create',
  UPDATE_CMDB_SETUP = 'cloud-connector/cmdb/update',
}
