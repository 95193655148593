import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReleaseNoteType } from 'pages/ReleaseNotesPage/types';

import { RootState } from './store';

export interface ReleaseNotesState {
  releaseNotes: ReleaseNoteType[];
}

const initialState: ReleaseNotesState = {
  releaseNotes: [],
};

export const releaseNotesSlice = createSlice({
  name: 'releaseNotes',
  initialState,
  reducers: {
    setReleaseNotes: (state, action: PayloadAction<ReleaseNoteType[]>) => {
      state.releaseNotes = action.payload;
    },
  },
});

export const selectReleaseNotes = (state: RootState) => state.releaseNotes;
export const { setReleaseNotes } = releaseNotesSlice.actions;
export default releaseNotesSlice.reducer;
