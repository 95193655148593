import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { uniqBy } from 'lodash';

import LineChart from 'components/LineChart';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Cascader from 'components/Cascader';
import SelectDropdown from 'components/Select';
import { INPUT_SIZE } from 'constants/appearance';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { evaluateRequestArray } from 'utils/handleErrors';
import { DateRangeFilter } from 'types/dataTypes';
import { getDurationAxisLabel } from 'pages/ContainerInsightsPage/utils';

import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';

import { displayRender, onChangeDateFilter, onChangeDates } from '../../utils';
import { UtilizationByNodeAndTimeType } from '../../types';
import { getPodCpuUtilizationExcelData } from '../../utils/exportToExcel';

type PodCpuUtilizationProps = {
  tableData: any[];
  allNodes: string[];
  selectedNode: string;
  setSelectedNode: (val: string) => void;
  requestStatus: string[];
  isTableView: boolean;
  setIsTableView: (value: boolean) => void;
  filteredPodCpuUtilizationData: UtilizationByNodeAndTimeType[];
  pdfView: boolean;
  podCpuUtilizationDateFilter: DateRangeFilter;
  setPodCpuUtilizationDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};

const PodCpuUtilization = ({
  tableData,
  allNodes,
  selectedNode,
  setSelectedNode,
  requestStatus,
  isTableView,
  setIsTableView,
  filteredPodCpuUtilizationData,
  pdfView,
  podCpuUtilizationDateFilter,
  setPodCpuUtilizationDateFilter,
  excelFilters,
}: PodCpuUtilizationProps) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: t('containerInsight.usageMeteringLabels.dimension'),
      dataIndex: 'dimension',
      key: 'dimension',
      width: 150,
    },
    ...uniqBy(filteredPodCpuUtilizationData, 'time').map((item) => ({
      title: item.time,
      key: `${item.time}-utilization`,
      dataIndex: `${item.time}-utilization`,
      width: 100,
    })),
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  defaultValue={[
                    moment(podCpuUtilizationDateFilter.startDate),
                    moment(podCpuUtilizationDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(dateString, setPodCpuUtilizationDateFilter)
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters flex flex-align-items-center flex-gap-16">
      <Cascader
        className="width-40"
        options={DateMenu}
        defaultValue={[podCpuUtilizationDateFilter.option]}
        displayRender={() => displayRender(podCpuUtilizationDateFilter)}
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setPodCpuUtilizationDateFilter)
        }
      />
      <SelectDropdown
        rootClassName="width-40"
        value={selectedNode}
        options={allNodes.map((item) => ({
          value: item,
          label: item,
        }))}
        placeholder={t('containerInsight.usageMeteringLabels.chooseNode')}
        size={INPUT_SIZE.SMALL}
        loading={
          evaluateRequestArray(requestStatus) === REQUEST_STATUS.PROCESSING
        }
        onSelect={(value: string) => setSelectedNode(value)}
        designVersion2
      />
    </div>
  );

  const getComponent = () => {
    return isTableView ? (
      <Table
        pagination={false}
        dataSource={tableData}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer={true}
      />
    ) : (
      <LineChart
        data={filteredPodCpuUtilizationData}
        xField="time"
        yField="utilization"
        groupingField="dimension"
        xTitle={getDurationAxisLabel(podCpuUtilizationDateFilter)}
        yTitle={t('containerInsight.usageMeteringLabels.cpuUtilization')}
        showAllLegend={pdfView}
        disableAnimation={pdfView}
        postfixSymbol="%"
      />
    );
  };

  return (
    <div
      className="pod-cpu-utilization graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.podCpuUtilization')}
        graphName="pod-cpu-utilization"
        setIsTableView={setIsTableView}
        isTableView={isTableView}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        excelData={getPodCpuUtilizationExcelData(
          filteredPodCpuUtilizationData,
          tableData,
          [
            ...excelFilters,
            {
              heading: t('excelExportLabels.node'),
              value: selectedNode?.toString(),
            },
            {
              heading: t('excelExportLabels.startDate'),
              value: podCpuUtilizationDateFilter.startDate,
            },
            {
              heading: t('excelExportLabels.endDate'),
              value: podCpuUtilizationDateFilter.endDate,
            },
          ]
        )}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default PodCpuUtilization;
