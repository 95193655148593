import { useTranslation } from 'react-i18next';

import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import GaugeChart from 'components/GaugeChart';
import { COLORS } from 'constants/graphConfig';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';

type SavingsEffectivenessSummaryProps = {
  pdfView: boolean;
  savingsPercentage: number;
  requestStatus: string[];
};

const SavingsEffectivenessSummary = ({
  pdfView,
  savingsPercentage,
  requestStatus,
}: SavingsEffectivenessSummaryProps) => {
  const { t } = useTranslation();

  const getComponent = () => (
    <div className="gauge-graph full-height">
      <GaugeChart
        percent={savingsPercentage}
        ticks={[0, 1 / 5, 2 / 5, 3 / 5, 4 / 5, 1]}
        colorOverride={[
          COLORS.green1,
          COLORS.green2,
          COLORS.green3,
          COLORS.green4,
          COLORS.primaryButton,
        ]}
        title={t('scorecard.summary.currentMonth')}
        contentText={numberCommaSeparator(savingsPercentage) + '%'}
        disableAnimation={pdfView}
      />
    </div>
  );

  return (
    <div
      className="graph-card savings-effectiveness-summary"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.savingsEffectiveness')}
        graphName="savings-effectiveness"
        isDownloadable={!pdfView}
        isTableViewSwitch={false}
        showExpandIcon={!pdfView}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default SavingsEffectivenessSummary;
