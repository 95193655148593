import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { ICONS } from 'constants/icons';
import { BUTTON_SIZE } from 'constants/appearance';

type EntryTableHeaderProps = {
  title: string;
  onClickAddRow?: () => void;
  disableAddRowCta?: boolean;
};
const EntryTableHeader = ({
  title,
  onClickAddRow,
  disableAddRowCta,
}: EntryTableHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-center flex-space-between full-width">
      <div className="graph-heading-typography">{title}</div>
      {onClickAddRow && (
        <Button
          className="new-row-button"
          title={t(`manualEntry.addNewRow`)}
          iconName={ICONS.ADD_LINE}
          size={BUTTON_SIZE.SMALL}
          onClick={onClickAddRow}
          disabled={disableAddRowCta}
        />
      )}
    </div>
  );
};

export default EntryTableHeader;
