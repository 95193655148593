import i18n from 'i18n';

import { MAX_CHARACTER_LIMIT } from 'constants/validation';

/**
 * @function validateStringLengthLessThan
 * @description Function to validate if the string length is less than specified length
 * @param value string value to be validated
 * @param length length of the string to be validated
 * @returns boolean false if string length is less than specified, else true
 */
export const validateStringLengthLessThan = (
  value: string | undefined,
  length: number = MAX_CHARACTER_LIMIT,
  inputFieldName = '',
  setValidation?: (val: string) => void
) => {
  if ((value ?? '').length > length) {
    setValidation?.(
      `${inputFieldName} ${i18n.t('stringLimitErrorMessage', {
        limit: length,
      })}`
    );
    return true;
  }
  setValidation?.('');
  return false;
};

/**
 * @function isEmptyField
 * @description Function to validate if the string value is empty
 * @param value string value to be validated
 * @returns boolean true if empty, else false
 */
export const isEmptyField = (value: string | number | undefined) => {
  if (!value) {
    return true;
  }

  if (typeof value === 'number') return !value;

  return !value.trim();
};

/**
 * @function validateEmail
 * @description Function to validate email address
 * @param email string email to be validated
 * @returns boolean true if valid email, else false
 */
export const validateEmail = (email: string) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/.test(
      email
    )
  ) {
    return true;
  }
  return false;
};

/**
 * @function validateEmptyField
 * @description Function to validate empty field and set error message
 * @param value string value to be validated
 * @param inputFieldName string field name to be validated
 * @param setValidation callback for setting validation message
 * @param setErrorMessage A boolean indicating whether to set the error message or not true by default
 * @returns true if empty field, false otherwise
 */
export const validateEmptyField = (
  value: string | number | undefined,
  inputFieldName: string,
  setValidation: (val: string) => void,
  setErrorMessage: boolean = true
) => {
  if (isEmptyField(value)) {
    setErrorMessage &&
      setValidation(
        `${inputFieldName} ${i18n.t('connectionCSPForm.isRequired')}`
      );
    return true;
  }

  setErrorMessage && setValidation('');
  return false;
};

/**
 * @function containsUnderscoreAndSpace
 * @description Function to  check if string contains underscore
 * @param value string value to be checked
 * @returns true if contains underscore, false otherwise
 */
export const containsUnderscoreAndSpace = (value: string | undefined) => {
  if (!value) {
    return false;
  }
  return value.includes('_') || value.includes(' ');
};

/**
 * @function getValidationStyle
 * @description Function to return the validation message display styling
 * @param validation string validation message
 * @return string display style
 */
export const getValidationStyle = (validation: string | null) =>
  validation ? 'inline' : 'none';

/**
 * @function isStartWithLowerCaseLetter
 * @param input string to check the starting character
 * @returns true if starts with lowercase letter.
 */
export const isStartWithLowerCaseLetter = (input: string) => {
  const regExp = /^[a-z]+$/;
  if (input && !regExp.test(input[0])) {
    return false;
  }
  return true;
};

/**
 * @function isStringContainsLowercaseNumbersHyphensUnderscore
 * @param input string to check string contains lower case letters, numbers, hyphen and underscore
 * @returns true if starts contains valid characters.
 */
export const isStringContainsLowercaseNumbersHyphensUnderscore = (
  input: string
) => {
  const regExp = /^[a-z0-9\-_]+$/;
  if (input && !regExp.test(input)) {
    return false;
  }
  return true;
};

/**
 * @function isNumber
 * @param input string to validate
 * @param includeDecimal boolean to indicate whether to include decimal or not
 * @param inputFieldName string field name to be validated
 * @param setValidation callback for setting validation message
 * @returns true if check passes else false.
 */
export const isNumber = (
  input: string | undefined,
  includeDecimal: boolean = true,
  inputFieldName?: string,
  setValidation?: (val: string) => void
) => {
  const regExp = includeDecimal
    ? /^[+]?(\d+(?:[.]\d*)?|\.\d+)$/
    : /^0*[1-9]\d*$/;
  const errorMessage = includeDecimal
    ? i18n.t('positiveDecimalErrorMessage')
    : i18n.t('positiveIntegerErrorMessage');

  if (!input || !regExp.test(input)) {
    setValidation?.(`${inputFieldName} ${errorMessage}`);
    return false;
  }

  setValidation?.('');
  return true;
};

/**
 * @function validateRange
 * @description function to validate the number range
 * @param value string or number input to validate
 * @param start starting range
 * @param end ending range
 * @param inputFieldName string field name to be validated
 * @param setValidation callback for setting validation message
 * @returns true if check passes else false.
 */
export const validateRange = (
  value: number | string,
  start: number,
  end: number | undefined,
  inputFieldName?: string,
  setValidation?: (val: string) => void
) => {
  if ((end && Number(value) > end) || Number(value) < start) {
    const errorMessage = i18n.t('rangeError', {
      start: start,
      end: end,
    });
    setValidation?.(`${inputFieldName} ${errorMessage}`);
    return false;
  }

  return true;
};

/**
 * @function validateContainsOnlyAlphabets
 * @param value string to validate
 * @param inputFieldName string field name to be validated
 * @param setValidation callback for setting validation message
 * @returns true if check passes else false.
 */
export const validateContainsOnlyAlphabets = (
  value: string,
  inputFieldName?: string,
  setValidation?: (val: string) => void
) => {
  if (/^[a-z ]+$/i.test(value)) {
    setValidation?.('');
    return true;
  }
  setValidation?.(`${inputFieldName} ${i18n.t('onlyAlphabets')}`);
  return false;
};

/**
 * @function validateAlphanumericNames
 * @description Function to validate the string contains only alphanumeric characters or hyphen, undersore and space
 * @param value string to validate
 * @param inputFieldName string field name to be validated
 * @param setValidation callback for setting validation message
 * @returns true if check passes else false.
 */
export const validateAlphanumericNames = (
  value: string,
  inputFieldName?: string,
  setValidation?: (val: string) => void
) => {
  if (/^[a-z\d\-_+\s]+$/i.test(value)) {
    setValidation?.('');
    return true;
  }
  setValidation?.(
    `${inputFieldName} ${i18n.t('cannotContainSpecialCharacters')}`
  );
  return false;
};

/**
 * @function validateByRegex
 * @description Function to validate the string by regex
 * @param value string to validate
 * @param regex regex to be validated against
 * @param inputFieldName string field name to be validated
 * @param setValidation callback for setting validation message
 * @param errorMessage custo error message
 * @returns true if check passes else false.
 */
export const validateByRegex = (
  value: string,
  regex: RegExp,
  inputFieldName?: string,
  setValidation?: (val: string) => void,
  errorMessage?: string
) => {
  if (regex.test(value)) {
    setValidation?.('');
    return true;
  }

  setValidation?.(errorMessage ?? `${inputFieldName} ${i18n.t('invalid')}`);
  return false;
};

/**
 * @function validateHexColorField
 * @param value string to validate (returns false if alpha channel is there)
 * @param inputFieldName string field name to be validated
 * @param setValidation callback for setting validation message
 * @returns true if check passes else false.
 */
export const validateHexColorField = (
  value: string,
  inputFieldName?: string,
  setValidation?: (val: string) => void
) => {
  if (/^#([0-9A-F]{3}){1,2}$/i.test(value)) {
    setValidation?.('');
    return true;
  }
  setValidation?.(`${inputFieldName} ${i18n.t('invalidColor')}`);
  return false;
};
