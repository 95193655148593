import i18n from 'i18n';
import axios from 'axios';
import moment from 'moment';

import {
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { ConnectionListType } from 'types/dashboard';
import { TypeAndNameByCost } from 'types/dataTypes';
import {
  getProviderForConnection,
  transformDataByGroupAndType,
} from 'utils/dashboardUtils';
import {
  formatArrayToStringByProvider,
  numberCommaSeparator,
} from 'utils/dataFormatterUtils';
import {
  enumerateDaysBetweenDates,
  HYPHEN_DATE_FORMAT,
  HYPHEN_DATE_MONTH_YEAR_FORMAT,
  MONTH_YEAR_FORMAT,
  monthsBetweenDates,
} from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import { getChartData } from 'utils/services';
import { getApplicableGCPRecommendations } from 'pages/CostOptimizationInsightsPage/components/GCP/RecommendationTable/services';
import {
  CHECK_NAME_ID_MAP,
  RecommendationCategoriesLabels,
} from 'constants/recommendations';
import { OTHERS_LABEL } from 'constants/graphLabels';
import { PROVIDER } from 'constants/cloudProviders';
import { fetchRecommenderCategoryTypes } from 'pages/CostOptimizationInsightsPage/utils';
import { getApplicableAWSRecommendations } from 'pages/CostOptimizationInsightsPage/components/AWS/RecommendationTable/services';

import {
  getAwsAutoPilotAppliedRecommendations,
  getGcpAutoPilotAppliedRecommendations,
} from './services';
import {
  AutoPilotAppliedRecommendations,
  RecommendationDetailsType,
} from './types';
import {
  AWS_CHECK_ID_TO_RECOMMENDATION_DETAILS_QUERY_COLUMNS,
  AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD,
  AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD,
  AWS_RECOMMENDATIONS_DETAILS_TABLE_COLUMNS,
  GCP_RECOMMENDATIONS_DETAILS_COLUMNS,
} from './constants';

/**
 * @function getPdfMetaData
 * @description Returns the AutoPilot Savings Pdf Metadata
 * @param connection Connection for which the metadata is returned
 * @return Object containing the metadata
 */
export const getPdfMetaData = (connection: ConnectionListType) => {
  return {
    fileName: i18n.t('autoPilotSavingsRealisation'),
    heading: i18n.t('autoPilotSavingsRealisation'),
    subtitle1: i18n.t('autoPilotSavingsRealisation'),
    provider: getProviderForConnection(connection),
    viewName: i18n.t('autoPilotSavingsRealisation'),
  };
};

/**
 * @function getAutoPilotSavingsByCategoryColumns
 * @description Returns the Columns for AutoPilot Savings by Category table
 * @param dates List of dates to be included in the column
 * @return List of columns containing the column details
 */
export const getAutoPilotSavingsByCategoryColumns = (dates: string[]) => [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('category'),
    dataIndex: 'type',
    key: 'category',
    width: 150,
  },
  ...dates.map((dates) => ({
    title: dates,
    dataIndex: dates,
    key: dates,
    render: (text: any) => '$' + numberCommaSeparator(text ?? 0),
    width: 100,
  })),
];

/**
 * @function getDatesByGraphType
 * @description Returns the list of dates for the given date range based on the month value. Returns month and year if the month is null, else returns the list of dates
 * @param month month for which the dates are required, returns the list of month year if the month is null
 * @param dateRange List containing the start and end date
 * @return List of dates month and year if the month is null else dates for the given month and date range
 */
export const getDatesByGraphType = (
  month: string | null,
  dateRange: string[]
) => {
  return month === null
    ? monthsBetweenDates(dateRange[0], dateRange[1], MONTH_YEAR_FORMAT)
    : enumerateDaysBetweenDates(
        [
          moment().month(month).startOf('month').format(HYPHEN_DATE_FORMAT),
          moment().month(month).endOf('month').format(HYPHEN_DATE_FORMAT),
        ],
        HYPHEN_DATE_MONTH_YEAR_FORMAT,
        'day'
      );
};

/**
 * @function getSavingsChartsCost
 * @description Returns the list of autopilot savings chart based on the selected month
 * @param selectedMonth month for which the data is required, returns the list of monthly data if the month is null
 * @param savingsChartMonthData List containing the monthly data of autopilot savings
 * @param savingsChartDailyData Object containing the map from month to list of daily cost for the month
 * @return List of monthly cost if the month is null, else daily cost for the given month
 */
export const getSavingsChartsCost = (
  selectedMonth: string | null,
  savingsChartMonthData: TypeAndNameByCost[],
  savingsChartDailyData: { [key: string]: TypeAndNameByCost[] }
) => {
  if (selectedMonth) {
    return savingsChartDailyData[selectedMonth] ?? [];
  }

  return savingsChartMonthData;
};

/**
 * @function getSavingsChartsTableData
 * @description Returns the list of autopilot savings data based on the selected month for tabular view
 * @param selectedMonth month for which the data is required, returns the list of monthly data if the month is null
 * @param savingsChartMonthData List containing the monthly data of autopilot savings
 * @param savingsChartDailyData Object containing the map from month to list of daily cost for the month
 * @return List of monthly cost if the month is null, else daily cost for the given month for tabular view
 */
export const getSavingsChartsTableData = (
  selectedMonth: string | null,
  savingsChartMonthData: TypeAndNameByCost[],
  savingsChartDailyData: { [key: string]: TypeAndNameByCost[] }
) => {
  return transformDataByGroupAndType(
    getSavingsChartsCost(
      selectedMonth,
      savingsChartMonthData,
      savingsChartDailyData
    ),
    'type',
    'cost',
    'name'
  );
};

/**
 * @function getRecommenders
 * @description Returns the list of recommenders for the given recommendations and categories
 * @param recommendations Recommendations from which the recommenders needs to be filtered
 * @param categories List of categories for which the recommenders needs to be returned. Returns recommenders from all categories if not exists.
 * @return List of recommenders
 */
const getRecommenders = (
  recommendations: AutoPilotAppliedRecommendations,
  categories?: string[]
) => {
  let consolidatedRecommenders: string[] = [];
  Object.entries(recommendations)
    .filter((entry) => !categories || categories.includes(entry[0]))
    .forEach((entry) => {
      Object.keys(entry[1]).forEach((recommendersList) =>
        consolidatedRecommenders.push(recommendersList)
      );
    });

  return consolidatedRecommenders;
};

/**
 * @function getRecommendations
 * @description Returns the list of recommendations for the given category to recommendations map, categories and recommenders
 * @param recommendations Category to recommenders to recommendations map from which the recommendations needs to be filtered
 * @param categories List of categories for which the recommendations needs to be returned. Returns recommendations from all categories if not exists.
 * @param recommenders List of recommenders for which the recommendations needs to be returned. Returns recommendations from all recommenders if not exists.
 * @return List of recommendations
 */
export const getRecommendations = (
  recommendations: AutoPilotAppliedRecommendations,
  categories?: string[],
  recommenders?: string[]
) => {
  let consolidatedRecommendations: string[] = [];
  Object.entries(recommendations)
    .filter((entry) => !categories || categories.includes(entry[0]))
    .forEach((entry) => {
      Object.entries(entry[1])
        .filter((recommendersList) => {
          return !recommenders || recommenders.includes(recommendersList[0]);
        })
        .forEach((recommendersList) => {
          consolidatedRecommendations.push(...recommendersList[1]);
        });
    });

  return consolidatedRecommendations;
};

/**
 * @function getApiMethodForFetchAutopilotAppliedRecommendation
 * @description Returns the API method to call for fetching the applied recommendations based on the provider
 * @param provider Provider for which the API method is required
 * @return API method to call for fetching the applied recommendations
 */
const getApiMethodForFetchAutopilotAppliedRecommendation = (
  provider: string
) => {
  return provider === PROVIDER.GCP
    ? getGcpAutoPilotAppliedRecommendations
    : getAwsAutoPilotAppliedRecommendations;
};

/**
 * @function fetchAutopilotAppliedRecommendation
 * @description Fetch and returns the auto pilot applied recommendations
 * @param connection Connection for which the recomendations are fetched
 * @param dateRange List containing the start date and end date
 * @return Promise containing the recommendations map
 */
export const fetchAutopilotAppliedRecommendation = async (
  connection: ConnectionListType,
  dateRange: string[]
) => {
  let recommendations: AutoPilotAppliedRecommendations = {};

  const params = {
    connectorId: connection.connectorId,
    fromDate: moment(dateRange[0]).startOf('month').format(HYPHEN_DATE_FORMAT),
    toDate: moment(dateRange[1]).endOf('month').format(HYPHEN_DATE_FORMAT),
  };

  await getApiMethodForFetchAutopilotAppliedRecommendation(
    getProviderForConnection(connection)
  )(params)
    .then((res: any) => {
      recommendations = res.data.responseData;
    })
    .catch((e) => {
      onApiCallError(e, false);
    });

  return recommendations;
};

/**
 * @function fetchYtdRecommendersAndRecommendations
 * @description Function to fetch and return the auto pilot recommenders and recommendations
 * @param selectedConnection Connection for which the recomendations and recommenders are fetched
 * @return Promise containing the object consisting recommendations and recommenders
 */
export const fetchYtdRecommendersAndRecommendations = async (
  selectedConnection: ConnectionListType
) => {
  let recommenders: string[] = [];
  let recommendations: AutoPilotAppliedRecommendations = {};

  await axios
    .all([
      fetchAutopilotAppliedRecommendation(selectedConnection, [
        moment().startOf('year').startOf('month').format(HYPHEN_DATE_FORMAT),
        moment().format(HYPHEN_DATE_FORMAT),
      ]),
      getProviderForConnection(selectedConnection) === PROVIDER.GCP
        ? getApplicableGCPRecommendations()
        : getApplicableAWSRecommendations(),
    ])
    .then((responses: any[]) => {
      recommenders = responses[1].data.responseData;
      recommendations = responses[0];
    });

  return { recommenders, recommendations };
};

/**
 * @function getGcpYtdSavingsQuery
 * @description Function to return the query to fetch the YTD Savings for GCP
 * @param selectedConnection Connection for which the query is constructed
 * @param recommenders List of recommenders to be filtered
 * @param recommendations List of recommendations to be filtered
 * @return Object containing the query to fetch YTD savings for GCP
 */
const getGcpYtdSavingsQuery = (
  selectedConnection: ConnectionListType,
  recommenders: string[],
  recommendations: AutoPilotAppliedRecommendations
) => {
  return {
    columns: [
      {
        label: 'totalCostSaved',
        field: QUERY_FIELDS.SUM_UNITS_PLUS_NANOS,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'seqnum',
          field:
            'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  [QUERY_VALUES.SUCCEEDED],
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.RECOMMENDER,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  recommenders,
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.NAME,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  getRecommendations(recommendations, undefined, recommenders),
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: moment()
                  .startOf('year')
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: moment().format(HYPHEN_DATE_FORMAT),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getGcpYtdSavingsMissedQuery
 * @description Function to return the query to fetch the YTD Savings missed without autopilot for GCP
 * @param selectedConnection Connection for which the query is constructed
 * @param recommenders List of recommenders to be filtered
 * @return Object containing the query to fetch YTD savings missed for GCP
 */
const getGcpYtdSavingsMissedQuery = (
  selectedConnection: ConnectionListType,
  recommenders: string[]
) => {
  return {
    columns: [
      {
        label: 'totalCostMissed',
        field: QUERY_FIELDS.SUM_UNITS_PLUS_NANOS,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'seqnum',
          field:
            'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  [QUERY_VALUES.ACTIVE, QUERY_VALUES.FAILED],
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.RECOMMENDER,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  recommenders,
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: moment()
                  .startOf('year')
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: moment().format(HYPHEN_DATE_FORMAT),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getGcpYtdCostMtrics
 * @description Function to fetch the YTD Savings with autopilot and savings missed without autopilot for GCP
 * @param selectedConnection Connection for which the data is fetched
 * @param recommenders List of recommenders to be filtered
 * @param recommendations List of recommendations for which the savings is fetched
 * @return Object containing the cost details of savings acheived and savings missed
 */
const getGcpYtdCostMtrics = async (
  selectedConnection: ConnectionListType,
  recommenders: string[],
  recommendations: AutoPilotAppliedRecommendations
) => {
  const ytdSavingsQuery = getGcpYtdSavingsQuery(
    selectedConnection,
    recommenders,
    recommendations
  );
  const ytdSavingsMissedQuery = getGcpYtdSavingsMissedQuery(
    selectedConnection,
    recommenders
  );

  let ytdSavings: number = 0;
  let ytdSavingsMissed: number = 0;

  await axios
    .all([
      getChartData(ytdSavingsQuery, selectedConnection.connectorId),
      getChartData(ytdSavingsMissedQuery, selectedConnection.connectorId),
    ])
    .then((responses: any) => {
      ytdSavings = responses[0].data[0].totalCostSaved;
      ytdSavingsMissed = responses[1].data[0].totalCostMissed;
    });

  return { ytdSavings, ytdSavingsMissed };
};

/**
 * @function getAwsYtdSavingsCostQuery
 * @description Function to return the YTD Savings with autopilot query for AWS
 * @param selectedConnection Connection for which the query is constructed
 * @param recommender Recommender (CheckID) for which the query is constructed
 * @param recommendations List of recommendations for which the savings is fetched
 * @return Object containing query for savings acheived through autopilot
 */
const getAwsYtdSavingsCostQuery = (
  selectedConntion: ConnectionListType,
  recommender: keyof typeof AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD,
  recommendations: string[]
) => {
  return {
    columns: [
      {
        label: 'totalCostSaved',
        field: AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD[recommender],
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'rownum',
          field: `ROW_NUMBER() OVER (PARTITION BY "${AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD[recommender]}" ORDER BY status_priority DESC, datetime DESC)`,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment()
                  .startOf('year')
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment().format(
                  HYPHEN_DATE_FORMAT
                )} 23:59:59'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: `"${AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD[recommender]}"`,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  recommendations,
                  getProviderForConnection(selectedConntion)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.ROW_NUM,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.SUCCEEDED],
              getProviderForConnection(selectedConntion)
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getAwsYtdSavingsCostMetrics
 * @description Function to return the YTD Savings with autopilot for AWS
 * @param selectedConnection Connection for which the query is constructed
 * @param recommenders List of recommenders for which the data is fetched
 * @param recommendations List of recommendations for which the savings is fetched
 * @return Cost Saved through autopilot
 */
const getAwsYtdSavingsCostMetrics = async (
  selectedConnection: ConnectionListType,
  recommenders: string[],
  recommendations: AutoPilotAppliedRecommendations
) => {
  let costSavings: number = 0;

  const requests = recommenders.map((recommender) =>
    getChartData(
      getAwsYtdSavingsCostQuery(
        selectedConnection,
        recommender as any,
        getRecommendations(recommendations, undefined, [recommender])
      ),
      selectedConnection.connectorId,
      { checkId: recommender.toLowerCase() }
    )
  );

  await axios.all(requests).then((responses: any[]) => {
    costSavings = responses.reduce(
      (acc, res) => (acc += Number(res.data[0]?.totalCostSaved ?? 0)),
      0
    );
  });

  return costSavings;
};

/**
 * @function getAwsYtdSavingsMissedCostQuery
 * @description Function to return the query for YTD Savings missed without autopilot for AWS
 * @param selectedConnection Connection for which the query is constructed
 * @param recommender Recommender for which the query is constructed
 * @return Object containing the query for YTD savings missed for AWS connection
 */
const getAwsYtdSavingsMissedCostQuery = (
  selectedConntion: ConnectionListType,
  recommender: keyof typeof AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD
) => {
  return {
    columns: [
      {
        label: 'totalCostMissed',
        field: AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD[recommender],
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'rownum',
          field: `ROW_NUMBER() OVER (PARTITION BY "${AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD[recommender]}" ORDER BY status_priority DESC, datetime DESC)`,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment()
                  .startOf('year')
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment().format(
                  HYPHEN_DATE_FORMAT
                )} 23:59:59'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.ROW_NUM,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.ACTIVE, QUERY_VALUES.FAILED],
              getProviderForConnection(selectedConntion)
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getAwsYtdSavingsMissedCostMetrics
 * @description Function to return the YTD Savings missed without autopilot for AWS
 * @param selectedConnection Connection for which the query is constructed
 * @param recommenders List of recommenders for which the data is fetched
 * @return Cost Savings Missed without AutoPilot
 */
const getAwsYtdSavingsMissedCostMetrics = async (
  selectedConnection: ConnectionListType,
  recommenders: string[]
) => {
  let costSavingsMissed: number = 0;

  const requests = recommenders.map((recommender) =>
    getChartData(
      getAwsYtdSavingsMissedCostQuery(selectedConnection, recommender as any),
      selectedConnection.connectorId,
      { checkId: recommender.toLowerCase() }
    )
  );

  await axios.all(requests).then((responses: any[]) => {
    costSavingsMissed = responses.reduce(
      (acc, res) => (acc += Number(res.data[0]?.totalCostMissed ?? 0)),
      0
    );
  });

  return costSavingsMissed;
};

/**
 * @function getAwsYtdCostMtrics
 * @description Function to return the YTD Savings with autopilot and savings missed without autopilot for AWS
 * @param selectedConnection Connection for which the data is fetched
 * @param recommenders List of recommenders for which the data is fetched
 * @param recommendations List of recommendations for which the savings is fetched
 * @return Object containing the savings and savings missed due to autopilot
 */
const getAwsYtdCostMtrics = async (
  selectedConnection: ConnectionListType,
  recommenders: string[],
  recommendations: AutoPilotAppliedRecommendations
) => {
  let ytdSavings: number = 0;
  let ytdSavingsMissed: number = 0;

  await axios
    .all([
      getAwsYtdSavingsCostMetrics(
        selectedConnection,
        recommenders,
        recommendations
      ),
      getAwsYtdSavingsMissedCostMetrics(selectedConnection, recommenders),
    ])
    .then((responses: any[]) => {
      ytdSavings = responses[0];
      ytdSavingsMissed = responses[1];
    });

  return { ytdSavings, ytdSavingsMissed };
};

const onCatchError = (error: any, setReqStatus: (val: string) => void) => {
  onApiCallError(error, false, setReqStatus);
};

/**
 * @function fetchYtdCostMetrics
 * @description Function to fetch the YTD savings through autopilot
 * @param selectedConnection Connection for which the query is constructed
 * @param setData Object containing the callbacks to set the data and the request status
 */
export const fetchYtdCostMetrics = (
  selectedConnection: ConnectionListType,
  setData: {
    setYtdSavings: (val: number) => void;
    setYtdSavingsMissed: (val: number) => void;
    setReqStatus: (val: string) => void;
  }
) => {
  setData.setReqStatus(REQUEST_STATUS.PROCESSING);

  fetchYtdRecommendersAndRecommendations(selectedConnection)
    .then(({ recommenders, recommendations }) => {
      (getProviderForConnection(selectedConnection) === PROVIDER.GCP
        ? getGcpYtdCostMtrics(selectedConnection, recommenders, recommendations)
        : getAwsYtdCostMtrics(selectedConnection, recommenders, recommendations)
      )
        .then(({ ytdSavings, ytdSavingsMissed }) => {
          setData.setYtdSavings(ytdSavings);
          setData.setYtdSavingsMissed(ytdSavingsMissed);
          setData.setReqStatus(REQUEST_STATUS.SUCCESS);
        })
        .catch((e) => onCatchError(e, setData.setReqStatus));
    })
    .catch((e) => {
      onCatchError(e, setData.setReqStatus);
    });
};

/**
 * @function getCategoryQueryField
 * @description Function to construct the query field for category with CASES
 * @param recommendations Object containing the category to recommenders to recommendations map for which the CASE conditions are added
 * @returns String containing the query with CASE
 */
const getCategoryQueryField = (
  recommendations: AutoPilotAppliedRecommendations
) => {
  let queryField: string = '(CASE';
  Object.entries(recommendations).forEach(([category, recommenders]) => {
    const recommendersList: string[] = Object.keys(recommenders);
    const categoryName =
      RecommendationCategoriesLabels.find(
        (categoryLabel) => categoryLabel.key === category
      )?.label ?? OTHERS_LABEL;
    queryField += ` WHEN ${QUERY_FIELDS.RECOMMENDER} ${
      COMPARATORS.IN
    } ${formatArrayToStringByProvider(
      recommendersList,
      PROVIDER.GCP
    )} THEN "${categoryName}"`;
  });

  queryField += ` ELSE "${OTHERS_LABEL}" END)`;
  return queryField;
};

const fetchGcpMonthlySavingsCost = async (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  appliedRecommendations: AutoPilotAppliedRecommendations
) => {
  const recommendations = getRecommendations(appliedRecommendations);

  const requestBody = {
    columns: [
      {
        label: 'totalCostSaved',
        field: QUERY_FIELDS.SUM_UNITS_PLUS_NANOS,
      },
      {
        label: 'month',
        field: `FORMAT_TIMESTAMP('%Y-%m', ${QUERY_FIELDS.LAST_REFRESH_TIME})`,
      },
      {
        label: 'category',
        field: getCategoryQueryField(appliedRecommendations),
      },
    ],
    groupBy: ['month', 'category'],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.SEQUENCE_NUMBER,
          field:
            'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  [QUERY_VALUES.SUCCEEDED],
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.NAME,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  recommendations,
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: moment(dateRange[0])
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: moment(dateRange[1])
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };

  let data: any[] = [];

  await getChartData(requestBody, selectedConnection.connectorId).then(
    (res: any) => {
      data = res.data;
    }
  );

  return data;
};

const getAwsMonthlySavingsQuery = (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  category: string,
  recommender: keyof typeof AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD,
  appliedRecommendations: AutoPilotAppliedRecommendations
) => {
  return {
    columns: [
      {
        label: 'totalCostSaved',
        field: AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD[recommender],
      },
      {
        label: 'category',
        field: `'${
          RecommendationCategoriesLabels.find((item) => item.key === category)
            ?.label
        }'`,
      },
      {
        label: 'month',
        field: QUERY_FIELDS.DATE_FORMAT_DATE_PARSE_DATETIME_YEAR_MONTH,
      },
    ],
    groupBy: ['month', 'category'],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.ROW_NUM,
          field: `ROW_NUMBER() OVER (PARTITION BY "${AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD[recommender]}" ORDER BY status_priority DESC, datetime DESC)`,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment(dateRange[0])
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment(dateRange[1])
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 23:59:59'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: `"${AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD[recommender]}"`,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  getRecommendations(
                    appliedRecommendations,
                    [category],
                    [recommender]
                  ),
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.ROW_NUM,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.SUCCEEDED],
              getProviderForConnection(selectedConnection)
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

const combineArraysAndSumCost = (data: any[][], nameField: string) => {
  let result = data.reduce((acc, dataArray) => {
    dataArray.forEach((dataItem) => {
      let index = acc.findIndex(
        (item) =>
          item[nameField] === dataItem[nameField] &&
          item.category === dataItem.category
      );

      if (index !== -1) {
        acc[index].totalCostSaved += Number(dataItem.totalCostSaved);
      } else {
        acc.push({
          ...dataItem,
          totalCostSaved: Number(dataItem.totalCostSaved),
        });
      }
    });

    return acc;
  }, []);

  return result;
};

const getAwsSavingsTrendByCategory = async (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  category: string,
  recommenders: string[],
  appliedRecommendations: AutoPilotAppliedRecommendations,
  month: string | null
) => {
  const recommendersWithAppliedRecommendations = recommenders.filter(
    (recommender) =>
      getRecommendations(appliedRecommendations, [category], [recommender])
        .length > 0
  );
  const requests = recommendersWithAppliedRecommendations.map((recommender) =>
    getChartData(
      (month ? getAwsDailySavingsQuery : getAwsMonthlySavingsQuery)(
        selectedConnection,
        dateRange,
        category,
        recommender as keyof typeof AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD,
        appliedRecommendations
      ),
      selectedConnection.connectorId,
      {
        checkId: recommender.toLowerCase(),
      }
    )
  );

  let monthlySavings: TypeAndNameByCost[] = [];

  await axios.all(requests).then((responses: any[]) => {
    monthlySavings = combineArraysAndSumCost(
      responses.map((res) => res.data),
      month ? 'day' : 'month'
    );
  });

  return monthlySavings;
};

const fetchAwsSavingsCostTrend = async (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  appliedRecommendations: AutoPilotAppliedRecommendations,
  month: string | null
) => {
  const requests = RecommendationCategoriesLabels.filter(
    (category) =>
      getRecommendations(appliedRecommendations, [category.key]).length > 0
  ).map((category) =>
    getAwsSavingsTrendByCategory(
      selectedConnection,
      dateRange,
      category.key,
      getRecommenders(appliedRecommendations, [category.key]),
      appliedRecommendations,
      month
    )
  );

  let allCategoriesMonthlyCost: any[] = [];

  await axios.all(requests).then((responses: any[]) => {
    allCategoriesMonthlyCost = combineArraysAndSumCost(
      responses,
      month ? 'day' : 'month'
    );
  });

  return allCategoriesMonthlyCost;
};

/**
 * @function fetchMonthlySavingsCost
 * @description Function to fetch and set the monthly autopilot savings realisation data
 * @param selectedConnection Connection for which the cost savings is fetched
 * @param dateRange List containing the start and end dates for which the data is fetched
 * @param setData Object containing the callbacks to set the data fetched and request status
 */
export const fetchMonthlySavingsCost = (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  setData: {
    setMonthlyCost: (val: TypeAndNameByCost[]) => void;
    setReqStatus: (val: string) => void;
  }
) => {
  setData.setReqStatus(REQUEST_STATUS.PROCESSING);

  fetchAutopilotAppliedRecommendation(selectedConnection, [
    moment(dateRange[0]).startOf('month').format(HYPHEN_DATE_FORMAT),
    moment(dateRange[1]).endOf('month').format(HYPHEN_DATE_FORMAT),
  ]).then((appliedRecommendations) => {
    (getProviderForConnection(selectedConnection) === PROVIDER.GCP
      ? fetchGcpMonthlySavingsCost(
          selectedConnection,
          dateRange,
          appliedRecommendations
        )
      : fetchAwsSavingsCostTrend(
          selectedConnection,
          dateRange,
          appliedRecommendations,
          null
        )
    )
      .then((responseData: any[]) => {
        const months = getDatesByGraphType(null, dateRange);
        const data: TypeAndNameByCost[] = [];
        months.forEach((month) => {
          RecommendationCategoriesLabels.forEach((category) => {
            const dataForMonth = responseData.find(
              (item: any) =>
                month === moment(item.month).format(MONTH_YEAR_FORMAT) &&
                item.category === category.label
            );
            data.push({
              name: month,
              cost: dataForMonth?.totalCostSaved ?? 0,
              type: category.label,
            });
          });
        });
        setData.setMonthlyCost(data);
        setData.setReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setData.setReqStatus);
      });
  });
};

const fetchGcpDailySavingsCost = async (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  appliedRecommendations: AutoPilotAppliedRecommendations
) => {
  const recommendations = getRecommendations(appliedRecommendations);

  const requestBody = {
    columns: [
      {
        label: 'totalCostSaved',
        field: QUERY_FIELDS.SUM_UNITS_PLUS_NANOS,
      },
      {
        label: 'day',
        field: `FORMAT_TIMESTAMP('%Y-%m-%d', ${QUERY_FIELDS.LAST_REFRESH_TIME})`,
      },
      {
        label: 'category',
        field: getCategoryQueryField(appliedRecommendations),
      },
    ],
    groupBy: ['day', 'category'],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.SEQUENCE_NUMBER,
          field:
            'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  [QUERY_VALUES.SUCCEEDED],
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.NAME,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  recommendations,
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: dateRange[0],
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: dateRange[1],
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };

  let data: any[] = [];

  await getChartData(requestBody, selectedConnection.connectorId).then(
    (res: any) => {
      data = res.data;
    }
  );

  return data;
};

const getAwsDailySavingsQuery = (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  category: string,
  recommender: keyof typeof AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD,
  appliedRecommendations: AutoPilotAppliedRecommendations
) => {
  return {
    columns: [
      {
        label: 'totalCostSaved',
        field: AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD[recommender],
      },
      {
        label: 'category',
        field: `'${
          RecommendationCategoriesLabels.find((item) => item.key === category)
            ?.label
        }'`,
      },
      {
        label: 'day',
        field: QUERY_FIELDS.DATE_FORMAT_DATE_PARSE_DATETIME_YEAR_MONTH_DAY,
      },
    ],
    groupBy: ['day', 'category'],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.ROW_NUM,
          field: `ROW_NUMBER() OVER (PARTITION BY "${AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD[recommender]}" ORDER BY status_priority DESC, datetime DESC)`,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment(dateRange[0])
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment(dateRange[1])
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 23:59:59'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: `"${AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD[recommender]}"`,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  getRecommendations(
                    appliedRecommendations,
                    [category],
                    [recommender]
                  ),
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.ROW_NUM,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.SUCCEEDED],
              getProviderForConnection(selectedConnection)
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function fetchDailySavingsCost
 * @description Function to fetch and set the daily autopilot savings realisation data
 * @param selectedConnection Connection for which the cost savings is fetched
 * @param month Month for which the data is fetched
 * @param dailyCost Object containing the map of month to the list of cost
 * @param setData Object containing the callbacks to set the data fetched and request status
 */
export const fetchDailySavingsCost = (
  selectedConnection: ConnectionListType,
  month: string,
  dailyCost: { [key: string]: TypeAndNameByCost[] },
  setData: {
    setDailyCost: (val: { [key: string]: TypeAndNameByCost[] }) => void;
    setReqStatus: (val: string) => void;
  }
) => {
  setData.setReqStatus(REQUEST_STATUS.PROCESSING);
  const startDate = moment(month, MONTH_YEAR_FORMAT)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT);
  const endDate =
    moment(month, MONTH_YEAR_FORMAT).month() === moment().month()
      ? moment().format(HYPHEN_DATE_FORMAT)
      : moment(month).endOf('month').format(HYPHEN_DATE_FORMAT);

  fetchAutopilotAppliedRecommendation(selectedConnection, [
    moment(month).startOf('month').format(HYPHEN_DATE_FORMAT),
    endDate,
  ]).then((appliedRecommendations) => {
    (getProviderForConnection(selectedConnection) === PROVIDER.GCP
      ? fetchGcpDailySavingsCost(
          selectedConnection,
          [startDate, endDate],
          appliedRecommendations
        )
      : fetchAwsSavingsCostTrend(
          selectedConnection,
          [startDate, endDate],
          appliedRecommendations,
          month
        )
    )
      .then((responseData: any[]) => {
        const dates = getDatesByGraphType(month, [
          moment(month).startOf('month').format(HYPHEN_DATE_FORMAT),
          endDate,
        ]);
        const data: TypeAndNameByCost[] = [];
        dates.forEach((date) => {
          RecommendationCategoriesLabels.forEach((category) => {
            const dataForMonth = responseData.find(
              (item: any) =>
                date ===
                  moment(item.day).format(HYPHEN_DATE_MONTH_YEAR_FORMAT) &&
                item.category === category.label
            );
            data.push({
              name: date,
              cost: dataForMonth?.totalCostSaved ?? 0,
              type: category.label,
            });
          });
        });
        setData.setDailyCost({ ...dailyCost, [month]: data });
        setData.setReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setData.setReqStatus);
      });
  });
};

const getRecommenderLabel = (
  category: string,
  recommender: string,
  provider: string
) => {
  return provider === PROVIDER.GCP
    ? fetchRecommenderCategoryTypes(category).find(
        (item) => item.key === recommender
      )?.label
    : CHECK_NAME_ID_MAP.find((item) => item.id === recommender)?.name;
};

/**
 * @function getRecommendersOptions
 * @description Returns the list of recommendation option with label and value
 * @param recommendations Map of categoty to recommenders to recommendations
 * @param category Category for which the recommenders are fetched
 * @returns The list of recommenders with value and label
 */
export const getRecommendersOptions = (
  recommendations: AutoPilotAppliedRecommendations,
  category: string,
  provider: string
) => {
  const recommenders = getRecommenders(recommendations, [category]);

  return recommenders.map((recommender) => ({
    value: recommender,
    label: getRecommenderLabel(category, recommender, provider),
  }));
};

/**
 * @function getRecommenderSelected
 * @description Returns the recommender based on the current recommender selected and recommenders fetched
 * @param selectedRecommender Recommender currently selected
 * @param recommenders List of recommenders selected
 * @returns recommender based on the selected recommender and recommendations fetched
 */
const getRecommenderSelected = (
  selectedRecommender: string | undefined,
  recommenders: string[]
) => {
  if (!selectedRecommender || !recommenders.includes(selectedRecommender)) {
    return recommenders[0];
  }

  return selectedRecommender;
};

/**
 * @function fetchAndSetAppliedRecommendations
 * @description Function to fetch and set the recommendations applied through autopilot
 * @param selectedConnection Connection for which the cost savings is fetched
 * @param categories List of categories for which the recommendations are fetched
 * @param dateRange List containing the start and end date
 * @param selectedRecommender Recommender for which the recommendations are fetched
 * @param setData Object containing the callbacks to set the data fetched and request status
 */
export const fetchAndSetAppliedRecommendations = (
  selectedConnection: ConnectionListType,
  categories: string[],
  dateRange: string[],
  selectedRecommender: string | undefined,
  setData: {
    setAppliedRecommendations: (val: AutoPilotAppliedRecommendations) => void;
    setReqStatus: (val: string) => void;
    setSelectedRecommender: (val: string) => void;
  }
) => {
  setData.setReqStatus(REQUEST_STATUS.PROCESSING);

  fetchAutopilotAppliedRecommendation(selectedConnection, dateRange)
    .then((appliedRecommendations) => {
      setData.setAppliedRecommendations(appliedRecommendations);
      const recommenders = getRecommenders(appliedRecommendations, categories);

      // Set the recommender based on the recommenders fetched and currently selected recommender
      setData.setSelectedRecommender(
        getRecommenderSelected(selectedRecommender, recommenders)
      );
      setData.setReqStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setData.setReqStatus);
    });
};

const fetchGcpRecommendationDetails = async (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  recommender: string,
  recommendations: string[]
) => {
  const requestBody = {
    columns: [
      {
        label: 'projectNumber',
        field: QUERY_FIELDS.CLOUD_ENTITY_ID,
      },
      {
        label: 'priority',
        field: QUERY_FIELDS.PRIORITY,
      },
      {
        label: 'recommendation',
        field: QUERY_FIELDS.DESCRIPTION,
      },
      {
        label: 'lastRefreshed',
        field: QUERY_FIELDS.LAST_REFRESH_TIME,
      },
      {
        label: 'costSavings',
        field: QUERY_FIELDS.SUM_IF_NULL_UNITS_NANOS,
      },
      {
        label: 'recommender_subtype',
        field: QUERY_FIELDS.RECOMMENDER_SUBTYPE,
      },
      {
        label: 'recommendationName',
        field: QUERY_FIELDS.NAME,
      },
      {
        label: 'recommendationState',
        field: QUERY_FIELDS.STATE,
      },
      {
        label: 'targetResource',
        field: QUERY_FIELDS.TARGET_RESOURCES,
      },
      {
        label: 'recommendationCategory',
        field: QUERY_FIELDS.CATEGORY,
      },
    ],
    orderBy: [
      {
        label: 'recommendationName',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.SEQUENCE_NUMBER,
          field:
            'row_number() over (partition by name, CONCAT(EXTRACT(YEAR FROM last_refresh_time),EXTRACT(MONTH FROM last_refresh_time)) order by last_refresh_time desc)',
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.STATE,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  [QUERY_VALUES.SUCCEEDED],
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.RECOMMENDER,
                comparator: COMPARATORS.EQUALS,
                value: recommender,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.NAME,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  recommendations,
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: moment(dateRange[0])
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.LAST_REFRESH_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: moment(dateRange[1])
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };

  let recommendationDetails: RecommendationDetailsType[] = [];

  await getChartData(requestBody, selectedConnection.connectorId).then(
    (res: any) => {
      recommendationDetails = res.data.map((item: any) => ({
        ...item,
        recommendationId: item.recommendationName.substring(
          item.recommendationName.lastIndexOf('/')
        ),
      }));
    }
  );

  return recommendationDetails;
};

const fetchAwsRecommendationDetails = async (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  recommender: keyof typeof AWS_CHECK_ID_TO_RECOMMENDATION_DETAILS_QUERY_COLUMNS,
  recommendations: string[]
) => {
  const requestBody = {
    columns: AWS_CHECK_ID_TO_RECOMMENDATION_DETAILS_QUERY_COLUMNS[recommender],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'rownum',
          field: `ROW_NUMBER() OVER (PARTITION BY "${AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD[recommender]}" ORDER BY status_priority DESC, datetime DESC)`,
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
        ],
        filterGroups: [
          {
            filters: [
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment(dateRange[0])
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
                comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
                value: `#TIMESTAMP '${moment(dateRange[1])
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT)} 23:59:59'`,
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
              {
                field: `"${AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD[recommender]}"`,
                comparator: COMPARATORS.IN,
                value: formatArrayToStringByProvider(
                  recommendations,
                  getProviderForConnection(selectedConnection)
                ),
                conjunctToNextFilter: CONJUNCTIONS.AND,
              },
            ],
          },
        ],
      },
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.ROW_NUM,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.SUCCEEDED],
              getProviderForConnection(selectedConnection)
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };

  let recommendationDetails: RecommendationDetailsType[] = [];

  await getChartData(requestBody, selectedConnection.connectorId, {
    checkId: recommender.toLowerCase(),
  }).then((res: any) => {
    recommendationDetails = res.data;
  });

  return recommendationDetails;
};

/**
 * @function fetchRecommendationDetails
 * @description Function to fetch and set the recommendation details applied through autopilot
 * @param selectedConnection Connection for which the cost savings is fetched
 * @param dateRange List containing the start and end date
 * @param recommender Recommender for which the recommendations are fetched
 * @param recommendations List of recommendations for which the details are fethed
 * @param setData Object containing the callbacks to set the data fetched and request status
 */
export const fetchRecommendationDetails = (
  selectedConnection: ConnectionListType,
  dateRange: string[],
  recommender: string,
  recommendations: string[],
  setData: {
    setRecommendations: (val: RecommendationDetailsType[]) => void;
    setReqStatus: (val: string) => void;
  }
) => {
  setData.setReqStatus(REQUEST_STATUS.PROCESSING);

  (getProviderForConnection(selectedConnection) === PROVIDER.GCP
    ? fetchGcpRecommendationDetails
    : fetchAwsRecommendationDetails)(
    selectedConnection,
    dateRange,
    recommender as keyof typeof AWS_CHECK_ID_TO_RECOMMENDATION_DETAILS_QUERY_COLUMNS,
    recommendations
  )
    .then((recommendationsList: any[]) => {
      setData.setRecommendations(recommendationsList);
      setData.setReqStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setData.setReqStatus);
    });
};

export const getRecommendationDetailsTableColumns = (
  provider: string,
  recommender?: string
) => {
  if (!recommender) {
    return [];
  }

  return provider === PROVIDER.GCP
    ? GCP_RECOMMENDATIONS_DETAILS_COLUMNS
    : AWS_RECOMMENDATIONS_DETAILS_TABLE_COLUMNS[
        recommender as keyof typeof AWS_RECOMMENDATIONS_DETAILS_TABLE_COLUMNS
      ];
};
