import i18n from 'i18n';
import moment from 'moment';

import { REQUEST_STATUS } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { ConnectionListType, TagsFilterType, TagsType } from 'types/dashboard';
import { DashboardListType } from 'types/navigationMenu';
import { TypeAndNameByCost } from 'types/dataTypes';
import {
  getAWSTagFiltersData,
  getAzureTagFiltersData,
  getGCPTagFiltersData,
  isDashboardWithStaticData,
  transformDataByGroupAndType,
} from 'utils/dashboardUtils';
import { getServiceSpendProfile, getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  MONTH_YEAR_SHORT,
  YEAR_HYPHEN_MONTH,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  getMonthYearShortList,
} from 'utils/date';

import {
  getAWSServiceSpendProfileQuery,
  getAwsCloudSpendQuery,
  getAwsTaggedUntaggedSpendQuery,
} from './components/AWSCostSummaryDashboard/utils';
import {
  getGCPServiceSpendProfileQuery,
  getGcpCloudSpendQuery,
  getGcpTaggedUntaggedSpendQuery,
} from './components/GCPCostSummaryDashboard/utils';
import {
  getAzureServiceSpendProfileQuery,
  getAzureCloudSpendQuery,
  getAzureTaggedUntaggedSpendQuery,
} from './components/AzureCostSummaryDashboard/utils';
import { CostTrendColumns } from './constants';
import {
  ProfileSpendDataType,
  SpendProfileDataType,
} from './components/SpendProfileChartWrapper/types';
import { ProfileSpendTypes } from './components/SpendProfileChartWrapper/constants';

/**
 * @function getCostTrendsHeading
 * @description Function to return the gcp cost trends graph heading based on the drilldown level
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @return String containing the graph heading based on the drilldown
 */
export const getCostTrendsHeading = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined
) => {
  if (!selectedTrendMonth) {
    return i18n.t('graphHeadings.costTrend');
  }

  if (!selectedTrendService) {
    return `${i18n.t('graphHeadings.costByService')} (${selectedTrendMonth})`;
  }

  if (selectedTrendService) {
    return `${i18n.t(
      'graphHeadings.costByResources'
    )} (${selectedTrendMonth})(${selectedTrendService})`;
  }

  return i18n.t('graphHeadings.costTrend');
};

/**
 * @function getCostTrendsColumns
 * @description Function to return the columns for excel and pdf export.
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @return List of columns for export
 */
export const getCostTrendsColumns = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined
) => {
  let tableColumns: any[] = [...CostTrendColumns];

  if (!selectedTrendMonth) {
    return tableColumns;
  }

  tableColumns.splice(3, 1);
  if (!selectedTrendService) {
    tableColumns.splice(1, 1, {
      title: i18n.t('carbonFootprint.carbonEmissionByServices.services'),
      dataIndex: 'service',
      key: 'service',
      width: '60%',
    });
  }

  if (selectedTrendService) {
    tableColumns.splice(1, 1, {
      title: i18n.t('costByResource.resources'),
      dataIndex: 'resource',
      key: 'resource',
      width: '60%',
    });
  }

  return tableColumns;
};

/**
 * @function getCostTrendChartLegends
 * @description Function to return the legends for the cost trend chart.
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @returns List of legends for the cost trend chart.
 */
export const getCostTrendChartLegends = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined
) => {
  if (!selectedTrendMonth) {
    return [i18n.t('costTrend.costWithoutSuffix'), i18n.t('costTrend.trend')];
  }

  if (!selectedTrendService) {
    return [i18n.t('graphHeadings.costByService')];
  }

  return [i18n.t('graphHeadings.costByResources')];
};

/**
 * @function getCostTrendXAxisLabel
 * @description Function to return the x-axis label for the cost trend chart.
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @returns X-axis label for the cost trend chart.
 */
export const getCostTrendXAxisLabel = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined
) => {
  if (!selectedTrendMonth) {
    return i18n.t('months');
  }

  if (!selectedTrendService) {
    return i18n.t('services');
  }

  return i18n.t('resources');
};

/**
 * @function fetchCloudSpendData
 * @description Fetches the cloud spend data.
 * @param setRequestStatus - The function to set the request status.
 * @param selectedDashboard - The selected dashboard.
 * @param selectedConnection Object containing the connection details
 * @param dateFilter - The date filter including start and end date.
 * @param tagFilters - The tag filters.
 * @param selectedDashboardView - The selected dashboard view.
 * @param stateUpdates - The state updates including setData and setSelectedData.
 * @param selectedAccounts List of accounts selected for filter
 */
export const fetchCloudSpendData = (
  setRequestStatus: (status: string) => void,
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  dateFilter: {
    startDate: string;
    endDate: string;
  },
  tagFilters: TagsFilterType[],
  selectedDashboardView: string,
  stateUpdates: {
    setData: (data: any[]) => void;
    setSelectedData: (data: any[]) => void;
  },
  selectedAccounts?: string[]
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);
  let request;

  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.GCP:
      request = getGcpCloudSpendQuery(
        dateFilter.startDate,
        dateFilter.endDate,
        getGCPTagFiltersData(
          tagFilters,
          selectedDashboard.id,
          selectedDashboardView
        ),
        selectedDashboard,
        selectedConnection
      );
      break;
    case PROVIDER.AWS:
      request = getAwsCloudSpendQuery(
        dateFilter.startDate,
        dateFilter.endDate,
        isDashboardWithStaticData(selectedDashboard),
        getAWSTagFiltersData(
          tagFilters,
          selectedDashboard.id,
          selectedDashboardView
        ),
        selectedDashboard,
        selectedConnection
      );
      break;
    case PROVIDER.AZURE:
      request = getAzureCloudSpendQuery(
        dateFilter.startDate,
        dateFilter.endDate,
        isDashboardWithStaticData(selectedDashboard),
        getAzureTagFiltersData(
          tagFilters,
          selectedDashboard.id,
          selectedDashboardView
        ),
        selectedDashboard,
        selectedConnection,
        selectedAccounts!
      );
  }

  getChartData(request, selectedDashboard.connectorId)
    .then((res: any) => {
      const data = res.data.map((item: any) => ({
        name: item.name || 'Others',
        cost: Number(item.cost),
      }));
      stateUpdates.setData(data);
      stateUpdates.setSelectedData(data.slice(0, 10));
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function fetchTaggedUntaggedSpendData
 * @description Fetches the tagged and untagged spend data.
 * @param setRequestStatus - The function to set the request status.
 * @param dateFilter - The date filter including start and end date.
 * @param stateUpdates - The state updates including setData and setTableData.
 * @param selectedDashboard - The selected dashboard.
 * @param selectedConnection Object containing the connection details
 * @param tagFilters - The tag filters.
 * @param selectedDashboardView - The selected dashboard view.
 * @param allAvailableTags - All available tags.
 * @param selectedAccounts List of accounts selected for filter
 */
export const fetchTaggedUntaggedSpendData = (
  setRequestStatus: (status: string) => void,
  dateFilter: {
    startMonth: string;
    endMonth: string;
  },
  stateUpdates: {
    setData: (data: any[]) => void;
    setTableData: (data: any[]) => void;
  },
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  tagFilters: TagsFilterType[],
  selectedDashboardView: string,
  allAvailableTags?: TagsType[],
  selectedAccounts?: string[]
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  let requests: any[] = [];
  let dateFormat = YEAR_MONTH_WITHOUT_SEPARATOR;

  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.GCP:
      requests = getGcpTaggedUntaggedSpendQuery(
        dateFilter.startMonth,
        dateFilter.endMonth,
        tagFilters,
        selectedDashboard,
        selectedConnection,
        selectedDashboardView
      ).map((query) => getChartData(query, selectedDashboard.connectorId));
      break;
    case PROVIDER.AWS:
      requests = getAwsTaggedUntaggedSpendQuery(
        dateFilter.startMonth,
        dateFilter.endMonth,
        tagFilters,
        selectedDashboard,
        selectedConnection,
        selectedDashboardView,
        allAvailableTags ?? []
      ).map((query) => getChartData(query, selectedDashboard.connectorId));
      dateFormat = YEAR_HYPHEN_MONTH;
      break;
    case PROVIDER.AZURE:
      requests = getAzureTaggedUntaggedSpendQuery(
        dateFilter.startMonth,
        dateFilter.endMonth,
        tagFilters,
        selectedDashboard,
        selectedConnection,
        selectedDashboardView,
        selectedAccounts!
      ).map((query) => getChartData(query, selectedDashboard.connectorId));
      break;
  }

  Promise.all(requests)
    .then((responses: any[]) => {
      const monthsList = getMonthYearShortList(
        dateFilter.startMonth,
        dateFilter.endMonth
      );
      const taggedUntaggedData: TypeAndNameByCost[] = [];
      const taggedData = responses[0].data;

      monthsList.forEach((month) => {
        const taggedCost =
          taggedData.find(
            (item: any) =>
              moment(item.name, dateFormat).format(MONTH_YEAR_SHORT) === month
          )?.cost ?? 0;
        taggedUntaggedData.push({
          name: month,
          type: i18n.t('tagged'),
          cost: Number(taggedCost),
        });
      });

      if (responses.length === 2) {
        const untaggedData = responses[1].data;
        monthsList.forEach((month) => {
          const untaggedCost =
            untaggedData.find(
              (item: any) =>
                moment(item.name, dateFormat).format(MONTH_YEAR_SHORT) === month
            )?.cost ?? 0;
          taggedUntaggedData.push({
            name: month,
            type: i18n.t('untagged'),
            cost: Number(untaggedCost),
          });
        });
      }

      stateUpdates.setData(taggedUntaggedData);
      stateUpdates.setTableData(
        transformDataByGroupAndType(taggedUntaggedData, 'name', 'cost', 'type')
      );
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function fetchServiceSpendProfileData
 * @description Function to fetch the service spend profile data
 * @param selectedDashboard selector dashboard details
 * @param selectedConnection Object containing the connection details
 * @param dateFilter date filter for the data including startMonth and endMonth
 * @param stateUpdates set states for the data including setData and setRequestStatus
 * @param selectedDashboardView selected dashboard view
 * @param tagFilters tag filters for the data
 * @param selectedAccounts List of accounts selected
 */
export const fetchServiceSpendProfileData = (
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  dateFilter: {
    startMonth: string;
    endMonth: string;
  },
  stateUpdates: {
    setData: (val: SpendProfileDataType[]) => void;
    setRequestStatus: (val: string) => void;
  },
  selectedDashboardView: string,
  tagFilters: TagsFilterType[],
  selectedAccounts?: string[]
) => {
  stateUpdates.setRequestStatus(REQUEST_STATUS.PROCESSING);

  let requestBody;
  switch (selectedDashboard.connectorProvider) {
    case PROVIDER.AWS:
      requestBody = getAWSServiceSpendProfileQuery(
        dateFilter.startMonth,
        dateFilter.endMonth,
        selectedDashboard,
        selectedConnection,
        selectedDashboardView,
        tagFilters
      );
      break;
    case PROVIDER.GCP:
      requestBody = getGCPServiceSpendProfileQuery(
        dateFilter.startMonth,
        dateFilter.endMonth,
        selectedDashboard,
        selectedConnection,
        selectedDashboardView,
        tagFilters
      );
      break;
    case PROVIDER.AZURE:
      requestBody = getAzureServiceSpendProfileQuery(
        dateFilter.startMonth,
        dateFilter.endMonth,
        selectedDashboard,
        selectedConnection,
        selectedDashboardView,
        tagFilters,
        selectedAccounts!
      );
      break;
  }

  getServiceSpendProfile(requestBody, selectedDashboard.connectorId)
    .then((res: any) => {
      stateUpdates.setData(modifyResponseDataForSpendProfile(res.data));
      stateUpdates.setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, stateUpdates.setRequestStatus);
    });
};

/**
 * @function modifyResponseDataForSpendProfile
 * @description Function to modify the response data for spend profile
 * @param data response data
 * @returns modified response data in the required type of SpendProfileDataType[]
 */
const modifyResponseDataForSpendProfile = (data: any[]) => {
  const allSpendData: SpendProfileDataType[] = [];
  Object.values(ProfileSpendTypes).forEach((type) => {
    const filteredSpendTypeResData = data.filter((item) => item.trend === type);
    if (!filteredSpendTypeResData.length) return;
    const filteredSpendTypeResDataSortedByPrice = [
      ...filteredSpendTypeResData,
    ].sort((a, b) => a.currentprice - b.currentprice);
    const filteredSpendTypeResDataSortedByPercentage = [
      ...filteredSpendTypeResData,
    ].sort((a, b) => a.percentage - b.percentage);
    const appsSpend = filteredSpendTypeResDataSortedByPrice.reduce(
      (acc, item) => acc + item.currentprice,
      0
    );

    // club prices of apps which are less than 20% of total spend
    // sort them in ascending order
    // and keep clubbing them until the total spend is less than 20%
    const appsSpendMoreThan20: ProfileSpendDataType[] = [];
    const appsSpendLessThan20Data =
      filteredSpendTypeResDataSortedByPrice.reduce(
        (acc, item) => {
          const newPercentage =
            (item.currentprice / appsSpend) * 100 + acc.percentage;
          if (newPercentage < 20) {
            acc.percentage = newPercentage;
            acc.count++;
            acc.cost += item.currentprice;
          } else {
            appsSpendMoreThan20.push({
              businessUnit: item.serviceName,
              cost: item.currentprice,
            });
          }
          return acc;
        },
        { businessUnit: 'Others', percentage: 0, count: 0, cost: 0 }
      );

    // Pie chart data
    const appSpendData: ProfileSpendDataType[] = [];
    appSpendData.push(...appsSpendMoreThan20);
    appsSpendLessThan20Data.count &&
      appSpendData.push({
        businessUnit: `Others (${appsSpendLessThan20Data.count})`,
        cost: appsSpendLessThan20Data.cost,
      });

    // top 2 apps impacted by spend
    let topImpactedApps;
    switch (type) {
      case ProfileSpendTypes.INCREASING_SPEND:
        topImpactedApps = [
          ...filteredSpendTypeResDataSortedByPercentage,
        ].reverse();
        break;
      case ProfileSpendTypes.DECREASING_SPEND:
        topImpactedApps = filteredSpendTypeResDataSortedByPercentage;
        break;
      case ProfileSpendTypes.INCONSISTENT_SPEND:
      case ProfileSpendTypes.CONSTANT_SPEND:
        // set the one with highest spend and one with lowest spend
        if (filteredSpendTypeResDataSortedByPercentage.length === 1) {
          topImpactedApps = [filteredSpendTypeResDataSortedByPercentage[0]];
        } else
          topImpactedApps = [
            filteredSpendTypeResDataSortedByPercentage[0],
            filteredSpendTypeResDataSortedByPercentage[
              filteredSpendTypeResDataSortedByPercentage.length - 1
            ],
          ];
        break;
    }

    const spendData: SpendProfileDataType = {
      spendType: type,
      appsCount: filteredSpendTypeResDataSortedByPrice.length,
      appsSpend: appsSpend,
      topAppsImpacted: topImpactedApps.slice(0, 2).map((item) => ({
        app: item.serviceName,
        percentage: item.percentage - 100,
      })),
      data: appSpendData,
    };
    allSpendData.push(spendData);
  });

  return allSpendData;
};
