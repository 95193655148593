import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectCostAllocation,
  setConnectionList,
  setCostAllocationData,
  setCurrentStepValidation,
  setSelectedProjects,
} from 'redux/costAllocationSlice';
import { providerList } from 'redux/providerSlice';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import DatePicker from 'components/DatePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getValidationStyle } from 'utils/validations';
import { MONTH_YEAR_FORMAT, YEAR_MONTH_WITHOUT_SEPARATOR } from 'utils/date';
import { fetchCostAllocationByConnectionAndMonth } from 'pages/CostAllocationPage/services';
import { VALIDATION_STATUS } from 'pages/ConnectingCSPPage/constants';
import { fetchConnectionData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

const ConnectionAndMonthForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { costAllocationData, connectionList } =
    useSelector(selectCostAllocation);
  const { selectedProvider } = useSelector(providerList);

  const [connectionId, setConnectionId] = useState<string>(
    costAllocationData.connection
  );
  const [connectionName, setConnectionName] = useState<string>(
    costAllocationData.connection
  );
  const [connectionReqValidation, setConnectionReqValidation] =
    useState<string>('');
  const [connectionListStatus, setConnectionListStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [month, setMonth] = useState<string>(costAllocationData.month);
  const [monthReqValidation, setMonthReqValidation] = useState<string>('');
  const [
    fetchCostAllocationDataReqStatus,
    setFetchCostAllocationDataReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    getConnectionListForSelectedProvider();
  }, []);

  useDidMountEffect(() => {
    dispatch(setSelectedProjects([]));
    if (connectionId && month) {
      fetchSavedOrSubmittedData();
    }
  }, [connectionId, month]);

  useEffect(() => {
    dispatch(setCurrentStepValidation(validateChooseConnectionStep()));
  }, [
    costAllocationData.connection,
    costAllocationData.month,
    fetchCostAllocationDataReqStatus,
  ]);

  /**
   * @function getConnectionListForSelectedProvider
   * @description Function to fetch the list of connections for the selected provider.
   */
  const getConnectionListForSelectedProvider = () => {
    setConnectionListStatus(REQUEST_STATUS.PROCESSING);
    const params = { provider: selectedProvider };

    fetchConnectionData(params)
      .then((res: any) => {
        setConnectionListStatus(REQUEST_STATUS.SUCCESS);
        dispatch(setConnectionList(res?.data?.responseData?.content));
      })
      .catch((e) => onApiCallError(e, false, setConnectionListStatus));
  };

  /**
   * @function fetchSavedOrSubmittedData
   * @description Function to fetch cost allocation data for the selected month and connection
   */
  const fetchSavedOrSubmittedData = () => {
    setFetchCostAllocationDataReqStatus(REQUEST_STATUS.PROCESSING);

    fetchCostAllocationByConnectionAndMonth(connectionId, month)
      .then((res: any) => {
        const data = res?.data?.responseData;
        dispatch(
          setCostAllocationData({
            connection: data?.connectorId,
            connectionName: connectionName,
            month: data?.invoiceMonth,
            projects: data?.selectedProjects,
            businessUnits: data?.businessUnits?.map((businessUnit: any) => {
              let addedCost = 0;
              businessUnit?.costCenterList?.forEach(
                (costCenter: any) => (addedCost += costCenter?.cost)
              );
              return {
                businessUnitCode: businessUnit?.businessUnitName,
                share: businessUnit?.sharePercentage,
                cost: businessUnit?.cost,
                pendingCostCenter: businessUnit?.cost - addedCost,
                costCenters: businessUnit?.costCenterList?.map(
                  (costCenter: any) => ({
                    costCenterCode: costCenter?.costCenterCode,
                    share: costCenter?.sharePercentage,
                    cost: costCenter?.cost,
                  })
                ),
              };
            }),
          })
        );

        dispatch(setSelectedProjects(data?.selectedProjects ?? []));
        setFetchCostAllocationDataReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchCostAllocationDataReqStatus);
        dispatch(
          setCostAllocationData({
            connection: connectionId,
            connectionName: connectionName,
            month: month,
            projects: [],
            businessUnits: [],
          })
        );
      });
    dispatch(setSelectedProjects([]));
  };

  /**
   * @function validateChooseConnectionStep
   * @description Function to fvalidate the connection and month selection step
   * @returns string validation status (VALID, INVALID or SKIP)
   */
  const validateChooseConnectionStep = () => {
    if (fetchCostAllocationDataReqStatus === REQUEST_STATUS.PROCESSING) {
      return VALIDATION_STATUS.INVALID;
    }

    if (costAllocationData.connection && costAllocationData.month) {
      return VALIDATION_STATUS.VALID;
    }

    return VALIDATION_STATUS.INVALID;
  };

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t('costAllocations.connection')} required={true} />
        <SelectDropdown
          placeholder={t('costAllocations.connectionPlaceholder')}
          value={connectionId}
          labelInValue
          options={connectionList
            ?.filter((item) => !item.migrated)
            ?.map((item) => ({
              value: item.connectorId,
              label: item.displayName,
            }))}
          loading={connectionListStatus === REQUEST_STATUS.PROCESSING}
          notFoundContent={
            connectionListStatus === REQUEST_STATUS.ERROR && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('graphErrorMessage')}
              />
            )
          }
          onSelect={(value: any) => {
            setConnectionId(value.value);
            setConnectionName(value.label);
            setConnectionReqValidation('');
          }}
          onBlur={() =>
            connectionId
              ? setConnectionReqValidation('')
              : setConnectionReqValidation(
                  t('costAllocations.connectionRequired')
                )
          }
          designVersion2
        />
        <span
          style={{
            display: getValidationStyle(connectionReqValidation),
          }}
          className="font-validation-error"
        >
          {connectionReqValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('costAllocations.month')} required={true} />
        <DatePicker
          picker="month"
          pickerType={DATE_PICKER_TYPE.DATE_PICKER}
          format={MONTH_YEAR_FORMAT}
          value={moment(month, YEAR_MONTH_WITHOUT_SEPARATOR)}
          disabledDate={(date: any) => moment().isBefore(date)}
          onChange={(value: any) => {
            setMonth(value?.format(YEAR_MONTH_WITHOUT_SEPARATOR) ?? '');
            setMonthReqValidation('');
          }}
          onBlur={() =>
            month
              ? setMonthReqValidation('')
              : setMonthReqValidation(t('costAllocations.monthRequired'))
          }
          size="large"
          designVersion2
        />
        <span
          style={{
            display: getValidationStyle(monthReqValidation),
          }}
          className="font-validation-error"
        >
          {monthReqValidation}
        </span>
      </div>
      {fetchCostAllocationDataReqStatus === REQUEST_STATUS.PROCESSING && (
        <div className="fetch-cost-allocation-data font-button">
          {t('costAllocations.fetchingSavedData')}
        </div>
      )}
    </div>
  );
};

export default ConnectionAndMonthForm;
