import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { selectDashboard } from 'redux/dashboardSlice';
import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import { addIndexAndCommaSeparator } from 'pages/CostOptimizationInsightsPage/utils';
import {
  RECOMMENDATION_STATUS_LABELS,
  SAVINGS_TYPES,
  SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP,
} from 'pages/CostOptimizationInsightsPage/constants';
import { MonthlyCostSavingsType } from 'pages/CostOptimizationInsightsPage/types';
import { CHART_TYPES } from 'constants/graphConfig';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { getChartData } from 'utils/services';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  evaluateRequestArray,
  onApiCallError,
  useNull,
} from 'utils/handleErrors';
import {
  DATE_TIME_AM_PM,
  getMonthYearShortList,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import { getPreviousMonthAndYear } from 'utils/dashboardUtils';
import {
  AWS_CHECK_IDS,
  RecommendationCategoriesLabels,
  RECOMMENDATION_CATEGORIES,
} from 'constants/recommendations';
import { KeyValueTypes } from 'types/dataTypes';
import CategoryCostSavingsTable from './components/CategoryCostSavingsTable';
import {
  fetchCategoryCostSavingsExcelExportColumns,
  getCategoryCostSavingsRequestBody,
  getCostSavingsByCheckIdRequestBody,
  getCostSavingsTrendRequestBody,
  getTotalCostBySavingsType,
} from './utils';
import CostSavingsSummary from '../../CostSavingsSummary';
import SummarizedCostSavings from '../../SummarizedCostSavings';
import CostSavingsTrend from '../../CostSavingsTrend';
import ResourceDetails from '../../ResourceDetails';
import {
  CategoryResourceDataType,
  CostByCategoryAndSavingsType,
} from './types';
import { CHECK_ID_TO_KEY_MAPPING } from './constants';

const AWSStatusSummaryDashboard = () => {
  const { t } = useTranslation();
  const { pdfDownloadMode, expandedGraphName, showExpandGraphModal } =
    useSelector(selectDashboard);
  const { selectedCostOptimizationInsightsConnection } = useSelector(
    costOptimizationInsights
  );

  //Savings Summary States - Waste Management
  const [
    wasteManagementCostSavingSummaryData,
    setWasteManagementCostSavingSummaryData,
  ] = useState<CostByCategoryAndSavingsType[]>([]);
  const [
    wasteManagementSavingsSummaryRequestStatus,
    setWasteManagementSavingsSummaryRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  //Savings Summary States - Consumption Management
  const [
    consumptionManagementCostSavingSummaryData,
    setConsumptionManagementCostSavingSummaryData,
  ] = useState<CostByCategoryAndSavingsType[]>([]);
  const [
    consumptionManagementSavingsSummaryRequestStatus,
    setConsumptionManagementSavingsSummaryRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  //Savings Summary States - Purchase Tactics
  const [
    purchaseTacticsCostSavingSummaryData,
    setPurchaseTacticsCostSavingSummaryData,
  ] = useState<CostByCategoryAndSavingsType[]>([]);
  const [
    purchaseTacticsSavingsSummaryRequestStatus,
    setPurchaseTacticsSavingsSummaryRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  // Summarized Cost Savings states
  const [summarizedSavingsSummaryData, setSummarizedSavingsSummaryData] =
    useState<any[]>([]);

  // Cost Savings Trend states
  const [costSavingsTrendData, setCostSavingsTrendData] = useState<
    MonthlyCostSavingsType[]
  >([]);
  const [costSavingsTrendRequestStatus, setCostSavingsTrendRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  // Category Cost Savings Table states
  const [categoryCostSavingsData, setCategoryCostSavingsData] = useState<
    CategoryResourceDataType[]
  >([]);
  const [
    categoryCostSavingsRequestStatus,
    setCategoryCostSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Resource Details states
  const [selectedRow, setSelectedRow] = useState<CategoryResourceDataType>();
  const [selectedRowKey, setSelectedRowKey] = useState<React.Key>();
  const [resourceDetails, setResourceDetails] = useState<KeyValueTypes[]>([]);

  const [selectedSummaryGraph, setSelectedSummaryGraph] = useState<string>(
    RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
  );
  const [selectedSavingsType, setSelectedSavingsType] = useState<string>();
  const [selectedRecommendationType, setSelectedRecommendationType] =
    useState<string>();

  useEffect(() => {
    setSelectedSummaryGraph(RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT);
    setSelectedSavingsType(undefined);
    setSelectedRecommendationType(undefined);
  }, [selectedCostOptimizationInsightsConnection]);

  useEffect(() => {
    if (selectedCostOptimizationInsightsConnection) {
      getSummaryGraphData();
    }
  }, [selectedCostOptimizationInsightsConnection]);

  useEffect(() => {
    getSummarizedCostSummaryData();
  }, [
    selectedSummaryGraph,
    selectedSavingsType,
    wasteManagementCostSavingSummaryData,
    consumptionManagementCostSavingSummaryData,
    purchaseTacticsCostSavingSummaryData,
  ]);

  useEffect(() => {
    setCostSavingsTrendData([]);
    setCategoryCostSavingsData([]);
    setSelectedRow(undefined);
    if (selectedRecommendationType) {
      getCostSavingsTrendData(
        SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
          (item) => item.type === selectedSavingsType
        )?.status ?? []
      );
      getCategoryCostSavingsData(
        SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
          (item) => item.type === selectedSavingsType
        )?.status ?? []
      );
    }
  }, [selectedRecommendationType]);

  useEffect(() => {
    setSelectedRecommendationType(undefined);
  }, [selectedSummaryGraph, selectedSavingsType]);

  useEffect(() => {
    setResourceDetails([]);
    if (selectedRow) {
      fetchResourceDetails();
    }
  }, [selectedRow]);

  /**
   * @function getSummaryGraphData
   * @description Function to fetch and set the summary graph data.
   */
  const getSummaryGraphData = () => {
    getCostSummaryData(
      RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
      setWasteManagementCostSavingSummaryData,
      setWasteManagementSavingsSummaryRequestStatus
    );
    getCostSummaryData(
      RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
      setConsumptionManagementCostSavingSummaryData,
      setConsumptionManagementSavingsSummaryRequestStatus
    );
    getCostSummaryData(
      RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
      setPurchaseTacticsCostSavingSummaryData,
      setPurchaseTacticsSavingsSummaryRequestStatus
    );
  };

  /**
   * @function getCostSummaryData
   * @description Function to fetch and set the cost summary data by categories.
   * @param category Category for which the cost data is fetched
   * @param setData callback function for setting the data
   * @param setRequestStatus Callback function for setting the request status
   */
  const getCostSummaryData = (
    category: string,
    setData: (val: CostByCategoryAndSavingsType[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    CHECK_ID_TO_KEY_MAPPING.filter(
      (item) => item.category === category
    ).forEach((item) => {
      SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.forEach((type) => {
        requests.push(
          getChartData(
            getCostSavingsByCheckIdRequestBody(
              item.uniqueKey,
              item.checkId,
              type.status,
              type.type
            ),
            selectedCostOptimizationInsightsConnection?.connectorId,
            { checkId: item.checkId.toLowerCase() }
          ).catch(useNull)
        );
      });
    });
    axios
      .all(requests)
      .then((responses) => {
        let costData: CostByCategoryAndSavingsType[] = [];
        let index = 0;
        CHECK_ID_TO_KEY_MAPPING.filter(
          (item) => item.category === category
        ).forEach((item) => {
          SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.forEach((type) => {
            costData.push({
              checkId: item.checkId,
              savingsType: type.type,
              cost: Number(responses[index]?.data[0]?.totalCostSaved ?? 0),
            });

            index++;
          });
        });
        setData(costData);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
        setData([]);
      });
  };

  /**
   * @function getCostSavingsTrendData
   * @description Function to fetch and set the cost trend data for the given status.
   * @param status recommendation status for which the data is fetched
   */
  const getCostSavingsTrendData = (status: string[]) => {
    setCostSavingsTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    getChartData(
      getCostSavingsTrendRequestBody(
        selectedRecommendationType,
        CHECK_ID_TO_KEY_MAPPING.find(
          (item) => item.checkId === selectedRecommendationType
        )?.uniqueKey,
        status
      ),
      selectedCostOptimizationInsightsConnection?.connectorId,
      { checkId: selectedRecommendationType?.toLowerCase() }
    )
      .then((res: any) => {
        let data: MonthlyCostSavingsType[] = res?.data || [];
        const labels = getMonthYearShortList(
          getPreviousMonthAndYear(11),
          getPreviousMonthAndYear(0)
        );
        setCostSavingsTrendData(
          labels.map((month) => ({
            month: month,
            totalCost: Number(
              data.find(
                (item) =>
                  month ===
                  moment(item.month, YEAR_MONTH_WITHOUT_ZERO).format(
                    MONTH_YEAR_SHORT
                  )
              )?.totalCost ?? 0
            ),
          }))
        );
        setCostSavingsTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostSavingsTrendRequestStatus);
        setCostSavingsTrendData([]);
      });
  };

  /**
   * @function getCategoryCostSavingsData
   * @description Function to fetch and set the resource list data for the given status.
   * @param status recommendation status for which the data is fetched
   */
  const getCategoryCostSavingsData = (status: string[]) => {
    setCategoryCostSavingsRequestStatus(REQUEST_STATUS.PROCESSING);

    getChartData(
      getCategoryCostSavingsRequestBody(
        selectedRecommendationType,
        status,
        selectedSavingsType
      ),
      selectedCostOptimizationInsightsConnection?.connectorId,
      { checkId: selectedRecommendationType?.toLowerCase() }
    )
      .then((res: any) => {
        const data: any[] = res?.data || [];
        const modifiedData = data.map((item) => ({
          ...item,
          cost:
            selectedRecommendationType === AWS_CHECK_IDS.IDLE_IP_ADDRESS
              ? 3.6
              : Number(item?.cost) || 0,
          state:
            RECOMMENDATION_STATUS_LABELS.find(
              (state) => state.key === item?.state
            )?.label ?? item?.state,
          lastRefreshedAt: moment(item?.lastRefreshedAt).format(
            DATE_TIME_AM_PM
          ),
        }));
        setCategoryCostSavingsData(modifiedData);
        setSelectedRowKey(0);
        setSelectedRow(modifiedData[0]);
        setCategoryCostSavingsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCategoryCostSavingsRequestStatus);
        setCategoryCostSavingsData([]);
        setSelectedRowKey(undefined);
        setSelectedRow(undefined);
      });
  };

  /**
   * @function fetchResourceDetails
   * @description Function to set the resource details for the resource selected
   */
  const fetchResourceDetails = () => {
    const columns =
      CHECK_ID_TO_KEY_MAPPING.find(
        (item) => item.checkId === selectedRecommendationType
      )?.resourceDetailsQueryColumns ?? [];

    const data: CategoryResourceDataType | undefined =
      categoryCostSavingsData.find(
        (item) => item.resourceId === selectedRow?.resourceId
      );
    const resourceData = columns.map((column) => ({
      key: t(
        `costOptimizationInsight.statusDashboard.costSavingsTable.${column.label}`
      ),
      value: data ? data[column.label as keyof CategoryResourceDataType] : '',
    }));
    setResourceDetails(resourceData);
  };

  /**
   * @function getSummarizedCostSummaryDataset
   * @description Function to set the cost summary dataset for each category.
   */
  const getSummarizedCostSummaryData = () => {
    const savingsCategoryData = getCostSavingsSummaryDataByCategory();

    const potentialSavingsData = savingsCategoryData
      .filter((item) => item.savingsType === SAVINGS_TYPES.POTENTIAL_SAVINGS)
      .map((item) => ({
        label: t(
          'costOptimizationInsight.statusDashboard.costSavingsTable.potentialSavings'
        ),
        category:
          CHECK_ID_TO_KEY_MAPPING.find(
            (checkIdMap) => checkIdMap.checkId === item.checkId
          )?.label ?? '',
        data: item.cost,
      }));

    const costSavingsInProgressData = savingsCategoryData
      .filter(
        (item) => item.savingsType === SAVINGS_TYPES.COST_SAVINGS_IN_PROGRESS
      )
      .map((item) => ({
        label: t(
          'costOptimizationInsight.statusDashboard.costSavingsTable.costSavingInProgress'
        ),
        category:
          CHECK_ID_TO_KEY_MAPPING.find(
            (checkIdMap) => checkIdMap.checkId === item.checkId
          )?.label ?? '',
        data: item.cost,
      }));

    const costSavedData = savingsCategoryData
      .filter((item) => item.savingsType === SAVINGS_TYPES.COST_SAVED)
      .map((item) => ({
        label: t(
          'costOptimizationInsight.statusDashboard.costSavingsTable.costSaved'
        ),
        category:
          CHECK_ID_TO_KEY_MAPPING.find(
            (checkIdMap) => checkIdMap.checkId === item.checkId
          )?.label ?? '',
        data: item.cost,
      }));

    let data: any[] = [];

    if (selectedSavingsType === undefined) {
      data = [
        ...potentialSavingsData,
        ...costSavingsInProgressData,
        ...costSavedData,
      ];
    }

    if (selectedSavingsType === SAVINGS_TYPES.POTENTIAL_SAVINGS) {
      data = potentialSavingsData;
    }

    if (selectedSavingsType === SAVINGS_TYPES.COST_SAVINGS_IN_PROGRESS) {
      data = costSavingsInProgressData;
    }

    if (selectedSavingsType === SAVINGS_TYPES.COST_SAVED) {
      data = costSavedData;
    }

    setSummarizedSavingsSummaryData(data);
  };

  /**
   * @function getCostSavingsSummaryDataByCategory
   * @description Function to get the cost summary data by type of recommendation.
   * @returns category wise cost data
   */
  const getCostSavingsSummaryDataByCategory = () => {
    switch (selectedSummaryGraph) {
      case RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT:
        return wasteManagementCostSavingSummaryData;

      case RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT:
        return consumptionManagementCostSavingSummaryData;

      case RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS:
        return purchaseTacticsCostSavingSummaryData;

      default:
        return [];
    }
  };

  /**
   * @function getPdfMetaData
   * @description Function to return the pdf metadata
   * @returns Object containing the metadata for the pdf export
   */
  const getPdfMetaData = () => {
    return {
      viewName: t('dashNav.costSavingsStatusSummary'),
      fileName: selectedCostOptimizationInsightsConnection?.name ?? '',
      heading: selectedCostOptimizationInsightsConnection?.name ?? '',
      subtitle1:
        selectedCostOptimizationInsightsConnection?.dataSourceType ?? '',
      subtitle2: '',
      provider: selectedCostOptimizationInsightsConnection?.provider ?? '',
    };
  };

  /**
   * @function getGraphComponent
   * @description Function to return the graph component by graph name
   * @param graphName Name of the graph
   * @param pdfView Optional param for stating whether the graph is for pdf view or not
   * @returns React component of the graph
   */
  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT:
        return (
          <CostSavingsSummary
            graphHeading={t('recommendationCategoryLabels.wasteManagement')}
            graphName={RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT}
            pdfView={pdfView}
            savingsSummaryData={getTotalCostBySavingsType(
              wasteManagementCostSavingSummaryData
            )}
            requestStatus={wasteManagementSavingsSummaryRequestStatus}
            selectedSummaryGraph={selectedSummaryGraph}
            setSelectedSummaryGraph={setSelectedSummaryGraph}
            selectedSavingsType={selectedSavingsType}
            setSelectedSavingsType={setSelectedSavingsType}
          />
        );

      case RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT:
        return (
          <CostSavingsSummary
            graphHeading={t(
              'recommendationCategoryLabels.consumptionManagement'
            )}
            graphName={RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT}
            pdfView={pdfView}
            savingsSummaryData={getTotalCostBySavingsType(
              consumptionManagementCostSavingSummaryData
            )}
            requestStatus={consumptionManagementSavingsSummaryRequestStatus}
            selectedSummaryGraph={selectedSummaryGraph}
            setSelectedSummaryGraph={setSelectedSummaryGraph}
            selectedSavingsType={selectedSavingsType}
            setSelectedSavingsType={setSelectedSavingsType}
          />
        );

      case RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS:
        return (
          <CostSavingsSummary
            graphHeading={t('recommendationCategoryLabels.purchaseTactics')}
            graphName={RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS}
            pdfView={pdfView}
            savingsSummaryData={getTotalCostBySavingsType(
              purchaseTacticsCostSavingSummaryData
            )}
            requestStatus={purchaseTacticsSavingsSummaryRequestStatus}
            selectedSummaryGraph={selectedSummaryGraph}
            setSelectedSummaryGraph={setSelectedSummaryGraph}
            selectedSavingsType={selectedSavingsType}
            setSelectedSavingsType={setSelectedSavingsType}
          />
        );

      case 'summarized-cost-savings':
        return getSummarizedCostGraph(pdfView);

      case 'category-cost-savings-table':
        return (
          <CategoryCostSavingsTable
            graphHeading={
              CHECK_ID_TO_KEY_MAPPING.find(
                (item) => item.checkId === selectedRecommendationType
              )?.label ?? ''
            }
            graphName="category-cost-savings-table"
            pdfView={pdfView}
            categoryCostSavingsData={categoryCostSavingsData}
            requestStatus={categoryCostSavingsRequestStatus}
            selectedRecommendationType={selectedRecommendationType}
            selectedSavingsType={selectedSavingsType}
            selectedRowKey={selectedRowKey}
            setSelectedRowKey={setSelectedRowKey}
            setSelectedRow={setSelectedRow}
          />
        );

      case 'resource-details':
        return (
          <ResourceDetails
            graphHeading={
              CHECK_ID_TO_KEY_MAPPING.find(
                (item) => item.checkId === selectedRecommendationType
              )?.resourceLabel ?? ''
            }
            graphName="resource-details"
            resourceDetails={resourceDetails}
            pdfView={pdfView}
            requestStatus={[categoryCostSavingsRequestStatus]}
            selectedRecommendationType={selectedRecommendationType}
            resourceId={selectedRow?.resourceId}
          />
        );
    }
  };

  /**
   * @function getSummarizedCostGraph
   * @description Function to return the summarized category graph component
   * @param pdfView Optional param for stating whether the graph is for pdf view or not
   * @returns React component of the graph
   */
  const getSummarizedCostGraph = (pdfView: boolean) => {
    if (selectedRecommendationType === undefined) {
      return (
        <SummarizedCostSavings
          graphHeading={
            (SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
              (item) => item.type === selectedSavingsType
            )?.label ?? t('graphHeadings.costSavings')) +
            ' - ' +
            RecommendationCategoriesLabels.find(
              (item) => item.key === selectedSummaryGraph
            )?.label
          }
          graphName="summarized-cost-savings"
          data={summarizedSavingsSummaryData}
          pdfView={pdfView}
          requestStatus={[
            wasteManagementSavingsSummaryRequestStatus,
            consumptionManagementSavingsSummaryRequestStatus,
            purchaseTacticsSavingsSummaryRequestStatus,
          ]}
          selectedSavingsType={selectedSavingsType}
          setSelectedSavingsType={setSelectedSavingsType}
          selectedRecommendationType={selectedRecommendationType}
          setSelectedRecommendationType={(recom) =>
            setSelectedRecommendationType(
              CHECK_ID_TO_KEY_MAPPING.find((item) => item.label === recom)
                ?.checkId
            )
          }
        />
      );
    }

    return (
      <CostSavingsTrend
        graphName="summarized-cost-savings"
        pdfView={pdfView}
        requestStatus={[costSavingsTrendRequestStatus]}
        data={costSavingsTrendData}
        selectedSavingsType={selectedSavingsType}
        selectedRecommendationType={
          CHECK_ID_TO_KEY_MAPPING.find(
            (item) => item.checkId === selectedRecommendationType
          )?.label ?? ''
        }
        setSelectedRecommendationType={setSelectedRecommendationType}
      />
    );
  };

  return (
    <div className="status-summary-container flex flex-column flex-gap-16">
      <div className="full-width flex flex-gap-24">
        {getGraphComponent(RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT)}
        {getGraphComponent(RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT)}
        {getGraphComponent(RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS)}
      </div>
      {getGraphComponent('summarized-cost-savings')}
      <div className="flex flex-gap-16 flex-space-between">
        <div className="width-60">
          {getGraphComponent('category-cost-savings-table')}
        </div>
        <div className="width-40">{getGraphComponent('resource-details')}</div>
      </div>
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData()}
          pdfContent={[
            {
              element: getGraphComponent(
                RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
              isTableView: false,
            },
            {
              element: getGraphComponent(
                RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
              isTableView: false,
            },
            {
              element: getGraphComponent(
                RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
              isTableView: false,
            },
            {
              element: getGraphComponent('summarized-cost-savings', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'summarized-cost-savings',
              isTableView: false,
            },
            {
              contentType: CHART_TYPES.TABLE,
              graphName: 'category-cost-savings-table',
              column: fetchCategoryCostSavingsExcelExportColumns(
                selectedSavingsType,
                selectedRecommendationType
              ),
              element: getGraphComponent('category-cost-savings-table', true),
              body: addIndexAndCommaSeparator(categoryCostSavingsData),
              tableName: CHECK_ID_TO_KEY_MAPPING.find(
                (item) => item.checkId === selectedRecommendationType
              )?.label,
            },
          ]}
          pdfCardData={{
            costOptmization: {
              cards:
                selectedRow === undefined ||
                evaluateRequestArray([categoryCostSavingsRequestStatus]) ===
                  REQUEST_STATUS.ERROR
                  ? undefined
                  : getGraphComponent('resource-details', true),
              insertAtStart: false,
            },
          }}
        />
      )}
    </div>
  );
};

export default AWSStatusSummaryDashboard;
