import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  cmdbSetup,
  setCmdbConfigurationStep,
  setCmdbSetupConnection,
  setIsEditConnection,
} from 'redux/cmdbSetupSlice';
import { setNavSubPath } from 'redux/activeNavMenuSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import ProgressBar from 'components/ProgressBar';
import NavigationPath from 'components/NavigationPath';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import DashboardComponent from 'components/DashboardComponent';

import {
  CMDB_PROGRESS_BAR_CONTENT,
  CmdbConfigurationSteps,
  CMDB_DEFAULT_DATA,
} from './constants';
import CmdbConfigurationFooter from './components/CmdbConfigurationFooter';
import CmdbConfigurationHeader from './components/CmdbConfigurationHeader';
import CmdbBasicDetails from './components/CmdbBasicDetails';
import { fetchAndSetCmdbSetupConnectionDetails } from './utils';
import CmdbAccessDetails from './components/CmdbAccessDetails';
import CmdbFieldTagsMapping from './components/CmdbFieldTagsMapping';

import './index.scss';

const CmdbConfigurationPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const { cmdbConfigurationStep } = useSelector(cmdbSetup);

  const [cmdbId, setCmdbId] = useState<string | null>(null);
  const [cmdbDataReqStatus, setCmdbDataReqStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [fetchCmdbDataByIdError, setFetchCmdbDataByIdError] =
    useState<string>();
  const [isDuplicateName, setIsDuplicateName] = useState(true);

  useEffect(() => {
    addZeroMarginClass();
    dispatch(setNavSubPath([t('configure')]));
    setCmdbId(getCmdbNameFromQueryParams());

    return () => {
      removeZeroMarginClass();
      dispatch(
        setCmdbConfigurationStep(CmdbConfigurationSteps.BASIC_INFORMATION)
      );
      dispatch(setCmdbSetupConnection(null));
    };
  }, []);

  useEffect(() => {
    if (cmdbId) {
      fetchAndSetCmdbSetupConnectionDetails(cmdbId, {
        setCmdbConnection: (data) => {
          dispatch(setCmdbSetupConnection(data));
          setIsDuplicateName(false);
          dispatch(setIsEditConnection(true));
        },
        setReqStatus: setCmdbDataReqStatus,
        setError: setFetchCmdbDataByIdError,
      });
    } else {
      dispatch(setIsEditConnection(false));
      dispatch(setCmdbSetupConnection(CMDB_DEFAULT_DATA));
      setCmdbDataReqStatus(REQUEST_STATUS.SUCCESS);
    }
  }, [cmdbId]);

  const getCmdbNameFromQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('id');
  };

  /**
   * @function providerForms
   * @description Function to set the form for budget and dashboard
   *
   */
  const providerForms = () => {
    switch (cmdbConfigurationStep) {
      case CmdbConfigurationSteps.BASIC_INFORMATION:
        return <CmdbBasicDetails setIsDuplicateName={setIsDuplicateName} />;

      case CmdbConfigurationSteps.ACCESS_DETAILS:
        return <CmdbAccessDetails />;

      case CmdbConfigurationSteps.FIELD_MAPPING:
        return <CmdbFieldTagsMapping />;
    }
  };

  const CMDB_CONFIGURATION_FORM = (
    <div className="cmdb-configuration-form flex">
      <ProgressBar
        items={CMDB_PROGRESS_BAR_CONTENT}
        current={cmdbConfigurationStep}
        additionalClassNames="progress-bar new-styled-scroll width-40"
        heading={t('configureCmdbSetup')}
        subHeading={t('configureCmdbSetupSubHeading', {
          stepCount: CMDB_PROGRESS_BAR_CONTENT.length,
        })}
      />
      <div className="form-container flex flex-column flex-space-between flex-fit">
        <div className="form-content new-styled-scroll">
          <div className="flex flex-column flex-gap-24">
            <CmdbConfigurationHeader />
            {providerForms()}
          </div>
        </div>
        <CmdbConfigurationFooter isDuplicateName={isDuplicateName} />
      </div>
    </div>
  );
  return (
    <div className="cmdb-configuration-page">
      <header className="new-page-header modal-heading flex flex-align-items-center">
        {t('navigationMenu.configureCmdbSetup')}
      </header>
      <div className="page-content cmdb-configuration-form">
        <NavigationPath />
        <DashboardComponent
          component={CMDB_CONFIGURATION_FORM}
          errorMessage={t('errorWhileFetchingTheCmdbDetails', {
            error: fetchCmdbDataByIdError,
          })}
          requestStatus={cmdbDataReqStatus}
        />
      </div>
    </div>
  );
};

export default CmdbConfigurationPage;
