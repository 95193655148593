import { FilterKeysLabels } from './constants';

/**
 * @function getFilterLabelByKey
 * @description Function to get the filter label by key
 * @param filterKey The filter key for which the label is returned
 * @returns String Label. Defaults to key
 */
export const getFilterLabelByKey = (filterKey: string): string => {
  return (
    FilterKeysLabels.find((item) => item.key === filterKey)?.label ?? filterKey
  );
};
