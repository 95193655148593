import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { selectDashboard } from 'redux/dashboardSlice';
import SelectDropdown from 'components/Select';
import Checkbox from 'components/Checkbox';
import { INPUT_SIZE } from 'constants/appearance';

type ConnectionsFilterProps = {
  selectedConnections: string[];
  onSelectedDropdown: (val: string[]) => void;
};

const ConnectionsFilter = ({
  selectedConnections,
  onSelectedDropdown,
}: ConnectionsFilterProps) => {
  const { selectedGroupMetaData } = useSelector(selectDashboard);

  const ProviderOptions = (
    <>
      {(selectedGroupMetaData?.connectorDtos ?? [])
        .filter((connection) => connection.wantBilling)
        .map((connection) => (
          <Select.Option
            key={connection.connectorId}
            value={connection.connectorId}
          >
            <div className="flex flex-gap-4">
              <Checkbox
                className="dropdown-checkbox"
                checked={selectedConnections.includes(connection.connectorId)}
              />
              {connection.name}
            </div>
          </Select.Option>
        ))}
    </>
  );

  return (
    <SelectDropdown
      mode="multiple"
      className="connection-filter"
      value={selectedConnections}
      onChange={onSelectedDropdown}
      menu={ProviderOptions}
      maxTagCount="responsive"
      showArrow
      dropdownClassName="connection-filter-dropdown"
      designVersion2
      size={INPUT_SIZE.SMALL}
    />
  );
};

export default ConnectionsFilter;
