import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectDashboard,
  setPdfGraphToDownload,
  setPdfDownloadMode,
  setTableViewEnabled,
} from 'redux/dashboardSlice';
import {
  selectScorecard,
  setScorecardConnectionList,
  setSelectedConnection,
} from 'redux/scorecardSlice';
import { selectTheme } from 'redux/themeSlice';
import Switch from 'components/Switch';
import Button from 'components/Button';
import ConnectionsListDrawer from 'components/ConnectionsListDrawer';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { PDF_ALL_GRAPHS } from 'constants/pdfConstants';
import { LoadingIcon } from 'assets/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { exportToExcel } from 'utils/exportToExcel';
import { searchConnectionData } from 'utils/services';
import { ConnectionListType } from 'types/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import { PROVIDER } from 'constants/cloudProviders';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import ScorecardDashboardTitle from '../ScorecardDashboardTitle';

import './index.scss';

type ScorecardDashboardHeaderProps = {
  setIsEmptyDashboard: (val: boolean) => void;
  setRequestStatus: (val: string) => void;
};

const ScorecardDashboardHeader = ({
  setIsEmptyDashboard,
  setRequestStatus,
}: ScorecardDashboardHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { exportToExcelData, tableViewEnabled, pdfDownloadMode } =
    useSelector(selectDashboard);
  const { selectedConnection, scorecardConnectionList } =
    useSelector(selectScorecard);
  const { theme } = useSelector(selectTheme);

  const [showSwitchConnectionDrawer, setShowSwitchConnectionDrawer] =
    useState(false);
  const [isDownloadingData, setIsDownloadingData] = useState(false);
  const [fetchConnectionsRequestStatus, setFetchConnectionsRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  useEffect(() => {
    fetchConnections();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  useEffect(() => {
    if (scorecardConnectionList.length === 0) {
      return;
    }

    if (
      !selectedConnection ||
      !scorecardConnectionList.some(
        (availableDashboard) =>
          availableDashboard.connectorId === selectedConnection.connectorId
      )
    ) {
      dispatch(setSelectedConnection(scorecardConnectionList[0]));
    }
  }, [scorecardConnectionList]);

  /**
   * @function fetchConnections
   * @description Function to fetch the connection list
   */
  const fetchConnections = (addLoader: boolean = true) => {
    addLoader && setFetchConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: [PROVIDER.AWS, PROVIDER.GCP].join(','),
    };

    searchConnectionData(params)
      .then((res: any) => {
        let connections: ConnectionListType[] =
          res?.data?.responseData?.content || [];

        dispatch(setScorecardConnectionList(connections));
        setIsEmptyDashboard(connections.length === 0);
        if (addLoader) {
          setFetchConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
          setRequestStatus(REQUEST_STATUS.SUCCESS);
        }
      })
      .catch((e) => {
        onApiCallError(
          e,
          false,
          addLoader ? setFetchConnectionsRequestStatus : undefined
        );
        dispatch(setScorecardConnectionList([]));
        addLoader && setRequestStatus(REQUEST_STATUS.ERROR);
      });
  };

  /**
   * @function onHandleDownloadDashboardData
   * @description function to handle common download of dashboard view.
   * @returns either pdf or excel
   */
  const onHandleDownloadDashboardData = () => {
    setIsDownloadingData(true);
    if (!tableViewEnabled) {
      dispatch(setPdfGraphToDownload(PDF_ALL_GRAPHS));
      dispatch(setPdfDownloadMode(true));
      return;
    }
    setIsDownloadingData(false);
    return exportToExcel(selectedConnection?.name ?? '', exportToExcelData);
  };

  return (
    <div className="scorecard-dashboard-header new-page-header flex flex-align-items-center flex-space-between flex-wrap">
      {selectedConnection ? (
        <ScorecardDashboardTitle />
      ) : (
        <Icon icon={LoadingIcon} className="rotate" />
      )}
      <div className="header-right flex flex-align-items-center flex-gap-16">
        {isDownloadingData ? (
          <Icon icon={LoadingIcon} className="rotate" />
        ) : (
          <Icon
            iconName={ICONS.DOWNLOAD_2_LINE}
            onClick={() => onHandleDownloadDashboardData()}
            size={ICONS_SIZE.ONE_X}
            color={theme.buttonIconColor}
          />
        )}
        <div className="table-view-title table-typography flex flex-align-items-center flex-gap-8">
          {t('tableView')}
          <Switch
            size="small"
            checked={tableViewEnabled}
            onChange={(value: boolean) => {
              dispatch(setTableViewEnabled(value));
            }}
          />
        </div>
        <Button
          title={t('dashboardLabels.switchConnection')}
          iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
          iconSize={ICONS_SIZE.ONE_X}
          onClick={() => setShowSwitchConnectionDrawer(true)}
        />
      </div>
      <ConnectionsListDrawer
        isCB360TabSelected={true}
        show={showSwitchConnectionDrawer}
        setShow={setShowSwitchConnectionDrawer}
        selectedConnection={selectedConnection}
        connectionList={scorecardConnectionList}
        onClick={(key: any) => {
          dispatch(
            setSelectedConnection(
              scorecardConnectionList.find(
                (connection) => connection.connectorId === key
              ) ?? null
            )
          );
          setShowSwitchConnectionDrawer(false);
        }}
        loading={fetchConnectionsRequestStatus === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );
};

export default ScorecardDashboardHeader;
