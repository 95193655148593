import { useEffect, useState } from 'react';
import { Radio, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  tagsAndLabels,
  setTagsAndLabelComponent,
  setSelectedTagsObjective,
  setSelectedTagConnection,
  setSelectedBillingAccount,
} from 'redux/tagsAndLabelsSlice';
import { providerList } from 'redux/providerSlice';
import {
  TAGS_OBJECTIVES,
  TAGS_AND_LABELS_COMPONENTS,
} from 'pages/TagsAndLabelsPage/constants';
import Button from 'components/Button';
import SelectDropdown from 'components/Select';
import { PROVIDER } from 'constants/cloudProviders';
import { LeftBackArrowIcon } from 'assets/icons';
import { FormLabel } from 'components/FormLabel';
import CheckRadio from 'components/CheckRadio';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import { ConnectionListType } from 'types/dashboard';
import { REQUEST_STATUS } from 'constants/requestBody';
import { fetchAllBillingAccounts, fetchConnectionData } from 'utils/services';
import { BillingAccountOptionTypes } from 'pages/BudgetsAndAlertsPage/components/BudgetsAndAlertsForm/types';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import './index.scss';

type SelectTagLabelConnectionProps = {
  downloadSchema: () => void;
  downloadSchemaStatus: string;
};

const SelectTagLabelConnection = ({
  downloadSchema,
  downloadSchemaStatus,
}: SelectTagLabelConnectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedTagsObjective,
    selectedTagConnection,
    selectedBillingAccount,
  } = useSelector(tagsAndLabels);
  const { selectedProvider } = useSelector(providerList);

  //States for connection
  const [connectionOptions, setConnectionOptions] = useState<
    ConnectionListType[]
  >([]);
  const [connectionOptionsRequestStatus, setConnectionOptionsRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [selectedConnectionReqValidation, setSelectedConnectionReqValidation] =
    useState('');

  //States for billing account
  const [billingAccountOptions, setBillingAccountOptions] = useState<
    BillingAccountOptionTypes[]
  >([]);
  const [
    billingAccountOptionsRequestStatus,
    setBillingAccountOptionsRequestStatus,
  ] = useState('');
  const [billingAccountReqValidation, setBillingAccountReqValidation] =
    useState('');
  const [continueCtaLabel, setContinueCtaLabel] = useState(t('continueButton'));

  useEffect(() => {
    setConnectionOptionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: selectedProvider,
    };

    fetchConnectionData(params)
      .then((res: any) => {
        setConnectionOptions(res?.data?.responseData?.content);
        setConnectionOptionsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, true, setConnectionOptionsRequestStatus);
      });

    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    if (selectedTagConnection && selectedProvider === PROVIDER.GCP) {
      setBillingAccountOptions([]);
      dispatch(setSelectedBillingAccount(''));
      onfetchBillingAccountOptions();
      setSelectedConnectionReqValidation('');
    }
  }, [selectedTagConnection]);

  /**
   * @function onfetchBillingAccountOptions
   * @description Function to fetch billing account options data and set the billing account dropdown
   */
  const onfetchBillingAccountOptions = async () => {
    setBillingAccountOptionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedTagConnection?.connectorId,
    };
    try {
      const resData: any = await fetchAllBillingAccounts(params);
      if (resData?.status === 200) {
        const { data } = resData;
        const { responseData } = data;
        const selectOptions: BillingAccountOptionTypes[] = [];
        for (const billingData in responseData) {
          selectOptions.push({
            value: billingData,
            title: responseData[billingData],
          });
        }
        setBillingAccountOptions(selectOptions);
        setBillingAccountOptionsRequestStatus(REQUEST_STATUS.SUCCESS);
      }
    } catch (e) {
      onApiCallError(e, true, setBillingAccountOptionsRequestStatus);
    }
  };

  /**
   * @function onHandleBackButton
   * @description Function to handle back button clicked
   */
  const onHandleBackButton = () => {
    dispatch(
      setTagsAndLabelComponent(TAGS_AND_LABELS_COMPONENTS.SELECT_PROVIDER)
    );
  };

  const ConnectionDropdown = (
    <>
      {connectionOptions?.map((options) => (
        <Select.Option key={options.connectorId} value={options.connectorId}>
          {options.displayName}
        </Select.Option>
      ))}
    </>
  );

  const BillingAccountDropdown = (
    <>
      {billingAccountOptions?.map((options) => (
        <Select.Option key={options.title} value={options.value}>
          {options.title}
        </Select.Option>
      ))}
    </>
  );

  /**
   * @function handleContinue
   * @description Function to handle continue or download button click callback
   */
  const handleContinue = () => {
    switch (selectedTagsObjective) {
      case TAGS_OBJECTIVES.CREATE_TAG_SCHEMA:
        return dispatch(
          setTagsAndLabelComponent(TAGS_AND_LABELS_COMPONENTS.EDIT_TAGS_LABELS)
        );
      case TAGS_OBJECTIVES.APPLY_TAGS:
        return dispatch(
          setTagsAndLabelComponent(TAGS_AND_LABELS_COMPONENTS.APPLY_TAGS_LABELS)
        );
      case TAGS_OBJECTIVES.DOWNLOAD_SCHEMA:
        downloadSchema();
        return;
    }
  };

  return (
    <div className="select-tag-connection-container flex flex-column full-height">
      <div className="select-tag-connection-header new-page-header flex flex-center flex-start">
        <LeftBackArrowIcon
          className="back-arrow cursor-pointer"
          onClick={onHandleBackButton}
        />
      </div>
      <div className="page-content flex flex-column flex-center full-height flex-gap-24">
        <div className="flex flex-column flex-align-items-center">
          <div className="modal-heading">
            {t('tagsOrLabels.selectConnection')}
          </div>
          <div className="font-subHeader">
            {t('tagsOrLabels.addTheCredentialCloudConnection')}
          </div>
        </div>
        <div className="card flex flex-column flex-gap-24">
          <div className="flex flex-column">
            <FormLabel
              title={t('tagsOrLabels.selectConnection')}
              required={true}
            />
            <SelectDropdown
              value={selectedTagConnection?.connectorId}
              menu={ConnectionDropdown}
              loading={
                connectionOptionsRequestStatus === REQUEST_STATUS.PROCESSING
              }
              onSelect={(value: any) => {
                dispatch(
                  setSelectedTagConnection(
                    connectionOptions.filter(
                      (connection) => connection.connectorId === value
                    )[0]
                  )
                );
                setSelectedConnectionReqValidation('');
              }}
            />
            <span
              style={{
                display: `${
                  selectedConnectionReqValidation ? 'inline' : 'none'
                }`,
              }}
              className="font-validation-error"
            >
              {selectedConnectionReqValidation}
            </span>
          </div>
          {selectedProvider === PROVIDER.GCP && (
            <div className="flex flex-column">
              <FormLabel
                title={t('tagsOrLabels.billingAccount')}
                required={true}
              />
              <SelectDropdown
                value={selectedBillingAccount}
                disabled={!billingAccountOptionsRequestStatus}
                menu={BillingAccountDropdown}
                loading={
                  billingAccountOptionsRequestStatus ===
                  REQUEST_STATUS.PROCESSING
                }
                onSelect={(value: any) => {
                  dispatch(setSelectedBillingAccount(value));
                  setBillingAccountReqValidation('');
                }}
              />
              <span
                style={{
                  display: `${billingAccountReqValidation ? 'inline' : 'none'}`,
                }}
                className="font-validation-error"
              >
                {billingAccountReqValidation}
              </span>
            </div>
          )}
          <div className="flex flex-column">
            <FormLabel title={t('tagsOrLabels.objective')} required={true} />
            <Radio.Group
              onChange={(event) => {
                dispatch(setSelectedTagsObjective(event.target.value));
                event.target.value === TAGS_OBJECTIVES.DOWNLOAD_SCHEMA
                  ? setContinueCtaLabel(t('tagsOrLabels.download'))
                  : setContinueCtaLabel(t('continueButton'));
              }}
              value={selectedTagsObjective}
            >
              <Space direction="vertical">
                <CheckRadio
                  value={TAGS_OBJECTIVES.CREATE_TAG_SCHEMA}
                  className="font-caption"
                >
                  {t('tagsOrLabels.createTagSchema')}
                </CheckRadio>
                <CheckRadio
                  value={TAGS_OBJECTIVES.APPLY_TAGS}
                  className="font-caption"
                >
                  {t('tagsOrLabels.applyTags')}
                </CheckRadio>
                <CheckRadio
                  value={TAGS_OBJECTIVES.DOWNLOAD_SCHEMA}
                  className="font-caption"
                >
                  {t('tagsOrLabels.initializeTagValueMapping')}
                </CheckRadio>
              </Space>
            </Radio.Group>
          </div>
        </div>
        <div className="submit-section">
          <Button
            title={continueCtaLabel}
            disabled={
              (selectedProvider === PROVIDER.GCP && !selectedBillingAccount) ||
              !selectedTagConnection
            }
            className="full-width"
            onClick={handleContinue}
            loading={downloadSchemaStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
        {evaluateRequestArray([
          connectionOptionsRequestStatus,
          billingAccountOptionsRequestStatus,
        ]) === REQUEST_STATUS.ERROR && (
          <div className="error-message flex flex-justify-content-center font-caption">
            {connectionOptionsRequestStatus === REQUEST_STATUS.ERROR &&
              t('tagsOrLabels.errorFetchingConnections')}
            {billingAccountOptionsRequestStatus === REQUEST_STATUS.ERROR &&
              t('tagsOrLabels.errorFetchingBillingAccount')}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectTagLabelConnection;
