import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { OrderByType } from 'types/dashboard';
import { ORDER_BY } from 'constants/requestBody';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { COLORS } from 'constants/graphConfig';
import AutoComplete from 'components/AutoComplete';
import Icon from 'components/Icon';
import { INPUT_SIZE, KEYCODE } from 'constants/appearance';
import { customDashboard } from 'redux/customDashboardSlice';
import {
  getTableColumns,
  getTableSorts,
  updateSortQuery,
} from 'pages/CustomDashboardPage/utils';

type SortListItemProps = {
  sort: OrderByType;
  index: number;
};

const SortListItem = ({ sort, index }: SortListItemProps) => {
  const { t } = useTranslation();
  const { selectedMetrics, selectedDimensions } = useSelector(customDashboard);
  const [showSortsDropdown, setShowSortsDropdown] = useState(false);

  /**
   * @function removeSortField
   * @description Removes the sort field from the sort query
   */
  const removeSortField = () => {
    let newSorts = [...getTableSorts()].filter(
      (_, sortIndex) => sortIndex !== index
    );
    updateSortQuery(newSorts);
  };

  /**
   * @function onKeyDown
   * @description Handles the keydown event on the sort input
   * @param e Keydown event
   */
  const onKeyDown = (e: any) => {
    let newSorts = [...getTableSorts()];
    if (newSorts.length === 0) return;
    if (e.keyCode === KEYCODE.BACKSPACE) {
      if (sort.label === '') {
        newSorts = getTableSorts().filter(
          (_, sortIndex) => sortIndex !== index
        );
      } else if (sort.sort) {
        newSorts = newSorts.map((sort, sortIndex) => {
          if (sortIndex === index) {
            return { ...sort, sort: '' };
          }
          return sort;
        });
        setShowSortsDropdown(true);
      } else {
        newSorts = newSorts.map((sort, sortIndex) => {
          if (sortIndex === index) {
            return { label: '', sort: '' };
          }
          return sort;
        });
        setShowSortsDropdown(true);
      }
    }
    updateSortQuery(newSorts);
  };

  /**
   * @function onSortFieldAdd
   * @description Handles when a new sort field (metric or dimension) is added
   * @param value New sort field
   */
  const onSortFieldAdd = (value: string) => {
    let newSorts = [...getTableSorts()];
    newSorts = newSorts.map((sort, sortIndex) => {
      if (sortIndex === index) {
        return {
          label: value,
          sort: '',
        };
      }
      return sort;
    });
    updateSortQuery(newSorts);
    setShowSortsDropdown(true);
  };

  /**
   * @function onSortOrderChange
   * @description Handles when a new sort order to an already existing sort field is added
   * @param order New sort order
   */
  const onSortOrderChange = (order: string) => {
    let newSorts = [...getTableSorts()];
    newSorts = newSorts.map((sort, sortIndex) => {
      if (sortIndex === index) {
        return {
          ...sort,
          sort: order,
        };
      }
      return sort;
    });
    setShowSortsDropdown(false);
    updateSortQuery(newSorts);
  };

  /**
   * @function getOptionsForSort
   * @description Returns the options for the sort field autocomplete popup
   * @returns Options for the sort field autocomplete popup
   */
  const getOptionsForSort = () => {
    if (!sort.label) {
      const options = [];
      const dimensionsToAdd = selectedDimensions.filter(
        (dimension) =>
          getTableSorts().findIndex(
            (sort) => sort.label === dimension.field
          ) === -1
      );
      const metricsToAdd = selectedMetrics.filter(
        (metric) =>
          getTableSorts().findIndex((sort) => sort.label === metric.field) ===
          -1
      );
      if (dimensionsToAdd.length > 0) {
        options.push({
          label: (
            <span className="font-small-bold">
              {t('customDashboard.optionsLabels.dimension')}
            </span>
          ),
          options: dimensionsToAdd.map((dimension) => ({
            label: <span className="font-caption">{dimension.label}</span>,
            value: dimension.field,
          })),
        });
      }
      if (metricsToAdd.length > 0) {
        options.push({
          label: (
            <span className="font-small-bold">
              {t('customDashboard.optionsLabels.metrics')}
            </span>
          ),
          options: metricsToAdd.map((metric) => ({
            label: <span className="font-caption">{metric.label}</span>,
            value: metric.field,
          })),
        });
      }
      return options;
    }
    return [
      {
        label: (
          <span className="font-small-bold">
            {t('customDashboard.optionsLabels.order')}
          </span>
        ),
        options: Object.values(ORDER_BY).map((order) => ({
          label: <span className="font-caption">{order}</span>,
          value: order,
        })),
      },
    ];
  };

  return (
    <div className="flex flex-gap-16 flex-align-items-center">
      <AutoComplete
        additionalClassNames="full-width"
        ref={(ref) => !sort.label && !sort.sort && ref?.focus()}
        placeholder={t('customDashboard.optionsLabels.writeSort')}
        value={`${
          sort.label
            ? getTableColumns()?.find((column) => column.field === sort.label)
                ?.label +
              ' as ' +
              sort.sort
            : ''
        }`}
        onKeyDown={onKeyDown}
        open={showSortsDropdown}
        onFocus={() => {
          setShowSortsDropdown(true);
        }}
        autoFocus
        onBlur={() => {
          setShowSortsDropdown(false);
        }}
        options={getOptionsForSort()}
        inputSize={INPUT_SIZE.SMALL}
        onSelect={!sort.label ? onSortFieldAdd : onSortOrderChange}
      />
      <Icon
        iconName={ICONS.SUBTRACT_LINE}
        size={ICONS_SIZE.SM}
        color={COLORS.colorRegentGrey}
        onClick={removeSortField}
      />
    </div>
  );
};

export default SortListItem;
