import { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectPurchase, setAwsRdsRisCartData } from 'redux/purchaseSlice';
import QuickActionMenu from 'components/QuickActionMenu';
import {
  CartListQuickActions,
  CART_LIST_QUICKACTIONS,
} from 'pages/PurchasePage/constants';
import { RI_PAYMENT_OPTIONS_LIST } from 'pages/PurchasePage/components/ReservedInstances/constants';
import { PurchaseAwsRdsRisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import { YES_NO_OPTIONS } from 'constants/utilityConstants';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  getAverageMonthlyRecurringCost,
  getSubTotalDue,
} from 'pages/PurchasePage/components/ReservedInstances/utils';

import BuyRdsRiFormModal from '../BuyRdsRiFormModal';

import './index.scss';

type PurchasedRdsRICardProps = {
  rdsRiData: PurchaseAwsRdsRisType;
  index: number;
};

const PurchasedRdsRICard = ({ rdsRiData, index }: PurchasedRdsRICardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { awsRdsRiCartData } = useSelector(selectPurchase);

  const [showRdsRiForm, setShowRdsRiForm] = useState(false);

  /**
   * @function handleQuickActions
   * @description Function to handle the quick actions of the cart items
   * @param key quick action key
   */
  const handleQuickActions = (key: string) => {
    if (key === CartListQuickActions.EDIT) {
      setShowRdsRiForm(true);
      return;
    }

    if (key === CartListQuickActions.DELETE) {
      const cartData = [...awsRdsRiCartData];
      cartData.splice(index, 1);
      dispatch(setAwsRdsRisCartData(cartData));
    }
  };

  return (
    <>
      <Row className="purchased-rds-ri-card flex flex-align-items-center">
        <Col span={3} className="flex flex-column flex-gap-8">
          <div className="font-button">{rdsRiData.productDescription}</div>
          <div className="payment-type font-small-bold">
            {
              RI_PAYMENT_OPTIONS_LIST.find(
                (item) => item.value === rdsRiData.paymentOption
              )?.label
            }
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.region')}
          </div>
          <div className="font-caption-bold">{rdsRiData.region}</div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.dbInstanceClass')}
          </div>
          <div className="font-caption-bold">{rdsRiData.dbInstanceClass}</div>
        </Col>
        <Col span={4} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.isMultiAzDbInstance')}
          </div>
          <div className="font-caption-bold">
            {
              YES_NO_OPTIONS.find(
                (item) => item.value === rdsRiData.isMultiAzDbInstance
              )?.label
            }
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.term')}
          </div>
          <div className="font-caption-bold">
            {t('purchasePage.cart.countMonths', { count: rdsRiData.term * 12 })}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.quantity')}
          </div>
          <div className="font-caption-bold">{rdsRiData.quantity}</div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t(
              'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
            )}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(
              getSubTotalDue(rdsRiData.term, rdsRiData.hourlyUsageCharge)
            )}
          </div>
        </Col>
        <Col span={3} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.reservedInstancesLabels.oneTimePayment')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(rdsRiData.oneTimePayment)}
          </div>
        </Col>
        <Col span={2} className="flex flex-column flex-gap-4">
          <div className="data-label font-caption-bold">
            {t('purchasePage.cart.subTotalDue')}
          </div>
          <div className="font-caption-bold">
            {currencySymbol}
            {numberCommaSeparator(
              getAverageMonthlyRecurringCost(
                rdsRiData.term,
                rdsRiData.hourlyUsageCharge
              )
            )}
          </div>
        </Col>
        <Col span={1} className="text-align-center">
          <QuickActionMenu
            quickActions={CART_LIST_QUICKACTIONS}
            quickActionHandler={handleQuickActions}
          />
        </Col>
      </Row>
      {showRdsRiForm && (
        <BuyRdsRiFormModal
          show={showRdsRiForm}
          setShow={setShowRdsRiForm}
          editData={rdsRiData}
          indexPosition={index}
        />
      )}
    </>
  );
};

export default PurchasedRdsRICard;
