import { Menu } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import { SuccessIcon } from 'assets/icons';
import InviteUsers from '../InviteUsers';
import SharedWith from '../SharedWith';
import {
  SHARE_DASHBAORD_NAV_LIST,
  SHARE_DASHBOARD_COMPONENTS,
} from '../../constants';
import './index.scss';

type ShareDashboardModalProps = {
  showShareDashboardModal: boolean;
  setShowShareDashboardModal: (val: boolean) => void;
  fetchAndSetDashboardUsers: () => void;
  fetchUsersReqStatus: string;
};

const ShareDashboardModal = ({
  showShareDashboardModal,
  setShowShareDashboardModal,
  fetchAndSetDashboardUsers,
  fetchUsersReqStatus,
}: ShareDashboardModalProps) => {
  const { t } = useTranslation();

  const [shareDashboardComponent, setShareDashboardComponent] =
    useState<string>(SHARE_DASHBOARD_COMPONENTS.INVITE);

  return (
    <Modal
      className="share-dashboard"
      title={t('shareDashboard.shareModalTitle')}
      visible={showShareDashboardModal}
      footer={null}
      onCancel={() => {
        setShowShareDashboardModal(false);
        setShareDashboardComponent(SHARE_DASHBOARD_COMPONENTS.INVITE);
      }}
      width={500}
      centered
    >
      <div className="flex flex-column flex-start">
        {shareDashboardComponent === SHARE_DASHBOARD_COMPONENTS.SUCCESS_PAGE ? (
          <div className="flex flex-column flex-center flex-gap-16 full-width">
            <SuccessIcon />
            <div className="font-heading-4-bold">
              {t('shareDashboard.successfullySentTitle')}
            </div>
            <div className="sub-title font-subHeader">
              {t('shareDashboard.successfullySentSubtitle')}
            </div>
          </div>
        ) : (
          <>
            <Menu
              mode="horizontal"
              className="horizontal-nav-menu flex flex-gap-8"
              selectedKeys={[shareDashboardComponent]}
            >
              {SHARE_DASHBAORD_NAV_LIST.map((navMenu) => (
                <Menu.Item
                  onClick={() => setShareDashboardComponent(navMenu.id)}
                  key={navMenu.id}
                  eventKey={navMenu.id}
                  className={`font-subHeader menu ${
                    navMenu.id === shareDashboardComponent && 'active-menu'
                  }`}
                >
                  {navMenu.title}
                </Menu.Item>
              ))}
            </Menu>
            {shareDashboardComponent === SHARE_DASHBOARD_COMPONENTS.INVITE && (
              <InviteUsers
                setShareDashboardComponent={setShareDashboardComponent}
                fetchAndSetDashboardUsers={fetchAndSetDashboardUsers}
              />
            )}
            {shareDashboardComponent ===
              SHARE_DASHBOARD_COMPONENTS.SHARED_WITH && (
              <SharedWith
                setShareDashboardComponent={setShareDashboardComponent}
                fetchAndSetDashboardUsers={fetchAndSetDashboardUsers}
                fetchUsersReqStatus={fetchUsersReqStatus}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default ShareDashboardModal;
