import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectAnomalyDetectionDashboard } from 'redux/anomalyDetectionDashboardSlice';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray } from 'utils/handleErrors';
import { HYPHEN_DATE_FORMAT } from 'utils/date';
import { getDetectionHistoryExcelData } from '../../utils';
import { AWSAnomalyType } from '../../types';

type DetectionHostoryProps = {
  pdfView: boolean;
  detectionHistoryData: AWSAnomalyType[];
  requestStatus: string;
  setShowExpandedGraphModal: (val: boolean) => void;
  selectedRowKey: React.Key | undefined;
  setSelectedRowKey: (val: React.Key | undefined) => void;
  setSelectedRow: (val: AWSAnomalyType | undefined) => void;
};

const DetectionHostory = ({
  pdfView,
  detectionHistoryData,
  requestStatus,
  setShowExpandedGraphModal,
  selectedRowKey,
  setSelectedRowKey,
  setSelectedRow,
}: DetectionHostoryProps) => {
  const { t } = useTranslation();
  const { selectedAnomalyDetectionConnection } = useSelector(
    selectAnomalyDetectionDashboard
  );

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: '50px',
      fixed: 'left',
    },
    {
      title: t('anomalyDetectionDashboard.detectionHistory.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text: any) => moment(text).format(HYPHEN_DATE_FORMAT),
      width: '100px',
    },
    {
      title: t('anomalyDetectionDashboard.detectionHistory.service'),
      dataIndex: 'dimensionValue',
      key: 'dimensionValue',
      width: '100px',
    },
    {
      title: t('anomalyDetectionDashboard.detectionHistory.monitorName'),
      dataIndex: 'monitorName',
      key: 'monitorName',
      width: '100px',
    },
    {
      title: t('anomalyDetectionDashboard.detectionHistory.monitorType'),
      dataIndex: 'monitorType',
      key: 'monitorType',
      render: (text: any) => text || '--',
      width: '100px',
    },
    {
      title: t('anomalyDetectionDashboard.detectionHistory.impactPercentage'),
      dataIndex: 'impactPercentage',
      key: 'impactPercentage',
      render: (text: any) => text || '--',
      align: 'right',
      width: '100px',
    },
  ];

  /**
   * @function getComponent
   * @description Function to return the graph or table component
   * @returns JSX element containing graph or the table
   */
  const getComponent = () => {
    return (
      <Table
        className="full-height detection-history-table"
        rowClassName={(_record: any, index: number) => {
          if (selectedRowKey === index) {
            return 'selected-row';
          }
        }}
        rowSelection={{
          type: 'radio',
          columnWidth: '40px',
          fixed: true,
          selectedRowKeys: [selectedRowKey],
          onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedRowKey(selectedRowKeys[0]);
            setSelectedRow(selectedRows[0]);
          },
        }}
        pagination={false}
        dataSource={detectionHistoryData.map((item, index) => ({
          key: index,
          ...item,
        }))}
        columns={columns}
        loading={
          evaluateRequestArray([requestStatus]) === REQUEST_STATUS.PROCESSING
        }
        fillContainer={true}
        scroll={{ y: '100%' }}
      />
    );
  };

  return (
    <div className="graph-area detection-history" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.detectionHistory')}
        graphName="detection-history"
        isTableViewSwitch={false}
        isTableView={true}
        showExpandIcon={!pdfView}
        setShowExpandedGraph={setShowExpandedGraphModal}
        isDownloadable={!pdfView}
        excelData={getDetectionHistoryExcelData(
          selectedAnomalyDetectionConnection?.name ?? '',
          detectionHistoryData
        )}
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};

export default DetectionHostory;
