import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectTheme } from 'redux/themeSlice';
import { setLoginType, userAuthorization } from 'redux/authorizationSlice';
import SSOLoginRoute from 'components/SSOLoginRoute';
import LoginWithCredentials from 'pages/LoginPage/components/LoginWithCredentials';
import { Config } from 'config';
import { convertHexToHsl } from 'utils/dashboardUtils';

import { LoginTypes } from './constants';
import { axiosInterceptor } from './utils';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme } = useSelector(selectTheme);
  const { loginType } = useSelector(userAuthorization);
  const location = useLocation();

  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    axiosInterceptor(navigate, setSessionExpired);

    updateLoginTypeByQueryParam();
  }, []);

  /**
   * @function updateLoginTypeByQueryParam
   * @description Function to update the login type by the query param
   * @param accessToken jwt access token
   */
  const updateLoginTypeByQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    const typeOfLogin = queryParams.get('loginType')?.toUpperCase();
    dispatch(
      setLoginType(
        typeOfLogin === LoginTypes.AUTOMATION
          ? LoginTypes.AUTOMATION
          : Config.login_type
      )
    );
  };

  /**
   * @function getLoginComponent
   * @description Function to return the login component based on the login type
   * @returns JSX element containing the login page
   */
  const getLoginComponent = () => {
    switch (loginType) {
      case LoginTypes.SSO:
        return (
          <SSOLoginRoute
            sessionExpired={sessionExpired}
            setSessionExpired={setSessionExpired}
          />
        );

      case LoginTypes.MFA:
        return <LoginWithCredentials sessionExpired={sessionExpired} />;

      case LoginTypes.AUTOMATION:
        return (
          <LoginWithCredentials
            sessionExpired={sessionExpired}
            requireMfa={false}
          />
        );
    }
  };

  return (
    <div
      className="stretch"
      style={
        {
          color: theme.textColor,
          '--text-color': theme.textColor,
          '--primary-color': theme.primaryColor,
          '--button-icon-color': theme.buttonIconColor,
          '--table-header-color': convertHexToHsl(theme.primaryColor, {
            l: 96,
          }),
        } as React.CSSProperties
      }
    >
      {getLoginComponent()}
    </div>
  );
};

export default LoginPage;
