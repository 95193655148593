import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { ChatbotProviderListType } from 'components/ChatBoxModal/types';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import ChatBotLLMsModal from './components/ChatBotLLMsModal';
import { getAllGenAIProviders } from './services';

type ChatBotLLMsControlsProps = {
  selectedChatBotdLLMs: ChatbotProviderListType[];
  setSelectedModels: (field: string, value: ChatbotProviderListType[]) => void;
};

const ChatBotLLMsControls = ({
  selectedChatBotdLLMs,
  setSelectedModels,
}: ChatBotLLMsControlsProps) => {
  const { t } = useTranslation();

  const [showLLMsModal, setShowLLMsModal] = useState(false);
  const [allChatBotLLMs, setAllChatBotLLMs] = useState<
    ChatbotProviderListType[]
  >([]);
  const [fetchAllLLMsReqStatus, setFetchAllLLMsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    fetchAllChatBotLLMs();
  }, []);

  const fetchAllChatBotLLMs = () => {
    setFetchAllLLMsReqStatus(REQUEST_STATUS.PROCESSING);

    getAllGenAIProviders()
      .then((res: any) => {
        if (res.status === 200) {
          setAllChatBotLLMs(res.data);
          setFetchAllLLMsReqStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setFetchAllLLMsReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setFetchAllLLMsReqStatus);
      });
  };

  return (
    <>
      <div
        className="flex flex-space-between flex-align-items-center flex-gap-16"
        data-testid="control-row"
      >
        <div>
          <div className="modal-heading">{t('chooseLLMsPrompt')}</div>
          <div className="small-grey-button">{t('chooseLLMsPromptNote')}</div>
        </div>
        <Icon
          className="action-cta-icon"
          iconName={ICONS.EDIT_LINE}
          size={ICONS_SIZE.TWO_X}
          onClick={() => setShowLLMsModal(true)}
          dataTestId="action-cta-icon"
        />
      </div>
      <ChatBotLLMsModal
        show={showLLMsModal}
        setShow={setShowLLMsModal}
        allChatBotLLMs={allChatBotLLMs}
        fetchAllLLMsReqStatus={fetchAllLLMsReqStatus}
        selectedChatBotLLMs={selectedChatBotdLLMs}
        onClickOk={(selectedModels) => {
          setSelectedModels('genAIModels', selectedModels);
          setShowLLMsModal(false);
        }}
      />
    </>
  );
};

export default ChatBotLLMsControls;
