import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
  selectPurchase,
  setAwsElastiCacheRisCartData,
} from 'redux/purchaseSlice';
import { selectNavMenu } from 'redux/activeNavMenuSlice';
import EmptyCart from 'pages/PurchasePage/components/EmptyCart';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';
import { purchaseAwsElastiCacheRis } from 'pages/PurchasePage/components/ReservedInstances/services';
import { PurchaseAwsElastiCacheRisType } from 'pages/PurchasePage/components/ReservedInstances/types';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import { PurchaseCartComponents } from 'pages/PurchasePage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  getRiTotalDue,
  getRiTotalMonthlyRecurringCost,
  getTotalOnetimePayment,
} from 'pages/PurchasePage/components/ReservedInstances/utils';

import BuyElastiCacheRiFormModal from '../BuyElastiCacheRiFormModal';
import PurchasedElastiCacheRICard from '../PurchasedElastiCacheRICard';

import './index.scss';

type ElastiCacheRiCartProps = {
  setElastiCacheRiCartComponent: (val: PurchaseCartComponents) => void;
};

const ElastiCacheRiCart = ({
  setElastiCacheRiCartComponent,
}: ElastiCacheRiCartProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { awsElastiCacheCartData, selectedConnection } =
    useSelector(selectPurchase);
  const { isSubMenuOpen } = useSelector(selectNavMenu);

  const [showElastiCacheRiForm, setShowElastiCacheRiForm] = useState(false);
  const [placeOrderRequestStatus, setPlaceOrderRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  /**
   * @function purchaseElastiCacheRIs
   * @description Function to purchase the ElastiCache RIs
   */
  const _purchaseElastiCacheRIs = (
    cartData: PurchaseAwsElastiCacheRisType[]
  ) => {
    const requests = cartData.map((item) => {
      const requestBody = {
        connectorId: selectedConnection?.connectorId,
        riOfferingId: item.offeringId,
        count: item.quantity,
        region: item.region,
      };

      return purchaseAwsElastiCacheRis(requestBody);
    });

    axios
      .all(requests)
      .then((responses: any[]) => {
        if (responses?.every((res: any) => res?.status === 200)) {
          setElastiCacheRiCartComponent(PurchaseCartComponents.SUCCESS);
          setPlaceOrderRequestStatus(REQUEST_STATUS.SUCCESS);
          dispatch(setAwsElastiCacheRisCartData([]));
          return;
        }
        setElastiCacheRiCartComponent(PurchaseCartComponents.ERROR);
        setPlaceOrderRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setPlaceOrderRequestStatus);
        setElastiCacheRiCartComponent(PurchaseCartComponents.ERROR);
      });
  };

  const getComponent = () => {
    if (awsElastiCacheCartData.length === 0) {
      return (
        <EmptyCart
          description={t(
            'purchasePage.reservedInstancesLabels.emptyRiCartDescription'
          )}
          buttonTitle={t(
            'purchasePage.reservedInstancesLabels.addReservedInstances'
          )}
          setOpenForm={setShowElastiCacheRiForm}
        />
      );
    }

    return (
      <div className="aws-elasticache-ri-cart flex flex-column flex-gap-16">
        <div className="flex flex-align-items-center flex-space-between">
          <div>
            <div className="modal-heading">
              {t(
                'purchasePage.reservedInstancesLabels.purchaseReservedInstances'
              )}
            </div>
            <div className="cart-heading-subtitle small-grey-button">
              {t('purchasePage.cart.cartHeadingSubTitle')}
            </div>
          </div>
          <Button
            title={t('purchasePage.cart.addNew')}
            type={BUTTON_TYPE.NEUTRAL}
            onClick={() => setShowElastiCacheRiForm(true)}
          />
        </div>
        <div className="cart-list flex flex-column flex-gap-10">
          {awsElastiCacheCartData
            .map((item, index) => ({ ...item, key: index }))
            .map((data, index) => (
              <PurchasedElastiCacheRICard
                elastiCacheRiData={data}
                index={index}
                key={data.key}
              />
            ))}
        </div>
        <PurchaseFooter
          totalHeading={t(
            'purchasePage.reservedInstancesLabels.riTotalDueNow',
            {
              count: awsElastiCacheCartData.length,
            }
          )}
          totalValue={getRiTotalDue(awsElastiCacheCartData)}
          costSummary={[
            {
              key: t(
                'purchasePage.reservedInstancesLabels.averageMonthlyRecurringCost'
              ),
              value: getRiTotalMonthlyRecurringCost(awsElastiCacheCartData),
            },
            {
              key: t('purchasePage.reservedInstancesLabels.oneTimePayment'),
              value: getTotalOnetimePayment(awsElastiCacheCartData),
            },
          ]}
          okTitle={t('purchasePage.cart.placeOrder')}
          onClickCancel={() => navigate(NAVIGATION_MENU_PATH.COMMITMENTS)}
          onClickOk={() => {
            setElastiCacheRiCartComponent(PurchaseCartComponents.SUCCESS);
            setPlaceOrderRequestStatus(REQUEST_STATUS.SUCCESS);
            dispatch(setAwsElastiCacheRisCartData([]));
          }}
          loadingOk={placeOrderRequestStatus === REQUEST_STATUS.PROCESSING}
          className={`cart-footer ${isSubMenuOpen && 'nav-pinned'}`}
        />
      </div>
    );
  };

  return (
    <section>
      {getComponent()}
      {showElastiCacheRiForm && (
        <BuyElastiCacheRiFormModal
          show={showElastiCacheRiForm}
          setShow={setShowElastiCacheRiForm}
        />
      )}
    </section>
  );
};

export default ElastiCacheRiCart;
