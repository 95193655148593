import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/Checkbox';
import DatePicker from 'components/DatePicker';
import {
  getAllAvailableValues,
  getFieldCategory,
  getFirstTableFilterGroup,
  updateFilterQuery,
} from 'pages/CustomDashboardPage/utils';
import { selectTheme } from 'redux/themeSlice';
import { ChartType, FilterType, LayoutDesignsType } from 'types/dashboard';
import { FIELD_TYPE } from 'constants/dashboard';

type FilterDropdownType = {
  chart: ChartType;
  layout: LayoutDesignsType;
  filter: FilterType;
};

const FilterDropdown = ({ chart, layout, filter }: FilterDropdownType) => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);

  /**
   * @function onSelectAll
   * @description Adds/Removes all the available values in the selected values property.
   * @param e Checkbox event
   */
  const onSelectAll = (e: any) => {
    let updatedFilter = { ...filter };
    if (e.target.checked) {
      updatedFilter.selectedValues = getAllAvailableValues(filter.field);
    } else {
      updatedFilter.selectedValues = [];
    }

    updateFilterQuery(
      [
        ...getFirstTableFilterGroup(chart).map((filterObj) => {
          if (filterObj.field === filter.field) return updatedFilter;
          return filterObj;
        })!,
      ],
      layout.position,
      chart.chartPosition
    );
  };

  /**
   * @function onSelectValue
   * @description Adds/Removes a particular value in the selected values property
   * @param e Checkbox event
   * @param checkboxValue The value to be added or removed
   */
  const onSelectValue = (e: any, checkboxValue: string) => {
    let updatedFilter = { ...filter };
    const selectedValues =
      updatedFilter?.selectedValues ?? getAllAvailableValues(filter.field);
    if (e.target.checked) {
      updatedFilter.selectedValues = [...selectedValues, checkboxValue];
    } else {
      updatedFilter.selectedValues = selectedValues.filter(
        (value) => value !== checkboxValue
      );
    }

    updateFilterQuery(
      [
        ...getFirstTableFilterGroup(chart).map((filterObj) => {
          if (filterObj.field === filter.field) return updatedFilter;
          return filterObj;
        })!,
      ],
      layout.position,
      chart.chartPosition
    );
  };

  /**
   * @function onChangeDates
   * @description Updates the start and end date in the selected values property.
   * @param dateString start and end date array
   */
  const onChangeDates = (dateString: [string, string]) => {
    updateFilterQuery(
      [
        ...getFirstTableFilterGroup(chart).map((f) => {
          if (f.field === filter.field)
            return { ...filter, selectedValues: dateString };
          return filter;
        })!,
      ],
      layout.position,
      chart.chartPosition
    );
  };

  const getFilterForLiteral = () => (
    <>
      <Checkbox
        checked={
          getAllAvailableValues(filter.field)?.every((val) =>
            filter.selectedValues?.includes(val)
          ) || filter.selectedValues === undefined
        }
        onChange={onSelectAll}
      >
        {t('tableFilterDropdown.selectAll')}
      </Checkbox>
      {getAllAvailableValues(filter.field)!.map((value) => (
        <Checkbox
          key={value}
          checked={
            filter.selectedValues?.includes(value) ??
            filter.selectedValues === undefined
          }
          onChange={(e) => onSelectValue(e, value)}
        >
          {value}
        </Checkbox>
      ))}
    </>
  );

  const getFilterForTime = () => (
    <DatePicker
      className="range-picker"
      onChange={(_dates: any, dateString: [string, string]) =>
        onChangeDates(dateString)
      }
    />
  );

  return (
    <div
      className="custom-table-filter-dropdown new-styled-scroll flex flex-column flex-gap-8"
      style={
        {
          color: theme.textColor,
          '--text-color': theme.textColor,
          '--primary-color': theme.primaryColor,
          '--button-icon-color': theme.buttonIconColor,
        } as React.CSSProperties
      }
    >
      {getFieldCategory(filter.field) === FIELD_TYPE.LITERAL
        ? getFilterForLiteral()
        : getFilterForTime()}
    </div>
  );
};

export default FilterDropdown;
