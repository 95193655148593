import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  setImportStaticFilesComponent,
  importStaticFiles,
  addFileUploadTransaction,
  removeFileUploadTransactions,
  setImportFileData,
} from 'redux/importStaticFileSlice';
import { providerList } from 'redux/providerSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import { ImportStaticFilesComponents } from 'pages/StaticFilesPage/constants';
import { SaveStaticTransactionRequestType } from 'pages/StaticFilesPage/types';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  getTransactionByName,
  saveStaticTransaction,
  uploadImportDataFile,
} from 'pages/StaticFilesPage/services';
import { ImportFileStatus } from 'types/importFiles';
import { onApiCallError } from 'utils/handleErrors';

import BasicDetails from '../BasicDetails';
import SchemaDetails from '../SchemaDetails';
import UploadFileForm from '../UploadFileForm';
import { getSchemaToSave } from './utils';

import './index.scss';

type ImportFileFormProps = {
  isValidSchema: boolean;
  setIsValidSchema: (isValid: boolean) => void;
  setIsDuplicateTransactionName: (isValid: boolean) => void;
  clickedSubmit: boolean;
  setClickedSubmit: (val: boolean) => void;
  setFormSubmitRequestStatus: (val: string) => void;
  fileToBeUploaded: File | undefined;
  setFileToBeUploaded: (file: File | undefined) => void;
};

const ImportFileForm = ({
  isValidSchema,
  setIsValidSchema,
  setIsDuplicateTransactionName,
  clickedSubmit,
  setClickedSubmit,
  setFormSubmitRequestStatus,
  fileToBeUploaded,
  setFileToBeUploaded,
}: ImportFileFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { importFileData, isEdit, currentStep } =
    useSelector(importStaticFiles);
  const { selectedProvider } = useSelector(providerList);

  useEffect(() => {
    if (isEdit) {
      setIsDuplicateTransactionName(false);
      setIsValidSchema(true);
    }
  }, [isEdit]);

  useEffect(() => {
    clickedSubmit && onClickSubmit();
    setClickedSubmit(false);
  }, [clickedSubmit]);

  /**
   * @function setUploadStatusPage
   * @description Function to set the page to based on status and set error message
   * @param status status of upload
   * @param statusMessage upload status message
   */
  const setUploadStatusPage = (status: string, statusMessage: string) => {
    switch (status) {
      case ImportFileStatus.READY: {
        dispatch(
          setImportStaticFilesComponent(
            ImportStaticFilesComponents.SUCCESS_PAGE
          )
        );
        break;
      }

      case ImportFileStatus.ERROR: {
        dispatch(setErrorMessage(statusMessage));
        dispatch(
          setImportStaticFilesComponent(ImportStaticFilesComponents.ERROR_PAGE)
        );
        break;
      }

      case ImportFileStatus.UPLOADING:
      case ImportFileStatus.PROCESSING:
        break;
    }
  };

  const checkUploadStatus = (transactionName: string) => {
    const requestParams = {
      transactionName: transactionName,
    };
    getTransactionByName(requestParams)
      .then((res: any) => {
        if (res.status === 200) {
          const responseData = res.data.responseData;
          setUploadStatusPage(
            responseData.fileImportStatus,
            responseData.statusMessage
          );
        }
      })
      .catch((e) => {
        onApiCallError(e);
      });
  };

  /**
   * @function uploadDataFile
   * @description Function to upload to file using signed URL
   * @param signedUrl Signed url for uploading the file
   * @param transactionName Transaction name of the saved transaction
   */
  const uploadDataFile = (signedUrl: string, transactionName: string) => {
    if (!fileToBeUploaded) return;

    dispatch(addFileUploadTransaction(transactionName));

    uploadImportDataFile(signedUrl, fileToBeUploaded)
      .then(() => {
        dispatch(removeFileUploadTransactions(transactionName));
        checkUploadStatus(transactionName);
      })
      .catch((e) => {
        onApiCallError(e);
        dispatch(removeFileUploadTransactions(transactionName));
        dispatch(
          setErrorMessage(t('importFileLabels.errorPageLabels.errorFileUpload'))
        );
        dispatch(
          setImportStaticFilesComponent(ImportStaticFilesComponents.ERROR_PAGE)
        );
        setFormSubmitRequestStatus(REQUEST_STATUS.SUCCESS);
      });
  };

  /**
   * @function onClickSubmit
   * @description Function to save the data of all the fields entered
   */
  const onClickSubmit = () => {
    setFormSubmitRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody: SaveStaticTransactionRequestType = {
      transactionName: importFileData.displayName,
      originalFileName: fileToBeUploaded?.name ?? '',
      datasetType: importFileData.typeOfDataset,
      focusSchema: importFileData.focusSchema,
      standardSchema: importFileData.isExistingSchema,
      preBuiltDashbaordRequired: importFileData.wantSpendDiagnosticsDashboard,
      schemaName: importFileData.schemaName,
      schema: getSchemaToSave(importFileData),
      datasourceProvider: selectedProvider,
      fileSize: fileToBeUploaded?.size ?? 0,
    };

    const params = { update: isEdit };

    saveStaticTransaction(requestBody, params)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(
            setImportStaticFilesComponent(
              ImportStaticFilesComponents.UPLOADING_PAGE
            )
          );
          const response = res.data.responseData;
          uploadDataFile(response.signedUrl, response.transactionName);
          dispatch(
            setImportFileData({
              ...importFileData,
              transactionName: response.transactionName,
            })
          );
          setFormSubmitRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        dispatch(
          setErrorMessage(
            res.data.message ?? t('connectionCSPErrorPage.subHeader')
          )
        );
        dispatch(
          setImportStaticFilesComponent(ImportStaticFilesComponents.ERROR_PAGE)
        );
        setFormSubmitRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setFormSubmitRequestStatus);
        dispatch(
          setErrorMessage(
            e.response.data.message ?? t('connectionCSPErrorPage.subHeader')
          )
        );
        dispatch(
          setImportStaticFilesComponent(ImportStaticFilesComponents.ERROR_PAGE)
        );
      });
  };

  const getComponent = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicDetails
            setIsDuplicateTransactionName={setIsDuplicateTransactionName}
          />
        );
      case 2:
        return (
          <SchemaDetails
            isValidSchema={isValidSchema}
            setIsValidSchema={setIsValidSchema}
          />
        );
      case 3:
        return (
          <UploadFileForm
            focusSchema={importFileData.focusSchema}
            isStandardSchema={importFileData.isExistingSchema}
            setFileToBeUploaded={setFileToBeUploaded}
          />
        );
    }
  };

  return <>{getComponent()}</>;
};

export default ImportFileForm;
