import i18n from 'i18n';
import moment from 'moment';

import { store } from 'redux/store';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { changeAlphaOfColor } from 'utils/dashboardUtils';
import { ProjectNumberIdMappingType, ValueLabelType } from 'types/dashboard';
import { RECOMMENDATION_CATEGORIES } from 'constants/recommendations';
import {
  ServiceNowStatus,
  TICKET_RECOMMENDATION_STATUS,
} from 'constants/workflowIntegrations';
import { RulesetsType } from 'pages/RuleEnginePage/types';

import {
  RECOMMENDATION_CATEGORIES_LABELS,
  RECOMMENDATION_SUBTYPE_LABELS,
  RecommendationStatus,
  RecommendationTableExportColumns,
  SAVINGS_TYPES,
  WASTE_MANAGEMENT_RECOMMENDATION_TYPES_LABELS,
  CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES_LABELS,
  PURCHASE_TACTICS_RECOMMENDATION_TYPES_LABELS,
  FilterKeys,
  GranulateRecommenderSubTypes,
} from './constants';
import {
  GcpRecommendationListType,
  RecommendationFiltersType,
  ViewListType,
} from './types';

/**
 * @function addIndexAndCommaSeprator
 * @description Function to add the slNo and format cost data
 * @param data Data to be formatted
 * @returns Formatted data
 */
export const addIndexAndCommaSeparator = (data: any) => {
  return data.map((item: any, index: number) => ({
    ...item,
    slNo: index + 1,
    cost: numberCommaSeparator(item.cost ?? 0),
  }));
};

export const getRecommendationTableExcelData = (
  connectorName: string,
  data: {
    sheetName: string;
    recommendationTableData: GcpRecommendationListType[];
  }[]
) => {
  return data
    .filter((item) => item.recommendationTableData.length > 0)
    .map((item) => ({
      sheetName: item.sheetName,
      columns: RecommendationTableExportColumns,
      data: addIndexAndCommaSeparator(item.recommendationTableData),
      filters: [
        {
          heading: i18n.t('excelExportLabels.connectionName'),
          value: connectorName,
        },
      ],
    }));
};

/**
 * @function getRulesetIdByName
 * @description Function to get the ruleset Id by ruleset Name
 * @param rulesetName The Ruleset name for which the id is returned
 * @param rulesets List of rulesets to find the id
 * @returns String Id or undefined if not found
 */
const getRulesetIdByName = (
  rulesetName: string,
  rulesets: RulesetsType[] = []
) => {
  return rulesets?.find((ruleset) => ruleset.ruleSetName === rulesetName)
    ?.rulesetId;
};

/**
 * @function getFilterValueLabels
 * @description Function to add the display labels for filter values and filter the data based on permissions
 * @param key Filter key for which the value labels are added
 * @param values List of values for which the labels are added
 * @param valueMap Map containing the additional information of labels
 * @param hasGranulatePermission Boolean to indicated whether the granulate permission exists or not
 * @returns List of value and labels
 */
const getFilterValueLabels = (
  key: string,
  values: ValueLabelType[],
  valuesMap: {
    projectNumberIdMapping?: ProjectNumberIdMappingType[];
    rulesets?: RulesetsType[];
  },
  hasGranulatePermission?: boolean
): ValueLabelType[] => {
  switch (key) {
    case FilterKeys.RULESET:
      return values.map((valueLabel) => ({
        value:
          getRulesetIdByName(valueLabel.value, valuesMap.rulesets) ??
          valueLabel.value,
        label: valueLabel.value,
      }));

    case FilterKeys.RECOMMENDER_SUBTYPE: {
      let permittedValues = values;
      if (!hasGranulatePermission) {
        // Remove Granulate filter values if the granulate permission is not present
        permittedValues = permittedValues.filter(
          (item) =>
            !Object.values(GranulateRecommenderSubTypes).includes(
              item.value as GranulateRecommenderSubTypes
            )
        );
      }

      return permittedValues.map((valueLabel) => ({
        value: valueLabel.value,
        label: RECOMMENDATION_SUBTYPE_LABELS.find(
          (item) => item.key === valueLabel.value
        )?.label,
      }));
    }

    case FilterKeys.PRIMARY_IMPACT_CATEGORY:
      return values.map((valueLabel) => ({
        value: valueLabel.value,
        label: RECOMMENDATION_CATEGORIES_LABELS.find(
          (item) => item.key === valueLabel.value
        )?.label,
      }));

    case FilterKeys.CLOUD_ENTITY_ID:
      return values.map((valueLabel) => ({
        value: valueLabel.value,
        // Add project name (project Id) as label (Currently added for GCP)
        label: valuesMap.projectNumberIdMapping?.find(
          (item) =>
            item.projectNumber.toString() === valueLabel.value.toString()
        )?.projectId,
      }));

    default:
      return values;
  }
};

/**
 * @function getLabelledFilters
 * @description Function to add the display labels for filter values
 * @param filters List of filters to be updated
 * @param valueMap Map containing the additional information of labels
 * @param hasGranulatePermission Boolean to indicated whether the granulate permission exists or not
 * @returns List of filters with the updated labels for values
 */
export const getLabelledFilters = (
  filters: RecommendationFiltersType[],
  valuesMap: {
    projectNumberIdMapping?: ProjectNumberIdMappingType[];
    rulesets?: RulesetsType[];
  },
  hasGranulatePermission?: boolean
): RecommendationFiltersType[] => {
  return filters.map((filter) => {
    if (!filter.values || filter.values.length === 0) {
      return filter;
    }

    return {
      ...filter,
      values: getFilterValueLabels(
        filter.key,
        filter.values,
        valuesMap,
        hasGranulatePermission
      ),
    };
  });
};

export const getStatusTextClassName = (text: string) => {
  switch (text) {
    case ServiceNowStatus.TICKET_TBC:
    case RecommendationStatus.ACTIVE:
      return 'status-purple';
    case ServiceNowStatus.IN_PROGRESS:
    case RecommendationStatus.CLAIMED:
      return 'status-orange';
    case ServiceNowStatus.ON_HOLD:
      return 'status-cyan';
    case ServiceNowStatus.RESOLVED:
      return 'status-green';
    case ServiceNowStatus.CLOSED:
    case RecommendationStatus.DISMISSED:
      return 'status-grey';
    case ServiceNowStatus.NEW:
      return 'status-violet';
    case RecommendationStatus.SUCCEEDED:
      return 'status-light-green';
    case RecommendationStatus.FAILED:
      return 'status-red';
    case TICKET_RECOMMENDATION_STATUS.APPROVED:
      return 'status-chip status-chip-green font-small-bold';
    case TICKET_RECOMMENDATION_STATUS.DISAPPROVED:
      return 'status-chip status-chip-red font-small-bold';

    default:
      return 'font-subHeader';
  }
};

/**
 * @function compareToValue
 * @description Function to return comparison value
 * @param value1 first value for comparison
 * @param value2 second value for comparison
 * @param key field for sorting
 * @param order order of sorting, either ascending or descending
 * @returns boolean value based on the comparison values and order
 */
export const compareToValue = (
  value1: string,
  value2: string,
  key: string,
  order: string | undefined
) => {
  if (order === 'descend') {
    const val = value1;
    value1 = value2;
    value2 = val;
  }

  if (key === 'lastRefreshed') {
    return moment.utc(value1).isAfter(moment.utc(value2));
  }

  return value1 >= value2;
};

/**
 * @function numberArraySortFunction
 * @description Function to return comparison integer value for numbers
 * @param value1 first value for comparison
 * @param value2 second value for comparison
 * @param order order of sorting, either ascending or descending
 * @returns integer value based on the comparison values and order
 */
export const numberArraySortFunction = (
  value1: number,
  value2: number,
  order: string
) => {
  if (order === 'descend') {
    const val = value1;
    value1 = value2;
    value2 = val;
  }

  if (value1 === Infinity) return 1;
  else if (isNaN(value1)) return -1;
  else return value1 - value2;
};

/**
 * @function getCostSavingsSummaryBarColors
 * @description Function to list of colors for cost summary graph based on the savings type selection
 * @param selectedSummaryGraph summary graph selected
 * @param graph summary graph for which the colors are fetched
 * @param selectedSavingsType savings type selected
 * @returns List of colors
 */
export const getCostSavingsSummaryBarColors = (
  selectedSummaryGraph: string,
  graph: string,
  selectedSavingsType: string | undefined
) => {
  const manualColors = store.getState().theme.manualChartColors;
  if (selectedSummaryGraph !== graph || !selectedSavingsType) {
    return [
      manualColors[0].hexCode,
      manualColors[1].hexCode,
      manualColors[2].hexCode,
    ];
  }

  return [
    !selectedSavingsType ||
    selectedSavingsType === SAVINGS_TYPES.POTENTIAL_SAVINGS
      ? manualColors[0].hexCode
      : changeAlphaOfColor(manualColors[0].hexCode, 40),
    !selectedSavingsType ||
    selectedSavingsType === SAVINGS_TYPES.COST_SAVINGS_IN_PROGRESS
      ? manualColors[1].hexCode
      : changeAlphaOfColor(manualColors[1].hexCode, 40),
    !selectedSavingsType || selectedSavingsType === SAVINGS_TYPES.COST_SAVED
      ? manualColors[2].hexCode
      : changeAlphaOfColor(manualColors[2].hexCode, 40),
  ];
};

/**
 * @function fetchRecommenderCategoryTypes
 * @description Function to fetch the recommender list for the selected recommendation type graph
 * @param category Category for which the recommenders are returned
 * @returns List of recommender category list
 */
export const fetchRecommenderCategoryTypes = (category: string) => {
  switch (category) {
    case RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT:
      return WASTE_MANAGEMENT_RECOMMENDATION_TYPES_LABELS;
    case RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT:
      return CONSUMPTION_MANAGEMENT_RECOMMENDATION_TYPES_LABELS;
    case RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS:
      return PURCHASE_TACTICS_RECOMMENDATION_TYPES_LABELS;
    default:
      return [];
  }
};

/**
 * @function hasRulesetFilters
 * @description Function to validate if the view has ruleset filter
 * @param viewList List of filter views
 * @param currentRecommendationView selected view
 * @returns Boolean true if the view has a ruleset filter else false
 */
export const hasRulesetFilters = (
  viewList: ViewListType[] | undefined,
  currentRecommendationView: ViewListType
) => {
  return (
    !viewList?.some((view) => view.key === currentRecommendationView.key) ||
    currentRecommendationView.recommendationsFilterDtoList.some(
      (filter) => filter.key === FilterKeys.RULESET
    )
  );
};
