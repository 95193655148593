import {
  DEFAULT_RULE,
  DEFAULT_STATEMENT,
  FIELD_VALUE_TYPES,
  OPERATORS,
} from 'pages/RuleEnginePage/constants';
import {
  RulesetsType,
  RulesetTemplateType,
  RulesType,
  StatementValueTemplateType,
  StatementValueType,
} from 'pages/RuleEnginePage/types';
import { APIS } from 'constants/endpoints';
import { PROVIDER } from 'constants/cloudProviders';

export const getRulesetTemplateEndpointByProvider = (provider: string) => {
  if (provider === PROVIDER.GCP) {
    return APIS.GET_GCP_DEFAULT_RULESET_TEMPLATES;
  }
  if (provider === PROVIDER.AWS) {
    return APIS.GET_AWS_DEFAULT_RULESET_TEMPLATES;
  }

  return '';
};

/**
 * @function isRecommendationCategoryDisabled
 * @description Function to check if all the statements are added in the ruleset for a recommendation type
 * @param rulesetTemplateList ruleset templates
 * @param ruleEngineFormData form data of the current ruleset
 * @param recommendationType recommendation type
 * @param currentRuleRecommendationType recommendation type chosen for current rule
 * @returns boolean true if all the statements are present else false
 */
export const isRecommendationCategoryDisabled = (
  rulesetTemplateList: RulesetTemplateType[],
  ruleEngineFormData: RulesetsType,
  recommendationType: string,
  currentRuleRecommendationType: string = ''
) => {
  const templateStatements = rulesetTemplateList.find(
    (template) => template.recommendationType === recommendationType
  )?.statements;

  if (!templateStatements || templateStatements.length === 0) {
    return true;
  }

  return (
    recommendationType !== currentRuleRecommendationType &&
    templateStatements?.every((statement) =>
      ruleEngineFormData.rules?.some(
        (rule) =>
          rule.recommendationType === recommendationType &&
          rule.recommendationStatementType ===
            statement.recommendationStatementType
      )
    )
  );
};

/**
 * @function isRecommendationStatementDisabled
 * @description Function to check if the statement added in the ruleset
 * @param rulesetTemplateList ruleset templates
 * @param ruleEngineFormData form data of the current ruleset
 * @param recommendationType recommendation type
 * @param recommendationStatementType statement type to be validated for
 * @param ruleIndex index of the current rule in the ruleset
 * @returns boolean true if all the statements are present else false
 */
export const isRecommendationStatementDisabled = (
  rulesetTemplateList: RulesetTemplateType[],
  ruleEngineFormData: RulesetsType,
  recommendationType: string,
  recommendationStatementType: string,
  ruleIndex: number
) => {
  const templateStatements = rulesetTemplateList
    .find((item) => item.recommendationType === recommendationType)
    ?.statements?.find(
      (item) => item.recommendationStatementType === recommendationStatementType
    )?.statementValuesList;

  if (!templateStatements || templateStatements.length === 0) {
    return true;
  }

  const index = ruleEngineFormData.rules?.findIndex(
    (statement) =>
      statement.recommendationType === recommendationType &&
      statement.recommendationStatementType === recommendationStatementType
  );

  return !(index === -1 || index === ruleIndex);
};

/**
 * @function isStatementFieldDisabled
 * @description Function to check if the field in a statement is disabled
 * @param ruleEngineFormData form data of the current ruleset
 * @param recommendationType recommendation type
 * @param recommendationStatementType statement type to be validated for
 * @param statementIndex index of the statement in the rule
 * @param field current statement field selected
 * @returns boolean true if all the fields are present else false
 */
export const isStatementFieldDisabled = (
  ruleEngineFormData: RulesetsType,
  recommendationType: string,
  recommendationStatementType: string,
  statementIndex: number,
  field: string
) => {
  const statements = ruleEngineFormData.rules?.find(
    (statement) =>
      statement.recommendationType === recommendationType &&
      statement.recommendationStatementType === recommendationStatementType
  )?.statementValuesList;

  const index = statements?.findIndex((item) => item.field === field);

  return !(index === -1 || index === statementIndex);
};

/**
 * @function getDefaultRule
 * @description Function to construct a default rule to display on adding a new rule
 * @param rulesetTemplateList template of the ruleset
 * @param ruleEngineFormData form data of the current ruleset
 * @returns default rule or undefined
 */
export const getDefaultRule = (
  rulesetTemplateList: RulesetTemplateType[],
  ruleEngineFormData: RulesetsType
) => {
  const rulesetTemplate = rulesetTemplateList.find(
    (item) =>
      !isRecommendationCategoryDisabled(
        rulesetTemplateList,
        ruleEngineFormData,
        item.recommendationType,
        ''
      )
  );

  if (!rulesetTemplate) {
    return rulesetTemplate;
  }

  const defaultStatementType = rulesetTemplate?.statements.find(
    (item) =>
      !isRecommendationStatementDisabled(
        rulesetTemplateList,
        ruleEngineFormData,
        rulesetTemplate.recommendationType,
        item.recommendationStatementType,
        ruleEngineFormData.rules?.length
      )
  );

  const defaultStatementValue =
    defaultStatementType && defaultStatementType.statementValuesList?.length > 0
      ? {
          field: defaultStatementType?.statementValuesList[0].field,
          operator: defaultStatementType?.statementValuesList[0].operators[0],
          value: '',
        }
      : DEFAULT_STATEMENT;

  const defaultRule: RulesType = {
    ...DEFAULT_RULE,
    recommendationType: rulesetTemplate?.recommendationType || '',
    recommendationStatementType:
      defaultStatementType?.recommendationStatementType ?? '',
    checkId: defaultStatementType?.checkId,
    statementValuesList: [
      {
        field: defaultStatementValue.field,
        operator: defaultStatementValue.operator,
        value: {},
      },
    ],
  };

  return defaultRule;
};

/**
 * @function getDefaultStatement
 * @description Function to construct a default statement to display on adding a new statement
 * @param rulesetTemplateList template of the ruleset
 * @param ruleEngineFormData form data of the current ruleset
 * @param recommendationType type of recommendation selected
 * @param recommendationStatementType type of recommendation statement selected
 * @returns default statement or undefined
 */
export const getDefaultStatement = (
  rulesetTemplateList: RulesetTemplateType[],
  ruleEngineFormData: RulesetsType,
  recommendationType: string,
  recommendationStatementType: string
) => {
  const rulesetTemplateStatements = rulesetTemplateList
    .find((item) => item.recommendationType === recommendationType)
    ?.statements?.find(
      (statement) =>
        statement.recommendationStatementType === recommendationStatementType
    )?.statementValuesList;

  const statementsLength =
    ruleEngineFormData.rules?.find(
      (item) =>
        item.recommendationType === recommendationType &&
        item.recommendationStatementType === recommendationStatementType
    )?.statementValuesList?.length ?? 0;

  const defaultStatement = rulesetTemplateStatements?.find(
    (item) =>
      !isStatementFieldDisabled(
        ruleEngineFormData,
        recommendationType,
        recommendationStatementType,
        statementsLength,
        item.field
      )
  );

  if (!defaultStatement) {
    return defaultStatement;
  }

  return defaultStatement
    ? {
        field: defaultStatement.field,
        operator: defaultStatement.operators[0],
        value: '',
      }
    : DEFAULT_STATEMENT;
};

/**
 * @function addHashPrefixToIntegerAndFloat
 * @description Function to add # as prefix to integer and float values
 * @param statementTemplate template of the statement modified
 * @param statementValue Value without # prefix
 * @returns modified statement Value
 */
export const addHashPrefixToIntegerAndFloat = (
  statementTemplate: StatementValueTemplateType | undefined,
  statementValue: StatementValueType
) => {
  if (!statementTemplate) {
    return statementValue.value;
  }

  if (
    [
      FIELD_VALUE_TYPES.INTEGER.valueOf(),
      FIELD_VALUE_TYPES.FLOAT.valueOf(),
    ].includes(statementTemplate.type)
  ) {
    return statementValue.operator === OPERATORS.BETWEEN
      ? {
          ...statementValue.value,
          valueRange: {
            min: '#' + statementValue.value.valueRange?.min,
            max: '#' + statementValue.value.valueRange?.max,
          },
        }
      : {
          ...statementValue.value,
          constantValue: '#' + statementValue.value.constantValue,
        };
  }

  return statementValue.value;
};

/**
 * @function removeHashPrefixFromIntegerAndFloat
 * @description Function to remove # as prefix from integer and float values
 * @param statementTemplate template of the statement modified
 * @param statementValue Value without # prefix
 * @returns modified statement Value
 */
export const removeHashPrefixFromIntegerAndFloat = (
  statementTemplate: StatementValueTemplateType | undefined,
  statementValue: StatementValueType
) => {
  if (!statementTemplate) {
    return statementValue.value;
  }

  if (
    [
      FIELD_VALUE_TYPES.INTEGER.valueOf(),
      FIELD_VALUE_TYPES.FLOAT.valueOf(),
    ].includes(statementTemplate.type)
  ) {
    return statementValue.operator === OPERATORS.BETWEEN
      ? {
          ...statementValue.value,
          valueRange: {
            min:
              statementValue?.value?.valueRange?.min?.replaceAll('#', '') ?? '',
            max:
              statementValue?.value?.valueRange?.max?.replaceAll('#', '') ?? '',
          },
        }
      : {
          ...statementValue.value,
          constantValue: statementValue.value.constantValue?.replaceAll(
            '#',
            ''
          ),
        };
  }

  return statementValue.value;
};
