import i18n from 'i18n';
import { FilterKeys } from 'pages/CostOptimizationInsightsPage/constants';

export const FilterKeysLabels = [
  {
    key: FilterKeys.CATEGORY,
    label: i18n.t('filtersLabels.category'),
  },
  {
    key: FilterKeys.RECOMMENDER_SUBTYPE,
    label: i18n.t('filtersLabels.recommenderSubtype'),
  },
  {
    key: FilterKeys.PRIMARY_IMPACT_CATEGORY,
    label: i18n.t('filtersLabels.primaryImpactCategory'),
  },
  {
    key: FilterKeys.CLOUD_ENTITY_ID,
    label: i18n.t('filtersLabels.projectId'),
  },
  {
    key: FilterKeys.RECOMMENDER,
    label: i18n.t('filtersLabels.recommender'),
  },
  {
    key: FilterKeys.RECOMMENDATION_DESCRIPTION,
    label: i18n.t('filtersLabels.recommendationDescription'),
  },
  {
    key: FilterKeys.LAST_REFRESH_DATE_RANGE,
    label: i18n.t('filtersLabels.lastRefreshDateRange'),
  },
  {
    key: FilterKeys.COST_SAVINGS,
    label: i18n.t('filtersLabels.costSavings'),
  },
  {
    key: FilterKeys.STATUS,
    label: i18n.t('filtersLabels.status'),
  },
  {
    key: FilterKeys.REGION,
    label: i18n.t('filtersLabels.region'),
  },
  {
    key: FilterKeys.RULESET,
    label: i18n.t('filtersLabels.ruleset'),
  },
];
