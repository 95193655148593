import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { userAuthorization } from 'redux/authorizationSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { RECOMMENDATION_CATEGORIES } from 'constants/recommendations';
import LineChartWithTable from 'components/LineChartWithTable';
import DashboardGraphContainer from 'components/DashboardGraphContainer';
import {
  ChartCriteria,
  ChartFilterType,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import { selectDashboard } from 'redux/dashboardSlice';
import { RulesetsType } from 'pages/RuleEnginePage/types';
import { CHART_TYPES } from 'constants/graphConfig';
import { CostByNameType } from 'types/dataTypes';
import { getMonthRangeHandler } from 'utils/graphUtils';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import {
  getFilteredRecommendations,
  getGCPProjectNumberIdMapping,
  getRulesetFilters,
} from 'utils/services';
import {
  DATE_TIME_AM_PM,
  getLastNMonthLabels,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  addZeroMarginClass,
  generateGraphColors,
  isDashboardWithStaticData,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { fetchRecommenderCategoryTypes } from 'pages/CostOptimizationInsightsPage/utils';
import {
  FilterGroupType,
  GranulateCostImpactType,
  GraphProperties,
  GraphRowContentType,
  ProjectNumberIdMappingType,
} from 'types/dashboard';
import {
  RECOMMENDATION_STATUS_LABELS,
  RECOMMENDATION_SUBTYPE_LABELS,
  RecommendationSource,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  fetchGranulateCostImpact,
  getPdfMetaData,
} from 'pages/OverviewPage/components/ConnectionsDashboard/utils';
import { getAzureCategoryRecommenderMapping } from 'pages/CostOptimizationInsightsPage/components/Azure/RecommendationTable/services';
import { CategoryRecommenderMappingType } from 'pages/CostOptimizationInsightsPage/types';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { GranulateConnectionsType } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationTableWithWorkFlow/types';
import { getAndSetGranulateRecommendations } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/components/RecommendationTableWithWorkFlow/utils';

import { EstimatedMonthlySavingsPerCategory } from './components/EstimatedMonthlySavingsPerCategory';
import EstimatedSavingsPerProject from './components/EstimatedSavingsPerProject';
import { SavingsPerCategory } from './components/SavingsPerCategory';
import DetailedSavingsTable from './components/DetailedSavingsTable';
import CostRecomSummaryDashboardControls from './components/CostRecomSummaryDashboardControls';
import {
  SavingsPlanTableColumns,
  EstimatedSavingsPerProjectColumns,
  CHART_CRITERIA_LIST,
  CategorySavingsExportColumns,
} from './constants';
import {
  CostByProject,
  MonthlyCostByCategory,
  ColouredMonthlyCostByRecommender,
} from './types';
import {
  getMonthlySavingsByCategoryRequestBody,
  getDetailedSavingsRequestBody,
  getEstimatedSavingsPerProjectRequestBody,
  hasSavingsByProjectChart,
  hasSavingsPlanTrendChart,
  fetchAzureSavingsByCategoriesData,
  fetchSavingsPlanTrendData,
  getEstimatedMonthlySavingsPerCategoryColumns,
  fetchAzureMonthlySavingsByEachCategoryData,
  getDetailedSavingsTableColumns,
  getSelectedRecommenderLabel,
  getAllRecommenderOptionsByProvider,
  fetchAzureDetailedSavingsData,
  getConsolidatedEstimatedSavingsPerProject,
  getConsolidatedConsumptionManagementCost,
  getDetailsSavingsTableDataForExport,
  fetchGranulateConnections,
} from './utils';

const CostRecommendationSummaryDashboard = () => {
  const { t } = useTranslation();

  const { selectedDashboard, selectedConnection } =
    useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { permissions } = useSelector(userAuthorization);

  const [chartCriteriaFilters, setChartCriteriaFilters] = useState<string[]>(
    CHART_CRITERIA_LIST.map((item) => item.value)
  );

  // Ruleset states
  const [selectedRulesets, setSelectedRulesets] = useState<RulesetsType[]>([]);
  const [rulesetsRequestStatus, setRulesetsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [rulesetFilters, setRulesetFilters] = useState<FilterGroupType[]>([]);
  const [rulesetFiltersRequestStatus, setRulesetFiltersRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  // Project Number to Id mapping state
  const [projectNumberIdMapping, setProjectNumberIdMapping] =
    useState<ProjectNumberIdMappingType[]>();

  // Azure Category to Recommender Mapping
  const [azureCategoryRecommenderMapping, setAzureCategoryRecommenderMapping] =
    useState<CategoryRecommenderMappingType[]>([]);
  const [
    azureCategoryRecommenderMappingRequestStatus,
    setAzureCategoryRecommenderMappingRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  // Estimated Monthly Savings Per Category
  const [
    estimatedMonthlySavingsPerCategoryData,
    setEstimatedMonthlySavingsPerCategoryData,
  ] = useState<MonthlyCostByCategory[]>([]);
  const [
    isEstimatedMonthlySavingsPerCategoryTableView,
    setIsEstimatedMonthlySavingsPerCategoryTableView,
  ] = useState(false);
  const [
    estimatedMonthlySavingsPerCategoryRequestStatus,
    setEstimatedMonthlySavingsPerCategoryRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Estimated Savings Per Project
  const [
    cspEstimatedSavingsPerProjectData,
    setCspEstimatedSavingsPerProjectData,
  ] = useState<CostByProject[]>([]);
  const [estimatedSavingsPerProjectData, setEstimatedSavingsPerProjectData] =
    useState<CostByProject[]>([]);
  const [
    isEstimatedSavingsPerProjectTableView,
    setIsEstimatedSavingsPerProjectTableView,
  ] = useState(false);
  const [
    estimatedSavingsPerProjectRequestStatus,
    setEstimatedSavingsPerProjectRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Consumption Management Savings
  const [
    consumptionManagementSavingsData,
    setConsumptionManagementSavingsData,
  ] = useState<ColouredMonthlyCostByRecommender[]>([]);
  const [
    cspConsumptionManagementSavingsData,
    setCspConsumptionManagementSavingsData,
  ] = useState<ColouredMonthlyCostByRecommender[]>([]);
  const [
    consumptionManagementSavingsRequestStatus,
    setConsumptionManagementSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Waste management savings
  const [wasteManagementSavingsData, setWasteManagementSavingsData] = useState<
    ColouredMonthlyCostByRecommender[]
  >([]);
  const [
    wasteManagementSavingsRequestStatus,
    setWasteManagementSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Purchase Tactics Savings
  const [purchaseTacticsSavingsData, setPurchaseTacticsSavingsData] = useState<
    ColouredMonthlyCostByRecommender[]
  >([]);
  const [
    purchaseTacticsSavingsRequestStatus,
    setPurchaseTacticsSavingsRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  // Saving details by category filters
  const [selectedRecommender, setSelectedRecommender] = useState('');

  // Saving details by category data
  const [selectedGraphCategory, setSelectedGraphCategory] = useState<string>(
    RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
  );
  const [detailedRecomSelectedDateRange, setDetailedRecomSelectedDateRange] =
    useState<RangeValue<Moment>>([
      moment().subtract(2, 'month').startOf('month'),
      moment().endOf('day'),
    ]);
  const [detailedSavingsData, setDetailedSavingsData] = useState<any[]>([]);
  const [detailedSavingsRequestStatus, setDetailedSavingsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  // Savings plan trend
  const [savingsPlanTrendData, setSavingsPlanTrendData] = useState<
    CostByNameType[]
  >([]);
  const [savingsPlanTrendRequestStatus, setSavingsPlanTrendRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [savingsPlanTrendTableView, setSavingsPlanTrendTableView] =
    useState(false);
  const [savingsPlanTrendStartMonth, setSavingsPlanTrendStartMonth] = useState(
    moment().startOf('month').subtract(6, 'month').format(HYPHEN_DATE_FORMAT)
  );
  const [savingsPlanTrendEndMonth, setSavingsPlanTrendEndMonth] = useState(
    moment().format(HYPHEN_DATE_FORMAT)
  );

  // Granulate
  const [granulateCostImpact, setGranulateCostImpact] = useState<
    GranulateCostImpactType[]
  >([]);
  const [granulateCostImpactReqStatus, setGranulateCostImpactReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [granulateConnections, setGranulateConnections] = useState<
    GranulateConnectionsType[]
  >([]);

  useEffect(() => {
    addZeroMarginClass('dashboard-view');
    if (selectedDashboard!.connectorProvider === PROVIDER.GCP) {
      setProjectNumberIdMapping(undefined);
      getAllProjectNumberIdMapping();
    } else {
      setAzureCategoryRecommenderMapping([]);
      getAllAzureRecommenderMapping();
    }

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    if (
      permissions.thirdPartyAppsRead &&
      selectedDashboard?.dashBoardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION
    ) {
      fetchGranulateCostImpact(
        selectedDashboard!.connectorId,
        setGranulateCostImpact,
        setGranulateCostImpactReqStatus
      );

      fetchGranulateConnections(
        selectedDashboard!.connectorId,
        setGranulateConnections
      );
    }
  }, [selectedDashboard]);

  useEffect(() => {
    fetchRulesetFilters();
  }, [selectedRulesets]);

  useEffect(() => {
    if (
      ((selectedDashboard!.connectorProvider === PROVIDER.AZURE &&
        azureCategoryRecommenderMapping.length) ||
        selectedDashboard!.connectorProvider !== PROVIDER.AZURE) &&
      selectedGraphCategory
    ) {
      setSelectedRecommender(
        getAllRecommenderOptionsByProvider(
          selectedDashboard!.connectorProvider,
          selectedGraphCategory,
          azureCategoryRecommenderMapping
        ).at(0)!.value
      );
    }
  }, [selectedGraphCategory, azureCategoryRecommenderMapping]);

  useEffect(() => {
    if (selectedDashboard!.connectorProvider !== PROVIDER.GCP) return;
    setCspEstimatedSavingsPerProjectData([]);
    setWasteManagementSavingsData([]);
    setCspConsumptionManagementSavingsData([]);
    setPurchaseTacticsSavingsData([]);
    setEstimatedMonthlySavingsPerCategoryData([]);
    setAzureCategoryRecommenderMappingRequestStatus(REQUEST_STATUS.SUCCESS);
    if (projectNumberIdMapping) {
      fetchEstimatedSavingsPerProjectData();
      fetchGcpMonthlySavingsByCategoryData(
        RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
        setWasteManagementSavingsData,
        setWasteManagementSavingsRequestStatus
      );
      fetchGcpMonthlySavingsByCategoryData(
        RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
        setCspConsumptionManagementSavingsData,
        setConsumptionManagementSavingsRequestStatus
      );
      fetchGcpMonthlySavingsByCategoryData(
        RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
        setPurchaseTacticsSavingsData,
        setPurchaseTacticsSavingsRequestStatus
      );
    }
  }, [projectNumberIdMapping, rulesetFilters]);

  useEffect(() => {
    setEstimatedSavingsPerProjectData(
      getConsolidatedEstimatedSavingsPerProject(
        cspEstimatedSavingsPerProjectData,
        granulateCostImpact
      )
    );
  }, [cspEstimatedSavingsPerProjectData, granulateCostImpact]);

  useEffect(() => {
    if (
      selectedDashboard!.connectorProvider !== PROVIDER.AZURE ||
      !azureCategoryRecommenderMapping.length
    )
      return;
    fetchAzureSavingsByCategoriesData(
      selectedDashboard!.connectorId,
      azureCategoryRecommenderMapping,
      setEstimatedMonthlySavingsPerCategoryRequestStatus,
      setEstimatedMonthlySavingsPerCategoryData,
      isDashboardWithStaticData(selectedDashboard)
    );
    fetchAzureMonthlySavingsByEachCategoryData(
      selectedDashboard!.connectorId,
      azureCategoryRecommenderMapping,
      {
        setData: setWasteManagementSavingsData,
        setRequestStatus: setWasteManagementSavingsRequestStatus,
      },
      {
        setData: setCspConsumptionManagementSavingsData,
        setRequestStatus: setConsumptionManagementSavingsRequestStatus,
      },
      {
        setData: setPurchaseTacticsSavingsData,
        setRequestStatus: setPurchaseTacticsSavingsRequestStatus,
      },
      isDashboardWithStaticData(selectedDashboard)
    );
  }, [azureCategoryRecommenderMapping]);

  useEffect(() => {
    setDetailedSavingsData([]);
    if (
      selectedRecommender &&
      selectedRecommender !== RecommendationSource.GRANULATE &&
      selectedDashboard!.connectorProvider === PROVIDER.GCP &&
      projectNumberIdMapping
    ) {
      fetchGcpDetailedSavingsData();
    }
  }, [
    rulesetFilters,
    projectNumberIdMapping,
    selectedRecommender,
    detailedRecomSelectedDateRange,
  ]);

  useEffect(() => {
    setDetailedSavingsData([]);
    if (
      selectedRecommender &&
      selectedDashboard!.connectorProvider === PROVIDER.AZURE &&
      azureCategoryRecommenderMapping.length &&
      detailedRecomSelectedDateRange
    ) {
      fetchAzureDetailedSavingsData(
        selectedDashboard!.connectorId,
        selectedRecommender,
        detailedRecomSelectedDateRange[0]!.format(HYPHEN_DATE_FORMAT),
        detailedRecomSelectedDateRange[1]!.format(HYPHEN_DATE_FORMAT),
        setDetailedSavingsData,
        setDetailedSavingsRequestStatus,
        isDashboardWithStaticData(selectedDashboard)
      );
    }
  }, [
    azureCategoryRecommenderMapping,
    selectedRecommender,
    detailedRecomSelectedDateRange,
  ]);

  useEffect(() => {
    if (
      selectedConnection &&
      selectedRecommender === RecommendationSource.GRANULATE
    ) {
      getAndSetGranulateRecommendations(
        selectedConnection,
        granulateConnections,
        setDetailedSavingsData,
        setDetailedSavingsRequestStatus
      );
    }
  }, [granulateConnections, selectedRecommender, selectedConnection]);

  useEffect(() => {
    const wasteManagementTotalData: number[] = [];
    const consumptionManagementTotalData: number[] = [];
    const purchaseTacticsTotalData: number[] = [];

    getLastNMonthLabels(3).forEach((month, index) => {
      let wasteManagementCostByMonth = 0;
      let consumptionManagementCostByMonth = 0;
      let purchaseTacticsCostByMonth = 0;
      wasteManagementSavingsData.forEach((data) => {
        wasteManagementCostByMonth += data.cost[index];
      });
      consumptionManagementSavingsData.forEach((data) => {
        consumptionManagementCostByMonth += data.cost[index];
      });
      purchaseTacticsSavingsData.forEach((data) => {
        purchaseTacticsCostByMonth += data.cost[index];
      });
      wasteManagementTotalData.push(wasteManagementCostByMonth);
      consumptionManagementTotalData.push(consumptionManagementCostByMonth);
      purchaseTacticsTotalData.push(purchaseTacticsCostByMonth);
    });

    setEstimatedMonthlySavingsPerCategoryData([
      {
        category: getDetailedSavingsTableHeading(
          RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
        ),
        cost: wasteManagementTotalData,
      },
      {
        category: getDetailedSavingsTableHeading(
          RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT
        ),
        cost: consumptionManagementTotalData,
      },
      {
        category: getDetailedSavingsTableHeading(
          RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS
        ),
        cost: purchaseTacticsTotalData,
      },
    ]);
    setEstimatedMonthlySavingsPerCategoryRequestStatus(REQUEST_STATUS.SUCCESS);
  }, [
    wasteManagementSavingsData,
    consumptionManagementSavingsData,
    purchaseTacticsSavingsData,
    granulateCostImpact,
  ]);

  useEffect(() => {
    setConsumptionManagementSavingsData(
      getConsolidatedConsumptionManagementCost(
        permissions,
        selectedDashboard!,
        cspConsumptionManagementSavingsData,
        granulateCostImpact
      )
    );
  }, [cspConsumptionManagementSavingsData, granulateCostImpact]);

  useEffect(() => {
    if (!isDashboardWithStaticData(selectedDashboard))
      fetchSavingsPlanTrendData(
        setSavingsPlanTrendRequestStatus,
        selectedDashboard!,
        selectedConnection!,
        savingsPlanTrendStartMonth,
        savingsPlanTrendEndMonth,
        setSavingsPlanTrendData
      );
  }, [selectedDashboard, savingsPlanTrendStartMonth, savingsPlanTrendEndMonth]);

  /**
   * @function getAllProjectNumberIdMapping
   * @description Function to fetch project Number to Id mapping.
   */
  const getAllProjectNumberIdMapping = () => {
    getGCPProjectNumberIdMapping(selectedDashboard!.connectorId)
      .then((res: any) => {
        if (res?.status === 200) {
          setProjectNumberIdMapping(res?.data?.responseData ?? []);
          return;
        }
        setProjectNumberIdMapping([]);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setProjectNumberIdMapping([]);
      });
  };

  /**
   * @function getAllAzureRecommenderMapping
   * @description Function to fetch all the available filters for connection.
   */
  const getAllAzureRecommenderMapping = () => {
    setAzureCategoryRecommenderMappingRequestStatus(REQUEST_STATUS.PROCESSING);

    getAzureCategoryRecommenderMapping()
      .then((res: any) => {
        if (res?.status === 200) {
          setAzureCategoryRecommenderMapping(res.data.responseData);
          setAzureCategoryRecommenderMappingRequestStatus(
            REQUEST_STATUS.SUCCESS
          );
          return;
        }
        setAzureCategoryRecommenderMappingRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) =>
        onApiCallError(
          e,
          false,
          setAzureCategoryRecommenderMappingRequestStatus
        )
      );
  };

  /**
   * @function fetchRulesetFilters
   * @description Function to fetch the rulesets filters
   */
  const fetchRulesetFilters = () => {
    if (!selectedRulesets || selectedRulesets.length === 0) {
      setRulesetFilters([]);
      return;
    }

    setRulesetFiltersRequestStatus(REQUEST_STATUS.PROCESSING);

    axios
      .all(
        selectedRulesets.map((ruleset) =>
          getRulesetFilters({ rulesetId: ruleset.rulesetId })
        )
      )
      .then((res) => {
        const filters: FilterGroupType[] = [];
        res?.forEach((item: any) => {
          filters.push(...(item?.data?.responseData ?? []));
        });
        setRulesetFilters(filters);
        setRulesetFiltersRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, true, setRulesetFiltersRequestStatus);
      });
  };

  /**
   * @function fetchGcpMonthlySavingsByCategoryData
   * @description Function to fetch and set the monthly savings by categories data for GCP
   * @param category Category for which the cost data is fetched
   * @param setData callback function for setting the data
   * @param setRequestStatus Callback function for setting the request status
   */
  const fetchGcpMonthlySavingsByCategoryData = (
    category: string,
    setData: (val: ColouredMonthlyCostByRecommender[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    const categoryRecommenders = fetchRecommenderCategoryTypes(category);
    const body = getMonthlySavingsByCategoryRequestBody(
      categoryRecommenders.map((item) => item.key),
      moment(getLastNMonthLabels(3)[0], MONTH_YEAR_FORMAT).format(
        HYPHEN_DATE_FORMAT
      ),
      moment().format(HYPHEN_DATE_FORMAT),
      rulesetFilters,
      isDashboardWithStaticData(selectedDashboard)
    );
    const params = {
      connectorId: selectedDashboard!.connectorId,
    };

    getFilteredRecommendations(body, params)
      .then((res: any) => {
        let data: ColouredMonthlyCostByRecommender[] = [];
        const graphColors = generateGraphColors(categoryRecommenders.length);

        categoryRecommenders.forEach((recommenderObj, index) => {
          const currentRecommenderResData = res?.data?.filter(
            (responseData: any) =>
              responseData.recommender === recommenderObj.key
          );
          const monthlyCostsByRecommender: number[] = [];
          getLastNMonthLabels(3).forEach((month) => {
            const currentMonthResData = currentRecommenderResData?.find(
              (responseData: any) =>
                moment(responseData.month, YEAR_MONTH_WITHOUT_ZERO).format(
                  MONTH_YEAR_FORMAT
                ) === month
            );
            monthlyCostsByRecommender.push(
              currentMonthResData?.totalPotentialSavings || 0
            );
          });
          data.push({
            recommender: recommenderObj.label,
            cost: monthlyCostsByRecommender,
            color: graphColors[index],
          });
        });
        setData(data);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, true, setRequestStatus));
  };

  /**
   * @function fetchEstimatedSavingsPerProjectData
   * @description Function to fetch estimated savings per project
   */
  const fetchEstimatedSavingsPerProjectData = () => {
    setEstimatedSavingsPerProjectRequestStatus(REQUEST_STATUS.PROCESSING);
    const body = getEstimatedSavingsPerProjectRequestBody(
      moment(getLastNMonthLabels(3)[0], MONTH_YEAR_FORMAT).format(
        HYPHEN_DATE_FORMAT
      ),
      moment().format(HYPHEN_DATE_FORMAT),
      rulesetFilters,
      isDashboardWithStaticData(selectedDashboard)
    );
    const params = {
      connectorId: selectedDashboard!.connectorId,
    };

    getFilteredRecommendations(body, params)
      .then((res: any) => {
        const data: any[] = res?.data || [];
        const modifiedData = data.map((item) => ({
          project:
            projectNumberIdMapping?.find(
              (eachId) =>
                eachId.projectNumber.toString() ===
                item?.projectNumber.toString()
            )?.projectId ?? item?.projectNumber,
          cost: item?.totalPotentialSavings,
        }));
        setCspEstimatedSavingsPerProjectData(modifiedData);
        setEstimatedSavingsPerProjectRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, true, setEstimatedSavingsPerProjectRequestStatus)
      );
  };

  /**
   * @function fetchGcpDetailedSavingsData
   * @description Function to fetch the detailed savings
   */
  const fetchGcpDetailedSavingsData = () => {
    setDetailedSavingsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedDashboard!.connectorId,
    };
    getFilteredRecommendations(
      getDetailedSavingsRequestBody(
        selectedRecommender,
        detailedRecomSelectedDateRange,
        rulesetFilters,
        isDashboardWithStaticData(selectedDashboard)
      ),
      params
    )
      .then((res: any) => {
        const data: any[] = res?.data || [];
        const modifiedData = data.map((item) => ({
          ...item,
          projectName:
            projectNumberIdMapping?.find(
              (eachId) =>
                eachId.projectNumber.toString() ===
                item?.projectNumber.toString()
            )?.projectId ?? item?.projectNumber,
          recommendationName: item.recommendationName?.substring(
            item.recommendationName?.lastIndexOf('/') + 1
          ),
          state:
            RECOMMENDATION_STATUS_LABELS.find(
              (state) => state.key === item?.recommendationState
            )?.label ?? item?.recommendationState,
          recommender_subtype:
            RECOMMENDATION_SUBTYPE_LABELS.find(
              (subtypeObj) => subtypeObj.key === item.recommender_subtype
            )?.label ?? item.recommender_subtype,
          cost: item?.costSavings,
          lastRefreshed: moment(item.lastRefreshed).format(DATE_TIME_AM_PM),
        }));
        setDetailedSavingsData(modifiedData);
        setDetailedSavingsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setDetailedSavingsRequestStatus);
        setDetailedSavingsData([]);
      });
  };

  /**
   * @function onChangeDetailedRecomTableDateRange
   * @description Callback function for detailed recommendation table date range selection
   * @param dates date range selected
   */
  const onChangeDetailedRecomTableDateRange = (dates: RangeValue<Moment>) => {
    if (!dates?.[0] || !dates?.[1]) {
      return;
    }

    setDetailedRecomSelectedDateRange(dates);
  };

  /**
   * @function getDetailedSavingsTableHeading
   * @description Function to get the detailed savings table heading
   * @param graphCategory Recommendation category for which the heading is required
   * @returns Detailed savings table heading
   */
  const getDetailedSavingsTableHeading = (graphCategory = '') => {
    switch (graphCategory || selectedGraphCategory) {
      case RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT:
        return t('gcpRecommendationSummary.wasteManagement');
      case RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT:
        return t('gcpRecommendationSummary.consumptionManagement');
      case RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS:
        return t('gcpRecommendationSummary.purchaseTactics');
      default:
        return '';
    }
  };

  const getGraphComponent = (componentName: string, pdfView = false) => {
    switch (componentName) {
      case 'estimated-monthly-savings-per-category':
        return (
          <EstimatedMonthlySavingsPerCategory
            isEstimatedMonthlySavingsPerCategoryTableView={
              isEstimatedMonthlySavingsPerCategoryTableView
            }
            setEstimatedMonthlySavingsPerCategoryTableView={
              setIsEstimatedMonthlySavingsPerCategoryTableView
            }
            requestStatus={[
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
              azureCategoryRecommenderMappingRequestStatus,
              estimatedMonthlySavingsPerCategoryRequestStatus,
              granulateCostImpactReqStatus,
            ]}
            estimatedMonthlySavingsPerCategoryData={
              estimatedMonthlySavingsPerCategoryData
            }
            estimatedMonthlySavingsPerCategoryLabels={getLastNMonthLabels(3)}
            pdfView={pdfView}
          />
        );
      case 'estimated-savings-per-project':
        return (
          <EstimatedSavingsPerProject
            pdfView={pdfView}
            requestStatus={[
              estimatedSavingsPerProjectRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
              granulateCostImpactReqStatus,
            ]}
            estimatedSavingsPerProjectData={estimatedSavingsPerProjectData}
            isEstimatedSavingsPerProjectTableView={
              isEstimatedSavingsPerProjectTableView
            }
            setIsEstimatedSavingsPerProjectTableView={
              setIsEstimatedSavingsPerProjectTableView
            }
          />
        );
      case RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT:
        return (
          <SavingsPerCategory
            requestStatus={[
              wasteManagementSavingsRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
              azureCategoryRecommenderMappingRequestStatus,
            ]}
            savingsPerCategoryData={wasteManagementSavingsData}
            savingsPerCategoryLabels={getLastNMonthLabels(3)}
            pdfView={pdfView}
            graphHeading={t('gcpRecommendationSummary.wasteManagement')}
            graphName={RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT}
            selectedGraphCategory={selectedGraphCategory}
            setSelectedGraphCategory={setSelectedGraphCategory}
          />
        );
      case RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT:
        return (
          <SavingsPerCategory
            requestStatus={[
              consumptionManagementSavingsRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
              azureCategoryRecommenderMappingRequestStatus,
              granulateCostImpactReqStatus,
            ]}
            savingsPerCategoryData={consumptionManagementSavingsData}
            savingsPerCategoryLabels={getLastNMonthLabels(3)}
            pdfView={pdfView}
            graphHeading={t('gcpRecommendationSummary.consumptionManagement')}
            graphName={RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT}
            selectedGraphCategory={selectedGraphCategory}
            setSelectedGraphCategory={setSelectedGraphCategory}
          />
        );
      case RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS:
        return (
          <SavingsPerCategory
            requestStatus={[
              purchaseTacticsSavingsRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
              azureCategoryRecommenderMappingRequestStatus,
            ]}
            savingsPerCategoryData={purchaseTacticsSavingsData}
            savingsPerCategoryLabels={getLastNMonthLabels(3)}
            pdfView={pdfView}
            graphHeading={t('gcpRecommendationSummary.purchaseTactics')}
            graphName={RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS}
            selectedGraphCategory={selectedGraphCategory}
            setSelectedGraphCategory={setSelectedGraphCategory}
          />
        );
      case 'detailed-savings-table':
        return (
          <DetailedSavingsTable
            detailedSavingsData={detailedSavingsData}
            graphHeading={getDetailedSavingsTableHeading()}
            requestStatus={[
              detailedSavingsRequestStatus,
              rulesetsRequestStatus,
              rulesetFiltersRequestStatus,
            ]}
            pdfView={pdfView}
            allRecommenders={getAllRecommenderOptionsByProvider(
              selectedDashboard!.connectorProvider,
              selectedGraphCategory,
              azureCategoryRecommenderMapping
            )}
            selectedRecommender={selectedRecommender}
            setSelectedRecommender={setSelectedRecommender}
            selectedDateRange={detailedRecomSelectedDateRange}
            onChangeDateRange={onChangeDetailedRecomTableDateRange}
            dropdownFilterRequestStatus={evaluateRequestArray([
              wasteManagementSavingsRequestStatus,
              consumptionManagementSavingsRequestStatus,
              purchaseTacticsSavingsRequestStatus,
            ])}
            selectedGraphCategory={selectedGraphCategory}
          />
        );

      case 'savings-plan-trend':
        return (
          <LineChartWithTable
            graphTitle={t('graphHeadings.savingsPlanTrend')}
            graphName="savings-plan-trend"
            data={savingsPlanTrendData.map((item) => ({
              ...item,
              type: t('savingsEffectiveness'),
            }))}
            chartProperties={{
              xTitle: t('months'),
              yTitle: t('savingsEffectiveness'),
            }}
            columns={SavingsPlanTableColumns}
            requestStatus={savingsPlanTrendRequestStatus}
            isTableView={savingsPlanTrendTableView}
            setIsTableView={setSavingsPlanTrendTableView}
            pdfView={pdfView}
            suffixSymbol="%"
            filters={[
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: savingsPlanTrendStartMonth,
                  filterEndDate: savingsPlanTrendEndMonth,
                  onChangeDateRange: getMonthRangeHandler(
                    setSavingsPlanTrendStartMonth,
                    setSavingsPlanTrendEndMonth
                  ),
                  format: MONTH_YEAR_FORMAT,
                  picker: 'month',
                },
              },
            ]}
          />
        );
    }
  };

  /**
   * @function getEstimatedMonthlySavingsPerCategoryExportData
   * @description function to get estimated monthly savings per category pdf table data
   * @returns table data for estimated monthly savings per category as array
   */
  const getEstimatedMonthlySavingsPerCategoryExportData = () => {
    let tableData: any[] = [];
    estimatedMonthlySavingsPerCategoryData.forEach((value) => {
      let data: any = {};
      data['category'] = value.category;
      getLastNMonthLabels(3).forEach((label, index) => {
        data[label] = currencySymbol + numberCommaSeparator(value.cost[index]);
      });
      tableData.push(data);
    });
    return tableData;
  };

  /**
   * @function getMonthlySavingsOfCategoryPdfTableData
   * @description function to get estimated monthly savings per category pdf table data
   * @returns table data for estimated monthly savings per category as array
   */
  const getMonthlySavingsOfCategoryExportTableData = (
    monthlySavingsData: ColouredMonthlyCostByRecommender[]
  ) => {
    let tableData: any[] = [];
    getLastNMonthLabels(3).forEach((label, index) => {
      monthlySavingsData.forEach((value) =>
        tableData.push({
          month: label,
          category: value.recommender,
          cost: value.cost[index] || 0,
        })
      );
    });
    return tableData;
  };

  const SavingsPlanTrendRow: GraphProperties[] = [
    {
      element: getGraphComponent('savings-plan-trend', true),
      graphHeading: t('graphHeadings.savingsPlanTrend'),
      contentType: CHART_TYPES.LINE_CHART,
      graphName: 'savings-plan-trend',
      criteria: [ChartCriteria.SAVINGS_PLAN],
      colSpanWidth: 12,
      chartView: {
        xAxisLabel: t('months'),
        yAxisLabel: t('savingsEffectiveness'),
      },
      tableView: {
        columns: SavingsPlanTableColumns,
        isTableView: savingsPlanTrendTableView,
        setTableView: setSavingsPlanTrendTableView,
      },
      pdfExport: {
        tableData: savingsPlanTrendData,
      },
      excelExport: {
        excelData: savingsPlanTrendData,
        excelFilters: {
          connectionName: selectedDashboard!.name,
        },
      },
      pptExport: {
        suffixSymbol: '%',
        pptData: [
          {
            name: t('savingsEffectiveness'),
            labels: savingsPlanTrendData.map((item) => item.name),
            values: savingsPlanTrendData.map((item) => item.cost),
          },
        ],
      },
    },
  ];

  const GraphRows: GraphRowContentType = useMemo(
    () => [
      [
        {
          element: getGraphComponent(
            'estimated-monthly-savings-per-category',
            true
          ),
          graphHeading: t('graphHeadings.estimatedMonthlySavingsPerCategory'),
          contentType: CHART_TYPES.LINE_CHART,
          graphName: 'estimated-monthly-savings-per-category',
          criteria: [ChartCriteria.TIME_PERIOD],
          colSpanWidth: 16,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getEstimatedMonthlySavingsPerCategoryColumns(
              getLastNMonthLabels(3)
            ),
            isTableView: isEstimatedMonthlySavingsPerCategoryTableView,
            setTableView: setIsEstimatedMonthlySavingsPerCategoryTableView,
          },
          pdfExport: {
            tableData: getEstimatedMonthlySavingsPerCategoryExportData(),
          },
          excelExport: {
            excelData: getEstimatedMonthlySavingsPerCategoryExportData(),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: estimatedMonthlySavingsPerCategoryData.map((item) => ({
              name: item.category,
              labels: getLastNMonthLabels(3),
              values: item.cost,
            })),
          },
        },
        ...(hasSavingsByProjectChart(selectedDashboard!.connectorProvider)
          ? [
              {
                element: getGraphComponent(
                  'estimated-savings-per-project',
                  true
                ),
                graphHeading: t('graphHeadings.ytdEstimatedSavingsPerProject'),
                contentType: CHART_TYPES.HORIZONTAL_BAR_CHART,
                graphName: 'estimated-savings-per-project',
                criteria: [ChartCriteria.PROJECT],
                colSpanWidth: 8,
                chartView: {
                  xAxisLabel: t('costInCurrency', { currencySymbol }),
                  yAxisLabel: t('project'),
                },
                tableView: {
                  columns: EstimatedSavingsPerProjectColumns,
                  isTableView: isEstimatedSavingsPerProjectTableView,
                  setTableView: setIsEstimatedSavingsPerProjectTableView,
                },
                pdfExport: {
                  tableData: estimatedSavingsPerProjectData.map((item) => ({
                    ...item,
                    cost: currencySymbol + numberCommaSeparator(item.cost),
                  })),
                },
                excelExport: {
                  excelData: estimatedSavingsPerProjectData,
                  excelFilters: {
                    connectionName: selectedDashboard!.name,
                  },
                },
                pptExport: {
                  pptData: [
                    {
                      name: t('costInCurrency', { currencySymbol }),
                      labels: estimatedSavingsPerProjectData.map(
                        (item) => item.project
                      ),
                      values: estimatedSavingsPerProjectData.map(
                        (item) => item.cost
                      ),
                    },
                  ],
                },
              },
            ]
          : []),
      ],
      isDashboardWithStaticData(selectedDashboard) ||
      !hasSavingsPlanTrendChart(selectedDashboard!.connectorProvider)
        ? []
        : SavingsPlanTrendRow,
      [
        {
          element: getGraphComponent(
            RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
            true
          ),
          graphHeading: t('awsRecommendationSummary.wasteManagement'),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
          criteria: [ChartCriteria.RECOMMENDATION],
          colSpanWidth:
            selectedGraphCategory === RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT
              ? 10
              : 7,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: CategorySavingsExportColumns,
          },
          pdfExport: {
            tableData: getMonthlySavingsOfCategoryExportTableData(
              wasteManagementSavingsData
            ).map((item) => ({
              ...item,
              cost: currencySymbol + numberCommaSeparator(item.cost),
            })),
          },
          excelExport: {
            excelData: getMonthlySavingsOfCategoryExportTableData(
              wasteManagementSavingsData
            ),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: wasteManagementSavingsData.map((item) => ({
              name: item.recommender,
              labels: getLastNMonthLabels(3),
              values: item.cost,
            })),
          },
        },
        {
          element: getGraphComponent(
            RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
            true
          ),
          graphHeading: t('awsRecommendationSummary.consumptionManagement'),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
          criteria: [ChartCriteria.RECOMMENDATION],
          colSpanWidth:
            selectedGraphCategory ===
            RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT
              ? 10
              : 7,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: CategorySavingsExportColumns,
          },
          pdfExport: {
            tableData: getMonthlySavingsOfCategoryExportTableData(
              consumptionManagementSavingsData
            ).map((item) => ({
              ...item,
              cost: currencySymbol + numberCommaSeparator(item.cost),
            })),
          },
          excelExport: {
            excelData: getMonthlySavingsOfCategoryExportTableData(
              consumptionManagementSavingsData
            ),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: consumptionManagementSavingsData.map((item) => ({
              name: item.recommender,
              labels: getLastNMonthLabels(3),
              values: item.cost,
            })),
          },
        },
        {
          element: getGraphComponent(
            RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
            true
          ),
          graphHeading: t('awsRecommendationSummary.purchaseTactics'),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
          criteria: [ChartCriteria.RECOMMENDATION],
          colSpanWidth:
            selectedGraphCategory === RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS
              ? 10
              : 7,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: CategorySavingsExportColumns,
          },
          pdfExport: {
            tableData: getMonthlySavingsOfCategoryExportTableData(
              purchaseTacticsSavingsData
            ).map((item) => ({
              ...item,
              cost: currencySymbol + numberCommaSeparator(item.cost),
            })),
          },
          excelExport: {
            excelData: getMonthlySavingsOfCategoryExportTableData(
              purchaseTacticsSavingsData
            ),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: purchaseTacticsSavingsData.map((item) => ({
              name: item.recommender,
              labels: getLastNMonthLabels(3),
              values: item.cost,
            })),
          },
        },
      ],
      [
        {
          element: getGraphComponent('detailed-savings-table', true),
          graphHeading:
            getDetailedSavingsTableHeading() +
            ` - ${getSelectedRecommenderLabel(
              selectedDashboard!.connectorProvider,
              selectedGraphCategory,
              selectedRecommender
            )}`,
          contentType: CHART_TYPES.TABLE,
          graphName: 'detailed-savings-table',
          criteria: [ChartCriteria.RECOMMENDATION],
          tableView: {
            columns: getDetailedSavingsTableColumns(
              selectedDashboard!.connectorProvider,
              selectedRecommender,
              true
            ),
          },
          pdfExport: {
            tableData: getDetailsSavingsTableDataForExport(
              selectedRecommender,
              detailedSavingsData
            ).map((item) => ({
              ...item,
              cost: currencySymbol + numberCommaSeparator(item.cost),
            })),
          },
          excelExport: {
            excelData: getDetailsSavingsTableDataForExport(
              selectedRecommender,
              detailedSavingsData
            ),
            excelFilters: {
              connectionName: selectedDashboard!.name,
              startDate:
                detailedRecomSelectedDateRange?.[0]?.format(MONTH_YEAR_FORMAT),
              endDate:
                detailedRecomSelectedDateRange?.[1]?.format(MONTH_YEAR_FORMAT),
            },
          },
          pptExport: {
            pptData: [
              getDetailedSavingsTableColumns(
                selectedDashboard!.connectorProvider,
                selectedRecommender,
                true
              ).map((column) => column.title),
              ...getDetailsSavingsTableDataForExport(
                selectedRecommender,
                detailedSavingsData
              ).map((item) =>
                getDetailedSavingsTableColumns(
                  selectedDashboard!.connectorProvider,
                  selectedRecommender,
                  true
                ).map((column) => item[column.dataIndex])
              ),
            ],
          },
        },
      ],
    ],
    [
      consumptionManagementSavingsData,
      detailedRecomSelectedDateRange,
      detailedSavingsData,
      estimatedMonthlySavingsPerCategoryData,
      estimatedSavingsPerProjectData,
      isEstimatedMonthlySavingsPerCategoryTableView,
      isEstimatedSavingsPerProjectTableView,
      purchaseTacticsSavingsData,
      selectedGraphCategory,
      selectedRecommender,
      wasteManagementSavingsData,
    ]
  );

  return (
    <div>
      <CostRecomSummaryDashboardControls
        chartCriteriaStates={{
          chartCriteriaFilters: chartCriteriaFilters,
          setChartCriteriaFilters: setChartCriteriaFilters,
        }}
        rulesetStates={{
          selectedRulesets: selectedRulesets,
          setSelectedRulesets: setSelectedRulesets,
          rulesetsRequestStatus: rulesetsRequestStatus,
          setRulesetsRequestStatus: setRulesetsRequestStatus,
        }}
      />
      <div className="gcp-recommendation-summary inner-dashboard-content flex flex-column flex-gap-24">
        <DashboardGraphContainer
          getGraphComponent={getGraphComponent}
          chartCriteriaFilters={chartCriteriaFilters}
          rows={GraphRows}
          pdfMetaData={getPdfMetaData(
            t('dashNav.costRecommendationSummary'),
            selectedDashboard!,
            selectedConnection!
          )}
        />
      </div>
    </div>
  );
};

export default CostRecommendationSummaryDashboard;
