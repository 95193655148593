import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigationMenuDataState } from 'types/navigationMenu';

import { RootState } from './store';

export interface ActiveNavMenuState {
  id: string;
  isNavClosed?: boolean;
  isNavPinned?: boolean;
  isSubMenuOpen?: boolean;
  showMenuWithTitle: boolean;
  navSubPath: string[];
  expandedMenu: NavigationMenuDataState | undefined;
  selectedParentMenu: string | undefined;
}

const initialState: ActiveNavMenuState = {
  id: '',
  isNavClosed: true,
  isNavPinned: false,
  isSubMenuOpen: false,
  showMenuWithTitle: false,
  navSubPath: [],
  expandedMenu: undefined,
  selectedParentMenu: undefined,
};

export const activeNavMenuSlice = createSlice({
  name: 'activeNavMenuSlice',
  initialState,
  reducers: {
    setActiveNavId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    closeNavMenu: (state, action: PayloadAction<boolean>) => {
      state.isNavClosed = action.payload;
    },
    setNavPinned: (state, action: PayloadAction<boolean>) => {
      state.isNavPinned = action.payload;
    },
    setSubMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isSubMenuOpen = action.payload;
    },
    setShowMenuWithTitle: (state, action: PayloadAction<boolean>) => {
      state.showMenuWithTitle = action.payload;
    },
    setNavSubPath: (state, action: PayloadAction<string[]>) => {
      state.navSubPath = action.payload;
    },
    setExpandedMenu: (
      state,
      action: PayloadAction<NavigationMenuDataState | undefined>
    ) => {
      state.expandedMenu = action.payload;
    },
    setSelectedParentMenu: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedParentMenu = action.payload;
    },
  },
});

export const selectNavMenu = (state: RootState) => state.activeMenu;
export const {
  setActiveNavId,
  closeNavMenu,
  setNavPinned,
  setSubMenuOpen,
  setShowMenuWithTitle,
  setNavSubPath,
  setExpandedMenu,
  setSelectedParentMenu,
} = activeNavMenuSlice.actions;
export default activeNavMenuSlice.reducer;
