import i18n from 'i18n';

import { ItemOptionsType } from 'components/DropdownCheckbox/types';
import { ChartCriteria } from 'constants/dashboard';
import { store } from 'redux/store';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const TABLE_HEADER_LENGTH_LIMIT = 15;

export const EstimatedSavingsPerAccountColumns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('awsRecommendationSummary.accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: '65%',
  },
  {
    title: i18n.t('awsRecommendationSummary.cost', {
      currencySymbol: currencySymbol,
    }),
    dataIndex: 'cost',
    key: 'cost',
    align: 'right',
    width: '30%',
    render: (text: any) => currencySymbol + numberCommaSeparator(text),
  },
];

export const CHART_CRITERIA_LIST: ItemOptionsType[] = [
  {
    title: i18n.t('timePeriod'),
    value: ChartCriteria.TIME_PERIOD,
  },
  {
    title: i18n.t('account'),
    value: ChartCriteria.ACCOUNT,
  },
  {
    title: i18n.t('recommendation'),
    value: ChartCriteria.RECOMMENDATION,
  },
  {
    title: i18n.t('savingsPlan'),
    value: ChartCriteria.SAVINGS_PLAN,
  },
];
