import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';

import {
  costOptimizationInsights,
  setCurrentRecommendationView,
} from 'redux/costOptimizationInsightsSlice';
import SelectDropdown from 'components/Select';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DEFAULT_VIEW } from 'pages/CostOptimizationInsightsPage/constants';
import { RulesetsType } from 'pages/RuleEnginePage/types';
import AccessibleDiv from 'components/AccessibleDiv';
import { ViewListType } from 'pages/CostOptimizationInsightsPage/types';

import './index.scss';

type ViewDropdownProps = {
  viewList: ViewListType[] | undefined;
  viewListRequestStatus: string;
  rulesets: RulesetsType[];
  rulesetTagOptionsLoading: string;
};

const ViewDropdown = ({
  viewList,
  viewListRequestStatus,
  rulesets,
  rulesetTagOptionsLoading,
}: ViewDropdownProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentRecommendationView, selectedCostOptimizationInsightsNav } =
    useSelector(costOptimizationInsights);

  const ViewDropdownOptions = viewList
    ?.filter(
      (view) =>
        view.recommendationType === selectedCostOptimizationInsightsNav ||
        view.key === DEFAULT_VIEW
    )
    .map((view) => (
      <Select.Option key={view.key} value={view.key}>
        {view.viewName}
      </Select.Option>
    ));

  const getRulesetsWithView = () => {
    return rulesets.filter((ruleset) => ruleset.createViewFilter);
  };

  const RulesetDropdownOptions = (
    <div className="flex flex-column">
      {getRulesetsWithView().map((ruleset) => (
        <AccessibleDiv
          className={`ruleset-options cursor-pointer ${
            currentRecommendationView.key === ruleset.rulesetId
              ? 'font-button'
              : 'font-label'
          }`}
          key={ruleset.rulesetId}
          onClick={() =>
            dispatch(
              setCurrentRecommendationView({
                viewName: ruleset.ruleSetName,
                key: ruleset.rulesetId ?? '',
                recommendationsFilterDtoList: [],
              })
            )
          }
          data-testid={ruleset.ruleSetName}
        >
          {ruleset.ruleSetName}
        </AccessibleDiv>
      ))}
    </div>
  );

  const viewDropdownRender = (menu: JSX.Element) => (
    <div className="filter-view-dropdown styled-scroll">
      <span className="view-type font-subHeader-small">
        {t('costOptimizationInsight.viewDropdown.filterViewLabel')}
      </span>
      <div className="dropdown-container">{menu}</div>
      {getRulesetsWithView().length > 0 && (
        <div>
          <span className="view-type font-subHeader-small">
            {t('costOptimizationInsight.viewDropdown.rulesetDefaultViewLabel')}
          </span>
          <div className="dropdown-container">{RulesetDropdownOptions}</div>
        </div>
      )}
    </div>
  );

  return (
    <SelectDropdown
      value={{
        value: currentRecommendationView.key,
        label: (
          <>
            <span className="font-button">
              {t('costOptimizationInsight.viewDropdown.viewValuePrefix')}
            </span>
            {currentRecommendationView.viewName}
          </>
        ),
      }}
      onSelect={(value: any) => {
        dispatch(
          setCurrentRecommendationView(
            viewList?.find((view) => view.key === value.key)!
          )
        );
      }}
      menu={ViewDropdownOptions}
      dropdownRender={viewDropdownRender}
      labelInValue
      loading={[viewListRequestStatus, rulesetTagOptionsLoading].includes(
        REQUEST_STATUS.PROCESSING
      )}
      className="view-dropdown"
    />
  );
};

export default ViewDropdown;
