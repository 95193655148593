import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { selectCostAllocationDashboard } from 'redux/costAllocationDashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import SelectDropdown from 'components/Select';
import { DashboardListType } from 'types/navigationMenu';
import { PROVIDER } from 'constants/cloudProviders';
import { INPUT_SIZE } from 'constants/appearance';

import './index.scss';

const { Option } = Select;

type DashboardListDropdownProps = {
  selectedDashboard: DashboardListType | null;
  onSelectDropdown: (key: string) => void;
  loadingDropdown: boolean;
};

const DashboardListDropdown = ({
  selectedDashboard,
  onSelectDropdown,
  loadingDropdown,
}: DashboardListDropdownProps) => {
  const { costAllocationDashboardList } = useSelector(
    selectCostAllocationDashboard
  );
  const { theme } = useSelector(selectTheme);

  /**
   * @function getProviderIconClass
   * @description Function to get cloud provider icon class name.
   * @params provider : name of the provider
   * @returns cloud provider icon class
   */
  const getProviderIconClass = (provider: string) => {
    switch (provider) {
      case PROVIDER.GCP:
        return 'gcp-icon';
      case PROVIDER.AWS:
        return 'aws-icon';
      case PROVIDER.AZURE:
        return 'azure-icon';
    }
  };

  const DropdownMenus = (
    <>
      {costAllocationDashboardList.map((dashboard) => {
        return (
          <Option value={dashboard?.id} key={dashboard?.id}>
            <div className="menu-item full-width flex flex-align-items-center flex-gap-8">
              <div className="header-logo-container flex flex-center">
                <div
                  className={`logo flex-fit ${getProviderIconClass(
                    dashboard.connectorProvider
                  )}`}
                ></div>
              </div>
              <div
                className={`flex flex-column font-subHeader ${
                  selectedDashboard?.id === dashboard?.id &&
                  'selected-dropdown-option'
                }`}
                style={{
                  color:
                    selectedDashboard?.id !== dashboard?.id
                      ? theme.textColor
                      : '',
                }}
              >
                <div className="connection-name">
                  {dashboard?.connectorName}
                </div>
                <div className="dashboard-name font-subHeader-small">
                  {dashboard.name}
                </div>
              </div>
            </div>
          </Option>
        );
      })}
    </>
  );

  return (
    <SelectDropdown
      className="cost-allocation-dashboard-dropdown"
      value={selectedDashboard?.id}
      onSelect={onSelectDropdown}
      menu={DropdownMenus}
      loading={loadingDropdown}
      dropdownClassName="cost-allocation-dropdown-options"
      designVersion2
      size={INPUT_SIZE.SMALL}
    />
  );
};

export default DashboardListDropdown;
