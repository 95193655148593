export const GCPDataSourceTypeOptions = [
  {
    value: 'bigQuery',
    title: 'BigQuery',
  },
];

export enum AzureDataSource {
  SYNAPSE = 'synapse',
  SQL = 'sql',
}

export const AzureDataSourceTypeOptions = [
  {
    value: AzureDataSource.SYNAPSE,
    title: 'Synapse dedicated SQL pool',
  },
  {
    value: AzureDataSource.SQL,
    title: 'SQL',
  },
];

export enum AWS_CONNECTION_TYPE {
  ATHENA = 'athena',
}

export const AWSDataSourceTypeOptions = [
  {
    value: AWS_CONNECTION_TYPE.ATHENA,
    title: 'Athena',
  },
];

export const OciDatasourceOptions = [
  {
    value: 'autonomousDataWarehouse',
    title: 'Autonomous Warehouse',
  },
];

export enum PlanType {
  NORMAL = 'NORMAL',
  EDP = 'EDP',
  EA = 'EA',
  MCA = 'MCA',
  MANAGEMENT_GROUP = 'MANAGEMENT_GROUP',
  CSP = 'CSP',
  MOSA = 'MOSA',
}
