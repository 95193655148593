import { CoverageUtilizationExcelMetaData } from 'pages/ScorecardPage/types';
import { ConnectionListType } from 'types/dashboard';
import { getUtilizationCoverageExcelData } from '../CoverageUtilizationGraph/utils';

/**
 * @function getAwsSpExcelData
 * @description Function to return the excel data for export
 * @param overall Overall Utilization and Coverage data containing the sheetName, data and average
 * @param computeAndEc2 Compute + EC2 Utilization and Coverage data containing the sheetName, data and average
 * @param sageMaker SageMaker Utilization and Coverage data containing the sheetName, data and average
 * @param connection connection details of the selected connection
 * @returns List of excel export data
 */
export const getAwsSpExcelData = (
  overall: {
    utilization: CoverageUtilizationExcelMetaData;
    coverage: CoverageUtilizationExcelMetaData;
  },
  computeAndEc2: {
    utilization: CoverageUtilizationExcelMetaData;
    coverage: CoverageUtilizationExcelMetaData;
  },
  sageMaker: {
    utilization: CoverageUtilizationExcelMetaData;
    coverage: CoverageUtilizationExcelMetaData;
  },
  connection: ConnectionListType | null
) => {
  return [
    ...getUtilizationCoverageExcelData(
      overall.utilization,
      overall.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      computeAndEc2.utilization,
      computeAndEc2.coverage,
      connection
    ),
    ...getUtilizationCoverageExcelData(
      sageMaker.utilization,
      sageMaker.coverage,
      connection
    ),
  ];
};
