import { Col, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDidMountEffect from 'hooks/useDidMountEffect';
import { DeleteIconButton } from 'assets/icons';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import { CONJUNCTIONS } from 'constants/requestBody';
import { THRESHOLD_TYPE_LABELS } from 'pages/CostMonitorPage/constants';
import {
  AnamolySubscriptionType,
  ALERT_SUBSCRIPTION_THRESHOLD_TYPE,
  ThresholdType,
} from 'pages/CostMonitorPage/types';
import { isNumber } from 'utils/validations';

type ThresholdRowProps = {
  createButtonClick: boolean;
  threshold: ThresholdType;
  index: number;
  alertSubscriptionDetails: AnamolySubscriptionType;
  updateAlertSubscription: (alertSubscription: AnamolySubscriptionType) => void;
  setAlertSubscriptionsValidation: (val: boolean) => void;
};

const ThresholdRow = ({
  createButtonClick,
  threshold,
  index,
  alertSubscriptionDetails,
  updateAlertSubscription,
  setAlertSubscriptionsValidation,
}: ThresholdRowProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'costMonitor.createCostMonitorLabels.createAlertSubscription',
  });

  const [thresholdValidation, setThresholdValidation] = useState<string>('');

  useEffect(() => {
    setAlertSubscriptionsValidation(
      validateThresholdRow(threshold.thresholdValue, false)
    );
  }, [threshold.thresholdValue]);

  useDidMountEffect(() => {
    validateThresholdRow(threshold.thresholdValue);
  }, [createButtonClick]);

  const ThresholdConjunctionDropDown = (
    <>
      {Object.values(CONJUNCTIONS).map((conj) => (
        <Select.Option key={conj} value={conj}>
          {conj}
        </Select.Option>
      ))}
    </>
  );

  /**
   * @function validateThresholdRow
   * @description Function to validate the fields of a threshold row
   * @param value string value of the threshold row
   * @param setErrorMessage boolean to set the error message
   * @returns boolean true if validation is successful else false
   */
  const validateThresholdRow = (value: string, setErrorMessage = true) => {
    if (isNumber(value)) {
      return validateThresholdRange(value, setErrorMessage);
    } else {
      setErrorMessage && setThresholdValidation(t('thresholdValueValidation'));
      return false;
    }
  };

  /**
   * @function validateThresholdRange
   * @description Function to validate the range of a threshold value
   * @param value string value of the threshold row
   * @param setErrorMessage boolean to set the error message
   * @returns boolean true if validation is successful else false
   */
  const validateThresholdRange = (value: string, setErrorMessage = true) => {
    if (threshold.thresholdType === ALERT_SUBSCRIPTION_THRESHOLD_TYPE.AMOUNT) {
      if (Number(value) <= 0) {
        setErrorMessage &&
          setThresholdValidation(t('thresholdValueValidation'));
        return false;
      }
    }

    if (
      threshold.thresholdType === ALERT_SUBSCRIPTION_THRESHOLD_TYPE.PERCENTAGE
    ) {
      if (Number(value) <= 0 || Number(value) > 100) {
        setErrorMessage && setThresholdValidation(t('percentageValidation'));
        return false;
      }
    }

    setThresholdValidation('');
    return true;
  };

  return (
    <div className="flex flex-column threshold-row">
      <Row gutter={12} key={index}>
        {index !== 0 && (
          <Col span={4}>
            <SelectDropdown
              className="full-width"
              value={alertSubscriptionDetails.conjunction}
              menu={ThresholdConjunctionDropDown}
              onChange={(value: any) => {
                updateAlertSubscription({
                  ...alertSubscriptionDetails,
                  conjunction: value,
                });
              }}
            />
          </Col>
        )}
        <Col span={index !== 0 ? 5 : 9}>
          <Input
            className="full-width"
            value={threshold.thresholdValue}
            prefix={
              threshold.thresholdType ===
              ALERT_SUBSCRIPTION_THRESHOLD_TYPE.AMOUNT
                ? '$'
                : undefined
            }
            suffix={
              threshold.thresholdType ===
              ALERT_SUBSCRIPTION_THRESHOLD_TYPE.PERCENTAGE
                ? '%'
                : undefined
            }
            onChange={(e: any) => {
              updateAlertSubscription({
                ...alertSubscriptionDetails,
                thresholds: alertSubscriptionDetails?.thresholds?.map(
                  (eachThreshold, thresholdIndex) =>
                    thresholdIndex === index
                      ? { ...eachThreshold, thresholdValue: e.target.value }
                      : eachThreshold
                ),
              });
              validateThresholdRow(e.target.value);
            }}
            onBlur={() => {
              validateThresholdRow(threshold.thresholdValue);
            }}
          />
        </Col>
        <Col span={14}>
          <SelectDropdown
            className="full-width"
            value={threshold.thresholdType}
            options={THRESHOLD_TYPE_LABELS.map((thresholdLabelObj) => ({
              value: thresholdLabelObj.type,
              label: thresholdLabelObj.label,
              disabled: alertSubscriptionDetails?.thresholds?.some(
                (addedThreshold) =>
                  addedThreshold.thresholdType === thresholdLabelObj.type
              ),
            }))}
            onChange={(value: any) => {
              updateAlertSubscription({
                ...alertSubscriptionDetails,
                thresholds: alertSubscriptionDetails?.thresholds?.map(
                  (eachThreshold, thresholdIndex) =>
                    thresholdIndex === index
                      ? { ...eachThreshold, thresholdType: value }
                      : eachThreshold
                ),
              });
            }}
          />
        </Col>
        <Col span={1} className="flex-align-self-end">
          {(alertSubscriptionDetails?.thresholds ?? [])?.length > 1 && (
            <DeleteIconButton
              className="delete-button cursor-pointer"
              width={32}
              height={32}
              onClick={() => {
                updateAlertSubscription({
                  ...alertSubscriptionDetails,
                  conjunction: undefined,
                  thresholds: alertSubscriptionDetails?.thresholds?.filter(
                    (_, thresholdIndex) => thresholdIndex !== index
                  ),
                });
              }}
            />
          )}
        </Col>
      </Row>
      <span
        style={{
          display: `${thresholdValidation ? 'inline' : 'none'}`,
        }}
        className="font-validation-error"
      >
        {thresholdValidation}
      </span>
    </div>
  );
};

export default ThresholdRow;
