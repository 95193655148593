import { useEffect, useState } from 'react';
import { Empty, message, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { ColumnProps } from 'antd/lib/table/Column';
import { FilterDropdownProps } from 'antd/lib/table/interface';

import TableFilterDropdown from 'components/TableFilterDropdown';
import Button from 'components/Button';
import SearchInput from 'components/Input';
import QuickActionMenu from 'components/QuickActionMenu';
import Table from 'components/Table';
import DeleteModal from 'components/DeleteModal';
import NavigationPath from 'components/NavigationPath';
import Pagination from 'components/Pagination';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import { CLOUD_PROVIDERS_LIST } from 'constants/cloudProviders';
import { BUTTON_TYPE, INPUT_SIZE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { REFRESH_INTERVAL } from 'constants/defaultValues';
import { userAuthorization } from 'redux/authorizationSlice';
import {
  importStaticFiles,
  removeFileUploadTransactions,
  setCurrentStep,
  setImportFileData,
  setImportFilesList,
  setImportStaticFilesComponent,
  setIsEdit,
} from 'redux/importStaticFileSlice';
import { setSelectedProvider } from 'redux/providerSlice';
import {
  ImportFilesActions,
  ImportFilesListType,
  IMPORT_FILES_QUICK_ACTIONS,
  ImportFileStatus,
  IMPORT_FILE_STATUS_LABELS,
} from 'types/importFiles';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { onApiCallError } from 'utils/handleErrors';
import { deleteImportFile, fetchImportFilesData } from 'utils/services';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import { defaultFormData, ImportStaticFilesComponents } from './constants';
import { downloadSchema } from './utils';

import './index.scss';

const StaticFilesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux states
  const { importFilesList } = useSelector(importStaticFiles);
  const { permissions } = useSelector(userAuthorization);

  // Table states
  const [searchKey, setSearchKey] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterProviders, setFilterProviders] = useState<string[] | null>([]);
  const [totalConnectionsCount, setTotalConnectionsCount] = useState<number>(0);
  const [currentImportFile, setCurrentImportFile] = useState<
    ImportFilesListType | undefined
  >(undefined);

  // Modal states
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Request Status
  const [
    fetchImportFilesDataRequestStatus,
    setFetchImportFilesDataRequestStatus,
  ] = useState('');
  const [deleteImportFileRequestStatus, setDeleteImportFileRequestStatus] =
    useState('');

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    fetchAndSetImportFilesData(searchKey, filterProviders, currentPage);
    const interval = setInterval(() => {
      fetchAndSetImportFilesData(
        searchKey,
        filterProviders,
        currentPage,
        false
      );
    }, REFRESH_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [searchKey, filterProviders, currentPage]);

  /**
   * @function fetchAndSetImportFilesData
   * @description Function to fetch import files data and with search and filters
   * @param searchString string to be searched for.
   * @param providers selected providers for filter.
   * @param page page number defaults to first page
   * @param showLoading If true the loader will be shown in the table, else not. Defaults to true.
   */
  const fetchAndSetImportFilesData = (
    searchString: string,
    providers: string[] | null,
    page: number = 1,
    showLoading: boolean = true
  ) => {
    showLoading &&
      setFetchImportFilesDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      key: searchString,
      page: page - 1,
      size: PAGINATION_SIZE,
      datasourceProvider: providers?.join(','),
    };
    fetchImportFilesData(params)
      .then((res: any) => {
        dispatch(setImportFilesList(res?.data?.responseData?.content));
        setTotalConnectionsCount(res?.data?.responseData?.totalElements);
        showLoading &&
          setFetchImportFilesDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, false, setFetchImportFilesDataRequestStatus)
      );
  };

  /**
   * @function onHandleImportClick
   * @description Function to handle import file button click
   */
  const onHandleImportClick = () => {
    dispatch(setIsEdit(false));
    dispatch(setImportFileData(defaultFormData));
    navigate(NAVIGATION_MENU_PATH.IMPORT_FILE);
    dispatch(setCurrentStep(0));
    dispatch(
      setImportStaticFilesComponent(
        ImportStaticFilesComponents.IMPORT_FILE_FORM
      )
    );
  };

  /**
   * @function handleSearchImportFiles
   * @description Function to handle search for import files table
   * @param e :accepts search input event
   */
  const handleSearchImportFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchKey(e.target.value);
  };

  const handleQuickAction = (
    importFile: ImportFilesListType,
    action: string
  ) => {
    switch (action) {
      case ImportFilesActions.UPLOAD_FILE:
        dispatch(setIsEdit(true));
        dispatch(setSelectedProvider(importFile.datasourceProvider));
        dispatch(
          setImportFileData({
            ...importFile,
            displayName: importFile.displayName,
            transactionName: importFile.transactionName,
            typeOfDataset: importFile.datasetType,
            focusSchema: importFile.focusSchema,
            isExistingSchema: importFile.standardSchema,
            wantSpendDiagnosticsDashboard: importFile.preBuiltDashbaordRequired,
            schemaName: importFile.schemaName,
            isCreateSchemaDefinition: false,
            schema: importFile.displaySchema,
            schemaDefinition: [],
          })
        );
        dispatch(
          setImportStaticFilesComponent(
            ImportStaticFilesComponents.IMPORT_FILE_FORM
          )
        );
        dispatch(setCurrentStep(3));
        navigate(NAVIGATION_MENU_PATH.IMPORT_FILE);
        break;

      case ImportFilesActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  /**
   * @function deleteSelectedImportFile
   * @description Function to delete a selected import file
   */
  const deleteSelectedImportFile = () => {
    setDeleteImportFileRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      transactionName: currentImportFile?.transactionName,
    };
    deleteImportFile(params)
      .then(() => {
        setDeleteImportFileRequestStatus(REQUEST_STATUS.SUCCESS);
        fetchAndSetImportFilesData(searchKey, filterProviders, 1);
        setShowDeleteModal(false);
        message.success(
          `${currentImportFile?.displayName}${t(
            'importFilesTable.deleteImportFileSuccess'
          )}.`
        );
        dispatch(
          removeFileUploadTransactions(currentImportFile?.transactionName ?? '')
        );
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteImportFileRequestStatus,
          t('importFilesTable.deleteImportFileError')
        );
      });
  };

  const getStatusClassName = (text: string) => {
    switch (text) {
      case ImportFileStatus.READY:
        return 'active';
      case ImportFileStatus.UPLOADING:
        return 'uploading';
      case ImportFileStatus.ERROR:
        return 'error';
      case ImportFileStatus.PROCESSING:
        return 'processing';
    }
  };

  const getQuickActionsForRecord = (record: ImportFilesListType) =>
    record.fileImportStatus === ImportFileStatus.READY
      ? IMPORT_FILES_QUICK_ACTIONS
      : IMPORT_FILES_QUICK_ACTIONS.filter(
          (obj) => obj.id === ImportFilesActions.DELETE
        );

  /**
   * @function getCloudProviderFilterDropdown
   * @description Function to return the cloud provider dropdown for table filter
   * @param Object containing the filter dropdown props
   * @returns JSX element
   */
  const getCloudProviderFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
  }: FilterDropdownProps) => (
    <TableFilterDropdown
      allKeys={filters ?? []}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      designVersion2
    />
  );

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + index + 1,
      width: '5%',
      fixed: 'left',
    },
    {
      title: () => t('importFilesTable.transactionName'),
      dataIndex: 'displayName',
      key: 'transactionName',
      filters: CLOUD_PROVIDERS_LIST,
      filterIcon: <Icon iconName={ICONS.FILTER_2_FILL} />,
      filterDropdown: getCloudProviderFilterDropdown,
      render: (text: any, record: ImportFilesListType) => (
        <div className="overflow-wrap flex flex-gap-8 flex-align-items-center">
          <div className="logo">
            <img
              src={getProviderSmallLogo(record.datasourceProvider)}
              alt={`${record.datasourceProvider} Logo`}
            />
          </div>
          {text}
        </div>
      ),
      width: '15%',
      fixed: 'left',
    },
    {
      title: () => t('importFilesTable.uploadedFileName'),
      dataIndex: 'originalFileName',
      key: 'originalFileName',
      width: '15%',
    },
    {
      title: t('importFilesTable.schema'),
      dataIndex: 'schemaName',
      key: 'schemaName',
      render: (text: string, record: ImportFilesListType) => (
        <Button
          className="schema-name-cta overflow-wrap"
          title={text}
          type={BUTTON_TYPE.LINK}
          onClick={() => downloadSchema(record.displaySchema, text)}
        />
      ),
      width: '15%',
    },
    {
      title: t('importFilesTable.fileType'),
      dataIndex: 'fileType',
      key: 'fileType',
      width: '10%',
    },
    {
      title: () => t('importFilesTable.status'),
      dataIndex: 'fileImportStatus',
      key: 'fileImportStatus',
      render: (text: string, record: ImportFilesListType) => {
        return (
          <div className="flex flex-row flex-gap-8 flex-align-items-center">
            <span className={`status ${getStatusClassName(text)}`}>
              {IMPORT_FILE_STATUS_LABELS.find((obj) => obj.id === text)?.label}
            </span>
            {text === ImportFileStatus.ERROR && (
              <Tooltip
                placement="bottom"
                overlayClassName="tooltip-error-body"
                title={record.statusMessage}
                trigger="click"
              >
                <Icon iconName={ICONS.INFORMATION_LINE} className="info-icon" />
              </Tooltip>
            )}
          </div>
        );
      },
      width: '10%',
    },
    {
      title: () => t('importFilesTable.tableName'),
      dataIndex: 'tableName',
      key: 'tableName',
      width: '15%',
    },
    {
      title: t('importFilesTable.quickAction'),
      dataIndex: 'quickAction',
      key: 'quickAction',
      render: (_text: string, record: ImportFilesListType) => {
        return (
          <QuickActionMenu
            setCurrentConnectionData={() => setCurrentImportFile(record)}
            quickActions={getQuickActionsForRecord(record)}
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
            disabled={!permissions.cloudConnectorModify}
          />
        );
      },
      align: 'center',
      width: '10%',
    },
  ];

  return (
    <div className="static-file-import">
      <header className="static-file-import-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="modal-heading">
          {t('navigationMenu.importStaticFile')}
        </div>
        {permissions.cloudConnectorWrite && (
          <Button
            onClick={onHandleImportClick}
            title={t('importFileLabels.import')}
          />
        )}
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="static-file-import-content">
          <SearchInput
            placeholder={t('searchImportFilesPlaceholder')}
            type="search"
            size={INPUT_SIZE.SMALL}
            onChange={debounce(handleSearchImportFiles, DEBOUNCE_TIME_DELAY)}
          />
          <Table
            rootClassName="import-table"
            pagination={false}
            dataSource={importFilesList?.map(
              (importFile: ImportFilesListType, index) => ({
                ...importFile,
                key: index,
              })
            )}
            columns={columns}
            scroll={{ y: '100%' }}
            loading={
              fetchImportFilesDataRequestStatus === REQUEST_STATUS.PROCESSING
            }
            locale={{
              emptyText: fetchImportFilesDataRequestStatus !==
                REQUEST_STATUS.PROCESSING && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    fetchImportFilesDataRequestStatus === REQUEST_STATUS.ERROR
                      ? t('graphErrorMessage')
                      : t('importFilesTable.noTransactionListMessage')
                  }
                />
              ),
            }}
            onChange={(_pagination: any, filters: any) => {
              setCurrentPage(1);
              setFilterProviders(filters.transactionName);
            }}
            data-testid="import-static-file-table"
            designVersion2
          />
        </div>
      </div>
      <div className="page-footer flex">
        {totalConnectionsCount > PAGINATION_SIZE && (
          <Pagination
            current={currentPage}
            onChange={setCurrentPage}
            total={totalConnectionsCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </div>
      {currentImportFile !== undefined && showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={deleteSelectedImportFile}
          loading={deleteImportFileRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default StaticFilesPage;
