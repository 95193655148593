import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import { message, Skeleton } from 'antd';
import { useSelector } from 'react-redux';

import { selectReport } from 'redux/reportSlice';
import Button from 'components/Button';
import DrawerComponent from 'components/DrawerComponent';
import SelectDropdown from 'components/Select';
import { FormLabel } from 'components/FormLabel';
import { FETCH_DROPDOWN_PAGINATION_SIZE } from 'constants/userConsole';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { BUTTON_TYPE } from 'constants/appearance';
import { shareReport } from 'pages/ReportsPage/services';
import { ReportSharePermissions } from 'pages/CreateReportPage/constants';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  getAllTeams,
  getAllUsersData,
  getSearchUserData,
  searchTeams,
} from 'utils/services';
import { getValidationStyle } from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';

import UserRow from './components/UserRow';
import TeamRow from './components/TeamRow';
import { ReportSharedTeamType, ReportSharedUserType } from './types';

import './index.scss';

type ShareReportModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
};

const ShareReportModal = ({ show, setShow }: ShareReportModalProps) => {
  const { t } = useTranslation();
  const { reportOptions } = useSelector(selectReport);

  const [users, setUsers] = useState<ReportSharedUserType[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<ReportSharedUserType[]>(
    []
  );
  const [openUsersDropdown, setOpenUsersDropdown] = useState(false);
  const [fetchUsersReqStatus, setFetchUsersReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [teams, setTeams] = useState<ReportSharedTeamType[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<ReportSharedTeamType[]>(
    []
  );
  const [openTeamsDropdown, setOpenTeamsDropdown] = useState(false);
  const [fetchTeamsReqStatus, setFetchTeamsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [searchKey, setSearchKey] = useState('');
  const [validation, setValidation] = useState('');
  const [shareReqStatus, setShareReqStatus] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    setFetchUsersReqStatus(REQUEST_STATUS.PROCESSING);

    let source = axios.CancelToken.source();
    fetchUserData(source);

    return () => {
      source?.cancel();
    };
  }, [searchKey]);

  useEffect(() => {
    setFetchTeamsReqStatus(REQUEST_STATUS.PROCESSING);

    let source = axios.CancelToken.source();
    fetchTeamsData(source);

    return () => {
      source?.cancel();
    };
  }, []);

  /**
   * @function fetchUserData
   * @description Function to fetch all user data
   * @param page accepts the current page number
   */
  const fetchUserData = (cancelTokenSource: CancelTokenSource) => {
    setFetchUsersReqStatus(REQUEST_STATUS.PROCESSING);

    const params = { key: searchKey, size: FETCH_DROPDOWN_PAGINATION_SIZE };

    (searchKey
      ? getSearchUserData(params, cancelTokenSource?.token)
      : getAllUsersData(
          { size: FETCH_DROPDOWN_PAGINATION_SIZE },
          cancelTokenSource?.token
        )
    )
      .then((response: any) => {
        const data = response?.data?.content ?? [];
        const usersList = data
          .filter(
            (item: any) =>
              !selectedUsers.some((user) => user.email === item.email) &&
              item.email !== localStorage.getItem('email')
          )
          .map((item: any) => ({
            email: item.email,
            name: item.name,
            permission: ReportSharePermissions.READ,
          }));
        setUsers(usersList);
        setFetchUsersReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchUsersReqStatus);
      });
  };

  /**
   * @function fetchTeamsData
   * @description Function to fetch all teams data
   * @param page accepts the current page number
   */
  const fetchTeamsData = (cancelTokenSource: CancelTokenSource) => {
    setFetchTeamsReqStatus(REQUEST_STATUS.PROCESSING);

    const params = { key: searchKey, size: FETCH_DROPDOWN_PAGINATION_SIZE };

    (searchKey
      ? searchTeams(params, cancelTokenSource?.token)
      : getAllTeams(params, cancelTokenSource?.token)
    )
      .then((response: any) => {
        const list = response?.data?.responseData?.teamsRespDtoList ?? [];
        const teamsList = list
          .filter(
            (item: any) =>
              !selectedTeams.some((team) => team.teamName === item.teamName)
          )
          .map((item: any) => ({
            teamName: item.teamName,
            permission: ReportSharePermissions.READ,
          }));
        setTeams(teamsList);
        setFetchTeamsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchTeamsReqStatus);
      });
  };

  /**
   * @function onShareError
   * @description Callback function for error on sharing a report
   * @param errorMessage error message to be shown
   */
  const onShareError = (errorMessage: string) => {
    message.error({
      content: errorMessage ?? t('reports.reportShareError'),
      key: ERROR_KEY,
    });
    setShareReqStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function onClickShare
   * @description Callback function for share button click
   */
  const onClickShare = () => {
    if (selectedUsers.length === 0 && selectedTeams.length === 0) {
      setValidation(t('reports.addAtLeastOneUserOrTeam'));
      return;
    }

    setShareReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      userList: selectedUsers.map((user) => ({
        userEmail: user.email,
        reportName: reportOptions.reportName,
        reportPermission: user.permission,
      })),
      teamList: selectedTeams.map((team) => ({
        teamName: team.teamName,
        reportName: reportOptions.reportName,
        reportPermission: team.permission,
      })),
    };

    shareReport(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setShareReqStatus(REQUEST_STATUS.SUCCESS);
          setShow(false);
          message.success({
            content: t('reports.reportShareSuccess'),
            key: SUCCESS_KEY,
          });
          return;
        }
        onShareError(res?.data?.message);
      })
      .catch((e) => {
        onApiCallError(e, false, setShareReqStatus);
        onShareError(e?.response?.data?.message);
      });
  };

  /**
   * @function onHandleUserSelect
   * @description Callback function for user selection
   * @param _e event for select
   * @param option user option selected
   */
  const onHandleUserSelect = (_e: any, option: any) => {
    setValidation('');

    const selectedUsersList = [
      ...selectedUsers,
      {
        name: option.label,
        email: option.value,
        permission: ReportSharePermissions.READ,
      },
    ];
    setSelectedUsers(selectedUsersList);
    const filteredUsers = users.filter(
      (item: any) =>
        !selectedUsersList.some((user) => user.email === item.email)
    );
    setUsers(filteredUsers);
    if (filteredUsers.length === 0) {
      setOpenUsersDropdown(false);
      setSearchKey('');
    }
  };

  /**
   * @function onHandleTeamSelect
   * @description Callback function for team selection
   * @param _e event for select
   * @param option team option selected
   */
  const onHandleTeamSelect = (_e: any, option: any) => {
    setValidation('');

    const selectedTeamsList = [
      ...selectedTeams,
      {
        teamName: option.label,
        permission: ReportSharePermissions.READ,
      },
    ];
    setSelectedTeams(selectedTeamsList);
    const filteredTeams = teams.filter(
      (item: any) =>
        !selectedTeamsList.some((team) => team.teamName === item.teamName)
    );
    setTeams(filteredTeams);
    if (filteredTeams.length === 0) {
      setOpenUsersDropdown(false);
      setSearchKey('');
    }
  };

  /**
   * @function onClickRemoveUser
   * @description Function to remove the user from selection
   * @param user user removed
   */
  const onClickRemoveUser = (user: ReportSharedUserType) => {
    setSelectedUsers(selectedUsers.filter((item) => item.email !== user.email));
    setUsers([...users, user]);
  };

  /**
   * @function onClickRemoveTeam
   * @description Function to remove the team from selection
   * @param team team removed
   */
  const onClickRemoveTeam = (team: ReportSharedTeamType) => {
    setSelectedTeams(
      selectedTeams.filter((item) => item.teamName !== team.teamName)
    );
    setTeams([...teams, team]);
  };

  /**
   * @function onSelectPermissionForUser
   * @description Function to update the user permission
   * @param permission permission to be updated
   * @param user user to be updated
   * @param index index position of the user in the list
   */
  const onSelectPermissionForUser = (
    permission: string,
    user: ReportSharedUserType,
    index: number
  ) => {
    const usersList = [...selectedUsers];
    usersList.splice(index, 1, { ...user, permission: permission });
    setSelectedUsers(usersList);
  };

  /**
   * @function onSelectPermissionForTeam
   * @description Function to update the team permission
   * @param permission permission to be updated
   * @param team team to be updated
   * @param index index position of the team in the list
   */
  const onSelectPermissionForTeam = (
    permission: string,
    team: ReportSharedTeamType,
    index: number
  ) => {
    const teamsList = [...selectedTeams];
    teamsList.splice(index, 1, { ...team, permission: permission });
    setSelectedTeams(teamsList);
  };

  const getDropdownRender = (reqStatus: string, originalNode: ReactNode) =>
    reqStatus === REQUEST_STATUS.PROCESSING ? (
      <Skeleton active paragraph={{ rows: 5 }} title={false} />
    ) : (
      originalNode
    );

  return (
    <DrawerComponent
      className="share-report-drawer"
      dataTestId="share-report-drawer"
      open={show}
      title={t('reports.shareReport')}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-align-items-center flex-end">
          <Button
            title={t('reports.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={t('reports.share')}
            onClick={onClickShare}
            loading={shareReqStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
      }
      width="481px"
    >
      <div className="flex flex-column flex-gap-24">
        <div className="share-list flex flex-column flex-gap-8">
          <FormLabel title={t('reports.users')} />
          <SelectDropdown
            rootClassName="full-width"
            labelInValue
            options={users.map((item) => ({
              label: item.name,
              value: item.email,
            }))}
            value={{
              value: '',
              label: searchKey || (
                <span className="search-placeholder table-typography">
                  {t('reports.searchUsers')}
                </span>
              ),
            }}
            placeholder={t('reports.searchUsers')}
            filterOption={false}
            dropdownRender={(node: ReactNode) =>
              getDropdownRender(fetchUsersReqStatus, node)
            }
            showSearch
            onSearch={(searchValue: string) => {
              setUsers([]);
              setSearchKey(searchValue);
            }}
            open={openUsersDropdown}
            suffixIcon={<Icon iconName={ICONS.SEARCH_2_LINE} />}
            onChange={onHandleUserSelect}
            designVersion2={true}
            onBlur={() => {
              setOpenUsersDropdown(false);
              setSearchKey('');
            }}
            onFocus={() => setOpenUsersDropdown(true)}
            onInputKeyDown={() => setOpenUsersDropdown(true)}
          />
          <div className="selected full-width flex flex-align-items-center">
            <Icon
              className="arrow-down"
              iconName={ICONS.ARROW_DOWN_S_LINE}
              size={ICONS_SIZE.XL}
            />
            <div className="font-caption-bold">
              {t('reports.selectedUsers')}
            </div>
            <div className="count font-tooltip-small flex flex-end flex-fit">
              <span className="count-value">{selectedUsers.length}</span>
            </div>
          </div>
          <div className="flex flex-column flex-gap-12 new-styled-scroll">
            {selectedUsers.map((user, index) => (
              <UserRow
                key={user.email}
                user={user}
                onClickRemoveUser={onClickRemoveUser}
                onSelectPermission={(permission) =>
                  onSelectPermissionForUser(permission, user, index)
                }
              />
            ))}
          </div>
        </div>
        <div className="share-list flex flex-column flex-gap-8">
          <FormLabel title={t('reports.teams')} />
          <SelectDropdown
            rootClassName="full-width"
            labelInValue
            options={teams.map((item) => ({
              label: item.teamName,
              value: item.teamName,
            }))}
            value={{
              value: '',
              label: searchKey || (
                <span className="search-placeholder table-typography">
                  {t('reports.searchTeams')}
                </span>
              ),
            }}
            placeholder={t('reports.searchTeams')}
            filterOption={false}
            dropdownRender={(node: ReactNode) =>
              getDropdownRender(fetchTeamsReqStatus, node)
            }
            showSearch
            onSearch={(searchValue: string) => {
              setTeams([]);
              setSearchKey(searchValue);
            }}
            open={openTeamsDropdown}
            suffixIcon={<Icon iconName={ICONS.SEARCH_2_LINE} />}
            onChange={onHandleTeamSelect}
            designVersion2={true}
            onBlur={() => {
              setOpenTeamsDropdown(false);
              setSearchKey('');
            }}
            onFocus={() => setOpenTeamsDropdown(true)}
            onInputKeyDown={() => setOpenTeamsDropdown(true)}
          />
          <div className="selected full-width flex flex-align-items-center">
            <Icon
              className="arrow-down"
              iconName={ICONS.ARROW_DOWN_S_LINE}
              size={ICONS_SIZE.XL}
            />
            <div className="font-caption-bold">
              {t('reports.selectedTeams')}
            </div>
            <div className="count font-tooltip-small flex flex-end flex-fit">
              <span className="count-value">{selectedTeams.length}</span>
            </div>
          </div>
          <div className="flex flex-column flex-gap-12 new-styled-scroll">
            {selectedTeams.map((team, index) => (
              <TeamRow
                key={team.teamName}
                team={team}
                onClickRemoveTeamFromTeam={onClickRemoveTeam}
                onSelectPermission={(permission) =>
                  onSelectPermissionForTeam(permission, team, index)
                }
              />
            ))}
          </div>
          <span
            style={{
              display: `${getValidationStyle(validation)}`,
            }}
            className="font-validation-error"
          >
            {validation}
          </span>
        </div>
      </div>
    </DrawerComponent>
  );
};

export default ShareReportModal;
