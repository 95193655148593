import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import ColumnChart from 'components/ColumnChart';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { MonthlyCostByNameType } from 'types/dataTypes';
import {
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  getMonthYearShortList,
} from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import { getCostByNamePerMonthExcelData } from '../utils';

type CostByNamePerMonthProps = {
  graphHeading: string;
  graphName: string;
  costByNamePerMonth: MonthlyCostByNameType[];
  costByNamePerMonthTableData: any[];
  costByNamePerMonthRequestStatus: string;
  isCostByNamePerMonthTableView: boolean;
  setIsCostByNamePerMonthTableView: (value: boolean) => void;
  costByNamePerMonthDateRange: string[];
  onChangeCostByNamePerMonthDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
  pdfView?: boolean;
};

const CostByNamePerMonth = ({
  graphHeading,
  graphName,
  costByNamePerMonth,
  costByNamePerMonthTableData,
  costByNamePerMonthRequestStatus,
  isCostByNamePerMonthTableView,
  setIsCostByNamePerMonthTableView,
  costByNamePerMonthDateRange,
  onChangeCostByNamePerMonthDateRange,
  pdfView,
}: CostByNamePerMonthProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
      fixed: true,
    },
    {
      title:
        graphHeading === t('graphHeadings.storageUsageByDatabasePerMonth')
          ? t('snowflakeDashboards.databases')
          : t('snowflakeDashboards.warehouses'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      fixed: true,
    },
    ...getMonthYearShortList(
      costByNamePerMonthDateRange[0],
      costByNamePerMonthDateRange[1],
      MONTH_YEAR_SHORT
    ).map((month) => ({
      title: month,
      key: `${month}-cost`,
      dataIndex: `${month}-cost`,
      width: 100,
      render: (text: string) =>
        graphHeading === t('graphHeadings.storageUsageByDatabasePerMonth')
          ? text ?? '0.00 ' + t('snowflakeDashboards.tb')
          : currencySymbol + numberCommaSeparator(text ?? 0),
    })),
  ];

  const filters = (
    <div className="filters">
      <DatePicker
        defaultValue={[
          moment(costByNamePerMonthDateRange[0]),
          moment(costByNamePerMonthDateRange[1]),
        ]}
        onChange={onChangeCostByNamePerMonthDateRange}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        disabledDate={(current: any) => current > moment().endOf('day')}
      />
    </div>
  );

  const getComponent = () => (
    <div
      className={`graph ${isCostByNamePerMonthTableView && 'flex flex-gap-16'}`}
    >
      <div
        className={`graph-area ${
          isCostByNamePerMonthTableView && 'with-table expand-hide'
        }`}
      >
        <ColumnChart
          data={costByNamePerMonth}
          xField="month"
          yField="cost"
          xTitle={t('snowflakeDashboards.month')}
          yTitle={
            graphHeading === t('graphHeadings.storageUsageByDatabasePerMonth')
              ? t('snowflakeDashboards.usageInTb')
              : t('snowflakeDashboards.costInCurrency', {
                  currencySymbol: currencySymbol,
                })
          }
          groupingField="name"
          isStack
          prefixSymbol={
            graphHeading === t('graphHeadings.storageUsageByDatabasePerMonth')
              ? undefined
              : currencySymbol
          }
          suffixSymbol={
            graphHeading === t('graphHeadings.storageUsageByDatabasePerMonth')
              ? t('snowflakeDashboards.tb')
              : undefined
          }
          disableAnimation={pdfView}
        />
      </div>
      {isCostByNamePerMonthTableView && (
        <div className="tabular-view width-30">
          <Table
            pagination={false}
            loading={
              costByNamePerMonthRequestStatus === REQUEST_STATUS.PROCESSING
            }
            dataSource={costByNamePerMonthTableData.map((item, index) => ({
              ...item,
              index: index + 1,
            }))}
            columns={columns}
            scroll={{ y: 190 }}
            designVersion2
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      className="graph-card cost-by-name-per-month flex flex-column flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={graphHeading}
        filters={filters}
        graphName={graphName}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostByNamePerMonthTableView}
        setIsTableView={setIsCostByNamePerMonthTableView}
        excelData={getCostByNamePerMonthExcelData(
          graphHeading,
          costByNamePerMonthTableData,
          costByNamePerMonthDateRange
        )}
        className="extended-filter"
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={costByNamePerMonthRequestStatus}
      />
    </div>
  );
};

export default CostByNamePerMonth;
