import React, { useCallback, useEffect, useState } from 'react';
import { DualAxes } from '@ant-design/plots';
import { uniqBy } from 'lodash';

import TooltipContent from 'components/TooltipContent';
import { COLORS } from 'constants/graphConfig';
import { CHART_AXIS_LABEL_LENGTH_LIMIT } from 'constants/userConsole';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { generateGraphColors } from 'utils/dashboardUtils';

type DualAxisChartProps = {
  leftAxisData: any[];
  rightAxisData: any[];
  xField?: string;
  leftAxisYField?: string;
  rightAxisYField?: string;
  syncYAxis?: boolean;
  xTitle?: string;
  leftYTitle?: string;
  rightYTitle?: string;
  isStack?: boolean;
  isGroup?: boolean;
  leftAxisSeriesField?: string;
  rightAxisSeriesField?: string;
  showFullLabels?: boolean;
  disableAnimation?: boolean;
  prefixSymbol?: string;
  additionalTooltipContent?: React.ReactNode;
  tooltipContentOverride?: (title: any, data: any[]) => any;
  showSlider?: boolean;
};

const DualAxisChart = ({
  leftAxisData,
  rightAxisData,
  xField,
  leftAxisYField,
  rightAxisYField,
  syncYAxis,
  xTitle,
  leftYTitle,
  rightYTitle,
  isStack,
  isGroup,
  leftAxisSeriesField,
  rightAxisSeriesField,
  showFullLabels,
  disableAnimation,
  prefixSymbol = '',
  additionalTooltipContent,
  tooltipContentOverride,
  showSlider,
}: DualAxisChartProps) => {
  const [legendColors, setLegendColors] = useState<{
    leftAxis: string | string[];
    rightAxis: string | string[];
  }>({ leftAxis: [], rightAxis: [] });

  useEffect(() => {
    const leftLegends = leftAxisSeriesField
      ? uniqBy(leftAxisData, leftAxisSeriesField)
      : ['one data'];
    const rightLegends = rightAxisSeriesField
      ? uniqBy(rightAxisData, rightAxisSeriesField)
      : ['one data'];

    const colors = generateGraphColors(
      leftLegends.length + rightLegends.length
    );

    setLegendColors({
      leftAxis: leftAxisSeriesField
        ? colors.slice(0, leftLegends.length)
        : colors[0],
      rightAxis: rightAxisSeriesField
        ? colors.slice(leftLegends.length)
        : colors[leftLegends.length],
    });
  }, [leftAxisData, rightAxisData]);

  const labelFormatter = useCallback((text: string) => {
    if (text.length > CHART_AXIS_LABEL_LENGTH_LIMIT && !showFullLabels) {
      return text.substring(0, CHART_AXIS_LABEL_LENGTH_LIMIT) + '...';
    }
    return text;
  }, []);

  const config: any = {
    syncViewPadding: true,
    data: [leftAxisData, rightAxisData],
    xField,
    yField: [leftAxisYField, rightAxisYField],
    yAxis: [
      {
        line: {
          style: {
            stroke: COLORS.fnGrey,
            lineWidth: 1,
            opacity: 0.7,
          },
        },
        title: {
          text: leftYTitle ?? null,
        },
        sync: syncYAxis,
      },
      {
        line: {
          style: {
            stroke: COLORS.fnGrey,
            lineWidth: 1,
            opacity: 0.7,
          },
        },
        title: {
          text: rightYTitle ?? null,
        },
        sync: syncYAxis,
      },
    ],
    xAxis: {
      line: {
        style: {
          stroke: COLORS.fnGrey,
          lineWidth: 1,
          opacity: 0.7,
        },
      },
      label: {
        autoRotate: showFullLabels,
        formatter: labelFormatter,
      },
      title: {
        text: xTitle,
      },
    },
    geometryOptions: [
      {
        geometry: 'column',
        isStack,
        isGroup,
        seriesField: leftAxisSeriesField,
        color: legendColors.leftAxis,
        maxColumnWidth: 24,
      },
      {
        geometry: 'line',
        seriesField: rightAxisSeriesField,
        color: legendColors.rightAxis,
      },
    ],
    legend: {
      position: 'bottom',
    },
    tooltip: {
      shared: true,
      customItems: useCallback(
        (originalItems: any[]) =>
          originalItems.map((item) => ({
            ...item,
            value: numberCommaSeparator(item.value),
          })),
        []
      ),
      customContent: useCallback(
        tooltipContentOverride ??
          ((title: string, data: any[]) => (
            <TooltipContent
              title={title}
              data={data}
              prefixSymbol={prefixSymbol}
              additionalContent={additionalTooltipContent}
            />
          )),
        []
      ),
    },
    animation: !disableAnimation,
    slider: showSlider ? {} : undefined,
  };

  return <DualAxes {...config} />;
};

export default DualAxisChart;
