import moment, { DurationInputArg2 } from 'moment';

import { PROVIDER } from 'constants/cloudProviders';

export const MONTH_FORMAT = 'M';
export const DATE_FORMAT = 'MMM DD, YYYY';
export const HYPHEN_DATE_FORMAT = 'YYYY-MM-DD';
export const HYPHEN_DATE_MONTH_YEAR_FORMAT = 'DD-MMM-YYYY';
export const SLASH_DATE_FORMAT = 'DD/MM/YYYY';
export const SLASH_DATE_FORMAT_MM_DD_YYYY = 'MM/DD/YYYY';
export const DATE_TIME_AM_PM = 'MMM DD, YYYY, hh:mm:ss A';
export const SLASH_DATE_TIME_HH_MM_SS_AM_PM = 'DD/MM/YYYY hh:mm:ss A';
export const HYPHEN_DATE_TIME_HH_MM_SS_AM_PM = 'YYYY-MM-DD hh:mm:ss A';
export const HYPHEN_DATE_TIME_HH_MM_SS = 'YYYY-MM-DD hh:mm:ss';
export const SHORT_MONTH_FORMAT = 'MMM';
export const FULL_YEAR_FORMAT = 'YYYY';
export const MONTH_YEAR_FORMAT = 'MMM YYYY';
export const YEAR_HYPHEN_MONTH = 'YYYY-MM';
export const YEAR_MONTH_WITHOUT_SEPARATOR = 'YYYYMM';
export const YEAR = 'year';
export const MONTH = 'month';
export const QUARTER = 'quarter';
export const WEEK = 'week';
export const TIMESTAMP_FORMAT = 'yyyy-MM-DDTHH:mm:ss.SSSZ';
export const TIMESTAMP_FORMAT_PERSIST_Z = 'yyyy-MM-DDTHH:mm:ss.SSS[Z]';
export const TIMESTAMP_HH_MM_SS_PERSIST_Z = 'yyyy-MM-DDTHH:mm:ss.SSS[Z]';
export const TIMESTAMP_FORMAT_WITHOUT_ZONE = 'yyyy-MM-DDTHH:mm:ss.SSS';
export const MONTH_YEAR_SHORT = 'MMM YY';
export const DATE_MONTH_SHORT = 'MMM DD';
export const YEAR_MONTH_WITHOUT_ZERO = 'YYYYM';
export const YEAR_WEEK = 'YYYYWW';
export const YEAR_QQ = 'YYYYQQ';
export const HOUR_MINUTE_SECONDS = 'H:mm:ss';
export const DD_MMM_YYYY = 'DD MMM YYYY';
export const DD_MMM_YY = 'DD MMM YY';
export const STRING_TIMESTAMP = 'Do MMMM YYYY, HH:mm:ss';
export const HOUR_MINUTE = 'HH:mm';

/**
 * @function dateFormat
 * @description Function to format date string from backend
 * @param date:string
 * @returns Formatted  version of date
 */
export const dateFormat = (date: string) => {
  const dateList = date.slice(0, 10).split('-');
  return dateList[1] + '/' + dateList[2] + '/' + dateList[0];
};

/**
 * @function dateToday
 * @description Function to return today's date.
 * @param format string format of the date.
 * @returns Formatted  version of today's date
 */
export const dateToday = (format?: string) => {
  return moment().format(format ?? DATE_FORMAT);
};

/**
 * @function dateNYearsFromToday
 * @description Function to return date n years back from today
 * @param years: number of years.
 * @returns Formatted  version of date back {years} from today
 */
export const dateNYearsFromToday = (years: number) => {
  return moment().subtract(years, YEAR).format(HYPHEN_DATE_FORMAT);
};

/**
 * @function getAllMonthNames
 * @description Function to get all month names
 * @returns Month List ['Jan', 'Feb', ...., 'Dec']
 */
export const getAllMonthNames = () => {
  return moment.monthsShort();
};

/**
 * @function getCommingYearsList
 * @description Function to get coming years list
 * @param limit: Is used to limit the year list.
 * @returns the list of years eg: [2022, 2023, 2024....]
 */
export const getComingYearsList = (limit: number) => {
  const years = [];
  const startingYear = moment();
  const endingYear = moment().add(limit, 'y');
  while (endingYear.diff(startingYear, 'y') >= 0) {
    years.push(startingYear.format('YYYY'));
    startingYear.add(1, 'y');
  }
  return years;
};

export const getCurrentDate = (format: string) => {
  return moment().format(format);
};

/**
 * @function monthsBetweenDates
 * @description Function to get the list of month and year.
 * @param fromMonthAndYear String which contains start year and month in the format [year][month] Eg: 202203
 * @param toMonthAndYear String which contains end year and month in the format [year][month] Eg: 202203
 * @return list of string in the format [month][year]. Eg Apr 2022
 */
export const monthsBetweenDates = (
  startDate: string,
  endDate: string,
  format = MONTH_YEAR_SHORT
) => {
  let date1 = moment(startDate);
  let date2 = moment(endDate);
  let betweenMonths = [];

  while (date1 <= date2) {
    betweenMonths.push(date1.format(format));
    date1.add(1, 'month');
  }
  return betweenMonths;
};

/**
 * @function getMonthAndYearDigitsFormat
 * @description Function to get the digits format of month and year.
 * @param monthYear String which contains year and month in the format [month] [year] Eg: Mar 2022
 * @return year and month in the format [year][month]. Eg 202203 for Mar 2022
 */
export const getMonthAndYearDigitsFormat = (monthYear: string) => {
  const [month, year] = monthYear.split(' ');
  return ('20' + year).slice(-4) + moment().month(month).format('MM');
};

/**
 * @function getMonthAndYearStringFormat
 * @description Function to get the string format of month and year.
 * @param monthYear String which contains year and month in the format [year][month]. Eg 202203
 * @return month and year in the format [month] [year] Eg: Mar 2022
 */
export const getMonthAndYearStringFormat = (monthYear: string) => {
  const year: number = Number(monthYear.slice(0, 4));
  const month: number = Number(monthYear.slice(4)) - 1;
  const monthShorts = moment.monthsShort();
  return monthShorts.at(month) + ' ' + year;
};

/**
 * @function getStartDateOfYear
 * @description Function to get the start date of the year in the given format.
 * @param year number year for which the start date is required, defaults to current year
 * @param format String format of date, defaults to YYYY-MM-DD format
 * @return Start date of the given year in the given format
 */
export const getStartDateOfYear = (year?: number, format?: string) => {
  return moment([year ?? moment().year()])
    .startOf(YEAR)
    .format(format ?? HYPHEN_DATE_FORMAT);
};

/**
 * @function getEndDateOfYear
 * @description Function to get the end date of the year in the given format.
 * @param year number year for which the end date is required, defaults to current year
 * @param format String format of date, defaults to YYYY-MM-DD format
 * @return End date of the given year in the given format
 */
export const getEndDateOfYear = (year?: number, format?: string) => {
  return moment([year ?? moment().year()])
    .endOf(YEAR)
    .format(format ?? HYPHEN_DATE_FORMAT);
};

/**
 * @function getStartDateOfQuarter
 * @description Function to get the start date of the quarter in the given format.
 * @param quarter number quarter for which the start date is required, defaults to current quarter
 * @param format String format of date, defaults to YYYY-MM-DD format
 * @return Start date of the given quarter in the given format
 */
export const getStartDateOfQuarter = (quarter?: number, format?: string) => {
  return moment()
    .quarter(quarter ?? moment().quarter())
    .startOf(QUARTER)
    .format(format ?? HYPHEN_DATE_FORMAT);
};

/**
 * @function getEndDateOfQuarter
 * @description Function to get the end date of the quarter in the given format.
 * @param quarter number quarter for which the end date is required, defaults to current quarter
 * @param format String format of date, defaults to YYYY-MM-DD format
 * @return End date of the given quarter in the given format
 */
export const getEndDateOfQuarter = (quarter?: number, format?: string) => {
  return moment()
    .quarter(quarter ?? moment().quarter())
    .endOf(QUARTER)
    .format(format ?? HYPHEN_DATE_FORMAT);
};

/**
 * @function getStartDateOfMonth
 * @description Function to get the start date of the month in the given format.
 * @param month number month for which the start date is required, defaults to current month
 * @param format String format of date, defaults to YYYY-MM-DD format
 * @return Start date of the given month in the given format
 */
export const getStartDateOfMonth = (month?: number, format?: string) => {
  return moment()
    .month(month ?? moment().month())
    .startOf(MONTH)
    .format(format ?? HYPHEN_DATE_FORMAT);
};

/**
 * @function getEndDateOfMonth
 * @description Function to get the end date of the month in the given format.
 * @param month number month for which the end date is required, defaults to current month
 * @param format String format of date, defaults to YYYY-MM-DD format
 * @return End date of the given month in the given format
 */
export const getEndDateOfMonth = (month?: number, format?: string) => {
  return moment()
    .month(month ?? moment().month())
    .endOf(MONTH)
    .format(format ?? HYPHEN_DATE_FORMAT);
};

/**
 * @function getStartDateNMonthsBack
 * @description Function to get the start date of the month n months back in the given format.
 * @param months number of months back for which the start date is required, defaults to current month
 * @param format String format of date, defaults to YYYY-MM-DD format
 * @return Start date of the month n months back in the given format
 */
export const getStartDateNMonthsBack = (months?: number, format?: string) => {
  return moment()
    .subtract(months ?? 0, MONTH)
    .startOf(MONTH)
    .format(format ?? HYPHEN_DATE_FORMAT);
};

/**
 * @function getEndDateNMonthsBack
 * @description Function to get the end date of the month n months back in the given format.
 * @param months number of months back for which the end date is required, defaults to current month
 * @param format String format of date, defaults to YYYY-MM-DD format
 * @return End date of the month n months back in the given format
 */
export const getEndDateNMonthsBack = (months?: number, format?: string) => {
  return moment()
    .subtract(months ?? 0, MONTH)
    .endOf(MONTH)
    .format(format ?? HYPHEN_DATE_FORMAT);
};

/**
 * @function formatHyphenDateFormatToMonthYear
 * @description Function to get the HyphenDate and format to Month Year
 * @param date Hyphen-date date string
 * @return String of Month year format
 */
export const formatHyphenDateFormatToMonthYear = (date: string) => {
  const dateSplit = date.split('-');
  return dateSplit[0] + dateSplit[1];
};

/**
 * @function getMonthYearShortList
 * @description Function to get the list of month and year in selected format.
 * @param startMonth String which contains start year and month in the format [year][month] Eg: 202203
 * @param endMonth String which contains end year and month in the format [year][month] Eg: 202203
 * @return list of string in the selected format
 */
export const getMonthYearShortList = (
  startMonth: string,
  endMonth: string,
  returnMonthFormat: string = MONTH_YEAR_SHORT
) => {
  let dateStart = moment(startMonth);
  let dateEnd = moment(endMonth);
  let labels = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    labels.push(dateStart.format(returnMonthFormat));
    dateStart.add(1, 'month');
  }
  return labels;
};

/**
 * @function getDateMonthShortList
 * @description Function to get the list of date and month in selected format.
 * @param startDate String which contains start date in the format [YYYY-MM-DD]
 * @param endDate String which contains end date in the format [YYYY-MM-DD]
 * @param dateInterval string interval to skip defaults to day
 * @param format format of the output date defaults to MMM DD
 * @return list of string in the selected format
 */
export const getDateMonthShortList = (
  startDate: string,
  endDate: string,
  dateInterval: moment.unitOfTime.DurationConstructor = 'day',
  format: string = DATE_MONTH_SHORT
) => {
  let dateStart = moment(startDate);
  let dateEnd = moment(endDate);
  let labels = [];

  while (dateEnd >= dateStart) {
    labels.push(dateStart.format(format));
    dateStart.add(1, dateInterval);
  }
  return labels;
};

/**
 * @function getListOfHours
 * @description Function to get the list of hours
 * @return list of hours
 */
export const getListOfHours = () => {
  let startDate = moment().startOf('day');

  let labels: string[] = [];
  Array(24)
    .fill(0)
    .forEach((_i, index) => {
      labels.push(
        startDate.add(index === 0 ? 0 : 1, 'hour').format(HOUR_MINUTE_SECONDS)
      );
    });

  return labels;
};

/**
 * @function getLastNMonthLabels
 * @description Function to get the month labels for the last n months.
 * @param n Number of months to get the labels for.
 * @param format Format of the month labels.
 * @returns Array of month labels. Example: ['Jan 2021', 'Feb 2021', 'Mar 2021']
 */
export const getLastNMonthLabels = (n: number, format = MONTH_YEAR_FORMAT) => {
  const monthLabels = [];
  for (let i = n - 1; i >= 0; i--)
    monthLabels.push(moment().subtract(i, 'month').format(format));
  return monthLabels;
};

/**
 * @function getLastNQuarterLabels
 * @description Function to get the quarter labels for the last n months.
 * @param n Number of quarters to get the labels for.
 * @returns Array of quarter labels. Example: ['Jan 21', 'Apr 21', 'Jul 21']
 */
export const getLastNQuarterLabels = (n: number) => {
  const quarterLabels = [];
  for (let i = n - 1; i >= 0; i--)
    quarterLabels.push(
      moment()
        .subtract(i, 'quarter')
        .startOf('quarter')
        .format(MONTH_YEAR_SHORT)
    );
  return quarterLabels;
};

/**
 * @function getLabelledDayFromDate
 * @description Function to get the labelled day for given date.
 * @param date date to convert into label.
 * @returns Labelled day
 */
export const getLabelledDayFromDate = (date: string) => {
  return moment(date).calendar({
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[Last] dddd',
    nextWeek: '[Next] dddd',
    sameElse: SLASH_DATE_FORMAT,
  });
};

/**
 * @function getLast24HrsDate
 * @description Function to get the last 24 hours date.
 * @returns 24 hours ago date
 */
export const getLast24HrsDate = () => {
  const currentDate = moment();
  const last24HoursDate = currentDate.subtract(24, 'hours');
  return last24HoursDate.format(HYPHEN_DATE_TIME_HH_MM_SS);
};

/**
 * @function getFormattedDateForTimestamp
 * @description Function to format the timestamp
 * @param inputTimestamp String which contains the timestamp
 * @return formatted date [month][year]. Eg Apr 23
 */
export const getFormattedDateForTimestamp = (inputTimestamp: string) => {
  const date = moment(inputTimestamp);
  return date.format(MONTH_YEAR_SHORT);
};

/**
 * @function formatDateFieldByProvider
 * @description function to format the date field by provider
 * @param date input date
 * @param provider input provider
 * @param isStaticData boolean to indicate whether the date is for static file data
 * @returns date in corresponding formats supported by each provider
 * @requires date to be in HYPHEN_DATE_FORMAT
 */
export const formatDateFieldByProvider = (
  date: string,
  provider: string,
  isStaticData: boolean,
  isFocusConversionEnabled?: boolean
) => {
  switch (provider) {
    case PROVIDER.AWS: {
      const hyphenDate = moment(date).format(HYPHEN_DATE_FORMAT);
      return isStaticData ? hyphenDate : `#DATE('${hyphenDate}')`;
    }

    case PROVIDER.AZURE:
      return moment(date).format(HYPHEN_DATE_FORMAT);

    case PROVIDER.GCP:
    case PROVIDER.OCI:
    default:
      return isStaticData && isFocusConversionEnabled
        ? moment(date).format(HYPHEN_DATE_FORMAT)
        : moment(date).format(YEAR_MONTH_WITHOUT_SEPARATOR);
  }
};

/**
 * @function enumerateDaysBetweenDates
 * @description Function to list the dates with hours
 * @param dateRange Date Range for which the list is populated
 * @param format Format of the listed dates
 * @param skipDuration Duration unit to skip the date or time
 * @returns List of dates in the given format
 */
export const enumerateDaysBetweenDates = function (
  dateRange: string[],
  format: string = DATE_TIME_AM_PM,
  skipDuration: DurationInputArg2 = 'hour'
) {
  const dates = [];

  let currDate = moment(dateRange[0]).startOf('day');
  const lastDate = moment(dateRange[1]).endOf('day');

  do {
    dates.push(currDate.format(format));
  } while (currDate.add(1, skipDuration).diff(lastDate) < 0);

  return dates;
};

/**
 * @function getDateFilterAsString
 * @description Function to get the date filter as string
 * @param startDate start date
 * @param endDate end date
 * @returns Date filter as string
 * @example Input: '2022-01-01', '2022-01-31' => Output: 'Jan 01, 2022 - Jan 31, 2022'
 */
export const getDateFilterAsString = (startDate: string, endDate: string) => {
  return `${moment(startDate, HYPHEN_DATE_FORMAT).format(
    DATE_FORMAT
  )} - ${moment(endDate, HYPHEN_DATE_FORMAT).format(DATE_FORMAT)}`;
};
