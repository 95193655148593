import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { ICONS } from 'constants/icons';
import { ConnectionDependencyType } from 'pages/ConnectingCSPPage/types';

type DependencyHeadingProps = {
  dependency: ConnectionDependencyType;
};

const DependencyHeading = ({ dependency }: DependencyHeadingProps) => {
  const { t } = useTranslation();

  return (
    <div className="dependency-heading flex flex-column flex-gap-8">
      <div className="form-header">{dependency.title}</div>
      <div className="release-date font-caption flex flex-align-items-center flex-gap-4">
        {t('viewPathInstruction', {
          path: dependency.dependencySectionPath,
          interpolation: { escapeValue: false },
        })}
        <Tooltip title={t('menuNotFoundInfo')}>
          <span>
            <Icon
              iconName={ICONS.INFORMATION_LINE}
              dataTestId="access-info-icon"
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default DependencyHeading;
