export const COLORS = {
  primaryButton: '#468629',
  primaryGreen: '#86bc25',
  fnYellow: '#ffca64',
  fnOrange: '#e68157',
  fnGreen: '#569968',
  fnPink: '#dc789c',
  fnViolet: '#789adc',
  secondaryBackground: '#FDFFF9',
  fnGrey: '#97999b',
  fnGrey5: '#76787B', // $color-grey-5
  fnGrey13: '#f2f3f5', // $color-grey-13
  secondaryBlack: '#0A1E40',
  green1: '#CEECC1',
  green2: '#AFE09A',
  green3: '#71C84B',
  green4: '#59AC35',
  colorScrollBar: '#BCC4CD',
  white: '#FFFFFF',
  colorRegentGrey: '#8798B0',
  brightGreen: '#70ec48',
  fnRed: '#d23927',
  secondaryPurple: '#1f00f0',
  goldFoil: '#c29217',
};

export enum POINT_STYLE {
  RECT_ROUNDED = 'rectRounded',
  LINE = 'line',
}

export const GRAPH_OPTIONS = {
  legendPositionBottom: 'bottom',
  legendPositionRight: 'right',
  legendpointStyle: POINT_STYLE.RECT_ROUNDED,
  legendBoxWidth: 16,
  legendpadding: 16,
  layoutPadding: 16,
  xAxisMaximumValue: 1000,
  xAxisMinimumValue: 0,
  yAxisMaximumValue: 1000,
  yAxisMinimumValue: 0,
  stepSize: 100,
  xAxisPadding: 8,
  yAxisPadding: 8,
  indexAxis: 'x',
  legendAlignment: 'center',
  pointRadius: 0,
  cursorDefault: 'default',
  sliderCountLimit: 10,
};

export enum CHART_TYPES {
  TABLE = 'TABLE',
  PIE_CHART = 'PIE_CHART',
  DOUGHNUT_CHART = 'DOUGHNUT_CHART',
  LINE_CHART = 'LINE_CHART',
  BAR_CHART = 'BAR_CHART',
  BAR_LINE_CHART = 'BAR_LINE_CHART',
  GROUPED_CHART = 'GROUPED_CHART',
  HORIZONTAL_BAR_CHART = 'HORIZONTAL_BAR_CHART',
  STACK_CHART = 'STACK_CHART',
  AREA_CHART = 'AREA_CHART',
  MAP = 'MAP',
  NONE = 'NONE',
}

export enum PIE_CHART_TYPE {
  PIE_CHART = 'pie-chart',
  DOUGHNUT_GRAPH = 'doughnut-graph',
}
