import { DASHBOARD_TYPES } from 'constants/requestBody';

/**
 * @function modifyQueryForCount
 * @description Function to return the query for fetching the count of the sub query
 * @param subQuery Sub query for which the count is fetched
 * @return Object containing the updated query
 */
export const modifyQueryForCount = (subQuery: any) => {
  return {
    columns: [
      {
        label: 'totalCount',
        field: 'COUNT(*)',
      },
    ],
    subQuery: subQuery,
    dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
    cached: true,
  };
};
