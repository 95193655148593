import i18n from 'i18n';
import { store } from 'redux/store';

const state = store.getState();
const currencySymbol = state.commonUtility.currencySymbol;

export const AllocationTrendExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('costAllocationDashboardLabels.costAllocationSummary.month'),
    key: 'month',
    dataKey: 'month',
    width: 15,
    alignment: 'center',
  },
  {
    header: i18n.t(
      'costAllocationDashboardLabels.costAllocationSummary.totalSharedCostWithCurrency',
      { currencySymbol: currencySymbol }
    ),
    key: 'sharedCost',
    dataKey: 'sharedCost',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t(
      'costAllocationDashboardLabels.costAllocationSummary.allocatedCostWithCurrency',
      { currencySymbol: currencySymbol }
    ),
    key: 'allocatedCost',
    dataKey: 'allocatedCost',
    width: 20,
    alignment: 'right',
  },
  {
    header: i18n.t(
      'costAllocationDashboardLabels.costAllocationSummary.totalResidualCostWithCurrency',
      { currencySymbol: currencySymbol }
    ),
    key: 'residualCost',
    dataKey: 'residualCost',
    width: 20,
    alignment: 'right',
  },
];

export const getAllocationByBusinessUnitExportColumns = (
  currencySymbol: string
) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t(
      'costAllocationDashboardLabels.allocationByBusinessUnit.businessUnit'
    ),
    key: 'name',
    dataKey: 'name',
    width: 30,
  },
  {
    header: i18n.t(
      'costAllocationDashboardLabels.allocationByBusinessUnit.cost',
      {
        currencySymbol: currencySymbol,
      }
    ),
    key: 'cost',
    dataKey: 'cost',
    width: 20,
    alignment: 'right',
  },
];

export const getAllocationByCostCenterCodeExportColumns = (
  currencySymbol: string
) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t(
      'costAllocationDashboardLabels.allocationByCSC.costCenterCode'
    ),
    key: 'name',
    dataKey: 'name',
    width: 30,
  },
  {
    header: i18n.t('costAllocationDashboardLabels.allocationByCSC.cost', {
      currencySymbol: currencySymbol,
    }),
    key: 'cost',
    dataKey: 'cost',
    width: 20,
    alignment: 'right',
  },
];
