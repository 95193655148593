import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/Loader';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  deleteConnectionFile,
  fetchConnectionById,
  fetchFileMetaData,
} from 'utils/services';
import {
  cloudConnection,
  setAccessDetails,
  setCurrentConnection,
  setCurrentStepValidation,
  setResetStepForm,
} from 'redux/cloudConnectionSlice';
import {
  FREE_TEXT_CHARACTER_LIMIT,
  MAX_CHARACTER_LIMIT,
} from 'constants/validation';
import { validateStringLengthLessThan } from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';
import {
  ACCESS_DATA_VALUES,
  SECRET_KEY_PLACEHOLDER,
  VALIDATION_STATUS,
} from 'pages/ConnectingCSPPage/constants';

import BasicDetails from '../BasicDetails';
import { AccessDetails } from './components/AccessDetails';
import { BillingDetails } from './components/BillingDetails';
import { RecommendationDetails } from './components/RecommendationDetails';
import { ContainerCostDetails } from './components/ContainerCostDetails';

const AWSConnectionForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    currentConnection,
    isTryAgain,
    isEditConnection,
    accessDetails,
    currentStep,
    resetStepForm,
  } = useSelector(cloudConnection);

  const [isDuplicateConnectionName, setIsDuplicateConnectionName] =
    useState(true);
  const [connectionName, setConnectionName] = useState('');

  const [accEnvMapFileIdValidation, setAccEnvMapFileIdValidation] =
    useState('');
  const [fileName, setFileName] = useState('');
  const [
    fetchConnectionDataRequestStatus,
    setFetchConnectionDataRequestStatus,
  ] = useState('');

  useEffect(() => {
    if (isEditConnection) {
      setIsDuplicateConnectionName(false);
      setProviderInitialValues();
      currentConnection?.accEnvMapFileId && fetchFilename();
      return;
    }

    if (isTryAgain) {
      setIsDuplicateConnectionName(false);
      currentConnection?.accEnvMapFileId && fetchFilename();
      return;
    }

    dispatch(
      setCurrentConnection({
        ...currentConnection,
        wantBilling: true,
        wantRecommendations: true,
      })
    );
  }, []);

  useEffect(() => {
    if (resetStepForm) {
      switch (currentStep) {
        case 1:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              dataSourceType: '',
              displayName: '',
              name: '',
              description: '',
              planType: '',
            })
          );
          break;
        case 2:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              awsAccessKeyId: '',
              awsSecretKey: '',
              awsRegion: '',
              awsS3OutputLocation: '',
            })
          );
          dispatch(setAccessDetails([]));
          break;
        case 3:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantBilling: false,
              dataCatalog: '',
              billingDataset: '',
              billingTableName: '',
            })
          );
          break;
        case 4:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantRecommendations: false,
              recommendationsDataCatalog: '',
              recommendationDataset: '',
              recommendationsTableName: '',
              accEnvMapFileId: undefined,
            })
          );
          setFileName('');
          break;

        case 5:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantContainerCost: false,
              containerInsightDataCatLog: '',
              containerInsightDataset: '',
              containerInsightTableName: '',
            })
          );
          break;
      }
      dispatch(setResetStepForm(false));
    }
  }, [resetStepForm]);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        dispatch(setCurrentStepValidation(validateBasicDetailsFields()));
        break;
      case 2:
        dispatch(setCurrentStepValidation(validateAccessDetailsFields()));
        break;
      case 3:
        dispatch(setCurrentStepValidation(validateBillingDetailsFields()));
        break;
      case 4:
        dispatch(
          setCurrentStepValidation(validateRecommendationDetailsFields())
        );
        break;
      case 5:
        dispatch(
          setCurrentStepValidation(validateContainerCostDetailsFields())
        );
        break;
      default:
        dispatch(setCurrentStepValidation(VALIDATION_STATUS.INVALID));
        break;
    }
  }, [
    currentStep,
    currentConnection,
    isDuplicateConnectionName,
    accessDetails,
  ]);

  /**
   * @function fetchFilename
   * @description Function to fetch the filename from the server
   */
  const fetchFilename = () => {
    fetchFileMetaData(currentConnection?.accEnvMapFileId).then((res: any) => {
      setFileName(res?.data?.responseData?.fileName ?? '');
    });
  };

  /**
   * @function setProviderInitialValues
   * @description Function to set initial values from the edit page
   */
  const setProviderInitialValues = () => {
    setFetchConnectionDataRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchConnectionById(currentConnection.connectorId)
      .then((res: any) => {
        const connectionDetails = res?.data?.responseData;
        dispatch(
          setCurrentConnection({
            ...currentConnection,
            awsAccessKeyId: SECRET_KEY_PLACEHOLDER,
            awsSecretKey: SECRET_KEY_PLACEHOLDER,
            awsS3OutputLocation: connectionDetails.awsS3OutputLocation ?? '',
            name: connectionDetails.name,
            description: connectionDetails.description,
          })
        );
        setConnectionName(connectionDetails.displayName);
        setFetchConnectionDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((error: any) => {
        onApiCallError(error, true, setFetchConnectionDataRequestStatus);
      });
  };

  /**
   * @function validateBasicDetailsFields
   * @description Function to validate basic details fields
   * @return boolean true if validation is success else false
   */
  const validateBasicDetailsFields = () => {
    if (
      currentConnection?.displayName &&
      !validateStringLengthLessThan(
        currentConnection?.displayName,
        MAX_CHARACTER_LIMIT
      ) &&
      ((isEditConnection &&
        currentConnection?.displayName === connectionName) ||
        !isDuplicateConnectionName) &&
      currentConnection?.dataSourceType &&
      !validateStringLengthLessThan(
        currentConnection?.description,
        FREE_TEXT_CHARACTER_LIMIT
      ) &&
      currentConnection.planType
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateAccessDetailsFields
   * @description Function to validate access details fields
   * @return boolean true if validation is success else false
   */
  const validateAccessDetailsFields = () => {
    if (!validateBasicDetailsFields()) {
      return VALIDATION_STATUS.INVALID;
    }

    if (
      currentConnection?.awsAccessKeyId &&
      currentConnection?.awsSecretKey &&
      currentConnection?.awsRegion &&
      currentConnection?.awsS3OutputLocation &&
      (isEditConnection ||
        accessDetails.find(
          (value) => value.role === ACCESS_DATA_VALUES.HAS_BILLING_ACCESS
        )?.haveAccess)
    ) {
      return VALIDATION_STATUS.VALID;
    }

    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateBillingDetailsFields
   * @description Function to validate the billing details fields
   * @return boolean true if validation is success else false
   */
  const validateBillingDetailsFields = () => {
    if (
      !currentConnection?.dataCatalog ||
      !currentConnection?.billingDataset ||
      !currentConnection?.billingTableName
    )
      return VALIDATION_STATUS.SKIP;
    else if (
      currentConnection?.dataCatalog &&
      currentConnection?.billingDataset &&
      currentConnection?.billingTableName
    )
      return VALIDATION_STATUS.VALID;

    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateRecommendationDetailsFields
   * @description Function to validate the billing details fields
   * @return boolean true if validation is success else false
   */
  const validateRecommendationDetailsFields = () => {
    if (
      !currentConnection?.recommendationsDataCatalog ||
      !currentConnection?.recommendationDataset ||
      !currentConnection?.accEnvMapFileId
    )
      return VALIDATION_STATUS.SKIP;
    else if (
      currentConnection?.recommendationsDataCatalog &&
      currentConnection?.recommendationDataset &&
      currentConnection?.accEnvMapFileId
    )
      return VALIDATION_STATUS.VALID;

    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateContainerCostDetailsFields
   * @description Function to validate the container cost details fields
   * @return boolean true if validation is success else false
   */
  const validateContainerCostDetailsFields = () => {
    if (
      !currentConnection?.containerInsightDataCatLog ||
      !currentConnection?.containerInsightDataset ||
      !currentConnection?.containerInsightTableName
    )
      return VALIDATION_STATUS.SKIP;
    else if (
      currentConnection?.containerInsightDataCatLog &&
      currentConnection?.containerInsightDataset &&
      currentConnection?.containerInsightTableName
    )
      return VALIDATION_STATUS.VALID;

    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function removeFile
   * @description Function to remove uploaded key json file from the form
   */
  const removeFile = async () => {
    if (currentConnection?.accEnvMapFileId) {
      await deleteConnectionFile(currentConnection?.accEnvMapFileId);
      dispatch(
        setCurrentConnection({
          ...currentConnection,
          accEnvMapFileId: undefined,
        })
      );
      setFileName('');
      setAccEnvMapFileIdValidation(
        `${t('connectionCSPForm.gcpUploadLabel')} ${t(
          'connectionCSPForm.isRequired'
        )}`
      );
    }
  };

  const getFormByStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicDetails
            setIsDuplicateConnectionName={setIsDuplicateConnectionName}
            connectionName={connectionName}
          />
        );
      case 2:
        return <AccessDetails />;
      case 3:
        return <BillingDetails />;
      case 4:
        return (
          <RecommendationDetails
            removeFile={removeFile}
            fileName={fileName}
            setFileName={setFileName}
            accEnvMapFileIdValidation={accEnvMapFileIdValidation}
            setAccEnvMapFileIdValidation={setAccEnvMapFileIdValidation}
          />
        );
      case 5:
        return <ContainerCostDetails />;
    }
  };

  if (fetchConnectionDataRequestStatus === REQUEST_STATUS.PROCESSING) {
    return <Loader />;
  }

  return (
    <div
      className="cloud-connection-form flex-fit"
      data-testid="aws-connection-form"
    >
      {getFormByStep()}
    </div>
  );
};

export default AWSConnectionForm;
