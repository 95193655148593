import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';
import { EmailRoleMap, SearchUserExistParamsType } from './types';

/**
 * @function createUser
 * @description Function to add user
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const createUser = async (body: EmailRoleMap[]) => {
  const requestAPI = APIS.USER_MANAGEMENT;
  return ServiceCalls.post(requestAPI, body);
};

/**
 * @function searchUserExist
 * @description Function to check whether user exist while adding users
 * @params params- request parameters
 * @return axios response from POST request
 */
export const searchUserExist = async (params: SearchUserExistParamsType) => {
  return ServiceCalls.post(APIS.SEARCH_USER_EXIST, null, null, params);
};

/**
 * @function deleteUserByID
 * @description Function to delete the user by id
 * @params params- request parameters
 * @return axios response from DELETE request
 */
export const deleteUserByID = async (params: { id: number }) => {
  return ServiceCalls.delete(APIS.DELETE_USER, null, params);
};
