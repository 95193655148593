import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DOLLAR } from 'constants/utilityConstants';
import { RootState } from './store';

export interface CommonUtilitySlice {
  currencySymbol: string;
}

const initialState: CommonUtilitySlice = {
  currencySymbol: DOLLAR,
};

export const commonUitlitySlice = createSlice({
  name: 'commonUtility',
  initialState,
  reducers: {
    setCurrencySymbol: (state, action: PayloadAction<string>) => {
      state.currencySymbol = action.payload;
    },
  },
});

export const selectCommonUtility = (state: RootState) => state.commonUtility;
export const { setCurrencySymbol } = commonUitlitySlice.actions;
export default commonUitlitySlice.reducer;
