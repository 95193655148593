import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { COLORS } from 'constants/graphConfig';
import {
  getFirstTableFilterGroup,
  updateFilterQuery,
  getColumnLabelByField,
} from 'pages/CustomDashboardPage/utils';

import './index.scss';

const FilterList = () => {
  const { t } = useTranslation();

  const removeFilterField = (filterField: string) => {
    const filters = getFirstTableFilterGroup().filter(
      (filter) => filter.field !== filterField
    );
    updateFilterQuery(filters);
  };

  return (
    <div className="filter-list flex flex-column flex-gap-8">
      {getFirstTableFilterGroup()?.length === 0 ? (
        <div className="empty-filter-message font-subHeader-small">
          {t('customDashboard.optionsLabels.clickToAddFilter')}
        </div>
      ) : (
        getFirstTableFilterGroup().map((filter) => (
          <div
            className="flex flex-space-between flex-align-items-center"
            key={filter.field}
          >
            <span className="font-subHeader">
              {getColumnLabelByField(filter.field)}
            </span>
            <Icon
              iconName={ICONS.SUBTRACT_LINE}
              color={COLORS.colorRegentGrey}
              onClick={() => removeFilterField(filter.field)}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default FilterList;
