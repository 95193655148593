import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchAllAWSBillingServices
 * @description Function all services for budget
 * @param params Object containing the connector Id
 * @return axios response from GET request
 */
export const fetchAllAWSBillingServices = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AWS_BUDGET_SERVICES, null, params);
};

/**
 * @function createBudgetAWS
 * @description Function to create new AWS budget
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const createBudgetAWS = async (body: any) => {
  return ServiceCalls.post(APIS.AWS_CREATE_BUDGET, body);
};

/**
 * @function fetchAllAWSBudgets
 * @description Function to fetch all budgets
 * @return axios response from GET request
 */
export const fetchAllAWSBudgets = async () => {
  return ServiceCalls.get(APIS.AWS_GET_ALL_BUDGET);
};

/**
 * @function fetchAllAzureServices
 * @description Function all services for budget
 * @params body : Object containing the accesskey id and secret key
 * @return axios response from POST request
 */
export const fetchAllAzureServices = async (params: any) => {
  return ServiceCalls.get(APIS.AZURE_GET_ALL_BILLING_SERVICE, null, params);
};

/**
 * @function createBudgetAzure
 * @description Function to create new Azure budget
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const createBudgetAzure = async (body: any) => {
  return ServiceCalls.post(APIS.AZURE_CREATE_BUDGET, body);
};

/**
 * @function getAzureBudget
 * @param params Contains body for request parameters
 * @description Function to get AZURE budget
 * @return axios response from get request
 */
export const getAzureBudget = async (params: any) => {
  return ServiceCalls.get(APIS.GET_AZURE_BUDGET, null, params);
};

/**
 * @function updateAzureBudget
 * @description Function to update AzureBudget
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const updateAzureBudget = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_AZURE_BUDGET, body);
};

/**
 * @function fetchAllAzureBudgets
 * @description Function to fetch all azure budgets
 * @return axios response from GET request
 */
export const fetchAllAzureBudgets = async () => {
  return ServiceCalls.get(APIS.AZURE_GET_ALL_BUDGET);
};

/**
 * @function fetchAllOciBudgets
 * @description Function to fetch all Oci budgets
 * @return axios response from GET request
 */
export const fetchAllOciBudgets = async () => {
  return ServiceCalls.get(APIS.OCI_GET_ALL_BUDGET);
};

/**
 * @function fetchAllOciCompartments
 * @description Function to fetch all OCI Compartments
 * @return axios response from GET request
 */
export const fetchAllOciCompartments = async (params: any) => {
  return ServiceCalls.get(APIS.OCI_GET_ALL_COMPARTMENTS, null, params);
};

/**
 * @function getOciBudget
 * @param params Contains body for request parameters
 * @description Function to get Oci budget
 * @return axios response from get request
 */
export const getOciBudget = async (params: any) => {
  return ServiceCalls.get(APIS.GET_OCI_BUDGET, null, params);
};

/**
 * @function createBudgetOci
 * @description Function to create new Oci budget
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const createBudgetOci = async (body: any) => {
  return ServiceCalls.post(APIS.OCI_CREATE_BUDGET, body);
};

/**
 * @function deleteOciBudget
 * @description Function to delete Oci budget
 * @param params : Object containing having the request parameters.
 * @return axios response from POST request
 */
export const deleteOciBudget = async (params: any) => {
  return ServiceCalls.delete(APIS.DELETE_OCI_BUDGET, null, params);
};

/**
 * @function updateOciBudget
 * @description Function to update Oci Budget
 * @params body : Object containing the request body
 * @return axios response from POST request
 */
export const updateOciBudget = async (body: any) => {
  return ServiceCalls.put(APIS.UPDATE_OCI_BUDGET, body);
};

/**
 * @function verifyGcpBudgetAccess
 * @description Function to get the budget access details for a GCP connection
 * @return axios response from POST request
 */
export const verifyGcpBudgetAccess = async (params: any) => {
  return ServiceCalls.post(APIS.VERIFY_GCP_BUDGET_ACCESS, null, null, params);
};

/**
 * @function verifyAwsBudgetAccess
 * @description Function to get the budget access details for a Aws connection
 * @return axios response from POST request
 */
export const verifyAwsBudgetAccess = async (params: any) => {
  return ServiceCalls.post(APIS.VERIFY_AWS_BUDGET_ACCESS, null, null, params);
};

/**
 * @function verifyAzureBudgetAccess
 * @description Function to get the budget access details for a Azure connection
 * @return axios response from POST request
 */
export const verifyAzureBudgetAccess = async (params: any) => {
  return ServiceCalls.post(APIS.VERIFY_AZURE_BUDGET_ACCESS, null, null, params);
};
