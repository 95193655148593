import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SuccessIcon } from 'assets/icons';
import ProviderList from 'components/ConnectionProviderList';
import ErrorComponent from 'components/ErrorComponent';
import SuccessComponent from 'components/SuccessComponent';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { CostMonitorComponents } from 'pages/CostMonitorPage/constants';
import {
  costMonitor,
  setCostMonitorComponent,
  setIsTryAgain,
} from 'redux/costMonitorSlice';
import { providerList } from 'redux/providerSlice';
import { PROVIDER } from 'constants/cloudProviders';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import AWSCostMonitorForm from '../AWSCostMonitorForm';

import './index.scss';

const CostMonitorForm = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'costMonitor.createCostMonitorLabels',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { costMonitorComponent, isEditForm, costMonitorFormData } =
    useSelector(costMonitor);
  const { selectedProvider } = useSelector(providerList);

  useEffect(() => {
    if (!costMonitorFormData.name) {
      dispatch(setCostMonitorComponent(CostMonitorComponents.PROVIDER_LIST));
    }
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function getCostMonitorFormByProvider
   * @description Function for returning the component for Cost monitor form by provider
   * @returns JSX element
   */
  const getCostMonitorFormByProvider = () => {
    if (selectedProvider === PROVIDER.AWS) {
      return <AWSCostMonitorForm />;
    } else return <></>;
  };

  /**
   * @function getComponent
   * @description Function for returning the component based on the component set
   * @returns JSX element
   */
  const getComponent = () => {
    const { PROVIDER_LIST, COST_MONITOR_FORM, SUCCESS_PAGE, ERROR_PAGE } =
      CostMonitorComponents;

    switch (costMonitorComponent) {
      case PROVIDER_LIST:
        return (
          <div className="page-content cost-monitor-provider-list vertical-margin-24">
            <ProviderList
              handleContinueButtonClick={() =>
                dispatch(setCostMonitorComponent(COST_MONITOR_FORM))
              }
              handleBackArrowClick={() =>
                navigate(NAVIGATION_MENU_PATH.COST_MONITOR)
              }
              heading={t('selectCloudProvider')}
              subHeading={t('cloudProviderListSubHeading')}
              disabledList={[PROVIDER.GCP, PROVIDER.AZURE, PROVIDER.OCI]}
            />
          </div>
        );

      case COST_MONITOR_FORM:
        return getCostMonitorFormByProvider();
      case SUCCESS_PAGE:
        return (
          <SuccessComponent
            mainTitle={
              isEditForm ? t('successfullyUpdated') : t('successfullyCreated')
            }
            subTitle={
              isEditForm
                ? t('successfullyUpdatedSubTitle')
                : t('successfullyCreatedSubTitle')
            }
            buttonTitle={t('goToAnomalyDetection')}
            icon={<SuccessIcon />}
            onHandleButtonClick={() =>
              navigate(NAVIGATION_MENU_PATH.ANOMALY_DASHBOARD)
            }
            linkTitle={t('gotoCostMonitorList')}
            onHandleLinkClick={() =>
              navigate(NAVIGATION_MENU_PATH.COST_MONITOR)
            }
          />
        );
      case ERROR_PAGE:
        return (
          <ErrorComponent
            mainTitle={
              isEditForm
                ? t('errorUpdatingCostMonitor')
                : t('errorCreatingCostMonitor')
            }
            buttonTitle={t('tryAgain')}
            onHandleButtonClick={() => {
              dispatch(setIsTryAgain(true));
              dispatch(
                setCostMonitorComponent(CostMonitorComponents.COST_MONITOR_FORM)
              );
            }}
            linkTitle={t('gotoCostMonitorList')}
            onHandleLinkClick={() =>
              navigate(NAVIGATION_MENU_PATH.COST_MONITOR)
            }
          />
        );
    }
  };

  return <>{getComponent()}</>;
};

export default CostMonitorForm;
