import {
  CategoryRecommenderMappingType,
  ViewListType,
} from 'pages/CostOptimizationInsightsPage/types';
import { FilterKeys } from 'pages/CostOptimizationInsightsPage/constants';
import {
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { FilterGroupType, FilterType } from 'types/dashboard';
import { formatArrayToString } from 'utils/dataFormatterUtils';
import {
  RECOMMENDATION_QUERY_COLUMNS,
  RULESET_FILTERS_ADDITIONAL_QUERY_COLUMNS,
} from './constants';

/**
 * @function getCurrentCategoryRecommenders
 * @description Function to get the recommenders for the current selected category
 * @param categoryRecommenderMapping category to list of recommenders mapping
 * @param selectedCostOptimizationInsightsNav selected navigation menu within cost optimization insights
 * @returns List of recommenders
 */
const getCurrentCategoryRecommenders = (
  categoryRecommenderMapping: CategoryRecommenderMappingType[],
  selectedCostOptimizationInsightsNav: string
) =>
  categoryRecommenderMapping?.find(
    (value) => value.category === selectedCostOptimizationInsightsNav
  )?.recommenders!;

/**
 * @function getFilterObjectByFilterKey
 * @description Function to construct the filter object based on the key and value.
 * @param key string filter key
 * @param value: array of string values
 * @returns array of filter object
 */
const getFilterObjectByFilterKey = (key: string, value: string[]) => {
  switch (key) {
    case FilterKeys.PRIMARY_IMPACT_CATEGORY:
      return [
        {
          field: QUERY_FIELDS.CATEGORY,
          comparator: COMPARATORS.IN,
          value: formatArrayToString(value),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];

    case FilterKeys.RECOMMENDATION_DESCRIPTION:
      return [
        {
          field: QUERY_FIELDS.DESCRIPTION,
          comparator: COMPARATORS.LIKE,
          value: `%${value[0]}%`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];

    case FilterKeys.LAST_REFRESH_DATE_RANGE:
      return [
        {
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: value[0].split(',')[0],
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: value[0].split(',')[1],
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];

    case FilterKeys.COST_SAVINGS:
      return value.map((commaJoinedValue) => {
        return {
          field: QUERY_FIELDS.SUM_IF_NULL_UNITS_NANOS,
          comparator: commaJoinedValue.split(',')[0],
          value: '#' + commaJoinedValue.split(',')[1],
          conjunctToNextFilter: commaJoinedValue.split(',')[2],
        };
      });

    default:
      return [
        {
          field: key,
          comparator: COMPARATORS.IN,
          value: formatArrayToString(value),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
  }
};

/**
 * @function getQueryFilters
 * @description Function to construct the filters for query.
 * @param currentRecommendationView recommendation view selected
 * @param categoryRecommenderMapping category to list of recommenders mapping
 * @param selectedCostOptimizationInsightsNav selected navigation menu within cost optimization insights
 * @returns list of filters
 */
export const getQueryFilters = (
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string
) => {
  let filters: FilterType[] = [
    {
      field: QUERY_FIELDS.SEQUENCE_NUMBER,
      comparator: COMPARATORS.EQUALS,
      value: '#1',
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
  ];
  if (
    currentRecommendationView.recommendationsFilterDtoList &&
    !currentRecommendationView.recommendationsFilterDtoList.some(
      (value: any) => value.key === FilterKeys.RECOMMENDER
    ) &&
    categoryRecommenderMapping
  ) {
    filters.push({
      field: FilterKeys.RECOMMENDER,
      comparator: COMPARATORS.IN,
      value: formatArrayToString(
        getCurrentCategoryRecommenders(
          categoryRecommenderMapping,
          selectedCostOptimizationInsightsNav
        )
      ),
      conjunctToNextFilter: CONJUNCTIONS.AND,
    });
  }
  currentRecommendationView?.recommendationsFilterDtoList
    ?.filter((item) => item.key !== FilterKeys.RULESET)
    ?.forEach((filter: any) => {
      filter.values &&
        filter.values.length > 0 &&
        filters.push(
          ...getFilterObjectByFilterKey(
            filter.key,
            filter?.values?.map((item: any) => item.value) ?? []
          )
        );
    });

  return filters;
};

const getQueryFilterGroups = (
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string
) => {
  return [
    {
      conjunctToNextGroup: CONJUNCTIONS.OR,
      filters: [
        ...getQueryFilters(
          currentRecommendationView,
          categoryRecommenderMapping,
          selectedCostOptimizationInsightsNav
        ),
        {
          field: QUERY_FIELDS.STATE,
          comparator: COMPARATORS.EQUALS,
          value: QUERY_VALUES.CLAIMED,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: QUERY_VALUES.DATE_INTERVAL_90_DAYS,
        },
      ],
    },
    {
      conjunctToNextGroup: CONJUNCTIONS.OR,
      filters: [
        ...getQueryFilters(
          currentRecommendationView,
          categoryRecommenderMapping,
          selectedCostOptimizationInsightsNav
        ),
        {
          field: QUERY_FIELDS.STATE,
          comparator: COMPARATORS.EQUALS,
          value: QUERY_VALUES.ACTIVE,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: QUERY_VALUES.DATE_INTERVAL_10_DAYS,
        },
      ],
    },
    {
      conjunctToNextGroup: CONJUNCTIONS.OR,
      filters: [
        ...getQueryFilters(
          currentRecommendationView,
          categoryRecommenderMapping,
          selectedCostOptimizationInsightsNav
        ),
        {
          field: QUERY_FIELDS.STATE,
          comparator: COMPARATORS.NOT_IN,
          value: formatArrayToString([
            QUERY_VALUES.ACTIVE,
            QUERY_VALUES.CLAIMED,
            QUERY_VALUES.SUCCEEDED,
          ]),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.LAST_REFRESH_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: QUERY_VALUES.DATE_INTERVAL_365_DAYS,
        },
      ],
    },
  ];
};

/**
 * @function getGcpRecommendationTableDefaultQuery
 * @description Function to construct the query for the default view and views without ruleset filter for GCP.
 * @param currentRecommendationView recommendation view selected
 * @param categoryRecommenderMapping category to list of recommenders mapping
 * @param selectedCostOptimizationInsightsNav selected navigation menu within cost optimization insights
 * @returns Object containing query for recommendations
 */
export const getGcpRecommendationTableDefaultQuery = (
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string
) => {
  return {
    columns: RECOMMENDATION_QUERY_COLUMNS,
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.SEQUENCE_NUMBER,
          field: QUERY_VALUES.SEQUENCE_NUMBER_VALUE_DESC,
        },
      ],
    },
    orderBy: [
      {
        label: 'recommendationName',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: getQueryFilterGroups(
      currentRecommendationView,
      categoryRecommenderMapping,
      selectedCostOptimizationInsightsNav
    ),
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: false,
  };
};

/**
 * @function getGcpRulesetFilterRecommendationQuery
 * @description Function to construct the query for the views with ruleset filters for GCP.
 * @param currentRecommendationView recommendation view selected
 * @param categoryRecommenderMapping category to list of recommenders mapping
 * @param selectedCostOptimizationInsightsNav selected navigation menu within cost optimization insights
 * @param filterGroups list of filter groups for the ruleset filter dto
 * @returns Object containing query for recommendations
 */
export const getGcpRulesetFilterRecommendationQuery = (
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string,
  filterGroups: FilterGroupType[]
) => {
  return {
    recommendationsDto: {
      ...getGcpRecommendationTableDefaultQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav
      ),
      columns: [
        ...RECOMMENDATION_QUERY_COLUMNS,
        ...RULESET_FILTERS_ADDITIONAL_QUERY_COLUMNS,
      ],
    },
    insightsDto: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'insight_subtype',
          field: QUERY_FIELDS.SUBTYPE,
        },
        {
          label: 'last_used_time',
          field:
            "CAST(TIMESTAMP_DIFF(CURRENT_TIMESTAMP(),TIMESTAMP(JSON_EXTRACT_SCALAR(content, '$.diskLastUseTime')), DAY) as STRING)",
        },
      ],
      structColumns: [
        {
          label: 'insight',
          field: QUERY_FIELDS.INSIGHT,
        },
        {
          label: 'quantileFunctionMap',
          field:
            "ARRAY(SELECT as STRUCT CAST(JSON_EXTRACT_SCALAR(x, '$.quantileFunctionValue') AS FLOAT64) quantileFunctionValue,  CAST(JSON_EXTRACT_SCALAR(x, '$.sampleProbability') AS FLOAT64) sampleProbability FROM UNNEST(JSON_EXTRACT_ARRAY(content, '$.points')) x)",
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
          {
            label: 'seqnum',
            field:
              'row_number() over (partition by recommendation_id order by last_refreshed_date desc)',
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.SEQUENCE_NUMBER,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CUSTOM_INSIGHTS,
      cached: false,
    },
    filterDto: {
      columns: [
        {
          label: 'recommendationName',
          field: QUERY_FIELDS.DISTINCT_RECOMMENDATION_NAME,
        },
        ...RECOMMENDATION_QUERY_COLUMNS.filter(
          (item) => item.field !== QUERY_FIELDS.NAME
        ).map((item) => ({
          field: item.label,
          label: item.label,
        })),
      ],
      filterGroups: filterGroups,
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: false,
    },
    recomTableJoinColumnName: 'recommendationName',
    insightTableJoinColumnName: 'recommendation_id',
  };
};

/**
 * @function getGcpDefaultRecommendationCountQuery
 * @description Function to construct the recommendation count query for the views without ruleset filters for GCP.
 * @param currentRecommendationView recommendation view selected
 * @param categoryRecommenderMapping category to list of recommenders mapping
 * @param selectedCostOptimizationInsightsNav selected navigation menu within cost optimization insights
 * @returns Object containing query for recommendations count
 */
export const getGcpDefaultRecommendationCountQuery = (
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string
) => {
  return {
    columns: [
      {
        label: 'totalCount',
        field: QUERY_FIELDS.COUNT_ALL,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.SEQUENCE_NUMBER,
          field: QUERY_VALUES.SEQUENCE_NUMBER_VALUE_DESC,
        },
      ],
    },
    filterGroups: getQueryFilterGroups(
      currentRecommendationView,
      categoryRecommenderMapping,
      selectedCostOptimizationInsightsNav
    ),
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: false,
  };
};

/**
 * @function getGcpRulesetFilterRecommendationCountQuery
 * @description Function to construct the recommendation count query for the views with ruleset filters for GCP.
 * @param currentRecommendationView recommendation view selected
 * @param categoryRecommenderMapping category to list of recommenders mapping
 * @param selectedCostOptimizationInsightsNav selected navigation menu within cost optimization insights
 * @param filterGroups list of filter groups for the ruleset filter dto
 * @returns Object containing query for recommendations count
 */
export const getGcpRulesetFilterRecommendationCountQuery = (
  currentRecommendationView: ViewListType,
  categoryRecommenderMapping: CategoryRecommenderMappingType[] | undefined,
  selectedCostOptimizationInsightsNav: string,
  filterGroups: FilterGroupType[]
) => {
  return {
    recommendationsDto: {
      ...getGcpRecommendationTableDefaultQuery(
        currentRecommendationView,
        categoryRecommenderMapping,
        selectedCostOptimizationInsightsNav
      ),
      columns: [
        ...RECOMMENDATION_QUERY_COLUMNS,
        ...RULESET_FILTERS_ADDITIONAL_QUERY_COLUMNS,
      ],
    },
    insightsDto: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'insight_subtype',
          field: QUERY_FIELDS.SUBTYPE,
        },
        {
          label: 'last_used_time',
          field:
            "CAST(TIMESTAMP_DIFF(CURRENT_TIMESTAMP(),TIMESTAMP(JSON_EXTRACT_SCALAR(content, '$.diskLastUseTime')), DAY) as STRING)",
        },
      ],
      structColumns: [
        {
          label: 'insight',
          field: QUERY_FIELDS.INSIGHT,
        },
        {
          label: 'quantileFunctionMap',
          field:
            "ARRAY(SELECT as STRUCT CAST(JSON_EXTRACT_SCALAR(x, '$.quantileFunctionValue') AS FLOAT64) quantileFunctionValue,  CAST(JSON_EXTRACT_SCALAR(x, '$.sampleProbability') AS FLOAT64) sampleProbability FROM UNNEST(JSON_EXTRACT_ARRAY(content, '$.points')) x)",
        },
      ],
      subQuery: {
        columns: [
          {
            field: '*',
          },
          {
            label: 'seqnum',
            field:
              'row_number() over (partition by recommendation_id order by last_refreshed_date desc)',
          },
        ],
      },
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.SEQUENCE_NUMBER,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.CUSTOM_INSIGHTS,
      cached: false,
    },
    filterDto: {
      columns: [
        {
          field: `COUNT(${QUERY_FIELDS.DISTINCT_RECOMMENDATION_NAME})`,
          label: 'totalCount',
        },
      ],
      filterGroups: filterGroups,
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: false,
    },
    recomTableJoinColumnName: 'recommendationName',
    insightTableJoinColumnName: 'recommendation_id',
  };
};
