import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, message, Select } from 'antd';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';
import { GroupType } from 'pages/GroupsPage/types';
import { fetchGroups } from 'pages/GroupsPage/services';
import {
  editUser,
  fetchConnectionData,
  fetchDashboardList,
  fetchImportFilesData,
  getOtherUsersProfilePhoto,
} from 'utils/services';
import Button from 'components/Button';
import { USER_STATUS } from 'constants/userConsole';
import { REFRESH_INTERVAL } from 'constants/defaultValues';
import { ConnectionListType } from 'types/dashboard';
import { ProfileIcon } from 'assets/icons';
import Modal from 'components/Modal';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { SelectedConnectionDetaiilsTypes } from 'pages/UserPage/types';
import DashboardComponent from 'components/DashboardComponent';
import SelectDropdown from 'components/Select';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { RoleStatus } from 'constants/quickAction';
import { fetchAllRoles } from 'pages/RolesPage/services';
import { RolesType } from 'pages/RolesPage/constants';
import {
  CONSOLIDATED_DASHBOARD_NAVIGATION_DATA,
  getDashboardNavigationMenuByProvider,
} from 'pages/OverviewPage/components/ConnectionsDashboard/components/HorizontalNavigation/constants';
import { PERMISSION_MODULES, PERMISSION_RIGHTS } from 'types/roleManagement';
import { ImportFilesListType } from 'types/importFiles';
import { DashboardListType } from 'types/navigationMenu';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import { QUERY_FIELDS, REQUEST_STATUS } from 'constants/requestBody';
import { userAuthorization } from 'redux/authorizationSlice';
import { getConnectorIdsByUser } from 'components/DashboardHeader/components/services';
import RadioGroup from 'components/RadioGroup';
import { SWITCH_STATUS_OPTIONS } from './constants';
import EditUserConnectionList from '../EditUserConnectionList';

import './index.scss';

type EditUserProps = {
  showEditUserModal: boolean;
  setShowEditUserModal: (value: boolean) => void;
  selectedUserRecord: any;
  setSelectedUserRecord: (value: any) => void;
  fetchUserData: () => void;
};

const EditUser = ({
  showEditUserModal,
  setShowEditUserModal,
  selectedUserRecord,
  setSelectedUserRecord,
  fetchUserData,
}: EditUserProps) => {
  const { t } = useTranslation();

  const { permissions } = useSelector(userAuthorization);
  const { theme } = useSelector(selectTheme);

  const [userName, setUserName] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [profileImage, setProfileImage] = useState<any>();
  const [userStatus, setUserStatus] = useState<string>('');
  const [initialUserRole, setInitialUserRole] = useState<string>('');
  const [initialUserStatus, setInitialUserStatus] = useState<string>('');
  const [rolesData, setRolesData] = useState<RolesType[]>([]);
  const [rolesDataRequestStatus, setRolesDataRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  const [cloudConnections, setCloudConnections] = useState<
    ConnectionListType[]
  >([]);
  const [cloudConnectionsRequestStatus, setCloudConnectionsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [groupsRequestStatus, setGroupsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [importConnections, setImportConnections] = useState<
    ImportFilesListType[]
  >([]);
  const [importConnectionsRequestStatus, setImportConnectionsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [selectedCloudConnections, setSelectedCloudConnections] = useState<
    string[]
  >([]);
  const [selectedCloudConnectionDetails, setSelectedCloudConnectionDetails] =
    useState<SelectedConnectionDetaiilsTypes[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  const [selectedImportConnections, setSelectedImportConnections] = useState<
    string[]
  >([]);
  const [dropdownListType, setDropdownListType] = useState<string>(
    MY_DASHBOARD_TYPES.SINGLE_CONNECTION
  );
  const [rootUserDatasourceAccess, setRootUserDatasourceAccess] = useState<
    any[]
  >([]);
  const [userDatasourceAccess, setUserDatasourceAccess] = useState<any[]>([]);
  const [userDashboards, setUserDashboards] = useState<DashboardListType[]>([]);

  const [isLoadingOnUpdate, setIsLoadingOnUpdate] = useState(false);

  useEffect(() => {
    fetchRootUserDatasourceAccess(setRootUserDatasourceAccess);
    fetchDashboards();
    getAllRoles();
  }, []);

  useEffect(() => {
    setDefaultData();
  }, [selectedUserRecord]);

  useEffect(() => {
    if (userEmail) {
      fetchImage();
      fetchRootUserDatasourceAccess(setUserDatasourceAccess, userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    if (userRole && showDatasourceList()) {
      getAllConnections();
      getAllImports();
      fetchAllGroups();
      const interval = setInterval(() => {
        getAllImports(false);
      }, REFRESH_INTERVAL);
      return () => {
        clearInterval(interval);
      };
    }
  }, [userRole, rolesData]);

  useEffect(() => {
    setSelectedCloudConnectionDetails(getSelectedCloudConnectionDetails());
  }, [selectedCloudConnections, cloudConnections]);

  const fetchRootUserDatasourceAccess = (
    setData: (val: any) => void,
    email?: string
  ) => {
    getConnectorIdsByUser(email)
      .then((res: any) => {
        setData(res.data);
      })
      .catch((e) => {
        onApiCallError(e, false);
      });
  };

  const fetchDashboards = () => {
    fetchDashboardList()
      .then((res: any) => {
        if (res.status === 200) {
          const dashboards: DashboardListType[] =
            res?.data?.responseData?.content;
          setUserDashboards(dashboards);
          return;
        }

        setUserDashboards([]);
      })
      .catch(() => {
        setUserDashboards([]);
      });
  };

  /**
   * @function getSelectedCloudConnectionDetails
   * @description Function to set cloud connection details such as name and connector id
   * @returns the details of selected cloud connections
   */
  const getSelectedCloudConnectionDetails = () => {
    const selectedConnectionDetails: ConnectionListType[] =
      selectedCloudConnections
        ? cloudConnections.filter((connection) =>
            selectedCloudConnections.includes(connection.connectorId)
          )
        : [];
    return selectedConnectionDetails.map((selectedConnection) => {
      return {
        name: selectedConnection.name,
        value: selectedConnection.connectorId,
      };
    });
  };

  /**
   * @function getAllConnections
   * @description Function to get all connection list and set the connection dropdown
   */
  const getAllConnections = async () => {
    setCloudConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchConnectionData()
      .then((res: any) => {
        if (res?.status === 200) {
          setCloudConnections(res?.data?.responseData?.content);
          setCloudConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setCloudConnectionsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setCloudConnectionsRequestStatus);
      });
  };

  /**
   * @function fetchAllGroups
   * @description Function to get all groups
   */
  const fetchAllGroups = () => {
    setGroupsRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchGroups()
      .then((res: any) => {
        if (res?.status === 200) {
          setGroups(res?.data?.responseData?.content);
          setGroupsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setGroupsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setGroupsRequestStatus);
      });
  };

  /**
   * @function getAllImports
   * @description Function to get all connection list and set the connection dropdown
   * @param addLoader Boolean for adding loader or not. Defaults to true
   */
  const getAllImports = (addLoader: boolean = true) => {
    addLoader && setImportConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchImportFilesData()
      .then((res: any) => {
        if (res?.status === 200) {
          setImportConnections(res.data.responseData.content);
          addLoader &&
            setImportConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        addLoader && setImportConnectionsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(
          e,
          false,
          addLoader ? setImportConnectionsRequestStatus : undefined
        );
      });
  };

  /**
   * @function getAllRoles
   * @description Function to get all roles.
   */
  const getAllRoles = () => {
    setRolesDataRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchAllRoles()
      .then((res: any) => {
        const activeRoles = res.data.responseData.content.filter(
          (role: RolesType) => role.status === RoleStatus.ACTIVE
        );
        setRolesData(activeRoles);
        setRolesDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setRolesDataRequestStatus,
          t('editUserModal.errorWhileFetchingRoles')
        );
      });
  };

  /**
   * @function fetchImage
   * @description Function to fetch profile image
   */
  const fetchImage = async () => {
    try {
      const res: any = await getOtherUsersProfilePhoto(userEmail);
      if (res.status > 400) {
        setProfileImage(ProfileIcon);
      } else {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setProfileImage(imageObjectURL);
      }
    } catch (error) {
      onApiCallError(error);
    }
  };

  /**
   * @function setDefaultData
   * @description Function to set all user default datas
   */
  const setDefaultData = () => {
    setUserName(selectedUserRecord?.name);
    setUserEmail(selectedUserRecord?.email);
    setUserRole(selectedUserRecord?.role);
    setInitialUserRole(selectedUserRecord?.role);
    setUserStatus(selectedUserRecord?.status);
    setInitialUserStatus(selectedUserRecord?.status);
    setSelectedCloudConnections(
      selectedUserRecord.userDatasourceList
        .filter(
          (item: any) =>
            item.datasourceType === QUERY_FIELDS.DATASOURCE_TYPE_CONNECTION
        )
        .map((item: any) => item.datasourceId)
    );

    setSelectedGroups(
      selectedUserRecord.userDatasourceList
        .filter(
          (item: any) =>
            item.datasourceType === QUERY_FIELDS.DATASOURCE_TYPE_GROUP
        )
        .map((item: any) => item.datasourceId)
    );

    setSelectedImportConnections(
      selectedUserRecord.userDatasourceList
        .filter(
          (item: any) =>
            item.datasourceType ===
            QUERY_FIELDS.DATASOURCE_TYPE_STATIC_FILE_IMPORT
        )
        .map((item: any) => item.datasourceId)
    );
  };

  /**
   * @function onHandleClose
   * @description Function to handle close of edit modal
   */
  const onHandleClose = () => {
    setShowEditUserModal(false);
    setSelectedUserRecord('');
    setProfileImage(ProfileIcon);
    setSelectedCloudConnectionDetails([]);
    setSelectedCloudConnections([]);
    setSelectedImportConnections([]);
  };

  /**
   * @function removeConnectionFromList
   * @description Function to handle removal of selected connection
   */
  const removeConnectionFromList = (selectedConnection: string) => {
    setSelectedCloudConnections(
      selectedCloudConnections.filter(
        (connection) => connection !== selectedConnection
      )
    );
  };

  /**
   * @function removeGroupFromList
   * @description Function to handle removal of selected group
   */
  const removeGroupFromList = (group: string) => {
    setSelectedGroups(
      selectedGroups.filter((eachGroup) => eachGroup !== group)
    );
  };

  /**
   * @function removeImportFileFromList
   * @description Function to handle removal of selected group
   */
  const removeImportFileFromList = (importFile: string) => {
    setSelectedImportConnections(
      selectedImportConnections.filter(
        (eachImportFile) => eachImportFile !== importFile
      )
    );
  };

  /**
   * @function onHandleChangeStatus
   * @description Function to handle change of user status
   * @param status status to be changed to
   */
  const onHandleChangeStatus = (status: string) => {
    if (status === USER_STATUS.DEACTIVATED) {
      setUserStatus(USER_STATUS.DEACTIVATED);
      return;
    }

    if (
      initialUserStatus === USER_STATUS.PENDING ||
      (initialUserStatus === USER_STATUS.DEACTIVATED &&
        selectedUserRecord?.lastAccessed === null)
    ) {
      setUserStatus(USER_STATUS.PENDING);
      return;
    }

    setUserStatus(status);
  };

  const filterDatasource = () => {
    const selectedDataSources = [
      ...selectedCloudConnections,
      ...selectedGroups,
      ...selectedImportConnections,
    ];

    if (permissions.cloudConnectorListALL) {
      return getAllConnectorDetails().filter((item) =>
        selectedDataSources.includes(item.datasourceId)
      );
    }

    const datasourceDto = rootUserDatasourceAccess.filter((item) =>
      selectedDataSources.includes(item.datasourceId)
    );

    const userExplicitAccess = userDatasourceAccess.filter(
      (item) =>
        !rootUserDatasourceAccess.some(
          (datasource) => datasource.datasourceId === item.datasourceId
        )
    );

    return [...datasourceDto, ...userExplicitAccess];
  };

  const getViews = (dashboard: DashboardListType) => {
    const staticViews = (
      dashboard.dashBoardType === MY_DASHBOARD_TYPES.GROUP
        ? CONSOLIDATED_DASHBOARD_NAVIGATION_DATA
        : getDashboardNavigationMenuByProvider(dashboard.connectorProvider)
    ).map((view) => view.id);

    return [...staticViews, ...dashboard.customViewIds];
  };

  const getAllConnectorDetails = () => {
    const connectionsDashboards = cloudConnections.map((datasource) => ({
      datasourceId: datasource.connectorId,
      datasourceDashboards: userDashboards
        .filter((dashboard) => dashboard.connectorId === datasource.connectorId)
        .map((dashboard) => ({
          dashboardId: dashboard.id,
          views: getViews(dashboard),
        })),
      datasourceType: QUERY_FIELDS.DATASOURCE_TYPE_CONNECTION,
    }));

    const groupsDashboards = groups.map((datasource) => ({
      datasourceId: datasource.name,
      datasourceDashboards: userDashboards
        .filter((dashboard) => dashboard.groupName === datasource.name)
        .map((dashboard) => ({
          dashboardId: dashboard.id,
          views: getViews(dashboard),
        })),
      datasourceType: QUERY_FIELDS.DATASOURCE_TYPE_GROUP,
    }));

    const transactionsDashboards = importConnections.map((datasource) => ({
      datasourceId: datasource.transactionName,
      datasourceDashboards: userDashboards
        .filter(
          (dashboard) =>
            dashboard.transactionName === datasource.transactionName
        )
        .map((dashboard) => ({
          dashboardId: dashboard.id,
          views: getViews(dashboard),
        })),
      datasourceType: QUERY_FIELDS.DATASOURCE_TYPE_STATIC_FILE_IMPORT,
    }));

    return [
      ...connectionsDashboards,
      ...groupsDashboards,
      ...transactionsDashboards,
    ];
  };

  const getUserDatasourceDTOList = () => {
    if (!permissions.cloudConnectorList) {
      return userDatasourceAccess;
    }

    if (showDatasourceList()) {
      return filterDatasource();
    }

    return [];
  };

  /**
   * @function onHandleUpdate
   * @description Function to handle update
   */
  const onHandleUpdate = async () => {
    setIsLoadingOnUpdate(true);

    const body = {
      name: userName,
      email: userEmail,
      status: userStatus,
      roleName: userRole,
      userDatasourceDTOList: getUserDatasourceDTOList(),
    };

    editUser(body)
      .then((res: any) => {
        setIsLoadingOnUpdate(false);
        if (res.status === 200) {
          message.success(t('editUserModal.updateSuccessMessage'));
          if (userEmail === localStorage.getItem('email')) {
            localStorage.clear();
            window.location.assign('/');
            return;
          }
          onHandleClose();
          fetchUserData();
        } else {
          message.error(t('editUserModal.updateFailureMessage'));
        }
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          undefined,
          t('editUserModal.updateFailureMessage')
        );
        setIsLoadingOnUpdate(false);
      });
  };

  const roleOptions = (
    <>
      {rolesData.map((role) => (
        <Select.Option key={role.name} value={role.name}>
          {role.name}
        </Select.Option>
      ))}
    </>
  );

  /**
   * @function showDatasourceList
   * @description Function to check the permission for connection list and return a boolean
   * @returns true if the role has connection list permission, else false
   */
  const showDatasourceList = () => {
    if (!permissions.cloudConnectorList) return false;
    const selectedRolePermissions = rolesData.find(
      (role) => role.name === userRole
    )?.permissions;
    const hasListConnectionPermission = selectedRolePermissions?.some(
      (permission) =>
        permission.module === PERMISSION_MODULES.CLOUD_CONNECTOR &&
        permission.rights.includes(PERMISSION_RIGHTS.LIST)
    );
    if (hasListConnectionPermission !== undefined) {
      return !hasListConnectionPermission;
    }
    return false;
  };

  /**
   * @function getDatasourceListComponent
   * @description Function returning the UI components for datasource dropdowns
   * @returns JSX element containing the UI components
   */
  const getDatasourceListComponent = () => (
    <div className="cloud-connection-edit flex flex-column">
      <div className="cloud-input-section flex flex-column">
        <EditUserConnectionList
          cloudConnections={cloudConnections}
          selectedCloudConnections={selectedCloudConnections}
          groups={groups}
          selectedGroups={selectedGroups}
          importConnections={importConnections}
          selectedImportConnections={selectedImportConnections}
          dropdownListType={dropdownListType}
          setDropdownListType={setDropdownListType}
          setSelectedCloudConnections={setSelectedCloudConnections}
          setSelectedGroups={setSelectedGroups}
          setSelectedImportConenctions={setSelectedImportConnections}
        />
        <div className="selected-users-list flex flex-justify-content-left flex-wrap">
          {selectedCloudConnectionDetails.map((selectedConnection) => (
            <AccessibleDiv
              className="selected-users-list-under-dropdown flex flex-center"
              key={selectedConnection.value}
              onClick={() => removeConnectionFromList(selectedConnection.value)}
            >
              {selectedConnection.name}
              <Icon
                iconName={ICONS.CLOSE_LINE}
                size={ICONS_SIZE.SM}
                color={theme.buttonIconColor}
              />
            </AccessibleDiv>
          ))}
          {selectedGroups.map((group) => (
            <AccessibleDiv
              className="selected-users-list-under-dropdown flex flex-center"
              key={group}
              onClick={() => removeGroupFromList(group)}
            >
              {group}
              <Icon
                iconName={ICONS.CLOSE_LINE}
                size={ICONS_SIZE.SM}
                color={theme.buttonIconColor}
              />
            </AccessibleDiv>
          ))}
          {selectedImportConnections.map((importFile) => (
            <AccessibleDiv
              className="selected-users-list-under-dropdown flex flex-center"
              key={importFile}
              onClick={() => removeImportFileFromList(importFile)}
            >
              {importFile}
              <Icon
                iconName={ICONS.CLOSE_LINE}
                size={ICONS_SIZE.SM}
                color={theme.buttonIconColor}
              />
            </AccessibleDiv>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      visible={showEditUserModal}
      onCancel={onHandleClose}
      title={t('editUserModal.updateUser')}
      centered
      footer={null}
    >
      <div className="edit-modal-container flex flex-column">
        <div className="header flex">
          <img src={profileImage} alt="profileimage" className="icon" />
          <div className="user-detail">
            <div className="form-header">{userName}</div>
            <div className="email font-overline">{userEmail}</div>
          </div>
          <div className="role font-small-bold">{initialUserRole}</div>
        </div>
        <div className="status flex flex-align-items-center flex-space-between">
          <div className="flex flex-column">
            <div className="font-label">{t('editUserModal.status')}</div>
            {initialUserStatus === USER_STATUS.PENDING && (
              <div className="font-caption-bold pending-message">
                {t('editUserModal.pendingUserMessage')}
              </div>
            )}
          </div>
          <RadioGroup
            options={SWITCH_STATUS_OPTIONS}
            onChange={(e: any) => onHandleChangeStatus(e.target.value)}
            value={
              userStatus === USER_STATUS.DEACTIVATED
                ? USER_STATUS.DEACTIVATED
                : USER_STATUS.ACTIVE
            }
            optionType="button"
            rootClassName="no-custom-style"
            style={{ height: 28 }}
          />
        </div>
        <div className="change-role flex flex-column">
          <div className="font-label">
            {t('editUserModal.changeAssignedRole')}
          </div>
          <SelectDropdown
            showSearch
            placeholder={t('addRoleModal.searchRolePlaceholder')}
            value={userRole}
            menu={roleOptions}
            onSelect={(event: string) => setUserRole(event)}
            loading={rolesDataRequestStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
        <Divider />
        {showDatasourceList() && (
          <DashboardComponent
            component={getDatasourceListComponent()}
            requestStatus={evaluateRequestArray([
              cloudConnectionsRequestStatus,
              groupsRequestStatus,
              importConnectionsRequestStatus,
            ])}
          />
        )}
        <Button
          title={t('updateBtn')}
          onClick={onHandleUpdate}
          disabled={isLoadingOnUpdate}
          loading={isLoadingOnUpdate}
        />
      </div>
    </Modal>
  );
};

export default EditUser;
