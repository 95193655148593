import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ColumnChart from 'components/ColumnChart';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Cascader from 'components/Cascader';
import { REQUEST_STATUS } from 'constants/requestBody';
import GraphHeader from 'components/GraphHeader';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { evaluateRequestArray } from 'utils/handleErrors';
import { DATE_FORMAT, HYPHEN_DATE_FORMAT } from 'utils/date';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';

import { displayRender, onChangeDateFilter, onChangeDates } from '../../utils';
import { PodUsageCostType } from '../../types';
import { getPodUsageCostExcelData } from '../../utils/exportToExcel';

type EffectiveMemoryUtilizationByPodsProps = {
  podUsageCostData: PodUsageCostType[];
  filteredPodUsageCostData: PodUsageCostType[];
  selectedPods: string[];
  setSelectedPods: (val: string[]) => void;
  requestStatus: string[];
  podUsageCostTableView: boolean;
  setPodUsageCostTableView: (value: boolean) => void;
  pdfView: boolean;
  podUsageCostDateFilter: DateRangeFilter;
  setPodUsageCostDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};

const EffectiveMemoryUtilizationByPods = ({
  podUsageCostData,
  filteredPodUsageCostData,
  selectedPods,
  setSelectedPods,
  requestStatus,
  podUsageCostTableView,
  setPodUsageCostTableView,
  pdfView,
  podUsageCostDateFilter,
  setPodUsageCostDateFilter,
  excelFilters,
}: EffectiveMemoryUtilizationByPodsProps) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('containerInsight.usageMeteringLabels.pod'),
      dataIndex: 'pod',
      key: 'pod',
    },
    {
      title: t('containerInsight.usageMeteringLabels.memoryInBytesPerGiB'),
      dataIndex: 'usageAmount',
      key: 'usageAmount',
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text ?? 0),
    },
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  format={DATE_FORMAT}
                  defaultValue={[
                    moment(podUsageCostDateFilter.startDate),
                    moment(podUsageCostDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(
                      [
                        moment(dateString[0], DATE_FORMAT).format(
                          HYPHEN_DATE_FORMAT
                        ),
                        moment(dateString[1], DATE_FORMAT).format(
                          HYPHEN_DATE_FORMAT
                        ),
                      ],
                      setPodUsageCostDateFilter
                    )
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters flex flex-row flex-align-items-center flex-gap-16">
      <Cascader
        className="width-40"
        options={DateMenu}
        defaultValue={[podUsageCostDateFilter.option]}
        displayRender={() => displayRender(podUsageCostDateFilter)}
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setPodUsageCostDateFilter)
        }
      />
      <GraphFilterDropdown
        additionalClassNames="width-50"
        allData={podUsageCostData}
        selectedData={podUsageCostData.filter((item) =>
          selectedPods.includes(item.pod)
        )}
        setSelectedData={(selectedItems: any[]) => {
          setSelectedPods(selectedItems.map((item) => item.pod));
        }}
        setLabels={setSelectedPods}
        valueSuffix={t('containerInsight.usageMeteringLabels.pods')}
        fieldName={DROPDOWN_VALUE_FIELDS.POD}
      />
    </div>
  );

  const getComponent = () =>
    podUsageCostTableView ? (
      <Table
        pagination={false}
        loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
        dataSource={filteredPodUsageCostData.map((data, index) => ({
          ...data,
          key: index,
        }))}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer={true}
      />
    ) : (
      <ColumnChart
        data={filteredPodUsageCostData.map((item) => ({
          ...item,
          groupingField: item.pod,
        }))}
        yField="usageAmount"
        xField="pod"
        groupingField="groupingField"
        xTitle={t('containerInsight.usageMeteringLabels.pods')}
        yTitle={t('containerInsight.usageMeteringLabels.memoryInBytesPerGiB')}
        disableAnimation={pdfView}
        suffixSymbol={t('containerInsight.usageMeteringLabels.bytesPerGiB')}
      />
    );

  return (
    <div
      className="pod-usage-cost graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.effectiveMemoryUtilizationByPod')}
        graphName="pod-usage-cost"
        ignorePaddingBottom={true}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={podUsageCostTableView}
        setIsTableView={setPodUsageCostTableView}
        filters={filters}
        excelData={getPodUsageCostExcelData(filteredPodUsageCostData, [
          ...excelFilters,
          {
            heading: t('excelExportLabels.startDate'),
            value: podUsageCostDateFilter.startDate,
          },
          {
            heading: t('excelExportLabels.endDate'),
            value: podUsageCostDateFilter.endDate,
          },
          {
            heading: t('excelExportLabels.pods'),
            value: selectedPods.toString(),
          },
        ])}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default EffectiveMemoryUtilizationByPods;
