import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import Icon from 'components/Icon';
import { COLORS } from 'constants/graphConfig';
import { ICONS, ICONS_SIZE } from 'constants/icons';

type HelpPopoverProps = {
  message: string;
  popoverPlacement?: TooltipPlacement;
};

const HelpPopover = ({
  message,
  popoverPlacement = 'top',
}: HelpPopoverProps) => {
  return (
    <Popover content={message} placement={popoverPlacement}>
      <div>
        <Icon
          iconName={ICONS.INFORMATION_LINE}
          size={ICONS_SIZE.ONE_X}
          color={COLORS.colorRegentGrey}
          dataTestId="help-icon"
        />
      </div>
    </Popover>
  );
};

export default HelpPopover;
