import { useCallback } from 'react';
import { Gauge } from '@ant-design/plots';
import { COLORS } from 'constants/graphConfig';

type GaugeChartProps = {
  percent: number;
  ticks: number[];
  title?: string;
  contentText?: string;
  colorOverride?: string[];
  disableAnimation?: boolean;
  axisLabelFontSize?: number;
  rangeWidth?: number;
  statisticsTitleFontSize?: string;
};

const GaugeChart = ({
  percent,
  ticks,
  title,
  contentText,
  colorOverride,
  disableAnimation,
  axisLabelFontSize = 10,
  rangeWidth = 20,
  statisticsTitleFontSize = '12px',
}: GaugeChartProps) => {
  const config = {
    percent: percent / 100,
    range: {
      ticks: ticks,
      color: colorOverride,
      width: rangeWidth,
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    axis: {
      label: {
        formatter: useCallback((value: any) => {
          return Number(value) * 100 + '%';
        }, []),
        style: {
          fontSize: axisLabelFontSize,
        },
      },
      tickLine: null,
      subTickLine: {
        count: 0,
      },
    },
    statistic: {
      title: {
        content: title,
        style: {
          fontSize: statisticsTitleFontSize,
          lineHeight: '16px',
          color: COLORS.fnGrey5,
          opacity: 1,
        },
      },
      content: {
        offsetY: title ? 30 : 0,
        content: contentText,
        style: {
          fontSize: '24px',
          fontWeight: 600,
          color: COLORS.secondaryBlack,
          opacity: 1,
        },
      },
    },
    animation: !disableAnimation,
  };

  return <Gauge {...config} />;
};

export default GaugeChart;
