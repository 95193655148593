import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { POINT_STYLE } from 'constants/graphConfig';
import { CHART_TOOLTIP_LABEL_LENGTH_LIMIT } from 'constants/userConsole';
import { numberCommaSeparator } from './dataFormatterUtils';
import { DATE_FORMAT, HYPHEN_DATE_FORMAT, MONTH_YEAR_FORMAT } from './date';

/**
 * @function onClickHideDataset
 * @description Function to simulate hide or show chart data on clicking legends in chartjs.
 * @params click : click event for the legend
 * @params legendItem : metadata of legends in chart
 * @params legend : metadata of legends dataset
 */
export const onClickHideDataset = (
  _click: any,
  legendItem: any,
  legend: any
) => {
  const datasets = legend.legendItems.map((dataset: any) => {
    return dataset.text;
  });
  const index = datasets.indexOf(legendItem.text);
  if (legend.chart.isDatasetVisible(index) === true) {
    legend.chart.hide(index);
  } else {
    legend.chart.show(index);
  }
};

/**
 * @function onClickHideLabel
 * @description Function to simulate hide or show chart data on clicking legends in chartjs. Useful for labels for each bar.
 * @params click : click event for the legend
 * @params legendItem : metadata of legends in chart
 * @params legend : metadata of legends dataset
 */
export const onClickHideLabel = (_click: any, legendItem: any, legend: any) => {
  const index = legend.chart.data.labels.indexOf(legendItem.text);
  legend.chart.toggleDataVisibility(index);
  legend.chart.update();
};

/**
 * @function generateLabelsWithLine
 * @description Function to generate legends with a line for line charts and rest as rounded rect. Useful for mixed and line charts.
 * @params chart : metadata for the chart
 * @returns list of LegendItem which include the options for legends.
 */
export const generateLabelsWithLine = (chart: any) => {
  let visibility: boolean[] = [];
  for (let i = 0; i < chart.data.datasets.length; i++) {
    if (chart.isDatasetVisible(i) === true) {
      visibility.push(false);
    } else {
      visibility.push(true);
    }
  }

  let pointStyle: string[] = [];
  chart.data.datasets.forEach((dataset: any) => {
    if (dataset.type === 'line') {
      pointStyle.push(POINT_STYLE.LINE);
    } else {
      pointStyle.push(POINT_STYLE.RECT_ROUNDED);
    }
  });
  return chart.data.datasets.map((dataset: any, index: number) => ({
    text: dataset.label,
    fillStyle: dataset.backgroundColor,
    strokeStyle:
      dataset.type === 'line' ? dataset.borderColor : dataset.backgroundColor,
    pointStyle: pointStyle[index],
    hidden: visibility[index],
  }));
};

/**
 * @function generateLabelsForEachBar
 * @description Function to generate legends for each bar in the bar graph.
 * @params chart : metadata for the chart
 * @returns list of LegendItem which include the options for legends.
 */
export const generateLabelsForEachBar = (chart: any) => {
  let visibility: boolean[] = [];
  for (let i = 0; i < chart.data.labels.length; i++) {
    if (chart.getDataVisibility(i) === true) {
      visibility.push(false);
    } else {
      visibility.push(true);
    }
  }

  return chart.data.labels.map((label: any, index: number) => ({
    text: label,
    strokeStyle:
      chart?.data?.datasets[0]?.backgroundColor[
        index % chart?.data?.datasets[0]?.backgroundColor?.length
      ],
    fillStyle:
      chart?.data?.datasets[0]?.backgroundColor[
        index % chart?.data?.datasets[0]?.backgroundColor?.length
      ],

    pointStyle: POINT_STYLE.RECT_ROUNDED,
    hidden: visibility[index],
  }));
};

/**
 * @function graphTooltipFormatter
 * @description Function to format the tooltip for the graph.
 * @params value : value of the tooltip
 * @params groupingField : field to be used for grouping
 * @params yField : field to be used for y axis
 * @returns object with name and value for the tooltip.
 */
export const graphTooltipFormatter = (
  value: any,
  groupingField: string,
  yField: string
) => {
  return {
    name:
      (value[groupingField]?.length ?? 0) > CHART_TOOLTIP_LABEL_LENGTH_LIMIT
        ? (value[groupingField] || '').substring(
            0,
            CHART_TOOLTIP_LABEL_LENGTH_LIMIT
          ) + '...'
        : value[groupingField] || '',
    value: numberCommaSeparator(value[yField]),
  };
};

/**
 * @function graphSliderValueFormatter
 * @description Function to format the tooltip for the graph.
 * @params value : value of the tooltip
 * @params groupingField : field to be used for grouping
 * @params yField : field to be used for y axis
 * @returns object with name and value for the tooltip.
 */
export const graphSliderValueFormatter = (
  index: number,
  dataLength: number
) => {
  const modifiedIndex = index === dataLength - 1 ? dataLength : index;
  return ((modifiedIndex / (dataLength || 1)) * 100).toFixed(2) + '%';
};

/**
 * @function getMonthRangeHandler
 * @description Creates a handler for a month range change.
 * @param setStartMonth - The function to set the start date.
 * @param setEndMonth - The function to set the end date.
 * @param inputFormat - The format of the input date strings. Defaults to MONTH_YEAR_FORMAT.
 * @param outputFormat - The format of the output date strings. Defaults to HYPHEN_DATE_FORMAT.
 * @returns The handler function.
 */
export const getMonthRangeHandler = (
  setStartMonth: (date: string) => void,
  setEndMonth: (date: string) => void,
  inputFormat: string = MONTH_YEAR_FORMAT,
  outputFormat: string = HYPHEN_DATE_FORMAT
) => {
  return (_dates: RangeValue<Moment>, dateString: [string, string]) => {
    setStartMonth(
      moment(dateString[0], inputFormat).startOf('month').format(outputFormat)
    );
    setEndMonth(
      moment(dateString[1], inputFormat).endOf('month').format(outputFormat)
    );
  };
};

/**
 * @function getDateRangeHandler
 * @description Creates a handler for a date range change.
 * @param setStartDate - The function to set the start date.
 * @param setEndDate - The function to set the end date.
 * @param dateStringInputFormat - The format of the input date strings. Defaults to DATE_FORMAT.
 * @param outputFormat - The format of the output date strings. Defaults to HYPHEN_DATE_FORMAT.
 * @returns The handler function.
 * @default inputFormat - DATE_FORMAT
 * @default outputFormat - HYPHEN_DATE_FORMAT
 */
export const getDateRangeHandler = (
  setStartDate: (date: string) => void,
  setEndDate: (date: string) => void,
  dateStringInputFormat: string = DATE_FORMAT,
  outputFormat: string = HYPHEN_DATE_FORMAT
) => {
  return (_dates: RangeValue<Moment>, dateString: [string, string]) => {
    setStartDate(
      moment(dateString[0], dateStringInputFormat).format(outputFormat)
    );
    setEndDate(
      moment(dateString[1], dateStringInputFormat).format(outputFormat)
    );
  };
};
