import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Table from 'components/Table';
import GraphHeader from 'components/GraphHeader';
import { generateGraphColors } from 'utils/dashboardUtils';
import { AllocationTrendType } from 'pages/CostAllocationDashboardPage/types';
import { selectCostAllocationDashboard } from 'redux/costAllocationDashboardSlice';
import DashboardComponent from 'components/DashboardComponent';
import ColumnLineChart from 'components/ColumnLineChart';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { COLORS } from 'constants/graphConfig';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getAllocationTrendExcelData } from '../../utils/exportToExcel';

type AllocationTrendProps = {
  allocationTrendData: AllocationTrendType[];
  isAllocationTrendTableView: boolean;
  setIsAllocationTrendTableView: (value: boolean) => void;
  requestStatus: string;
  pdfView: boolean;
};

export const AllocationTrend = ({
  allocationTrendData,
  isAllocationTrendTableView,
  setIsAllocationTrendTableView,
  requestStatus,
  pdfView,
}: AllocationTrendProps) => {
  const { t } = useTranslation();
  const { selectedCostAllocationDashboard } = useSelector(
    selectCostAllocationDashboard
  );
  const { currencySymbol } = useSelector(selectCommonUtility);

  const AllocationTrendColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('costAllocationDashboardLabels.costAllocationSummary.month'),
      dataIndex: 'month',
      key: 'month',
      width: 50,
    },
    {
      title: t(
        'costAllocationDashboardLabels.costAllocationSummary.totalSharedCostWithCurrency',
        { currencySymbol: currencySymbol }
      ),
      dataIndex: 'sharedCost',
      key: 'sharedCost',
      width: 180,
      align: 'center' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text || 0),
    },
    {
      title: t(
        'costAllocationDashboardLabels.costAllocationSummary.allocatedCostWithCurrency',
        { currencySymbol: currencySymbol }
      ),
      dataIndex: 'allocatedCost',
      key: 'allocatedCost',
      width: 180,
      align: 'center' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text || 0),
    },
    {
      title: t(
        'costAllocationDashboardLabels.costAllocationSummary.totalResidualCostWithCurrency',
        { currencySymbol: currencySymbol }
      ),
      dataIndex: 'residualCost',
      key: 'residualCost',
      width: 180,
      align: 'center' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text || 0),
    },
  ];

  const getColumnChartData = () =>
    allocationTrendData.reduce((acc: any, curr: any) => {
      const { month, sharedCost, allocatedCost, residualCost } = curr;
      acc.push({
        month,
        cost: sharedCost,
        type: t(
          'costAllocationDashboardLabels.costAllocationSummary.totalSharedCost'
        ),
      });
      acc.push({
        month,
        cost: allocatedCost,
        type: t(
          'costAllocationDashboardLabels.costAllocationSummary.allocatedCost'
        ),
      });
      acc.push({
        month,
        cost: residualCost,
        type: t(
          'costAllocationDashboardLabels.costAllocationSummary.totalResidualCost'
        ),
      });
      return acc;
    }, []);

  const getLineChartData = () =>
    allocationTrendData.reduce((acc: any, curr: any) => {
      const { month, residualCost } = curr;
      acc.push({
        month,
        cost: residualCost,
        type: t(
          'costAllocationDashboardLabels.costAllocationSummary.totalResidualCost'
        ),
      });
      return acc;
    }, []);

  const getComponent = () => {
    const colors = generateGraphColors(3);
    return isAllocationTrendTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={allocationTrendData.map((item, index) => {
            return {
              ...item,
              key: index,
            };
          })}
          columns={AllocationTrendColumns}
          scroll={{ y: 220 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnLineChart
        plots={[
          {
            type: 'column',
            options: {
              data: getColumnChartData(),
              xField: 'month',
              yField: 'cost',
              seriesField: 'type',
              isGroup: true,
              yAxis: {
                line: {
                  style: {
                    stroke: COLORS.fnGrey,
                    lineWidth: 1,
                    opacity: 0.7,
                  },
                },
                title: {
                  text: t(
                    'costAllocationDashboardLabels.costAllocationSummary.cost',
                    { currencySymbol: currencySymbol }
                  ),
                },
              },
              xAxis: {
                title: {
                  text: t(
                    'costAllocationDashboardLabels.costAllocationSummary.month'
                  ),
                },
              },
              maxColumnWidth: 24,
              animation: !pdfView,
              color: colors,
            },
          },
          {
            type: 'line',
            options: {
              data: getLineChartData(),
              xField: 'month',
              yField: 'cost',
              seriesField: 'type',
              xAxis: {
                title: {
                  text: t(
                    'costAllocationDashboardLabels.costAllocationSummary.month'
                  ),
                },
              },
              animation: !pdfView,
              color: colors.slice(-1),
              tooltip: false,
            },
          },
        ]}
        markerOverride={(_name: any, _index: any, item: any) => {
          if (
            item.name ===
            t(
              'costAllocationDashboardLabels.costAllocationSummary.totalResidualCost'
            )
          ) {
            return {
              symbol: 'hyphen',
            };
          }
        }}
        prefixSymbol={currencySymbol}
      />
    );
  };

  return (
    <div className="allocation-trend graph-card" id="graph-container">
      <GraphHeader
        heading={t('graphHeadings.allocationTrend')}
        graphName={'allocation-trend'}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isAllocationTrendTableView}
        setIsTableView={setIsAllocationTrendTableView}
        excelData={getAllocationTrendExcelData(
          selectedCostAllocationDashboard?.connectorName ?? '',
          allocationTrendData
        )}
        designVersion2
      />
      <div className="graph no-filters">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};
