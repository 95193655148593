import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormLabel } from 'components/FormLabel';
import AddUserSearch from 'components/AddUserSearch';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { COLORS } from 'constants/graphConfig';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import { UserInfoType } from 'types/userManagementConsole';

type NotificationsFormProps = {
  showEmailMessageField?: boolean;
};

const NotificationsForm = ({
  showEmailMessageField = false,
}: NotificationsFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { budgetData } = useSelector(budgetsAndAlerts);

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="flex flex-column flex-gap-8">
        <div className="form-item flex flex-column">
          <FormLabel
            title={t(
              'addBudgetAlert.createBudgetLabels.notifications.addRecipients'
            )}
            required={true}
          />
          <AddUserSearch
            placeholder={t(
              'addBudgetAlert.createBudgetLabels.notifications.addRecipientsPlaceholder'
            )}
            setUserInfo={(user: UserInfoType) => {
              if (!budgetData.notificationEmails.includes(user.email)) {
                dispatch(
                  setBudgetData({
                    ...budgetData,
                    notificationEmails: [
                      ...budgetData.notificationEmails,
                      user.email,
                    ],
                  })
                );
              }
            }}
            designVersion2
          />
        </div>
        <div className="email-list flex flex-justify-content-left flex-wrap">
          {budgetData.notificationEmails.map((eachEmail: string) => (
            <div
              className="email flex flex-gap-4 flex-align-items-baseline"
              key={eachEmail}
            >
              {eachEmail}
              <Icon
                iconName={ICONS.CLOSE_FILL}
                size={ICONS_SIZE.ONE_X}
                color={COLORS.colorRegentGrey}
                onClick={() =>
                  dispatch(
                    setBudgetData({
                      ...budgetData,
                      notificationEmails: budgetData.notificationEmails.filter(
                        (email) => email !== eachEmail
                      ),
                    })
                  )
                }
                dataTestId="remove-email"
              />
            </div>
          ))}
        </div>
      </div>
      {showEmailMessageField && (
        <div className="form-item flex flex-column">
          <FormLabel
            title={t(
              'addBudgetAlert.createBudgetLabels.notifications.emailMessage'
            )}
          />
          <Input
            type="textarea"
            value={budgetData.message}
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder={t(
              'addBudgetAlert.createBudgetLabels.notifications.emailMessagePlaceholder'
            )}
            onChange={(e: any) =>
              dispatch(
                setBudgetData({
                  ...budgetData,
                  message: e.target.value,
                })
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default NotificationsForm;
