import i18n from 'i18n';
import moment from 'moment';

import { store } from 'redux/store';
import { PROVIDER } from 'constants/cloudProviders';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import {
  getAWSTagFiltersData,
  getAzureTagFiltersData,
  getGCPTagFiltersData,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import {
  formatArrayToStringByProvider,
  insertIndex,
  numberCommaSeparator,
} from 'utils/dataFormatterUtils';
import {
  DATE_FORMAT,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  enumerateDaysBetweenDates,
  formatDateFieldByProvider,
  getMonthYearShortList,
} from 'utils/date';
import { DashboardMetaData } from 'types/dashboard';

import { ExcelData } from '../../../types';
import {
  getAccountLabelByProvider,
  getAccountWithGroupingValuesColumns,
  getAWSUnitCostTimePeriodQuery,
  getAzureUnitCostTimePeriodQuery,
  getChartExcelDataWithDateFilter,
  getDualAxisCostByTypeTableData,
  getEbsVolumesSpendColumns,
  getGcpUnitCostTimePeriodQuery,
  getUsageCostTableData,
} from '../../../utils';
import { DiskTypeToStorageMap } from '../../../constants';
import { StorageDashboardGraphs } from '../constants';

const state = store.getState();
const { currencySymbol } = state.commonUtility;

/**
 * @function getSpendByAccountsLabelByProvider
 * @description Function to return the Spend by Accounts label by provider
 * @param provider Provider for which the label is required
 * @returns String containing the label
 */
export const getSpendByAccountsLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('graphHeadings.storageCostBySubscription');

    case PROVIDER.AWS:
      return i18n.t('graphHeadings.ebsSpendByAccounts');

    case PROVIDER.GCP:
    default:
      return i18n.t('graphHeadings.cloudStorageCostByProject');
  }
};

/**
 * @function getAwsEbsSpendByAccountsQuery
 * @description Function to return the EBS Spend by Accounts for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsEbsSpendByAccountsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'account'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EBS,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpCloudStorageCostByProjectQuery
 * @description Function to return the Cloud Storage Spend by Project for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpCloudStorageCostByProjectQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PROJECT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'account'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.CLOUD_STORAGE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureManagedDiskSpendBySubscriptionsQuery
 * @description Function to return the Managed Disk cost by Subscription for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureManagedDiskSpendBySubscriptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SUBSCRIPTION_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'account'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_SUB_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: `%${QUERY_VALUES.MANAGED_DISK}%`,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getSpendByAccountsQuery
 * @description Function to return the Spend by Accounts Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getSpendByAccountsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureManagedDiskSpendBySubscriptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEbsSpendByAccountsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpCloudStorageCostByProjectQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getVolumeCoverageLabelByProvider
 * @description Function to return the Volume coverage label by provider
 * @param provider Provider for which the label is required
 * @returns String containing the label
 */
export const getVolumeCoverageLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('graphHeadings.azureManagedDiskCoveragePreviousMonth');

    case PROVIDER.AWS:
      return i18n.t('graphHeadings.ebsVolumeCoveragePreviousMonth');

    case PROVIDER.GCP:
    default:
      return i18n.t('graphHeadings.cloudStorageCoveragePreviousMonth');
  }
};

/**
 * @function getAwsEbsVolumeCoverageQuery
 * @description Function to return the EBS Volume Coverage Query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsEbsVolumeCoverageQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'volume',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.EBS_VOLUME_COVERAGE_SPLIT_COLUMN
            : QUERY_FIELDS.EBS_VOLUME_COVERAGE_SPLIT_PART_COLUMN,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.SUM_CAST_LINE_ITEM_USAGE_AMOUNT_AS_FLOAT_64
            : QUERY_FIELDS.SUM_CAST_LINE_ITEM_USAGE_AMOUNT_AS_DOUBLE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['volume'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment()
                .subtract(1, 'month')
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment()
                .subtract(1, 'month')
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EC2,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_UNIT,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.GB_MO,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.EBS_VOLUME,
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpCloudStorageCoverageQuery
 * @description Function to return the Cloud Storage Coverage Query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpCloudStorageCoverageQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'volume',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SKU_DESCRIPTION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['volume'],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment()
                .subtract(1, 'month')
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment()
                .subtract(1, 'month')
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.CLOUD_STORAGE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.USAGE_UNIT,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.BYTE_SECONDS,
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureDiskVolumeField
 * @description Function to return the query field for azure disk storage
 * @returns String query field
 */
const getAzureDiskVolumeField = (isStaticData: boolean) => {
  let query = 'SUM(CASE ';

  for (const [diskType, memory] of Object.entries(DiskTypeToStorageMap)) {
    query += `WHEN MeterName LIKE '${diskType}' THEN ${memory} * CAST(Quantity AS ${
      isStaticData ? 'FLOAT64' : 'FLOAT'
    }) `;
  }

  query += 'ELSE 0 END)';
  return query;
};

/**
 * @function getAzureManagedDiskCoverageQuery
 * @description Function to return theManaged Disk Coverage Query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureManagedDiskCoverageQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'volume',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.METER_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },

      {
        label: 'cost',
        field: getAzureDiskVolumeField(isStaticData),
      },
    ],
    groupBy: ['volume'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment()
                .subtract(1, 'month')
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment()
                .subtract(1, 'month')
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_SUB_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: `%${QUERY_VALUES.MANAGED_DISK}%`,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getStorageCoverageQuery
 * @description Function to return the Storage Coverage Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @returns Object containing the query
 */
export const getStorageCoverageQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureManagedDiskCoverageQuery(
        dashboardMetaData,
        selectedAccounts,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEbsVolumeCoverageQuery(
        dashboardMetaData,
        selectedAccounts,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpCloudStorageCoverageQuery(
        dashboardMetaData,
        selectedAccounts,
        isStaticData
      );
  }
};

/**
 * @function getStorageMonthlyCostLabelByProvider
 * @description Function to return the Storage monthly cost by provider
 * @param provider Provider for which the label is required
 * @returns String containing the label
 */
export const getStorageMonthlyCostLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('graphHeadings.azureManagedDiskMonthlyCost');

    case PROVIDER.AWS:
      return i18n.t('graphHeadings.ebsStorageMonthlyCost');

    case PROVIDER.GCP:
    default:
      return i18n.t('graphHeadings.cloudStorageMonthlyCost');
  }
};

/**
 * @function getAwsEbsStorageMonthlyCostQuery
 * @description Function to return the EBS Storage Monthly Cost Query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsEbsStorageMonthlyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAWSUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_FAMILY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.STORAGE, QUERY_VALUES.SYSTEM_OPERATION],
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpCloudStorageMonthlyCostQuery
 * @description Function to return the Cloud Storage Monthly Cost Query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpCloudStorageMonthlyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getGcpUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['month'],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.CLOUD_STORAGE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureManagedDiskMonthlyCostQuery
 * @description Function to return the Azure Managed Disk Monthly Cost Query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureManagedDiskMonthlyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAzureUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_SUB_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: `%${QUERY_VALUES.MANAGED_DISK}%`,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getStorageMonthlyCostQuery
 * @description Function to return the Storage Monthly Cost Query by provider
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getStorageMonthlyCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureManagedDiskMonthlyCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEbsStorageMonthlyCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpCloudStorageMonthlyCostQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getStorageSpendLabelByProvider
 * @description Function to return the Storage spend label by provider
 * @param provider Provider for which the label is required
 * @returns String containing the label
 */
export const getStorageSpendLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('graphHeadings.azureManagedDiskCostByDiskType');

    case PROVIDER.AWS:
      return i18n.t('graphHeadings.ebsStorageSpend');

    case PROVIDER.GCP:
    default:
      return i18n.t('graphHeadings.cloudStorageCostBySKU');
  }
};

/**
 * @function getAwsEbsStorageSpendQuery
 * @description Function to return the EBS Storage Spend Query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsEbsStorageSpendQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'volume',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.EBS_VOLUME_COVERAGE_SPLIT_COLUMN
            : QUERY_FIELDS.EBS_VOLUME_COVERAGE_SPLIT_PART_COLUMN,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.SUM_CAST_FLOAT_64_LINE_ITEM_UNBLENDED_COST
            : QUERY_FIELDS.SUM_CAST_DECIMAL_LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['volume', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_FAMILY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.STORAGE, QUERY_VALUES.SYSTEM_OPERATION],
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpCloudStorageSpendBySKUQuery
 * @description Function to return the Cloud Storage Spend by SKU Query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpCloudStorageSpendBySKUQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'volume',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SKU_DESCRIPTION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['volume', 'month'],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.CLOUD_STORAGE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureStorageSpendByDiskTypeQuery
 * @description Function to return the Storage Spend by Disk Type Query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureStorageSpendByDiskTypeQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'volume',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.METER_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['volume', 'month'],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_SUB_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: `%${QUERY_VALUES.MANAGED_DISK}%`,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getStorageSpendByStorageTypeQuery
 * @description Function to return the Storage Spend Query by storage type
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getStorageSpendByStorageTypeQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureStorageSpendByDiskTypeQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsEbsStorageSpendQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpCloudStorageSpendBySKUQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getEbsOperationUsageCostQuery
 * @description Function to return the EBS Operations Usage Cost query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getEbsOperationUsageCostQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const { tagsFilters } = store.getState().dashboard;
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  return {
    columns: [
      {
        label: 'operation',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_OPERATION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: isStaticData
          ? `FORMAT_TIMESTAMP('%Y-%m-%d',${QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE})`
          : `DATE_FORMAT(${QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE}, '%Y-%m-%d')`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['operation', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_PRODUCT_CODE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_EBS,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getEbsSnapshotSpendQuery
 * @description Function to return the EBS Snapshot Spend Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getEbsSnapshotSpendQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const { tagsFilters } = store.getState().dashboard;

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAWSUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['account', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_FAMILY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.STORAGE_SNAPSHOT,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getEbsVolumesSpendQuery
 * @description Function to return the EBS Volumes Spend query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is construct
 * @returns Object containing the query
 */
export const getEbsVolumesSpendQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const { tagsFilters } = store.getState().dashboard;
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  return {
    columns: [
      {
        label: 'resourceId',
        field: QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['resourceId'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: QUERY_VALUES.EBS_VOLUME,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: QUERY_FIELDS.LINE_ITEM_RESOURCE_ID,
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getStorageFsUsageCostLabelByProvider
 * @description Function to return the Storage FS Usage cost label by provider
 * @param provider Provider for which the label is required
 * @returns String containing the label
 */
export const getStorageFsUsageCostLabelByProvider = (provider: string) => {
  return provider === PROVIDER.AWS
    ? i18n.t('graphHeadings.storageFsUsageCostByAccount')
    : i18n.t('graphHeadings.storageFsUsageCostBySubscription');
};

/**
 * @function getAwsStorageFsCostByAccountsQuery
 * @description Function to return the Storage FS Usage Cost by Account Query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsStorageFsCostByAccountsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAWSUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['account', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_FSX,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureStorageFsCostBySubscriptionsQuery
 * @description Function to return the Storage FS Usage Cost by Subscription Query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureStorageFsCostBySubscriptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SUBSCRIPTION_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAzureUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['account', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_SUB_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: `%${QUERY_VALUES.BLOB}%`,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getStorageFsCostByAccountsQuery
 * @description Function to return theStorage FS Usage Cost by Account Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getStorageFsCostByAccountsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  return dashboardMetaData.dashboard.connectorProvider === PROVIDER.AWS
    ? getAwsStorageFsCostByAccountsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      )
    : getAzureStorageFsCostBySubscriptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
};

/**
 * @function StorageFsCostByRegions
 * @description Function to return the Storage Fs Cost By Regions label by provider
 * @param provider Provider for which the label is required
 * @returns String containing the label
 */
export const getStorageFsCostByRegionsLabelByProvider = (provider: string) => {
  return provider === PROVIDER.GCP
    ? i18n.t('graphHeadings.cloudStorageCostByRegion')
    : i18n.t('graphHeadings.storageFsUsageCostByRegion');
};

/**
 * @function getAwsStorageFsCostByRegionsQuery
 * @description Function to return the Storage FS Usage Cost by Region Query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsStorageFsCostByRegionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'region',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_REGION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['region', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_FSX,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpCloudStorageCostByRegionsQuery
 * @description Function to return the Cloud Storage Cost by Region Query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpCloudStorageCostByRegionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'region',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LOCATION_LOCATION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['region', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.CLOUD_STORAGE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureStorageFsCostByRegionsQuery
 * @description Function to return the Storage FS Usage Cost by Region Query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureStorageFsCostByRegionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'region',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.RESOURCE_LOCATION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['region', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_SUB_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: `%${QUERY_VALUES.BLOB}%`,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getStorageFsCostByRegionsQuery
 * @description Function to return the Storage FS Usage Cost by Region Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getStorageFsCostByRegionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureStorageFsCostByRegionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsStorageFsCostByRegionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpCloudStorageCostByRegionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getStorageFsCostByProductCodeQuery
 * @description Function to return the Storage FS Usage Cost by Product Code Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getStorageFsCostByProductCodeQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const { tagsFilters } = store.getState().dashboard;
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  return {
    columns: [
      {
        label: 'productCode',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['productCode', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.AMAZON_FSX,
                QUERY_VALUES.AMAZON_ELASTIC_FILE_SYSTEM,
              ],
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getStorageFsCostByUsageTypeQuery
 * @description Function to return theStorage FS Usage Cost by Usage Type Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getStorageFsCostByUsageTypeQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const { tagsFilters } = store.getState().dashboard;

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  return {
    columns: [
      {
        label: 'usageType',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['usageType', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_FSX,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAwsStorageFsCostByOperationQuery
 * @description Function to return the Storage FS Usage Cost by Operation Query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsStorageFsCostByOperationQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'operation',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_OPERATION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: isStaticData
          ? `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )})`
          : `DATE_FORMAT(${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )}, '%Y-%m-%d')`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['operation', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.AMAZON_FSX,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpCloudStorageCostBySKUQuery
 * @description Function to return the Cloud Storage Cost by SKU Query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpCloudStorageCostBySKUQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'operation',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SKU_DESCRIPTION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.USAGE_END_TIME,
          dashboardMetaData.connection.focusConversionEnabled
        )})`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['operation', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.CLOUD_STORAGE,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureStorageFsCostByOperationQuery
 * @description Function to return the Storage FS Usage Cost by Operation Query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureStorageFsCostByOperationQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'operation',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.METER_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE
            : QUERY_FIELDS.CONVERT_DATETIME_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['operation', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.METER_SUB_CATEGORY,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LIKE,
            value: `%${QUERY_VALUES.BLOB}%`,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function StorageFsCostByRegions
 * @description Function to return the Storage Fs Cost By Regions label by provider
 * @param provider Provider for which the label is required
 * @returns String containing the label
 */
export const getStorageFsCostByOperationLabelByProvider = (
  provider: string
) => {
  return provider === PROVIDER.GCP
    ? i18n.t('graphHeadings.cloudStorageDailyCostBySKU')
    : i18n.t('graphHeadings.storageFsUsageCostByOperation');
};

/**
 * @function getStorageFsCostByOperationQuery
 * @description Function to return theStorage FS Usage Cost by Operation Query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getStorageFsCostByOperationQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureStorageFsCostByOperationQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsStorageFsCostByOperationQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpCloudStorageCostBySKUQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function geVolumeColumnTitle
 * @description Function to return the Volumes Coverage Column heading
 * @param provider provider for the column label is fetched
 * @returns String column heading
 */
const geVolumeColumnTitle = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('diskType');

    case PROVIDER.AWS:
      return i18n.t('ebsVolume');

    case PROVIDER.GCP:
    default:
      return i18n.t('sku');
  }
};

/**
 * @function getVolumesCoverageColumns
 * @description Function to return the Volumes Coverage Columns by provider
 * @param provider provider for which the columns is fetched
 * @returns List of columns
 */
export const getVolumesCoverageColumns = (provider: string) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: geVolumeColumnTitle(provider),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    {
      title: i18n.t('awsStorageDashboard.quantity'),
      dataIndex: 'value',
      key: 'value',
      render: (text: number) => numberCommaSeparator(text),
      width: 140,
    },
  ];
};

/**
 * @function getEbsStorageMonthlyCostColumns
 * @description Function to return the EBS Storage Average Unit Cost Columns
 * @param dateRange of dates for which the columns are required
 * @returns List of columns
 */
export const getEbsStorageMonthlyCostColumns = (dateRange: string[]) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: i18n.t('awsStorageDashboard.month'),
      dataIndex: 'time',
      key: 'time',
      width: 140,
    },
    {
      title: i18n.t('awsStorageDashboard.cost', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'value',
      key: 'value',
      width: 140,
    },
  ];
};

/**
 * @function getStorageSpendColumns
 * @description Function to return the Storage Spend Columns
 * @param provider provider for which the columns is fetched
 * @param dateRange of dates for which the columns are required
 * @returns List of columns
 */
export const getStorageSpendColumns = (
  provider: string,
  dateRange: string[]
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: geVolumeColumnTitle(provider),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...getMonthYearShortList(dateRange[0], dateRange[1], MONTH_YEAR_FORMAT).map(
      (month) => ({
        title: month,
        key: `${month}-value`,
        dataIndex: `${month}-value`,
        width: 100,
      })
    ),
  ];
};

/**
 * @function getEbsOperationUsageCostColumns
 * @description Function to return the EBS Operation Usage Cost Columns
 * @param dateRange of dates for which the columns are required
 * @returns List of columns
 */
export const getEbsOperationUsageCostColumns = (dateRange: string[]) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: i18n.t('awsStorageDashboard.operation'),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...enumerateDaysBetweenDates(dateRange, DATE_FORMAT, 'day').map((time) => ({
      title: time,
      key: `${time}-value`,
      dataIndex: `${time}-value`,
      width: 100,
    })),
  ];
};

/**
 * @function getRegionWithGroupingValuesColumns
 * @description Function to return the Region by grouping values Columns
 * @param groupingValues List of grouping values for which the columns are required
 * @returns List of columns
 */
export const getRegionWithGroupingValuesColumns = (
  groupingValues: string[]
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: i18n.t('awsStorageDashboard.region'),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...groupingValues.map((groupingValue) => ({
      title: groupingValue,
      key: `${groupingValue}-value`,
      dataIndex: `${groupingValue}-value`,
      width: 140,
    })),
  ];
};

export const getStorageFsUsageCostByOperationColumnLabel = (
  provider: string
) => {
  return provider === PROVIDER.GCP
    ? i18n.t('sku')
    : i18n.t('awsStorageDashboard.operation');
};

/**
 * @function getAWSStorageExcelData
 * @description Returns the the excel export data for storage
 * @param connectorName name of the connection chosen
 * @param selectedAccounts List of accounts selected
 * @param data export data for all tables
 * @returns List of excel export data
 */
export const getAWSStorageExcelData = (
  connectorName: string,
  provider: string,
  selectedAccounts: string[],
  data: {
    ebsSpendByAccounts: ExcelData;
    ebsVolumeUsage: ExcelData;
    ebsStorageMonthlyCost: ExcelData;
    ebsStorageSpend: ExcelData;
    ebsOperationsUsageCost: ExcelData;
    ebsSnapshotSpend: ExcelData;
    ebsVolumesSpend: ExcelData;
    storageFsCostByAccounts: ExcelData;
    storageFsCostByRegions: ExcelData;
    storageFsCostByProductCode: ExcelData;
    storageFsCostByUsageType: ExcelData;
    storageFsCostByOperation: ExcelData;
  }
) => {
  return [
    getChartExcelDataWithDateFilter({
      ...data.ebsSpendByAccounts,
      columns: getAccountWithGroupingValuesColumns(
        getMonthYearShortList(
          data.ebsSpendByAccounts.dateRange[0],
          data.ebsSpendByAccounts.dateRange[1],
          MONTH_YEAR_FORMAT
        ),
        getAccountLabelByProvider(provider)
      ),
      data: getUsageCostTableData(data.ebsSpendByAccounts.data, 'time'),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.ebsVolumeUsage,
      columns: getVolumesCoverageColumns(provider),
      data: insertIndex(data.ebsVolumeUsage.data).map((item) => ({
        ...item,
        value: numberCommaSeparator(item.value),
      })),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.ebsStorageMonthlyCost,
      columns: getEbsStorageMonthlyCostColumns(
        data.ebsStorageMonthlyCost.dateRange
      ),
      data: insertIndex(
        data.ebsStorageMonthlyCost.data.map((item: any) => ({
          ...item,
          value: currencySymbol + numberCommaSeparator(item.value),
        }))
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.ebsStorageSpend,
      columns: getStorageSpendColumns(provider, data.ebsStorageSpend.dateRange),
      data: getUsageCostTableData(data.ebsStorageSpend.data, 'time'),
      connectorName,
      provider,
      selectedAccounts,
    }),
    {
      ...getChartExcelDataWithDateFilter({
        ...data.ebsOperationsUsageCost,
        columns: getEbsOperationUsageCostColumns(
          data.ebsOperationsUsageCost.dateRange
        ),
        data: getDualAxisCostByTypeTableData(data.ebsOperationsUsageCost.data),
        connectorName,
        provider,
        selectedAccounts,
      }),
      excludeInGlobalDownload: !hasChartBasedOnProvider(
        provider,
        data.ebsOperationsUsageCost.graph
      ),
    },
    {
      ...getChartExcelDataWithDateFilter({
        ...data.ebsSnapshotSpend,
        columns: getAccountWithGroupingValuesColumns(
          getMonthYearShortList(
            data.ebsSnapshotSpend.dateRange[0],
            data.ebsSnapshotSpend.dateRange[1],
            MONTH_YEAR_FORMAT
          )
        ),
        data: getUsageCostTableData(data.ebsSnapshotSpend.data, 'time'),
        connectorName,
        provider,
        selectedAccounts,
      }),
      excludeInGlobalDownload: !hasChartBasedOnProvider(
        provider,
        data.ebsSnapshotSpend.graph
      ),
    },
    {
      ...getChartExcelDataWithDateFilter({
        ...data.ebsVolumesSpend,
        columns: getEbsVolumesSpendColumns(),
        data: insertIndex(data.ebsVolumesSpend.data).map((item) => ({
          ...item,
          value: currencySymbol + numberCommaSeparator(item.value),
        })),
        connectorName,
        provider,
        selectedAccounts,
      }),
      excludeInGlobalDownload: !hasChartBasedOnProvider(
        provider,
        data.ebsVolumesSpend.graph
      ),
    },
    {
      ...getChartExcelDataWithDateFilter({
        ...data.storageFsCostByAccounts,
        columns: getAccountWithGroupingValuesColumns(
          getMonthYearShortList(
            data.storageFsCostByAccounts.dateRange[0],
            data.storageFsCostByAccounts.dateRange[1],
            MONTH_YEAR_FORMAT
          ),
          getAccountLabelByProvider(provider)
        ),
        data: insertIndex(
          getUsageCostTableData(data.storageFsCostByAccounts.data, 'time')
        ),
        connectorName,
        provider,
        selectedAccounts,
      }),
      excludeInGlobalDownload: !hasChartBasedOnProvider(
        provider,
        data.storageFsCostByAccounts.graph
      ),
    },
    getChartExcelDataWithDateFilter({
      ...data.storageFsCostByRegions,
      columns: getRegionWithGroupingValuesColumns(
        getMonthYearShortList(
          data.storageFsCostByRegions.dateRange[0],
          data.storageFsCostByRegions.dateRange[1],
          MONTH_YEAR_FORMAT
        )
      ),
      data: insertIndex(
        getUsageCostTableData(data.storageFsCostByRegions.data, 'time')
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
    {
      ...getChartExcelDataWithDateFilter({
        ...data.storageFsCostByProductCode,
        columns: getAccountWithGroupingValuesColumns(
          getMonthYearShortList(
            data.storageFsCostByProductCode.dateRange[0],
            data.storageFsCostByProductCode.dateRange[1],
            MONTH_YEAR_FORMAT
          ),
          i18n.t('awsStorageDashboard.productCode')
        ),
        data: insertIndex(
          getUsageCostTableData(data.storageFsCostByProductCode.data, 'time')
        ),
        connectorName,
        provider,
        selectedAccounts,
      }),
      excludeInGlobalDownload: !hasChartBasedOnProvider(
        provider,
        data.storageFsCostByProductCode.graph
      ),
    },
    {
      ...getChartExcelDataWithDateFilter({
        ...data.storageFsCostByUsageType,
        columns: getAccountWithGroupingValuesColumns(
          getMonthYearShortList(
            data.storageFsCostByUsageType.dateRange[0],
            data.storageFsCostByUsageType.dateRange[1],
            MONTH_YEAR_FORMAT
          ),
          i18n.t('awsStorageDashboard.usageType')
        ),
        data: insertIndex(
          getUsageCostTableData(data.storageFsCostByUsageType.data, 'time')
        ),
        connectorName,
        provider,
        selectedAccounts,
      }),
      excludeInGlobalDownload: !hasChartBasedOnProvider(
        provider,
        data.storageFsCostByUsageType.graph
      ),
    },
    getChartExcelDataWithDateFilter({
      ...data.storageFsCostByOperation,
      columns: getAccountWithGroupingValuesColumns(
        enumerateDaysBetweenDates(
          data.storageFsCostByOperation.dateRange,
          DATE_FORMAT,
          'day'
        ),
        i18n.t('awsStorageDashboard.operation')
      ),
      data: insertIndex(
        getUsageCostTableData(data.storageFsCostByOperation.data, 'time')
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
  ];
};

/**
 * @function hasChartBasedOnProvider
 * @description Returns the boolean based on the provider if the charts are rendered or not
 * @param provider provider for which the condition is validated.
 * @param chart The chart name for which the condition is checked for
 * @returns boolean true if the chart is included else false
 */
export const hasChartBasedOnProvider = (provider: string, chart?: string) => {
  switch (chart) {
    case StorageDashboardGraphs.EBS_OPERATION_USAGE_COST:
    case StorageDashboardGraphs.EBS_SNAPSHOT_SPEND:
    case StorageDashboardGraphs.EBS_VOLUMES_SPEND:
    case StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_PRODUCT_CODE:
    case StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_USAGE_TYPE:
      return provider === PROVIDER.AWS;

    case StorageDashboardGraphs.STORAGE_FS_USAGE_COST_BY_ACCOUNT:
      return [PROVIDER.AWS, PROVIDER.AZURE].includes(provider as PROVIDER);

    default:
      return true;
  }
};
