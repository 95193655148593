import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { customDashboard, setCustomViewData } from 'redux/customDashboardSlice';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { CHARTS_LIST, FIELD_TYPE } from 'constants/dashboard';
import {
  getColumnLabelByField,
  getFieldCategory,
  getSelectedChart,
  getSelectedChartLayout,
} from 'pages/CustomDashboardPage/utils';
import { COLORS } from 'constants/graphConfig';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { ColumnType } from 'types/dashboard';

import './index.scss';

const ChartDimensionList = () => {
  const dispatch = useDispatch();
  const {
    selectedDimensions,
    customViewData,
    selectedChartIndex,
    selectedChartRow,
  } = useSelector(customDashboard);

  const [allChartDimensions, setAllChartDimensions] = useState<ColumnType[]>(
    []
  );

  useEffect(() => {
    setChartDimensionByOrderOfSelection(true);
  }, [getSelectedChart()?.chartType]);

  useEffect(() => {
    setChartDimensionByOrderOfSelection();
    updateChartColumns(
      selectedDimensions.filter((dimension) =>
        getSelectedChart()?.chartQuery?.groupBy?.includes(dimension.field)
      )
    );
  }, [selectedDimensions]);

  /**
   * @function setChartDimensionByOrderOfSelection
   * @description Function to set the chart dimension by the order of chart columns
   */
  const setChartDimensionByOrderOfSelection = (firstRender = false) => {
    const chartGroupBy = getSelectedChart()?.chartQuery?.groupBy;
    const chartDimensions: ColumnType[] = [];
    chartGroupBy?.forEach((item) => {
      const dimensionColumn = selectedDimensions.find(
        (dimension) => dimension.field === item
      );
      if (dimensionColumn) {
        chartDimensions.push(dimensionColumn);
      }
    });

    chartDimensions.push(
      ...selectedDimensions.filter(
        (item) => !chartGroupBy?.includes(item.field)
      )
    );

    if (firstRender) {
      // show time dimension first
      const timestampDimension = chartDimensions.find(
        (item) => getFieldCategory(item.field) === FIELD_TYPE.TIME
      );
      if (timestampDimension) {
        const timestampDimensionIndex = chartDimensions.findIndex(
          (item) => item.field === timestampDimension.field
        );
        if (timestampDimensionIndex > -1) {
          chartDimensions.splice(timestampDimensionIndex, 1);
          chartDimensions.unshift(timestampDimension);
        }
      }
    }
    setAllChartDimensions(chartDimensions);
  };

  /**
   * @function getMaxDimensionCount
   * @description Function to return the max dimension count of selected chart
   * @returns maximum dimension numerical value. Defaults to 1.
   */
  const getMaxDimensionCount = () => {
    const chart = getSelectedChart();
    return (
      CHARTS_LIST.find((item) => item.chartType === chart?.chartType)
        ?.dimensionsCount?.max ?? 1
    );
  };

  /**
   * @function updateChartColumns
   * @description Function to update the chart columns
   * @param dimensions dimensions to be updated with
   */
  const updateChartColumns = (dimensions: ColumnType[]) => {
    const allLayouts = Array.from(customViewData.layoutDesigns);
    let layout = getSelectedChartLayout();
    const allCharts = Array.from(layout?.charts ?? []);
    let chart = allCharts?.at(selectedChartIndex);

    if (chart) {
      const metrics =
        chart.chartQuery?.columns?.filter((item) =>
          chart?.chartQuery?.aggregators?.some(
            (aggregator) => aggregator.label === item.field
          )
        ) ?? [];
      const chartQuery = {
        ...chart.chartQuery,
        columns: [...dimensions, ...metrics],
        groupBy: dimensions.map((item) => item.field),
      };
      chart = { ...chart, chartQuery: chartQuery };
      allCharts?.splice(selectedChartIndex, 1, chart);
    }

    if (layout) {
      layout = { ...layout, charts: allCharts };
      allLayouts.splice(selectedChartRow, 1, layout);
    }

    dispatch(
      setCustomViewData({ ...customViewData, layoutDesigns: allLayouts })
    );
  };

  /**
   * @function onChangeDimensionSelection
   * @description Callback function for selecting dimension
   * @param e Event for selection change
   */
  const onChangeDimensionSelection = (e: any) => {
    if (e.target.type === 'radio') {
      updateChartColumns([
        ...allChartDimensions.filter((item) => item.field === e.target.value),
      ]);
    }

    const chart = getSelectedChart();
    let dimensionColumns: ColumnType[];
    const columns = chart?.chartQuery?.columns ?? [];
    const groupBy = chart?.chartQuery?.groupBy;
    const existingDimensions = columns?.filter((item) =>
      groupBy?.includes(item.field)
    );

    if (e.target.checked) {
      const dimensions = [
        ...existingDimensions,
        { field: e.target.value, label: e.target.value },
      ];
      dimensionColumns = allChartDimensions.filter((item) =>
        dimensions.some((dimension) => dimension.field === item.field)
      );
    } else {
      dimensionColumns = existingDimensions?.filter(
        (item) => item.field !== e.target.value
      );
    }

    updateChartColumns(dimensionColumns);
  };

  /**
   * @function isDimensionChecked
   * @description Function to check if the dimension is selected
   * @returns boolean true if selected else false
   */
  const isDimensionChecked = (dimension: ColumnType) => {
    const chart = getSelectedChart();
    const columns = chart?.chartQuery?.columns ?? [];

    return columns.some((item) => item.field === dimension.field);
  };

  /**
   * @function isDimensionDisabled
   * @description Function to return if the dimension is disabled
   * @param dimension Dimension for which the validation is performed.
   * @returns boolean true if the dimension is disabled else false
   */
  const isDimensionDisabled = (dimension: ColumnType) => {
    const chart = getSelectedChart();
    const dimensions = chart?.chartQuery?.groupBy;
    if (dimensions?.includes(dimension.field)) {
      return false;
    }

    return (dimensions?.length ?? 0) >= getMaxDimensionCount();
  };

  /**
   * @function isDraggingDisabled
   * @description Function to return if the dragging is disabled
   * @param dimension Dimension for which the validation is performed.
   * @returns boolean true if the dragging is disabled else false
   */
  const isDraggingDisabled = (dimension: ColumnType) => {
    const chart = getSelectedChart();
    const columns = chart?.chartQuery?.columns;
    return !columns?.some((item) => item.field === dimension.field);
  };

  /**
   * @function handleOnDragEnd
   * @description Updates the selected dimensions list based on the drag and drop
   * @param result result of the drag and drop
   */
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const allDimensions = Array.from(allChartDimensions);
    const [reorderedItem] = allDimensions.splice(result.source.index, 1);
    allDimensions.splice(result.destination.index, 0, reorderedItem);
    setAllChartDimensions(allDimensions);

    const chartGroupBy = getSelectedChart()?.chartQuery?.groupBy;
    const reOrderedDimensions = allDimensions.filter((item) =>
      chartGroupBy?.includes(item.field)
    );
    updateChartColumns(reOrderedDimensions);
  };

  return (
    <div className="chart-dimensions-list">
      {getMaxDimensionCount() > 1 ? (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="chart-dimensions">
            {(provided) => (
              <ul
                className="draggable-chart-dimension-list flex flex-column flex-gap-8"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {allChartDimensions.map((dimension, index) => (
                  <Draggable
                    key={dimension.field}
                    draggableId={dimension.field}
                    index={index}
                    isDragDisabled={isDraggingDisabled(dimension)}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex flex-align-items-center flex-gap-4"
                      >
                        <Icon
                          iconName={ICONS.DRAGGABLE}
                          size={ICONS_SIZE.SM}
                          color={COLORS.colorRegentGrey}
                        />
                        <Checkbox
                          checked={isDimensionChecked(dimension)}
                          value={dimension.field}
                          disabled={isDimensionDisabled(dimension)}
                          onChange={onChangeDimensionSelection}
                        >
                          {getColumnLabelByField(dimension.field)}
                        </Checkbox>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="flex flex-column flex-gap-8">
          {allChartDimensions.map((dimension) => (
            <Radio
              key={dimension.field}
              value={dimension.field}
              checked={isDimensionChecked(dimension)}
              onChange={onChangeDimensionSelection}
            >
              {getColumnLabelByField(dimension.field)}
            </Radio>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChartDimensionList;
