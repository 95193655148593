import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import moment from 'moment';

import {
  selectReport,
  setReportView,
  setReportConnection,
  setReportDatasource,
  setReportGroup,
  setReportOptions,
  setTypeOfConnection,
  setReportNameValidation,
  setIsReportNameExists,
  setReportMetrics,
  setReportDimensions,
  setReportGroupAvailableFields,
  setSelectedReportDimensions,
  setSelectedReportMetrics,
  setExistingReport,
  setReportData,
  setTagMaps,
  setTagKeyValues,
} from 'redux/reportSlice';
import { setNavSubPath } from 'redux/activeNavMenuSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import Button from 'components/Button';
import NavigationPath from 'components/NavigationPath';
import DashboardComponent from 'components/DashboardComponent';
import {
  ReportPermissions,
  REPORTS_TYPES_LIST,
} from 'pages/ReportsPage/constants';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import {
  addZeroMarginClass,
  createAvailableFieldsTypeForGroup,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { BUTTON_TYPE } from 'constants/appearance';
import {
  FieldSource,
  FIELD_TYPE,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import {
  defaultCustomDashboardDataSource,
  defaultReportOptions,
} from 'constants/defaultValues';
import {
  ANT_MESSAGE_LONG_DURATION,
  DASHBOARD_TYPES,
  ERROR_KEY,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import {
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { DateRangePresetOptions } from 'components/DatePicker/constants';
import { CHART_TYPES } from 'constants/graphConfig';
import { DATE_FORMAT } from 'utils/date';
import {
  fetchConnectionById,
  fetchGroupDashboardMetaData,
  getBillingMappingData,
  getCustomGroupAvailableFields,
  getLabels,
  getSystemBillingMappingData,
  getTagMappingData,
} from 'utils/services';
import {
  AvailableCustomGroupFieldsType,
  AvailableFieldsType,
  CustomDashboardDataSourceType,
} from 'types/dashboard';
import { validateQueryAndGetAvailableFields } from 'components/CustomViewDataSourceDrawer/services';
import { ReportOptionsType } from 'pages/ReportsPage/types';
import { PROVIDER } from 'constants/cloudProviders';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';

import {
  CreateReportsPageComponent,
  DateRanges,
  ReportsDateRangesToPresetValuesMap,
  ReportsViewType,
} from './constants';
import { createReport, getReportByReportName, updateReport } from './services';
import ReportComponent from './components/ReportComponent';
import { getReportPageTitle } from './utils';

import './index.scss';

const CreateReportPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    reportOptions,
    reportView,
    reportConnection,
    reportGroup,
    reportDatasource,
    isReportNameExists,
    existingReport,
  } = useSelector(selectReport);

  const [reportsPageComponent, setReportsPageComponent] = useState(
    CreateReportsPageComponent.CANVAS
  );
  const [saveReportReqStatus, setSaveReportReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchReportByNameReqStatus, setFetchReportByNameReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [
    fetchConnectionOrGroupDataReqStatus,
    setFetchConnectionOrGroupDataReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [fetchAvailableFieldsReqStatus, setFetchAvailableFieldsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
      dispatch(setReportDatasource(defaultCustomDashboardDataSource));
      dispatch(setReportConnection(null));
      dispatch(setReportGroup(null));
      dispatch(setTypeOfConnection(MY_DASHBOARD_TYPES.SINGLE_CONNECTION));
      dispatch(setReportOptions(defaultReportOptions));
      dispatch(setIsReportNameExists(false));
      dispatch(setReportNameValidation(''));
      dispatch(setReportData({ chartData: [], tableData: [] }));
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paths: string[] = [];

    const reportType = getReportTypeByLocation();
    paths.push(reportType.title);

    const reportName = queryParams.get('report');

    const reportView =
      queryParams.get('view')?.toUpperCase() ?? ReportsViewType.OPEN;
    dispatch(setReportView(reportView));

    if (reportView === ReportsViewType.EDIT && !reportName) {
      paths.push(t('reports.createReport'));
    }

    if (reportView !== ReportsViewType.EDIT && reportName) {
      paths.push(reportName);
    }

    dispatch(setNavSubPath(paths));

    if (reportView === ReportsViewType.OPEN && !reportName) {
      navigateToCreateNewReport();
    }

    if (
      reportView !== ReportsViewType.PREVIEW &&
      reportName &&
      !reportConnection &&
      !reportGroup
    ) {
      fetchReportByName(reportName);
    }
  }, [location.search]);

  /**
   * @function navigateToCreateNewReport
   * @description Function to navigate to the create new report path
   */
  const navigateToCreateNewReport = () => {
    const queryParams = new URLSearchParams(location.search);
    const reportType = getReportTypeByLocation();
    queryParams.set('view', ReportsViewType.EDIT);
    queryParams.set('reportType', reportType.id.toLowerCase());
    queryParams.delete('report');
    navigate(NAVIGATION_MENU_PATH.CREATE_REPORT + '?' + queryParams.toString());
  };

  /**
   * @function getReportTypeByLocation
   * @description function to fetch the report type by the url location
   * @returns Object containing the report type details (Shared or My Report)
   */
  const getReportTypeByLocation = () => {
    const queryParams = new URLSearchParams(location.search);
    const reportTypeQuery = queryParams.get('reportType')?.toUpperCase();

    let reportType = REPORTS_TYPES_LIST.find(
      (item) => item.id === reportTypeQuery
    );

    if (!reportType) {
      reportType = REPORTS_TYPES_LIST[0];
      navigate(
        NAVIGATION_MENU_PATH.REPORTS +
          '?reportType=' +
          reportType.id.toLocaleLowerCase()
      );
    }

    return reportType;
  };

  /**
   * @function getDatesByRange
   * @description Function to return the start and end dates based on the date ranges
   * @param range range for which the dates are required
   * @param startDate startDate for custom range
   * @param endDate endDate for custom range
   * @returns Object containing the report type details (Shared or My Report)
   */
  const getDatesByRange = (
    range: string,
    startDate: string,
    endDate: string
  ) => {
    switch (range) {
      case DateRanges.THIRTY:
        return {
          startDate: moment().subtract(30, 'days').format(DATE_FORMAT),
          endDate: moment().format(DATE_FORMAT),
        };

      case DateRanges.NINETY:
        return {
          startDate: moment().subtract(90, 'days').format(DATE_FORMAT),
          endDate: moment().format(DATE_FORMAT),
        };

      case DateRanges.CUSTOM:
        return {
          startDate: startDate,
          endDate: endDate,
        };

      case DateRanges.SEVEN:
      default:
        return {
          startDate: moment().subtract(7, 'days').format(DATE_FORMAT),
          endDate: moment().format(DATE_FORMAT),
        };
    }
  };

  /**
   * @function fetchReportByName
   * @description Function to fetch the report by report name
   * @param reportName Name of the report
   */
  const fetchReportByName = (reportName: string) => {
    setFetchReportByNameReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      reportName: reportName,
    };

    getReportByReportName(params)
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          const dates = getDatesByRange(
            data?.dateRanges,
            data?.startDate,
            data?.endDate
          );
          const reportOptions: ReportOptionsType = {
            reportName: data?.reportName ?? '',
            createdBy: data?.createdBy ?? '',
            dimension: data?.dimension ?? [],
            metric: data?.metric ?? [],
            chartType: data?.chartType ?? CHART_TYPES.TABLE,
            chartDimension: data?.chartDimension ?? [],
            chartMetric: data?.chartMetric ?? [],
            sort: data?.sort ?? [],
            startDate: dates.startDate,
            endDate: dates.endDate,
            dateRanges:
              ReportsDateRangesToPresetValuesMap.find(
                (item) => item.dateRange === data?.dateRanges
              )?.presetValue ?? DateRangePresetOptions.CUSTOM,
          };

          dispatch(setReportOptions(reportOptions));

          dispatch(
            setExistingReport({
              reportId: data?.reportId,
              reportName: data?.reportName,
              reportPermission: data?.reportPermission,
              isOwner: data?.createdBy === localStorage.getItem('email'),
            })
          );

          dispatch(setSelectedReportDimensions(reportOptions.dimension));
          dispatch(
            setSelectedReportMetrics(
              reportOptions.metric.map((item: string) => ({
                label: item,
                field: item,
              }))
            )
          );

          const datasource = {
            dashboardTitle: '',
            dataSet: data?.dataSource?.dataset,
            dashBoardType: data?.dataSource?.dashBoardType,
            useCustomQuery: data?.dataSource?.useCustomQuery,
            customQuery: '',
            customQueryId: data?.dataSource?.customQueryId,
            availableFields: data?.dataSource?.availableFields,
          };

          dispatch(setReportDatasource(datasource));

          fetchConnectionData(data, datasource);

          setFetchReportByNameReqStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        navigateToCreateNewReport();
        setFetchReportByNameReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setFetchReportByNameReqStatus(REQUEST_STATUS.SUCCESS);
        navigateToCreateNewReport();
        dispatch(setExistingReport(null));
      });
  };

  /**
   * @function fetchConnectionData
   * @description Function to fetch the connection details after fetching the report
   * @param data Report data
   * @param datasource Datasource details
   */
  const fetchConnectionData = (data: any, datasource: any) => {
    if (data.connectionName) {
      fetchConnectionDataById(data?.connectorId);

      if (
        !datasource.useCustomQuery &&
        datasource.dashBoardType === DASHBOARD_TYPES.BILLING_DEFAULT
      ) {
        fetchDimensionsAndTagMap(data, datasource);
      } else {
        fetchConnectionFields(data?.connectorId, datasource);
      }
    }

    if (data?.groupName) {
      fetchGroupData(data?.groupName);
      fetchGroupsAvailableFields(datasource);
    }

    if (data?.reportPermission !== ReportPermissions.WRITE) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('view', ReportsViewType.OPEN);
      navigate(
        NAVIGATION_MENU_PATH.CREATE_REPORT + '?' + queryParams.toString()
      );
    }
  };

  /**
   * @function fetchConnectionDataById
   * @description Function to fetch the connection details
   * @param connectorId Connector ID for which the details are fetch
   */
  const fetchConnectionDataById = (connectorId: string) => {
    setFetchConnectionOrGroupDataReqStatus(REQUEST_STATUS.PROCESSING);
    fetchConnectionById(connectorId)
      .then((res: any) => {
        dispatch(setReportConnection(res.data.responseData));
        dispatch(setReportGroup(null));
        dispatch(setTypeOfConnection(MY_DASHBOARD_TYPES.SINGLE_CONNECTION));
        setFetchConnectionOrGroupDataReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchConnectionOrGroupDataReqStatus);
      });
  };

  /**
   * @function fetchGroupData
   * @description Function to fetch the group details
   * @param groupName Group name for which the details are fetch
   */
  const fetchGroupData = (groupName: string) => {
    setFetchConnectionOrGroupDataReqStatus(REQUEST_STATUS.PROCESSING);

    fetchGroupDashboardMetaData(groupName)
      .then((res: any) => {
        dispatch(setReportConnection(null));
        dispatch(setReportGroup(res?.data?.responseData));
        dispatch(setTypeOfConnection(MY_DASHBOARD_TYPES.GROUP));
        setFetchConnectionOrGroupDataReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchConnectionOrGroupDataReqStatus);
      });
  };

  /**
   * @function fetchConnectionFields
   * @description Function to fetch the fields for a connection
   * @param connectorId Connector ID for which the fields are fetched
   * @param datasource Datasource details
   */
  const fetchConnectionFields = (
    connectorId: string,
    datasource: CustomDashboardDataSourceType
  ) => {
    setFetchAvailableFieldsReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: connectorId,
      type: datasource.dashBoardType,
      database: datasource.dataSet,
    };

    const requestBody = {
      queryString: datasource.customQuery,
    };

    validateQueryAndGetAvailableFields(requestBody, params)
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res?.data?.responseData;

          dispatch(
            setReportMetrics(
              data?.filter(
                (availableField: AvailableFieldsType) =>
                  availableField.category === FIELD_TYPE.NUMERIC
              )
            )
          );
          dispatch(
            setReportDimensions(
              data?.filter((availableField: AvailableFieldsType) =>
                [
                  FIELD_TYPE.TIME.valueOf(),
                  FIELD_TYPE.LITERAL.valueOf(),
                ].includes(availableField.category)
              )
            )
          );

          dispatch(
            setReportDatasource({
              ...datasource,
              availableFields: data ?? [],
            })
          );
          setFetchAvailableFieldsReqStatus(REQUEST_STATUS.SUCCESS);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, false, setFetchAvailableFieldsReqStatus);
      });
  };

  /**
   * @function fetchDimensionsAndTagMap
   * @description Function to fetch the billing mapping and tag mapping
   * @param data Report data
   * @param datasource Datasource details
   */
  const fetchDimensionsAndTagMap = (
    data: any,
    datasource: CustomDashboardDataSourceType
  ) => {
    setFetchAvailableFieldsReqStatus(REQUEST_STATUS.PROCESSING);

    const labelParams = {
      connectorId: data?.connectorId,
      dashBoardType: datasource.dashBoardType,
    };

    const requests = [
      getSystemBillingMappingData(),
      getBillingMappingData(),
      getTagMappingData(),
      getLabels(labelParams),
    ];

    Promise.all(requests)
      .then((responses: any[]) => {
        const systemDimensionList = responses[0]?.data?.responseData ?? [];
        const billingMapDimensionList =
          responses[1]?.data?.responseData?.content ?? [];
        const tags = responses[2]?.data?.responseData?.content?.filter(
          (tagItem: any) =>
            tagItem?.cloudMapList?.some(
              (connection: any) => connection.connectionId === data?.connectorId
            )
        );
        const tagKeyValues =
          data?.cloudProvider === PROVIDER.AWS ? responses[3]?.data : [];

        dispatch(setTagMaps(tags));

        const allFields: AvailableFieldsType[] = [
          ...systemDimensionList.map((item: any) => {
            const fieldDetails = item.billingDimensionMap.find(
              (item: any) => item.provider === data?.cloudProvider
            );
            return {
              name: item.dimensionName,
              field: fieldDetails?.billingField,
              category: fieldDetails?.fieldType,
              source: FieldSource.SYSTEM,
            };
          }),
          ...billingMapDimensionList.map((item: any) => {
            const fieldDetails = item.billingDimensionMap.find(
              (item: any) => item.provider === data?.cloudProvider
            );
            return {
              name: item.dimensionName,
              field: fieldDetails?.billingField,
              category: fieldDetails?.fieldType,
              source: FieldSource.USER,
            };
          }),
        ];

        dispatch(
          setReportMetrics(
            allFields?.filter(
              (availableField: AvailableFieldsType) =>
                availableField.field &&
                availableField.category === FIELD_TYPE.NUMERIC
            )
          )
        );
        dispatch(
          setReportDimensions(
            allFields?.filter(
              (availableField: AvailableFieldsType) =>
                availableField.field &&
                [
                  FIELD_TYPE.TIME.valueOf(),
                  FIELD_TYPE.LITERAL.valueOf(),
                ].includes(availableField.category)
            )
          )
        );
        dispatch(
          setReportDatasource({
            ...datasource,
            availableFields: allFields ?? [],
          })
        );

        dispatch(
          setTagKeyValues(
            tagKeyValues?.map((item: any) => ({
              key: item.field,
              value: item.rawField,
            })) ?? []
          )
        );

        setFetchAvailableFieldsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchAvailableFieldsReqStatus);
      });
  };

  /**
   * @function fetchGroupsAvailableFields
   * @description Function to fetch the fields for groups
   * @param datasource Datasource details
   */
  const fetchGroupsAvailableFields = (
    datasource: CustomDashboardDataSourceType
  ) => {
    setFetchAvailableFieldsReqStatus(REQUEST_STATUS.PROCESSING);

    getCustomGroupAvailableFields()
      .then((res: any) => {
        if (res?.status === 200) {
          const data: AvailableCustomGroupFieldsType[] =
            res?.data?.responseData;
          dispatch(
            setReportMetrics(
              data
                ?.filter(
                  (availableField: AvailableCustomGroupFieldsType) =>
                    availableField.category === FIELD_TYPE.NUMERIC
                )
                ?.map((availableField: AvailableCustomGroupFieldsType) => {
                  return createAvailableFieldsTypeForGroup(availableField);
                })
            )
          );
          dispatch(
            setReportDimensions(
              data
                ?.filter((availableField: AvailableCustomGroupFieldsType) =>
                  [
                    FIELD_TYPE.TIME.valueOf(),
                    FIELD_TYPE.LITERAL.valueOf(),
                  ].includes(availableField.category)
                )
                ?.map((availableField: AvailableCustomGroupFieldsType) => {
                  return createAvailableFieldsTypeForGroup(availableField);
                }) ?? []
            )
          );
          dispatch(setReportGroupAvailableFields(data));
          dispatch(
            setReportDatasource({
              ...datasource,
              availableFields:
                data?.map((availableField: AvailableCustomGroupFieldsType) =>
                  createAvailableFieldsTypeForGroup(availableField)
                ) ?? [],
            })
          );
        } else {
          dispatch(setReportGroupAvailableFields([]));
        }
        setFetchAvailableFieldsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setFetchAvailableFieldsReqStatus);
        dispatch(setReportGroupAvailableFields([]));
      });
  };

  /**
   * @function validateReportData
   * @description Function to validate the report data on save
   * @returns boolean true if the validation is successful, else false
   */
  const validateReportData = () => {
    if (
      validateEmptyField(
        reportOptions.reportName,
        t('reports.reportName'),
        (value: string) => dispatch(setReportNameValidation(value))
      ) ||
      !validateAlphanumericNames(
        reportOptions.reportName,
        t('reports.reportName'),
        (value: string) => dispatch(setReportNameValidation(value))
      ) ||
      validateStringLengthLessThan(
        reportOptions.reportName,
        MAX_CHARACTER_LIMIT,
        t('reports.reportName'),
        (value: string) => dispatch(setReportNameValidation(value))
      )
    ) {
      return false;
    }

    if (isReportNameExists) {
      dispatch(setReportNameValidation(t('reports.duplicateReportNameError')));

      return false;
    }

    if (!reportDatasource.availableFields?.length) {
      message.error({
        content: t('reports.emptyDatasource'),
        key: ERROR_KEY,
        duration: ANT_MESSAGE_LONG_DURATION,
      });
      return false;
    }

    if (reportOptions.dimension.length + reportOptions.metric.length === 0) {
      message.error({
        content: t('reports.emptySelectionError'),
        key: ERROR_KEY,
        duration: ANT_MESSAGE_LONG_DURATION,
      });
      return false;
    }

    return true;
  };

  const onClickPreviewReport = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('view', ReportsViewType.PREVIEW);
    if (reportOptions.reportName) {
      queryParams.set('report', reportOptions.reportName);
    }
    navigate(location.pathname + '?' + queryParams.toString());
  };

  const onClickEditReport = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('view', ReportsViewType.EDIT);
    navigate(location.pathname + '?' + queryParams.toString());
  };

  /**
   * @function onSaveReportError
   * @description Callback function for error on saving the report
   */
  const onSaveReportError = (errorMessage: string) => {
    setSaveReportReqStatus(REQUEST_STATUS.ERROR);
    setReportsPageComponent(CreateReportsPageComponent.ERROR_COMPONENT);
    dispatch(
      setErrorMessage(
        errorMessage ??
          (existingReport
            ? t('reports.errorUpdatingReport')
            : t('reports.errorSavingReport'))
      )
    );
  };

  /**
   * @function onClickSaveReport
   * @description Function to validate and save the report
   */
  const onClickSaveReport = () => {
    if (!validateReportData()) {
      return;
    }

    setSaveReportReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      reportId: existingReport?.reportId,
      connectorId: reportConnection?.connectorId,
      groupName: reportGroup?.name,
      reportName: reportOptions.reportName,
      dataSource: {
        useCustomQuery: reportDatasource.useCustomQuery,
        customQueryId: reportDatasource.customQueryId,
        dataset: reportDatasource.dataSet,
        dashBoardType: reportDatasource.dashBoardType,
        availableFields: reportDatasource.availableFields,
      },
      customQueryDto: {
        queryString: reportDatasource.customQuery,
      },
      dateRanges:
        ReportsDateRangesToPresetValuesMap.find(
          (item) => item.presetValue === reportOptions.dateRanges
        )?.dateRange ?? DateRanges.CUSTOM,
      startTime: reportOptions.startDate,
      endTime: reportOptions.endDate,
      dimension: reportOptions.dimension,
      metric: reportOptions.metric,
      sort: reportOptions.sort,
      chartType: reportOptions.chartType,
      chartDimension: reportOptions.chartDimension,
      chartMetric: reportOptions.chartMetric,
    };

    (existingReport ? updateReport(requestBody) : createReport(requestBody))
      .then((res: any) => {
        if (res?.status === 200) {
          setSaveReportReqStatus(REQUEST_STATUS.SUCCESS);
          setReportsPageComponent(CreateReportsPageComponent.SUCCESS_COMPONENT);
          return;
        }
        onSaveReportError(res?.data?.message);
      })
      .catch((e) => {
        onApiCallError(e, false, setSaveReportReqStatus);
        onSaveReportError(e?.response?.data?.message);
      });
  };

  return (
    <div className="create-report flex flex-column flex-fit">
      <header className="new-page-header create-report-header flex flex-align-items-center flex-space-between">
        <span className="modal-heading">{getReportPageTitle()}</span>
        {reportsPageComponent === CreateReportsPageComponent.CANVAS &&
          fetchReportByNameReqStatus !== REQUEST_STATUS.PROCESSING &&
          (!existingReport ||
            existingReport?.reportPermission === ReportPermissions.WRITE) && (
            <div className="flex flex-align-items-center flex-gap-8">
              {reportView === ReportsViewType.EDIT ? (
                <>
                  <Button
                    title={t('reports.discard')}
                    type={BUTTON_TYPE.LINK}
                    onClick={() =>
                      navigate(
                        NAVIGATION_MENU_PATH.REPORTS +
                          '?reportType=' +
                          getReportTypeByLocation().id.toLowerCase()
                      )
                    }
                  />
                  <Button
                    title={t('reports.preview')}
                    type={BUTTON_TYPE.NEUTRAL}
                    onClick={onClickPreviewReport}
                  />
                  <Button
                    title={
                      existingReport ? t('reports.update') : t('reports.save')
                    }
                    onClick={onClickSaveReport}
                    loading={saveReportReqStatus === REQUEST_STATUS.PROCESSING}
                  />
                </>
              ) : (
                <Button
                  title={t('reports.editReport')}
                  onClick={onClickEditReport}
                />
              )}
            </div>
          )}
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <DashboardComponent
          component={
            <ReportComponent
              reportsPageComponent={reportsPageComponent}
              setReportsPageComponent={setReportsPageComponent}
            />
          }
          requestStatus={evaluateRequestArray([
            fetchReportByNameReqStatus,
            fetchConnectionOrGroupDataReqStatus,
            fetchAvailableFieldsReqStatus,
          ])}
        />
      </div>
    </div>
  );
};

export default CreateReportPage;
