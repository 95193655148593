export const DummyData = [
  {
    utilization: 0.43217347935703354,
    time: '20231',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 6.2792006654213575,
    time: '20231',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 9.2866283129638347,
    time: '20231',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 2.6307900694571549,
    time: '20231',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 3.0513522118821688,
    time: '20231',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 0.53628783066408858,
    time: '20231',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cert-manager',
  },
  {
    utilization: 11.208131577467167,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 0.0042802108678021093,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'default',
  },
  {
    utilization: 1.3374291098508231,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 10.119066048304763,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'microservices-apis',
  },
  {
    utilization: 0.43443183082780673,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'vault',
  },
  {
    utilization: 6.1852504201012239,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 0.10842042474432385,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 0.731283430699193,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 0.00034943559096945544,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev-helm-test-2',
  },
  {
    utilization: 17.502216614813456,
    time: '202310',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'jenkins',
  },
  {
    utilization: 2.521201492757374,
    time: '20232',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 0.40329339583786566,
    time: '20232',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 8.6670545358592612,
    time: '20232',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 3.1589988410599528,
    time: '20232',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 5.224685328467312,
    time: '20232',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 0.48497656894558483,
    time: '20232',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cert-manager',
  },
  {
    utilization: 3.6900396466445149,
    time: '20233',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 10.219849141911718,
    time: '20233',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'gprofiler',
  },
  {
    utilization: 8.5280209813906911,
    time: '20233',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 0.48000385534115719,
    time: '20233',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 10.930345486682645,
    time: '20233',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 2.7890929392325736,
    time: '20233',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 0.55696458029387386,
    time: '20233',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cert-manager',
  },
  {
    utilization: 0.047434324904572445,
    time: '20233',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'test-namespace',
  },
  {
    utilization: 4.3131962200959162,
    time: '20234',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 2.3004354164987868,
    time: '20234',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 7.8374058481116915,
    time: '20234',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 9.3076603145504784,
    time: '20234',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 15.1178575751319,
    time: '20234',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'gprofiler',
  },
  {
    utilization: 0.3916011419644434,
    time: '20234',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 0.0987656086506086,
    time: '20234',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'test-namespace',
  },
  {
    utilization: 0.4120382425017205,
    time: '20234',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cert-manager',
  },
  {
    utilization: 0.036838477162710381,
    time: '20234',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cb360-gmaestro',
  },
  {
    utilization: 0.019118967153120864,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'gmp-system',
  },
  {
    utilization: 18.360310162424806,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 0.020124868036035578,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'gprofiler',
  },
  {
    utilization: 0.71302959493784,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'monitoring',
  },
  {
    utilization: 3.4366685172100646,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 3.6714959948467194,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 0.36770003010462449,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'default',
  },
  {
    utilization: 48.021234894219589,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 1.0925456673359444,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kubecost',
  },
  {
    utilization: 0.028215655342873344,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'test-namespace',
  },
  {
    utilization: 0.36402578957167925,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 0.37600465279949141,
    time: '20235',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cert-manager',
  },
  {
    utilization: 14.434140548279252,
    time: '20236',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 2.2630724652286682,
    time: '20236',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kubecost',
  },
  {
    utilization: 0.4414114194754421,
    time: '20236',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cert-manager',
  },
  {
    utilization: 2.8528472091438317,
    time: '20236',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 28.862865573994636,
    time: '20236',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 3.0303853844619337,
    time: '20236',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 0.37433095199965222,
    time: '20236',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 0.037473981372774842,
    time: '20237',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cert-manager',
  },
  {
    utilization: 14.672123018879415,
    time: '20237',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 39.495004441755171,
    time: '20237',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 0.37119495524899565,
    time: '20237',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 2.3348932697719356,
    time: '20237',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 27.410833199402333,
    time: '20237',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 0.19212760969820797,
    time: '20237',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kubecost',
  },
  {
    utilization: 36.70155024008691,
    time: '20238',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 2.6282819001579796,
    time: '20238',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 6.5539529706680852,
    time: '20238',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cicd-run',
  },
  {
    utilization: 6.027827024895652,
    time: '20238',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 0.38546717786448537,
    time: '20238',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 161.20061969629239,
    time: '20238',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 0.02750617062800189,
    time: '20238',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'jenkins-duplicate',
  },
  {
    utilization: 0.465068153807231,
    time: '20238',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'jenkins',
  },
  {
    utilization: 62.3498704090079,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'kube-system',
  },
  {
    utilization: 0.38667885525103518,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'ingress',
  },
  {
    utilization: 10.580085462237395,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'cicd-run',
  },
  {
    utilization: 39.62929827964971,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'gprofiler-granulate',
  },
  {
    utilization: 63.869610631310572,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'dev',
  },
  {
    utilization: 2.363910019313983,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'qa',
  },
  {
    utilization: 8.949127540155061,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'jenkins',
  },
  {
    utilization: 2.5205970906701602,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'argocd',
  },
  {
    utilization: 50.128820382236931,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'microservices-apis',
  },
  {
    utilization: 0.15544925935322795,
    time: '20239',
    cluster: 'cb360-dev-qa-argocd',
    namespace: 'vault',
  },
];
