import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';
import { ForecastRequestBody } from './types';

/**
 * @function getForecastedCost
 * @description Function to get the forecast cost.
 * @param body request body of the post request
 * @return axios response from POST request
 */
export const getForecastedCost = async (body: ForecastRequestBody) => {
  return ServiceCalls.post(APIS.GET_FORECASTED_COST, body);
};

/**
 * @function deleteCustomView
 * @description Function to delete custom view
 * @param dashboardId : Id of selected dashboard
 * @param viewId : Id of selected view
 * @return axios response from DELETE request
 */
export const deleteCustomView = async (
  dashboardId: string | undefined,
  viewId: string
) => {
  const customViewDeleteApi = `${
    APIS.DELETE_CUSTOM_VIEW + dashboardId
  }/${viewId}`;
  return ServiceCalls.delete(customViewDeleteApi);
};
