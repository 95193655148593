import { useSelector } from 'react-redux';
import { selectContainerInsights } from 'redux/containerInsightsSlice';
import { getProviderLogo } from 'utils/providerDetails';

import './index.scss';

const ConatinerDashboardTitle = () => {
  const { selectedConnection } = useSelector(selectContainerInsights);

  return (
    <div className="container-cost-dashboard-title flex flex-align-items-center flex-gap-16">
      <img
        width={35}
        height={35}
        src={getProviderLogo(selectedConnection?.provider ?? '')}
        alt={`${selectedConnection?.provider} Logo`}
      />
      <div className="flex flex-column">
        <div className="title-container flex flex-row">
          <div className="edit flex flex-center">
            <div className="modal-heading">
              {selectedConnection?.displayName}
            </div>
          </div>
        </div>
        <div className="data-source-type flex font-caption-bold">
          <div>{selectedConnection?.dataSourceType}</div>
        </div>
      </div>
    </div>
  );
};

export default ConatinerDashboardTitle;
