import { TICKET_PROVIDER } from 'constants/cloudProviders';

export enum TicketIntegrationComponents {
  TICKET_PROVIDER_LIST = 'TICKET_PROVIDER_LIST',
  TICKET_INTEGRATION_FORM = 'TICKET_INTEGRATION_FORM',
  SUCCESS_PAGE = 'SUCCESS_PAGE',
  ERROR_PAGE = 'ERROR_PAGE',
}

export const TICKET_PROVIDER_LIST_DATA = [
  {
    provider: TICKET_PROVIDER.JIRA,
    header: 'jiraHeader',
    subHeader: 'jiraSubHeader',
  },
  {
    provider: TICKET_PROVIDER.SERVICE_NOW,
    header: 'serviceNowHeader',
    subHeader: 'serviceNowSubHeader',
  },
];
