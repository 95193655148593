import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mean } from 'lodash';

import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import GraphHeader from 'components/GraphHeader';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import ColumnChart from 'components/ColumnChart';
import AccessibleDiv from 'components/AccessibleDiv';
import { getChartExcelExportData } from 'utils/exportToExcel';

import { ColouredMonthlyCostByCheckId } from '../../types';
import { SavingsPerCategoryColumns } from '../../../CostRecommendationSummaryDashboard/constants';

type SavingsPerCategoryProps = {
  requestStatus: string[];
  savingsPerCategoryData: ColouredMonthlyCostByCheckId[];
  savingsPerCategoryLabels: string[];
  pdfView: boolean;
  graphHeading: string;
  graphName: string;
  selectedGraphCategory: string;
  setSelectedGraphCategory: (value: string) => void;
};

export const SavingsPerCategory = ({
  requestStatus,
  savingsPerCategoryData,
  savingsPerCategoryLabels,
  pdfView,
  graphHeading,
  graphName,
  selectedGraphCategory,
  setSelectedGraphCategory,
}: SavingsPerCategoryProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const getTableDataSource = () => {
    let tableData: any[] = [];
    savingsPerCategoryData.forEach((value) =>
      tableData.push({
        category: value.check,
        color: value.color,
        cost: mean(value.cost),
      })
    );
    return tableData;
  };

  const getDataSource = () => {
    let data: any[] = [];
    savingsPerCategoryLabels?.forEach((label, index) => {
      savingsPerCategoryData.forEach((value) =>
        data.push({
          month: label,
          category: value.check,
          cost: value.cost[index] || 0,
        })
      );
    });
    return data;
  };

  const getComponent = () => (
    <>
      <div
        className={`category-graph flex-fit full-height full-width ${
          selectedGraphCategory === graphName && 'width-70'
        }`}
      >
        <ColumnChart
          data={getDataSource()}
          xField={'month'}
          yField={'cost'}
          isStack
          groupingField={'category'}
          xTitle={t('awsRecommendationSummary.month')}
          yTitle={t('awsRecommendationSummary.cost', {
            currencySymbol: currencySymbol,
          })}
          columnColorOverride={savingsPerCategoryData.map(
            (value) => value.color
          )}
          showAllLegend={pdfView}
          prefixSymbol={currencySymbol}
          disableAnimation={pdfView}
        />
      </div>
      {selectedGraphCategory === graphName && !pdfView && (
        <div className="width-30">
          <Table
            pagination={false}
            dataSource={getTableDataSource().map((data, index) => ({
              key: index,
              ...data,
            }))}
            columns={SavingsPerCategoryColumns}
            scroll={{ y: 190 }}
            designVersion2
          />
        </div>
      )}
    </>
  );

  return (
    <AccessibleDiv
      className={`savings-per-category graph-card flex flex-column flex-gap-4 flex-fit ${
        selectedGraphCategory === graphName && 'selected'
      }`}
      id="graph-container"
      onClick={() => setSelectedGraphCategory(graphName)}
    >
      <GraphHeader
        heading={graphHeading}
        graphName={graphName}
        isTableView={false}
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        designVersion2
        excelData={getChartExcelExportData(
          graphHeading,
          getTableDataSource(),
          SavingsPerCategoryColumns,
          {
            connectionName: selectedDashboard!.connectorName,
          }
        )}
      />
      <div className="flex flex-gap-4 flex-align-items-center">
        <span className="savings-label font-caption-bold">
          {t('awsRecommendationSummary.potentialSavings')}
        </span>
        <span className="savings-value font-caption-bold">
          {currencySymbol +
            numberCommaSeparator(
              mean(savingsPerCategoryData.map((value) => mean(value.cost)))
            )}
        </span>
      </div>
      <div className="graph flex flex-align-items-center">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </AccessibleDiv>
  );
};
