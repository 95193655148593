import { Select } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import { REQUEST_STATUS } from 'constants/requestBody';
import { selectPurchase, setSelectedGcpProjectId } from 'redux/purchaseSlice';
import { onApiCallError } from 'utils/handleErrors';
import {
  isNumber,
  validateEmptyField,
  validateRange,
  validateStringLengthLessThan,
} from 'utils/validations';

import { DISABLED_COMMITTEMENT_TYPES } from './constants';
import { PurchaseComputeEngineType } from '../../types';
import {
  GCP_COMPUTE_ENGINE_DEFAULT_VALUES,
  GCP_CUD_NAME_LENGTH,
  GCP_TERMS_LIST,
} from '../../constants';
import {
  fetchGcpComputeEngineCommitmentTypes,
  fetchGcpComputeEngineCostEstimates,
  fetchGcpRegions,
  purchaseGcpComputeEngine,
} from '../../services';
import './index.scss';

type BuyComputeEngineFormModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
};

const BuyComputeEngineFormModal = ({
  show,
  setShow,
}: BuyComputeEngineFormModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedConnection, selectedGcpProjectId } =
    useSelector(selectPurchase);

  const [computeEngineFormData, setComputeEngineFormData] =
    useState<PurchaseComputeEngineType>(GCP_COMPUTE_ENGINE_DEFAULT_VALUES);

  // Dropdown options states
  const [regions, setRegions] = useState<string[]>([]);
  const [commitmentTypes, setCommitmentTypes] = useState<
    { commitmentName: string; machineFamily: string }[]
  >([]);

  // Request status states
  const [fetchRegionReqStatus, setFetchRegionReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [
    fetchCommitmentTypesRequestStatus,
    setFetchCommitmentTypesRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [costEstimationRequestStatus, setCostEstimationRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [
    purchaseComputeEngineRequestStatus,
    setPurchaseComputeEngineRequestStatus,
  ] = useState('');

  // Validation states
  const [nameValidation, setNameValidation] = useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [commitmentTypeValidation, setCommitmentTypeValidation] = useState('');
  const [coresValidation, setCoresValidation] = useState('');
  const [memoryValidation, setMemoryValidation] = useState('');
  const [costEstimationValidation, setCostEstimationValidation] = useState('');

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (selectedConnection && selectedGcpProjectId) fetchRegions(source);

    return () => {
      source.cancel();
    };
  }, [selectedConnection, selectedGcpProjectId]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (
      selectedConnection &&
      selectedGcpProjectId &&
      computeEngineFormData.region
    )
      fetchCommitmentTypes(source);

    return () => {
      source.cancel();
    };
  }, [selectedConnection, selectedGcpProjectId, computeEngineFormData.region]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    if (isDataLoaded) {
      setComputeEngineFormData({
        ...computeEngineFormData,
        totalMonthlyEst: 0,
        originalCost: 0,
      });
      setCostEstimationValidation('');
    } else {
      setIsDataLoaded(true);
    }

    if (validateAllFields(false)) {
      fetchCostEstimations(source);
    }

    return () => {
      source.cancel();
    };
  }, [
    computeEngineFormData.name,
    computeEngineFormData.plan,
    computeEngineFormData.machineFamily,
    computeEngineFormData.region,
    computeEngineFormData.cores,
    computeEngineFormData.memory,
  ]);

  /**
   * @function fetchRegions
   * @description Function to fetch the regions based on connectorid and projectid
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchRegions = (cancelTokenSource: CancelTokenSource) => {
    setFetchRegionReqStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedConnection?.connectorId,
      project: selectedGcpProjectId,
    };
    fetchGcpRegions(params, cancelTokenSource.token)
      .then((res: any) => {
        setRegions(res?.data?.responseData || []);
        setFetchRegionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, true, setFetchRegionReqStatus);
      });
  };

  /**
   * @function fetchCommitmentTypes
   * @description Function to fetch the GCP commitment types
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchCommitmentTypes = (cancelTokenSource: CancelTokenSource) => {
    setFetchCommitmentTypesRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedConnection?.connectorId,
      project: selectedGcpProjectId,
      region: computeEngineFormData?.region,
    };
    fetchGcpComputeEngineCommitmentTypes(params, cancelTokenSource.token)
      .then((res: any) => {
        const data = res?.data?.responseData || [];
        setCommitmentTypes(
          Object.entries(data).map((entry: any[]) => {
            return {
              commitmentName: entry[0],
              machineFamily: entry[1],
            };
          })
        );
        setFetchCommitmentTypesRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, true, setFetchCommitmentTypesRequestStatus);
      });
  };

  /**
   * @function fetchCostEstimations
   * @description Function to fetch the cost estimates for the compute engine
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchCostEstimations = (cancelTokenSource: CancelTokenSource) => {
    setCostEstimationRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedConnection?.connectorId,
    };
    const body = {
      machineFamily: computeEngineFormData?.machineFamily,
      region: computeEngineFormData?.region,
      virtualCpuCount: computeEngineFormData?.cores,
      memoryInGb: computeEngineFormData?.memory,
      plan: computeEngineFormData?.plan,
    };
    fetchGcpComputeEngineCostEstimates(params, body, cancelTokenSource.token)
      .then((res: any) => {
        const data = res?.data?.responseData;
        if (res?.status === 200 && data) {
          setComputeEngineFormData({
            ...computeEngineFormData,
            totalMonthlyEst: data.monthlyCostAfterDiscount,
            originalCost: data.monthlyCostWithoutDiscount,
            committedUseDiscount: data.discount,
          });
          setCostEstimationRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setCostEstimationValidation(
          t('purchasePage.committedUseDiscountsLabels.costEstimateNullError')
        );
        setCostEstimationRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e: any) => {
        onApiCallError(e, true, setCostEstimationRequestStatus);
      });
  };

  /**
   * @function validateName
   * @description Function to validate the name field. It should be alphanumeric and can contain hyphen.
   * Name should be less than 63 characters
   * @param value to be validated
   * @param addErrorMessage to add error message or not
   * @returns true if validation is successful else false
   */
  const validateName = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (!/^[a-z0-9-]+$/.test(`${value}`)) {
      addErrorMessage &&
        setNameValidation(
          t(`purchasePage.committedUseDiscountsLabels.nameValidation`)
        );
      return false;
    }
    return !validateStringLengthLessThan(
      `${value}`,
      GCP_CUD_NAME_LENGTH,
      t(`purchasePage.committedUseDiscountsLabels.name`),
      addErrorMessage ? setNameValidation : undefined
    );
  };

  /**
   * @function validateRegion
   * @description Function to validate the region field
   * @param value to be validated
   * @param addErrorMessage to add error message or not
   * @returns true if validation is successful else false
   */
  const validateRegion = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.committedUseDiscountsLabels.region`),
      setRegionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateCommitmentType
   * @description Function to validate the Commitment Type field
   * @param value to be validated
   * @param addErrorMessage to add error message or not
   * @returns true if validation is successful else false
   */
  const validateCommitmentType = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.committedUseDiscountsLabels.commitmentType`),
      setCommitmentTypeValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateCores
   * @description Function to validate the cores field
   * @param value to be validated
   * @param addErrorMessage to add error message or not
   * @returns true if validation is successful else false
   */
  const validateCores = (value: number, addErrorMessage: boolean = true) => {
    return (
      !validateEmptyField(
        value,
        t(`purchasePage.committedUseDiscountsLabels.cores`),
        setCoresValidation,
        addErrorMessage
      ) &&
      isNumber(
        `${value}`,
        false,
        t(`purchasePage.committedUseDiscountsLabels.cores`),
        addErrorMessage ? setCoresValidation : undefined
      ) &&
      validateRange(
        value,
        0,
        undefined,
        t(`purchasePage.committedUseDiscountsLabels.cores`),
        addErrorMessage ? setCoresValidation : undefined
      )
    );
  };

  /**
   * @function validateMemoryMultipleOfPoint25
   * @description Function to validate the memory field. It should be multiple of 0.25
   * @param value to be validated
   * @param addErrorMessage to add error message or not
   * @returns true if validation is successful else false
   */
  const validateMemoryMultipleOfPoint25 = (
    value: number,
    addErrorMessage: boolean
  ) => {
    if (value % 0.25 !== 0) {
      addErrorMessage &&
        setMemoryValidation(
          t(`purchasePage.committedUseDiscountsLabels.memoryValidation`)
        );
      return false;
    }
    return true;
  };

  /**
   * @function validateMemory
   * @description Function to validate the memory field
   * @param value to be validated
   * @param addErrorMessage to add error message or not
   * @returns true if validation is successful else false
   */
  const validateMemory = (value: number, addErrorMessage: boolean = true) => {
    return (
      !validateEmptyField(
        value,
        t(`purchasePage.committedUseDiscountsLabels.memory`),
        setMemoryValidation,
        addErrorMessage
      ) &&
      isNumber(
        `${value}`,
        true,
        t(`purchasePage.committedUseDiscountsLabels.memory`),
        addErrorMessage ? setMemoryValidation : undefined
      ) &&
      validateMemoryMultipleOfPoint25(value, addErrorMessage)
    );
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAllFields = (addErrorMessage = true) => {
    let validation = true;

    if (!validateName(computeEngineFormData?.name, addErrorMessage))
      validation = false;

    if (!validateRegion(computeEngineFormData?.region, addErrorMessage))
      validation = false;

    if (
      !validateCommitmentType(
        computeEngineFormData?.machineFamily,
        addErrorMessage
      )
    )
      validation = false;

    if (!validateCores(computeEngineFormData?.cores, addErrorMessage))
      validation = false;

    if (!validateMemory(computeEngineFormData?.memory, addErrorMessage))
      validation = false;

    return validation;
  };

  /**
   * @function onClickPlaceOrder
   * @description Callback function for place order button
   */
  const _onClickPlaceOrder = () => {
    setPurchaseComputeEngineRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: selectedConnection?.connectorId,
      project: selectedGcpProjectId,
      region: computeEngineFormData?.region,
    };
    const body = {
      commitmentName: computeEngineFormData?.name,
      plan: computeEngineFormData?.plan,
      commitmentType: commitmentTypes.find(
        (value) => value.machineFamily === computeEngineFormData?.machineFamily
      )?.commitmentName,
      vcpu: computeEngineFormData?.cores,
      memoryInGB: computeEngineFormData?.memory,
    };
    purchaseGcpComputeEngine(params, body)
      .then((res: any) => {
        if (res?.status === 200) {
          setPurchaseComputeEngineRequestStatus(REQUEST_STATUS.SUCCESS);
          dispatch(setSelectedGcpProjectId(selectedGcpProjectId));
          setShow(false);
          return;
        }
        setPurchaseComputeEngineRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e: any) => {
        onApiCallError(e, true, setPurchaseComputeEngineRequestStatus);
      });
  };

  return (
    <DrawerComponent
      className="purchase-compute-engine-form"
      width={481}
      open={show}
      title={
        <div className="flex flex-column">
          {t('purchasePage.committedUseDiscountsLabels.purchaseComputeEngine')}
          <span className="project-id font-subHeader-small">
            {selectedGcpProjectId}
          </span>
        </div>
      }
      onClose={() => setShow(false)}
      footer={
        <PurchaseFooter
          totalHeading={t(
            'purchasePage.committedUseDiscountsLabels.totalMonthlyEst'
          )}
          totalValue={computeEngineFormData.totalMonthlyEst}
          costSummary={[
            {
              key: t('purchasePage.committedUseDiscountsLabels.vCpuMemory', {
                cpu: computeEngineFormData?.cores || 0,
                memory: computeEngineFormData?.memory || 0,
              }),
              value: computeEngineFormData.originalCost,
            },
            {
              key: t(
                'purchasePage.committedUseDiscountsLabels.committedUseDiscount'
              ),
              value: computeEngineFormData?.committedUseDiscount,
            },
          ]}
          okTitle={t('purchasePage.committedUseDiscountsLabels.placeOrder')}
          onClickCancel={() => setShow(false)}
          onClickOk={() => {
            if (!validateAllFields()) {
              return;
            }
            // Call API to place order for the compute engine
          }}
          loading={costEstimationRequestStatus === REQUEST_STATUS.PROCESSING}
          loadingOk={
            purchaseComputeEngineRequestStatus === REQUEST_STATUS.PROCESSING
          }
          validationMessage={costEstimationValidation}
        />
      }
    >
      <section className="flex flex-column flex-gap-24 new-styled-scroll">
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.committedUseDiscountsLabels.name')}
            required={true}
          />
          <Input
            value={computeEngineFormData?.name}
            placeholder={t(
              'purchasePage.committedUseDiscountsLabels.selectName'
            )}
            onChange={(e: any) => {
              setComputeEngineFormData({
                ...computeEngineFormData,
                name: e.target.value,
              });
              validateName(e.target.value);
            }}
            onBlur={() => {
              validateName(computeEngineFormData?.name);
            }}
          />
          <span
            style={{
              display: nameValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {nameValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.committedUseDiscountsLabels.region')}
            required={true}
          />
          <SelectDropdown
            value={computeEngineFormData?.region}
            options={regions.map((item) => ({ value: item, label: item }))}
            placeholder={t(
              'purchasePage.committedUseDiscountsLabels.selectRegion'
            )}
            loading={fetchRegionReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: any) => {
              setComputeEngineFormData({
                ...computeEngineFormData,
                region: value,
              });
              validateRegion(value);
            }}
            onBlur={() => {
              validateRegion(computeEngineFormData?.region);
            }}
            designVersion2
          />
          <span
            style={{
              display: regionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {regionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.committedUseDiscountsLabels.commitmentType')}
            required={true}
          />
          <SelectDropdown
            value={computeEngineFormData?.machineFamily}
            menu={commitmentTypes?.map((item) => (
              <Select.Option
                key={item.machineFamily}
                value={item.machineFamily}
                disabled={DISABLED_COMMITTEMENT_TYPES.includes(
                  item.commitmentName
                )}
              >
                {item.commitmentName}
              </Select.Option>
            ))}
            placeholder={t(
              'purchasePage.committedUseDiscountsLabels.selectCommitmentType'
            )}
            loading={
              fetchCommitmentTypesRequestStatus === REQUEST_STATUS.PROCESSING
            }
            onSelect={(value: any) => {
              setComputeEngineFormData({
                ...computeEngineFormData,
                machineFamily: value,
              });
              validateCommitmentType(value);
            }}
            onBlur={() => {
              validateCommitmentType(computeEngineFormData?.machineFamily);
            }}
            designVersion2
          />
          <span
            style={{
              display: commitmentTypeValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {commitmentTypeValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.committedUseDiscountsLabels.term')}
            required={true}
          />
          <RadioGroup
            options={GCP_TERMS_LIST}
            onChange={(e: any) => {
              setComputeEngineFormData({
                ...computeEngineFormData,
                plan: e.target.value,
              });
            }}
            value={computeEngineFormData.plan}
            optionType="button"
            rootClassName="no-custom-style"
            style={{ height: 28 }}
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.committedUseDiscountsLabels.cores')}
            required={true}
          />
          <Input
            value={computeEngineFormData?.cores}
            type="number"
            placeholder={t(
              'purchasePage.committedUseDiscountsLabels.selectCores'
            )}
            onChange={(value: number) => {
              setComputeEngineFormData({
                ...computeEngineFormData,
                cores: value,
              });
              validateCores(value);
            }}
            onBlur={() => {
              validateCores(computeEngineFormData?.cores);
            }}
          />
          <span
            style={{
              display: coresValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {coresValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.committedUseDiscountsLabels.memory')}
            required={true}
          />
          <Input
            value={computeEngineFormData?.memory}
            type="number"
            placeholder={t(
              'purchasePage.committedUseDiscountsLabels.selectMemory'
            )}
            onChange={(value: number) => {
              setComputeEngineFormData({
                ...computeEngineFormData,
                memory: value,
              });
              validateMemory(value);
            }}
            onBlur={() => {
              validateMemory(computeEngineFormData?.memory);
            }}
          />
          <span
            style={{
              display: memoryValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {memoryValidation}
          </span>
        </div>
      </section>
    </DrawerComponent>
  );
};

export default BuyComputeEngineFormModal;
