import i18n from 'i18n';
import { TeamsType } from './types';

export enum TeamsComponents {
  TABLE = 'TABLE',
  SUCCESS_COMPONENT = 'SUCCESS_COMPONENT',
  ERROR_COMPONENT = 'ERROR_COMPONENT',
}

export enum TeamsQuickActions {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum TeamStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'INACTIVE',
}

export const TEAMS_QUICKACTIONS = [
  {
    id: TeamsQuickActions.EDIT,
    title: i18n.t('teamsLabels.edit'),
  },
  {
    id: TeamsQuickActions.ACTIVATE,
    title: i18n.t('teamsLabels.activate'),
  },
  {
    id: TeamsQuickActions.DEACTIVATE,
    title: i18n.t('teamsLabels.deactivate'),
  },
  {
    id: TeamsQuickActions.DELETE,
    title: i18n.t('teamsLabels.delete'),
  },
];

export const DefaultTeamData: TeamsType = {
  teamName: '',
  userDetailDtoList: [],
};
