import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import ColumnChart from 'components/ColumnChart';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS } from 'constants/icons';
import { MonthlyCostSavingsType } from 'pages/CostOptimizationInsightsPage/types';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { evaluateRequestArray } from 'utils/handleErrors';
import { SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP } from 'pages/CostOptimizationInsightsPage/constants';

type CostSavingsTrendProps = {
  graphName: string;
  data: MonthlyCostSavingsType[];
  pdfView: boolean;
  requestStatus: string[];
  selectedSavingsType: string | undefined;
  selectedRecommendationType: string | undefined;
  setSelectedRecommendationType: (val: string | undefined) => void;
};

const CostSavingsTrend = ({
  graphName,
  data,
  pdfView,
  requestStatus,
  selectedSavingsType,
  selectedRecommendationType,
  setSelectedRecommendationType,
}: CostSavingsTrendProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  /**
   * @function getComponent
   * @description Function to return the graph or table component
   * @returns JSX element containing graph or the table
   */
  const getComponent = () => (
    <ColumnChart
      data={data.map((item) => ({
        ...item,
        savingType: SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
          (mapItem) => {
            return mapItem.type === selectedSavingsType;
          }
        )?.label,
      }))}
      xField="month"
      yField="totalCost"
      groupingField="savingType"
      disableAnimation={pdfView}
      xTitle={t('costTrend.month')}
      yTitle={t('costTrend.costInCurrency', { currencySymbol: currencySymbol })}
      prefixSymbol={currencySymbol}
    />
  );

  const getHeadingComponent = () => {
    return selectedRecommendationType ? (
      <div className="graph-heading flex flex-column">
        <AccessibleDiv
          className="flex flex-align-items-center cursor-pointer"
          onClick={() => setSelectedRecommendationType(undefined)}
        >
          <Icon
            iconName={ICONS.ARROW_LEFT_S_LINE}
            className="previous-icon flex flex-end"
          />
          <span>{selectedRecommendationType}</span>
        </AccessibleDiv>
      </div>
    ) : undefined;
  };

  return (
    <div
      className="graph-area cost-savings-trend flex flex-column flex-gap-16"
      id="graph-container"
    >
      <GraphHeader
        heading={selectedRecommendationType ?? ''}
        headingComponent={getHeadingComponent()}
        graphName={graphName}
        isDownloadable={!pdfView}
        isTableView={false}
        showExpandIcon={!pdfView}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default CostSavingsTrend;
