import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  cloudConnection,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { getOciRegions } from 'pages/ConnectingCSPPage/services';
import { validateInputLength } from 'pages/ConnectingCSPPage/utils';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import UploadFile from 'components/UploadFile';
import { uploadConnectionFile } from 'utils/services';
import { validateByRegex, validateEmptyField } from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { FileInfoType } from '../../types';
import RadioGroup from 'components/RadioGroup';
import { PASSPHRASE_RADIO_OPTIONS } from './constants';

type OCIBudgetAccessDetailsProps = {
  removeFile: (
    fileId: string,
    setFileInfo?: (val: FileInfoType) => void,
    setValidationError?: (val: string) => void,
    fieldName?: string
  ) => void;
  privateKeyFileInfo: FileInfoType;
  setPrivateKeyFileInfo: (fileInfo: FileInfoType) => void;
};

const OCIBudgetAccessDetails = ({
  removeFile,
  privateKeyFileInfo,
  setPrivateKeyFileInfo,
}: OCIBudgetAccessDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection } = useSelector(cloudConnection);

  const [
    privateKeyFileRequiredValidation,
    setPrivateKeyFileRequiredValidation,
  ] = useState('');
  const [regionOptions, setRegionOptions] = useState<string[]>([]);
  const [passFieldValidation, setPassFieldValidation] = useState('');
  const [tenancyValidation, setTenancyValidation] = useState('');
  const [userIdValidation, setUserIdValidation] = useState('');

  const [regionOptionsRequestStatus, setRegionOptionsRequestStatus] =
    useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [fingerprintValidation, setFingerprintValidation] = useState('');

  useEffect(() => {
    populateOciRegionOptions();
  }, []);

  /**
   * @function populateOciRegionOptions
   * @description Function to fetch all the regions for OCI connection
   */
  const populateOciRegionOptions = () => {
    setRegionOptionsRequestStatus(REQUEST_STATUS.PROCESSING);
    getOciRegions()
      .then((res: any) => {
        setRegionOptions(res?.data?.responseData || []);
        setRegionOptionsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setRegionOptionsRequestStatus));
  };

  const RegionDropdown = (
    <>
      {regionOptions?.map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </>
  );

  return (
    <div className="flex flex-column flex-gap-24">
      {!isEditConnection && (
        <div className="form-item flex flex-column">
          <FormLabel title={t(`ociConnectionCSPForm.privateKeyPem`)} />
          <UploadFile
            accept={'.pem'}
            isFileUploaded={!!privateKeyFileInfo.fileId}
            filename={privateKeyFileInfo.fileName}
            buttonTitle={t(`ociConnectionCSPForm.upload`)}
            onSelectFile={async (file, onSuccess, onError) => {
              if (
                !validateByRegex(
                  file.name.slice(0, file.name.lastIndexOf('.')),
                  /^[a-zA-Z0-9][a-zA-Z0-9-_]*$/,
                  undefined,
                  setPrivateKeyFileRequiredValidation,
                  t('ociFileFormatError')
                )
              ) {
                onError();
                return;
              }
              try {
                const resData: any = await uploadConnectionFile(file);
                onSuccess(resData);
                setPrivateKeyFileInfo({
                  fileId: resData.data.responseData,
                  fileName: file.name,
                });
                dispatch(
                  setCurrentConnection({
                    ...currentConnection,
                    ociBudgetFileId: resData.data.responseData,
                  })
                );
                setPrivateKeyFileRequiredValidation('');
              } catch (error) {
                onError({ error });
              }
            }}
            onRemoveFile={() => removeFile(privateKeyFileInfo.fileId)}
          />
          <span
            style={{
              display: privateKeyFileRequiredValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {privateKeyFileRequiredValidation}
          </span>
        </div>
      )}
      <div className="form-item flex flex-column">
        <FormLabel title={t(`ociConnectionCSPForm.passphraseRadioTitle`)} />
        <RadioGroup
          value={currentConnection?.hasPassPhrase}
          optionType="button"
          rootClassName="flex"
          options={PASSPHRASE_RADIO_OPTIONS}
          onChange={(e: any) => {
            if (!e.target.value) {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  hasPassPhrase: e.target.value,
                  ociPassPhrase: undefined,
                })
              );
              return;
            }
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                hasPassPhrase: e.target.value,
              })
            );
          }}
        />
      </div>
      {currentConnection?.hasPassPhrase && (
        <div className="form-item flex flex-column">
          <FormLabel title={t(`ociConnectionCSPForm.passphrase`)} />
          <Input
            default
            type="input"
            value={currentConnection?.ociPassPhrase}
            onChange={(e: any) => {
              dispatch(
                setCurrentConnection({
                  ...currentConnection,
                  ociPassPhrase: e.target.value,
                })
              );
              setPassFieldValidation('');
              validateInputLength(
                e.target.value,
                t(`ociConnectionCSPForm.passphrase`),
                setPassFieldValidation
              );
            }}
            onBlur={(e: any) => {
              validateEmptyField(
                e.target.value,
                t(`ociConnectionCSPForm.passphrase`),
                setPassFieldValidation
              );
            }}
            data-testid="passphrase-input"
          />
          <span
            style={{
              display: `${passFieldValidation ? 'inline' : 'none'}`,
            }}
            className="font-validation-error"
          >
            {passFieldValidation}
          </span>
        </div>
      )}
      <div className="form-item flex flex-column">
        <FormLabel title={t(`ociConnectionCSPForm.tenancy`)} />
        <Input
          default
          type="input"
          value={currentConnection?.ociTenancy}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                ociTenancy: e.target.value,
              })
            );
            setTenancyValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t(`ociConnectionCSPForm.tenancy`),
              setTenancyValidation
            );
          }}
          data-testid="tenancy-input"
        />
        <span
          style={{
            display: `${tenancyValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {tenancyValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t(`ociConnectionCSPForm.userId`)} />
        <Input
          default
          type="input"
          value={currentConnection?.ociUserId}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                ociUserId: e.target.value,
              })
            );
            setUserIdValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t(`ociConnectionCSPForm.userId`),
              setUserIdValidation
            );
          }}
          data-testid="user-id-input"
        />
        <span
          style={{
            display: `${userIdValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {userIdValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t(`ociConnectionCSPForm.region`)} />
        <SelectDropdown
          value={currentConnection?.ociRegion}
          loading={regionOptionsRequestStatus === REQUEST_STATUS.PROCESSING}
          menu={RegionDropdown}
          onSelect={(value: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                ociRegion: value,
              })
            );
            setRegionValidation('');
          }}
          onBlur={() =>
            validateEmptyField(
              currentConnection?.ociRegion,
              t(`ociConnectionCSPForm.region`),
              setRegionValidation
            )
          }
        />
        <span
          style={{
            display: `${regionValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {regionValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t(`ociConnectionCSPForm.fingerprint`)} />
        <Input
          default
          type="input"
          value={currentConnection?.fingerprint}
          onChange={(e: any) => {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                fingerprint: e.target.value,
              })
            );
            setFingerprintValidation('');
          }}
          onBlur={(e: any) => {
            validateEmptyField(
              e.target.value,
              t(`ociConnectionCSPForm.fingerprint`),
              setFingerprintValidation
            );
          }}
          data-testid="fingerprint-input"
        />
        <span
          style={{
            display: `${fingerprintValidation ? 'inline' : 'none'}`,
          }}
          className="font-validation-error"
        >
          {fingerprintValidation}
        </span>
      </div>
    </div>
  );
};

export default OCIBudgetAccessDetails;
