import { useDispatch, useSelector } from 'react-redux';

import { LeftBackArrowIcon } from 'assets/icons';
import { dataCenter, setDataCenterComponent } from 'redux/dataCenterSlice';
import { selectTheme } from 'redux/themeSlice';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { DataCenterComponents } from 'pages/DataCenterPage/constants';

import './index.scss';

const DataCenterHeader = () => {
  const dispatch = useDispatch();
  const { selectedDataCenter } = useSelector(dataCenter);
  const { theme } = useSelector(selectTheme);

  return (
    <div className="data-center-header new-page-header flex flex-align-items-center flex-gap-16">
      <AccessibleDiv
        className="back-arrow flex cursor-pointer"
        onClick={() => {
          dispatch(
            setDataCenterComponent(DataCenterComponents.DATA_CENTER_TABLE)
          );
        }}
        data-testid="back-arrow"
      >
        <Icon icon={LeftBackArrowIcon} color={theme.buttonIconColor} />
      </AccessibleDiv>
      <div className="flex flex-column">
        <div className="modal-heading">{selectedDataCenter?.name}</div>
        <div className="header-sub-title font-caption-bold">
          {selectedDataCenter?.dataCenterCode}
        </div>
      </div>
    </div>
  );
};
export default DataCenterHeader;
