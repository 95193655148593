import i18n from 'i18n';

export type ImportFilesListType = {
  transactionName: string;
  displayName: string;
  originalFileName: string;
  datasourceProvider: string;
  fileType: string;
  fileImportStatus: string;
  tableName: string;
  statusMessage: string;
  datasetType: string;
  schemaName: string;
  displaySchema: string;
  schema: string;
  focusSchema: boolean;
  standardSchema: boolean;
  preBuiltDashbaordRequired: boolean;
};

export enum ImportFilesActions {
  UPLOAD_FILE = 'UPLOAD_FILE',
  DELETE = 'DELETE',
}

export const IMPORT_FILES_QUICK_ACTIONS = [
  {
    id: ImportFilesActions.UPLOAD_FILE,
    title: i18n.t('importFilesTableQuickActions.uploadFile'),
  },
  {
    id: ImportFilesActions.DELETE,
    title: i18n.t('importFilesTableQuickActions.delete'),
  },
];

export enum ImportFileStatus {
  READY = 'READY',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
}

export const IMPORT_FILE_STATUS_LABELS = [
  {
    id: ImportFileStatus.READY,
    label: i18n.t('importFilesTable.ready'),
  },
  {
    id: ImportFileStatus.UPLOADING,
    label: i18n.t('importFilesTable.uploading'),
  },
  {
    id: ImportFileStatus.PROCESSING,
    label: i18n.t('importFilesTable.processing'),
  },
  {
    id: ImportFileStatus.ERROR,
    label: i18n.t('importFilesTable.error'),
  },
];
