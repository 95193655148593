import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import moment from 'moment';

import { selectPurchase } from 'redux/purchaseSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import Button from 'components/Button';
import QuickActionMenu from 'components/QuickActionMenu';
import Table from 'components/Table';
import DeleteModal from 'components/DeleteModal';
import { BUTTON_SIZE } from 'constants/appearance';
import {
  PURCHASED_LIST_QUICKACTIONS,
  SPAndEC2RIStatus,
  SPAndEC2RIStatusLabels,
} from 'pages/PurchasePage/constants';
import { ERROR_KEY, SUCCESS_KEY } from 'components/DashboardTitle/constants';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DATE_TIME_AM_PM } from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import { deleteAwsSavingPlan, getAllAwsSavingPlans } from './services';
import { AWSSpPlansType } from './types';

const PurchasedSavingPlans = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedConnection } = useSelector(selectPurchase);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [awsSpPlans, setAwsSpPlans] = useState<AWSSpPlansType[]>([]);
  const [fetchAwsSpPlansRequestStatus, setFetchAwsSpPlansRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [actionRowData, setActionRowData] = useState<AWSSpPlansType>();
  const [deleteRequestStatus, setDeleteRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    if (selectedConnection) {
      fetchAllAwsSpPlans();
    }
  }, [selectedConnection]);

  /**
   * @function fetchAllAwsSpPlans
   * @description Function to fetch and set all the AWS Saving plans
   */
  const fetchAllAwsSpPlans = () => {
    setFetchAwsSpPlansRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      region: selectedConnection?.awsRegion,
    };

    getAllAwsSavingPlans(params)
      .then((res: any) => {
        setAwsSpPlans(res?.data?.responseData || []);
        setFetchAwsSpPlansRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchAwsSpPlansRequestStatus);
      });
  };

  /**
   * @function removeAwsSpPlan
   * @description Function to delete the AWS SP Plan
   */
  const removeAwsSpPlan = () => {
    setDeleteRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      region: selectedConnection?.awsRegion,
      savingsPlanId: actionRowData?.savingsPlanId,
    };

    deleteAwsSavingPlan(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setDeleteRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success({
            content: t('purchasePage.savingsPlansLabels.spDeleteSuccess'),
            key: SUCCESS_KEY,
          });
          return;
        }
        message.error({
          content: t('purchasePage.savingsPlansLabels.spDeleteFailed'),
          key: ERROR_KEY,
        });
        setDeleteRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setDeleteRequestStatus);
        message.error({
          content: t('purchasePage.savingsPlansLabels.spDeleteFailed'),
          key: ERROR_KEY,
        });
      });
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick actions clicks
   * @param record record for which the quick action is actioned
   */
  const handleQuickAction = (record: AWSSpPlansType) => {
    setActionRowData(record);
    setShowConfirmDeleteModal(true);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('purchasePage.savingsPlansLabels.savingsPlanType'),
      dataIndex: 'savingsPlanType',
      key: 'savingsPlanType',
    },
    {
      title: t('purchasePage.cart.region'),
      dataIndex: 'region',
      key: 'region',
      render: (text: string) => text || '--',
    },
    {
      title: t('purchasePage.savingsPlansLabels.instanceFamily'),
      dataIndex: 'ec2InstanceFamily',
      key: 'ec2InstanceFamily',
      render: (text: string) => text || '--',
    },
    {
      title: t('purchasePage.cart.term'),
      dataIndex: 'term',
      key: 'term',
      render: (text: string) =>
        t('purchasePage.cart.countMonths', { count: (Number(text) || 0) * 12 }),
    },
    {
      title: t('purchasePage.savingsPlansLabels.hourlyCommitment'),
      dataIndex: 'commitment',
      key: 'hourlyCommitment',
      render: (text: string) =>
        currencySymbol + ' ' + numberCommaSeparator(text, 3, 3),
    },
    {
      title: t('purchasePage.cart.paymentOption'),
      dataIndex: 'paymentOption',
      key: 'paymentOption',
    },
    {
      title: t('purchasePage.cart.createdDate'),
      dataIndex: 'start',
      key: 'createdDate',
      render: (text: string) => moment(text).format(DATE_TIME_AM_PM),
    },
    {
      title: t('purchasePage.status'),
      dataIndex: 'state',
      key: 'status',
      render: (text: string) => (
        <span className={`status ${text}`}>
          {SPAndEC2RIStatusLabels.find((item) => item.key === text)?.label}
        </span>
      ),
    },
    {
      title: t('purchasePage.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_text: string, record: any) => {
        return (
          <QuickActionMenu
            quickActions={PURCHASED_LIST_QUICKACTIONS}
            disabled={record.status !== SPAndEC2RIStatus.QUEUED}
            quickActionHandler={() => {
              handleQuickAction(record);
            }}
          />
        );
      },
      align: 'center',
      width: 80,
    },
  ];

  return (
    <>
      <section className="purchase-saving-plans flex flex-column flex-gap-16">
        <div className="flex flex-align-items-center flex-space-between">
          <div className="form-header">
            {t('purchasePage.savingsPlansLabels.purchasesSavingsPlans')}
          </div>
          <Button
            title={t('purchasePage.savingsPlansLabels.purchaseSavingPlans')}
            size={BUTTON_SIZE.SMALL}
            onClick={() => navigate(NAVIGATION_MENU_PATH.PURCHASE_SAVING_PLANS)}
          />
        </div>
        <Table
          pagination={false}
          dataSource={awsSpPlans.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={columns}
          loading={fetchAwsSpPlansRequestStatus === REQUEST_STATUS.PROCESSING}
          designVersion2={true}
          scroll={{ y: 'calc(100vh - 20em)' }}
        />
      </section>
      {showConfirmDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowConfirmDeleteModal}
          showDeleteModal={showConfirmDeleteModal}
          deletionFunction={removeAwsSpPlan}
          loading={deleteRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </>
  );
};

export default PurchasedSavingPlans;
