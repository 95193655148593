import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Table from 'components/Table';
import GraphHeader from 'components/GraphHeader';
import { CostByBusinessUnitType } from 'pages/CostAllocationDashboardPage/types';
import { selectCostAllocationDashboard } from 'redux/costAllocationDashboardSlice';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import DashboardComponent from 'components/DashboardComponent';
import PieDonutChart from 'components/PieChartAnt';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getAllocationByBusinessUnitExcelData } from '../../utils/exportToExcel';

type AllocationByBusinessUnitProps = {
  businessUnitsData: CostByBusinessUnitType[];
  filteredBusinessUnitsData: CostByBusinessUnitType[];
  months: string[];
  selectedMonths: string[];
  setSelectedMonths: (val: string[]) => void;
  selectedBus: string[];
  setSelectedBUs: (val: string[]) => void;
  isAllocationByBusinessUnitTableView: boolean;
  setIsAllocationByBusinessUnitTableView: (value: boolean) => void;
  requestStatus: string[];
  pdfView: boolean;
};

export const AllocationByBusinessUnit = ({
  businessUnitsData,
  filteredBusinessUnitsData,
  months,
  selectedMonths,
  setSelectedMonths,
  selectedBus,
  setSelectedBUs,
  isAllocationByBusinessUnitTableView,
  setIsAllocationByBusinessUnitTableView,
  requestStatus,
  pdfView,
}: AllocationByBusinessUnitProps) => {
  const { t } = useTranslation();
  const { selectedCostAllocationDashboard } = useSelector(
    selectCostAllocationDashboard
  );
  const { currencySymbol } = useSelector(selectCommonUtility);

  const AllocationByBusinessUnitColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t(
        'costAllocationDashboardLabels.allocationByBusinessUnit.businessUnit'
      ),
      dataIndex: 'name',
      key: 'businessUnit',
    },
    {
      title: t('costAllocationDashboardLabels.allocationByBusinessUnit.cost', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'cost',
      key: 'cost',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text || 0),
    },
  ];

  const getComponent = () =>
    isAllocationByBusinessUnitTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={filteredBusinessUnitsData.map((item, index) => {
            return {
              ...item,
              key: index,
            };
          })}
          columns={AllocationByBusinessUnitColumns}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <PieDonutChart
        data={filteredBusinessUnitsData}
        angleField="cost"
        colorField="name"
        disableAnimation={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  const filters = (
    <div className="filters flex flex-align-items-center">
      <GraphFilterDropdown
        allData={months.map((item) => ({
          month: item,
        }))}
        selectedData={selectedMonths.map((item) => ({
          month: item,
        }))}
        setSelectedData={(selectedData: { month: string }[]) =>
          setSelectedMonths(selectedData.map((item) => item.month))
        }
        setLabels={setSelectedMonths}
        valueSuffix={t(
          'costAllocationDashboardLabels.allocationByBusinessUnit.months'
        )}
        fieldName={DROPDOWN_VALUE_FIELDS.MONTH}
        includeAdditionalOptions={false}
        additionalClassNames="width-25"
        designVersion2
      />
      <GraphFilterDropdown
        allData={businessUnitsData.map((item) => ({
          businessUnit: item.name,
        }))}
        selectedData={selectedBus.map((item) => ({
          businessUnit: item,
        }))}
        setSelectedData={(selectedData: { businessUnit: string }[]) =>
          setSelectedBUs(selectedData.map((item) => item.businessUnit))
        }
        setLabels={setSelectedBUs}
        valueSuffix={t(
          'costAllocationDashboardLabels.allocationByCSC.businessUnits'
        )}
        fieldName={DROPDOWN_VALUE_FIELDS.BUSINESS_UNIT}
        includeAdditionalOptions={false}
        additionalClassNames="width-30"
        designVersion2
      />
    </div>
  );

  return (
    <div
      className="allocation-by-business-unit graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.allocationCostByBusinessUnit')}
        graphName={'allocation-by-business-unit'}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isAllocationByBusinessUnitTableView}
        setIsTableView={setIsAllocationByBusinessUnitTableView}
        filters={filters}
        excelData={getAllocationByBusinessUnitExcelData(
          selectedCostAllocationDashboard?.connectorName ?? '',
          filteredBusinessUnitsData,
          selectedMonths,
          selectedBus
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};
