import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userAuthorization } from 'redux/authorizationSlice';
import {
  customDashboard,
  setDashboardType,
  setIsAddNewDashboard,
} from 'redux/customDashboardSlice';
import { selectDashboard } from 'redux/dashboardSlice';
import Button from 'components/Button';
import DashboardHeader from 'components/DashboardHeader';
import DashboardComponent from 'components/DashboardComponent';
import NavigationPath from 'components/NavigationPath';
import { CUSTOM_DASHBOARD_MODE } from 'constants/dashboard';
import { REQUEST_STATUS } from 'constants/requestBody';
import { BUTTON_SIZE } from 'constants/appearance';
import HorizontalNavigation from '../ConnectionsDashboard/components/HorizontalNavigation';
import ConsolidatedCostSummaryDashboard from './components/ConsolidateCostSummaryDashboard';
import { DASHBOARD } from '../ConnectionsDashboard/components/HorizontalNavigation/constants';
import CustomViewDashboard from '../ConnectionsDashboard/components/CustomViewDashboard';

type GroupsDashboardProps = {
  setShowDataSourceModal: (val: boolean) => void;
  dashboardRequestStatus: string;
  setDashboardRequestStatus: (val: string) => void;
};

const GroupsDashboard = ({
  setShowDataSourceModal,
  dashboardRequestStatus,
  setDashboardRequestStatus,
}: GroupsDashboardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permissions } = useSelector(userAuthorization);
  const { customDashBoardMode } = useSelector(customDashboard);
  const {
    selectedGroupMetaData,
    selectedMyDashboardType,
    selectedDashboardView,
  } = useSelector(selectDashboard);

  const getDashboardView = () => {
    if (selectedDashboardView === DASHBOARD.CONSOLIDATED_COST_SUMMARY) {
      return <ConsolidatedCostSummaryDashboard />;
    }

    return <CustomViewDashboard />;
  };

  const CreateCustomViewCta = (
    <>
      {permissions.dashboardWrite &&
        customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED &&
        dashboardRequestStatus !== REQUEST_STATUS.PROCESSING && (
          <Button
            title={t('dashboardLabels.createCustomView')}
            size={BUTTON_SIZE.SMALL}
            onClick={() => {
              dispatch(setIsAddNewDashboard(false));
              dispatch(setDashboardType(selectedMyDashboardType));
              setShowDataSourceModal(true);
            }}
            disabled={!selectedGroupMetaData}
          />
        )}
    </>
  );

  return (
    <div className="dashboard-content flex-fit">
      <div className="upper-dashboard">
        <DashboardHeader />
      </div>
      <div className="page-content">
        <div className="dashboard-navigation">
          <NavigationPath />
          <HorizontalNavigation
            setRequestStatus={setDashboardRequestStatus}
            extraComponent={CreateCustomViewCta}
          />
        </div>
        <div className="dashboard-view">
          <DashboardComponent
            component={getDashboardView()}
            requestStatus={dashboardRequestStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupsDashboard;
