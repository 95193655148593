import moment from 'moment';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import {
  HYPHEN_DATE_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import {
  getAWSCloudServicesRequestBody,
  getAzureCloudServicesRequestBody,
  getAzureSpecificServicesRequestBody,
  getGCPCloudServicesRequestBody,
  getOCICloudServicesRequestBody,
} from '../constants';

const getGCPCostByCloudProviderRequestBody = (
  fromMonth: string,
  toMonth: string
) => {
  return {
    columns: [
      {
        label: QUERY_FIELDS.MONTH,
        field: QUERY_FIELDS.INVOICE_MONTH,
      },
      {
        label: 'cost',
        field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: [QUERY_FIELDS.MONTH],
    orderBy: [
      {
        label: QUERY_FIELDS.MONTH,
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filters: [
      {
        field: QUERY_FIELDS.INVOICE_MONTH,
        comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
        value: fromMonth,
      },
      {
        field: QUERY_FIELDS.INVOICE_MONTH,
        comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
        value: toMonth,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

const getAWSCostByCloudProviderRequestBody = (
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  let fromDate = `#DATE('${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;
  let toDate = `#DATE('${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;

  if (isMigrated) {
    fromDate = `${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
    toDate = `${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
  }

  return {
    columns: [
      {
        label: QUERY_FIELDS.MONTH,
        field: QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
      },
      {
        label: 'cost',
        field: QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: [QUERY_FIELDS.MONTH],
    orderBy: [
      {
        label: QUERY_FIELDS.MONTH,
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: fromDate,
          },
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: toDate,
          },
          {
            field: QUERY_FIELDS.BILL_BILL_TYPE,
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

const getAzureCostByCloudProviderRequestBody = (
  fromMonth: string,
  toMonth: string
) => {
  return {
    columns: [
      {
        label: QUERY_FIELDS.MONTH,
        field: QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
      },
      {
        label: 'cost',
        field: QUERY_FIELDS.COST_BILLING_CURRENCY,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: [QUERY_FIELDS.MONTH],
    orderBy: [
      {
        label: QUERY_FIELDS.MONTH,
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filters: [
      {
        field: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
        comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
        value: moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
          .startOf('month')
          .format(HYPHEN_DATE_FORMAT),
      },
      {
        field: QUERY_FIELDS.BILLING_PERIOD_END_DATE,
        comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
        value: moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
          .endOf('month')
          .format(HYPHEN_DATE_FORMAT),
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

const getOciCostByCloudProviderRequestBody = (
  fromMonth: string,
  toMonth: string
) => {
  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.COST_MY_COST,
      },
      {
        label: QUERY_FIELDS.MONTH,
        field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['compartment', 'invoiceMonth'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        conjunctToNextGroup: CONJUNCTIONS.AND,
        filters: [
          {
            field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR).format(
              YEAR_MONTH_WITHOUT_ZERO
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR).format(
              YEAR_MONTH_WITHOUT_ZERO
            ),
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

export const getMonthlyCostByProviderRequestBody = (
  provider: string,
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGCPCostByCloudProviderRequestBody(fromMonth, toMonth);
    case PROVIDER.AWS:
      return getAWSCostByCloudProviderRequestBody(
        fromMonth,
        toMonth,
        isMigrated
      );
    case PROVIDER.AZURE:
      return getAzureCostByCloudProviderRequestBody(fromMonth, toMonth);
    case PROVIDER.OCI:
      return getOciCostByCloudProviderRequestBody(fromMonth, toMonth);
  }
};

export const getCloudServicesRequestBody = (
  provider: string,
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  switch (provider) {
    case PROVIDER.GCP:
      return getGCPCloudServicesRequestBody();
    case PROVIDER.AWS:
      return getAWSCloudServicesRequestBody(fromMonth, toMonth, isMigrated);
    case PROVIDER.AZURE:
      return getAzureCloudServicesRequestBody(fromMonth, toMonth);
    case PROVIDER.OCI:
      return getOCICloudServicesRequestBody(fromMonth, toMonth);
  }
};

export const getCostByComputeServiceRequestBody = (
  provider: string,
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  switch (provider) {
    case PROVIDER.GCP: {
      const additionalGcpFilter = [
        {
          field: QUERY_FIELDS.SERVICE_DESCRIPTION,
          comparator: COMPARATORS.EQUALS,
          value: 'Compute Engine',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
      return getGCPCloudServicesRequestBody(additionalGcpFilter);
    }
    case PROVIDER.AWS: {
      const additionalAwsFilter = [
        {
          field: QUERY_FIELDS.PRODUCT_SERVICENAME,
          comparator: COMPARATORS.IN,
          value: "('Amazon Elastic Compute Cloud')",
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
      return getAWSCloudServicesRequestBody(
        fromMonth,
        toMonth,
        isMigrated,
        additionalAwsFilter
      );
    }
    case PROVIDER.AZURE: {
      const additionalAzureFilter = [
        {
          field: QUERY_FIELDS.METER_CATEGORY,
          comparator: COMPARATORS.EQUALS,
          value: 'Virtual Machines',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
      return getAzureSpecificServicesRequestBody(
        fromMonth,
        toMonth,
        additionalAzureFilter
      );
    }
    case PROVIDER.OCI:
      return getOCICloudServicesRequestBody(fromMonth, toMonth);
  }
};

export const getCostByStorageServiceRequestBody = (
  provider: string,
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  switch (provider) {
    case PROVIDER.GCP: {
      const additionalGcpFilter = [
        {
          field: QUERY_FIELDS.SERVICE_DESCRIPTION,
          comparator: COMPARATORS.EQUALS,
          value: 'Cloud Storage',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
      return getGCPCloudServicesRequestBody(additionalGcpFilter);
    }
    case PROVIDER.AWS: {
      const additionalAwsFilter = [
        {
          field: QUERY_FIELDS.PRODUCT_SERVICENAME,
          comparator: COMPARATORS.IN,
          value: "('Amazon Simple Storage Service')",
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
      return getAWSCloudServicesRequestBody(
        fromMonth,
        toMonth,
        isMigrated,
        additionalAwsFilter
      );
    }
    case PROVIDER.AZURE: {
      const additionalAzureFilter = [
        {
          field: QUERY_FIELDS.METER_CATEGORY,
          comparator: COMPARATORS.EQUALS,
          value: 'Storage',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: 'MeterSubCategory',
          comparator: COMPARATORS.LIKE,
          value: '%Blob%',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];
      return getAzureSpecificServicesRequestBody(
        fromMonth,
        toMonth,
        additionalAzureFilter
      );
    }
    case PROVIDER.OCI:
      return getOCICloudServicesRequestBody(fromMonth, toMonth);
  }
};
