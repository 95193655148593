import moment, { Moment } from 'moment';

import { RangeValue } from './types';

/**
 * @function isDateRangeDisabled
 * @description Returns if the date should be disabled for the date range
 * @param current Date which needs to be checked for disable
 * @param dates Dates against which the range needs to be decided (or current selected dates)
 * @param maxMonths Months to be allowed for selection. Defaults to 12 months
 * @returns Boolean to indicate wheter the current date needs to be disabled or enabled
 */
export const isDateRangeDisabled = (
  current: Moment,
  dates: RangeValue,
  maxMonths: number = 12
) => {
  if (!dates) {
    return false;
  }

  // Future months should be disabled
  const isFutureDates = current > moment().endOf('day');

  // Disable any month greater than 3 months from the start month
  const isMoreThanThreeMonthsFromStart =
    Math.ceil(current.diff(dates[0], 'months', true)) >= maxMonths;

  // Disable any month less than 3 months from the end month
  const isLessThanThreeMonthsFromEnd =
    Math.floor(current.diff(dates[1], 'months', true)) <= -maxMonths;

  return (
    isFutureDates ||
    isMoreThanThreeMonthsFromStart ||
    isLessThanThreeMonthsFromEnd
  );
};

/**
 * @function onOpenChange
 * @description Callback for on open or close of the date range selection panel
 * @param open Boolean to indicate the open state of the panel
 * @param setDates Callback to set the dates based on the open state
 */
export const onOpenChange = (
  open: boolean,
  setDates: (val: RangeValue) => void
) => {
  if (open) {
    setDates([null, null]);
  } else {
    setDates(null);
  }
};
