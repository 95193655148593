import { Col, Row } from 'antd';
import SelectDropdown from 'components/Select';
import {
  DEFAULT_RULE,
  DEFAULT_STATEMENT,
  FIELD_VALUE_TYPES,
  OPERATORS,
} from 'pages/RuleEnginePage/constants';
import {
  RulesType,
  RulesetTemplateType,
  StatementValueTemplateType,
  StatementValueType,
  ValueType,
} from 'pages/RuleEnginePage/types';
import { useDispatch, useSelector } from 'react-redux';
import { ruleEngine, setRuleEngineFormData } from 'redux/ruleEngineSlice';
import { isStatementFieldDisabled } from '../../utils';
import DropdownCheckbox from 'components/DropdownCheckbox';
import moment, { Moment } from 'moment';
import { DATE_FORMAT, HYPHEN_DATE_FORMAT } from 'utils/date';
import DatePicker from 'components/DatePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import { DeleteIconButton } from 'assets/icons';
import {
  getTemplateForARuleStatement,
  validateStatement,
} from '../SetupRules/utils';
import { useEffect, useState } from 'react';

type StatementComponentProps = {
  statement: StatementValueType;
  statementIndex: number;
  rule: RulesType;
  ruleIndex: number;
  rulesetTemplateList: RulesetTemplateType[];
  createButtonClick: boolean;
};

const StatementComponent = ({
  statement,
  statementIndex,
  rule,
  ruleIndex,
  rulesetTemplateList,
  createButtonClick,
}: StatementComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ruleEngineFormData } = useSelector(ruleEngine);

  const [statementValidations, setStatementValidations] = useState<string[]>(
    []
  );

  useEffect(() => {
    createButtonClick &&
      setStatementValidations(
        validateStatement(
          statement,
          getTemplateForARuleStatement(rulesetTemplateList, rule, statement)
        )
      );
  }, [createButtonClick]);

  /**
   * @function deleteStatement
   * @description Function to delete a statement in a rule
   * @param statementIndex index of the statement to be deleted
   */
  const deleteStatement = (statementIndex: number) => {
    const rules: RulesType[] = [...ruleEngineFormData.rules];
    let statements = [...(rules.at(ruleIndex)?.statementValuesList ?? [])];
    if (statements && statements.length > 0) {
      statements.splice(statementIndex, 1);
      rules.splice(ruleIndex, 1, {
        ...(rules.at(ruleIndex) ?? DEFAULT_RULE),
        statementValuesList: statements,
      });
      dispatch(setRuleEngineFormData({ ...ruleEngineFormData, rules: rules }));
    }
  };

  /**
   * @function getStatementValueList
   * @description Function to return the statement list for a rule
   * @returns list of statements
   */
  const getStatementValueList = () => {
    return rulesetTemplateList
      .find((item) => item.recommendationType === rule?.recommendationType)
      ?.statements?.find(
        (item) =>
          item.recommendationStatementType === rule.recommendationStatementType
      )?.statementValuesList;
  };

  /**
   * @function getHelpingText
   * @description Function to return the helping text for a statement
   * @param statementField field for which the help statement is formed
   * @returns String containing the sample statement
   */
  const getHelpingText = (statementField: string) => {
    const statement: StatementValueTemplateType | undefined =
      getStatementValueList()?.find((item) => item.field === statementField);
    const field = statement?.field.toLowerCase().replaceAll('_', ' ');
    const operator = statement?.operators[0];
    const min = statement?.min ? statement.min : 3;
    const max = statement?.max ? statement.max : 5;
    const property = statement?.property;
    const applicableValues = statement?.applicableValues ?? [];

    let helpingText = `if ${field} is`;

    if (operator) {
      helpingText = helpingText + ' ' + operator;
    }

    if (operator === OPERATORS.BETWEEN) {
      helpingText = helpingText + ` ${min} and ${max}`;
    } else if (
      (statement?.type === FIELD_VALUE_TYPES.STRING ||
        statement?.type === FIELD_VALUE_TYPES.BOOLEAN) &&
      applicableValues.length > 0
    ) {
      helpingText = helpingText + ' ' + applicableValues[0];
    } else if (max) {
      helpingText = helpingText + ' ' + max;
    }

    if (property) {
      helpingText = helpingText + ' ' + property;
    }

    return helpingText.toLowerCase().replaceAll('_', ' ');
  };

  /**
   * @function onChangeStatementField
   * @description Callback function for onchange event of a field in a statement
   * @param value field selected
   * @param statementIndex Index of the statement
   */
  const onChangeStatementField = (value: string, statementIndex: number) => {
    const rules: RulesType[] = [...ruleEngineFormData.rules];
    let statements = [...(rules.at(ruleIndex)?.statementValuesList ?? [])];
    if (statements && statements.length > 0) {
      statements.splice(statementIndex, 1, {
        ...(statements.at(statementIndex) ?? DEFAULT_STATEMENT),
        field: value,
        operator: '',
        value: {},
      });
      rules.splice(ruleIndex, 1, {
        ...(rules.at(ruleIndex) ?? DEFAULT_RULE),
        statementValuesList: statements,
      });
      dispatch(setRuleEngineFormData({ ...ruleEngineFormData, rules: rules }));
    }
  };

  /**
   * @function onChangeStatementOperator
   * @description Callback function for onchange event of a operator in a statement
   * @param value operator selected
   * @param statementIndex Index of the statement
   */
  const onChangeStatementOperator = (value: string, statementIndex: number) => {
    const rules: RulesType[] = [...ruleEngineFormData.rules];
    let statements = [...(rules.at(ruleIndex)?.statementValuesList ?? [])];
    if (statements && statements.length > 0) {
      statements.splice(statementIndex, 1, {
        ...(statements.at(statementIndex) ?? DEFAULT_STATEMENT),
        operator: value,
        value: {},
      });
      rules.splice(ruleIndex, 1, {
        ...(rules.at(ruleIndex) ?? DEFAULT_RULE),
        statementValuesList: statements,
      });
      dispatch(setRuleEngineFormData({ ...ruleEngineFormData, rules: rules }));
    }
  };

  /**
   * @function onChangeValue
   * @description Callback function on change event of value in a statement
   * @param value value of the input field
   * @param statementIndex Index of the statement
   */
  const onChangeValue = (value: ValueType, statementIndex: number) => {
    const rules: RulesType[] = [...ruleEngineFormData.rules];
    let statements = [...(rules.at(ruleIndex)?.statementValuesList ?? [])];
    if (statements && statements.length > 0) {
      statements.splice(statementIndex, 1, {
        ...(statements.at(statementIndex) ?? DEFAULT_STATEMENT),
        value: value,
      });
      rules.splice(ruleIndex, 1, {
        ...(rules.at(ruleIndex) ?? DEFAULT_RULE),
        statementValuesList: statements,
      });
      dispatch(setRuleEngineFormData({ ...ruleEngineFormData, rules: rules }));
    }
    setStatementValidations(
      validateStatement(
        statements[statementIndex],
        getTemplateForARuleStatement(
          rulesetTemplateList,
          rule,
          statements[statementIndex]
        )
      )
    );
  };

  /**
   * @function onChangeRangePicker
   * @description Callback function for change event of range picker values in a statement
   * @param dateString list containing the start date and end date
   * @param statementIndex Index of the statement
   */
  const onChangeRangePicker = (
    dates: [Moment, Moment],
    statementIndex: number
  ) => {
    const value: ValueType = {
      valueRange: {
        min: dates[0].format(HYPHEN_DATE_FORMAT),
        max: dates[1].format(HYPHEN_DATE_FORMAT),
      },
    };

    onChangeValue(value, statementIndex);
  };

  /**
   * @function onChangeDatePicker
   * @description Callback function for change event of date picker in a statement
   * @param dateString date string
   * @param statementIndex Index of the statement
   */
  const onChangeDatePicker = (
    dateString: string | undefined,
    statementIndex: number
  ) => {
    const value: ValueType = {
      constantValue: dateString,
    };

    onChangeValue(value, statementIndex);
  };

  /**
   * @function onChangeInputValue1
   * @description Callback function for change event of first value of input field in a statement
   * @param inputValue Input value of the field
   * @param previousValue Previous value of the statement
   * @param operator Operator chosen
   * @param statementIndex Index of the statement
   */
  const onChangeInputValue1 = (
    inputValue: string,
    previousValue: ValueType,
    operator: string,
    statementIndex: number
  ) => {
    let value: ValueType = {};

    if (operator === OPERATORS.BETWEEN) {
      value = {
        valueRange: {
          min: inputValue,
          max: previousValue?.valueRange?.max ?? '',
        },
      };
    } else {
      value = {
        constantValue: inputValue,
      };
    }

    onChangeValue(value, statementIndex);
  };

  /**
   * @function onChangeInputValue2
   * @description Callback function for change event of second value of input field in a statement
   * @param inputValue Input value of the field
   * @param previousValue Previous value of the statement
   * @param operator Operator chosen
   * @param statementIndex Index of the statement
   */
  const onChangeInputValue2 = (
    inputValue: string,
    previousValue: ValueType,
    operator: string,
    statementIndex: number
  ) => {
    let value: ValueType = {};

    if (operator === OPERATORS.BETWEEN) {
      value = {
        valueRange: {
          min: previousValue?.valueRange?.min ?? '',
          max: inputValue,
        },
      };
    } else {
      value = {
        constantValue: inputValue,
      };
    }

    onChangeValue(value, statementIndex);
  };

  /**
   * @function onChangeMultiSelectionField
   * @description Callback function for on change event of multiple selection dropdown
   * @param values Values selected
   * @param statementIndex Index of the statement
   */
  const onChangeMultiSelectionField = (
    values: string[],
    statementIndex: number
  ) => {
    const value: ValueType = { valueList: values };

    onChangeValue(value, statementIndex);
  };

  /**
   * @function getDateField
   * @description Function to construct and return the date field(s)
   * @param operator Operator chosen in the statement
   * @param value Value of the statement field
   * @param statementIndex Index of the statement
   * @returns JSX element containing the date field
   */
  const getDateField = (
    operator: string,
    value: ValueType,
    statementIndex: number
  ) => {
    const startDate = value ? moment(value.valueRange?.min) : moment();
    const endDate = value ? moment(value.valueRange?.max) : moment();
    const datePickerValue = value.constantValue
      ? moment(value.constantValue)
      : moment();

    return operator === OPERATORS.BETWEEN ? (
      <DatePicker
        defaultValue={[startDate, endDate]}
        onChange={(dates: [Moment, Moment], _dateString: [string, string]) =>
          onChangeRangePicker(dates, statementIndex)
        }
        disabledDate={(date: any) => date > moment()}
        format={DATE_FORMAT}
      />
    ) : (
      <DatePicker
        value={datePickerValue}
        pickerType={DATE_PICKER_TYPE.DATE_PICKER}
        showToday={false}
        onChange={(date: any) =>
          onChangeDatePicker(date?.format(HYPHEN_DATE_FORMAT), statementIndex)
        }
        disabledDate={(date: any) => date > moment()}
        format={DATE_FORMAT}
      />
    );
  };

  /**
   * @function getDropdownField
   * @description Function to construct and return the dropdown
   * @param operator Operator chosen in the statement
   * @param applicableValues list of options for the dropdown
   * @param value Value of the statement field
   * @param statementIndex Index of the statement
   * @returns JSX element containing the dropdown field
   */
  const getDropdownField = (
    operator: string,
    applicableValues: string[],
    value: ValueType,
    statementIndex: number
  ) => {
    if (operator === OPERATORS.IN) {
      return (
        <DropdownCheckbox
          additionalClassNames="multi-select-checkbox"
          itemOptions={applicableValues?.map((item) => ({
            value: item,
            title: item,
          }))}
          value={t('createRulesetLabels.rulesets.countSelection', {
            count: value?.valueList?.length ?? 0,
          })}
          selectedItems={value?.valueList ?? []}
          setSelectedItems={(values: string[]) =>
            onChangeMultiSelectionField(values, statementIndex)
          }
        />
      );
    }

    return (
      <SelectDropdown
        className="select-dropdown"
        value={value.constantValue}
        options={applicableValues?.map((item) => ({
          value: item,
          label: item,
        }))}
        onChange={(selectedValue: string) =>
          onChangeInputValue1(selectedValue, value, operator, statementIndex)
        }
        showSearch
        dropdownMatchSelectWidth
      />
    );
  };

  /**
   * @function getInputField
   * @description Function to construct and return the input field based on the operator
   * @param applicableValues list of options for the dropdown
   * @param operator Operator chosen in the statement
   * @param value Value of the statement field
   * @param statementIndex Index of the statement
   * @returns JSX element containing the input field
   */
  const getInputField = (
    applicableValues: string[] | undefined,
    operator: string,
    value: ValueType,
    statementIndex: number
  ) => {
    if (applicableValues && applicableValues.length > 0) {
      return getDropdownField(
        operator,
        applicableValues,
        value,
        statementIndex
      );
    }

    return (
      <>
        <Input
          className="input-text flex-fit"
          value={
            operator === OPERATORS.BETWEEN
              ? value.valueRange?.min
              : value.constantValue
          }
          onChange={(e: any) =>
            onChangeInputValue1(e.target.value, value, operator, statementIndex)
          }
        />
        {operator === OPERATORS.BETWEEN && (
          <>
            <span>and</span>
            <Input
              className="input-text flex-fit"
              value={value.valueRange?.max}
              onChange={(e: any) =>
                onChangeInputValue2(
                  e.target.value,
                  value,
                  operator,
                  statementIndex
                )
              }
            />
          </>
        )}
      </>
    );
  };

  /**
   * @function getValueComponent
   * @description Function to return the input field based on the operator and type of statement value
   * @param statementTemplate Template of the statement
   * @param operator Operator chosen in the statement
   * @param value Value of the statement field
   * @param statementIndex Index of the statement
   * @returns JSX element containing the input field
   */
  const getValueComponent = (
    statementTemplate: StatementValueTemplateType | undefined,
    operator: string,
    value: ValueType,
    statementIndex: number
  ) => {
    if (statementTemplate?.type === FIELD_VALUE_TYPES.DATE) {
      return getDateField(operator, value, statementIndex);
    }

    return getInputField(
      statementTemplate?.applicableValues,
      operator,
      value,
      statementIndex
    );
  };

  return (
    <div key={statement.field}>
      <Row gutter={16} className="statement-row">
        <Col span={22} className="flex flex-wrap flex-align-items-center">
          <span className="conjuction">if</span>
          <SelectDropdown
            className="select-dropdown flex-fit"
            value={statement.field}
            options={getStatementValueList()?.map((item) => ({
              value: item.field,
              label: item.field.toLowerCase().replaceAll('_', ' '),
              disabled: isStatementFieldDisabled(
                ruleEngineFormData,
                rule.recommendationType,
                rule.recommendationStatementType,
                statementIndex,
                item.field
              ),
            }))}
            onChange={(value: string) =>
              onChangeStatementField(value, statementIndex)
            }
            showSearch
            dropdownMatchSelectWidth
          />
          <span>is</span>
          <SelectDropdown
            className="select-dropdown flex-fit"
            value={statement.operator}
            options={getStatementValueList()
              ?.find((item) => item.field === statement.field)
              ?.operators?.map((operator) => ({
                value: operator,
                label: operator.toLowerCase().replaceAll('_', ' '),
              }))}
            showSearch
            dropdownMatchSelectWidth
            onChange={(value: string) =>
              onChangeStatementOperator(value, statementIndex)
            }
          />
          {getValueComponent(
            getStatementValueList()?.find(
              (item) => item.field === statement.field
            ),
            statement.operator,
            statement.value,
            statementIndex
          )}
        </Col>
        <Col span={2} className="flex flex-end">
          <DeleteIconButton
            className="cursor-pointer flex flex-align-self-end"
            width={32}
            height={32}
            onClick={() => deleteStatement(statementIndex)}
          />
        </Col>
      </Row>
      <span className="helping-text font-caption">
        {getHelpingText(statement.field)}
      </span>
      {statementValidations.length > 0 && (
        <div className="font-validation-error">
          {statementValidations.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StatementComponent;
