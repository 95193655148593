export enum BUTTON_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum BUTTON_TYPE {
  PRIMARY = 'primary',
  NEUTRAL = 'neutral',
  OUTLINED = 'outlined',
  LINK = 'link',
}

export enum INPUT_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export const INPUT_PADDING = 4;

export enum KEYCODE {
  BACKSPACE = 8,
}
