import { message } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ControlFilterType } from 'components/DashboardControl/types';
import RadioGroup from 'components/RadioGroup';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ORDERED_SERVICE_NOW_STATUS_LIST } from 'constants/workflowIntegrations';
import {
  Days,
  DAYS_LIST,
  SPAndOpenSearchPaymentOptionsList,
  SPAndOpenSearchPaymentOptions,
  TERMS_LIST,
  RecommendationSavingsPlanType,
  SAVINGS_PLANS_TYPES,
  RecommendationTerm,
  SP_SNOW_IDENTIFIER,
  Terms,
} from 'pages/PurchasePage/constants';
import RecommendationsWrapper from 'pages/PurchasePage/components/RecommendationsWrapper';
import { PurchaseReservedInstanceType } from 'pages/PurchasePage/components/ReservedInstances/types';
import { getAWSSpRecommendations } from 'pages/PurchasePage/components/ReservedInstances/services';
import { selectPurchase } from 'redux/purchaseSlice';
import { ComparisonListType } from 'types/dashboard';
import { onApiCallError } from 'utils/handleErrors';

import { getColumns, getDefaultRiMetricCards } from './utils';
import { PurchaseSavingPlansType } from '../PurchaseSavingPlans/types';
import {
  buyAwsSavingPlans,
  getAwsSpOfferingId,
} from '../PurchaseSavingPlans/services';

const SavingsPlansRecommendation = () => {
  const { t } = useTranslation();
  const { selectedConnection } = useSelector(selectPurchase);

  const [filterValues, setFilterValues] = useState<{
    [key: string]: string | number;
  }>({
    type: RecommendationSavingsPlanType.COMPUTE_SP,
    term: RecommendationTerm.THREE_YEARS,
    paymentOption: SPAndOpenSearchPaymentOptions.NO_UPFRONT,
    days: Days.SIXTY_DAYS,
  });

  /**
   * @function purchaseSps
   * @description Function to purchase the selected savings plans
   * @param data RIs offering ids and details to purchase
   * @param setPurchaseRequestStatus set purchase request status for button
   * @param setData set data for Recommendation table
   * @param setMetricCards set metric cards data
   * @param setReqStatus set request status for Recommendation table
   */
  const purchaseSps = async (
    data: (PurchaseReservedInstanceType | PurchaseSavingPlansType)[],
    setPurchaseRequestStatus: (val: string) => void,
    setData: (val: any[]) => void,
    setMetricCards: (val: ComparisonListType[]) => void,
    setReqStatus: (val: string) => void
  ) => {
    const offeringData = data as PurchaseSavingPlansType[];
    const requests = offeringData.map((item) => {
      const requestBody = {
        connectorId: selectedConnection?.connectorId,
        region: item.region,
        commitment: item.commitment,
        savingsPlanOfferingId: item.savingsPlanOfferingId,
      };

      return buyAwsSavingPlans(requestBody);
    });

    try {
      const responses: any[] = await axios.all(requests);
      if (responses?.filter((res: any) => res?.status === 200).length > 0) {
        setPurchaseRequestStatus(REQUEST_STATUS.SUCCESS);
        const successCount = responses.filter(
          (res: any) => res?.status === 200
        ).length;
        message.success(
          t(
            'purchasePage.reservedInstancesLabels.purchaseRecommendedRiSuccess',
            {
              successCount,
              totalCount: responses.length,
            }
          )
        );
        fetchRecommendationData(setData, setMetricCards, setReqStatus);
        return;
      }
      message.error(
        t('purchasePage.reservedInstancesLabels.purchaseRecommendedRiError')
      );
      setPurchaseRequestStatus(REQUEST_STATUS.ERROR);
    } catch (e) {
      onApiCallError(
        e,
        true,
        setPurchaseRequestStatus,
        t('purchasePage.reservedInstancesLabels.purchaseRecommendedRiError')
      );
    }
  };

  const fetchRecommendationData = (
    setData: (val: any[]) => void,
    setMetricCards: (val: any[]) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);
    setMetricCards(getDefaultRiMetricCards());

    const params = {
      connectorId: selectedConnection?.connectorId,
      savingsPlansType: filterValues.type,
      lookbackPeriodInDays: filterValues.days,
      termInYears: filterValues.term,
      paymentOption: filterValues.paymentOption,
      accountScope: 'LINKED',
    };
    getAWSSpRecommendations(params)
      .then((res: any) => {
        const data = res?.data?.responseData;
        setData(
          data?.savingPlanList?.map((item: any) => ({
            ...item,
            serviceNowStatus: ORDERED_SERVICE_NOW_STATUS_LIST.at(
              Number(item.incidentStatus)
            ),
            incidentRecommendationStatus: item.approvalStatus,
            id: item.spId,
            instanceType: item.instanceFamily,
            estimatedMonthlySavings: item.estimatedMonthlySavingsAmount,
          }))
        );
        setMetricCards([
          {
            ...getDefaultRiMetricCards()[0],
            value: data?.resourceNumber,
            requestStatus: [REQUEST_STATUS.SUCCESS],
          },
          {
            ...getDefaultRiMetricCards()[1],
            value: data?.monthlyOnDemandCost,
            requestStatus: [REQUEST_STATUS.SUCCESS],
          },
          {
            ...getDefaultRiMetricCards()[2],
            value: data?.estimatedMothlySpend,
            requestStatus: [REQUEST_STATUS.SUCCESS],
          },
          {
            ...getDefaultRiMetricCards()[3],
            value: data?.estimatedMonthlySavingsAmount,
            requestStatus: [REQUEST_STATUS.SUCCESS],
          },
        ]);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        setMetricCards(
          getDefaultRiMetricCards().map((item) => ({
            ...item,
            requestStatus: [REQUEST_STATUS.ERROR],
          }))
        );
        setData([]);
        onApiCallError(e, false, setRequestStatus);
      });
  };

  const controlFilters: ControlFilterType[] = [
    {
      title: t('purchasePage.savingsPlansLabels.savingsPlanType'),
      filter: (
        <RadioGroup
          options={SAVINGS_PLANS_TYPES.map((item) => ({
            label: item.label,
            value: item.recommendationValue,
          }))}
          onChange={(e: any) =>
            setFilterValues({ ...filterValues, type: e.target.value })
          }
          value={filterValues.type}
          optionType="button"
          rootClassName="no-custom-style radio-button-options"
          style={{ height: 28 }}
        />
      ),
      minimizedText:
        SAVINGS_PLANS_TYPES.find(
          (item) => item.recommendationValue === filterValues.type
        )?.label ?? '',
      onClear: () =>
        setFilterValues({
          ...filterValues,
          type: RecommendationSavingsPlanType.COMPUTE_SP,
        }),
      width: 'auto',
    },
    {
      title: t('purchasePage.term'),
      filter: (
        <RadioGroup
          options={TERMS_LIST.map((item) => ({
            label: item.label,
            value: item.recommendationValue,
          }))}
          onChange={(e: any) =>
            setFilterValues({ ...filterValues, term: e.target.value })
          }
          value={filterValues.term}
          optionType="button"
          rootClassName="no-custom-style radio-button-options"
          style={{ height: 28 }}
        />
      ),
      minimizedText:
        TERMS_LIST.find(
          (item) => item.recommendationValue === filterValues.term
        )?.label ?? '',
      onClear: () =>
        setFilterValues({
          ...filterValues,
          term: RecommendationTerm.THREE_YEARS,
        }),
      width: 'auto',
    },
    {
      title: t('purchasePage.paymentOptions'),
      filter: (
        <RadioGroup
          options={SPAndOpenSearchPaymentOptionsList}
          onChange={(e: any) =>
            setFilterValues({ ...filterValues, paymentOption: e.target.value })
          }
          value={filterValues.paymentOption}
          optionType="button"
          rootClassName="no-custom-style radio-button-options"
          style={{ height: 28 }}
        />
      ),
      minimizedText:
        SPAndOpenSearchPaymentOptionsList.find(
          (item) => item.value === filterValues.paymentOption
        )?.label ?? '',
      onClear: () =>
        setFilterValues({
          ...filterValues,
          paymentOption: SPAndOpenSearchPaymentOptions.NO_UPFRONT,
        }),
      width: 'auto',
    },
    {
      title: t('purchasePage.basedOnThePast'),
      filter: (
        <RadioGroup
          options={DAYS_LIST}
          onChange={(e: any) =>
            setFilterValues({ ...filterValues, days: e.target.value })
          }
          value={filterValues.days}
          optionType="button"
          rootClassName="no-custom-style radio-button-options"
          style={{ height: 28 }}
        />
      ),
      minimizedText:
        DAYS_LIST.find((item) => item.value === filterValues.days)?.label ?? '',
      onClear: () =>
        setFilterValues({ ...filterValues, days: Days.SIXTY_DAYS }),
      width: 'auto',
    },
  ];

  /**
   * @function onClickPurchase
   * @description Function to purchase the selected EC2 RIs
   * @param selectedRecommendations selected EC2 RIs
   * @param setPurchaseRequestStatus set purchase request status for button
   * @param setPurchaseOfferingDetails set offering ids and details for purchase
   */
  const onClickPurchase = async (
    selectedRecommendations: any[],
    setPurchaseRequestStatus: (val: string) => void,
    setPurchaseOfferingDetails: (val: PurchaseSavingPlansType[]) => void
  ) => {
    setPurchaseRequestStatus(REQUEST_STATUS.PROCESSING);
    const purchaseSpDetails: PurchaseSavingPlansType[] = [];

    for (const item of selectedRecommendations) {
      const requestBody = {
        connectorId: selectedConnection?.connectorId,
        instanceFamily: item.instanceType,
        region: item.region,
        savingsPlanPaymentOption: filterValues.paymentOption,
        savingsPlanType: SAVINGS_PLANS_TYPES.find(
          (option) => option.recommendationValue === filterValues.type
        )?.value,
        term:
          TERMS_LIST.find(
            (option) => option.recommendationValue === filterValues.term
          )?.value ?? Terms.ONE_YEAR,
      };

      try {
        const res: any = await getAwsSpOfferingId(requestBody);
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          if (data) {
            purchaseSpDetails.push({
              region: item.region,
              commitment: item.hourlyCommitmentToPurchase,
              savingsPlanOfferingId: data,
              savingsPlanType: filterValues.type as string,
              term:
                TERMS_LIST.find(
                  (option) => option.recommendationValue === filterValues.term
                )?.value ?? Terms.ONE_YEAR,
              paymentOption: filterValues.paymentOption as string,
            });
            continue;
          }
          setPurchaseRequestStatus(REQUEST_STATUS.ERROR);
          message.error(
            t('purchasePage.reservedInstancesLabels.offeringIdNullError')
          );
          continue;
        }
        setPurchaseRequestStatus(REQUEST_STATUS.ERROR);
        message.error(
          t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      } catch (e: any) {
        onApiCallError(
          e,
          true,
          setPurchaseRequestStatus,
          e?.response?.data?.message ??
            t('purchasePage.reservedInstancesLabels.offeringDetailsError')
        );
      }
    }

    setPurchaseOfferingDetails(purchaseSpDetails);
    setPurchaseRequestStatus(REQUEST_STATUS.SUCCESS);
  };

  return (
    <RecommendationsWrapper
      controlFilters={controlFilters}
      purchaseRecommendationCtaTitle={t(
        'purchasePage.savingsPlansLabels.purchaseRecommendedSp'
      )}
      columns={getColumns()}
      filterValues={filterValues}
      fetchRecommendationData={fetchRecommendationData}
      onClickPurchase={onClickPurchase}
      heading={t('purchasePage.savingsPlans')}
      snowStatusColumnIndex={3}
      purchaseOfferings={purchaseSps}
      snowTicketIdentifier={SP_SNOW_IDENTIFIER}
    />
  );
};

export default SavingsPlansRecommendation;
