import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ColumnChart from 'components/ColumnChart';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Cascader from 'components/Cascader';
import { REQUEST_STATUS } from 'constants/requestBody';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import SelectDropdown from 'components/Select';
import { INPUT_SIZE } from 'constants/appearance';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { DATE_FORMAT } from 'utils/date';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';

import { displayRender, onChangeDateFilter, onChangeDates } from '../../utils';
import { UtilizationByDimensionAndNodeType } from '../../types';
import { getPodMemoryUtilizationExcelData } from '../../utils/exportToExcel';

type PodMemoryUtilizationProps = {
  podMemoryUtilizationData: UtilizationByDimensionAndNodeType[];
  allNodes: string[];
  podMemoryUtilizationSelectedNode: string;
  setPodMemoryUtilizationSelectedNode: (val: string) => void;
  requestStatus: string[];
  podMemoryUtilizationTableView: boolean;
  setPodMemoryUtilizationTableView: (value: boolean) => void;
  pdfView: boolean;
  podMemoryUtilizationDateFilter: DateRangeFilter;
  setPodMemoryUtilizationDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};

const PodMemoryUtilization = ({
  podMemoryUtilizationData,
  allNodes,
  podMemoryUtilizationSelectedNode,
  setPodMemoryUtilizationSelectedNode,
  requestStatus,
  podMemoryUtilizationTableView,
  setPodMemoryUtilizationTableView,
  pdfView,
  podMemoryUtilizationDateFilter,
  setPodMemoryUtilizationDateFilter,
  excelFilters,
}: PodMemoryUtilizationProps) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 40,
    },
    {
      title: t('containerInsight.usageMeteringLabels.dimension'),
      dataIndex: 'dimension',
      key: 'dimension',
    },
    {
      title: t('containerInsight.usageMeteringLabels.memoryInBytes'),
      dataIndex: 'utilization',
      key: 'utilization',
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text || 0),
    },
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  format={DATE_FORMAT}
                  defaultValue={[
                    moment(podMemoryUtilizationDateFilter.startDate),
                    moment(podMemoryUtilizationDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(dateString, setPodMemoryUtilizationDateFilter)
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters flex flex-row flex-align-items-center flex-gap-16">
      <Cascader
        className="width-40"
        options={DateMenu}
        defaultValue={[podMemoryUtilizationDateFilter.option]}
        displayRender={() => displayRender(podMemoryUtilizationDateFilter)}
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setPodMemoryUtilizationDateFilter)
        }
      />
      <SelectDropdown
        rootClassName="width-40"
        value={podMemoryUtilizationSelectedNode}
        options={allNodes.map((item) => ({
          label: item,
          value: item,
        }))}
        onSelect={(value: any) => {
          setPodMemoryUtilizationSelectedNode(value);
        }}
        size={INPUT_SIZE.SMALL}
        designVersion2
      />
    </div>
  );

  const getComponent = () =>
    podMemoryUtilizationTableView ? (
      <Table
        pagination={false}
        loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
        dataSource={podMemoryUtilizationData.map((data, index) => ({
          ...data,
          key: index,
        }))}
        columns={columns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer={true}
      />
    ) : (
      <ColumnChart
        data={podMemoryUtilizationData}
        yField="utilization"
        xField="dimension"
        groupingField="dimension"
        yTitle={t('containerInsight.usageMeteringLabels.memoryInBytes')}
        disableAnimation={pdfView}
        suffixSymbol={t('containerInsight.usageMeteringLabels.bytes')}
      />
    );

  return (
    <div
      className="pod-memory-utilization graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.podMemoryUtilization')}
        graphName="pod-memory-utilization"
        ignorePaddingBottom={true}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={podMemoryUtilizationTableView}
        setIsTableView={setPodMemoryUtilizationTableView}
        filters={filters}
        excelData={getPodMemoryUtilizationExcelData(podMemoryUtilizationData, [
          ...excelFilters,
          {
            heading: t('excelExportLabels.startDate'),
            value: podMemoryUtilizationDateFilter.startDate,
          },
          {
            heading: t('excelExportLabels.endDate'),
            value: podMemoryUtilizationDateFilter.endDate,
          },
          {
            heading: t('excelExportLabels.node'),
            value: podMemoryUtilizationSelectedNode,
          },
        ])}
        designVersion2
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default PodMemoryUtilization;
