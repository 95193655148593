import { useEffect, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loginRequest } from 'authConfig';
import AppRouter from 'AppRouter';
import Loader from 'components/Loader';
import { REQUEST_STATUS } from 'constants/requestBody';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import {
  setAuthorities,
  setPermissions,
  setUserAuthorization,
  userAuthorization,
  setIsServerError,
  setAzureADAccessToken,
} from 'redux/authorizationSlice';
import {
  setAutoGenerateChartColors,
  setManualChartColors,
  setTheme,
} from 'redux/themeSlice';
import {
  fetchChartColorPreferences,
  fetchDefaultPallete,
} from 'pages/AppearancePage/services';
import LoginWithSSO from 'pages/LoginPage/components/LoginWithSSO';
import { getPermissionsMap } from 'utils/permissions';
import { fetchAuthToken } from 'utils/services';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';

type SSOLoginRouteProps = {
  sessionExpired: boolean;
  setSessionExpired: (val: boolean) => void;
};

function SSOLoginRoute({
  sessionExpired,
  setSessionExpired,
}: Readonly<SSOLoginRouteProps>) {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { authorities, isUserAuthorized, azureADAccessToken } =
    useSelector(userAuthorization);

  const [ssoAccessTokenReqStatus, setSsoAccessTokenReqStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [fetchAccessToken, setFetchAccessToken] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [fetchThemeRequestStatus, setFetchThemeRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [chartColorRequestStatus, setChartColorRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    requestAccessToken();
  }, []);

  useEffect(() => {
    if (isAuthenticated && azureADAccessToken) {
      fetchCb360AuthToken();
    }
  }, [azureADAccessToken, isAuthenticated]);

  useEffect(() => {
    dispatch(setPermissions(getPermissionsMap(authorities)));
  }, [authorities]);

  /**
   * @function requestAccessToken
   * @description Function to silently fetch access token or redirect users to login and get access token through sso
   */
  const requestAccessToken = () => {
    setSsoAccessTokenReqStatus(REQUEST_STATUS.PROCESSING);
    const request = {
      ...loginRequest,
      account: instance.getActiveAccount() ?? undefined,
    };

    instance
      .ssoSilent(request)
      .then((response) => {
        dispatch(setAzureADAccessToken(response.accessToken));
        setSsoAccessTokenReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        setSsoAccessTokenReqStatus(REQUEST_STATUS.ERROR);
        if (
          [
            'login_required',
            'interaction_required',
            'consent_required',
            'no_account_error',
          ].includes(e?.errorCode)
        ) {
          instance.acquireTokenPopup(request).then((response) => {
            dispatch(setAzureADAccessToken(response.accessToken));
          });
        }
      });
  };

  /**
   * @function fetchCb360AuthToken
   * @description Function to fetch the CB360 auth token
   */
  const fetchCb360AuthToken = () => {
    setFetchAccessToken(REQUEST_STATUS.PROCESSING);
    fetchAuthToken(azureADAccessToken)
      .then((res: any) => {
        if (res?.status === 200) {
          const { data } = res;
          const { accessToken } = data;
          getDefaultPallete();
          getChartColorPreference();
          const tokenData: any = jwt_decode(accessToken);
          dispatch(setUserAuthorization(true));
          dispatch(setIsServerError(false));
          setSessionExpired(false);
          dispatch(setAuthorities(tokenData.authorities));
          localStorage.setItem('name', tokenData.name);
          localStorage.setItem('email', tokenData.sub);
          localStorage.setItem('role', tokenData.role);
          localStorage.setItem('expiresAt', tokenData.exp);
          localStorage.setItem('issuedAt', tokenData.iat);
          if (
            [
              NAVIGATION_MENU_PATH.NOT_AUTHORIZED.valueOf(),
              NAVIGATION_MENU_PATH.ERROR.valueOf(),
            ].includes(location.pathname)
          ) {
            navigate(NAVIGATION_MENU_PATH.DEFAULT);
          }
          return;
        }

        dispatch(setUserAuthorization(false));
        dispatch(setIsServerError(true));
      })
      .catch((error) => {
        if ([404, 401].includes(error?.response?.status)) {
          dispatch(setIsServerError(false));
        } else {
          dispatch(setIsServerError(true));
        }
        dispatch(setUserAuthorization(false));
        onApiCallError(error);
        if (
          [
            NAVIGATION_MENU_PATH.NOT_AUTHORIZED.valueOf(),
            NAVIGATION_MENU_PATH.ERROR.valueOf(),
          ].includes(location.pathname)
        ) {
          navigate(NAVIGATION_MENU_PATH.DEFAULT);
        }
      })
      .finally(() => {
        setFetchAccessToken(REQUEST_STATUS.SUCCESS);
      });
  };

  /**
   * @function getDefaultPallete
   * @description Function to fetch color pallette theme
   */
  const getDefaultPallete = () => {
    setFetchThemeRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchDefaultPallete()
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setTheme(res?.data?.responseData));
          setFetchThemeRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setFetchThemeRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, false, setFetchThemeRequestStatus);
      });
  };

  /**
   * @function getChartColorPreference
   * @description Function to fetch static pallettes
   */
  const getChartColorPreference = () => {
    setChartColorRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchChartColorPreferences()
      .then((res: any) => {
        if (res.status === 200) {
          const autoGenerate = res?.data?.responseData?.autoGenerate;
          dispatch(setAutoGenerateChartColors(autoGenerate));
          if (!autoGenerate) {
            dispatch(
              setManualChartColors(res?.data?.responseData?.selectedColors)
            );
          }
          setChartColorRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setChartColorRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setChartColorRequestStatus);
      });
  };

  const isLoading = () => {
    return (
      evaluateRequestArray([
        fetchAccessToken,
        fetchThemeRequestStatus,
        chartColorRequestStatus,
      ]) === REQUEST_STATUS.PROCESSING
    );
  };

  if (sessionExpired && isUserAuthorized) {
    return (
      <LoginWithSSO
        sessionExpired={sessionExpired}
        onClickLogin={requestAccessToken}
      />
    );
  }

  return (
    <div className="full-height" data-testid="sso-login-page">
      <AuthenticatedTemplate>
        {isLoading() ? <Loader /> : <AppRouter />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {ssoAccessTokenReqStatus === REQUEST_STATUS.PROCESSING ? (
          <Loader />
        ) : (
          <LoginWithSSO
            sessionExpired={sessionExpired}
            onClickLogin={requestAccessToken}
          />
        )}
      </UnauthenticatedTemplate>
    </div>
  );
}

export default SSOLoginRoute;
