import { Table as AntTable } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { RangeValue } from 'rc-picker/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';

import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import ColumnLineChart from 'components/ColumnLineChart';
import SelectDropdown from 'components/Select';
import DatePicker from 'components/DatePicker';
import TooltipContent from 'components/TooltipContent';
import DashboardComponent from 'components/DashboardComponent';
import Icon from 'components/Icon';
import { ICONS_SIZE } from 'constants/icons';
import { SavingsEffectivenessType } from 'types/dataTypes';

import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { DATE_FORMAT, HYPHEN_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'utils/date';
import { selectScorecard } from 'redux/scorecardSlice';
import {
  ScoreCardsTimeRangeOptions,
  SCORECARDS_TIMERANGE,
} from 'pages/ScorecardPage/constants';
import {
  getDateLabelsByGranularity,
  getRowExpandIcon,
} from 'pages/ScorecardPage/utils';
import { COLORS } from 'constants/graphConfig';
import { generateGraphColors } from 'utils/dashboardUtils';
import { INPUT_SIZE } from 'constants/appearance';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import {
  getSavingsEffectivenessExcelData,
  getSavingsEffectivenessConsolidatedData,
  getTableData,
  getTableExpandedItemsData,
  getCategoryEnum,
} from '../SavingsEffectivenessScorecardDashboard/utils';
import { GCP_SAVINGS_EFFECTIVENESS_ITEMS } from '../SavingsEffectivenessScorecardDashboard/constants';

import './index.scss';

type SavingsEffectivenessProps = {
  savingsEffectivenessData: SavingsEffectivenessType;
  requestStatus: string;
  pdfView: boolean;
  selectedTimeRange: string;
  setSelectedTimeRange: (val: string) => void;
  savingsEffectivenessDateRange: string[];
  setSavingsEffectivenessDateRange: (val: string[]) => void;
  savingsEffectivenessTableView: boolean;
  setSavingsEffectivenessTableView: (val: boolean) => void;
  errorMessage?: string;
};

const SavingsEffectiveness = ({
  savingsEffectivenessData,
  requestStatus,
  pdfView,
  selectedTimeRange,
  setSelectedTimeRange,
  savingsEffectivenessDateRange,
  setSavingsEffectivenessDateRange,
  savingsEffectivenessTableView,
  setSavingsEffectivenessTableView,
  errorMessage,
}: SavingsEffectivenessProps) => {
  const { t } = useTranslation();
  const { selectedConnection } = useSelector(selectScorecard);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const [expandedRow, setExpandedRow] = useState(-1);

  const [timeRangeLabels, setTimeRangeLabels] = useState<string[]>([]);
  const [chartColors, setChartColors] = useState<string[]>([]);

  useEffect(() => {
    setChartColors(
      generateGraphColors(
        Object.keys(getCategoryEnum(selectedConnection?.provider)).length + 1
      )
    );
  }, [selectedConnection]);

  useEffect(() => {
    setTimeRangeLabels(
      getDateLabelsByGranularity(
        selectedTimeRange,
        savingsEffectivenessDateRange
      )
    );
  }, [selectedTimeRange, savingsEffectivenessDateRange]);

  /**
   * @function onChangeTimeRageOption
   * @description Callback function for time range select
   * @param value value selected
   */
  const onChangeTimeRageOption = (value: string) => {
    setSelectedTimeRange(value);
    if (value === SCORECARDS_TIMERANGE.MONTHLY.valueOf()) {
      setSavingsEffectivenessDateRange([
        moment()
          .subtract(5, 'months')
          .startOf('month')
          .format(HYPHEN_DATE_FORMAT),
        moment().format(HYPHEN_DATE_FORMAT),
      ]);
      return;
    }

    setSavingsEffectivenessDateRange([
      moment().subtract(8, 'days').startOf('day').format(HYPHEN_DATE_FORMAT),
      moment().subtract(2, 'days').format(HYPHEN_DATE_FORMAT),
    ]);
  };

  /**
   * @function onChangeSavingsEffectivenessMonthRange
   * @description Callback function for date range change for month picker
   * @param _dates Date ranges selected
   * @param dateString Date range in strings
   */
  const onChangeSavingsEffectivenessMonthRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    const startDate = moment(dateString[0])
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT);
    let endDate = moment(dateString[1])
      .endOf('month')
      .format(HYPHEN_DATE_FORMAT);
    if (
      moment(dateString[1]).format(MONTH_YEAR_FORMAT) ===
      moment().format(MONTH_YEAR_FORMAT)
    ) {
      endDate = moment().format(HYPHEN_DATE_FORMAT);
    }
    setSavingsEffectivenessDateRange([startDate, endDate]);
  };

  /**
   * @function onChangeSavingsEffectivenessDateRange
   * @description Callback function for date range change for date picker
   * @param _dates Date ranges selected
   * @param dateString Date range in strings
   */
  const onChangeSavingsEffectivenessDateRange = (
    dates: RangeValue<Moment>,
    _dateString: [string, string]
  ) => {
    if (!dates?.[0] || !dates?.[1]) {
      return;
    }

    const startDate = moment(dates[0], DATE_FORMAT)
      .startOf('day')
      .format(HYPHEN_DATE_FORMAT);
    const endDate = moment(dates[1], HYPHEN_DATE_FORMAT)
      .endOf('day')
      .format(HYPHEN_DATE_FORMAT);

    setSavingsEffectivenessDateRange([startDate, endDate]);
  };

  const getColumns = () => {
    const columns: ColumnProps<any>[] = [
      {
        title: t('scorecard.savingsEffectiveness.items'),
        align: 'left',
        dataIndex: 'item',
        fixed: 'left',
        key: 'item',
        width: 250,
      },
    ];
    timeRangeLabels.forEach((label) => {
      columns.push({
        title: label,
        dataIndex: label,
        align: 'center',
        key: label,
        render: (value) => numberCommaSeparator(value),
        width: 100,
      });
    });
    return columns;
  };

  const filters = (
    <div className="filters">
      <SelectDropdown
        value={selectedTimeRange}
        onChange={onChangeTimeRageOption}
        options={ScoreCardsTimeRangeOptions}
        designVersion2
        size={INPUT_SIZE.SMALL}
      />
      <DatePicker
        value={[
          moment(savingsEffectivenessDateRange[0], HYPHEN_DATE_FORMAT),
          moment(savingsEffectivenessDateRange[1], HYPHEN_DATE_FORMAT),
        ]}
        onChange={
          selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
            ? onChangeSavingsEffectivenessMonthRange
            : onChangeSavingsEffectivenessDateRange
        }
        disabledDate={(current: any) =>
          current > moment().subtract(2, 'days').endOf('day') ||
          current < moment().subtract(11, 'months')
        }
        picker={
          selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY ? 'month' : 'date'
        }
        format={
          selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
            ? MONTH_YEAR_FORMAT
            : DATE_FORMAT
        }
        designVersion2={true}
      />
    </div>
  );

  const getExpandedRowTable = (record: any) => (
    <Table
      pagination={false}
      columns={getColumns()}
      dataSource={getTableExpandedItemsData(
        selectedConnection?.provider,
        savingsEffectivenessData.savingsCostItems,
        timeRangeLabels,
        record.item
      ).map((data, index) => ({
        ...data,
        key: index,
      }))}
      designVersion2={true}
    />
  );

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = ({ expandable, expanded, onExpand, record }: any) =>
    expandable ? (
      <Icon
        className="collapse-icon"
        iconName={getRowExpandIcon(expanded)}
        size={ICONS_SIZE.LG}
        onClick={(e) => onExpand(record, e)}
      />
    ) : null;

  const getSummaryRowComponent = () => (
    <AntTable.Summary>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell
          className="footer"
          index={0}
          rowSpan={2}
          colSpan={2}
        >
          {t('scorecard.savingsEffectiveness.footer')}
        </AntTable.Summary.Cell>
        {savingsEffectivenessData.savingsEffectivenessPercentage.map(
          (item, index) => (
            <AntTable.Summary.Cell
              index={index + 2}
              align="center"
              key={item.date}
            >
              {`${numberCommaSeparator(item.percentage)}%`}
            </AntTable.Summary.Cell>
          )
        )}
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  const TableComponent = (
    <div className="table-view">
      <Table
        pagination={false}
        scroll={{ y: 'calc(100vh - 28em)' }}
        dataSource={getTableData(
          selectedConnection?.provider,
          getSavingsEffectivenessConsolidatedData(
            selectedConnection?.provider,
            savingsEffectivenessData
          ),
          timeRangeLabels
        ).map((data, index) => ({
          ...data,
          key: index,
        }))}
        rowClassName={(record: any) =>
          `${
            record.item !==
              GCP_SAVINGS_EFFECTIVENESS_ITEMS.COST_TO_ACHIEVE_SAVINGS.valueOf() &&
            'cursor-pointer'
          } ${expandedRow === record.key && 'expanded-row-item'}`
        }
        expandable={{
          rowExpandable: (record: any) =>
            record.item !==
            GCP_SAVINGS_EFFECTIVENESS_ITEMS.COST_TO_ACHIEVE_SAVINGS.valueOf(),
          columnWidth: 20,
          expandedRowRender: getExpandedRowTable,
          expandedRowKeys: [expandedRow],
          onExpand: (expanded: boolean, record: any) => {
            if (expanded) {
              setExpandedRow(record.key);
            } else {
              setExpandedRow(-1);
            }
          },
          expandRowByClick: true,
          expandIcon: getExpandIcon,
        }}
        columns={getColumns()}
        summary={getSummaryRowComponent}
        designVersion2={true}
      />
    </div>
  );

  const getTooltipContentOverride = useCallback(
    (title: string, data: any[]) => {
      return (
        <TooltipContent
          title={title}
          data={data.map((obj) => ({
            ...obj,
            suffix:
              obj.name ===
              t('scorecard.savingsEffectiveness.savingsEffectivenessPercentage')
                ? '%'
                : '',
            prefix:
              obj.name ===
              t('scorecard.savingsEffectiveness.savingsEffectivenessPercentage')
                ? ''
                : currencySymbol,
          }))}
        />
      );
    },
    [currencySymbol]
  );

  const ChartComponent = (
    <ColumnLineChart
      plots={[
        {
          type: 'line',
          options: {
            data: getSavingsEffectivenessConsolidatedData(
              selectedConnection?.provider,
              savingsEffectivenessData
            ),
            xField: 'date',
            yField: 'cost',
            seriesField: 'category',
            xAxis: {
              title: {
                text:
                  selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
                    ? t('scorecard.savingsEffectiveness.month')
                    : t('scorecard.savingsEffectiveness.date'),
              },
            },
            yAxis: {
              title: {
                text: t(
                  'scorecard.savingsEffectiveness.ratioCostOfWasteToTotalCost'
                ),
              },
              line: {
                style: {
                  stroke: COLORS.fnGrey,
                  lineWidth: 1,
                  opacity: 0.7,
                },
              },
            },
            point: {
              size: 5,
              shape: 'custom-point',
            },
            color: chartColors,
            animation: !pdfView,
          },
        },
        {
          type: 'area',
          options: {
            data: savingsEffectivenessData.savingsEffectivenessPercentage.map(
              (item) => ({
                ...item,
                groupingField: t(
                  'scorecard.savingsEffectiveness.savingsEffectivenessPercentage'
                ),
              })
            ),
            xField: 'date',
            yField: 'percentage',
            seriesField: 'groupingField',
            xAxis: {
              title: {
                text:
                  selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
                    ? t('scorecard.savingsEffectiveness.month')
                    : t('scorecard.savingsEffectiveness.date'),
              },
            },
            yAxis: {
              line: {
                style: {
                  stroke: COLORS.fnGrey,
                  lineWidth: 1,
                  opacity: 0.7,
                },
              },
              grid: null,
              position: 'right',
              title: {
                text: t(
                  'scorecard.savingsEffectiveness.savingsEffectivenessPercentage'
                ),
              },
            },
            meta: {
              value: {
                formatter: (v: any) => `${v}%`,
              },
            },
            smooth: true,
            color: chartColors.slice(-1),
            areaStyle: {
              fill: `l(270) 0:#ffffff 0.5:${chartColors.slice(
                -1
              )} 1:${chartColors.slice(-1)}`,
            },
            line: {
              style: {
                lineDash: [5, 5],
              },
            },
            animation: !pdfView,
          },
        },
      ]}
      markerOverride={(_name: any, _index: any, item: any) => {
        if (
          item.name ===
          t('scorecard.savingsEffectiveness.savingsEffectivenessPercentage')
        ) {
          return {
            symbol: 'hyphen',
            style: {
              lineDash: [2, 2],
            },
          };
        }
      }}
      tooltipContentOverride={getTooltipContentOverride}
    />
  );

  const getComponent = () => (
    <div className="graph">
      {savingsEffectivenessTableView ? TableComponent : ChartComponent}
    </div>
  );

  return (
    <div
      className={`savings-effectiveness graph-card ${
        savingsEffectivenessTableView && 'tabular-view'
      }`}
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.overallSavingsEffectiveness')}
        graphName="savings-effectiveness"
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        isTableViewSwitch={!pdfView}
        isTableView={savingsEffectivenessTableView}
        setIsTableView={setSavingsEffectivenessTableView}
        filters={filters}
        excelData={getSavingsEffectivenessExcelData(
          savingsEffectivenessData,
          selectedTimeRange,
          savingsEffectivenessDateRange,
          selectedConnection
        )}
        designVersion2={true}
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={requestStatus}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default SavingsEffectiveness;
