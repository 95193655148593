import i18n from 'i18n';

/**
 * @function getExcelExportColumns
 * @description Function return the recommendation excel columns
 * @param columns List of table columns
 * @returns List of columns for export
 */
const getExcelExportColumns = (columns: any[]) => {
  const tableColumns = columns
    .filter((item) => item.dataIndex !== 'ticketInfo')
    .map((item) => ({
      header: item.title,
      key: item.dataIndex,
      dataKey: item.dataIndex,
      width: 40,
      alignment: 'left',
    }));
  return [
    {
      header: '#',
      key: 'slNo',
      dataKey: 'slNo',
      width: 10,
      alignment: 'center',
    },
    ...tableColumns,
  ];
};

/**
 * @function getRecommendationTableExcelData
 * @description Function return the recommendation excel data for a sheet
 * @param connectorName Connector name of the selected connection
 * @param data List of data to be exported for each sheet, contains sheet name and the recommendations list
 * @returns List of oject containing the excel export data
 */
export const getRecommendationTableExcelData = (
  connectorName: string,
  data: {
    sheetName: string;
    recommendations: any[];
    columns: any[];
  }[]
) => {
  return data.map((item) => ({
    sheetName: item.sheetName,
    columns: getExcelExportColumns(item.columns),
    data: item.recommendations,
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  }));
};
