import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { selectTheme } from 'redux/themeSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import GraphHeader from 'components/GraphHeader';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import ColumnLineChart from 'components/ColumnLineChart';
import DashboardComponent from 'components/DashboardComponent';
import { MonthlyCostType } from 'types/dataTypes';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { REQUEST_STATUS } from 'constants/requestBody';

import { getCostTrendExcelData } from '../utils';

type CostTrendProps = {
  monthlyCost: MonthlyCostType[];
  costTrendRequestStatus: string;
  isCostTrendTableView: boolean;
  pdfView?: boolean;
  setIsCostTrendTableView: (value: boolean) => void;
  costTrendDateRange: string[];
  onChangeCostTrendDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

const CostTrend = ({
  monthlyCost,
  costTrendRequestStatus,
  isCostTrendTableView,
  pdfView,
  setIsCostTrendTableView,
  costTrendDateRange,
  onChangeCostTrendDateRange,
}: CostTrendProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { theme } = useSelector(selectTheme);

  const [activeLegends, setActiveLegends] = useState<string[]>([
    t('snowflakeDashboards.costWithoutSuffix'),
    t('snowflakeDashboards.trend'),
  ]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('snowflakeDashboards.month'),
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: t('snowflakeDashboards.costInCurrency', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'cost',
      key: 'cost',
      align: 'right' as const,
      render: (text: any) => currencySymbol + numberCommaSeparator(text),
    },
  ];

  const filters = (
    <div className="filters">
      <DatePicker
        defaultValue={[
          moment(costTrendDateRange[0]),
          moment(costTrendDateRange[1]),
        ]}
        onChange={onChangeCostTrendDateRange}
        picker="month"
        format={MONTH_YEAR_FORMAT}
        disabledDate={(current: any) => current > moment().endOf('day')}
      />
    </div>
  );

  /**
   * @function isDataIncluded
   * @description Function to check if the the active legends list includes the legend id
   * @param id Id to be validated
   * @returns true if the id is exists, else false
   */
  const isDataIncluded = useCallback(
    (id: string) => {
      return activeLegends.includes(id);
    },
    [activeLegends]
  );

  /**
   * @function getDataSource
   * @description returns the data with formatted type.
   * @returns costTrend data mapped with the type {month:string,cost:number,type:string}
   */
  const getData = useCallback(() => {
    const data: any[] = [];
    if (
      isDataIncluded(t('snowflakeDashboards.costWithoutSuffix')) ||
      isDataIncluded(t('snowflakeDashboards.trend'))
    ) {
      data.push(
        ...monthlyCost.map((item) => ({
          ...item,
          type: t('snowflakeDashboards.costWithoutSuffix'),
        }))
      );
    } else {
      data.push({
        month: null,
        cost: null,
        type: t('snowflakeDashboards.costWithoutSuffix'),
      });
    }

    return data;
  }, [monthlyCost, activeLegends]);

  /**
   * @function getLegendOverrides
   * @description Function to return the custom legends
   * @returns list of custom legends
   */
  const getLegendOverrides = useCallback(() => {
    return [
      {
        name: t('snowflakeDashboards.costWithoutSuffix'),
        unchecked: !isDataIncluded(t('snowflakeDashboards.costWithoutSuffix')),
        marker: {
          symbol: 'square',
          style: {
            fill: theme.primaryColor,
          },
        },
      },
      {
        name: t('snowflakeDashboards.trend'),
        unchecked: !isDataIncluded(t('snowflakeDashboards.trend')),
        marker: {
          symbol: 'hyphen',
          style: {
            lineWidth: 2,
            stroke: theme.secondaryColor,
          },
        },
      },
    ];
  }, [activeLegends]);

  /**
   * @function getColumnColor
   * @description Function to return the column color based on the selection
   * @param data data against which the color is fetched
   * @returns string color
   */
  const getColumnColor = useCallback(
    (data: any) => {
      if (!isDataIncluded(data.type)) {
        return 'transparent';
      }

      return theme.primaryColor;
    },
    [activeLegends]
  );

  /**
   * @function getLineColor
   * @description Function to return the line color
   * @returns string color
   */
  const getLineColor = useCallback(() => {
    return !isDataIncluded(t('snowflakeDashboards.trend'))
      ? 'transparent'
      : theme.secondaryColor;
  }, [activeLegends]);

  const getComponent = () => (
    <div className={`graph ${isCostTrendTableView && 'flex flex-gap-16'}`}>
      <div
        className={`graph-area ${
          isCostTrendTableView && 'with-table expand-hide'
        }`}
      >
        <ColumnLineChart
          data={getData()}
          xField="month"
          yField="cost"
          xTitle={t('snowflakeDashboards.month')}
          yTitle={t('snowflakeDashboards.costInCurrency', {
            currencySymbol: currencySymbol,
          })}
          groupingField="type"
          prefixSymbol={currencySymbol}
          disableAnimation={pdfView}
          legendOverride={getLegendOverrides()}
          columnColorOverride={getColumnColor}
          lineColorOverride={getLineColor}
          onClickLegends={(e: any) => {
            setActiveLegends(
              e.gEvent.delegateObject.component.cfg.items
                .filter(
                  (item: any) => item.unchecked === undefined || !item.unchecked
                )
                .map((item: any) => item.name)
            );
          }}
        />
      </div>
      {isCostTrendTableView && (
        <div className="tabular-view width-30">
          <Table
            pagination={false}
            loading={costTrendRequestStatus === REQUEST_STATUS.PROCESSING}
            dataSource={monthlyCost?.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            scroll={{ y: 190 }}
            designVersion2
            fillContainer
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      className="graph-card cost-trend flex flex-column flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.costTrend')}
        filters={filters}
        graphName="cost-trend"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostTrendTableView}
        setIsTableView={setIsCostTrendTableView}
        excelData={getCostTrendExcelData(monthlyCost, costTrendDateRange)}
        className="extended-filter"
        designVersion2
      />
      <DashboardComponent
        component={getComponent()}
        requestStatus={costTrendRequestStatus}
      />
    </div>
  );
};

export default CostTrend;
