import moment from 'moment';
import { HYPHEN_DATE_FORMAT, YEAR_MONTH_WITHOUT_SEPARATOR } from 'utils/date';
import { PROVIDER } from 'constants/cloudProviders';

/**
 * @function getDatesForTcoDashboard
 * @description function to get date for tco dashboard api for different providers
 * @param date input date
 * @param provider input provider
 * @returns date in corresponding formats supported by each provider
 */
export const getDatesForTcoDashboard = (date: string, provider: string) => {
  switch (provider) {
    case PROVIDER.GCP:
    case PROVIDER.OCI:
      return moment(date).format(YEAR_MONTH_WITHOUT_SEPARATOR);
    case PROVIDER.AWS:
      return `#DATE('${moment(date).format(HYPHEN_DATE_FORMAT)}')`;
    case PROVIDER.AZURE:
      return moment(date).format(HYPHEN_DATE_FORMAT);
  }
};
