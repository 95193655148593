import { Collapse } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';

import { TagMappingType, ConnectionsData } from 'pages/TagMappingPage/types';
import { getProviderSmallLogo } from 'utils/providerDetails';

import SelectedTagComponent from './components/SelectedTagComponent';

import './index.scss';

const { Panel } = Collapse;

type SelectedConnectionsTagsProps = {
  tagMapData: TagMappingType;
  setTagMapData: (val: TagMappingType) => void;
};

const SelectedConnectionsTags = ({
  tagMapData,
  setTagMapData,
}: SelectedConnectionsTagsProps) => {
  /**
   * @function findTagPriority
   * @description Function to find the priority of the tag selected
   * @param tag tag for which the priority is retrieved
   * @param connection Connection in which the tag is present
   * @returns integer priority of the tag if selected else undefined
   */
  const findTagPriority = (tag: string, connection: ConnectionsData) => {
    return connection.values.find((value) => value.fieldName === tag)?.priority;
  };

  /**
   * @function getConnectionsAfterUpdate
   * @description Function to update the connections with the tag selection
   * @param tag tag for to be updated (add or remove)
   * @param connection Connection to which the tag is updated
   * @param priority priority of the tag updated
   * @returns List of updated connections
   */
  const getConnectionsAfterUpdate = (
    tag: string,
    connection: ConnectionsData,
    priority?: number
  ) => {
    const connectionsList = [...tagMapData.cloudMapList];
    const connectionIndex = connectionsList.findIndex(
      (item) => item.connectionId === connection.connectionId
    );
    const existingConnection = connectionsList.find(
      (item) => item.connectionId === connection.connectionId
    );

    // Remove tag when exists and remove connection when no tag selected
    if (priority && existingConnection) {
      const fields = [...existingConnection.values];
      fields.splice(
        fields.findIndex((item) => item.fieldName === tag),
        1
      );

      if (fields.length > 0) {
        connectionsList.splice(connectionIndex, 1, {
          ...existingConnection,
          values: fields.map((item, index) => ({
            ...item,
            priority: index + 1,
          })),
        });
      } else {
        connectionsList.splice(connectionIndex, 1);
      }

      return connectionsList;
    }

    // Add tag for an existing connection
    if (existingConnection) {
      connectionsList.splice(connectionIndex, 1, {
        ...existingConnection,
        values: [
          ...existingConnection.values,
          { fieldName: tag, priority: existingConnection.values.length + 1 },
        ],
      });

      return connectionsList;
    }

    // Add connection when there is no connection
    connectionsList.splice(connectionsList.length, 1, {
      connectionName: connection.connectionName,
      connectionId: connection.connectionId,
      providerName: connection.providerName,
      values: [{ fieldName: tag, priority: 1 }],
    });

    return connectionsList;
  };

  /**
   * @function onClickTag
   * @description Callback function for selection and deselection of the tag
   * @param tag Tag clicked
   * @param connection Connection to which the tag belongs to
   */
  const onClickTag = (tag: string, connection: ConnectionsData) => {
    const priority = findTagPriority(tag, connection);
    const connectionList = getConnectionsAfterUpdate(tag, connection, priority);
    setTagMapData({
      ...tagMapData,
      cloudMapList: connectionList,
    });
  };

  /**
   * @function getSelectedTagsCountByConnection
   * @description Function to retrieve the selected tags count for a connection
   * @param connectionName Name of the connection for which the count is retrieved
   * @returns Tags count
   */
  const getSelectedTagsCountByConnection = (connectionName: string) => {
    return (
      tagMapData.cloudMapList.find(
        (item) => item.connectionName === connectionName
      )?.values.length ?? 0
    );
  };

  /**
   * @function handleOnDragEnd
   * @description Updates the selected dimensions list based on the drag and drop
   * @param result - result of the drag and drop
   * @param connection connection for which the tag is dragged
   */
  const handleOnDragEnd = (result: any, connection: ConnectionsData) => {
    if (!result.destination) return;

    const updatedTags = [...connection.values];
    const [removed] = updatedTags.splice(result.source.index, 1);
    updatedTags.splice(result.destination.index, 0, removed);

    const connectionsList = [...tagMapData.cloudMapList];
    const connectionIndex = connectionsList.findIndex(
      (item) => item.connectionName === connection.connectionName
    );
    connectionsList.splice(connectionIndex, 1, {
      ...connection,
      values: updatedTags.map((item, index) => ({
        ...item,
        priority: index + 1,
      })),
    });
    setTagMapData({
      ...tagMapData,
      cloudMapList: connectionsList,
    });
  };

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="collapse-arrow" />
    );

  return (
    <div className="selected-connection-tag-dropdown">
      <Collapse
        className="connection-tag-collapse new-styled-scroll full-width flex flex-column"
        accordion={true}
        bordered={false}
        expandIcon={getExpandIcon}
      >
        {tagMapData.cloudMapList.map((item) => (
          <Panel
            className="connection-tag-panel"
            header={
              <div className="flex flex-align-items-center flex-gap-8">
                <div>
                  <img
                    src={getProviderSmallLogo(item.providerName)}
                    alt={`${item.providerName} Logo`}
                  />
                </div>
                <span className="table-typography">{item.connectionName}</span>
              </div>
            }
            key={item.connectionName}
            extra={
              getSelectedTagsCountByConnection(item.connectionName) ? (
                <div className="font-small-bold flex flex-center values-count">
                  {getSelectedTagsCountByConnection(item.connectionName)}
                </div>
              ) : null
            }
          >
            <DragDropContext
              onDragEnd={(result) => handleOnDragEnd(result, item)}
            >
              <Droppable droppableId="tags">
                {(provided) => (
                  <ul
                    className="draggable-tags-list table-typography flex flex-column flex-gap-4"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {item.values.map((tag, index) => (
                      <Draggable
                        key={tag.fieldName}
                        draggableId={tag.fieldName}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <SelectedTagComponent
                              tag={tag.fieldName}
                              priority={findTagPriority(tag.fieldName, item)}
                              onClickTag={() => onClickTag(tag.fieldName, item)}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default SelectedConnectionsTags;
