import i18n from 'i18n';
import { ApplicationDetailsDataType } from '../../type';
import { ApplicationDeatilsExportColumns } from './constants';

export const getApplicationDetailsExcelData = (
  tableData: ApplicationDetailsDataType[],

  filters: any[]
) => {
  return {
    sheetName: i18n.t(
      'consolidatedDashboard.applicationDetails.applicationDetails'
    ),
    columns: ApplicationDeatilsExportColumns,
    data: tableData.map((data, index: number) => ({
      ...data,
      cpuUtilisation: `${data.cpuUtilisation.toFixed(4)} cores`,
      memoryUtilisation: `${data.memoryUtilisation.toFixed(4)} Mi`,
      costAfterCredit: (data.costAfterCredit ?? 0).toFixed(2),
      slNo: index + 1,
    })),
    filters: filters,
  };
};
