import i18n from 'i18n';

import { store } from 'redux/store';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  PurchaseAwsEc2RisType,
  PurchaseAwsOpenSearchRisType,
  PurchaseAwsElastiCacheRisType,
  PurchaseAwsMemoryDbRisType,
  PurchaseAwsRdsRisType,
  PurchaseAwsRedshiftRisType,
} from 'pages/PurchasePage/components/ReservedInstances/types';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { AWS_SERVICES_REQUEST_FIELDS } from './constants';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

/**
 * @function getSubTotalDue
 * @description Function to calculate the sub total due of a purchase
 * @param term The term purchased for in years
 * @param hourlyCharge charge per hour
 * @returns sub total due amount calculated
 */
export const getSubTotalDue = (
  term: number,
  hourlyCharge: string | number | undefined
) => {
  if (hourlyCharge && term) {
    return Number(hourlyCharge) * term * 365 * 24;
  } else {
    return 0;
  }
};

/**
 * @function getAverageMonthlyRecurringCost
 * @description Function to calculate the average monthly recurring cost for the purchase
 * @param term The term purchased for in years
 * @param hourlyCharge charge per hour
 * @param oneTimePayment One time payment cost done
 * @returns monthly payment amount calculated
 */
export const getAverageMonthlyRecurringCost = (
  term: number,
  hourlyCharge: string | number | undefined
) => {
  return getSubTotalDue(term, hourlyCharge) / (term * 12);
};

/**
 * @function getRiTotalDue
 * @description Function to calculate the total due for the cart
 * @param cartData cart data
 * @returns total due amount calculated
 */
export const getRiTotalDue = (
  cartData: (
    | PurchaseAwsRdsRisType
    | PurchaseAwsRedshiftRisType
    | PurchaseAwsOpenSearchRisType
    | PurchaseAwsMemoryDbRisType
    | PurchaseAwsElastiCacheRisType
  )[]
) => {
  return cartData.reduce(
    (sum, item) => sum + getSubTotalDue(item.term, item.hourlyUsageCharge),
    0
  );
};

/**
 * @function getRiTotalMonthlyRecurringCost
 * @description Function to calculate the total monthly recurring cost for the cart
 * @param cartData cart data
 * @returns total monthly recurring cost calculated
 */
export const getRiTotalMonthlyRecurringCost = (
  cartData: (
    | PurchaseAwsRdsRisType
    | PurchaseAwsRedshiftRisType
    | PurchaseAwsOpenSearchRisType
    | PurchaseAwsMemoryDbRisType
    | PurchaseAwsElastiCacheRisType
  )[]
) => {
  return cartData.reduce(
    (sum, item) =>
      sum + getAverageMonthlyRecurringCost(item.term, item.hourlyUsageCharge),
    0
  );
};

/**
 * @function getTotalOnetimePayment
 * @description Function to calculate the total one time payment cost for the cart
 * @param cartData cart data
 * @returns total upfront cost calculated
 */
export const getTotalOnetimePayment = (
  cartData: (
    | PurchaseAwsRdsRisType
    | PurchaseAwsEc2RisType
    | PurchaseAwsRedshiftRisType
    | PurchaseAwsOpenSearchRisType
    | PurchaseAwsMemoryDbRisType
    | PurchaseAwsElastiCacheRisType
  )[]
) => {
  return cartData.reduce((sum, item) => sum + (item.oneTimePayment || 0), 0);
};

/**
 * @function convertYearToSeconds
 * @description Function to convert year to seconds
 * @param year year to convert
 * @returns seconds calculated
 */
export const convertYearToSeconds = (year: number) => {
  return year * 365 * 24 * 60 * 60;
};

export const getDefaultRiMetricCards = () => {
  return [
    {
      value: 0,
      heading: i18n.t('purchasePage.recommendedReservationPurchases'),
      requestStatus: [REQUEST_STATUS.PROCESSING],
      valueMinimumFractionDigits: 0,
    },
    {
      value: 0,
      heading: i18n.t('purchasePage.newEstimatedTotalCost'),
      requestStatus: [REQUEST_STATUS.PROCESSING],
      valuePrefix: currencySymbol,
    },
    {
      value: 0,
      heading: i18n.t('purchasePage.estimatedNetSavings'),
      requestStatus: [REQUEST_STATUS.PROCESSING],
      valuePrefix: currencySymbol,
      valueMaximumFractionDigits: 2,
    },
    {
      value: 0,
      heading: i18n.t('purchasePage.estimatedSavingsRate'),
      requestStatus: [REQUEST_STATUS.PROCESSING],
      valueSuffix: '%',
      valueMaximumFractionDigits: 2,
    },
  ];
};

export const getRecommendedRIsColumns = (service: string) => {
  switch (service) {
    case AWS_SERVICES_REQUEST_FIELDS.EC2:
      return [
        {
          title: i18n.t('purchasePage.instance'),
          dataIndex: 'instanceType',
          key: 'instanceType',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.risk'),
          dataIndex: 'risk',
          key: 'risk',
          width: 150,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.region'),
          dataIndex: 'location',
          key: 'location',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.os'),
          dataIndex: 'os',
          key: 'os',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.tenancy'),
          dataIndex: 'tenancy',
          key: 'tenancy',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.quantity'),
          dataIndex: 'recommendedInstanceQuantityPurchase',
          key: 'recommendedInstanceQuantityPurchase',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: `${i18n.t(
            'purchasePage.estimatedMonthlyOnDemandCost'
          )} (${currencySymbol})`,
          dataIndex: 'estimatedMonthlyOnDemandCost',
          key: 'estimatedMonthlyOnDemandCost',
          width: 200,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: `${i18n.t('purchasePage.upfrontCost')} (${currencySymbol})`,
          dataIndex: 'upfrontCost',
          key: 'upfrontCost',
          width: 200,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.recurringMonthlyCost'),
          dataIndex: 'recurringMonthlyCost',
          key: 'recurringMonthlyCost',
          width: 200,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.breakEvenMonths'),
          dataIndex: 'breakEvenMonths',
          key: 'breakEvenMonths',
          width: 200,
          render: (text: string) => numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: `${i18n.t(
            'purchasePage.estimatedMonthlySavings'
          )} (${currencySymbol})`,
          dataIndex: 'estimatedMonthlySavings',
          key: 'estimatedMonthlySavings',
          width: 230,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
      ];

    case AWS_SERVICES_REQUEST_FIELDS.RDS:
      return [
        {
          title: i18n.t('purchasePage.instance'),
          dataIndex: 'instanceType',
          key: 'instanceType',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.risk'),
          dataIndex: 'risk',
          key: 'risk',
          width: 150,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.region'),
          dataIndex: 'location',
          key: 'location',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.databaseEngine'),
          dataIndex: 'os',
          key: 'os',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.family'),
          dataIndex: 'tenancy',
          key: 'tenancy',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.quantity'),
          dataIndex: 'recommendedInstanceQuantityPurchase',
          key: 'recommendedInstanceQuantityPurchase',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: `${i18n.t(
            'purchasePage.estimatedMonthlyOnDemandCost'
          )} (${currencySymbol})`,
          dataIndex: 'estimatedMonthlyOnDemandCost',
          key: 'estimatedMonthlyOnDemandCost',
          width: 200,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: `${i18n.t('purchasePage.upfrontCost')} (${currencySymbol})`,
          dataIndex: 'upfrontCost',
          key: 'upfrontCost',
          width: 200,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.recurringMonthlyCost'),
          dataIndex: 'recurringMonthlyCost',
          key: 'recurringMonthlyCost',
          width: 200,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.breakEvenMonths'),
          dataIndex: 'breakEvenMonths',
          key: 'breakEvenMonths',
          width: 200,
          render: (text: string) => numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: `${i18n.t(
            'purchasePage.estimatedMonthlySavings'
          )} (${currencySymbol})`,
          dataIndex: 'estimatedMonthlySavings',
          key: 'estimatedMonthlySavings',
          width: 230,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
      ];

    default:
      return [
        {
          title: i18n.t('purchasePage.nodeType'),
          dataIndex: 'instanceType',
          key: 'instanceType',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.risk'),
          dataIndex: 'risk',
          key: 'risk',
          width: 150,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.region'),
          dataIndex: 'location',
          key: 'location',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.productDescription'),
          dataIndex: 'os',
          key: 'os',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.family'),
          dataIndex: 'tenancy',
          key: 'tenancy',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.quantity'),
          dataIndex: 'recommendedInstanceQuantityPurchase',
          key: 'recommendedInstanceQuantityPurchase',
          width: 200,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: `${i18n.t(
            'purchasePage.estimatedMonthlyOnDemandCost'
          )} (${currencySymbol})`,
          dataIndex: 'estimatedMonthlyOnDemandCost',
          key: 'estimatedMonthlyOnDemandCost',
          width: 200,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: `${i18n.t('purchasePage.upfrontCost')} (${currencySymbol})`,
          dataIndex: 'upfrontCost',
          key: 'upfrontCost',
          width: 200,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.recurringMonthlyCost'),
          dataIndex: 'recurringMonthlyCost',
          key: 'recurringMonthlyCost',
          width: 200,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: i18n.t('purchasePage.breakEvenMonths'),
          dataIndex: 'breakEvenMonths',
          key: 'breakEvenMonths',
          width: 200,
          render: (text: string) => numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
        {
          title: `${i18n.t(
            'purchasePage.estimatedMonthlySavings'
          )} (${currencySymbol})`,
          dataIndex: 'estimatedMonthlySavings',
          key: 'estimatedMonthlySavings',
          width: 230,
          render: (text: string) => currencySymbol + numberCommaSeparator(text),
          sorter: true,
          showSorterTooltip: false,
        },
      ];
  }
};
