import i18n from 'i18n';

import { CMDBListType } from 'pages/CmdbSetupListPage/types';

export enum CmdbConfigurationSteps {
  BASIC_INFORMATION,
  ACCESS_DETAILS,
  FIELD_MAPPING,
}

export const CMDB_PROGRESS_BAR_CONTENT = [
  {
    title: i18n.t('provideBasicInformation'),
    description: i18n.t('provideBasicInformationSubHeading'),
  },
  {
    title: i18n.t('grantingAccess'),
    description: i18n.t('configureAccessSubHeading'),
  },
  {
    title: i18n.t('cmdbFieldMapping'),
    description: i18n.t('configureCmdbFieldToTagsMapping'),
  },
];

export const CMDB_DEFAULT_DATA: CMDBListType = {
  name: '',
  dataSourceProvider: '',
  connectionUrl: '',
  database: '',
  fieldTagsMappings: [],
};
