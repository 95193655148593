import i18n from 'i18n';

import { RecommendationDetailsListType } from './types';

export const CONSUMPTION_MANAGEMENT_COMMON_RECOMMENDATION_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.accountId'),
      field: 'accountId',
    },
    {
      label: i18n.t(
        'costOptimizationInsight.recommendationTable.recommendationSource'
      ),
      field: 'recommendationSource',
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.resource'),
      field: 'resource',
    },
    {
      label: i18n.t(
        'costOptimizationInsight.recommendationTable.recommendation'
      ),
      field: 'recommendation',
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.ticketStatus'),
      field: 'serviceNowStatus',
      isComponent: true,
    },
    {
      label: i18n.t(
        'costOptimizationInsight.recommendationTable.approvalStatus'
      ),
      field: 'incidentRecommendationStatus',
      isComponent: true,
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.recStatus'),
      field: 'recStatus',
      isComponent: true,
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.timeStamp'),
      field: 'timeStamp',
    },
  ];

export const GCP_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.priority'),
      field: 'priority',
    },
    {
      label: i18n.t(
        'costOptimizationInsight.recommendationTable.recommendationId'
      ),
      field: 'recommendationId',
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.type'),
      field: 'type',
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.category'),
      field: 'category',
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.costSavings'),
      field: 'costSavings',
      isCostField: true,
    },
  ];

export const AZURE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t(
        'costOptimizationInsight.recommendationTable.recommendationProblem'
      ),
      field: 'recommendationProblem',
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.recStatus'),
      field: 'recStatus',
      isComponent: true,
    },
    {
      label: i18n.t(
        'costOptimizationInsight.recommendationTable.impactedField'
      ),
      field: 'impactedField',
    },
    {
      label: i18n.t(
        'costOptimizationInsight.recommendationTable.impactedValue'
      ),
      field: 'impactedValue',
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.type'),
      field: 'type',
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.category'),
      field: 'category',
    },
    {
      label: i18n.t('costOptimizationInsight.recommendationTable.costSavings'),
      field: 'costSavings',
      isCostField: true,
    },
  ];

export const AWS_UNDERUTILIZED_ENDPOINTS_FIELD: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('awsRecommendationTableLabels.accountName'),
      field: 'accountName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.region'),
      field: 'region',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.provisionedInferenceUnit'),
      field: 'provisionedInferenceUnit',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.autoScalingStatus'),
      field: 'autoScalingStatus',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.reason'),
      field: 'reason',
    },
  ];

export const AWS_OVERPROVISIONED_EBS_VOLUMES_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('awsRecommendationTableLabels.risk'),
      field: 'risk',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.priority'),
      field: 'priority',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.accountName'),
      field: 'accountName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.region'),
      field: 'region',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.volumeType'),
      field: 'volumeType',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.volumeSizeGb'),
      field: 'volumeSizeGb',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.volumeBaselineIops'),
      field: 'volumeBaselineIops',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.volumeBurstIops'),
      field: 'volumeBurstIops',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.volumeBurstThroughput'),
      field: 'volumeBurstThroughput',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.recommendedVolumeType'),
      field: 'recommendedVolumeType',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.recommendedVolumeSizeGb'),
      field: 'recommendedVolumeSizeGb',
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.recommendedVolumeBaselineIops'
      ),
      field: 'recommendedVolumeBaselineIops',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.recommendedVolumeBurstIops'),
      field: 'recommendedVolumeBurstIops',
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.recommendedVolumeBaselineThroughput'
      ),
      field: 'recommendedVolumeBaselineThroughput',
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.recommendedVolumeBurstThroughput'
      ),
      field: 'recommendedVolumeBurstThroughput',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.lookbackPeriodDays'),
      field: 'lookbackPeriodDays',
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.savingsOpportunityPercentage'
      ),
      field: 'savingsOpportunityPercentage',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.estimatedMonthlySavings'),
      field: 'estimatedMonthlySavings',
      isCostField: true,
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.estimatedMonthlySavingsCurrency'
      ),
      field: 'estimatedMonthlySavingsCurrency',
    },
  ];

export const AWS_EC2_INSTANCES_CONSOLIDATION_MSSQL_SERVER_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('awsRecommendationTableLabels.accountName'),
      field: 'accountName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.region'),
      field: 'region',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.instanceType'),
      field: 'instanceType',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.vCpu'),
      field: 'vCpu',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.minimumVCpu'),
      field: 'minimumVCpu',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.sqlServerEdition'),
      field: 'sqlServerEdition',
    },
  ];

export const AWS_EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('awsRecommendationTableLabels.risk'),
      field: 'risk',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.priority'),
      field: 'priority',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.accountName'),
      field: 'accountName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.region'),
      field: 'region',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.instanceType'),
      field: 'instanceType',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.vCpu'),
      field: 'vCpu',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.sqlServerEdition'),
      field: 'sqlServerEdition',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.maximumVCpu'),
      field: 'maximumVCpu',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.recommendedInstanceType'),
      field: 'recommendedInstanceType',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.estimatedMonthlySavings'),
      field: 'estimatedMonthlySavings',
      isCostField: true,
    },
  ];

export const AWS_ROUTE_53_LATENCY_RECORD_SETS_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('awsRecommendationTableLabels.accountName'),
      field: 'accountName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.hostedZoneId'),
      field: 'hostedZoneId',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.resourceRecordSetName'),
      field: 'resourceRecordSetName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.resourceRecordSetType'),
      field: 'resourceRecordSetType',
    },
  ];

export const AWS_LAMBDA_FUNCTIONS_EXCESSIVE_TIMEOUT_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('awsRecommendationTableLabels.accountName'),
      field: 'accountName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.status'),
      field: 'status',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.region'),
      field: 'region',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.maxDailyTimeoutRate'),
      field: 'maxDailyTimeoutRate',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.dateOfMaxDailyTimeoutRate'),
      field: 'dateOfMaxDailyTimeoutRate',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.averageDailyTimeoutRate'),
      field: 'averageDailyTimeoutRate',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.functionTimeoutSettings'),
      field: 'functionTimeoutSettings',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.lostDailyComputeCost'),
      field: 'lostDailyComputeCost',
      isCostField: true,
    },
    {
      label: i18n.t('awsRecommendationTableLabels.averageDailyInvokes'),
      field: 'averageDailyInvokes',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.currentDayInvokes'),
      field: 'currentDayInvokes',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.currentDayTimeoutRate'),
      field: 'currentDayTimeoutRate',
    },
  ];

export const AWS_LAMBDA_FUNCTIONS_HIGH_ERROR_RATES_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('awsRecommendationTableLabels.accountName'),
      field: 'accountName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.status'),
      field: 'status',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.region'),
      field: 'region',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.maxDailyErrorRate'),
      field: 'maxDailyErrorRate',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.dateForMaxErrorRate'),
      field: 'dateForMaxErrorRate',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.averageDailyErrorRate'),
      field: 'averageDailyErrorRate',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.lostDailyComputeCost'),
      field: 'lostDailyComputeCost',
      isCostField: true,
    },
    {
      label: i18n.t('awsRecommendationTableLabels.averageDailyInvokes'),
      field: 'averageDailyInvokes',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.currentDayInvokes'),
      field: 'currentDayInvokes',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.currentDayErrorRate'),
      field: 'currentDayErrorRate',
    },
  ];

export const AWS_LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('awsRecommendationTableLabels.risk'),
      field: 'risk',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.priority'),
      field: 'priority',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.accountName'),
      field: 'accountName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.status'),
      field: 'status',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.region'),
      field: 'region',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.functionVersion'),
      field: 'functionVersion',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.memorySize'),
      field: 'memorySize',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.recommendedMemorySize'),
      field: 'recommendedMemorySize',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.lookbackPeriod'),
      field: 'lookbackPeriod',
      isCostField: true,
    },
    {
      label: i18n.t('awsRecommendationTableLabels.savingsOpportunity'),
      field: 'savingsOpportunity',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.estimatedMonthlySavings'),
      field: 'estimatedMonthlySavings',
      isCostField: true,
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.estimatedMonthlySavingsCurrency'
      ),
      field: 'estimatedMonthlySavingsCurrency',
    },
  ];

export const AWS_WELL_ARCHITECTED_HRI_COST_OPTIMIZATION_PILLAR_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('awsRecommendationTableLabels.accountName'),
      field: 'accountName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.status'),
      field: 'status',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.region'),
      field: 'region',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.workloadName'),
      field: 'workloadName',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.workloadType'),
      field: 'workloadType',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.workloadStartedDate'),
      field: 'workloadStartedDate',
    },
    {
      label: i18n.t('awsRecommendationTableLabels.workloadLastModifiedDate'),
      field: 'workloadLastModifiedDate',
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.numberOfIdentifiedHrisForCostOptimization'
      ),
      field: 'numberOfIdentifiedHrisForCostOptimization',
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.numberOfHrisResolvedForCostOptimization'
      ),
      field: 'numberOfHrisResolvedForCostOptimization',
      isCostField: true,
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.numberOfQuestionsAnsweredForCostOptimization'
      ),
      field: 'numberOfQuestionsAnsweredForCostOptimization',
    },
    {
      label: i18n.t(
        'awsRecommendationTableLabels.totalNumberOfQuestionsInCostOptimizationPillar'
      ),
      field: 'totalNumberOfQuestionsInCostOptimizationPillar',
    },
  ];

export const AWS_RIGHT_SIZING_EC2_FIELDS: RecommendationDetailsListType[] = [
  {
    label: i18n.t('awsRecommendationTableLabels.risk'),
    field: 'risk',
  },
  {
    label: i18n.t('awsRecommendationTableLabels.priority'),
    field: 'priority',
  },
  {
    label: i18n.t('awsRecommendationTableLabels.instanceId'),
    field: 'instanceId',
  },
  {
    label: i18n.t('awsRecommendationTableLabels.instanceType'),
    field: 'instanceType',
  },
  {
    label: i18n.t('awsRecommendationTableLabels.recommendedInstanceType'),
    field: 'recommendedInstanceType',
  },
  {
    label: i18n.t('awsRecommendationTableLabels.cpuUtilization'),
    field: 'cpuUtilization',
    isPercent: true,
  },
  {
    label: i18n.t('awsRecommendationTableLabels.estimatedSavings'),
    field: 'estimatedSavings',
    isCostField: true,
  },
];

export const GRANULATE_CONSUMPTION_MANAGEMENT_RECOMMENDATION_FIELDS: RecommendationDetailsListType[] =
  [
    {
      label: i18n.t('granulateInsightDashboard.namespace'),
      field: 'namespace',
    },
    {
      label: i18n.t('granulateInsightDashboard.containers'),
      field: 'containers',
      isComponent: true,
    },
    {
      label: i18n.t('granulateInsightDashboard.hpaPolicy'),
      field: 'hpaPolicy',
      isComponent: true,
    },
    {
      label: i18n.t('granulateInsightDashboard.cpuRequest'),
      field: 'cpuRequest',
      isLabelComponent: true,
      isComponent: true,
    },
    {
      label: i18n.t('granulateInsightDashboard.memoryRequest'),
      field: 'memoryRequest',
      isLabelComponent: true,
      isComponent: true,
    },
    {
      label: i18n.t('granulateInsightDashboard.cpuReduction'),
      field: 'cpuReduction',
      isComponent: true,
    },
    {
      label: i18n.t('granulateInsightDashboard.memoryReduction'),
      field: 'memoryReduction',
      isComponent: true,
    },
    {
      label: i18n.t('granulateInsightDashboard.costImpact', {
        currencySymbol: '$',
      }),
      field: 'costImpact',
      isCostField: true,
    },
    {
      label: i18n.t('granulateInsightDashboard.yaml'),
      field: 'yaml',
      isComponent: true,
    },
  ];
