import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import {
  Col,
  Empty,
  message,
  Radio,
  Select,
  Table,
  TablePaginationConfig,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { FilterDropdownProps } from 'antd/lib/table/interface';

import TableFilterDropdown from 'components/TableFilterDropdown';
import {
  setServiceNowData,
  setTicketIntegrationData,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';
import {
  costOptimizationInsights,
  setCurrentRecommendationView,
  setSelectedRecommendations,
} from 'redux/costOptimizationInsightsSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import { selectTheme } from 'redux/themeSlice';
import InfiniteScrollTable from 'components/InfiniteScrollTable';
import QuickActionMenu from 'components/QuickActionMenu';
import Button from 'components/Button';
import SelectDropdown from 'components/Select';
import ExpandModal from 'components/ExpandModal';
import { INCIDENT_TYPES } from 'components/CreateTicketDrawer/constants';
import Loader from 'components/Loader';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import {
  defaultFormData,
  ServiceNowDefaultData,
} from 'pages/TicketIntegrationPage/types';
import {
  DEFAULT_VIEW,
  DEFAULT_VIEW_DROPDOWN_OPTIONS,
  RECOMMENDATION_CATEGORIES_LABELS,
  RECOMMENDATION_STATUS_LABELS,
  RECOMMENDATION_SUBTYPE_LABELS,
  RecommendationTableActions,
  RecommendationStatus,
  RECOMMENDATION_TABLE_QUICK_ACTIONS,
  FilterKeys,
  COST_OPTIMIZATION_INSIGHTS_NAV,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  getAllSnowIncidentDetails,
  getRecommendationViews,
  deleteRecommendationViews,
} from 'pages/CostOptimizationInsightsPage/services';
import {
  IncidentRecommendationMappingType,
  StatusFilterType,
  CategoryRecommenderMappingType,
  ViewListType,
  AzureRecommendationListType,
  RecommendationFiltersType,
} from 'pages/CostOptimizationInsightsPage/types';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  compareToValue,
  getLabelledFilters,
  getStatusTextClassName,
  hasRulesetFilters,
  numberArraySortFunction,
} from 'pages/CostOptimizationInsightsPage/utils';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  getChartData,
  getFilteredRecommendations,
  getRulesetFilters,
  getTicketConnection,
  createSnowIncident,
} from 'utils/services';
import { DATE_TIME_AM_PM } from 'utils/date';
import {
  ServiceNowStatus,
  SERVICE_NOW_STATUS_LABELS,
  IncidentRecommendationStatusLabels,
  TICKET_RECOMMENDATION_STATUS,
  ORDERED_SERVICE_NOW_STATUS_LIST,
} from 'constants/workflowIntegrations';
import { INFINITE_SCROLL_PAGE_SIZE } from 'constants/userConsole';
import { FilterGroupType } from 'types/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { BUTTON_SIZE } from 'constants/appearance';
import { selectDashboard } from 'redux/dashboardSlice';
import { UNKNOWN_VALUE } from 'constants/utilityConstants';
import { SortType } from 'types/dataTypes';

import CreateTicketDrawer from '../../../../../components/CreateTicketDrawer';
import GraphHeader from '../../GraphHeader';
import Filters from '../../Filters';
import SelectedFilters from '../../SelectedFilters';
import TicketInfoDrawer from '../../../../../components/TicketInfoDrawer';
import {
  getAzureRecommendationTableDefaultQuery,
  getAzureRulesetFilterRecommendationQuery,
  getAzureRulesetFilterRecommendationCountQuery,
  getAzureDefaultRecommendationCountQuery,
  getAzureRecommendationTableExcelData,
} from './utils';
import {
  getAzureCategoryRecommenderMapping,
  applyAzureRecommendations,
} from './services';
import { fetchRecommendationFilters } from '../../ConsolidatedRecommendationTable/components/RecommendationsHeader/utils';

const RecommendationTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showExpandGraphModal } = useSelector(selectDashboard);
  const {
    selectedRecommendations,
    selectedCostOptimizationInsightsConnection,
    currentRecommendationView,
    selectedCostOptimizationInsightsNav,
  } = useSelector(costOptimizationInsights);
  const { permissions } = useSelector(userAuthorization);
  const { theme } = useSelector(selectTheme);
  const { serviceNowData } = useSelector(ticketIntegration);

  const [recommendationData, setRecommendationData] = useState<
    AzureRecommendationListType[]
  >([]);
  const [nextPageRecommendationData, setNextPageRecommendationData] = useState<
    AzureRecommendationListType[]
  >([]);
  const [recommendationTableData, setRecommendationTableData] = useState<
    AzureRecommendationListType[]
  >([]);
  const [filteredRecommendationData, setFilteredRecommendationData] = useState<
    AzureRecommendationListType[]
  >([]);
  const [allRecommendationData, setAllRecommendationData] = useState<
    AzureRecommendationListType[]
  >([]);
  const [serviceNowTickets, setServiceNowTickets] = useState<
    IncidentRecommendationMappingType[]
  >([]);
  const [snowTicketsRequestStatus, setSnowTicketsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [viewList, setViewList] = useState<ViewListType[]>();
  const [viewListRequestStatus, setViewListRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [rulesetFilters, setRulesetFilters] = useState<FilterGroupType[]>([]);
  const [rulesetFiltersRequestStatus, setRulesetFiltersRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [hasTicketIntegration, setHasTicketIntegration] = useState(false);
  const [fetchingDefaultTicketData, setFetchingDefaultTicketData] =
    useState(true);
  const [showCreateTicketDrawer, setShowCreateTicketDrawer] = useState(false);
  const [showTicketInfoDrawer, setShowTicketInfoDrawer] = useState(false);
  const [recommendationRequestStatus, setRecommendationRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [deleteViewRequestStatus, setDeleteViewRequestStatus] = useState('');
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<AzureRecommendationListType>();
  const [loadApplyRecommendation, setLoadApplyRecommendation] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [statusFilters, setStatusFilters] = useState<StatusFilterType>({
    incidentStatus: [],
    incidentRecommendationStatus: [],
    recommendationStatus: [],
  });
  const [sortApplied, setSortApplied] = useState<SortType>({
    key: '',
    order: undefined,
  });
  const [selectedSnowStatus, setSelectedSnowStatus] = useState(
    ServiceNowStatus.TICKET_TBC
  );
  const [categoryRecommenderMapping, setCategoryRecommenderMapping] =
    useState<CategoryRecommenderMappingType[]>();
  const [recommendationFilters, setRecommendationFilters] = useState<
    RecommendationFiltersType[]
  >([]);
  const [labelledRecommendationFilters, setLabelledRecommendationFilters] =
    useState<RecommendationFiltersType[]>([]);
  const [
    recommendationFiltersRequestStatus,
    setRecommendationFiltersRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [createTicketLoading, setCreateTicketLoading] = useState(false);
  const [createTicketValidationMessage, setCreateTicketValidationMessage] =
    useState('');

  useEffect(() => {
    fetchServiceNowConnection();
  }, []);

  useEffect(() => {
    setTotalCount(0);
    setRecommendationData([]);
    setServiceNowTickets([]);

    dispatch(setSelectedRecommendations([]));
    dispatch(setCurrentRecommendationView(DEFAULT_VIEW_DROPDOWN_OPTIONS[0]));

    if (selectedCostOptimizationInsightsConnection) {
      setCurrentPage(-1);
      getAllSnowIncidents();
      getAllRecommenderMapping();
    }
  }, [selectedCostOptimizationInsightsConnection]);

  useEffect(() => {
    getAllRecommendationViews();
  }, [categoryRecommenderMapping, selectedCostOptimizationInsightsNav]);

  useEffect(() => {
    setRecommendationFilters([]);
    if (selectedCostOptimizationInsightsConnection) {
      fetchRecommendationFilters(
        selectedCostOptimizationInsightsConnection!,
        selectedCostOptimizationInsightsNav,
        setRecommendationFilters,
        setRecommendationFiltersRequestStatus
      );
    }
  }, [
    selectedCostOptimizationInsightsConnection,
    selectedCostOptimizationInsightsNav,
  ]);

  useEffect(() => {
    if (!viewList) {
      return;
    }

    if (hasRulesetFilters(viewList, currentRecommendationView)) {
      getRulesetViewFilters();
    } else {
      fetchAndSetRecommendationData();
    }
  }, [currentRecommendationView, viewList]);

  useEffect(() => {
    if (currentPage === -1) {
      setCurrentPage(1);
      return;
    }
    if (
      selectedCostOptimizationInsightsConnection &&
      categoryRecommenderMapping &&
      viewList &&
      currentPage === 1
    ) {
      getRecommendationTableData(setRecommendationData);
      getTotalRecommendations();
    }

    if (currentPage !== 1 && categoryRecommenderMapping) {
      getRecommendationTableData(setNextPageRecommendationData);
    }
  }, [categoryRecommenderMapping, currentPage]);

  useEffect(() => {
    setRecommendationTableData(
      addServiceNowStatusToRecommendationData(recommendationData)
    );
  }, [serviceNowTickets, recommendationData]);

  useEffect(() => {
    const data = filterRecommendationTableDataSource(
      recommendationTableData,
      statusFilters,
      selectedSnowStatus
    );
    setFilteredRecommendationData(data);

    if (data.length === 0) {
      handleChangePagination();
    }
  }, [recommendationTableData, statusFilters, selectedSnowStatus]);

  useEffect(() => {
    if (currentPage > 1) {
      const data = addServiceNowStatusToRecommendationData(
        nextPageRecommendationData
      );
      const filteredData = filterRecommendationTableDataSource(
        data,
        statusFilters,
        selectedSnowStatus
      );

      if (
        filteredRecommendationData.length === 0 &&
        filteredData.length === 0
      ) {
        handleChangePagination();
      }
    }
  }, [nextPageRecommendationData]);

  useEffect(() => {
    setLabelledRecommendationFilters(
      getLabelledFilters(recommendationFilters, {})
    );
  }, [recommendationFilters]);

  /**
   * @function fetchAndSetRecommendationData
   * @description Function to fetch the recommendation data for the first page
   */
  const fetchAndSetRecommendationData = () => {
    setCurrentPage(-1);
    setTotalCount(0);
    setRecommendationTableData([]);
    setAllRecommendationData([]);
    dispatch(setSelectedRecommendations([]));

    if (
      selectedCostOptimizationInsightsConnection &&
      categoryRecommenderMapping &&
      viewList &&
      (!hasRulesetFilters(viewList, currentRecommendationView) ||
        rulesetFilters.length > 0)
    ) {
      getRecommendationTableData(setAllRecommendationData, false, false);
    }
  };

  /**
   * @function fetchServiceNowConnection
   * @description Function to fetch the service now connection details.
   */
  const fetchServiceNowConnection = () => {
    getTicketConnection()
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res.data.responseData;
          dispatch(
            setTicketIntegrationData({
              url: data.endpoint,
              usernameHost: data.username,
              password: data.password,
              setDefault: data.default,
              defaultAssignmentGroup: data.defaultAssigneeGroup,
              defaultAssignedTo: data.defaultAssignTo,
              category: data.defaultCategory,
            })
          );
          setHasTicketIntegration(true);
          setFetchingDefaultTicketData(false);
          return;
        }
        dispatch(setTicketIntegrationData(defaultFormData));
        setHasTicketIntegration(false);
        setFetchingDefaultTicketData(false);
      })
      .catch((e) => {
        onApiCallError(e);
        dispatch(setTicketIntegrationData(defaultFormData));
        setHasTicketIntegration(false);
        setFetchingDefaultTicketData(false);
      });
  };

  /**
   * @function getAllSnowIncidents
   * @description Function to fetch the service now status for all recommendations and ticket under a connection.
   */
  const getAllSnowIncidents = () => {
    setSnowTicketsRequestStatus(REQUEST_STATUS.PROCESSING);
    getAllSnowIncidentDetails(
      selectedCostOptimizationInsightsConnection?.connectorId ?? ''
    )
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          const tickets = data?.incidentRecommendationMappings?.map(
            (incident: IncidentRecommendationMappingType) => ({
              ...incident,
              incidentStatus: ORDERED_SERVICE_NOW_STATUS_LIST.at(
                Number(incident.incidentStatus)
              ),
            })
          );
          setServiceNowTickets(tickets);
          setSnowTicketsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setServiceNowTickets([]);
        setSnowTicketsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setSnowTicketsRequestStatus);
        setServiceNowTickets([]);
      });
  };

  /**
   * @function getServiceNowStatusAndSysId
   * @description Function to fetch the service now status and the sysId of the recommendation mapped ticket.
   * @param recommendationName The name of the recommendation
   * @param recStatus The name of the recommendation status of the recommendation
   * @returns service now status if the ticket is created else `Ticket TBC` by default
   */
  const getServiceNowStatusAndSysId = (
    recommendationName: string,
    recStatus: string
  ) => {
    const incident = serviceNowTickets.find((snowIncident) =>
      snowIncident.recommendationApprovals?.some(
        (recommendationEntry) => recommendationEntry.name === recommendationName
      )
    );

    if (incident) {
      return { incidentStatus: incident.incidentStatus, sysId: incident.sysId };
    }

    if (
      recStatus === RecommendationStatus.ACTIVE ||
      recStatus === RecommendationStatus.FAILED
    ) {
      return { incidentStatus: ServiceNowStatus.TICKET_TBC };
    }

    return { incidentStatus: UNKNOWN_VALUE };
  };

  /**
   * @function getIncidentRecommendationStatus
   * @description Function to fetch the approval status of recommendation in service now.
   * @param recommendationName The name of the recommendation
   * @returns Object containing the approvalStatus, name and sysId
   */
  const getIncidentRecommendationStatus = (recommendationName: string) => {
    const recommendationApprovals = serviceNowTickets.find((incident) =>
      incident.recommendationApprovals?.some(
        (recommendationEntry) => recommendationEntry.name === recommendationName
      )
    )?.recommendationApprovals;

    if (recommendationApprovals) {
      return recommendationApprovals.find(
        (recommendation) => recommendation.name === recommendationName
      )!;
    }

    return {
      approvalStatus: undefined,
      name: recommendationName,
      recomStatus: undefined,
      sysId: undefined,
    };
  };

  /**
   * @function getRecommendationTableData
   * @description Function to fetch the recommendations.
   * @param setData Setter method for updating the data fetched
   * @param withPagination boolean value to indicate whether to include pagination params or not.
   * @param addLoader boolean to indicate whether to add a loader or not
   */
  const getRecommendationTableData = (
    setData: (val: AzureRecommendationListType[]) => void,
    withPagination: boolean = true,
    addLoader: boolean = true
  ) => {
    if (addLoader) {
      setRecommendationRequestStatus(REQUEST_STATUS.PROCESSING);
    }

    const hasRulesetFilter =
      currentRecommendationView.key !== DEFAULT_VIEW &&
      hasRulesetFilters(viewList, currentRecommendationView);

    let params: any = {
      connectorId: selectedCostOptimizationInsightsConnection?.connectorId,
    };

    if (withPagination) {
      params = {
        ...params,
        page: currentPage,
        pageSize: INFINITE_SCROLL_PAGE_SIZE,
      };
    }

    const requestBody = hasRulesetFilter
      ? getAzureRulesetFilterRecommendationQuery(
          currentRecommendationView,
          categoryRecommenderMapping,
          selectedCostOptimizationInsightsNav,
          rulesetFilters
        )
      : getAzureRecommendationTableDefaultQuery(
          currentRecommendationView,
          categoryRecommenderMapping,
          selectedCostOptimizationInsightsNav
        );

    (hasRulesetFilter
      ? getFilteredRecommendations(requestBody, params)
      : getChartData(
          requestBody,
          selectedCostOptimizationInsightsConnection?.connectorId,
          params
        )
    )
      .then((res: any) => {
        setData(
          res?.data?.map((item: any) => ({
            ...item,
            recStatus: item?.recommendationState,
            serviceNowStatus: ServiceNowStatus.TICKET_TBC,
            costSavings: item?.costSavings
              ? numberCommaSeparator(item?.costSavings)
              : UNKNOWN_VALUE,
          }))
        );
        setRecommendationRequestStatus(REQUEST_STATUS.SUCCESS);

        if (currentPage === 1) {
          setCurrentPage(2);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setRecommendationRequestStatus);
        setData([]);
      });
  };

  /**
   * @function getTotalRecommendations
   * @description Function to fetch the total count of recommendations
   */
  const getTotalRecommendations = () => {
    const hasRulesetFilter =
      currentRecommendationView.key !== DEFAULT_VIEW &&
      hasRulesetFilters(viewList, currentRecommendationView);

    const requestBody = hasRulesetFilter
      ? getAzureRulesetFilterRecommendationCountQuery(
          currentRecommendationView,
          categoryRecommenderMapping,
          selectedCostOptimizationInsightsNav,
          rulesetFilters
        )
      : getAzureDefaultRecommendationCountQuery(
          currentRecommendationView,
          categoryRecommenderMapping,
          selectedCostOptimizationInsightsNav
        );

    const params = {
      connectorId: selectedCostOptimizationInsightsConnection?.connectorId,
    };

    (hasRulesetFilter
      ? getFilteredRecommendations(requestBody, params)
      : getChartData(
          requestBody,
          selectedCostOptimizationInsightsConnection?.connectorId
        )
    )
      .then((res: any) => {
        setTotalCount(parseFloat(res?.data[0]?.totalCount?.toFixed(2)));
      })
      .catch((e) => {
        onApiCallError(e, false);
        setTotalCount(0);
      });
  };

  /**
   * @function getAllRecommenderMapping
   * @description Function to fetch all the available filters for connection.
   */
  const getAllRecommenderMapping = () => {
    getAzureCategoryRecommenderMapping()
      .then((res: any) => {
        if (res?.status === 200) {
          setCategoryRecommenderMapping(res.data.responseData);
        }
      })
      .catch((e) => onApiCallError(e, true, setRecommendationRequestStatus));
  };

  /**
   * @function getAllRecommendationViews
   * @description Function to fetch all the available filters for connection.
   */
  const getAllRecommendationViews = (currentView?: ViewListType) => {
    setViewListRequestStatus(REQUEST_STATUS.PROCESSING);
    const extraHeaders = {
      connectorId:
        selectedCostOptimizationInsightsConnection?.connectorId ?? '',
    };
    getRecommendationViews(extraHeaders)
      .then((res: any) => {
        if (res?.status === 200) {
          const formattedViews =
            res?.data?.responseData?.map((view: any) => {
              return {
                ...view,
                key: view.viewName,
                recommendationsFilterDtoList:
                  view?.recommendationsFilterDtoList?.map((filter: any) => ({
                    ...filter,
                    values:
                      filter?.values?.map((item: any) => ({
                        value: item,
                        label: item,
                      })) || [],
                  })),
              };
            }) || [];
          setViewList([...DEFAULT_VIEW_DROPDOWN_OPTIONS, ...formattedViews]);
          setViewListRequestStatus(REQUEST_STATUS.SUCCESS);
          currentView && dispatch(setCurrentRecommendationView(currentView));
          return;
        }

        setViewList([...DEFAULT_VIEW_DROPDOWN_OPTIONS]);
        setViewListRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setViewListRequestStatus);
        setViewList([...DEFAULT_VIEW_DROPDOWN_OPTIONS]);
      });
  };

  /**
   * @function getRulesetViewFilters
   * @description Function to fetch the ruleset filters
   */
  const getRulesetViewFilters = () => {
    setRulesetFiltersRequestStatus(REQUEST_STATUS.PROCESSING);

    const rulesetIds = viewList?.some(
      (view) => view.key === currentRecommendationView.key
    )
      ? currentRecommendationView.recommendationsFilterDtoList
          .find((item) => item.key === FilterKeys.RULESET)
          ?.values?.map((item) => item.value)
      : [currentRecommendationView.key];

    axios
      .all(
        (rulesetIds ?? []).map((rulesetId) =>
          getRulesetFilters({ rulesetId: rulesetId })
        )
      )
      .then((res) => {
        const filters: FilterGroupType[] = [];
        res?.forEach((item: any) => {
          filters.push(...(item?.data?.responseData ?? []));
        });
        setRulesetFilters(filters);
        setRulesetFiltersRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, true, setRulesetFiltersRequestStatus);
      });
  };

  /**
   * @function applyRecommendation
   * @description Function apply the recommendation.
   * @param recommendation recommendation details of applied recommendation
   */
  const applyRecommendation = (recommendation: AzureRecommendationListType) => {
    setLoadApplyRecommendation(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedCostOptimizationInsightsConnection?.connectorId,
      recommendationName: recommendation.recommendationName,
      recommender: recommendation.recommendationProblem,
      recommendationId: recommendation.recommendationId,
      resourceId: recommendation.resourceMetadata.substring(1),
    };

    applyAzureRecommendations(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          message.success(
            t(
              'costOptimizationInsight.recommendationTableAction.applyRecSuccess',
              {
                recommendationId: recommendation.recommendationName,
              }
            )
          );
          getAllSnowIncidents();
          setRecommendationData([]);
          setCurrentPage(-1);
          setLoadApplyRecommendation(REQUEST_STATUS.SUCCESS);

          return;
        }
        message.error(
          res?.data?.message ??
            t(
              'costOptimizationInsight.recommendationTableAction.applyRecFailure',
              {
                recommendationId: recommendation.recommendationName,
              }
            )
        );
        setLoadApplyRecommendation(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        const errorMessage =
          e.response.data.message ??
          t(
            'costOptimizationInsight.recommendationTableAction.applyRecFailure',
            {
              recommendationId: recommendation.recommendationName,
            }
          );
        onApiCallError(e, true, setLoadApplyRecommendation, errorMessage);
      });
  };

  /**
   * @function addServiceNowStatusToRecommendationData
   * @description Function to add the service now status to the recommendation data.
   * @param data Recommendation Data
   * @returns Recommendation data with SNOW details updated
   */
  const addServiceNowStatusToRecommendationData = (
    data: AzureRecommendationListType[]
  ) => {
    return data.map((item: AzureRecommendationListType) => {
      const { incidentStatus, sysId } = getServiceNowStatusAndSysId(
        item.recommendationName,
        item.recStatus
      );
      const { recomStatus, approvalStatus } = getIncidentRecommendationStatus(
        item.recommendationName
      );
      return {
        ...item,
        recStatus: recomStatus ?? item.recStatus,
        serviceNowStatus: incidentStatus,
        incidentRecommendationStatus: approvalStatus,
        lastRefreshed: moment(item.lastRefreshed).format(DATE_TIME_AM_PM),
        sysId: sysId,
      };
    });
  };

  const handleQuickAction = (
    record: AzureRecommendationListType,
    action: string
  ) => {
    switch (action) {
      case RecommendationTableActions.APPLY_RECOMMENDATIONS:
        setSelectedRecommendation(record);
        applyRecommendation(record);
        break;

      case RecommendationTableActions.TICKET_INFO:
        setSelectedRecommendation(record);
        setShowTicketInfoDrawer(true);
        break;
    }
  };

  /**
   * @function getStatusElement
   * @description Function to return a styled element for status text
   * @param text text to be displayed
   * @param key status key for style
   * @returns a span element with styled text
   */
  const getStatusElement = (text: string, key: string) => (
    <span className={`font-button status-text ${getStatusTextClassName(key)}`}>
      {text}
    </span>
  );

  /**
   * @function checkRecordStatusForApplyRec
   * @description Function to check if apply recommendation quick action should be shown as an
   * option in the recommendation row. Checks the rec status and snow status.
   * @param record The row details for the recommendation
   */
  const checkRecordStatusForApplyRec = (
    record: AzureRecommendationListType
  ) => {
    return (
      record.incidentRecommendationStatus ===
        TICKET_RECOMMENDATION_STATUS.APPROVED &&
      (record.recStatus === RecommendationStatus.CLAIMED ||
        record.recStatus === RecommendationStatus.FAILED) &&
      (record.serviceNowStatus === ServiceNowStatus.RESOLVED ||
        record.serviceNowStatus === ServiceNowStatus.CLOSED)
    );
  };

  const getQuickActions = (record: AzureRecommendationListType) => {
    return RECOMMENDATION_TABLE_QUICK_ACTIONS.filter((quickAction: any) => {
      // Conditions for apply recommendation quick action
      if (quickAction.id === RecommendationTableActions.APPLY_RECOMMENDATIONS) {
        return (
          permissions.costControlWrite &&
          checkRecordStatusForApplyRec(record) &&
          selectedCostOptimizationInsightsNav ===
            COST_OPTIMIZATION_INSIGHTS_NAV.WASTE_MANAGEMENT_RECOMMENDATIONS
        );
      }

      // Conditions for Ticket info quick action
      else if (quickAction.id === RecommendationTableActions.TICKET_INFO)
        return (
          ORDERED_SERVICE_NOW_STATUS_LIST.includes(record.serviceNowStatus) &&
          record.serviceNowStatus !== ServiceNowStatus.TICKET_TBC
        );
      else return true;
    });
  };

  /**
   * @function getFilterDropdown
   * @description Function to return the filter dropdown
   * @returns JSX element with filters
   */
  const getFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
  }: FilterDropdownProps) => (
    <TableFilterDropdown
      allKeys={filters ?? []}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  );

  const getColumns = () => {
    const columns: ColumnsType<AzureRecommendationListType> = [
      {
        title: t(
          'costOptimizationInsight.recommendationTable.recommendationProblem'
        ),
        dataIndex: 'recommendationProblem',
        width: 400,
        fixed: 'left',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('costOptimizationInsight.recommendationTable.recStatus'),
        dataIndex: 'recStatus',
        width: 130,
        render: (text: string) =>
          getStatusElement(
            RECOMMENDATION_STATUS_LABELS.find((item) => item.key === text)
              ?.label ?? text,
            text
          ),
        filters: RECOMMENDATION_STATUS_LABELS.map((item) => ({
          value: item.key,
          text: item.label,
        })),
        filterIcon: (
          <Icon iconName={ICONS.FILTER_3_FILL} dataTestId="filter-cta" />
        ),
        filterDropdown: getFilterDropdown,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('costOptimizationInsight.recommendationTable.impactedField'),
        dataIndex: 'impactedField',
        width: 250,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('costOptimizationInsight.recommendationTable.impactedValue'),
        dataIndex: 'impactedValue',
        width: 150,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t(
          'costOptimizationInsight.recommendationTable.recommendationName'
        ),
        dataIndex: 'recommendationName',
        width: 220,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('costOptimizationInsight.recommendationTable.type'),
        dataIndex: 'type',
        render: (text: string) =>
          RECOMMENDATION_SUBTYPE_LABELS.find((item) => item.key === text)
            ?.label ?? text,
        width: 250,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('costOptimizationInsight.recommendationTable.category'),
        dataIndex: 'recommendationCategory',
        render: (text: string) =>
          RECOMMENDATION_CATEGORIES_LABELS.find((item) => item.key === text)
            ?.label ?? text,
        width: 100,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('costOptimizationInsight.recommendationTable.lastRefreshed'),
        dataIndex: 'lastRefreshed',
        width: 230,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('costOptimizationInsight.recommendationTable.costSavings'),
        dataIndex: 'costSavings',
        width: 120,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('costOptimizationInsight.recommendationTable.actions'),
        dataIndex: 'quickAction',
        render: (_text: string, record: AzureRecommendationListType) => {
          return (
            <QuickActionMenu
              quickActions={getQuickActions(record)}
              disabled={
                getQuickActions(record).length === 0 ||
                loadApplyRecommendation === REQUEST_STATUS.PROCESSING
              }
              quickActionHandler={(action: string) => {
                handleQuickAction(record, action);
              }}
            />
          );
        },
        width: 80,
        fixed: 'right',
        align: 'center',
      },
    ];

    if (
      [
        ServiceNowStatus.TICKET_TBC,
        ServiceNowStatus.RESOLVED,
        ServiceNowStatus.CLOSED,
      ].includes(selectedSnowStatus)
    ) {
      const snowStatusColumn = {
        title: t('costOptimizationInsight.recommendationTable.ticketStatus'),
        dataIndex:
          selectedSnowStatus === ServiceNowStatus.TICKET_TBC
            ? 'serviceNowStatus'
            : 'incidentRecommendationStatus',
        width: 200,
        render: (_text: string, record: AzureRecommendationListType) => {
          return (
            <div className="flex flex-gap-8">
              {[ServiceNowStatus.TICKET_TBC, UNKNOWN_VALUE].includes(
                record?.serviceNowStatus
              ) &&
                getStatusElement(
                  SERVICE_NOW_STATUS_LABELS.find(
                    (item) => item.key === record.serviceNowStatus
                  )?.label ?? record.serviceNowStatus,
                  record.serviceNowStatus
                )}
              {[
                ServiceNowStatus.RESOLVED.valueOf(),
                ServiceNowStatus.CLOSED.valueOf(),
              ].includes(record?.serviceNowStatus) &&
                record?.incidentRecommendationStatus && (
                  <div>
                    {getStatusElement(
                      IncidentRecommendationStatusLabels.find(
                        (item) =>
                          item.key === record?.incidentRecommendationStatus
                      )?.label ?? record?.incidentRecommendationStatus,
                      record?.incidentRecommendationStatus
                    )}
                  </div>
                )}
            </div>
          );
        },
        filters:
          selectedSnowStatus === ServiceNowStatus.TICKET_TBC
            ? [
                {
                  value: ServiceNowStatus.TICKET_TBC,
                  text: t('serviceNowLabels.ticketTBC'),
                },
                {
                  value: UNKNOWN_VALUE,
                  text: UNKNOWN_VALUE,
                },
              ]
            : IncidentRecommendationStatusLabels.map((item) => ({
                value: item.key,
                text: item.label,
              })),
        filterDropdown: getFilterDropdown,
        filterIcon: (
          <Icon
            iconName={ICONS.FILTER_3_FILL}
            dataTestId="snow-status-filter-cta"
          />
        ),
        sorter: true,
        showSorterTooltip: false,
      };
      columns.splice(1, 0, snowStatusColumn);
    }

    return columns;
  };

  const onClickCreateSnowTicketCta = () => {
    if (hasTicketIntegration) {
      dispatch(setServiceNowData(ServiceNowDefaultData));
      dispatch(setTicketIntegrationData(defaultFormData));
      setShowCreateTicketDrawer(true);
      return;
    }

    navigate(NAVIGATION_MENU_PATH.TICKET_INTEGRATION);
  };

  const getCreateTicketsCtaLabel = () => {
    if (fetchingDefaultTicketData) {
      return t('costOptimizationInsight.recommendationTableHeader.loading');
    }

    if (hasTicketIntegration) {
      return t(
        'costOptimizationInsight.recommendationTableHeader.createTicket'
      );
    }

    return t(
      'costOptimizationInsight.recommendationTableHeader.ticketIntegrationError'
    );
  };

  const onClickDeleteView = () => {
    setDeleteViewRequestStatus(REQUEST_STATUS.PROCESSING);
    const extraHeaders = {
      viewName: currentRecommendationView.viewName,
      connectorId:
        selectedCostOptimizationInsightsConnection?.connectorId ?? '',
    };
    deleteRecommendationViews(extraHeaders)
      .then((res: any) => {
        if (res.status === 200) {
          message.success({
            content: t(
              'costOptimizationInsight.recommendationApiMessage.deleteSuccess',
              {
                viewName: currentRecommendationView.viewName,
              }
            ),
          });
          getAllRecommendationViews(DEFAULT_VIEW_DROPDOWN_OPTIONS[0]);
          setDeleteViewRequestStatus(REQUEST_STATUS.SUCCESS);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setDeleteViewRequestStatus);
      });
  };

  /**
   * @function handleChangePagination
   * @description Function to handle the pagination change
   */
  const handleChangePagination = () => {
    if (recommendationRequestStatus === REQUEST_STATUS.PROCESSING) return;

    if (
      currentPage * INFINITE_SCROLL_PAGE_SIZE <= totalCount &&
      recommendationData.length + nextPageRecommendationData.length ===
        currentPage * INFINITE_SCROLL_PAGE_SIZE
    ) {
      setRecommendationRequestStatus(REQUEST_STATUS.PROCESSING);
      setCurrentPage(currentPage + 1);
    }
    if (recommendationData.length < totalCount) {
      setRecommendationData([
        ...recommendationData,
        ...nextPageRecommendationData,
      ]);
    }
  };

  /**
   * @function onClickCreateTicket
   * @description Callback function for create ticket
   */
  const onClickCreateTicket = () => {
    setCreateTicketLoading(true);

    const isBulkTicket =
      serviceNowData.incidentType === INCIDENT_TYPES.BULK_INCIDENT;

    let requestBody: any = {
      connectorId: selectedCostOptimizationInsightsConnection?.connectorId,
      bulkTicket: selectedRecommendations.length === 1 ? false : isBulkTicket,
      multipleTicket:
        selectedRecommendations.length === 1 ? false : !isBulkTicket,
      assignTo: serviceNowData.assignedTo,
      category: serviceNowData.category,
    };

    requestBody = {
      ...requestBody,
      recomDetails: selectedRecommendations.map((recommendation) => {
        const recommendationDetails =
          recommendation as AzureRecommendationListType;
        return {
          name: recommendationDetails.recommendationName,
          description: recommendationDetails.recommendationProblem,
          category: recommendationDetails.recommendationCategory,
          targetResource: recommendationDetails.recommendationProblem,
        };
      }),
    };

    createSnowIncident(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setCreateTicketLoading(false);
          message.success(t('createTicketDrawer.ticketCreateSuccess'));
          dispatch(setSelectedRecommendations([]));
          getAllSnowIncidents();
          setShowCreateTicketDrawer(false);
          return;
        }
        setCreateTicketLoading(false);
        setCreateTicketValidationMessage(
          res?.data?.message ?? t('createTicketDrawer.errorCreateTicket')
        );
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          undefined,
          e.response.data.message ?? t('createTicketDrawer.errorCreateTicket')
        );
        setCreateTicketLoading(false);
      });
  };

  const CreateSnowTicketButton = (
    <Button
      className={`create-ticket-cta ${
        !hasTicketIntegration && !fetchingDefaultTicketData && 'error'
      }`}
      size={BUTTON_SIZE.SMALL}
      title={getCreateTicketsCtaLabel()}
      onClick={onClickCreateSnowTicketCta}
      disabled={!hasTicketIntegration || selectedRecommendations.length === 0}
    />
  );

  const DeleteViewButton = (
    <Button
      className="delete-view-button"
      size={BUTTON_SIZE.SMALL}
      iconName={ICONS.DELETE_BIN_LINE}
      title={t('costOptimizationInsight.recommendationTableHeader.deleteView')}
      onClick={onClickDeleteView}
      loading={deleteViewRequestStatus === REQUEST_STATUS.PROCESSING}
      disabled={currentRecommendationView.key === DEFAULT_VIEW}
    />
  );

  const ViewDropdownOptions = viewList
    ?.filter(
      (view) =>
        view.recommendationType === selectedCostOptimizationInsightsNav ||
        view.key === DEFAULT_VIEW
    )
    .map((view) => (
      <Select.Option key={view.key} value={view.key}>
        {view.viewName}
      </Select.Option>
    ));

  /**
   * @function getViewsDropdownRender
   * @description Function return the views dropdown render component
   * @param menu JSX menu element
   * @returns JSX element
   */
  const getViewsDropdownRender = (menu: JSX.Element) => (
    <div className="filter-view-dropdown styled-scroll">
      <span className="view-type font-subHeader-small">
        {t('costOptimizationInsight.viewDropdown.filterViewLabel')}
      </span>
      <div className="dropdown-container">{menu}</div>
    </div>
  );

  const ViewDropdown = (
    <SelectDropdown
      value={{
        value: currentRecommendationView.key,
        label: (
          <>
            <span className="font-button">
              {t('costOptimizationInsight.viewDropdown.viewValuePrefix')}
            </span>
            {currentRecommendationView.viewName}
          </>
        ),
      }}
      onSelect={(value: any) => {
        dispatch(
          setCurrentRecommendationView(
            viewList?.find((view) => view.key === value.key)!
          )
        );
      }}
      menu={ViewDropdownOptions}
      dropdownRender={getViewsDropdownRender}
      labelInValue
      loading={[viewListRequestStatus].includes(REQUEST_STATUS.PROCESSING)}
      className="view-dropdown"
    />
  );

  /**
   * @function filterRecommendationTableDataSource
   * @description Function that filters table data on the frontend for service now status filters
   * @returns Recommendation table data filtered based on the snow filters
   */
  const filterRecommendationTableDataSource = (
    recDataToBeFiltered: AzureRecommendationListType[],
    filters: StatusFilterType,
    selectedStatusNav: string
  ) => {
    let recommendationTableFilteredData = [...recDataToBeFiltered];

    let status = [selectedStatusNav];
    if (selectedStatusNav === ServiceNowStatus.TICKET_TBC) {
      status =
        filters.incidentStatus?.length > 0
          ? filters.incidentStatus
          : [selectedStatusNav, UNKNOWN_VALUE];
    }

    recommendationTableFilteredData = recommendationTableFilteredData.filter(
      (value) => status.includes(value.serviceNowStatus)
    );

    if (filters.incidentRecommendationStatus?.length) {
      recommendationTableFilteredData = recommendationTableFilteredData.filter(
        (value) =>
          ![
            ServiceNowStatus.RESOLVED.valueOf(),
            ServiceNowStatus.CLOSED.valueOf(),
          ].includes(value.serviceNowStatus) ||
          filters.incidentRecommendationStatus.includes(
            value?.incidentRecommendationStatus ?? ''
          )
      );
    }

    if (filters.recommendationStatus?.length) {
      recommendationTableFilteredData = recommendationTableFilteredData.filter(
        (value) => filters.recommendationStatus.includes(value.recStatus)
      );
    }

    return recommendationTableFilteredData;
  };

  /**
   * @function onChangeSortApplied
   * @description Function to sort the data
   * @param key field against which the sorting is done.
   * @param order order of sorting, either ascending or descending
   */
  const onChangeSortApplied = (key: string, order: string | undefined) => {
    if (sortApplied.key === key && sortApplied.order === order) return;

    if (order === undefined) {
      setRecommendationTableData(
        addServiceNowStatusToRecommendationData(recommendationData)
      );
      return;
    }

    const data = [...recommendationTableData].sort((item1, item2) => {
      if (key === 'costSavings') {
        return numberArraySortFunction(
          item1.costSavings,
          item2.costSavings,
          order
        );
      }
      return compareToValue(
        item1[key as keyof AzureRecommendationListType] as any,
        item2[key as keyof AzureRecommendationListType] as any,
        key,
        order
      )
        ? 1
        : -1;
    });
    setRecommendationTableData(data);
    setSortApplied({ key: key, order: order });
  };

  /**
   * @function onSelectTableRow
   * @description Callback function for row selection
   * @param record selected row data.
   * @param checked boolean value to indicate row selection or deselection
   */
  const onSelectTableRow = (
    record: AzureRecommendationListType,
    checked: boolean
  ) => {
    const typedSelectedRecoms =
      selectedRecommendations as AzureRecommendationListType[];
    if (checked) {
      dispatch(setSelectedRecommendations([...typedSelectedRecoms, record]));
      return;
    }
    dispatch(
      setSelectedRecommendations([
        ...typedSelectedRecoms.filter(
          (item) => item.recommendationId !== record.recommendationId
        ),
      ])
    );
  };

  /**
   * @function isRecommendationTableLoading
   * @description Function to return a boolean value to for loading table
   * @returns boolean value true or false
   */
  const isRecommendationTableLoading = () => {
    return (
      (recommendationRequestStatus !== REQUEST_STATUS.PROCESSING &&
        snowTicketsRequestStatus === REQUEST_STATUS.PROCESSING) ||
      (filteredRecommendationData.length === 0 &&
        recommendationRequestStatus === REQUEST_STATUS.PROCESSING) ||
      rulesetFiltersRequestStatus === REQUEST_STATUS.PROCESSING
    );
  };

  const getSummaryRowComponent = () => (
    <>
      {filteredRecommendationData.length > 0 &&
        recommendationRequestStatus === REQUEST_STATUS.PROCESSING && (
          <Table.Summary.Row className="loader-row">
            <Table.Summary.Cell index={0} colSpan={7}>
              <Loader
                iconWidth={16}
                iconHeight={16}
                additionalText={
                  <span className="loading-text">{t('loading')}...</span>
                }
              />
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
    </>
  );

  const getTableComponent = () => (
    <div
      className={`rec-table-container styled-scroll ${
        currentRecommendationView.recommendationsFilterDtoList &&
        currentRecommendationView.recommendationsFilterDtoList.length > 0 &&
        'with-filter'
      }`}
      id="graph-container"
    >
      <GraphHeader
        heading={t(`costOptimizationInsight.recommendationTableHeader.header`)}
        graphName={t(
          `costOptimizationInsight.recommendationTableHeader.header`
        )}
        ignorePadding={true}
        showExpandIcon={true}
        isDownloadable
        viewDropdown={ViewDropdown}
        actionButtons={[
          {
            component: (
              <Icon
                iconName={ICONS.REFRESH_LINE}
                onClick={() => getAllSnowIncidents()}
                size={ICONS_SIZE.ONE_X}
                color={theme.buttonIconColor}
                dataTestId="refresh-cta"
              />
            ),
            permission: true,
          },
          {
            component: DeleteViewButton,
            permission: permissions.costControlModify,
          },
          {
            component: CreateSnowTicketButton,
            permission: permissions.costControlWrite,
          },
          {
            component: (
              <Filters
                filters={labelledRecommendationFilters}
                disabled={currentRecommendationView.key !== DEFAULT_VIEW}
                onClickSubmit={(view: ViewListType) => {
                  setViewList(undefined);
                  getAllRecommendationViews(view);
                }}
                loading={[recommendationFiltersRequestStatus]}
              />
            ),
            permission: permissions.costControlWrite,
          },
        ]}
        excelData={getAzureRecommendationTableExcelData(
          selectedCostOptimizationInsightsConnection?.name ?? '',
          SERVICE_NOW_STATUS_LABELS.map((status) => ({
            sheetName: status.tabTitle,
            recommendationTableData: filterRecommendationTableDataSource(
              addServiceNowStatusToRecommendationData(allRecommendationData),
              statusFilters,
              status.key
            ),
          }))
        )}
      />
      {currentRecommendationView.recommendationsFilterDtoList &&
        currentRecommendationView.recommendationsFilterDtoList.length > 0 && (
          <SelectedFilters
            filters={labelledRecommendationFilters}
            selectedFilters={
              currentRecommendationView.recommendationsFilterDtoList
            }
          />
        )}
      <div className="snow-status-tabs flex flex-align-items-center flex-gap-16 flex-wrap">
        <div className="flex flex-column font-caption-bold">
          {t(
            'costOptimizationInsight.recommendationTableHeader.serviceNowStatus'
          )}
        </div>
        <Radio.Group
          options={SERVICE_NOW_STATUS_LABELS.map((status) => ({
            label: status.tabTitle,
            value: status.key,
          }))}
          onChange={(e) => setSelectedSnowStatus(e.target.value)}
          value={selectedSnowStatus}
          optionType="button"
          rootClassName="no-custom-style status-tabs font-caption-bold"
          style={{ height: 28 }}
        />
      </div>
      <Col
        span={24}
        className={`recommendations-table ${
          currentRecommendationView.key !== DEFAULT_VIEW && 'with-filters'
        }`}
      >
        <InfiniteScrollTable
          rowSelection={{
            type: 'checkbox',
            hideSelectAll: true,
            getCheckboxProps: (record: AzureRecommendationListType) => {
              return {
                disabled:
                  !permissions.costControlWrite ||
                  record.serviceNowStatus !== ServiceNowStatus.TICKET_TBC,
              };
            },
            onSelect: onSelectTableRow,
            selectedRowKeys: selectedRecommendations.map(
              (value) =>
                (value as AzureRecommendationListType)?.recommendationId
            ),
          }}
          loading={recommendationRequestStatus === REQUEST_STATUS.PROCESSING}
          tableLoading={isRecommendationTableLoading()}
          pagination={false}
          dataSource={filteredRecommendationData.map((value) => {
            return {
              ...value,
              key: value.recommendationId,
            };
          })}
          columns={getColumns()}
          sortDirections={['ascend', 'descend', 'ascend']}
          onChange={(
            _newPagination: TablePaginationConfig,
            filters: any,
            sorter: any
          ) => {
            setStatusFilters({
              incidentStatus: filters?.serviceNowStatus?.filter((item: any) =>
                SERVICE_NOW_STATUS_LABELS.some(
                  (status) => status.key === item || item === UNKNOWN_VALUE
                )
              ),
              incidentRecommendationStatus:
                filters?.incidentRecommendationStatus?.filter((item: any) =>
                  IncidentRecommendationStatusLabels.some(
                    (status) => status.key === item
                  )
                ),
              recommendationStatus: filters?.recStatus,
            });
            if (
              sortApplied.key !== sorter.field ||
              sortApplied.order !== sorter.order
            ) {
              onChangeSortApplied(sorter.field, sorter.order);
            }
          }}
          handleChangePageNumber={handleChangePagination}
          summary={getSummaryRowComponent}
          locale={{
            emptyText: ![
              recommendationRequestStatus,
              snowTicketsRequestStatus,
            ].includes(REQUEST_STATUS.PROCESSING) && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  recommendationRequestStatus === REQUEST_STATUS.ERROR
                    ? t('graphErrorMessage')
                    : t('costOptimizationInsight.noRecommendations', {
                        ticketStatus: SERVICE_NOW_STATUS_LABELS.find(
                          (item) => item.key === selectedSnowStatus
                        )?.tabTitle,
                      })
                }
              />
            ),
          }}
          designVersion2
          fillContainer
          scroll={{
            y: 'inherit',
            scrollToFirstRowOnChange: false,
          }}
          data-testid="azure-recommendation-table"
        />
      </Col>
    </div>
  );

  return (
    <div className="card">
      {getTableComponent()}
      <CreateTicketDrawer
        show={showCreateTicketDrawer}
        setShow={setShowCreateTicketDrawer}
        fetchServiceNowConnection={fetchServiceNowConnection}
        selectedRecommendationsLength={selectedRecommendations.length}
        onClickCreate={onClickCreateTicket}
        loading={createTicketLoading}
        validationMessage={createTicketValidationMessage}
      />
      {selectedRecommendation && (
        <TicketInfoDrawer
          ticket={selectedRecommendation}
          show={showTicketInfoDrawer}
          setShow={setShowTicketInfoDrawer}
        />
      )}
      {showExpandGraphModal && (
        <ExpandModal
          graphContent={getTableComponent()}
          show={showExpandGraphModal}
          width={'90%'}
          height={'90vh'}
        />
      )}
    </div>
  );
};

export default RecommendationTable;
