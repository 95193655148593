import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { TickFillWarning } from 'assets/icons';

import './index.scss';

type CollapsePanelIconProps = {
  isValidationSuccess: boolean;
  disabled?: boolean;
};

const CollapsePanelIcon = ({
  isValidationSuccess,
  disabled,
}: CollapsePanelIconProps) => {
  return isValidationSuccess ? (
    <Icon
      iconName={ICONS.CHECKBOX_CIRCLE_FILL}
      className={`collpse-panel-icon tick-success ${disabled && 'disabled'}`}
    />
  ) : (
    <Icon
      icon={TickFillWarning}
      className={`collpse-panel-icon tick-error ${disabled && 'disabled'}`}
    />
  );
};

export default CollapsePanelIcon;
