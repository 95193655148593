import i18n from 'i18n';
import { store } from 'redux/store';
import {
  ColouredCostByServiceType,
  ColouredCostByResourceType,
  ColouredCostByAccountType,
} from 'types/dataTypes';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { getMonthYearShortList, MONTH_YEAR_FORMAT } from 'utils/date';
import { TopServicesExportColumns } from './constants';

const state = store.getState();
const currencySymbol = state.commonUtility.currencySymbol;

/**
 * @function getTopAccountsTableHeading
 * @desciption Returns the top accounts table/chart heading for export and render.
 * @param selectedAccount account selected in drilldown
 * @param selectedService selected service selected in drilldown
 * @returns top accounts table/chart heading
 */
export const getTopAccountsTableHeading = (
  selectedAccount: string,
  selectedService: string
) => {
  if (!selectedAccount) return i18n.t('accountDeepDiveSummary.topAccounts');
  if (!selectedService) return i18n.t('graphHeadings.costByService');
  return i18n.t('graphHeadings.costByResources');
};

/**
 * @function getTopAccountsTableDataSource
 * @desciption Returns the top accounts table/chart data source for export and render.
 * @param topAccountsData top accounts data
 * @param accountWiseServiceData account wise service data
 * @param serviceWiseResourceData service wise resource data
 * @param selectedAccount account selected in drilldown
 * @param selectedService selected service selected in drilldown
 * @returns top accounts table/chart data source
 */
export const getTopAccountsTableDataSource = (
  topAccountsData: ColouredCostByAccountType[],
  accountWiseServiceData: ColouredCostByServiceType[],
  serviceWiseResourceData: ColouredCostByResourceType[],
  selectedAccount: string,
  selectedService: string
) => {
  if (!selectedAccount) return topAccountsData;
  else if (!selectedService) return accountWiseServiceData;
  else return serviceWiseResourceData;
};

/**
 * @function getCostTimeLineExportColumns
 * @desciption Returns the the columns for timeline charts
 * @param graph graph for whch the columns are fetched
 * @param startDate Start date chosen
 * @param endDate End date chosen
 * @returns List of columns
 */
export const getCostTimeLineExportColumns = (
  graph: string,
  startDate: string,
  endDate: string
) => [
  {
    header: '#',
    key: 'slNo',
    width: 10,
    dataKey: 'slNo',
  },
  {
    header: i18n.t(
      `accountDeepDiveSummary.${
        graph === 'service-time-line' ? 'services' : 'accounts'
      }`
    ),
    key: 'name',
    dataKey: 'name',
    width: 25,
  },
  ...getMonthYearShortList(startDate, endDate, MONTH_YEAR_FORMAT).map(
    (month: any) => ({
      header: month,
      key: `${month}-cost`,
      dataKey: `${month}-cost`,
      width: 15,
    })
  ),
];

/**
 * @function getAwsCostTimeLineExcelData
 * @desciption Returns the the excel export data for timeline charts
 * @param connectorName name of the connection chosen
 * @param timeLineData data to be exported
 * @param graph graph for whch the columns are fetched
 * @param startDate Start date chosen
 * @param endDate End date chosen
 * @param selectedAccounts List of accounts selected
 * @returns Object containing the excel data
 */
export const getAwsCostTimeLineExcelData = (
  connectorName: string,
  timeLineData: any[],
  graph: string,
  startDate: string,
  endDate: string,
  selectedAccounts: string[]
) => {
  return {
    sheetName:
      graph === 'account-time-line'
        ? i18n.t('accountDeepDiveSummary.costAccountTimeLine')
        : i18n.t('accountDeepDiveSummary.costServiceTimeLine'),
    columns: getCostTimeLineExportColumns(graph, startDate, endDate),
    data: insertIndex(timeLineData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: startDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: endDate,
      },
      {
        heading: i18n.t('excelExportLabels.accounts'),
        value: selectedAccounts.join(', '),
      },
    ],
  };
};

/**
 * @function getTopServicesExcelData
 * @desciption Returns the the excel export data for top services
 * @param connectorName name of the connection chosen
 * @param tableData data to be exported
 * @param startDate Start date chosen
 * @param endDate End date chosen
 * @param selectedAccounts List of accounts selected
 * @returns Object containing the excel data
 */
export const getTopServicesExcelData = (
  connectorName: string,
  tableData: ColouredCostByServiceType[],
  startDate: string,
  endDate: string,
  selectedAccounts: string[]
) => {
  return {
    sheetName: i18n.t('accountDeepDiveSummary.topServices'),
    columns: TopServicesExportColumns,
    data: insertIndex(tableData).map((item) => ({
      ...item,
      cost: numberCommaSeparator(item.cost),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: startDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: endDate,
      },
      {
        heading: i18n.t('excelExportLabels.accounts'),
        value: selectedAccounts.join(', '),
      },
    ],
  };
};

/**
 * @function getTopAccountsExportColumns
 * @desciption Returns the the export columns for top accounts
 * @param selectedAccount account selected
 * @param selectedService service selected
 * @returns Object containing the columns
 */
export const getTopAccountsExportColumns = (
  selectedAccount: string,
  selectedService: string
) => {
  let columns = [
    {
      header: '#',
      dataKey: 'slNo',
      key: 'slNo',
      width: 5,
    },
    {
      header: i18n.t('accountDeepDiveSummary.accounts'),
      dataKey: 'account',
      key: 'account',
      width: 25,
    },
    {
      header: i18n.t('accountDeepDiveSummary.cost', {
        currencySymbol: currencySymbol,
      }),
      dataKey: 'cost',
      key: 'cost',
      width: 20,
    },
  ];

  if (!selectedAccount) return columns;
  else if (!selectedService)
    columns.splice(1, 1, {
      header: i18n.t('accountDeepDiveSummary.services'),
      dataKey: 'service',
      key: 'service',
      width: 25,
    });
  else
    columns.splice(1, 1, {
      header: i18n.t('accountDeepDiveSummary.resources'),
      dataKey: 'resource',
      key: 'resource',
      width: 25,
    });
  return columns;
};

/**
 * @function getTopAccountsExcelData
 * @desciption Returns the the excel export data for top accounts
 * @param connectorName name of the connection chosen
 * @param tableData data to be exported
 * @param startDate Start date chosen
 * @param endDate End date chosen
 * @param selectedAccounts List of accounts selected
 * @returns Object containing the excel data
 */
export const getTopAccountsExcelData = (
  connectorName: string,
  tableData:
    | ColouredCostByAccountType[]
    | ColouredCostByServiceType[]
    | ColouredCostByResourceType[],
  selectedAccount: string,
  selectedService: string,
  startDate: string,
  endDate: string,
  filteredAccounts: string[]
) => {
  let sheetName = '';
  if (!selectedAccount)
    sheetName = i18n.t('accountDeepDiveSummary.topAccounts');
  else if (!selectedService) sheetName = i18n.t('graphHeadings.costByService');
  else sheetName = i18n.t('graphHeadings.costByResources');

  const additionalDrilldownFilters = [];
  if (!selectedAccount)
    additionalDrilldownFilters.push({
      heading: i18n.t('excelExportLabels.accounts'),
      value: filteredAccounts.join(', '),
    });
  else if (!selectedService)
    additionalDrilldownFilters.push({
      heading: i18n.t('excelExportLabels.account'),
      value: selectedAccount,
    });
  else {
    additionalDrilldownFilters.push({
      heading: i18n.t('excelExportLabels.account'),
      value: selectedAccount,
    });
    additionalDrilldownFilters.push({
      heading: i18n.t('excelExportLabels.service'),
      value: selectedService,
    });
  }

  return {
    sheetName: sheetName,
    columns: getTopAccountsExportColumns(selectedAccount, selectedService),
    data: insertIndex(tableData),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: startDate,
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: endDate,
      },
      ...additionalDrilldownFilters,
    ],
  };
};
