import { CMDBFieldTagsMappingType } from 'pages/CmdbSetupListPage/types';
import { ConnectionListType } from 'types/dashboard';

/**
 * @function filterConnections
 * @description Function to filter the connections based on the search key
 * @param connections List of connections to search for
 * @param searchKey Search key for the filter
 * @returns List of connections post filter
 */
export const filterConnections = (
  connections: ConnectionListType[],
  searchKey: string
) => {
  return connections.filter((connection) =>
    connection.displayName
      ?.toLowerCase()
      ?.includes(searchKey?.trim()?.toLowerCase())
  );
};

/**
 * @function getSelectedTagCountByConnectorId
 * @description Function to get the list of selected tag count based on the connector ID
 * @param connectorId ConnecorId for which the count is fetched
 * @param cmdbFieldTagsMap Map of CMDB field to list of connections and tags added for each connection
 * @returns Number containing the count of tags for the given connector ID. Defaults to zero.
 */
export const getSelectedTagCountByConnectorId = (
  connectorId: string,
  cmdbFieldTagsMap: CMDBFieldTagsMappingType
) => {
  return (
    cmdbFieldTagsMap.cloudTags.find(
      (cloudTag) => cloudTag.connectorId === connectorId
    )?.tagKeys?.length ?? 0
  );
};
