import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useSelector } from 'react-redux';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectTheme } from 'redux/themeSlice';
import { costOptimizationInsights } from 'redux/costOptimizationInsightsSlice';
import {
  convertContainerStringToArrayOfObject,
  fetchCPUMemoryCostImpact,
  getRequestBodyObject,
} from 'pages/CostOptimizationInsightsPage/components/Granulate/RecommendationPage/utils';
import { SERVICE_NOW_STATUS } from 'pages/ThirdPartyPage/components/GProfilerInsightPage/constants';
import {
  memoryRequestBody,
  cpuRequestBody,
} from 'pages/CostOptimizationInsightsPage/components/Granulate/RecommendationPage/constants';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import DatePicker from 'components/DatePicker';
import ColumnLineChart from 'components/ColumnLineChart';
import GraphHeader from 'components/GraphHeader';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import Table from 'components/Table';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import {
  getChartData,
  getRecommendationCostData,
  getRecommendationData,
} from 'utils/services';
import {
  getMonthAndYearStringFormat,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { MonthlyCostType } from 'types/dataTypes';
import { RecommendationDataType } from 'types/GranulateRecommendation';
import { getCostTrendExcelData } from 'pages/ContainerInsightsPage/utils';
import { changeAlphaOfColor } from 'utils/dashboardUtils';
import { PROVIDER } from 'constants/cloudProviders';

type CostTrendProps = {
  costTrendValues: MonthlyCostType[];
  dateRange: string[];
  setDateRange: (val: string[]) => void;
  allNamespaces: string[];
  selectedNamespaces: string[];
  setSelectedNamespaces: (val: string[]) => void;
  requestStatus: string[];
  costTrendNamespaceRequestStatus: string;
  setIsCostTrendTableView: (val: boolean) => void;
  isCostTrendTableView: boolean;
  pdfView: boolean;
  excelFilters: any[];
};

const CostTrend = ({
  costTrendValues,
  dateRange,
  setDateRange,
  allNamespaces,
  selectedNamespaces,
  setSelectedNamespaces,
  requestStatus,
  costTrendNamespaceRequestStatus,
  setIsCostTrendTableView,
  isCostTrendTableView,
  pdfView,
  excelFilters,
}: CostTrendProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { theme } = useSelector(selectTheme);
  const { showConsolidatedDashboard, selectedGranulateService } = useSelector(
    costOptimizationInsights
  );

  const [activeLegends, setActiveLegends] = useState<string[]>([
    t('costTrend.costWithoutSuffix'),
    t('costTrend.trend'),
    t('costTrend.savingsOpportunity'),
  ]);

  const [recommendationData, setRecommendationData] = useState<
    RecommendationDataType[]
  >([]);
  const [costImpactCPURequestBody, setCostImpactCPURequestBody] = useState<
    any[]
  >([]);
  const [costImpactMemoryRequestBody, setCostImpactMemoryRequestBody] =
    useState<any[]>([]);

  const [costImpact, setCostImpact] = useState(0);

  useEffect(() => {
    if (showConsolidatedDashboard) {
      fetchRecommendationData();
    }
    if (selectedGranulateService?.provider === PROVIDER.AWS) {
      fetchAWSRecommendationCostData();
    }
  }, [selectedGranulateService]);

  useEffect(() => {
    if (selectedGranulateService?.provider === PROVIDER.GCP) {
      getGCPCostImpactRequestBody();
    }
  }, [recommendationData]);

  useEffect(() => {
    if (showConsolidatedDashboard) fetchGCPCostImpact();
  }, [costImpactCPURequestBody, costImpactMemoryRequestBody]);

  const fetchRecommendationData = () => {
    if (selectedGranulateService?.serviceName) {
      setRecommendationData([]);
      const { serviceName, provider, connectorId } = selectedGranulateService;
      const params = { serviceName, provider, connectorId };

      getRecommendationData(params)
        .then((res: any) => {
          if (res.status === 200) {
            const data = res.data.map((item: any) => ({
              sys_id: item.sys_id,
              ticketStatus: item.incidentStatus,
              service_name: item.u_service_name,
              namespace: item.u_namespace,
              controller_name: item.u_resource_name,
              container: convertContainerStringToArrayOfObject(
                item.u_containers,
                item.incidentStatus === SERVICE_NOW_STATUS.UNDEFINED &&
                  item.u_approval_status === null
              ),

              costImpact: Number(item?.u_cost_impact ?? 0),
            }));
            setRecommendationData(data);
            return;
          }
          onApiCallError(res.data.Message, false);
        })
        .catch((e) => {
          onApiCallError(e, false);
        });
    }
  };

  /**
   * @function getGCPCostImpactRequestBody
   * @description function to get the request body for GCP cost impact
   */
  const getGCPCostImpactRequestBody = () => {
    const params = { project: 'cb360-foundations-devops-cicd' };

    const requestBody = [
      getChartData(
        memoryRequestBody,
        selectedGranulateService?.connectorId,
        params
      ),
      getChartData(
        cpuRequestBody,
        selectedGranulateService?.connectorId,
        params
      ),
    ];

    axios
      .all(requestBody)
      .then((res: any[]) => {
        let costImpactMemoryRequest: any[] = [];
        let costImpactCPURequest: any[] = [];

        recommendationData.forEach((data) => {
          const container = data.container.find(
            (item) => item.name === data.controller_name
          );
          res[0].data.forEach((item: any) => {
            if (
              data.controller_name === item.name ||
              item.name.includes(data.controller_name)
            ) {
              costImpactMemoryRequest.push(
                getRequestBodyObject(
                  selectedGranulateService?.name,
                  data,
                  item.sku_id,
                  container?.current_mem,
                  container?.recommended_memory,
                  'MEMORY',
                  { unitDescription: 'mebibyte-seconds', usageUnit: 'MB.s' }
                )
              );
            }
          });

          res[1].data.forEach((item: any) => {
            if (
              data.controller_name === item.name ||
              item.name.includes(data.controller_name)
            ) {
              costImpactCPURequest.push(
                getRequestBodyObject(
                  selectedGranulateService?.name,
                  data,
                  item.sku_id,
                  container?.current_cpu,
                  container?.recommended_cpu,
                  'CPU',
                  { unitDescription: 'seconds', usageUnit: 's' }
                )
              );
            }
          });
        });

        setCostImpactMemoryRequestBody(costImpactMemoryRequest);
        setCostImpactCPURequestBody(costImpactCPURequest);
      })
      .catch((e: any) => {
        onApiCallError(e, false);
      });
  };

  /**
   * @function fetchGCPCostImpact
   * @description function to get the cost impact for GCP
   */
  const fetchGCPCostImpact = async () => {
    const connectorId = selectedGranulateService?.connectorId;

    let cpuCostImpact = await fetchCPUMemoryCostImpact(
      connectorId,
      costImpactCPURequestBody
    );
    let memoryCostImpact = await fetchCPUMemoryCostImpact(
      connectorId,
      costImpactMemoryRequestBody
    );

    if (cpuCostImpact?.length > 0 || memoryCostImpact?.length > 0) {
      const memoryCost = (memoryCostImpact ?? []).reduce(
        (sum: number, item: any) => sum + (item?.costDifference ?? 0),
        0
      );

      const cpuCost = (cpuCostImpact ?? []).reduce(
        (sum: number, item: any) => sum + (item?.costDifference ?? 0),
        0
      );
      setCostImpact(cpuCost + memoryCost);
    }
  };

  /**
   * @function fetchAWSRecommendationCostData
   * @description function to get the cost impact for AWS
   */
  const fetchAWSRecommendationCostData = () => {
    if (selectedGranulateService?.serviceName) {
      setCostImpact(0);
      setRecommendationData([]);
      const { serviceName, provider, connectorId } = selectedGranulateService;
      const params = { serviceName, provider, connectorId };

      getRecommendationCostData(params)
        .then((res: any) => {
          if (res.status === 200) {
            const totalCost = (res.data ?? []).reduce(
              (cost: number, item: any) =>
                cost + Number(item?.u_cost_impact ?? 0),
              0
            );
            setCostImpact(totalCost);
            return;
          }
          onApiCallError(res.data.Message, false);
          setCostImpact(0);
        })
        .catch((e) => {
          onApiCallError(e, false);
          setCostImpact(0);
        });
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('costTrend.month'),
      dataIndex: 'month',
      key: 'month',
      align: 'center' as const,
    },
    {
      title: t('costTrend.costInCurrency', { currencySymbol: currencySymbol }),
      dataIndex: 'cost',
      key: 'cost',
      width: 180,
      render: (text: any) => numberCommaSeparator(text),
      align: 'center' as const,
    },
  ];

  /**
   * @function isDataIncluded
   * @description Function to check if the the active legends list includes the legend id
   * @param id Id to be validated
   * @returns true if the id is exists, else false
   */
  const isDataIncluded = useCallback(
    (id: string) => {
      return activeLegends.includes(id);
    },
    [activeLegends]
  );

  /**
   * @function getDataSource
   * @description returns the data with formatted type.
   * @returns costTrend data mapped with the type {month:string,cost:number,key:number}
   */
  const getDataSource = useCallback(() => {
    const data: any[] = [];
    if (
      isDataIncluded(t('costTrend.costWithoutSuffix')) ||
      isDataIncluded(t('costTrend.trend'))
    ) {
      data.push(
        ...costTrendValues.map((item) => ({
          ...item,
          month: getMonthAndYearStringFormat(item.month),
          type: t('costTrend.costWithoutSuffix'),
        }))
      );
    } else {
      data.push({
        month: null,
        cost: null,
        type: t('costTrend.costWithoutSuffix'),
      });
    }

    return data;
  }, [costTrendValues, activeLegends]);

  /**
   * @function getLegendOverrides
   * @description Function to return the custom legends
   * @returns list of custom legends
   */
  const getLegendOverrides = useCallback(() => {
    return [
      {
        name: t('costTrend.costWithoutSuffix'),
        unchecked: !isDataIncluded(t('costTrend.costWithoutSuffix')),
        marker: {
          symbol: 'square',
          style: {
            fill: theme.primaryColor,
          },
        },
      },
      {
        name: t('costTrend.trend'),
        unchecked: !isDataIncluded(t('costTrend.trend')),
        marker: {
          symbol: 'hyphen',
          style: {
            lineWidth: 2,
            stroke: theme.secondaryColor,
          },
        },
      },
    ];
  }, [activeLegends]);

  /**
   * @function getColumnColor
   * @description Function to return the column color based on the selection
   * @param data data against which the color is fetched
   * @returns string color
   */
  const getColumnColor = useCallback(
    (data: any) => {
      if (!isDataIncluded(data.type)) {
        return 'transparent';
      }
      if (data.type === t('costTrend.costWithoutSuffix')) {
        return theme.primaryColor;
      }

      return changeAlphaOfColor(theme.primaryColor, 40);
    },
    [activeLegends]
  );

  /**
   * @function getLineColor
   * @description Function to return the line color
   * @returns string color
   */
  const getLineColor = useCallback(() => {
    return !isDataIncluded(t('costByProjectMixed.trend'))
      ? 'transparent'
      : theme.secondaryColor;
  }, [activeLegends]);

  const onChangeProjectDateRange = (
    _dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => {
    setDateRange([
      moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
      moment(dateString[1], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      ),
    ]);
  };

  const filters = (
    <div className="filters flex flex-row flex-align-items-center flex-gap-16">
      <DatePicker
        defaultValue={[moment(dateRange[0]), moment(dateRange[1])]}
        picker="month"
        onChange={onChangeProjectDateRange}
        disabledDate={(current: any) => current > moment().endOf('day')}
        format={MONTH_YEAR_FORMAT}
        size="small"
      />
      <GraphFilterDropdown
        allData={allNamespaces.map((item) => ({ namespace: item }))}
        selectedData={selectedNamespaces.map((item) => ({
          namespace: item,
        }))}
        setSelectedData={(selectedItems: any[]) => {
          setSelectedNamespaces(selectedItems.map((item) => item.namespace));
        }}
        setLabels={setSelectedNamespaces}
        valueSuffix={t('containerInsight.usageMeteringLabels.namespaces')}
        fieldName={DROPDOWN_VALUE_FIELDS.NAMESPACE}
        loading={costTrendNamespaceRequestStatus === REQUEST_STATUS.PROCESSING}
        additionalClassNames="width-25"
      />
    </div>
  );

  const getComponent = () => {
    return (
      <div
        className={`graph-area full-height ${
          isCostTrendTableView && 'flex flex-fit flex-gap-16'
        }`}
      >
        <div
          className={`with-filters full-height ${
            isCostTrendTableView && 'with-table width-60 expand-hide'
          }`}
        >
          <ColumnLineChart
            data={
              showConsolidatedDashboard
                ? [
                    ...getDataSource(),
                    {
                      cost: costImpact,
                      month: getMonthAndYearStringFormat(
                        moment(dateRange[1]).format(
                          YEAR_MONTH_WITHOUT_SEPARATOR
                        )
                      ),
                      type: t('costTrend.savingsOpportunity'),
                    },
                  ]
                : getDataSource()
            }
            isColumnStack={false}
            isGroup={true}
            xField="month"
            yField="cost"
            groupingField="type"
            xTitle={t('costTrend.month')}
            yTitle={t('costTrend.costInCurrency', {
              currencySymbol: currencySymbol,
            })}
            disableAnimation={pdfView}
            prefixSymbol={currencySymbol}
            legendOverride={
              showConsolidatedDashboard
                ? [
                    ...getLegendOverrides(),
                    {
                      name: t('costTrend.savingsOpportunity'),
                      unchecked: !isDataIncluded(
                        t('costTrend.savingsOpportunity')
                      ),
                      marker: {
                        symbol: 'square',
                        style: {
                          fill: changeAlphaOfColor(theme.primaryColor, 40),
                        },
                      },
                    },
                  ]
                : getLegendOverrides()
            }
            columnColorOverride={getColumnColor}
            lineColorOverride={getLineColor}
            onClickLegends={(e: any) => {
              setActiveLegends(
                e.gEvent.delegateObject.component.cfg.items
                  .filter(
                    (item: any) =>
                      item.unchecked === undefined || !item.unchecked
                  )
                  .map((item: any) => item.name)
              );
            }}
          />
        </div>
        {isCostTrendTableView && (
          <div className="tabular-view width-40 full-height">
            <Table
              pagination={false}
              loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
              dataSource={getDataSource()}
              columns={columns}
              scroll={{ y: '100%' }}
              designVersion2={true}
              fillContainer={true}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="usage-metering-cost-trend full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.costTrend')}
        graphName="cost-trend"
        filters={filters}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostTrendTableView}
        setIsTableView={setIsCostTrendTableView}
        excelData={getCostTrendExcelData(getDataSource(), excelFilters)}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default CostTrend;
