import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { REQUEST_STATUS } from 'constants/requestBody';
import { WasteManagementType, KeyValueTypes } from 'types/dataTypes';
import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { selectScorecard } from 'redux/scorecardSlice';
import ExpandModal from 'components/ExpandModal';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { getDateLabelsByGranularity } from 'pages/ScorecardPage/utils';
import { onApiCallError } from 'utils/handleErrors';
import {
  DD_MMM_YYYY,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import { CHART_TYPES } from 'constants/graphConfig';
import { SCORECARDS_TIMERANGE } from 'pages/ScorecardPage/constants';
import { CategoryMonthlyCost } from 'pages/ScorecardPage/types';
import { fetchWMAssignedWeightage } from 'pages/ScorecardPage/services';
import { PROVIDER } from 'constants/cloudProviders';
import { getChartData, getFilteredRecommendations } from 'utils/services';
import { AWS_CHECK_IDS } from 'constants/recommendations';
import { getBillingPeriodDateFormatByProvider } from 'utils/dashboardUtils';

import WasteManagement from '../WasteManagement';
import { WASTE_MANAGEMENT_SUB_ITEMS } from './constants';
import {
  getWasteManagementExcelData,
  getWasteManagementExportColumns,
  getWasteManagementExportData,
  getWasteManagementCategoriesByProvider,
  getCostOfWasteRequestBody,
  getTotalCostRequestBody,
  getWasteManagementCategoriesLabelsByProvider,
} from './utils';

import './index.scss';

const WasteManagementScorecardDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tableViewEnabled, expandedGraphName, showExpandGraphModal } =
    useSelector(selectDashboard);
  const { selectedConnection } = useSelector(selectScorecard);

  const [weightagePercentage, setWeightagePercentage] = useState<
    KeyValueTypes[]
  >([]);
  const [
    weightagePercentageRequestStatus,
    setWeightagePercentageRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [costOfWaste, setCostOfWaste] = useState<CategoryMonthlyCost[]>([]);
  const [costOfWasteRequestStatus, setCostOfWasteRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [totalCost, setTotalCost] = useState<CategoryMonthlyCost[]>([]);
  const [totalCostRequestStatus, setTotalCostRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [wasteManagementData, setWasteManagementData] =
    useState<WasteManagementType>({
      wasteManagementRate: [],
      savingsCost: [],
    });
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(
    SCORECARDS_TIMERANGE.MONTHLY
  );
  const [wasteManagementDateRange, setWasteManagementDateRange] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);
  const [wasteManagementTableView, setWasteManagementTableView] =
    useState(false);

  useEffect(() => {
    setWasteManagementTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    setWeightagePercentage([]);
    if (selectedConnection) {
      fetchWeightagePercentage();
    }
  }, [selectedConnection]);

  useEffect(() => {
    setWasteManagementData({
      wasteManagementRate: [],
      savingsCost: [],
    });
    setCostOfWaste([]);
    if (selectedConnection?.wantBilling) {
      fetchTotalCost();
    }

    if (selectedConnection?.wantRecommendations) {
      fetchCostOfWaste();
    }
  }, [selectedConnection, selectedTimeRange, wasteManagementDateRange]);

  useEffect(() => {
    if (costOfWaste.length && totalCost.length && weightagePercentage.length) {
      const timeRangeLabels = getDateLabelsByGranularity(
        selectedTimeRange,
        wasteManagementDateRange,
        selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
          ? MONTH_YEAR_FORMAT
          : DD_MMM_YYYY
      );

      const wmData: WasteManagementType = {
        wasteManagementRate: [],
        savingsCost: [],
      };

      const categoryLabels = getWasteManagementCategoriesLabelsByProvider(
        selectedConnection?.provider
      );

      // Calculate Waste Management cost ratio for each category
      Object.values(
        getWasteManagementCategoriesByProvider(
          selectedConnection?.provider ?? ''
        )
      ).forEach((category) => {
        const categoryLabel =
          categoryLabels.find((item) => item.value === category)?.label ?? '';

        timeRangeLabels.forEach((date) => {
          const totalCostValue =
            totalCost
              .find((totalCostItem) => totalCostItem.category === category)
              ?.costData.find((costData) => costData.month === date)?.cost ?? 0;

          const costOfWasteValue =
            costOfWaste
              .find((costOfWasteItem) => costOfWasteItem.category === category)
              ?.costData.find((costData) => costData.month === date)?.cost ?? 0;

          const ratio = totalCostValue ? costOfWasteValue / totalCostValue : 0;

          wmData.savingsCost.push({
            category: categoryLabel,
            date: date,
            value: ratio,
          });

          wmData.savingsCost.push({
            category: categoryLabel,
            date: date,
            subItem: WASTE_MANAGEMENT_SUB_ITEMS.TOTAL_COST,
            value: totalCostValue,
          });

          wmData.savingsCost.push({
            category: categoryLabel,
            date: date,
            subItem: WASTE_MANAGEMENT_SUB_ITEMS.COST_OF_WASTE,
            value: costOfWasteValue,
          });
        });
      });

      // Calculate Waste Management Rate for each month/day
      timeRangeLabels.forEach((date) => {
        let wasteManagementRate = 0;
        weightagePercentage.forEach((weightage) => {
          const ratio =
            wmData.savingsCost.find(
              (savingsCost) =>
                savingsCost.category === weightage.key &&
                savingsCost.subItem === undefined &&
                savingsCost.date === date
            )?.value ?? 0;

          wasteManagementRate += Math.min(1, ratio) * Number(weightage.value);
        });
        wmData.wasteManagementRate.push({
          date: date,
          percentage: wasteManagementRate,
        });
      });

      setWasteManagementData(wmData);
    }
  }, [costOfWaste, totalCost, weightagePercentage]);

  useEffect(() => {
    if (selectedConnection) {
      dispatch(
        setExportToExcelData([
          getWasteManagementExcelData(
            wasteManagementData,
            selectedTimeRange,
            wasteManagementDateRange,
            selectedConnection
          ),
        ])
      );
    }
  }, [
    selectedConnection,
    wasteManagementData,
    selectedTimeRange,
    wasteManagementDateRange,
  ]);

  /**
   * @function fetchWeightagePercentage
   * @description Function to fetch the weightage percentage
   */
  const fetchWeightagePercentage = () => {
    setWeightagePercentageRequestStatus(REQUEST_STATUS.PROCESSING);

    fetchWMAssignedWeightage()
      .then((res: any) => {
        let responseData = JSON.parse(res?.data?.responseData)[
          selectedConnection?.provider ?? ''
        ];

        const data = Object.entries(responseData).map((item) => ({
          key:
            getWasteManagementCategoriesLabelsByProvider(
              selectedConnection?.provider
            ).find((labelItem) => labelItem.value === item[0])?.label ??
            item[0],
          value: Number(item[1]),
        }));
        setWeightagePercentage(data);
        setWeightagePercentageRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setWeightagePercentageRequestStatus);
      });
  };

  /**
   * @function fetchCostOfWaste
   * @description Function to fetch the cost of waste for all waste management categories
   */
  const fetchCostOfWaste = () => {
    setCostOfWasteRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    const wmKeys = Object.keys(
      getWasteManagementCategoriesByProvider(selectedConnection?.provider)
    );
    wmKeys.forEach((category) => {
      const requestBody = getCostOfWasteRequestBody(
        selectedConnection?.provider ?? '',
        category,
        {
          startDate: wasteManagementDateRange[0],
          endDate: wasteManagementDateRange[1],
        },
        selectedTimeRange
      );
      if (selectedConnection?.provider === PROVIDER.GCP) {
        requests.push(
          getChartData(requestBody, selectedConnection?.connectorId)
        );
        return;
      }

      requests.push(
        getFilteredRecommendations(requestBody, {
          connectorId: selectedConnection?.connectorId,
        })
      );
    });

    axios
      .all(requests)
      .then((responses) => {
        let consolidatedData: CategoryMonthlyCost[] = [];
        wmKeys.forEach((key, index) => {
          consolidatedData.push({
            category: key,
            costData: (responses.at(index)?.data || []).map((item: any) => {
              let cost = Number(item.estimatedSavings);
              if (selectedTimeRange === SCORECARDS_TIMERANGE.DAILY) {
                cost = cost / moment(item.month).daysInMonth();
              } else if (
                selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY &&
                moment(item.month, YEAR_MONTH_WITHOUT_SEPARATOR).format(
                  YEAR_MONTH_WITHOUT_SEPARATOR
                ) === moment().format(YEAR_MONTH_WITHOUT_SEPARATOR)
              ) {
                cost = (cost / moment().daysInMonth()) * (moment().date() - 1);
              }
              return {
                month:
                  selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
                    ? moment(item.month, YEAR_MONTH_WITHOUT_ZERO).format(
                        MONTH_YEAR_FORMAT
                      )
                    : moment(item.month).format(DD_MMM_YYYY),
                cost: cost,
              };
            }),
          });
        });
        setCostOfWaste(consolidatedData);
        setCostOfWasteRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostOfWasteRequestStatus);
      });
  };

  /**
   * @function fetchTotalCost
   * @description Function to fetch the total cost for all waste management categories
   */
  const fetchTotalCost = () => {
    setTotalCostRequestStatus(REQUEST_STATUS.PROCESSING);

    let requests: any[] = [];
    const wmKeys = Object.keys(
      getWasteManagementCategoriesByProvider(selectedConnection?.provider ?? '')
    );
    wmKeys.forEach((category) => {
      let params: any = {
        checkId: AWS_CHECK_IDS[category as keyof typeof AWS_CHECK_IDS],
      };
      if (selectedConnection?.provider === PROVIDER.GCP) {
        params = {};
      }

      requests.push(
        getChartData(
          getTotalCostRequestBody(
            selectedConnection?.provider ?? '',
            category,
            {
              startDate: wasteManagementDateRange[0],
              endDate: wasteManagementDateRange[1],
            },
            selectedTimeRange
          ),
          selectedConnection?.connectorId,
          params
        )
      );
    });

    axios
      .all(requests)
      .then((responses) => {
        let consolidatedData: CategoryMonthlyCost[] = [];
        wmKeys.forEach((category, index) => {
          consolidatedData.push({
            category: category,
            costData: (responses.at(index)?.data || []).map((item: any) => {
              return {
                ...item,
                month:
                  selectedTimeRange === SCORECARDS_TIMERANGE.MONTHLY
                    ? moment(
                        item.month,
                        getBillingPeriodDateFormatByProvider(
                          selectedConnection!.provider
                        )
                      ).format(MONTH_YEAR_FORMAT)
                    : moment(item.month).format(DD_MMM_YYYY),
                cost: Number(item.cost),
              };
            }),
          });
        });
        setTotalCost(consolidatedData);
        setTotalCostRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTotalCostRequestStatus);
      });
  };

  /**
   * @function getGraphComponent
   * @description Function to get the graph component
   * @param graphName string name of the graph
   * @param pdfView boolean flag to check if the graph is for pdf
   * @returns ReactNode
   */
  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    if (graphName === 'waste-management') {
      return getWasteManagementGraphComponent(pdfView);
    }
  };

  /**
   * @function getWasteManagementGraphComponent
   * @description Function to get the waste management graph component
   * @param pdfView boolean flag to check if the graph is for pdf
   * @param pdfDateRange string[] date range for pdf
   * @returns ReactNode
   */
  const getWasteManagementGraphComponent = (
    pdfView: boolean = false,
    pdfDateRange?: string[]
  ) => {
    return (
      <WasteManagement
        wasteManagementData={wasteManagementData}
        requestStatus={[
          weightagePercentageRequestStatus,
          totalCostRequestStatus,
          costOfWasteRequestStatus,
        ]}
        pdfView={pdfView}
        selectedTimeRange={selectedTimeRange}
        setSelectedTimeRange={setSelectedTimeRange}
        wasteManagementDateRange={pdfDateRange ?? wasteManagementDateRange}
        setWasteManagementDateRange={setWasteManagementDateRange}
        wasteManagementTableView={wasteManagementTableView}
        setWasteManagementTableView={setWasteManagementTableView}
        weightagePercentage={weightagePercentage}
      />
    );
  };

  if (
    selectedConnection &&
    (!selectedConnection.wantBilling || !selectedConnection.wantRecommendations)
  ) {
    if (!selectedConnection.wantBilling)
      return <div>{t('scorecard.noBillingAccess')}</div>;

    if (!selectedConnection.wantRecommendations)
      return <div>{t('scorecard.noRecommendationAccess')}</div>;
  }

  return (
    <>
      <div className="waste-management-scorecard-dashboard">
        {getGraphComponent('waste-management')}
      </div>
      {showExpandGraphModal && (
        <ExpandModal
          show={showExpandGraphModal}
          graphContent={getGraphComponent(expandedGraphName)}
        />
      )}
      {
        <PdfDownloadComponent
          pdfContent={[
            {
              element: getGraphComponent('waste-management', true),
              contentType: CHART_TYPES.BAR_LINE_CHART,
              graphName: 'waste-management',
              isTableView: wasteManagementTableView,
              column: getWasteManagementExportColumns(
                selectedTimeRange,
                wasteManagementDateRange
              ),
              body: insertIndex(
                getWasteManagementExportData(
                  selectedConnection?.provider,
                  wasteManagementData,
                  selectedTimeRange,
                  wasteManagementDateRange,
                  true
                )
              ),
              boldRows: getWasteManagementExportData(
                selectedConnection?.provider,
                wasteManagementData,
                selectedTimeRange,
                wasteManagementDateRange
              )
                .map((value, index) => (!value.isSubItem ? index : -1))
                .filter((value) => value !== -1),
              tableName: t('graphHeadings.wasteManagementEffectiveness'),
              tableFooterData: {
                totalTableData: [
                  t('scorecard.wasteManagement.effectiveWasteManagementRate'),
                  ...wasteManagementData.wasteManagementRate.map(
                    (value) => `${numberCommaSeparator(value.percentage)}%`
                  ),
                ],
              },
            },
          ]}
          pdfMetaData={{
            viewName: t('navigationMenu.scorecards'),
            fileName: selectedConnection?.name ?? '',
            heading: selectedConnection?.name ?? '',
            subtitle1: '',
            provider: selectedConnection?.provider ?? '',
          }}
        />
      }
    </>
  );
};

export default WasteManagementScorecardDashboard;
