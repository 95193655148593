import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch } from 'react-redux';

import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import Button from 'components/Button';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import Icon from 'components/Icon';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import { BUTTON_TYPE } from 'constants/appearance';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { TeamsType } from 'pages/TeamsPage/types';
import { TeamsComponents } from 'pages/TeamsPage/constants';
import { UserInfoType } from 'types/userManagementConsole';
import { onApiCallError } from 'utils/handleErrors';
import { getAllUsersData, getSearchUserData } from 'utils/services';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
} from 'utils/validations';
import {
  createTeam,
  updateTeam,
  validateUniqueTeamName,
} from 'pages/TeamsPage/services';

import UserRow from '../UserRow';

import './index.scss';

type AddOrEditTeamDrawerProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  teamData: TeamsType;
  setTeamData: (val: TeamsType) => void;
  isEdit: boolean;
  setComponentRendered: (val: TeamsComponents) => void;
};

const AddOrEditTeamDrawer = ({
  show,
  setShow,
  teamData,
  setTeamData,
  isEdit,
  setComponentRendered,
}: AddOrEditTeamDrawerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [teamNameValidation, setTeamNameValidation] = useState('');
  const [users, setUsers] = useState<UserInfoType[]>([]);
  const [fetchUsersReqStatus, setFetchUsersReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [searchKey, setSearchKey] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [usersListValidation, setUsersListValidation] = useState('');
  const [createTeamReqStatus, setCreateTeamReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [isDuplicateTeamName, setIsDuplicateTeamName] = useState(false);

  useEffect(() => {
    setFetchUsersReqStatus(REQUEST_STATUS.PROCESSING);

    let source = axios.CancelToken.source();
    fetchUserData(source);

    return () => {
      source.cancel();
    };
  }, [searchKey]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (!isEdit && !!teamData.teamName) {
      validateForUniqueTeamName(source);
    }

    return () => {
      source.cancel();
    };
  }, [teamData.teamName]);

  /**
   * @function fetchUserData
   * @description Function to fetch the users data
   * @param cancelTokenSource token to cancel the request
   */
  const fetchUserData = (cancelTokenSource: CancelTokenSource) => {
    setFetchUsersReqStatus(REQUEST_STATUS.PROCESSING);

    const params = { key: searchKey };

    (searchKey
      ? getSearchUserData(params, cancelTokenSource.token)
      : getAllUsersData({}, cancelTokenSource.token)
    )
      .then((respose: any) => {
        const { data } = respose;
        const usersList = data?.content
          ?.filter(
            (item: any) =>
              !teamData.userDetailDtoList.some(
                (user) => user.email === item.email
              )
          )
          ?.map((item: any) => ({
            email: item.email,
            name: item.name,
          }));
        setUsers(usersList);
        setFetchUsersReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchUsersReqStatus);
      });
  };

  /**
   * @function validateForUniqueTeamName
   * @description Function to validate the unique name
   * @param cancelTokenSource cancel token for the API call
   */
  const validateForUniqueTeamName = (cancelTokenSource: CancelTokenSource) => {
    validateUniqueTeamName(teamData.teamName, cancelTokenSource.token)
      .then((res: any) => {
        const isDuplicateName = res?.data?.responseData;
        setIsDuplicateTeamName(isDuplicateName);
        isDuplicateName
          ? setTeamNameValidation(t('teamsLabels.uniqueTeamNameError'))
          : setTeamNameValidation('');
      })
      .catch((e) => {
        onApiCallError(e, false);
      });
  };

  /**
   * @function validateTeamName
   * @description Function to validate the team name
   * @param teamName team name for validation
   * @returns boolean true if validation is successful else false
   */
  const validateTeamName = (teamName: string) => {
    if (
      validateEmptyField(
        teamName.trim(),
        t('teamsLabels.teamName'),
        setTeamNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        teamName.trim(),
        t('teamsLabels.teamName'),
        setTeamNameValidation
      )
    ) {
      return false;
    }

    if (!isEdit && isDuplicateTeamName) {
      setTeamNameValidation(t('teamsLabels.uniqueTeamNameError'));
      return false;
    }

    return true;
  };

  /**
   * @function validateUserList
   * @description Function to validate the users list
   * @returns boolean true if validation is successful else false
   */
  const validateUserList = () => {
    if (teamData.userDetailDtoList.length < 2) {
      setUsersListValidation(t('teamsLabels.userListLimitValidationMessage'));
      return false;
    }

    return true;
  };

  /**
   * @function onHandleUserSelect
   * @description Callback function for user selection
   * @param _e event for select
   * @param option user option selected
   */
  const onHandleUserSelect = (_e: any, option: any) => {
    setUsersListValidation('');

    const selectedUsersList = [
      ...teamData.userDetailDtoList,
      { name: option.label, email: option.value },
    ];
    setTeamData({ ...teamData, userDetailDtoList: selectedUsersList });
    const filteredUsers = users?.filter(
      (item: any) =>
        !selectedUsersList.some((user) => user.email === item.email)
    );
    setUsers(filteredUsers);
    if (filteredUsers.length === 0) {
      setOpenDropdown(false);
      setSearchKey('');
    }
  };

  /**
   * @function onClickRemoveUserFromTeam
   * @description Function to remove the user from selection
   * @param user user removed
   */
  const onClickRemoveUserFromTeam = (user: UserInfoType) => {
    setTeamData({
      ...teamData,
      userDetailDtoList: teamData.userDetailDtoList.filter(
        (item) => item.email !== user.email
      ),
    });
    setUsers([...users, user]);
  };

  /**
   * @function onError
   * @description Callback function for an error
   */
  const onError = () => {
    setCreateTeamReqStatus(REQUEST_STATUS.ERROR);
    setShow(false);
    setComponentRendered(TeamsComponents.ERROR_COMPONENT);
  };

  /**
   * @function createOrUpdateTeam
   * @description Function to create or update a team
   */
  const createOrUpdateTeam = () => {
    setCreateTeamReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody: any = {
      ...teamData,
      createdBy: localStorage.getItem('email'),
      cbUserList: teamData.userDetailDtoList.map((item) => item.email),
    };

    if (isEdit) {
      requestBody.updatedBy = localStorage.getItem('email');
    }

    (isEdit ? updateTeam(requestBody) : createTeam(requestBody))
      .then((res: any) => {
        if (res?.status === 200) {
          setCreateTeamReqStatus(REQUEST_STATUS.SUCCESS);
          setShow(false);
          setComponentRendered(TeamsComponents.SUCCESS_COMPONENT);
          return;
        }
        onError();
      })
      .catch((e) => {
        onApiCallError(e, false);
        dispatch(setErrorMessage(e?.response?.data?.message));
        onError();
      });
  };

  /**
   * @function validateTeamForm
   * @description Function to validate the team form
   * @returns true if the validation is successful else false
   */
  const validateTeamForm = () => {
    let validation = true;
    if (!validateTeamName(teamData.teamName)) {
      validation = false;
    }

    if (!validateUserList()) {
      validation = false;
    }

    return validation;
  };

  const onCLickCreateTeam = () => {
    if (!validateTeamForm()) {
      return;
    }

    createOrUpdateTeam();
  };

  const getUsersListDropdownRender = (originalNode: ReactNode) =>
    fetchUsersReqStatus === REQUEST_STATUS.PROCESSING ? (
      <Skeleton active paragraph={{ rows: 5 }} title={false} />
    ) : (
      originalNode
    );

  return (
    <DrawerComponent
      className="add-or-edit-teams-drawer"
      open={show}
      width={481}
      title={
        isEdit ? t('teamsLabels.updateATeam') : t('teamsLabels.createATeam')
      }
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-align-items-center flex-end flex-gap-8">
          <Button
            title={t('teamsLabels.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={isEdit ? t('teamsLabels.update') : t('teamsLabels.create')}
            onClick={onCLickCreateTeam}
            loading={createTeamReqStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
      }
    >
      <div className="flex flex-column flex-gap-24">
        <div>
          <FormLabel
            title={t('teamsLabels.teamName')}
            required
            disabled={isEdit}
          />
          <Input
            type="input"
            value={teamData.teamName}
            placeholder={t('teamsLabels.enterTeamName')}
            onChange={(e: any) => {
              setTeamData({ ...teamData, teamName: e.target.value });
              validateTeamName(e.target.value);
            }}
            onBlur={() => validateTeamName(teamData.teamName)}
            disabled={isEdit}
          />
          <span
            style={{
              display: `${getValidationStyle(teamNameValidation)}`,
            }}
            className="font-validation-error"
          >
            {teamNameValidation}
          </span>
        </div>
        <div className="team-users-list flex flex-column flex-gap-16">
          <SelectDropdown
            rootClassName="full-width"
            labelInValue
            options={users.map((item) => ({
              label: item.name,
              value: item.email,
            }))}
            value={{
              value: '',
              label: searchKey || (
                <span className="search-placeholder table-typography">
                  {t('teamsLabels.searchUsers')}
                </span>
              ),
            }}
            placeholder={t('teamsLabels.searchUsers')}
            filterOption={false}
            dropdownRender={getUsersListDropdownRender}
            showSearch
            onSearch={(searchValue: string) => {
              setUsers([]);
              setSearchKey(searchValue);
            }}
            open={openDropdown}
            suffixIcon={<Icon iconName={ICONS.SEARCH_2_LINE} />}
            onChange={onHandleUserSelect}
            designVersion2={true}
            onBlur={() => {
              setOpenDropdown(false);
              setSearchKey('');
            }}
            onFocus={() => setOpenDropdown(true)}
            onInputKeyDown={() => setOpenDropdown(true)}
          />
          <div className="selected-users full-width flex flex-align-items-center">
            <Icon
              className="arrow-down"
              iconName={ICONS.ARROW_DOWN_S_LINE}
              size={ICONS_SIZE.XL}
            />
            <div className="font-caption-bold">
              {t('teamsLabels.selectedUsers')}
            </div>
            <div className="users-count font-tooltip-small flex flex-end flex-fit">
              <span className="users-count-value">
                {teamData.userDetailDtoList.length}
              </span>
            </div>
          </div>
          <div className="team-users-list flex flex-column flex-gap-12 new-styled-scroll">
            {teamData.userDetailDtoList.map((user) => (
              <UserRow
                key={user.email}
                user={user}
                onClickRemoveUserFromTeam={onClickRemoveUserFromTeam}
              />
            ))}
          </div>
          <span
            style={{
              display: `${getValidationStyle(usersListValidation)}`,
            }}
            className="font-validation-error"
          >
            {usersListValidation}
          </span>
        </div>
      </div>
    </DrawerComponent>
  );
};

export default AddOrEditTeamDrawer;
