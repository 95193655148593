import { SketchPicker } from 'react-color';
import './index.scss';

type ColorPickerProps = {
  color: string;
  onChange: (color: string) => void;
};

const ColorPicker = ({ color, onChange }: ColorPickerProps) => {
  return (
    <SketchPicker
      className="custom-color-picker flex flex-column flex-gap-16"
      color={color}
      disableAlpha={true}
      onChange={(newColor) => {
        onChange(newColor.hex);
      }}
    />
  );
};

export default ColorPicker;
