import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AWSConnection } from 'redux/gProfilerConnectionSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import { PROVIDER } from 'constants/cloudProviders';
import { Radio, Space } from 'antd';
import { FormLabel } from 'components/FormLabel';
import CheckRadio from 'components/CheckRadio';
import SelectDropDown from 'components/Select';
import Button from 'components/Button';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { BUTTON_TYPE } from 'constants/appearance';
import { ICONS } from 'constants/icons';
import Input from 'components/Input';
import { GProfilerConnectionRadioValue } from 'constants/gProfiler';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { addNewCSPConnection, searchConnectionData } from 'utils/services';
import { TickFillError } from 'assets/icons';
import {
  validateEmptyField,
  validateStringLengthLessThan,
  containsUnderscoreAndSpace,
  isNumber,
  validateContainsOnlyAlphabets,
  getValidationStyle,
} from 'utils/validations';
import {
  MAX_CHARACTER_LIMIT,
  VALIDATION_MIN_INPUT_LENGTH,
} from 'constants/validation';
import DashboardComponent from 'components/DashboardComponent';

import ConnectionFormHeader from '../../components/GProfilerConnectionPage/components/ConnectionForm/components/ConnectionFormHeader';
import ConnectionFormCard from '../../components/GProfilerConnectionPage/components/ConnectionForm/components/Card';

type GranulateAppsAWSConnectionProps = {
  setSubmitDisabled: (val: boolean) => void;
  awsConnection: AWSConnection;
  serviceList: string[];
  zones: string[];
  clusterList: string[];
  fetchingClusterStatus: string;
  inputLoaderStatus: string;
  setInputLoaderStatus: (status: string) => void;
  resetAWSConnection: () => void;
  addConnectorIdForNewConnection: (connectorId: string) => void;
  dispatchCloudConnectionAndConnectorId: (
    cloudConnection: string,
    connectorId: string
  ) => void;
  dispatchConnectionName: (connectionName: string) => void;
  dispatchSecretKey: (secretKey: string) => void;
  dispatchAccessKey: (accessKey: string) => void;
  dispatchServiceName: (serviceName: string) => void;
  dispatchRegionAndResetingCluster: (region: string) => void;
  dispatchCluster: (cluster: string) => void;
  setFetchingClusterStatus: (status: string) => void;
  clustersErrorMessage: string;
  setClustersErrorMessage: (val: string) => void;
  fetchClusterList: (zone: string) => void;
  fetchServiceList: () => void;
  fetchZones: () => void;
  setClusterList: (clusterList: string[]) => void;
  hideSetupConnection?: boolean;
};

const GranulateAppsAWSConnection = ({
  setSubmitDisabled,
  awsConnection,
  fetchClusterList,
  fetchServiceList,
  fetchZones,
  fetchingClusterStatus,
  setFetchingClusterStatus,
  clustersErrorMessage,
  setClustersErrorMessage,
  inputLoaderStatus,
  setInputLoaderStatus,
  clusterList,
  setClusterList,
  zones,
  serviceList,
  resetAWSConnection,
  addConnectorIdForNewConnection,
  dispatchCluster,
  dispatchRegionAndResetingCluster,
  dispatchCloudConnectionAndConnectorId,
  dispatchServiceName,
  dispatchConnectionName,
  dispatchSecretKey,
  dispatchAccessKey,
  hideSetupConnection = false,
}: GranulateAppsAWSConnectionProps) => {
  const { t } = useTranslation();

  const { permissions } = useSelector(userAuthorization);

  const [checkValue, setCheckValue] = useState<string>(
    GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION
  );
  const [connectionList, setConnectionList] = useState<any[]>([]);
  const [region, setRegion] = useState<string>('');
  const [cluster, setCluster] = useState<string | undefined>(undefined);
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);

  const [showValidatedButton, setShowValidatedButton] = useState<boolean>(true);
  const [validationRequestStatus, setValidationRequestStatus] =
    useState<string>('');
  const [cloudConnectionValidation, setCloudConnectionValidation] =
    useState('');
  const [serviceNameValidation, setServiceNameValidation] = useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [clusterValidation, setClusterValidation] = useState('');
  const [connectionNameValidation, setConnectionNameValidation] = useState('');
  const [accessKeyValidation, setAccessKeyValidation] = useState('');
  const [secretKeyValidation, setSecretKeyValidation] = useState('');

  const isSecretKeyEmpty = !awsConnection.secretKey;
  const isAccessKeyEmpty = !awsConnection.accessKey;
  const isConnectionNameEmpty = !awsConnection.connectionName;

  const isButtonDisabled = () =>
    isSecretKeyEmpty ||
    isAccessKeyEmpty ||
    isConnectionNameEmpty ||
    connectionNameValidation;

  useEffect(() => {
    resetAWSConnection();
    setInputLoaderStatus(REQUEST_STATUS.SUCCESS);
    setCloudConnectionValidation('');
    setServiceNameValidation('');
    setRegionValidation('');
    setConnectionNameValidation('');
    setClusterValidation('');
    setAccessKeyValidation('');
    setSecretKeyValidation('');
  }, [checkValue]);

  useEffect(() => {
    setCluster('');
    setRegion('');
    setFetchingClusterStatus(REQUEST_STATUS.SUCCESS);
    setClustersErrorMessage('');
    setClusterList([]);
  }, [
    awsConnection.cloudConnection,
    awsConnection.secretKey,
    awsConnection.accessKey,
  ]);

  useEffect(() => {
    fetchConnectionData();
    fetchServiceList();
  }, []);

  useEffect(() => {
    setShowValidatedButton(true);
  }, [
    awsConnection.secretKey,
    awsConnection.accessKey,
    awsConnection.connectionName,
  ]);

  useEffect(() => {
    if (awsConnection.connectorId) {
      fetchZones();
    }
  }, [awsConnection.connectorId]);

  useEffect(() => {
    setSubmitDisabled(!validateAllFields(false));
  }, [awsConnection]);

  /**
   * @function fetchConnectionData
   * @description Function to get connection list of AWS
   */
  const fetchConnectionData = () => {
    searchConnectionData({ provider: PROVIDER.AWS })
      .then((res: any) => {
        setConnectionList(res?.data?.responseData?.content);
      })
      .catch((e) => onApiCallError(e, true));
  };

  /**
   * @function handleValidation
   * @description Function to validate secret key and accessId key to get connectorId.
   */
  const handleValidation = () => {
    const { secretKey, accessKey, connectionName } = awsConnection;
    if (secretKey && accessKey && connectionName) {
      setValidationRequestStatus(REQUEST_STATUS.PROCESSING);
      const body = {
        provider: PROVIDER.AWS,
        awsSecretKey: secretKey,
        awsAccessKeyId: accessKey,
        name: connectionName,
        displayName: connectionName,
        wantBilling: false,
        wantRecommendations: false,
        wantCarbonFootprint: false,
      };

      addNewCSPConnection(body)
        .then((res: any) => {
          if (res.status === 200) {
            setValidationRequestStatus(REQUEST_STATUS.SUCCESS);
            setShowValidatedButton(false);
            addConnectorIdForNewConnection(res?.data?.responseData);
            fetchConnectionData();
          } else {
            setValidationRequestStatus(REQUEST_STATUS.ERROR);
            setShowValidatedButton(false);
          }
        })
        .catch((e) => {
          setShowValidatedButton(false);
          setValidationRequestStatus(REQUEST_STATUS.ERROR);
          onApiCallError(e, true);
        });
    }
  };

  /**
   * @function validateCloudConnection
   * @description Function to validate the cloud connection field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateCloudConnection = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.cloudConnectionLowerCase'),
      setCloudConnectionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateServiceName
   * @description Function to validate the service name field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateServiceName = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (
      validateEmptyField(
        value,
        t('gProfilerConnectionPage.connectionForm.serviceNameLowerCase'),
        setServiceNameValidation,
        addErrorMessage
      )
    ) {
      return false;
    }

    if (!validateContainsOnlyAlphabets(value?.charAt(0) || '')) {
      setServiceNameValidation(t('firstCharacterError'));
      return false;
    }

    if (containsUnderscoreAndSpace(value)) {
      setServiceNameValidation(
        `${t(
          'gProfilerConnectionPage.connectionForm.serviceNameLowerCase'
        )} ${t('underscoreError')}`
      );
      return false;
    }

    if (
      !validateContainsOnlyAlphabets(value?.charAt(value?.length - 1) || '') &&
      !isNumber(value?.charAt(value?.length - 1))
    ) {
      setServiceNameValidation(t('lastCharacterError'));
      return false;
    }

    setServiceNameValidation('');
    return true;
  };

  /**
   * @function validateRegion
   * @description Function to validate the region field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateRegion = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.region'),
      setRegionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateCluster
   * @description Function to validate the cluster field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateCluster = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.cluster'),
      setClusterValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateConnectionName
   * @description Function to validate the connection name field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateConnectionName = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (
      validateEmptyField(
        value,
        t('gProfilerConnectionPage.connectionForm.connectionNameLowerCase'),
        setConnectionNameValidation,
        addErrorMessage
      )
    ) {
      return false;
    }

    if (isNumber(value?.charAt(0))) {
      setConnectionNameValidation(
        t('gProfilerConnectionPage.connectionForm.firstCharacterNumberError', {
          field: t(
            'gProfilerConnectionPage.connectionForm.connectionNameLowerCase'
          ),
        })
      );
      return false;
    }

    if (
      connectionList.some(
        (item) =>
          item?.name?.trim().toLowerCase() === value?.trim()?.toLowerCase()
      )
    ) {
      addErrorMessage &&
        setConnectionNameValidation(
          t('gProfilerConnectionPage.connectionForm.duplicateConnectionName')
        );
      return false;
    }

    if (
      !validateStringLengthLessThan(
        (value || '')?.trim(),
        VALIDATION_MIN_INPUT_LENGTH - 1
      )
    ) {
      addErrorMessage &&
        setConnectionNameValidation(
          t('characterLessThanValidation', {
            field: t(
              'gProfilerConnectionPage.connectionForm.connectionNameLowerCase'
            ),
            characterCount: VALIDATION_MIN_INPUT_LENGTH,
          })
        );
      return false;
    }

    if (
      validateStringLengthLessThan((value || '')?.trim(), MAX_CHARACTER_LIMIT)
    ) {
      addErrorMessage &&
        setConnectionNameValidation(
          t('characterGreaterThanValidation', {
            field: t(
              'gProfilerConnectionPage.connectionForm.connectionNameLowerCase'
            ),
            characterCount: MAX_CHARACTER_LIMIT,
          })
        );
      return false;
    }
    return true;
  };

  /**
   * @function validateAccessId
   * @description Function to validate the access id field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAccessId = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.accessKeyId'),
      setAccessKeyValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateSecretKey
   * @description Function to validate the secret key field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateSecretKey = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.secretKeyLabel'),
      setSecretKeyValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAllFields = (addErrorMessage: boolean = true) => {
    let validation = true;

    if (checkValue === GProfilerConnectionRadioValue.NEW_CLOUD_CONNECTION) {
      if (!validateAccessId(awsConnection.accessKey, addErrorMessage)) {
        validation = false;
      }

      if (!validateSecretKey(awsConnection.secretKey, addErrorMessage)) {
        validation = false;
      }
    }
    if (!validateCloudConnection(awsConnection.connectorId, addErrorMessage)) {
      validation = false;
    }

    if (!validateServiceName(awsConnection.serviceName, addErrorMessage)) {
      validation = false;
    }

    if (!validateRegion(awsConnection.region, addErrorMessage)) {
      validation = false;
    }

    if (!validateCluster(awsConnection.cluster, addErrorMessage)) {
      validation = false;
    }

    return validation;
  };

  const getRegionAndClusterDropdown = () => (
    <div className="region-cluster flex">
      <div className="region flex-fit flex flex-column flex-gap-8 new-styled-scroll">
        <span className="region-header font-small-bold">
          {t('gProfilerConnectionPage.connectionForm.region')}
        </span>
        <Radio.Group
          value={region}
          onChange={(e: any) => {
            setCluster('');
            setRegion(e.target.value);
            fetchClusterList(e.target.value);
            dispatchRegionAndResetingCluster(e.target.value);
            validateRegion(e.target.value);
          }}
        >
          <Space direction="vertical">
            {zones.map((zone) => (
              <CheckRadio value={zone} className="font-caption" key={zone}>
                {zone}
              </CheckRadio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      <div className="cluster flex-fit flex flex-column flex-gap-4 new-styled-scroll">
        <span className="cluster-header font-small-bold">
          {t('gProfilerConnectionPage.connectionForm.cluster')}
        </span>
        <DashboardComponent
          component={
            <Radio.Group
              value={cluster}
              onChange={(e: any) => {
                setCluster(e.target.value);
                dispatchCluster(e.target.value);
                validateCluster(e.target.value);
              }}
            >
              <Space direction="vertical">
                {clusterList?.length > 0 ? (
                  clusterList.map((clusterObj: any) => (
                    <CheckRadio
                      value={clusterObj.clusterName}
                      className="font-caption"
                      key={clusterObj.clusterName}
                      disabled={clusterObj.status}
                    >
                      {clusterObj.clusterName}
                    </CheckRadio>
                  ))
                ) : (
                  <span className="font-caption">
                    {t(
                      'gProfilerConnectionPage.connectionForm.clusterNotAvailable'
                    )}
                  </span>
                )}
              </Space>
            </Radio.Group>
          }
          requestStatus={fetchingClusterStatus}
          errorMessage={clustersErrorMessage}
        />
      </div>
    </div>
  );

  const getServiceDropdown = () => (
    <div className="service-dropdown flex flex-column flex-gap-8">
      <Input
        value={searchKey}
        className="full-width"
        placeholder={t('gProfilerConnectionPage.connectionForm.searchService')}
        type="search"
        onChange={(e: any) => setSearchKey(e.target.value)}
      />
      <div className="flex flex-column">
        {searchKey &&
          serviceList.filter(
            (service) =>
              service.toLowerCase() === (searchKey?.toLowerCase() || '')
          ).length === 0 && (
            <div className="flex flex-space-between full-width flex-align-items-center">
              <span>{searchKey}</span>
              <Button
                className="cursor-pointer"
                type={BUTTON_TYPE.LINK}
                title={t(
                  'gProfilerConnectionPage.connectionForm.addNewService'
                )}
                onClick={() => {
                  dispatchServiceName(searchKey);
                  validateServiceName(searchKey);
                  setSearchKey('');
                }}
              />
            </div>
          )}
        <div className="flex flex-column flex-gap-4">
          {serviceList
            .filter((service) =>
              service.toLowerCase().includes(searchKey?.toLowerCase() ?? '')
            )
            .map((service) => (
              <AccessibleDiv
                className={`font-caption cursor-pointer ${
                  awsConnection.serviceName === service &&
                  'selected-dropdown-option'
                } `}
                onClick={() => {
                  dispatchServiceName(service);
                  validateServiceName(service);
                  setSearchKey('');
                }}
                key={service}
              >
                {service}
              </AccessibleDiv>
            ))}
        </div>
      </div>
    </div>
  );

  const errorMessage = (message: string) => (
    <div className="flex flex-gap-4">
      <span className="font-caption font-validation-error">{message}</span>
      <Icon icon={TickFillError} />
    </div>
  );

  const ValidationMessage =
    validationRequestStatus === REQUEST_STATUS.SUCCESS ? (
      <div className="flex flex-gap-4">
        <span className="font-caption">
          {t('gProfilerConnectionPage.connectionForm.validationSuccessful')}
        </span>
        <Icon iconName={ICONS.CHECKBOX_CIRCLE_FILL} className="tick-success" />
      </div>
    ) : (
      errorMessage(
        t('gProfilerConnectionPage.connectionForm.awsValidationFailed')
      )
    );

  const ValidationContent = showValidatedButton ? (
    <Button
      type={BUTTON_TYPE.NEUTRAL}
      title={t('gProfilerConnectionPage.connectionForm.validate')}
      onClick={handleValidation}
      disabled={isButtonDisabled()}
      loading={validationRequestStatus === REQUEST_STATUS.PROCESSING}
    />
  ) : (
    ValidationMessage
  );

  const RegionClusterValidation = regionValidation || clusterValidation;

  const getNewConnectionValidation = () =>
    permissions.cloudConnectorWrite
      ? ValidationContent
      : errorMessage(
          t('gProfilerConnectionPage.connectionForm.connectorWriteValidation')
        );

  return (
    <ConnectionFormCard>
      <ConnectionFormHeader provider={PROVIDER.AWS} />
      <section className="connection-form-section flex-fit full-width flex flex-column flex-gap-16">
        {!hideSetupConnection && (
          <div className="form-item flex flex-column">
            <FormLabel
              title={t(
                'gProfilerConnectionPage.connectionForm.setupConnectionFrom'
              )}
              required={true}
            />
            <Radio.Group
              onChange={(e: any) => {
                setCheckValue(e.target.value);
              }}
              value={checkValue}
            >
              <Space direction="vertical">
                <CheckRadio
                  value={
                    GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION
                  }
                  className="font-caption"
                  checked={true}
                >
                  {t(
                    'gProfilerConnectionPage.connectionForm.existingCloudConnection'
                  )}
                </CheckRadio>
                <CheckRadio
                  value={GProfilerConnectionRadioValue.NEW_CLOUD_CONNECTION}
                  className="font-caption"
                >
                  {t(
                    'gProfilerConnectionPage.connectionForm.newCloudConnectoin'
                  )}
                </CheckRadio>
              </Space>
            </Radio.Group>
          </div>
        )}
        {checkValue ===
        GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION ? (
          <div className="form-item flex flex-column">
            <FormLabel
              title={t(
                'gProfilerConnectionPage.connectionForm.cloudConnection'
              )}
              required={true}
            />
            <SelectDropDown
              placeholder={t(
                'gProfilerConnectionPage.connectionForm.selectCloudConnection'
              )}
              className="select-item"
              options={connectionList.map((item) => ({
                value: item.name,
                label: item.name,
                connectorId: item.connectorId,
              }))}
              onChange={(_value: any, data: any) => {
                dispatchCloudConnectionAndConnectorId(
                  data.value,
                  data.connectorId
                );
                validateCloudConnection(data.connectorId);
              }}
              onBlur={() => validateCloudConnection(awsConnection?.connectorId)}
              designVersion2
            />
            <span
              style={{
                display: getValidationStyle(cloudConnectionValidation),
              }}
              className="font-validation-error"
            >
              {cloudConnectionValidation}
            </span>
          </div>
        ) : (
          <>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t(
                  'gProfilerConnectionPage.connectionForm.connectionName'
                )}
                required={true}
              />
              <Input
                className="select-item"
                onChange={(e: any) => {
                  dispatchConnectionName(e.target.value);
                  validateConnectionName(e.target.value);
                }}
                onBlur={() =>
                  validateConnectionName(awsConnection?.connectionName)
                }
              />
              <span
                style={{
                  display: getValidationStyle(connectionNameValidation),
                }}
                className="font-validation-error"
              >
                {connectionNameValidation}
              </span>
            </div>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t('gProfilerConnectionPage.connectionForm.accessKeyId')}
                required={true}
              />
              <Input
                className="select-item"
                type="password"
                onChange={(e: any) => {
                  dispatchAccessKey(e.target.value);
                  validateAccessId(e.target.value);
                }}
                onBlur={() => validateAccessId(awsConnection?.accessKey)}
              />
              <span
                style={{
                  display: getValidationStyle(accessKeyValidation),
                }}
                className="font-validation-error"
              >
                {accessKeyValidation}
              </span>
            </div>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t(
                  'gProfilerConnectionPage.connectionForm.secretKeyLabel'
                )}
                required={true}
              />
              <Input
                className="select-item"
                type="password"
                onChange={(e: any) => {
                  dispatchSecretKey(e.target.value);
                  validateSecretKey(e.target.value);
                }}
                onBlur={() => validateSecretKey(awsConnection.secretKey)}
              />
              <span
                style={{
                  display: getValidationStyle(secretKeyValidation),
                }}
                className="font-validation-error"
              >
                {secretKeyValidation}
              </span>
            </div>
            <div className="validate-btn flex flex-end">
              {getNewConnectionValidation()}
            </div>
          </>
        )}
        <div
          className={`form-item flex flex-column ${
            checkValue === GProfilerConnectionRadioValue.NEW_CLOUD_CONNECTION &&
            showValidatedButton &&
            'disable'
          }`}
        >
          <FormLabel
            title={t('gProfilerConnectionPage.connectionForm.serviceName')}
            required={true}
          />
          <SelectDropDown
            placeholder={t(
              'gProfilerConnectionPage.connectionForm.chooseAServiceName'
            )}
            className="select-item"
            dropdownRender={getServiceDropdown}
            value={awsConnection.serviceName}
            onBlur={() => validateServiceName(awsConnection.serviceName)}
            designVersion2
          />
          <span
            style={{
              display: getValidationStyle(serviceNameValidation),
            }}
            className="font-validation-error"
          >
            {serviceNameValidation}
          </span>
        </div>
        <div
          className={`form-item flex flex-column ${
            checkValue === GProfilerConnectionRadioValue.NEW_CLOUD_CONNECTION &&
            showValidatedButton
              ? 'disable'
              : ''
          }`}
        >
          <FormLabel
            title={t('gProfilerConnectionPage.connectionForm.regionAndCluster')}
            required={true}
          />
          <SelectDropDown
            placeholder={t(
              'gProfilerConnectionPage.connectionForm.regionAndCluster'
            )}
            className="select-item"
            value={`${region} ${cluster}`}
            dropdownRender={getRegionAndClusterDropdown}
            loading={inputLoaderStatus === REQUEST_STATUS.PROCESSING}
            onBlur={() => {
              validateRegion(awsConnection.region);
              validateCluster(awsConnection.cluster);
            }}
            disabled={
              (checkValue ===
                GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION &&
                !awsConnection.cloudConnection) ||
              inputLoaderStatus === REQUEST_STATUS.PROCESSING
            }
            designVersion2
          />
          <span
            style={{
              display: getValidationStyle(RegionClusterValidation),
            }}
            className="font-validation-error"
          >
            {RegionClusterValidation}
          </span>
        </div>
      </section>
    </ConnectionFormCard>
  );
};

export default GranulateAppsAWSConnection;
