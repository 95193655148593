import { useEffect, useState } from 'react';
import {
  Col,
  Empty,
  message,
  Radio,
  Select,
  Table,
  TablePaginationConfig,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FilterDropdownProps } from 'antd/lib/table/interface';

import TableFilterDropdown from 'components/TableFilterDropdown';
import {
  setServiceNowData,
  setTicketIntegrationData,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';
import {
  costOptimizationInsights,
  setApplicableRecommendations,
  setCurrentRecommendationView,
  setSelectedAwsRecommendations,
} from 'redux/costOptimizationInsightsSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import { selectTheme } from 'redux/themeSlice';
import InfiniteScrollTable from 'components/InfiniteScrollTable';
import QuickActionMenu from 'components/QuickActionMenu';
import Button from 'components/Button';
import SelectDropdown from 'components/Select';
import ExpandModal from 'components/ExpandModal';
import Loader from 'components/Loader';
import AccessibleDiv from 'components/AccessibleDiv';
import CreateTicketDrawer from 'components/CreateTicketDrawer';
import TicketInfoDrawer from 'components/TicketInfoDrawer';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import {
  defaultFormData,
  ServiceNowDefaultData,
} from 'pages/TicketIntegrationPage/types';
import {
  DEFAULT_VIEW,
  DEFAULT_VIEW_DROPDOWN_OPTIONS,
  RECOMMENDATION_STATUS_LABELS,
  RECOMMENDATION_SUBTYPE_LABELS,
  RecommendationTableActions,
  RecommendationStatus,
  RECOMMENDATION_TABLE_QUICK_ACTIONS,
  FilterKeys,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  getAllSnowIncidentDetails,
  getRecommendationViews,
  deleteRecommendationViews,
} from 'pages/CostOptimizationInsightsPage/services';
import {
  IncidentRecommendationMappingType,
  StatusFilterType,
  AwsRecommendationListType,
  ViewListType,
  RecommendationFiltersType,
} from 'pages/CostOptimizationInsightsPage/types';
import { QUERY_FIELDS, REQUEST_STATUS } from 'constants/requestBody';
import {
  compareToValue,
  getLabelledFilters,
  getStatusTextClassName,
  numberArraySortFunction,
} from 'pages/CostOptimizationInsightsPage/utils';
import { RulesetsType } from 'pages/RuleEnginePage/types';
import { getAllRulesets } from 'pages/RuleEnginePage/services';
import Icon from 'components/Icon';
import { INCIDENT_TYPES } from 'components/CreateTicketDrawer/constants';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  ServiceNowStatus,
  SERVICE_NOW_STATUS_LABELS,
  IncidentRecommendationStatusLabels,
  TICKET_RECOMMENDATION_STATUS,
  ORDERED_SERVICE_NOW_STATUS_LIST,
} from 'constants/workflowIntegrations';
import {
  getAWSCategoryMapping,
  getFilteredRecommendations,
  getRulesetFilters,
  getTicketConnection,
  createSnowIncident,
} from 'utils/services';
import { DATE_TIME_AM_PM } from 'utils/date';
import {
  AwsCategoryMappingType,
  RulesetFilterGroupsType,
  SortType,
} from 'types/dataTypes';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import { BUTTON_SIZE, INPUT_SIZE } from 'constants/appearance';
import {
  AWS_CHECKS_MAP,
  AWS_CHECK_IDS,
  CHECK_NAME_ID_MAP,
  AWSRecommendationSummaryColumns,
} from 'constants/recommendations';
import { selectDashboard } from 'redux/dashboardSlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { INFINITE_SCROLL_PAGE_SIZE } from 'constants/userConsole';
import { UNKNOWN_VALUE } from 'constants/utilityConstants';

import ApplyAwsRecommendation from '../ApplyAwsRecommendation';
import {
  getAwsRecommendationTableExcelData,
  getRulesetFilteredRequestBodyForAwsCheckId,
} from './utils';
import GraphHeader from '../../GraphHeader';
import Filters from '../../Filters';
import SelectedFilters from '../../SelectedFilters';
import {
  getApplicableAWSRecommendations,
  applyAWSRecommendations,
} from './services';
import { fetchRecommendationFilters } from '../../ConsolidatedRecommendationTable/components/RecommendationsHeader/utils';

const AWSRecommendationTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showExpandGraphModal } = useSelector(selectDashboard);
  const {
    selectedAwsRecommendations,
    selectedCostOptimizationInsightsConnection,
    currentRecommendationView,
    applicableRecommendations,
    selectedCostOptimizationInsightsNav,
  } = useSelector(costOptimizationInsights);
  const { permissions } = useSelector(userAuthorization);
  const { serviceNowData } = useSelector(ticketIntegration);
  const { theme } = useSelector(selectTheme);

  const [recommendationData, setRecommendationData] = useState<
    AwsRecommendationListType[]
  >([]);
  const [nextPageRecommendationData, setNextPageRecommendationData] = useState<
    AwsRecommendationListType[]
  >([]);
  const [recommendationTableData, setRecommendationTableData] = useState<
    AwsRecommendationListType[]
  >([]);
  const [filteredRecommendationData, setFilteredRecommendationData] = useState<
    AwsRecommendationListType[]
  >([]);
  const [allRecommendationData, setAllRecommendationData] = useState<
    AwsRecommendationListType[]
  >([]);
  const [serviceNowTickets, setServiceNowTickets] = useState<
    IncidentRecommendationMappingType[]
  >([]);
  const [snowTicketsRequestStatus, setSnowTicketsRequestStatus] = useState('');
  const [viewList, setViewList] = useState<ViewListType[]>();
  const [viewListRequestStatus, setViewListRequestStatus] = useState('');
  const [hasTicketIntegration, setHasTicketIntegration] = useState(false);
  const [fetchingDefaultTicketData, setFetchingDefaultTicketData] =
    useState(true);
  const [showCreateTicketDrawer, setShowCreateTicketDrawer] = useState(false);
  const [showTicketInfoDrawer, setShowTicketInfoDrawer] = useState(false);
  const [showConfirmApplyRecommendation, setShowConfirmApplyRecommendation] =
    useState(false);
  const [recommendationRequestStatus, setRecommendationRequestStatus] =
    useState('');
  const [currentPage, setCurrentPage] = useState<number>(-2);
  const [recommendationFilters, setRecommendationFilters] = useState<
    RecommendationFiltersType[]
  >([]);
  const [labelledRecommendationFilters, setLabelledRecommendationFilters] =
    useState<RecommendationFiltersType[]>([]);
  const [
    recommendationFiltersRequestStatus,
    setRecommendationFiltersRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [deleteViewRequestStatus, setDeleteViewRequestStatus] = useState('');
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<AwsRecommendationListType>();
  const [loadApplyRecommendation, setLoadApplyRecommendation] = useState('');
  const [statusFilters, setStatusFilters] = useState<StatusFilterType>({
    incidentStatus: [],
    incidentRecommendationStatus: [],
    recommendationStatus: [],
  });
  const [sortApplied, setSortApplied] = useState<SortType>({
    key: '',
    order: undefined,
  });
  const [selectedSnowStatus, setSelectedSnowStatus] = useState(
    ServiceNowStatus.TICKET_TBC
  );
  const [rulesets, setRulesets] = useState<RulesetsType[]>();
  const [rulesetTagOptionsLoading, setRulesetTagOptionsLoading] = useState(
    REQUEST_STATUS.PROCESSING
  );

  // States for AWS check dropdown
  const [awsCheckMapping, setAwsCheckMapping] = useState<
    AwsCategoryMappingType[]
  >([]);
  const [awsCheckMappingRequestStatus, setAwsCheckMappingRequestStatus] =
    useState('');
  const [selectedAwsCheck, setSelectedAwsCheck] = useState('');
  const [rulesetFilters, setRulesetFilters] =
    useState<RulesetFilterGroupsType[]>();
  const [rulesetFiltersRequestStatus, setRulesetFiltersRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [createTicketLoading, setCreateTicketLoading] = useState(false);
  const [createTicketValidationMessage, setCreateTicketValidationMessage] =
    useState('');

  useEffect(() => {
    fetchServiceNowConnection();
    fetchApplicableRecommendations();
  }, []);

  useEffect(() => {
    setRecommendationData([]);
    setServiceNowTickets([]);
    dispatch(setSelectedAwsRecommendations(undefined));
    setViewList(undefined);
    setRulesets(undefined);
    setRulesetFilters(undefined);
    if (selectedCostOptimizationInsightsConnection) {
      setCurrentPage(-2);
      getAllSnowIncidents();
      fetchAwsCategoryMapping();
      getAllRulesetTagOptions();
      getAllRecommendationViews(DEFAULT_VIEW_DROPDOWN_OPTIONS[0]);
    }
  }, [selectedCostOptimizationInsightsConnection]);

  useEffect(() => {
    getAllRecommendationViews(DEFAULT_VIEW_DROPDOWN_OPTIONS[0]);
    setSelectedAwsCheck('');
    setRulesetFilters(undefined);
  }, [selectedCostOptimizationInsightsNav]);

  useEffect(() => {
    setRecommendationFilters([]);
    if (selectedCostOptimizationInsightsConnection) {
      fetchRecommendationFilters(
        selectedCostOptimizationInsightsConnection!,
        selectedCostOptimizationInsightsNav,
        setRecommendationFilters,
        setRecommendationFiltersRequestStatus
      );
    }
  }, [
    selectedCostOptimizationInsightsConnection,
    selectedCostOptimizationInsightsNav,
  ]);

  useEffect(() => {
    setSelectedAwsCheck(
      awsCheckMapping.find(
        (category) => category.category === selectedCostOptimizationInsightsNav
      )?.recommenders[0] ?? ''
    );
  }, [selectedCostOptimizationInsightsNav, awsCheckMapping]);

  useEffect(() => {
    setCurrentPage(-2);
    setRecommendationTableData([]);
    setAllRecommendationData([]);
    dispatch(setSelectedAwsRecommendations(undefined));
  }, [selectedCostOptimizationInsightsNav, currentRecommendationView]);

  useEffect(() => {
    if (!viewList || !rulesets) {
      return;
    }

    setRulesetFilters(undefined);

    if (hasRulesetFilter()) {
      getRulesetFiltersQueries();
    } else {
      setRulesetFilters([]);
    }
  }, [viewList, rulesets, currentRecommendationView]);

  useEffect(() => {
    if (
      !selectedAwsCheck ||
      !rulesetFilters ||
      (hasRulesetFilter() && rulesetFilters?.length === 0)
    ) {
      return;
    }

    setCurrentPage(-1);
  }, [rulesetFilters, selectedAwsCheck]);

  useEffect(() => {
    if (currentPage === -2) {
      return;
    }

    if (currentPage === -1) {
      setCurrentPage(1);
      return;
    }

    if (currentPage === 1) {
      getRecommendationTableData(setRecommendationData);
      getRecommendationTableData(setAllRecommendationData, false, false);
    }

    if (currentPage !== 1) {
      getRecommendationTableData(setNextPageRecommendationData);
    }
  }, [currentPage]);

  useEffect(() => {
    setRecommendationTableData(
      addServiceNowStatusToRecommendationData(recommendationData)
    );
  }, [serviceNowTickets, recommendationData]);

  useEffect(() => {
    const data = filterRecommendationTableDataSource(
      recommendationTableData,
      statusFilters,
      selectedSnowStatus
    );
    setFilteredRecommendationData(data);

    if (data.length === 0) {
      handleChangePagination();
    }
  }, [recommendationTableData, statusFilters, selectedSnowStatus]);

  useEffect(() => {
    if (currentPage > 1) {
      const data = addServiceNowStatusToRecommendationData(
        nextPageRecommendationData
      );
      const filteredData = filterRecommendationTableDataSource(
        data,
        statusFilters,
        selectedSnowStatus
      );

      if (
        filteredRecommendationData.length === 0 &&
        filteredData.length === 0
      ) {
        handleChangePagination();
      }
    }
  }, [nextPageRecommendationData]);

  useEffect(() => {
    setLabelledRecommendationFilters(
      getLabelledFilters(recommendationFilters, {
        rulesets,
      })
    );
  }, [recommendationFilters, rulesets]);

  /**
   * @function fetchAwsCategoryMapping
   * @description Function to fetch the aws check names and check ids.
   */
  const fetchAwsCategoryMapping = () => {
    setAwsCheckMappingRequestStatus(REQUEST_STATUS.PROCESSING);
    getAWSCategoryMapping()
      .then((res: any) => {
        setAwsCheckMappingRequestStatus(REQUEST_STATUS.SUCCESS);
        setAwsCheckMapping(res?.data?.responseData);
      })
      .catch((e) => onApiCallError(e, true, setAwsCheckMappingRequestStatus));
  };

  /**
   * @function fetchServiceNowConnection
   * @description Function to fetch the service now connection details.
   */
  const fetchServiceNowConnection = () => {
    getTicketConnection()
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res.data.responseData;
          dispatch(
            setTicketIntegrationData({
              url: data.endpoint,
              usernameHost: data.username,
              password: data.password,
              setDefault: data.default,
              defaultAssignmentGroup: data.defaultAssigneeGroup,
              defaultAssignedTo: data.defaultAssignTo,
              category: data.defaultCategory,
            })
          );
          setHasTicketIntegration(true);
          setFetchingDefaultTicketData(false);
          return;
        }
        dispatch(setTicketIntegrationData(defaultFormData));
        setHasTicketIntegration(false);
        setFetchingDefaultTicketData(false);
      })
      .catch((e) => {
        onApiCallError(e);
        dispatch(setTicketIntegrationData(defaultFormData));
        setHasTicketIntegration(false);
        setFetchingDefaultTicketData(false);
      });
  };

  /**
   * @function getRulesetFiltersQueries
   * @description Function to fetch the ruleset filters for recommendation queries
   */
  const getRulesetFiltersQueries = () => {
    setRulesetFiltersRequestStatus(REQUEST_STATUS.PROCESSING);

    const rulesetIds = viewList?.some(
      (view) => view.key === currentRecommendationView.key
    )
      ? currentRecommendationView.recommendationsFilterDtoList
          .find((item) => item.key === FilterKeys.RULESET)
          ?.values?.map((item) => item.value)
      : [currentRecommendationView.key];

    Promise.all(
      (rulesetIds ?? []).map((rulesetId) =>
        getRulesetFilters({ rulesetId: rulesetId })
      )
    )
      .then((res) => {
        const filters: RulesetFilterGroupsType[] = [];
        res?.forEach((item: any) => {
          filters.push(...(item?.data?.responseData ?? []));
        });
        setRulesetFilters(filters);
        setRulesetFiltersRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, true, setRulesetFiltersRequestStatus);
      });
  };

  /**
   * @function fetchApplicableRecommendations
   * @description Function to fetch all the applicable recommendations.
   */
  const fetchApplicableRecommendations = () => {
    getApplicableAWSRecommendations()
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(
            setApplicableRecommendations(
              res?.data?.responseData.map((value: any) => value) || []
            )
          );
          return;
        }
        dispatch(setApplicableRecommendations([]));
      })

      .catch((e) => {
        onApiCallError(e);
        dispatch(setApplicableRecommendations([]));
      });
  };

  /**
   * @function getAllSnowIncidents
   * @description Function to fetch the service now status for all recommendations and ticket under a connection.
   */
  const getAllSnowIncidents = () => {
    setSnowTicketsRequestStatus(REQUEST_STATUS.PROCESSING);
    getAllSnowIncidentDetails(
      selectedCostOptimizationInsightsConnection?.connectorId ?? ''
    )
      .then((res: any) => {
        if (res?.status === 200) {
          const data = res?.data?.responseData;
          const tickets = data?.incidentRecommendationMappings?.map(
            (incident: IncidentRecommendationMappingType) => ({
              ...incident,
              incidentStatus: ORDERED_SERVICE_NOW_STATUS_LIST.at(
                Number(incident.incidentStatus)
              ),
            })
          );
          setServiceNowTickets(tickets);
          setSnowTicketsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setServiceNowTickets([]);
        setSnowTicketsRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setSnowTicketsRequestStatus);
        setServiceNowTickets([]);
      });
  };

  /**
   * @function getAllRulesetTagOptions
   * @description Function to fetch all the ruleset to show tags in filter options.
   */
  const getAllRulesetTagOptions = () => {
    setRulesetTagOptionsLoading(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: selectedCostOptimizationInsightsConnection?.provider,
    };
    getAllRulesets(params)
      .then((res: any) => {
        setRulesets(res?.data?.responseData?.content || []);
        setRulesetTagOptionsLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setRulesetTagOptionsLoading));
  };

  /**
   * @function getServiceNowStatusAndSysId
   * @description Function to fetch the service now status and the sysId of the recommendation mapped ticket.
   * @param recommendationId The name of the recommendation
   * @param recStatus The name of the recommendation status of the recommendation
   * @returns service now status if the ticket is created else `Ticket TBC` by default
   */
  const getServiceNowStatusAndSysId = (
    recommendationId: string,
    recStatus: string
  ) => {
    const incident = serviceNowTickets.find((snowIncident) =>
      snowIncident.recommendationApprovals?.some(
        (recommendationEntry) => recommendationEntry.name === recommendationId
      )
    );

    if (incident) {
      return { incidentStatus: incident.incidentStatus, sysId: incident.sysId };
    }

    if (
      recStatus === RecommendationStatus.ACTIVE ||
      recStatus === RecommendationStatus.FAILED
    ) {
      return { incidentStatus: ServiceNowStatus.TICKET_TBC };
    }

    return { incidentStatus: UNKNOWN_VALUE };
  };

  /**
   * @function getIncidentRecommendationStatus
   * @description Function to fetch the approval status of recommendation in service now.
   * @param recommendationId The name of the recommendation
   * @returns Object containing the approvalStatus, name and sysId
   */
  const getIncidentRecommendationStatus = (recommendationId: string) => {
    const recommendationApprovals = serviceNowTickets.find((incident) =>
      incident.recommendationApprovals?.some(
        (recommendationEntry) => recommendationEntry.name === recommendationId
      )
    )?.recommendationApprovals;

    if (recommendationApprovals) {
      return recommendationApprovals.find(
        (recommendation) => recommendation.name === recommendationId
      )!;
    }

    return {
      approvalStatus: undefined,
      name: recommendationId,
      recomStatus: undefined,
      sysId: undefined,
    };
  };

  /**
   * @function getRecommendationTableData
   * @description Function to fetch the recommendations.
   * @param setData Setter method for updating the data fetched
   * @param withPagination boolean value to indicate whether to include pagination params or not.
   * @param addLoader boolean to indicate whether to add a loader or not
   */
  const getRecommendationTableData = (
    setData: (val: AwsRecommendationListType[]) => void,
    withPagination: boolean = true,
    addLoader: boolean = true
  ) => {
    if (addLoader) {
      setRecommendationRequestStatus(REQUEST_STATUS.PROCESSING);
    }

    let params: any = {
      connectorId: selectedCostOptimizationInsightsConnection?.connectorId,
    };

    if (withPagination) {
      params = {
        ...params,
        page: currentPage,
        pageSize: INFINITE_SCROLL_PAGE_SIZE,
      };
    }

    const requestBodyWithViewFilters =
      getRulesetFilteredRequestBodyForAwsCheckId(
        selectedAwsCheck,
        currentRecommendationView,
        rulesetFilters
      );

    getFilteredRecommendations(requestBodyWithViewFilters, params)
      .then((res: any) => {
        setData(res?.data || []);
        setRecommendationRequestStatus(REQUEST_STATUS.SUCCESS);

        if (res?.data?.length >= INFINITE_SCROLL_PAGE_SIZE) {
          setCurrentPage(2);
        }
      })
      .catch((e) => {
        setData([]);
        if (e.response.status === 400) {
          setRecommendationRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        onApiCallError(e, false, setRecommendationRequestStatus);
      });
  };

  /**
   * @function getAllRecommendationViews
   * @description Function to fetch all the available filters for connection.
   */
  const getAllRecommendationViews = (currentView?: ViewListType) => {
    setViewListRequestStatus(REQUEST_STATUS.PROCESSING);
    const extraHeaders = {
      connectorId:
        selectedCostOptimizationInsightsConnection?.connectorId ?? '',
    };
    getRecommendationViews(extraHeaders)
      .then((res: any) => {
        if (res?.status === 200) {
          const formattedViews: ViewListType[] =
            res?.data?.responseData?.map((view: any) => {
              return {
                ...view,
                key: view.viewName,
                recommendationsFilterDtoList:
                  view?.recommendationsFilterDtoList?.map((filter: any) => ({
                    ...filter,
                    values:
                      filter?.values?.map((item: any) => ({
                        value: item,
                        label: item,
                      })) || [],
                  })),
              };
            }) || [];
          setViewList([...DEFAULT_VIEW_DROPDOWN_OPTIONS, ...formattedViews]);
          currentView && dispatch(setCurrentRecommendationView(currentView));
          setViewListRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setViewList([...DEFAULT_VIEW_DROPDOWN_OPTIONS]);
        setViewListRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setViewListRequestStatus);
        setViewList([...DEFAULT_VIEW_DROPDOWN_OPTIONS]);
      });
  };

  /**
   * @function applyRecommendation
   * @description Function apply the recommendation.
   * @param additionalRequestBody recommendation details of applied recommendation
   */
  const applyRecommendation = (additionalRequestBody: any) => {
    setLoadApplyRecommendation(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      connectorId: selectedCostOptimizationInsightsConnection?.connectorId,
      checkId: selectedAwsCheck,
      resourceId: (selectedRecommendation as any)[getUniqueKey()],
      region: selectedRecommendation?.region,
      ...additionalRequestBody,
    };

    applyAWSRecommendations(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          message.success(
            t(
              'costOptimizationInsight.recommendationTableAction.applyRecSuccess',
              {
                recommendationId: (selectedRecommendation as any)[
                  getUniqueKey()
                ],
              }
            )
          );
          getAllSnowIncidents();
          setShowConfirmApplyRecommendation(false);
          setRecommendationData([]);
          setCurrentPage(-1);
          setLoadApplyRecommendation(REQUEST_STATUS.SUCCESS);
          return;
        }
        message.error(
          res?.data?.message ||
            t(
              'costOptimizationInsight.recommendationTableAction.applyRecFailure',
              {
                recommendationId: (selectedRecommendation as any)[
                  getUniqueKey()
                ],
              }
            )
        );
        setShowConfirmApplyRecommendation(false);
        setLoadApplyRecommendation(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        const errorMessage =
          e.response.data.message ??
          t(
            'costOptimizationInsight.recommendationTableAction.applyRecFailure',
            {
              recommendationId: (selectedRecommendation as any)[getUniqueKey()],
            }
          );
        onApiCallError(e, true, setLoadApplyRecommendation, errorMessage);
        setShowConfirmApplyRecommendation(false);
      });
  };

  /**
   * @function addServiceNowStatusToRecommendationData
   * @description Function to add the service now status to the recommendation data.
   * @param data Recommendation Data
   * @returns Recommendation data with SNOW details updated
   */
  const addServiceNowStatusToRecommendationData = (
    data: AwsRecommendationListType[]
  ) => {
    return data.map((item: AwsRecommendationListType) => {
      const { incidentStatus, sysId } = getServiceNowStatusAndSysId(
        (item as any)[getUniqueKey()],
        item.recStatus
      );
      const { recomStatus, approvalStatus } = getIncidentRecommendationStatus(
        (item as any)[getUniqueKey()]
      );
      return {
        ...item,
        recStatus: recomStatus ?? item.recStatus,
        serviceNowStatus: incidentStatus,
        incidentRecommendationStatus: approvalStatus,
        sysId: sysId,
      };
    });
  };

  /**
   * @function hasRulesetFilter
   * @description Function to validate if the view has ruleset filters
   * @returns a boolean true if the selected view has ruleset filters else false
   */
  const hasRulesetFilter = () => {
    if (
      rulesets?.some(
        (ruleset) => ruleset.rulesetId === currentRecommendationView.key
      ) ||
      currentRecommendationView.recommendationsFilterDtoList.some(
        (filter) => filter.key === FilterKeys.RULESET
      )
    ) {
      return true;
    }

    return false;
  };

  const handleQuickAction = (
    record: AwsRecommendationListType,
    action: string
  ) => {
    switch (action) {
      case RecommendationTableActions.APPLY_RECOMMENDATIONS:
        setSelectedRecommendation(record);
        setShowConfirmApplyRecommendation(true);
        break;

      case RecommendationTableActions.TICKET_INFO:
        setSelectedRecommendation(record);
        setShowTicketInfoDrawer(true);
        break;
    }
  };

  /**
   * @function getStatusElement
   * @description Function to return a styled element for status text
   * @param text text to be displayed
   * @param key status key for style
   * @returns a span element with styled text
   */
  const getStatusElement = (text: string, key: string) => (
    <span className={`font-button status-text ${getStatusTextClassName(key)}`}>
      {text}
    </span>
  );

  /**
   * @function checkRecordStatusForApplyRec
   * @description Function to check if apply recommendation quick action should be shown as an
   * option in the recommendation row. Checks the rec status and snow status.
   * @param record The row details for the recommendation
   */
  const checkRecordStatusForApplyRec = (record: AwsRecommendationListType) => {
    return (
      record.incidentRecommendationStatus ===
        TICKET_RECOMMENDATION_STATUS.APPROVED &&
      (record.recStatus === RecommendationStatus.CLAIMED ||
        record.recStatus === RecommendationStatus.FAILED) &&
      (record.serviceNowStatus === ServiceNowStatus.RESOLVED ||
        record.serviceNowStatus === ServiceNowStatus.CLOSED)
    );
  };

  const getQuickActions = (record: AwsRecommendationListType) => {
    return RECOMMENDATION_TABLE_QUICK_ACTIONS.filter((quickAction: any) => {
      // Conditions for apply recommendation quick action
      if (quickAction.id === RecommendationTableActions.APPLY_RECOMMENDATIONS) {
        return (
          permissions.costControlWrite &&
          checkRecordStatusForApplyRec(record) &&
          applicableRecommendations.includes(selectedAwsCheck)
        );
      }

      // Conditions for Ticket info quick action
      else if (quickAction.id === RecommendationTableActions.TICKET_INFO)
        return (
          ORDERED_SERVICE_NOW_STATUS_LIST.includes(record.serviceNowStatus) &&
          record.serviceNowStatus !== ServiceNowStatus.TICKET_TBC
        );
      else return true;
    });
  };

  /**
   * @function getFilterDropdown
   * @description Function to return the filter dropdown
   * @returns JSX element with filters
   */
  const getFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
  }: FilterDropdownProps) => (
    <TableFilterDropdown
      allKeys={filters ?? []}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  );

  const SnowStatusColumn = {
    title: t('costOptimizationInsight.recommendationTable.ticketStatus'),
    dataIndex:
      selectedSnowStatus === ServiceNowStatus.TICKET_TBC
        ? 'serviceNowStatus'
        : 'incidentRecommendationStatus',
    width: 240,
    render: (_text: string, record: AwsRecommendationListType) => {
      return (
        <div className="flex flex-gap-8">
          {[ServiceNowStatus.TICKET_TBC, UNKNOWN_VALUE].includes(
            record?.serviceNowStatus
          ) &&
            getStatusElement(
              SERVICE_NOW_STATUS_LABELS.find(
                (item) => item.key === record.serviceNowStatus
              )?.label ?? record.serviceNowStatus,
              record.serviceNowStatus
            )}
          {[
            ServiceNowStatus.RESOLVED.valueOf(),
            ServiceNowStatus.CLOSED.valueOf(),
          ].includes(record?.serviceNowStatus) &&
            record?.incidentRecommendationStatus && (
              <div>
                {RECOMMENDATION_SUBTYPE_LABELS.find(
                  (item) => item.key === record.type
                )?.label ?? record.type}
                {getStatusElement(
                  IncidentRecommendationStatusLabels.find(
                    (item) => item.key === record?.incidentRecommendationStatus
                  )?.label ?? record?.incidentRecommendationStatus,
                  record?.incidentRecommendationStatus
                )}
              </div>
            )}
        </div>
      );
    },
    filters:
      selectedSnowStatus === ServiceNowStatus.TICKET_TBC
        ? [
            {
              value: ServiceNowStatus.TICKET_TBC,
              text: t('serviceNowLabels.ticketTBC'),
            },
            {
              value: UNKNOWN_VALUE,
              text: UNKNOWN_VALUE,
            },
          ]
        : IncidentRecommendationStatusLabels.map((item) => ({
            value: item.key,
            text: item.label,
          })),
    filterIcon: (
      <Icon
        iconName={ICONS.FILTER_3_FILL}
        dataTestId="snow-status-filter-cta"
      />
    ),
    filterDropdown: getFilterDropdown,
    sorter: true,
    showSorterTooltip: false,
  };

  const getColumns = () => {
    let columns: any[] = [
      ...AWSRecommendationSummaryColumns.filter(
        (item) =>
          !item.checkIds ||
          item.checkIds.includes(selectedAwsCheck as AWS_CHECK_IDS)
      ).map((column) => {
        if (column.dataIndex === 'accountId') {
          return {
            ...column,
            fixed: 'left',
            width: 300,
          };
        }
        if (
          column.dataIndex === 'lastRefreshedAt' ||
          column.dataIndex === 'generationTime'
        ) {
          return {
            ...column,
            width: 300,
            render: (text: string) => moment(text).format(DATE_TIME_AM_PM),
          };
        }
        if (column.dataIndex === 'cpuUtilization') {
          return {
            ...column,
            width: 300,
            render: (text: string) => `${numberCommaSeparator(text)}%`,
          };
        }
        if (column.costField) {
          return {
            ...column,
            width: 200,
            render: (text: string) => `$${numberCommaSeparator(text)}`,
          };
        }

        return { ...column, width: 300 };
      }),
      {
        title: t('costOptimizationInsight.recommendationTable.actions'),
        dataIndex: 'quickAction',
        render: (_text: string, record: AwsRecommendationListType) => {
          return (
            <QuickActionMenu
              quickActions={getQuickActions(record)}
              disabled={getQuickActions(record).length === 0}
              quickActionHandler={(action: string) => {
                handleQuickAction(record, action);
              }}
            />
          );
        },
        width: 80,
        fixed: 'right',
        align: 'center',
      },
    ];

    columns.splice(
      1,
      0,

      {
        title: t('costOptimizationInsight.recommendationTable.recStatus'),
        dataIndex: 'recStatus',
        width: 130,
        render: (text: string) =>
          getStatusElement(
            RECOMMENDATION_STATUS_LABELS.find((item) => item.key === text)
              ?.label ?? text,
            text
          ),
        filters: RECOMMENDATION_STATUS_LABELS.map((item) => ({
          value: item.key,
          text: item.label,
        })),
        filterIcon: (
          <Icon iconName={ICONS.FILTER_3_FILL} dataTestId="filter-cta" />
        ),
        filterDropdown: getFilterDropdown,
        sorter: true,
        showSorterTooltip: false,
      }
    );

    if (
      AWSRecommendationSummaryColumns.find(
        (item) =>
          (!item.checkIds ||
            item.checkIds.includes(selectedAwsCheck as AWS_CHECK_IDS)) &&
          item.costField
      ) !== undefined
    ) {
      columns.splice(
        1,
        0,
        ...[
          {
            title: t('costOptimizationInsight.recommendationTable.risk'),
            dataIndex: 'risk',
            width: 100,
          },
          {
            title: t('costOptimizationInsight.recommendationTable.priority'),
            dataIndex: 'priority',
            width: 100,
          },
        ]
      );
    }

    if (
      [
        ServiceNowStatus.TICKET_TBC,
        ServiceNowStatus.RESOLVED,
        ServiceNowStatus.CLOSED,
      ].includes(selectedSnowStatus)
    ) {
      columns.splice(1, 0, SnowStatusColumn);
    }

    return columns;
  };

  const onClickCreateSnowTicketCta = () => {
    if (hasTicketIntegration) {
      dispatch(setServiceNowData(ServiceNowDefaultData));
      dispatch(setTicketIntegrationData(defaultFormData));
      setShowCreateTicketDrawer(true);
      return;
    }

    navigate(NAVIGATION_MENU_PATH.TICKET_INTEGRATION);
  };

  const getCreateTicketsCtaLabel = () => {
    if (fetchingDefaultTicketData) {
      return t('costOptimizationInsight.recommendationTableHeader.loading');
    }

    if (hasTicketIntegration) {
      return t(
        'costOptimizationInsight.recommendationTableHeader.createTicket'
      );
    }

    return t(
      'costOptimizationInsight.recommendationTableHeader.ticketIntegrationError'
    );
  };

  const onClickDeleteView = () => {
    setDeleteViewRequestStatus(REQUEST_STATUS.PROCESSING);
    const extraHeaders = {
      viewName: currentRecommendationView.viewName,
      connectorId:
        selectedCostOptimizationInsightsConnection?.connectorId ?? '',
    };
    deleteRecommendationViews(extraHeaders)
      .then((res: any) => {
        if (res.status === 200) {
          message.success({
            content: t(
              'costOptimizationInsight.recommendationApiMessage.deleteSuccess',
              {
                viewName: currentRecommendationView.viewName,
              }
            ),
          });
          getAllRecommendationViews(DEFAULT_VIEW_DROPDOWN_OPTIONS[0]);
          setDeleteViewRequestStatus(REQUEST_STATUS.SUCCESS);
        }
      })
      .catch((e) => {
        onApiCallError(e, true, setDeleteViewRequestStatus);
      });
  };

  /**
   * @function handleChangePagination
   * @description Function to handle the pagination change
   */
  const handleChangePagination = () => {
    if (recommendationRequestStatus === REQUEST_STATUS.PROCESSING) return;

    if (nextPageRecommendationData.length >= INFINITE_SCROLL_PAGE_SIZE) {
      setRecommendationRequestStatus(REQUEST_STATUS.PROCESSING);
      setCurrentPage(currentPage + 1);
      setRecommendationData([
        ...recommendationData,
        ...nextPageRecommendationData,
      ]);
    }
  };

  /**
   * @function onClickCreateTicket
   * @description Callback function for create ticket
   */
  const onClickCreateTicket = () => {
    setCreateTicketLoading(true);

    const isBulkTicket =
      serviceNowData.incidentType === INCIDENT_TYPES.BULK_INCIDENT;

    let requestBody: any = {
      connectorId: selectedCostOptimizationInsightsConnection?.connectorId,
      bulkTicket:
        selectedAwsRecommendations?.recommendations.length === 1
          ? false
          : isBulkTicket,
      multipleTicket:
        selectedAwsRecommendations?.recommendations.length === 1
          ? false
          : !isBulkTicket,
      assignTo: serviceNowData.assignedTo,
      category: serviceNowData.category,
    };

    const uniqueKey = (AWS_CHECKS_MAP.find(
      (map) => map.check === selectedAwsRecommendations?.category
    )?.uniqueKeyLabel ?? 'accountId') as keyof AwsRecommendationListType;

    requestBody = {
      ...requestBody,
      recomDetails: selectedAwsRecommendations?.recommendations.map(
        (recommendation) => ({
          name: recommendation[uniqueKey],
          description: selectedAwsRecommendations?.category,
          category: QUERY_FIELDS.COST_ALL_CAPS,
          targetResource: recommendation[uniqueKey],
        })
      ),
    };

    createSnowIncident(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          setCreateTicketLoading(false);
          message.success(t('createTicketDrawer.ticketCreateSuccess'));
          dispatch(setSelectedAwsRecommendations(undefined));
          getAllSnowIncidents();
          setShowCreateTicketDrawer(false);
          return;
        }
        setCreateTicketLoading(false);
        setCreateTicketValidationMessage(
          res?.data?.message || t('createTicketDrawer.errorCreateTicket')
        );
      })
      .catch((e) => {
        const errorMessage =
          e.response.data.message ?? t('createTicketDrawer.errorCreateTicket');
        onApiCallError(e, true, undefined, errorMessage);
        setCreateTicketLoading(false);
      });
  };

  const CreateSnowTicketButton = (
    <Button
      className={`create-ticket-cta ${
        !hasTicketIntegration && !fetchingDefaultTicketData && 'error'
      }`}
      size={BUTTON_SIZE.SMALL}
      title={getCreateTicketsCtaLabel()}
      onClick={onClickCreateSnowTicketCta}
      disabled={
        !hasTicketIntegration ||
        !selectedAwsRecommendations?.recommendations.length
      }
    />
  );

  const DeleteViewButton = (
    <Button
      className="delete-view-button"
      title={t('costOptimizationInsight.recommendationTableHeader.deleteView')}
      iconName={ICONS.DELETE_BIN_LINE}
      size={BUTTON_SIZE.SMALL}
      onClick={onClickDeleteView}
      loading={deleteViewRequestStatus === REQUEST_STATUS.PROCESSING}
      disabled={currentRecommendationView.key === DEFAULT_VIEW}
    />
  );

  const ViewDropdownOptions = viewList
    ?.filter(
      (view) =>
        view.recommendationType === selectedCostOptimizationInsightsNav ||
        view.key === DEFAULT_VIEW
    )
    .map((view) => (
      <Select.Option key={view.key} value={view.key}>
        {view.viewName}
      </Select.Option>
    ));

  const RulesetDropdownOptions = (
    <div className="flex flex-column">
      {rulesets
        ?.filter((ruleset) => ruleset.createViewFilter)
        .map((ruleset) => (
          <AccessibleDiv
            className={`ruleset-options cursor-pointer ${
              currentRecommendationView.key === ruleset.rulesetId
                ? 'font-button'
                : 'font-label'
            }`}
            key={ruleset.rulesetId}
            onClick={() =>
              dispatch(
                setCurrentRecommendationView({
                  viewName: ruleset.ruleSetName,
                  key: ruleset.rulesetId ?? '',
                  recommendationsFilterDtoList: [],
                })
              )
            }
            data-testid={ruleset.ruleSetName}
          >
            {ruleset.ruleSetName}
          </AccessibleDiv>
        ))}
    </div>
  );

  /**
   * @function getViewsDropdownRender
   * @description Function return the views dropdown render component
   * @param menu JSX menu element
   * @returns JSX element
   */
  const getViewsDropdownRender = (menu: JSX.Element) => (
    <div className="filter-view-dropdown styled-scroll">
      <span className="view-type font-subHeader-small">
        {t('costOptimizationInsight.viewDropdown.filterViewLabel')}
      </span>
      <div className="dropdown-container">{menu}</div>
      {rulesets?.length ? (
        <>
          <span className="view-type font-subHeader-small">
            {t('costOptimizationInsight.viewDropdown.rulesetDefaultViewLabel')}
          </span>
          <div className="dropdown-container">{RulesetDropdownOptions}</div>
        </>
      ) : null}
    </div>
  );

  const ViewDropdown = (
    <SelectDropdown
      value={{
        value: currentRecommendationView.key,
        label: (
          <>
            <span className="font-button">
              {t('costOptimizationInsight.viewDropdown.viewValuePrefix')}
            </span>
            {currentRecommendationView.viewName}
          </>
        ),
      }}
      onSelect={(value: any) => {
        dispatch(
          setCurrentRecommendationView(
            viewList?.find((view) => view.key === value.key)!
          )
        );
      }}
      menu={ViewDropdownOptions}
      dropdownRender={getViewsDropdownRender}
      labelInValue
      loading={
        evaluateRequestArray([
          viewListRequestStatus,
          rulesetTagOptionsLoading,
        ]) === REQUEST_STATUS.PROCESSING
      }
      className="view-dropdown"
      designVersion2
      size={INPUT_SIZE.SMALL}
    />
  );

  /**
   * @function filterRecommendationTableDataSource
   * @description Function that filters table data on the frontend for service now status filters
   * @returns Recommendation table data filtered based on the snow filters
   */
  const filterRecommendationTableDataSource = (
    recDataToBeFiltered: AwsRecommendationListType[],
    filters: StatusFilterType,
    selectedStatusNav: string
  ) => {
    let recommendationTableFilteredData = [...recDataToBeFiltered];

    let status = [selectedStatusNav];
    if (selectedStatusNav === ServiceNowStatus.TICKET_TBC) {
      status =
        filters.incidentStatus?.length > 0
          ? filters.incidentStatus
          : [selectedStatusNav, UNKNOWN_VALUE];
    }

    recommendationTableFilteredData = recommendationTableFilteredData.filter(
      (value) => status.includes(value.serviceNowStatus)
    );

    if (filters.incidentRecommendationStatus?.length) {
      recommendationTableFilteredData = recommendationTableFilteredData.filter(
        (value) =>
          ![
            ServiceNowStatus.RESOLVED.valueOf(),
            ServiceNowStatus.CLOSED.valueOf(),
          ].includes(value.serviceNowStatus) ||
          filters.incidentRecommendationStatus.includes(
            value?.incidentRecommendationStatus ?? ''
          )
      );
    }

    if (filters.recommendationStatus?.length) {
      recommendationTableFilteredData = recommendationTableFilteredData.filter(
        (value) => filters.recommendationStatus.includes(value.recStatus)
      );
    }

    return recommendationTableFilteredData;
  };

  /**
   * @function onChangeSortApplied
   * @description Function to sort the data
   * @param key field against which the sorting is done.
   * @param order order of sorting, either ascending or descending
   */
  const onChangeSortApplied = (
    key: keyof AwsRecommendationListType,
    order: string | undefined
  ) => {
    if (sortApplied.key === key && sortApplied.order === order) return;

    if (order === undefined) {
      setRecommendationTableData(
        addServiceNowStatusToRecommendationData(recommendationData)
      );
      return;
    }

    const data = [...recommendationTableData].sort((item1, item2) => {
      if (key === 'costSavings') {
        return numberArraySortFunction(
          item1.costSavings ?? 0,
          item2.costSavings ?? 0,
          order
        );
      }
      return compareToValue(item1[key] as any, item2[key] as any, key, order)
        ? 1
        : -1;
    });
    setRecommendationTableData(data);
    setSortApplied({ key: key, order: order });
  };

  /**
   * @function onSelectTableRow
   * @description Callback function for row selection
   * @param record selected row data.
   * @param checked boolean value to indicate row selection or deselection
   */
  const onSelectTableRow = (
    record: AwsRecommendationListType,
    checked: boolean
  ) => {
    if (checked) {
      dispatch(
        setSelectedAwsRecommendations({
          category: selectedAwsCheck,
          recommendations: [
            ...(selectedAwsRecommendations?.recommendations ?? []),
            record,
          ],
        })
      );
      return;
    }
    dispatch(
      setSelectedAwsRecommendations({
        category: selectedAwsCheck,
        recommendations:
          selectedAwsRecommendations?.recommendations.filter(
            (item) => item[getUniqueKey()] !== record[getUniqueKey()]
          ) ?? [],
      })
    );
  };

  const getUniqueKey = () =>
    (AWS_CHECKS_MAP.find((map) => map.check === selectedAwsCheck)
      ?.uniqueKeyLabel ?? 'accountId') as keyof AwsRecommendationListType;

  /**
   * @function isRecommendationTableLoading
   * @description Function to return a boolean value to for loading table
   * @returns boolean value true or false
   */
  const isRecommendationTableLoading = () => {
    const loading = [
      recommendationRequestStatus,
      viewListRequestStatus,
      rulesetTagOptionsLoading,
      rulesetFiltersRequestStatus,
      awsCheckMappingRequestStatus,
    ].includes(REQUEST_STATUS.PROCESSING);
    return (
      (!loading && snowTicketsRequestStatus === REQUEST_STATUS.PROCESSING) ||
      (filteredRecommendationData.length === 0 && loading)
    );
  };

  const CategoryDropdown = (
    <SelectDropdown
      value={selectedAwsCheck}
      onSelect={(value: any) => {
        setCurrentPage(-1);
        setRecommendationData([]);
        setNextPageRecommendationData([]);
        setAllRecommendationData([]);
        dispatch(setSelectedAwsRecommendations(undefined));
        setSelectedAwsCheck(value);
      }}
      loading={awsCheckMappingRequestStatus === REQUEST_STATUS.PROCESSING}
      menu={awsCheckMapping
        .find(
          (category) =>
            category.category === selectedCostOptimizationInsightsNav
        )
        ?.recommenders.map((recommender) => {
          return {
            checkId: recommender,
            checkName:
              CHECK_NAME_ID_MAP.find((check) => check.id === recommender)
                ?.name ?? '',
          };
        })
        .map((value) => (
          <Select.Option key={value.checkId} value={value.checkId}>
            {value.checkName}
          </Select.Option>
        ))}
      className="table-heading-dropdown"
      designVersion2
    />
  );

  const getSummaryRowComponent = () => (
    <>
      {filteredRecommendationData.length > 0 &&
        recommendationRequestStatus === REQUEST_STATUS.PROCESSING && (
          <Table.Summary.Row className="loader-row">
            <Table.Summary.Cell index={0} colSpan={7}>
              <Loader
                iconWidth={16}
                iconHeight={16}
                additionalText={
                  <span className="loading-text">{t('loading')}...</span>
                }
              />
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
    </>
  );

  const getTableComponent = () => (
    <div
      className={`rec-table-container styled-scroll ${
        currentRecommendationView.recommendationsFilterDtoList &&
        currentRecommendationView.recommendationsFilterDtoList.length > 0 &&
        'with-filter'
      }`}
      id="graph-container"
    >
      <GraphHeader
        heading={t(`costOptimizationInsight.recommendationTableHeader.header`)}
        dropdownHeading={CategoryDropdown}
        graphName={t(
          `costOptimizationInsight.recommendationTableHeader.header`
        )}
        ignorePadding={true}
        showExpandIcon={true}
        isDownloadable
        viewDropdown={ViewDropdown}
        actionButtons={[
          {
            component: (
              <Icon
                iconName={ICONS.REFRESH_LINE}
                onClick={() => getAllSnowIncidents()}
                size={ICONS_SIZE.ONE_X}
                color={theme.buttonIconColor}
                dataTestId="refresh-cta"
              />
            ),
            permission: true,
          },
          {
            component: DeleteViewButton,
            permission: permissions.costControlModify,
          },
          {
            component: CreateSnowTicketButton,
            permission: permissions.costControlWrite,
          },
          {
            component: (
              <Filters
                filters={labelledRecommendationFilters}
                disabled={currentRecommendationView.key !== DEFAULT_VIEW}
                onClickSubmit={(view: ViewListType) => {
                  setViewList(undefined);
                  getAllRecommendationViews(view);
                }}
                loading={[
                  recommendationFiltersRequestStatus,
                  rulesetTagOptionsLoading,
                ]}
              />
            ),
            permission: permissions.costControlWrite,
          },
        ]}
        excelData={getAwsRecommendationTableExcelData(
          selectedCostOptimizationInsightsConnection?.name ?? '',
          selectedAwsCheck,
          SERVICE_NOW_STATUS_LABELS.map((status) => ({
            sheetName: status.tabTitle,
            snowStatus: status.key,
            recommendationTableData: filterRecommendationTableDataSource(
              addServiceNowStatusToRecommendationData(allRecommendationData),
              statusFilters,
              status.key
            ),
          }))
        )}
      />
      {currentRecommendationView.recommendationsFilterDtoList &&
        currentRecommendationView.recommendationsFilterDtoList.length > 0 && (
          <SelectedFilters
            filters={labelledRecommendationFilters}
            selectedFilters={
              currentRecommendationView.recommendationsFilterDtoList
            }
          />
        )}
      <div className="snow-status-tabs flex flex-align-items-center flex-gap-16 flex-wrap">
        <div className="flex flex-column font-caption-bold">
          {t(
            'costOptimizationInsight.recommendationTableHeader.serviceNowStatus'
          )}
        </div>
        <Radio.Group
          options={SERVICE_NOW_STATUS_LABELS.map((status) => ({
            label: status.tabTitle,
            value: status.key,
          }))}
          onChange={(e) => setSelectedSnowStatus(e.target.value)}
          value={selectedSnowStatus}
          optionType="button"
          rootClassName="no-custom-style status-tabs font-caption-bold"
          style={{ height: 28 }}
        />
      </div>
      <Col
        span={24}
        className={`recommendations-table ${
          currentRecommendationView.key !== DEFAULT_VIEW && 'with-filters'
        }`}
      >
        <InfiniteScrollTable
          rowSelection={{
            type: 'checkbox',
            hideSelectAll: true,
            getCheckboxProps: (record: AwsRecommendationListType) => {
              return {
                disabled:
                  !permissions.costControlWrite ||
                  record.serviceNowStatus !== ServiceNowStatus.TICKET_TBC,
              };
            },
            onSelect: onSelectTableRow,
          }}
          loading={recommendationRequestStatus === REQUEST_STATUS.PROCESSING}
          tableLoading={isRecommendationTableLoading()}
          pagination={false}
          dataSource={filteredRecommendationData.map((value) => {
            return {
              ...value,
              key: value[getUniqueKey()],
            };
          })}
          columns={getColumns()}
          sortDirections={['ascend', 'descend', 'ascend']}
          onChange={(
            _newPagination: TablePaginationConfig,
            filters: any,
            sorter: any
          ) => {
            setStatusFilters({
              incidentStatus: filters?.serviceNowStatus?.filter((item: any) =>
                SERVICE_NOW_STATUS_LABELS.some(
                  (status) => status.key === item || item === UNKNOWN_VALUE
                )
              ),
              incidentRecommendationStatus:
                filters?.incidentRecommendationStatus?.filter((item: any) =>
                  IncidentRecommendationStatusLabels.some(
                    (status) => status.key === item
                  )
                ),
              recommendationStatus: filters?.recStatus,
            });
            if (
              sortApplied.key !== sorter.field ||
              sortApplied.order !== sorter.order
            ) {
              onChangeSortApplied(sorter.field, sorter.order);
            }
          }}
          handleChangePageNumber={handleChangePagination}
          summary={getSummaryRowComponent}
          locale={{
            emptyText: !isRecommendationTableLoading() && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  recommendationRequestStatus === REQUEST_STATUS.ERROR
                    ? t('graphErrorMessage')
                    : t('costOptimizationInsight.noRecommendations', {
                        ticketStatus: SERVICE_NOW_STATUS_LABELS.find(
                          (item) => item.key === selectedSnowStatus
                        )?.tabTitle,
                      })
                }
              />
            ),
          }}
          designVersion2
          fillContainer
          scroll={{
            y: 'inherit',
            scrollToFirstRowOnChange: false,
          }}
          data-testid="aws-recommendation-table"
        />
      </Col>
    </div>
  );

  return (
    <div className="card">
      {getTableComponent()}
      <CreateTicketDrawer
        show={showCreateTicketDrawer}
        setShow={setShowCreateTicketDrawer}
        fetchServiceNowConnection={fetchServiceNowConnection}
        selectedRecommendationsLength={
          selectedAwsRecommendations?.recommendations.length ?? 0
        }
        onClickCreate={onClickCreateTicket}
        loading={createTicketLoading}
        validationMessage={createTicketValidationMessage}
      />
      {selectedRecommendation && (
        <TicketInfoDrawer
          ticket={selectedRecommendation}
          show={showTicketInfoDrawer}
          setShow={setShowTicketInfoDrawer}
        />
      )}
      <ApplyAwsRecommendation
        show={showConfirmApplyRecommendation}
        setShow={setShowConfirmApplyRecommendation}
        selectedAwsCheck={selectedAwsCheck}
        loadApplyRecommendation={loadApplyRecommendation}
        applyRecommendation={applyRecommendation}
      />
      {showExpandGraphModal && (
        <ExpandModal
          graphContent={getTableComponent()}
          show={showExpandGraphModal}
          width={'90%'}
          height={'90vh'}
        />
      )}
    </div>
  );
};

export default AWSRecommendationTable;
