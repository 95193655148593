import { GCP_SERVICES } from 'pages/ScorecardPage/constants';

export enum AWS_SAVINGS_EFFECTIVENESS_ITEMS {
  ON_DEMAND_SPEND = 'On-Demand Spend',
  COMPUTE_RI_SP_SAVINGS = 'Compute RI & SP Savings',
  COST_TO_ACHIEVE_SAVINGS = 'Cost to Achieve Savings',
}

export enum AWS_SAVINGS_EFFECTIVENESS_ON_DEMAND_SPEND_SUB_ITEMS {
  RI_ON_DEMAND_EQUIVALENT_SPEND_EC2 = 'Reserved Instancees On-Demand Equivalent Spend (EC2)',
  RI_ON_DEMAND_EQUIVALENT_SPEND_ELASTIC_CACHE = 'Reserved Instancees On-Demand Equivalent Spend (ElasticCache)',
  RI_ON_DEMAND_EQUIVALENT_SPEND_MEMORY_DB = 'Reserved Instancees On-Demand Equivalent Spend (MemoryDB)',
  RI_ON_DEMAND_EQUIVALENT_SPEND_OPEN_SEARCH = 'Reserved Instancees On-Demand Equivalent Spend (OpenSearch)',
  RI_ON_DEMAND_EQUIVALENT_SPEND_ELASTIC_SEARCH = 'Reserved Instancees On-Demand Equivalent Spend (ElasticSearch)',
  RI_ON_DEMAND_EQUIVALENT_SPEND_REDSHIFT = 'Reserved Instancees On-Demand Equivalent Spend (Redshift)',
  RI_ON_DEMAND_EQUIVALENT_SPEND_RDS = 'Reserved Instancees On-Demand Equivalent Spend (RDS)',
  SP_ON_DEMAND_SPEND_EC2_FARGATE_LAMDA_SAGEMAKER = 'Savings Plan On-Demand Spend (EC2, Fargate, Lamba,Sagemaker)(Equivalent Spend + Spend Not Covered)',
}

export enum AWS_SAVINGS_EFFECTIVENESS_COMPUTE_RI_SP_SAVINGS_SUB_ITEMS {
  SAVINGS_FROM_RELATED_RI_EC2 = 'Savings from related Reserved Insatance (EC2)',
  SAVINGS_FROM_RELATED_RI_ELASTIC_CACHE = 'Savings from related Reserved Insatance (ElasticCache)',
  SAVINGS_FROM_RELATED_RI_MEMORY_DB = 'Savings from related Reserved Insatance (MemoryDB)',
  SAVINGS_FROM_RELATED_RI_OPEN_SERACH = 'Savings from related Reserved Insatance (OpenSearch)',
  SAVINGS_FROM_RELATED_RI_ELASTIC_SEARCH = 'Savings from related Reserved Insatance (ElasticSearch)',
  SAVINGS_FROM_RELATED_RI_REDSHIFT = 'Savings from related Reserved Insatance (Redshift)',
  SAVINGS_FROM_RELATED_RI_RDS = 'Savings from related Reserved Insatance (RDS)',
  SAVINGS_PLAN_FROM_RELATED_SAVINGS_PLAN = 'Savings Plan from related Savings Plan',
}

export enum GCP_SAVINGS_EFFECTIVENESS_ITEMS {
  CUD_ON_DEMAND_SPEND = 'CUD On-Demand Spend',
  CUD_NET_SAVINGS = 'CUD Net Savings',
  COST_TO_ACHIEVE_SAVINGS = 'Cost to Achieve Savings',
}

export enum GCP_SAVINGS_EFFECTIVENESS_SUB_ITEMS {
  CLOUD_SQL = 'Cloud SQL (CUD Equivalent Spend + CUD Spend Not Covered)',
  CLOUD_SPANNER = 'Cloud Spanner (CUD Equivalent Spend + CUD Spend Not Covered)',
  COMPUTE = 'Compute [VMware Engine, Cloud Run, GKE, Compute Engine] (CUD Equivalent Spend + CUD Spend Not Covered)',
}

export const GCP_SAVINGS_EFFECTIVENESS_ITEMS_FIELDS = [
  {
    key: GCP_SAVINGS_EFFECTIVENESS_ITEMS.CUD_ON_DEMAND_SPEND,
    field: 'totalOnDemandSpend',
    subItemField: 'onDemandSpend',
  },
  {
    key: GCP_SAVINGS_EFFECTIVENESS_ITEMS.CUD_NET_SAVINGS,
    field: 'totalNetSavings',
    subItemField: 'netSavings',
  },
  {
    key: GCP_SAVINGS_EFFECTIVENESS_ITEMS.COST_TO_ACHIEVE_SAVINGS,
    field: 'costToAchieveSavings',
  },
];

export const GCP_SAVINGS_EFFECTIVENESS_SUB_ITEMS_TO_SERVICES_MAP = [
  {
    key: GCP_SAVINGS_EFFECTIVENESS_SUB_ITEMS.CLOUD_SQL,
    services: [GCP_SERVICES.CLOUD_SQL],
  },
  {
    key: GCP_SAVINGS_EFFECTIVENESS_SUB_ITEMS.CLOUD_SPANNER,
    services: [GCP_SERVICES.CLOUD_SPANNER],
  },
  {
    key: GCP_SAVINGS_EFFECTIVENESS_SUB_ITEMS.COMPUTE,
    services: [
      GCP_SERVICES.VMWARE_ENGINE,
      GCP_SERVICES.CLOUD_RUN,
      GCP_SERVICES.GKE,
      GCP_SERVICES.COMPUTE_ENGINE,
    ],
  },
];
