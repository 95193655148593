import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { dispatchUpdatedCustomViewData } from 'pages/CustomDashboardPage/utils';
import {
  customDashboard,
  setSelectedDimensions,
} from 'redux/customDashboardSlice';

import DimensionListItem from './components/DimensionsListItem';
import './index.scss';

const DimensionList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedDimensions } = useSelector(customDashboard);

  /**
   * @function handleOnDragEnd
   * @description Updates the selected dimensions list based on the drag and drop
   * @param result - result of the drag and drop
   */
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedDimensions = Array.from(selectedDimensions);
    const [reorderedItem] = updatedDimensions.splice(result.source.index, 1);
    updatedDimensions.splice(result.destination.index, 0, reorderedItem);
    dispatch(setSelectedDimensions(updatedDimensions));
    dispatchUpdatedCustomViewData();
  };

  return (
    <div className="dimension-list">
      {selectedDimensions?.length === 0 ? (
        <div className="empty-dimensions-message font-subHeader-small">
          {t('customDashboard.optionsLabels.clickToAddDimension')}
        </div>
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="dimensions">
            {(provided) => (
              <ul
                className="draggable-dimension-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selectedDimensions.map((dimension, index) => (
                  <Draggable
                    key={dimension.field}
                    draggableId={dimension.field}
                    index={index}
                  >
                    {(provided) => (
                      <li ref={provided.innerRef} {...provided.draggableProps}>
                        <DimensionListItem
                          dimension={dimension}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default DimensionList;
