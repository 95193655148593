import { useState } from 'react';
import { Menu, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import SelectDropdown from 'components/Select';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import Input from 'components/Input';
import {
  MY_DASHBOARD_TYPES_DATA,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import { GroupType } from 'pages/GroupsPage/types';
import { SnowflakeIntegrationType } from 'pages/IntegrationsPage/types';
import { IntegrationTypes } from 'pages/IntegrationsPage/constants';
import { ConnectionListType } from 'types/dashboard';
import { ImportFilesListType } from 'types/importFiles';
import {
  getGroupProvider,
  getGroupProviderLogo,
  getProviderSmallLogo,
} from 'utils/providerDetails';

import './index.scss';

const { Option } = Select;

type DatasourceDropdownProps = {
  connections?: ConnectionListType[];
  groups?: GroupType[];
  importConnections?: ImportFilesListType[];
  integrations?: SnowflakeIntegrationType[];
  selectedValue?: string;
  onSelect: (value: string, typeOfDashboard: string) => void;
  loading?: boolean;
  dashboardType: string;
  allowedConnectionTypes: string[];
  popupClassName?: string;
};

const DatasourceDropdown = ({
  connections,
  groups,
  importConnections,
  integrations,
  selectedValue,
  onSelect,
  loading,
  dashboardType,
  allowedConnectionTypes = [
    MY_DASHBOARD_TYPES.SINGLE_CONNECTION,
    MY_DASHBOARD_TYPES.GROUP,
    MY_DASHBOARD_TYPES.IMPORTS,
    MY_DASHBOARD_TYPES.SNOWFLAKE,
  ],
  popupClassName,
}: DatasourceDropdownProps) => {
  const { t } = useTranslation();

  const [searchKey, setSearchKey] = useState('');
  const [datasourceMenu, setDatasourceMenu] = useState(dashboardType);

  const isSearchKeyMatched = (name: string) => {
    return name.trim().toLowerCase().includes(searchKey.trim().toLowerCase());
  };

  const ConnectionDropdownOptions = (
    <>
      {connections
        ?.filter((connection) => isSearchKeyMatched(connection.name))
        ?.map((connection) => (
          <Option value={connection.connectorId} key={connection.connectorId}>
            <div className="menu-item flex flex-align-items-center flex-gap-8">
              <div className="logo">
                <img
                  src={getProviderSmallLogo(connection.provider)}
                  alt={`${connection?.provider} Logo`}
                />
              </div>
              <div
                className={`${
                  selectedValue === connection.connectorId &&
                  'selected-dropdown-option'
                }`}
              >
                {connection.displayName}
              </div>
            </div>
          </Option>
        ))}
    </>
  );

  const GroupDropdownOptions = (
    <>
      {groups
        ?.filter((group) => isSearchKeyMatched(group.name))
        ?.map((group) => (
          <Option value={group.name} key={group.name}>
            <div className="menu-item flex flex-align-items-center flex-gap-8">
              <div className="logo">
                <img
                  height={25}
                  width={25}
                  src={getGroupProviderLogo(getGroupProvider(group))}
                  alt="Logo"
                />
              </div>
              <div
                className={`${
                  selectedValue === group.name && 'selected-dropdown-option'
                }`}
              >
                {group.name}
              </div>
            </div>
          </Option>
        ))}
    </>
  );

  const ImportDropdownOptions = (
    <>
      {importConnections
        ?.filter((importConnection) =>
          isSearchKeyMatched(importConnection.transactionName)
        )
        ?.map((importConnection: ImportFilesListType) => (
          <Option
            value={importConnection.transactionName}
            key={importConnection.transactionName}
          >
            <div className="menu-item flex flex-align-items-center flex-gap-8">
              <div className="logo">
                <img
                  src={getProviderSmallLogo(
                    importConnection.datasourceProvider
                  )}
                  alt="Logo"
                />
              </div>
              <div
                className={`${
                  selectedValue === importConnection.transactionName &&
                  'selected-dropdown-option'
                }`}
              >
                {importConnection.transactionName}
              </div>
            </div>
          </Option>
        ))}
    </>
  );

  const IntegrationDropdownOptions = (
    <>
      {integrations
        ?.filter((integration) => isSearchKeyMatched(integration.name))
        ?.map((importConnection: SnowflakeIntegrationType) => (
          <Option
            value={importConnection.integrationId}
            key={importConnection.integrationId}
          >
            <div className="menu-item flex flex-align-items-center flex-gap-8">
              <div className="logo">
                <img
                  src={getProviderSmallLogo(IntegrationTypes.SNOWFLAKE)}
                  alt="Logo"
                />
              </div>
              <div
                className={`${
                  selectedValue === importConnection.integrationId &&
                  'selected-dropdown-option'
                }`}
              >
                {importConnection.name}
              </div>
            </div>
          </Option>
        ))}
    </>
  );

  const ConnectionsGroupsSwitch = (
    <div className="flex flex-column flex-gap-8">
      <HorizontalNavigationMenu
        menuItems={MY_DASHBOARD_TYPES_DATA.filter((navMenu) =>
          allowedConnectionTypes.includes(navMenu.type)
        ).map((navMenu) => (
          <Menu.Item
            key={navMenu.type}
            eventKey={navMenu.type}
            className={`font-caption-bold flex-fit ${
              navMenu.type === datasourceMenu && 'active-menu'
            }`}
            onClick={() => setDatasourceMenu(navMenu.type)}
          >
            {t(`dashboardLabels.${navMenu.label}`)}
          </Menu.Item>
        ))}
        selectedKeys={[]}
      />
      <Input
        type="search"
        rootClassName="full-width"
        value={searchKey}
        placeholder={t(
          'dashboardLabels.connectToDataSourceModalLabels.searchByConnectionOrGroupOrTransaction'
        )}
        onChange={(e: any) => setSearchKey(e.target.value)}
      />
    </div>
  );

  const getSelectedValueList = () => {
    switch (datasourceMenu) {
      case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
        return ConnectionDropdownOptions;
      case MY_DASHBOARD_TYPES.GROUP:
        return GroupDropdownOptions;
      case MY_DASHBOARD_TYPES.IMPORTS:
        return ImportDropdownOptions;
      case MY_DASHBOARD_TYPES.SNOWFLAKE:
        return IntegrationDropdownOptions;
    }
  };

  const getDropdownMenu = (menu: JSX.Element) => (
    <>
      {ConnectionsGroupsSwitch}
      {menu}
    </>
  );

  return (
    <SelectDropdown
      className="datasource-dropdown"
      value={selectedValue}
      placeholder={t(
        'dashboardLabels.connectToDataSourceModalLabels.selectDataSource'
      )}
      menu={getSelectedValueList()}
      dropdownRender={getDropdownMenu}
      onChange={(value: string) => {
        onSelect(value, datasourceMenu);
      }}
      popupClassName={`datasource-dropdown-menu ${popupClassName}`}
      loading={loading}
      designVersion2
      onDropdownVisibleChange={(open: boolean) => {
        if (open) {
          setSearchKey('');
          setDatasourceMenu(dashboardType);
        }
      }}
    />
  );
};

export default DatasourceDropdown;
