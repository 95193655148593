import { useTranslation } from 'react-i18next';

import { ReleaseNoteType } from 'pages/ReleaseNotesPage/types';

type ReleaseNoteHeadingProps = {
  release: ReleaseNoteType;
};

const ReleaseNoteHeading = ({ release }: ReleaseNoteHeadingProps) => {
  const { t } = useTranslation();

  return (
    <div className="release-note-heading flex flex-column flex-gap-8">
      <div className="form-header">
        {t('releaseNotes.version')} {release.version}
      </div>
      <div className="release-date font-caption-bold">
        {t('releaseNotes.releaseDate')} {release.releaseDate}
      </div>
    </div>
  );
};

export default ReleaseNoteHeading;
