import { useTranslation } from 'react-i18next';
import './index.scss';

type ModalFooterStepsProps = {
  currentStep: number;
  totalSteps: number;
};

const ModalFooterSteps = ({
  currentStep,
  totalSteps,
}: ModalFooterStepsProps) => {
  const { t } = useTranslation();
  return (
    <div className="modal-footer flex flex-align-items-center flex-space-between">
      <div className="modal-footer-step">{`${t(
        'addUserModal.step'
      )} ${currentStep} - ${totalSteps}`}</div>
      <div className="modal-footer-nav flex flex-row flex-center">
        {[...Array(totalSteps)]
          .map((_item, index) => ({ key: index }))
          .map((eachStep, index) => {
            return (
              <div
                className={`modal-footer-nav-item ${
                  index + 1 === currentStep ? 'active' : ''
                }`}
                key={eachStep.key}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ModalFooterSteps;
