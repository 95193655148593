import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import DashboardComponent from 'components/DashboardComponent';
import PieDonutChart from 'components/PieChartAnt';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Cascader from 'components/Cascader';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { LEGEND_ITEM_NAME_LENGTH_LIMIT } from 'constants/userConsole';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectDashboard } from 'redux/dashboardSlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { evaluateRequestArray } from 'utils/handleErrors';
import { DATE_FORMAT } from 'utils/date';
import { ClusterCostType } from 'pages/ContainerInsightsPage/types';
import { getClusterCostExcelData } from 'pages/ContainerInsightsPage/utils';
import { DateRangeFilter } from 'types/dataTypes';
import {
  DATE_RANGES,
  DATE_RANGE_OPTIONS,
} from 'pages/ContainerInsightsPage/constants';

import {
  displayRender,
  onChangeDateFilter,
  onChangeDates,
} from '../GCPUsageMetering/utils';

type ClusterCostProps = {
  isClusterCostTableView: boolean;
  setClusterCostTableView: (value: boolean) => void;
  requestStatus: string[];
  clusterCostData: ClusterCostType[];
  pdfView: boolean;
  clusterCostDateFilter: DateRangeFilter;
  setClusterCostDateFilter: (data: DateRangeFilter) => void;
  excelFilters: any[];
};

export const ClusterCost = ({
  isClusterCostTableView,
  setClusterCostTableView,
  requestStatus,
  clusterCostData,
  pdfView,
  clusterCostDateFilter,
  setClusterCostDateFilter,
  excelFilters,
}: ClusterCostProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { showExpandGraphModal } = useSelector(selectDashboard);

  const CreditDistributionByLocationColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('containerInsight.usageMeteringLabels.cluster'),
      dataIndex: 'cluster',
      key: 'cluster',
    },
    {
      title: t('containerInsight.usageMeteringLabels.cost', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'cost',
      key: 'cost',
      width: 180,
      align: 'center' as const,
      render: (text: any) => numberCommaSeparator(text),
    },
  ];

  const DateMenu: any = [
    ...DATE_RANGE_OPTIONS.map((option) => {
      if (option.key === DATE_RANGES.CUSTOM_RANGE) {
        return {
          value: option.key,
          label: option.title,
          children: [
            {
              label: (
                <DatePicker
                  format={DATE_FORMAT}
                  defaultValue={[
                    moment(clusterCostDateFilter.startDate),
                    moment(clusterCostDateFilter.endDate),
                  ]}
                  onChange={(_dates: any, dateString: [string, string]) =>
                    onChangeDates(dateString, setClusterCostDateFilter)
                  }
                  disabledDate={(current: any) =>
                    current && current > moment().endOf('day')
                  }
                />
              ),
            },
          ],
        };
      }
      return {
        value: option.key,
        label: option.title,
      };
    }),
  ];

  const filters = (
    <div className="filters">
      <Cascader
        className="width-40"
        options={DateMenu}
        defaultValue={[clusterCostDateFilter.option]}
        displayRender={() => displayRender(clusterCostDateFilter)}
        suffixIcon={
          <Icon iconName={ICONS.ARROW_DOWN_S_LINE} size={ICONS_SIZE.TWO_X} />
        }
        onChange={(value: any) =>
          onChangeDateFilter(value[0], setClusterCostDateFilter)
        }
      />
    </div>
  );

  const formatLegends = useCallback(
    (text: string) => {
      return text.length > LEGEND_ITEM_NAME_LENGTH_LIMIT &&
        !showExpandGraphModal
        ? `${text.substring(0, LEGEND_ITEM_NAME_LENGTH_LIMIT)}...`
        : text;
    },
    [showExpandGraphModal]
  );

  const getComponent = () =>
    isClusterCostTableView ? (
      <Table
        pagination={false}
        dataSource={clusterCostData.map((value, index) => {
          return {
            ...value,
            key: index,
          };
        })}
        columns={CreditDistributionByLocationColumns}
        scroll={{ y: '100%' }}
        designVersion2={true}
        fillContainer={true}
      />
    ) : (
      <PieDonutChart
        data={clusterCostData}
        angleField="cost"
        colorField="cluster"
        isDonut
        showLegends={!pdfView}
        disableAnimation={pdfView}
        additionalClassNames={`${pdfView && 'pdf-wrapper pdf-pie-wrapper'}`}
        colorOverride={clusterCostData.map((value) => value.color)}
        legendFormatter={formatLegends}
        prefixSymbol={currencySymbol}
      />
    );

  return (
    <div
      className="cluster-cost graph-card full-height flex flex-column flex-gap-16 flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.clusterCost')}
        graphName={'cluster-cost'}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isClusterCostTableView}
        setIsTableView={setClusterCostTableView}
        filters={filters}
        excelData={getClusterCostExcelData(clusterCostData, [
          ...excelFilters,
          {
            heading: t('excelExportLabels.startDate'),
            value: clusterCostDateFilter.startDate,
          },
          {
            heading: t('excelExportLabels.endDate'),
            value: clusterCostDateFilter.endDate,
          },
        ])}
        designVersion2={true}
      />
      <div className="graph flex-fit">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};
