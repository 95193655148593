import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/Checkbox';
import DropdownCheckbox from 'components/DropdownCheckbox';
import { ItemOptionsType } from 'components/DropdownCheckbox/types';
import { CheckboxValueType } from 'types/inputFields';
import { INPUT_SIZE } from 'constants/appearance';
import { GRAPH_ADDITIONAL_DROPDOWN } from './constants';

const { Option } = Select;

type GraphFilterDropdownProps = {
  allData: any[];
  selectedData: any[];
  setSelectedData: (val: any[]) => void;
  setLabels?: (val: string[]) => void;
  valueSuffix: string;
  fieldName: string;
  includeAdditionalOptions?: boolean;
  additionalClassNames?: string;
  loading?: boolean;
  designVersion2?: boolean;
};

const GraphFilterDropdown = ({
  allData,
  selectedData,
  setSelectedData,
  setLabels,
  valueSuffix,
  fieldName,
  includeAdditionalOptions = true,
  additionalClassNames,
  loading,
  designVersion2,
}: GraphFilterDropdownProps) => {
  const { t } = useTranslation();

  const [displayValue, setDisplayValue] = useState<CheckboxValueType>('');

  useEffect(() => {
    calculatedDisplayValue();
  }, [allData, selectedData]);

  /**
   * @function calculatedDisplayValue
   * @description Function to set the display value for the dropdown field
   */
  const calculatedDisplayValue = () => {
    if (selectedData.length === 0) {
      setDisplayValue(t('checkBoxDropdown.none'));
      return;
    }

    if (selectedData.length === allData.length) {
      setDisplayValue(t('checkBoxDropdown.all') + ' ' + valueSuffix);
      return;
    }

    if (includeAdditionalOptions) {
      if (
        selectedData.length === 10 &&
        isEqual(
          filterUpto(allData, 10),
          filterUpto(selectedData, selectedData.length)
        )
      ) {
        setDisplayValue(t('checkBoxDropdown.top10') + ' ' + valueSuffix);
        return;
      }

      if (
        selectedData.length === 20 &&
        isEqual(
          filterUpto(allData, 20),
          filterUpto(selectedData, selectedData.length)
        )
      ) {
        setDisplayValue(t('checkBoxDropdown.top20') + ' ' + valueSuffix);
        return;
      }
    }

    setDisplayValue(undefined);
  };

  /**
   * @function filterUpto
   * @description Function to filter the data upto given count
   * @param data non filtered data
   * @param count count upto which the data to be filtered
   * @returns an array of elements containing filtered data
   */
  const filterUpto = (data: any[], count: number) => {
    return data.slice(0, count).map((item) => item[fieldName]);
  };

  /**
   * @function isEqual
   * @description Function to compare two arrays
   * @param arr1 first array
   * @param arr2 second array
   * @returns boolean value true if array is equal else false
   */
  const isEqual = (arr1: any[], arr2: any[]) => {
    return (
      arr1.length === arr2.length &&
      arr1.every((element) => {
        return arr2.includes(element);
      })
    );
  };

  /**
   * @function onClickSelection
   * @description callback for onclick event of each option
   * @param e Checkbox input event
   * @param count count of elements selected for additional options like top10, top20, all, etc.
   */
  const onClickSelection = (e: CheckboxChangeEvent, count: number) => {
    setDisplayValue(e.target.value + ' ' + valueSuffix);
    const newElements = allData.slice(0, count);
    const removeExisting = selectedData.filter(
      (item) =>
        !newElements
          .map((element) => element[fieldName])
          .includes(item[fieldName])
    );
    if (e?.target?.checked) {
      setSelectedData([...newElements]);
    } else if (e.target !== undefined) {
      setSelectedData([...removeExisting]);
    }
  };

  /**
   * @function isCheckedForAdditionalOptions
   * @description Function to check if the checkbox is checked or not for additional options
   * @param count count of elements selected for additional options like top10, top20, all, etc.
   * @returns boolean true if the checkbox is checked, else false
   */
  const isCheckedForAdditionalOptions = (count: number) => {
    return isEqual(
      filterUpto(allData, count),
      filterUpto(selectedData, selectedData.length)
    );
  };

  /**
   * @function isChecked
   * @description Function to check if the checkbox is checked or not.
   * @param option option of type ItemOptionsType associated with the checkbox
   * @returns boolean true if the checkbox is checked, else false
   */
  const isChecked = (option: ItemOptionsType) => {
    return (
      selectedData.find((item) => item[fieldName] === option.value) !==
      undefined
    );
  };

  /**
   * @function onClickCheckBox
   * @description Callback function for each checkbox in the dropdown except additional options.
   * @param e Checkbox input event
   * @param option option of type ItemOptionsType associated with the checkbox
   * @returns boolean true if the checkbox is checked, else false
   */
  const onClickCheckBox = (option: ItemOptionsType) => {
    const element = allData.find((item) => item[fieldName] === option.value);
    if (!selectedData.some((item) => item[fieldName] === element[fieldName])) {
      setSelectedData([...selectedData, element]);
    } else {
      setSelectedData([
        ...selectedData.filter(
          (item) => item[fieldName] !== element[fieldName]
        ),
      ]);
    }
  };

  const additionalOptions = (
    <>
      {GRAPH_ADDITIONAL_DROPDOWN.filter(
        (item) => item.count <= allData.length
      ).map((item) => (
        <Option key={item.key}>
          <Checkbox
            className={`${
              isCheckedForAdditionalOptions(item.count) && 'font-button'
            }`}
            key={item.key}
            onChange={(e) => onClickSelection(e, item.count)}
            value={t(`checkBoxDropdown.${item.title}`)}
            checked={isCheckedForAdditionalOptions(item.count)}
          >
            {t(`checkBoxDropdown.${item.title}`) + ' ' + valueSuffix}
          </Checkbox>
        </Option>
      ))}
    </>
  );

  return (
    <DropdownCheckbox
      itemOptions={allData.map((item) => ({
        title: item[fieldName],
        value: item[fieldName],
      }))}
      value={displayValue}
      selectedItems={selectedData.map((item) => item[fieldName])}
      setSelectedItems={(val: string[]) => {
        setLabels?.(val);
      }}
      additionalOptions={
        includeAdditionalOptions ? additionalOptions : undefined
      }
      checkAllCallBack={(e) => onClickSelection(e, allData.length)}
      isAllChecked={isCheckedForAdditionalOptions(allData.length)}
      isChecked={isChecked}
      onClickOption={onClickCheckBox}
      additionalClassNames={additionalClassNames}
      loading={loading}
      designVersion2={designVersion2}
      size={INPUT_SIZE.SMALL}
    />
  );
};

export default GraphFilterDropdown;
