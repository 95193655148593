import { Divider, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import { useSelector } from 'react-redux';

import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Input from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import { TERMS_LIST } from 'pages/PurchasePage/constants';
import PurchaseFooter from 'pages/PurchasePage/components/PurchaseFooter';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import {
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';

import { PurchaseComputeSpType } from '../../types';
import { AZURE_COMPUTE_SP_DEFAULT_VALUES } from '../../constants';

import './index.scss';

type BuyEc2RiFormModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
};

const BuyEc2RiFormModal = ({ show, setShow }: BuyEc2RiFormModalProps) => {
  const { t } = useTranslation();

  const { currencySymbol } = useSelector(selectCommonUtility);

  const [computeFormData, setComputeFormData] = useState<PurchaseComputeSpType>(
    AZURE_COMPUTE_SP_DEFAULT_VALUES
  );
  const availableBillingSubscriptions: string[] = [];
  const fetchBillingSubscriptionReqStatus = REQUEST_STATUS.SUCCESS;
  const [nameValidation, setNameValidation] = useState('');
  const [billingSubscriptionValidation, setBillingSubscriptionValidation] =
    useState('');
  const [applyEligibilityValidation, setApplyEligibilityValidation] =
    useState('');
  const [onDemandCommitmentValidation, setOnDemandCommitmentValidation] =
    useState('');
  const [billingFrequencyValidation, setBillingFrequencyValidation] =
    useState('');
  const [offeringDetailsRequestStatus, setOfferingDetailsRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [offeringDetailsValidation, setOfferingDetailsValidation] =
    useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    // fetch all the billing subscriptions
  }, []);

  useEffect(() => {
    let source = axios.CancelToken.source();

    if (isDataLoaded) {
      setOfferingDetailsValidation('');
    } else {
      setIsDataLoaded(true);
    }

    if (validateAllFields(false)) {
      fetchOfferingDetails(source);
    }

    return () => {
      source.cancel();
    };
  }, [
    computeFormData.name,
    computeFormData.billingSubscription,
    computeFormData.applyEligibility,
    computeFormData.term,
    computeFormData.onDemandCommitment,
    computeFormData.billingFrequency,
    availableBillingSubscriptions,
  ]);

  /**
   * @function fetchOfferingDetails
   * @description Function to fetch the offering details
   * @param cancelTokenSource Cancel token used to cancel the api if there is a call in progress
   */
  const fetchOfferingDetails = (cancelTokenSource: CancelTokenSource) => {
    setOfferingDetailsRequestStatus(REQUEST_STATUS.PROCESSING);

    // getAwsEc2RiOfferingDetails(requestBody, cancelTokenSource.token)
    //   .then((res: any) => {
    //     if (res?.status === 200) {
    //       const data = res?.data?.responseData;
    //       if (data.offeringId) {
    //         setComputeFormData({
    //           ...computeFormData,
    //           averageMonthlyRecurringCost:
    //             data?.averageMonthlyRecurringCost || 0,
    //           oneTimePayment: data?.totalDue || 0,
    //           offeringId: data?.offeringId || '',
    //         });
    //         setOfferingDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
    //         return;
    //       }
    //       setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
    //       setOfferingDetailsValidation(
    //         t('purchasePage.reservedInstancesLabels.offeringIdNullError')
    //       );
    //       return;
    //     }
    //     setOfferingDetailsRequestStatus(REQUEST_STATUS.ERROR);
    //     setOfferingDetailsValidation(
    //       t('purchasePage.reservedInstancesLabels.offeringDetailsError')
    //     );
    //   })
    //   .catch((e) => {
    //     if (axios.isCancel(e)) {
    //       return;
    //     }

    //     onApiCallError(e, false, setOfferingDetailsRequestStatus);
    //     if (!axios.isCancel(e)) {
    //       setOfferingDetailsValidation(
    //         e?.response?.data?.message ||
    //           t('purchasePage.reservedInstancesLabels.offeringDetailsError')
    //       );
    //     }
    //   });
  };

  /**
   * @function validateName
   * @description Function to validate the name field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateName = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return (
      !validateEmptyField(
        value,
        t(`purchasePage.savingsPlansLabels.name`),
        setNameValidation,
        addErrorMessage
      ) &&
      validateStringLengthLessThan(
        value,
        MAX_CHARACTER_LIMIT,
        t('purchasePage.savingsPlansLabels.name'),
        setNameValidation
      )
    );
  };

  /**
   * @function validateBillingSubscription
   * @description Function to validate the billing subscription field
   * @param value value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateBillingSubscription = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.savingsPlansLabels.billingSubscription`),
      setBillingSubscriptionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateApplyEligibility
   * @description Function to validate the apply eligibility field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateApplyEligibility = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.savingsPlansLabels.applyToAnyEligibleResource`),
      setApplyEligibilityValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateOnDemandCommitment
   * @description Function to validate on demand commitment field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateOnDemandCommitment = (
    value: number | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.savingsPlansLabels.hourlyOnDemandCommitment`),
      setOnDemandCommitmentValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateBillingFrequency
   * @description Function to validate the billing frequency field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateBillingFrequency = (
    value: number | string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t(`purchasePage.savingsPlansLabels.billingFrequency`),
      setBillingFrequencyValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAllFields = (addErrorMessage: boolean = true) => {
    let validation = true;

    if (!validateName(computeFormData.name, addErrorMessage)) {
      validation = false;
    }

    if (
      !validateBillingSubscription(
        computeFormData.billingSubscription,
        addErrorMessage
      )
    ) {
      validation = false;
    }

    if (
      !validateApplyEligibility(
        computeFormData.applyEligibility,
        addErrorMessage
      )
    ) {
      validation = false;
    }

    if (
      !validateOnDemandCommitment(
        computeFormData.onDemandCommitment,
        addErrorMessage
      )
    ) {
      validation = false;
    }

    if (
      !validateBillingFrequency(
        computeFormData.billingFrequency,
        addErrorMessage
      )
    ) {
      validation = false;
    }

    if (!availableBillingSubscriptions?.length) {
      validation = false;
    }

    return validation;
  };

  /**
   * @function onClickBuy
   * @description Callback function for buy button click
   */
  const onClickBuy = () => {
    if (!validateAllFields()) {
      return;
    }

    //  Place order API integration
    setShow(false);
  };

  const ApplyEligibilityOptions = [
    {
      value: 'shared',
      label: t(
        'purchasePage.savingsPlansLabels.sharedAcrossSubscriptionsBillingScope'
      ),
    },
    {
      value: 'onlyInSubscription',
      label: t('purchasePage.savingsPlansLabels.onlyInTheSubscription'),
    },
    {
      value: 'specificResourceGroup',
      label: t(
        'purchasePage.savingsPlansLabels.inSpecificResourceGroupInTheSelectedSubscription'
      ),
    },
    {
      value: 'managementGroup',
      label: t(
        'purchasePage.savingsPlansLabels.inManagementGroupOfTheSelectedSubscription'
      ),
    },
  ];

  const BillingFrequencyOptions = [
    {
      value: 'monthly',
      label: t('purchasePage.savingsPlansLabels.monthly'),
    },
    {
      value: 'allUpfront',
      label: t('purchasePage.savingsPlansLabels.allUpfront'),
    },
  ];

  return (
    <DrawerComponent
      className="compute-sp-purchase-form"
      width={481}
      open={show}
      title={`${t('purchasePage.savingsPlansLabels.purchase')} - ${t(
        'purchasePage.savingsPlansLabels.compute'
      )}`}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-column">
          <div className="plan-cost-summary flex flex-column flex-gap-8 font-caption">
            <span className="font-button">
              {t('purchasePage.savingsPlansLabels.planCost')}
            </span>
            {offeringDetailsRequestStatus === REQUEST_STATUS.PROCESSING ? (
              <Skeleton active paragraph={{ rows: 3 }} title={false} />
            ) : (
              <>
                <div className="flex flex-space-between">
                  <span>
                    {t('purchasePage.savingsPlansLabels.monthlyCostOfPlan')}
                  </span>
                  <span>{currencySymbol}0.00</span>
                </div>
                <div className="flex flex-space-between">
                  <span>
                    {t('purchasePage.savingsPlansLabels.totalCostOfPlan')}
                  </span>
                  <span>{currencySymbol}0.00</span>
                </div>
                <div className="flex flex-space-between">
                  <span>
                    {t(
                      'purchasePage.savingsPlansLabels.totalDoesNotIncludeTaxes'
                    )}
                  </span>
                  <span>{currencySymbol}0.00</span>
                </div>
              </>
            )}
          </div>
          <Divider />
          <PurchaseFooter
            totalHeading={t('purchasePage.savingsPlansLabels.totalDueNow')}
            totalValue={0}
            okTitle={t('purchasePage.cart.buy')}
            onClickCancel={() => setShow(false)}
            onClickOk={onClickBuy}
            loading={offeringDetailsRequestStatus === REQUEST_STATUS.PROCESSING}
            validationMessage={offeringDetailsValidation}
          />
        </div>
      }
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.savingsPlansLabels.name')}
            required={true}
          />
          <Input
            value={computeFormData.name}
            placeholder={t('purchasePage.savingsPlansLabels.name')}
            onChange={(e: any) => {
              setComputeFormData({
                ...computeFormData,
                name: e.target.value,
              });
              validateName(e.target.value);
            }}
            onBlur={() => {
              validateName(computeFormData.name);
            }}
            designVersion2
          />
          <span
            style={{
              display: nameValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {nameValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.savingsPlansLabels.billingSubscription')}
            required={true}
          />
          <SelectDropdown
            value={computeFormData?.billingSubscription}
            options={availableBillingSubscriptions.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder={t('purchasePage.savingsPlansLabels.selectOption')}
            loading={
              fetchBillingSubscriptionReqStatus === REQUEST_STATUS.PROCESSING
            }
            onSelect={(value: any) => {
              setComputeFormData({
                ...computeFormData,
                billingSubscription: value,
              });
              validateBillingSubscription(value);
            }}
            onBlur={() => {
              validateBillingSubscription(computeFormData?.billingSubscription);
            }}
            designVersion2
          />
          <span
            style={{
              display: billingSubscriptionValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {billingSubscriptionValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t(
              'purchasePage.savingsPlansLabels.applyToAnyEligibleResource'
            )}
            required={true}
          />
          <SelectDropdown
            value={computeFormData?.applyEligibility}
            options={ApplyEligibilityOptions}
            placeholder={t('purchasePage.savingsPlansLabels.selectOption')}
            onSelect={(value: any) => {
              setComputeFormData({
                ...computeFormData,
                applyEligibility: value,
              });
              validateApplyEligibility(value);
            }}
            onBlur={() => {
              validateApplyEligibility(computeFormData?.applyEligibility);
            }}
            designVersion2
          />
          <span
            style={{
              display: applyEligibilityValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {applyEligibilityValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('purchasePage.cart.term')} required={true} />
          <RadioGroup
            options={TERMS_LIST}
            onChange={(e: any) => {
              setComputeFormData({ ...computeFormData, term: e.target.value });
            }}
            value={computeFormData.term}
            rootClassName="flex flex-column"
          />
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t(
              'purchasePage.savingsPlansLabels.hourlyOnDemandCommitment'
            )}
            required={true}
          />
          <Input
            value={computeFormData?.onDemandCommitment}
            type="number"
            prefix={currencySymbol}
            onChange={(value: number) => {
              setComputeFormData({
                ...computeFormData,
                onDemandCommitment: value,
              });
              validateOnDemandCommitment(value);
            }}
            onBlur={() => {
              validateOnDemandCommitment(computeFormData?.onDemandCommitment);
            }}
            designVersion2
          />
          <span
            style={{
              display: onDemandCommitmentValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {onDemandCommitmentValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('purchasePage.savingsPlansLabels.billingFrequency')}
            required={true}
          />
          <SelectDropdown
            value={computeFormData.billingFrequency}
            options={BillingFrequencyOptions}
            onChange={(value: any) => {
              setComputeFormData({
                ...computeFormData,
                billingFrequency: value,
              });
              validateBillingFrequency(value);
            }}
            onBlur={() => {
              validateBillingFrequency(computeFormData.billingFrequency);
            }}
            designVersion2
          />
          <span
            style={{
              display: billingFrequencyValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {billingFrequencyValidation}
          </span>
        </div>
      </section>
    </DrawerComponent>
  );
};

export default BuyEc2RiFormModal;
