import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import { RecommendationFiltersType } from 'pages/CostOptimizationInsightsPage/types';
import { ValueLabelType } from 'types/dashboard';

type RecommendationInputFieldProps = {
  filter: RecommendationFiltersType;
  value: ValueLabelType[];
  onChangeFilterValues: (
    filter: RecommendationFiltersType,
    values: ValueLabelType[] | null
  ) => void;
};

const RecommendationInputField = ({
  filter,
  value,
  onChangeFilterValues,
}: RecommendationInputFieldProps) => {
  const { t } = useTranslation();

  /**
   * @function onChangeRecommendation
   * @description Updated the recommendation value by calling the callback function.
   * @param value Recommendation value to be updated.
   */
  const onChangeRecommendation = (value: string) => {
    if (value.length === 0) {
      onChangeFilterValues(filter, []);
      return;
    }

    onChangeFilterValues(filter, [{ value: value }]);
  };

  return (
    <Input
      placeholder={t(
        'costOptimizationInsight.tagsModal.enterRecommendationKeyword'
      )}
      value={value[0]?.value}
      onChange={(e: any) => onChangeRecommendation(e.target.value)}
    />
  );
};

export default RecommendationInputField;
