import { useTranslation } from 'react-i18next';

import { getTableSorts } from 'pages/CustomDashboardPage/utils';

import SortListItem from './components/SortListItem';
import './index.scss';

const SortList = () => {
  const { t } = useTranslation();

  return (
    <div className="sort-list">
      {getTableSorts().length === 0 ? (
        <div className="empty-sort-message font-subHeader-small">
          {t('customDashboard.optionsLabels.clickToAddSort')}
        </div>
      ) : (
        <div className="flex flex-column flex-gap-8">
          {getTableSorts().map((sort, index) => (
            <SortListItem sort={sort} index={index} key={sort.label} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SortList;
