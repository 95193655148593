import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectGranulateConnection,
  setAWSConnection,
} from 'redux/granulateConnectionSlice';
import GranulateAppsAWSConnection from 'pages/ThirdPartyPage/GranulateAppsComponents/GranulateAppAWSConnection';
import { getAWSZonesData } from 'pages/ThirdPartyPage/components/GProfilerConnectionPage/services';
import { REQUEST_STATUS } from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { onApiCallError } from 'utils/handleErrors';

import { getAWSClusterData } from '../../services';

type AWSConnectionFormProps = {
  setSubmitDisabled: (val: boolean) => void;
};

const AWSConnectionForm = ({ setSubmitDisabled }: AWSConnectionFormProps) => {
  const { awsConnection, connectionList } = useSelector(
    selectGranulateConnection
  );
  const dispatch = useDispatch();

  const [serviceList, setServiceList] = useState<string[]>([]);
  const [zones, setZones] = useState<string[]>([]);
  const [clusterList, setClusterList] = useState<any[]>([]);
  const [inputLoaderStatus, setInputLoaderStatus] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchingClusterStatus, setFetchingClusterStatus] = useState<string>(
    REQUEST_STATUS.SUCCESS
  );
  const [clustersErrorMessage, setClustersErrorMessage] = useState<string>('');

  const resetAWSConnection = () => {
    dispatch(
      setAWSConnection({
        connectionName: '',
        cloudConnection: '',
        serviceName: '',
        region: '',
        cluster: '',
        accessKey: '',
        secretKey: '',
        connectorId: '',
      })
    );
  };

  const addConnectorIdForNewConnection = (connectorId: string) => {
    dispatch(
      setAWSConnection({
        ...awsConnection,
        connectorId,
      })
    );
  };

  const dispatchRegionAndResetingCluster = (region: string) => {
    dispatch(
      setAWSConnection({
        ...awsConnection,
        region,
        cluster: '',
      })
    );
  };

  const dispatchCluster = (cluster: string) => {
    dispatch(setAWSConnection({ ...awsConnection, cluster }));
  };

  const dispatchServiceName = (serviceName: string) => {
    dispatch(
      setAWSConnection({
        ...awsConnection,
        serviceName,
      })
    );
  };

  const dispatchCloudConnectionAndConnectorId = (
    cloudConnection: string,
    connectorId: string
  ) => {
    dispatch(
      setAWSConnection({
        ...awsConnection,
        cloudConnection,
        connectorId,
      })
    );
  };

  const dispatchConnectionName = (connectionName: string) => {
    dispatch(
      setAWSConnection({
        ...awsConnection,
        connectionName,
      })
    );
  };

  const dispatchAccessKey = (accessKey: string) => {
    dispatch(
      setAWSConnection({
        ...awsConnection,
        accessKey,
      })
    );
  };

  const dispatchSecretKey = (secretKey: string) => {
    dispatch(
      setAWSConnection({
        ...awsConnection,
        secretKey,
      })
    );
  };

  /**
   * @function fetchServiceList
   * @description Function to get all services
   */
  const fetchServiceList = () => {
    const serviceNames = connectionList
      .filter((connection) => connection.provider === PROVIDER.AWS)
      .map((connection) => connection.serviceDisplayName ?? '');
    setServiceList(serviceNames);
  };

  /**
   * @function fetchZones
   * @description Function to get all zones based on connectorId
   */
  const fetchZones = () => {
    setInputLoaderStatus(REQUEST_STATUS.PROCESSING);
    getAWSZonesData(awsConnection.connectorId)
      .then((res: any) => {
        if (res?.status === 200) {
          setInputLoaderStatus(REQUEST_STATUS.SUCCESS);
          setZones(res?.data);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setInputLoaderStatus);
      });
  };

  /**
   * @function fetchZones
   * @description Function to get all clusters based on zone
   * @param zone cluster related to zone
   */
  const fetchClusterList = (zone: string) => {
    const params = { connectorId: awsConnection.connectorId, region: zone };

    setFetchingClusterStatus(REQUEST_STATUS.PROCESSING);
    getAWSClusterData(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setFetchingClusterStatus(REQUEST_STATUS.SUCCESS);
          setClusterList(res?.data);
        }
      })
      .catch((e) => {
        setFetchingClusterStatus(REQUEST_STATUS.ERROR);
        setClustersErrorMessage(e.response.data.Message);
        onApiCallError(e, false, setFetchingClusterStatus);
      });
  };

  return (
    <GranulateAppsAWSConnection
      setSubmitDisabled={setSubmitDisabled}
      awsConnection={awsConnection}
      serviceList={serviceList}
      fetchServiceList={fetchServiceList}
      zones={zones}
      fetchZones={fetchZones}
      clusterList={clusterList}
      setClusterList={setClusterList}
      fetchClusterList={fetchClusterList}
      fetchingClusterStatus={fetchingClusterStatus}
      setFetchingClusterStatus={setFetchingClusterStatus}
      clustersErrorMessage={clustersErrorMessage}
      setClustersErrorMessage={setClustersErrorMessage}
      inputLoaderStatus={inputLoaderStatus}
      setInputLoaderStatus={setInputLoaderStatus}
      resetAWSConnection={resetAWSConnection}
      addConnectorIdForNewConnection={addConnectorIdForNewConnection}
      dispatchCluster={dispatchCluster}
      dispatchRegionAndResetingCluster={dispatchRegionAndResetingCluster}
      dispatchCloudConnectionAndConnectorId={
        dispatchCloudConnectionAndConnectorId
      }
      dispatchServiceName={dispatchServiceName}
      dispatchConnectionName={dispatchConnectionName}
      dispatchAccessKey={dispatchAccessKey}
      dispatchSecretKey={dispatchSecretKey}
      hideSetupConnection={true}
    />
  );
};

export default AWSConnectionForm;
