import { ControlFilterType } from './types';

/**
 * @function getVisibleFilters
 * @description Returns the list of filters by removing the filters that has show set to false.
 * @param filters The list of filters to be filtered
 * @returns The list of filters after filtering the one that is set to show false
 */
export const getVisibleFilters = (filters: ControlFilterType[]) => {
  return filters.filter(
    (filterItem) => filterItem.show === undefined || filterItem.show
  );
};
