import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectGProfilerInsights } from 'redux/gProfilerInsights';
import { selectTheme } from 'redux/themeSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import Icon from 'components/Icon';
import Table from 'components/Table';
import GraphHeader from 'components/GraphHeader';
import ExpandModal from 'components/ExpandModal';
import Button from 'components/Button';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REFRESH_INTERVAL } from 'constants/defaultValues';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import CreateTicketModal from '../CreateTicketModal';
import TicketInfoModal from '../TicketInfoModal';
import { ServiceNowStatusLabels, SERVICE_NOW_STATUS } from '../../constants';
import { getGprofilerInsights } from '../../services';
import { OptimizationTableType } from '../../type';
import { getTicketStatus, getTableExcelData } from '../../utils';

import './index.scss';

type InsightOptimizationProps = {
  data: any[];
  loading: boolean;
  impact: string | null;
  setInsights: (data: any[]) => void;
};

const InsightOptimization = ({
  data,
  loading,
  impact,
  setInsights,
}: InsightOptimizationProps) => {
  const { t } = useTranslation();
  const { selectedService } = useSelector(selectGProfilerInsights);
  const { theme } = useSelector(selectTheme);
  const { permissions } = useSelector(userAuthorization);

  const [selectedStatus, setSelectedStatus] = useState<string>(
    SERVICE_NOW_STATUS.UNDEFINED
  );
  const [serviceList, setServiceList] = useState<OptimizationTableType[]>([]);
  const [showCreateTicketModal, setShowCreateTicketModal] =
    useState<boolean>(false);
  const [showTicketInfoModal, setShowTicketInfoModal] =
    useState<boolean>(false);
  const [ticketInfo, setTicketInfo] = useState<OptimizationTableType | null>(
    null
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [showExpandedGraphModal, setShowExpandedGraphModal] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [insightsRequestStatus, setInsightsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    setServiceList([]);
    setSelectedRowKeys([]);
  }, [selectedService, selectedStatus]);

  useEffect(() => {
    const interval = setInterval(() => fetchInsights(), REFRESH_INTERVAL);
    filterDataBasedOnStatus();
    return () => clearInterval(interval);
  }, [data]);

  useEffect(() => {
    fetchInsights(true);
  }, [selectedStatus, showCreateTicketModal]);

  /**
   * @function filterDataBasedOnStatus
   * @description function to filter data based on selected status
   */
  const filterDataBasedOnStatus = () => {
    setFilteredData(
      data.filter(
        (item: any) =>
          item?.incidentStatus?.toLowerCase() === selectedStatus.toLowerCase()
      )
    );
  };

  const fetchInsights = (showLoading = false) => {
    showLoading && setInsightsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      serviceName: selectedService?.serviceName,
    };
    getGprofilerInsights(params)
      .then((res: any) => {
        showLoading && setInsightsRequestStatus(REQUEST_STATUS.SUCCESS);
        setInsights(res?.data);
      })
      .catch((e) => {
        showLoading && setInsightsRequestStatus(REQUEST_STATUS.ERROR);
        onApiCallError(e, false);
      });
  };

  /**
   * @function onSelectTableRow
   * @description Callback function for row selection
   * @param record selected row data.
   * @param checked boolean value to indicate row selection or deselection
   */
  const onSelectTableRow = (
    record: OptimizationTableType,
    checked: boolean
  ) => {
    if (checked) {
      setServiceList([...serviceList, record]);
      return;
    }
    setServiceList((prevServiceList) =>
      prevServiceList.filter((service) => service.key !== record.key)
    );
  };

  /**
   * @function getExcelData
   * @description Function to group data based on status
   * @return Grouped data
   */
  const getExcelData = () => {
    const convertedData: any[] = [];

    ServiceNowStatusLabels.forEach((status) => {
      const sheetData = {
        sheetName: status.tabTitle,
        tableData: data
          .filter((item) => item.incidentStatus === status.key)
          .map((item) => ({
            ...item,
            impact,
            incidentStatus: status.tabTitle,
          })),
      };
      convertedData.push(sheetData);
    });

    return convertedData;
  };

  const columns = [
    {
      title: t('gprofilerInsightsDashboard.name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (text: any, _record: any, _index: number) => {
        return <span className="font-caption">{text}</span>;
      },
      fixed: true,
    },
    {
      title: t('gprofilerInsightsDashboard.category'),
      dataIndex: 'category',
      key: 'category',
      width: 150,
      render: (text: any) => <span className="date">{text}</span>,
    },
    {
      title: t('gprofilerInsightsDashboard.impact'),
      key: 'impact',
      dataIndex: 'impact',
      width: 100,
      render: (_text: any) => {
        return <span className={'font-caption'}>{impact}</span>;
      },
    },
    {
      title: t('gprofilerInsightsDashboard.effort'),
      key: 'effort',
      dataIndex: 'effort',
      width: 100,
    },
    {
      title: t('gprofilerInsightsDashboard.description'),
      key: 'description',
      dataIndex: 'description',
      width: 400,
      render: (text: any) => {
        return <span className={'font-small'}>{text}</span>;
      },
    },
    {
      title: t('gprofilerInsightsDashboard.snowStatus'),
      key: 'snowStatus',
      dataIndex: 'snowStatus',
      width: 200,
      align: 'center',
      render: (_text: any, record: any, _index: number) => {
        return (
          <div className="flex flex-center">
            <span className="tbc font-caption-bold">
              {getTicketStatus(selectedStatus)}
            </span>
            {selectedStatus === SERVICE_NOW_STATUS.CLOSED ||
              (selectedStatus === SERVICE_NOW_STATUS.RESOLVED && (
                <span
                  className={`status ${record?.approvalstatus?.toLowerCase()}`}
                >
                  {record?.approvalstatus?.toLowerCase()}
                </span>
              ))}
          </div>
        );
      },
    },
    {
      title: t('gprofilerInsightsDashboard.resolution'),
      key: 'resolution',
      dataIndex: 'resolution',
      width: 300,
      render: (text: any) => {
        return <span className={'font-small'}>{text}</span>;
      },
    },
    {
      title: t('gprofilerInsightsDashboard.ticketInfo'),
      key: 'ticketInfo',
      dataIndex: 'ticketInfo',
      align: 'center',
      width: 150,
      render: (_text: any, record: OptimizationTableType, _index: number) => (
        <div
          className={
            selectedStatus === SERVICE_NOW_STATUS.UNDEFINED
              ? 'cursor-disabled'
              : ''
          }
        >
          <Icon
            className={
              selectedStatus === SERVICE_NOW_STATUS.UNDEFINED
                ? 'pointer-events-none'
                : ''
            }
            iconName={ICONS.EXTERNAL_LINK_LINE}
            size={ICONS_SIZE.XL}
            color={theme.primaryColor}
            onClick={() => {
              setTicketInfo(record);
              setShowTicketInfoModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const StatusBar = (
    <div className="status-bar font-caption-bold flex flex-align-items-center flex-gap-16">
      <span>{t('gprofilerInsightsDashboard.status')}</span>
      <div className="flex status-bar-menu">
        {ServiceNowStatusLabels.map((service) => (
          <AccessibleDiv
            className={`status-bar-menu-item cursor-pointer ${
              service.key === selectedStatus && 'selected'
            }`}
            key={service.key}
            onClick={() => {
              setSelectedStatus(service.key);
            }}
          >
            {service.tabTitle}
          </AccessibleDiv>
        ))}
      </div>
    </div>
  );

  const getComponent = () => (
    <div
      className={`optimization-table ${
        showExpandedGraphModal && 'full-height'
      }`}
    >
      <GraphHeader
        heading={selectedService?.serviceName ?? ''}
        graphName="optimization-table"
        setShowExpandedGraph={setShowExpandedGraphModal}
        isDownloadable={true}
        showExpandIcon={true}
        designVersion2
        excelData={{
          multipleSheets: true,
          sheets: getTableExcelData(
            selectedService?.serviceName ?? '',
            getExcelData()
          ),
        }}
        additionalComponents={{
          prefix: [
            <Button
              title={t('gprofilerInsightsDashboard.createTicket.createTicket')}
              disabled={serviceList.length === 0}
              onClick={() => setShowCreateTicketModal(true)}
              key="create-ticket-cta"
            />,
            <Icon
              iconName={ICONS.REFRESH_LINE}
              onClick={() => fetchInsights(true)}
              size={ICONS_SIZE.ONE_X}
              color={theme.buttonIconColor}
              key="refresh-cta"
            />,
          ],
        }}
      />
      {StatusBar}
      <Table
        rowSelection={{
          type: 'checkbox',
          hideSelectAll: true,
          getCheckboxProps: () => {
            return {
              disabled:
                (!permissions.thirdPartyAppsModify ||
                  selectedStatus !== SERVICE_NOW_STATUS.UNDEFINED) &&
                (!permissions.thirdPartyAppsWrite ||
                  selectedStatus !== SERVICE_NOW_STATUS.UNDEFINED),
            };
          },
          onSelect: onSelectTableRow,
          selectedRowKeys,
          onChange: (newSelectedRowKeys: number[]) =>
            setSelectedRowKeys(newSelectedRowKeys),
        }}
        pagination={false}
        dataSource={filteredData.map((item: any, index: number) => ({
          ...item,
          key: index + 1,
        }))}
        columns={columns}
        scroll={
          filteredData.length > 0 && { x: '100%', y: 'calc(100vh - 26em)' }
        }
        loading={loading || insightsRequestStatus === REQUEST_STATUS.PROCESSING}
        designVersion2
      />
      <CreateTicketModal
        show={showCreateTicketModal}
        setShow={setShowCreateTicketModal}
        items={serviceList.length}
        serviceList={serviceList}
        serviceName={selectedService?.serviceName}
        impact={impact}
      />
      <TicketInfoModal
        show={showTicketInfoModal}
        setShow={setShowTicketInfoModal}
        ticketInfo={ticketInfo}
        status={getTicketStatus(selectedStatus)}
      />
    </div>
  );

  return (
    <>
      {getComponent()}
      {showExpandedGraphModal && (
        <ExpandModal
          show={showExpandedGraphModal}
          onClose={() => setShowExpandedGraphModal(!showExpandedGraphModal)}
          graphContent={getComponent()}
        />
      )}
    </>
  );
};

export default InsightOptimization;
