import './index.scss';

type GraphBreadcrumbProps = {
  pathItems: string[];
};

const GraphBreadcrumb = ({ pathItems }: GraphBreadcrumbProps) => {
  return (
    <div className="graph-breadcrumb flex flex-gap-8 flex-align-items-center">
      {pathItems.map((value, index) => (
        <div className="flex flex-gap-8" key={value}>
          <span
            className={
              index !== pathItems.length - 1
                ? 'font-caption-bold'
                : 'font-caption'
            }
          >
            {value}
          </span>
          {index !== pathItems.length - 1 && (
            <span className="font-caption">/</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default GraphBreadcrumb;
