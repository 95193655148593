import { PROVIDER } from 'constants/cloudProviders';
import { COMPARATORS, CONJUNCTIONS, QUERY_FIELDS } from 'constants/requestBody';
import { getSearchKeyFilters } from 'pages/TagCompliancePage/utils';
import { modifyQueryForCount } from 'utils/requestDtos';
import {
  BASE_AWS_QUERY,
  BASE_GCP_QUERY,
  getBaseAzureQuery,
} from 'pages/TagCompliancePage/constants';

/**
 * @function getFullyCompliantCountQuery
 * @description Function to fetch the fully tag compliant resource count query
 * @param selectedProvider provider for which the data is fetched
 * @param searchKey search key in the search field
 * @param mandatoryTags mandatory tags list
 * @param azureBillingTableName billing table name for azure
 * @return Object containing the query
 */
export const getFullyCompliantCountQuery = (
  selectedProvider: string,
  searchKey: string,
  mandatoryTags: string[],
  azureBillingTableName: string
) => {
  let query;
  switch (selectedProvider) {
    case PROVIDER.AWS:
      query = getAwsFullyCompliantQuery(mandatoryTags, searchKey);
      break;
    case PROVIDER.GCP:
      query = getGcpFullyCompliantQuery(mandatoryTags, searchKey);
      break;
    case PROVIDER.AZURE:
      query = getAzureFullyCompliantQuery(
        mandatoryTags,
        searchKey,
        azureBillingTableName
      );
      break;
  }
  return modifyQueryForCount(query);
};

/**
 * @function getNonCompliantCountQuery
 * @description Function to fetch the non compliant resource count query
 * @param selectedProvider provider for which the data is fetched
 * @param searchKey search key in the search field
 * @param mandatoryTags mandatory tags list
 * @param azureBillingTableName billing table name for azure
 * @return Object containing the query
 */
export const getNonCompliantCountQuery = (
  selectedProvider: string,
  searchKey: string,
  mandatoryTags: string[],
  azureBillingTableName: string
) => {
  let query;
  switch (selectedProvider) {
    case PROVIDER.AWS:
      query = getAwsNonCompliantQuery(mandatoryTags, searchKey);
      break;
    case PROVIDER.GCP:
      query = getGcpNonCompliantQuery(mandatoryTags, searchKey);
      break;
    case PROVIDER.AZURE:
      query = getAzureNonCompliantQuery(
        mandatoryTags,
        searchKey,
        azureBillingTableName
      );
      break;
  }
  return modifyQueryForCount(query);
};

/**
 * @function getTotalCountQuery
 * @description Function to fetch the total resource count query
 * @param selectedProvider provider for which the data is fetched
 * @param searchKey search key in the search field
 * @param mandatoryTags mandatory tags list
 * @param azureBillingTableName billing table name for azure
 * @return Object containing the query
 */
export const getTotalCountQuery = (
  selectedProvider: string,
  searchKey: string,
  _: string[],
  azureBillingTableName: string
) => {
  let query;
  switch (selectedProvider) {
    case PROVIDER.AWS:
      query = getAwsTotalCountQuery(searchKey);
      break;
    case PROVIDER.GCP:
      query = getGcpTotalCountQuery(searchKey);
      break;
    case PROVIDER.AZURE:
      query = getAzureTotalCountQuery(searchKey, azureBillingTableName);
      break;
  }
  return modifyQueryForCount(query);
};

/**
 * @function getAwsFullyCompliantQuery
 * @description Function to fetch the aws fully compliant resource count query
 * @param mandatoryTags mandatory tags list
 * @param searchKey search key in the search field
 * @return Object containing the query
 */
const getAwsFullyCompliantQuery = (
  mandatoryTags: string[],
  searchKey: string
) => {
  return {
    ...BASE_AWS_QUERY,
    filterGroups: [
      {
        filters: mandatoryTags.map((tag) => ({
          field: tag,
          comparator: COMPARATORS.NOT_EQUAL_TO,
          value: '',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        })),
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      ...BASE_AWS_QUERY.filterGroups,
      ...getSearchKeyFilters(searchKey, PROVIDER.AWS),
    ],
  };
};

/**
 * @function getAwsNonCompliantQuery
 * @description Function to fetch the aws non compliant resource count query
 * @param mandatoryTags mandatory tags list
 * @param searchKey search key in the search field
 * @return Object containing the query
 */
const getAwsNonCompliantQuery = (
  mandatoryTags: string[],
  searchKey: string
) => {
  return {
    ...BASE_AWS_QUERY,
    filterGroups: [
      {
        filters: mandatoryTags.map((tag) => ({
          field: tag,
          comparator: COMPARATORS.EQUALS,
          value: '',
          conjunctToNextFilter: CONJUNCTIONS.AND,
        })),
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      ...BASE_AWS_QUERY.filterGroups,
      ...getSearchKeyFilters(searchKey, PROVIDER.AWS),
    ],
  };
};

/**
 * @function getAwsTotalCountQuery
 * @description Function to fetch the aws total resource count query
 * @param mandatoryTags mandatory tags list
 * @param searchKey search key in the search field
 * @return Object containing the query
 */
const getAwsTotalCountQuery = (searchKey: string) => {
  return {
    ...BASE_AWS_QUERY,
    filterGroups: [
      ...BASE_AWS_QUERY.filterGroups,
      ...getSearchKeyFilters(searchKey, PROVIDER.AWS),
    ],
  };
};

/**
 * @function getGcpFullyCompliantQuery
 * @description Function to fetch the gcp fully compliant resource count query
 * @param mandatoryTags mandatory tags list
 * @param searchKey search key in the search field
 * @return Object containing the query
 */
const getGcpFullyCompliantQuery = (
  mandatoryTags: string[],
  searchKey: string
) => {
  return {
    ...BASE_GCP_QUERY,
    subQuery: {
      ...BASE_GCP_QUERY.subQuery,
      filterGroups: [
        ...BASE_GCP_QUERY.subQuery.filterGroups,
        {
          filters: mandatoryTags.map((tag) => ({
            field: QUERY_FIELDS.TAG_KEY,
            comparator: COMPARATORS.EQUALS,
            value: tag,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          })),
          conjunctToNextGroup: CONJUNCTIONS.AND,
        },
        ...getSearchKeyFilters(searchKey, PROVIDER.GCP),
      ],
    },
  };
};

/**
 * @function getGcpNonCompliantQuery
 * @description Function to fetch the gcp non compliant resource count query
 * @param mandatoryTags mandatory tags list
 * @param searchKey search key in the search field
 * @return Object containing the query
 */
const getGcpNonCompliantQuery = (
  mandatoryTags: string[],
  searchKey: string
) => {
  return {
    ...BASE_GCP_QUERY,
    subQuery: {
      ...BASE_GCP_QUERY.subQuery,
      filterGroups: [
        ...BASE_GCP_QUERY.subQuery.filterGroups,
        {
          filters: mandatoryTags.map((tag) => ({
            field: QUERY_FIELDS.TAG_KEY,
            comparator: COMPARATORS.NOT_EQUAL_TO,
            value: tag,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          })),
          conjunctToNextGroup: CONJUNCTIONS.AND,
        },
        ...getSearchKeyFilters(searchKey, PROVIDER.GCP),
      ],
    },
  };
};

/**
 * @function getGcpTotalCountQuery
 * @description Function to fetch the gcp total resource count query
 * @param mandatoryTags mandatory tags list
 * @param searchKey search key in the search field
 * @return Object containing the query
 */
const getGcpTotalCountQuery = (searchKey: string) => {
  return {
    ...BASE_GCP_QUERY,
    subQuery: {
      ...BASE_GCP_QUERY.subQuery,
      filterGroups: [
        ...BASE_GCP_QUERY.subQuery.filterGroups,
        ...getSearchKeyFilters(searchKey, PROVIDER.GCP),
      ],
    },
  };
};

/**
 * @function getAzureFullyCompliantQuery
 * @description Function to fetch the azure fully compliant resource count query
 * @param mandatoryTags mandatory tags list
 * @param searchKey search key in the search field
 * @return Object containing the query
 */
const getAzureFullyCompliantQuery = (
  mandatoryTags: string[],
  searchKey: string,
  azureBillingTableName: string
) => {
  const BASE_AZURE_QUERY = getBaseAzureQuery(azureBillingTableName);
  return {
    ...BASE_AZURE_QUERY,
    filterGroups: [
      {
        filters: mandatoryTags.map((tag) => ({
          field: `CASE WHEN JSON_VALUE(Tags, '$."${tag}"') IS NOT NULL THEN 'true' ELSE 'false' END`,
          comparator: COMPARATORS.EQUALS,
          value: true,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        })),
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      ...BASE_AZURE_QUERY.filterGroups,
      ...getSearchKeyFilters(searchKey, PROVIDER.AZURE),
    ],
  };
};

/**
 * @function getAzureNonCompliantQuery
 * @description Function to fetch the azure non compliant resource count query
 * @param mandatoryTags mandatory tags list
 * @param searchKey search key in the search field
 * @return Object containing the query
 */
const getAzureNonCompliantQuery = (
  mandatoryTags: string[],
  searchKey: string,
  azureBillingTableName: string
) => {
  const BASE_AZURE_QUERY = getBaseAzureQuery(azureBillingTableName);
  return {
    ...BASE_AZURE_QUERY,
    filterGroups: [
      {
        filters: mandatoryTags.map((tag) => ({
          field: `CASE WHEN JSON_VALUE(Tags, '$."${tag}"') IS NOT NULL THEN 'true' ELSE 'false' END`,
          comparator: COMPARATORS.EQUALS,
          value: false,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        })),
        conjunctToNextGroup: CONJUNCTIONS.AND,
      },
      ...BASE_AZURE_QUERY.filterGroups,
      ...getSearchKeyFilters(searchKey, PROVIDER.AZURE),
    ],
  };
};

/**
 * @function getAzureTotalCountQuery
 * @description Function to fetch the azure total resource count query
 * @param mandatoryTags mandatory tags list
 * @param searchKey search key in the search field
 * @return Object containing the query
 */
const getAzureTotalCountQuery = (
  searchKey: string,
  azureBillingTableName: string
) => {
  const BASE_AZURE_QUERY = getBaseAzureQuery(azureBillingTableName);
  return {
    ...BASE_AZURE_QUERY,
    filterGroups: [
      ...BASE_AZURE_QUERY.filterGroups,
      ...getSearchKeyFilters(searchKey, PROVIDER.AZURE),
    ],
  };
};
