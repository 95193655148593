import i18n from 'i18n';
import moment from 'moment';

import { store } from 'redux/store';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { getPreviousMonthAndYear } from 'utils/dashboardUtils';
import {
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  YEAR_MONTH_WITHOUT_ZERO,
} from 'utils/date';
import { FilterType } from 'types/dashboard';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const ConsolidatedGCPCostTrendExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('costTrend.month'),
    key: 'month',
    dataKey: 'month',
    width: 15,
    alignment: 'center',
  },
  {
    header: i18n.t('costTrend.costInCurrency', {
      currencySymbol: currencySymbol,
    }),
    key: 'cost',
    dataKey: 'cost',
    width: 20,
    alignment: 'right',
  },
];

export const getGCPCloudServicesRequestBody = (
  additionalFilters?: FilterType[]
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
    },
    {
      label: QUERY_FIELDS.SERVICE,
      field: QUERY_FIELDS.SERVICE_DESCRIPTION,
    },
  ],
  groupBy: [QUERY_FIELDS.SERVICE],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.DESCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        ...(additionalFilters ?? []),
        {
          field: QUERY_FIELDS.INVOICE_MONTH,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: getPreviousMonthAndYear(1),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.INVOICE_MONTH,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: getPreviousMonthAndYear(0),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getConsolidatedYTDCostExportColumns = (providers: string[]) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('consolidatedYtdCost.month'),
    key: 'month',
    dataKey: 'month',
    width: 15,
    alignment: 'center',
  },
  ...providers.map((provider) => ({
    header: provider,
    key: provider,
    dataKey: provider,
    width: 20,
    alignment: 'center',
  })),
];

export const CostByServiceCategoryExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', alignment: 'center' },
  {
    header: i18n.t('costByComputeServices.service'),
    key: 'service',
    dataKey: 'service',
  },
  {
    header: i18n.t('costByComputeServices.provider'),
    dataKey: 'provider',
    key: 'provider',
  },
  {
    header: i18n.t('costByComputeServices.cost', {
      currencySymbol: currencySymbol,
    }),
    key: 'cost',
    dataKey: 'cost',
    alignment: 'right',
  },
];

export const ConsolidatedCloudServicesExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('consolidatedCloudServices.services'),
    key: 'service',
    dataKey: 'service',
    width: 30,
  },
  {
    header: i18n.t('consolidatedCloudServices.provider'),
    dataKey: 'provider',
    key: 'provider',
    width: 30,
  },
  {
    header: i18n.t('consolidatedCloudServices.familyType'),
    key: 'family',
    dataKey: 'family',
    width: 30,
  },
  {
    header: i18n.t('consolidatedCloudServices.spend', {
      currencySymbol: currencySymbol,
    }),
    key: 'cost',
    dataKey: 'cost',
    width: 20,
    alignment: 'right',
  },
];

export const ConsolidatedRegionsExcelExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('consolidatedRegions.region'),
    key: 'region',
    dataKey: 'region',
    width: 30,
  },
  {
    header: i18n.t('consolidatedRegions.cost', {
      currencySymbol: currencySymbol,
    }),
    key: 'cost',
    dataKey: 'cost',
    width: 20,
    alignment: 'right',
  },
];

export const getAWSCloudServicesRequestBody = (
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false,
  additionalFilters?: FilterType[]
) => {
  let fromDate = `#DATE('${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;
  let toDate = `#DATE('${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;

  if (isMigrated) {
    fromDate = `${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
    toDate = `${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
  }
  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
      },
      {
        label: QUERY_FIELDS.SERVICE,
        field: QUERY_FIELDS.PRODUCT_SERVICENAME,
      },
    ],
    groupBy: [QUERY_FIELDS.SERVICE],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          ...(additionalFilters ?? []),
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: fromDate,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: toDate,
          },
          {
            field: QUERY_FIELDS.BILL_BILL_TYPE,
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

export const getAzureCloudServicesRequestBody = (
  fromMonth: string,
  toMonth: string
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_BILLING_CURRENCY,
    },
    {
      label: QUERY_FIELDS.SERVICE,
      field: QUERY_FIELDS.PRODUCT_NAME,
    },
  ],
  groupBy: [QUERY_FIELDS.SERVICE],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.DESCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(fromMonth).startOf('month').format(HYPHEN_DATE_FORMAT),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.BILLING_PERIOD_END_DATE,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(toMonth).endOf('month').format(HYPHEN_DATE_FORMAT),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getAzureSpecificServicesRequestBody = (
  fromMonth: string,
  toMonth: string,
  additionalFilters?: FilterType[]
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_BILLING_CURRENCY,
    },
    {
      label: QUERY_FIELDS.SERVICE,
      field: QUERY_FIELDS.METER_CATEGORY,
    },
  ],
  groupBy: [QUERY_FIELDS.SERVICE],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.DESCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        ...(additionalFilters ?? []),
        {
          field: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(fromMonth).startOf('month').format(HYPHEN_DATE_FORMAT),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.BILLING_PERIOD_END_DATE,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(toMonth).endOf('month').format(HYPHEN_DATE_FORMAT),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getOCICloudServicesRequestBody = (
  fromMonth: string,
  toMonth: string
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_MY_COST,
    },
    {
      label: 'service',
      field: QUERY_FIELDS.PRD_SERVICE,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: ['service'],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      conjunctToNextGroup: CONJUNCTIONS.AND,
      filters: [
        {
          field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR).format(
            YEAR_MONTH_WITHOUT_ZERO
          ),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR).format(
            YEAR_MONTH_WITHOUT_ZERO
          ),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getGCPCostTrendRequestBody = (
  fromMonth: string,
  toMonth: string
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
    },
    {
      label: QUERY_FIELDS.MONTH,
      field: QUERY_FIELDS.INVOICE_MONTH,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: [QUERY_FIELDS.MONTH],
  orderBy: [
    {
      label: QUERY_FIELDS.MONTH,
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.INVOICE_MONTH,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: fromMonth,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.INVOICE_MONTH,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: toMonth,
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getAWSCostTrendRequestBody = (
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  let fromDate = `#DATE('${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;
  let toDate = `#DATE('${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;

  if (isMigrated) {
    fromDate = `${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
    toDate = `${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
  }

  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
      },
      {
        label: QUERY_FIELDS.MONTH,
        field: QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: [QUERY_FIELDS.MONTH],
    orderBy: [
      {
        label: QUERY_FIELDS.MONTH,
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: fromDate,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: toDate,
          },
          {
            field: QUERY_FIELDS.BILL_BILL_TYPE,
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

export const getAzureCostTrendRequestBody = (
  fromMonth: string,
  toMonth: string
) => ({
  columns: [
    {
      label: 'month',
      field: QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
    },
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_BILLING_CURRENCY,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: ['month'],
  orderBy: [
    {
      label: 'month',
      sort: ORDER_BY.ASCENDING,
    },
    {
      label: 'cost',
      sort: ORDER_BY.DESCENDING,
    },
  ],
  filterGroups: [
    {
      conjunctToNextGroup: CONJUNCTIONS.AND,
      filters: [
        {
          field: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(fromMonth).startOf('month').format(HYPHEN_DATE_FORMAT),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.BILLING_PERIOD_END_DATE,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(toMonth).endOf('month').format(HYPHEN_DATE_FORMAT),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getOCICostTrendRequestBody = (
  fromMonth: string,
  toMonth: string
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_MY_COST,
    },
    {
      label: 'invoiceMonth',
      field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: ['invoiceMonth'],
  orderBy: [
    {
      label: 'invoiceMonth',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      conjunctToNextGroup: CONJUNCTIONS.AND,
      filters: [
        {
          field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR).format(
            YEAR_MONTH_WITHOUT_ZERO
          ),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR).format(
            YEAR_MONTH_WITHOUT_ZERO
          ),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getGCPRegionsRequestBody = (
  fromMonth: string,
  toMonth: string
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
    },
    {
      label: QUERY_FIELDS.REGION,
      field: QUERY_FIELDS.LOCATION_ZONE,
    },
  ],
  groupBy: [QUERY_FIELDS.REGION],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.DESCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.INVOICE_MONTH,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: fromMonth,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.INVOICE_MONTH,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: toMonth,
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getAWSRegionsRequestBody = (
  fromMonth: string,
  toMonth: string,
  isMigrated: boolean = false
) => {
  let fromDate = `#DATE('${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;
  let toDate = `#DATE('${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
    .startOf('month')
    .format(HYPHEN_DATE_FORMAT)}')`;

  if (isMigrated) {
    fromDate = `${moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
    toDate = `${moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
      .startOf('month')
      .format(HYPHEN_DATE_FORMAT)}`;
  }

  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
      },
      {
        label: QUERY_FIELDS.REGION,
        field: QUERY_FIELDS.PRODUCT_REGION,
      },
    ],
    groupBy: [QUERY_FIELDS.REGION],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: fromDate,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: toDate,
          },
          {
            field: QUERY_FIELDS.BILL_BILL_TYPE,
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

export const getAzureRegionsRequestBody = (
  fromMonth: string,
  toMonth: string
) => ({
  columns: [
    {
      label: 'region',
      field: QUERY_FIELDS.RESOURCE_LOCATION,
    },
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_BILLING_CURRENCY,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: ['region'],
  filterGroups: [
    {
      conjunctToNextGroup: CONJUNCTIONS.AND,
      filters: [
        {
          field: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
            .startOf('month')
            .format(HYPHEN_DATE_FORMAT),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.BILLING_PERIOD_END_DATE,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
            .endOf('month')
            .format(HYPHEN_DATE_FORMAT),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getOCIRegionsRequestBody = (
  fromMonth: string,
  toMonth: string
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_MY_COST,
    },
    {
      label: 'region',
      field: QUERY_FIELDS.PRD_REGION,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: ['region'],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.ASCENDING,
    },
  ],
  filterGroups: [
    {
      conjunctToNextGroup: CONJUNCTIONS.AND,
      filters: [
        {
          field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(fromMonth, YEAR_MONTH_WITHOUT_SEPARATOR).format(
            YEAR_MONTH_WITHOUT_ZERO
          ),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(toMonth, YEAR_MONTH_WITHOUT_SEPARATOR).format(
            YEAR_MONTH_WITHOUT_ZERO
          ),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getGCPCostByServiceRequestBody = (selectedTrendMonth: string) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
    },
    {
      label: 'service',
      field: QUERY_FIELDS.SERVICE_DESCRIPTION,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: ['service'],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.DESCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.INVOICE_MONTH,
          comparator: COMPARATORS.EQUALS,
          value: moment(selectedTrendMonth, MONTH_YEAR_FORMAT).format(
            YEAR_MONTH_WITHOUT_SEPARATOR
          ),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getAWSCostByServiceRequestBody = (
  selectedTrendMonth: string,
  isMigrated: boolean = false
) => {
  let fromDate = `#DATE('${moment(selectedTrendMonth, MONTH_YEAR_FORMAT).format(
    HYPHEN_DATE_FORMAT
  )}')`;
  let toDate = `#DATE('${moment(selectedTrendMonth, MONTH_YEAR_FORMAT)
    .add(1, 'month')
    .format(HYPHEN_DATE_FORMAT)}')`;

  if (isMigrated) {
    fromDate = `${moment(selectedTrendMonth, MONTH_YEAR_FORMAT).format(
      HYPHEN_DATE_FORMAT
    )}`;
    toDate = `${moment(selectedTrendMonth, MONTH_YEAR_FORMAT)
      .add(1, 'month')
      .format(HYPHEN_DATE_FORMAT)}`;
  }
  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
      },
      {
        label: 'service',
        field: QUERY_FIELDS.PRODUCT_SERVICENAME,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['service'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: fromDate,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: toDate,
          },
          {
            field: QUERY_FIELDS.BILL_BILL_TYPE,
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

export const getAzureCostByServiceRequestBody = (
  selectedTrendMonth: string
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_BILLING_CURRENCY,
    },
    {
      label: 'service',
      field: QUERY_FIELDS.METER_CATEGORY,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: ['service'],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.DESCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(selectedTrendMonth, MONTH_YEAR_FORMAT).format(
            HYPHEN_DATE_FORMAT
          ),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.BILLING_PERIOD_END_DATE,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(selectedTrendMonth, MONTH_YEAR_FORMAT)
            .add(1, 'month')
            .format(HYPHEN_DATE_FORMAT),
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getGCPCostByResourceRequestBody = (
  selectedTrendMonth: string,
  selectedTrendService: string
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
    },
    {
      label: 'resourceName',
      field: QUERY_FIELDS.BILLING_RESOURCE_NAME,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: ['resourceName'],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.DESCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.INVOICE_MONTH,
          comparator: COMPARATORS.EQUALS,
          value: moment(selectedTrendMonth, MONTH_YEAR_FORMAT).format(
            YEAR_MONTH_WITHOUT_SEPARATOR
          ),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.SERVICE_DESCRIPTION,
          comparator: COMPARATORS.EQUALS,
          value: selectedTrendService,
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});

export const getAWSCostByResourceRequestBody = (
  selectedTrendMonth: string,
  selectedTrendService: string,
  isMigrated: boolean = false
) => {
  let fromDate = `#DATE('${moment(selectedTrendMonth, MONTH_YEAR_FORMAT).format(
    HYPHEN_DATE_FORMAT
  )}')`;
  let toDate = `#DATE('${moment(selectedTrendMonth, MONTH_YEAR_FORMAT)
    .add(1, 'month')
    .format(HYPHEN_DATE_FORMAT)}')`;

  if (isMigrated) {
    fromDate = `${moment(selectedTrendMonth, MONTH_YEAR_FORMAT).format(
      HYPHEN_DATE_FORMAT
    )}`;
    toDate = `${moment(selectedTrendMonth, MONTH_YEAR_FORMAT)
      .add(1, 'month')
      .format(HYPHEN_DATE_FORMAT)}`;
  }
  return {
    columns: [
      {
        label: 'cost',
        field: QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
      },
      {
        label: 'resourceName',
        field: QUERY_FIELDS.LINE_ITEM_USAGE_TYPE,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['resourceName'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: fromDate,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: toDate,
          },
          {
            field: QUERY_FIELDS.BILL_BILL_TYPE,
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: QUERY_FIELDS.PRODUCT_SERVICENAME,
            comparator: COMPARATORS.EQUALS,
            value: selectedTrendService,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

export const getAzureCostByResourceRequestBody = (
  selectedTrendMonth: string,
  selectedTrendService: string
) => ({
  columns: [
    {
      label: 'cost',
      field: QUERY_FIELDS.COST_BILLING_CURRENCY,
    },
    {
      label: 'resourceName',
      field: QUERY_FIELDS.RESOURCE_NAME,
    },
  ],
  aggregators: [
    {
      label: 'cost',
      function: AGGREGATORS.SUM,
    },
  ],
  groupBy: ['resourceName'],
  orderBy: [
    {
      label: 'cost',
      sort: ORDER_BY.DESCENDING,
    },
  ],
  filterGroups: [
    {
      filters: [
        {
          field: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(selectedTrendMonth, MONTH_YEAR_FORMAT).format(
            HYPHEN_DATE_FORMAT
          ),
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.BILLING_PERIOD_END_DATE,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(selectedTrendMonth, MONTH_YEAR_FORMAT)
            .add(1, 'month')
            .format(HYPHEN_DATE_FORMAT),
        },
        {
          field: QUERY_FIELDS.METER_CATEGORY,
          comparator: COMPARATORS.EQUALS,
          value: selectedTrendService,
        },
      ],
    },
  ],
  dashBoardType: DASHBOARD_TYPES.BILLING,
  cached: true,
});
