import { useSelector } from 'react-redux';
import { selectDashboard } from 'redux/dashboardSlice';
import { PROVIDER } from 'constants/cloudProviders';
import AWSCreditSummaryDashboard from './components/AWSCreditSummaryDashboard';
import GCPCreditSummaryDashboard from './components/GCPCreditSummaryDashboard';

const CreditSummaryDashboard = () => {
  const { selectedDashboard } = useSelector(selectDashboard);

  /**
   * @function getCreditSummaryDashboard
   * @description Function to get the credit summary dashboard component based on the provider
   * @return Function will return corresponding credit summary dashboard for selected provider
   */
  const getCreditSummaryDashboard = () => {
    switch (selectedDashboard?.connectorProvider) {
      case PROVIDER.GCP:
        return <GCPCreditSummaryDashboard />;

      case PROVIDER.AWS:
        return <AWSCreditSummaryDashboard />;

      case PROVIDER.AZURE:
        return <>Azure Credit Summary Dashboard</>;
      case PROVIDER.OCI:
        return <>OCI Credit Summary Dashboard</>;

      default:
        return <GCPCreditSummaryDashboard />;
    }
  };

  return getCreditSummaryDashboard();
};

export default CreditSummaryDashboard;
