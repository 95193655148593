import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { providerList, setSelectedProvider } from 'redux/providerSlice';
import { LeftBackArrowIcon } from 'assets/icons';
import Button from 'components/Button';
import ProviderListCard from 'components/ProviderListCard';
import AccessibleDiv from 'components/AccessibleDiv';
import { CONNECTION_PROVIDER_LIST_DATA } from 'constants/providerConnectionData';
import { getProviderLogo } from 'utils/providerDetails';
import { cloudConnection, setResetStepForm } from 'redux/cloudConnectionSlice';

import './index.scss';

type ProviderListType = {
  handleContinueButtonClick?: () => void;
  handleBackArrowClick?: () => void;
  heading?: string;
  subHeading?: string;
  listData?: any[];
  disabledList?: string[];
  onClickProvider?: (selectedCloudProvider: string) => void;
};
const ProviderList = ({
  handleContinueButtonClick,
  handleBackArrowClick,
  heading,
  subHeading,
  listData = CONNECTION_PROVIDER_LIST_DATA,
  disabledList,
  onClickProvider,
}: ProviderListType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedProvider } = useSelector(providerList);
  const { resetStepForm, currentStep } = useSelector(cloudConnection);

  useEffect(() => {
    dispatch(setSelectedProvider(''));
  }, []);

  useEffect(() => {
    if (resetStepForm && currentStep === 0) {
      dispatch(setSelectedProvider(''));
      dispatch(setResetStepForm(false));
    }
  }, [resetStepForm]);

  return (
    <div className="provider-list" data-testid="provider-list">
      {handleBackArrowClick && (
        <div className="flex flex-align-items-center">
          <AccessibleDiv
            className="back-arrow flex cursor-pointer"
            onClick={() => handleBackArrowClick()}
          >
            <LeftBackArrowIcon />
          </AccessibleDiv>
        </div>
      )}
      <div className="provider-list-body flex flex-center flex-gap-24 flex-column full-height">
        <div className="header-section full-width flex flex-column flex-center flex-gap-4">
          <div className="modal-heading">
            {heading ?? t('providerPageList.header')}
          </div>
          <div className="font-caption sub-header">
            {subHeading ?? t('providerPageList.subHeader')}
          </div>
        </div>
        <div className="flex-gap-16 flex flex-column">
          {listData.map((providerData) => (
            <div
              className={`${
                disabledList?.includes(providerData.provider)
                  ? 'cursor-disabled'
                  : ''
              }`}
              key={providerData.provider}
            >
              <ProviderListCard
                iconSVG={getProviderLogo(providerData.provider)}
                selectedProvider={selectedProvider}
                provider={providerData.provider}
                header={t(`providerPageList.${providerData.header}`)}
                subHeader={t(`providerPageList.${providerData.subHeader}`)}
                onClickProvider={(selectedCloudProvider: string) => {
                  onClickProvider?.(selectedCloudProvider);
                  dispatch(setSelectedProvider(selectedCloudProvider));
                }}
                disabled={disabledList?.includes(providerData.provider)}
              />
            </div>
          ))}
        </div>
        {handleContinueButtonClick && (
          <Button
            onClick={handleContinueButtonClick}
            title={t('continueButton')}
            disabled={!selectedProvider}
          />
        )}
      </div>
    </div>
  );
};

export default ProviderList;
