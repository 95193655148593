import { Avatar } from 'antd';
import { ProfileIcon } from 'assets/icons';
import { useEffect, useState } from 'react';
import { UserInfoType } from 'types/userManagementConsole';
import { onApiCallError } from 'utils/handleErrors';
import { getOtherUsersProfilePhoto } from 'utils/services';

type UserDataProps = {
  user: UserInfoType;
};

const UserData = ({ user }: UserDataProps) => {
  const [profilePic, setProfilePic] = useState(ProfileIcon);

  useEffect(() => {
    fetchProfileImage();
  }, []);

  /**
   * @function fetchProfileImage
   * @description function to fetch the profile image
   */
  const fetchProfileImage = async () => {
    getOtherUsersProfilePhoto(user.email)
      .then(async (res: any) => {
        if (res?.status === 200) {
          const imageBlob = await res.blob();
          const imageObjectURL = URL.createObjectURL(imageBlob);
          setProfilePic(imageObjectURL);
        }
      })
      .catch((e) => {
        onApiCallError(e, false);
      });
  };

  return (
    <div className="user-data flex flex-align-items-center flex-gap-4">
      <Avatar src={profilePic} />
      {user.name}
    </div>
  );
};

export default UserData;
