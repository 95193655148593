import i18n from 'i18n';
import {
  AWS_CHECK_IDS,
  RECOMMENDATION_CATEGORIES,
} from 'constants/recommendations';
import { QUERY_FIELDS } from 'constants/requestBody';

export const AWSResourceDetailsKeys = {
  ACCOUNT_ID: 'accountId',
  INSTANCE_TYPE: 'instanceType',
  REGION: 'region',
  AVERAGE_CPU_UTILIZATION: 'avgCpuUtilization',
  AVERAGE_NETWORK_IO: 'avgNetworkIO',
  NUMBER_OF_DAYS_LOW_UTILIZATION: 'numberOfDaysLowUtilization',
  IP_ADDRESS: 'ipAddress',
  CLUSTER: 'cluster',
  REASON: 'reason',
  DB_INSTANCE_NAME: 'dbInstanceName',
  STORAGE_PROVISIONED: 'storageProvisioned',
  DAYS_SINCE_LAST_CONNECTION: 'daysSinceLastConnection',
  LOAD_BALANCER_NAME: 'loadBalancerName',
  VOLUME_ID: 'volumeId',
  VOLUME_NAME: 'volumeName',
  VOLUME_SIZE: 'volumeSize',
  VOLUME_TYPE: 'volumeType',
  ZONE: 'zone',
  INSTANCE_COUNT: 'instanceCount',
  RESERVED_INSTANCE_ID: 'reservedInstanceId',
  EXPIRATION_DATE: 'expirationDate',
  CURRENT_MONTHLY_STORAGE_COST: 'currentMonthlyStorageCost',
  ESTIMATED_MONTHLY_SAVINGS: 'estimatedMonthlySavings',
  HOSTED_ZONE_NAME: 'hostedZoneName',
  HOSTED_ZONE_ID: 'hostedZoneId',
  RESOURCE_NAME: 'resourceName',
  RESOURCE_RECORD_SET_TYPE: 'resourceRecordSetType',
  INSTANCE_NAME: 'instanceName',
  RECOMMENDED_INSTANCE_TYPE: 'recommendedInstanceType',
};

// TODO: This mapping must be updated when the recommendation data is available for each checkId
export const CHECK_ID_TO_KEY_MAPPING = [
  {
    checkId: AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES,
    uniqueKey: QUERY_FIELDS.INSTANCE_ID,
    category: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.lowUtilizationAmazonEC2Instances'
    ),
    resourceLabel: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.instanceId'
    ),
    resourceDetailsQueryColumns: [
      {
        label: AWSResourceDetailsKeys.ACCOUNT_ID,
        field: QUERY_FIELDS.ACCOUNTID,
      },
      {
        label: AWSResourceDetailsKeys.INSTANCE_TYPE,
        field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
      },
      {
        label: AWSResourceDetailsKeys.REGION,
        field: QUERY_FIELDS.REGION,
      },
      {
        label: AWSResourceDetailsKeys.AVERAGE_CPU_UTILIZATION,
        field: `"${QUERY_FIELDS.FOURTEEN_DAYS_AVERAGE_CPU_UTILIZATION}"`,
      },
      {
        label: AWSResourceDetailsKeys.AVERAGE_NETWORK_IO,
        field: `"${QUERY_FIELDS.FOURTEEN_DAYS_AVERAGE_NETWORK_IO}"`,
      },
      {
        label: AWSResourceDetailsKeys.NUMBER_OF_DAYS_LOW_UTILIZATION,
        field: `"${QUERY_FIELDS.NUMBER_OF_DAYS_LOW_UTILIZATION}"`,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.IDLE_IP_ADDRESS,
    uniqueKey: QUERY_FIELDS.IP_ADDRESS,
    category: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
    costField: QUERY_FIELDS.COUNT_ALLX3_6,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.unassociatedElasticIPAddresses'
    ),
    resourceLabel: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.ipAddress'
    ),
    resourceDetailsQueryColumns: [
      {
        label: AWSResourceDetailsKeys.ACCOUNT_ID,
        field: QUERY_FIELDS.ACCOUNTID,
      },
      {
        label: AWSResourceDetailsKeys.REGION,
        field: QUERY_FIELDS.REGION,
      },
      {
        label: AWSResourceDetailsKeys.IP_ADDRESS,
        field: `"${QUERY_FIELDS.IP_ADDRESS}"`,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS,
    uniqueKey: QUERY_FIELDS.CLUSTER,
    category: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.underutilizedAmazonRedshiftClusters'
    ),
    resourceLabel: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.cluster'
    ),
    resourceDetailsQueryColumns: [
      {
        label: AWSResourceDetailsKeys.ACCOUNT_ID,
        field: QUERY_FIELDS.ACCOUNTID,
      },
      {
        label: AWSResourceDetailsKeys.REGION,
        field: QUERY_FIELDS.REGION,
      },
      {
        label: AWSResourceDetailsKeys.CLUSTER,
        field: QUERY_FIELDS.CLUSTER,
      },
      {
        label: AWSResourceDetailsKeys.INSTANCE_TYPE,
        field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
      },
      {
        label: AWSResourceDetailsKeys.REASON,
        field: QUERY_FIELDS.REASON,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE,
    uniqueKey: QUERY_FIELDS.DB_INSTANCE_NAME,
    category: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.amazonRDSIdleDBInstances'
    ),
    resourceLabel: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.dbInstanceName'
    ),
    resourceDetailsQueryColumns: [
      {
        label: AWSResourceDetailsKeys.ACCOUNT_ID,
        field: QUERY_FIELDS.ACCOUNTID,
      },
      {
        label: AWSResourceDetailsKeys.REGION,
        field: QUERY_FIELDS.REGION,
      },
      {
        label: AWSResourceDetailsKeys.DB_INSTANCE_NAME,
        field: `"${QUERY_FIELDS.DB_INSTANCE_NAME}"`,
      },
      {
        label: AWSResourceDetailsKeys.INSTANCE_TYPE,
        field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
      },
      {
        label: AWSResourceDetailsKeys.STORAGE_PROVISIONED,
        field: `"${QUERY_FIELDS.STORAGE_PROVISIONED}"`,
      },
      {
        label: AWSResourceDetailsKeys.DAYS_SINCE_LAST_CONNECTION,
        field: `"${QUERY_FIELDS.DAYS_SINCE_LAST_CONNECTION}"`,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.IDLE_LOAD_BALANCER,
    uniqueKey: QUERY_FIELDS.LOAD_BALANCER_NAME,
    category: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.idleLoadBalancers'
    ),
    resourceLabel: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.loadBalancerName'
    ),
    resourceDetailsQueryColumns: [
      {
        label: AWSResourceDetailsKeys.ACCOUNT_ID,
        field: QUERY_FIELDS.ACCOUNTID,
      },
      {
        label: AWSResourceDetailsKeys.REGION,
        field: QUERY_FIELDS.REGION,
      },
      {
        label: AWSResourceDetailsKeys.LOAD_BALANCER_NAME,
        field: `"${QUERY_FIELDS.LOAD_BALANCER_NAME}"`,
      },
      {
        label: AWSResourceDetailsKeys.REASON,
        field: QUERY_FIELDS.REASON,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES,
    uniqueKey: QUERY_FIELDS.VOLUME_ID,
    costField: QUERY_FIELDS.REPLACE_MONTHLY_STORAGE_COST_DOLLAR_BY_EMPTY,
    category: RECOMMENDATION_CATEGORIES.WASTE_MANAGEMENT,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.underutilizedAmazonEBSVolumes'
    ),
    resourceLabel: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.volumeId'
    ),
    resourceDetailsQueryColumns: [
      {
        label: AWSResourceDetailsKeys.ACCOUNT_ID,
        field: QUERY_FIELDS.ACCOUNTID,
      },
      {
        label: AWSResourceDetailsKeys.REGION,
        field: QUERY_FIELDS.REGION,
      },
      {
        label: AWSResourceDetailsKeys.VOLUME_ID,
        field: `"${QUERY_FIELDS.VOLUME_ID}"`,
      },
      {
        label: AWSResourceDetailsKeys.VOLUME_NAME,
        field: `"${QUERY_FIELDS.VOLUME_NAME}"`,
      },
      {
        label: AWSResourceDetailsKeys.VOLUME_SIZE,
        field: `"${QUERY_FIELDS.VOLUME_SIZE}"`,
      },
      {
        label: AWSResourceDetailsKeys.VOLUME_TYPE,
        field: `"${QUERY_FIELDS.VOLUME_TYPE}"`,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE,
    uniqueKey: QUERY_FIELDS.RESERVED_INSTANCE_ID,
    category: RECOMMENDATION_CATEGORIES.PURCHASE_TACTICS,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.amazonEC2ReservedInstanceLeaseExpiration'
    ),
    resourceLabel: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.reservedInstanceId'
    ),
    resourceDetailsQueryColumns: [
      {
        label: AWSResourceDetailsKeys.ACCOUNT_ID,
        field: QUERY_FIELDS.ACCOUNTID,
      },
      {
        label: AWSResourceDetailsKeys.ZONE,
        field: QUERY_FIELDS.ZONE,
      },
      {
        label: AWSResourceDetailsKeys.INSTANCE_TYPE,
        field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
      },
      {
        label: AWSResourceDetailsKeys.INSTANCE_COUNT,
        field: `"${QUERY_FIELDS.INSTANCE_COUNT}"`,
      },
      {
        label: AWSResourceDetailsKeys.RESERVED_INSTANCE_ID,
        field: `"${QUERY_FIELDS.RESERVED_INSTANCE_ID}"`,
      },
      {
        label: AWSResourceDetailsKeys.REASON,
        field: `"${QUERY_FIELDS.REASON}"`,
      },
      {
        label: AWSResourceDetailsKeys.EXPIRATION_DATE,
        field: `"${QUERY_FIELDS.EXPIRATION_DATE}"`,
      },
      {
        label: AWSResourceDetailsKeys.ESTIMATED_MONTHLY_SAVINGS,
        field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.ROUTE_53_LATENCY_RECORD_SETS,
    uniqueKey: QUERY_FIELDS.RESOURCE_RECORD_SET_NAME,
    category: RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.amazonRoute53LatencyResourceRecordSets'
    ),
    resourceLabel: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.resourceRecordSetName'
    ),
    resourceDetailsQueryColumns: [
      {
        label: AWSResourceDetailsKeys.ACCOUNT_ID,
        field: QUERY_FIELDS.ACCOUNTID,
      },
      {
        label: AWSResourceDetailsKeys.HOSTED_ZONE_NAME,
        field: `"${QUERY_FIELDS.HOSTED_ZONE_NAME}"`,
      },
      {
        label: AWSResourceDetailsKeys.HOSTED_ZONE_ID,
        field: `"${QUERY_FIELDS.HOSTED_ZONE_ID}"`,
      },
      {
        label: AWSResourceDetailsKeys.RESOURCE_NAME,
        field: `"${QUERY_FIELDS.RESOURCE_RECORD_SET_NAME}"`,
      },
      {
        label: AWSResourceDetailsKeys.RESOURCE_RECORD_SET_TYPE,
        field: `"${QUERY_FIELDS.RESOURCE_RECORD_SET_TYPE}"`,
      },
    ],
  },
  {
    checkId: AWS_CHECK_IDS.RIGHT_SIZING_EC2,
    uniqueKey: QUERY_FIELDS.INSTANCE_ID_NO_SPACE,
    category: RECOMMENDATION_CATEGORIES.CONSUMPTION_MANAGEMENT,
    label: i18n.t(
      'costOptimizationInsight.statusDashboard.recommendationTypeLabels.rightSizingEc2'
    ),
    resourceLabel: i18n.t(
      'costOptimizationInsight.statusDashboard.costSavingsTable.resourceRecordSetName'
    ),
    resourceDetailsQueryColumns: [
      {
        label: AWSResourceDetailsKeys.ACCOUNT_ID,
        field: QUERY_FIELDS.ACCOUNTID,
      },
      {
        label: AWSResourceDetailsKeys.INSTANCE_NAME,
        field: `"${QUERY_FIELDS.INSTANCE_NAME_NO_SPACE}"`,
      },
      {
        label: AWSResourceDetailsKeys.INSTANCE_TYPE,
        field: `"${QUERY_FIELDS.INSTANCE_TYPE_NO_SPACE}"`,
      },
      {
        label: AWSResourceDetailsKeys.RECOMMENDED_INSTANCE_TYPE,
        field: `"${QUERY_FIELDS.RECOMMENDED_INSTANCE_TYPE_NO_SPACE}"`,
      },
    ],
    costField: `"${QUERY_FIELDS.ESTIMATED_SAVINGS}"`,
  },
];
