import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';

import {
  DatasetsType,
  SnowflakeTableViewType,
} from 'components/CustomViewDataSourceDrawer/types';
import DatasourceDropdown from 'components/DatasourceDropdown';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import Switch from 'components/Switch';
import { fetchSnowflakeTables } from 'components/CustomViewDataSourceDrawer/services';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import { SnowflakeDatasourceType } from 'components/CustomViewDataSourceDrawer/constants';
import {
  CUSTOM_DASHBOARD_TYPES,
  DATASET_TYPES,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import { DASHBOARD_TYPES } from 'constants/requestBody';
import { fetchGroups } from 'pages/GroupsPage/services';
import { GroupType } from 'pages/GroupsPage/types';
import { SnowflakeIntegrationType } from 'pages/IntegrationsPage/types';
import {
  ConnectionListType,
  CustomDashboardDataSourceType,
} from 'types/dashboard';
import { ImportFilesListType, ImportFileStatus } from 'types/importFiles';
import { PROVIDER } from 'constants/cloudProviders';
import {
  fetchConnectionById,
  fetchConnectionData,
  fetchGroupDashboardMetaData,
  fetchImportFilesData,
  fetchSnowflakeIntegrations,
} from 'utils/services';
import { validateEmptyField } from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';

import './index.scss';

type DataSourceComponentProps = {
  showConnections: boolean;
  dashboardType: string;
  connection: any;
  integrationConnection: SnowflakeIntegrationType | null;
  group: GroupType | null;
  datasource: CustomDashboardDataSourceType;
  onSelectDataSource: (val: any, typeOfDashboard: string) => void;
  onSelectDatasetOrTypeOfDataset: (val: string, datasetType: string) => void;
  onChangeCustomQuerySwitch: (val: boolean) => void;
  onChangeCustomQuery: (val: any) => void;
  validationMessage: string;
  allowedConnectionTypes?: string[];
  dataSourceDropdownClassName?: string;
};

const DataSourceComponent = ({
  showConnections,
  dashboardType,
  connection,
  integrationConnection,
  group,
  datasource,
  onSelectDataSource,
  onSelectDatasetOrTypeOfDataset,
  onChangeCustomQuerySwitch,
  onChangeCustomQuery,
  validationMessage,
  allowedConnectionTypes = [
    MY_DASHBOARD_TYPES.SINGLE_CONNECTION,
    MY_DASHBOARD_TYPES.GROUP,
    MY_DASHBOARD_TYPES.IMPORTS,
    MY_DASHBOARD_TYPES.SNOWFLAKE,
  ],
  dataSourceDropdownClassName,
}: DataSourceComponentProps) => {
  const { t } = useTranslation();

  const [datasets, setDatasets] = useState<DatasetsType[]>();
  const [snowflakeTableViewList, setSnowflakeTableViewList] =
    useState<SnowflakeTableViewType[]>();
  const [snowflakeTableViewSearchKey, setSnowflakeTableViewSearchKey] =
    useState('');
  const [connections, setConnections] = useState<ConnectionListType[]>([]);
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [importConnections, setImportConnections] = useState<
    ImportFilesListType[]
  >([]);
  const [integrations, setIntegrations] = useState<SnowflakeIntegrationType[]>(
    []
  );
  const [selectedDatasource, setSelectedDatasource] = useState<string>();
  const [dataSetReqValidation, setDataSetReqValidation] = useState<string>();
  const [customQueryReqValidation, setCustomQueryReqValidation] =
    useState<string>();
  const [connectionsLoading, setConnectionsLoading] = useState(false);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [integrationsLoading, setIntegrationsLoading] = useState(false);
  const [datasetsLoading, setDatasetsLoading] = useState(false);
  const [snowflakeDatasourceType, setSnowflakeDatasourceType] = useState('');

  useEffect(() => {
    if (!showConnections) {
      return;
    }

    if (allowedConnectionTypes.includes(MY_DASHBOARD_TYPES.SINGLE_CONNECTION)) {
      fetchAllConnections();
    }

    if (allowedConnectionTypes.includes(MY_DASHBOARD_TYPES.GROUP)) {
      fetchAllGroups();
    }

    if (allowedConnectionTypes.includes(MY_DASHBOARD_TYPES.IMPORTS)) {
      fetchAllImports();
    }

    if (allowedConnectionTypes.includes(MY_DASHBOARD_TYPES.SNOWFLAKE)) {
      fetchAllIntegrations();
    }
  }, []);

  useEffect(() => {
    setConnectionAndImportDatasets(connection);
  }, [connection]);
  useEffect(() => {
    integrationConnection && fetchSnowflakeTableViews(integrationConnection);
  }, [integrationConnection]);

  /**
   * @function setConnectionAndImportDatasets
   * @description Function to set the datasets for connections and imports
   */
  const setConnectionAndImportDatasets = (
    selectedConnection: ConnectionListType | null | undefined
  ) => {
    if (selectedConnection) {
      const allDatasets = [
        {
          type: CUSTOM_DASHBOARD_TYPES.BILLING_DEFAULT,
          dataset: selectedConnection?.billingDataset,
        },
        {
          type: CUSTOM_DASHBOARD_TYPES.RECOMMENDATIONS_DEFAULT,
          dataset: selectedConnection?.recommendationDataset,
        },
        {
          type: CUSTOM_DASHBOARD_TYPES.CARBON_FOOTPRINT_DEFAULT,
          dataset: selectedConnection?.carbonFootprintDataset,
        },
      ];
      setDatasets(
        allDatasets?.filter(
          (item) =>
            item.dataset !== undefined &&
            item.dataset !== null &&
            item.dataset.length > 0
        )
      );
    } else {
      setDatasets([]);
    }
  };

  /**
   * @function fetchAllConnections
   * @description Function to get all single connections
   */
  const fetchAllConnections = () => {
    setConnectionsLoading(true);
    fetchConnectionData()
      .then((res: any) => {
        let connectionList = res?.data?.responseData?.content;
        connectionList = connectionList?.filter(
          (connection: ConnectionListType) => !connection.migrated
        );
        setConnections(connectionList);
        if (
          connection &&
          dashboardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION
        ) {
          setSelectedDatasource(connection.connectorId);
        }
        setConnectionsLoading(false);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setConnections([]);
        setConnectionsLoading(false);
      });
  };

  /**
   * @function fetchAllGroups
   * @description Function to get all groups
   */
  const fetchAllGroups = () => {
    setGroupsLoading(true);
    fetchGroups()
      .then((res: any) => {
        setGroups(res?.data?.responseData?.content ?? []);
        if (group && dashboardType === MY_DASHBOARD_TYPES.GROUP) {
          setSelectedDatasource(group.name);
        }
        setGroupsLoading(false);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setGroups([]);
        setGroupsLoading(false);
      });
  };

  /**
   * @function fetchAllImports
   * @description Function to get all connection list and set the connection dropdown
   */
  const fetchAllImports = async () => {
    setTransactionsLoading(true);
    fetchImportFilesData()
      .then((res: any) => {
        let transactions = res.data.responseData.content;
        transactions = transactions?.filter(
          (transaction: ImportFilesListType) =>
            transaction.fileImportStatus === ImportFileStatus.READY
        );
        setImportConnections(transactions);
        if (connection && dashboardType === MY_DASHBOARD_TYPES.IMPORTS) {
          setSelectedDatasource(connection.transactionName);
        }
        setTransactionsLoading(false);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setImportConnections([]);
        setTransactionsLoading(false);
      });
  };

  /**
   * @function fetchAllIntegrations
   * @description Function to get all integrations eg. snowflake integrations
   */
  const fetchAllIntegrations = () => {
    setIntegrationsLoading(true);
    const params = {
      key: '',
      page: -1,
      size: -1,
    };
    fetchSnowflakeIntegrations(params)
      .then((res: any) => {
        let integrationList = res?.data?.responseData?.content;
        setIntegrations(integrationList);
        setIntegrationsLoading(false);
      })
      .catch((e) => {
        onApiCallError(e, false);
        setIntegrations([]);
        setIntegrationsLoading(false);
      });
  };

  /**
   * @function fetchConnectionDataById
   * @description Function to fetch the connection details by id for connection and import transactions
   * @param connectorId Id of the connector for which the details are fetched.
   * @param typeOfDashboard dashboard type selected
   */
  const fetchConnectionDataById = (
    connectorId: string,
    typeOfDashboard: string
  ) => {
    setDatasetsLoading(true);

    fetchConnectionById(connectorId)
      .then((res: any) => {
        const connectionData = res?.data?.responseData;
        onSelectDataSource(connectionData, typeOfDashboard);
        setConnectionAndImportDatasets(connectionData);
        setDatasetsLoading(false);
      })
      .catch((e) => {
        onApiCallError(e, false);
        onSelectDataSource(null, typeOfDashboard);
        setDatasetsLoading(false);
      });
  };

  /**
   * @function fetchGroupDetailsByName
   * @description Function to fetch the group details by group name
   * @param groupName group name of the group for which the details are fetched.
   * @param typeOfDashboard dashboard type selected
   */
  const fetchGroupDetailsByName = (
    groupName: string | undefined,
    typeOfDashboard: string
  ) => {
    setDatasetsLoading(true);

    fetchGroupDashboardMetaData(groupName ?? '')
      .then((res: any) => {
        onSelectDataSource(res?.data?.responseData, typeOfDashboard);
        setDatasetsLoading(false);
      })
      .catch((e) => {
        onApiCallError(e, false);
        onSelectDataSource(null, typeOfDashboard);
        setDatasetsLoading(false);
      });
  };

  /**
   * @function fetchSnowflakeTableViews
   * @description Function to fetch the snowflake tables and views
   * @param integrationConnection integration connection
   */
  const fetchSnowflakeTableViews = (
    integrationConnection: SnowflakeIntegrationType
  ) => {
    setDatasetsLoading(true);
    fetchSnowflakeTables({
      integrationId: integrationConnection.integrationId,
      serverURL: integrationConnection.serverURL,
      database: integrationConnection.database,
      schema: integrationConnection.schema,
    })
      .then((res: any) => {
        const data = res?.data?.responseData;
        setSnowflakeTableViewList(
          Object.entries(data).map((obj: any) => ({
            type: obj[1],
            name: obj[0],
          }))
        );
        setSnowflakeDatasourceType(Object.values(data).at(0) as string);
        setDatasetsLoading(false);
      })
      .catch((e) => {
        onApiCallError(e, false);
        onSelectDataSource(null, MY_DASHBOARD_TYPES.SNOWFLAKE);
        setDatasetsLoading(false);
      });
  };

  /**
   * @function onSelectConnectionOrGroupOrTransaction
   * @description Callback function for datasource selection
   * @param value value selected, connector Id or group name or transaction name
   * @param typeOfDashboard dashboard type selected
   */
  const onSelectConnectionOrGroupOrTransaction = (
    value: string,
    typeOfDashboard: string
  ) => {
    setSelectedDatasource(value);
    setDatasets([]);
    setDataSetReqValidation('');

    switch (typeOfDashboard) {
      case MY_DASHBOARD_TYPES.SINGLE_CONNECTION: {
        const connectionData = connections.find(
          (connection) => connection.connectorId === value
        );
        onSelectDataSource(connectionData, typeOfDashboard);
        setConnectionAndImportDatasets(connectionData);
        break;
      }

      case MY_DASHBOARD_TYPES.GROUP:
        fetchGroupDetailsByName(value, typeOfDashboard);
        break;

      case MY_DASHBOARD_TYPES.IMPORTS:
        fetchConnectionDataById('STATIC_IMPORT.' + value, typeOfDashboard);
        break;

      case MY_DASHBOARD_TYPES.SNOWFLAKE: {
        const integrationData = integrations.find(
          (integration) => integration.integrationId === value
        )!;
        onSelectDataSource(integrationData, typeOfDashboard);
        fetchSnowflakeTableViews(integrationData);
        break;
      }
    }
  };

  /**
   * @function validateDatasetOrTypeOfDataset
   * @description Function to validate the dataset field
   * @param typeOfDataset type of dataset selected
   */
  const validateDatasetOrTypeOfDataset = (typeOfDataset: string) => {
    if (dashboardType !== MY_DASHBOARD_TYPES.GROUP) {
      setDataSetReqValidation('');
      return;
    }

    validateGroupConnectionsDatasetTypes(typeOfDataset);
  };

  /**
   * @function validateDatasetOrTypeOfDatasetField
   * @description Function to validate the dataset for connection or type of dataset field for group
   */
  const validateDatasetOrTypeOfDatasetField = () => {
    if (dashboardType === MY_DASHBOARD_TYPES.GROUP) {
      validateGroupConnectionsDatasetTypes(datasource.dashBoardType);
      return;
    }

    if (datasource.dataSet && datasource.dataSet.length > 0) {
      setDataSetReqValidation('');
    } else {
      setDataSetReqValidation(
        t('dashboardLabels.connectToDataSourceModalLabels.dataSetRequired')
      );
    }
  };

  /**
   * @function validateGroupConnectionsDatasetTypes
   * @description Function to validate the dataset type for the group selected and set the error message
   * @param typeOfDataset dataset type
   * @returns boolean true if the validation is successful else false
   */
  const validateGroupConnectionsDatasetTypes = (typeOfDataset: string) => {
    // Validate if all the connections in the group has the type of dataset selected
    if (!group || group.connectorDtos.length === 0) {
      setDataSetReqValidation(
        t('dashboardLabels.connectToDataSourceModalLabels.noGroupData')
      );
      return false;
    }

    if (!typeOfDataset) {
      setDataSetReqValidation(
        t('dashboardLabels.connectToDataSourceModalLabels.dataSetRequired')
      );
      return false;
    }

    let hasDataset = false;
    switch (typeOfDataset) {
      case DASHBOARD_TYPES.BILLING:
        hasDataset = group.connectorDtos.every(
          (connection) => connection.wantBilling
        );
        break;
      case DASHBOARD_TYPES.RECOMMENDATIONS:
        hasDataset = group.connectorDtos.every(
          (connection) => connection.wantRecommendations
        );
        break;
      case DASHBOARD_TYPES.CARBON_FOOTPRINT:
        hasDataset = group.connectorDtos.every(
          (connection) => connection.wantCarbonFootprint
        );
        break;
    }

    if (hasDataset) {
      setDataSetReqValidation('');
    } else {
      setDataSetReqValidation(
        t('dashboardLabels.connectToDataSourceModalLabels.datasetNotPresent', {
          typeOfDataset: DATASET_TYPES.find(
            (item) => item.value === typeOfDataset
          )?.label,
        })
      );
    }

    return hasDataset;
  };

  const getSnowflakeDatasourceDropdown = (menu: JSX.Element) => (
    <div className="flex flex-column flex-gap-8">
      <HorizontalNavigationMenu
        menuItems={Object.values(SnowflakeDatasourceType).map((type) => (
          <Menu.Item
            key={type}
            eventKey={type}
            className={`font-caption-bold flex-fit ${
              type === snowflakeDatasourceType && 'active-menu'
            }`}
            onClick={() => setSnowflakeDatasourceType(type)}
          >
            {type}
          </Menu.Item>
        ))}
        selectedKeys={[snowflakeDatasourceType]}
      />
      <Input
        placeholder={t(
          'dashboardLabels.connectToDataSourceModalLabels.searchSnowflakeTableOrView'
        )}
        value={snowflakeTableViewSearchKey}
        onChange={(e: any) => setSnowflakeTableViewSearchKey(e.target.value)}
      />
      {menu}
    </div>
  );

  const getDatasetDropdown = () =>
    dashboardType === MY_DASHBOARD_TYPES.GROUP ? (
      <>
        <FormLabel
          title={t(
            'dashboardLabels.connectToDataSourceModalLabels.selectTypeOfDataset'
          )}
          required
        />
        <SelectDropdown
          value={datasource.dashBoardType}
          options={DATASET_TYPES.filter(
            (item) => item.value === DASHBOARD_TYPES.BILLING
          )}
          placeholder={t(
            'dashboardLabels.connectToDataSourceModalLabels.selectDataset'
          )}
          showSearch
          onSelect={(value: string) => {
            onSelectDatasetOrTypeOfDataset(
              value,
              datasets?.find((item) => item.dataset === value)?.type ?? ''
            );
            validateDatasetOrTypeOfDataset(value);
          }}
          onBlur={validateDatasetOrTypeOfDatasetField}
          disabled={
            dashboardType !== MY_DASHBOARD_TYPES.GROUP &&
            connection?.provider !== PROVIDER.AZURE &&
            datasource.useCustomQuery
          }
          loading={datasetsLoading}
          designVersion2
        />
      </>
    ) : (
      <>
        <FormLabel
          title={t(
            'dashboardLabels.connectToDataSourceModalLabels.selectDataset'
          )}
          required
          disabled={
            connection?.provider !== PROVIDER.AZURE && datasource.useCustomQuery
          }
        />
        <SelectDropdown
          value={datasource?.dataSet}
          options={datasets?.map((option) => ({
            value: option.dataset,
            label: option.dataset,
          }))}
          placeholder={t(
            'dashboardLabels.connectToDataSourceModalLabels.selectDataset'
          )}
          showSearch
          onSelect={(value: string) => {
            onSelectDatasetOrTypeOfDataset(
              value,
              datasets?.find((item) => item.dataset === value)?.type ?? ''
            );
            validateDatasetOrTypeOfDataset(value);
          }}
          onBlur={validateDatasetOrTypeOfDatasetField}
          disabled={
            dashboardType !== MY_DASHBOARD_TYPES.GROUP &&
            connection?.provider !== PROVIDER.AZURE &&
            datasource.useCustomQuery
          }
          loading={datasetsLoading}
          designVersion2
        />
      </>
    );

  return (
    <div className="flex flex-column full-height flex-space-between">
      <div className="flex flex-column flex-gap-16">
        {showConnections ? (
          <div className="flex flex-column">
            <FormLabel
              title={t(
                'dashboardLabels.connectToDataSourceModalLabels.selectDataSource'
              )}
              required
            />
            <DatasourceDropdown
              connections={connections}
              groups={groups}
              importConnections={importConnections}
              integrations={integrations}
              selectedValue={selectedDatasource}
              onSelect={onSelectConnectionOrGroupOrTransaction}
              loading={
                connectionsLoading ||
                groupsLoading ||
                transactionsLoading ||
                integrationsLoading
              }
              dashboardType={dashboardType}
              allowedConnectionTypes={allowedConnectionTypes}
              popupClassName={dataSourceDropdownClassName}
            />
          </div>
        ) : null}
        {dashboardType === MY_DASHBOARD_TYPES.SNOWFLAKE ? (
          <div className="flex flex-column">
            <FormLabel
              title={t(
                'dashboardLabels.connectToDataSourceModalLabels.selectTableView'
              )}
              required
            />
            <SelectDropdown
              popupClassName="datasource-table-dropdown"
              value={datasource?.dataSet}
              options={snowflakeTableViewList
                ?.filter((option) => option.type === snowflakeDatasourceType)
                .filter((option) =>
                  option.name
                    .trim()
                    .toLowerCase()
                    .includes(snowflakeTableViewSearchKey.trim().toLowerCase())
                )
                ?.map((option) => ({ value: option.name, label: option.name }))}
              dropdownRender={getSnowflakeDatasourceDropdown}
              placeholder={t(
                'dashboardLabels.connectToDataSourceModalLabels.selectTableView'
              )}
              showSearch
              onSelect={(value: string) => {
                onSelectDatasetOrTypeOfDataset(
                  value,
                  datasets?.find((item) => item.dataset === value)?.type ?? ''
                );
                setDataSetReqValidation('');
              }}
              onBlur={() =>
                validateEmptyField(
                  datasource?.dataSet,
                  t(
                    'dashboardLabels.connectToDataSourceModalLabels.selectTableView'
                  ),
                  setDataSetReqValidation
                )
              }
              loading={datasetsLoading}
              designVersion2
            />
            <span
              style={{
                display: `${
                  (!datasource.useCustomQuery ||
                    connection?.provider === PROVIDER.AZURE) &&
                  dataSetReqValidation
                    ? 'inline'
                    : 'none'
                }`,
              }}
              className="font-validation-error"
            >
              {dataSetReqValidation}
            </span>
          </div>
        ) : (
          <div className="flex flex-column">
            {getDatasetDropdown()}
            <span
              style={{
                display: `${
                  (!datasource.useCustomQuery ||
                    connection?.provider === PROVIDER.AZURE) &&
                  dataSetReqValidation
                    ? 'inline'
                    : 'none'
                }`,
              }}
              className="font-validation-error"
            >
              {dataSetReqValidation}
            </span>
          </div>
        )}
        {dashboardType !== MY_DASHBOARD_TYPES.GROUP &&
          dashboardType !== MY_DASHBOARD_TYPES.SNOWFLAKE && (
            <>
              <div className="flex flex-align-items-center flex-space-between">
                <FormLabel
                  title={t(
                    'dashboardLabels.connectToDataSourceModalLabels.orUseCustomQuery'
                  )}
                />
                <Switch
                  checked={datasource.useCustomQuery}
                  onChange={onChangeCustomQuerySwitch}
                  size="small"
                />
              </div>
              <div className="flex flex-column">
                <FormLabel
                  title={t(
                    'dashboardLabels.connectToDataSourceModalLabels.enterCustomQuery'
                  )}
                  required
                  disabled={!datasource.useCustomQuery}
                />
                <Input
                  className="table-typography"
                  type="textarea"
                  value={datasource.customQuery}
                  autoSize={{ minRows: 5, maxRows: 10 }}
                  placeholder={t(
                    'dashboardLabels.connectToDataSourceModalLabels.enterCustomQuery'
                  )}
                  onChange={onChangeCustomQuery}
                  onBlur={() =>
                    datasource.customQuery && datasource.customQuery.length > 0
                      ? setCustomQueryReqValidation('')
                      : setCustomQueryReqValidation(
                          t(
                            'dashboardLabels.connectToDataSourceModalLabels.customQueryRequired'
                          )
                        )
                  }
                  disabled={!datasource.useCustomQuery}
                />
                <span
                  style={{
                    display: `${
                      datasource.useCustomQuery && customQueryReqValidation
                        ? 'inline'
                        : 'none'
                    }`,
                  }}
                  className="font-validation-error"
                >
                  {customQueryReqValidation}
                </span>
              </div>
            </>
          )}
      </div>
      {validationMessage && (
        <div className="error-message flex flex-justify-content-center font-caption">
          {validationMessage}
        </div>
      )}
    </div>
  );
};

export default DataSourceComponent;
