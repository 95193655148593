import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';

import { userAuthorization } from 'redux/authorizationSlice';
import Button from 'components/Button';
import DeleteModal from 'components/DeleteModal';
import SearchInput from 'components/Input';
import QuickActionMenu from 'components/QuickActionMenu';
import Table from 'components/Table';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import Pagination from 'components/Pagination';
import {
  RoleQuickActions,
  RoleStatus,
  ROLE_QUICKACTIONS,
} from 'constants/quickAction';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DATE_TIME_AM_PM, HYPHEN_DATE_TIME_HH_MM_SS_AM_PM } from 'utils/date';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { onApiCallError } from 'utils/handleErrors';

import {
  activateDeactivateCustomRole,
  deleteCustomRole,
  fetchAllRoles,
  getUseresForRole,
} from './services';
import { RolesType } from './constants';
import ViewPermissionsModal from './components/ViewPermissionsModal';
import AddCustomRoleModal from './components/AddCustomRoleModal';

import './index.scss';

const RolesPage = () => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  const [showAddCustomRoleModal, setShowAddCustomRoleModal] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rolesData, setRolesData] = useState<RolesType[]>();
  const [currentRoleForModal, setCurrentRoleForModal] =
    useState<RolesType | null>();
  const [currentPage, setCurrentPage] = useState(1);
  const [rolesDataRequestStatus, setRolesDataRequestStatus] = useState('');
  const [currentRoleForDelete, setCurrentRoleForDelete] = useState<RolesType>();
  const [deleteLoadingStatus, setDeleteLoadingStatus] = useState<string>('');
  const [totalRolesCount, setTotalRolesCount] = useState<number>(0);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    getAllRoles();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  const getAllRoles = (page: number = 1, key?: string) => {
    setRolesDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      page: page - 1,
      size: PAGINATION_SIZE,
      key: key,
    };
    fetchAllRoles(params)
      .then((res: any) => {
        setTotalRolesCount(res?.data?.responseData?.totalElements);
        setRolesData(res.data.responseData.content);
        setRolesDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRolesDataRequestStatus);
      });
  };

  const handleQuickAction = (role: any, action: string) => {
    switch (action) {
      case RoleQuickActions.ACTIVE:
        changeNewRoleStatus(role.roleId, RoleStatus.ACTIVE);
        break;
      case RoleQuickActions.DEACTIVATED:
        changeNewRoleStatus(role.roleId, RoleStatus.DEACTIVATED);
        break;
      case RoleQuickActions.EDIT:
        editCustomRole(role);
        break;
      case RoleQuickActions.DELETE:
        findUsersForRole(role);
        break;
    }
  };

  const editCustomRole = (role: any) => {
    setCurrentRoleForModal(role);
    setShowAddCustomRoleModal(true);
  };

  const changeNewRoleStatus = (roleId: number, status: string) => {
    const param = { status: status };
    activateDeactivateCustomRole(roleId, param)
      .then(() => {
        message.success(t('rolesTable.changeRoleResponse.roleChangeSuccess'));
        getAllRoles();
      })
      .catch((e: any) => {
        onApiCallError(
          e,
          true,
          undefined,
          t('rolesTable.changeRoleResponse.roleChangeFailed')
        );
      });
  };

  const findUsersForRole = (role: any) => {
    const param = { roleName: role.name };
    getUseresForRole(param)
      .then((res: any) => {
        if (res.data.responseData.length === 0) {
          setCurrentRoleForDelete(role);
          setShowDeleteModal(true);
        } else {
          message.error(t('rolesTable.deleteResponse.failedWithUsers'));
        }
      })
      .catch((e: any) => {
        onApiCallError(
          e,
          true,
          undefined,
          t('rolesTable.deleteResponse.faildedToDeleteRole')
        );
      });
  };

  const deleteRoleApiCall = (role: any) => {
    setDeleteLoadingStatus(REQUEST_STATUS.PROCESSING);
    const param = { roleId: role.roleId };
    deleteCustomRole(param)
      .then(() => {
        setDeleteLoadingStatus(REQUEST_STATUS.SUCCESS);
        message.success(t('rolesTable.deleteResponse.successful'));
        getAllRoles(1, searchKey);
        setCurrentPage(1);
        setShowDeleteModal(false);
      })
      .catch((e: any) => {
        onApiCallError(
          e,
          true,
          setDeleteLoadingStatus,
          t('rolesTable.deleteResponse.faildedToDeleteRole')
        );
        setShowDeleteModal(false);
      });
  };

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param currentPage:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    if (searchKey.length === 0) {
      getAllRoles(page);
    } else {
      getAllRoles(page, searchKey);
    }
  };

  /**
   * @function handleSerachRole
   * @description Function to handle role search
   * @param e :accepts search input event
   */
  const handleSearchRoles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchKey(e.target.value);
    getAllRoles(1, e.target.value);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
    },
    {
      title: t('rolesTable.roles'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('rolesTable.type'),
      dataIndex: 'roleType',
      key: 'roleType',
    },
    {
      title: () => t('rolesTable.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) =>
        text
          ? moment
              .utc(text, HYPHEN_DATE_TIME_HH_MM_SS_AM_PM)
              .local()
              .format(DATE_TIME_AM_PM)
          : '--',
    },
    {
      title: () => t('rolesTable.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        return (
          <span
            className={`font-button ${
              text === 'ACTIVE' ? 'active' : 'deactivated'
            }`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t('rolesTable.permissions'),
      dataIndex: 'permissions',
      key: 'permissions',
      render: (_text: string, record: any) => {
        return (
          <Icon
            iconName={ICONS.EYE_LINE}
            onClick={() => {
              setCurrentRoleForModal(record);
              setShowPermissionsModal(true);
            }}
          />
        );
      },
      width: 200,
      align: 'center' as const,
    },
    {
      title: t('rolesTable.quickAction'),
      dataIndex: 'quickAction',
      key: 'quickAction',
      render: (_text: string, record: any) => {
        return (
          <QuickActionMenu
            quickActions={ROLE_QUICKACTIONS.filter(
              (action: any) => action.id !== record.status
            )}
            disabled={
              record.roleType === 'SYSTEM' || !permissions.userManagementModify
            }
            quickActionHandler={(action: string) => {
              handleQuickAction(record, action);
            }}
          />
        );
      },
      width: 200,
      align: 'center' as const,
    },
  ];

  return (
    <div className="roles-page">
      <div className="roles-page-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="modal-heading">{t('navigationMenu.roles')}</div>
        {permissions.userManagementWrite && (
          <Button
            title={t('createCustomRole')}
            onClick={() => {
              setCurrentRoleForModal(null);
              setShowAddCustomRoleModal(true);
            }}
          />
        )}
      </div>
      <div className="roles-page-content page-content flex flex-column flex-fit">
        <SearchInput
          placeholder={t('searchRolePlaceholder')}
          type="search"
          onChange={debounce(handleSearchRoles, DEBOUNCE_TIME_DELAY)}
        />
        <Table
          pagination={false}
          rootClassName="table-section"
          loading={rolesDataRequestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={rolesData?.map((value, index) => {
            return { ...value, key: index + 1 };
          })}
          columns={columns}
        />
      </div>
      {totalRolesCount > 0 && (
        <Pagination
          rootClassName="flex flex-end"
          current={currentPage}
          onChange={onChangePagination}
          total={totalRolesCount}
          defaultPageSize={PAGINATION_SIZE}
        />
      )}
      {showAddCustomRoleModal && (
        <AddCustomRoleModal
          showAddCustomRoleModal={showAddCustomRoleModal}
          setShowAddCustomRoleModal={setShowAddCustomRoleModal}
          getAllRoles={getAllRoles}
          currentRoleForModal={currentRoleForModal}
        />
      )}
      {showPermissionsModal && currentRoleForModal && (
        <ViewPermissionsModal
          showPermissionsModal={showPermissionsModal}
          setShowPermissionsModal={setShowPermissionsModal}
          currentRoleForModal={currentRoleForModal}
        />
      )}
      <DeleteModal
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
        deletionFunction={() => {
          deleteRoleApiCall(currentRoleForDelete);
        }}
        loading={deleteLoadingStatus === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );
};
export default RolesPage;
