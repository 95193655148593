import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GranulateConnectionView,
  GranulateProviderView,
} from 'constants/granulate';
import { RootState } from './store';

export interface GranulateState {
  granulateConnectionView: string;
  currentStep: number;
  granulateProviderView: string;
  selectedProvider: string;
}

const initialState: GranulateState = {
  granulateConnectionView: GranulateConnectionView.TABLE_VIEW,
  currentStep: 0,
  granulateProviderView: GranulateProviderView.PROVIDER_LIST,
  selectedProvider: '',
};

export const granulateSlice = createSlice({
  name: 'granulate',
  initialState,
  reducers: {
    setGranulateConnectionView(state, action: PayloadAction<string>) {
      state.granulateConnectionView = action.payload;
    },
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setGranulateProviderView(state, action: PayloadAction<string>) {
      state.granulateProviderView = action.payload;
    },
    setSelectedProvider(state, action: PayloadAction<string>) {
      state.selectedProvider = action.payload;
    },
  },
});

export const selectGranulate = (state: RootState) => state.granulate;

export const {
  setGranulateConnectionView,
  setGranulateProviderView,
  setCurrentStep,
  setSelectedProvider,
} = granulateSlice.actions;

export default granulateSlice.reducer;
