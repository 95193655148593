import { useCallback } from 'react';
import { sumBy } from 'lodash';
import { useSelector } from 'react-redux';
import PieDonutChart from 'components/PieChartAnt';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { selectCommonUtility } from 'redux/commonUtilitySlice';

import { SpendProfileDataType } from '../../types';

type SpendProfileChartProps = {
  spendData: SpendProfileDataType;
};

const SpendProfileChart = ({ spendData }: SpendProfileChartProps) => {
  const { currencySymbol } = useSelector(selectCommonUtility);

  const tooltipCustomItems = useCallback(
    (originalItems: any[]) =>
      originalItems.map((item) => ({
        ...item,
        value: `${currencySymbol}
          ${numberCommaSeparator(item.data.cost)} (${numberCommaSeparator(
          (item.data.cost / sumBy(spendData.data, 'cost')) * 100
        )}%)`,
      })),
    []
  );

  return (
    <PieDonutChart
      additionalClassNames="service-spend-profile-chart"
      data={spendData.data.map((item) => ({
        ...item,
        cost: item.cost,
      }))}
      angleField="cost"
      colorField="businessUnit"
      disableAnimation={true}
      showLegends={false}
      radius={0.5}
      label={{
        callback: (...args: any[]) => ({
          content: `${args[1]} ${currencySymbol}${numberCommaSeparator(
            args[0]
          )}`,
        }),
      }}
      height={200}
      tooltipOverride={{
        customItems: tooltipCustomItems,
      }}
    />
  );
};

export default SpendProfileChart;
