import { Carousel } from 'antd';
import { CustomArrowProps } from 'react-slick';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';

import './index.scss';

type CarouselComponentProps = {
  children: any;
  isFullWidth?: boolean;
  className: string;
};

const SlickLeftArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => {
  return (
    <ArrowLeftSLineIcon
      {...props}
      aria-disabled={currentSlide === Number(slideCount) - 1}
    />
  );
};

const SlickRightArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => {
  return (
    <ArrowRightSLineIcon
      {...props}
      aria-disabled={currentSlide === Number(slideCount) - 1}
    />
  );
};

const CarouselComponent = ({
  children,
  isFullWidth = false,
  className,
}: CarouselComponentProps) => {
  return (
    <Carousel
      className={`${className} full-width`}
      arrows
      variableWidth={!isFullWidth}
      dots={false}
      infinite={false}
      prevArrow={<SlickLeftArrow className="slick-arrow slick-prev" />}
      nextArrow={<SlickRightArrow className="slick-arrow slick-next" />}
    >
      {children}
    </Carousel>
  );
};

export default CarouselComponent;
