import { useCallback } from 'react';
import { Line } from '@ant-design/plots';
import TooltipContent from 'components/TooltipContent';
import { COLORS, GRAPH_OPTIONS } from 'constants/graphConfig';
import { generateGraphColors } from 'utils/dashboardUtils';
import {
  graphSliderValueFormatter,
  graphTooltipFormatter,
} from 'utils/graphUtils';

type LineChartProps = {
  data: any[];
  xField: string;
  yField: string;
  xTitle: string;
  yTitle: string;
  groupingField?: string;
  colorOverride?: string | string[];
  showAllLegend?: boolean;
  disableAnimation?: boolean;
  prefixSymbol?: string;
  postfixSymbol?: string;
  showSlider?: boolean;
  sliderCountLimit?: number;
  onSliderValueChange?: (x: number, y: number) => void;
  sliderValues?: { x: number; y: number };
};

const LineChart = ({
  data,
  xField,
  yField,
  xTitle,
  yTitle,
  groupingField,
  colorOverride,
  showAllLegend = false,
  disableAnimation = false,
  prefixSymbol,
  postfixSymbol,
  showSlider,
  sliderCountLimit = GRAPH_OPTIONS.sliderCountLimit,
  onSliderValueChange,
  sliderValues,
}: LineChartProps) => {
  const getSliderTextFormatter = useCallback(
    (_value: any, _data: any, index: number) => {
      return graphSliderValueFormatter(index, data.length);
    },
    []
  );

  const config: any = {
    data,
    xField,
    yField,
    xAxis: {
      title: {
        text: xTitle,
      },
    },
    yAxis: {
      title: {
        text: yTitle,
      },
      line: {
        style: {
          stroke: COLORS.fnGrey,
          lineWidth: 1,
          opacity: 0.7,
        },
      },
    },
    legend: {
      position: 'bottom',
      flipPage: !showAllLegend,
      pageNavigator: {
        marker: {
          style: {
            fill: COLORS.primaryButton,
            size: 8,
          },
        },
        text: {
          style: {
            fill: COLORS.secondaryBlack,
            fontSize: 12,
          },
        },
      },
    },
    seriesField: groupingField,
    color: colorOverride ?? generateGraphColors(data.length),
    tooltip: {
      formatter: useCallback(
        (value: any) =>
          graphTooltipFormatter(value, groupingField ?? '', yField),
        [groupingField]
      ),
      customContent: useCallback(
        (title: string, data: any[]) => (
          <TooltipContent
            title={title}
            data={data}
            prefixSymbol={prefixSymbol}
            postfixSymbol={postfixSymbol}
          />
        ),
        [postfixSymbol]
      ),
    },
    animation: !disableAnimation,
    onReady: useCallback((plot: any) => {
      onSliderValueChange &&
        plot.on('slider:valuechanged', (evt: any) =>
          onSliderValueChange(
            evt.gEvent.originalEvent.value[0],
            evt.gEvent.originalEvent.value[1]
          )
        );
    }, []),
    slider:
      showSlider && data.length > sliderCountLimit
        ? {
            start: sliderValues ? sliderValues.x : 0,
            end: sliderValues ? sliderValues.y : sliderCountLimit / data.length,
            backgroundStyle: {
              fill: COLORS.fnGrey13,
              fillOpacity: 0.9,
              radius: 6,
            },
            foregroundStyle: {
              fill: COLORS.colorScrollBar,
              fillOpacity: 0.5,
            },
            handlerStyle: {
              fill: COLORS.colorScrollBar,
              highLightFill: COLORS.colorScrollBar,
              stroke: COLORS.white,
              height: 18,
              width: 12,
            },
            textStyle: {
              fill: COLORS.secondaryBlack,
              opacity: 0.9,
            },
            padding: [0, 10, 0, 0],
            formatter: getSliderTextFormatter,
          }
        : false,
  };

  return <Line {...config} />;
};

export default LineChart;
