import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import { RecommendationList } from 'pages/CostOptimizationInsightsPage/components/ConsolidatedRecommendationTable/types';

import YamlDetailsModal from './components/YamlDetailsModal';

type YamlDetailsProps = {
  recommendation: RecommendationList;
};

const YamlDetails = ({ recommendation }: YamlDetailsProps) => {
  const { t } = useTranslation();

  const [showYamlModal, setShowYamlModal] = useState(false);

  return (
    <div>
      <Button
        title={t('granulateInsightDashboard.seeDetails')}
        size={BUTTON_SIZE.SMALL}
        type={BUTTON_TYPE.NEUTRAL}
        onClick={() => setShowYamlModal(true)}
      />
      {showYamlModal && (
        <YamlDetailsModal
          show={showYamlModal}
          setShow={setShowYamlModal}
          recommendation={recommendation}
        />
      )}
    </div>
  );
};

export default YamlDetails;
