import i18n from 'i18n';
import { COMPARATORS } from 'constants/requestBody';
import { FIELD_TYPE } from 'constants/dashboard';

export enum ConditionsValueType {
  SINGLE,
  MULTIPLE,
  NUMERIC,
  DATE,
  DATE_RANGE,
}

export const CONDITIONS_FOR_FIELDS = {
  [FIELD_TYPE.LITERAL]: [
    {
      value: COMPARATORS.EQUALS,
      label: i18n.t('customDashboard.optionsLabels.equals'),
      optionsType: ConditionsValueType.SINGLE,
    },
    {
      value: COMPARATORS.IN,
      label: i18n.t('customDashboard.optionsLabels.in'),
      optionsType: ConditionsValueType.MULTIPLE,
    },
  ],
  [FIELD_TYPE.NUMERIC]: [
    {
      value: COMPARATORS.EQUALS,
      label: i18n.t('customDashboard.optionsLabels.equals'),
      optionsType: ConditionsValueType.NUMERIC,
    },
    {
      value: COMPARATORS.GREATER_THAN_OR_EQUAL,
      label: i18n.t('customDashboard.optionsLabels.greaterThanOrEqualTo'),
      optionsType: ConditionsValueType.NUMERIC,
    },
    {
      value: COMPARATORS.GREATER_THAN,
      label: i18n.t('customDashboard.optionsLabels.greaterThan'),
      optionsType: ConditionsValueType.NUMERIC,
    },
    {
      value: COMPARATORS.LESS_THAN_OR_EQUAL,
      label: i18n.t('customDashboard.optionsLabels.lessThanOrEqualTo'),
      optionsType: ConditionsValueType.NUMERIC,
    },
    {
      value: COMPARATORS.LESS_THAN,
      label: i18n.t('customDashboard.optionsLabels.lessThan'),
      optionsType: ConditionsValueType.NUMERIC,
    },
  ],
  [FIELD_TYPE.TIME]: [
    {
      value: COMPARATORS.LESS_THAN_OR_EQUAL,
      label: i18n.t('customDashboard.optionsLabels.before'),
      optionsType: ConditionsValueType.DATE,
    },
    {
      value: COMPARATORS.GREATER_THAN_OR_EQUAL,
      label: i18n.t('customDashboard.optionsLabels.after'),
      optionsType: ConditionsValueType.DATE,
    },
    {
      value: COMPARATORS.IN,
      label: i18n.t('customDashboard.optionsLabels.between'),
      optionsType: ConditionsValueType.DATE_RANGE,
    },
  ],
};
