import { Divider, message, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useDidMountEffect from 'hooks/useDidMountEffect';
import { DeleteIconButton } from 'assets/icons';
import Icon from 'components/Icon';
import { FormLabel } from 'components/FormLabel';
import SearchUserInput from 'components/AddUserSearch';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import Button from 'components/Button';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import {
  AnamolySubscriptionType,
  ALERT_SUBSCRIPTION_THRESHOLD_TYPE,
  ALERT_SUBSCRIPTION_TYPE,
} from 'pages/CostMonitorPage/types';
import {
  costMonitor,
  setCostMonitorFormData,
  setExistingAttachedSubscriptions,
} from 'redux/costMonitorSlice';
import { selectTheme } from 'redux/themeSlice';
import { validateEmptyField } from 'utils/validations';
import { UserInfoType } from 'types/userManagementConsole';
import { CONJUNCTIONS, REQUEST_STATUS } from 'constants/requestBody';

import ThresholdRow from '../ThresholdRow';

type AlertSubscriptionFormProps = {
  createButtonClick: boolean;
  index: number;
  alertSubscriptionDetails: AnamolySubscriptionType;
  setAlertSubscriptionsValidation: (val: boolean) => void;
  existingAlertSubscriptions: AnamolySubscriptionType[];
  existingAlertSubscriptionsRequestStatus: string;
};

const AlertSubscriptionForm = ({
  createButtonClick,
  index,
  alertSubscriptionDetails,
  setAlertSubscriptionsValidation,
  existingAlertSubscriptions,
  existingAlertSubscriptionsRequestStatus,
}: AlertSubscriptionFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', {
    keyPrefix: 'costMonitor.createCostMonitorLabels.createAlertSubscription',
  });

  const { costMonitorFormData, isEditForm, existingAttachedSubscriptions } =
    useSelector(costMonitor);
  const { theme } = useSelector(selectTheme);

  const [subscriptionNameValidation, setSubscriptionNameValidation] =
    useState('');
  const [alertRecipientsValidation, setAlertRecipientsValidation] =
    useState('');

  useDidMountEffect(() => {
    validateAlertSubscription();
  }, [createButtonClick]);

  useEffect(() => {
    validateSubscriptions(
      alertSubscriptionDetails.existingSubscription,
      alertSubscriptionDetails.subscriptionName,
      false
    );
  }, [costMonitorFormData.connectorId]);

  /**
   * @function updateAlertSubscription
   * @description update the current alert subscription details
   * @param newAlertSubscription - new alert subscription details
   */
  const updateAlertSubscription = (
    newAlertSubscription: AnamolySubscriptionType
  ) => {
    dispatch(
      setCostMonitorFormData({
        ...costMonitorFormData,
        anomalySubscriptions: costMonitorFormData.anomalySubscriptions.map(
          (alertSubscription, i) => {
            if (i === index) {
              return newAlertSubscription;
            }
            return alertSubscription;
          }
        ),
      })
    );
  };

  /**
   * @function addUserToList
   * @description add user to the alert recipients list
   * @param user - recipient user
   */
  const addUserToList = (user: UserInfoType) => {
    if (alertSubscriptionDetails?.alertRecipientEmails?.includes(user.email)) {
      message.warning(t('emailExists'));
      return;
    }
    validateAlertRecipients(
      (alertSubscriptionDetails?.alertRecipientEmails ?? [])?.length + 1
    );

    updateAlertSubscription({
      ...alertSubscriptionDetails,
      alertRecipientEmails: [
        ...(alertSubscriptionDetails?.alertRecipientEmails ?? []),
        user.email,
      ],
    });
  };

  /**
   * @function validateSubscriptionName
   * @description Function to validate the connection field
   * @param value Value to be validated
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateSubscriptionName = (value: string, setErrorMessage = true) => {
    setSubscriptionNameValidation('');
    if (
      validateEmptyField(
        value,
        t('subscriptionName'),
        setSubscriptionNameValidation,
        setErrorMessage
      )
    ) {
      return false;
    }

    if (
      !alertSubscriptionDetails.existingSubscription &&
      existingAlertSubscriptions.some(
        (subscription) => subscription.subscriptionName === value.trim()
      )
    ) {
      setErrorMessage &&
        setSubscriptionNameValidation(t('duplicateSubscriptionName'));
      return false;
    }

    return true;
  };

  /**
   * @function validateAlertRecipients
   * @description Function to validate the connection field
   * @param value Value to be validated
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateAlertRecipients = (
    lengthOfRecipients: number,
    setErrorMessage = true
  ) => {
    if (lengthOfRecipients === 0) {
      setErrorMessage &&
        setAlertRecipientsValidation(t('minAlertRecipientsValidation'));
      return false;
    }
    if ((alertSubscriptionDetails?.alertRecipientEmails || []).length >= 10) {
      setErrorMessage &&
        setAlertRecipientsValidation(t('maxRecipientsValidation'));
      return false;
    }
    setAlertRecipientsValidation('');
    return true;
  };

  /**
   * @function validateAlertSubscription
   * @description Function to validate the fields in alert subscription form
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateAlertSubscription = (setErrorMessage = true) => {
    let validation = true;
    validation =
      validateSubscriptionName(
        alertSubscriptionDetails.subscriptionName,
        setErrorMessage
      ) && validation;
    validation =
      validateSubscriptions(
        alertSubscriptionDetails.existingSubscription,
        alertSubscriptionDetails.subscriptionName,
        setErrorMessage
      ) && validation;
    if (!alertSubscriptionDetails.existingSubscription) {
      validation =
        validateAlertRecipients(
          (alertSubscriptionDetails?.alertRecipientEmails ?? [])?.length,
          setErrorMessage
        ) && validation;
    }
    return validation;
  };

  /**
   * @function validateSubscriptions
   * @description Function to validate the subscription field name based on the type of subscription chosen
   * @param subscriptionType boolean value to indicate whether to set the error message or not. Defaults to true.
   * @param setErrorMessage boolean value to indicate whether to set the error message or not. Defaults to true.
   * @returns boolean true if validation is successful else false
   */
  const validateSubscriptions = (
    isExistingSubscription: boolean,
    subscriptionName: string = alertSubscriptionDetails.subscriptionName,
    setErrorMessage: boolean = true
  ) => {
    if (isExistingSubscription && !costMonitorFormData.connectorId) {
      setErrorMessage && setSubscriptionNameValidation(t('chooseAConnection'));
      return false;
    }
    return validateSubscriptionName(subscriptionName, setErrorMessage);
  };

  /**
   * @function isSubscriptionDisabled
   * @description Function to validate if the subscription already exists in edit form
   * @returns boolean true if the subscription exists else false
   */
  const isSubscriptionDisabled = () => {
    return (
      isEditForm &&
      existingAttachedSubscriptions.some(
        (subscription) =>
          subscription === alertSubscriptionDetails.subscriptionArn
      )
    );
  };

  /**
   * @function onDeleteSubscription
   * @description Function to remove a subscription
   */
  const onDeleteSubscription = () => {
    if (isSubscriptionDisabled()) {
      dispatch(
        setExistingAttachedSubscriptions(
          existingAttachedSubscriptions.filter(
            (subscription) =>
              subscription !== alertSubscriptionDetails.subscriptionArn
          )
        )
      );
    }

    dispatch(
      setCostMonitorFormData({
        ...costMonitorFormData,
        anomalySubscriptions: costMonitorFormData.anomalySubscriptions.filter(
          (_, i) => i !== index
        ),
      })
    );
  };

  /**
   * @function getComponent
   * @description get the component based on the alert subscription type
   * @returns JSX.Element
   */
  const getComponent = () => {
    if (!alertSubscriptionDetails.existingSubscription) {
      return (
        <>
          <div className="form-item flex flex-column">
            <FormLabel title={t('subscriptionName')} required />
            <Input
              placeholder={t('subscriptionNamePlaceholder')}
              value={alertSubscriptionDetails.subscriptionName}
              onChange={(e: any) => {
                setSubscriptionNameValidation('');
                updateAlertSubscription({
                  ...alertSubscriptionDetails,
                  subscriptionName: e.target.value,
                });
                validateSubscriptionName(e.target.value);
              }}
              onBlur={(e: any) => {
                validateSubscriptionName(e.target.value);
              }}
            />
            <span
              style={{
                display: `${subscriptionNameValidation ? 'inline' : 'none'}`,
              }}
              className="font-validation-error"
            >
              {subscriptionNameValidation}
            </span>
          </div>
          <div className="form-item flex flex-column flex-gap-4">
            <FormLabel
              title={t('alertRecipients')}
              subtitle={
                <span className="help-text">{t('upto10Recipients')}</span>
              }
              required
            />
            <SearchUserInput
              placeholder={t('searchUserByEmail')}
              setUserInfo={addUserToList}
            />
            <span
              style={{
                display: `${alertRecipientsValidation ? 'inline' : 'none'}`,
              }}
              className="font-validation-error"
            >
              {alertRecipientsValidation}
            </span>
            <div className="flex flex-start flex-wrap flex-gap-4">
              {alertSubscriptionDetails?.alertRecipientEmails?.map(
                (recipient) => (
                  <AccessibleDiv
                    className="recipients-list-item flex flex-gap-4 flex-center"
                    key={recipient}
                    onClick={() => {
                      updateAlertSubscription({
                        ...alertSubscriptionDetails,
                        alertRecipientEmails:
                          alertSubscriptionDetails?.alertRecipientEmails?.filter(
                            (r) => r !== recipient
                          ),
                      });
                    }}
                  >
                    {recipient}
                    <Icon
                      iconName={ICONS.CLOSE_LINE}
                      size={ICONS_SIZE.SM}
                      color={theme.buttonIconColor}
                    />
                  </AccessibleDiv>
                )
              )}
            </div>
          </div>
          <div className="card flex flex-column">
            <span className="form-header">{t('threshold')}</span>
            {alertSubscriptionDetails?.thresholds
              ?.map((obj, index) => ({ ...obj, key: index }))
              .map((threshold) => (
                <ThresholdRow
                  threshold={threshold}
                  index={threshold.key}
                  key={threshold.key}
                  alertSubscriptionDetails={alertSubscriptionDetails}
                  updateAlertSubscription={updateAlertSubscription}
                  createButtonClick={createButtonClick}
                  setAlertSubscriptionsValidation={
                    setAlertSubscriptionsValidation
                  }
                />
              ))}
            <span className="font-caption help-text">
              {t('thresholdHelpText')}
            </span>
          </div>
          {(alertSubscriptionDetails?.thresholds ?? []).length < 2 && (
            <Button
              type={BUTTON_TYPE.NEUTRAL}
              iconName={ICONS.ADD_LINE}
              size={BUTTON_SIZE.SMALL}
              className="flex-align-self-start"
              title={t('addThreshold')}
              onClick={() => {
                updateAlertSubscription({
                  ...alertSubscriptionDetails,
                  conjunction: CONJUNCTIONS.AND,
                  thresholds: [
                    ...(alertSubscriptionDetails?.thresholds ?? []),
                    {
                      thresholdValue: '',
                      thresholdType:
                        alertSubscriptionDetails?.thresholds &&
                        alertSubscriptionDetails?.thresholds[0]
                          .thresholdType ===
                          ALERT_SUBSCRIPTION_THRESHOLD_TYPE.PERCENTAGE
                          ? ALERT_SUBSCRIPTION_THRESHOLD_TYPE.AMOUNT
                          : ALERT_SUBSCRIPTION_THRESHOLD_TYPE.PERCENTAGE,
                    },
                  ],
                });
              }}
            />
          )}
        </>
      );
    } else if (alertSubscriptionDetails.existingSubscription) {
      return (
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('subscriptionName')}
            required
            disabled={isSubscriptionDisabled()}
          />
          <SelectDropdown
            value={alertSubscriptionDetails.subscriptionName}
            options={existingAlertSubscriptions?.map((subscription) => ({
              value: subscription.subscriptionArn,
              label: subscription.subscriptionName,
              disabled: costMonitorFormData.anomalySubscriptions.some(
                (item) => subscription.subscriptionArn === item.subscriptionArn
              ),
            }))}
            showSearch
            optionFilterProp="label"
            onChange={(_value: string, option: any) => {
              updateAlertSubscription({
                ...alertSubscriptionDetails,
                subscriptionName: option.label,
                subscriptionArn: option.value,
              });
              validateSubscriptionName(option.label);
            }}
            onBlur={() => {
              validateSubscriptionName(
                alertSubscriptionDetails.subscriptionName
              );
            }}
            loading={
              existingAlertSubscriptionsRequestStatus ===
              REQUEST_STATUS.PROCESSING
            }
            disabled={isSubscriptionDisabled()}
          />
          <span
            style={{
              display: `${subscriptionNameValidation ? 'inline' : 'none'}`,
            }}
            className="font-validation-error"
          >
            {subscriptionNameValidation}
          </span>
        </div>
      );
    }
  };

  return (
    <div className="alert-subscription-form card flex flex-column">
      <div className="flex flex-center flex-space-between">
        <span className="form-header">{`${t('alertSubscriptionHeaderPrefix')}${
          index + 1
        }`}</span>
        {(costMonitorFormData.anomalySubscriptions.length > 1 ||
          isSubscriptionDisabled()) && (
          <DeleteIconButton
            className="cursor-pointer"
            onClick={onDeleteSubscription}
          />
        )}
      </div>
      <Divider />
      <div className="flex flex-column flex-gap-16">
        {!isSubscriptionDisabled() && (
          <div className="form-item flex flex-column">
            <FormLabel title={t('alertSubscriptionType')} required />
            <Radio.Group
              className="flex flex-column"
              value={
                alertSubscriptionDetails.existingSubscription
                  ? ALERT_SUBSCRIPTION_TYPE.EXISTING_SUBSCRIPTION
                  : ALERT_SUBSCRIPTION_TYPE.NEW_SUBSCRIPTION
              }
              onChange={(e) => {
                updateAlertSubscription({
                  ...alertSubscriptionDetails,
                  existingSubscription:
                    e.target.value ===
                    ALERT_SUBSCRIPTION_TYPE.EXISTING_SUBSCRIPTION,
                  subscriptionName: '',
                  subscriptionArn: undefined,
                });
                validateSubscriptions(e.target.value, '');
              }}
            >
              <Radio value={ALERT_SUBSCRIPTION_TYPE.NEW_SUBSCRIPTION}>
                {t('createNewSubscription')}
              </Radio>
              <Radio value={ALERT_SUBSCRIPTION_TYPE.EXISTING_SUBSCRIPTION}>
                {t('chooseExistingSubscription')}
              </Radio>
            </Radio.Group>
          </div>
        )}
        {getComponent()}
      </div>
    </div>
  );
};

export default AlertSubscriptionForm;
