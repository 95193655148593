import { NAVIGATION_MENU_DATA } from 'constants/navigationMenu';
import { CONJUNCTIONS } from 'constants/requestBody';
import { NavigationMenuDataState, SubSectionType } from 'types/navigationMenu';

/**
 * @function checkRouteAuthorization
 * @description Function to check the user is authorized to access the route
 * @returns boolean according to the user role
 */
export const checkRouteAuthorization = (pathName: string) => {
  const navMenuData = NAVIGATION_MENU_DATA;

  return navMenuData.some((menuData: NavigationMenuDataState) => {
    return (
      (menuData.rootPath && menuData.rootPath === pathName) ||
      (menuData.otherPaths?.length && menuData.otherPaths.includes(pathName)) ||
      (menuData.subSections.length &&
        menuData.subSections.some((subSectionData: SubSectionType) => {
          return (
            (subSectionData.rootPath && subSectionData.rootPath === pathName) ||
            subSectionData.otherPaths?.includes(pathName) ||
            subSectionData.paramPaths?.some((paramPath) =>
              pathName.includes(paramPath.substring(0, pathName.indexOf(':')))
            )
          );
        }))
    );
  });
};

/**
 * @function getExpandableMenuIds
 * @description Function to return the expandable menu items Ids.
 * @return List of ids
 */
export const getExpandableMenuIds = () => {
  const menuIds: string[] = [];
  NAVIGATION_MENU_DATA.forEach((navData) => {
    menuIds.push(navData.id);

    if (navData.subMenu && navData.subMenu.length > 0) {
      navData.subMenu.forEach((subMenu) => menuIds.push(subMenu.id));
    }
  });
  return menuIds;
};

/**
 * @function isPathPresentInMenuItems
 * @description Function to validate if path is present in the menu.
 * @param menu menu data to be validated against.
 * @param path path for which the validation is done.
 * @return boolean true if path is present else false
 */
export const isPathPresentInMenuItems = (
  menu: NavigationMenuDataState,
  path: string
): boolean => {
  if (
    (menu.rootPath && menu.rootPath === path) ||
    (menu.otherPaths &&
      menu.otherPaths.length > 0 &&
      menu.otherPaths.includes(path))
  ) {
    return true;
  }

  let isPathMatching = false;
  if (menu.subSections.length > 0) {
    isPathMatching = menu.subSections.some((subSection) =>
      isSubSectionItemIncludePath(subSection, path)
    );
  }

  if (isPathMatching) {
    return true;
  }

  if (menu.subMenu) {
    return menu.subMenu.some((subMenu) =>
      isPathPresentInMenuItems(subMenu, path)
    );
  }

  return false;
};

/**
 * @function isSubSectionItemIncludePath
 * @description Function to validate if path is present in the subsection of the menu.
 * @param subSection subsection data to be validated against.
 * @param path path for which the validation is done.
 * @return boolean true if path is present else false
 */
export const isSubSectionItemIncludePath = (
  subSection: SubSectionType,
  path: string
) => {
  return (
    (subSection.rootPath && subSection.rootPath === path) ||
    (subSection.otherPaths &&
      subSection.otherPaths.length > 0 &&
      subSection.otherPaths.includes(path)) ||
    subSection?.paramPaths?.some((paramPath) =>
      path.startsWith(paramPath.substring(0, paramPath.indexOf('/:')))
    )
  );
};

/**
 * @function isSubMenuItemIncludePath
 * @description Function to validate if path is present in the subs menu of the menu.
 * @param subMenu sub menu data to be validated against.
 * @param path path for which the validation is done.
 * @return boolean true if path is present else false
 */
export const isSubMenuItemIncludePath = (
  subMenu: NavigationMenuDataState,
  path: string
) => {
  return subMenu.subSections.some((subSection) =>
    isSubSectionItemIncludePath(subSection, path)
  );
};

/**
 * @function getMenuIdByPath
 * @description Function to return the id by path in the list of menu
 * @param menu menu data for fetching the data
 * @param path path for which the validation is done.
 * @return id of the matching path or undefined
 */
export const getMenuIdByPath = (
  menu: NavigationMenuDataState[],
  path: string
) => {
  const matchingParentMenu = menu.find((parentMenu) =>
    isPathPresentInMenuItems(parentMenu, path)
  );

  if (matchingParentMenu?.subMenu && matchingParentMenu.subMenu.length > 0) {
    const matchingSubMenu = matchingParentMenu.subMenu.find((subMenu) =>
      isSubMenuItemIncludePath(subMenu, path)
    );

    if (
      matchingSubMenu?.subSections &&
      matchingSubMenu.subSections.length > 0
    ) {
      return matchingSubMenu.subSections.find((subSection) =>
        isSubSectionItemIncludePath(subSection, path)
      )?.id;
    }
  }

  if (
    matchingParentMenu?.subSections &&
    matchingParentMenu.subSections.length > 0
  ) {
    return matchingParentMenu.subSections.find((subSection) =>
      isSubSectionItemIncludePath(subSection, path)
    )?.id;
  }

  // Check for the Id in nested subMenu
  if (
    matchingParentMenu?.subMenu &&
    matchingParentMenu.subMenu.length > 0 &&
    hasNestedSubMenu(matchingParentMenu)
  ) {
    const matchingNestedSubMenu = matchingParentMenu?.subMenu[0]?.subMenu?.find(
      (subMenu) => isSubMenuItemIncludePath(subMenu, path)
    );

    if (
      matchingNestedSubMenu?.subSections &&
      matchingNestedSubMenu.subSections.length > 0
    ) {
      return matchingNestedSubMenu.subSections.find((subSection) =>
        isSubSectionItemIncludePath(subSection, path)
      )?.id;
    }
  }

  return matchingParentMenu?.id;
};

/**
 * @function getDefaultRouteInSubSections
 * @description Function to fetch the default path in subsections
 * @param permissions List of permissions
 * @param subSections list of subsections
 * @returns path with permissions
 */
const getDefaultRouteInSubSections = (
  permissions: { [key: string]: boolean },
  subSections: SubSectionType[]
) => {
  return subSections.find((subSection) => {
    if (subSection.permissionConjunction === CONJUNCTIONS.AND) {
      subSection.permissions.every((permission) => permissions[permission]);
    }
    return subSection.permissions.some((permission) => permissions[permission]);
  })?.rootPath;
};

/**
 * @function getDefaultLandingPath
 * @description Function to fetch the default landing path
 * @param permissions List of permissions
 * @returns path with permissions
 */
export const getDefaultLandingPath = (permissions: {
  [key: string]: boolean;
}) => {
  const parentNavData = NAVIGATION_MENU_DATA.find(
    (navData) =>
      navData?.subSections?.some((item) =>
        isSubSectionAuthorized(item, permissions)
      ) ||
      navData.subMenu?.some((subMenu) =>
        subMenu?.subSections?.some((item) =>
          isSubSectionAuthorized(item, permissions)
        )
      )
  );

  if (!parentNavData) {
    return '/';
  }

  return getFirstSubSection(parentNavData, permissions)?.rootPath;
};

/**
 * @function isSubSectionAuthorized
 * @description Function to validate if the sub section is has the permission or not
 * @param subSection subsection item for which the sub section is fetched
 * @param permissions List of permissions
 * @param returns the first subsection of the menu selected
 */
export const isSubSectionAuthorized = (
  subSection: SubSectionType,
  permissions: { [key: string]: boolean }
) => {
  if (subSection.permissionConjunction === CONJUNCTIONS.AND) {
    return subSection.permissions.every(
      (permission) => permissions[permission]
    );
  }

  return subSection.permissions.some((permission) => permissions[permission]);
};

/**
 * @function getFirstSubSection
 * @description Function to fetch the first sub section of the menu selected
 * @param menu menu item for which the sub section is fetched
 * @param permissions List of permissions
 * @param returns the first subsection of the menu selected
 */
export const getFirstSubSection = (
  menu: NavigationMenuDataState,
  permissions: { [key: string]: boolean }
) => {
  let subSection = menu.subSections.find((item) =>
    isSubSectionAuthorized(item, permissions)
  );

  if (
    (!subSection || !menu.subSectionsFirst) &&
    menu.subMenu &&
    menu.subMenu.length > 0
  ) {
    const subMenu = menu.subMenu.find((item) =>
      item.subSections.some((subSectionItem) =>
        isSubSectionAuthorized(subSectionItem, permissions)
      )
    );

    subSection = subMenu?.subSections.find((item) =>
      isSubSectionAuthorized(item, permissions)
    );
  }

  return subSection;
};

/**
 * @function findFirstSubMenuWithSubsection
 * @description finds first submenu having subsections
 * @param menu menu item for which the sub section is fetched
 * @returns Object having subsections property or null
 */
export const findFirstSubMenuWithSubsection: any = (
  menu: NavigationMenuDataState
) => {
  if (menu.subSections.length > 0) {
    return menu;
  }
  if (menu?.subMenu) {
    const menuItem = menu.subMenu.find((subMenu) =>
      findFirstSubMenuWithSubsection(subMenu)
    );
    if (menuItem) {
      return menuItem;
    }
  }

  return null;
};

/**
 * @function hasNestedSubMenu
 * @description Function to check if menu has subMenu inside subMenu
 * @param menu menu data
 * @returns true if nested subMenu is present else undefined
 */
export const hasNestedSubMenu = (menu: NavigationMenuDataState) => {
  return (
    menu?.subMenu &&
    menu.subMenu.some(
      (subMenuItem) => subMenuItem.subMenu && subMenuItem.subMenu.length > 0
    )
  );
};

/**
 * @function getNavigationPathTitle
 * @description Returns the list of path title by Menu ID.
 * @param menuId The Menu id for which the nav titles are to be found
 * @param parentMenu List of Menu Items from which the title needs to be listed
 * @param subParentTitle Path of the additional paths needs to be prefixed.
 * @return Function will return the path titles of a nav id as string[].
 * If a nav id is inside a sub menu of a menu. It will return two parent nav ids.
 * For example for 'tagMapping' it will return ['dashboard', 'dimension', 'tagMapping']
 */
export const getNavigationPathTitle = (
  subNavId: string | undefined,
  parentMenu: NavigationMenuDataState[] = NAVIGATION_MENU_DATA,
  subParentTitle: string[] = []
): string[] => {
  let titles: string[] = [];
  parentMenu.forEach((section) => {
    if (section?.subMenu) {
      // If submenu found it will recursively call the function to search inside the submenu.
      const childTitles = getNavigationPathTitle(subNavId, section?.subMenu, [
        section.title,
      ]);

      if (childTitles.length > 1) {
        titles = childTitles;
        return;
      }
    }

    if (section.id === subNavId) {
      titles = [section.title];
    }

    section.subSections.forEach((subSection) => {
      if (subSection.id === subNavId) {
        titles = [section.title, subSection.title];
      }
    });
  });
  return [...subParentTitle, ...titles];
};
