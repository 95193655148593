import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectReport } from 'redux/reportSlice';
import { CHART_TYPES } from 'constants/graphConfig';
import { CHARTS_LIST } from 'constants/dashboard';

import ChartDimensionList from '../ChartDimensionList';

import './index.scss';

const ChartDimensions = () => {
  const { t } = useTranslation();
  const { reportOptions } = useSelector(selectReport);

  /**
   * @function getValidationMessage
   * @description Function to return the validation message for dimension in chart view
   * @returns string validation message
   */
  const getValidationMessage = () => {
    if (reportOptions.dimension.length === 0) {
      return t('reports.chooseDimensionInTable');
    }

    if (reportOptions.chartType === CHART_TYPES.TABLE) {
      return t('reports.chooseChartType');
    }

    const selectedChartDimensionCount = CHARTS_LIST.find(
      (item) => item.chartType === reportOptions.chartType
    )?.dimensionsCount;
    if (
      (selectedChartDimensionCount?.min ?? 0) > reportOptions.dimension.length
    ) {
      return t('reports.chooseAtleastNDimensions', {
        count: selectedChartDimensionCount?.min ?? 0,
      });
    }

    return null;
  };

  /**
   * @function getDimensionListComponent
   * @description Function to return the dimension list component based on the validation
   * @returns JSX component
   */
  const getDimensionListComponent = () => {
    const validationMessage = getValidationMessage();

    return validationMessage ? (
      <div className="dimension-validation-message font-caption-bold">
        {validationMessage}
      </div>
    ) : (
      <ChartDimensionList />
    );
  };

  return (
    <div className="report-chart-dimensions flex flex-column flex-gap-8">
      <div className="font-caption-bold">{t('reports.dimension')}</div>
      {getDimensionListComponent()}
    </div>
  );
};

export default ChartDimensions;
