import { useTranslation } from 'react-i18next';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/date';
import { KeyValueTypes } from 'types/dataTypes';
import { REQUEST_STATUS } from 'constants/requestBody';
import { AWSAnomalyType } from '../../types';

type AnomalyDetailsProps = {
  anomalyDetails: AWSAnomalyType | undefined;
  pdfView: boolean;
  requestStatus: string;
  setShowExpandedGraphModal: (val: boolean) => void;
};

const AnomalyDetails = ({
  anomalyDetails,
  pdfView,
  requestStatus,
  setShowExpandedGraphModal,
}: AnomalyDetailsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'anomalyDetectionDashboard.anomalyDetails',
  });

  const [anomalyData, setAnomalyData] = useState<KeyValueTypes[]>([]);

  useEffect(() => {
    if (!anomalyDetails) {
      setAnomalyData([]);
      return;
    }

    const data = [
      {
        key: t('totalCostImpact'),
        value: `$ ${anomalyDetails.totalCostImpact}`,
      },
      {
        key: t('expectedSpend'),
        value: `$ ${anomalyDetails.expectedSpend}`,
      },
      {
        key: t('actualSpend'),
        value: `$ ${anomalyDetails.actualSpend}`,
      },
      {
        key: t('startDate'),
        value: moment(anomalyDetails.startDate).format(DATE_FORMAT),
      },
      {
        key: t('lastDetectedDate'),
        value: moment(anomalyDetails.lastDetectedDate).format(DATE_FORMAT),
      },
      {
        key: t('duration'),
        value: `${
          moment(anomalyDetails.lastDetectedDate).diff(
            moment(anomalyDetails.startDate),
            'days'
          ) + 1
        } ${t('days')}`,
      },
      {
        key: t('monitorArn'),
        value: anomalyDetails.monitorArn,
      },
      {
        key: t('linkedAccountId'),
        value: anomalyDetails.rootCauses[0].linkedAccountId,
      },
      {
        key: t('linkedAccountName'),
        value: anomalyDetails.rootCauses[0].linkedAccountName,
      },
      {
        key: t('region'),
        value: anomalyDetails.rootCauses[0].region,
      },
      {
        key: t('service'),
        value: anomalyDetails.rootCauses[0].service,
      },
      {
        key: t('usageType'),
        value: anomalyDetails.rootCauses[0].usageType,
      },
    ];
    setAnomalyData(data);
  }, [anomalyDetails]);

  /**
   * @function getComponent
   * @description Function to return the graph or table component
   * @returns JSX element containing graph or the table
   */
  const getComponent = () => {
    if (!anomalyDetails) {
      return (
        <DashboardComponent
          requestStatus={REQUEST_STATUS.ERROR}
          errorMessage={t('chooseAnAnomaly')}
        />
      );
    }

    return (
      <div className="flex flex-column data full-height flex-gap-8">
        {anomalyData.map((item) => (
          <div key={item.key} className="flex flex-gap-8">
            <div className="font-caption-bold">{item.key}:</div>
            <div className="font-validation-error">{item.value}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="graph-area anomaly-details stretch" id="graph-container">
      <GraphHeader
        heading={anomalyDetails?.dimensionValue ?? ''}
        graphName="anomaly-details"
        isTableViewSwitch={false}
        isTableView={false}
        showExpandIcon={!pdfView}
        setShowExpandedGraph={setShowExpandedGraphModal}
        isDownloadable={!pdfView}
      />
      <div className="anomaly-data-list styled-scroll">
        <DashboardComponent
          component={getComponent()}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default AnomalyDetails;
