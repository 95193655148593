import moment from 'moment';
import { PROVIDER } from 'constants/cloudProviders';
import {
  FULL_YEAR_FORMAT,
  SHORT_MONTH_FORMAT,
  getCurrentDate,
} from 'utils/date';

import { BUDGET_RENEWAL_TYPE_VALUES } from './components/BudgetsAndAlertsForm/AWS/SetupBudgetDetails/constants';
import { BUDGET_DATE_RANGES } from './components/BudgetsAndAlertsForm/GCP/SetupBudgetsDetails/constants';
import { BudgetDataTypes } from './types';

export enum BudgetsAndAlertsComponents {
  BUDGETS_ALERTS_CONNECTION_FORM = 'BUDGETS_ALERTS_CONNECTION_FORM',
  SUCCESS_PAGE = 'SUCCESS_PAGE',
  ERROR_PAGE = 'ERROR_PAGE',
}

export enum BudgetsAndAlertsQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const BUTTON_TITLES = {
  CONTINUE: 'continueBtn',
  NEXT: 'nextBtn',
  CHECKING_CONNECTION: 'checkingConnectionBtn',
  FETCHING_DETAILS: 'fetchingDetailsBtn',
};

export const DEFAULT_BUDGET_DATA: BudgetDataTypes = {
  displayName: '',
  timeRange: BUDGET_DATE_RANGES.MONTHLY,
  budgetName: '',
  cloudProvider: PROVIDER.GCP,
  amount: 0,
  connectorId: '',
  projects: [],
  services: [],
  thresholdRules: [],
  notificationEmails: [],
  budgetType: '',
  budgetRenewalType: BUDGET_RENEWAL_TYPE_VALUES.RECURRING,
  selectedMonth: moment().add(1, 'months').format(SHORT_MONTH_FORMAT),
  selectedYear: getCurrentDate(FULL_YEAR_FORMAT),
  startDateTime: '',
  endDateTime: '',
  description: '',
  targetCompartment: '',
  budgetDuration: '',
  budgetProcessingStartDay: 1,
  message: '',
};
