import Button from 'components/Button';
import Modal from 'components/Modal';
import { BUTTON_TYPE } from 'constants/appearance';
import { useTranslation } from 'react-i18next';
import './index.scss';

type DeleteModalProps = {
  showDeleteModal: boolean;
  setShowDeleteModal: (val: boolean) => void;
  deletionFunction: () => any;
  loading?: boolean;
};

const DeleteModal = ({
  showDeleteModal,
  setShowDeleteModal,
  deletionFunction,
  loading,
}: DeleteModalProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={showDeleteModal}
        footer={null}
        closable={false}
        onCancel={() => setShowDeleteModal(false)}
        width={336}
        centered
      >
        <div className="deleteConnection flex flex-column flex-start text flex-align-items-center">
          <div>
            <span>
              <div className="modal-heading">
                {t('connectionDeleteModal.title')}
              </div>
              <div className="body table-typography">
                {t('connectionDeleteModal.description')}
              </div>
            </span>
          </div>
          <div className="button flex">
            <Button
              loading={loading}
              title={t('connectionDeleteModal.ok')}
              onClick={deletionFunction}
            />
            <Button
              type={BUTTON_TYPE.OUTLINED}
              title={t('connectionDeleteModal.cancel')}
              onClick={() => {
                setShowDeleteModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
