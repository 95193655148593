import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';
import {
  UploadCustomQueryRequestParamsType,
  ValidateQueryRequestBodyType,
  ValidateQueryRequestParamsType,
} from './types';

/**
 * @function validateQueryAndGetAvailableFields
 * @description Function to validate custom query and get available fields
 * @param body : Object containing the request body
 * @param params : Object containing the request parameters
 * @return axios response from POST request
 */
export const validateQueryAndGetAvailableFields = async (
  body: ValidateQueryRequestBodyType,
  params: ValidateQueryRequestParamsType
) => {
  return ServiceCalls.post(
    APIS.VALIDATE_CUSTOM_QUERY_AND_GET_AVAILABLE_FIELDS,
    body,
    null,
    params
  );
};

/**
 * @function createCustomQuery
 * @description Function to add custom query entry to database
 * @param body : Object containing the request body
 * @param params : Object containing the request parameters
 * @return axios response from POST request
 */
export const createCustomQuery = async (
  body: ValidateQueryRequestBodyType,
  params: UploadCustomQueryRequestParamsType
) => {
  return ServiceCalls.post(APIS.CREATE_CUSTOM_QUERY, body, null, params);
};

/**
 * @function fetchSnowflakeTables
 * @description Function to fetch available Snowflake Tables
 * @param body request params for POST request
 * @returns axios response from POST request
 */
export const fetchSnowflakeTables = async (body: any) => {
  return ServiceCalls.post(APIS.GET_SNOWFLAKE_TABLES, body);
};

/**
 * @function getAvailableFieldsForSnowflake
 * @description Function to get available fields for snowflake integrations
 * @param params Object containing the request parameters
 * @return axios response from POST request
 */
export const getAvailableFieldsForSnowflake = async (params: any) => {
  return ServiceCalls.post(
    APIS.SNOWFLAKE_GET_AVAILABLE_FIELDS,
    {},
    null,
    params
  );
};
