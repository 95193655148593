import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import {
  setSelectedBillingAccount,
  setSelectedTagConnection,
  setSelectedTagsObjective,
  setTagsAndLabelComponent,
  tagsAndLabels,
} from 'redux/tagsAndLabelsSlice';
import ProviderList from 'components/ConnectionProviderList';
import SuccessComponent from 'components/SuccessComponent';
import ErrorComponent from 'components/ErrorComponent';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { SuccessIcon } from 'assets/icons';
import { providerList } from 'redux/providerSlice';
import { PROVIDER } from 'constants/cloudProviders';
import { ERROR_KEY, REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import { TAGS_AND_LABELS_COMPONENTS, TAGS_OBJECTIVES } from './constants';
import UpdateTagsAndLabels from './components/UpdateTagsAndLabels';
import SelectTagLabelConnection from './components/SelectTagLabelConnection';
import ApplyTagsAndLabels from './components/ApplyTagsAndLabels';
import { exportTagsAndLabels } from './exportTagsAndLabels';
import { exportAzureTagsAndLabels, exportGCPTagsAndLabels } from './services';
import { AzureSubscriptionTagsType, ProjectsTagsType } from './types';

const TagsAndLabelsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    tagsAndLabelComponent,
    selectedTagsObjective,
    selectedTagConnection,
    selectedBillingAccount,
  } = useSelector(tagsAndLabels);
  const { selectedProvider } = useSelector(providerList);

  const [downloadSchemaStatus, setDownloadSchemaStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    dispatch(
      setTagsAndLabelComponent(TAGS_AND_LABELS_COMPONENTS.SELECT_PROVIDER)
    );
  }, []);

  /**
   * @function onHandleErrorFetchingTags
   * @description Function to handle error while fetching tags and labels
   * @param e error message
   */
  const onHandleErrorFetchingTags = (e: any) => {
    onApiCallError(
      e,
      true,
      setDownloadSchemaStatus,
      e.response.data.message ?? t('tagsOrLabels.errorFetchingSchema')
    );
  };

  /**
   * @function fetchAllTagsAndLabels
   * @description Function to fetch all the tags for export and download excel file
   */
  const fetchAllTagsAndLabels = () => {
    setDownloadSchemaStatus(REQUEST_STATUS.PROCESSING);
    if (selectedProvider === PROVIDER.GCP) {
      const params = {
        connectorId: selectedTagConnection?.connectorId,
        billingAccountId: selectedBillingAccount,
      };

      exportGCPTagsAndLabels(params)
        .then((res: any) => {
          if (res?.status === 200) {
            const tags: ProjectsTagsType[] =
              res?.data?.responseData?.projectLabelsDtoList || [];
            downloadGcpExcelFile(tags);
            return;
          }
          message.error({
            content:
              res?.data?.message || t('tagsOrLabels.errorFetchingSchema'),
            key: ERROR_KEY,
          });
          setDownloadSchemaStatus(REQUEST_STATUS.ERROR);
        })
        .catch((e) => {
          onHandleErrorFetchingTags(e);
        });
    } else if (selectedProvider === PROVIDER.AZURE) {
      const params = {
        connectorId: selectedTagConnection?.connectorId,
      };
      exportAzureTagsAndLabels(params)
        .then((res: any) => {
          if (res?.status === 200) {
            downloadAzureExcelFile(res?.data?.responseData || []);
            return;
          }
          message.error({
            content:
              res?.data?.message || t('tagsOrLabels.errorFetchingSchema'),
            key: ERROR_KEY,
          });
          setDownloadSchemaStatus(REQUEST_STATUS.ERROR);
        })
        .catch((e) => {
          onHandleErrorFetchingTags(e);
        });
    }
  };

  /**
   * @function onHandleErrorDownloadExcelFile
   * @description Function to handle error while downloading excel file
   */
  const onHandleErrorDownloadExcelFile = () => {
    message.error({
      content: t('tagsOrLabels.errorDownloadingFile'),
      key: ERROR_KEY,
    });
    setDownloadSchemaStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function downloadGcpExcelFile
   * @description Function to download the excel file
   * @param tags Tags that needs to be added in the downloaded excel file.
   */
  const downloadGcpExcelFile = (tags: ProjectsTagsType[]) => {
    exportTagsAndLabels(
      selectedTagConnection?.name ?? '',
      tags.map((tag) => getGcpKeyValueTags(tag)),
      [{ key: 'projectId', mandatory: true }, ...tags[0].labelValues]
    )
      .then(() => setDownloadSchemaStatus(REQUEST_STATUS.SUCCESS))
      .catch(() => {
        onHandleErrorDownloadExcelFile();
      });
  };

  /**
   * @function downloadAzureExcelFile
   * @description Function to download the excel file
   * @param tags Tags that needs to be added in the downloaded excel file.
   */
  const downloadAzureExcelFile = (tags: AzureSubscriptionTagsType) => {
    const data: any = {
      subscriptionId: tags.subscriptionId,
    };
    tags.labelValues.forEach((label) => {
      data[label.key] = label.value;
    });

    exportTagsAndLabels(
      selectedTagConnection?.name ?? '',
      [data],
      [{ key: 'subscriptionId', mandatory: true }, ...tags.labelValues]
    )
      .then(() => setDownloadSchemaStatus(REQUEST_STATUS.SUCCESS))
      .catch(() => {
        onHandleErrorDownloadExcelFile();
      });
  };

  /**
   * @function getGcpKeyValueTags
   * @description Function to return key value map of tags for a project
   * @param tag Object containing the list of tags of a project
   * @returns key value map of all the tags
   */
  const getGcpKeyValueTags = (tag: ProjectsTagsType) => {
    let obj: { [key: string]: string | null | undefined } = {};
    obj.projectId = tag.projectId;
    tag.labelValues.forEach((eachKeyValue) => {
      obj[eachKeyValue.key] = eachKeyValue.value;
    });
    return obj;
  };

  /**
   * @function getSuccessComponentProps
   * @description Function to the props values for success page depending on the option selected.
   * @returns Object containing the props values
   */
  const getSuccessComponentProps = () => {
    if (selectedTagsObjective === TAGS_OBJECTIVES.APPLY_TAGS) {
      return {
        maintitle: t('tagsOrLabels.successPageLabels.successfullyConnected'),
        subTitle: t('tagsOrLabels.successPageLabels.applyTagsSubTilte'),
        buttonTitle: t('tagsOrLabels.successPageLabels.goToDashboard'),
        onHandleButtonClick: () =>
          navigate(NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS),
        loadingButton: false,
      };
    }

    return {
      maintitle: t('tagsOrLabels.successPageLabels.successFullySetup'),
      subTitle: t('tagsOrLabels.successPageLabels.setupSchemaSubTitle'),
      buttonTitle: t('tagsOrLabels.successPageLabels.downloadSchema'),
      onHandleButtonClick: fetchAllTagsAndLabels,
      loadingButton: downloadSchemaStatus === REQUEST_STATUS.PROCESSING,
      linkTitle: t('tagsOrLabels.createTagSchema'),
      onHandleLinkClick: () => {
        dispatch(
          setTagsAndLabelComponent(TAGS_AND_LABELS_COMPONENTS.SELECT_PROVIDER)
        );
        dispatch(setSelectedTagConnection(undefined));
        dispatch(setSelectedBillingAccount(''));
      },
    };
  };

  /**
   * @function getTagsLabelsComponent
   * @description Function to return the component based on the selection
   * @returns component to display
   */
  const getTagsLabelsComponent = () => {
    const {
      maintitle,
      subTitle,
      buttonTitle,
      onHandleButtonClick,
      loadingButton,
      linkTitle,
      onHandleLinkClick,
    } = getSuccessComponentProps();

    switch (tagsAndLabelComponent) {
      case TAGS_AND_LABELS_COMPONENTS.SELECT_PROVIDER:
        return (
          <div className="page-content vertical-margin-24">
            <ProviderList
              disabledList={[PROVIDER.AWS, PROVIDER.OCI]}
              handleContinueButtonClick={() => {
                dispatch(
                  setTagsAndLabelComponent(
                    TAGS_AND_LABELS_COMPONENTS.SELECT_CONNECTION
                  )
                );
                dispatch(setSelectedTagConnection(undefined));
                dispatch(setSelectedBillingAccount(''));
                dispatch(
                  setSelectedTagsObjective(TAGS_OBJECTIVES.CREATE_TAG_SCHEMA)
                );
              }}
            />
          </div>
        );
      case TAGS_AND_LABELS_COMPONENTS.SELECT_CONNECTION:
        return (
          <SelectTagLabelConnection
            downloadSchema={fetchAllTagsAndLabels}
            downloadSchemaStatus={downloadSchemaStatus}
          />
        );
      case TAGS_AND_LABELS_COMPONENTS.EDIT_TAGS_LABELS:
        return <UpdateTagsAndLabels />;
      case TAGS_AND_LABELS_COMPONENTS.APPLY_TAGS_LABELS:
        return <ApplyTagsAndLabels />;
      case TAGS_AND_LABELS_COMPONENTS.SUCCESS_PAGE:
        return (
          <SuccessComponent
            mainTitle={maintitle}
            subTitle={subTitle}
            buttonTitle={buttonTitle}
            icon={<SuccessIcon />}
            onHandleButtonClick={onHandleButtonClick}
            loadingButton={loadingButton}
            linkTitle={linkTitle}
            onHandleLinkClick={onHandleLinkClick}
          />
        );

      case TAGS_AND_LABELS_COMPONENTS.ERROR_PAGE:
        return (
          <ErrorComponent
            mainTitle={t('tagsOrLabels.errorPageLabels.error')}
            subTitle={t('tagsOrLabels.errorPageLabels.errorMessage')}
            buttonTitle={t('tagsOrLabels.errorPageLabels.tryAgain')}
            onHandleButtonClick={() =>
              dispatch(
                setTagsAndLabelComponent(
                  TAGS_AND_LABELS_COMPONENTS.APPLY_TAGS_LABELS
                )
              )
            }
          />
        );
    }
  };
  return <>{getTagsLabelsComponent()}</>;
};

export default TagsAndLabelsPage;
