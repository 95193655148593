import i18n from 'i18n';

import {
  ImportFileDataType,
  SchemaDefinitionType,
} from 'pages/StaticFilesPage/types';
import { isEmptyField, validateAlphanumericNames } from 'utils/validations';
import { DROPDOWN_NONE } from 'components/Select';

/**
 * @function validateFieldName
 * @description Function to validate the field name for invalid formats and duplicates
 * @param value String value to be validated
 * @param position Position of the row in the schema definition list
 * @param schemaDefinition List of schema definitions for duplicate validations
 * @param setValidationMessage Optional validation message setter
 * @return Returns a boolean true if the validation is successful, else false
 */
export const validateFieldName = (
  value: string,
  position: number,
  schemaDefinition: SchemaDefinitionType[],
  setValidationMessage?: (val: string) => void
) => {
  if (isEmptyField(value)) {
    setValidationMessage?.('');
    return true;
  }

  if (
    !validateAlphanumericNames(
      value,
      i18n.t('importFileLabels.formLabels.step3.field'),
      setValidationMessage
    )
  ) {
    return false;
  }

  if (
    schemaDefinition.some(
      (schemaRow, index) => schemaRow.name === value && index !== position
    )
  ) {
    setValidationMessage?.(
      i18n.t('importFileLabels.formLabels.step3.duplicateSchemaFieldMessage')
    );
    return false;
  }

  setValidationMessage?.('');
  return true;
};

/**
 * @function getStandardSchemaFieldsDropdownOptions
 * @description Function to construct the dropdown values for standard schema mapping
 * @param standardSchema List of standard schema to be shown
 * @param importFileData Object containing the import file details added
 * @return Returns a list containing the standard schema options with None as one of the options
 */
export const getStandardSchemaFieldsDropdownOptions = (
  standardSchema: SchemaDefinitionType[],
  importFileData: ImportFileDataType
) => {
  const schemaFields = standardSchema.map((item) => ({
    label: item.name,
    value: item.name,
    disabled: importFileData.schemaDefinition.some(
      (schemaRow) => schemaRow.mappedFieldName === item.name
    ),
  }));

  const NoneField = {
    label: i18n.t('none'),
    value: DROPDOWN_NONE,
    disabled: false,
  };
  return [NoneField, ...schemaFields];
};
