import i18n from 'i18n';
import moment from 'moment';
import {
  AwsRecommendationListType,
  ViewListType,
} from 'pages/CostOptimizationInsightsPage/types';
import { addIndexAndCommaSeparator } from 'pages/CostOptimizationInsightsPage/utils';
import { FilterKeys } from 'pages/CostOptimizationInsightsPage/constants';
import { ServiceNowStatus } from 'constants/workflowIntegrations';
import {
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
} from 'constants/requestBody';
import { FilterGroupType, FilterType } from 'types/dashboard';
import { RulesetFilterGroupsType } from 'types/dataTypes';
import { HYPHEN_DATE_FORMAT } from 'utils/date';
import {
  AWS_CHECKS_MAP,
  AWS_CHECK_IDS,
  AWSRecommendationSummaryColumns,
  CHECK_NAME_ID_MAP,
} from 'constants/recommendations';
import { AWS_STATUS_FILTER_KEY_MAP } from './constants';

/**
 * @function getFilterGroupObjectByFilterKey
 * @description Function to construct the filter object for a group based on the key and value.
 * @param key string filter key
 * @param value: array of string values
 * @param costField Cost Field used for cost savings filter
 * @returns array of filter object
 */
const getFilterGroupObjectByFilterKey = (
  key: string,
  value: string[],
  costField: string
) => {
  switch (key) {
    case FilterKeys.REGION:
      return value.map((val) => {
        return {
          field: QUERY_FIELDS.REGION,
          comparator: COMPARATORS.EQUALS,
          value: val,
          conjunctToNextFilter: CONJUNCTIONS.OR,
        };
      });

    case FilterKeys.STATUS:
      return value.map((val) => {
        return {
          field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
          comparator: COMPARATORS.EQUALS,
          value:
            AWS_STATUS_FILTER_KEY_MAP.find((map) => map.key === val)?.label ??
            '',
          conjunctToNextFilter: CONJUNCTIONS.OR,
        };
      });

    case FilterKeys.LAST_REFRESH_DATE_RANGE:
      return [
        {
          field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: `#TIMESTAMP '${moment(value[0].split(',')[0]).format(
            HYPHEN_DATE_FORMAT
          )} 00:00:00'`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
        {
          field: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: `#TIMESTAMP '${moment(value[0].split(',')[1])
            .hour(23)
            .minute(59)
            .second(59)
            .format(HYPHEN_DATE_FORMAT)} 00:00:00'`,
          conjunctToNextFilter: CONJUNCTIONS.AND,
        },
      ];

    case FilterKeys.COST_SAVINGS:
      return value.map((commaJoinedValue) => {
        const costSavingsValues = commaJoinedValue.split(',');
        return {
          field: costField,
          comparator: costSavingsValues[0],
          value: '#' + costSavingsValues[1],
          conjunctToNextFilter: costSavingsValues[2],
        };
      });
  }
};

/**
 * @function getQueryFilters
 * @description Function to construct the filters for query.
 * @param currentRecommendationView selected filter view
 * @param costField Cost Field used for cost savings filter
 * @returns list of filters
 */
export const getQueryFiltersGroups = (
  currentRecommendationView: ViewListType,
  costField: string
) => {
  let filterGroupArray: FilterType[][] = [
    [
      {
        field: QUERY_FIELDS.ROW_NUM,
        comparator: COMPARATORS.EQUALS,
        value: '#1',
        conjunctToNextFilter: CONJUNCTIONS.AND,
      },
    ],
  ];
  currentRecommendationView.recommendationsFilterDtoList
    .filter((item) => item.key !== FilterKeys.RULESET)
    .forEach((viewFilter: any) => {
      if (viewFilter.values && viewFilter.values.length > 0) {
        const filterGroup = getFilterGroupObjectByFilterKey(
          viewFilter.key,
          viewFilter?.values?.map((item: any) => item.value) || [],
          costField
        );
        filterGroup && filterGroupArray.push(...[filterGroup]);
      }
    });

  return filterGroupArray.map((group) => {
    return { filters: group };
  });
};

/**
 * @function getDefaultRequestBodyForAwsCheckId
 * @description Function to return the default request body for recommendation table
 * @param checkId check Id unique key
 * @param currentRecommendationView selected filter view
 * @param costField Cost Field used for cost savings filter
 * @returns Object containing the request body
 */
const getDefaultRequestBodyForAwsCheckId = (
  checkId: string,
  currentRecommendationView: ViewListType,
  costField: string
) => {
  const checkIdMap = AWS_CHECKS_MAP.find((map) => map.check === checkId);
  let rownumField = `ROW_NUMBER() OVER (PARTITION BY "${checkIdMap?.uniqueKeyField}" ORDER BY status_priority DESC, datetime DESC)`;
  const daysDiff = `date_diff('day',cast(date_parse(${
    checkIdMap?.check === AWS_CHECK_IDS.RIGHT_SIZING_EC2
      ? "generationtime,'%Y-%m-%dT%H:%i:%sZ'"
      : "date,'%m-%d-%Y'"
  }) as date), current_date)`;

  return {
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: 'rownum',
          field: rownumField,
        },
      ],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.OR,
          filters: [
            {
              field: daysDiff,
              comparator: COMPARATORS.LESS_THAN,
              value: '#90',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.STATUS_PRIORITY,
              comparator: COMPARATORS.EQUALS,
              value: '#2',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
        {
          conjunctToNextGroup: CONJUNCTIONS.OR,
          filters: [
            {
              field: daysDiff,
              comparator: COMPARATORS.LESS_THAN,
              value: '#90',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.STATUS_PRIORITY,
              comparator: COMPARATORS.EQUALS,
              value: '#1',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
        {
          conjunctToNextGroup: CONJUNCTIONS.OR,
          filters: [
            {
              field: daysDiff,
              comparator: COMPARATORS.LESS_THAN,
              value: '#365',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.STATUS_PRIORITY,
              comparator: COMPARATORS.EQUALS,
              value: '#3',
              conjunctToNextFilter: CONJUNCTIONS.OR,
            },
          ],
        },
      ],
    },
    filterGroups: getQueryFiltersGroups(currentRecommendationView, costField),
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: false,
  };
};

/**
 * @function getRulesetFilteredRequestBodyForAwsCheckId
 * @description Function to return the ruleset filter request body for recommendation table
 * @param checkId check Id unique key
 * @param currentRecommendationView selected filter view
 * @param rulesetFilters ruleset filters
 * @returns Object containing the request body
 */
export const getRulesetFilteredRequestBodyForAwsCheckId = (
  checkId: string,
  currentRecommendationView: ViewListType,
  rulesetFilters: RulesetFilterGroupsType[] | undefined
) => {
  const checkIdMap = AWS_CHECKS_MAP.find((map) => map.check === checkId);
  const columns = AWSRecommendationSummaryColumns.filter(
    (item) => !item.checkIds || item.checkIds.includes(checkId as AWS_CHECK_IDS)
  );
  const filteredColumns = columns.filter(
    (item) =>
      !checkIdMap?.rulesetFiletrsAdditionalColumns?.some(
        (field) => field.label === item.key
      )
  );

  const costFieldLabel = columns.find((item) => item.costField)?.dataIndex;
  const costField =
    checkIdMap?.rulesetFiletrsAdditionalColumns?.find(
      (item) => item.label === checkIdMap.savingsCostField
    )?.field ??
    checkIdMap?.savingsCostField ??
    '';

  let filters: FilterGroupType[] = [];
  (rulesetFilters ?? [])
    ?.filter((item) => item.checkId!.toLowerCase() === checkId.toLowerCase())
    .forEach((item) => {
      filters.push(...item.filterGroups);
    });

  let additionalColumns: any = {
    accountIdFieldLabel: 'accountId',
    costFieldLabel: costFieldLabel,
    applyPriorityAndRisk: costFieldLabel !== undefined,
  };

  if (checkId.toLowerCase() === AWS_CHECK_IDS.RIGHT_SIZING_EC2.toLowerCase()) {
    additionalColumns.rightsizingRecoms = true;
    additionalColumns.currInstanceTypeFieldLabel = 'instancetype';
    additionalColumns.recommendedInstanceTypeFieldLabel =
      'recommendedinstancetype';
  }

  return {
    checkId: checkId.toLowerCase(),
    ...additionalColumns,
    recommendationsDto: {
      ...getDefaultRequestBodyForAwsCheckId(
        checkId,
        currentRecommendationView,
        costField
      ),
      columns: [
        ...filteredColumns.map((item) => {
          if (item.useDataIndexAsLabel) {
            return {
              field: item.key,
              label: item.dataIndex,
            };
          }
          return { field: item.key };
        }),
        {
          label: 'recom_status',
          field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
        },
        ...(checkIdMap?.rulesetFiletrsAdditionalColumns ?? []),
      ],
    },
    filterDto: {
      columns: [
        ...columns.map((item) => {
          if (item.useDataIndexAsLabel) {
            return { field: item.dataIndex, label: item.dataIndex };
          }

          return {
            field: item.key,
            label: item.dataIndex,
          };
        }),
        {
          label: 'recStatus',
          field: 'recom_status',
        },
      ],
      filterGroups: filters,
      dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
      cached: false,
    },
  };
};

/**
 * @function getExcelExportColumns
 * @description Function to return the excel export columns based on check id and ticket status
 * @param selectedAwsCheck selected check Id
 * @param selectedSnowStatus selected SNOW status
 * @returns List of columns
 */
const getExcelExportColumns = (
  selectedAwsCheck: string,
  selectedSnowStatus: ServiceNowStatus
) => {
  let columns: any[] = [
    ...AWSRecommendationSummaryColumns.filter(
      (item) =>
        !item.checkIds ||
        item.checkIds.includes(selectedAwsCheck as AWS_CHECK_IDS)
    ).map((column) => ({
      ...column,
      header: column.title,
      key: column.dataIndex,
    })),
  ];

  columns.splice(
    1,
    0,

    {
      header: i18n.t('costOptimizationInsight.recommendationTable.recStatus'),
      key: 'recStatus',
    }
  );

  if (
    AWSRecommendationSummaryColumns.find(
      (item) =>
        (!item.checkIds ||
          item.checkIds.includes(selectedAwsCheck as AWS_CHECK_IDS)) &&
        item.costField
    ) !== undefined
  ) {
    columns.splice(
      1,
      0,
      ...[
        {
          header: i18n.t('costOptimizationInsight.recommendationTable.risk'),
          key: 'risk',
        },
        {
          header: i18n.t(
            'costOptimizationInsight.recommendationTable.priority'
          ),
          key: 'priority',
        },
      ]
    );
  }

  if (
    [
      ServiceNowStatus.TICKET_TBC,
      ServiceNowStatus.RESOLVED,
      ServiceNowStatus.CLOSED,
    ].includes(selectedSnowStatus)
  ) {
    columns.splice(1, 0, {
      header: i18n.t(
        'costOptimizationInsight.recommendationTable.ticketStatus'
      ),
      key:
        selectedSnowStatus === ServiceNowStatus.TICKET_TBC
          ? 'serviceNowStatus'
          : 'incidentRecommendationStatus',
      width: 240,
    });
  }

  return columns;
};

/**
 * @function getAwsRecommendationTableExcelData
 * @description Function to return the excel export metadata
 * @param connectorName connection name
 * @param selectedCheckId selected check id
 * @param data Data containing the sheet name, snowstatus and the recommendation data
 * @returns List of excel export meta data
 */
export const getAwsRecommendationTableExcelData = (
  connectorName: string,
  selectedCheckId: string,
  data: {
    sheetName: string;
    snowStatus: ServiceNowStatus;
    recommendationTableData: AwsRecommendationListType[];
  }[]
) => {
  return data
    .filter((dataObj) => dataObj.recommendationTableData.length > 0)
    .map((item) => ({
      sheetName: item.sheetName,
      columns: [
        {
          header: '#',
          key: 'slNo',
          alignment: 'center',
        },
        ...getExcelExportColumns(selectedCheckId, item.snowStatus),
      ],
      data: addIndexAndCommaSeparator(item.recommendationTableData),
      filters: [
        {
          heading: i18n.t('excelExportLabels.connectionName'),
          value: connectorName,
        },
        {
          heading: i18n.t('excelExportLabels.checkName'),
          value:
            CHECK_NAME_ID_MAP.find((nameMap) => nameMap.id === selectedCheckId)
              ?.name ?? selectedCheckId,
        },
      ],
    }));
};
