import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { message } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { useDispatch, useSelector } from 'react-redux';

import { dataCenter, setComputeEntries } from 'redux/dataCenterSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import Table from 'components/Table';
import QuickActionMenu from 'components/QuickActionMenu';
import Pagination from 'components/Pagination';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { ERROR_KEY, REQUEST_STATUS } from 'constants/requestBody';
import { ComputeEntryListType } from 'pages/DataCenterPage/types';
import {
  deleteComputeResource,
  getAllComputeResources,
} from 'pages/DataCenterPage/services';
import {
  MODE_OF_PURCHASES,
  PurchaseModes,
} from 'pages/DataCenterPage/constants';
import DeleteModal from 'components/DeleteModal';
import { onApiCallError } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { MONTH_YEAR_FORMAT } from 'utils/date';

import {
  EntryQuickActions,
  ENTRY_QUICKACTIONS,
} from '../ManualEntry/constants';
import EntryTableHeader from '../EntryTableHeader';
import ComputeRowModal from '../ComputeRowModal';

const ComputeEntry = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { computeEntries, selectedDataCenter } = useSelector(dataCenter);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [totalCount, setTotalCount] = useState(0);
  const [currentComputeEntry, setCurrentComputeEntry] =
    useState<ComputeEntryListType>();
  const [showComputeRowModal, setShowComputeRowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [computeEntryDataRequestStatus, setComputeEntryDataRequestStatus] =
    useState('');
  const [
    deleteComputeResourceRequestStatus,
    setDeleteComputeResourceRequestStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    fetchComputeEntryData();
  }, []);

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    fetchComputeEntryData(page);
  };

  /**
   * @function fetchComputeEntryData
   * @description Function to fetch paginated compute entry data
   * @param page page number defaults to first page
   */
  const fetchComputeEntryData = (page: number = 1) => {
    setComputeEntryDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      dataCenterCode: selectedDataCenter?.dataCenterCode,
      page: page - 1,
      size: PAGINATION_SIZE,
    };

    getAllComputeResources(params)
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(setComputeEntries(res.data.responseData.content));
          setTotalCount(res.data.responseData.totalElements);
          setComputeEntryDataRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setComputeEntryDataRequestStatus(REQUEST_STATUS.ERROR);
        message.error({ content: t('apiCallErrorMessage'), key: ERROR_KEY });
      })
      .catch((e) => {
        onApiCallError(e, true, setComputeEntryDataRequestStatus);
      });
  };

  /**
   * @function deleteComputeEntry
   * @description Function to delete a compute entry
   */
  const deleteComputeEntry = () => {
    setDeleteComputeResourceRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      id: currentComputeEntry?.id,
    };

    deleteComputeResource(params)
      .then((res: any) => {
        if (res?.status === 200) {
          message.success(t('manualEntry.computeLabels.deleteComputeSuccess'));
          setDeleteComputeResourceRequestStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
          onSaveOrUpdateEntry();
          return;
        }
        message.error({
          content:
            res?.message ?? t('manualEntry.computeLabels.deleteComputeFailed'),
        });
        setShowDeleteModal(false);
        setDeleteComputeResourceRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteComputeResourceRequestStatus,
          e?.response?.data?.message ??
            t('manualEntry.computeLabels.deleteComputeFailed')
        );
        setShowDeleteModal(false);
      });
  };

  /**
   * @function handleQuickAction
   * @description Function to handle the quick action buttons from the table
   * @param action : The clicked action
   */
  const handleQuickAction = (action: string) => {
    switch (action) {
      case EntryQuickActions.EDIT:
        setShowComputeRowModal(true);
        break;
      case EntryQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  /**
   * @function onSaveOrUpdateEntry
   * @description Callback function for save or update compute entry
   */
  const onSaveOrUpdateEntry = () => {
    setCurrentPage(1);
    fetchComputeEntryData();
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      key: 'index',
      width: '5%',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + (index + 1),
    },
    {
      title: t('manualEntry.computeLabels.noOfVms'),
      dataIndex: 'vmCount',
      key: 'vmCount',
      width: '10%',
    },
    {
      title: t('manualEntry.computeLabels.avgvCpuPerVm'),
      dataIndex: 'vcpuPerVmInPercentage',
      key: 'vcpuPerVmInPercentage',
      width: '10%',
    },
    {
      title: t('manualEntry.computeLabels.avgMemoryPerVm'),
      dataIndex: 'ramPerVmInGB',
      key: 'ramPerVmInGB',
      width: '15%',
    },
    {
      title: t('manualEntry.computeLabels.hostOs'),
      dataIndex: 'hostOS',
      key: 'hostOS',
      width: '10%',
    },
    {
      title: t('manualEntry.computeLabels.hypervisorUsed'),
      dataIndex: 'hypervisor',
      key: 'hypervisor',
      width: '15%',
    },
    {
      title: t('manualEntry.computeLabels.modeOfPurchase'),
      dataIndex: 'modeOfPurchase',
      key: 'modeOfPurchase',
      width: '15%',
      render: (text: string) =>
        MODE_OF_PURCHASES.find((item) => item.key === text)?.label,
    },
    {
      title: t('manualEntry.computeLabels.dateOfPurchase'),
      dataIndex: 'dateOfPurchase',
      key: 'dateOfPurchase',
      width: '15%',
      render: (text: string) => moment(text).format(MONTH_YEAR_FORMAT),
    },
    {
      title: t('manualEntry.computeLabels.duration'),
      dataIndex: 'durationInMonths',
      key: 'durationInMonths',
      width: '10%',
      render: (text: string) =>
        text ? `${text} ${t('manualEntry.computeLabels.months')}` : '--',
    },
    {
      title: t('manualEntry.computeLabels.cost'),
      dataIndex: 'cost',
      key: 'cost',
      width: '10%',
      render: (_text: string, record: ComputeEntryListType) => {
        const cost =
          record.modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
            ? record.monthlyCost
            : record.totalCost;
        return `${currencySymbol} ${numberCommaSeparator(cost ?? 0)}`;
      },
    },
    {
      title: t('manualEntry.computeLabels.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '8%',
      render: (_text: string, record: any) => {
        return (
          <QuickActionMenu
            setCurrentConnectionData={() => setCurrentComputeEntry(record)}
            quickActions={ENTRY_QUICKACTIONS}
            quickActionHandler={(action: string) => {
              handleQuickAction(action);
            }}
          />
        );
      },
      fixed: 'right',
      align: 'center',
    },
  ];

  return (
    <div className="manual-entry-tab-container card flex flex-column flex-gap-16">
      <EntryTableHeader
        title={t('manualEntry.computeLabels.tableTitle')}
        onClickAddRow={() => {
          setShowComputeRowModal(true);
          setCurrentComputeEntry(undefined);
        }}
      />
      <Table
        rootClassName="manual-entry-table"
        pagination={false}
        columns={columns}
        dataSource={computeEntries.map((item, index) => ({
          key: index,
          ...item,
        }))}
        loading={computeEntryDataRequestStatus === REQUEST_STATUS.PROCESSING}
        scroll={{ y: '100%', x: '100%' }}
        designVersion2
        fillContainer
      />
      <div className="page-footer flex">
        {totalCount > PAGINATION_SIZE && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </div>
      {showComputeRowModal && (
        <ComputeRowModal
          show={showComputeRowModal}
          setShow={setShowComputeRowModal}
          onSaveOrUpdateEntry={onSaveOrUpdateEntry}
          computeEntry={currentComputeEntry}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={deleteComputeEntry}
          loading={
            deleteComputeResourceRequestStatus === REQUEST_STATUS.PROCESSING
          }
        />
      )}
    </div>
  );
};

export default ComputeEntry;
