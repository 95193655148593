import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionList } from 'types/gProfiler';
import { RootState } from './store';

export interface GCPConnection {
  connectionName: string;
  cloudConnection: string;
  serviceName: string;
  region: string;
  cluster: string;
  connectorId: string;
  file: any;
  project?: string;
}

export interface AWSConnection {
  connectionName: string;
  cloudConnection: string;
  serviceName: string;
  region: string;
  cluster: string;
  secretKey: string;
  accessKey: string;
  connectorId: string;
}

const gcpConnection: GCPConnection = {
  connectionName: '',
  cloudConnection: '',
  serviceName: '',
  region: '',
  cluster: '',
  file: undefined,
  connectorId: '',
};

const awsConnection: AWSConnection = {
  connectionName: '',
  cloudConnection: '',
  serviceName: '',
  region: '',
  cluster: '',
  accessKey: '',
  secretKey: '',
  connectorId: '',
};

export interface GProfilerConnectionState {
  gcpConnection: GCPConnection;
  awsConnection: AWSConnection;
  connectionList: ConnectionList[];
}

const initialState: GProfilerConnectionState = {
  gcpConnection,
  awsConnection,
  connectionList: [],
};

export const gProfilerConnectionSlice = createSlice({
  name: 'gProfilerConnection',
  initialState,
  reducers: {
    setGCPConnection(state, action: PayloadAction<GCPConnection>) {
      state.gcpConnection = action.payload;
    },
    setAWSConnection(state, action: PayloadAction<AWSConnection>) {
      state.awsConnection = action.payload;
    },
    setConnectionList(state, action: PayloadAction<ConnectionList[]>) {
      state.connectionList = action.payload;
    },
  },
});
export const selectGProfilerConnection = (state: RootState) =>
  state.gProfilerConnection;
export const { setGCPConnection, setAWSConnection, setConnectionList } =
  gProfilerConnectionSlice.actions;

export default gProfilerConnectionSlice.reducer;
