import { Collapse } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';

import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import { selectReport, setSelectedReportDimensions } from 'redux/reportSlice';
import Checkbox from 'components/Checkbox';
import Empty from 'components/Empty';
import {
  FieldSource,
  FIELD_SOURCE_LABELS,
  TagSelectionType,
} from 'constants/dashboard';
import {
  ChartDimensionType,
  TagsOptionsType,
} from 'pages/CreateReportPage/types';
import { AvailableFieldsType } from 'types/dashboard';

const { Panel } = Collapse;

type DimensionsWithTagsProps = {
  searchKey: string;
};

const DimensionsWithTags = ({ searchKey }: DimensionsWithTagsProps) => {
  const dispatch = useDispatch();
  const { reportDimensions, selectedReportDimensions, tagMaps } =
    useSelector(selectReport);

  /**
   * @function getTagMaps
   * @description Function to get all the selectable tags with suffix
   * @returns array of tags with suffix
   */
  const getTagMaps = () => {
    const tagsKeyValues: TagsOptionsType[] = [];
    tagMaps.forEach((tag) => {
      tagsKeyValues.push({
        tagName: tag.customTagName,
        suffix: TagSelectionType.KEY,
      });
      tagsKeyValues.push({
        tagName: tag.customTagName,
        suffix: TagSelectionType.VALUE,
      });
    });

    return tagsKeyValues.filter((item) =>
      `${item.tagName} (${item.suffix})`
        .toLowerCase()
        .includes(searchKey.toLowerCase())
    );
  };

  /**
   * @function getSelectedDimensionsCount
   * @description get selected dimensions count
   * @param source the field source
   * @returns length of selected dimensions for the given source
   */
  const getSelectedDimensionsCount = (source: FieldSource) => {
    if (source === FieldSource.TAGS) {
      return getTagMaps().filter((item) => isTagItemChecked(item)).length;
    }

    return reportDimensions.filter(
      (fieldItem) =>
        fieldItem.name.toLowerCase().includes(searchKey.toLowerCase()) &&
        fieldItem.source === source &&
        selectedReportDimensions.some(
          (eachDimension) =>
            eachDimension.dimension === fieldItem.name &&
            eachDimension.dimensionType === source
        )
    ).length;
  };

  /**
   * @function onChangeDimensionsCheckbox
   * @description Callback function for clicking the dimension checkbox
   * @param e checkbox event
   * @param fieldItem field details of the clicked item
   */
  const onChangeDimensionsCheckbox = (
    e: CheckboxChangeEvent,
    fieldItem: AvailableFieldsType
  ) => {
    let selectedValues: ChartDimensionType[];

    if (e.target.checked) {
      selectedValues = [
        ...selectedReportDimensions,
        { dimension: fieldItem.name, dimensionType: fieldItem.source },
      ];
    } else {
      selectedValues = selectedReportDimensions.filter(
        (item) =>
          item.dimension !== fieldItem.name &&
          item.dimensionType === fieldItem.source
      );
    }

    dispatch(setSelectedReportDimensions(selectedValues));
  };

  /**
   * @function onChangeTagSelection
   * @description Callback function for clicking the tag checkbox
   * @param e checkbox event
   * @param tagItem tag details of the clicked item
   */
  const onChangeTagSelection = (e: any, tagItem: TagsOptionsType) => {
    let selectedValues: ChartDimensionType[];

    if (e.target.checked) {
      selectedValues = [
        ...selectedReportDimensions,
        {
          dimension: tagItem.tagName,
          dimensionType: FieldSource.TAGS,
          tagDimensionType: tagItem.suffix,
        },
      ];
    } else {
      selectedValues = selectedReportDimensions.filter(
        (item) =>
          !(
            item.dimension === tagItem.tagName &&
            item.dimensionType === FieldSource.TAGS &&
            item.tagDimensionType === tagItem.suffix
          )
      );
    }

    dispatch(setSelectedReportDimensions(selectedValues));
  };

  /**
   * @function isTagItemChecked
   * @description Function to check if the tag is selected
   * @param tagItem tag details for which the validation is required
   * @returns boolean true if the validation is true else false
   */
  const isTagItemChecked = (tagItem: TagsOptionsType) => {
    return selectedReportDimensions.some(
      (eachDimension) =>
        eachDimension.dimension === tagItem.tagName &&
        eachDimension.dimensionType === FieldSource.TAGS &&
        eachDimension.tagDimensionType === tagItem.suffix
    );
  };

  /**
   * @function getUserSystemDimensionsComponent
   * @description Function to get the user/system dimensions list component
   * @param source the field source, user or system
   * @returns JSX element
   */
  const getUserSystemDimensionsComponent = (source: string) => {
    const dimensions = reportDimensions.filter(
      (fieldItem) =>
        fieldItem.name.toLowerCase().includes(searchKey.toLowerCase()) &&
        fieldItem.source === source
    );

    if (dimensions.length === 0) {
      return <Empty />;
    }

    return dimensions.map((fieldItem) => (
      <Checkbox
        key={fieldItem.name}
        checked={selectedReportDimensions.some(
          (eachDimension) =>
            eachDimension.dimension === fieldItem.name &&
            eachDimension.dimensionType === source
        )}
        onChange={(e) => onChangeDimensionsCheckbox(e, fieldItem)}
      >
        {fieldItem.name}
      </Checkbox>
    ));
  };

  /**
   * @function getTagMapsComponent
   * @description Function to get the tag maps list component
   * @returns JSX element
   */
  const getTagMapsComponent = () => {
    const allTags = getTagMaps();

    if (allTags.length === 0) {
      return <Empty />;
    }

    return allTags.map((tagItem) => (
      <Checkbox
        key={`${tagItem.tagName} (${tagItem.suffix})`}
        checked={isTagItemChecked(tagItem)}
        onChange={(e) => onChangeTagSelection(e, tagItem)}
      >
        {`${tagItem.tagName} (${tagItem.suffix})`}
      </Checkbox>
    ));
  };

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="collapse-arrow" />
    );

  return (
    <Collapse
      className="report-dimensions-group full-width new-styled-scroll"
      accordion={true}
      bordered={false}
      expandIcon={getExpandIcon}
      defaultActiveKey={[FieldSource.SYSTEM]}
    >
      {FIELD_SOURCE_LABELS.map((source) => (
        <Panel
          className="field-panel"
          header={
            <div className="flex flex-align-items-center flex-gap-8">
              {source.label}
            </div>
          }
          key={source.value}
          extra={
            getSelectedDimensionsCount(source.value) ? (
              <div className="font-small-bold flex flex-center values-count">
                {getSelectedDimensionsCount(source.value)}
              </div>
            ) : null
          }
        >
          <div className="flex flex-column flex-gap-8">
            {source.value !== FieldSource.TAGS
              ? getUserSystemDimensionsComponent(source.value)
              : getTagMapsComponent()}
          </div>
        </Panel>
      ))}
    </Collapse>
  );
};

export default DimensionsWithTags;
