import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { selectGranulateInsights } from 'redux/granulateInsightsSlice';
import { selectTheme } from 'redux/themeSlice';
import { useTranslation } from 'react-i18next';
import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import Button from 'components/Button';
import ExpandModal from 'components/ExpandModal';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import AccessibleDiv from 'components/AccessibleDiv';

import InstanceTable from './components/InstanceTable';
import { INSTANCE_STATUS, InstanceStatusItems } from '../../constants';
import { getInstanceTableExcelData } from '../../utils';
import {
  getInstanceTableData,
  deactivateInstancesWithHostNames,
  activateInstancesWithHostNames,
  activateInstancesWithServiceName,
  deactivateInstancesWithServiceName,
} from '../../services';

import './index.scss';

const InstancePage = () => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);

  const { selectedService, granulateServicesReqStatus } = useSelector(
    selectGranulateInsights
  );

  const [selectedStatus, setSelectedStatus] = useState<INSTANCE_STATUS>(
    INSTANCE_STATUS.ALL
  );
  const [selectedInstances, setSelectedInstances] = useState<any[]>([]);
  const [showExpandedGraphModal, setShowExpandedGraphModal] = useState(false);

  const [instanceData, setInstanceData] = useState<any[]>([]);
  const [filteredInstanceData, setFilteredInstanceData] = useState<any[]>([]);
  const [instanceDataRequestStatus, setInstanceDataRequestStatus] =
    useState<string>(REQUEST_STATUS.SUCCESS);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [showActivateConfirmModal, setShowActivateConfirmModal] =
    useState(false);
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] =
    useState(false);
  const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    fetchInstanceTableData();
    setSelectedInstances([]);
  }, []);

  useEffect(() => {
    filterDataBasedOnStatus();
  }, [instanceData]);

  useEffect(() => {
    fetchInstanceTableData();
    resetTableProperties();
  }, [selectedStatus, selectedService]);

  /**
   * @function filterDataBasedOnStatus
   * @description function to filter data based on selected status
   */
  const filterDataBasedOnStatus = () => {
    const filteredData =
      selectedStatus === INSTANCE_STATUS.ALL
        ? instanceData
        : instanceData.filter(
            (data) => data.status.toLowerCase() === selectedStatus.toLowerCase()
          );
    setFilteredInstanceData(filteredData);
  };

  const fetchInstanceTableData = () => {
    setInstanceData([]);
    if (selectedService?.serviceName) {
      setInstanceDataRequestStatus(REQUEST_STATUS.PROCESSING);
      const params: { service: string; status?: string } = {
        service: selectedService.serviceName,
      };

      getInstanceTableData(params)
        .then((res: any) => {
          if (res.status === 200) {
            const list = res.data.map((item: any, index: number) => {
              return {
                key: (index + 1).toString(),
                instanceName: item.hostname,
                agentVersion: item.version,
                status: item.status,
                instanceStatus: item.instance_status,
              };
            });
            setInstanceData(list);
            filterDataBasedOnStatus();
            setInstanceDataRequestStatus(REQUEST_STATUS.SUCCESS);
          } else {
            setInstanceDataRequestStatus(REQUEST_STATUS.ERROR);
          }
        })
        .catch((e) => {
          onApiCallError(e, false, setInstanceDataRequestStatus);
        });
    }
  };

  const isSelectAll =
    selectedRowKeys.length > 0
      ? selectedRowKeys.length === instanceData.length
      : false;

  /**
   * @function resetTableProperties
   * @description Function to reset selected rows and instances
   */
  const resetTableProperties = () => {
    setSelectedRowKeys([]);
    setSelectedInstances([]);
  };

  /**
   * @function onStatusUpdateError
   * @description Function to handle the error scenario on status update
   * @param errorMessage Optional message to display when error.
   */
  const onStatusUpdateError = (errorMessage?: string) => {
    setShowDeactivateConfirmModal(false);
    setShowActivateConfirmModal(false);
    setRequestStatus(REQUEST_STATUS.ERROR);
    resetTableProperties();
    message.error({
      content:
        errorMessage ?? t('granulateInsightDashboard.errorUpdatingStatus'),
      key: ERROR_KEY,
    });
  };

  /**
   * @function handelActivateDeactivate
   * @description Function to handle activate and deactivate of instances
   * @param body contains request body or request params
   * @param apiCall Api call function for activate or deactivate
   */
  const handelActivateDeactivate = (
    body: any,
    apiCall: (body: any) => Promise<unknown>
  ) => {
    apiCall(body)
      .then((res: any) => {
        if (res?.status === 200) {
          setShowDeactivateConfirmModal(false);
          setShowActivateConfirmModal(false);
          message.success({
            content: t('granulateInsightDashboard.successUpdatingStatus'),
            key: SUCCESS_KEY,
          });
          setRequestStatus(REQUEST_STATUS.SUCCESS);
          resetTableProperties();
          fetchInstanceTableData();
        } else {
          resetTableProperties();
          onStatusUpdateError(res.data.Message);
        }
      })
      .catch((e) => {
        onApiCallError(e, false);
        onStatusUpdateError(e?.response?.data?.message);
      });
  };

  /**
   * @function onClickDeactivate
   * @description Function to deactivate selected instances
   * @param hostNames to pass in the body in case of multiple deactivations.
   */
  const onClickDeactivate = (hostNames: string[]) => {
    if (selectedService?.serviceName) {
      const body = {
        service: selectedService.serviceName,
        hostNames,
      };
      setRequestStatus(REQUEST_STATUS.PROCESSING);

      const apiCall = isSelectAll
        ? deactivateInstancesWithServiceName
        : deactivateInstancesWithHostNames;
      const reqBody = isSelectAll ? { service: body.service } : body;

      handelActivateDeactivate(reqBody, apiCall);
    }
  };

  /**
   * @function onClickActivate
   * @description Function to activate selected instances
   * @param hostNames to pass in the body in case of multiple deactivations.
   */
  const onClickActivate = (hostNames: string[]) => {
    if (selectedService?.serviceName) {
      const body = {
        service: selectedService.serviceName,
        hostNames,
      };
      setRequestStatus(REQUEST_STATUS.PROCESSING);

      const apiCall = isSelectAll
        ? activateInstancesWithServiceName
        : activateInstancesWithHostNames;
      const reqBody = isSelectAll
        ? {
            service: body.service,
            activeAgentsPercentage: 100,
          }
        : body;

      handelActivateDeactivate(reqBody, apiCall);
    }
  };

  /**
   * @function getExcelData
   * @description Function to group data based on status
   * @return Grouped data
   */
  const getExcelData = () => {
    const convertedData: any[] = [];

    const sheetAllData = {
      sheetName: t('granulateInsightDashboard.all'),
      tableData: instanceData,
    };
    convertedData.push(sheetAllData);

    [...InstanceStatusItems].slice(1).forEach((status) => {
      const sheetData = {
        sheetName: status.label,
        tableData: instanceData.filter(
          (item) => item.status.toLowerCase() === status.key.toLowerCase()
        ),
      };
      convertedData.push(sheetData);
    });

    return convertedData;
  };

  const StatusBar = (
    <div className="status-bar font-caption-bold flex flex-align-items-center flex-gap-16">
      <span>{t('granulateInsightDashboard.status')}</span>
      <div className="flex status-bar-menu">
        {InstanceStatusItems.map((status) => (
          <AccessibleDiv
            className={`status-bar-menu-item cursor-pointer ${
              status.key === selectedStatus && 'selected'
            }`}
            key={status.key}
            onClick={() => {
              setSelectedStatus(status.key);
            }}
          >
            {status.label}
          </AccessibleDiv>
        ))}
      </div>
      <div className="flex flex-gap-8">
        <Button
          title={`${
            isSelectAll
              ? t('granulateInsightDashboard.activateAll')
              : t('granulateInsightDashboard.activate')
          }`}
          size={BUTTON_SIZE.SMALL}
          disabled={
            selectedStatus === INSTANCE_STATUS.ACTIVE ||
            selectedInstances.length === 0 ||
            selectedInstances.some((data: any) => data.status === 'active')
          }
          onClick={() => {
            setShowActivateConfirmModal(true);
          }}
        />
        <Button
          title={`${
            isSelectAll
              ? t('granulateInsightDashboard.deactivateAll')
              : t('granulateInsightDashboard.deactivate')
          }`}
          type={BUTTON_TYPE.NEUTRAL}
          size={BUTTON_SIZE.SMALL}
          disabled={
            selectedStatus === INSTANCE_STATUS.PASSIVE ||
            selectedInstances.length === 0 ||
            selectedInstances.some((data: any) => data.status === 'passive')
          }
          onClick={() => {
            setShowDeactivateConfirmModal(true);
          }}
        />
      </div>
    </div>
  );

  const getComponent = () => (
    <div className="instance-wrapper flex flex-column full-height">
      <GraphHeader
        heading={`${t('granulateInsightDashboard.instances')}`}
        graphName="instance-table"
        isDownloadable={true}
        showExpandIcon={true}
        setShowExpandedGraph={setShowExpandedGraphModal}
        designVersion2
        excelData={{
          multipleSheets: true,
          sheets: getInstanceTableExcelData(
            selectedService?.serviceDisplayName ?? '',
            getExcelData()
          ),
        }}
        additionalComponents={{
          prefix: [
            <Icon
              iconName={ICONS.REFRESH_LINE}
              onClick={() => fetchInstanceTableData()}
              size={ICONS_SIZE.ONE_X}
              color={theme.buttonIconColor}
              key="refresh-instance"
            />,
          ],
        }}
      />
      {StatusBar}
      <InstanceTable
        setSelectedInstances={setSelectedInstances}
        selectedInstances={selectedInstances}
        data={filteredInstanceData}
        loading={[
          instanceDataRequestStatus,
          granulateServicesReqStatus,
        ].includes(REQUEST_STATUS.PROCESSING)}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        isSelectAll={isSelectAll}
        onClickDeactivate={onClickDeactivate}
        onClickActivate={onClickActivate}
        showActivateConfirmModal={showActivateConfirmModal}
        setShowActivateConfirmModal={setShowActivateConfirmModal}
        showDeactivateConfirmModal={showDeactivateConfirmModal}
        setShowDeactivateConfirmModal={setShowDeactivateConfirmModal}
        loadModel={requestStatus === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );

  return (
    <>
      {getComponent()}
      <ExpandModal
        show={showExpandedGraphModal}
        onClose={() => setShowExpandedGraphModal(!showExpandedGraphModal)}
        graphContent={getComponent()}
      />
    </>
  );
};

export default InstancePage;
