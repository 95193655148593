import { MonthlyCostByCompartmentType } from 'types/dataTypes';

/**
 * @function getUniqueCompartments
 * @description Function to return unique data of compartments cost (Will return only the first entry of the cost for each compartment)
 * @param compartmentData List of compartment cost by month (only the first entry of each compartment)
 * @return List of compartment cost
 */
export const getUniqueCompartments = (
  compartmentData: MonthlyCostByCompartmentType[]
) => {
  const compartments: MonthlyCostByCompartmentType[] = [];
  compartmentData.forEach((item) => {
    if (!compartments.some((entry) => entry.compartment === item.compartment)) {
      compartments.push(item);
    }
  });
  return compartments;
};
