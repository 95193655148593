import i18n from 'i18n';

export enum DATE_PICKER_TYPE {
  RANGE_PICKER,
  DATE_PICKER,
  CUSTOM_RANGE_PICKER,
}

export enum DateRangePresetOptions {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  CUSTOM = 'CUSTOM',
}

export const DATE_RANGE_PRESET_OPTIONS = [
  {
    label: i18n.t('datePickerLabels.lastNDays', { noOfDays: 7 }),
    days: 7,
    key: DateRangePresetOptions.LAST_7_DAYS,
  },
  {
    label: i18n.t('datePickerLabels.lastNDays', { noOfDays: 30 }),
    days: 30,
    key: DateRangePresetOptions.LAST_30_DAYS,
  },
  {
    label: i18n.t('datePickerLabels.lastNDays', { noOfDays: 90 }),
    days: 90,
    key: DateRangePresetOptions.LAST_90_DAYS,
  },
  {
    label: i18n.t('datePickerLabels.customRange'),
    key: DateRangePresetOptions.CUSTOM,
  },
];
