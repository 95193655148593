import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { cmdbSetup, setCmdbSetupConnection } from 'redux/cmdbSetupSlice';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import FieldValidationMessage from 'components/FieldValidationMessage';
import { validateByRegex, validateEmptyField } from 'utils/validations';

import { CONNECTION_URL_REGEX } from './constants';

const CmdbAccessDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cmdbSetupConnection, isEditConnection } = useSelector(cmdbSetup);

  const [connectionUrlValidation, setConnectionUrlValidation] = useState('');
  const [usernameValidation, setUsernameValidation] = useState('');
  const [pwdValidation, setPwdValidation] = useState('');
  const [databaseValidation, setDatabaseValidation] = useState('');

  const validateConnectionUrl = (value: string) => {
    if (
      validateEmptyField(value, t('connectionUrl'), setConnectionUrlValidation)
    ) {
      return false;
    }

    return validateByRegex(
      value,
      CONNECTION_URL_REGEX,
      t('connectionUrl'),
      setConnectionUrlValidation,
      t('connectionUrlFormatError')
    );
  };

  return (
    <div className="flex flex-column flex-gap-16">
      <div>
        <FormLabel
          title={t('connectionUrl')}
          required
          disabled={isEditConnection} // Disable for the edit flow
        />
        <Input
          placeholder={t('connectionUrlPlaceholder')}
          value={cmdbSetupConnection!.connectionUrl}
          onChange={(e: any) => {
            dispatch(
              setCmdbSetupConnection({
                ...cmdbSetupConnection!,
                connectionUrl: e.target.value,
              })
            );
            validateConnectionUrl(e.target.value);
          }}
          onBlur={(e: any) => validateConnectionUrl(e.target.value)}
          disabled={isEditConnection} // Disable for the edit flow
        />
        <FieldValidationMessage message={connectionUrlValidation} />
      </div>
      {!isEditConnection && (
        <>
          <div>
            <FormLabel title={t('username')} required />
            <Input
              placeholder={t('enterUsername')}
              value={cmdbSetupConnection!.username}
              onChange={(e: any) => {
                dispatch(
                  setCmdbSetupConnection({
                    ...cmdbSetupConnection!,
                    username: e.target.value,
                  })
                );
                validateEmptyField(
                  e.target.value,
                  t('username'),
                  setUsernameValidation
                );
              }}
              onBlur={(e: any) =>
                validateEmptyField(
                  e.target.value,
                  t('username'),
                  setUsernameValidation
                )
              }
            />
            <FieldValidationMessage message={usernameValidation} />
          </div>
          <div>
            <FormLabel title={t('password')} required />
            <Input
              type="password"
              placeholder={t('enterPassword')}
              value={cmdbSetupConnection!.password}
              onChange={(e: any) => {
                dispatch(
                  setCmdbSetupConnection({
                    ...cmdbSetupConnection!,
                    password: e.target.value,
                  })
                );
                validateEmptyField(
                  e.target.value,
                  t('password'),
                  setPwdValidation
                );
              }}
              onBlur={(e: any) =>
                validateEmptyField(
                  e.target.value,
                  t('password'),
                  setPwdValidation
                )
              }
            />
            <FieldValidationMessage message={pwdValidation} />
          </div>
        </>
      )}
      <div>
        <FormLabel title={t('database')} required />
        <Input
          placeholder={t('enterDatabase')}
          value={cmdbSetupConnection!.database}
          onChange={(e: any) => {
            dispatch(
              setCmdbSetupConnection({
                ...cmdbSetupConnection!,
                database: e.target.value,
              })
            );
            validateEmptyField(
              e.target.value,
              t('database'),
              setDatabaseValidation
            );
          }}
          onBlur={(e: any) =>
            validateEmptyField(
              e.target.value,
              t('database'),
              setDatabaseValidation
            )
          }
        />
        <FieldValidationMessage message={databaseValidation} />
      </div>
    </div>
  );
};

export default CmdbAccessDetails;
