import moment from 'moment';
import i18n from 'i18n';

import { store } from 'redux/store';
import { ReportPermissions } from 'pages/ReportsPage/constants';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
} from 'constants/requestBody';
import { ConnectionProviderType, FilterGroupType } from 'types/dashboard';
import { PROVIDER } from 'constants/cloudProviders';
import {
  CUSTOM_DASHBOARD_TYPES,
  FieldSource,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import {
  DATE_FORMAT,
  HYPHEN_DATE_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { replaceAllSpecialCharactersBy } from 'utils/dataFormatterUtils';
import { getQueryTagField, getTagsQueryFilters } from 'utils/dashboardUtils';

import { ReportsViewType } from './constants';
import { ChartDimensionType } from './types';

/**
 * @function isEmptyDatasource
 * @description Function to check if the datasource options selection is is empty
 * @param isTableView is the view table view
 * @returns boolean true if empty else false
 */
export const isEmptyDatasource = (isTableView: boolean) => {
  const state = store.getState();
  const reportSlice = state.report;
  const { reportOptions } = reportSlice;

  if (isTableView) {
    return reportOptions.dimension.length + reportOptions.metric.length === 0;
  }

  return (
    reportOptions.chartDimension.length + reportOptions.chartMetric.length === 0
  );
};

/**
 * @function getReportFieldCategory
 * @description Function to return the report category by name
 * @param field name of the field
 * @returns category of the field provided
 */
export const getReportFieldCategory = (field: string) => {
  const state = store.getState();
  const reportSlice = state.report;
  const { reportDatasource } = reportSlice;

  return reportDatasource.availableFields?.find((item) => item.name === field)
    ?.category;
};

/**
 * @function getFieldByLabel
 * @description Function to fetch the field by the label by provider
 * @param label Label for which the field is fetched
 * @param connectionDetails connection details for which the label is fetched
 * @param dimensionDetails optional dimension details for a dimension label
 * @returns string query field
 */
const getFieldByLabel = (
  label: string,
  connectionDetails: ConnectionProviderType,
  dimensionDetails?: ChartDimensionType
) => {
  const state = store.getState();
  const reportSlice = state.report;
  const {
    reportGroupAvailableFields,
    typeOfConnection,
    reportDatasource,
    tagMaps,
    tagKeyValues,
  } = reportSlice;

  if (dimensionDetails?.dimensionType === FieldSource.TAGS) {
    return getQueryTagField(
      dimensionDetails,
      connectionDetails,
      tagKeyValues,
      tagMaps
    );
  }

  if (isAConnectionWithBillingDatasource()) {
    return reportDatasource.availableFields?.find(
      (item) => item.name === label && item.source !== FieldSource.TAGS
    )?.field;
  }

  if (typeOfConnection !== MY_DASHBOARD_TYPES.GROUP) {
    return label;
  }

  return reportGroupAvailableFields.find((item) => item.label === label)
    ?.fields[
    connectionDetails.provider as PROVIDER.GCP | PROVIDER.AWS | PROVIDER.AZURE
  ];
};

/**
 * @function getBillingDateFieldAndFormatByProvider
 * @description Function to return the date fields and format of the date for billing dataset
 * @param provider Provider for which the date fields are required
 * @returns Object containing the start and end date fields and format of the date
 */
const getBillingDateFieldAndFormatByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.GCP:
      return {
        dateStartField: QUERY_FIELDS.INVOICE_MONTH,
        dateEndField: QUERY_FIELDS.INVOICE_MONTH,
        dateFormat: YEAR_MONTH_WITHOUT_SEPARATOR,
      };

    case PROVIDER.AWS:
      return {
        dateStartField: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
        dateEndField: QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
        dateFormat: `[#DATE(']${HYPHEN_DATE_FORMAT}[')]`,
      };

    case PROVIDER.AZURE:
      return {
        dateStartField: QUERY_FIELDS.BILLING_PERIOD_START_DATE,
        dateEndField: QUERY_FIELDS.BILLING_PERIOD_END_DATE,
        dateFormat: HYPHEN_DATE_FORMAT,
      };
  }
};

/**
 * @function getRecommendationDateFieldAndFormatByProvider
 * @description Function to return the date fields and format of the date for recommendation dataset
 * @param provider Provider for which the date fields are required
 * @returns Object containing the start and end date fields and format of the date
 */
const getRecommendationDateFieldAndFormatByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.GCP:
      return {
        dateStartField: QUERY_FIELDS.LAST_REFRESH_TIME,
        dateEndField: QUERY_FIELDS.LAST_REFRESH_TIME,
        dateFormat: HYPHEN_DATE_FORMAT,
      };

    case PROVIDER.AWS:
      return {
        dateStartField: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
        dateEndField: QUERY_FIELDS.DATE_PARSE_DATE_TIME,
        dateFormat: `[#TIMESTAMP ']${HYPHEN_DATE_FORMAT} [00:00:00']`,
      };
  }
};

/**
 * @function getCarbonFootprintDateFieldAndFormatByProvider
 * @description Function to return the date fields and format of the date for carbon footprint dataset
 * @returns Object containing the start and end date fields and format of the date
 */
const getCarbonFootprintDateFieldAndFormatByProvider = () => {
  // Currently for GCP only
  return {
    dateStartField: QUERY_FIELDS.USAGE_MONTH,
    dateEndField: QUERY_FIELDS.USAGE_MONTH,
    dateFormat: HYPHEN_DATE_FORMAT,
  };
};

/**
 * @function getDateFieldsAndFormatByDashboardTypeAndProvider
 * @description Function to return the date fields and format of the date by dashboardType and provider
 * @param dashboardType type of dashboard for which the date fields required
 * @param provider Provider for which the date fields are required
 * @returns Object containing the start and end date fields and format of the date
 */
const getDateFieldsAndFormatByDashboardTypeAndProvider = (
  dashboardType: string,
  provider: string
) => {
  switch (dashboardType) {
    case CUSTOM_DASHBOARD_TYPES.BILLING_DEFAULT:
    case DASHBOARD_TYPES.BILLING:
      return getBillingDateFieldAndFormatByProvider(provider);

    case CUSTOM_DASHBOARD_TYPES.RECOMMENDATIONS_DEFAULT:
      return getRecommendationDateFieldAndFormatByProvider(provider);

    case CUSTOM_DASHBOARD_TYPES.CARBON_FOOTPRINT_DEFAULT:
      return getCarbonFootprintDateFieldAndFormatByProvider();
  }
};

/**
 * @function modifyDimensionLabelForCustomQuery
 * @description Function to modify the dimension labels for query
 * @param dimension Dimension details for a dimension label
 * @returns string query label
 */
export const modifyDimensionLabelForCustomQuery = (
  dimension: ChartDimensionType
) => {
  if (dimension.dimensionType === FieldSource.TAGS) {
    return (
      replaceAllSpecialCharactersBy(dimension.dimension) +
      '_' +
      dimension.tagDimensionType
    );
  }

  return replaceAllSpecialCharactersBy(dimension.dimension);
};

/**
 * @function getReportQuery
 * @description Function to construct a query using the report options
 * @param isTableView boolean value for the chart view state
 * @param connectionDetails cloud provider for which the label is fetched
 * @returns Object containing the query
 */
export const getReportQuery = (
  isTableView: boolean,
  connectionDetails: ConnectionProviderType
) => {
  const state = store.getState();
  const reportSlice = state.report;
  const { reportDatasource, reportOptions, tagKeyValues, tagMaps } =
    reportSlice;

  const dimensions = (
    isTableView ? reportOptions.dimension : reportOptions.chartDimension
  ).map((item) => ({
    label: modifyDimensionLabelForCustomQuery(item),
    field: getFieldByLabel(item.dimension, connectionDetails, item),
  }));

  const metrics = (
    isTableView ? reportOptions.metric : reportOptions.chartMetric
  ).map((item) => ({
    label: replaceAllSpecialCharactersBy(item),
    field: getFieldByLabel(item, connectionDetails),
  }));

  const dateFields = getDateFieldsAndFormatByDashboardTypeAndProvider(
    reportDatasource.dashBoardType,
    connectionDetails.provider
  );

  const filterGroups: FilterGroupType[] = [
    {
      filters: [
        {
          field: dateFields?.dateStartField ?? '',
          comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
          value: moment(reportOptions.startDate, DATE_FORMAT).format(
            dateFields?.dateFormat
          ),
          selectedValues: undefined,
        },
        {
          field: dateFields?.dateEndField ?? '',
          comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
          value: moment(reportOptions.endDate, DATE_FORMAT).format(
            dateFields?.dateFormat
          ),
          selectedValues: undefined,
        },
        ...getTagsQueryFilters(
          isTableView ? reportOptions.dimension : reportOptions.chartDimension,
          connectionDetails,
          tagKeyValues,
          tagMaps
        ),
      ],
      conjunctToNextGroup: CONJUNCTIONS.AND,
    },
  ];

  const structColumns =
    connectionDetails.provider === PROVIDER.GCP
      ? [{ label: QUERY_FIELDS.TAG, field: QUERY_FIELDS.PROJECT_LABEL_CUSTOM }]
      : undefined;

  return {
    columns: [
      {
        field: '*',
      },
    ],
    dashBoardType: reportDatasource?.dashBoardType,
    cached: true,
    orderBy: reportOptions.sort
      .filter((item) => item.label && item.sort)
      .map((item) => ({
        ...item,
        label: replaceAllSpecialCharactersBy(item.label),
      })),
    subQuery: {
      columns: [...dimensions, ...metrics],
      structColumns: structColumns,
      groupBy: dimensions.map((item) => item.label),
      aggregators: metrics.map((item) => ({
        label: item.label,
        function: AGGREGATORS.SUM,
      })),
      filterGroups: filterGroups,
    },
  };
};

/**
 * @function normalizeGroupData
 * @description Function to normalize the group data to remove the duplicate entries
 * @param isTableView is the view in table view mode
 * @param apiResponse response from the api call
 * @returns Modified data
 */
export const normalizeGroupData = (isTableView: boolean, apiResponse: any) => {
  // Get the metric from the chart
  const state = store.getState();
  const reportSlice = state.report;
  const { reportOptions } = reportSlice;

  let metrics = isTableView ? reportOptions.metric : reportOptions.chartMetric;

  // Group all the data in one array with metrics as number
  const allData: any[] = [];
  apiResponse.forEach((response: any) => {
    allData.push(
      ...response.data.map((data: any) => {
        let convertedMetricObj = {};
        metrics.forEach((metricName) => {
          convertedMetricObj = {
            ...convertedMetricObj,
            [metricName]: Number(data[metricName]),
          };
        });

        return {
          ...data,
          ...convertedMetricObj,
        };
      })
    );
  });

  // Merge the data with same dimension values
  let dimensionWiseMergedData: any[] = [];

  allData.forEach((dataToBeMerged: any) => {
    // Find if same dimension values already exists
    const index = dimensionWiseMergedData.findIndex((existingMergedData) => {
      const itemKeys = Object.keys(existingMergedData).filter(
        (key) => !metrics.includes(key)
      );
      return itemKeys.every(
        (key) => dataToBeMerged[key] === existingMergedData[key]
      );
    });

    // If same dimension values already exists then add the metric values to existing values
    if (index > -1) {
      let addedMetricObj = {};
      metrics.forEach((metricName) => {
        addedMetricObj = {
          ...addedMetricObj,
          [metricName]:
            dataToBeMerged[metricName] +
            dimensionWiseMergedData[index][metricName],
        };
      });
      dimensionWiseMergedData[index] = {
        ...dimensionWiseMergedData[index],
        ...addedMetricObj,
      };
    } else {
      dimensionWiseMergedData.push(dataToBeMerged);
    }
  });

  return dimensionWiseMergedData;
};

export const getReportPageTitle = () => {
  const state = store.getState();
  const reportSlice = state.report;
  const { reportView, existingReport } = reportSlice;

  if (reportView !== ReportsViewType.EDIT) {
    return i18n.t('reports.report');
  }

  if (
    existingReport &&
    existingReport.reportPermission === ReportPermissions.WRITE
  ) {
    return i18n.t('reports.updateReport');
  }

  return i18n.t('reports.createReport');
};

/**
 * @function isAConnectionWithBillingDatasource
 * @description Function to validate if the report datasource is a connection and is a billing dataset
 * @returns boolean true if it's a connection with billing dataset and without a custom query, else false
 */
export const isAConnectionWithBillingDatasource = () => {
  const state = store.getState();
  const reportSlice = state.report;
  const { typeOfConnection, reportDatasource } = reportSlice;

  return (
    !reportDatasource.useCustomQuery &&
    typeOfConnection === MY_DASHBOARD_TYPES.SINGLE_CONNECTION &&
    reportDatasource.dashBoardType === DASHBOARD_TYPES.BILLING_DEFAULT
  );
};

/**
 * @function getDimensionDisplayText
 * @description Function to get the dimension display text by dimension type
 * @param dimension Dimension details for which the text is required
 * @returns string display text
 */
export const getDimensionDisplayText = (dimension: ChartDimensionType) => {
  return dimension.dimensionType === FieldSource.TAGS
    ? `${dimension.dimension} (${dimension.tagDimensionType})`
    : dimension.dimension;
};
