import i18n from 'i18n';

import { PROVIDER } from 'constants/cloudProviders';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { store } from 'redux/store';
import {
  getAWSTagFiltersData,
  getAzureTagFiltersData,
  getGCPTagFiltersData,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import { formatArrayToStringByProvider } from 'utils/dataFormatterUtils';
import {
  DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  enumerateDaysBetweenDates,
  formatDateFieldByProvider,
  getMonthYearShortList,
} from 'utils/date';
import { DashboardMetaData } from 'types/dashboard';

import { ExcelData } from '../../../types';
import {
  getAccountLabelByProvider,
  getAWSUnitCostTimePeriodQuery,
  getAzureUnitCostTimePeriodQuery,
  getChartExcelDataWithDateFilter,
  getDualAxisCostByTypeTableData,
  getGcpUnitCostTimePeriodQuery,
  getUsageCostTableData,
} from '../../../utils';

/**
 * @function getTotalAmortizedCostByAccountsLabelByProvider
 * @description Function to return the total amortized cost by accounts label by provider
 * @param provider provider for which the label is fetched
 * @returns Returns string containing the accounts filter label
 */
export const getTotalAmortizedCostByAccountsLabelByProvider = (
  provider: string
) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('graphHeadings.totalAmortizedCostBySubscriptions');

    case PROVIDER.AWS:
      return i18n.t('graphHeadings.totalAmortizedCostByAccounts');

    case PROVIDER.GCP:
    default:
      return i18n.t('graphHeadings.totalAmortizedCostByProjects');
  }
};

/**
 * @function getAwsTotalAmortizedCostByAccountsQuery
 * @description Function to return the Total Amortized Cost by Accounts query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsTotalAmortizedCostByAccountsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAWSUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['month', 'account'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.AWS_RELATIONAL_DATABASE_SERVICE,
                QUERY_VALUES.AMAZON_RED_SHIFT,
                QUERY_VALUES.AMAZON_OPEN_SEARCH_SERVICE,
                QUERY_VALUES.AMAZON_NEPTUNE,
                QUERY_VALUES.AMAZON_ELASTIC_CACHE,
                QUERY_VALUES.AMAZON_DOCUMENT_DB,
              ],
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getGcpTotalAmortizedCostByProjectsQuery
 * @description Function to return the Total Amortized Cost by Projects query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpTotalAmortizedCostByProjectsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PROJECT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getGcpUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['month', 'account'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.CLOUD_SQL, QUERY_VALUES.BIGQUERY],
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureTotalAmortizedCostBySubscriptionsQuery
 * @description Function to return the Total Amortized Cost by Subscriptions query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureTotalAmortizedCostBySubscriptionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'account',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SUBSCRIPTION_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      ...getAzureUnitCostTimePeriodQuery(dashboardMetaData),
    ],
    groupBy: ['month', 'account'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.CONSUMED_SERVICE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.MICROSOFT_DOCUMENT_DB,
                QUERY_VALUES.MICROSOFT_SQL,
                QUERY_VALUES.MICROSOFT_DB_FOR_POSTGRESQL,
                QUERY_VALUES.MICROSOFT_MY_SQL_SERVER,
                QUERY_VALUES.MICROSOFT_MARIA_DB_SERVER,
                QUERY_VALUES.MICROSOFT_CACHE,
              ],
              PROVIDER.AZURE
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getTotalAmortizedCostByAccountsQuery
 * @description Function to return the Total Amortized Cost by Accounts query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getTotalAmortizedCostByAccountsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureTotalAmortizedCostBySubscriptionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsTotalAmortizedCostByAccountsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpTotalAmortizedCostByProjectsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getAWSTotalAmortizedCostByRegionsQuery
 * @description Function to return the Total Amortized Cost by Regions query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAWSTotalAmortizedCostByRegionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'region',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_REGION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'region'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.AWS_RELATIONAL_DATABASE_SERVICE,
                QUERY_VALUES.AMAZON_RED_SHIFT,
                QUERY_VALUES.AMAZON_OPEN_SEARCH_SERVICE,
                QUERY_VALUES.AMAZON_NEPTUNE,
                QUERY_VALUES.AMAZON_ELASTIC_CACHE,
                QUERY_VALUES.AMAZON_DOCUMENT_DB,
              ],
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getAzureTotalAmortizedCostByRegionsQuery
 * @description Function to return the Total Amortized Cost by Regions query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureTotalAmortizedCostByRegionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'region',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.RESOURCE_LOCATION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'region'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.CONSUMED_SERVICE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.MICROSOFT_DOCUMENT_DB,
                QUERY_VALUES.MICROSOFT_SQL,
                QUERY_VALUES.MICROSOFT_DB_FOR_POSTGRESQL,
                QUERY_VALUES.MICROSOFT_MY_SQL_SERVER,
                QUERY_VALUES.MICROSOFT_MARIA_DB_SERVER,
                QUERY_VALUES.MICROSOFT_CACHE,
              ],
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpTotalAmortizedCostByRegionsQuery
 * @description Function to return the Total Amortized Cost by Regions query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpTotalAmortizedCostByRegionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'region',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LOCATION_LOCATION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'region'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.CLOUD_SQL, QUERY_VALUES.BIGQUERY],
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getTotalAmortizedCostByRegionsQuery
 * @description Function to return the Total Amortized Cost by Regions query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getTotalAmortizedCostByRegionsQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureTotalAmortizedCostByRegionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAWSTotalAmortizedCostByRegionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpTotalAmortizedCostByRegionsQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getCostByServiceProductLabelByProvider
 * @description Function to return the cost by product service product label by provider
 * @param provider provider for which the label is fetched
 * @returns Returns string containing the accounts filter label
 */
export const getCostByServiceProductLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('graphHeadings.costByServiceProductType');

    case PROVIDER.AWS:
      return i18n.t('graphHeadings.costByServiceProductFamily');

    case PROVIDER.GCP:
    default:
      return i18n.t('graphHeadings.costBySku');
  }
};

/**
 * @function getProductTypeLabelByProvider
 * @description Function to return the product type label by provider
 * @param provider provider for which the label is fetched
 * @returns Returns string containing the accounts filter label
 */
export const getProductTypeLabelByProvider = (provider: string) => {
  switch (provider) {
    case PROVIDER.AZURE:
      return i18n.t('productType');

    case PROVIDER.AWS:
      return i18n.t('productFamily');

    case PROVIDER.GCP:
    default:
      return i18n.t('sku');
  }
};

/**
 * @function getAwsCostByServiceProductFamilyQuery
 * @description Function to return the Cost by Service Product Family query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsCostByServiceProductFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'productFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_OPERATION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'productFamily'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.AWS_RELATIONAL_DATABASE_SERVICE,
                QUERY_VALUES.AMAZON_RED_SHIFT,
                QUERY_VALUES.AMAZON_OPEN_SEARCH_SERVICE,
                QUERY_VALUES.AMAZON_NEPTUNE,
                QUERY_VALUES.AMAZON_ELASTIC_CACHE,
                QUERY_VALUES.AMAZON_DOCUMENT_DB,
              ],
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getAzureCostByServiceProductTypeQuery
 * @description Function to return the Cost by Service Product Type query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureCostByServiceProductTypeQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'productFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.CONSUMED_SERVICE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.CONCAT_CAST_YEAR_MONTH_BILLING_PERIOD_START_DATE
            : QUERY_FIELDS.CONCAT_YEAR_MONTH_BILLING_PERIOD_START_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'productFamily'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.CONSUMED_SERVICE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.MICROSOFT_DOCUMENT_DB,
                QUERY_VALUES.MICROSOFT_SQL,
                QUERY_VALUES.MICROSOFT_DB_FOR_POSTGRESQL,
                QUERY_VALUES.MICROSOFT_MY_SQL_SERVER,
                QUERY_VALUES.MICROSOFT_MARIA_DB_SERVER,
                QUERY_VALUES.MICROSOFT_CACHE,
              ],
              PROVIDER.AZURE
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getGcpCostByServiceProductTypeQuery
 * @description Function to return the Cost by Service Product Type query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpCostByServiceProductTypeQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'productFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SKU_DESCRIPTION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.INVOICE_MONTH,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'productFamily'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.CLOUD_SQL, QUERY_VALUES.BIGQUERY],
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
  };
};

/**
 * @function getCostByServiceProductFamilyQuery
 * @description Function to return the Cost by Service Product Family query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getCostByServiceProductFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureCostByServiceProductTypeQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsCostByServiceProductFamilyQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpCostByServiceProductTypeQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getCostByDatabaseEnginesQuery
 * @description Function to return the Cost by Database Engines query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
export const getCostByDatabaseEnginesQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const { tagsFilters } = store.getState().dashboard;
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  return {
    columns: [
      {
        label: 'databaseEngine',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_DATABASE_ENGINE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month', 'databaseEngine'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.AWS_RELATIONAL_DATABASE_SERVICE,
                QUERY_VALUES.AMAZON_RED_SHIFT,
                QUERY_VALUES.AMAZON_OPEN_SEARCH_SERVICE,
                QUERY_VALUES.AMAZON_NEPTUNE,
                QUERY_VALUES.AMAZON_ELASTIC_CACHE,
                QUERY_VALUES.AMAZON_DOCUMENT_DB,
              ],
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
        ],
      },
      ...getAWSTagFiltersData(
        tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
  };
};

/**
 * @function getAwsDailyElasticityByPurchaseOptionQuery
 * @description Function to return the Daily Elasticity by Purchase Option query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsDailyElasticityByPurchaseOptionQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_TERM,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: isStaticData
          ? `FORMAT_TIMESTAMP('%Y-%m-%d %H:00:00',${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )})`
          : `DATE_FORMAT(${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )}, '%Y-%m-%d %H:00:00')`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.AWS_RELATIONAL_DATABASE_SERVICE,
                QUERY_VALUES.AMAZON_RED_SHIFT,
                QUERY_VALUES.AMAZON_OPEN_SEARCH_SERVICE,
                QUERY_VALUES.AMAZON_NEPTUNE,
                QUERY_VALUES.AMAZON_ELASTIC_CACHE,
                QUERY_VALUES.AMAZON_DOCUMENT_DB,
              ],
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_TERM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureDailyElasticityByPurchaseOptionQuery
 * @description Function to return the Daily Elasticity by Purchase Option query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureDailyElasticityByPurchaseOptionQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRICING_MODEL,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE
            : QUERY_FIELDS.CONVERT_DATETIME_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.CONSUMED_SERVICE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.MICROSOFT_DOCUMENT_DB,
                QUERY_VALUES.MICROSOFT_SQL,
                QUERY_VALUES.MICROSOFT_DB_FOR_POSTGRESQL,
                QUERY_VALUES.MICROSOFT_MY_SQL_SERVER,
                QUERY_VALUES.MICROSOFT_MARIA_DB_SERVER,
                QUERY_VALUES.MICROSOFT_CACHE,
              ],
              PROVIDER.AZURE
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpDailyElasticityByPurchaseOptionQuery
 * @description Function to return the Daily Elasticity by Purchase Option query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpDailyElasticityByPurchaseOptionQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'pricingTerm',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_TYPE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.USAGE_END_TIME,
          dashboardMetaData.connection.focusConversionEnabled
        )})`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['pricingTerm', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.CLOUD_SQL, QUERY_VALUES.BIGQUERY],
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getDailyElasticityByPurchaseOptionQuery
 * @description Function to return the Daily Elasticity by Purchase Option query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getDailyElasticityByPurchaseOptionQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureDailyElasticityByPurchaseOptionQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsDailyElasticityByPurchaseOptionQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpDailyElasticityByPurchaseOptionQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getDailyCostByInstanceFamilyLabelByProvider
 * @description Function to return the Daily Cost by Instance Family label
 * @param provider Provider for which the label is required
 * @returns String Label for chart heading
 */
export const getDailyCostByInstanceFamilyLabelByProvider = (
  provider: string
) => {
  return provider === PROVIDER.GCP
    ? i18n.t('graphHeadings.dailyCostBySku')
    : i18n.t('graphHeadings.dailyCostByInstanceFamily');
};

/**
 * @function getAwsDailyCostByInstanceFamilyQuery
 * @description Function to return the Daily Cost by Instance Family query for AWS
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAwsDailyCostByInstanceFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'instanceFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_INSTANCE_TYPE_FAMILY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: isStaticData
          ? `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )})`
          : `DATE_FORMAT(${getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            )}, '%Y-%m-%d')`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['instanceFamily', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AWS,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_PRODUCT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.AWS_RELATIONAL_DATABASE_SERVICE,
                QUERY_VALUES.AMAZON_RED_SHIFT,
                QUERY_VALUES.AMAZON_OPEN_SEARCH_SERVICE,
                QUERY_VALUES.AMAZON_NEPTUNE,
                QUERY_VALUES.AMAZON_ELASTIC_CACHE,
                QUERY_VALUES.AMAZON_DOCUMENT_DB,
              ],
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AWS
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRICING_TERM,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: '',
          },
        ],
      },
      ...getAWSTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getAzureDailyCostByInstanceFamilyQuery
 * @description Function to return the Daily Cost by Instance Family query for Azure
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getAzureDailyCostByInstanceFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'instanceFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.METER_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_BILLING_CURRENCY,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE
            : QUERY_FIELDS.CONVERT_DATETIME_DATE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['instanceFamily', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              isStaticData
                ? QUERY_FIELDS.COALESCE_SAFE_PARSE_DATE_BILLING_PERIOD_START_DATE
                : QUERY_FIELDS.BILLING_PERIOD_START_DATE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.AZURE,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.CONSUMED_SERVICE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [
                QUERY_VALUES.MICROSOFT_DOCUMENT_DB,
                QUERY_VALUES.MICROSOFT_SQL,
                QUERY_VALUES.MICROSOFT_DB_FOR_POSTGRESQL,
                QUERY_VALUES.MICROSOFT_MY_SQL_SERVER,
                QUERY_VALUES.MICROSOFT_MARIA_DB_SERVER,
                QUERY_VALUES.MICROSOFT_CACHE,
              ],
              PROVIDER.AZURE
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SUBSCRIPTION_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.AZURE
            ),
          },
        ],
      },
      ...getAzureTagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getGcpDailyCostBySkuQuery
 * @description Function to return the Daily Cost by SKU query for GCP
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @param isStaticData boolean to indicate if the data is for static file data
 * @returns Object containing the query
 */
const getGcpDailyCostBySkuQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[],
  isStaticData: boolean
) => {
  return {
    columns: [
      {
        label: 'instanceFamily',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.SKU_DESCRIPTION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'time',
        field: `FORMAT_TIMESTAMP('%Y-%m-%d',${getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.USAGE_END_TIME,
          dashboardMetaData.connection.focusConversionEnabled
        )})`,
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['instanceFamily', 'time'],
    orderBy: [
      {
        label: 'time',
        sort: ORDER_BY.ASCENDING,
      },
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[0],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.INVOICE_MONTH,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              dateRange[1],
              PROVIDER.GCP,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.SERVICE_DESCRIPTION,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              [QUERY_VALUES.CLOUD_SQL, QUERY_VALUES.BIGQUERY],
              PROVIDER.GCP
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PROJECT_NAME,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.IN,
            value: formatArrayToStringByProvider(
              selectedAccounts,
              PROVIDER.GCP
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: getGCPTagFiltersData(
      dashboardMetaData.tagsFilters,
      dashboardMetaData.dashboard.id,
      dashboardMetaData.dashboardView
    ),
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

/**
 * @function getDailyCostByInstanceFamilyQuery
 * @description Function to return the Daily Cost by Instance Family query
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedAccounts List of accounts selected
 * @param dateRange Range of dates for which the query is constructed
 * @returns Object containing the query
 */
export const getDailyCostByInstanceFamilyQuery = (
  dashboardMetaData: DashboardMetaData,
  selectedAccounts: string[],
  dateRange: string[]
) => {
  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  switch (dashboardMetaData.dashboard.connectorProvider) {
    case PROVIDER.AZURE:
      return getAzureDailyCostByInstanceFamilyQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.AWS:
      return getAwsDailyCostByInstanceFamilyQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );

    case PROVIDER.GCP:
    default:
      return getGcpDailyCostBySkuQuery(
        dashboardMetaData,
        selectedAccounts,
        dateRange,
        isStaticData
      );
  }
};

/**
 * @function getDailyElasticityByPurchaseOptionColumns
 * @description Function to return the Daily Elasticity by Purchase Option Columns
 * @param dateRange of dates for which the columns are required
 * @returns List of columns
 */
export const getDailyElasticityByPurchaseOptionColumns = (
  dateRange: string[]
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: i18n.t('awsDatabaseDashboard.purchaseOptionOrCost'),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...enumerateDaysBetweenDates(dateRange).map((time) => ({
      title: time,
      key: `${time}-value`,
      dataIndex: `${time}-value`,
      width: 100,
    })),
  ];
};

/**
 * @function getInstanceFamilyLabelByProvider
 * @description Function to return the instance family label by provider
 * @param provider Provider for which the label is required
 * @returns String Label for instance family based on the provider
 */
const getInstanceFamilyLabelByProvider = (provider: string) => {
  return provider === PROVIDER.GCP
    ? i18n.t('sku')
    : i18n.t('awsDatabaseDashboard.instanceFamily');
};

/**
 * @function getDailyCostByInstanceFamilyColumns
 * @description Function to return the Daily Cost by Instance Family Columns
 * @param dateRange of dates for which the columns are required
 * @param provider CSP provider for which the columns are required
 * @returns List of columns
 */
export const getDailyCostByInstanceFamilyColumns = (
  dateRange: string[],
  provider: string
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: getInstanceFamilyLabelByProvider(provider),
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...enumerateDaysBetweenDates(dateRange, DATE_FORMAT, 'day').map((time) => ({
      title: time,
      key: `${time}-value`,
      dataIndex: `${time}-value`,
      width: 100,
    })),
  ];
};

/**
 * @function getMonthlyCostByAccountsColumns
 * @description Function to return the the Monthly Cost by Accounts Columns
 * @param dateRange Range of dates for which the columns are required
 * @returns List of columns
 */
export const getMonthlyCostByAccountsColumns = (
  dateRange: string[],
  groupingLabel: string
) => {
  return [
    {
      title: '#',
      dataIndex: 'slNo',
      key: 'slNo',
      width: 40,
      render: (_text: any, _record: any, index: number) => index + 1,
      fixed: true,
    },
    {
      title: groupingLabel,
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    ...getMonthYearShortList(dateRange[0], dateRange[1], MONTH_YEAR_FORMAT).map(
      (month) => ({
        title: month,
        key: `${month}-value`,
        dataIndex: `${month}-value`,
        width: 100,
      })
    ),
  ];
};

/**
 * @function getComputeExcelData
 * @description Returns the excel export data for compute
 * @param connectorName name of the connection chosen
 * @param provider provider for which the excel data is needed.
 * @param selectedAccounts List of accounts selected
 * @param data export data for all tables
 * @returns List of excel export data
 */
export const getComputeExcelData = (
  connectorName: string,
  provider: string,
  selectedAccounts: string[],
  data: {
    totalAmortizedCostByAccounts: ExcelData;
    totalAmortizedCostByRegions: ExcelData;
    costByServiceProductFamily: ExcelData;
    costByDatabaseEngines: ExcelData;
    dailyElasticityByPurchaseOptionData: ExcelData;
    dailyCostByInstanceFamily: ExcelData;
  }
) => {
  return [
    getChartExcelDataWithDateFilter({
      ...data.totalAmortizedCostByAccounts,
      columns: getMonthlyCostByAccountsColumns(
        data.totalAmortizedCostByAccounts.dateRange,
        getAccountLabelByProvider(provider)
      ),
      data: getUsageCostTableData(
        data.totalAmortizedCostByAccounts.data,
        'time'
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.totalAmortizedCostByRegions,
      columns: getMonthlyCostByAccountsColumns(
        data.totalAmortizedCostByRegions.dateRange,
        i18n.t('awsDatabaseDashboard.region')
      ),
      data: getDualAxisCostByTypeTableData(
        data.totalAmortizedCostByRegions.data
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.costByServiceProductFamily,
      columns: getMonthlyCostByAccountsColumns(
        data.costByServiceProductFamily.dateRange,
        getProductTypeLabelByProvider(provider)
      ),
      data: getDualAxisCostByTypeTableData(
        data.costByServiceProductFamily.data
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
    {
      ...getChartExcelDataWithDateFilter({
        ...data.costByDatabaseEngines,
        columns: getMonthlyCostByAccountsColumns(
          data.costByDatabaseEngines.dateRange,
          i18n.t('awsDatabaseDashboard.databaseEngine')
        ),
        data: getDualAxisCostByTypeTableData(data.costByDatabaseEngines.data),
        connectorName,
        provider,
        selectedAccounts,
      }),
      excludeInGlobalDownload: !hasCostByDatabaseEngineChart(provider),
    },
    getChartExcelDataWithDateFilter({
      ...data.dailyElasticityByPurchaseOptionData,
      columns: getDailyElasticityByPurchaseOptionColumns(
        data.dailyElasticityByPurchaseOptionData.dateRange
      ),
      data: getDualAxisCostByTypeTableData(
        data.dailyElasticityByPurchaseOptionData.data
      ),
      connectorName,
      provider,
      selectedAccounts,
    }),
    getChartExcelDataWithDateFilter({
      ...data.dailyCostByInstanceFamily,
      columns: getDailyCostByInstanceFamilyColumns(
        data.dailyCostByInstanceFamily.dateRange,
        provider
      ),
      data: getDualAxisCostByTypeTableData(data.dailyCostByInstanceFamily.data),
      connectorName,
      provider,
      selectedAccounts,
    }),
  ];
};

/**
 * @function hasCostByDatabaseEngineChart
 * @description Returns the boolean based on the provider if the cost by database engine chart is needed for the provider or not
 * @param provider provider for which the condition is validated.
 * @returns boolean true if the chart is included else false
 */
export const hasCostByDatabaseEngineChart = (provider: string) => {
  return provider === PROVIDER.AWS;
};
