import { Collapse } from 'antd';
import { ExpandIconPosition } from 'antd/es/collapse/Collapse';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';

import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';

import { AccordionItemsType } from './types';

const { Panel } = Collapse;

type AccordionProps = {
  items: AccordionItemsType[];
  className?: string;
  expandIconPosition?: ExpandIconPosition;
  defaultActiveKey?: string | number;
  collapsible?: CollapsibleType;
  showArrow?: boolean;
};

const Accordion = ({
  items,
  className,
  expandIconPosition,
  defaultActiveKey,
  collapsible,
  showArrow = true,
}: AccordionProps) => {
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <Icon iconName={ICONS.ARROW_DOWN_S_LINE} />
    ) : (
      <Icon iconName={ICONS.ARROW_RIGHT_S_LINE} />
    );

  return (
    <Collapse
      className={`accordion design-v2 full-width flex flex-column flex-gap-16 ${className}`}
      expandIcon={getExpandIcon}
      expandIconPosition={expandIconPosition}
      defaultActiveKey={defaultActiveKey}
      accordion
      collapsible={collapsible}
    >
      {items.map((item) => (
        <Panel
          className="panel"
          header={item.heading}
          key={item.key}
          showArrow={showArrow}
        >
          {item.children}
        </Panel>
      ))}
    </Collapse>
  );
};

export default Accordion;
