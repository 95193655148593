import { COMPARATORS, CONJUNCTIONS } from 'constants/requestBody';
import { COMPARATORS_LIST } from './constants';
import { CostSavingFilterType } from 'pages/CostOptimizationInsightsPage/types';

/**
 * @function isComparatorCompliant
 * @description Returns the boolean value if the filter exists with the given comparator
 * @param comparator comparator to be checked for compliance
 * @param filters The list of existing filters
 * @returns Boolean value tru if the filter exists with the given comparator, else false
 */
const isFilterAddedWithConjunction = (
  comparator: string,
  filters: CostSavingFilterType[]
) => {
  return filters.some((eachFilter) => eachFilter.comparator === comparator);
};

/**
 * @function isComparatorCompliant
 * @description Returns the boolean value if the comparator is compliant or not based on the existing filters and conjunction to the current filter
 * @param comparator comparator to be checked for compliance
 * @param filters The list of existing filters
 * @param conjunction Conjunction for the current filter
 * @returns List of filters with disabled field based on the existing filters and conjunction for the current filter
 */
const isComparatorCompliant = (
  comparator: string,
  filters: CostSavingFilterType[],
  conjunction: string
) => {
  switch (comparator) {
    case COMPARATORS.EQUALS:
      // True if conjunction is 'OR' or no filters present with the comparator, false if conjunction is 'AND' and filter present with the same comparator
      return !(
        conjunction === CONJUNCTIONS.AND &&
        isFilterAddedWithConjunction(comparator, filters)
      );

    case COMPARATORS.GREATER_THAN_OR_EQUAL:
    case COMPARATORS.LESS_THAN_OR_EQUAL:
      return !isFilterAddedWithConjunction(comparator, filters);

    default:
      return true;
  }
};

/**
 * @function getComparatorsList
 * @description Returns the list of comparators basis on the existing filters and the conjunction
 * @param filters The list of existing filters
 * @param conjunction Conjunction for the current filter
 * @returns List of filters with disabled field based on the existing filters and conjunction for the current filter
 */
export const getComparatorsList = (
  filters: CostSavingFilterType[],
  conjunction: string
) => {
  return COMPARATORS_LIST.map((comparator) => ({
    ...comparator,
    disabled: !isComparatorCompliant(comparator.symbol, filters, conjunction),
  }));
};
