import { Menu } from 'antd';
import DashboardComponent from 'components/DashboardComponent';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import NavigationPath from 'components/NavigationPath';
import { REQUEST_STATUS } from 'constants/requestBody';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userAuthorization } from 'redux/authorizationSlice';
import { selectContainerInsights } from 'redux/containerInsightsSlice';
import { setTableViewEnabled } from 'redux/dashboardSlice';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import GranulateUsageMetering from './components/GranulateUsageMetering';
import ContainerDashboardHeader from './components/ContainerDashboardHeader';
import { CONTAINER_INSIGHTS_DASHBOARD_NAVIGATION } from './constants';

import './index.scss';

const ContainerInsights = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permissions } = useSelector(userAuthorization);
  const { selectedContainerCostDashboardView } = useSelector(
    selectContainerInsights
  );

  const [isEmptyDashboard, setIsEmptyDashboard] = useState(false);
  const [connectionsRequestStatus, setConnectionsRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    dispatch(setTableViewEnabled(false));
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function getUsageMeteringComponentByProvider
   * @description Function to return the usage metering component based on the selected connection
   * @returns JSX component
   */
  const getUsageMeteringComponentByProvider = () => {
    return <GranulateUsageMetering />;
  };

  if (!permissions.cloudConnectorList) {
    return <div>{t('noConnectionsPermission')}</div>;
  }

  if (isEmptyDashboard) {
    return <div>{t('containerInsight.noContainerConnections')}</div>;
  }

  return (
    <div className="container-insights-page">
      <div className="container-insights-header new-page-header">
        <ContainerDashboardHeader
          setIsEmptyDashboard={setIsEmptyDashboard}
          setRequestStatus={setConnectionsRequestStatus}
        />
      </div>
      <div className="page-content">
        <div className="navigation">
          <NavigationPath />
          <HorizontalNavigationMenu
            selectedKeys={[selectedContainerCostDashboardView]}
            menuItems={CONTAINER_INSIGHTS_DASHBOARD_NAVIGATION.map(
              (navMenu) => (
                <Menu.Item
                  key={navMenu.id}
                  eventKey={navMenu.id}
                  className={`font-caption-bold menu ${
                    navMenu.id === selectedContainerCostDashboardView &&
                    'active-menu'
                  }`}
                >
                  {navMenu.title}
                </Menu.Item>
              )
            )}
          />
        </div>
        <DashboardComponent
          component={getUsageMeteringComponentByProvider()}
          requestStatus={connectionsRequestStatus}
        />
      </div>
    </div>
  );
};

export default ContainerInsights;
