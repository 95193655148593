import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import useDidMountEffect from 'hooks/useDidMountEffect';
import { selectReport, setReportOptions } from 'redux/reportSlice';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { getReportFieldCategory } from 'pages/CreateReportPage/utils';
import { ChartDimensionType } from 'pages/CreateReportPage/types';
import { COLORS } from 'constants/graphConfig';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { CHARTS_LIST, FieldSource, FIELD_TYPE } from 'constants/dashboard';

import './index.scss';

const ChartDimensionList = () => {
  const dispatch = useDispatch();
  const { reportOptions } = useSelector(selectReport);

  const [allChartDimensions, setAllChartDimensions] = useState<
    ChartDimensionType[]
  >([]);

  useEffect(() => {
    setChartDimensionByOrderOfSelection(true);
  }, [reportOptions.chartType, reportOptions.dimension]);

  useDidMountEffect(() => {
    setChartDimensionByOrderOfSelection();
  }, [reportOptions.dimension]);

  /**
   * @function setChartDimensionByOrderOfSelection
   * @description Function to set the chart dimension by the order of chart columns
   */
  const setChartDimensionByOrderOfSelection = (firstRender = false) => {
    const existingChartDimensions = reportOptions.dimension.filter(
      (eachDimension) =>
        reportOptions.chartDimension.some(
          (item) =>
            item.dimension === eachDimension.dimension &&
            item.dimensionType === eachDimension.dimensionType
        )
    );
    updateChartColumns(existingChartDimensions);
    const chartDimensions: ChartDimensionType[] = [];
    existingChartDimensions.forEach((item) => {
      const dimensionColumn = reportOptions.dimension.find(
        (eachDimension) =>
          eachDimension.dimension === item.dimension &&
          eachDimension.dimensionType === item.dimensionType &&
          eachDimension.tagDimensionType === item.tagDimensionType
      );
      if (dimensionColumn) {
        chartDimensions.push(dimensionColumn);
      }
    });

    chartDimensions.push(
      ...reportOptions.dimension.filter(
        (eachDimension) =>
          !existingChartDimensions.some(
            (item) =>
              eachDimension.dimension === item.dimension &&
              eachDimension.dimensionType === item.dimensionType &&
              eachDimension.tagDimensionType === item.tagDimensionType
          )
      )
    );

    if (firstRender) {
      // show time dimension first
      const timestampDimension = chartDimensions.find(
        (item) => getReportFieldCategory(item.dimension) === FIELD_TYPE.TIME
      );
      if (timestampDimension) {
        const timestampDimensionIndex = chartDimensions.findIndex(
          (item) => item === timestampDimension
        );
        if (timestampDimensionIndex > -1) {
          chartDimensions.splice(timestampDimensionIndex, 1);
          chartDimensions.unshift(timestampDimension);
        }
      }
    }
    setAllChartDimensions(chartDimensions);
  };

  /**
   * @function getMaxDimensionCount
   * @description Function to return the max dimension count of selected chart
   * @returns maximum dimension numerical value. Defaults to 1.
   */
  const getMaxDimensionCount = () => {
    return (
      CHARTS_LIST.find((item) => item.chartType === reportOptions.chartType)
        ?.dimensionsCount?.max ?? 1
    );
  };

  /**
   * @function updateChartColumns
   * @description Function to update the chart columns
   * @param dimensions dimensions to be updated with
   */
  const updateChartColumns = (dimensions: ChartDimensionType[]) => {
    dispatch(
      setReportOptions({ ...reportOptions, chartDimension: dimensions })
    );
  };

  /**
   * @function onChangeDimensionSelection
   * @description Callback function for selecting dimension
   * @param e Event for selection change
   */
  const onChangeDimensionSelection = (
    e: any,
    dimension: ChartDimensionType
  ) => {
    if (e.target.type === 'radio') {
      updateChartColumns([
        ...allChartDimensions.filter(
          (item) =>
            item.dimension === dimension.dimension &&
            item.dimensionType === dimension.dimensionType &&
            item.tagDimensionType === dimension.tagDimensionType
        ),
      ]);
      return;
    }

    let dimensionColumns: ChartDimensionType[] = [];
    const existingDimensions = reportOptions.chartDimension;

    if (e.target.checked) {
      const dimensions: ChartDimensionType[] = [
        ...existingDimensions,
        dimension,
      ];
      dimensionColumns = allChartDimensions.filter((item) =>
        dimensions.some(
          (eachDimension) =>
            item.dimension === eachDimension.dimension &&
            item.dimensionType === eachDimension.dimensionType &&
            item.tagDimensionType === eachDimension.tagDimensionType
        )
      );
    } else {
      dimensionColumns = existingDimensions.filter(
        (item) =>
          !(
            item.dimension === dimension.dimension &&
            item.dimensionType === dimension.dimensionType &&
            item.tagDimensionType === dimension.tagDimensionType
          )
      );
    }

    updateChartColumns(dimensionColumns);
  };

  /**
   * @function isDimensionChecked
   * @description Function to check if the dimension is selected
   * @param dimension Dimension for which the validation is performed.
   * @returns boolean true if selected else false
   */
  const isDimensionChecked = (dimension: ChartDimensionType) => {
    return reportOptions.chartDimension.some(
      (item) =>
        item.dimension === dimension.dimension &&
        item.dimensionType === dimension.dimensionType &&
        item.tagDimensionType === dimension.tagDimensionType
    );
  };

  /**
   * @function isDimensionDisabled
   * @description Function to return if the dimension is disabled
   * @param dimension Dimension for which the validation is performed.
   * @returns boolean true if the dimension is disabled else false
   */
  const isDimensionDisabled = (dimension: ChartDimensionType) => {
    if (
      reportOptions.chartDimension.some(
        (item) =>
          item.dimension === dimension.dimension &&
          item.dimensionType === dimension.dimensionType &&
          item.tagDimensionType === dimension.tagDimensionType
      )
    ) {
      return false;
    }

    return reportOptions.chartDimension.length >= getMaxDimensionCount();
  };

  /**
   * @function isDraggingDisabled
   * @description Function to return if the dragging is disabled
   * @param dimension Dimension for which the validation is performed.
   * @returns boolean true if the dragging is disabled else false
   */
  const isDraggingDisabled = (dimension: ChartDimensionType) => {
    return !reportOptions.chartDimension.some(
      (item) =>
        item.dimension === dimension.dimension &&
        item.dimensionType === dimension.dimensionType &&
        item.tagDimensionType === dimension.tagDimensionType
    );
  };

  /**
   * @function handleOnDragEnd
   * @description Updates the selected dimensions list based on the drag and drop
   * @param result result of the drag and drop
   */
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const allDimensions = Array.from(allChartDimensions);
    const [reorderedItem] = allDimensions.splice(result.source.index, 1);
    allDimensions.splice(result.destination.index, 0, reorderedItem);
    setAllChartDimensions(allDimensions);

    const reOrderedDimensions = allDimensions.filter((item) =>
      reportOptions.chartDimension.includes(item)
    );
    updateChartColumns(reOrderedDimensions);
  };

  return (
    <div className="report-chart-dimensions-list">
      {getMaxDimensionCount() > 1 ? (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="chart-dimensions">
            {(provided) => (
              <ul
                className="draggable-chart-dimension-list flex flex-column flex-gap-8"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {allChartDimensions.map((eachDimension, index) => (
                  <Draggable
                    key={`${eachDimension.dimension}${eachDimension.dimensionType}${eachDimension.tagDimensionType}`}
                    draggableId={`${eachDimension.dimension}${eachDimension.dimensionType}${eachDimension.tagDimensionType}`}
                    index={index}
                    isDragDisabled={isDraggingDisabled(eachDimension)}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex flex-align-items-center flex-gap-4"
                      >
                        <Icon
                          iconName={ICONS.DRAGGABLE}
                          size={ICONS_SIZE.SM}
                          color={COLORS.colorRegentGrey}
                        />
                        <Checkbox
                          checked={isDimensionChecked(eachDimension)}
                          value={eachDimension.dimension}
                          disabled={isDimensionDisabled(eachDimension)}
                          onChange={(e) =>
                            onChangeDimensionSelection(e, eachDimension)
                          }
                        >
                          {eachDimension.dimensionType === FieldSource.TAGS
                            ? `${eachDimension.dimension} (${eachDimension.tagDimensionType})`
                            : eachDimension.dimension}
                        </Checkbox>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="flex flex-column flex-gap-8">
          {allChartDimensions.map((eachDimension) => (
            <Radio
              key={`${eachDimension.dimension}${eachDimension.dimensionType}${eachDimension.tagDimensionType}`}
              value={eachDimension.dimension}
              checked={isDimensionChecked(eachDimension)}
              onChange={(e) => onChangeDimensionSelection(e, eachDimension)}
            >
              {eachDimension.dimensionType === FieldSource.TAGS
                ? `${eachDimension.dimension} (${eachDimension.tagDimensionType})`
                : eachDimension.dimension}
            </Radio>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChartDimensionList;
