import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import i18n from 'i18n';
import { TagsType } from './types';

export const exportTagsAndLabels = async (
  fileName: string,
  data: any[],
  columns: TagsType[]
) => {
  const mandatoryTags = columns.filter((tag) => tag.mandatory);
  const optionalTags = columns.filter((tag) => !tag.mandatory);

  const workbook = new Excel.Workbook();

  try {
    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(
      i18n.t('tagsOrLabels.tags').toString(),
      { properties: { defaultColWidth: 15 } }
    );

    // // add worksheet columns
    // // each columns contains header and its mapping key from data
    worksheet.columns = [...mandatoryTags, ...optionalTags].map((column) => ({
      header: column.key,
      key: column.key,
    }));

    // first row
    worksheet.getCell(`A1`).value = '';
    worksheet.mergeCells(
      1,
      2,
      1,
      mandatoryTags.length + optionalTags.length + 1
    );
    worksheet.getCell('B1').value = i18n.t('tagsOrLabels.labels').toString();

    // second row
    if (mandatoryTags.length > 1) {
      worksheet.mergeCells(2, 2, 2, mandatoryTags.length);
      worksheet.getCell(2, 2).value = i18n
        .t('tagsOrLabels.mandatory')
        .toString();
    }
    if (optionalTags.length > 0) {
      worksheet.mergeCells(2, mandatoryTags.length + 1, 2, columns.length);
      worksheet.getCell(2, mandatoryTags.length + 1).value = i18n
        .t('tagsOrLabels.optional')
        .toString();
    }
    worksheet.getRows(1, 3)?.forEach((row) => {
      row.alignment = { horizontal: 'center' };
      row.eachCell((eachCell) => (eachCell.font = { bold: true }));
    });

    // Add column headers for data in the third row
    worksheet.getRow(3).values = [...mandatoryTags, ...optionalTags].map(
      (column) => column.key
    );

    worksheet.addRows(data);
    worksheet.getColumn('A').font = { bold: true };
    worksheet.getColumn('A').width = 50;
    worksheet.getRow(3).font = { bold: true };

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error: any) {
    console.error('<<<ERRROR>>>', error);
    console.error('Something Went Wrong', error.message);
  }
};
