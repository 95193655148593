import { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from 'react-simple-maps';
import { Divider, Tooltip } from 'antd';

import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import worldMapCoordinates from 'assets/data/worldMapCoordinates.json';
import AccessibleDiv from 'components/AccessibleDiv';
import TooltipContent from 'components/TooltipContent';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

import './index.scss';

type PointerMapProps = {
  data: any[];
  valueLabel: string;
  labelField: string;
  valueField: string;
  colorField?: string;
  prefixSymbol?: string;
  postfixSymbol?: string;
};

const PointerMap = ({
  data,
  valueLabel,
  labelField,
  valueField,
  colorField,
  prefixSymbol,
  postfixSymbol,
}: PointerMapProps) => {
  const [zoom, setZoom] = useState(1);

  const handleZoomIn = () => {
    if (zoom >= 8) return;
    setZoom(zoom * 2);
  };

  const handleZoomOut = () => {
    if (zoom <= 1) return;
    setZoom(zoom / 2);
  };

  return (
    <div className="pointer-map">
      <ComposableMap projection="geoEqualEarth">
        <ZoomableGroup zoom={zoom} center={[0, -20]}>
          <Geographies geography={worldMapCoordinates}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="rgba(188, 196, 205, 0.6)"
                  stroke="#FFFFFF"
                  strokeWidth={1}
                  style={{
                    default: { outline: 'none' },
                    hover: { outline: 'none' },
                    pressed: { outline: 'none' },
                  }}
                />
              ))
            }
          </Geographies>
          {data.map((eachData) => (
            <Marker
              key={eachData[labelField as any]}
              coordinates={eachData.coordinates}
            >
              <Tooltip
                overlay={
                  <TooltipContent
                    title={eachData[labelField as any]}
                    data={[
                      {
                        color: eachData[colorField as any] || '#F00',
                        name: valueLabel,
                        value: numberCommaSeparator(
                          eachData[valueField as any]
                        ),
                      },
                    ]}
                    prefixSymbol={prefixSymbol}
                    postfixSymbol={postfixSymbol}
                  />
                }
              >
                <circle
                  r={4}
                  fill={eachData[colorField as any] || '#F00'}
                  stroke={eachData[colorField as any] || '#fff'}
                  strokeWidth={1}
                />
              </Tooltip>
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
      <div className="controls flex flex-gap-4 flex-column flex-center">
        <AccessibleDiv
          onClick={handleZoomIn}
          className="zoom-button flex flex-center cursor-pointer"
        >
          <Icon iconName={ICONS.ADD_LINE} />
        </AccessibleDiv>
        <Divider className="horizontal-divider" />
        <AccessibleDiv
          onClick={handleZoomOut}
          className="zoom-button flex flex-center cursor-pointer"
        >
          <Icon iconName={ICONS.SUBTRACT_LINE} />
        </AccessibleDiv>
      </div>
    </div>
  );
};

export default PointerMap;
