import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { selectPurchase } from 'redux/purchaseSlice';
import Button from 'components/Button';
import Table from 'components/Table';
import { BUTTON_SIZE } from 'constants/appearance';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';
import { REQUEST_STATUS } from 'constants/requestBody';
import { DATE_TIME_AM_PM } from 'utils/date';

import { AWSRdsRisType } from '../../types';
import { getAllAwsRdsPurchasedRis } from '../../services';
import { ReservedInstancesStatusLabels } from '../../constants';

const RDSPurchased = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedConnection } = useSelector(selectPurchase);

  const [rdsRis, setRdsRis] = useState<AWSRdsRisType[]>([]);
  const [fetchRdsRisRequestStatus, setFetchRdsRisRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    if (selectedConnection) {
      fetchAllRdsRis();
    }
  }, [selectedConnection]);

  /**
   * @function fetchAllRdsRis
   * @description Function to fetch and set all the AWS RDS RIs
   */
  const fetchAllRdsRis = () => {
    setFetchRdsRisRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: selectedConnection?.connectorId,
      region: selectedConnection?.awsRegion,
    };

    getAllAwsRdsPurchasedRis(params)
      .then((res: any) => {
        setRdsRis(res?.data?.responseData || []);
        setFetchRdsRisRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchRdsRisRequestStatus);
      });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('purchasePage.reservedInstancesLabels.reservationId'),
      dataIndex: 'reservationId',
      key: 'reservationId',
    },
    {
      title: t('purchasePage.reservedInstancesLabels.productDescription'),
      dataIndex: 'productDescription',
      key: 'productDescription',
    },
    {
      title: t('purchasePage.reservedInstancesLabels.dbInstanceClass'),
      dataIndex: 'instanceClass',
      key: 'instanceClass',
    },
    {
      title: t('purchasePage.cart.paymentOption'),
      dataIndex: 'offeringType',
      key: 'offeringType',
    },
    {
      title: t('purchasePage.cart.term'),
      dataIndex: 'term',
      key: 'term',
      render: (text: string) =>
        t('purchasePage.cart.countMonths', { count: (Number(text) || 0) * 12 }),
    },
    {
      title: t('purchasePage.reservedInstancesLabels.quantity'),
      dataIndex: 'instanceCount',
      key: 'quantity',
    },
    {
      title: t('purchasePage.cart.createdDate'),
      dataIndex: 'createDate',
      key: 'createDate',
      render: (text: string) => moment(text).format(DATE_TIME_AM_PM),
    },
    {
      title: t('purchasePage.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => (
        <span className={`status ${text}`}>
          {
            ReservedInstancesStatusLabels.find((item) => item.key === text)
              ?.label
          }
        </span>
      ),
    },
  ];

  return (
    <section className="flex flex-column flex-gap-16">
      <div className="flex flex-align-items-center flex-space-between">
        <div className="form-header">
          {t('purchasePage.reservedInstancesLabels.purchasedResevedInstance')}
        </div>
        <Button
          title={t('purchasePage.reservedInstancesLabels.purchaseNewRi')}
          size={BUTTON_SIZE.SMALL}
          onClick={() => navigate(NAVIGATION_MENU_PATH.PURCHASE_RDS_RI)}
        />
      </div>
      <Table
        pagination={false}
        dataSource={rdsRis.map((item, index) => ({
          ...item,
          key: index,
        }))}
        columns={columns}
        loading={fetchRdsRisRequestStatus === REQUEST_STATUS.PROCESSING}
        designVersion2={true}
        scroll={{ y: 'calc(100vh - 20em)' }}
      />
    </section>
  );
};

export default RDSPurchased;
