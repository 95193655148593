import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { useSelector } from 'react-redux';

import { selectScorecard } from 'redux/scorecardSlice';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import ColumnChart from 'components/ColumnChart';
import BarChart from 'components/BarChart';
import Table from 'components/Table';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  SAVING_PLAN_REPORTS,
  SAVING_PLAN_REPORT_TYPES,
} from 'pages/ScorecardPage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray } from 'utils/handleErrors';

import { getUtilizationCoverageSummaryExcelData } from './utils';

import './index.scss';

type CoverageUtilizationSummaryGraphProps = {
  heading: string;
  graphName: string;
  pdfView: boolean;
  isTableView: boolean;
  setIsTableView: (val: boolean) => void;
  coverageUtilizationData: any;
  requestStatus: string[];
  selectedReportType: string;
  setSelectedReportType: (val: string) => void;
  isVerticalBarAlignment?: boolean;
};

const CoverageUtilizationSummaryGraph = ({
  heading,
  graphName,
  pdfView,
  isTableView,
  setIsTableView,
  coverageUtilizationData,
  requestStatus,
  selectedReportType,
  setSelectedReportType,
  isVerticalBarAlignment = true,
}: CoverageUtilizationSummaryGraphProps) => {
  const { t } = useTranslation();
  const { selectedConnection } = useSelector(selectScorecard);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
    },
    {
      title: t('scorecard.summary.item'),
      dataIndex: 'item',
      key: 'item',
    },
    {
      title:
        SAVING_PLAN_REPORTS.find((item) => item.value === selectedReportType)
          ?.label + ' %',
      dataIndex: 'value',
      key: 'value',
      align: 'right' as const,
      render: (text: any) => numberCommaSeparator(text || 0) + ' %',
    },
  ];

  /**
   * @function getData
   * @description Function to return the data based on the report type selected
   * @returns Object containing the selected report type data
   */
  const getData = () => {
    if (selectedReportType === SAVING_PLAN_REPORT_TYPES.UTILIZATION) {
      return coverageUtilizationData.utilization;
    }

    return coverageUtilizationData.coverage;
  };

  const tooltipOverride = {
    customItems: useCallback(
      (originalItems: any[]) =>
        originalItems.map((item) => ({
          ...item,
          name: SAVING_PLAN_REPORTS.find(
            (reportType) => reportType.value === selectedReportType
          )?.label,
          value: numberCommaSeparator(item.data.value) + '%',
        })),
      []
    ),
  };

  const valueFormatter = useCallback((v: any) => `${v}%`, []);

  /**
   * @function getGraphByBarAlignment
   * @description Function to return the graph component
   * @returns JSX Graph component
   */
  const getGraphByBarAlignment = () =>
    isVerticalBarAlignment ? (
      <ColumnChart
        data={getData()}
        xField="item"
        yField="value"
        yTitle={
          SAVING_PLAN_REPORTS.find((item) => item.value === selectedReportType)
            ?.label
        }
        groupingField="item"
        showLabels={false}
        showAllLegend={pdfView}
        disableAnimation={pdfView}
        meta={{
          value: { formatter: valueFormatter },
        }}
        tooltipOverride={tooltipOverride}
      />
    ) : (
      <BarChart
        data={getData()}
        xField="value"
        yField="item"
        groupingField="item"
        xTitle={
          SAVING_PLAN_REPORTS.find((item) => item.value === selectedReportType)
            ?.label
        }
        showLabels={false}
        disableAnimation={pdfView}
        showAllLegend={pdfView}
        meta={{
          value: { formatter: valueFormatter },
        }}
        tooltipOverride={tooltipOverride}
      />
    );

  const getComponent = () =>
    isTableView ? (
      <Table
        pagination={false}
        loading={requestStatus.includes(REQUEST_STATUS.PROCESSING)}
        dataSource={getData()}
        columns={columns}
        scroll={{ y: 175 }}
        designVersion2
      />
    ) : (
      getGraphByBarAlignment()
    );
  const HeadingComponent = (
    <div className="flex flex-gap-12 flex-align-items-center">
      <div>{heading}</div>
      <div>
        <Radio.Group
          options={SAVING_PLAN_REPORTS}
          onChange={(e) => setSelectedReportType(e.target.value)}
          value={selectedReportType}
          optionType="button"
          rootClassName="no-custom-style savings-types"
          style={{ height: 28 }}
        />
      </div>
    </div>
  );

  return (
    <div
      className="graph-card coverage-utilization-summary"
      id="graph-container"
    >
      <GraphHeader
        heading={heading}
        headingComponent={HeadingComponent}
        graphName={graphName}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        excelData={{
          multipleSheets: true,
          sheets: getUtilizationCoverageSummaryExcelData(
            {
              sheetName: `${heading} (${t(
                'scorecard.savingsTypes.utilization'
              )})`,
              data: coverageUtilizationData.utilization,
            },
            {
              sheetName: `${heading} (${t('scorecard.savingsTypes.coverage')})`,
              data: coverageUtilizationData.coverage,
            },
            selectedConnection
          ),
        }}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default CoverageUtilizationSummaryGraph;
