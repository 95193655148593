import { message } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProfileIcon } from 'assets/icons';
import { FormLabel } from 'components/FormLabel';
import SearchUserInput from 'components/AddUserSearch';
import Button from 'components/Button';
import Switch from 'components/Switch';
import SelectDashboardViews from 'components/SelectDashboardViews';
import AccessibleDiv from 'components/AccessibleDiv';
import { selectDashboard } from 'redux/dashboardSlice';
import { customDashboard } from 'redux/customDashboardSlice';
import { UserInfoType } from 'types/userManagementConsole';
import { QUERY_FIELDS, REQUEST_STATUS } from 'constants/requestBody';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { onApiCallError } from 'utils/handleErrors';
import { SHARE_DASHBOARD_COMPONENTS } from 'components/DashboardHeader/constants';
import { getDashboardDatasourceId } from 'utils/dashboardUtils';
import { CUSTOM_VIEW_PUBLISH_STATUS } from 'pages/CustomDashboardPage/constants';
import { getUserByEmail, shareDashboard } from '../services';
import { NewShareUserType } from '../types';
import { getDataSourceTypeField } from '../utils';

type InviteUsersProps = {
  setShareDashboardComponent: (val: string) => void;
  fetchAndSetDashboardUsers: () => void;
};

const InviteUsers = ({
  setShareDashboardComponent,
  fetchAndSetDashboardUsers,
}: InviteUsersProps) => {
  const { t } = useTranslation();

  const { dashboardViewsList, selectedDashboard, selectedConnection } =
    useSelector(selectDashboard);
  const { selectedDashboardCustomViews } = useSelector(customDashboard);

  const [selectedUsers, setSelectedUsers] = useState<NewShareUserType[]>([]);
  const [shareAllDashboardViews, setShareAllDashboardViews] = useState(true);
  const [selectedDashboardViews, setSelectedDashboardViews] = useState<
    string[]
  >([]);
  const [inviteUserRequestStatus, setInviteUserRequestStatus] = useState('');

  /**
   * @function addUserToList
   * @description Function to add user to user list.
   * Adds role as QUERY_FIELDS.NEW_USER_ROLE if user is not present in the system.
   * @param newUser - user data to be added
   */
  const addUserToList = (newUser: UserInfoType) => {
    if (!selectedUsers.some((user) => user.email === newUser.email)) {
      getUserByEmail(newUser.email).then((res: any) => {
        if (!res.data) {
          setSelectedUsers([
            ...selectedUsers,
            { ...newUser, roleName: QUERY_FIELDS.NEW_USER_ROLE },
          ]);
        } else {
          setSelectedUsers([...selectedUsers, newUser]);
        }
      });
    }
  };

  /**
   * @function onShareDashboard
   * @description Function to handle click Share button.
   */
  const onShareDashboard = () => {
    setInviteUserRequestStatus(REQUEST_STATUS.PROCESSING);
    const dashboardViews = shareAllDashboardViews
      ? [
          ...dashboardViewsList.map((view) => view.id),
          ...selectedDashboardCustomViews
            .filter(
              (customDashboards) =>
                customDashboards.viewStatus !== CUSTOM_VIEW_PUBLISH_STATUS.DRAFT
            )
            .map((view) => view.id),
        ]
      : selectedDashboardViews;
    const body = selectedUsers.map((user) => {
      return {
        ...user,
        status: QUERY_FIELDS.USER_STATUS_ACTIVE,
        userDatasourceDTOList: [
          {
            datasourceId: getDashboardDatasourceId(
              selectedDashboard,
              selectedConnection
            ),
            datasourceDashboards: [
              { dashboardId: selectedDashboard?.id, views: dashboardViews },
            ],
            datasourceType: getDataSourceTypeField(
              selectedDashboard?.dashBoardType ?? ''
            ),
          },
        ],
        dashboardLink: `${NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS.substring(
          1
        )}/${selectedDashboard?.id}`,
        sharingDashboard: true,
      };
    });
    shareDashboard(body)
      .then((res: any) => {
        setInviteUserRequestStatus(REQUEST_STATUS.SUCCESS);
        message.success(res.data.message);
        setShareDashboardComponent(SHARE_DASHBOARD_COMPONENTS.SUCCESS_PAGE);
        fetchAndSetDashboardUsers();
      })
      .catch((e) => onApiCallError(e, true, setInviteUserRequestStatus));
  };

  return (
    <div className="invite-users flex flex-column flex-gap-16">
      <div className="form-item flex flex-column">
        <FormLabel title={t('shareDashboard.searchRecipients')} required />
        <SearchUserInput
          placeholder={t('addUserModal.searchPlaceHolder')}
          setUserInfo={addUserToList}
          excludeUserEmail
        />
      </div>
      {selectedUsers.length ? (
        <div className="form-item flex flex-column">
          <FormLabel title={t('shareDashboard.selectedRecipients')} />
          <div className="flex flex-column flex-gap-8">
            {selectedUsers.map((user) => (
              <div
                className="flex flex-space-between flex-align-items-center"
                key={user.email}
              >
                <div className="flex flex-gap-8 flex-align-items-center font-caption-bold">
                  <img
                    src={ProfileIcon}
                    alt="profileimage"
                    className="profile-icon"
                  />
                  {user.name}
                </div>
                <AccessibleDiv
                  className="small-grey-button cursor-pointer"
                  onClick={() => {
                    setSelectedUsers(
                      selectedUsers.filter(
                        (value) => value.email !== user.email
                      )
                    );
                  }}
                >
                  {t('shareDashboard.removeLabel')}
                </AccessibleDiv>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="form-item flex flex-space-between">
        <FormLabel title={t('shareDashboard.shareAllDashboardViews')} />
        <Switch
          size="small"
          checked={shareAllDashboardViews}
          onChange={(value: boolean) => {
            setShareAllDashboardViews(value);
          }}
        />
      </div>
      {!shareAllDashboardViews && (
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('shareDashboard.selectDashboardViews')}
            required
          />
          <SelectDashboardViews
            prebuiltDashboardViews={dashboardViewsList}
            customDashboardViews={selectedDashboardCustomViews.filter(
              (customDashboards) =>
                customDashboards.viewStatus !== CUSTOM_VIEW_PUBLISH_STATUS.DRAFT
            )}
            selectedDashboardViews={selectedDashboardViews}
            setSelectedDashboardViews={setSelectedDashboardViews}
          />
        </div>
      )}
      <div className="flex flex-end">
        <Button
          className="share-button-cta"
          title={t('shareDashboard.share')}
          disabled={
            !selectedUsers.length ||
            (!shareAllDashboardViews && !selectedDashboardViews.length)
          }
          loading={inviteUserRequestStatus === REQUEST_STATUS.PROCESSING}
          onClick={onShareDashboard}
        />
      </div>
    </div>
  );
};

export default InviteUsers;
