import { Divider } from 'antd';

import Dimensions from './components/Dimensions';
import Metrics from './components/Metrics';
import Sort from './components/Sort';

import './index.scss';

const TableOptions = () => {
  return (
    <div className="report-options">
      <Dimensions />
      <Divider rootClassName="options-divider" />
      <Metrics />
      <Divider rootClassName="options-divider" />
      <Sort />
    </div>
  );
};

export default TableOptions;
