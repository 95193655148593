import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { MONTH_YEAR_FORMAT } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import DashboardComponent from 'components/DashboardComponent';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import { CostByRegionType } from 'types/dataTypes';
import { evaluateRequestArray } from 'utils/handleErrors';
import { getRegionCoordinates } from 'utils/dashboardUtils';
import PointerMap from 'components/PointerMap';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { PROVIDER } from 'constants/cloudProviders';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { CostByRegionColumns } from '../../../../constants';

type CostByRegionProps = {
  requestStatus: string;
  costByRegionData: CostByRegionType[];
  pdfView: boolean;
  costByRegionStartDate: string;
  costByRegionEndDate: string;
  onChangeCostByRegionDateRange: (
    dates: RangeValue<Moment>,
    dateString: [string, string]
  ) => void;
};

const CostByRegion = ({
  requestStatus,
  costByRegionData,
  pdfView,
  costByRegionStartDate,
  costByRegionEndDate,
  onChangeCostByRegionDateRange,
}: CostByRegionProps) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  const filters = (
    <div className="filters flex flex-align-items-center">
      <DatePicker
        defaultValue={[
          moment(costByRegionStartDate),
          moment(costByRegionEndDate),
        ]}
        onChange={onChangeCostByRegionDateRange}
        disabledDate={(current: Moment) => current > moment().endOf('day')}
        picker="month"
        format={MONTH_YEAR_FORMAT}
      />
    </div>
  );

  const getComponent = () => {
    return (
      <div className="graph flex flex-gap-16">
        <div className="width-30 full-height">
          <Table
            pagination={false}
            dataSource={costByRegionData.map((value, index) => ({
              ...value,
              key: index,
            }))}
            columns={CostByRegionColumns}
            scroll={{ y: 300 }}
            designVersion2
          />
        </div>
        <div
          className={`graph-area with-table width-70 ${
            pdfView && 'pdf-wrapper'
          }`}
        >
          <PointerMap
            data={costByRegionData.map((item) => ({
              ...item,
              coordinates: getRegionCoordinates(item.region, PROVIDER.OCI),
            }))}
            valueLabel={t('costByAwsRegion.cost')}
            labelField="region"
            valueField="cost"
            colorField="color"
            prefixSymbol={currencySymbol}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className="aws-cost-by-region map-card graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.costByRegion')}
        graphName="cost-by-region"
        isTableView={false}
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        filters={filters}
        designVersion2
        excelData={getChartExcelExportData(
          t('graphHeadings.costByRegion'),
          costByRegionData,
          CostByRegionColumns,
          {
            startDate: costByRegionStartDate,
            endDate: costByRegionEndDate,
          }
        )}
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};

export default CostByRegion;
