import { useState } from 'react';
import { Popover, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import Switch from 'components/Switch';
import { ColorPalleteType } from 'pages/AppearancePage/types';
import { CUSTOM_PALLETE_NAME_FIELD } from 'pages/AppearancePage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import Input from 'components/Input';
import ColorPicker from 'components/ColorPicker';
import {
  getValidationStyle,
  validateEmptyField,
  validateHexColorField,
} from 'utils/validations';

import ColorDisc from '../ColorDisc';

type GlobalAppearanceProps = {
  selectedStaticPallete: ColorPalleteType | undefined;
  setSelectedPallete: (value: ColorPalleteType) => void;
  staticPalletes: ColorPalleteType[];
  customPallete: ColorPalleteType;
  setCustomPallete: (value: ColorPalleteType) => void;
  isCustomTheme: boolean;
  setIsCustomTheme: (value: boolean) => void;
  dropdownRequestStatus: string;
};

const GlobalAppearance = ({
  selectedStaticPallete,
  setSelectedPallete,
  staticPalletes,
  customPallete,
  setCustomPallete,
  isCustomTheme,
  setIsCustomTheme,
  dropdownRequestStatus,
}: GlobalAppearanceProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'appearance' });

  // Validation states
  const [primaryColorValidation, setPrimaryColorValidation] = useState('');
  const [secondaryColorValidation, setSecondaryColorValidation] = useState('');
  const [buttonIconColorValidation, setButtonIconColorValidation] =
    useState('');
  const [backgroundColorValidation, setBackgroundColorValidation] =
    useState('');
  const [textColorValidation, setTextColorValidation] = useState('');

  const ColorPalleteOptions = staticPalletes.map((item) => (
    <Select.Option
      key={item.templateId ?? item.templateName}
      value={item.templateId ?? item.templateName}
    >
      <div className="flex flex-space-between">
        {item.templateName}
        <div className="color-disc-group flex flex-gap-8">
          <ColorDisc color={item.primaryColor} />
          <ColorDisc color={item.secondaryColor} />
          <ColorDisc color={item.buttonIconColor} />
          <ColorDisc color={item.backgroundColor} />
          <ColorDisc color={item.textColor} />
        </div>
      </div>
    </Select.Option>
  ));

  /**
   * @function getCustomPalletteInputFields
   * @description Function to get the JSX element of custom theme input fields
   * @param fieldName : name of the field
   * @param colorState : color react state of the field
   * @param setColorState : function to set the color state of the field
   * @param validationState : validation react state of the field
   * @param setValidation : function to set the validation state of the field
   * @returns JSX element of custom theme input fields
   */
  const getCustomPalletteInputFields = (
    fieldName: string,
    colorState: string,
    setColorState: (val: string) => void,
    validationState: string,
    setValidation: (val: string) => void
  ) => (
    <div className="form-item flex flex-column">
      <FormLabel title={fieldName} required />
      <Input
        prefix={
          <Popover
            placement="bottomLeft"
            trigger="click"
            overlayClassName="color-picker-popover"
            content={
              <ColorPicker
                color={colorState}
                onChange={(color) => {
                  setColorState(color);
                  validateHexColorField(color, fieldName, setValidation);
                }}
              />
            }
          >
            <ColorDisc
              color={colorState}
              className="color-disc cursor-pointer"
            />
          </Popover>
        }
        value={colorState}
        className="color-input full-width"
        onChange={(e: any) => {
          validateHexColorField(e.target.value, fieldName, setValidation);
          setColorState(e.target.value);
        }}
        onBlur={(e: any) => {
          e.target.value &&
            validateEmptyField(e.target.value, fieldName, setValidation);
          validateHexColorField(e.target.value, fieldName, setValidation);
        }}
      />
      <span
        style={{
          display: `${getValidationStyle(validationState)}`,
        }}
        className="font-validation-error"
      >
        {validationState}
      </span>
    </div>
  );

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="form-item flex flex-column">
        <FormLabel title={t('defaultTheme')} />
        <SelectDropdown
          value={selectedStaticPallete?.templateId ?? CUSTOM_PALLETE_NAME_FIELD}
          menu={ColorPalleteOptions}
          placeholder={t('selectDefaultTheme')}
          onSelect={(value: any) => {
            const newPallete = staticPalletes.find(
              (item) => item.templateId === value
            )!;
            setSelectedPallete(newPallete);
            setCustomPallete(newPallete);
          }}
          loading={dropdownRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      </div>
      <div className="form-item flex flex-space-between">
        <FormLabel title={t('customThemeSettings')} />
        <Switch
          size="small"
          checked={isCustomTheme}
          onChange={(value: boolean) => {
            setIsCustomTheme(value);
          }}
        />
      </div>
      {isCustomTheme && (
        <>
          {getCustomPalletteInputFields(
            t('primary'),
            customPallete.primaryColor,
            (val: string) => {
              setCustomPallete({ ...customPallete, primaryColor: val });
            },
            primaryColorValidation,
            setPrimaryColorValidation
          )}
          {getCustomPalletteInputFields(
            t('secondary'),
            customPallete.secondaryColor,
            (val: string) => {
              setCustomPallete({ ...customPallete, secondaryColor: val });
            },
            secondaryColorValidation,
            setSecondaryColorValidation
          )}
          {getCustomPalletteInputFields(
            t('buttonIcon'),
            customPallete.buttonIconColor,
            (val: string) => {
              setCustomPallete({ ...customPallete, buttonIconColor: val });
            },
            buttonIconColorValidation,
            setButtonIconColorValidation
          )}
          {getCustomPalletteInputFields(
            t('background'),
            customPallete.backgroundColor,
            (val: string) => {
              setCustomPallete({ ...customPallete, backgroundColor: val });
            },
            backgroundColorValidation,
            setBackgroundColorValidation
          )}
          {getCustomPalletteInputFields(
            t('text'),
            customPallete.textColor,
            (val: string) => {
              setCustomPallete({ ...customPallete, textColor: val });
            },
            textColorValidation,
            setTextColorValidation
          )}
        </>
      )}
    </div>
  );
};

export default GlobalAppearance;
