import axios from 'axios';
import { Dropdown, Menu, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  selectDashboard,
  setDashboardViewsList,
  setSelectedConnection,
  setSelectedDashboardView,
  setSelectedIntegrationConnection,
  setTableViewEnabled,
} from 'redux/dashboardSlice';
import {
  customDashboard,
  setCustomDashboardConnection,
  setCustomDashboardDataSource,
  setCustomDashboardGroup,
  setCustomDashboardIntegrationConnection,
  setCustomViewDimensions,
  setCustomViewMetrics,
  setDashboardType,
  setSelectedChartIndex,
  setSelectedChartRow,
  setSelectedDashboardCustomViews,
  setSelectedDimensions,
  setSelectedMetrics,
  setTagKeyValues,
} from 'redux/customDashboardSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import {
  CONSOLIDATED_DASHBOARD_NAVIGATION_DATA,
  CUSTOM_NAVIGATION_MENU_DROPDOWN,
  CUSTOM_NAVIGATION_MENU_DROPDOWN_IDS,
  DASHBOARD,
  getDashboardNavigationMenuByProvider,
  SNOWFLAKE_DASHBOARD_NAVIGATION,
} from 'pages/OverviewPage/components/ConnectionsDashboard/components/HorizontalNavigation/constants';
import DeleteModal from 'components/DeleteModal';
import { REQUEST_STATUS } from 'constants/requestBody';
import { NUMERIC_CATEGORY } from 'constants/newCustomView';
import { PROVIDER } from 'constants/cloudProviders';
import { DASHBOARD_TYPE } from 'types/navigationMenu';
import {
  fetchConnectionById,
  fetchIntegrationById,
  getBillingMappingData,
  getCustomDashboardDetails,
  getDashboardUsers,
  getLabels,
  getSystemBillingMappingData,
  getTagMappingData,
} from 'utils/services';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import {
  CUSTOM_DASHBOARD_MODE,
  FIELD_TYPE,
  FieldSource,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import {
  AvailableFieldsType,
  ConnectionListType,
  CustomViewDataType,
} from 'types/dashboard';
import HorizontalNavigationMenu from 'components/HorizontalNavigationMenu';
import { CUSTOM_VIEW_PUBLISH_STATUS } from 'pages/CustomDashboardPage/constants';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import { getProviderForConnection } from 'utils/dashboardUtils';
import { selectTheme } from 'redux/themeSlice';

import { getDefaultViewId } from '../../utils';
import { deleteCustomView } from '../../services';

type HorizontalNavigationProps = {
  NewViewTitle?: JSX.Element;
  setRequestStatus?: (val: string) => void;
  extraComponent?: JSX.Element;
};

const HorizontalNavigation = ({
  NewViewTitle,
  setRequestStatus,
  extraComponent,
}: HorizontalNavigationProps) => {
  const { t } = useTranslation();
  const {
    selectedDashboard,
    selectedConnection,
    selectedDashboardView,
    dashboardViewsList,
    selectedGroupMetaData,
    selectedIntegrationConnection,
  } = useSelector(selectDashboard);
  const {
    selectedDashboardCustomViews,
    customDashBoardMode,
    customViewData,
    isAddNewDashboard,
  } = useSelector(customDashboard);
  const { permissions } = useSelector(userAuthorization);
  const { theme } = useSelector(selectTheme);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCustomViewRequestStatus, setDeleteCustomViewRequestStatus] =
    useState('');
  const [connectionDataRequestStatus, setConnectionDataRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [customViewsRequestStatus, setCustomViewsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [connectorIdsRequestStatus, setConnectorIdsRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [
    billingAndTagMappingRequestStatus,
    setBillingAndTagMappingRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  useEffect(() => {
    dispatch(setSelectedDashboardCustomViews([]));
    dispatch(setDashboardViewsList([]));
    !NewViewTitle && setSelectedDashboardView('');

    getConnectionDetailsAndSetViews();
  }, [selectedDashboard, customDashBoardMode]);

  useEffect(() => {
    if (
      evaluateRequestArray([
        connectionDataRequestStatus,
        customViewsRequestStatus,
      ]) !== REQUEST_STATUS.SUCCESS
    )
      return;

    const selectedCustomViewData = selectedDashboardCustomViews.find(
      (item) => item.id === selectedDashboardView
    );
    if (!selectedCustomViewData) {
      setBillingAndTagMappingRequestStatus(REQUEST_STATUS.SUCCESS);
      return;
    }

    if (
      selectedCustomViewData.dataSource.dataset ===
        selectedConnection?.billingDataset &&
      !selectedCustomViewData.dataSource.useCustomQuery &&
      selectedDashboard?.dashBoardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION
    ) {
      fetchDimensionsAndTagMap(selectedCustomViewData);
    }
    dispatch(setDashboardType(selectedDashboard?.dashBoardType ?? ''));
    dispatch(setCustomDashboardConnection(selectedConnection));
    if (selectedDashboardView !== DASHBOARD.NEW_VIEW) {
      dispatch(
        setCustomDashboardDataSource({
          useCustomQuery: selectedCustomViewData.dataSource.useCustomQuery,
          customQueryId: selectedCustomViewData.dataSource.customQueryId,
          dataSet: selectedCustomViewData.dataSource.dataset ?? '',
          dashBoardType: selectedCustomViewData.dataSource.dashBoardType,
          availableFields: selectedCustomViewData.dataSource.availableFields,
          dashboardTitle: selectedDashboard?.name ?? '',
        })
      );
    }
  }, [
    selectedDashboardView,
    connectionDataRequestStatus,
    customViewsRequestStatus,
  ]);

  useEffect(() => {
    const status = evaluateRequestArray([
      connectionDataRequestStatus,
      customViewsRequestStatus,
      connectorIdsRequestStatus,
      billingAndTagMappingRequestStatus,
    ]);

    setRequestStatus?.(status);
    if (status !== REQUEST_STATUS.SUCCESS) {
      return;
    }

    const views = [
      ...dashboardViewsList,
      ...selectedDashboardCustomViews.map((item) => ({
        id: item.id ?? '',
        title: item.viewName,
      })),
    ];

    if (
      !views.some((item) => item.id === selectedDashboardView) &&
      !NewViewTitle
    ) {
      dispatch(
        setSelectedDashboardView(
          getDefaultViewId(
            selectedDashboard,
            selectedConnection,
            selectedIntegrationConnection,
            views
          )
        )
      );
    }
  }, [
    dashboardViewsList,
    selectedDashboardCustomViews,
    selectedConnection,
    selectedIntegrationConnection,
    connectionDataRequestStatus,
    customViewsRequestStatus,
    connectorIdsRequestStatus,
    billingAndTagMappingRequestStatus,
  ]);

  const fetchConnectionDetailsByDashboardType = (dashboardType: string) => {
    switch (dashboardType) {
      case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
      case MY_DASHBOARD_TYPES.IMPORTS:
        fetchConnectionDataById(false);
        break;

      case MY_DASHBOARD_TYPES.SNOWFLAKE:
        fetchIntegrationDataById();
        return;
    }
  };

  /**
   * @function getConnectionDetailsAndSetViews
   * @description Function to fetch the connection details and then set the views
   */
  const getConnectionDetailsAndSetViews = () => {
    if (!selectedDashboard) {
      return;
    }

    if (selectedDashboard.type === DASHBOARD_TYPE.CUSTOM) {
      fetchAndSetCustomViews();
      fetchConnectionDetailsByDashboardType(selectedDashboard.dashBoardType);
      return;
    }

    if (selectedDashboard.dashBoardType === MY_DASHBOARD_TYPES.SNOWFLAKE) {
      fetchAndFilterStaticViews(SNOWFLAKE_DASHBOARD_NAVIGATION);
      fetchIntegrationDataById();
      return;
    }

    if (selectedDashboard.dashBoardType === MY_DASHBOARD_TYPES.GROUP) {
      fetchAndFilterStaticViews(CONSOLIDATED_DASHBOARD_NAVIGATION_DATA);
    } else {
      fetchConnectionDataById(true);
    }
    fetchAndSetCustomViews();
  };

  /**
   * @function fetchIntegrationDataById
   * @description Function to fetch the Integration details
   * @param hasStaticViews boolean to check if the dashboard has static views or not.
   */
  const fetchIntegrationDataById = () => {
    if (!selectedDashboard) return;
    setConnectionDataRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      integrationId: selectedDashboard.integrationId,
    };
    fetchIntegrationById(params)
      .then((res: any) => {
        dispatch(setSelectedIntegrationConnection(res.data.responseData));
        fetchAndSetCustomViews();
        setConnectionDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((error: any) => {
        onApiCallError(error, false, setConnectionDataRequestStatus);
      });
  };

  /**
   * @function fetchConnectionDataById
   * @description Function to fetch the connection details
   * @param hasStaticViews boolean to check if the dashboard has static views or not.
   */
  const fetchConnectionDataById = (hasStaticViews: boolean) => {
    setConnectionDataRequestStatus(REQUEST_STATUS.PROCESSING);
    selectedDashboard &&
      fetchConnectionById(selectedDashboard.connectorId)
        .then((res: any) => {
          dispatch(setSelectedConnection(res.data.responseData));
          hasStaticViews &&
            setConnectionAndImportStaticViews(res.data.responseData);
          setConnectionDataRequestStatus(REQUEST_STATUS.SUCCESS);
        })
        .catch((error: any) => {
          onApiCallError(error, false, setConnectionDataRequestStatus);
        });
  };

  /**
   * @function fetchAndSetCustomViews
   * @description Function to fetch details of selected dashboard custom views.
   */
  const fetchAndSetCustomViews = () => {
    setCustomViewsRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestParams = {
      id: selectedDashboard?.id,
    };
    selectedDashboard &&
      getCustomDashboardDetails(requestParams)
        .then((res: any) => {
          const customViews: CustomViewDataType[] =
            res?.data?.responseData?.customViews;
          if (customViews) {
            dispatch(setSelectedDashboardCustomViews(customViews));
            setCustomViewsRequestStatus(REQUEST_STATUS.SUCCESS);
            return;
          }
          dispatch(setSelectedDashboardCustomViews([]));
          setCustomViewsRequestStatus(REQUEST_STATUS.SUCCESS);
        })
        .catch((error) => {
          dispatch(setSelectedDashboardCustomViews([]));
          onApiCallError(error, false, setCustomViewsRequestStatus);
        });
  };

  /**
   * @function setConnectionAndImportStaticViews
   * @description Function to set the static views for the selected connection dashboard or imports
   * @param connection Connection details
   */
  const setConnectionAndImportStaticViews = (
    connection: ConnectionListType
  ) => {
    if (!selectedDashboard) {
      return;
    }

    let views = getDashboardNavigationMenuByProvider(
      selectedDashboard.connectorProvider
    );

    if (selectedDashboard.dashBoardType === MY_DASHBOARD_TYPES.IMPORTS) {
      views = filterImportFileStaticViews(connection);
    } else {
      views = views.filter(
        (item) =>
          (![
            DASHBOARD.COST_SUMMARY,
            DASHBOARD.CREDIT_SUMMARY,
            DASHBOARD.PROJECT_DEEP_DIVE,
          ].includes(item.id) ||
            connection.wantBilling) &&
          (item.id !== DASHBOARD.COST_RECOMMENDATION_SUMMARY ||
            connection.wantRecommendations) &&
          (item.id !== DASHBOARD.CARBON_FOOTPRINT ||
            connection.wantCarbonFootprint)
      );
    }

    fetchAndFilterStaticViews(views);
  };

  /**
   * @function fetchAndFilterStaticViews
   * @description Function to fetch the static views of logged-in user and filter the views based on access
   * @param views Static views without filtering
   */
  const fetchAndFilterStaticViews = (views: any) => {
    if (permissions.dashboardListAll) {
      dispatch(setDashboardViewsList(views));
      return;
    }

    setConnectorIdsRequestStatus(REQUEST_STATUS.PROCESSING);
    getDashboardUsers(selectedDashboard?.id ?? '')
      .then((res: any) => {
        if (res.data.responseData.length > 0) {
          const userViews = res.data.responseData.find(
            (item: any) => item.userEmail === localStorage.getItem('email')
          )?.views;
          views = views.filter((view: any) => userViews.includes(view.id));
          dispatch(setDashboardViewsList(views));
          setConnectorIdsRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        dispatch(setDashboardViewsList([]));
        setConnectorIdsRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setConnectorIdsRequestStatus);
        dispatch(setDashboardViewsList([]));
      });
  };

  /**
   * @function filterImportFileStaticViews
   * @description Function to filter the static file import static views based on the dataset type. Note: This filter is not for the user access based filters
   * @returns list of filtered views
   */
  const filterImportFileStaticViews = (connection: ConnectionListType) => {
    let dashboardViews = getDashboardNavigationMenuByProvider(
      selectedDashboard?.connectorProvider ?? ''
    );

    if (
      [PROVIDER.GCP, PROVIDER.AZURE].includes(
        selectedDashboard?.connectorProvider as PROVIDER
      ) &&
      connection.focusConversionEnabled
    ) {
      dashboardViews = dashboardViews.filter(
        (view) =>
          ![DASHBOARD.CREDIT_SUMMARY, DASHBOARD.PROJECT_DEEP_DIVE].includes(
            view.id
          )
      );
    }

    if (!connection?.wantBilling) {
      dashboardViews = dashboardViews.filter(
        (view) =>
          ![
            DASHBOARD.COST_SUMMARY,
            DASHBOARD.CREDIT_SUMMARY,
            DASHBOARD.PROJECT_DEEP_DIVE,
            DASHBOARD.COMPUTE,
            DASHBOARD.STORAGE,
            DASHBOARD.DATABASE,
          ].includes(view.id)
      );
    }

    if (
      [PROVIDER.AWS, PROVIDER.AZURE].includes(
        selectedDashboard?.connectorProvider as PROVIDER
      ) &&
      connection.focusConversionEnabled
    ) {
      dashboardViews = dashboardViews.filter(
        (view) =>
          ![DASHBOARD.COMPUTE, DASHBOARD.STORAGE, DASHBOARD.DATABASE].includes(
            view.id
          )
      );
    }

    if (!connection?.wantRecommendations) {
      dashboardViews = dashboardViews.filter(
        (view) => ![DASHBOARD.COST_RECOMMENDATION_SUMMARY].includes(view.id)
      );
    }

    if (!connection?.wantCarbonFootprint) {
      dashboardViews = dashboardViews.filter(
        (view) => ![DASHBOARD.CARBON_FOOTPRINT].includes(view.id)
      );
    }

    return dashboardViews;
  };

  /**
   * @function onDeleteCustomView
   * @description Callback function for the delete view
   */
  const onDeleteCustomView = () => {
    setDeleteCustomViewRequestStatus(REQUEST_STATUS.PROCESSING);
    deleteCustomView(selectedDashboard?.id, selectedDashboardView)
      .then(() => {
        message.success(t('successfullyDeletedCustomView'));
        fetchAndSetCustomViews();
        setDeleteCustomViewRequestStatus(REQUEST_STATUS.SUCCESS);
        setShowDeleteModal(false);
        dispatch(setSelectedDashboardView(DASHBOARD.COST_SUMMARY));
      })
      .catch((_error) => {
        message.success(t('failedToDeletedCustomView'));
        setDeleteCustomViewRequestStatus(REQUEST_STATUS.ERROR);
      });
  };

  /**
   * @function fetchDimensionsAndTagMap
   * @description Function to fetch the billing mapping and tag mapping
   * @param dashboardType Dashboard type BILLING_DEFAULT or others
   * @param isDeveloperMode is going to developer mode
   */
  const fetchDimensionsAndTagMap = (
    customViewData: CustomViewDataType,
    isDeveloperMode = false
  ) => {
    setBillingAndTagMappingRequestStatus(REQUEST_STATUS.PROCESSING);
    const labelParams = {
      connectorId: selectedConnection?.connectorId,
      dashBoardType: customViewData?.dataSource.dashBoardType,
    };
    const requests = [
      getSystemBillingMappingData(),
      getBillingMappingData(),
      getTagMappingData(),
      getLabels(labelParams),
    ];

    axios
      .all(requests)
      .then((responses: any[]) => {
        const systemDimensionList = responses[0]?.data?.responseData ?? [];
        const billingMapDimensionList =
          responses[1]?.data?.responseData?.content ?? [];
        const tags = responses[2]?.data?.responseData?.content?.filter(
          (tagItem: any) =>
            tagItem?.cloudMapList?.some(
              (connection: any) =>
                connection.connectionId === selectedConnection?.connectorId
            )
        );
        const tagKeyValues =
          getProviderForConnection(selectedConnection) === PROVIDER.AWS
            ? responses[3]?.data
            : [];

        const allFields: AvailableFieldsType[] = [
          ...systemDimensionList.map((item: any) => {
            const fieldDetails = item.billingDimensionMap.find(
              (item: any) =>
                item.provider === getProviderForConnection(selectedConnection)
            );
            return {
              name: item.dimensionName,
              field: fieldDetails?.billingField,
              category: fieldDetails?.fieldType,
              source: FieldSource.SYSTEM,
            };
          }),
          ...billingMapDimensionList.map((item: any) => {
            const fieldDetails = item.billingDimensionMap.find(
              (item: any) =>
                item.provider === getProviderForConnection(selectedConnection)
            );
            return {
              name: item.dimensionName,
              field: fieldDetails?.billingField,
              category: fieldDetails?.fieldType,
              source: FieldSource.USER,
            };
          }),
          ...tags.map((item: any) => ({
            name: item.customTagName,
            field: item.customTagName,
            category: FIELD_TYPE.LITERAL,
            source: FieldSource.TAGS,
          })),
        ];

        dispatch(
          setCustomViewMetrics(
            allFields?.filter(
              (availableField: AvailableFieldsType) =>
                availableField.field &&
                availableField.category === FIELD_TYPE.NUMERIC
            )
          )
        );
        dispatch(
          setCustomViewDimensions(
            allFields?.filter(
              (availableField: AvailableFieldsType) =>
                availableField.field &&
                [
                  FIELD_TYPE.TIME.valueOf(),
                  FIELD_TYPE.LITERAL.valueOf(),
                ].includes(availableField.category)
            )
          )
        );
        dispatch(
          setTagKeyValues(
            tagKeyValues?.map((item: any) => ({
              key: item.field,
              value: item.rawField,
            })) ?? []
          )
        );

        dispatch(
          setCustomDashboardDataSource({
            useCustomQuery: customViewData?.dataSource.useCustomQuery,
            customQueryId: customViewData?.dataSource.customQueryId,
            dataSet: customViewData?.dataSource.dataset ?? '',
            dashBoardType: customViewData?.dataSource.dashBoardType ?? '',
            availableFields: allFields,
            dashboardTitle: selectedDashboard?.name ?? '',
          })
        );

        if (isDeveloperMode) {
          dispatch(setSelectedDimensions([]));
          dispatch(setSelectedMetrics([]));
          dispatch(setSelectedChartRow(0));
          dispatch(setSelectedChartIndex(0));
          navigate(NAVIGATION_MENU_PATH.CUSTOM_DASHBOARD);
        }
        setBillingAndTagMappingRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, true, setBillingAndTagMappingRequestStatus);
      });
  };

  const handleNavigationToDeveloperMode = () => {
    switch (selectedDashboard?.dashBoardType) {
      case MY_DASHBOARD_TYPES.GROUP:
        dispatch(setCustomDashboardConnection(null));
        dispatch(setSelectedIntegrationConnection(null));
        dispatch(setCustomDashboardGroup(selectedGroupMetaData));
        break;
      case MY_DASHBOARD_TYPES.SNOWFLAKE:
        dispatch(setCustomDashboardConnection(null));
        dispatch(setCustomDashboardGroup(null));
        dispatch(
          setCustomDashboardIntegrationConnection(selectedIntegrationConnection)
        );
        break;
      default:
        dispatch(setCustomDashboardGroup(null));
        dispatch(setSelectedIntegrationConnection(null));
        dispatch(setCustomDashboardConnection(selectedConnection));
        break;
    }

    if (
      selectedDashboard?.dashBoardType ===
        MY_DASHBOARD_TYPES.SINGLE_CONNECTION &&
      selectedConnection?.billingDataset ===
        customViewData?.dataSource.dataset &&
      !customViewData?.dataSource.useCustomQuery &&
      billingAndTagMappingRequestStatus !== REQUEST_STATUS.SUCCESS
    ) {
      fetchDimensionsAndTagMap(customViewData);
      return;
    }
    dispatch(setDashboardType(selectedDashboard?.dashBoardType ?? ''));
    dispatch(
      setCustomDashboardDataSource({
        useCustomQuery: customViewData?.dataSource.useCustomQuery,
        customQueryId: customViewData?.dataSource.customQueryId,
        dataSet: customViewData?.dataSource.dataset ?? '',
        dashBoardType: customViewData?.dataSource.dashBoardType ?? '',
        availableFields: customViewData?.dataSource.availableFields,
        dashboardTitle: selectedDashboard?.name ?? '',
      })
    );
    dispatch(
      setCustomViewDimensions(
        customViewData?.dataSource.availableFields?.filter(
          (availableField: AvailableFieldsType) =>
            availableField.category !== NUMERIC_CATEGORY
        ) || []
      )
    );
    dispatch(
      setCustomViewMetrics(
        customViewData?.dataSource.availableFields?.filter(
          (availableField: AvailableFieldsType) =>
            availableField.category === NUMERIC_CATEGORY
        )
      )
    );
    dispatch(setSelectedDimensions([]));
    dispatch(setSelectedMetrics([]));
    dispatch(setSelectedChartRow(0));
    dispatch(setSelectedChartIndex(0));
    navigate(NAVIGATION_MENU_PATH.CUSTOM_DASHBOARD);
  };

  const onHandleCustomNavigationMenuDropdown = (dropdownId: string) => {
    switch (dropdownId) {
      case CUSTOM_NAVIGATION_MENU_DROPDOWN_IDS.DELETE: {
        setShowDeleteModal(true);
        break;
      }
      case CUSTOM_NAVIGATION_MENU_DROPDOWN_IDS.DEVELOPER_MODE: {
        handleNavigationToDeveloperMode();
        break;
      }
    }
  };

  const CustomViewNavigationMenus =
    selectedDashboardCustomViews &&
    (customDashBoardMode === CUSTOM_DASHBOARD_MODE.DEV
      ? selectedDashboardCustomViews.filter(
          (view) => view.id !== customViewData?.id
        )
      : selectedDashboardCustomViews
    ).map((customView) => (
      <Menu.Item
        className={`font-caption-bold menu ${
          customView.id === selectedDashboardView &&
          !NewViewTitle &&
          'active-menu'
        }`}
        key={customView.id}
        eventKey={customView.id}
        disabled={!!NewViewTitle}
        onClick={() => {
          dispatch(setSelectedDashboardView(customView.id!));
          dispatch(setTableViewEnabled(false));
          dispatch(setDashboardType(selectedDashboard?.dashBoardType ?? ''));
          dispatch(setCustomDashboardGroup(selectedGroupMetaData));
        }}
      >
        <div className="flex flex-center flex-gap-8">
          {customView.viewName}
          {customView.viewStatus === CUSTOM_VIEW_PUBLISH_STATUS.DRAFT && (
            <div className="tag font-subHeader-small">{t('draftTag')}</div>
          )}
          {permissions.dashboardModify && (
            <Dropdown
              menu={{
                items: CUSTOM_NAVIGATION_MENU_DROPDOWN.map((item) => ({
                  key: item.id,
                  label: t(`customMenudropdown.${item.label}`),
                  danger:
                    item.id === CUSTOM_NAVIGATION_MENU_DROPDOWN_IDS.DELETE,
                  onClick: () => onHandleCustomNavigationMenuDropdown(item.id),
                })),
              }}
              trigger={['click']}
              placement="bottom"
              className="quickAction-dropdown"
              disabled={!!NewViewTitle}
            >
              <Icon
                iconName={ICONS.ARROW_DOWN_S_LINE}
                className="tag-suffix-icon flex flex-align-items-center full-height"
                size={ICONS_SIZE.XL}
                color={
                  customView.id === selectedDashboardView && !NewViewTitle
                    ? theme.buttonIconColor
                    : theme.textColor
                }
              />
            </Dropdown>
          )}
        </div>
      </Menu.Item>
    ));

  if (isAddNewDashboard) {
    return (
      <>
        <HorizontalNavigationMenu
          selectedKeys={[selectedDashboardView]}
          extraComponent={extraComponent}
          menuItems={[
            <Menu.Item
              key={DASHBOARD.NEW_VIEW}
              eventKey={DASHBOARD.NEW_VIEW}
              className="font-caption-bold active-menu"
            >
              {NewViewTitle}
            </Menu.Item>,
          ]}
        />
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={() => {
            onDeleteCustomView();
          }}
          loading={deleteCustomViewRequestStatus === REQUEST_STATUS.PROCESSING}
        />
      </>
    );
  }

  return (
    <>
      <HorizontalNavigationMenu
        menuItems={[
          ...dashboardViewsList.map((navMenu) => (
            <Menu.Item
              className={`font-caption-bold ${
                navMenu.id === selectedDashboardView &&
                !NewViewTitle &&
                'active-menu'
              } ${NewViewTitle && ' nav-menu-disabled'}`}
              onClick={() => {
                dispatch(setSelectedDashboardView(navMenu.id));
                dispatch(setTableViewEnabled(false));
              }}
              key={navMenu.id}
              eventKey={navMenu.id}
              disabled={!!NewViewTitle}
            >
              {t(`dashNav.${navMenu.title}`)}
            </Menu.Item>
          )),
          ...(selectedDashboardCustomViews ? CustomViewNavigationMenus : []),
          NewViewTitle && (
            <Menu.Item
              key={DASHBOARD.NEW_VIEW}
              eventKey={DASHBOARD.NEW_VIEW}
              className="font-caption-bold active-menu"
            >
              {NewViewTitle}
            </Menu.Item>
          ),
        ]}
        selectedKeys={[selectedDashboardView]}
        extraComponent={extraComponent}
        withCarousel={true}
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deletionFunction={() => {
          onDeleteCustomView();
        }}
        loading={deleteCustomViewRequestStatus === REQUEST_STATUS.PROCESSING}
      />
    </>
  );
};

export default HorizontalNavigation;
