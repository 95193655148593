import { message } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import { TagMappingType } from 'pages/TagMappingPage/types';
import { defaultTagMapData } from 'pages/TagMappingPage/constants';
import {
  createTagMapping,
  getTagDimensionNameValidation,
  updateTagMapping,
} from 'pages/TagMappingPage/services';
import { BUTTON_TYPE, INPUT_SIZE } from 'constants/appearance';
import {
  validateAlphanumericNames,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';

import ConnectionsTagDropdown from '../ConnectionsTagDropdown';
import SelectedConnectionsTags from '../SelectedConnectionsTags';

type AddOrEditTagMappingModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  isEditTagMapping?: boolean;
  editData?: TagMappingType;
  onClickSubmit: Function;
};

const AddOrEditTagMappingModal = ({
  show,
  setShow,
  isEditTagMapping,
  editData,
  onClickSubmit,
}: AddOrEditTagMappingModalProps) => {
  const { t } = useTranslation();

  const [tagMapData, setTagMapData] =
    useState<TagMappingType>(defaultTagMapData);
  const [tagNameValidation, setTagNameValidation] = useState('');
  const [isDuplicateTagName, setIsDuplicateTagName] = useState(false);
  const [fieldMappingValidation, setFieldMappingValidation] = useState('');
  const [submitReqStatus, setSubmitReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    if (isEditTagMapping && editData) {
      setTagMapData(editData);
    }
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (tagMapData.customTagName) {
      validateDuplicateTagName(tagMapData.customTagName, source);
    }

    return () => {
      source?.cancel();
    };
  }, [tagMapData.customTagName]);

  useEffect(() => {
    if (tagMapData?.cloudMapList?.length) {
      setFieldMappingValidation('');
    }
  }, [tagMapData?.cloudMapList]);

  useEffect(() => {
    if (isDuplicateTagName && tagNameValidation) {
      setTagNameValidation(t('tagMapping.duplicateTagName'));
    }
  }, [isDuplicateTagName, tagNameValidation]);

  /**
   * @function validateDuplicateTagName
   * @description Function to validate the duplicate tag name
   * @param value string value to be validated
   * @param source cancel token source
   */
  const validateDuplicateTagName = (
    value: string,
    source: CancelTokenSource
  ) => {
    if (isEditTagMapping && editData?.customTagName === value) {
      setIsDuplicateTagName(false);
      return;
    }

    const params = {
      tagName: value,
    };

    getTagDimensionNameValidation(params, source?.token)
      .then((res: any) => {
        const isDuplicate = res?.data?.responseData;
        setIsDuplicateTagName(isDuplicate);
        setTagNameValidation(
          isDuplicate ? t('tagMapping.duplicateTagName') : tagNameValidation
        );
      })
      .catch((error) => {
        onApiCallError(error);
      });
  };

  /**
   * @function validateTagName
   * @description Function to validate the tag name
   * @param value string value to be validated
   * @returns true if the validation is successful, else false
   */
  const validateTagName = (value: string) => {
    if (
      validateEmptyField(
        value?.trim(),
        t('tagMapping.tagName'),
        setTagNameValidation
      )
    ) {
      return false;
    }

    if (
      validateStringLengthLessThan(
        value?.trim(),
        MAX_CHARACTER_LIMIT,
        t('tagMapping.tagName'),
        setTagNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value.trim(),
        t('tagMapping.tagName'),
        setTagNameValidation
      )
    ) {
      return false;
    }

    if (isDuplicateTagName) {
      setTagNameValidation(t('tagMapping.duplicateTagName'));
      return false;
    }

    return true;
  };

  /**
   * @function validateFieldMapping
   * @description Function to validate the field mapping
   * @returns true if the validation is successful else false
   */
  const validateFieldMapping = () => {
    if (!tagMapData.cloudMapList.length) {
      setFieldMappingValidation(t('tagMapping.chooseAtleastOneTagField'));
      return false;
    }

    setFieldMappingValidation('');
    return true;
  };

  /**
   * @function validateForm
   * @description Function to validate the form fields
   * @returns true if the validation is successful, else false
   */
  const validateForm = () => {
    let validation = true;

    validation = validateTagName(tagMapData.customTagName) && validation;

    validation = validateFieldMapping() && validation;

    return validation;
  };

  /**
   * @function onClickAddOrUpdate
   * @description Function to add or update the billing map fields
   */
  const onClickAddOrUpdate = () => {
    if (!validateForm()) {
      return;
    }
    setSubmitReqStatus(REQUEST_STATUS.PROCESSING);
    const body = {
      ...tagMapData,
    };
    (isEditTagMapping ? updateTagMapping : createTagMapping)(body)
      .then((res: any) => {
        if (res.status === 200) {
          onClickSubmit();
          message.success(
            t(
              isEditTagMapping
                ? 'tagMapping.updateSuccessMessage'
                : 'tagMapping.createSuccessMessage',
              {
                tagName: tagMapData.customTagName,
              }
            )
          );
          setSubmitReqStatus(REQUEST_STATUS.SUCCESS);
        }
        setSubmitReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((error) => {
        onApiCallError(error, true, setSubmitReqStatus);
      });
  };

  const getConnectionTagDropdown = () => {
    return (
      <ConnectionsTagDropdown
        tagMapData={tagMapData}
        setTagMapData={setTagMapData}
      />
    );
  };

  return (
    <DrawerComponent
      className="tag-mapping-drawer"
      open={show}
      title={t('tagMapping.tagMapping')}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-gap-8 flex-end">
          <Button
            title={t('tagMapping.cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={
              isEditTagMapping ? t('tagMapping.update') : t('tagMapping.save')
            }
            onClick={onClickAddOrUpdate}
            loading={submitReqStatus === REQUEST_STATUS.PROCESSING}
          />
        </div>
      }
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel title={t('tagMapping.tagName')} required />
          <Input
            value={tagMapData.customTagName}
            placeholder={t('tagMapping.enterTagName')}
            onChange={(e: any) => {
              setTagMapData({
                ...tagMapData,
                customTagName: e.target.value,
              });
              validateTagName(e.target.value);
            }}
            onBlur={() => {
              validateTagName(tagMapData.customTagName);
            }}
          />
          <span
            style={{
              display: tagNameValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {tagNameValidation}
          </span>
        </div>
        <div className="form-item flex flex-column">
          <FormLabel title={t('tagMapping.fieldMapping')} required />
          <SelectDropdown
            value=""
            placeholder={t('tagMapping.selectFields')}
            data-testid="field-mapping-dropdown"
            popupClassName="field-mapping-overlay"
            dropdownRender={getConnectionTagDropdown}
            size={INPUT_SIZE.MEDIUM}
            designVersion2
          />
          <span
            style={{
              display: fieldMappingValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {fieldMappingValidation}
          </span>
        </div>
        {tagMapData.cloudMapList.length ? (
          <div className="form-item flex flex-column">
            <FormLabel title={t('tagMapping.selectedFields')} />
            <SelectedConnectionsTags
              tagMapData={tagMapData}
              setTagMapData={setTagMapData}
            />
          </div>
        ) : null}
      </section>
    </DrawerComponent>
  );
};

export default AddOrEditTagMappingModal;
