import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  selectCostAllocation,
  setAllProjectsList,
  setCostAllocationData,
  setCurrentStepValidation,
  setSelectedProjects,
  setTotalSharedCost,
} from 'redux/costAllocationSlice';
import DropdownCheckbox from 'components/DropdownCheckbox';
import Input from 'components/Input';
import { FormLabel } from 'components/FormLabel';
import { getValidationStyle } from 'utils/validations';

import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  QUERY_FIELDS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { OTHERS_LABEL } from 'constants/graphLabels';
import { VALIDATION_STATUS } from 'pages/ConnectingCSPPage/constants';
import {
  FULL_YEAR_FORMAT,
  MONTH_FORMAT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';

const ResourceGroupSelectionStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    costAllocationData,
    totalSharedCost,
    allProjectsList,
    selectedProjects,
  } = useSelector(selectCostAllocation);

  const [fetchResourceGroupsReqStatus, setFetchResourceGroupsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [resourceGroupsReqValidation, setResourceGroupsReqValidation] =
    useState<string>('');
  const [totalCost, setTotalCost] = useState<number>(0);
  const [totalCostReqStatus, setTotalCostReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [
    selectedResourceGroupsCostReqStatus,
    setSelectedResourceGroupsCostReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);

  useEffect(() => {
    getResourceGroupsList();
    getCostForAllTheResourceGroups();
  }, []);

  useEffect(() => {
    dispatch(
      setCostAllocationData({
        ...costAllocationData,
        projects: selectedProjects,
      })
    );

    const source = axios.CancelToken.source();

    if (selectedProjects.length > 0) {
      setResourceGroupsReqValidation('');
      getTotalSharedCost(source);
    } else {
      dispatch(setTotalSharedCost(0));
      setSelectedResourceGroupsCostReqStatus(REQUEST_STATUS.SUCCESS);
    }

    return () => {
      source.cancel();
    };
  }, [selectedProjects]);

  useEffect(() => {
    dispatch(setCurrentStepValidation(validateSelectResourceGroupsStep()));
  }, [
    costAllocationData.projects,
    fetchResourceGroupsReqStatus,
    totalCostReqStatus,
    selectedResourceGroupsCostReqStatus,
  ]);

  /**
   * @function getResourceGroupsList
   * @description Function to fetch the list of resource groups for the selected month.
   */
  const getResourceGroupsList = () => {
    setFetchResourceGroupsReqStatus(REQUEST_STATUS.PROCESSING);
    const requestBody = {
      columns: [
        {
          label: 'resourceGroup',
          field: QUERY_FIELDS.RESOURCE_GROUP,
        },
      ],
      aggregators: [
        {
          label: 'resourceGroup',
          function: AGGREGATORS.DISTINCT,
        },
      ],
      groupBy: ['resourceGroup'],
      distinct: true,
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.YEAR_DATE,
              comparator: COMPARATORS.EQUALS,
              value: moment(
                costAllocationData.month,
                YEAR_MONTH_WITHOUT_SEPARATOR
              ).format(FULL_YEAR_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.MONTH_DATE,
              comparator: COMPARATORS.EQUALS,
              value: moment(
                costAllocationData.month,
                YEAR_MONTH_WITHOUT_SEPARATOR
              ).format(MONTH_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, costAllocationData.connection)
      .then((res: any) => {
        dispatch(
          setAllProjectsList(
            res?.data?.map(
              (resourceGroup: any) =>
                resourceGroup?.resourceGroup ?? OTHERS_LABEL
            )
          )
        );
        setFetchResourceGroupsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setFetchResourceGroupsReqStatus));
  };

  /**
   * @function getCostForAllTheResourceGroups
   * @description Function to fetch the cost of all the Resource Groups for the selected month.
   */
  const getCostForAllTheResourceGroups = () => {
    setTotalCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      columns: [
        { label: 'cost', field: QUERY_FIELDS.SUM_COST_IN_BILLING_CURRENCY },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.YEAR_DATE,
              comparator: COMPARATORS.EQUALS,
              value: moment(
                costAllocationData.month,
                YEAR_MONTH_WITHOUT_SEPARATOR
              ).format(FULL_YEAR_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.MONTH_DATE,
              comparator: COMPARATORS.EQUALS,
              value: moment(
                costAllocationData.month,
                YEAR_MONTH_WITHOUT_SEPARATOR
              ).format(MONTH_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, costAllocationData.connection)
      .then((res: any) => {
        setTotalCost((res?.data[0]?.cost ?? 0).toFixed(2));
        setTotalCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setTotalCostReqStatus));
  };

  /**
   * @function selectedResourceGroupsToString
   * @description Function to convert list of string quoted comma separated string
   * @returns quoted comma separated string
   */
  const selectedResourceGroupsToString = () => {
    const projects = selectedProjects.map((item) =>
      item === OTHERS_LABEL ? 'null' : item
    );
    const selectedProjectsString = "('";
    return selectedProjectsString + projects.join("','") + "')";
  };

  /**
   * @function getTotalSharedCost
   * @description Function to fetch the cost for the selected resource groups.
   * @param cancelTokenSource cancel token
   */
  const getTotalSharedCost = (cancelTokenSource: CancelTokenSource) => {
    setSelectedResourceGroupsCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: QUERY_FIELDS.SUM_COST_IN_BILLING_CURRENCY,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: QUERY_FIELDS.RESOURCE_GROUP,
              comparator: COMPARATORS.IN,
              value: selectedResourceGroupsToString(),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.YEAR_DATE,
              comparator: COMPARATORS.EQUALS,
              value: moment(
                costAllocationData.month,
                YEAR_MONTH_WITHOUT_SEPARATOR
              ).format(FULL_YEAR_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.MONTH_DATE,
              comparator: COMPARATORS.EQUALS,
              value: moment(
                costAllocationData.month,
                YEAR_MONTH_WITHOUT_SEPARATOR
              ).format(MONTH_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(
      requestBody,
      costAllocationData.connection,
      null,
      cancelTokenSource.token
    )
      .then((res: any) => {
        dispatch(
          setTotalSharedCost(Number((res?.data?.[0]?.cost ?? 0).toFixed(2)))
        );
        setSelectedResourceGroupsCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) =>
        onApiCallError(e, false, setSelectedResourceGroupsCostReqStatus)
      );
  };

  /**
   * @function validateSelectResourceGroupsStep
   * @description Function to validate the resource groups selection step
   * @returns string validation status (VALID, INVALID or SKIP)
   */
  const validateSelectResourceGroupsStep = () => {
    if (
      [
        fetchResourceGroupsReqStatus,
        totalCostReqStatus,
        selectedResourceGroupsCostReqStatus,
      ].includes(REQUEST_STATUS.PROCESSING)
    ) {
      return VALIDATION_STATUS.INVALID;
    }

    if (costAllocationData?.projects?.length > 0) {
      return VALIDATION_STATUS.VALID;
    }

    return VALIDATION_STATUS.INVALID;
  };

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('costAllocations.selectResourceGroups')}
          required={true}
        />
        <DropdownCheckbox
          itemOptions={allProjectsList.map((project) => ({
            title: project,
            value: project,
          }))}
          selectedItems={costAllocationData?.projects || []}
          setSelectedItems={(values) => {
            dispatch(setSelectedProjects(values));
            values.length > 0
              ? setResourceGroupsReqValidation('')
              : setResourceGroupsReqValidation(
                  t('costAllocations.resourceGroupsRequired')
                );
          }}
          onHandleBlur={() =>
            costAllocationData?.projects?.length > 0
              ? setResourceGroupsReqValidation('')
              : setResourceGroupsReqValidation(
                  t('costAllocations.resourceGroupsRequired')
                )
          }
          designVersion2
          showSearch={true}
          showSelectionCount={true}
          valueLabelSuffix={t('costAllocations.resourceGroups')}
          loading={fetchResourceGroupsReqStatus === REQUEST_STATUS.PROCESSING}
          isDisabled={
            fetchResourceGroupsReqStatus === REQUEST_STATUS.PROCESSING
          }
        />
        <span
          style={{
            display: getValidationStyle(resourceGroupsReqValidation),
          }}
          className="font-validation-error"
        >
          {resourceGroupsReqValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('costAllocations.totalSharedCost')} />
        <Input
          value={
            totalCostReqStatus === REQUEST_STATUS.PROCESSING
              ? 'Loading...'
              : totalSharedCost.toFixed(2)
          }
          disabled
          designVersion2
        />
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('costAllocations.residualCostMonth')} />
        <Input
          value={
            [selectedResourceGroupsCostReqStatus, totalCostReqStatus].includes(
              REQUEST_STATUS.PROCESSING
            )
              ? 'Loading...'
              : (totalCost - totalSharedCost).toFixed(2)
          }
          disabled
          designVersion2
        />
      </div>
    </div>
  );
};

export default ResourceGroupSelectionStep;
