import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { sum } from 'lodash';
import { RangeValue } from 'rc-picker/lib/interface';

import ControlComponent from 'components/DashboardControl';
import DropdownCheckbox from 'components/DropdownCheckbox';
import DashboardGraphContainer from 'components/DashboardGraphContainer';
import ColumnChartWithTable from 'components/ColumnChartWithTable';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import TagFilters from 'components/TagFilters';
import { INPUT_SIZE } from 'constants/appearance';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getForecastedCost } from 'pages/OverviewPage/components/ConnectionsDashboard/services';
import { ForecastRequestBody } from 'pages/OverviewPage/components/ConnectionsDashboard/types';
import { getPdfMetaData } from 'pages/OverviewPage/components/ConnectionsDashboard/utils';
import { ChartCriteria, ChartFilterType } from 'constants/dashboard';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { QUARTER_COST_SUMMARY } from 'constants/graphLabels';
import { ComparisonListType, GraphRowContentType } from 'types/dashboard';
import {
  CostByProjectDatasetType,
  CostByProjectType,
  ColouredCostByServiceType,
  MonthlyCostByProjectDatasetType,
  MonthlyCostByServiceType,
  CostByServiceConsolidatedCostType,
  ColouredCostByResourceType,
  MonthlyCostType,
  CostByNameType,
  TypeAndNameByCost,
} from 'types/dataTypes';
import { getChartData } from 'utils/services';
import {
  addZeroMarginClass,
  generateGraphColors,
  getCurrentQuarterStartMonthAndYear,
  getFirstMonthAndCurrentYear,
  getGCPTagFiltersData,
  getPercentageDifference,
  getPreviousMonthAndYear,
  getPreviousMonthDayAndYear,
  getPreviousQuarterEndMonthAndYear,
  getPreviousQuarterStartMonthAndYear,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
  removeZeroMarginClass,
  transformStackedDataByGroupForPptExport,
} from 'utils/dashboardUtils';
import { calculateTotalCostSum } from 'utils/CostSum';
import {
  formatDateFieldByProvider,
  getDateFilterAsString,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { CHART_TYPES, GRAPH_OPTIONS } from 'constants/graphConfig';
import ComparisonCard from 'components/NewComparisonCard';
import { onApiCallError } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { getDateRangeHandler, getMonthRangeHandler } from 'utils/graphUtils';
import { PROVIDER } from 'constants/cloudProviders';

import CostTrend from './components/CostTrend';
import CostByGCPService from './components/CostByGCPService';
import QuarterCostSummary from './components/QuarterCostSummary';
import CostByProjectMixed from './components/CostByProjectMixed';
import MonthlyCostByProject from './components/MonthlyCostByProject';
import {
  getCostByServiceColumns,
  getCostTrendsData,
  getMonthlyCostByProjectColumns,
} from './utils';
import { getUniqueProjects } from './components/MonthlyCostByProject/utils';
import { getUniqueServices } from './components/CostByGCPService/utils';
import SpendProfileChartWrapper from '../SpendProfileChartWrapper';
import { SpendProfileDataType } from '../SpendProfileChartWrapper/types';
import { getSpendProfilePptContent } from '../SpendProfileChartWrapper/utils';
import {
  CHART_CRITERIA_LIST,
  CostByProjectMixedColumns,
  GcpCloudSpendColumns,
} from './constants';
import {
  getCostTrendsHeading,
  getCostTrendsColumns,
  fetchCloudSpendData,
  fetchTaggedUntaggedSpendData,
  getCostTrendChartLegends,
  getCostTrendXAxisLabel,
} from '../../utils';
import { TaggedUntaggedSpendColumns } from '../../constants';

const GCPCostSummaryDashboard = () => {
  const { t } = useTranslation();
  const {
    selectedDashboard,
    selectedConnection,
    tagsFilters,
    selectedDashboardView,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [chartCriteriaFilters, setChartCriteriaFilters] = useState<string[]>(
    CHART_CRITERIA_LIST.map((item) => item.value)
  );
  const [costComparisonList, setCostComparisonList] = useState<
    ComparisonListType[]
  >([]);

  //States for comparison cards
  const [currentYearCost, setCurrentYearCost] = useState(0);
  const [currentYearCostRequestStatus, setCurrentYearCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousYearCost, setPreviousYearCost] = useState(0);
  const [previousYearCostRequestStatus, setPreviousYearCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [currentQuarterCost, setCurrentQuarterCost] = useState(0);
  const [currentQuarterCostRequestStatus, setCurrentQuarterCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousQuarterCost, setPreviousQuarterCost] = useState(0);
  const [
    previousQuarterCostRequestStatus,
    setPreviousQuarterCostRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [currentMonthCost, setCurrentMonthCost] = useState(0);
  const [currentMonthCostRequestStatus, setCurrentMonthCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousMonthCost, setPreviousMonthCost] = useState(0);
  const [previousMonthCostRequestStatus, setPreviousMonthCostRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);
  const [previousSecondMonthCost, setPreviousSecondMonthCost] = useState(0);
  const [
    previousSecondMonthCostRequestStatus,
    setPreviousSecondMonthCostRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  // ML forecasted cost
  const [nextMonthForeCastedCost, setNextMonthForeCastedCost] = useState(0);
  const [
    nextMonthForecastedCostReqStatus,
    setNextMonthForecastedCostReqStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [foreCastedCost, setForeCastedCost] = useState<MonthlyCostType[]>([]);
  const [forecastErrorMessage, setForecastErrorMessage] = useState('');
  const [foreCastedCostRequestStatus, setForeCastedCostRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [forecastDateRange, setForecastDateRange] = useState([
    moment().format(HYPHEN_DATE_FORMAT),
    moment().add(3, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
  ]);

  //State for Cost Trend Data
  const [costTrendData, setCostTrendData] = useState<MonthlyCostType[]>([]);
  const [costTrendRequestStatus, setCostTrendRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [costTrendStartMonth, setCostTrendStartMonth] = useState(
    getPreviousMonthAndYear(11)
  );
  const [costTrendEndMonth, setCostTrendEndMonth] = useState(
    getPreviousMonthAndYear(0)
  );
  const [selectedTrendMonth, setSelectedTrendMonth] = useState<string>();
  const [selectedTrendService, setSelectedTrendService] = useState<string>();
  const [monthWiseServiceData, setMonthWiseServiceData] = useState<{
    [key: string]: ColouredCostByServiceType[];
  }>();
  const [monthServiceResourceData, setMonthServiceResourceData] = useState<{
    [key: string]: ColouredCostByResourceType[];
  }>();
  const [costTrendSliderValue, setCostTrendSliderValue] = useState<{
    x: number;
    y: number;
  }>();

  // States for Cloud spend
  const [cloudSpendData, setCloudSpendData] = useState<CostByNameType[]>([]);
  const [selectedCloudSpendData, setSelectedCloudSpendData] = useState<
    CostByNameType[]
  >([]);
  const [cloudSpendStartDate, setCloudSpendStartDate] = useState<string>(
    getPreviousMonthDayAndYear(12)
  );
  const [cloudSpendEndDate, setCloudSpendEndDate] = useState<string>(
    getPreviousMonthDayAndYear(0)
  );
  const [cloudSpendRequestStatus, setCloudSpendRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [isCloudSpendTableView, setIsCloudSpendTableView] = useState(false);

  // States for Tagged UnTagged Spend
  const [taggedUntaggedSpendData, setTaggedUntaggedSpendData] = useState<
    TypeAndNameByCost[]
  >([]);
  const [taggedUntaggedTableData, setTaggedUntaggedTableData] = useState<any[]>(
    []
  );
  const [taggedUntaggedSpendStartMonth, setTaggedUntaggedSpendStartMonth] =
    useState(
      moment().startOf('month').subtract(12, 'month').format(HYPHEN_DATE_FORMAT)
    );
  const [taggedUntaggedSpendEndMonth, setTaggedUntaggedSpendEndMonth] =
    useState(moment().format(HYPHEN_DATE_FORMAT));
  const [
    taggedUntaggedSpendRequestStatus,
    setTaggedUntaggedSpendRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [isTaggedUntaggedSpendTableView, setIsTaggedUntaggedSpendTableView] =
    useState(false);

  //State for Monthly Cost By Project Data
  const [monthWiseCostByProject, setMonthWiseCostByProject] = useState<
    CostByProjectType[]
  >([]);
  const [
    selectedMonthlyCostByProjectData,
    setSelectedMonthlyCostByProjectData,
  ] = useState<CostByProjectType[]>([]);
  const [
    monthlyCostByProjectRequestStatus,
    setMonthlyCostByProjectRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [monthlyCostByProjectStartDate, setMonthlyCostByProjectStartDate] =
    useState(getPreviousMonthAndYear(11));
  const [monthlyCostByProjectEndDate, setMonthlyCostByProjectEndDate] =
    useState(getPreviousMonthAndYear(0));

  //State for Cost By Project Data
  const [costByProjectData, setCostByProjectData] = useState<
    CostByProjectDatasetType[]
  >([]);
  const [selectedCostByProjectData, setSelectedCostByProjectData] = useState<
    CostByProjectDatasetType[]
  >([]);
  const [costByProjectDataRequestStatus, setCostByProjectDataRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  //State for Cost by Service Data
  const [monthlyCostByServiceData, setMonthlyCostByServiceData] = useState<
    MonthlyCostByServiceType[]
  >([]);
  const [
    selectedMonthlyCostByServiceData,
    setSelectedMonthlyCostByServiceData,
  ] = useState<MonthlyCostByServiceType[]>([]);
  const [costByServiceRequestStatus, setCostByServiceRequestStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  // States for Application Profile Spend
  const [applicationProfileSpendData, setApplicationProfileSpendData] =
    useState<SpendProfileDataType[]>([]);
  const [
    applicationProfileSpendReqStatus,
    setApplicationProfileSpendReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [spendProfileStartMonth, setSpendProfileStartMonth] = useState(
    moment().startOf('month').subtract(12, 'month').format(HYPHEN_DATE_FORMAT)
  );
  const [spendProfileEndMonth, setSpendProfileEndMonth] = useState(
    moment().format(HYPHEN_DATE_FORMAT)
  );

  //States for table view
  const [isCostTrendTableView, setIsCostTrendTableView] = useState(false);
  const [isCostByServiceTableView, setIsCostByServiceTableView] =
    useState(false);
  const [isCostByProjectTableView, setIsCostByProjectTableView] =
    useState(false);
  const [isMonthlyCostByProjectTableView, setIsMonthlyCostByProjectTableView] =
    useState(false);

  useEffect(() => {
    addZeroMarginClass('dashboard-view');

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    setCurrentYearCost(0);
    setPreviousYearCost(0);
    setCurrentQuarterCost(0);
    setPreviousQuarterCost(0);
    setCurrentMonthCost(0);
    setPreviousMonthCost(0);
    setPreviousSecondMonthCost(0);
    setNextMonthForeCastedCost(0);
    setCostByProjectData([]);
    setMonthlyCostByServiceData([]);

    // API calls
    getCostComparisonCardsData();

    // Do not fetch the forecast data for static file import as the forecast model is not implemented for static data
    if (!isDashboardWithStaticData(selectedDashboard)) {
      getNextMonthForecastCost();
    }

    getGCPCostByServiceData();

    // Do not fetch the project related data when the data source is static file as there is no FOCUS mapping
    if (!isDashboardWithStaticData(selectedDashboard)) {
      getGCPCostByProjectData();
    }
  }, [selectedDashboard, tagsFilters]);

  useEffect(() => {
    setForeCastedCost([]);
    // Do not fetch the forecast data for static file import as the forecast model is not implemented for static data
    if (!isDashboardWithStaticData(selectedDashboard)) {
      getForecastCost();
    }
  }, [selectedDashboard, forecastDateRange]);

  useEffect(() => {
    const services = getUniqueServices(monthlyCostByServiceData).slice(0, 10);
    setSelectedMonthlyCostByServiceData(
      monthlyCostByServiceData.filter((item) =>
        services.some((service) => service.service === item.service)
      )
    );
  }, [monthlyCostByServiceData]);

  useEffect(() => {
    setSelectedCostByProjectData(costByProjectData.slice(0, 10));
  }, [costByProjectData]);

  useEffect(() => {
    const projects = getUniqueProjects(monthWiseCostByProject).slice(0, 10);
    setSelectedMonthlyCostByProjectData(
      monthWiseCostByProject.filter((item) =>
        projects.some((project) => project.project === item.project)
      )
    );
  }, [monthWiseCostByProject]);

  useEffect(() => {
    const comparisonCardDataList: ComparisonListType[] = [
      {
        value: currentYearCost,
        heading: t('costComparisonCard.ytdCosts'),
        comparisonValue: getPercentageDifference(
          currentYearCost,
          previousYearCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousYear'),
        requestStatus: [
          currentYearCostRequestStatus,
          previousYearCostRequestStatus,
        ],
      },
      {
        value: currentQuarterCost,
        heading: t('costComparisonCard.qtdCosts'),
        comparisonValue: getPercentageDifference(
          currentQuarterCost,
          previousQuarterCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousQuarter'),
        requestStatus: [
          currentQuarterCostRequestStatus,
          previousQuarterCostRequestStatus,
        ],
      },
      {
        value: currentMonthCost,
        heading: t('costComparisonCard.currentMonthCost'),
        comparisonValue: getPercentageDifference(
          currentMonthCost,
          previousMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousMonth'),
        requestStatus: [
          currentMonthCostRequestStatus,
          previousMonthCostRequestStatus,
        ],
      },
      {
        value: previousMonthCost,
        heading: t('costComparisonCard.lastMonthCost'),
        comparisonValue: getPercentageDifference(
          previousMonthCost,
          previousSecondMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromTwoMonthsBack'),
        requestStatus: [
          previousMonthCostRequestStatus,
          previousSecondMonthCostRequestStatus,
        ],
      },
    ];

    if (!isDashboardWithStaticData(selectedDashboard)) {
      comparisonCardDataList.push({
        value: nextMonthForeCastedCost,
        heading: t('costComparisonCard.nextMonthForecastedCost'),
        comparisonValue: getPercentageDifference(
          nextMonthForeCastedCost,
          previousMonthCost
        ),
        comparisonFrom: t('costComparisonCard.fromPreviousMonth'),
        requestStatus: [
          previousMonthCostRequestStatus,
          nextMonthForecastedCostReqStatus,
        ],
        errorMessage: forecastErrorMessage,
      });
    }

    setCostComparisonList(comparisonCardDataList);
  }, [
    currentYearCost,
    previousYearCost,
    currentQuarterCost,
    previousQuarterCost,
    currentMonthCost,
    previousMonthCost,
    previousSecondMonthCost,
    costTrendData,
    currentYearCostRequestStatus,
    previousYearCostRequestStatus,
    currentQuarterCostRequestStatus,
    previousQuarterCostRequestStatus,
    currentMonthCostRequestStatus,
    previousMonthCostRequestStatus,
    previousSecondMonthCostRequestStatus,
    nextMonthForecastedCostReqStatus,
    forecastErrorMessage,
  ]);

  useEffect(() => {
    setCostTrendData([]);
    setSelectedTrendMonth(undefined);
    setSelectedTrendService(undefined);
    getCostTrendData();
  }, [selectedDashboard, costTrendEndMonth, costTrendStartMonth, tagsFilters]);

  useEffect(() => {
    setCostTrendSliderValue(undefined);
    if (selectedTrendMonth && !monthWiseServiceData?.[selectedTrendMonth]) {
      getCostByServiceForTrends();
    }
  }, [selectedTrendMonth]);

  useEffect(() => {
    setCostTrendSliderValue(undefined);
    if (
      selectedTrendMonth &&
      selectedTrendService &&
      !monthServiceResourceData?.[
        `${selectedTrendMonth}${selectedTrendService}`
      ]
    ) {
      getCostByResourceForTrends();
    }
  }, [selectedTrendMonth, selectedTrendService]);

  useEffect(() => {
    setMonthWiseCostByProject([]);

    // Do not fetch the project related data when the data source is static file as there is no FOCUS mapping
    if (!isDashboardWithStaticData(selectedDashboard)) {
      getGCPMonthlyCostByProjectData();
    }
  }, [
    selectedDashboard,
    monthlyCostByProjectStartDate,
    monthlyCostByProjectEndDate,
    tagsFilters,
  ]);

  useEffect(() => {
    // Do not fetch the project related data when the data source is static file as there is no FOCUS mapping
    if (!isDashboardWithStaticData(selectedDashboard)) {
      fetchCloudSpendData(
        setCloudSpendRequestStatus,
        selectedDashboard!,
        selectedConnection!,
        {
          startDate: cloudSpendStartDate,
          endDate: cloudSpendEndDate,
        },
        tagsFilters,
        selectedDashboardView,
        {
          setData: setCloudSpendData,
          setSelectedData: setSelectedCloudSpendData,
        }
      );
    }
  }, [selectedDashboard, cloudSpendStartDate, cloudSpendEndDate, tagsFilters]);

  useEffect(() => {
    // Do not fetch the project related data when the data source is static file as there is no FOCUS mapping
    if (!isDashboardWithStaticData(selectedDashboard)) {
      fetchTaggedUntaggedSpendData(
        setTaggedUntaggedSpendRequestStatus,
        {
          startMonth: taggedUntaggedSpendStartMonth,
          endMonth: taggedUntaggedSpendEndMonth,
        },
        {
          setData: setTaggedUntaggedSpendData,
          setTableData: setTaggedUntaggedTableData,
        },
        selectedDashboard!,
        selectedConnection!,
        tagsFilters,
        selectedDashboardView
      );
    }
  }, [
    selectedDashboard,
    taggedUntaggedSpendStartMonth,
    taggedUntaggedSpendEndMonth,
    tagsFilters,
  ]);

  const getGCPCostByPeriodData = (
    fromMonth: string,
    toMonth: string,
    setData: (val: number) => void,
    setRequestStatus: (val: string) => void
  ) => {
    setRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                fromMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                toMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],

      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        setData(Number.parseFloat((res?.data[0]?.cost || 0).toFixed(2)));
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setRequestStatus);
      });
  };

  /**
   * @function onChangeCostTrendDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostTrendDateRange = useCallback(
    (_dates: RangeValue<Moment>, dateString: [string, string]) => {
      const startMonth = moment(dateString[0], MONTH_YEAR_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      );
      const endMonth = moment(dateString[1], MONTH_YEAR_FORMAT).isAfter(
        moment(),
        'months'
      )
        ? moment().format(YEAR_MONTH_WITHOUT_SEPARATOR)
        : moment(dateString[1], MONTH_YEAR_FORMAT).format(
            YEAR_MONTH_WITHOUT_SEPARATOR
          );
      const forecastStartMonth = moment(
        dateString[0],
        MONTH_YEAR_FORMAT
      ).isSameOrBefore(moment(), 'months')
        ? moment().format(HYPHEN_DATE_FORMAT)
        : moment(dateString[0], MONTH_YEAR_FORMAT)
            .startOf('month')
            .format(HYPHEN_DATE_FORMAT);
      const forecastEndMonth = moment(dateString[1], MONTH_YEAR_FORMAT)
        .endOf('month')
        .format(HYPHEN_DATE_FORMAT);
      setCostTrendStartMonth(startMonth);
      setCostTrendEndMonth(endMonth);
      setForecastDateRange([forecastStartMonth, forecastEndMonth]);
    },
    []
  );

  /**
   * @function onChangeCostByProjectDateRange
   * @description Function to update start and end month
   * @param _dates output from date picker
   * @param dateString array of start and end date in months
   */
  const onChangeCostByProjectDateRange = useCallback(
    (_dates: RangeValue<Moment>, dateString: [string, string]) => {
      setMonthlyCostByProjectStartDate(
        moment(dateString[0], MONTH_YEAR_FORMAT).format(
          YEAR_MONTH_WITHOUT_SEPARATOR
        )
      );
      setMonthlyCostByProjectEndDate(
        moment(dateString[1], MONTH_YEAR_FORMAT).format(
          YEAR_MONTH_WITHOUT_SEPARATOR
        )
      );
    },
    []
  );

  const getCostComparisonCardsData = async () => {
    //Current Year Cost
    getGCPCostByPeriodData(
      getFirstMonthAndCurrentYear(),
      getPreviousMonthAndYear(0),
      setCurrentYearCost,
      setCurrentYearCostRequestStatus
    );

    // Previous year Cost
    getGCPCostByPeriodData(
      moment()
        .subtract(1, 'year')
        .startOf('year')
        .format(YEAR_MONTH_WITHOUT_SEPARATOR),
      moment()
        .subtract(1, 'year')
        .endOf('year')
        .format(YEAR_MONTH_WITHOUT_SEPARATOR),
      setPreviousYearCost,
      setPreviousYearCostRequestStatus
    );

    // Current Quarter Cost
    getGCPCostByPeriodData(
      getCurrentQuarterStartMonthAndYear(),
      getPreviousMonthAndYear(0),
      setCurrentQuarterCost,
      setCurrentQuarterCostRequestStatus
    );

    // Previous Quarter Cost
    getGCPCostByPeriodData(
      getPreviousQuarterStartMonthAndYear(),
      getPreviousQuarterEndMonthAndYear(),
      setPreviousQuarterCost,
      setPreviousQuarterCostRequestStatus
    );

    // Current Month Cost
    getGCPCostByPeriodData(
      moment().startOf('month').format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
      setCurrentMonthCost,
      setCurrentMonthCostRequestStatus
    );

    // Previous Month Cost
    getGCPCostByPeriodData(
      moment().subtract(1, 'month').startOf('month').format(HYPHEN_DATE_FORMAT),
      moment().subtract(1, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
      setPreviousMonthCost,
      setPreviousMonthCostRequestStatus
    );

    // Previous 2nd Month Cost
    getGCPCostByPeriodData(
      moment().subtract(2, 'month').startOf('month').format(HYPHEN_DATE_FORMAT),
      moment().subtract(2, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
      setPreviousSecondMonthCost,
      setPreviousSecondMonthCostRequestStatus
    );
  };

  const getCostTrendData = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.INVOICE_MONTH,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                costTrendStartMonth,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment(costTrendEndMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data: any[] = res?.data || [];
        const months = getMonthYearShortList(
          costTrendStartMonth,
          costTrendEndMonth,
          YEAR_MONTH_WITHOUT_SEPARATOR
        );
        setCostTrendData(
          months.map((month: string) => ({
            month: moment(month, YEAR_MONTH_WITHOUT_SEPARATOR).format(
              MONTH_YEAR_SHORT
            ),
            cost: data.find(
              (item) =>
                moment(item.month).format(YEAR_MONTH_WITHOUT_SEPARATOR) ===
                month
            )?.cost,
          }))
        );
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendRequestStatus);
      });
  };

  const parseAndSetForecastErrorMessage = (errorMessage: string) => {
    try {
      const isPermissionDenied =
        JSON.parse(errorMessage ?? null)?.error?.status === 'PERMISSION_DENIED';
      setForecastErrorMessage(
        isPermissionDenied ? t('costComparisonCard.permissionDenied') : ''
      );
    } catch {
      setForecastErrorMessage(errorMessage);
    }
  };

  const getNextMonthForecastCost = () => {
    setNextMonthForecastedCostReqStatus(REQUEST_STATUS.PROCESSING);
    const requestBody: ForecastRequestBody = {
      connectorId: selectedDashboard?.connectorId!,
      start: moment()
        .add(1, 'month')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      end: moment().add(1, 'month').endOf('month').format(HYPHEN_DATE_FORMAT),
    };

    getForecastedCost(requestBody)
      .then((res: any) => {
        setForecastErrorMessage('');
        if (res?.status === 200) {
          const forecastCost =
            JSON.parse(res?.data?.responseData)?.predictions ?? [];
          setNextMonthForeCastedCost(sum(forecastCost));
          setNextMonthForecastedCostReqStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setForeCastedCost([]);
          setNextMonthForecastedCostReqStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setNextMonthForecastedCostReqStatus);
        setForeCastedCost([]);
        parseAndSetForecastErrorMessage(e?.response?.data?.message);
      });
  };

  const getForecastCost = () => {
    setForeCastedCostRequestStatus(REQUEST_STATUS.PROCESSING);
    const requestBody: ForecastRequestBody = {
      connectorId: selectedDashboard?.connectorId!,
      start: forecastDateRange[0],
      end: forecastDateRange[1],
    };

    getForecastedCost(requestBody)
      .then((res: any) => {
        setForecastErrorMessage('');
        if (res?.status === 200) {
          const forecastCost =
            JSON.parse(res?.data?.responseData)?.predictions ?? [];
          const monthwiseForecast: MonthlyCostType[] = [];
          const months = getMonthYearShortList(
            moment(forecastDateRange[0]).format(YEAR_MONTH_WITHOUT_SEPARATOR),
            moment(forecastDateRange[1]).format(YEAR_MONTH_WITHOUT_SEPARATOR)
          );

          let index = 0;
          months.forEach((month) => {
            let days = moment(month, MONTH_YEAR_SHORT).daysInMonth();
            if (month === moment().format(MONTH_YEAR_SHORT)) {
              days = moment()
                .endOf('month')
                .diff(moment().subtract(1, 'days'), 'days');
            }

            monthwiseForecast.push({
              month: month,
              cost: sum(forecastCost.slice(index, index + days)),
            });
            index += days;
          });
          setForeCastedCost(monthwiseForecast);
          setForeCastedCostRequestStatus(REQUEST_STATUS.SUCCESS);
        } else {
          setForeCastedCost([]);
          setForeCastedCostRequestStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setNextMonthForecastedCostReqStatus);
        setForeCastedCost([]);
        parseAndSetForecastErrorMessage(e?.response?.data?.message);
      });
  };

  /**
   * @function getCostByServiceForTrends
   * @description Function to fetch the service details for the selected month.
   */
  const getCostByServiceForTrends = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'service',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.SERVICE_DESCRIPTION,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['service'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment(selectedTrendMonth!, MONTH_YEAR_SHORT)
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment(selectedTrendMonth!, MONTH_YEAR_SHORT)
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data = res?.data?.filter((item: any) => item.cost > 0) || [];
        const colors = generateGraphColors(data.length);
        setMonthWiseServiceData({
          ...monthWiseServiceData,
          [selectedTrendMonth ?? '']: data.map((item: any, index: number) => ({
            ...item,
            color: colors[index],
          })),
        });
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendRequestStatus);
      });
  };

  /**
   * @function getServiceDataBySelectedMonth
   * @description Function to return the service data for the selected month from the month to service data map.
   * @returns list of service data
   */
  const getServiceDataBySelectedMonth = () => {
    if (
      !monthWiseServiceData ||
      !selectedTrendMonth ||
      !monthWiseServiceData[selectedTrendMonth]
    ) {
      return [];
    }

    return monthWiseServiceData[selectedTrendMonth];
  };

  /**
   * @function getCostByResourceForTrends
   * @description Function to fetch the resource details for the selected month and resource.
   */
  const getCostByResourceForTrends = () => {
    setCostTrendRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'resourceName',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.BILLING_RESOURCE_NAME,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['resourceName'],
      orderBy: [
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment(selectedTrendMonth!, MONTH_YEAR_SHORT)
                  .startOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment(selectedTrendMonth!, MONTH_YEAR_SHORT)
                  .endOf('month')
                  .format(HYPHEN_DATE_FORMAT),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.SERVICE_DESCRIPTION,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.EQUALS,
              value: selectedTrendService,
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        const data = res?.data?.filter((item: any) => item.cost > 0) || [];
        const colors = generateGraphColors(data.length);
        setMonthServiceResourceData({
          ...monthServiceResourceData,
          [`${selectedTrendMonth}${selectedTrendService}`]: data.map(
            (item: any, index: number) => ({
              ...item,
              color: colors[index],
              resource: item.resourceName,
            })
          ),
        });
        setCostTrendRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostTrendRequestStatus);
      });
  };

  /**
   * @function getResourceDataBySelectedMonthAndService
   * @description Function to return the service data for the selected month and service from the month service and resource data map.
   * @returns list of resource data
   */
  const getResourceDataBySelectedMonthAndService = () => {
    if (
      !monthServiceResourceData ||
      !selectedTrendMonth ||
      !selectedTrendService ||
      !monthServiceResourceData[`${selectedTrendMonth}${selectedTrendService}`]
    ) {
      return [];
    }

    return monthServiceResourceData[
      `${selectedTrendMonth}${selectedTrendService}`
    ];
  };

  /**
   * @function getGCPMonthlyCostByProjectData
   * @description Function to fetch the GCP monthly cost by project data
   */
  const getGCPMonthlyCostByProjectData = () => {
    setMonthlyCostByProjectRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'project',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PROJECT_NAME,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.INVOICE_MONTH,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['project', 'month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                monthlyCostByProjectStartDate,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                monthlyCostByProjectEndDate,
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        let costByProject: MonthlyCostByProjectDatasetType[] = [];

        res?.data?.forEach((item: CostByProjectType) => {
          const existingIndex = costByProject?.findIndex(
            (eachItem) => eachItem.project === item.project
          );

          if (existingIndex >= 0) {
            costByProject[existingIndex].costData.push(item?.cost);
          } else {
            costByProject.push({
              project: item.project || 'Other',
              costData: [item.cost],
            });
          }
        });
        costByProject = sortMonthlyCostByData(costByProject);
        const sortedMonthlyData: CostByProjectType[] = [];
        costByProject.forEach((projectItem) =>
          sortedMonthlyData.push(
            ...getProjectCostForAllMonths(res.data || [], projectItem.project)
          )
        );
        setMonthWiseCostByProject(sortedMonthlyData);
        setMonthlyCostByProjectRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setMonthlyCostByProjectRequestStatus);
      });
  };

  const getGCPCostByProjectData = () => {
    setCostByProjectDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'project',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.PROJECT_NAME,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.INVOICE_MONTH,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['project', 'month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment().startOf('year').format(YEAR_MONTH_WITHOUT_SEPARATOR),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        let costByProject: CostByProjectDatasetType[] = [];
        res?.data?.forEach((item: CostByProjectType) => {
          const existingIndex = costByProject?.findIndex(
            (eachItem) => eachItem.project === (item.project || 'Other')
          );

          if (existingIndex >= 0) {
            costByProject[existingIndex].cost += item.cost;
          } else {
            costByProject.push({
              project: item.project || 'Other',
              cost: item.cost,
            });
          }
        });
        costByProject.sort((a, b) => b.cost - a.cost);
        setCostByProjectData(costByProject);
        setCostByProjectDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostByProjectDataRequestStatus);
      });
  };

  const getGCPCostByServiceData = () => {
    setCostByServiceRequestStatus(REQUEST_STATUS.PROCESSING);

    const isStaticData = isDashboardWithStaticData(selectedDashboard);
    const requestBody = {
      columns: [
        {
          label: 'cost',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'service',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.SERVICE_DESCRIPTION,
            selectedConnection!.focusConversionEnabled
          ),
        },
        {
          label: 'month',
          field: getQueryFieldByDataSource(
            selectedDashboard!.dashBoardType,
            QUERY_FIELDS.INVOICE_MONTH,
            selectedConnection!.focusConversionEnabled
          ),
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['service', 'month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment().startOf('year').format(YEAR_MONTH_WITHOUT_SEPARATOR),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: getQueryFieldByDataSource(
                selectedDashboard!.dashBoardType,
                QUERY_FIELDS.INVOICE_MONTH,
                selectedConnection!.focusConversionEnabled
              ),
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: formatDateFieldByProvider(
                moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
                PROVIDER.GCP,
                isStaticData,
                selectedConnection!.focusConversionEnabled
              ),
            },
          ],
        },
      ],
      keyValueStructFilterGroups: [
        ...getGCPTagFiltersData(
          tagsFilters,
          selectedDashboard?.id,
          selectedDashboardView
        ),
      ],
      dashBoardType: DASHBOARD_TYPES.BILLING,
      cached: true,
    };

    getChartData(requestBody, selectedDashboard?.connectorId)
      .then((res: any) => {
        let costByService: CostByServiceConsolidatedCostType[] = [];

        res?.data?.forEach((item: ColouredCostByServiceType) => {
          const existingIndex = costByService?.findIndex(
            (eachItem) => eachItem.service === item.service
          );

          if (existingIndex >= 0) {
            costByService[existingIndex].costData.push(item?.cost);
          } else {
            costByService.push({
              service: item.service || 'Other',
              costData: [item.cost],
            });
          }
        });
        costByService = sortMonthlyCostByData(costByService);
        const sortedMonthlyData: MonthlyCostByServiceType[] = [];
        costByService.forEach((serviceItem) =>
          sortedMonthlyData.push(
            ...getServiceCostForAllMonths(res.data || [], serviceItem.service)
          )
        );
        setMonthlyCostByServiceData(sortedMonthlyData);

        setCostByServiceRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCostByServiceRequestStatus);
      });
  };

  /**
   * @function sortMonthlyCostByData
   * @description Function to sort the data by consolidated cost
   * @param data List of cost data
   * @return List of sorted data
   */
  const sortMonthlyCostByData = (data: any[]) => {
    return data.sort(
      (data1, data2) =>
        calculateTotalCostSum(data2.costData) -
        calculateTotalCostSum(data1.costData)
    );
  };

  /**
   * @function getProjectCostForAllMonths
   * @description Function to return the project cost of all the months in the selected date range.
   * @param data List of project cost by month from the response data (May not have the data for all the months)
   * @param project Project for which the data is required
   * @return List of project cost for all the months in the selected month range and provided project
   */
  const getProjectCostForAllMonths = (
    data: CostByProjectType[],
    project: string
  ) => {
    const labels: string[] = getMonthYearShortList(
      monthlyCostByProjectStartDate,
      monthlyCostByProjectEndDate,
      MONTH_YEAR_FORMAT
    );

    const monthlyCostByProject: CostByProjectType[] = [];
    labels.forEach((label) => {
      monthlyCostByProject.push({
        project: project,
        month: label,
        cost:
          data.find(
            (item) =>
              item.project === project &&
              moment(item.month).format(MONTH_YEAR_FORMAT) === label
          )?.cost ?? 0,
      });
    });

    return monthlyCostByProject;
  };

  /**
   * @function getServiceCostForAllMonths
   * @description Function to return the service cost of all the months in the selected date range.
   * @param data List of service cost by month from the response data (May not have the data for all the months)
   * @param service Service for which the data is required
   * @return List of service cost for all the months in the selected month range and provided service
   */
  const getServiceCostForAllMonths = (
    data: MonthlyCostByServiceType[],
    service: string
  ) => {
    const labels: string[] = getMonthYearShortList(
      moment().startOf('year').format(YEAR_MONTH_WITHOUT_SEPARATOR),
      moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
      MONTH_YEAR_FORMAT
    );

    const costByServiceData: MonthlyCostByServiceType[] = [];
    labels.forEach((label) => {
      costByServiceData.push({
        service: service,
        month: label,
        cost:
          data.find(
            (item) =>
              item.service === service &&
              moment(item.month).format(MONTH_YEAR_FORMAT) === label
          )?.cost ?? 0,
      });
    });

    return costByServiceData;
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'cost-trend':
        return (
          <CostTrend
            monthlyCost={costTrendData}
            forecastedCost={foreCastedCost}
            costTrendStartMonth={costTrendStartMonth}
            costTrendEndMonth={costTrendEndMonth}
            forecastDateRange={forecastDateRange}
            onChangeCostTrendDateRange={onChangeCostTrendDateRange}
            costTrendRequestStatus={[
              costTrendRequestStatus,
              foreCastedCostRequestStatus,
            ]}
            isCostTrendTableView={isCostTrendTableView}
            pdfView={pdfView}
            setIsCostTrendTableView={setIsCostTrendTableView}
            hasForecastedCost={
              !isDashboardWithStaticData(selectedDashboard) &&
              foreCastedCostRequestStatus === REQUEST_STATUS.SUCCESS &&
              moment(costTrendEndMonth).isSameOrAfter(moment(), 'month')
            }
            selectedTrendMonth={selectedTrendMonth}
            setSelectedTrendMonth={setSelectedTrendMonth}
            selectedTrendService={selectedTrendService}
            setSelectedTrendService={setSelectedTrendService}
            serviceData={getServiceDataBySelectedMonth()}
            resourceData={getResourceDataBySelectedMonthAndService()}
            sliderValue={costTrendSliderValue}
            setSliderValue={setCostTrendSliderValue}
          />
        );

      case 'cloud-spend':
        return (
          <ColumnChartWithTable
            graphTitle={t('graphHeadings.cloudSpend')}
            graphName="cloud-spend"
            columns={GcpCloudSpendColumns}
            data={selectedCloudSpendData}
            chartProperties={{
              xTitle: t('projects'),
              yTitle: t('costInCurrency', { currencySymbol }),
            }}
            filters={[
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: cloudSpendStartDate,
                  filterEndDate: cloudSpendEndDate,
                  onChangeDateRange: getDateRangeHandler(
                    setCloudSpendStartDate,
                    setCloudSpendEndDate
                  ),
                },
              },
              {
                filterType: ChartFilterType.DROPDOWN,
                filterProperties: {
                  allData: cloudSpendData,
                  selectedData: selectedCloudSpendData,
                  setSelectedData: setSelectedCloudSpendData,
                  valueSuffix: t('projects'),
                  fieldName: DROPDOWN_VALUE_FIELDS.NAME,
                },
              },
            ]}
            requestStatus={cloudSpendRequestStatus}
            isTableView={isCloudSpendTableView}
            setIsTableView={setIsCloudSpendTableView}
            pdfView={pdfView}
          />
        );

      case 'tagged-untagged-cost':
        return (
          <ColumnChartWithTable
            graphTitle={t('graphHeadings.taggedUntaggedSpend')}
            graphName="tagged-untagged-cost"
            columns={TaggedUntaggedSpendColumns}
            data={taggedUntaggedSpendData}
            tableData={taggedUntaggedTableData}
            chartProperties={{
              xTitle: t('months'),
              yTitle: t('costInCurrency', { currencySymbol }),
              isStack: true,
            }}
            filters={[
              {
                filterType: ChartFilterType.DATE_RANGE,
                filterProperties: {
                  filterStartDate: taggedUntaggedSpendStartMonth,
                  filterEndDate: taggedUntaggedSpendEndMonth,
                  onChangeDateRange: getMonthRangeHandler(
                    setTaggedUntaggedSpendStartMonth,
                    setTaggedUntaggedSpendEndMonth
                  ),
                  format: MONTH_YEAR_FORMAT,
                  picker: 'month',
                },
              },
            ]}
            requestStatus={taggedUntaggedSpendRequestStatus}
            isTableView={isTaggedUntaggedSpendTableView}
            setIsTableView={setIsTaggedUntaggedSpendTableView}
            pdfView={pdfView}
          />
        );

      case 'quarter-cost-summary':
        return (
          <QuarterCostSummary
            currentQuarterCost={currentQuarterCost}
            previousQuarterCost={previousQuarterCost}
            requestStatus={[
              currentQuarterCostRequestStatus,
              previousQuarterCostRequestStatus,
            ]}
            pdfView={pdfView}
          />
        );

      case 'monthly-cost-by-project':
        return (
          <MonthlyCostByProject
            requestStatus={monthlyCostByProjectRequestStatus}
            selectedMonthlyCostByProjectData={selectedMonthlyCostByProjectData}
            monthWiseCostByProject={monthWiseCostByProject}
            setSelectedMonthlyCostByProjectData={
              setSelectedMonthlyCostByProjectData
            }
            isMonthlyCostByProjectTableView={isMonthlyCostByProjectTableView}
            setIsMonthlyCostByProjectTableView={
              setIsMonthlyCostByProjectTableView
            }
            pdfView={pdfView}
            monthlyCostByProjectStartDate={monthlyCostByProjectStartDate}
            monthlyCostByProjectEndDate={monthlyCostByProjectEndDate}
            onChangeCostByProjectDateRange={onChangeCostByProjectDateRange}
          />
        );

      case 'ytd-cost-by-gcp-service':
        return (
          <CostByGCPService
            requestStatus={costByServiceRequestStatus}
            selectedMonthlyCostByServiceData={selectedMonthlyCostByServiceData}
            monthlyCostByServiceData={monthlyCostByServiceData}
            setSelectedMonthlyCostByServiceData={
              setSelectedMonthlyCostByServiceData
            }
            isCostByServiceTableView={isCostByServiceTableView}
            setIsCostByServiceTableView={setIsCostByServiceTableView}
            pdfView={pdfView}
          />
        );

      case 'cost-by-project-mixed':
        return (
          <CostByProjectMixed
            requestStatus={costByProjectDataRequestStatus}
            costByProjectData={costByProjectData}
            isCostByProjectTableView={isCostByProjectTableView}
            selectedCostByProjectData={selectedCostByProjectData}
            setSelectedCostByProjectData={setSelectedCostByProjectData}
            setIsCostByProjectTableView={setIsCostByProjectTableView}
            pdfView={pdfView}
          />
        );

      case 'service-spend-profile':
        return (
          <SpendProfileChartWrapper
            data={applicationProfileSpendData}
            setData={setApplicationProfileSpendData}
            requestStatus={applicationProfileSpendReqStatus}
            setRequestStatus={setApplicationProfileSpendReqStatus}
            pdfView={pdfView}
            spendProfileStartMonth={spendProfileStartMonth}
            setSpendProfileStartMonth={setSpendProfileStartMonth}
            spendProfileEndMonth={spendProfileEndMonth}
            setSpendProfileEndMonth={setSpendProfileEndMonth}
          />
        );
    }
  };

  const getCostComparisonCards = () =>
    costComparisonList.map((item, index) => {
      return (
        <ComparisonCard
          key={item.heading}
          index={index}
          value={item.value}
          heading={item.heading}
          valuePrefix={currencySymbol}
          comparisonValue={item.comparisonValue}
          comparisonFrom={item.comparisonFrom}
          requestStatus={item.requestStatus}
          errorMessage={item.errorMessage}
        />
      );
    });

  const monthlyCostByProjectExportData = (addNumberComma = false) => {
    const groupedByProject: any[] = [];
    selectedMonthlyCostByProjectData.forEach((item) => {
      const existingProject = groupedByProject.find(
        (obj) => obj.project === item.project
      );
      if (existingProject) {
        existingProject[`${item.month}-cost`] = addNumberComma
          ? `${currencySymbol}${numberCommaSeparator(item.cost)}`
          : `${currencySymbol}${item.cost}`;
      } else {
        groupedByProject.push({
          project: item.project,
          [`${item.month}-cost`]: addNumberComma
            ? `${currencySymbol}${numberCommaSeparator(item.cost)}`
            : `${currencySymbol}${item.cost}`,
        });
      }
    });
    return groupedByProject;
  };

  const costByServiceExportData = (addNumberComma = false) => {
    const groupedByService: any[] = [];
    selectedMonthlyCostByServiceData.forEach((item) => {
      const existingService = groupedByService.find(
        (obj) => obj.service === item.service
      );
      if (existingService) {
        existingService[`${item.month}-cost`] = addNumberComma
          ? `${currencySymbol}${numberCommaSeparator(item.cost)}`
          : `${currencySymbol}${item.cost}`;
      } else {
        groupedByService.push({
          service: item.service,
          [`${item.month}-cost`]: addNumberComma
            ? `${currencySymbol}${numberCommaSeparator(item.cost)}`
            : `${currencySymbol}${item.cost}`,
        });
      }
    });
    return groupedByService;
  };

  /**
   * @function getCostTrendLegendsForPdfExport
   * @description Function to fetch the legends list based on the slider position
   * @returns list of legends with color
   */
  const getCostTrendLegendsForPdfExport = () => {
    if (!selectedTrendMonth) {
      return undefined;
    }

    const startIndex = costTrendSliderValue
      ? costTrendSliderValue.x * (CostTrendTableData.length - 1)
      : 0;

    const endIndex = costTrendSliderValue
      ? costTrendSliderValue.y * (CostTrendTableData.length - 1) + 1
      : GRAPH_OPTIONS.sliderCountLimit + 1;

    return CostTrendTableData.slice(startIndex, endIndex).map((data: any) => ({
      name: data.month ?? data.service ?? data.resource ?? '',
      color: data.color ?? '',
    }));
  };

  const CostTrendTableData = getCostTrendsData(
    selectedTrendMonth,
    selectedTrendService,
    [costTrendStartMonth, forecastDateRange[1]],
    {
      trends: costTrendData,
      forecasted: foreCastedCost,
      service: getServiceDataBySelectedMonth(),
      resource: getResourceDataBySelectedMonthAndService(),
    }
  );

  // Memoized data for the graph rows to improve performance
  const GraphRows: GraphRowContentType = useMemo(
    () => [
      [
        {
          element: getGraphComponent('cost-trend', true),
          contentType: selectedTrendMonth
            ? CHART_TYPES.BAR_CHART
            : CHART_TYPES.BAR_LINE_CHART,
          graphName: 'cost-trend',
          graphHeading: getCostTrendsHeading(
            selectedTrendMonth,
            selectedTrendService
          ),
          criteria: [ChartCriteria.TIME_PERIOD, ChartCriteria.SERVICE],
          chartView: {
            customLegends: getCostTrendChartLegends(
              selectedTrendMonth,
              selectedTrendService
            ),
            xAxisLabel: getCostTrendXAxisLabel(
              selectedTrendMonth,
              selectedTrendService
            ),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getCostTrendsColumns(
              selectedTrendMonth,
              selectedTrendService
            ),
            isTableView: isCostTrendTableView,
            setTableView: setIsCostTrendTableView,
          },
          pdfTableExport: {
            pdfTableData: CostTrendTableData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
              forecastedCost:
                'forecastedCost' in spend
                  ? currencySymbol + numberCommaSeparator(spend.forecastedCost)
                  : undefined,
            })),
            legends: getCostTrendLegendsForPdfExport(),
          },
          excelExport: {
            excelData: CostTrendTableData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              endDate: costTrendEndMonth,
              startDate: costTrendStartMonth,
              month: selectedTrendMonth,
              service: selectedTrendService,
            },
          },
          pptExport: {
            chartHeading: !selectedTrendMonth
              ? `${t('graphHeadings.costTrend')} (${getDateFilterAsString(
                  costTrendStartMonth,
                  costTrendEndMonth
                )})`
              : getCostTrendsHeading(selectedTrendMonth, selectedTrendService),
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: CostTrendTableData.map((spend) => {
                  if ('resource' in spend) {
                    return spend.resource;
                  } else if ('service' in spend) {
                    return spend.service;
                  } else {
                    return spend.month;
                  }
                }),
                values: CostTrendTableData.map(
                  (spend: any) => spend.forecastedCost ?? spend.cost ?? 0
                ),
              },
            ],
          },
        },
      ],
      [
        {
          element: getGraphComponent('cloud-spend', true),
          contentType: CHART_TYPES.BAR_CHART,
          graphName: 'cloud-spend',
          graphHeading: t('graphHeadings.cloudSpend'),
          display: !isDashboardWithStaticData(selectedDashboard),
          criteria: [ChartCriteria.PROJECT],
          chartView: {
            xAxisLabel: t('projects'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: GcpCloudSpendColumns,
            isTableView: isCloudSpendTableView,
            setTableView: setIsCloudSpendTableView,
          },
          pdfTableExport: {
            pdfTableData: selectedCloudSpendData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
          },
          excelExport: {
            excelData: selectedCloudSpendData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              startDate: cloudSpendStartDate,
              endDate: cloudSpendEndDate,
            },
          },
          pptExport: {
            chartHeading: `${t(
              'graphHeadings.cloudSpend'
            )} (${getDateFilterAsString(
              cloudSpendStartDate,
              cloudSpendEndDate
            )})`,
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: selectedCloudSpendData.map((item) => item.name),
                values: selectedCloudSpendData.map((item) => item.cost),
              },
            ],
          },
        },
      ],
      [
        {
          element: getGraphComponent('service-spend-profile', true),
          contentType: CHART_TYPES.PIE_CHART,
          graphName: 'service-spend-profile',
          graphHeading: t('graphHeadings.serviceSpendProfile'),
          criteria: [ChartCriteria.SPEND_PROFILE],
          pptExport: {
            pptCustomContent: getSpendProfilePptContent(
              applicationProfileSpendData
            ),
          },
        },
      ],
      [
        {
          element: getGraphComponent('tagged-untagged-cost', true),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: 'tagged-untagged-cost',
          graphHeading: t('graphHeadings.taggedUntaggedSpend'),
          display: !isDashboardWithStaticData(selectedDashboard),
          criteria: [ChartCriteria.SPEND_PROFILE],
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: TaggedUntaggedSpendColumns,
            isTableView: isTaggedUntaggedSpendTableView,
            setTableView: setIsTaggedUntaggedSpendTableView,
          },
          pdfTableExport: {
            pdfTableData: taggedUntaggedTableData.map((spend) => ({
              ...spend,
              Tagged: currencySymbol + numberCommaSeparator(spend.Tagged),
              Untagged: currencySymbol + numberCommaSeparator(spend.Untagged),
            })),
          },
          excelExport: {
            excelData: taggedUntaggedTableData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
              startDate: taggedUntaggedSpendStartMonth,
              endDate: taggedUntaggedSpendEndMonth,
            },
          },
          pptExport: {
            chartHeading: `${t(
              'graphHeadings.taggedUntaggedSpend'
            )} (${getDateFilterAsString(
              taggedUntaggedSpendStartMonth,
              taggedUntaggedSpendEndMonth
            )})`,
            pptData: transformStackedDataByGroupForPptExport(
              taggedUntaggedSpendData,
              'type',
              'cost',
              'name'
            ),
          },
        },
      ],
      [
        {
          colSpanWidth: 10,
          element: getGraphComponent('quarter-cost-summary', true),
          contentType: CHART_TYPES.HORIZONTAL_BAR_CHART,
          graphName: 'quarter-cost-summary',
          criteria: [ChartCriteria.TIME_PERIOD],
          chartView: {
            customLegends: QUARTER_COST_SUMMARY,
            xAxisLabel: t('costInCurrency', { currencySymbol }),
            yAxisLabel: t('quarters'),
          },
          graphHeading: t('graphHeadings.costByQuarter'),
          pptExport: {
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: QUARTER_COST_SUMMARY,
                values: [currentQuarterCost, previousQuarterCost],
              },
            ],
          },
        },
        {
          element: getGraphComponent('monthly-cost-by-project', true),
          contentType: CHART_TYPES.STACK_CHART,
          graphName: 'monthly-cost-by-project',
          graphHeading: t('graphHeadings.monthlyCostByProject'),
          criteria: [ChartCriteria.PROJECT],
          display: !isDashboardWithStaticData(selectedDashboard),
          colSpanWidth: 14,
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getMonthlyCostByProjectColumns(
              monthlyCostByProjectStartDate,
              monthlyCostByProjectEndDate
            ),
            isTableView: isMonthlyCostByProjectTableView,
            setTableView: setIsMonthlyCostByProjectTableView,
          },
          pdfTableExport: {
            pdfTableData: monthlyCostByProjectExportData(true),
          },
          excelExport: {
            excelData: monthlyCostByProjectExportData(),
            excelFilters: {
              connectionName: selectedDashboard!.name,
              endDate: monthlyCostByProjectStartDate,
              startDate: monthlyCostByProjectEndDate,
            },
          },
          pptExport: {
            chartHeading: `${t(
              'graphHeadings.monthlyCostByProject'
            )} (${getDateFilterAsString(
              monthlyCostByProjectStartDate,
              monthlyCostByProjectEndDate
            )})`,
            pptData: transformStackedDataByGroupForPptExport(
              selectedMonthlyCostByProjectData,
              'project',
              'cost',
              'month'
            ),
          },
        },
      ],
      [
        {
          colSpanWidth: 12,
          contentType: CHART_TYPES.STACK_CHART,
          graphName: 'ytd-cost-by-gcp-service',
          graphHeading: t('graphHeadings.ytdCostByGcpService'),
          element: getGraphComponent('ytd-cost-by-gcp-service', true),
          criteria: [ChartCriteria.SERVICE],
          chartView: {
            xAxisLabel: t('months'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: getCostByServiceColumns(selectedMonthlyCostByServiceData),
            isTableView: isCostByServiceTableView,
            setTableView: setIsCostByServiceTableView,
          },
          pdfTableExport: {
            pdfTableData: costByServiceExportData(true).map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
          },
          excelExport: {
            excelData: costByServiceExportData(),
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: transformStackedDataByGroupForPptExport(
              selectedMonthlyCostByServiceData,
              'service',
              'cost',
              'month'
            ),
          },
        },
        {
          colSpanWidth: 12,
          contentType: CHART_TYPES.BAR_LINE_CHART,
          graphName: 'cost-by-project-mixed',
          graphHeading: t('graphHeadings.ytdCostByProject'),
          element: getGraphComponent('cost-by-project-mixed', true),
          display: !isDashboardWithStaticData(selectedDashboard),
          criteria: [ChartCriteria.PROJECT],
          chartView: {
            customLegends: [
              t('costTrend.costWithoutSuffix'),
              t('costTrend.trend'),
            ],
            xAxisLabel: t('projects'),
            yAxisLabel: t('costInCurrency', { currencySymbol }),
          },
          tableView: {
            columns: CostByProjectMixedColumns,
            isTableView: isCostByProjectTableView,
            setTableView: setIsCostByProjectTableView,
          },
          pdfTableExport: {
            pdfTableData: selectedCostByProjectData.map((spend) => ({
              ...spend,
              cost: currencySymbol + numberCommaSeparator(spend.cost),
            })),
          },
          excelExport: {
            excelData: selectedCostByProjectData,
            excelFilters: {
              connectionName: selectedDashboard!.name,
            },
          },
          pptExport: {
            pptData: [
              {
                name: t('costInCurrency', { currencySymbol }),
                labels: selectedCostByProjectData.map((item) => item.project),
                values: selectedCostByProjectData.map((item) => item.cost),
              },
            ],
          },
        },
      ],
    ],
    [
      applicationProfileSpendData,
      cloudSpendEndDate,
      cloudSpendStartDate,
      costTrendEndMonth,
      costTrendStartMonth,
      currentQuarterCost,
      isCloudSpendTableView,
      isCostByServiceTableView,
      isCostTrendTableView,
      isTaggedUntaggedSpendTableView,
      monthlyCostByProjectEndDate,
      monthlyCostByProjectStartDate,
      previousQuarterCost,
      selectedCloudSpendData,
      selectedCostByProjectData,
      selectedMonthlyCostByProjectData,
      selectedMonthlyCostByServiceData,
      selectedTrendMonth,
      selectedTrendService,
      taggedUntaggedSpendData,
      taggedUntaggedSpendEndMonth,
      taggedUntaggedSpendStartMonth,
      taggedUntaggedTableData,
    ]
  );

  return (
    <>
      <ControlComponent
        filters={[
          {
            title: t('listByDimensions'),
            filter: (
              <DropdownCheckbox
                itemOptions={CHART_CRITERIA_LIST}
                selectedItems={chartCriteriaFilters}
                setSelectedItems={setChartCriteriaFilters}
                designVersion2
                size={INPUT_SIZE.SMALL}
              />
            ),
            minimizedText:
              chartCriteriaFilters.length === CHART_CRITERIA_LIST.length
                ? t('all')
                : CHART_CRITERIA_LIST.filter((each) =>
                    chartCriteriaFilters.includes(each.value)
                  )
                    .map((each) => each.title)
                    .join(', '),
            onClear: () => setChartCriteriaFilters([]),
          },
        ]}
      />
      <div className="gcp-cost-summary inner-dashboard-content flex flex-column flex-gap-24">
        <TagFilters />
        <DashboardGraphContainer
          getGraphComponent={getGraphComponent}
          chartCriteriaFilters={chartCriteriaFilters}
          rows={GraphRows}
          pdfMetaData={getPdfMetaData(
            t('dashNav.costSummary'),
            selectedDashboard!,
            selectedConnection!
          )}
          costCardComponents={getCostComparisonCards()}
        />
      </div>
    </>
  );
};

export default GCPCostSummaryDashboard;
