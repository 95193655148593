import './index.scss';

type DependentsListProps = {
  dependents: string[];
};

const DependentsList = ({ dependents }: DependentsListProps) => {
  return (
    <ol className="dependents-list">
      {dependents.map((dependent) => (
        <li className="font-label" key={dependent}>
          {dependent}
        </li>
      ))}
    </ol>
  );
};

export default DependentsList;
