import { useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Empty as BaseEmpty } from 'antd';

import { userAuthorization } from 'redux/authorizationSlice';
import {
  costOptimizationInsights,
  setSelectedRecommendations,
} from 'redux/costOptimizationInsightsSlice';
import InfiniteScrollTable from 'components/InfiniteScrollTable';
import { ServiceNowStatus } from 'constants/workflowIntegrations';
import { TableOnChangeType } from 'components/InfiniteScrollTable/types';
import Empty from 'components/Empty';
import Loader from 'components/Loader';
import { RecommendationSource } from 'pages/CostOptimizationInsightsPage/constants';

import { RecommendationList } from '../../types';
import { getColumnsWithCellActions } from './utils';
import RecommendationDetailsModal from '../RecommendationDetailsModal';

import './index.scss';

type RecommendationsTableProps = {
  isFetchingMoreData: boolean;
  isLoading: boolean;
  recommendationData: RecommendationList[];
  columns: ColumnsType<RecommendationList>;
  onChangeTableFilters: TableOnChangeType;
  handleChangePagination: Function;
  hasErrorFetchingData: boolean;
  noDataMessage: string;
};

const RecommendationsTable = ({
  isFetchingMoreData,
  isLoading,
  recommendationData,
  columns,
  onChangeTableFilters,
  handleChangePagination,
  hasErrorFetchingData,
  noDataMessage,
}: RecommendationsTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permissions } = useSelector(userAuthorization);
  const { selectedRecommendations } = useSelector(costOptimizationInsights);

  const [showRecommendationDetailsModal, setShowRecommendationDetailsModal] =
    useState(false);
  const [selectedRecommendationData, setSelectedRecommendationData] =
    useState<RecommendationList>();

  /**
   * @function onSelectTableRow
   * @description Callback function for row selection
   * @param record selected row data.
   * @param checked boolean value to indicate row selection or deselection
   */
  const onSelectTableRow = (record: RecommendationList, checked: boolean) => {
    const castedSelectedRecommendations =
      selectedRecommendations as RecommendationList[];
    if (checked) {
      dispatch(
        setSelectedRecommendations([...castedSelectedRecommendations, record])
      );
      return;
    }

    dispatch(
      setSelectedRecommendations([
        ...castedSelectedRecommendations.filter(
          (item) => item.recommendationId !== record.recommendationId
        ),
      ])
    );
  };

  const getInfiniteTableLoaderRow = () => {
    if (recommendationData.length > 0 && isFetchingMoreData) {
      return (
        <Table.Summary.Row className="loader-row">
          <Table.Summary.Cell index={0} colSpan={7}>
            <Loader
              iconWidth={16}
              iconHeight={16}
              additionalText={
                <span className="loading-text">{t('loading')}...</span>
              }
            />
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    }
  };

  /**
   * @function onCellClick
   * @description Callback function for cell click event
   * @param record CLicked row data
   * @returns Object containing the events
   */
  const onCellClick = (record: RecommendationList) => {
    return {
      onClick: () => {
        setSelectedRecommendationData(record);
        setShowRecommendationDetailsModal(true);
      },
    };
  };

  /**
   * @function onCloseRecommendationDetailsModal
   * @description Callback function for closing the recommendation details modal
   */
  const onCloseRecommendationDetailsModal = () => {
    setShowRecommendationDetailsModal(false);
    setSelectedRecommendationData(undefined);
  };

  return (
    <div className="recommendations-table-container">
      <InfiniteScrollTable
        rootClassName="consolidated-recommendation-table"
        rowClassName="cursor-pointer"
        rowSelection={{
          type: 'checkbox',
          hideSelectAll: true,
          getCheckboxProps: (record: RecommendationList) => {
            return {
              disabled:
                !permissions.costControlWrite ||
                record.serviceNowStatus !== ServiceNowStatus.TICKET_TBC ||
                record.recommendationSource === RecommendationSource.GRANULATE,
            };
          },
          onSelect: onSelectTableRow,
          selectedRowKeys: (
            selectedRecommendations as RecommendationList[]
          ).map((value) => value?.recommendationId),
        }}
        tableLoading={isLoading}
        pagination={false}
        dataSource={recommendationData.map((value) => {
          return {
            ...value,
            key: value.recommendationId,
          };
        })}
        columns={getColumnsWithCellActions(
          columns,
          ['quickAction'],
          onCellClick
        )}
        sortDirections={['ascend', 'descend', 'ascend']}
        onChange={onChangeTableFilters}
        handleChangePageNumber={handleChangePagination}
        summary={getInfiniteTableLoaderRow}
        locale={{
          emptyText: !isLoading && (
            <Empty
              image={BaseEmpty.PRESENTED_IMAGE_SIMPLE}
              description={
                hasErrorFetchingData ? t('graphErrorMessage') : noDataMessage
              }
            />
          ),
        }}
        designVersion2
        fillContainer
        scroll={{
          y: '100%',
          scrollToFirstRowOnChange: false,
        }}
        data-testid="recommendation-table"
      />
      {selectedRecommendationData && (
        <RecommendationDetailsModal
          show={showRecommendationDetailsModal}
          onClose={onCloseRecommendationDetailsModal}
          recommendation={selectedRecommendationData!}
        />
      )}
    </div>
  );
};

export default RecommendationsTable;
