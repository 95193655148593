import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectReport } from 'redux/reportSlice';
import ErrorComponent from 'components/ErrorComponent';
import SuccessComponent from 'components/SuccessComponent';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { SuccessIcon } from 'assets/icons';
import { CreateReportsPageComponent } from 'pages/CreateReportPage/constants';

import ReportDashboardCanvas from '../ReportDashboardCanvas';

type ReportComponentProps = {
  reportsPageComponent: string;
  setReportsPageComponent: (val: CreateReportsPageComponent) => void;
};

const ReportComponent = ({
  reportsPageComponent,
  setReportsPageComponent,
}: ReportComponentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { existingReport } = useSelector(selectReport);

  switch (reportsPageComponent) {
    case CreateReportsPageComponent.CANVAS:
      return <ReportDashboardCanvas />;

    case CreateReportsPageComponent.SUCCESS_COMPONENT:
      return (
        <SuccessComponent
          mainTitle={
            existingReport
              ? t('reports.reportUpdatedSuccess')
              : t('reports.reportCreatedSuccess')
          }
          buttonTitle={t('reports.gotoReports')}
          onHandleButtonClick={() => navigate(NAVIGATION_MENU_PATH.REPORTS)}
          icon={<SuccessIcon />}
        />
      );

    case CreateReportsPageComponent.ERROR_COMPONENT:
      return (
        <ErrorComponent
          mainTitle={
            existingReport
              ? t('reports.reportUpdateError')
              : t('reports.reportCreatedError')
          }
          buttonTitle={t('reports.tryAgain')}
          onHandleButtonClick={() =>
            setReportsPageComponent(CreateReportsPageComponent.CANVAS)
          }
        />
      );

    default:
      return <ReportDashboardCanvas />;
  }
};

export default ReportComponent;
