import { useTranslation } from 'react-i18next';

import { ReleaseNoteType } from 'pages/ReleaseNotesPage/types';

type ReleaseNoteDescriptionProps = {
  release: ReleaseNoteType;
};

const ReleaseNoteDescription = ({ release }: ReleaseNoteDescriptionProps) => {
  const { t } = useTranslation();

  const featureRows = (features: string[]) => (
    <div className="feature-list flex flex-column flex-gap-12">
      {features.map((item) => (
        <div key={item} className="feature-description font-caption">
          {item}
        </div>
      ))}
    </div>
  );

  return (
    <div className="release-description flex flex-column flex-gap-16">
      {release.newFeatures.length > 0 && (
        <div>
          <div className="status new font-small-bold">
            {t('releaseNotes.newFeatures')}
          </div>
          {featureRows(release.newFeatures)}
        </div>
      )}
      {release.improvements.length > 0 && (
        <div>
          <div className="status improvements font-small-bold">
            {t('releaseNotes.improvements')}
          </div>
          {featureRows(release.improvements)}
        </div>
      )}
      {release.bugFixes.length > 0 && (
        <div>
          <div className="status bug-fixes font-small-bold">
            {t('releaseNotes.bugFixes')}
          </div>
          {featureRows(release.bugFixes)}
        </div>
      )}
    </div>
  );
};

export default ReleaseNoteDescription;
