import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getGprofilerProfileData
 * @description Function to fetch the gProfiler profile data
 * @param params Request parameters
 * @return axios response from GET request
 */
export const getGprofilerProfileData = async (params: any) => {
  return ServiceCalls.get(APIS.GPROFILER_DASHBOARD_PROFILE_DATA, null, params);
};

/**
 * @function getGprofilerCpuTrend
 * @description Function to fetch the gProfiler cpu trend
 * @param params Request parameters
 * @return axios response from GET request
 */
export const getGprofilerCpuTrend = async (params: any) => {
  return ServiceCalls.get(APIS.GPROFILER_DASHBOARD_CPU_TREND, null, params);
};

/**
 * @function getGprofilerMetadata
 * @description Function to fetch the gProfiler meta data
 * @param params Request parameters
 * @return axios response from GET request
 */
export const getGprofilerMetadata = async (params: any) => {
  return ServiceCalls.get(APIS.GPROFILER_DASHBOARD_METADATA, null, params);
};

/**
 * @function getGprofilerMetrics
 * @description Function to fetch the gProfiler metrics
 * @param params Request parameters
 * @return axios response from GET request
 */
export const getGprofilerMetrics = async (params: any) => {
  return ServiceCalls.get(APIS.GPROFILER_DASHBOARD_METRICS, null, params);
};

/**
 * @function getGprofilerInsights
 * @description Function to fetch the gProfiler insights
 * @param params Request parameters
 * @return axios response from GET request
 */
export const getGprofilerInsights = async (params: any) => {
  return ServiceCalls.get(APIS.GPROFILER_DASHBOARD_INSIGHTS, null, params);
};

/**
 * @function getGprofilerDateTimeWithData
 * @description Function to fetch the gProfiler date time with data
 * @param params Request parameters
 * @return axios response from GET request
 */
export const getGprofilerDateTimeWithData = async (params: any) => {
  return ServiceCalls.get(
    APIS.GPROFILER_DASHBOARD_DATE_TIME_WITH_DATA,
    null,
    params
  );
};

/**
 * @function getGprofilerOptimizationData
 * @description Function to fetch the optimization data for a service
 * @param serviceName optimization to be serached for
 * @return axios response from GET request
 */
export const getGprofilerOptimizationData = async (serviceName: any) => {
  const api = `${APIS.GPROFILER_OPTIMIZATION_SERVICE}?serviceName=${serviceName}`;
  return ServiceCalls.get(api);
};

/**
 * @function createIncidentTicket
 * @description Function to create the workflow ticket integration
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const createIncidentTicket = async (body: any) => {
  return ServiceCalls.post(APIS.GPROFILER_CREATE_TICKET, body);
};

/**
 * @function getTicketIncidentInfo
 * @description Function to get information about the ticket
 * @param sysId sysId for the ticket
 * @return axios response from GET request
 */
export const getTicketIncidentInfo = async (sysId: string) => {
  return ServiceCalls.get(APIS.GPROFILER_GET_SNOW_TICKET + sysId);
};
