import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import { AMOUNT_MINIMUM } from 'constants/validation';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import { getValidationStyle } from 'utils/validations';

type AmountFormProps = {
  budgetTypeOptions?: { value: string; label: string }[];
};

const AmountForm = ({ budgetTypeOptions }: AmountFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { budgetData } = useSelector(budgetsAndAlerts);

  const [budgetAmountReqValidation, setBudgetAmountReqValidation] =
    useState('');
  const [budgetTypeReqValidation, setBudgetTypeReqValidation] = useState('');

  return (
    <div className="flex flex-column flex-gap-16">
      {budgetTypeOptions && (
        <div className="form-item flex flex-column">
          <FormLabel
            title={t('addBudgetAlert.createBudgetLabels.amount.budgetType')}
            required={true}
          />
          <SelectDropdown
            value={budgetData.budgetType}
            options={budgetTypeOptions}
            onSelect={(value: any) => {
              dispatch(
                setBudgetData({
                  ...budgetData,
                  budgetType: value,
                })
              );
              setBudgetTypeReqValidation('');
            }}
            onBlur={() =>
              budgetData.budgetType
                ? setBudgetTypeReqValidation('')
                : setBudgetTypeReqValidation(
                    t(
                      'addBudgetAlert.createBudgetLabels.amount.budgetTypeRequired'
                    )
                  )
            }
            designVersion2
          />
          <span
            style={{
              display: `${getValidationStyle(budgetTypeReqValidation)}`,
            }}
            className="font-validation-error"
          >
            {budgetTypeReqValidation}
          </span>
        </div>
      )}
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('addBudgetAlert.createBudgetLabels.amount.amount', {
            currencySymbol: currencySymbol,
          })}
          required={true}
        />
        <Input
          type="number"
          placeholder={t(
            'addBudgetAlert.createBudgetLabels.amount.amountPlaceholder'
          )}
          value={budgetData.amount}
          onChange={(value: number) => {
            dispatch(
              setBudgetData({
                ...budgetData,
                amount: value,
              })
            );
            if (!value || value <= 0) {
              setBudgetAmountReqValidation(
                t('addBudgetAlert.createBudgetLabels.amount.amountRequired')
              );
            } else {
              setBudgetAmountReqValidation('');
            }
          }}
          onBlur={() =>
            !budgetData.amount || budgetData.amount <= 0
              ? setBudgetAmountReqValidation(
                  t('addBudgetAlert.createBudgetLabels.amount.amountRequired')
                )
              : setBudgetAmountReqValidation('')
          }
          min={AMOUNT_MINIMUM}
        />
        <span
          style={{
            display: `${getValidationStyle(budgetAmountReqValidation)}`,
          }}
          className="font-validation-error"
        >
          {budgetAmountReqValidation}
        </span>
      </div>
    </div>
  );
};

export default AmountForm;
