import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { successAndErrorPage } from 'redux/successAndErrorPageSlice';
import Button from 'components/Button';
import { ErrorIcon } from 'assets/icons';
import { BUTTON_TYPE } from 'constants/appearance';

import './index.scss';

type ErrorComponentProps = {
  mainTitle: string;
  subTitle?: string;
  buttonTitle: string;
  linkTitle?: string;
  onHandleButtonClick: () => void;
  onHandleLinkClick?: () => void;
  additionalClassName?: string;
};
const ErrorComponent = ({
  mainTitle,
  subTitle,
  buttonTitle,
  linkTitle,
  onHandleButtonClick,
  onHandleLinkClick,
  additionalClassName,
}: ErrorComponentProps) => {
  const { t } = useTranslation();
  const { errorMessage } = useSelector(successAndErrorPage);

  return (
    <div
      className={`error-component flex flex-column flex-center flex-fit full-height ${additionalClassName}`}
      data-testid="error-component"
    >
      <ErrorIcon />
      <div className="font-heading-4-bold">{mainTitle}</div>
      <div className="sub-title font-subHeader">{subTitle}</div>
      {errorMessage && (
        <span className="font-validation-error error-message">
          {errorMessage}
        </span>
      )}
      <Button
        className="dashboard-button"
        title={buttonTitle}
        onClick={onHandleButtonClick}
      />
      {linkTitle && onHandleLinkClick && (
        <div className="footer-info font-caption flex flex-align-items-center flex-gap-4">
          <span>{t('connectionCSPErrorPage.orTag')}</span>
          <Button
            title={linkTitle}
            type={BUTTON_TYPE.LINK}
            onClick={onHandleLinkClick}
          />
        </div>
      )}
    </div>
  );
};

export default ErrorComponent;
