import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import { getValidationStyle, validateEmptyField } from 'utils/validations';
import {
  setTicketIntegrationData,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';

type CredentialDetailsProps = {
  saveOrTestIntegration: (isTest: boolean) => void;
  setIsValidCredentials: (val: boolean) => void;
  credsValidationMessage: string;
  setCredsValidationMessage: (val: string) => void;
};

const CredentialDetails = ({
  saveOrTestIntegration,
  setIsValidCredentials,
  credsValidationMessage,
  setCredsValidationMessage,
}: CredentialDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ticketIntegrationData } = useSelector(ticketIntegration);

  const [urlValidationMessage, setUrlValidationMessage] = useState('');
  const [usernameHostValidationMessage, setUsernameHostValidationMessage] =
    useState('');
  const [passFieldValidationMessage, setPassFieldValidationMessage] =
    useState('');
  const [url, setUrl] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setUrl(ticketIntegrationData.url);
    setUsername(ticketIntegrationData.usernameHost);
    setPassword(ticketIntegrationData.password);
  }, [ticketIntegrationData]);

  useEffect(() => {
    const timer = setTimeout(testCredentials, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [url, username, password]);

  const testCredentials = () => {
    if (
      ticketIntegrationData.url &&
      ticketIntegrationData.usernameHost &&
      ticketIntegrationData.password
    ) {
      saveOrTestIntegration(true);
    }
  };

  return (
    <div className="collapse-body flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('ticketIntegrationLabels.formLabels.step1.url')}
          required={true}
        />
        <Input
          placeholder={t(
            'ticketIntegrationLabels.formLabels.step1.urlPlaceholder'
          )}
          value={ticketIntegrationData.url}
          onChange={(e: any) => {
            validateEmptyField(
              e.target.value,
              t('ticketIntegrationLabels.formLabels.step1.url'),
              setUrlValidationMessage
            );
            setIsValidCredentials(false);
            setCredsValidationMessage('');
            dispatch(
              setTicketIntegrationData({
                ...ticketIntegrationData,
                url: e.target.value,
              })
            );
          }}
        />
        <span
          style={{
            display: `${getValidationStyle(urlValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {urlValidationMessage}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('ticketIntegrationLabels.formLabels.step1.usernameHost')}
          required={true}
        />
        <Input
          placeholder={t(
            'ticketIntegrationLabels.formLabels.step1.usernameHostPlaceholder'
          )}
          value={ticketIntegrationData.usernameHost}
          onChange={(e: any) => {
            validateEmptyField(
              e.target.value,
              t('ticketIntegrationLabels.formLabels.step1.usernameHost'),
              setUsernameHostValidationMessage
            );
            setIsValidCredentials(false);
            setCredsValidationMessage('');
            dispatch(
              setTicketIntegrationData({
                ...ticketIntegrationData,
                usernameHost: e.target.value,
              })
            );
          }}
        />
        <span
          style={{
            display: `${getValidationStyle(usernameHostValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {usernameHostValidationMessage}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('ticketIntegrationLabels.formLabels.step1.password')}
          required={true}
        />
        <Input
          placeholder={t(
            'ticketIntegrationLabels.formLabels.step1.passwordPlaceholder'
          )}
          type="password"
          value={ticketIntegrationData.password}
          onChange={(e: any) => {
            validateEmptyField(
              e.target.value,
              t('ticketIntegrationLabels.formLabels.step1.password'),
              setPassFieldValidationMessage
            );
            setIsValidCredentials(false);
            setCredsValidationMessage('');
            dispatch(
              setTicketIntegrationData({
                ...ticketIntegrationData,
                password: e.target.value,
              })
            );
          }}
        />
        <span
          style={{
            display: `${getValidationStyle(passFieldValidationMessage)}`,
          }}
          className="font-validation-error"
        >
          {passFieldValidationMessage}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`ticketIntegrationLabels.formLabels.step1.setDefaultRadio`)}
          required={true}
        />
        <Radio.Group
          buttonStyle="solid"
          value={ticketIntegrationData.setDefault}
          onChange={(e) => {
            dispatch(
              setTicketIntegrationData({
                ...ticketIntegrationData,
                setDefault: e.target.value,
              })
            );
          }}
        >
          <Radio.Button key="Yes" value={true}>
            Yes
          </Radio.Button>
          <Radio.Button key="No" value={false}>
            No
          </Radio.Button>
        </Radio.Group>
      </div>
      <span
        style={{
          display: `${getValidationStyle(credsValidationMessage)}`,
        }}
        className="font-validation-error"
      >
        {credsValidationMessage}
      </span>
    </div>
  );
};

export default CredentialDetails;
