import { useSelector } from 'react-redux';

import { selectScorecard } from 'redux/scorecardSlice';
import { PROVIDER } from 'constants/cloudProviders';
import AWSSummaryDashboard from './components/AWSSummaryDashboard';

import GCPSummaryDashboard from './components/GCPSummaryDashboard';

import './index.scss';

const ScorecardSummaryDashboard = () => {
  const { selectedConnection } = useSelector(selectScorecard);

  const getSummaryComponent = () => {
    if (selectedConnection?.provider === PROVIDER.GCP) {
      return <GCPSummaryDashboard />;
    }

    return <AWSSummaryDashboard />;
  };

  return (
    <div className="scorecard-summary-dashboard">{getSummaryComponent()}</div>
  );
};

export default ScorecardSummaryDashboard;
