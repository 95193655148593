import i18n from 'i18n';

export const AZURE_TRIGGER_AT = [
  {
    value: 'ACTUAL',
    label: i18n.t('addBudgetAlert.createBudgetLabels.actions.actualCost'),
  },
  {
    value: 'FORECASTED',
    label: i18n.t('addBudgetAlert.createBudgetLabels.actions.forecastSpend'),
  },
];

export const TIME_RANGES = [
  {
    label: i18n.t('addBudgetAlert.createBudgetLabels.scope.monthly'),
    value: 'MONTHLY',
  },
  {
    label: i18n.t('addBudgetAlert.createBudgetLabels.scope.quarterly'),
    value: 'QUARTERLY',
  },
  {
    label: i18n.t('addBudgetAlert.createBudgetLabels.scope.yearly'),
    value: 'ANNUALLY',
  },
];

export const AZURE_BUDGET_PERCENTAGE_MIN_LIMIT = 0.01;
export const AZURE_BUDGET_PERCENTAGE_MAX_LIMIT = 1000;
