import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import CalendarLineIcon from 'remixicon-react/Database2LineIcon';
import axios, { CancelTokenSource } from 'axios';
import { xor } from 'lodash';

import { selectReport, setReportData } from 'redux/reportSlice';
import { selectTheme } from 'redux/themeSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { selectDashboard } from 'redux/dashboardSlice';
import usePrevious from 'hooks/usePrevious';
import Table from 'components/Table';
import ExpandModal from 'components/ExpandModal';
import DashboardComponent from 'components/DashboardComponent';
import PieDonutChart from 'components/PieChartAnt';
import ColumnChart from 'components/ColumnChart';
import BarChart from 'components/BarChart';
import ColumnLineChart from 'components/ColumnLineChart';
import AreaChart from 'components/AreaChart';
import LineChart from 'components/LineChart';
import {
  getReportQuery,
  getReportFieldCategory,
  isEmptyDatasource,
  normalizeGroupData,
  getDimensionDisplayText,
  modifyDimensionLabelForCustomQuery,
} from 'pages/CreateReportPage/utils';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { ORDER_BY, REQUEST_STATUS } from 'constants/requestBody';
import {
  CHARTS_LIST,
  FIELD_TYPE,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import {
  numberCommaSeparator,
  replaceAllSpecialCharactersBy,
} from 'utils/dataFormatterUtils';
import { onApiCallError } from 'utils/handleErrors';
import { getChartData } from 'utils/services';
import { ReportOptionsType } from 'pages/ReportsPage/types';
import { OrderByType } from 'types/dashboard';
import { getProviderForConnection } from 'utils/dashboardUtils';
import { CHART_TYPES } from 'constants/graphConfig';
import { ReportsDataSourceNavs } from 'pages/CreateReportPage/constants';

import ReportTableOrChartHeader from '../ReportTableOrChartHeader';

import './index.scss';

type ReportTableOrChartProps = {
  pdfView?: boolean;
  sliderValue?: { x: number; y: number };
  setSliderValue?: Function;
};

const ReportTableOrChart = ({
  pdfView,
  sliderValue,
  setSliderValue,
}: ReportTableOrChartProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    reportOptions,
    reportData,
    reportDatasource,
    reportConnection,
    reportGroup,
    typeOfConnection,
    reportGroupAvailableFields,
    selectedDatasourceMenu,
  } = useSelector(selectReport);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { theme } = useSelector(selectTheme);
  const { showExpandGraphModal } = useSelector(selectDashboard);

  const [isTableView, setIsTableView] = useState(true);
  const [dataRequestStatus, setDataRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const previousReportOptions = usePrevious<ReportOptionsType>(reportOptions);
  const [previousCancelTokenSource, setPreviousCancelTokenSource] =
    useState<CancelTokenSource | null>(null);

  useEffect(() => {
    if (selectedDatasourceMenu === ReportsDataSourceNavs.DATASOURCE) {
      return;
    }

    setIsTableView(
      reportOptions.chartType === CHART_TYPES.TABLE ||
        selectedDatasourceMenu === ReportsDataSourceNavs.TABLE
    );
  }, [reportOptions.chartType, selectedDatasourceMenu]);

  useEffect(() => {
    if (isValidationSuccessful()) {
      if (previousCancelTokenSource) {
        previousCancelTokenSource.cancel();
      }
      const source = axios.CancelToken.source();
      setPreviousCancelTokenSource(source);

      if (typeOfConnection === MY_DASHBOARD_TYPES.GROUP && reportGroup) {
        fetchGroupReportData(source);
      } else if (reportConnection) {
        fetchConnectionReportData(source);
      }
    }
  }, [reportOptions, isTableView, reportConnection, reportGroup]);

  /**
   * @function isValidationSuccessful
   * @description Function to validate the graph options based on the view type
   * @returns boolean true if the validation is successful else false
   */
  const isValidationSuccessful = () => {
    if (pdfView) {
      return;
    }

    if (isTableView) {
      return validateTableOptions();
    }

    return validateChartOptions();
  };

  /**
   * @function filterReportSorts
   * @description Function to filter the completed sorts
   * @param sorts list of sorts to filter
   * @returns filtered sorts by removing partially filled sorts
   */
  const filterReportSorts = (sorts: OrderByType[] | undefined) => {
    return sorts?.filter((item) => item.label && item.sort);
  };

  /**
   * @function validateTableOptions
   * @description Function to validate the table options
   * @returns boolean true if the validation is successful else false
   */
  const validateTableOptions = () => {
    // Validate atleast one dimension or metric in table
    if (reportOptions.dimension.length + reportOptions.metric.length === 0) {
      dispatch(setReportData({ ...reportData, tableData: [] }));
      return false;
    }

    // No table data
    if (reportData.tableData.length === 0) {
      return true;
    }

    // Date Range Change
    if (
      previousReportOptions?.startDate !== reportOptions.startDate ||
      previousReportOptions?.endDate !== reportOptions.endDate
    ) {
      return true;
    }

    // Validate change in dimension
    if (
      xor(previousReportOptions?.dimension, reportOptions.dimension).length > 0
    ) {
      return true;
    }

    // Validate change in metric
    if (xor(previousReportOptions?.metric, reportOptions.metric).length > 0) {
      return true;
    }

    // Validate change in sorts
    if (
      xor(
        filterReportSorts(previousReportOptions?.sort),
        filterReportSorts(reportOptions.sort)
      ).length > 0
    ) {
      return true;
    }

    return false;
  };

  /**
   * @function validateChartOptions
   * @description Function to validate the chart options
   * @returns boolean true if the validation is successful else false
   */
  const validateChartOptions = () => {
    // Validate atleast one dimension or metric in table
    if (
      reportOptions.dimension.length === 0 ||
      reportOptions.metric.length === 0 ||
      !validateMinFieldsSelected()
    ) {
      dispatch(setReportData({ ...reportData, chartData: [] }));
      return false;
    }

    // No table data
    if (reportData.chartData.length === 0) {
      return true;
    }

    // Date Range Change
    if (
      previousReportOptions?.startDate !== reportOptions.startDate ||
      previousReportOptions?.endDate !== reportOptions.endDate
    ) {
      return true;
    }

    // Validate change in dimension
    if (
      xor(previousReportOptions?.chartDimension, reportOptions.chartDimension)
        .length > 0
    ) {
      return true;
    }

    // Validate change in metric
    if (
      xor(previousReportOptions?.chartMetric, reportOptions.chartMetric)
        .length > 0
    ) {
      return true;
    }

    return false;
  };

  /**
   * @function updateReportTableChartData
   * @description Function to update the table or chart data
   * @param newData Data to be updated with
   */
  const updateReportTableChartData = (newData: any[]) => {
    let data = { ...reportData };
    const metrics = isTableView
      ? reportOptions.metric
      : reportOptions.chartMetric;

    const typeModifiedData = newData.map((dataItem) => {
      let metricData: any = {};
      metrics.forEach((metric) => {
        const metricFieldKey = replaceAllSpecialCharactersBy(metric) ?? '';
        metricData[metricFieldKey] = Number(dataItem[metricFieldKey]);
      });

      return { ...dataItem, ...metricData };
    });

    if (isTableView) {
      data = { ...data, tableData: typeModifiedData };
    } else {
      data = { ...data, chartData: typeModifiedData };
    }
    dispatch(setReportData(data));
  };

  /**
   * @function fetchConnectionReportData
   * @description Function to fetch the connection report data
   * @param cancelTokenSource Cancel token
   */
  const fetchConnectionReportData = (cancelTokenSource: CancelTokenSource) => {
    setDataRequestStatus(REQUEST_STATUS.PROCESSING);

    let requestBody = {
      ...getReportQuery(isTableView, {
        provider: getProviderForConnection(reportConnection),
        connectorId: reportConnection?.connectorId ?? '',
      }),
    };

    const params = {
      queryId: reportDatasource?.customQueryId,
      database: reportDatasource?.dataSet,
    };

    getChartData(
      requestBody,
      reportConnection?.connectorId,
      params,
      cancelTokenSource?.token
    )
      .then((res: any) => {
        const fetchedData = res.data ?? [];
        updateReportTableChartData(fetchedData);

        setDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setDataRequestStatus);
      });
  };

  /**
   * @function fetchGroupReportData
   * @description Function fetch groups report data
   */
  const fetchGroupReportData = (cancelTokenSource: CancelTokenSource) => {
    if (!reportGroup?.connectorDtos?.length) return;

    setDataRequestStatus(REQUEST_STATUS.PROCESSING);

    const requests = reportGroup?.connectorDtos.map((connector) => {
      const requestBody = {
        ...getReportQuery(isTableView, {
          provider: getProviderForConnection(connector),
          connectorId: connector.connectorId,
        }),
      };

      return getChartData(
        requestBody,
        connector.connectorId,
        cancelTokenSource?.token
      );
    });

    Promise.all(requests)
      .then((res: any) => {
        let normalizedData = normalizeGroupData(isTableView, res);
        reportOptions.sort
          .filter((sort) => sort.label && sort.sort)
          .forEach((sort) => {
            if (
              reportGroupAvailableFields.find(
                (field) => field.label === sort.label
              )?.category === FIELD_TYPE.NUMERIC
            ) {
              normalizedData = normalizedData.sort((a: any, b: any) => {
                if (sort.sort === ORDER_BY.ASCENDING) {
                  return a[sort.label] - b[sort.label];
                }
                return b[sort.label] - a[sort.label];
              });
              return;
            }
            normalizedData = normalizedData.sort((a: any, b: any) => {
              if (sort.sort === ORDER_BY.ASCENDING) {
                return a[sort.label].localeCompare(b[sort.label]);
              }
              return b[sort.label].localeCompare(a[sort.label]);
            });
          });
        updateReportTableChartData(normalizedData);
        setDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setDataRequestStatus);
      });
  };

  /**
   * @function validateMinFieldsSelected
   * @description check if minimum required fields are selected for the chart
   * @returns true if minimum fields are selected else false
   */
  const validateMinFieldsSelected = () => {
    const chartProperties = CHARTS_LIST.find(
      (item) => item.chartType === reportOptions.chartType
    );
    if (
      (chartProperties?.dimensionsCount?.min || 0) >
        reportOptions.chartDimension?.length ||
      (chartProperties?.metricsCount?.min || 0) >
        reportOptions.chartMetric?.length
    )
      return false;
    return true;
  };

  const getColumns = () => {
    const columns: any[] = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        render: (_text: any, _record: any, index: number) => index + 1,
        width: 50,
        fixed: true,
      },
    ];

    reportOptions.dimension?.forEach((dimension) => {
      columns.push({
        title: getDimensionDisplayText(dimension),
        dataIndex: modifyDimensionLabelForCustomQuery(dimension),
        key: modifyDimensionLabelForCustomQuery(dimension),
        render: (text: any) =>
          getReportFieldCategory(dimension.dimension) === FIELD_TYPE.NUMERIC
            ? numberCommaSeparator(text)
            : text,
        width:
          getReportFieldCategory(dimension.dimension) === FIELD_TYPE.NUMERIC
            ? 100
            : 200,
      });
    });

    reportOptions.metric?.forEach((metric) => {
      columns.push({
        title: metric,
        dataIndex: replaceAllSpecialCharactersBy(metric),
        key: replaceAllSpecialCharactersBy(metric),
        render: (text: any) =>
          getReportFieldCategory(metric) === FIELD_TYPE.NUMERIC
            ? numberCommaSeparator(text)
            : text,
        width:
          getReportFieldCategory(metric) === FIELD_TYPE.NUMERIC ? 100 : 200,
      });
    });

    return columns;
  };

  const getReportChart = () => {
    const dimensions =
      reportOptions.chartDimension?.map((eachDimension) => ({
        title: getDimensionDisplayText(eachDimension),
        key: modifyDimensionLabelForCustomQuery(eachDimension) ?? '',
        category: getReportFieldCategory(eachDimension?.dimension),
      })) ?? [];
    const metrics =
      reportOptions.chartMetric?.map((metric) => ({
        title: metric,
        key: replaceAllSpecialCharactersBy(metric) ?? '',
      })) ?? [];

    let chartData = [...reportData.chartData];
    if (
      !dimensions.some((dimension) => dimension.category === FIELD_TYPE.TIME)
    ) {
      // sort the data based on the first metric if no sorts are applied.
      chartData.sort((a: any, b: any) => {
        return b[metrics[0]?.key] - a[metrics[0]?.key];
      });
    }

    const sliderValueByPdfView = pdfView ? sliderValue : undefined;

    switch (reportOptions.chartType) {
      case CHART_TYPES.PIE_CHART:
      case CHART_TYPES.DOUGHNUT_CHART:
        return (
          <PieDonutChart
            data={chartData}
            angleField={metrics[0]?.key}
            colorField={dimensions[0]?.key}
            isDonut={reportOptions.chartType === CHART_TYPES.DOUGHNUT_CHART}
            disableAnimation={pdfView}
            showLegends={!pdfView}
            additionalClassNames={`${pdfView && 'pdf-wrapper pdf-pie-wrapper'}`}
          />
        );
      case CHART_TYPES.BAR_CHART:
        return (
          <ColumnChart
            data={chartData}
            xField={dimensions[0].key}
            yField={metrics[0].key}
            xTitle={dimensions[0].title}
            yTitle={metrics[0].title}
            groupingField={dimensions[0].key}
            prefixSymbol={currencySymbol}
            disableAnimation={pdfView}
            showLegend={!pdfView}
            additionalClassNames={`${pdfView && 'pdf-wrapper'}`}
            showSlider
            sliderValues={sliderValueByPdfView}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y });
            }}
          />
        );
      case CHART_TYPES.HORIZONTAL_BAR_CHART:
        return (
          <BarChart
            data={chartData}
            xField={metrics[0].key}
            yField={dimensions[0].key}
            xTitle={metrics[0].title}
            yTitle={dimensions[0].title}
            groupingField={dimensions[0].key}
            prefixSymbol={currencySymbol}
            showAllLegend={pdfView}
            disableAnimation={pdfView}
          />
        );
      case CHART_TYPES.BAR_LINE_CHART:
        return (
          <ColumnLineChart
            data={chartData.map((item) => ({
              ...item,
              groupingField: metrics[0].title,
            }))}
            xField={dimensions[0].key}
            yField={metrics[0].key}
            groupingField="groupingField"
            xTitle={dimensions[0].title}
            yTitle={metrics[0].title}
            prefixSymbol={currencySymbol}
            disableAnimation={pdfView}
          />
        );
      case CHART_TYPES.AREA_CHART:
        return (
          <AreaChart
            data={chartData.map((item) => ({
              ...item,
              groupingField: metrics[0].title,
            }))}
            xField={dimensions[0].key}
            yField={metrics[0].key}
            groupingField="groupingField"
            xTitle={dimensions[0].title}
            yTitle={metrics[0].title}
            prefixSymbol={currencySymbol}
            colorOverride={{ [metrics[0].title]: theme.primaryColor }}
            flipPage={!pdfView}
            disableAnimation={pdfView}
            showSlider
            sliderValues={sliderValueByPdfView}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y });
            }}
          />
        );
      case CHART_TYPES.LINE_CHART:
        return (
          <LineChart
            data={chartData.map((item) => ({
              ...item,
              groupingField: metrics[0].title,
            }))}
            xField={dimensions[0].key}
            yField={metrics[0].key}
            groupingField="groupingField"
            xTitle={dimensions[0].title}
            yTitle={metrics[0].title}
            prefixSymbol={currencySymbol}
            colorOverride={theme.primaryColor}
            showAllLegend={pdfView}
            disableAnimation={pdfView}
            showSlider
            sliderValues={sliderValueByPdfView}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y });
            }}
          />
        );
      case CHART_TYPES.GROUPED_CHART: {
        let groupedChartData: any[] = [];
        chartData.forEach((item) => {
          metrics.forEach((metric) => {
            groupedChartData.push({
              [dimensions[0].key]: item[dimensions[0].key],
              metric: item[metric.key],
              groupingField: metric.title,
            });
          });
        });
        return (
          <ColumnChart
            data={groupedChartData}
            xField={dimensions[0].key}
            xTitle={dimensions[0].title}
            yField="metric"
            groupingField="groupingField"
            prefixSymbol={currencySymbol}
            isGroup
            disableAnimation={pdfView}
            showAllLegend={pdfView}
            showSlider
            sliderValues={sliderValueByPdfView}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y });
            }}
          />
        );
      }
      case CHART_TYPES.STACK_CHART: {
        let stackedChartData: any[] = [];
        chartData.forEach((item) => {
          stackedChartData.push({
            [metrics[0].key]: item[metrics[0].key],
            [dimensions[0].key]: item[dimensions[0].key],
            groupingField: item[dimensions[1].key],
          });
        });
        return (
          <ColumnChart
            data={stackedChartData}
            xField={dimensions[0].key}
            xTitle={dimensions[0].title}
            yField={metrics[0].key}
            yTitle={metrics[0].title}
            groupingField="groupingField"
            prefixSymbol={currencySymbol}
            isStack
            disableAnimation={pdfView}
            showLegend={!pdfView}
            additionalClassNames={`${pdfView && 'pdf-wrapper'}`}
            showSlider
            sliderValues={sliderValueByPdfView}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y });
            }}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const getComponent = () => {
    if (isTableView && isEmptyDatasource(isTableView)) {
      return (
        <div className="no-data flex flex-column flex-center flex-gap-8 flex-fit">
          <Icon iconName={ICONS.DATABASE_2_LINE} size={ICONS_SIZE.XL} />
          <span className="text font-caption-bold">{t('reports.noData')}</span>
        </div>
      );
    }

    if (isTableView) {
      return (
        <Table
          pagination={false}
          columns={getColumns()}
          dataSource={
            reportData?.tableData?.map((item, index) => ({
              ...item,
              key: index,
            })) || []
          }
          loading={dataRequestStatus === REQUEST_STATUS.PROCESSING}
          locale={{
            emptyText: dataRequestStatus !== REQUEST_STATUS.PROCESSING && (
              <Empty
                image={<CalendarLineIcon />}
                description={
                  dataRequestStatus === REQUEST_STATUS.ERROR
                    ? t('graphErrorMessage')
                    : t('reports.noData')
                }
              />
            ),
          }}
          scroll={{ y: 'inherit', x: 'max-content' }}
          designVersion2
          fillContainer
        />
      );
    }

    const chartProperties = CHARTS_LIST.find(
      (item) => item.chartType === reportOptions.chartType
    );
    if (!validateMinFieldsSelected())
      return (
        <div className="font-subHeader flex flex-center stretch">
          {t('reports.pleaseSelectMinimumRequiredMetricsDimensions', {
            metricAmount: chartProperties?.metricsCount?.min ?? 0,
            dimensionAmount: chartProperties?.dimensionsCount?.min ?? 0,
          })}
        </div>
      );

    if (
      (dataRequestStatus === REQUEST_STATUS.SUCCESS &&
        reportData?.chartData?.length === 0) ||
      dataRequestStatus === REQUEST_STATUS.ERROR
    ) {
      return (
        <div className="no-data flex flex-column flex-center flex-gap-8 flex-fit">
          <Icon iconName={ICONS.DATABASE_2_LINE} size={ICONS_SIZE.XL} />
          <span className="text font-caption-bold">
            {dataRequestStatus === REQUEST_STATUS.ERROR
              ? t('graphErrorMessage')
              : t('reports.noData')}
          </span>
        </div>
      );
    }

    return (
      <DashboardComponent
        component={getReportChart() || <></>}
        requestStatus={dataRequestStatus}
      />
    );
  };

  const ReportTableOrChartComponent = (
    <>
      <ReportTableOrChartHeader
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        pdfView={pdfView}
      />
      {getComponent()}
    </>
  );

  return (
    <div
      className="report-table-or-chart flex flex-column"
      id="graph-container"
    >
      {ReportTableOrChartComponent}
      {showExpandGraphModal && !pdfView && (
        <ExpandModal graphContent={ReportTableOrChartComponent} />
      )}
    </div>
  );
};

export default ReportTableOrChart;
