import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BudgetDataTypes } from 'pages/BudgetsAndAlertsPage/types';
import {
  BudgetsAndAlertsComponents,
  DEFAULT_BUDGET_DATA,
} from 'pages/BudgetsAndAlertsPage/constants';
import { RootState } from './store';

export interface BudgetsAndAlertsState {
  budgetsAlertsComponent: string;
  budgetData: BudgetDataTypes;
  isEdit: boolean;
}

const initialState: BudgetsAndAlertsState = {
  budgetsAlertsComponent:
    BudgetsAndAlertsComponents.BUDGETS_ALERTS_CONNECTION_FORM,
  budgetData: DEFAULT_BUDGET_DATA,
  isEdit: false,
};

export const budgetsAndAlertsSlice = createSlice({
  name: 'budgetsAndAlerts',
  initialState,
  reducers: {
    setBudgetsAlertsComponent: (state, action: PayloadAction<string>) => {
      state.budgetsAlertsComponent = action.payload;
    },
    setBudgetData: (state, action: PayloadAction<BudgetDataTypes>) => {
      state.budgetData = action.payload;
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
  },
});

export const budgetsAndAlerts = (state: RootState) => state.budgetsAndAlerts;
export const { setBudgetsAlertsComponent, setBudgetData, setIsEdit } =
  budgetsAndAlertsSlice.actions;
export default budgetsAndAlertsSlice.reducer;
