import i18n from 'i18n';
import moment from 'moment';

import { MonthlyCostType } from 'types/dataTypes';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  getMonthYearShortList,
} from 'utils/date';
import { getSelectedDashboardConnectionName } from 'utils/dashboardUtils';

import { CostTrendExportColumns } from './constants';

/**
 * @function getCostTrendExcelData
 * @description Function to return the cost trend excel data
 * @param costTrendData List of cost trend data
 * @param dateRange Date range selected
 * @returns Objects containing excel export details
 */
export const getCostTrendExcelData = (
  costTrendData: MonthlyCostType[],
  dateRange: string[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.costTrend'),
    columns: CostTrendExportColumns,
    data: insertIndex(costTrendData).map((item) => ({
      ...item,
      cost: numberCommaSeparator(item.cost),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.integrationName'),
        value: getSelectedDashboardConnectionName(),
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: moment(dateRange[0], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: moment(dateRange[1], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
    ],
  };
};

/**
 * @function getCostByNamePerMonthExportColumns
 * @description Function to return the columns for cost by name per month excel export
 * @param graph Graph selected
 * @returns Array of objects containing excel export details
 */
export const getCostByNamePerMonthExportColumns = (
  graph: string,
  startDate: string,
  endDate: string
) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header:
      graph === i18n.t('graphHeadings.storageUsageByDatabasePerMonth')
        ? i18n.t('snowflakeDashboards.databases')
        : i18n.t('snowflakeDashboards.warehouses'),
    key: 'name',
    dataKey: 'name',
    width: 30,
  },
  ...getMonthYearShortList(startDate, endDate, MONTH_YEAR_SHORT).map(
    (month) => ({
      header: month,
      key: `${month}-cost`,
      dataKey: `${month}-cost`,
      width: 15,
      alignment: 'right',
    })
  ),
];

/**
 * @function getCostByNamePerMonthExcelData
 * @description Function to return the cost by name per month excel data
 * @param costByNamePerMonth List of cost trend data
 * @param dateRange Date range selected
 * @returns Objects containing excel export details
 */
export const getCostByNamePerMonthExcelData = (
  sheetName: string,
  costByNamePerMonth: any[],
  dateRange: string[]
) => {
  return {
    sheetName: sheetName,
    columns: getCostByNamePerMonthExportColumns(
      sheetName,
      dateRange[0],
      dateRange[1]
    ),
    data: insertIndex(costByNamePerMonth),
    filters: [
      {
        heading: i18n.t('excelExportLabels.integrationName'),
        value: getSelectedDashboardConnectionName(),
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: moment(dateRange[0], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: moment(dateRange[1], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
    ],
  };
};
