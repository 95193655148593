import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  importStaticFiles,
  setImportFileData,
} from 'redux/importStaticFileSlice';
import { providerList } from 'redux/providerSlice';
import { selectTheme } from 'redux/themeSlice';
import { FormLabel } from 'components/FormLabel';
import Button from 'components/Button';
import RadioGroup from 'components/RadioGroup';
import Input from 'components/Input';
import Switch from 'components/Switch';
import {
  getValidationStyle,
  isEmptyField,
  validateEmptyField,
  validateStringLengthLessThan,
} from 'utils/validations';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import {
  getSampleSchema,
  validateSchema,
} from 'pages/StaticFilesPage/services';
import { SchemaDefinitionType } from 'pages/StaticFilesPage/types';
import Loader from 'components/Loader';
import Icon from 'components/Icon';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import { ICONS } from 'constants/icons';
import { onApiCallError } from 'utils/handleErrors';
import { DASHBOARD_TYPES, REQUEST_STATUS } from 'constants/requestBody';
import { LoadingIcon } from 'assets/icons';
import { downloadSchema } from 'pages/StaticFilesPage/utils';

import SchemaRow from '../SchemaRow';
import {
  convertSchemaDefinitionToSchema,
  convertSchemaToSchemaDefinition,
  getSchemaDefaultFields,
  resetSchemaDefinitionToStandard,
} from './utils';
import { validateFieldName } from '../SchemaRow/utils';

type SchemaDetailsProps = {
  isValidSchema: boolean;
  setIsValidSchema: (val: boolean) => void;
};

const SchemaDetails = ({
  isValidSchema,
  setIsValidSchema,
}: SchemaDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { importFileData } = useSelector(importStaticFiles);
  const { selectedProvider } = useSelector(providerList);
  const { theme } = useSelector(selectTheme);

  const [schemaNameValidationMessage, setSchemaNameValidationMessage] =
    useState('');
  const [schemaValidationMessage, setSchemaValidationMessage] = useState('');
  const [schemaDefinitionValidation, setSchemaDefinitionValidation] =
    useState('');
  const [isValidatingSchema, setIsValidatingSchema] = useState(false);
  const [standardSchema, setStandardSchema] = useState<SchemaDefinitionType[]>(
    []
  );
  const [sampleSchemaRequestStatus, setSampleSchemaRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [schemaParsingError, setSchemaParsingError] = useState<string | null>(
    null
  );

  useEffect(() => {
    fetchSampleSchema();
  }, [importFileData.typeOfDataset]);

  /**
   * @function fetchSampleSchema
   * @description Function to fetch the sample schema
   */
  const fetchSampleSchema = () => {
    setSampleSchemaRequestStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      provider: selectedProvider,
      dashBoardType: importFileData.typeOfDataset,
    };

    getSampleSchema(params)
      .then((res: any) => {
        if (res.status === 200) {
          setStandardSchema(res.data);
          setSampleSchemaRequestStatus(REQUEST_STATUS.SUCCESS);
          return;
        }

        setSampleSchemaRequestStatus(REQUEST_STATUS.ERROR);
        setStandardSchema([]);
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setSampleSchemaRequestStatus,
          t('importFileLabels.formLabels.step3.errorFetchingSampleSchema')
        );
        setStandardSchema([]);
      });
  };

  /**
   * @function validateSchemaName
   * @description Function to validate the schema name
   * @param schemaName string value to be validated
   */
  const validateSchemaName = (schemaName: string) => {
    if (
      validateEmptyField(
        schemaName,
        t('importFileLabels.formLabels.step3.schemaName'),
        setSchemaNameValidationMessage
      )
    ) {
      return;
    }

    if (validateStringLengthLessThan(schemaName, MAX_CHARACTER_LIMIT)) {
      setSchemaNameValidationMessage(
        t('importFileLabels.formLabels.step3.schemaNameLengthValidation')
      );
      return;
    }

    setSchemaNameValidationMessage('');
  };

  /**
   * @function validateEnteredSchema
   * @description Function to validate the schema entered
   * @param schema string schema to be validated
   */
  const validateEnteredSchema = (schema: string) => {
    if (
      validateEmptyField(
        schema,
        t('importFileLabels.formLabels.step3.schema'),
        setSchemaValidationMessage
      )
    ) {
      return;
    }

    setSchemaValidationMessage('');
  };

  /**
   * @function onClickAddField
   * @description Call back for add field button, adds a new row in schema definition
   */
  const onClickAddField = () => {
    setIsValidSchema(false);
    dispatch(
      setImportFileData({
        ...importFileData,
        schemaDefinition: [
          ...importFileData.schemaDefinition,
          getSchemaDefaultFields(),
        ],
      })
    );
    setSchemaDefinitionValidation('');
  };

  /**
   * @function validateSchemaDefinitionFields
   * @description Function to validate the schema definition fields
   * @returns boolean true the validation is successful, else false
   */
  const validateSchemaDefinitionFields = () => {
    if (
      importFileData.schemaDefinition.some(
        (schema) =>
          isEmptyField(schema.name) ||
          isEmptyField(schema.type) ||
          isEmptyField(schema.mode)
      )
    ) {
      setSchemaDefinitionValidation(
        t('importFileLabels.formLabels.step3.allMandatoryFieldsReq')
      );
      return false;
    }

    if (
      importFileData.schemaDefinition.some(
        (schema, index) =>
          !validateFieldName(
            schema.name,
            index,
            importFileData.schemaDefinition
          )
      )
    ) {
      setSchemaDefinitionValidation(
        t('importFileLabels.formLabels.step3.checkEachRowValidation')
      );
      return false;
    }

    return true;
  };

  /**
   * @function onClickValidate
   * @description Function to validate the schema definition fields and validate schema through API call
   */
  const onClickValidate = () => {
    if (!importFileData.isCreateSchemaDefinition && !importFileData.schema) {
      setIsValidSchema(false);
      validateEnteredSchema(importFileData.schema);
      return;
    }

    if (importFileData.isCreateSchemaDefinition) {
      if (!validateSchemaDefinitionFields()) {
        setIsValidSchema(false);
        return;
      }
    }

    setIsValidatingSchema(true);
    const requestBody = {
      schemaString: importFileData.isCreateSchemaDefinition
        ? JSON.stringify(
            importFileData.schemaDefinition.map((item) => ({
              ...item,
              key: undefined,
            }))
          )
        : importFileData.schema,
      datasourceProvider: selectedProvider,
      datasetType: importFileData.typeOfDataset,
      standardSchema: importFileData.isExistingSchema,
      preBuiltDashbaordRequired: importFileData.wantSpendDiagnosticsDashboard,
    };

    validateSchema(requestBody)
      .then((res: any) => {
        if (res?.status === 200) {
          importFileData.isCreateSchemaDefinition
            ? setSchemaDefinitionValidation(
                t('importFileLabels.formLabels.step3.schemaValidationSuccess')
              )
            : setSchemaValidationMessage(
                t('importFileLabels.formLabels.step3.schemaValidationSuccess')
              );

          setIsValidSchema(true);
          setIsValidatingSchema(false);
          return;
        }
        setSchemaValidationMessage(res.data.message);
        setIsValidSchema(false);
        setIsValidatingSchema(false);
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message ??
          t('importFileLabels.formLabels.step3.errorValidatingQuery');
        importFileData.isCreateSchemaDefinition
          ? setSchemaDefinitionValidation(errorMessage)
          : setSchemaValidationMessage(errorMessage);
        setIsValidSchema(false);
        setIsValidatingSchema(false);
      });
  };

  /**
   * @function onUpdateSchemaDefinition
   * @description Callback for for updating any field in the schema definition
   */
  const onUpdateSchemaDefinition = () => {
    setSchemaDefinitionValidation('');
    setSchemaParsingError(null);
  };

  const schemaForm = () => {
    if (importFileData.isCreateSchemaDefinition) {
      return (
        <div className="schema-definition styled-scroll flex flex-column flex-gap-16">
          <div className="schema-list flex flex-column flex-gap-16">
            {importFileData.schemaDefinition
              .map((schema, index) => ({ ...schema, key: schema.name + index }))
              .map((schema, index) => (
                <SchemaRow
                  key={schema.key}
                  schema={schema}
                  position={index}
                  setIsValidSchema={setIsValidSchema}
                  onUpdateSchemaDefinition={onUpdateSchemaDefinition}
                  standardSchema={standardSchema}
                />
              ))}
          </div>
          <div className="flex flex-start">
            <Button
              className="add-field-button"
              iconName={ICONS.ADD_LINE}
              title={t('importFileLabels.formLabels.step3.addField')}
              size={BUTTON_SIZE.SMALL}
              onClick={onClickAddField}
            />
          </div>
          <span
            style={{
              display: getValidationStyle(schemaDefinitionValidation),
            }}
            className={`${!isValidSchema && 'font-validation-error'} ${
              isValidSchema && 'validation-success'
            }`}
          >
            {schemaDefinitionValidation}
          </span>
          <span
            style={{
              display: getValidationStyle(schemaParsingError),
            }}
            className="font-validation-error"
          >
            {schemaParsingError}
          </span>
        </div>
      );
    }

    return (
      <div className="form-item flex flex-column">
        <FormLabel
          title={t('importFileLabels.formLabels.step3.schema')}
          required
        />
        <Input
          className="table-typography"
          type="textarea"
          placeholder={t('importFileLabels.formLabels.step3.enterSchema')}
          value={importFileData.schema}
          autoSize={{ minRows: 5, maxRows: 10 }}
          onChange={(e: any) => {
            validateEnteredSchema(e.target.value);
            setIsValidSchema(false);
            dispatch(
              setImportFileData({ ...importFileData, schema: e.target.value })
            );
          }}
          onBlur={() => validateEnteredSchema(importFileData.schema)}
        />
        <span
          style={{
            display: getValidationStyle(schemaValidationMessage),
          }}
          className={`${!isValidSchema && 'font-validation-error'} ${
            isValidSchema && 'validation-success'
          }`}
        >
          {schemaValidationMessage}
        </span>
      </div>
    );
  };

  const onChangeCreateSchemaDefinition = (checked: boolean) => {
    if (checked) {
      dispatch(
        setImportFileData({
          ...importFileData,
          isCreateSchemaDefinition: checked,
          schemaDefinition: convertSchemaToSchemaDefinition(
            importFileData,
            setSchemaParsingError,
            standardSchema
          ),
        })
      );
      setSchemaDefinitionValidation('');
      return;
    }

    dispatch(
      setImportFileData({
        ...importFileData,
        isCreateSchemaDefinition: checked,
        schema: convertSchemaDefinitionToSchema(
          importFileData,
          schemaParsingError,
          setSchemaParsingError
        ),
      })
    );
    setIsValidSchema(false);
    setSchemaDefinitionValidation('');
    setSchemaValidationMessage('');
  };

  /**
   * @function sampleSchemaComponent
   * @description Function to return the sample schema component based on the request status
   * @returns JSX element
   */
  const sampleSchemaComponent = () => {
    if (sampleSchemaRequestStatus === REQUEST_STATUS.PROCESSING) {
      return (
        <Button
          type={BUTTON_TYPE.LINK}
          icon={
            <Icon
              className="rotate"
              icon={LoadingIcon}
              color={theme.buttonIconColor}
            />
          }
          size={BUTTON_SIZE.SMALL}
          disabled={importFileData.focusSchema}
        />
      );
    }

    if (sampleSchemaRequestStatus === REQUEST_STATUS.ERROR) {
      return (
        <Button
          type={BUTTON_TYPE.LINK}
          title={t('importFileLabels.formLabels.step3.fetchSampleSchema')}
          size={BUTTON_SIZE.SMALL}
          onClick={fetchSampleSchema}
        />
      );
    }

    return (
      <Button
        type={BUTTON_TYPE.LINK}
        title={t('importFileLabels.formLabels.step3.sampleSchema')}
        size={BUTTON_SIZE.SMALL}
        onClick={() => downloadSchema(standardSchema)}
      />
    );
  };

  return (
    <div className="collapse-body flex flex-column flex-gap-16">
      {importFileData.typeOfDataset === DASHBOARD_TYPES.BILLING && (
        <div className="form-item flex flex-column">
          <div className="flex flex-space-between flex-align-items-center">
            <FormLabel
              title={t('importFileLabels.formLabels.step3.isFocusSchemaLabel')}
              required={true}
            />
          </div>
          <RadioGroup
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            onChange={(e: any) => {
              dispatch(
                setImportFileData({
                  ...importFileData,
                  focusSchema: e.target.value,
                  isExistingSchema: !e.target.value,
                  wantSpendDiagnosticsDashboard: false,
                })
              );
            }}
            value={importFileData.focusSchema}
            optionType="button"
            rootClassName="no-custom-style"
            style={{ height: 28 }}
          />
        </div>
      )}
      <div className="form-item flex flex-column">
        <div className="flex flex-space-between flex-align-items-center">
          <FormLabel
            title={t(
              'importFileLabels.formLabels.step3.useExistingSchemaLabel',
              { provider: selectedProvider }
            )}
            required={true}
            disabled={importFileData.focusSchema}
          />
          {importFileData.isExistingSchema && sampleSchemaComponent()}
        </div>
        <RadioGroup
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]}
          onChange={(e: any) => {
            dispatch(
              setImportFileData({
                ...importFileData,
                isExistingSchema: e.target.value,
                wantSpendDiagnosticsDashboard: false,
              })
            );
          }}
          value={importFileData.isExistingSchema}
          optionType="button"
          rootClassName="no-custom-style"
          style={{ height: 28 }}
          disabled={importFileData.focusSchema}
        />
      </div>
      {!importFileData.focusSchema && !importFileData.isExistingSchema && (
        <>
          <div className="form-item flex flex-column">
            <FormLabel
              title={t(
                'importFileLabels.formLabels.step3.wantSpendDiagnosticsLabel'
              )}
              required={true}
            />
            <RadioGroup
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              onChange={(e: any) => {
                dispatch(
                  setImportFileData({
                    ...importFileData,
                    wantSpendDiagnosticsDashboard: e.target.value,
                    schemaDefinition: e.target.value
                      ? resetSchemaDefinitionToStandard(
                          importFileData.schemaDefinition,
                          standardSchema
                        )
                      : importFileData.schemaDefinition,
                  })
                );
              }}
              value={importFileData.wantSpendDiagnosticsDashboard}
              optionType="button"
              rootClassName="no-custom-style"
              style={{ height: 28 }}
            />
          </div>
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('importFileLabels.formLabels.step3.schemaName')}
              required={true}
            />
            <Input
              placeholder={t(
                'importFileLabels.formLabels.step3.schemaNamePlaceholder'
              )}
              value={importFileData.schemaName}
              onChange={(e: any) => {
                validateSchemaName(e.target.value);
                dispatch(
                  setImportFileData({
                    ...importFileData,
                    schemaName: e.target.value,
                  })
                );
              }}
              onBlur={(e: any) => validateSchemaName(e.target.value)}
            />
            <span
              style={{
                display: `${getValidationStyle(schemaNameValidationMessage)}`,
              }}
              className="font-validation-error"
            >
              {schemaNameValidationMessage}
            </span>
          </div>
          <div className="form-item flex flex-column">
            <div className="flex flex-space-between flex-align-items-center">
              <FormLabel
                title={t(
                  'importFileLabels.formLabels.step3.createSchemaDefinition'
                )}
              />
              <Switch
                checked={importFileData.isCreateSchemaDefinition}
                onChange={onChangeCreateSchemaDefinition}
                size="small"
              />
            </div>
          </div>
          <div className="small-grey-button flex flex-align-items-center flex-gap-8">
            <Icon iconName={ICONS.INFORMATION_LINE} />
            {t('schemaDefinitionOrderInfo')}
          </div>
          {schemaForm()}
          <div className="flex flex-end">
            <Button
              title={t('importFileLabels.formLabels.step3.validateSchema')}
              onClick={onClickValidate}
            />
          </div>
          {isValidatingSchema && (
            <Loader wrapperClassName="validation-loader" />
          )}
        </>
      )}
    </div>
  );
};

export default SchemaDetails;
