import { useTranslation } from 'react-i18next';
import AccessibleDiv from 'components/AccessibleDiv';
import { getProviderLogo } from 'utils/providerDetails';

import './index.scss';

type CloudProvidersProps = {
  providerList: { provider: string; header: string; subHeader: string }[];
  selectedProvider: string;
  handleClick: (value: string) => void;
  header: string;
  subHeader: string;
  disabledList?: string[];
};

const CloudProviders = ({
  providerList,
  selectedProvider,
  handleClick,
  header,
  subHeader,
  disabledList,
}: CloudProvidersProps) => {
  const { t } = useTranslation();

  return (
    <section className="provider-section-body flex flex-fit flex-column flex-align-items-center new-styled-scroll">
      <div className="provider-header">
        <div className="provider-heading font-caption-bold">{header}</div>
        <p className="provider-sub-heading font-caption">{subHeader}</p>
      </div>
      {providerList.map((item) => (
        <div
          key={item.provider}
          className={`provider-card-wrapper ${
            disabledList?.includes(item.provider) && 'cursor-disabled'
          }`}
        >
          <AccessibleDiv
            className={`provider-card cursor-pointer flex flex-align-items-center ${
              selectedProvider === item.provider && 'selected'
            } ${disabledList?.includes(item.provider) && 'disabled'}`}
            onClick={() => handleClick(item.provider)}
          >
            <img
              className="logo"
              src={getProviderLogo(item.provider)}
              alt={`${item.provider}-logo`}
            />
            <div className="provider-card-about flex flex-column">
              <div className="font-caption-bold">
                {t(`providerPageList.${item.header}`)}
              </div>
              <span className="font-caption">
                {t(`providerPageList.${item.subHeader}`)}
              </span>
            </div>
          </AccessibleDiv>
        </div>
      ))}
    </section>
  );
};

export default CloudProviders;
