import { t } from 'i18next';

import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import { validateStringLengthLessThan } from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';
import { getNavigationPathTitle } from 'utils/navigationMenu';

import {
  CHECK_CONNECTOR_NAME_SUCCESS_MESSAGE,
  DEPENDENCY_KEY_TO_MENU_ID_MAP,
} from './constants';
import { checkConnectorNameAlreadyUsed } from './services';
import { ResponseDependencyType } from './types';

/**
 * @function validateDuplicateConnectionName
 * @description Function to check whether the input connection name has been previously used
 * @param value This param is for passing value to check
 * @param setStateFn This param is to set the validation message
 */
export const validateDuplicateConnectionName = (
  value: string,
  setStateFn: (value: string) => void,
  setIsDuplicateConnectionName?: (value: boolean) => void,
  isEditConnection: boolean = false,
  connectionName: string = ''
) => {
  if (isEditConnection && connectionName === value) {
    setIsDuplicateConnectionName?.(false);
    return;
  }

  const params = {
    name: value,
  };
  checkConnectorNameAlreadyUsed(params)
    .then((res: any) => {
      if (res?.data?.message === CHECK_CONNECTOR_NAME_SUCCESS_MESSAGE) {
        setIsDuplicateConnectionName?.(false);
      } else {
        setStateFn(`${t('connectionCSPForm.duplicateConnectionName')}`);
      }
    })
    .catch((error) => {
      onApiCallError(error);
    });
};

/**
 * @function validateInputLength
 * @description Function to handle validate input length
 * @param value This param is for passing value to check
 * @param fieldName This param is for setting validation message
 * @param setStateFn This param is to set the validation message
 * @param setErrorMessage A boolean indicating whether to set the error message or not true by default
 */
export const validateInputLength = (
  value: string,
  fieldName: string,
  setStateFn: (value: string) => void,
  setErrorMessage: boolean = true
) => {
  if (validateStringLengthLessThan(value, MAX_CHARACTER_LIMIT)) {
    setErrorMessage &&
      setStateFn(
        `${fieldName} ${t(
          'connectionCSPForm.charactersShouldBeLessThan'
        )} ${MAX_CHARACTER_LIMIT}`
      );
    return false;
  }
  setErrorMessage && setStateFn('');
  return true;
};

/**
 * @function getDependencyList
 * @description Returns the list of dependencies with additional details to display on the UI
 * @param dependencies List of dependencies fetched from the server
 * @return List of dependencies with title, path, etc.
 */
export const getDependencyList = (dependencies: ResponseDependencyType[]) => {
  return dependencies.map((item: ResponseDependencyType) => {
    const menuDetails = DEPENDENCY_KEY_TO_MENU_ID_MAP.find(
      (keyIdMap) => keyIdMap.key === item.key
    );
    const menuId = menuDetails?.id ?? item.key;
    const title = menuDetails?.title ?? item.key;
    const paths: string[] = getNavigationPathTitle(menuId as string);

    return {
      dependentSectionId: menuId,
      title: title,
      dependenciesList: item.values,
      dependencySectionPath: paths
        .map((path) => t(`navigationMenu.${path}`))
        .join(' / '),
    };
  });
};
