import React from 'react';
import { Input as BaseInput, InputNumber, InputRef } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { selectTheme } from 'redux/themeSlice';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { INPUT_PADDING, INPUT_SIZE } from 'constants/appearance';
import { getClassnameBySize } from 'utils/dashboardUtils';
import {
  decrementNumberBySteps,
  incrementNumberBySteps,
  onChangeStepNumber,
} from './utils';

const { TextArea } = BaseInput;

const Input = React.forwardRef<InputRef | HTMLInputElement, any>(
  (
    {
      type = 'input',
      size = INPUT_SIZE.MEDIUM,
      autoWidth = false,
      ...remainingProps
    },
    ref
  ) => {
    const { theme } = useSelector(selectTheme);

    const style = {
      ...remainingProps.style,
      '--primary-color': theme.primaryColor,
    };

    const passwordInputIconRender = (visible: any) =>
      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

    switch (type) {
      case 'search':
        return (
          <BaseInput
            size="middle"
            {...remainingProps}
            ref={ref}
            rootClassName={`${getClassnameBySize(size)} ${
              remainingProps.rootClassName
            }`}
            suffix={
              <Icon iconName={ICONS.SEARCH_2_LINE} className="search-icon" />
            }
            style={style}
          />
        );

      case 'textarea':
        return (
          <TextArea
            {...remainingProps}
            style={style}
            nonce="YmhkY2JoZGJjaGJkY2RiY2prYmFkY2JkYWNkYmNkYw=="
          />
        );

      case 'password':
        return (
          <BaseInput.Password
            {...remainingProps}
            ref={ref}
            rootClassName={`${getClassnameBySize(size)}`}
            iconRender={passwordInputIconRender}
            style={style}
          />
        );

      case 'number':
        return (
          <InputNumber
            rootClassName={`${getClassnameBySize(size)}`}
            ref={ref}
            {...remainingProps}
            style={style}
          />
        );

      case 'stepNumber':
        return (
          <BaseInput
            {...remainingProps}
            ref={ref}
            rootClassName={`${
              remainingProps.rootClassName
            } ${getClassnameBySize(size)} step-number-input`}
            style={style}
            suffix={
              <Icon
                iconName={ICONS.ADD_LINE}
                onClick={() =>
                  incrementNumberBySteps(
                    remainingProps.value,
                    remainingProps.max,
                    remainingProps.steps ?? 1,
                    remainingProps.onChange
                  )
                }
              />
            }
            prefix={
              <Icon
                iconName={ICONS.SUBTRACT_LINE}
                onClick={() =>
                  decrementNumberBySteps(
                    remainingProps.value,
                    remainingProps.min,
                    remainingProps.steps ?? 1,
                    remainingProps.onChange
                  )
                }
              />
            }
            onChange={(e) => onChangeStepNumber(e, remainingProps.onChange)}
          />
        );

      default:
        return (
          <BaseInput
            rootClassName={`${getClassnameBySize(size)} ${
              remainingProps.rootClassName
            }`}
            {...remainingProps}
            ref={ref}
            style={{
              ...style,
              transition: 'none',
              width: autoWidth
                ? remainingProps.value?.length + INPUT_PADDING + 'ch'
                : undefined,
            }}
            onChange={(e) => remainingProps?.onChange?.(e)}
            onFocus={(e) => remainingProps?.onFocus?.(e)}
          />
        );
    }
  }
);

export default Input;
