import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchCmdbDatasourceProviders
 * @description Function to fetch CMDB datasource providers
 * @return axios response from GET request
 */
export const fetchCmdbDatasourceProviders = async () => {
  return ServiceCalls.get(APIS.GET_CMDB_DATASOURCE_PROVIDERS);
};

/**
 * @function validateDuplicateCmdbName
 * @description Function to validate the CMDB name
 * @param params Object containing the request parameters
 * @return axios response from GET request
 */
export const validateDuplicateCmdbName = async (params: any) => {
  return ServiceCalls.get(APIS.VALIDATE_DUPLICATE_CMDB_NAME, null, params);
};

/**
 * @function fetchCmdbConfigurationById
 * @description Function to fetch CMDB data by id
 * @param params Object containing the request parameters
 * @return axios response from GET request
 */
export const fetchCmdbConfigurationById = async (params: any) => {
  return ServiceCalls.get(APIS.GET_CMDB_CONFIGURATION_BY_ID, null, params);
};
