import i18n from 'i18n';

export enum CONTAINER_INSIGHTS_NAVS {
  USAGE_METERING = 'USAGE_METERING',
}

export const CONTAINER_INSIGHTS_DASHBOARD_NAVIGATION = [
  {
    id: CONTAINER_INSIGHTS_NAVS.USAGE_METERING,
    title: i18n.t('dashNav.usageMetering'),
  },
];

export enum DATE_INTERVALS {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  HOUR = 'hour',
}

export enum DATE_RANGES {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_MONTH = 'THIS_MONTH',
  THIS_QUARTER = 'THIS_QUARTER',
  THIS_YEAR = 'THIS_YEAR',
  CUSTOM_RANGE = 'CUSTOM_RANGE',
}

export const DATE_RANGE_OPTIONS = [
  {
    key: DATE_RANGES.TODAY,
    title: i18n.t('containerInsight.dateRanges.today'),
  },
  {
    key: DATE_RANGES.YESTERDAY,
    title: i18n.t('containerInsight.dateRanges.yesterday'),
  },
  {
    key: DATE_RANGES.THIS_MONTH,
    title: i18n.t('containerInsight.dateRanges.thisMonth'),
  },
  {
    key: DATE_RANGES.THIS_QUARTER,
    title: i18n.t('containerInsight.dateRanges.thisQuarter'),
  },
  {
    key: DATE_RANGES.THIS_YEAR,
    title: i18n.t('containerInsight.dateRanges.thisYear'),
  },
  {
    key: DATE_RANGES.CUSTOM_RANGE,
    title: i18n.t('containerInsight.dateRanges.customRange'),
  },
];
