import { sum } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import { MemoryUtilizationType } from '../../../type';
import GranulateUsageMeteringPieChart from '../GranulateUsageMeteringPieChart';

type MemoryUtilizationChartType = {
  data: MemoryUtilizationType[];
  requestStatus: string;
  pdfView: boolean;
  memoryUtilizationTableView: boolean;
  setMemoryUtilizationTableView: (value: boolean) => void;
  excelFilters: any[];
};

const MemoryUtilizationChart = ({
  data,
  requestStatus,
  pdfView,
  memoryUtilizationTableView,
  setMemoryUtilizationTableView,
  excelFilters,
}: MemoryUtilizationChartType) => {
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(selectCommonUtility);

  const headingComponent = (
    <div className="flex flex-column flex-gap-8">
      <span className="graph-heading font-caption-bold">
        {t('consolidatedDashboard.memoryUtilization')}
      </span>
      <div className="flex flex-column flex-gap-8">
        <span className="font-graph-total">
          {numberCommaSeparator(
            sum(data.map((value) => value.memoryUtilisation))
          )}{' '}
          {t('consolidatedDashboard.MI')}
        </span>
        <span className="font-graph-total">
          {currencySymbol}{' '}
          {numberCommaSeparator(
            sum(data.map((value) => value.costAfterCredit))
          )}
        </span>
      </div>
    </div>
  );

  return (
    <GranulateUsageMeteringPieChart
      data={data}
      heading={t('consolidatedDashboard.memoryUtilization')}
      headingComponent={headingComponent}
      graphName="memory-utilization"
      requestStatus={requestStatus}
      suffixText={t('consolidatedDashboard.MI')}
      colorField="name"
      angleField="memoryUtilisation"
      pdfView={pdfView}
      setIsTableView={setMemoryUtilizationTableView}
      isTableView={memoryUtilizationTableView}
      excelFilters={excelFilters}
    />
  );
};

export default MemoryUtilizationChart;
