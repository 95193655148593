import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import GraphHeader from 'components/GraphHeader';
import { evaluateRequestArray } from 'utils/handleErrors';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import ColumnChart from 'components/ColumnChart';
import { ICONS } from 'constants/icons';
import Icon from 'components/Icon';
import GraphBreadcrumb from 'components/GraphBreadcrumb';
import AccessibleDiv from 'components/AccessibleDiv';
import {
  ColouredCostByAccountType,
  ColouredCostByResourceType,
  ColouredCostByServiceType,
} from 'types/dataTypes';
import { isDashboardWithStaticData } from 'utils/dashboardUtils';

import {
  getTopAccountsExcelData,
  getTopAccountsTableDataSource,
  getTopAccountsTableHeading,
} from '../../utils';

type ToAccountsProps = {
  topAccountsData: ColouredCostByAccountType[];
  startDate: string;
  endDate: string;
  selectedAccounts: string[];
  pdfView: boolean;
  requestStatus: string[];
  isTableView: boolean;
  setTableView: (value: boolean) => void;
  selectedAccount: string;
  setSelectedAccount: (value: string) => void;
  selectedService: string;
  setSelectedService: (value: string) => void;
  accountWiseServiceData: ColouredCostByServiceType[];
  serviceWiseResourceData: ColouredCostByResourceType[];
  sliderValue?: { x: number; y: number };
  setSliderValue: (value: { x: number; y: number } | undefined) => void;
};

const TopAccounts = ({
  topAccountsData,
  startDate,
  endDate,
  selectedAccounts,
  pdfView,
  requestStatus,
  isTableView,
  setTableView,
  selectedAccount,
  setSelectedAccount,
  selectedService,
  setSelectedService,
  accountWiseServiceData,
  serviceWiseResourceData,
  sliderValue,
  setSliderValue,
}: ToAccountsProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [breadcrumbPaths, setBreadcrumbPaths] = useState<string[]>([]);

  useEffect(() => {
    setBreadcrumbPathsByGraph();
  }, [selectedAccount, selectedService]);

  const setBreadcrumbPathsByGraph = () => {
    if (!selectedAccount) {
      setBreadcrumbPaths([]);
      return;
    }

    if (!selectedService) {
      setBreadcrumbPaths([
        t('graphHeadings.costByAccount') + ' - ' + selectedAccount,
        t('graphHeadings.costByService'),
      ]);
      return;
    }

    setBreadcrumbPaths([
      t('graphHeadings.costByAccount') + ' - ' + selectedAccount,
      t('graphHeadings.costByService') + ' - ' + selectedService,
      t('graphHeadings.costByResources'),
    ]);
  };

  const getColumns = () => {
    let columns = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: '7%',
        render: (_text: any, _record: any, index: number) => index + 1,
      },
      {
        title: t('accountDeepDiveSummary.accounts'),
        dataIndex: 'account',
        key: 'account',
      },
      {
        title: t('accountDeepDiveSummary.costWithoutSuffix'),
        dataIndex: 'cost',
        key: 'cost',
        width: '20%',
        align: 'center',
        render: (text: any) => currencySymbol + numberCommaSeparator(text),
      },
    ];

    if (!selectedAccount) return columns;
    else if (!selectedService)
      columns.splice(1, 1, {
        title: t('accountDeepDiveSummary.services'),
        dataIndex: 'service',
        key: 'service',
      });
    else
      columns.splice(1, 1, {
        title: t('accountDeepDiveSummary.resources'),
        dataIndex: 'resource',
        key: 'resource',
      });
    return columns;
  };

  const getChartComponent = () => {
    if (!selectedAccount)
      return (
        <ColumnChart
          data={topAccountsData.map((item) => ({
            ...item,
            groupingField: item.account,
          }))}
          key="account"
          yField="cost"
          xField="account"
          groupingField="groupingField"
          xTitle={t('accountDeepDiveSummary.accounts')}
          yTitle={t('accountDeepDiveSummary.cost', {
            currencySymbol: currencySymbol,
          })}
          disableAnimation={pdfView}
          prefixSymbol={currencySymbol}
          columnColorOverride={topAccountsData.map((item: any) => item.color)}
          additionalClassNames={`${pdfView && 'pdf-wrapper'}`}
          onClickColumn={(e: any) => setSelectedAccount(e?.data?.data?.account)}
          additionalTooltipContent={
            <span className="tooltip-deep-dive-label font-subHeader-small">
              {t('clickDeepDive')}
            </span>
          }
          showSlider
          isColumnClickable
          sliderValues={pdfView ? sliderValue : undefined}
          onSliderValueChange={(x: number, y: number) => {
            setSliderValue({ x, y });
          }}
          showLegend={!pdfView}
        />
      );

    if (!selectedService)
      return (
        <ColumnChart
          data={accountWiseServiceData.map((item) => ({
            ...item,
            groupingField: item.service,
          }))}
          key="service"
          xField="service"
          yField="cost"
          groupingField="groupingField"
          xTitle={t('accountDeepDiveSummary.services')}
          yTitle={t('accountDeepDiveSummary.cost', {
            currencySymbol: currencySymbol,
          })}
          showAllLegend={pdfView}
          prefixSymbol={currencySymbol}
          disableAnimation={pdfView}
          columnColorOverride={accountWiseServiceData.map((item) => item.color)}
          additionalClassNames={`${pdfView && 'pdf-wrapper'}`}
          onClickColumn={(e: any) => {
            if (!isDashboardWithStaticData(selectedDashboard)) {
              setSelectedService(e?.data?.data?.service);
            }
          }}
          additionalTooltipContent={
            <span className="tooltip-deep-dive-label font-subHeader-small">
              {t('clickDeepDive')}
            </span>
          }
          showSlider
          isColumnClickable
          sliderValues={pdfView ? sliderValue : undefined}
          onSliderValueChange={(x: number, y: number) => {
            setSliderValue({ x, y });
          }}
          showLegend={!pdfView}
        />
      );

    return (
      <ColumnChart
        data={serviceWiseResourceData.map((item) => ({
          ...item,
          groupingField: item.resource,
        }))}
        key="resource"
        xField="resource"
        yField="cost"
        groupingField="groupingField"
        disableAnimation={pdfView}
        columnColorOverride={serviceWiseResourceData.map(
          (item: any) => item.color
        )}
        additionalClassNames={`${pdfView && 'pdf-wrapper'}`}
        xTitle={t('accountDeepDiveSummary.resources')}
        yTitle={t('accountDeepDiveSummary.cost', {
          currencySymbol: currencySymbol,
        })}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
        showSlider
        sliderValues={pdfView ? sliderValue : undefined}
        onSliderValueChange={(x: number, y: number) => {
          setSliderValue({ x, y });
        }}
        showLegend={!pdfView}
      />
    );
  };

  const getComponent = () =>
    isTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          dataSource={getTopAccountsTableDataSource(
            topAccountsData,
            accountWiseServiceData,
            serviceWiseResourceData,
            selectedAccount,
            selectedService
          ).map((item, index) => {
            return {
              ...item,
              key: index,
            };
          })}
          columns={getColumns()}
          scroll={{ y: 220 }}
          designVersion2
        />
      </div>
    ) : (
      getChartComponent()
    );

  const getHeadingComponent = () => {
    if (!selectedAccount) {
      return (
        <div className="graph-heading flex flex-column">
          {t('accountDeepDiveSummary.topAccounts')}
          {!isTableView && !pdfView && (
            <span className="sub-title font-caption-bold">
              {t('clickBarInfo')}
            </span>
          )}
        </div>
      );
    }

    if (!selectedService) {
      return (
        <div className="graph-heading flex flex-column">
          <AccessibleDiv
            className="flex flex-align-items-center cursor-pointer"
            onClick={() => setSelectedAccount('')}
          >
            <Icon
              iconName={ICONS.ARROW_LEFT_S_LINE}
              className="previous-icon flex flex-end"
            />
            <span>{t('graphHeadings.costByService')}</span>
          </AccessibleDiv>
          <GraphBreadcrumb pathItems={breadcrumbPaths} />
        </div>
      );
    }

    return (
      <div className="graph-heading flex flex-column">
        <AccessibleDiv
          className="flex flex-align-items-center cursor-pointer"
          onClick={() => setSelectedService('')}
        >
          <Icon
            iconName={ICONS.ARROW_LEFT_S_LINE}
            className="previous-icon flex flex-end"
          />
          <span>{t('graphHeadings.costByResources')}</span>
        </AccessibleDiv>
        <GraphBreadcrumb pathItems={breadcrumbPaths} />
      </div>
    );
  };

  return (
    <div
      className="top-accounts-graph graph-card flex flex-column flex-fit"
      id="graph-container"
    >
      <GraphHeader
        heading={getTopAccountsTableHeading(selectedAccount, selectedService)}
        headingComponent={getHeadingComponent()}
        graphName={'top-accounts'}
        isDownloadable={!pdfView}
        showExpandIcon={!pdfView}
        isTableViewSwitch={!pdfView}
        isTableView={isTableView}
        setIsTableView={setTableView}
        excelData={getTopAccountsExcelData(
          selectedDashboard?.connectorName ?? '',
          getTopAccountsTableDataSource(
            topAccountsData,
            accountWiseServiceData,
            serviceWiseResourceData,
            selectedAccount,
            selectedService
          ),
          selectedAccount,
          selectedService,
          startDate,
          endDate,
          selectedAccounts
        )}
        designVersion2
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default TopAccounts;
