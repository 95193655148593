import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectTheme } from 'redux/themeSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import {
  costOptimizationInsights,
  setCurrentRecommendationView,
  setViewList,
} from 'redux/costOptimizationInsightsSlice';
import GraphHeader from 'pages/CostOptimizationInsightsPage/components/GraphHeader';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  DEFAULT_VIEW,
  DEFAULT_VIEW_DROPDOWN_OPTIONS,
} from 'pages/CostOptimizationInsightsPage/constants';
import {
  RecommendationFiltersType,
  ViewListType,
} from 'pages/CostOptimizationInsightsPage/types';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getRecommendationViews } from 'pages/CostOptimizationInsightsPage/services';
import { onApiCallError } from 'utils/handleErrors';
import { RulesetsType } from 'pages/RuleEnginePage/types';
import { getAllRulesets } from 'pages/RuleEnginePage/services';
import Filters from 'pages/CostOptimizationInsightsPage/components/Filters';
import { getLabelledFilters } from 'pages/CostOptimizationInsightsPage/utils';
import SelectedFilters from 'pages/CostOptimizationInsightsPage/components/SelectedFilters';
import { ProjectNumberIdMappingType } from 'types/dashboard';

import ViewDropdown from '../ViewDropdown';
import DeleteViewButton from '../DeleteViewButton';
import CreateSnowTicketButton from '../CreateSnowTicketButton';
import { fetchRecommendationFilters } from './utils';

type RecommendationsHeaderProps = {
  fetchSnowIncidents: Function;
  projectNumberIdMapping?: ProjectNumberIdMappingType[];
};

const RecommendationsHeader = ({
  fetchSnowIncidents,
  projectNumberIdMapping = [],
}: RecommendationsHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { theme } = useSelector(selectTheme);
  const { permissions } = useSelector(userAuthorization);
  const {
    selectedCostOptimizationInsightsConnection,
    selectedCostOptimizationInsightsNav,
    viewList,
    currentRecommendationView,
    consolidatedExportData,
  } = useSelector(costOptimizationInsights);

  const [viewListRequestStatus, setViewListRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [rulesets, setRulesets] = useState<RulesetsType[]>([]);
  const [rulesetTagOptionsLoading, setRulesetTagOptionsLoading] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [recommendationFilters, setRecommendationFilters] = useState<
    RecommendationFiltersType[]
  >([]);
  const [labelledRecommendationFilters, setLabelledRecommendationFilters] =
    useState<RecommendationFiltersType[]>([]);
  const [
    recommendationFiltersRequestStatus,
    setRecommendationFiltersRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);

  useEffect(() => {
    setRulesets([]);
    if (selectedCostOptimizationInsightsConnection) {
      getAllRulesetTagOptions();
    }
  }, [selectedCostOptimizationInsightsConnection]);

  useEffect(() => {
    dispatch(setCurrentRecommendationView(DEFAULT_VIEW_DROPDOWN_OPTIONS[0]));
    setRecommendationFilters([]);

    if (selectedCostOptimizationInsightsConnection) {
      getAllRecommendationViews();
      fetchRecommendationFilters(
        selectedCostOptimizationInsightsConnection!,
        selectedCostOptimizationInsightsNav,
        setRecommendationFilters,
        setRecommendationFiltersRequestStatus
      );
    }
  }, [
    selectedCostOptimizationInsightsConnection,
    selectedCostOptimizationInsightsNav,
  ]);

  useEffect(() => {
    // Set the updated labels for the filter values.
    setLabelledRecommendationFilters(
      getLabelledFilters(
        recommendationFilters,
        {
          projectNumberIdMapping,
          rulesets,
        },
        permissions.thirdPartyAppsRead
      )
    );
  }, [recommendationFilters, projectNumberIdMapping, rulesets]);

  /**
   * @function getAllRulesetTagOptions
   * @description Function to fetch all the ruleset to show tags in filter options.
   */
  const getAllRulesetTagOptions = () => {
    setRulesetTagOptionsLoading(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: selectedCostOptimizationInsightsConnection?.provider,
    };
    getAllRulesets(params)
      .then((res: any) => {
        setRulesets(res?.data?.responseData?.content || []);
        setRulesetTagOptionsLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => onApiCallError(e, false, setRulesetTagOptionsLoading));
  };

  /**
   * @function getAllRecommendationViews
   * @description Function to fetch all the available filters for connection.
   * @param currentView the view to be set as the current view after fetching recommendation views
   */
  const getAllRecommendationViews = (currentView?: ViewListType) => {
    setViewListRequestStatus(REQUEST_STATUS.PROCESSING);
    const extraHeaders = {
      connectorId: selectedCostOptimizationInsightsConnection!.connectorId,
    };
    getRecommendationViews(extraHeaders)
      .then((res: any) => {
        if (res?.status === 200) {
          const formattedViews =
            res?.data?.responseData?.map((view: any) => {
              return {
                ...view,
                key: view.viewName,
                recommendationsFilterDtoList:
                  view?.recommendationsFilterDtoList?.map((filter: any) => ({
                    ...filter,
                    values:
                      filter?.values?.map((item: any) => ({
                        value: item,
                        label: item,
                      })) || [],
                  })),
              };
            }) || [];
          dispatch(
            setViewList([...DEFAULT_VIEW_DROPDOWN_OPTIONS, ...formattedViews])
          );
          setViewListRequestStatus(REQUEST_STATUS.SUCCESS);
          currentView && dispatch(setCurrentRecommendationView(currentView));
          return;
        }

        dispatch(setViewList([...DEFAULT_VIEW_DROPDOWN_OPTIONS]));
        setViewListRequestStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false);
        dispatch(setViewList([...DEFAULT_VIEW_DROPDOWN_OPTIONS]));
        setViewListRequestStatus(REQUEST_STATUS.ERROR);
      });
  };

  return (
    <div data-testid="recommendations-header">
      <GraphHeader
        heading={t(`costOptimizationInsight.recommendationTableHeader.header`)}
        graphName={t(
          `costOptimizationInsight.recommendationTableHeader.header`
        )}
        ignorePadding={true}
        showExpandIcon={true}
        isDownloadable
        viewDropdown={
          <ViewDropdown
            viewList={viewList}
            viewListRequestStatus={viewListRequestStatus}
            rulesets={rulesets}
            rulesetTagOptionsLoading={rulesetTagOptionsLoading}
          />
        }
        actionButtons={[
          {
            component: (
              <Icon
                iconName={ICONS.REFRESH_LINE}
                onClick={() => fetchSnowIncidents()}
                size={ICONS_SIZE.ONE_X}
                color={theme.buttonIconColor}
                dataTestId="refresh-cta"
              />
            ),
            permission: true,
          },
          {
            component: (
              <DeleteViewButton
                getAllRecommendationViews={getAllRecommendationViews}
                rulesets={rulesets}
              />
            ),
            permission: permissions.costControlModify,
          },
          {
            component: (
              <CreateSnowTicketButton fetchSnowIncidents={fetchSnowIncidents} />
            ),
            permission: permissions.costControlWrite,
          },
          {
            component: (
              <Filters
                filters={labelledRecommendationFilters}
                disabled={currentRecommendationView.key !== DEFAULT_VIEW}
                loading={[
                  recommendationFiltersRequestStatus,
                  rulesetTagOptionsLoading,
                ]}
                onClickSubmit={(view: ViewListType) => {
                  setViewList(undefined);
                  getAllRecommendationViews(view);
                }}
              />
            ),
            permission: permissions.costControlWrite,
          },
        ]}
        excelData={consolidatedExportData}
      />
      {currentRecommendationView.recommendationsFilterDtoList &&
        currentRecommendationView.recommendationsFilterDtoList.length > 0 && (
          <SelectedFilters
            filters={labelledRecommendationFilters}
            selectedFilters={
              currentRecommendationView.recommendationsFilterDtoList
            }
          />
        )}
    </div>
  );
};

export default RecommendationsHeader;
