import i18n from 'i18n';

import { AWS_CHECK_IDS } from 'constants/recommendations';
import { QUERY_FIELDS } from 'constants/requestBody';

import { formatNumberToCompact } from 'utils/dataFormatterUtils';

export enum AUTOPILOT_SAVINGS_GRAPH_TYPE {
  DAILY,
  MONTHLY,
}

export const AWS_CHECK_IDS_TO_SAVINGS_COST_FIELD = {
  // Waste Management
  [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES]:
    QUERY_FIELDS.SUM_MONTHLY_STOARGE_COST,
  [AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS]:
    QUERY_FIELDS.SUM_ESTIMATED_MONTHLY_SAVINGS,
  [AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES]:
    QUERY_FIELDS.SUM_ESTIMATED_MONTHLY_SAVINGS,
  [AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE]:
    QUERY_FIELDS.SUM_ESTIMATED_MONTHLY_SAVINGS,
  [AWS_CHECK_IDS.IDLE_IP_ADDRESS]: QUERY_FIELDS.COUNT_ALL_MULTIPLY_3_2,
  [AWS_CHECK_IDS.IDLE_LOAD_BALANCER]:
    QUERY_FIELDS.SUM_ESTIMATED_MONTHLY_SAVINGS,

  // Consumption Management
  [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES]:
    QUERY_FIELDS.SUM_ESTIMATED_MONTHLY_SAVINGS,
  [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY]:
    QUERY_FIELDS.SUM_ESTIMATED_MONTHLY_SAVINGS,
  [AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER]:
    QUERY_FIELDS.SUM_ESTIMATED_MONTHLY_SAVINGS,
  [AWS_CHECK_IDS.RIGHT_SIZING_EC2]: QUERY_FIELDS.SUM_ESTIMATED_SAVINGS,

  // Purchase Tactics
  [AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE]:
    QUERY_FIELDS.SUM_ESTIMATED_MONTHLY_SAVINGS,
  [AWS_CHECK_IDS.RDS_RI_OPTIMIZATION]:
    QUERY_FIELDS.SUM_ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS,
  [AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION]:
    QUERY_FIELDS.SUM_ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS,
  [AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION]:
    QUERY_FIELDS.SUM_ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS,
  [AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION]:
    QUERY_FIELDS.SUM_ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS,
  [AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION]:
    QUERY_FIELDS.SUM_ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS,
  [AWS_CHECK_IDS.SAVINGS_PLAN]: QUERY_FIELDS.SUM_ESTIMATED_MONTHLY_SAVINGS,
};

export const AWS_CHECK_IDS_TO_RECOMMENDATION_FIELD = {
  // Waste Management
  [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES]: QUERY_FIELDS.VOLUME_ID,
  [AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS]: QUERY_FIELDS.CLUSTER,
  [AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES]: QUERY_FIELDS.INSTANCE_ID,
  [AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE]: QUERY_FIELDS.DB_INSTANCE_NAME,
  [AWS_CHECK_IDS.IDLE_IP_ADDRESS]: QUERY_FIELDS.IP_ADDRESS,
  [AWS_CHECK_IDS.IDLE_LOAD_BALANCER]: QUERY_FIELDS.LOAD_BALANCER_NAME,

  // Consumption Management
  [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES]: QUERY_FIELDS.VOLUME_ID,
  [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY]:
    QUERY_FIELDS.FUNCTION_NAME,
  [AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER]:
    QUERY_FIELDS.INSTANCE_ID,
  [AWS_CHECK_IDS.RIGHT_SIZING_EC2]: QUERY_FIELDS.INSTANCE_ID_NO_SPACE,

  // Purchase Tactics
  [AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE]: QUERY_FIELDS.RESERVED_INSTANCE_ID,
  [AWS_CHECK_IDS.RDS_RI_OPTIMIZATION]: QUERY_FIELDS.INSTANCE_TYPE,
  [AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION]: QUERY_FIELDS.NODE_TYPE,
  [AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION]: QUERY_FIELDS.INSTANCE_CLASS,
  [AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION]: QUERY_FIELDS.INSTANCE_TYPE,
  [AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION]:
    QUERY_FIELDS.NODE_TYPE,
  [AWS_CHECK_IDS.SAVINGS_PLAN]: QUERY_FIELDS.SAVINGS_PLAN_TYPE,
};

const UNDERUTILIZED_EBS_VOLUMES_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_ID}"`,
    label: 'volumeId',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_NAME}"`,
    label: 'volumeName',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_TYPE}"`,
    label: 'volumeType',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_SIZE}"`,
    label: 'volumeSize',
  },
  {
    field: `"${QUERY_FIELDS.SNAPSHOT_ID}"`,
    label: 'snapshotId',
  },
  {
    field: `"${QUERY_FIELDS.SNAPSHOT_NAME}"`,
    label: 'snapshotName',
  },
  {
    field: `"${QUERY_FIELDS.SNAPSHOT_AGE}"`,
    label: 'snapshotAge',
  },
  {
    field: `"${QUERY_FIELDS.MONTHLY_STORAGE_COST}"`,
    label: 'monthlyStorageCost',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const UNDERUTILIZED_REDSHIFT_CLUSTERS_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.STATUS}"`,
    label: 'status',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.CLUSTER}"`,
    label: 'cluster',
  },
  {
    field: `"${QUERY_FIELDS.REASON}"`,
    label: 'reason',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
    label: 'instanceType',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    label: 'estimatedMonthlySavings',
  },
  {
    field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const UNDERUTILIZED_EC2_INSTANCES_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_ID}"`,
    label: 'instanceId',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_NAME}"`,
    label: 'instanceName',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
    label: 'instanceType',
  },
  {
    field: `"${QUERY_FIELDS.FOURTEEN_DAYS_AVERAGE_CPU_UTILIZATION}"`,
    label: 'cpuUtilizationFourteenDayAverage',
  },
  {
    field: `"${QUERY_FIELDS.FOURTEEN_DAYS_AVERAGE_NETWORK_IO}"`,
    label: 'networkIoFourteenDayAverage',
  },
  {
    field: `"${QUERY_FIELDS.NUMBER_OF_DAYS_LOW_UTILIZATION}"`,
    label: 'numberOfDaysLowUtilization',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    label: 'estimatedMonthlySavings',
  },
  {
    field: `"${QUERY_FIELDS.CUSTOM_RECOM_STATUS}"`,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const IDLE_RDS_DB_INSTANCE_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
    label: 'instanceType',
  },
  {
    field: `"${QUERY_FIELDS.DB_INSTANCE_NAME}"`,
    label: 'dbInstanceName',
  },
  {
    field: `"${QUERY_FIELDS.MULTI_AZ}"`,
    label: 'multiAz',
  },
  {
    field: `"${QUERY_FIELDS.STORAGE_PROVISIONED}"`,
    label: 'storageProvisioned',
  },
  {
    field: `"${QUERY_FIELDS.DAYS_SINCE_LAST_CONNECTION}"`,
    label: 'daysSinceLastConnection',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    label: 'estimatedMonthlySavings',
  },
  {
    field: `"${QUERY_FIELDS.CUSTOM_RECOM_STATUS}"`,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const IDLE_IP_ADDRESS_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.IP_ADDRESS}"`,
    label: 'ipAddress',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: '3.2',
    label: 'costSavings',
  },
  {
    field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const IDLE_LOAD_BALANCER_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.LOAD_BALANCER_NAME}"`,
    label: 'loadBalancerName',
  },
  {
    field: `"${QUERY_FIELDS.REASON}"`,
    label: 'reason',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    label: 'estimatedMonthlySavings',
  },
  { field: QUERY_FIELDS.CUSTOM_RECOM_STATUS, label: 'recStatus' },
  { field: QUERY_FIELDS.STATUS_PRIORITY, label: 'priority' },
];

const OVERPROVISIONED_EBS_VOLUMES_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_ID}"`,
    label: 'volumeId',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_TYPE}"`,
    label: 'volumeType',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_SIZE_GB}"`,
    label: 'volumeSizeGb',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_BASELINE_IOPS}"`,
    label: 'volumeBaselineIops',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_BURST_IOPS}"`,
    label: 'volumeBurstIops',
  },
  {
    field: `"${QUERY_FIELDS.VOLUME_BURST_THROUGHPUT}"`,
    label: 'volumeBurstThroughput',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_TYPE}"`,
    label: 'recommendedVolumeType',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_SIZE_GB}"`,
    label: 'recommendedVolumeSizeGb',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_BASELINE_IOPS}"`,
    label: 'recommendedVolumeBaselineIops',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_BURST_IOPS}"`,
    label: 'recommendedVolumeBurstIops',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_BASELINE_THROUGHPUT}"`,
    label: 'recommendedVolumeBaselineThroughput',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_VOLUME_BURST_THROUGHPUT}"`,
    label: 'recommendedVolumeBurstThroughput',
  },
  {
    field: `"${QUERY_FIELDS.LOOKBACK_PERIOD_DAYS}"`,
    label: 'lookbackPeriodDays',
  },
  {
    field: `"${QUERY_FIELDS.SAVINGS_OPPORTUNITY_PERCENTAGE}"`,
    label: 'savingsOpportunityPercentage',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    label: 'estimatedMonthlySavings',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS_CURRENCY}"`,
    label: 'estimatedMonthlySavingsCurrency',
  },
  {
    field: `"${QUERY_FIELDS.LAST_UPDATED_TIME}"`,
    label: 'lastUpdatedTime',
  },
  {
    field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.STATUS}"`,
    label: 'status',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.FUNCTION_NAME}"`,
    label: 'functionName',
  },
  {
    field: `"${QUERY_FIELDS.FUNCTION_VERSION}"`,
    label: 'functionVersion',
  },
  {
    field: `"${QUERY_FIELDS.MEMORY_SIZE}"`,
    label: 'memorySize',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_MEMORY_SIZE}"`,
    label: 'recommendedMemorySize',
  },
  {
    field: `"${QUERY_FIELDS.LOOKBACK_PERIOD}"`,
    label: 'lookbackPeriod',
  },
  {
    field: `"${QUERY_FIELDS.SAVINGS_OPPORTUNITY}"`,
    label: 'savingsOpportunity',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    label: 'estimatedMonthlySavings',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS_CURRENCY}"`,
    label: 'estimatedMonthlySavingsCurrency',
  },
  {
    field: `"${QUERY_FIELDS.LAST_UPDATED_TIME}"`,
    label: 'lastUpdatedTime',
  },
  {
    field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_ID}"`,
    label: 'instanceId',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
    label: 'instanceType',
  },
  {
    field: `"${QUERY_FIELDS.VCPU}"`,
    label: 'vCpu',
  },
  {
    field: `"${QUERY_FIELDS.SQL_SERVER_EDITION}"`,
    label: 'sqlServerEdition',
  },
  {
    field: `"${QUERY_FIELDS.MAXIMUM_VCPU}"`,
    label: 'maximumVCpu',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_INSTANCE_TYPE}"`,
    label: 'recommendedInstanceType',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    label: 'estimatedMonthlySavings',
  },
  {
    field: `"${QUERY_FIELDS.LAST_UPDATED_TIME}"`,
    label: 'lastUpdatedTime',
  },
  {
    field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const RIGHT_SIZING_EC2_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_ID_NO_SPACE}"`,
    label: 'instanceId',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_NAME_NO_SPACE}"`,
    label: 'instanceName',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_TYPE_NO_SPACE}"`,
    label: 'instanceType',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_INSTANCE_TYPE_NO_SPACE}"`,
    label: 'recommendedInstanceType',
  },
  {
    field: `"${QUERY_FIELDS.GENERATION_TIME}"`,
    label: 'generationTime',
  },
  {
    field: `"${QUERY_FIELDS.CPU_UTILIZATION}"`,
    label: 'cpuUtilization',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_SAVINGS}"`,
    label: 'estimatedSavings',
  },
  {
    field: QUERY_FIELDS.CUSTOM_RECOM_STATUS,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const EC2_INSTANCES_RI_LEASE_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REASON}"`,
    label: 'reason',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
    label: 'instanceType',
  },
  {
    field: `"${QUERY_FIELDS.ZONE}"`,
    label: 'zone',
  },
  {
    field: `"${QUERY_FIELDS.PLATFORM}"`,
    label: 'platform',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_COUNT}"`,
    label: 'instanceCount',
  },
  {
    field: `"${QUERY_FIELDS.CURRENT_MONTHLY_COST}"`,
    label: 'currentMonthlyCost',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    label: 'estimatedMonthlySavings',
  },
  {
    field: `"${QUERY_FIELDS.EXPIRATION_DATE}"`,
    label: 'expirationDate',
  },
  {
    field: `"${QUERY_FIELDS.RESERVED_INSTANCE_ID}"`,
    label: 'reservedInstanceId',
  },
  {
    field: `"${QUERY_FIELDS.CUSTOM_RECOM_STATUS}`,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const RDS_RI_OPTIMIZATION_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
    label: 'instanceType',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
    label: 'estimatedSavingsWithRecommendations',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_BREAK_EVEN}"`,
    label: 'estimatedBreakEven',
  },
  {
    field: `"${QUERY_FIELDS.LOOKBACK_PERIOD}"`,
    label: 'lookbackPeriod',
  },
  {
    field: `"${QUERY_FIELDS.TERM}"`,
    label: 'term',
  },
  {
    field: `"${QUERY_FIELDS.FAMILY}"`,
    label: 'family',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_NUMBER_OF_RESERVED_INSTANCES_TO_PURCHASE}"`,
    label: 'recommendedNumberOfReservedInstancesToPurchase',
  },
  {
    field: `"${QUERY_FIELDS.EXPECTED_AVERAGE_RESERVED_INSTANCES_TO_PURCHASE}"`,
    label: 'expectedAverageReservedInstancesToPurchase',
  },
  {
    field: `"${QUERY_FIELDS.UPFRONT_COST_OF_RESERVED_INSATNCES}"`,
    label: 'upfrontCostOfReservedInstances',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_COST_OF_RESERVED_INSTANCES}"`,
    label: 'estimatedCostOfReservedInstances',
  },
  {
    field: `"${QUERY_FIELDS.LICENCE_MODEL}"`,
    label: 'licenceModel',
  },
  {
    field: `"${QUERY_FIELDS.DATABASE_EDITION}"`,
    label: 'databaseEdition',
  },
  {
    field: `"${QUERY_FIELDS.DATABASE_ENGINE}"`,
    label: 'databaseEngine',
  },
  {
    field: `"${QUERY_FIELDS.DEPLOYMENT_OPTION}"`,
    label: 'deploymentOption',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVE_INSTANCE_PURCHASE_MONTHLY}"`,
    label: 'estimatedOnDemandCostPostRecommendedReserveInstancePurchaseMonthly',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.CUSTOM_RECOM_STATUS}`,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const REDSHIFT_RESERVED_NODE_OPTIMIZATION_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
    label: 'estimatedSavingsWithRecommendations',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_BREAK_EVEN}"`,
    label: 'estimatedBreakEven',
  },
  {
    field: `"${QUERY_FIELDS.LOOKBACK_PERIOD}"`,
    label: 'lookbackPeriod',
  },
  {
    field: `"${QUERY_FIELDS.TERM}"`,
    label: 'term',
  },
  {
    field: `"${QUERY_FIELDS.FAMILY}"`,
    label: 'family',
  },
  {
    field: `"${QUERY_FIELDS.NODE_TYPE}"`,
    label: 'nodeType',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_NUMBER_OF_RESERVED_NODES_TO_PURCHASE}"`,
    label: 'recommendedNumberOfReservedNodesToPurchase',
  },
  {
    field: `"${QUERY_FIELDS.EXPECTED_AVERAGE_RESERVED_NODE_UTILIZARION}"`,
    label: 'expectedAverageReservedNodeUtilization',
  },
  {
    field: `"${QUERY_FIELDS.UPFRONT_COST_OF_RESERVED_NODES}"`,
    label: 'upfrontCostOfReservedNodes',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_COST_OF_RESERVED_NODES}"`,
    label: 'estimatedCostOfReservedNodes',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVED_NODES_PURCHASE}"`,
    label: 'estimatedOnDemandCostPostRecommendedReservedNodesPurchase',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.CUSTOM_RECOM_STATUS}"`,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const OPEN_SEARCH_RI_OPTIMIZATION_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
    label: 'estimatedSavingsWithRecommendations',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_BREAK_EVEN}"`,
    label: 'estimatedBreakEven',
  },
  {
    field: `"${QUERY_FIELDS.LOOKBACK_PERIOD}"`,
    label: 'lookbackPeriod',
  },
  {
    field: `"${QUERY_FIELDS.TERM}"`,
    label: 'term',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_CLASS}"`,
    label: 'instanceClass',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_SIZE}"`,
    label: 'instanceSize',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_NUMBER_OF_RESERVED_INSTANCES_TO_PURCHASE}"`,
    label: 'recommendedNumberOfReservedInstancesToPurchase',
  },
  {
    field: `"${QUERY_FIELDS.EXPECTED_AVERAGE_RESERVED_INSTANCES_TO_PURCHASE}"`,
    label: 'expectedAverageReservedInstancesToPurchase',
  },
  {
    field: `"${QUERY_FIELDS.UPFRONT_COST_OF_RESERVED_INSATNCES}"`,
    label: 'upfrontCostOfReservedInstances',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_COST_OF_RESERVED_INSTANCES}"`,
    label: 'estimatedCostOfReservedInstances',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVED_INSTANCE_PURCHASE}"`,
    label: 'estimatedOnDemandCostPostRecommendedReservedInstancePurchase',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.CUSTOM_RECOM_STATUS}"`,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const EC2_INSTANCES_RI_OPTIMIZATION_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.INSTANCE_TYPE}"`,
    label: 'instanceType',
  },
  {
    field: `"${QUERY_FIELDS.PLATFORM}"`,
    label: 'platform',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_NUMBER_OF_RIS_TO_PURCHASE}"`,
    label: 'recommendedNumberOfRisToPurchase',
  },
  {
    field: `"${QUERY_FIELDS.EXPECTED_AVERAGE_RI_UTILIZATION}"`,
    label: 'expectedAverageRiUtilization',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
    label: 'estimatedSavingsWithRecommendations',
  },
  {
    field: `"${QUERY_FIELDS.UPFRONT_COST_OF_RIS}"`,
    label: 'uprontCostOfRis',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_COST_OF_RIS}"`,
    label: 'estimatedCostOfRis',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RI_PURCHASE}"`,
    label: 'estimatedOnDemandCostPostRecommendedRiPurchase',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_BREAK_EVEN}"`,
    label: 'estimatedBreakEven',
  },
  {
    field: `"${QUERY_FIELDS.LOOKBACK_PERIOD}"`,
    label: 'lookbackPeriod',
  },
  {
    field: `"${QUERY_FIELDS.TERM}"`,
    label: 'term',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.CUSTOM_RECOM_STATUS}"`,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.REGION}"`,
    label: 'region',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_WITH_RECOMMENDATIONS}"`,
    label: 'estimatedSavingsWithRecommendations',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_BREAK_EVEN}"`,
    label: 'estimatedBreakEven',
  },
  {
    field: `"${QUERY_FIELDS.LOOKBACK_PERIOD}"`,
    label: 'lookbackPeriod',
  },
  {
    field: `"${QUERY_FIELDS.TERM}"`,
    label: 'term',
  },
  {
    field: `"${QUERY_FIELDS.FAMILY}"`,
    label: 'family',
  },
  {
    field: `"${QUERY_FIELDS.NODE_TYPE}"`,
    label: 'nodeType',
  },
  {
    field: `"${QUERY_FIELDS.PRODUCT_DESCRIPTION}"`,
    label: 'productDescription',
  },
  {
    field: `"${QUERY_FIELDS.RECOMMENDED_NUMBER_OF_RESERVED_NODES_TO_PURCHASE}"`,
    label: 'recommendedNumberOfReservedNodesToPurchase',
  },
  {
    field: `"${QUERY_FIELDS.EXPECTED_AVERAGE_RESERVED_NODE_UTILIZARION}"`,
    label: 'expectedAverageReservedNodeUtilization',
  },
  {
    field: `"${QUERY_FIELDS.UPFRONT_COST_OF_RESERVED_NODES}"`,
    label: 'upfrontCostOfReservedNodes',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_COST_OF_RESERVED_NODES}"`,
    label: 'estimatedCostOfReservedNodes',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_ON_DEMAND_COST_POST_RECOMMENDED_RESERVED_NODES_PURCHASE}"`,
    label: 'estimatedOnDemandCostPostRecommendedReservedNodesPurchase',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.CUSTOM_RECOM_STATUS}"`,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

const SAVINGS_PLAN_QUERY_COLUMNS = [
  {
    field: `CAST("${QUERY_FIELDS.ACCOUNTID}" AS VARCHAR)`,
    label: 'accountId',
  },
  {
    field: `"${QUERY_FIELDS.ACCOUNT_NAME}"`,
    label: 'accountName',
  },
  {
    field: `"${QUERY_FIELDS.LOOKBACK_PERIOD}"`,
    label: 'lookbackPeriod',
  },
  {
    field: `"${QUERY_FIELDS.TERM}"`,
    label: 'term',
  },
  {
    field: `"${QUERY_FIELDS.SAVINGS_PLAN_TYPE}"`,
    label: 'savingsPlanType',
  },
  {
    field: `"${QUERY_FIELDS.PAYMENT_OPTION}"`,
    label: 'paymentOption',
  },
  {
    field: `"${QUERY_FIELDS.UPFRONT_COST}"`,
    label: 'upfrontCost',
  },
  {
    field: `"${QUERY_FIELDS.HOURLY_COMMITMENT_TO_PURCHASE}"`,
    label: 'hourlyCommitmentToPurchase',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_AVERAGE_UTILIZATION}"`,
    label: 'estimatedAverageUtilization',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_MONTHLY_SAVINGS}"`,
    label: 'estimatedMonthlySavings',
  },
  {
    field: `"${QUERY_FIELDS.ESTIMATED_SAVINGS_PERCENTAGE}"`,
    label: 'estimatedSavingsPercentage',
  },
  {
    field: `"${QUERY_FIELDS.DATETIME}"`,
    label: 'lastRefreshedAt',
  },
  {
    field: `"${QUERY_FIELDS.CUSTOM_RECOM_STATUS}"`,
    label: 'recStatus',
  },
  {
    field: QUERY_FIELDS.STATUS_PRIORITY,
    label: 'priority',
  },
];

export const AWS_CHECK_ID_TO_RECOMMENDATION_DETAILS_QUERY_COLUMNS = {
  // Waste Management
  [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES]:
    UNDERUTILIZED_EBS_VOLUMES_QUERY_COLUMNS,
  [AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS]:
    UNDERUTILIZED_REDSHIFT_CLUSTERS_QUERY_COLUMNS,
  [AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES]:
    UNDERUTILIZED_EC2_INSTANCES_QUERY_COLUMNS,
  [AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE]: IDLE_RDS_DB_INSTANCE_QUERY_COLUMNS,
  [AWS_CHECK_IDS.IDLE_IP_ADDRESS]: IDLE_IP_ADDRESS_QUERY_COLUMNS,
  [AWS_CHECK_IDS.IDLE_LOAD_BALANCER]: IDLE_LOAD_BALANCER_QUERY_COLUMNS,

  // Consumption Management
  [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES]:
    OVERPROVISIONED_EBS_VOLUMES_QUERY_COLUMNS,
  [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY]:
    LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY_QUERY_COLUMNS,
  [AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER]:
    EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER_QUERY_COLUMNS,
  [AWS_CHECK_IDS.RIGHT_SIZING_EC2]: RIGHT_SIZING_EC2_QUERY_COLUMNS,

  // // Purchase Tactics
  [AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE]: EC2_INSTANCES_RI_LEASE_QUERY_COLUMNS,
  [AWS_CHECK_IDS.RDS_RI_OPTIMIZATION]: RDS_RI_OPTIMIZATION_QUERY_COLUMNS,
  [AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION]:
    REDSHIFT_RESERVED_NODE_OPTIMIZATION_QUERY_COLUMNS,
  [AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION]:
    OPEN_SEARCH_RI_OPTIMIZATION_QUERY_COLUMNS,
  [AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION]:
    EC2_INSTANCES_RI_OPTIMIZATION_QUERY_COLUMNS,
  [AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION]:
    ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION_QUERY_COLUMNS,
  [AWS_CHECK_IDS.SAVINGS_PLAN]: SAVINGS_PLAN_QUERY_COLUMNS,
};

export const GCP_RECOMMENDATIONS_DETAILS_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('projectName'),
    dataIndex: 'projectNumber',
    key: 'projectNumber',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 70,
  },
  {
    title: i18n.t('recommendation'),
    dataIndex: 'recommendation',
    key: 'recommendation',
    width: 200,
  },
  {
    title: i18n.t('lastRefreshed'),
    dataIndex: 'lastRefreshed',
    key: 'lastRefreshed',
    width: 150,
  },
  {
    title: i18n.t('recommendationId'),
    dataIndex: 'recommendationId',
    key: 'recommendationId',
    width: 150,
  },
  {
    title: i18n.t('type'),
    dataIndex: 'recommender_subtype',
    key: 'recommender_subtype',
    width: 150,
  },
  {
    title: i18n.t('status'),
    dataIndex: 'recommendationState',
    key: 'recommendationState',
    width: 150,
  },
  {
    title: i18n.t('resource'),
    dataIndex: 'targetResource',
    key: 'targetResource',
    width: 150,
  },
  {
    title: i18n.t('category'),
    dataIndex: 'recommendationCategory',
    key: 'recommendationCategory',
    width: 150,
  },
  {
    title: i18n.t('costSavings'),
    dataIndex: 'costSavings',
    key: 'costSavings',
    render: (text: number) => formatNumberToCompact(text),
    width: 150,
  },
];

const UNDERUTILIZED_EBS_VOLUMES_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('volumeId'),
    dataIndex: 'volumeId',
    key: 'volumeId',
    width: 150,
  },
  {
    title: i18n.t('volumeName'),
    dataIndex: 'volumeName',
    key: 'volumeName',
    width: 150,
  },
  {
    title: i18n.t('volumeType'),
    dataIndex: 'volumeType',
    key: 'volumeType',
    width: 150,
  },
  {
    title: i18n.t('volumeSize'),
    dataIndex: 'volumeSize',
    key: 'volumeSize',
    width: 150,
  },
  {
    title: i18n.t('snapshotId'),
    dataIndex: 'snapshotId',
    key: 'snapshotId',
    width: 150,
  },
  {
    title: i18n.t('snapshotName'),
    dataIndex: 'snapshotName',
    key: 'snapshotName',
    width: 150,
  },
  {
    title: i18n.t('snapshotAge'),
    dataIndex: 'snapshotAge',
    key: 'snapshotAge',
    width: 150,
  },
  {
    title: i18n.t('monthlyStorageCost'),
    dataIndex: 'monthlyStorageCost',
    key: 'monthlyStorageCost',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const UNDERUTILIZED_REDSHIFT_CLUSTERS_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('status'),
    dataIndex: 'status',
    key: 'status',
    width: 200,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('cluster'),
    dataIndex: 'cluster',
    key: 'cluster',
    width: 150,
  },
  {
    title: i18n.t('reason'),
    dataIndex: 'reason',
    key: 'reason',
    width: 150,
  },
  {
    title: i18n.t('instanceType'),
    dataIndex: 'instanceType',
    key: 'instanceType',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavings'),
    dataIndex: 'estimatedMonthlySavings',
    key: 'estimatedMonthlySavings',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const UNDERUTILIZED_EC2_INSTANCES_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('instanceId'),
    dataIndex: 'instanceId',
    key: 'instanceId',
    width: 150,
  },
  {
    title: i18n.t('instanceName'),
    dataIndex: 'instanceName',
    key: 'instanceName',
    width: 150,
  },
  {
    title: i18n.t('instanceType'),
    dataIndex: 'instanceType',
    key: 'instanceType',
    width: 150,
  },
  {
    title: i18n.t('cpuUtilizationFourteenDayAverage'),
    dataIndex: 'cpuUtilizationFourteenDayAverage',
    key: 'cpuUtilizationFourteenDayAverage',
    width: 150,
  },
  {
    title: i18n.t('networkIoFourteenDayAverage'),
    dataIndex: 'networkIoFourteenDayAverage',
    key: 'networkIoFourteenDayAverage',
    width: 150,
  },
  {
    title: i18n.t('numberOfDaysLowUtilization'),
    dataIndex: 'numberOfDaysLowUtilization',
    key: 'numberOfDaysLowUtilization',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavings'),
    dataIndex: 'estimatedMonthlySavings',
    key: 'estimatedMonthlySavings',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const IDLE_RDS_DB_INSTANCE_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('instanceType'),
    dataIndex: 'instanceType',
    key: 'instanceType',
    width: 150,
  },
  {
    title: i18n.t('dbInstanceName'),
    dataIndex: 'dbInstanceName',
    key: 'dbInstanceName',
    width: 150,
  },
  {
    title: i18n.t('multiAz'),
    dataIndex: 'multiAz',
    key: 'multiAz',
    width: 150,
  },
  {
    title: i18n.t('storageProvisioned'),
    dataIndex: 'storageProvisioned',
    key: 'storageProvisioned',
    width: 150,
  },
  {
    title: i18n.t('daysSinceLastConnection'),
    dataIndex: 'daysSinceLastConnection',
    key: 'daysSinceLastConnection',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavings'),
    dataIndex: 'estimatedMonthlySavings',
    key: 'estimatedMonthlySavings',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const IDLE_IP_ADDRESS_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('ipAddress'),
    dataIndex: 'ipAddress',
    key: 'ipAddress',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('costSavings'),
    dataIndex: 'costSavings',
    key: 'costSavings',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const IDLE_LOAD_BALANCER_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('loadBalancerName'),
    dataIndex: 'loadBalancerName',
    key: 'loadBalancerName',
    width: 150,
  },
  {
    title: i18n.t('reason'),
    dataIndex: 'reason',
    key: 'reason',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavings'),
    dataIndex: 'estimatedMonthlySavings',
    key: 'estimatedMonthlySavings',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const OVERPROVISIONED_EBS_VOLUMES_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('volumeId'),
    dataIndex: 'volumeId',
    key: 'volumeId',
    width: 150,
  },
  {
    title: i18n.t('volumeType'),
    dataIndex: 'volumeType',
    key: 'volumeType',
    width: 150,
  },
  {
    title: i18n.t('volumeSizeGb'),
    dataIndex: 'volumeSizeGb',
    key: 'volumeSizeGb',
    width: 150,
  },
  {
    title: i18n.t('volumeBaselineIops'),
    dataIndex: 'volumeBaselineIops',
    key: 'volumeBaselineIops',
    width: 150,
  },
  {
    title: i18n.t('volumeBurstIops'),
    dataIndex: 'volumeBurstIops',
    key: 'volumeBurstIops',
    width: 150,
  },
  {
    title: i18n.t('volumeBurstThroughput'),
    dataIndex: 'volumeBurstThroughput',
    key: 'volumeBurstThroughput',
    width: 150,
  },
  {
    title: i18n.t('recommendedVolumeType'),
    dataIndex: 'recommendedVolumeType',
    key: 'recommendedVolumeType',
    width: 150,
  },
  {
    title: i18n.t('recommendedVolumeSizeGb'),
    dataIndex: 'recommendedVolumeSizeGb',
    key: 'recommendedVolumeSizeGb',
    width: 150,
  },
  {
    title: i18n.t('recommendedVolumeBaselineIops'),
    dataIndex: 'recommendedVolumeBaselineIops',
    key: 'recommendedVolumeBaselineIops',
    width: 150,
  },
  {
    title: i18n.t('recommendedVolumeBurstIops'),
    dataIndex: 'recommendedVolumeBurstIops',
    key: 'recommendedVolumeBurstIops',
    width: 150,
  },
  {
    title: i18n.t('recommendedVolumeBaselineThroughput'),
    dataIndex: 'recommendedVolumeBaselineThroughput',
    key: 'recommendedVolumeBaselineThroughput',
    width: 150,
  },
  {
    title: i18n.t('recommendedVolumeBurstThroughput'),
    dataIndex: 'recommendedVolumeBurstThroughput',
    key: 'recommendedVolumeBurstThroughput',
    width: 150,
  },
  {
    title: i18n.t('lookbackPeriodDays'),
    dataIndex: 'lookbackPeriodDays',
    key: 'lookbackPeriodDays',
    width: 150,
  },
  {
    title: i18n.t('savingsOpportunityPercentage'),
    dataIndex: 'savingsOpportunityPercentage',
    key: 'savingsOpportunityPercentage',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavings'),
    dataIndex: 'estimatedMonthlySavings',
    key: 'estimatedMonthlySavings',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavingsCurrency'),
    dataIndex: 'estimatedMonthlySavingsCurrency',
    key: 'estimatedMonthlySavingsCurrency',
    width: 150,
  },
  {
    title: i18n.t('lastUpdatedTime'),
    dataIndex: 'lastUpdatedTime',
    key: 'lastUpdatedTime',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('status'),
    dataIndex: 'status',
    key: 'status',
    width: 200,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('functionName'),
    dataIndex: 'functionName',
    key: 'functionName',
    width: 150,
  },
  {
    title: i18n.t('functionVersion'),
    dataIndex: 'functionVersion',
    key: 'functionVersion',
    width: 150,
  },
  {
    title: i18n.t('memorySize'),
    dataIndex: 'memorySize',
    key: 'memorySize',
    width: 150,
  },
  {
    title: i18n.t('recommendedMemorySize'),
    dataIndex: 'recommendedMemorySize',
    key: 'recommendedMemorySize',
    width: 150,
  },
  {
    title: i18n.t('lookbackPeriod'),
    dataIndex: 'lookbackPeriod',
    key: 'lookbackPeriod',
    width: 150,
  },
  {
    title: i18n.t('savingsOpportunity'),
    dataIndex: 'savingsOpportunity',
    key: 'savingsOpportunity',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavings'),
    dataIndex: 'estimatedMonthlySavings',
    key: 'estimatedMonthlySavings',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavingsCurrency'),
    dataIndex: 'estimatedMonthlySavingsCurrency',
    key: 'estimatedMonthlySavingsCurrency',
    width: 150,
  },
  {
    title: i18n.t('lastUpdatedTime'),
    dataIndex: 'lastUpdatedTime',
    key: 'lastUpdatedTime',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('instanceId'),
    dataIndex: 'instanceId',
    key: 'instanceId',
    width: 150,
  },
  {
    title: i18n.t('instanceType'),
    dataIndex: 'instanceType',
    key: 'instanceType',
    width: 150,
  },
  {
    title: i18n.t('vCpu'),
    dataIndex: 'vCpu',
    key: 'vCpu',
    width: 150,
  },
  {
    title: i18n.t('sqlServerEdition'),
    dataIndex: 'sqlServerEdition',
    key: 'sqlServerEdition',
    width: 150,
  },
  {
    title: i18n.t('maximumVCpu'),
    dataIndex: 'maximumVCpu',
    key: 'maximumVCpu',
    width: 150,
  },
  {
    title: i18n.t('recommendedInstanceType'),
    dataIndex: 'recommendedInstanceType',
    key: 'recommendedInstanceType',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavings'),
    dataIndex: 'estimatedMonthlySavings',
    key: 'estimatedMonthlySavings',
    width: 150,
  },
  {
    title: i18n.t('lastUpdatedTime'),
    dataIndex: 'lastUpdatedTime',
    key: 'lastUpdatedTime',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const RIGHT_SIZING_EC2_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('instanceId'),
    dataIndex: 'instanceId',
    key: 'instanceId',
    width: 150,
  },
  {
    title: i18n.t('instanceName'),
    dataIndex: 'instanceName',
    key: 'instanceName',
    width: 200,
  },
  {
    title: i18n.t('instanceType'),
    dataIndex: 'instanceType',
    key: 'instanceType',
    width: 150,
  },
  {
    title: i18n.t('recommendedInstanceType'),
    dataIndex: 'recommendedInstanceType',
    key: 'recommendedInstanceType',
    width: 150,
  },
  {
    title: i18n.t('generationTime'),
    dataIndex: 'generationTime',
    key: 'generationTime',
    width: 150,
  },
  {
    title: i18n.t('cpuUtilization'),
    dataIndex: 'cpuUtilization',
    key: 'cpuUtilization',
    width: 150,
  },
  {
    title: i18n.t('estimatedSavings'),
    dataIndex: 'estimatedSavings',
    key: 'estimatedSavings',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const EC2_INSTANCES_RI_LEASE_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('reason'),
    dataIndex: 'reason',
    key: 'reason',
    width: 200,
  },
  {
    title: i18n.t('instanceType'),
    dataIndex: 'instanceType',
    key: 'instanceType',
    width: 150,
  },
  {
    title: i18n.t('zone'),
    dataIndex: 'zone',
    key: 'zone',
    width: 150,
  },
  {
    title: i18n.t('platform'),
    dataIndex: 'platform',
    key: 'platform',
    width: 150,
  },
  {
    title: i18n.t('instanceCount'),
    dataIndex: 'instanceCount',
    key: 'instanceCount',
    width: 150,
  },
  {
    title: i18n.t('currentMonthlyCost'),
    dataIndex: 'currentMonthlyCost',
    key: 'currentMonthlyCost',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavings'),
    dataIndex: 'estimatedMonthlySavings',
    key: 'estimatedMonthlySavings',
    width: 150,
  },
  {
    title: i18n.t('expirationDate'),
    dataIndex: 'expirationDate',
    key: 'expirationDate',
    width: 150,
  },
  {
    title: i18n.t('reservedInstanceId'),
    dataIndex: 'reservedInstanceId',
    key: 'reservedInstanceId',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const RDS_RI_OPTIMIZATION_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('instanceType'),
    dataIndex: 'instanceType',
    key: 'instanceType',
    width: 150,
  },
  {
    title: i18n.t('estimatedSavingsWithRecommendations'),
    dataIndex: 'estimatedSavingsWithRecommendations',
    key: 'estimatedSavingsWithRecommendations',
    width: 150,
  },
  {
    title: i18n.t('estimatedBreakEven'),
    dataIndex: 'estimatedBreakEven',
    key: 'estimatedBreakEven',
    width: 150,
  },
  {
    title: i18n.t('lookbackPeriod'),
    dataIndex: 'lookbackPeriod',
    key: 'lookbackPeriod',
    width: 150,
  },
  {
    title: i18n.t('term'),
    dataIndex: 'term',
    key: 'term',
    width: 150,
  },
  {
    title: i18n.t('family'),
    dataIndex: 'family',
    key: 'family',
    width: 150,
  },
  {
    title: i18n.t('recommendedNumberOfReservedInstancesToPurchase'),
    dataIndex: 'recommendedNumberOfReservedInstancesToPurchase',
    key: 'recommendedNumberOfReservedInstancesToPurchase',
    width: 150,
  },
  {
    title: i18n.t('expectedAverageReservedInstancesToPurchase'),
    dataIndex: 'expectedAverageReservedInstancesToPurchase',
    key: 'expectedAverageReservedInstancesToPurchase',
    width: 150,
  },
  {
    title: i18n.t('upfrontCostOfReservedInstances'),
    dataIndex: 'upfrontCostOfReservedInstances',
    key: 'upfrontCostOfReservedInstances',
    width: 150,
  },
  {
    title: i18n.t('estimatedCostOfReservedInstances'),
    dataIndex: 'estimatedCostOfReservedInstances',
    key: 'estimatedCostOfReservedInstances',
    width: 150,
  },
  {
    title: i18n.t('licenceModel'),
    dataIndex: 'licenceModel',
    key: 'licenceModel',
    width: 150,
  },
  {
    title: i18n.t('databaseEdition'),
    dataIndex: 'databaseEdition',
    key: 'databaseEdition',
    width: 150,
  },
  {
    title: i18n.t('databaseEngine'),
    dataIndex: 'databaseEngine',
    key: 'databaseEngine',
    width: 150,
  },
  {
    title: i18n.t('deploymentOption'),
    dataIndex: 'deploymentOption',
    key: 'deploymentOption',
    width: 150,
  },
  {
    title: i18n.t(
      'estimatedOnDemandCostPostRecommendedReserveInstancePurchaseMonthly'
    ),
    dataIndex:
      'estimatedOnDemandCostPostRecommendedReserveInstancePurchaseMonthly',
    key: 'estimatedOnDemandCostPostRecommendedReserveInstancePurchaseMonthly',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const REDSHIFT_RESERVED_NODE_OPTIMIZATION_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('estimatedSavingsWithRecommendations'),
    dataIndex: 'estimatedSavingsWithRecommendations',
    key: 'estimatedSavingsWithRecommendations',
    width: 150,
  },
  {
    title: i18n.t('estimatedBreakEven'),
    dataIndex: 'estimatedBreakEven',
    key: 'estimatedBreakEven',
    width: 150,
  },
  {
    title: i18n.t('lookbackPeriod'),
    dataIndex: 'lookbackPeriod',
    key: 'lookbackPeriod',
    width: 150,
  },
  {
    title: i18n.t('term'),
    dataIndex: 'term',
    key: 'term',
    width: 150,
  },
  {
    title: i18n.t('family'),
    dataIndex: 'family',
    key: 'family',
    width: 150,
  },
  {
    title: i18n.t('nodeType'),
    dataIndex: 'nodeType',
    key: 'nodeType',
    width: 150,
  },
  {
    title: i18n.t('recommendedNumberOfReservedNodesToPurchase'),
    dataIndex: 'recommendedNumberOfReservedNodesToPurchase',
    key: 'recommendedNumberOfReservedNodesToPurchase',
    width: 150,
  },
  {
    title: i18n.t('expectedAverageReservedNodeUtilization'),
    dataIndex: 'expectedAverageReservedNodeUtilization',
    key: 'expectedAverageReservedNodeUtilization',
    width: 150,
  },
  {
    title: i18n.t('upfrontCostOfReservedNodes'),
    dataIndex: 'upfrontCostOfReservedNodes',
    key: 'upfrontCostOfReservedNodes',
    width: 150,
  },
  {
    title: i18n.t('estimatedCostOfReservedNodes'),
    dataIndex: 'estimatedCostOfReservedNodes',
    key: 'estimatedCostOfReservedNodes',
    width: 150,
  },
  {
    title: i18n.t('estimatedOnDemandCostPostRecommendedReservedNodesPurchase'),
    dataIndex: 'estimatedOnDemandCostPostRecommendedReservedNodesPurchase',
    key: 'estimatedOnDemandCostPostRecommendedReservedNodesPurchase',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const OPEN_SEARCH_RI_OPTIMIZATION_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('estimatedSavingsWithRecommendations'),
    dataIndex: 'estimatedSavingsWithRecommendations',
    key: 'estimatedSavingsWithRecommendations',
    width: 150,
  },
  {
    title: i18n.t('estimatedBreakEven'),
    dataIndex: 'estimatedBreakEven',
    key: 'estimatedBreakEven',
    width: 150,
  },
  {
    title: i18n.t('lookbackPeriod'),
    dataIndex: 'lookbackPeriod',
    key: 'lookbackPeriod',
    width: 150,
  },
  {
    title: i18n.t('term'),
    dataIndex: 'term',
    key: 'term',
    width: 150,
  },
  {
    title: i18n.t('instanceClass'),
    dataIndex: 'instanceClass',
    key: 'instanceClass',
    width: 150,
  },
  {
    title: i18n.t('instanceSize'),
    dataIndex: 'instanceSize',
    key: 'instanceSize',
    width: 150,
  },
  {
    title: i18n.t('recommendedNumberOfReservedInstancesToPurchase'),
    dataIndex: 'recommendedNumberOfReservedInstancesToPurchase',
    key: 'recommendedNumberOfReservedInstancesToPurchase',
    width: 150,
  },
  {
    title: i18n.t('expectedAverageReservedInstancesToPurchase'),
    dataIndex: 'expectedAverageReservedInstancesToPurchase',
    key: 'expectedAverageReservedInstancesToPurchase',
    width: 150,
  },
  {
    title: i18n.t('upfrontCostOfReservedInstances'),
    dataIndex: 'upfrontCostOfReservedInstances',
    key: 'upfrontCostOfReservedInstances',
    width: 150,
  },
  {
    title: i18n.t('estimatedCostOfReservedInstances'),
    dataIndex: 'estimatedCostOfReservedInstances',
    key: 'estimatedCostOfReservedInstances',
    width: 150,
  },
  {
    title: i18n.t(
      'estimatedOnDemandCostPostRecommendedReservedInstancePurchase'
    ),
    dataIndex: 'estimatedOnDemandCostPostRecommendedReservedInstancePurchase',
    key: 'estimatedOnDemandCostPostRecommendedReservedInstancePurchase',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const EC2_INSTANCES_RI_OPTIMIZATION_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('instanceType'),
    dataIndex: 'instanceType',
    key: 'instanceType',
    width: 150,
  },
  {
    title: i18n.t('platform'),
    dataIndex: 'platform',
    key: 'platform',
    width: 150,
  },
  {
    title: i18n.t('recommendedNumberOfRisToPurchase'),
    dataIndex: 'recommendedNumberOfRisToPurchase',
    key: 'recommendedNumberOfRisToPurchase',
    width: 150,
  },
  {
    title: i18n.t('expectedAverageRiUtilization'),
    dataIndex: 'expectedAverageRiUtilization',
    key: 'expectedAverageRiUtilization',
    width: 150,
  },
  {
    title: i18n.t('estimatedSavingsWithRecommendations'),
    dataIndex: 'estimatedSavingsWithRecommendations',
    key: 'estimatedSavingsWithRecommendations',
    width: 150,
  },
  {
    title: i18n.t('uprontCostOfRis'),
    dataIndex: 'uprontCostOfRis',
    key: 'uprontCostOfRis',
    width: 150,
  },
  {
    title: i18n.t('estimatedCostOfRis'),
    dataIndex: 'estimatedCostOfRis',
    key: 'estimatedCostOfRis',
    width: 150,
  },
  {
    title: i18n.t('estimatedOnDemandCostPostRecommendedRiPurchase'),
    dataIndex: 'estimatedOnDemandCostPostRecommendedRiPurchase',
    key: 'estimatedOnDemandCostPostRecommendedRiPurchase',
    width: 150,
  },
  {
    title: i18n.t('estimatedBreakEven'),
    dataIndex: 'estimatedBreakEven',
    key: 'estimatedBreakEven',
    width: 150,
  },
  {
    title: i18n.t('lookbackPeriod'),
    dataIndex: 'lookbackPeriod',
    key: 'lookbackPeriod',
    width: 150,
  },
  {
    title: i18n.t('term'),
    dataIndex: 'term',
    key: 'term',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('region'),
    dataIndex: 'region',
    key: 'region',
    width: 200,
  },
  {
    title: i18n.t('estimatedSavingsWithRecommendations'),
    dataIndex: 'estimatedSavingsWithRecommendations',
    key: 'estimatedSavingsWithRecommendations',
    width: 150,
  },
  {
    title: i18n.t('estimatedBreakEven'),
    dataIndex: 'estimatedBreakEven',
    key: 'estimatedBreakEven',
    width: 150,
  },
  {
    title: i18n.t('lookbackPeriod'),
    dataIndex: 'lookbackPeriod',
    key: 'lookbackPeriod',
    width: 150,
  },
  {
    title: i18n.t('term'),
    dataIndex: 'term',
    key: 'term',
    width: 150,
  },
  {
    title: i18n.t('family'),
    dataIndex: 'family',
    key: 'family',
    width: 150,
  },
  {
    title: i18n.t('nodeType'),
    dataIndex: 'nodeType',
    key: 'nodeType',
    width: 150,
  },
  {
    title: i18n.t('productDescription'),
    dataIndex: 'productDescription',
    key: 'productDescription',
    width: 150,
  },
  {
    title: i18n.t('recommendedNumberOfReservedNodesToPurchase'),
    dataIndex: 'recommendedNumberOfReservedNodesToPurchase',
    key: 'recommendedNumberOfReservedNodesToPurchase',
    width: 150,
  },
  {
    title: i18n.t('expectedAverageReservedNodeUtilization'),
    dataIndex: 'expectedAverageReservedNodeUtilization',
    key: 'expectedAverageReservedNodeUtilization',
    width: 150,
  },
  {
    title: i18n.t('upfrontCostOfReservedNodes'),
    dataIndex: 'upfrontCostOfReservedNodes',
    key: 'upfrontCostOfReservedNodes',
    width: 150,
  },
  {
    title: i18n.t('estimatedCostOfReservedNodes'),
    dataIndex: 'estimatedCostOfReservedNodes',
    key: 'estimatedCostOfReservedNodes',
    width: 150,
  },
  {
    title: i18n.t('estimatedOnDemandCostPostRecommendedReservedNodesPurchase'),
    dataIndex: 'estimatedOnDemandCostPostRecommendedReservedNodesPurchase',
    key: 'estimatedOnDemandCostPostRecommendedReservedNodesPurchase',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

const SAVINGS_PLAN_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountId'),
    dataIndex: 'accountId',
    key: 'accountId',
    width: 150,
  },
  {
    title: i18n.t('accountName'),
    dataIndex: 'accountName',
    key: 'accountName',
    width: 150,
  },
  {
    title: i18n.t('lookbackPeriod'),
    dataIndex: 'lookbackPeriod',
    key: 'lookbackPeriod',
    width: 200,
  },
  {
    title: i18n.t('term'),
    dataIndex: 'term',
    key: 'term',
    width: 150,
  },
  {
    title: i18n.t('savingsPlanType'),
    dataIndex: 'savingsPlanType',
    key: 'savingsPlanType',
    width: 150,
  },
  {
    title: i18n.t('paymentOption'),
    dataIndex: 'paymentOption',
    key: 'paymentOption',
    width: 150,
  },
  {
    title: i18n.t('upfrontCost'),
    dataIndex: 'upfrontCost',
    key: 'upfrontCost',
    width: 150,
  },
  {
    title: i18n.t('hourlyCommitmentToPurchase'),
    dataIndex: 'hourlyCommitmentToPurchase',
    key: 'hourlyCommitmentToPurchase',
    width: 150,
  },
  {
    title: i18n.t('estimatedAverageUtilization'),
    dataIndex: 'estimatedAverageUtilization',
    key: 'nodeTestimatedAverageUtilizationype',
    width: 150,
  },
  {
    title: i18n.t('estimatedMonthlySavings'),
    dataIndex: 'estimatedMonthlySavings',
    key: 'estimatedMonthlySavings',
    width: 150,
  },
  {
    title: i18n.t('estimatedSavingsPercentage'),
    dataIndex: 'estimatedSavingsPercentage',
    key: 'estimatedSavingsPercentage',
    width: 150,
  },
  {
    title: i18n.t('lastRefreshedAt'),
    dataIndex: 'lastRefreshedAt',
    key: 'lastRefreshedAt',
    width: 150,
  },
  {
    title: i18n.t('recStatus'),
    dataIndex: 'recStatus',
    key: 'recStatus',
    width: 150,
  },
  {
    title: i18n.t('priority'),
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
];

export const AWS_RECOMMENDATIONS_DETAILS_TABLE_COLUMNS = {
  // Waste Management
  [AWS_CHECK_IDS.UNDERUTILIZED_EBS_VOLUMES]:
    UNDERUTILIZED_EBS_VOLUMES_TABLE_COLUMNS,
  [AWS_CHECK_IDS.UNDERUTILIZED_REDSHIFT_CLUSTERS]:
    UNDERUTILIZED_REDSHIFT_CLUSTERS_TABLE_COLUMNS,
  [AWS_CHECK_IDS.UNDERUTILIZED_EC2_INSTANCES]:
    UNDERUTILIZED_EC2_INSTANCES_TABLE_COLUMNS,
  [AWS_CHECK_IDS.IDLE_RDS_DB_INSTANCE]: IDLE_RDS_DB_INSTANCE_TABLE_COLUMNS,
  [AWS_CHECK_IDS.IDLE_IP_ADDRESS]: IDLE_IP_ADDRESS_TABLE_COLUMNS,
  [AWS_CHECK_IDS.IDLE_LOAD_BALANCER]: IDLE_LOAD_BALANCER_TABLE_COLUMNS,

  // Consumption Management
  [AWS_CHECK_IDS.OVERPROVISIONED_EBS_VOLUMES]:
    OVERPROVISIONED_EBS_VOLUMES_TABLE_COLUMNS,
  [AWS_CHECK_IDS.LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY]:
    LAMBDA_FUNCTIONS_OVERPROVISIONED_MEMORY_TABLE_COLUMNS,
  [AWS_CHECK_IDS.EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER]:
    EC2_INSTANCES_OVERPROVISIONED_MSSQL_SERVER_TABLE_COLUMNS,
  [AWS_CHECK_IDS.RIGHT_SIZING_EC2]: RIGHT_SIZING_EC2_TABLE_COLUMNS,

  // // Purchase Tactics
  [AWS_CHECK_IDS.EC2_INSTANCES_RI_LEASE]: EC2_INSTANCES_RI_LEASE_TABLE_COLUMNS,
  [AWS_CHECK_IDS.RDS_RI_OPTIMIZATION]: RDS_RI_OPTIMIZATION_TABLE_COLUMNS,
  [AWS_CHECK_IDS.REDSHIFT_RESERVED_NODE_OPTIMIZATION]:
    REDSHIFT_RESERVED_NODE_OPTIMIZATION_TABLE_COLUMNS,
  [AWS_CHECK_IDS.OPEN_SEARCH_RI_OPTIMIZATION]:
    OPEN_SEARCH_RI_OPTIMIZATION_TABLE_COLUMNS,
  [AWS_CHECK_IDS.EC2_INSTANCES_RI_OPTIMIZATION]:
    EC2_INSTANCES_RI_OPTIMIZATION_TABLE_COLUMNS,
  [AWS_CHECK_IDS.ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION]:
    ELASTIC_CACHE_RESERVED_NODE_OPTIMIZATION_TABLE_COLUMNS,
  [AWS_CHECK_IDS.SAVINGS_PLAN]: SAVINGS_PLAN_TABLE_COLUMNS,
};
