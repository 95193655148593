import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';

import { validateStringLengthLessThan } from 'utils/validations';
import {
  FREE_TEXT_CHARACTER_LIMIT,
  MAX_CHARACTER_LIMIT,
} from 'constants/validation';
import {
  cloudConnection,
  setCurrentConnection,
  setCurrentStepValidation,
  setResetStepForm,
} from 'redux/cloudConnectionSlice';
import {
  deleteConnectionFile,
  fetchConnectionById,
  fetchFileMetaData,
} from 'utils/services';
import { VALIDATION_STATUS } from 'pages/ConnectingCSPPage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import BasicDetails from '../BasicDetails';
import OCIAccessDetails from './components/OCIAccessDetails';
import { FileInfoType } from './types';
import OCIBillingDetails from './components/OCIBillingDetails';
import OCIBudgetAccessDetails from './components/OCIBudgetAccessDetails';

const OCIConnectionForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    currentConnection,
    isEditConnection,
    isTryAgain,
    currentStep,
    resetStepForm,
  } = useSelector(cloudConnection);

  const [isDuplicateConnectionName, setIsDuplicateConnectionName] =
    useState(true);
  const [connectionName, setConnectionName] = useState('');
  const [walletFileInfo, setWalletFileInfo] = useState<FileInfoType>({
    fileName: '',
    fileId: '',
  });

  const [privateKeyFileInfo, setPrivateKeyFileInfo] = useState<FileInfoType>({
    fileName: '',
    fileId: '',
  });

  const [
    fetchConnectionDataRequestStatus,
    setFetchConnectionDataRequestStatus,
  ] = useState('');

  useEffect(() => {
    if (isEditConnection) {
      setIsDuplicateConnectionName(false);
      setProviderInitialValues();
      return;
    }

    if (isTryAgain) {
      setIsDuplicateConnectionName(false);
      fetchFilename();
      return;
    }

    dispatch(
      setCurrentConnection({
        ...currentConnection,
        migrated: false,
        wantRecommendations: false,
        wantCarbonFootprint: false,
        hasPassPhrase: false,
      })
    );
  }, []);

  useEffect(() => {
    if (resetStepForm) {
      switch (currentStep) {
        case 1:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              dataSourceType: '',
              name: '',
              description: '',
            })
          );
          break;
        case 2:
          if (!isEditConnection) {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                jsonFileId: '',
                ociAdwUsername: '',
                ociAdwPassword: '',
              })
            );
            removeFile(walletFileInfo.fileId, setWalletFileInfo);
            break;
          }
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              ociAdwUsername: '',
              ociAdwPassword: '',
            })
          );
          break;
        case 3:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantBilling: false,
              billingDataset: '',
              billingTableName: '',
            })
          );
          break;
        case 4:
          if (!isEditConnection) {
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                ociBudgetFileId: '',
                hasPassPhrase: false,
                ociPassPhrase: '',
                ociTenancy: '',
                ociUserId: '',
                ociRegion: '',
                fingerprint: '',
              })
            );
            removeFile(privateKeyFileInfo.fileId, setPrivateKeyFileInfo);
            break;
          }
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              hasPassPhrase: false,
              ociPassPhrase: '',
              ociTenancy: '',
              ociUserId: '',
              ociRegion: '',
              fingerprint: '',
            })
          );
          break;
      }
      dispatch(setResetStepForm(false));
    }
  }, [resetStepForm]);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        dispatch(setCurrentStepValidation(validateBasicDetailsFields()));
        break;
      case 2:
        dispatch(setCurrentStepValidation(validateAccessDetailsFields()));
        break;
      case 3:
        dispatch(setCurrentStepValidation(validateBillingDetailsFields()));
        break;
      case 4:
        dispatch(setCurrentStepValidation(validateBudgetAccessDetailsFields()));
        break;
      default:
        dispatch(setCurrentStepValidation(VALIDATION_STATUS.INVALID));
        break;
    }
  }, [
    currentStep,
    currentConnection,
    isDuplicateConnectionName,
    walletFileInfo,
  ]);

  /**
   * @function setProviderInitialValues
   * @description Function to set initial values from the edit page
   */
  const setProviderInitialValues = () => {
    setFetchConnectionDataRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchConnectionById(currentConnection?.connectorId)
      .then((res: any) => {
        const connectionDetails = res?.data?.responseData;
        dispatch(
          setCurrentConnection({
            ...connectionDetails,
            hasPassPhrase: !!connectionDetails?.ociPassPhrase,
          })
        );
        setConnectionName(connectionDetails?.displayName);
        setFetchConnectionDataRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((error: any) => {
        onApiCallError(error, false, setFetchConnectionDataRequestStatus);
      });
  };

  /**
   * @function fetchFilename
   * @description Function to fetch the filename from the server
   */
  const fetchFilename = () => {
    fetchFileMetaData(currentConnection?.jsonFileId).then((res: any) => {
      setWalletFileInfo({
        ...walletFileInfo,
        fileName: res?.data?.responseData?.fileName ?? '',
        fileId: currentConnection.jsonFileId,
      });
    });
  };

  /**
   * @function validateBasicDetailsFields
   * @description Function to validate basic details fields
   * @return boolean true if validation is success else false
   */
  const validateBasicDetailsFields = () => {
    if (
      currentConnection?.displayName &&
      !validateStringLengthLessThan(
        currentConnection?.displayName,
        MAX_CHARACTER_LIMIT
      ) &&
      ((isEditConnection &&
        currentConnection?.displayName === connectionName) ||
        !isDuplicateConnectionName) &&
      currentConnection?.dataSourceType &&
      !validateStringLengthLessThan(
        currentConnection?.description,
        FREE_TEXT_CHARACTER_LIMIT
      ) &&
      (!currentConnection?.migrated || currentConnection?.migrationProvider)
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateAccessDetailsFields
   * @description Function to validate access details fields
   * @return boolean true if validation is success else false
   */
  const validateAccessDetailsFields = () => {
    if (
      isEditConnection ||
      (walletFileInfo.fileId &&
        currentConnection?.ociAdwUsername &&
        currentConnection?.ociAdwPassword)
    ) {
      return VALIDATION_STATUS.VALID;
    }

    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateBillingDetailsFields
   * @description Function to validate the billing details fields
   * @return boolean true if validation is success else false
   */
  const validateBillingDetailsFields = () => {
    if (
      !currentConnection?.billingDataset ||
      !currentConnection?.billingTableName
    )
      return VALIDATION_STATUS.SKIP;
    else if (
      currentConnection?.billingDataset &&
      currentConnection?.billingTableName
    )
      return VALIDATION_STATUS.VALID;
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateBudgetAccessDetailsFields
   * @description Function to validate the carbon footprint details fields
   * @return boolean true if validation is success else false
   */
  const validateBudgetAccessDetailsFields = () => {
    if (
      privateKeyFileInfo.fileId &&
      (!currentConnection?.hasPassPhrase || currentConnection?.ociPassPhrase) &&
      currentConnection?.ociTenancy &&
      currentConnection?.ociUserId &&
      currentConnection?.ociRegion &&
      currentConnection?.fingerprint
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.SKIP;
  };

  /**
   * @function removeFile
   * @description Function to remove uploaded file from the form
   * @param fileId ID of the file to be removed
   * @param setFileInfo setter function for resetting the file info after deletion
   * @param setValidationError setter function for error message after deletion
   * @param fieldName fields label of the file deleted.
   */
  const removeFile = async (
    fileId: string,
    setFileInfo?: (val: FileInfoType) => void,
    setValidationError?: (val: string) => void,
    fieldName?: string
  ) => {
    if (fileId) {
      await deleteConnectionFile(fileId);
      dispatch(
        setCurrentConnection({
          ...currentConnection,
          jsonFileId: undefined,
        })
      );
      setFileInfo?.({ fileId: '', fileName: '' });
      setValidationError?.(`${fieldName} ${t('connectionCSPForm.isRequired')}`);
    }
  };

  if (fetchConnectionDataRequestStatus === REQUEST_STATUS.PROCESSING) {
    return <Loader />;
  }

  const getFormByStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicDetails
            setIsDuplicateConnectionName={setIsDuplicateConnectionName}
          />
        );
      case 2:
        return (
          <OCIAccessDetails
            removeFile={removeFile}
            walletFileInfo={walletFileInfo}
            setWalletFileInfo={setWalletFileInfo}
          />
        );
      case 3:
        return <OCIBillingDetails walletFileInfo={walletFileInfo} />;
      case 4:
        return (
          <OCIBudgetAccessDetails
            removeFile={removeFile}
            privateKeyFileInfo={privateKeyFileInfo}
            setPrivateKeyFileInfo={setPrivateKeyFileInfo}
          />
        );
    }
  };

  return (
    <div
      className="cloud-connection-form flex-fit"
      data-testid="oci-connection-form"
    >
      {getFormByStep()}
    </div>
  );
};

export default OCIConnectionForm;
