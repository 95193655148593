import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import moment from 'moment';
import { sumBy, uniqBy } from 'lodash';

import { selectDashboard, setExportToExcelData } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { getChartData } from 'utils/services';
import {
  addZeroMarginClass,
  getBillingPeriodDateFormatByProvider,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { DualAxisChartData } from 'types/dashboard';
import {
  DATE_FORMAT,
  DATE_TIME_AM_PM,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  enumerateDaysBetweenDates,
  getMonthYearShortList,
} from 'utils/date';
import ExpandModal from 'components/ExpandModal';
import TagFilters from 'components/TagFilters';
import PdfDownloadComponent from 'components/PdfDownloadComponent';
import { CHART_TYPES } from 'constants/graphConfig';
import { insertIndex } from 'utils/dataFormatterUtils';
import { modifyToExportColumns } from 'utils/exportToExcel';
import { OTHERS_LABEL } from 'constants/graphLabels';

import {
  getComputeExcelData,
  getEc2ComputeUnitCostByPurchaseOptionsQuery,
  getEc2CoverageByPurchaseOptionsColumns,
  getEc2UsageByPlatformColumns,
  getEc2UsageByPlatformQuery,
  getEc2ElasticityQuery,
  getEc2ElasticityColumns,
  getEc2RunningHoursCostByAccountQuery,
  getPurchaseOptionWithGroupingValuesColumns,
  getEc2CostByInstanceFamilyQuery,
  getEc2CostByInstanceFamilyColumns,
  getEc2RunningHoursCostByInstanceFamilyQuery,
  getTopLambdaAccountsByCostQuery,
  getLambdaByPurchaseOptionsQuery,
  getTopLambdasByResourceIdQuery,
  getTopLambdasByDailyCostQuery,
  getEC2CpuCreditsUsageCostByAccountsQuery,
  getMonthlyCostByAccountsColumns,
  getFargateAccountsByCostQuery,
  getFargateCostByPurchaseOptionsQuery,
  getFargateEcsEksCostQuery,
  getFargateClusterEksAndEcsCostColumns,
  getFargateTopClustersByDailyCostQuery,
  getDailyCostByAccountsColumns,
  getResourceIdWithDailyCostColumns,
  getEc2LabelByProvider,
  hasEc2CpuCreditsUsageCostByAccountsChart,
  getLambdaLabelByProvider,
  getTopLambdasByResourceIdGraphHeader,
  getFargateLabelByProvider,
  hasEcsEksFargateAndEksControlPanelCost,
  getPlatformLabelByProvider,
  getInstanceFamilyLabelByProvider,
} from './utils';
import StackedDualAxisCharts from '../StackedDualAxisCharts';
import StackedColumnCharts from '../StackedColumnCharts';
import StackedHorizontalBarCharts from '../StackedHorizontalBarCharts';
import StackedColumnLineWithUnitCostCharts from '../StackedColumnLineWithUnitCostCharts';
import {
  getAccountLabelByProvider,
  getAccountsLabelByProvider,
  getAccountWithGroupingValuesColumns,
  getDualAxisCostByTypeTableData,
  getEc2UsagePercentageTableData,
  getPdfMetaData,
  getUsageCostTableData,
} from '../../utils';
import { UnitCostTimePeriod } from '../../constants';
import ServiceViewsControls from '../ServiceViewsControls';

const ComputeDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedDashboard,
    selectedConnection,
    showExpandGraphModal,
    expandedGraphName,
    tableViewEnabled,
    pdfDownloadMode,
    tagsFilters,
    selectedDashboardView,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  // Accounts States
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [accountsReqStatus, setAccountsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  // EC2 Compute Unit Cost and Normalized Hours by Purchase Option
  const [ec2CostByPurchaseOption, setEc2CostByPurchaseOption] = useState<any[]>(
    []
  );
  const [ec2CostByPOReqStatus, setEc2CostByPOReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [ec2CostByPOTableView, setEc2CostByPOTableView] = useState(false);
  const [ec2CostByPODateRange, setEc2CostByPODateRange] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);
  const [ec2CostByPOUnitCostTimePeriod, setEc2CostByPOUnitCostTimePeriod] =
    useState(UnitCostTimePeriod.HOURLY);

  // EC2 Coverage by Purchase Option in Normalized Hours
  const [ec2CoverageByPurchaseOption, setEc2CoverageByPurchaseOption] =
    useState<any[]>([]);
  const [ec2CoverageByPOReqStatus, setEc2CoverageByPOReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [ec2CoverageByPOTableView, setEc2CoverageByPOTableView] =
    useState(false);
  const [ec2CoverageByPODateRange, setEc2CoverageByPODateRange] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // EC2 Normalized Hours by Platform
  const [ec2UsageByPlatform, setEc2UsageByPlatform] = useState<any[]>([]);
  const [ec2UsageByPlatformReqStatus, setEc2UsageByPlatformReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [ec2UsageByPlatformTableView, setEc2UsageByPlatformTableView] =
    useState(false);
  const [ec2UsageByPlatformDateRange, setEc2UsageByPlatformDateRange] =
    useState([
      moment()
        .subtract(5, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
    ]);

  // EC2 Elasticity
  const [ec2Elasticity, setEc2Elasticity] = useState<DualAxisChartData>({
    leftAxisData: [],
    rightAxisData: [],
  });
  const [ec2ElasticityReqStatus, setEc2ElasticityReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [ec2ElasticityTableView, setEc2ElasticityTableView] = useState(false);
  const [ec2ElasticityDateRange, setEc2ElasticityDateRange] = useState([
    moment()
      .subtract(1, 'months')
      .subtract(1, 'day')
      .format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);

  // EC2 Running Hours Cost by Spending Account
  const [ec2RunningHoursCostByAccount, setEc2RunningHoursCostByAccount] =
    useState<any[]>([]);
  const [
    ec2RunningHoursCostByAccountReqStatus,
    setEc2RunningHoursCostByAccountReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    ec2RunningHoursCostByAccountTableView,
    setEc2RunningHoursCostByAccountTableView,
  ] = useState(false);
  const [
    ec2RunningHoursCostByAccountDateRange,
    setEc2RunningHoursCostByAccountDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // EC2 Daily Cost by Instance Family
  const [ec2CostByInstanceFamily, setEc2CostByInstanceFamily] =
    useState<DualAxisChartData>({
      leftAxisData: [],
      rightAxisData: [],
    });
  const [
    ec2CostByInstanceFamilyReqStatus,
    setEc2CostByInstanceFamilyReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    ec2CostByInstanceFamilyTableView,
    setEc2CostByInstanceFamilyTableView,
  ] = useState(false);
  const [
    ec2CostByInstanceFamilyDateRange,
    setEc2CostByInstanceFamilyDateRange,
  ] = useState([
    moment()
      .subtract(2, 'months')
      .subtract(1, 'day')
      .format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);

  // EC2 Running Hours Cost by Instance Family
  const [
    ec2RunningHoursCostByInstanceFamily,
    setEc2RunningHoursCostByInstanceFamily,
  ] = useState<any[]>([]);
  const [
    ec2RunningHoursCostByInstanceFamilyReqStatus,
    setEc2RunningHoursCostByInstanceFamilyReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    ec2RunningHoursCostByInstanceFamilyTableView,
    setEc2RunningHoursCostByInstanceFamilyTableView,
  ] = useState(false);
  const [
    ec2RunningHoursCostByInstanceFamilyDateRange,
    setEc2RunningHoursCostByInstanceFamilyDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // EC2 CPU Credits Usage Cost by Accounts
  const [
    ec2CpuCreditsUsageCostByAccounts,
    setEc2CpuCreditsUsageCostByAccounts,
  ] = useState<any[]>([]);
  const [
    ec2CpuCreditsUsageCostByAccountsReqStatus,
    setEc2CpuCreditsUsageCostByAccountsReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    ec2CpuCreditsUsageCostByAccountsTableView,
    setEc2CpuCreditsUsageCostByAccountsTableView,
  ] = useState(false);
  const [
    ec2CpuCreditsUsageCostByAccountsDateRange,
    setEc2CpuCreditsUsageCostByAccountsDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // Top Lambda Accounts by Cost
  const [topLambdaAccountsByCost, setTopLambdaAccountsByCost] = useState<any[]>(
    []
  );
  const [
    topLambdaAccountsByCostReqStatus,
    setTopLambdaAccountsByCostReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    topLambdaAccountsByCostTableView,
    setTopLambdaAccountsByCostTableView,
  ] = useState(false);
  const [
    topLambdaAccountsByCostDateRange,
    setTopLambdaAccountsByCostDateRange,
  ] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);
  const [
    topLambdaAccountsByCostUnitCostTimePeriod,
    setTopLambdaAccountsByCostUnitCostTimePeriod,
  ] = useState(UnitCostTimePeriod.HOURLY);

  // Lambda by Purchase Option
  const [lambdaByPurchaseOption, setLambdaByPurchaseOption] = useState<any[]>(
    []
  );
  const [lambdaByPurchaseOptionReqStatus, setLambdaByPurchaseOptionReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [lambdaByPurchaseOptionTableView, setLambdaByPurchaseOptionTableView] =
    useState(false);
  const [lambdaByPurchaseOptionDateRange, setLambdaByPurchaseOptionDateRange] =
    useState([
      moment()
        .subtract(5, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
    ]);

  // Top Lambdas by resource id
  const [topLambdasByResourceId, setTopLambdasByResourceId] = useState<any[]>(
    []
  );
  const [topLambdasByResourceIdReqStatus, setTopLambdasByResourceIdReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [topLambdasByResourceIdTableView, setTopLambdasByResourceIdTableView] =
    useState(false);
  const [topLambdasByResourceIdDateRange, setTopLambdasByResourceIdDateRange] =
    useState([
      moment()
        .subtract(5, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
    ]);

  // Top Lambdas by daily cost
  const [topLambdasByDailyCost, setTopLambdasByDailyCost] = useState<any[]>([]);
  const [topLambdasByDailyCostReqStatus, setTopLambdasByDailyCostReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [topLambdasByDailyCostTableView, setTopLambdasByDailyCostTableView] =
    useState(false);
  const [topLambdasByDailyCostDateRange, setTopLambdasByDailyCostDateRange] =
    useState([
      moment()
        .subtract(2, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
    ]);

  // Fargate Accounts by Cost
  const [fargateAccountsByCost, setFargateAccountsByCost] = useState<any[]>([]);
  const [fargateAccountsByCostReqStatus, setFargateAccountsByCostReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [fargateAccountsByCostTableView, setFargateAccountsByCostTableView] =
    useState(false);
  const [fargateAccountsByCostDateRange, setFargateAccountsByCostDateRange] =
    useState([
      moment()
        .subtract(5, 'months')
        .startOf('month')
        .format(HYPHEN_DATE_FORMAT),
      moment().format(HYPHEN_DATE_FORMAT),
    ]);
  const [
    fargateAccountsByCostUnitCostTimePeriod,
    setFargateAccountsByCostUnitCostTimePeriod,
  ] = useState(UnitCostTimePeriod.HOURLY);

  // Fargate Cost by Purchase Option
  const [fargateCostByPurchaseOption, setFargateCostByPurchaseOption] =
    useState<any[]>([]);
  const [fargateCostByPOReqStatus, setFargateCostByPOReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fargateCostByPOTableView, setFargateCostByPOTableView] =
    useState(false);
  const [fargateCostByPODateRange, setFargateCostByPODateRange] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // "ECS Fargate", "EKS Fargate" and "EKS Control Plane" cost
  const [fargateEcsEksCost, setFargateEcsEksCost] = useState<any[]>([]);
  const [fargateEcsEksCostReqStatus, setFargateEcsEksCostReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fargateEcsEksCostTableView, setFargateEcsEksCostTableView] =
    useState(false);
  const [fargateEcsEksCostDateRange, setFargateEcsEksCostDateRange] = useState([
    moment().subtract(5, 'months').startOf('month').format(HYPHEN_DATE_FORMAT),
    moment().format(HYPHEN_DATE_FORMAT),
  ]);

  // Fargate Top Clusters by Daily Cost
  const [fargateTopClustersByDailyCost, setFargateTopClustersByDailyCost] =
    useState<any[]>([]);
  const [
    fargateTopClustersByDailyCostReqStatus,
    setFargateTopClustersByDailyCostReqStatus,
  ] = useState(REQUEST_STATUS.SUCCESS);
  const [
    fargateTopClustersByDailyCostTableView,
    setFargateTopClustersByDailyCostTableView,
  ] = useState(false);
  const [
    fargateTopClustersByDailyCostDateRange,
    setFargateTopClustersByDailyCostDateRange,
  ] = useState([
    moment()
      .subtract(2, 'months')
      .subtract(1, 'day')
      .format(HYPHEN_DATE_FORMAT),
    moment().subtract(1, 'day').format(HYPHEN_DATE_FORMAT),
  ]);

  useEffect(() => {
    addZeroMarginClass('dashboard-view');

    return () => {
      removeZeroMarginClass('dashboard-view');
    };
  }, []);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEc2CostByPurchaseOption([]);
    } else {
      fetchEc2ComputeUnitCostByPurchaseOptions();
    }
  }, [selectedAccounts, ec2CostByPODateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEc2CoverageByPurchaseOption([]);
    } else {
      fetchEc2CoverageByPurchaseOptions();
    }
  }, [selectedAccounts, ec2CoverageByPODateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEc2UsageByPlatform([]);
    } else {
      fetchEc2UsageByPlatform();
    }
  }, [selectedAccounts, ec2UsageByPlatformDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEc2Elasticity({ leftAxisData: [], rightAxisData: [] });
    } else {
      fetchEc2Elasticity();
    }
  }, [selectedAccounts, ec2ElasticityDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEc2RunningHoursCostByAccount([]);
    } else {
      fetchEc2RunningHoursCostByAccount();
    }
  }, [selectedAccounts, ec2RunningHoursCostByAccountDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEc2CostByInstanceFamily({ leftAxisData: [], rightAxisData: [] });
    } else {
      fetchEc2CostByInstanceFamily();
    }
  }, [selectedAccounts, ec2CostByInstanceFamilyDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setEc2RunningHoursCostByInstanceFamily([]);
    } else {
      fetchEc2RunningHoursCostByInstanceFamily();
    }
  }, [
    selectedAccounts,
    ec2RunningHoursCostByInstanceFamilyDateRange,
    tagsFilters,
  ]);

  useEffect(() => {
    if (
      selectedAccounts.length > 0 &&
      hasEc2CpuCreditsUsageCostByAccountsChart(
        selectedDashboard!.connectorProvider
      )
    ) {
      fetchEc2CpuCreditsUsageCostByAccounts();
    } else {
      setEc2CpuCreditsUsageCostByAccounts([]);
    }
  }, [
    selectedAccounts,
    ec2CpuCreditsUsageCostByAccountsDateRange,
    tagsFilters,
  ]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setTopLambdaAccountsByCost([]);
    } else {
      fetchTopLambdaAccountsByCost();
    }
  }, [selectedAccounts, topLambdaAccountsByCostDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setLambdaByPurchaseOption([]);
    } else {
      fetchLambdaByPurchaseOption();
    }
  }, [selectedAccounts, lambdaByPurchaseOptionDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setTopLambdasByResourceId([]);
    } else {
      fetchTopLambdasByResourceId();
    }
  }, [selectedAccounts, topLambdasByResourceIdDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setTopLambdasByDailyCost([]);
    } else {
      fetchTopLambdasByDailyCost();
    }
  }, [selectedAccounts, topLambdasByDailyCostDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setFargateAccountsByCost([]);
    } else {
      fetchFargateAccountsByCost();
    }
  }, [selectedAccounts, fargateAccountsByCostDateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setFargateCostByPurchaseOption([]);
    } else {
      fetchFargateCostByPurchaseOptions();
    }
  }, [selectedAccounts, fargateCostByPODateRange, tagsFilters]);

  useEffect(() => {
    if (
      selectedAccounts.length > 0 &&
      hasEcsEksFargateAndEksControlPanelCost(
        selectedDashboard!.connectorProvider
      )
    ) {
      fetchFargateEcsEksCost();
    } else {
      setFargateEcsEksCost([]);
    }
  }, [selectedAccounts, fargateCostByPODateRange, tagsFilters]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setFargateTopClustersByDailyCost([]);
    } else {
      fetchFargateTopClustersByDailyCost();
    }
  }, [selectedAccounts, fargateTopClustersByDailyCostDateRange, tagsFilters]);

  useEffect(() => {
    setEc2CostByPOTableView(tableViewEnabled);
    setEc2CoverageByPOTableView(tableViewEnabled);
    setEc2UsageByPlatformTableView(tableViewEnabled);
    setEc2ElasticityTableView(tableViewEnabled);
    setEc2RunningHoursCostByAccountTableView(tableViewEnabled);
    setEc2CostByInstanceFamilyTableView(tableViewEnabled);
    setEc2RunningHoursCostByInstanceFamilyTableView(tableViewEnabled);
    setEc2CpuCreditsUsageCostByAccountsTableView(tableViewEnabled);
    setTopLambdaAccountsByCostTableView(tableViewEnabled);
    setLambdaByPurchaseOptionTableView(tableViewEnabled);
    setTopLambdasByResourceIdTableView(tableViewEnabled);
    setTopLambdasByDailyCostTableView(tableViewEnabled);
    setFargateAccountsByCostTableView(tableViewEnabled);
    setFargateCostByPOTableView(tableViewEnabled);
    setFargateEcsEksCostTableView(tableViewEnabled);
    setFargateTopClustersByDailyCostTableView(tableViewEnabled);
  }, [tableViewEnabled]);

  useEffect(() => {
    dispatch(
      setExportToExcelData(
        getComputeExcelData(
          selectedDashboard!.connectorProvider,
          selectedDashboard!.connectorName,
          selectedAccounts,
          {
            ec2ComputeByPOData: {
              sheetName: t('graphHeadings.ec2ComputeCostByPurchaseOption', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider,
                  true
                ),
              }),
              data: ec2CostByPurchaseOption,
              dateRange: ec2CostByPODateRange,
            },
            ec2CoverageByPOData: {
              sheetName: t('graphHeadings.ec2CoverageByPurchaseOption', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              data: ec2CoverageByPurchaseOption,
              dateRange: ec2CoverageByPODateRange,
            },
            ec2UsageByPlatformData: {
              sheetName: t('graphHeadings.ec2ComputeCoverageByPlatform', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider,
                  true
                ),
                platform: getPlatformLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              data: ec2UsageByPlatform,
              dateRange: ec2UsageByPlatformDateRange,
            },
            ec2ElasticityData: {
              sheetName: t('graphHeadings.ec2Elasticity', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              data: ec2Elasticity,
              dateRange: ec2ElasticityDateRange,
            },
            ec2RunningHoursCostByAccountData: {
              sheetName: t(
                'graphHeadings.ec2RunningHoursCostBySpendingAccount',
                {
                  ec2: getEc2LabelByProvider(
                    selectedDashboard!.connectorProvider
                  ),
                  account: getAccountLabelByProvider(
                    selectedDashboard!.connectorProvider
                  ),
                }
              ),
              data: ec2RunningHoursCostByAccount,
              dateRange: ec2RunningHoursCostByAccountDateRange,
            },
            ec2CostByInstanceFamily: {
              sheetName: t('graphHeadings.ec2DailyCostByInstanceFamily', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                instanceFamily: getInstanceFamilyLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              data: ec2CostByInstanceFamily,
              dateRange: ec2CostByInstanceFamilyDateRange,
            },
            ec2RunningHoursCostByInstanceFamily: {
              sheetName: t('graphHeadings.ec2CostByInstanceFamily', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                instanceFamily: getInstanceFamilyLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              data: ec2RunningHoursCostByInstanceFamily,
              dateRange: ec2RunningHoursCostByInstanceFamilyDateRange,
            },
            ec2CpuCreditsUsageCostByAccounts: {
              sheetName: t('graphHeadings.ec2CpuCreditsUsageCostByAccounts'),
              data: ec2CpuCreditsUsageCostByAccounts,
              dateRange: ec2CpuCreditsUsageCostByAccountsDateRange,
            },
            topLambdaAccountsByCost: {
              sheetName: t('graphHeadings.topLambdaAccountsByCost', {
                lambda: getLambdaLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                accounts: getAccountsLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                interpolation: { escapeValue: false },
              }),
              data: topLambdaAccountsByCost,
              dateRange: topLambdaAccountsByCostDateRange,
            },
            lambdaByPurchaseOption: {
              sheetName: t('graphHeadings.lambdaByPurchaseOption', {
                lambda: getLambdaLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                interpolation: { escapeValue: false },
              }),
              data: lambdaByPurchaseOption,
              dateRange: lambdaByPurchaseOptionDateRange,
            },
            topLambdasByResourceId: {
              sheetName: getTopLambdasByResourceIdGraphHeader(
                selectedDashboard!.connectorProvider
              ),
              data: topLambdasByResourceId,
              dateRange: topLambdasByResourceIdDateRange,
            },
            topLambdasByDailyCost: {
              sheetName: t('graphHeadings.topLambdasByDailyCost', {
                lambda: getLambdaLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                interpolation: { escapeValue: false },
              }),
              data: topLambdasByDailyCost,
              dateRange: topLambdasByDailyCostDateRange,
            },
            fargateAccountsByCost: {
              sheetName: t('graphHeadings.fargateAccountsByCost', {
                fargate: getFargateLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                accounts: getAccountsLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              data: fargateAccountsByCost,
              dateRange: fargateAccountsByCostDateRange,
            },
            fargateCostByPO: {
              sheetName: t('graphHeadings.fargateCostByPurchaseOption', {
                fargate: getFargateLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              data: fargateCostByPurchaseOption,
              dateRange: fargateCostByPODateRange,
            },
            fargateEcsEksCost: {
              sheetName: t('graphHeadings.ecsEksFargateAndEksControlPanelCost'),
              data: fargateEcsEksCost,
              dateRange: fargateEcsEksCostDateRange,
            },
            fargateTopClustersByDailyCost: {
              sheetName: t('graphHeadings.fargateTopClustersByDailyCost', {
                fargate: getFargateLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              data: fargateTopClustersByDailyCost,
              dateRange: fargateTopClustersByDailyCostDateRange,
            },
          }
        )
      )
    );
  }, [
    selectedDashboard,
    selectedAccounts,
    ec2CostByPODateRange,
    ec2CostByPurchaseOption,
    ec2CoverageByPurchaseOption,
    ec2CoverageByPODateRange,
    ec2UsageByPlatform,
    ec2UsageByPlatformDateRange,
    ec2Elasticity,
    ec2ElasticityDateRange,
    ec2CostByInstanceFamily,
    ec2CostByInstanceFamilyDateRange,
    ec2RunningHoursCostByAccount,
    ec2RunningHoursCostByAccountDateRange,
    ec2RunningHoursCostByInstanceFamily,
    ec2RunningHoursCostByInstanceFamilyDateRange,
    ec2CpuCreditsUsageCostByAccounts,
    ec2CpuCreditsUsageCostByAccountsDateRange,
    topLambdaAccountsByCost,
    topLambdaAccountsByCostDateRange,
    lambdaByPurchaseOption,
    lambdaByPurchaseOptionDateRange,
    topLambdasByResourceId,
    topLambdasByResourceIdDateRange,
    topLambdasByDailyCost,
    topLambdasByDailyCostDateRange,
    fargateAccountsByCost,
    fargateAccountsByCostDateRange,
    fargateEcsEksCost,
    fargateEcsEksCostDateRange,
    fargateCostByPurchaseOption,
    fargateCostByPODateRange,
    fargateTopClustersByDailyCost,
    fargateTopClustersByDailyCostDateRange,
  ]);

  /**
   * @function fetchEc2ComputeUnitCostByPurchaseOptions
   * @description Function to fetch the Ec2 Compute Unit Cost By Purchase Options
   */
  const fetchEc2ComputeUnitCostByPurchaseOptions = () => {
    setEc2CostByPOReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEc2ComputeUnitCostByPurchaseOptionsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ec2CostByPODateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
            [UnitCostTimePeriod.DAILY]: Number(item[UnitCostTimePeriod.DAILY]),
            [UnitCostTimePeriod.HOURLY]: Number(
              item[UnitCostTimePeriod.HOURLY]
            ),
            [UnitCostTimePeriod.WEEKLY]: Number(
              item[UnitCostTimePeriod.WEEKLY]
            ),
          })) ?? [];
        const pricingTerms = uniqBy(data, 'pricingTerm').map(
          (item) => item.pricingTerm
        );
        const months = getMonthYearShortList(
          ec2CostByPODateRange[0],
          ec2CostByPODateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          pricingTerms.forEach((term) => {
            const dataItem = data.find(
              (item) =>
                item.pricingTerm === term &&
                item.month ===
                  moment(month, MONTH_YEAR_FORMAT).format(
                    getBillingPeriodDateFormatByProvider(
                      selectedDashboard!.connectorProvider
                    )
                  )
            );
            formattedData.push({
              time: month,
              type: term,
              value: dataItem?.cost ?? 0,
              [UnitCostTimePeriod.DAILY]:
                dataItem?.[UnitCostTimePeriod.DAILY] ?? 0,
              [UnitCostTimePeriod.HOURLY]:
                dataItem?.[UnitCostTimePeriod.HOURLY] ?? 0,
              [UnitCostTimePeriod.WEEKLY]:
                dataItem?.[UnitCostTimePeriod.WEEKLY] ?? 0,
              unitCostType: 'Average Unit Cost - ' + term,
            });
          });
        });

        setEc2CostByPurchaseOption(formattedData);
        setEc2CostByPOReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEc2CostByPOReqStatus);
      });
  };

  /**
   * @function fetchEc2CoverageByPurchaseOptions
   * @description Function to fetch the Ec2 Coverage By Purchase Options
   */
  const fetchEc2CoverageByPurchaseOptions = () => {
    setEc2CoverageByPOReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEc2ComputeUnitCostByPurchaseOptionsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ec2CoverageByPODateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
          })) ?? [];
        const pricingTerms = uniqBy(data, 'pricingTerm').map(
          (item) => item.pricingTerm
        );
        const months = getMonthYearShortList(
          ec2CoverageByPODateRange[0],
          ec2CoverageByPODateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          const monthData = data.filter(
            (item) =>
              item.month ===
              moment(month, MONTH_YEAR_FORMAT).format(
                getBillingPeriodDateFormatByProvider(
                  selectedDashboard!.connectorProvider
                )
              )
          );
          pricingTerms.forEach((term) => {
            const dataItem = monthData.find(
              (item) => item.pricingTerm === term
            );
            const percentage = dataItem?.cost
              ? (dataItem.cost / sumBy(monthData, 'cost')) * 100
              : 0;
            formattedData.push({
              time: month,
              type: term,
              value: percentage,
            });
          });
        });
        setEc2CoverageByPurchaseOption(formattedData);
        setEc2CoverageByPOReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEc2CoverageByPOReqStatus);
      });
  };

  /**
   * @function fetchEc2UsageByPlatform
   * @description Function to fetch the EC2 Normalized Hours by Platform
   */
  const fetchEc2UsageByPlatform = () => {
    setEc2UsageByPlatformReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEc2UsageByPlatformQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ec2UsageByPlatformDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
          })) ?? [];
        const osList = uniqBy(data, 'os').map((item) => item.os);
        const months = getMonthYearShortList(
          ec2UsageByPlatformDateRange[0],
          ec2UsageByPlatformDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          const monthData = data.filter(
            (item) =>
              item.month ===
              moment(month, MONTH_YEAR_FORMAT).format(
                getBillingPeriodDateFormatByProvider(
                  selectedDashboard!.connectorProvider
                )
              )
          );
          osList.forEach((os) => {
            const dataItem = monthData.find((item) => item.os === os);
            const percentage = dataItem?.cost
              ? (dataItem.cost / sumBy(monthData, 'cost')) * 100
              : 0;
            formattedData.push({
              time: month,
              type: os,
              value: percentage,
            });
          });
        });
        setEc2UsageByPlatform(formattedData);
        setEc2UsageByPlatformReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEc2UsageByPlatformReqStatus);
      });
  };

  /**
   * @function fetchEc2Elasticity
   * @description Function to fetch the EC2 Elasticity
   */
  const fetchEc2Elasticity = () => {
    setEc2ElasticityReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEc2ElasticityQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ec2ElasticityDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_TIME_AM_PM),
            cost: Number(item.cost),
          })) ?? [];
        const pricingTerms = uniqBy(data, 'pricingTerm').map(
          (item) => item.pricingTerm
        );
        const timeList = enumerateDaysBetweenDates(ec2ElasticityDateRange);

        const formattedLeftAxisData: any[] = [];
        const formattedRightAxisData: any[] = [];
        timeList.forEach((time) => {
          const currentTimeData = data.filter((item) => time === item.time);
          formattedRightAxisData.push({
            time: time,
            type: t('computeDashboard.costSum'),
            value: sumBy(currentTimeData, 'cost'),
          });
          pricingTerms.forEach((term) => {
            const dataItem = currentTimeData.find(
              (item) => item.pricingTerm === term
            );
            formattedLeftAxisData.push({
              time: time,
              type: term,
              value: dataItem?.cost ?? 0,
            });
          });
        });

        setEc2Elasticity({
          leftAxisData: formattedLeftAxisData,
          rightAxisData: formattedRightAxisData,
        });
        setEc2ElasticityReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEc2ElasticityReqStatus);
      });
  };

  /**
   * @function fetchEc2RunningHoursCostByAccount
   * @description Function to fetch the EC2 Running Hours by Account
   */
  const fetchEc2RunningHoursCostByAccount = () => {
    setEc2RunningHoursCostByAccountReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEc2RunningHoursCostByAccountQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ec2RunningHoursCostByAccountDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            type: item.pricingTerm,
            value: Number(item.cost),
          })) ?? [];
        setEc2RunningHoursCostByAccount(data);
        setEc2RunningHoursCostByAccountReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEc2RunningHoursCostByAccountReqStatus);
      });
  };

  /**
   * @function fetchEc2CostByInstanceFamily
   * @description Function to fetch the EC2 Daily Cost by Instance Family
   */
  const fetchEc2CostByInstanceFamily = () => {
    setEc2CostByInstanceFamilyReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEc2CostByInstanceFamilyQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ec2CostByInstanceFamilyDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
            instanceFamily: item.instanceFamily
              ? item.instanceFamily
              : OTHERS_LABEL,
          })) ?? [];
        const instanceFamilies = uniqBy(data, 'instanceFamily')
          .map((item) => item.instanceFamily)
          .reverse();
        const datesList = enumerateDaysBetweenDates(
          ec2CostByInstanceFamilyDateRange,
          DATE_FORMAT,
          'day'
        );

        const formattedLeftAxisData: any[] = [];
        const formattedRightAxisData: any[] = [];
        datesList.forEach((date) => {
          const currentDateData = data.filter((item) => date === item.time);
          formattedRightAxisData.push({
            time: date,
            type: t('computeDashboard.costSum'),
            value: sumBy(currentDateData, 'cost'),
          });
          instanceFamilies.forEach((family) => {
            const dataItem = currentDateData.find(
              (item) => item.instanceFamily === family
            );
            formattedLeftAxisData.push({
              time: date,
              type: family,
              value: dataItem?.cost ?? 0,
            });
          });
        });

        setEc2CostByInstanceFamily({
          leftAxisData: formattedLeftAxisData,
          rightAxisData: formattedRightAxisData,
        });
        setEc2CostByInstanceFamilyReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEc2CostByInstanceFamilyReqStatus);
      });
  };

  /**
   * @function fetchEc2RunningHoursCostByInstanceFamily
   * @description Function to fetch the EC2 Running Hours by Instance Family
   */
  const fetchEc2RunningHoursCostByInstanceFamily = () => {
    setEc2RunningHoursCostByInstanceFamilyReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEc2RunningHoursCostByInstanceFamilyQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ec2RunningHoursCostByInstanceFamilyDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            type: item.pricingTerm,
            value: Number(item.cost ?? 0),
          })) ?? [];
        setEc2RunningHoursCostByInstanceFamily(data);
        setEc2RunningHoursCostByInstanceFamilyReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(
          e,
          false,
          setEc2RunningHoursCostByInstanceFamilyReqStatus
        );
      });
  };

  /** @function fetchEc2CpuCreditsUsageCostByAccounts
   * @description Function to fetch the Ec2 CPU Credits Usage Cost By Accounts
   */
  const fetchEc2CpuCreditsUsageCostByAccounts = () => {
    setEc2CpuCreditsUsageCostByAccountsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getEC2CpuCreditsUsageCostByAccountsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      ec2CpuCreditsUsageCostByAccountsDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
          })) ?? [];
        const accountsList = uniqBy(data, 'accountId').map(
          (item) => item.accountId
        );
        const months = getMonthYearShortList(
          ec2CpuCreditsUsageCostByAccountsDateRange[0],
          ec2CpuCreditsUsageCostByAccountsDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          accountsList.forEach((account) => {
            const dataItem = data.find(
              (item) =>
                item.month ===
                  moment(month, MONTH_YEAR_FORMAT).format(
                    getBillingPeriodDateFormatByProvider(
                      selectedDashboard!.connectorProvider
                    )
                  ) && item.accountId === account
            );
            formattedData.push({
              time: month,
              type: account,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setEc2CpuCreditsUsageCostByAccounts(formattedData);
        setEc2CpuCreditsUsageCostByAccountsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setEc2CpuCreditsUsageCostByAccountsReqStatus);
      });
  };

  /**
   * @function fetchTopLambdaAccountsByCost
   * @description Function to fetch the Top Lambda Accounts by Cost
   */
  const fetchTopLambdaAccountsByCost = () => {
    setTopLambdaAccountsByCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getTopLambdaAccountsByCostQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      topLambdaAccountsByCostDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
            [UnitCostTimePeriod.DAILY]: Number(item[UnitCostTimePeriod.DAILY]),
            [UnitCostTimePeriod.HOURLY]: Number(
              item[UnitCostTimePeriod.HOURLY]
            ),
            [UnitCostTimePeriod.WEEKLY]: Number(
              item[UnitCostTimePeriod.WEEKLY]
            ),
            account: item.account ? item.account : OTHERS_LABEL,
          })) ?? [];
        const accounts = uniqBy(data, 'account')
          .map((item) => item.account)
          .reverse();
        const months = getMonthYearShortList(
          topLambdaAccountsByCostDateRange[0],
          topLambdaAccountsByCostDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          const monthData = data.filter(
            (item) =>
              item.month ===
              moment(month, MONTH_YEAR_FORMAT).format(
                getBillingPeriodDateFormatByProvider(
                  selectedDashboard!.connectorProvider
                )
              )
          );
          accounts.forEach((account) => {
            const dataItem = monthData.find((item) => item.account === account);
            formattedData.push({
              time: month,
              type: account,
              value: dataItem?.cost ?? 0,
              [UnitCostTimePeriod.DAILY]:
                dataItem?.[UnitCostTimePeriod.DAILY] ?? 0,
              [UnitCostTimePeriod.HOURLY]:
                dataItem?.[UnitCostTimePeriod.HOURLY] ?? 0,
              [UnitCostTimePeriod.WEEKLY]:
                dataItem?.[UnitCostTimePeriod.WEEKLY] ?? 0,
              unitCostType: 'Average Unit Cost - ' + account,
            });
          });
        });
        setTopLambdaAccountsByCost(formattedData);
        setTopLambdaAccountsByCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTopLambdaAccountsByCostReqStatus);
      });
  };

  /**
   * @function fetchLambdaByPurchaseOption
   * @description Function to fetch the Lambda by Purchase Option
   */
  const fetchLambdaByPurchaseOption = () => {
    setLambdaByPurchaseOptionReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getLambdaByPurchaseOptionsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      lambdaByPurchaseOptionDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
          })) ?? [];
        const pricingTerms = uniqBy(data, 'pricingTerm').map(
          (item) => item.pricingTerm
        );
        const months = getMonthYearShortList(
          lambdaByPurchaseOptionDateRange[0],
          lambdaByPurchaseOptionDateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          const monthData = data.filter(
            (item) =>
              item.month ===
              moment(month, MONTH_YEAR_FORMAT).format(
                getBillingPeriodDateFormatByProvider(
                  selectedDashboard!.connectorProvider
                )
              )
          );
          pricingTerms.forEach((term) => {
            const dataItem = monthData.find(
              (item) => item.pricingTerm === term
            );
            formattedData.push({
              time: month,
              type: term,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setLambdaByPurchaseOption(formattedData);
        setLambdaByPurchaseOptionReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setLambdaByPurchaseOptionReqStatus);
      });
  };

  /**
   * @function fetchTopLambdasByResourceId
   * @description Function to fetch the Top Lambdas by Resource Id
   */
  const fetchTopLambdasByResourceId = () => {
    setTopLambdasByResourceIdReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getTopLambdasByResourceIdQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      topLambdasByResourceIdDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data.map((item: any) => ({
            ...item,
            type: item.account,
            value: Number(item.cost),
          })) ?? [];
        setTopLambdasByResourceId(data);
        setTopLambdasByResourceIdReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTopLambdasByResourceIdReqStatus);
      });
  };

  /**
   * @function fetchTopLambdasByDailyCost
   * @description Function to fetch the Top Lambdas by Daily Cost
   */
  const fetchTopLambdasByDailyCost = () => {
    setTopLambdasByDailyCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getTopLambdasByDailyCostQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      topLambdasByDailyCostDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data.map((item: any) => ({
            ...item,
            time: moment(item.time).format(DATE_FORMAT),
            cost: Number(item.cost),
          })) ?? [];
        const resourceIds = uniqBy(data, 'resourceId').map(
          (item) => item.resourceId
        );
        const dates = enumerateDaysBetweenDates(
          topLambdasByDailyCostDateRange,
          DATE_FORMAT,
          'day'
        );

        const formattedData: any[] = [];
        dates.forEach((date) => {
          const dateData = data.filter((item) => item.time === date);
          resourceIds.forEach((resourceId) => {
            const dataItem = dateData.find(
              (item) => item.resourceId === resourceId
            );
            formattedData.push({
              time: date,
              type: resourceId,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setTopLambdasByDailyCost(formattedData);
        setTopLambdasByDailyCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTopLambdasByDailyCostReqStatus);
      });
  };
  /**
   * @function fetchFargateAccountsByCost
   * @description Function to fetch the Fargate Accounts by Cost
   */
  const fetchFargateAccountsByCost = () => {
    setFargateAccountsByCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getFargateAccountsByCostQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      fargateAccountsByCostDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            time: moment(
              item.time,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
            cost: Number(item.cost),
            [UnitCostTimePeriod.DAILY]: Number(item[UnitCostTimePeriod.DAILY]),
            [UnitCostTimePeriod.HOURLY]: Number(
              item[UnitCostTimePeriod.HOURLY]
            ),
            [UnitCostTimePeriod.WEEKLY]: Number(
              item[UnitCostTimePeriod.WEEKLY]
            ),
          })) ?? [];
        const accountIds = uniqBy(data, 'accountId').map(
          (item) => item.accountId
        );
        const monthsList = getMonthYearShortList(
          fargateAccountsByCostDateRange[0],
          fargateAccountsByCostDateRange[1],
          MONTH_YEAR_FORMAT
        );
        const formattedData: any[] = [];
        monthsList.forEach((month) => {
          const currentDateData = data.filter((item) => month === item.time);
          accountIds.forEach((account) => {
            const dataItem = currentDateData.find(
              (item) => item.accountId === account
            );
            formattedData.push({
              time: month,
              type: account,
              value: dataItem?.cost ?? 0,
              [UnitCostTimePeriod.DAILY]:
                dataItem?.[UnitCostTimePeriod.DAILY] ?? 0,
              [UnitCostTimePeriod.HOURLY]:
                dataItem?.[UnitCostTimePeriod.HOURLY] ?? 0,
              [UnitCostTimePeriod.WEEKLY]:
                dataItem?.[UnitCostTimePeriod.WEEKLY] ?? 0,
              unitCostType: 'Average Unit Cost - ' + account,
            });
          });
        });
        setFargateAccountsByCost(formattedData);
        setFargateAccountsByCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFargateAccountsByCostReqStatus);
      });
  };

  /**
   * @function fetchFargateCostByPurchaseOptions
   * @description Function to fetch the Fargate Cost By Purchase Options
   */
  const fetchFargateCostByPurchaseOptions = () => {
    setFargateCostByPOReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getFargateCostByPurchaseOptionsQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      fargateCostByPODateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            month: moment(
              item.month,
              getBillingPeriodDateFormatByProvider(
                selectedDashboard!.connectorProvider
              )
            ).format(MONTH_YEAR_FORMAT),
            cost: Number(item.cost),
          })) ?? [];
        const pricingTerms = uniqBy(data, 'pricingTerm').map(
          (item) => item.pricingTerm
        );
        const months = getMonthYearShortList(
          fargateCostByPODateRange[0],
          fargateCostByPODateRange[1],
          MONTH_YEAR_FORMAT
        );

        const formattedData: any[] = [];
        months.forEach((month) => {
          pricingTerms.forEach((term) => {
            const dataItem = data.find(
              (item) => item.pricingTerm === term && item.month === month
            );
            formattedData.push({
              time: month,
              type: term,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setFargateCostByPurchaseOption(formattedData);
        setFargateCostByPOReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFargateCostByPOReqStatus);
      });
  };

  /**
   * @function fetchFargateEcsEksCost
   * @description Function to fetch the Fargate ECS EKS Cost
   */
  const fetchFargateEcsEksCost = () => {
    setFargateEcsEksCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getFargateEcsEksCostQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      fargateEcsEksCostDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data?.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
          })) ?? [];
        const fargateClusters = uniqBy(data, 'fargateCluster').map(
          (item) => item.fargateCluster
        );
        const eksEcsOperations = uniqBy(data, 'ecsEksOperation').map(
          (item) => item.ecsEksOperation
        );

        const formattedData: any[] = [];
        fargateClusters.forEach((cluster) => {
          eksEcsOperations.forEach((operation) => {
            const dataItem = data.find(
              (item) =>
                item.fargateCluster === cluster &&
                item.ecsEksOperation === operation
            );
            formattedData.push({
              operation,
              type: cluster,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setFargateEcsEksCost(formattedData);
        setFargateEcsEksCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFargateEcsEksCostReqStatus);
      });
  };

  /**
   * @function fetchFargateTopClustersByDailyCost
   * @description Function to fetch the Fargate Top Clusters by Daily Cost
   */
  const fetchFargateTopClustersByDailyCost = () => {
    setFargateTopClustersByDailyCostReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = getFargateTopClustersByDailyCostQuery(
      {
        dashboard: selectedDashboard!,
        connection: selectedConnection!,
        tagsFilters,
        dashboardView: selectedDashboardView,
      },
      selectedAccounts,
      fargateTopClustersByDailyCostDateRange
    );

    getChartData(requestBody, selectedDashboard!.connectorId)
      .then((res: any) => {
        const data: any[] =
          res?.data.map((item: any) => ({
            ...item,
            cost: Number(item.cost),
          })) ?? [];
        const accounts = uniqBy(data, 'account').map((item) => item.account);
        const dates = enumerateDaysBetweenDates(
          fargateTopClustersByDailyCostDateRange,
          DATE_FORMAT,
          'day'
        );
        const formattedData: any[] = [];
        dates.forEach((date) => {
          const dateData = data.filter(
            (item) =>
              moment(item.time, HYPHEN_DATE_FORMAT).format(DATE_FORMAT) === date
          );
          accounts.forEach((account) => {
            const dataItem = dateData.find((item) => item.account === account);
            formattedData.push({
              time: date,
              type: account,
              value: dataItem?.cost ?? 0,
            });
          });
        });
        setFargateTopClustersByDailyCost(formattedData);
        setFargateTopClustersByDailyCostReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFargateTopClustersByDailyCostReqStatus);
      });
  };

  const getGraphComponent = (graphName: string, pdfView: boolean = false) => {
    switch (graphName) {
      case 'ec2-cost-by-purchase-option':
        return (
          <StackedColumnLineWithUnitCostCharts
            title={t('graphHeadings.ec2ComputeCostByPurchaseOption', {
              ec2: getEc2LabelByProvider(
                selectedDashboard!.connectorProvider,
                true
              ),
            })}
            graph={graphName}
            data={ec2CostByPurchaseOption}
            xField="time"
            requestStatus={[accountsReqStatus, ec2CostByPOReqStatus]}
            isTableView={ec2CostByPOTableView}
            setIsTableView={setEc2CostByPOTableView}
            dateRange={ec2CostByPODateRange}
            setDateRange={setEc2CostByPODateRange}
            pdfView={pdfView}
            columns={getPurchaseOptionWithGroupingValuesColumns(
              getMonthYearShortList(
                ec2CostByPODateRange[0],
                ec2CostByPODateRange[1],
                MONTH_YEAR_FORMAT
              )
            )}
            tableData={getUsageCostTableData(ec2CostByPurchaseOption, 'time')}
            selectedAccounts={selectedAccounts}
            unitCostTimePeriod={ec2CostByPOUnitCostTimePeriod}
            setUnitCostTimePeriod={setEc2CostByPOUnitCostTimePeriod}
          />
        );

      case 'ec2-coverage-by-purchase-option':
        return (
          <StackedColumnCharts
            title={t('graphHeadings.ec2CoverageByPurchaseOption', {
              ec2: getEc2LabelByProvider(selectedDashboard!.connectorProvider),
            })}
            graph={graphName}
            data={ec2CoverageByPurchaseOption}
            xField="time"
            seriesField="type"
            yTitle={t('computeDashboard.usageAmountDisplaySum')}
            requestStatus={[accountsReqStatus, ec2CoverageByPOReqStatus]}
            isTableView={ec2CoverageByPOTableView}
            setIsTableView={setEc2CoverageByPOTableView}
            dateRange={ec2CoverageByPODateRange}
            setDateRange={setEc2CoverageByPODateRange}
            pdfView={pdfView}
            columns={getEc2CoverageByPurchaseOptionsColumns(
              ec2CoverageByPODateRange
            )}
            tableData={getEc2UsagePercentageTableData(
              ec2CoverageByPurchaseOption
            )}
            selectedAccounts={selectedAccounts}
            suffixSymbol="%"
          />
        );

      case 'ec2-usage-by-platform':
        return (
          <StackedColumnCharts
            title={t('graphHeadings.ec2ComputeCoverageByPlatform', {
              ec2: getEc2LabelByProvider(
                selectedDashboard!.connectorProvider,
                true
              ),
              platform: getPlatformLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
            })}
            graph={graphName}
            data={ec2UsageByPlatform}
            xField="time"
            seriesField="type"
            yTitle={t('computeDashboard.usageAmountDisplaySum')}
            requestStatus={[accountsReqStatus, ec2UsageByPlatformReqStatus]}
            isTableView={ec2UsageByPlatformTableView}
            setIsTableView={setEc2UsageByPlatformTableView}
            dateRange={ec2UsageByPlatformDateRange}
            setDateRange={setEc2UsageByPlatformDateRange}
            pdfView={pdfView}
            columns={getEc2UsageByPlatformColumns(
              ec2UsageByPlatformDateRange,
              selectedDashboard!.connectorProvider
            )}
            tableData={getEc2UsagePercentageTableData(ec2UsageByPlatform)}
            selectedAccounts={selectedAccounts}
            suffixSymbol="%"
          />
        );

      case 'ec2-elasticity':
        return (
          <StackedDualAxisCharts
            title={t('graphHeadings.ec2Elasticity', {
              ec2: getEc2LabelByProvider(selectedDashboard!.connectorProvider),
            })}
            graph={graphName}
            data={ec2Elasticity}
            xTitle={t('computeDashboard.time')}
            leftYTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            syncYAxis={true}
            requestStatus={[accountsReqStatus, ec2ElasticityReqStatus]}
            isTableView={ec2ElasticityTableView}
            setIsTableView={setEc2ElasticityTableView}
            dateRange={ec2ElasticityDateRange}
            setDateRange={setEc2ElasticityDateRange}
            pdfView={pdfView}
            columns={getEc2ElasticityColumns(ec2ElasticityDateRange)}
            tableData={getDualAxisCostByTypeTableData(ec2Elasticity)}
            selectedAccounts={selectedAccounts}
            showSlider={true}
            maxMonths={3}
          />
        );

      case 'ec2-running-hours-cost-spending-accounts':
        return (
          <StackedColumnCharts
            title={t('graphHeadings.ec2RunningHoursCostBySpendingAccount', {
              ec2: getEc2LabelByProvider(selectedDashboard!.connectorProvider),
              account: getAccountLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
            })}
            graph={graphName}
            data={ec2RunningHoursCostByAccount}
            xField="account"
            seriesField="type"
            xTitle={t('computeDashboard.spendingAccount')}
            yTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[
              accountsReqStatus,
              ec2RunningHoursCostByAccountReqStatus,
            ]}
            isTableView={ec2RunningHoursCostByAccountTableView}
            setIsTableView={setEc2RunningHoursCostByAccountTableView}
            dateRange={ec2RunningHoursCostByAccountDateRange}
            setDateRange={setEc2RunningHoursCostByAccountDateRange}
            pdfView={pdfView}
            columns={getPurchaseOptionWithGroupingValuesColumns(
              uniqBy(ec2RunningHoursCostByAccount, 'account').map(
                (item) => item.account
              )
            )}
            tableData={getUsageCostTableData(
              ec2RunningHoursCostByAccount,
              'account'
            )}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case 'ec2-cost-by-instance-family':
        return (
          <StackedDualAxisCharts
            title={t('graphHeadings.ec2DailyCostByInstanceFamily', {
              ec2: getEc2LabelByProvider(selectedDashboard!.connectorProvider),
              instanceFamily: getInstanceFamilyLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
            })}
            graph={graphName}
            data={ec2CostByInstanceFamily}
            xTitle={t('computeDashboard.date')}
            leftYTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            syncYAxis={true}
            requestStatus={[
              accountsReqStatus,
              ec2CostByInstanceFamilyReqStatus,
            ]}
            isTableView={ec2CostByInstanceFamilyTableView}
            setIsTableView={setEc2CostByInstanceFamilyTableView}
            dateRange={ec2CostByInstanceFamilyDateRange}
            setDateRange={setEc2CostByInstanceFamilyDateRange}
            pdfView={pdfView}
            columns={getEc2CostByInstanceFamilyColumns(
              ec2CostByInstanceFamilyDateRange,
              selectedDashboard!.connectorProvider
            )}
            tableData={getDualAxisCostByTypeTableData(ec2CostByInstanceFamily)}
            selectedAccounts={selectedAccounts}
            maxMonths={3}
          />
        );

      case 'ec2-running-hours-cost-instance-family':
        return (
          <StackedColumnCharts
            title={t('graphHeadings.ec2RunningHoursCostByInstanceFamily', {
              ec2: getEc2LabelByProvider(selectedDashboard!.connectorProvider),
              instanceFamily: getInstanceFamilyLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
            })}
            graph={graphName}
            data={ec2RunningHoursCostByInstanceFamily}
            xField="instanceFamily"
            seriesField="type"
            yTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[
              accountsReqStatus,
              ec2RunningHoursCostByInstanceFamilyReqStatus,
            ]}
            isTableView={ec2RunningHoursCostByInstanceFamilyTableView}
            setIsTableView={setEc2RunningHoursCostByInstanceFamilyTableView}
            dateRange={ec2RunningHoursCostByInstanceFamilyDateRange}
            setDateRange={setEc2RunningHoursCostByInstanceFamilyDateRange}
            pdfView={pdfView}
            columns={getPurchaseOptionWithGroupingValuesColumns(
              uniqBy(ec2RunningHoursCostByInstanceFamily, 'instanceFamily').map(
                (item) => item.instanceFamily
              )
            )}
            tableData={getUsageCostTableData(
              ec2RunningHoursCostByInstanceFamily,
              'instanceFamily'
            )}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case 'ec2-cpu-credits-usage-cost-by-accounts':
        return (
          <StackedColumnCharts
            title={t('graphHeadings.ec2CpuCreditsUsageCostByAccounts')}
            graph={graphName}
            data={ec2CpuCreditsUsageCostByAccounts}
            xField="time"
            seriesField="type"
            yTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[
              accountsReqStatus,
              ec2CpuCreditsUsageCostByAccountsReqStatus,
            ]}
            isTableView={ec2CpuCreditsUsageCostByAccountsTableView}
            setIsTableView={setEc2CpuCreditsUsageCostByAccountsTableView}
            dateRange={ec2CpuCreditsUsageCostByAccountsDateRange}
            setDateRange={setEc2CpuCreditsUsageCostByAccountsDateRange}
            pdfView={pdfView}
            columns={getMonthlyCostByAccountsColumns(
              ec2CpuCreditsUsageCostByAccountsDateRange,
              selectedDashboard!.connectorProvider
            )}
            tableData={getUsageCostTableData(
              ec2CpuCreditsUsageCostByAccounts,
              'time'
            )}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case 'top-lambda-accounts-by-cost':
        return (
          <StackedColumnLineWithUnitCostCharts
            title={t('graphHeadings.topLambdaAccountsByCost', {
              lambda: getLambdaLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              accounts: getAccountsLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              interpolation: { escapeValue: false },
            })}
            graph={graphName}
            data={topLambdaAccountsByCost}
            requestStatus={[
              accountsReqStatus,
              topLambdaAccountsByCostReqStatus,
            ]}
            isTableView={topLambdaAccountsByCostTableView}
            setIsTableView={setTopLambdaAccountsByCostTableView}
            dateRange={topLambdaAccountsByCostDateRange}
            setDateRange={setTopLambdaAccountsByCostDateRange}
            pdfView={pdfView}
            columns={getMonthlyCostByAccountsColumns(
              topLambdaAccountsByCostDateRange,
              selectedDashboard!.connectorProvider
            )}
            tableData={getUsageCostTableData(topLambdaAccountsByCost, 'time')}
            selectedAccounts={selectedAccounts}
            xField={'type'}
            unitCostTimePeriod={topLambdaAccountsByCostUnitCostTimePeriod}
            setUnitCostTimePeriod={setTopLambdaAccountsByCostUnitCostTimePeriod}
          />
        );

      case 'lambda-purchase-option':
        return (
          <StackedColumnCharts
            title={t('graphHeadings.lambdaByPurchaseOption', {
              lambda: getLambdaLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              interpolation: { escapeValue: false },
            })}
            graph={graphName}
            data={lambdaByPurchaseOption}
            xField="time"
            seriesField="type"
            yTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[accountsReqStatus, lambdaByPurchaseOptionReqStatus]}
            isTableView={lambdaByPurchaseOptionTableView}
            setIsTableView={setLambdaByPurchaseOptionTableView}
            dateRange={lambdaByPurchaseOptionDateRange}
            setDateRange={setLambdaByPurchaseOptionDateRange}
            pdfView={pdfView}
            columns={getPurchaseOptionWithGroupingValuesColumns(
              uniqBy(lambdaByPurchaseOption, 'time').map((item) => item.time)
            )}
            tableData={getUsageCostTableData(lambdaByPurchaseOption, 'time')}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case 'top-lambdas-by-resource-id':
        return (
          <StackedColumnCharts
            title={getTopLambdasByResourceIdGraphHeader(
              selectedDashboard!.connectorProvider
            )}
            graph={graphName}
            data={topLambdasByResourceId}
            xField="resourceId"
            seriesField="type"
            xTitle={t('computeDashboard.resourceId')}
            yTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[accountsReqStatus, topLambdasByResourceIdReqStatus]}
            isTableView={topLambdasByResourceIdTableView}
            setIsTableView={setTopLambdasByResourceIdTableView}
            dateRange={topLambdasByResourceIdDateRange}
            setDateRange={setTopLambdasByResourceIdDateRange}
            pdfView={pdfView}
            columns={getAccountWithGroupingValuesColumns(
              uniqBy(topLambdasByResourceId, 'resourceId').map(
                (item) => item.resourceId
              ),
              getAccountLabelByProvider(selectedDashboard!.connectorProvider)
            )}
            tableData={getUsageCostTableData(
              topLambdasByResourceId,
              'resourceId'
            )}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case 'top-lambdas-by-daily-cost':
        return (
          <StackedColumnCharts
            title={t('graphHeadings.topLambdasByDailyCost', {
              lambda: getLambdaLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              interpolation: { escapeValue: false },
            })}
            graph={graphName}
            data={topLambdasByDailyCost}
            xField="time"
            seriesField="type"
            xTitle={t('computeDashboard.date')}
            yTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[accountsReqStatus, topLambdasByDailyCostReqStatus]}
            isTableView={topLambdasByDailyCostTableView}
            setIsTableView={setTopLambdasByDailyCostTableView}
            dateRange={topLambdasByDailyCostDateRange}
            setDateRange={setTopLambdasByDailyCostDateRange}
            pdfView={pdfView}
            columns={getResourceIdWithDailyCostColumns(
              topLambdasByDailyCostDateRange
            )}
            tableData={getUsageCostTableData(topLambdasByDailyCost, 'time')}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
            maxMonths={3}
          />
        );

      case 'fargate-accounts-by-cost':
        return (
          <StackedColumnLineWithUnitCostCharts
            title={t('graphHeadings.fargateAccountsByCost', {
              fargate: getFargateLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
              accounts: getAccountsLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
            })}
            graph={graphName}
            data={fargateAccountsByCost}
            requestStatus={[accountsReqStatus, fargateAccountsByCostReqStatus]}
            isTableView={fargateAccountsByCostTableView}
            setIsTableView={setFargateAccountsByCostTableView}
            dateRange={fargateAccountsByCostDateRange}
            setDateRange={setFargateAccountsByCostDateRange}
            pdfView={pdfView}
            columns={getMonthlyCostByAccountsColumns(
              fargateAccountsByCostDateRange,
              selectedDashboard!.connectorProvider
            )}
            tableData={getUsageCostTableData(topLambdaAccountsByCost, 'time')}
            selectedAccounts={selectedAccounts}
            xField={'type'}
            unitCostTimePeriod={fargateAccountsByCostUnitCostTimePeriod}
            setUnitCostTimePeriod={setFargateAccountsByCostUnitCostTimePeriod}
          />
        );

      case 'fargate-cost-by-purchase-option':
        return (
          <StackedColumnCharts
            title={t('graphHeadings.fargateCostByPurchaseOption', {
              fargate: getFargateLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
            })}
            graph={graphName}
            data={fargateCostByPurchaseOption}
            xField="time"
            seriesField="type"
            yTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[accountsReqStatus, fargateCostByPOReqStatus]}
            isTableView={fargateCostByPOTableView}
            setIsTableView={setFargateCostByPOTableView}
            dateRange={fargateCostByPODateRange}
            setDateRange={setFargateCostByPODateRange}
            pdfView={pdfView}
            columns={getPurchaseOptionWithGroupingValuesColumns(
              getMonthYearShortList(
                fargateCostByPODateRange[0],
                fargateCostByPODateRange[1],
                MONTH_YEAR_FORMAT
              )
            )}
            tableData={getUsageCostTableData(
              fargateCostByPurchaseOption,
              'time'
            )}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case 'fargate-ecs-eks-control-panel-cost':
        return (
          <StackedHorizontalBarCharts
            title={t('graphHeadings.ecsEksFargateAndEksControlPanelCost')}
            graph={graphName}
            data={fargateEcsEksCost}
            yField="type"
            seriesField="operation"
            xTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            yTitle={t('computeDashboard.fargateCluster')}
            requestStatus={[accountsReqStatus, fargateEcsEksCostReqStatus]}
            isTableView={fargateEcsEksCostTableView}
            setIsTableView={setFargateEcsEksCostTableView}
            dateRange={fargateEcsEksCostDateRange}
            setDateRange={setFargateEcsEksCostDateRange}
            pdfView={pdfView}
            columns={getFargateClusterEksAndEcsCostColumns(
              uniqBy(fargateEcsEksCost, 'operation').map(
                (item) => item.operation
              )
            )}
            tableData={getUsageCostTableData(fargateEcsEksCost, 'operation')}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
          />
        );

      case 'fargate-top-clusters-by-daily-cost':
        return (
          <StackedColumnCharts
            title={t('graphHeadings.fargateTopClustersByDailyCost', {
              fargate: getFargateLabelByProvider(
                selectedDashboard!.connectorProvider
              ),
            })}
            graph={graphName}
            data={fargateTopClustersByDailyCost}
            xField="time"
            seriesField="type"
            xTitle={t('computeDashboard.date')}
            yTitle={t('computeDashboard.cost', {
              currencySymbol: currencySymbol,
            })}
            requestStatus={[
              accountsReqStatus,
              fargateTopClustersByDailyCostReqStatus,
            ]}
            isTableView={fargateTopClustersByDailyCostTableView}
            setIsTableView={setFargateTopClustersByDailyCostTableView}
            dateRange={fargateTopClustersByDailyCostDateRange}
            setDateRange={setFargateTopClustersByDailyCostDateRange}
            pdfView={pdfView}
            columns={getDailyCostByAccountsColumns(
              fargateTopClustersByDailyCostDateRange,
              selectedDashboard!.connectorProvider
            )}
            tableData={getUsageCostTableData(
              fargateTopClustersByDailyCost,
              'time'
            )}
            selectedAccounts={selectedAccounts}
            prefixSymbol={currencySymbol}
            maxMonths={3}
          />
        );
    }
  };

  return (
    <div>
      <ServiceViewsControls
        accountsProps={{
          selectedAccounts,
          setSelectedAccounts,
          accountsReqStatus,
          setAccountsReqStatus,
        }}
      />
      <div className="aws-compute-container inner-dashboard-content flex flex-column flex-gap-24">
        <TagFilters />
        <Row gutter={24}>
          <Col span={12}>
            {getGraphComponent('ec2-cost-by-purchase-option')}
          </Col>
          <Col span={12}>
            {getGraphComponent('ec2-coverage-by-purchase-option')}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>{getGraphComponent('ec2-usage-by-platform')}</Col>
          <Col span={12}>
            {getGraphComponent('ec2-running-hours-cost-spending-accounts')}
          </Col>
        </Row>
        {getGraphComponent('ec2-elasticity')}
        {getGraphComponent('ec2-cost-by-instance-family')}
        {getGraphComponent('ec2-running-hours-cost-instance-family')}
        {hasEc2CpuCreditsUsageCostByAccountsChart(
          selectedDashboard!.connectorProvider
        ) && getGraphComponent('ec2-cpu-credits-usage-cost-by-accounts')}
        <Row gutter={24}>
          <Col span={12}>
            {getGraphComponent('top-lambda-accounts-by-cost')}
          </Col>
          <Col span={12}>{getGraphComponent('lambda-purchase-option')}</Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>{getGraphComponent('top-lambdas-by-resource-id')}</Col>
          <Col span={12}>{getGraphComponent('top-lambdas-by-daily-cost')}</Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>{getGraphComponent('fargate-accounts-by-cost')}</Col>
          <Col span={12}>
            {getGraphComponent('fargate-cost-by-purchase-option')}
          </Col>
        </Row>
        {hasEcsEksFargateAndEksControlPanelCost(
          selectedDashboard!.connectorProvider
        ) ? (
          <Row gutter={24}>
            <Col span={12}>
              {getGraphComponent('fargate-ecs-eks-control-panel-cost')}
            </Col>
            <Col span={12}>
              {getGraphComponent('fargate-top-clusters-by-daily-cost')}
            </Col>
          </Row>
        ) : (
          getGraphComponent('fargate-top-clusters-by-daily-cost')
        )}
      </div>
      {showExpandGraphModal && (
        <ExpandModal graphContent={getGraphComponent(expandedGraphName)} />
      )}
      {pdfDownloadMode && (
        <PdfDownloadComponent
          pdfMetaData={getPdfMetaData(
            t('dashNav.compute'),
            selectedDashboard!,
            selectedConnection!
          )}
          pdfContent={[
            {
              element: getGraphComponent('ec2-cost-by-purchase-option', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'ec2-cost-by-purchase-option',
              column: modifyToExportColumns(
                getPurchaseOptionWithGroupingValuesColumns(
                  getMonthYearShortList(
                    ec2CostByPODateRange[0],
                    ec2CostByPODateRange[1],
                    MONTH_YEAR_FORMAT
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(ec2CostByPurchaseOption, 'time')
              ),
              tableName: t('graphHeadings.ec2ComputeCostByPurchaseOption', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider,
                  true
                ),
              }),
              isTableView: ec2CostByPOTableView,
            },
            {
              element: getGraphComponent(
                'ec2-coverage-by-purchase-option',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'ec2-coverage-by-purchase-option',
              column: modifyToExportColumns(
                getEc2CoverageByPurchaseOptionsColumns(ec2CoverageByPODateRange)
              ),
              body: insertIndex(
                getEc2UsagePercentageTableData(ec2CoverageByPurchaseOption)
              ),
              tableName: t('graphHeadings.ec2CoverageByPurchaseOption', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              isTableView: ec2CoverageByPOTableView,
            },
            {
              element: getGraphComponent('ec2-usage-by-platform', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'ec2-usage-by-platform',
              column: modifyToExportColumns(
                getEc2UsageByPlatformColumns(
                  ec2UsageByPlatformDateRange,
                  selectedDashboard!.connectorProvider
                )
              ),
              body: insertIndex(
                getEc2UsagePercentageTableData(ec2UsageByPlatform)
              ),
              tableName: t('graphHeadings.ec2ComputeCoverageByPlatform', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider,
                  true
                ),
                platform: getPlatformLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              isTableView: ec2UsageByPlatformTableView,
            },
            {
              element: getGraphComponent('ec2-elasticity', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'ec2-elasticity',
              column: modifyToExportColumns(
                getEc2ElasticityColumns(ec2ElasticityDateRange)
              ),
              body: insertIndex(getDualAxisCostByTypeTableData(ec2Elasticity)),
              tableName: t('graphHeadings.ec2Elasticity', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              isTableView: ec2ElasticityTableView,
            },
            {
              element: getGraphComponent(
                'ec2-running-hours-cost-spending-accounts',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'ec2-running-hours-cost-spending-accounts',
              column: modifyToExportColumns(
                getPurchaseOptionWithGroupingValuesColumns(
                  uniqBy(ec2RunningHoursCostByAccount, 'account').map(
                    (item) => item.account
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(ec2RunningHoursCostByAccount, 'account')
              ),
              tableName: t(
                'graphHeadings.ec2RunningHoursCostBySpendingAccount',
                {
                  ec2: getEc2LabelByProvider(
                    selectedDashboard!.connectorProvider
                  ),
                  account: getAccountLabelByProvider(
                    selectedDashboard!.connectorProvider
                  ),
                }
              ),
              isTableView: ec2RunningHoursCostByAccountTableView,
            },
            {
              element: getGraphComponent('ec2-cost-by-instance-family', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'ec2-cost-by-instance-family',
              column: modifyToExportColumns(
                getEc2CostByInstanceFamilyColumns(
                  ec2CostByInstanceFamilyDateRange,
                  selectedDashboard!.connectorProvider
                )
              ),
              body: insertIndex(
                getDualAxisCostByTypeTableData(ec2CostByInstanceFamily)
              ),
              tableName: t('graphHeadings.ec2DailyCostByInstanceFamily', {
                ec2: getEc2LabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                instanceFamily: getInstanceFamilyLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              isTableView: ec2CostByInstanceFamilyTableView,
            },
            {
              element: getGraphComponent(
                'ec2-running-hours-cost-instance-family',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'ec2-running-hours-cost-instance-family',
              column: modifyToExportColumns(
                getPurchaseOptionWithGroupingValuesColumns(
                  uniqBy(
                    ec2RunningHoursCostByInstanceFamily,
                    'instanceFamily'
                  ).map((item) => item.instanceFamily)
                )
              ),
              body: insertIndex(
                getUsageCostTableData(
                  ec2RunningHoursCostByInstanceFamily,
                  'instanceFamily'
                )
              ),
              tableName: t(
                'graphHeadings.ec2RunningHoursCostByInstanceFamily',
                {
                  ec2: getEc2LabelByProvider(
                    selectedDashboard!.connectorProvider
                  ),
                  instanceFamily: getInstanceFamilyLabelByProvider(
                    selectedDashboard!.connectorProvider
                  ),
                }
              ),
              isTableView: ec2RunningHoursCostByInstanceFamilyTableView,
            },
            {
              element: getGraphComponent(
                'ec2-cpu-credits-usage-cost-by-accounts',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'ec2-cpu-credits-usage-cost-by-accounts',
              column: modifyToExportColumns(
                getMonthlyCostByAccountsColumns(
                  ec2CpuCreditsUsageCostByAccountsDateRange,
                  selectedDashboard!.connectorProvider
                )
              ),
              body: insertIndex(
                getUsageCostTableData(ec2CpuCreditsUsageCostByAccounts, 'time')
              ),
              excludeInGlobalDownload:
                !hasEc2CpuCreditsUsageCostByAccountsChart(
                  selectedDashboard!.connectorProvider
                ),
              tableName: t('graphHeadings.ec2CpuCreditsUsageCostByAccounts'),
              isTableView: ec2CpuCreditsUsageCostByAccountsTableView,
            },
            {
              element: getGraphComponent('top-lambda-accounts-by-cost', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'top-lambda-accounts-by-cost',
              column: modifyToExportColumns(
                getMonthlyCostByAccountsColumns(
                  topLambdaAccountsByCostDateRange,
                  selectedDashboard!.connectorProvider
                )
              ),
              body: insertIndex(
                getUsageCostTableData(topLambdaAccountsByCost, 'time')
              ),
              tableName: t('graphHeadings.topLambdaAccountsByCost', {
                lambda: getLambdaLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                accounts: getAccountsLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                interpolation: { escapeValue: false },
              }),
              isTableView: topLambdaAccountsByCostTableView,
            },
            {
              element: getGraphComponent('lambda-purchase-option', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'lambda-purchase-option',
              column: modifyToExportColumns(
                getPurchaseOptionWithGroupingValuesColumns(
                  uniqBy(lambdaByPurchaseOption, 'time').map(
                    (item) => item.time
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(lambdaByPurchaseOption, 'time')
              ),
              tableName: t('graphHeadings.lambdaPurchaseOption'),
              isTableView: lambdaByPurchaseOptionTableView,
            },
            {
              element: getGraphComponent('top-lambdas-by-resource-id', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'top-lambdas-by-resource-id',
              column: modifyToExportColumns(
                getAccountWithGroupingValuesColumns(
                  uniqBy(topLambdasByResourceId, 'resourceId').map(
                    (item) => item.resourceId
                  ),
                  getAccountLabelByProvider(
                    selectedDashboard!.connectorProvider
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(topLambdasByResourceId, 'resourceId')
              ),
              tableName: getTopLambdasByResourceIdGraphHeader(
                selectedDashboard!.connectorProvider
              ),
              isTableView: topLambdasByResourceIdTableView,
            },
            {
              element: getGraphComponent('top-lambdas-by-daily-cost', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'top-lambdas-by-daily-cost',
              column: modifyToExportColumns(
                getResourceIdWithDailyCostColumns(
                  topLambdasByDailyCostDateRange
                )
              ),
              body: insertIndex(
                getUsageCostTableData(topLambdasByDailyCost, 'time')
              ),
              tableName: t('graphHeadings.topLambdasByDailyCost', {
                lambda: getLambdaLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                interpolation: { escapeValue: false },
              }),
              isTableView: topLambdasByDailyCostTableView,
            },
            {
              element: getGraphComponent('fargate-accounts-by-cost', true),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'fargate-accounts-by-cost',
              column: modifyToExportColumns(
                getMonthlyCostByAccountsColumns(
                  fargateAccountsByCostDateRange,
                  selectedDashboard!.connectorProvider
                )
              ),
              body: insertIndex(
                getUsageCostTableData(fargateAccountsByCost, 'time')
              ),
              tableName: t('graphHeadings.fargateAccountsByCost', {
                fargate: getFargateLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
                accounts: getAccountsLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              isTableView: fargateAccountsByCostTableView,
            },
            {
              element: getGraphComponent(
                'fargate-cost-by-purchase-option',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'fargate-cost-by-purchase-option',
              column: modifyToExportColumns(
                getPurchaseOptionWithGroupingValuesColumns(
                  getMonthYearShortList(
                    fargateCostByPODateRange[0],
                    fargateCostByPODateRange[1],
                    MONTH_YEAR_FORMAT
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(fargateCostByPurchaseOption, 'time')
              ),
              tableName: t('graphHeadings.fargateCostByPurchaseOption', {
                fargate: getFargateLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              isTableView: fargateCostByPOTableView,
            },
            {
              element: getGraphComponent(
                'fargate-ecs-eks-control-panel-cost',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'fargate-ecs-eks-control-panel-cost',
              excludeInGlobalDownload: !hasEcsEksFargateAndEksControlPanelCost(
                selectedDashboard!.connectorProvider
              ),
              column: modifyToExportColumns(
                getFargateClusterEksAndEcsCostColumns(
                  uniqBy(fargateEcsEksCost, 'operation').map(
                    (item) => item.operation
                  )
                )
              ),
              body: insertIndex(
                getUsageCostTableData(fargateEcsEksCost, 'operation')
              ),
              tableName: t('graphHeadings.ecsEksFargateAndEksControlPanelCost'),
              isTableView: fargateEcsEksCostTableView,
            },
            {
              element: getGraphComponent(
                'fargate-top-clusters-by-daily-cost',
                true
              ),
              contentType: CHART_TYPES.BAR_CHART,
              graphName: 'fargate-top-clusters-by-daily-cost',
              column: modifyToExportColumns(
                getDailyCostByAccountsColumns(
                  fargateTopClustersByDailyCostDateRange,
                  selectedDashboard!.connectorProvider
                )
              ),
              body: insertIndex(
                getUsageCostTableData(fargateTopClustersByDailyCost, 'time')
              ),
              excludeInGlobalDownload: hasEcsEksFargateAndEksControlPanelCost(
                selectedDashboard!.connectorProvider
              ),
              tableName: t('graphHeadings.fargateTopClustersByDailyCost', {
                fargate: getFargateLabelByProvider(
                  selectedDashboard!.connectorProvider
                ),
              }),
              isTableView: fargateTopClustersByDailyCostTableView,
            },
          ]}
        />
      )}
    </div>
  );
};

export default ComputeDashboard;
