import i18n from 'i18n';
import { addIndexAndCommaSeparator } from 'pages/CostOptimizationInsightsPage/utils';
import { SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP } from 'pages/CostOptimizationInsightsPage/constants';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { CategoryCostDetailsType } from './types';

/**
 * @function getSummaryRequestBody
 * @description Function to return the request body for the cost summary
 * @param resources resource(s) for which the data is fetched
 * @param status recommendation status(s)
 * @param selectedSavingsType savings type selected on the chart
 * @returns Object containing the request body
 */
export const getSummaryRequestBody = (
  resources: string[],
  status: string[]
) => {
  let filters = [
    {
      field: QUERY_FIELDS.DATE_UPLOADED_TIMESTAMP,
      conjunctToNextFilter: CONJUNCTIONS.AND,
      comparator: COMPARATORS.EQUALS,
      value: QUERY_VALUES.SELECT_MAX_DATE_UPLOADED_TIMESTAMP,
    },
    {
      field: QUERY_FIELDS.STATE,
      conjunctToNextFilter: CONJUNCTIONS.AND,
      comparator: COMPARATORS.IN,
      value: '(' + status.map((item) => "'" + item + "'").join(',') + ')',
    },
    {
      field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
      comparator: COMPARATORS.IN,
      value: '(' + resources.map((item) => "'" + item + "'").join(',') + ')',
      conjunctToNextFilter: CONJUNCTIONS.AND,
    },
    {
      field: QUERY_FIELDS.PROPERTIES_CATEGORY,
      conjunctToNextFilter: CONJUNCTIONS.AND,
      comparator: COMPARATORS.EQUALS,
      value: QUERY_VALUES.NORMAL_CASE_COST,
    },
  ];

  return {
    columns: [
      {
        label: 'totalAmount',
        field: QUERY_FIELDS.EXTENDED_PROPERTIES_SAVINGS_AMOUNT_INTEGER,
      },
    ],
    subQuery: {
      columns: [
        {
          label: '',
          field: 'distinct *',
        },
      ],
      filterGroups: [
        {
          filters: filters,
        },
      ],
    },
    aggregators: [
      {
        label: 'Total Savings',
        function: AGGREGATORS.SUM,
        field: QUERY_FIELDS.PROPERTIES_SAVINGS,
        dataType: QUERY_VALUES.INTEGER,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getCostSavingsTrendRequestBody
 * @description Function to return the request body for the cost savings trend
 * @param resources resource(s) for which the data is fetched
 * @param status recommendation status(s)
 * @returns Object containing the request body
 */
export const getCostSavingsTrendRequestBody = (
  resources: string[],
  status: string[]
) => {
  return {
    columns: [
      {
        label: 'totalCost',
        field: QUERY_FIELDS.EXTENDED_PROPERTIES_SAVINGS_AMOUNT_FLOAT,
      },
      {
        label: 'row',
        field: QUERY_FIELDS.LAST_DAY_OF_MONTH_VALUE_DESC,
      },
      {
        label: 'month',
        field: QUERY_FIELDS.LAST_DAY_OF_MONTH,
      },
    ],
    subQuery: {
      columns: [
        {
          label: QUERY_FIELDS.LAST_DAY_OF_MONTH,
          field: QUERY_FIELDS.EOMONTH_CAST_DATE_UPLOADED_TIMESTAMP,
        },
        {
          field: QUERY_FIELDS.PROPERTIES_SAVINGS,
        },
      ],
      filterGroups: [
        {
          filters: [
            {
              label: 'uploadDate',
              field: QUERY_FIELDS.DATE_UPLOADED_TIMESTAMP_DATE,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_FIELDS.EOMONTH_DATE_UPLOADED_TIMESTAMP,
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
              comparator: COMPARATORS.IN,
              value:
                '(' + resources.map((item) => "'" + item + "'").join(',') + ')',
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.STATE,
              conjunctToNextFilter: CONJUNCTIONS.AND,
              comparator: COMPARATORS.IN,
              value:
                '(' + status.map((item) => "'" + item + "'").join(',') + ')',
            },
            {
              field: QUERY_FIELDS.PROPERTIES_CATEGORY,
              conjunctToNextFilter: CONJUNCTIONS.AND,
              comparator: COMPARATORS.EQUALS,
              value: QUERY_VALUES.NORMAL_CASE_COST,
            },
          ],
        },
      ],
    },
    groupBy: ['month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getCategoryCostSavingsRequestBody
 * @description Function to return the request body for the category resource list data
 * @param resources resource(s) for which the data is fetched
 * @param status recommendation status(s)
 * @returns Object containing the request body
 */
export const getCategoryCostSavingsRequestBody = (
  resources: string[],
  status: string[]
) => {
  return {
    columns: [
      {
        label: 'recommendationId',
        field: QUERY_FIELDS.NAME,
      },
      {
        label: 'recommendationProblem',
        field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
      },
      {
        label: 'costSavings',
        field: QUERY_FIELDS.PROPERTIES_SAVINGS,
      },
      {
        label: 'impactedField',
        field: QUERY_FIELDS.PROPERTIES_IMPACTED_FIELD,
      },
      {
        label: 'recommendationState',
        field: QUERY_FIELDS.STATE,
      },
      {
        label: 'recommendationCategory',
        field: QUERY_FIELDS.PROPERTIES_CATEGORY,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.SEQUENCE_NUMBER,
          field: QUERY_VALUES.AZURE_SEQUENCE_NUMBER_VALUE_DESC,
        },
      ],
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.PROPERTIES_SHORTDESCRIPTION_PROBLEM,
            comparator: COMPARATORS.IN,
            value:
              '(' + resources.map((item) => "'" + item + "'").join(',') + ')',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.STATE,
            conjunctToNextFilter: CONJUNCTIONS.AND,
            comparator: COMPARATORS.IN,
            value: '(' + status.map((item) => "'" + item + "'").join(',') + ')',
          },
          {
            field: QUERY_FIELDS.PROPERTIES_CATEGORY,
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.NORMAL_CASE_COST,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          // {
          //   field: 'properties_lastUpdated',
          //   comparator: '=',
          //   value: 'DATE_SUB(CURRENT_TIMESTAMP(), INTERVAL 1 DAY)',
          //   conjunctToNextFilter: 'AND',
          // },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function getResourceDetailsRequestBody
 * @description Function to return the request body for returning the resource details
 * @param recommendationId recommendation id
 * @returns Object containing the request body
 */
export const getResourceDetailsRequestBody = (recommendationId: string) => {
  return {
    columns: [
      {
        label: 'id',
        field: 'id',
      },
      {
        label: 'impactedField',
        field: QUERY_FIELDS.PROPERTIES_IMPACTED_FIELD,
      },
      {
        label: 'impactedValue',
        field: QUERY_FIELDS.PROPERTIES_IMPACTED_VALUE,
      },
      {
        label: 'resourceMetadata',
        field: QUERY_FIELDS.PROPERTIES_RESOURCE_METADATA_RESOURCE_ID,
      },
    ],
    subQuery: {
      columns: [
        {
          field: '*',
        },
        {
          label: QUERY_FIELDS.SEQUENCE_NUMBER,
          field: QUERY_VALUES.AZURE_SEQUENCE_NUMBER_VALUE_DESC,
        },
      ],
    },
    filterGroups: [
      {
        filters: [
          {
            field: QUERY_FIELDS.NAME,
            comparator: COMPARATORS.EQUALS,
            value: recommendationId,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.SEQUENCE_NUMBER,
            comparator: COMPARATORS.EQUALS,
            value: '#1',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: QUERY_FIELDS.PROPERTIES_CATEGORY,
            comparator: COMPARATORS.EQUALS,
            value: QUERY_VALUES.NORMAL_CASE_COST,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          // {
          //   field: 'properties_lastUpdated',
          //   comparator: '=',
          //   value: 'DATE_SUB(CURRENT_TIMESTAMP(), INTERVAL 1 DAY)',
          //   conjunctToNextFilter: 'AND',
          // },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.RECOMMENDATIONS,
    cached: true,
  };
};

/**
 * @function fetchCategoryCostSavingsExcelExportColumns
 * @description Function to get the resources table excel columns
 * @param selectedSavingsType savings type selected in summary graph
 * @returns List of excel columns
 */
export const fetchCategoryCostSavingsExcelExportColumns = (
  selectedSavingsType: string | undefined
) => {
  return [
    {
      header: '#',
      key: 'slNo',
      dataKey: 'slNo',
    },
    {
      header: i18n.t(
        'costOptimizationInsight.recommendationTable.recommendationId'
      ),
      key: 'recommendationId',
      dataKey: 'recommendationId',
    },
    {
      header: i18n.t(
        'costOptimizationInsight.recommendationTable.recommendationSolution'
      ),
      key: 'recommendationSolution',
      dataKey: 'recommendationSolution',
    },
    {
      header: i18n.t(
        'costOptimizationInsight.recommendationTable.impactedField'
      ),
      key: 'impactedField',
      dataKey: 'impactedField',
    },
    {
      header: SAVINGS_TYPE_TO_RECOMMENDATION_STATUS_MAP.find(
        (item) => item.type === selectedSavingsType
      )?.label,
      key: 'costSavings',
      dataKey: 'costSavings',
    },
    {
      header: i18n.t('costOptimizationInsight.recommendationTable.recStatus'),
      key: 'recStatus',
      dataKey: 'recStatus',
    },
    {
      header: i18n.t('costOptimizationInsight.recommendationTable.category'),
      key: 'recommendationCategory',
      dataKey: 'recommendationCategory',
    },
  ];
};

/**
 * @function getCostSavingsSummaryExcelData
 * @description Function to get the cost savings summary excel data
 * @param connectorName Name of the connection selected
 * @param tableHeading Table heading
 * @param data excel data
 * @param selectedSavingsType savings type selected in summary graph
 * @returns List of excel file configartions and data
 */
export const getCostSavingsSummaryExcelData = (
  connectorName: string,
  tableHeading: string,
  data: CategoryCostDetailsType[],
  selectedSavingsType: string | undefined
) => {
  return {
    sheetName: tableHeading,
    columns: fetchCategoryCostSavingsExcelExportColumns(selectedSavingsType),
    data: addIndexAndCommaSeparator(data),
    filters: [
      {
        heading: i18n.t('excelExportLabels.connectionName'),
        value: connectorName,
      },
    ],
  };
};
