import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SuccessIcon } from 'assets/icons';
import ErrorComponent from 'components/ErrorComponent';
import SuccessComponent from 'components/SuccessComponent';
import FormFooter from 'components/FormFooter';
import ProgressBar from 'components/ProgressBar';
import NavigationPath from 'components/NavigationPath';
import ProviderList from 'components/ConnectionProviderList';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { PROVIDER } from 'constants/cloudProviders';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import { REQUEST_STATUS } from 'constants/requestBody';
import {
  BudgetsAndAlertsComponents,
  DEFAULT_BUDGET_DATA,
} from 'pages/BudgetsAndAlertsPage/constants';
import {
  createBudgetAWS,
  createBudgetAzure,
  createBudgetOci,
  updateAzureBudget,
  updateOciBudget,
} from 'pages/BudgetsAndAlertsPage/services';
import { providerList } from 'redux/providerSlice';
import {
  budgetsAndAlerts,
  setBudgetData,
  setBudgetsAlertsComponent,
  setIsEdit,
} from 'redux/budgetsAndAlertsSlice';
import { setNavSubPath } from 'redux/activeNavMenuSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import { validateAlphanumericNames } from 'utils/validations';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { createBudget, updateAwsBudget, updateGcpBudget } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

import SetupBudgetFormGCP from './GCP/SetupBudgetsDetails';
import SetupBudgetFormAWS from './AWS/SetupBudgetDetails';
import SetupBudgetFormAzure from './Azure/SetupBudgetDetails';
import SetupBudgetFormOCI from './OCI/SetupBudgetDetails';
import { OCI_BUDGET_PERIOD_TYPE } from './OCI/SetupBudgetDetails/constants';
import { PROGRESS_BAR_ITEMS } from './constants';
import {
  BILLING_ACCOUNT_SLICE_END,
  BILLING_ACCOUNT_SLICE_START,
  BUDGET_DATE_RANGES,
  BUDGET_PERCENTAGE_MAX_LIMIT,
} from './GCP/SetupBudgetsDetails/constants';

import './index.scss';

const CreateBudgetAlertConnection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedProvider } = useSelector(providerList);
  const { budgetsAlertsComponent, budgetData, isEdit } =
    useSelector(budgetsAndAlerts);
  const [currentStep, setCurrentStep] = useState(isEdit ? 2 : 0);
  const [formSubmitRequestStatus, setFormSubmitRequestStatus] = useState('');
  const [isBudgetConnectionError, setIsBudgetConnectionError] = useState(true);
  const [isDuplicateAzureBudget, setIsDuplicateAzureBudget] = useState(false);

  useEffect(() => {
    addZeroMarginClass();
    dispatch(
      setNavSubPath([
        isEdit
          ? t('addBudgetAlert.createBudgetLabels.editBudget')
          : t('addBudgetAlert.createBudgetLabels.createBudget'),
      ])
    );

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function onCreateBudgetSuccess
   * @description Function that handles when budget is successfully created
   */
  const onCreateBudgetSuccess = () => {
    dispatch(
      setBudgetsAlertsComponent(BudgetsAndAlertsComponents.SUCCESS_PAGE)
    );
    setFormSubmitRequestStatus(REQUEST_STATUS.SUCCESS);
  };

  /**
   * @function onCreateBudgetError
   * @description Function that handles when budget creation / edit faces error
   * @param e The error that occurred
   */
  const onCreateBudgetError = (e: any) => {
    onApiCallError(e, false, setFormSubmitRequestStatus);
    dispatch(setBudgetsAlertsComponent(BudgetsAndAlertsComponents.ERROR_PAGE));
    dispatch(
      setErrorMessage(
        e?.response?.data?.message ?? t('connectionCSPErrorPage.subHeader')
      )
    );
  };

  /**
   * @function createUpdateGcpBudget
   * @description Function that handles the create budget / edit budget for GCP
   */
  const createUpdateGcpBudget = () => {
    const requestBody = {
      ...budgetData,
      from:
        budgetData.timeRange === BUDGET_DATE_RANGES.CUSTOM_PERIOD
          ? budgetData.from
          : null,
      to:
        budgetData.timeRange === BUDGET_DATE_RANGES.CUSTOM_PERIOD
          ? budgetData.to
          : null,
    };
    setFormSubmitRequestStatus(REQUEST_STATUS.PROCESSING);
    if (isEdit) {
      const updateBody = {
        ...requestBody,
        billingAccount: budgetData.name?.slice(
          BILLING_ACCOUNT_SLICE_START,
          BILLING_ACCOUNT_SLICE_END
        ),
        name: budgetData.name,
      };
      const updateParamBody = {
        connectorId: budgetData.connectorId,
      };
      updateGcpBudget(updateBody, updateParamBody)
        .then(onCreateBudgetSuccess)
        .catch(onCreateBudgetError);
      return;
    }

    createBudget(budgetData.connectorId, requestBody)
      .then(onCreateBudgetSuccess)
      .catch(onCreateBudgetError);
  };

  /**
   * @function createUpdateAwsBudget
   * @description Function that handles the create budget / edit budget for AWS
   */
  const createUpdateAwsBudget = async () => {
    const requestBody = {
      ...budgetData,
      name: budgetData.displayName,
      awsTimeRange: budgetData.timeRange,
      startMonth:
        budgetData.selectedYear +
        moment().month(budgetData.selectedMonth).format('MM'),
      budgetingMethod: budgetData.budgetType,
    };
    setFormSubmitRequestStatus(REQUEST_STATUS.PROCESSING);
    (isEdit ? updateAwsBudget : createBudgetAWS)(requestBody)
      .then(onCreateBudgetSuccess)
      .catch(onCreateBudgetError);
  };

  /**
   * @function createUpdateAzureBudget
   * @description Function that handles the create budget / edit budget for Azure
   */
  const createUpdateAzureBudget = async () => {
    const requestBody = {
      ...budgetData,
      name: budgetData.displayName,
      azureTimeRange: budgetData.timeRange,
    };
    setFormSubmitRequestStatus(REQUEST_STATUS.PROCESSING);
    if (isEdit) {
      const updateBody = {
        ...requestBody,
        connectorId: budgetData.connectorId,
      };
      updateAzureBudget(updateBody)
        .then(onCreateBudgetSuccess)
        .catch(onCreateBudgetError);
      return;
    }

    createBudgetAzure(requestBody)
      .then(onCreateBudgetSuccess)
      .catch(onCreateBudgetError);
  };

  /**
   * @function createUpdateOciBudget
   * @description Function that handles the create budget / edit budget for OCI
   */
  const createUpdateOciBudget = async () => {
    const requestBody = {
      ...budgetData,
      budgetDisplayName: budgetData.displayName,
      targetCompartmentId: budgetData.targetCompartment,
      targets: [budgetData.targetCompartment],
      budget: budgetData.amount,
      periodType: OCI_BUDGET_PERIOD_TYPE,
    };
    setFormSubmitRequestStatus(REQUEST_STATUS.PROCESSING);
    if (isEdit) {
      const updateBody = {
        ...requestBody,
        budgetId: budgetData.budgetId,
        compartmentId: budgetData.compartmentId,
      };
      updateOciBudget(updateBody)
        .then(onCreateBudgetSuccess)
        .catch(onCreateBudgetError);
      return;
    }

    createBudgetOci(requestBody)
      .then(onCreateBudgetSuccess)
      .catch(onCreateBudgetError);
  };

  /**
   * @function onSubmit
   * @description function to submit the form for creating budget / editing budget
   */
  const onSubmit = async () => {
    switch (selectedProvider) {
      case PROVIDER.GCP:
        return createUpdateGcpBudget();
      case PROVIDER.AWS:
        return createUpdateAwsBudget();
      case PROVIDER.AZURE:
        return createUpdateAzureBudget();
      case PROVIDER.OCI:
        return createUpdateOciBudget();
    }
  };

  /**
   * @function getSetupBudgetsDetailsFormComponent
   * @description Function to get the setup budget details form component according to the provider selected
   */
  const getSetupBudgetsDetailsFormComponent = () => {
    switch (selectedProvider) {
      case PROVIDER.GCP:
        return (
          <SetupBudgetFormGCP
            currentStep={currentStep}
            isBudgetConnectionError={isBudgetConnectionError}
            setIsBudgetConnectionError={setIsBudgetConnectionError}
          />
        );

      case PROVIDER.AWS:
        return (
          <SetupBudgetFormAWS
            currentStep={currentStep}
            isBudgetConnectionError={isBudgetConnectionError}
            setIsBudgetConnectionError={setIsBudgetConnectionError}
          />
        );

      case PROVIDER.AZURE:
        return (
          <SetupBudgetFormAzure
            currentStep={currentStep}
            isBudgetConnectionError={isBudgetConnectionError}
            setIsBudgetConnectionError={setIsBudgetConnectionError}
            setIsDuplicateAzureBudget={setIsDuplicateAzureBudget}
          />
        );

      case PROVIDER.OCI:
        return (
          <SetupBudgetFormOCI
            currentStep={currentStep}
            isBudgetConnectionError={isBudgetConnectionError}
            setIsBudgetConnectionError={setIsBudgetConnectionError}
          />
        );
    }
  };

  /**
   * @function validateScopeFields
   * @description Function to validate the scope fields for different providers
   * @returns returns true if all the fields are valid
   */
  const validateScopeFields = () => {
    if (selectedProvider === PROVIDER.GCP) {
      return (
        budgetData.displayName &&
        budgetData.displayName.length < MAX_CHARACTER_LIMIT &&
        validateAlphanumericNames(budgetData.displayName) &&
        budgetData.timeRange &&
        budgetData.projects.length > 0 &&
        budgetData.services.length > 0 &&
        (budgetData.timeRange !== BUDGET_DATE_RANGES.CUSTOM_PERIOD ||
          (budgetData.from && budgetData.to))
      );
    } else if (selectedProvider === PROVIDER.AWS) {
      return (
        budgetData.displayName &&
        budgetData.displayName.length < MAX_CHARACTER_LIMIT &&
        validateAlphanumericNames(budgetData.displayName) &&
        budgetData.timeRange &&
        budgetData.services.length > 0 &&
        moment().isBefore(
          `${budgetData.selectedYear}${budgetData.selectedMonth}`
        )
      );
    } else if (selectedProvider === PROVIDER.AZURE) {
      return (
        !isDuplicateAzureBudget &&
        budgetData.displayName.length > 0 &&
        budgetData.displayName.length < MAX_CHARACTER_LIMIT &&
        validateAlphanumericNames(budgetData.displayName) &&
        budgetData.timeRange &&
        budgetData.services.length > 0 &&
        budgetData.startDateTime &&
        budgetData.endDateTime
      );
    } else if (selectedProvider === PROVIDER.OCI) {
      return (
        budgetData.displayName &&
        budgetData.displayName.length < MAX_CHARACTER_LIMIT &&
        budgetData.description &&
        budgetData.targetCompartment &&
        budgetData.budgetProcessingStartDay > 0
      );
    }
    return true;
  };

  /**
   * @function isNextButtonDisabled
   * @description function to check if next button should be disabled
   */
  const isNextButtonDisabled = () => {
    switch (currentStep) {
      case 0:
        return !selectedProvider;

      case 1:
        return (
          !budgetData.connectorId ||
          (selectedProvider === PROVIDER.GCP && !budgetData.billingAccount) ||
          isBudgetConnectionError
        );

      case 2:
        return !validateScopeFields();

      case 3:
        return budgetData.amount <= 0;

      case 4:
        return !(
          !budgetData.thresholdRules.some((item, index) =>
            budgetData.thresholdRules.some(
              (item2, index2) =>
                item.percentage === item2.percentage &&
                item.triggerOn === item2.triggerOn &&
                index !== index2
            )
          ) &&
          budgetData.thresholdRules.length > 0 &&
          budgetData.thresholdRules.findIndex(
            (budget) =>
              budget.percentage <= 0 ||
              budget.percentage > BUDGET_PERCENTAGE_MAX_LIMIT
          ) === -1
        );

      case 5:
        return budgetData.notificationEmails.length === 0;
    }
  };

  /**
   * @function getNextButtonTitle
   * @returns next button title
   */
  const getNextButtonTitle = () => {
    if (currentStep < PROGRESS_BAR_ITEMS.length - 1)
      return t('addBudgetAlert.createBudgetLabels.next');
    return isEdit
      ? t('addBudgetAlert.createBudgetLabels.update')
      : t('addBudgetAlert.createBudgetLabels.submit');
  };

  /**
   * @function getComponent
   * @description Function to get the create budget component
   */
  const getComponent = () => {
    switch (budgetsAlertsComponent) {
      case BudgetsAndAlertsComponents.BUDGETS_ALERTS_CONNECTION_FORM:
        return (
          <div className="budget-form flex">
            <ProgressBar
              items={PROGRESS_BAR_ITEMS}
              current={currentStep}
              additionalClassNames="progress-bar new-styled-scroll width-40"
              heading={
                isEdit
                  ? t('addBudgetAlert.createBudgetLabels.editBudget')
                  : t('addBudgetAlert.createBudgetLabels.createBudget')
              }
              subHeading={t(
                'addBudgetAlert.createBudgetLabels.setupBudgetSubHeading',
                {
                  stepCount: PROGRESS_BAR_ITEMS.length,
                }
              )}
            />
            <div className="width-60 flex flex-column flex-space-between flex-fit">
              <div className="form-content new-styled-scroll">
                {currentStep === 0 ? (
                  <ProviderList
                    heading={PROGRESS_BAR_ITEMS[currentStep].title}
                    subHeading={PROGRESS_BAR_ITEMS[currentStep].description}
                    onClickProvider={() =>
                      dispatch(setBudgetData(DEFAULT_BUDGET_DATA))
                    }
                  />
                ) : (
                  <div className="flex flex-column flex-gap-24">
                    <div className="flex flex-column flex-gap-8 flex-center">
                      <span className="modal-heading">
                        {PROGRESS_BAR_ITEMS[currentStep].title}
                      </span>
                      <span className="table-typography">
                        {PROGRESS_BAR_ITEMS[currentStep].description}
                      </span>
                    </div>
                    {getSetupBudgetsDetailsFormComponent()}
                  </div>
                )}
              </div>
              <FormFooter
                showPreviousButton={isEdit ? currentStep > 2 : currentStep > 0}
                onClickNext={() => {
                  if (currentStep < 5) {
                    return setCurrentStep(currentStep + 1);
                  }
                  onSubmit();
                }}
                nextButtonTitle={getNextButtonTitle()}
                isNextDisabled={isNextButtonDisabled()}
                onClickPrevious={() => setCurrentStep(currentStep - 1)}
                submitRequestStatus={formSubmitRequestStatus}
                onClickCancel={() =>
                  navigate(NAVIGATION_MENU_PATH.BUDGETS_AND_ALERTS)
                }
              />
            </div>
          </div>
        );

      case BudgetsAndAlertsComponents.SUCCESS_PAGE:
        return (
          <SuccessComponent
            mainTitle={t('connectionCSPSuccessPage.header')}
            subTitle={t('connectionCSPSuccessPage.subHeader')}
            buttonTitle={t(
              'addBudgetAlert.successPageLabels.gotoBudgetsAndAlerts'
            )}
            linkTitle={t('addBudgetAlert.successPageLabels.createNewBudget')}
            onHandleButtonClick={() =>
              navigate(NAVIGATION_MENU_PATH.BUDGETS_AND_ALERTS)
            }
            onHandleLinkClick={() => {
              dispatch(
                setBudgetsAlertsComponent(
                  BudgetsAndAlertsComponents.BUDGETS_ALERTS_CONNECTION_FORM
                )
              );
              dispatch(setBudgetData(DEFAULT_BUDGET_DATA));
              dispatch(setIsEdit(false));
              setCurrentStep(0);
            }}
            icon={<SuccessIcon />}
          />
        );

      case BudgetsAndAlertsComponents.ERROR_PAGE:
        return (
          <ErrorComponent
            mainTitle={t('connectionCSPErrorPage.header')}
            buttonTitle={t('addBudgetAlert.errorPageLabels.tryAgainButton')}
            linkTitle={t('addBudgetAlert.errorPageLabels.goToBudgetsAndAlerts')}
            onHandleButtonClick={() => {
              if (isEdit) setCurrentStep(2);
              dispatch(
                setBudgetsAlertsComponent(
                  BudgetsAndAlertsComponents.BUDGETS_ALERTS_CONNECTION_FORM
                )
              );
            }}
            onHandleLinkClick={() =>
              navigate(NAVIGATION_MENU_PATH.BUDGETS_AND_ALERTS)
            }
          />
        );
    }
  };

  return (
    <div className="budgets-form-page flex flex-column flex-fit">
      <header className="new-page-header">
        <div className="title-container flex flex-align-items-center flex-space-between">
          <span className="modal-heading">
            {isEdit
              ? t('addBudgetAlert.createBudgetLabels.editBudget')
              : t('addBudgetAlert.createBudgetLabels.createBudget')}
          </span>
        </div>
        <NavigationPath />
      </header>
      <section className="page-content flex flex-column flex-fit">
        {getComponent()}
      </section>
    </div>
  );
};

export default CreateBudgetAlertConnection;
