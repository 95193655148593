import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import { REQUEST_STATUS } from 'constants/requestBody';
import { SnowflakeIntegrationType } from 'pages/IntegrationsPage/types';
import {
  createSnowflakeIntegration,
  updateSnowflakeIntegration,
} from 'pages/IntegrationsPage/services';
import { IntegrationsPageComponent } from 'pages/IntegrationsPage/constants';
import {
  getValidationStyle,
  validateByRegex,
  validateEmptyField,
} from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';

import { defaultSnowflakeFormValues } from '../../constants';

type SnowflakeAccessProps = {
  clickedSubmit: boolean;
  setClickedSubmit: (val: boolean) => void;
  setFormSubmitRequestStatus: (val: string) => void;
  setIntegrationPageComponent: Function;
  editSnowflakeData?: SnowflakeIntegrationType;
};

const SnowflakeAccess = ({
  clickedSubmit,
  setClickedSubmit,
  setFormSubmitRequestStatus,
  setIntegrationPageComponent,
  editSnowflakeData,
}: SnowflakeAccessProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'integrations.snowflake',
  });

  const [formData, setFormData] = useState<SnowflakeIntegrationType>(
    editSnowflakeData ?? defaultSnowflakeFormValues
  );
  const [nameValidation, setNameValidation] = useState('');
  const [serverUrlValidation, setServerUrlValidation] = useState('');
  const [usernameValidation, setUsernameValidation] = useState('');
  const [passFieldValidation, setPassFieldValidation] = useState('');
  const [databaseValidation, setDatabaseValidation] = useState('');
  const [schemaValidation, setSchemaValidation] = useState('');

  useEffect(() => {
    clickedSubmit && onClickSubmit();
    setClickedSubmit(false);
  }, [clickedSubmit]);

  /**
   * @function validateName
   * @description Validates the name field
   * @param value name value
   * @param showErrorMessage boolean to show error message or not
   * @returns boolean to indicate if the field is valid or not
   */
  const validateName = (value: string, showErrorMessage = false) => {
    if (
      validateEmptyField(value, t('name'), setNameValidation, showErrorMessage)
    )
      return false;

    setNameValidation('');
    return true;
  };

  /**
   * @function validateServerUrl
   * @description Validates the server url field
   * @param value server url value
   * @param showErrorMessage boolean to show error message or not
   * @returns boolean to indicate if the field is valid or not
   */
  const validateServerUrl = (value: string, showErrorMessage = false) => {
    if (
      validateEmptyField(
        value,
        t('serverUrl'),
        setServerUrlValidation,
        showErrorMessage
      ) ||
      !validateByRegex(
        value,
        /^[a-z0-9]+([-.][a-z0-9]+)*\.snowflakecomputing.com$/,
        t('serverUrl'),
        showErrorMessage ? setServerUrlValidation : undefined,
        t('serverUrlFormat')
      )
    )
      return false;

    setServerUrlValidation('');
    return true;
  };

  /**
   * @function validateUsername
   * @description Validates the username field
   * @param value username value
   * @param showErrorMessage boolean to show error message or not
   * @returns boolean to indicate if the field is valid or not
   */
  const validateUsername = (value: string, showErrorMessage = false) => {
    if (
      validateEmptyField(
        value,
        t('username'),
        setUsernameValidation,
        showErrorMessage
      )
    )
      return false;

    setUsernameValidation('');
    return true;
  };

  /**
   * @function validateSnowFlakePwdField
   * @description Validates the snowflake pwd field
   * @param value pwd value
   * @param showErrorMessage boolean to show error message or not
   * @returns boolean to indicate if the field is valid or not
   */
  const validateSnowFlakePwdField = (
    value: string,
    showErrorMessage = false
  ) => {
    if (
      validateEmptyField(
        value,
        t('password'),
        setPassFieldValidation,
        showErrorMessage
      )
    )
      return false;

    setPassFieldValidation('');
    return true;
  };

  /**
   * @function validateDatabase
   * @description Validates the database field
   * @param value database value
   * @param showErrorMessage boolean to show error message or not
   * @returns boolean to indicate if the field is valid or not
   */
  const validateDatabase = (value: string, showErrorMessage = false) => {
    if (
      validateEmptyField(
        value,
        t('database'),
        setDatabaseValidation,
        showErrorMessage
      )
    )
      return false;
    setDatabaseValidation('');
    return true;
  };

  /**
   * @function validateSchema
   * @description Validates the schema field
   * @param value schema value
   * @param showErrorMessage boolean to show error message or not
   * @returns boolean to indicate if the field is valid or not
   */
  const validateSchema = (value: string, showErrorMessage = false) => {
    if (
      validateEmptyField(
        value,
        t('schema'),
        setSchemaValidation,
        showErrorMessage
      )
    )
      return false;

    setSchemaValidation('');
    return true;
  };

  /**
   * @function validateAllFields
   * @description Validates all the fields
   * @returns boolean to indicate if all the fields are valid or not
   */
  const validateAllFields = () => {
    let validation = true;
    if (!validateName(formData.name, true)) validation = false;
    if (!validateServerUrl(formData.serverURL, true)) validation = false;
    if (!editSnowflakeData && !validateUsername(formData.username, true))
      validation = false;
    if (
      !editSnowflakeData &&
      !validateSnowFlakePwdField(formData.password, true)
    )
      validation = false;
    if (!validateDatabase(formData.database, true)) validation = false;
    if (!validateSchema(formData.schema, true)) validation = false;
    return validation;
  };

  const onClickSubmit = () => {
    if (!validateAllFields()) return;

    setFormSubmitRequestStatus(REQUEST_STATUS.PROCESSING);

    let body: any = {
      ...formData,
    };
    if (editSnowflakeData) {
      body = {
        ...body,
        username: undefined,
        password: undefined,
      };
    }

    (!editSnowflakeData
      ? createSnowflakeIntegration(body)
      : updateSnowflakeIntegration(body)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setFormSubmitRequestStatus(REQUEST_STATUS.SUCCESS);
          setIntegrationPageComponent(IntegrationsPageComponent.SUCCESS);
        } else {
          onApiCallError(res?.data?.message, true, setFormSubmitRequestStatus);
          setIntegrationPageComponent(IntegrationsPageComponent.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(e, true, setFormSubmitRequestStatus);
        setIntegrationPageComponent(IntegrationsPageComponent.ERROR);
      });
  };

  return (
    <div className="flex flex-column flex-gap-24">
      <div className="form-item flex flex-column">
        <FormLabel title={t('name')} required={true} />
        <Input
          default
          type="input"
          placeholder={t('namePlaceholder')}
          value={formData.name}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              name: e.target.value,
            });
            validateName(e.target.value);
          }}
          onBlur={(e: any) => validateName(e.target.value, true)}
        />
        <span
          style={{
            display: getValidationStyle(nameValidation),
          }}
          className="font-validation-error"
        >
          {nameValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('serverUrl')} required={true} />
        <Input
          default
          type="input"
          placeholder={t('serverUrlPlaceholder')}
          disabled={!!editSnowflakeData}
          value={formData.serverURL}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              serverURL: e.target.value,
            });
            validateServerUrl(e.target.value);
          }}
          onBlur={(e: any) => validateServerUrl(e.target.value, true)}
        />
        <span
          style={{
            display: getValidationStyle(serverUrlValidation),
          }}
          className="font-validation-error"
        >
          {serverUrlValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('username')} required={true} />
        <Input
          default
          type={editSnowflakeData ? 'password' : 'input'}
          value={
            editSnowflakeData ? t('usernamePlaceholder') : formData.username
          }
          disabled={!!editSnowflakeData}
          placeholder={t('usernamePlaceholder')}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              username: e.target.value,
            });
            validateUsername(e.target.value);
          }}
          onBlur={(e: any) => validateUsername(e.target.value, true)}
        />
        <span
          style={{
            display: getValidationStyle(usernameValidation),
          }}
          className="font-validation-error"
        >
          {usernameValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('password')} required={true} />
        <Input
          default
          type="password"
          value={
            editSnowflakeData ? t('passwordPlaceholder') : formData.password
          }
          disabled={!!editSnowflakeData}
          placeholder={t('passwordPlaceholder')}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              password: e.target.value,
            });
            validateSnowFlakePwdField(e.target.value);
          }}
          onBlur={(e: any) => validateSnowFlakePwdField(e.target.value, true)}
        />
        <span
          style={{
            display: getValidationStyle(passFieldValidation),
          }}
          className="font-validation-error"
        >
          {passFieldValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('database')} required={true} />
        <Input
          default
          type="input"
          value={formData.database}
          placeholder={t('databasePlaceholder')}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              database: e.target.value,
            });
            validateDatabase(e.target.value);
          }}
          onBlur={(e: any) => validateDatabase(e.target.value, true)}
        />
        <span
          style={{
            display: getValidationStyle(databaseValidation),
          }}
          className="font-validation-error"
        >
          {databaseValidation}
        </span>
      </div>
      <div className="form-item flex flex-column">
        <FormLabel title={t('schema')} required={true} />
        <Input
          default
          type="input"
          value={formData.schema}
          placeholder={t('schemaPlaceholder')}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              schema: e.target.value,
            });
            validateSchema(e.target.value);
          }}
          onBlur={(e: any) => validateSchema(e.target.value, true)}
        />
        <span
          style={{
            display: getValidationStyle(schemaValidation),
          }}
          className="font-validation-error"
        >
          {schemaValidation}
        </span>
      </div>
    </div>
  );
};

export default SnowflakeAccess;
