import i18n from 'i18n';
import {
  ALERT_SUBSCRIPTION_THRESHOLD_TYPE,
  AnamolySubscriptionType,
  ThresholdType,
} from 'pages/CostMonitorPage/types';
import { isEmptyField, isNumber } from 'utils/validations';

/**
 * @function validateThresholdRow
 * @description Function to validate the fields of a threshold row
 * @param threshold threshold details
 * @returns boolean true if validation is successful else false
 */
const validateThresholdRow = (threshold: ThresholdType) => {
  if (isNumber(threshold.thresholdValue)) {
    return validateThresholdRange(threshold);
  }
  return false;
};

/**
 * @function validateThresholdRange
 * @description Function to validate the range of a threshold value
 * @param threshold threshold details
 * @returns boolean true if validation is successful else false
 */
const validateThresholdRange = (threshold: ThresholdType) => {
  if (
    threshold.thresholdType ===
    ALERT_SUBSCRIPTION_THRESHOLD_TYPE.AMOUNT.valueOf()
  ) {
    if (Number(threshold.thresholdType) <= 0) {
      return false;
    }
  }
  if (
    threshold.thresholdType ===
    ALERT_SUBSCRIPTION_THRESHOLD_TYPE.PERCENTAGE.valueOf()
  ) {
    if (
      Number(threshold.thresholdType) <= 0 ||
      Number(threshold.thresholdType) > 100
    ) {
      return false;
    }
  }

  return true;
};

/**
 * @function validateSubscription
 * @description Function to validate fields in a subscription
 * @param alertSubscriptionDetails subscriptions details
 * @returns boolean true if validation is successful else false
 */
const validateSubscription = (
  alertSubscriptionDetails: AnamolySubscriptionType,
  existingAlertSubscriptions: AnamolySubscriptionType[]
) => {
  let validation = true;
  validation =
    !isEmptyField(alertSubscriptionDetails.subscriptionName) &&
    (alertSubscriptionDetails.existingSubscription ||
      !existingAlertSubscriptions.some(
        (subscription) =>
          subscription.subscriptionName ===
          alertSubscriptionDetails.subscriptionName.trim()
      )) &&
    validation;
  validation =
    (!alertSubscriptionDetails.existingSubscription ||
      !!alertSubscriptionDetails.subscriptionArn) &&
    validation;
  if (!alertSubscriptionDetails.existingSubscription) {
    validation =
      (alertSubscriptionDetails.alertRecipientEmails ?? [])?.length > 0 &&
      (alertSubscriptionDetails.alertRecipientEmails ?? [])?.length <= 10 &&
      validation;

    validation =
      !!alertSubscriptionDetails.thresholds &&
      alertSubscriptionDetails.thresholds?.length > 0 &&
      alertSubscriptionDetails.thresholds.every((threshold) =>
        validateThresholdRow(threshold)
      ) &&
      validation;
  }
  return validation;
};

/**
 * @function validateAllSubscriptions
 * @description Function to validate the newly added subscriptions
 * @param anamolySubscriptions subscriptions list
 * @param existingAttachedSubscriptions existing subscriptions that are not detached
 * @param existingAlertSubscriptions Existing subscriptions in the connection
 * @param setValidationMessage call back function to set the error message
 * @returns boolean true if validation is successful else false
 */
export const validateAllSubscriptions = (
  anamolySubscriptions: AnamolySubscriptionType[],
  existingAttachedSubscriptions: string[],
  existingAlertSubscriptions: AnamolySubscriptionType[],
  setValidationMessage: (val: string) => void
) => {
  if (anamolySubscriptions.length === 0) {
    setValidationMessage(
      i18n.t(
        'costMonitor.createCostMonitorLabels.createAlertSubscription.noSubscriptionsMessage'
      )
    );
    return false;
  }

  const newlyAttachedSubscriptions = anamolySubscriptions.filter(
    (subscription) =>
      !subscription.subscriptionArn ||
      !existingAttachedSubscriptions.includes(subscription.subscriptionArn)
  );

  return newlyAttachedSubscriptions.every((subscription) =>
    validateSubscription(subscription, existingAlertSubscriptions)
  );
};
