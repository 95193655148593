import Tooltip from 'components/Tooltip';
import { COLORS } from 'constants/graphConfig';

const LabelOverflowWithTooltip = (
  title: string,
  headerLimit: number,
  addTooltip = true
) => {
  return title.length > headerLimit && addTooltip ? (
    <Tooltip title={title} color={COLORS.secondaryBackground}>
      {title.substring(0, headerLimit) + '...'}
    </Tooltip>
  ) : (
    <>{title}</>
  );
};

export default LabelOverflowWithTooltip;
