import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectGranulate, setSelectedProvider } from 'redux/granulateSlice';
import CloudProviders from 'components/CloudProviders';
import ProgressBar from 'components/ProgressBar';
import { PROGRESS_BAR_ITEMS, GranulateProviderView } from 'constants/granulate';
import { CONNECTION_PROVIDER_LIST_DATA } from 'constants/providerConnectionData';
import { PROVIDER } from 'constants/cloudProviders';
import Footer from './components/Footer';
import GCPConnectionForm from './GCPConnectionForm';
import AWSConnectionForm from './AWSConnectionForm';

import './index.scss';

const GranulateAddConnection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currentStep, granulateProviderView, selectedProvider } =
    useSelector(selectGranulate);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const onHandleClick = (provider: string) => {
    dispatch(setSelectedProvider(provider));
  };

  const getComponent = () => {
    switch (granulateProviderView) {
      case GranulateProviderView.PROVIDER_LIST:
        return (
          <CloudProviders
            providerList={CONNECTION_PROVIDER_LIST_DATA}
            selectedProvider={selectedProvider}
            handleClick={onHandleClick}
            header={t('gProfilerConnectionPage.addConnection.header')}
            subHeader={t('gProfilerConnectionPage.addConnection.subHeader')}
            disabledList={[PROVIDER.AZURE, PROVIDER.OCI]}
          />
        );
      case GranulateProviderView.GCP:
        return <GCPConnectionForm setSubmitDisabled={setSubmitDisabled} />;
      case GranulateProviderView.AWS:
        return <AWSConnectionForm setSubmitDisabled={setSubmitDisabled} />;
      case GranulateProviderView.AZURE:
        return null;
    }
  };

  return (
    <div className="granulate-add-connection flex flex-fit full-height">
      <ProgressBar
        items={PROGRESS_BAR_ITEMS}
        current={currentStep}
        heading={t('granulateConnectionPage.steps.heading')}
        subHeading={t('granulateConnectionPage.steps.subHeading')}
        additionalClassNames="add-connection-progress-bar"
      />
      <div className="form-section flex flex-column flex-fit flex-space-between">
        {getComponent()}
        <Footer
          selectedProvider={selectedProvider}
          itemsLength={PROGRESS_BAR_ITEMS.length}
          submitDisabled={submitDisabled}
        />
      </div>
    </div>
  );
};

export default GranulateAddConnection;
