import { MonthlyCostByServiceType } from 'types/dataTypes';

/**
 * @function getUniqueServices
 * @description Function to return unique data of service cost (Will return only the first entry of the cost for each service)
 * @param serviceData List of service cost by month (only the first entry of each service)
 * @return List of service cost
 */
export const getUniqueServices = (serviceData: MonthlyCostByServiceType[]) => {
  const services: MonthlyCostByServiceType[] = [];
  serviceData.forEach((item) => {
    if (!services.some((entry) => entry.service === item.service)) {
      services.push(item);
    }
  });
  return services;
};
