import axios, { CancelTokenSource } from 'axios';
import { Empty } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq, xor } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import GraphHeader from 'components/GraphHeader';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Table from 'components/Table';
import PieDonutChart from 'components/PieChartAnt';
import BarChart from 'components/BarChart';
import ColumnLineChart from 'components/ColumnLineChart';
import ColumnChart from 'components/ColumnChart';
import AreaChart from 'components/AreaChart';
import LineChart from 'components/LineChart';
import DashboardComponent from 'components/DashboardComponent';
import DropdownCheckbox from 'components/DropdownCheckbox';
import DatePicker from 'components/DatePicker';
import AccessibleDiv from 'components/AccessibleDiv';
import { CHART_TYPES } from 'constants/graphConfig';
import { INPUT_SIZE } from 'constants/appearance';
import { ORDER_BY, REQUEST_STATUS } from 'constants/requestBody';
import {
  CHARTS_LIST,
  CUSTOM_DASHBOARD_MODE,
  FIELD_TYPE,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import { MAX_CHARACTER_LIMIT } from 'constants/validation';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import usePrevious from 'hooks/usePrevious';
import {
  getAllAvailableValues,
  getChartConditions,
  getColumnLabelByField,
  getFieldCategory,
  getFilterForColumn,
  getFirstTableFilterGroup,
  getTableSorts,
  updateFilterQuery,
} from 'pages/CustomDashboardPage/utils';
import { CustomDashboardChartTypes } from 'pages/CustomDashboardPage/constants';
import { IntegrationTypes } from 'pages/IntegrationsPage/constants';
import { GroupConnectorDto } from 'pages/GroupsPage/types';
import {
  customDashboard,
  setAllFieldValuesMap,
  setCustomViewData,
  setSelectedChartIndex,
  setSelectedChartRow,
} from 'redux/customDashboardSlice';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import {
  ChartType,
  ColumnType,
  FilterType,
  LayoutDesignsType,
  TagsFilterType,
} from 'types/dashboard';
import { getChartData, getSnowflakeChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import {
  getAzureTagFiltersData,
  getDashboardDatasourceName,
  getGCPTagFiltersData,
  getProviderForConnection,
} from 'utils/dashboardUtils';
import { PROVIDER } from 'constants/cloudProviders';
import {
  insertIndex,
  numberCommaSeparator,
  replaceAllSpecialCharactersBy,
} from 'utils/dataFormatterUtils';
import { validateStringLengthLessThan } from 'utils/validations';

import {
  getModifiedChartQuery,
  isCustomChartSelected,
  modifyDimensionLabelForCustomQuery,
  normalizeGroupData,
  replaceFieldsInGroupsQuery,
  updateTableChartData,
} from './utils';
import FilterDropdown from './FilterDropdown';

import './index.scss';

type CustomTableOrChartDataProps = {
  chart: ChartType;
  layout: LayoutDesignsType;
  isExpanded?: boolean;
  pdfView?: boolean;
  sliderValue?: { x: number; y: number };
  setSliderValue?: Function;
};

const CustomTableOrChartData = ({
  chart,
  layout,
  isExpanded,
  pdfView,
  sliderValue,
  setSliderValue,
}: CustomTableOrChartDataProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    customViewData,
    customDashBoardMode,
    customDashboardConnection,
    customDashboardDataSource,
    customDashboardIntegrationConnection,
    dashboardType,
    customDashboardGroup,
    customGroupAvailableFields,
    tableChartData,
    selectedChartType,
    selectedChartIndex,
    selectedChartRow,
    allFieldValuesMap,
  } = useSelector(customDashboard);
  const {
    selectedDashboard,
    tableViewEnabled,
    selectedDashboardView,
    tagsFilters,
  } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);
  const { theme } = useSelector(selectTheme);

  const [showEditTitle, setShowEditTitle] = useState(false);
  const [loading, setLoading] = useState(REQUEST_STATUS.SUCCESS);
  const [isTableView, setIsTableView] = useState(
    chart.chartType === CHART_TYPES.TABLE
  );
  const [previousCancelTokenSource, setPreviousCancelTokenSource] =
    useState<CancelTokenSource | null>(null);
  const previousChart = usePrevious<ChartType>(chart);
  const previousTagFilters = usePrevious<TagsFilterType[]>(tagsFilters);
  const [chartName, setChartName] = useState('');

  useEffect(() => {
    if (customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED) {
      setIsTableView(chart.chartType === CHART_TYPES.TABLE || tableViewEnabled);
    } else {
      setIsTableView(
        chart.chartType === CHART_TYPES.TABLE ||
          (selectedChartType === CustomDashboardChartTypes.TABLE &&
            chart.chartPosition === selectedChartIndex &&
            layout.position === selectedChartRow)
      );
    }
  }, [selectedChartType, chart.chartType, tableViewEnabled]);

  useEffect(() => {
    const tableChartData = getTableChartData();
    if (
      (isExpanded && isTableView && tableChartData?.tableData?.length) ||
      (isExpanded && !isTableView && tableChartData?.chartData?.length)
    ) {
      return;
    }

    if (
      !chart.tableQuery?.columns?.length ||
      (!isTableView && !validateMinFieldsSelected())
    )
      return updateTableChartData(`${layout.position}${chart.chartPosition}`, {
        chartData: [],
        tableData: [],
      });
    if (
      xor(
        previousChart?.tableQuery?.columns?.map((column) => column.field),
        chart.tableQuery?.columns?.map((column) => column.field)
      ).length ||
      xor(
        previousChart?.chartQuery?.columns?.map((column) => column.field),
        chart.chartQuery?.columns?.map((column) => column.field)
      ).length ||
      checkOrderByChange() ||
      checkFilterChange() ||
      checkConditionChange() ||
      isChartOrTableDataEmpty() ||
      JSON.stringify(tagsFilters) !== JSON.stringify(previousTagFilters)
    ) {
      if (previousCancelTokenSource) {
        previousCancelTokenSource.cancel();
      }
      let source = axios.CancelToken.source();
      setPreviousCancelTokenSource(source);
      switch (dashboardType) {
        case MY_DASHBOARD_TYPES.GROUP:
          fetchGroupsTableChartData(source);
          break;
        case MY_DASHBOARD_TYPES.SNOWFLAKE:
          fetchSnowflakeTableChartData(source);
          break;
        case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
        case MY_DASHBOARD_TYPES.IMPORTS:
          fetchTableChartData(source);
          break;
      }
    }
  }, [
    isTableView,
    chart.chartQuery,
    chart.tableQuery,
    chart.conditions,
    tagsFilters,
  ]);

  /**
   * @function getTableChartData
   * @description get table data for table component and chart data for chart component
   * @returns this chart/table data
   */
  const getTableChartData = () => {
    return tableChartData[`${layout.position}${chart.chartPosition}`];
  };

  /**
   * @function validateMinFieldsSelected
   * @description check if minimum required fields are selected for the chart
   * @returns true if minimum fields are selected else false
   */
  const validateMinFieldsSelected = () => {
    const dimensions =
      chart.chartQuery?.columns?.filter((item) =>
        chart?.chartQuery?.groupBy?.includes(item.field)
      ) ?? [];
    const metrics =
      chart.chartQuery?.columns?.filter((item) =>
        chart?.chartQuery?.aggregators?.some(
          (aggregator) => aggregator.label === item.field
        )
      ) ?? [];
    const chartProperties = CHARTS_LIST.find(
      (item) => item.chartType === chart.chartType
    );
    return !(
      (chartProperties?.dimensionsCount?.min ?? 0) > dimensions.length ||
      (chartProperties?.metricsCount?.min ?? 0) > metrics.length
    );
  };

  /**
   * @function checkOrderByChange
   * @description check if order by has changed compared to previous chart state
   * @returns boolean
   */
  const checkOrderByChange = () => {
    return (
      JSON.stringify(
        chart.tableQuery?.orderBy?.filter((item) => item.label && item.sort) ??
          []
      ) !==
      JSON.stringify(
        previousChart?.tableQuery?.orderBy?.filter(
          (item) => item.label && item.sort
        ) ?? []
      )
    );
  };

  /**
   * @function checkFilterChange
   * @description check if filters and selected values have changed compared to previous chart state
   * @returns boolean
   */
  const checkFilterChange = () => {
    const currentFilters = getFirstTableFilterGroup(chart);
    const previousFilters = getFirstTableFilterGroup(previousChart);
    for (
      let i = 0;
      i < currentFilters.length || i < previousFilters.length;
      i++
    ) {
      if (
        xor(
          currentFilters.at(i)?.selectedValues || [],
          previousFilters.at(i)?.selectedValues || []
        ).length
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * @function checkConditionChange
   * @description check if condition has changed compared to previous chart state
   * @returns boolean
   */
  const checkConditionChange = () => {
    const thisConditions =
      chart.conditions?.filter(
        (item) => item.field && item.comparator && item.value
      ) ?? [];
    const prevConditions =
      previousChart?.conditions?.filter(
        (item) => item.field && item.comparator && item.value
      ) ?? [];
    return JSON.stringify(thisConditions) !== JSON.stringify(prevConditions);
  };

  /**
   * @function isChartOrTableDataEmpty
   * @description check if the data for the chart is empty and make an API call
   * @returns boolean
   */
  const isChartOrTableDataEmpty = () => {
    const tableAndChartData = getTableChartData();
    if (isTableView) {
      return !tableAndChartData?.tableData?.length;
    }

    return !tableAndChartData?.chartData?.length;
  };

  /**
   * @function onFetchData
   * @description preprocess and set data for table/chart component
   * @param data data from API
   */
  const onFetchData = (data: any[]) => {
    // Update all available values for all fields in filters
    let allValues: { field: string; values: string[] }[] = [
      ...allFieldValuesMap,
    ];
    chart.tableQuery?.columns?.forEach((column) => {
      if (!allValues.some((item) => item.field === column.field)) {
        allValues = [
          ...allValues,
          {
            field: column.field,
            values: uniq(
              data.map(
                (row: any) => row[replaceAllSpecialCharactersBy(column.field)]
              )
            ),
          },
        ];
      }
    });
    dispatch(setAllFieldValuesMap(allValues));

    // Update selected values for filters if not present
    let newFiltersCheck = false;
    const filters = getFirstTableFilterGroup(chart).map((filter) => {
      if (getFieldCategory(filter.field) === FIELD_TYPE.LITERAL) {
        if (filter.selectedValues === undefined) newFiltersCheck = true;
        return {
          ...filter,
          selectedValues:
            filter.selectedValues ??
            uniq(
              data.map(
                (row: any) => row[replaceAllSpecialCharactersBy(filter.field)]
              )
            ),
        };
      }
      return filter;
    });
    newFiltersCheck &&
      updateFilterQuery(filters, layout.position, chart.chartPosition);

    // Convert all metrics from string to Number and set data
    const metrics =
      chart.chartQuery?.columns
        ?.filter((item) =>
          chart?.chartQuery?.aggregators?.some(
            (aggregator) => aggregator.label === item.field
          )
        )
        .map((column) => modifyDimensionLabelForCustomQuery(column)) ?? [];
    data = data.map((obj: any) => {
      const newObj = { ...obj };
      metrics.forEach((key) => {
        newObj[key] = Number(newObj[key]);
      });
      return newObj;
    });
    if (isTableView)
      updateTableChartData(`${layout.position}${chart.chartPosition}`, {
        chartData: getTableChartData()?.chartData,
        tableData: data,
      });
    else
      updateTableChartData(`${layout.position}${chart.chartPosition}`, {
        tableData: getTableChartData()?.tableData,
        chartData: data,
      });
  };

  /**
   * @function fetchSnowflakeTableChartData
   * @description fetch table data and set data for table component for snowflake data
   * @param cancelTokenSource cancel token source
   */
  const fetchSnowflakeTableChartData = (
    cancelTokenSource: CancelTokenSource
  ) => {
    setLoading(REQUEST_STATUS.PROCESSING);

    let requestBody = {
      snowflakeIntegrationId:
        customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED
          ? selectedDashboard?.integrationId
          : customDashboardIntegrationConnection?.integrationId,
      table: customDashboardDataSource.dataSet,
      queryDto: {
        ...getModifiedChartQuery(
          isTableView ? chart.tableQuery : chart.chartQuery,
          (customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED
            ? selectedDashboard?.connectorProvider
            : getProviderForConnection(customDashboardConnection)) ?? '',
          getFirstTableFilterGroup(chart),
          getChartConditions(chart),
          {
            provider:
              (customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED
                ? selectedDashboard?.connectorProvider
                : getProviderForConnection(customDashboardConnection)) ?? '',
            connectorId:
              customDashboardConnection?.connectorId ??
              selectedDashboard?.connectorId ??
              '',
          },
          IntegrationTypes.SNOWFLAKE
        ),
      },
    };

    getSnowflakeChartData(requestBody, cancelTokenSource.token)
      .then((res: any) => {
        let data = JSON.parse(res.data.responseData) ?? [];
        onFetchData(data);
        setLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setLoading);
      });
  };

  /**
   * @function fetchTableChartData
   * @description fetch table data and set data for table component
   * @param cancelTokenSource cancel token source
   */
  const fetchTableChartData = (cancelTokenSource: CancelTokenSource) => {
    setLoading(REQUEST_STATUS.PROCESSING);
    let requestBody = {
      ...getModifiedChartQuery(
        isTableView ? chart.tableQuery : chart.chartQuery,
        (customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED
          ? selectedDashboard?.connectorProvider
          : getProviderForConnection(customDashboardConnection)) ?? '',
        getFirstTableFilterGroup(chart),
        getChartConditions(chart),
        {
          provider:
            (customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED
              ? selectedDashboard?.connectorProvider
              : getProviderForConnection(customDashboardConnection)) ?? '',
          connectorId:
            customDashboardConnection?.connectorId ??
            selectedDashboard?.connectorId ??
            '',
        }
      ),
    };
    const params = {
      queryId: customViewData?.dataSource.customQueryId,
      database: customViewData?.dataSource.dataset,
    };
    const connectorId =
      customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED
        ? selectedDashboard?.connectorId
        : customDashboardConnection?.connectorId;

    // Add tags query to the request body
    if (selectedDashboard?.connectorProvider === PROVIDER.GCP) {
      const tagFiltersData = getGCPTagFiltersData(
        tagsFilters,
        selectedDashboard?.id,
        selectedDashboardView
      );
      if (tagFiltersData.length > 0) {
        requestBody.subQuery.keyValueStructFilterGroups = tagFiltersData;
      }
    } else if (selectedDashboard?.connectorProvider === PROVIDER.AZURE) {
      const tagFiltersData = getAzureTagFiltersData(
        tagsFilters,
        selectedDashboard?.id,
        selectedDashboardView
      );
      if (tagFiltersData.length > 0) {
        requestBody.subQuery.filterGroups = [
          ...(requestBody?.subQuery?.filterGroups || []),
          ...tagFiltersData,
        ];
      }
    }

    getChartData(requestBody, connectorId, params, cancelTokenSource.token)
      .then((res: any) => {
        let data = res.data || [];
        onFetchData(data);
        setLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setLoading);
      });
  };

  /**
   * @function fetchGroupsTableChartData
   * @description fetch table data for groups connection and set data for table component
   * @param cancelTokenSource cancel token source
   */
  const fetchGroupsTableChartData = (cancelTokenSource: CancelTokenSource) => {
    if (
      !customDashboardGroup?.connectorDtos ||
      (dashboardType === MY_DASHBOARD_TYPES.GROUP &&
        customDashBoardMode !== CUSTOM_DASHBOARD_MODE.DEV &&
        customGroupAvailableFields.length === 0)
    )
      return;
    setLoading(REQUEST_STATUS.PROCESSING);
    const requests = customDashboardGroup?.connectorDtos.map(
      (connector: GroupConnectorDto) => {
        let requestBody = {
          ...getModifiedChartQuery(
            isTableView ? chart.tableQuery : chart.chartQuery,
            getProviderForConnection(connector),
            getFirstTableFilterGroup(chart),
            getChartConditions(chart),
            {
              connectorId: connector.connectorId,
              provider: getProviderForConnection(connector),
            }
          ),
        };
        requestBody = {
          ...requestBody,
          filterGroups: requestBody.filterGroups,
          subQuery: replaceFieldsInGroupsQuery(
            requestBody.subQuery,
            getProviderForConnection(connector),
            customGroupAvailableFields
          ),
        };
        return getChartData(
          requestBody,
          connector.connectorId,
          cancelTokenSource.token
        );
      }
    );
    axios
      .all(requests || [])
      .then((res: any) => {
        let normalizedData = normalizeGroupData(isTableView, chart, res);
        normalizedData = sortDataByAppliedSorts(normalizedData);
        onFetchData(normalizedData);
        setLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setLoading);
      });
  };

  /**
   * @function sortDataByAppliedSorts
   * @description sort data based on applied sorts for the chart
   * @param data data from API
   * @returns sorted data
   */
  const sortDataByAppliedSorts = (data: any[]) => {
    let sortedData = [...data];
    getTableSorts(chart)
      .filter((sort) => sort.label && sort.sort)
      .forEach((sort) => {
        if (
          customGroupAvailableFields.find((field) => field.label === sort.label)
            ?.category === FIELD_TYPE.NUMERIC
        ) {
          sortedData = sortedData.sort((a: any, b: any) => {
            if (sort.sort === ORDER_BY.ASCENDING) {
              return a[sort.label] - b[sort.label];
            }
            return b[sort.label] - a[sort.label];
          });
          return;
        }
        sortedData = sortedData.sort((a: any, b: any) => {
          if (sort.sort === ORDER_BY.ASCENDING) {
            return a[sort.label].localeCompare(b[sort.label]);
          }
          return b[sort.label].localeCompare(a[sort.label]);
        });
      });
    return sortedData;
  };

  /**
   * @function onUpdateTableTitle
   * @description update table title based on user input
   * @param e input event object
   */
  const onUpdateTableTitle = (e: any) => {
    const layoutDesigns: LayoutDesignsType[] = [
      ...(customViewData?.layoutDesigns || []),
    ];

    const updatedCharts: ChartType[] =
      layoutDesigns
        .find((row) => row.position === layout.position)
        ?.charts?.map((eachChart) => {
          if (eachChart.chartPosition === chart.chartPosition) {
            return { ...eachChart, chartName: e.target.value };
          } else {
            return { ...eachChart };
          }
        }) ?? [];
    layoutDesigns.splice(layout.position, 1, {
      charts: updatedCharts,
      position: layout.position,
    });

    dispatch(
      setCustomViewData({
        ...customViewData,
        layoutDesigns: layoutDesigns,
      })
    );
    setShowEditTitle(false);
  };

  /**
   * @function formatFieldText
   * @description format field text based on field type
   * @param column column object
   * @param text text to be formatted
   * @returns formatted text
   */
  const formatFieldText = (column: ColumnType, text: string) =>
    getFieldCategory(column.field) === FIELD_TYPE.NUMERIC
      ? numberCommaSeparator(text)
      : text;

  /**
   * @function getColumnWidth
   * @description get column width based on field type
   * @param column column object
   * @returns column width
   */
  const getColumnWidth = (column: ColumnType) =>
    getFieldCategory(column.field) === FIELD_TYPE.NUMERIC ? 100 : 200;

  const getColumns = () => {
    const columns: any[] = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        render: (_text: any, _record: any, index: number) => index + 1,
        width: 50,
      },
    ];

    chart.tableQuery?.columns?.forEach((column) => {
      const filter = getFilterForColumn(column.field, chart);
      if (filter)
        columns.push({
          title: column.label,
          dataIndex: replaceAllSpecialCharactersBy(column.field),
          key: replaceAllSpecialCharactersBy(column.field),
          filterIcon: <Icon iconName={ICONS.FILTER_3_FILL} />,
          filterDropdown: (
            <FilterDropdown filter={filter} chart={chart} layout={layout} />
          ),
          render: (text: any) => formatFieldText(column, text),
          width: getColumnWidth(column),
        });
      else
        columns.push({
          title: getColumnLabelByField(column.field, chart),
          dataIndex: replaceAllSpecialCharactersBy(column.field),
          key: replaceAllSpecialCharactersBy(column.field),
          render: (text: any) => formatFieldText(column, text),
          width: getColumnWidth(column),
        });
    });
    return columns;
  };

  const pdfWrapperClass = pdfView ? 'pdf-wrapper' : '';
  const pdfSliderValue = pdfView ? sliderValue : undefined;

  const getCustomChart = () => {
    const dimensions =
      chart.chartQuery?.columns
        ?.filter((item) => chart?.chartQuery?.groupBy?.includes(item.field))
        .map((column) => ({
          title: getColumnLabelByField(column.field, chart),
          key: replaceAllSpecialCharactersBy(column.field),
          category: getFieldCategory(column.field),
        })) ?? [];
    const metrics =
      chart.chartQuery?.columns
        ?.filter((item) =>
          chart?.chartQuery?.aggregators?.some(
            (aggregator) => aggregator.label === item.field
          )
        )
        .map((column) => ({
          title: getColumnLabelByField(column.field, chart),
          key: replaceAllSpecialCharactersBy(column.field),
        })) ?? [];
    const chartProperties = CHARTS_LIST.find(
      (item) => item.chartType === chart.chartType
    );
    if (!validateMinFieldsSelected())
      return (
        <div className="flex flex-center stretch">
          {t(
            'customDashboard.optionsLabels.pleaseSelectMinimumRequiredMetricsDimensions',
            {
              metricAmount: chartProperties?.metricsCount?.min ?? 0,
              dimensionAmount: chartProperties?.dimensionsCount?.min ?? 0,
            }
          )}
        </div>
      );

    let chartData = [...(getTableChartData()?.chartData || [])];
    if (
      !dimensions.some((dimension) => dimension.category === FIELD_TYPE.TIME) &&
      !chart.chartQuery?.orderBy?.filter((item) => item.label && item.sort)
        ?.length
    ) {
      // sort the data based on the first metric if no sorts are applied.
      if (
        !chart.chartQuery?.orderBy?.filter((item) => item.label && item.sort)
          ?.length
      ) {
        chartData.sort((a: any, b: any) => {
          return b[metrics[0]?.key] - a[metrics[0]?.key];
        });
      }
    }

    switch (chart.chartType) {
      case CHART_TYPES.PIE_CHART:
      case CHART_TYPES.DOUGHNUT_CHART:
        return (
          <PieDonutChart
            data={chartData}
            angleField={metrics[0].key}
            colorField={dimensions[0].key}
            isDonut={chart.chartType === CHART_TYPES.DOUGHNUT_CHART}
            disableAnimation={pdfView}
            showLegends={!pdfView}
            additionalClassNames={`${pdfView && 'pdf-wrapper pdf-pie-wrapper'}`}
          />
        );
      case CHART_TYPES.BAR_CHART:
        return (
          <ColumnChart
            data={chartData}
            xField={dimensions[0].key}
            yField={metrics[0].key}
            xTitle={dimensions[0].title}
            yTitle={metrics[0].title}
            groupingField={dimensions[0].key}
            prefixSymbol={currencySymbol}
            disableAnimation={pdfView}
            showLegend={!pdfView}
            additionalClassNames={pdfWrapperClass}
            showSlider
            sliderValues={pdfSliderValue}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y }, layout.position, chart.chartPosition);
            }}
          />
        );
      case CHART_TYPES.HORIZONTAL_BAR_CHART:
        return (
          <BarChart
            data={chartData}
            xField={metrics[0].key}
            yField={dimensions[0].key}
            xTitle={metrics[0].title}
            yTitle={dimensions[0].title}
            groupingField={dimensions[0].key}
            prefixSymbol={currencySymbol}
            showAllLegend={pdfView}
            disableAnimation={pdfView}
            showLegends={!pdfView}
            additionalClassNames={pdfWrapperClass}
          />
        );
      case CHART_TYPES.BAR_LINE_CHART:
        return (
          <ColumnLineChart
            data={chartData.map((item) => ({
              ...item,
              groupingField: metrics[0].title,
            }))}
            xField={dimensions[0].key}
            yField={metrics[0].key}
            groupingField="groupingField"
            xTitle={dimensions[0].title}
            yTitle={metrics[0].title}
            prefixSymbol={currencySymbol}
            disableAnimation={pdfView}
          />
        );
      case CHART_TYPES.AREA_CHART:
        return (
          <AreaChart
            data={chartData.map((item) => ({
              ...item,
              groupingField: metrics[0].title,
            }))}
            xField={dimensions[0].key}
            yField={metrics[0].key}
            groupingField="groupingField"
            xTitle={dimensions[0].title}
            yTitle={metrics[0].title}
            prefixSymbol={currencySymbol}
            colorOverride={{ [metrics[0].title]: theme.primaryColor }}
            flipPage={!pdfView}
            disableAnimation={pdfView}
            showSlider
            sliderValues={pdfSliderValue}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y }, layout.position, chart.chartPosition);
            }}
          />
        );
      case CHART_TYPES.LINE_CHART:
        return (
          <LineChart
            data={chartData.map((item) => ({
              ...item,
              groupingField: metrics[0].title,
            }))}
            xField={dimensions[0].key}
            yField={metrics[0].key}
            groupingField="groupingField"
            xTitle={dimensions[0].title}
            yTitle={metrics[0].title}
            prefixSymbol={currencySymbol}
            colorOverride={theme.primaryColor}
            showAllLegend={pdfView}
            disableAnimation={pdfView}
            showSlider
            sliderValues={pdfSliderValue}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y }, layout.position, chart.chartPosition);
            }}
          />
        );
      case CHART_TYPES.GROUPED_CHART: {
        let groupedChartData: any[] = [];
        chartData.forEach((item) => {
          metrics.forEach((metric) => {
            groupedChartData.push({
              [dimensions[0].key]: item[dimensions[0].key],
              metric: item[metric.key],
              groupingField: metric.title,
            });
          });
        });
        return (
          <ColumnChart
            data={groupedChartData}
            xField={dimensions[0].key}
            xTitle={dimensions[0].title}
            yField="metric"
            groupingField="groupingField"
            prefixSymbol={currencySymbol}
            isGroup
            disableAnimation={pdfView}
            showAllLegend={pdfView}
            showSlider
            sliderValues={pdfSliderValue}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y }, layout.position, chart.chartPosition);
            }}
          />
        );
      }
      case CHART_TYPES.STACK_CHART: {
        let stackedChartData: any[] = [];
        chartData.forEach((item) => {
          stackedChartData.push({
            [metrics[0].key]: item[metrics[0].key],
            [dimensions[0].key]: item[dimensions[0].key],
            groupingField: item[dimensions[1].key],
          });
        });
        return (
          <ColumnChart
            data={stackedChartData}
            xField={dimensions[0].key}
            xTitle={dimensions[0].title}
            yField={metrics[0].key}
            yTitle={metrics[0].title}
            groupingField="groupingField"
            prefixSymbol={currencySymbol}
            isStack
            disableAnimation={pdfView}
            showLegend={!pdfView}
            additionalClassNames={pdfWrapperClass}
            showSlider
            sliderValues={pdfSliderValue}
            onSliderValueChange={(x: number, y: number) => {
              setSliderValue?.({ x, y }, layout.position, chart.chartPosition);
            }}
          />
        );
      }
      default:
        return <></>;
    }
  };

  /**
   * @function getTableEmptyText
   * @description Function to return the empty locale text for table
   * @returns text for empty data or error state
   */
  const getTableEmptyText = () => {
    if (loading === REQUEST_STATUS.ERROR) {
      return t('graphErrorMessage');
    }

    if (customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED) {
      return t('customDashboard.dashboardTableLabels.noData');
    }

    return t('customDashboard.dashboardTableLabels.addDimensionsOrMetrics');
  };

  const getTableChartComponent = () => {
    if (isTableView) {
      return (
        <Table
          pagination={false}
          columns={getColumns()}
          dataSource={
            getTableChartData()?.tableData?.map((item, index) => ({
              ...item,
              key: index,
            })) || []
          }
          loading={loading.includes(REQUEST_STATUS.PROCESSING)}
          locale={{
            emptyText: loading !== REQUEST_STATUS.SUCCESS && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={getTableEmptyText()}
              />
            ),
          }}
          scroll={{ y: 200 }}
          designVersion2
        />
      );
    }

    return (
      <DashboardComponent
        component={getCustomChart() || <></>}
        requestStatus={loading}
      />
    );
  };

  const GraphHeadingValidation = (
    <span
      style={{
        display: `${
          !chartName || !validateStringLengthLessThan(chartName)
            ? 'none'
            : 'inline'
        }`,
      }}
      className="font-validation-error"
    >
      {t('customDashboard.dashboardTableLabels.graphTitle', {
        length: MAX_CHARACTER_LIMIT,
      })}
    </span>
  );

  /**
   * @function getGraphHeadingComponent
   * @description Function to return the chart heading component
   * @returns string or JSX element containing the graph heading
   */
  const getGraphHeadingComponent = () => {
    if (customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED) {
      return chart.chartName
        ? chart.chartName
        : t('customDashboard.dashboardTableLabels.addATitle');
    }

    if (showEditTitle) {
      return (
        <>
          <Input
            defaultValue={chart.chartName}
            placeholder={t('customDashboard.dashboardTableLabels.addATitle')}
            size={INPUT_SIZE.SMALL}
            onChange={(e: any) => setChartName(e.target.value)}
            onBlur={onUpdateTableTitle}
            onPressEnter={onUpdateTableTitle}
            autoFocus
          />
          {GraphHeadingValidation}
        </>
      );
    }

    return (
      <>
        <AccessibleDiv
          className="flex flex-align-items-center flex-gap-4"
          onClick={() => setShowEditTitle(true)}
        >
          <div>
            {chart.chartName
              ? chart.chartName
              : t('customDashboard.dashboardTableLabels.addATitle')}
          </div>
          {!showEditTitle && !pdfView && (
            <Icon
              className="edit-pencil"
              iconName={ICONS.EDIT_LINE}
              size={ICONS_SIZE.ONE_X}
            />
          )}
        </AccessibleDiv>
        {GraphHeadingValidation}
      </>
    );
  };

  /**
   * @function getLiteralChartFilter
   * @description get filter component for literal field
   * @param filter filter object
   * @returns filter component JSX
   */
  const getLiteralChartFilter = (filter: FilterType) => (
    <DropdownCheckbox
      itemOptions={getAllAvailableValues(filter.field).map((item) => ({
        title: item,
        value: item,
      }))}
      selectedItems={
        filter.selectedValues ?? getAllAvailableValues(filter.field)
      }
      setSelectedItems={(list) => {
        let updatedFilter = { ...filter };
        updatedFilter.selectedValues = list;
        updateFilterQuery(
          [
            ...getFirstTableFilterGroup(chart).map((f) => {
              if (f.field === filter.field) return updatedFilter;
              return filter;
            })!,
          ],
          layout.position,
          chart.chartPosition
        );
      }}
      isAllChecked={
        filter.selectedValues?.length ===
          getAllAvailableValues(filter.field).length ||
        filter.selectedValues === undefined
      }
      checkAllCallBack={(e: CheckboxChangeEvent) => {
        let updatedFilter = { ...filter };
        updatedFilter.selectedValues = e.target.checked
          ? getAllAvailableValues(filter.field)
          : [];
        updateFilterQuery(
          [
            ...getFirstTableFilterGroup(chart).map((f) => {
              if (f.field === filter.field) return updatedFilter;
              return filter;
            })!,
          ],
          layout.position,
          chart.chartPosition
        );
      }}
      additionalClassNames="width-30"
      designVersion2
      size={INPUT_SIZE.SMALL}
      key={filter.field}
    />
  );

  /**
   * @function getTimeChartFilter
   * @description get filter component for time field
   * @param filter filter object
   * @returns filter component JSX
   */
  const getTimeChartFilter = (filter: FilterType) => (
    <DatePicker
      className="width-30"
      onChange={(_dates: any, dateString: [string, string]) =>
        updateFilterQuery(
          [
            ...getFirstTableFilterGroup(chart).map((f) => {
              if (f.field === filter.field)
                return { ...filter, selectedValues: dateString };
              return filter;
            })!,
          ],
          layout.position,
          chart.chartPosition
        )
      }
      key={filter.field}
    />
  );

  /**
   * @function getChartFilters
   * @description get filter components for the chart
   * @returns filter components JSX
   */
  const getChartFilters = () => {
    return (
      <div className="flex flex-gap-16">
        {getFirstTableFilterGroup(chart)
          .filter(
            (filter) =>
              filter.field === FIELD_TYPE.LITERAL ||
              filter.field === FIELD_TYPE.TIME
          )
          .map((filter) => {
            if (getFieldCategory(filter.field) === FIELD_TYPE.LITERAL)
              return getLiteralChartFilter(filter);
            return getTimeChartFilter(filter);
          })}
      </div>
    );
  };

  return (
    <AccessibleDiv
      className={`custom-table-or-chart-data flex flex-column flex-gap-16 ${
        customDashBoardMode === CUSTOM_DASHBOARD_MODE.DEV && 'cursor-pointer'
      } ${
        customDashBoardMode === CUSTOM_DASHBOARD_MODE.DEV &&
        isCustomChartSelected(layout.position, chart.chartPosition) &&
        'selected'
      } ${isExpanded && 'expanded-view full-height'}`}
      id="graph-container"
      onClick={() => {
        dispatch(setSelectedChartRow(layout.position));
        dispatch(setSelectedChartIndex(chart.chartPosition));
      }}
    >
      <GraphHeader
        heading={`custom-graph-${layout.position}${chart.chartPosition}`}
        headingComponent={getGraphHeadingComponent()}
        graphName={`custom-graph-${layout.position}${chart.chartPosition}`}
        designVersion2
        filters={!isTableView ? getChartFilters() : []}
        isTableViewSwitch={chart.chartType !== CHART_TYPES.TABLE && !pdfView}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        showExpandIcon={
          customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED && !pdfView
        }
        isDownloadable={
          !pdfView && customDashBoardMode === CUSTOM_DASHBOARD_MODE.PUBLISHED
        }
        excelData={{
          sheetName:
            (chart.chartName ?? t('customDashboard.headerLabels.addATitle')) +
            `${layout.position}${chart.chartPosition}`,
          columns: [
            { header: '#', key: 'slNo', alignment: 'center' },
            ...(
              (isTableView
                ? chart.tableQuery?.columns
                : chart.chartQuery?.columns) ?? []
            ).map((column) => ({
              header: column.label,
              key: column.field.replaceAll('.', '_'),
            })),
          ],
          data: insertIndex(getTableChartData()?.tableData || []),
          filters: [
            {
              heading: t('excelExportLabels.connectionName'),
              value: getDashboardDatasourceName(selectedDashboard),
            },
            ...getFirstTableFilterGroup(chart).map((item) => ({
              heading: getColumnLabelByField(item.field, chart),
              value: item?.selectedValues?.join(',') ?? '',
            })),
          ],
        }}
      />
      {getTableChartComponent()}
    </AccessibleDiv>
  );
};

export default CustomTableOrChartData;
