import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceStatus } from 'pages/ThirdPartyPage/components/GProfilerConnectionPage/TableView/constants';
import Table from 'components/Table';
import ConfirmModal from 'components/ConfirmModal';
import DeleteModal from 'components/DeleteModal';
import { REQUEST_STATUS } from 'constants/requestBody';
import { REFRESH_INTERVAL } from 'constants/defaultValues';
import { ConnectionList } from 'types/gProfiler';

import './index.scss';

type GranulateAppsTableProps = {
  connectionList: ConnectionList[];
  fetchDashboardServices: (addLoader?: boolean) => void;
  columns: any[];
  requestStatus: string;
  actionRecord: ConnectionList | undefined;
  statusUpdateRequestStatus?: string;
  updateConnectionStatus: (status: string) => void;
  showActivateConfirmModal: boolean;
  showDeactivateConfirmModal: boolean;
  showDeleteModal: boolean;
  setShowActivateConfirmModal: (val: boolean) => void;
  setShowDeactivateConfirmModal: (val: boolean) => void;
  setShowDeleteModal: (val: boolean) => void;
  handleDelete: () => void;
  refreshInterval?: number;
  showUninstallModal?: boolean;
  setShowUninstallModal?: (val: boolean) => void;
  handleUninstallation?: () => void;
  loadUninstall?: boolean;
};

const GranulateAppsTable = ({
  connectionList,
  columns,
  actionRecord,
  requestStatus,
  statusUpdateRequestStatus = REQUEST_STATUS.SUCCESS,
  fetchDashboardServices,
  updateConnectionStatus,
  showActivateConfirmModal,
  showDeactivateConfirmModal,
  showDeleteModal,
  setShowActivateConfirmModal,
  setShowDeactivateConfirmModal,
  setShowDeleteModal,
  handleDelete,
  refreshInterval = REFRESH_INTERVAL,
  showUninstallModal,
  setShowUninstallModal,
  handleUninstallation,
  loadUninstall,
}: GranulateAppsTableProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchDashboardServices();
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => fetchDashboardServices(false),
      refreshInterval
    );
    return () => clearInterval(interval);
  }, [connectionList]);

  return (
    <div className="connection-table-view full-width">
      <Table
        pagination={false}
        dataSource={connectionList}
        columns={columns}
        designVersion2
        loading={requestStatus === REQUEST_STATUS.PROCESSING}
        scroll={{ y: 'calc(100vh - 14em)' }}
      />
      {showActivateConfirmModal && (
        <ConfirmModal
          show={showActivateConfirmModal}
          setShow={setShowActivateConfirmModal}
          title={t('gProfilerConnectionPage.doYouConfirm')}
          subTitle={
            <>
              {t('gProfilerConnectionPage.activateConfirm')}{' '}
              <span className="font-button">
                {actionRecord?.connectionName}
              </span>
              {'. '}
              {t('gProfilerConnectionPage.deactivateAgainInfo')}
            </>
          }
          loadingYes={statusUpdateRequestStatus === REQUEST_STATUS.PROCESSING}
          onClickYes={() => updateConnectionStatus(ServiceStatus.ACTIVATE)}
          onClickNo={() => setShowActivateConfirmModal(false)}
        />
      )}
      {showDeactivateConfirmModal && (
        <ConfirmModal
          show={showDeactivateConfirmModal}
          setShow={setShowDeactivateConfirmModal}
          title={t('gProfilerConnectionPage.doYouConfirm')}
          subTitle={
            <>
              {t('gProfilerConnectionPage.deActivateConfirm')}{' '}
              <span className="font-button">
                {actionRecord?.connectionName}
              </span>
              {'. '}
              {t('gProfilerConnectionPage.activateAgainInfo')}
            </>
          }
          loadingYes={statusUpdateRequestStatus === REQUEST_STATUS.PROCESSING}
          onClickYes={() => updateConnectionStatus(ServiceStatus.DEACTIVATE)}
          onClickNo={() => setShowDeactivateConfirmModal(false)}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletionFunction={handleDelete}
          loading={false}
        />
      )}
      {showUninstallModal && setShowUninstallModal && (
        <DeleteModal
          showDeleteModal={showUninstallModal}
          setShowDeleteModal={setShowUninstallModal}
          deletionFunction={handleUninstallation!}
          loading={loadUninstall}
        />
      )}
    </div>
  );
};

export default GranulateAppsTable;
