import i18n from 'i18n';

export const PROGRESS_BAR_ITEMS = [
  {
    title: i18n.t('connectionCSPForm.steps.titleFirst'),
    description: i18n.t('connectionCSPForm.steps.descriptionFirst'),
  },
  {
    title: i18n.t('connectionCSPForm.steps.titleSecond'),
    description: i18n.t('connectionCSPForm.steps.descriptionSecond'),
  },
  {
    title: i18n.t('connectionCSPForm.steps.titleThird'),
    description: i18n.t('connectionCSPForm.steps.descriptionThird'),
  },
  {
    title: i18n.t('connectionCSPForm.steps.titleFourth'),
    description: i18n.t('connectionCSPForm.steps.descriptionFourth'),
  },
  {
    title: i18n.t('connectionCSPForm.steps.titleFifth'),
    description: i18n.t('connectionCSPForm.steps.descriptionFifth'),
  },
  {
    title: i18n.t('connectionCSPForm.steps.titleSixth'),
    description: i18n.t('connectionCSPForm.steps.descriptionSixth'),
  },
  {
    title: i18n.t('connectionCSPForm.steps.titleSeventh'),
    description: i18n.t('connectionCSPForm.steps.descriptionSeventh'),
  },
];

export const BUDGET_ACCESS_PROGRESS_ITEM = {
  title: i18n.t('connectionCSPForm.steps.titleBudgetAccess'),
  description: i18n.t('connectionCSPForm.steps.descriptionBudgetAccess'),
};

export const MANDATORY_BILLING_ACCESS_PROGRESS_ITEM = {
  title: i18n.t('connectionCSPForm.steps.titleMandatoryBillingSetup'),
  description: i18n.t('connectionCSPForm.steps.descriptionFourth'),
};
