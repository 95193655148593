import i18n from 'i18n';
import { store } from 'redux/store';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

export const ApplicationDeatilsExportColumns = [
  { header: '#', key: 'slNo', width: 10, alignment: 'center', dataKey: 'slNo' },
  {
    header: i18n.t('consolidatedDashboard.applicationDetails.name'),
    dataKey: 'name',
    key: 'name',
    alignment: 'left',
    width: 40,
  },
  {
    header: i18n.t('consolidatedDashboard.applicationDetails.namespace'),
    dataKey: 'namespace',
    key: 'namespace',
    alignment: 'left',
    width: 20,
  },
  {
    header: i18n.t('consolidatedDashboard.applicationDetails.pods'),
    key: 'pods',
    dataKey: 'pods',
    alignment: 'left',
    width: 15,
  },
  {
    header: i18n.t('consolidatedDashboard.applicationDetails.cpuUtilization'),
    key: 'cpuUtilisation',
    dataKey: 'cpuUtilisation',
    alignment: 'left',
  },
  {
    header: i18n.t(
      'consolidatedDashboard.applicationDetails.memoryUtilization'
    ),
    key: 'memoryUtilisation',
    dataKey: 'memoryUtilisation',
    alignment: 'left',
    width: 80,
  },
  {
    header: i18n.t('consolidatedDashboard.applicationDetails.costCredit', {
      currencySymbol: currencySymbol,
    }),
    key: 'costAfterCredit',
    dataKey: 'costAfterCredit',
    alignment: 'left',
    width: 80,
  },
];
