import { Radio } from 'antd';
import AccessibleDiv from 'components/AccessibleDiv';
import { getLabelledDayFromDate } from 'utils/date';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { ConsumptionManagementType } from 'pages/CostOptimizationInsightsPage/types';

import './index.scss';

type DashboardListItemProps = {
  onClick: () => void;
  connection: ConsumptionManagementType;
  isChecked: boolean;
};

const DashboardListItem = ({
  onClick,
  connection,
  isChecked,
}: DashboardListItemProps) => {
  return (
    <div className="dashboard-list-item flex flex-space-between flex-align-items-center">
      <AccessibleDiv
        className={`flex flex-center flex-gap-16 cursor-pointer `}
        onClick={onClick}
      >
        <Radio checked={isChecked} />
        <img
          src={getProviderSmallLogo(connection?.provider)}
          alt={`${connection?.provider} Logo`}
          width={35}
          height={35}
          className="provider-logo"
        />
        <div className={`dashboard-details flex flex-column flex-gap-4`}>
          <div className="dashboard-name-container">
            <span className="font-button">
              {connection.displayName ?? connection.name}
            </span>
          </div>
          <span className="connection-name font-caption-bold">
            {connection?.connectionName}
          </span>
        </div>
      </AccessibleDiv>
      <div>
        <span className="weeks-label font-caption-bold">
          {getLabelledDayFromDate(connection.createdAt)}
        </span>
      </div>
    </div>
  );
};
export default DashboardListItem;
