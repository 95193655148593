import i18n from 'i18n';
import {
  EmploymentTypes,
  ManualEntryNavs,
  SoftwareSubscriptionTypes,
} from 'pages/DataCenterPage/constants';

export const MANUAL_ENTRY_NAVIGATION = [
  {
    id: ManualEntryNavs.COMPUTE,
    title: i18n.t('manualEntry.nav.compute'),
  },
  {
    id: ManualEntryNavs.STORAGE,
    title: i18n.t('manualEntry.nav.storage'),
  },
  {
    id: ManualEntryNavs.NETWORK,
    title: i18n.t('manualEntry.nav.network'),
  },
  {
    id: ManualEntryNavs.DATABASE,
    title: i18n.t('manualEntry.nav.database'),
  },
  {
    id: ManualEntryNavs.SOFTWARE,
    title: i18n.t('manualEntry.nav.software'),
  },
  {
    id: ManualEntryNavs.LABOUR,
    title: i18n.t('manualEntry.nav.labor'),
  },
];

export enum EntryQuickActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const ENTRY_QUICKACTIONS = [
  { id: EntryQuickActions.EDIT, title: i18n.t('manualEntry.edit') },
  { id: EntryQuickActions.DELETE, title: i18n.t('manualEntry.delete') },
];

export const TypesOfSoftwareSubscriptions = [
  {
    key: SoftwareSubscriptionTypes.SUBSCRIPTION,
    label: i18n.t('manualEntry.softwareLabels.subscription'),
  },
  {
    key: SoftwareSubscriptionTypes.PERMANENT_PURCHASE,
    label: i18n.t('manualEntry.softwareLabels.permanentPurchase'),
  },
];

export const TypesOfEmployment = [
  {
    key: EmploymentTypes.CONTRACTUAL,
    label: i18n.t('manualEntry.laborLabels.contractual'),
  },
  {
    key: EmploymentTypes.PERMANENT,
    label: i18n.t('manualEntry.laborLabels.permanent'),
  },
];
