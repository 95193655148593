import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import SuccessComponent from 'components/SuccessComponent';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { useNavigate } from 'react-router-dom';
import { userAuthorization } from 'redux/authorizationSlice';
import { Empty, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/date';
import { debounce } from 'lodash';
import { SuccessIcon } from 'assets/icons';
import Table from 'components/Table';
import Input from 'components/Input';
import { INPUT_SIZE } from 'constants/appearance';
import NavigationPath from 'components/NavigationPath';
import Pagination from 'components/Pagination';
import QuickActionMenu from 'components/QuickActionMenu';
import DeleteModal from 'components/DeleteModal';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { DEBOUNCE_TIME_DELAY, PAGINATION_SIZE } from 'constants/userConsole';
import CreateGroupModal from './components/CreateGroupModal';
import { deleteGroup, fetchGroups, searchGroupData } from './services';
import { GroupType } from './types';
import { GroupsQuickActions, GROUPS_QUICK_ACTIONS } from './constants';

import './index.scss';

const Groups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { permissions } = useSelector(userAuthorization);

  const [groups, setGroups] = useState<GroupType[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupType>();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [totalGroupsCount, setTotalGroupsCount] = useState<number>(0);
  const [loadingGetGroupsApiRequest, setLoadingGetGroupsApiRequest] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [groupForDelete, setGroupForDelete] = useState<GroupType>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  useEffect(() => {
    fetchAllGroups();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  /**
   * @function onFetchApiError
   * @param e Error in catch block
   * @description handles the case for api error for groups and search groups data
   */
  const onFetchApiError = (e: any) => {
    onApiCallError(e, false, setLoadingGetGroupsApiRequest);
    setGroups([]);
    setTotalGroupsCount(0);
  };

  /**
   * @function fetchAllGroups
   * @param page Takes the current page
   * @description Function to fetch groups list
   */
  const fetchAllGroups = (page: number = 1) => {
    setLoadingGetGroupsApiRequest(REQUEST_STATUS.PROCESSING);
    const params = {
      page: page - 1,
      size: PAGINATION_SIZE,
    };
    fetchGroups(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setGroups(res?.data?.responseData?.content);
          setTotalGroupsCount(res.data.responseData.totalElements);
          setLoadingGetGroupsApiRequest(REQUEST_STATUS.SUCCESS);
        } else {
          setGroups([]);
          setTotalGroupsCount(0);
          setLoadingGetGroupsApiRequest(REQUEST_STATUS.ERROR);
        }
      })
      .catch(onFetchApiError);
  };

  /**
   * @function fetchSearchGroupData
   * @description Function to fetch connection names
   * @param searchString accepts the string in the search input
   * @param page accepts the page number from pagination
   */

  const fetchSearchGroupData = (searchString: string, page: number = 1) => {
    setLoadingGetGroupsApiRequest(REQUEST_STATUS.PROCESSING);
    const params = {
      key: searchString,
      page: page - 1,
      size: PAGINATION_SIZE,
    };
    searchGroupData(params)
      .then((res: any) => {
        setGroups(res.data.responseData.content);
        setTotalGroupsCount(res.data.responseData.totalElements);
        setLoadingGetGroupsApiRequest(REQUEST_STATUS.SUCCESS);
      })
      .catch(onFetchApiError);
  };
  /**
   * @function deleteSelectedGroup
   * @description Function to delete a selected group
   * @param group accepts the group object to be deleted
   */
  const deleteSelectedGroup = (group: GroupType) => {
    setDeleteLoadingStatus(REQUEST_STATUS.PROCESSING);
    deleteGroup(group.name)
      .then((res: any) => {
        if (res?.status === 200) {
          message.success(
            t('groupsLabels.groupsTableLabels.deleteSuccessMessage')
          );
          fetchAllGroups();
          setDeleteLoadingStatus(REQUEST_STATUS.SUCCESS);
          setShowDeleteModal(false);
        } else {
          message.error(
            t('groupsLabels.groupsTableLabels.deleteFailureMessage')
          );
          setDeleteLoadingStatus(REQUEST_STATUS.ERROR);
        }
      })
      .catch((e) => {
        onApiCallError(
          e,
          true,
          setDeleteLoadingStatus,
          t('groupsLabels.groupsTableLabels.deleteFailureMessage')
        );
        message.error(t('groupsLabels.groupsTableLabels.deleteFailureMessage'));
        setDeleteLoadingStatus(REQUEST_STATUS.ERROR);
      });
  };

  /**
   * @function styleAndReduceAllConnectionNames
   * @description Function to return the styled component of the connection names
   * @param connectionNames List of connection names for which the component is built.
   */
  const styleAndReduceAllConnectionNames = (connectionNames: string[]) => {
    if (connectionNames.length > 2) {
      return [
        connectionNames.slice(0, 2).map((name: string, index: number) => (
          <span className="group-connection-names" key={name}>
            {name}
          </span>
        )),
        <span className="group-connection-names" key={connectionNames.length}>
          {`+${connectionNames.length - 2}`}
        </span>,
      ];
    }
    return connectionNames.map((name: string, index: number) => (
      <span className="group-connection-names" key={name}>
        {name}
      </span>
    ));
  };

  /**
   * @function onChangePagination
   * @description Function to handle pagination
   * @param page:accepts the page number from pagination
   */
  const onChangePagination = (page: number) => {
    setCurrentPage(page);
    if (searchKey.length === 0) {
      fetchAllGroups(page);
    } else {
      fetchSearchGroupData(searchKey, page);
    }
  };

  /**
   * @function handleSearchGroup
   * @description Function to handle group search
   * @param e :accepts search input event
   */
  const handleSearchGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchKey(e.target.value);
    fetchSearchGroupData(e.target.value);
  };

  const handleQuickAction = (group: GroupType, action: string) => {
    switch (action) {
      case GroupsQuickActions.EDIT:
        setSelectedGroup(group);
        setShowCreateGroupModal(true);
        break;
      case GroupsQuickActions.DELETE:
        setGroupForDelete(group);
        setShowDeleteModal(true);
        break;
    }
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * PAGINATION_SIZE + index + 1,
    },
    {
      title: () => t('groupsLabels.groupsTableLabels.groupName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: () => t('groupsLabels.groupsTableLabels.connectionName'),
      dataIndex: 'connectorIds',
      key: 'connectorIds',
      render: (_text: string, record: GroupType) => {
        return styleAndReduceAllConnectionNames(
          record.connectorDtos.map((item) => item.name)
        );
      },
    },
    {
      title: t('groupsLabels.groupsTableLabels.createdDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => {
        return text && moment(text).format(DATE_FORMAT);
      },
    },
    {
      title: t('groupsLabels.groupsTableLabels.quickAction'),
      dataIndex: 'quickAction',
      key: 'quickAction',
      render: (_text: string, record: GroupType) => (
        <QuickActionMenu
          quickActions={GROUPS_QUICK_ACTIONS}
          quickActionHandler={(action: string) => {
            handleQuickAction(record, action);
          }}
          disabled={!permissions.cloudConnectorModify}
        />
      ),
    },
  ];

  const getComponent = () => {
    if (showSuccessPage) {
      return (
        <SuccessComponent
          mainTitle={
            selectedGroup
              ? t('groupsLabels.successPageLabels.successfullyUpdated')
              : t('groupsLabels.successPageLabels.successfullyCreated')
          }
          subTitle={
            selectedGroup
              ? t('groupsLabels.successPageLabels.updateSubTitle')
              : t('groupsLabels.successPageLabels.subTitle')
          }
          buttonTitle={t('groupsLabels.successPageLabels.goToDashboard')}
          linkTitle={t('groupsLabels.successPageLabels.createNewGroup')}
          onHandleButtonClick={() =>
            navigate(NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS)
          }
          onHandleLinkClick={() => {
            setSelectedGroup(undefined);
            setShowSuccessPage(false);
            setShowCreateGroupModal(true);
          }}
          icon={<SuccessIcon />}
        />
      );
    }

    return (
      <div className="table-section">
        <Input
          placeholder={t('connectionTable.searchByName')}
          type="search"
          rootClassName="search-input"
          size={INPUT_SIZE.SMALL}
          onChange={debounce(handleSearchGroup, DEBOUNCE_TIME_DELAY)}
        />
        <Table
          pagination={false}
          dataSource={groups?.map((group: any, index) => ({
            ...group,
            key: index,
          }))}
          columns={columns}
          designVersion2
          loading={loadingGetGroupsApiRequest === REQUEST_STATUS.PROCESSING}
          locale={{
            emptyText: loadingGetGroupsApiRequest !==
              REQUEST_STATUS.PROCESSING && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  loadingGetGroupsApiRequest === REQUEST_STATUS.ERROR
                    ? t('graphErrorMessage')
                    : t('groupsLabels.noGroupsListMessage')
                }
              />
            ),
          }}
        />
      </div>
    );
  };

  return (
    <div className="groups-page flex flex-column flex-fit">
      <header className="new-page-header">
        <div className="title-container flex flex-align-items-center flex-space-between">
          <span className="modal-heading">{t('navigationMenu.groups')}</span>
          {permissions.cloudConnectorWrite && (
            <Button
              title={t('groupsLabels.createGroup')}
              onClick={() => {
                setSelectedGroup(undefined);
                setShowCreateGroupModal(true);
              }}
            />
          )}
        </div>
      </header>
      <section className="page-content flex flex-column flex-fit">
        <NavigationPath />
        {getComponent()}
      </section>
      <footer className="page-footer flex">
        {totalGroupsCount > 0 && (
          <Pagination
            current={currentPage}
            onChange={onChangePagination}
            total={totalGroupsCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </footer>
      <CreateGroupModal
        show={showCreateGroupModal}
        setShow={setShowCreateGroupModal}
        setShowSuccessPage={setShowSuccessPage}
        fetchAllGroups={fetchAllGroups}
        editGroup={selectedGroup}
      />
      {groupForDelete && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={() => {
            deleteSelectedGroup(groupForDelete);
          }}
          loading={deleteLoadingStatus === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default Groups;
