import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectDashboard,
  setPdfGraphToDownload,
  setPdfDownloadMode,
  setTableViewEnabled,
} from 'redux/dashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import Switch from 'components/Switch';
import Button from 'components/Button';
import ConnectionsListDrawer from 'components/ConnectionsListDrawer';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { PDF_ALL_GRAPHS } from 'constants/pdfConstants';
import { LoadingIcon } from 'assets/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { exportToExcel } from 'utils/exportToExcel';
import { searchConnectionData } from 'utils/services';
import { ConnectionListType } from 'types/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import { PROVIDER } from 'constants/cloudProviders';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import AutoPilotDashboardTitle from '../AutoPilotDashboardTitle';

import './index.scss';

type AutoPilotSavingsDashboardHeaderProps = {
  selectedConnection: ConnectionListType | null;
  setSelectedConnection: (val: ConnectionListType | null) => void;
  setIsEmptyDashboard: (val: boolean) => void;
  setRequestStatus: (val: string) => void;
};

const AutoPilotSavingsDashboardHeader = ({
  selectedConnection,
  setSelectedConnection,
  setIsEmptyDashboard,
  setRequestStatus,
}: AutoPilotSavingsDashboardHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { exportToExcelData, tableViewEnabled, pdfDownloadMode } =
    useSelector(selectDashboard);
  const { theme } = useSelector(selectTheme);

  const [showSwitchConnectionDrawer, setShowSwitchConnectionDrawer] =
    useState(false);
  const [connectionList, setConnectionList] = useState<ConnectionListType[]>(
    []
  );
  const [isDownloadingData, setIsDownloadingData] = useState(false);
  const [fetchConnectionsRequestStatus, setFetchConnectionsRequestStatus] =
    useState(REQUEST_STATUS.PROCESSING);

  useEffect(() => {
    fetchConnections();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  useEffect(() => {
    if (connectionList.length === 0) {
      return;
    }

    if (
      !selectedConnection ||
      !connectionList.some(
        (availableDashboard) =>
          availableDashboard.connectorId === selectedConnection.connectorId
      )
    ) {
      setSelectedConnection(connectionList[0]);
    }
  }, [connectionList]);

  /**
   * @function fetchConnections
   * @description Function to fetch the connection list
   */
  const fetchConnections = () => {
    setFetchConnectionsRequestStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      provider: [PROVIDER.GCP, PROVIDER.AWS].join(','),
    };

    searchConnectionData(params)
      .then((res: any) => {
        let connectionsWithRecommendationDataset: ConnectionListType[] = (
          res?.data?.responseData?.content || []
        ).filter((connection: any) => connection.wantRecommendations);

        setConnectionList(connectionsWithRecommendationDataset);
        setIsEmptyDashboard(connectionsWithRecommendationDataset.length === 0);

        setFetchConnectionsRequestStatus(REQUEST_STATUS.SUCCESS);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchConnectionsRequestStatus);
        setConnectionList([]);
        setRequestStatus(REQUEST_STATUS.ERROR);
      });
  };

  /**
   * @function onHandleDownloadDashboardData
   * @description function to handle common download of dashboard view.
   * @returns either pdf or excel
   */
  const onHandleDownloadDashboardData = () => {
    setIsDownloadingData(true);
    if (!tableViewEnabled) {
      dispatch(setPdfGraphToDownload(PDF_ALL_GRAPHS));
      dispatch(setPdfDownloadMode(true));
      return;
    }
    setIsDownloadingData(false);
    return exportToExcel(selectedConnection?.name ?? '', exportToExcelData);
  };

  return (
    <div className="autopilot-savings-dashboard-header new-page-header flex flex-align-items-center flex-space-between flex-wrap">
      {selectedConnection ? (
        <AutoPilotDashboardTitle selectedConnection={selectedConnection} />
      ) : (
        <Icon icon={LoadingIcon} className="rotate" />
      )}
      <div className="header-right flex flex-align-items-center flex-gap-16">
        {isDownloadingData ? (
          <Icon icon={LoadingIcon} className="rotate" />
        ) : (
          <Icon
            iconName={ICONS.DOWNLOAD_2_LINE}
            onClick={() => onHandleDownloadDashboardData()}
            size={ICONS_SIZE.ONE_X}
            color={theme.buttonIconColor}
            dataTestId="download-cta"
          />
        )}
        <div className="table-view-title table-typography flex flex-align-items-center flex-gap-8">
          {t('tableView')}
          <Switch
            size="small"
            checked={tableViewEnabled}
            onChange={(value: boolean) => {
              dispatch(setTableViewEnabled(value));
            }}
          />
        </div>
        <Button
          title={t('dashboardLabels.switchConnection')}
          iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
          iconSize={ICONS_SIZE.ONE_X}
          onClick={() => setShowSwitchConnectionDrawer(true)}
        />
      </div>
      <ConnectionsListDrawer
        isCB360TabSelected={true}
        show={showSwitchConnectionDrawer}
        setShow={setShowSwitchConnectionDrawer}
        selectedConnection={selectedConnection}
        connectionList={connectionList}
        onClick={(key: any) => {
          setSelectedConnection(
            connectionList.find(
              (connection) => connection.connectorId === key
            ) ?? null
          );
          setShowSwitchConnectionDrawer(false);
        }}
        loading={fetchConnectionsRequestStatus === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );
};

export default AutoPilotSavingsDashboardHeader;
