import { Navigate, useLocation } from 'react-router-dom';

import AppLayout from 'components/AppLayout';
import { checkRouteAuthorization } from 'utils/navigationMenu';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';

const AuthorizedRoute = () => {
  const location = useLocation().pathname;
  return checkRouteAuthorization(location) ? (
    <AppLayout />
  ) : (
    <Navigate to={NAVIGATION_MENU_PATH.NOT_AUTHORIZED} />
  );
};

export default AuthorizedRoute;
