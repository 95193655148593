import { useState, useEffect } from 'react';
import { Upload, Radio, Space } from 'antd';
import { useSelector } from 'react-redux';
import { userAuthorization } from 'redux/authorizationSlice';
import { useTranslation } from 'react-i18next';
import { GCPConnection } from 'redux/gProfilerConnectionSlice';
import { getGcpProjectDatasets } from 'pages/ConnectingCSPPage/services';
import { FormLabel } from 'components/FormLabel';
import CheckRadio from 'components/CheckRadio';
import SelectDropDown from 'components/Select';
import Input from 'components/Input';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import AccessibleDiv from 'components/AccessibleDiv';
import { BUTTON_TYPE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { GProfilerConnectionRadioValue } from 'constants/gProfiler';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { PROVIDER } from 'constants/cloudProviders';
import { onApiCallError } from 'utils/handleErrors';
import { TickFillError } from 'assets/icons';
import {
  uploadConnectionFile,
  deleteConnectionFile,
  addNewCSPConnection,
  searchConnectionData,
} from 'utils/services';
import {
  MAX_CHARACTER_LIMIT,
  VALIDATION_MIN_INPUT_LENGTH,
} from 'constants/validation';
import {
  validateEmptyField,
  validateStringLengthLessThan,
  containsUnderscoreAndSpace,
  isNumber,
  validateContainsOnlyAlphabets,
  getValidationStyle,
} from 'utils/validations';

import ConnectionFormHeader from '../../components/GProfilerConnectionPage/components/ConnectionForm/components/ConnectionFormHeader';
import ConnectionFormCard from '../../components/GProfilerConnectionPage/components/ConnectionForm/components/Card';

const { Dragger } = Upload;

type GranulateAppsGCPConnectionProps = {
  setSubmitDisabled: (val: boolean) => void;
  gcpConnection: GCPConnection;
  serviceList: string[];
  fetchServiceList: () => void;
  zones: string[];
  fetchZones: () => void;
  clusterList: string[];
  setClusterList: (clusterList: string[]) => void;
  fetchClusterList: (zone: string) => void;
  fetchingClusterStatus: string;
  setFetchingClusterStatus: (status: string) => void;
  inputLoaderStatus: string;
  setInputLoaderStatus: (status: string) => void;
  resetGCPConnection: () => void;
  addConnectorIdForNewConnection: (connectorId: string) => void;
  removeJson: () => void;
  dispatchCluster: (cluster: string) => void;
  dispatchRegionAndResetingCluster: (region: string) => void;
  dispatchServiceName: (serviceName?: string) => void;
  dispatchCloudConnectionAndConnectorId: (
    cloudConnection: string,
    connectorId: string
  ) => void;
  dispatchConnectionName: (connectionName: string) => void;
  dispatchJsonField: (keyJson: any) => void;
  dispatchProject?: (project: string) => void;
  hideSetupConnection?: boolean;
  showProjectDropdown?: boolean;
  projects?: string[];
  setProjects?: (project: string[]) => void;
  projectsRequestStatus?: string;
  setProjectsRequestStatus?: (status: string) => void;
};

const GranulateAppsGCPConnection = ({
  setSubmitDisabled,
  gcpConnection,
  serviceList,
  fetchServiceList,
  zones,
  fetchZones,
  clusterList,
  setClusterList,
  fetchClusterList,
  fetchingClusterStatus,
  setFetchingClusterStatus,
  inputLoaderStatus,
  setInputLoaderStatus,
  resetGCPConnection,
  addConnectorIdForNewConnection,
  removeJson,
  dispatchCluster,
  dispatchRegionAndResetingCluster,
  dispatchCloudConnectionAndConnectorId,
  dispatchServiceName,
  dispatchConnectionName,
  dispatchProject,
  dispatchJsonField,
  hideSetupConnection = false,
  showProjectDropdown = false,
  projects,
  setProjects,
  projectsRequestStatus,
  setProjectsRequestStatus,
}: GranulateAppsGCPConnectionProps) => {
  const { t } = useTranslation();
  const { permissions } = useSelector(userAuthorization);

  const [checkValue, setCheckValue] = useState<string>(
    GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION
  );
  const [region, setRegion] = useState<any>('');
  const [cluster, setCluster] = useState<string | undefined>(undefined);
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
  const [fileName, setFileName] = useState('');
  const [connectionList, setConnectionList] = useState<any[]>([]);
  const [showValidatedButton, setShowValidatedButton] = useState<boolean>(true);
  const [cloudConnectionValidation, setCloudConnectionValidation] =
    useState('');
  const [validationRequestStatus, setValidationRequestStatus] =
    useState<string>('');
  const [serviceNameValidation, setServiceNameValidation] = useState('');
  const [regionValidation, setRegionValidation] = useState('');
  const [clusterValidation, setClusterValidation] = useState('');
  const [connectionNameValidation, setConnectionNameValidation] = useState('');
  const [keyJsonValidation, setKeyJsonValidation] = useState('');
  const [projectValidation, setProjectValidation] = useState('');
  const [uploadFileData, setUploadFileData] = useState<any>(undefined);
  const isConnectionNameEmpty = !gcpConnection.connectionName;

  const isButtonDisabled =
    !uploadFileData || isConnectionNameEmpty || connectionNameValidation;

  useEffect(() => {
    resetGCPConnection();
    setInputLoaderStatus(REQUEST_STATUS.SUCCESS);
    onRemoveFile();
    setCloudConnectionValidation('');
    setServiceNameValidation('');
    setRegionValidation('');
    setConnectionNameValidation('');
    setClusterValidation('');
    setKeyJsonValidation('');
  }, [checkValue]);

  useEffect(() => {
    setShowValidatedButton(true);
  }, [gcpConnection.file, gcpConnection.connectionName, uploadFileData]);

  useEffect(() => {
    fetchConnectionData();
    fetchServiceList();
  }, []);

  useEffect(() => {
    if (gcpConnection.connectorId) {
      fetchZones();
    }
  }, [gcpConnection.connectorId, gcpConnection.project]);

  useEffect(() => {
    if (gcpConnection.connectorId) {
      if (showProjectDropdown) {
        setProjects?.([]);
        dispatchProject?.('');
        fetchAndSetProjects();
      }
    }
  }, [gcpConnection.connectorId]);

  useEffect(() => {
    setCluster('');
    setRegion('');
    setClusterList([]);
    setFetchingClusterStatus(REQUEST_STATUS.SUCCESS);
  }, [gcpConnection.cloudConnection, gcpConnection.file]);

  useEffect(() => {
    setSubmitDisabled(!validateAllFields(false));
  }, [gcpConnection]);

  /**
   * @function fetchConnectionData
   * @description Function to get connection list of GCP
   */
  const fetchConnectionData = () => {
    searchConnectionData({ provider: PROVIDER.GCP })
      .then((res: any) => {
        setConnectionList(res?.data?.responseData?.content);
      })
      .catch((e) => onApiCallError(e, true));
  };

  /**
   * @function fetchAndSetProjects
   * @description Function to fetch the connection data and set projects
   */
  const fetchAndSetProjects = () => {
    setProjectsRequestStatus?.(REQUEST_STATUS.PROCESSING);
    getGcpProjectDatasets({ connectorId: gcpConnection.connectorId ?? '' })
      .then((res: any) => {
        setProjects?.(
          res?.data?.responseData?.map((item: any) => item.project) ?? []
        );
        setProjectsRequestStatus?.(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, true, setProjectsRequestStatus);
        setProjects?.([]);
      });
  };

  /**
   * @function handleValidation
   * @description Function to validate json key to get connectorId.
   */
  const handleValidation = () => {
    const { connectionName, file } = gcpConnection;
    if (connectionName && file) {
      setValidationRequestStatus(REQUEST_STATUS.PROCESSING);
      const body = {
        provider: PROVIDER.GCP,
        name: connectionName,
        jsonFileId: file.jsonFileId,
        displayName: connectionName,
        wantBilling: false,
        migrated: false,
        wantRecommendations: false,
        wantCarbonFootprint: false,
      };

      addNewCSPConnection(body)
        .then((res: any) => {
          if (res.status === 200) {
            setValidationRequestStatus(REQUEST_STATUS.SUCCESS);
            setShowValidatedButton(false);

            addConnectorIdForNewConnection(res?.data?.responseData);

            fetchConnectionData();
          } else {
            setValidationRequestStatus(REQUEST_STATUS.ERROR);
            setShowValidatedButton(false);
          }
        })
        .catch((e) => {
          setShowValidatedButton(false);
          setValidationRequestStatus(REQUEST_STATUS.ERROR);
          onApiCallError(e, true);
        });
    }
  };

  /**
   * @function onRemoveFile
   * @description Function to handle remove file
   */
  const onRemoveFile = () => {
    if (gcpConnection?.file) {
      deleteConnectionFile(gcpConnection?.file.jsonFileId);
      setFileName('');
      setUploadFileData(undefined);
      removeJson();
      validateKeyJson('');
    }
  };

  /**
   * @function validateCloudConnection
   * @description Function to validate the cloud connection field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateCloudConnection = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.cloudConnectionLowerCase'),
      setCloudConnectionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateServiceName
   * @description Function to validate the service name field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateServiceName = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (
      validateEmptyField(
        value,
        t('gProfilerConnectionPage.connectionForm.serviceNameLowerCase'),
        setServiceNameValidation,
        addErrorMessage
      )
    ) {
      return false;
    }

    if (!validateContainsOnlyAlphabets(value?.charAt(0) || '')) {
      setServiceNameValidation(t('firstCharacterError'));
      return false;
    }

    if (containsUnderscoreAndSpace(value)) {
      setServiceNameValidation(
        `${t(
          'gProfilerConnectionPage.connectionForm.serviceNameLowerCase'
        )} ${t('underscoreError')}`
      );
      return false;
    }

    if (
      !validateContainsOnlyAlphabets(value?.charAt(value?.length - 1) || '') &&
      !isNumber(value?.charAt(value?.length - 1))
    ) {
      setServiceNameValidation(t('lastCharacterError'));
      return false;
    }

    setServiceNameValidation('');
    return true;
  };

  /**
   * @function validateRegion
   * @description Function to validate the region field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateRegion = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.region'),
      setRegionValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateCluster
   * @description Function to validate the cluster field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateCluster = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.cluster'),
      setClusterValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateKeyJson
   * @description Function to validate the Key json field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateKeyJson = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.keyJson'),
      setKeyJsonValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateConnectionName
   * @description Function to validate the connection name field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateConnectionName = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    if (
      validateEmptyField(
        value,
        t('gProfilerConnectionPage.connectionForm.connectionNameLowerCase'),
        setConnectionNameValidation,
        addErrorMessage
      )
    ) {
      return false;
    }

    if (isNumber(value?.charAt(0))) {
      setConnectionNameValidation(
        t('gProfilerConnectionPage.connectionForm.firstCharacterNumberError', {
          field: t(
            'gProfilerConnectionPage.connectionForm.connectionNameLowerCase'
          ),
        })
      );
      return false;
    }

    if (
      connectionList.some(
        (item) =>
          item?.name?.trim().toLowerCase() === value?.trim()?.toLowerCase()
      )
    ) {
      addErrorMessage &&
        setConnectionNameValidation(
          t('gProfilerConnectionPage.connectionForm.duplicateConnectionName')
        );
      return false;
    }

    if (
      !validateStringLengthLessThan(
        (value || '')?.trim(),
        VALIDATION_MIN_INPUT_LENGTH - 1
      )
    ) {
      addErrorMessage &&
        setConnectionNameValidation(
          t('characterLessThanValidation', {
            field: t(
              'gProfilerConnectionPage.connectionForm.connectionNameLowerCase'
            ),
            characterCount: VALIDATION_MIN_INPUT_LENGTH,
          })
        );
      return false;
    }

    if (
      validateStringLengthLessThan((value ?? '')?.trim(), MAX_CHARACTER_LIMIT)
    ) {
      addErrorMessage &&
        setConnectionNameValidation(
          t('characterGreaterThanValidation', {
            field: t(
              'gProfilerConnectionPage.connectionForm.connectionNameLowerCase'
            ),
            characterCount: MAX_CHARACTER_LIMIT,
          })
        );
      return false;
    }
    return true;
  };

  /**
   * @function validateProject
   * @description Function to validate the project field
   * @param value to be validated
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateProject = (
    value: string | undefined,
    addErrorMessage: boolean = true
  ) => {
    return !validateEmptyField(
      value,
      t('gProfilerConnectionPage.connectionForm.project'),
      setProjectValidation,
      addErrorMessage
    );
  };

  /**
   * @function validateAllFields
   * @description Function to validate all the fields in the form
   * @param addErrorMessage Optional boolean value to set the error message or not
   * @returns true if validation is successful else false
   */
  const validateAllFields = (addErrorMessage: boolean = true) => {
    let validation = true;

    if (checkValue === GProfilerConnectionRadioValue.NEW_CLOUD_CONNECTION) {
      if (!validateKeyJson(gcpConnection.file?.jsonFileId, addErrorMessage)) {
        validation = false;
      }
    }
    if (!validateCloudConnection(gcpConnection.connectorId, addErrorMessage)) {
      validation = false;
    }

    if (!validateServiceName(gcpConnection.serviceName, addErrorMessage)) {
      validation = false;
    }

    if (!validateRegion(gcpConnection.region, addErrorMessage)) {
      validation = false;
    }

    if (!validateCluster(gcpConnection.cluster, addErrorMessage)) {
      validation = false;
    }

    if (
      showProjectDropdown &&
      !validateCluster(gcpConnection.project, addErrorMessage)
    ) {
      validation = false;
    }
    return validation;
  };

  /**
   * @function getClusterOptions
   * @description Function to get the cluster options for the Region and Cluster dropdown
   * @returns JSX element based on the status of the fetch cluster API call status
   */
  const getClusterOptions = () => {
    switch (fetchingClusterStatus) {
      case REQUEST_STATUS.PROCESSING:
        return <Loader />;
      case REQUEST_STATUS.SUCCESS:
        return (
          <Radio.Group
            value={cluster}
            onChange={(e: any) => {
              setCluster(e.target.value);

              dispatchCluster(e.target.value);

              validateCluster(e.target.value);
            }}
          >
            <Space direction="vertical">
              {clusterList?.length > 0 ? (
                clusterList.map((clusterObj: any) => (
                  <CheckRadio
                    value={clusterObj.clusterName}
                    className="font-caption"
                    key={clusterObj.clusterName}
                    disabled={clusterObj.status}
                  >
                    {clusterObj.clusterName}
                  </CheckRadio>
                ))
              ) : (
                <span className="font-caption">
                  {t(
                    'gProfilerConnectionPage.connectionForm.clusterNotAvailable'
                  )}
                </span>
              )}
            </Space>
          </Radio.Group>
        );
      case REQUEST_STATUS.ERROR:
        return (
          <span className="font-validation-error">
            {t('gProfilerConnectionPage.connectionForm.clusterError')}
          </span>
        );
    }
  };

  const getRegionAndClusterDropdownRender = () => (
    <div className="region-cluster flex">
      <div className="region flex-fit flex flex-column flex-gap-8 new-styled-scroll">
        <span className="region-header font-small-bold">
          {t('gProfilerConnectionPage.connectionForm.region')}
        </span>
        <Radio.Group
          value={region}
          onChange={(e: any) => {
            setCluster('');
            setRegion(e.target.value);
            fetchClusterList(e.target.value);

            dispatchRegionAndResetingCluster(e.target.value);

            validateRegion(e.target.value);
          }}
        >
          <Space direction="vertical">
            {zones.map((zone) => (
              <CheckRadio value={zone} className="font-caption" key={zone}>
                {zone}
              </CheckRadio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      <div className="cluster flex-fit flex flex-column flex-gap-4 new-styled-scroll">
        <span className="cluster-header font-small-bold">
          {t('gProfilerConnectionPage.connectionForm.cluster')}
        </span>
        {getClusterOptions()}
      </div>
    </div>
  );

  const getServiceDropdownRender = () => (
    <div className="service-dropdown flex flex-column flex-gap-8">
      <Input
        value={searchKey}
        className="full-width"
        placeholder={t('gProfilerConnectionPage.connectionForm.searchService')}
        type="search"
        onChange={(e: any) => setSearchKey(e.target.value)}
      />
      <div className="flex flex-column">
        {searchKey &&
          serviceList.filter(
            (service) =>
              service.toLowerCase() === (searchKey?.toLowerCase() || '')
          ).length === 0 && (
            <div className="flex flex-space-between full-width flex-align-items-center">
              <span>{searchKey}</span>
              <Button
                className="cursor-pointer"
                type={BUTTON_TYPE.LINK}
                title={t(
                  'gProfilerConnectionPage.connectionForm.addNewService'
                )}
                onClick={() => {
                  dispatchServiceName(searchKey);

                  validateServiceName(searchKey);
                  setSearchKey('');
                }}
              />
            </div>
          )}
        <div className="flex flex-column flex-gap-4">
          {serviceList
            .filter((service) =>
              service.toLowerCase().includes(searchKey?.toLowerCase() ?? '')
            )
            .map((service) => (
              <AccessibleDiv
                className={`font-caption cursor-pointer ${
                  gcpConnection.serviceName === service &&
                  'selected-dropdown-option'
                } `}
                onClick={() => {
                  dispatchServiceName(service);

                  validateServiceName(service);
                  setSearchKey('');
                }}
                key={service}
              >
                {service}
              </AccessibleDiv>
            ))}
        </div>
      </div>
    </div>
  );

  const errorMessage = (message: string) => (
    <div className="flex flex-gap-4">
      <span className="font-caption font-validation-error">{message}</span>
      <Icon icon={TickFillError} />
    </div>
  );

  const ValidationMessage =
    validationRequestStatus === REQUEST_STATUS.SUCCESS ? (
      <div className="flex flex-gap-4">
        <span className="font-caption">
          {t('gProfilerConnectionPage.connectionForm.validationSuccessful')}
        </span>
        <Icon iconName={ICONS.CHECKBOX_CIRCLE_FILL} className="tick-success" />
      </div>
    ) : (
      errorMessage(
        t('gProfilerConnectionPage.connectionForm.keyJsonValidation')
      )
    );

  const DraggerContent = fileName ? (
    <div className="flex flex-gap-4">
      <span className="font-overline">{fileName}</span>
      <Icon
        iconName={ICONS.CLOSE_FILL}
        size={ICONS_SIZE.SM}
        className="small-close-icon flex flex-center"
        onClick={onRemoveFile}
      />
    </div>
  ) : (
    <div className="flex flex-column flex-gap-16">
      <Icon
        iconName={ICONS.UPLOAD_LINE}
        className="small-close-icon flex flex-center"
      />
      <span className="font-subHeader-small">
        {t('gProfilerConnectionPage.connectionForm.dragUploadLabel')}
      </span>
    </div>
  );

  const ValidationContent = showValidatedButton ? (
    <Button
      type={BUTTON_TYPE.NEUTRAL}
      title={t('gProfilerConnectionPage.connectionForm.validate')}
      onClick={handleValidation}
      loading={validationRequestStatus === REQUEST_STATUS.PROCESSING}
      disabled={isButtonDisabled}
    />
  ) : (
    ValidationMessage
  );

  const RegionClusterValidation = regionValidation || clusterValidation;

  const getNewConnectionValidation = () =>
    permissions.cloudConnectorWrite
      ? ValidationContent
      : errorMessage(
          t('gProfilerConnectionPage.connectionForm.connectorWriteValidation')
        );

  return (
    <ConnectionFormCard>
      <ConnectionFormHeader provider={PROVIDER.GCP} />
      <section className="connection-form-section flex-fit full-width flex flex-column flex-gap-16">
        {!hideSetupConnection && (
          <div className="form-item flex flex-column">
            <FormLabel
              title={t(
                'gProfilerConnectionPage.connectionForm.setupConnectionFrom'
              )}
              required={true}
            />
            <Radio.Group
              value={checkValue}
              onChange={(e: any) => {
                setCheckValue(e.target.value);
              }}
            >
              <Space direction="vertical">
                <CheckRadio
                  value={
                    GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION
                  }
                  className="font-caption"
                  checked={true}
                >
                  {t(
                    'gProfilerConnectionPage.connectionForm.existingCloudConnection'
                  )}
                </CheckRadio>
                <CheckRadio
                  value={GProfilerConnectionRadioValue.NEW_CLOUD_CONNECTION}
                  className="font-caption"
                >
                  {t(
                    'gProfilerConnectionPage.connectionForm.newCloudConnectoin'
                  )}
                </CheckRadio>
              </Space>
            </Radio.Group>
          </div>
        )}
        {checkValue ===
        GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION ? (
          <div className="form-item flex flex-column">
            <FormLabel
              title={t(
                'gProfilerConnectionPage.connectionForm.cloudConnection'
              )}
              required={true}
            />
            <SelectDropDown
              placeholder={t(
                'gProfilerConnectionPage.connectionForm.selectCloudConnection'
              )}
              className="select-item"
              options={connectionList.map((item) => ({
                value: item.name,
                label: item.name,
                connectorId: item.connectorId,
              }))}
              onChange={(_value: any, data: any) => {
                dispatchCloudConnectionAndConnectorId(
                  data.value,
                  data.connectorId
                );
                validateCloudConnection(data.connectorId);
              }}
              onBlur={() => validateCloudConnection(gcpConnection?.connectorId)}
              designVersion2
            />
            <span
              style={{
                display: getValidationStyle(cloudConnectionValidation),
              }}
              className="font-validation-error"
            >
              {cloudConnectionValidation}
            </span>
          </div>
        ) : (
          <>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t(
                  'gProfilerConnectionPage.connectionForm.connectionName'
                )}
                required={true}
              />
              <Input
                className="select-item"
                onChange={(e: any) => {
                  dispatchConnectionName(e.target.value);
                  validateConnectionName(e.target.value);
                }}
                onBlur={() =>
                  validateConnectionName(gcpConnection?.connectionName)
                }
              />
              <span
                style={{
                  display: getValidationStyle(connectionNameValidation),
                }}
                className="font-validation-error"
              >
                {connectionNameValidation}
              </span>
            </div>
            <div className="form-item flex flex-column">
              <FormLabel
                title={t('gProfilerConnectionPage.connectionForm.keyJson')}
                required={true}
              />
              <Dragger
                customRequest={async (options: any) => {
                  const { onSuccess, file } = options;
                  const resData: any = await uploadConnectionFile(file);
                  setUploadFileData(resData);

                  dispatchJsonField(resData.data.responseData);

                  validateKeyJson(resData.data.responseData);
                  onSuccess(resData);
                  setFileName(file.name);
                }}
                accept={'.json'}
                maxCount={1}
                multiple={false}
                showUploadList={false}
                onRemove={onRemoveFile}
                openFileDialogOnClick={!gcpConnection?.file?.jsonFileId}
              >
                <div className="flex flex-column flex-center flex-gap-4">
                  {DraggerContent}
                </div>
              </Dragger>
              <span
                style={{
                  display: getValidationStyle(keyJsonValidation),
                }}
                className="font-validation-error"
              >
                {keyJsonValidation}
              </span>
            </div>
            <div className="validate-btn flex flex-end">
              {getNewConnectionValidation()}
            </div>
          </>
        )}
        {showProjectDropdown && (
          <div className="form-item flex flex-column">
            <FormLabel
              title={t('gProfilerConnectionPage.connectionForm.chooseProjct')}
              required={true}
            />
            <SelectDropDown
              placeholder={t('gProfilerConnectionPage.connectionForm.project')}
              className="select-item"
              options={(projects ?? []).map((item) => ({
                value: item,
              }))}
              value={gcpConnection.project}
              onChange={(_value: any, data: any) => {
                validateProject(data.value);
                dispatchProject?.(data.value);
              }}
              onBlur={() => validateProject(gcpConnection?.project)}
              loading={projectsRequestStatus === REQUEST_STATUS.PROCESSING}
              designVersion2
            />
            <span
              style={{
                display: getValidationStyle(projectValidation),
              }}
              className="font-validation-error"
            >
              {projectValidation}
            </span>
          </div>
        )}
        <div
          className={`form-item flex flex-column ${
            checkValue === GProfilerConnectionRadioValue.NEW_CLOUD_CONNECTION &&
            showValidatedButton &&
            'disable'
          }`}
        >
          <FormLabel
            title={t(
              'gProfilerConnectionPage.connectionForm.chooseAServiceName'
            )}
            required={true}
          />
          <SelectDropDown
            placeholder={t(
              'gProfilerConnectionPage.connectionForm.serviceName'
            )}
            className="select-item"
            dropdownRender={getServiceDropdownRender}
            value={gcpConnection.serviceName}
            onBlur={() => validateServiceName(gcpConnection.serviceName)}
            designVersion2
          />
          <span
            style={{
              display: getValidationStyle(serviceNameValidation),
            }}
            className="font-validation-error"
          >
            {serviceNameValidation}
          </span>
        </div>
        <div
          className={`form-item flex flex-column ${
            checkValue === GProfilerConnectionRadioValue.NEW_CLOUD_CONNECTION &&
            showValidatedButton
              ? 'disable'
              : ''
          }`}
        >
          <FormLabel
            title={t('gProfilerConnectionPage.connectionForm.regionAndCluster')}
            required={true}
          />
          <SelectDropDown
            placeholder={t(
              'gProfilerConnectionPage.connectionForm.regionAndCluster'
            )}
            className="select-item"
            value={`${region} ${cluster}`}
            dropdownRender={getRegionAndClusterDropdownRender}
            loading={inputLoaderStatus === REQUEST_STATUS.PROCESSING}
            onBlur={() => {
              validateRegion(gcpConnection.region);
              validateCluster(gcpConnection.cluster);
            }}
            disabled={
              checkValue ===
                GProfilerConnectionRadioValue.EXISTING_CLOUD_CONNECTION &&
              !gcpConnection.cloudConnection
            }
            designVersion2
          />
          <span
            style={{
              display: getValidationStyle(RegionClusterValidation),
            }}
            className="font-validation-error"
          >
            {RegionClusterValidation}
          </span>
        </div>
      </section>
    </ConnectionFormCard>
  );
};

export default GranulateAppsGCPConnection;
