import moment from 'moment';
import i18n from 'i18n';

import { MonthlyCostType, TypeAndNameByCost } from 'types/dataTypes';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  SNOWFLAKE_TABLES,
} from 'constants/requestBody';
import { insertIndex, numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
} from 'utils/date';
import { getSelectedDashboardConnectionName } from 'utils/dashboardUtils';

import {
  getCostByNamePerMonthExcelData,
  getCostTrendExcelData,
} from '../SnowflakeDashboardComponents/utils';
import { TopDbByCostExportColumns } from './constants';

/**
 * @function getTopDbByCostExcelData
 * @description Function to return the excel export data for top databases by cost graph
 * @param topDbByCost top databases by cost data
 * @param dateRange date range
 * @param selectedDbs selected databases in the filter
 * @returns Object containing the excel export data.
 */
export const getTopDbByCostExcelData = (
  topDbByCost: TypeAndNameByCost[],
  dateRange: string[],
  selectedDbs: string[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.topDbByStorageUsage'),
    columns: TopDbByCostExportColumns,
    data: insertIndex(topDbByCost).map((item) => ({
      ...item,
      cost: numberCommaSeparator(item.cost),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.integrationName'),
        value: getSelectedDashboardConnectionName(),
      },
      {
        heading: i18n.t('snowflakeDashboards.databases'),
        value: selectedDbs.join(', '),
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: moment(dateRange[0], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: moment(dateRange[1], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
    ],
  };
};

/**
 * @function getCostOverTimeByAccountColumns
 * @description Function to return the excel export columns for cost over time by account graph
 * @param startDate start date from filter
 * @param endDate end date from filter
 * @returns Array of excel export columns.
 */
export const getCostOverTimeByAccountColumns = (
  startDate: string,
  endDate: string
) => [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10, alignment: 'center' },
  {
    header: i18n.t('snowflakeDashboards.accounts'),
    key: 'name',
    dataKey: 'name',
    width: 30,
  },
  ...getMonthYearShortList(startDate, endDate, MONTH_YEAR_SHORT).map(
    (month) => ({
      header: month,
      key: `${month}-cost`,
      dataKey: `${month}-cost`,
      width: 15,
      alignment: 'right',
    })
  ),
];

/**
 * @function getCostOverTimeByAccountExcelData
 * @description Function to return the excel export data for top databases by cost graph
 * @param costOverTimeByAccount top databases by cost data
 * @param dateRange date range
 * @param selectedDbs selected databases in the filter
 * @returns Object containing the excel export data.
 */
export const getCostOverTimeByAccountExcelData = (
  costOverTimeByAccount: any[],
  dateRange: string[]
) => {
  return {
    sheetName: i18n.t('graphHeadings.costOverTimeByAccount'),
    columns: getCostOverTimeByAccountColumns(dateRange[0], dateRange[1]),
    data: insertIndex(costOverTimeByAccount).map((item) => ({
      ...item,
      cost: numberCommaSeparator(item.cost),
    })),
    filters: [
      {
        heading: i18n.t('excelExportLabels.integrationName'),
        value: getSelectedDashboardConnectionName(),
      },
      {
        heading: i18n.t('excelExportLabels.startDate'),
        value: moment(dateRange[0], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
      {
        heading: i18n.t('excelExportLabels.endDate'),
        value: moment(dateRange[1], YEAR_MONTH_WITHOUT_SEPARATOR).format(
          MONTH_YEAR_FORMAT
        ),
      },
    ],
  };
};

/**
 * @function getSnowflakeStorageExcelData
 * @description Function to return the excel export data for snowflake storage dashboard
 * @param monthlyCost Array of monthly cost data.
 * @param dateRange Array of date range.
 * @returns Object containing the excel export data.
 */
export const getSnowflakeStorageExcelData = (
  monthlyCost: MonthlyCostType[],
  dateRange: string[],
  costByNamePerMonth: {
    graphHeading: string;
    data: any[];
    dateRange: string[];
  },
  topDbByCost: {
    data: TypeAndNameByCost[];
    dateRange: string[];
    selectedDbs: string[];
  },
  costOverTimeByAccount: {
    data: any[];
    dateRange: string[];
  }
) => {
  return [
    getCostTrendExcelData(monthlyCost, dateRange),
    getCostByNamePerMonthExcelData(
      costByNamePerMonth.graphHeading,
      costByNamePerMonth.data,
      costByNamePerMonth.dateRange
    ),
    getTopDbByCostExcelData(
      topDbByCost.data,
      topDbByCost.dateRange,
      topDbByCost.selectedDbs
    ),
    getCostOverTimeByAccountExcelData(
      costByNamePerMonth.data,
      costOverTimeByAccount.dateRange
    ),
  ];
};

/**
 * @function getStorageUsageByTimePeriod
 * @description Function to construct the storage usage query for the given time period
 * @param dateRange Date range for which the query needs to be constructed
 * @returns Object containing the query
 */
export const getStorageUsageByTimePeriod = (dateRange: string[]) => {
  return {
    queryDto: {
      columns: [
        {
          label: 'value',
          field: QUERY_FIELDS.AVG_STAGE_BYTES,
        },
      ],
      aggregators: [
        {
          label: 'value',
          function: AGGREGATORS.SUM,
        },
      ],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: [
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange[0]).format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange[1]).format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
      cached: true,
    },
    table: SNOWFLAKE_TABLES.STAGE_STORAGE_USAGE_HISTORY,
  };
};

/**
 * @function getStorageCostTrendQuery
 * @description Function to construct the storage cost trend query
 * @param integrationId integration Id of the selected snowflake dashboard
 * @param dateRange date range selected
 * @returns Object containing the request query.
 */
export const getStorageCostTrendQuery = (
  integrationId: string,
  dateRange: string[]
) => {
  return {
    snowflakeIntegrationId: integrationId,
    queryDto: {
      columns: [
        {
          label: 'month',
          field: QUERY_FIELDS.CONCAT_YEAR_MONTH_USAGE_DATE,
        },
        {
          label: 'cost',
          field: QUERY_FIELDS.CREDITS,
        },
      ],
      aggregators: [
        {
          label: 'cost',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
        {
          label: 'cost',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: [
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange[0])
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange[1])
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
      cached: true,
    },
    table: SNOWFLAKE_TABLES.STORAGE_DAILY_HISTORY,
  };
};

/**
 * @function getUsageByDatabasePerMonthQuery
 * @description Function to construct the query for usage by Database per month chart
 * @param integrationId integration Id of the selected snowflake dashboard
 * @param dateRange date range selected
 * @returns Object containing the request query.
 */
export const getUsageByDatabasePerMonthQuery = (
  integrationId: string,
  dateRange: string[]
) => {
  return {
    snowflakeIntegrationId: integrationId,
    queryDto: {
      columns: [
        {
          label: 'month',
          field: QUERY_FIELDS.CONCAT_YEAR_MONTH_USAGE_DATE,
        },
        {
          label: 'usage',
          field: QUERY_FIELDS.AVERAGE_DATABASE_BYTES,
        },
        {
          label: 'databaseName',
          field: QUERY_FIELDS.DATABASE_NAME,
        },
      ],
      aggregators: [
        {
          label: 'usage',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['month', 'databaseName'],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
        {
          label: 'usage',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: [
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange[0])
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange[1])
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
      cached: true,
    },
    table: SNOWFLAKE_TABLES.DATABASE_STORAGE_USAGE_HISTORY,
  };
};

/**
 * @function getTopDatabaseByStorageUsageQuery
 * @description Function to construct the query for top databases by storage usage chart
 * @param integrationId integration Id of the selected snowflake dashboard
 * @param dateRange date range selected
 * @returns Object containing the request query.
 */
export const getTopDatabaseByStorageUsageQuery = (
  integrationId: string,
  dateRange: string[]
) => {
  return {
    snowflakeIntegrationId: integrationId,
    queryDto: {
      columns: [
        {
          label: 'databaseUsage',
          field: QUERY_FIELDS.AVERAGE_DATABASE_BYTES,
        },
        {
          label: 'failsafeUsage',
          field: QUERY_FIELDS.AVERAGE_FAILSAFE_BYTES,
        },
        {
          label: 'databaseName',
          field: QUERY_FIELDS.DATABASE_NAME,
        },
      ],
      aggregators: [
        {
          label: 'databaseUsage',
          function: AGGREGATORS.SUM,
        },
        {
          label: 'failsafeUsage',
          function: AGGREGATORS.SUM,
        },
      ],
      groupBy: ['databaseName'],
      orderBy: [
        {
          label: 'databaseUsage',
          sort: ORDER_BY.DESCENDING,
        },
      ],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: [
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange[0])
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange[1])
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
      cached: true,
    },
    table: SNOWFLAKE_TABLES.DATABASE_STORAGE_USAGE_HISTORY,
  };
};

/**
 * @function getCostOvertimeByAccountQuery
 * @description Function to construct the query for cost over time by account chart
 * @param integrationId integration Id of the selected snowflake dashboard
 * @param dateRange date range selected
 * @returns Object containing the request query.
 */
export const getCostOvertimeByAccountQuery = (
  integrationId: string,
  dateRange: string[]
) => {
  return {
    snowflakeIntegrationId: integrationId,
    queryDto: {
      columns: [
        {
          label: 'month',
          field: QUERY_FIELDS.CONCAT_YEAR_MONTH_USAGE_DATE,
        },
        {
          label: 'ACCOUNT_NAME',
          field: QUERY_FIELDS.ACCOUNT_NAME_CAPS,
        },
        {
          label: 'CREDITS',
          field: QUERY_FIELDS.CREDITS,
        },
      ],
      aggregators: [
        {
          label: 'CREDITS',
          function: AGGREGATORS.SUM,
        },
      ],
      orderBy: [
        {
          label: 'month',
          sort: ORDER_BY.ASCENDING,
        },
      ],
      groupBy: ['ACCOUNT_NAME', 'month'],
      filterGroups: [
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: [
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
              value: moment(dateRange[0])
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              conjunctToNextFilter: CONJUNCTIONS.AND,
            },
            {
              field: QUERY_FIELDS.USAGE_DATE,
              comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
              value: moment(dateRange[1])
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
            },
          ],
        },
      ],
      dashBoardType: DASHBOARD_TYPES.SNOWFLAKE,
      cached: true,
    },
    table: SNOWFLAKE_TABLES.STORAGE_DAILY_HISTORY,
  };
};
