import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Loader from 'components/Loader';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import { getOciBudget } from 'pages/BudgetsAndAlertsPage/services';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import ConnectionForm from '../../ConnectionForm';
import ScopeForm from '../ScopeForm';
import AmountForm from '../../AmountForm';
import ActionsForm from '../../ActionsForm';
import NotificationsForm from '../../NotificationsForm';

type SetupBudgetFormOCIProps = {
  currentStep: number;
  isBudgetConnectionError: boolean;
  setIsBudgetConnectionError: (value: boolean) => void;
};

const SetupBudgetFormOCI = ({
  currentStep,
  isBudgetConnectionError,
  setIsBudgetConnectionError,
}: SetupBudgetFormOCIProps) => {
  const dispatch = useDispatch();
  const { budgetData, isEdit } = useSelector(budgetsAndAlerts);

  const [budgetDetailsLoading, setBudgetDetailsLoading] = useState<string>('');

  useEffect(() => {
    isEdit && fetchBudgetData();
  }, []);

  /**
   * @function fetchBudgetData
   * @description Function to fetch budget data to populate fields for editing
   */
  const fetchBudgetData = () => {
    setBudgetDetailsLoading(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: budgetData.connectorId,
      budgetId: budgetData.budgetId,
    };
    getOciBudget(params)
      .then((res: any) => {
        const fetchedBudgetDetails = res.data.responseData;
        dispatch(
          setBudgetData({
            ...fetchedBudgetDetails,
            displayName: fetchedBudgetDetails.budgetDisplayName,
            connectorId: budgetData.connectorId,
            targetCompartment: fetchedBudgetDetails.targets[0],
            amount: fetchedBudgetDetails.budget,
            thresholdRules: fetchedBudgetDetails.thresholdRules.map(
              (rule: any) => ({
                ...rule,
                amount: (fetchedBudgetDetails.amount * rule.percentage) / 100,
              })
            ),
          })
        );
        setBudgetDetailsLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e: any) => {
        onApiCallError(e, false, setBudgetDetailsLoading);
      });
  };

  if (budgetDetailsLoading === REQUEST_STATUS.PROCESSING && isEdit) {
    return <Loader />;
  }

  switch (currentStep) {
    case 1:
      return (
        <ConnectionForm
          isBudgetConnectionError={isBudgetConnectionError}
          setIsBudgetConnectionError={setIsBudgetConnectionError}
        />
      );

    case 2:
      return <ScopeForm />;

    case 3:
      return <AmountForm />;

    case 4:
      return <ActionsForm />;

    case 5:
      return <NotificationsForm />;
  }

  return <></>;
};

export default SetupBudgetFormOCI;
