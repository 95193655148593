import { useEffect } from 'react';
import NavigationPath from 'components/NavigationPath';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import GranulateInsightHeader from './components/GranulateInsightHeader';
import InstancePage from './components/InstancePage';

const GranulateInsightDashboard = () => {
  useEffect(() => {
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  return (
    <div className="flex flex-column flex-fit">
      <GranulateInsightHeader />
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <InstancePage />
      </div>
    </div>
  );
};

export default GranulateInsightDashboard;
