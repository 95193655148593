import i18n from 'i18n';
import {
  ColouredCostByResourceType,
  ColouredCostByServiceType,
  MonthlyCostType,
} from 'types/dataTypes';
import {
  AGGREGATORS,
  COMPARATORS,
  CONJUNCTIONS,
  DASHBOARD_TYPES,
  ORDER_BY,
  QUERY_FIELDS,
  QUERY_VALUES,
} from 'constants/requestBody';
import { PROVIDER } from 'constants/cloudProviders';
import { ConnectionListType, TagsFilterType, TagsType } from 'types/dashboard';
import { formatDateFieldByProvider } from 'utils/date';
import {
  getAWSTagFiltersData,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import { DashboardListType } from 'types/navigationMenu';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';

/**
 * @function getCostTrendsExportColumns
 * @description Function to return the columns for excel and pdf export.
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @param data trends graph data for all the drilldown levels
 * @return List of columns for export
 */
export const getCostTrendsData = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined,
  data: {
    trends: MonthlyCostType[];
    service: ColouredCostByServiceType[];
    resource: ColouredCostByResourceType[];
  }
) => {
  if (!selectedTrendMonth) {
    return data.trends;
  }

  if (!selectedTrendService) {
    return data.service;
  }

  if (selectedTrendService) {
    return data.resource;
  }

  return [];
};

/**
 * @function getAwsCloudSpendQuery
 * @description Function to return the query for cloud spend
 * @param startDate start date for the query filter
 * @param endDate end date for the query filter
 * @param isStaticData is static data
 * @param filterGroupForTags filter group for tags
 * @param selectedDashboard Object containing the dashboard details
 * @param selectedConnection Object containing the connection details
 * @returns Query for cloud spend
 */
export const getAwsCloudSpendQuery = (
  startDate: string,
  endDate: string,
  isStaticData: boolean,
  filterGroupForTags: any[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType
) => {
  return {
    columns: [
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_USAGE_ACCOUNT_ID,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [{ label: 'cost', function: AGGREGATORS.SUM }],
    groupBy: ['name'],
    orderBy: [{ label: 'cost', sort: ORDER_BY.DESCENDING }],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startDate,
              PROVIDER.AWS,
              isStaticData
            ),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              endDate,
              PROVIDER.AWS,
              isStaticData
            ),
          },
        ],
      },
      ...filterGroupForTags,
    ],
  };
};

/**
 * @function getAwsTaggedUntaggedSpendQuery
 * @description Function to return the query for tagged and untagged spend
 * @param startMonth start month for the query filter
 * @param endMonth end month for the query filter
 * @param tagFilters tag filters
 * @param selectedDashboard selected dashboard
 * @param selectedConnection Object containing the connection details
 * @param selectedDashboardView selected dashboard view
 * @param allAvailableTags all available tags
 * @returns List of queries for tagged and untagged spend
 */
export const getAwsTaggedUntaggedSpendQuery = (
  startMonth: string,
  endMonth: string,
  tagFilters: TagsFilterType[],
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  allAvailableTags: TagsType[]
) => {
  const isStaticData = isDashboardWithStaticData(selectedDashboard);
  const selectedTagsFilterGroups = getAWSTagFiltersData(
    tagFilters,
    selectedDashboard.id,
    selectedDashboardView
  );
  const baseQuery = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    groupBy: ['name'],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        conjunctToNextGroup: CONJUNCTIONS.AND,
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startMonth,
              PROVIDER.AWS,
              isStaticData
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.BILLING_PERIOD_START_DATE_LOWERCASE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              endMonth,
              PROVIDER.AWS,
              isStaticData
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
        ],
      },
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };

  if (selectedTagsFilterGroups.length) {
    return [
      {
        ...baseQuery,
        filterGroups: [baseQuery.filterGroups[0], ...selectedTagsFilterGroups],
      },
    ];
  }
  return [
    {
      ...baseQuery,
      filterGroups: [
        baseQuery.filterGroups[0],
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: allAvailableTags.map((tag) => ({
            field: tag.key,
            comparator: COMPARATORS.NOT_EQUAL_TO,
            value: '',
            conjunctToNextFilter: CONJUNCTIONS.OR,
          })),
        },
      ],
    },
    {
      ...baseQuery,
      filterGroups: [
        baseQuery.filterGroups[0],
        {
          conjunctToNextGroup: CONJUNCTIONS.AND,
          filters: allAvailableTags.map((tag) => ({
            field: tag.key,
            comparator: COMPARATORS.EQUALS,
            value: '',
            conjunctToNextFilter: CONJUNCTIONS.AND,
          })),
        },
      ],
    },
  ];
};

/**
 * @function getAWSServiceSpendProfileQuery
 * @description Function to return the query for service spend profile
 * @param startMonth start month for the query filter
 * @param endMonth end month for the query filter
 * @param selectedDashboard selected dashboard
 * @param selectedConnection Onject containing the connection details
 * @param selectedDashboardView selected dashboard view
 * @param tagFilters tag filters
 * @returns Query for service spend profile
 */
export const getAWSServiceSpendProfileQuery = (
  startMonth: string,
  endMonth: string,
  selectedDashboard: DashboardListType,
  selectedConnection: ConnectionListType,
  selectedDashboardView: string,
  tagFilters: TagsFilterType[]
) => {
  const selectedTagsFilterGroups = getAWSTagFiltersData(
    tagFilters,
    selectedDashboard.id,
    selectedDashboardView
  );
  const isStaticData = isDashboardWithStaticData(selectedDashboard);

  return {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.LINE_ITEM_UNBLENDED_COST,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'service',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          QUERY_FIELDS.PRODUCT_SERVICENAME,
          selectedConnection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          selectedDashboard.dashBoardType,
          isStaticData
            ? QUERY_FIELDS.FORMAT_TIMESTAMP_BILLING_PERIOD
            : QUERY_FIELDS.DATE_FORMAT_BILLING_PERIOD,
          selectedConnection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['service', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.PRODUCT_SERVICENAME,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL_TO,
            value: '',
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_START_DATE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startMonth,
              PROVIDER.AWS,
              isStaticData
            ),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.LINE_ITEM_USAGE_END_DATE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              endMonth,
              PROVIDER.AWS,
              isStaticData
            ),
          },
          {
            field: getQueryFieldByDataSource(
              selectedDashboard.dashBoardType,
              QUERY_FIELDS.BILL_BILL_TYPE,
              selectedConnection.focusConversionEnabled
            ),
            comparator: COMPARATORS.NOT_EQUAL,
            value: QUERY_VALUES.REFUND,
          },
        ],
      },
      ...selectedTagsFilterGroups,
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };
};

export const getAwsSavingsPlanTrendColumns = (monthsList: string[]) => [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (_text: any, _record: any, index: number) => index + 1,
    width: 30,
  },
  {
    title: i18n.t('accountIds'),
    dataIndex: 'type',
    key: 'type',
  },
  ...monthsList.map((month) => ({
    title: month,
    dataIndex: month,
    key: month,
    render: (text: any) => numberCommaSeparator(text || 0) + '%',
  })),
];
