import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';

import { validateStringLengthLessThan } from 'utils/validations';
import {
  FREE_TEXT_CHARACTER_LIMIT,
  MAX_CHARACTER_LIMIT,
} from 'constants/validation';
import {
  cloudConnection,
  setAccessDetails,
  setCurrentConnection,
  setCurrentStepValidation,
  setResetStepForm,
} from 'redux/cloudConnectionSlice';
import {
  deleteConnectionFile,
  fetchConnectionById,
  fetchFileMetaData,
} from 'utils/services';
import {
  ACCESS_DATA_VALUES,
  VALIDATION_STATUS,
} from 'pages/ConnectingCSPPage/constants';
import { REQUEST_STATUS } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';

import BasicDetails from '../BasicDetails';
import GCPAccessDetails from './components/GCPAccessDetails';
import GCPBillingDetails from './components/GCPBillingDetails';
import GCPRecommendationDetails from './components/GCPRecommendationDetails';
import GCPCarbonFootprintDetails from './components/GCPCarbonFootprintDetails';
import GCPContainerCostDetails from './components/GCPContainerCostDetails';
import { fetchGcpDatasets } from './utils';

const GCPConnectionForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    currentConnection,
    isEditConnection,
    isTryAgain,
    accessDetails,
    currentStep,
    resetStepForm,
  } = useSelector(cloudConnection);

  const [isDuplicateConnectionName, setIsDuplicateConnectionName] =
    useState(true);
  const [connectionName, setConnectionName] = useState('');
  const [fileName, setFileName] = useState('');
  const [
    serviceAccountJsonRequiredValidation,
    setServiceAccountJsonRequiredValidation,
  ] = useState('');
  const [gcpDatasetOptions, setGcpDatasetOptions] = useState<string[]>([]);
  const [gcpDatasetOptionsRequestStatus, setGcpDatasetOptionsRequestStatus] =
    useState('');
  const [gcpDatasetTableOptions, setGcpDatasetTableOptions] = useState<
    string[]
  >([]);
  const [
    gcpRecommendationDatasetTableOptions,
    setGcpRecommendationDatasetTableOptions,
  ] = useState<string[]>([]);
  const [
    gcpCarbonFootprintDatasetTableOptions,
    setGcpCarbonFootprintDatasetTableOptions,
  ] = useState<string[]>([]);
  const [
    fetchConnectionDataRequestStatus,
    setFetchConnectionDataRequestStatus,
  ] = useState('');

  useEffect(() => {
    if (isEditConnection) {
      setIsDuplicateConnectionName(false);
      setProviderInitialValues();
      return;
    }

    if (isTryAgain) {
      setIsDuplicateConnectionName(false);
      fetchGcpDatasets(
        currentConnection?.jsonFileId,
        currentConnection?.projectId,
        setGcpDatasetOptions,
        setGcpDatasetOptionsRequestStatus,
        isEditConnection,
        currentConnection?.connectorId
      );
      fetchFilename();
      return;
    }

    dispatch(
      setCurrentConnection({
        ...currentConnection,
        migrated: false,
        wantBilling: true,
        wantRecommendations: true,
        wantCarbonFootprint: true,
      })
    );
  }, [isEditConnection, isTryAgain]);

  useEffect(() => {
    if (resetStepForm) {
      switch (currentStep) {
        case 1:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              dataSourceType: '',
              name: '',
              description: '',
              migrationProvider: '',
              migrated: false,
            })
          );
          break;
        case 2:
          if (currentConnection?.jsonFileId && !isEditConnection) {
            deleteConnectionFile(currentConnection?.jsonFileId);
            setFileName('');
            dispatch(
              setCurrentConnection({
                ...currentConnection,
                jsonFileId: '',
                projectId: '',
              })
            );
            dispatch(setAccessDetails([]));
          }
          break;
        case 3:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantBilling: false,
              billingDataset: '',
              billingTableName: '',
            })
          );
          break;
        case 4:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantRecommendations: false,
              recommendationDataset: '',
              recommendationsTableName: '',
            })
          );
          break;
        case 5:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantCarbonFootprint: false,
              carbonFootprintDataset: '',
              carbonFootprintTableName: '',
            })
          );
          break;
        case 6:
          dispatch(
            setCurrentConnection({
              ...currentConnection,
              wantContainerCost: false,
              projectDatasetMap: undefined,
            })
          );
          break;
      }
      dispatch(setResetStepForm(false));
    }
  }, [resetStepForm]);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        dispatch(setCurrentStepValidation(validateBasicDetailsFields()));
        break;
      case 2:
        dispatch(setCurrentStepValidation(validateAccessDetailsFields()));
        break;
      case 3:
        dispatch(setCurrentStepValidation(validateBillingDetailsFields()));
        break;
      case 4:
        dispatch(
          setCurrentStepValidation(validateRecommendationDetailsFields())
        );
        break;
      case 5:
        dispatch(
          setCurrentStepValidation(validateCarbonFootprintDetailsFields())
        );
        break;
      case 6:
        dispatch(
          setCurrentStepValidation(validateContainerCostDetailsFields())
        );
        break;
      default:
        dispatch(setCurrentStepValidation(VALIDATION_STATUS.INVALID));
        break;
    }
  }, [
    currentStep,
    currentConnection,
    isDuplicateConnectionName,
    accessDetails,
  ]);

  /**
   * @function setProviderInitialValues
   * @description Function to set initial values from the edit page
   */
  const setProviderInitialValues = () => {
    setFetchConnectionDataRequestStatus(REQUEST_STATUS.PROCESSING);
    fetchConnectionById(currentConnection?.connectorId)
      .then((res: any) => {
        const connectionDetails = res?.data?.responseData;
        dispatch(setCurrentConnection({ ...connectionDetails }));
        setConnectionName(connectionDetails?.displayName);
        setFetchConnectionDataRequestStatus(REQUEST_STATUS.SUCCESS);

        fetchGcpDatasets(
          connectionDetails?.jsonFileId,
          connectionDetails?.projectId,
          setGcpDatasetOptions,
          setGcpDatasetOptionsRequestStatus,
          isEditConnection,
          connectionDetails?.connectorId
        );
      })
      .catch((error: any) => {
        onApiCallError(error, false, setFetchConnectionDataRequestStatus);
      });
  };

  /**
   * @function fetchFilename
   * @description Function to fetch the filename from the server
   */
  const fetchFilename = () => {
    fetchFileMetaData(currentConnection?.jsonFileId).then((res: any) => {
      setFileName(res?.data?.responseData?.fileName ?? '');
    });
  };

  /**
   * @function validateBasicDetailsFields
   * @description Function to validate basic details fields
   * @return boolean true if validation is success else false
   */
  const validateBasicDetailsFields = () => {
    if (
      currentConnection?.displayName &&
      !validateStringLengthLessThan(
        currentConnection?.displayName,
        MAX_CHARACTER_LIMIT
      ) &&
      ((isEditConnection &&
        currentConnection?.displayName === connectionName) ||
        !isDuplicateConnectionName) &&
      currentConnection?.dataSourceType &&
      !validateStringLengthLessThan(
        currentConnection?.description,
        FREE_TEXT_CHARACTER_LIMIT
      ) &&
      (!currentConnection?.migrated || currentConnection?.migrationProvider)
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateAccessDetailsFields
   * @description Function to validate access details fields
   * @return boolean true if validation is success else false
   */
  const validateAccessDetailsFields = () => {
    if (
      isEditConnection ||
      (currentConnection?.jsonFileId &&
        currentConnection?.projectId &&
        accessDetails.find(
          (value) => value.role === ACCESS_DATA_VALUES.HAS_BILLING_ACCESS
        )?.haveAccess)
    ) {
      return VALIDATION_STATUS.VALID;
    }

    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateBillingDetailsFields
   * @description Function to validate the billing details fields
   * @return boolean true if validation is success else false
   */
  const validateBillingDetailsFields = () => {
    if (
      !currentConnection?.billingDataset ||
      !currentConnection?.billingTableName
    )
      return VALIDATION_STATUS.SKIP;
    else if (
      currentConnection?.billingDataset &&
      currentConnection?.billingTableName
    )
      return VALIDATION_STATUS.VALID;

    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateRecommendationDetailsFields
   * @description Function to validate the recommendation details fields
   * @return boolean true if validation is success else false
   */
  const validateRecommendationDetailsFields = () => {
    if (
      !currentConnection?.recommendationDataset ||
      !currentConnection?.recommendationsTableName
    )
      return VALIDATION_STATUS.SKIP;
    else if (
      currentConnection?.recommendationDataset &&
      currentConnection?.recommendationsTableName
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateCarbonFootprintDetailsFields
   * @description Function to validate the carbon footprint details fields
   * @return boolean true if validation is success else false
   */
  const validateCarbonFootprintDetailsFields = () => {
    if (
      !currentConnection?.carbonFootprintDataset ||
      !currentConnection?.carbonFootprintTableName
    )
      return VALIDATION_STATUS.SKIP;
    else if (
      currentConnection?.carbonFootprintDataset &&
      currentConnection?.carbonFootprintTableName
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function validateContainerCostDetailsFields
   * @description Function to validate the container cost details fields
   * @return boolean true if validation is success else false
   */
  const validateContainerCostDetailsFields = () => {
    if (
      !currentConnection?.projectDatasetMap?.length ||
      currentConnection?.projectDatasetMap?.some(
        (data: any) => !data.dataset && !data.project
      )
    )
      return VALIDATION_STATUS.SKIP;
    if (
      currentConnection?.projectDatasetMap &&
      currentConnection?.projectDatasetMap?.every(
        (data: any) => data.dataset !== '' && data.project !== ''
      )
    ) {
      return VALIDATION_STATUS.VALID;
    }
    return VALIDATION_STATUS.INVALID;
  };

  /**
   * @function removeFile
   * @description Function to remove uploaded key json file from the form
   */
  const removeFile = async () => {
    if (currentConnection?.jsonFileId) {
      await deleteConnectionFile(currentConnection?.jsonFileId);
      dispatch(
        setCurrentConnection({
          name: currentConnection?.name,
          displayName: currentConnection?.displayName,
          description: currentConnection?.description,
          dataSourceType: currentConnection?.dataSourceType,
          migrated: currentConnection?.migrated || false,
          migrationProvider: currentConnection?.migrationProvider,
          wantRecommendations: true,
          wantCarbonFootprint: true,
        })
      );
      setFileName('');
      setServiceAccountJsonRequiredValidation(
        `${t('connectionCSPForm.gcpUploadLabel')} ${t(
          'connectionCSPForm.isRequired'
        )}`
      );
      setGcpDatasetOptions([]);
      setGcpDatasetTableOptions([]);
    }
  };

  const getFormByStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicDetails
            setIsDuplicateConnectionName={setIsDuplicateConnectionName}
            connectionName={connectionName}
          />
        );
      case 2:
        return (
          <GCPAccessDetails
            removeFile={removeFile}
            fileName={fileName}
            setFileName={setFileName}
            serviceAccountJsonRequiredValidation={
              serviceAccountJsonRequiredValidation
            }
            setServiceAccountJsonRequiredValidation={
              setServiceAccountJsonRequiredValidation
            }
            setGcpDatasetOptions={setGcpDatasetOptions}
            setGcpDatasetOptionsRequestStatus={
              setGcpDatasetOptionsRequestStatus
            }
          />
        );
      case 3:
        return (
          <GCPBillingDetails
            gcpDatasetOptionsRequestStatus={gcpDatasetOptionsRequestStatus}
            gcpDatasetOptions={gcpDatasetOptions}
            gcpDatasetTableOptions={gcpDatasetTableOptions}
            setGcpDatasetTableOptions={setGcpDatasetTableOptions}
          />
        );
      case 4:
        return (
          <GCPRecommendationDetails
            gcpDatasetOptionsRequestStatus={gcpDatasetOptionsRequestStatus}
            gcpDatasetOptions={gcpDatasetOptions}
            gcpRecommendationDatasetTableOptions={
              gcpRecommendationDatasetTableOptions
            }
            setGcpRecommendationDatasetTableOptions={
              setGcpRecommendationDatasetTableOptions
            }
          />
        );
      case 5:
        return (
          <GCPCarbonFootprintDetails
            gcpDatasetOptionsRequestStatus={gcpDatasetOptionsRequestStatus}
            gcpDatasetOptions={gcpDatasetOptions}
            gcpCarbonFootprintDatasetTableOptions={
              gcpCarbonFootprintDatasetTableOptions
            }
            setGcpCarbonFootprintDatasetTableOptions={
              setGcpCarbonFootprintDatasetTableOptions
            }
          />
        );
      case 6:
        return <GCPContainerCostDetails />;
    }
  };

  if (fetchConnectionDataRequestStatus === REQUEST_STATUS.PROCESSING) {
    return <Loader />;
  }

  return (
    <div
      className="cloud-connection-form flex-fit"
      data-testid="gcp-connection-form"
    >
      {getFormByStep()}
    </div>
  );
};

export default GCPConnectionForm;
