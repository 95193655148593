import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';
import AccessibleDiv from 'components/AccessibleDiv';
import { ICONS } from 'constants/icons';
import { CONJUNCTIONS } from 'constants/requestBody';
import { CostSavingFilterType } from 'pages/CostOptimizationInsightsPage/types';

import FilterFields from '../FilterFields';

import './index.scss';

type CostSavingsFilterProps = {
  onChangeFilters: (val: CostSavingFilterType[]) => void;
};

const CostSavingsFilter = ({ onChangeFilters }: CostSavingsFilterProps) => {
  const { t } = useTranslation();

  const [showConditionsDropdown, setShowConditionsDropdown] = useState(false);
  const [filters, setFilters] = useState<CostSavingFilterType[]>([
    { comparator: '', value: '', conjunctToNextFilter: CONJUNCTIONS.AND },
  ]);

  useEffect(() => {
    onChangeFilters(filters);
  }, [filters]);

  /**
   * @function addOrUpdateFilter
   * @description Function to update or add filter at given position with given filter
   * @param filter FilterType filter to be added or updated
   * @param position position of the filter to be updated
   * @returns updated filter group
   */
  const addOrUpdateFilter = (
    filter: CostSavingFilterType,
    position: number
  ) => {
    let existingFilters = [...filters];
    existingFilters.splice(position, 1, filter);
    setFilters(existingFilters);
  };

  /**
   * @function addNewFilterWithConjunction
   * @description Function to add conjunction to the next filter at the end of the filter group
   * @param filter CostSavingFilterType filter to be added or updated
   * @returns updated filter group
   */
  const addNewFilterWithConjunction = (conjunction: string) => {
    let existingFilters = [...filters];
    if (existingFilters.length > 0)
      existingFilters[existingFilters.length - 1].conjunctToNextFilter =
        conjunction;
    existingFilters.push({
      comparator: '',
      value: '',
      conjunctToNextFilter: CONJUNCTIONS.AND,
    });
    setShowConditionsDropdown(false);
    setFilters(existingFilters);
  };

  /**
   * @function removeFilter
   * @description Function to remove filter at given position
   * @param position position of the filter to be removed
   */
  const removeFilter = (position: number) => {
    let existingFilters = [...filters];
    existingFilters.splice(position, 1);
    setFilters(existingFilters);
  };

  return (
    <div
      className="flex flex-column flex-gap-16 new-styled-scroll full-width"
      data-testid="cost-savings-filter"
    >
      <div className="flex flex-align-items-center flex-space-between">
        <div className="font-caption-bold">{t('newView.condition')}</div>
        <Tooltip
          rootClassName="conjunctions-overlay"
          overlay={
            <div className="flex flex-column flex-gap-8">
              <AccessibleDiv
                className="conjunction-option font-caption cursor-pointer"
                onClick={() => addNewFilterWithConjunction(CONJUNCTIONS.AND)}
                data-testid={CONJUNCTIONS.AND}
              >
                {CONJUNCTIONS.AND}
              </AccessibleDiv>
              <AccessibleDiv
                className="conjunction-option font-caption cursor-pointer"
                onClick={() => addNewFilterWithConjunction(CONJUNCTIONS.OR)}
                data-testid={CONJUNCTIONS.OR}
              >
                {CONJUNCTIONS.OR}
              </AccessibleDiv>
            </div>
          }
          placement="bottomRight"
          open={showConditionsDropdown}
          onOpenChange={(visible: any) => setShowConditionsDropdown(visible)}
          trigger="click"
          arrow={false}
        >
          <Icon
            className="add-icon"
            iconName={ICONS.ADD_LINE}
            dataTestId="add-filter-field-cta"
          />
        </Tooltip>
      </div>
      <div className="flex flex-column flex-gap-16">
        {filters.map((filter, index) => (
          <FilterFields
            key={`${filter.comparator}${filter.value}`}
            filters={filters}
            filter={filter}
            position={index}
            addOrUpdateFilter={addOrUpdateFilter}
            removeFilter={removeFilter}
          />
        ))}
      </div>
    </div>
  );
};

export default CostSavingsFilter;
