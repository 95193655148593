import { Upload } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormLabel } from 'components/FormLabel';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { REQUEST_STATUS } from 'constants/requestBody';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  cloudConnection,
  setAccessDetails,
  setCurrentConnection,
} from 'redux/cloudConnectionSlice';
import { ACCESS_DATA_VALUES } from 'pages/ConnectingCSPPage/constants';
import { getAccessDetails } from 'pages/ConnectingCSPPage/services';
import { onApiCallError } from 'utils/handleErrors';
import { PROVIDER } from 'constants/cloudProviders';
import { getGcpProjectIdFromJson, uploadConnectionFile } from 'utils/services';
import AccessSummaryTable from 'pages/ConnectingCSPPage/components/AccessSummaryTable';

import { fetchGcpDatasets } from '../../utils';

const { Dragger } = Upload;

type GcpAccessDetailsProps = {
  removeFile: () => void;
  fileName: string;
  setFileName: (val: string) => void;
  serviceAccountJsonRequiredValidation: string;
  setServiceAccountJsonRequiredValidation: (val: string) => void;
  setGcpDatasetOptions: (val: string[]) => void;
  setGcpDatasetOptionsRequestStatus: (val: string) => void;
};

const GcpAccessDetails = ({
  removeFile,
  fileName,
  setFileName,
  serviceAccountJsonRequiredValidation,
  setServiceAccountJsonRequiredValidation,
  setGcpDatasetOptions,
  setGcpDatasetOptionsRequestStatus,
}: GcpAccessDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentConnection, isEditConnection, accessDetails } =
    useSelector(cloudConnection);

  const [accessDetailsRequestStatus, setAccessDetailsRequestStatus] =
    useState('');

  /**
   * @function fetchConnectionAccessDetails
   * @description Function to fetch the access details
   */
  const fetchConnectionAccessDetails = (
    jsonFileId: string,
    projectId: string
  ) => {
    setAccessDetailsRequestStatus(REQUEST_STATUS.PROCESSING);
    const body = {
      gcpJsonFileId: jsonFileId,
      gcpProjectId: projectId,
    };
    const param = { provider: PROVIDER.GCP };
    getAccessDetails(body, param)
      .then((res: any) => {
        setAccessDetailsRequestStatus(REQUEST_STATUS.SUCCESS);
        dispatch(
          setAccessDetails(
            Object.entries(res?.data?.responseData).map((objectArr) => {
              return {
                role: objectArr[0],
                haveAccess: objectArr[1] as boolean,
              };
            })
          )
        );
      })
      .catch((e) => onApiCallError(e, false, setAccessDetailsRequestStatus));
  };

  /**
   * @function fetchProjectId
   * @description Function fetch the gcp projectId after uploading file
   * @param jsonFileId file id of the file uploaded
   */
  const fetchProjectId = (jsonFileId: string) => {
    getGcpProjectIdFromJson(jsonFileId).then((res: any) => {
      dispatch(
        setCurrentConnection({
          ...currentConnection,
          jsonFileId: jsonFileId,
          projectId: res.data.responseData,
        })
      );
      fetchGcpDatasets(
        jsonFileId,
        res.data.responseData,
        setGcpDatasetOptions,
        setGcpDatasetOptionsRequestStatus,
        isEditConnection,
        currentConnection?.connectorId
      );
      fetchConnectionAccessDetails(jsonFileId, res.data.responseData);
    });
  };

  return (
    <div
      className={`flex flex-column flex-gap-24 ${isEditConnection && 'edit'}`}
    >
      <div className="flex flex-wrap">
        {!isEditConnection && (
          <div className="form-item flex flex-column full-width">
            <FormLabel
              title={t(`connectionCSPForm.gcpUploadLabel`)}
              required={true}
            />
            <Dragger
              customRequest={async (options: any) => {
                const { onSuccess, file } = options;
                const resData: any = await uploadConnectionFile(file);
                fetchProjectId(resData.data.responseData);
                onSuccess(resData);
                setFileName(file.name);
                setServiceAccountJsonRequiredValidation('');
              }}
              accept={'.json'}
              maxCount={1}
              multiple={false}
              showUploadList={false}
              onRemove={removeFile}
              openFileDialogOnClick={!currentConnection?.jsonFileId}
            >
              <div className="flex flex-column flex-center flex-gap-4">
                {fileName ? (
                  <div className="flex flex-gap-4">
                    <span className="font-overline">{fileName}</span>
                    <Icon
                      iconName={ICONS.CLOSE_FILL}
                      size={ICONS_SIZE.SM}
                      className="small-close-icon flex flex-center"
                      onClick={removeFile}
                      dataTestId="remove-file-cta"
                    />
                  </div>
                ) : (
                  <div className="flex flex-column flex-gap-16">
                    <Icon
                      iconName={ICONS.UPLOAD_LINE}
                      className="small-close-icon flex flex-center"
                      onClick={removeFile}
                    />
                    <span className="font-subHeader-small">
                      {t('connectionCSPForm.clickOrDragFile')}
                    </span>
                  </div>
                )}
              </div>
            </Dragger>
            <span
              style={{
                display: serviceAccountJsonRequiredValidation
                  ? 'inline'
                  : 'none',
              }}
              className="font-validation-error"
            >
              {serviceAccountJsonRequiredValidation}
            </span>
          </div>
        )}
      </div>
      <div className="form-item flex flex-column">
        <FormLabel
          title={t(`connectionCSPForm.gcpProjectId`)}
          required={true}
        />
        <Input
          default
          type="input"
          placeholder={t(`connectionCSPForm.gcpProjectIdPlaceholder`)}
          disabled={true}
          value={currentConnection?.projectId}
        />
      </div>
      {currentConnection.jsonFileId &&
        currentConnection.projectId &&
        !isEditConnection && (
          <>
            <div className="form-item flex flex-column">
              <FormLabel title={t(`connectionCSPForm.accessSummary`)} />
              <AccessSummaryTable
                accessDetailsRequestStatus={accessDetailsRequestStatus}
              />
            </div>
            {!accessDetails.find(
              (value) => value.role === ACCESS_DATA_VALUES.HAS_BILLING_ACCESS
            )?.haveAccess &&
              accessDetailsRequestStatus !== REQUEST_STATUS.PROCESSING && (
                <div className="error-message flex flex-justify-content-center font-caption">
                  {t('connectionCSPForm.invalidCredentials')}
                </div>
              )}
          </>
        )}
    </div>
  );
};

export default GcpAccessDetails;
