import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';
import { SaveStaticTransactionRequestType } from './types';

/**
 * @function validateSchema
 * @description Function to validate the schema
 * @param body Object containing the request body
 * @return axios response from POST request
 */
export const validateSchema = async (body: { schemaString: string }) => {
  return ServiceCalls.post(APIS.VALIDATE_SCHEMA, body);
};

/**
 * @function saveStaticTransaction
 * @description Function to save the static file transaction data
 * @param body Object containing the request body
 * @param params Object containin the request parameters
 * @return axios response from POST request
 */
export const saveStaticTransaction = async (
  body: SaveStaticTransactionRequestType,
  params: { update: boolean }
) => {
  return ServiceCalls.post(APIS.SAVE_STATIC_TRANSACTION, body, null, params);
};

/**
 * @function uploadImportFile
 * @description Function to upload Import file
 * @param url signed url for uploading the file
 * @param file file to be uploaded
 * @return axios response from PUT request
 */
export const uploadImportDataFile = async (url: string, file: File) => {
  return ServiceCalls.put(url, file, {}, true, undefined, false);
};

/**
 * @function validateDuplicateTransactionName
 * @description Function to validate the transaction name for duplicate name
 * @param params Object containing the query params
 * @return axios response from GET request
 */
export const validateDuplicateTransactionName = async (params: {
  transactionName: string;
}) => {
  return ServiceCalls.get(APIS.VALIDATE_TRANSACTION_NAME, null, params);
};

/**
 * @function getTransactionByName
 * @description Function to fetch the transaction details by name
 * @param params request parameters
 * @return axios response from GET request
 */
export const getTransactionByName = async (params: {
  transactionName: string;
}) => {
  return ServiceCalls.get(APIS.GET_TRANSACTION_BY_NAME, null, params);
};

/**
 * @function getSampleSchema
 * @description Function to fetch the sample schema
 * @param params request parameters
 * @return axios response from GET request
 */
export const getSampleSchema = async (params: any) => {
  return ServiceCalls.get(APIS.GET_SAMPLE_SCHEMA, null, params);
};
