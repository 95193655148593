import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function getAllGenAIProviders
 * @description Function to fetch all the GenAI providers and its sub models
 * @returns axios response from GET request
 */
export const getAllGenAIProviders = async () => {
  return ServiceCalls.get(APIS.GET_ALL_GEN_AI_PROVIDERS);
};
