import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';

import Input from 'components/Input';
import { INPUT_SIZE } from 'constants/appearance';
import { ConnectionListType } from 'types/dashboard';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { CMDBFieldTagsMappingType } from 'pages/CmdbSetupListPage/types';
import { DASHBOARD_TYPES, REQUEST_STATUS } from 'constants/requestBody';
import { getLabels } from 'utils/services';
import { PROVIDER } from 'constants/cloudProviders';
import { onApiCallError } from 'utils/handleErrors';

import TagsList from './components/TagsList';
import { filterConnections, getSelectedTagCountByConnectorId } from './utils';

import './index.scss';

const { Panel } = Collapse;

type ConnectionTagMappingsProps = {
  connections: ConnectionListType[];
  cmdbFieldTagsMap: CMDBFieldTagsMappingType;
  setCmdbFieldTagsMap: (val: CMDBFieldTagsMappingType) => void;
};

const ConnectionTagMappings = ({
  connections,
  cmdbFieldTagsMap,
  setCmdbFieldTagsMap,
}: ConnectionTagMappingsProps) => {
  const { t } = useTranslation();

  const [searchKey, setSearchKey] = useState('');
  const [connectionTagsMap, setConnectionTagsMap] = useState<{
    [key: string]: string[];
  }>({});
  const [tagsReqStatus, setTagsReqStatus] = useState(REQUEST_STATUS.SUCCESS);

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) =>
    props.isActive ? (
      <ArrowDownSLineIcon size={20} className="collapse-arrow" />
    ) : (
      <ArrowRightSLineIcon size={20} className="collapse-arrow" />
    );

  /**
   * @function fetchConnectionTags
   * @description Function to fetch the tags for the connection
   * @param connectorId connectorId of the connection
   */
  const fetchConnectionTags = (connectorId: string | undefined) => {
    // If the tags are already fetched for the connection, then return
    if (!connectorId || connectionTagsMap[connectorId] !== undefined) return;

    setTagsReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      connectorId: connectorId,
      dashBoardType: DASHBOARD_TYPES.BILLING,
    };

    getLabels(params)
      .then((res: any) => {
        let data: any[] = [];
        if (res.status === 200) {
          const provider = connections.find(
            (connection) => connection.connectorId === connectorId
          )?.provider;
          if (provider === PROVIDER.GCP)
            data = res.data.map((eachTag: any) => eachTag.label_keys);
          if (provider === PROVIDER.AWS)
            data = res.data.map((eachTag: any) => eachTag.field);
          if (provider === PROVIDER.AZURE)
            data = res.data.map((eachTag: any) => eachTag.label_keys);
        }
        setConnectionTagsMap({ ...connectionTagsMap, [connectorId]: data });
        setTagsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, true, setTagsReqStatus);
      });
  };

  return (
    <div
      className="connection-tag-mappings flex flex-column flex-gap-16"
      data-testid="connection-tag-mappings"
    >
      <Input
        type="search"
        rootClassName="full-width"
        placeholder={t('searchConnectionsByName')}
        value={searchKey}
        size={INPUT_SIZE.SMALL}
        onChange={(e: any) => setSearchKey(e.target.value)}
      />
      <Collapse
        className="connection-tag-collapse new-styled-scroll full-width flex flex-column"
        accordion={true}
        bordered={false}
        expandIcon={getExpandIcon}
        onChange={(connectorsIds: string | string[]) => {
          fetchConnectionTags(connectorsIds.at(0));
        }}
      >
        {filterConnections(connections, searchKey).map((connection) => (
          <Panel
            className="connection-tag-panel"
            header={
              <div className="flex flex-align-items-center flex-gap-8">
                <div>
                  <img
                    src={getProviderSmallLogo(connection.provider)}
                    alt={`${connection.provider} Logo`}
                  />
                </div>
                <span className="table-typography">
                  {connection.displayName}
                </span>
              </div>
            }
            key={connection.connectorId}
            extra={
              getSelectedTagCountByConnectorId(
                connection.connectorId,
                cmdbFieldTagsMap
              ) ? (
                <div
                  className="font-small-bold flex flex-center values-count"
                  data-testid={`tag-count-${getSelectedTagCountByConnectorId(
                    connection.connectorId,
                    cmdbFieldTagsMap
                  )}`}
                >
                  {getSelectedTagCountByConnectorId(
                    connection.connectorId,
                    cmdbFieldTagsMap
                  )}
                </div>
              ) : null
            }
          >
            <TagsList
              connection={connection}
              tags={connectionTagsMap[connection.connectorId]}
              tagsReqStatus={tagsReqStatus}
              cmdbFieldTagsMap={cmdbFieldTagsMap}
              setCmdbFieldTagsMap={setCmdbFieldTagsMap}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ConnectionTagMappings;
