import { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import SelectDropdown from 'components/Select';
import { ProfileIcon } from 'assets/icons';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { onApiCallError } from 'utils/handleErrors';
import { getOtherUsersProfilePhoto } from 'utils/services';

import { ReportSharedUserType } from '../../types';
import { ShareReportPermissionOptions } from '../../constants';

import './index.scss';

type UserRowProps = {
  user: ReportSharedUserType;
  onClickRemoveUser: (val: ReportSharedUserType) => void;
  onSelectPermission: (val: string) => void;
};

const UserRow = ({
  user,
  onClickRemoveUser,
  onSelectPermission,
}: UserRowProps) => {
  const [profilePic, setProfilePic] = useState(ProfileIcon);

  useEffect(() => {
    fetchProfileImage();
  }, []);

  /**
   * @function fetchProfileImage
   * @description function to fetch the profile image
   */
  const fetchProfileImage = async () => {
    getOtherUsersProfilePhoto(user.email)
      .then(async (res: any) => {
        if (res?.status === 200) {
          const imageBlob = await res.blob();
          const imageObjectURL = URL.createObjectURL(imageBlob);
          setProfilePic(imageObjectURL);
        }
      })
      .catch((e) => {
        onApiCallError(e, false);
      });
  };

  return (
    <div
      key={user.email}
      className="share-report-user-row flex flex-align-items-center flex-space-between"
    >
      <div className="flex flex-align-items-center flex-gap-8">
        <img src={profilePic} alt="user" className="user-pic" />
        <div>
          <div className="font-button">{user.name}</div>
          <div className="email font-caption">{user.email}</div>
        </div>
      </div>
      <div className="flex flex-align-items-center flex-gap-8">
        <SelectDropdown
          className="permission-dropdown"
          value={user.permission}
          options={ShareReportPermissionOptions}
          onSelect={onSelectPermission}
        />
        <span className="flex flex-end flex-fit">
          <Icon
            className="remove-icon"
            dataTestId="remove-user-icon"
            iconName={ICONS.CLOSE_LINE}
            size={ICONS_SIZE.FW}
            onClick={() => onClickRemoveUser(user)}
          />
        </span>
      </div>
    </div>
  );
};

export default UserRow;
