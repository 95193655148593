import { message } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LoadingIcon } from 'assets/icons';
import Input from 'components/Input';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import {
  CUSTOM_DASHBOARD_MODE,
  CUSTOM_DASHBOARD_TYPES,
  MY_DASHBOARD_TYPES,
} from 'constants/dashboard';
import { BUTTON_TYPE, INPUT_SIZE } from 'constants/appearance';
import { defaultCustomViewData } from 'constants/defaultValues';
import { ICONS } from 'constants/icons';
import {
  customDashboard,
  setCustomDashboardDataSource,
  setCustomDashBoardMode,
  setCustomViewData,
} from 'redux/customDashboardSlice';
import {
  selectDashboard,
  setDashboardList,
  setSelectedConnection,
  setSelectedDashboard,
  setSelectedDashboardView,
  setSelectedGroupMetaData,
  setSelectedMyDashboardType,
} from 'redux/dashboardSlice';
import { selectTheme } from 'redux/themeSlice';
import { CUSTOM_VIEW_PUBLISH_STATUS } from 'pages/CustomDashboardPage/constants';
import {
  createCustomDashboard,
  createCustomView,
  updateCustomView,
} from 'pages/CustomDashboardPage/services';
import { DashboardListType } from 'types/navigationMenu';
import { DASHBOARD_STATUS } from 'pages/DashboardsPage/constants';
import { IntegrationTypes } from 'pages/IntegrationsPage/constants';
import {
  removePartialConditionsQuery,
  removePartialSortQuery,
} from 'pages/CustomDashboardPage/utils';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import {
  getGroupProvider,
  getGroupProviderLogo,
  getProviderSmallLogo,
} from 'utils/providerDetails';
import { fetchDashboardList, updateDashboardTitle } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { getProviderForConnection } from 'utils/dashboardUtils';
import { validateStringLengthLessThan } from 'utils/validations';

import './index.scss';

const CustomDashboardHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    customDashboardConnection,
    customDashboardIntegrationConnection,
    customDashboardDataSource,
    isAddNewDashboard,
    customDashboardGroup,
    dashboardType,
    customViewData,
    selectedDashboardCustomViews,
  } = useSelector(customDashboard);
  const { theme } = useSelector(selectTheme);
  const navigate = useNavigate();
  const { selectedDashboard } = useSelector(selectDashboard);

  const [editTitle, setEditTitle] = useState(false);
  const [publishRequestStatus, setPublishRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [draftRequestStatus, setDraftRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [loading, setLoading] = useState(REQUEST_STATUS.SUCCESS);

  const updateTitle = (e: any) => {
    dispatch(
      setCustomDashboardDataSource({
        ...customDashboardDataSource,
        dashboardTitle: e.target.value,
      })
    );
    if (!isAddNewDashboard) {
      setLoading(REQUEST_STATUS.PROCESSING);
      const params = {
        dashboardId: selectedDashboard?.id!,
        updateDashboardName: e.target.value,
      };
      updateDashboardTitle(params)
        .then(() => {
          setEditTitle(false);
          setLoading(REQUEST_STATUS.SUCCESS);
          message.success({
            content: t('dashboardLabels.titleUpdateSuccessMessage'),
            key: SUCCESS_KEY,
          });
        })
        .catch((e) => onApiCallError(e, true, setLoading));
    } else {
      setEditTitle(false);
    }
  };

  /**
   * @function publishValidations
   * @description Function to validate dashboard for publish
   */
  const publishValidations = () => {
    if (!customDashboardDataSource?.dashboardTitle) {
      message.error(
        t('customDashboard.validationMessages.customDashboardNameisRequired')
      );
      return false;
    }

    if (!customViewData?.viewName) {
      message.error(
        t('customDashboard.validationMessages.customViewNameisRequired')
      );
      return false;
    }

    if (
      !isAddNewDashboard &&
      selectedDashboardCustomViews.some(
        (view) =>
          view.viewName === customViewData?.viewName &&
          view.id !== customViewData.id
      )
    ) {
      message.warning(
        t('customDashboard.validationMessages.duplicateViewName')
      );
      return false;
    }

    if (
      !customViewData?.layoutDesigns ||
      customViewData?.layoutDesigns?.length === 0
    ) {
      message.error(
        t('customDashboard.validationMessages.atleastOneLayoutisRequired')
      );
      return false;
    }

    const isEmptyTable = customViewData?.layoutDesigns?.some((layout) =>
      layout?.charts?.some(
        (chart) =>
          !chart?.tableQuery ||
          (chart.tableQuery.columns && chart.tableQuery.columns.length === 0)
      )
    );

    if (isEmptyTable) {
      message.error(t('customDashboard.validationMessages.emptyChartData'));
      return false;
    }

    const isGraphNameInvalid = customViewData?.layoutDesigns?.some((layout) =>
      layout?.charts?.some(
        (chart) =>
          chart?.chartName && validateStringLengthLessThan(chart?.chartName)
      )
    );

    if (isGraphNameInvalid) {
      return false;
    }

    return true;
  };

  /**
   * @function draftValidations
   * @description Function to validate dashboard for save as draft
   */
  const draftValidations = () => {
    if (!customDashboardDataSource?.dashboardTitle) {
      message.error(
        t('customDashboard.validationMessages.customDashboardNameisRequired')
      );
      return false;
    }
    return true;
  };

  /**
   * @function fetchDashboardsAndSetDefaultDashboard
   * @description Function to fetch the dashboard and set the selected dashboard
   * @param dashboardId Id of the new dashboard selected.
   * @param publishStatus status of the publishing either PUBLISH or DRAFT
   */
  const fetchDashboardsAndSetDefaultDashboard = (
    dashboardId: string,
    publishStatus: string
  ) => {
    fetchDashboardList()
      .then((res: any) => {
        const dashboards: DashboardListType[] =
          res?.data?.responseData?.content;
        dispatch(
          setDashboardList(
            dashboards.filter(
              (value) => value.dashboardStatus !== DASHBOARD_STATUS.DE_ACTIVATED
            )
          )
        );
        dispatch(
          setSelectedDashboard(
            dashboards.find((dashboard) => dashboard.id === dashboardId)!
          )
        );
        dispatch(setSelectedConnection(customDashboardConnection));
        dispatch(setSelectedGroupMetaData(customDashboardGroup));
        dispatch(setCustomDashBoardMode(CUSTOM_DASHBOARD_MODE.PUBLISHED));
        dispatch(setSelectedMyDashboardType(dashboardType));
        publishStatus === CUSTOM_VIEW_PUBLISH_STATUS.PUBLISH
          ? setPublishRequestStatus(REQUEST_STATUS.SUCCESS)
          : setDraftRequestStatus(REQUEST_STATUS.SUCCESS);
        navigate(NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS + '/' + dashboardId);
        dispatch(setCustomViewData(defaultCustomViewData));
      })
      .catch((e: any) => {
        message.error({ content: t('apiCallErrorMessage'), key: ERROR_KEY });
        onApiCallError(e, false);
      });
  };

  /**
   * @function handlePublishError
   * @description Function to handle publish custom view or dashboard error
   * @param publishStatus status of the publishing either PUBLISH or DRAFT
   * @param error The error that occurred
   */
  const handlePublishError = (publishStatus: string, error: any) => {
    publishStatus === CUSTOM_VIEW_PUBLISH_STATUS.PUBLISH
      ? setPublishRequestStatus(REQUEST_STATUS.ERROR)
      : setDraftRequestStatus(REQUEST_STATUS.ERROR);
    onApiCallError(error, false);
  };

  /**
   * @function handlePublishCustomDashboard
   * @description Function to handle publish custom dashboard
   * @param publishStatus status of the publishing either PUBLISH or DRAFT
   */
  const handlePublishCustomDashboard = (publishStatus: string) => {
    if (
      publishStatus === CUSTOM_VIEW_PUBLISH_STATUS.PUBLISH &&
      !publishValidations()
    ) {
      return;
    }

    if (
      publishStatus === CUSTOM_VIEW_PUBLISH_STATUS.DRAFT &&
      !draftValidations()
    ) {
      return;
    }
    const requestBody = {
      name: customDashboardDataSource?.dashboardTitle,
      connectorId:
        dashboardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION
          ? customDashboardConnection?.connectorId
          : undefined,
      connectorName:
        dashboardType === MY_DASHBOARD_TYPES.SINGLE_CONNECTION
          ? customDashboardConnection?.name
          : undefined,
      transactionName:
        dashboardType === MY_DASHBOARD_TYPES.IMPORTS
          ? customDashboardConnection?.name
          : undefined,
      integrationId:
        dashboardType === MY_DASHBOARD_TYPES.SNOWFLAKE
          ? customDashboardIntegrationConnection?.integrationId
          : undefined,
      groupName: customDashboardGroup?.name,
      customViews: [
        {
          ...customViewData,
          viewStatus: publishStatus,
          dataSource: {
            ...customDashboardDataSource,
            dataset: customDashboardDataSource.dataSet,
          },
        },
      ],
      type: CUSTOM_DASHBOARD_TYPES.CUSTOM,
      dashboardStatus: publishStatus,
      connectorProvider: customDashboardConnection?.provider,
    };

    publishStatus === CUSTOM_VIEW_PUBLISH_STATUS.PUBLISH
      ? setPublishRequestStatus(REQUEST_STATUS.PROCESSING)
      : setDraftRequestStatus(REQUEST_STATUS.PROCESSING);

    createCustomDashboard(requestBody)
      .then((res: any) => {
        fetchDashboardsAndSetDefaultDashboard(
          res?.data?.responseData,
          publishStatus
        );
      })
      .catch((error) => {
        handlePublishError(publishStatus, error);
      });
  };

  /**
   * @function handlePublishCustomView
   * @description Function to handle publish custom view
   * @param publishStatus status of the publishing either PUBLISH or DRAFT
   */
  const handlePublishCustomView = (publishStatus: string) => {
    if (
      publishStatus === CUSTOM_VIEW_PUBLISH_STATUS.PUBLISH &&
      !publishValidations()
    ) {
      return;
    }

    if (
      publishStatus === CUSTOM_VIEW_PUBLISH_STATUS.DRAFT &&
      !draftValidations()
    ) {
      return;
    }

    const requestBody = {
      ...customViewData,
      viewStatus: publishStatus,
      dataSource: {
        ...customDashboardDataSource,
        dataset: customDashboardDataSource.dataSet,
      },
    };

    const requestParams = {
      dashboardId: selectedDashboard?.id!,
    };

    publishStatus === CUSTOM_VIEW_PUBLISH_STATUS.PUBLISH
      ? setPublishRequestStatus(REQUEST_STATUS.PROCESSING)
      : setDraftRequestStatus(REQUEST_STATUS.PROCESSING);

    (customViewData?.id
      ? updateCustomView(requestBody)
      : createCustomView(requestBody, requestParams)
    )
      .then((res: any) => {
        dispatch(setSelectedDashboardView(res?.data?.responseData));
        publishStatus === CUSTOM_VIEW_PUBLISH_STATUS.PUBLISH
          ? setPublishRequestStatus(REQUEST_STATUS.SUCCESS)
          : setDraftRequestStatus(REQUEST_STATUS.SUCCESS);
        navigate(
          NAVIGATION_MENU_PATH.SPEND_DIAGNOSTICS + '/' + selectedDashboard?.id
        );
        dispatch(setCustomDashBoardMode(CUSTOM_DASHBOARD_MODE.PUBLISHED));
      })
      .catch((error) => {
        handlePublishError(publishStatus, error);
      });
  };

  /**
   * @function getDashboardDatasourceLogo
   * @description Function to get the logo of the dashboard datasource
   * @returns logo of the dashboard datasource
   */
  const getDashboardDatasourceLogo = () => {
    switch (dashboardType) {
      case MY_DASHBOARD_TYPES.GROUP:
        return getGroupProviderLogo(getGroupProvider(customDashboardGroup));
      case MY_DASHBOARD_TYPES.SNOWFLAKE:
        return getProviderSmallLogo(IntegrationTypes.SNOWFLAKE);
      default:
        return getProviderSmallLogo(
          getProviderForConnection(customDashboardConnection)
        );
    }
  };

  /**
   * @function getCustomDashboardDatasource
   * @description Function to get the name of the dashboard datasource
   * @returns name of the dashboard datasource
   */
  const getCustomDashboardDatasource = () => {
    switch (dashboardType) {
      case MY_DASHBOARD_TYPES.GROUP:
        return customDashboardGroup?.name;

      case MY_DASHBOARD_TYPES.SNOWFLAKE:
        return customDashboardIntegrationConnection?.name;

      default:
        return customDashboardConnection?.name;
    }
  };

  return (
    <div className="custom-dashboard-header new-page-header flex flex-align-items-center flex-space-between">
      <div className="flex flex-center flex-gap-8">
        <img
          height={32}
          src={getDashboardDatasourceLogo()}
          alt={`${
            customDashboardIntegrationConnection
              ? IntegrationTypes.SNOWFLAKE
              : customDashboardConnection?.provider
          } Logo`}
        />
        <div className="flex flex-column">
          <div className="flex flex-row">
            {editTitle ? (
              <div className="flex flex-align-items-center">
                <Input
                  defaultValue={
                    customDashboardDataSource?.dashboardTitle ||
                    t('customDashboard.headerLabels.addATitle')
                  }
                  size={INPUT_SIZE.SMALL}
                  onPressEnter={(e: any) => updateTitle(e)}
                  onBlur={(e: any) => updateTitle(e)}
                />
                {loading === REQUEST_STATUS.PROCESSING && (
                  <Icon
                    icon={LoadingIcon}
                    className="rotate"
                    color={theme.buttonIconColor}
                  />
                )}
              </div>
            ) : (
              <div className="flex flex-gap-4 flex-center">
                <div className="modal-heading">
                  {customDashboardDataSource?.dashboardTitle ||
                    t('customDashboard.headerLabels.addATitle')}
                </div>
                <Icon
                  className="edit-icon"
                  iconName={ICONS.EDIT_LINE}
                  onClick={() => setEditTitle(true)}
                  color={theme.buttonIconColor}
                />
              </div>
            )}
          </div>
          <div className="flex flex-gap-4 font-caption-bold">
            <span>{getCustomDashboardDatasource()}</span>
            <span className="data-source-type">
              {customDashboardConnection?.dataSourceType}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-gap-8">
        <Button
          type={BUTTON_TYPE.LINK}
          title={t('customDashboard.headerLabels.discard')}
          onClick={() => navigate(NAVIGATION_MENU_PATH.DASHBOARDS)}
        />
        <Button
          type={BUTTON_TYPE.NEUTRAL}
          title={t('customDashboard.headerLabels.saveAsDraft')}
          loading={draftRequestStatus === REQUEST_STATUS.PROCESSING}
          onClick={() => {
            removePartialConditionsQuery();
            removePartialSortQuery();
            isAddNewDashboard
              ? handlePublishCustomDashboard(CUSTOM_VIEW_PUBLISH_STATUS.DRAFT)
              : handlePublishCustomView(CUSTOM_VIEW_PUBLISH_STATUS.DRAFT);
          }}
        />
        <Button
          type={BUTTON_TYPE.PRIMARY}
          title={t('customDashboard.headerLabels.publish')}
          loading={publishRequestStatus === REQUEST_STATUS.PROCESSING}
          onClick={() => {
            removePartialConditionsQuery();
            removePartialSortQuery();
            isAddNewDashboard
              ? handlePublishCustomDashboard(CUSTOM_VIEW_PUBLISH_STATUS.PUBLISH)
              : handlePublishCustomView(CUSTOM_VIEW_PUBLISH_STATUS.PUBLISH);
          }}
        />
      </div>
    </div>
  );
};

export default CustomDashboardHeader;
