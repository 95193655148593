import i18n from 'i18n';

import { DASHBOARD } from './components/HorizontalNavigation/constants';

export const DashboardViewsWithControlComponent: string[] = [
  DASHBOARD.COST_RECOMMENDATION_SUMMARY,
  DASHBOARD.COST_SUMMARY,
  DASHBOARD.PROJECT_DEEP_DIVE,
  DASHBOARD.SNOWFLAKE_WAREHOUSE,
  DASHBOARD.SNOWFLAKE_STORAGE,
  DASHBOARD.COMPUTE,
  DASHBOARD.STORAGE,
  DASHBOARD.DATABASE,
];

export enum UnitCostTimePeriod {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export const UNIT_COST_TIME_PERIOD_OPTIONS = [
  { label: i18n.t('hourly'), value: UnitCostTimePeriod.HOURLY },
  { label: i18n.t('daily'), value: UnitCostTimePeriod.DAILY },
  { label: i18n.t('weekly'), value: UnitCostTimePeriod.WEEKLY },
];

export const DiskTypeToStorageMap = {
  'P1 %': 4,
  'P2 %': 8,
  'P3 %': 16,
  'P4 %': 32,
  'P6 %': 64,
  'P10 %': 128,
  'P15 %': 256,
  'P20 %': 512,
  'P30 %': 1024,
  'P40 %': 2048,
  'P50 %': 4096,
  'P60 %': 8192,
  'P70 %': 16384,
  'P80 %': 32767,
  '%E4 %': 32,
  '%E1 LRS Disk%': 4,
  '%E2 LRS Disk%': 8,
  '%E3 LRS Disk%': 16,
  '%E6 LRS Disk%': 64,
  '%E10 LRS Disk%': 128,
  '%S4 LRS Disk%': 32,
  '%S6 LRS Disk%': 64,
  '%S10 LRS Disk%': 128,
};
