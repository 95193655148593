import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Loader from 'components/Loader';
import { budgetsAndAlerts, setBudgetData } from 'redux/budgetsAndAlertsSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { MONTH_FORMAT, SHORT_MONTH_FORMAT } from 'utils/date';
import { getAwsBudget } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';

import { BUDGET_TYPES } from './constants';
import ConnectionForm from '../../ConnectionForm';
import ScopeForm from '../ScopeForm';
import AmountForm from '../../AmountForm';
import ActionsForm from '../../ActionsForm';
import NotificationsForm from '../../NotificationsForm';

type SetupBudgetFormAWSProps = {
  currentStep: number;
  isBudgetConnectionError: boolean;
  setIsBudgetConnectionError: (value: boolean) => void;
};

const SetupBudgetFormAWS = ({
  currentStep,
  isBudgetConnectionError,
  setIsBudgetConnectionError,
}: SetupBudgetFormAWSProps) => {
  const dispatch = useDispatch();
  const { budgetData, isEdit } = useSelector(budgetsAndAlerts);

  const [budgetDetailsLoading, setBudgetDetailsLoading] = useState<string>(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    isEdit && fetchBudgetData();
  }, []);

  const fetchBudgetData = () => {
    const params = {
      connectorId: budgetData.connectorId,
      budgetName: budgetData.displayName,
    };
    setBudgetDetailsLoading(REQUEST_STATUS.PROCESSING);
    getAwsBudget(params)
      .then((res: any) => {
        const fetchedBudgetDetails = res.data.responseData;
        dispatch(
          setBudgetData({
            ...fetchedBudgetDetails,
            displayName: fetchedBudgetDetails.name,
            connectorId: budgetData.connectorId,
            timeRange: fetchedBudgetDetails.awsTimeRange,
            budgetType: fetchedBudgetDetails.budgetingMethod,
            selectedMonth: moment(
              parseInt(fetchedBudgetDetails.startMonth.slice(4, 6)),
              MONTH_FORMAT
            ).format(SHORT_MONTH_FORMAT),
            selectedYear: fetchedBudgetDetails.startMonth.slice(0, 4),
            thresholdRules: fetchedBudgetDetails.thresholdRules.map(
              (rule: any) => ({
                ...rule,
                amount: (fetchedBudgetDetails.amount * rule.percentage) / 100,
              })
            ),
          })
        );
        setBudgetDetailsLoading(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setBudgetDetailsLoading);
      });
  };

  if (budgetDetailsLoading === REQUEST_STATUS.PROCESSING && isEdit) {
    return <Loader />;
  }

  switch (currentStep) {
    case 1:
      return (
        <ConnectionForm
          isBudgetConnectionError={isBudgetConnectionError}
          setIsBudgetConnectionError={setIsBudgetConnectionError}
        />
      );

    case 2:
      return <ScopeForm />;

    case 3:
      return <AmountForm budgetTypeOptions={BUDGET_TYPES} />;

    case 4:
      return <ActionsForm />;

    case 5:
      return <NotificationsForm />;
  }

  return <></>;
};

export default SetupBudgetFormAWS;
