import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Empty, message } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import moment from 'moment';

import { selectCommonUtility } from 'redux/commonUtilitySlice';
import TableFilterDropdown from 'components/TableFilterDropdown';
import Table from 'components/Table';
import Button from 'components/Button';
import SearchInput from 'components/Input';
import {
  deleteAwsBudget,
  deleteAzureBudget,
  deleteGcpBudget,
  fetchAllBudgets,
} from 'utils/services';
import {
  setBudgetsAlertsComponent,
  setBudgetData,
  setIsEdit,
} from 'redux/budgetsAndAlertsSlice';
import { setSelectedProvider } from 'redux/providerSlice';
import { userAuthorization } from 'redux/authorizationSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { CONNECTION_QUICK_ACTIONS } from 'constants/quickAction';
import QuickActionMenu from 'components/QuickActionMenu';
import DeleteModal from 'components/DeleteModal';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import { CLOUD_PROVIDERS_LIST, PROVIDER } from 'constants/cloudProviders';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { DATE_FORMAT } from 'utils/date';
import NavigationPath from 'components/NavigationPath';
import { INPUT_SIZE } from 'constants/appearance';
import Pagination from 'components/Pagination';
import { PAGINATION_SIZE } from 'constants/userConsole';
import { getProviderSmallLogo } from 'utils/providerDetails';
import { onApiCallError } from 'utils/handleErrors';

import {
  BudgetsAndAlertsComponents,
  BudgetsAndAlertsQuickActions,
  DEFAULT_BUDGET_DATA,
} from './constants';
import { BudgetDataTypes } from './types';
import {
  deleteOciBudget,
  fetchAllAWSBudgets,
  fetchAllAzureBudgets,
  fetchAllOciBudgets,
} from './services';

import './index.scss';

const BudgetsAndAlertsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permissions } = useSelector(userAuthorization);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [totalBudgetsCount, setTotalBudgetsCount] = useState(100);
  const [filterProviders, setFilterProviders] = useState<string[] | null>([]);

  const [awsBudgetData, setAwsBudgetData] = useState<BudgetDataTypes[]>([]);
  const [awsBudgetRequestStatus, setAwsBudgetRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [gcpBudgetData, setGcpBudgetData] = useState<BudgetDataTypes[]>([]);
  const [gcpBudgetRequestStatus, setGcpBudgetRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [azureBudgetData, setAzureBudgetData] = useState<BudgetDataTypes[]>([]);
  const [azureBudgetRequestStatus, setAzureBudgetRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [ociBudgetData, setOciBudgetData] = useState<BudgetDataTypes[]>([]);
  const [ociBudgetRequestStatus, setOciBudgetRequestStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [allBudgetData, setAllBudgetData] = useState<BudgetDataTypes[]>([]);
  const [filteredBudgetData, setFilteredBudgetData] = useState<
    BudgetDataTypes[]
  >([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteParams, setDeleteParams] = useState<any>(null);
  const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.SUCCESS);
  const [deleteLoading, setDeleteLoading] = useState<string>('');
  const [selectedBudgetData, setSelectedBudgetData] = useState<any>();

  useEffect(() => {
    onFetchAllGCPBudgets();
    onFetchAllAWSBudgets();
    onFetchAllAzureBudgets();
    onFetchAllOCIBudgets();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    const allBudgets = [
      ...gcpBudgetData,
      ...awsBudgetData,
      ...azureBudgetData,
      ...ociBudgetData,
    ];
    setAllBudgetData(allBudgets);
    setTotalBudgetsCount(allBudgets.length);
  }, [awsBudgetData, gcpBudgetData, azureBudgetData, ociBudgetData]);

  useEffect(() => {
    const data = allBudgetData
      .filter((budget) => {
        return (
          budget.displayName.toLowerCase().includes(searchKey?.toLowerCase()) &&
          containsProviderFilter(budget.cloudProvider)
        );
      })
      .slice((currentPage - 1) * 10, (currentPage - 1) * 10 + PAGINATION_SIZE);
    setFilteredBudgetData(data);
  }, [allBudgetData, currentPage, searchKey, filterProviders]);

  useEffect(() => {
    const requestStatus = [
      awsBudgetRequestStatus,
      gcpBudgetRequestStatus,
      azureBudgetRequestStatus,
      ociBudgetRequestStatus,
    ];
    if (requestStatus.includes(REQUEST_STATUS.PROCESSING)) {
      setRequestStatus(REQUEST_STATUS.PROCESSING);
      return;
    }
    if (requestStatus.every((status) => status === REQUEST_STATUS.ERROR)) {
      setRequestStatus(REQUEST_STATUS.ERROR);
      return;
    }
    setRequestStatus(REQUEST_STATUS.SUCCESS);
  }, [
    awsBudgetRequestStatus,
    gcpBudgetRequestStatus,
    azureBudgetRequestStatus,
    ociBudgetRequestStatus,
  ]);

  /**
   * @function containsProviderFilter
   * @description Function to check if the provider if of filtered provider
   * @param provider provider to check
   * @returns boolean true if the provider is of filtered provider or filtered provider is empty or null else false
   */
  const containsProviderFilter = (provider: string) => {
    if (filterProviders?.length) {
      return filterProviders?.includes(provider);
    }
    return true;
  };

  /**
   * @function onFetchAllGCPBudgets
   * @description Function to fetch all GCP budget data
   */
  const onFetchAllGCPBudgets = async () => {
    setGcpBudgetRequestStatus(REQUEST_STATUS.PROCESSING);
    try {
      const resDataGCP: any = await fetchAllBudgets();
      if (resDataGCP.status === 200) {
        setGcpBudgetData(
          resDataGCP.data.responseData.map((budget: any) => ({
            ...budget,
            displayName: budget.budgetDisplayName,
          }))
        );
        setGcpBudgetRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      setGcpBudgetData([]);
      setGcpBudgetRequestStatus(REQUEST_STATUS.ERROR);
    } catch (error) {
      onApiCallError(error, false, setGcpBudgetRequestStatus);
      setGcpBudgetData([]);
    }
  };

  /**
   * @function onFetchAllAzureBudgets
   * @description Function to fetch all Azure budget data
   */
  const onFetchAllAzureBudgets = async () => {
    setAzureBudgetRequestStatus(REQUEST_STATUS.PROCESSING);
    try {
      const resDataAzure: any = await fetchAllAzureBudgets();
      if (resDataAzure.status === 200) {
        setAzureBudgetData(
          resDataAzure.data.responseData.map((budget: any) => ({
            ...budget,
            displayName: budget.budgetDisplayName,
          }))
        );
        setAzureBudgetRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      setAzureBudgetData([]);
      setAzureBudgetRequestStatus(REQUEST_STATUS.ERROR);
    } catch (error) {
      onApiCallError(error, false, setAzureBudgetRequestStatus);
      setAzureBudgetData([]);
    }
  };

  /**
   * @function onFetchAllAWSBudgets
   * @description Function to fetch all AWS budget data
   */
  const onFetchAllAWSBudgets = async () => {
    setAwsBudgetRequestStatus(REQUEST_STATUS.PROCESSING);
    try {
      const resDataAWS: any = await fetchAllAWSBudgets();
      if (resDataAWS.status === 200) {
        setAwsBudgetData(
          resDataAWS.data.responseData.map((budget: any) => ({
            ...budget,
            displayName: budget.budgetDisplayName,
          }))
        );
        setAwsBudgetRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      setAwsBudgetData([]);
      setAwsBudgetRequestStatus(REQUEST_STATUS.ERROR);
    } catch (error) {
      onApiCallError(error, false, setAwsBudgetRequestStatus);
      setAwsBudgetData([]);
    }
  };

  /**
   * @function onFetchAllOCIBudgets
   * @description Function to fetch all OCI budget data
   */
  const onFetchAllOCIBudgets = async () => {
    setOciBudgetRequestStatus(REQUEST_STATUS.PROCESSING);
    try {
      const resDataOCI: any = await fetchAllOciBudgets();
      if (resDataOCI.status === 200) {
        setOciBudgetData(
          resDataOCI.data.responseData.map((budget: any) => ({
            ...budget,
            displayName: budget.budgetDisplayName,
          }))
        );
        setOciBudgetRequestStatus(REQUEST_STATUS.SUCCESS);
        return;
      }
      setOciBudgetData([]);
      setOciBudgetRequestStatus(REQUEST_STATUS.ERROR);
    } catch (error) {
      onApiCallError(error, false, setOciBudgetRequestStatus);
      setOciBudgetData([]);
    }
  };

  /**
   * @function onBudgetDeleteSuccess
   * @description function to handle what happens after successful deletion ie. showing messages and such
   * @param responseStatus The response status code for the deleting API request
   */
  const onBudgetDeleteSuccess = (responseStatus: number) => {
    setShowDeleteModal(false);
    if (responseStatus === 200) {
      message.success(
        t('budgetDeleteModal.deleteSuccess') +
          selectedBudgetData.budgetDisplayName
      );
      setAllBudgetData([]);
      onFetchAllAWSBudgets();
      onFetchAllAzureBudgets();
      onFetchAllGCPBudgets();
      onFetchAllOCIBudgets();
      setDeleteLoading(REQUEST_STATUS.SUCCESS);
    } else {
      message.error(
        t('budgetDeleteModal.deleteError') +
          selectedBudgetData.budgetDisplayName
      );
      setDeleteLoading(REQUEST_STATUS.ERROR);
    }
  };

  /**
   * @function onBudgetDeleteError
   * @description function to handle what happens after facing error when deleting ie. showing messages
   * @param e The error which occurred while making deletion API request
   */
  const onBudgetDeleteError = (e: any) => {
    onApiCallError(
      e,
      true,
      setDeleteLoading,
      t('budgetDeleteModal.deleteError') + selectedBudgetData.budgetDisplayName
    );
  };

  /**
   * @function deleteAwsBudgetSetup
   * @description Set up function to pass in delete modal
   */
  const deleteAwsBudgetSetup = (params: any) => {
    setDeleteLoading(REQUEST_STATUS.PROCESSING);
    deleteAwsBudget(params)
      .then((res: any) => {
        onBudgetDeleteSuccess(res?.status);
      })
      .catch((e: any) => {
        onBudgetDeleteError(e);
      });
  };

  /**
   * @function deleteAzureBudgetSetup
   * @description Set up function to pass in delete modal
   */
  const deleteAzureBudgetSetup = (params: any) => {
    setDeleteLoading(REQUEST_STATUS.PROCESSING);
    deleteAzureBudget(params)
      .then((res: any) => {
        onBudgetDeleteSuccess(res?.status);
      })
      .catch((e: any) => {
        onBudgetDeleteError(e);
      });
  };

  /**
   * @function deleteGcpBudgetSetup
   * @description Set up function to pass in delete modal
   */
  const deleteGcpBudgetSetup = (params: any) => {
    setDeleteLoading(REQUEST_STATUS.PROCESSING);
    deleteGcpBudget(params)
      .then((res: any) => {
        onBudgetDeleteSuccess(res?.status);
      })
      .catch((e: any) => {
        onBudgetDeleteError(e);
      });
  };

  /**
   * @function deleteOciBudgetSetup
   * @description Set up function to pass in delete modal
   */
  const deleteOciBudgetSetup = (params: any) => {
    setDeleteLoading(REQUEST_STATUS.PROCESSING);
    deleteOciBudget(params)
      .then((res: any) => {
        onBudgetDeleteSuccess(res?.status);
      })
      .catch((e: any) => {
        onBudgetDeleteError(e);
      });
  };

  /**
   * @function bodyForDeleteBudget
   * @description Set up body to pass in respective delete function
   */
  const bodyForDeleteBudget = (budgetDataRecord: any) => {
    switch (budgetDataRecord.cloudProvider) {
      case PROVIDER.GCP: {
        setDeleteParams({
          connectorId: budgetDataRecord.connectorId,
          budgetName: budgetDataRecord.budgetName,
        });
        break;
      }
      case PROVIDER.AWS:
      case PROVIDER.AZURE: {
        setDeleteParams({
          connectorId: budgetDataRecord.connectorId,
          budgetName: budgetDataRecord.budgetDisplayName,
        });
        break;
      }
      case PROVIDER.OCI: {
        setDeleteParams({
          connectorId: budgetDataRecord.connectorId,
          budgetId: budgetDataRecord.budgetId,
        });
        break;
      }
    }
  };

  const setDeleteBodyByProvider = (budgetDataToBeDeleted: any) => {
    switch (budgetDataToBeDeleted.cloudProvider) {
      case PROVIDER.AWS: {
        deleteAwsBudgetSetup(deleteParams);
        break;
      }
      case PROVIDER.GCP: {
        deleteGcpBudgetSetup(deleteParams);
        break;
      }
      case PROVIDER.AZURE: {
        deleteAzureBudgetSetup(deleteParams);
        break;
      }
      case PROVIDER.OCI: {
        deleteOciBudgetSetup(deleteParams);
        break;
      }
    }
  };

  const handleQuickAction = (value: any, action: string) => {
    setSelectedBudgetData(value);
    switch (action) {
      case BudgetsAndAlertsQuickActions.EDIT:
        onClickQuickActionEdit(value);
        break;
      case BudgetsAndAlertsQuickActions.DELETE:
        setShowDeleteModal(true);
        break;
    }
  };

  const onClickQuickActionEdit = (data: any) => {
    dispatch(setSelectedProvider(data.cloudProvider));
    dispatch(setIsEdit(true));
    dispatch(setBudgetData(data));
    dispatch(
      setBudgetsAlertsComponent(
        BudgetsAndAlertsComponents.BUDGETS_ALERTS_CONNECTION_FORM
      )
    );
    navigate(NAVIGATION_MENU_PATH.CREATE_BUDGET);
  };

  const getDateRangeText = (text: string) => {
    const dates = text.split('-');

    return (
      moment(dates[0]).format(DATE_FORMAT) +
      ' - ' +
      moment(dates[1]).format(DATE_FORMAT)
    );
  };

  /**
   * @function getCloudProviderFilterDropdown
   * @description Function to return the cloud provider dropdown for table filter
   * @param Object containing the filter dropdown props
   * @returns JSX element
   */
  const getCloudProviderFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
  }: FilterDropdownProps) => (
    <TableFilterDropdown
      allKeys={filters ?? []}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
      width: '5%',
    },
    {
      title: t('addBudgetAlert.budgetAlertTable.budgetName'),
      dataIndex: 'budgetDisplayName',
      key: 'budgetDisplayName',
      filters: CLOUD_PROVIDERS_LIST,
      filterIcon: <Icon iconName={ICONS.FILTER_2_FILL} />,
      filterDropdown: getCloudProviderFilterDropdown,
      render: (text: any, record: BudgetDataTypes) => (
        <div className="flex flex-gap-8 flex-align-items-center">
          <div className="logo">
            <img
              src={getProviderSmallLogo(record.cloudProvider)}
              alt={`${record.cloudProvider} Logo`}
            />
          </div>
          {text}
        </div>
      ),
    },
    {
      title: t('addBudgetAlert.budgetAlertTable.budgetDuration'),
      dataIndex: 'budgetDuration',
      key: 'budgetDuration',
      render: (text: string) =>
        text.includes('-') ? getDateRangeText(text) : text,
    },
    {
      title: t('addBudgetAlert.budgetAlertTable.budget', {
        currencySymbol: currencySymbol,
      }),
      dataIndex: 'budget',
      key: 'budget',
      render: (text: number) => `$ ${text}`,
    },
    {
      title: t('addBudgetAlert.budgetAlertTable.quickAction'),
      dataIndex: 'quickAction',
      key: 'quickAction',
      render: (_text: string, record: BudgetDataTypes) => (
        <QuickActionMenu
          setCurrentConnectionData={() => {
            bodyForDeleteBudget(record);
          }}
          quickActions={CONNECTION_QUICK_ACTIONS}
          quickActionHandler={(action: string) => {
            handleQuickAction(record, action);
          }}
          disabled={!permissions.budgetAlertsModify}
        />
      ),
      width: '10%',
      align: 'center',
    },
  ];

  /**
   * @function onHandleAddBudget
   * @description Function to handle the add budget cta
   */
  const onHandleAddBudget = () => {
    dispatch(setIsEdit(false));
    dispatch(setBudgetData(DEFAULT_BUDGET_DATA));
    navigate(NAVIGATION_MENU_PATH.CREATE_BUDGET);
    dispatch(
      setBudgetsAlertsComponent(
        BudgetsAndAlertsComponents.BUDGETS_ALERTS_CONNECTION_FORM
      )
    );
  };

  return (
    <div className="budgets-and-alerts flex flex-column flex-fit">
      <header className="budgets-and-alerts-header new-page-header flex flex-align-items-center flex-space-between">
        <div className="modal-heading">{t('navigationMenu.budgetsList')}</div>
        {permissions.budgetAlertsWrite && (
          <Button
            onClick={onHandleAddBudget}
            title={t('addBudgetAlert.budgetAlertTable.addBudget')}
          />
        )}
      </header>
      <div className="page-content flex flex-column flex-fit">
        <NavigationPath />
        <div className="budgets-and-alerts-content margin-24 flex flex-column flex-fit">
          <SearchInput
            placeholder={t(
              'addBudgetAlert.budgetAlertTable.searchBudgetPlaceholder'
            )}
            type="search"
            size={INPUT_SIZE.SMALL}
            onChange={(e: any) => {
              setSearchKey(e.target.value);
              setCurrentPage(1);
            }}
          />

          <Table
            rootClassName="budget-table"
            pagination={false}
            dataSource={filteredBudgetData.map((budget, index) => {
              return {
                ...budget,
                key: index,
              };
            })}
            columns={columns}
            scroll={{ y: '100%' }}
            loading={requestStatus === REQUEST_STATUS.PROCESSING}
            locale={{
              emptyText: requestStatus !== REQUEST_STATUS.PROCESSING && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    requestStatus === REQUEST_STATUS.ERROR
                      ? t('graphErrorMessage')
                      : t('noData')
                  }
                />
              ),
            }}
            onChange={(_pagination: any, filters: any) => {
              setCurrentPage(1);
              setFilterProviders(filters.budgetDisplayName);
            }}
            designVersion2
            fillContainer
          />
        </div>
      </div>
      <div className="page-footer flex flex-fit flex-align-items-end">
        {totalBudgetsCount > 0 && (
          <Pagination
            current={currentPage}
            onChange={setCurrentPage}
            total={totalBudgetsCount}
            defaultPageSize={PAGINATION_SIZE}
          />
        )}
      </div>
      {showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          deletionFunction={() => setDeleteBodyByProvider(selectedBudgetData)}
          loading={deleteLoading === REQUEST_STATUS.PROCESSING}
        />
      )}
    </div>
  );
};

export default BudgetsAndAlertsPage;
