import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { customDashboard, setCustomViewData } from 'redux/customDashboardSlice';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import {
  getSelectedChart,
  getSelectedChartLayout,
} from 'pages/CustomDashboardPage/utils';
import { COLORS } from 'constants/graphConfig';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { ColumnType } from 'types/dashboard';
import { AGGREGATORS } from 'constants/requestBody';
import { CHARTS_LIST } from 'constants/dashboard';

import './index.scss';

const ChartMetricList = () => {
  const dispatch = useDispatch();
  const {
    selectedMetrics,
    customViewData,
    selectedChartIndex,
    selectedChartRow,
  } = useSelector(customDashboard);

  const [allChartMetrics, setAllChartMetrics] = useState<ColumnType[]>([]);

  useEffect(() => {
    setChartMetricByOrderOfSelection();
    updateChartColumns(
      selectedMetrics.filter((metric) =>
        getSelectedChart()
          ?.chartQuery?.aggregators?.map((aggregator) => aggregator.label)
          .includes(metric.field)
      )
    );
  }, [selectedMetrics]);

  /**
   * @function setChartMetricByOrderOfSelection
   * @description Function to set the chart metric by the order of chart columns
   */
  const setChartMetricByOrderOfSelection = () => {
    const chartAggregators = getSelectedChart()?.chartQuery?.aggregators;
    const chartMetrics: ColumnType[] = [];
    chartAggregators?.forEach((item) => {
      const metricColumn = selectedMetrics.find(
        (metric) => metric.field === item.label
      );
      if (metricColumn) {
        chartMetrics.push(metricColumn);
      }
    });

    chartMetrics.push(
      ...selectedMetrics.filter(
        (item) =>
          !chartAggregators?.some(
            (aggregator) => item.field === aggregator.label
          )
      )
    );
    setAllChartMetrics(chartMetrics);
  };

  /**
   * @function getMaxMetricCount
   * @description Function to return the max metric count of selected chart
   * @returns maximum metric numerical value. Defaults to 1.
   */
  const getMaxMetricCount = () => {
    const chart = getSelectedChart();
    return (
      CHARTS_LIST.find((item) => item.chartType === chart?.chartType)
        ?.metricsCount?.max ?? 1
    );
  };

  /**
   * @function updateChartColumns
   * @description Function to update the chart columns
   * @param metrics metrics to be updated with
   */
  const updateChartColumns = (metrics: ColumnType[]) => {
    const allLayouts = Array.from(customViewData.layoutDesigns);
    let layout = getSelectedChartLayout();
    const allCharts = Array.from(layout?.charts ?? []);
    let chart = allCharts?.at(selectedChartIndex);

    if (chart) {
      const dimensions =
        chart.chartQuery?.columns?.filter((item) =>
          chart?.chartQuery?.groupBy?.includes(item.field)
        ) ?? [];
      const chartQuery = {
        ...chart.chartQuery,
        columns: [...dimensions, ...metrics],
        aggregators: metrics.map((item) => ({
          label: item.field,
          function: AGGREGATORS.SUM,
        })),
      };
      chart = { ...chart, chartQuery: chartQuery };
      allCharts?.splice(selectedChartIndex, 1, chart);
    }

    if (layout) {
      layout = { ...layout, charts: allCharts };
      allLayouts.splice(selectedChartRow, 1, layout);
    }

    dispatch(
      setCustomViewData({ ...customViewData, layoutDesigns: allLayouts })
    );
  };

  /**
   * @function onChangeMetricSelection
   * @description Callback function for selecting metric
   * @param e Event for selection change
   */
  const onChangeMetricSelection = (e: any) => {
    if (e.target.type === 'radio') {
      updateChartColumns([
        ...allChartMetrics.filter((item) => item.field === e.target.value),
      ]);
    }

    const chart = getSelectedChart();
    let metricColumns: ColumnType[] = [];
    const columns = chart?.chartQuery?.columns ?? [];
    const aggregators = chart?.chartQuery?.aggregators;
    const existingMetrics = columns?.filter((item) =>
      aggregators?.some((aggregator) => aggregator.label === item.field)
    );

    if (e.target.checked) {
      const metrics = [
        ...existingMetrics,
        { field: e.target.value, label: e.target.value },
      ];
      metricColumns = allChartMetrics.filter((item) =>
        metrics.some((metric) => metric.field === item.field)
      );
    } else {
      metricColumns = existingMetrics?.filter(
        (item) => item.field !== e.target.value
      );
    }

    updateChartColumns(metricColumns);
  };

  /**
   * @function isMetricChecked
   * @description Function to check if the metric is selected
   * @returns boolean true if selected else false
   */
  const isMetricChecked = (metric: ColumnType) => {
    const chart = getSelectedChart();
    const columns = chart?.chartQuery?.columns ?? [];

    return columns.some((item) => item.field === metric.field);
  };

  /**
   * @function isMetricDisabled
   * @description Function to return if the metric is disabled
   * @param metric Metric for which the validation is performed.
   * @returns boolean true if the metric is disabled else false
   */
  const isMetricDisabled = (metric: ColumnType) => {
    const chart = getSelectedChart();
    const metrics = chart?.chartQuery?.aggregators;
    if (metrics?.some((item) => item.label === metric.field)) {
      return false;
    }

    return (metrics?.length ?? 0) >= getMaxMetricCount();
  };

  /**
   * @function isDraggingDisabled
   * @description Function to return if the dragging is disabled
   * @param metric Metric for which the validation is performed.
   * @returns boolean true if the dragging is disabled else false
   */
  const isDraggingDisabled = (metric: ColumnType) => {
    const chart = getSelectedChart();
    const columns = chart?.chartQuery?.columns;
    return !columns?.some((item) => item.field === metric.field);
  };

  /**
   * @function handleOnDragEnd
   * @description Updates the selected metrics list based on the drag and drop
   * @param result result of the drag and drop
   */
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const allMetrics = Array.from(allChartMetrics);
    const [reorderedItem] = allMetrics.splice(result.source.index, 1);
    allMetrics.splice(result.destination.index, 0, reorderedItem);
    setAllChartMetrics(allMetrics);

    const chartAggregators = getSelectedChart()?.chartQuery?.aggregators;
    const reOrderedMetrics = allMetrics.filter((item) =>
      chartAggregators?.some((aggregator) => aggregator.label === item.field)
    );
    updateChartColumns(reOrderedMetrics);
  };

  return (
    <div className="chart-metrics-list">
      {getMaxMetricCount() > 1 ? (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="chart-metrics">
            {(provided) => (
              <ul
                className="draggable-chart-metric-list flex flex-column flex-gap-8"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {allChartMetrics.map((metric, index) => (
                  <Draggable
                    key={metric.field}
                    draggableId={metric.field}
                    index={index}
                    isDragDisabled={isDraggingDisabled(metric)}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex flex-align-items-center flex-gap-4"
                      >
                        <Icon
                          iconName={ICONS.DRAGGABLE}
                          size={ICONS_SIZE.SM}
                          color={COLORS.colorRegentGrey}
                        />
                        <Checkbox
                          checked={isMetricChecked(metric)}
                          value={metric.field}
                          disabled={isMetricDisabled(metric)}
                          onChange={onChangeMetricSelection}
                        >
                          {metric.label}
                        </Checkbox>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="flex flex-column flex-gap-8">
          {allChartMetrics.map((metric) => (
            <Radio
              key={metric.field}
              value={metric.field}
              checked={isMetricChecked(metric)}
              onChange={onChangeMetricSelection}
            >
              {metric.label}
            </Radio>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChartMetricList;
