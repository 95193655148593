import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';

import './index.scss';

type SelectedTagComponentProps = {
  tag: string;
  priority?: number;
  onClickTag: () => void;
  dragHandleProps?: any;
};

const SelectedTagComponent = ({
  tag,
  priority,
  onClickTag,
  dragHandleProps,
}: SelectedTagComponentProps) => {
  return (
    <div
      key={tag}
      className="tag-item flex flex-align-items-center flex-space-between"
      data-testid={'tag-item' + priority}
      {...dragHandleProps}
    >
      <div className="flex flex-align-items-center flex-gap-8">
        <div className="tag-priority">{`P${priority}`}</div>
        <Icon
          iconName={ICONS.DRAGGABLE}
          size={ICONS_SIZE.SM}
          className="tag-handle"
        />
        <div className="tag-value">{tag}</div>
      </div>
      <Icon
        iconName={ICONS.SUBTRACT_LINE}
        className="remove-icon"
        dataTestId="remove-icon"
        onClick={onClickTag}
      />
    </div>
  );
};

export default SelectedTagComponent;
