import { Empty as BaseEmpty } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type EmptyProps = {
  image?: ReactNode;
  description?: string;
};

const Empty = ({
  image = BaseEmpty.PRESENTED_IMAGE_SIMPLE,
  description,
}: EmptyProps) => {
  const { t } = useTranslation();
  return <BaseEmpty image={image} description={description ?? t('noData')} />;
};

export default Empty;
