import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultOptionType } from 'antd/es/select';
import { useDispatch, useSelector } from 'react-redux';

import { cmdbSetup, setCmdbSetupConnection } from 'redux/cmdbSetupSlice';
import Button from 'components/Button';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import { BUTTON_TYPE } from 'constants/appearance';
import { CMDBFieldTagsMappingType } from 'pages/CmdbSetupListPage/types';
import { REQUEST_STATUS } from 'constants/requestBody';
import { validateEmptyField } from 'utils/validations';
import FieldValidationMessage from 'components/FieldValidationMessage';
import { ConnectionListType } from 'types/dashboard';
import DashboardComponent from 'components/DashboardComponent';

import { CMDB_FIELD_TAGS_DEFAULT_VALUE } from './constants';
import ConnectionTagMappings from '../ConnectionTagMappings';

type CmdbFieldTagsMappingDrawerProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  selectedCmdbTagMap?: CMDBFieldTagsMappingType;
  cmdbFieldOptions: DefaultOptionType[];
  cmdbFieldOptionsReqStatus: string;
  connections: ConnectionListType[];
  connectionsReqStatus: string;
};

const CmdbFieldTagsMappingDrawer = ({
  show,
  setShow,
  selectedCmdbTagMap,
  cmdbFieldOptions,
  cmdbFieldOptionsReqStatus,
  connections,
  connectionsReqStatus,
}: CmdbFieldTagsMappingDrawerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cmdbSetupConnection } = useSelector(cmdbSetup);

  const [cmdbFieldTagsMap, setCmdbFieldTagsMap] =
    useState<CMDBFieldTagsMappingType>(CMDB_FIELD_TAGS_DEFAULT_VALUE);
  const [cmdbFieldValidation, setCmdbFieldValidation] = useState('');

  useEffect(() => {
    if (selectedCmdbTagMap) {
      setCmdbFieldTagsMap(selectedCmdbTagMap);
    }
  }, [selectedCmdbTagMap]);

  /**
   * @function onClickAddOrUpdate
   * @description Callback function for save or update the added field and tag maps
   */
  const onClickAddOrUpdate = () => {
    const fieldTagsMappings = cmdbSetupConnection!.fieldTagsMappings.filter(
      (cmdbMap) => cmdbMap.cmdbField !== cmdbFieldTagsMap.cmdbField
    );

    if (cmdbFieldTagsMap.cloudTags.length > 0) {
      fieldTagsMappings.push(cmdbFieldTagsMap);
    }

    dispatch(
      setCmdbSetupConnection({
        ...cmdbSetupConnection!,
        fieldTagsMappings: fieldTagsMappings,
      })
    );

    setShow(false);
  };

  return (
    <DrawerComponent
      className="cmdb-field-tags-mapping-drawer"
      open={show}
      title={t('cmdbFieldMapping')}
      onClose={() => setShow(false)}
      footer={
        <div className="flex flex-gap-8 flex-end">
          <Button
            title={t('cancel')}
            type={BUTTON_TYPE.LINK}
            onClick={() => setShow(false)}
          />
          <Button
            title={selectedCmdbTagMap ? t('update') : t('save')}
            onClick={onClickAddOrUpdate}
            disabled={
              !cmdbFieldTagsMap.cmdbField ||
              cmdbFieldTagsMap.cloudTags.every(
                (cloudTag) => cloudTag.tagKeys.length === 0
              )
            }
          />
        </div>
      }
      dataTestId="cmdb-field-map-drawer"
    >
      <section className="flex flex-column flex-gap-24">
        <div className="flex flex-column">
          <FormLabel
            title={t('cmdbField')}
            required
            disabled={!!selectedCmdbTagMap} // Disable if on edit flow
          />
          <SelectDropdown
            placeholder={t('chooseCmdbField')}
            value={cmdbFieldTagsMap.cmdbField}
            options={cmdbFieldOptions}
            showSearch
            loading={cmdbFieldOptionsReqStatus === REQUEST_STATUS.PROCESSING}
            onSelect={(value: string) => {
              setCmdbFieldTagsMap({ ...cmdbFieldTagsMap, cmdbField: value });
              setCmdbFieldValidation('');
            }}
            onBlur={() =>
              validateEmptyField(
                cmdbFieldTagsMap.cmdbField,
                t('cmdbField'),
                setCmdbFieldValidation
              )
            }
            designVersion2
            disabled={!!selectedCmdbTagMap} // Disable if on edit flow
          />
          <FieldValidationMessage message={cmdbFieldValidation} />
        </div>
        <DashboardComponent
          component={
            <ConnectionTagMappings
              connections={connections}
              cmdbFieldTagsMap={cmdbFieldTagsMap}
              setCmdbFieldTagsMap={setCmdbFieldTagsMap}
            />
          }
          requestStatus={connectionsReqStatus}
          errorMessage={t('errorFetchingConnections')}
        />
      </section>
    </DrawerComponent>
  );
};

export default CmdbFieldTagsMappingDrawer;
