import {
  CostSavingFilterType,
  RecommendationFiltersType,
} from 'pages/CostOptimizationInsightsPage/types';
import { ValueLabelType } from 'types/dashboard';

import CostSavingsFilter from './components/CostSavingsFilter';

type CostSavingsInputProps = {
  filter: RecommendationFiltersType;
  onChangeFilterValues: (
    filter: RecommendationFiltersType,
    values: ValueLabelType[] | null
  ) => void;
};

const CostSavingsInput = ({
  filter,
  onChangeFilterValues,
}: CostSavingsInputProps) => {
  /**
   * @function onChangeCostSavingsFilters
   * @description Formats the cost savings filter and calls the filter values change callback function
   * @param filterValues The filter values to be updated with.
   */
  const onChangeCostSavingsFilters = (filterValues: CostSavingFilterType[]) => {
    const commaSeparatedValues = filterValues.map((filterValue) => {
      if (
        filterValue.comparator &&
        filterValue.value &&
        filterValue.conjunctToNextFilter
      ) {
        return (
          filterValue.comparator +
          ',' +
          filterValue.value +
          ',' +
          filterValue.conjunctToNextFilter
        );
      }

      return '';
    });

    if (commaSeparatedValues.includes('')) {
      onChangeFilterValues(filter, []);
    } else {
      onChangeFilterValues(
        filter,
        commaSeparatedValues.map((item) => ({ value: item }))
      );
    }
  };

  return <CostSavingsFilter onChangeFilters={onChangeCostSavingsFilters} />;
};

export default CostSavingsInput;
