import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, message } from 'antd';
import { useSelector } from 'react-redux';

import { selectTagCompliance } from 'redux/tagComplianceSlice';
import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import Button from 'components/Button';
import {
  LabelValueType,
  UntaggedResourceType,
} from 'pages/TagCompliancePage/types';
import { BUTTON_TYPE, INPUT_SIZE } from 'constants/appearance';
import { REQUEST_STATUS } from 'constants/requestBody';
import { KeyValueTypes } from 'types/dataTypes';
import { applyResourceTags } from 'pages/TagCompliancePage/services';
import { onApiCallError } from 'utils/handleErrors';
import { getValidationStyle } from 'utils/validations';
import { PROVIDER } from 'constants/cloudProviders';

import './index.scss';

type AddResourceTagValuesDrawerProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  record: UntaggedResourceType | undefined;
  setSelectedRecord: (value: UntaggedResourceType | undefined) => void;
  onClickAddTags: Function;
  allTags: LabelValueType[];
};

const AddResourceTagValuesDrawer = ({
  show,
  setShow,
  record,
  setSelectedRecord,
  onClickAddTags,
  allTags,
}: AddResourceTagValuesDrawerProps) => {
  const { t } = useTranslation();
  const { selectedConnection } = useSelector(selectTagCompliance);

  const [keyValues, setKeyValues] = useState<KeyValueTypes[]>([]);
  const [addTagKeysReqStatus, setAddTagKeysReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    setKeyValues(
      (record?.untaggedTags ?? []).map((tagKey) => ({ key: tagKey, value: '' }))
    );
  }, [record?.untaggedTags]);

  /**
   * @function getTagValuesMap
   * @description Function to return the map of tag values from list of tag values
   * @return Map containing the key value pairs
   */
  const getTagValuesMap = () => {
    const tagsMap: { [key: string]: string | number } = {};
    keyValues
      .filter((keyValue) => keyValue.value)
      .forEach((keyValue) => (tagsMap[keyValue.key] = keyValue.value));

    return tagsMap;
  };

  /**
   * @function onClickAddTagKeys
   * @description Callback function to add the missing tags
   */
  const onClickAddTagKeys = () => {
    if (!keyValues.some((keyValue) => keyValue.value)) {
      setValidationMessage(t('tagCompliance.oneValueIsRequired'));
      return;
    }

    setAddTagKeysReqStatus(REQUEST_STATUS.PROCESSING);

    let requestBody: any = {
      tags: getTagValuesMap(),
      resourceId: record!.resource,
      connectionId: selectedConnection!.connectorId,
    };

    if (selectedConnection!.provider === PROVIDER.AWS) {
      requestBody = {
        ...requestBody,
        service: record!.service ?? '',
        region: record!.regionCode
          ? record!.regionCode
          : selectedConnection!.awsRegion,
        operation: record!.operation ?? '',
      };
    }

    if (selectedConnection!.provider === PROVIDER.GCP) {
      requestBody = {
        ...requestBody,
        resourceId: record!.resourceGlobalName,
        service: record!.service,
        imageName: record!.resource,
        projectName: record!.projectName,
      };
    }

    applyResourceTags(requestBody)
      .then((res: any) => {
        if (res.status === 200) {
          setAddTagKeysReqStatus(REQUEST_STATUS.SUCCESS);
          message.success(t('tagCompliance.applyTagsSuccessfulMessage'));
          onClickAddTags();
          setShow(false);
          return;
        }

        setValidationMessage(t('tagCompliance.applyTagsFailureMessage'));
        setAddTagKeysReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setAddTagKeysReqStatus);
        setValidationMessage(t('tagCompliance.applyTagsFailureMessage'));
      });
  };

  /**
   * @function onHandleChangeTagValue
   * @description Callback function to update the tag value
   * @param value value to be updated
   * @param key Key for which the value is updated
   * @param index index of the corresponding key in the tags list
   */
  const onHandleChangeTagValue = (
    value: string,
    key: string,
    index: number
  ) => {
    setValidationMessage('');
    const keyValuesList = [...keyValues];
    keyValuesList.splice(index, 1, { key, value });
    setKeyValues(keyValuesList);
  };

  return (
    <DrawerComponent
      className="add-resource-tag-values-drawer"
      open={show}
      title={
        <div>
          <div>{t('tagCompliance.addTags')}</div>
          <div className="resource-name font-small-bold">
            {record?.resource}
          </div>
        </div>
      }
      onClose={() => setShow(false)}
      footer={
        <div>
          <div
            style={{
              display: getValidationStyle(validationMessage),
            }}
            className="error font-validation-error"
          >
            {validationMessage}
          </div>
          <div className="flex flex-gap-8 flex-end">
            <Button
              title={t('tagCompliance.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShow(false)}
            />
            <Button
              title={t('tagCompliance.add')}
              onClick={onClickAddTagKeys}
              loading={addTagKeysReqStatus === REQUEST_STATUS.PROCESSING}
            />
          </div>
        </div>
      }
      afterOpenChange={(open: boolean) => {
        if (!open) {
          setKeyValues([]);
          setSelectedRecord(undefined);
          setValidationMessage('');
        }
      }}
      dataTestId="add-resource-tag-value-drawer"
    >
      <section className="flex flex-column flex-gap-24">
        {keyValues.map((keyValue, index) => (
          <Row gutter={16} className="tag-row" key={keyValue.key}>
            <Col span={12} className="flex flex-column">
              <FormLabel title={t('tagCompliance.key')} />
              <Input
                rootClassName="tag-key"
                value={
                  allTags.find((item) => item.value === keyValue.key)?.label ??
                  keyValue.key
                }
                size={INPUT_SIZE.SMALL}
                disabled={true}
              />
            </Col>
            <Col span={12} className="flex flex-column">
              <FormLabel title={t('tagCompliance.value')} />
              <Input
                placeholder={t('tagCompliance.enterValue')}
                value={keyValue.value}
                size={INPUT_SIZE.SMALL}
                onChange={(e: any) =>
                  onHandleChangeTagValue(e.target.value, keyValue.key, index)
                }
              />
            </Col>
          </Row>
        ))}
      </section>
    </DrawerComponent>
  );
};

export default AddResourceTagValuesDrawer;
