import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTicketIntegrationComponent,
  setTicketIntegrationData,
  ticketIntegration,
} from 'redux/ticketIntegrationSlice';
import { setErrorMessage } from 'redux/successAndErrorPageSlice';
import { LeftBackArrowIcon } from 'assets/icons';
import Button from 'components/Button';
import { TicketIntegrationComponents } from 'pages/TicketIntegrationPage/constants';
import { getTicketConnection } from 'utils/services';
import { createTicketIntegration } from 'pages/TicketIntegrationPage/services';
import { defaultFormData } from 'pages/TicketIntegrationPage/types';
import CollapsePanelIcon from 'components/CollapsePanelIcon';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import {
  addZeroMarginClass,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';
import { onApiCallError } from 'utils/handleErrors';

import CredentialDetails from '../CredentialDetails';
import DefaultsDetails from '../DefaultsDetails';

import './index.scss';

const { Panel } = Collapse;

const TicketIntegrationForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ticketIntegrationData } = useSelector(ticketIntegration);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isValidCredentials, setIsValidCredentials] = useState(false);
  const [credsValidationMessage, setCredsValidationMessage] = useState('');

  useEffect(() => {
    fetchServiceNowConnection();
    addZeroMarginClass();

    return () => {
      removeZeroMarginClass();
    };
  }, []);

  const fetchServiceNowConnection = () => {
    getTicketConnection()
      .then((res: any) => {
        if (res?.status === 200) {
          setIsValidCredentials(true);
          const data = res.data.responseData;
          dispatch(
            setTicketIntegrationData({
              url: data.endpoint,
              usernameHost: data.username,
              password: data.password,
              setDefault: data.default,
              defaultAssignmentGroup: data.defaultAssigneeGroup,
              defaultAssignedTo: data.defaultAssignTo,
              category: data.defaultCategory,
            })
          );
          return;
        }
        dispatch(setTicketIntegrationData(defaultFormData));
      })
      .catch((e) => {
        onApiCallError(e);
        dispatch(setTicketIntegrationData(defaultFormData));
      });
  };

  /**
   * @function validateCredentialDetailsFields
   * @description Function to validate basic details fields
   * @return boolean true if validation is success else false
   */
  const validateCredentialDetailsFields = () => {
    if (
      ticketIntegrationData.url &&
      ticketIntegrationData.usernameHost &&
      ticketIntegrationData.password
    ) {
      return true;
    }
    return false;
  };

  /**
   * @function validateDefaultsFields
   * @description Function to validate schema fields
   * @return boolean true if validation is success else false
   */
  const validateDefaultsFields = () => {
    if (
      ticketIntegrationData.defaultAssignmentGroup &&
      ticketIntegrationData.defaultAssignedTo &&
      ticketIntegrationData.category
    ) {
      return true;
    }
    return false;
  };

  /**
   * @function saveOrTestIntegration
   * @description Function to save or test the entered data
   * @param isTest boolean value to indicated the test or save
   */
  const saveOrTestIntegration = (isTest: boolean) => {
    if (!isTest) {
      setLoadingSubmit(true);
    }
    const step1Params = {
      username: ticketIntegrationData.usernameHost,
      password: ticketIntegrationData.password,
      endpoint: ticketIntegrationData.url,
      default: ticketIntegrationData.setDefault,
      testConnection: isTest,
    };

    const step2Params = {
      defaultAssigneeGroup: ticketIntegrationData.defaultAssignmentGroup,
      defaultAssignTo: ticketIntegrationData.defaultAssignedTo,
      defaultCategory: ticketIntegrationData.category,
    };

    const params = isTest
      ? { ...step1Params }
      : { ...step1Params, ...step2Params };

    createTicketIntegration(params)
      .then((res: any) => {
        if (res?.status === 200) {
          if (isTest) {
            setIsValidCredentials(true);
            setCredsValidationMessage('');
            return;
          }
          dispatch(
            setTicketIntegrationComponent(
              TicketIntegrationComponents.SUCCESS_PAGE
            )
          );
          setLoadingSubmit(false);
          return;
        }

        if (isTest) {
          setIsValidCredentials(false);
          setCredsValidationMessage(
            t('ticketIntegrationLabels.formLabels.step1.credsValidationMessage')
          );
          return;
        }
        dispatch(
          setErrorMessage(
            res?.data?.message ||
              t(
                'ticketIntegrationLabels.errorPageLabels.errorTicketIntegration'
              )
          )
        );
        dispatch(
          setTicketIntegrationComponent(TicketIntegrationComponents.ERROR_PAGE)
        );
        setLoadingSubmit(false);
      })
      .catch((e) => {
        onApiCallError(e);
        if (isTest) {
          setIsValidCredentials(false);
          setCredsValidationMessage(
            t('ticketIntegrationLabels.formLabels.step1.credsValidationMessage')
          );
          return;
        }
        dispatch(
          setErrorMessage(
            e.response.data.message ||
              t(
                'ticketIntegrationLabels.errorPageLabels.errorTicketIntegration'
              )
          )
        );
        dispatch(
          setTicketIntegrationComponent(TicketIntegrationComponents.ERROR_PAGE)
        );
        setLoadingSubmit(false);
      });
  };

  const onClickBackArrow = () => {
    dispatch(
      setTicketIntegrationComponent(
        TicketIntegrationComponents.TICKET_PROVIDER_LIST
      )
    );
  };

  /**
   * @function getExpandIcon
   * @description Function to return the expand Icon based on the active panel
   * @param props props for panel expand icon
   * @return Returns JSX element
   */
  const getExpandIcon = (props: any) => {
    return props.isActive ? (
      <Icon iconName={ICONS.SUBTRACT_LINE} />
    ) : (
      <Icon iconName={ICONS.ADD_LINE} />
    );
  };

  return (
    <div className="ticket-integration-container flex flex-column flex-fit">
      <div className="ticket-integration-header flex flex-space-between">
        <div className="flex flex-align-items-center flex-gap-8">
          <LeftBackArrowIcon
            className="back-arrow cursor-pointer"
            onClick={onClickBackArrow}
          />
          <div className="flex flex-column">
            <div className="modal-heading">
              {t('ticketIntegrationLabels.formLabels.heading')}
            </div>
            <div className="table-typography">
              {t('ticketIntegrationLabels.formLabels.subHeading')}
            </div>
          </div>
        </div>
        <Button
          title={t('ticketIntegrationLabels.formLabels.save')}
          disabled={
            !validateCredentialDetailsFields() ||
            !isValidCredentials ||
            !validateDefaultsFields()
          }
          onClick={() => saveOrTestIntegration(false)}
          loading={loadingSubmit}
        />
      </div>
      <div className="form-section flex flex-column flex-align-items-center">
        <Collapse
          className="collapse-container flex flex-column"
          expandIconPosition="right"
          expandIcon={getExpandIcon}
          accordion
          defaultActiveKey="credential-details"
        >
          <Panel
            className="panel"
            header={
              <div className="panel-heading flex flex-align-items-center">
                <CollapsePanelIcon
                  isValidationSuccess={
                    validateCredentialDetailsFields() && isValidCredentials
                  }
                />
                {t('ticketIntegrationLabels.formLabels.step1.heading')}
              </div>
            }
            key="credential-details"
          >
            <CredentialDetails
              saveOrTestIntegration={saveOrTestIntegration}
              setIsValidCredentials={setIsValidCredentials}
              credsValidationMessage={credsValidationMessage}
              setCredsValidationMessage={setCredsValidationMessage}
            />
          </Panel>
          <Panel
            className="panel"
            header={
              <div className="panel-heading flex flex-align-items-center">
                <CollapsePanelIcon
                  isValidationSuccess={validateDefaultsFields()}
                />
                {t('ticketIntegrationLabels.formLabels.step2.heading')}
              </div>
            }
            key="defaults"
            {...(!(validateCredentialDetailsFields() && isValidCredentials) && {
              collapsible: 'disabled',
            })}
          >
            <DefaultsDetails isValidCredentials={isValidCredentials} />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default TicketIntegrationForm;
