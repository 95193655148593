import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MY_DASHBOARD_TYPES } from 'constants/dashboard';
import { DASHBOARD } from 'pages/OverviewPage/components/ConnectionsDashboard/components/HorizontalNavigation/constants';
import { ConnectionListType, TagsFilterType, TagsType } from 'types/dashboard';
import { DashboardListType, DashboardViewType } from 'types/navigationMenu';
import { GroupType } from 'pages/GroupsPage/types';
import { SnowflakeIntegrationType } from 'pages/IntegrationsPage/types';
import { PDF_ALL_GRAPHS } from './../constants/pdfConstants';
import { RootState } from './store';

export interface DashboardState {
  connectionList: ConnectionListType[];
  selectedConnection: ConnectionListType | null;
  expandedGraphName: string;
  showExpandGraphModal: boolean;
  selectedDashboardView: string;
  exportToExcelData: any;
  tableViewEnabled: boolean;
  dashboardList: DashboardListType[];
  selectedDashboard: DashboardListType | null;
  dashboardViewsList: DashboardViewType[];
  selectedMyDashboardType: string;
  selectedGroupMetaData: GroupType | null;
  pdfDownloadMode: boolean;
  pptDownloadMode: boolean;
  pdfGraphToDownload: string;
  allAvailableTags: TagsType[];
  tagsFilters: TagsFilterType[];
  isEmptyDashboardList: boolean;
  dashboardUsers: any[];
  selectedIntegrationConnection: SnowflakeIntegrationType | null;
}

const initialState: DashboardState = {
  connectionList: [],
  selectedConnection: null,
  expandedGraphName: '',
  showExpandGraphModal: false,
  selectedDashboardView: DASHBOARD.COST_SUMMARY,
  exportToExcelData: null,
  tableViewEnabled: false,
  dashboardList: [],
  selectedDashboard: null,
  dashboardViewsList: [],
  selectedMyDashboardType: MY_DASHBOARD_TYPES.SINGLE_CONNECTION,
  selectedGroupMetaData: null,
  pdfDownloadMode: false,
  pptDownloadMode: false,
  pdfGraphToDownload: PDF_ALL_GRAPHS,
  allAvailableTags: [],
  tagsFilters: [],
  isEmptyDashboardList: false,
  dashboardUsers: [],
  selectedIntegrationConnection: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    setConnectionList: (state, action: PayloadAction<ConnectionListType[]>) => {
      state.connectionList = [...action.payload];
    },
    setSelectedConnection: (
      state,
      action: PayloadAction<ConnectionListType | null>
    ) => {
      state.selectedConnection = action.payload;
    },
    setExpandedGraphName: (state, action: PayloadAction<string>) => {
      state.expandedGraphName = action.payload;
    },
    setShowExpandGraphModal: (state, action: PayloadAction<boolean>) => {
      state.showExpandGraphModal = action.payload;
    },
    setSelectedDashboardView: (state, action: PayloadAction<string>) => {
      state.selectedDashboardView = action.payload;
    },
    setExportToExcelData: (state, action: PayloadAction<any>) => {
      state.exportToExcelData = action.payload;
    },
    setTableViewEnabled: (state, action: PayloadAction<boolean>) => {
      state.tableViewEnabled = action.payload;
    },
    setDashboardList: (state, action: PayloadAction<DashboardListType[]>) => {
      state.dashboardList = action.payload;
    },
    setSelectedDashboard: (
      state,
      action: PayloadAction<DashboardListType | null>
    ) => {
      state.selectedDashboard = action.payload;
    },
    setDashboardViewsList: (
      state,
      action: PayloadAction<DashboardViewType[]>
    ) => {
      state.dashboardViewsList = action.payload;
    },
    setSelectedMyDashboardType: (state, action: PayloadAction<string>) => {
      state.selectedMyDashboardType = action.payload;
    },
    setSelectedGroupMetaData: (
      state,
      action: PayloadAction<GroupType | null>
    ) => {
      state.selectedGroupMetaData = action.payload;
    },
    setPdfDownloadMode: (state, action: PayloadAction<boolean>) => {
      state.pdfDownloadMode = action.payload;
    },
    setPptDownloadMode: (state, action: PayloadAction<boolean>) => {
      state.pptDownloadMode = action.payload;
    },
    setPdfGraphToDownload: (state, action: PayloadAction<string>) => {
      state.pdfGraphToDownload = action.payload;
    },
    setAllAvailableTags: (state, action: PayloadAction<TagsType[]>) => {
      state.allAvailableTags = action.payload;
    },
    setTagsFilters: (state, action: PayloadAction<TagsFilterType[]>) => {
      state.tagsFilters = action.payload;
    },
    setIsEmptyDashboardList: (state, action: PayloadAction<boolean>) => {
      state.isEmptyDashboardList = action.payload;
    },
    setDashboardUsers: (state, action: PayloadAction<any[]>) => {
      state.dashboardUsers = action.payload;
    },
    setSelectedIntegrationConnection: (
      state,
      action: PayloadAction<SnowflakeIntegrationType | null>
    ) => {
      state.selectedIntegrationConnection = action.payload;
    },
  },
});

export const selectDashboard = (state: RootState) => state.dashboard;
export const {
  setConnectionList,
  setSelectedConnection,
  setExpandedGraphName,
  setShowExpandGraphModal,
  setSelectedDashboardView,
  setExportToExcelData,
  setTableViewEnabled,
  setDashboardList,
  setSelectedDashboard,
  setDashboardViewsList,
  setSelectedMyDashboardType,
  setSelectedGroupMetaData,
  setPdfDownloadMode,
  setPptDownloadMode,
  setPdfGraphToDownload,
  setAllAvailableTags,
  setTagsFilters,
  setIsEmptyDashboardList,
  setDashboardUsers,
  setSelectedIntegrationConnection,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
