import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface SuccessAndErrorPageState {
  errorMessage: string;
}

const initialState: SuccessAndErrorPageState = {
  errorMessage: '',
};

export const successAndErrorPageSlice = createSlice({
  name: 'successAndErrorPage',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});

export const successAndErrorPage = (state: RootState) =>
  state.successAndErrorPage;
export const { setErrorMessage } = successAndErrorPageSlice.actions;
export default successAndErrorPageSlice.reducer;
