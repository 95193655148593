import i18n from 'i18n';

export const BUDGET_TYPES = [
  {
    value: 'FIXED',
    label: i18n.t('addBudgetAlert.createBudgetLabels.amount.fixed'),
  },
];

export const AWS_TRIGGER_AT = [
  {
    value: 'ACTUAL',
    label: i18n.t('addBudgetAlert.createBudgetLabels.actions.actualCost'),
  },
  {
    value: 'FORECASTED',
    label: i18n.t('addBudgetAlert.createBudgetLabels.actions.forecastSpend'),
  },
];

export const YEARS_LIMIT = 50;

export const TIME_RANGES = [
  {
    value: 'MONTHLY',
    label: i18n.t('addBudgetAlert.createBudgetLabels.scope.monthly'),
  },
  {
    value: 'QUARTERLY',
    label: i18n.t('addBudgetAlert.createBudgetLabels.scope.quarterly'),
  },
  {
    value: 'ANNUALLY',
    label: i18n.t('addBudgetAlert.createBudgetLabels.scope.yearly'),
  },
];

export enum BUDGET_RENEWAL_TYPE_VALUES {
  RECURRING = 'RECURRING',
}

export const BUDGET_RENEWAL_TYPES = [
  {
    value: BUDGET_RENEWAL_TYPE_VALUES.RECURRING,
    label: i18n.t('addBudgetAlert.createBudgetLabels.scope.recurring'),
  },
];
