import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import { selectTheme } from 'redux/themeSlice';
import TreeMapComponent from 'components/TreeMapComponent';
import GraphHeader from 'components/GraphHeader';
import ControlComponent from 'components/DashboardControl';
import DropdownCheckbox from 'components/DropdownCheckbox';
import DatePicker from 'components/DatePicker';
import DashboardComponent from 'components/DashboardComponent';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import {
  selectGProfilerInsights,
  setSelectedService,
} from 'redux/gProfilerInsights';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray, onApiCallError } from 'utils/handleErrors';
import {
  STRING_TIMESTAMP,
  TIMESTAMP_FORMAT_PERSIST_Z,
  TIMESTAMP_FORMAT_WITHOUT_ZONE,
  TIMESTAMP_HH_MM_SS_PERSIST_Z,
} from 'utils/date';
import RadioGroup from 'components/RadioGroup';
import ExpandModal from 'components/ExpandModal';
import NavigationPath from 'components/NavigationPath';
import AccessibleDiv from 'components/AccessibleDiv';
import { INPUT_SIZE } from 'constants/appearance';
import {
  addZeroMarginClass,
  generateGraphColors,
  removeZeroMarginClass,
} from 'utils/dashboardUtils';

import ProfileBar from './components/ProfileBar';
import InsightOptimization from './components/InsightOptimization';
import InsightTable from './components/InsightTable';
import InsightBar from './components/InsightBar';
import GprofilerInsightsHeader from './components/GProfilerInsightsHeader';
import {
  getGprofilerCpuTrend,
  getGprofilerDateTimeWithData,
  getGprofilerInsights,
  getGprofilerMetadata,
  getGprofilerMetrics,
  getGprofilerProfileData,
  getGprofilerOptimizationData,
} from './services';
import {
  DateRangeOptions,
  DateRanges,
  DASHBOARD_ITEMS,
  DashboardItems,
} from './constants';
import { getInsightDashboardExcelData, getImpactCategory } from './utils';

import './index.scss';

const GProfilerInsightPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedService, gProfilerServicesReqStatus } = useSelector(
    selectGProfilerInsights
  );
  const { theme } = useSelector(selectTheme);
  const initialZoom = { x: [0, 1], y: [0, 1] };
  let dataId = 0;

  const [treemapData, setTreemapData] = useState<any[]>([]);
  const [rawData, setRawData] = useState<any>({});
  const [cpuTrendData, setCpuTrendData] = useState<{ [key: string]: number }>();
  const [metaData, setMetaData] = useState<{ [key: string]: any }>();
  const [metricsData, setMetricsData] = useState<{ [key: string]: number }>();
  const [insights, setInsights] = useState<any[]>([]);
  const [snapshotDateRange, setSnapshotDateRange] = useState<{
    [key: string]: string;
  }>();
  const [dateRange, setDateRange] = useState<{
    [key: string]: string;
  }>();
  const [selectedProcessNames, setSelectedProcessNames] = useState<string[]>(
    []
  );
  const [treemapDataReqStatus, setTreemapDataReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [cpuTrendDataReqStatus, setCpuTrendDataReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [metaDataReqStatus, setMetaDataReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [insightsReqStatus, setInsightsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [metricsReqStatus, setMetricsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [snapshotDateRangeReqStatus, setSnapshotDateRangeReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [zoom, setZoom] = useState({});
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    DateRanges.LAST_SNAPSHOT
  );
  const [isTableView, setIsTableView] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<any[]>([]);
  const [showExpandedGraphModal, setShowExpandedGraphModal] = useState(false);
  const [languageColorMap, setLanguageColorMap] = useState<any[]>([]);
  const [parentNode, setParentNode] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<string>(
    DASHBOARD_ITEMS.DASHBOARD
  );
  const [impact, setImpact] = useState<string | null>('');

  useEffect(() => {
    addZeroMarginClass();

    return () => {
      dispatch(setSelectedService(undefined));
      removeZeroMarginClass();
    };
  }, []);

  useEffect(() => {
    setSnapshotDateRange(undefined);
    setDateRange(undefined);
    setInsights([]);
    if (selectedService) {
      fetchSnapshotDateTime();
      fetchInsights();
      selectedService?.serviceName &&
        getGprofilerOptimizationData(selectedService.serviceName)
          .then((res: any) => {
            if (res.status === 200) {
              setImpact(getImpactCategory(res.data));
            }
          })
          .catch((e) => {
            onApiCallError(e, false);
          });
    }
  }, [selectedService]);

  useEffect(() => {
    setTreemapData([]);
    setRawData({});
    setMetaData(undefined);
    setCpuTrendData(undefined);
    setSelectedProcessNames([]);
    setLanguageColorMap([]);
    if (dateRange) {
      fetchTreemapData();
      fetchCpuTrend();
      fetchCpuMetadata();
      fetchCpuMetrics();
    }
  }, [dateRange]);

  useEffect(() => {
    if (selectedProcessNames?.length > 0) {
      setTreemapData(formatData(filterDataByProcessNames()));
    } else {
      setTreemapData(formatData(rawData));
    }
  }, [selectedProcessNames]);

  const fetchSnapshotDateTime = () => {
    setSnapshotDateRangeReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      serviceName: selectedService?.serviceName,
    };

    getGprofilerDateTimeWithData(params)
      .then((res: any) => {
        const responseTimeRange = res?.data;
        setSnapshotDateRange({
          firstDateTime: moment
            .utc(responseTimeRange.firstDateTime)
            .local()
            .format(TIMESTAMP_HH_MM_SS_PERSIST_Z),
          lastDateTime: moment
            .utc(responseTimeRange.lastDateTime)
            .local()
            .format(TIMESTAMP_HH_MM_SS_PERSIST_Z),
        });
        setDateRange({
          firstDateTime: moment
            .utc(responseTimeRange.lastDateTime)
            .local()
            .subtract(1, 'day')
            .format(TIMESTAMP_HH_MM_SS_PERSIST_Z),
          lastDateTime: moment
            .utc(responseTimeRange.lastDateTime)
            .local()
            .format(TIMESTAMP_HH_MM_SS_PERSIST_Z),
        });
        setSnapshotDateRangeReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setSnapshotDateRangeReqStatus);
      });
  };

  const fetchTreemapData = () => {
    setTreemapDataReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      startTime: moment
        .utc(
          moment(dateRange?.firstDateTime, TIMESTAMP_FORMAT_PERSIST_Z),
          TIMESTAMP_FORMAT_PERSIST_Z
        )
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      endTime: moment
        .utc(
          moment(dateRange?.lastDateTime, TIMESTAMP_FORMAT_PERSIST_Z),
          TIMESTAMP_FORMAT_PERSIST_Z
        )
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      serviceName: selectedService?.serviceName,
      enrichment: ['lang', 'insights'],
    };

    getGprofilerProfileData(params)
      .then((res: any) => {
        dataId = 0;
        setTreemapData(formatData(res?.data));
        setRawData(res?.data);
        setTreemapDataReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setTreemapDataReqStatus);
        setTreemapData([]);
        setRawData({});
      });
  };

  const fetchCpuTrend = () => {
    setCpuTrendDataReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      startTime: moment
        .utc(
          moment(dateRange?.firstDateTime, TIMESTAMP_FORMAT_PERSIST_Z),
          TIMESTAMP_FORMAT_PERSIST_Z
        )
        .format(TIMESTAMP_FORMAT_PERSIST_Z),
      endTime: moment
        .utc(
          moment(dateRange?.lastDateTime, TIMESTAMP_FORMAT_PERSIST_Z),
          TIMESTAMP_FORMAT_PERSIST_Z
        )
        .format(TIMESTAMP_FORMAT_PERSIST_Z),
      serviceName: selectedService?.serviceName,
    };

    getGprofilerCpuTrend(params)
      .then((res: any) => {
        setCpuTrendData(res?.data);
        setCpuTrendDataReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setCpuTrendDataReqStatus);
      });
  };

  const fetchCpuMetadata = () => {
    setMetaDataReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      startTime: moment
        .utc(
          moment(dateRange?.firstDateTime, TIMESTAMP_FORMAT_PERSIST_Z),
          TIMESTAMP_FORMAT_PERSIST_Z
        )
        .format(TIMESTAMP_FORMAT_PERSIST_Z),
      endTime: moment
        .utc(
          moment(dateRange?.lastDateTime, TIMESTAMP_FORMAT_PERSIST_Z),
          TIMESTAMP_FORMAT_PERSIST_Z
        )
        .format(TIMESTAMP_FORMAT_PERSIST_Z),
      serviceName: selectedService?.serviceName,
    };

    getGprofilerMetadata(params)
      .then((res: any) => {
        setMetaData(res?.data);
        setMetaDataReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setMetaDataReqStatus);
      });
  };

  const fetchCpuMetrics = () => {
    setMetricsReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      startTime: moment
        .utc(
          moment(dateRange?.firstDateTime, TIMESTAMP_FORMAT_PERSIST_Z),
          TIMESTAMP_FORMAT_PERSIST_Z
        )
        .format(TIMESTAMP_FORMAT_PERSIST_Z),
      endTime: moment
        .utc(
          moment(dateRange?.lastDateTime, TIMESTAMP_FORMAT_PERSIST_Z),
          TIMESTAMP_FORMAT_PERSIST_Z
        )
        .format(TIMESTAMP_FORMAT_PERSIST_Z),
      serviceName: selectedService?.serviceName,
    };

    getGprofilerMetrics(params)
      .then((res: any) => {
        setMetricsData(res?.data);
        setMetricsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setMetricsReqStatus);
      });
  };

  const fetchInsights = () => {
    setInsightsReqStatus(REQUEST_STATUS.PROCESSING);

    const params = {
      serviceName: selectedService?.serviceName,
    };

    getGprofilerInsights(params)
      .then((res: any) => {
        setInsights(res?.data);
        setInsightsReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setInsightsReqStatus);
      });
  };

  const filterDataByProcessNames = () => {
    if (selectedProcessNames.length === 0) {
      return rawData;
    }

    return {
      ...rawData,
      children: rawData.children.filter((item: any) =>
        selectedProcessNames.includes(item.name)
      ),
    };
  };

  const formatData = (data: any) => {
    let parent = undefined;
    const dataArray = [];
    dataArray.push({
      id: parent,
      label: '',
      value: data.value,
      parent: '',
      color: '',
      language: '',
    });
    addChildrenToArray(parent, data.children, dataArray, 0, languageColorMap);

    return dataArray;
  };

  const addChildrenToArray = (
    parent: number | undefined,
    children: any[],
    dataArray: any[],
    depth: number,
    colorMap: any[]
  ) => {
    if (children?.length > 0) {
      children?.forEach((item) => {
        dataId += 1;
        const colorsList = getColorByLanguage(colorMap, item.language);
        dataArray.push({
          id: dataId,
          label: item.name,
          value: item.value,
          parent: parent,
          color: colorsList.find(
            (eachColor: any) => item.language === eachColor.language
          ).color,
          language: item.language,
        });
        if (depth < 4) {
          addChildrenToArray(
            dataId,
            item.children,
            dataArray,
            depth + 1,
            colorsList
          );
        }
      });
    }
  };

  const getColorByLanguage = (colorMap: any[], language: string = 'Other') => {
    const existingColor = colorMap.find((item) => item.language === language);

    if (existingColor !== undefined) {
      return colorMap;
    }

    const colors = generateGraphColors(colorMap.length + 1);
    const unUsedColor =
      colors.find((color) => !colorMap.some((item) => item.color === color)) ??
      '';
    colorMap.push({ language: language, color: unUsedColor });
    setLanguageColorMap(colorMap);
    return colorMap;
  };

  const fetchDrillDownData = (data: any, parent: string, name: string) => {
    if (
      data.name === parent &&
      data.children.some((item: any) => item.name === name)
    ) {
      setTreemapData(
        formatData({
          ...data,
          children: [data.children.find((item: any) => item.name === name)],
        })
      );
      return;
    }

    data.children.forEach((item: any) =>
      fetchDrillDownData(item, parent, name)
    );
  };

  const fetchDrillOutData = (
    data: any,
    parent: string,
    name: string,
    superParent: any
  ) => {
    if (
      data.name === parent &&
      data.children.some((item: any) => item.name === name)
    ) {
      setTreemapData(
        formatData({
          ...superParent,
          children: [data],
        })
      );
      setParentNode(superParent.name);
      return;
    }

    data.children.forEach((item: any) =>
      fetchDrillOutData(item, parent, name, data)
    );
  };

  const onClickTreemapCard = (event: any) => {
    if (event.points[0].entry === event.points[0].label && parentNode) {
      fetchDrillOutData(
        filterDataByProcessNames(),
        parentNode,
        event.points[0].label,
        rawData
      );
      return;
    }

    setParentNode(event.points[0].parent);
    fetchDrillDownData(
      filterDataByProcessNames(),
      event.points[0].parent,
      event.points[0].label
    );
  };

  const handleReset = () => {
    setZoom(initialZoom);
    setTreemapData(formatData(filterDataByProcessNames()));
  };

  const sortLegends = () => {
    const sortedItems = languageColorMap
      .filter((item) => item.language !== 'Other')
      .sort((item1, item2) => {
        if (item1.language < item2.language) {
          return -1;
        }

        if (item1.language > item2.language) {
          return 1;
        }

        return 0;
      });
    return [
      ...sortedItems,
      ...languageColorMap.filter((item) => item.language === 'Other'),
    ];
  };

  const getProcessNamesMinimizedText = () => {
    if (rawData?.children?.length === 0 || selectedProcessNames.length === 0) {
      return t('gprofilerInsightsDashboard.none');
    }

    if (selectedProcessNames.length === rawData?.children?.length) {
      return t('gprofilerInsightsDashboard.all');
    }

    if (selectedProcessNames.length === 1) {
      return selectedProcessNames[0];
    }

    return (
      selectedProcessNames[0] + '(+' + (selectedProcessNames.length - 1) + ')'
    );
  };

  const getMinimizedDateRangeText = () => {
    if (dateRange) {
      return (
        moment(dateRange?.firstDateTime, TIMESTAMP_FORMAT_PERSIST_Z).format(
          STRING_TIMESTAMP
        ) +
        ' to ' +
        moment(dateRange?.lastDateTime, TIMESTAMP_FORMAT_PERSIST_Z).format(
          STRING_TIMESTAMP
        )
      );
    }

    return t('gprofilerInsightsDashboard.none');
  };

  const ResetButton = (
    <AccessibleDiv
      className="flex flex-gap-4 flex-align-items-center cursor-pointer"
      onClick={handleReset}
    >
      <Icon
        iconName={ICONS.REFRESH_LINE}
        size={ICONS_SIZE.ONE_X}
        color={theme.buttonIconColor}
      />
      <span className="table-typography">{t('reset')}</span>
    </AccessibleDiv>
  );

  const generateHoverTemplate = (
    label: string,
    totalTime: string,
    ownTime: string,
    value: number
  ) => {
    return (
      `<b>${label}</b><br><br>` +
      `Total Time: &nbsp;${totalTime}%<br>` +
      `Own Time: &nbsp;${ownTime}%<br>` +
      `Samples: &nbsp;${value}` +
      `<extra></extra>`
    );
  };

  const graphHeader = (
    <GraphHeader
      heading={t('gProfilerConnectionPage.insightDashboard.allItems')}
      headingComponent={
        <div className="font-caption-bold">
          {t('gProfilerConnectionPage.insightDashboard.allItems')}
        </div>
      }
      graphName=""
      isDownloadable={true}
      setShowExpandedGraph={setShowExpandedGraphModal}
      isTableViewSwitch={true}
      isTableView={isTableView}
      setIsTableView={setIsTableView}
      showExpandIcon={true}
      designVersion2
      className="insight-dashboard-graph"
      excelData={getInsightDashboardExcelData(
        t('gprofilerInsightsDashboard.insightDashboard'),
        excelData,
        dateRange?.firstDateTime ?? '',
        dateRange?.lastDateTime ?? ''
      )}
      additionalComponents={{
        prefix: [ResetButton],
      }}
      alwaysExcelDownload={true}
    />
  );

  const getGraphContent = () => {
    if (!rawData?.children || rawData?.children?.length === 0) {
      return <>{t('gprofilerInsightsDashboard.noDataWithTimeFrame')}</>;
    }

    return isTableView ? (
      <InsightTable
        data={treemapData}
        totalSamples={rawData?.value}
        setExcelData={setExcelData}
        showExpandedGraphModal={showExpandedGraphModal}
      />
    ) : (
      <TreeMapComponent
        className="insights-graph"
        data={treemapData}
        tooltipContent={generateHoverTemplate}
        zoom={zoom}
        totalSamples={rawData?.value}
        onClick={onClickTreemapCard}
      />
    );
  };

  const getGraphHeaderContent = () => {
    return (
      <>
        {graphHeader}
        <DashboardComponent
          component={getGraphContent()}
          requestStatus={evaluateRequestArray([
            gProfilerServicesReqStatus,
            snapshotDateRangeReqStatus,
            treemapDataReqStatus,
          ])}
        />
        {languageColorMap.length > 0 && (
          <div className="language-legends flex flex-align-items-center flex-gap-16">
            {sortLegends().map((item) => (
              <div
                className="flex flex-align-items-center flex-gap-4"
                key={item.language}
              >
                <span
                  className="legend"
                  style={{ backgroundColor: item.color }}
                />
                <span>{item.language}</span>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  const getDateRangePanelRender = (panelNode: any) => (
    <div className="gprofiler-insights-date-range-panel">
      <RadioGroup
        value={selectedDateRangeOption}
        options={DateRangeOptions}
        rootClassName="date-range-options"
        onChange={(e: any) => {
          setSelectedDateRangeOption(e.target.value);
          if (e.target.value === DateRanges.LAST_SNAPSHOT.valueOf()) {
            setDateRange({
              firstDateTime: moment(
                snapshotDateRange?.lastDateTime,
                TIMESTAMP_HH_MM_SS_PERSIST_Z
              )
                .subtract(1, 'day')
                .format(TIMESTAMP_HH_MM_SS_PERSIST_Z),
              lastDateTime: snapshotDateRange?.lastDateTime ?? '',
            });
          }
        }}
      />
      {selectedDateRangeOption === DateRanges.CUSTOM_RANGE && panelNode}
    </div>
  );

  return (
    <div>
      <GprofilerInsightsHeader />
      <div className="page-content">
        <NavigationPath />
        <InsightBar
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          dashboardItems={DashboardItems}
        />
        {selectedItem === DASHBOARD_ITEMS.DASHBOARD ? (
          <>
            <ControlComponent
              filters={[
                {
                  title: t(
                    'gProfilerConnectionPage.insightDashboard.processNames'
                  ),
                  filter: (
                    <DropdownCheckbox
                      itemOptions={rawData?.children?.map((item: any) => ({
                        title: item.name,
                        value: item.name,
                      }))}
                      selectedItems={selectedProcessNames}
                      setSelectedItems={setSelectedProcessNames}
                      designVersion2
                      size={INPUT_SIZE.SMALL}
                      showSearch
                    />
                  ),
                  minimizedText: getProcessNamesMinimizedText(),
                  onClear: () => {
                    setSelectedProcessNames([]);
                  },
                },
                {
                  title: t(
                    'gProfilerConnectionPage.insightDashboard.timeRange'
                  ),
                  filter: (
                    <DatePicker
                      designVersion2
                      showTime
                      defaultValue={[
                        dateRange?.firstDateTime
                          ? moment(
                              dateRange?.firstDateTime,
                              TIMESTAMP_FORMAT_PERSIST_Z
                            )
                          : undefined,
                        dateRange?.firstDateTime
                          ? moment(
                              dateRange?.lastDateTime,
                              TIMESTAMP_FORMAT_PERSIST_Z
                            )
                          : undefined,
                      ]}
                      onChange={(date: Moment[]) => {
                        setDateRange({
                          firstDateTime: date[0].format(
                            TIMESTAMP_FORMAT_PERSIST_Z
                          ),
                          lastDateTime: date[1].format(
                            TIMESTAMP_FORMAT_PERSIST_Z
                          ),
                        });
                      }}
                      disabledDate={(current: Moment) =>
                        current.isAfter(moment(), 'date') ||
                        current.isBefore(
                          moment(selectedService?.createdDate),
                          'date'
                        )
                      }
                      panelRender={getDateRangePanelRender}
                    />
                  ),
                  minimizedText: getMinimizedDateRangeText(),
                  onClear: () => {
                    setDateRange({
                      firstDateTime: moment(
                        snapshotDateRange?.lastDateTime,
                        TIMESTAMP_HH_MM_SS_PERSIST_Z
                      )
                        .subtract(1, 'day')
                        .format(TIMESTAMP_HH_MM_SS_PERSIST_Z),
                      lastDateTime: snapshotDateRange?.lastDateTime ?? '',
                    });
                  },
                  width: 350,
                },
              ]}
            />
            <div className="gProfiler-insight-dashboard full-height">
              <ProfileBar
                data={rawData}
                cpuTrendData={cpuTrendData}
                metaData={metaData}
                metricsData={metricsData}
                loading={[
                  cpuTrendDataReqStatus,
                  metaDataReqStatus,
                  metricsReqStatus,
                  treemapDataReqStatus,
                  snapshotDateRangeReqStatus,
                ].includes(REQUEST_STATUS.PROCESSING)}
              />
              {getGraphHeaderContent()}
            </div>
          </>
        ) : (
          <InsightOptimization
            impact={impact}
            data={insights}
            loading={insightsReqStatus === REQUEST_STATUS.PROCESSING}
            setInsights={setInsights}
          />
        )}
        {showExpandedGraphModal && (
          <ExpandModal
            show={showExpandedGraphModal}
            onClose={() => setShowExpandedGraphModal(!showExpandedGraphModal)}
            graphContent={getGraphHeaderContent()}
          />
        )}
      </div>
    </div>
  );
};

export default GProfilerInsightPage;
