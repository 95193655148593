import i18n from 'i18n';
import moment from 'moment';

import { store } from 'redux/store';
import {
  MonthlyCostType,
  ColouredCostByServiceType,
  ColouredCostByResourceType,
  MonthlyCostByServiceType,
  CostByCompartmentDatasetType,
  MonthlyCostByCompartmentType,
  CostByServiceConsolidatedCostType,
  ColouredCostByRegionType,
} from 'types/dataTypes';
import { numberCommaSeparator } from 'utils/dataFormatterUtils';
import {
  generateGraphColors,
  getOCITagFiltersData,
  getPreviousMonthAndYear,
  getQueryFieldByDataSource,
  isDashboardWithStaticData,
} from 'utils/dashboardUtils';
import {
  formatDateFieldByProvider,
  getMonthYearShortList,
  HYPHEN_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  MONTH_YEAR_SHORT,
  YEAR_MONTH_WITHOUT_SEPARATOR,
} from 'utils/date';
import { PROVIDER } from 'constants/cloudProviders';
import {
  QUERY_FIELDS,
  AGGREGATORS,
  ORDER_BY,
  DASHBOARD_TYPES,
  COMPARATORS,
  CONJUNCTIONS,
  REQUEST_STATUS,
} from 'constants/requestBody';
import { DashboardMetaData } from 'types/dashboard';
import { getChartData } from 'utils/services';
import { onApiCallError } from 'utils/handleErrors';
import { calculateTotalCostSum } from 'utils/CostSum';
import { OTHERS_LABEL } from 'constants/graphLabels';

const commonUtilitySlice = store.getState().commonUtility;
const { currencySymbol } = commonUtilitySlice;

/**
 * @function getOCICostByPeriodData
 * @description Function to get and set the OCI cost based on the period provided
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param fromMonth start month from which the data needs to be fetched
 * @param toMonth end month to which the data needs to be fetched
 * @param setData Callback to set the data fetched
 * @param setRequestStatus Callback to set the request status
 */
export const getOCICostByPeriodData = (
  dashboardMetaData: DashboardMetaData,
  fromMonth: string,
  toMonth: string,
  setData: (val: number) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  const requestBody = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_MY_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              fromMonth,
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              toMonth,
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: [
      ...getOCITagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.dashBoardType,
        dashboardMetaData.dashboardView
      ),
    ],

    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      setData(Number.parseFloat(res.data[0].COST ?? 0));
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getCostTrendData
 * @description Function to get and set the OCI cost trend data
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param startMonth start month from which the data needs to be fetched
 * @param endMonth end month to which the data needs to be fetched
 * @param setData Callback to set the data fetched
 * @param setRequestStatus Callback to set the request status
 */
export const getCostTrendData = (
  dashboardMetaData: DashboardMetaData,
  startMonth: string,
  endMonth: string,
  setData: (val: MonthlyCostType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);
  const requestBody = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_MY_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startMonth,
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment(endMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: [
      ...getOCITagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      const data: any[] = res?.data || [];
      const months = getMonthYearShortList(
        startMonth,
        endMonth,
        YEAR_MONTH_WITHOUT_SEPARATOR
      );
      setData(
        months.map((month: string) => ({
          month: moment(month, YEAR_MONTH_WITHOUT_SEPARATOR).format(
            MONTH_YEAR_SHORT
          ),
          cost: data.find(
            (item) =>
              moment(item.MONTH).format(YEAR_MONTH_WITHOUT_SEPARATOR) === month
          )?.COST,
        }))
      );
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getCostByServiceForTrends
 * @description Function to fetch the service details for the selected month.
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param selectedTrendMonth Month for which the servivce data is fetched
 * @param monthWiseServiceData Object containing the data for the services that are already fetched and fetched data needs to appended
 * @param setData Callback to set the data fetched
 * @param setRequestStatus Callback to set the request status
 */
export const getCostByServiceForTrends = (
  dashboardMetaData: DashboardMetaData,
  selectedTrendMonth: string,
  monthWiseServiceData:
    | {
        [key: string]: ColouredCostByServiceType[];
      }
    | undefined,
  setData: (val: { [key: string]: ColouredCostByServiceType[] }) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);
  const requestBody = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_MY_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'service',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRD_SERVICE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['service'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment(selectedTrendMonth!, MONTH_YEAR_SHORT)
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment(selectedTrendMonth!, MONTH_YEAR_SHORT)
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: [
      ...getOCITagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      const data = res?.data?.filter((item: any) => item.COST > 0) || [];
      const colors = generateGraphColors(data.length);
      setData({
        ...monthWiseServiceData,
        [selectedTrendMonth ?? '']: data.map((item: any, index: number) => ({
          service: item.SERVICE,
          cost: item.COST,
          color: colors[index],
        })),
      });
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getCostByResourceForTrends
 * @description Function to fetch the resource details for the selected month and resource.
 */
export const getCostByResourceForTrends = (
  dashboardMetaData: DashboardMetaData,
  selectedTrendMonth: string,
  selectedTrendService: string,
  monthServiceResourceData:
    | {
        [key: string]: ColouredCostByResourceType[];
      }
    | undefined,
  setData: (val: { [key: string]: ColouredCostByResourceType[] }) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);
  const requestBody = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_MY_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'name',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRD_RESOURCE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['name'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment(selectedTrendMonth, MONTH_YEAR_SHORT)
                .startOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment(selectedTrendMonth, MONTH_YEAR_SHORT)
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.PRD_SERVICE,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.EQUALS,
            value: selectedTrendService,
          },
        ],
      },
    ],
    keyValueStructFilterGroups: [
      ...getOCITagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      const data = res?.data?.filter((item: any) => item.COST > 0) || [];
      const colors = generateGraphColors(data.length);
      setData({
        ...monthServiceResourceData,
        [`${selectedTrendMonth}${selectedTrendService}`]: data.map(
          (item: any, index: number) => ({
            cost: item.COST,
            color: colors[index],
            resource: item.NAME ?? OTHERS_LABEL,
          })
        ),
      });
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function sortMonthlyCostByData
 * @description Function to sort the data by consolidated cost
 * @param data List of cost data
 * @return List of sorted data
 */
const sortMonthlyCostByData = (data: any[]) => {
  return data.sort(
    (data1, data2) =>
      calculateTotalCostSum(data2.costData) -
      calculateTotalCostSum(data1.costData)
  );
};

/**
 * @function getCompartmentCostForAllMonths
 * @description Function to return the compartment cost of all the months in the selected date range.
 * @param data List of compartment cost by month from the response data (May not have the data for all the months)
 * @param startDate startDate of the data required
 * @param endDate end date of the data required
 * @param compartment Compartment for which the data is required
 * @return List of compartment cost for all the months in the selected month range and provided compartment
 */
const getCompartmentCostForAllMonths = (
  data: any[],
  startDate: string,
  endDate: string,
  compartment: string
) => {
  const labels: string[] = getMonthYearShortList(
    startDate,
    endDate,
    MONTH_YEAR_FORMAT
  );

  const monthlyCostByCompartment: MonthlyCostByCompartmentType[] = [];
  labels.forEach((label) => {
    monthlyCostByCompartment.push({
      compartment: compartment,
      month: label,
      cost:
        data.find(
          (item) =>
            item.COMPARTMENT === compartment &&
            moment(item.MONTH).format(MONTH_YEAR_FORMAT) === label
        )?.COST ?? 0,
    });
  });

  return monthlyCostByCompartment;
};

/**
 * @function getOCIMonthlyCostByCompartmentData
 * @description Function to get and set the OCI monthly cost by compartments
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param startMonth start month from which the data needs to be fetched
 * @param endMonth end month to which the data needs to be fetched
 * @param setData Callback to set the data fetched
 * @param setRequestStatus Callback to set the request status
 */
export const getOCIMonthlyCostByCompartmentData = (
  dashboardMetaData: DashboardMetaData,
  startMonth: string,
  endMonth: string,
  setData: (val: MonthlyCostByCompartmentType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);
  const requestBody = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_MY_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'compartment',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRD_COMPARTMENT_NAME,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['compartment', 'month'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startMonth,
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              endMonth,
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: [
      ...getOCITagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      let costByCompartment: CostByCompartmentDatasetType[] = [];

      res?.data?.forEach((item: any) => {
        const existingIndex = costByCompartment?.findIndex(
          (eachItem) => eachItem.compartment === item.COMPARTMENT
        );

        if (existingIndex >= 0) {
          costByCompartment[existingIndex].costData.push(item.COST);
        } else {
          costByCompartment.push({
            compartment: item.COMPARTMENT ?? 'Other',
            costData: [item.COST],
          });
        }
      });
      costByCompartment = sortMonthlyCostByData(costByCompartment);
      const sortedMonthlyData: MonthlyCostByCompartmentType[] = [];
      costByCompartment.forEach((compartmentItem) =>
        sortedMonthlyData.push(
          ...getCompartmentCostForAllMonths(
            res.data,
            startMonth,
            endMonth,
            compartmentItem.compartment
          )
        )
      );
      setData(sortedMonthlyData);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getServiceCostForAllMonths
 * @description Function to return the service cost of all the months in the selected date range.
 * @param data List of service cost by month from the response data (May not have the data for all the months)
 * @param service Service for which the data is required
 * @return List of service cost for all the months in the selected month range and provided service
 */
const getServiceCostForAllMonths = (data: any[], service: string) => {
  const labels: string[] = getMonthYearShortList(
    moment().startOf('year').format(YEAR_MONTH_WITHOUT_SEPARATOR),
    moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
    MONTH_YEAR_FORMAT
  );

  const costByServiceData: MonthlyCostByServiceType[] = [];
  labels.forEach((label) => {
    costByServiceData.push({
      service: service,
      month: label,
      cost:
        data.find(
          (item) =>
            item.SERVICE === service &&
            moment(item.MONTH).format(MONTH_YEAR_FORMAT) === label
        )?.COST ?? 0,
    });
  });

  return costByServiceData;
};

/**
 * @function getOCICostByServiceData
 * @description Function to get and set the OCI cost by service data
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param setData Callback to set the data fetched
 * @param setRequestStatus Callback to set the request status
 */
export const getOCICostByServiceData = (
  dashboardMetaData: DashboardMetaData,
  setData: (val: MonthlyCostByServiceType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  const requestBody = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_MY_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'service',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRD_SERVICE,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'month',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['service', 'month'],
    orderBy: [
      {
        label: 'month',
        sort: ORDER_BY.ASCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment().startOf('year').format(YEAR_MONTH_WITHOUT_SEPARATOR),
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment().format(YEAR_MONTH_WITHOUT_SEPARATOR),
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
        ],
      },
    ],
    keyValueStructFilterGroups: [
      ...getOCITagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      let costByService: CostByServiceConsolidatedCostType[] = [];

      res?.data?.forEach((item: any) => {
        const existingIndex = costByService?.findIndex(
          (eachItem) => eachItem.service === item.SERVICE
        );

        if (existingIndex >= 0) {
          costByService[existingIndex].costData.push(item.COST);
        } else {
          costByService.push({
            service: item.SERVICE ?? 'Other',
            costData: [item.COST],
          });
        }
      });
      costByService = sortMonthlyCostByData(costByService);
      const sortedMonthlyData: MonthlyCostByServiceType[] = [];
      costByService.forEach((serviceItem) =>
        sortedMonthlyData.push(
          ...getServiceCostForAllMonths(res.data, serviceItem.service)
        )
      );

      setData(sortedMonthlyData);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getOCICostByRegionData
 * @description Function to get and set the OCI cost by region data
 * @param dashboardMetaData Dashboard meta data of type DashboardMetaData containing the dashboard details
 * @param startMonth start month from which the data needs to be fetched
 * @param endMonth end month to which the data needs to be fetched
 * @param setData Callback to set the data fetched
 * @param setRequestStatus Callback to set the request status
 */
export const getOCICostByRegionData = (
  dashboardMetaData: DashboardMetaData,
  startMonth: string,
  endMonth: string,
  setData: (val: ColouredCostByRegionType[]) => void,
  setRequestStatus: (val: string) => void
) => {
  setRequestStatus(REQUEST_STATUS.PROCESSING);

  const isStaticData = isDashboardWithStaticData(dashboardMetaData.dashboard);

  const requestBody = {
    columns: [
      {
        label: 'cost',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.COST_MY_COST,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
      {
        label: 'region',
        field: getQueryFieldByDataSource(
          dashboardMetaData.dashboard.dashBoardType,
          QUERY_FIELDS.PRD_REGION,
          dashboardMetaData.connection.focusConversionEnabled
        ),
      },
    ],
    aggregators: [
      {
        label: 'cost',
        function: AGGREGATORS.SUM,
      },
    ],
    groupBy: ['region'],
    orderBy: [
      {
        label: 'cost',
        sort: ORDER_BY.DESCENDING,
      },
    ],
    filterGroups: [
      {
        filters: [
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              startMonth,
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            conjunctToNextFilter: CONJUNCTIONS.AND,
          },
          {
            field: getQueryFieldByDataSource(
              dashboardMetaData.dashboard.dashBoardType,
              QUERY_FIELDS.LTRIM_TO_CHAR_USAGE_INTERVAL_END,
              dashboardMetaData.connection.focusConversionEnabled
            ),
            comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
            value: formatDateFieldByProvider(
              moment(endMonth, YEAR_MONTH_WITHOUT_SEPARATOR)
                .endOf('month')
                .format(HYPHEN_DATE_FORMAT),
              PROVIDER.OCI,
              isStaticData,
              dashboardMetaData.connection.focusConversionEnabled
            ),
          },
        ],
      },
      ...getOCITagFiltersData(
        dashboardMetaData.tagsFilters,
        dashboardMetaData.dashboard.id,
        dashboardMetaData.dashboardView
      ),
    ],
    dashBoardType: DASHBOARD_TYPES.BILLING,
    cached: true,
  };

  getChartData(requestBody, dashboardMetaData.dashboard.connectorId)
    .then((res: any) => {
      const colors = generateGraphColors(res.data.length);
      setData(
        res.data.map((item: any, index: number) => {
          return {
            cost: item.COST,
            region: item.REGION,
            color: colors[index],
          };
        })
      );
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    })
    .catch((e) => {
      onApiCallError(e, false, setRequestStatus);
    });
};

/**
 * @function getCostTrendsExportColumns
 * @description Function to return the columns for excel and pdf export.
 * @param selectedTrendMonth selected month for cost trends service graph.
 * @param selectedTrendService selected service for cost trends resource graph.
 * @param data trends graph data for all the drilldown levels
 * @return List of columns for export
 */
export const getCostTrendsData = (
  selectedTrendMonth: string | undefined,
  selectedTrendService: string | undefined,
  dateRange: string[],
  data: {
    trends?: MonthlyCostType[];
    forecasted?: MonthlyCostType[];
    service?: ColouredCostByServiceType[];
    resource?: ColouredCostByResourceType[];
  }
) => {
  const { trends = [], forecasted = [], service = [], resource = [] } = data;
  if (!selectedTrendMonth) {
    const months = getMonthYearShortList(
      dateRange[0],
      moment(dateRange[1], HYPHEN_DATE_FORMAT).format(
        YEAR_MONTH_WITHOUT_SEPARATOR
      )
    );
    const consolidatedData = months.map((month) => ({
      month: month,
      cost: trends.find((item) => item.month === month)?.cost,
      forecastedCost: forecasted.find((item) => item.month === month)?.cost,
    }));

    return consolidatedData;
  }

  if (!selectedTrendService) {
    return service;
  }

  if (selectedTrendService) {
    return resource;
  }

  return [];
};

/**
 * @function getMonthlyCostByCompartmentColumns
 * @param startDate start date for monthly cost by compartment graph
 * @param endDate end date for monthly cost by compartment graph
 * @returns List of columns for monthly cost by compartment graph
 */
export const getMonthlyCostByCompartmentColumns = (
  startDate: string,
  endDate: string
) => {
  return [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
      fixed: true,
    },
    {
      title: i18n.t('compartments'),
      dataIndex: 'compartment',
      key: 'compartment',
      width: 150,
      fixed: true,
    },
    ...getMonthYearShortList(startDate, endDate, MONTH_YEAR_FORMAT).map(
      (month) => ({
        title: month,
        key: `${month}-cost`,
        dataIndex: `${month}-cost`,
        width: 100,
        render: (text: string) => currencySymbol + numberCommaSeparator(text),
      })
    ),
  ];
};

/**
 * @function getCostByServiceColumns
 * @param selectedMonthlyCostByServiceData selected monthly cost by service data
 * @returns List of columns for cost by service graph
 */
export const getCostByServiceColumns = (
  selectedMonthlyCostByServiceData: MonthlyCostByServiceType[]
) => {
  return [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_text: any, _record: any, index: number) => index + 1,
      width: 50,
      fixed: true,
    },
    {
      title: i18n.t('services'),
      dataIndex: 'service',
      key: 'service',
      width: 150,
      fixed: true,
    },
    ...getMonthYearShortList(
      getPreviousMonthAndYear(11),
      getPreviousMonthAndYear(0),
      MONTH_YEAR_FORMAT
    ).map((month) => ({
      title: month,
      dataIndex: `${month}-cost`,
      key: `${month}-cost`,
      width: 100,
      render: (_text: string, record: MonthlyCostByServiceType) =>
        currencySymbol +
        numberCommaSeparator(
          selectedMonthlyCostByServiceData.find(
            (item) => item.month === month && item.service === record.service
          )?.cost ?? 0
        ),
    })),
  ];
};

// /**
//  * @function getGcpCloudSpendQuery
//  * @description Function to return the query for cloud spend
//  * @param startDate start date for the query filter
//  * @param endDate end date for the query filter
//  * @param tagFilterData tag filter data for the query
//  * @param selectedDashboard selected dashboard
//  * @param selectedConnection Object containing the connection details
//  * @returns Query for cloud spend
//  */
// export const getGcpCloudSpendQuery = (
//   startDate: string,
//   endDate: string,
//   tagFilterData: any[],
//   selectedDashboard: DashboardListType,
//   selectedConnection: ConnectionListType
// ) => {
//   const isStaticData = isDashboardWithStaticData(selectedDashboard);
//   return {
//     columns: [
//       {
//         label: 'name',
//         field: getQueryFieldByDataSource(
//           selectedDashboard.dashBoardType,
//           QUERY_FIELDS.PROJECT_NAME,
//           selectedConnection.focusConversionEnabled
//         ),
//       },
//       {
//         label: 'cost',
//         field: getQueryFieldByDataSource(
//           selectedDashboard.dashBoardType,
//           QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
//           selectedConnection.focusConversionEnabled
//         ),
//       },
//     ],
//     aggregators: [{ label: 'cost', function: AGGREGATORS.SUM }],
//     groupBy: ['name'],
//     orderBy: [{ label: 'cost', sort: ORDER_BY.DESCENDING }],
//     dashBoardType: DASHBOARD_TYPES.BILLING,
//     cached: true,
//     filterGroups: [
//       {
//         filters: [
//           {
//             field: getQueryFieldByDataSource(
//               selectedDashboard.dashBoardType,
//               QUERY_FIELDS.INVOICE_MONTH,
//               selectedConnection.focusConversionEnabled
//             ),
//             comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
//             value: formatDateFieldByProvider(
//               startDate,
//               PROVIDER.OCI,
//               isStaticData,
//               selectedConnection.focusConversionEnabled
//             ),
//           },
//           {
//             field: getQueryFieldByDataSource(
//               selectedDashboard.dashBoardType,
//               QUERY_FIELDS.INVOICE_MONTH,
//               selectedConnection.focusConversionEnabled
//             ),
//             comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
//             value: formatDateFieldByProvider(
//               endDate,
//               PROVIDER.OCI,
//               isStaticData,
//               selectedConnection.focusConversionEnabled
//             ),
//           },
//         ],
//       },
//     ],
//     keyValueStructFilterGroups: [...tagFilterData],
//   };
// };

// /**
//  * @function getGcpTaggedUntaggedSpendQuery
//  * @description Function to return the query for tagged and untagged spend
//  * @param startMonth start month for the query filter
//  * @param endMonth end month for the query filter
//  * @param tagFilters tag filters for the query
//  * @param selectedDashboard selected dashboard
//  * @param selectedConnection Object containing the connection details
//  * @param selectedDashboardView selected dashboard view
//  * @returns List of queries for tagged and untagged spend
//  */
// export const getGcpTaggedUntaggedSpendQuery = (
//   startMonth: string,
//   endMonth: string,
//   tagFilters: TagsFilterType[],
//   selectedDashboard: DashboardListType,
//   selectedConnection: ConnectionListType,
//   selectedDashboardView: string
// ) => {
//   const selectedTagsFilterGroups = getOCITagFiltersData(
//     tagFilters,
//     selectedDashboard.id,
//     selectedDashboardView
//   );
//   const baseQuery = {
//     columns: [
//       {
//         label: 'cost',
//         field: getQueryFieldByDataSource(
//           selectedDashboard.dashBoardType,
//           QUERY_FIELDS.COST,
//           selectedConnection.focusConversionEnabled
//         ),
//       },
//       {
//         label: 'name',
//         field: getQueryFieldByDataSource(
//           selectedDashboard.dashBoardType,
//           QUERY_FIELDS.INVOICE_MONTH,
//           selectedConnection.focusConversionEnabled
//         ),
//       },
//     ],
//     groupBy: ['name'],
//     aggregators: [
//       {
//         label: 'cost',
//         function: AGGREGATORS.SUM,
//       },
//     ],
//     filterGroups: [
//       {
//         filters: [
//           {
//             field: getQueryFieldByDataSource(
//               selectedDashboard.dashBoardType,
//               QUERY_FIELDS.INVOICE_MONTH,
//               selectedConnection.focusConversionEnabled
//             ),
//             comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
//             value: formatDateFieldByProvider(
//               startMonth,
//               PROVIDER.OCI,
//               false,
//               selectedConnection.focusConversionEnabled
//             ),
//           },
//           {
//             field: getQueryFieldByDataSource(
//               selectedDashboard.dashBoardType,
//               QUERY_FIELDS.INVOICE_MONTH,
//               selectedConnection.focusConversionEnabled
//             ),
//             comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
//             value: formatDateFieldByProvider(
//               endMonth,
//               PROVIDER.OCI,
//               false,
//               selectedConnection.focusConversionEnabled
//             ),
//           },
//         ],
//         conjunctToNextGroup: CONJUNCTIONS.AND,
//       },
//     ],
//     dashBoardType: DASHBOARD_TYPES.BILLING_DEFAULT,
//     cached: true,
//   };

//   if (selectedTagsFilterGroups.length > 0) {
//     return [
//       {
//         ...baseQuery,
//         keyValueStructFilterGroups: selectedTagsFilterGroups,
//       },
//     ];
//   }
//   return [
//     {
//       ...baseQuery,
//       filterGroups: [
//         ...baseQuery.filterGroups,
//         {
//           filters: [
//             {
//               field: getQueryFieldByDataSource(
//                 selectedDashboard.dashBoardType,
//                 QUERY_FIELDS.SELECT_STRUCT_PROJECT_LABELS,
//                 selectedConnection.focusConversionEnabled
//               ),
//               comparator: COMPARATORS.IS,
//               value: QUERY_VALUES.NOT_NULL,
//             },
//           ],
//         },
//       ],
//     },
//     {
//       ...baseQuery,
//       filterGroups: [
//         ...baseQuery.filterGroups,
//         {
//           filters: [
//             {
//               field: getQueryFieldByDataSource(
//                 selectedDashboard.dashBoardType,
//                 QUERY_FIELDS.SELECT_STRUCT_PROJECT_LABELS,
//                 selectedConnection.focusConversionEnabled
//               ),
//               comparator: COMPARATORS.IS,
//               value: QUERY_VALUES.NULL,
//             },
//           ],
//         },
//       ],
//     },
//   ];
// };

// /**
//  * @function getGCPServiceSpendProfileQuery
//  * @description Function to return the query for service spend profile
//  * @param startMonth start month for the query filter
//  * @param endMonth end month for the query filter
//  * @param selectedDashboard selected dashboard
//  * @param selectedConnection Object containing the connection details
//  * @param selectedDashboardView selected dashboard view
//  * @param tagFilters tag filters for the query
//  * @returns Query for service spend profile
//  */
// export const getGCPServiceSpendProfileQuery = (
//   startMonth: string,
//   endMonth: string,
//   selectedDashboard: DashboardListType,
//   selectedConnection: ConnectionListType,
//   selectedDashboardView: string,
//   tagFilters: TagsFilterType[]
// ) => {
//   const selectedTagsFilterGroups = getOCITagFiltersData(
//     tagFilters,
//     selectedDashboard.id,
//     selectedDashboardView
//   );

//   const isStaticData = isDashboardWithStaticData(selectedDashboard);

//   return {
//     columns: [
//       {
//         label: 'cost',
//         field: getQueryFieldByDataSource(
//           selectedDashboard.dashBoardType,
//           QUERY_FIELDS.COST_PLUS_CREDIT_AMOUNT,
//           selectedConnection.focusConversionEnabled
//         ),
//       },
//       {
//         label: 'service',
//         field: getQueryFieldByDataSource(
//           selectedDashboard.dashBoardType,
//           QUERY_FIELDS.SERVICE_DESCRIPTION,
//           selectedConnection.focusConversionEnabled
//         ),
//       },
//       {
//         label: 'month',
//         field: getQueryFieldByDataSource(
//           selectedDashboard.dashBoardType,
//           QUERY_FIELDS.INVOICE_MONTH,
//           selectedConnection.focusConversionEnabled
//         ),
//       },
//     ],
//     aggregators: [
//       {
//         label: 'cost',
//         function: AGGREGATORS.SUM,
//       },
//     ],
//     groupBy: ['service', 'month'],
//     orderBy: [
//       {
//         label: 'month',
//         sort: ORDER_BY.DESCENDING,
//       },
//     ],
//     filterGroups: [
//       {
//         filters: [
//           {
//             field: getQueryFieldByDataSource(
//               selectedDashboard.dashBoardType,
//               QUERY_FIELDS.SERVICE_DESCRIPTION,
//               selectedConnection.focusConversionEnabled
//             ),
//             comparator: COMPARATORS.NOT_EQUAL,
//             value: '',
//           },
//           {
//             field: getQueryFieldByDataSource(
//               selectedDashboard.dashBoardType,
//               QUERY_FIELDS.INVOICE_MONTH,
//               selectedConnection.focusConversionEnabled
//             ),
//             comparator: COMPARATORS.GREATER_THAN_OR_EQUAL,
//             value: formatDateFieldByProvider(
//               startMonth,
//               PROVIDER.OCI,
//               isStaticData,
//               selectedConnection.focusConversionEnabled
//             ),
//           },
//           {
//             field: getQueryFieldByDataSource(
//               selectedDashboard.dashBoardType,
//               QUERY_FIELDS.INVOICE_MONTH,
//               selectedConnection.focusConversionEnabled
//             ),
//             comparator: COMPARATORS.LESS_THAN_OR_EQUAL,
//             value: formatDateFieldByProvider(
//               endMonth,
//               PROVIDER.OCI,
//               isStaticData,
//               selectedConnection.focusConversionEnabled
//             ),
//           },
//         ],
//       },
//     ],
//     keyValueStructFilterGroups: selectedTagsFilterGroups,
//     dashBoardType: DASHBOARD_TYPES.BILLING,
//     cached: true,
//   };
// };
