import i18n from 'i18n';

export const SavingsTrackExportColumns = [
  { header: '#', key: 'slNo', dataKey: 'slNo', width: 10 },
  {
    header: i18n.t('scorecard.summary.savingsType'),
    dataKey: 'savingsType',
    key: 'savingsType',
  },
  {
    header: i18n.t('scorecard.summary.savings'),
    dataKey: 'savings',
    key: 'savings',
    alignment: 'right',
  },
];
