import { Steps } from 'antd';

import './index.scss';

type ProgressBarProps = {
  items: { title: string; description: string }[];
  current: number;
  heading: string;
  subHeading: string;
  direction?: 'vertical' | 'horizontal';
  additionalClassNames?: string;
};

const ProgressBar = ({
  items,
  current = 0,
  heading,
  subHeading,
  direction = 'vertical',
  additionalClassNames = '',
}: ProgressBarProps) => {
  return (
    <div
      className={`progress-bar new-styled-scroll ${additionalClassNames}`}
      data-testid="progress-bar"
    >
      <div className="progress-bar-header">
        <div className="progress-bar-header-heading form-header">{heading}</div>
        <p className="progress-bar-header-sub-heading font-caption">
          {subHeading}
        </p>
      </div>
      <Steps
        className="steps"
        direction={direction}
        current={current}
        items={items}
      />
    </div>
  );
};

export default ProgressBar;
