import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectTheme } from 'redux/themeSlice';
import Icon from 'components/Icon';
import Switch from 'components/Switch';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { LoadingIcon } from 'assets/icons';
import {
  setExpandedGraphName,
  setPdfGraphToDownload,
  setPdfDownloadMode,
  selectDashboard,
  setShowExpandGraphModal,
} from 'redux/dashboardSlice';
import { exportToExcel } from 'utils/exportToExcel';

import './index.scss';

type GraphHeaderProps = {
  heading: string;
  headingComponent?: React.ReactNode;
  comparisonValue?: number;
  comparisonBelowHeading?: boolean;
  comparisonFrom?: string;
  setExpandedGraphHeader?: (val: string) => void;
  setExpandedGraphComparisonValue?: (val: number | undefined) => void;
  setShowExpandedGraph?: (val: boolean) => void;
  graphName: string;
  filters?: React.ReactNode;
  excelData?: any;
  ignorePaddingBottom?: boolean;
  isDownloadable?: boolean;
  isTableViewSwitch?: boolean;
  isTableView?: boolean;
  setIsTableView?: (value: boolean) => void;
  showExpandIcon?: boolean;
  className?: string;
  graphHeadingClass?: string;
  designVersion2?: boolean;
  additionalComponents?: {
    prefix?: React.ReactNode[];
  };
  alwaysExcelDownload?: boolean;
};

const GraphHeader = ({
  heading,
  headingComponent,
  comparisonValue,
  comparisonBelowHeading = false,
  comparisonFrom,
  setExpandedGraphHeader,
  setExpandedGraphComparisonValue,
  setShowExpandedGraph,
  graphName,
  filters,
  excelData,
  ignorePaddingBottom,
  isDownloadable,
  isTableViewSwitch,
  isTableView,
  showExpandIcon = true,
  setIsTableView,
  className,
  graphHeadingClass,
  designVersion2,
  additionalComponents,
  alwaysExcelDownload,
}: GraphHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pdfDownloadMode, showExpandGraphModal } =
    useSelector(selectDashboard);
  const { theme } = useSelector(selectTheme);

  const [isDownloadingData, setIsDownloadingData] = useState(false);
  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  const onClickExpandGraphEvent = () => {
    setExpandedGraphHeader?.(heading);
    setExpandedGraphComparisonValue?.(comparisonValue);
    setShowExpandedGraph?.(true);
    dispatch(setExpandedGraphName(graphName));
    dispatch(setShowExpandGraphModal(true));
  };

  /**
   * @function onClickDownload
   * @description function to handle download of chart or table view.
   * @returns either pdf or excel
   */
  const onClickDownload = () => {
    setIsDownloadingData(true);
    if (isTableView === undefined || isTableView || alwaysExcelDownload) {
      exportToExcel(
        heading,
        excelData.multipleSheets
          ? excelData.sheets
          : [
              {
                sheetName: heading,
                columns: excelData.columns,
                data: excelData.data,
                filters: excelData.filters,
                boldRows: excelData.boldRows ?? [],
                footer: excelData.footer,
              },
            ]
      ).then(() => setIsDownloadingData(false));
      return;
    }
    dispatch(setPdfGraphToDownload(graphName));
    dispatch(setPdfDownloadMode(true));
  };

  const ComparisonComponent = (
    <div
      className={`comparison ${
        comparisonValue! > 0 ? 'positive' : 'negative'
      } flex flex-center`}
    >
      {comparisonValue! > 0 ? (
        <Icon iconName={ICONS.ARROW_RIGHT_UP_LINE} />
      ) : (
        <Icon iconName={ICONS.ARROW_RIGHT_DOWN_LINE} />
      )}
      <span>
        {comparisonValue! > 0 ? `+${comparisonValue}` : comparisonValue}%
      </span>
    </div>
  );

  const getComparisonPercent = () =>
    comparisonBelowHeading ? (
      <div className="comparison-below flex flex-align-items-center flex-gap-4">
        {ComparisonComponent}
        {comparisonFrom && (
          <div className="font-comparison-label">{comparisonFrom}</div>
        )}
      </div>
    ) : (
      ComparisonComponent
    );

  return (
    <div
      className={`graph-header${
        designVersion2 ? '-v2' : ''
      } graph-heading-typography flex flex-column ${
        ignorePaddingBottom ? 'ignore-padding-bottom' : ''
      } ${className}`}
    >
      <div className="flex flex-space-between flex-gap-16 flex-wrap full-width graph-header-item">
        <div
          className={`graph-heading-text flex flex-column flex-gap-4 ${graphHeadingClass}`}
        >
          {headingComponent ?? heading}
          {comparisonValue !== undefined &&
            isFinite(comparisonValue) &&
            comparisonBelowHeading &&
            getComparisonPercent()}
        </div>
        <div className="expand-icon flex flex-align-items-center flex-align-self-start">
          {comparisonValue !== undefined &&
            isFinite(comparisonValue) &&
            !comparisonBelowHeading &&
            getComparisonPercent()}
          {additionalComponents?.prefix &&
            additionalComponents.prefix
              .map((item, index) => ({ component: item, key: index }))
              .map((item) => (
                <div className="additional-components" key={item.key}>
                  {item.component}
                </div>
              ))}
          {isDownloadable &&
            (isDownloadingData ? (
              <Icon
                icon={LoadingIcon}
                className="rotate download-icon"
                color={theme.buttonIconColor}
              />
            ) : (
              <Icon
                className="download-icon"
                id="pdf-hide"
                iconName={ICONS.DOWNLOAD_2_LINE}
                size={ICONS_SIZE.ONE_X}
                color={theme.buttonIconColor}
                onClick={onClickDownload}
              />
            ))}
          {isTableViewSwitch && (
            <div
              id="pdf-hide"
              className="table-view-title table-typography flex flex-align-items-center flex-gap-4"
            >
              {t('tableView')}
              <Switch
                size="small"
                checked={isTableView}
                onChange={(value: boolean) => {
                  setIsTableView?.(value);
                }}
              />
            </div>
          )}
          {showExpandIcon &&
            (showExpandGraphModal ? (
              <Icon
                iconName={ICONS.FULLSCREEN_EXIT_FILL}
                className="maximize-icon"
                onClick={() => {
                  dispatch(setShowExpandGraphModal(false));
                  setShowExpandedGraph?.(false);
                }}
                id="pdf-hide"
                color={theme.buttonIconColor}
                size={ICONS_SIZE.ONE_X}
              />
            ) : (
              <Icon
                iconName={ICONS.FULLSCREEN_FILL}
                className="maximize-icon"
                onClick={onClickExpandGraphEvent}
                id="pdf-hide"
                color={theme.buttonIconColor}
                size={ICONS_SIZE.ONE_X}
                dataTestId="expand-cta"
              />
            ))}
        </div>
      </div>
      {filters}
    </div>
  );
};

export default GraphHeader;
