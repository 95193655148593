import { message, Skeleton } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CalendarLineIcon from 'remixicon-react/CalendarLineIcon';

import DrawerComponent from 'components/DrawerComponent';
import { FormLabel } from 'components/FormLabel';
import SelectDropdown from 'components/Select';
import { default as TimePicker } from 'components/TimePicker';
import DatePicker from 'components/DatePicker';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/appearance';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { FETCH_DROPDOWN_PAGINATION_SIZE } from 'constants/userConsole';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { SubscriptionType } from 'pages/SubscriptionsPage/types';
import {
  SUBSCRIPTION_FREQUENCY_LIST,
  SubscriptionFrequency,
  WEEKDAYS,
} from 'pages/SubscriptionsPage/constants';
import { TeamsType } from 'pages/TeamsPage/types';
import {
  createSubscription,
  updateSubscription,
  fetchSubscriptionNameValidation,
} from 'pages/SubscriptionsPage/services';
import { UserInfoType } from 'types/userManagementConsole';
import {
  getValidationStyle,
  validateAlphanumericNames,
  validateEmptyField,
} from 'utils/validations';
import {
  DD_MMM_YYYY,
  HOUR_MINUTE,
  TIMESTAMP_FORMAT_WITHOUT_ZONE,
} from 'utils/date';
import { onApiCallError } from 'utils/handleErrors';
import {
  getAllUsersData,
  getSearchUserData,
  searchTeams,
  getAllTeams,
} from 'utils/services';
import { TeamStatus } from 'pages/TeamsPage/constants';

import UserRow from '../UserRow';
import TeamRow from '../TeamRow';

import './index.scss';

type EditSubscriptionDrawerProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  isEditSubscription?: boolean;
  subscription: SubscriptionType;
  onClickUpdate?: Function;
};

const EditSubscriptionDrawer = ({
  show,
  setShow,
  isEditSubscription = false,
  subscription,
  onClickUpdate,
}: EditSubscriptionDrawerProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' });

  const [editedData, setEditedData] = useState<SubscriptionType>(subscription);
  const [subscriptionNameValidation, setSubscriptionNameValidation] =
    useState('');
  const [isDuplicateSubscriptionName, setIsDuplicateSubscriptionName] =
    useState(false);
  const [users, setUsers] = useState<UserInfoType[]>([]);
  const [teams, setTeams] = useState<TeamsType[]>([]);
  const [fetchUsersReqStatus, setFetchUsersReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [fetchTeamsReqStatus, setFetchTeamsReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );
  const [searchKey, setSearchKey] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [userTeamValidation, setUserTeamValidation] = useState('');
  const [createEditReqStatus, setCreateEditReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    setFetchUsersReqStatus(REQUEST_STATUS.PROCESSING);

    let source = axios.CancelToken.source();
    fetchUserData(source);
    fetchTeamData(source);

    return () => {
      source.cancel();
    };
  }, [searchKey]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (editedData.subscriptionViewName === subscription.subscriptionViewName) {
      setIsDuplicateSubscriptionName(false);
      return;
    }

    if (editedData.subscriptionViewName) {
      validateDuplicateSubscriptionName(source);
    }

    return () => {
      source.cancel();
    };
  }, [editedData.subscriptionViewName]);

  /**
   * @function validateDuplicateSubscriptionName
   * @description Function to validate the duplicate name
   * @param cancelTokenSource cancel token
   */
  const validateDuplicateSubscriptionName = (
    cancelTokenSource: CancelTokenSource
  ) => {
    const params = {
      subscriptionName:
        editedData.subscriptionViewName + localStorage.getItem('email'),
    };

    fetchSubscriptionNameValidation(params, cancelTokenSource.token)
      .then((res: any) => {
        if (res?.data?.responseData) {
          setIsDuplicateSubscriptionName(true);
          setSubscriptionNameValidation(t('duplicateSubscriptionName'));
          return;
        }
        setIsDuplicateSubscriptionName(false);
      })
      .catch((e) => {
        onApiCallError(e, false);
      });
  };

  /**
   * @function setInitialValues
   * @description Function to set initial values
   */
  const setInitialValues = () => {
    setEditedData({
      ...subscription,
      startDate: subscription.startDate
        ? moment(subscription.startDate).format(DD_MMM_YYYY)
        : moment().format(DD_MMM_YYYY),
      endDate: subscription.endDate
        ? moment(subscription.endDate).format(DD_MMM_YYYY)
        : undefined,
      hour: subscription.hour ?? moment().hours().toString(),
      minute: subscription.minute ?? moment().minutes().toString(),
    });
  };

  /**
   * @function fetchTeamData
   * @description Function to fetch all team data
   * @param page accepts the current page number
   */
  const fetchTeamData = (cancelTokenSource: CancelTokenSource) => {
    setFetchTeamsReqStatus(REQUEST_STATUS.PROCESSING);

    const params = { key: searchKey, size: FETCH_DROPDOWN_PAGINATION_SIZE };

    (searchKey
      ? searchTeams(params, cancelTokenSource.token)
      : getAllTeams(params, cancelTokenSource.token)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setTeams(
            res?.data?.responseData?.teamsRespDtoList?.filter(
              (item: any) => item.teamStatus === TeamStatus.ACTIVE
            )
          );
          setFetchTeamsReqStatus(REQUEST_STATUS.SUCCESS);
          return;
        }

        setFetchTeamsReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchTeamsReqStatus);
      });
  };

  /**
   * @function fetchUserData
   * @description Function to fetch all user data
   * @param page accepts the current page number
   */
  const fetchUserData = (cancelTokenSource: CancelTokenSource) => {
    setFetchUsersReqStatus(REQUEST_STATUS.PROCESSING);

    const params = { key: searchKey, size: FETCH_DROPDOWN_PAGINATION_SIZE };

    (searchKey
      ? getSearchUserData(params, cancelTokenSource.token)
      : getAllUsersData(
          { size: FETCH_DROPDOWN_PAGINATION_SIZE },
          cancelTokenSource.token
        )
    )
      .then((res: any) => {
        const { data } = res;
        const usersList = data?.content?.map((item: any) => ({
          email: item.email,
          name: item.name,
        }));
        setUsers(usersList);
        setFetchUsersReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setFetchUsersReqStatus);
      });
  };

  /**
   * @function validateSubscriptionName
   * @description Function to validate the subscription name field
   * @param value value to be validated
   * @returns true if validation is successful else false
   */
  const validateSubscriptionName = (value: string) => {
    if (
      validateEmptyField(
        value,
        t('subscriptionName'),
        setSubscriptionNameValidation
      )
    ) {
      return false;
    }

    if (
      !validateAlphanumericNames(
        value,
        t('subscriptionName'),
        setSubscriptionNameValidation
      )
    ) {
      return false;
    }

    if (isDuplicateSubscriptionName) {
      setSubscriptionNameValidation(t('duplicateSubscriptionName'));
      return false;
    }

    return true;
  };

  /**
   * @function validateUsersTeams
   * @description Function to validate the users and teams field
   * @param users users to be validated
   * @param teams teams to be validated
   * @returns true if validation is successful else false
   */
  const validateUsersTeams = (
    users: UserInfoType[] | undefined,
    teams: string[] | undefined
  ) => {
    if (users?.length === 0 && teams?.length === 0) {
      setUserTeamValidation(t('selectAtLeastOneUserOrTeam'));
      return false;
    }
    return true;
  };

  /**
   * @function onCreateUpdateSubscriptionError
   * @description Callback function for error on creating or updating a subscription
   * @param errorMessage error message to be displayed
   */
  const onCreateUpdateSubscriptionError = (errorMessage: string) => {
    message.error({
      content:
        errorMessage ||
        (isEditSubscription
          ? t('errorUpdatingSubscription')
          : t('errorCreatingSubscription')),
      key: ERROR_KEY,
    });
    setCreateEditReqStatus(REQUEST_STATUS.ERROR);
  };

  /**
   * @function onClickFinish
   * @description Function to handle the finish button click
   */
  const onClickFinish = () => {
    if (
      !validateSubscriptionName(editedData.subscriptionViewName) ||
      !validateUsersTeams(editedData.listOfUser, editedData.teamName) ||
      isDuplicateSubscriptionName
    )
      return;

    setCreateEditReqStatus(REQUEST_STATUS.PROCESSING);

    const body = {
      ...editedData,
      startDate: moment(editedData.startDate)
        .startOf('day')
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      endDate: editedData.endDate
        ? moment(editedData.endDate)
            .endOf('day')
            .format(TIMESTAMP_FORMAT_WITHOUT_ZONE)
        : undefined,
      createdBy: localStorage.getItem('email'),
    };

    (isEditSubscription ? updateSubscription(body) : createSubscription(body))
      .then((res: any) => {
        if (res?.status === 200) {
          setCreateEditReqStatus(REQUEST_STATUS.SUCCESS);
          if (isEditSubscription) {
            onClickUpdate?.();
          }
          setShow(false);
          message.success({
            content: isEditSubscription
              ? t('successUpdatingSubscription')
              : t('successCreatingSubscription'),
            key: SUCCESS_KEY,
          });
          return;
        }
        onCreateUpdateSubscriptionError(res?.data?.message);
      })
      .catch((e) => {
        onApiCallError(e, false, setCreateEditReqStatus);
        onCreateUpdateSubscriptionError(e?.response?.data?.message);
      });
  };

  /**
   * @function onSelectFrequency
   * @description Callback function for selecting the frequency
   */
  const onSelectFrequency = (value: string) => {
    setEditedData({
      ...editedData,
      frequency: value,
    });

    switch (value) {
      case SubscriptionFrequency.DAILY:
        setEditedData({
          ...editedData,
          frequency: value,
          hour: editedData.hour ?? moment().hours().toString(),
          minute: editedData.minute ?? moment().hours().toString(),
        });
        break;

      case SubscriptionFrequency.WEEKLY:
        setEditedData({
          ...editedData,
          frequency: value,
          dayOfWeek: editedData.dayOfWeek ?? WEEKDAYS[0].value.toString(),
        });
        break;

      case SubscriptionFrequency.MONTHLY:
        setEditedData({
          ...editedData,
          frequency: value,
          dateOfTheMonth: editedData.dateOfTheMonth ?? '1',
        });
        break;
    }
  };

  const getTimePickersByFrequency = () => {
    switch (editedData.frequency) {
      case SubscriptionFrequency.DAILY:
        return (
          <TimePicker
            className="flex-fit"
            value={moment(
              `${editedData.hour}:${editedData.minute}`,
              HOUR_MINUTE
            )}
            onSelect={(value: any) => {
              const hours = value.hours();
              const minutes = value.minutes();
              setEditedData({ ...editedData, hour: hours, minute: minutes });
            }}
            format={HOUR_MINUTE}
            showNow={false}
            size="large"
          />
        );
      case SubscriptionFrequency.WEEKLY:
        return (
          <div className="form-item flex flex-gap-8">
            <SelectDropdown
              className="flex-fit"
              value={editedData.dayOfWeek}
              defaultValue={WEEKDAYS[0].value}
              options={WEEKDAYS.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              onSelect={(value: any) => {
                setEditedData({
                  ...editedData,
                  dayOfWeek: value,
                });
              }}
              iconOverride={
                <CalendarLineIcon className="calendar-suffix-icon" />
              }
              designVersion2
            />
            <TimePicker
              className="flex-fit"
              value={moment(
                `${editedData.hour}:${editedData.minute}`,
                HOUR_MINUTE
              )}
              onSelect={(value: any) => {
                const hours = value.hours();
                const minutes = value.minutes();
                setEditedData({ ...editedData, hour: hours, minute: minutes });
              }}
              format={HOUR_MINUTE}
              showNow={false}
              size="large"
            />
          </div>
        );
      case SubscriptionFrequency.MONTHLY:
        return (
          <div className="form-item flex flex-gap-8">
            <SelectDropdown
              className="flex-fit"
              value={editedData.dateOfTheMonth}
              defaultValue={1}
              options={Array.from({ length: 31 }, (_, i) => i + 1).map(
                (item) => {
                  if (item === 1 || item === 21 || item === 31)
                    return { value: item, label: `${item}st` };
                  else if (item === 2 || item === 22)
                    return { value: item, label: `${item}nd` };
                  else if (item === 3 || item === 23)
                    return { value: item, label: `${item}rd` };
                  else return { value: item, label: `${item}th` };
                }
              )}
              onSelect={(value: any) => {
                setEditedData({
                  ...editedData,
                  dateOfTheMonth: value,
                });
              }}
              iconOverride={<CalendarLineIcon className="suffix-icon" />}
              designVersion2
            />
            <TimePicker
              className="flex-fit"
              value={moment(
                `${editedData.hour}:${editedData.minute}`,
                HOUR_MINUTE
              )}
              onSelect={(value: any) => {
                const hours = value.hours();
                const minutes = value.minutes();
                setEditedData({ ...editedData, hour: hours, minute: minutes });
              }}
              format={HOUR_MINUTE}
              showNow={false}
              size="large"
            />
          </div>
        );
    }
  };

  const getUsersTeamsDropdownRender = (originalNode: ReactNode) =>
    [fetchUsersReqStatus, fetchTeamsReqStatus].includes(
      REQUEST_STATUS.PROCESSING
    ) ? (
      <Skeleton active paragraph={{ rows: 5 }} title={false} />
    ) : (
      originalNode
    );

  /**
   * @function getUsersAndTeamsList
   * @description Function to get the dropdown options for teams and users list
   * @returns list of dropdown options based on the number of items in the list
   */
  const getUsersAndTeamsList = () => {
    const filteredTeams = teams
      .filter(
        (team) =>
          !editedData?.teamName?.find(
            (selectedTeam) => selectedTeam === team.teamName
          )
      )
      .map((item) => ({
        label: item.teamName,
        value: item.teamName,
      }));

    const filteredUsers = users
      .filter(
        (user) =>
          !editedData?.listOfUser?.find(
            (selectedUser) => selectedUser.email === user.email
          )
      )
      .map((item) => ({
        label: item.name,
        value: item.email,
      }));

    const options = [];

    if (filteredTeams.length > 0) {
      options.push({
        label: t('teams'),
        options: filteredTeams,
      });
    }

    if (filteredUsers.length > 0) {
      options.push({
        label: t('users'),
        options: filteredUsers,
      });
    }

    return options;
  };

  return (
    <DrawerComponent
      className="subscription-drawer"
      dataTestId="subscription-drawer"
      open={show}
      title={
        isEditSubscription ? t('editSubscription') : t('createSubscription')
      }
      onClose={() => setShow(false)}
      footer={
        <>
          <div className="timezone-info font-small-bold">
            {t('timezoneInUtc')}
          </div>
          <div className="flex flex-gap-8 flex-end">
            <Button
              title={t('cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShow(false)}
            />
            <Button
              title={isEditSubscription ? t('update') : t('create')}
              onClick={() => onClickFinish()}
              loading={createEditReqStatus === REQUEST_STATUS.PROCESSING}
            />
          </div>
        </>
      }
    >
      <section className="flex flex-column flex-gap-24">
        <div className="form-item flex flex-column">
          <FormLabel title={t('subscriptionName')} required />
          <Input
            value={editedData.subscriptionViewName}
            placeholder={t('enterSubscriptionName')}
            onChange={(e: any) => {
              setEditedData({
                ...editedData,
                subscriptionViewName: e.target.value,
              });
              validateSubscriptionName(e.target.value);
            }}
            onBlur={() => {
              validateSubscriptionName(editedData.subscriptionViewName);
            }}
          />
          <span
            style={{
              display: subscriptionNameValidation ? 'inline' : 'none',
            }}
            className="font-validation-error"
          >
            {subscriptionNameValidation}
          </span>
        </div>
        <div className="flex flex-column flex-gap-8">
          <div className="form-item flex flex-column">
            <FormLabel title={t('frequency')} required />
            <SelectDropdown
              value={editedData.frequency}
              options={SUBSCRIPTION_FREQUENCY_LIST.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
              onSelect={onSelectFrequency}
              designVersion2
            />
          </div>
          {getTimePickersByFrequency()}
        </div>
        <div className="flex flex-gap-8">
          <div className="form-item flex flex-column flex-fit">
            <FormLabel title={t('startDate')} />
            <DatePicker
              value={moment(editedData.startDate, DD_MMM_YYYY)}
              pickerType={DATE_PICKER_TYPE.DATE_PICKER}
              format={DD_MMM_YYYY}
              onChange={(_dates: any, value: string) => {
                setEditedData({
                  ...editedData,
                  startDate: value,
                });
              }}
              disabledDate={(current: moment.Moment) => {
                return current && current < moment().startOf('day');
              }}
              showToday={false}
              size="large"
            />
          </div>
          <div className="form-item flex flex-column flex-fit">
            <FormLabel title={t('endDate')} />
            <DatePicker
              value={
                editedData.endDate
                  ? moment(editedData.endDate, DD_MMM_YYYY)
                  : ''
              }
              pickerType={DATE_PICKER_TYPE.DATE_PICKER}
              format={DD_MMM_YYYY}
              onChange={(_dates: any, value: string) => {
                setEditedData({
                  ...editedData,
                  endDate: value,
                });
              }}
              showToday={false}
              size="large"
              disabledDate={(current: moment.Moment) => {
                return (
                  current &&
                  current <
                    moment(editedData.startDate, DD_MMM_YYYY).startOf('day')
                );
              }}
              allowClear={true}
            />
          </div>
        </div>
        <div className="team-users-list flex flex-column flex-gap-16">
          <div className="form-item flex flex-column">
            <FormLabel title={t('sendAlertOnEmail')} required />
            <SelectDropdown
              rootClassName="full-width"
              labelInValue
              options={getUsersAndTeamsList()}
              placeholder={t('searchByUsersAndTeams')}
              filterOption={false}
              dropdownRender={getUsersTeamsDropdownRender}
              showSearch
              onSearch={(searchValue: string) => {
                setUsers([]);
                setTeams([]);
                setSearchKey(searchValue);
              }}
              open={openDropdown}
              suffixIcon={<Icon iconName={ICONS.SEARCH_2_LINE} />}
              designVersion2={true}
              onBlur={() => {
                setOpenDropdown(false);
                setSearchKey('');
              }}
              onSelect={(value: any) => {
                if (users.find((user) => user.email === value.key)) {
                  setEditedData({
                    ...editedData,
                    listOfUser: [
                      ...(editedData.listOfUser ?? []),
                      {
                        email: value.key,
                        name: value.label,
                      },
                    ],
                  });
                }
                if (teams.find((team) => team.teamName === value.key)) {
                  setEditedData({
                    ...editedData,
                    teamName: [
                      ...(editedData.teamName ?? []),
                      value.key.toString(),
                    ],
                  });
                }
              }}
              onFocus={() => setOpenDropdown(true)}
              onInputKeyDown={() => setOpenDropdown(true)}
            />
          </div>
          <div className="selected-users full-width flex flex-align-items-center">
            <Icon
              className="arrow-down"
              iconName={ICONS.ARROW_DOWN_S_LINE}
              size={ICONS_SIZE.XL}
            />
            <div className="font-caption-bold">
              {t('selectedUsersAndTeams')}
            </div>
            <div className="users-count font-tooltip-small flex flex-end flex-fit">
              <span
                className="users-count-value"
                data-testid="users-count-value"
              >
                {(editedData?.listOfUser?.length ?? 0) +
                  (editedData.teamName?.length ?? 0)}
              </span>
            </div>
          </div>
          <div className="team-users-list flex flex-column flex-gap-12 new-styled-scroll">
            {editedData?.listOfUser?.map((user) => (
              <UserRow
                key={user.email}
                user={user}
                onClickRemoveUser={() => {
                  const updatedUsers = editedData?.listOfUser?.filter(
                    (selectedUser) => selectedUser.email !== user.email
                  );
                  setEditedData({
                    ...editedData,
                    listOfUser: updatedUsers,
                  });
                }}
              />
            ))}
            {editedData?.teamName?.map((teamName) => (
              <TeamRow
                key={teamName}
                teamName={teamName}
                onClickRemoveTeam={() => {
                  const updatedTeams = editedData?.teamName?.filter(
                    (selectedTeam) => selectedTeam !== teamName
                  );
                  setEditedData({
                    ...editedData,
                    teamName: updatedTeams,
                  });
                }}
              />
            ))}
          </div>
          <span
            style={{
              display: `${getValidationStyle(userTeamValidation)}`,
            }}
            className="font-validation-error"
          >
            {userTeamValidation}
          </span>
        </div>
      </section>
    </DrawerComponent>
  );
};

export default EditSubscriptionDrawer;
