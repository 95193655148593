import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COST_ALLOCATION_NAVS } from 'pages/CostAllocationDashboardPage/components/HorizontalNavigation/constants';
import { DashboardListType } from 'types/navigationMenu';
import { RootState } from './store';

export interface CostAllocationDashboardState {
  costAllocationDashboardList: DashboardListType[];
  selectedCostAllocationDashboard: DashboardListType | null;
  selectedCostAllocationView: string;
}

const initialState: CostAllocationDashboardState = {
  selectedCostAllocationDashboard: null,
  costAllocationDashboardList: [],
  selectedCostAllocationView: COST_ALLOCATION_NAVS.COST_ALLOCATION_SUMMARY,
};

export const costAllocationDashboardSlice = createSlice({
  name: 'costAllocationDashboardSlice',
  initialState,
  reducers: {
    setSelectedCostAllocationDashboard: (
      state,
      action: PayloadAction<DashboardListType | null>
    ) => {
      state.selectedCostAllocationDashboard = action.payload;
    },
    setCostAllocationDashboardList: (
      state,
      action: PayloadAction<DashboardListType[]>
    ) => {
      state.costAllocationDashboardList = action.payload;
    },
    setSelectedCostAllocationView: (state, action: PayloadAction<string>) => {
      state.selectedCostAllocationView = action.payload;
    },
  },
});

export const selectCostAllocationDashboard = (state: RootState) =>
  state.costAllocationDashboard;
export const {
  setSelectedCostAllocationDashboard,
  setCostAllocationDashboardList,
  setSelectedCostAllocationView,
} = costAllocationDashboardSlice.actions;
export default costAllocationDashboardSlice.reducer;
