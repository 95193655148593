import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  CostByResourceGroupType,
  MonthlyCostByResourceGroupType,
} from 'types/dataTypes';
import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import ColumnChart from 'components/ColumnChart';
import { selectDashboard } from 'redux/dashboardSlice';
import { REQUEST_STATUS } from 'constants/requestBody';
import { getMonthAndYearStringFormat } from 'utils/date';
import GraphHeader from 'components/GraphHeader';
import { evaluateRequestArray } from 'utils/handleErrors';
import GraphFilterDropdown from 'components/GraphFilterDropdown';
import { DROPDOWN_VALUE_FIELDS } from 'components/GraphFilterDropdown/constants';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getChartExcelExportData } from 'utils/exportToExcel';

import { YtdCostByResourceGroupColumns } from '../../constants';

type YtdCostByResourceGroupProps = {
  requestStatus: string;
  costByResourceGroupValues: CostByResourceGroupType[];
  costByResourceGroupData: MonthlyCostByResourceGroupType[];
  setSelectedCostByResourceGroupValues: (
    val: CostByResourceGroupType[]
  ) => void;
  isCostByResourceGroupTableView: boolean;
  setIsCostByResourceGroupTableView: (value: boolean) => void;
  selectedCostByResourceGroupValues: CostByResourceGroupType[];
  pdfView: boolean;
};

const YtdCostByResourceGroup = ({
  costByResourceGroupValues,
  costByResourceGroupData,
  setSelectedCostByResourceGroupValues,
  requestStatus,
  isCostByResourceGroupTableView,
  setIsCostByResourceGroupTableView,
  selectedCostByResourceGroupValues,
  pdfView,
}: YtdCostByResourceGroupProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const resourceGroupDropdown = (
    <GraphFilterDropdown
      allData={costByResourceGroupValues}
      selectedData={selectedCostByResourceGroupValues}
      setSelectedData={setSelectedCostByResourceGroupValues}
      valueSuffix={t('ytdCostByAzureResourceGroups.resourceGroups')}
      fieldName={DROPDOWN_VALUE_FIELDS.RESOURCE_GROUP}
      designVersion2
    />
  );

  const getDataSource = () => {
    return costByResourceGroupData
      .filter((item) =>
        selectedCostByResourceGroupValues
          .map((item1) => item1.resource_group)
          .includes(item.resource_group)
      )
      .map((item, index) => ({
        ...item,
        month: getMonthAndYearStringFormat(item.month),
        key: index,
      }));
  };

  const getComponent = () => {
    return isCostByResourceGroupTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={requestStatus === REQUEST_STATUS.PROCESSING}
          dataSource={getDataSource()}
          columns={YtdCostByResourceGroupColumns}
          scroll={{ y: 190 }}
          designVersion2
        />
      </div>
    ) : (
      <ColumnChart
        data={getDataSource()}
        xField="month"
        yField="cost"
        groupingField="resource_group"
        xTitle={t('ytdCostByAzureResourceGroups.month')}
        yTitle={t('ytdCostByAzureResourceGroups.costAfterCredit', {
          currencySymbol: currencySymbol,
        })}
        isStack
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
      />
    );
  };

  return (
    <div
      className="ytd-cost-by-azure-resource-group graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.ytdCostByResourceGroup')}
        graphName="ytd-cost-by-azure-resource-group"
        filters={resourceGroupDropdown}
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isCostByResourceGroupTableView}
        setIsTableView={setIsCostByResourceGroupTableView}
        designVersion2
        excelData={getChartExcelExportData(
          t('graphHeadings.ytdCostByResourceGroup'),
          getDataSource(),
          YtdCostByResourceGroupColumns,
          {
            connectionName: selectedDashboard!.connectorName,
          }
        )}
      />
      <div className="graph">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray([requestStatus])}
        />
      </div>
    </div>
  );
};

export default YtdCostByResourceGroup;
