import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Input from 'components/Input';
import Icon from 'components/Icon';
import { COLORS } from 'constants/graphConfig';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { INPUT_SIZE } from 'constants/appearance';
import {
  dispatchUpdatedCustomViewData,
  doesDataSourceSupportBillingMapping,
} from 'pages/CustomDashboardPage/utils';
import { ColumnType } from 'types/dashboard';
import {
  customDashboard,
  setSelectedMetrics,
} from 'redux/customDashboardSlice';

type MetricListItemProps = {
  metric: ColumnType;
  dragHandleProps?: any;
};

const MetricListItem = ({ metric, dragHandleProps }: MetricListItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedMetrics } = useSelector(customDashboard);
  const [isEditName, setIsEditName] = useState(false);

  /**
   * @function updateMetric
   * @description Updates the metric name based on metric field and updates the custom view data
   * @param metricField - metric to be updated
   * @param metricName - updated metric name
   */
  const updateMetric = (metricField: string, metricName: string) => {
    const updatedMetrics = selectedMetrics.map((item) =>
      item.field === metricField ? { ...item, label: metricName } : item
    );

    dispatch(setSelectedMetrics(updatedMetrics));
  };

  /**
   * @function onRemoveMetric
   * @description Removes the metric from the selected metrics list and updates the custom view data
   * @param metric - metric to be removed
   */
  const onRemoveMetric = (metric: string) => {
    const updatedMetrics = selectedMetrics.filter(
      (item) => item.field !== metric
    );

    dispatch(setSelectedMetrics(updatedMetrics));
    dispatchUpdatedCustomViewData();
  };

  return (
    <div
      className="metric-item flex flex-space-between flex-align-items-center"
      key={metric.field}
    >
      <div className="flex flex-column flex-gap-2" {...dragHandleProps}>
        <div className="flex flex-gap-2 flex-align-items-start">
          <Icon
            iconName={ICONS.DRAGGABLE}
            size={ICONS_SIZE.SM}
            color={COLORS.colorRegentGrey}
            className="metric-handle"
          />
          {isEditName && !doesDataSourceSupportBillingMapping() ? (
            <Input
              placeholder={t('customDashboard.optionsLabels.searchMetrics')}
              value={metric.label}
              onChange={(e: any) => updateMetric(metric.field, e.target.value)}
              onBlur={() => {
                setIsEditName(false);
                dispatchUpdatedCustomViewData();
              }}
              onPressEnter={() => {
                setIsEditName(false);
                dispatchUpdatedCustomViewData();
              }}
              size={INPUT_SIZE.SMALL}
            />
          ) : (
            <span className="metric-label font-subHeader">{metric.label}</span>
          )}
        </div>
        {!doesDataSourceSupportBillingMapping() && (
          <div className="actual-field flex flex-gap-4 flex-align-items-center">
            <span className="font-small-bold">
              {t('customDashboard.optionsLabels.actualField')}
            </span>
            <span className="font-small">{metric.field}</span>
          </div>
        )}
      </div>
      <div className="flex flex-gap-4">
        {!isEditName && !doesDataSourceSupportBillingMapping() && (
          <Icon
            iconName={ICONS.EDIT_LINE}
            size={ICONS_SIZE.SM}
            color={COLORS.colorRegentGrey}
            onClick={() => setIsEditName(true)}
          />
        )}
        <Icon
          iconName={ICONS.SUBTRACT_LINE}
          size={ICONS_SIZE.SM}
          color={COLORS.colorRegentGrey}
          onClick={() => onRemoveMetric(metric.field)}
        />
      </div>
    </div>
  );
};

export default MetricListItem;
