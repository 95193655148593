import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectDashboard,
  setPdfGraphToDownload,
  setPdfDownloadMode,
  setTableViewEnabled,
} from 'redux/dashboardSlice';
import Icon from 'components/Icon';
import Switch from 'components/Switch';
import Button from 'components/Button';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { tcoDashboard } from 'redux/tcoDashboardSlice';
import { PDF_ALL_GRAPHS } from 'constants/pdfConstants';
import { LoadingIcon } from 'assets/icons';
import { exportToExcel } from 'utils/exportToExcel';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectTheme } from 'redux/themeSlice';

import TcoDashboardListDrawer from '../TcoDashboardListDrawer';
import './index.scss';

type TcoDashboardHeaderProps = {
  setIsEmptyDashboard: (val: boolean) => void;
  setRequestStatus: (val: string) => void;
};

const TcoDashboardHeader = ({
  setIsEmptyDashboard,
  setRequestStatus,
}: TcoDashboardHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);
  const { exportToExcelData, tableViewEnabled, pdfDownloadMode } =
    useSelector(selectDashboard);
  const { selectedOnPremise, selectedTco } = useSelector(tcoDashboard);

  const [
    fetchDataCenterDataRequestStatus,
    setFetchDataCenterDataRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [
    fetchTcoProjectDataRequestStatus,
    setFetchTcoProjectDataRequestStatus,
  ] = useState(REQUEST_STATUS.PROCESSING);
  const [isDownloadingData, setIsDownloadingData] = useState(false);
  const [showSwitchDashboardDrawer, setShowSwitchDashboardDrawer] =
    useState(false);

  useEffect(() => {
    setRequestStatus(
      evaluateRequestArray([
        fetchDataCenterDataRequestStatus,
        fetchTcoProjectDataRequestStatus,
      ])
    );
  }, [fetchDataCenterDataRequestStatus, fetchTcoProjectDataRequestStatus]);

  useEffect(() => {
    if (!pdfDownloadMode) {
      setIsDownloadingData(false);
    }
  }, [pdfDownloadMode]);

  /**
   * @function onHandleDownloadDashboardData
   * @description function to handle common download of dashboard view. Will download pdf dashboard view is static except for cost optimization summary other wise will download data as excel
   * @returns either pdf or excel
   */
  const onHandleDownloadDashboardData = () => {
    setIsDownloadingData(true);
    if (!tableViewEnabled) {
      dispatch(setPdfGraphToDownload(PDF_ALL_GRAPHS));
      dispatch(setPdfDownloadMode(true));
      return;
    }
    setIsDownloadingData(false);
    return exportToExcel(
      selectedOnPremise?.name ?? selectedTco?.name ?? '',
      exportToExcelData
    );
  };

  return (
    <div className="tco-header flex flex-align-items-center flex-space-between flex-wrap">
      {evaluateRequestArray([
        fetchDataCenterDataRequestStatus,
        fetchTcoProjectDataRequestStatus,
      ]) === REQUEST_STATUS.SUCCESS &&
      (selectedOnPremise ?? selectedTco) ? (
        <div className="flex flex-align-items-center" id="title">
          <div className="flex flex-column">
            <div className="modal-heading">
              {selectedOnPremise?.name ?? selectedTco?.name}
            </div>
            <div className="sub-title-container flex flex-gap-8 font-caption-bold">
              <div>
                {selectedOnPremise?.dataCenterCode ?? selectedTco?.wbsCode}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Icon icon={LoadingIcon} className="rotate" />
      )}
      <div className="header-right flex flex-align-items-center flex-gap-16">
        {isDownloadingData ? (
          <Icon
            icon={LoadingIcon}
            className="rotate"
            color={theme.buttonIconColor}
          />
        ) : (
          <Icon
            iconName={ICONS.DOWNLOAD_2_LINE}
            size={ICONS_SIZE.ONE_X}
            color={theme.buttonIconColor}
            onClick={onHandleDownloadDashboardData}
            dataTestId="download-dashboard"
          />
        )}
        <div className="table-view-title table-typography flex flex-align-items-center flex-gap-8">
          {t('tableView')}
          <Switch
            size="small"
            checked={tableViewEnabled}
            onChange={(value: boolean) => {
              dispatch(setTableViewEnabled(value));
            }}
          />
        </div>
        <Button
          title={t('dashboardLabels.switchDashboard')}
          iconName={ICONS.ARROW_LEFT_RIGHT_LINE}
          iconSize={ICONS_SIZE.ONE_X}
          onClick={() => setShowSwitchDashboardDrawer(true)}
        />
        <TcoDashboardListDrawer
          show={showSwitchDashboardDrawer}
          setShow={setShowSwitchDashboardDrawer}
          setIsEmptyDashboard={setIsEmptyDashboard}
          fetchDataCenterDataRequestStatus={fetchDataCenterDataRequestStatus}
          setFetchDataCenterDataRequestStatus={
            setFetchDataCenterDataRequestStatus
          }
          fetchTcoProjectDataRequestStatus={fetchTcoProjectDataRequestStatus}
          setFetchTcoProjectDataRequestStatus={
            setFetchTcoProjectDataRequestStatus
          }
        />
      </div>
    </div>
  );
};

export default TcoDashboardHeader;
