import i18n from 'i18n';
import {
  SAVING_PLAN_REPORTS,
  SAVING_PLAN_REPORT_TYPES,
} from 'pages/ScorecardPage/constants';
import { CoverageUtilizationExcelMetaData } from 'pages/ScorecardPage/types';
import { ConnectionListType } from 'types/dashboard';
import { insertIndex } from 'utils/dataFormatterUtils';

/**
 * @function getUtilizationCoverageSummaryExportColumns
 * @description Function to return the columns details for export
 * @param reportType type of report for ehich the columns are returned. One of COVERAGE or UTILIZATION
 * @returns List of columns for export
 */
export const getUtilizationCoverageSummaryExportColumns = (
  reportType: string
) => {
  return [
    { header: '#', key: 'slNo', dataKey: 'slNo', width: 10 },
    {
      header: i18n.t('scorecard.summary.item'),
      dataKey: 'item',
      key: 'item',
    },
    {
      header:
        SAVING_PLAN_REPORTS.find((item) => item.value === reportType)?.label +
        ' %',
      dataKey: 'value',
      key: 'value',
      alignment: 'right',
    },
  ];
};

/**
 * @function getUtilizationCoverageSummaryExcelData
 * @param utilization Utilization data containing the sheetName, data and average of utilization
 * @param coverage - Coverage data containing the sheetName, data and average of coverage
 * @param connection connection details of the selected connection
 * @returns Object containing the details of excel export
 */
export const getUtilizationCoverageSummaryExcelData = (
  utilization: CoverageUtilizationExcelMetaData,
  coverage: CoverageUtilizationExcelMetaData,
  connection: ConnectionListType | null
) => {
  return [
    {
      sheetName: utilization.sheetName,
      columns: getUtilizationCoverageSummaryExportColumns(
        SAVING_PLAN_REPORT_TYPES.UTILIZATION
      ),
      data: insertIndex(utilization.data),
      filters: [
        {
          heading: i18n.t('excelExportLabels.connectionName'),
          value: connection?.displayName,
        },
      ],
    },
    {
      sheetName: coverage.sheetName,
      columns: getUtilizationCoverageSummaryExportColumns(
        SAVING_PLAN_REPORT_TYPES.COVERAGE
      ),
      data: insertIndex(coverage.data),
      filters: [
        {
          heading: i18n.t('excelExportLabels.connectionName'),
          value: connection?.displayName,
        },
      ],
    },
  ];
};
