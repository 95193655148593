import i18n from 'i18n';

export enum TCO_DROPDOWN_MENU_TYPES {
  ON_PREMISE = 'ON_PREMISE',
  TCO = 'TCO',
}

export const TCO_DROPDOWN_MENU_LIST = [
  {
    type: TCO_DROPDOWN_MENU_TYPES.ON_PREMISE,
    label: i18n.t('tcoDashboard.onPremise'),
  },
  {
    type: TCO_DROPDOWN_MENU_TYPES.TCO,
    label: i18n.t('tcoDashboard.tco'),
  },
];

export enum TCO_HORIZONTAL_NAV_MENU {
  COST_SUMMAY = 'COST_SUMMAY',
}

export const TCO_HORIZONTAL_NAV_MENU_LIST = [
  {
    id: TCO_HORIZONTAL_NAV_MENU.COST_SUMMAY,
    label: i18n.t('tcoDashboard.horizontalNavMenu.costSummary'),
  },
];
