import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Checkbox } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectReport,
  setReportOptions,
  setSelectedReportDimensions,
} from 'redux/reportSlice';
import Icon from 'components/Icon';
import { ICONS } from 'constants/icons';
import Input from 'components/Input';
import Tooltip from 'components/Tooltip';
import { isAConnectionWithBillingDatasource } from 'pages/CreateReportPage/utils';
import { INPUT_SIZE } from 'constants/appearance';
import { ChartDimensionType } from 'pages/CreateReportPage/types';
import Empty from 'components/Empty';

import DimensionList from '../DimensionList';
import DimensionsWithTags from '../DimensionsWithTags';

import './index.scss';

const Dimensions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { reportDimensions, selectedReportDimensions, reportOptions } =
    useSelector(selectReport);

  const [searchKey, setSearchKey] = useState('');
  const [showDimensionDropdown, setShowDimensionDropdown] = useState(false);

  const onChangeDimensionsCheckbox = (
    e: CheckboxChangeEvent,
    dimension: string
  ) => {
    let selectedValues: ChartDimensionType[] = [];

    if (e.target.checked) {
      selectedValues = [...selectedReportDimensions, { dimension: dimension }];
    } else {
      selectedValues = selectedReportDimensions.filter(
        (item) => item.dimension !== dimension
      );
    }

    dispatch(setSelectedReportDimensions(selectedValues));
  };

  const DimensionDropdownContent = reportDimensions.length ? (
    reportDimensions
      .filter((fieldItem) =>
        fieldItem.name.toLowerCase().includes(searchKey.toLowerCase())
      )
      .map((fieldItem) => (
        <Checkbox
          key={fieldItem.name}
          checked={selectedReportDimensions
            .map((dimension) => dimension.dimension)
            .includes(fieldItem.name)}
          onChange={(e) => onChangeDimensionsCheckbox(e, fieldItem.name)}
        >
          {fieldItem.name}
        </Checkbox>
      ))
  ) : (
    <Empty />
  );

  return (
    <div className="report-table-dimensions">
      <div className="dimension-heading flex flex-column flex-gap-4">
        <div className="flex flex-align-items-center flex-space-between">
          <div className="font-caption-bold">{t('reports.dimension')}</div>
          <Tooltip
            overlayClassName="dimension-dropdown"
            overlay={
              <div className="flex flex-column flex-gap-8">
                <Input
                  type="search"
                  rootClassName="full-width"
                  placeholder={t('reports.searchDimensions')}
                  size={INPUT_SIZE.SMALL}
                  value={searchKey}
                  onChange={(e: any) => setSearchKey(e.target.value)}
                  autoFocus
                />
                <div className="report-dimensions-group flex flex-column flex-gap-8 new-styled-scroll">
                  {isAConnectionWithBillingDatasource() ? (
                    <DimensionsWithTags searchKey={searchKey} />
                  ) : (
                    DimensionDropdownContent
                  )}
                </div>
              </div>
            }
            placement="bottomRight"
            open={showDimensionDropdown}
            onOpenChange={(open: boolean) => {
              if (open) {
                setSearchKey('');
              } else {
                dispatch(
                  setReportOptions({
                    ...reportOptions,
                    dimension: selectedReportDimensions,
                  })
                );
              }
              setShowDimensionDropdown(open);
            }}
            trigger="click"
            arrow={false}
          >
            <Icon
              className="add-icon"
              iconName={ICONS.ADD_LINE}
              dataTestId="add-dimension"
            />
          </Tooltip>
        </div>
        <div className="sub-title font-subHeader-small">
          {t('reports.dimensionSubHeading')}
        </div>
      </div>
      <DimensionList />
    </div>
  );
};

export default Dimensions;
