import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectReport,
  setReportConnection,
  setReportDatasource,
  setReportDimensions,
  setReportGroup,
  setReportGroupAvailableFields,
  setReportMetrics,
  setSelectedDatasourceMenu,
  setTagKeyValues,
  setTagMaps,
  setTypeOfConnection,
} from 'redux/reportSlice';
import Button from 'components/Button';
import DataSourceComponent from 'components/DataSourceComponent';
import {
  CUSTOM_DASHBOARD_TYPES,
  MY_DASHBOARD_TYPES,
  FIELD_TYPE,
  FieldSource,
} from 'constants/dashboard';
import { ReportsDataSourceNavs } from 'pages/CreateReportPage/constants';
import { DASHBOARD_TYPES, REQUEST_STATUS } from 'constants/requestBody';
import {
  getBillingMappingData,
  getCustomGroupAvailableFields,
  getLabels,
  getSystemBillingMappingData,
  getTagMappingData,
} from 'utils/services';
import {
  AvailableCustomGroupFieldsType,
  AvailableFieldsType,
} from 'types/dashboard';
import { onApiCallError } from 'utils/handleErrors';
import {
  createAvailableFieldsTypeForGroup,
  getProviderForConnection,
} from 'utils/dashboardUtils';
import {
  createCustomQuery,
  validateQueryAndGetAvailableFields,
} from 'components/CustomViewDataSourceDrawer/services';
import { PROVIDER } from 'constants/cloudProviders';

import './index.scss';

type DataSourceFormProps = {
  validateDatasourceForm: () => boolean;
  setDatasourceValidation: (val: boolean) => void;
};

const DataSourceForm = ({
  validateDatasourceForm,
  setDatasourceValidation,
}: DataSourceFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { typeOfConnection, reportDatasource, reportConnection, reportGroup } =
    useSelector(selectReport);

  const [validationMessage, setValidationMessage] = useState('');
  const [validateQueryReqStatus, setValidateQueryReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  /**
   * @function onSelectConnectionOrGroup
   * @description Function to update the connection or the group data and set the values based on the dashboard type selected (Group or Connection)
   */
  const onSelectConnectionOrGroup = (
    datasource: any,
    typeOfDashboard: string
  ) => {
    dispatch(
      setReportDatasource({
        ...reportDatasource,
        dataSet: '',
        dashBoardType: '',
      })
    );
    dispatch(setTypeOfConnection(typeOfDashboard));
    switch (typeOfDashboard) {
      case MY_DASHBOARD_TYPES.SINGLE_CONNECTION:
        dispatch(setReportGroup(null));
        dispatch(setReportConnection(datasource));
        break;

      case MY_DASHBOARD_TYPES.GROUP:
        dispatch(setReportConnection(null));
        dispatch(setReportGroup(datasource));
        break;
    }
  };

  /**
   * @function onSelectDatasetOrTypeOfDataset
   * @description Callback function for the dataset or type of dataset selection
   * @param dataset dataset selected
   * @param datasetType type of dataset selected (BILLING, RECOMMENDATION, CO2, etc.)
   */
  const onSelectDatasetOrTypeOfDataset = (
    dataset: string,
    datasetType: string
  ) => {
    if (typeOfConnection === MY_DASHBOARD_TYPES.SINGLE_CONNECTION) {
      dispatch(
        setReportDatasource({
          ...reportDatasource,
          dataSet: dataset,
          dashBoardType: datasetType,
        })
      );
      return;
    }

    dispatch(
      setReportDatasource({
        ...reportDatasource,
        dataSet: '',
        dashBoardType: dataset,
      })
    );
  };

  /**
   * @function onChangeCustomQuerySwitch
   * @description Callback function for switching the custom query switch cta
   * @param value boolean value of the switch status
   */
  const onChangeCustomQuerySwitch = (value: boolean) => {
    dispatch(
      setReportDatasource({
        ...reportDatasource,
        useCustomQuery: value,
      })
    );
  };

  /**
   * @function onChangeCustomQuery
   * @description Callback function for change in custom query
   * @param e Change event for custom query field
   */
  const onChangeCustomQuery = (e: any) =>
    dispatch(
      setReportDatasource({
        ...reportDatasource,
        customQuery: e.target.value,
      })
    );

  /**
   * @function onClickValidateAndContinue
   * @description Callback function for the continue button click
   */
  const onClickValidateAndContinue = () => {
    if (typeOfConnection === MY_DASHBOARD_TYPES.GROUP) {
      validateAndContinueGroup();
      return;
    }

    if (
      !reportDatasource.useCustomQuery &&
      reportDatasource.dashBoardType === DASHBOARD_TYPES.BILLING_DEFAULT
    ) {
      fetchDimensionsAndTagMap();
      return;
    }

    validateAndContinueConnection();
  };

  /**
   * @function validateAndContinueGroup
   * @description Callback function for the continue button click for group
   */
  const validateAndContinueGroup = () => {
    setValidateQueryReqStatus(REQUEST_STATUS.PROCESSING);

    getCustomGroupAvailableFields()
      .then((res: any) => {
        if (res?.status === 200) {
          const data: AvailableCustomGroupFieldsType[] =
            res?.data?.responseData ?? [];
          dispatch(
            setReportMetrics(
              data
                .filter(
                  (availableField: AvailableCustomGroupFieldsType) =>
                    availableField.category === FIELD_TYPE.NUMERIC
                )
                .map((availableField: AvailableCustomGroupFieldsType) => {
                  return createAvailableFieldsTypeForGroup(availableField);
                })
            )
          );
          dispatch(
            setReportDimensions(
              data
                .filter((availableField: AvailableCustomGroupFieldsType) =>
                  [
                    FIELD_TYPE.TIME.valueOf(),
                    FIELD_TYPE.LITERAL.valueOf(),
                  ].includes(availableField.category)
                )
                .map((availableField: AvailableCustomGroupFieldsType) => {
                  return createAvailableFieldsTypeForGroup(availableField);
                })
            )
          );
          dispatch(setReportGroupAvailableFields(data));
          dispatch(
            setReportDatasource({
              ...reportDatasource,
              availableFields: data.map(
                (availableField: AvailableCustomGroupFieldsType) =>
                  createAvailableFieldsTypeForGroup(availableField)
              ),
            })
          );
          setValidateQueryReqStatus(REQUEST_STATUS.SUCCESS);
          dispatch(setSelectedDatasourceMenu(ReportsDataSourceNavs.TABLE));
          setDatasourceValidation(true);
        } else {
          setValidationMessage(res?.message);
          setValidateQueryReqStatus(REQUEST_STATUS.ERROR);
          setDatasourceValidation(false);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, false, setValidateQueryReqStatus);
        setValidationMessage(e?.response?.data?.message);
        setDatasourceValidation(false);
      });
  };

  /**
   * @function validateAndContinueConnection
   * @description Callback function for the continue button click for single connection or import connection
   */
  const validateAndContinueConnection = () => {
    setValidateQueryReqStatus(REQUEST_STATUS.PROCESSING);
    const params = {
      connectorId: reportConnection?.connectorId!,
      type: reportDatasource.useCustomQuery
        ? CUSTOM_DASHBOARD_TYPES.CUSTOM
        : reportDatasource.dashBoardType,
      database: reportDatasource.dataSet,
    };

    const requestBody = {
      queryString: reportDatasource.customQuery ?? '',
    };

    validateQueryAndGetAvailableFields(requestBody, params)
      .then((res: any) => {
        if (res?.status === 200) {
          const data: any[] = res?.data?.responseData ?? [];

          dispatch(
            setReportMetrics(
              data.filter(
                (availableField: AvailableFieldsType) =>
                  availableField.category === FIELD_TYPE.NUMERIC
              )
            )
          );
          dispatch(
            setReportDimensions(
              data.filter((availableField: AvailableFieldsType) =>
                [
                  FIELD_TYPE.TIME.valueOf(),
                  FIELD_TYPE.LITERAL.valueOf(),
                ].includes(availableField.category)
              )
            )
          );

          if (reportDatasource.useCustomQuery) {
            uploadCustomQuery(data);
          } else {
            dispatch(
              setReportDatasource({
                ...reportDatasource,
                availableFields: data ?? [],
              })
            );
            setValidateQueryReqStatus(REQUEST_STATUS.SUCCESS);
            dispatch(setSelectedDatasourceMenu(ReportsDataSourceNavs.TABLE));
            setDatasourceValidation(true);
          }
        } else {
          setValidationMessage(res?.message);
          setValidateQueryReqStatus(REQUEST_STATUS.ERROR);
          setDatasourceValidation(false);
        }
      })
      .catch((e: any) => {
        onApiCallError(e, false, setValidateQueryReqStatus);
        setValidationMessage(e?.response?.data?.message);
        setDatasourceValidation(false);
      });
  };

  /**
   * @function fetchDimensionsAndTagMap
   * @description Function to fetch the billing mapping and tag mapping
   */
  const fetchDimensionsAndTagMap = () => {
    setValidateQueryReqStatus(REQUEST_STATUS.PROCESSING);

    const labelParams = {
      connectorId: reportConnection?.connectorId,
      dashBoardType: reportDatasource.dashBoardType,
    };

    const requests = [
      getSystemBillingMappingData(),
      getBillingMappingData(),
      getTagMappingData(),
      getLabels(labelParams),
    ];

    Promise.all(requests)
      .then((responses: any[]) => {
        const systemDimensionList = responses[0]?.data?.responseData ?? [];
        const billingMapDimensionList =
          responses[1]?.data?.responseData?.content ?? [];
        const tags =
          responses[2]?.data?.responseData?.content?.filter((tagItem: any) =>
            tagItem?.cloudMapList?.some(
              (connection: any) =>
                connection.connectionId === reportConnection?.connectorId
            )
          ) ?? [];
        const tagKeyValues =
          getProviderForConnection(reportConnection) === PROVIDER.AWS
            ? responses[3]?.data ?? []
            : [];

        dispatch(setTagMaps(tags));

        const allFields: AvailableFieldsType[] = [
          ...systemDimensionList.map((item: any) => {
            const fieldDetails = item.billingDimensionMap.find(
              (item: any) =>
                item.provider === getProviderForConnection(reportConnection)
            );
            return {
              name: item.dimensionName,
              field: fieldDetails?.billingField,
              category: fieldDetails?.fieldType,
              source: FieldSource.SYSTEM,
            };
          }),
          ...billingMapDimensionList.map((item: any) => {
            const fieldDetails = item.billingDimensionMap.find(
              (item: any) =>
                item.provider === getProviderForConnection(reportConnection)
            );
            return {
              name: item.dimensionName,
              field: fieldDetails?.billingField,
              category: fieldDetails?.fieldType,
              source: FieldSource.USER,
            };
          }),
        ];

        dispatch(
          setReportMetrics(
            allFields.filter(
              (availableField: AvailableFieldsType) =>
                availableField.field &&
                availableField.category === FIELD_TYPE.NUMERIC
            )
          )
        );
        dispatch(
          setReportDimensions(
            allFields.filter(
              (availableField: AvailableFieldsType) =>
                availableField.field &&
                [
                  FIELD_TYPE.TIME.valueOf(),
                  FIELD_TYPE.LITERAL.valueOf(),
                ].includes(availableField.category)
            )
          )
        );
        dispatch(
          setReportDatasource({
            ...reportDatasource,
            availableFields: allFields ?? [],
          })
        );

        dispatch(
          setTagKeyValues(
            tagKeyValues.map((item: any) => ({
              key: item.field,
              value: item.rawField,
            }))
          )
        );

        dispatch(setSelectedDatasourceMenu(ReportsDataSourceNavs.TABLE));
        setDatasourceValidation(true);
        setValidateQueryReqStatus(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        onApiCallError(e, false, setValidateQueryReqStatus);
      });
  };

  /**
   * @function uploadCustomQuery
   * @description Function to upload the custom query
   * @param availableFields list of available fields
   */
  const uploadCustomQuery = (availableFields: AvailableFieldsType[]) => {
    const params = {
      connectorId: reportConnection?.connectorId!,
    };

    const requestBody = {
      queryString: reportDatasource.customQuery,
    };

    createCustomQuery(requestBody, params)
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(
            setReportDatasource({
              ...reportDatasource,
              availableFields: availableFields ?? [],
              dashBoardType: CUSTOM_DASHBOARD_TYPES.CUSTOM,
              customQueryId: res?.data?.responseData,
            })
          );

          setValidateQueryReqStatus(REQUEST_STATUS.SUCCESS);
          dispatch(setSelectedDatasourceMenu(ReportsDataSourceNavs.TABLE));
          setDatasourceValidation(true);
        } else {
          setValidateQueryReqStatus(REQUEST_STATUS.ERROR);
          setValidationMessage(
            t('dashboardLabels.connectToDataSourceModalLabels.errorUploadQuery')
          );
          setDatasourceValidation(false);
        }
      })
      .catch((e) => {
        onApiCallError(e, false, setValidateQueryReqStatus);
        setValidationMessage(
          t('dashboardLabels.connectToDataSourceModalLabels.errorUploadQuery')
        );
        setDatasourceValidation(false);
      });
  };

  return (
    <div className="datasource-form flex flex-column flex-space-between flex-fit">
      <DataSourceComponent
        showConnections={true}
        dashboardType={typeOfConnection}
        connection={reportConnection}
        integrationConnection={null}
        group={reportGroup}
        datasource={reportDatasource}
        onSelectDataSource={onSelectConnectionOrGroup}
        onSelectDatasetOrTypeOfDataset={onSelectDatasetOrTypeOfDataset}
        onChangeCustomQuerySwitch={onChangeCustomQuerySwitch}
        onChangeCustomQuery={onChangeCustomQuery}
        validationMessage={validationMessage}
        allowedConnectionTypes={[
          MY_DASHBOARD_TYPES.SINGLE_CONNECTION,
          MY_DASHBOARD_TYPES.GROUP,
        ]}
        dataSourceDropdownClassName="reports-datasource-dropdown"
      />
      <Button
        title={t('reports.validateAndContinue')}
        onClick={onClickValidateAndContinue}
        disabled={!validateDatasourceForm()}
        loading={validateQueryReqStatus === REQUEST_STATUS.PROCESSING}
      />
    </div>
  );
};

export default DataSourceForm;
