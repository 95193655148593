import i18n from 'i18n';

import { COLORS } from 'constants/graphConfig';
import { store } from 'redux/store';
import { PptSlideCustomContent } from 'types/dashboard';
import { PPT_SLIDE_MARGIN, PPT_SLIDE_WIDTH } from 'utils/exportToPpt';
import { generateGraphColors } from 'utils/dashboardUtils';
import { formatNumberToCompact } from 'utils/dataFormatterUtils';

import { ProfileSpendTypes } from './constants';
import { SpendProfileDataType } from './types';
import {
  getHeaderBySpendType,
  getProfileDescriptionBySpendType,
} from './components/SpendProfileHeader/utils';

/**
 * @function getClassNameBySpendName
 * @description Function to get the class name by spend type
 * @param spendType spend type for which the class name is returned
 * @returns string class name
 */
export const getClassNameBySpendName = (spendType: ProfileSpendTypes) => {
  switch (spendType) {
    case ProfileSpendTypes.INCREASING_SPEND:
      return 'increasing-spend';

    case ProfileSpendTypes.DECREASING_SPEND:
      return 'decreasing-spend';

    case ProfileSpendTypes.INCONSISTENT_SPEND:
      return 'inconsistent-spend';

    case ProfileSpendTypes.CONSTANT_SPEND:
      return 'constant-spend';
  }
};

/**
 * @function getSpendProfilePptContent
 * @description Function to get the ppt content for spend profile
 * @param allSpendData all spend data
 * @returns PptSlidesCustomContent ppt content
 */
export const getSpendProfilePptContent = (
  allSpendData: SpendProfileDataType[]
) => {
  const currencySymbol = store.getState().commonUtility.currencySymbol;
  const thisSlideContent: PptSlideCustomContent = [];
  allSpendData.forEach((spendData, spendDataIndex) => {
    let titleColor: string;
    let iconSymbol: string;
    switch (spendData.spendType) {
      case ProfileSpendTypes.INCREASING_SPEND:
        titleColor = COLORS.brightGreen;
        iconSymbol = '↑';
        break;

      case ProfileSpendTypes.DECREASING_SPEND:
        titleColor = COLORS.fnRed;
        iconSymbol = '↓';
        break;

      case ProfileSpendTypes.INCONSISTENT_SPEND:
        titleColor = COLORS.secondaryPurple;
        iconSymbol = '⚡︎';
        break;

      case ProfileSpendTypes.CONSTANT_SPEND:
        titleColor = COLORS.goldFoil;
        iconSymbol = '=';
        break;
    }
    titleColor = titleColor.replace('#', '');

    const pieChartXPosition =
      (PPT_SLIDE_WIDTH / allSpendData.length) * spendDataIndex;
    const pieChartWidthPercentage: any = `${100 / allSpendData.length}%`;

    // Add header
    thisSlideContent.push({
      contentType: 'text',
      content: getHeaderBySpendType(spendData.spendType),
      contentOptions: {
        x: pieChartXPosition,
        y: PPT_SLIDE_MARGIN,
        w: pieChartWidthPercentage,
        align: 'center',
        fontSize: 10,
        bold: true,
        color: titleColor,
      },
    });

    // Add description
    thisSlideContent.push({
      contentType: 'text',
      content: getProfileDescriptionBySpendType(spendData.spendType),
      contentOptions: {
        x: pieChartXPosition,
        y: 0.5 + PPT_SLIDE_MARGIN,
        w: pieChartWidthPercentage,
        align: 'center',
        fontSize: 8,
      },
    });

    // Add total spend and total percentage
    thisSlideContent.push({
      contentType: 'text',
      content: `${iconSymbol} ${spendData.appsCount} ${i18n.t(
        'services'
      )} (${currencySymbol} ${formatNumberToCompact(spendData.appsSpend)})`,
      contentOptions: {
        x: pieChartXPosition,
        y: 1 + PPT_SLIDE_MARGIN,
        w: pieChartWidthPercentage,
        align: 'center',
        fontSize: 8,
        bold: true,
        color: titleColor,
      },
    });

    // Add pie chart for this current spend
    const chartData = [
      {
        name: spendData.spendType,
        labels: spendData.data.map((data) => data.businessUnit),
        values: spendData.data.map((data) => data.cost),
      },
    ];
    const pieChartProperties = {
      x: pieChartXPosition,
      y: 1.5,
      w: pieChartWidthPercentage,
      chartColors: generateGraphColors(chartData[0].values.length).map(
        (value) => value.replace('#', '')
      ),
      //
      showLabel: true,
      showValue: true,
      dataLabelPosition: 'outEnd',
      dataLabelFontSize: 6,
      dataLabelColor: COLORS.secondaryBlack.replace('#', ''),
      dataLabelFormatCode: '$ 0.00',
    };
    thisSlideContent.push({
      contentType: 'pie',
      content: chartData,
      contentOptions: pieChartProperties,
    });

    // Add top impacted apps as footer
    spendData.topAppsImpacted.forEach((impactedApp, appIndex) => {
      const impactedAppsXPosition = pieChartXPosition + appIndex;
      thisSlideContent.push({
        contentType: 'text',
        content: `${impactedApp.app}`,
        contentOptions: {
          x: PPT_SLIDE_MARGIN + impactedAppsXPosition,
          y: 5,
          w: 1,
          align: 'center',
          fontSize: 6,
          bold: true,
        },
      });
      thisSlideContent.push({
        contentType: 'text',
        content: `${iconSymbol} ${formatNumberToCompact(
          impactedApp.percentage
        )} %`,
        contentOptions: {
          x: PPT_SLIDE_MARGIN + impactedAppsXPosition,
          y: 5.2,
          w: 1,
          align: 'center',
          fontSize: 6,
          color: titleColor,
        },
      });
    });
  });

  return thisSlideContent;
};
