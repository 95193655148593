import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from 'components/Table';
import DashboardComponent from 'components/DashboardComponent';
import { REQUEST_STATUS } from 'constants/requestBody';
import GraphHeader from 'components/GraphHeader';
import BarChart from 'components/BarChart';
import { evaluateRequestArray } from 'utils/handleErrors';
import { selectDashboard } from 'redux/dashboardSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { getChartExcelExportData } from 'utils/exportToExcel';
import { CostByProject } from '../../types';
import { EstimatedSavingsPerProjectColumns } from '../../constants';

type EstimatedSavingsPerProjectProps = {
  estimatedSavingsPerProjectData: CostByProject[];
  requestStatus: string[];
  isEstimatedSavingsPerProjectTableView: boolean;
  setIsEstimatedSavingsPerProjectTableView: (value: boolean) => void;
  pdfView?: boolean;
};

const EstimatedSavingsPerProject = ({
  estimatedSavingsPerProjectData,
  requestStatus,
  isEstimatedSavingsPerProjectTableView,
  setIsEstimatedSavingsPerProjectTableView,
  pdfView,
}: EstimatedSavingsPerProjectProps) => {
  const { t } = useTranslation();
  const { selectedDashboard } = useSelector(selectDashboard);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const getComponent = () =>
    isEstimatedSavingsPerProjectTableView ? (
      <div className="table-view">
        <Table
          pagination={false}
          loading={
            evaluateRequestArray(requestStatus) === REQUEST_STATUS.PROCESSING
          }
          dataSource={estimatedSavingsPerProjectData.map((value, index) => {
            return { ...value, key: index };
          })}
          columns={EstimatedSavingsPerProjectColumns}
          scroll={{ y: 180 }}
          designVersion2
        />
      </div>
    ) : (
      <BarChart
        data={estimatedSavingsPerProjectData}
        xField="cost"
        yField="project"
        groupingField="project"
        showLabels={false}
        xTitle={t('gcpRecommendationSummary.cost', {
          currencySymbol: currencySymbol,
        })}
        yTitle={t('gcpRecommendationSummary.project')}
        disableAnimation={pdfView}
        showAllLegend={pdfView}
        prefixSymbol={currencySymbol}
      />
    );

  return (
    <div
      className="estimated-savings-per-project graph-card"
      id="graph-container"
    >
      <GraphHeader
        heading={t('graphHeadings.ytdEstimatedSavingsPerProject')}
        graphName="estimated-savings-per-project"
        isDownloadable={!pdfView}
        isTableViewSwitch={!pdfView}
        showExpandIcon={!pdfView}
        isTableView={isEstimatedSavingsPerProjectTableView}
        setIsTableView={setIsEstimatedSavingsPerProjectTableView}
        excelData={getChartExcelExportData(
          t('graphHeadings.ytdEstimatedSavingsPerProject'),
          estimatedSavingsPerProjectData,
          EstimatedSavingsPerProjectColumns,
          {
            connectionName: selectedDashboard!.connectorName,
          }
        )}
        designVersion2
      />
      <div className="graph no-filters">
        <DashboardComponent
          component={getComponent()}
          requestStatus={evaluateRequestArray(requestStatus)}
        />
      </div>
    </div>
  );
};

export default EstimatedSavingsPerProject;
