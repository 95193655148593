import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CalendarLineIcon from 'remixicon-react/CalendarLineIcon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';

import { selectTheme } from 'redux/themeSlice';
import MomentAntDatePicker from 'components/MomentAntDatePicker';
import Button from 'components/Button';
import { changeAlphaOfColor } from 'utils/dashboardUtils';
import { BUTTON_SIZE, BUTTON_TYPE } from 'constants/appearance';
import {
  DateRangePresetOptions,
  DATE_RANGE_PRESET_OPTIONS,
} from 'components/DatePicker/constants';
import { getValidationStyle } from 'utils/validations';

import './index.scss';

const { RangePicker } = MomentAntDatePicker;

const CustomRangePicker = ({ ...remainingProps }) => {
  const { t } = useTranslation();
  const { theme } = useSelector(selectTheme);

  const [dates, setDates] = useState<any>();
  const [popupOpen, setPopupOpen] = useState(false);
  const [presetValue, setPresetValue] = useState(DateRangePresetOptions.CUSTOM);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    setDates(remainingProps.value);
  }, [remainingProps.value]);

  useEffect(() => {
    setPresetValue(remainingProps.presetValue ?? DateRangePresetOptions.CUSTOM);
  }, [remainingProps.presetValue]);

  /**
   * @function onClickOk
   * @description Callback function for OK button click
   */
  const onClickOk = () => {
    if (!dates?.[0] || !dates?.[1]) {
      setValidationMessage(t('datePickerLabels.emptyRangeError'));
      return;
    }

    remainingProps.onChange(
      dates,
      [
        dates[0].format(remainingProps.format),
        dates[1].format(remainingProps.format),
      ],
      presetValue
    );
    setPopupOpen(false);
  };

  /**
   * @function onClickCancel
   * @description Callback function for Cancel button click
   */
  const onClickCancel = () => {
    setDates(remainingProps.value);
    setPresetValue(remainingProps.presetValue || DateRangePresetOptions.CUSTOM);
    setPopupOpen(false);
  };

  /**
   * @function onClickPresetValue
   * @description Callback function for preset radio button clicks
   */
  const onClickPresetValue = (presetItem: {
    label: string;
    key: DateRangePresetOptions;
    days?: number;
  }) => {
    setPresetValue(presetItem.key);

    if (presetItem.key !== DateRangePresetOptions.CUSTOM) {
      setDates([moment().subtract(presetItem.days, 'days'), moment()]);
    } else {
      setDates(remainingProps.value);
    }
  };

  return (
    <RangePicker
      popupStyle={
        {
          '--primary-color': theme.primaryColor,
          '--button-icon-color': theme.buttonIconColor,
          '--primary-bg-color': changeAlphaOfColor(theme.primaryColor, 10),
          '--text-color': theme.textColor,
        } as React.CSSProperties
      }
      separator="to"
      suffixIcon={<CalendarLineIcon className="suffix-icon" />}
      allowClear={false}
      open={popupOpen}
      onClick={() => setPopupOpen(true)}
      className={`date-picker-wrapper cursor-pointer ${
        remainingProps.className
      } ${remainingProps.designVersion2 ? 'design-v2' : 'design-v1'}`}
      popupClassName={`custom-range-picker-popup ${remainingProps.popupClassName}`}
      renderExtraFooter={() => (
        <div className="date-picker-footer flex flex-align-items-center flex-space-between">
          <span
            style={{
              display: `${getValidationStyle(validationMessage)}`,
            }}
            className="font-validation-error"
          >
            {validationMessage}
          </span>
          <div className="flex flex-align-items-center flex-end flex-fit">
            <Button
              title={t('datePickerLabels.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={onClickCancel}
            />
            <Button
              title={t('datePickerLabels.ok')}
              size={BUTTON_SIZE.SMALL}
              onClick={onClickOk}
            />
          </div>
        </div>
      )}
      presets={DATE_RANGE_PRESET_OPTIONS.map((item) => ({
        label: (
          <Radio
            value={presetValue}
            checked={item.key === presetValue}
            onClick={() => {
              onClickPresetValue(item);
            }}
          >
            {item.label}
          </Radio>
        ),
        value: [null, null],
      }))}
      {...remainingProps}
      value={dates}
      onChange={(values) => {
        setValidationMessage('');
        setPopupOpen(true);
        if (!values?.[0] || !values?.[1]) {
          return;
        }
        setDates(values);
      }}
      onCalendarChange={(values) => {
        setValidationMessage('');
        if (!values || (!values[0] && !values[1])) {
          return;
        }
        setPresetValue(DateRangePresetOptions.CUSTOM);
        setDates(values);
      }}
      inputReadOnly={true}
      onBlur={(e) => {
        setPopupOpen(true);
        e.preventDefault();
      }}
    />
  );
};

export default CustomRangePicker;
