import { Menu } from 'antd';
import CarouselComponent from 'components/CarouselComponent';

import './index.scss';

type HorizontalNavigationMenuProps = {
  menuItems: (JSX.Element | undefined)[];
  extraComponent?: JSX.Element;
  selectedKeys: string[];
  withCarousel?: boolean;
  className?: string;
};

const HorizontalNavigationMenu = ({
  menuItems,
  extraComponent,
  selectedKeys,
  withCarousel = false,
  className,
}: HorizontalNavigationMenuProps) => {
  return (
    <div
      className={`horizontal-navigation-menu-wrapper full-width flex flex-align-items-center flex-space-between ${className}`}
    >
      <Menu
        className={`nav-menu ${!withCarousel && 'flex'}`}
        selectedKeys={selectedKeys}
      >
        {withCarousel ? (
          <CarouselComponent className="nav-menu-carousel full-width">
            {menuItems}
          </CarouselComponent>
        ) : (
          menuItems
        )}
      </Menu>
      <div>{extraComponent}</div>
    </div>
  );
};

export default HorizontalNavigationMenu;
