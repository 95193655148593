import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, message, Radio, Row, Select } from 'antd';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';

import { dataCenter } from 'redux/dataCenterSlice';
import { selectCommonUtility } from 'redux/commonUtilitySlice';
import { ComputeEntryListType } from 'pages/DataCenterPage/types';
import {
  saveComputeResource,
  updateComputeResource,
} from 'pages/DataCenterPage/services';
import {
  HostOs,
  HypervisorUsed,
  MODE_OF_PURCHASES,
  PurchaseModes,
} from 'pages/DataCenterPage/constants';
import Button from 'components/Button';
import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import SelectDropdown from 'components/Select';
import CheckRadio from 'components/CheckRadio';
import DrawerComponent from 'components/DrawerComponent';
import { DATE_PICKER_TYPE } from 'components/DatePicker/constants';
import DatePicker from 'components/DatePicker';
import Icon from 'components/Icon';
import { ICONS, ICONS_SIZE } from 'constants/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { BUTTON_TYPE } from 'constants/appearance';
import { MONTH_YEAR_FORMAT, TIMESTAMP_FORMAT_WITHOUT_ZONE } from 'utils/date';
import {
  getValidationStyle,
  isNumber,
  validateEmptyField,
  validateRange,
} from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';

type ComputeRowModalProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  onSaveOrUpdateEntry: () => void;
  computeEntry?: ComputeEntryListType;
};

const ComputeRowModal = ({
  show,
  setShow,
  onSaveOrUpdateEntry,
  computeEntry,
}: ComputeRowModalProps) => {
  const { t } = useTranslation();
  const { selectedDataCenter } = useSelector(dataCenter);
  const { currencySymbol } = useSelector(selectCommonUtility);

  const [noOfVms, setNoOfVms] = useState('');
  const [noOfVmsValidation, setNoOfVmsValidation] = useState('');
  const [avgvCpuPerVm, setAvgvCpuPerVm] = useState('');
  const [avgvCpuPerVmValidation, setAvgvCpuPerVmValidation] = useState('');
  const [avgMemoryPerVm, setAvgMemoryPerVm] = useState('');
  const [avgMemoryPerVmValidation, setAvgMemoryPerVmValidation] = useState('');
  const [hostOs, setHostOs] = useState('');
  const [hostOsValidation, setHostOsValidation] = useState('');
  const [hypervisorUsed, setHypervisorUsed] = useState('');
  const [hypervisorUsedValidation, setHypervisorUsedValidation] = useState('');
  const [modeOfPurchase, setModeOfPurchase] = useState(
    PurchaseModes.RENTAL_OR_LEASE
  );
  const [cost, setCost] = useState('');
  const [costValidation, setCostValidation] = useState('');
  const [purchaseDate, setPurchaseDate] = useState<Moment | null>(null);
  const [purchaseDateValidation, setPurchaseDateValidation] = useState('');
  const [duration, setDuration] = useState('');
  const [durationValidation, setDurationValidation] = useState('');

  const [saveComputeEntryRequestStatus, setSaveComputeEntryRequestStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (computeEntry !== undefined) {
      setNoOfVms(computeEntry.vmCount.toString());
      setAvgvCpuPerVm(computeEntry.vcpuPerVmInPercentage.toString());
      setAvgMemoryPerVm(computeEntry.ramPerVmInGB.toString());
      setHostOs(computeEntry.hostOS);
      setHypervisorUsed(computeEntry.hypervisor);
      setModeOfPurchase(
        MODE_OF_PURCHASES.find(
          (item) => item.key === computeEntry.modeOfPurchase
        )?.key ?? PurchaseModes.RENTAL_OR_LEASE
      );
      setPurchaseDate(moment(computeEntry.dateOfPurchase));
      setDuration(computeEntry.durationInMonths?.toString() ?? '');
      setCost(
        (computeEntry.modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
          ? computeEntry.monthlyCost
          : computeEntry.totalCost
        )?.toString() ?? ''
      );
    }
  }, []);

  /**
   * @function getDropdownOptions
   * @description Function to construct dropdown options.
   * @param options list of strings used for dropdown options
   * @returns JSX elements with dropdown options.
   */
  const getDropdownOptions = (options: string[]) => {
    return (
      <>
        {options.map((option) => (
          <Select.Option key={option} value={option}>
            {option}
          </Select.Option>
        ))}
      </>
    );
  };

  /**
   * @function validateNoOfVms
   * @description Function to validate the number of vms input.
   * @param value string input value
   */
  const validateNoOfVms = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.computeLabels.noOfVms'),
        setNoOfVmsValidation
      )
    ) {
      return isNumber(
        value,
        false,
        t('manualEntry.computeLabels.noOfVms'),
        setNoOfVmsValidation
      );
    }

    return false;
  };

  /**
   * @function validateAvgvCpuPerVm
   * @description Function to validate the average CPU per VM.
   * @param value string input value
   */
  const validateAvgvCpuPerVm = (value: string) => {
    let isValidationSuccess = true;

    isValidationSuccess = !validateEmptyField(
      value,
      t('manualEntry.computeLabels.avgvCpuPerVm'),
      setAvgvCpuPerVmValidation
    );

    if (isValidationSuccess) {
      isValidationSuccess = isNumber(
        value,
        true,
        t('manualEntry.computeLabels.avgvCpuPerVm'),
        setAvgvCpuPerVmValidation
      );
    }

    if (isValidationSuccess) {
      isValidationSuccess = validateRange(
        value,
        0,
        100,
        t('manualEntry.computeLabels.avgvCpuPerVm'),
        setAvgvCpuPerVmValidation
      );
    }

    return isValidationSuccess;
  };

  /**
   * @function validateAvgMemoryPerVm
   * @description Function to validate the average memory per vm.
   * @param value string input value
   */
  const validateAvgMemoryPerVm = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.computeLabels.avgMemoryPerVm'),
        setAvgMemoryPerVmValidation
      )
    ) {
      return isNumber(
        value,
        false,
        t('manualEntry.computeLabels.avgMemoryPerVm'),
        setAvgMemoryPerVmValidation
      );
    }

    return false;
  };

  /**
   * @function validateHostOs
   * @description Function to validate the Host OS.
   * @param value string input value
   */
  const validateHostOs = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.computeLabels.hostOs'),
      setHostOsValidation
    );
  };

  /**
   * @function validateHypervisorUsed
   * @description Function to validate the Hypervisor Used.
   * @param value string input value
   */
  const validateHypervisorUsed = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.computeLabels.hypervisorUsed'),
      setHypervisorUsedValidation
    );
  };

  /**
   * @function validateCost
   * @description Function to validate the Cost.
   * @param value string input value
   */
  const validateCost = (value: string) => {
    if (
      !validateEmptyField(
        value,
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
          ? t('manualEntry.computeLabels.costMonthly')
          : t('manualEntry.computeLabels.cost'),
        setCostValidation
      )
    ) {
      return isNumber(
        value,
        true,
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
          ? t('manualEntry.computeLabels.costMonthly')
          : t('manualEntry.computeLabels.cost'),
        setCostValidation
      );
    }

    return false;
  };

  /**
   * @function validatePurchaseDate
   * @description Function to validate the Purchase Date.
   * @param value string input value
   */
  const validatePurchaseDate = (value: string) => {
    return !validateEmptyField(
      value,
      t('manualEntry.computeLabels.dateMonthOfPurchase'),
      setPurchaseDateValidation
    );
  };

  /**
   * @function validateDuration
   * @description Function to validate the Duration.
   * @param value string input value
   */
  const validateDuration = (value: string) => {
    if (
      !validateEmptyField(
        value,
        t('manualEntry.computeLabels.duration'),
        setDurationValidation
      )
    ) {
      return isNumber(
        value,
        false,
        t('manualEntry.computeLabels.duration'),
        setDurationValidation
      );
    }

    return false;
  };

  /**
   * @function onClickAdd
   * @description Function to add compute row for the entered values
   */
  const onClickAdd = () => {
    if (!validateAllFields()) {
      return;
    }

    setSaveComputeEntryRequestStatus(REQUEST_STATUS.PROCESSING);
    let requestBody: any = {
      dataCenterCode: selectedDataCenter?.dataCenterCode,
      vmCount: noOfVms,
      ramPerVmInGB: avgMemoryPerVm,
      vcpuPerVmInPercentage: avgvCpuPerVm,
      hostOS: hostOs,
      hypervisor: hypervisorUsed,
      modeOfPurchase: modeOfPurchase,
      dateOfPurchase: moment(purchaseDate)
        .startOf('month')
        .startOf('day')
        .format(TIMESTAMP_FORMAT_WITHOUT_ZONE),
      monthlyCost:
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE ? cost : undefined,
      totalCost:
        modeOfPurchase === PurchaseModes.PERMANENT_PURCHASE ? cost : undefined,
      durationInMonths:
        modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE ? duration : undefined,
    };

    let successMessage = t('manualEntry.computeLabels.saveComputeSuccess');
    let failureMessage = t('manualEntry.computeLabels.saveComputeFailed');

    if (computeEntry !== undefined) {
      requestBody.id = computeEntry.id;

      successMessage = t('manualEntry.computeLabels.updateComputeSuccess');
      failureMessage = t('manualEntry.computeLabels.updateComputeFailed');
    }

    (computeEntry === undefined
      ? saveComputeResource(requestBody)
      : updateComputeResource(requestBody)
    )
      .then((res: any) => {
        if (res?.status === 200) {
          setSaveComputeEntryRequestStatus(REQUEST_STATUS.SUCCESS);
          message.success(successMessage);
          onSaveOrUpdateEntry();
          setShow(false);
          return;
        }

        setSaveComputeEntryRequestStatus(REQUEST_STATUS.ERROR);
        setErrorMessage(res?.message ?? failureMessage);
      })
      .catch((e) => {
        onApiCallError(e, false, setSaveComputeEntryRequestStatus);
        setErrorMessage(e?.response?.data?.message ?? failureMessage);
      });
  };

  /**
   * @function validateAllFields
   * @description Function to validate the compute fields
   * @returns boolean true validated else false
   */
  const validateAllFields = () => {
    validateNoOfVms(noOfVms);
    validateAvgvCpuPerVm(avgvCpuPerVm);
    validateAvgMemoryPerVm(avgMemoryPerVm);
    validateHostOs(hostOs);
    validateHypervisorUsed(hypervisorUsed);
    validateCost(cost);
    validatePurchaseDate(purchaseDate?.toString() ?? '');
    validateDuration(duration);

    return (
      validateNoOfVms(noOfVms) &&
      validateAvgvCpuPerVm(avgvCpuPerVm) &&
      validateAvgMemoryPerVm(avgMemoryPerVm) &&
      validateHostOs(hostOs) &&
      validateHypervisorUsed(hypervisorUsed) &&
      validateCost(cost) &&
      validatePurchaseDate(purchaseDate?.toString() ?? '') &&
      (modeOfPurchase === PurchaseModes.PERMANENT_PURCHASE ||
        validateDuration(duration))
    );
  };

  return (
    <DrawerComponent
      className="row-modal"
      width={600}
      open={show}
      onClose={() => setShow(false)}
      title={
        (computeEntry ? t('manualEntry.editRow') : t('manualEntry.addNewRow')) +
        ' - ' +
        t('manualEntry.nav.compute')
      }
      footer={
        <div className="flex flex-column flex-gap-8">
          <span
            style={{
              display: getValidationStyle(errorMessage),
            }}
            className="font-validation-error"
          >
            {errorMessage}
          </span>
          <div className="flex flex-align-items-center flex-end">
            <Button
              title={t('manualEntry.cancel')}
              type={BUTTON_TYPE.LINK}
              onClick={() => setShow(false)}
            />
            <Button
              title={
                computeEntry === undefined
                  ? t('manualEntry.add')
                  : t('manualEntry.update')
              }
              onClick={onClickAdd}
              loading={
                saveComputeEntryRequestStatus === REQUEST_STATUS.PROCESSING
              }
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-column flex-gap-16">
        <Row>
          <Col span={24}>
            <FormLabel
              title={t('manualEntry.computeLabels.noOfVms')}
              required
            />
          </Col>
          <Col span={24}>
            <Input
              type="input"
              value={noOfVms}
              placeholder={t('manualEntry.computeLabels.noOfVmsPlaceholder')}
              onChange={(e: any) => {
                setNoOfVms(e.target.value);
                validateNoOfVms(e.target.value);
              }}
              onBlur={() => validateNoOfVms(noOfVms)}
            />
          </Col>
          <Col span={24}>
            <span
              style={{
                display: `${getValidationStyle(noOfVmsValidation)}`,
              }}
              className="font-validation-error"
            >
              {noOfVmsValidation}
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.computeLabels.avgvCpuPerVm')}
              required
            />
            <Input
              type="input"
              value={avgvCpuPerVm}
              placeholder={t(
                'manualEntry.computeLabels.avgvCpuPerVmPlaceholder'
              )}
              onChange={(e: any) => {
                setAvgvCpuPerVm(e.target.value);
                validateAvgvCpuPerVm(e.target.value);
              }}
              onBlur={() => validateAvgvCpuPerVm(avgvCpuPerVm)}
            />
            <span
              style={{
                display: `${getValidationStyle(avgvCpuPerVmValidation)}`,
              }}
              className="font-validation-error"
            >
              {avgvCpuPerVmValidation}
            </span>
          </Col>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.computeLabels.avgMemoryPerVm')}
              required
            />
            <Input
              type="input"
              value={avgMemoryPerVm}
              placeholder={t(
                'manualEntry.computeLabels.avgMemoryPerVmlaceholder'
              )}
              onChange={(e: any) => {
                setAvgMemoryPerVm(e.target.value);
                validateAvgMemoryPerVm(e.target.value);
              }}
              onBlur={() => validateAvgMemoryPerVm(avgMemoryPerVm)}
            />
            <span
              style={{
                display: `${getValidationStyle(avgMemoryPerVmValidation)}`,
              }}
              className="font-validation-error"
            >
              {avgMemoryPerVmValidation}
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel title={t('manualEntry.computeLabels.hostOs')} required />
            <SelectDropdown
              className="full-width"
              menu={getDropdownOptions(Object.values(HostOs))}
              value={hostOs}
              placeholder={t('manualEntry.computeLabels.hostOsPlaceholder')}
              onChange={(value: string) => {
                setHostOs(value);
                validateHostOs(value);
              }}
              onBlur={() => validateHostOs(hostOs)}
            />
            <span
              style={{
                display: `${getValidationStyle(hostOsValidation)}`,
              }}
              className="font-validation-error"
            >
              {hostOsValidation}
            </span>
          </Col>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.computeLabels.hypervisorUsed')}
              required
            />
            <SelectDropdown
              className="full-width"
              menu={getDropdownOptions(Object.values(HypervisorUsed))}
              value={hypervisorUsed}
              placeholder={t(
                'manualEntry.computeLabels.hypervisorUsedPlaceholder'
              )}
              onChange={(value: string) => {
                setHypervisorUsed(value);
                validateHypervisorUsed(value);
              }}
              onBlur={() => validateHypervisorUsed(hypervisorUsed)}
            />
            <span
              style={{
                display: `${getValidationStyle(hypervisorUsedValidation)}`,
              }}
              className="font-validation-error"
            >
              {hypervisorUsedValidation}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormLabel
              title={t('manualEntry.computeLabels.modeOfPurchase')}
              required
            />
          </Col>
          <Col span={24}>
            <Radio.Group
              onChange={(event) => setModeOfPurchase(event.target.value)}
              value={modeOfPurchase}
            >
              {MODE_OF_PURCHASES.map((mode) => (
                <CheckRadio value={mode.key} key={mode.key}>
                  {mode.label}
                </CheckRadio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <Divider className="horizontal-divider" />
        <Row>
          <Col span={24}>
            <FormLabel
              title={
                modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE
                  ? t('manualEntry.computeLabels.costMonthly')
                  : t('manualEntry.computeLabels.cost')
              }
              required
            />
          </Col>
          <Col span={24}>
            <Input
              type="input"
              prefix={currencySymbol}
              value={cost}
              placeholder={t('manualEntry.computeLabels.costPlaceholder')}
              onChange={(e: any) => {
                setCost(e.target.value);
                validateCost(e.target.value);
              }}
              onBlur={() => validateCost(cost)}
            />
          </Col>
          <Col span={24}>
            <span
              style={{
                display: `${getValidationStyle(costValidation)}`,
              }}
              className="font-validation-error"
            >
              {costValidation}
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormLabel
              title={t('manualEntry.computeLabels.dateMonthOfPurchase')}
              required
            />
            <DatePicker
              className="full-width"
              picker="month"
              placeholder={t(
                'manualEntry.computeLabels.dateMonthOfPurchasePlaceholder'
              )}
              disabledDate={(current: any) =>
                current && current > moment().endOf('day')
              }
              suffixIcon={
                <Icon
                  iconName={ICONS.ARROW_DOWN_S_LINE}
                  size={ICONS_SIZE.TWO_X}
                />
              }
              pickerType={DATE_PICKER_TYPE.DATE_PICKER}
              format={MONTH_YEAR_FORMAT}
              value={purchaseDate}
              onChange={(value: any, date: any) => {
                setPurchaseDate(value);
                validatePurchaseDate(date);
              }}
              onBlur={() =>
                validatePurchaseDate(purchaseDate?.toString() ?? '')
              }
            />
            <span
              style={{
                display: `${getValidationStyle(purchaseDateValidation)}`,
              }}
              className="font-validation-error"
            >
              {purchaseDateValidation}
            </span>
          </Col>
          {modeOfPurchase === PurchaseModes.RENTAL_OR_LEASE && (
            <Col span={12}>
              <FormLabel
                title={t('manualEntry.computeLabels.duration')}
                required
              />
              <Input
                className="full-width"
                type="input"
                value={duration}
                suffix={`/ ${t('manualEntry.computeLabels.months')}`}
                placeholder={t('manualEntry.computeLabels.durationPlaceholder')}
                onChange={(e: any) => {
                  setDuration(e.target.value);
                  validateDuration(e.target.value);
                }}
                onBlur={() => validateDuration(duration)}
              />
              <span
                style={{
                  display: `${getValidationStyle(durationValidation)}`,
                }}
                className="font-validation-error"
              >
                {durationValidation}
              </span>
            </Col>
          )}
        </Row>
      </div>
    </DrawerComponent>
  );
};

export default ComputeRowModal;
