import { ChatbotProviderListType } from 'components/ChatBoxModal/types';

export const isAllLLMsChecked = (
  allLLMs: ChatbotProviderListType[],
  selectedLLMs: ChatbotProviderListType[]
) => {
  return allLLMs.every((llmModel) =>
    selectedLLMs.some(
      (selectedModel) =>
        selectedModel.model_name === llmModel.model_name &&
        llmModel.submodel_name.every((subModel) =>
          selectedModel.submodel_name.includes(subModel)
        )
    )
  );
};

export const onSelectAllModels = (
  isChecked: boolean,
  allLLMs: ChatbotProviderListType[],
  setSelectedLLMs: (val: ChatbotProviderListType[]) => void
) => {
  if (isChecked) {
    setSelectedLLMs(allLLMs);
  } else {
    setSelectedLLMs([]);
  }
};

export const isLLMSelected = (
  subModelName: string,
  modelName: string,
  selectedLLMs: ChatbotProviderListType[]
) => {
  return selectedLLMs.some(
    (llmModel) =>
      llmModel.model_name === modelName &&
      llmModel.submodel_name.includes(subModelName)
  );
};

export const onSelectSubModel = (
  isChecked: boolean,
  model: string,
  subModel: string,
  selectedLLMs: ChatbotProviderListType[],
  setSelectedLLMs: (val: ChatbotProviderListType[]) => void
) => {
  const existingModelSelection = selectedLLMs.find(
    (llmModel) => llmModel.model_name === model
  );

  // A sub model needs to be appended for the existing model selection
  if (isChecked && existingModelSelection) {
    setSelectedLLMs([
      ...selectedLLMs.filter((llmModel) => llmModel.model_name !== model),
      {
        ...existingModelSelection,
        submodel_name: [...existingModelSelection.submodel_name, subModel],
      },
    ]);
    return;
  }

  // A new model needs to be added if there are no previous sub models exists before
  if (isChecked) {
    setSelectedLLMs([
      ...selectedLLMs,
      {
        model_name: model,
        submodel_name: [subModel],
      },
    ]);
    return;
  }

  // Remove the model from selection if unchecked sub-model is the last model in of the model
  if (existingModelSelection!.submodel_name.length === 1) {
    setSelectedLLMs([
      ...selectedLLMs.filter((llmModel) => llmModel.model_name !== model),
    ]);
    return;
  } else {
    // Remove the sub model from selection if unchecked and the parent model has more selections
    setSelectedLLMs([
      ...selectedLLMs.filter((llmModel) => llmModel.model_name !== model),
      {
        ...existingModelSelection!,
        submodel_name: [
          ...existingModelSelection!.submodel_name.filter(
            (subModelName) => subModelName !== subModel
          ),
        ],
      },
    ]);
    return;
  }
};
